// export const USA = (async () => {
//   const apiURL = 'https://dr48nfhb-5000.use.devtunnels.ms/cities_data'; // Replace with your API URL
  
//   try {
//     const response = await fetch(apiURL);
//     if (!response.ok) {
//       throw new Error('Network response was not OK');
//     }
//     const data = await response.json();
//     console.log(data); // For debugging, you can log the data

//     return data; // This returns the fetched data
//   } catch (error) {
//     console.error('Fetch error:', error); // Handle errors
//     return []; // Return an empty array or handle it accordingly
//   }
// })();

export const USA = [
  {
      "city": "100 MILE HOUSE",
      "state": "BC"
  },
  {
      "city": "108 MILE RANCH",
      "state": "BC"
  },
  {
      "city": "150 MILE HOUSE",
      "state": "BC"
  },
  {
      "city": "70 MILE HOUSE",
      "state": "BC"
  },
  {
      "city": "ABBEY",
      "state": "SK"
  },
  {
      "city": "ABBOTSFORD",
      "state": "BC"
  },
  {
      "city": "ABEE",
      "state": "AB"
  },
  {
      "city": "ABERCORN",
      "state": "QC"
  },
  {
      "city": "ABERDEEN",
      "state": "SK"
  },
  {
      "city": "ABERNETHY",
      "state": "SK"
  },
  {
      "city": "ACADIA VALLEY",
      "state": "AB"
  },
  {
      "city": "ACADIE SIDING",
      "state": "NB"
  },
  {
      "city": "ACADIEVILLE",
      "state": "NB"
  },
  {
      "city": "ACHESON",
      "state": "AB"
  },
  {
      "city": "ACME",
      "state": "AB"
  },
  {
      "city": "ACTON VALE",
      "state": "QC"
  },
  {
      "city": "ACTON",
      "state": "ON"
  },
  {
      "city": "ADAMS GULCH",
      "state": "NB"
  },
  {
      "city": "ADAMSVILLE",
      "state": "NB"
  },
  {
      "city": "ADDISON",
      "state": "ON"
  },
  {
      "city": "ADEN",
      "state": "AB"
  },
  {
      "city": "ADMIRAL ROCK",
      "state": "NS"
  },
  {
      "city": "ADMIRAL",
      "state": "SK"
  },
  {
      "city": "ADSTOCK",
      "state": "QC"
  },
  {
      "city": "ADVOCATE HARBOUR",
      "state": "NS"
  },
  {
      "city": "AETNA",
      "state": "AB"
  },
  {
      "city": "AFTON STATION",
      "state": "NS"
  },
  {
      "city": "AGASSIZ",
      "state": "BC"
  },
  {
      "city": "AGUANISH",
      "state": "QC"
  },
  {
      "city": "AGUATHUNA",
      "state": "NL"
  },
  {
      "city": "AHMIC HARBOUR",
      "state": "ON"
  },
  {
      "city": "AHOUSAT",
      "state": "BC"
  },
  {
      "city": "AILSA CRAIG",
      "state": "ON"
  },
  {
      "city": "AINSWORTH HOT SPRINGS",
      "state": "BC"
  },
  {
      "city": "AIR RONGE",
      "state": "SK"
  },
  {
      "city": "AIRDRIE",
      "state": "AB"
  },
  {
      "city": "AIYANSH",
      "state": "BC"
  },
  {
      "city": "AJAX",
      "state": "ON"
  },
  {
      "city": "AKLAVIK",
      "state": "NT"
  },
  {
      "city": "AKULIVIK",
      "state": "QC"
  },
  {
      "city": "AKWESASNE",
      "state": "ON"
  },
  {
      "city": "AKWESASNE",
      "state": "QC"
  },
  {
      "city": "ALAMEDA",
      "state": "SK"
  },
  {
      "city": "ALBAN",
      "state": "ON"
  },
  {
      "city": "ALBANEL",
      "state": "QC"
  },
  {
      "city": "ALBANY",
      "state": "PE"
  },
  {
      "city": "ALBERT BRIDGE",
      "state": "NS"
  },
  {
      "city": "ALBERT MINES",
      "state": "NB"
  },
  {
      "city": "ALBERTA BEACH",
      "state": "AB"
  },
  {
      "city": "ALBERTON",
      "state": "ON"
  },
  {
      "city": "ALBERTON",
      "state": "PE"
  },
  {
      "city": "ALBERTVILLE",
      "state": "QC"
  },
  {
      "city": "ALBERTVILLE",
      "state": "SK"
  },
  {
      "city": "ALBRIGHTS CORNER",
      "state": "NB"
  },
  {
      "city": "ALCIDA",
      "state": "NB"
  },
  {
      "city": "ALCOMDALE",
      "state": "AB"
  },
  {
      "city": "ALCOVE",
      "state": "QC"
  },
  {
      "city": "ALDER FLATS",
      "state": "AB"
  },
  {
      "city": "ALDER POINT",
      "state": "NS"
  },
  {
      "city": "ALDERGROVE",
      "state": "BC"
  },
  {
      "city": "ALDERSYDE",
      "state": "AB"
  },
  {
      "city": "ALDERWOOD",
      "state": "NB"
  },
  {
      "city": "ALDOUANE",
      "state": "NB"
  },
  {
      "city": "ALERT BAY",
      "state": "BC"
  },
  {
      "city": "ALEXANDER",
      "state": "MB"
  },
  {
      "city": "ALEXANDRIA",
      "state": "ON"
  },
  {
      "city": "ALEXIS CREEK",
      "state": "BC"
  },
  {
      "city": "ALFRED",
      "state": "ON"
  },
  {
      "city": "ALGOMA MILLS",
      "state": "ON"
  },
  {
      "city": "ALHAMBRA",
      "state": "AB"
  },
  {
      "city": "ALIDA",
      "state": "SK"
  },
  {
      "city": "ALIX",
      "state": "AB"
  },
  {
      "city": "ALKALI LAKE",
      "state": "BC"
  },
  {
      "city": "ALLAINVILLE",
      "state": "NB"
  },
  {
      "city": "ALLAN",
      "state": "SK"
  },
  {
      "city": "ALLANBURG",
      "state": "ON"
  },
  {
      "city": "ALLARDVILLE",
      "state": "NB"
  },
  {
      "city": "ALLENFORD",
      "state": "ON"
  },
  {
      "city": "ALLIANCE",
      "state": "AB"
  },
  {
      "city": "ALLISON",
      "state": "NB"
  },
  {
      "city": "ALLISTON",
      "state": "ON"
  },
  {
      "city": "ALMA",
      "state": "NB"
  },
  {
      "city": "ALMA",
      "state": "ON"
  },
  {
      "city": "ALMA",
      "state": "QC"
  },
  {
      "city": "ALMONTE",
      "state": "ON"
  },
  {
      "city": "ALONSA",
      "state": "MB"
  },
  {
      "city": "ALOUETTE",
      "state": "QC"
  },
  {
      "city": "ALSASK",
      "state": "SK"
  },
  {
      "city": "ALSIKE",
      "state": "AB"
  },
  {
      "city": "ALTAMONT",
      "state": "MB"
  },
  {
      "city": "ALTARIO",
      "state": "AB"
  },
  {
      "city": "ALTICANE",
      "state": "SK"
  },
  {
      "city": "ALTON",
      "state": "NS"
  },
  {
      "city": "ALTON",
      "state": "ON"
  },
  {
      "city": "ALTONA",
      "state": "BC"
  },
  {
      "city": "ALTONA",
      "state": "MB"
  },
  {
      "city": "ALVENA",
      "state": "SK"
  },
  {
      "city": "ALVINSTON",
      "state": "ON"
  },
  {
      "city": "AMARANTH",
      "state": "MB"
  },
  {
      "city": "AMELIASBURG",
      "state": "ON"
  },
  {
      "city": "AMHERST",
      "state": "NS"
  },
  {
      "city": "AMHERST",
      "state": "QC"
  },
  {
      "city": "AMHERSTBURG",
      "state": "ON"
  },
  {
      "city": "AMHERSTVIEW",
      "state": "ON"
  },
  {
      "city": "AMISK",
      "state": "AB"
  },
  {
      "city": "AMMON",
      "state": "NB"
  },
  {
      "city": "AMOS",
      "state": "QC"
  },
  {
      "city": "AMQUI",
      "state": "QC"
  },
  {
      "city": "ANAGANCE",
      "state": "NB"
  },
  {
      "city": "ANAHIM LAKE",
      "state": "BC"
  },
  {
      "city": "ANCASTER",
      "state": "ON"
  },
  {
      "city": "ANCHOR POINT",
      "state": "NL"
  },
  {
      "city": "ANDERSON ROAD",
      "state": "NB"
  },
  {
      "city": "ANDERSON SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "ANDERSONVILLE",
      "state": "NB"
  },
  {
      "city": "ANDREW",
      "state": "AB"
  },
  {
      "city": "ANEROID",
      "state": "SK"
  },
  {
      "city": "ANFIELD",
      "state": "NB"
  },
  {
      "city": "ANGE-GARDIEN",
      "state": "QC"
  },
  {
      "city": "ANGLEMONT",
      "state": "BC"
  },
  {
      "city": "ANGLIERS",
      "state": "QC"
  },
  {
      "city": "ANGLING LAKE",
      "state": "ON"
  },
  {
      "city": "ANGUS",
      "state": "ON"
  },
  {
      "city": "ANGUSVILLE",
      "state": "MB"
  },
  {
      "city": "ANJOU",
      "state": "QC"
  },
  {
      "city": "ANMORE",
      "state": "BC"
  },
  {
      "city": "ANNAHEIM",
      "state": "SK"
  },
  {
      "city": "ANNAN",
      "state": "ON"
  },
  {
      "city": "ANNAPOLIS ROYAL",
      "state": "NS"
  },
  {
      "city": "ANOLA",
      "state": "MB"
  },
  {
      "city": "ANSE-BLEUE",
      "state": "NB"
  },
  {
      "city": "ANTIGONISH",
      "state": "NS"
  },
  {
      "city": "ANTLER",
      "state": "SK"
  },
  {
      "city": "ANTRIM",
      "state": "NS"
  },
  {
      "city": "ANZAC",
      "state": "AB"
  },
  {
      "city": "APOHAQUI",
      "state": "NB"
  },
  {
      "city": "APPIN",
      "state": "ON"
  },
  {
      "city": "APPLE HILL",
      "state": "ON"
  },
  {
      "city": "APSLEY",
      "state": "ON"
  },
  {
      "city": "AQUAFORTE",
      "state": "NL"
  },
  {
      "city": "ARBEAU SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "ARBORFIELD",
      "state": "SK"
  },
  {
      "city": "ARBORG",
      "state": "MB"
  },
  {
      "city": "ARCADIA",
      "state": "NS"
  },
  {
      "city": "ARCHERWILL",
      "state": "SK"
  },
  {
      "city": "ARCOLA",
      "state": "SK"
  },
  {
      "city": "ARCTIC BAY",
      "state": "NU"
  },
  {
      "city": "ARDATH",
      "state": "SK"
  },
  {
      "city": "ARDEN",
      "state": "MB"
  },
  {
      "city": "ARDEN",
      "state": "ON"
  },
  {
      "city": "ARDILL",
      "state": "SK"
  },
  {
      "city": "ARDMORE",
      "state": "AB"
  },
  {
      "city": "ARDOCH",
      "state": "ON"
  },
  {
      "city": "ARDROSSAN",
      "state": "AB"
  },
  {
      "city": "ARELEE",
      "state": "SK"
  },
  {
      "city": "ARGENTA",
      "state": "BC"
  },
  {
      "city": "ARGYLE",
      "state": "MB"
  },
  {
      "city": "ARICHAT",
      "state": "NS"
  },
  {
      "city": "ARISS",
      "state": "ON"
  },
  {
      "city": "ARKELL",
      "state": "ON"
  },
  {
      "city": "ARKONA",
      "state": "ON"
  },
  {
      "city": "ARMAGH",
      "state": "QC"
  },
  {
      "city": "ARMENA",
      "state": "AB"
  },
  {
      "city": "ARMOND",
      "state": "NB"
  },
  {
      "city": "ARMSTRONG STATION",
      "state": "ON"
  },
  {
      "city": "ARMSTRONG",
      "state": "BC"
  },
  {
      "city": "ARNAUD",
      "state": "MB"
  },
  {
      "city": "ARNES",
      "state": "MB"
  },
  {
      "city": "ARNOLDS COVE",
      "state": "NL"
  },
  {
      "city": "ARNPRIOR",
      "state": "ON"
  },
  {
      "city": "ARNSTEIN",
      "state": "ON"
  },
  {
      "city": "ARNTFIELD",
      "state": "QC"
  },
  {
      "city": "AROLAND",
      "state": "ON"
  },
  {
      "city": "AROOSTOOK JUNCTION",
      "state": "NB"
  },
  {
      "city": "AROOSTOOK",
      "state": "NB"
  },
  {
      "city": "ARRAN",
      "state": "SK"
  },
  {
      "city": "ARRAS",
      "state": "BC"
  },
  {
      "city": "ARROW RIVER",
      "state": "MB"
  },
  {
      "city": "ARROWWOOD",
      "state": "AB"
  },
  {
      "city": "ARTHUR",
      "state": "ON"
  },
  {
      "city": "ARTHURETTE",
      "state": "NB"
  },
  {
      "city": "ARUNDEL",
      "state": "QC"
  },
  {
      "city": "ARVA",
      "state": "ON"
  },
  {
      "city": "ARVIAT",
      "state": "NU"
  },
  {
      "city": "ASBESTOS",
      "state": "QC"
  },
  {
      "city": "ASCOT CORNER",
      "state": "QC"
  },
  {
      "city": "ASHBURN",
      "state": "ON"
  },
  {
      "city": "ASHCROFT",
      "state": "BC"
  },
  {
      "city": "ASHERN",
      "state": "MB"
  },
  {
      "city": "ASHLAND",
      "state": "NB"
  },
  {
      "city": "ASHMONT",
      "state": "AB"
  },
  {
      "city": "ASHTON",
      "state": "ON"
  },
  {
      "city": "ASHVILLE",
      "state": "MB"
  },
  {
      "city": "ASKILTON",
      "state": "NS"
  },
  {
      "city": "ASPEN COVE",
      "state": "NL"
  },
  {
      "city": "ASPEN",
      "state": "NS"
  },
  {
      "city": "ASQUITH",
      "state": "SK"
  },
  {
      "city": "ASSINIBOIA",
      "state": "SK"
  },
  {
      "city": "ASTLE",
      "state": "NB"
  },
  {
      "city": "ASTON-JONCTION",
      "state": "QC"
  },
  {
      "city": "ASTORVILLE",
      "state": "ON"
  },
  {
      "city": "ASTRA",
      "state": "ON"
  },
  {
      "city": "ATHABASCA",
      "state": "AB"
  },
  {
      "city": "ATHALMER",
      "state": "BC"
  },
  {
      "city": "ATHELSTAN",
      "state": "QC"
  },
  {
      "city": "ATHENS",
      "state": "ON"
  },
  {
      "city": "ATHOLVILLE",
      "state": "NB"
  },
  {
      "city": "ATIKAMEG",
      "state": "AB"
  },
  {
      "city": "ATIKOKAN",
      "state": "ON"
  },
  {
      "city": "ATLIN",
      "state": "BC"
  },
  {
      "city": "ATMORE",
      "state": "AB"
  },
  {
      "city": "ATTAWAPISKAT",
      "state": "ON"
  },
  {
      "city": "ATWATER",
      "state": "SK"
  },
  {
      "city": "ATWOOD",
      "state": "ON"
  },
  {
      "city": "AUBIGNY",
      "state": "MB"
  },
  {
      "city": "AUBURN",
      "state": "NS"
  },
  {
      "city": "AUBURN",
      "state": "ON"
  },
  {
      "city": "AUBURNDALE",
      "state": "NS"
  },
  {
      "city": "AUCLAIR",
      "state": "QC"
  },
  {
      "city": "AUDET",
      "state": "QC"
  },
  {
      "city": "AULAC",
      "state": "NB"
  },
  {
      "city": "AUMOND",
      "state": "QC"
  },
  {
      "city": "AUPALUK",
      "state": "QC"
  },
  {
      "city": "AURORA",
      "state": "ON"
  },
  {
      "city": "AUSTIN",
      "state": "MB"
  },
  {
      "city": "AUSTIN",
      "state": "QC"
  },
  {
      "city": "AUTHIER",
      "state": "QC"
  },
  {
      "city": "AUTHIER-NORD",
      "state": "QC"
  },
  {
      "city": "AVOLA",
      "state": "BC"
  },
  {
      "city": "AVONDALE",
      "state": "NB"
  },
  {
      "city": "AVONDALE",
      "state": "NL"
  },
  {
      "city": "AVONHURST",
      "state": "SK"
  },
  {
      "city": "AVONLEA",
      "state": "SK"
  },
  {
      "city": "AVONMORE",
      "state": "ON"
  },
  {
      "city": "AVONPORT",
      "state": "NS"
  },
  {
      "city": "AYER'S CLIFF",
      "state": "QC"
  },
  {
      "city": "AYLESBURY",
      "state": "SK"
  },
  {
      "city": "AYLESFORD",
      "state": "NS"
  },
  {
      "city": "AYLMER",
      "state": "ON"
  },
  {
      "city": "AYLSHAM",
      "state": "SK"
  },
  {
      "city": "AYR",
      "state": "ON"
  },
  {
      "city": "AYTON",
      "state": "ON"
  },
  {
      "city": "AZILDA",
      "state": "ON"
  },
  {
      "city": "BACCARO",
      "state": "NS"
  },
  {
      "city": "BACK BAY",
      "state": "NB"
  },
  {
      "city": "BADDECK",
      "state": "NS"
  },
  {
      "city": "BADEN",
      "state": "ON"
  },
  {
      "city": "BADGER",
      "state": "NL"
  },
  {
      "city": "BADGERS QUAY",
      "state": "NL"
  },
  {
      "city": "BADJEROS",
      "state": "ON"
  },
  {
      "city": "BAGOT",
      "state": "MB"
  },
  {
      "city": "BAIE DE BOUCTOUCHE",
      "state": "NB"
  },
  {
      "city": "BAIE DE PETIT-POKEMOUCHE",
      "state": "NB"
  },
  {
      "city": "BAIE VERTE",
      "state": "NB"
  },
  {
      "city": "BAIE VERTE",
      "state": "NL"
  },
  {
      "city": "BAIE-COMEAU",
      "state": "QC"
  },
  {
      "city": "BAIE-D'URFE",
      "state": "QC"
  },
  {
      "city": "BAIE-DES-SABLES",
      "state": "QC"
  },
  {
      "city": "BAIE-DU-FEBVRE",
      "state": "QC"
  },
  {
      "city": "BAIE-JOHAN-BEETZ",
      "state": "QC"
  },
  {
      "city": "BAIE-SAINT-PAUL",
      "state": "QC"
  },
  {
      "city": "BAIE-SAINTE-ANNE",
      "state": "NB"
  },
  {
      "city": "BAIE-SAINTE-CATHERINE",
      "state": "QC"
  },
  {
      "city": "BAIE-TRINITE",
      "state": "QC"
  },
  {
      "city": "BAILIEBORO",
      "state": "ON"
  },
  {
      "city": "BAILLIE",
      "state": "NB"
  },
  {
      "city": "BAINE HARBOUR",
      "state": "NL"
  },
  {
      "city": "BAINS CORNER",
      "state": "NB"
  },
  {
      "city": "BAINSVILLE",
      "state": "ON"
  },
  {
      "city": "BAIRDSVILLE",
      "state": "NB"
  },
  {
      "city": "BAKER BROOK",
      "state": "NB"
  },
  {
      "city": "BAKER LAKE",
      "state": "NU"
  },
  {
      "city": "BAKER SETTLEMENT",
      "state": "NS"
  },
  {
      "city": "BALA",
      "state": "ON"
  },
  {
      "city": "BALCARRES",
      "state": "SK"
  },
  {
      "city": "BALD ROCK",
      "state": "NS"
  },
  {
      "city": "BALDERSON",
      "state": "ON"
  },
  {
      "city": "BALDONNEL",
      "state": "BC"
  },
  {
      "city": "BALDUR",
      "state": "MB"
  },
  {
      "city": "BALDWIN",
      "state": "ON"
  },
  {
      "city": "BALDWINTON",
      "state": "SK"
  },
  {
      "city": "BALFOUR",
      "state": "BC"
  },
  {
      "city": "BALGONIE",
      "state": "SK"
  },
  {
      "city": "BALLA PHILIP",
      "state": "NB"
  },
  {
      "city": "BALLINAFAD",
      "state": "ON"
  },
  {
      "city": "BALLS CREEK",
      "state": "NS"
  },
  {
      "city": "BALMERTOWN",
      "state": "ON"
  },
  {
      "city": "BALMORAL EST",
      "state": "NB"
  },
  {
      "city": "BALMORAL SUD",
      "state": "NB"
  },
  {
      "city": "BALMORAL",
      "state": "MB"
  },
  {
      "city": "BALMORAL",
      "state": "NB"
  },
  {
      "city": "BALTIMORE",
      "state": "NB"
  },
  {
      "city": "BALTIMORE",
      "state": "ON"
  },
  {
      "city": "BALZAC",
      "state": "AB"
  },
  {
      "city": "BAMFIELD",
      "state": "BC"
  },
  {
      "city": "BANCROFT",
      "state": "ON"
  },
  {
      "city": "BANFF",
      "state": "AB"
  },
  {
      "city": "BANGOR",
      "state": "SK"
  },
  {
      "city": "BANKEND",
      "state": "SK"
  },
  {
      "city": "BANNON",
      "state": "NB"
  },
  {
      "city": "BARACHOIS BROOK",
      "state": "NL"
  },
  {
      "city": "BARACHOIS",
      "state": "QC"
  },
  {
      "city": "BARKERVILLE",
      "state": "BC"
  },
  {
      "city": "BARKMERE",
      "state": "QC"
  },
  {
      "city": "BARNABY",
      "state": "NB"
  },
  {
      "city": "BARNESVILLE",
      "state": "NB"
  },
  {
      "city": "BARNETTVILLE",
      "state": "NB"
  },
  {
      "city": "BARNEYS RIVER STATION",
      "state": "NS"
  },
  {
      "city": "BARNWELL",
      "state": "AB"
  },
  {
      "city": "BARONS",
      "state": "AB"
  },
  {
      "city": "BARONY",
      "state": "NB"
  },
  {
      "city": "BARR SETTLEMENT",
      "state": "NS"
  },
  {
      "city": "BARRA GLEN",
      "state": "NS"
  },
  {
      "city": "BARRACHOIS",
      "state": "NS"
  },
  {
      "city": "BARRAUTE",
      "state": "QC"
  },
  {
      "city": "BARRHEAD",
      "state": "AB"
  },
  {
      "city": "BARRIE",
      "state": "ON"
  },
  {
      "city": "BARRIERE",
      "state": "BC"
  },
  {
      "city": "BARRINGTON PASSAGE",
      "state": "NS"
  },
  {
      "city": "BARRINGTON",
      "state": "NS"
  },
  {
      "city": "BARROWS",
      "state": "MB"
  },
  {
      "city": "BARRYS BAY",
      "state": "ON"
  },
  {
      "city": "BARRYVILLE",
      "state": "NB"
  },
  {
      "city": "BARSS CORNER",
      "state": "NS"
  },
  {
      "city": "BARTER SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "BARTHEL",
      "state": "SK"
  },
  {
      "city": "BARTHOLOMEW",
      "state": "NB"
  },
  {
      "city": "BARTIBOG BRIDGE",
      "state": "NB"
  },
  {
      "city": "BARTIBOG",
      "state": "NB"
  },
  {
      "city": "BARTLETTS HARBOUR",
      "state": "NL"
  },
  {
      "city": "BARTLETTS MILLS",
      "state": "NB"
  },
  {
      "city": "BARTON",
      "state": "NS"
  },
  {
      "city": "BARWICK",
      "state": "ON"
  },
  {
      "city": "BAS-CAP-PELE",
      "state": "NB"
  },
  {
      "city": "BAS-CARAQUET",
      "state": "NB"
  },
  {
      "city": "BAS-PAQUETVILLE",
      "state": "NB"
  },
  {
      "city": "BASHAW",
      "state": "AB"
  },
  {
      "city": "BASS RIVER",
      "state": "NB"
  },
  {
      "city": "BASS RIVER",
      "state": "NS"
  },
  {
      "city": "BASSANO",
      "state": "AB"
  },
  {
      "city": "BASSIN",
      "state": "QC"
  },
  {
      "city": "BASSWOOD RIDGE",
      "state": "NB"
  },
  {
      "city": "BATAWA",
      "state": "ON"
  },
  {
      "city": "BATCHAWANA BAY",
      "state": "ON"
  },
  {
      "city": "BATEMAN",
      "state": "SK"
  },
  {
      "city": "BATESTON",
      "state": "NS"
  },
  {
      "city": "BATH",
      "state": "NB"
  },
  {
      "city": "BATH",
      "state": "ON"
  },
  {
      "city": "BATHURST",
      "state": "NB"
  },
  {
      "city": "BATISCAN",
      "state": "QC"
  },
  {
      "city": "BATTERSEA",
      "state": "ON"
  },
  {
      "city": "BATTLEFORD",
      "state": "SK"
  },
  {
      "city": "BAULINE",
      "state": "NL"
  },
  {
      "city": "BAWLF",
      "state": "AB"
  },
  {
      "city": "BAXTERS CORNER",
      "state": "NB"
  },
  {
      "city": "BAY BULLS",
      "state": "NL"
  },
  {
      "city": "BAY CHIMO",
      "state": "NU"
  },
  {
      "city": "BAY DE VERDE",
      "state": "NL"
  },
  {
      "city": "BAY DU VIN",
      "state": "NB"
  },
  {
      "city": "BAY L'ARGENT",
      "state": "NL"
  },
  {
      "city": "BAY ROBERTS",
      "state": "NL"
  },
  {
      "city": "BAY TREE",
      "state": "AB"
  },
  {
      "city": "BAY VIEW",
      "state": "NB"
  },
  {
      "city": "BAYFIELD",
      "state": "NB"
  },
  {
      "city": "BAYFIELD",
      "state": "ON"
  },
  {
      "city": "BAYSIDE",
      "state": "NB"
  },
  {
      "city": "BAYSIDE",
      "state": "NS"
  },
  {
      "city": "BAYSVILLE",
      "state": "ON"
  },
  {
      "city": "BAYSWATER",
      "state": "NB"
  },
  {
      "city": "BAYTONA",
      "state": "NL"
  },
  {
      "city": "BEACHBURG",
      "state": "ON"
  },
  {
      "city": "BEACHSIDE",
      "state": "NL"
  },
  {
      "city": "BEACHVILLE",
      "state": "ON"
  },
  {
      "city": "BEACONIA",
      "state": "MB"
  },
  {
      "city": "BEACONSFIELD",
      "state": "NB"
  },
  {
      "city": "BEACONSFIELD",
      "state": "QC"
  },
  {
      "city": "BEAMSVILLE",
      "state": "ON"
  },
  {
      "city": "BEAR CANYON",
      "state": "AB"
  },
  {
      "city": "BEAR COVE",
      "state": "NS"
  },
  {
      "city": "BEAR ISLAND",
      "state": "NB"
  },
  {
      "city": "BEAR ISLAND",
      "state": "ON"
  },
  {
      "city": "BEAR LAKE",
      "state": "BC"
  },
  {
      "city": "BEAR RIVER",
      "state": "NS"
  },
  {
      "city": "BEARDMORE",
      "state": "ON"
  },
  {
      "city": "BEARDSLEY",
      "state": "NB"
  },
  {
      "city": "BEARN",
      "state": "QC"
  },
  {
      "city": "BEARSKIN LAKE",
      "state": "ON"
  },
  {
      "city": "BEATTY",
      "state": "SK"
  },
  {
      "city": "BEAUBIER",
      "state": "SK"
  },
  {
      "city": "BEAUCANTON",
      "state": "QC"
  },
  {
      "city": "BEAUCEVILLE",
      "state": "QC"
  },
  {
      "city": "BEAUHARNOIS",
      "state": "QC"
  },
  {
      "city": "BEAULAC-GARTHBY",
      "state": "QC"
  },
  {
      "city": "BEAUMONT",
      "state": "AB"
  },
  {
      "city": "BEAUMONT",
      "state": "NL"
  },
  {
      "city": "BEAUMONT",
      "state": "QC"
  },
  {
      "city": "BEAUPRE",
      "state": "QC"
  },
  {
      "city": "BEAUSEJOUR",
      "state": "MB"
  },
  {
      "city": "BEAUVAL",
      "state": "SK"
  },
  {
      "city": "BEAUVALLON",
      "state": "AB"
  },
  {
      "city": "BEAVER BANK",
      "state": "NS"
  },
  {
      "city": "BEAVER BROOK",
      "state": "NS"
  },
  {
      "city": "BEAVER COVE",
      "state": "NS"
  },
  {
      "city": "BEAVER CREEK",
      "state": "SK"
  },
  {
      "city": "BEAVER CREEK",
      "state": "YT"
  },
  {
      "city": "BEAVER DAM",
      "state": "NB"
  },
  {
      "city": "BEAVER HARBOUR",
      "state": "NB"
  },
  {
      "city": "BEAVERBROOK ALBERT CO",
      "state": "NB"
  },
  {
      "city": "BEAVERBROOK",
      "state": "NB"
  },
  {
      "city": "BEAVERDELL",
      "state": "BC"
  },
  {
      "city": "BEAVERLODGE",
      "state": "AB"
  },
  {
      "city": "BEAVERTON",
      "state": "ON"
  },
  {
      "city": "BECANCOUR",
      "state": "QC"
  },
  {
      "city": "BECKIM SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "BEDELL",
      "state": "NB"
  },
  {
      "city": "BEDEQUE",
      "state": "PE"
  },
  {
      "city": "BEDFORD",
      "state": "NS"
  },
  {
      "city": "BEDFORD",
      "state": "QC"
  },
  {
      "city": "BEECHMONT",
      "state": "NS"
  },
  {
      "city": "BEECHVILLE",
      "state": "NS"
  },
  {
      "city": "BEECHWOOD",
      "state": "NB"
  },
  {
      "city": "BEECHY",
      "state": "SK"
  },
  {
      "city": "BEERSVILLE",
      "state": "NB"
  },
  {
      "city": "BEETON",
      "state": "ON"
  },
  {
      "city": "BEGIN",
      "state": "QC"
  },
  {
      "city": "BEHCHOKO",
      "state": "NT"
  },
  {
      "city": "BEISEKER",
      "state": "AB"
  },
  {
      "city": "BELAIR",
      "state": "MB"
  },
  {
      "city": "BELCARRA",
      "state": "BC"
  },
  {
      "city": "BELCOURT",
      "state": "QC"
  },
  {
      "city": "BELFAST",
      "state": "PE"
  },
  {
      "city": "BELFOUNTAIN",
      "state": "ON"
  },
  {
      "city": "BELGRAVE",
      "state": "ON"
  },
  {
      "city": "BELL ISLAND FRONT",
      "state": "NL"
  },
  {
      "city": "BELL ISLAND",
      "state": "NL"
  },
  {
      "city": "BELLA BELLA",
      "state": "BC"
  },
  {
      "city": "BELLA COOLA",
      "state": "BC"
  },
  {
      "city": "BELLBURNS",
      "state": "NL"
  },
  {
      "city": "BELLE COTE",
      "state": "NS"
  },
  {
      "city": "BELLE EWART",
      "state": "ON"
  },
  {
      "city": "BELLE PLAINE",
      "state": "SK"
  },
  {
      "city": "BELLE RIVER",
      "state": "ON"
  },
  {
      "city": "BELLE RIVER",
      "state": "PE"
  },
  {
      "city": "BELLE VALLEE",
      "state": "ON"
  },
  {
      "city": "BELLECOMBE",
      "state": "QC"
  },
  {
      "city": "BELLEDUNE",
      "state": "NB"
  },
  {
      "city": "BELLEFOND",
      "state": "NB"
  },
  {
      "city": "BELLEGARDE",
      "state": "SK"
  },
  {
      "city": "BELLEISLE CREEK",
      "state": "NB"
  },
  {
      "city": "BELLEORAM",
      "state": "NL"
  },
  {
      "city": "BELLETERRE",
      "state": "QC"
  },
  {
      "city": "BELLEVIEW",
      "state": "MB"
  },
  {
      "city": "BELLEVILLE",
      "state": "NB"
  },
  {
      "city": "BELLEVILLE",
      "state": "ON"
  },
  {
      "city": "BELLEVUE",
      "state": "AB"
  },
  {
      "city": "BELLEVUE",
      "state": "NL"
  },
  {
      "city": "BELLIS",
      "state": "AB"
  },
  {
      "city": "BELLIVEAU COVE",
      "state": "NS"
  },
  {
      "city": "BELMONT",
      "state": "MB"
  },
  {
      "city": "BELMONT",
      "state": "NS"
  },
  {
      "city": "BELMONT",
      "state": "ON"
  },
  {
      "city": "BELNAN",
      "state": "NS"
  },
  {
      "city": "BELOEIL",
      "state": "QC"
  },
  {
      "city": "BELWOOD",
      "state": "ON"
  },
  {
      "city": "BEN EOIN",
      "state": "NS"
  },
  {
      "city": "BENACADIE",
      "state": "NS"
  },
  {
      "city": "BENALTO",
      "state": "AB"
  },
  {
      "city": "BENGOUGH",
      "state": "SK"
  },
  {
      "city": "BENITO",
      "state": "MB"
  },
  {
      "city": "BENJAMIN RIVER",
      "state": "NB"
  },
  {
      "city": "BENOIT",
      "state": "NB"
  },
  {
      "city": "BENOITS COVE",
      "state": "NL"
  },
  {
      "city": "BENSON",
      "state": "SK"
  },
  {
      "city": "BENTLEY",
      "state": "AB"
  },
  {
      "city": "BENTON",
      "state": "NB"
  },
  {
      "city": "BENTON",
      "state": "NL"
  },
  {
      "city": "BERENS RIVER",
      "state": "MB"
  },
  {
      "city": "BERESFORD",
      "state": "NB"
  },
  {
      "city": "BERKELEY",
      "state": "ON"
  },
  {
      "city": "BERRY MILLS",
      "state": "NB"
  },
  {
      "city": "BERRY",
      "state": "QC"
  },
  {
      "city": "BERRYTON",
      "state": "NB"
  },
  {
      "city": "BERTHIER-SUR-MER",
      "state": "QC"
  },
  {
      "city": "BERTHIERVILLE",
      "state": "QC"
  },
  {
      "city": "BERTRAND",
      "state": "NB"
  },
  {
      "city": "BERWICK",
      "state": "NB"
  },
  {
      "city": "BERWICK",
      "state": "NS"
  },
  {
      "city": "BERWICK",
      "state": "ON"
  },
  {
      "city": "BERWYN",
      "state": "AB"
  },
  {
      "city": "BETHANIE",
      "state": "QC"
  },
  {
      "city": "BETHANY",
      "state": "MB"
  },
  {
      "city": "BETHANY",
      "state": "ON"
  },
  {
      "city": "BETHEL",
      "state": "NB"
  },
  {
      "city": "BETHUNE",
      "state": "SK"
  },
  {
      "city": "BETSIAMITES",
      "state": "QC"
  },
  {
      "city": "BETTSBURG",
      "state": "NB"
  },
  {
      "city": "BEULAH",
      "state": "MB"
  },
  {
      "city": "BEWDLEY",
      "state": "ON"
  },
  {
      "city": "BEZANSON",
      "state": "AB"
  },
  {
      "city": "BIBLE HILL",
      "state": "NS"
  },
  {
      "city": "BICKERTON WEST",
      "state": "NS"
  },
  {
      "city": "BIDE ARM",
      "state": "NL"
  },
  {
      "city": "BIENCOURT",
      "state": "QC"
  },
  {
      "city": "BIENFAIT",
      "state": "SK"
  },
  {
      "city": "BIG BEACH",
      "state": "NS"
  },
  {
      "city": "BIG BEAVER",
      "state": "SK"
  },
  {
      "city": "BIG BRAS D'OR",
      "state": "NS"
  },
  {
      "city": "BIG COVE QUEENS CO",
      "state": "NB"
  },
  {
      "city": "BIG HOLE",
      "state": "NB"
  },
  {
      "city": "BIG LAKE RANCH",
      "state": "BC"
  },
  {
      "city": "BIG LAKE",
      "state": "NS"
  },
  {
      "city": "BIG POND CENTRE",
      "state": "NS"
  },
  {
      "city": "BIG POND",
      "state": "NS"
  },
  {
      "city": "BIG RIDGE",
      "state": "NS"
  },
  {
      "city": "BIG RIVER",
      "state": "NB"
  },
  {
      "city": "BIG RIVER",
      "state": "SK"
  },
  {
      "city": "BIG STONE",
      "state": "AB"
  },
  {
      "city": "BIG TROUT LAKE",
      "state": "ON"
  },
  {
      "city": "BIG VALLEY",
      "state": "AB"
  },
  {
      "city": "BIGGAR",
      "state": "SK"
  },
  {
      "city": "BINBROOK",
      "state": "ON"
  },
  {
      "city": "BINDLOSS",
      "state": "AB"
  },
  {
      "city": "BINSCARTH",
      "state": "MB"
  },
  {
      "city": "BIRCH GROVE",
      "state": "NS"
  },
  {
      "city": "BIRCH HILL",
      "state": "NS"
  },
  {
      "city": "BIRCH HILLS",
      "state": "SK"
  },
  {
      "city": "BIRCH ISLAND",
      "state": "ON"
  },
  {
      "city": "BIRCH RIDGE",
      "state": "NB"
  },
  {
      "city": "BIRCH RIVER",
      "state": "MB"
  },
  {
      "city": "BIRCHCLIFF",
      "state": "AB"
  },
  {
      "city": "BIRCHY BAY",
      "state": "NL"
  },
  {
      "city": "BIRCHY HEAD",
      "state": "NL"
  },
  {
      "city": "BIRD COVE",
      "state": "NL"
  },
  {
      "city": "BIRDTON",
      "state": "NB"
  },
  {
      "city": "BIRNIE",
      "state": "MB"
  },
  {
      "city": "BIRSAY",
      "state": "SK"
  },
  {
      "city": "BIRTLE",
      "state": "MB"
  },
  {
      "city": "BISCOTASING",
      "state": "ON"
  },
  {
      "city": "BISHOPS FALLS",
      "state": "NL"
  },
  {
      "city": "BISHOPTON",
      "state": "QC"
  },
  {
      "city": "BISSETT CREEK",
      "state": "ON"
  },
  {
      "city": "BISSETT",
      "state": "MB"
  },
  {
      "city": "BITTERN LAKE",
      "state": "AB"
  },
  {
      "city": "BJORKDALE",
      "state": "SK"
  },
  {
      "city": "BLACK BROOK",
      "state": "NS"
  },
  {
      "city": "BLACK CREEK",
      "state": "BC"
  },
  {
      "city": "BLACK DIAMOND",
      "state": "AB"
  },
  {
      "city": "BLACK DUCK COVE",
      "state": "NL"
  },
  {
      "city": "BLACK DUCK SIDING",
      "state": "NL"
  },
  {
      "city": "BLACK LAKE",
      "state": "SK"
  },
  {
      "city": "BLACK POINT",
      "state": "NB"
  },
  {
      "city": "BLACK POINT",
      "state": "NS"
  },
  {
      "city": "BLACK RIVER BRIDGE",
      "state": "NB"
  },
  {
      "city": "BLACK RIVER",
      "state": "NB"
  },
  {
      "city": "BLACK ROCK",
      "state": "NB"
  },
  {
      "city": "BLACK ROCK",
      "state": "NS"
  },
  {
      "city": "BLACK TICKLE",
      "state": "NL"
  },
  {
      "city": "BLACKETTS LAKE",
      "state": "NS"
  },
  {
      "city": "BLACKFALDS",
      "state": "AB"
  },
  {
      "city": "BLACKFOOT",
      "state": "AB"
  },
  {
      "city": "BLACKIE",
      "state": "AB"
  },
  {
      "city": "BLACKLAND RESTIGOUCHE CO",
      "state": "NB"
  },
  {
      "city": "BLACKS HARBOUR",
      "state": "NB"
  },
  {
      "city": "BLACKSTOCK",
      "state": "ON"
  },
  {
      "city": "BLACKVILLE",
      "state": "NB"
  },
  {
      "city": "BLADWORTH",
      "state": "SK"
  },
  {
      "city": "BLAINE LAKE",
      "state": "SK"
  },
  {
      "city": "BLAINVILLE",
      "state": "QC"
  },
  {
      "city": "BLAIR ATHOL",
      "state": "NB"
  },
  {
      "city": "BLAIRMORE",
      "state": "AB"
  },
  {
      "city": "BLAKETOWN",
      "state": "NL"
  },
  {
      "city": "BLANC-SABLON",
      "state": "QC"
  },
  {
      "city": "BLANDFORD",
      "state": "NS"
  },
  {
      "city": "BLENHEIM",
      "state": "ON"
  },
  {
      "city": "BLEZARD VALLEY",
      "state": "ON"
  },
  {
      "city": "BLIND BAY",
      "state": "BC"
  },
  {
      "city": "BLIND BAY",
      "state": "NS"
  },
  {
      "city": "BLIND CHANNEL",
      "state": "BC"
  },
  {
      "city": "BLIND RIVER",
      "state": "ON"
  },
  {
      "city": "BLISSFIELD",
      "state": "NB"
  },
  {
      "city": "BLOCKHOUSE",
      "state": "NS"
  },
  {
      "city": "BLOODVEIN",
      "state": "MB"
  },
  {
      "city": "BLOOMFIELD CARLETON CO",
      "state": "NB"
  },
  {
      "city": "BLOOMFIELD KINGS CO",
      "state": "NB"
  },
  {
      "city": "BLOOMFIELD RIDGE",
      "state": "NB"
  },
  {
      "city": "BLOOMFIELD STATION",
      "state": "PE"
  },
  {
      "city": "BLOOMFIELD",
      "state": "NL"
  },
  {
      "city": "BLOOMFIELD",
      "state": "ON"
  },
  {
      "city": "BLOOMINGDALE",
      "state": "ON"
  },
  {
      "city": "BLOOMSBURY",
      "state": "AB"
  },
  {
      "city": "BLUBBER BAY",
      "state": "BC"
  },
  {
      "city": "BLUE BELL",
      "state": "NB"
  },
  {
      "city": "BLUE MOUNTAIN BEND",
      "state": "NB"
  },
  {
      "city": "BLUE MOUNTAINS",
      "state": "ON"
  },
  {
      "city": "BLUE RIDGE",
      "state": "AB"
  },
  {
      "city": "BLUE RIVER",
      "state": "BC"
  },
  {
      "city": "BLUE SEA",
      "state": "QC"
  },
  {
      "city": "BLUEBERRY MOUNTAIN",
      "state": "AB"
  },
  {
      "city": "BLUESKY",
      "state": "AB"
  },
  {
      "city": "BLUEVALE",
      "state": "ON"
  },
  {
      "city": "BLUFFTON",
      "state": "AB"
  },
  {
      "city": "BLUMENHOF",
      "state": "SK"
  },
  {
      "city": "BLUMENORT",
      "state": "MB"
  },
  {
      "city": "BLYTH",
      "state": "ON"
  },
  {
      "city": "BLYTHESWOOD",
      "state": "ON"
  },
  {
      "city": "BOAT HARBOUR WEST",
      "state": "NL"
  },
  {
      "city": "BOBCAYGEON",
      "state": "ON"
  },
  {
      "city": "BOCABEC",
      "state": "NB"
  },
  {
      "city": "BODO",
      "state": "AB"
  },
  {
      "city": "BOGGY CREEK",
      "state": "MB"
  },
  {
      "city": "BOGNOR",
      "state": "ON"
  },
  {
      "city": "BOIESTOWN",
      "state": "NB"
  },
  {
      "city": "BOILEAU",
      "state": "QC"
  },
  {
      "city": "BOIS-BLANC",
      "state": "NB"
  },
  {
      "city": "BOIS-DES-FILION",
      "state": "QC"
  },
  {
      "city": "BOIS-FRANC",
      "state": "QC"
  },
  {
      "city": "BOIS-GAGNON",
      "state": "NB"
  },
  {
      "city": "BOISBRIAND",
      "state": "QC"
  },
  {
      "city": "BOISCHATEL",
      "state": "QC"
  },
  {
      "city": "BOISDALE",
      "state": "NS"
  },
  {
      "city": "BOISSEVAIN",
      "state": "MB"
  },
  {
      "city": "BOLSOVER",
      "state": "ON"
  },
  {
      "city": "BOLTON",
      "state": "ON"
  },
  {
      "city": "BOLTON-EST",
      "state": "QC"
  },
  {
      "city": "BOLTON-OUEST",
      "state": "QC"
  },
  {
      "city": "BON ACCORD",
      "state": "AB"
  },
  {
      "city": "BON ACCORD",
      "state": "NB"
  },
  {
      "city": "BON-CONSEIL",
      "state": "QC"
  },
  {
      "city": "BONANZA",
      "state": "AB"
  },
  {
      "city": "BONAVENTURE",
      "state": "QC"
  },
  {
      "city": "BONAVISTA",
      "state": "NL"
  },
  {
      "city": "BOND HEAD",
      "state": "ON"
  },
  {
      "city": "BONFIELD",
      "state": "ON"
  },
  {
      "city": "BONNE BAY POND",
      "state": "NL"
  },
  {
      "city": "BONNE BAY",
      "state": "NL"
  },
  {
      "city": "BONNY RIVER",
      "state": "NB"
  },
  {
      "city": "BONNYVILLE",
      "state": "AB"
  },
  {
      "city": "BONSECOURS",
      "state": "QC"
  },
  {
      "city": "BONSHAW",
      "state": "PE"
  },
  {
      "city": "BOOM ROAD",
      "state": "NB"
  },
  {
      "city": "BORDEN",
      "state": "ON"
  },
  {
      "city": "BORDEN",
      "state": "SK"
  },
  {
      "city": "BORDEN-CARLETON",
      "state": "PE"
  },
  {
      "city": "BORNHOLM",
      "state": "ON"
  },
  {
      "city": "BOSTON BAR",
      "state": "BC"
  },
  {
      "city": "BOSWELL",
      "state": "BC"
  },
  {
      "city": "BOTHA",
      "state": "AB"
  },
  {
      "city": "BOTHWELL",
      "state": "ON"
  },
  {
      "city": "BOTWOOD",
      "state": "NL"
  },
  {
      "city": "BOUCHERVILLE",
      "state": "QC"
  },
  {
      "city": "BOUCHETTE",
      "state": "QC"
  },
  {
      "city": "BOUCTOUCHE COVE",
      "state": "NB"
  },
  {
      "city": "BOUCTOUCHE RESERVE",
      "state": "NB"
  },
  {
      "city": "BOUCTOUCHE SUD",
      "state": "NB"
  },
  {
      "city": "BOUCTOUCHE",
      "state": "NB"
  },
  {
      "city": "BOUDREAU-OUEST",
      "state": "NB"
  },
  {
      "city": "BOULANGER",
      "state": "QC"
  },
  {
      "city": "BOULARDERIE CENTER",
      "state": "NS"
  },
  {
      "city": "BOULARDERIE EAST",
      "state": "NS"
  },
  {
      "city": "BOULTER",
      "state": "ON"
  },
  {
      "city": "BOUNDARY CREEK",
      "state": "NB"
  },
  {
      "city": "BOURGET",
      "state": "ON"
  },
  {
      "city": "BOUTILIERS POINT",
      "state": "NS"
  },
  {
      "city": "BOW ISLAND",
      "state": "AB"
  },
  {
      "city": "BOWDEN",
      "state": "AB"
  },
  {
      "city": "BOWEN ISLAND",
      "state": "BC"
  },
  {
      "city": "BOWMAN",
      "state": "QC"
  },
  {
      "city": "BOWMANVILLE",
      "state": "ON"
  },
  {
      "city": "BOWSER",
      "state": "BC"
  },
  {
      "city": "BOWSMAN",
      "state": "MB"
  },
  {
      "city": "BOYDS COVE",
      "state": "NL"
  },
  {
      "city": "BOYLE",
      "state": "AB"
  },
  {
      "city": "BOYLSTON",
      "state": "NS"
  },
  {
      "city": "BOYNE LAKE",
      "state": "AB"
  },
  {
      "city": "BRACEBRIDGE",
      "state": "ON"
  },
  {
      "city": "BRACKEN",
      "state": "SK"
  },
  {
      "city": "BRACKENDALE",
      "state": "BC"
  },
  {
      "city": "BRADFORD",
      "state": "ON"
  },
  {
      "city": "BRADOR",
      "state": "QC"
  },
  {
      "city": "BRADWARDINE",
      "state": "MB"
  },
  {
      "city": "BRADWELL",
      "state": "SK"
  },
  {
      "city": "BRAESIDE",
      "state": "ON"
  },
  {
      "city": "BRAGG CREEK",
      "state": "AB"
  },
  {
      "city": "BRAMPTON",
      "state": "ON"
  },
  {
      "city": "BRANCH LAHAVE",
      "state": "NS"
  },
  {
      "city": "BRANCH",
      "state": "NL"
  },
  {
      "city": "BRANCHTON",
      "state": "ON"
  },
  {
      "city": "BRANDON",
      "state": "MB"
  },
  {
      "city": "BRANT",
      "state": "AB"
  },
  {
      "city": "BRANTFORD",
      "state": "ON"
  },
  {
      "city": "BRANTVILLE",
      "state": "NB"
  },
  {
      "city": "BRAS D'OR",
      "state": "NS"
  },
  {
      "city": "BREADALBANE",
      "state": "NB"
  },
  {
      "city": "BREADALBANE",
      "state": "PE"
  },
  {
      "city": "BREBEUF",
      "state": "QC"
  },
  {
      "city": "BRECHIN",
      "state": "ON"
  },
  {
      "city": "BREDENBURY",
      "state": "SK"
  },
  {
      "city": "BRENTS COVE",
      "state": "NL"
  },
  {
      "city": "BRENTWOOD BAY",
      "state": "BC"
  },
  {
      "city": "BRENTWOOD",
      "state": "NS"
  },
  {
      "city": "BRESLAU",
      "state": "ON"
  },
  {
      "city": "BRETON",
      "state": "AB"
  },
  {
      "city": "BREWERS MILL",
      "state": "NB"
  },
  {
      "city": "BREYNAT",
      "state": "AB"
  },
  {
      "city": "BRIDESVILLE",
      "state": "BC"
  },
  {
      "city": "BRIDGE LAKE",
      "state": "BC"
  },
  {
      "city": "BRIDGENORTH",
      "state": "ON"
  },
  {
      "city": "BRIDGEPORT",
      "state": "NL"
  },
  {
      "city": "BRIDGETOWN",
      "state": "NS"
  },
  {
      "city": "BRIDGEWATER",
      "state": "NS"
  },
  {
      "city": "BRIERCREST",
      "state": "SK"
  },
  {
      "city": "BRIGDEN",
      "state": "ON"
  },
  {
      "city": "BRIGGS CORNER QUEENS CO",
      "state": "NB"
  },
  {
      "city": "BRIGHAM",
      "state": "QC"
  },
  {
      "city": "BRIGHT SAND",
      "state": "SK"
  },
  {
      "city": "BRIGHT",
      "state": "ON"
  },
  {
      "city": "BRIGHT'S GROVE",
      "state": "ON"
  },
  {
      "city": "BRIGHTON",
      "state": "NL"
  },
  {
      "city": "BRIGHTON",
      "state": "ON"
  },
  {
      "city": "BRIGUS JUNCTION",
      "state": "NL"
  },
  {
      "city": "BRIGUS",
      "state": "NL"
  },
  {
      "city": "BRINSTON",
      "state": "ON"
  },
  {
      "city": "BRISCO",
      "state": "BC"
  },
  {
      "city": "BRISTOL JUNCTION",
      "state": "NB"
  },
  {
      "city": "BRISTOL",
      "state": "QC"
  },
  {
      "city": "BRITANNIA BEACH",
      "state": "BC"
  },
  {
      "city": "BRITISH SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "BRITT",
      "state": "ON"
  },
  {
      "city": "BROAD COVE BDV",
      "state": "NL"
  },
  {
      "city": "BROAD VALLEY",
      "state": "MB"
  },
  {
      "city": "BROADVIEW",
      "state": "SK"
  },
  {
      "city": "BROCHET",
      "state": "MB"
  },
  {
      "city": "BROCK",
      "state": "SK"
  },
  {
      "city": "BROCKET",
      "state": "AB"
  },
  {
      "city": "BROCKINGTON",
      "state": "SK"
  },
  {
      "city": "BROCKVILLE",
      "state": "ON"
  },
  {
      "city": "BROCKWAY",
      "state": "NB"
  },
  {
      "city": "BRODERICK",
      "state": "SK"
  },
  {
      "city": "BRODHAGEN",
      "state": "ON"
  },
  {
      "city": "BROME",
      "state": "QC"
  },
  {
      "city": "BROMHEAD",
      "state": "SK"
  },
  {
      "city": "BROMONT",
      "state": "QC"
  },
  {
      "city": "BRONSON SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "BROOKDALE",
      "state": "MB"
  },
  {
      "city": "BROOKFIELD",
      "state": "NL"
  },
  {
      "city": "BROOKFIELD",
      "state": "NS"
  },
  {
      "city": "BROOKLYN",
      "state": "NS"
  },
  {
      "city": "BROOKS",
      "state": "AB"
  },
  {
      "city": "BROOKSBY",
      "state": "SK"
  },
  {
      "city": "BROOKSIDE",
      "state": "NS"
  },
  {
      "city": "BROOKSIDE.",
      "state": "NS"
  },
  {
      "city": "BROOKVILLE",
      "state": "NB"
  },
  {
      "city": "BROSSARD",
      "state": "QC"
  },
  {
      "city": "BROSSEAU",
      "state": "AB"
  },
  {
      "city": "BROUGHAM",
      "state": "ON"
  },
  {
      "city": "BROUGHTON",
      "state": "NS"
  },
  {
      "city": "BROWNFIELD",
      "state": "AB"
  },
  {
      "city": "BROWNLEE",
      "state": "SK"
  },
  {
      "city": "BROWNS FLAT",
      "state": "NB"
  },
  {
      "city": "BROWNS YARD",
      "state": "NB"
  },
  {
      "city": "BROWNSBURG-CHATHAM",
      "state": "QC"
  },
  {
      "city": "BROWNSDALE",
      "state": "NL"
  },
  {
      "city": "BROWNSVILLE",
      "state": "ON"
  },
  {
      "city": "BROWNVALE",
      "state": "AB"
  },
  {
      "city": "BRUCE MINES",
      "state": "ON"
  },
  {
      "city": "BRUCE",
      "state": "AB"
  },
  {
      "city": "BRUCEFIELD",
      "state": "ON"
  },
  {
      "city": "BRUDERHEIM",
      "state": "AB"
  },
  {
      "city": "BRULE",
      "state": "AB"
  },
  {
      "city": "BRUNKILD",
      "state": "MB"
  },
  {
      "city": "BRUNNER",
      "state": "ON"
  },
  {
      "city": "BRUNO",
      "state": "SK"
  },
  {
      "city": "BRUNSWICK MINES",
      "state": "NB"
  },
  {
      "city": "BRUSSELS",
      "state": "ON"
  },
  {
      "city": "BRUXELLES",
      "state": "MB"
  },
  {
      "city": "BRYENTON",
      "state": "NB"
  },
  {
      "city": "BRYSON",
      "state": "QC"
  },
  {
      "city": "BUBARTOWN",
      "state": "NB"
  },
  {
      "city": "BUCHANAN",
      "state": "SK"
  },
  {
      "city": "BUCHANS JUNCTION",
      "state": "NL"
  },
  {
      "city": "BUCHANS",
      "state": "NL"
  },
  {
      "city": "BUCK CREEK",
      "state": "AB"
  },
  {
      "city": "BUCK LAKE",
      "state": "AB"
  },
  {
      "city": "BUCKFIELD",
      "state": "NS"
  },
  {
      "city": "BUCKHORN",
      "state": "ON"
  },
  {
      "city": "BUCKLAND",
      "state": "QC"
  },
  {
      "city": "BUENA VISTA",
      "state": "SK"
  },
  {
      "city": "BUFFALO CREEK",
      "state": "BC"
  },
  {
      "city": "BUFFALO HEAD PRAIRIE",
      "state": "AB"
  },
  {
      "city": "BUFFALO NARROWS",
      "state": "SK"
  },
  {
      "city": "BUFFALO POINT",
      "state": "MB"
  },
  {
      "city": "BUFFALO",
      "state": "AB"
  },
  {
      "city": "BUICK",
      "state": "BC"
  },
  {
      "city": "BULL LAKE",
      "state": "NB"
  },
  {
      "city": "BULLS CREEK",
      "state": "NB"
  },
  {
      "city": "BULYEA",
      "state": "SK"
  },
  {
      "city": "BUNYANS COVE",
      "state": "NL"
  },
  {
      "city": "BURDETT",
      "state": "AB"
  },
  {
      "city": "BURFORD",
      "state": "ON"
  },
  {
      "city": "BURGEO",
      "state": "NL"
  },
  {
      "city": "BURGESSVILLE",
      "state": "ON"
  },
  {
      "city": "BURGOYNES COVE",
      "state": "NL"
  },
  {
      "city": "BURIN BAY ARM",
      "state": "NL"
  },
  {
      "city": "BURIN",
      "state": "NL"
  },
  {
      "city": "BURKS FALLS",
      "state": "ON"
  },
  {
      "city": "BURLINGTON",
      "state": "NL"
  },
  {
      "city": "BURLINGTON",
      "state": "ON"
  },
  {
      "city": "BURNABY",
      "state": "BC"
  },
  {
      "city": "BURNS LAKE",
      "state": "BC"
  },
  {
      "city": "BURNSIDE",
      "state": "NL"
  },
  {
      "city": "BURNSTOWN",
      "state": "ON"
  },
  {
      "city": "BURNSVILLE",
      "state": "NB"
  },
  {
      "city": "BURNT CHURCH FIRST NATION",
      "state": "NB"
  },
  {
      "city": "BURNT CHURCH",
      "state": "NB"
  },
  {
      "city": "BURNT HILL",
      "state": "NB"
  },
  {
      "city": "BURNT ISLANDS BLP",
      "state": "NL"
  },
  {
      "city": "BURNT POINT BDV",
      "state": "NL"
  },
  {
      "city": "BURNT RIVER",
      "state": "ON"
  },
  {
      "city": "BURNTLAND BROOK",
      "state": "NB"
  },
  {
      "city": "BURPEE",
      "state": "NB"
  },
  {
      "city": "BURR",
      "state": "SK"
  },
  {
      "city": "BURRITTS RAPIDS",
      "state": "ON"
  },
  {
      "city": "BURSTALL",
      "state": "SK"
  },
  {
      "city": "BURTON",
      "state": "BC"
  },
  {
      "city": "BURTON",
      "state": "NB"
  },
  {
      "city": "BURTTS CORNER",
      "state": "NB"
  },
  {
      "city": "BURWASH LANDING",
      "state": "YT"
  },
  {
      "city": "BURY",
      "state": "QC"
  },
  {
      "city": "BUSBY",
      "state": "AB"
  },
  {
      "city": "BUSHELL PARK",
      "state": "SK"
  },
  {
      "city": "BYEMOOR",
      "state": "AB"
  },
  {
      "city": "BYNG INLET",
      "state": "ON"
  },
  {
      "city": "CABANO",
      "state": "QC"
  },
  {
      "city": "CABRI",
      "state": "SK"
  },
  {
      "city": "CACHE BAY",
      "state": "ON"
  },
  {
      "city": "CACHE CREEK",
      "state": "BC"
  },
  {
      "city": "CACOUNA",
      "state": "QC"
  },
  {
      "city": "CACTUS LAKE",
      "state": "SK"
  },
  {
      "city": "CADILLAC",
      "state": "QC"
  },
  {
      "city": "CADILLAC",
      "state": "SK"
  },
  {
      "city": "CADOGAN",
      "state": "AB"
  },
  {
      "city": "CADOMIN",
      "state": "AB"
  },
  {
      "city": "CADOTTE LAKE",
      "state": "AB"
  },
  {
      "city": "CAESAREA",
      "state": "ON"
  },
  {
      "city": "CAILS MILLS",
      "state": "NB"
  },
  {
      "city": "CAINS RIVER",
      "state": "NB"
  },
  {
      "city": "CAISSIE ROAD",
      "state": "NB"
  },
  {
      "city": "CAISTOR CENTRE",
      "state": "ON"
  },
  {
      "city": "CAITHNESS",
      "state": "NB"
  },
  {
      "city": "CALABOGIE",
      "state": "ON"
  },
  {
      "city": "CALAHOO",
      "state": "AB"
  },
  {
      "city": "CALAIS",
      "state": "AB"
  },
  {
      "city": "CALDER",
      "state": "SK"
  },
  {
      "city": "CALDERS HEAD",
      "state": "NB"
  },
  {
      "city": "CALEDON EAST",
      "state": "ON"
  },
  {
      "city": "CALEDON VILLAGE",
      "state": "ON"
  },
  {
      "city": "CALEDON",
      "state": "ON"
  },
  {
      "city": "CALEDONIA MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "CALEDONIA",
      "state": "NS"
  },
  {
      "city": "CALEDONIA",
      "state": "ON"
  },
  {
      "city": "CALGARY",
      "state": "AB"
  },
  {
      "city": "CALHOUN",
      "state": "NB"
  },
  {
      "city": "CALIFORNIA SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "CALIXA-LAVALLEE",
      "state": "QC"
  },
  {
      "city": "CALLANDER",
      "state": "ON"
  },
  {
      "city": "CALLING LAKE",
      "state": "AB"
  },
  {
      "city": "CALMAR",
      "state": "AB"
  },
  {
      "city": "CALVERT",
      "state": "NL"
  },
  {
      "city": "CAMBRAY",
      "state": "ON"
  },
  {
      "city": "CAMBRIDGE BAY",
      "state": "NU"
  },
  {
      "city": "CAMBRIDGE",
      "state": "NS"
  },
  {
      "city": "CAMBRIDGE",
      "state": "ON"
  },
  {
      "city": "CAMBRIDGE-NARROWS",
      "state": "NB"
  },
  {
      "city": "CAMDEN EAST",
      "state": "ON"
  },
  {
      "city": "CAMDEN",
      "state": "NS"
  },
  {
      "city": "CAMERON",
      "state": "ON"
  },
  {
      "city": "CAMLACHIE",
      "state": "ON"
  },
  {
      "city": "CAMP CREEK",
      "state": "AB"
  },
  {
      "city": "CAMP MORTON",
      "state": "MB"
  },
  {
      "city": "CAMPBELL RIVER",
      "state": "BC"
  },
  {
      "city": "CAMPBELL SETTLEMENT YORK CO",
      "state": "NB"
  },
  {
      "city": "CAMPBELL SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "CAMPBELL'S BAY",
      "state": "QC"
  },
  {
      "city": "CAMPBELLCROFT",
      "state": "ON"
  },
  {
      "city": "CAMPBELLFORD",
      "state": "ON"
  },
  {
      "city": "CAMPBELLTON",
      "state": "NB"
  },
  {
      "city": "CAMPBELLTON",
      "state": "NL"
  },
  {
      "city": "CAMPBELLVILLE",
      "state": "ON"
  },
  {
      "city": "CAMPDEN",
      "state": "ON"
  },
  {
      "city": "CAMPERDOWN",
      "state": "NS"
  },
  {
      "city": "CAMPERVILLE",
      "state": "MB"
  },
  {
      "city": "CAMROSE",
      "state": "AB"
  },
  {
      "city": "CANAAN FORKS",
      "state": "NB"
  },
  {
      "city": "CANAAN STATION",
      "state": "NB"
  },
  {
      "city": "CANAL FLATS",
      "state": "BC"
  },
  {
      "city": "CANAL",
      "state": "NB"
  },
  {
      "city": "CANDIAC",
      "state": "QC"
  },
  {
      "city": "CANDIAC",
      "state": "SK"
  },
  {
      "city": "CANDLE LAKE",
      "state": "SK"
  },
  {
      "city": "CANDO",
      "state": "SK"
  },
  {
      "city": "CANFIELD",
      "state": "ON"
  },
  {
      "city": "CANIM LAKE",
      "state": "BC"
  },
  {
      "city": "CANISTO",
      "state": "NB"
  },
  {
      "city": "CANMORE",
      "state": "AB"
  },
  {
      "city": "CANNIFTON",
      "state": "ON"
  },
  {
      "city": "CANNING",
      "state": "NS"
  },
  {
      "city": "CANNINGS COVE",
      "state": "NL"
  },
  {
      "city": "CANNINGTON",
      "state": "ON"
  },
  {
      "city": "CANOBIE",
      "state": "NB"
  },
  {
      "city": "CANOE NARROWS",
      "state": "SK"
  },
  {
      "city": "CANOE",
      "state": "BC"
  },
  {
      "city": "CANOOSE",
      "state": "NB"
  },
  {
      "city": "CANORA",
      "state": "SK"
  },
  {
      "city": "CANSO",
      "state": "NS"
  },
  {
      "city": "CANTERBURY",
      "state": "NB"
  },
  {
      "city": "CANTLEY",
      "state": "QC"
  },
  {
      "city": "CANTON BEDFORD",
      "state": "QC"
  },
  {
      "city": "CANTON DES BASQUES",
      "state": "NB"
  },
  {
      "city": "CANTON STANSTEAD",
      "state": "QC"
  },
  {
      "city": "CANTON TREMBLAY",
      "state": "QC"
  },
  {
      "city": "CANTON-DE-HATLEY",
      "state": "QC"
  },
  {
      "city": "CANWOOD",
      "state": "SK"
  },
  {
      "city": "CANYON CREEK",
      "state": "AB"
  },
  {
      "city": "CANYON",
      "state": "BC"
  },
  {
      "city": "CAP-AU-RENARD",
      "state": "QC"
  },
  {
      "city": "CAP-AUX-MEULES",
      "state": "QC"
  },
  {
      "city": "CAP-BATEAU",
      "state": "NB"
  },
  {
      "city": "CAP-CHAT",
      "state": "QC"
  },
  {
      "city": "CAP-CHAT-EST",
      "state": "QC"
  },
  {
      "city": "CAP-D'ESPOIR",
      "state": "QC"
  },
  {
      "city": "CAP-PELE",
      "state": "NB"
  },
  {
      "city": "CAP-SAINT-IGNACE",
      "state": "QC"
  },
  {
      "city": "CAP-SANTE",
      "state": "QC"
  },
  {
      "city": "CAPE BROYLE",
      "state": "NL"
  },
  {
      "city": "CAPE DAUPHIN",
      "state": "NS"
  },
  {
      "city": "CAPE DORSET",
      "state": "NU"
  },
  {
      "city": "CAPE ENRAGE",
      "state": "NB"
  },
  {
      "city": "CAPE FREELS NORTH",
      "state": "NL"
  },
  {
      "city": "CAPE NEGRO",
      "state": "NS"
  },
  {
      "city": "CAPE NORTH",
      "state": "NS"
  },
  {
      "city": "CAPE RAY",
      "state": "NL"
  },
  {
      "city": "CAPE SPEAR",
      "state": "NB"
  },
  {
      "city": "CAPE ST GEORGE",
      "state": "NL"
  },
  {
      "city": "CAPE STATION",
      "state": "NB"
  },
  {
      "city": "CAPE TORMENTINE",
      "state": "NB"
  },
  {
      "city": "CAPLAN",
      "state": "QC"
  },
  {
      "city": "CAPLIN COVE BDV",
      "state": "NL"
  },
  {
      "city": "CAPPAHAYDEN",
      "state": "NL"
  },
  {
      "city": "CAPREOL",
      "state": "ON"
  },
  {
      "city": "CAPSTICK",
      "state": "NS"
  },
  {
      "city": "CAPUCINS",
      "state": "QC"
  },
  {
      "city": "CARAMAT",
      "state": "ON"
  },
  {
      "city": "CARAQUET",
      "state": "NB"
  },
  {
      "city": "CARBERRY",
      "state": "MB"
  },
  {
      "city": "CARBON",
      "state": "AB"
  },
  {
      "city": "CARBONEAR",
      "state": "NL"
  },
  {
      "city": "CARCAJOU",
      "state": "AB"
  },
  {
      "city": "CARCROSS",
      "state": "YT"
  },
  {
      "city": "CARDALE",
      "state": "MB"
  },
  {
      "city": "CARDIFF",
      "state": "ON"
  },
  {
      "city": "CARDIGAN",
      "state": "NB"
  },
  {
      "city": "CARDIGAN",
      "state": "PE"
  },
  {
      "city": "CARDINAL",
      "state": "ON"
  },
  {
      "city": "CARDROSS",
      "state": "SK"
  },
  {
      "city": "CARDSTON",
      "state": "AB"
  },
  {
      "city": "CARGILL",
      "state": "ON"
  },
  {
      "city": "CARIBOU MARSH",
      "state": "NS"
  },
  {
      "city": "CARIEVALE",
      "state": "SK"
  },
  {
      "city": "CARIGNAN",
      "state": "QC"
  },
  {
      "city": "CARLETON PLACE",
      "state": "ON"
  },
  {
      "city": "CARLETON",
      "state": "NS"
  },
  {
      "city": "CARLETON",
      "state": "QC"
  },
  {
      "city": "CARLINGFORD",
      "state": "NB"
  },
  {
      "city": "CARLISLE",
      "state": "NB"
  },
  {
      "city": "CARLISLE",
      "state": "ON"
  },
  {
      "city": "CARLOW",
      "state": "NB"
  },
  {
      "city": "CARLOWRIE",
      "state": "MB"
  },
  {
      "city": "CARLSBAD SPRINGS",
      "state": "ON"
  },
  {
      "city": "CARLTON",
      "state": "SK"
  },
  {
      "city": "CARLYLE",
      "state": "SK"
  },
  {
      "city": "CARMACKS",
      "state": "YT"
  },
  {
      "city": "CARMAN",
      "state": "MB"
  },
  {
      "city": "CARMANGAY",
      "state": "AB"
  },
  {
      "city": "CARMANVILLE",
      "state": "NL"
  },
  {
      "city": "CARMEL",
      "state": "SK"
  },
  {
      "city": "CARNARVON",
      "state": "ON"
  },
  {
      "city": "CARNDUFF",
      "state": "SK"
  },
  {
      "city": "CARNWOOD",
      "state": "AB"
  },
  {
      "city": "CAROLINE",
      "state": "AB"
  },
  {
      "city": "CARON",
      "state": "SK"
  },
  {
      "city": "CARONPORT",
      "state": "SK"
  },
  {
      "city": "CARP",
      "state": "ON"
  },
  {
      "city": "CARRAGANA",
      "state": "SK"
  },
  {
      "city": "CARROL RIDGE",
      "state": "NB"
  },
  {
      "city": "CARROLL",
      "state": "MB"
  },
  {
      "city": "CARROLLS CORNER",
      "state": "NS"
  },
  {
      "city": "CARROLLS CROSSING",
      "state": "NB"
  },
  {
      "city": "CARROT CREEK",
      "state": "AB"
  },
  {
      "city": "CARROT RIVER",
      "state": "SK"
  },
  {
      "city": "CARRYING PLACE",
      "state": "ON"
  },
  {
      "city": "CARSELAND",
      "state": "AB"
  },
  {
      "city": "CARSONVILLE",
      "state": "NB"
  },
  {
      "city": "CARSTAIRS",
      "state": "AB"
  },
  {
      "city": "CARTERS COVE",
      "state": "NL"
  },
  {
      "city": "CARTERS POINT",
      "state": "NB"
  },
  {
      "city": "CARTIER",
      "state": "MB"
  },
  {
      "city": "CARTIER",
      "state": "ON"
  },
  {
      "city": "CARTWRIGHT",
      "state": "MB"
  },
  {
      "city": "CARTWRIGHT",
      "state": "NL"
  },
  {
      "city": "CARTYVILLE",
      "state": "NL"
  },
  {
      "city": "CARVEL",
      "state": "AB"
  },
  {
      "city": "CASA RIO",
      "state": "SK"
  },
  {
      "city": "CASCAPEDIA-SAINT-JULES",
      "state": "QC"
  },
  {
      "city": "CASLAN",
      "state": "AB"
  },
  {
      "city": "CASSELMAN",
      "state": "ON"
  },
  {
      "city": "CASSIDY LAKE",
      "state": "NB"
  },
  {
      "city": "CASSIDY",
      "state": "BC"
  },
  {
      "city": "CASSILIS",
      "state": "NB"
  },
  {
      "city": "CASTLE BAY",
      "state": "NS"
  },
  {
      "city": "CASTLEGAR",
      "state": "BC"
  },
  {
      "city": "CASTLETON",
      "state": "ON"
  },
  {
      "city": "CASTOR",
      "state": "AB"
  },
  {
      "city": "CASTORS RIVER",
      "state": "NL"
  },
  {
      "city": "CAT LAKE",
      "state": "ON"
  },
  {
      "city": "CATALINA",
      "state": "NL"
  },
  {
      "city": "CATALONE GUT",
      "state": "NS"
  },
  {
      "city": "CATALONE",
      "state": "NS"
  },
  {
      "city": "CATHCART",
      "state": "ON"
  },
  {
      "city": "CAUSAPSCAL",
      "state": "QC"
  },
  {
      "city": "CAVAN",
      "state": "ON"
  },
  {
      "city": "CAVENDISH",
      "state": "NL"
  },
  {
      "city": "CAWSTON",
      "state": "BC"
  },
  {
      "city": "CAYER",
      "state": "MB"
  },
  {
      "city": "CAYLEY",
      "state": "AB"
  },
  {
      "city": "CAYUGA",
      "state": "ON"
  },
  {
      "city": "CAZAVILLE",
      "state": "QC"
  },
  {
      "city": "CECIL LAKE",
      "state": "BC"
  },
  {
      "city": "CEDAR CAMP",
      "state": "NB"
  },
  {
      "city": "CEDAR POINT",
      "state": "ON"
  },
  {
      "city": "CEDAR SPRINGS",
      "state": "ON"
  },
  {
      "city": "CEDAR VALLEY",
      "state": "ON"
  },
  {
      "city": "CEDARVALE",
      "state": "BC"
  },
  {
      "city": "CELISTA",
      "state": "BC"
  },
  {
      "city": "CENTRAL BEDEQUE",
      "state": "PE"
  },
  {
      "city": "CENTRAL BLISSVILLE",
      "state": "NB"
  },
  {
      "city": "CENTRAL BUTTE",
      "state": "SK"
  },
  {
      "city": "CENTRAL GREENWICH",
      "state": "NB"
  },
  {
      "city": "CENTRAL HAINESVILLE",
      "state": "NB"
  },
  {
      "city": "CENTRAL HAMPSTEAD",
      "state": "NB"
  },
  {
      "city": "CENTRAL NORTH RIVER",
      "state": "NS"
  },
  {
      "city": "CENTRAL ONSLOW",
      "state": "NS"
  },
  {
      "city": "CENTRAL WATERVILLE",
      "state": "NB"
  },
  {
      "city": "CENTRALIA",
      "state": "ON"
  },
  {
      "city": "CENTRE BURLINGTON",
      "state": "NS"
  },
  {
      "city": "CENTRE VILLAGE",
      "state": "NB"
  },
  {
      "city": "CENTREVILLE",
      "state": "NB"
  },
  {
      "city": "CENTREVILLE",
      "state": "NS"
  },
  {
      "city": "CENTREVILLE",
      "state": "ON"
  },
  {
      "city": "CEREAL",
      "state": "AB"
  },
  {
      "city": "CESSFORD",
      "state": "AB"
  },
  {
      "city": "CEYLON",
      "state": "SK"
  },
  {
      "city": "CHALK RIVER",
      "state": "ON"
  },
  {
      "city": "CHAMBERLAIN SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "CHAMBERLAIN",
      "state": "SK"
  },
  {
      "city": "CHAMBERS SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "CHAMBLY",
      "state": "QC"
  },
  {
      "city": "CHAMBORD",
      "state": "QC"
  },
  {
      "city": "CHAMCOOK",
      "state": "NB"
  },
  {
      "city": "CHAMPION",
      "state": "AB"
  },
  {
      "city": "CHAMPLAIN",
      "state": "QC"
  },
  {
      "city": "CHAMPNEUF",
      "state": "QC"
  },
  {
      "city": "CHANCE COVE",
      "state": "NL"
  },
  {
      "city": "CHANCE HARBOUR",
      "state": "NB"
  },
  {
      "city": "CHANDLER",
      "state": "QC"
  },
  {
      "city": "CHANGE ISLANDS",
      "state": "NL"
  },
  {
      "city": "CHANNEL-PORT-AUX-BASQUES",
      "state": "NL"
  },
  {
      "city": "CHANNING",
      "state": "MB"
  },
  {
      "city": "CHAPAIS",
      "state": "QC"
  },
  {
      "city": "CHAPEAU",
      "state": "QC"
  },
  {
      "city": "CHAPEL ARM",
      "state": "NL"
  },
  {
      "city": "CHAPEL COVE",
      "state": "NL"
  },
  {
      "city": "CHAPLEAU",
      "state": "ON"
  },
  {
      "city": "CHAPLIN ISLAND ROAD",
      "state": "NB"
  },
  {
      "city": "CHAPLIN",
      "state": "SK"
  },
  {
      "city": "CHAPUT HUGHES",
      "state": "ON"
  },
  {
      "city": "CHARD",
      "state": "AB"
  },
  {
      "city": "CHARETTE",
      "state": "QC"
  },
  {
      "city": "CHARING CROSS",
      "state": "ON"
  },
  {
      "city": "CHARLEMAGNE",
      "state": "QC"
  },
  {
      "city": "CHARLESTON",
      "state": "NB"
  },
  {
      "city": "CHARLESTON",
      "state": "NL"
  },
  {
      "city": "CHARLIE LAKE",
      "state": "BC"
  },
  {
      "city": "CHARLIE LAKE",
      "state": "NB"
  },
  {
      "city": "CHARLO SOUTH",
      "state": "NB"
  },
  {
      "city": "CHARLO",
      "state": "NB"
  },
  {
      "city": "CHARLOTTETOWN LAB",
      "state": "NL"
  },
  {
      "city": "CHARLOTTETOWN",
      "state": "NL"
  },
  {
      "city": "CHARLOTTETOWN",
      "state": "PE"
  },
  {
      "city": "CHARLTON",
      "state": "ON"
  },
  {
      "city": "CHARNY",
      "state": "QC"
  },
  {
      "city": "CHARTERS SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "CHARTIERVILLE",
      "state": "QC"
  },
  {
      "city": "CHASE",
      "state": "BC"
  },
  {
      "city": "CHASWOOD",
      "state": "NS"
  },
  {
      "city": "CHATEAU-RICHER",
      "state": "QC"
  },
  {
      "city": "CHATEAUGUAY",
      "state": "QC"
  },
  {
      "city": "CHATEH",
      "state": "AB"
  },
  {
      "city": "CHATFIELD",
      "state": "MB"
  },
  {
      "city": "CHATHAM",
      "state": "ON"
  },
  {
      "city": "CHATSWORTH",
      "state": "ON"
  },
  {
      "city": "CHAUVIN",
      "state": "AB"
  },
  {
      "city": "CHAZEL",
      "state": "QC"
  },
  {
      "city": "CHELAN",
      "state": "SK"
  },
  {
      "city": "CHELMSFORD",
      "state": "NB"
  },
  {
      "city": "CHELMSFORD",
      "state": "ON"
  },
  {
      "city": "CHELSEA",
      "state": "NS"
  },
  {
      "city": "CHELSEA",
      "state": "QC"
  },
  {
      "city": "CHELTENHAM",
      "state": "ON"
  },
  {
      "city": "CHEMAINUS",
      "state": "BC"
  },
  {
      "city": "CHENEVILLE",
      "state": "QC"
  },
  {
      "city": "CHEPSTOW",
      "state": "ON"
  },
  {
      "city": "CHERHILL",
      "state": "AB"
  },
  {
      "city": "CHERRY BROOK",
      "state": "NS"
  },
  {
      "city": "CHERRY BURTON",
      "state": "NB"
  },
  {
      "city": "CHERRY GROVE",
      "state": "AB"
  },
  {
      "city": "CHERRY POINT",
      "state": "AB"
  },
  {
      "city": "CHERRY VALLEY",
      "state": "ON"
  },
  {
      "city": "CHERRYVILLE",
      "state": "BC"
  },
  {
      "city": "CHERTSEY",
      "state": "QC"
  },
  {
      "city": "CHESLEY",
      "state": "ON"
  },
  {
      "city": "CHESTER BASIN",
      "state": "NS"
  },
  {
      "city": "CHESTER",
      "state": "NS"
  },
  {
      "city": "CHESTERFIELD INLET",
      "state": "NU"
  },
  {
      "city": "CHESTERMERE",
      "state": "AB"
  },
  {
      "city": "CHESTERVILLE",
      "state": "ON"
  },
  {
      "city": "CHESTERVILLE",
      "state": "QC"
  },
  {
      "city": "CHETICAMP",
      "state": "NS"
  },
  {
      "city": "CHETWYND",
      "state": "BC"
  },
  {
      "city": "CHEVERIE",
      "state": "NS"
  },
  {
      "city": "CHEVERY",
      "state": "QC"
  },
  {
      "city": "CHIASSON OFFICE",
      "state": "NB"
  },
  {
      "city": "CHIBOUGAMAU",
      "state": "QC"
  },
  {
      "city": "CHICOUTIMI",
      "state": "QC"
  },
  {
      "city": "CHILANKO FORKS",
      "state": "BC"
  },
  {
      "city": "CHILDS CREEK",
      "state": "NB"
  },
  {
      "city": "CHILLIWACK",
      "state": "BC"
  },
  {
      "city": "CHINOOK",
      "state": "AB"
  },
  {
      "city": "CHIPMAN",
      "state": "AB"
  },
  {
      "city": "CHIPMAN",
      "state": "NB"
  },
  {
      "city": "CHISASIBI",
      "state": "QC"
  },
  {
      "city": "CHISHOLM MILLS",
      "state": "AB"
  },
  {
      "city": "CHITEK LAKE",
      "state": "SK"
  },
  {
      "city": "CHOCOLATE COVE",
      "state": "NB"
  },
  {
      "city": "CHOICELAND",
      "state": "SK"
  },
  {
      "city": "CHOMEDEY",
      "state": "QC"
  },
  {
      "city": "CHRISTINA LAKE",
      "state": "BC"
  },
  {
      "city": "CHRISTMAS ISLAND",
      "state": "NS"
  },
  {
      "city": "CHRISTOPHER LAKE",
      "state": "SK"
  },
  {
      "city": "CHURCH POINT",
      "state": "NS"
  },
  {
      "city": "CHURCHBRIDGE",
      "state": "SK"
  },
  {
      "city": "CHURCHILL FALLS",
      "state": "NL"
  },
  {
      "city": "CHURCHILL",
      "state": "MB"
  },
  {
      "city": "CHURCHILL",
      "state": "ON"
  },
  {
      "city": "CHUTE A BLONDEAU",
      "state": "ON"
  },
  {
      "city": "CHUTE-AUX-OUTARDES",
      "state": "QC"
  },
  {
      "city": "CHUTE-SAINT-PHILIPPE",
      "state": "QC"
  },
  {
      "city": "CLAIR",
      "state": "NB"
  },
  {
      "city": "CLAIR",
      "state": "SK"
  },
  {
      "city": "CLAIRMONT",
      "state": "AB"
  },
  {
      "city": "CLAIRVILLE",
      "state": "NB"
  },
  {
      "city": "CLAM POINT",
      "state": "NS"
  },
  {
      "city": "CLANDEBOYE",
      "state": "MB"
  },
  {
      "city": "CLANDONALD",
      "state": "AB"
  },
  {
      "city": "CLANWILLIAM",
      "state": "MB"
  },
  {
      "city": "CLAREMONT",
      "state": "ON"
  },
  {
      "city": "CLARENCE CREEK",
      "state": "ON"
  },
  {
      "city": "CLARENCEVILLE",
      "state": "QC"
  },
  {
      "city": "CLARENDON STATION",
      "state": "ON"
  },
  {
      "city": "CLARENDON",
      "state": "NB"
  },
  {
      "city": "CLARENVILLE",
      "state": "NL"
  },
  {
      "city": "CLARESHOLM",
      "state": "AB"
  },
  {
      "city": "CLARKE CITY",
      "state": "QC"
  },
  {
      "city": "CLARKES BEACH",
      "state": "NL"
  },
  {
      "city": "CLARKS CORNER",
      "state": "NB"
  },
  {
      "city": "CLARKS HARBOUR",
      "state": "NS"
  },
  {
      "city": "CLARKSBURG",
      "state": "ON"
  },
  {
      "city": "CLARKVILLE",
      "state": "NB"
  },
  {
      "city": "CLAVET",
      "state": "SK"
  },
  {
      "city": "CLAYBANK",
      "state": "SK"
  },
  {
      "city": "CLAYDON",
      "state": "SK"
  },
  {
      "city": "CLAYHURST",
      "state": "BC"
  },
  {
      "city": "CLAYTON",
      "state": "ON"
  },
  {
      "city": "CLEAR CREEK",
      "state": "ON"
  },
  {
      "city": "CLEARDALE",
      "state": "AB"
  },
  {
      "city": "CLEARVIEW",
      "state": "NB"
  },
  {
      "city": "CLEARWATER BAY",
      "state": "ON"
  },
  {
      "city": "CLEARWATER RIVER",
      "state": "SK"
  },
  {
      "city": "CLEARWATER",
      "state": "BC"
  },
  {
      "city": "CLEARWATER",
      "state": "MB"
  },
  {
      "city": "CLEMENTSPORT",
      "state": "NS"
  },
  {
      "city": "CLEMENTSVALE",
      "state": "NS"
  },
  {
      "city": "CLERICY",
      "state": "QC"
  },
  {
      "city": "CLERMONT",
      "state": "QC"
  },
  {
      "city": "CLERVAL",
      "state": "QC"
  },
  {
      "city": "CLEVELAND",
      "state": "NS"
  },
  {
      "city": "CLEVELAND",
      "state": "QC"
  },
  {
      "city": "CLIFFORD",
      "state": "ON"
  },
  {
      "city": "CLIFTON ROYAL",
      "state": "NB"
  },
  {
      "city": "CLIFTON",
      "state": "NB"
  },
  {
      "city": "CLIFTON",
      "state": "NS"
  },
  {
      "city": "CLIMAX",
      "state": "SK"
  },
  {
      "city": "CLINTON",
      "state": "BC"
  },
  {
      "city": "CLINTON",
      "state": "ON"
  },
  {
      "city": "CLIVE",
      "state": "AB"
  },
  {
      "city": "CLORIDORME",
      "state": "QC"
  },
  {
      "city": "CLOUTIER",
      "state": "QC"
  },
  {
      "city": "CLOVA",
      "state": "QC"
  },
  {
      "city": "CLOVER HILL",
      "state": "NB"
  },
  {
      "city": "CLOVER VALLEY",
      "state": "NB"
  },
  {
      "city": "CLOVERDALE",
      "state": "NB"
  },
  {
      "city": "CLOVERDALE",
      "state": "NS"
  },
  {
      "city": "CLOYNE",
      "state": "ON"
  },
  {
      "city": "CLUNY",
      "state": "AB"
  },
  {
      "city": "CLYDE RIVER",
      "state": "NS"
  },
  {
      "city": "CLYDE RIVER",
      "state": "NU"
  },
  {
      "city": "CLYDE",
      "state": "AB"
  },
  {
      "city": "COACHMANS COVE",
      "state": "NL"
  },
  {
      "city": "COAL BRANCH",
      "state": "NB"
  },
  {
      "city": "COAL CREEK",
      "state": "NB"
  },
  {
      "city": "COAL HARBOUR",
      "state": "BC"
  },
  {
      "city": "COALDALE",
      "state": "AB"
  },
  {
      "city": "COALHURST",
      "state": "AB"
  },
  {
      "city": "COALMONT",
      "state": "BC"
  },
  {
      "city": "COATICOOK",
      "state": "QC"
  },
  {
      "city": "COATSWORTH STATION",
      "state": "ON"
  },
  {
      "city": "COBALT",
      "state": "ON"
  },
  {
      "city": "COBBLE HILL",
      "state": "BC"
  },
  {
      "city": "COBDEN",
      "state": "ON"
  },
  {
      "city": "COBOCONK",
      "state": "ON"
  },
  {
      "city": "COBOURG",
      "state": "ON"
  },
  {
      "city": "COBURG",
      "state": "NB"
  },
  {
      "city": "COCAGNE",
      "state": "NB"
  },
  {
      "city": "COCHENOUR",
      "state": "ON"
  },
  {
      "city": "COCHIN",
      "state": "SK"
  },
  {
      "city": "COCHRANE",
      "state": "AB"
  },
  {
      "city": "COCHRANE",
      "state": "ON"
  },
  {
      "city": "CODERRE",
      "state": "SK"
  },
  {
      "city": "CODETTE",
      "state": "SK"
  },
  {
      "city": "CODRINGTON",
      "state": "ON"
  },
  {
      "city": "CODROY",
      "state": "NL"
  },
  {
      "city": "CODYS",
      "state": "NB"
  },
  {
      "city": "COE HILL",
      "state": "ON"
  },
  {
      "city": "COLBORNE",
      "state": "ON"
  },
  {
      "city": "COLD LAKE",
      "state": "AB"
  },
  {
      "city": "COLDBROOK",
      "state": "NS"
  },
  {
      "city": "COLDSTREAM",
      "state": "BC"
  },
  {
      "city": "COLDSTREAM",
      "state": "NB"
  },
  {
      "city": "COLDSTREAM",
      "state": "NS"
  },
  {
      "city": "COLDWATER",
      "state": "ON"
  },
  {
      "city": "COLE BAY",
      "state": "SK"
  },
  {
      "city": "COLE HARBOUR",
      "state": "NS"
  },
  {
      "city": "COLEMAN",
      "state": "AB"
  },
  {
      "city": "COLEMAN",
      "state": "PE"
  },
  {
      "city": "COLES ISLAND QUEENS CO",
      "state": "NB"
  },
  {
      "city": "COLEVILLE",
      "state": "SK"
  },
  {
      "city": "COLEYS POINT SOUTH",
      "state": "NL"
  },
  {
      "city": "COLFAX",
      "state": "SK"
  },
  {
      "city": "COLGATE",
      "state": "SK"
  },
  {
      "city": "COLINET",
      "state": "NL"
  },
  {
      "city": "COLINTON",
      "state": "AB"
  },
  {
      "city": "COLLEGE HEIGHTS",
      "state": "AB"
  },
  {
      "city": "COLLETTE",
      "state": "NB"
  },
  {
      "city": "COLLIERS RIVERHEAD",
      "state": "NL"
  },
  {
      "city": "COLLINA",
      "state": "NB"
  },
  {
      "city": "COLLINGWOOD CORNER",
      "state": "NS"
  },
  {
      "city": "COLLINGWOOD",
      "state": "ON"
  },
  {
      "city": "COLLINS",
      "state": "ON"
  },
  {
      "city": "COLOMBIER",
      "state": "QC"
  },
  {
      "city": "COLONSAY",
      "state": "SK"
  },
  {
      "city": "COLPITTS SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "COLVILLE LAKE",
      "state": "NT"
  },
  {
      "city": "COMBER",
      "state": "ON"
  },
  {
      "city": "COMBERMERE",
      "state": "ON"
  },
  {
      "city": "COME BY CHANCE",
      "state": "NL"
  },
  {
      "city": "COMFORT COVE-NEWSTEAD",
      "state": "NL"
  },
  {
      "city": "COMMANDA",
      "state": "ON"
  },
  {
      "city": "COMOX",
      "state": "BC"
  },
  {
      "city": "COMPEER",
      "state": "AB"
  },
  {
      "city": "COMPTON",
      "state": "QC"
  },
  {
      "city": "CONCEPTION BAY SOUTH",
      "state": "NL"
  },
  {
      "city": "CONCEPTION HARBOUR",
      "state": "NL"
  },
  {
      "city": "CONCHE",
      "state": "NL"
  },
  {
      "city": "CONCORD",
      "state": "ON"
  },
  {
      "city": "CONDOR",
      "state": "AB"
  },
  {
      "city": "CONESTOGO",
      "state": "ON"
  },
  {
      "city": "CONGRESS",
      "state": "SK"
  },
  {
      "city": "CONISTON",
      "state": "ON"
  },
  {
      "city": "CONKLIN",
      "state": "AB"
  },
  {
      "city": "CONN",
      "state": "ON"
  },
  {
      "city": "CONNAUGHT",
      "state": "ON"
  },
  {
      "city": "CONNE RIVER",
      "state": "NL"
  },
  {
      "city": "CONNELL",
      "state": "NB"
  },
  {
      "city": "CONQUERALL BANK",
      "state": "NS"
  },
  {
      "city": "CONQUERALL MILLS",
      "state": "NS"
  },
  {
      "city": "CONQUEST",
      "state": "SK"
  },
  {
      "city": "CONSECON",
      "state": "ON"
  },
  {
      "city": "CONSORT",
      "state": "AB"
  },
  {
      "city": "CONSTANCE LAKE",
      "state": "ON"
  },
  {
      "city": "CONSUL",
      "state": "SK"
  },
  {
      "city": "CONTRECOEUR",
      "state": "QC"
  },
  {
      "city": "COOKING LAKE",
      "state": "AB"
  },
  {
      "city": "COOKS BROOK",
      "state": "NS"
  },
  {
      "city": "COOKS HARBOUR",
      "state": "NL"
  },
  {
      "city": "COOKSHIRE-EATON",
      "state": "QC"
  },
  {
      "city": "COOKSTOWN",
      "state": "ON"
  },
  {
      "city": "COOKVILLE",
      "state": "NB"
  },
  {
      "city": "COOKVILLE",
      "state": "NS"
  },
  {
      "city": "COOMBS",
      "state": "BC"
  },
  {
      "city": "COPETOWN",
      "state": "ON"
  },
  {
      "city": "COPPER CLIFF",
      "state": "ON"
  },
  {
      "city": "COPPERSANDS",
      "state": "SK"
  },
  {
      "city": "COQUITLAM",
      "state": "BC"
  },
  {
      "city": "CORAL HARBOUR",
      "state": "NU"
  },
  {
      "city": "CORBEIL",
      "state": "ON"
  },
  {
      "city": "CORBYVILLE",
      "state": "ON"
  },
  {
      "city": "CORMAC",
      "state": "ON"
  },
  {
      "city": "CORMACK",
      "state": "NL"
  },
  {
      "city": "CORMAN PARK",
      "state": "SK"
  },
  {
      "city": "CORMIER-VILLAGE",
      "state": "NB"
  },
  {
      "city": "CORMORANT",
      "state": "MB"
  },
  {
      "city": "CORNER BROOK",
      "state": "NL"
  },
  {
      "city": "CORNHILL",
      "state": "NB"
  },
  {
      "city": "CORNING",
      "state": "SK"
  },
  {
      "city": "CORNWALL",
      "state": "ON"
  },
  {
      "city": "CORNWALL",
      "state": "PE"
  },
  {
      "city": "CORNWALLIS",
      "state": "NS"
  },
  {
      "city": "CORONACH",
      "state": "SK"
  },
  {
      "city": "CORONATION",
      "state": "AB"
  },
  {
      "city": "CORUNNA",
      "state": "ON"
  },
  {
      "city": "COTE SAINT-LUC",
      "state": "QC"
  },
  {
      "city": "COTEAU ROAD",
      "state": "NB"
  },
  {
      "city": "COTEAU-DU-LAC",
      "state": "QC"
  },
  {
      "city": "COTTAM",
      "state": "ON"
  },
  {
      "city": "COTTLESVILLE",
      "state": "NL"
  },
  {
      "city": "COTTRELLS COVE",
      "state": "NL"
  },
  {
      "city": "COULTER",
      "state": "MB"
  },
  {
      "city": "COUNTY OF GRANDE PRAIRIE NO. 1",
      "state": "AB"
  },
  {
      "city": "COURCELETTE",
      "state": "QC"
  },
  {
      "city": "COURCELLES",
      "state": "QC"
  },
  {
      "city": "COURTENAY",
      "state": "BC"
  },
  {
      "city": "COURTICE",
      "state": "ON"
  },
  {
      "city": "COURTLAND",
      "state": "ON"
  },
  {
      "city": "COURTRIGHT",
      "state": "ON"
  },
  {
      "city": "COURVAL",
      "state": "SK"
  },
  {
      "city": "COUTTS",
      "state": "AB"
  },
  {
      "city": "COW BAY",
      "state": "NS"
  },
  {
      "city": "COW HEAD",
      "state": "NL"
  },
  {
      "city": "COWAN",
      "state": "MB"
  },
  {
      "city": "COWANSVILLE",
      "state": "QC"
  },
  {
      "city": "COWESSESS",
      "state": "SK"
  },
  {
      "city": "COWICHAN BAY",
      "state": "BC"
  },
  {
      "city": "COWLEY",
      "state": "AB"
  },
  {
      "city": "COXHEATH",
      "state": "NS"
  },
  {
      "city": "COXS COVE",
      "state": "NL"
  },
  {
      "city": "CRABTREE",
      "state": "QC"
  },
  {
      "city": "CRAIG FLATS",
      "state": "NB"
  },
  {
      "city": "CRAIGMORE",
      "state": "NS"
  },
  {
      "city": "CRAIGMYLE",
      "state": "AB"
  },
  {
      "city": "CRAIK",
      "state": "SK"
  },
  {
      "city": "CRANBERRY PORTAGE",
      "state": "MB"
  },
  {
      "city": "CRANBROOK",
      "state": "BC"
  },
  {
      "city": "CRANDALL ROAD",
      "state": "NS"
  },
  {
      "city": "CRANDALL",
      "state": "MB"
  },
  {
      "city": "CRANE RIVER",
      "state": "MB"
  },
  {
      "city": "CRANE VALLEY",
      "state": "SK"
  },
  {
      "city": "CRANFORD",
      "state": "AB"
  },
  {
      "city": "CRAPAUD",
      "state": "PE"
  },
  {
      "city": "CRAVEN",
      "state": "SK"
  },
  {
      "city": "CRAWFORD BAY",
      "state": "BC"
  },
  {
      "city": "CREDITON",
      "state": "ON"
  },
  {
      "city": "CREELMAN",
      "state": "SK"
  },
  {
      "city": "CREEMORE",
      "state": "ON"
  },
  {
      "city": "CREIGHTON",
      "state": "SK"
  },
  {
      "city": "CREIGNISH",
      "state": "NS"
  },
  {
      "city": "CREMONA",
      "state": "AB"
  },
  {
      "city": "CRESCENT SPUR",
      "state": "BC"
  },
  {
      "city": "CRESCENT VALLEY",
      "state": "BC"
  },
  {
      "city": "CRESTON NORTH",
      "state": "NL"
  },
  {
      "city": "CRESTON",
      "state": "BC"
  },
  {
      "city": "CRESTON",
      "state": "NL"
  },
  {
      "city": "CROCKER HILL",
      "state": "NB"
  },
  {
      "city": "CROFTON",
      "state": "BC"
  },
  {
      "city": "CROMBIE SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "CROMER",
      "state": "MB"
  },
  {
      "city": "CROOKED CREEK",
      "state": "AB"
  },
  {
      "city": "CROOKED RIVER",
      "state": "SK"
  },
  {
      "city": "CROQUE",
      "state": "NL"
  },
  {
      "city": "CROSS CREEK",
      "state": "NB"
  },
  {
      "city": "CROSS LAKE",
      "state": "MB"
  },
  {
      "city": "CROSS ROADS COUNTRY HARBOUR",
      "state": "NS"
  },
  {
      "city": "CROSSFIELD",
      "state": "AB"
  },
  {
      "city": "CROTON",
      "state": "ON"
  },
  {
      "city": "CROUSES SETTLEMENT",
      "state": "NS"
  },
  {
      "city": "CROUSETOWN",
      "state": "NS"
  },
  {
      "city": "CROWELL",
      "state": "NS"
  },
  {
      "city": "CROWES MILLS",
      "state": "NS"
  },
  {
      "city": "CRYSLER",
      "state": "ON"
  },
  {
      "city": "CRYSTAL BEACH",
      "state": "ON"
  },
  {
      "city": "CRYSTAL CITY",
      "state": "MB"
  },
  {
      "city": "CRYSTAL FALLS",
      "state": "ON"
  },
  {
      "city": "CRYSTAL SPRINGS",
      "state": "SK"
  },
  {
      "city": "CUDWORTH",
      "state": "SK"
  },
  {
      "city": "CULTUS LAKE",
      "state": "BC"
  },
  {
      "city": "CUMBERLAND BAY",
      "state": "NB"
  },
  {
      "city": "CUMBERLAND BEACH",
      "state": "ON"
  },
  {
      "city": "CUMBERLAND HOUSE",
      "state": "SK"
  },
  {
      "city": "CUMBERLAND",
      "state": "BC"
  },
  {
      "city": "CUMBERLAND",
      "state": "ON"
  },
  {
      "city": "CUMMINGS COVE",
      "state": "NB"
  },
  {
      "city": "CUPAR",
      "state": "SK"
  },
  {
      "city": "CUPIDS",
      "state": "NL"
  },
  {
      "city": "CURRAN",
      "state": "ON"
  },
  {
      "city": "CURRIE SIDING",
      "state": "NB"
  },
  {
      "city": "CURRIEBURG",
      "state": "NB"
  },
  {
      "city": "CURRYS CORNER",
      "state": "NS"
  },
  {
      "city": "CURRYVILLE",
      "state": "NB"
  },
  {
      "city": "CURVE LAKE",
      "state": "ON"
  },
  {
      "city": "CURVENTON",
      "state": "NB"
  },
  {
      "city": "CUT KNIFE",
      "state": "SK"
  },
  {
      "city": "CUTLER",
      "state": "ON"
  },
  {
      "city": "CYMRIC",
      "state": "SK"
  },
  {
      "city": "CYNTHIA",
      "state": "AB"
  },
  {
      "city": "CYPRESS COUNTY",
      "state": "AB"
  },
  {
      "city": "CYPRESS RIVER",
      "state": "MB"
  },
  {
      "city": "CZAR",
      "state": "AB"
  },
  {
      "city": "D'ALEMBERT",
      "state": "QC"
  },
  {
      "city": "D'ARCY STATION",
      "state": "SK"
  },
  {
      "city": "D'ARCY",
      "state": "BC"
  },
  {
      "city": "D'ESCOUSSE",
      "state": "NS"
  },
  {
      "city": "DACOTAH",
      "state": "MB"
  },
  {
      "city": "DACRE",
      "state": "ON"
  },
  {
      "city": "DAFOE",
      "state": "SK"
  },
  {
      "city": "DAKOTA TIPI",
      "state": "MB"
  },
  {
      "city": "DALEM LAKE",
      "state": "NS"
  },
  {
      "city": "DALEMEAD",
      "state": "AB"
  },
  {
      "city": "DALHOUSIE JUNCTION",
      "state": "NB"
  },
  {
      "city": "DALHOUSIE",
      "state": "NB"
  },
  {
      "city": "DALHOUSIE",
      "state": "QC"
  },
  {
      "city": "DALKEITH",
      "state": "ON"
  },
  {
      "city": "DALLAS",
      "state": "MB"
  },
  {
      "city": "DALMAS",
      "state": "QC"
  },
  {
      "city": "DALMENY",
      "state": "SK"
  },
  {
      "city": "DAMASCUS",
      "state": "NB"
  },
  {
      "city": "DANBURY",
      "state": "SK"
  },
  {
      "city": "DANESVILLE",
      "state": "NS"
  },
  {
      "city": "DANFORD LAKE",
      "state": "QC"
  },
  {
      "city": "DANIELS HARBOUR",
      "state": "NL"
  },
  {
      "city": "DANVILLE",
      "state": "QC"
  },
  {
      "city": "DAPP",
      "state": "AB"
  },
  {
      "city": "DARFIELD",
      "state": "BC"
  },
  {
      "city": "DARLINGFORD",
      "state": "MB"
  },
  {
      "city": "DARLINGS ISLAND",
      "state": "NB"
  },
  {
      "city": "DARTMOUTH",
      "state": "NS"
  },
  {
      "city": "DARWELL",
      "state": "AB"
  },
  {
      "city": "DASHWOOD",
      "state": "ON"
  },
  {
      "city": "DAUPHIN",
      "state": "MB"
  },
  {
      "city": "DAUVERSIERE",
      "state": "NB"
  },
  {
      "city": "DAVELUYVILLE",
      "state": "QC"
  },
  {
      "city": "DAVIDSON",
      "state": "QC"
  },
  {
      "city": "DAVIDSON",
      "state": "SK"
  },
  {
      "city": "DAVIN",
      "state": "SK"
  },
  {
      "city": "DAWSON CREEK",
      "state": "BC"
  },
  {
      "city": "DAWSON SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "DAWSON",
      "state": "YT"
  },
  {
      "city": "DAWSONS LANDING",
      "state": "BC"
  },
  {
      "city": "DAWSONVILLE",
      "state": "NB"
  },
  {
      "city": "DAYSLAND",
      "state": "AB"
  },
  {
      "city": "DAYSPRING",
      "state": "NS"
  },
  {
      "city": "DE WINTON",
      "state": "AB"
  },
  {
      "city": "DEAD CREEK",
      "state": "NB"
  },
  {
      "city": "DEAD MAN'S FLATS",
      "state": "AB"
  },
  {
      "city": "DEADMANS BAY",
      "state": "NL"
  },
  {
      "city": "DEADWOOD",
      "state": "AB"
  },
  {
      "city": "DEASE LAKE",
      "state": "BC"
  },
  {
      "city": "DEBDEN",
      "state": "SK"
  },
  {
      "city": "DEBEC",
      "state": "NB"
  },
  {
      "city": "DEBERT",
      "state": "NS"
  },
  {
      "city": "DEBOLT",
      "state": "AB"
  },
  {
      "city": "DECKER",
      "state": "MB"
  },
  {
      "city": "DEEP BAY",
      "state": "NL"
  },
  {
      "city": "DEEP BROOK",
      "state": "NS"
  },
  {
      "city": "DEEP RIVER",
      "state": "ON"
  },
  {
      "city": "DEER LAKE",
      "state": "NL"
  },
  {
      "city": "DEER LAKE",
      "state": "ON"
  },
  {
      "city": "DEER VALLEY",
      "state": "SK"
  },
  {
      "city": "DEERSDALE",
      "state": "NB"
  },
  {
      "city": "DEERVILLE",
      "state": "NB"
  },
  {
      "city": "DEGELIS",
      "state": "QC"
  },
  {
      "city": "DEL BONITA",
      "state": "AB"
  },
  {
      "city": "DELACOUR",
      "state": "AB"
  },
  {
      "city": "DELAWARE",
      "state": "ON"
  },
  {
      "city": "DELBURNE",
      "state": "AB"
  },
  {
      "city": "DELEAGE",
      "state": "QC"
  },
  {
      "city": "DELEAU",
      "state": "MB"
  },
  {
      "city": "DELHI",
      "state": "ON"
  },
  {
      "city": "DELIA",
      "state": "AB"
  },
  {
      "city": "DELINE",
      "state": "NT"
  },
  {
      "city": "DELISLE",
      "state": "SK"
  },
  {
      "city": "DELMAS",
      "state": "SK"
  },
  {
      "city": "DELORAINE",
      "state": "MB"
  },
  {
      "city": "DELSON",
      "state": "QC"
  },
  {
      "city": "DELTA",
      "state": "BC"
  },
  {
      "city": "DELTA",
      "state": "ON"
  },
  {
      "city": "DEMAINE",
      "state": "SK"
  },
  {
      "city": "DEMMITT",
      "state": "AB"
  },
  {
      "city": "DEMORESTVILLE",
      "state": "ON"
  },
  {
      "city": "DENARE BEACH",
      "state": "SK"
  },
  {
      "city": "DENBIGH",
      "state": "ON"
  },
  {
      "city": "DENFIELD",
      "state": "ON"
  },
  {
      "city": "DENHOLM",
      "state": "QC"
  },
  {
      "city": "DENHOLM",
      "state": "SK"
  },
  {
      "city": "DENMAN ISLAND",
      "state": "BC"
  },
  {
      "city": "DENNIS BEACH",
      "state": "NB"
  },
  {
      "city": "DENNY ISLAND",
      "state": "BC"
  },
  {
      "city": "DENSMORES MILLS",
      "state": "NS"
  },
  {
      "city": "DENWOOD",
      "state": "AB"
  },
  {
      "city": "DENZIL",
      "state": "SK"
  },
  {
      "city": "DERBY JUNCTION",
      "state": "NB"
  },
  {
      "city": "DERBY",
      "state": "NB"
  },
  {
      "city": "DEROCHE",
      "state": "BC"
  },
  {
      "city": "DERWENT",
      "state": "AB"
  },
  {
      "city": "DESBARATS",
      "state": "ON"
  },
  {
      "city": "DESBIENS",
      "state": "QC"
  },
  {
      "city": "DESBORO",
      "state": "ON"
  },
  {
      "city": "DESCHAILLONS-SUR-SAINT-LAURENT",
      "state": "QC"
  },
  {
      "city": "DESCHAMBAULT LAKE",
      "state": "SK"
  },
  {
      "city": "DESCHAMBAULT",
      "state": "QC"
  },
  {
      "city": "DESERONTO",
      "state": "ON"
  },
  {
      "city": "DESERT BLUME",
      "state": "AB"
  },
  {
      "city": "DESMARAISVILLE",
      "state": "QC"
  },
  {
      "city": "DESMELOIZES",
      "state": "QC"
  },
  {
      "city": "DESTOR",
      "state": "QC"
  },
  {
      "city": "DESTRUCTION BAY",
      "state": "YT"
  },
  {
      "city": "DEUX RIVIERES",
      "state": "ON"
  },
  {
      "city": "DEUX-MONTAGNES",
      "state": "QC"
  },
  {
      "city": "DEVLIN",
      "state": "ON"
  },
  {
      "city": "DEVON",
      "state": "AB"
  },
  {
      "city": "DEWBERRY",
      "state": "AB"
  },
  {
      "city": "DEWDNEY",
      "state": "BC"
  },
  {
      "city": "DEWITTVILLE",
      "state": "QC"
  },
  {
      "city": "DEWOLFE",
      "state": "NB"
  },
  {
      "city": "DIAMOND CITY",
      "state": "AB"
  },
  {
      "city": "DIDSBURY",
      "state": "AB"
  },
  {
      "city": "DIEPPE",
      "state": "NB"
  },
  {
      "city": "DIGBY",
      "state": "NS"
  },
  {
      "city": "DIGDEGUASH",
      "state": "NB"
  },
  {
      "city": "DILDO",
      "state": "NL"
  },
  {
      "city": "DILIGENT RIVER",
      "state": "NS"
  },
  {
      "city": "DILKE",
      "state": "SK"
  },
  {
      "city": "DILLON",
      "state": "SK"
  },
  {
      "city": "DINGWALL",
      "state": "NS"
  },
  {
      "city": "DINORWIC",
      "state": "ON"
  },
  {
      "city": "DINSMORE",
      "state": "SK"
  },
  {
      "city": "DIPPER HARBOUR",
      "state": "NB"
  },
  {
      "city": "DISRAELI",
      "state": "QC"
  },
  {
      "city": "DIVIDE",
      "state": "NB"
  },
  {
      "city": "DIXONVILLE",
      "state": "AB"
  },
  {
      "city": "DIXVILLE",
      "state": "QC"
  },
  {
      "city": "DOAKTOWN",
      "state": "NB"
  },
  {
      "city": "DOBBINTON",
      "state": "ON"
  },
  {
      "city": "DOBIE",
      "state": "ON"
  },
  {
      "city": "DOBSON CORNER",
      "state": "NB"
  },
  {
      "city": "DODSLAND",
      "state": "SK"
  },
  {
      "city": "DOG CREEK",
      "state": "BC"
  },
  {
      "city": "DOLBEAU-MISTASSINI",
      "state": "QC"
  },
  {
      "city": "DOLLARD",
      "state": "SK"
  },
  {
      "city": "DOLLARD-DES-ORMEAUX",
      "state": "QC"
  },
  {
      "city": "DOMAIN",
      "state": "MB"
  },
  {
      "city": "DOME CREEK",
      "state": "BC"
  },
  {
      "city": "DOMINION CITY",
      "state": "MB"
  },
  {
      "city": "DOMINION",
      "state": "NS"
  },
  {
      "city": "DOMREMY",
      "state": "SK"
  },
  {
      "city": "DONALDA",
      "state": "AB"
  },
  {
      "city": "DONEGAL",
      "state": "NB"
  },
  {
      "city": "DONKIN",
      "state": "NS"
  },
  {
      "city": "DONNACONA",
      "state": "QC"
  },
  {
      "city": "DONNELLY",
      "state": "AB"
  },
  {
      "city": "DORCHESTER CAPE",
      "state": "NB"
  },
  {
      "city": "DORCHESTER",
      "state": "NB"
  },
  {
      "city": "DORCHESTER",
      "state": "ON"
  },
  {
      "city": "DORINTOSH",
      "state": "SK"
  },
  {
      "city": "DORION",
      "state": "ON"
  },
  {
      "city": "DOROTHY",
      "state": "AB"
  },
  {
      "city": "DORRINGTON HILL",
      "state": "NB"
  },
  {
      "city": "DORSET",
      "state": "ON"
  },
  {
      "city": "DORVAL",
      "state": "QC"
  },
  {
      "city": "DOSQUET",
      "state": "QC"
  },
  {
      "city": "DOUGLAS HARBOUR",
      "state": "NB"
  },
  {
      "city": "DOUGLAS LAKE",
      "state": "BC"
  },
  {
      "city": "DOUGLAS",
      "state": "MB"
  },
  {
      "city": "DOUGLAS",
      "state": "NB"
  },
  {
      "city": "DOUGLAS",
      "state": "ON"
  },
  {
      "city": "DOURO",
      "state": "ON"
  },
  {
      "city": "DOVER CENTRE",
      "state": "ON"
  },
  {
      "city": "DOVER",
      "state": "NL"
  },
  {
      "city": "DOW SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "DOWLING",
      "state": "ON"
  },
  {
      "city": "DOYLES BROOK",
      "state": "NB"
  },
  {
      "city": "DOYLES",
      "state": "NL"
  },
  {
      "city": "DRAKE",
      "state": "SK"
  },
  {
      "city": "DRAYTON VALLEY",
      "state": "AB"
  },
  {
      "city": "DRAYTON",
      "state": "ON"
  },
  {
      "city": "DRESDEN",
      "state": "ON"
  },
  {
      "city": "DRIFTPILE",
      "state": "AB"
  },
  {
      "city": "DRIFTWOOD",
      "state": "ON"
  },
  {
      "city": "DRINKWATER",
      "state": "SK"
  },
  {
      "city": "DROPMORE",
      "state": "MB"
  },
  {
      "city": "DRUMBO",
      "state": "ON"
  },
  {
      "city": "DRUMHELLER",
      "state": "AB"
  },
  {
      "city": "DRUMMOND",
      "state": "NB"
  },
  {
      "city": "DRUMMONDVILLE",
      "state": "QC"
  },
  {
      "city": "DRURYS COVE",
      "state": "NB"
  },
  {
      "city": "DRYDEN",
      "state": "ON"
  },
  {
      "city": "DSL DE DRUMMOND",
      "state": "NB"
  },
  {
      "city": "DSL DE GRAND-SAULT/FALLS",
      "state": "NB"
  },
  {
      "city": "DUART",
      "state": "ON"
  },
  {
      "city": "DUBEE SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "DUBLIN",
      "state": "ON"
  },
  {
      "city": "DUBREUILVILLE",
      "state": "ON"
  },
  {
      "city": "DUBUC",
      "state": "SK"
  },
  {
      "city": "DUCHESS",
      "state": "AB"
  },
  {
      "city": "DUCK BAY",
      "state": "MB"
  },
  {
      "city": "DUCK LAKE",
      "state": "SK"
  },
  {
      "city": "DUCLOS",
      "state": "QC"
  },
  {
      "city": "DUFF",
      "state": "SK"
  },
  {
      "city": "DUFFERIN CHARLOTTE CO",
      "state": "NB"
  },
  {
      "city": "DUFFERIN QUEENS CO",
      "state": "NB"
  },
  {
      "city": "DUFFIELD",
      "state": "AB"
  },
  {
      "city": "DUFRESNE",
      "state": "MB"
  },
  {
      "city": "DUFROST",
      "state": "MB"
  },
  {
      "city": "DUGALD",
      "state": "MB"
  },
  {
      "city": "DUGAS",
      "state": "NB"
  },
  {
      "city": "DUGUAYVILLE",
      "state": "NB"
  },
  {
      "city": "DUHAMEL",
      "state": "QC"
  },
  {
      "city": "DUHAMEL-OUEST",
      "state": "QC"
  },
  {
      "city": "DUMFRIES",
      "state": "NB"
  },
  {
      "city": "DUMMER",
      "state": "SK"
  },
  {
      "city": "DUNCAN",
      "state": "BC"
  },
  {
      "city": "DUNCANS COVE",
      "state": "NS"
  },
  {
      "city": "DUNCHURCH",
      "state": "ON"
  },
  {
      "city": "DUNDALK",
      "state": "ON"
  },
  {
      "city": "DUNDAS",
      "state": "NB"
  },
  {
      "city": "DUNDAS",
      "state": "ON"
  },
  {
      "city": "DUNDEE",
      "state": "NB"
  },
  {
      "city": "DUNDURN",
      "state": "SK"
  },
  {
      "city": "DUNGANNON",
      "state": "ON"
  },
  {
      "city": "DUNHAM",
      "state": "QC"
  },
  {
      "city": "DUNLOP",
      "state": "NB"
  },
  {
      "city": "DUNMORE",
      "state": "AB"
  },
  {
      "city": "DUNNVILLE",
      "state": "ON"
  },
  {
      "city": "DUNREA",
      "state": "MB"
  },
  {
      "city": "DUNROBIN",
      "state": "ON"
  },
  {
      "city": "DUNSFORD",
      "state": "ON"
  },
  {
      "city": "DUNSINANE",
      "state": "NB"
  },
  {
      "city": "DUNSTER",
      "state": "BC"
  },
  {
      "city": "DUNTARA",
      "state": "NL"
  },
  {
      "city": "DUNTROON",
      "state": "ON"
  },
  {
      "city": "DUNVEGAN",
      "state": "ON"
  },
  {
      "city": "DUNVILLE",
      "state": "NL"
  },
  {
      "city": "DUPARQUET",
      "state": "QC"
  },
  {
      "city": "DUPEROW",
      "state": "SK"
  },
  {
      "city": "DUPUY",
      "state": "QC"
  },
  {
      "city": "DURBAN",
      "state": "MB"
  },
  {
      "city": "DURHAM BRIDGE",
      "state": "NB"
  },
  {
      "city": "DURHAM",
      "state": "ON"
  },
  {
      "city": "DURHAM-SUD",
      "state": "QC"
  },
  {
      "city": "DURRELL",
      "state": "NL"
  },
  {
      "city": "DUTCH BROOK",
      "state": "NS"
  },
  {
      "city": "DUTCH SETTLEMENT",
      "state": "NS"
  },
  {
      "city": "DUTCH VALLEY",
      "state": "NB"
  },
  {
      "city": "DUTTON",
      "state": "ON"
  },
  {
      "city": "DUVAL",
      "state": "SK"
  },
  {
      "city": "DWIGHT",
      "state": "ON"
  },
  {
      "city": "DYSART",
      "state": "SK"
  },
  {
      "city": "EABAMET LAKE",
      "state": "ON"
  },
  {
      "city": "EAGLE BAY",
      "state": "BC"
  },
  {
      "city": "EAGLE CREEK",
      "state": "BC"
  },
  {
      "city": "EAGLE LAKE",
      "state": "ON"
  },
  {
      "city": "EAGLE RIDGE",
      "state": "SK"
  },
  {
      "city": "EAGLE RIVER",
      "state": "ON"
  },
  {
      "city": "EAGLESHAM",
      "state": "AB"
  },
  {
      "city": "EAR FALLS",
      "state": "ON"
  },
  {
      "city": "EARL GREY",
      "state": "SK"
  },
  {
      "city": "EARLTON",
      "state": "ON"
  },
  {
      "city": "EAST ANGUS",
      "state": "QC"
  },
  {
      "city": "EAST BACCARO",
      "state": "NS"
  },
  {
      "city": "EAST BAY",
      "state": "NS"
  },
  {
      "city": "EAST BRAINTREE",
      "state": "MB"
  },
  {
      "city": "EAST BRANCH",
      "state": "NB"
  },
  {
      "city": "EAST BRIGHTON",
      "state": "NB"
  },
  {
      "city": "EAST BROUGHTON STATION",
      "state": "QC"
  },
  {
      "city": "EAST BROUGHTON",
      "state": "QC"
  },
  {
      "city": "EAST CENTREVILLE",
      "state": "NB"
  },
  {
      "city": "EAST CLIFFORD",
      "state": "NS"
  },
  {
      "city": "EAST COLDSTREAM",
      "state": "NB"
  },
  {
      "city": "EAST COULEE",
      "state": "AB"
  },
  {
      "city": "EAST DOVER",
      "state": "NS"
  },
  {
      "city": "EAST FARNHAM",
      "state": "QC"
  },
  {
      "city": "EAST GORE",
      "state": "NS"
  },
  {
      "city": "EAST GWILLIMBURY",
      "state": "ON"
  },
  {
      "city": "EAST HEREFORD",
      "state": "QC"
  },
  {
      "city": "EAST LAHAVE",
      "state": "NS"
  },
  {
      "city": "EAST LAWRENCETOWN",
      "state": "NS"
  },
  {
      "city": "EAST MOUNTAIN",
      "state": "NS"
  },
  {
      "city": "EAST NEWBRIDGE",
      "state": "NB"
  },
  {
      "city": "EAST PENNANT",
      "state": "NS"
  },
  {
      "city": "EAST PRESTON",
      "state": "NS"
  },
  {
      "city": "EAST SELKIRK",
      "state": "MB"
  },
  {
      "city": "EAST ST PAUL",
      "state": "MB"
  },
  {
      "city": "EAST STEWIACKE",
      "state": "NS"
  },
  {
      "city": "EAST YORK",
      "state": "ON"
  },
  {
      "city": "EASTEND",
      "state": "SK"
  },
  {
      "city": "EASTERN PASSAGE",
      "state": "NS"
  },
  {
      "city": "EASTERVILLE",
      "state": "MB"
  },
  {
      "city": "EASTMAIN",
      "state": "QC"
  },
  {
      "city": "EASTMAN",
      "state": "QC"
  },
  {
      "city": "EASTPORT",
      "state": "NL"
  },
  {
      "city": "EATONIA",
      "state": "SK"
  },
  {
      "city": "EBB AND FLOW",
      "state": "MB"
  },
  {
      "city": "EBENEZER",
      "state": "SK"
  },
  {
      "city": "ECHO BAY",
      "state": "ON"
  },
  {
      "city": "ECKVILLE",
      "state": "AB"
  },
  {
      "city": "ECONOMY",
      "state": "NS"
  },
  {
      "city": "EDAM",
      "state": "SK"
  },
  {
      "city": "EDBERG",
      "state": "AB"
  },
  {
      "city": "EDDIES COVE WEST",
      "state": "NL"
  },
  {
      "city": "EDDIES COVE",
      "state": "NL"
  },
  {
      "city": "EDDYSTONE",
      "state": "MB"
  },
  {
      "city": "EDEN MILLS",
      "state": "ON"
  },
  {
      "city": "EDEN",
      "state": "MB"
  },
  {
      "city": "EDEN",
      "state": "ON"
  },
  {
      "city": "EDENWOLD",
      "state": "SK"
  },
  {
      "city": "EDGELEY",
      "state": "SK"
  },
  {
      "city": "EDGERTON",
      "state": "AB"
  },
  {
      "city": "EDGETTS LANDING",
      "state": "NB"
  },
  {
      "city": "EDGEWATER",
      "state": "BC"
  },
  {
      "city": "EDGEWOOD",
      "state": "BC"
  },
  {
      "city": "EDMONTON",
      "state": "AB"
  },
  {
      "city": "EDMUNDSTON",
      "state": "NB"
  },
  {
      "city": "EDSON",
      "state": "AB"
  },
  {
      "city": "EDWARDS",
      "state": "ON"
  },
  {
      "city": "EDWARDSVILLE",
      "state": "NS"
  },
  {
      "city": "EDWIN",
      "state": "MB"
  },
  {
      "city": "EEL GROUND",
      "state": "NB"
  },
  {
      "city": "EEL RIVER BAR FIRST NATION",
      "state": "NB"
  },
  {
      "city": "EEL RIVER COVE",
      "state": "NB"
  },
  {
      "city": "EEL RIVER CROSSING",
      "state": "NB"
  },
  {
      "city": "EEL RIVER LAKE",
      "state": "NB"
  },
  {
      "city": "EGAN",
      "state": "QC"
  },
  {
      "city": "EGANVILLE",
      "state": "ON"
  },
  {
      "city": "EGBERT",
      "state": "ON"
  },
  {
      "city": "EGMONDVILLE",
      "state": "ON"
  },
  {
      "city": "EGMONT",
      "state": "BC"
  },
  {
      "city": "EGREMONT",
      "state": "AB"
  },
  {
      "city": "ELBOW",
      "state": "SK"
  },
  {
      "city": "ELDERBANK",
      "state": "NS"
  },
  {
      "city": "ELDORADO",
      "state": "ON"
  },
  {
      "city": "ELFROS",
      "state": "SK"
  },
  {
      "city": "ELGIN",
      "state": "MB"
  },
  {
      "city": "ELGIN",
      "state": "NB"
  },
  {
      "city": "ELGIN",
      "state": "ON"
  },
  {
      "city": "ELGIN",
      "state": "QC"
  },
  {
      "city": "ELGINBURG",
      "state": "ON"
  },
  {
      "city": "ELIE",
      "state": "MB"
  },
  {
      "city": "ELIZABETHTOWN",
      "state": "ON"
  },
  {
      "city": "ELK LAKE",
      "state": "ON"
  },
  {
      "city": "ELK POINT",
      "state": "AB"
  },
  {
      "city": "ELKFORD",
      "state": "BC"
  },
  {
      "city": "ELKHORN",
      "state": "MB"
  },
  {
      "city": "ELKO",
      "state": "BC"
  },
  {
      "city": "ELKWATER",
      "state": "AB"
  },
  {
      "city": "ELLERSHOUSE",
      "state": "NS"
  },
  {
      "city": "ELLERSLIE",
      "state": "PE"
  },
  {
      "city": "ELLIOT LAKE",
      "state": "ON"
  },
  {
      "city": "ELLISTON",
      "state": "NL"
  },
  {
      "city": "ELLSCOTT",
      "state": "AB"
  },
  {
      "city": "ELM CREEK",
      "state": "MB"
  },
  {
      "city": "ELM HILL",
      "state": "NB"
  },
  {
      "city": "ELMA",
      "state": "MB"
  },
  {
      "city": "ELMIRA",
      "state": "ON"
  },
  {
      "city": "ELMIRA",
      "state": "PE"
  },
  {
      "city": "ELMSDALE",
      "state": "NS"
  },
  {
      "city": "ELMSDALE",
      "state": "PE"
  },
  {
      "city": "ELMSVILLE",
      "state": "NB"
  },
  {
      "city": "ELMVALE",
      "state": "ON"
  },
  {
      "city": "ELMWOOD",
      "state": "NB"
  },
  {
      "city": "ELMWOOD",
      "state": "ON"
  },
  {
      "city": "ELMWORTH",
      "state": "AB"
  },
  {
      "city": "ELNORA",
      "state": "AB"
  },
  {
      "city": "ELORA",
      "state": "ON"
  },
  {
      "city": "ELPHINSTONE",
      "state": "MB"
  },
  {
      "city": "ELROSE",
      "state": "SK"
  },
  {
      "city": "ELSA",
      "state": "YT"
  },
  {
      "city": "ELSIPOGTOG FIRST NATION",
      "state": "NB"
  },
  {
      "city": "ELSTOW",
      "state": "SK"
  },
  {
      "city": "EM-1-A-SARCELLE-RUPERT",
      "state": "QC"
  },
  {
      "city": "EMBREE",
      "state": "NL"
  },
  {
      "city": "EMBRO",
      "state": "ON"
  },
  {
      "city": "EMBRUN",
      "state": "ON"
  },
  {
      "city": "EMERALD PARK",
      "state": "SK"
  },
  {
      "city": "EMERSON",
      "state": "MB"
  },
  {
      "city": "EMERYVILLE",
      "state": "ON"
  },
  {
      "city": "EMO",
      "state": "ON"
  },
  {
      "city": "EMPRESS",
      "state": "AB"
  },
  {
      "city": "EMSDALE",
      "state": "ON"
  },
  {
      "city": "ENCHANT",
      "state": "AB"
  },
  {
      "city": "ENDAKO",
      "state": "BC"
  },
  {
      "city": "ENDEAVOUR",
      "state": "SK"
  },
  {
      "city": "ENDERBY",
      "state": "BC"
  },
  {
      "city": "ENDIANG",
      "state": "AB"
  },
  {
      "city": "ENFIELD",
      "state": "NS"
  },
  {
      "city": "ENGLEE",
      "state": "NL"
  },
  {
      "city": "ENGLEFELD",
      "state": "SK"
  },
  {
      "city": "ENGLEHART",
      "state": "ON"
  },
  {
      "city": "ENGLISH HARBOUR EAST",
      "state": "NL"
  },
  {
      "city": "ENGLISH HARBOUR WEST",
      "state": "NL"
  },
  {
      "city": "ENGLISH SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "ENGLISHTOWN",
      "state": "NS"
  },
  {
      "city": "ENILDA",
      "state": "AB"
  },
  {
      "city": "ENNISMORE",
      "state": "ON"
  },
  {
      "city": "ENOCH",
      "state": "AB"
  },
  {
      "city": "ENON",
      "state": "NS"
  },
  {
      "city": "ENTERPRISE",
      "state": "NB"
  },
  {
      "city": "ENTERPRISE",
      "state": "NT"
  },
  {
      "city": "ENTERPRISE",
      "state": "ON"
  },
  {
      "city": "ENTRELACS",
      "state": "QC"
  },
  {
      "city": "ENTWISTLE",
      "state": "AB"
  },
  {
      "city": "EPWORTH",
      "state": "NL"
  },
  {
      "city": "ERB SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "ERBS COVE",
      "state": "NB"
  },
  {
      "city": "ERICKSON",
      "state": "BC"
  },
  {
      "city": "ERICKSON",
      "state": "MB"
  },
  {
      "city": "ERIEAU",
      "state": "ON"
  },
  {
      "city": "ERIKSDALE",
      "state": "MB"
  },
  {
      "city": "ERIN",
      "state": "ON"
  },
  {
      "city": "ERINSVILLE",
      "state": "ON"
  },
  {
      "city": "ERNFOLD",
      "state": "SK"
  },
  {
      "city": "ERRINGTON",
      "state": "BC"
  },
  {
      "city": "ERSKINE",
      "state": "AB"
  },
  {
      "city": "ESCUMINAC",
      "state": "NB"
  },
  {
      "city": "ESCUMINAC",
      "state": "QC"
  },
  {
      "city": "ESKASONI",
      "state": "NS"
  },
  {
      "city": "ESPANOLA",
      "state": "ON"
  },
  {
      "city": "ESPRIT-SAINT",
      "state": "QC"
  },
  {
      "city": "ESQUIMALT",
      "state": "BC"
  },
  {
      "city": "ESSEX",
      "state": "ON"
  },
  {
      "city": "ESTEREL",
      "state": "QC"
  },
  {
      "city": "ESTERHAZY",
      "state": "SK"
  },
  {
      "city": "ESTEVAN",
      "state": "SK"
  },
  {
      "city": "ESTEY'S BRIDGE",
      "state": "NB"
  },
  {
      "city": "ESTHER",
      "state": "AB"
  },
  {
      "city": "ESTON",
      "state": "SK"
  },
  {
      "city": "ETHEL",
      "state": "ON"
  },
  {
      "city": "ETHELBERT",
      "state": "MB"
  },
  {
      "city": "ETOBICOKE",
      "state": "ON"
  },
  {
      "city": "ETZIKOM",
      "state": "AB"
  },
  {
      "city": "EUREKA RIVER",
      "state": "AB"
  },
  {
      "city": "EUREKA",
      "state": "NS"
  },
  {
      "city": "EUREKA",
      "state": "NU"
  },
  {
      "city": "EVAIN",
      "state": "QC"
  },
  {
      "city": "EVANDALE",
      "state": "NB"
  },
  {
      "city": "EVANGELINE",
      "state": "NB"
  },
  {
      "city": "EVANS ROAD",
      "state": "NB"
  },
  {
      "city": "EVANSBURG",
      "state": "AB"
  },
  {
      "city": "EVANSVILLE",
      "state": "ON"
  },
  {
      "city": "EVERETT",
      "state": "NB"
  },
  {
      "city": "EVERETT",
      "state": "ON"
  },
  {
      "city": "EVESHAM",
      "state": "SK"
  },
  {
      "city": "EXETER",
      "state": "ON"
  },
  {
      "city": "EXMOOR",
      "state": "NB"
  },
  {
      "city": "EXSHAW",
      "state": "AB"
  },
  {
      "city": "EYEBROW",
      "state": "SK"
  },
  {
      "city": "FABRE",
      "state": "QC"
  },
  {
      "city": "FABREVILLE",
      "state": "QC"
  },
  {
      "city": "FAIR HAVEN",
      "state": "NL"
  },
  {
      "city": "FAIRFAX",
      "state": "MB"
  },
  {
      "city": "FAIRFIELD WESTMORLAND CO",
      "state": "NB"
  },
  {
      "city": "FAIRFIELD",
      "state": "NB"
  },
  {
      "city": "FAIRFORD",
      "state": "MB"
  },
  {
      "city": "FAIRHAVEN",
      "state": "NB"
  },
  {
      "city": "FAIRISLE",
      "state": "NB"
  },
  {
      "city": "FAIRLIGHT",
      "state": "SK"
  },
  {
      "city": "FAIRMONT HOT SPRINGS",
      "state": "BC"
  },
  {
      "city": "FAIRVIEW",
      "state": "AB"
  },
  {
      "city": "FAIRY GLEN",
      "state": "SK"
  },
  {
      "city": "FALARDEAU",
      "state": "QC"
  },
  {
      "city": "FALCON BEACH",
      "state": "MB"
  },
  {
      "city": "FALCONBRIDGE",
      "state": "ON"
  },
  {
      "city": "FALHER",
      "state": "AB"
  },
  {
      "city": "FALKLAND",
      "state": "BC"
  },
  {
      "city": "FALL RIVER",
      "state": "NS"
  },
  {
      "city": "FALLIS",
      "state": "AB"
  },
  {
      "city": "FALMOUTH",
      "state": "NS"
  },
  {
      "city": "FALUN",
      "state": "AB"
  },
  {
      "city": "FANNY BAY",
      "state": "BC"
  },
  {
      "city": "FANNYSTELLE",
      "state": "MB"
  },
  {
      "city": "FARMINGTON",
      "state": "BC"
  },
  {
      "city": "FARNHAM",
      "state": "QC"
  },
  {
      "city": "FARO",
      "state": "YT"
  },
  {
      "city": "FARRELLTON",
      "state": "QC"
  },
  {
      "city": "FASSETT",
      "state": "QC"
  },
  {
      "city": "FATIMA",
      "state": "QC"
  },
  {
      "city": "FAULKNER",
      "state": "MB"
  },
  {
      "city": "FAUQUIER",
      "state": "BC"
  },
  {
      "city": "FAUQUIER",
      "state": "ON"
  },
  {
      "city": "FAUST",
      "state": "AB"
  },
  {
      "city": "FAWCETT HILL",
      "state": "NB"
  },
  {
      "city": "FAWCETT",
      "state": "AB"
  },
  {
      "city": "FENELON FALLS",
      "state": "ON"
  },
  {
      "city": "FENN",
      "state": "AB"
  },
  {
      "city": "FENWICK",
      "state": "ON"
  },
  {
      "city": "FENWOOD",
      "state": "SK"
  },
  {
      "city": "FERGUS",
      "state": "ON"
  },
  {
      "city": "FERGUSONS COVE",
      "state": "NS"
  },
  {
      "city": "FERINTOSH",
      "state": "AB"
  },
  {
      "city": "FERLAND",
      "state": "SK"
  },
  {
      "city": "FERLAND-ET-BOILLEAU",
      "state": "QC"
  },
  {
      "city": "FERME-NEUVE",
      "state": "QC"
  },
  {
      "city": "FERMEUSE",
      "state": "NL"
  },
  {
      "city": "FERMONT",
      "state": "QC"
  },
  {
      "city": "FERNIE",
      "state": "BC"
  },
  {
      "city": "FERRYLAND",
      "state": "NL"
  },
  {
      "city": "FERTILE",
      "state": "SK"
  },
  {
      "city": "FEVERSHAM",
      "state": "ON"
  },
  {
      "city": "FIELD",
      "state": "BC"
  },
  {
      "city": "FIELD",
      "state": "ON"
  },
  {
      "city": "FIELDING",
      "state": "NB"
  },
  {
      "city": "FIFE LAKE",
      "state": "SK"
  },
  {
      "city": "FILLMORE",
      "state": "SK"
  },
  {
      "city": "FINCH",
      "state": "ON"
  },
  {
      "city": "FINDLATER",
      "state": "SK"
  },
  {
      "city": "FINGAL",
      "state": "ON"
  },
  {
      "city": "FINNEGAN",
      "state": "AB"
  },
  {
      "city": "FIR MOUNTAIN",
      "state": "SK"
  },
  {
      "city": "FISHER BRANCH",
      "state": "MB"
  },
  {
      "city": "FISHERMANS HARBOUR",
      "state": "NS"
  },
  {
      "city": "FISHERVILLE",
      "state": "ON"
  },
  {
      "city": "FISKE",
      "state": "SK"
  },
  {
      "city": "FITZGERALD",
      "state": "AB"
  },
  {
      "city": "FITZROY HARBOUR",
      "state": "ON"
  },
  {
      "city": "FIVE ISLANDS",
      "state": "NS"
  },
  {
      "city": "FLATBUSH",
      "state": "AB"
  },
  {
      "city": "FLATLANDS",
      "state": "NB"
  },
  {
      "city": "FLATROCK",
      "state": "NL"
  },
  {
      "city": "FLAXCOMBE",
      "state": "SK"
  },
  {
      "city": "FLEMING",
      "state": "SK"
  },
  {
      "city": "FLEMINGTON",
      "state": "NB"
  },
  {
      "city": "FLESHERTON",
      "state": "ON"
  },
  {
      "city": "FLETCHERS LAKE",
      "state": "NS"
  },
  {
      "city": "FLEUR DE LYS",
      "state": "NL"
  },
  {
      "city": "FLIN FLON",
      "state": "MB"
  },
  {
      "city": "FLINTOFT",
      "state": "SK"
  },
  {
      "city": "FLINTON",
      "state": "ON"
  },
  {
      "city": "FLORADALE",
      "state": "ON"
  },
  {
      "city": "FLORENCE",
      "state": "NS"
  },
  {
      "city": "FLORENCE",
      "state": "ON"
  },
  {
      "city": "FLORENCEVILLE-BRISTOL",
      "state": "NB"
  },
  {
      "city": "FLOWERS COVE",
      "state": "NB"
  },
  {
      "city": "FLOWERS COVE",
      "state": "NL"
  },
  {
      "city": "FLUME RIDGE",
      "state": "NB"
  },
  {
      "city": "FOAM LAKE",
      "state": "SK"
  },
  {
      "city": "FOGO",
      "state": "NL"
  },
  {
      "city": "FOISY",
      "state": "AB"
  },
  {
      "city": "FOLEYET",
      "state": "ON"
  },
  {
      "city": "FOND DU LAC",
      "state": "SK"
  },
  {
      "city": "FONTHILL",
      "state": "ON"
  },
  {
      "city": "FORD BANK",
      "state": "NB"
  },
  {
      "city": "FORDS MILLS",
      "state": "NB"
  },
  {
      "city": "FORDWICH",
      "state": "ON"
  },
  {
      "city": "FOREMOST",
      "state": "AB"
  },
  {
      "city": "FOREST CITY",
      "state": "NB"
  },
  {
      "city": "FOREST GLEN",
      "state": "NB"
  },
  {
      "city": "FOREST GROVE",
      "state": "BC"
  },
  {
      "city": "FOREST",
      "state": "ON"
  },
  {
      "city": "FORESTBURG",
      "state": "AB"
  },
  {
      "city": "FORESTERS FALLS",
      "state": "ON"
  },
  {
      "city": "FORESTVILLE",
      "state": "QC"
  },
  {
      "city": "FORGET",
      "state": "SK"
  },
  {
      "city": "FORK RIVER",
      "state": "MB"
  },
  {
      "city": "FORMOSA",
      "state": "ON"
  },
  {
      "city": "FORREST STATION",
      "state": "MB"
  },
  {
      "city": "FORT ALBANY",
      "state": "ON"
  },
  {
      "city": "FORT ALEXANDER",
      "state": "MB"
  },
  {
      "city": "FORT ASSINIBOINE",
      "state": "AB"
  },
  {
      "city": "FORT CHIPEWYAN",
      "state": "AB"
  },
  {
      "city": "FORT ELLIS",
      "state": "NS"
  },
  {
      "city": "FORT ERIE",
      "state": "ON"
  },
  {
      "city": "FORT FRANCES",
      "state": "ON"
  },
  {
      "city": "FORT FRASER",
      "state": "BC"
  },
  {
      "city": "FORT GOOD HOPE",
      "state": "NT"
  },
  {
      "city": "FORT KENT",
      "state": "AB"
  },
  {
      "city": "FORT LIARD",
      "state": "NT"
  },
  {
      "city": "FORT MACKAY",
      "state": "AB"
  },
  {
      "city": "FORT MACLEOD",
      "state": "AB"
  },
  {
      "city": "FORT MCMURRAY",
      "state": "AB"
  },
  {
      "city": "FORT MCPHERSON",
      "state": "NT"
  },
  {
      "city": "FORT NELSON",
      "state": "BC"
  },
  {
      "city": "FORT PROVIDENCE",
      "state": "NT"
  },
  {
      "city": "FORT QU'APPELLE",
      "state": "SK"
  },
  {
      "city": "FORT RESOLUTION",
      "state": "NT"
  },
  {
      "city": "FORT SASKATCHEWAN",
      "state": "AB"
  },
  {
      "city": "FORT SEVERN",
      "state": "ON"
  },
  {
      "city": "FORT SIMPSON",
      "state": "NT"
  },
  {
      "city": "FORT SMITH",
      "state": "NT"
  },
  {
      "city": "FORT ST. JAMES",
      "state": "BC"
  },
  {
      "city": "FORT ST. JOHN",
      "state": "BC"
  },
  {
      "city": "FORT STEELE",
      "state": "BC"
  },
  {
      "city": "FORT VERMILION",
      "state": "AB"
  },
  {
      "city": "FORT-COULONGE",
      "state": "QC"
  },
  {
      "city": "FORTEAU",
      "state": "NL"
  },
  {
      "city": "FORTIERVILLE",
      "state": "QC"
  },
  {
      "city": "FORTRESS OF LOUISBOURG",
      "state": "NS"
  },
  {
      "city": "FORTUNE",
      "state": "NL"
  },
  {
      "city": "FOSSAMBAULT-SUR-LE-LAC",
      "state": "QC"
  },
  {
      "city": "FOSSTON",
      "state": "SK"
  },
  {
      "city": "FOSTER",
      "state": "QC"
  },
  {
      "city": "FOSTERVILLE",
      "state": "NB"
  },
  {
      "city": "FOUR CORNERS",
      "state": "NB"
  },
  {
      "city": "FOUR FALLS",
      "state": "NB"
  },
  {
      "city": "FOUR ROADS",
      "state": "NB"
  },
  {
      "city": "FOURCHU",
      "state": "NS"
  },
  {
      "city": "FOURNIER",
      "state": "ON"
  },
  {
      "city": "FOX CREEK",
      "state": "AB"
  },
  {
      "city": "FOX HARBOUR PB",
      "state": "NL"
  },
  {
      "city": "FOX LAKE",
      "state": "AB"
  },
  {
      "city": "FOX VALLEY",
      "state": "SK"
  },
  {
      "city": "FOXBORO",
      "state": "ON"
  },
  {
      "city": "FOXFORD",
      "state": "SK"
  },
  {
      "city": "FOXWARREN",
      "state": "MB"
  },
  {
      "city": "FOYMOUNT",
      "state": "ON"
  },
  {
      "city": "FRAMBOISE INTERVALE",
      "state": "NS"
  },
  {
      "city": "FRAMBOISE",
      "state": "NS"
  },
  {
      "city": "FRAMPTON",
      "state": "QC"
  },
  {
      "city": "FRANCIS",
      "state": "SK"
  },
  {
      "city": "FRANCOIS LAKE",
      "state": "BC"
  },
  {
      "city": "FRANCOIS",
      "state": "NL"
  },
  {
      "city": "FRANKFORD",
      "state": "ON"
  },
  {
      "city": "FRANKLIN",
      "state": "MB"
  },
  {
      "city": "FRANKLIN",
      "state": "QC"
  },
  {
      "city": "FRANKVILLE",
      "state": "NS"
  },
  {
      "city": "FRANKVILLE",
      "state": "ON"
  },
  {
      "city": "FRANQUELIN",
      "state": "QC"
  },
  {
      "city": "FRASER LAKE",
      "state": "BC"
  },
  {
      "city": "FRASERVILLE",
      "state": "ON"
  },
  {
      "city": "FRASERWOOD",
      "state": "MB"
  },
  {
      "city": "FREDERICKHOUSE",
      "state": "ON"
  },
  {
      "city": "FREDERICKSBURG",
      "state": "NB"
  },
  {
      "city": "FREDERICKTON",
      "state": "NL"
  },
  {
      "city": "FREDERICTON JUNCTION",
      "state": "NB"
  },
  {
      "city": "FREDERICTON",
      "state": "NB"
  },
  {
      "city": "FREE GRANT",
      "state": "NB"
  },
  {
      "city": "FREELTON",
      "state": "ON"
  },
  {
      "city": "FREEPORT",
      "state": "NS"
  },
  {
      "city": "FREETOWN",
      "state": "PE"
  },
  {
      "city": "FRELIGHSBURG",
      "state": "QC"
  },
  {
      "city": "FRENCH LAKE",
      "state": "NB"
  },
  {
      "city": "FRENCH ROAD",
      "state": "NS"
  },
  {
      "city": "FRENCH VILLAGE KINGS CO",
      "state": "NB"
  },
  {
      "city": "FRENCH VILLAGE",
      "state": "NS"
  },
  {
      "city": "FRENCH VILLAGE-YORK",
      "state": "NB"
  },
  {
      "city": "FRENCHMAN BUTTE",
      "state": "SK"
  },
  {
      "city": "FRENCHMANS COVE BOI",
      "state": "NL"
  },
  {
      "city": "FRENCHMANS COVE FB",
      "state": "NL"
  },
  {
      "city": "FRENCHVALE",
      "state": "NS"
  },
  {
      "city": "FRESHWATER PB",
      "state": "NL"
  },
  {
      "city": "FROBISHER",
      "state": "SK"
  },
  {
      "city": "FROG LAKE",
      "state": "AB"
  },
  {
      "city": "FRONTENAC",
      "state": "QC"
  },
  {
      "city": "FRONTIER",
      "state": "SK"
  },
  {
      "city": "FROSTY HOLLOW",
      "state": "NB"
  },
  {
      "city": "FRUITVALE",
      "state": "BC"
  },
  {
      "city": "FUGEREVILLE",
      "state": "QC"
  },
  {
      "city": "FULDA",
      "state": "SK"
  },
  {
      "city": "FULFORD",
      "state": "QC"
  },
  {
      "city": "FULLARTON",
      "state": "ON"
  },
  {
      "city": "FUNDY NATIONAL PARK",
      "state": "NB"
  },
  {
      "city": "FURDALE",
      "state": "SK"
  },
  {
      "city": "FURRY CREEK",
      "state": "BC"
  },
  {
      "city": "GABARUS LAKE",
      "state": "NS"
  },
  {
      "city": "GABARUS",
      "state": "NS"
  },
  {
      "city": "GABRIOLA",
      "state": "BC"
  },
  {
      "city": "GADS HILL STATION",
      "state": "ON"
  },
  {
      "city": "GADSBY",
      "state": "AB"
  },
  {
      "city": "GAGETOWN",
      "state": "NB"
  },
  {
      "city": "GAINFORD",
      "state": "AB"
  },
  {
      "city": "GAINSBOROUGH",
      "state": "SK"
  },
  {
      "city": "GALAHAD",
      "state": "AB"
  },
  {
      "city": "GALIANO",
      "state": "BC"
  },
  {
      "city": "GALLAGHER RIDGE",
      "state": "NB"
  },
  {
      "city": "GALLANTS",
      "state": "NL"
  },
  {
      "city": "GALLICHAN",
      "state": "QC"
  },
  {
      "city": "GALLIVAN",
      "state": "SK"
  },
  {
      "city": "GALLIX",
      "state": "QC"
  },
  {
      "city": "GALLOWAY",
      "state": "BC"
  },
  {
      "city": "GALLOWAY",
      "state": "NB"
  },
  {
      "city": "GAMBO SOUTH",
      "state": "NL"
  },
  {
      "city": "GAMBO",
      "state": "NL"
  },
  {
      "city": "GAMETI",
      "state": "NT"
  },
  {
      "city": "GANANOQUE",
      "state": "ON"
  },
  {
      "city": "GANDER BAY SOUTH",
      "state": "NL"
  },
  {
      "city": "GANDER BAY",
      "state": "NL"
  },
  {
      "city": "GANDER",
      "state": "NL"
  },
  {
      "city": "GANG RANCH",
      "state": "BC"
  },
  {
      "city": "GARDEN BAY",
      "state": "BC"
  },
  {
      "city": "GARDEN COVE PB",
      "state": "NL"
  },
  {
      "city": "GARDEN RIVER",
      "state": "AB"
  },
  {
      "city": "GARDEN RIVER",
      "state": "ON"
  },
  {
      "city": "GARDEN VILLAGE",
      "state": "ON"
  },
  {
      "city": "GARDENTON",
      "state": "MB"
  },
  {
      "city": "GARDINER MINES",
      "state": "NS"
  },
  {
      "city": "GARDINER POINT",
      "state": "NB"
  },
  {
      "city": "GARDNER CREEK",
      "state": "NB"
  },
  {
      "city": "GARIBALDI HIGHLANDS",
      "state": "BC"
  },
  {
      "city": "GARLAND",
      "state": "MB"
  },
  {
      "city": "GARNETT SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "GARNISH",
      "state": "NL"
  },
  {
      "city": "GARRICK",
      "state": "SK"
  },
  {
      "city": "GARSON",
      "state": "MB"
  },
  {
      "city": "GARSON",
      "state": "ON"
  },
  {
      "city": "GASCONS",
      "state": "QC"
  },
  {
      "city": "GASPE",
      "state": "QC"
  },
  {
      "city": "GASPEREAU FORKS",
      "state": "NB"
  },
  {
      "city": "GATINEAU",
      "state": "ON"
  },
  {
      "city": "GATINEAU",
      "state": "QC"
  },
  {
      "city": "GAULTOIS",
      "state": "NL"
  },
  {
      "city": "GAUVREAU",
      "state": "NB"
  },
  {
      "city": "GAYS RIVER",
      "state": "NS"
  },
  {
      "city": "GEARY",
      "state": "NB"
  },
  {
      "city": "GEM",
      "state": "AB"
  },
  {
      "city": "GENELLE",
      "state": "BC"
  },
  {
      "city": "GEORGES RIVER",
      "state": "NS"
  },
  {
      "city": "GEORGETOWN",
      "state": "ON"
  },
  {
      "city": "GEORGETOWN",
      "state": "PE"
  },
  {
      "city": "GEORGEVILLE",
      "state": "QC"
  },
  {
      "city": "GERALD",
      "state": "SK"
  },
  {
      "city": "GERALDTON",
      "state": "ON"
  },
  {
      "city": "GERMANSEN LANDING",
      "state": "BC"
  },
  {
      "city": "GERMANTOWN",
      "state": "NB"
  },
  {
      "city": "GETHSEMANI",
      "state": "QC"
  },
  {
      "city": "GIANTS GLEN",
      "state": "NB"
  },
  {
      "city": "GIBBONS",
      "state": "AB"
  },
  {
      "city": "GIBSONS",
      "state": "BC"
  },
  {
      "city": "GIFT LAKE",
      "state": "AB"
  },
  {
      "city": "GILBERT PLAINS",
      "state": "MB"
  },
  {
      "city": "GILFORD",
      "state": "ON"
  },
  {
      "city": "GILLAM",
      "state": "MB"
  },
  {
      "city": "GILLIES BAY",
      "state": "BC"
  },
  {
      "city": "GILLIS LAKE",
      "state": "NS"
  },
  {
      "city": "GILLIS POINT",
      "state": "NS"
  },
  {
      "city": "GILMOUR",
      "state": "ON"
  },
  {
      "city": "GIMLI",
      "state": "MB"
  },
  {
      "city": "GINEW",
      "state": "MB"
  },
  {
      "city": "GIRARDVILLE",
      "state": "QC"
  },
  {
      "city": "GIROUX",
      "state": "MB"
  },
  {
      "city": "GIROUXVILLE",
      "state": "AB"
  },
  {
      "city": "GITWINKSIHLKW",
      "state": "BC"
  },
  {
      "city": "GJOA HAVEN",
      "state": "NU"
  },
  {
      "city": "GLACE BAY",
      "state": "NS"
  },
  {
      "city": "GLADESIDE",
      "state": "NB"
  },
  {
      "city": "GLADMAR",
      "state": "SK"
  },
  {
      "city": "GLADSTONE",
      "state": "MB"
  },
  {
      "city": "GLADWYN",
      "state": "NB"
  },
  {
      "city": "GLASLYN",
      "state": "SK"
  },
  {
      "city": "GLASNEVIN",
      "state": "SK"
  },
  {
      "city": "GLASSVILLE",
      "state": "NB"
  },
  {
      "city": "GLEICHEN",
      "state": "AB"
  },
  {
      "city": "GLEN EWEN",
      "state": "SK"
  },
  {
      "city": "GLEN HAVEN",
      "state": "NS"
  },
  {
      "city": "GLEN HURON",
      "state": "ON"
  },
  {
      "city": "GLEN MARGARET",
      "state": "NS"
  },
  {
      "city": "GLEN MORRIS",
      "state": "ON"
  },
  {
      "city": "GLEN ROBERTSON",
      "state": "ON"
  },
  {
      "city": "GLENAVON",
      "state": "SK"
  },
  {
      "city": "GLENBAIN",
      "state": "SK"
  },
  {
      "city": "GLENBORO",
      "state": "MB"
  },
  {
      "city": "GLENBURNIE",
      "state": "ON"
  },
  {
      "city": "GLENBUSH",
      "state": "SK"
  },
  {
      "city": "GLENCAIRN",
      "state": "ON"
  },
  {
      "city": "GLENCOE",
      "state": "NB"
  },
  {
      "city": "GLENCOE",
      "state": "ON"
  },
  {
      "city": "GLENDON",
      "state": "AB"
  },
  {
      "city": "GLENELLA",
      "state": "MB"
  },
  {
      "city": "GLENEVIS",
      "state": "AB"
  },
  {
      "city": "GLENLEA",
      "state": "MB"
  },
  {
      "city": "GLENLEVIT",
      "state": "NB"
  },
  {
      "city": "GLENORA",
      "state": "MB"
  },
  {
      "city": "GLENSIDE",
      "state": "SK"
  },
  {
      "city": "GLENTWORTH",
      "state": "SK"
  },
  {
      "city": "GLENVALE",
      "state": "NB"
  },
  {
      "city": "GLENWOOD KINGS CO",
      "state": "NB"
  },
  {
      "city": "GLENWOOD",
      "state": "AB"
  },
  {
      "city": "GLENWOOD",
      "state": "NB"
  },
  {
      "city": "GLENWOOD",
      "state": "NL"
  },
  {
      "city": "GLENWOOD",
      "state": "NS"
  },
  {
      "city": "GLENWOOD.",
      "state": "NB"
  },
  {
      "city": "GLIDDEN",
      "state": "SK"
  },
  {
      "city": "GLOUCESTER JUNCTION",
      "state": "NB"
  },
  {
      "city": "GLOUCESTER",
      "state": "ON"
  },
  {
      "city": "GLOVERTOWN SOUTH",
      "state": "NL"
  },
  {
      "city": "GLOVERTOWN",
      "state": "NL"
  },
  {
      "city": "GODBOUT",
      "state": "QC"
  },
  {
      "city": "GODERICH",
      "state": "ON"
  },
  {
      "city": "GODFREY",
      "state": "ON"
  },
  {
      "city": "GODMANCHESTER",
      "state": "QC"
  },
  {
      "city": "GODS LAKE NARROWS",
      "state": "MB"
  },
  {
      "city": "GODS RIVER",
      "state": "MB"
  },
  {
      "city": "GOFFS",
      "state": "NS"
  },
  {
      "city": "GOGAMA",
      "state": "ON"
  },
  {
      "city": "GOLD BRIDGE",
      "state": "BC"
  },
  {
      "city": "GOLD RIVER",
      "state": "BC"
  },
  {
      "city": "GOLDBORO",
      "state": "NS"
  },
  {
      "city": "GOLDEN LAKE",
      "state": "ON"
  },
  {
      "city": "GOLDEN PRAIRIE",
      "state": "SK"
  },
  {
      "city": "GOLDEN VALLEY",
      "state": "ON"
  },
  {
      "city": "GOLDEN",
      "state": "BC"
  },
  {
      "city": "GOOD CORNER",
      "state": "NB"
  },
  {
      "city": "GOOD HOPE LAKE",
      "state": "BC"
  },
  {
      "city": "GOODERHAM",
      "state": "ON"
  },
  {
      "city": "GOODEVE",
      "state": "SK"
  },
  {
      "city": "GOODFARE",
      "state": "AB"
  },
  {
      "city": "GOODFISH LAKE",
      "state": "AB"
  },
  {
      "city": "GOODLANDS",
      "state": "MB"
  },
  {
      "city": "GOODLOW",
      "state": "BC"
  },
  {
      "city": "GOODRIDGE",
      "state": "AB"
  },
  {
      "city": "GOODSOIL",
      "state": "SK"
  },
  {
      "city": "GOODWATER",
      "state": "SK"
  },
  {
      "city": "GOODWIN MILL",
      "state": "NB"
  },
  {
      "city": "GOODWOOD",
      "state": "NS"
  },
  {
      "city": "GOODWOOD",
      "state": "ON"
  },
  {
      "city": "GOOSEBERRY COVE",
      "state": "NL"
  },
  {
      "city": "GORDONDALE",
      "state": "AB"
  },
  {
      "city": "GORDONSVILLE",
      "state": "NB"
  },
  {
      "city": "GORE BAY",
      "state": "ON"
  },
  {
      "city": "GORE",
      "state": "QC"
  },
  {
      "city": "GORES LANDING",
      "state": "ON"
  },
  {
      "city": "GORMLEY",
      "state": "ON"
  },
  {
      "city": "GORRIE",
      "state": "ON"
  },
  {
      "city": "GOSHEN",
      "state": "NS"
  },
  {
      "city": "GOULAIS RIVER",
      "state": "ON"
  },
  {
      "city": "GOULDS",
      "state": "NL"
  },
  {
      "city": "GOULDTOWN",
      "state": "SK"
  },
  {
      "city": "GOVAN",
      "state": "SK"
  },
  {
      "city": "GOWANSTOWN",
      "state": "ON"
  },
  {
      "city": "GOWGANDA",
      "state": "ON"
  },
  {
      "city": "GRACEFIELD",
      "state": "QC"
  },
  {
      "city": "GRAFTON",
      "state": "NB"
  },
  {
      "city": "GRAFTON",
      "state": "ON"
  },
  {
      "city": "GRAHAM",
      "state": "ON"
  },
  {
      "city": "GRAHAMDALE",
      "state": "MB"
  },
  {
      "city": "GRANBY",
      "state": "QC"
  },
  {
      "city": "GRAND BANK",
      "state": "NL"
  },
  {
      "city": "GRAND BAY EAST",
      "state": "NL"
  },
  {
      "city": "GRAND BAY-WESTFIELD",
      "state": "NB"
  },
  {
      "city": "GRAND BEACH",
      "state": "NL"
  },
  {
      "city": "GRAND BEND",
      "state": "ON"
  },
  {
      "city": "GRAND BRUIT",
      "state": "NL"
  },
  {
      "city": "GRAND ETANG",
      "state": "NS"
  },
  {
      "city": "GRAND FALLS",
      "state": "NB"
  },
  {
      "city": "GRAND FALLS-WINDSOR",
      "state": "NL"
  },
  {
      "city": "GRAND FORKS",
      "state": "BC"
  },
  {
      "city": "GRAND LAKE ROAD",
      "state": "NB"
  },
  {
      "city": "GRAND LAKE ROAD",
      "state": "NS"
  },
  {
      "city": "GRAND LAKE",
      "state": "NS"
  },
  {
      "city": "GRAND LE PIERRE",
      "state": "NL"
  },
  {
      "city": "GRAND MANAN",
      "state": "NB"
  },
  {
      "city": "GRAND MARAIS",
      "state": "MB"
  },
  {
      "city": "GRAND MIRA NORTH",
      "state": "NS"
  },
  {
      "city": "GRAND MIRA SOUTH",
      "state": "NS"
  },
  {
      "city": "GRAND NARROWS",
      "state": "NS"
  },
  {
      "city": "GRAND PRE",
      "state": "NS"
  },
  {
      "city": "GRAND RAPIDS",
      "state": "MB"
  },
  {
      "city": "GRAND RIVER",
      "state": "NS"
  },
  {
      "city": "GRAND VALLEY",
      "state": "ON"
  },
  {
      "city": "GRAND-BARACHOIS",
      "state": "NB"
  },
  {
      "city": "GRAND-MERE",
      "state": "QC"
  },
  {
      "city": "GRAND-REMOUS",
      "state": "QC"
  },
  {
      "city": "GRAND-SAINT-ESPRIT",
      "state": "QC"
  },
  {
      "city": "GRAND-SAULT/GRAND FALLS",
      "state": "NB"
  },
  {
      "city": "GRANDE CACHE",
      "state": "AB"
  },
  {
      "city": "GRANDE POINTE",
      "state": "MB"
  },
  {
      "city": "GRANDE POINTE",
      "state": "ON"
  },
  {
      "city": "GRANDE PRAIRIE",
      "state": "AB"
  },
  {
      "city": "GRANDE-ANSE",
      "state": "NB"
  },
  {
      "city": "GRANDE-DIGUE",
      "state": "NB"
  },
  {
      "city": "GRANDE-ENTREE",
      "state": "QC"
  },
  {
      "city": "GRANDE-RIVIERE",
      "state": "QC"
  },
  {
      "city": "GRANDE-RIVIERE-OUEST",
      "state": "QC"
  },
  {
      "city": "GRANDE-VALLEE",
      "state": "QC"
  },
  {
      "city": "GRANDES-BERGERONNES",
      "state": "QC"
  },
  {
      "city": "GRANDES-PILES",
      "state": "QC"
  },
  {
      "city": "GRANDORA",
      "state": "SK"
  },
  {
      "city": "GRANDVIEW",
      "state": "MB"
  },
  {
      "city": "GRANISLE",
      "state": "BC"
  },
  {
      "city": "GRANTON",
      "state": "ON"
  },
  {
      "city": "GRANUM",
      "state": "AB"
  },
  {
      "city": "GRANVILLE FERRY",
      "state": "NS"
  },
  {
      "city": "GRASMERE",
      "state": "BC"
  },
  {
      "city": "GRASS COVE",
      "state": "NS"
  },
  {
      "city": "GRASSIE",
      "state": "ON"
  },
  {
      "city": "GRASSLAND",
      "state": "AB"
  },
  {
      "city": "GRASSWOOD",
      "state": "SK"
  },
  {
      "city": "GRASSY LAKE",
      "state": "AB"
  },
  {
      "city": "GRASSY NARROWS",
      "state": "ON"
  },
  {
      "city": "GRATES COVE",
      "state": "NL"
  },
  {
      "city": "GRAVEL HILL",
      "state": "NB"
  },
  {
      "city": "GRAVELBOURG",
      "state": "SK"
  },
  {
      "city": "GRAVENHURST",
      "state": "ON"
  },
  {
      "city": "GRAY CREEK",
      "state": "BC"
  },
  {
      "city": "GRAY RAPIDS",
      "state": "NB"
  },
  {
      "city": "GRAY",
      "state": "SK"
  },
  {
      "city": "GRAYSON",
      "state": "SK"
  },
  {
      "city": "GRAYSVILLE",
      "state": "MB"
  },
  {
      "city": "GREAT FALLS",
      "state": "MB"
  },
  {
      "city": "GREAT VILLAGE",
      "state": "NS"
  },
  {
      "city": "GREATER LAKEBURN",
      "state": "NB"
  },
  {
      "city": "GREELY",
      "state": "ON"
  },
  {
      "city": "GREEN BAY",
      "state": "NS"
  },
  {
      "city": "GREEN CREEK",
      "state": "NS"
  },
  {
      "city": "GREEN GABLES",
      "state": "PE"
  },
  {
      "city": "GREEN HILL",
      "state": "NB"
  },
  {
      "city": "GREEN ISLAND BROOK",
      "state": "NL"
  },
  {
      "city": "GREEN ISLAND COVE",
      "state": "NL"
  },
  {
      "city": "GREEN LAKE",
      "state": "SK"
  },
  {
      "city": "GREEN MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "GREEN OAKS",
      "state": "NS"
  },
  {
      "city": "GREEN RIDGE",
      "state": "MB"
  },
  {
      "city": "GREEN ROAD",
      "state": "NB"
  },
  {
      "city": "GREEN VALLEY",
      "state": "ON"
  },
  {
      "city": "GREENBANK",
      "state": "ON"
  },
  {
      "city": "GREENFIELD PARK",
      "state": "QC"
  },
  {
      "city": "GREENFIELD",
      "state": "NB"
  },
  {
      "city": "GREENFIELD",
      "state": "NS"
  },
  {
      "city": "GREENFIELD.",
      "state": "NS"
  },
  {
      "city": "GREENHILL LAKE",
      "state": "NB"
  },
  {
      "city": "GREENS HARBOUR",
      "state": "NL"
  },
  {
      "city": "GREENSPOND",
      "state": "NL"
  },
  {
      "city": "GREENVILLE",
      "state": "BC"
  },
  {
      "city": "GREENWOOD",
      "state": "BC"
  },
  {
      "city": "GREENWOOD",
      "state": "NS"
  },
  {
      "city": "GREENWOOD",
      "state": "ON"
  },
  {
      "city": "GREGG SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "GRENFELL",
      "state": "SK"
  },
  {
      "city": "GRENVILLE",
      "state": "QC"
  },
  {
      "city": "GRENVILLE-SUR-LA-ROUGE",
      "state": "QC"
  },
  {
      "city": "GRETNA",
      "state": "MB"
  },
  {
      "city": "GREY RIVER",
      "state": "NL"
  },
  {
      "city": "GRIFFIN",
      "state": "SK"
  },
  {
      "city": "GRIFFITH",
      "state": "ON"
  },
  {
      "city": "GRIMMS SETTLEMENT",
      "state": "NS"
  },
  {
      "city": "GRIMSBY",
      "state": "ON"
  },
  {
      "city": "GRIMSHAW",
      "state": "AB"
  },
  {
      "city": "GRINDROD",
      "state": "BC"
  },
  {
      "city": "GRISE FIORD",
      "state": "NU"
  },
  {
      "city": "GRISWOLD",
      "state": "MB"
  },
  {
      "city": "GRONDINES",
      "state": "QC"
  },
  {
      "city": "GRONLID",
      "state": "SK"
  },
  {
      "city": "GROS-MORNE",
      "state": "QC"
  },
  {
      "city": "GROSSE ISLE",
      "state": "MB"
  },
  {
      "city": "GROSSE-ILE",
      "state": "QC"
  },
  {
      "city": "GROSSES-ROCHES",
      "state": "QC"
  },
  {
      "city": "GROUARD",
      "state": "AB"
  },
  {
      "city": "GROUNDBIRCH",
      "state": "BC"
  },
  {
      "city": "GROVE HILL",
      "state": "NB"
  },
  {
      "city": "GROVEDALE",
      "state": "AB"
  },
  {
      "city": "GROVES POINT",
      "state": "NS"
  },
  {
      "city": "GRUNTHAL",
      "state": "MB"
  },
  {
      "city": "GUELPH",
      "state": "ON"
  },
  {
      "city": "GUERIN",
      "state": "QC"
  },
  {
      "city": "GUERNSEY",
      "state": "SK"
  },
  {
      "city": "GULL BAY",
      "state": "ON"
  },
  {
      "city": "GULL LAKE",
      "state": "SK"
  },
  {
      "city": "GUNDY",
      "state": "AB"
  },
  {
      "city": "GUNN",
      "state": "AB"
  },
  {
      "city": "GUNTON",
      "state": "MB"
  },
  {
      "city": "GUY",
      "state": "AB"
  },
  {
      "city": "GUYENNE",
      "state": "QC"
  },
  {
      "city": "GUYSBOROUGH",
      "state": "NS"
  },
  {
      "city": "GWYNNE",
      "state": "AB"
  },
  {
      "city": "GYPSUMVILLE",
      "state": "MB"
  },
  {
      "city": "HACHEYVILLE",
      "state": "NB"
  },
  {
      "city": "HACKETTS COVE",
      "state": "NS"
  },
  {
      "city": "HADASHVILLE",
      "state": "MB"
  },
  {
      "city": "HAFFORD",
      "state": "SK"
  },
  {
      "city": "HAGAR",
      "state": "ON"
  },
  {
      "city": "HAGEN",
      "state": "SK"
  },
  {
      "city": "HAGENSBORG",
      "state": "BC"
  },
  {
      "city": "HAGERSVILLE",
      "state": "ON"
  },
  {
      "city": "HAGUE",
      "state": "SK"
  },
  {
      "city": "HAILEYBURY",
      "state": "ON"
  },
  {
      "city": "HAINES JUNCTION",
      "state": "YT"
  },
  {
      "city": "HAIRY HILL",
      "state": "AB"
  },
  {
      "city": "HAISLA",
      "state": "BC"
  },
  {
      "city": "HALBRITE",
      "state": "SK"
  },
  {
      "city": "HALBSTADT",
      "state": "MB"
  },
  {
      "city": "HALCOMB",
      "state": "NB"
  },
  {
      "city": "HALEY STATION",
      "state": "ON"
  },
  {
      "city": "HALFMOON BAY",
      "state": "AB"
  },
  {
      "city": "HALFMOON BAY",
      "state": "BC"
  },
  {
      "city": "HALIBURTON",
      "state": "ON"
  },
  {
      "city": "HALIBUT BAY",
      "state": "NS"
  },
  {
      "city": "HALIFAX",
      "state": "NS"
  },
  {
      "city": "HALKIRK",
      "state": "AB"
  },
  {
      "city": "HALL BEACH",
      "state": "NU"
  },
  {
      "city": "HALLEBOURG",
      "state": "ON"
  },
  {
      "city": "HAM-NORD",
      "state": "QC"
  },
  {
      "city": "HAMILTON",
      "state": "ON"
  },
  {
      "city": "HAMIOTA",
      "state": "MB"
  },
  {
      "city": "HAMMOND",
      "state": "ON"
  },
  {
      "city": "HAMMONDS PLAINS",
      "state": "NS"
  },
  {
      "city": "HAMMONDVALE",
      "state": "NB"
  },
  {
      "city": "HAMPDEN",
      "state": "NL"
  },
  {
      "city": "HAMPSTEAD",
      "state": "NB"
  },
  {
      "city": "HAMPSTEAD",
      "state": "QC"
  },
  {
      "city": "HAMPTON",
      "state": "NB"
  },
  {
      "city": "HAMPTON",
      "state": "NS"
  },
  {
      "city": "HAMPTON",
      "state": "ON"
  },
  {
      "city": "HAMTOWN CORNER",
      "state": "NB"
  },
  {
      "city": "HANCEVILLE",
      "state": "BC"
  },
  {
      "city": "HANDEL",
      "state": "SK"
  },
  {
      "city": "HANEYTOWN",
      "state": "NB"
  },
  {
      "city": "HANFORD BROOK",
      "state": "NB"
  },
  {
      "city": "HANLEY",
      "state": "SK"
  },
  {
      "city": "HANMER",
      "state": "ON"
  },
  {
      "city": "HANNA",
      "state": "AB"
  },
  {
      "city": "HANNON",
      "state": "ON"
  },
  {
      "city": "HANOVER",
      "state": "ON"
  },
  {
      "city": "HANTS HARBOUR",
      "state": "NL"
  },
  {
      "city": "HANTSPORT",
      "state": "NS"
  },
  {
      "city": "HANWELL",
      "state": "NB"
  },
  {
      "city": "HAPPY VALLEY-GOOSE BAY",
      "state": "NL"
  },
  {
      "city": "HARBOUR BRETON",
      "state": "NL"
  },
  {
      "city": "HARBOUR GRACE SOUTH",
      "state": "NL"
  },
  {
      "city": "HARBOUR GRACE",
      "state": "NL"
  },
  {
      "city": "HARBOUR MAIN",
      "state": "NL"
  },
  {
      "city": "HARBOUR MILLE",
      "state": "NL"
  },
  {
      "city": "HARBOUR ROUND",
      "state": "NL"
  },
  {
      "city": "HARCOURT",
      "state": "NB"
  },
  {
      "city": "HARCOURT",
      "state": "ON"
  },
  {
      "city": "HARDING",
      "state": "MB"
  },
  {
      "city": "HARDISTY",
      "state": "AB"
  },
  {
      "city": "HARDWICKE",
      "state": "NB"
  },
  {
      "city": "HARDWOOD LANDS",
      "state": "NS"
  },
  {
      "city": "HARDWOOD RIDGE",
      "state": "NB"
  },
  {
      "city": "HARE BAY BB",
      "state": "NL"
  },
  {
      "city": "HAREWOOD",
      "state": "NB"
  },
  {
      "city": "HARGRAVE",
      "state": "MB"
  },
  {
      "city": "HARLEY",
      "state": "ON"
  },
  {
      "city": "HARMONY",
      "state": "NS"
  },
  {
      "city": "HARRIETSFIELD",
      "state": "NS"
  },
  {
      "city": "HARRIETSVILLE",
      "state": "ON"
  },
  {
      "city": "HARRINGTON HARBOUR",
      "state": "QC"
  },
  {
      "city": "HARRINGTON",
      "state": "QC"
  },
  {
      "city": "HARRIS",
      "state": "SK"
  },
  {
      "city": "HARRISON HOT SPRINGS",
      "state": "BC"
  },
  {
      "city": "HARRISON MILLS",
      "state": "BC"
  },
  {
      "city": "HARRISTON",
      "state": "ON"
  },
  {
      "city": "HARROGATE",
      "state": "BC"
  },
  {
      "city": "HARROW",
      "state": "ON"
  },
  {
      "city": "HARROWSMITH",
      "state": "ON"
  },
  {
      "city": "HARRYS HARBOUR",
      "state": "NL"
  },
  {
      "city": "HARTFIELD",
      "state": "NB"
  },
  {
      "city": "HARTFORD",
      "state": "NB"
  },
  {
      "city": "HARTIN SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "HARTINGTON",
      "state": "ON"
  },
  {
      "city": "HARTLAND",
      "state": "NB"
  },
  {
      "city": "HARTLEY BAY",
      "state": "BC"
  },
  {
      "city": "HARTLEY SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "HARTNEY",
      "state": "MB"
  },
  {
      "city": "HARTY",
      "state": "ON"
  },
  {
      "city": "HARVEY ALBERT CO",
      "state": "NB"
  },
  {
      "city": "HARVEY STATION",
      "state": "NB"
  },
  {
      "city": "HARVEY YORK CO",
      "state": "NB"
  },
  {
      "city": "HARVIE HEIGHTS",
      "state": "AB"
  },
  {
      "city": "HARWOOD",
      "state": "ON"
  },
  {
      "city": "HASTINGS",
      "state": "ON"
  },
  {
      "city": "HATCHET LAKE",
      "state": "NS"
  },
  {
      "city": "HATFIELD POINT",
      "state": "NB"
  },
  {
      "city": "HATLEY",
      "state": "QC"
  },
  {
      "city": "HAUT-LAMEQUE",
      "state": "NB"
  },
  {
      "city": "HAUT-PAQUETVILLE",
      "state": "NB"
  },
  {
      "city": "HAUT-RIVIERE-DU-PORTAGE",
      "state": "NB"
  },
  {
      "city": "HAUT-SAINT-ANTOINE",
      "state": "NB"
  },
  {
      "city": "HAUT-SHEILA",
      "state": "NB"
  },
  {
      "city": "HAUT-SHIPPAGAN",
      "state": "NB"
  },
  {
      "city": "HAUTE-ABOUJAGANE",
      "state": "NB"
  },
  {
      "city": "HAVELOCK",
      "state": "NB"
  },
  {
      "city": "HAVELOCK",
      "state": "ON"
  },
  {
      "city": "HAVELOCK",
      "state": "QC"
  },
  {
      "city": "HAVRE BOUCHER",
      "state": "NS"
  },
  {
      "city": "HAVRE-AUBERT",
      "state": "QC"
  },
  {
      "city": "HAVRE-AUX-MAISONS",
      "state": "QC"
  },
  {
      "city": "HAVRE-SAINT-PIERRE",
      "state": "QC"
  },
  {
      "city": "HAWARDEN",
      "state": "SK"
  },
  {
      "city": "HAWK JUNCTION",
      "state": "ON"
  },
  {
      "city": "HAWKES BAY",
      "state": "NL"
  },
  {
      "city": "HAWKESBURY",
      "state": "ON"
  },
  {
      "city": "HAWKESTONE",
      "state": "ON"
  },
  {
      "city": "HAWKESVILLE",
      "state": "ON"
  },
  {
      "city": "HAWKINS CORNER",
      "state": "NB"
  },
  {
      "city": "HAWKSHAW",
      "state": "NB"
  },
  {
      "city": "HAY LAKES",
      "state": "AB"
  },
  {
      "city": "HAY RIVER",
      "state": "NT"
  },
  {
      "city": "HAY SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "HAY",
      "state": "ON"
  },
  {
      "city": "HAYDON",
      "state": "ON"
  },
  {
      "city": "HAYESVILLE",
      "state": "NB"
  },
  {
      "city": "HAYMAN HILL",
      "state": "NB"
  },
  {
      "city": "HAYS",
      "state": "AB"
  },
  {
      "city": "HAYTER",
      "state": "AB"
  },
  {
      "city": "HAYWOOD",
      "state": "MB"
  },
  {
      "city": "HAZEL DELL",
      "state": "SK"
  },
  {
      "city": "HAZELDEAN",
      "state": "NB"
  },
  {
      "city": "HAZELRIDGE",
      "state": "MB"
  },
  {
      "city": "HAZELTON",
      "state": "BC"
  },
  {
      "city": "HAZELTON",
      "state": "NB"
  },
  {
      "city": "HAZENMORE",
      "state": "SK"
  },
  {
      "city": "HAZLET",
      "state": "SK"
  },
  {
      "city": "HEAD BAY D'ESPOIR",
      "state": "NL"
  },
  {
      "city": "HEAD OF CHEZZETCOOK",
      "state": "NS"
  },
  {
      "city": "HEAD OF JEDDORE",
      "state": "NS"
  },
  {
      "city": "HEAD OF MILLSTREAM",
      "state": "NB"
  },
  {
      "city": "HEAD OF ST MARGARETS BAY",
      "state": "NS"
  },
  {
      "city": "HEADINGLEY",
      "state": "MB"
  },
  {
      "city": "HEARNE",
      "state": "SK"
  },
  {
      "city": "HEARST",
      "state": "ON"
  },
  {
      "city": "HEART'S CONTENT",
      "state": "NL"
  },
  {
      "city": "HEART'S DELIGHT",
      "state": "NL"
  },
  {
      "city": "HEART'S DESIRE",
      "state": "NL"
  },
  {
      "city": "HEATHCOTE",
      "state": "ON"
  },
  {
      "city": "HEATHERTON",
      "state": "NL"
  },
  {
      "city": "HEATHERTON",
      "state": "NS"
  },
  {
      "city": "HEATHLAND",
      "state": "NB"
  },
  {
      "city": "HEBBS CROSS",
      "state": "NS"
  },
  {
      "city": "HEBBVILLE",
      "state": "NS"
  },
  {
      "city": "HEBERT",
      "state": "NB"
  },
  {
      "city": "HEBERTVILLE",
      "state": "QC"
  },
  {
      "city": "HEBERTVILLE-STATION",
      "state": "QC"
  },
  {
      "city": "HEBRON",
      "state": "NB"
  },
  {
      "city": "HEBRON",
      "state": "NS"
  },
  {
      "city": "HEDLEY",
      "state": "BC"
  },
  {
      "city": "HEFFLEY CREEK",
      "state": "BC"
  },
  {
      "city": "HEIDELBERG",
      "state": "ON"
  },
  {
      "city": "HEINSBURG",
      "state": "AB"
  },
  {
      "city": "HEISLER",
      "state": "AB"
  },
  {
      "city": "HEMMINGFORD",
      "state": "QC"
  },
  {
      "city": "HENDERSON SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "HENDON",
      "state": "SK"
  },
  {
      "city": "HENRIBOURG",
      "state": "SK"
  },
  {
      "city": "HENRYVILLE",
      "state": "QC"
  },
  {
      "city": "HENSALL",
      "state": "ON"
  },
  {
      "city": "HEPBURN",
      "state": "SK"
  },
  {
      "city": "HEPWORTH",
      "state": "ON"
  },
  {
      "city": "HERBERT",
      "state": "SK"
  },
  {
      "city": "HERIOT BAY",
      "state": "BC"
  },
  {
      "city": "HERMITAGE",
      "state": "NL"
  },
  {
      "city": "HERON BAY",
      "state": "ON"
  },
  {
      "city": "HEROUXVILLE",
      "state": "QC"
  },
  {
      "city": "HERRING COVE",
      "state": "NS"
  },
  {
      "city": "HERRING NECK",
      "state": "NL"
  },
  {
      "city": "HERSCHEL",
      "state": "SK"
  },
  {
      "city": "HERSONVILLE",
      "state": "NB"
  },
  {
      "city": "HEWARD",
      "state": "SK"
  },
  {
      "city": "HIBERNIA COVE",
      "state": "NB"
  },
  {
      "city": "HICKMANS HARBOUR",
      "state": "NL"
  },
  {
      "city": "HICKSON",
      "state": "ON"
  },
  {
      "city": "HICKSVILLE",
      "state": "NB"
  },
  {
      "city": "HIGH BLUFF",
      "state": "MB"
  },
  {
      "city": "HIGH LEVEL",
      "state": "AB"
  },
  {
      "city": "HIGH PRAIRIE",
      "state": "AB"
  },
  {
      "city": "HIGH RIVER",
      "state": "AB"
  },
  {
      "city": "HIGHFIELD",
      "state": "NB"
  },
  {
      "city": "HIGHGATE",
      "state": "ON"
  },
  {
      "city": "HIGHLAND GROVE",
      "state": "ON"
  },
  {
      "city": "HIGHLAND HILL",
      "state": "NS"
  },
  {
      "city": "HIGHLANDS",
      "state": "NL"
  },
  {
      "city": "HILBRE",
      "state": "MB"
  },
  {
      "city": "HILDA",
      "state": "AB"
  },
  {
      "city": "HILL SPRING",
      "state": "AB"
  },
  {
      "city": "HILLANDALE",
      "state": "NB"
  },
  {
      "city": "HILLCREST MINES",
      "state": "AB"
  },
  {
      "city": "HILLGRADE",
      "state": "NL"
  },
  {
      "city": "HILLGROVE",
      "state": "NB"
  },
  {
      "city": "HILLIARD",
      "state": "AB"
  },
  {
      "city": "HILLIARDTON",
      "state": "ON"
  },
  {
      "city": "HILLIER",
      "state": "ON"
  },
  {
      "city": "HILLSBOROUGH WEST",
      "state": "NB"
  },
  {
      "city": "HILLSBOROUGH",
      "state": "NB"
  },
  {
      "city": "HILLSBURGH",
      "state": "ON"
  },
  {
      "city": "HILLSDALE",
      "state": "NB"
  },
  {
      "city": "HILLSDALE",
      "state": "ON"
  },
  {
      "city": "HILLSIDE BOULARDERIE",
      "state": "NS"
  },
  {
      "city": "HILLTOP",
      "state": "NB"
  },
  {
      "city": "HILLVIEW",
      "state": "NL"
  },
  {
      "city": "HILTON BEACH",
      "state": "ON"
  },
  {
      "city": "HINCHINBROOKE",
      "state": "QC"
  },
  {
      "city": "HINES CREEK",
      "state": "AB"
  },
  {
      "city": "HINTON",
      "state": "AB"
  },
  {
      "city": "HIXON",
      "state": "BC"
  },
  {
      "city": "HOBBEMA",
      "state": "AB"
  },
  {
      "city": "HODGES COVE",
      "state": "NL"
  },
  {
      "city": "HODGEVILLE",
      "state": "SK"
  },
  {
      "city": "HODGSON",
      "state": "MB"
  },
  {
      "city": "HOEY",
      "state": "SK"
  },
  {
      "city": "HOLBEIN",
      "state": "SK"
  },
  {
      "city": "HOLBERG",
      "state": "BC"
  },
  {
      "city": "HOLDEN",
      "state": "AB"
  },
  {
      "city": "HOLDFAST",
      "state": "SK"
  },
  {
      "city": "HOLLAND CENTRE",
      "state": "ON"
  },
  {
      "city": "HOLLAND LANDING",
      "state": "ON"
  },
  {
      "city": "HOLLAND",
      "state": "MB"
  },
  {
      "city": "HOLMESVILLE",
      "state": "NB"
  },
  {
      "city": "HOLMFIELD",
      "state": "MB"
  },
  {
      "city": "HOLSTEIN",
      "state": "ON"
  },
  {
      "city": "HOLTVILLE",
      "state": "NB"
  },
  {
      "city": "HOLTYRE",
      "state": "ON"
  },
  {
      "city": "HOLYROOD",
      "state": "NL"
  },
  {
      "city": "HOLYROOD",
      "state": "ON"
  },
  {
      "city": "HOMEVILLE",
      "state": "NS"
  },
  {
      "city": "HOMEWOOD",
      "state": "MB"
  },
  {
      "city": "HONDO",
      "state": "AB"
  },
  {
      "city": "HONEY HARBOUR",
      "state": "ON"
  },
  {
      "city": "HONEYDALE",
      "state": "NB"
  },
  {
      "city": "HONEYMOON BAY",
      "state": "BC"
  },
  {
      "city": "HONEYWOOD",
      "state": "ON"
  },
  {
      "city": "HONFLEUR",
      "state": "QC"
  },
  {
      "city": "HOOSIER",
      "state": "SK"
  },
  {
      "city": "HOPE",
      "state": "BC"
  },
  {
      "city": "HOPE",
      "state": "QC"
  },
  {
      "city": "HOPEALL",
      "state": "NL"
  },
  {
      "city": "HOPEDALE",
      "state": "NL"
  },
  {
      "city": "HOPEWELL CAPE",
      "state": "NB"
  },
  {
      "city": "HOPEWELL HILL",
      "state": "NB"
  },
  {
      "city": "HOPEWELL",
      "state": "NS"
  },
  {
      "city": "HORNBY ISLAND",
      "state": "BC"
  },
  {
      "city": "HORNBY",
      "state": "ON"
  },
  {
      "city": "HORNDEAN",
      "state": "MB"
  },
  {
      "city": "HORNELL HEIGHTS",
      "state": "ON"
  },
  {
      "city": "HORNEPAYNE",
      "state": "ON"
  },
  {
      "city": "HORNINGS MILLS",
      "state": "ON"
  },
  {
      "city": "HORSEFLY",
      "state": "BC"
  },
  {
      "city": "HORWOOD",
      "state": "NL"
  },
  {
      "city": "HOTCHKISS",
      "state": "AB"
  },
  {
      "city": "HOUSTON",
      "state": "BC"
  },
  {
      "city": "HOWARD BROOK",
      "state": "NB"
  },
  {
      "city": "HOWARD",
      "state": "NB"
  },
  {
      "city": "HOWDEN",
      "state": "MB"
  },
  {
      "city": "HOWICK",
      "state": "QC"
  },
  {
      "city": "HOWIE CENTER",
      "state": "NS"
  },
  {
      "city": "HOWLAND RIDGE",
      "state": "NB"
  },
  {
      "city": "HOWLEY",
      "state": "NL"
  },
  {
      "city": "HOYT",
      "state": "NB"
  },
  {
      "city": "HUBBARD",
      "state": "SK"
  },
  {
      "city": "HUBBARDS",
      "state": "NS"
  },
  {
      "city": "HUBERDEAU",
      "state": "QC"
  },
  {
      "city": "HUBLEY",
      "state": "NS"
  },
  {
      "city": "HUDSON BAY",
      "state": "SK"
  },
  {
      "city": "HUDSON HEIGHTS",
      "state": "QC"
  },
  {
      "city": "HUDSON",
      "state": "ON"
  },
  {
      "city": "HUDSON",
      "state": "QC"
  },
  {
      "city": "HUDSON'S HOPE",
      "state": "BC"
  },
  {
      "city": "HUGHENDEN",
      "state": "AB"
  },
  {
      "city": "HUMBOLDT",
      "state": "SK"
  },
  {
      "city": "HUNTA",
      "state": "ON"
  },
  {
      "city": "HUNTERS HOME",
      "state": "NB"
  },
  {
      "city": "HUNTINGDON",
      "state": "QC"
  },
  {
      "city": "HUNTINGTON",
      "state": "NS"
  },
  {
      "city": "HUNTS POINT",
      "state": "NS"
  },
  {
      "city": "HUNTSVILLE",
      "state": "ON"
  },
  {
      "city": "HURON PARK",
      "state": "ON"
  },
  {
      "city": "HUSSAR",
      "state": "AB"
  },
  {
      "city": "HUXLEY",
      "state": "AB"
  },
  {
      "city": "HYAS",
      "state": "SK"
  },
  {
      "city": "HYLO",
      "state": "AB"
  },
  {
      "city": "HYTHE",
      "state": "AB"
  },
  {
      "city": "IDDESLEIGH",
      "state": "AB"
  },
  {
      "city": "IGLOOLIK",
      "state": "NU"
  },
  {
      "city": "IGNACE",
      "state": "ON"
  },
  {
      "city": "ILDERTON",
      "state": "ON"
  },
  {
      "city": "ILE DES CHENES",
      "state": "MB"
  },
  {
      "city": "ILE DU GRAND-CALUMET",
      "state": "QC"
  },
  {
      "city": "ILE-A-LA-CROSSE",
      "state": "SK"
  },
  {
      "city": "ILE-AUX-NOIX",
      "state": "QC"
  },
  {
      "city": "ILFORD",
      "state": "MB"
  },
  {
      "city": "IMMIGRANT ROAD",
      "state": "NB"
  },
  {
      "city": "IMPERIAL",
      "state": "SK"
  },
  {
      "city": "INDIAN BAY BB",
      "state": "NL"
  },
  {
      "city": "INDIAN BROOK 14",
      "state": "NS"
  },
  {
      "city": "INDIAN HARBOUR",
      "state": "NS"
  },
  {
      "city": "INDIAN HEAD",
      "state": "SK"
  },
  {
      "city": "INDIAN ISLAND",
      "state": "NB"
  },
  {
      "city": "INDIAN MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "INDIAN PATH",
      "state": "NS"
  },
  {
      "city": "INDIAN RIVER",
      "state": "ON"
  },
  {
      "city": "INDUS",
      "state": "AB"
  },
  {
      "city": "INGERSOLL",
      "state": "ON"
  },
  {
      "city": "INGLESIDE",
      "state": "ON"
  },
  {
      "city": "INGLEWOOD",
      "state": "ON"
  },
  {
      "city": "INGLIS",
      "state": "MB"
  },
  {
      "city": "INGOLF",
      "state": "ON"
  },
  {
      "city": "INGOMAR",
      "state": "NS"
  },
  {
      "city": "INGONISH BEACH",
      "state": "NS"
  },
  {
      "city": "INGONISH",
      "state": "NS"
  },
  {
      "city": "INGRAMPORT",
      "state": "NS"
  },
  {
      "city": "INKERMAN FERRY",
      "state": "NB"
  },
  {
      "city": "INKERMAN",
      "state": "NB"
  },
  {
      "city": "INKERMAN",
      "state": "ON"
  },
  {
      "city": "INNERKIP",
      "state": "ON"
  },
  {
      "city": "INNISFAIL",
      "state": "AB"
  },
  {
      "city": "INNISFIL",
      "state": "ON"
  },
  {
      "city": "INNISFREE",
      "state": "AB"
  },
  {
      "city": "INSINGER",
      "state": "SK"
  },
  {
      "city": "INTERVALE",
      "state": "NB"
  },
  {
      "city": "INUKJUAK",
      "state": "QC"
  },
  {
      "city": "INUVIK",
      "state": "NT"
  },
  {
      "city": "INVERARY",
      "state": "ON"
  },
  {
      "city": "INVERMAY",
      "state": "SK"
  },
  {
      "city": "INVERMERE",
      "state": "BC"
  },
  {
      "city": "INVERNESS",
      "state": "NS"
  },
  {
      "city": "INVERNESS",
      "state": "QC"
  },
  {
      "city": "INWOOD",
      "state": "MB"
  },
  {
      "city": "INWOOD",
      "state": "ON"
  },
  {
      "city": "IONA STATION",
      "state": "ON"
  },
  {
      "city": "IONA",
      "state": "NS"
  },
  {
      "city": "IQALUIT",
      "state": "NU"
  },
  {
      "city": "IRISH COVE",
      "state": "NS"
  },
  {
      "city": "IRISH SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "IRISHTOWN",
      "state": "NB"
  },
  {
      "city": "IRISHVALE",
      "state": "NS"
  },
  {
      "city": "IRLANDE",
      "state": "QC"
  },
  {
      "city": "IRMA",
      "state": "AB"
  },
  {
      "city": "IRON BOUND COVE",
      "state": "NB"
  },
  {
      "city": "IRON BRIDGE",
      "state": "ON"
  },
  {
      "city": "IRON RIVER",
      "state": "AB"
  },
  {
      "city": "IRON SPRINGS",
      "state": "AB"
  },
  {
      "city": "IRONDALE",
      "state": "ON"
  },
  {
      "city": "IRONVILLE",
      "state": "NS"
  },
  {
      "city": "IROQUOIS FALLS A",
      "state": "ON"
  },
  {
      "city": "IROQUOIS FALLS",
      "state": "ON"
  },
  {
      "city": "IROQUOIS",
      "state": "ON"
  },
  {
      "city": "IRRICANA",
      "state": "AB"
  },
  {
      "city": "IRVINE",
      "state": "AB"
  },
  {
      "city": "ISAACS HARBOUR",
      "state": "NS"
  },
  {
      "city": "ISABELLA",
      "state": "MB"
  },
  {
      "city": "ISKUT",
      "state": "BC"
  },
  {
      "city": "ISLAND HARBOUR",
      "state": "NL"
  },
  {
      "city": "ISLAND LAKE SOUTH",
      "state": "AB"
  },
  {
      "city": "ISLAND LAKE",
      "state": "AB"
  },
  {
      "city": "ISLAND LAKE",
      "state": "MB"
  },
  {
      "city": "ISLAND LAKE",
      "state": "SK"
  },
  {
      "city": "ISLAND VIEW",
      "state": "NB"
  },
  {
      "city": "ISLANDVIEW",
      "state": "NS"
  },
  {
      "city": "ISLAY",
      "state": "AB"
  },
  {
      "city": "ISLE-AUX-COUDRES",
      "state": "QC"
  },
  {
      "city": "ISLE-AUX-MORTS",
      "state": "NL"
  },
  {
      "city": "ISLINGTON",
      "state": "NL"
  },
  {
      "city": "ISSOUDUN",
      "state": "QC"
  },
  {
      "city": "ITALY CROSS",
      "state": "NS"
  },
  {
      "city": "ITUNA",
      "state": "SK"
  },
  {
      "city": "IVRY-SUR-LE-LAC",
      "state": "QC"
  },
  {
      "city": "IVUJIVIK",
      "state": "QC"
  },
  {
      "city": "JACKFISH LAKE",
      "state": "SK"
  },
  {
      "city": "JACKSON FALLS",
      "state": "NB"
  },
  {
      "city": "JACKSONS ARM",
      "state": "NL"
  },
  {
      "city": "JACKSONS COVE",
      "state": "NL"
  },
  {
      "city": "JACKSONS POINT",
      "state": "ON"
  },
  {
      "city": "JACKSONTOWN",
      "state": "NB"
  },
  {
      "city": "JACKSONVILLE",
      "state": "NB"
  },
  {
      "city": "JACQUES-CARTIER",
      "state": "QC"
  },
  {
      "city": "JADE CITY",
      "state": "BC"
  },
  {
      "city": "JAFFRAY",
      "state": "BC"
  },
  {
      "city": "JAMES RIVER BRIDGE",
      "state": "AB"
  },
  {
      "city": "JAMESVILLE",
      "state": "NS"
  },
  {
      "city": "JANETVILLE",
      "state": "ON"
  },
  {
      "city": "JANEVILLE",
      "state": "NB"
  },
  {
      "city": "JANSEN",
      "state": "SK"
  },
  {
      "city": "JARDINEVILLE",
      "state": "NB"
  },
  {
      "city": "JARVIE",
      "state": "AB"
  },
  {
      "city": "JARVIS BAY",
      "state": "AB"
  },
  {
      "city": "JARVIS",
      "state": "ON"
  },
  {
      "city": "JASPER",
      "state": "AB"
  },
  {
      "city": "JASPER",
      "state": "ON"
  },
  {
      "city": "JEAN COTE",
      "state": "AB"
  },
  {
      "city": "JEDBURGH",
      "state": "SK"
  },
  {
      "city": "JEDDORE OYSTER PONDS",
      "state": "NS"
  },
  {
      "city": "JEFFREYS",
      "state": "NL"
  },
  {
      "city": "JEFFRIES CORNER",
      "state": "NB"
  },
  {
      "city": "JELLICOE",
      "state": "ON"
  },
  {
      "city": "JEMSEG",
      "state": "NB"
  },
  {
      "city": "JENNER",
      "state": "AB"
  },
  {
      "city": "JERSEYSIDE",
      "state": "NL"
  },
  {
      "city": "JERSEYVILLE",
      "state": "ON"
  },
  {
      "city": "JEWETTS MILLS",
      "state": "NB"
  },
  {
      "city": "JOBS COVE",
      "state": "NL"
  },
  {
      "city": "JOE BATTS ARM",
      "state": "NL"
  },
  {
      "city": "JOGGINS",
      "state": "NS"
  },
  {
      "city": "JOGUES",
      "state": "ON"
  },
  {
      "city": "JOHN D'OR PRAIRIE",
      "state": "AB"
  },
  {
      "city": "JOHNSON SETTLEMENT CHARLOTTE C",
      "state": "NB"
  },
  {
      "city": "JOHNSON SETTLEMENT YORK CO",
      "state": "NB"
  },
  {
      "city": "JOHNSON'S MILLS",
      "state": "NB"
  },
  {
      "city": "JOHNSTON POINT",
      "state": "NB"
  },
  {
      "city": "JOHNVILLE",
      "state": "NB"
  },
  {
      "city": "JOLICURE",
      "state": "NB"
  },
  {
      "city": "JOLIETTE",
      "state": "QC"
  },
  {
      "city": "JOLY",
      "state": "QC"
  },
  {
      "city": "JONQUIERE",
      "state": "QC"
  },
  {
      "city": "JORDAN FALLS",
      "state": "NS"
  },
  {
      "city": "JORDAN MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "JORDAN RIVER",
      "state": "BC"
  },
  {
      "city": "JORDAN STATION",
      "state": "ON"
  },
  {
      "city": "JOUSSARD",
      "state": "AB"
  },
  {
      "city": "JOYCEVILLE",
      "state": "ON"
  },
  {
      "city": "JUDIQUE",
      "state": "NS"
  },
  {
      "city": "JUNIPER ISLAND",
      "state": "ON"
  },
  {
      "city": "JUNIPER MOUNTAIN",
      "state": "NS"
  },
  {
      "city": "JUNIPER",
      "state": "NB"
  },
  {
      "city": "JUSKATLA",
      "state": "BC"
  },
  {
      "city": "JUSTICE",
      "state": "MB"
  },
  {
      "city": "KAGAWONG",
      "state": "ON"
  },
  {
      "city": "KAHNAWAKE",
      "state": "QC"
  },
  {
      "city": "KAKABEKA FALLS",
      "state": "ON"
  },
  {
      "city": "KALADAR",
      "state": "ON"
  },
  {
      "city": "KALEDEN",
      "state": "BC"
  },
  {
      "city": "KAMINISTIQUIA",
      "state": "ON"
  },
  {
      "city": "KAMLOOPS",
      "state": "BC"
  },
  {
      "city": "KAMOURASKA",
      "state": "QC"
  },
  {
      "city": "KAMSACK",
      "state": "SK"
  },
  {
      "city": "KANANASKIS",
      "state": "AB"
  },
  {
      "city": "KANATA",
      "state": "ON"
  },
  {
      "city": "KANGIQSUALUJJUAQ",
      "state": "QC"
  },
  {
      "city": "KANGIQSUJUAQ",
      "state": "QC"
  },
  {
      "city": "KANGIRSUK",
      "state": "QC"
  },
  {
      "city": "KAPASIWIN",
      "state": "AB"
  },
  {
      "city": "KAPUSKASING",
      "state": "ON"
  },
  {
      "city": "KARS",
      "state": "NB"
  },
  {
      "city": "KARS",
      "state": "ON"
  },
  {
      "city": "KASABONIKA",
      "state": "ON"
  },
  {
      "city": "KASHABOWIE",
      "state": "ON"
  },
  {
      "city": "KASHECHEWAN",
      "state": "ON"
  },
  {
      "city": "KASLO",
      "state": "BC"
  },
  {
      "city": "KATHYRN",
      "state": "AB"
  },
  {
      "city": "KATRINE",
      "state": "ON"
  },
  {
      "city": "KAWAWACHIKAMACH",
      "state": "QC"
  },
  {
      "city": "KAYVILLE",
      "state": "SK"
  },
  {
      "city": "KAZABAZUA",
      "state": "QC"
  },
  {
      "city": "KEARNEY",
      "state": "ON"
  },
  {
      "city": "KEARNS",
      "state": "ON"
  },
  {
      "city": "KEDGWICK NORD",
      "state": "NB"
  },
  {
      "city": "KEDGWICK OUEST",
      "state": "NB"
  },
  {
      "city": "KEDGWICK RIVER",
      "state": "NB"
  },
  {
      "city": "KEDGWICK SUD",
      "state": "NB"
  },
  {
      "city": "KEDGWICK",
      "state": "NB"
  },
  {
      "city": "KEELER",
      "state": "SK"
  },
  {
      "city": "KEELS",
      "state": "NL"
  },
  {
      "city": "KEENANS",
      "state": "NB"
  },
  {
      "city": "KEENE",
      "state": "ON"
  },
  {
      "city": "KEEWATIN",
      "state": "ON"
  },
  {
      "city": "KEEWAYWIN",
      "state": "ON"
  },
  {
      "city": "KEG RIVER",
      "state": "AB"
  },
  {
      "city": "KEGASKA",
      "state": "QC"
  },
  {
      "city": "KEHEWIN",
      "state": "AB"
  },
  {
      "city": "KEJICK",
      "state": "ON"
  },
  {
      "city": "KELFIELD",
      "state": "SK"
  },
  {
      "city": "KELLIHER",
      "state": "SK"
  },
  {
      "city": "KELOWNA",
      "state": "BC"
  },
  {
      "city": "KELSEY",
      "state": "AB"
  },
  {
      "city": "KELVINGTON",
      "state": "SK"
  },
  {
      "city": "KELWOOD",
      "state": "MB"
  },
  {
      "city": "KEMBLE",
      "state": "ON"
  },
  {
      "city": "KEMPT HEAD",
      "state": "NS"
  },
  {
      "city": "KEMPTOWN",
      "state": "NS"
  },
  {
      "city": "KEMPTVILLE",
      "state": "NS"
  },
  {
      "city": "KEMPTVILLE",
      "state": "ON"
  },
  {
      "city": "KENABEEK",
      "state": "ON"
  },
  {
      "city": "KENASTON",
      "state": "SK"
  },
  {
      "city": "KENDAL",
      "state": "ON"
  },
  {
      "city": "KENDAL",
      "state": "SK"
  },
  {
      "city": "KENILWORTH",
      "state": "ON"
  },
  {
      "city": "KENMORE",
      "state": "ON"
  },
  {
      "city": "KENNEDY",
      "state": "SK"
  },
  {
      "city": "KENNETCOOK",
      "state": "NS"
  },
  {
      "city": "KENORA",
      "state": "ON"
  },
  {
      "city": "KENOSEE LAKE",
      "state": "SK"
  },
  {
      "city": "KENSINGTON",
      "state": "PE"
  },
  {
      "city": "KENT BRIDGE",
      "state": "ON"
  },
  {
      "city": "KENT JUNCTION",
      "state": "NB"
  },
  {
      "city": "KENTON",
      "state": "MB"
  },
  {
      "city": "KENTVILLE",
      "state": "NS"
  },
  {
      "city": "KENVILLE",
      "state": "MB"
  },
  {
      "city": "KEOMA",
      "state": "AB"
  },
  {
      "city": "KEREMEOS",
      "state": "BC"
  },
  {
      "city": "KERROBERT",
      "state": "SK"
  },
  {
      "city": "KERWOOD",
      "state": "ON"
  },
  {
      "city": "KESWICK RIDGE",
      "state": "NB"
  },
  {
      "city": "KESWICK",
      "state": "NB"
  },
  {
      "city": "KESWICK",
      "state": "ON"
  },
  {
      "city": "KETCH HARBOUR",
      "state": "NS"
  },
  {
      "city": "KETTLEBY",
      "state": "ON"
  },
  {
      "city": "KHEDIVE",
      "state": "SK"
  },
  {
      "city": "KIAMIKA",
      "state": "QC"
  },
  {
      "city": "KIERSTEAD MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "KIERSTEADVILLE",
      "state": "NB"
  },
  {
      "city": "KIKINO",
      "state": "AB"
  },
  {
      "city": "KILBRIDE",
      "state": "NL"
  },
  {
      "city": "KILBURN",
      "state": "NB"
  },
  {
      "city": "KILDONAN",
      "state": "BC"
  },
  {
      "city": "KILLALOE",
      "state": "ON"
  },
  {
      "city": "KILLALY",
      "state": "SK"
  },
  {
      "city": "KILLAM",
      "state": "AB"
  },
  {
      "city": "KILLAMS MILLS",
      "state": "NB"
  },
  {
      "city": "KILLARNEY ROAD",
      "state": "NB"
  },
  {
      "city": "KILLARNEY",
      "state": "MB"
  },
  {
      "city": "KILLARNEY",
      "state": "ON"
  },
  {
      "city": "KILLOWEEN",
      "state": "NB"
  },
  {
      "city": "KILWORTHY",
      "state": "ON"
  },
  {
      "city": "KIMBERLEY",
      "state": "BC"
  },
  {
      "city": "KIMBERLEY",
      "state": "ON"
  },
  {
      "city": "KIMMIRUT",
      "state": "NU"
  },
  {
      "city": "KINBURN",
      "state": "ON"
  },
  {
      "city": "KINCAID",
      "state": "SK"
  },
  {
      "city": "KINCARDINE",
      "state": "NB"
  },
  {
      "city": "KINCARDINE",
      "state": "ON"
  },
  {
      "city": "KINCOLITH",
      "state": "BC"
  },
  {
      "city": "KINDERSLEY",
      "state": "SK"
  },
  {
      "city": "KING CITY",
      "state": "ON"
  },
  {
      "city": "KING KIRKLAND",
      "state": "ON"
  },
  {
      "city": "KINGCOME INLET",
      "state": "BC"
  },
  {
      "city": "KINGFISHER LAKE",
      "state": "ON"
  },
  {
      "city": "KINGMAN",
      "state": "AB"
  },
  {
      "city": "KINGS COVE",
      "state": "NL"
  },
  {
      "city": "KINGS LANDING HISTORICAL SETTL",
      "state": "NB"
  },
  {
      "city": "KINGS POINT",
      "state": "NL"
  },
  {
      "city": "KINGSBURY",
      "state": "QC"
  },
  {
      "city": "KINGSCLEAR FIRST NATION",
      "state": "NB"
  },
  {
      "city": "KINGSEY FALLS",
      "state": "QC"
  },
  {
      "city": "KINGSGATE",
      "state": "BC"
  },
  {
      "city": "KINGSLEY",
      "state": "NB"
  },
  {
      "city": "KINGSTON",
      "state": "NB"
  },
  {
      "city": "KINGSTON",
      "state": "NS"
  },
  {
      "city": "KINGSTON",
      "state": "ON"
  },
  {
      "city": "KINGSVILLE",
      "state": "ON"
  },
  {
      "city": "KINISTINO",
      "state": "SK"
  },
  {
      "city": "KINKORA",
      "state": "PE"
  },
  {
      "city": "KINLEY",
      "state": "SK"
  },
  {
      "city": "KINMOUNT",
      "state": "ON"
  },
  {
      "city": "KINNEAR SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "KINNEAR'S MILLS",
      "state": "QC"
  },
  {
      "city": "KINOOSAO",
      "state": "SK"
  },
  {
      "city": "KINOSOTA",
      "state": "MB"
  },
  {
      "city": "KINSAC",
      "state": "NS"
  },
  {
      "city": "KINSELLA",
      "state": "AB"
  },
  {
      "city": "KINTORE",
      "state": "ON"
  },
  {
      "city": "KINUSO",
      "state": "AB"
  },
  {
      "city": "KIPAWA",
      "state": "QC"
  },
  {
      "city": "KIPLING",
      "state": "SK"
  },
  {
      "city": "KIPPEN",
      "state": "ON"
  },
  {
      "city": "KIPPENS",
      "state": "NL"
  },
  {
      "city": "KIRKELLA",
      "state": "MB"
  },
  {
      "city": "KIRKFIELD",
      "state": "ON"
  },
  {
      "city": "KIRKLAND LAKE",
      "state": "ON"
  },
  {
      "city": "KIRKLAND",
      "state": "NB"
  },
  {
      "city": "KIRKLAND",
      "state": "QC"
  },
  {
      "city": "KIRKTON",
      "state": "ON"
  },
  {
      "city": "KIRRIEMUIR",
      "state": "AB"
  },
  {
      "city": "KISBEY",
      "state": "SK"
  },
  {
      "city": "KITCHENER",
      "state": "BC"
  },
  {
      "city": "KITCHENER",
      "state": "ON"
  },
  {
      "city": "KITIMAT",
      "state": "BC"
  },
  {
      "city": "KITKATLA",
      "state": "BC"
  },
  {
      "city": "KITSCOTY",
      "state": "AB"
  },
  {
      "city": "KITWANGA",
      "state": "BC"
  },
  {
      "city": "KLEEFELD",
      "state": "MB"
  },
  {
      "city": "KLEENA KLEENE",
      "state": "BC"
  },
  {
      "city": "KLEINBURG",
      "state": "ON"
  },
  {
      "city": "KLEMTU",
      "state": "BC"
  },
  {
      "city": "KNIGHTS COVE",
      "state": "NL"
  },
  {
      "city": "KNIGHTVILLE",
      "state": "NB"
  },
  {
      "city": "KNOWLESVILLE",
      "state": "NB"
  },
  {
      "city": "KNOWLTON",
      "state": "QC"
  },
  {
      "city": "KNUTSFORD",
      "state": "BC"
  },
  {
      "city": "KOKSILAH",
      "state": "BC"
  },
  {
      "city": "KOLA",
      "state": "MB"
  },
  {
      "city": "KOMARNO",
      "state": "MB"
  },
  {
      "city": "KOMOKA",
      "state": "ON"
  },
  {
      "city": "KOOSTATAK",
      "state": "MB"
  },
  {
      "city": "KOOTENAY BAY",
      "state": "BC"
  },
  {
      "city": "KOUCHIBOUGUAC NATIONAL PARK",
      "state": "NB"
  },
  {
      "city": "KOUCHIBOUGUAC",
      "state": "NB"
  },
  {
      "city": "KRONAU",
      "state": "SK"
  },
  {
      "city": "KRYDOR",
      "state": "SK"
  },
  {
      "city": "KUGAARUK",
      "state": "NU"
  },
  {
      "city": "KUGLUKTUK",
      "state": "NU"
  },
  {
      "city": "KUROKI",
      "state": "SK"
  },
  {
      "city": "KUUJJUAQ",
      "state": "QC"
  },
  {
      "city": "KUUJJUARAPIK",
      "state": "QC"
  },
  {
      "city": "KYLE",
      "state": "SK"
  },
  {
      "city": "KYLEMORE",
      "state": "SK"
  },
  {
      "city": "KYUQUOT",
      "state": "BC"
  },
  {
      "city": "L'ALVERNE",
      "state": "QC"
  },
  {
      "city": "L'AMABLE",
      "state": "ON"
  },
  {
      "city": "L'ANCIENNE-LORETTE",
      "state": "QC"
  },
  {
      "city": "L'ANGE GARDIEN",
      "state": "QC"
  },
  {
      "city": "L'ANGE-GARDIEN",
      "state": "QC"
  },
  {
      "city": "L'ANSE AU CLAIR",
      "state": "NL"
  },
  {
      "city": "L'ANSE AU LOUP",
      "state": "NL"
  },
  {
      "city": "L'ANSE-PLEUREUSE",
      "state": "QC"
  },
  {
      "city": "L'ANSE-SAINT-JEAN",
      "state": "QC"
  },
  {
      "city": "L'ARDOISE",
      "state": "NS"
  },
  {
      "city": "L'ASCENSION",
      "state": "QC"
  },
  {
      "city": "L'ASCENSION-DE-NOTRE-SEIGNEUR",
      "state": "QC"
  },
  {
      "city": "L'ASCENSION-DE-PATAPEDIA",
      "state": "QC"
  },
  {
      "city": "L'ASSOMPTION",
      "state": "QC"
  },
  {
      "city": "L'AVENIR",
      "state": "QC"
  },
  {
      "city": "L'EPIPHANIE",
      "state": "QC"
  },
  {
      "city": "L'ETANG-DU-NORD",
      "state": "QC"
  },
  {
      "city": "L'ETETE",
      "state": "NB"
  },
  {
      "city": "L'ILE-BIZARD",
      "state": "QC"
  },
  {
      "city": "L'ILE-CADIEUX",
      "state": "QC"
  },
  {
      "city": "L'ILE-D'ENTREE",
      "state": "QC"
  },
  {
      "city": "L'ILE-MICHON",
      "state": "QC"
  },
  {
      "city": "L'ILE-PERROT",
      "state": "QC"
  },
  {
      "city": "L'ISLE-AUX-GRUES",
      "state": "QC"
  },
  {
      "city": "L'ISLE-VERTE",
      "state": "QC"
  },
  {
      "city": "L'ISLET",
      "state": "QC"
  },
  {
      "city": "L'ISLETVILLE",
      "state": "QC"
  },
  {
      "city": "L'ORIGNAL",
      "state": "ON"
  },
  {
      "city": "LA BAIE",
      "state": "QC"
  },
  {
      "city": "LA BALEINE",
      "state": "QC"
  },
  {
      "city": "LA BARRIERE",
      "state": "MB"
  },
  {
      "city": "LA BOSTONNAIS",
      "state": "QC"
  },
  {
      "city": "LA BROQUERIE",
      "state": "MB"
  },
  {
      "city": "LA CONCEPTION",
      "state": "QC"
  },
  {
      "city": "LA COREY",
      "state": "AB"
  },
  {
      "city": "LA CORNE",
      "state": "QC"
  },
  {
      "city": "LA CRETE",
      "state": "AB"
  },
  {
      "city": "LA CROCHE",
      "state": "QC"
  },
  {
      "city": "LA DORE",
      "state": "QC"
  },
  {
      "city": "LA DURANTAYE",
      "state": "QC"
  },
  {
      "city": "LA GLACE",
      "state": "AB"
  },
  {
      "city": "LA GUADELOUPE",
      "state": "QC"
  },
  {
      "city": "LA HAVE",
      "state": "NS"
  },
  {
      "city": "LA LOCHE",
      "state": "SK"
  },
  {
      "city": "LA MACAZA",
      "state": "QC"
  },
  {
      "city": "LA MALBAIE",
      "state": "QC"
  },
  {
      "city": "LA MARTRE",
      "state": "QC"
  },
  {
      "city": "LA MINERVE",
      "state": "QC"
  },
  {
      "city": "LA MORANDIERE",
      "state": "QC"
  },
  {
      "city": "LA MOTTE",
      "state": "QC"
  },
  {
      "city": "LA PATRIE",
      "state": "QC"
  },
  {
      "city": "LA POCATIERE",
      "state": "QC"
  },
  {
      "city": "LA POILE",
      "state": "NL"
  },
  {
      "city": "LA PRAIRIE",
      "state": "QC"
  },
  {
      "city": "LA PRESENTATION",
      "state": "QC"
  },
  {
      "city": "LA REDEMPTION",
      "state": "QC"
  },
  {
      "city": "LA REINE",
      "state": "QC"
  },
  {
      "city": "LA RIVIERE",
      "state": "MB"
  },
  {
      "city": "LA RONGE",
      "state": "SK"
  },
  {
      "city": "LA SALETTE",
      "state": "ON"
  },
  {
      "city": "LA SALLE",
      "state": "MB"
  },
  {
      "city": "LA SARRE",
      "state": "QC"
  },
  {
      "city": "LA SCIE",
      "state": "NL"
  },
  {
      "city": "LA TABATIERE",
      "state": "QC"
  },
  {
      "city": "LA TRINITE-DES-MONTS",
      "state": "QC"
  },
  {
      "city": "LA TUQUE",
      "state": "QC"
  },
  {
      "city": "LA VISITATION-DE-L'ILE-DUPAS",
      "state": "QC"
  },
  {
      "city": "LA VISITATION-DE-YAMASKA",
      "state": "QC"
  },
  {
      "city": "LABELLE",
      "state": "NS"
  },
  {
      "city": "LABELLE",
      "state": "QC"
  },
  {
      "city": "LABRADOR CITY",
      "state": "NL"
  },
  {
      "city": "LABRECQUE",
      "state": "QC"
  },
  {
      "city": "LAC BAKER",
      "state": "NB"
  },
  {
      "city": "LAC BROCHET",
      "state": "MB"
  },
  {
      "city": "LAC DES ARCS",
      "state": "AB"
  },
  {
      "city": "LAC DES LOUPS",
      "state": "QC"
  },
  {
      "city": "LAC DU BONNET",
      "state": "MB"
  },
  {
      "city": "LAC LA BICHE",
      "state": "AB"
  },
  {
      "city": "LAC LA HACHE",
      "state": "BC"
  },
  {
      "city": "LAC SEUL",
      "state": "ON"
  },
  {
      "city": "LAC VERT",
      "state": "SK"
  },
  {
      "city": "LAC-A-LA-TORTUE",
      "state": "QC"
  },
  {
      "city": "LAC-AU-SAUMON",
      "state": "QC"
  },
  {
      "city": "LAC-AUX-SABLES",
      "state": "QC"
  },
  {
      "city": "LAC-BEAUPORT",
      "state": "QC"
  },
  {
      "city": "LAC-BOUCHETTE",
      "state": "QC"
  },
  {
      "city": "LAC-CAYAMANT",
      "state": "QC"
  },
  {
      "city": "LAC-DELAGE",
      "state": "QC"
  },
  {
      "city": "LAC-DES-AIGLES",
      "state": "QC"
  },
  {
      "city": "LAC-DES-ECORCES",
      "state": "QC"
  },
  {
      "city": "LAC-DES-ILES",
      "state": "QC"
  },
  {
      "city": "LAC-DES-PLAGES",
      "state": "QC"
  },
  {
      "city": "LAC-DES-SEIZE-ILES",
      "state": "QC"
  },
  {
      "city": "LAC-DROLET",
      "state": "QC"
  },
  {
      "city": "LAC-DU-CERF",
      "state": "QC"
  },
  {
      "city": "LAC-EDOUARD",
      "state": "QC"
  },
  {
      "city": "LAC-ETCHEMIN",
      "state": "QC"
  },
  {
      "city": "LAC-FRONTIERE",
      "state": "QC"
  },
  {
      "city": "LAC-HUMQUI",
      "state": "QC"
  },
  {
      "city": "LAC-KENOGAMI",
      "state": "QC"
  },
  {
      "city": "LAC-MEGANTIC",
      "state": "QC"
  },
  {
      "city": "LAC-SAGUAY",
      "state": "QC"
  },
  {
      "city": "LAC-SAINT-JOSEPH",
      "state": "QC"
  },
  {
      "city": "LAC-SAINT-PAUL",
      "state": "QC"
  },
  {
      "city": "LAC-SAINTE-MARIE",
      "state": "QC"
  },
  {
      "city": "LAC-SERGENT",
      "state": "QC"
  },
  {
      "city": "LAC-SIMON",
      "state": "QC"
  },
  {
      "city": "LAC-SUPERIEUR",
      "state": "QC"
  },
  {
      "city": "LACADENA",
      "state": "SK"
  },
  {
      "city": "LACHINE",
      "state": "QC"
  },
  {
      "city": "LACHUTE",
      "state": "QC"
  },
  {
      "city": "LACOLLE",
      "state": "QC"
  },
  {
      "city": "LACOMBE COUNTY",
      "state": "AB"
  },
  {
      "city": "LACOMBE",
      "state": "AB"
  },
  {
      "city": "LACONIA",
      "state": "NS"
  },
  {
      "city": "LADLE COVE",
      "state": "NL"
  },
  {
      "city": "LADRIERE",
      "state": "QC"
  },
  {
      "city": "LADYSMITH",
      "state": "BC"
  },
  {
      "city": "LADYSMITH",
      "state": "QC"
  },
  {
      "city": "LAFLECHE",
      "state": "SK"
  },
  {
      "city": "LAFOND",
      "state": "AB"
  },
  {
      "city": "LAFORCE",
      "state": "QC"
  },
  {
      "city": "LAGACEVILLE",
      "state": "NB"
  },
  {
      "city": "LAIRD",
      "state": "SK"
  },
  {
      "city": "LAJORD",
      "state": "SK"
  },
  {
      "city": "LAKE ALMA",
      "state": "SK"
  },
  {
      "city": "LAKE AUDY",
      "state": "MB"
  },
  {
      "city": "LAKE CHARLOTTE",
      "state": "NS"
  },
  {
      "city": "LAKE COWICHAN",
      "state": "BC"
  },
  {
      "city": "LAKE ECHO",
      "state": "NS"
  },
  {
      "city": "LAKE EDWARD",
      "state": "NB"
  },
  {
      "city": "LAKE EGMONT",
      "state": "NS"
  },
  {
      "city": "LAKE ERROCK",
      "state": "BC"
  },
  {
      "city": "LAKE FRANCIS",
      "state": "MB"
  },
  {
      "city": "LAKE GEORGE",
      "state": "NB"
  },
  {
      "city": "LAKE ISLE",
      "state": "AB"
  },
  {
      "city": "LAKE LENORE",
      "state": "SK"
  },
  {
      "city": "LAKE LOUISE",
      "state": "AB"
  },
  {
      "city": "LAKE MANITOBA FIRST NATION",
      "state": "MB"
  },
  {
      "city": "LAKE OF BAYS",
      "state": "ON"
  },
  {
      "city": "LAKE ST PETER",
      "state": "ON"
  },
  {
      "city": "LAKE UIST",
      "state": "NS"
  },
  {
      "city": "LAKEFIELD",
      "state": "ON"
  },
  {
      "city": "LAKEHURST",
      "state": "ON"
  },
  {
      "city": "LAKELAND",
      "state": "MB"
  },
  {
      "city": "LAKESIDE",
      "state": "NB"
  },
  {
      "city": "LAKESIDE",
      "state": "NS"
  },
  {
      "city": "LAKESIDE",
      "state": "ON"
  },
  {
      "city": "LAKETON",
      "state": "NB"
  },
  {
      "city": "LAKEVIEW",
      "state": "NS"
  },
  {
      "city": "LAKEVILLE CARLETON CO",
      "state": "NB"
  },
  {
      "city": "LAKEVILLE CORNER",
      "state": "NB"
  },
  {
      "city": "LAKEVILLE-WESTMORLAND",
      "state": "NB"
  },
  {
      "city": "LAMALINE",
      "state": "NL"
  },
  {
      "city": "LAMARCHE",
      "state": "QC"
  },
  {
      "city": "LAMARTINE",
      "state": "QC"
  },
  {
      "city": "LAMBERT'S COVE",
      "state": "NB"
  },
  {
      "city": "LAMBERTVILLE",
      "state": "NB"
  },
  {
      "city": "LAMBTON",
      "state": "QC"
  },
  {
      "city": "LAMEQUE",
      "state": "NB"
  },
  {
      "city": "LAMONT",
      "state": "AB"
  },
  {
      "city": "LAMPMAN",
      "state": "SK"
  },
  {
      "city": "LANARK",
      "state": "ON"
  },
  {
      "city": "LANCASTER PARK",
      "state": "AB"
  },
  {
      "city": "LANCASTER",
      "state": "ON"
  },
  {
      "city": "LANCE COVE",
      "state": "NL"
  },
  {
      "city": "LANCER",
      "state": "SK"
  },
  {
      "city": "LANDIS",
      "state": "SK"
  },
  {
      "city": "LANDMARK",
      "state": "MB"
  },
  {
      "city": "LANDRIENNE",
      "state": "QC"
  },
  {
      "city": "LANDRY OFFICE",
      "state": "NB"
  },
  {
      "city": "LANESVILLE",
      "state": "NS"
  },
  {
      "city": "LANG",
      "state": "SK"
  },
  {
      "city": "LANGBANK",
      "state": "SK"
  },
  {
      "city": "LANGDON",
      "state": "AB"
  },
  {
      "city": "LANGENBURG",
      "state": "SK"
  },
  {
      "city": "LANGHAM",
      "state": "SK"
  },
  {
      "city": "LANGLEY",
      "state": "BC"
  },
  {
      "city": "LANGRUTH",
      "state": "MB"
  },
  {
      "city": "LANGTON",
      "state": "ON"
  },
  {
      "city": "LANIEL",
      "state": "QC"
  },
  {
      "city": "LANIGAN",
      "state": "SK"
  },
  {
      "city": "LANORAIE",
      "state": "QC"
  },
  {
      "city": "LANSDOWNE HOUSE",
      "state": "ON"
  },
  {
      "city": "LANSDOWNE",
      "state": "NB"
  },
  {
      "city": "LANSDOWNE",
      "state": "ON"
  },
  {
      "city": "LANTIER",
      "state": "QC"
  },
  {
      "city": "LANTZ",
      "state": "NS"
  },
  {
      "city": "LANTZVILLE",
      "state": "BC"
  },
  {
      "city": "LAPLAND",
      "state": "NS"
  },
  {
      "city": "LAPLANTE",
      "state": "NB"
  },
  {
      "city": "LAPORTE",
      "state": "SK"
  },
  {
      "city": "LARDER LAKE",
      "state": "ON"
  },
  {
      "city": "LARK HARBOUR",
      "state": "NL"
  },
  {
      "city": "LAROUCHE",
      "state": "QC"
  },
  {
      "city": "LARRYS RIVER",
      "state": "NS"
  },
  {
      "city": "LASALLE",
      "state": "ON"
  },
  {
      "city": "LASALLE",
      "state": "QC"
  },
  {
      "city": "LASHBURN",
      "state": "SK"
  },
  {
      "city": "LASQUETI",
      "state": "BC"
  },
  {
      "city": "LATCHFORD",
      "state": "ON"
  },
  {
      "city": "LATERRIERE",
      "state": "QC"
  },
  {
      "city": "LATULIPE",
      "state": "QC"
  },
  {
      "city": "LAUDER",
      "state": "MB"
  },
  {
      "city": "LAUNAY",
      "state": "QC"
  },
  {
      "city": "LAUREL",
      "state": "ON"
  },
  {
      "city": "LAURENCETON",
      "state": "NL"
  },
  {
      "city": "LAURIER",
      "state": "MB"
  },
  {
      "city": "LAURIER-STATION",
      "state": "QC"
  },
  {
      "city": "LAURIERVILLE",
      "state": "QC"
  },
  {
      "city": "LAVAL",
      "state": "QC"
  },
  {
      "city": "LAVAL-OUEST",
      "state": "QC"
  },
  {
      "city": "LAVALTRIE",
      "state": "QC"
  },
  {
      "city": "LAVENHAM",
      "state": "MB"
  },
  {
      "city": "LAVERLOCHERE",
      "state": "QC"
  },
  {
      "city": "LAVIGNE",
      "state": "ON"
  },
  {
      "city": "LAVILLETTE",
      "state": "NB"
  },
  {
      "city": "LAVINGTON",
      "state": "BC"
  },
  {
      "city": "LAVOY",
      "state": "AB"
  },
  {
      "city": "LAWN",
      "state": "NL"
  },
  {
      "city": "LAWRENCE STATION",
      "state": "NB"
  },
  {
      "city": "LAWRENCETOWN",
      "state": "NS"
  },
  {
      "city": "LAWRENCETOWN.",
      "state": "NS"
  },
  {
      "city": "LAWRENCEVILLE",
      "state": "QC"
  },
  {
      "city": "LAX KW'ALAAMS",
      "state": "BC"
  },
  {
      "city": "LAZO",
      "state": "BC"
  },
  {
      "city": "LE BIC",
      "state": "QC"
  },
  {
      "city": "LE GARDEUR",
      "state": "QC"
  },
  {
      "city": "LE GOULET",
      "state": "NB"
  },
  {
      "city": "LEADER",
      "state": "SK"
  },
  {
      "city": "LEADING TICKLES",
      "state": "NL"
  },
  {
      "city": "LEAF RAPIDS",
      "state": "MB"
  },
  {
      "city": "LEAMINGTON",
      "state": "ON"
  },
  {
      "city": "LEASK",
      "state": "SK"
  },
  {
      "city": "LEASKDALE",
      "state": "ON"
  },
  {
      "city": "LEBEL-SUR-QUEVILLON",
      "state": "QC"
  },
  {
      "city": "LEBRET",
      "state": "SK"
  },
  {
      "city": "LECLERCVILLE",
      "state": "QC"
  },
  {
      "city": "LEDUC COUNTY",
      "state": "AB"
  },
  {
      "city": "LEDUC",
      "state": "AB"
  },
  {
      "city": "LEE SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "LEECH",
      "state": "NB"
  },
  {
      "city": "LEFAIVRE",
      "state": "ON"
  },
  {
      "city": "LEFEBVRE",
      "state": "QC"
  },
  {
      "city": "LEFROY",
      "state": "ON"
  },
  {
      "city": "LEGAL",
      "state": "AB"
  },
  {
      "city": "LEITCHES CREEK",
      "state": "NS"
  },
  {
      "city": "LEITH",
      "state": "ON"
  },
  {
      "city": "LEJEUNE",
      "state": "QC"
  },
  {
      "city": "LEMBERG",
      "state": "SK"
  },
  {
      "city": "LEMIEUX",
      "state": "QC"
  },
  {
      "city": "LEMOYNE",
      "state": "QC"
  },
  {
      "city": "LENNOX ISLAND",
      "state": "PE"
  },
  {
      "city": "LENORE",
      "state": "MB"
  },
  {
      "city": "LEONARD COLONY",
      "state": "NB"
  },
  {
      "city": "LEONARDVILLE",
      "state": "NB"
  },
  {
      "city": "LEOVILLE",
      "state": "SK"
  },
  {
      "city": "LEPREAU",
      "state": "NB"
  },
  {
      "city": "LEROSS",
      "state": "SK"
  },
  {
      "city": "LEROY",
      "state": "SK"
  },
  {
      "city": "LERY",
      "state": "QC"
  },
  {
      "city": "LES BUISSONS",
      "state": "QC"
  },
  {
      "city": "LES CEDRES",
      "state": "QC"
  },
  {
      "city": "LES COTEAUX",
      "state": "QC"
  },
  {
      "city": "LES EBOULEMENTS",
      "state": "QC"
  },
  {
      "city": "LES ESCOUMINS",
      "state": "QC"
  },
  {
      "city": "LES HAUTEURS",
      "state": "QC"
  },
  {
      "city": "LES MECHINS",
      "state": "QC"
  },
  {
      "city": "LESLIE",
      "state": "SK"
  },
  {
      "city": "LESLIEVILLE",
      "state": "AB"
  },
  {
      "city": "LESTOCK",
      "state": "SK"
  },
  {
      "city": "LETANG",
      "state": "NB"
  },
  {
      "city": "LETELLIER",
      "state": "MB"
  },
  {
      "city": "LETHBRIDGE",
      "state": "AB"
  },
  {
      "city": "LETHBRIDGE",
      "state": "NL"
  },
  {
      "city": "LEVACK",
      "state": "ON"
  },
  {
      "city": "LEVERVILLE",
      "state": "NB"
  },
  {
      "city": "LEVIS",
      "state": "QC"
  },
  {
      "city": "LEWINS COVE",
      "state": "NL"
  },
  {
      "city": "LEWIS LAKE",
      "state": "NS"
  },
  {
      "city": "LEWIS MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "LEWISPORTE",
      "state": "NL"
  },
  {
      "city": "LEWVAN",
      "state": "SK"
  },
  {
      "city": "LEXINGTON",
      "state": "NS"
  },
  {
      "city": "LIBAU",
      "state": "MB"
  },
  {
      "city": "LIBERTY",
      "state": "SK"
  },
  {
      "city": "LIEBENTHAL",
      "state": "SK"
  },
  {
      "city": "LIKELY",
      "state": "BC"
  },
  {
      "city": "LILLOOET",
      "state": "BC"
  },
  {
      "city": "LIMEHOUSE",
      "state": "ON"
  },
  {
      "city": "LIMEKILN",
      "state": "NB"
  },
  {
      "city": "LIMERICK",
      "state": "SK"
  },
  {
      "city": "LIMESTONE",
      "state": "NB"
  },
  {
      "city": "LIMOGES",
      "state": "ON"
  },
  {
      "city": "LINCOLN",
      "state": "NB"
  },
  {
      "city": "LINDALE",
      "state": "AB"
  },
  {
      "city": "LINDBERGH",
      "state": "AB"
  },
  {
      "city": "LINDELL BEACH",
      "state": "BC"
  },
  {
      "city": "LINDEN",
      "state": "AB"
  },
  {
      "city": "LINDSAY",
      "state": "NB"
  },
  {
      "city": "LINDSAY",
      "state": "ON"
  },
  {
      "city": "LINGAN ROAD",
      "state": "NS"
  },
  {
      "city": "LINGAN",
      "state": "NS"
  },
  {
      "city": "LINGWICK",
      "state": "QC"
  },
  {
      "city": "LINTLAW",
      "state": "SK"
  },
  {
      "city": "LINTON CORNER",
      "state": "NB"
  },
  {
      "city": "LINWOOD",
      "state": "ON"
  },
  {
      "city": "LIONS BAY",
      "state": "BC"
  },
  {
      "city": "LIONS HEAD",
      "state": "ON"
  },
  {
      "city": "LIPTON",
      "state": "SK"
  },
  {
      "city": "LISCOMB",
      "state": "NS"
  },
  {
      "city": "LISIEUX",
      "state": "SK"
  },
  {
      "city": "LISLE",
      "state": "ON"
  },
  {
      "city": "LISTER",
      "state": "BC"
  },
  {
      "city": "LISTOWEL",
      "state": "ON"
  },
  {
      "city": "LISTUGUJ",
      "state": "QC"
  },
  {
      "city": "LITTLE BARTIBOG",
      "state": "NB"
  },
  {
      "city": "LITTLE BAY EAST",
      "state": "NL"
  },
  {
      "city": "LITTLE BAY ISLANDS",
      "state": "NL"
  },
  {
      "city": "LITTLE BAY NDB",
      "state": "NL"
  },
  {
      "city": "LITTLE BAY PB",
      "state": "NL"
  },
  {
      "city": "LITTLE BRAS D'OR",
      "state": "NS"
  },
  {
      "city": "LITTLE BRITAIN",
      "state": "ON"
  },
  {
      "city": "LITTLE BROOK",
      "state": "NS"
  },
  {
      "city": "LITTLE BULLHEAD",
      "state": "MB"
  },
  {
      "city": "LITTLE BURNT BAY",
      "state": "NL"
  },
  {
      "city": "LITTLE CATALINA",
      "state": "NL"
  },
  {
      "city": "LITTLE CURRENT",
      "state": "ON"
  },
  {
      "city": "LITTLE DOVER",
      "state": "NS"
  },
  {
      "city": "LITTLE FORT",
      "state": "BC"
  },
  {
      "city": "LITTLE GRAND RAPIDS",
      "state": "MB"
  },
  {
      "city": "LITTLE HARBOUR EAST PB",
      "state": "NL"
  },
  {
      "city": "LITTLE HEARTS EASE",
      "state": "NL"
  },
  {
      "city": "LITTLE LEPREAU",
      "state": "NB"
  },
  {
      "city": "LITTLE LORRAINE",
      "state": "NS"
  },
  {
      "city": "LITTLE NARROWS",
      "state": "NS"
  },
  {
      "city": "LITTLE POND",
      "state": "NS"
  },
  {
      "city": "LITTLE RIDGE",
      "state": "NB"
  },
  {
      "city": "LITTLE RIVER ALBERT CO",
      "state": "NB"
  },
  {
      "city": "LITTLE RIVER GLOUCESTER CO",
      "state": "NB"
  },
  {
      "city": "LITTLE RIVER HILL",
      "state": "NB"
  },
  {
      "city": "LITTLE RIVER",
      "state": "NS"
  },
  {
      "city": "LITTLE SALMON RIVER WEST",
      "state": "NB"
  },
  {
      "city": "LITTLE SHEMOGUE",
      "state": "NB"
  },
  {
      "city": "LITTLE SMOKY",
      "state": "AB"
  },
  {
      "city": "LITTLE ST LAWRENCE",
      "state": "NL"
  },
  {
      "city": "LITTLE TANCOOK",
      "state": "NS"
  },
  {
      "city": "LIVELONG",
      "state": "SK"
  },
  {
      "city": "LIVELY",
      "state": "ON"
  },
  {
      "city": "LIVERPOOL",
      "state": "NS"
  },
  {
      "city": "LLOYDMINSTER",
      "state": "AB"
  },
  {
      "city": "LLOYDMINSTER",
      "state": "SK"
  },
  {
      "city": "LOCH LOMOND",
      "state": "NS"
  },
  {
      "city": "LOCHLIN",
      "state": "ON"
  },
  {
      "city": "LOCKEPORT",
      "state": "NS"
  },
  {
      "city": "LOCKPORT",
      "state": "MB"
  },
  {
      "city": "LOCKSTEAD",
      "state": "NB"
  },
  {
      "city": "LOCKWOOD",
      "state": "SK"
  },
  {
      "city": "LOCUST HILL",
      "state": "ON"
  },
  {
      "city": "LODGE BAY",
      "state": "NL"
  },
  {
      "city": "LODGEPOLE",
      "state": "AB"
  },
  {
      "city": "LOGAN LAKE",
      "state": "BC"
  },
  {
      "city": "LOGY BAY",
      "state": "NL"
  },
  {
      "city": "LOMBARDY",
      "state": "ON"
  },
  {
      "city": "LOMOND",
      "state": "AB"
  },
  {
      "city": "LONDESBOROUGH",
      "state": "ON"
  },
  {
      "city": "LONDON",
      "state": "ON"
  },
  {
      "city": "LONDONDERRY",
      "state": "NB"
  },
  {
      "city": "LONDONDERRY",
      "state": "NS"
  },
  {
      "city": "LONE BUTTE",
      "state": "BC"
  },
  {
      "city": "LONE PINE",
      "state": "AB"
  },
  {
      "city": "LONE ROCK",
      "state": "SK"
  },
  {
      "city": "LONG CREEK",
      "state": "NB"
  },
  {
      "city": "LONG HARBOUR",
      "state": "NL"
  },
  {
      "city": "LONG ISLAND",
      "state": "NS"
  },
  {
      "city": "LONG POINT",
      "state": "NB"
  },
  {
      "city": "LONG POINT",
      "state": "NS"
  },
  {
      "city": "LONG REACH",
      "state": "NB"
  },
  {
      "city": "LONG SAULT",
      "state": "ON"
  },
  {
      "city": "LONG SETTLEMENT KINGS CO",
      "state": "NB"
  },
  {
      "city": "LONG SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "LONGBOW LAKE",
      "state": "ON"
  },
  {
      "city": "LONGFORD MILLS",
      "state": "ON"
  },
  {
      "city": "LONGLAC",
      "state": "ON"
  },
  {
      "city": "LONGUE-POINTE-DE-MINGAN",
      "state": "QC"
  },
  {
      "city": "LONGUE-RIVE",
      "state": "QC"
  },
  {
      "city": "LONGUEUIL",
      "state": "QC"
  },
  {
      "city": "LONGVIEW",
      "state": "AB"
  },
  {
      "city": "LONGWORTH",
      "state": "BC"
  },
  {
      "city": "LOON BAY",
      "state": "NL"
  },
  {
      "city": "LOON LAKE",
      "state": "SK"
  },
  {
      "city": "LORD'S COVE",
      "state": "NB"
  },
  {
      "city": "LOREBURN",
      "state": "SK"
  },
  {
      "city": "LORETTE",
      "state": "MB"
  },
  {
      "city": "LORETTO",
      "state": "ON"
  },
  {
      "city": "LORING",
      "state": "ON"
  },
  {
      "city": "LORNE",
      "state": "NB"
  },
  {
      "city": "LORRAINE",
      "state": "QC"
  },
  {
      "city": "LORRAINVILLE",
      "state": "QC"
  },
  {
      "city": "LOSIER SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "LOTBINIERE",
      "state": "QC"
  },
  {
      "city": "LOTS-RENVERSES",
      "state": "QC"
  },
  {
      "city": "LOUGHEED",
      "state": "AB"
  },
  {
      "city": "LOUIS CREEK",
      "state": "BC"
  },
  {
      "city": "LOUISBOURG",
      "state": "NS"
  },
  {
      "city": "LOUISDALE",
      "state": "NS"
  },
  {
      "city": "LOUISEVILLE",
      "state": "QC"
  },
  {
      "city": "LOURDES",
      "state": "NL"
  },
  {
      "city": "LOURDES",
      "state": "QC"
  },
  {
      "city": "LOURDES-DE-BLANC-SABLON",
      "state": "QC"
  },
  {
      "city": "LOURDES-DE-JOLIETTE",
      "state": "QC"
  },
  {
      "city": "LOUSANA",
      "state": "AB"
  },
  {
      "city": "LOVE",
      "state": "SK"
  },
  {
      "city": "LOVERNA",
      "state": "SK"
  },
  {
      "city": "LOW",
      "state": "QC"
  },
  {
      "city": "LOWBANKS",
      "state": "ON"
  },
  {
      "city": "LOWE FARM",
      "state": "MB"
  },
  {
      "city": "LOWER BRANCH",
      "state": "NS"
  },
  {
      "city": "LOWER BRIGHTON",
      "state": "NB"
  },
  {
      "city": "LOWER CAMBRIDGE",
      "state": "NB"
  },
  {
      "city": "LOWER CAPE",
      "state": "NB"
  },
  {
      "city": "LOWER COVE",
      "state": "NB"
  },
  {
      "city": "LOWER COVERDALE",
      "state": "NB"
  },
  {
      "city": "LOWER DERBY",
      "state": "NB"
  },
  {
      "city": "LOWER EAST PUBNICO",
      "state": "NS"
  },
  {
      "city": "LOWER FIVE ISLANDS",
      "state": "NS"
  },
  {
      "city": "LOWER GREENWICH",
      "state": "NB"
  },
  {
      "city": "LOWER HAINESVILLE",
      "state": "NB"
  },
  {
      "city": "LOWER ISLAND COVE",
      "state": "NL"
  },
  {
      "city": "LOWER KINGSCLEAR",
      "state": "NB"
  },
  {
      "city": "LOWER KINTORE",
      "state": "NB"
  },
  {
      "city": "LOWER KNOXFORD",
      "state": "NB"
  },
  {
      "city": "LOWER L'ARDOISE",
      "state": "NS"
  },
  {
      "city": "LOWER MILLSTREAM",
      "state": "NB"
  },
  {
      "city": "LOWER NEWCASTLE",
      "state": "NB"
  },
  {
      "city": "LOWER NICOLA",
      "state": "BC"
  },
  {
      "city": "LOWER NORTHFIELD",
      "state": "NS"
  },
  {
      "city": "LOWER NORTON",
      "state": "NB"
  },
  {
      "city": "LOWER ONSLOW",
      "state": "NS"
  },
  {
      "city": "LOWER POST",
      "state": "BC"
  },
  {
      "city": "LOWER PROSPECT",
      "state": "NS"
  },
  {
      "city": "LOWER QUEENSBURY",
      "state": "NB"
  },
  {
      "city": "LOWER SACKVILLE",
      "state": "NS"
  },
  {
      "city": "LOWER ST MARYS",
      "state": "NB"
  },
  {
      "city": "LOWER TRURO",
      "state": "NS"
  },
  {
      "city": "LOWER WASHABUCK",
      "state": "NS"
  },
  {
      "city": "LOWER WEDGEPORT",
      "state": "NS"
  },
  {
      "city": "LOWER WEST PUBNICO",
      "state": "NS"
  },
  {
      "city": "LOWER WOODS HARBOUR",
      "state": "NS"
  },
  {
      "city": "LOWER WOODSTOCK",
      "state": "NB"
  },
  {
      "city": "LUCAN",
      "state": "ON"
  },
  {
      "city": "LUCASVILLE",
      "state": "NS"
  },
  {
      "city": "LUCKNOW",
      "state": "ON"
  },
  {
      "city": "LUCKY LAKE",
      "state": "SK"
  },
  {
      "city": "LUDLOW",
      "state": "NB"
  },
  {
      "city": "LUGAR",
      "state": "NB"
  },
  {
      "city": "LUMBY",
      "state": "BC"
  },
  {
      "city": "LUMSDEN",
      "state": "NL"
  },
  {
      "city": "LUMSDEN",
      "state": "SK"
  },
  {
      "city": "LUND",
      "state": "BC"
  },
  {
      "city": "LUNDAR",
      "state": "MB"
  },
  {
      "city": "LUNDBRECK",
      "state": "AB"
  },
  {
      "city": "LUNENBURG",
      "state": "NS"
  },
  {
      "city": "LUNENBURG",
      "state": "ON"
  },
  {
      "city": "LUSELAND",
      "state": "SK"
  },
  {
      "city": "LUSKVILLE",
      "state": "QC"
  },
  {
      "city": "LUTES MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "LUTSELK'E",
      "state": "NT"
  },
  {
      "city": "LYALTA",
      "state": "AB"
  },
  {
      "city": "LYDGATE",
      "state": "NS"
  },
  {
      "city": "LYLETON",
      "state": "MB"
  },
  {
      "city": "LYN",
      "state": "ON"
  },
  {
      "city": "LYNDEN",
      "state": "ON"
  },
  {
      "city": "LYNDHURST",
      "state": "ON"
  },
  {
      "city": "LYNN LAKE",
      "state": "MB"
  },
  {
      "city": "LYNNFIELD",
      "state": "NB"
  },
  {
      "city": "LYSTER",
      "state": "QC"
  },
  {
      "city": "LYTTLETON",
      "state": "NB"
  },
  {
      "city": "LYTTON",
      "state": "BC"
  },
  {
      "city": "M'CHIGEENG",
      "state": "ON"
  },
  {
      "city": "MA-ME-O BEACH",
      "state": "AB"
  },
  {
      "city": "MABERLY",
      "state": "ON"
  },
  {
      "city": "MABOU",
      "state": "NS"
  },
  {
      "city": "MACADAMS LAKE",
      "state": "NS"
  },
  {
      "city": "MACAMIC",
      "state": "QC"
  },
  {
      "city": "MACCAN",
      "state": "NS"
  },
  {
      "city": "MACDIARMID",
      "state": "ON"
  },
  {
      "city": "MACDONALD",
      "state": "MB"
  },
  {
      "city": "MACDOUGALL SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "MACDOWALL",
      "state": "SK"
  },
  {
      "city": "MACES BAY",
      "state": "NB"
  },
  {
      "city": "MACGREGOR",
      "state": "MB"
  },
  {
      "city": "MACKAY SIDING",
      "state": "NS"
  },
  {
      "city": "MACKDALE",
      "state": "NS"
  },
  {
      "city": "MACKENZIE",
      "state": "BC"
  },
  {
      "city": "MACKEY",
      "state": "ON"
  },
  {
      "city": "MACKINNONS HARBOUR",
      "state": "NS"
  },
  {
      "city": "MACKLIN",
      "state": "SK"
  },
  {
      "city": "MACLAGGAN BRIDGE",
      "state": "NB"
  },
  {
      "city": "MACNUTT",
      "state": "SK"
  },
  {
      "city": "MACOUN",
      "state": "SK"
  },
  {
      "city": "MACPHEES CORNER",
      "state": "NS"
  },
  {
      "city": "MACRORIE",
      "state": "SK"
  },
  {
      "city": "MACTAQUAC",
      "state": "NB"
  },
  {
      "city": "MACTIER",
      "state": "ON"
  },
  {
      "city": "MADAWASKA MALISEET FRST NATION",
      "state": "NB"
  },
  {
      "city": "MADAWASKA",
      "state": "ON"
  },
  {
      "city": "MADDEN",
      "state": "AB"
  },
  {
      "city": "MADDINGTON",
      "state": "QC"
  },
  {
      "city": "MADEIRA PARK",
      "state": "BC"
  },
  {
      "city": "MADELEINE-CENTRE",
      "state": "QC"
  },
  {
      "city": "MADISON",
      "state": "SK"
  },
  {
      "city": "MADOC",
      "state": "ON"
  },
  {
      "city": "MADRAN",
      "state": "NB"
  },
  {
      "city": "MADSEN",
      "state": "ON"
  },
  {
      "city": "MAFEKING",
      "state": "MB"
  },
  {
      "city": "MAGNETAWAN",
      "state": "ON"
  },
  {
      "city": "MAGOG",
      "state": "QC"
  },
  {
      "city": "MAGPIE",
      "state": "QC"
  },
  {
      "city": "MAGRATH",
      "state": "AB"
  },
  {
      "city": "MAHONE BAY",
      "state": "NS"
  },
  {
      "city": "MAIDSTONE",
      "state": "ON"
  },
  {
      "city": "MAIDSTONE",
      "state": "SK"
  },
  {
      "city": "MAIN BROOK",
      "state": "NL"
  },
  {
      "city": "MAIN CENTRE",
      "state": "SK"
  },
  {
      "city": "MAIN POINT",
      "state": "NL"
  },
  {
      "city": "MAIN RIVER",
      "state": "NB"
  },
  {
      "city": "MAIN-A-DIEU",
      "state": "NS"
  },
  {
      "city": "MAINSTREAM",
      "state": "NB"
  },
  {
      "city": "MAISONNETTE",
      "state": "NB"
  },
  {
      "city": "MAITLAND BRIDGE",
      "state": "NS"
  },
  {
      "city": "MAITLAND",
      "state": "NS"
  },
  {
      "city": "MAITLAND",
      "state": "ON"
  },
  {
      "city": "MAJOR",
      "state": "SK"
  },
  {
      "city": "MAKINAK",
      "state": "MB"
  },
  {
      "city": "MAKINSONS",
      "state": "NL"
  },
  {
      "city": "MAKKOVIK",
      "state": "NL"
  },
  {
      "city": "MAKWA",
      "state": "SK"
  },
  {
      "city": "MALAGASH",
      "state": "NS"
  },
  {
      "city": "MALAHAT",
      "state": "BC"
  },
  {
      "city": "MALAKWA",
      "state": "BC"
  },
  {
      "city": "MALARTIC",
      "state": "QC"
  },
  {
      "city": "MALDEN",
      "state": "NB"
  },
  {
      "city": "MALEB",
      "state": "AB"
  },
  {
      "city": "MALIOTENAM",
      "state": "QC"
  },
  {
      "city": "MALLAIG",
      "state": "AB"
  },
  {
      "city": "MALLORYTOWN",
      "state": "ON"
  },
  {
      "city": "MALONTON",
      "state": "MB"
  },
  {
      "city": "MALTEMPEC",
      "state": "NB"
  },
  {
      "city": "MANCEBOURG",
      "state": "QC"
  },
  {
      "city": "MANCHE-D'EPEE",
      "state": "QC"
  },
  {
      "city": "MANDEVILLE",
      "state": "QC"
  },
  {
      "city": "MANGANESE MINES",
      "state": "NS"
  },
  {
      "city": "MANIGOTAGAN",
      "state": "MB"
  },
  {
      "city": "MANILLA",
      "state": "ON"
  },
  {
      "city": "MANITOU",
      "state": "MB"
  },
  {
      "city": "MANITOUWADGE",
      "state": "ON"
  },
  {
      "city": "MANITOWANING",
      "state": "ON"
  },
  {
      "city": "MANIWAKI",
      "state": "QC"
  },
  {
      "city": "MANKOTA",
      "state": "SK"
  },
  {
      "city": "MANN'S MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "MANNHURST",
      "state": "NB"
  },
  {
      "city": "MANNING PARK",
      "state": "BC"
  },
  {
      "city": "MANNING",
      "state": "AB"
  },
  {
      "city": "MANNVILLE",
      "state": "AB"
  },
  {
      "city": "MANOR",
      "state": "SK"
  },
  {
      "city": "MANOTICK",
      "state": "ON"
  },
  {
      "city": "MANOUANE",
      "state": "QC"
  },
  {
      "city": "MANSEAU",
      "state": "QC"
  },
  {
      "city": "MANSFIELD",
      "state": "ON"
  },
  {
      "city": "MANSFIELD",
      "state": "QC"
  },
  {
      "city": "MANSON CREEK",
      "state": "BC"
  },
  {
      "city": "MANSON",
      "state": "MB"
  },
  {
      "city": "MANSONS LANDING",
      "state": "BC"
  },
  {
      "city": "MANSONVILLE",
      "state": "QC"
  },
  {
      "city": "MANTARIO",
      "state": "SK"
  },
  {
      "city": "MANYBERRIES",
      "state": "AB"
  },
  {
      "city": "MAPLE CREEK",
      "state": "SK"
  },
  {
      "city": "MAPLE GLEN",
      "state": "NB"
  },
  {
      "city": "MAPLE GROVE",
      "state": "NB"
  },
  {
      "city": "MAPLE GROVE",
      "state": "NS"
  },
  {
      "city": "MAPLE GROVE",
      "state": "QC"
  },
  {
      "city": "MAPLE LEAF",
      "state": "ON"
  },
  {
      "city": "MAPLE RIDGE",
      "state": "BC"
  },
  {
      "city": "MAPLE RIDGE",
      "state": "NB"
  },
  {
      "city": "MAPLE VIEW",
      "state": "NB"
  },
  {
      "city": "MAPLE",
      "state": "ON"
  },
  {
      "city": "MAPLEDALE",
      "state": "NB"
  },
  {
      "city": "MAPLEHURST",
      "state": "NB"
  },
  {
      "city": "MAPLEWOOD",
      "state": "NB"
  },
  {
      "city": "MAQUAPIT LAKE",
      "state": "NB"
  },
  {
      "city": "MAR",
      "state": "ON"
  },
  {
      "city": "MARA",
      "state": "BC"
  },
  {
      "city": "MARATHON",
      "state": "ON"
  },
  {
      "city": "MARBLETON",
      "state": "QC"
  },
  {
      "city": "MARCELIN",
      "state": "SK"
  },
  {
      "city": "MARCHAND",
      "state": "MB"
  },
  {
      "city": "MARENGO",
      "state": "SK"
  },
  {
      "city": "MARGAREE CENTRE",
      "state": "NS"
  },
  {
      "city": "MARGAREE FORKS",
      "state": "NS"
  },
  {
      "city": "MARGAREE HARBOUR",
      "state": "NS"
  },
  {
      "city": "MARGAREE VALLEY",
      "state": "NS"
  },
  {
      "city": "MARGAREE",
      "state": "NS"
  },
  {
      "city": "MARGARET",
      "state": "MB"
  },
  {
      "city": "MARGARETSVILLE",
      "state": "NS"
  },
  {
      "city": "MARGO",
      "state": "SK"
  },
  {
      "city": "MARIA",
      "state": "QC"
  },
  {
      "city": "MARIAPOLIS",
      "state": "MB"
  },
  {
      "city": "MARICOURT",
      "state": "QC"
  },
  {
      "city": "MARIE JOSEPH",
      "state": "NS"
  },
  {
      "city": "MARIE REINE",
      "state": "AB"
  },
  {
      "city": "MARIEVILLE",
      "state": "QC"
  },
  {
      "city": "MARION BRIDGE",
      "state": "NS"
  },
  {
      "city": "MARIUS",
      "state": "MB"
  },
  {
      "city": "MARKDALE",
      "state": "ON"
  },
  {
      "city": "MARKERVILLE",
      "state": "AB"
  },
  {
      "city": "MARKHAM",
      "state": "ON"
  },
  {
      "city": "MARKHAMVILLE",
      "state": "NB"
  },
  {
      "city": "MARKINCH",
      "state": "SK"
  },
  {
      "city": "MARKSTAY",
      "state": "ON"
  },
  {
      "city": "MARLBANK",
      "state": "ON"
  },
  {
      "city": "MARMORA",
      "state": "ON"
  },
  {
      "city": "MARNE",
      "state": "NB"
  },
  {
      "city": "MARQUETTE",
      "state": "MB"
  },
  {
      "city": "MARQUIS",
      "state": "SK"
  },
  {
      "city": "MARRTOWN",
      "state": "NB"
  },
  {
      "city": "MARSDEN",
      "state": "SK"
  },
  {
      "city": "MARSH LAKE",
      "state": "YT"
  },
  {
      "city": "MARSHALL",
      "state": "SK"
  },
  {
      "city": "MARSOUI",
      "state": "QC"
  },
  {
      "city": "MARSTON",
      "state": "QC"
  },
  {
      "city": "MARTEN RIVER",
      "state": "ON"
  },
  {
      "city": "MARTENSVILLE",
      "state": "SK"
  },
  {
      "city": "MARTINTOWN",
      "state": "ON"
  },
  {
      "city": "MARTINVILLE",
      "state": "QC"
  },
  {
      "city": "MARWAYNE",
      "state": "AB"
  },
  {
      "city": "MARYFIELD",
      "state": "SK"
  },
  {
      "city": "MARYHILL",
      "state": "ON"
  },
  {
      "city": "MARYS HARBOUR",
      "state": "NL"
  },
  {
      "city": "MARYSTOWN",
      "state": "NL"
  },
  {
      "city": "MARYSVALE",
      "state": "NL"
  },
  {
      "city": "MARYSVILLE",
      "state": "ON"
  },
  {
      "city": "MASCARENE",
      "state": "NB"
  },
  {
      "city": "MASCOUCHE",
      "state": "QC"
  },
  {
      "city": "MASHTEUIATSH",
      "state": "QC"
  },
  {
      "city": "MASKINONGE",
      "state": "QC"
  },
  {
      "city": "MASSET",
      "state": "BC"
  },
  {
      "city": "MASSEY DRIVE",
      "state": "NL"
  },
  {
      "city": "MASSEY",
      "state": "ON"
  },
  {
      "city": "MASSUEVILLE",
      "state": "QC"
  },
  {
      "city": "MATACHEWAN",
      "state": "ON"
  },
  {
      "city": "MATAGAMI",
      "state": "QC"
  },
  {
      "city": "MATANE",
      "state": "QC"
  },
  {
      "city": "MATAPEDIA",
      "state": "QC"
  },
  {
      "city": "MATES CORNER",
      "state": "NB"
  },
  {
      "city": "MATHER",
      "state": "MB"
  },
  {
      "city": "MATHESON ISLAND",
      "state": "MB"
  },
  {
      "city": "MATHESON",
      "state": "ON"
  },
  {
      "city": "MATLOCK",
      "state": "MB"
  },
  {
      "city": "MATTAWA",
      "state": "ON"
  },
  {
      "city": "MATTHEWS SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "MATTICE",
      "state": "ON"
  },
  {
      "city": "MAUGERVILLE",
      "state": "NB"
  },
  {
      "city": "MAVILLETTE",
      "state": "NS"
  },
  {
      "city": "MAXVILLE",
      "state": "ON"
  },
  {
      "city": "MAXWELL",
      "state": "NB"
  },
  {
      "city": "MAXWELL",
      "state": "ON"
  },
  {
      "city": "MAYERTHORPE",
      "state": "AB"
  },
  {
      "city": "MAYFAIR",
      "state": "SK"
  },
  {
      "city": "MAYFIELD",
      "state": "NB"
  },
  {
      "city": "MAYMONT",
      "state": "SK"
  },
  {
      "city": "MAYNE",
      "state": "BC"
  },
  {
      "city": "MAYNOOTH",
      "state": "ON"
  },
  {
      "city": "MAYO",
      "state": "QC"
  },
  {
      "city": "MAYO",
      "state": "YT"
  },
  {
      "city": "MAYVIEW",
      "state": "SK"
  },
  {
      "city": "MAZENOD",
      "state": "SK"
  },
  {
      "city": "MAZEROLLE SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "MCADAM",
      "state": "NB"
  },
  {
      "city": "MCARTHURS MILLS",
      "state": "ON"
  },
  {
      "city": "MCAULEY",
      "state": "MB"
  },
  {
      "city": "MCBRIDE",
      "state": "BC"
  },
  {
      "city": "MCCALLUM SETTLEMENT",
      "state": "NS"
  },
  {
      "city": "MCCALLUM",
      "state": "NL"
  },
  {
      "city": "MCCORD",
      "state": "SK"
  },
  {
      "city": "MCCREARY",
      "state": "MB"
  },
  {
      "city": "MCDONALDS CORNERS",
      "state": "ON"
  },
  {
      "city": "MCGIVNEY",
      "state": "NB"
  },
  {
      "city": "MCGRATHS COVE",
      "state": "NS"
  },
  {
      "city": "MCGRAY",
      "state": "NS"
  },
  {
      "city": "MCGREGOR",
      "state": "ON"
  },
  {
      "city": "MCINTOSH HILL",
      "state": "NB"
  },
  {
      "city": "MCKEES MILLS",
      "state": "NB"
  },
  {
      "city": "MCKELLAR",
      "state": "ON"
  },
  {
      "city": "MCKENNA",
      "state": "NB"
  },
  {
      "city": "MCKENZIE CORNER",
      "state": "NB"
  },
  {
      "city": "MCKENZIE ISLAND",
      "state": "ON"
  },
  {
      "city": "MCKERROW",
      "state": "ON"
  },
  {
      "city": "MCKINLEYVILLE",
      "state": "NB"
  },
  {
      "city": "MCLAUGHLIN",
      "state": "AB"
  },
  {
      "city": "MCLAUGHLIN",
      "state": "NB"
  },
  {
      "city": "MCLEAN",
      "state": "SK"
  },
  {
      "city": "MCLEESE LAKE",
      "state": "BC"
  },
  {
      "city": "MCLENNAN",
      "state": "AB"
  },
  {
      "city": "MCLEOD HILL",
      "state": "NB"
  },
  {
      "city": "MCLEOD LAKE",
      "state": "BC"
  },
  {
      "city": "MCLEODS",
      "state": "NB"
  },
  {
      "city": "MCLURE",
      "state": "BC"
  },
  {
      "city": "MCMAHON",
      "state": "SK"
  },
  {
      "city": "MCMASTERVILLE",
      "state": "QC"
  },
  {
      "city": "MCNAMEE",
      "state": "NB"
  },
  {
      "city": "MCQUADE",
      "state": "NB"
  },
  {
      "city": "MCRAE",
      "state": "AB"
  },
  {
      "city": "MCTAGGART",
      "state": "SK"
  },
  {
      "city": "MCWATTERS",
      "state": "QC"
  },
  {
      "city": "MEACHAM",
      "state": "SK"
  },
  {
      "city": "MEADOW BROOK",
      "state": "NB"
  },
  {
      "city": "MEADOW CREEK",
      "state": "BC"
  },
  {
      "city": "MEADOW LAKE",
      "state": "SK"
  },
  {
      "city": "MEADOW PORTAGE",
      "state": "MB"
  },
  {
      "city": "MEAFORD",
      "state": "ON"
  },
  {
      "city": "MEAGHERS GRANT",
      "state": "NS"
  },
  {
      "city": "MEANDER RIVER",
      "state": "AB"
  },
  {
      "city": "MEATH PARK",
      "state": "SK"
  },
  {
      "city": "MECHANIC SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "MEDFORD",
      "state": "NB"
  },
  {
      "city": "MEDICINE HAT",
      "state": "AB"
  },
  {
      "city": "MEDORA",
      "state": "MB"
  },
  {
      "city": "MEDSTEAD",
      "state": "SK"
  },
  {
      "city": "MEDUCTIC",
      "state": "NB"
  },
  {
      "city": "MEETING CREEK",
      "state": "AB"
  },
  {
      "city": "MELBOURNE",
      "state": "ON"
  },
  {
      "city": "MELBOURNE",
      "state": "QC"
  },
  {
      "city": "MELDRUM BAY",
      "state": "ON"
  },
  {
      "city": "MELEB",
      "state": "MB"
  },
  {
      "city": "MELFORT",
      "state": "SK"
  },
  {
      "city": "MELITA",
      "state": "MB"
  },
  {
      "city": "MELOCHEVILLE",
      "state": "QC"
  },
  {
      "city": "MELROSE",
      "state": "NB"
  },
  {
      "city": "MELROSE",
      "state": "NL"
  },
  {
      "city": "MELVILLE",
      "state": "NS"
  },
  {
      "city": "MELVILLE",
      "state": "SK"
  },
  {
      "city": "MEMBERTOU",
      "state": "NS"
  },
  {
      "city": "MEMRAMCOOK EAST",
      "state": "NB"
  },
  {
      "city": "MEMRAMCOOK",
      "state": "NB"
  },
  {
      "city": "MENDHAM",
      "state": "SK"
  },
  {
      "city": "MENISINO",
      "state": "MB"
  },
  {
      "city": "MENNEVAL",
      "state": "NB"
  },
  {
      "city": "MENZIE",
      "state": "MB"
  },
  {
      "city": "MEOTA",
      "state": "SK"
  },
  {
      "city": "MERCIER",
      "state": "QC"
  },
  {
      "city": "MERIGOMISH",
      "state": "NS"
  },
  {
      "city": "MERLIN",
      "state": "ON"
  },
  {
      "city": "MERRICKVILLE",
      "state": "ON"
  },
  {
      "city": "MERRITT",
      "state": "BC"
  },
  {
      "city": "MERVILLE",
      "state": "BC"
  },
  {
      "city": "MERVIN",
      "state": "SK"
  },
  {
      "city": "MESACHIE LAKE",
      "state": "BC"
  },
  {
      "city": "MESKANAW",
      "state": "SK"
  },
  {
      "city": "MESSINES",
      "state": "QC"
  },
  {
      "city": "METABETCHOUAN-LAC-A-LA-CROIX",
      "state": "QC"
  },
  {
      "city": "METCALFE",
      "state": "ON"
  },
  {
      "city": "METEGHAN CENTRE",
      "state": "NS"
  },
  {
      "city": "METEGHAN RIVER",
      "state": "NS"
  },
  {
      "city": "METEGHAN",
      "state": "NS"
  },
  {
      "city": "METIS-SUR-MER",
      "state": "QC"
  },
  {
      "city": "METISKOW",
      "state": "AB"
  },
  {
      "city": "MEYRONNE",
      "state": "SK"
  },
  {
      "city": "MEZIADIN LAKE",
      "state": "BC"
  },
  {
      "city": "MIAMI",
      "state": "MB"
  },
  {
      "city": "MICA CREEK",
      "state": "BC"
  },
  {
      "city": "MICMAC",
      "state": "NS"
  },
  {
      "city": "MIDALE",
      "state": "SK"
  },
  {
      "city": "MIDDLE ARM GB",
      "state": "NL"
  },
  {
      "city": "MIDDLE BAY",
      "state": "QC"
  },
  {
      "city": "MIDDLE CAPE",
      "state": "NS"
  },
  {
      "city": "MIDDLE COVE",
      "state": "NL"
  },
  {
      "city": "MIDDLE HAINESVILLE",
      "state": "NB"
  },
  {
      "city": "MIDDLE LAHAVE",
      "state": "NS"
  },
  {
      "city": "MIDDLE LAKE",
      "state": "SK"
  },
  {
      "city": "MIDDLE MUSQUODOBOIT",
      "state": "NS"
  },
  {
      "city": "MIDDLE PORTERS LAKE",
      "state": "NS"
  },
  {
      "city": "MIDDLE RIVER",
      "state": "NB"
  },
  {
      "city": "MIDDLE SACKVILLE",
      "state": "NB"
  },
  {
      "city": "MIDDLE SACKVILLE",
      "state": "NS"
  },
  {
      "city": "MIDDLE STEWIACKE",
      "state": "NS"
  },
  {
      "city": "MIDDLE WEST PUBNICO",
      "state": "NS"
  },
  {
      "city": "MIDDLEBRO",
      "state": "MB"
  },
  {
      "city": "MIDDLETON",
      "state": "NB"
  },
  {
      "city": "MIDDLETON",
      "state": "NS"
  },
  {
      "city": "MIDDLEWOOD",
      "state": "NS"
  },
  {
      "city": "MIDGIC",
      "state": "NB"
  },
  {
      "city": "MIDHURST",
      "state": "ON"
  },
  {
      "city": "MIDLAND KINGS CO",
      "state": "NB"
  },
  {
      "city": "MIDLAND QUEENS CO",
      "state": "NB"
  },
  {
      "city": "MIDLAND",
      "state": "ON"
  },
  {
      "city": "MIDVILLE BRANCH",
      "state": "NS"
  },
  {
      "city": "MIDWAY",
      "state": "BC"
  },
  {
      "city": "MIDWAY",
      "state": "NB"
  },
  {
      "city": "MIGISI SAHGAIGAN",
      "state": "ON"
  },
  {
      "city": "MIKADO",
      "state": "SK"
  },
  {
      "city": "MILAN",
      "state": "QC"
  },
  {
      "city": "MILDEN",
      "state": "SK"
  },
  {
      "city": "MILDMAY",
      "state": "ON"
  },
  {
      "city": "MILDRED",
      "state": "SK"
  },
  {
      "city": "MILES COVE",
      "state": "NL"
  },
  {
      "city": "MILESTONE",
      "state": "SK"
  },
  {
      "city": "MILFORD BAY",
      "state": "ON"
  },
  {
      "city": "MILFORD STATION",
      "state": "NS"
  },
  {
      "city": "MILFORD",
      "state": "NS"
  },
  {
      "city": "MILFORD",
      "state": "ON"
  },
  {
      "city": "MILK RIVER",
      "state": "AB"
  },
  {
      "city": "MILL BAY",
      "state": "BC"
  },
  {
      "city": "MILL BROOK",
      "state": "NB"
  },
  {
      "city": "MILL COVE",
      "state": "NB"
  },
  {
      "city": "MILL CREEK",
      "state": "NS"
  },
  {
      "city": "MILL VILLAGE",
      "state": "NS"
  },
  {
      "city": "MILL VILLAGE.",
      "state": "NS"
  },
  {
      "city": "MILLARVILLE",
      "state": "AB"
  },
  {
      "city": "MILLBANK",
      "state": "ON"
  },
  {
      "city": "MILLBROOK",
      "state": "NS"
  },
  {
      "city": "MILLBROOK",
      "state": "ON"
  },
  {
      "city": "MILLE-ISLES",
      "state": "QC"
  },
  {
      "city": "MILLER LAKE",
      "state": "ON"
  },
  {
      "city": "MILLERTON",
      "state": "NB"
  },
  {
      "city": "MILLERTOWN",
      "state": "NL"
  },
  {
      "city": "MILLET",
      "state": "AB"
  },
  {
      "city": "MILLGROVE",
      "state": "ON"
  },
  {
      "city": "MILLICENT",
      "state": "AB"
  },
  {
      "city": "MILLTOWN",
      "state": "NL"
  },
  {
      "city": "MILLVILLE",
      "state": "NB"
  },
  {
      "city": "MILLVILLE",
      "state": "NS"
  },
  {
      "city": "MILNER",
      "state": "BC"
  },
  {
      "city": "MILO",
      "state": "AB"
  },
  {
      "city": "MILTON",
      "state": "NS"
  },
  {
      "city": "MILTON",
      "state": "ON"
  },
  {
      "city": "MILVERTON",
      "state": "ON"
  },
  {
      "city": "MIMINEGASH",
      "state": "PE"
  },
  {
      "city": "MINAKI",
      "state": "ON"
  },
  {
      "city": "MINBURN",
      "state": "AB"
  },
  {
      "city": "MINDEMOYA",
      "state": "ON"
  },
  {
      "city": "MINDEN",
      "state": "ON"
  },
  {
      "city": "MINE CENTRE",
      "state": "ON"
  },
  {
      "city": "MINESING",
      "state": "ON"
  },
  {
      "city": "MINETT",
      "state": "ON"
  },
  {
      "city": "MINEVILLE",
      "state": "NS"
  },
  {
      "city": "MINGS BIGHT",
      "state": "NL"
  },
  {
      "city": "MINIOTA",
      "state": "MB"
  },
  {
      "city": "MINISTERS ISLAND",
      "state": "NB"
  },
  {
      "city": "MINITONAS",
      "state": "MB"
  },
  {
      "city": "MINNEDOSA",
      "state": "MB"
  },
  {
      "city": "MINNITAKI",
      "state": "ON"
  },
  {
      "city": "MINSTREL ISLAND",
      "state": "BC"
  },
  {
      "city": "MINTO",
      "state": "MB"
  },
  {
      "city": "MINTO",
      "state": "NB"
  },
  {
      "city": "MINTON",
      "state": "SK"
  },
  {
      "city": "MIQUELON",
      "state": "QC"
  },
  {
      "city": "MIRA GUT",
      "state": "NS"
  },
  {
      "city": "MIRA ROAD",
      "state": "NS"
  },
  {
      "city": "MIRABEL",
      "state": "QC"
  },
  {
      "city": "MIRAMICHI BAY",
      "state": "NB"
  },
  {
      "city": "MIRAMICHI ROAD",
      "state": "NB"
  },
  {
      "city": "MIRAMICHI",
      "state": "NB"
  },
  {
      "city": "MIRROR",
      "state": "AB"
  },
  {
      "city": "MISCOU",
      "state": "NB"
  },
  {
      "city": "MISCOUCHE",
      "state": "PE"
  },
  {
      "city": "MISPEC",
      "state": "NB"
  },
  {
      "city": "MISSANABIE",
      "state": "ON"
  },
  {
      "city": "MISSION",
      "state": "BC"
  },
  {
      "city": "MISSISSAUGA",
      "state": "ON"
  },
  {
      "city": "MISSISSIPPI STATION",
      "state": "ON"
  },
  {
      "city": "MISTATIM",
      "state": "SK"
  },
  {
      "city": "MISTISSINI",
      "state": "QC"
  },
  {
      "city": "MITCHELL",
      "state": "MB"
  },
  {
      "city": "MITCHELL",
      "state": "ON"
  },
  {
      "city": "MOBERLY LAKE",
      "state": "BC"
  },
  {
      "city": "MOBERT",
      "state": "ON"
  },
  {
      "city": "MOBILE",
      "state": "NL"
  },
  {
      "city": "MOFFAT",
      "state": "ON"
  },
  {
      "city": "MOFFET",
      "state": "QC"
  },
  {
      "city": "MOHANNES",
      "state": "NB"
  },
  {
      "city": "MOISIE",
      "state": "QC"
  },
  {
      "city": "MOLEGA LAKE",
      "state": "NS"
  },
  {
      "city": "MONARCH",
      "state": "AB"
  },
  {
      "city": "MONASTERY",
      "state": "NS"
  },
  {
      "city": "MONCTON",
      "state": "NB"
  },
  {
      "city": "MONETVILLE",
      "state": "ON"
  },
  {
      "city": "MONITOR",
      "state": "AB"
  },
  {
      "city": "MONKLAND",
      "state": "ON"
  },
  {
      "city": "MONKSTOWN",
      "state": "NL"
  },
  {
      "city": "MONKTON",
      "state": "ON"
  },
  {
      "city": "MONQUART",
      "state": "NB"
  },
  {
      "city": "MONT NEBO",
      "state": "SK"
  },
  {
      "city": "MONT-BRUN",
      "state": "QC"
  },
  {
      "city": "MONT-CARMEL",
      "state": "QC"
  },
  {
      "city": "MONT-JOLI",
      "state": "QC"
  },
  {
      "city": "MONT-LAURIER",
      "state": "QC"
  },
  {
      "city": "MONT-LOUIS",
      "state": "QC"
  },
  {
      "city": "MONT-ROYAL",
      "state": "QC"
  },
  {
      "city": "MONT-SAINT-GREGOIRE",
      "state": "QC"
  },
  {
      "city": "MONT-SAINT-HILAIRE",
      "state": "QC"
  },
  {
      "city": "MONT-SAINT-MICHEL",
      "state": "QC"
  },
  {
      "city": "MONT-SAINT-PIERRE",
      "state": "QC"
  },
  {
      "city": "MONT-TREMBLANT",
      "state": "QC"
  },
  {
      "city": "MONTAGUE GOLD MINES",
      "state": "NS"
  },
  {
      "city": "MONTAGUE",
      "state": "PE"
  },
  {
      "city": "MONTBEILLARD",
      "state": "QC"
  },
  {
      "city": "MONTCALM",
      "state": "QC"
  },
  {
      "city": "MONTCERF-LYTTON",
      "state": "QC"
  },
  {
      "city": "MONTE CREEK",
      "state": "BC"
  },
  {
      "city": "MONTE LAKE",
      "state": "BC"
  },
  {
      "city": "MONTEAGLE",
      "state": "NB"
  },
  {
      "city": "MONTEBELLO",
      "state": "QC"
  },
  {
      "city": "MONTEITH",
      "state": "ON"
  },
  {
      "city": "MONTMAGNY",
      "state": "QC"
  },
  {
      "city": "MONTMARTRE",
      "state": "SK"
  },
  {
      "city": "MONTNEY",
      "state": "BC"
  },
  {
      "city": "MONTPELLIER",
      "state": "QC"
  },
  {
      "city": "MONTREAL LAKE",
      "state": "SK"
  },
  {
      "city": "MONTREAL RIVER HARBOUR",
      "state": "ON"
  },
  {
      "city": "MONTREAL",
      "state": "QC"
  },
  {
      "city": "MONTREAL-EST",
      "state": "QC"
  },
  {
      "city": "MONTREAL-NORD",
      "state": "QC"
  },
  {
      "city": "MONTREAL-OUEST",
      "state": "QC"
  },
  {
      "city": "MONTROSE",
      "state": "BC"
  },
  {
      "city": "MONUMENT",
      "state": "NB"
  },
  {
      "city": "MOONBEAM",
      "state": "ON"
  },
  {
      "city": "MOONSTONE",
      "state": "ON"
  },
  {
      "city": "MOOREFIELD",
      "state": "ON"
  },
  {
      "city": "MOORES MILLS",
      "state": "NB"
  },
  {
      "city": "MOORETOWN",
      "state": "ON"
  },
  {
      "city": "MOOSE CREEK",
      "state": "ON"
  },
  {
      "city": "MOOSE FACTORY",
      "state": "ON"
  },
  {
      "city": "MOOSE JAW",
      "state": "SK"
  },
  {
      "city": "MOOSE LAKE",
      "state": "MB"
  },
  {
      "city": "MOOSE MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "MOOSEHORN CREEK",
      "state": "NB"
  },
  {
      "city": "MOOSEHORN",
      "state": "MB"
  },
  {
      "city": "MOOSELAND",
      "state": "NS"
  },
  {
      "city": "MOOSOMIN",
      "state": "SK"
  },
  {
      "city": "MOOSONEE",
      "state": "ON"
  },
  {
      "city": "MORDEN",
      "state": "MB"
  },
  {
      "city": "MORELL",
      "state": "PE"
  },
  {
      "city": "MORETONS HARBOUR",
      "state": "NL"
  },
  {
      "city": "MOREWOOD",
      "state": "ON"
  },
  {
      "city": "MORIN-HEIGHTS",
      "state": "QC"
  },
  {
      "city": "MORINVILLE",
      "state": "AB"
  },
  {
      "city": "MORLEY",
      "state": "AB"
  },
  {
      "city": "MORPETH",
      "state": "ON"
  },
  {
      "city": "MORRELL SIDING",
      "state": "NB"
  },
  {
      "city": "MORRIN",
      "state": "AB"
  },
  {
      "city": "MORRIS",
      "state": "MB"
  },
  {
      "city": "MORRISBURG",
      "state": "ON"
  },
  {
      "city": "MORRISDALE",
      "state": "NB"
  },
  {
      "city": "MORRISTON",
      "state": "ON"
  },
  {
      "city": "MORSE",
      "state": "SK"
  },
  {
      "city": "MORSON",
      "state": "ON"
  },
  {
      "city": "MORTLACH",
      "state": "SK"
  },
  {
      "city": "MOSER RIVER",
      "state": "NS"
  },
  {
      "city": "MOSSBANK",
      "state": "SK"
  },
  {
      "city": "MOSSLEIGH",
      "state": "AB"
  },
  {
      "city": "MOSSLEY",
      "state": "ON"
  },
  {
      "city": "MOUNT ALBERT",
      "state": "ON"
  },
  {
      "city": "MOUNT ARLINGTON HEIGHTS",
      "state": "NL"
  },
  {
      "city": "MOUNT BRYDGES",
      "state": "ON"
  },
  {
      "city": "MOUNT CARMEL",
      "state": "NL"
  },
  {
      "city": "MOUNT CURRIE",
      "state": "BC"
  },
  {
      "city": "MOUNT DELIGHT",
      "state": "NB"
  },
  {
      "city": "MOUNT ELGIN",
      "state": "ON"
  },
  {
      "city": "MOUNT FOREST",
      "state": "ON"
  },
  {
      "city": "MOUNT HEBRON",
      "state": "NB"
  },
  {
      "city": "MOUNT HOPE",
      "state": "NB"
  },
  {
      "city": "MOUNT HOPE",
      "state": "ON"
  },
  {
      "city": "MOUNT MORIAH",
      "state": "NL"
  },
  {
      "city": "MOUNT PEARL",
      "state": "NL"
  },
  {
      "city": "MOUNT PISGAH",
      "state": "NB"
  },
  {
      "city": "MOUNT PLEASANT",
      "state": "NB"
  },
  {
      "city": "MOUNT PLEASANT",
      "state": "ON"
  },
  {
      "city": "MOUNT STEWART",
      "state": "PE"
  },
  {
      "city": "MOUNT UNIACKE",
      "state": "NS"
  },
  {
      "city": "MOUNTAIN GROVE",
      "state": "ON"
  },
  {
      "city": "MOUNTAIN ROAD",
      "state": "MB"
  },
  {
      "city": "MOUNTAIN VIEW",
      "state": "AB"
  },
  {
      "city": "MOUNTAIN",
      "state": "ON"
  },
  {
      "city": "MOYIE",
      "state": "BC"
  },
  {
      "city": "MOZART",
      "state": "SK"
  },
  {
      "city": "MT MIDDLETON",
      "state": "NB"
  },
  {
      "city": "MUD LAKE",
      "state": "NL"
  },
  {
      "city": "MUENSTER",
      "state": "SK"
  },
  {
      "city": "MUIRKIRK",
      "state": "ON"
  },
  {
      "city": "MULGRAVE",
      "state": "NS"
  },
  {
      "city": "MULGRAVE-ET-DERRY",
      "state": "QC"
  },
  {
      "city": "MULHURST BAY",
      "state": "AB"
  },
  {
      "city": "MULLINGAR",
      "state": "SK"
  },
  {
      "city": "MULVIHILL",
      "state": "MB"
  },
  {
      "city": "MUNCEY",
      "state": "ON"
  },
  {
      "city": "MUNCHO LAKE",
      "state": "BC"
  },
  {
      "city": "MUNDARE",
      "state": "AB"
  },
  {
      "city": "MUNDLEVILLE",
      "state": "NB"
  },
  {
      "city": "MUNIAC",
      "state": "NB"
  },
  {
      "city": "MUNSON",
      "state": "AB"
  },
  {
      "city": "MUNSTER",
      "state": "ON"
  },
  {
      "city": "MURDOCHVILLE",
      "state": "QC"
  },
  {
      "city": "MURILLO",
      "state": "ON"
  },
  {
      "city": "MURRAY CORNER",
      "state": "NB"
  },
  {
      "city": "MURRAY HARBOUR",
      "state": "PE"
  },
  {
      "city": "MURRAY RIVER",
      "state": "PE"
  },
  {
      "city": "MURRAY SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "MURRAY SIDING",
      "state": "NS"
  },
  {
      "city": "MUSGRAVE HARBOUR",
      "state": "NL"
  },
  {
      "city": "MUSGRAVETOWN",
      "state": "NL"
  },
  {
      "city": "MUSIDORA",
      "state": "AB"
  },
  {
      "city": "MUSKODAY",
      "state": "SK"
  },
  {
      "city": "MUSKRAT DAM",
      "state": "ON"
  },
  {
      "city": "MUSQUASH",
      "state": "NB"
  },
  {
      "city": "MUSQUODOBOIT HARBOUR",
      "state": "NS"
  },
  {
      "city": "MUTTON BAY",
      "state": "QC"
  },
  {
      "city": "MYRNAM",
      "state": "AB"
  },
  {
      "city": "NACKAWIC",
      "state": "NB"
  },
  {
      "city": "NACMINE",
      "state": "AB"
  },
  {
      "city": "NAICAM",
      "state": "SK"
  },
  {
      "city": "NAIN",
      "state": "NL"
  },
  {
      "city": "NAIRN CENTRE",
      "state": "ON"
  },
  {
      "city": "NAKINA",
      "state": "ON"
  },
  {
      "city": "NAKUSP",
      "state": "BC"
  },
  {
      "city": "NAMAO",
      "state": "AB"
  },
  {
      "city": "NAMPA",
      "state": "AB"
  },
  {
      "city": "NAMUR",
      "state": "QC"
  },
  {
      "city": "NANAIMO",
      "state": "BC"
  },
  {
      "city": "NANOOSE BAY",
      "state": "BC"
  },
  {
      "city": "NANTES",
      "state": "QC"
  },
  {
      "city": "NANTICOKE",
      "state": "ON"
  },
  {
      "city": "NANTON",
      "state": "AB"
  },
  {
      "city": "NAPADOGAN",
      "state": "NB"
  },
  {
      "city": "NAPAN",
      "state": "NB"
  },
  {
      "city": "NAPANEE",
      "state": "ON"
  },
  {
      "city": "NAPIERVILLE",
      "state": "QC"
  },
  {
      "city": "NAPINKA",
      "state": "MB"
  },
  {
      "city": "NAPPAN",
      "state": "NS"
  },
  {
      "city": "NARAMATA",
      "state": "BC"
  },
  {
      "city": "NARCISSE",
      "state": "MB"
  },
  {
      "city": "NASH CREEK",
      "state": "NB"
  },
  {
      "city": "NASHWAAK BRIDGE",
      "state": "NB"
  },
  {
      "city": "NASHWAAK VILLAGE",
      "state": "NB"
  },
  {
      "city": "NASONWORTH",
      "state": "NB"
  },
  {
      "city": "NASS CAMP",
      "state": "BC"
  },
  {
      "city": "NATASHQUAN",
      "state": "QC"
  },
  {
      "city": "NATUASHISH",
      "state": "NL"
  },
  {
      "city": "NAUGHTON",
      "state": "ON"
  },
  {
      "city": "NAUWIGEWAUK",
      "state": "NB"
  },
  {
      "city": "NAVAN",
      "state": "ON"
  },
  {
      "city": "NEDELEC",
      "state": "QC"
  },
  {
      "city": "NEEBING",
      "state": "ON"
  },
  {
      "city": "NEEPAWA",
      "state": "MB"
  },
  {
      "city": "NEERLANDIA",
      "state": "AB"
  },
  {
      "city": "NEGGINAN",
      "state": "MB"
  },
  {
      "city": "NEGUAC",
      "state": "NB"
  },
  {
      "city": "NEIDPATH",
      "state": "SK"
  },
  {
      "city": "NEILBURG",
      "state": "SK"
  },
  {
      "city": "NEILS HARBOUR",
      "state": "NS"
  },
  {
      "city": "NELSON HOLLOW",
      "state": "NB"
  },
  {
      "city": "NELSON HOUSE",
      "state": "MB"
  },
  {
      "city": "NELSON",
      "state": "BC"
  },
  {
      "city": "NEMAIAH VALLEY",
      "state": "BC"
  },
  {
      "city": "NEMISCAU",
      "state": "QC"
  },
  {
      "city": "NEPEAN",
      "state": "ON"
  },
  {
      "city": "NEPISIGUIT FALLS",
      "state": "NB"
  },
  {
      "city": "NEREPIS",
      "state": "NB"
  },
  {
      "city": "NESBITT",
      "state": "MB"
  },
  {
      "city": "NESTLETON STATION",
      "state": "ON"
  },
  {
      "city": "NESTOR FALLS",
      "state": "ON"
  },
  {
      "city": "NESTOW",
      "state": "AB"
  },
  {
      "city": "NETHERHILL",
      "state": "SK"
  },
  {
      "city": "NEUDORF",
      "state": "SK"
  },
  {
      "city": "NEUSTADT",
      "state": "ON"
  },
  {
      "city": "NEUVILLE",
      "state": "QC"
  },
  {
      "city": "NEVILLE",
      "state": "SK"
  },
  {
      "city": "NEVIS",
      "state": "AB"
  },
  {
      "city": "NEW AVON",
      "state": "NB"
  },
  {
      "city": "NEW BANDON GLOUCESTER CO",
      "state": "NB"
  },
  {
      "city": "NEW BANDON NORTHUMB CO",
      "state": "NB"
  },
  {
      "city": "NEW BOTHWELL",
      "state": "MB"
  },
  {
      "city": "NEW BRIGDEN",
      "state": "AB"
  },
  {
      "city": "NEW CAMPBELLTON",
      "state": "NS"
  },
  {
      "city": "NEW CANAAN",
      "state": "NB"
  },
  {
      "city": "NEW CANADA",
      "state": "NS"
  },
  {
      "city": "NEW CARLISLE",
      "state": "QC"
  },
  {
      "city": "NEW CHELSEA",
      "state": "NL"
  },
  {
      "city": "NEW DAYTON",
      "state": "AB"
  },
  {
      "city": "NEW DENMARK",
      "state": "NB"
  },
  {
      "city": "NEW DENVER",
      "state": "BC"
  },
  {
      "city": "NEW DUNDEE",
      "state": "ON"
  },
  {
      "city": "NEW ENGLAND SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "NEW GERMANY",
      "state": "NS"
  },
  {
      "city": "NEW GLASGOW",
      "state": "NS"
  },
  {
      "city": "NEW GLASGOW",
      "state": "PE"
  },
  {
      "city": "NEW HAMBURG",
      "state": "ON"
  },
  {
      "city": "NEW HARBOUR TB",
      "state": "NL"
  },
  {
      "city": "NEW HARRIS",
      "state": "NS"
  },
  {
      "city": "NEW HAVEN",
      "state": "NS"
  },
  {
      "city": "NEW HAZELTON",
      "state": "BC"
  },
  {
      "city": "NEW HORTON",
      "state": "NB"
  },
  {
      "city": "NEW JERSEY",
      "state": "NB"
  },
  {
      "city": "NEW LINE",
      "state": "NB"
  },
  {
      "city": "NEW LISKEARD",
      "state": "ON"
  },
  {
      "city": "NEW LOWELL",
      "state": "ON"
  },
  {
      "city": "NEW MARKET",
      "state": "NB"
  },
  {
      "city": "NEW MARYLAND",
      "state": "NB"
  },
  {
      "city": "NEW MELBOURNE",
      "state": "NL"
  },
  {
      "city": "NEW MILLS",
      "state": "NB"
  },
  {
      "city": "NEW MINAS",
      "state": "NS"
  },
  {
      "city": "NEW NORWAY",
      "state": "AB"
  },
  {
      "city": "NEW PERLICAN",
      "state": "NL"
  },
  {
      "city": "NEW RICHMOND",
      "state": "QC"
  },
  {
      "city": "NEW RIVER BEACH",
      "state": "NB"
  },
  {
      "city": "NEW ROSS",
      "state": "NS"
  },
  {
      "city": "NEW SCOTLAND",
      "state": "NB"
  },
  {
      "city": "NEW SONGHEES 1A",
      "state": "BC"
  },
  {
      "city": "NEW VICTORIA",
      "state": "NS"
  },
  {
      "city": "NEW WATERFORD",
      "state": "NS"
  },
  {
      "city": "NEW WESTMINSTER",
      "state": "BC"
  },
  {
      "city": "NEW ZION",
      "state": "NB"
  },
  {
      "city": "NEWBORO",
      "state": "ON"
  },
  {
      "city": "NEWBRIDGE",
      "state": "NB"
  },
  {
      "city": "NEWBROOK",
      "state": "AB"
  },
  {
      "city": "NEWBURG",
      "state": "NB"
  },
  {
      "city": "NEWBURGH",
      "state": "ON"
  },
  {
      "city": "NEWBURY",
      "state": "ON"
  },
  {
      "city": "NEWCASTLE CENTRE",
      "state": "NB"
  },
  {
      "city": "NEWCASTLE CREEK",
      "state": "NB"
  },
  {
      "city": "NEWCASTLE",
      "state": "ON"
  },
  {
      "city": "NEWCOMBVILLE",
      "state": "NS"
  },
  {
      "city": "NEWDALE",
      "state": "MB"
  },
  {
      "city": "NEWINGTON",
      "state": "ON"
  },
  {
      "city": "NEWMANS COVE",
      "state": "NL"
  },
  {
      "city": "NEWMARKET",
      "state": "ON"
  },
  {
      "city": "NEWPORT STATION",
      "state": "NS"
  },
  {
      "city": "NEWPORT",
      "state": "NS"
  },
  {
      "city": "NEWPORT",
      "state": "QC"
  },
  {
      "city": "NEWTON SIDING",
      "state": "MB"
  },
  {
      "city": "NEWTON",
      "state": "ON"
  },
  {
      "city": "NEWTONVILLE",
      "state": "ON"
  },
  {
      "city": "NEWTOWN",
      "state": "NB"
  },
  {
      "city": "NEWTOWN",
      "state": "NL"
  },
  {
      "city": "NEWTOWN",
      "state": "NS"
  },
  {
      "city": "NIAGARA FALLS",
      "state": "ON"
  },
  {
      "city": "NIAGARA ON THE LAKE",
      "state": "ON"
  },
  {
      "city": "NICHOLAS DENYS",
      "state": "NB"
  },
  {
      "city": "NICOLET",
      "state": "QC"
  },
  {
      "city": "NICTAU",
      "state": "NB"
  },
  {
      "city": "NIGADOO",
      "state": "NB"
  },
  {
      "city": "NIMPO LAKE",
      "state": "BC"
  },
  {
      "city": "NINE MILE RIVER",
      "state": "NS"
  },
  {
      "city": "NINETTE",
      "state": "MB"
  },
  {
      "city": "NINGA",
      "state": "MB"
  },
  {
      "city": "NIPAWIN",
      "state": "SK"
  },
  {
      "city": "NIPIGON",
      "state": "ON"
  },
  {
      "city": "NIPISSING",
      "state": "ON"
  },
  {
      "city": "NIPPERS HARBOUR",
      "state": "NL"
  },
  {
      "city": "NISKU",
      "state": "AB"
  },
  {
      "city": "NITON JUNCTION",
      "state": "AB"
  },
  {
      "city": "NIVERVILLE",
      "state": "MB"
  },
  {
      "city": "NOBEL",
      "state": "ON"
  },
  {
      "city": "NOBLEFORD",
      "state": "AB"
  },
  {
      "city": "NOBLETON",
      "state": "ON"
  },
  {
      "city": "NOEL",
      "state": "NS"
  },
  {
      "city": "NOELS POND",
      "state": "NL"
  },
  {
      "city": "NOELVILLE",
      "state": "ON"
  },
  {
      "city": "NOINVILLE",
      "state": "NB"
  },
  {
      "city": "NOKOMIS",
      "state": "SK"
  },
  {
      "city": "NOLALU",
      "state": "ON"
  },
  {
      "city": "NOMININGUE",
      "state": "QC"
  },
  {
      "city": "NOONAN",
      "state": "NB"
  },
  {
      "city": "NORDEGG",
      "state": "AB"
  },
  {
      "city": "NORGLENWOLD",
      "state": "AB"
  },
  {
      "city": "NORLAND",
      "state": "ON"
  },
  {
      "city": "NORMAN WELLS",
      "state": "NT"
  },
  {
      "city": "NORMANDIN",
      "state": "QC"
  },
  {
      "city": "NORMANS COVE",
      "state": "NL"
  },
  {
      "city": "NORMETAL",
      "state": "QC"
  },
  {
      "city": "NORQUAY",
      "state": "SK"
  },
  {
      "city": "NORRIS ARM NORTHSIDE",
      "state": "NL"
  },
  {
      "city": "NORRIS ARM",
      "state": "NL"
  },
  {
      "city": "NORRIS POINT",
      "state": "NL"
  },
  {
      "city": "NORTH AUGUSTA",
      "state": "ON"
  },
  {
      "city": "NORTH BATTLEFORD",
      "state": "SK"
  },
  {
      "city": "NORTH BAY",
      "state": "ON"
  },
  {
      "city": "NORTH BUXTON",
      "state": "ON"
  },
  {
      "city": "NORTH COBALT",
      "state": "ON"
  },
  {
      "city": "NORTH EAST MARGAREE",
      "state": "NS"
  },
  {
      "city": "NORTH EAST POINT",
      "state": "NS"
  },
  {
      "city": "NORTH FORKS",
      "state": "NB"
  },
  {
      "city": "NORTH GOWER",
      "state": "ON"
  },
  {
      "city": "NORTH HARBOUR PB",
      "state": "NL"
  },
  {
      "city": "NORTH HARBOUR SMB",
      "state": "NL"
  },
  {
      "city": "NORTH HATLEY",
      "state": "QC"
  },
  {
      "city": "NORTH LAKE",
      "state": "NB"
  },
  {
      "city": "NORTH LANCASTER",
      "state": "ON"
  },
  {
      "city": "NORTH PINE",
      "state": "BC"
  },
  {
      "city": "NORTH PORTAL",
      "state": "SK"
  },
  {
      "city": "NORTH PRESTON",
      "state": "NS"
  },
  {
      "city": "NORTH RIVER",
      "state": "NS"
  },
  {
      "city": "NORTH RUSTICO",
      "state": "PE"
  },
  {
      "city": "NORTH SAANICH",
      "state": "BC"
  },
  {
      "city": "NORTH SALEM",
      "state": "NS"
  },
  {
      "city": "NORTH SHANNONVALE",
      "state": "NB"
  },
  {
      "city": "NORTH SPIRIT LAKE",
      "state": "ON"
  },
  {
      "city": "NORTH STAR",
      "state": "AB"
  },
  {
      "city": "NORTH SYDNEY",
      "state": "NS"
  },
  {
      "city": "NORTH TAY",
      "state": "NB"
  },
  {
      "city": "NORTH TETAGOUCHE",
      "state": "NB"
  },
  {
      "city": "NORTH VALLEY",
      "state": "NL"
  },
  {
      "city": "NORTH VANCOUVER",
      "state": "BC"
  },
  {
      "city": "NORTH VIEW",
      "state": "NB"
  },
  {
      "city": "NORTH WEST ARM",
      "state": "NS"
  },
  {
      "city": "NORTH WEST BROOK",
      "state": "NL"
  },
  {
      "city": "NORTH WEST RIVER",
      "state": "NL"
  },
  {
      "city": "NORTH WEYBURN",
      "state": "SK"
  },
  {
      "city": "NORTH WILTSHIRE",
      "state": "PE"
  },
  {
      "city": "NORTH YORK",
      "state": "ON"
  },
  {
      "city": "NORTHAMPTON",
      "state": "NB"
  },
  {
      "city": "NORTHBROOK",
      "state": "ON"
  },
  {
      "city": "NORTHERN BAY",
      "state": "NL"
  },
  {
      "city": "NORTHERN HARBOUR",
      "state": "NB"
  },
  {
      "city": "NORTHGATE",
      "state": "SK"
  },
  {
      "city": "NORTHPORT",
      "state": "NS"
  },
  {
      "city": "NORTHSIDE EAST BAY",
      "state": "NS"
  },
  {
      "city": "NORTON",
      "state": "NB"
  },
  {
      "city": "NORTONDALE",
      "state": "NB"
  },
  {
      "city": "NORVAL",
      "state": "ON"
  },
  {
      "city": "NORWAY HOUSE",
      "state": "MB"
  },
  {
      "city": "NORWICH",
      "state": "ON"
  },
  {
      "city": "NORWOOD",
      "state": "ON"
  },
  {
      "city": "NOTIKEWIN",
      "state": "AB"
  },
  {
      "city": "NOTRE DAME DE LOURDES",
      "state": "MB"
  },
  {
      "city": "NOTRE DAME DE LOURDES",
      "state": "NB"
  },
  {
      "city": "NOTRE-DAME",
      "state": "NB"
  },
  {
      "city": "NOTRE-DAME-DE-HAM",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-L'ILE-PERROT",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-LA-MERCI",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-LA-PAIX",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-LA-SALETTE",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-LORETTE",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-MONTAUBAN",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-PONTMAIN",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DE-STANBRIDGE",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DES-BOIS",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DES-ERABLES",
      "state": "NB"
  },
  {
      "city": "NOTRE-DAME-DES-MONTS",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DES-PINS",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DES-PRAIRIES",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DES-SEPT-DOULEURS",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DU-LAC",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DU-LAUS",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DU-MONT-CARMEL",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DU-NORD",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DU-PORTAGE",
      "state": "QC"
  },
  {
      "city": "NOTRE-DAME-DU-ROSAIRE",
      "state": "QC"
  },
  {
      "city": "NOTTAWA",
      "state": "ON"
  },
  {
      "city": "NOUVELLE",
      "state": "QC"
  },
  {
      "city": "NOUVELLE-OUEST",
      "state": "QC"
  },
  {
      "city": "NOVAR",
      "state": "ON"
  },
  {
      "city": "NOYAN",
      "state": "QC"
  },
  {
      "city": "NUT MOUNTAIN",
      "state": "SK"
  },
  {
      "city": "NUTTBY",
      "state": "NS"
  },
  {
      "city": "O'HANLY",
      "state": "MB"
  },
  {
      "city": "O'LEARY",
      "state": "PE"
  },
  {
      "city": "OAK BAY",
      "state": "NB"
  },
  {
      "city": "OAK BLUFF",
      "state": "MB"
  },
  {
      "city": "OAK HAVEN",
      "state": "NB"
  },
  {
      "city": "OAK HILL",
      "state": "NB"
  },
  {
      "city": "OAK LAKE",
      "state": "MB"
  },
  {
      "city": "OAK MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "OAK POINT KINGS CO",
      "state": "NB"
  },
  {
      "city": "OAK POINT",
      "state": "MB"
  },
  {
      "city": "OAK POINT",
      "state": "NB"
  },
  {
      "city": "OAK RIVER",
      "state": "MB"
  },
  {
      "city": "OAKBANK",
      "state": "MB"
  },
  {
      "city": "OAKBURN",
      "state": "MB"
  },
  {
      "city": "OAKFIELD",
      "state": "NS"
  },
  {
      "city": "OAKHILL",
      "state": "NS"
  },
  {
      "city": "OAKLAND",
      "state": "NB"
  },
  {
      "city": "OAKLAND",
      "state": "ON"
  },
  {
      "city": "OAKVIEW",
      "state": "MB"
  },
  {
      "city": "OAKVILLE",
      "state": "MB"
  },
  {
      "city": "OAKVILLE",
      "state": "NB"
  },
  {
      "city": "OAKVILLE",
      "state": "ON"
  },
  {
      "city": "OAKWOOD",
      "state": "ON"
  },
  {
      "city": "OBA",
      "state": "ON"
  },
  {
      "city": "OBEDJIWAN",
      "state": "QC"
  },
  {
      "city": "OCEAN FALLS",
      "state": "BC"
  },
  {
      "city": "OCHRE PIT COVE",
      "state": "NL"
  },
  {
      "city": "OCHRE RIVER",
      "state": "MB"
  },
  {
      "city": "ODANAK",
      "state": "QC"
  },
  {
      "city": "ODELL",
      "state": "NB"
  },
  {
      "city": "ODESSA",
      "state": "ON"
  },
  {
      "city": "ODESSA",
      "state": "SK"
  },
  {
      "city": "OGDEN",
      "state": "QC"
  },
  {
      "city": "OGEMA",
      "state": "SK"
  },
  {
      "city": "OGOKI",
      "state": "ON"
  },
  {
      "city": "OHATON",
      "state": "AB"
  },
  {
      "city": "OHSWEKEN",
      "state": "ON"
  },
  {
      "city": "OIL CITY",
      "state": "ON"
  },
  {
      "city": "OIL SPRINGS",
      "state": "ON"
  },
  {
      "city": "OKA",
      "state": "QC"
  },
  {
      "city": "OKANAGAN CENTRE",
      "state": "BC"
  },
  {
      "city": "OKANAGAN FALLS",
      "state": "BC"
  },
  {
      "city": "OKLA",
      "state": "SK"
  },
  {
      "city": "OKOTOKS",
      "state": "AB"
  },
  {
      "city": "OLD AVON",
      "state": "NB"
  },
  {
      "city": "OLD BARNS",
      "state": "NS"
  },
  {
      "city": "OLD CROW",
      "state": "YT"
  },
  {
      "city": "OLD FORT BAY",
      "state": "QC"
  },
  {
      "city": "OLD PERLICAN",
      "state": "NL"
  },
  {
      "city": "OLD RIDGE",
      "state": "NB"
  },
  {
      "city": "OLD SHOP",
      "state": "NL"
  },
  {
      "city": "OLDCASTLE",
      "state": "ON"
  },
  {
      "city": "OLDHAM",
      "state": "NS"
  },
  {
      "city": "OLDS",
      "state": "AB"
  },
  {
      "city": "OLHA",
      "state": "MB"
  },
  {
      "city": "OLIVER",
      "state": "BC"
  },
  {
      "city": "OMEMEE",
      "state": "ON"
  },
  {
      "city": "OMPAH",
      "state": "ON"
  },
  {
      "city": "ONANOLE",
      "state": "MB"
  },
  {
      "city": "ONAPING",
      "state": "ON"
  },
  {
      "city": "ONEFOUR",
      "state": "AB"
  },
  {
      "city": "ONION LAKE",
      "state": "SK"
  },
  {
      "city": "ONOWAY",
      "state": "AB"
  },
  {
      "city": "ONSLOW MOUNTAIN",
      "state": "NS"
  },
  {
      "city": "OONA RIVER",
      "state": "BC"
  },
  {
      "city": "OPAL",
      "state": "AB"
  },
  {
      "city": "OPASATIKA",
      "state": "ON"
  },
  {
      "city": "OPASKWAYAK",
      "state": "MB"
  },
  {
      "city": "OPEN HALL",
      "state": "NL"
  },
  {
      "city": "ORANGE HILL",
      "state": "NB"
  },
  {
      "city": "ORANGEDALE",
      "state": "NS"
  },
  {
      "city": "ORANGEVILLE",
      "state": "ON"
  },
  {
      "city": "ORFORD",
      "state": "QC"
  },
  {
      "city": "ORILLIA",
      "state": "ON"
  },
  {
      "city": "ORION",
      "state": "AB"
  },
  {
      "city": "ORKNEY",
      "state": "SK"
  },
  {
      "city": "ORLEANS",
      "state": "ON"
  },
  {
      "city": "ORMISTON",
      "state": "SK"
  },
  {
      "city": "ORMSTOWN",
      "state": "QC"
  },
  {
      "city": "ORO STATION",
      "state": "ON"
  },
  {
      "city": "ORO",
      "state": "ON"
  },
  {
      "city": "OROMOCTO",
      "state": "NB"
  },
  {
      "city": "ORONO",
      "state": "ON"
  },
  {
      "city": "ORTON",
      "state": "ON"
  },
  {
      "city": "OSAGE",
      "state": "SK"
  },
  {
      "city": "OSBORNE CORNER",
      "state": "NB"
  },
  {
      "city": "OSBORNE HARBOUR",
      "state": "NS"
  },
  {
      "city": "OSGOODE",
      "state": "ON"
  },
  {
      "city": "OSHAWA",
      "state": "ON"
  },
  {
      "city": "OSLER",
      "state": "SK"
  },
  {
      "city": "OSNABURGH HOUSE",
      "state": "ON"
  },
  {
      "city": "OSOYOOS",
      "state": "BC"
  },
  {
      "city": "OTTAWA BROOK",
      "state": "NS"
  },
  {
      "city": "OTTAWA",
      "state": "ON"
  },
  {
      "city": "OTTER CREEK",
      "state": "NB"
  },
  {
      "city": "OTTER LAKE",
      "state": "QC"
  },
  {
      "city": "OTTERBURN PARK",
      "state": "QC"
  },
  {
      "city": "OTTERBURNE",
      "state": "MB"
  },
  {
      "city": "OTTERVILLE",
      "state": "ON"
  },
  {
      "city": "OUJE-BOUGOUMOU",
      "state": "QC"
  },
  {
      "city": "OUNGRE",
      "state": "SK"
  },
  {
      "city": "OUTER COVE",
      "state": "NL"
  },
  {
      "city": "OUTLOOK",
      "state": "SK"
  },
  {
      "city": "OUTREMONT",
      "state": "QC"
  },
  {
      "city": "OVERSTONEVILLE",
      "state": "MB"
  },
  {
      "city": "OWEN SOUND",
      "state": "ON"
  },
  {
      "city": "OXBOW",
      "state": "NB"
  },
  {
      "city": "OXBOW",
      "state": "SK"
  },
  {
      "city": "OXDRIFT",
      "state": "ON"
  },
  {
      "city": "OXFORD HOUSE",
      "state": "MB"
  },
  {
      "city": "OXFORD JUNCTION",
      "state": "NS"
  },
  {
      "city": "OXFORD MILLS",
      "state": "ON"
  },
  {
      "city": "OXFORD STATION",
      "state": "ON"
  },
  {
      "city": "OXFORD",
      "state": "NS"
  },
  {
      "city": "OYAMA",
      "state": "BC"
  },
  {
      "city": "OYEN",
      "state": "AB"
  },
  {
      "city": "PABINEAU FALLS",
      "state": "NB"
  },
  {
      "city": "PABINEAU FIRST NATION",
      "state": "NB"
  },
  {
      "city": "PABOS MILLS",
      "state": "QC"
  },
  {
      "city": "PABOS",
      "state": "QC"
  },
  {
      "city": "PACKINGTON",
      "state": "QC"
  },
  {
      "city": "PACQUET",
      "state": "NL"
  },
  {
      "city": "PADDLE PRAIRIE",
      "state": "AB"
  },
  {
      "city": "PADDOCKWOOD",
      "state": "SK"
  },
  {
      "city": "PADOUE",
      "state": "QC"
  },
  {
      "city": "PAIN COURT",
      "state": "ON"
  },
  {
      "city": "PAISLEY",
      "state": "ON"
  },
  {
      "city": "PAKENHAM",
      "state": "ON"
  },
  {
      "city": "PAKWAW LAKE",
      "state": "SK"
  },
  {
      "city": "PALGRAVE",
      "state": "ON"
  },
  {
      "city": "PALMAROLLE",
      "state": "QC"
  },
  {
      "city": "PALMER RAPIDS",
      "state": "ON"
  },
  {
      "city": "PALMER",
      "state": "SK"
  },
  {
      "city": "PALMERSTON",
      "state": "ON"
  },
  {
      "city": "PAMBRUN",
      "state": "SK"
  },
  {
      "city": "PANGMAN",
      "state": "SK"
  },
  {
      "city": "PANGNIRTUNG",
      "state": "NU"
  },
  {
      "city": "PANORAMA",
      "state": "BC"
  },
  {
      "city": "PANSY",
      "state": "MB"
  },
  {
      "city": "PAPINEAUVILLE",
      "state": "QC"
  },
  {
      "city": "PAQUETVILLE",
      "state": "NB"
  },
  {
      "city": "PARADISE HILL",
      "state": "SK"
  },
  {
      "city": "PARADISE RIVER",
      "state": "NL"
  },
  {
      "city": "PARADISE VALLEY",
      "state": "AB"
  },
  {
      "city": "PARADISE",
      "state": "NL"
  },
  {
      "city": "PARADISE",
      "state": "NS"
  },
  {
      "city": "PARENT",
      "state": "QC"
  },
  {
      "city": "PARHAM",
      "state": "ON"
  },
  {
      "city": "PARIS",
      "state": "ON"
  },
  {
      "city": "PARISVILLE",
      "state": "QC"
  },
  {
      "city": "PARKBEG",
      "state": "SK"
  },
  {
      "city": "PARKER RIDGE",
      "state": "NB"
  },
  {
      "city": "PARKER ROAD",
      "state": "NB"
  },
  {
      "city": "PARKERS COVE",
      "state": "NL"
  },
  {
      "city": "PARKERVIEW",
      "state": "SK"
  },
  {
      "city": "PARKHILL",
      "state": "ON"
  },
  {
      "city": "PARKINDALE",
      "state": "NB"
  },
  {
      "city": "PARKLAND",
      "state": "AB"
  },
  {
      "city": "PARKMAN",
      "state": "SK"
  },
  {
      "city": "PARKSIDE",
      "state": "SK"
  },
  {
      "city": "PARKSVILLE",
      "state": "BC"
  },
  {
      "city": "PARLEE BROOK",
      "state": "NB"
  },
  {
      "city": "PARLEEVILLE",
      "state": "NB"
  },
  {
      "city": "PARRSBORO",
      "state": "NS"
  },
  {
      "city": "PARRY SOUND",
      "state": "ON"
  },
  {
      "city": "PARRY",
      "state": "SK"
  },
  {
      "city": "PARSON",
      "state": "BC"
  },
  {
      "city": "PARSONS POND",
      "state": "NL"
  },
  {
      "city": "PASADENA",
      "state": "NL"
  },
  {
      "city": "PASPEBIAC",
      "state": "QC"
  },
  {
      "city": "PASQUA",
      "state": "SK"
  },
  {
      "city": "PASS LAKE",
      "state": "ON"
  },
  {
      "city": "PASSEKEAG",
      "state": "NB"
  },
  {
      "city": "PATHLOW",
      "state": "SK"
  },
  {
      "city": "PATRICIA",
      "state": "AB"
  },
  {
      "city": "PATUANAK",
      "state": "SK"
  },
  {
      "city": "PAUINGASSI",
      "state": "MB"
  },
  {
      "city": "PAULATUK",
      "state": "NT"
  },
  {
      "city": "PAVILION",
      "state": "BC"
  },
  {
      "city": "PAWITIK",
      "state": "ON"
  },
  {
      "city": "PAYNTON",
      "state": "SK"
  },
  {
      "city": "PAYS PLAT",
      "state": "ON"
  },
  {
      "city": "PEACE RIVER RD",
      "state": "BC"
  },
  {
      "city": "PEACE RIVER",
      "state": "AB"
  },
  {
      "city": "PEACHLAND",
      "state": "BC"
  },
  {
      "city": "PEARSONVILLE",
      "state": "NB"
  },
  {
      "city": "PEAWANUCK",
      "state": "ON"
  },
  {
      "city": "PEEBLES",
      "state": "SK"
  },
  {
      "city": "PEEL",
      "state": "NB"
  },
  {
      "city": "PEERLESS LAKE",
      "state": "AB"
  },
  {
      "city": "PEERS",
      "state": "AB"
  },
  {
      "city": "PEFFERLAW",
      "state": "ON"
  },
  {
      "city": "PEGGYS COVE",
      "state": "NS"
  },
  {
      "city": "PEGUIS",
      "state": "MB"
  },
  {
      "city": "PELEE ISLAND",
      "state": "ON"
  },
  {
      "city": "PELERIN",
      "state": "NB"
  },
  {
      "city": "PELICAN NARROWS",
      "state": "SK"
  },
  {
      "city": "PELICAN RAPIDS",
      "state": "MB"
  },
  {
      "city": "PELLY CROSSING",
      "state": "YT"
  },
  {
      "city": "PELLY",
      "state": "SK"
  },
  {
      "city": "PELTOMA SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "PEMBERTON RIDGE",
      "state": "NB"
  },
  {
      "city": "PEMBERTON",
      "state": "BC"
  },
  {
      "city": "PEMBROKE",
      "state": "NB"
  },
  {
      "city": "PEMBROKE",
      "state": "ON"
  },
  {
      "city": "PENDER ISLAND",
      "state": "BC"
  },
  {
      "city": "PENETANGUISHENE",
      "state": "ON"
  },
  {
      "city": "PENHOLD",
      "state": "AB"
  },
  {
      "city": "PENNANT STATION",
      "state": "SK"
  },
  {
      "city": "PENNFIELD",
      "state": "NB"
  },
  {
      "city": "PENNIAC",
      "state": "NB"
  },
  {
      "city": "PENNY",
      "state": "BC"
  },
  {
      "city": "PENOBSQUIS",
      "state": "NB"
  },
  {
      "city": "PENSE",
      "state": "SK"
  },
  {
      "city": "PENTICTON",
      "state": "BC"
  },
  {
      "city": "PENZANCE",
      "state": "SK"
  },
  {
      "city": "PERCE",
      "state": "QC"
  },
  {
      "city": "PERDUE",
      "state": "SK"
  },
  {
      "city": "PERIBONKA",
      "state": "QC"
  },
  {
      "city": "PERKINSFIELD",
      "state": "ON"
  },
  {
      "city": "PERRAULT FALLS",
      "state": "ON"
  },
  {
      "city": "PERRY SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "PERRYVALE",
      "state": "AB"
  },
  {
      "city": "PERTH ROAD",
      "state": "ON"
  },
  {
      "city": "PERTH",
      "state": "ON"
  },
  {
      "city": "PERTH-ANDOVER",
      "state": "NB"
  },
  {
      "city": "PETAWAWA",
      "state": "ON"
  },
  {
      "city": "PETERBOROUGH",
      "state": "ON"
  },
  {
      "city": "PETERSBURG",
      "state": "ON"
  },
  {
      "city": "PETERSFIELD",
      "state": "MB"
  },
  {
      "city": "PETERVIEW",
      "state": "NL"
  },
  {
      "city": "PETIT DE GRAT",
      "state": "NS"
  },
  {
      "city": "PETIT ETANG",
      "state": "NS"
  },
  {
      "city": "PETIT FORTE",
      "state": "NL"
  },
  {
      "city": "PETIT TRACADIE",
      "state": "NB"
  },
  {
      "city": "PETIT-CAP",
      "state": "NB"
  },
  {
      "city": "PETIT-PAQUETVILLE",
      "state": "NB"
  },
  {
      "city": "PETIT-ROCHER",
      "state": "NB"
  },
  {
      "city": "PETIT-ROCHER-NORD",
      "state": "NB"
  },
  {
      "city": "PETIT-ROCHER-OUEST",
      "state": "NB"
  },
  {
      "city": "PETIT-ROCHER-SUD",
      "state": "NB"
  },
  {
      "city": "PETIT-SAGUENAY",
      "state": "QC"
  },
  {
      "city": "PETIT-SHIPPAGAN",
      "state": "NB"
  },
  {
      "city": "PETITCODIAC EAST",
      "state": "NB"
  },
  {
      "city": "PETITCODIAC",
      "state": "NB"
  },
  {
      "city": "PETITE RIVIERE",
      "state": "NS"
  },
  {
      "city": "PETITE-LAMEQUE",
      "state": "NB"
  },
  {
      "city": "PETITE-RIVIERE-DE-L'ILE",
      "state": "NB"
  },
  {
      "city": "PETITE-RIVIERE-SAINT-FRANCOIS",
      "state": "QC"
  },
  {
      "city": "PETITE-VALLEE",
      "state": "QC"
  },
  {
      "city": "PETROLIA",
      "state": "ON"
  },
  {
      "city": "PETTY HARBOUR",
      "state": "NL"
  },
  {
      "city": "PHELPSTON",
      "state": "ON"
  },
  {
      "city": "PHILIPSBURG",
      "state": "QC"
  },
  {
      "city": "PHIPPEN",
      "state": "SK"
  },
  {
      "city": "PIAPOT",
      "state": "SK"
  },
  {
      "city": "PICADILLY",
      "state": "NB"
  },
  {
      "city": "PICKARDVILLE",
      "state": "AB"
  },
  {
      "city": "PICKEREL",
      "state": "ON"
  },
  {
      "city": "PICKERING",
      "state": "ON"
  },
  {
      "city": "PICKETTS COVE",
      "state": "NB"
  },
  {
      "city": "PICKLE LAKE",
      "state": "ON"
  },
  {
      "city": "PICTON",
      "state": "ON"
  },
  {
      "city": "PICTOU ISLAND",
      "state": "NS"
  },
  {
      "city": "PICTOU",
      "state": "NS"
  },
  {
      "city": "PICTURE BUTTE",
      "state": "AB"
  },
  {
      "city": "PIEDMONT",
      "state": "QC"
  },
  {
      "city": "PIERCELAND",
      "state": "SK"
  },
  {
      "city": "PIERCEMONT",
      "state": "NB"
  },
  {
      "city": "PIERREFONDS",
      "state": "QC"
  },
  {
      "city": "PIERREVILLE",
      "state": "QC"
  },
  {
      "city": "PIERSON",
      "state": "MB"
  },
  {
      "city": "PIGEON HILL",
      "state": "NB"
  },
  {
      "city": "PIKANGIKUM",
      "state": "ON"
  },
  {
      "city": "PIKE RIVER",
      "state": "QC"
  },
  {
      "city": "PIKWITONEI",
      "state": "MB"
  },
  {
      "city": "PILGER",
      "state": "SK"
  },
  {
      "city": "PILLEYS ISLAND",
      "state": "NL"
  },
  {
      "city": "PILOT BUTTE",
      "state": "SK"
  },
  {
      "city": "PILOT MOUND",
      "state": "MB"
  },
  {
      "city": "PINANTAN LAKE",
      "state": "BC"
  },
  {
      "city": "PINAWA",
      "state": "MB"
  },
  {
      "city": "PINCHER CREEK",
      "state": "AB"
  },
  {
      "city": "PINCOURT",
      "state": "QC"
  },
  {
      "city": "PINE FALLS",
      "state": "MB"
  },
  {
      "city": "PINE GLEN",
      "state": "NB"
  },
  {
      "city": "PINE GROVE",
      "state": "NS"
  },
  {
      "city": "PINE GROVE.",
      "state": "NS"
  },
  {
      "city": "PINE LAKE",
      "state": "AB"
  },
  {
      "city": "PINE RIDGE",
      "state": "NB"
  },
  {
      "city": "PINE RIVER",
      "state": "MB"
  },
  {
      "city": "PINEHOUSE LAKE",
      "state": "SK"
  },
  {
      "city": "PINEHURST",
      "state": "NS"
  },
  {
      "city": "PINEVILLE",
      "state": "NB"
  },
  {
      "city": "PINEWOOD",
      "state": "ON"
  },
  {
      "city": "PINEY",
      "state": "MB"
  },
  {
      "city": "PINK MOUNTAIN",
      "state": "BC"
  },
  {
      "city": "PINTENDRE",
      "state": "QC"
  },
  {
      "city": "PIOPOLIS",
      "state": "QC"
  },
  {
      "city": "PIPERS COVE",
      "state": "NS"
  },
  {
      "city": "PIPESTONE",
      "state": "MB"
  },
  {
      "city": "PITT MEADOWS",
      "state": "BC"
  },
  {
      "city": "PLACENTIA",
      "state": "NL"
  },
  {
      "city": "PLAINFIELD",
      "state": "ON"
  },
  {
      "city": "PLAISANCE",
      "state": "QC"
  },
  {
      "city": "PLAMONDON",
      "state": "AB"
  },
  {
      "city": "PLANTAGENET",
      "state": "ON"
  },
  {
      "city": "PLASTER ROCK",
      "state": "NB"
  },
  {
      "city": "PLATE COVE EAST",
      "state": "NL"
  },
  {
      "city": "PLATE COVE WEST",
      "state": "NL"
  },
  {
      "city": "PLATO",
      "state": "SK"
  },
  {
      "city": "PLATTSVILLE",
      "state": "ON"
  },
  {
      "city": "PLEASANT BAY",
      "state": "NS"
  },
  {
      "city": "PLEASANT HILL",
      "state": "NS"
  },
  {
      "city": "PLEASANT RIDGE CHAR COUNTY",
      "state": "NB"
  },
  {
      "city": "PLEASANT RIDGE KINGS CO",
      "state": "NB"
  },
  {
      "city": "PLEASANT VILLA",
      "state": "NB"
  },
  {
      "city": "PLEASANTDALE",
      "state": "SK"
  },
  {
      "city": "PLEASANTVILLE",
      "state": "NS"
  },
  {
      "city": "PLENTY",
      "state": "SK"
  },
  {
      "city": "PLESSISVILLE",
      "state": "QC"
  },
  {
      "city": "PLEVNA",
      "state": "ON"
  },
  {
      "city": "PLUM COULEE",
      "state": "MB"
  },
  {
      "city": "PLUM POINT",
      "state": "NL"
  },
  {
      "city": "PLUMAS",
      "state": "MB"
  },
  {
      "city": "PLUMWESEEP",
      "state": "NB"
  },
  {
      "city": "PLUNKETT",
      "state": "SK"
  },
  {
      "city": "PLYMOUTH",
      "state": "NB"
  },
  {
      "city": "PLYMPTON",
      "state": "NS"
  },
  {
      "city": "POCOLOGAN",
      "state": "NB"
  },
  {
      "city": "POHENEGAMOOK",
      "state": "QC"
  },
  {
      "city": "POINT ACONI",
      "state": "NS"
  },
  {
      "city": "POINT DE BUTE",
      "state": "NB"
  },
  {
      "city": "POINT EDWARD",
      "state": "NS"
  },
  {
      "city": "POINT EDWARD",
      "state": "ON"
  },
  {
      "city": "POINT LA NIM",
      "state": "NB"
  },
  {
      "city": "POINT LEAMINGTON",
      "state": "NL"
  },
  {
      "city": "POINT OF BAY",
      "state": "NL"
  },
  {
      "city": "POINT TUPPER",
      "state": "NS"
  },
  {
      "city": "POINTE A BOULEAU",
      "state": "NB"
  },
  {
      "city": "POINTE A TOM",
      "state": "NB"
  },
  {
      "city": "POINTE AUX ROCHES",
      "state": "ON"
  },
  {
      "city": "POINTE DES ROBICHAUD",
      "state": "NB"
  },
  {
      "city": "POINTE DIXON POINT",
      "state": "NB"
  },
  {
      "city": "POINTE DU BOIS",
      "state": "MB"
  },
  {
      "city": "POINTE-A-LA-CROIX",
      "state": "QC"
  },
  {
      "city": "POINTE-A-LA-GARDE",
      "state": "QC"
  },
  {
      "city": "POINTE-ALEXANDRE",
      "state": "NB"
  },
  {
      "city": "POINTE-AU-BARIL-STATION",
      "state": "ON"
  },
  {
      "city": "POINTE-AUX-LOUPS",
      "state": "QC"
  },
  {
      "city": "POINTE-AUX-OUTARDES",
      "state": "QC"
  },
  {
      "city": "POINTE-AUX-TREMBLES",
      "state": "QC"
  },
  {
      "city": "POINTE-BRULE",
      "state": "NB"
  },
  {
      "city": "POINTE-CALUMET",
      "state": "QC"
  },
  {
      "city": "POINTE-CANOT",
      "state": "NB"
  },
  {
      "city": "POINTE-CLAIRE",
      "state": "QC"
  },
  {
      "city": "POINTE-DES-CASCADES",
      "state": "QC"
  },
  {
      "city": "POINTE-DU-CHENE",
      "state": "NB"
  },
  {
      "city": "POINTE-FORTUNE",
      "state": "QC"
  },
  {
      "city": "POINTE-LEBEL",
      "state": "QC"
  },
  {
      "city": "POINTE-SAPIN",
      "state": "NB"
  },
  {
      "city": "POINTE-SAUVAGE",
      "state": "NB"
  },
  {
      "city": "POINTE-VERTE",
      "state": "NB"
  },
  {
      "city": "POIRIER SUBDIVISION",
      "state": "NB"
  },
  {
      "city": "POKEMOUCHE",
      "state": "NB"
  },
  {
      "city": "POKESHAW",
      "state": "NB"
  },
  {
      "city": "POKESUDIE",
      "state": "NB"
  },
  {
      "city": "POKIOK",
      "state": "NB"
  },
  {
      "city": "POLE HILL",
      "state": "NB"
  },
  {
      "city": "POLLARDS POINT",
      "state": "NL"
  },
  {
      "city": "POLLETT RIVER",
      "state": "NB"
  },
  {
      "city": "POLLOCKVILLE",
      "state": "AB"
  },
  {
      "city": "POLONIA",
      "state": "MB"
  },
  {
      "city": "POMEROY RIDGE",
      "state": "NB"
  },
  {
      "city": "POND INLET",
      "state": "NU"
  },
  {
      "city": "PONDSTREAM",
      "state": "NB"
  },
  {
      "city": "PONOKA",
      "state": "AB"
  },
  {
      "city": "PONT LAFRANCE",
      "state": "NB"
  },
  {
      "city": "PONT LANDRY",
      "state": "NB"
  },
  {
      "city": "PONT-ROUGE",
      "state": "QC"
  },
  {
      "city": "PONTEIX",
      "state": "SK"
  },
  {
      "city": "PONTYPOOL",
      "state": "ON"
  },
  {
      "city": "POODIAC",
      "state": "NB"
  },
  {
      "city": "POOLE",
      "state": "ON"
  },
  {
      "city": "POOLS COVE",
      "state": "NL"
  },
  {
      "city": "POOLS ISLAND",
      "state": "NL"
  },
  {
      "city": "POPLAR HILL",
      "state": "ON"
  },
  {
      "city": "POPLAR POINT",
      "state": "MB"
  },
  {
      "city": "POPLARFIELD",
      "state": "MB"
  },
  {
      "city": "PORCUPINE PLAIN",
      "state": "SK"
  },
  {
      "city": "PORCUPINE",
      "state": "ON"
  },
  {
      "city": "PORQUIS JUNCTION",
      "state": "ON"
  },
  {
      "city": "PORT ALBERNI",
      "state": "BC"
  },
  {
      "city": "PORT ALBERT",
      "state": "NL"
  },
  {
      "city": "PORT ALICE",
      "state": "BC"
  },
  {
      "city": "PORT ALMA",
      "state": "ON"
  },
  {
      "city": "PORT ANSON",
      "state": "NL"
  },
  {
      "city": "PORT AU CHOIX",
      "state": "NL"
  },
  {
      "city": "PORT AU PORT",
      "state": "NL"
  },
  {
      "city": "PORT BLANDFORD",
      "state": "NL"
  },
  {
      "city": "PORT BURWELL",
      "state": "ON"
  },
  {
      "city": "PORT CALEDONIA",
      "state": "NS"
  },
  {
      "city": "PORT CARLING",
      "state": "ON"
  },
  {
      "city": "PORT CLEMENTS",
      "state": "BC"
  },
  {
      "city": "PORT CLYDE",
      "state": "NS"
  },
  {
      "city": "PORT COLBORNE",
      "state": "ON"
  },
  {
      "city": "PORT COQUITLAM",
      "state": "BC"
  },
  {
      "city": "PORT DE GRAVE",
      "state": "NL"
  },
  {
      "city": "PORT DOVER",
      "state": "ON"
  },
  {
      "city": "PORT DUFFERIN",
      "state": "NS"
  },
  {
      "city": "PORT EDWARD",
      "state": "BC"
  },
  {
      "city": "PORT ELGIN",
      "state": "NB"
  },
  {
      "city": "PORT ELGIN",
      "state": "ON"
  },
  {
      "city": "PORT FRANKS",
      "state": "ON"
  },
  {
      "city": "PORT GREVILLE",
      "state": "NS"
  },
  {
      "city": "PORT HARDY",
      "state": "BC"
  },
  {
      "city": "PORT HASTINGS",
      "state": "NS"
  },
  {
      "city": "PORT HAWKESBURY",
      "state": "NS"
  },
  {
      "city": "PORT HOOD",
      "state": "NS"
  },
  {
      "city": "PORT HOPE SIMPSON",
      "state": "NL"
  },
  {
      "city": "PORT HOPE",
      "state": "ON"
  },
  {
      "city": "PORT HOWE",
      "state": "NS"
  },
  {
      "city": "PORT JOLI",
      "state": "NS"
  },
  {
      "city": "PORT LA TOUR",
      "state": "NS"
  },
  {
      "city": "PORT LAMBTON",
      "state": "ON"
  },
  {
      "city": "PORT LORING",
      "state": "ON"
  },
  {
      "city": "PORT MAITLAND",
      "state": "NS"
  },
  {
      "city": "PORT MALCOLM",
      "state": "NS"
  },
  {
      "city": "PORT MCNEILL",
      "state": "BC"
  },
  {
      "city": "PORT MCNICOLL",
      "state": "ON"
  },
  {
      "city": "PORT MEDWAY",
      "state": "NS"
  },
  {
      "city": "PORT MELLON",
      "state": "BC"
  },
  {
      "city": "PORT MOODY",
      "state": "BC"
  },
  {
      "city": "PORT MORIEN",
      "state": "NS"
  },
  {
      "city": "PORT MOUTON",
      "state": "NS"
  },
  {
      "city": "PORT NEVILLE",
      "state": "BC"
  },
  {
      "city": "PORT PERRY",
      "state": "ON"
  },
  {
      "city": "PORT RENFREW",
      "state": "BC"
  },
  {
      "city": "PORT REXTON",
      "state": "NL"
  },
  {
      "city": "PORT ROBINSON",
      "state": "ON"
  },
  {
      "city": "PORT ROWAN",
      "state": "ON"
  },
  {
      "city": "PORT SANDFIELD",
      "state": "ON"
  },
  {
      "city": "PORT SAUNDERS",
      "state": "NL"
  },
  {
      "city": "PORT SEVERN",
      "state": "ON"
  },
  {
      "city": "PORT STANLEY",
      "state": "ON"
  },
  {
      "city": "PORT SYDNEY",
      "state": "ON"
  },
  {
      "city": "PORT UNION",
      "state": "NL"
  },
  {
      "city": "PORT WILLIAMS",
      "state": "NS"
  },
  {
      "city": "PORT-CARTIER",
      "state": "QC"
  },
  {
      "city": "PORT-DANIEL",
      "state": "QC"
  },
  {
      "city": "PORT-MENIER",
      "state": "QC"
  },
  {
      "city": "PORTAGE LA PRAIRIE",
      "state": "MB"
  },
  {
      "city": "PORTAGE ST-LOUIS",
      "state": "NB"
  },
  {
      "city": "PORTAGE VALE",
      "state": "NB"
  },
  {
      "city": "PORTAGE",
      "state": "NB"
  },
  {
      "city": "PORTAGE",
      "state": "NS"
  },
  {
      "city": "PORTAGE-DU-FORT",
      "state": "QC"
  },
  {
      "city": "PORTER COVE",
      "state": "NB"
  },
  {
      "city": "PORTERS LAKE",
      "state": "NS"
  },
  {
      "city": "PORTLAND CREEK",
      "state": "NL"
  },
  {
      "city": "PORTLAND",
      "state": "ON"
  },
  {
      "city": "PORTNEUF",
      "state": "QC"
  },
  {
      "city": "PORTNEUF-SUR-MER",
      "state": "QC"
  },
  {
      "city": "PORTREEVE",
      "state": "SK"
  },
  {
      "city": "PORTUGAL COVE-ST PHILIPS",
      "state": "NL"
  },
  {
      "city": "PORTUGUESE COVE",
      "state": "NS"
  },
  {
      "city": "POSTVILLE",
      "state": "NL"
  },
  {
      "city": "POUCE COUPE",
      "state": "BC"
  },
  {
      "city": "POUCH COVE",
      "state": "NL"
  },
  {
      "city": "POULARIES",
      "state": "QC"
  },
  {
      "city": "POUND COVE",
      "state": "NL"
  },
  {
      "city": "POWASSAN",
      "state": "ON"
  },
  {
      "city": "POWELL RIVER",
      "state": "BC"
  },
  {
      "city": "POWERVIEW",
      "state": "MB"
  },
  {
      "city": "POWNAL",
      "state": "PE"
  },
  {
      "city": "PRAIRIE RIVER",
      "state": "SK"
  },
  {
      "city": "PRAIRIE VIEW",
      "state": "SK"
  },
  {
      "city": "PRATT",
      "state": "MB"
  },
  {
      "city": "PREECEVILLE",
      "state": "SK"
  },
  {
      "city": "PREISSAC",
      "state": "QC"
  },
  {
      "city": "PRELATE",
      "state": "SK"
  },
  {
      "city": "PRESCOTT",
      "state": "ON"
  },
  {
      "city": "PRESPATOU",
      "state": "BC"
  },
  {
      "city": "PREVOST",
      "state": "QC"
  },
  {
      "city": "PRICE",
      "state": "QC"
  },
  {
      "city": "PRICEVILLE",
      "state": "NB"
  },
  {
      "city": "PRICEVILLE",
      "state": "ON"
  },
  {
      "city": "PRIDDIS",
      "state": "AB"
  },
  {
      "city": "PRIMATE",
      "state": "SK"
  },
  {
      "city": "PRIME BROOK",
      "state": "NS"
  },
  {
      "city": "PRINCE ALBERT",
      "state": "ON"
  },
  {
      "city": "PRINCE ALBERT",
      "state": "SK"
  },
  {
      "city": "PRINCE GEORGE",
      "state": "BC"
  },
  {
      "city": "PRINCE OF WALES",
      "state": "NB"
  },
  {
      "city": "PRINCE RUPERT",
      "state": "BC"
  },
  {
      "city": "PRINCE WILLIAM",
      "state": "NB"
  },
  {
      "city": "PRINCEPORT",
      "state": "NS"
  },
  {
      "city": "PRINCESS HARBOUR",
      "state": "MB"
  },
  {
      "city": "PRINCESS PARK",
      "state": "NB"
  },
  {
      "city": "PRINCETON",
      "state": "BC"
  },
  {
      "city": "PRINCETON",
      "state": "NL"
  },
  {
      "city": "PRINCETON",
      "state": "ON"
  },
  {
      "city": "PRINCEVILLE",
      "state": "QC"
  },
  {
      "city": "PRINTZ COVE",
      "state": "NB"
  },
  {
      "city": "PRITCHARD",
      "state": "BC"
  },
  {
      "city": "PROCTER",
      "state": "BC"
  },
  {
      "city": "PROGRESS",
      "state": "BC"
  },
  {
      "city": "PROPHET RIVER",
      "state": "BC"
  },
  {
      "city": "PROSPECT BAY",
      "state": "NS"
  },
  {
      "city": "PROSPECT VILLAGE",
      "state": "NS"
  },
  {
      "city": "PROSPECT",
      "state": "NS"
  },
  {
      "city": "PROSSER BROOK",
      "state": "NB"
  },
  {
      "city": "PROTON STATION",
      "state": "ON"
  },
  {
      "city": "PROULXVILLE",
      "state": "QC"
  },
  {
      "city": "PROVIDENCE BAY",
      "state": "ON"
  },
  {
      "city": "PROVOST",
      "state": "AB"
  },
  {
      "city": "PRUD'HOMME",
      "state": "SK"
  },
  {
      "city": "PUBLIC LANDING",
      "state": "NB"
  },
  {
      "city": "PUBNICO",
      "state": "NS"
  },
  {
      "city": "PUGWASH JUNCTION",
      "state": "NS"
  },
  {
      "city": "PUGWASH",
      "state": "NS"
  },
  {
      "city": "PUKATAWAGAN",
      "state": "MB"
  },
  {
      "city": "PUNNICHY",
      "state": "SK"
  },
  {
      "city": "PURPLE SPRINGS",
      "state": "AB"
  },
  {
      "city": "PUSLINCH",
      "state": "ON"
  },
  {
      "city": "PUTNAM",
      "state": "ON"
  },
  {
      "city": "PUVIRNITUQ",
      "state": "QC"
  },
  {
      "city": "PYNN'S BROOK",
      "state": "NL"
  },
  {
      "city": "QIKIQTARJUAQ",
      "state": "NU"
  },
  {
      "city": "QU'APPELLE",
      "state": "SK"
  },
  {
      "city": "QUADEVILLE",
      "state": "ON"
  },
  {
      "city": "QUAKER BROOK",
      "state": "NB"
  },
  {
      "city": "QUALICUM BEACH",
      "state": "BC"
  },
  {
      "city": "QUAQTAQ",
      "state": "QC"
  },
  {
      "city": "QUARRYVILLE",
      "state": "NB"
  },
  {
      "city": "QUATHIASKI COVE",
      "state": "BC"
  },
  {
      "city": "QUATSINO",
      "state": "BC"
  },
  {
      "city": "QUEBEC",
      "state": "QC"
  },
  {
      "city": "QUEEN CHARLOTTE",
      "state": "BC"
  },
  {
      "city": "QUEENSTON",
      "state": "ON"
  },
  {
      "city": "QUEENSTOWN",
      "state": "NB"
  },
  {
      "city": "QUEENSVILLE",
      "state": "NS"
  },
  {
      "city": "QUEENSVILLE",
      "state": "ON"
  },
  {
      "city": "QUESNEL",
      "state": "BC"
  },
  {
      "city": "QUILCHENA",
      "state": "BC"
  },
  {
      "city": "QUILL LAKE",
      "state": "SK"
  },
  {
      "city": "QUINTON",
      "state": "SK"
  },
  {
      "city": "QUISPAMSIS",
      "state": "NB"
  },
  {
      "city": "QUYON",
      "state": "QC"
  },
  {
      "city": "RABBIT LAKE",
      "state": "SK"
  },
  {
      "city": "RACINE",
      "state": "QC"
  },
  {
      "city": "RADISSON",
      "state": "QC"
  },
  {
      "city": "RADISSON",
      "state": "SK"
  },
  {
      "city": "RADIUM HOT SPRINGS",
      "state": "BC"
  },
  {
      "city": "RADVILLE",
      "state": "SK"
  },
  {
      "city": "RADWAY",
      "state": "AB"
  },
  {
      "city": "RAGUENEAU",
      "state": "QC"
  },
  {
      "city": "RAINBOW LAKE",
      "state": "AB"
  },
  {
      "city": "RAINIER",
      "state": "AB"
  },
  {
      "city": "RAINY RIVER",
      "state": "ON"
  },
  {
      "city": "RAITH",
      "state": "ON"
  },
  {
      "city": "RALEIGH",
      "state": "NL"
  },
  {
      "city": "RALSTON",
      "state": "AB"
  },
  {
      "city": "RAMA",
      "state": "ON"
  },
  {
      "city": "RAMA",
      "state": "SK"
  },
  {
      "city": "RAMEA",
      "state": "NL"
  },
  {
      "city": "RAMORE",
      "state": "ON"
  },
  {
      "city": "RAMSAYVILLE",
      "state": "ON"
  },
  {
      "city": "RAMSEY",
      "state": "ON"
  },
  {
      "city": "RANDOLPH",
      "state": "MB"
  },
  {
      "city": "RANFURLY",
      "state": "AB"
  },
  {
      "city": "RANG-SAINT-GEORGES",
      "state": "NB"
  },
  {
      "city": "RANKIN INLET",
      "state": "NU"
  },
  {
      "city": "RAPID CITY",
      "state": "MB"
  },
  {
      "city": "RAPID LAKE",
      "state": "QC"
  },
  {
      "city": "RAPID VIEW",
      "state": "SK"
  },
  {
      "city": "RAPIDE-DANSEUR",
      "state": "QC"
  },
  {
      "city": "RAPIDES-DES-JOACHIMS",
      "state": "QC"
  },
  {
      "city": "RATHWELL",
      "state": "MB"
  },
  {
      "city": "RATTER CORNER",
      "state": "NB"
  },
  {
      "city": "RATTLING BROOK",
      "state": "NL"
  },
  {
      "city": "RAVENNA",
      "state": "ON"
  },
  {
      "city": "RAVIGNAN",
      "state": "QC"
  },
  {
      "city": "RAWDON",
      "state": "QC"
  },
  {
      "city": "RAYMOND",
      "state": "AB"
  },
  {
      "city": "RAYMORE",
      "state": "SK"
  },
  {
      "city": "REABORO",
      "state": "ON"
  },
  {
      "city": "RED BANK QUEENS CO",
      "state": "NB"
  },
  {
      "city": "RED BANK RESERVE",
      "state": "NB"
  },
  {
      "city": "RED BANK",
      "state": "NB"
  },
  {
      "city": "RED BAY",
      "state": "NL"
  },
  {
      "city": "RED BRIDGE",
      "state": "NB"
  },
  {
      "city": "RED DEER COUNTY",
      "state": "AB"
  },
  {
      "city": "RED DEER",
      "state": "AB"
  },
  {
      "city": "RED EARTH CREEK",
      "state": "AB"
  },
  {
      "city": "RED EARTH",
      "state": "SK"
  },
  {
      "city": "RED HARBOUR PB",
      "state": "NL"
  },
  {
      "city": "RED HEAD COVE",
      "state": "NL"
  },
  {
      "city": "RED LAKE",
      "state": "ON"
  },
  {
      "city": "RED POINT",
      "state": "NS"
  },
  {
      "city": "RED RAPIDS",
      "state": "NB"
  },
  {
      "city": "RED ROCK",
      "state": "NB"
  },
  {
      "city": "RED ROCK",
      "state": "ON"
  },
  {
      "city": "RED SUCKER LAKE",
      "state": "MB"
  },
  {
      "city": "RED WILLOW",
      "state": "AB"
  },
  {
      "city": "REDBRIDGE",
      "state": "ON"
  },
  {
      "city": "REDCLIFF",
      "state": "AB"
  },
  {
      "city": "REDDITT",
      "state": "ON"
  },
  {
      "city": "REDMONDVILLE",
      "state": "NB"
  },
  {
      "city": "REDSTONE",
      "state": "BC"
  },
  {
      "city": "REDVERS",
      "state": "SK"
  },
  {
      "city": "REDWATER",
      "state": "AB"
  },
  {
      "city": "REDWOOD MEADOWS",
      "state": "AB"
  },
  {
      "city": "REEFS HARBOUR",
      "state": "NL"
  },
  {
      "city": "REFUGE COVE",
      "state": "BC"
  },
  {
      "city": "REGINA BEACH",
      "state": "SK"
  },
  {
      "city": "REGINA",
      "state": "SK"
  },
  {
      "city": "REIDVILLE",
      "state": "NL"
  },
  {
      "city": "REINFELD",
      "state": "MB"
  },
  {
      "city": "REMIGNY",
      "state": "QC"
  },
  {
      "city": "RENAUDS MILLS",
      "state": "NB"
  },
  {
      "city": "RENCONTRE EAST",
      "state": "NL"
  },
  {
      "city": "RENEWS",
      "state": "NL"
  },
  {
      "city": "RENFREW",
      "state": "ON"
  },
  {
      "city": "RENNIE",
      "state": "MB"
  },
  {
      "city": "RENOUS",
      "state": "NB"
  },
  {
      "city": "RENWER",
      "state": "MB"
  },
  {
      "city": "REPENTIGNY",
      "state": "QC"
  },
  {
      "city": "REPULSE BAY",
      "state": "NU"
  },
  {
      "city": "RESERVE MINES",
      "state": "NS"
  },
  {
      "city": "RESOLUTE",
      "state": "NU"
  },
  {
      "city": "RESTON",
      "state": "MB"
  },
  {
      "city": "RESTOULE",
      "state": "ON"
  },
  {
      "city": "REVELSTOKE",
      "state": "BC"
  },
  {
      "city": "REWARD",
      "state": "SK"
  },
  {
      "city": "REXTON",
      "state": "NB"
  },
  {
      "city": "RHEIN",
      "state": "SK"
  },
  {
      "city": "RHODES CORNER",
      "state": "NS"
  },
  {
      "city": "RICETON",
      "state": "SK"
  },
  {
      "city": "RICEVILLE",
      "state": "NB"
  },
  {
      "city": "RICHARD",
      "state": "SK"
  },
  {
      "city": "RICHARDS LANDING",
      "state": "ON"
  },
  {
      "city": "RICHARDSON",
      "state": "NB"
  },
  {
      "city": "RICHARDSON",
      "state": "SK"
  },
  {
      "city": "RICHELIEU",
      "state": "QC"
  },
  {
      "city": "RICHER",
      "state": "MB"
  },
  {
      "city": "RICHIBOUCTOU-VILLAGE",
      "state": "NB"
  },
  {
      "city": "RICHIBUCTO ROAD",
      "state": "NB"
  },
  {
      "city": "RICHIBUCTO",
      "state": "NB"
  },
  {
      "city": "RICHLEA",
      "state": "SK"
  },
  {
      "city": "RICHMOND CORNER",
      "state": "NB"
  },
  {
      "city": "RICHMOND HILL",
      "state": "ON"
  },
  {
      "city": "RICHMOND SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "RICHMOND",
      "state": "BC"
  },
  {
      "city": "RICHMOND",
      "state": "ON"
  },
  {
      "city": "RICHMOND",
      "state": "PE"
  },
  {
      "city": "RICHMOND",
      "state": "QC"
  },
  {
      "city": "RICHMOUND",
      "state": "SK"
  },
  {
      "city": "RIDEAU FERRY",
      "state": "ON"
  },
  {
      "city": "RIDGEDALE",
      "state": "SK"
  },
  {
      "city": "RIDGETOWN",
      "state": "ON"
  },
  {
      "city": "RIDGEVILLE",
      "state": "MB"
  },
  {
      "city": "RIDGEVILLE",
      "state": "ON"
  },
  {
      "city": "RIDGEWAY",
      "state": "ON"
  },
  {
      "city": "RIDING MOUNTAIN",
      "state": "MB"
  },
  {
      "city": "RIGAUD",
      "state": "QC"
  },
  {
      "city": "RIGOLET",
      "state": "NL"
  },
  {
      "city": "RILEY BROOK",
      "state": "NB"
  },
  {
      "city": "RIMBEY",
      "state": "AB"
  },
  {
      "city": "RIMOUSKI",
      "state": "QC"
  },
  {
      "city": "RINES CREEK",
      "state": "NS"
  },
  {
      "city": "RIO GRANDE",
      "state": "NB"
  },
  {
      "city": "RIONDEL",
      "state": "BC"
  },
  {
      "city": "RIPLEY",
      "state": "ON"
  },
  {
      "city": "RIPON",
      "state": "QC"
  },
  {
      "city": "RIPPLES",
      "state": "NB"
  },
  {
      "city": "RISKE CREEK",
      "state": "BC"
  },
  {
      "city": "RITCHIE",
      "state": "NB"
  },
  {
      "city": "RIVER BOURGEOIS",
      "state": "NS"
  },
  {
      "city": "RIVER DE CHUTE",
      "state": "NB"
  },
  {
      "city": "RIVER DENYS",
      "state": "NS"
  },
  {
      "city": "RIVER DRIVE PARK",
      "state": "ON"
  },
  {
      "city": "RIVER GLADE",
      "state": "NB"
  },
  {
      "city": "RIVER HEBERT EAST",
      "state": "NS"
  },
  {
      "city": "RIVER HEBERT",
      "state": "NS"
  },
  {
      "city": "RIVER HILLS",
      "state": "MB"
  },
  {
      "city": "RIVER JOHN",
      "state": "NS"
  },
  {
      "city": "RIVER OF PONDS",
      "state": "NL"
  },
  {
      "city": "RIVER PHILIP",
      "state": "NS"
  },
  {
      "city": "RIVER RYAN",
      "state": "NS"
  },
  {
      "city": "RIVER VALLEY",
      "state": "ON"
  },
  {
      "city": "RIVERBANK CARLETON CO",
      "state": "NB"
  },
  {
      "city": "RIVERBANK KINGS CO",
      "state": "NB"
  },
  {
      "city": "RIVERBANK SOUTH",
      "state": "NB"
  },
  {
      "city": "RIVERCOURSE",
      "state": "AB"
  },
  {
      "city": "RIVERHEAD HARBOUR GRACE",
      "state": "NL"
  },
  {
      "city": "RIVERHURST",
      "state": "SK"
  },
  {
      "city": "RIVERPORT",
      "state": "NS"
  },
  {
      "city": "RIVERS",
      "state": "MB"
  },
  {
      "city": "RIVERSDALE",
      "state": "NS"
  },
  {
      "city": "RIVERSIDE ESTATES",
      "state": "SK"
  },
  {
      "city": "RIVERSIDE",
      "state": "NS"
  },
  {
      "city": "RIVERSIDE-ALBERT",
      "state": "NB"
  },
  {
      "city": "RIVERTON",
      "state": "MB"
  },
  {
      "city": "RIVERVIEW",
      "state": "NB"
  },
  {
      "city": "RIVIERE A LA TRUITE",
      "state": "NB"
  },
  {
      "city": "RIVIERE DU NORD",
      "state": "NB"
  },
  {
      "city": "RIVIERE QUI BARRE",
      "state": "AB"
  },
  {
      "city": "RIVIERE-A-CLAUDE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-A-PIERRE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-AU-TONNERRE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-BEAUDETTE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-BLEUE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-DU-LOUP",
      "state": "QC"
  },
  {
      "city": "RIVIERE-DU-PORTAGE",
      "state": "NB"
  },
  {
      "city": "RIVIERE-ETERNITE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-HEVA",
      "state": "QC"
  },
  {
      "city": "RIVIERE-MADELEINE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-OUELLE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-PASPEBIAC",
      "state": "QC"
  },
  {
      "city": "RIVIERE-PENTECOTE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-ROUGE",
      "state": "QC"
  },
  {
      "city": "RIVIERE-SAINT-JEAN",
      "state": "QC"
  },
  {
      "city": "RIVIERE-SAINT-PAUL",
      "state": "QC"
  },
  {
      "city": "RIVIERE-TROIS-PISTOLES",
      "state": "QC"
  },
  {
      "city": "RIVIERE-VERTE",
      "state": "NB"
  },
  {
      "city": "ROACHVILLE",
      "state": "NB"
  },
  {
      "city": "ROBB",
      "state": "AB"
  },
  {
      "city": "ROBERTS ARM",
      "state": "NL"
  },
  {
      "city": "ROBERTS CREEK",
      "state": "BC"
  },
  {
      "city": "ROBERTVILLE",
      "state": "NB"
  },
  {
      "city": "ROBERVAL",
      "state": "QC"
  },
  {
      "city": "ROBICHAUD SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "ROBINSONS",
      "state": "NL"
  },
  {
      "city": "ROBINSONVILLE",
      "state": "NB"
  },
  {
      "city": "ROBLIN",
      "state": "MB"
  },
  {
      "city": "ROBLIN",
      "state": "ON"
  },
  {
      "city": "ROBSART",
      "state": "SK"
  },
  {
      "city": "ROBSON",
      "state": "BC"
  },
  {
      "city": "ROCANVILLE",
      "state": "SK"
  },
  {
      "city": "ROCHEBAUCOURT",
      "state": "QC"
  },
  {
      "city": "ROCHES POINT",
      "state": "ON"
  },
  {
      "city": "ROCHESTER",
      "state": "AB"
  },
  {
      "city": "ROCHEVILLE",
      "state": "NB"
  },
  {
      "city": "ROCHFORT BRIDGE",
      "state": "AB"
  },
  {
      "city": "ROCHON SANDS",
      "state": "AB"
  },
  {
      "city": "ROCK CREEK",
      "state": "BC"
  },
  {
      "city": "ROCK ELM",
      "state": "NS"
  },
  {
      "city": "ROCK RIDGE",
      "state": "MB"
  },
  {
      "city": "ROCKCLIFFE",
      "state": "ON"
  },
  {
      "city": "ROCKGLEN",
      "state": "SK"
  },
  {
      "city": "ROCKHAVEN",
      "state": "SK"
  },
  {
      "city": "ROCKLAND",
      "state": "NB"
  },
  {
      "city": "ROCKLAND",
      "state": "ON"
  },
  {
      "city": "ROCKPORT",
      "state": "NB"
  },
  {
      "city": "ROCKPORT",
      "state": "ON"
  },
  {
      "city": "ROCKTON",
      "state": "ON"
  },
  {
      "city": "ROCKWOOD",
      "state": "ON"
  },
  {
      "city": "ROCKY HARBOUR",
      "state": "NL"
  },
  {
      "city": "ROCKY MOUNTAIN HOUSE",
      "state": "AB"
  },
  {
      "city": "ROCKY RAPIDS",
      "state": "AB"
  },
  {
      "city": "ROCKY VIEW",
      "state": "AB"
  },
  {
      "city": "ROCKYFORD",
      "state": "AB"
  },
  {
      "city": "RODDICKTON",
      "state": "NL"
  },
  {
      "city": "RODGERS COVE",
      "state": "NL"
  },
  {
      "city": "RODNEY",
      "state": "ON"
  },
  {
      "city": "ROGERSVILLE",
      "state": "NB"
  },
  {
      "city": "ROGERSVILLE-EST",
      "state": "NB"
  },
  {
      "city": "ROGERSVILLE-OUEST",
      "state": "NB"
  },
  {
      "city": "ROKEBY",
      "state": "SK"
  },
  {
      "city": "ROLAND",
      "state": "MB"
  },
  {
      "city": "ROLLA",
      "state": "BC"
  },
  {
      "city": "ROLLET",
      "state": "QC"
  },
  {
      "city": "ROLLING HILLS",
      "state": "AB"
  },
  {
      "city": "ROLLINGDAM",
      "state": "NB"
  },
  {
      "city": "ROLLY VIEW",
      "state": "AB"
  },
  {
      "city": "ROLPHTON",
      "state": "ON"
  },
  {
      "city": "ROQUEMAURE",
      "state": "QC"
  },
  {
      "city": "RORKETON",
      "state": "MB"
  },
  {
      "city": "ROSA",
      "state": "MB"
  },
  {
      "city": "ROSAIREVILLE",
      "state": "NB"
  },
  {
      "city": "ROSALIND",
      "state": "AB"
  },
  {
      "city": "ROSE BAY",
      "state": "NS"
  },
  {
      "city": "ROSE BLANCHE",
      "state": "NL"
  },
  {
      "city": "ROSE PRAIRIE",
      "state": "BC"
  },
  {
      "city": "ROSE VALLEY",
      "state": "SK"
  },
  {
      "city": "ROSEAU RIVER",
      "state": "MB"
  },
  {
      "city": "ROSEBUD",
      "state": "AB"
  },
  {
      "city": "ROSEDALE STATION",
      "state": "AB"
  },
  {
      "city": "ROSEDALE VALLEY",
      "state": "AB"
  },
  {
      "city": "ROSEDALE",
      "state": "BC"
  },
  {
      "city": "ROSEDALE",
      "state": "NB"
  },
  {
      "city": "ROSEHILL",
      "state": "NB"
  },
  {
      "city": "ROSEISLE",
      "state": "MB"
  },
  {
      "city": "ROSEMARY",
      "state": "AB"
  },
  {
      "city": "ROSEMERE",
      "state": "QC"
  },
  {
      "city": "ROSEMONT",
      "state": "ON"
  },
  {
      "city": "ROSENEATH",
      "state": "ON"
  },
  {
      "city": "ROSENFELD",
      "state": "MB"
  },
  {
      "city": "ROSENORT",
      "state": "MB"
  },
  {
      "city": "ROSETOWN",
      "state": "SK"
  },
  {
      "city": "ROSEVALE",
      "state": "NB"
  },
  {
      "city": "ROSLIN",
      "state": "ON"
  },
  {
      "city": "ROSS FERRY",
      "state": "NS"
  },
  {
      "city": "ROSS RIVER",
      "state": "YT"
  },
  {
      "city": "ROSSBURN",
      "state": "MB"
  },
  {
      "city": "ROSSEAU",
      "state": "ON"
  },
  {
      "city": "ROSSENDALE",
      "state": "MB"
  },
  {
      "city": "ROSSER",
      "state": "MB"
  },
  {
      "city": "ROSSLAND",
      "state": "BC"
  },
  {
      "city": "ROSSPORT",
      "state": "ON"
  },
  {
      "city": "ROSSVILLE",
      "state": "NB"
  },
  {
      "city": "ROSTHERN",
      "state": "SK"
  },
  {
      "city": "ROSTOCK",
      "state": "ON"
  },
  {
      "city": "ROTHESAY",
      "state": "NB"
  },
  {
      "city": "ROUGEMONT",
      "state": "QC"
  },
  {
      "city": "ROUGH WATERS",
      "state": "NB"
  },
  {
      "city": "ROULEAU",
      "state": "SK"
  },
  {
      "city": "ROUND HARBOUR GB",
      "state": "NL"
  },
  {
      "city": "ROUND HILL",
      "state": "AB"
  },
  {
      "city": "ROUND ISLAND",
      "state": "NS"
  },
  {
      "city": "ROUND LAKE CENTRE",
      "state": "ON"
  },
  {
      "city": "ROUTHIERVILLE",
      "state": "QC"
  },
  {
      "city": "ROUYN-NORANDA",
      "state": "QC"
  },
  {
      "city": "ROWENA",
      "state": "NB"
  },
  {
      "city": "ROWLEY",
      "state": "AB"
  },
  {
      "city": "ROWLEY",
      "state": "NB"
  },
  {
      "city": "ROXBORO",
      "state": "QC"
  },
  {
      "city": "ROXTON FALLS",
      "state": "QC"
  },
  {
      "city": "ROXTON POND",
      "state": "QC"
  },
  {
      "city": "ROYAL ROAD",
      "state": "NB"
  },
  {
      "city": "ROYALTON",
      "state": "NB"
  },
  {
      "city": "ROYSTON",
      "state": "BC"
  },
  {
      "city": "RUDDELL",
      "state": "SK"
  },
  {
      "city": "RUISSEAU-A-REBOURS",
      "state": "QC"
  },
  {
      "city": "RUMSEY",
      "state": "AB"
  },
  {
      "city": "RUNNYMEDE",
      "state": "SK"
  },
  {
      "city": "RUSAGONIS",
      "state": "NB"
  },
  {
      "city": "RUSCOM STATION",
      "state": "ON"
  },
  {
      "city": "RUSH LAKE",
      "state": "SK"
  },
  {
      "city": "RUSHOON",
      "state": "NL"
  },
  {
      "city": "RUSSELL",
      "state": "MB"
  },
  {
      "city": "RUSSELL",
      "state": "ON"
  },
  {
      "city": "RUSSELLVILLE",
      "state": "NB"
  },
  {
      "city": "RUTHERGLEN",
      "state": "ON"
  },
  {
      "city": "RUTHILDA",
      "state": "SK"
  },
  {
      "city": "RUTHVEN",
      "state": "ON"
  },
  {
      "city": "RYCROFT",
      "state": "AB"
  },
  {
      "city": "RYLEY",
      "state": "AB"
  },
  {
      "city": "SAANICH",
      "state": "BC"
  },
  {
      "city": "SAANICHTON",
      "state": "BC"
  },
  {
      "city": "SABLE RIVER",
      "state": "NS"
  },
  {
      "city": "SABREVOIS",
      "state": "QC"
  },
  {
      "city": "SACHIGO LAKE",
      "state": "ON"
  },
  {
      "city": "SACHS HARBOUR",
      "state": "NT"
  },
  {
      "city": "SACKVILLE ROAD",
      "state": "NB"
  },
  {
      "city": "SACKVILLE",
      "state": "NB"
  },
  {
      "city": "SACRE-COEUR-SAGUENAY",
      "state": "QC"
  },
  {
      "city": "SADDLE LAKE",
      "state": "AB"
  },
  {
      "city": "SAINT JOHN",
      "state": "NB"
  },
  {
      "city": "SAINT PONS",
      "state": "NB"
  },
  {
      "city": "SAINT-ADALBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-ADELME",
      "state": "QC"
  },
  {
      "city": "SAINT-ADELPHE-DE-CHAMPLAIN",
      "state": "QC"
  },
  {
      "city": "SAINT-ADOLPHE-D'HOWARD",
      "state": "QC"
  },
  {
      "city": "SAINT-ADRIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-ADRIEN-D'IRLANDE",
      "state": "QC"
  },
  {
      "city": "SAINT-AGAPIT",
      "state": "QC"
  },
  {
      "city": "SAINT-AIME",
      "state": "QC"
  },
  {
      "city": "SAINT-AIME-DES-LACS",
      "state": "QC"
  },
  {
      "city": "SAINT-ALBAN",
      "state": "QC"
  },
  {
      "city": "SAINT-ALBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-ALEXANDRE-D'IBERVILLE",
      "state": "QC"
  },
  {
      "city": "SAINT-ALEXANDRE-DE-KAMOURASKA",
      "state": "QC"
  },
  {
      "city": "SAINT-ALEXANDRE-DES-LACS",
      "state": "QC"
  },
  {
      "city": "SAINT-ALEXIS-DE-MATAPEDIA",
      "state": "QC"
  },
  {
      "city": "SAINT-ALEXIS-DE-MONTCALM",
      "state": "QC"
  },
  {
      "city": "SAINT-ALEXIS-DES-MONTS",
      "state": "QC"
  },
  {
      "city": "SAINT-ALFRED",
      "state": "QC"
  },
  {
      "city": "SAINT-ALPHONSE-DE-CAPLAN",
      "state": "QC"
  },
  {
      "city": "SAINT-ALPHONSE-DE-GRANBY",
      "state": "QC"
  },
  {
      "city": "SAINT-ALPHONSE-RODRIGUEZ",
      "state": "QC"
  },
  {
      "city": "SAINT-AMABLE",
      "state": "QC"
  },
  {
      "city": "SAINT-AMATEUR",
      "state": "NB"
  },
  {
      "city": "SAINT-AMBROISE",
      "state": "QC"
  },
  {
      "city": "SAINT-AMBROISE-DE-KILDARE",
      "state": "QC"
  },
  {
      "city": "SAINT-ANACLET",
      "state": "QC"
  },
  {
      "city": "SAINT-ANDRE",
      "state": "NB"
  },
  {
      "city": "SAINT-ANDRE-AVELLIN",
      "state": "QC"
  },
  {
      "city": "SAINT-ANDRE-D'ARGENTEUIL",
      "state": "QC"
  },
  {
      "city": "SAINT-ANDRE-DE-KAMOURASKA",
      "state": "QC"
  },
  {
      "city": "SAINT-ANDRE-DE-RESTIGOUCHE",
      "state": "QC"
  },
  {
      "city": "SAINT-ANDRE-DU-LAC-SAINT-JEAN",
      "state": "QC"
  },
  {
      "city": "SAINT-ANDRE-LEBLANC",
      "state": "NB"
  },
  {
      "city": "SAINT-ANICET",
      "state": "QC"
  },
  {
      "city": "SAINT-ANSELME",
      "state": "QC"
  },
  {
      "city": "SAINT-ANTOINE SUD",
      "state": "NB"
  },
  {
      "city": "SAINT-ANTOINE",
      "state": "NB"
  },
  {
      "city": "SAINT-ANTOINE-ABBE",
      "state": "QC"
  },
  {
      "city": "SAINT-ANTOINE-DE-KENT",
      "state": "NB"
  },
  {
      "city": "SAINT-ANTOINE-DE-TILLY",
      "state": "QC"
  },
  {
      "city": "SAINT-ANTOINE-SUR-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-ANTONIN",
      "state": "QC"
  },
  {
      "city": "SAINT-APOLLINAIRE",
      "state": "QC"
  },
  {
      "city": "SAINT-ARMAND",
      "state": "QC"
  },
  {
      "city": "SAINT-ARSENE",
      "state": "QC"
  },
  {
      "city": "SAINT-ARTHUR",
      "state": "NB"
  },
  {
      "city": "SAINT-AUBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-AUGUSTIN-DE-DESMAURES",
      "state": "QC"
  },
  {
      "city": "SAINT-AUGUSTIN-SAGUENAY",
      "state": "QC"
  },
  {
      "city": "SAINT-BARNABE-SUD",
      "state": "QC"
  },
  {
      "city": "SAINT-BARTHELEMY",
      "state": "QC"
  },
  {
      "city": "SAINT-BASILE",
      "state": "NB"
  },
  {
      "city": "SAINT-BASILE",
      "state": "QC"
  },
  {
      "city": "SAINT-BASILE-LE-GRAND",
      "state": "QC"
  },
  {
      "city": "SAINT-BENJAMIN",
      "state": "QC"
  },
  {
      "city": "SAINT-BENOIT-DU-LAC",
      "state": "QC"
  },
  {
      "city": "SAINT-BENOIT-LABRE",
      "state": "QC"
  },
  {
      "city": "SAINT-BERNARD",
      "state": "QC"
  },
  {
      "city": "SAINT-BERNARD-DE-LACOLLE",
      "state": "QC"
  },
  {
      "city": "SAINT-BERNARD-DE-MICHAUDVILLE",
      "state": "QC"
  },
  {
      "city": "SAINT-BERNARD-SUR-MER",
      "state": "QC"
  },
  {
      "city": "SAINT-BLAISE-SUR-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-BONAVENTURE",
      "state": "QC"
  },
  {
      "city": "SAINT-BONIFACE-DE-SHAWINIGAN",
      "state": "QC"
  },
  {
      "city": "SAINT-BRUNO",
      "state": "QC"
  },
  {
      "city": "SAINT-BRUNO-DE-GUIGUES",
      "state": "QC"
  },
  {
      "city": "SAINT-BRUNO-DE-KAMOURASKA",
      "state": "QC"
  },
  {
      "city": "SAINT-BRUNO-LAC-SAINT-JEAN",
      "state": "QC"
  },
  {
      "city": "SAINT-CALIXTE",
      "state": "QC"
  },
  {
      "city": "SAINT-CAMILLE",
      "state": "QC"
  },
  {
      "city": "SAINT-CAMILLE-DE-BELLECHASSE",
      "state": "QC"
  },
  {
      "city": "SAINT-CASIMIR",
      "state": "QC"
  },
  {
      "city": "SAINT-CELESTIN",
      "state": "QC"
  },
  {
      "city": "SAINT-CESAIRE",
      "state": "QC"
  },
  {
      "city": "SAINT-CHARLES",
      "state": "NB"
  },
  {
      "city": "SAINT-CHARLES-BORROMEE",
      "state": "QC"
  },
  {
      "city": "SAINT-CHARLES-DE-BELLECHASSE",
      "state": "QC"
  },
  {
      "city": "SAINT-CHARLES-DE-BOURGET",
      "state": "QC"
  },
  {
      "city": "SAINT-CHARLES-DE-DRUMMOND",
      "state": "QC"
  },
  {
      "city": "SAINT-CHARLES-GARNIER",
      "state": "QC"
  },
  {
      "city": "SAINT-CHARLES-SUR-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-CHRISTOPHE-D'ARTHABASKA",
      "state": "QC"
  },
  {
      "city": "SAINT-CHRYSOSTOME",
      "state": "QC"
  },
  {
      "city": "SAINT-CLAUDE",
      "state": "QC"
  },
  {
      "city": "SAINT-CLEMENT",
      "state": "QC"
  },
  {
      "city": "SAINT-CLEOPHAS",
      "state": "QC"
  },
  {
      "city": "SAINT-CLEOPHAS-DE-BRANDON",
      "state": "QC"
  },
  {
      "city": "SAINT-CLET",
      "state": "QC"
  },
  {
      "city": "SAINT-COLOMBAN",
      "state": "QC"
  },
  {
      "city": "SAINT-COME",
      "state": "QC"
  },
  {
      "city": "SAINT-COME-LINIERE",
      "state": "QC"
  },
  {
      "city": "SAINT-CONSTANT",
      "state": "QC"
  },
  {
      "city": "SAINT-CUTHBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-CYPRIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-CYPRIEN-DES-ETCHEMINS",
      "state": "QC"
  },
  {
      "city": "SAINT-CYRILLE-DE-L'ISLET",
      "state": "QC"
  },
  {
      "city": "SAINT-CYRILLE-DE-WENDOVER",
      "state": "QC"
  },
  {
      "city": "SAINT-DAMASE",
      "state": "QC"
  },
  {
      "city": "SAINT-DAMASE-DE-MATAPEDIA",
      "state": "QC"
  },
  {
      "city": "SAINT-DAMASE-DES-AULNAIES",
      "state": "QC"
  },
  {
      "city": "SAINT-DAMIEN",
      "state": "NB"
  },
  {
      "city": "SAINT-DAMIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-DAMIEN-DE-BUCKLAND",
      "state": "QC"
  },
  {
      "city": "SAINT-DAVID",
      "state": "QC"
  },
  {
      "city": "SAINT-DENIS-DE-BROMPTON",
      "state": "QC"
  },
  {
      "city": "SAINT-DENIS-DE-LA-BOUTEILLERIE",
      "state": "QC"
  },
  {
      "city": "SAINT-DENIS-SUR-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-DIDACE",
      "state": "QC"
  },
  {
      "city": "SAINT-DOMINIQUE",
      "state": "QC"
  },
  {
      "city": "SAINT-DOMINIQUE-DU-ROSAIRE",
      "state": "QC"
  },
  {
      "city": "SAINT-DONAT",
      "state": "QC"
  },
  {
      "city": "SAINT-DONAT-DE-MONTCALM",
      "state": "QC"
  },
  {
      "city": "SAINT-EDMOND-DE-GRANTHAM",
      "state": "QC"
  },
  {
      "city": "SAINT-EDMOND-LES-PLAINES",
      "state": "QC"
  },
  {
      "city": "SAINT-EDOUARD-DE-KENT",
      "state": "NB"
  },
  {
      "city": "SAINT-EDOUARD-DE-LOTBINIERE",
      "state": "QC"
  },
  {
      "city": "SAINT-EDOUARD-DE-MASKINONGE",
      "state": "QC"
  },
  {
      "city": "SAINT-EDOUARD-DE-NAPIERVILLE",
      "state": "QC"
  },
  {
      "city": "SAINT-ELIE-DE-CAXTON",
      "state": "QC"
  },
  {
      "city": "SAINT-ELOI",
      "state": "QC"
  },
  {
      "city": "SAINT-ELPHEGE",
      "state": "QC"
  },
  {
      "city": "SAINT-ELZEAR",
      "state": "QC"
  },
  {
      "city": "SAINT-ELZEAR-DE-BONAVENTURE",
      "state": "QC"
  },
  {
      "city": "SAINT-ELZEAR-DE-TEMISCOUATA",
      "state": "QC"
  },
  {
      "city": "SAINT-EMILE-DE-SUFFOLK",
      "state": "QC"
  },
  {
      "city": "SAINT-EPHREM-DE-BEAUCE",
      "state": "QC"
  },
  {
      "city": "SAINT-EPIPHANE",
      "state": "QC"
  },
  {
      "city": "SAINT-ESPRIT",
      "state": "QC"
  },
  {
      "city": "SAINT-ETIENNE-DE-BEAUHARNOIS",
      "state": "QC"
  },
  {
      "city": "SAINT-ETIENNE-DE-BOLTON",
      "state": "QC"
  },
  {
      "city": "SAINT-ETIENNE-DE-LAUZON",
      "state": "QC"
  },
  {
      "city": "SAINT-ETIENNE-DES-GRES",
      "state": "QC"
  },
  {
      "city": "SAINT-EUGENE-D'ARGENTENAY",
      "state": "QC"
  },
  {
      "city": "SAINT-EUGENE-DE-GRANTHAM",
      "state": "QC"
  },
  {
      "city": "SAINT-EUGENE-DE-GUIGUES",
      "state": "QC"
  },
  {
      "city": "SAINT-EUSEBE",
      "state": "QC"
  },
  {
      "city": "SAINT-EUSTACHE",
      "state": "QC"
  },
  {
      "city": "SAINT-EVARISTE-DE-FORSYTH",
      "state": "QC"
  },
  {
      "city": "SAINT-FABIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-FABIEN-DE-PANET",
      "state": "QC"
  },
  {
      "city": "SAINT-FAUSTIN-LAC-CARRE",
      "state": "QC"
  },
  {
      "city": "SAINT-FELICIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-FELIX-D'OTIS",
      "state": "QC"
  },
  {
      "city": "SAINT-FELIX-DE-DALQUIER",
      "state": "QC"
  },
  {
      "city": "SAINT-FELIX-DE-KINGSEY",
      "state": "QC"
  },
  {
      "city": "SAINT-FELIX-DE-VALOIS",
      "state": "QC"
  },
  {
      "city": "SAINT-FERDINAND",
      "state": "QC"
  },
  {
      "city": "SAINT-FERREOL-LES-NEIGES",
      "state": "QC"
  },
  {
      "city": "SAINT-FLAVIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-FORTUNAT",
      "state": "QC"
  },
  {
      "city": "SAINT-FRANCOIS-D'ASSISE",
      "state": "QC"
  },
  {
      "city": "SAINT-FRANCOIS-D'ORLEANS",
      "state": "QC"
  },
  {
      "city": "SAINT-FRANCOIS-DE-MADAWASKA",
      "state": "NB"
  },
  {
      "city": "SAINT-FRANCOIS-DE-SALES",
      "state": "QC"
  },
  {
      "city": "SAINT-FRANCOIS-DU-LAC",
      "state": "QC"
  },
  {
      "city": "SAINT-FRANCOIS-XAVIER-DE-VIGER",
      "state": "QC"
  },
  {
      "city": "SAINT-FREDERIC",
      "state": "QC"
  },
  {
      "city": "SAINT-FULGENCE",
      "state": "QC"
  },
  {
      "city": "SAINT-GABRIEL-DE-BRANDON",
      "state": "QC"
  },
  {
      "city": "SAINT-GABRIEL-DE-RIMOUSKI",
      "state": "QC"
  },
  {
      "city": "SAINT-GABRIEL-DE-VALCARTIER",
      "state": "QC"
  },
  {
      "city": "SAINT-GEDEON",
      "state": "QC"
  },
  {
      "city": "SAINT-GEDEON-DE-BEAUCE",
      "state": "QC"
  },
  {
      "city": "SAINT-GEORGES",
      "state": "QC"
  },
  {
      "city": "SAINT-GEORGES-DE-CHAMPLAIN",
      "state": "QC"
  },
  {
      "city": "SAINT-GEORGES-DE-MALBAIE",
      "state": "QC"
  },
  {
      "city": "SAINT-GEORGES-DE-WINDSOR",
      "state": "QC"
  },
  {
      "city": "SAINT-GERARD-DES-LAURENTIDES",
      "state": "QC"
  },
  {
      "city": "SAINT-GERMAIN",
      "state": "QC"
  },
  {
      "city": "SAINT-GERMAIN-DE-GRANTHAM",
      "state": "QC"
  },
  {
      "city": "SAINT-GERVAIS",
      "state": "QC"
  },
  {
      "city": "SAINT-GILBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-GILLES",
      "state": "QC"
  },
  {
      "city": "SAINT-GODEFROI",
      "state": "QC"
  },
  {
      "city": "SAINT-GREGOIRE",
      "state": "NB"
  },
  {
      "city": "SAINT-GUILLAUME",
      "state": "QC"
  },
  {
      "city": "SAINT-GUY",
      "state": "QC"
  },
  {
      "city": "SAINT-HENRI-DE-LEVIS",
      "state": "QC"
  },
  {
      "city": "SAINT-HERMENEGILDE",
      "state": "QC"
  },
  {
      "city": "SAINT-HILARION",
      "state": "QC"
  },
  {
      "city": "SAINT-HIPPOLYTE",
      "state": "QC"
  },
  {
      "city": "SAINT-HONORE-DE-CHICOUTIMI",
      "state": "QC"
  },
  {
      "city": "SAINT-HONORE-DE-SHENLEY",
      "state": "QC"
  },
  {
      "city": "SAINT-HONORE-DE-TEMISCOUATA",
      "state": "QC"
  },
  {
      "city": "SAINT-HUBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-HUBERT-RIVIERE-DU-LOUP",
      "state": "QC"
  },
  {
      "city": "SAINT-HUGUES",
      "state": "QC"
  },
  {
      "city": "SAINT-HYACINTHE",
      "state": "QC"
  },
  {
      "city": "SAINT-IGNACE",
      "state": "NB"
  },
  {
      "city": "SAINT-IGNACE-DE-LOYOLA",
      "state": "QC"
  },
  {
      "city": "SAINT-IGNACE-DE-STANBRIDGE",
      "state": "QC"
  },
  {
      "city": "SAINT-IRENEE",
      "state": "NB"
  },
  {
      "city": "SAINT-IRENEE",
      "state": "QC"
  },
  {
      "city": "SAINT-ISIDORE",
      "state": "NB"
  },
  {
      "city": "SAINT-ISIDORE",
      "state": "QC"
  },
  {
      "city": "SAINT-ISIDORE-DE-CLIFTON",
      "state": "QC"
  },
  {
      "city": "SAINT-ISIDORE-DE-LAPRAIRIE",
      "state": "QC"
  },
  {
      "city": "SAINT-JACQUES",
      "state": "NB"
  },
  {
      "city": "SAINT-JACQUES",
      "state": "QC"
  },
  {
      "city": "SAINT-JACQUES-DE-LEEDS",
      "state": "QC"
  },
  {
      "city": "SAINT-JACQUES-LE-MINEUR",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-BAPTISTE",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-CHRYSOSTOME",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-D'ORLEANS",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-DE-BREBEUF",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-DE-CHERBOURG",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-DE-DIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-DE-LA-LANDE",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-DE-MATAPEDIA",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-DE-MATHA",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-DES-PILES",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-PORT-JOLI",
      "state": "QC"
  },
  {
      "city": "SAINT-JEAN-SUR-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-JEROME",
      "state": "QC"
  },
  {
      "city": "SAINT-JOACHIM-DE-COURVAL",
      "state": "QC"
  },
  {
      "city": "SAINT-JOACHIM-DE-SHEFFORD",
      "state": "QC"
  },
  {
      "city": "SAINT-JOGUES",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DE-BEAUCE",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DE-COLERAINE",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DE-HAM-SUD",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DE-KAMOURASKA",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DE-KENT",
      "state": "NB"
  },
  {
      "city": "SAINT-JOSEPH-DE-LA-RIVE",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DE-LEPAGE",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DE-SOREL",
      "state": "QC"
  },
  {
      "city": "SAINT-JOSEPH-DU-LAC",
      "state": "QC"
  },
  {
      "city": "SAINT-JUDE",
      "state": "QC"
  },
  {
      "city": "SAINT-JULES",
      "state": "QC"
  },
  {
      "city": "SAINT-JULIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-JUST-DE-BRETENIERES",
      "state": "QC"
  },
  {
      "city": "SAINT-JUSTE-DU-LAC",
      "state": "QC"
  },
  {
      "city": "SAINT-JUSTIN",
      "state": "QC"
  },
  {
      "city": "SAINT-LAMBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-LAMBERT-DE-LAUZON",
      "state": "QC"
  },
  {
      "city": "SAINT-LAURENT NORD",
      "state": "NB"
  },
  {
      "city": "SAINT-LAURENT",
      "state": "NB"
  },
  {
      "city": "SAINT-LAURENT",
      "state": "QC"
  },
  {
      "city": "SAINT-LAURENT-ILE-D'ORLEANS",
      "state": "QC"
  },
  {
      "city": "SAINT-LAZARE",
      "state": "QC"
  },
  {
      "city": "SAINT-LAZARE-DE-BELLECHASSE",
      "state": "QC"
  },
  {
      "city": "SAINT-LEANDRE",
      "state": "QC"
  },
  {
      "city": "SAINT-LEOLIN",
      "state": "NB"
  },
  {
      "city": "SAINT-LEON",
      "state": "QC"
  },
  {
      "city": "SAINT-LEON-LE-GRAND",
      "state": "QC"
  },
  {
      "city": "SAINT-LEONARD",
      "state": "NB"
  },
  {
      "city": "SAINT-LEONARD",
      "state": "QC"
  },
  {
      "city": "SAINT-LEONARD-D'ASTON",
      "state": "QC"
  },
  {
      "city": "SAINT-LEONARD-DE-PORTNEUF",
      "state": "QC"
  },
  {
      "city": "SAINT-LEONARD-PARENT",
      "state": "NB"
  },
  {
      "city": "SAINT-LIBOIRE",
      "state": "QC"
  },
  {
      "city": "SAINT-LIGUORI",
      "state": "QC"
  },
  {
      "city": "SAINT-LIN-LAURENTIDES",
      "state": "QC"
  },
  {
      "city": "SAINT-LOUIS",
      "state": "NB"
  },
  {
      "city": "SAINT-LOUIS",
      "state": "QC"
  },
  {
      "city": "SAINT-LOUIS-DE-BLANDFORD",
      "state": "QC"
  },
  {
      "city": "SAINT-LOUIS-DE-GONZAGUE",
      "state": "QC"
  },
  {
      "city": "SAINT-LOUIS-DE-KENT",
      "state": "NB"
  },
  {
      "city": "SAINT-LOUIS-DU-HA-HA",
      "state": "QC"
  },
  {
      "city": "SAINT-LUC-DE-BELLECHASSE",
      "state": "QC"
  },
  {
      "city": "SAINT-LUC-DE-VINCENNES",
      "state": "QC"
  },
  {
      "city": "SAINT-LUCIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-LUDGER",
      "state": "QC"
  },
  {
      "city": "SAINT-LUDGER-DE-MILOT",
      "state": "QC"
  },
  {
      "city": "SAINT-MAGLOIRE",
      "state": "QC"
  },
  {
      "city": "SAINT-MAJORIQUE",
      "state": "QC"
  },
  {
      "city": "SAINT-MALACHIE",
      "state": "QC"
  },
  {
      "city": "SAINT-MALO",
      "state": "QC"
  },
  {
      "city": "SAINT-MARC-DE-FIGUERY",
      "state": "QC"
  },
  {
      "city": "SAINT-MARC-DES-CARRIERES",
      "state": "QC"
  },
  {
      "city": "SAINT-MARC-DU-LAC-LONG",
      "state": "QC"
  },
  {
      "city": "SAINT-MARC-SUR-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-MARCEL-DE-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-MARCELLIN",
      "state": "QC"
  },
  {
      "city": "SAINT-MARTIN",
      "state": "QC"
  },
  {
      "city": "SAINT-MARTIN-DE-RESTIGOUCHE",
      "state": "NB"
  },
  {
      "city": "SAINT-MATHIAS-SUR-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-MATHIEU-D'HARRICANA",
      "state": "QC"
  },
  {
      "city": "SAINT-MATHIEU-DE-BELOEIL",
      "state": "QC"
  },
  {
      "city": "SAINT-MATHIEU-DE-LAPRAIRIE",
      "state": "QC"
  },
  {
      "city": "SAINT-MATHIEU-DE-RIOUX",
      "state": "QC"
  },
  {
      "city": "SAINT-MATHIEU-DU-PARC",
      "state": "QC"
  },
  {
      "city": "SAINT-MAURE",
      "state": "NB"
  },
  {
      "city": "SAINT-MAURICE",
      "state": "NB"
  },
  {
      "city": "SAINT-MAURICE",
      "state": "QC"
  },
  {
      "city": "SAINT-MEDARD",
      "state": "QC"
  },
  {
      "city": "SAINT-MICHEL",
      "state": "QC"
  },
  {
      "city": "SAINT-MICHEL-DE-BELLECHASSE",
      "state": "QC"
  },
  {
      "city": "SAINT-MICHEL-DES-SAINTS",
      "state": "QC"
  },
  {
      "city": "SAINT-MODESTE",
      "state": "QC"
  },
  {
      "city": "SAINT-MOISE",
      "state": "QC"
  },
  {
      "city": "SAINT-NARCISSE",
      "state": "QC"
  },
  {
      "city": "SAINT-NARCISSE-DE-BEAURIVAGE",
      "state": "QC"
  },
  {
      "city": "SAINT-NARCISSE-DE-RIMOUSKI",
      "state": "QC"
  },
  {
      "city": "SAINT-NAZAIRE-D'ACTON",
      "state": "QC"
  },
  {
      "city": "SAINT-NAZAIRE-DE-DORCHESTER",
      "state": "QC"
  },
  {
      "city": "SAINT-NEREE",
      "state": "QC"
  },
  {
      "city": "SAINT-NICEPHORE",
      "state": "QC"
  },
  {
      "city": "SAINT-NICOLAS",
      "state": "QC"
  },
  {
      "city": "SAINT-NOEL",
      "state": "QC"
  },
  {
      "city": "SAINT-NORBERT",
      "state": "NB"
  },
  {
      "city": "SAINT-NORBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-NORBERT-D'ARTHABASKA",
      "state": "QC"
  },
  {
      "city": "SAINT-ODILON",
      "state": "QC"
  },
  {
      "city": "SAINT-OMER",
      "state": "QC"
  },
  {
      "city": "SAINT-OMER-L'ISLET",
      "state": "QC"
  },
  {
      "city": "SAINT-OURS",
      "state": "QC"
  },
  {
      "city": "SAINT-PACOME",
      "state": "QC"
  },
  {
      "city": "SAINT-PAMPHILE",
      "state": "QC"
  },
  {
      "city": "SAINT-PASCAL",
      "state": "QC"
  },
  {
      "city": "SAINT-PASCAL-BAYLON",
      "state": "ON"
  },
  {
      "city": "SAINT-PATRICE-DE-BEAURIVAGE",
      "state": "QC"
  },
  {
      "city": "SAINT-PAUL",
      "state": "NB"
  },
  {
      "city": "SAINT-PAUL",
      "state": "QC"
  },
  {
      "city": "SAINT-PAUL-D'ABBOTSFORD",
      "state": "QC"
  },
  {
      "city": "SAINT-PAUL-DE-LA-CROIX",
      "state": "QC"
  },
  {
      "city": "SAINT-PAUL-DE-MONTMINY",
      "state": "QC"
  },
  {
      "city": "SAINT-PAULIN",
      "state": "QC"
  },
  {
      "city": "SAINT-PHILEMON",
      "state": "QC"
  },
  {
      "city": "SAINT-PHILIBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-PHILIPPE",
      "state": "NB"
  },
  {
      "city": "SAINT-PHILIPPE",
      "state": "QC"
  },
  {
      "city": "SAINT-PHILIPPE-DE-NERI",
      "state": "QC"
  },
  {
      "city": "SAINT-PIE",
      "state": "QC"
  },
  {
      "city": "SAINT-PIE-DE-GUIRE",
      "state": "QC"
  },
  {
      "city": "SAINT-PIERRE-BAPTISTE",
      "state": "QC"
  },
  {
      "city": "SAINT-PIERRE-DE-BROUGHTON",
      "state": "QC"
  },
  {
      "city": "SAINT-PIERRE-DE-LAMY",
      "state": "QC"
  },
  {
      "city": "SAINT-PIERRE-ILE-D'ORLEANS",
      "state": "QC"
  },
  {
      "city": "SAINT-PIERRE-LES-BECQUETS",
      "state": "QC"
  },
  {
      "city": "SAINT-PLACIDE",
      "state": "QC"
  },
  {
      "city": "SAINT-POLYCARPE",
      "state": "QC"
  },
  {
      "city": "SAINT-PRIME",
      "state": "QC"
  },
  {
      "city": "SAINT-PROSPER",
      "state": "QC"
  },
  {
      "city": "SAINT-PROSPER-DE-DORCHESTER",
      "state": "QC"
  },
  {
      "city": "SAINT-QUENTIN",
      "state": "NB"
  },
  {
      "city": "SAINT-RAPHAEL",
      "state": "QC"
  },
  {
      "city": "SAINT-RAYMOND",
      "state": "QC"
  },
  {
      "city": "SAINT-REDEMPTEUR",
      "state": "QC"
  },
  {
      "city": "SAINT-REMI",
      "state": "QC"
  },
  {
      "city": "SAINT-REMI-D'AMHERST",
      "state": "QC"
  },
  {
      "city": "SAINT-REMI-DE-TINGWICK",
      "state": "QC"
  },
  {
      "city": "SAINT-RENE",
      "state": "QC"
  },
  {
      "city": "SAINT-RENE-DE-MATANE",
      "state": "QC"
  },
  {
      "city": "SAINT-ROBERT",
      "state": "QC"
  },
  {
      "city": "SAINT-ROBERT-BELLARMIN",
      "state": "QC"
  },
  {
      "city": "SAINT-ROCH-DE-L'ACHIGAN",
      "state": "QC"
  },
  {
      "city": "SAINT-ROCH-DE-MEKINAC",
      "state": "QC"
  },
  {
      "city": "SAINT-ROCH-DE-RICHELIEU",
      "state": "QC"
  },
  {
      "city": "SAINT-ROCH-DES-AULNAIES",
      "state": "QC"
  },
  {
      "city": "SAINT-ROCH-OUEST",
      "state": "QC"
  },
  {
      "city": "SAINT-ROMAIN",
      "state": "QC"
  },
  {
      "city": "SAINT-ROMUALD",
      "state": "QC"
  },
  {
      "city": "SAINT-ROSAIRE",
      "state": "QC"
  },
  {
      "city": "SAINT-SAMUEL",
      "state": "QC"
  },
  {
      "city": "SAINT-SAUVEUR",
      "state": "NB"
  },
  {
      "city": "SAINT-SAUVEUR",
      "state": "QC"
  },
  {
      "city": "SAINT-SEBASTIEN",
      "state": "QC"
  },
  {
      "city": "SAINT-SEBASTIEN-DE-FRONTENAC",
      "state": "QC"
  },
  {
      "city": "SAINT-SEVERE",
      "state": "QC"
  },
  {
      "city": "SAINT-SEVERIN-DE-BEAUCE",
      "state": "QC"
  },
  {
      "city": "SAINT-SIMEON",
      "state": "QC"
  },
  {
      "city": "SAINT-SIMEON-DE-BONAVENTURE",
      "state": "QC"
  },
  {
      "city": "SAINT-SIMON",
      "state": "NB"
  },
  {
      "city": "SAINT-SIMON-DE-BAGOT",
      "state": "QC"
  },
  {
      "city": "SAINT-SIMON-DE-RIMOUSKI",
      "state": "QC"
  },
  {
      "city": "SAINT-SIMON-LES-MINES",
      "state": "QC"
  },
  {
      "city": "SAINT-STANISLAS",
      "state": "QC"
  },
  {
      "city": "SAINT-STANISLAS-DE-CHAMPLAIN",
      "state": "QC"
  },
  {
      "city": "SAINT-STANISLAS-DE-KOSTKA",
      "state": "QC"
  },
  {
      "city": "SAINT-SULPICE",
      "state": "QC"
  },
  {
      "city": "SAINT-SYLVERE",
      "state": "QC"
  },
  {
      "city": "SAINT-SYLVESTRE",
      "state": "QC"
  },
  {
      "city": "SAINT-TELESPHORE",
      "state": "QC"
  },
  {
      "city": "SAINT-THARCISIUS",
      "state": "QC"
  },
  {
      "city": "SAINT-THEODORE-D'ACTON",
      "state": "QC"
  },
  {
      "city": "SAINT-THEOPHILE",
      "state": "QC"
  },
  {
      "city": "SAINT-THOMAS",
      "state": "QC"
  },
  {
      "city": "SAINT-THOMAS-DE-KENT",
      "state": "NB"
  },
  {
      "city": "SAINT-THOMAS-DIDYME",
      "state": "QC"
  },
  {
      "city": "SAINT-THURIBE",
      "state": "QC"
  },
  {
      "city": "SAINT-TITE",
      "state": "QC"
  },
  {
      "city": "SAINT-TITE-DES-CAPS",
      "state": "QC"
  },
  {
      "city": "SAINT-UBALDE",
      "state": "QC"
  },
  {
      "city": "SAINT-ULRIC",
      "state": "QC"
  },
  {
      "city": "SAINT-URBAIN-DE-CHARLEVOIX",
      "state": "QC"
  },
  {
      "city": "SAINT-URBAIN-PREMIER",
      "state": "QC"
  },
  {
      "city": "SAINT-VALENTIN",
      "state": "QC"
  },
  {
      "city": "SAINT-VALERE",
      "state": "QC"
  },
  {
      "city": "SAINT-VALERIEN-DE-RIMOUSKI",
      "state": "QC"
  },
  {
      "city": "SAINT-VALLIER",
      "state": "QC"
  },
  {
      "city": "SAINT-VIANNEY",
      "state": "QC"
  },
  {
      "city": "SAINT-VICTOR",
      "state": "QC"
  },
  {
      "city": "SAINT-VITAL-DE-CLERMONT",
      "state": "QC"
  },
  {
      "city": "SAINT-WENCESLAS",
      "state": "QC"
  },
  {
      "city": "SAINT-WILFRED",
      "state": "NB"
  },
  {
      "city": "SAINT-ZACHARIE",
      "state": "QC"
  },
  {
      "city": "SAINT-ZENON",
      "state": "QC"
  },
  {
      "city": "SAINT-ZEPHIRIN-DE-COURVAL",
      "state": "QC"
  },
  {
      "city": "SAINT-ZOTIQUE",
      "state": "QC"
  },
  {
      "city": "SAINTE ROSE",
      "state": "NB"
  },
  {
      "city": "SAINTE-ADELE",
      "state": "QC"
  },
  {
      "city": "SAINTE-AGATHE-DE-LOTBINIERE",
      "state": "QC"
  },
  {
      "city": "SAINTE-AGATHE-DES-MONTS",
      "state": "QC"
  },
  {
      "city": "SAINTE-AGATHE-NORD",
      "state": "QC"
  },
  {
      "city": "SAINTE-AGNES-DE-DUNDEE",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANGELE-DE-MERICI",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANGELE-DE-MONNOIR",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANGELE-DE-PREMONT",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE GLOUCESTER CO",
      "state": "NB"
  },
  {
      "city": "SAINTE-ANNE-DE-BEAUPRE",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DE-BELLEVUE",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DE-KENT",
      "state": "NB"
  },
  {
      "city": "SAINTE-ANNE-DE-LA-PERADE",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DE-LA-ROCHELLE",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DE-MADAWASKA",
      "state": "NB"
  },
  {
      "city": "SAINTE-ANNE-DE-SOREL",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DES-LACS",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DES-MONTS",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DES-PLAINES",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DU-LAC",
      "state": "QC"
  },
  {
      "city": "SAINTE-ANNE-DU-SAULT",
      "state": "QC"
  },
  {
      "city": "SAINTE-APOLLINE-DE-PATTON",
      "state": "QC"
  },
  {
      "city": "SAINTE-AURELIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-BARBE",
      "state": "QC"
  },
  {
      "city": "SAINTE-BEATRIX",
      "state": "QC"
  },
  {
      "city": "SAINTE-BRIGIDE-D'IBERVILLE",
      "state": "QC"
  },
  {
      "city": "SAINTE-BRIGITTE-DE-LAVAL",
      "state": "QC"
  },
  {
      "city": "SAINTE-BRIGITTE-DES-SAULTS",
      "state": "QC"
  },
  {
      "city": "SAINTE-CATHERINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-CATHERINE-DE-HATLEY",
      "state": "QC"
  },
  {
      "city": "SAINTE-CECILE",
      "state": "NB"
  },
  {
      "city": "SAINTE-CECILE-DE-LEVRARD",
      "state": "QC"
  },
  {
      "city": "SAINTE-CECILE-DE-MASHAM",
      "state": "QC"
  },
  {
      "city": "SAINTE-CECILE-DE-MILTON",
      "state": "QC"
  },
  {
      "city": "SAINTE-CECILE-DE-WHITTON",
      "state": "QC"
  },
  {
      "city": "SAINTE-CHRISTINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-CHRISTINE-D'AUVERGNE",
      "state": "QC"
  },
  {
      "city": "SAINTE-CLAIRE",
      "state": "QC"
  },
  {
      "city": "SAINTE-CLOTILDE-DE-BEAUCE",
      "state": "QC"
  },
  {
      "city": "SAINTE-CLOTILDE-DE-CHATEAUGUAY",
      "state": "QC"
  },
  {
      "city": "SAINTE-CLOTILDE-DE-HORTON",
      "state": "QC"
  },
  {
      "city": "SAINTE-CROIX",
      "state": "QC"
  },
  {
      "city": "SAINTE-DOROTHEE",
      "state": "QC"
  },
  {
      "city": "SAINTE-EDWIDGE",
      "state": "QC"
  },
  {
      "city": "SAINTE-ELISABETH",
      "state": "QC"
  },
  {
      "city": "SAINTE-ELISABETH-DE-PROULX",
      "state": "QC"
  },
  {
      "city": "SAINTE-ELIZABETH-DE-WARWICK",
      "state": "QC"
  },
  {
      "city": "SAINTE-EMELIE-DE-L'ENERGIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-EULALIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-EUPHEMIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-FAMILLE",
      "state": "QC"
  },
  {
      "city": "SAINTE-FELICITE",
      "state": "QC"
  },
  {
      "city": "SAINTE-FELICITE-DE-L'ISLET",
      "state": "QC"
  },
  {
      "city": "SAINTE-FLAVIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-FLORENCE",
      "state": "QC"
  },
  {
      "city": "SAINTE-FRANCOISE",
      "state": "QC"
  },
  {
      "city": "SAINTE-FRANCOISE-DE-LOTBINIERE",
      "state": "QC"
  },
  {
      "city": "SAINTE-GENEVIEVE",
      "state": "QC"
  },
  {
      "city": "SAINTE-GENEVIEVE-DE-BATISCAN",
      "state": "QC"
  },
  {
      "city": "SAINTE-GERMAINE-BOULE",
      "state": "QC"
  },
  {
      "city": "SAINTE-GERTRUDE-MANNEVILLE",
      "state": "QC"
  },
  {
      "city": "SAINTE-HEDWIDGE-DE-ROBERVAL",
      "state": "QC"
  },
  {
      "city": "SAINTE-HELENE-DE-BAGOT",
      "state": "QC"
  },
  {
      "city": "SAINTE-HELENE-DE-BREAKEYVILLE",
      "state": "QC"
  },
  {
      "city": "SAINTE-HELENE-DE-CHESTER",
      "state": "QC"
  },
  {
      "city": "SAINTE-HELENE-DE-KAMOURASKA",
      "state": "QC"
  },
  {
      "city": "SAINTE-HENEDINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-JULIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-JULIENNE",
      "state": "QC"
  },
  {
      "city": "SAINTE-JUSTINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-JUSTINE-DE-NEWTON",
      "state": "QC"
  },
  {
      "city": "SAINTE-LOUISE",
      "state": "NB"
  },
  {
      "city": "SAINTE-LOUISE",
      "state": "QC"
  },
  {
      "city": "SAINTE-LUCE",
      "state": "QC"
  },
  {
      "city": "SAINTE-LUCIE-DE-BEAUREGARD",
      "state": "QC"
  },
  {
      "city": "SAINTE-LUCIE-DES-LAURENTIDES",
      "state": "QC"
  },
  {
      "city": "SAINTE-MADELEINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARCELLINE-DE-KILDARE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARGUERITE-MARIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARIE-DE-BLANDFORD",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARIE-DE-KENT",
      "state": "NB"
  },
  {
      "city": "SAINTE-MARIE-SAINT-RAPHAEL",
      "state": "NB"
  },
  {
      "city": "SAINTE-MARIE-SALOME",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARTHE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARTHE-SUR-LE-LAC",
      "state": "QC"
  },
  {
      "city": "SAINTE-MARTINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MELANIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MONIQUE",
      "state": "QC"
  },
  {
      "city": "SAINTE-MONIQUE-LAC-SAINT-JEAN",
      "state": "QC"
  },
  {
      "city": "SAINTE-PAULE",
      "state": "QC"
  },
  {
      "city": "SAINTE-PERPETUE",
      "state": "QC"
  },
  {
      "city": "SAINTE-PERPETUE-DE-L'ISLET",
      "state": "QC"
  },
  {
      "city": "SAINTE-PETRONILLE",
      "state": "QC"
  },
  {
      "city": "SAINTE-RITA",
      "state": "QC"
  },
  {
      "city": "SAINTE-ROSE",
      "state": "QC"
  },
  {
      "city": "SAINTE-ROSE-DE-WATFORD",
      "state": "QC"
  },
  {
      "city": "SAINTE-ROSE-DU-NORD",
      "state": "QC"
  },
  {
      "city": "SAINTE-ROSETTE",
      "state": "NB"
  },
  {
      "city": "SAINTE-SABINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-SABINE-DE-BELLECHASSE",
      "state": "QC"
  },
  {
      "city": "SAINTE-SERAPHINE",
      "state": "QC"
  },
  {
      "city": "SAINTE-SOPHIE",
      "state": "QC"
  },
  {
      "city": "SAINTE-SOPHIE-D'HALIFAX",
      "state": "QC"
  },
  {
      "city": "SAINTE-SOPHIE-DE-LEVRARD",
      "state": "QC"
  },
  {
      "city": "SAINTE-THECLE",
      "state": "QC"
  },
  {
      "city": "SAINTE-THERESE SUD",
      "state": "NB"
  },
  {
      "city": "SAINTE-THERESE",
      "state": "QC"
  },
  {
      "city": "SAINTE-THERESE-DE-GASPE",
      "state": "QC"
  },
  {
      "city": "SAINTE-THERESE-DE-LA-GATINEAU",
      "state": "QC"
  },
  {
      "city": "SAINTE-URSULE",
      "state": "QC"
  },
  {
      "city": "SAINTE-VICTOIRE-DE-SOREL",
      "state": "QC"
  },
  {
      "city": "SAINTS-ANGES",
      "state": "QC"
  },
  {
      "city": "SAINTS-MARTYRS-CANADIENS",
      "state": "QC"
  },
  {
      "city": "SALABERRY-DE-VALLEYFIELD",
      "state": "QC"
  },
  {
      "city": "SALEM",
      "state": "NB"
  },
  {
      "city": "SALFORD",
      "state": "ON"
  },
  {
      "city": "SALISBURY WEST",
      "state": "NB"
  },
  {
      "city": "SALISBURY",
      "state": "NB"
  },
  {
      "city": "SALLUIT",
      "state": "QC"
  },
  {
      "city": "SALLYS COVE",
      "state": "NL"
  },
  {
      "city": "SALMO",
      "state": "BC"
  },
  {
      "city": "SALMON ARM",
      "state": "BC"
  },
  {
      "city": "SALMON BEACH",
      "state": "NB"
  },
  {
      "city": "SALMON COVE BDV",
      "state": "NL"
  },
  {
      "city": "SALMON CREEK",
      "state": "NB"
  },
  {
      "city": "SALMON RIVER ROAD",
      "state": "NB"
  },
  {
      "city": "SALMON RIVER",
      "state": "NB"
  },
  {
      "city": "SALMON RIVER",
      "state": "NS"
  },
  {
      "city": "SALMON RIVER.",
      "state": "NS"
  },
  {
      "city": "SALT SPRING ISLAND",
      "state": "BC"
  },
  {
      "city": "SALT SPRINGS",
      "state": "NB"
  },
  {
      "city": "SALT SPRINGS",
      "state": "NS"
  },
  {
      "city": "SALTCOATS",
      "state": "SK"
  },
  {
      "city": "SALVAGE",
      "state": "NL"
  },
  {
      "city": "SAMBRO CREEK",
      "state": "NS"
  },
  {
      "city": "SAMBRO HEAD",
      "state": "NS"
  },
  {
      "city": "SAMBRO",
      "state": "NS"
  },
  {
      "city": "SAMPSON COVE",
      "state": "NS"
  },
  {
      "city": "SAN CLARA",
      "state": "MB"
  },
  {
      "city": "SANDFIELD",
      "state": "NS"
  },
  {
      "city": "SANDFORD",
      "state": "ON"
  },
  {
      "city": "SANDILANDS",
      "state": "MB"
  },
  {
      "city": "SANDRINGHAM",
      "state": "NL"
  },
  {
      "city": "SANDSPIT",
      "state": "BC"
  },
  {
      "city": "SANDY BAY",
      "state": "SK"
  },
  {
      "city": "SANDY COVE",
      "state": "NL"
  },
  {
      "city": "SANDY COVE",
      "state": "NS"
  },
  {
      "city": "SANDY HOOK",
      "state": "MB"
  },
  {
      "city": "SANDY LAKE",
      "state": "MB"
  },
  {
      "city": "SANDY LAKE",
      "state": "ON"
  },
  {
      "city": "SANFORD",
      "state": "MB"
  },
  {
      "city": "SANGUDO",
      "state": "AB"
  },
  {
      "city": "SANIKILUAQ",
      "state": "NU"
  },
  {
      "city": "SARNIA",
      "state": "ON"
  },
  {
      "city": "SARSFIELD",
      "state": "ON"
  },
  {
      "city": "SARTO",
      "state": "MB"
  },
  {
      "city": "SASKATOON",
      "state": "SK"
  },
  {
      "city": "SATURNA",
      "state": "BC"
  },
  {
      "city": "SAUBLE BEACH",
      "state": "ON"
  },
  {
      "city": "SAULNIERVILLE",
      "state": "NS"
  },
  {
      "city": "SAULT STE. MARIE",
      "state": "ON"
  },
  {
      "city": "SAUMAREZ",
      "state": "NB"
  },
  {
      "city": "SAVANT LAKE",
      "state": "ON"
  },
  {
      "city": "SAVOIE LANDING",
      "state": "NB"
  },
  {
      "city": "SAVONA",
      "state": "BC"
  },
  {
      "city": "SAWYERVILLE",
      "state": "QC"
  },
  {
      "city": "SAYABEC",
      "state": "QC"
  },
  {
      "city": "SAYWARD",
      "state": "BC"
  },
  {
      "city": "SCANDIA",
      "state": "AB"
  },
  {
      "city": "SCANTERBURY",
      "state": "MB"
  },
  {
      "city": "SCARBOROUGH",
      "state": "ON"
  },
  {
      "city": "SCEPTRE",
      "state": "SK"
  },
  {
      "city": "SCHANZENFELD",
      "state": "MB"
  },
  {
      "city": "SCHEFFERVILLE",
      "state": "QC"
  },
  {
      "city": "SCHOMBERG",
      "state": "ON"
  },
  {
      "city": "SCHREIBER",
      "state": "ON"
  },
  {
      "city": "SCHULER",
      "state": "AB"
  },
  {
      "city": "SCHUMACHER",
      "state": "ON"
  },
  {
      "city": "SCOTCH CREEK",
      "state": "BC"
  },
  {
      "city": "SCOTCH LAKE",
      "state": "NB"
  },
  {
      "city": "SCOTCH LAKE",
      "state": "NS"
  },
  {
      "city": "SCOTCH RIDGE",
      "state": "NB"
  },
  {
      "city": "SCOTCH SETTLEMENT YORK CO",
      "state": "NB"
  },
  {
      "city": "SCOTCH SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "SCOTCH VILLAGE",
      "state": "NS"
  },
  {
      "city": "SCOTCHTOWN",
      "state": "NB"
  },
  {
      "city": "SCOTCHTOWN",
      "state": "NS"
  },
  {
      "city": "SCOTLAND",
      "state": "ON"
  },
  {
      "city": "SCOTSBURN",
      "state": "NS"
  },
  {
      "city": "SCOTSTOWN",
      "state": "QC"
  },
  {
      "city": "SCOTSVILLE",
      "state": "NS"
  },
  {
      "city": "SCOTT SIDING",
      "state": "NB"
  },
  {
      "city": "SCOTT",
      "state": "QC"
  },
  {
      "city": "SCOTT",
      "state": "SK"
  },
  {
      "city": "SCOUDOUC ROAD",
      "state": "NB"
  },
  {
      "city": "SCOUDOUC",
      "state": "NB"
  },
  {
      "city": "SCOUT LAKE",
      "state": "SK"
  },
  {
      "city": "SEA SIDE",
      "state": "NB"
  },
  {
      "city": "SEABRIGHT",
      "state": "NS"
  },
  {
      "city": "SEAFORTH",
      "state": "ON"
  },
  {
      "city": "SEAGRAVE",
      "state": "ON"
  },
  {
      "city": "SEAL COVE FB",
      "state": "NL"
  },
  {
      "city": "SEAL COVE WB",
      "state": "NL"
  },
  {
      "city": "SEARCHMONT",
      "state": "ON"
  },
  {
      "city": "SEARSVILLE",
      "state": "NB"
  },
  {
      "city": "SEBA BEACH",
      "state": "AB"
  },
  {
      "city": "SEBRIGHT",
      "state": "ON"
  },
  {
      "city": "SEBRINGVILLE",
      "state": "ON"
  },
  {
      "city": "SECHELT",
      "state": "BC"
  },
  {
      "city": "SECOND FALLS",
      "state": "NB"
  },
  {
      "city": "SECOND NORTH RIVER",
      "state": "NB"
  },
  {
      "city": "SEDALIA",
      "state": "AB"
  },
  {
      "city": "SEDDONS CORNER",
      "state": "MB"
  },
  {
      "city": "SEDGEWICK",
      "state": "AB"
  },
  {
      "city": "SEDLEY",
      "state": "SK"
  },
  {
      "city": "SEELEYS BAY",
      "state": "ON"
  },
  {
      "city": "SEELEYS COVE",
      "state": "NB"
  },
  {
      "city": "SELBY",
      "state": "ON"
  },
  {
      "city": "SELDOM",
      "state": "NL"
  },
  {
      "city": "SELKIRK",
      "state": "MB"
  },
  {
      "city": "SELKIRK",
      "state": "ON"
  },
  {
      "city": "SEMANS",
      "state": "SK"
  },
  {
      "city": "SENLAC",
      "state": "SK"
  },
  {
      "city": "SENNETERRE",
      "state": "QC"
  },
  {
      "city": "SENNEVILLE",
      "state": "QC"
  },
  {
      "city": "SEPT-ILES",
      "state": "QC"
  },
  {
      "city": "SERPENT RIVER",
      "state": "ON"
  },
  {
      "city": "SESEKINIKA",
      "state": "ON"
  },
  {
      "city": "SETON PORTAGE",
      "state": "BC"
  },
  {
      "city": "SEVEN PERSONS",
      "state": "AB"
  },
  {
      "city": "SEVEN SISTERS FALLS",
      "state": "MB"
  },
  {
      "city": "SEVERN BRIDGE",
      "state": "ON"
  },
  {
      "city": "SEVOGLE",
      "state": "NB"
  },
  {
      "city": "SEXSMITH",
      "state": "AB"
  },
  {
      "city": "SHACKLETON",
      "state": "SK"
  },
  {
      "city": "SHAD BAY",
      "state": "NS"
  },
  {
      "city": "SHAG HARBOUR",
      "state": "NS"
  },
  {
      "city": "SHAKESPEARE",
      "state": "ON"
  },
  {
      "city": "SHALALTH",
      "state": "BC"
  },
  {
      "city": "SHALLOW LAKE",
      "state": "ON"
  },
  {
      "city": "SHALLOWAY COVE",
      "state": "NL"
  },
  {
      "city": "SHAMATTAWA",
      "state": "MB"
  },
  {
      "city": "SHAMROCK",
      "state": "SK"
  },
  {
      "city": "SHANKLIN",
      "state": "NB"
  },
  {
      "city": "SHANNON",
      "state": "NB"
  },
  {
      "city": "SHANNON",
      "state": "QC"
  },
  {
      "city": "SHANNONVILLE",
      "state": "ON"
  },
  {
      "city": "SHANTY BAY",
      "state": "ON"
  },
  {
      "city": "SHARBOT LAKE",
      "state": "ON"
  },
  {
      "city": "SHARON",
      "state": "ON"
  },
  {
      "city": "SHAUGHNESSY",
      "state": "AB"
  },
  {
      "city": "SHAUNAVON",
      "state": "SK"
  },
  {
      "city": "SHAWINIGAN",
      "state": "QC"
  },
  {
      "city": "SHAWINIGAN-SUD",
      "state": "QC"
  },
  {
      "city": "SHAWNIGAN LAKE",
      "state": "BC"
  },
  {
      "city": "SHAWVILLE",
      "state": "QC"
  },
  {
      "city": "SHEA HEIGHTS",
      "state": "NL"
  },
  {
      "city": "SHEARSTOWN",
      "state": "NL"
  },
  {
      "city": "SHEARWATER",
      "state": "NS"
  },
  {
      "city": "SHEBANDOWAN",
      "state": "ON"
  },
  {
      "city": "SHEDDEN",
      "state": "ON"
  },
  {
      "city": "SHEDIAC BRIDGE",
      "state": "NB"
  },
  {
      "city": "SHEDIAC BRIDGE-SHEDIAC RIVER",
      "state": "NB"
  },
  {
      "city": "SHEDIAC CAPE",
      "state": "NB"
  },
  {
      "city": "SHEDIAC RIVER",
      "state": "NB"
  },
  {
      "city": "SHEDIAC",
      "state": "NB"
  },
  {
      "city": "SHEENBORO",
      "state": "QC"
  },
  {
      "city": "SHEET HARBOUR",
      "state": "NS"
  },
  {
      "city": "SHEFFIELD",
      "state": "NB"
  },
  {
      "city": "SHEFFIELD",
      "state": "ON"
  },
  {
      "city": "SHEFFORD",
      "state": "QC"
  },
  {
      "city": "SHEGUIANDAH",
      "state": "ON"
  },
  {
      "city": "SHEHO",
      "state": "SK"
  },
  {
      "city": "SHELBURNE",
      "state": "NS"
  },
  {
      "city": "SHELBURNE",
      "state": "ON"
  },
  {
      "city": "SHELDRAKE",
      "state": "QC"
  },
  {
      "city": "SHELL LAKE",
      "state": "SK"
  },
  {
      "city": "SHELLBROOK",
      "state": "SK"
  },
  {
      "city": "SHELLMOUTH",
      "state": "MB"
  },
  {
      "city": "SHEMOGUE",
      "state": "NB"
  },
  {
      "city": "SHENACADIE",
      "state": "NS"
  },
  {
      "city": "SHENSTONE",
      "state": "NB"
  },
  {
      "city": "SHEPODY ALBERT CO",
      "state": "NB"
  },
  {
      "city": "SHEPODY KINGS CO",
      "state": "NB"
  },
  {
      "city": "SHERBROOKE",
      "state": "NS"
  },
  {
      "city": "SHERBROOKE",
      "state": "QC"
  },
  {
      "city": "SHERKSTON",
      "state": "ON"
  },
  {
      "city": "SHERRIDON",
      "state": "MB"
  },
  {
      "city": "SHERRINGTON",
      "state": "QC"
  },
  {
      "city": "SHERWOOD PARK",
      "state": "AB"
  },
  {
      "city": "SHESHEGWANING",
      "state": "ON"
  },
  {
      "city": "SHIGAWAKE",
      "state": "QC"
  },
  {
      "city": "SHILO",
      "state": "MB"
  },
  {
      "city": "SHINING TREE",
      "state": "ON"
  },
  {
      "city": "SHIP HARBOUR PB",
      "state": "NL"
  },
  {
      "city": "SHIPMAN",
      "state": "SK"
  },
  {
      "city": "SHIPPAGAN",
      "state": "NB"
  },
  {
      "city": "SHIPSHAW",
      "state": "QC"
  },
  {
      "city": "SHIRLEY",
      "state": "BC"
  },
  {
      "city": "SHOAL LAKE",
      "state": "MB"
  },
  {
      "city": "SHOE COVE",
      "state": "NL"
  },
  {
      "city": "SHORTDALE",
      "state": "MB"
  },
  {
      "city": "SHORTTS LAKE",
      "state": "NS"
  },
  {
      "city": "SHUBENACADIE EAST",
      "state": "NS"
  },
  {
      "city": "SHUBENACADIE",
      "state": "NS"
  },
  {
      "city": "SIBBALD",
      "state": "AB"
  },
  {
      "city": "SICAMOUS",
      "state": "BC"
  },
  {
      "city": "SIDNEY",
      "state": "BC"
  },
  {
      "city": "SIDNEY",
      "state": "MB"
  },
  {
      "city": "SIEGAS",
      "state": "NB"
  },
  {
      "city": "SIFTON",
      "state": "MB"
  },
  {
      "city": "SIKSIKA",
      "state": "AB"
  },
  {
      "city": "SILLIKERS",
      "state": "NB"
  },
  {
      "city": "SILTON",
      "state": "SK"
  },
  {
      "city": "SILVER RIDGE",
      "state": "MB"
  },
  {
      "city": "SILVER VALLEY",
      "state": "AB"
  },
  {
      "city": "SILVER WATER",
      "state": "ON"
  },
  {
      "city": "SILVER",
      "state": "MB"
  },
  {
      "city": "SILVERTON",
      "state": "BC"
  },
  {
      "city": "SIMCOE",
      "state": "ON"
  },
  {
      "city": "SIMMIE",
      "state": "SK"
  },
  {
      "city": "SIMONDS",
      "state": "NB"
  },
  {
      "city": "SIMOOM SOUND",
      "state": "BC"
  },
  {
      "city": "SIMPSON",
      "state": "SK"
  },
  {
      "city": "SINCLAIR MILLS",
      "state": "BC"
  },
  {
      "city": "SINCLAIR",
      "state": "MB"
  },
  {
      "city": "SINGHAMPTON",
      "state": "ON"
  },
  {
      "city": "SINTALUTA",
      "state": "SK"
  },
  {
      "city": "SIOUX LOOKOUT",
      "state": "ON"
  },
  {
      "city": "SIOUX NARROWS",
      "state": "ON"
  },
  {
      "city": "SIRDAR",
      "state": "BC"
  },
  {
      "city": "SISSON BROOK",
      "state": "NB"
  },
  {
      "city": "SISSON RIDGE",
      "state": "NB"
  },
  {
      "city": "SIX ROADS",
      "state": "NB"
  },
  {
      "city": "SKEAD",
      "state": "ON"
  },
  {
      "city": "SKIFF LAKE",
      "state": "NB"
  },
  {
      "city": "SKIFF",
      "state": "AB"
  },
  {
      "city": "SKOOKUMCHUCK",
      "state": "BC"
  },
  {
      "city": "SKOWNAN",
      "state": "MB"
  },
  {
      "city": "SLATE FALLS",
      "state": "ON"
  },
  {
      "city": "SLAVE LAKE",
      "state": "AB"
  },
  {
      "city": "SLEEMAN",
      "state": "ON"
  },
  {
      "city": "SLEMON PARK",
      "state": "PE"
  },
  {
      "city": "SLOCAN PARK",
      "state": "BC"
  },
  {
      "city": "SLOCAN",
      "state": "BC"
  },
  {
      "city": "SLOPE",
      "state": "NB"
  },
  {
      "city": "SMEATON",
      "state": "SK"
  },
  {
      "city": "SMILEY",
      "state": "SK"
  },
  {
      "city": "SMITH CROSSING",
      "state": "NB"
  },
  {
      "city": "SMITH",
      "state": "AB"
  },
  {
      "city": "SMITH'S CORNER",
      "state": "NB"
  },
  {
      "city": "SMITHERS",
      "state": "BC"
  },
  {
      "city": "SMITHFIELD",
      "state": "NB"
  },
  {
      "city": "SMITHS COVE",
      "state": "NS"
  },
  {
      "city": "SMITHS CREEK",
      "state": "NB"
  },
  {
      "city": "SMITHS FALLS",
      "state": "ON"
  },
  {
      "city": "SMITHSVILLE",
      "state": "NS"
  },
  {
      "city": "SMITHTOWN",
      "state": "NB"
  },
  {
      "city": "SMITHVILLE",
      "state": "ON"
  },
  {
      "city": "SMOKY LAKE",
      "state": "AB"
  },
  {
      "city": "SMOOTH ROCK FALLS",
      "state": "ON"
  },
  {
      "city": "SNIDER MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "SNOOKS ARM",
      "state": "NL"
  },
  {
      "city": "SNOW LAKE",
      "state": "MB"
  },
  {
      "city": "SNOW ROAD STATION",
      "state": "ON"
  },
  {
      "city": "SNOWDEN",
      "state": "SK"
  },
  {
      "city": "SNOWFLAKE",
      "state": "MB"
  },
  {
      "city": "SOINTULA",
      "state": "BC"
  },
  {
      "city": "SOLSGIRTH",
      "state": "MB"
  },
  {
      "city": "SOMBRA",
      "state": "ON"
  },
  {
      "city": "SOMERSET",
      "state": "MB"
  },
  {
      "city": "SOMERVILLE",
      "state": "NB"
  },
  {
      "city": "SONNINGDALE",
      "state": "SK"
  },
  {
      "city": "SOOKE",
      "state": "BC"
  },
  {
      "city": "SOPS ARM",
      "state": "NL"
  },
  {
      "city": "SOREL-TRACY",
      "state": "QC"
  },
  {
      "city": "SORMANY",
      "state": "NB"
  },
  {
      "city": "SORRENTO",
      "state": "BC"
  },
  {
      "city": "SOURIS",
      "state": "MB"
  },
  {
      "city": "SOURIS",
      "state": "PE"
  },
  {
      "city": "SOUTH BAPTISTE",
      "state": "AB"
  },
  {
      "city": "SOUTH BAR",
      "state": "NS"
  },
  {
      "city": "SOUTH BAYMOUTH",
      "state": "ON"
  },
  {
      "city": "SOUTH BRANCH KENT CO",
      "state": "NB"
  },
  {
      "city": "SOUTH BRANCH KINGS CO",
      "state": "NB"
  },
  {
      "city": "SOUTH BRANCH",
      "state": "NL"
  },
  {
      "city": "SOUTH BROOK GB",
      "state": "NL"
  },
  {
      "city": "SOUTH BROOKFIELD",
      "state": "NS"
  },
  {
      "city": "SOUTH CANAAN",
      "state": "NB"
  },
  {
      "city": "SOUTH DILDO",
      "state": "NL"
  },
  {
      "city": "SOUTH EAST BIGHT",
      "state": "NL"
  },
  {
      "city": "SOUTH ESK",
      "state": "NB"
  },
  {
      "city": "SOUTH GILLIES",
      "state": "ON"
  },
  {
      "city": "SOUTH HAZELTON",
      "state": "BC"
  },
  {
      "city": "SOUTH HEAD",
      "state": "NS"
  },
  {
      "city": "SOUTH INDIAN LAKE",
      "state": "MB"
  },
  {
      "city": "SOUTH JUNCTION",
      "state": "MB"
  },
  {
      "city": "SOUTH LANCASTER",
      "state": "ON"
  },
  {
      "city": "SOUTH MAITLAND",
      "state": "NS"
  },
  {
      "city": "SOUTH MOUNTAIN",
      "state": "ON"
  },
  {
      "city": "SOUTH NELSON",
      "state": "NB"
  },
  {
      "city": "SOUTH OHIO",
      "state": "NS"
  },
  {
      "city": "SOUTH OROMOCTO LAKE",
      "state": "NB"
  },
  {
      "city": "SOUTH PORCUPINE",
      "state": "ON"
  },
  {
      "city": "SOUTH RIVER",
      "state": "NL"
  },
  {
      "city": "SOUTH RIVER",
      "state": "ON"
  },
  {
      "city": "SOUTH SLOCAN",
      "state": "BC"
  },
  {
      "city": "SOUTH TETAGOUCHE",
      "state": "NB"
  },
  {
      "city": "SOUTH WEST MARGAREE",
      "state": "NS"
  },
  {
      "city": "SOUTH WOODSLEE",
      "state": "ON"
  },
  {
      "city": "SOUTHAMPTON",
      "state": "NB"
  },
  {
      "city": "SOUTHAMPTON",
      "state": "NS"
  },
  {
      "city": "SOUTHAMPTON",
      "state": "ON"
  },
  {
      "city": "SOUTHBANK",
      "state": "BC"
  },
  {
      "city": "SOUTHEND",
      "state": "SK"
  },
  {
      "city": "SOUTHERN BAY",
      "state": "NL"
  },
  {
      "city": "SOUTHERN HARBOUR PB",
      "state": "NL"
  },
  {
      "city": "SOUTHEY",
      "state": "SK"
  },
  {
      "city": "SOUTHFIELD",
      "state": "NB"
  },
  {
      "city": "SOUTHPORT",
      "state": "MB"
  },
  {
      "city": "SOUTHSIDE BOULARDERIE",
      "state": "NS"
  },
  {
      "city": "SOUTHWOLD",
      "state": "ON"
  },
  {
      "city": "SOVEREIGN",
      "state": "SK"
  },
  {
      "city": "SPALDING",
      "state": "SK"
  },
  {
      "city": "SPANIARDS BAY",
      "state": "NL"
  },
  {
      "city": "SPANISH",
      "state": "ON"
  },
  {
      "city": "SPARTA",
      "state": "ON"
  },
  {
      "city": "SPARWOOD",
      "state": "BC"
  },
  {
      "city": "SPECTACLE LAKES",
      "state": "NS"
  },
  {
      "city": "SPEDDEN",
      "state": "AB"
  },
  {
      "city": "SPEERS",
      "state": "SK"
  },
  {
      "city": "SPEERVILLE",
      "state": "NB"
  },
  {
      "city": "SPENCERVILLE",
      "state": "ON"
  },
  {
      "city": "SPENCES BRIDGE",
      "state": "BC"
  },
  {
      "city": "SPERLING",
      "state": "MB"
  },
  {
      "city": "SPILLIMACHEEN",
      "state": "BC"
  },
  {
      "city": "SPIRIT RIVER",
      "state": "AB"
  },
  {
      "city": "SPIRITWOOD",
      "state": "SK"
  },
  {
      "city": "SPLIT LAKE",
      "state": "MB"
  },
  {
      "city": "SPRAGGE",
      "state": "ON"
  },
  {
      "city": "SPRAGUE",
      "state": "MB"
  },
  {
      "city": "SPRING BAY",
      "state": "ON"
  },
  {
      "city": "SPRING COULEE",
      "state": "AB"
  },
  {
      "city": "SPRING LAKE",
      "state": "AB"
  },
  {
      "city": "SPRING VALLEY",
      "state": "SK"
  },
  {
      "city": "SPRINGBROOK",
      "state": "AB"
  },
  {
      "city": "SPRINGBROOK",
      "state": "ON"
  },
  {
      "city": "SPRINGDALE",
      "state": "NB"
  },
  {
      "city": "SPRINGDALE",
      "state": "NL"
  },
  {
      "city": "SPRINGFIELD KINGS CO",
      "state": "NB"
  },
  {
      "city": "SPRINGFIELD YORK CO",
      "state": "NB"
  },
  {
      "city": "SPRINGFIELD",
      "state": "MB"
  },
  {
      "city": "SPRINGFIELD",
      "state": "NS"
  },
  {
      "city": "SPRINGFIELD",
      "state": "ON"
  },
  {
      "city": "SPRINGFORD",
      "state": "ON"
  },
  {
      "city": "SPRINGHILL",
      "state": "NS"
  },
  {
      "city": "SPRINGSIDE",
      "state": "SK"
  },
  {
      "city": "SPRINGSTEIN",
      "state": "MB"
  },
  {
      "city": "SPRINGWATER",
      "state": "SK"
  },
  {
      "city": "SPRUCE GROVE",
      "state": "AB"
  },
  {
      "city": "SPRUCE HOME",
      "state": "SK"
  },
  {
      "city": "SPRUCE LAKE",
      "state": "SK"
  },
  {
      "city": "SPRUCE VIEW",
      "state": "AB"
  },
  {
      "city": "SPRUCEDALE",
      "state": "ON"
  },
  {
      "city": "SPUTINOW",
      "state": "AB"
  },
  {
      "city": "SPY HILL",
      "state": "SK"
  },
  {
      "city": "SQUAMISH",
      "state": "BC"
  },
  {
      "city": "SQUATEC",
      "state": "QC"
  },
  {
      "city": "SQUAW CAP",
      "state": "NB"
  },
  {
      "city": "SQUIRREL COVE",
      "state": "BC"
  },
  {
      "city": "ST ADOLPHE",
      "state": "MB"
  },
  {
      "city": "ST AGATHA",
      "state": "ON"
  },
  {
      "city": "ST ALBANS",
      "state": "NL"
  },
  {
      "city": "ST ALBERT",
      "state": "ON"
  },
  {
      "city": "ST ALMO",
      "state": "NB"
  },
  {
      "city": "ST ALPHONSE",
      "state": "MB"
  },
  {
      "city": "ST AMBROISE",
      "state": "MB"
  },
  {
      "city": "ST ANDREWS CHANNEL",
      "state": "NS"
  },
  {
      "city": "ST ANDREWS WEST",
      "state": "ON"
  },
  {
      "city": "ST ANDREWS",
      "state": "MB"
  },
  {
      "city": "ST ANDREWS",
      "state": "NB"
  },
  {
      "city": "ST ANDREWS",
      "state": "NL"
  },
  {
      "city": "ST ANDREWS",
      "state": "NS"
  },
  {
      "city": "ST ANNS",
      "state": "ON"
  },
  {
      "city": "ST ANTHONY EAST",
      "state": "NL"
  },
  {
      "city": "ST BENEDICT",
      "state": "SK"
  },
  {
      "city": "ST BERNARDIN",
      "state": "ON"
  },
  {
      "city": "ST BERNARDS-JACQUES FONTAINE",
      "state": "NL"
  },
  {
      "city": "ST BRENDANS",
      "state": "NL"
  },
  {
      "city": "ST BRIDES",
      "state": "AB"
  },
  {
      "city": "ST BRIDES",
      "state": "NL"
  },
  {
      "city": "ST BRIEUX",
      "state": "SK"
  },
  {
      "city": "ST CATHARINES",
      "state": "ON"
  },
  {
      "city": "ST CHADS",
      "state": "NL"
  },
  {
      "city": "ST CHARLES",
      "state": "ON"
  },
  {
      "city": "ST CLAUDE",
      "state": "MB"
  },
  {
      "city": "ST CLEMENTS",
      "state": "MB"
  },
  {
      "city": "ST CLEMENTS",
      "state": "ON"
  },
  {
      "city": "ST COLUMBA",
      "state": "NS"
  },
  {
      "city": "ST CROIX",
      "state": "NB"
  },
  {
      "city": "ST DAVID RIDGE",
      "state": "NB"
  },
  {
      "city": "ST DAVIDS",
      "state": "NL"
  },
  {
      "city": "ST DAVIDS",
      "state": "ON"
  },
  {
      "city": "ST DENIS",
      "state": "SK"
  },
  {
      "city": "ST EUGENE",
      "state": "ON"
  },
  {
      "city": "ST EUSTACHE",
      "state": "MB"
  },
  {
      "city": "ST FINTANS",
      "state": "NL"
  },
  {
      "city": "ST FRANCOIS XAVIER",
      "state": "MB"
  },
  {
      "city": "ST GEORGE BRANT",
      "state": "ON"
  },
  {
      "city": "ST GEORGE",
      "state": "NB"
  },
  {
      "city": "ST GEORGES",
      "state": "NL"
  },
  {
      "city": "ST GERMAIN SOUTH",
      "state": "MB"
  },
  {
      "city": "ST GREGOR",
      "state": "SK"
  },
  {
      "city": "ST ISIDORE DE BELLEVUE",
      "state": "SK"
  },
  {
      "city": "ST ISIDORE",
      "state": "AB"
  },
  {
      "city": "ST ISIDORE",
      "state": "ON"
  },
  {
      "city": "ST JACOBS",
      "state": "ON"
  },
  {
      "city": "ST JEAN BAPTISTE",
      "state": "MB"
  },
  {
      "city": "ST JOACHIM",
      "state": "ON"
  },
  {
      "city": "ST JOSEPH",
      "state": "MB"
  },
  {
      "city": "ST JOSEPHS SAL",
      "state": "NL"
  },
  {
      "city": "ST JUDES",
      "state": "NL"
  },
  {
      "city": "ST JULIENS",
      "state": "NL"
  },
  {
      "city": "ST LAURENT",
      "state": "MB"
  },
  {
      "city": "ST LAWRENCE",
      "state": "NL"
  },
  {
      "city": "ST LAZARE",
      "state": "MB"
  },
  {
      "city": "ST LEON",
      "state": "MB"
  },
  {
      "city": "ST LEWIS",
      "state": "NL"
  },
  {
      "city": "ST LINA",
      "state": "AB"
  },
  {
      "city": "ST LOUIS",
      "state": "SK"
  },
  {
      "city": "ST LUNAIRE-GRIQUET",
      "state": "NL"
  },
  {
      "city": "ST MALO",
      "state": "MB"
  },
  {
      "city": "ST MARGARET VILLAGE",
      "state": "NS"
  },
  {
      "city": "ST MARGARETS",
      "state": "NB"
  },
  {
      "city": "ST MARKS",
      "state": "MB"
  },
  {
      "city": "ST MARTIN",
      "state": "MB"
  },
  {
      "city": "ST MARTINS NORTH",
      "state": "NB"
  },
  {
      "city": "ST MARTINS",
      "state": "NB"
  },
  {
      "city": "ST MARYS",
      "state": "NL"
  },
  {
      "city": "ST MARYS",
      "state": "ON"
  },
  {
      "city": "ST MICHAEL",
      "state": "AB"
  },
  {
      "city": "ST PAUL",
      "state": "AB"
  },
  {
      "city": "ST PAULS STATION",
      "state": "ON"
  },
  {
      "city": "ST PAULS",
      "state": "NL"
  },
  {
      "city": "ST PETERS",
      "state": "NS"
  },
  {
      "city": "ST PIERRE JOLYS",
      "state": "MB"
  },
  {
      "city": "ST SHOTTS",
      "state": "NL"
  },
  {
      "city": "ST STEPHEN",
      "state": "NB"
  },
  {
      "city": "ST THERESA POINT",
      "state": "MB"
  },
  {
      "city": "ST THOMAS",
      "state": "NB"
  },
  {
      "city": "ST THOMAS",
      "state": "ON"
  },
  {
      "city": "ST VICTOR",
      "state": "SK"
  },
  {
      "city": "ST VINCENT",
      "state": "AB"
  },
  {
      "city": "ST VINCENTS",
      "state": "NL"
  },
  {
      "city": "ST WALBURG",
      "state": "SK"
  },
  {
      "city": "ST WILLIAMS",
      "state": "ON"
  },
  {
      "city": "ST-ANTOINE NORD",
      "state": "NB"
  },
  {
      "city": "ST-ATHANASE",
      "state": "QC"
  },
  {
      "city": "ST-BARNABE-NORD",
      "state": "QC"
  },
  {
      "city": "ST-FRANCOIS-DE-LA-RIVIERE-DU-S",
      "state": "QC"
  },
  {
      "city": "ST-FRANCOIS-XAVIER-DE-BROMPTON",
      "state": "QC"
  },
  {
      "city": "ST-GABRIEL-DE-KAMOURASKA",
      "state": "QC"
  },
  {
      "city": "ST-HILAIRE",
      "state": "NB"
  },
  {
      "city": "ST-JEAN-BAPTISTE",
      "state": "NB"
  },
  {
      "city": "ST-JOACHIM-DE-MONTMORENCY",
      "state": "QC"
  },
  {
      "city": "ST-JOSEPH-DE-LA-POINTE-DE-LEVY",
      "state": "QC"
  },
  {
      "city": "ST-JOSEPH-DE-MADAWASKA",
      "state": "NB"
  },
  {
      "city": "ST-JOSEPH-DU-MOINE",
      "state": "NS"
  },
  {
      "city": "ST-LOUIS",
      "state": "PE"
  },
  {
      "city": "ST-MARCEL-DE-L'ISLET",
      "state": "QC"
  },
  {
      "city": "ST-NAZAIRE-DU-LAC-ST-JEAN",
      "state": "QC"
  },
  {
      "city": "ST-OCTAVE",
      "state": "QC"
  },
  {
      "city": "ST-ONESIME",
      "state": "QC"
  },
  {
      "city": "ST-PETERS BAY",
      "state": "PE"
  },
  {
      "city": "ST-PIERRE-DE-LA-RIVIERE-DU-SUD",
      "state": "QC"
  },
  {
      "city": "ST-VALERIEN",
      "state": "QC"
  },
  {
      "city": "ST. ALBERT",
      "state": "AB"
  },
  {
      "city": "ST. ANTHONY",
      "state": "NL"
  },
  {
      "city": "ST. GEORGES",
      "state": "MB"
  },
  {
      "city": "ST. JOHN'S",
      "state": "NL"
  },
  {
      "city": "STAFFA",
      "state": "ON"
  },
  {
      "city": "STAG HARBOUR",
      "state": "NL"
  },
  {
      "city": "STALWART",
      "state": "SK"
  },
  {
      "city": "STANBRIDGE EAST",
      "state": "QC"
  },
  {
      "city": "STANBRIDGE STATION",
      "state": "QC"
  },
  {
      "city": "STAND OFF",
      "state": "AB"
  },
  {
      "city": "STANDARD",
      "state": "AB"
  },
  {
      "city": "STANDON",
      "state": "QC"
  },
  {
      "city": "STANLEY MISSION",
      "state": "SK"
  },
  {
      "city": "STANLEY",
      "state": "NB"
  },
  {
      "city": "STANSTEAD",
      "state": "QC"
  },
  {
      "city": "STAPLES SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "STAPLES",
      "state": "ON"
  },
  {
      "city": "STAR CITY",
      "state": "SK"
  },
  {
      "city": "STAR",
      "state": "AB"
  },
  {
      "city": "STARBUCK",
      "state": "MB"
  },
  {
      "city": "STAUFFER",
      "state": "AB"
  },
  {
      "city": "STAVELY",
      "state": "AB"
  },
  {
      "city": "STAYNER",
      "state": "ON"
  },
  {
      "city": "STE AGATHE",
      "state": "MB"
  },
  {
      "city": "STE ANNE DE PRESCOTT",
      "state": "ON"
  },
  {
      "city": "STE ANNE",
      "state": "MB"
  },
  {
      "city": "STE CROIX",
      "state": "NS"
  },
  {
      "city": "STE ROSE DU LAC",
      "state": "MB"
  },
  {
      "city": "STE-ANNE-DU-RUISSEAU",
      "state": "NS"
  },
  {
      "city": "STE-CATHERINE-DE-LA-J-CARTIER",
      "state": "QC"
  },
  {
      "city": "STE-IRENE-DE-MATAPEDIA",
      "state": "QC"
  },
  {
      "city": "STE-JEANNE-D'ARC-DE-MATANE",
      "state": "QC"
  },
  {
      "city": "STE-MARGUERITE-DE-DORCHESTER",
      "state": "QC"
  },
  {
      "city": "STE-MARGUERITE-DU-LAC-MASSON",
      "state": "QC"
  },
  {
      "city": "STEAD",
      "state": "MB"
  },
  {
      "city": "STEELMAN",
      "state": "SK"
  },
  {
      "city": "STEEP ROCK",
      "state": "MB"
  },
  {
      "city": "STEEVES MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "STEEVES SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "STEINBACH",
      "state": "MB"
  },
  {
      "city": "STELLA",
      "state": "ON"
  },
  {
      "city": "STELLARTON",
      "state": "NS"
  },
  {
      "city": "STENEN",
      "state": "SK"
  },
  {
      "city": "STEPHENFIELD",
      "state": "MB"
  },
  {
      "city": "STEPHENVILLE CROSSING",
      "state": "NL"
  },
  {
      "city": "STEPHENVILLE",
      "state": "NL"
  },
  {
      "city": "STETTLER",
      "state": "AB"
  },
  {
      "city": "STEVENSON ISLAND",
      "state": "MB"
  },
  {
      "city": "STEVENSVILLE",
      "state": "ON"
  },
  {
      "city": "STEWART VALLEY",
      "state": "SK"
  },
  {
      "city": "STEWART",
      "state": "BC"
  },
  {
      "city": "STEWIACKE",
      "state": "NS"
  },
  {
      "city": "STICKNEY",
      "state": "NB"
  },
  {
      "city": "STILESVILLE",
      "state": "NB"
  },
  {
      "city": "STILLWATER LAKE",
      "state": "NS"
  },
  {
      "city": "STIRLING",
      "state": "AB"
  },
  {
      "city": "STIRLING",
      "state": "NS"
  },
  {
      "city": "STIRLING",
      "state": "ON"
  },
  {
      "city": "STITTSVILLE",
      "state": "ON"
  },
  {
      "city": "STOCKHOLM",
      "state": "SK"
  },
  {
      "city": "STOCKTON",
      "state": "MB"
  },
  {
      "city": "STOKE",
      "state": "QC"
  },
  {
      "city": "STOKES BAY",
      "state": "ON"
  },
  {
      "city": "STONECLIFFE",
      "state": "ON"
  },
  {
      "city": "STONEHAM-ET-TEWKESBURY",
      "state": "QC"
  },
  {
      "city": "STONEHAVEN",
      "state": "NB"
  },
  {
      "city": "STONEVILLE",
      "state": "NL"
  },
  {
      "city": "STONEWALL",
      "state": "MB"
  },
  {
      "city": "STONEY CREEK",
      "state": "NB"
  },
  {
      "city": "STONEY CREEK",
      "state": "ON"
  },
  {
      "city": "STONEY ISLAND",
      "state": "NS"
  },
  {
      "city": "STONY BEACH",
      "state": "SK"
  },
  {
      "city": "STONY MOUNTAIN",
      "state": "MB"
  },
  {
      "city": "STONY PLAIN",
      "state": "AB"
  },
  {
      "city": "STONY RAPIDS",
      "state": "SK"
  },
  {
      "city": "STOREYTOWN",
      "state": "NB"
  },
  {
      "city": "STORNOWAY",
      "state": "QC"
  },
  {
      "city": "STORNOWAY",
      "state": "SK"
  },
  {
      "city": "STORTHOAKS",
      "state": "SK"
  },
  {
      "city": "STOUFFVILLE",
      "state": "ON"
  },
  {
      "city": "STOUGHTON",
      "state": "SK"
  },
  {
      "city": "STRAFFORDVILLE",
      "state": "ON"
  },
  {
      "city": "STRANRAER",
      "state": "SK"
  },
  {
      "city": "STRASBOURG",
      "state": "SK"
  },
  {
      "city": "STRATFORD",
      "state": "ON"
  },
  {
      "city": "STRATFORD",
      "state": "PE"
  },
  {
      "city": "STRATFORD",
      "state": "QC"
  },
  {
      "city": "STRATHADAM",
      "state": "NB"
  },
  {
      "city": "STRATHCLAIR",
      "state": "MB"
  },
  {
      "city": "STRATHMORE",
      "state": "AB"
  },
  {
      "city": "STRATHROY",
      "state": "ON"
  },
  {
      "city": "STRATTON",
      "state": "ON"
  },
  {
      "city": "STREAMSTOWN",
      "state": "AB"
  },
  {
      "city": "STRICKLAND",
      "state": "ON"
  },
  {
      "city": "STROME",
      "state": "AB"
  },
  {
      "city": "STRONGFIELD",
      "state": "SK"
  },
  {
      "city": "STUART ISLAND",
      "state": "BC"
  },
  {
      "city": "STUART TOWN",
      "state": "NB"
  },
  {
      "city": "STUARTBURN",
      "state": "MB"
  },
  {
      "city": "STUKELY-SUD",
      "state": "QC"
  },
  {
      "city": "STUMP LAKE",
      "state": "SK"
  },
  {
      "city": "STURGEON COUNTY",
      "state": "AB"
  },
  {
      "city": "STURGEON FALLS",
      "state": "ON"
  },
  {
      "city": "STURGEON LANDING",
      "state": "SK"
  },
  {
      "city": "STURGIS",
      "state": "SK"
  },
  {
      "city": "STYMIEST",
      "state": "NB"
  },
  {
      "city": "SUCCESS",
      "state": "SK"
  },
  {
      "city": "SUDBURY",
      "state": "ON"
  },
  {
      "city": "SUGAR CAMP",
      "state": "NS"
  },
  {
      "city": "SULTAN",
      "state": "ON"
  },
  {
      "city": "SUMMER BEAVER",
      "state": "ON"
  },
  {
      "city": "SUMMERBERRY",
      "state": "SK"
  },
  {
      "city": "SUMMERFIELD CARLETON CO",
      "state": "NB"
  },
  {
      "city": "SUMMERFIELD KINGS CO",
      "state": "NB"
  },
  {
      "city": "SUMMERFORD",
      "state": "NL"
  },
  {
      "city": "SUMMERLAND",
      "state": "BC"
  },
  {
      "city": "SUMMERSIDE",
      "state": "PE"
  },
  {
      "city": "SUMMERSTOWN",
      "state": "ON"
  },
  {
      "city": "SUMMERVILLE",
      "state": "NB"
  },
  {
      "city": "SUMMERVILLE",
      "state": "NL"
  },
  {
      "city": "SUMMERVILLE",
      "state": "NS"
  },
  {
      "city": "SUMMIT LAKE",
      "state": "BC"
  },
  {
      "city": "SUN PEAKS",
      "state": "BC"
  },
  {
      "city": "SUNDERLAND",
      "state": "ON"
  },
  {
      "city": "SUNDOWN",
      "state": "MB"
  },
  {
      "city": "SUNDRE",
      "state": "AB"
  },
  {
      "city": "SUNDRIDGE",
      "state": "ON"
  },
  {
      "city": "SUNNY CORNER",
      "state": "NB"
  },
  {
      "city": "SUNNYBRAE",
      "state": "NS"
  },
  {
      "city": "SUNNYBROOK",
      "state": "AB"
  },
  {
      "city": "SUNNYNOOK",
      "state": "AB"
  },
  {
      "city": "SUNNYSIDE BEACH",
      "state": "NB"
  },
  {
      "city": "SUNNYSIDE",
      "state": "NL"
  },
  {
      "city": "SUNSET BEACH",
      "state": "AB"
  },
  {
      "city": "SUNSET HOUSE",
      "state": "AB"
  },
  {
      "city": "SUNSET PRAIRIE",
      "state": "BC"
  },
  {
      "city": "SURGE NARROWS",
      "state": "BC"
  },
  {
      "city": "SURREY",
      "state": "BC"
  },
  {
      "city": "SUSSEX CORNER",
      "state": "NB"
  },
  {
      "city": "SUSSEX EAST",
      "state": "NB"
  },
  {
      "city": "SUSSEX SOUTH",
      "state": "NB"
  },
  {
      "city": "SUSSEX",
      "state": "NB"
  },
  {
      "city": "SUTTON WEST",
      "state": "ON"
  },
  {
      "city": "SUTTON",
      "state": "QC"
  },
  {
      "city": "SWALWELL",
      "state": "AB"
  },
  {
      "city": "SWAN CREEK",
      "state": "NB"
  },
  {
      "city": "SWAN HILLS",
      "state": "AB"
  },
  {
      "city": "SWAN LAKE",
      "state": "MB"
  },
  {
      "city": "SWAN RIVER",
      "state": "MB"
  },
  {
      "city": "SWANSEA POINT",
      "state": "BC"
  },
  {
      "city": "SWASTIKA",
      "state": "ON"
  },
  {
      "city": "SWEET BAY",
      "state": "NL"
  },
  {
      "city": "SWIFT CURRENT",
      "state": "NL"
  },
  {
      "city": "SWIFT CURRENT",
      "state": "SK"
  },
  {
      "city": "SYDENHAM",
      "state": "ON"
  },
  {
      "city": "SYDNEY FORKS",
      "state": "NS"
  },
  {
      "city": "SYDNEY MINES",
      "state": "NS"
  },
  {
      "city": "SYDNEY RIVER",
      "state": "NS"
  },
  {
      "city": "SYDNEY",
      "state": "NS"
  },
  {
      "city": "SYLVAN LAKE",
      "state": "AB"
  },
  {
      "city": "SYLVANIA",
      "state": "SK"
  },
  {
      "city": "SYPHER COVE",
      "state": "NB"
  },
  {
      "city": "TA TA CREEK",
      "state": "BC"
  },
  {
      "city": "TABER",
      "state": "AB"
  },
  {
      "city": "TABUSINTAC",
      "state": "NB"
  },
  {
      "city": "TADOULE LAKE",
      "state": "MB"
  },
  {
      "city": "TADOUSSAC",
      "state": "QC"
  },
  {
      "city": "TAGISH",
      "state": "YT"
  },
  {
      "city": "TAHSIS",
      "state": "BC"
  },
  {
      "city": "TAILLON",
      "state": "QC"
  },
  {
      "city": "TAKLA LANDING",
      "state": "BC"
  },
  {
      "city": "TALBOTVILLE ROYAL",
      "state": "ON"
  },
  {
      "city": "TALOYOAK",
      "state": "NU"
  },
  {
      "city": "TAMWORTH",
      "state": "ON"
  },
  {
      "city": "TANCOOK ISLAND",
      "state": "NS"
  },
  {
      "city": "TANGENT",
      "state": "AB"
  },
  {
      "city": "TANGIER",
      "state": "NS"
  },
  {
      "city": "TANTALLON",
      "state": "NS"
  },
  {
      "city": "TANTALLON",
      "state": "SK"
  },
  {
      "city": "TAPPEN",
      "state": "BC"
  },
  {
      "city": "TARA",
      "state": "ON"
  },
  {
      "city": "TARGETTVILLE",
      "state": "NB"
  },
  {
      "city": "TARZWELL",
      "state": "ON"
  },
  {
      "city": "TASCHEREAU",
      "state": "QC"
  },
  {
      "city": "TASIUJAQ",
      "state": "QC"
  },
  {
      "city": "TATAMAGOUCHE",
      "state": "NS"
  },
  {
      "city": "TATLA LAKE",
      "state": "BC"
  },
  {
      "city": "TATLAYOKO LAKE",
      "state": "BC"
  },
  {
      "city": "TAVISTOCK",
      "state": "ON"
  },
  {
      "city": "TAWATINAW",
      "state": "AB"
  },
  {
      "city": "TAXIS RIVER",
      "state": "NB"
  },
  {
      "city": "TAY CREEK",
      "state": "NB"
  },
  {
      "city": "TAY FALLS",
      "state": "NB"
  },
  {
      "city": "TAYLOR VILLAGE",
      "state": "NB"
  },
  {
      "city": "TAYLOR",
      "state": "BC"
  },
  {
      "city": "TAYMOUTH",
      "state": "NB"
  },
  {
      "city": "TECUMSEH",
      "state": "ON"
  },
  {
      "city": "TEEDS MILLS",
      "state": "NB"
  },
  {
      "city": "TEES",
      "state": "AB"
  },
  {
      "city": "TEESWATER",
      "state": "ON"
  },
  {
      "city": "TEETERVILLE",
      "state": "ON"
  },
  {
      "city": "TEHKUMMAH",
      "state": "ON"
  },
  {
      "city": "TELEGRAPH COVE",
      "state": "BC"
  },
  {
      "city": "TELEGRAPH CREEK",
      "state": "BC"
  },
  {
      "city": "TELKWA",
      "state": "BC"
  },
  {
      "city": "TEMAGAMI",
      "state": "ON"
  },
  {
      "city": "TEMISCAMING",
      "state": "QC"
  },
  {
      "city": "TEMPERANCE VALE",
      "state": "NB"
  },
  {
      "city": "TEMPLE",
      "state": "NB"
  },
  {
      "city": "TEMPLEMAN",
      "state": "NL"
  },
  {
      "city": "TERENCE BAY",
      "state": "NS"
  },
  {
      "city": "TERRA COTTA",
      "state": "ON"
  },
  {
      "city": "TERRACE BAY",
      "state": "ON"
  },
  {
      "city": "TERRACE",
      "state": "BC"
  },
  {
      "city": "TERRASSE-VAUDREUIL",
      "state": "QC"
  },
  {
      "city": "TERREBONNE",
      "state": "QC"
  },
  {
      "city": "TERRENCEVILLE",
      "state": "NL"
  },
  {
      "city": "TESLIN",
      "state": "YT"
  },
  {
      "city": "TESSIER",
      "state": "SK"
  },
  {
      "city": "TETAGOUCHE FALLS",
      "state": "NB"
  },
  {
      "city": "TETE-A-LA-BALEINE",
      "state": "QC"
  },
  {
      "city": "TEULON",
      "state": "MB"
  },
  {
      "city": "THAMESFORD",
      "state": "ON"
  },
  {
      "city": "THAMESVILLE",
      "state": "ON"
  },
  {
      "city": "THE GLADES",
      "state": "NB"
  },
  {
      "city": "THE PAS",
      "state": "MB"
  },
  {
      "city": "THEDFORD",
      "state": "ON"
  },
  {
      "city": "THEODORE",
      "state": "SK"
  },
  {
      "city": "THESSALON",
      "state": "ON"
  },
  {
      "city": "THETFORD MINES",
      "state": "QC"
  },
  {
      "city": "THETIS ISLAND",
      "state": "BC"
  },
  {
      "city": "THICKET PORTAGE",
      "state": "MB"
  },
  {
      "city": "THOMASBURG",
      "state": "ON"
  },
  {
      "city": "THOMASVILLE",
      "state": "NS"
  },
  {
      "city": "THOMPSON",
      "state": "MB"
  },
  {
      "city": "THORBURN",
      "state": "NS"
  },
  {
      "city": "THORHILD",
      "state": "AB"
  },
  {
      "city": "THORNBURY",
      "state": "ON"
  },
  {
      "city": "THORNDALE",
      "state": "ON"
  },
  {
      "city": "THORNE",
      "state": "ON"
  },
  {
      "city": "THORNHILL",
      "state": "BC"
  },
  {
      "city": "THORNHILL",
      "state": "MB"
  },
  {
      "city": "THORNHILL",
      "state": "ON"
  },
  {
      "city": "THORNLOE",
      "state": "ON"
  },
  {
      "city": "THORNTON",
      "state": "ON"
  },
  {
      "city": "THOROLD",
      "state": "ON"
  },
  {
      "city": "THORSBY",
      "state": "AB"
  },
  {
      "city": "THREE BROOKS",
      "state": "NB"
  },
  {
      "city": "THREE HILLS",
      "state": "AB"
  },
  {
      "city": "THREE TREE CREEK",
      "state": "NB"
  },
  {
      "city": "THUNDER BAY",
      "state": "ON"
  },
  {
      "city": "THURSO",
      "state": "QC"
  },
  {
      "city": "TICHBORNE",
      "state": "ON"
  },
  {
      "city": "TICKLE COVE",
      "state": "NL"
  },
  {
      "city": "TIDE HEAD",
      "state": "NB"
  },
  {
      "city": "TIGER LILY",
      "state": "AB"
  },
  {
      "city": "TIGNISH",
      "state": "PE"
  },
  {
      "city": "TILBURY",
      "state": "ON"
  },
  {
      "city": "TILDEN LAKE",
      "state": "ON"
  },
  {
      "city": "TILLEY ROAD",
      "state": "NB"
  },
  {
      "city": "TILLEY",
      "state": "AB"
  },
  {
      "city": "TILLEY",
      "state": "NB"
  },
  {
      "city": "TILLSONBURG",
      "state": "ON"
  },
  {
      "city": "TILSTON",
      "state": "MB"
  },
  {
      "city": "TILTING",
      "state": "NL"
  },
  {
      "city": "TIMBER BAY",
      "state": "SK"
  },
  {
      "city": "TIMBER RIVER",
      "state": "NB"
  },
  {
      "city": "TIMBERLEA",
      "state": "NS"
  },
  {
      "city": "TIMMINS",
      "state": "ON"
  },
  {
      "city": "TINGWICK",
      "state": "QC"
  },
  {
      "city": "TINKER",
      "state": "NB"
  },
  {
      "city": "TISDALE",
      "state": "SK"
  },
  {
      "city": "TITUSVILLE",
      "state": "NB"
  },
  {
      "city": "TIVERTON",
      "state": "NS"
  },
  {
      "city": "TIVERTON",
      "state": "ON"
  },
  {
      "city": "TIZZARDS HARBOUR",
      "state": "NL"
  },
  {
      "city": "TLELL",
      "state": "BC"
  },
  {
      "city": "TOAD RIVER",
      "state": "BC"
  },
  {
      "city": "TOBERMORY",
      "state": "ON"
  },
  {
      "city": "TOBIQUE FIRST NATION",
      "state": "NB"
  },
  {
      "city": "TOBIQUE NARROWS",
      "state": "NB"
  },
  {
      "city": "TOFIELD",
      "state": "AB"
  },
  {
      "city": "TOFINO",
      "state": "BC"
  },
  {
      "city": "TOGO",
      "state": "SK"
  },
  {
      "city": "TOLEDO",
      "state": "ON"
  },
  {
      "city": "TOLSTOI",
      "state": "MB"
  },
  {
      "city": "TOMAHAWK",
      "state": "AB"
  },
  {
      "city": "TOMPKINS",
      "state": "SK"
  },
  {
      "city": "TOMSLAKE",
      "state": "BC"
  },
  {
      "city": "TOPLEY",
      "state": "BC"
  },
  {
      "city": "TORBAY",
      "state": "NL"
  },
  {
      "city": "TORONTO",
      "state": "ON"
  },
  {
      "city": "TORQUAY",
      "state": "SK"
  },
  {
      "city": "TORRANCE",
      "state": "ON"
  },
  {
      "city": "TORRINGTON",
      "state": "AB"
  },
  {
      "city": "TORS COVE",
      "state": "NL"
  },
  {
      "city": "TORY HILL",
      "state": "ON"
  },
  {
      "city": "TOTTENHAM",
      "state": "ON"
  },
  {
      "city": "TOUROND",
      "state": "MB"
  },
  {
      "city": "TOURVILLE",
      "state": "QC"
  },
  {
      "city": "TOUTES AIDES",
      "state": "MB"
  },
  {
      "city": "TOWER HILL",
      "state": "NB"
  },
  {
      "city": "TOWER ROAD",
      "state": "NS"
  },
  {
      "city": "TOWNSEND",
      "state": "ON"
  },
  {
      "city": "TRACADIE BEACH",
      "state": "NB"
  },
  {
      "city": "TRACADIE-SHEILA",
      "state": "NB"
  },
  {
      "city": "TRACEY MILLS",
      "state": "NB"
  },
  {
      "city": "TRACY",
      "state": "NB"
  },
  {
      "city": "TRACYVILLE",
      "state": "NB"
  },
  {
      "city": "TRAIL",
      "state": "BC"
  },
  {
      "city": "TRAMPING LAKE",
      "state": "SK"
  },
  {
      "city": "TRAVERSE BAY",
      "state": "MB"
  },
  {
      "city": "TRAYTOWN",
      "state": "NL"
  },
  {
      "city": "TRECESSON",
      "state": "QC"
  },
  {
      "city": "TREHERNE",
      "state": "MB"
  },
  {
      "city": "TREMBLAY",
      "state": "NB"
  },
  {
      "city": "TRENT RIVER",
      "state": "ON"
  },
  {
      "city": "TRENTON",
      "state": "NS"
  },
  {
      "city": "TRENTON",
      "state": "ON"
  },
  {
      "city": "TREPASSEY",
      "state": "NL"
  },
  {
      "city": "TRES-SAINT-REDEMPTEUR",
      "state": "QC"
  },
  {
      "city": "TRIBUNE",
      "state": "SK"
  },
  {
      "city": "TRING-JONCTION",
      "state": "QC"
  },
  {
      "city": "TRINITY BB",
      "state": "NL"
  },
  {
      "city": "TRINITY TB",
      "state": "NL"
  },
  {
      "city": "TRITON",
      "state": "NL"
  },
  {
      "city": "TROCHU",
      "state": "AB"
  },
  {
      "city": "TROIS-PISTOLES",
      "state": "QC"
  },
  {
      "city": "TROIS-RIVES",
      "state": "QC"
  },
  {
      "city": "TROIS-RIVIERES",
      "state": "QC"
  },
  {
      "city": "TROIS-RUISSEAUX",
      "state": "NB"
  },
  {
      "city": "TROSSACHS",
      "state": "SK"
  },
  {
      "city": "TROUT BROOK",
      "state": "NB"
  },
  {
      "city": "TROUT CREEK",
      "state": "ON"
  },
  {
      "city": "TROUT LAKE",
      "state": "AB"
  },
  {
      "city": "TROUT LAKE",
      "state": "NT"
  },
  {
      "city": "TROUT RIVER",
      "state": "NL"
  },
  {
      "city": "TROY",
      "state": "NS"
  },
  {
      "city": "TROY",
      "state": "ON"
  },
  {
      "city": "TRUAX",
      "state": "SK"
  },
  {
      "city": "TRUDEL",
      "state": "NB"
  },
  {
      "city": "TRURO HEIGHTS",
      "state": "NS"
  },
  {
      "city": "TRURO",
      "state": "NS"
  },
  {
      "city": "TSAWWASSEN",
      "state": "BC"
  },
  {
      "city": "TSAY KEH DENE",
      "state": "BC"
  },
  {
      "city": "TSIIGEHTCHIC",
      "state": "NT"
  },
  {
      "city": "TSUU T'INA",
      "state": "AB"
  },
  {
      "city": "TUFFNELL",
      "state": "SK"
  },
  {
      "city": "TUGASKE",
      "state": "SK"
  },
  {
      "city": "TUKTOYAKTUK",
      "state": "NT"
  },
  {
      "city": "TULAMEEN",
      "state": "BC"
  },
  {
      "city": "TULITA",
      "state": "NT"
  },
  {
      "city": "TULLIBY LAKE",
      "state": "AB"
  },
  {
      "city": "TUMBLER RIDGE",
      "state": "BC"
  },
  {
      "city": "TUNIS",
      "state": "ON"
  },
  {
      "city": "TUPPERVILLE",
      "state": "ON"
  },
  {
      "city": "TURIN",
      "state": "AB"
  },
  {
      "city": "TURKEY POINT",
      "state": "ON"
  },
  {
      "city": "TURKS COVE",
      "state": "NL"
  },
  {
      "city": "TURNER VALLEY",
      "state": "AB"
  },
  {
      "city": "TURNOR LAKE",
      "state": "SK"
  },
  {
      "city": "TURTLE CREEK",
      "state": "NB"
  },
  {
      "city": "TURTLEFORD",
      "state": "SK"
  },
  {
      "city": "TUSKET",
      "state": "NS"
  },
  {
      "city": "TUXFORD",
      "state": "SK"
  },
  {
      "city": "TWAY",
      "state": "SK"
  },
  {
      "city": "TWEED",
      "state": "ON"
  },
  {
      "city": "TWILLINGATE",
      "state": "NL"
  },
  {
      "city": "TWIN BUTTE",
      "state": "AB"
  },
  {
      "city": "TWO BROOKS",
      "state": "NB"
  },
  {
      "city": "TWO HILLS",
      "state": "AB"
  },
  {
      "city": "TYNDALL",
      "state": "MB"
  },
  {
      "city": "TYNE VALLEY",
      "state": "PE"
  },
  {
      "city": "TYNEMOUTH CREEK",
      "state": "NB"
  },
  {
      "city": "TYNER",
      "state": "SK"
  },
  {
      "city": "TYVAN",
      "state": "SK"
  },
  {
      "city": "UCLUELET",
      "state": "BC"
  },
  {
      "city": "UDORA",
      "state": "ON"
  },
  {
      "city": "ULUKHAKTOK",
      "state": "NT"
  },
  {
      "city": "ULVERTON",
      "state": "QC"
  },
  {
      "city": "UMIUJAQ",
      "state": "QC"
  },
  {
      "city": "UNION BAY",
      "state": "BC"
  },
  {
      "city": "UNION CORNER",
      "state": "NB"
  },
  {
      "city": "UNION",
      "state": "ON"
  },
  {
      "city": "UNIONVILLE",
      "state": "ON"
  },
  {
      "city": "UNITY",
      "state": "SK"
  },
  {
      "city": "UPHAM",
      "state": "NB"
  },
  {
      "city": "UPPER BLACKVILLE",
      "state": "NB"
  },
  {
      "city": "UPPER BRANCH",
      "state": "NS"
  },
  {
      "city": "UPPER BRIGHTON",
      "state": "NB"
  },
  {
      "city": "UPPER BROOKSIDE",
      "state": "NS"
  },
  {
      "city": "UPPER CANADA VILLAGE",
      "state": "ON"
  },
  {
      "city": "UPPER CAPE",
      "state": "NB"
  },
  {
      "city": "UPPER CAVERHILL",
      "state": "NB"
  },
  {
      "city": "UPPER CHELSEA",
      "state": "NS"
  },
  {
      "city": "UPPER COVERDALE",
      "state": "NB"
  },
  {
      "city": "UPPER DERBY",
      "state": "NB"
  },
  {
      "city": "UPPER DORCHESTER",
      "state": "NB"
  },
  {
      "city": "UPPER FRASER",
      "state": "BC"
  },
  {
      "city": "UPPER GAGETOWN",
      "state": "NB"
  },
  {
      "city": "UPPER GOLDEN GROVE",
      "state": "NB"
  },
  {
      "city": "UPPER GRAND MIRA",
      "state": "NS"
  },
  {
      "city": "UPPER HAINESVILLE",
      "state": "NB"
  },
  {
      "city": "UPPER HAMMONDS PLAINS",
      "state": "NS"
  },
  {
      "city": "UPPER HAMPSTEAD",
      "state": "NB"
  },
  {
      "city": "UPPER ISLAND COVE",
      "state": "NL"
  },
  {
      "city": "UPPER KENNETCOOK",
      "state": "NS"
  },
  {
      "city": "UPPER KENT",
      "state": "NB"
  },
  {
      "city": "UPPER KESWICK",
      "state": "NB"
  },
  {
      "city": "UPPER KINGSCLEAR",
      "state": "NB"
  },
  {
      "city": "UPPER KINTORE",
      "state": "NB"
  },
  {
      "city": "UPPER KNOXFORD",
      "state": "NB"
  },
  {
      "city": "UPPER LAHAVE",
      "state": "NS"
  },
  {
      "city": "UPPER LEITCHES CREEK",
      "state": "NS"
  },
  {
      "city": "UPPER LETANG",
      "state": "NB"
  },
  {
      "city": "UPPER LOCH LOMOND",
      "state": "NB"
  },
  {
      "city": "UPPER MILLS",
      "state": "NB"
  },
  {
      "city": "UPPER MUSQUODOBOIT",
      "state": "NS"
  },
  {
      "city": "UPPER NINE MILE RIVER",
      "state": "NS"
  },
  {
      "city": "UPPER NORTH RIVER",
      "state": "NS"
  },
  {
      "city": "UPPER NORTH SYDNEY",
      "state": "NS"
  },
  {
      "city": "UPPER NORTHFIELD",
      "state": "NS"
  },
  {
      "city": "UPPER ONSLOW",
      "state": "NS"
  },
  {
      "city": "UPPER PORT LA TOUR",
      "state": "NS"
  },
  {
      "city": "UPPER QUEENSBURY",
      "state": "NB"
  },
  {
      "city": "UPPER RAWDON",
      "state": "NS"
  },
  {
      "city": "UPPER REXTON",
      "state": "NB"
  },
  {
      "city": "UPPER ROCKPORT",
      "state": "NB"
  },
  {
      "city": "UPPER SACKVILLE",
      "state": "NB"
  },
  {
      "city": "UPPER SACKVILLE",
      "state": "NS"
  },
  {
      "city": "UPPER SALMON CREEK",
      "state": "NB"
  },
  {
      "city": "UPPER STEWIACKE",
      "state": "NS"
  },
  {
      "city": "UPPER TANTALLON",
      "state": "NS"
  },
  {
      "city": "UPPER TRACY",
      "state": "NB"
  },
  {
      "city": "UPPER WASHABUCK",
      "state": "NS"
  },
  {
      "city": "UPPER WOODSTOCK",
      "state": "NB"
  },
  {
      "city": "UPPERTON",
      "state": "NB"
  },
  {
      "city": "UPSALA",
      "state": "ON"
  },
  {
      "city": "UPSALQUITCH",
      "state": "NB"
  },
  {
      "city": "UPTON",
      "state": "QC"
  },
  {
      "city": "URANIUM CITY",
      "state": "SK"
  },
  {
      "city": "URBANIA",
      "state": "NS"
  },
  {
      "city": "UTOPIA",
      "state": "NB"
  },
  {
      "city": "UTOPIA",
      "state": "ON"
  },
  {
      "city": "UTTERSON",
      "state": "ON"
  },
  {
      "city": "UXBRIDGE",
      "state": "ON"
  },
  {
      "city": "VAL CARON",
      "state": "ON"
  },
  {
      "city": "VAL COMEAU",
      "state": "NB"
  },
  {
      "city": "VAL COTE",
      "state": "ON"
  },
  {
      "city": "VAL GAGNE",
      "state": "ON"
  },
  {
      "city": "VAL MARIE",
      "state": "SK"
  },
  {
      "city": "VAL RITA",
      "state": "ON"
  },
  {
      "city": "VAL THERESE",
      "state": "ON"
  },
  {
      "city": "VAL-ALAIN",
      "state": "QC"
  },
  {
      "city": "VAL-BRILLANT",
      "state": "QC"
  },
  {
      "city": "VAL-D'AMOUR",
      "state": "NB"
  },
  {
      "city": "VAL-D'ESPOIR",
      "state": "QC"
  },
  {
      "city": "VAL-D'OR",
      "state": "QC"
  },
  {
      "city": "VAL-DAVID",
      "state": "QC"
  },
  {
      "city": "VAL-DES-BOIS",
      "state": "QC"
  },
  {
      "city": "VAL-DES-LACS",
      "state": "QC"
  },
  {
      "city": "VAL-DES-MONTS",
      "state": "QC"
  },
  {
      "city": "VAL-DOUCET",
      "state": "NB"
  },
  {
      "city": "VAL-JOLI",
      "state": "QC"
  },
  {
      "city": "VAL-MORIN",
      "state": "QC"
  },
  {
      "city": "VAL-PARADIS",
      "state": "QC"
  },
  {
      "city": "VAL-RACINE",
      "state": "QC"
  },
  {
      "city": "VAL-SAINT-GILLES",
      "state": "QC"
  },
  {
      "city": "VALCOURT",
      "state": "QC"
  },
  {
      "city": "VALEMOUNT",
      "state": "BC"
  },
  {
      "city": "VALHALLA CENTRE",
      "state": "AB"
  },
  {
      "city": "VALLEE-JONCTION",
      "state": "QC"
  },
  {
      "city": "VALLEY POND",
      "state": "NL"
  },
  {
      "city": "VALLEY RIVER",
      "state": "MB"
  },
  {
      "city": "VALLEY ROAD",
      "state": "NB"
  },
  {
      "city": "VALLEY",
      "state": "NS"
  },
  {
      "city": "VALLEYVIEW",
      "state": "AB"
  },
  {
      "city": "VAN ANDA",
      "state": "BC"
  },
  {
      "city": "VANCOUVER",
      "state": "BC"
  },
  {
      "city": "VANDERHOOF",
      "state": "BC"
  },
  {
      "city": "VANESSA",
      "state": "ON"
  },
  {
      "city": "VANGUARD",
      "state": "SK"
  },
  {
      "city": "VANIER",
      "state": "ON"
  },
  {
      "city": "VANKLEEK HILL",
      "state": "ON"
  },
  {
      "city": "VANSCOY",
      "state": "SK"
  },
  {
      "city": "VARENNES",
      "state": "QC"
  },
  {
      "city": "VARNA",
      "state": "ON"
  },
  {
      "city": "VARS",
      "state": "ON"
  },
  {
      "city": "VASSAR",
      "state": "MB"
  },
  {
      "city": "VAUDREUIL-DORION",
      "state": "QC"
  },
  {
      "city": "VAUDREUIL-SUR-LE-LAC",
      "state": "QC"
  },
  {
      "city": "VAUGHAN",
      "state": "ON"
  },
  {
      "city": "VAUXHALL",
      "state": "AB"
  },
  {
      "city": "VAVENBY",
      "state": "BC"
  },
  {
      "city": "VAWN",
      "state": "SK"
  },
  {
      "city": "VEGA",
      "state": "AB"
  },
  {
      "city": "VEGREVILLE",
      "state": "AB"
  },
  {
      "city": "VENISE-EN-QUEBEC",
      "state": "QC"
  },
  {
      "city": "VENOSTA",
      "state": "QC"
  },
  {
      "city": "VERCHERES",
      "state": "QC"
  },
  {
      "city": "VERDUN",
      "state": "QC"
  },
  {
      "city": "VEREGIN",
      "state": "SK"
  },
  {
      "city": "VERMETTE",
      "state": "MB"
  },
  {
      "city": "VERMILION BAY",
      "state": "ON"
  },
  {
      "city": "VERMILION",
      "state": "AB"
  },
  {
      "city": "VERNER",
      "state": "ON"
  },
  {
      "city": "VERNON BRIDGE",
      "state": "PE"
  },
  {
      "city": "VERNON",
      "state": "BC"
  },
  {
      "city": "VERNON",
      "state": "ON"
  },
  {
      "city": "VERONA",
      "state": "ON"
  },
  {
      "city": "VERRET",
      "state": "NB"
  },
  {
      "city": "VERWOOD",
      "state": "SK"
  },
  {
      "city": "VESPRA",
      "state": "NB"
  },
  {
      "city": "VETERAN",
      "state": "AB"
  },
  {
      "city": "VIBANK",
      "state": "SK"
  },
  {
      "city": "VICEROY",
      "state": "SK"
  },
  {
      "city": "VICTOIRE",
      "state": "SK"
  },
  {
      "city": "VICTORIA BEACH",
      "state": "MB"
  },
  {
      "city": "VICTORIA CB",
      "state": "NL"
  },
  {
      "city": "VICTORIA CORNER",
      "state": "NB"
  },
  {
      "city": "VICTORIA COVE",
      "state": "NL"
  },
  {
      "city": "VICTORIA HARBOUR",
      "state": "ON"
  },
  {
      "city": "VICTORIA MINES",
      "state": "NS"
  },
  {
      "city": "VICTORIA",
      "state": "BC"
  },
  {
      "city": "VICTORIA",
      "state": "PE"
  },
  {
      "city": "VICTORIAVILLE",
      "state": "QC"
  },
  {
      "city": "VIDORA",
      "state": "SK"
  },
  {
      "city": "VIENNA",
      "state": "ON"
  },
  {
      "city": "VIKING",
      "state": "AB"
  },
  {
      "city": "VILLAGE BLANCHARD",
      "state": "NB"
  },
  {
      "city": "VILLAGE SAINT-PIERRE",
      "state": "QC"
  },
  {
      "city": "VILLAGE-DES-POIRIER",
      "state": "NB"
  },
  {
      "city": "VILLAGE-SAINT-LAURENT",
      "state": "NB"
  },
  {
      "city": "VILLE-MARIE",
      "state": "QC"
  },
  {
      "city": "VILLEBOIS",
      "state": "QC"
  },
  {
      "city": "VILLEROY",
      "state": "QC"
  },
  {
      "city": "VILNA",
      "state": "AB"
  },
  {
      "city": "VIMONT",
      "state": "QC"
  },
  {
      "city": "VIMY",
      "state": "AB"
  },
  {
      "city": "VINEGAR HILL",
      "state": "NB"
  },
  {
      "city": "VINELAND STATION",
      "state": "ON"
  },
  {
      "city": "VINELAND",
      "state": "ON"
  },
  {
      "city": "VIRDEN",
      "state": "MB"
  },
  {
      "city": "VIRGIL",
      "state": "ON"
  },
  {
      "city": "VIRGINIATOWN",
      "state": "ON"
  },
  {
      "city": "VISCOUNT",
      "state": "SK"
  },
  {
      "city": "VISTA",
      "state": "MB"
  },
  {
      "city": "VITA",
      "state": "MB"
  },
  {
      "city": "VITTORIA",
      "state": "ON"
  },
  {
      "city": "VOGAR",
      "state": "MB"
  },
  {
      "city": "VONDA",
      "state": "SK"
  },
  {
      "city": "VULCAN",
      "state": "AB"
  },
  {
      "city": "WAASIS",
      "state": "NB"
  },
  {
      "city": "WABAMUN",
      "state": "AB"
  },
  {
      "city": "WABASCA",
      "state": "AB"
  },
  {
      "city": "WABIGOON",
      "state": "ON"
  },
  {
      "city": "WABOWDEN",
      "state": "MB"
  },
  {
      "city": "WABUSH",
      "state": "NL"
  },
  {
      "city": "WADENA",
      "state": "SK"
  },
  {
      "city": "WAGMATCOOK",
      "state": "NS"
  },
  {
      "city": "WAHNAPITAE",
      "state": "ON"
  },
  {
      "city": "WAINFLEET",
      "state": "ON"
  },
  {
      "city": "WAINWRIGHT",
      "state": "AB"
  },
  {
      "city": "WAKAW",
      "state": "SK"
  },
  {
      "city": "WAKEFIELD",
      "state": "NB"
  },
  {
      "city": "WAKEFIELD",
      "state": "QC"
  },
  {
      "city": "WALDECK",
      "state": "SK"
  },
  {
      "city": "WALDERSEE",
      "state": "MB"
  },
  {
      "city": "WALDHEIM",
      "state": "SK"
  },
  {
      "city": "WALDHOF",
      "state": "ON"
  },
  {
      "city": "WALDRON",
      "state": "SK"
  },
  {
      "city": "WALFORD STATION",
      "state": "ON"
  },
  {
      "city": "WALHACHIN",
      "state": "BC"
  },
  {
      "city": "WALKER SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "WALKERTON",
      "state": "ON"
  },
  {
      "city": "WALLACE",
      "state": "NS"
  },
  {
      "city": "WALLACEBURG",
      "state": "ON"
  },
  {
      "city": "WALLACETOWN",
      "state": "ON"
  },
  {
      "city": "WALLENSTEIN",
      "state": "ON"
  },
  {
      "city": "WALSH",
      "state": "AB"
  },
  {
      "city": "WALSINGHAM",
      "state": "ON"
  },
  {
      "city": "WALTERS FALLS",
      "state": "ON"
  },
  {
      "city": "WALTHAM",
      "state": "QC"
  },
  {
      "city": "WALTON",
      "state": "NS"
  },
  {
      "city": "WALTON",
      "state": "ON"
  },
  {
      "city": "WANDERING RIVER",
      "state": "AB"
  },
  {
      "city": "WANHAM",
      "state": "AB"
  },
  {
      "city": "WANIPIGOW",
      "state": "MB"
  },
  {
      "city": "WANLESS",
      "state": "MB"
  },
  {
      "city": "WAPELLA",
      "state": "SK"
  },
  {
      "city": "WAPSKE",
      "state": "NB"
  },
  {
      "city": "WARBURG",
      "state": "AB"
  },
  {
      "city": "WARD SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "WARDEN",
      "state": "QC"
  },
  {
      "city": "WARDLOW",
      "state": "AB"
  },
  {
      "city": "WARDNER",
      "state": "BC"
  },
  {
      "city": "WARDS CREEK",
      "state": "NB"
  },
  {
      "city": "WARDSVILLE",
      "state": "ON"
  },
  {
      "city": "WARE",
      "state": "BC"
  },
  {
      "city": "WAREHAM-CENTREVILLE",
      "state": "NL"
  },
  {
      "city": "WARKWORTH",
      "state": "ON"
  },
  {
      "city": "WARMAN",
      "state": "SK"
  },
  {
      "city": "WARMINSTER",
      "state": "ON"
  },
  {
      "city": "WARNER",
      "state": "AB"
  },
  {
      "city": "WARREN",
      "state": "MB"
  },
  {
      "city": "WARREN",
      "state": "ON"
  },
  {
      "city": "WARSAW",
      "state": "ON"
  },
  {
      "city": "WARSPITE",
      "state": "AB"
  },
  {
      "city": "WARWICK SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "WARWICK",
      "state": "QC"
  },
  {
      "city": "WASA",
      "state": "BC"
  },
  {
      "city": "WASAGA BEACH",
      "state": "ON"
  },
  {
      "city": "WASAGAMACK",
      "state": "MB"
  },
  {
      "city": "WASAGAMING",
      "state": "MB"
  },
  {
      "city": "WASECA",
      "state": "SK"
  },
  {
      "city": "WASHABUCK CENTRE",
      "state": "NS"
  },
  {
      "city": "WASHAGO",
      "state": "ON"
  },
  {
      "city": "WASKADA",
      "state": "MB"
  },
  {
      "city": "WASKAGANISH",
      "state": "QC"
  },
  {
      "city": "WASKATENAU",
      "state": "AB"
  },
  {
      "city": "WASKESIU LAKE",
      "state": "SK"
  },
  {
      "city": "WASWANIPI",
      "state": "QC"
  },
  {
      "city": "WATER VALLEY",
      "state": "AB"
  },
  {
      "city": "WATERBOROUGH",
      "state": "NB"
  },
  {
      "city": "WATERDOWN",
      "state": "ON"
  },
  {
      "city": "WATERFORD",
      "state": "NB"
  },
  {
      "city": "WATERFORD",
      "state": "ON"
  },
  {
      "city": "WATERHEN LAKE",
      "state": "SK"
  },
  {
      "city": "WATERHEN",
      "state": "MB"
  },
  {
      "city": "WATERLOO",
      "state": "NS"
  },
  {
      "city": "WATERLOO",
      "state": "ON"
  },
  {
      "city": "WATERLOO",
      "state": "QC"
  },
  {
      "city": "WATERSIDE",
      "state": "NB"
  },
  {
      "city": "WATERTON PARK",
      "state": "AB"
  },
  {
      "city": "WATERVILLE CARLETON CO",
      "state": "NB"
  },
  {
      "city": "WATERVILLE",
      "state": "NS"
  },
  {
      "city": "WATERVILLE",
      "state": "QC"
  },
  {
      "city": "WATERVILLE-SUNBURY",
      "state": "NB"
  },
  {
      "city": "WATFORD",
      "state": "ON"
  },
  {
      "city": "WATINO",
      "state": "AB"
  },
  {
      "city": "WATROUS",
      "state": "SK"
  },
  {
      "city": "WATSON LAKE",
      "state": "YT"
  },
  {
      "city": "WATSON",
      "state": "SK"
  },
  {
      "city": "WAUBAUSHENE",
      "state": "ON"
  },
  {
      "city": "WAUCHOPE",
      "state": "SK"
  },
  {
      "city": "WAVERLEY",
      "state": "NS"
  },
  {
      "city": "WAWA",
      "state": "ON"
  },
  {
      "city": "WAWANESA",
      "state": "MB"
  },
  {
      "city": "WAWEIG",
      "state": "NB"
  },
  {
      "city": "WAWOTA",
      "state": "SK"
  },
  {
      "city": "WAYERTON",
      "state": "NB"
  },
  {
      "city": "WAYWAYSEECAPPO",
      "state": "MB"
  },
  {
      "city": "WEAGAMOW LAKE",
      "state": "ON"
  },
  {
      "city": "WEAVER SIDING",
      "state": "NB"
  },
  {
      "city": "WEAVER",
      "state": "NB"
  },
  {
      "city": "WEBB",
      "state": "SK"
  },
  {
      "city": "WEBBWOOD",
      "state": "ON"
  },
  {
      "city": "WEBEQUIE",
      "state": "ON"
  },
  {
      "city": "WEDGEPORT",
      "state": "NS"
  },
  {
      "city": "WEEDON",
      "state": "QC"
  },
  {
      "city": "WEEKES",
      "state": "SK"
  },
  {
      "city": "WEIRDALE",
      "state": "SK"
  },
  {
      "city": "WEKWETI",
      "state": "NT"
  },
  {
      "city": "WELDON",
      "state": "NB"
  },
  {
      "city": "WELDON",
      "state": "SK"
  },
  {
      "city": "WELLAND",
      "state": "ON"
  },
  {
      "city": "WELLANDPORT",
      "state": "ON"
  },
  {
      "city": "WELLESLEY",
      "state": "ON"
  },
  {
      "city": "WELLING",
      "state": "AB"
  },
  {
      "city": "WELLINGTON STATION",
      "state": "PE"
  },
  {
      "city": "WELLINGTON",
      "state": "NS"
  },
  {
      "city": "WELLINGTON",
      "state": "ON"
  },
  {
      "city": "WELLS",
      "state": "BC"
  },
  {
      "city": "WELLWOOD",
      "state": "MB"
  },
  {
      "city": "WELSFORD",
      "state": "NB"
  },
  {
      "city": "WELSHPOOL",
      "state": "NB"
  },
  {
      "city": "WELWYN",
      "state": "SK"
  },
  {
      "city": "WEMBLEY",
      "state": "AB"
  },
  {
      "city": "WEMINDJI",
      "state": "QC"
  },
  {
      "city": "WEMOTACI",
      "state": "QC"
  },
  {
      "city": "WENDAKE",
      "state": "QC"
  },
  {
      "city": "WENDOVER",
      "state": "ON"
  },
  {
      "city": "WENTWORTH",
      "state": "NS"
  },
  {
      "city": "WENTWORTH",
      "state": "QC"
  },
  {
      "city": "WENTWORTH-NORD",
      "state": "QC"
  },
  {
      "city": "WENTZELLS LAKE",
      "state": "NS"
  },
  {
      "city": "WESLEYVILLE",
      "state": "NL"
  },
  {
      "city": "WEST ARICHAT",
      "state": "NS"
  },
  {
      "city": "WEST BAPTISTE",
      "state": "AB"
  },
  {
      "city": "WEST BAY CENTRE",
      "state": "NL"
  },
  {
      "city": "WEST BAY ROAD",
      "state": "NS"
  },
  {
      "city": "WEST BAY",
      "state": "NS"
  },
  {
      "city": "WEST BEND",
      "state": "SK"
  },
  {
      "city": "WEST BRANCH",
      "state": "NB"
  },
  {
      "city": "WEST BROME",
      "state": "QC"
  },
  {
      "city": "WEST CLIFFORD",
      "state": "NS"
  },
  {
      "city": "WEST DOVER",
      "state": "NS"
  },
  {
      "city": "WEST FLORENCEVILLE",
      "state": "NB"
  },
  {
      "city": "WEST GUILFORD",
      "state": "ON"
  },
  {
      "city": "WEST INDIAN ROAD",
      "state": "NS"
  },
  {
      "city": "WEST KELOWNA",
      "state": "BC"
  },
  {
      "city": "WEST LORNE",
      "state": "ON"
  },
  {
      "city": "WEST MONTROSE",
      "state": "ON"
  },
  {
      "city": "WEST NORTHFIELD",
      "state": "NS"
  },
  {
      "city": "WEST PENNANT",
      "state": "NS"
  },
  {
      "city": "WEST PORTERS LAKE",
      "state": "NS"
  },
  {
      "city": "WEST PUBNICO",
      "state": "NS"
  },
  {
      "city": "WEST QUACO",
      "state": "NB"
  },
  {
      "city": "WEST RIVER STATION",
      "state": "NS"
  },
  {
      "city": "WEST RIVER",
      "state": "NB"
  },
  {
      "city": "WEST ST ANDREWS",
      "state": "NS"
  },
  {
      "city": "WEST ST MODESTE",
      "state": "NL"
  },
  {
      "city": "WEST ST PAUL",
      "state": "MB"
  },
  {
      "city": "WEST VANCOUVER",
      "state": "BC"
  },
  {
      "city": "WESTBANK",
      "state": "BC"
  },
  {
      "city": "WESTBOURNE",
      "state": "MB"
  },
  {
      "city": "WESTBRIDGE",
      "state": "BC"
  },
  {
      "city": "WESTBROOK",
      "state": "ON"
  },
  {
      "city": "WESTBURY",
      "state": "QC"
  },
  {
      "city": "WESTCHESTER STATION",
      "state": "NS"
  },
  {
      "city": "WESTCOCK",
      "state": "NB"
  },
  {
      "city": "WESTERN BAY",
      "state": "NL"
  },
  {
      "city": "WESTERN SHORE",
      "state": "NS"
  },
  {
      "city": "WESTEROSE",
      "state": "AB"
  },
  {
      "city": "WESTHOLME",
      "state": "BC"
  },
  {
      "city": "WESTLOCK",
      "state": "AB"
  },
  {
      "city": "WESTMEATH",
      "state": "ON"
  },
  {
      "city": "WESTMOUNT",
      "state": "NS"
  },
  {
      "city": "WESTMOUNT",
      "state": "QC"
  },
  {
      "city": "WESTON",
      "state": "NB"
  },
  {
      "city": "WESTPHAL",
      "state": "NS"
  },
  {
      "city": "WESTPORT",
      "state": "NL"
  },
  {
      "city": "WESTPORT",
      "state": "NS"
  },
  {
      "city": "WESTPORT",
      "state": "ON"
  },
  {
      "city": "WESTVILLE",
      "state": "NS"
  },
  {
      "city": "WESTWOLD",
      "state": "BC"
  },
  {
      "city": "WESTWOOD",
      "state": "ON"
  },
  {
      "city": "WETASKIWIN",
      "state": "AB"
  },
  {
      "city": "WEYAKWIN",
      "state": "SK"
  },
  {
      "city": "WEYBURN",
      "state": "SK"
  },
  {
      "city": "WEYMOUTH",
      "state": "NS"
  },
  {
      "city": "WHALE COVE",
      "state": "NU"
  },
  {
      "city": "WHALETOWN",
      "state": "BC"
  },
  {
      "city": "WHATI",
      "state": "NT"
  },
  {
      "city": "WHEATLEY",
      "state": "ON"
  },
  {
      "city": "WHEATON SETTLEMENT",
      "state": "NB"
  },
  {
      "city": "WHELAN",
      "state": "SK"
  },
  {
      "city": "WHISPERING HILLS",
      "state": "AB"
  },
  {
      "city": "WHISTLER",
      "state": "BC"
  },
  {
      "city": "WHITBOURNE",
      "state": "NL"
  },
  {
      "city": "WHITBY",
      "state": "ON"
  },
  {
      "city": "WHITE BEAR",
      "state": "SK"
  },
  {
      "city": "WHITE CITY",
      "state": "SK"
  },
  {
      "city": "WHITE FOX",
      "state": "SK"
  },
  {
      "city": "WHITE GULL",
      "state": "AB"
  },
  {
      "city": "WHITE HEAD ISLAND",
      "state": "NB"
  },
  {
      "city": "WHITE HEAD",
      "state": "NB"
  },
  {
      "city": "WHITE LAKE",
      "state": "ON"
  },
  {
      "city": "WHITE RAPIDS",
      "state": "NB"
  },
  {
      "city": "WHITE RIVER",
      "state": "ON"
  },
  {
      "city": "WHITE ROCK",
      "state": "BC"
  },
  {
      "city": "WHITECAP",
      "state": "SK"
  },
  {
      "city": "WHITECOURT",
      "state": "AB"
  },
  {
      "city": "WHITEDOG",
      "state": "ON"
  },
  {
      "city": "WHITEFISH FALLS",
      "state": "ON"
  },
  {
      "city": "WHITEFISH",
      "state": "ON"
  },
  {
      "city": "WHITEHORSE",
      "state": "YT"
  },
  {
      "city": "WHITELAW",
      "state": "AB"
  },
  {
      "city": "WHITEMOUTH",
      "state": "MB"
  },
  {
      "city": "WHITES BROOK",
      "state": "NB"
  },
  {
      "city": "WHITES COVE",
      "state": "NB"
  },
  {
      "city": "WHITES LAKE",
      "state": "NS"
  },
  {
      "city": "WHITES MOUNTAIN",
      "state": "NB"
  },
  {
      "city": "WHITESHELL",
      "state": "MB"
  },
  {
      "city": "WHITEVALE",
      "state": "ON"
  },
  {
      "city": "WHITEWAY",
      "state": "NL"
  },
  {
      "city": "WHITEWOOD",
      "state": "SK"
  },
  {
      "city": "WHITNEY",
      "state": "NB"
  },
  {
      "city": "WHITNEY",
      "state": "ON"
  },
  {
      "city": "WHYCOCOMAGH",
      "state": "NS"
  },
  {
      "city": "WHYNOTTS SETTLEMENT",
      "state": "NS"
  },
  {
      "city": "WIARTON",
      "state": "ON"
  },
  {
      "city": "WICKHAM",
      "state": "NB"
  },
  {
      "city": "WICKHAM",
      "state": "QC"
  },
  {
      "city": "WICKLOW",
      "state": "NB"
  },
  {
      "city": "WIDEWATER",
      "state": "AB"
  },
  {
      "city": "WIGGINS MILL",
      "state": "NB"
  },
  {
      "city": "WIKWEMIKONG",
      "state": "ON"
  },
  {
      "city": "WILBERFORCE",
      "state": "ON"
  },
  {
      "city": "WILCOX",
      "state": "SK"
  },
  {
      "city": "WILD COVE WB",
      "state": "NL"
  },
  {
      "city": "WILDWOOD",
      "state": "AB"
  },
  {
      "city": "WILEVILLE",
      "state": "NS"
  },
  {
      "city": "WILKESPORT",
      "state": "ON"
  },
  {
      "city": "WILKIE",
      "state": "SK"
  },
  {
      "city": "WILLIAMS HARBOUR",
      "state": "NL"
  },
  {
      "city": "WILLIAMS LAKE",
      "state": "BC"
  },
  {
      "city": "WILLIAMSBURG",
      "state": "NB"
  },
  {
      "city": "WILLIAMSBURG",
      "state": "ON"
  },
  {
      "city": "WILLIAMSFORD",
      "state": "ON"
  },
  {
      "city": "WILLIAMSTOWN CARLETON CO",
      "state": "NB"
  },
  {
      "city": "WILLIAMSTOWN",
      "state": "NB"
  },
  {
      "city": "WILLIAMSTOWN",
      "state": "ON"
  },
  {
      "city": "WILLIAMSWOOD",
      "state": "NS"
  },
  {
      "city": "WILLINGDON",
      "state": "AB"
  },
  {
      "city": "WILLOW BEACH",
      "state": "ON"
  },
  {
      "city": "WILLOW BUNCH",
      "state": "SK"
  },
  {
      "city": "WILLOW GROVE",
      "state": "NB"
  },
  {
      "city": "WILLOW RIVER",
      "state": "BC"
  },
  {
      "city": "WILLOWBROOK",
      "state": "SK"
  },
  {
      "city": "WILMOT STATION",
      "state": "NS"
  },
  {
      "city": "WILMOT",
      "state": "NB"
  },
  {
      "city": "WILMOT",
      "state": "ON"
  },
  {
      "city": "WILNO",
      "state": "ON"
  },
  {
      "city": "WILSONS BEACH",
      "state": "NB"
  },
  {
      "city": "WILSONVILLE",
      "state": "ON"
  },
  {
      "city": "WILTONDALE",
      "state": "NL"
  },
  {
      "city": "WIMBORNE",
      "state": "AB"
  },
  {
      "city": "WINCHESTER SPRINGS",
      "state": "ON"
  },
  {
      "city": "WINCHESTER",
      "state": "ON"
  },
  {
      "city": "WINDERMERE",
      "state": "BC"
  },
  {
      "city": "WINDERMERE",
      "state": "ON"
  },
  {
      "city": "WINDHAM CENTRE",
      "state": "ON"
  },
  {
      "city": "WINDSOR JUNCTION",
      "state": "NS"
  },
  {
      "city": "WINDSOR",
      "state": "NB"
  },
  {
      "city": "WINDSOR",
      "state": "NS"
  },
  {
      "city": "WINDSOR",
      "state": "ON"
  },
  {
      "city": "WINDSOR",
      "state": "QC"
  },
  {
      "city": "WINDTHORST",
      "state": "SK"
  },
  {
      "city": "WINFIELD",
      "state": "AB"
  },
  {
      "city": "WINFIELD",
      "state": "BC"
  },
  {
      "city": "WINGHAM",
      "state": "ON"
  },
  {
      "city": "WINGS POINT",
      "state": "NL"
  },
  {
      "city": "WINKLER",
      "state": "MB"
  },
  {
      "city": "WINLAW",
      "state": "BC"
  },
  {
      "city": "WINNIPEG BEACH",
      "state": "MB"
  },
  {
      "city": "WINNIPEG",
      "state": "MB"
  },
  {
      "city": "WINNIPEGOSIS",
      "state": "MB"
  },
  {
      "city": "WINSLOE",
      "state": "PE"
  },
  {
      "city": "WINTER HARBOUR",
      "state": "BC"
  },
  {
      "city": "WINTERLAND",
      "state": "NL"
  },
  {
      "city": "WINTERTON",
      "state": "NL"
  },
  {
      "city": "WIRRAL",
      "state": "NB"
  },
  {
      "city": "WISETON",
      "state": "SK"
  },
  {
      "city": "WISHART",
      "state": "SK"
  },
  {
      "city": "WITLESS BAY",
      "state": "NL"
  },
  {
      "city": "WITTENBURG",
      "state": "NS"
  },
  {
      "city": "WOBURN",
      "state": "QC"
  },
  {
      "city": "WOKING",
      "state": "AB"
  },
  {
      "city": "WOLFE ISLAND",
      "state": "ON"
  },
  {
      "city": "WOLFVILLE",
      "state": "NS"
  },
  {
      "city": "WOLINAK",
      "state": "QC"
  },
  {
      "city": "WOLLASTON LAKE",
      "state": "SK"
  },
  {
      "city": "WOLSELEY",
      "state": "SK"
  },
  {
      "city": "WONOWON",
      "state": "BC"
  },
  {
      "city": "WOOD MOUNTAIN",
      "state": "SK"
  },
  {
      "city": "WOOD POINT",
      "state": "NB"
  },
  {
      "city": "WOODBRIDGE",
      "state": "ON"
  },
  {
      "city": "WOODFORDS",
      "state": "NL"
  },
  {
      "city": "WOODHAM",
      "state": "ON"
  },
  {
      "city": "WOODLANDS",
      "state": "MB"
  },
  {
      "city": "WOODLANDS",
      "state": "NB"
  },
  {
      "city": "WOODLAWN",
      "state": "ON"
  },
  {
      "city": "WOODMANS POINT",
      "state": "NB"
  },
  {
      "city": "WOODMORE",
      "state": "MB"
  },
  {
      "city": "WOODRIDGE",
      "state": "MB"
  },
  {
      "city": "WOODROW",
      "state": "SK"
  },
  {
      "city": "WOODSIDE",
      "state": "MB"
  },
  {
      "city": "WOODSIDE",
      "state": "NB"
  },
  {
      "city": "WOODSTOCK FIRST NATION",
      "state": "NB"
  },
  {
      "city": "WOODSTOCK",
      "state": "NB"
  },
  {
      "city": "WOODSTOCK",
      "state": "NL"
  },
  {
      "city": "WOODSTOCK",
      "state": "ON"
  },
  {
      "city": "WOODVIEW",
      "state": "ON"
  },
  {
      "city": "WOODVILLE",
      "state": "ON"
  },
  {
      "city": "WOOLER",
      "state": "ON"
  },
  {
      "city": "WORSLEY",
      "state": "AB"
  },
  {
      "city": "WORTHINGTON",
      "state": "ON"
  },
  {
      "city": "WOSS",
      "state": "BC"
  },
  {
      "city": "WOSTOK",
      "state": "AB"
  },
  {
      "city": "WOTTON",
      "state": "QC"
  },
  {
      "city": "WRENTHAM",
      "state": "AB"
  },
  {
      "city": "WRIGLEY",
      "state": "NT"
  },
  {
      "city": "WROXETER",
      "state": "ON"
  },
  {
      "city": "WROXTON",
      "state": "SK"
  },
  {
      "city": "WUHRS BEACH",
      "state": "NB"
  },
  {
      "city": "WUNNUMIN LAKE",
      "state": "ON"
  },
  {
      "city": "WYEBRIDGE",
      "state": "ON"
  },
  {
      "city": "WYERS BROOK",
      "state": "NB"
  },
  {
      "city": "WYEVALE",
      "state": "ON"
  },
  {
      "city": "WYMARK",
      "state": "SK"
  },
  {
      "city": "WYNNDEL",
      "state": "BC"
  },
  {
      "city": "WYNYARD",
      "state": "SK"
  },
  {
      "city": "WYOMING",
      "state": "ON"
  },
  {
      "city": "WYSES CORNER",
      "state": "NS"
  },
  {
      "city": "YAHK",
      "state": "BC"
  },
  {
      "city": "YALE",
      "state": "BC"
  },
  {
      "city": "YAMACHICHE",
      "state": "QC"
  },
  {
      "city": "YAMASKA",
      "state": "QC"
  },
  {
      "city": "YAMASKA-EST",
      "state": "QC"
  },
  {
      "city": "YARBO",
      "state": "SK"
  },
  {
      "city": "YARKER",
      "state": "ON"
  },
  {
      "city": "YARMOUTH",
      "state": "NS"
  },
  {
      "city": "YELLOW CREEK",
      "state": "SK"
  },
  {
      "city": "YELLOW GRASS",
      "state": "SK"
  },
  {
      "city": "YELLOW QUILL",
      "state": "SK"
  },
  {
      "city": "YELLOWKNIFE",
      "state": "NT"
  },
  {
      "city": "YMIR",
      "state": "BC"
  },
  {
      "city": "YOHO",
      "state": "NB"
  },
  {
      "city": "YORK HARBOUR",
      "state": "NL"
  },
  {
      "city": "YORK LANDING",
      "state": "MB"
  },
  {
      "city": "YORK",
      "state": "ON"
  },
  {
      "city": "YORK",
      "state": "PE"
  },
  {
      "city": "YORK.",
      "state": "ON"
  },
  {
      "city": "YORKTON",
      "state": "SK"
  },
  {
      "city": "YOUBOU",
      "state": "BC"
  },
  {
      "city": "YOUNG",
      "state": "SK"
  },
  {
      "city": "YOUNGS COVE",
      "state": "NB"
  },
  {
      "city": "YOUNGS POINT",
      "state": "ON"
  },
  {
      "city": "YOUNGSTOWN",
      "state": "AB"
  },
  {
      "city": "ZAMA CITY",
      "state": "AB"
  },
  {
      "city": "ZEALAND",
      "state": "NB"
  },
  {
      "city": "ZEALANDIA",
      "state": "SK"
  },
  {
      "city": "ZEBALLOS",
      "state": "BC"
  },
  {
      "city": "ZEHNER",
      "state": "SK"
  },
  {
      "city": "ZENON PARK",
      "state": "SK"
  },
  {
      "city": "ZEPHYR",
      "state": "ON"
  },
  {
      "city": "ZHODA",
      "state": "MB"
  },
  {
      "city": "ZIONVILLE",
      "state": "NB"
  },
  {
      "city": "ZURICH",
      "state": "ON"
  },
  {
      "zipCode": 501,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Holtsville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 544,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Holtsville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 601,
      "latitude": 18.165273,
      "longitude": -66.722583,
      "city": "Adjuntas",
      "state": "PR",
      "county": "Adjuntas"
  },
  {
      "zipCode": 602,
      "latitude": 18.393103,
      "longitude": -67.180953,
      "city": "Aguada",
      "state": "PR",
      "county": "Aguada"
  },
  {
      "zipCode": 603,
      "latitude": 18.455913,
      "longitude": -67.14578,
      "city": "Aguadilla",
      "state": "PR",
      "county": "Aguadilla"
  },
  {
      "zipCode": 604,
      "latitude": 18.49352,
      "longitude": -67.135883,
      "city": "Aguadilla",
      "state": "PR",
      "county": "Aguadilla"
  },
  {
      "zipCode": 605,
      "latitude": 18.465162,
      "longitude": -67.141486,
      "city": "Aguadilla",
      "state": "PR",
      "county": "Aguadilla"
  },
  {
      "zipCode": 606,
      "latitude": 18.172947,
      "longitude": -66.944111,
      "city": "Maricao",
      "state": "PR",
      "county": "Maricao"
  },
  {
      "zipCode": 610,
      "latitude": 18.288685,
      "longitude": -67.139696,
      "city": "Anasco",
      "state": "PR",
      "county": "Anasco"
  },
  {
      "zipCode": 611,
      "latitude": 18.279531,
      "longitude": -66.80217,
      "city": "Angeles",
      "state": "PR",
      "county": "Utuado"
  },
  {
      "zipCode": 612,
      "latitude": 18.450674,
      "longitude": -66.698262,
      "city": "Arecibo",
      "state": "PR",
      "county": "Arecibo"
  },
  {
      "zipCode": 613,
      "latitude": 18.458093,
      "longitude": -66.732732,
      "city": "Arecibo",
      "state": "PR",
      "county": "Arecibo"
  },
  {
      "zipCode": 614,
      "latitude": 18.429675,
      "longitude": -66.674506,
      "city": "Arecibo",
      "state": "PR",
      "county": "Arecibo"
  },
  {
      "zipCode": 616,
      "latitude": 18.444792,
      "longitude": -66.640678,
      "city": "Bajadero",
      "state": "PR",
      "county": "Arecibo"
  },
  {
      "zipCode": 617,
      "latitude": 18.447092,
      "longitude": -66.544255,
      "city": "Barceloneta",
      "state": "PR",
      "county": "Barceloneta"
  },
  {
      "zipCode": 622,
      "latitude": 17.998531,
      "longitude": -67.187318,
      "city": "Boqueron",
      "state": "PR",
      "county": "Cabo Rojo"
  },
  {
      "zipCode": 623,
      "latitude": 18.062201,
      "longitude": -67.149541,
      "city": "Cabo Rojo",
      "state": "PR",
      "county": "Cabo Rojo"
  },
  {
      "zipCode": 624,
      "latitude": 18.023535,
      "longitude": -66.726156,
      "city": "Penuelas",
      "state": "PR",
      "county": "Penuelas"
  },
  {
      "zipCode": 627,
      "latitude": 18.477891,
      "longitude": -66.85477,
      "city": "Camuy",
      "state": "PR",
      "county": "Camuy"
  },
  {
      "zipCode": 631,
      "latitude": 18.269187,
      "longitude": -66.864993,
      "city": "Castaner",
      "state": "PR",
      "county": "Lares"
  },
  {
      "zipCode": 636,
      "latitude": 18.113284,
      "longitude": -67.039706,
      "city": "Rosario",
      "state": "PR",
      "county": "San German"
  },
  {
      "zipCode": 637,
      "latitude": 18.087322,
      "longitude": -66.934911,
      "city": "Sabana Grande",
      "state": "PR",
      "county": "Sabana Grande"
  },
  {
      "zipCode": 638,
      "latitude": 18.33616,
      "longitude": -66.472087,
      "city": "Ciales",
      "state": "PR",
      "county": "Ciales"
  },
  {
      "zipCode": 641,
      "latitude": 18.250027,
      "longitude": -66.698957,
      "city": "Utuado",
      "state": "PR",
      "county": "Utuado"
  },
  {
      "zipCode": 646,
      "latitude": 18.43606,
      "longitude": -66.281954,
      "city": "Dorado",
      "state": "PR",
      "county": "Dorado"
  },
  {
      "zipCode": 647,
      "latitude": 17.969594,
      "longitude": -66.939754,
      "city": "Ensenada",
      "state": "PR",
      "county": "Guanica"
  },
  {
      "zipCode": 650,
      "latitude": 18.360125,
      "longitude": -66.562311,
      "city": "Florida",
      "state": "PR",
      "county": "Florida"
  },
  {
      "zipCode": 652,
      "latitude": 18.457254,
      "longitude": -66.603358,
      "city": "Garrochales",
      "state": "PR",
      "county": "Arecibo"
  },
  {
      "zipCode": 653,
      "latitude": 17.972468,
      "longitude": -66.898661,
      "city": "Guanica",
      "state": "PR",
      "county": "Guanica"
  },
  {
      "zipCode": 656,
      "latitude": 18.02328,
      "longitude": -66.786909,
      "city": "Guayanilla",
      "state": "PR",
      "county": "Guayanilla"
  },
  {
      "zipCode": 659,
      "latitude": 18.481391,
      "longitude": -66.808696,
      "city": "Hatillo",
      "state": "PR",
      "county": "Hatillo"
  },
  {
      "zipCode": 660,
      "latitude": 18.145486,
      "longitude": -67.135323,
      "city": "Hormigueros",
      "state": "PR",
      "county": "Hormigueros"
  },
  {
      "zipCode": 662,
      "latitude": 18.480285,
      "longitude": -67.028155,
      "city": "Isabela",
      "state": "PR",
      "county": "Isabela"
  },
  {
      "zipCode": 664,
      "latitude": 18.217606,
      "longitude": -66.598306,
      "city": "Jayuya",
      "state": "PR",
      "county": "Jayuya"
  },
  {
      "zipCode": 667,
      "latitude": 18.015099,
      "longitude": -67.055973,
      "city": "Lajas",
      "state": "PR",
      "county": "Lajas"
  },
  {
      "zipCode": 669,
      "latitude": 18.295952,
      "longitude": -66.881925,
      "city": "Lares",
      "state": "PR",
      "county": "Lares"
  },
  {
      "zipCode": 670,
      "latitude": 18.252135,
      "longitude": -66.990967,
      "city": "Las Marias",
      "state": "PR",
      "county": "Las Marias"
  },
  {
      "zipCode": 674,
      "latitude": 18.44553,
      "longitude": -66.490268,
      "city": "Manati",
      "state": "PR",
      "county": "Manati"
  },
  {
      "zipCode": 676,
      "latitude": 18.383727,
      "longitude": -67.080799,
      "city": "Moca",
      "state": "PR",
      "county": "Moca"
  },
  {
      "zipCode": 677,
      "latitude": 18.335781,
      "longitude": -67.252547,
      "city": "Rincon",
      "state": "PR",
      "county": "Rincon"
  },
  {
      "zipCode": 678,
      "latitude": 18.45303,
      "longitude": -66.933689,
      "city": "Quebradillas",
      "state": "PR",
      "county": "Quebradillas"
  },
  {
      "zipCode": 680,
      "latitude": 18.219023,
      "longitude": -67.508068,
      "city": "Mayaguez",
      "state": "PR",
      "county": "Mayaguez"
  },
  {
      "zipCode": 681,
      "latitude": 18.219023,
      "longitude": -67.508068,
      "city": "Mayaguez",
      "state": "PR",
      "county": "Mayaguez"
  },
  {
      "zipCode": 682,
      "latitude": 18.219023,
      "longitude": -67.508068,
      "city": "Mayaguez",
      "state": "PR",
      "county": "Mayaguez"
  },
  {
      "zipCode": 683,
      "latitude": 18.113284,
      "longitude": -67.039706,
      "city": "San German",
      "state": "PR",
      "county": "San German"
  },
  {
      "zipCode": 685,
      "latitude": 18.3236,
      "longitude": -66.972227,
      "city": "San Sebastian",
      "state": "PR",
      "county": "San Sebastian"
  },
  {
      "zipCode": 687,
      "latitude": 18.316976,
      "longitude": -66.415207,
      "city": "Morovis",
      "state": "PR",
      "county": "Morovis"
  },
  {
      "zipCode": 688,
      "latitude": 18.429675,
      "longitude": -66.674506,
      "city": "Sabana Hoyos",
      "state": "PR",
      "county": "Arecibo"
  },
  {
      "zipCode": 690,
      "latitude": 18.476196,
      "longitude": -67.139597,
      "city": "San Antonio",
      "state": "PR",
      "county": "Aguadilla"
  },
  {
      "zipCode": 692,
      "latitude": 18.435818,
      "longitude": -66.338523,
      "city": "Vega Alta",
      "state": "PR",
      "county": "Vega Alta"
  },
  {
      "zipCode": 693,
      "latitude": 18.443109,
      "longitude": -66.398302,
      "city": "Vega Baja",
      "state": "PR",
      "county": "Vega Baja"
  },
  {
      "zipCode": 694,
      "latitude": 18.443109,
      "longitude": -66.398302,
      "city": "Vega Baja",
      "state": "PR",
      "county": "Vega Baja"
  },
  {
      "zipCode": 698,
      "latitude": 18.06104,
      "longitude": -66.861164,
      "city": "Yauco",
      "state": "PR",
      "county": "Yauco"
  },
  {
      "zipCode": 703,
      "latitude": 18.250398,
      "longitude": -66.12845,
      "city": "Aguas Buenas",
      "state": "PR",
      "county": "Aguas Buenas"
  },
  {
      "zipCode": 704,
      "latitude": 17.99229,
      "longitude": -66.139253,
      "city": "Aguirre",
      "state": "PR",
      "county": "Guayama"
  },
  {
      "zipCode": 705,
      "latitude": 18.128092,
      "longitude": -66.268717,
      "city": "Aibonito",
      "state": "PR",
      "county": "Aibonito"
  },
  {
      "zipCode": 707,
      "latitude": 18.007014,
      "longitude": -65.898719,
      "city": "Maunabo",
      "state": "PR",
      "county": "Maunabo"
  },
  {
      "zipCode": 714,
      "latitude": 17.96751,
      "longitude": -66.04138,
      "city": "Arroyo",
      "state": "PR",
      "county": "Arroyo"
  },
  {
      "zipCode": 715,
      "latitude": 18.011854,
      "longitude": -66.563721,
      "city": "Mercedita",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 716,
      "latitude": 18.001995,
      "longitude": -66.607429,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 717,
      "latitude": 18.001995,
      "longitude": -66.607429,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 718,
      "latitude": 18.22139,
      "longitude": -65.77831,
      "city": "Naguabo",
      "state": "PR",
      "county": "Naguabo"
  },
  {
      "zipCode": 719,
      "latitude": 18.298017,
      "longitude": -66.229751,
      "city": "Naranjito",
      "state": "PR",
      "county": "Naranjito"
  },
  {
      "zipCode": 720,
      "latitude": 18.226862,
      "longitude": -66.39402,
      "city": "Orocovis",
      "state": "PR",
      "county": "Orocovis"
  },
  {
      "zipCode": 721,
      "latitude": 18.364161,
      "longitude": -65.778288,
      "city": "Palmer",
      "state": "PR",
      "county": "Rio Grande"
  },
  {
      "zipCode": 723,
      "latitude": 18.009994,
      "longitude": -66.010572,
      "city": "Patillas",
      "state": "PR",
      "county": "Patillas"
  },
  {
      "zipCode": 725,
      "latitude": 18.212965,
      "longitude": -66.058033,
      "city": "Caguas",
      "state": "PR",
      "county": "Caguas"
  },
  {
      "zipCode": 726,
      "latitude": 18.212965,
      "longitude": -66.058033,
      "city": "Caguas",
      "state": "PR",
      "county": "Caguas"
  },
  {
      "zipCode": 727,
      "latitude": 18.212965,
      "longitude": -66.058033,
      "city": "Caguas",
      "state": "PR",
      "county": "Caguas"
  },
  {
      "zipCode": 728,
      "latitude": 18.001995,
      "longitude": -66.607429,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 729,
      "latitude": 18.333093,
      "longitude": -65.883864,
      "city": "Canovanas",
      "state": "PR",
      "county": "Canovanas"
  },
  {
      "zipCode": 730,
      "latitude": 18.001995,
      "longitude": -66.607429,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 731,
      "latitude": 18.03831,
      "longitude": -66.626344,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 732,
      "latitude": 18.021781,
      "longitude": -66.613742,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 733,
      "latitude": 18.019331,
      "longitude": -66.619165,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 734,
      "latitude": 17.999499,
      "longitude": -66.643934,
      "city": "Ponce",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 735,
      "latitude": 18.264872,
      "longitude": -65.594769,
      "city": "Ceiba",
      "state": "PR",
      "county": "Ceiba"
  },
  {
      "zipCode": 736,
      "latitude": 18.102967,
      "longitude": -66.139274,
      "city": "Cayey",
      "state": "PR",
      "county": "Cayey"
  },
  {
      "zipCode": 737,
      "latitude": 18.102967,
      "longitude": -66.139274,
      "city": "Cayey",
      "state": "PR",
      "county": "Cayey"
  },
  {
      "zipCode": 738,
      "latitude": 18.359381,
      "longitude": -65.611361,
      "city": "Fajardo",
      "state": "PR",
      "county": "Fajardo"
  },
  {
      "zipCode": 739,
      "latitude": 18.181469,
      "longitude": -66.169519,
      "city": "Cidra",
      "state": "PR",
      "county": "Cidra"
  },
  {
      "zipCode": 740,
      "latitude": 18.331958,
      "longitude": -65.63878,
      "city": "Puerto Real",
      "state": "PR",
      "county": "Fajardo"
  },
  {
      "zipCode": 741,
      "latitude": 18.162156,
      "longitude": -65.753485,
      "city": "Punta Santiago",
      "state": "PR",
      "county": "Humacao"
  },
  {
      "zipCode": 742,
      "latitude": 18.264872,
      "longitude": -65.594769,
      "city": "Roosevelt Roads",
      "state": "PR",
      "county": "Ceiba"
  },
  {
      "zipCode": 744,
      "latitude": 18.206329,
      "longitude": -65.743058,
      "city": "Rio Blanco",
      "state": "PR",
      "county": "Naguabo"
  },
  {
      "zipCode": 745,
      "latitude": 18.367347,
      "longitude": -65.816583,
      "city": "Rio Grande",
      "state": "PR",
      "county": "Rio Grande"
  },
  {
      "zipCode": 751,
      "latitude": 17.99852,
      "longitude": -66.264825,
      "city": "Salinas",
      "state": "PR",
      "county": "Salinas"
  },
  {
      "zipCode": 754,
      "latitude": 18.187584,
      "longitude": -65.962389,
      "city": "San Lorenzo",
      "state": "PR",
      "county": "San Lorenzo"
  },
  {
      "zipCode": 757,
      "latitude": 17.991233,
      "longitude": -66.395543,
      "city": "Santa Isabel",
      "state": "PR",
      "county": "Santa Isabel"
  },
  {
      "zipCode": 765,
      "latitude": 18.123347,
      "longitude": -65.460356,
      "city": "Vieques",
      "state": "PR",
      "county": "Vieques"
  },
  {
      "zipCode": 766,
      "latitude": 18.120519,
      "longitude": -66.496607,
      "city": "Villalba",
      "state": "PR",
      "county": "Villalba"
  },
  {
      "zipCode": 767,
      "latitude": 18.045522,
      "longitude": -65.886988,
      "city": "Yabucoa",
      "state": "PR",
      "county": "Yabucoa"
  },
  {
      "zipCode": 769,
      "latitude": 18.095992,
      "longitude": -66.35081,
      "city": "Coamo",
      "state": "PR",
      "county": "Coamo"
  },
  {
      "zipCode": 771,
      "latitude": 18.196576,
      "longitude": -65.87197,
      "city": "Las Piedras",
      "state": "PR",
      "county": "Las Piedras"
  },
  {
      "zipCode": 772,
      "latitude": 18.447406,
      "longitude": -65.899357,
      "city": "Loiza",
      "state": "PR",
      "county": "Loiza"
  },
  {
      "zipCode": 773,
      "latitude": 18.366027,
      "longitude": -65.708138,
      "city": "Luquillo",
      "state": "PR",
      "county": "Luquillo"
  },
  {
      "zipCode": 775,
      "latitude": 18.323736,
      "longitude": -65.296467,
      "city": "Culebra",
      "state": "PR",
      "county": "Culebra"
  },
  {
      "zipCode": 777,
      "latitude": 18.206489,
      "longitude": -65.901774,
      "city": "Juncos",
      "state": "PR",
      "county": "Juncos"
  },
  {
      "zipCode": 778,
      "latitude": 18.259092,
      "longitude": -65.974641,
      "city": "Gurabo",
      "state": "PR",
      "county": "Gurabo"
  },
  {
      "zipCode": 780,
      "latitude": 18.001995,
      "longitude": -66.607429,
      "city": "Coto Laurel",
      "state": "PR",
      "county": "Ponce"
  },
  {
      "zipCode": 782,
      "latitude": 18.224958,
      "longitude": -66.219885,
      "city": "Comerio",
      "state": "PR",
      "county": "Comerio"
  },
  {
      "zipCode": 783,
      "latitude": 18.304369,
      "longitude": -66.330525,
      "city": "Corozal",
      "state": "PR",
      "county": "Corozal"
  },
  {
      "zipCode": 784,
      "latitude": 17.99229,
      "longitude": -66.139253,
      "city": "Guayama",
      "state": "PR",
      "county": "Guayama"
  },
  {
      "zipCode": 785,
      "latitude": 18.018822,
      "longitude": -66.795603,
      "city": "Guayama",
      "state": "PR",
      "county": "Guayanilla"
  },
  {
      "zipCode": 786,
      "latitude": 18.128092,
      "longitude": -66.268717,
      "city": "La Plata",
      "state": "PR",
      "county": "Aibonito"
  },
  {
      "zipCode": 791,
      "latitude": 18.138029,
      "longitude": -65.788499,
      "city": "Humacao",
      "state": "PR",
      "county": "Humacao"
  },
  {
      "zipCode": 792,
      "latitude": 18.138029,
      "longitude": -65.788499,
      "city": "Humacao",
      "state": "PR",
      "county": "Humacao"
  },
  {
      "zipCode": 794,
      "latitude": 18.200898,
      "longitude": -66.307236,
      "city": "Barranquitas",
      "state": "PR",
      "county": "Barranquitas"
  },
  {
      "zipCode": 795,
      "latitude": 17.997367,
      "longitude": -66.493218,
      "city": "Juana Diaz",
      "state": "PR",
      "county": "Juana Diaz"
  },
  {
      "zipCode": 801,
      "latitude": 18.322285,
      "longitude": -64.963715,
      "city": "St Thomas",
      "state": "VI",
      "county": "Saint Thomas"
  },
  {
      "zipCode": 802,
      "latitude": 18.322285,
      "longitude": -64.963715,
      "city": "St Thomas",
      "state": "VI",
      "county": "Saint Thomas"
  },
  {
      "zipCode": 803,
      "latitude": 18.322285,
      "longitude": -64.963715,
      "city": "St Thomas",
      "state": "VI",
      "county": "Saint Thomas"
  },
  {
      "zipCode": 804,
      "latitude": 18.322285,
      "longitude": -64.963715,
      "city": "St Thomas",
      "state": "VI",
      "county": "Saint Thomas"
  },
  {
      "zipCode": 805,
      "latitude": 18.322285,
      "longitude": -64.963715,
      "city": "St Thomas",
      "state": "VI",
      "county": "Saint Thomas"
  },
  {
      "zipCode": 820,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Christiansted",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 821,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Christiansted",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 822,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Christiansted",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 823,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Christiansted",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 824,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Christiansted",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 830,
      "latitude": 18.32816,
      "longitude": -64.740737,
      "city": "St John",
      "state": "VI",
      "county": "Saint John"
  },
  {
      "zipCode": 831,
      "latitude": 18.32816,
      "longitude": -64.740737,
      "city": "St John",
      "state": "VI",
      "county": "Saint John"
  },
  {
      "zipCode": 840,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Frederiksted",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 841,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Frederiksted",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 850,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Kingshill",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 851,
      "latitude": 17.734211,
      "longitude": -64.734694,
      "city": "Kingshill",
      "state": "VI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 901,
      "latitude": 18.465901,
      "longitude": -66.103568,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 902,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 906,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 907,
      "latitude": 18.451647,
      "longitude": -66.077003,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 908,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 909,
      "latitude": 18.443384,
      "longitude": -66.068133,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 910,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 911,
      "latitude": 18.450828,
      "longitude": -66.057859,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 912,
      "latitude": 18.444187,
      "longitude": -66.059859,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 913,
      "latitude": 18.450735,
      "longitude": -66.043352,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 914,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 915,
      "latitude": 18.437896,
      "longitude": -66.048087,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 916,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 917,
      "latitude": 18.422018,
      "longitude": -66.050602,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 918,
      "latitude": 18.419069,
      "longitude": -66.062319,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 919,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 920,
      "latitude": 18.406808,
      "longitude": -66.091885,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 921,
      "latitude": 18.390429,
      "longitude": -66.066124,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 922,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 923,
      "latitude": 18.410775,
      "longitude": -66.036708,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 924,
      "latitude": 18.396392,
      "longitude": -66.01146,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 925,
      "latitude": 18.401055,
      "longitude": -66.049773,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 926,
      "latitude": 18.369376,
      "longitude": -66.062804,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 927,
      "latitude": 18.385174,
      "longitude": -66.071959,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 928,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 929,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 930,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 931,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 933,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 934,
      "latitude": 18.34487,
      "longitude": -66.166014,
      "city": "Fort Buchanan",
      "state": "PR",
      "county": "Bayamon"
  },
  {
      "zipCode": 935,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 936,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 937,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 938,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 939,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 940,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 949,
      "latitude": 18.431911,
      "longitude": -66.199692,
      "city": "Toa Baja",
      "state": "PR",
      "county": "Toa Baja"
  },
  {
      "zipCode": 950,
      "latitude": 18.457761,
      "longitude": -66.196503,
      "city": "Toa Baja",
      "state": "PR",
      "county": "Toa Baja"
  },
  {
      "zipCode": 951,
      "latitude": 18.457761,
      "longitude": -66.196503,
      "city": "Toa Baja",
      "state": "PR",
      "county": "Toa Baja"
  },
  {
      "zipCode": 952,
      "latitude": 18.457761,
      "longitude": -66.196503,
      "city": "Sabana Seca",
      "state": "PR",
      "county": "Toa Baja"
  },
  {
      "zipCode": 953,
      "latitude": 18.356795,
      "longitude": -66.25701,
      "city": "Toa Alta",
      "state": "PR",
      "county": "Toa Alta"
  },
  {
      "zipCode": 954,
      "latitude": 18.358939,
      "longitude": -66.258108,
      "city": "Toa Alta",
      "state": "PR",
      "county": "Toa Alta"
  },
  {
      "zipCode": 955,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 956,
      "latitude": 18.326702,
      "longitude": -66.174176,
      "city": "Bayamon",
      "state": "PR",
      "county": "Bayamon"
  },
  {
      "zipCode": 957,
      "latitude": 18.366645,
      "longitude": -66.183907,
      "city": "Bayamon",
      "state": "PR",
      "county": "Bayamon"
  },
  {
      "zipCode": 958,
      "latitude": 18.34487,
      "longitude": -66.166014,
      "city": "Bayamon",
      "state": "PR",
      "county": "Bayamon"
  },
  {
      "zipCode": 959,
      "latitude": 18.387058,
      "longitude": -66.159044,
      "city": "Bayamon",
      "state": "PR",
      "county": "Bayamon"
  },
  {
      "zipCode": 960,
      "latitude": 18.34487,
      "longitude": -66.166014,
      "city": "Bayamon",
      "state": "PR",
      "county": "Bayamon"
  },
  {
      "zipCode": 961,
      "latitude": 18.413195,
      "longitude": -66.168651,
      "city": "Bayamon",
      "state": "PR",
      "county": "Bayamon"
  },
  {
      "zipCode": 962,
      "latitude": 18.437644,
      "longitude": -66.140407,
      "city": "Catano",
      "state": "PR",
      "county": "Catano"
  },
  {
      "zipCode": 963,
      "latitude": 18.443217,
      "longitude": -66.139293,
      "city": "Catano",
      "state": "PR",
      "county": "Catano"
  },
  {
      "zipCode": 965,
      "latitude": 18.428784,
      "longitude": -66.11539,
      "city": "Guaynabo",
      "state": "PR",
      "county": "Guaynabo"
  },
  {
      "zipCode": 966,
      "latitude": 18.396815,
      "longitude": -66.114889,
      "city": "Guaynabo",
      "state": "PR",
      "county": "Guaynabo"
  },
  {
      "zipCode": 968,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "Guaynabo",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 969,
      "latitude": 18.362322,
      "longitude": -66.113729,
      "city": "Guaynabo",
      "state": "PR",
      "county": "Guaynabo"
  },
  {
      "zipCode": 970,
      "latitude": 18.359143,
      "longitude": -66.112295,
      "city": "Guaynabo",
      "state": "PR",
      "county": "Guaynabo"
  },
  {
      "zipCode": 971,
      "latitude": 18.30942,
      "longitude": -66.113606,
      "city": "Guaynabo",
      "state": "PR",
      "county": "Guaynabo"
  },
  {
      "zipCode": 975,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "San Juan",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 976,
      "latitude": 18.338133,
      "longitude": -65.989229,
      "city": "Trujillo Alto",
      "state": "PR",
      "county": "Trujillo Alto"
  },
  {
      "zipCode": 977,
      "latitude": 18.337004,
      "longitude": -65.990099,
      "city": "Trujillo Alto",
      "state": "PR",
      "county": "Trujillo Alto"
  },
  {
      "zipCode": 978,
      "latitude": 18.337004,
      "longitude": -65.990099,
      "city": "Saint Just Contract",
      "state": "PR",
      "county": "Trujillo Alto"
  },
  {
      "zipCode": 979,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "Carolina",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 981,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "Carolina",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 982,
      "latitude": 18.410462,
      "longitude": -66.060533,
      "city": "Carolina",
      "state": "PR",
      "county": "San Juan"
  },
  {
      "zipCode": 983,
      "latitude": 18.413452,
      "longitude": -65.977659,
      "city": "Carolina",
      "state": "PR",
      "county": "Carolina"
  },
  {
      "zipCode": 984,
      "latitude": 18.393355,
      "longitude": -65.972495,
      "city": "Carolina",
      "state": "PR",
      "county": "Carolina"
  },
  {
      "zipCode": 985,
      "latitude": 18.345741,
      "longitude": -65.93888,
      "city": "Carolina",
      "state": "PR",
      "county": "Carolina"
  },
  {
      "zipCode": 986,
      "latitude": 18.393355,
      "longitude": -65.972495,
      "city": "Carolina",
      "state": "PR",
      "county": "Carolina"
  },
  {
      "zipCode": 987,
      "latitude": 18.373466,
      "longitude": -65.959932,
      "city": "Carolina",
      "state": "PR",
      "county": "Carolina"
  },
  {
      "zipCode": 988,
      "latitude": 18.393355,
      "longitude": -65.972495,
      "city": "Carolina",
      "state": "PR",
      "county": "Carolina"
  },
  {
      "zipCode": 1001,
      "latitude": 42.140549,
      "longitude": -72.788661,
      "city": "Agawam",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1002,
      "latitude": 42.367092,
      "longitude": -72.464571,
      "city": "Amherst",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1003,
      "latitude": 42.369562,
      "longitude": -72.63599,
      "city": "Amherst",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1004,
      "latitude": 42.384494,
      "longitude": -72.513183,
      "city": "Amherst",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1005,
      "latitude": 42.32916,
      "longitude": -72.139465,
      "city": "Barre",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1007,
      "latitude": 42.280267,
      "longitude": -72.402056,
      "city": "Belchertown",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1008,
      "latitude": 42.177833,
      "longitude": -72.958359,
      "city": "Blandford",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1009,
      "latitude": 42.206092,
      "longitude": -72.340486,
      "city": "Bondsville",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1010,
      "latitude": 42.108585,
      "longitude": -72.20448,
      "city": "Brimfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1011,
      "latitude": 42.294259,
      "longitude": -72.952776,
      "city": "Chester",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1012,
      "latitude": 42.392274,
      "longitude": -72.825607,
      "city": "Chesterfield",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1013,
      "latitude": 42.161492,
      "longitude": -72.667341,
      "city": "Chicopee",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1014,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Chicopee",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1020,
      "latitude": 42.177492,
      "longitude": -72.562563,
      "city": "Chicopee",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1021,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Chicopee",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1022,
      "latitude": 42.193392,
      "longitude": -72.55436,
      "city": "Chicopee",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1026,
      "latitude": 42.428617,
      "longitude": -72.909841,
      "city": "Cummington",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1027,
      "latitude": 42.368303,
      "longitude": -72.768839,
      "city": "Easthampton",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1028,
      "latitude": 42.062009,
      "longitude": -72.49874,
      "city": "East Longmeadow",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1029,
      "latitude": 42.190904,
      "longitude": -73.051661,
      "city": "East Otis",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1030,
      "latitude": 42.189335,
      "longitude": -72.79774,
      "city": "Feeding Hills",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1031,
      "latitude": 42.352554,
      "longitude": -72.205724,
      "city": "Gilbertville",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1032,
      "latitude": 42.443837,
      "longitude": -72.819446,
      "city": "Goshen",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1033,
      "latitude": 42.262285,
      "longitude": -72.504086,
      "city": "Granby",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1034,
      "latitude": 42.112748,
      "longitude": -72.952003,
      "city": "Granville",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1035,
      "latitude": 42.356804,
      "longitude": -72.576613,
      "city": "Hadley",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1036,
      "latitude": 42.067614,
      "longitude": -72.417507,
      "city": "Hampden",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1037,
      "latitude": 42.347856,
      "longitude": -72.225251,
      "city": "Hardwick",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1038,
      "latitude": 42.387269,
      "longitude": -72.643081,
      "city": "Hatfield",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1039,
      "latitude": 42.35641,
      "longitude": -72.682127,
      "city": "Haydenville",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1040,
      "latitude": 42.198291,
      "longitude": -72.64207,
      "city": "Holyoke",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1041,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Holyoke",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1050,
      "latitude": 42.313427,
      "longitude": -72.903677,
      "city": "Huntington",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1053,
      "latitude": 42.353838,
      "longitude": -72.704385,
      "city": "Leeds",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1054,
      "latitude": 42.474681,
      "longitude": -72.467543,
      "city": "Leverett",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1056,
      "latitude": 42.173276,
      "longitude": -72.627038,
      "city": "Ludlow",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1057,
      "latitude": 42.095323,
      "longitude": -72.282063,
      "city": "Monson",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1059,
      "latitude": 42.369562,
      "longitude": -72.63599,
      "city": "North Amherst",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1060,
      "latitude": 42.415154,
      "longitude": -72.76927,
      "city": "Northampton",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1061,
      "latitude": 42.369562,
      "longitude": -72.63599,
      "city": "Northampton",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1062,
      "latitude": 42.328838,
      "longitude": -72.845227,
      "city": "Florence",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1063,
      "latitude": 42.317939,
      "longitude": -72.640234,
      "city": "Northampton",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1066,
      "latitude": 42.406697,
      "longitude": -72.633901,
      "city": "North Hatfield",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1068,
      "latitude": 42.346144,
      "longitude": -72.058847,
      "city": "Oakham",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1069,
      "latitude": 42.176131,
      "longitude": -72.31457,
      "city": "Palmer",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1070,
      "latitude": 42.448984,
      "longitude": -72.958397,
      "city": "Plainfield",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1071,
      "latitude": 42.177432,
      "longitude": -72.864558,
      "city": "Russell",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1072,
      "latitude": 42.471562,
      "longitude": -72.44017,
      "city": "Shutesbury",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1073,
      "latitude": 42.230008,
      "longitude": -72.728463,
      "city": "Southampton",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1074,
      "latitude": 42.375998,
      "longitude": -72.149388,
      "city": "South Barre",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1075,
      "latitude": 42.24984,
      "longitude": -72.58152,
      "city": "South Hadley",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1077,
      "latitude": 42.066644,
      "longitude": -72.541205,
      "city": "Southwick",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1079,
      "latitude": 42.192892,
      "longitude": -72.329574,
      "city": "Thorndike",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1080,
      "latitude": 42.179805,
      "longitude": -72.517813,
      "city": "Three Rivers",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1081,
      "latitude": 42.061948,
      "longitude": -72.213598,
      "city": "Wales",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1082,
      "latitude": 42.377471,
      "longitude": -72.548549,
      "city": "Ware",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1083,
      "latitude": 42.204027,
      "longitude": -72.199439,
      "city": "Warren",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1084,
      "latitude": 42.390303,
      "longitude": -72.870857,
      "city": "West Chesterfield",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1085,
      "latitude": 42.14869,
      "longitude": -72.501887,
      "city": "Westfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1086,
      "latitude": 42.173428,
      "longitude": -72.847964,
      "city": "Westfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1088,
      "latitude": 42.390583,
      "longitude": -72.646894,
      "city": "West Hatfield",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1089,
      "latitude": 42.125793,
      "longitude": -72.645334,
      "city": "West Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1090,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "West Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1092,
      "latitude": 42.202887,
      "longitude": -72.229025,
      "city": "West Warren",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1093,
      "latitude": 42.442673,
      "longitude": -72.652511,
      "city": "Whately",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1094,
      "latitude": 42.358201,
      "longitude": -72.140846,
      "city": "Wheelwright",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1095,
      "latitude": 42.125974,
      "longitude": -72.489988,
      "city": "Wilbraham",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1096,
      "latitude": 42.413069,
      "longitude": -72.821653,
      "city": "Williamsburg",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1097,
      "latitude": 42.161743,
      "longitude": -72.845912,
      "city": "Woronoco",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1098,
      "latitude": 42.40494,
      "longitude": -72.896133,
      "city": "Worthington",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1101,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1102,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1103,
      "latitude": 42.103044,
      "longitude": -72.590783,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1104,
      "latitude": 42.128605,
      "longitude": -72.567966,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1105,
      "latitude": 42.099793,
      "longitude": -72.577732,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1106,
      "latitude": 42.049194,
      "longitude": -72.567882,
      "city": "Longmeadow",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1107,
      "latitude": 42.126237,
      "longitude": -72.586733,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1108,
      "latitude": 42.080594,
      "longitude": -72.558081,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1109,
      "latitude": 42.119293,
      "longitude": -72.548981,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1111,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1114,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1115,
      "latitude": 42.102894,
      "longitude": -72.591633,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1116,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Longmeadow",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1118,
      "latitude": 42.093894,
      "longitude": -72.523227,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1119,
      "latitude": 42.124943,
      "longitude": -72.526705,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1128,
      "latitude": 42.094616,
      "longitude": -72.487229,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1129,
      "latitude": 42.124485,
      "longitude": -72.489479,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1133,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1138,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1139,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1144,
      "latitude": 42.101796,
      "longitude": -72.59151,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1151,
      "latitude": 42.150593,
      "longitude": -72.51278,
      "city": "Indian Orchard",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1152,
      "latitude": 42.170731,
      "longitude": -72.604842,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1199,
      "latitude": 42.119943,
      "longitude": -72.604983,
      "city": "Springfield",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1201,
      "latitude": 42.479475,
      "longitude": -73.24807,
      "city": "Pittsfield",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1202,
      "latitude": 42.3929,
      "longitude": -73.228483,
      "city": "Pittsfield",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1203,
      "latitude": 42.3929,
      "longitude": -73.228483,
      "city": "Pittsfield",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1220,
      "latitude": 42.385595,
      "longitude": -73.172166,
      "city": "Adams",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1222,
      "latitude": 42.185969,
      "longitude": -73.318695,
      "city": "Ashley Falls",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1223,
      "latitude": 42.3418,
      "longitude": -73.103468,
      "city": "Becket",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1224,
      "latitude": 42.3929,
      "longitude": -73.228483,
      "city": "Berkshire",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1225,
      "latitude": 42.482125,
      "longitude": -73.127483,
      "city": "Cheshire",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1226,
      "latitude": 42.470296,
      "longitude": -73.08895,
      "city": "Dalton",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1227,
      "latitude": 42.516145,
      "longitude": -73.092852,
      "city": "Dalton",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1229,
      "latitude": 42.279292,
      "longitude": -73.343545,
      "city": "Glendale",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1230,
      "latitude": 42.299392,
      "longitude": -73.26665,
      "city": "Great Barrington",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1235,
      "latitude": 42.29237,
      "longitude": -73.22164,
      "city": "Hinsdale",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1236,
      "latitude": 42.291299,
      "longitude": -73.358798,
      "city": "Housatonic",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1237,
      "latitude": 42.487569,
      "longitude": -73.235048,
      "city": "Lanesboro",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1238,
      "latitude": 42.369856,
      "longitude": -73.267465,
      "city": "Lee",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1240,
      "latitude": 42.459202,
      "longitude": -73.219858,
      "city": "Lenox",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1242,
      "latitude": 42.338594,
      "longitude": -73.250891,
      "city": "Lenox Dale",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1243,
      "latitude": 42.356088,
      "longitude": -73.010448,
      "city": "Middlefield",
      "state": "MA",
      "county": "Hampshire"
  },
  {
      "zipCode": 1244,
      "latitude": 42.122827,
      "longitude": -73.253983,
      "city": "Mill River",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1245,
      "latitude": 42.18669,
      "longitude": -73.206498,
      "city": "Monterey",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1247,
      "latitude": 42.426974,
      "longitude": -73.18632,
      "city": "North Adams",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1252,
      "latitude": 42.198648,
      "longitude": -73.446234,
      "city": "North Egremont",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1253,
      "latitude": 42.213156,
      "longitude": -73.090434,
      "city": "Otis",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1254,
      "latitude": 42.233105,
      "longitude": -73.238358,
      "city": "Richmond",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1255,
      "latitude": 42.148975,
      "longitude": -73.14861,
      "city": "Sandisfield",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1256,
      "latitude": 42.367341,
      "longitude": -73.128528,
      "city": "Savoy",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1257,
      "latitude": 42.314564,
      "longitude": -73.267694,
      "city": "Sheffield",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1258,
      "latitude": 42.128075,
      "longitude": -73.36089,
      "city": "South Egremont",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1259,
      "latitude": 42.198712,
      "longitude": -73.278543,
      "city": "Southfield",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1260,
      "latitude": 42.286586,
      "longitude": -73.313274,
      "city": "South Lee",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1262,
      "latitude": 42.304604,
      "longitude": -73.330001,
      "city": "Stockbridge",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1263,
      "latitude": 42.3929,
      "longitude": -73.228483,
      "city": "Stockbridge",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1264,
      "latitude": 42.220001,
      "longitude": -73.197865,
      "city": "Tyringham",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1266,
      "latitude": 42.360482,
      "longitude": -73.265354,
      "city": "West Stockbridge",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1267,
      "latitude": 42.642075,
      "longitude": -73.257699,
      "city": "Williamstown",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1270,
      "latitude": 42.443726,
      "longitude": -73.116127,
      "city": "Windsor",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1301,
      "latitude": 42.601335,
      "longitude": -72.623619,
      "city": "Greenfield",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1302,
      "latitude": 42.522178,
      "longitude": -72.624164,
      "city": "Greenfield",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1330,
      "latitude": 42.562391,
      "longitude": -72.738152,
      "city": "Ashfield",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1331,
      "latitude": 42.547302,
      "longitude": -72.183903,
      "city": "Athol",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1337,
      "latitude": 42.62706,
      "longitude": -72.642888,
      "city": "Bernardston",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1338,
      "latitude": 42.573832,
      "longitude": -72.769487,
      "city": "Buckland",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1339,
      "latitude": 42.594252,
      "longitude": -72.665507,
      "city": "Charlemont",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1340,
      "latitude": 42.681539,
      "longitude": -72.822986,
      "city": "Colrain",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1341,
      "latitude": 42.590824,
      "longitude": -72.70976,
      "city": "Conway",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1342,
      "latitude": 42.54723,
      "longitude": -72.607679,
      "city": "Deerfield",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1343,
      "latitude": 42.642666,
      "longitude": -72.986231,
      "city": "Drury",
      "state": "MA",
      "county": "Berkshire"
  },
  {
      "zipCode": 1344,
      "latitude": 42.627072,
      "longitude": -72.553654,
      "city": "Erving",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1346,
      "latitude": 42.618011,
      "longitude": -72.788896,
      "city": "Heath",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1347,
      "latitude": 42.556558,
      "longitude": -72.518104,
      "city": "Lake Pleasant",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1349,
      "latitude": 42.621412,
      "longitude": -72.705633,
      "city": "Turners Falls",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1350,
      "latitude": 42.721456,
      "longitude": -72.976204,
      "city": "Monroe Bridge",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1351,
      "latitude": 42.548107,
      "longitude": -72.48693,
      "city": "Montague",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1354,
      "latitude": 42.522178,
      "longitude": -72.624164,
      "city": "Northfield",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1355,
      "latitude": 42.518718,
      "longitude": -72.534371,
      "city": "New Salem",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1360,
      "latitude": 42.614152,
      "longitude": -72.528209,
      "city": "Northfield",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1364,
      "latitude": 42.578325,
      "longitude": -72.556589,
      "city": "Orange",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1366,
      "latitude": 42.459632,
      "longitude": -72.182962,
      "city": "Petersham",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1367,
      "latitude": 42.683065,
      "longitude": -72.715667,
      "city": "Rowe",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1368,
      "latitude": 42.672182,
      "longitude": -72.196376,
      "city": "Royalston",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1369,
      "latitude": 42.522178,
      "longitude": -72.624164,
      "city": "Shattuckville",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1370,
      "latitude": 42.588812,
      "longitude": -72.758781,
      "city": "Shelburne Falls",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1373,
      "latitude": 42.464522,
      "longitude": -72.682346,
      "city": "South Deerfield",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1375,
      "latitude": 42.565346,
      "longitude": -72.70094,
      "city": "Sunderland",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1376,
      "latitude": 42.654878,
      "longitude": -72.718123,
      "city": "Turners Falls",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1378,
      "latitude": 42.667059,
      "longitude": -72.339655,
      "city": "Warwick",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1379,
      "latitude": 42.581007,
      "longitude": -72.437179,
      "city": "Wendell",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1380,
      "latitude": 42.553431,
      "longitude": -72.392694,
      "city": "Wendell Depot",
      "state": "MA",
      "county": "Franklin"
  },
  {
      "zipCode": 1420,
      "latitude": 42.583689,
      "longitude": -71.816767,
      "city": "Fitchburg",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1430,
      "latitude": 42.654906,
      "longitude": -71.920942,
      "city": "Ashburnham",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1431,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Ashby",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1432,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Ayer",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1436,
      "latitude": 42.601427,
      "longitude": -72.083838,
      "city": "Baldwinville",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1438,
      "latitude": 42.551681,
      "longitude": -72.029434,
      "city": "East Templeton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1440,
      "latitude": 42.582529,
      "longitude": -72.025884,
      "city": "Gardner",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1441,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Westminster",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1450,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Groton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1451,
      "latitude": 42.500187,
      "longitude": -71.575864,
      "city": "Harvard",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1452,
      "latitude": 42.483895,
      "longitude": -72.011516,
      "city": "Hubbardston",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1453,
      "latitude": 42.471316,
      "longitude": -71.837509,
      "city": "Leominster",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1460,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Littleton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1462,
      "latitude": 42.583322,
      "longitude": -71.752266,
      "city": "Lunenburg",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1463,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Pepperell",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1464,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Shirley",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1467,
      "latitude": 42.487056,
      "longitude": -71.613078,
      "city": "Still River",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1468,
      "latitude": 42.555059,
      "longitude": -72.072285,
      "city": "Templeton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1469,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Townsend",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1470,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Groton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1471,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Groton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1472,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "West Groton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1473,
      "latitude": 42.549489,
      "longitude": -71.913219,
      "city": "Westminster",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1474,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "West Townsend",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1475,
      "latitude": 42.661612,
      "longitude": -72.047876,
      "city": "Winchendon",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1477,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Winchendon Springs",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1501,
      "latitude": 42.184835,
      "longitude": -71.947184,
      "city": "Auburn",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1503,
      "latitude": 42.3129,
      "longitude": -71.841656,
      "city": "Berlin",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1504,
      "latitude": 42.114078,
      "longitude": -71.799785,
      "city": "Blackstone",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1505,
      "latitude": 42.300037,
      "longitude": -71.943458,
      "city": "Boylston",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1506,
      "latitude": 42.19169,
      "longitude": -72.105011,
      "city": "Brookfield",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1507,
      "latitude": 42.13277,
      "longitude": -71.972627,
      "city": "Charlton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1508,
      "latitude": 42.109748,
      "longitude": -72.079455,
      "city": "Charlton City",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1509,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Charlton Depot",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1510,
      "latitude": 42.413972,
      "longitude": -71.687523,
      "city": "Clinton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1515,
      "latitude": 42.205311,
      "longitude": -72.049907,
      "city": "East Brookfield",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1516,
      "latitude": 42.113076,
      "longitude": -71.891139,
      "city": "Douglas",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1517,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "East Princeton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1518,
      "latitude": 42.106405,
      "longitude": -72.114045,
      "city": "Fiskdale",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1519,
      "latitude": 42.203944,
      "longitude": -71.682862,
      "city": "Grafton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1520,
      "latitude": 42.336791,
      "longitude": -71.845316,
      "city": "Holden",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1521,
      "latitude": 42.061063,
      "longitude": -72.163991,
      "city": "Holland",
      "state": "MA",
      "county": "Hampden"
  },
  {
      "zipCode": 1522,
      "latitude": 42.264629,
      "longitude": -71.795442,
      "city": "Jefferson",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1523,
      "latitude": 42.427188,
      "longitude": -71.91177,
      "city": "Lancaster",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1524,
      "latitude": 42.238192,
      "longitude": -72.012379,
      "city": "Leicester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1525,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Linwood",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1526,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Manchaug",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1527,
      "latitude": 42.255642,
      "longitude": -71.819961,
      "city": "Millbury",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1529,
      "latitude": 42.124662,
      "longitude": -71.846943,
      "city": "Millville",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1531,
      "latitude": 42.320938,
      "longitude": -72.128644,
      "city": "New Braintree",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1532,
      "latitude": 42.322118,
      "longitude": -71.64282,
      "city": "Northborough",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1534,
      "latitude": 42.207191,
      "longitude": -71.856807,
      "city": "Northbridge",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1535,
      "latitude": 42.275382,
      "longitude": -72.089338,
      "city": "North Brookfield",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1536,
      "latitude": 42.407556,
      "longitude": -71.860402,
      "city": "North Grafton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1537,
      "latitude": 42.166241,
      "longitude": -71.891052,
      "city": "North Oxford",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1538,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "North Uxbridge",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1540,
      "latitude": 42.109223,
      "longitude": -71.855444,
      "city": "Oxford",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1541,
      "latitude": 42.451926,
      "longitude": -71.880057,
      "city": "Princeton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1542,
      "latitude": 42.220085,
      "longitude": -71.914361,
      "city": "Rochdale",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1543,
      "latitude": 42.383516,
      "longitude": -71.95463,
      "city": "Rutland",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1545,
      "latitude": 42.286992,
      "longitude": -71.715313,
      "city": "Shrewsbury",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1546,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Shrewsbury",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1550,
      "latitude": 42.129251,
      "longitude": -72.031155,
      "city": "Southbridge",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1560,
      "latitude": 42.176544,
      "longitude": -71.681912,
      "city": "South Grafton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1561,
      "latitude": 42.443539,
      "longitude": -71.686137,
      "city": "South Lancaster",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1562,
      "latitude": 42.254837,
      "longitude": -72.065612,
      "city": "Spencer",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1564,
      "latitude": 42.366765,
      "longitude": -71.939375,
      "city": "Sterling",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1566,
      "latitude": 42.10273,
      "longitude": -72.080996,
      "city": "Sturbridge",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1568,
      "latitude": 42.175591,
      "longitude": -71.603197,
      "city": "Upton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1569,
      "latitude": 42.059736,
      "longitude": -71.638438,
      "city": "Uxbridge",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1570,
      "latitude": 42.1351,
      "longitude": -71.994169,
      "city": "Webster",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1571,
      "latitude": 42.059189,
      "longitude": -71.937037,
      "city": "Dudley",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1580,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Westborough",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1581,
      "latitude": 42.255222,
      "longitude": -71.845865,
      "city": "Westborough",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1582,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Westborough",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1583,
      "latitude": 42.362783,
      "longitude": -71.781215,
      "city": "West Boylston",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1585,
      "latitude": 42.235638,
      "longitude": -72.172523,
      "city": "West Brookfield",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1586,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "West Millbury",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1588,
      "latitude": 42.166554,
      "longitude": -71.899484,
      "city": "Whitinsville",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1590,
      "latitude": 42.126575,
      "longitude": -71.755193,
      "city": "Sutton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1601,
      "latitude": 42.265275,
      "longitude": -71.879415,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1602,
      "latitude": 42.274595,
      "longitude": -71.846966,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1603,
      "latitude": 42.245442,
      "longitude": -71.839257,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1604,
      "latitude": 42.247955,
      "longitude": -71.76678,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1605,
      "latitude": 42.288792,
      "longitude": -71.796128,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1606,
      "latitude": 42.311834,
      "longitude": -71.794465,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1607,
      "latitude": 42.226452,
      "longitude": -71.792506,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1608,
      "latitude": 42.293501,
      "longitude": -71.925696,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1609,
      "latitude": 42.282565,
      "longitude": -71.827685,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1610,
      "latitude": 42.246483,
      "longitude": -71.808915,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1611,
      "latitude": 42.239392,
      "longitude": -71.878716,
      "city": "Cherry Valley",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1612,
      "latitude": 42.304675,
      "longitude": -71.892875,
      "city": "Paxton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1613,
      "latitude": 42.293316,
      "longitude": -71.801971,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1614,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1615,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1653,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1654,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1655,
      "latitude": 42.364807,
      "longitude": -71.896868,
      "city": "Worcester",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1701,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Framingham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1702,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Framingham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1703,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Framingham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1704,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Framingham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1705,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Framingham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1718,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Village Of Nagog Woods",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1719,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Boxborough",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1720,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Acton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1721,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Ashland",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1730,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Bedford",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1731,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Hanscom Afb",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1740,
      "latitude": 42.436043,
      "longitude": -71.605916,
      "city": "Bolton",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1741,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Carlisle",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1742,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Concord",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1745,
      "latitude": 42.293442,
      "longitude": -71.502762,
      "city": "Fayville",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1746,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Holliston",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1747,
      "latitude": 42.127515,
      "longitude": -71.533138,
      "city": "Hopedale",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1748,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Hopkinton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1749,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Hudson",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1752,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Marlborough",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1754,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Maynard",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1756,
      "latitude": 42.100352,
      "longitude": -71.546961,
      "city": "Mendon",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1757,
      "latitude": 42.147087,
      "longitude": -71.528085,
      "city": "Milford",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1760,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Natick",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1770,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Sherborn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1772,
      "latitude": 42.296842,
      "longitude": -71.533229,
      "city": "Southborough",
      "state": "MA",
      "county": "Worcester"
  },
  {
      "zipCode": 1773,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Lincoln",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1775,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Stow",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1776,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Sudbury",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1778,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Wayland",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1784,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Woodville",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1801,
      "latitude": 42.488595,
      "longitude": -71.157271,
      "city": "Woburn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1803,
      "latitude": 42.504844,
      "longitude": -71.201539,
      "city": "Burlington",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1805,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Burlington",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1806,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Woburn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1807,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Woburn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1808,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Woburn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1810,
      "latitude": 42.647991,
      "longitude": -71.165685,
      "city": "Andover",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1812,
      "latitude": 42.647191,
      "longitude": -71.184202,
      "city": "Andover",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1813,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Woburn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1815,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Woburn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1821,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Billerica",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1822,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Billerica",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1824,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Chelmsford",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1826,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Dracut",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1827,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Dunstable",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1830,
      "latitude": 42.792639,
      "longitude": -71.072501,
      "city": "Haverhill",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1831,
      "latitude": 42.771095,
      "longitude": -71.122054,
      "city": "Haverhill",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1832,
      "latitude": 42.789627,
      "longitude": -71.126562,
      "city": "Haverhill",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1833,
      "latitude": 42.72377,
      "longitude": -70.981298,
      "city": "Georgetown",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1834,
      "latitude": 42.751074,
      "longitude": -71.021437,
      "city": "Groveland",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1835,
      "latitude": 42.752844,
      "longitude": -71.084301,
      "city": "Haverhill",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1840,
      "latitude": 42.70734,
      "longitude": -71.161052,
      "city": "Lawrence",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1841,
      "latitude": 42.70979,
      "longitude": -71.164402,
      "city": "Lawrence",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1842,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Lawrence",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1843,
      "latitude": 42.71037,
      "longitude": -71.088303,
      "city": "Lawrence",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1844,
      "latitude": 42.73184,
      "longitude": -71.186915,
      "city": "Methuen",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1845,
      "latitude": 42.672835,
      "longitude": -71.087689,
      "city": "North Andover",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1850,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Lowell",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1851,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Lowell",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1852,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Lowell",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1853,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Lowell",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1854,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Lowell",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1860,
      "latitude": 42.802441,
      "longitude": -71.0896,
      "city": "Merrimac",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1862,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "North Billerica",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1863,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "North Chelmsford",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1864,
      "latitude": 42.581332,
      "longitude": -71.083725,
      "city": "North Reading",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1865,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Nutting Lake",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1866,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Pinehurst",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1867,
      "latitude": 42.537065,
      "longitude": -71.107172,
      "city": "Reading",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1876,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Tewksbury",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1879,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Tyngsboro",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1880,
      "latitude": 42.499891,
      "longitude": -71.068829,
      "city": "Wakefield",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1885,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "West Boxford",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1886,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Westford",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1887,
      "latitude": 42.561782,
      "longitude": -71.173888,
      "city": "Wilmington",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1888,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Woburn",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1889,
      "latitude": 42.571633,
      "longitude": -71.109646,
      "city": "North Reading",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1890,
      "latitude": 42.454545,
      "longitude": -71.148779,
      "city": "Winchester",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 1899,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Andover",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1901,
      "latitude": 42.461246,
      "longitude": -70.946743,
      "city": "Lynn",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1902,
      "latitude": 42.473195,
      "longitude": -70.928593,
      "city": "Lynn",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1903,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Lynn",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1904,
      "latitude": 42.488896,
      "longitude": -70.964694,
      "city": "Lynn",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1905,
      "latitude": 42.469395,
      "longitude": -70.972844,
      "city": "Lynn",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1906,
      "latitude": 42.472112,
      "longitude": -70.997794,
      "city": "Saugus",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1907,
      "latitude": 42.513295,
      "longitude": -70.905893,
      "city": "Swampscott",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1908,
      "latitude": 42.427296,
      "longitude": -70.922442,
      "city": "Nahant",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1910,
      "latitude": 42.454796,
      "longitude": -70.974694,
      "city": "Lynn",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1913,
      "latitude": 42.853539,
      "longitude": -70.948211,
      "city": "Amesbury",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1915,
      "latitude": 42.565145,
      "longitude": -70.853843,
      "city": "Beverly",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1921,
      "latitude": 42.683256,
      "longitude": -71.017403,
      "city": "Boxford",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1922,
      "latitude": 42.763216,
      "longitude": -70.92812,
      "city": "Byfield",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1923,
      "latitude": 42.577188,
      "longitude": -70.949245,
      "city": "Danvers",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1929,
      "latitude": 42.62781,
      "longitude": -70.780576,
      "city": "Essex",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1930,
      "latitude": 42.630011,
      "longitude": -70.694179,
      "city": "Gloucester",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1931,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Gloucester",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1936,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Hamilton",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1937,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Hathorne",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1938,
      "latitude": 42.68571,
      "longitude": -70.864132,
      "city": "Ipswich",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1940,
      "latitude": 42.533732,
      "longitude": -71.028775,
      "city": "Lynnfield",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1944,
      "latitude": 42.579503,
      "longitude": -70.755062,
      "city": "Manchester",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1945,
      "latitude": 42.56142,
      "longitude": -70.770768,
      "city": "Marblehead",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1949,
      "latitude": 42.644942,
      "longitude": -71.087905,
      "city": "Middleton",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1950,
      "latitude": 42.80965,
      "longitude": -70.873196,
      "city": "Newburyport",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1951,
      "latitude": 42.777524,
      "longitude": -70.867246,
      "city": "Newbury",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1952,
      "latitude": 42.851234,
      "longitude": -70.865667,
      "city": "Salisbury",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1960,
      "latitude": 42.536996,
      "longitude": -70.973646,
      "city": "Peabody",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1961,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Peabody",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1965,
      "latitude": 42.558113,
      "longitude": -70.825743,
      "city": "Prides Crossing",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1966,
      "latitude": 42.657866,
      "longitude": -70.618057,
      "city": "Rockport",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1969,
      "latitude": 42.716155,
      "longitude": -70.892754,
      "city": "Rowley",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1970,
      "latitude": 42.512946,
      "longitude": -70.904237,
      "city": "Salem",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1971,
      "latitude": 42.635443,
      "longitude": -70.879123,
      "city": "Salem",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1982,
      "latitude": 42.626664,
      "longitude": -70.851125,
      "city": "South Hamilton",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1983,
      "latitude": 42.661793,
      "longitude": -70.954487,
      "city": "Topsfield",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1984,
      "latitude": 42.597691,
      "longitude": -70.8732,
      "city": "Wenham",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 1985,
      "latitude": 42.79414,
      "longitude": -70.971068,
      "city": "West Newbury",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 2018,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Accord",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2019,
      "latitude": 42.076501,
      "longitude": -71.470464,
      "city": "Bellingham",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2020,
      "latitude": 42.081825,
      "longitude": -70.643868,
      "city": "Brant Rock",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2021,
      "latitude": 42.179146,
      "longitude": -71.121185,
      "city": "Canton",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2025,
      "latitude": 42.233938,
      "longitude": -70.815826,
      "city": "Cohasset",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2026,
      "latitude": 42.244733,
      "longitude": -71.181141,
      "city": "Dedham",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2027,
      "latitude": 42.180048,
      "longitude": -71.08923,
      "city": "Dedham",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2030,
      "latitude": 42.236114,
      "longitude": -71.283072,
      "city": "Dover",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2031,
      "latitude": 41.998799,
      "longitude": -71.200894,
      "city": "East Mansfield",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2032,
      "latitude": 42.153786,
      "longitude": -71.21455,
      "city": "East Walpole",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2035,
      "latitude": 42.062204,
      "longitude": -71.235774,
      "city": "Foxboro",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2038,
      "latitude": 42.08868,
      "longitude": -71.404814,
      "city": "Franklin",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2040,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Greenbush",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2041,
      "latitude": 42.069642,
      "longitude": -70.649075,
      "city": "Green Harbor",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2043,
      "latitude": 42.212105,
      "longitude": -70.884989,
      "city": "Hingham",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2044,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Hingham",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2045,
      "latitude": 42.284413,
      "longitude": -70.873659,
      "city": "Hull",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2047,
      "latitude": 42.142836,
      "longitude": -70.69353,
      "city": "Humarock",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2048,
      "latitude": 42.013182,
      "longitude": -71.218373,
      "city": "Mansfield",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2050,
      "latitude": 42.111805,
      "longitude": -70.710744,
      "city": "Marshfield",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2051,
      "latitude": 42.151202,
      "longitude": -70.734146,
      "city": "Marshfield Hills",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2052,
      "latitude": 42.181265,
      "longitude": -71.309934,
      "city": "Medfield",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2053,
      "latitude": 42.156282,
      "longitude": -71.427663,
      "city": "Medway",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2054,
      "latitude": 42.165249,
      "longitude": -71.36126,
      "city": "Millis",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2055,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Minot",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2056,
      "latitude": 42.117511,
      "longitude": -71.331793,
      "city": "Norfolk",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2059,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "North Marshfield",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2060,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "North Scituate",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2061,
      "latitude": 42.154145,
      "longitude": -70.823035,
      "city": "Norwell",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2062,
      "latitude": 42.182798,
      "longitude": -71.196277,
      "city": "Norwood",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2065,
      "latitude": 42.097219,
      "longitude": -70.651567,
      "city": "Ocean Bluff",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2066,
      "latitude": 42.207254,
      "longitude": -70.770188,
      "city": "Scituate",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2067,
      "latitude": 42.105288,
      "longitude": -71.184785,
      "city": "Sharon",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2070,
      "latitude": 42.180048,
      "longitude": -71.08923,
      "city": "Sheldonville",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2071,
      "latitude": 42.100399,
      "longitude": -71.270933,
      "city": "South Walpole",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2072,
      "latitude": 42.118416,
      "longitude": -71.105733,
      "city": "Stoughton",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2081,
      "latitude": 42.148624,
      "longitude": -71.255533,
      "city": "Walpole",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2090,
      "latitude": 42.220548,
      "longitude": -71.199238,
      "city": "Westwood",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2093,
      "latitude": 42.054311,
      "longitude": -71.371169,
      "city": "Wrentham",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2101,
      "latitude": 42.370567,
      "longitude": -71.026964,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2102,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2103,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2104,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2105,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2106,
      "latitude": 42.354318,
      "longitude": -71.073449,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2107,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2108,
      "latitude": 42.353806,
      "longitude": -71.102446,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2109,
      "latitude": 42.360027,
      "longitude": -71.054495,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2110,
      "latitude": 42.352847,
      "longitude": -71.051466,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2111,
      "latitude": 42.351047,
      "longitude": -71.059365,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2112,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2113,
      "latitude": 42.365398,
      "longitude": -71.055001,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2114,
      "latitude": 42.362097,
      "longitude": -71.023661,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2115,
      "latitude": 42.342043,
      "longitude": -71.096848,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2116,
      "latitude": 42.347207,
      "longitude": -71.086095,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2117,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2118,
      "latitude": 42.336162,
      "longitude": -71.072854,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2119,
      "latitude": 42.323077,
      "longitude": -71.084608,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2120,
      "latitude": 42.332484,
      "longitude": -71.096416,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2121,
      "latitude": 42.307098,
      "longitude": -71.081645,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2122,
      "latitude": 42.29658,
      "longitude": -71.055215,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2123,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2124,
      "latitude": 42.286784,
      "longitude": -71.071045,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2125,
      "latitude": 42.31476,
      "longitude": -71.067244,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2126,
      "latitude": 42.301247,
      "longitude": -71.105195,
      "city": "Mattapan",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2127,
      "latitude": 42.329023,
      "longitude": -71.020343,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2128,
      "latitude": 42.364197,
      "longitude": -71.025694,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2129,
      "latitude": 42.382588,
      "longitude": -71.065287,
      "city": "Charlestown",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2130,
      "latitude": 42.309661,
      "longitude": -71.121097,
      "city": "Jamaica Plain",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2131,
      "latitude": 42.284197,
      "longitude": -71.120896,
      "city": "Roslindale",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2132,
      "latitude": 42.277897,
      "longitude": -71.155833,
      "city": "West Roxbury",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2133,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2134,
      "latitude": 42.357169,
      "longitude": -71.112646,
      "city": "Allston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2135,
      "latitude": 42.349768,
      "longitude": -71.104888,
      "city": "Brighton",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2136,
      "latitude": 42.254248,
      "longitude": -71.129321,
      "city": "Hyde Park",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2137,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Readville",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2138,
      "latitude": 42.380442,
      "longitude": -71.132947,
      "city": "Cambridge",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2139,
      "latitude": 42.365146,
      "longitude": -71.101842,
      "city": "Cambridge",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2140,
      "latitude": 42.393246,
      "longitude": -71.133833,
      "city": "Cambridge",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2141,
      "latitude": 42.368673,
      "longitude": -71.083596,
      "city": "Cambridge",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2142,
      "latitude": 42.36284,
      "longitude": -71.084814,
      "city": "Cambridge",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2143,
      "latitude": 42.38092,
      "longitude": -71.098896,
      "city": "Somerville",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2144,
      "latitude": 42.402696,
      "longitude": -71.12022,
      "city": "Somerville",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2145,
      "latitude": 42.391046,
      "longitude": -71.095146,
      "city": "Somerville",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2148,
      "latitude": 42.436545,
      "longitude": -71.085396,
      "city": "Malden",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2149,
      "latitude": 42.407396,
      "longitude": -71.051183,
      "city": "Everett",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2150,
      "latitude": 42.378197,
      "longitude": -71.038894,
      "city": "Chelsea",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2151,
      "latitude": 42.366303,
      "longitude": -71.020494,
      "city": "Revere",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2152,
      "latitude": 42.378447,
      "longitude": -70.981679,
      "city": "Winthrop",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2153,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Medford",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2155,
      "latitude": 42.422095,
      "longitude": -71.109297,
      "city": "Medford",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2156,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "West Medford",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2163,
      "latitude": 42.32532,
      "longitude": -71.112159,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2169,
      "latitude": 42.241799,
      "longitude": -71.006042,
      "city": "Quincy",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2170,
      "latitude": 42.267248,
      "longitude": -71.016742,
      "city": "Quincy",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2171,
      "latitude": 42.284898,
      "longitude": -71.014243,
      "city": "Quincy",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2176,
      "latitude": 42.458995,
      "longitude": -71.053095,
      "city": "Melrose",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2177,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Melrose",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2180,
      "latitude": 42.474595,
      "longitude": -71.098146,
      "city": "Stoneham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2184,
      "latitude": 42.202216,
      "longitude": -71.005192,
      "city": "Braintree",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2185,
      "latitude": 42.180048,
      "longitude": -71.08923,
      "city": "Braintree",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2186,
      "latitude": 42.240598,
      "longitude": -71.078494,
      "city": "Milton",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2187,
      "latitude": 42.180048,
      "longitude": -71.08923,
      "city": "Milton Village",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2188,
      "latitude": 42.20794,
      "longitude": -70.95514,
      "city": "Weymouth",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2189,
      "latitude": 42.210649,
      "longitude": -70.932318,
      "city": "Weymouth",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2190,
      "latitude": 42.167841,
      "longitude": -70.95074,
      "city": "Weymouth",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2191,
      "latitude": 42.251499,
      "longitude": -70.94484,
      "city": "Weymouth",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2196,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2199,
      "latitude": 42.347247,
      "longitude": -71.082395,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2201,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2202,
      "latitude": 42.361094,
      "longitude": -71.061814,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2203,
      "latitude": 42.361485,
      "longitude": -71.060364,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2204,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2205,
      "latitude": 42.350334,
      "longitude": -71.053877,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2206,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2207,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2208,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2209,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2210,
      "latitude": 42.347547,
      "longitude": -71.040645,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2211,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2212,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Boston",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2215,
      "latitude": 42.345079,
      "longitude": -71.107653,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2216,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2217,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2222,
      "latitude": 42.364381,
      "longitude": -71.063314,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2228,
      "latitude": 42.375,
      "longitude": -71.03972,
      "city": "East Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2238,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Cambridge",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2239,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Cambridge",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2241,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2266,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2269,
      "latitude": 42.180048,
      "longitude": -71.08923,
      "city": "Quincy",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2283,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2284,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2293,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2295,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2297,
      "latitude": 42.338947,
      "longitude": -70.919635,
      "city": "Boston",
      "state": "MA",
      "county": "Suffolk"
  },
  {
      "zipCode": 2301,
      "latitude": 42.079399,
      "longitude": -71.03999,
      "city": "Brockton",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2302,
      "latitude": 42.08475,
      "longitude": -71.000189,
      "city": "Brockton",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2303,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Brockton",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2304,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Brockton",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2305,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Brockton",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2322,
      "latitude": 42.126049,
      "longitude": -71.048216,
      "city": "Avon",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2324,
      "latitude": 41.973741,
      "longitude": -70.976558,
      "city": "Bridgewater",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2325,
      "latitude": 41.98725,
      "longitude": -70.972786,
      "city": "Bridgewater",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2327,
      "latitude": 42.040708,
      "longitude": -70.827245,
      "city": "Bryantville",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2330,
      "latitude": 41.896238,
      "longitude": -70.759689,
      "city": "Carver",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2331,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Duxbury",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2332,
      "latitude": 42.052985,
      "longitude": -70.710808,
      "city": "Duxbury",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2333,
      "latitude": 42.02285,
      "longitude": -70.931056,
      "city": "East Bridgewater",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2334,
      "latitude": 42.023528,
      "longitude": -71.132397,
      "city": "Easton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2337,
      "latitude": 42.022225,
      "longitude": -70.931588,
      "city": "Elmwood",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2338,
      "latitude": 41.988351,
      "longitude": -70.860578,
      "city": "Halifax",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2339,
      "latitude": 42.123534,
      "longitude": -70.851048,
      "city": "Hanover",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2340,
      "latitude": 42.123534,
      "longitude": -70.851048,
      "city": "Hanover",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2341,
      "latitude": 42.055701,
      "longitude": -70.875936,
      "city": "Hanson",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2343,
      "latitude": 42.144424,
      "longitude": -71.00289,
      "city": "Holbrook",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2344,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Middleboro",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2345,
      "latitude": 41.888198,
      "longitude": -70.581029,
      "city": "Manomet",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2346,
      "latitude": 41.915054,
      "longitude": -70.882035,
      "city": "Middleboro",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2347,
      "latitude": 41.843757,
      "longitude": -70.959981,
      "city": "Lakeville",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2348,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Middleboro",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2349,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Middleboro",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2350,
      "latitude": 42.018525,
      "longitude": -70.847486,
      "city": "Monponsett",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2351,
      "latitude": 42.11749,
      "longitude": -70.959888,
      "city": "Abington",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2355,
      "latitude": 41.916918,
      "longitude": -70.801331,
      "city": "North Carver",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2356,
      "latitude": 42.053408,
      "longitude": -71.12033,
      "city": "North Easton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2357,
      "latitude": 42.064499,
      "longitude": -71.087091,
      "city": "North Easton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2358,
      "latitude": 41.95351,
      "longitude": -70.713109,
      "city": "North Pembroke",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2359,
      "latitude": 42.065702,
      "longitude": -70.800778,
      "city": "Pembroke",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2360,
      "latitude": 41.886207,
      "longitude": -70.638717,
      "city": "Plymouth",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2361,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Plymouth",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2362,
      "latitude": 41.970474,
      "longitude": -70.701357,
      "city": "Plymouth",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2364,
      "latitude": 41.979405,
      "longitude": -70.744813,
      "city": "Kingston",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2366,
      "latitude": 41.850087,
      "longitude": -70.704431,
      "city": "South Carver",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2367,
      "latitude": 41.96914,
      "longitude": -70.812299,
      "city": "Plympton",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2368,
      "latitude": 42.171467,
      "longitude": -71.055602,
      "city": "Randolph",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2370,
      "latitude": 41.954199,
      "longitude": -70.885095,
      "city": "Rockland",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2375,
      "latitude": 42.023199,
      "longitude": -71.111091,
      "city": "South Easton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2379,
      "latitude": 42.02165,
      "longitude": -71.023588,
      "city": "West Bridgewater",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2381,
      "latitude": 41.931602,
      "longitude": -70.561051,
      "city": "White Horse Beach",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2382,
      "latitude": 42.0785,
      "longitude": -70.940837,
      "city": "Whitman",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2420,
      "latitude": 42.45631,
      "longitude": -71.21665,
      "city": "Lexington",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2421,
      "latitude": 42.442567,
      "longitude": -71.226453,
      "city": "Lexington",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2445,
      "latitude": 42.318097,
      "longitude": -71.143697,
      "city": "Brookline",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2446,
      "latitude": 42.343097,
      "longitude": -71.123046,
      "city": "Brookline",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2447,
      "latitude": 42.180048,
      "longitude": -71.08923,
      "city": "Brookline Village",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2451,
      "latitude": 42.398588,
      "longitude": -71.24505,
      "city": "Waltham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2452,
      "latitude": 42.394319,
      "longitude": -71.218049,
      "city": "Waltham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2453,
      "latitude": 42.365396,
      "longitude": -71.23165,
      "city": "Waltham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2454,
      "latitude": 42.356719,
      "longitude": -71.250479,
      "city": "Waltham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2455,
      "latitude": 42.404644,
      "longitude": -71.234086,
      "city": "North Waltham",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2456,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "New Town",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2457,
      "latitude": 42.180048,
      "longitude": -71.08923,
      "city": "Babson Park",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2458,
      "latitude": 42.385096,
      "longitude": -71.208399,
      "city": "Newton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2459,
      "latitude": 42.334146,
      "longitude": -71.183298,
      "city": "Newton Center",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2460,
      "latitude": 42.374296,
      "longitude": -71.182371,
      "city": "Newtonville",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2461,
      "latitude": 42.361196,
      "longitude": -71.205349,
      "city": "Newton Highlands",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2462,
      "latitude": 42.349496,
      "longitude": -71.209699,
      "city": "Newton Lower Falls",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2464,
      "latitude": 42.36599,
      "longitude": -71.221849,
      "city": "Newton Upper Falls",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2465,
      "latitude": 42.378145,
      "longitude": -71.213199,
      "city": "West Newton",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2466,
      "latitude": 42.346696,
      "longitude": -71.224957,
      "city": "Auburndale",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2467,
      "latitude": 42.357564,
      "longitude": -71.211649,
      "city": "Chestnut Hill",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2468,
      "latitude": 42.327146,
      "longitude": -71.231534,
      "city": "Waban",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2471,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Watertown",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2472,
      "latitude": 42.363096,
      "longitude": -71.201398,
      "city": "Watertown",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2474,
      "latitude": 42.417595,
      "longitude": -71.159696,
      "city": "Arlington",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2475,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Arlington Heights",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2476,
      "latitude": 42.379146,
      "longitude": -71.184299,
      "city": "Arlington",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2477,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Watertown",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2478,
      "latitude": 42.412795,
      "longitude": -71.204399,
      "city": "Belmont",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2479,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Waverley",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2481,
      "latitude": 42.310597,
      "longitude": -71.274652,
      "city": "Wellesley Hills",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2482,
      "latitude": 42.294546,
      "longitude": -71.299201,
      "city": "Wellesley",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2492,
      "latitude": 42.279797,
      "longitude": -71.25006,
      "city": "Needham",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2493,
      "latitude": 42.375925,
      "longitude": -71.227208,
      "city": "Weston",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2494,
      "latitude": 42.300147,
      "longitude": -71.26315,
      "city": "Needham Heights",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2495,
      "latitude": 42.446396,
      "longitude": -71.459405,
      "city": "Nonantum",
      "state": "MA",
      "county": "Middlesex"
  },
  {
      "zipCode": 2532,
      "latitude": 41.745505,
      "longitude": -70.590471,
      "city": "Buzzards Bay",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2534,
      "latitude": 41.669373,
      "longitude": -70.62337,
      "city": "Cataumet",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2535,
      "latitude": 41.379034,
      "longitude": -70.673082,
      "city": "Chilmark",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2536,
      "latitude": 41.662506,
      "longitude": -70.562843,
      "city": "East Falmouth",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2537,
      "latitude": 41.72832,
      "longitude": -70.439975,
      "city": "East Sandwich",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2538,
      "latitude": 41.77544,
      "longitude": -70.660562,
      "city": "East Wareham",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2539,
      "latitude": 41.401176,
      "longitude": -70.552054,
      "city": "Edgartown",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2540,
      "latitude": 41.614199,
      "longitude": -70.493263,
      "city": "Falmouth",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2541,
      "latitude": 41.799312,
      "longitude": -70.308662,
      "city": "Falmouth",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2542,
      "latitude": 41.65308,
      "longitude": -70.553727,
      "city": "Buzzards Bay",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2543,
      "latitude": 41.593809,
      "longitude": -70.646442,
      "city": "Woods Hole",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2552,
      "latitude": 41.379836,
      "longitude": -70.643092,
      "city": "Menemsha",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2553,
      "latitude": 41.67336,
      "longitude": -70.608047,
      "city": "Monument Beach",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2554,
      "latitude": 41.287647,
      "longitude": -70.08665,
      "city": "Nantucket",
      "state": "MA",
      "county": "Nantucket"
  },
  {
      "zipCode": 2556,
      "latitude": 41.652967,
      "longitude": -70.375475,
      "city": "North Falmouth",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2557,
      "latitude": 41.417376,
      "longitude": -70.560032,
      "city": "Oak Bluffs",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2558,
      "latitude": 41.74756,
      "longitude": -70.658164,
      "city": "Onset",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2559,
      "latitude": 41.694771,
      "longitude": -70.622769,
      "city": "Pocasset",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2561,
      "latitude": 41.770254,
      "longitude": -70.533664,
      "city": "Sagamore",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2562,
      "latitude": 41.793263,
      "longitude": -70.519584,
      "city": "Sagamore Beach",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2563,
      "latitude": 41.711291,
      "longitude": -70.477482,
      "city": "Sandwich",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2564,
      "latitude": 41.273949,
      "longitude": -70.015545,
      "city": "Siasconset",
      "state": "MA",
      "county": "Nantucket"
  },
  {
      "zipCode": 2565,
      "latitude": 41.799312,
      "longitude": -70.308662,
      "city": "Silver Beach",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2568,
      "latitude": 41.41595,
      "longitude": -70.595235,
      "city": "Vineyard Haven",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2571,
      "latitude": 41.760216,
      "longitude": -70.694662,
      "city": "Wareham",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2573,
      "latitude": 41.379836,
      "longitude": -70.643092,
      "city": "West Chop",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2574,
      "latitude": 41.603946,
      "longitude": -70.638189,
      "city": "West Falmouth",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2575,
      "latitude": 41.42125,
      "longitude": -70.642806,
      "city": "West Tisbury",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2576,
      "latitude": 41.769863,
      "longitude": -70.749688,
      "city": "West Wareham",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2584,
      "latitude": 41.277794,
      "longitude": -70.046019,
      "city": "Nantucket",
      "state": "MA",
      "county": "Nantucket"
  },
  {
      "zipCode": 2601,
      "latitude": 41.829813,
      "longitude": -70.138834,
      "city": "Hyannis",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2630,
      "latitude": 41.697313,
      "longitude": -70.301394,
      "city": "Barnstable",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2631,
      "latitude": 41.852997,
      "longitude": -70.044462,
      "city": "Brewster",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2632,
      "latitude": 41.796311,
      "longitude": -70.175129,
      "city": "Centerville",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2633,
      "latitude": 41.859559,
      "longitude": -70.0468,
      "city": "Chatham",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2634,
      "latitude": 41.799312,
      "longitude": -70.308662,
      "city": "Centerville",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2635,
      "latitude": 41.624341,
      "longitude": -70.43638,
      "city": "Cotuit",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2636,
      "latitude": 41.799312,
      "longitude": -70.308662,
      "city": "Centerville",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2637,
      "latitude": 41.701438,
      "longitude": -70.277212,
      "city": "Cummaquid",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2638,
      "latitude": 41.725716,
      "longitude": -70.089142,
      "city": "Dennis",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2639,
      "latitude": 41.750745,
      "longitude": -70.071836,
      "city": "Dennis Port",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2641,
      "latitude": 41.734713,
      "longitude": -70.20467,
      "city": "East Dennis",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2642,
      "latitude": 41.850612,
      "longitude": -70.020648,
      "city": "Eastham",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2643,
      "latitude": 41.784308,
      "longitude": -69.962034,
      "city": "East Orleans",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2644,
      "latitude": 41.790031,
      "longitude": -70.268632,
      "city": "Forestdale",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2645,
      "latitude": 41.835582,
      "longitude": -70.043359,
      "city": "Harwich",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2646,
      "latitude": 41.845717,
      "longitude": -70.053544,
      "city": "Harwich Port",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2647,
      "latitude": 41.635004,
      "longitude": -70.306336,
      "city": "Hyannis Port",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2648,
      "latitude": 41.813034,
      "longitude": -70.246666,
      "city": "Marstons Mills",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2649,
      "latitude": 41.788337,
      "longitude": -70.253543,
      "city": "Mashpee",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2650,
      "latitude": 41.73497,
      "longitude": -70.029065,
      "city": "North Chatham",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2651,
      "latitude": 41.824264,
      "longitude": -69.98176,
      "city": "North Eastham",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2652,
      "latitude": 41.931061,
      "longitude": -70.283584,
      "city": "North Truro",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2653,
      "latitude": 41.852933,
      "longitude": -70.01539,
      "city": "Orleans",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2655,
      "latitude": 41.810178,
      "longitude": -70.191269,
      "city": "Osterville",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2657,
      "latitude": 41.888775,
      "longitude": -70.091057,
      "city": "Provincetown",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2659,
      "latitude": 41.848932,
      "longitude": -70.030194,
      "city": "South Chatham",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2660,
      "latitude": 41.80038,
      "longitude": -70.089244,
      "city": "South Dennis",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2661,
      "latitude": 41.686205,
      "longitude": -70.032858,
      "city": "South Harwich",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2662,
      "latitude": 41.756694,
      "longitude": -69.984123,
      "city": "South Orleans",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2663,
      "latitude": 41.800531,
      "longitude": -70.076776,
      "city": "South Wellfleet",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2664,
      "latitude": 41.82412,
      "longitude": -70.084259,
      "city": "South Yarmouth",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2666,
      "latitude": 41.987377,
      "longitude": -70.047163,
      "city": "Truro",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2667,
      "latitude": 41.821307,
      "longitude": -70.022806,
      "city": "Wellfleet",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2668,
      "latitude": 41.79055,
      "longitude": -70.201719,
      "city": "West Barnstable",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2669,
      "latitude": 41.698721,
      "longitude": -70.004937,
      "city": "West Chatham",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2670,
      "latitude": 41.710855,
      "longitude": -70.072195,
      "city": "West Dennis",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2671,
      "latitude": 41.846719,
      "longitude": -70.038282,
      "city": "West Harwich",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2672,
      "latitude": 41.635635,
      "longitude": -70.323307,
      "city": "West Hyannisport",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2673,
      "latitude": 41.776105,
      "longitude": -70.150512,
      "city": "West Yarmouth",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2675,
      "latitude": 41.716271,
      "longitude": -70.135884,
      "city": "Yarmouth Port",
      "state": "MA",
      "county": "Barnstable"
  },
  {
      "zipCode": 2702,
      "latitude": 41.782993,
      "longitude": -71.017328,
      "city": "Assonet",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2703,
      "latitude": 41.938976,
      "longitude": -71.302297,
      "city": "Attleboro",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2712,
      "latitude": 41.756214,
      "longitude": -71.067062,
      "city": "Chartley",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2713,
      "latitude": 41.42178,
      "longitude": -70.931309,
      "city": "Cuttyhunk",
      "state": "MA",
      "county": "Dukes"
  },
  {
      "zipCode": 2714,
      "latitude": 41.756214,
      "longitude": -71.067062,
      "city": "Dartmouth",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2715,
      "latitude": 41.817659,
      "longitude": -71.151787,
      "city": "Dighton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2717,
      "latitude": 41.747358,
      "longitude": -70.978947,
      "city": "East Freetown",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2718,
      "latitude": 41.871407,
      "longitude": -71.013148,
      "city": "East Taunton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2719,
      "latitude": 41.631672,
      "longitude": -70.870045,
      "city": "Fairhaven",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2720,
      "latitude": 41.819766,
      "longitude": -71.165971,
      "city": "Fall River",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2721,
      "latitude": 41.678895,
      "longitude": -71.153648,
      "city": "Fall River",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2722,
      "latitude": 41.756214,
      "longitude": -71.067062,
      "city": "Fall River",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2723,
      "latitude": 41.693802,
      "longitude": -71.133088,
      "city": "Fall River",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2724,
      "latitude": 41.684202,
      "longitude": -71.175139,
      "city": "Fall River",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2725,
      "latitude": 41.723851,
      "longitude": -71.173989,
      "city": "Somerset",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2726,
      "latitude": 41.757951,
      "longitude": -71.153639,
      "city": "Somerset",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2738,
      "latitude": 41.736735,
      "longitude": -70.754015,
      "city": "Marion",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2739,
      "latitude": 41.664976,
      "longitude": -70.810856,
      "city": "Mattapoisett",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2740,
      "latitude": 41.633416,
      "longitude": -70.951045,
      "city": "New Bedford",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2741,
      "latitude": 41.756214,
      "longitude": -71.067062,
      "city": "New Bedford",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2742,
      "latitude": 41.619557,
      "longitude": -70.956346,
      "city": "New Bedford",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2743,
      "latitude": 41.711894,
      "longitude": -70.908286,
      "city": "Acushnet",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2744,
      "latitude": 41.609354,
      "longitude": -70.916181,
      "city": "New Bedford",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2745,
      "latitude": 41.7087,
      "longitude": -70.946883,
      "city": "New Bedford",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2746,
      "latitude": 41.665704,
      "longitude": -70.943021,
      "city": "New Bedford",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2747,
      "latitude": 41.639261,
      "longitude": -71.007578,
      "city": "North Dartmouth",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2748,
      "latitude": 41.566464,
      "longitude": -70.984253,
      "city": "South Dartmouth",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2760,
      "latitude": 41.964376,
      "longitude": -71.326448,
      "city": "North Attleboro",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2761,
      "latitude": 41.756214,
      "longitude": -71.067062,
      "city": "North Attleboro",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2762,
      "latitude": 42.013553,
      "longitude": -71.334399,
      "city": "Plainville",
      "state": "MA",
      "county": "Norfolk"
  },
  {
      "zipCode": 2763,
      "latitude": 41.972584,
      "longitude": -71.308229,
      "city": "Attleboro Falls",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2764,
      "latitude": 41.847791,
      "longitude": -71.155797,
      "city": "North Dighton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2766,
      "latitude": 41.959149,
      "longitude": -71.180393,
      "city": "Norton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2767,
      "latitude": 41.9367,
      "longitude": -71.048941,
      "city": "Raynham",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2768,
      "latitude": 41.756214,
      "longitude": -71.067062,
      "city": "Raynham Center",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2769,
      "latitude": 41.852989,
      "longitude": -71.243061,
      "city": "Rehoboth",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2770,
      "latitude": 41.751812,
      "longitude": -70.846041,
      "city": "Rochester",
      "state": "MA",
      "county": "Plymouth"
  },
  {
      "zipCode": 2771,
      "latitude": 41.840103,
      "longitude": -71.318995,
      "city": "Seekonk",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2777,
      "latitude": 41.766629,
      "longitude": -71.234443,
      "city": "Swansea",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2779,
      "latitude": 41.828249,
      "longitude": -71.064135,
      "city": "Berkley",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2780,
      "latitude": 41.858851,
      "longitude": -71.092827,
      "city": "Taunton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2783,
      "latitude": 41.756214,
      "longitude": -71.067062,
      "city": "Taunton",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2790,
      "latitude": 41.61547,
      "longitude": -71.079636,
      "city": "Westport",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2791,
      "latitude": 41.519104,
      "longitude": -71.085137,
      "city": "Westport Point",
      "state": "MA",
      "county": "Bristol"
  },
  {
      "zipCode": 2801,
      "latitude": 41.530131,
      "longitude": -71.284066,
      "city": "Adamsville",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2802,
      "latitude": 41.954098,
      "longitude": -71.462053,
      "city": "Albion",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2804,
      "latitude": 41.322365,
      "longitude": -71.679251,
      "city": "Ashaway",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2806,
      "latitude": 41.742501,
      "longitude": -71.320395,
      "city": "Barrington",
      "state": "RI",
      "county": "Bristol"
  },
  {
      "zipCode": 2807,
      "latitude": 41.188888,
      "longitude": -71.577696,
      "city": "Block Island",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2808,
      "latitude": 41.403784,
      "longitude": -71.761665,
      "city": "Bradford",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2809,
      "latitude": 41.678378,
      "longitude": -71.2704,
      "city": "Bristol",
      "state": "RI",
      "county": "Bristol"
  },
  {
      "zipCode": 2812,
      "latitude": 41.469148,
      "longitude": -71.675092,
      "city": "Carolina",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2813,
      "latitude": 41.395079,
      "longitude": -71.66542,
      "city": "Charlestown",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2814,
      "latitude": 41.888971,
      "longitude": -71.688769,
      "city": "Chepachet",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2815,
      "latitude": 41.770525,
      "longitude": -71.65585,
      "city": "Clayville",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2816,
      "latitude": 41.694251,
      "longitude": -71.636786,
      "city": "Coventry",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2817,
      "latitude": 41.631168,
      "longitude": -71.667165,
      "city": "West Greenwich",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2818,
      "latitude": 41.64335,
      "longitude": -71.478002,
      "city": "East Greenwich",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2822,
      "latitude": 41.5469,
      "longitude": -71.650262,
      "city": "Exeter",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2823,
      "latitude": 41.7312,
      "longitude": -71.546756,
      "city": "Fiskeville",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2824,
      "latitude": 42.000248,
      "longitude": -71.563057,
      "city": "Forestdale",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2825,
      "latitude": 41.790569,
      "longitude": -71.70644,
      "city": "Foster",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2826,
      "latitude": 41.982417,
      "longitude": -71.600848,
      "city": "Glendale",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2827,
      "latitude": 41.691676,
      "longitude": -71.727467,
      "city": "Greene",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2828,
      "latitude": 41.879298,
      "longitude": -71.551682,
      "city": "Greenville",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2829,
      "latitude": 41.879298,
      "longitude": -71.589357,
      "city": "Harmony",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2830,
      "latitude": 41.97239,
      "longitude": -71.648502,
      "city": "Harrisville",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2831,
      "latitude": 41.753594,
      "longitude": -71.577746,
      "city": "Hope",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2832,
      "latitude": 41.509632,
      "longitude": -71.733875,
      "city": "Hope Valley",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2833,
      "latitude": 41.475117,
      "longitude": -71.772617,
      "city": "Hopkinton",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2835,
      "latitude": 41.51412,
      "longitude": -71.377247,
      "city": "Jamestown",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2836,
      "latitude": 41.447427,
      "longitude": -71.620432,
      "city": "Kenyon",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2837,
      "latitude": 41.510257,
      "longitude": -71.16634,
      "city": "Little Compton",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2838,
      "latitude": 41.967848,
      "longitude": -71.475604,
      "city": "Manville",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2839,
      "latitude": 41.941715,
      "longitude": -71.637581,
      "city": "Mapleville",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2840,
      "latitude": 41.487552,
      "longitude": -71.327084,
      "city": "Newport",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2841,
      "latitude": 41.498977,
      "longitude": -71.299004,
      "city": "Newport",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2842,
      "latitude": 41.519757,
      "longitude": -71.273101,
      "city": "Middletown",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2852,
      "latitude": 41.586851,
      "longitude": -71.464801,
      "city": "North Kingstown",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2854,
      "latitude": 41.375317,
      "longitude": -71.64393,
      "city": "North Kingstown",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2857,
      "latitude": 41.838827,
      "longitude": -71.655006,
      "city": "North Scituate",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2858,
      "latitude": 41.962303,
      "longitude": -71.647542,
      "city": "Oakland",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2859,
      "latitude": 41.96415,
      "longitude": -71.723316,
      "city": "Pascoag",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2860,
      "latitude": 41.875149,
      "longitude": -71.392732,
      "city": "Pawtucket",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2861,
      "latitude": 41.878249,
      "longitude": -71.369899,
      "city": "Pawtucket",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2862,
      "latitude": 41.86125,
      "longitude": -71.369099,
      "city": "Pawtucket",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2863,
      "latitude": 41.889849,
      "longitude": -71.394186,
      "city": "Central Falls",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2864,
      "latitude": 41.949498,
      "longitude": -71.43269,
      "city": "Cumberland",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2865,
      "latitude": 41.925298,
      "longitude": -71.493355,
      "city": "Lincoln",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2871,
      "latitude": 41.58523,
      "longitude": -71.264197,
      "city": "Portsmouth",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2872,
      "latitude": 41.707119,
      "longitude": -71.286834,
      "city": "Prudence Island",
      "state": "RI",
      "county": "Bristol"
  },
  {
      "zipCode": 2873,
      "latitude": 41.519922,
      "longitude": -71.774023,
      "city": "Rockville",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2874,
      "latitude": 41.478466,
      "longitude": -71.471752,
      "city": "Saunderstown",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2875,
      "latitude": 41.456039,
      "longitude": -71.635474,
      "city": "Shannock",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2876,
      "latitude": 41.998398,
      "longitude": -71.576307,
      "city": "Slatersville",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2877,
      "latitude": 41.5289,
      "longitude": -71.529854,
      "city": "Slocum",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2878,
      "latitude": 41.61018,
      "longitude": -71.175183,
      "city": "Tiverton",
      "state": "RI",
      "county": "Newport"
  },
  {
      "zipCode": 2879,
      "latitude": 41.443653,
      "longitude": -71.534202,
      "city": "Wakefield",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2880,
      "latitude": 41.375317,
      "longitude": -71.64393,
      "city": "Wakefield",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2881,
      "latitude": 41.482901,
      "longitude": -71.524317,
      "city": "Kingston",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2882,
      "latitude": 41.377185,
      "longitude": -71.497793,
      "city": "Narragansett",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2883,
      "latitude": 41.375317,
      "longitude": -71.64393,
      "city": "Peace Dale",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2885,
      "latitude": 41.727451,
      "longitude": -71.257492,
      "city": "Warren",
      "state": "RI",
      "county": "Bristol"
  },
  {
      "zipCode": 2886,
      "latitude": 41.70247,
      "longitude": -71.47902,
      "city": "Warwick",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2887,
      "latitude": 41.682455,
      "longitude": -71.557732,
      "city": "Warwick",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2888,
      "latitude": 41.7473,
      "longitude": -71.411088,
      "city": "Warwick",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2889,
      "latitude": 41.689182,
      "longitude": -71.496048,
      "city": "Warwick",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2891,
      "latitude": 41.284494,
      "longitude": -71.710708,
      "city": "Westerly",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2892,
      "latitude": 41.505764,
      "longitude": -71.62114,
      "city": "West Kingston",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2893,
      "latitude": 41.696822,
      "longitude": -71.507304,
      "city": "West Warwick",
      "state": "RI",
      "county": "Kent"
  },
  {
      "zipCode": 2894,
      "latitude": 41.45005,
      "longitude": -71.70742,
      "city": "Wood River Junction",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2895,
      "latitude": 41.984598,
      "longitude": -71.51939,
      "city": "Woonsocket",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2896,
      "latitude": 41.934348,
      "longitude": -71.540506,
      "city": "North Smithfield",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2898,
      "latitude": 41.504089,
      "longitude": -71.662954,
      "city": "Wyoming",
      "state": "RI",
      "county": "Washington"
  },
  {
      "zipCode": 2901,
      "latitude": 41.82275,
      "longitude": -71.414451,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2902,
      "latitude": 41.81835,
      "longitude": -71.424851,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2903,
      "latitude": 41.819459,
      "longitude": -71.4115,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2904,
      "latitude": 41.85414,
      "longitude": -71.437752,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2905,
      "latitude": 41.803799,
      "longitude": -71.422547,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2906,
      "latitude": 41.8374,
      "longitude": -71.394717,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2907,
      "latitude": 41.797065,
      "longitude": -71.425501,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2908,
      "latitude": 41.837399,
      "longitude": -71.4399,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2909,
      "latitude": 41.820599,
      "longitude": -71.444302,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2910,
      "latitude": 41.7917,
      "longitude": -71.435251,
      "city": "Cranston",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2911,
      "latitude": 41.838749,
      "longitude": -71.449202,
      "city": "North Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2912,
      "latitude": 41.826737,
      "longitude": -71.397699,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2914,
      "latitude": 41.813429,
      "longitude": -71.363348,
      "city": "East Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2915,
      "latitude": 41.7743,
      "longitude": -71.349697,
      "city": "Riverside",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2916,
      "latitude": 41.842727,
      "longitude": -71.355798,
      "city": "Rumford",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2917,
      "latitude": 41.853899,
      "longitude": -71.497804,
      "city": "Smithfield",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2918,
      "latitude": 41.841499,
      "longitude": -71.440352,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2919,
      "latitude": 41.870932,
      "longitude": -71.497604,
      "city": "Johnston",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2920,
      "latitude": 41.7693,
      "longitude": -71.471353,
      "city": "Cranston",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2921,
      "latitude": 41.76675,
      "longitude": -71.476703,
      "city": "Cranston",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 2940,
      "latitude": 41.871766,
      "longitude": -71.558518,
      "city": "Providence",
      "state": "RI",
      "county": "Providence"
  },
  {
      "zipCode": 3031,
      "latitude": 42.87474,
      "longitude": -71.629365,
      "city": "Amherst",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3032,
      "latitude": 42.989151,
      "longitude": -71.343576,
      "city": "Auburn",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3033,
      "latitude": 42.847268,
      "longitude": -71.767437,
      "city": "Brookline",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3034,
      "latitude": 43.059283,
      "longitude": -71.305437,
      "city": "Candia",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3036,
      "latitude": 42.961606,
      "longitude": -71.251021,
      "city": "Chester",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3037,
      "latitude": 42.987495,
      "longitude": -71.252719,
      "city": "Deerfield",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3038,
      "latitude": 42.950825,
      "longitude": -71.197169,
      "city": "Derry",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3040,
      "latitude": 43.005895,
      "longitude": -71.013202,
      "city": "East Candia",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3041,
      "latitude": 43.005895,
      "longitude": -71.013202,
      "city": "East Derry",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3042,
      "latitude": 43.047595,
      "longitude": -71.084411,
      "city": "Epping",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3043,
      "latitude": 42.916233,
      "longitude": -71.816505,
      "city": "Francestown",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3044,
      "latitude": 42.992203,
      "longitude": -71.127041,
      "city": "Fremont",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3045,
      "latitude": 42.94671,
      "longitude": -71.687633,
      "city": "Goffstown",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3046,
      "latitude": 43.10118,
      "longitude": -71.60501,
      "city": "Dunbarton",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3047,
      "latitude": 42.929346,
      "longitude": -71.859087,
      "city": "Greenfield",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3048,
      "latitude": 42.875116,
      "longitude": -71.717548,
      "city": "Greenville",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3049,
      "latitude": 42.859545,
      "longitude": -71.581313,
      "city": "Hollis",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3051,
      "latitude": 42.766426,
      "longitude": -71.61887,
      "city": "Hudson",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3052,
      "latitude": 42.886789,
      "longitude": -71.485331,
      "city": "Litchfield",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3053,
      "latitude": 42.8712,
      "longitude": -71.387776,
      "city": "Londonderry",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3054,
      "latitude": 42.924928,
      "longitude": -71.667508,
      "city": "Merrimack",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3055,
      "latitude": 42.848787,
      "longitude": -71.743882,
      "city": "Milford",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3057,
      "latitude": 42.850464,
      "longitude": -71.727044,
      "city": "Mont Vernon",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3060,
      "latitude": 42.771537,
      "longitude": -71.626336,
      "city": "Nashua",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3061,
      "latitude": 42.952124,
      "longitude": -71.653939,
      "city": "Nashua",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3062,
      "latitude": 42.860117,
      "longitude": -71.49976,
      "city": "Nashua",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3063,
      "latitude": 42.774427,
      "longitude": -71.511111,
      "city": "Nashua",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3064,
      "latitude": 42.774237,
      "longitude": -71.628611,
      "city": "Nashua",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3070,
      "latitude": 42.903497,
      "longitude": -71.775889,
      "city": "New Boston",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3071,
      "latitude": 42.762946,
      "longitude": -71.845164,
      "city": "New Ipswich",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3073,
      "latitude": 43.005895,
      "longitude": -71.013202,
      "city": "North Salem",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3076,
      "latitude": 42.740651,
      "longitude": -71.318715,
      "city": "Pelham",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3077,
      "latitude": 43.05932,
      "longitude": -71.204521,
      "city": "Raymond",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3079,
      "latitude": 42.872045,
      "longitude": -71.196195,
      "city": "Salem",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3082,
      "latitude": 42.902031,
      "longitude": -71.773947,
      "city": "Lyndeborough",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3084,
      "latitude": 42.822275,
      "longitude": -71.860983,
      "city": "Temple",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3086,
      "latitude": 42.894022,
      "longitude": -71.730154,
      "city": "Wilton",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3087,
      "latitude": 42.892211,
      "longitude": -71.234009,
      "city": "Windham",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3101,
      "latitude": 42.988483,
      "longitude": -71.462111,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3102,
      "latitude": 43.007883,
      "longitude": -71.494561,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3103,
      "latitude": 42.942463,
      "longitude": -71.463761,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3104,
      "latitude": 42.897524,
      "longitude": -71.372208,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3105,
      "latitude": 42.952124,
      "longitude": -71.653939,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3106,
      "latitude": 43.065097,
      "longitude": -71.443616,
      "city": "Hooksett",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3107,
      "latitude": 42.952124,
      "longitude": -71.653939,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3108,
      "latitude": 42.952124,
      "longitude": -71.653939,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3109,
      "latitude": 42.970084,
      "longitude": -71.405283,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3110,
      "latitude": 42.93743,
      "longitude": -71.535515,
      "city": "Bedford",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3111,
      "latitude": 42.952124,
      "longitude": -71.653939,
      "city": "Manchester",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3215,
      "latitude": 43.930221,
      "longitude": -71.534068,
      "city": "Waterville Valley",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3216,
      "latitude": 43.447006,
      "longitude": -71.684093,
      "city": "Andover",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3217,
      "latitude": 43.715626,
      "longitude": -71.639896,
      "city": "Ashland",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3218,
      "latitude": 43.417369,
      "longitude": -71.373189,
      "city": "Barnstead",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3220,
      "latitude": 43.455551,
      "longitude": -71.40709,
      "city": "Belmont",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3221,
      "latitude": 43.280961,
      "longitude": -71.792489,
      "city": "Bradford",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3222,
      "latitude": 43.70781,
      "longitude": -71.72158,
      "city": "Bristol",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3223,
      "latitude": 43.841799,
      "longitude": -71.665223,
      "city": "Campton",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3224,
      "latitude": 43.378281,
      "longitude": -71.604226,
      "city": "Canterbury",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3225,
      "latitude": 43.386652,
      "longitude": -71.292981,
      "city": "Center Barnstead",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3226,
      "latitude": 43.69387,
      "longitude": -71.466212,
      "city": "Center Harbor",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3227,
      "latitude": 43.694809,
      "longitude": -71.323953,
      "city": "Center Sandwich",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3229,
      "latitude": 43.301308,
      "longitude": -71.812943,
      "city": "Contoocook",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3230,
      "latitude": 43.514791,
      "longitude": -71.882094,
      "city": "Danbury",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3231,
      "latitude": 43.466172,
      "longitude": -71.736518,
      "city": "East Andover",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3232,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "East Hebron",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3233,
      "latitude": 43.422706,
      "longitude": -71.944794,
      "city": "Elkins",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3234,
      "latitude": 43.340736,
      "longitude": -71.669712,
      "city": "Epsom",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3235,
      "latitude": 43.374297,
      "longitude": -71.680021,
      "city": "Franklin",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3237,
      "latitude": 43.414064,
      "longitude": -71.384213,
      "city": "Gilmanton",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3238,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Glencliff",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3240,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Grafton",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3241,
      "latitude": 43.718268,
      "longitude": -71.889483,
      "city": "Hebron",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3242,
      "latitude": 43.180148,
      "longitude": -71.81959,
      "city": "Henniker",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3243,
      "latitude": 43.443473,
      "longitude": -71.762631,
      "city": "Hill",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3244,
      "latitude": 43.12295,
      "longitude": -71.914287,
      "city": "Hillsboro",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3245,
      "latitude": 43.737831,
      "longitude": -71.604406,
      "city": "Holderness",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3246,
      "latitude": 43.535461,
      "longitude": -71.426997,
      "city": "Laconia",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3247,
      "latitude": 43.588972,
      "longitude": -71.445452,
      "city": "Laconia",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3249,
      "latitude": 43.5586,
      "longitude": -71.3844,
      "city": "Gilford",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3251,
      "latitude": 44.049183,
      "longitude": -71.653505,
      "city": "Lincoln",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3252,
      "latitude": 43.468562,
      "longitude": -71.537145,
      "city": "Lochmere",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3253,
      "latitude": 43.592239,
      "longitude": -71.517479,
      "city": "Meredith",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3254,
      "latitude": 43.728235,
      "longitude": -71.335248,
      "city": "Moultonborough",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3255,
      "latitude": 43.321723,
      "longitude": -72.01184,
      "city": "Newbury",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3256,
      "latitude": 43.603288,
      "longitude": -71.634674,
      "city": "New Hampton",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3257,
      "latitude": 43.301663,
      "longitude": -71.733643,
      "city": "New London",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3258,
      "latitude": 43.265259,
      "longitude": -71.408835,
      "city": "Chichester",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3259,
      "latitude": 43.85971,
      "longitude": -71.37719,
      "city": "North Sandwich",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3260,
      "latitude": 43.365345,
      "longitude": -71.934059,
      "city": "North Sutton",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3261,
      "latitude": 43.208676,
      "longitude": -71.202568,
      "city": "Northwood",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3262,
      "latitude": 44.035391,
      "longitude": -71.686326,
      "city": "North Woodstock",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3263,
      "latitude": 43.297834,
      "longitude": -71.343707,
      "city": "Pittsfield",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3264,
      "latitude": 43.710919,
      "longitude": -71.694017,
      "city": "Plymouth",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3266,
      "latitude": 43.752475,
      "longitude": -71.788434,
      "city": "Rumney",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3268,
      "latitude": 43.361756,
      "longitude": -71.558817,
      "city": "Salisbury",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3269,
      "latitude": 43.50178,
      "longitude": -71.514248,
      "city": "Sanbornton",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3272,
      "latitude": 43.310279,
      "longitude": -71.662928,
      "city": "South Newbury",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3273,
      "latitude": 43.304205,
      "longitude": -71.928867,
      "city": "South Sutton",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3274,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Stinson Lake",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3275,
      "latitude": 43.286382,
      "longitude": -71.599047,
      "city": "Suncook",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3276,
      "latitude": 43.536056,
      "longitude": -71.49166,
      "city": "Tilton",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3278,
      "latitude": 43.310858,
      "longitude": -71.842299,
      "city": "Warner",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3279,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Warren",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3280,
      "latitude": 43.17229,
      "longitude": -72.100689,
      "city": "Washington",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3281,
      "latitude": 43.084176,
      "longitude": -71.762578,
      "city": "Weare",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3282,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Wentworth",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3284,
      "latitude": 43.493812,
      "longitude": -72.047018,
      "city": "Springfield",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3287,
      "latitude": 43.44853,
      "longitude": -71.915826,
      "city": "Wilmot",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3289,
      "latitude": 43.496372,
      "longitude": -71.519728,
      "city": "Winnisquam",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3290,
      "latitude": 43.124825,
      "longitude": -71.125879,
      "city": "Nottingham",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3291,
      "latitude": 43.182251,
      "longitude": -71.139551,
      "city": "West Nottingham",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3293,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Woodstock",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3298,
      "latitude": 43.524872,
      "longitude": -71.445841,
      "city": "Tilton",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3299,
      "latitude": 43.524872,
      "longitude": -71.445841,
      "city": "Tilton",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3301,
      "latitude": 43.230314,
      "longitude": -71.536101,
      "city": "Concord",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3302,
      "latitude": 43.310279,
      "longitude": -71.662928,
      "city": "Concord",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3303,
      "latitude": 43.281654,
      "longitude": -71.659523,
      "city": "Concord",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3304,
      "latitude": 43.128256,
      "longitude": -71.544599,
      "city": "Bow",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3305,
      "latitude": 43.2134,
      "longitude": -71.517151,
      "city": "Concord",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3307,
      "latitude": 43.319339,
      "longitude": -71.467006,
      "city": "Loudon",
      "state": "NH",
      "county": "Merrimack"
  },
  {
      "zipCode": 3431,
      "latitude": 42.903656,
      "longitude": -72.248052,
      "city": "Keene",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3435,
      "latitude": 42.947098,
      "longitude": -72.243029,
      "city": "Keene",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3440,
      "latitude": 43.044535,
      "longitude": -71.971353,
      "city": "Antrim",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3441,
      "latitude": 42.777271,
      "longitude": -72.444106,
      "city": "Ashuelot",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3442,
      "latitude": 43.002936,
      "longitude": -71.908029,
      "city": "Bennington",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3443,
      "latitude": 42.883948,
      "longitude": -72.454813,
      "city": "Chesterfield",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3444,
      "latitude": 42.889124,
      "longitude": -72.018993,
      "city": "Dublin",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3445,
      "latitude": 42.998225,
      "longitude": -72.201903,
      "city": "Sullivan",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3446,
      "latitude": 42.841079,
      "longitude": -72.330145,
      "city": "Swanzey",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3447,
      "latitude": 42.881769,
      "longitude": -72.248026,
      "city": "Fitzwilliam",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3448,
      "latitude": 42.936786,
      "longitude": -72.210953,
      "city": "Gilsum",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3449,
      "latitude": 42.93924,
      "longitude": -71.983797,
      "city": "Hancock",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3450,
      "latitude": 42.884548,
      "longitude": -72.102634,
      "city": "Harrisville",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3451,
      "latitude": 42.894151,
      "longitude": -72.280099,
      "city": "Hinsdale",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3452,
      "latitude": 42.821969,
      "longitude": -72.082559,
      "city": "Jaffrey",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3455,
      "latitude": 42.88825,
      "longitude": -72.279638,
      "city": "Marlborough",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3456,
      "latitude": 43.119294,
      "longitude": -72.202564,
      "city": "Marlow",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3457,
      "latitude": 42.896623,
      "longitude": -72.091677,
      "city": "Nelson",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3458,
      "latitude": 42.956433,
      "longitude": -71.9372,
      "city": "Peterborough",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3461,
      "latitude": 42.76509,
      "longitude": -72.00912,
      "city": "Rindge",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3462,
      "latitude": 42.870716,
      "longitude": -72.239432,
      "city": "Spofford",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3464,
      "latitude": 42.95478,
      "longitude": -72.102454,
      "city": "Stoddard",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3465,
      "latitude": 42.843617,
      "longitude": -72.279814,
      "city": "Troy",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3466,
      "latitude": 42.858419,
      "longitude": -72.432202,
      "city": "West Chesterfield",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3467,
      "latitude": 42.973867,
      "longitude": -72.44256,
      "city": "Westmoreland",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3468,
      "latitude": 42.890804,
      "longitude": -71.933396,
      "city": "West Peterborough",
      "state": "NH",
      "county": "Hillsborough"
  },
  {
      "zipCode": 3469,
      "latitude": 42.87341,
      "longitude": -72.31509,
      "city": "West Swanzey",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3470,
      "latitude": 42.895797,
      "longitude": -72.274928,
      "city": "Winchester",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3561,
      "latitude": 44.335289,
      "longitude": -71.877638,
      "city": "Littleton",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3570,
      "latitude": 44.512344,
      "longitude": -71.194226,
      "city": "Berlin",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3574,
      "latitude": 44.30632,
      "longitude": -71.728049,
      "city": "Bethlehem",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3575,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "Bretton Woods",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3576,
      "latitude": 44.902731,
      "longitude": -71.500809,
      "city": "Colebrook",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3579,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "Errol",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3580,
      "latitude": 44.212814,
      "longitude": -71.737162,
      "city": "Franconia",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3581,
      "latitude": 44.404848,
      "longitude": -71.154758,
      "city": "Gorham",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3582,
      "latitude": 44.75096,
      "longitude": -71.422453,
      "city": "Groveton",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3583,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "Jefferson",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3584,
      "latitude": 44.476575,
      "longitude": -71.561225,
      "city": "Lancaster",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3585,
      "latitude": 44.245004,
      "longitude": -71.888882,
      "city": "Lisbon",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3587,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "Meadows",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3588,
      "latitude": 44.56532,
      "longitude": -71.218672,
      "city": "Milan",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3589,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "Mount Washington",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3590,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "North Stratford",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3592,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "Pittsburg",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3595,
      "latitude": 44.26895,
      "longitude": -71.547061,
      "city": "Twin Mountain",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3597,
      "latitude": 44.695648,
      "longitude": -71.387387,
      "city": "West Stewartstown",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3598,
      "latitude": 44.363681,
      "longitude": -71.610189,
      "city": "Whitefield",
      "state": "NH",
      "county": "Coos"
  },
  {
      "zipCode": 3601,
      "latitude": 43.195982,
      "longitude": -72.300075,
      "city": "Acworth",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3602,
      "latitude": 43.134412,
      "longitude": -72.310623,
      "city": "Alstead",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3603,
      "latitude": 43.268117,
      "longitude": -72.370222,
      "city": "Charlestown",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3604,
      "latitude": 42.947098,
      "longitude": -72.243029,
      "city": "Drewsville",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3605,
      "latitude": 43.213615,
      "longitude": -72.196758,
      "city": "Lempster",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3607,
      "latitude": 43.364504,
      "longitude": -72.193966,
      "city": "South Acworth",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3608,
      "latitude": 43.072239,
      "longitude": -72.390121,
      "city": "Walpole",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3609,
      "latitude": 43.138018,
      "longitude": -72.407236,
      "city": "North Walpole",
      "state": "NH",
      "county": "Cheshire"
  },
  {
      "zipCode": 3740,
      "latitude": 44.114163,
      "longitude": -71.948852,
      "city": "Bath",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3741,
      "latitude": 43.63123,
      "longitude": -72.057312,
      "city": "Canaan",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3743,
      "latitude": 43.415993,
      "longitude": -72.209819,
      "city": "Claremont",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3745,
      "latitude": 43.462156,
      "longitude": -72.337149,
      "city": "Cornish",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3746,
      "latitude": 43.364504,
      "longitude": -72.193966,
      "city": "Cornish Flat",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3748,
      "latitude": 43.630906,
      "longitude": -72.116603,
      "city": "Enfield",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3749,
      "latitude": 43.579573,
      "longitude": -72.08541,
      "city": "Enfield Center",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3750,
      "latitude": 43.697999,
      "longitude": -72.215182,
      "city": "Etna",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3751,
      "latitude": 43.44872,
      "longitude": -72.063675,
      "city": "Georges Mills",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3752,
      "latitude": 43.417705,
      "longitude": -72.196718,
      "city": "Goshen",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3753,
      "latitude": 43.463101,
      "longitude": -72.218445,
      "city": "Grantham",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3754,
      "latitude": 43.365618,
      "longitude": -72.125562,
      "city": "Guild",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3755,
      "latitude": 43.861077,
      "longitude": -72.120314,
      "city": "Hanover",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3756,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Lebanon",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3765,
      "latitude": 44.037549,
      "longitude": -72.046692,
      "city": "Haverhill",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3766,
      "latitude": 43.637148,
      "longitude": -72.23546,
      "city": "Lebanon",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3768,
      "latitude": 43.801656,
      "longitude": -72.130495,
      "city": "Lyme",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3769,
      "latitude": 43.786267,
      "longitude": -72.120468,
      "city": "Lyme Center",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3770,
      "latitude": 43.421675,
      "longitude": -72.255638,
      "city": "Meriden",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3771,
      "latitude": 44.268612,
      "longitude": -72.015317,
      "city": "Monroe",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3773,
      "latitude": 43.390485,
      "longitude": -72.199659,
      "city": "Newport",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3774,
      "latitude": 44.077803,
      "longitude": -72.014608,
      "city": "North Haverhill",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3777,
      "latitude": 43.927348,
      "longitude": -72.10987,
      "city": "Orford",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3779,
      "latitude": 43.948343,
      "longitude": -72.062132,
      "city": "Piermont",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3780,
      "latitude": 43.967542,
      "longitude": -71.840883,
      "city": "Pike",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3781,
      "latitude": 43.432999,
      "longitude": -72.282596,
      "city": "Plainfield",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3782,
      "latitude": 43.426838,
      "longitude": -72.15471,
      "city": "Sunapee",
      "state": "NH",
      "county": "Sullivan"
  },
  {
      "zipCode": 3784,
      "latitude": 43.644367,
      "longitude": -72.2348,
      "city": "West Lebanon",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3785,
      "latitude": 43.948644,
      "longitude": -72.080708,
      "city": "Woodsville",
      "state": "NH",
      "county": "Grafton"
  },
  {
      "zipCode": 3801,
      "latitude": 43.009222,
      "longitude": -70.950742,
      "city": "Portsmouth",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3802,
      "latitude": 43.005895,
      "longitude": -71.013202,
      "city": "Portsmouth",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3803,
      "latitude": 42.92703,
      "longitude": -71.444752,
      "city": "Portsmouth",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3804,
      "latitude": 43.005895,
      "longitude": -71.013202,
      "city": "Portsmouth",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3805,
      "latitude": 43.285282,
      "longitude": -70.930684,
      "city": "Rollinsford",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3809,
      "latitude": 43.462586,
      "longitude": -71.222455,
      "city": "Alton",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3810,
      "latitude": 43.502514,
      "longitude": -71.277387,
      "city": "Alton Bay",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3811,
      "latitude": 42.878366,
      "longitude": -71.159035,
      "city": "Atkinson",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3812,
      "latitude": 43.883871,
      "longitude": -71.257726,
      "city": "Bartlett",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3813,
      "latitude": 43.797391,
      "longitude": -71.145357,
      "city": "Center Conway",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3814,
      "latitude": 43.762429,
      "longitude": -71.122689,
      "city": "Center Ossipee",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3815,
      "latitude": 43.253739,
      "longitude": -71.109825,
      "city": "Center Strafford",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3816,
      "latitude": 43.660752,
      "longitude": -71.274458,
      "city": "Center Tuftonboro",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3817,
      "latitude": 43.74858,
      "longitude": -71.089796,
      "city": "Chocorua",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3818,
      "latitude": 43.78892,
      "longitude": -71.10261,
      "city": "Conway",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3819,
      "latitude": 42.926725,
      "longitude": -71.082299,
      "city": "Danville",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3820,
      "latitude": 43.297309,
      "longitude": -70.992042,
      "city": "Dover",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3821,
      "latitude": 43.326734,
      "longitude": -71.028427,
      "city": "Dover",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3822,
      "latitude": 43.326734,
      "longitude": -71.028427,
      "city": "Dover",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3824,
      "latitude": 43.165772,
      "longitude": -70.962843,
      "city": "Durham",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3825,
      "latitude": 43.29779,
      "longitude": -71.097423,
      "city": "Barrington",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3826,
      "latitude": 42.893629,
      "longitude": -71.14269,
      "city": "East Hampstead",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3827,
      "latitude": 42.912071,
      "longitude": -71.054245,
      "city": "East Kingston",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3830,
      "latitude": 43.635065,
      "longitude": -71.009368,
      "city": "East Wakefield",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3832,
      "latitude": 43.883871,
      "longitude": -71.257726,
      "city": "Eaton Center",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3833,
      "latitude": 42.951274,
      "longitude": -70.922262,
      "city": "Exeter",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3835,
      "latitude": 43.306172,
      "longitude": -71.003435,
      "city": "Farmington",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3836,
      "latitude": 43.823704,
      "longitude": -71.093154,
      "city": "Freedom",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3837,
      "latitude": 43.446567,
      "longitude": -71.299456,
      "city": "Gilmanton Iron Works",
      "state": "NH",
      "county": "Belknap"
  },
  {
      "zipCode": 3838,
      "latitude": 44.050111,
      "longitude": -71.265371,
      "city": "Glen",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3839,
      "latitude": 43.309966,
      "longitude": -70.998256,
      "city": "Rochester",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3840,
      "latitude": 43.038634,
      "longitude": -70.809157,
      "city": "Greenland",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3841,
      "latitude": 42.877438,
      "longitude": -71.139098,
      "city": "Hampstead",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3842,
      "latitude": 42.917389,
      "longitude": -70.95095,
      "city": "Hampton",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3843,
      "latitude": 43.005895,
      "longitude": -71.013202,
      "city": "Hampton",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3844,
      "latitude": 42.891888,
      "longitude": -70.982601,
      "city": "Hampton Falls",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3845,
      "latitude": 44.082716,
      "longitude": -71.134348,
      "city": "Intervale",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3846,
      "latitude": 43.883871,
      "longitude": -71.257726,
      "city": "Jackson",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3847,
      "latitude": 43.883871,
      "longitude": -71.257726,
      "city": "Kearsarge",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3848,
      "latitude": 42.909731,
      "longitude": -71.102933,
      "city": "Kingston",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3849,
      "latitude": 43.902311,
      "longitude": -71.124612,
      "city": "Madison",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3850,
      "latitude": 43.707657,
      "longitude": -71.301681,
      "city": "Melvin Village",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3851,
      "latitude": 43.422942,
      "longitude": -71.011369,
      "city": "Milton",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3852,
      "latitude": 43.326734,
      "longitude": -71.028427,
      "city": "Milton Mills",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3853,
      "latitude": 43.646319,
      "longitude": -71.282068,
      "city": "Mirror Lake",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3854,
      "latitude": 43.064938,
      "longitude": -70.721596,
      "city": "New Castle",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3855,
      "latitude": 43.459503,
      "longitude": -71.119055,
      "city": "New Durham",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3856,
      "latitude": 43.037363,
      "longitude": -70.978211,
      "city": "Newfields",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3857,
      "latitude": 42.979021,
      "longitude": -70.891642,
      "city": "Newmarket",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3858,
      "latitude": 42.937092,
      "longitude": -71.006898,
      "city": "Newton",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3859,
      "latitude": 42.861915,
      "longitude": -71.04002,
      "city": "Newton Junction",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3860,
      "latitude": 43.784643,
      "longitude": -71.10263,
      "city": "North Conway",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3862,
      "latitude": 43.00027,
      "longitude": -70.913947,
      "city": "North Hampton",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3864,
      "latitude": 43.667352,
      "longitude": -71.153338,
      "city": "Ossipee",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3865,
      "latitude": 42.937838,
      "longitude": -70.926206,
      "city": "Plaistow",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3866,
      "latitude": 43.41176,
      "longitude": -71.027303,
      "city": "Rochester",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3867,
      "latitude": 43.326831,
      "longitude": -70.993148,
      "city": "Rochester",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3868,
      "latitude": 43.349689,
      "longitude": -70.945161,
      "city": "Rochester",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3869,
      "latitude": 43.16136,
      "longitude": -70.942666,
      "city": "Rollinsford",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3870,
      "latitude": 43.010939,
      "longitude": -70.764813,
      "city": "Rye",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3871,
      "latitude": 42.980639,
      "longitude": -70.771896,
      "city": "Rye Beach",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3872,
      "latitude": 43.570694,
      "longitude": -71.079427,
      "city": "Sanbornville",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3873,
      "latitude": 42.922666,
      "longitude": -71.174262,
      "city": "Sandown",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3874,
      "latitude": 42.964415,
      "longitude": -70.819897,
      "city": "Seabrook",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3875,
      "latitude": 43.855269,
      "longitude": -71.176182,
      "city": "Silver Lake",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3878,
      "latitude": 43.229489,
      "longitude": -70.958264,
      "city": "Somersworth",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3882,
      "latitude": 43.704702,
      "longitude": -70.990155,
      "city": "Effingham",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3883,
      "latitude": 43.883871,
      "longitude": -71.257726,
      "city": "South Tamworth",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3884,
      "latitude": 43.256871,
      "longitude": -71.171886,
      "city": "Strafford",
      "state": "NH",
      "county": "Strafford"
  },
  {
      "zipCode": 3885,
      "latitude": 43.01295,
      "longitude": -70.882134,
      "city": "Stratham",
      "state": "NH",
      "county": "Rockingham"
  },
  {
      "zipCode": 3886,
      "latitude": 43.647527,
      "longitude": -71.277779,
      "city": "Tamworth",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3887,
      "latitude": 43.459999,
      "longitude": -71.040842,
      "city": "Union",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3890,
      "latitude": 43.811782,
      "longitude": -71.194586,
      "city": "West Ossipee",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3894,
      "latitude": 43.667102,
      "longitude": -71.183597,
      "city": "Wolfeboro",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3896,
      "latitude": 43.589157,
      "longitude": -71.218894,
      "city": "Wolfeboro Falls",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3897,
      "latitude": 43.883871,
      "longitude": -71.257726,
      "city": "Wonalancet",
      "state": "NH",
      "county": "Carroll"
  },
  {
      "zipCode": 3901,
      "latitude": 43.279539,
      "longitude": -70.740013,
      "city": "Berwick",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3902,
      "latitude": 43.245785,
      "longitude": -70.664719,
      "city": "Cape Neddick",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3903,
      "latitude": 43.182729,
      "longitude": -70.745759,
      "city": "Eliot",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3904,
      "latitude": 43.191554,
      "longitude": -70.684607,
      "city": "Kittery",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3905,
      "latitude": 43.094746,
      "longitude": -70.68928,
      "city": "Kittery Point",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3906,
      "latitude": 43.340626,
      "longitude": -70.753996,
      "city": "North Berwick",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3907,
      "latitude": 43.350806,
      "longitude": -70.731498,
      "city": "Ogunquit",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3908,
      "latitude": 43.227549,
      "longitude": -70.71171,
      "city": "South Berwick",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3909,
      "latitude": 43.237891,
      "longitude": -70.69099,
      "city": "York",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3910,
      "latitude": 43.211674,
      "longitude": -70.73201,
      "city": "York Beach",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 3911,
      "latitude": 43.155465,
      "longitude": -70.635702,
      "city": "York Harbor",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4001,
      "latitude": 43.520752,
      "longitude": -70.804588,
      "city": "Acton",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4002,
      "latitude": 43.467224,
      "longitude": -70.738992,
      "city": "Alfred",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4003,
      "latitude": 43.735867,
      "longitude": -69.995456,
      "city": "Bailey Island",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4004,
      "latitude": 43.365658,
      "longitude": -70.604379,
      "city": "Bar Mills",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4005,
      "latitude": 43.532376,
      "longitude": -70.651859,
      "city": "Biddeford",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4006,
      "latitude": 43.436049,
      "longitude": -70.3598,
      "city": "Biddeford Pool",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4007,
      "latitude": 43.45813,
      "longitude": -70.505301,
      "city": "Biddeford",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4008,
      "latitude": 44.023162,
      "longitude": -69.875816,
      "city": "Bowdoinham",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4009,
      "latitude": 43.939828,
      "longitude": -70.747431,
      "city": "Bridgton",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4010,
      "latitude": 44.163033,
      "longitude": -70.739823,
      "city": "Brownfield",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4011,
      "latitude": 43.935634,
      "longitude": -70.340652,
      "city": "Brunswick",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4013,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Bustins Island",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4014,
      "latitude": 43.365658,
      "longitude": -70.604379,
      "city": "Cape Porpoise",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4015,
      "latitude": 43.962115,
      "longitude": -70.524465,
      "city": "Casco",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4016,
      "latitude": 44.180965,
      "longitude": -70.891727,
      "city": "Center Lovell",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4017,
      "latitude": 43.727806,
      "longitude": -70.120437,
      "city": "Chebeague Island",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4019,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Cliff Island",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4020,
      "latitude": 43.77397,
      "longitude": -70.791727,
      "city": "Cornish",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4021,
      "latitude": 43.812676,
      "longitude": -70.450494,
      "city": "Cumberland Center",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4022,
      "latitude": 44.566156,
      "longitude": -70.661557,
      "city": "Denmark",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4024,
      "latitude": 43.844826,
      "longitude": -70.688656,
      "city": "East Baldwin",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4027,
      "latitude": 43.459708,
      "longitude": -70.91519,
      "city": "Lebanon",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4028,
      "latitude": 43.732391,
      "longitude": -70.845114,
      "city": "East Parsonfield",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4029,
      "latitude": 43.867223,
      "longitude": -70.547159,
      "city": "Sebago",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4030,
      "latitude": 43.596921,
      "longitude": -70.689681,
      "city": "East Waterboro",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4032,
      "latitude": 43.864477,
      "longitude": -70.097543,
      "city": "Freeport",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4033,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Freeport",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4034,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Freeport",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4037,
      "latitude": 44.044292,
      "longitude": -70.964418,
      "city": "Fryeburg",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4038,
      "latitude": 43.803689,
      "longitude": -70.355188,
      "city": "Gorham",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4039,
      "latitude": 43.878567,
      "longitude": -70.328889,
      "city": "Gray",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4040,
      "latitude": 43.992681,
      "longitude": -70.357119,
      "city": "Harrison",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4041,
      "latitude": 43.879628,
      "longitude": -70.826188,
      "city": "Hiram",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4042,
      "latitude": 43.584232,
      "longitude": -70.624391,
      "city": "Hollis Center",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4043,
      "latitude": 43.494679,
      "longitude": -70.54921,
      "city": "Kennebunk",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4046,
      "latitude": 43.534125,
      "longitude": -70.607475,
      "city": "Kennebunkport",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4047,
      "latitude": 43.744987,
      "longitude": -70.909196,
      "city": "Parsonsfield",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4048,
      "latitude": 43.661222,
      "longitude": -70.83448,
      "city": "Limerick",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4049,
      "latitude": 43.703854,
      "longitude": -70.748668,
      "city": "Limington",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4050,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Long Island",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4051,
      "latitude": 44.139839,
      "longitude": -70.886741,
      "city": "Lovell",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4053,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Merepoint",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4054,
      "latitude": 43.276341,
      "longitude": -70.597752,
      "city": "Moody",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4055,
      "latitude": 43.957208,
      "longitude": -70.639546,
      "city": "Naples",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4056,
      "latitude": 43.658378,
      "longitude": -70.868871,
      "city": "Newfield",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4057,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "North Bridgton",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4061,
      "latitude": 43.54209,
      "longitude": -70.710773,
      "city": "North Waterboro",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4062,
      "latitude": 43.911381,
      "longitude": -70.396652,
      "city": "Windham",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4063,
      "latitude": 43.504889,
      "longitude": -70.385703,
      "city": "Ocean Park",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4064,
      "latitude": 43.411837,
      "longitude": -70.469526,
      "city": "Old Orchard Beach",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4066,
      "latitude": 43.779982,
      "longitude": -69.975477,
      "city": "Orrs Island",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4068,
      "latitude": 43.848322,
      "longitude": -70.935379,
      "city": "Porter",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4069,
      "latitude": 43.894983,
      "longitude": -70.189159,
      "city": "Pownal",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4070,
      "latitude": 43.576983,
      "longitude": -70.273642,
      "city": "Scarborough",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4071,
      "latitude": 43.906227,
      "longitude": -70.465928,
      "city": "Raymond",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4072,
      "latitude": 43.541241,
      "longitude": -70.666387,
      "city": "Saco",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4073,
      "latitude": 43.576196,
      "longitude": -70.630569,
      "city": "Sanford",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4074,
      "latitude": 43.724562,
      "longitude": -70.375162,
      "city": "Scarborough",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4075,
      "latitude": 43.796053,
      "longitude": -70.552183,
      "city": "Sebago Lake",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4076,
      "latitude": 43.510275,
      "longitude": -70.77159,
      "city": "Shapleigh",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4077,
      "latitude": 43.910029,
      "longitude": -70.524524,
      "city": "South Casco",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4078,
      "latitude": 43.820774,
      "longitude": -70.120781,
      "city": "South Freeport",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4079,
      "latitude": 43.78105,
      "longitude": -69.995537,
      "city": "Harpswell",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4081,
      "latitude": 44.566156,
      "longitude": -70.661557,
      "city": "South Waterford",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4082,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "South Windham",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4083,
      "latitude": 43.465941,
      "longitude": -70.748295,
      "city": "Springvale",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4084,
      "latitude": 43.804454,
      "longitude": -70.554905,
      "city": "Standish",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4085,
      "latitude": 43.771934,
      "longitude": -70.639561,
      "city": "Steep Falls",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4086,
      "latitude": 43.981404,
      "longitude": -69.937793,
      "city": "Topsham",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4087,
      "latitude": 43.573684,
      "longitude": -70.749181,
      "city": "Waterboro",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4088,
      "latitude": 44.566156,
      "longitude": -70.661557,
      "city": "Waterford",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4090,
      "latitude": 43.429659,
      "longitude": -70.671274,
      "city": "Wells",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4091,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "West Baldwin",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4092,
      "latitude": 43.855116,
      "longitude": -70.568534,
      "city": "Westbrook",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4093,
      "latitude": 43.653805,
      "longitude": -70.622959,
      "city": "Buxton",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4094,
      "latitude": 43.406101,
      "longitude": -70.573255,
      "city": "West Kennebunk",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4095,
      "latitude": 43.601018,
      "longitude": -70.902676,
      "city": "West Newfield",
      "state": "ME",
      "county": "York"
  },
  {
      "zipCode": 4096,
      "latitude": 43.798227,
      "longitude": -70.171979,
      "city": "Yarmouth",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4097,
      "latitude": 43.837951,
      "longitude": -70.200114,
      "city": "North Yarmouth",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4098,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Westbrook",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4101,
      "latitude": 43.658784,
      "longitude": -70.262393,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4102,
      "latitude": 43.74275,
      "longitude": -70.44338,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4103,
      "latitude": 43.68826,
      "longitude": -70.289844,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4104,
      "latitude": 43.84649,
      "longitude": -70.464839,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4105,
      "latitude": 43.73928,
      "longitude": -70.270696,
      "city": "Falmouth",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4106,
      "latitude": 43.627364,
      "longitude": -70.289248,
      "city": "South Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4107,
      "latitude": 43.595512,
      "longitude": -70.239559,
      "city": "Cape Elizabeth",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4108,
      "latitude": 43.662809,
      "longitude": -70.188442,
      "city": "Peaks Island",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4109,
      "latitude": 43.678339,
      "longitude": -70.198742,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4110,
      "latitude": 43.759021,
      "longitude": -70.199307,
      "city": "Cumberland Foreside",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4112,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4116,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "South Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4122,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4123,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4124,
      "latitude": 44.408078,
      "longitude": -70.470703,
      "city": "Portland",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4210,
      "latitude": 44.087422,
      "longitude": -70.243848,
      "city": "Auburn",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4211,
      "latitude": 44.197009,
      "longitude": -70.239485,
      "city": "Auburn",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4212,
      "latitude": 44.197009,
      "longitude": -70.239485,
      "city": "Auburn",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4216,
      "latitude": 44.56816,
      "longitude": -70.704051,
      "city": "Andover",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4217,
      "latitude": 44.428414,
      "longitude": -70.770035,
      "city": "Bethel",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4219,
      "latitude": 44.265009,
      "longitude": -70.587095,
      "city": "Bryant Pond",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4220,
      "latitude": 44.326165,
      "longitude": -70.381112,
      "city": "Buckfield",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4221,
      "latitude": 44.38575,
      "longitude": -70.311314,
      "city": "Canton",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4222,
      "latitude": 43.968378,
      "longitude": -70.184618,
      "city": "Durham",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4223,
      "latitude": 44.023873,
      "longitude": -70.285748,
      "city": "Danville",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4224,
      "latitude": 44.553968,
      "longitude": -70.422541,
      "city": "Dixfield",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4225,
      "latitude": 44.602705,
      "longitude": -70.22651,
      "city": "Dryden",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4226,
      "latitude": 44.555618,
      "longitude": -70.691857,
      "city": "East Andover",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4227,
      "latitude": 44.579135,
      "longitude": -70.326337,
      "city": "East Dixfield",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4228,
      "latitude": 44.197009,
      "longitude": -70.239485,
      "city": "East Livermore",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4230,
      "latitude": 44.062672,
      "longitude": -70.327049,
      "city": "East Poland",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4231,
      "latitude": 44.313283,
      "longitude": -70.861797,
      "city": "Stoneham",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4234,
      "latitude": 44.617509,
      "longitude": -70.182012,
      "city": "East Wilton",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4236,
      "latitude": 44.189655,
      "longitude": -70.136683,
      "city": "Greene",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4237,
      "latitude": 44.494347,
      "longitude": -70.735691,
      "city": "Hanover",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4238,
      "latitude": 44.22564,
      "longitude": -70.372831,
      "city": "Hebron",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4239,
      "latitude": 44.541508,
      "longitude": -70.208556,
      "city": "Jay",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4240,
      "latitude": 44.086323,
      "longitude": -70.169297,
      "city": "Lewiston",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4241,
      "latitude": 44.197009,
      "longitude": -70.239485,
      "city": "Lewiston",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4243,
      "latitude": 44.197009,
      "longitude": -70.239485,
      "city": "Lewiston",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4250,
      "latitude": 44.033026,
      "longitude": -70.101247,
      "city": "Lisbon",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4252,
      "latitude": 44.032476,
      "longitude": -70.068046,
      "city": "Lisbon Falls",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4253,
      "latitude": 44.297416,
      "longitude": -70.189139,
      "city": "Livermore",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4254,
      "latitude": 44.242189,
      "longitude": -70.188508,
      "city": "Livermore Falls",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4255,
      "latitude": 44.401595,
      "longitude": -70.708774,
      "city": "Greenwood",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4256,
      "latitude": 44.079644,
      "longitude": -70.272624,
      "city": "Mechanic Falls",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4257,
      "latitude": 44.570265,
      "longitude": -70.516131,
      "city": "Mexico",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4258,
      "latitude": 44.146121,
      "longitude": -70.339859,
      "city": "Minot",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4259,
      "latitude": 44.349166,
      "longitude": -69.870181,
      "city": "Monmouth",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4260,
      "latitude": 43.967474,
      "longitude": -70.299687,
      "city": "New Gloucester",
      "state": "ME",
      "county": "Cumberland"
  },
  {
      "zipCode": 4261,
      "latitude": 44.689526,
      "longitude": -71.011246,
      "city": "Newry",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4262,
      "latitude": 45.063384,
      "longitude": -70.381639,
      "city": "North Jay",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4263,
      "latitude": 44.292089,
      "longitude": -70.135366,
      "city": "Leeds",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4265,
      "latitude": 44.303359,
      "longitude": -70.037848,
      "city": "North Monmouth",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4266,
      "latitude": 44.357987,
      "longitude": -70.255783,
      "city": "North Turner",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4267,
      "latitude": 44.566156,
      "longitude": -70.661557,
      "city": "North Waterford",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4268,
      "latitude": 44.280503,
      "longitude": -70.691576,
      "city": "Norway",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4270,
      "latitude": 44.284385,
      "longitude": -70.507791,
      "city": "Oxford",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4271,
      "latitude": 44.264111,
      "longitude": -70.498513,
      "city": "Paris",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4274,
      "latitude": 44.047435,
      "longitude": -70.389923,
      "city": "Poland",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4275,
      "latitude": 44.566156,
      "longitude": -70.661557,
      "city": "Roxbury",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4276,
      "latitude": 44.523556,
      "longitude": -70.638174,
      "city": "Rumford",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4278,
      "latitude": 44.377031,
      "longitude": -70.568041,
      "city": "Rumford Center",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4280,
      "latitude": 44.126723,
      "longitude": -70.126922,
      "city": "Sabattus",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4281,
      "latitude": 44.353052,
      "longitude": -70.607841,
      "city": "South Paris",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4282,
      "latitude": 44.265473,
      "longitude": -70.254155,
      "city": "Turner",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4283,
      "latitude": 44.197009,
      "longitude": -70.239485,
      "city": "Turner Center",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4284,
      "latitude": 44.359835,
      "longitude": -70.074555,
      "city": "Wayne",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4285,
      "latitude": 45.063384,
      "longitude": -70.381639,
      "city": "Weld",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4286,
      "latitude": 44.40205,
      "longitude": -70.860094,
      "city": "West Bethel",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4287,
      "latitude": 44.057514,
      "longitude": -69.965553,
      "city": "Bowdoin",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4288,
      "latitude": 44.197009,
      "longitude": -70.239485,
      "city": "West Minot",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4289,
      "latitude": 44.340438,
      "longitude": -70.543265,
      "city": "West Paris",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4290,
      "latitude": 44.477183,
      "longitude": -70.436722,
      "city": "Peru",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4291,
      "latitude": 44.040857,
      "longitude": -70.453006,
      "city": "West Poland",
      "state": "ME",
      "county": "Androscoggin"
  },
  {
      "zipCode": 4292,
      "latitude": 44.3189,
      "longitude": -70.477506,
      "city": "Sumner",
      "state": "ME",
      "county": "Oxford"
  },
  {
      "zipCode": 4294,
      "latitude": 44.618873,
      "longitude": -70.245935,
      "city": "Wilton",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4330,
      "latitude": 44.351642,
      "longitude": -69.803773,
      "city": "Augusta",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4332,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "Augusta",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4333,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "Augusta",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4336,
      "latitude": 44.315693,
      "longitude": -69.818009,
      "city": "Augusta",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4338,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "Augusta",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4341,
      "latitude": 44.258771,
      "longitude": -69.551024,
      "city": "Coopers Mills",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4342,
      "latitude": 44.077448,
      "longitude": -69.738418,
      "city": "Dresden",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4343,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "East Winthrop",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4344,
      "latitude": 44.256614,
      "longitude": -69.797105,
      "city": "Farmingdale",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4345,
      "latitude": 44.296652,
      "longitude": -69.796909,
      "city": "Gardiner",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4346,
      "latitude": 44.269959,
      "longitude": -69.724135,
      "city": "Randolph",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4347,
      "latitude": 44.24913,
      "longitude": -69.795037,
      "city": "Hallowell",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4348,
      "latitude": 44.220432,
      "longitude": -69.513293,
      "city": "Jefferson",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4349,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "Kents Hill",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4350,
      "latitude": 44.158104,
      "longitude": -69.95404,
      "city": "Litchfield",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4351,
      "latitude": 44.358014,
      "longitude": -69.867001,
      "city": "Manchester",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4352,
      "latitude": 44.461522,
      "longitude": -69.986762,
      "city": "Mount Vernon",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4353,
      "latitude": 44.188406,
      "longitude": -69.575119,
      "city": "Whitefield",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4354,
      "latitude": 44.39559,
      "longitude": -69.4168,
      "city": "Palermo",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4355,
      "latitude": 44.38484,
      "longitude": -69.953716,
      "city": "Readfield",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4357,
      "latitude": 44.108059,
      "longitude": -69.827489,
      "city": "Richmond",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4358,
      "latitude": 44.375578,
      "longitude": -69.586327,
      "city": "South China",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4359,
      "latitude": 44.183266,
      "longitude": -69.777196,
      "city": "South Gardiner",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4360,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "Vienna",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4363,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "Windsor",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4364,
      "latitude": 44.322865,
      "longitude": -69.957627,
      "city": "Winthrop",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4401,
      "latitude": 45.061744,
      "longitude": -68.878893,
      "city": "Bangor",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4402,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Bangor",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4406,
      "latitude": 45.184103,
      "longitude": -69.233382,
      "city": "Abbot",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4408,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Aurora",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4410,
      "latitude": 45.058864,
      "longitude": -68.95432,
      "city": "Bradford",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4411,
      "latitude": 44.914946,
      "longitude": -68.625688,
      "city": "Bradley",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4412,
      "latitude": 44.83561,
      "longitude": -68.783742,
      "city": "Brewer",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4413,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Brookton",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4414,
      "latitude": 45.310095,
      "longitude": -69.029462,
      "city": "Brownville",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4415,
      "latitude": 45.351184,
      "longitude": -69.058062,
      "city": "Brownville Junction",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4416,
      "latitude": 44.600448,
      "longitude": -68.773241,
      "city": "Bucksport",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4417,
      "latitude": 44.81782,
      "longitude": -68.817481,
      "city": "Burlington",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4418,
      "latitude": 45.049429,
      "longitude": -68.581212,
      "city": "Greenbush",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4419,
      "latitude": 44.808062,
      "longitude": -68.940624,
      "city": "Carmel",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4420,
      "latitude": 44.412968,
      "longitude": -68.798047,
      "city": "Castine",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4421,
      "latitude": 44.415639,
      "longitude": -68.792883,
      "city": "Castine",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4422,
      "latitude": 45.066999,
      "longitude": -69.040695,
      "city": "Charleston",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4423,
      "latitude": 45.039478,
      "longitude": -68.529182,
      "city": "Costigan",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4424,
      "latitude": 45.658831,
      "longitude": -67.866054,
      "city": "Danforth",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4426,
      "latitude": 45.171605,
      "longitude": -69.207725,
      "city": "Dover Foxcroft",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4427,
      "latitude": 44.989376,
      "longitude": -68.865187,
      "city": "Corinth",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4428,
      "latitude": 44.79169,
      "longitude": -68.577728,
      "city": "Eddington",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4429,
      "latitude": 44.894294,
      "longitude": -68.818345,
      "city": "Holden",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4430,
      "latitude": 45.233408,
      "longitude": -68.689174,
      "city": "East Millinocket",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4431,
      "latitude": 44.561174,
      "longitude": -68.664735,
      "city": "East Orland",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4434,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Etna",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4435,
      "latitude": 44.966032,
      "longitude": -69.13308,
      "city": "Exeter",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4438,
      "latitude": 44.632657,
      "longitude": -68.849975,
      "city": "Frankfort",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4441,
      "latitude": 45.792364,
      "longitude": -69.30463,
      "city": "Greenville",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4442,
      "latitude": 45.792364,
      "longitude": -69.30463,
      "city": "Greenville Junction",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4443,
      "latitude": 45.315976,
      "longitude": -69.457444,
      "city": "Guilford",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4444,
      "latitude": 44.835984,
      "longitude": -68.829046,
      "city": "Hampden",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4448,
      "latitude": 45.245635,
      "longitude": -68.666632,
      "city": "Howland",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4449,
      "latitude": 45.006054,
      "longitude": -68.884621,
      "city": "Hudson",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4450,
      "latitude": 44.918877,
      "longitude": -68.965803,
      "city": "Kenduskeag",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4451,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Kingman",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4453,
      "latitude": 44.787229,
      "longitude": -69.21821,
      "city": "Lagrange",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4454,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Lambert Lake",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4455,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Lee",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4456,
      "latitude": 44.871231,
      "longitude": -69.011636,
      "city": "Levant",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4457,
      "latitude": 45.366794,
      "longitude": -68.49299,
      "city": "Lincoln",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4459,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Mattawamkeag",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4460,
      "latitude": 45.618608,
      "longitude": -68.543602,
      "city": "Medway",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4461,
      "latitude": 45.0542,
      "longitude": -68.595946,
      "city": "Milford",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4462,
      "latitude": 45.226479,
      "longitude": -68.752091,
      "city": "Millinocket",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4463,
      "latitude": 45.244612,
      "longitude": -68.975988,
      "city": "Milo",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4464,
      "latitude": 45.792364,
      "longitude": -69.30463,
      "city": "Monson",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4467,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Olamon",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4468,
      "latitude": 45.040655,
      "longitude": -68.722962,
      "city": "Old Town",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4469,
      "latitude": 45.002798,
      "longitude": -68.63343,
      "city": "Orono",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4471,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Orient",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4472,
      "latitude": 44.409375,
      "longitude": -68.625903,
      "city": "Orland",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4473,
      "latitude": 45.068848,
      "longitude": -68.744361,
      "city": "Orono",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4474,
      "latitude": 44.842939,
      "longitude": -68.782487,
      "city": "Orrington",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4475,
      "latitude": 45.040668,
      "longitude": -68.618775,
      "city": "Passadumkeag",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4476,
      "latitude": 44.433911,
      "longitude": -68.721716,
      "city": "Penobscot",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4478,
      "latitude": 45.28657,
      "longitude": -70.054629,
      "city": "Rockwood",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4479,
      "latitude": 45.792364,
      "longitude": -69.30463,
      "city": "Sangerville",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4481,
      "latitude": 45.792364,
      "longitude": -69.30463,
      "city": "Sebec",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4485,
      "latitude": 45.792364,
      "longitude": -69.30463,
      "city": "Shirley Mills",
      "state": "ME",
      "county": "Piscataquis"
  },
  {
      "zipCode": 4487,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Springfield",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4488,
      "latitude": 44.894697,
      "longitude": -69.142341,
      "city": "Stetson",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4489,
      "latitude": 44.922368,
      "longitude": -68.686796,
      "city": "Stillwater",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4490,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Topsfield",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4491,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Vanceboro",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4492,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Waite",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4493,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "West Enfield",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4495,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Winn",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4496,
      "latitude": 44.65337,
      "longitude": -68.917487,
      "city": "Winterport",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4497,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Wytopitlock",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4530,
      "latitude": 43.867892,
      "longitude": -69.826495,
      "city": "Bath",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4535,
      "latitude": 44.008962,
      "longitude": -69.566684,
      "city": "Alna",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4536,
      "latitude": 44.021333,
      "longitude": -69.523263,
      "city": "Bayville",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4537,
      "latitude": 43.894903,
      "longitude": -69.627163,
      "city": "Boothbay",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4538,
      "latitude": 43.956167,
      "longitude": -69.517161,
      "city": "Boothbay Harbor",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4539,
      "latitude": 43.928967,
      "longitude": -69.598725,
      "city": "Bristol",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4541,
      "latitude": 43.884294,
      "longitude": -69.479184,
      "city": "Chamberlain",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4543,
      "latitude": 43.986485,
      "longitude": -69.424228,
      "city": "Damariscotta",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4544,
      "latitude": 43.82677,
      "longitude": -69.597446,
      "city": "East Boothbay",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4547,
      "latitude": 44.010424,
      "longitude": -69.231179,
      "city": "Friendship",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4548,
      "latitude": 43.805389,
      "longitude": -69.745295,
      "city": "Georgetown",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4549,
      "latitude": 44.021333,
      "longitude": -69.523263,
      "city": "Isle Of Springs",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4551,
      "latitude": 44.010411,
      "longitude": -69.440192,
      "city": "Bremen",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4552,
      "latitude": 44.021333,
      "longitude": -69.523263,
      "city": "Newagen",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4553,
      "latitude": 43.991336,
      "longitude": -69.503427,
      "city": "Newcastle",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4554,
      "latitude": 43.870936,
      "longitude": -69.566313,
      "city": "New Harbor",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4555,
      "latitude": 44.074877,
      "longitude": -69.536374,
      "city": "Nobleboro",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4556,
      "latitude": 43.931748,
      "longitude": -69.587334,
      "city": "Edgecomb",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4558,
      "latitude": 43.896727,
      "longitude": -69.509282,
      "city": "Pemaquid",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4562,
      "latitude": 43.789616,
      "longitude": -69.810821,
      "city": "Phippsburg",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4563,
      "latitude": 44.02301,
      "longitude": -69.255572,
      "city": "Cushing",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4564,
      "latitude": 43.932449,
      "longitude": -69.468052,
      "city": "Round Pond",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4565,
      "latitude": 43.773312,
      "longitude": -69.863461,
      "city": "Sebasco Estates",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4567,
      "latitude": 43.900856,
      "longitude": -69.859541,
      "city": "Small Point",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4568,
      "latitude": 43.877877,
      "longitude": -69.612725,
      "city": "South Bristol",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4570,
      "latitude": 44.021333,
      "longitude": -69.523263,
      "city": "Squirrel Island",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4571,
      "latitude": 43.88261,
      "longitude": -69.680144,
      "city": "Trevett",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4572,
      "latitude": 44.038581,
      "longitude": -69.415708,
      "city": "Waldoboro",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4573,
      "latitude": 43.929821,
      "longitude": -69.592127,
      "city": "Walpole",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4574,
      "latitude": 44.162384,
      "longitude": -69.387795,
      "city": "Washington",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4575,
      "latitude": 43.854534,
      "longitude": -69.660795,
      "city": "West Boothbay Harbor",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4576,
      "latitude": 43.935605,
      "longitude": -69.529648,
      "city": "Southport",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4578,
      "latitude": 43.964901,
      "longitude": -69.597134,
      "city": "Wiscasset",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4579,
      "latitude": 43.938696,
      "longitude": -69.764378,
      "city": "Woolwich",
      "state": "ME",
      "county": "Sagadahoc"
  },
  {
      "zipCode": 4605,
      "latitude": 44.477935,
      "longitude": -68.356311,
      "city": "Ellsworth",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4606,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Addison",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4607,
      "latitude": 44.483471,
      "longitude": -68.090339,
      "city": "Gouldsboro",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4609,
      "latitude": 44.362323,
      "longitude": -68.305513,
      "city": "Bar Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4611,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Beals",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4612,
      "latitude": 44.239876,
      "longitude": -68.354598,
      "city": "Bernard",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4613,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Birch Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4614,
      "latitude": 44.29691,
      "longitude": -68.623927,
      "city": "Blue Hill",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4615,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Blue Hill Falls",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4616,
      "latitude": 44.258121,
      "longitude": -68.567404,
      "city": "Brooklin",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4617,
      "latitude": 44.337581,
      "longitude": -68.765568,
      "city": "Brooksville",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4619,
      "latitude": 45.188254,
      "longitude": -67.389101,
      "city": "Calais",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4622,
      "latitude": 44.603258,
      "longitude": -67.923248,
      "city": "Cherryfield",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4623,
      "latitude": 44.698608,
      "longitude": -67.779881,
      "city": "Columbia Falls",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4624,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Corea",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4625,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Cranberry Isles",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4626,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Cutler",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4627,
      "latitude": 44.213503,
      "longitude": -68.711691,
      "city": "Deer Isle",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4628,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Dennysville",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4629,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "East Blue Hill",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4630,
      "latitude": 44.70746,
      "longitude": -67.46247,
      "city": "East Machias",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4631,
      "latitude": 45.036287,
      "longitude": -67.136853,
      "city": "Eastport",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4634,
      "latitude": 44.595131,
      "longitude": -68.233576,
      "city": "Franklin",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4635,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Frenchboro",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4637,
      "latitude": 45.185635,
      "longitude": -67.601026,
      "city": "Grand Lake Stream",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4640,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Hancock",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4642,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Harborside",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4643,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Harrington",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4644,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Hulls Cove",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4645,
      "latitude": 44.032196,
      "longitude": -69.148017,
      "city": "Isle Au Haut",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4646,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Islesford",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4648,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Jonesboro",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4649,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Jonesport",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4650,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Little Deer Isle",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4652,
      "latitude": 44.904288,
      "longitude": -67.040767,
      "city": "Lubec",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4653,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Bass Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4654,
      "latitude": 44.660627,
      "longitude": -67.469196,
      "city": "Machias",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4655,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Machiasport",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4656,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Manset",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4657,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Meddybemps",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4658,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Milbridge",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4660,
      "latitude": 44.334692,
      "longitude": -68.308737,
      "city": "Mount Desert",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4662,
      "latitude": 44.294073,
      "longitude": -68.284865,
      "city": "Northeast Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4664,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Sullivan",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4665,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Otter Creek",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4666,
      "latitude": 44.930092,
      "longitude": -67.156533,
      "city": "Pembroke",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4667,
      "latitude": 45.013348,
      "longitude": -67.097043,
      "city": "Perry",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4668,
      "latitude": 45.209229,
      "longitude": -67.575432,
      "city": "Princeton",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4669,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Prospect Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4671,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Robbinston",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4672,
      "latitude": 44.413523,
      "longitude": -68.251778,
      "city": "Salsbury Cove",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4673,
      "latitude": 44.313629,
      "longitude": -68.686305,
      "city": "Sargentville",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4674,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Seal Cove",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4675,
      "latitude": 44.299037,
      "longitude": -68.246314,
      "city": "Seal Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4676,
      "latitude": 44.399111,
      "longitude": -68.701228,
      "city": "Sedgwick",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4677,
      "latitude": 44.491175,
      "longitude": -68.19137,
      "city": "Sorrento",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4679,
      "latitude": 44.390897,
      "longitude": -68.261568,
      "city": "Southwest Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4680,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Steuben",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4681,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Stonington",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4683,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Sunset",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4684,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "Surry",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4685,
      "latitude": 44.213118,
      "longitude": -68.396479,
      "city": "Swans Island",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4686,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Wesley",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4690,
      "latitude": 44.641873,
      "longitude": -68.391481,
      "city": "West Tremont",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4691,
      "latitude": 45.002969,
      "longitude": -67.495548,
      "city": "Whiting",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4693,
      "latitude": 44.387188,
      "longitude": -68.074964,
      "city": "Winter Harbor",
      "state": "ME",
      "county": "Hancock"
  },
  {
      "zipCode": 4694,
      "latitude": 45.152637,
      "longitude": -67.37741,
      "city": "Baileyville",
      "state": "ME",
      "county": "Washington"
  },
  {
      "zipCode": 4730,
      "latitude": 46.121345,
      "longitude": -67.833009,
      "city": "Houlton",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4732,
      "latitude": 46.975725,
      "longitude": -68.39816,
      "city": "Ashland",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4733,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Benedicta",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4734,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Blaine",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4735,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Bridgewater",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4736,
      "latitude": 46.871355,
      "longitude": -68.025442,
      "city": "Caribou",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4737,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Clayton Lake",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4738,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Crouseville",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4739,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Eagle Lake",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4740,
      "latitude": 46.611101,
      "longitude": -67.85582,
      "city": "Easton",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4741,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Estcourt Station",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4742,
      "latitude": 46.746453,
      "longitude": -67.841507,
      "city": "Fort Fairfield",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4743,
      "latitude": 47.056484,
      "longitude": -68.255868,
      "city": "Fort Kent",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4744,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Fort Kent Mills",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4745,
      "latitude": 47.295289,
      "longitude": -68.39192,
      "city": "Frenchville",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4746,
      "latitude": 47.00434,
      "longitude": -67.998581,
      "city": "Grand Isle",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4747,
      "latitude": 46.011345,
      "longitude": -68.273154,
      "city": "Island Falls",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4750,
      "latitude": 46.906749,
      "longitude": -67.851979,
      "city": "Limestone",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4751,
      "latitude": 46.892918,
      "longitude": -67.9643,
      "city": "Limestone",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4756,
      "latitude": 46.934538,
      "longitude": -68.109616,
      "city": "Madawaska",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4757,
      "latitude": 47.007842,
      "longitude": -68.182061,
      "city": "Mapleton",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4758,
      "latitude": 46.551571,
      "longitude": -67.84817,
      "city": "Mars Hill",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4759,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Masardis",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4760,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Monticello",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4761,
      "latitude": 46.100442,
      "longitude": -68.003376,
      "city": "New Limerick",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4762,
      "latitude": 46.976619,
      "longitude": -68.205546,
      "city": "New Sweden",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4763,
      "latitude": 46.09998,
      "longitude": -68.14487,
      "city": "Oakfield",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4764,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Oxbow",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4765,
      "latitude": 45.999084,
      "longitude": -68.443112,
      "city": "Patten",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4766,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Perham",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4768,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Portage",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4769,
      "latitude": 46.715072,
      "longitude": -68.012557,
      "city": "Presque Isle",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4770,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Quimby",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4772,
      "latitude": 47.263956,
      "longitude": -68.335025,
      "city": "Saint Agatha",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4773,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Saint David",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4774,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Saint Francis",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4775,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Sheridan",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4776,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Sherman Mills",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4777,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Sherman Station",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4779,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Sinclair",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4780,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Smyrna Mills",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4781,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Wallagrass",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4782,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "Stacyville",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4783,
      "latitude": 46.787807,
      "longitude": -68.155212,
      "city": "Stockholm",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4785,
      "latitude": 46.986075,
      "longitude": -68.042433,
      "city": "Van Buren",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4786,
      "latitude": 46.793032,
      "longitude": -68.105233,
      "city": "Washburn",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4787,
      "latitude": 46.594523,
      "longitude": -67.930172,
      "city": "Westfield",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4788,
      "latitude": 46.516166,
      "longitude": -68.886826,
      "city": "Winterville",
      "state": "ME",
      "county": "Aroostook"
  },
  {
      "zipCode": 4841,
      "latitude": 44.128607,
      "longitude": -69.068648,
      "city": "Rockland",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4843,
      "latitude": 44.12613,
      "longitude": -69.029238,
      "city": "Camden",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4846,
      "latitude": 44.131005,
      "longitude": -69.091111,
      "city": "Glen Cove",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4847,
      "latitude": 44.241072,
      "longitude": -69.203015,
      "city": "Hope",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4848,
      "latitude": 44.309021,
      "longitude": -68.900772,
      "city": "Islesboro",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4849,
      "latitude": 44.324832,
      "longitude": -68.998255,
      "city": "Lincolnville",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4850,
      "latitude": 44.478694,
      "longitude": -69.149559,
      "city": "Lincolnville Center",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4851,
      "latitude": 44.032196,
      "longitude": -69.148017,
      "city": "Matinicus",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4852,
      "latitude": 44.021333,
      "longitude": -69.523263,
      "city": "Monhegan",
      "state": "ME",
      "county": "Lincoln"
  },
  {
      "zipCode": 4853,
      "latitude": 44.113472,
      "longitude": -68.855722,
      "city": "North Haven",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4854,
      "latitude": 44.07951,
      "longitude": -69.085574,
      "city": "Owls Head",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4855,
      "latitude": 44.032196,
      "longitude": -69.148017,
      "city": "Port Clyde",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4856,
      "latitude": 44.132144,
      "longitude": -69.03416,
      "city": "Rockport",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4857,
      "latitude": 43.995024,
      "longitude": -69.198524,
      "city": "Saint George",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4858,
      "latitude": 44.115186,
      "longitude": -69.132883,
      "city": "South Thomaston",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4859,
      "latitude": 44.002248,
      "longitude": -69.11955,
      "city": "Spruce Head",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4860,
      "latitude": 43.963129,
      "longitude": -69.213329,
      "city": "Tenants Harbor",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4861,
      "latitude": 44.108131,
      "longitude": -69.042704,
      "city": "Thomaston",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4862,
      "latitude": 44.231517,
      "longitude": -69.219722,
      "city": "Union",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4863,
      "latitude": 44.08511,
      "longitude": -68.841965,
      "city": "Vinalhaven",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4864,
      "latitude": 44.086333,
      "longitude": -69.188163,
      "city": "Warren",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4865,
      "latitude": 44.192369,
      "longitude": -69.121139,
      "city": "West Rockport",
      "state": "ME",
      "county": "Knox"
  },
  {
      "zipCode": 4901,
      "latitude": 44.554321,
      "longitude": -69.617809,
      "city": "Waterville",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4903,
      "latitude": 44.549225,
      "longitude": -69.713178,
      "city": "Waterville",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4910,
      "latitude": 44.530366,
      "longitude": -69.639976,
      "city": "Albion",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4911,
      "latitude": 44.81062,
      "longitude": -69.912885,
      "city": "Anson",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4912,
      "latitude": 44.9227,
      "longitude": -69.67668,
      "city": "Athens",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4915,
      "latitude": 44.404619,
      "longitude": -69.040297,
      "city": "Belfast",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4917,
      "latitude": 44.480594,
      "longitude": -69.83253,
      "city": "Belgrade",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4918,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "Belgrade Lakes",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4920,
      "latitude": 45.575731,
      "longitude": -69.909773,
      "city": "Bingham",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4921,
      "latitude": 44.579387,
      "longitude": -69.175448,
      "city": "Brooks",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4922,
      "latitude": 44.677388,
      "longitude": -69.378829,
      "city": "Burnham",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4923,
      "latitude": 45.036055,
      "longitude": -69.438697,
      "city": "Cambridge",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4924,
      "latitude": 44.743659,
      "longitude": -69.585151,
      "city": "Canaan",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4925,
      "latitude": 45.575731,
      "longitude": -69.909773,
      "city": "Caratunk",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4926,
      "latitude": 44.391167,
      "longitude": -69.538257,
      "city": "China Village",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4927,
      "latitude": 44.621084,
      "longitude": -69.512609,
      "city": "Clinton",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4928,
      "latitude": 44.944157,
      "longitude": -69.228736,
      "city": "Corinna",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4929,
      "latitude": 44.761527,
      "longitude": -69.322662,
      "city": "Detroit",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4930,
      "latitude": 44.964532,
      "longitude": -69.203773,
      "city": "Dexter",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4932,
      "latitude": 44.6819,
      "longitude": -69.121648,
      "city": "Dixmont",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4933,
      "latitude": 45.519867,
      "longitude": -68.647416,
      "city": "East Newport",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4935,
      "latitude": 44.414056,
      "longitude": -69.751913,
      "city": "East Vassalboro",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4936,
      "latitude": 45.063384,
      "longitude": -70.381639,
      "city": "Eustis",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4937,
      "latitude": 44.646261,
      "longitude": -69.680158,
      "city": "Fairfield",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4938,
      "latitude": 44.650311,
      "longitude": -70.129267,
      "city": "Farmington",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4939,
      "latitude": 45.014913,
      "longitude": -69.156965,
      "city": "Garland",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4940,
      "latitude": 44.622563,
      "longitude": -70.075178,
      "city": "Farmington Falls",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4941,
      "latitude": 44.488583,
      "longitude": -69.339221,
      "city": "Freedom",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4942,
      "latitude": 44.973047,
      "longitude": -69.548148,
      "city": "Harmony",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4943,
      "latitude": 44.896404,
      "longitude": -69.464092,
      "city": "Hartland",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4944,
      "latitude": 44.684737,
      "longitude": -69.6425,
      "city": "Hinckley",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4945,
      "latitude": 44.883675,
      "longitude": -69.452187,
      "city": "Jackman",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4947,
      "latitude": 45.063384,
      "longitude": -70.381639,
      "city": "Kingfield",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4949,
      "latitude": 44.371549,
      "longitude": -69.15555,
      "city": "Liberty",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4950,
      "latitude": 44.786404,
      "longitude": -69.805662,
      "city": "Madison",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4951,
      "latitude": 44.478694,
      "longitude": -69.149559,
      "city": "Monroe",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4952,
      "latitude": 44.39785,
      "longitude": -69.142182,
      "city": "Morrill",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4953,
      "latitude": 44.863406,
      "longitude": -69.266827,
      "city": "Newport",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4954,
      "latitude": 45.575731,
      "longitude": -69.909773,
      "city": "New Portland",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4955,
      "latitude": 44.60363,
      "longitude": -70.010292,
      "city": "New Sharon",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4956,
      "latitude": 44.952578,
      "longitude": -70.183554,
      "city": "New Vineyard",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4957,
      "latitude": 44.707468,
      "longitude": -69.818804,
      "city": "Norridgewock",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4958,
      "latitude": 44.8019,
      "longitude": -69.946593,
      "city": "North Anson",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4961,
      "latitude": 45.575731,
      "longitude": -69.909773,
      "city": "North New Portland",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4962,
      "latitude": 44.479288,
      "longitude": -69.622006,
      "city": "North Vassalboro",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4963,
      "latitude": 44.557111,
      "longitude": -69.826495,
      "city": "Oakland",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4964,
      "latitude": 45.063384,
      "longitude": -70.381639,
      "city": "Oquossoc",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4965,
      "latitude": 44.802655,
      "longitude": -69.537715,
      "city": "Palmyra",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4966,
      "latitude": 45.063384,
      "longitude": -70.381639,
      "city": "Phillips",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4967,
      "latitude": 44.760948,
      "longitude": -69.387705,
      "city": "Pittsfield",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4969,
      "latitude": 44.772503,
      "longitude": -69.247539,
      "city": "Plymouth",
      "state": "ME",
      "county": "Penobscot"
  },
  {
      "zipCode": 4970,
      "latitude": 44.985998,
      "longitude": -70.660181,
      "city": "Rangeley",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4971,
      "latitude": 44.93183,
      "longitude": -69.403011,
      "city": "Saint Albans",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4972,
      "latitude": 44.478694,
      "longitude": -69.149559,
      "city": "Sandy Point",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4973,
      "latitude": 44.357201,
      "longitude": -69.215906,
      "city": "Searsmont",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4974,
      "latitude": 44.489412,
      "longitude": -68.933051,
      "city": "Searsport",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4975,
      "latitude": 44.624515,
      "longitude": -69.586852,
      "city": "Shawmut",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4976,
      "latitude": 44.769303,
      "longitude": -69.593975,
      "city": "Skowhegan",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4978,
      "latitude": 44.62111,
      "longitude": -69.770923,
      "city": "Smithfield",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4979,
      "latitude": 44.931415,
      "longitude": -69.834764,
      "city": "Solon",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4981,
      "latitude": 44.501114,
      "longitude": -68.891743,
      "city": "Stockton Springs",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4982,
      "latitude": 45.063384,
      "longitude": -70.381639,
      "city": "Stratton",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4983,
      "latitude": 44.814175,
      "longitude": -70.187221,
      "city": "Strong",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4984,
      "latitude": 44.670079,
      "longitude": -70.196855,
      "city": "Temple",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 4985,
      "latitude": 45.575731,
      "longitude": -69.909773,
      "city": "West Forks",
      "state": "ME",
      "county": "Somerset"
  },
  {
      "zipCode": 4986,
      "latitude": 44.513048,
      "longitude": -69.183929,
      "city": "Thorndike",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4987,
      "latitude": 44.478694,
      "longitude": -69.149559,
      "city": "Troy",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4988,
      "latitude": 44.601175,
      "longitude": -69.357191,
      "city": "Unity",
      "state": "ME",
      "county": "Waldo"
  },
  {
      "zipCode": 4989,
      "latitude": 44.427757,
      "longitude": -69.635908,
      "city": "Vassalboro",
      "state": "ME",
      "county": "Kennebec"
  },
  {
      "zipCode": 4992,
      "latitude": 44.662822,
      "longitude": -70.153049,
      "city": "West Farmington",
      "state": "ME",
      "county": "Franklin"
  },
  {
      "zipCode": 5001,
      "latitude": 43.591821,
      "longitude": -72.463589,
      "city": "White River Junction",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5009,
      "latitude": 43.592039,
      "longitude": -72.588407,
      "city": "White River Junction",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5030,
      "latitude": 43.410672,
      "longitude": -72.429777,
      "city": "Ascutney",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5031,
      "latitude": 43.657625,
      "longitude": -72.54586,
      "city": "Barnard",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5032,
      "latitude": 43.758758,
      "longitude": -72.688169,
      "city": "Bethel",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5033,
      "latitude": 44.004691,
      "longitude": -72.15845,
      "city": "Bradford",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5034,
      "latitude": 43.592527,
      "longitude": -72.621578,
      "city": "Bridgewater",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5035,
      "latitude": 43.599795,
      "longitude": -72.675164,
      "city": "Bridgewater Corners",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5036,
      "latitude": 44.017782,
      "longitude": -72.577365,
      "city": "Brookfield",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5037,
      "latitude": 43.467179,
      "longitude": -72.488285,
      "city": "Brownsville",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5038,
      "latitude": 43.994212,
      "longitude": -72.457153,
      "city": "Chelsea",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5039,
      "latitude": 44.033236,
      "longitude": -72.293631,
      "city": "Corinth",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5040,
      "latitude": 44.077918,
      "longitude": -72.210962,
      "city": "East Corinth",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5041,
      "latitude": 43.995961,
      "longitude": -72.411393,
      "city": "East Randolph",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5042,
      "latitude": 44.359629,
      "longitude": -72.086586,
      "city": "East Ryegate",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5043,
      "latitude": 43.807282,
      "longitude": -72.214693,
      "city": "East Thetford",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5045,
      "latitude": 43.928681,
      "longitude": -72.196635,
      "city": "Fairlee",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5046,
      "latitude": 44.281506,
      "longitude": -72.207573,
      "city": "Groton",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5047,
      "latitude": 43.672103,
      "longitude": -72.355539,
      "city": "Hartford",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5048,
      "latitude": 43.573379,
      "longitude": -72.402698,
      "city": "Hartland",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5049,
      "latitude": 43.592039,
      "longitude": -72.588407,
      "city": "Hartland Four Corners",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5050,
      "latitude": 44.46239,
      "longitude": -72.135804,
      "city": "Mc Indoe Falls",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5051,
      "latitude": 44.068284,
      "longitude": -72.118739,
      "city": "Newbury",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5052,
      "latitude": 43.591324,
      "longitude": -72.34903,
      "city": "North Hartland",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5053,
      "latitude": 43.709919,
      "longitude": -72.511882,
      "city": "North Pomfret",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5054,
      "latitude": 43.995961,
      "longitude": -72.411393,
      "city": "North Thetford",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5055,
      "latitude": 43.717918,
      "longitude": -72.3612,
      "city": "Norwich",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5056,
      "latitude": 43.526289,
      "longitude": -72.724848,
      "city": "Plymouth",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5058,
      "latitude": 43.995961,
      "longitude": -72.411393,
      "city": "Post Mills",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5059,
      "latitude": 43.664059,
      "longitude": -72.433116,
      "city": "Quechee",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5060,
      "latitude": 43.985803,
      "longitude": -72.694098,
      "city": "Randolph",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5061,
      "latitude": 43.928552,
      "longitude": -72.569113,
      "city": "Randolph Center",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5062,
      "latitude": 43.47559,
      "longitude": -72.572818,
      "city": "Reading",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5065,
      "latitude": 43.777365,
      "longitude": -72.407132,
      "city": "Sharon",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5067,
      "latitude": 43.697055,
      "longitude": -72.508042,
      "city": "South Pomfret",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5068,
      "latitude": 43.777096,
      "longitude": -72.528249,
      "city": "South Royalton",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5069,
      "latitude": 44.19779,
      "longitude": -72.110362,
      "city": "South Ryegate",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5070,
      "latitude": 43.844018,
      "longitude": -72.378546,
      "city": "South Strafford",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5071,
      "latitude": 43.565888,
      "longitude": -72.534106,
      "city": "South Woodstock",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5072,
      "latitude": 43.892586,
      "longitude": -72.369563,
      "city": "Strafford",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5073,
      "latitude": 43.630897,
      "longitude": -72.48908,
      "city": "Taftsville",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5074,
      "latitude": 43.995961,
      "longitude": -72.411393,
      "city": "Thetford",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5075,
      "latitude": 43.854018,
      "longitude": -72.254614,
      "city": "Thetford Center",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5076,
      "latitude": 43.995961,
      "longitude": -72.411393,
      "city": "East Corinth",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5077,
      "latitude": 43.895465,
      "longitude": -72.473372,
      "city": "Tunbridge",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5079,
      "latitude": 43.952586,
      "longitude": -72.311252,
      "city": "Vershire",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5081,
      "latitude": 44.119395,
      "longitude": -72.088869,
      "city": "Wells River",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5083,
      "latitude": 43.995961,
      "longitude": -72.411393,
      "city": "West Fairlee",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5084,
      "latitude": 43.718535,
      "longitude": -72.443862,
      "city": "West Hartford",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5085,
      "latitude": 43.995961,
      "longitude": -72.411393,
      "city": "West Newbury",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5086,
      "latitude": 44.115326,
      "longitude": -72.317918,
      "city": "West Topsham",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5088,
      "latitude": 43.673484,
      "longitude": -72.31196,
      "city": "Wilder",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5089,
      "latitude": 43.5181,
      "longitude": -72.418059,
      "city": "Windsor",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5091,
      "latitude": 43.631348,
      "longitude": -72.512835,
      "city": "Woodstock",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5101,
      "latitude": 43.171185,
      "longitude": -72.623106,
      "city": "Bellows Falls",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5141,
      "latitude": 42.99467,
      "longitude": -72.720362,
      "city": "Cambridgeport",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5142,
      "latitude": 43.402135,
      "longitude": -72.583854,
      "city": "Cavendish",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5143,
      "latitude": 43.298315,
      "longitude": -72.667167,
      "city": "Chester",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5144,
      "latitude": 43.592039,
      "longitude": -72.588407,
      "city": "Chester Depot",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5146,
      "latitude": 43.179936,
      "longitude": -72.605636,
      "city": "Grafton",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5148,
      "latitude": 43.228101,
      "longitude": -72.787995,
      "city": "Londonderry",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5149,
      "latitude": 43.376744,
      "longitude": -72.706913,
      "city": "Ludlow",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5150,
      "latitude": 43.33803,
      "longitude": -72.527652,
      "city": "North Springfield",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5151,
      "latitude": 43.409654,
      "longitude": -72.495851,
      "city": "Perkinsville",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5152,
      "latitude": 43.09893,
      "longitude": -73.034944,
      "city": "Peru",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5153,
      "latitude": 43.439854,
      "longitude": -72.620659,
      "city": "Proctorsville",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5154,
      "latitude": 43.164738,
      "longitude": -72.53194,
      "city": "Saxtons River",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5155,
      "latitude": 43.169854,
      "longitude": -72.851525,
      "city": "South Londonderry",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5156,
      "latitude": 43.40971,
      "longitude": -72.586064,
      "city": "Springfield",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5158,
      "latitude": 43.082635,
      "longitude": -72.474882,
      "city": "Westminster",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5159,
      "latitude": 43.093972,
      "longitude": -72.456492,
      "city": "Westminster Station",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5161,
      "latitude": 43.316142,
      "longitude": -72.739715,
      "city": "Weston",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5201,
      "latitude": 42.934076,
      "longitude": -73.105382,
      "city": "Bennington",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5250,
      "latitude": 43.065719,
      "longitude": -73.134727,
      "city": "Arlington",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5251,
      "latitude": 43.261817,
      "longitude": -73.139173,
      "city": "Dorset",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5252,
      "latitude": 43.069409,
      "longitude": -73.165898,
      "city": "East Arlington",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5253,
      "latitude": 43.182502,
      "longitude": -73.065443,
      "city": "East Dorset",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5254,
      "latitude": 43.177204,
      "longitude": -73.045787,
      "city": "Manchester",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5255,
      "latitude": 43.169289,
      "longitude": -73.047358,
      "city": "Manchester Center",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5257,
      "latitude": 42.966471,
      "longitude": -73.250529,
      "city": "North Bennington",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5260,
      "latitude": 42.829469,
      "longitude": -73.262496,
      "city": "North Pownal",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5261,
      "latitude": 42.790682,
      "longitude": -73.201254,
      "city": "Pownal",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5262,
      "latitude": 42.979702,
      "longitude": -73.192929,
      "city": "Shaftsbury",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5301,
      "latitude": 42.884281,
      "longitude": -72.760753,
      "city": "Brattleboro",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5302,
      "latitude": 42.99467,
      "longitude": -72.720362,
      "city": "Brattleboro",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5303,
      "latitude": 42.99467,
      "longitude": -72.720362,
      "city": "Brattleboro",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5304,
      "latitude": 42.99467,
      "longitude": -72.720362,
      "city": "Brattleboro",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5340,
      "latitude": 43.16409,
      "longitude": -72.91514,
      "city": "Bondville",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5341,
      "latitude": 42.968046,
      "longitude": -72.812012,
      "city": "East Dover",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5342,
      "latitude": 42.795363,
      "longitude": -72.817838,
      "city": "Jacksonville",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5343,
      "latitude": 43.012776,
      "longitude": -72.767275,
      "city": "Jamaica",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5344,
      "latitude": 42.99467,
      "longitude": -72.720362,
      "city": "Marlboro",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5345,
      "latitude": 43.013138,
      "longitude": -72.678725,
      "city": "Newfane",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5346,
      "latitude": 43.016096,
      "longitude": -72.534025,
      "city": "Putney",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5350,
      "latitude": 42.785591,
      "longitude": -72.971932,
      "city": "Readsboro",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5351,
      "latitude": 42.939071,
      "longitude": -72.72923,
      "city": "South Newfane",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5352,
      "latitude": 42.782616,
      "longitude": -73.067501,
      "city": "Stamford",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5353,
      "latitude": 43.070341,
      "longitude": -72.672991,
      "city": "Townshend",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5354,
      "latitude": 42.773711,
      "longitude": -72.520166,
      "city": "Vernon",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5355,
      "latitude": 43.00476,
      "longitude": -72.792004,
      "city": "Wardsboro",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5356,
      "latitude": 43.013041,
      "longitude": -72.793787,
      "city": "West Dover",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5357,
      "latitude": 42.957233,
      "longitude": -72.62408,
      "city": "West Dummerston",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5358,
      "latitude": 42.766519,
      "longitude": -72.745723,
      "city": "West Halifax",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5359,
      "latitude": 43.133864,
      "longitude": -72.714573,
      "city": "West Townshend",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5360,
      "latitude": 43.050937,
      "longitude": -72.825636,
      "city": "West Wardsboro",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5361,
      "latitude": 42.78318,
      "longitude": -72.867891,
      "city": "Whitingham",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5362,
      "latitude": 42.975027,
      "longitude": -72.673566,
      "city": "Williamsville",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5363,
      "latitude": 42.944039,
      "longitude": -72.727518,
      "city": "Wilmington",
      "state": "VT",
      "county": "Windham"
  },
  {
      "zipCode": 5401,
      "latitude": 44.507404,
      "longitude": -73.151384,
      "city": "Burlington",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5402,
      "latitude": 44.442117,
      "longitude": -73.082525,
      "city": "Burlington",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5403,
      "latitude": 44.448119,
      "longitude": -73.098238,
      "city": "South Burlington",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5404,
      "latitude": 44.498032,
      "longitude": -73.182158,
      "city": "Winooski",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5405,
      "latitude": 44.442117,
      "longitude": -73.082525,
      "city": "Burlington",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5406,
      "latitude": 44.442117,
      "longitude": -73.082525,
      "city": "Burlington",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5407,
      "latitude": 44.442117,
      "longitude": -73.082525,
      "city": "South Burlington",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5439,
      "latitude": 44.49518,
      "longitude": -73.165092,
      "city": "Colchester",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5440,
      "latitude": 44.93606,
      "longitude": -73.289065,
      "city": "Alburg",
      "state": "VT",
      "county": "Grand Isle"
  },
  {
      "zipCode": 5441,
      "latitude": 44.836967,
      "longitude": -72.92169,
      "city": "Bakersfield",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5442,
      "latitude": 44.724842,
      "longitude": -72.701554,
      "city": "Belvidere Center",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5443,
      "latitude": 44.157814,
      "longitude": -73.071813,
      "city": "Bristol",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5444,
      "latitude": 44.62218,
      "longitude": -72.881162,
      "city": "Cambridge",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5445,
      "latitude": 44.31658,
      "longitude": -73.226034,
      "city": "Charlotte",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5446,
      "latitude": 44.535557,
      "longitude": -73.103897,
      "city": "Colchester",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5447,
      "latitude": 44.824855,
      "longitude": -72.895849,
      "city": "East Berkshire",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5448,
      "latitude": 44.741186,
      "longitude": -72.882683,
      "city": "East Fairfield",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5449,
      "latitude": 44.442117,
      "longitude": -73.082525,
      "city": "Colchester",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5450,
      "latitude": 44.902044,
      "longitude": -72.782425,
      "city": "Enosburg Falls",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5451,
      "latitude": 44.50835,
      "longitude": -73.050271,
      "city": "Essex",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5452,
      "latitude": 44.485558,
      "longitude": -73.025271,
      "city": "Essex Junction",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5453,
      "latitude": 44.442117,
      "longitude": -73.082525,
      "city": "Essex Junction",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5454,
      "latitude": 44.733486,
      "longitude": -72.953478,
      "city": "Fairfax",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5455,
      "latitude": 44.816159,
      "longitude": -72.969743,
      "city": "Fairfield",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5456,
      "latitude": 44.210886,
      "longitude": -73.256629,
      "city": "Ferrisburg",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5457,
      "latitude": 44.96038,
      "longitude": -72.913261,
      "city": "Franklin",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5458,
      "latitude": 44.723391,
      "longitude": -73.303193,
      "city": "Grand Isle",
      "state": "VT",
      "county": "Grand Isle"
  },
  {
      "zipCode": 5459,
      "latitude": 44.962395,
      "longitude": -73.033062,
      "city": "Highgate Center",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5460,
      "latitude": 44.974765,
      "longitude": -73.105205,
      "city": "Highgate Springs",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5461,
      "latitude": 44.4553,
      "longitude": -73.067858,
      "city": "Hinesburg",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5462,
      "latitude": 44.318091,
      "longitude": -73.006184,
      "city": "Huntington",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5463,
      "latitude": 44.878094,
      "longitude": -73.338597,
      "city": "Isle La Motte",
      "state": "VT",
      "county": "Grand Isle"
  },
  {
      "zipCode": 5464,
      "latitude": 44.638789,
      "longitude": -72.82199,
      "city": "Jeffersonville",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5465,
      "latitude": 44.458996,
      "longitude": -72.955175,
      "city": "Jericho",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5466,
      "latitude": 44.362504,
      "longitude": -73.019801,
      "city": "Jonesville",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5468,
      "latitude": 44.643418,
      "longitude": -73.121685,
      "city": "Milton",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5469,
      "latitude": 44.240375,
      "longitude": -73.134702,
      "city": "Monkton",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5470,
      "latitude": 44.824855,
      "longitude": -72.895849,
      "city": "Montgomery",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5471,
      "latitude": 44.848241,
      "longitude": -72.619812,
      "city": "Montgomery Center",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5472,
      "latitude": 44.149509,
      "longitude": -73.197156,
      "city": "New Haven",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5473,
      "latitude": 44.239233,
      "longitude": -73.205939,
      "city": "North Ferrisburg",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5474,
      "latitude": 44.835274,
      "longitude": -73.277846,
      "city": "North Hero",
      "state": "VT",
      "county": "Grand Isle"
  },
  {
      "zipCode": 5476,
      "latitude": 44.952669,
      "longitude": -72.65435,
      "city": "Richford",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5477,
      "latitude": 44.387289,
      "longitude": -72.953283,
      "city": "Richmond",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5478,
      "latitude": 44.779982,
      "longitude": -72.908985,
      "city": "Saint Albans",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5479,
      "latitude": 44.824855,
      "longitude": -72.895849,
      "city": "Saint Albans",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5481,
      "latitude": 44.824855,
      "longitude": -72.895849,
      "city": "Saint Albans Bay",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5482,
      "latitude": 44.42493,
      "longitude": -73.114047,
      "city": "Shelburne",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5483,
      "latitude": 44.897751,
      "longitude": -72.961075,
      "city": "Sheldon",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5485,
      "latitude": 44.905573,
      "longitude": -72.963154,
      "city": "Sheldon Springs",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5486,
      "latitude": 44.738156,
      "longitude": -73.306352,
      "city": "South Hero",
      "state": "VT",
      "county": "Grand Isle"
  },
  {
      "zipCode": 5487,
      "latitude": 44.235203,
      "longitude": -73.02086,
      "city": "Starksboro",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5488,
      "latitude": 44.902128,
      "longitude": -72.948391,
      "city": "Swanton",
      "state": "VT",
      "county": "Franklin"
  },
  {
      "zipCode": 5489,
      "latitude": 44.565148,
      "longitude": -72.907209,
      "city": "Underhill",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5490,
      "latitude": 44.504656,
      "longitude": -72.885253,
      "city": "Underhill Center",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5491,
      "latitude": 44.145691,
      "longitude": -73.260487,
      "city": "Vergennes",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5492,
      "latitude": 44.736083,
      "longitude": -72.703199,
      "city": "Waterville",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5494,
      "latitude": 44.56959,
      "longitude": -73.025541,
      "city": "Westford",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5495,
      "latitude": 44.430096,
      "longitude": -73.026735,
      "city": "Williston",
      "state": "VT",
      "county": "Chittenden"
  },
  {
      "zipCode": 5501,
      "latitude": 42.647191,
      "longitude": -71.184202,
      "city": "Andover",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 5544,
      "latitude": 42.647191,
      "longitude": -71.184202,
      "city": "Andover",
      "state": "MA",
      "county": "Essex"
  },
  {
      "zipCode": 5601,
      "latitude": 44.19906,
      "longitude": -72.559638,
      "city": "Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5602,
      "latitude": 44.26829,
      "longitude": -72.623619,
      "city": "Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5603,
      "latitude": 44.156554,
      "longitude": -72.655892,
      "city": "Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5604,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5609,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5620,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5633,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5640,
      "latitude": 44.174872,
      "longitude": -72.502235,
      "city": "Adamant",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5641,
      "latitude": 44.20896,
      "longitude": -72.607693,
      "city": "Barre",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5647,
      "latitude": 44.266228,
      "longitude": -72.530473,
      "city": "Cabot",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5648,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "Calais",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5649,
      "latitude": 44.157636,
      "longitude": -72.453261,
      "city": "East Barre",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5650,
      "latitude": 44.407044,
      "longitude": -72.498572,
      "city": "East Calais",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5651,
      "latitude": 44.279376,
      "longitude": -72.49657,
      "city": "East Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5652,
      "latitude": 44.718619,
      "longitude": -72.57821,
      "city": "Eden",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5653,
      "latitude": 44.701158,
      "longitude": -72.479642,
      "city": "Eden Mills",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5654,
      "latitude": 44.155438,
      "longitude": -72.484706,
      "city": "Graniteville",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5655,
      "latitude": 44.560808,
      "longitude": -72.590031,
      "city": "Hyde Park",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5656,
      "latitude": 44.642603,
      "longitude": -72.735911,
      "city": "Johnson",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5657,
      "latitude": 44.599905,
      "longitude": -72.647519,
      "city": "Lake Elmore",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5658,
      "latitude": 44.374874,
      "longitude": -72.329902,
      "city": "Marshfield",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5660,
      "latitude": 44.270817,
      "longitude": -72.786431,
      "city": "Moretown",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5661,
      "latitude": 44.560179,
      "longitude": -72.615346,
      "city": "Morrisville",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5662,
      "latitude": 44.442001,
      "longitude": -72.718759,
      "city": "Moscow",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5663,
      "latitude": 44.180906,
      "longitude": -72.678854,
      "city": "Northfield",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5664,
      "latitude": 44.183536,
      "longitude": -72.646591,
      "city": "Northfield Falls",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5665,
      "latitude": 44.67307,
      "longitude": -72.597057,
      "city": "North Hyde Park",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5666,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "North Montpelier",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5667,
      "latitude": 44.283708,
      "longitude": -72.552243,
      "city": "Plainfield",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5669,
      "latitude": 44.028005,
      "longitude": -73.089655,
      "city": "Roxbury",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5670,
      "latitude": 44.175744,
      "longitude": -72.50247,
      "city": "South Barre",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5671,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "Waterbury",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5672,
      "latitude": 44.535179,
      "longitude": -72.660043,
      "city": "Stowe",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5673,
      "latitude": 44.192186,
      "longitude": -72.843868,
      "city": "Waitsfield",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5674,
      "latitude": 44.217175,
      "longitude": -72.820401,
      "city": "Warren",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5675,
      "latitude": 44.078238,
      "longitude": -72.426322,
      "city": "Washington",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5676,
      "latitude": 44.259608,
      "longitude": -72.781143,
      "city": "Waterbury",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5677,
      "latitude": 44.389313,
      "longitude": -72.708616,
      "city": "Waterbury Center",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5678,
      "latitude": 44.155782,
      "longitude": -72.46581,
      "city": "Websterville",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5679,
      "latitude": 44.103841,
      "longitude": -72.526916,
      "city": "Williamstown",
      "state": "VT",
      "county": "Orange"
  },
  {
      "zipCode": 5680,
      "latitude": 44.539539,
      "longitude": -72.487589,
      "city": "Wolcott",
      "state": "VT",
      "county": "Lamoille"
  },
  {
      "zipCode": 5681,
      "latitude": 44.259518,
      "longitude": -72.585018,
      "city": "Woodbury",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5682,
      "latitude": 44.343536,
      "longitude": -72.575645,
      "city": "Worcester",
      "state": "VT",
      "county": "Washington"
  },
  {
      "zipCode": 5701,
      "latitude": 43.630346,
      "longitude": -72.93586,
      "city": "Rutland",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5702,
      "latitude": 43.412792,
      "longitude": -72.990632,
      "city": "Rutland",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5730,
      "latitude": 43.420978,
      "longitude": -72.828524,
      "city": "Belmont",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5731,
      "latitude": 43.687677,
      "longitude": -73.291868,
      "city": "Benson",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5732,
      "latitude": 43.643723,
      "longitude": -73.209356,
      "city": "Bomoseen",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5733,
      "latitude": 43.655139,
      "longitude": -73.121251,
      "city": "Brandon",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5734,
      "latitude": 43.982512,
      "longitude": -73.331483,
      "city": "Bridport",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5735,
      "latitude": 43.636856,
      "longitude": -73.126638,
      "city": "Castleton",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5736,
      "latitude": 43.594106,
      "longitude": -73.008253,
      "city": "Center Rutland",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5737,
      "latitude": 43.713218,
      "longitude": -72.925199,
      "city": "Chittenden",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5738,
      "latitude": 43.526706,
      "longitude": -72.869165,
      "city": "Cuttingsville",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5739,
      "latitude": 43.34792,
      "longitude": -73.027985,
      "city": "Danby",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5740,
      "latitude": 43.97153,
      "longitude": -73.091416,
      "city": "East Middlebury",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5741,
      "latitude": 43.592039,
      "longitude": -72.818121,
      "city": "East Poultney",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5742,
      "latitude": 43.446092,
      "longitude": -72.92087,
      "city": "East Wallingford",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5743,
      "latitude": 43.634258,
      "longitude": -73.182585,
      "city": "Fair Haven",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5744,
      "latitude": 43.576783,
      "longitude": -73.107352,
      "city": "Florence",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5745,
      "latitude": 43.592039,
      "longitude": -72.818121,
      "city": "Forest Dale",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5746,
      "latitude": 43.592039,
      "longitude": -72.588407,
      "city": "Gaysville",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5747,
      "latitude": 44.0032,
      "longitude": -72.817108,
      "city": "Granville",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5748,
      "latitude": 43.907858,
      "longitude": -72.890031,
      "city": "Hancock",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5750,
      "latitude": 43.599381,
      "longitude": -73.25104,
      "city": "Hydeville",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5751,
      "latitude": 43.656951,
      "longitude": -72.782713,
      "city": "Killington",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5753,
      "latitude": 43.991942,
      "longitude": -73.171578,
      "city": "Middlebury",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5757,
      "latitude": 43.478301,
      "longitude": -73.060391,
      "city": "Middletown Springs",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5758,
      "latitude": 43.444535,
      "longitude": -72.802413,
      "city": "Mount Holly",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5759,
      "latitude": 43.535739,
      "longitude": -72.970114,
      "city": "North Clarendon",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5760,
      "latitude": 43.863158,
      "longitude": -73.242322,
      "city": "Orwell",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5761,
      "latitude": 43.361439,
      "longitude": -73.150683,
      "city": "Pawlet",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5762,
      "latitude": 43.592039,
      "longitude": -72.818121,
      "city": "Pittsfield",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5763,
      "latitude": 43.72182,
      "longitude": -72.999461,
      "city": "Pittsford",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5764,
      "latitude": 43.525395,
      "longitude": -73.112878,
      "city": "Poultney",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5765,
      "latitude": 43.601975,
      "longitude": -73.09589,
      "city": "Proctor",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5766,
      "latitude": 43.979315,
      "longitude": -73.010663,
      "city": "Ripton",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5767,
      "latitude": 43.866832,
      "longitude": -72.851495,
      "city": "Rochester",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5768,
      "latitude": 43.026984,
      "longitude": -73.055156,
      "city": "Rupert",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5769,
      "latitude": 43.920297,
      "longitude": -73.122709,
      "city": "Salisbury",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5770,
      "latitude": 43.912406,
      "longitude": -73.279162,
      "city": "Shoreham",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5772,
      "latitude": 43.756978,
      "longitude": -72.730177,
      "city": "Stockbridge",
      "state": "VT",
      "county": "Windsor"
  },
  {
      "zipCode": 5773,
      "latitude": 43.477386,
      "longitude": -73.066066,
      "city": "Wallingford",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5774,
      "latitude": 43.429363,
      "longitude": -73.161826,
      "city": "Wells",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5775,
      "latitude": 43.360006,
      "longitude": -73.224211,
      "city": "West Pawlet",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5776,
      "latitude": 43.026984,
      "longitude": -73.055156,
      "city": "West Rupert",
      "state": "VT",
      "county": "Bennington"
  },
  {
      "zipCode": 5777,
      "latitude": 43.588163,
      "longitude": -73.065323,
      "city": "West Rutland",
      "state": "VT",
      "county": "Rutland"
  },
  {
      "zipCode": 5778,
      "latitude": 43.893982,
      "longitude": -73.203001,
      "city": "Whiting",
      "state": "VT",
      "county": "Addison"
  },
  {
      "zipCode": 5819,
      "latitude": 44.426455,
      "longitude": -72.114155,
      "city": "Saint Johnsbury",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5820,
      "latitude": 44.741679,
      "longitude": -72.364639,
      "city": "Albany",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5821,
      "latitude": 44.313675,
      "longitude": -72.11879,
      "city": "Barnet",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5822,
      "latitude": 44.73663,
      "longitude": -72.199084,
      "city": "Barton",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5823,
      "latitude": 45.005783,
      "longitude": -72.138347,
      "city": "Beebe Plain",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5824,
      "latitude": 44.581613,
      "longitude": -71.833956,
      "city": "Concord",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5825,
      "latitude": 44.775607,
      "longitude": -72.22641,
      "city": "Coventry",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5826,
      "latitude": 44.641663,
      "longitude": -72.388928,
      "city": "Craftsbury",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5827,
      "latitude": 44.678786,
      "longitude": -72.35944,
      "city": "Craftsbury Common",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5828,
      "latitude": 44.437306,
      "longitude": -72.123508,
      "city": "Danville",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5829,
      "latitude": 44.952347,
      "longitude": -72.218616,
      "city": "Derby",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5830,
      "latitude": 44.97035,
      "longitude": -72.036632,
      "city": "Derby Line",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5832,
      "latitude": 44.587962,
      "longitude": -71.910223,
      "city": "East Burke",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5833,
      "latitude": 44.775607,
      "longitude": -72.22641,
      "city": "East Charleston",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5836,
      "latitude": 44.496356,
      "longitude": -72.17371,
      "city": "East Hardwick",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5837,
      "latitude": 44.667396,
      "longitude": -71.813465,
      "city": "East Haven",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5838,
      "latitude": 44.468494,
      "longitude": -71.930077,
      "city": "East Saint Johnsbury",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5839,
      "latitude": 44.679528,
      "longitude": -72.221912,
      "city": "Glover",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5840,
      "latitude": 44.680771,
      "longitude": -71.719436,
      "city": "Granby",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5841,
      "latitude": 44.600325,
      "longitude": -72.286556,
      "city": "Greensboro",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5842,
      "latitude": 44.775607,
      "longitude": -72.22641,
      "city": "Greensboro Bend",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5843,
      "latitude": 44.51609,
      "longitude": -72.309822,
      "city": "Hardwick",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5845,
      "latitude": 44.783177,
      "longitude": -72.301139,
      "city": "Irasburg",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5846,
      "latitude": 44.832535,
      "longitude": -71.841125,
      "city": "Island Pond",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5847,
      "latitude": 44.788925,
      "longitude": -72.439455,
      "city": "Lowell",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5848,
      "latitude": 44.382103,
      "longitude": -71.919518,
      "city": "Lower Waterford",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5849,
      "latitude": 44.497557,
      "longitude": -71.950519,
      "city": "Lyndon",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5850,
      "latitude": 44.481915,
      "longitude": -71.988258,
      "city": "Lyndon Center",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5851,
      "latitude": 44.5351,
      "longitude": -72.056557,
      "city": "Lyndonville",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5853,
      "latitude": 44.878704,
      "longitude": -71.971424,
      "city": "Morgan",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5855,
      "latitude": 44.907921,
      "longitude": -72.203948,
      "city": "Newport",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5857,
      "latitude": 44.927018,
      "longitude": -72.299191,
      "city": "Newport Center",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5858,
      "latitude": 44.512087,
      "longitude": -71.795986,
      "city": "North Concord",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5859,
      "latitude": 44.937769,
      "longitude": -72.433354,
      "city": "North Troy",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5860,
      "latitude": 44.823782,
      "longitude": -72.102019,
      "city": "Orleans",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5861,
      "latitude": 44.46239,
      "longitude": -72.135804,
      "city": "Passumpsic",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5862,
      "latitude": 44.46239,
      "longitude": -72.135804,
      "city": "Peacham",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5863,
      "latitude": 44.503403,
      "longitude": -71.972088,
      "city": "Saint Johnsbury Center",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5866,
      "latitude": 44.639653,
      "longitude": -72.132255,
      "city": "Sheffield",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5867,
      "latitude": 44.664521,
      "longitude": -72.042415,
      "city": "Sutton",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5868,
      "latitude": 44.775607,
      "longitude": -72.22641,
      "city": "Troy",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5871,
      "latitude": 44.683041,
      "longitude": -71.936817,
      "city": "West Burke",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5872,
      "latitude": 44.849402,
      "longitude": -72.03121,
      "city": "West Charleston",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5873,
      "latitude": 44.393366,
      "longitude": -72.222424,
      "city": "West Danville",
      "state": "VT",
      "county": "Caledonia"
  },
  {
      "zipCode": 5874,
      "latitude": 44.869117,
      "longitude": -72.450485,
      "city": "Westfield",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5875,
      "latitude": 44.710028,
      "longitude": -72.258038,
      "city": "West Glover",
      "state": "VT",
      "county": "Orleans"
  },
  {
      "zipCode": 5901,
      "latitude": 44.680771,
      "longitude": -71.719436,
      "city": "Averill",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5902,
      "latitude": 44.680771,
      "longitude": -71.719436,
      "city": "Beecher Falls",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5903,
      "latitude": 44.930787,
      "longitude": -71.597809,
      "city": "Canaan",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5904,
      "latitude": 44.680771,
      "longitude": -71.719436,
      "city": "Gilman",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5905,
      "latitude": 44.671187,
      "longitude": -71.662213,
      "city": "Guildhall",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5906,
      "latitude": 44.680771,
      "longitude": -71.719436,
      "city": "Lunenburg",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 5907,
      "latitude": 44.932643,
      "longitude": -71.776359,
      "city": "Norton",
      "state": "VT",
      "county": "Essex"
  },
  {
      "zipCode": 6001,
      "latitude": 41.757944,
      "longitude": -72.728272,
      "city": "Avon",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6002,
      "latitude": 41.851961,
      "longitude": -72.738939,
      "city": "Bloomfield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6006,
      "latitude": 41.879637,
      "longitude": -72.734271,
      "city": "Windsor",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6010,
      "latitude": 41.681198,
      "longitude": -72.939577,
      "city": "Bristol",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6011,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Bristol",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6013,
      "latitude": 41.761773,
      "longitude": -72.95826,
      "city": "Burlington",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6016,
      "latitude": 41.842097,
      "longitude": -72.708388,
      "city": "Broad Brook",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6018,
      "latitude": 42.001585,
      "longitude": -73.295891,
      "city": "Canaan",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6019,
      "latitude": 41.842882,
      "longitude": -72.899645,
      "city": "Canton",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6020,
      "latitude": 41.853921,
      "longitude": -72.902811,
      "city": "Canton Center",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6021,
      "latitude": 42.005809,
      "longitude": -73.118329,
      "city": "Colebrook",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6022,
      "latitude": 41.851597,
      "longitude": -72.918127,
      "city": "Collinsville",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6023,
      "latitude": 41.613489,
      "longitude": -72.7207,
      "city": "East Berlin",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6024,
      "latitude": 42.015806,
      "longitude": -73.291299,
      "city": "East Canaan",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6025,
      "latitude": 41.688896,
      "longitude": -72.534482,
      "city": "East Glastonbury",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6026,
      "latitude": 41.939096,
      "longitude": -72.740538,
      "city": "East Granby",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6027,
      "latitude": 42.004978,
      "longitude": -72.896907,
      "city": "East Hartland",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6028,
      "latitude": 41.859248,
      "longitude": -72.603033,
      "city": "East Windsor Hill",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6029,
      "latitude": 41.849319,
      "longitude": -72.411777,
      "city": "Ellington",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6030,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Farmington",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6031,
      "latitude": 41.949717,
      "longitude": -73.304403,
      "city": "Falls Village",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6032,
      "latitude": 41.726769,
      "longitude": -72.829543,
      "city": "Farmington",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6033,
      "latitude": 41.703451,
      "longitude": -72.539332,
      "city": "Glastonbury",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6034,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Farmington",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6035,
      "latitude": 41.966062,
      "longitude": -72.796407,
      "city": "Granby",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6037,
      "latitude": 41.602948,
      "longitude": -72.769991,
      "city": "Berlin",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6039,
      "latitude": 41.951917,
      "longitude": -73.38138,
      "city": "Lakeville",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6040,
      "latitude": 41.776048,
      "longitude": -72.523748,
      "city": "Manchester",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6041,
      "latitude": 41.794681,
      "longitude": -72.564832,
      "city": "Manchester",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6043,
      "latitude": 41.768648,
      "longitude": -72.439278,
      "city": "Bolton",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6045,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Manchester",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6049,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Melrose",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6050,
      "latitude": 41.666049,
      "longitude": -72.778391,
      "city": "New Britain",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6051,
      "latitude": 41.668449,
      "longitude": -72.770441,
      "city": "New Britain",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6052,
      "latitude": 41.656748,
      "longitude": -72.801892,
      "city": "New Britain",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6053,
      "latitude": 41.690249,
      "longitude": -72.790991,
      "city": "New Britain",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6057,
      "latitude": 41.883102,
      "longitude": -73.045918,
      "city": "New Hartford",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6058,
      "latitude": 41.886811,
      "longitude": -73.184674,
      "city": "Norfolk",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6059,
      "latitude": 41.899683,
      "longitude": -72.890744,
      "city": "North Canton",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6060,
      "latitude": 42.004694,
      "longitude": -72.843205,
      "city": "North Granby",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6061,
      "latitude": 41.874382,
      "longitude": -72.967958,
      "city": "Pine Meadow",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6062,
      "latitude": 41.672983,
      "longitude": -72.859695,
      "city": "Plainville",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6063,
      "latitude": 41.93937,
      "longitude": -72.990647,
      "city": "Barkhamsted",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6064,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Poquonock",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6065,
      "latitude": 41.968597,
      "longitude": -73.014467,
      "city": "Riverton",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6066,
      "latitude": 41.8372,
      "longitude": -72.454917,
      "city": "Vernon Rockville",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6067,
      "latitude": 41.657249,
      "longitude": -72.670887,
      "city": "Rocky Hill",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6068,
      "latitude": 42.00579,
      "longitude": -73.399336,
      "city": "Salisbury",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6069,
      "latitude": 41.874681,
      "longitude": -73.433903,
      "city": "Sharon",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6070,
      "latitude": 41.868647,
      "longitude": -72.819742,
      "city": "Simsbury",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6071,
      "latitude": 41.919603,
      "longitude": -72.433046,
      "city": "Somers",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6072,
      "latitude": 41.976466,
      "longitude": -72.490593,
      "city": "Somersville",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6073,
      "latitude": 41.657099,
      "longitude": -72.572233,
      "city": "South Glastonbury",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6074,
      "latitude": 41.834198,
      "longitude": -72.564632,
      "city": "South Windsor",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6075,
      "latitude": 41.81152,
      "longitude": -72.308779,
      "city": "Stafford",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6076,
      "latitude": 41.900495,
      "longitude": -72.27641,
      "city": "Stafford Springs",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6077,
      "latitude": 41.991645,
      "longitude": -72.257735,
      "city": "Staffordville",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6078,
      "latitude": 41.989646,
      "longitude": -72.658135,
      "city": "Suffield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6079,
      "latitude": 42.032012,
      "longitude": -73.403763,
      "city": "Taconic",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6080,
      "latitude": 41.948597,
      "longitude": -72.628802,
      "city": "Suffield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6081,
      "latitude": 41.905269,
      "longitude": -72.767239,
      "city": "Tariffville",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6082,
      "latitude": 41.984646,
      "longitude": -72.557731,
      "city": "Enfield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6083,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Enfield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6084,
      "latitude": 41.882553,
      "longitude": -72.361039,
      "city": "Tolland",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6085,
      "latitude": 41.860009,
      "longitude": -72.933467,
      "city": "Unionville",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6087,
      "latitude": 41.757616,
      "longitude": -72.884953,
      "city": "Unionville",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6088,
      "latitude": 41.903347,
      "longitude": -72.592883,
      "city": "East Windsor",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6089,
      "latitude": 41.889965,
      "longitude": -72.704,
      "city": "Weatogue",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6090,
      "latitude": 41.955645,
      "longitude": -72.861993,
      "city": "West Granby",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6091,
      "latitude": 42.003447,
      "longitude": -72.992109,
      "city": "West Hartland",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6092,
      "latitude": 41.865597,
      "longitude": -72.849854,
      "city": "West Simsbury",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6093,
      "latitude": 42.005795,
      "longitude": -72.727337,
      "city": "West Suffield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6094,
      "latitude": 41.896321,
      "longitude": -73.146312,
      "city": "Winchester Center",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6095,
      "latitude": 41.909988,
      "longitude": -72.804935,
      "city": "Windsor",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6096,
      "latitude": 41.933846,
      "longitude": -72.660245,
      "city": "Windsor Locks",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6098,
      "latitude": 41.925671,
      "longitude": -73.058157,
      "city": "Winsted",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6101,
      "latitude": 41.78007,
      "longitude": -72.677099,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6102,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6103,
      "latitude": 41.766349,
      "longitude": -72.674636,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6104,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6105,
      "latitude": 41.774499,
      "longitude": -72.699537,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6106,
      "latitude": 41.745049,
      "longitude": -72.687787,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6107,
      "latitude": 41.75325,
      "longitude": -72.75804,
      "city": "W Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6108,
      "latitude": 41.779799,
      "longitude": -72.621365,
      "city": "East Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6109,
      "latitude": 41.698899,
      "longitude": -72.667887,
      "city": "Wethersfield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6110,
      "latitude": 41.73425,
      "longitude": -72.738139,
      "city": "W Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6111,
      "latitude": 41.685985,
      "longitude": -72.729646,
      "city": "Newington",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6112,
      "latitude": 41.791337,
      "longitude": -72.694337,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6114,
      "latitude": 41.747399,
      "longitude": -72.672237,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6115,
      "latitude": 41.758849,
      "longitude": -72.679387,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6117,
      "latitude": 41.788688,
      "longitude": -72.755739,
      "city": "W Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6118,
      "latitude": 41.748999,
      "longitude": -72.609884,
      "city": "East Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6119,
      "latitude": 41.764349,
      "longitude": -72.726388,
      "city": "W Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6120,
      "latitude": 41.785449,
      "longitude": -72.669936,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6123,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6126,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6127,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "W Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6128,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "East Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6129,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Wethersfield",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6131,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Newington",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6132,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6133,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "W Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6134,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6137,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "W Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6138,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "East Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6140,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6141,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6142,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6143,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6144,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6145,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6146,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6147,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6150,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6151,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6152,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6153,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6154,
      "latitude": 41.771499,
      "longitude": -72.686687,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6155,
      "latitude": 41.76928,
      "longitude": -72.686465,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6156,
      "latitude": 41.767949,
      "longitude": -72.690687,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6160,
      "latitude": 41.766499,
      "longitude": -72.693337,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6161,
      "latitude": 41.766049,
      "longitude": -72.671837,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6167,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6176,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6180,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6183,
      "latitude": 41.763849,
      "longitude": -72.673037,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6199,
      "latitude": 41.927447,
      "longitude": -72.680386,
      "city": "Hartford",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6226,
      "latitude": 41.845504,
      "longitude": -72.091889,
      "city": "Willimantic",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6230,
      "latitude": 41.847302,
      "longitude": -72.02528,
      "city": "Abington",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6231,
      "latitude": 41.626377,
      "longitude": -72.3728,
      "city": "Amston",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6232,
      "latitude": 41.731847,
      "longitude": -72.374634,
      "city": "Andover",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6233,
      "latitude": 41.831565,
      "longitude": -72.020137,
      "city": "Ballouville",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6234,
      "latitude": 41.847758,
      "longitude": -71.983412,
      "city": "Brooklyn",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6235,
      "latitude": 41.80294,
      "longitude": -72.126036,
      "city": "Chaplin",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6237,
      "latitude": 41.696124,
      "longitude": -72.300043,
      "city": "Columbia",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6238,
      "latitude": 41.778623,
      "longitude": -72.323642,
      "city": "Coventry",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6239,
      "latitude": 41.864901,
      "longitude": -71.874354,
      "city": "Danielson",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6241,
      "latitude": 41.854975,
      "longitude": -71.858826,
      "city": "Dayville",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6242,
      "latitude": 41.927524,
      "longitude": -72.041112,
      "city": "Eastford",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6243,
      "latitude": 41.844885,
      "longitude": -71.818941,
      "city": "East Killingly",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6244,
      "latitude": 41.984327,
      "longitude": -71.981045,
      "city": "East Woodstock",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6245,
      "latitude": 42.019776,
      "longitude": -71.941669,
      "city": "Fabyan",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6246,
      "latitude": 41.971116,
      "longitude": -71.891732,
      "city": "Grosvenor Dale",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6247,
      "latitude": 41.788756,
      "longitude": -71.975819,
      "city": "Hampton",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6248,
      "latitude": 41.689249,
      "longitude": -72.398627,
      "city": "Hebron",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6249,
      "latitude": 41.628008,
      "longitude": -72.245422,
      "city": "Lebanon",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6250,
      "latitude": 41.78155,
      "longitude": -72.22554,
      "city": "Mansfield Center",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6251,
      "latitude": 41.799326,
      "longitude": -72.306526,
      "city": "Mansfield Depot",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6254,
      "latitude": 41.609895,
      "longitude": -72.150305,
      "city": "North Franklin",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6255,
      "latitude": 41.978392,
      "longitude": -71.899716,
      "city": "North Grosvenordale",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6256,
      "latitude": 41.778469,
      "longitude": -72.040003,
      "city": "North Windham",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6258,
      "latitude": 41.889048,
      "longitude": -71.968232,
      "city": "Pomfret",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6259,
      "latitude": 41.863133,
      "longitude": -72.00259,
      "city": "Pomfret Center",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6260,
      "latitude": 41.862904,
      "longitude": -71.887712,
      "city": "Putnam",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6262,
      "latitude": 42.021536,
      "longitude": -71.947077,
      "city": "Quinebaug",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6263,
      "latitude": 41.839051,
      "longitude": -71.90634,
      "city": "Rogers",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6264,
      "latitude": 41.695803,
      "longitude": -72.087045,
      "city": "Scotland",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6265,
      "latitude": 41.81152,
      "longitude": -72.308779,
      "city": "South Willington",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6266,
      "latitude": 41.674079,
      "longitude": -72.172298,
      "city": "South Windham",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6267,
      "latitude": 41.943902,
      "longitude": -71.945253,
      "city": "South Woodstock",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6268,
      "latitude": 41.787937,
      "longitude": -72.25248,
      "city": "Storrs Mansfield",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6269,
      "latitude": 41.808007,
      "longitude": -72.250963,
      "city": "Storrs Mansfield",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6277,
      "latitude": 41.975796,
      "longitude": -71.862047,
      "city": "Thompson",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6278,
      "latitude": 41.889043,
      "longitude": -72.147561,
      "city": "Ashford",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6279,
      "latitude": 41.896623,
      "longitude": -72.262236,
      "city": "Willington",
      "state": "CT",
      "county": "Tolland"
  },
  {
      "zipCode": 6280,
      "latitude": 41.693914,
      "longitude": -72.137843,
      "city": "Windham",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6281,
      "latitude": 41.961991,
      "longitude": -72.014108,
      "city": "Woodstock",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6282,
      "latitude": 41.952846,
      "longitude": -72.057134,
      "city": "Woodstock Valley",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6320,
      "latitude": 41.413998,
      "longitude": -72.10907,
      "city": "New London",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6330,
      "latitude": 41.501708,
      "longitude": -72.044306,
      "city": "Baltic",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6331,
      "latitude": 41.696753,
      "longitude": -71.998277,
      "city": "Canterbury",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6332,
      "latitude": 41.725694,
      "longitude": -71.909019,
      "city": "Central Village",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6333,
      "latitude": 41.378842,
      "longitude": -72.236794,
      "city": "East Lyme",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6334,
      "latitude": 41.544598,
      "longitude": -72.177023,
      "city": "Bozrah",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6335,
      "latitude": 41.523377,
      "longitude": -72.021165,
      "city": "Gales Ferry",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6336,
      "latitude": 41.579548,
      "longitude": -72.196273,
      "city": "Gilman",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6337,
      "latitude": 41.559873,
      "longitude": -71.889199,
      "city": "Glasgo",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6339,
      "latitude": 41.492843,
      "longitude": -71.965554,
      "city": "Ledyard",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6340,
      "latitude": 41.355405,
      "longitude": -72.038268,
      "city": "Groton",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6349,
      "latitude": 41.39973,
      "longitude": -72.090357,
      "city": "Groton",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6350,
      "latitude": 41.644546,
      "longitude": -72.06769,
      "city": "Hanover",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6351,
      "latitude": 41.599562,
      "longitude": -71.982619,
      "city": "Jewett City",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6353,
      "latitude": 41.452548,
      "longitude": -72.137471,
      "city": "Montville",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6354,
      "latitude": 41.704931,
      "longitude": -71.849772,
      "city": "Moosup",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6355,
      "latitude": 41.485188,
      "longitude": -72.000748,
      "city": "Mystic",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6357,
      "latitude": 41.323447,
      "longitude": -72.215623,
      "city": "Niantic",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6359,
      "latitude": 41.466851,
      "longitude": -71.880694,
      "city": "North Stonington",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6360,
      "latitude": 41.499398,
      "longitude": -71.994757,
      "city": "Norwich",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6365,
      "latitude": 41.522369,
      "longitude": -71.993427,
      "city": "Preston",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6370,
      "latitude": 41.512172,
      "longitude": -72.102302,
      "city": "Oakdale",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6371,
      "latitude": 41.409397,
      "longitude": -72.147665,
      "city": "Old Lyme",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6372,
      "latitude": 41.388216,
      "longitude": -71.949548,
      "city": "Old Mystic",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6373,
      "latitude": 41.678546,
      "longitude": -71.817759,
      "city": "Oneco",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6374,
      "latitude": 41.688121,
      "longitude": -71.884113,
      "city": "Plainfield",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6375,
      "latitude": 41.401066,
      "longitude": -72.140022,
      "city": "Quaker Hill",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6376,
      "latitude": 41.296668,
      "longitude": -72.263296,
      "city": "South Lyme",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6377,
      "latitude": 41.719948,
      "longitude": -71.823184,
      "city": "Sterling",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6378,
      "latitude": 41.378598,
      "longitude": -71.917915,
      "city": "Stonington",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6379,
      "latitude": 41.455191,
      "longitude": -71.895547,
      "city": "Pawcatuck",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6380,
      "latitude": 41.563998,
      "longitude": -72.05522,
      "city": "Taftville",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6382,
      "latitude": 41.424507,
      "longitude": -72.069578,
      "city": "Uncasville",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6383,
      "latitude": 41.601648,
      "longitude": -72.04037,
      "city": "Versailles",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6384,
      "latitude": 41.576894,
      "longitude": -71.866587,
      "city": "Voluntown",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6385,
      "latitude": 41.456698,
      "longitude": -72.126498,
      "city": "Waterford",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6386,
      "latitude": 41.464758,
      "longitude": -72.127301,
      "city": "Waterford",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6387,
      "latitude": 41.744527,
      "longitude": -71.913339,
      "city": "Wauregan",
      "state": "CT",
      "county": "Windham"
  },
  {
      "zipCode": 6388,
      "latitude": 41.344098,
      "longitude": -71.976467,
      "city": "West Mystic",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6389,
      "latitude": 41.559648,
      "longitude": -72.122672,
      "city": "Yantic",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6390,
      "latitude": 40.992288,
      "longitude": -72.723496,
      "city": "Fishers Island",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 6401,
      "latitude": 41.342514,
      "longitude": -73.070301,
      "city": "Ansonia",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6403,
      "latitude": 41.435134,
      "longitude": -73.058835,
      "city": "Beacon Falls",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6404,
      "latitude": 41.165097,
      "longitude": -73.129186,
      "city": "Botsford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6405,
      "latitude": 41.284746,
      "longitude": -72.796292,
      "city": "Branford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6408,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Cheshire",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6409,
      "latitude": 41.349098,
      "longitude": -72.41338,
      "city": "Centerbrook",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6410,
      "latitude": 41.460167,
      "longitude": -72.97222,
      "city": "Cheshire",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6411,
      "latitude": 41.550097,
      "longitude": -72.922235,
      "city": "Cheshire",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6412,
      "latitude": 41.39027,
      "longitude": -72.461805,
      "city": "Chester",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6413,
      "latitude": 41.298201,
      "longitude": -72.532934,
      "city": "Clinton",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6414,
      "latitude": 41.566668,
      "longitude": -72.558055,
      "city": "Cobalt",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6415,
      "latitude": 41.546443,
      "longitude": -72.338832,
      "city": "Colchester",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6416,
      "latitude": 41.608529,
      "longitude": -72.667807,
      "city": "Cromwell",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6417,
      "latitude": 41.368124,
      "longitude": -72.452284,
      "city": "Deep River",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6418,
      "latitude": 41.327246,
      "longitude": -73.084252,
      "city": "Derby",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6419,
      "latitude": 41.417962,
      "longitude": -72.493446,
      "city": "Killingworth",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6420,
      "latitude": 41.527441,
      "longitude": -72.269141,
      "city": "Salem",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6422,
      "latitude": 41.462468,
      "longitude": -72.682297,
      "city": "Durham",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6423,
      "latitude": 41.475478,
      "longitude": -72.389729,
      "city": "East Haddam",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6424,
      "latitude": 41.559841,
      "longitude": -72.494927,
      "city": "East Hampton",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6426,
      "latitude": 41.448179,
      "longitude": -72.50426,
      "city": "Essex",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6430,
      "latitude": 41.175173,
      "longitude": -73.282408,
      "city": "Fairfield",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6431,
      "latitude": 41.219093,
      "longitude": -73.252696,
      "city": "Fairfield",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6432,
      "latitude": 41.196046,
      "longitude": -73.252802,
      "city": "Fairfield",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6436,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Greens Farms",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6437,
      "latitude": 41.338847,
      "longitude": -72.690089,
      "city": "Guilford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6438,
      "latitude": 41.471457,
      "longitude": -72.511376,
      "city": "Haddam",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6439,
      "latitude": 41.421198,
      "longitude": -72.414095,
      "city": "Hadlyme",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6440,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Hawleyville",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6441,
      "latitude": 41.462718,
      "longitude": -72.578971,
      "city": "Higganum",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6442,
      "latitude": 41.344076,
      "longitude": -72.444369,
      "city": "Ivoryton",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6443,
      "latitude": 41.350397,
      "longitude": -72.788392,
      "city": "Madison",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6444,
      "latitude": 41.791776,
      "longitude": -72.718832,
      "city": "Marion",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6447,
      "latitude": 41.636949,
      "longitude": -72.463179,
      "city": "Marlborough",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6450,
      "latitude": 41.536498,
      "longitude": -72.801901,
      "city": "Meriden",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6451,
      "latitude": 41.54011,
      "longitude": -72.81888,
      "city": "Meriden",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6454,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Meriden",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6455,
      "latitude": 41.515346,
      "longitude": -72.712988,
      "city": "Middlefield",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6456,
      "latitude": 41.538017,
      "longitude": -72.525208,
      "city": "Middle Haddam",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6457,
      "latitude": 41.550139,
      "longitude": -72.655357,
      "city": "Middletown",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6459,
      "latitude": 41.556463,
      "longitude": -72.658179,
      "city": "Middletown",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6460,
      "latitude": 41.343773,
      "longitude": -72.951273,
      "city": "Milford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6467,
      "latitude": 41.565697,
      "longitude": -72.903746,
      "city": "Milldale",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6468,
      "latitude": 41.341845,
      "longitude": -73.236918,
      "city": "Monroe",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6469,
      "latitude": 41.508904,
      "longitude": -72.440086,
      "city": "Moodus",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6470,
      "latitude": 41.396295,
      "longitude": -73.310561,
      "city": "Newtown",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6471,
      "latitude": 41.332347,
      "longitude": -72.780892,
      "city": "North Branford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6472,
      "latitude": 41.379797,
      "longitude": -72.772991,
      "city": "Northford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6473,
      "latitude": 41.384381,
      "longitude": -72.862644,
      "city": "North Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6474,
      "latitude": 41.464758,
      "longitude": -72.127301,
      "city": "North Westchester",
      "state": "CT",
      "county": "New London"
  },
  {
      "zipCode": 6475,
      "latitude": 41.300448,
      "longitude": -72.389129,
      "city": "Old Saybrook",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6477,
      "latitude": 41.279896,
      "longitude": -73.031018,
      "city": "Orange",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6478,
      "latitude": 41.432583,
      "longitude": -73.138379,
      "city": "Oxford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6479,
      "latitude": 41.577845,
      "longitude": -72.902496,
      "city": "Plantsville",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6480,
      "latitude": 41.598339,
      "longitude": -72.601307,
      "city": "Portland",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6481,
      "latitude": 41.542105,
      "longitude": -72.666583,
      "city": "Rockfall",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6482,
      "latitude": 41.408137,
      "longitude": -73.249671,
      "city": "Sandy Hook",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6483,
      "latitude": 41.417646,
      "longitude": -73.102078,
      "city": "Seymour",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6484,
      "latitude": 41.314347,
      "longitude": -73.137143,
      "city": "Shelton",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6487,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "South Britain",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6488,
      "latitude": 41.470921,
      "longitude": -73.240839,
      "city": "Southbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6489,
      "latitude": 41.604786,
      "longitude": -72.871945,
      "city": "Southington",
      "state": "CT",
      "county": "Hartford"
  },
  {
      "zipCode": 6490,
      "latitude": 41.143396,
      "longitude": -73.289859,
      "city": "Southport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6491,
      "latitude": 41.386646,
      "longitude": -73.187207,
      "city": "Stevenson",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6492,
      "latitude": 41.456205,
      "longitude": -72.811047,
      "city": "Wallingford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6493,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Wallingford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6494,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Wallingford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6495,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Wallingford",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6497,
      "latitude": 41.207146,
      "longitude": -73.130503,
      "city": "Stratford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6498,
      "latitude": 41.307899,
      "longitude": -72.463381,
      "city": "Westbrook",
      "state": "CT",
      "county": "Middlesex"
  },
  {
      "zipCode": 6501,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6502,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6503,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6504,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6505,
      "latitude": 41.305721,
      "longitude": -72.779901,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6506,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6507,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6508,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6509,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6510,
      "latitude": 41.309797,
      "longitude": -72.922296,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6511,
      "latitude": 41.310547,
      "longitude": -72.926046,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6512,
      "latitude": 41.290997,
      "longitude": -72.865894,
      "city": "East Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6513,
      "latitude": 41.307197,
      "longitude": -72.865365,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6514,
      "latitude": 41.372723,
      "longitude": -72.938747,
      "city": "Hamden",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6515,
      "latitude": 41.328697,
      "longitude": -72.964247,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6516,
      "latitude": 41.272452,
      "longitude": -72.940335,
      "city": "West Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6517,
      "latitude": 41.362197,
      "longitude": -72.911396,
      "city": "Hamden",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6518,
      "latitude": 41.416747,
      "longitude": -72.906096,
      "city": "Hamden",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6519,
      "latitude": 41.296897,
      "longitude": -72.938596,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6520,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6521,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6524,
      "latitude": 41.428088,
      "longitude": -72.996191,
      "city": "Bethany",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6525,
      "latitude": 41.353296,
      "longitude": -73.013981,
      "city": "Woodbridge",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6530,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6531,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6532,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6533,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6534,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6535,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6536,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6537,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6538,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6540,
      "latitude": 41.299647,
      "longitude": -72.918846,
      "city": "New Haven",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6601,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6602,
      "latitude": 41.179846,
      "longitude": -73.189006,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6604,
      "latitude": 41.192746,
      "longitude": -73.213907,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6605,
      "latitude": 41.162573,
      "longitude": -73.216557,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6606,
      "latitude": 41.208796,
      "longitude": -73.211807,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6607,
      "latitude": 41.181596,
      "longitude": -73.165604,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6608,
      "latitude": 41.188199,
      "longitude": -73.180005,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6610,
      "latitude": 41.219847,
      "longitude": -73.163955,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6611,
      "latitude": 41.259996,
      "longitude": -73.206957,
      "city": "Trumbull",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6612,
      "latitude": 41.273016,
      "longitude": -73.297721,
      "city": "Easton",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6614,
      "latitude": 41.216006,
      "longitude": -73.130353,
      "city": "Stratford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6615,
      "latitude": 41.177046,
      "longitude": -73.133574,
      "city": "Stratford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6650,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6673,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6699,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Bridgeport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6701,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6702,
      "latitude": 41.540696,
      "longitude": -73.071124,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6703,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6704,
      "latitude": 41.581797,
      "longitude": -73.034451,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6705,
      "latitude": 41.552893,
      "longitude": -72.992749,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6706,
      "latitude": 41.535346,
      "longitude": -73.026951,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6708,
      "latitude": 41.551496,
      "longitude": -73.064152,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6710,
      "latitude": 41.571496,
      "longitude": -73.044035,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6712,
      "latitude": 41.499097,
      "longitude": -72.975694,
      "city": "Prospect",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6716,
      "latitude": 41.598347,
      "longitude": -72.980578,
      "city": "Wolcott",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6720,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6721,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6722,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6723,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6724,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6725,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6726,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6749,
      "latitude": 41.365709,
      "longitude": -72.927507,
      "city": "Waterbury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6750,
      "latitude": 41.714352,
      "longitude": -73.251598,
      "city": "Bantam",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6751,
      "latitude": 41.6409,
      "longitude": -73.209685,
      "city": "Bethlehem",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6752,
      "latitude": 41.520312,
      "longitude": -73.363875,
      "city": "Bridgewater",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6753,
      "latitude": 41.828067,
      "longitude": -73.332345,
      "city": "Cornwall",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6754,
      "latitude": 41.744373,
      "longitude": -73.301922,
      "city": "Cornwall Bridge",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6755,
      "latitude": 41.634686,
      "longitude": -73.472048,
      "city": "Gaylordsville",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6756,
      "latitude": 41.794278,
      "longitude": -73.239023,
      "city": "Goshen",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6757,
      "latitude": 41.774725,
      "longitude": -73.401908,
      "city": "Kent",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6758,
      "latitude": 41.739889,
      "longitude": -73.222981,
      "city": "Lakeside",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6759,
      "latitude": 41.791598,
      "longitude": -73.229787,
      "city": "Litchfield",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6762,
      "latitude": 41.528274,
      "longitude": -73.116458,
      "city": "Middlebury",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6763,
      "latitude": 41.689616,
      "longitude": -73.212587,
      "city": "Morris",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6770,
      "latitude": 41.491016,
      "longitude": -73.052965,
      "city": "Naugatuck",
      "state": "CT",
      "county": "New Haven"
  },
  {
      "zipCode": 6776,
      "latitude": 41.658619,
      "longitude": -73.366017,
      "city": "New Milford",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6777,
      "latitude": 41.690297,
      "longitude": -73.310346,
      "city": "New Preston Marble Dale",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6778,
      "latitude": 41.707722,
      "longitude": -73.108997,
      "city": "Northfield",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6779,
      "latitude": 41.604052,
      "longitude": -73.216887,
      "city": "Oakville",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6781,
      "latitude": 41.669898,
      "longitude": -72.99145,
      "city": "Pequabuck",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6782,
      "latitude": 41.65873,
      "longitude": -73.044364,
      "city": "Plymouth",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6783,
      "latitude": 41.578971,
      "longitude": -73.298344,
      "city": "Roxbury",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6784,
      "latitude": 41.525631,
      "longitude": -73.490891,
      "city": "Sherman",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6785,
      "latitude": 41.682439,
      "longitude": -73.305256,
      "city": "South Kent",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6786,
      "latitude": 41.66353,
      "longitude": -73.022449,
      "city": "Terryville",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6787,
      "latitude": 41.658721,
      "longitude": -73.098657,
      "city": "Thomaston",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6790,
      "latitude": 41.87484,
      "longitude": -73.126763,
      "city": "Torrington",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6791,
      "latitude": 41.649639,
      "longitude": -73.176163,
      "city": "Harwinton",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6793,
      "latitude": 41.718159,
      "longitude": -73.294187,
      "city": "Washington",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6794,
      "latitude": 41.646347,
      "longitude": -73.316951,
      "city": "Washington Depot",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6795,
      "latitude": 41.759427,
      "longitude": -73.162003,
      "city": "Watertown",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6796,
      "latitude": 41.821529,
      "longitude": -73.350384,
      "city": "West Cornwall",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6798,
      "latitude": 41.733106,
      "longitude": -73.135499,
      "city": "Woodbury",
      "state": "CT",
      "county": "Litchfield"
  },
  {
      "zipCode": 6801,
      "latitude": 41.381244,
      "longitude": -73.39908,
      "city": "Bethel",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6804,
      "latitude": 41.404799,
      "longitude": -73.275562,
      "city": "Brookfield",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6807,
      "latitude": 41.058646,
      "longitude": -73.587618,
      "city": "Cos Cob",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6810,
      "latitude": 41.376242,
      "longitude": -73.471416,
      "city": "Danbury",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6811,
      "latitude": 41.42333,
      "longitude": -73.478717,
      "city": "Danbury",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6812,
      "latitude": 41.486689,
      "longitude": -73.491042,
      "city": "New Fairfield",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6813,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Danbury",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6814,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Danbury",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6816,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Danbury",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6817,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Danbury",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6820,
      "latitude": 41.075846,
      "longitude": -73.480765,
      "city": "Darien",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6829,
      "latitude": 41.25553,
      "longitude": -73.427915,
      "city": "Georgetown",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6830,
      "latitude": 41.042746,
      "longitude": -73.62617,
      "city": "Greenwich",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6831,
      "latitude": 41.079983,
      "longitude": -73.654472,
      "city": "Greenwich",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6832,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Greenwich",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6836,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Greenwich",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6840,
      "latitude": 41.162209,
      "longitude": -73.501267,
      "city": "New Canaan",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6842,
      "latitude": 41.147096,
      "longitude": -73.495366,
      "city": "New Canaan",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6850,
      "latitude": 41.126146,
      "longitude": -73.442423,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6851,
      "latitude": 41.139796,
      "longitude": -73.404963,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6852,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6853,
      "latitude": 41.070396,
      "longitude": -73.437563,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6854,
      "latitude": 41.090946,
      "longitude": -73.432313,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6855,
      "latitude": 41.099196,
      "longitude": -73.398362,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6856,
      "latitude": 41.111196,
      "longitude": -73.420363,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6857,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6858,
      "latitude": 41.110496,
      "longitude": -73.416213,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6859,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6860,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Norwalk",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6870,
      "latitude": 41.034946,
      "longitude": -73.566968,
      "city": "Old Greenwich",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6875,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Redding Center",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6876,
      "latitude": 41.032647,
      "longitude": -73.583569,
      "city": "Redding Ridge",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6877,
      "latitude": 41.308201,
      "longitude": -73.494637,
      "city": "Ridgefield",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6878,
      "latitude": 41.035596,
      "longitude": -73.580241,
      "city": "Riverside",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6879,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Ridgefield",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6880,
      "latitude": 41.144496,
      "longitude": -73.343261,
      "city": "Westport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6881,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Westport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6883,
      "latitude": 41.222945,
      "longitude": -73.376263,
      "city": "Weston",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6888,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Westport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6889,
      "latitude": 41.141005,
      "longitude": -73.34689,
      "city": "Westport",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6896,
      "latitude": 41.271095,
      "longitude": -73.38634,
      "city": "Redding",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6897,
      "latitude": 41.209695,
      "longitude": -73.439165,
      "city": "Wilton",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6901,
      "latitude": 41.054082,
      "longitude": -73.536216,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6902,
      "latitude": 41.060196,
      "longitude": -73.544477,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6903,
      "latitude": 41.136773,
      "longitude": -73.565919,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6904,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6905,
      "latitude": 41.116751,
      "longitude": -73.552752,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6906,
      "latitude": 41.071246,
      "longitude": -73.521416,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6907,
      "latitude": 41.088346,
      "longitude": -73.537217,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6910,
      "latitude": 41.039147,
      "longitude": -73.559118,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6911,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6912,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6913,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6914,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6920,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6921,
      "latitude": 41.049866,
      "longitude": -73.53796,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6922,
      "latitude": 41.051551,
      "longitude": -73.514344,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6925,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6926,
      "latitude": 41.041197,
      "longitude": -73.538567,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6927,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 6928,
      "latitude": 41.308873,
      "longitude": -73.363661,
      "city": "Stamford",
      "state": "CT",
      "county": "Fairfield"
  },
  {
      "zipCode": 7001,
      "latitude": 40.582845,
      "longitude": -74.27524,
      "city": "Avenel",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 7002,
      "latitude": 40.670858,
      "longitude": -74.109486,
      "city": "Bayonne",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7003,
      "latitude": 40.808549,
      "longitude": -74.187005,
      "city": "Bloomfield",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7004,
      "latitude": 40.875948,
      "longitude": -74.29681,
      "city": "Fairfield",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7005,
      "latitude": 40.918648,
      "longitude": -74.430696,
      "city": "Boonton",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7006,
      "latitude": 40.854548,
      "longitude": -74.278892,
      "city": "Caldwell",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7007,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Caldwell",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7008,
      "latitude": 40.583203,
      "longitude": -74.234839,
      "city": "Carteret",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 7009,
      "latitude": 40.857049,
      "longitude": -74.227109,
      "city": "Cedar Grove",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7010,
      "latitude": 40.820541,
      "longitude": -73.98782,
      "city": "Cliffside Park",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7011,
      "latitude": 40.877949,
      "longitude": -74.141237,
      "city": "Clifton",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7012,
      "latitude": 40.847699,
      "longitude": -74.160538,
      "city": "Clifton",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7013,
      "latitude": 40.872898,
      "longitude": -74.170138,
      "city": "Clifton",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7014,
      "latitude": 40.832449,
      "longitude": -74.136937,
      "city": "Clifton",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7015,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Clifton",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7016,
      "latitude": 40.654802,
      "longitude": -74.304792,
      "city": "Cranford",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7017,
      "latitude": 40.77185,
      "longitude": -74.207039,
      "city": "East Orange",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7018,
      "latitude": 40.7566,
      "longitude": -74.216839,
      "city": "East Orange",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7019,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "East Orange",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7020,
      "latitude": 40.82655,
      "longitude": -73.978082,
      "city": "Edgewater",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7021,
      "latitude": 40.826158,
      "longitude": -74.276591,
      "city": "Essex Fells",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7022,
      "latitude": 40.817537,
      "longitude": -74.000883,
      "city": "Fairview",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7023,
      "latitude": 40.641902,
      "longitude": -74.387044,
      "city": "Fanwood",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7024,
      "latitude": 40.848745,
      "longitude": -73.974032,
      "city": "Fort Lee",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7026,
      "latitude": 40.884648,
      "longitude": -74.110336,
      "city": "Garfield",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7027,
      "latitude": 40.651242,
      "longitude": -74.322892,
      "city": "Garwood",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7028,
      "latitude": 40.807499,
      "longitude": -74.205039,
      "city": "Glen Ridge",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7029,
      "latitude": 40.744501,
      "longitude": -74.150787,
      "city": "Harrison",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7030,
      "latitude": 40.746851,
      "longitude": -74.033934,
      "city": "Hoboken",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7031,
      "latitude": 40.787112,
      "longitude": -74.127316,
      "city": "North Arlington",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7032,
      "latitude": 40.75175,
      "longitude": -74.122937,
      "city": "Kearny",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7033,
      "latitude": 40.677601,
      "longitude": -74.291191,
      "city": "Kenilworth",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7034,
      "latitude": 40.880649,
      "longitude": -74.379745,
      "city": "Lake Hiawatha",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7035,
      "latitude": 40.926637,
      "longitude": -74.303093,
      "city": "Lincoln Park",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7036,
      "latitude": 40.626953,
      "longitude": -74.250939,
      "city": "Linden",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7039,
      "latitude": 40.787006,
      "longitude": -74.325993,
      "city": "Livingston",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7040,
      "latitude": 40.7291,
      "longitude": -74.266541,
      "city": "Maplewood",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7041,
      "latitude": 40.73365,
      "longitude": -74.301692,
      "city": "Millburn",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7042,
      "latitude": 40.811908,
      "longitude": -74.216389,
      "city": "Montclair",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7043,
      "latitude": 40.8034,
      "longitude": -74.19448,
      "city": "Montclair",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7044,
      "latitude": 40.832849,
      "longitude": -74.243895,
      "city": "Verona",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7045,
      "latitude": 40.906311,
      "longitude": -74.363231,
      "city": "Montville",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7046,
      "latitude": 40.890502,
      "longitude": -74.440898,
      "city": "Mountain Lakes",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7047,
      "latitude": 40.789724,
      "longitude": -74.023234,
      "city": "North Bergen",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7050,
      "latitude": 40.7704,
      "longitude": -74.23674,
      "city": "Orange",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7051,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Orange",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7052,
      "latitude": 40.789944,
      "longitude": -74.22719,
      "city": "West Orange",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7054,
      "latitude": 40.852118,
      "longitude": -74.407706,
      "city": "Parsippany",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7055,
      "latitude": 40.855103,
      "longitude": -74.126916,
      "city": "Passaic",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7057,
      "latitude": 40.852799,
      "longitude": -74.107325,
      "city": "Wallington",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7058,
      "latitude": 40.871014,
      "longitude": -74.340144,
      "city": "Pine Brook",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7059,
      "latitude": 40.629101,
      "longitude": -74.532449,
      "city": "Warren",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7060,
      "latitude": 40.615202,
      "longitude": -74.414995,
      "city": "Plainfield",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7061,
      "latitude": 40.665651,
      "longitude": -74.299692,
      "city": "Plainfield",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7062,
      "latitude": 40.632252,
      "longitude": -74.399736,
      "city": "Plainfield",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7063,
      "latitude": 40.604752,
      "longitude": -74.442746,
      "city": "Plainfield",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7064,
      "latitude": 40.570254,
      "longitude": -74.249739,
      "city": "Port Reading",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 7065,
      "latitude": 40.609553,
      "longitude": -74.280641,
      "city": "Rahway",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7066,
      "latitude": 40.622502,
      "longitude": -74.311092,
      "city": "Clark",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7067,
      "latitude": 40.590853,
      "longitude": -74.314688,
      "city": "Colonia",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 7068,
      "latitude": 40.820499,
      "longitude": -74.306093,
      "city": "Roseland",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7069,
      "latitude": 40.642845,
      "longitude": -74.436233,
      "city": "Watchung",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7070,
      "latitude": 40.827449,
      "longitude": -74.109786,
      "city": "Rutherford",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7071,
      "latitude": 40.799549,
      "longitude": -74.116517,
      "city": "Lyndhurst",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7072,
      "latitude": 40.828399,
      "longitude": -74.076055,
      "city": "Carlstadt",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7073,
      "latitude": 40.828399,
      "longitude": -74.093886,
      "city": "East Rutherford",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7074,
      "latitude": 40.839299,
      "longitude": -74.060184,
      "city": "Moonachie",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7075,
      "latitude": 40.848599,
      "longitude": -74.089585,
      "city": "Wood Ridge",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7076,
      "latitude": 40.637852,
      "longitude": -74.368244,
      "city": "Scotch Plains",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7077,
      "latitude": 40.551804,
      "longitude": -74.259139,
      "city": "Sewaren",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 7078,
      "latitude": 40.74055,
      "longitude": -74.334443,
      "city": "Short Hills",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7079,
      "latitude": 40.745772,
      "longitude": -74.267541,
      "city": "South Orange",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7080,
      "latitude": 40.572065,
      "longitude": -74.413545,
      "city": "South Plainfield",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 7081,
      "latitude": 40.701101,
      "longitude": -74.316301,
      "city": "Springfield",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7082,
      "latitude": 40.92769,
      "longitude": -74.347695,
      "city": "Towaco",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7083,
      "latitude": 40.692651,
      "longitude": -74.269841,
      "city": "Union",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7086,
      "latitude": 40.76801,
      "longitude": -74.017008,
      "city": "Weehawken",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7087,
      "latitude": 40.758951,
      "longitude": -74.056335,
      "city": "Union City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7088,
      "latitude": 40.71789,
      "longitude": -74.284341,
      "city": "Vauxhall",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7090,
      "latitude": 40.652851,
      "longitude": -74.346764,
      "city": "Westfield",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7091,
      "latitude": 40.665651,
      "longitude": -74.299692,
      "city": "Westfield",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7092,
      "latitude": 40.680701,
      "longitude": -74.358394,
      "city": "Mountainside",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7093,
      "latitude": 40.7888,
      "longitude": -74.011533,
      "city": "West New York",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7094,
      "latitude": 40.78885,
      "longitude": -74.056035,
      "city": "Secaucus",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7095,
      "latitude": 40.553254,
      "longitude": -74.288023,
      "city": "Woodbridge",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 7096,
      "latitude": 40.73276,
      "longitude": -74.075485,
      "city": "Secaucus",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7097,
      "latitude": 40.73276,
      "longitude": -74.075485,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7099,
      "latitude": 40.73276,
      "longitude": -74.075485,
      "city": "Kearny",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7101,
      "latitude": 40.736101,
      "longitude": -74.22509,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7102,
      "latitude": 40.735642,
      "longitude": -74.173988,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7103,
      "latitude": 40.738901,
      "longitude": -74.194839,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7104,
      "latitude": 40.76435,
      "longitude": -74.151788,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7105,
      "latitude": 40.723606,
      "longitude": -74.147337,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7106,
      "latitude": 40.74245,
      "longitude": -74.23114,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7107,
      "latitude": 40.7646,
      "longitude": -74.188029,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7108,
      "latitude": 40.722496,
      "longitude": -74.200649,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7109,
      "latitude": 40.7821,
      "longitude": -74.167488,
      "city": "Belleville",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7110,
      "latitude": 40.821299,
      "longitude": -74.159449,
      "city": "Nutley",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7111,
      "latitude": 40.726231,
      "longitude": -74.23294,
      "city": "Irvington",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7112,
      "latitude": 40.711151,
      "longitude": -74.211265,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7114,
      "latitude": 40.705401,
      "longitude": -74.169538,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7175,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7182,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7184,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7188,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7189,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7191,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7192,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7193,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7194,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7195,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7197,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7198,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7199,
      "latitude": 40.79185,
      "longitude": -74.245241,
      "city": "Newark",
      "state": "NJ",
      "county": "Essex"
  },
  {
      "zipCode": 7201,
      "latitude": 40.672052,
      "longitude": -74.183438,
      "city": "Elizabeth",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7202,
      "latitude": 40.650902,
      "longitude": -74.217189,
      "city": "Elizabeth",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7203,
      "latitude": 40.650152,
      "longitude": -74.25879,
      "city": "Roselle",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7204,
      "latitude": 40.665652,
      "longitude": -74.26674,
      "city": "Roselle Park",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7205,
      "latitude": 40.693823,
      "longitude": -74.229889,
      "city": "Hillside",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7206,
      "latitude": 40.652152,
      "longitude": -74.191814,
      "city": "Elizabeth",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7207,
      "latitude": 40.665651,
      "longitude": -74.299692,
      "city": "Elizabeth",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7208,
      "latitude": 40.677102,
      "longitude": -74.229289,
      "city": "Elizabeth",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7302,
      "latitude": 40.726001,
      "longitude": -74.047304,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7303,
      "latitude": 40.73276,
      "longitude": -74.075485,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7304,
      "latitude": 40.716101,
      "longitude": -74.065535,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7305,
      "latitude": 40.696661,
      "longitude": -74.081135,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7306,
      "latitude": 40.734951,
      "longitude": -74.068685,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7307,
      "latitude": 40.749801,
      "longitude": -74.054285,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7308,
      "latitude": 40.73276,
      "longitude": -74.075485,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7309,
      "latitude": 40.73276,
      "longitude": -74.075485,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7310,
      "latitude": 40.732728,
      "longitude": -74.037168,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7311,
      "latitude": 40.73235,
      "longitude": -74.075391,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7399,
      "latitude": 40.73235,
      "longitude": -74.075391,
      "city": "Jersey City",
      "state": "NJ",
      "county": "Hudson"
  },
  {
      "zipCode": 7401,
      "latitude": 41.017446,
      "longitude": -74.062774,
      "city": "Allendale",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7403,
      "latitude": 41.023414,
      "longitude": -74.334444,
      "city": "Bloomingdale",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7405,
      "latitude": 40.998797,
      "longitude": -74.426148,
      "city": "Butler",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7407,
      "latitude": 40.905848,
      "longitude": -74.117736,
      "city": "Elmwood Park",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7410,
      "latitude": 40.935348,
      "longitude": -74.119236,
      "city": "Fair Lawn",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7416,
      "latitude": 41.115723,
      "longitude": -74.593439,
      "city": "Franklin",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7417,
      "latitude": 41.010464,
      "longitude": -74.20831,
      "city": "Franklin Lakes",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7418,
      "latitude": 41.228102,
      "longitude": -74.477351,
      "city": "Glenwood",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7419,
      "latitude": 41.159187,
      "longitude": -74.57688,
      "city": "Hamburg",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7420,
      "latitude": 41.028396,
      "longitude": -74.304516,
      "city": "Haskell",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7421,
      "latitude": 41.151395,
      "longitude": -74.357893,
      "city": "Hewitt",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7422,
      "latitude": 41.187468,
      "longitude": -74.458935,
      "city": "Highland Lakes",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7423,
      "latitude": 40.998882,
      "longitude": -74.096848,
      "city": "Ho Ho Kus",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7424,
      "latitude": 40.883548,
      "longitude": -74.214388,
      "city": "Little Falls",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7428,
      "latitude": 41.206509,
      "longitude": -74.538144,
      "city": "Mc Afee",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7430,
      "latitude": 41.053446,
      "longitude": -74.172766,
      "city": "Mahwah",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7432,
      "latitude": 40.994697,
      "longitude": -74.142287,
      "city": "Midland Park",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7435,
      "latitude": 41.053096,
      "longitude": -74.439356,
      "city": "Newfoundland",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7436,
      "latitude": 41.023402,
      "longitude": -74.238623,
      "city": "Oakland",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7438,
      "latitude": 41.099796,
      "longitude": -74.443399,
      "city": "Oak Ridge",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7439,
      "latitude": 41.078346,
      "longitude": -74.594104,
      "city": "Ogdensburg",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7440,
      "latitude": 40.948208,
      "longitude": -74.295592,
      "city": "Pequannock",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7442,
      "latitude": 40.999597,
      "longitude": -74.297725,
      "city": "Pompton Lakes",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7444,
      "latitude": 41.008797,
      "longitude": -74.402663,
      "city": "Pompton Plains",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7446,
      "latitude": 41.059124,
      "longitude": -74.133441,
      "city": "Ramsey",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7450,
      "latitude": 40.982247,
      "longitude": -74.11207,
      "city": "Ridgewood",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7451,
      "latitude": 40.948054,
      "longitude": -74.083231,
      "city": "Ridgewood",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7452,
      "latitude": 40.960347,
      "longitude": -74.125036,
      "city": "Glen Rock",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7456,
      "latitude": 41.110445,
      "longitude": -74.293271,
      "city": "Ringwood",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7457,
      "latitude": 40.992647,
      "longitude": -74.312443,
      "city": "Riverdale",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7458,
      "latitude": 41.044324,
      "longitude": -74.098093,
      "city": "Saddle River",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7460,
      "latitude": 41.138101,
      "longitude": -74.561915,
      "city": "Stockholm",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7461,
      "latitude": 41.242702,
      "longitude": -74.596734,
      "city": "Sussex",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7462,
      "latitude": 41.189046,
      "longitude": -74.51434,
      "city": "Vernon",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7463,
      "latitude": 41.041225,
      "longitude": -74.128326,
      "city": "Waldwick",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7465,
      "latitude": 41.065596,
      "longitude": -74.297393,
      "city": "Wanaque",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7470,
      "latitude": 40.948689,
      "longitude": -74.241168,
      "city": "Wayne",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7474,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Wayne",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7477,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Wayne",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7480,
      "latitude": 41.047296,
      "longitude": -74.294243,
      "city": "West Milford",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7481,
      "latitude": 40.998387,
      "longitude": -74.165733,
      "city": "Wyckoff",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7495,
      "latitude": 40.948054,
      "longitude": -74.083231,
      "city": "Mahwah",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7498,
      "latitude": 40.948054,
      "longitude": -74.083231,
      "city": "Mahwah",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7501,
      "latitude": 40.915045,
      "longitude": -74.174488,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7502,
      "latitude": 40.918698,
      "longitude": -74.194989,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7503,
      "latitude": 40.898098,
      "longitude": -74.151837,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7504,
      "latitude": 40.911248,
      "longitude": -74.144087,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7505,
      "latitude": 40.916648,
      "longitude": -74.174038,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7506,
      "latitude": 40.954375,
      "longitude": -74.161788,
      "city": "Hawthorne",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7507,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Hawthorne",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7508,
      "latitude": 40.951587,
      "longitude": -74.186269,
      "city": "Haledon",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7509,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7510,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7511,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Totowa",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7512,
      "latitude": 40.901698,
      "longitude": -74.22199,
      "city": "Totowa",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7513,
      "latitude": 40.907698,
      "longitude": -74.146515,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7514,
      "latitude": 40.928748,
      "longitude": -74.143187,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7522,
      "latitude": 40.923798,
      "longitude": -74.179488,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7524,
      "latitude": 40.932498,
      "longitude": -74.156971,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7533,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7538,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Haledon",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7543,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7544,
      "latitude": 41.011428,
      "longitude": -74.304793,
      "city": "Paterson",
      "state": "NJ",
      "county": "Passaic"
  },
  {
      "zipCode": 7601,
      "latitude": 40.913482,
      "longitude": -74.001623,
      "city": "Hackensack",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7602,
      "latitude": 40.948054,
      "longitude": -74.083231,
      "city": "Hackensack",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7603,
      "latitude": 40.874773,
      "longitude": -74.030484,
      "city": "Bogota",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7604,
      "latitude": 40.862349,
      "longitude": -74.075585,
      "city": "Hasbrouck Heights",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7605,
      "latitude": 40.863499,
      "longitude": -73.987132,
      "city": "Leonia",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7606,
      "latitude": 40.857899,
      "longitude": -74.048884,
      "city": "South Hackensack",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7607,
      "latitude": 40.90181,
      "longitude": -74.061784,
      "city": "Maywood",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7608,
      "latitude": 40.863954,
      "longitude": -74.055584,
      "city": "Teterboro",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7620,
      "latitude": 40.958975,
      "longitude": -73.927906,
      "city": "Alpine",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7621,
      "latitude": 40.923498,
      "longitude": -73.998332,
      "city": "Bergenfield",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7624,
      "latitude": 40.970621,
      "longitude": -73.960164,
      "city": "Closter",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7626,
      "latitude": 40.972898,
      "longitude": -74.098841,
      "city": "Cresskill",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7627,
      "latitude": 40.954198,
      "longitude": -73.957623,
      "city": "Demarest",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7628,
      "latitude": 40.955018,
      "longitude": -73.989911,
      "city": "Dumont",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7630,
      "latitude": 40.973199,
      "longitude": -74.021118,
      "city": "Emerson",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7631,
      "latitude": 40.889498,
      "longitude": -73.971782,
      "city": "Englewood",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7632,
      "latitude": 40.883949,
      "longitude": -73.952423,
      "city": "Englewood Cliffs",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7640,
      "latitude": 40.991696,
      "longitude": -73.98486,
      "city": "Harrington Park",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7641,
      "latitude": 40.963867,
      "longitude": -73.99358,
      "city": "Haworth",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7642,
      "latitude": 41.008367,
      "longitude": -74.048576,
      "city": "Hillsdale",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7643,
      "latitude": 40.925361,
      "longitude": -74.076029,
      "city": "Little Ferry",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7644,
      "latitude": 40.878349,
      "longitude": -74.081335,
      "city": "Lodi",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7645,
      "latitude": 41.054651,
      "longitude": -74.045425,
      "city": "Montvale",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7646,
      "latitude": 40.932998,
      "longitude": -74.017633,
      "city": "New Milford",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7647,
      "latitude": 41.008636,
      "longitude": -73.93893,
      "city": "Northvale",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7648,
      "latitude": 40.992921,
      "longitude": -73.949747,
      "city": "Norwood",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7649,
      "latitude": 40.955347,
      "longitude": -74.026983,
      "city": "Oradell",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7650,
      "latitude": 40.939673,
      "longitude": -74.010792,
      "city": "Palisades Park",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7652,
      "latitude": 40.944997,
      "longitude": -74.068964,
      "city": "Paramus",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7653,
      "latitude": 40.948054,
      "longitude": -74.083231,
      "city": "Paramus",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7656,
      "latitude": 41.032868,
      "longitude": -74.042948,
      "city": "Park Ridge",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7657,
      "latitude": 40.83315,
      "longitude": -74.004233,
      "city": "Ridgefield",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7660,
      "latitude": 40.853449,
      "longitude": -74.021233,
      "city": "Ridgefield Park",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7661,
      "latitude": 40.926248,
      "longitude": -74.038533,
      "city": "River Edge",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7662,
      "latitude": 40.905698,
      "longitude": -74.079035,
      "city": "Rochelle Park",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7663,
      "latitude": 40.942101,
      "longitude": -74.094685,
      "city": "Saddle Brook",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7666,
      "latitude": 40.914616,
      "longitude": -73.986334,
      "city": "Teaneck",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7670,
      "latitude": 40.919298,
      "longitude": -73.960481,
      "city": "Tenafly",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7675,
      "latitude": 41.011453,
      "longitude": -74.021278,
      "city": "Westwood",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7676,
      "latitude": 40.988306,
      "longitude": -74.064693,
      "city": "Township Of Washington",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7677,
      "latitude": 41.025977,
      "longitude": -74.061061,
      "city": "Woodcliff Lake",
      "state": "NJ",
      "county": "Bergen"
  },
  {
      "zipCode": 7701,
      "latitude": 40.358409,
      "longitude": -74.068143,
      "city": "Red Bank",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7702,
      "latitude": 40.325361,
      "longitude": -74.107943,
      "city": "Shrewsbury",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7703,
      "latitude": 40.314531,
      "longitude": -74.041654,
      "city": "Fort Monmouth",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7704,
      "latitude": 40.358459,
      "longitude": -74.036433,
      "city": "Fair Haven",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7709,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Allenhurst",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7710,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Adelphia",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7711,
      "latitude": 40.237111,
      "longitude": -74.008581,
      "city": "Allenhurst",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7712,
      "latitude": 40.250725,
      "longitude": -74.048582,
      "city": "Asbury Park",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7715,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Belmar",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7716,
      "latitude": 40.404762,
      "longitude": -74.05617,
      "city": "Atlantic Highlands",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7717,
      "latitude": 40.191913,
      "longitude": -74.015931,
      "city": "Avon By The Sea",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7718,
      "latitude": 40.417707,
      "longitude": -74.088534,
      "city": "Belford",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7719,
      "latitude": 40.257461,
      "longitude": -74.112134,
      "city": "Belmar",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7720,
      "latitude": 40.202113,
      "longitude": -74.012581,
      "city": "Bradley Beach",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7721,
      "latitude": 40.38633,
      "longitude": -74.115229,
      "city": "Cliffwood",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7722,
      "latitude": 40.302848,
      "longitude": -74.160911,
      "city": "Colts Neck",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7723,
      "latitude": 40.250861,
      "longitude": -74.004581,
      "city": "Deal",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7724,
      "latitude": 40.30466,
      "longitude": -74.073433,
      "city": "Eatontown",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7726,
      "latitude": 40.2697,
      "longitude": -74.265539,
      "city": "Englishtown",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7727,
      "latitude": 40.285211,
      "longitude": -74.161571,
      "city": "Farmingdale",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7728,
      "latitude": 40.236423,
      "longitude": -74.306284,
      "city": "Freehold",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7730,
      "latitude": 40.423057,
      "longitude": -74.176036,
      "city": "Hazlet",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7731,
      "latitude": 40.258361,
      "longitude": -74.19678,
      "city": "Howell",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7732,
      "latitude": 40.401078,
      "longitude": -74.000582,
      "city": "Highlands",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7733,
      "latitude": 40.376408,
      "longitude": -74.170037,
      "city": "Holmdel",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7734,
      "latitude": 40.438145,
      "longitude": -74.127883,
      "city": "Keansburg",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7735,
      "latitude": 40.438398,
      "longitude": -74.188087,
      "city": "Keyport",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7737,
      "latitude": 40.409391,
      "longitude": -74.059884,
      "city": "Leonardo",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7738,
      "latitude": 40.346629,
      "longitude": -74.125401,
      "city": "Lincroft",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7739,
      "latitude": 40.382028,
      "longitude": -74.168941,
      "city": "Little Silver",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7740,
      "latitude": 40.345361,
      "longitude": -74.1109,
      "city": "Long Branch",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7746,
      "latitude": 40.351277,
      "longitude": -74.141236,
      "city": "Marlboro",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7747,
      "latitude": 40.407507,
      "longitude": -74.233788,
      "city": "Matawan",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7748,
      "latitude": 40.400997,
      "longitude": -74.144636,
      "city": "Middletown",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7750,
      "latitude": 40.277757,
      "longitude": -74.194702,
      "city": "Monmouth Beach",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7751,
      "latitude": 40.319979,
      "longitude": -74.214488,
      "city": "Morganville",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7752,
      "latitude": 40.402277,
      "longitude": -74.027285,
      "city": "Navesink",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7753,
      "latitude": 40.209551,
      "longitude": -74.071351,
      "city": "Neptune",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7754,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Neptune",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7755,
      "latitude": 40.261158,
      "longitude": -74.021382,
      "city": "Oakhurst",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7756,
      "latitude": 40.278922,
      "longitude": -74.123285,
      "city": "Ocean Grove",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7757,
      "latitude": 40.31496,
      "longitude": -74.019032,
      "city": "Oceanport",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7758,
      "latitude": 40.429731,
      "longitude": -74.106535,
      "city": "Port Monmouth",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7760,
      "latitude": 40.370684,
      "longitude": -74.008432,
      "city": "Rumson",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7762,
      "latitude": 40.176013,
      "longitude": -74.031331,
      "city": "Spring Lake",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7763,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Tennent",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7764,
      "latitude": 40.283511,
      "longitude": -74.018282,
      "city": "West Long Branch",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7765,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Wickatunk",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7777,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Holmdel",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7799,
      "latitude": 40.302718,
      "longitude": -74.24928,
      "city": "Eatontown",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 7801,
      "latitude": 40.917598,
      "longitude": -74.546651,
      "city": "Dover",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7802,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Dover",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7803,
      "latitude": 40.877099,
      "longitude": -74.584453,
      "city": "Mine Hill",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7806,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Picatinny Arsenal",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7820,
      "latitude": 40.869648,
      "longitude": -74.849661,
      "city": "Allamuchy",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7821,
      "latitude": 41.092778,
      "longitude": -74.718969,
      "city": "Andover",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7822,
      "latitude": 41.161241,
      "longitude": -74.6174,
      "city": "Augusta",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7823,
      "latitude": 40.819772,
      "longitude": -75.030961,
      "city": "Belvidere",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7825,
      "latitude": 40.942238,
      "longitude": -74.937487,
      "city": "Blairstown",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7826,
      "latitude": 41.188035,
      "longitude": -74.824675,
      "city": "Branchville",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7827,
      "latitude": 41.255279,
      "longitude": -74.644746,
      "city": "Montague",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7828,
      "latitude": 40.880028,
      "longitude": -74.75756,
      "city": "Budd Lake",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7829,
      "latitude": 40.84335,
      "longitude": -74.985914,
      "city": "Buttzville",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7830,
      "latitude": 40.717424,
      "longitude": -74.813951,
      "city": "Califon",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 7831,
      "latitude": 40.739382,
      "longitude": -74.944756,
      "city": "Changewater",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7832,
      "latitude": 40.893979,
      "longitude": -75.029163,
      "city": "Columbia",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7833,
      "latitude": 40.906906,
      "longitude": -75.075377,
      "city": "Delaware",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7834,
      "latitude": 40.881248,
      "longitude": -74.489349,
      "city": "Denville",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7836,
      "latitude": 40.850884,
      "longitude": -74.701558,
      "city": "Flanders",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7837,
      "latitude": 41.12831,
      "longitude": -74.678956,
      "city": "Glasser",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7838,
      "latitude": 40.888834,
      "longitude": -74.937714,
      "city": "Great Meadows",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7839,
      "latitude": 41.12831,
      "longitude": -74.678956,
      "city": "Greendell",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7840,
      "latitude": 40.868804,
      "longitude": -74.844734,
      "city": "Hackettstown",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7842,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Hibernia",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7843,
      "latitude": 40.936753,
      "longitude": -74.659969,
      "city": "Hopatcong",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7844,
      "latitude": 40.919658,
      "longitude": -74.984628,
      "city": "Hope",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7845,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Ironia",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7846,
      "latitude": 40.968998,
      "longitude": -74.873265,
      "city": "Johnsonburg",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7847,
      "latitude": 40.876098,
      "longitude": -74.695309,
      "city": "Kenvil",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7848,
      "latitude": 41.128183,
      "longitude": -74.731759,
      "city": "Lafayette",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7849,
      "latitude": 40.950597,
      "longitude": -74.612904,
      "city": "Lake Hopatcong",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7850,
      "latitude": 40.906298,
      "longitude": -74.664605,
      "city": "Landing",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7851,
      "latitude": 41.178266,
      "longitude": -74.89306,
      "city": "Layton",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7852,
      "latitude": 40.863318,
      "longitude": -74.721224,
      "city": "Ledgewood",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7853,
      "latitude": 40.806849,
      "longitude": -74.794595,
      "city": "Long Valley",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7855,
      "latitude": 41.12831,
      "longitude": -74.678956,
      "city": "Middleville",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7856,
      "latitude": 40.874049,
      "longitude": -74.73433,
      "city": "Mount Arlington",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7857,
      "latitude": 40.897808,
      "longitude": -74.700159,
      "city": "Netcong",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7860,
      "latitude": 41.069522,
      "longitude": -74.806938,
      "city": "Newton",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7863,
      "latitude": 40.820044,
      "longitude": -74.941392,
      "city": "Oxford",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7865,
      "latitude": 40.810748,
      "longitude": -74.87427,
      "city": "Port Murray",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7866,
      "latitude": 40.960111,
      "longitude": -74.501551,
      "city": "Rockaway",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7869,
      "latitude": 40.837399,
      "longitude": -74.586052,
      "city": "Randolph",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7870,
      "latitude": 40.810362,
      "longitude": -74.819318,
      "city": "Schooleys Mountain",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7871,
      "latitude": 41.040597,
      "longitude": -74.718191,
      "city": "Sparta",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7874,
      "latitude": 40.930937,
      "longitude": -74.713557,
      "city": "Stanhope",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7875,
      "latitude": 41.043858,
      "longitude": -74.871981,
      "city": "Stillwater",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7876,
      "latitude": 40.855286,
      "longitude": -74.655255,
      "city": "Succasunna",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7877,
      "latitude": 41.10289,
      "longitude": -74.850759,
      "city": "Swartswood",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7878,
      "latitude": 40.871099,
      "longitude": -74.477699,
      "city": "Mount Tabor",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7879,
      "latitude": 40.955934,
      "longitude": -74.788108,
      "city": "Tranquility",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7880,
      "latitude": 40.864844,
      "longitude": -74.897002,
      "city": "Vienna",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7881,
      "latitude": 41.125646,
      "longitude": -74.917711,
      "city": "Wallpack Center",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7882,
      "latitude": 40.747698,
      "longitude": -75.008713,
      "city": "Washington",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 7885,
      "latitude": 40.935349,
      "longitude": -74.577102,
      "city": "Wharton",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7890,
      "latitude": 41.12831,
      "longitude": -74.678956,
      "city": "Branchville",
      "state": "NJ",
      "county": "Sussex"
  },
  {
      "zipCode": 7901,
      "latitude": 40.71275,
      "longitude": -74.361594,
      "city": "Summit",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7902,
      "latitude": 40.665651,
      "longitude": -74.299692,
      "city": "Summit",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7920,
      "latitude": 40.682651,
      "longitude": -74.573001,
      "city": "Basking Ridge",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7921,
      "latitude": 40.659151,
      "longitude": -74.676788,
      "city": "Bedminster",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7922,
      "latitude": 40.675451,
      "longitude": -74.422196,
      "city": "Berkeley Heights",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7924,
      "latitude": 40.72575,
      "longitude": -74.593552,
      "city": "Bernardsville",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7926,
      "latitude": 40.800353,
      "longitude": -74.571785,
      "city": "Brookside",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7927,
      "latitude": 40.820799,
      "longitude": -74.456198,
      "city": "Cedar Knolls",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7928,
      "latitude": 40.759477,
      "longitude": -74.496806,
      "city": "Chatham",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7930,
      "latitude": 40.782111,
      "longitude": -74.683861,
      "city": "Chester",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7931,
      "latitude": 40.699572,
      "longitude": -74.653603,
      "city": "Far Hills",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7932,
      "latitude": 40.7735,
      "longitude": -74.397996,
      "city": "Florham Park",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7933,
      "latitude": 40.689339,
      "longitude": -74.472198,
      "city": "Gillette",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7934,
      "latitude": 40.715359,
      "longitude": -74.682767,
      "city": "Gladstone",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7935,
      "latitude": 40.739782,
      "longitude": -74.448747,
      "city": "Green Village",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7936,
      "latitude": 40.816349,
      "longitude": -74.367747,
      "city": "East Hanover",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7938,
      "latitude": 40.655399,
      "longitude": -74.586151,
      "city": "Liberty Corner",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7939,
      "latitude": 40.566553,
      "longitude": -74.599801,
      "city": "Lyons",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7940,
      "latitude": 40.7582,
      "longitude": -74.423019,
      "city": "Madison",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7945,
      "latitude": 40.786699,
      "longitude": -74.5938,
      "city": "Mendham",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7946,
      "latitude": 40.678701,
      "longitude": -74.505399,
      "city": "Millington",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7950,
      "latitude": 40.843399,
      "longitude": -74.540341,
      "city": "Morris Plains",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7960,
      "latitude": 40.77165,
      "longitude": -74.506256,
      "city": "Morristown",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7961,
      "latitude": 40.77975,
      "longitude": -74.442797,
      "city": "Morristown",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7962,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Morristown",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7963,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Morristown",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7970,
      "latitude": 40.805549,
      "longitude": -74.573832,
      "city": "Mount Freedom",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7974,
      "latitude": 40.697851,
      "longitude": -74.405595,
      "city": "New Providence",
      "state": "NJ",
      "county": "Union"
  },
  {
      "zipCode": 7976,
      "latitude": 40.739599,
      "longitude": -74.483413,
      "city": "New Vernon",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7977,
      "latitude": 40.707876,
      "longitude": -74.654131,
      "city": "Peapack",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7978,
      "latitude": 40.642491,
      "longitude": -74.639597,
      "city": "Pluckemin",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 7979,
      "latitude": 40.708241,
      "longitude": -74.748389,
      "city": "Pottersville",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 7980,
      "latitude": 40.69835,
      "longitude": -74.450197,
      "city": "Stirling",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7981,
      "latitude": 40.823899,
      "longitude": -74.419097,
      "city": "Whippany",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7983,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Whippany",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 7999,
      "latitude": 40.867331,
      "longitude": -74.578269,
      "city": "Whippany",
      "state": "NJ",
      "county": "Morris"
  },
  {
      "zipCode": 8001,
      "latitude": 39.559077,
      "longitude": -75.350573,
      "city": "Alloway",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8002,
      "latitude": 39.908663,
      "longitude": -75.010307,
      "city": "Cherry Hill",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8003,
      "latitude": 39.889763,
      "longitude": -74.972761,
      "city": "Cherry Hill",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8004,
      "latitude": 39.760048,
      "longitude": -74.866534,
      "city": "Atco",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8005,
      "latitude": 39.753858,
      "longitude": -74.293989,
      "city": "Barnegat",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8006,
      "latitude": 39.751214,
      "longitude": -74.114631,
      "city": "Barnegat Light",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8007,
      "latitude": 39.863813,
      "longitude": -75.053765,
      "city": "Barrington",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8008,
      "latitude": 39.641062,
      "longitude": -74.192228,
      "city": "Beach Haven",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8009,
      "latitude": 39.761231,
      "longitude": -74.92701,
      "city": "Berlin",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8010,
      "latitude": 40.049948,
      "longitude": -74.917061,
      "city": "Beverly",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8011,
      "latitude": 39.976041,
      "longitude": -74.711429,
      "city": "Birmingham",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8012,
      "latitude": 39.790131,
      "longitude": -75.036652,
      "city": "Blackwood",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8014,
      "latitude": 39.802163,
      "longitude": -75.339215,
      "city": "Bridgeport",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8015,
      "latitude": 39.924179,
      "longitude": -74.670408,
      "city": "Browns Mills",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8016,
      "latitude": 40.089874,
      "longitude": -74.751146,
      "city": "Burlington",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8018,
      "latitude": 39.80237,
      "longitude": -74.938259,
      "city": "Cedar Brook",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8019,
      "latitude": 39.749506,
      "longitude": -74.558941,
      "city": "Chatsworth",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8020,
      "latitude": 39.796706,
      "longitude": -75.218852,
      "city": "Clarksboro",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8021,
      "latitude": 39.80364,
      "longitude": -75.005762,
      "city": "Clementon",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8022,
      "latitude": 40.049355,
      "longitude": -74.702475,
      "city": "Columbus",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8023,
      "latitude": 39.681545,
      "longitude": -75.493433,
      "city": "Deepwater",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8025,
      "latitude": 39.701526,
      "longitude": -75.162903,
      "city": "Ewan",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8026,
      "latitude": 39.833114,
      "longitude": -74.967496,
      "city": "Gibbsboro",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8027,
      "latitude": 39.771133,
      "longitude": -75.272137,
      "city": "Gibbstown",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8028,
      "latitude": 39.696661,
      "longitude": -75.127183,
      "city": "Glassboro",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8029,
      "latitude": 39.837113,
      "longitude": -75.062315,
      "city": "Glendora",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8030,
      "latitude": 39.890413,
      "longitude": -75.112619,
      "city": "Gloucester City",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8031,
      "latitude": 39.805543,
      "longitude": -75.067729,
      "city": "Bellmawr",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8032,
      "latitude": 39.77875,
      "longitude": -75.060114,
      "city": "Grenloch",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8033,
      "latitude": 39.876313,
      "longitude": -75.033414,
      "city": "Haddonfield",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8034,
      "latitude": 39.899962,
      "longitude": -75.035015,
      "city": "Cherry Hill",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8035,
      "latitude": 39.879163,
      "longitude": -75.065666,
      "city": "Haddon Heights",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8036,
      "latitude": 39.987212,
      "longitude": -74.829308,
      "city": "Hainesport",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8037,
      "latitude": 39.563616,
      "longitude": -74.711081,
      "city": "Hammonton",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8038,
      "latitude": 39.569805,
      "longitude": -75.312703,
      "city": "Hancocks Bridge",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8039,
      "latitude": 39.693067,
      "longitude": -75.271114,
      "city": "Harrisonville",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8041,
      "latitude": 40.044129,
      "longitude": -74.679579,
      "city": "Jobstown",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8042,
      "latitude": 40.012273,
      "longitude": -74.66464,
      "city": "Juliustown",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8043,
      "latitude": 39.839156,
      "longitude": -74.965057,
      "city": "Voorhees",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8045,
      "latitude": 39.868113,
      "longitude": -75.032114,
      "city": "Lawnside",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8046,
      "latitude": 40.013737,
      "longitude": -74.805773,
      "city": "Willingboro",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8048,
      "latitude": 39.962299,
      "longitude": -74.80007,
      "city": "Lumberton",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8049,
      "latitude": 39.854363,
      "longitude": -75.036214,
      "city": "Magnolia",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8050,
      "latitude": 39.702978,
      "longitude": -74.257677,
      "city": "Manahawkin",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8051,
      "latitude": 39.751571,
      "longitude": -75.212842,
      "city": "Mantua",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8052,
      "latitude": 39.951212,
      "longitude": -74.992163,
      "city": "Maple Shade",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8053,
      "latitude": 39.892263,
      "longitude": -74.94075,
      "city": "Marlton",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8054,
      "latitude": 39.955912,
      "longitude": -74.91731,
      "city": "Mount Laurel",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8055,
      "latitude": 39.874549,
      "longitude": -74.762083,
      "city": "Medford",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8056,
      "latitude": 39.787913,
      "longitude": -75.246687,
      "city": "Mickleton",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8057,
      "latitude": 40.054255,
      "longitude": -74.840115,
      "city": "Moorestown",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8059,
      "latitude": 39.886463,
      "longitude": -75.094068,
      "city": "Mount Ephraim",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8060,
      "latitude": 40.044956,
      "longitude": -74.801869,
      "city": "Mount Holly",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8061,
      "latitude": 39.808643,
      "longitude": -75.212943,
      "city": "Mount Royal",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8062,
      "latitude": 39.715857,
      "longitude": -75.235017,
      "city": "Mullica Hill",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8063,
      "latitude": 39.779654,
      "longitude": -75.218413,
      "city": "National Park",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8064,
      "latitude": 39.862433,
      "longitude": -74.725079,
      "city": "New Lisbon",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8065,
      "latitude": 40.031291,
      "longitude": -74.861971,
      "city": "Palmyra",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8066,
      "latitude": 39.81569,
      "longitude": -75.238849,
      "city": "Paulsboro",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8067,
      "latitude": 39.733769,
      "longitude": -75.408412,
      "city": "Pedricktown",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8068,
      "latitude": 39.966394,
      "longitude": -74.709185,
      "city": "Pemberton",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8069,
      "latitude": 39.619937,
      "longitude": -75.288423,
      "city": "Penns Grove",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8070,
      "latitude": 39.637683,
      "longitude": -75.512699,
      "city": "Pennsville",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8071,
      "latitude": 39.734928,
      "longitude": -75.136809,
      "city": "Pitman",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8072,
      "latitude": 39.541055,
      "longitude": -75.383689,
      "city": "Quinton",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8073,
      "latitude": 39.862433,
      "longitude": -74.725079,
      "city": "Rancocas",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8074,
      "latitude": 39.720622,
      "longitude": -75.168141,
      "city": "Richwood",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8075,
      "latitude": 40.063293,
      "longitude": -74.853307,
      "city": "Riverside",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8076,
      "latitude": 39.862433,
      "longitude": -74.725079,
      "city": "Riverton",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8077,
      "latitude": 39.999211,
      "longitude": -74.90871,
      "city": "Riverton",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8078,
      "latitude": 39.850163,
      "longitude": -75.070672,
      "city": "Runnemede",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8079,
      "latitude": 39.587729,
      "longitude": -75.439362,
      "city": "Salem",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8080,
      "latitude": 39.75521,
      "longitude": -75.201593,
      "city": "Sewell",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8081,
      "latitude": 39.747558,
      "longitude": -74.970462,
      "city": "Sicklerville",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8083,
      "latitude": 39.844063,
      "longitude": -75.022163,
      "city": "Somerdale",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8084,
      "latitude": 39.829763,
      "longitude": -75.012012,
      "city": "Stratford",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8085,
      "latitude": 39.75274,
      "longitude": -75.318049,
      "city": "Swedesboro",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8086,
      "latitude": 39.838721,
      "longitude": -75.24861,
      "city": "Thorofare",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8087,
      "latitude": 39.671687,
      "longitude": -74.288092,
      "city": "Tuckerton",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8088,
      "latitude": 39.860351,
      "longitude": -74.669346,
      "city": "Vincentown",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8089,
      "latitude": 39.723037,
      "longitude": -74.836939,
      "city": "Waterford Works",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8090,
      "latitude": 39.798313,
      "longitude": -75.148071,
      "city": "Wenonah",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8091,
      "latitude": 39.734923,
      "longitude": -74.950993,
      "city": "West Berlin",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8092,
      "latitude": 39.656391,
      "longitude": -74.295555,
      "city": "West Creek",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8093,
      "latitude": 39.85781,
      "longitude": -75.13732,
      "city": "Westville",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8094,
      "latitude": 39.725538,
      "longitude": -75.058082,
      "city": "Williamstown",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8095,
      "latitude": 39.654885,
      "longitude": -74.868531,
      "city": "Winslow",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8096,
      "latitude": 39.822013,
      "longitude": -75.129669,
      "city": "Woodbury",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8097,
      "latitude": 39.762971,
      "longitude": -75.19787,
      "city": "Woodbury Heights",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8098,
      "latitude": 39.586,
      "longitude": -75.379897,
      "city": "Woodstown",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8099,
      "latitude": 39.779179,
      "longitude": -74.962071,
      "city": "Bellmawr",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8101,
      "latitude": 39.80237,
      "longitude": -74.938259,
      "city": "Camden",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8102,
      "latitude": 39.950912,
      "longitude": -75.118869,
      "city": "Camden",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8103,
      "latitude": 39.933174,
      "longitude": -75.108519,
      "city": "Camden",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8104,
      "latitude": 39.916612,
      "longitude": -75.109169,
      "city": "Camden",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8105,
      "latitude": 39.920162,
      "longitude": -75.084667,
      "city": "Camden",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8106,
      "latitude": 39.828349,
      "longitude": -74.994647,
      "city": "Audubon",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8107,
      "latitude": 39.872001,
      "longitude": -75.053114,
      "city": "Oaklyn",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8108,
      "latitude": 39.834003,
      "longitude": -74.991268,
      "city": "Collingswood",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8109,
      "latitude": 39.950312,
      "longitude": -75.059916,
      "city": "Merchantville",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8110,
      "latitude": 39.839405,
      "longitude": -74.945624,
      "city": "Pennsauken",
      "state": "NJ",
      "county": "Camden"
  },
  {
      "zipCode": 8201,
      "latitude": 39.48909,
      "longitude": -74.680323,
      "city": "Absecon",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8202,
      "latitude": 39.112027,
      "longitude": -74.731427,
      "city": "Avalon",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8203,
      "latitude": 39.370172,
      "longitude": -74.49401,
      "city": "Brigantine",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8204,
      "latitude": 39.110337,
      "longitude": -74.767939,
      "city": "Cape May",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8205,
      "latitude": 39.482,
      "longitude": -74.452637,
      "city": "Absecon",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8210,
      "latitude": 39.137841,
      "longitude": -74.78056,
      "city": "Cape May Court House",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8212,
      "latitude": 38.937168,
      "longitude": -74.965385,
      "city": "Cape May Point",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8213,
      "latitude": 39.509208,
      "longitude": -74.608557,
      "city": "Cologne",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8214,
      "latitude": 39.056521,
      "longitude": -74.816619,
      "city": "Dennisville",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8215,
      "latitude": 39.530539,
      "longitude": -74.638341,
      "city": "Egg Harbor City",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8217,
      "latitude": 39.573741,
      "longitude": -74.719989,
      "city": "Elwood",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8218,
      "latitude": 39.056521,
      "longitude": -74.816619,
      "city": "Goshen",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8219,
      "latitude": 39.056521,
      "longitude": -74.816619,
      "city": "Green Creek",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8220,
      "latitude": 39.509208,
      "longitude": -74.608557,
      "city": "Leeds Point",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8221,
      "latitude": 39.427786,
      "longitude": -74.622112,
      "city": "Linwood",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8223,
      "latitude": 39.13952,
      "longitude": -74.73038,
      "city": "Marmora",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8224,
      "latitude": 39.595197,
      "longitude": -74.435683,
      "city": "New Gretna",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8225,
      "latitude": 39.421922,
      "longitude": -74.572227,
      "city": "Northfield",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8226,
      "latitude": 39.24853,
      "longitude": -74.604409,
      "city": "Ocean City",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8230,
      "latitude": 39.205183,
      "longitude": -74.778293,
      "city": "Ocean View",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8231,
      "latitude": 39.509208,
      "longitude": -74.608557,
      "city": "Oceanville",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8232,
      "latitude": 39.470869,
      "longitude": -74.686004,
      "city": "Pleasantville",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8234,
      "latitude": 39.374067,
      "longitude": -74.611764,
      "city": "Egg Harbor Township",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8240,
      "latitude": 39.487717,
      "longitude": -74.554334,
      "city": "Pomona",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8241,
      "latitude": 39.521785,
      "longitude": -74.694414,
      "city": "Port Republic",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8242,
      "latitude": 39.017283,
      "longitude": -74.883671,
      "city": "Rio Grande",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8243,
      "latitude": 39.154028,
      "longitude": -74.700502,
      "city": "Sea Isle City",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8244,
      "latitude": 39.425655,
      "longitude": -74.664422,
      "city": "Somers Point",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8245,
      "latitude": 39.09998,
      "longitude": -74.848733,
      "city": "South Dennis",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8246,
      "latitude": 39.056521,
      "longitude": -74.816619,
      "city": "South Seaville",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8247,
      "latitude": 39.047882,
      "longitude": -74.77505,
      "city": "Stone Harbor",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8248,
      "latitude": 39.192018,
      "longitude": -74.661977,
      "city": "Strathmere",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8250,
      "latitude": 39.056521,
      "longitude": -74.816619,
      "city": "Tuckahoe",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8251,
      "latitude": 39.140921,
      "longitude": -74.851181,
      "city": "Villas",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8252,
      "latitude": 39.042124,
      "longitude": -74.861792,
      "city": "Whitesboro",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8260,
      "latitude": 39.067777,
      "longitude": -74.77674,
      "city": "Wildwood",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8270,
      "latitude": 39.158165,
      "longitude": -74.786886,
      "city": "Woodbine",
      "state": "NJ",
      "county": "Cape May"
  },
  {
      "zipCode": 8302,
      "latitude": 39.3762,
      "longitude": -75.1617,
      "city": "Bridgeton",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8310,
      "latitude": 39.523712,
      "longitude": -74.899762,
      "city": "Buena",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8311,
      "latitude": 39.330611,
      "longitude": -75.117761,
      "city": "Cedarville",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8312,
      "latitude": 39.653531,
      "longitude": -75.078625,
      "city": "Clayton",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8313,
      "latitude": 39.529261,
      "longitude": -75.224865,
      "city": "Deerfield Street",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8314,
      "latitude": 39.358151,
      "longitude": -74.977352,
      "city": "Delmont",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8315,
      "latitude": 39.273214,
      "longitude": -75.095025,
      "city": "Dividing Creek",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8316,
      "latitude": 39.267898,
      "longitude": -74.979118,
      "city": "Dorchester",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8317,
      "latitude": 39.425858,
      "longitude": -74.826057,
      "city": "Dorothy",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8318,
      "latitude": 39.548074,
      "longitude": -75.198368,
      "city": "Elmer",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8319,
      "latitude": 39.376144,
      "longitude": -74.805412,
      "city": "Estell Manor",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8320,
      "latitude": 39.379906,
      "longitude": -75.221681,
      "city": "Fairton",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8321,
      "latitude": 39.273034,
      "longitude": -75.19137,
      "city": "Fortescue",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8322,
      "latitude": 39.59823,
      "longitude": -75.035749,
      "city": "Franklinville",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8323,
      "latitude": 39.405498,
      "longitude": -75.320881,
      "city": "Greenwich",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8324,
      "latitude": 39.224047,
      "longitude": -74.994184,
      "city": "Heislerville",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8326,
      "latitude": 39.533843,
      "longitude": -74.934493,
      "city": "Landisville",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8327,
      "latitude": 39.387948,
      "longitude": -75.007418,
      "city": "Leesburg",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8328,
      "latitude": 39.58101,
      "longitude": -75.055482,
      "city": "Malaga",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8329,
      "latitude": 39.285278,
      "longitude": -74.998319,
      "city": "Mauricetown",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8330,
      "latitude": 39.47204,
      "longitude": -74.71588,
      "city": "Mays Landing",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8332,
      "latitude": 39.370401,
      "longitude": -75.04282,
      "city": "Millville",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8340,
      "latitude": 39.447855,
      "longitude": -74.869885,
      "city": "Milmay",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8341,
      "latitude": 39.530779,
      "longitude": -74.940036,
      "city": "Minotola",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8342,
      "latitude": 39.50208,
      "longitude": -74.833469,
      "city": "Mizpah",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8343,
      "latitude": 39.641089,
      "longitude": -75.144475,
      "city": "Monroeville",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8344,
      "latitude": 39.565465,
      "longitude": -75.014084,
      "city": "Newfield",
      "state": "NJ",
      "county": "Gloucester"
  },
  {
      "zipCode": 8345,
      "latitude": 39.304244,
      "longitude": -75.109132,
      "city": "Newport",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8346,
      "latitude": 39.557607,
      "longitude": -74.870807,
      "city": "Newtonville",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8347,
      "latitude": 39.499765,
      "longitude": -75.082022,
      "city": "Norma",
      "state": "NJ",
      "county": "Salem"
  },
  {
      "zipCode": 8348,
      "latitude": 39.313114,
      "longitude": -74.980668,
      "city": "Port Elizabeth",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8349,
      "latitude": 39.283259,
      "longitude": -75.098448,
      "city": "Port Norris",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8350,
      "latitude": 39.491351,
      "longitude": -74.875543,
      "city": "Richland",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8352,
      "latitude": 39.469515,
      "longitude": -75.146176,
      "city": "Rosenhayn",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8353,
      "latitude": 39.42165,
      "longitude": -75.207667,
      "city": "Shiloh",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8360,
      "latitude": 39.392671,
      "longitude": -75.025676,
      "city": "Vineland",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8361,
      "latitude": 39.465465,
      "longitude": -74.965279,
      "city": "Vineland",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8362,
      "latitude": 39.271264,
      "longitude": -75.027671,
      "city": "Vineland",
      "state": "NJ",
      "county": "Cumberland"
  },
  {
      "zipCode": 8401,
      "latitude": 39.486848,
      "longitude": -74.643014,
      "city": "Atlantic City",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8402,
      "latitude": 39.442068,
      "longitude": -74.666123,
      "city": "Margate City",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8403,
      "latitude": 39.324265,
      "longitude": -74.513497,
      "city": "Longport",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8404,
      "latitude": 39.509208,
      "longitude": -74.608557,
      "city": "Atlantic City",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8405,
      "latitude": 39.509208,
      "longitude": -74.608557,
      "city": "Atlantic City",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8406,
      "latitude": 39.414646,
      "longitude": -74.66415,
      "city": "Ventnor City",
      "state": "NJ",
      "county": "Atlantic"
  },
  {
      "zipCode": 8501,
      "latitude": 40.149712,
      "longitude": -74.539598,
      "city": "Allentown",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8502,
      "latitude": 40.461504,
      "longitude": -74.648302,
      "city": "Belle Mead",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8504,
      "latitude": 40.425369,
      "longitude": -74.668753,
      "city": "Blawenburg",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8505,
      "latitude": 40.101535,
      "longitude": -74.724711,
      "city": "Bordentown",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8510,
      "latitude": 40.193311,
      "longitude": -74.442803,
      "city": "Clarksburg",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8511,
      "latitude": 40.049551,
      "longitude": -74.557215,
      "city": "Cookstown",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8512,
      "latitude": 40.323116,
      "longitude": -74.516597,
      "city": "Cranbury",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8514,
      "latitude": 40.139913,
      "longitude": -74.465018,
      "city": "Cream Ridge",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8515,
      "latitude": 40.147555,
      "longitude": -74.661474,
      "city": "Crosswicks",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8518,
      "latitude": 40.12486,
      "longitude": -74.738863,
      "city": "Florence",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8520,
      "latitude": 40.281558,
      "longitude": -74.572449,
      "city": "Hightstown",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8525,
      "latitude": 40.389675,
      "longitude": -74.783956,
      "city": "Hopewell",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8526,
      "latitude": 40.162275,
      "longitude": -74.475936,
      "city": "Imlaystown",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8527,
      "latitude": 40.105164,
      "longitude": -74.352814,
      "city": "Jackson",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8528,
      "latitude": 40.382756,
      "longitude": -74.609551,
      "city": "Kingston",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8530,
      "latitude": 40.37936,
      "longitude": -74.901535,
      "city": "Lambertville",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8533,
      "latitude": 40.08294,
      "longitude": -74.498741,
      "city": "New Egypt",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8534,
      "latitude": 40.32863,
      "longitude": -74.813557,
      "city": "Pennington",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8535,
      "latitude": 40.22586,
      "longitude": -74.452293,
      "city": "Perrineville",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8536,
      "latitude": 40.342197,
      "longitude": -74.582062,
      "city": "Plainsboro",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8540,
      "latitude": 40.343625,
      "longitude": -74.693953,
      "city": "Princeton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8541,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Princeton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8542,
      "latitude": 40.350456,
      "longitude": -74.658852,
      "city": "Princeton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8543,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Princeton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8544,
      "latitude": 40.349206,
      "longitude": -74.652811,
      "city": "Princeton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8550,
      "latitude": 40.266858,
      "longitude": -74.651101,
      "city": "Princeton Junction",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8551,
      "latitude": 40.445602,
      "longitude": -74.840322,
      "city": "Ringoes",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8553,
      "latitude": 40.412705,
      "longitude": -74.632256,
      "city": "Rocky Hill",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8554,
      "latitude": 40.071829,
      "longitude": -74.711814,
      "city": "Roebling",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8555,
      "latitude": 40.22136,
      "longitude": -74.474745,
      "city": "Roosevelt",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8556,
      "latitude": 40.419965,
      "longitude": -74.988619,
      "city": "Rosemont",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8557,
      "latitude": 40.563654,
      "longitude": -74.949409,
      "city": "Sergeantsville",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8558,
      "latitude": 40.434255,
      "longitude": -74.708525,
      "city": "Skillman",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8559,
      "latitude": 40.436413,
      "longitude": -74.970597,
      "city": "Stockton",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8560,
      "latitude": 40.311826,
      "longitude": -74.863858,
      "city": "Titusville",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8561,
      "latitude": 40.242313,
      "longitude": -74.578709,
      "city": "Windsor",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8562,
      "latitude": 40.0751,
      "longitude": -74.608402,
      "city": "Wrightstown",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8570,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "Cranbury",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8601,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8602,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8603,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8604,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8605,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8606,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8607,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8608,
      "latitude": 40.220108,
      "longitude": -74.764055,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8609,
      "latitude": 40.224808,
      "longitude": -74.741004,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8610,
      "latitude": 40.201609,
      "longitude": -74.704953,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8611,
      "latitude": 40.196658,
      "longitude": -74.741554,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8618,
      "latitude": 40.223608,
      "longitude": -74.732504,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8619,
      "latitude": 40.241808,
      "longitude": -74.696151,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8620,
      "latitude": 40.194664,
      "longitude": -74.639852,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8625,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8628,
      "latitude": 40.265457,
      "longitude": -74.816757,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8629,
      "latitude": 40.219558,
      "longitude": -74.733404,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8638,
      "latitude": 40.232908,
      "longitude": -74.716954,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8640,
      "latitude": 40.009789,
      "longitude": -74.605198,
      "city": "Trenton",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8641,
      "latitude": 39.968787,
      "longitude": -74.62405,
      "city": "Trenton",
      "state": "NJ",
      "county": "Burlington"
  },
  {
      "zipCode": 8645,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8646,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8647,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8648,
      "latitude": 40.279457,
      "longitude": -74.69118,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8650,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8666,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8677,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8690,
      "latitude": 40.233577,
      "longitude": -74.657602,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8691,
      "latitude": 40.219738,
      "longitude": -74.593949,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8695,
      "latitude": 40.280531,
      "longitude": -74.712018,
      "city": "Trenton",
      "state": "NJ",
      "county": "Mercer"
  },
  {
      "zipCode": 8701,
      "latitude": 39.94517,
      "longitude": -74.149784,
      "city": "Lakewood",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8720,
      "latitude": 40.138878,
      "longitude": -74.11221,
      "city": "Allenwood",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8721,
      "latitude": 39.966604,
      "longitude": -74.300023,
      "city": "Bayville",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8722,
      "latitude": 39.927521,
      "longitude": -74.198585,
      "city": "Beachwood",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8723,
      "latitude": 39.940021,
      "longitude": -74.122989,
      "city": "Brick",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8724,
      "latitude": 39.938047,
      "longitude": -74.17109,
      "city": "Brick",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8730,
      "latitude": 40.107614,
      "longitude": -74.065081,
      "city": "Brielle",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8731,
      "latitude": 39.876031,
      "longitude": -74.230522,
      "city": "Forked River",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8732,
      "latitude": 39.983267,
      "longitude": -74.128482,
      "city": "Island Heights",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8733,
      "latitude": 39.992317,
      "longitude": -74.28224,
      "city": "Lakehurst",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8734,
      "latitude": 39.863489,
      "longitude": -74.168217,
      "city": "Lanoka Harbor",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8735,
      "latitude": 39.980917,
      "longitude": -74.07203,
      "city": "Lavallette",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8736,
      "latitude": 40.121514,
      "longitude": -74.069963,
      "city": "Manasquan",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8738,
      "latitude": 40.029916,
      "longitude": -74.05688,
      "city": "Mantoloking",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8739,
      "latitude": 40.000759,
      "longitude": -74.24928,
      "city": "Normandy Beach",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8740,
      "latitude": 39.926628,
      "longitude": -74.133332,
      "city": "Ocean Gate",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8741,
      "latitude": 40.003246,
      "longitude": -74.109626,
      "city": "Pine Beach",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8742,
      "latitude": 39.954807,
      "longitude": -74.157056,
      "city": "Point Pleasant Beach",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8750,
      "latitude": 40.161392,
      "longitude": -74.043772,
      "city": "Sea Girt",
      "state": "NJ",
      "county": "Monmouth"
  },
  {
      "zipCode": 8751,
      "latitude": 39.945991,
      "longitude": -74.181649,
      "city": "Seaside Heights",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8752,
      "latitude": 39.805832,
      "longitude": -74.145694,
      "city": "Seaside Park",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8753,
      "latitude": 39.958851,
      "longitude": -74.215336,
      "city": "Toms River",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8754,
      "latitude": 40.000759,
      "longitude": -74.24928,
      "city": "Toms River",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8755,
      "latitude": 40.000696,
      "longitude": -74.256821,
      "city": "Toms River",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8756,
      "latitude": 39.787966,
      "longitude": -74.191058,
      "city": "Toms River",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8757,
      "latitude": 39.943064,
      "longitude": -74.264068,
      "city": "Toms River",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8758,
      "latitude": 39.787943,
      "longitude": -74.246662,
      "city": "Waretown",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8759,
      "latitude": 39.945798,
      "longitude": -74.29782,
      "city": "Manchester Township",
      "state": "NJ",
      "county": "Ocean"
  },
  {
      "zipCode": 8801,
      "latitude": 40.631008,
      "longitude": -74.890685,
      "city": "Annandale",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8802,
      "latitude": 40.695034,
      "longitude": -75.028089,
      "city": "Asbury",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 8803,
      "latitude": 40.563654,
      "longitude": -74.949409,
      "city": "Baptistown",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8804,
      "latitude": 40.646294,
      "longitude": -75.092934,
      "city": "Bloomsbury",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8805,
      "latitude": 40.575402,
      "longitude": -74.538249,
      "city": "Bound Brook",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8807,
      "latitude": 40.598702,
      "longitude": -74.607518,
      "city": "Bridgewater",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8808,
      "latitude": 40.737213,
      "longitude": -75.046926,
      "city": "Broadway",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 8809,
      "latitude": 40.5305,
      "longitude": -74.845574,
      "city": "Clinton",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8810,
      "latitude": 40.372306,
      "longitude": -74.494889,
      "city": "Dayton",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8812,
      "latitude": 40.589702,
      "longitude": -74.463947,
      "city": "Dunellen",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8816,
      "latitude": 40.427697,
      "longitude": -74.421695,
      "city": "East Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8817,
      "latitude": 40.519753,
      "longitude": -74.393444,
      "city": "Edison",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8818,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "Edison",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8820,
      "latitude": 40.576611,
      "longitude": -74.362616,
      "city": "Edison",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8821,
      "latitude": 40.566553,
      "longitude": -74.599801,
      "city": "Flagtown",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8822,
      "latitude": 40.50786,
      "longitude": -74.863283,
      "city": "Flemington",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8823,
      "latitude": 40.440604,
      "longitude": -74.560249,
      "city": "Franklin Park",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8824,
      "latitude": 40.422955,
      "longitude": -74.549761,
      "city": "Kendall Park",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8825,
      "latitude": 40.58119,
      "longitude": -75.012655,
      "city": "Frenchtown",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8826,
      "latitude": 40.578191,
      "longitude": -74.904575,
      "city": "Glen Gardner",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8827,
      "latitude": 40.6667,
      "longitude": -74.964692,
      "city": "Hampton",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8828,
      "latitude": 40.37554,
      "longitude": -74.421644,
      "city": "Helmetta",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8829,
      "latitude": 40.668163,
      "longitude": -74.889823,
      "city": "High Bridge",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8830,
      "latitude": 40.569211,
      "longitude": -74.315042,
      "city": "Iselin",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8831,
      "latitude": 40.343757,
      "longitude": -74.42887,
      "city": "Monroe Township",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8832,
      "latitude": 40.517559,
      "longitude": -74.306961,
      "city": "Keasbey",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8833,
      "latitude": 40.635369,
      "longitude": -74.829976,
      "city": "Lebanon",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8834,
      "latitude": 40.563654,
      "longitude": -74.949409,
      "city": "Little York",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8835,
      "latitude": 40.541053,
      "longitude": -74.589225,
      "city": "Manville",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8836,
      "latitude": 40.604352,
      "longitude": -74.5538,
      "city": "Martinsville",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8837,
      "latitude": 40.527054,
      "longitude": -74.350393,
      "city": "Edison",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8840,
      "latitude": 40.472224,
      "longitude": -74.45194,
      "city": "Metuchen",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8844,
      "latitude": 40.497692,
      "longitude": -74.670505,
      "city": "Hillsborough",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8846,
      "latitude": 40.575202,
      "longitude": -74.499398,
      "city": "Middlesex",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8848,
      "latitude": 40.664749,
      "longitude": -74.970542,
      "city": "Milford",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8850,
      "latitude": 40.448255,
      "longitude": -74.444395,
      "city": "Milltown",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8852,
      "latitude": 40.386942,
      "longitude": -74.555799,
      "city": "Monmouth Junction",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8853,
      "latitude": 40.492604,
      "longitude": -74.726555,
      "city": "Neshanic Station",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8854,
      "latitude": 40.552925,
      "longitude": -74.457746,
      "city": "Piscataway",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8855,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "Piscataway",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8857,
      "latitude": 40.407319,
      "longitude": -74.314319,
      "city": "Old Bridge",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8858,
      "latitude": 40.691798,
      "longitude": -74.752537,
      "city": "Oldwick",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8859,
      "latitude": 40.459205,
      "longitude": -74.306956,
      "city": "Parlin",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8861,
      "latitude": 40.520654,
      "longitude": -74.279144,
      "city": "Perth Amboy",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8862,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "Perth Amboy",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8863,
      "latitude": 40.53139,
      "longitude": -74.31525,
      "city": "Fords",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8865,
      "latitude": 40.765309,
      "longitude": -74.98743,
      "city": "Phillipsburg",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 8867,
      "latitude": 40.585926,
      "longitude": -74.976982,
      "city": "Pittstown",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8868,
      "latitude": 40.565493,
      "longitude": -74.938931,
      "city": "Quakertown",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8869,
      "latitude": 40.573802,
      "longitude": -74.642649,
      "city": "Raritan",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8870,
      "latitude": 40.563654,
      "longitude": -74.949409,
      "city": "Readington",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8871,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "Sayreville",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8872,
      "latitude": 40.453358,
      "longitude": -74.334192,
      "city": "Sayreville",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8873,
      "latitude": 40.491002,
      "longitude": -74.523648,
      "city": "Somerset",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8875,
      "latitude": 40.580918,
      "longitude": -74.711731,
      "city": "Somerset",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8876,
      "latitude": 40.560314,
      "longitude": -74.66165,
      "city": "Somerville",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8877,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "South River",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8878,
      "latitude": 40.436865,
      "longitude": -74.250942,
      "city": "South Amboy",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8879,
      "latitude": 40.46397,
      "longitude": -74.274168,
      "city": "South Amboy",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8880,
      "latitude": 40.552253,
      "longitude": -74.531149,
      "city": "South Bound Brook",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8882,
      "latitude": 40.445905,
      "longitude": -74.381768,
      "city": "South River",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8884,
      "latitude": 40.385707,
      "longitude": -74.393249,
      "city": "Spotswood",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8885,
      "latitude": 40.576372,
      "longitude": -74.831105,
      "city": "Stanton",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8886,
      "latitude": 40.717349,
      "longitude": -75.072957,
      "city": "Stewartsville",
      "state": "NJ",
      "county": "Warren"
  },
  {
      "zipCode": 8887,
      "latitude": 40.52057,
      "longitude": -74.794632,
      "city": "Three Bridges",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8888,
      "latitude": 40.619412,
      "longitude": -74.740597,
      "city": "Whitehouse",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8889,
      "latitude": 40.615577,
      "longitude": -74.772376,
      "city": "Whitehouse Station",
      "state": "NJ",
      "county": "Hunterdon"
  },
  {
      "zipCode": 8890,
      "latitude": 40.536069,
      "longitude": -74.578872,
      "city": "Zarephath",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8896,
      "latitude": 40.566553,
      "longitude": -74.599801,
      "city": "Raritan",
      "state": "NJ",
      "county": "Somerset"
  },
  {
      "zipCode": 8899,
      "latitude": 40.520254,
      "longitude": -74.420545,
      "city": "Edison",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8901,
      "latitude": 40.486754,
      "longitude": -74.444395,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8902,
      "latitude": 40.437705,
      "longitude": -74.488547,
      "city": "North Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8903,
      "latitude": 40.513854,
      "longitude": -74.445098,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8904,
      "latitude": 40.499866,
      "longitude": -74.428095,
      "city": "Highland Park",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8905,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8906,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8922,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8933,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8988,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 8989,
      "latitude": 40.430006,
      "longitude": -74.417344,
      "city": "New Brunswick",
      "state": "NJ",
      "county": "Middlesex"
  },
  {
      "zipCode": 9007,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9009,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9012,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9013,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9014,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9021,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9028,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9029,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9031,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9033,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9034,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9036,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9037,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9042,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9045,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9046,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9050,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9053,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9054,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9056,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9058,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9059,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9060,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9063,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9067,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9069,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9072,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9074,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9076,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9080,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9081,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9086,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9089,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9090,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9094,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9095,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9096,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9098,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9099,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9100,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9102,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9103,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9104,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9106,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9107,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9110,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9111,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9112,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9114,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9123,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9126,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9128,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9131,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9136,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9137,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9138,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9139,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9140,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9142,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9143,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9154,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9157,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9164,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9165,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9166,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9169,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9172,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9173,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9175,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9177,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9178,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9180,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9182,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9183,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9185,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9186,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9189,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9211,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9212,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9213,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9214,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9220,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9222,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9225,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9226,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9227,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9229,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9234,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9237,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9239,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9244,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9245,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9250,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9252,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9262,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9263,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9264,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9265,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9266,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9267,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9269,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9275,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9302,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9303,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9304,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9305,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9340,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9391,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9396,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9397,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9398,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9409,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9419,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9420,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9421,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9447,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9448,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9449,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9454,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9456,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9459,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9461,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9463,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9464,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9468,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9469,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9470,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9494,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9496,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9498,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9499,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9501,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9502,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9503,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9504,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9505,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9506,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9507,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9508,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9509,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9510,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9511,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9517,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9521,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9524,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9532,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9534,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9536,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9543,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9544,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9545,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9549,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9550,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9551,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9554,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9556,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9557,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9558,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9563,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9564,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9565,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9566,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9567,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9568,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9569,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9570,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9573,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9574,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9575,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9576,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9577,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9578,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9579,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9581,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9582,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9586,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9587,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9588,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9589,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9590,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9591,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9593,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9594,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9595,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9596,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9599,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9601,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9603,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9604,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9609,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9610,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9612,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9613,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9617,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9618,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9619,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9620,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9621,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9622,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9623,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9624,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9625,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9626,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9627,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9628,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9630,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9631,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9636,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9638,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9642,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9643,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9644,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9645,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9647,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9649,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9703,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9704,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9705,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9706,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9707,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9708,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9709,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9710,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9711,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9713,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9714,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9715,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9716,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9717,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9718,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9720,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9721,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9722,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9723,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9724,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9725,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9726,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9727,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9728,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9729,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9730,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9731,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9732,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9733,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9734,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9735,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9777,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9779,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9780,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9782,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9783,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9784,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9789,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9790,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9791,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9792,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9793,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9795,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9796,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9797,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9802,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9803,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9804,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9805,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9808,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9809,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9810,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9811,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9812,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9813,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9814,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9815,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9816,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9819,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9821,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9822,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9823,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9824,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9825,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9826,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9827,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9828,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9829,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9830,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9831,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9832,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9833,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9834,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9835,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9836,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9837,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9838,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9839,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9841,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9842,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9843,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9844,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9852,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9853,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9854,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9855,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9858,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9865,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9866,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9867,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9868,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9871,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9876,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9880,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9882,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9888,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9889,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9890,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9892,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9894,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9898,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 9899,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AE",
      "county": ""
  },
  {
      "zipCode": 10001,
      "latitude": 40.750422,
      "longitude": -73.996328,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10002,
      "latitude": 40.718758,
      "longitude": -73.986427,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10003,
      "latitude": 40.730223,
      "longitude": -73.988564,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10004,
      "latitude": 40.696355,
      "longitude": -74.025276,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10005,
      "latitude": 40.706903,
      "longitude": -74.008654,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10006,
      "latitude": 40.708834,
      "longitude": -74.013168,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10007,
      "latitude": 40.713941,
      "longitude": -74.007401,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10008,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10009,
      "latitude": 40.72775,
      "longitude": -73.980396,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10010,
      "latitude": 40.739024,
      "longitude": -73.983542,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10011,
      "latitude": 40.740916,
      "longitude": -73.999769,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10012,
      "latitude": 40.729124,
      "longitude": -73.991582,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10013,
      "latitude": 40.722105,
      "longitude": -74.003497,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10014,
      "latitude": 40.738088,
      "longitude": -74.005095,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10015,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10016,
      "latitude": 40.745527,
      "longitude": -73.978449,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10017,
      "latitude": 40.752955,
      "longitude": -73.973196,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10018,
      "latitude": 40.755332,
      "longitude": -73.993172,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10019,
      "latitude": 40.765926,
      "longitude": -73.985443,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10020,
      "latitude": 40.735449,
      "longitude": -73.996788,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10021,
      "latitude": 40.768823,
      "longitude": -73.960257,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10022,
      "latitude": 40.758775,
      "longitude": -73.967842,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10023,
      "latitude": 40.776765,
      "longitude": -73.982213,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10024,
      "latitude": 40.80813,
      "longitude": -73.965653,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10025,
      "latitude": 40.798664,
      "longitude": -73.967778,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10026,
      "latitude": 40.802918,
      "longitude": -73.953107,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10027,
      "latitude": 40.812242,
      "longitude": -73.9532,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10028,
      "latitude": 40.776629,
      "longitude": -73.953914,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10029,
      "latitude": 40.791884,
      "longitude": -73.943517,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10030,
      "latitude": 40.818474,
      "longitude": -73.94329,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10031,
      "latitude": 40.823438,
      "longitude": -73.949039,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10032,
      "latitude": 40.839137,
      "longitude": -73.942181,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10033,
      "latitude": 40.851293,
      "longitude": -73.934764,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10034,
      "latitude": 40.863194,
      "longitude": -73.926959,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10035,
      "latitude": 40.801913,
      "longitude": -73.9347,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10036,
      "latitude": 40.75953,
      "longitude": -73.989847,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10037,
      "latitude": 40.813884,
      "longitude": -73.938498,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10038,
      "latitude": 40.710223,
      "longitude": -74.003368,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10039,
      "latitude": 40.826736,
      "longitude": -73.939398,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10040,
      "latitude": 40.858692,
      "longitude": -73.930999,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10041,
      "latitude": 40.703801,
      "longitude": -74.009814,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10043,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10044,
      "latitude": 40.761812,
      "longitude": -73.950521,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10045,
      "latitude": 40.70859,
      "longitude": -74.008687,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10046,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10047,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10048,
      "latitude": 40.71254,
      "longitude": -74.013289,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10055,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10060,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10069,
      "latitude": 40.777952,
      "longitude": -73.988381,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10072,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10079,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10080,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10081,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10082,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10087,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10090,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10094,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10095,
      "latitude": 40.748181,
      "longitude": -73.988421,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10096,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10098,
      "latitude": 40.748181,
      "longitude": -73.988421,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10099,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10101,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10102,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10103,
      "latitude": 40.760264,
      "longitude": -73.97624,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10104,
      "latitude": 40.760943,
      "longitude": -73.979908,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10105,
      "latitude": 40.762808,
      "longitude": -73.978534,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10106,
      "latitude": 40.765243,
      "longitude": -73.980438,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10107,
      "latitude": 40.766429,
      "longitude": -73.982728,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10108,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10109,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10110,
      "latitude": 40.753987,
      "longitude": -73.980811,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10111,
      "latitude": 40.759224,
      "longitude": -73.977762,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10112,
      "latitude": 40.759287,
      "longitude": -73.979808,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10113,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10114,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10115,
      "latitude": 40.811115,
      "longitude": -73.96423,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10116,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10117,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10118,
      "latitude": 40.748998,
      "longitude": -73.986467,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10119,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10120,
      "latitude": 40.750629,
      "longitude": -73.989426,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10121,
      "latitude": 40.74964,
      "longitude": -73.991889,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10122,
      "latitude": 40.751757,
      "longitude": -73.992171,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10123,
      "latitude": 40.751489,
      "longitude": -73.990537,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10124,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10125,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10126,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10128,
      "latitude": 40.780989,
      "longitude": -73.95158,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10129,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10130,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10131,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10132,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10133,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10138,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10149,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10150,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10151,
      "latitude": 40.763419,
      "longitude": -73.973971,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10152,
      "latitude": 40.758937,
      "longitude": -73.97302,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10153,
      "latitude": 40.764068,
      "longitude": -73.97348,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10154,
      "latitude": 40.758304,
      "longitude": -73.973468,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10155,
      "latitude": 40.761104,
      "longitude": -73.968036,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10156,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10157,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10158,
      "latitude": 40.749435,
      "longitude": -73.9758,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10159,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10160,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10161,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10162,
      "latitude": 40.769945,
      "longitude": -73.951117,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10163,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10164,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10165,
      "latitude": 40.752351,
      "longitude": -73.979132,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10166,
      "latitude": 40.754591,
      "longitude": -73.976238,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10167,
      "latitude": 40.754858,
      "longitude": -73.97497,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10168,
      "latitude": 40.751933,
      "longitude": -73.976794,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10169,
      "latitude": 40.75474,
      "longitude": -73.976595,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10170,
      "latitude": 40.752621,
      "longitude": -73.97548,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10171,
      "latitude": 40.756447,
      "longitude": -73.974821,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10172,
      "latitude": 40.755839,
      "longitude": -73.975279,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10173,
      "latitude": 40.754305,
      "longitude": -73.979564,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10174,
      "latitude": 40.751656,
      "longitude": -73.97516,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10175,
      "latitude": 40.754305,
      "longitude": -73.979782,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10176,
      "latitude": 40.75557,
      "longitude": -73.978877,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10177,
      "latitude": 40.755332,
      "longitude": -73.976082,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10178,
      "latitude": 40.751378,
      "longitude": -73.978507,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10179,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10184,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10185,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10196,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10197,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10199,
      "latitude": 40.750308,
      "longitude": -74.00058,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10203,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10211,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10212,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10213,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10242,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10249,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10256,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10257,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10258,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10259,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10260,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10261,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10265,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10268,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10269,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10270,
      "latitude": 40.706925,
      "longitude": -74.008154,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10271,
      "latitude": 40.708918,
      "longitude": -74.011066,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10272,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10273,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10274,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10275,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10276,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10277,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10278,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10279,
      "latitude": 40.712697,
      "longitude": -74.007812,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10280,
      "latitude": 40.708857,
      "longitude": -74.016217,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10281,
      "latitude": 40.714643,
      "longitude": -74.014958,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10282,
      "latitude": 40.716597,
      "longitude": -74.01459,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10285,
      "latitude": 40.71533,
      "longitude": -74.016306,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10286,
      "latitude": 40.714231,
      "longitude": -74.011895,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10292,
      "latitude": 40.780751,
      "longitude": -73.977182,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": 10301,
      "latitude": 40.623632,
      "longitude": -74.093323,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10302,
      "latitude": 40.628923,
      "longitude": -74.137947,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10303,
      "latitude": 40.631697,
      "longitude": -74.165016,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10304,
      "latitude": 40.608389,
      "longitude": -74.093684,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10305,
      "latitude": 40.596828,
      "longitude": -74.077837,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10306,
      "latitude": 40.559668,
      "longitude": -74.12241,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10307,
      "latitude": 40.510723,
      "longitude": -74.24194,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10308,
      "latitude": 40.551191,
      "longitude": -74.148893,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10309,
      "latitude": 40.531767,
      "longitude": -74.218779,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10310,
      "latitude": 40.632943,
      "longitude": -74.118669,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10311,
      "latitude": 40.605245,
      "longitude": -74.179485,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10312,
      "latitude": 40.545163,
      "longitude": -74.174692,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10313,
      "latitude": 40.564393,
      "longitude": -74.146836,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10314,
      "latitude": 40.591006,
      "longitude": -74.150741,
      "city": "Staten Island",
      "state": "NY",
      "county": "Richmond"
  },
  {
      "zipCode": 10451,
      "latitude": 40.819329,
      "longitude": -73.920355,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10452,
      "latitude": 40.838729,
      "longitude": -73.921605,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10453,
      "latitude": 40.852728,
      "longitude": -73.912455,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10454,
      "latitude": 40.807129,
      "longitude": -73.918405,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10455,
      "latitude": 40.815029,
      "longitude": -73.908355,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10456,
      "latitude": 40.830529,
      "longitude": -73.908655,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10457,
      "latitude": 40.846129,
      "longitude": -73.898255,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10458,
      "latitude": 40.864728,
      "longitude": -73.889855,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10459,
      "latitude": 40.825629,
      "longitude": -73.893605,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10460,
      "latitude": 40.841829,
      "longitude": -73.879004,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10461,
      "latitude": 40.845279,
      "longitude": -73.843103,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10462,
      "latitude": 40.842729,
      "longitude": -73.854703,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10463,
      "latitude": 40.879528,
      "longitude": -73.904355,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10464,
      "latitude": 40.863028,
      "longitude": -73.799802,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10465,
      "latitude": 40.826879,
      "longitude": -73.825153,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10466,
      "latitude": 40.859878,
      "longitude": -73.841003,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10467,
      "latitude": 40.876328,
      "longitude": -73.870404,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10468,
      "latitude": 40.870978,
      "longitude": -73.900505,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10469,
      "latitude": 40.869978,
      "longitude": -73.844903,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10470,
      "latitude": 40.87143,
      "longitude": -73.862303,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10471,
      "latitude": 40.899178,
      "longitude": -73.898677,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10472,
      "latitude": 40.829529,
      "longitude": -73.865704,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10473,
      "latitude": 40.816229,
      "longitude": -73.860304,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10474,
      "latitude": 40.813929,
      "longitude": -73.884104,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10475,
      "latitude": 40.874878,
      "longitude": -73.827653,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10499,
      "latitude": 40.851549,
      "longitude": -73.840908,
      "city": "Bronx",
      "state": "NY",
      "county": "Bronx"
  },
  {
      "zipCode": 10501,
      "latitude": 41.293169,
      "longitude": -73.759033,
      "city": "Amawalk",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10502,
      "latitude": 41.015096,
      "longitude": -73.841478,
      "city": "Ardsley",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10503,
      "latitude": 41.025947,
      "longitude": -73.87185,
      "city": "Ardsley On Hudson",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10504,
      "latitude": 41.075185,
      "longitude": -73.761247,
      "city": "Armonk",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10505,
      "latitude": 41.33431,
      "longitude": -73.749244,
      "city": "Baldwin Place",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10506,
      "latitude": 41.123741,
      "longitude": -73.718481,
      "city": "Bedford",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10507,
      "latitude": 41.229037,
      "longitude": -73.697998,
      "city": "Bedford Hills",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10509,
      "latitude": 41.415979,
      "longitude": -73.725197,
      "city": "Brewster",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10510,
      "latitude": 41.05978,
      "longitude": -73.829802,
      "city": "Briarcliff Manor",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10511,
      "latitude": 41.259961,
      "longitude": -73.943495,
      "city": "Buchanan",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10512,
      "latitude": 41.429571,
      "longitude": -73.777626,
      "city": "Carmel",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10514,
      "latitude": 41.078957,
      "longitude": -73.775547,
      "city": "Chappaqua",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10516,
      "latitude": 41.455213,
      "longitude": -73.812479,
      "city": "Cold Spring",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10517,
      "latitude": 41.300561,
      "longitude": -73.861217,
      "city": "Crompond",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10518,
      "latitude": 41.269896,
      "longitude": -73.612074,
      "city": "Cross River",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10519,
      "latitude": 41.347727,
      "longitude": -73.661014,
      "city": "Croton Falls",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10520,
      "latitude": 41.122622,
      "longitude": -73.867258,
      "city": "Croton On Hudson",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10521,
      "latitude": 41.234256,
      "longitude": -73.926165,
      "city": "Croton On Hudson",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10522,
      "latitude": 41.011147,
      "longitude": -73.865178,
      "city": "Dobbs Ferry",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10523,
      "latitude": 41.058732,
      "longitude": -73.836784,
      "city": "Elmsford",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10524,
      "latitude": 41.375016,
      "longitude": -73.931844,
      "city": "Garrison",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10526,
      "latitude": 41.300361,
      "longitude": -73.647891,
      "city": "Goldens Bridge",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10527,
      "latitude": 41.323246,
      "longitude": -73.757911,
      "city": "Granite Springs",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10528,
      "latitude": 40.979118,
      "longitude": -73.723036,
      "city": "Harrison",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10530,
      "latitude": 41.019105,
      "longitude": -73.812077,
      "city": "Hartsdale",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10532,
      "latitude": 41.013742,
      "longitude": -73.835421,
      "city": "Hawthorne",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10533,
      "latitude": 41.079553,
      "longitude": -73.856051,
      "city": "Irvington",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10535,
      "latitude": 41.338568,
      "longitude": -73.735548,
      "city": "Jefferson Valley",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10536,
      "latitude": 41.271614,
      "longitude": -73.687334,
      "city": "Katonah",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10537,
      "latitude": 41.394819,
      "longitude": -73.718016,
      "city": "Lake Peekskill",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10538,
      "latitude": 41.090197,
      "longitude": -73.755284,
      "city": "Larchmont",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10540,
      "latitude": 41.333398,
      "longitude": -73.724257,
      "city": "Lincolndale",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10541,
      "latitude": 41.412395,
      "longitude": -73.752059,
      "city": "Mahopac",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10542,
      "latitude": 41.372581,
      "longitude": -73.760132,
      "city": "Mahopac Falls",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10543,
      "latitude": 40.949199,
      "longitude": -73.738779,
      "city": "Mamaroneck",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10545,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Maryknoll",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10546,
      "latitude": 41.201373,
      "longitude": -73.796456,
      "city": "Millwood",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10547,
      "latitude": 41.281975,
      "longitude": -73.797555,
      "city": "Mohegan Lake",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10548,
      "latitude": 41.25126,
      "longitude": -73.942877,
      "city": "Montrose",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10549,
      "latitude": 41.197329,
      "longitude": -73.72286,
      "city": "Mount Kisco",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10550,
      "latitude": 40.909838,
      "longitude": -73.83389,
      "city": "Mount Vernon",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10551,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Mount Vernon",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10552,
      "latitude": 41.011586,
      "longitude": -73.798391,
      "city": "Mount Vernon",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10553,
      "latitude": 40.909449,
      "longitude": -73.822109,
      "city": "Mount Vernon",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10557,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Mount Vernon",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10558,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Mount Vernon",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10559,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Mount Vernon",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10560,
      "latitude": 41.331814,
      "longitude": -73.608451,
      "city": "North Salem",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10562,
      "latitude": 41.109701,
      "longitude": -73.827995,
      "city": "Ossining",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10566,
      "latitude": 41.28383,
      "longitude": -73.893116,
      "city": "Peekskill",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10567,
      "latitude": 41.284044,
      "longitude": -73.893116,
      "city": "Cortlandt Manor",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10570,
      "latitude": 41.131069,
      "longitude": -73.792214,
      "city": "Pleasantville",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10571,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Pleasantville",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10572,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Pleasantville",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10573,
      "latitude": 41.022193,
      "longitude": -73.679799,
      "city": "Port Chester",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10576,
      "latitude": 41.208519,
      "longitude": -73.568126,
      "city": "Pound Ridge",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10577,
      "latitude": 41.002757,
      "longitude": -73.751025,
      "city": "Purchase",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10578,
      "latitude": 41.315779,
      "longitude": -73.645145,
      "city": "Purdys",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10579,
      "latitude": 41.399262,
      "longitude": -73.776871,
      "city": "Putnam Valley",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 10580,
      "latitude": 40.955237,
      "longitude": -73.744725,
      "city": "Rye",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10581,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Rye",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10583,
      "latitude": 40.992686,
      "longitude": -73.799476,
      "city": "Scarsdale",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10587,
      "latitude": 41.328737,
      "longitude": -73.742268,
      "city": "Shenorock",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10588,
      "latitude": 41.330892,
      "longitude": -73.823549,
      "city": "Shrub Oak",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10589,
      "latitude": 41.326544,
      "longitude": -73.693685,
      "city": "Somers",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10590,
      "latitude": 41.254331,
      "longitude": -73.542714,
      "city": "South Salem",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10591,
      "latitude": 41.089703,
      "longitude": -73.844034,
      "city": "Tarrytown",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10592,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Tarrytown",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10594,
      "latitude": 41.117214,
      "longitude": -73.771469,
      "city": "Thornwood",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10595,
      "latitude": 41.090218,
      "longitude": -73.784768,
      "city": "Valhalla",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10596,
      "latitude": 41.254823,
      "longitude": -73.958727,
      "city": "Verplanck",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10597,
      "latitude": 41.297198,
      "longitude": -73.597979,
      "city": "Waccabuc",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10598,
      "latitude": 41.287329,
      "longitude": -73.790284,
      "city": "Yorktown Heights",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10601,
      "latitude": 41.031397,
      "longitude": -73.769626,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10602,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10603,
      "latitude": 41.048958,
      "longitude": -73.783036,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10604,
      "latitude": 41.156215,
      "longitude": -73.805176,
      "city": "West Harrison",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10605,
      "latitude": 41.007597,
      "longitude": -73.748575,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10606,
      "latitude": 41.021686,
      "longitude": -73.766044,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10607,
      "latitude": 41.039235,
      "longitude": -73.807293,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10610,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10625,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10629,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10633,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10650,
      "latitude": 41.013696,
      "longitude": -73.705975,
      "city": "White Plains",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10701,
      "latitude": 40.946107,
      "longitude": -73.866926,
      "city": "Yonkers",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10702,
      "latitude": 41.119008,
      "longitude": -73.732996,
      "city": "Yonkers",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10703,
      "latitude": 40.959347,
      "longitude": -73.881329,
      "city": "Yonkers",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10704,
      "latitude": 40.922938,
      "longitude": -73.861941,
      "city": "Yonkers",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10705,
      "latitude": 40.926538,
      "longitude": -73.872529,
      "city": "Yonkers",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10706,
      "latitude": 40.987797,
      "longitude": -73.862969,
      "city": "Hastings On Hudson",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10707,
      "latitude": 40.960498,
      "longitude": -73.816127,
      "city": "Tuckahoe",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10708,
      "latitude": 40.938448,
      "longitude": -73.829978,
      "city": "Bronxville",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10709,
      "latitude": 40.956098,
      "longitude": -73.812177,
      "city": "Eastchester",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10710,
      "latitude": 40.967946,
      "longitude": -73.84736,
      "city": "Yonkers",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10801,
      "latitude": 41.035123,
      "longitude": -73.801401,
      "city": "New Rochelle",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10802,
      "latitude": 40.948274,
      "longitude": -73.795361,
      "city": "New Rochelle",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10803,
      "latitude": 40.904538,
      "longitude": -73.806277,
      "city": "Pelham",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10804,
      "latitude": 40.951498,
      "longitude": -73.786526,
      "city": "New Rochelle",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10805,
      "latitude": 40.899599,
      "longitude": -73.780976,
      "city": "New Rochelle",
      "state": "NY",
      "county": "Westchester"
  },
  {
      "zipCode": 10901,
      "latitude": 41.137295,
      "longitude": -74.105737,
      "city": "Suffern",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10910,
      "latitude": 41.386195,
      "longitude": -74.125718,
      "city": "Arden",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10911,
      "latitude": 41.160945,
      "longitude": -74.060826,
      "city": "Bear Mountain",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10912,
      "latitude": 41.387822,
      "longitude": -74.354699,
      "city": "Bellvale",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10913,
      "latitude": 41.068782,
      "longitude": -73.957282,
      "city": "Blauvelt",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10914,
      "latitude": 41.464867,
      "longitude": -74.255646,
      "city": "Blooming Grove",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10915,
      "latitude": 41.555634,
      "longitude": -74.328805,
      "city": "Bullville",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10916,
      "latitude": 41.443168,
      "longitude": -74.258172,
      "city": "Campbell Hall",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10917,
      "latitude": 41.329224,
      "longitude": -74.121764,
      "city": "Central Valley",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10918,
      "latitude": 41.414188,
      "longitude": -74.348151,
      "city": "Chester",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10919,
      "latitude": 41.53156,
      "longitude": -74.370569,
      "city": "Circleville",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10920,
      "latitude": 41.152545,
      "longitude": -73.936305,
      "city": "Congers",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10921,
      "latitude": 41.319956,
      "longitude": -74.36699,
      "city": "Florida",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10922,
      "latitude": 41.334557,
      "longitude": -73.99175,
      "city": "Fort Montgomery",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10923,
      "latitude": 41.203984,
      "longitude": -74.003167,
      "city": "Garnerville",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10924,
      "latitude": 41.357428,
      "longitude": -74.210608,
      "city": "Goshen",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10925,
      "latitude": 41.297325,
      "longitude": -74.26484,
      "city": "Greenwood Lake",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10926,
      "latitude": 41.300486,
      "longitude": -74.124934,
      "city": "Harriman",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10927,
      "latitude": 41.191694,
      "longitude": -73.964933,
      "city": "Haverstraw",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10928,
      "latitude": 41.398883,
      "longitude": -74.255846,
      "city": "Highland Falls",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10930,
      "latitude": 41.372924,
      "longitude": -74.245108,
      "city": "Highland Mills",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10931,
      "latitude": 41.120195,
      "longitude": -74.170639,
      "city": "Hillburn",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10932,
      "latitude": 41.485227,
      "longitude": -74.484249,
      "city": "Howells",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10933,
      "latitude": 41.365286,
      "longitude": -74.510918,
      "city": "Johnson",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10940,
      "latitude": 41.390109,
      "longitude": -74.340401,
      "city": "Middletown",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10941,
      "latitude": 41.370586,
      "longitude": -74.354017,
      "city": "Middletown",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10943,
      "latitude": 41.387822,
      "longitude": -74.354699,
      "city": "Middletown",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10950,
      "latitude": 41.343092,
      "longitude": -74.316824,
      "city": "Monroe",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10952,
      "latitude": 41.118595,
      "longitude": -74.084536,
      "city": "Monsey",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10953,
      "latitude": 41.409284,
      "longitude": -74.083045,
      "city": "Mountainville",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10954,
      "latitude": 41.097716,
      "longitude": -74.010934,
      "city": "Nanuet",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10956,
      "latitude": 41.149494,
      "longitude": -73.98827,
      "city": "New City",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10958,
      "latitude": 41.375634,
      "longitude": -74.43375,
      "city": "New Hampton",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10959,
      "latitude": 41.387822,
      "longitude": -74.354699,
      "city": "New Milford",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10960,
      "latitude": 41.080474,
      "longitude": -73.930179,
      "city": "Nyack",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10962,
      "latitude": 41.057496,
      "longitude": -73.953181,
      "city": "Orangeburg",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10963,
      "latitude": 41.401702,
      "longitude": -74.536702,
      "city": "Otisville",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10964,
      "latitude": 41.008947,
      "longitude": -73.92093,
      "city": "Palisades",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10965,
      "latitude": 41.061476,
      "longitude": -74.018283,
      "city": "Pearl River",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10968,
      "latitude": 41.040946,
      "longitude": -73.918205,
      "city": "Piermont",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10969,
      "latitude": 41.325856,
      "longitude": -74.488954,
      "city": "Pine Island",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10970,
      "latitude": 41.18416,
      "longitude": -74.045358,
      "city": "Pomona",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10973,
      "latitude": 41.386099,
      "longitude": -74.480395,
      "city": "Slate Hill",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10974,
      "latitude": 41.157473,
      "longitude": -74.200784,
      "city": "Sloatsburg",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10975,
      "latitude": 41.332379,
      "longitude": -74.113857,
      "city": "Southfields",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10976,
      "latitude": 41.083784,
      "longitude": -74.061335,
      "city": "Sparkill",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10977,
      "latitude": 41.115833,
      "longitude": -74.047384,
      "city": "Spring Valley",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10979,
      "latitude": 41.182344,
      "longitude": -74.318354,
      "city": "Sterling Forest",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10980,
      "latitude": 41.235675,
      "longitude": -74.028095,
      "city": "Stony Point",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10981,
      "latitude": 41.323172,
      "longitude": -74.288565,
      "city": "Sugar Loaf",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10982,
      "latitude": 41.160945,
      "longitude": -74.060826,
      "city": "Tallman",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10983,
      "latitude": 41.027646,
      "longitude": -73.948404,
      "city": "Tappan",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10984,
      "latitude": 41.206898,
      "longitude": -74.015411,
      "city": "Thiells",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10985,
      "latitude": 41.574986,
      "longitude": -74.322534,
      "city": "Thompson Ridge",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10986,
      "latitude": 41.265953,
      "longitude": -73.982912,
      "city": "Tomkins Cove",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10987,
      "latitude": 41.28142,
      "longitude": -74.204924,
      "city": "Tuxedo Park",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10988,
      "latitude": 41.314931,
      "longitude": -74.550346,
      "city": "Unionville",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10989,
      "latitude": 41.122868,
      "longitude": -73.934844,
      "city": "Valley Cottage",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10990,
      "latitude": 41.350681,
      "longitude": -74.32116,
      "city": "Warwick",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10992,
      "latitude": 41.407974,
      "longitude": -74.345433,
      "city": "Washingtonville",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10993,
      "latitude": 41.208044,
      "longitude": -73.975833,
      "city": "West Haverstraw",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10994,
      "latitude": 41.100622,
      "longitude": -73.970482,
      "city": "West Nyack",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10995,
      "latitude": 41.160945,
      "longitude": -74.060826,
      "city": "West Nyack",
      "state": "NY",
      "county": "Rockland"
  },
  {
      "zipCode": 10996,
      "latitude": 41.36471,
      "longitude": -73.998375,
      "city": "West Point",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10997,
      "latitude": 41.387822,
      "longitude": -74.354699,
      "city": "West Point",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 10998,
      "latitude": 41.331044,
      "longitude": -74.456673,
      "city": "Westtown",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 11001,
      "latitude": 40.720051,
      "longitude": -73.706783,
      "city": "Floral Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11002,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Floral Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11003,
      "latitude": 40.697558,
      "longitude": -73.704946,
      "city": "Elmont",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11004,
      "latitude": 40.745263,
      "longitude": -73.711159,
      "city": "Glen Oaks",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11005,
      "latitude": 40.757057,
      "longitude": -73.7182,
      "city": "Floral Park",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11010,
      "latitude": 40.700101,
      "longitude": -73.674074,
      "city": "Franklin Square",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11020,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11021,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11022,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11023,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11024,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11025,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11026,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11027,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Great Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11030,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Manhasset",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11040,
      "latitude": 40.729351,
      "longitude": -73.682824,
      "city": "New Hyde Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11041,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "New Hyde Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11042,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "New Hyde Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11043,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "New Hyde Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11044,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "New Hyde Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11050,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Port Washington",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11051,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Port Washington",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11052,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Port Washington",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11053,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Port Washington",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11054,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Port Washington",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11055,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Port Washington",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11096,
      "latitude": 40.620492,
      "longitude": -73.747376,
      "city": "Inwood",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11099,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "New Hyde Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11101,
      "latitude": 40.744622,
      "longitude": -73.934502,
      "city": "Long Island City",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11102,
      "latitude": 40.770808,
      "longitude": -73.92516,
      "city": "Astoria",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11103,
      "latitude": 40.761884,
      "longitude": -73.911642,
      "city": "Astoria",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11104,
      "latitude": 40.742794,
      "longitude": -73.918216,
      "city": "Sunnyside",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11105,
      "latitude": 40.776468,
      "longitude": -73.908761,
      "city": "Astoria",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11106,
      "latitude": 40.760601,
      "longitude": -73.930984,
      "city": "Astoria",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11109,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Long Island City",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11120,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Long Island City",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11201,
      "latitude": 40.694481,
      "longitude": -73.989319,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11202,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11203,
      "latitude": 40.663551,
      "longitude": -73.949389,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11204,
      "latitude": 40.603838,
      "longitude": -73.987635,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11205,
      "latitude": 40.682507,
      "longitude": -73.936274,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11206,
      "latitude": 40.700453,
      "longitude": -73.949255,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11207,
      "latitude": 40.670925,
      "longitude": -73.895216,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11208,
      "latitude": 40.662195,
      "longitude": -73.912945,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11209,
      "latitude": 40.622459,
      "longitude": -74.028749,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11210,
      "latitude": 40.625468,
      "longitude": -73.945521,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11211,
      "latitude": 40.707789,
      "longitude": -73.945344,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11212,
      "latitude": 40.663845,
      "longitude": -73.916406,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11213,
      "latitude": 40.67317,
      "longitude": -73.936055,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11214,
      "latitude": 40.599523,
      "longitude": -73.998594,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11215,
      "latitude": 40.664322,
      "longitude": -73.984132,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11216,
      "latitude": 40.680474,
      "longitude": -73.946829,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11217,
      "latitude": 40.684948,
      "longitude": -73.980613,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11218,
      "latitude": 40.64434,
      "longitude": -73.978411,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11219,
      "latitude": 40.633157,
      "longitude": -73.996953,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11220,
      "latitude": 40.634873,
      "longitude": -74.016905,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11221,
      "latitude": 40.700418,
      "longitude": -73.936764,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11222,
      "latitude": 40.728388,
      "longitude": -73.945754,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11223,
      "latitude": 40.633801,
      "longitude": -73.954405,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11224,
      "latitude": 40.635444,
      "longitude": -73.968536,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11225,
      "latitude": 40.661096,
      "longitude": -73.952295,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11226,
      "latitude": 40.644962,
      "longitude": -73.956304,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11228,
      "latitude": 40.624552,
      "longitude": -73.989853,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11229,
      "latitude": 40.600988,
      "longitude": -73.939998,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11230,
      "latitude": 40.62251,
      "longitude": -73.966109,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11231,
      "latitude": 40.676521,
      "longitude": -74.003322,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11232,
      "latitude": 40.671186,
      "longitude": -73.971708,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11233,
      "latitude": 40.682088,
      "longitude": -73.920638,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11234,
      "latitude": 40.610947,
      "longitude": -73.913892,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11235,
      "latitude": 40.584455,
      "longitude": -73.948402,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11236,
      "latitude": 40.65701,
      "longitude": -73.91581,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11237,
      "latitude": 40.702192,
      "longitude": -73.91807,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11238,
      "latitude": 40.680593,
      "longitude": -73.963928,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11239,
      "latitude": 40.649207,
      "longitude": -73.876754,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11240,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11241,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11242,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11243,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11244,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11245,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11247,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11248,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11249,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11251,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11252,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11254,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11255,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11256,
      "latitude": 40.645099,
      "longitude": -73.945032,
      "city": "Brooklyn",
      "state": "NY",
      "county": "Kings"
  },
  {
      "zipCode": 11351,
      "latitude": 40.781703,
      "longitude": -73.831698,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11352,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11354,
      "latitude": 40.766382,
      "longitude": -73.82619,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11355,
      "latitude": 40.750317,
      "longitude": -73.813903,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11356,
      "latitude": 40.784369,
      "longitude": -73.843641,
      "city": "College Point",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11357,
      "latitude": 40.786173,
      "longitude": -73.819069,
      "city": "Whitestone",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11358,
      "latitude": 40.760426,
      "longitude": -73.795788,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11359,
      "latitude": 40.79278,
      "longitude": -73.776701,
      "city": "Bayside",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11360,
      "latitude": 40.756745,
      "longitude": -73.803301,
      "city": "Bayside",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11361,
      "latitude": 40.764438,
      "longitude": -73.773026,
      "city": "Bayside",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11362,
      "latitude": 40.758364,
      "longitude": -73.735759,
      "city": "Little Neck",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11363,
      "latitude": 40.771467,
      "longitude": -73.745323,
      "city": "Little Neck",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11364,
      "latitude": 40.742772,
      "longitude": -73.758831,
      "city": "Oakland Gardens",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11365,
      "latitude": 40.738884,
      "longitude": -73.790067,
      "city": "Fresh Meadows",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11366,
      "latitude": 40.727277,
      "longitude": -73.790772,
      "city": "Fresh Meadows",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11367,
      "latitude": 40.730164,
      "longitude": -73.821997,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11368,
      "latitude": 40.748751,
      "longitude": -73.854333,
      "city": "Corona",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11369,
      "latitude": 40.762741,
      "longitude": -73.869969,
      "city": "East Elmhurst",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11370,
      "latitude": 40.763771,
      "longitude": -73.891451,
      "city": "East Elmhurst",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11371,
      "latitude": 40.7712,
      "longitude": -73.867514,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11372,
      "latitude": 40.751146,
      "longitude": -73.882508,
      "city": "Jackson Heights",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11373,
      "latitude": 40.73509,
      "longitude": -73.877562,
      "city": "Elmhurst",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11374,
      "latitude": 40.722861,
      "longitude": -73.862069,
      "city": "Rego Park",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11375,
      "latitude": 40.723041,
      "longitude": -73.843688,
      "city": "Forest Hills",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11377,
      "latitude": 40.748372,
      "longitude": -73.905445,
      "city": "Woodside",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11378,
      "latitude": 40.722848,
      "longitude": -73.908413,
      "city": "Maspeth",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11379,
      "latitude": 40.71924,
      "longitude": -73.892791,
      "city": "Middle Village",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11380,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Elmhurst",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11381,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11385,
      "latitude": 40.702404,
      "longitude": -73.885979,
      "city": "Ridgewood",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11386,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Ridgewood",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11390,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Flushing",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11405,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11411,
      "latitude": 40.685535,
      "longitude": -73.737064,
      "city": "Cambria Heights",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11412,
      "latitude": 40.695829,
      "longitude": -73.761747,
      "city": "Saint Albans",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11413,
      "latitude": 40.664542,
      "longitude": -73.755926,
      "city": "Springfield Gardens",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11414,
      "latitude": 40.658825,
      "longitude": -73.84382,
      "city": "Howard Beach",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11415,
      "latitude": 40.707133,
      "longitude": -73.829247,
      "city": "Kew Gardens",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11416,
      "latitude": 40.684546,
      "longitude": -73.850708,
      "city": "Ozone Park",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11417,
      "latitude": 40.675056,
      "longitude": -73.844315,
      "city": "Ozone Park",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11418,
      "latitude": 40.698566,
      "longitude": -73.83127,
      "city": "Richmond Hill",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11419,
      "latitude": 40.686769,
      "longitude": -73.822979,
      "city": "South Richmond Hill",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11420,
      "latitude": 40.674432,
      "longitude": -73.819013,
      "city": "South Ozone Park",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11421,
      "latitude": 40.691917,
      "longitude": -73.85666,
      "city": "Woodhaven",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11422,
      "latitude": 40.659418,
      "longitude": -73.736866,
      "city": "Rosedale",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11423,
      "latitude": 40.716888,
      "longitude": -73.766897,
      "city": "Hollis",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11424,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11425,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11426,
      "latitude": 40.736798,
      "longitude": -73.723176,
      "city": "Bellerose",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11427,
      "latitude": 40.729293,
      "longitude": -73.749811,
      "city": "Queens Village",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11428,
      "latitude": 40.720639,
      "longitude": -73.742099,
      "city": "Queens Village",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11429,
      "latitude": 40.710076,
      "longitude": -73.740151,
      "city": "Queens Village",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11430,
      "latitude": 40.655246,
      "longitude": -73.797769,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11431,
      "latitude": 40.686903,
      "longitude": -73.850143,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11432,
      "latitude": 40.714531,
      "longitude": -73.794435,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11433,
      "latitude": 40.696533,
      "longitude": -73.786776,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11434,
      "latitude": 40.674583,
      "longitude": -73.78156,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11435,
      "latitude": 40.700229,
      "longitude": -73.797278,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11436,
      "latitude": 40.675283,
      "longitude": -73.800696,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11439,
      "latitude": 40.722008,
      "longitude": -73.790822,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11451,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11484,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11499,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Jamaica",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11501,
      "latitude": 40.735901,
      "longitude": -73.638061,
      "city": "Mineola",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11507,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Albertson",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11509,
      "latitude": 40.588854,
      "longitude": -73.72709,
      "city": "Atlantic Beach",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11510,
      "latitude": 40.654626,
      "longitude": -73.608972,
      "city": "Baldwin",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11514,
      "latitude": 40.74435,
      "longitude": -73.608422,
      "city": "Carle Place",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11516,
      "latitude": 40.626453,
      "longitude": -73.725825,
      "city": "Cedarhurst",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11518,
      "latitude": 40.640387,
      "longitude": -73.668224,
      "city": "East Rockaway",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11520,
      "latitude": 40.651251,
      "longitude": -73.585222,
      "city": "Freeport",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11530,
      "latitude": 40.726801,
      "longitude": -73.639723,
      "city": "Garden City",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11531,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Garden City",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11535,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Garden City",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11536,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Garden City",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11542,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Glen Cove",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11545,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Glen Head",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11547,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Glenwood Landing",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11548,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Greenvale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11549,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hempstead",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11550,
      "latitude": 40.700201,
      "longitude": -73.620872,
      "city": "Hempstead",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11551,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hempstead",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11552,
      "latitude": 40.690251,
      "longitude": -73.653023,
      "city": "West Hempstead",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11553,
      "latitude": 40.705868,
      "longitude": -73.591421,
      "city": "Uniondale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11554,
      "latitude": 40.72135,
      "longitude": -73.55877,
      "city": "East Meadow",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11555,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Uniondale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11556,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Uniondale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11557,
      "latitude": 40.638803,
      "longitude": -73.693274,
      "city": "Hewlett",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11558,
      "latitude": 40.606953,
      "longitude": -73.654724,
      "city": "Island Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11559,
      "latitude": 40.615435,
      "longitude": -73.72642,
      "city": "Lawrence",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11560,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Locust Valley",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11561,
      "latitude": 40.589139,
      "longitude": -73.642323,
      "city": "Long Beach",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11563,
      "latitude": 40.657602,
      "longitude": -73.672574,
      "city": "Lynbrook",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11564,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Lynbrook",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11565,
      "latitude": 40.674652,
      "longitude": -73.673324,
      "city": "Malverne",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11566,
      "latitude": 40.668501,
      "longitude": -73.553628,
      "city": "Merrick",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11568,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Old Westbury",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11569,
      "latitude": 40.590503,
      "longitude": -73.580822,
      "city": "Point Lookout",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11570,
      "latitude": 40.667352,
      "longitude": -73.638523,
      "city": "Rockville Centre",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11571,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Rockville Centre",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11572,
      "latitude": 40.635252,
      "longitude": -73.636923,
      "city": "Oceanside",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11575,
      "latitude": 40.680451,
      "longitude": -73.590071,
      "city": "Roosevelt",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11576,
      "latitude": 40.656802,
      "longitude": -73.622523,
      "city": "Roslyn",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11577,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Roslyn Heights",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11579,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Sea Cliff",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11580,
      "latitude": 40.676052,
      "longitude": -73.697828,
      "city": "Valley Stream",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11581,
      "latitude": 40.652253,
      "longitude": -73.711775,
      "city": "Valley Stream",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11582,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Valley Stream",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11583,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Valley Stream",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11588,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Uniondale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11590,
      "latitude": 40.74505,
      "longitude": -73.571521,
      "city": "Westbury",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11592,
      "latitude": 40.621753,
      "longitude": -73.632673,
      "city": "Rockville Centre",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11593,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Westbury",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11594,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Westbury",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11595,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Westbury",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11596,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Williston Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11597,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Westbury",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11598,
      "latitude": 40.633103,
      "longitude": -73.712025,
      "city": "Woodmere",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11599,
      "latitude": 40.607574,
      "longitude": -73.742691,
      "city": "Garden City",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11690,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Far Rockaway",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11691,
      "latitude": 40.601475,
      "longitude": -73.76191,
      "city": "Far Rockaway",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11692,
      "latitude": 40.5923,
      "longitude": -73.793273,
      "city": "Arverne",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11693,
      "latitude": 40.59846,
      "longitude": -73.81427,
      "city": "Far Rockaway",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11694,
      "latitude": 40.57661,
      "longitude": -73.84276,
      "city": "Rockaway Park",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11695,
      "latitude": 40.651378,
      "longitude": -73.870779,
      "city": "Far Rockaway",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11697,
      "latitude": 40.559365,
      "longitude": -73.906713,
      "city": "Breezy Point",
      "state": "NY",
      "county": "Queens"
  },
  {
      "zipCode": 11701,
      "latitude": 40.686249,
      "longitude": -73.411866,
      "city": "Amityville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11702,
      "latitude": 40.66415,
      "longitude": -73.340984,
      "city": "Babylon",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11703,
      "latitude": 40.732348,
      "longitude": -73.325363,
      "city": "North Babylon",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11704,
      "latitude": 40.709098,
      "longitude": -73.356164,
      "city": "West Babylon",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11705,
      "latitude": 40.748364,
      "longitude": -73.057026,
      "city": "Bayport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11706,
      "latitude": 40.705138,
      "longitude": -73.243004,
      "city": "Bay Shore",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11707,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "West Babylon",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11708,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Amityville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11709,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Bayville",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11710,
      "latitude": 40.6729,
      "longitude": -73.53652,
      "city": "Bellmore",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11713,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Bellport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11714,
      "latitude": 40.728449,
      "longitude": -73.495369,
      "city": "Bethpage",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11715,
      "latitude": 40.75673,
      "longitude": -73.043481,
      "city": "Blue Point",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11716,
      "latitude": 40.76902,
      "longitude": -73.113366,
      "city": "Bohemia",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11717,
      "latitude": 40.780882,
      "longitude": -73.250336,
      "city": "Brentwood",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11718,
      "latitude": 40.715424,
      "longitude": -73.260912,
      "city": "Brightwaters",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11719,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Brookhaven",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11720,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Centereach",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11721,
      "latitude": 40.893197,
      "longitude": -73.370462,
      "city": "Centerport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11722,
      "latitude": 40.78193,
      "longitude": -73.196148,
      "city": "Central Islip",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11724,
      "latitude": 40.860098,
      "longitude": -73.442315,
      "city": "Cold Spring Harbor",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11725,
      "latitude": 40.842247,
      "longitude": -73.280935,
      "city": "Commack",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11726,
      "latitude": 40.68022,
      "longitude": -73.394762,
      "city": "Copiague",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11727,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Coram",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11729,
      "latitude": 40.762574,
      "longitude": -73.323061,
      "city": "Deer Park",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11730,
      "latitude": 40.724146,
      "longitude": -73.175926,
      "city": "East Islip",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11731,
      "latitude": 40.856997,
      "longitude": -73.314561,
      "city": "East Northport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11732,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "East Norwich",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11733,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "East Setauket",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11735,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Farmingdale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11736,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Farmingdale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11737,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Farmingdale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11738,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Farmingville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11739,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Great River",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11740,
      "latitude": 40.866948,
      "longitude": -73.362512,
      "city": "Greenlawn",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11741,
      "latitude": 40.78791,
      "longitude": -73.066689,
      "city": "Holbrook",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11742,
      "latitude": 40.798994,
      "longitude": -73.048985,
      "city": "Holtsville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11743,
      "latitude": 40.867647,
      "longitude": -73.410218,
      "city": "Huntington",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11745,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Smithtown",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11746,
      "latitude": 40.814348,
      "longitude": -73.363363,
      "city": "Huntington Station",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11747,
      "latitude": 40.787248,
      "longitude": -73.405573,
      "city": "Melville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11749,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Islandia",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11750,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Huntington Station",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11751,
      "latitude": 40.72813,
      "longitude": -73.217871,
      "city": "Islip",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11752,
      "latitude": 40.757226,
      "longitude": -73.182854,
      "city": "Islip Terrace",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11753,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Jericho",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11754,
      "latitude": 40.80323,
      "longitude": -73.30485,
      "city": "Kings Park",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11755,
      "latitude": 40.85185,
      "longitude": -73.129003,
      "city": "Lake Grove",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11756,
      "latitude": 40.7228,
      "longitude": -73.515819,
      "city": "Levittown",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11757,
      "latitude": 40.688799,
      "longitude": -73.376475,
      "city": "Lindenhurst",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11758,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Massapequa",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11760,
      "latitude": 40.810246,
      "longitude": -73.191789,
      "city": "Islandia",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11762,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Massapequa Park",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11763,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Medford",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11764,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Miller Place",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11765,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Mill Neck",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11766,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Mount Sinai",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11767,
      "latitude": 40.846678,
      "longitude": -73.146763,
      "city": "Nesconset",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11768,
      "latitude": 40.914289,
      "longitude": -73.33268,
      "city": "Northport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11769,
      "latitude": 40.738322,
      "longitude": -73.131797,
      "city": "Oakdale",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11770,
      "latitude": 40.644344,
      "longitude": -73.161286,
      "city": "Ocean Beach",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11771,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Oyster Bay",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11772,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Patchogue",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11773,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Syosset",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11774,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Farmingdale",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11775,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Melville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11776,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Port Jefferson Station",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11777,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Port Jefferson",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11778,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Rocky Point",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11779,
      "latitude": 40.808322,
      "longitude": -73.130469,
      "city": "Ronkonkoma",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11780,
      "latitude": 40.889314,
      "longitude": -73.174435,
      "city": "Saint James",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11782,
      "latitude": 40.745866,
      "longitude": -73.085858,
      "city": "Sayville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11783,
      "latitude": 40.683897,
      "longitude": -73.496169,
      "city": "Seaford",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11784,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Selden",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11786,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Shoreham",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11787,
      "latitude": 40.856793,
      "longitude": -73.208278,
      "city": "Smithtown",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11788,
      "latitude": 40.819074,
      "longitude": -73.2122,
      "city": "Hauppauge",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11789,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Sound Beach",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11790,
      "latitude": 40.90129,
      "longitude": -73.182803,
      "city": "Stony Brook",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11791,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Syosset",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11792,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Wading River",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11793,
      "latitude": 40.67815,
      "longitude": -73.509519,
      "city": "Wantagh",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11794,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Stony Brook",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11795,
      "latitude": 40.699825,
      "longitude": -73.290905,
      "city": "West Islip",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11796,
      "latitude": 40.732304,
      "longitude": -73.10002,
      "city": "West Sayville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11797,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Woodbury",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11798,
      "latitude": 40.73417,
      "longitude": -73.351354,
      "city": "Wyandanch",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11801,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hicksville",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11802,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hicksville",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11803,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Plainview",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11804,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Old Bethpage",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11805,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Mid Island",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11815,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hicksville",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11819,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hicksville",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11853,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Jericho",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11854,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hicksville",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11855,
      "latitude": 40.754757,
      "longitude": -73.601772,
      "city": "Hicksville",
      "state": "NY",
      "county": "Nassau"
  },
  {
      "zipCode": 11901,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Riverhead",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11930,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Amagansett",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11931,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Aquebogue",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11932,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Bridgehampton",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11933,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Calverton",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11934,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Center Moriches",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11935,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Cutchogue",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11937,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "East Hampton",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11939,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "East Marion",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11940,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "East Moriches",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11941,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Eastport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11942,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "East Quogue",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11944,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Greenport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11946,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Hampton Bays",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11947,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Jamesport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11948,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Laurel",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11949,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Manorville",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11950,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Mastic",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11951,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Mastic Beach",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11952,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Mattituck",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11953,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Middle Island",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11954,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Montauk",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11955,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Moriches",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11956,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "New Suffolk",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11957,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Orient",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11958,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Peconic",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11959,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Quogue",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11960,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Remsenburg",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11961,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Ridge",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11962,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Sagaponack",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11963,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Sag Harbor",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11964,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Shelter Island",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11965,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Shelter Island Heights",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11967,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Shirley",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11968,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Southampton",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11969,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Southampton",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11970,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "South Jamesport",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11971,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Southold",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11972,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Speonk",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11973,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Upton",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11975,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Wainscott",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11976,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Water Mill",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11977,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Westhampton",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11978,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Westhampton Beach",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 11980,
      "latitude": 40.922326,
      "longitude": -72.637078,
      "city": "Yaphank",
      "state": "NY",
      "county": "Suffolk"
  },
  {
      "zipCode": 12007,
      "latitude": 42.482455,
      "longitude": -73.936671,
      "city": "Alcove",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12008,
      "latitude": 42.859176,
      "longitude": -73.902117,
      "city": "Alplaus",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12009,
      "latitude": 42.688731,
      "longitude": -74.030596,
      "city": "Altamont",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12010,
      "latitude": 42.906298,
      "longitude": -74.229042,
      "city": "Amsterdam",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12015,
      "latitude": 42.28004,
      "longitude": -73.837035,
      "city": "Athens",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12016,
      "latitude": 42.910206,
      "longitude": -74.423606,
      "city": "Auriesville",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12017,
      "latitude": 42.309805,
      "longitude": -73.565617,
      "city": "Austerlitz",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12018,
      "latitude": 42.670645,
      "longitude": -73.55142,
      "city": "Averill Park",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12019,
      "latitude": 42.934416,
      "longitude": -73.848971,
      "city": "Ballston Lake",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12020,
      "latitude": 42.983588,
      "longitude": -73.882538,
      "city": "Ballston Spa",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12022,
      "latitude": 42.67051,
      "longitude": -73.357825,
      "city": "Berlin",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12023,
      "latitude": 42.602236,
      "longitude": -74.154615,
      "city": "Berne",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12024,
      "latitude": 42.494981,
      "longitude": -73.510676,
      "city": "Brainard",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12025,
      "latitude": 43.082793,
      "longitude": -74.160753,
      "city": "Broadalbin",
      "state": "NY",
      "county": "Fulton"
  },
  {
      "zipCode": 12027,
      "latitude": 42.923676,
      "longitude": -73.911768,
      "city": "Burnt Hills",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12028,
      "latitude": 42.851332,
      "longitude": -73.454055,
      "city": "Buskirk",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12029,
      "latitude": 42.357057,
      "longitude": -73.448944,
      "city": "Canaan",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12031,
      "latitude": 42.592287,
      "longitude": -74.438129,
      "city": "Carlisle",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12032,
      "latitude": 43.15566,
      "longitude": -74.494888,
      "city": "Caroga Lake",
      "state": "NY",
      "county": "Fulton"
  },
  {
      "zipCode": 12033,
      "latitude": 42.589158,
      "longitude": -73.565925,
      "city": "Castleton On Hudson",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12035,
      "latitude": 42.721081,
      "longitude": -74.382064,
      "city": "Central Bridge",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12036,
      "latitude": 42.541025,
      "longitude": -74.655395,
      "city": "Charlotteville",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12037,
      "latitude": 42.335777,
      "longitude": -73.528651,
      "city": "Chatham",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12040,
      "latitude": 42.646742,
      "longitude": -73.371611,
      "city": "Cherry Plain",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12041,
      "latitude": 42.561988,
      "longitude": -73.957345,
      "city": "Clarksville",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12042,
      "latitude": 42.411207,
      "longitude": -73.927573,
      "city": "Climax",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12043,
      "latitude": 42.665376,
      "longitude": -74.51884,
      "city": "Cobleskill",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12045,
      "latitude": 42.475704,
      "longitude": -73.797722,
      "city": "Coeymans",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12046,
      "latitude": 42.492791,
      "longitude": -73.928403,
      "city": "Coeymans Hollow",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12047,
      "latitude": 42.647039,
      "longitude": -73.739349,
      "city": "Cohoes",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12050,
      "latitude": 42.317154,
      "longitude": -73.748555,
      "city": "Columbiaville",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12051,
      "latitude": 42.355924,
      "longitude": -73.847967,
      "city": "Coxsackie",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12052,
      "latitude": 42.670318,
      "longitude": -73.549377,
      "city": "Cropseyville",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12053,
      "latitude": 42.77788,
      "longitude": -74.197936,
      "city": "Delanson",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12054,
      "latitude": 42.605467,
      "longitude": -73.8757,
      "city": "Delmar",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12055,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Dormansville",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12056,
      "latitude": 42.770065,
      "longitude": -74.085963,
      "city": "Duanesburg",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12057,
      "latitude": 42.980783,
      "longitude": -73.352206,
      "city": "Eagle Bridge",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12058,
      "latitude": 42.352969,
      "longitude": -73.905306,
      "city": "Earlton",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12059,
      "latitude": 42.618248,
      "longitude": -74.028108,
      "city": "East Berne",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12060,
      "latitude": 42.419964,
      "longitude": -73.512815,
      "city": "East Chatham",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12061,
      "latitude": 42.570352,
      "longitude": -73.655682,
      "city": "East Greenbush",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12062,
      "latitude": 42.537218,
      "longitude": -73.503877,
      "city": "East Nassau",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12063,
      "latitude": 42.563685,
      "longitude": -73.627428,
      "city": "East Schodack",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12064,
      "latitude": 42.632058,
      "longitude": -74.667368,
      "city": "East Worcester",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 12065,
      "latitude": 42.850576,
      "longitude": -73.792524,
      "city": "Clifton Park",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12066,
      "latitude": 42.801547,
      "longitude": -74.342884,
      "city": "Esperance",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12067,
      "latitude": 42.555446,
      "longitude": -73.913714,
      "city": "Feura Bush",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12068,
      "latitude": 42.940975,
      "longitude": -74.391459,
      "city": "Fonda",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12069,
      "latitude": 42.945701,
      "longitude": -74.263312,
      "city": "Fort Hunter",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12070,
      "latitude": 42.969738,
      "longitude": -74.292476,
      "city": "Fort Johnson",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12071,
      "latitude": 42.592287,
      "longitude": -74.438129,
      "city": "Fultonham",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12072,
      "latitude": 42.877533,
      "longitude": -74.404477,
      "city": "Fultonville",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12073,
      "latitude": 42.592287,
      "longitude": -74.438129,
      "city": "Gallupville",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12074,
      "latitude": 43.082925,
      "longitude": -74.043981,
      "city": "Galway",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12075,
      "latitude": 42.366064,
      "longitude": -73.589731,
      "city": "Ghent",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12076,
      "latitude": 42.411096,
      "longitude": -74.427506,
      "city": "Gilboa",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12077,
      "latitude": 42.595704,
      "longitude": -73.787766,
      "city": "Glenmont",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12078,
      "latitude": 43.114958,
      "longitude": -74.344328,
      "city": "Gloversville",
      "state": "NY",
      "county": "Fulton"
  },
  {
      "zipCode": 12082,
      "latitude": 42.772624,
      "longitude": -73.446834,
      "city": "Grafton",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12083,
      "latitude": 42.382594,
      "longitude": -73.951259,
      "city": "Greenville",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12084,
      "latitude": 42.703519,
      "longitude": -73.960324,
      "city": "Guilderland",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12085,
      "latitude": 42.694777,
      "longitude": -73.905271,
      "city": "Guilderland Center",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12086,
      "latitude": 42.969505,
      "longitude": -74.155559,
      "city": "Hagaman",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12087,
      "latitude": 42.411114,
      "longitude": -73.881515,
      "city": "Hannacroix",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12089,
      "latitude": 42.866666,
      "longitude": -73.318113,
      "city": "Hoosick",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12090,
      "latitude": 42.73531,
      "longitude": -73.437943,
      "city": "Hoosick Falls",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12092,
      "latitude": 42.712176,
      "longitude": -74.349066,
      "city": "Howes Cave",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12093,
      "latitude": 42.458932,
      "longitude": -74.580562,
      "city": "Jefferson",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12094,
      "latitude": 42.879946,
      "longitude": -73.48684,
      "city": "Johnsonville",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12095,
      "latitude": 43.090543,
      "longitude": -74.355438,
      "city": "Johnstown",
      "state": "NY",
      "county": "Fulton"
  },
  {
      "zipCode": 12106,
      "latitude": 42.385231,
      "longitude": -73.704442,
      "city": "Kinderhook",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12107,
      "latitude": 42.660046,
      "longitude": -74.116812,
      "city": "Knox",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12108,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Lake Pleasant",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12110,
      "latitude": 42.752928,
      "longitude": -73.779714,
      "city": "Latham",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12111,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Latham",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12115,
      "latitude": 42.473718,
      "longitude": -73.56733,
      "city": "Malden Bridge",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12116,
      "latitude": 42.551473,
      "longitude": -74.913829,
      "city": "Maryland",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 12117,
      "latitude": 43.132167,
      "longitude": -74.248494,
      "city": "Mayfield",
      "state": "NY",
      "county": "Fulton"
  },
  {
      "zipCode": 12118,
      "latitude": 43.033189,
      "longitude": -73.897322,
      "city": "Mechanicville",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12120,
      "latitude": 42.478906,
      "longitude": -74.147178,
      "city": "Medusa",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12121,
      "latitude": 42.712948,
      "longitude": -73.601722,
      "city": "Melrose",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12122,
      "latitude": 42.560533,
      "longitude": -74.295505,
      "city": "Middleburgh",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12123,
      "latitude": 42.565736,
      "longitude": -73.629023,
      "city": "Nassau",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12124,
      "latitude": 42.444198,
      "longitude": -73.788241,
      "city": "New Baltimore",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12125,
      "latitude": 42.466748,
      "longitude": -73.567189,
      "city": "New Lebanon",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12128,
      "latitude": 42.724978,
      "longitude": -73.764296,
      "city": "Newtonville",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12130,
      "latitude": 42.439983,
      "longitude": -73.666343,
      "city": "Niverville",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12131,
      "latitude": 42.592287,
      "longitude": -74.438129,
      "city": "North Blenheim",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12132,
      "latitude": 42.466061,
      "longitude": -73.629296,
      "city": "North Chatham",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12133,
      "latitude": 42.926121,
      "longitude": -73.346409,
      "city": "North Hoosick",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12134,
      "latitude": 43.176121,
      "longitude": -74.248627,
      "city": "Northville",
      "state": "NY",
      "county": "Fulton"
  },
  {
      "zipCode": 12136,
      "latitude": 42.434992,
      "longitude": -73.561172,
      "city": "Old Chatham",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12137,
      "latitude": 42.838578,
      "longitude": -74.131734,
      "city": "Pattersonville",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12138,
      "latitude": 42.685947,
      "longitude": -73.390627,
      "city": "Petersburg",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12139,
      "latitude": 43.428386,
      "longitude": -74.526485,
      "city": "Piseco",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12140,
      "latitude": 42.686757,
      "longitude": -73.527434,
      "city": "Poestenkill",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12141,
      "latitude": 42.731771,
      "longitude": -74.185395,
      "city": "Quaker Street",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12143,
      "latitude": 42.493642,
      "longitude": -73.858352,
      "city": "Ravena",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12144,
      "latitude": 42.621627,
      "longitude": -73.596089,
      "city": "Rensselaer",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12147,
      "latitude": 42.500258,
      "longitude": -74.160591,
      "city": "Rensselaerville",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12148,
      "latitude": 42.841619,
      "longitude": -73.847016,
      "city": "Rexford",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12149,
      "latitude": 42.609418,
      "longitude": -74.594655,
      "city": "Richmondville",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12150,
      "latitude": 42.869961,
      "longitude": -74.050083,
      "city": "Rotterdam Junction",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12151,
      "latitude": 42.924726,
      "longitude": -73.785914,
      "city": "Round Lake",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12153,
      "latitude": 42.635503,
      "longitude": -73.479705,
      "city": "Sand Lake",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12154,
      "latitude": 42.904445,
      "longitude": -73.615618,
      "city": "Schaghticoke",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12155,
      "latitude": 42.605453,
      "longitude": -74.820584,
      "city": "Schenevus",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 12156,
      "latitude": 42.491469,
      "longitude": -73.723935,
      "city": "Schodack Landing",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12157,
      "latitude": 42.655569,
      "longitude": -74.337322,
      "city": "Schoharie",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12158,
      "latitude": 42.529746,
      "longitude": -73.840579,
      "city": "Selkirk",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12159,
      "latitude": 42.644278,
      "longitude": -73.876338,
      "city": "Slingerlands",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12160,
      "latitude": 42.756876,
      "longitude": -74.317361,
      "city": "Sloansville",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12161,
      "latitude": 42.521038,
      "longitude": -73.851898,
      "city": "South Bethlehem",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12164,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Speculator",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12165,
      "latitude": 42.331057,
      "longitude": -73.529774,
      "city": "Spencertown",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12166,
      "latitude": 42.842006,
      "longitude": -74.446,
      "city": "Sprakers",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12167,
      "latitude": 42.413508,
      "longitude": -74.61939,
      "city": "Stamford",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12168,
      "latitude": 42.63593,
      "longitude": -73.408789,
      "city": "Stephentown",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12169,
      "latitude": 42.585511,
      "longitude": -73.415444,
      "city": "Stephentown",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12170,
      "latitude": 43.001932,
      "longitude": -73.660897,
      "city": "Stillwater",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12172,
      "latitude": 42.285749,
      "longitude": -73.733532,
      "city": "Stottville",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12173,
      "latitude": 42.399271,
      "longitude": -73.622327,
      "city": "Stuyvesant",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12174,
      "latitude": 42.343986,
      "longitude": -73.73477,
      "city": "Stuyvesant Falls",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12175,
      "latitude": 42.535029,
      "longitude": -74.54525,
      "city": "Summit",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12176,
      "latitude": 42.385117,
      "longitude": -73.958671,
      "city": "Surprise",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12177,
      "latitude": 42.95002,
      "longitude": -74.296985,
      "city": "Tribes Hill",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 12179,
      "latitude": 42.71144,
      "longitude": -73.525561,
      "city": "Troy",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12180,
      "latitude": 42.673701,
      "longitude": -73.608792,
      "city": "Troy",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12181,
      "latitude": 42.738678,
      "longitude": -73.673862,
      "city": "Troy",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12182,
      "latitude": 42.685792,
      "longitude": -73.652374,
      "city": "Troy",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12183,
      "latitude": 42.745994,
      "longitude": -73.694263,
      "city": "Troy",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12184,
      "latitude": 42.398503,
      "longitude": -73.626548,
      "city": "Valatie",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12185,
      "latitude": 42.856307,
      "longitude": -73.500679,
      "city": "Valley Falls",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12186,
      "latitude": 42.620654,
      "longitude": -73.980441,
      "city": "Voorheesville",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12187,
      "latitude": 42.592287,
      "longitude": -74.438129,
      "city": "Warnerville",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12188,
      "latitude": 42.845579,
      "longitude": -73.702433,
      "city": "Waterford",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12189,
      "latitude": 42.736277,
      "longitude": -73.720318,
      "city": "Watervliet",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12190,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Wells",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12192,
      "latitude": 42.397703,
      "longitude": -73.834836,
      "city": "West Coxsackie",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12193,
      "latitude": 42.521722,
      "longitude": -74.03961,
      "city": "Westerlo",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12194,
      "latitude": 42.572934,
      "longitude": -74.514163,
      "city": "West Fulton",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 12195,
      "latitude": 42.479384,
      "longitude": -73.474809,
      "city": "West Lebanon",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12196,
      "latitude": 42.603715,
      "longitude": -73.561566,
      "city": "West Sand Lake",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12197,
      "latitude": 42.606993,
      "longitude": -74.732401,
      "city": "Worcester",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 12198,
      "latitude": 42.676167,
      "longitude": -73.606194,
      "city": "Wynantskill",
      "state": "NY",
      "county": "Rensselaer"
  },
  {
      "zipCode": 12201,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12202,
      "latitude": 42.636371,
      "longitude": -73.763564,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12203,
      "latitude": 42.700345,
      "longitude": -73.857496,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12204,
      "latitude": 42.682978,
      "longitude": -73.770714,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12205,
      "latitude": 42.719799,
      "longitude": -73.820666,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12206,
      "latitude": 42.675779,
      "longitude": -73.793065,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12207,
      "latitude": 42.656029,
      "longitude": -73.750764,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12208,
      "latitude": 42.654658,
      "longitude": -73.805866,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12209,
      "latitude": 42.677953,
      "longitude": -73.91019,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12210,
      "latitude": 42.683079,
      "longitude": -73.758115,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12211,
      "latitude": 42.712973,
      "longitude": -73.773868,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12212,
      "latitude": 42.716774,
      "longitude": -73.810373,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12214,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12220,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12222,
      "latitude": 42.692909,
      "longitude": -73.838035,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12223,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12224,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12225,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12226,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12227,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12228,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12229,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12230,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12231,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12232,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12233,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12234,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12235,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12236,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12237,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12238,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12239,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12240,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12241,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12242,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12243,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12244,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12245,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12246,
      "latitude": 42.647079,
      "longitude": -73.750314,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12247,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12248,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12249,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12250,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12252,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12255,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12256,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12257,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12260,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12261,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12262,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12288,
      "latitude": 42.614852,
      "longitude": -73.970812,
      "city": "Albany",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12301,
      "latitude": 42.833261,
      "longitude": -74.058015,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12302,
      "latitude": 42.880035,
      "longitude": -73.991321,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12303,
      "latitude": 42.782276,
      "longitude": -73.944818,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12304,
      "latitude": 42.788176,
      "longitude": -73.899917,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12305,
      "latitude": 42.812776,
      "longitude": -73.943368,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12306,
      "latitude": 42.816438,
      "longitude": -74.052713,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12307,
      "latitude": 42.805376,
      "longitude": -73.934868,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12308,
      "latitude": 42.835926,
      "longitude": -73.920467,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12309,
      "latitude": 42.809077,
      "longitude": -73.869316,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12325,
      "latitude": 42.833261,
      "longitude": -74.058015,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12345,
      "latitude": 42.833261,
      "longitude": -74.058015,
      "city": "Schenectady",
      "state": "NY",
      "county": "Schenectady"
  },
  {
      "zipCode": 12401,
      "latitude": 41.96973,
      "longitude": -74.06678,
      "city": "Kingston",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12402,
      "latitude": 41.878801,
      "longitude": -74.345684,
      "city": "Kingston",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12404,
      "latitude": 41.949147,
      "longitude": -74.130688,
      "city": "Accord",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12405,
      "latitude": 42.315975,
      "longitude": -74.065515,
      "city": "Acra",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12406,
      "latitude": 42.141725,
      "longitude": -74.572054,
      "city": "Arkville",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12407,
      "latitude": 42.332859,
      "longitude": -74.367866,
      "city": "Ashland",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12409,
      "latitude": 42.054666,
      "longitude": -74.192451,
      "city": "Bearsville",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12410,
      "latitude": 42.073965,
      "longitude": -74.453033,
      "city": "Big Indian",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12411,
      "latitude": 41.875232,
      "longitude": -74.043557,
      "city": "Bloomington",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12412,
      "latitude": 41.998413,
      "longitude": -74.265437,
      "city": "Boiceville",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12413,
      "latitude": 42.301789,
      "longitude": -74.029434,
      "city": "Cairo",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12414,
      "latitude": 42.258459,
      "longitude": -74.021968,
      "city": "Catskill",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12416,
      "latitude": 42.095023,
      "longitude": -74.271661,
      "city": "Chichester",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12417,
      "latitude": 41.907604,
      "longitude": -73.989254,
      "city": "Connelly",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12418,
      "latitude": 42.368277,
      "longitude": -74.179384,
      "city": "Cornwallville",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12419,
      "latitude": 41.856903,
      "longitude": -74.111235,
      "city": "Cottekill",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12420,
      "latitude": 41.669831,
      "longitude": -74.380138,
      "city": "Cragsmoor",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12421,
      "latitude": 42.235727,
      "longitude": -74.56969,
      "city": "Denver",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12422,
      "latitude": 42.307188,
      "longitude": -74.200237,
      "city": "Durham",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12423,
      "latitude": 42.377483,
      "longitude": -74.123477,
      "city": "East Durham",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12424,
      "latitude": 42.28041,
      "longitude": -74.15549,
      "city": "East Jewett",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12427,
      "latitude": 42.169082,
      "longitude": -74.12958,
      "city": "Elka Park",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12428,
      "latitude": 41.897181,
      "longitude": -74.253996,
      "city": "Ellenville",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12429,
      "latitude": 41.816228,
      "longitude": -73.992565,
      "city": "Esopus",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12430,
      "latitude": 42.177175,
      "longitude": -74.547279,
      "city": "Fleischmanns",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12431,
      "latitude": 42.336112,
      "longitude": -74.024147,
      "city": "Freehold",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12432,
      "latitude": 41.949795,
      "longitude": -74.003246,
      "city": "Glasco",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12433,
      "latitude": 42.008413,
      "longitude": -74.147995,
      "city": "Glenford",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12434,
      "latitude": 42.320684,
      "longitude": -74.459223,
      "city": "Grand Gorge",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12435,
      "latitude": 41.727613,
      "longitude": -74.515647,
      "city": "Greenfield Park",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12436,
      "latitude": 42.19551,
      "longitude": -74.102319,
      "city": "Haines Falls",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12438,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Halcottsville",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12439,
      "latitude": 42.293905,
      "longitude": -74.185279,
      "city": "Hensonville",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12440,
      "latitude": 41.872233,
      "longitude": -74.146678,
      "city": "High Falls",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12441,
      "latitude": 41.878801,
      "longitude": -74.345684,
      "city": "Highmount",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12442,
      "latitude": 42.233275,
      "longitude": -74.241582,
      "city": "Hunter",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12443,
      "latitude": 41.853723,
      "longitude": -74.115476,
      "city": "Hurley",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12444,
      "latitude": 42.264524,
      "longitude": -74.295888,
      "city": "Jewett",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12446,
      "latitude": 41.884748,
      "longitude": -74.232063,
      "city": "Kerhonkson",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12448,
      "latitude": 42.083209,
      "longitude": -74.191963,
      "city": "Lake Hill",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12449,
      "latitude": 41.883711,
      "longitude": -74.182479,
      "city": "Lake Katrine",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12450,
      "latitude": 42.208809,
      "longitude": -74.163793,
      "city": "Lanesville",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12451,
      "latitude": 42.304064,
      "longitude": -73.930812,
      "city": "Leeds",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12452,
      "latitude": 42.223407,
      "longitude": -74.386635,
      "city": "Lexington",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12453,
      "latitude": 42.098698,
      "longitude": -73.935418,
      "city": "Malden On Hudson",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12454,
      "latitude": 42.282466,
      "longitude": -74.160193,
      "city": "Maplecrest",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12455,
      "latitude": 42.185189,
      "longitude": -74.617819,
      "city": "Margaretville",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12456,
      "latitude": 42.037136,
      "longitude": -73.992829,
      "city": "Mount Marion",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12457,
      "latitude": 41.8743,
      "longitude": -74.168979,
      "city": "Mount Tremper",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12458,
      "latitude": 41.80137,
      "longitude": -74.420108,
      "city": "Napanoch",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12459,
      "latitude": 42.20943,
      "longitude": -74.683726,
      "city": "New Kingston",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12460,
      "latitude": 42.409721,
      "longitude": -74.192105,
      "city": "Oak Hill",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12461,
      "latitude": 41.875001,
      "longitude": -74.273362,
      "city": "Olivebridge",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12463,
      "latitude": 42.197886,
      "longitude": -74.00995,
      "city": "Palenville",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12464,
      "latitude": 42.019668,
      "longitude": -74.228732,
      "city": "Phoenicia",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12465,
      "latitude": 42.136293,
      "longitude": -74.473639,
      "city": "Pine Hill",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12466,
      "latitude": 41.894812,
      "longitude": -73.976653,
      "city": "Port Ewen",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12468,
      "latitude": 42.286917,
      "longitude": -74.385762,
      "city": "Prattsville",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12469,
      "latitude": 42.464687,
      "longitude": -74.213992,
      "city": "Preston Hollow",
      "state": "NY",
      "county": "Albany"
  },
  {
      "zipCode": 12470,
      "latitude": 42.282915,
      "longitude": -74.018409,
      "city": "Purling",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12471,
      "latitude": 41.840304,
      "longitude": -74.030611,
      "city": "Rifton",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12472,
      "latitude": 41.873367,
      "longitude": -74.074621,
      "city": "Rosendale",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12473,
      "latitude": 42.276462,
      "longitude": -74.044432,
      "city": "Round Top",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12474,
      "latitude": 42.295703,
      "longitude": -74.563088,
      "city": "Roxbury",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 12475,
      "latitude": 42.017632,
      "longitude": -74.007868,
      "city": "Ruby",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12477,
      "latitude": 41.996982,
      "longitude": -74.202816,
      "city": "Saugerties",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12480,
      "latitude": 41.911181,
      "longitude": -74.407894,
      "city": "Shandaken",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12481,
      "latitude": 41.970903,
      "longitude": -74.218443,
      "city": "Shokan",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12482,
      "latitude": 42.267617,
      "longitude": -73.986354,
      "city": "South Cairo",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12483,
      "latitude": 41.663895,
      "longitude": -74.424533,
      "city": "Spring Glen",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12484,
      "latitude": 41.846274,
      "longitude": -74.20731,
      "city": "Stone Ridge",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12485,
      "latitude": 42.195922,
      "longitude": -74.137242,
      "city": "Tannersville",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12486,
      "latitude": 41.837953,
      "longitude": -74.232241,
      "city": "Tillson",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12487,
      "latitude": 41.857076,
      "longitude": -74.017907,
      "city": "Ulster Park",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12489,
      "latitude": 41.760604,
      "longitude": -74.353531,
      "city": "Wawarsing",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12490,
      "latitude": 42.108689,
      "longitude": -73.934577,
      "city": "West Camp",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12491,
      "latitude": 41.983752,
      "longitude": -74.124371,
      "city": "West Hurley",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12492,
      "latitude": 42.206787,
      "longitude": -74.375508,
      "city": "West Kill",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12493,
      "latitude": 41.795276,
      "longitude": -73.980908,
      "city": "West Park",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12494,
      "latitude": 41.972572,
      "longitude": -74.267623,
      "city": "West Shokan",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12495,
      "latitude": 42.046885,
      "longitude": -74.203575,
      "city": "Willow",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12496,
      "latitude": 42.320273,
      "longitude": -74.231589,
      "city": "Windham",
      "state": "NY",
      "county": "Greene"
  },
  {
      "zipCode": 12498,
      "latitude": 41.883076,
      "longitude": -74.169764,
      "city": "Woodstock",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12501,
      "latitude": 41.826491,
      "longitude": -73.710901,
      "city": "Amenia",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12502,
      "latitude": 42.067399,
      "longitude": -73.657698,
      "city": "Ancram",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12503,
      "latitude": 42.043039,
      "longitude": -73.587575,
      "city": "Ancramdale",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12504,
      "latitude": 42.035434,
      "longitude": -73.909234,
      "city": "Annandale On Hudson",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12506,
      "latitude": 41.759905,
      "longitude": -73.743714,
      "city": "Bangall",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12507,
      "latitude": 42.000547,
      "longitude": -73.919899,
      "city": "Barrytown",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12508,
      "latitude": 41.712677,
      "longitude": -73.773082,
      "city": "Beacon",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12510,
      "latitude": 41.759905,
      "longitude": -73.743714,
      "city": "Billings",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12511,
      "latitude": 41.759905,
      "longitude": -73.743714,
      "city": "Castle Point",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12512,
      "latitude": 41.552793,
      "longitude": -73.968183,
      "city": "Chelsea",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12513,
      "latitude": 42.206862,
      "longitude": -73.694642,
      "city": "Claverack",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12514,
      "latitude": 41.867364,
      "longitude": -73.777256,
      "city": "Clinton Corners",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12515,
      "latitude": 41.682335,
      "longitude": -74.05799,
      "city": "Clintondale",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12516,
      "latitude": 42.099322,
      "longitude": -73.576102,
      "city": "Copake",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12517,
      "latitude": 42.130934,
      "longitude": -73.548337,
      "city": "Copake Falls",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12518,
      "latitude": 41.348593,
      "longitude": -74.135167,
      "city": "Cornwall",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12520,
      "latitude": 41.431474,
      "longitude": -74.132883,
      "city": "Cornwall On Hudson",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12521,
      "latitude": 42.190488,
      "longitude": -73.64465,
      "city": "Craryville",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12522,
      "latitude": 41.800316,
      "longitude": -73.687324,
      "city": "Dover Plains",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12523,
      "latitude": 42.119528,
      "longitude": -73.697297,
      "city": "Elizaville",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12524,
      "latitude": 41.61898,
      "longitude": -73.847308,
      "city": "Fishkill",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12525,
      "latitude": 41.677151,
      "longitude": -74.17387,
      "city": "Gardiner",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12526,
      "latitude": 42.121298,
      "longitude": -73.792924,
      "city": "Germantown",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12527,
      "latitude": 41.520249,
      "longitude": -73.933259,
      "city": "Glenham",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12528,
      "latitude": 41.720945,
      "longitude": -74.009481,
      "city": "Highland",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12529,
      "latitude": 42.19327,
      "longitude": -73.550028,
      "city": "Hillsdale",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12530,
      "latitude": 42.210991,
      "longitude": -73.687001,
      "city": "Hollowville",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12531,
      "latitude": 41.549045,
      "longitude": -73.670325,
      "city": "Holmes",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12533,
      "latitude": 41.748948,
      "longitude": -73.766191,
      "city": "Hopewell Junction",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12534,
      "latitude": 42.184313,
      "longitude": -73.742997,
      "city": "Hudson",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12537,
      "latitude": 41.582437,
      "longitude": -73.936281,
      "city": "Hughsonville",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12538,
      "latitude": 41.813468,
      "longitude": -73.814913,
      "city": "Hyde Park",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12540,
      "latitude": 41.633029,
      "longitude": -73.743183,
      "city": "Lagrangeville",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12541,
      "latitude": 42.142068,
      "longitude": -73.757399,
      "city": "Livingston",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12542,
      "latitude": 41.612647,
      "longitude": -74.002802,
      "city": "Marlboro",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12543,
      "latitude": 41.357625,
      "longitude": -74.230587,
      "city": "Maybrook",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12544,
      "latitude": 42.259623,
      "longitude": -73.667962,
      "city": "Mellenville",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12545,
      "latitude": 41.832012,
      "longitude": -73.637175,
      "city": "Millbrook",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12546,
      "latitude": 41.93846,
      "longitude": -73.546336,
      "city": "Millerton",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12547,
      "latitude": 41.644832,
      "longitude": -74.068056,
      "city": "Milton",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12548,
      "latitude": 41.672278,
      "longitude": -74.103276,
      "city": "Modena",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12549,
      "latitude": 41.405901,
      "longitude": -74.255717,
      "city": "Montgomery",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12550,
      "latitude": 41.38537,
      "longitude": -74.185363,
      "city": "Newburgh",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12551,
      "latitude": 41.387822,
      "longitude": -74.354699,
      "city": "Newburgh",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12552,
      "latitude": 41.387822,
      "longitude": -74.354699,
      "city": "Newburgh",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12553,
      "latitude": 41.413667,
      "longitude": -74.311974,
      "city": "New Windsor",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12555,
      "latitude": 41.387822,
      "longitude": -74.354699,
      "city": "Mid Hudson",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12561,
      "latitude": 41.74638,
      "longitude": -74.109241,
      "city": "New Paltz",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12563,
      "latitude": 41.485285,
      "longitude": -73.595066,
      "city": "Patterson",
      "state": "NY",
      "county": "Putnam"
  },
  {
      "zipCode": 12564,
      "latitude": 41.663759,
      "longitude": -73.590491,
      "city": "Pawling",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12565,
      "latitude": 42.250141,
      "longitude": -73.659054,
      "city": "Philmont",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12566,
      "latitude": 41.405485,
      "longitude": -74.219343,
      "city": "Pine Bush",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12567,
      "latitude": 41.952681,
      "longitude": -73.63633,
      "city": "Pine Plains",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12568,
      "latitude": 41.641207,
      "longitude": -74.07805,
      "city": "Plattekill",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12569,
      "latitude": 41.825708,
      "longitude": -73.783655,
      "city": "Pleasant Valley",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12570,
      "latitude": 41.646389,
      "longitude": -73.650107,
      "city": "Poughquag",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12571,
      "latitude": 41.808427,
      "longitude": -73.786847,
      "city": "Red Hook",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12572,
      "latitude": 41.836548,
      "longitude": -73.760423,
      "city": "Rhinebeck",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12574,
      "latitude": 41.915068,
      "longitude": -73.95173,
      "city": "Rhinecliff",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12575,
      "latitude": 41.50755,
      "longitude": -74.156394,
      "city": "Rock Tavern",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12577,
      "latitude": 41.437176,
      "longitude": -74.121804,
      "city": "Salisbury Mills",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12578,
      "latitude": 41.81246,
      "longitude": -73.786647,
      "city": "Salt Point",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12580,
      "latitude": 41.881636,
      "longitude": -73.72455,
      "city": "Staatsburg",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12581,
      "latitude": 41.913511,
      "longitude": -73.695442,
      "city": "Stanfordville",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12582,
      "latitude": 41.554509,
      "longitude": -73.72667,
      "city": "Stormville",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12583,
      "latitude": 41.909683,
      "longitude": -73.749214,
      "city": "Tivoli",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12584,
      "latitude": 41.464073,
      "longitude": -74.059104,
      "city": "Vails Gate",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12585,
      "latitude": 41.716145,
      "longitude": -73.690109,
      "city": "Verbank",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12586,
      "latitude": 41.406785,
      "longitude": -74.26731,
      "city": "Walden",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12588,
      "latitude": 41.878801,
      "longitude": -74.345684,
      "city": "Walker Valley",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12589,
      "latitude": 41.631653,
      "longitude": -74.154472,
      "city": "Wallkill",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12590,
      "latitude": 41.736948,
      "longitude": -73.741141,
      "city": "Wappingers Falls",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12592,
      "latitude": 41.802285,
      "longitude": -73.560425,
      "city": "Wassaic",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12593,
      "latitude": 42.243991,
      "longitude": -73.641076,
      "city": "West Copake",
      "state": "NY",
      "county": "Columbia"
  },
  {
      "zipCode": 12594,
      "latitude": 41.672789,
      "longitude": -73.584346,
      "city": "Wingdale",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12601,
      "latitude": 41.695839,
      "longitude": -73.896164,
      "city": "Poughkeepsie",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12602,
      "latitude": 41.759905,
      "longitude": -73.743714,
      "city": "Poughkeepsie",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12603,
      "latitude": 41.690707,
      "longitude": -73.862074,
      "city": "Poughkeepsie",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12604,
      "latitude": 41.759905,
      "longitude": -73.743714,
      "city": "Poughkeepsie",
      "state": "NY",
      "county": "Dutchess"
  },
  {
      "zipCode": 12701,
      "latitude": 41.686944,
      "longitude": -74.677156,
      "city": "Monticello",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12719,
      "latitude": 41.486186,
      "longitude": -74.915176,
      "city": "Barryville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12720,
      "latitude": 41.661466,
      "longitude": -74.911474,
      "city": "Bethel",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12721,
      "latitude": 41.63868,
      "longitude": -74.721754,
      "city": "Bloomingburg",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12722,
      "latitude": 41.719016,
      "longitude": -74.755373,
      "city": "Burlingham",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12723,
      "latitude": 41.775202,
      "longitude": -75.01807,
      "city": "Callicoon",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12724,
      "latitude": 41.719016,
      "longitude": -74.755373,
      "city": "Callicoon Center",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12725,
      "latitude": 41.899285,
      "longitude": -74.58802,
      "city": "Claryville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12726,
      "latitude": 41.691959,
      "longitude": -74.974074,
      "city": "Cochecton",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12727,
      "latitude": 41.719016,
      "longitude": -74.755373,
      "city": "Cochecton Center",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12729,
      "latitude": 41.403723,
      "longitude": -74.425265,
      "city": "Cuddebackville",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12732,
      "latitude": 41.517426,
      "longitude": -74.845788,
      "city": "Eldred",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12733,
      "latitude": 41.647052,
      "longitude": -74.537252,
      "city": "Fallsburg",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12734,
      "latitude": 41.734858,
      "longitude": -74.734508,
      "city": "Ferndale",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12736,
      "latitude": 41.878194,
      "longitude": -75.034258,
      "city": "Fremont Center",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12737,
      "latitude": 41.547309,
      "longitude": -74.820881,
      "city": "Glen Spey",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12738,
      "latitude": 41.656753,
      "longitude": -74.568565,
      "city": "Glen Wild",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12739,
      "latitude": 41.450914,
      "longitude": -74.601097,
      "city": "Godeffroy",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12740,
      "latitude": 41.818383,
      "longitude": -74.748053,
      "city": "Grahamsville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12741,
      "latitude": 41.839135,
      "longitude": -75.05342,
      "city": "Hankins",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12742,
      "latitude": 41.710156,
      "longitude": -74.71536,
      "city": "Harris",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12743,
      "latitude": 41.531346,
      "longitude": -74.849136,
      "city": "Highland Lake",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12745,
      "latitude": 41.762944,
      "longitude": -75.030592,
      "city": "Hortonville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12746,
      "latitude": 41.437689,
      "longitude": -74.640019,
      "city": "Huguenot",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12747,
      "latitude": 41.770283,
      "longitude": -74.726066,
      "city": "Hurleyville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12748,
      "latitude": 41.782874,
      "longitude": -74.905417,
      "city": "Jeffersonville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12749,
      "latitude": 41.687384,
      "longitude": -74.835807,
      "city": "Kauneonga Lake",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12750,
      "latitude": 41.729581,
      "longitude": -74.961146,
      "city": "Kenoza Lake",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12751,
      "latitude": 41.694655,
      "longitude": -74.682731,
      "city": "Kiamesha Lake",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12752,
      "latitude": 41.758563,
      "longitude": -74.946306,
      "city": "Lake Huntington",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12754,
      "latitude": 41.71994,
      "longitude": -74.66934,
      "city": "Liberty",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12758,
      "latitude": 41.818205,
      "longitude": -74.802549,
      "city": "Livingston Manor",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12759,
      "latitude": 41.786516,
      "longitude": -74.658843,
      "city": "Loch Sheldrake",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12760,
      "latitude": 41.871276,
      "longitude": -75.076328,
      "city": "Long Eddy",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12762,
      "latitude": 41.686123,
      "longitude": -74.783554,
      "city": "Mongaup Valley",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12763,
      "latitude": 41.80469,
      "longitude": -74.696318,
      "city": "Mountain Dale",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12764,
      "latitude": 41.595908,
      "longitude": -74.96233,
      "city": "Narrowsburg",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12765,
      "latitude": 41.865656,
      "longitude": -74.60902,
      "city": "Neversink",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12766,
      "latitude": 41.820897,
      "longitude": -74.995771,
      "city": "North Branch",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12767,
      "latitude": 41.719016,
      "longitude": -74.755373,
      "city": "Obernburg",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12768,
      "latitude": 41.841414,
      "longitude": -74.857261,
      "city": "Parksville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12769,
      "latitude": 41.651453,
      "longitude": -74.436159,
      "city": "Phillipsport",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12770,
      "latitude": 41.446322,
      "longitude": -74.856932,
      "city": "Pond Eddy",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12771,
      "latitude": 41.357119,
      "longitude": -74.498396,
      "city": "Port Jervis",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12775,
      "latitude": 41.619506,
      "longitude": -74.575902,
      "city": "Rock Hill",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12776,
      "latitude": 41.819249,
      "longitude": -74.951782,
      "city": "Roscoe",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12777,
      "latitude": 41.552911,
      "longitude": -74.603366,
      "city": "Forestburgh",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12778,
      "latitude": 41.661538,
      "longitude": -74.817751,
      "city": "Smallwood",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12779,
      "latitude": 41.770195,
      "longitude": -74.716843,
      "city": "South Fallsburg",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12780,
      "latitude": 41.376892,
      "longitude": -74.459687,
      "city": "Sparrow Bush",
      "state": "NY",
      "county": "Orange"
  },
  {
      "zipCode": 12781,
      "latitude": 41.719016,
      "longitude": -74.755373,
      "city": "Summitville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12782,
      "latitude": 41.881485,
      "longitude": -74.430063,
      "city": "Sundown",
      "state": "NY",
      "county": "Ulster"
  },
  {
      "zipCode": 12783,
      "latitude": 41.657319,
      "longitude": -74.840778,
      "city": "Swan Lake",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12784,
      "latitude": 41.714311,
      "longitude": -74.584061,
      "city": "Thompsonville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12785,
      "latitude": 41.719016,
      "longitude": -74.755373,
      "city": "Westbrookville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12786,
      "latitude": 41.64788,
      "longitude": -74.811773,
      "city": "White Lake",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12787,
      "latitude": 41.800193,
      "longitude": -74.828611,
      "city": "White Sulphur Springs",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12788,
      "latitude": 41.810974,
      "longitude": -74.729403,
      "city": "Woodbourne",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12789,
      "latitude": 41.797345,
      "longitude": -74.671013,
      "city": "Woodridge",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12790,
      "latitude": 41.592331,
      "longitude": -74.552644,
      "city": "Wurtsboro",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12791,
      "latitude": 41.695449,
      "longitude": -74.746874,
      "city": "Youngsville",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12792,
      "latitude": 41.591075,
      "longitude": -74.749722,
      "city": "Yulan",
      "state": "NY",
      "county": "Sullivan"
  },
  {
      "zipCode": 12801,
      "latitude": 43.31888,
      "longitude": -73.669061,
      "city": "Glens Falls",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12803,
      "latitude": 43.283561,
      "longitude": -73.629401,
      "city": "South Glens Falls",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12804,
      "latitude": 43.489472,
      "longitude": -73.789563,
      "city": "Queensbury",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12808,
      "latitude": 43.708338,
      "longitude": -73.794147,
      "city": "Adirondack",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12809,
      "latitude": 43.280711,
      "longitude": -73.484828,
      "city": "Argyle",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12810,
      "latitude": 43.59886,
      "longitude": -73.943693,
      "city": "Athol",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12811,
      "latitude": 43.62422,
      "longitude": -74.061201,
      "city": "Bakers Mills",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12812,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Blue Mountain Lake",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12814,
      "latitude": 43.574329,
      "longitude": -73.677564,
      "city": "Bolton Landing",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12815,
      "latitude": 43.677503,
      "longitude": -73.773434,
      "city": "Brant Lake",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12816,
      "latitude": 43.068526,
      "longitude": -73.392229,
      "city": "Cambridge",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12817,
      "latitude": 43.522364,
      "longitude": -73.794491,
      "city": "Chestertown",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12819,
      "latitude": 43.614194,
      "longitude": -73.503056,
      "city": "Clemons",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12820,
      "latitude": 43.47181,
      "longitude": -73.639291,
      "city": "Cleverdale",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12821,
      "latitude": 43.46144,
      "longitude": -73.403287,
      "city": "Comstock",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12822,
      "latitude": 43.241967,
      "longitude": -73.890739,
      "city": "Corinth",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12823,
      "latitude": 43.154262,
      "longitude": -73.458063,
      "city": "Cossayuna",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12824,
      "latitude": 43.560729,
      "longitude": -73.755545,
      "city": "Diamond Point",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12827,
      "latitude": 43.443936,
      "longitude": -73.489198,
      "city": "Fort Ann",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12828,
      "latitude": 43.371195,
      "longitude": -73.434566,
      "city": "Fort Edward",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12831,
      "latitude": 43.204261,
      "longitude": -73.707415,
      "city": "Gansevoort",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12832,
      "latitude": 43.369373,
      "longitude": -73.331692,
      "city": "Granville",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12833,
      "latitude": 43.181214,
      "longitude": -73.906293,
      "city": "Greenfield Center",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12834,
      "latitude": 43.212754,
      "longitude": -73.434261,
      "city": "Greenwich",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12835,
      "latitude": 43.287589,
      "longitude": -73.976958,
      "city": "Hadley",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12836,
      "latitude": 43.730225,
      "longitude": -73.543498,
      "city": "Hague",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12837,
      "latitude": 43.492705,
      "longitude": -73.278904,
      "city": "Hampton",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12838,
      "latitude": 43.354304,
      "longitude": -73.404906,
      "city": "Hartford",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12839,
      "latitude": 43.203596,
      "longitude": -73.468589,
      "city": "Hudson Falls",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12841,
      "latitude": 43.646953,
      "longitude": -73.508308,
      "city": "Huletts Landing",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12842,
      "latitude": 43.749928,
      "longitude": -74.327593,
      "city": "Indian Lake",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12843,
      "latitude": 43.58596,
      "longitude": -73.916498,
      "city": "Johnsburg",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12844,
      "latitude": 43.475353,
      "longitude": -73.627156,
      "city": "Kattskill Bay",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12845,
      "latitude": 43.518611,
      "longitude": -73.875462,
      "city": "Lake George",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12846,
      "latitude": 43.455598,
      "longitude": -73.771547,
      "city": "Lake Luzerne",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12847,
      "latitude": 43.953253,
      "longitude": -74.440029,
      "city": "Long Lake",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12848,
      "latitude": 43.100123,
      "longitude": -73.524554,
      "city": "Middle Falls",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12849,
      "latitude": 43.442087,
      "longitude": -73.290902,
      "city": "Middle Granville",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12850,
      "latitude": 43.185956,
      "longitude": -73.983737,
      "city": "Middle Grove",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12851,
      "latitude": 44.214452,
      "longitude": -73.787317,
      "city": "Minerva",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12852,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Newcomb",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12853,
      "latitude": 43.622181,
      "longitude": -73.922201,
      "city": "North Creek",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12854,
      "latitude": 43.452517,
      "longitude": -73.34095,
      "city": "North Granville",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12855,
      "latitude": 43.970736,
      "longitude": -73.721139,
      "city": "North Hudson",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12856,
      "latitude": 43.723665,
      "longitude": -74.087329,
      "city": "North River",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12857,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Olmstedville",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12858,
      "latitude": 43.879451,
      "longitude": -73.400254,
      "city": "Paradox",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12859,
      "latitude": 43.20138,
      "longitude": -73.891854,
      "city": "Porter Corners",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12860,
      "latitude": 43.592829,
      "longitude": -73.784407,
      "city": "Pottersville",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12861,
      "latitude": 43.743816,
      "longitude": -73.422181,
      "city": "Putnam Station",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12862,
      "latitude": 43.675377,
      "longitude": -73.932509,
      "city": "Riparius",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12863,
      "latitude": 43.061305,
      "longitude": -73.92308,
      "city": "Rock City Falls",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12864,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Sabael",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 12865,
      "latitude": 43.225966,
      "longitude": -73.348736,
      "city": "Salem",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12866,
      "latitude": 43.101145,
      "longitude": -73.850623,
      "city": "Saratoga Springs",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12870,
      "latitude": 43.841273,
      "longitude": -73.759213,
      "city": "Schroon Lake",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12871,
      "latitude": 43.130211,
      "longitude": -73.643205,
      "city": "Schuylerville",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12872,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Severance",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12873,
      "latitude": 43.104587,
      "longitude": -73.314179,
      "city": "Shushan",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12874,
      "latitude": 43.693173,
      "longitude": -73.505019,
      "city": "Silver Bay",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12878,
      "latitude": 43.464398,
      "longitude": -73.876364,
      "city": "Stony Creek",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12879,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Newcomb",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12883,
      "latitude": 43.852071,
      "longitude": -73.478024,
      "city": "Ticonderoga",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12884,
      "latitude": 43.088397,
      "longitude": -73.591652,
      "city": "Victory Mills",
      "state": "NY",
      "county": "Saratoga"
  },
  {
      "zipCode": 12885,
      "latitude": 43.570457,
      "longitude": -73.859928,
      "city": "Warrensburg",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12886,
      "latitude": 43.631297,
      "longitude": -73.936399,
      "city": "Wevertown",
      "state": "NY",
      "county": "Warren"
  },
  {
      "zipCode": 12887,
      "latitude": 43.492815,
      "longitude": -73.421536,
      "city": "Whitehall",
      "state": "NY",
      "county": "Washington"
  },
  {
      "zipCode": 12901,
      "latitude": 44.71187,
      "longitude": -73.637104,
      "city": "Plattsburgh",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12903,
      "latitude": 44.685411,
      "longitude": -73.447398,
      "city": "Plattsburgh",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12910,
      "latitude": 44.865209,
      "longitude": -73.577683,
      "city": "Altona",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12911,
      "latitude": 44.528081,
      "longitude": -73.471946,
      "city": "Keeseville",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12912,
      "latitude": 44.711326,
      "longitude": -73.648386,
      "city": "Au Sable Forks",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12913,
      "latitude": 44.401213,
      "longitude": -74.070277,
      "city": "Bloomingdale",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12914,
      "latitude": 44.674942,
      "longitude": -74.444789,
      "city": "Bombay",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12915,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Brainardsville",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12916,
      "latitude": 44.87763,
      "longitude": -74.489379,
      "city": "Brushton",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12917,
      "latitude": 44.932057,
      "longitude": -74.200948,
      "city": "Burke",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12918,
      "latitude": 44.686162,
      "longitude": -73.670249,
      "city": "Cadyville",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12919,
      "latitude": 44.726056,
      "longitude": -73.578285,
      "city": "Champlain",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12920,
      "latitude": 44.932727,
      "longitude": -74.068371,
      "city": "Chateaugay",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12921,
      "latitude": 44.879906,
      "longitude": -73.452578,
      "city": "Chazy",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12922,
      "latitude": 44.287829,
      "longitude": -74.702338,
      "city": "Childwold",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 12923,
      "latitude": 44.916842,
      "longitude": -73.932053,
      "city": "Churubusco",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12924,
      "latitude": 44.706561,
      "longitude": -73.744555,
      "city": "Keeseville",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12926,
      "latitude": 44.944145,
      "longitude": -74.327316,
      "city": "Constable",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12927,
      "latitude": 44.228969,
      "longitude": -74.858103,
      "city": "Cranberry Lake",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 12928,
      "latitude": 43.930616,
      "longitude": -73.58854,
      "city": "Crown Point",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12929,
      "latitude": 44.847709,
      "longitude": -73.582317,
      "city": "Dannemora",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12930,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Dickinson Center",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12932,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Elizabethtown",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12933,
      "latitude": 44.881962,
      "longitude": -73.963279,
      "city": "Ellenburg",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12934,
      "latitude": 44.892243,
      "longitude": -73.907936,
      "city": "Ellenburg Center",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12935,
      "latitude": 44.801467,
      "longitude": -73.739157,
      "city": "Ellenburg Depot",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12936,
      "latitude": 44.273412,
      "longitude": -73.377434,
      "city": "Essex",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12937,
      "latitude": 44.955977,
      "longitude": -74.48674,
      "city": "Fort Covington",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12939,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Gabriels",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12941,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Jay",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12942,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Keene",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12943,
      "latitude": 44.202397,
      "longitude": -73.773111,
      "city": "Keene Valley",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12944,
      "latitude": 44.343063,
      "longitude": -73.436913,
      "city": "Keeseville",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12945,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Lake Clear",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12946,
      "latitude": 44.130663,
      "longitude": -73.734652,
      "city": "Lake Placid",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12949,
      "latitude": 44.746861,
      "longitude": -74.660413,
      "city": "Lawrenceville",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 12950,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Lewis",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12952,
      "latitude": 44.734587,
      "longitude": -73.900452,
      "city": "Lyon Mountain",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12953,
      "latitude": 44.859117,
      "longitude": -74.288237,
      "city": "Malone",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12955,
      "latitude": 44.804316,
      "longitude": -73.97299,
      "city": "Lyon Mountain",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12956,
      "latitude": 44.12404,
      "longitude": -73.491335,
      "city": "Mineville",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12957,
      "latitude": 44.85688,
      "longitude": -74.552321,
      "city": "Moira",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12958,
      "latitude": 44.792956,
      "longitude": -73.661296,
      "city": "Mooers",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12959,
      "latitude": 44.76292,
      "longitude": -73.615749,
      "city": "Mooers Forks",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12960,
      "latitude": 44.041863,
      "longitude": -73.505265,
      "city": "Moriah",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12961,
      "latitude": 44.055155,
      "longitude": -73.534677,
      "city": "Moriah Center",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12962,
      "latitude": 44.617784,
      "longitude": -73.605062,
      "city": "Morrisonville",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12964,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "New Russia",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12965,
      "latitude": 44.726424,
      "longitude": -74.678121,
      "city": "Nicholville",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 12966,
      "latitude": 44.8532,
      "longitude": -74.419121,
      "city": "North Bangor",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12967,
      "latitude": 44.761862,
      "longitude": -74.680488,
      "city": "North Lawrence",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 12969,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Owls Head",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12970,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Paul Smiths",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12972,
      "latitude": 44.66607,
      "longitude": -73.569167,
      "city": "Peru",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12973,
      "latitude": 44.234289,
      "longitude": -74.555957,
      "city": "Piercefield",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 12974,
      "latitude": 44.087072,
      "longitude": -73.461308,
      "city": "Port Henry",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12975,
      "latitude": 44.526437,
      "longitude": -73.409243,
      "city": "Port Kent",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12976,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Rainbow Lake",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12977,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Ray Brook",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12978,
      "latitude": 44.664956,
      "longitude": -73.761204,
      "city": "Redford",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12979,
      "latitude": 44.864633,
      "longitude": -73.626615,
      "city": "Rouses Point",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12980,
      "latitude": 44.657819,
      "longitude": -74.515511,
      "city": "Saint Regis Falls",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12981,
      "latitude": 44.624413,
      "longitude": -73.809266,
      "city": "Saranac",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12983,
      "latitude": 44.583429,
      "longitude": -74.272104,
      "city": "Saranac Lake",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12985,
      "latitude": 44.570072,
      "longitude": -73.678749,
      "city": "Schuyler Falls",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12986,
      "latitude": 44.599971,
      "longitude": -74.294012,
      "city": "Tupper Lake",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12987,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Upper Jay",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12989,
      "latitude": 44.552725,
      "longitude": -74.317958,
      "city": "Vermontville",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12992,
      "latitude": 44.827274,
      "longitude": -73.513225,
      "city": "West Chazy",
      "state": "NY",
      "county": "Clinton"
  },
  {
      "zipCode": 12993,
      "latitude": 44.18783,
      "longitude": -73.434541,
      "city": "Westport",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12995,
      "latitude": 44.805681,
      "longitude": -74.252254,
      "city": "Whippleville",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 12996,
      "latitude": 44.242261,
      "longitude": -73.416202,
      "city": "Willsboro",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12997,
      "latitude": 44.145678,
      "longitude": -73.815204,
      "city": "Wilmington",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 12998,
      "latitude": 44.08267,
      "longitude": -73.530601,
      "city": "Witherbee",
      "state": "NY",
      "county": "Essex"
  },
  {
      "zipCode": 13020,
      "latitude": 42.823968,
      "longitude": -76.062425,
      "city": "Apulia Station",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13021,
      "latitude": 42.894381,
      "longitude": -76.565683,
      "city": "Auburn",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13022,
      "latitude": 43.163364,
      "longitude": -76.509567,
      "city": "Auburn",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13024,
      "latitude": 43.163364,
      "longitude": -76.509567,
      "city": "Auburn",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13026,
      "latitude": 42.755979,
      "longitude": -76.646007,
      "city": "Aurora",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13027,
      "latitude": 43.158531,
      "longitude": -76.360147,
      "city": "Baldwinsville",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13028,
      "latitude": 43.310494,
      "longitude": -76.135765,
      "city": "Bernhards Bay",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13029,
      "latitude": 43.227448,
      "longitude": -76.140648,
      "city": "Brewerton",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13030,
      "latitude": 43.165372,
      "longitude": -75.993896,
      "city": "Bridgeport",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13031,
      "latitude": 43.049756,
      "longitude": -76.348012,
      "city": "Camillus",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13032,
      "latitude": 43.005767,
      "longitude": -75.798607,
      "city": "Canastota",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13033,
      "latitude": 43.185529,
      "longitude": -76.579082,
      "city": "Cato",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13034,
      "latitude": 42.924315,
      "longitude": -76.676419,
      "city": "Cayuga",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13035,
      "latitude": 42.939422,
      "longitude": -75.775568,
      "city": "Cazenovia",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13036,
      "latitude": 43.330332,
      "longitude": -76.17085,
      "city": "Central Square",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13037,
      "latitude": 43.009985,
      "longitude": -75.806194,
      "city": "Chittenango",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13039,
      "latitude": 43.125491,
      "longitude": -76.154128,
      "city": "Cicero",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13040,
      "latitude": 42.565932,
      "longitude": -75.955301,
      "city": "Cincinnatus",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13041,
      "latitude": 43.119217,
      "longitude": -76.131931,
      "city": "Clay",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13042,
      "latitude": 43.27637,
      "longitude": -76.225752,
      "city": "Cleveland",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13043,
      "latitude": 43.042044,
      "longitude": -75.740848,
      "city": "Clockville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13044,
      "latitude": 43.301232,
      "longitude": -75.982593,
      "city": "Constantia",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13045,
      "latitude": 42.614268,
      "longitude": -76.120128,
      "city": "Cortland",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13051,
      "latitude": 42.870698,
      "longitude": -75.91128,
      "city": "Delphi Falls",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13052,
      "latitude": 42.776527,
      "longitude": -75.859458,
      "city": "De Ruyter",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13053,
      "latitude": 42.477712,
      "longitude": -76.291224,
      "city": "Dryden",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 13054,
      "latitude": 43.14548,
      "longitude": -75.559605,
      "city": "Durhamville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13056,
      "latitude": 42.677155,
      "longitude": -76.105201,
      "city": "East Homer",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13057,
      "latitude": 43.096216,
      "longitude": -76.047426,
      "city": "East Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13060,
      "latitude": 43.027813,
      "longitude": -76.416612,
      "city": "Elbridge",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13061,
      "latitude": 42.841725,
      "longitude": -75.744589,
      "city": "Erieville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13062,
      "latitude": 42.484352,
      "longitude": -76.389023,
      "city": "Etna",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 13063,
      "latitude": 42.855631,
      "longitude": -75.976158,
      "city": "Fabius",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13064,
      "latitude": 43.431311,
      "longitude": -76.200448,
      "city": "Fair Haven",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13065,
      "latitude": 42.822662,
      "longitude": -76.80197,
      "city": "Fayette",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 13066,
      "latitude": 42.947789,
      "longitude": -75.995518,
      "city": "Fayetteville",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13068,
      "latitude": 42.49653,
      "longitude": -76.372864,
      "city": "Freeville",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 13069,
      "latitude": 43.345255,
      "longitude": -76.227027,
      "city": "Fulton",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13071,
      "latitude": 42.673871,
      "longitude": -76.526226,
      "city": "Genoa",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13072,
      "latitude": 42.768124,
      "longitude": -75.788393,
      "city": "Georgetown",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13073,
      "latitude": 42.579298,
      "longitude": -76.392709,
      "city": "Groton",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 13074,
      "latitude": 43.324451,
      "longitude": -76.306549,
      "city": "Hannibal",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13076,
      "latitude": 43.338817,
      "longitude": -76.189586,
      "city": "Hastings",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13077,
      "latitude": 42.678012,
      "longitude": -76.175275,
      "city": "Homer",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13078,
      "latitude": 42.966136,
      "longitude": -76.057658,
      "city": "Jamesville",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13080,
      "latitude": 43.067413,
      "longitude": -76.439657,
      "city": "Jordan",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13081,
      "latitude": 42.876886,
      "longitude": -76.631117,
      "city": "King Ferry",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13082,
      "latitude": 43.103886,
      "longitude": -75.961597,
      "city": "Kirkville",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13083,
      "latitude": 43.642147,
      "longitude": -76.029304,
      "city": "Lacona",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13084,
      "latitude": 42.891099,
      "longitude": -76.128862,
      "city": "La Fayette",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13087,
      "latitude": 42.706981,
      "longitude": -76.156103,
      "city": "Little York",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13088,
      "latitude": 43.124866,
      "longitude": -76.207867,
      "city": "Liverpool",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13089,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Liverpool",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13090,
      "latitude": 43.152767,
      "longitude": -76.223459,
      "city": "Liverpool",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13092,
      "latitude": 42.659466,
      "longitude": -76.410528,
      "city": "Locke",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13093,
      "latitude": 43.431311,
      "longitude": -76.200448,
      "city": "Lycoming",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13101,
      "latitude": 42.597468,
      "longitude": -76.072949,
      "city": "Mc Graw",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13102,
      "latitude": 42.554215,
      "longitude": -76.292723,
      "city": "Mc Lean",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 13103,
      "latitude": 43.339851,
      "longitude": -76.096357,
      "city": "Mallory",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13104,
      "latitude": 42.955618,
      "longitude": -75.96654,
      "city": "Manlius",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13107,
      "latitude": 43.457798,
      "longitude": -76.153442,
      "city": "Maple View",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13108,
      "latitude": 42.948057,
      "longitude": -76.329799,
      "city": "Marcellus",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13110,
      "latitude": 42.8809,
      "longitude": -76.291265,
      "city": "Marietta",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13111,
      "latitude": 43.258337,
      "longitude": -76.6292,
      "city": "Martville",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13112,
      "latitude": 43.098983,
      "longitude": -76.413216,
      "city": "Memphis",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13113,
      "latitude": 43.089111,
      "longitude": -76.621747,
      "city": "Meridian",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13114,
      "latitude": 43.398473,
      "longitude": -76.204997,
      "city": "Mexico",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13115,
      "latitude": 43.397671,
      "longitude": -76.482365,
      "city": "Minetto",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13116,
      "latitude": 43.076828,
      "longitude": -76.006001,
      "city": "Minoa",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13117,
      "latitude": 43.002267,
      "longitude": -76.704722,
      "city": "Montezuma",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13118,
      "latitude": 42.737724,
      "longitude": -76.407811,
      "city": "Moravia",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13119,
      "latitude": 42.97446,
      "longitude": -76.440833,
      "city": "Mottville",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13120,
      "latitude": 42.951126,
      "longitude": -76.179845,
      "city": "Nedrow",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13121,
      "latitude": 43.483379,
      "longitude": -76.315044,
      "city": "New Haven",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13122,
      "latitude": 42.825164,
      "longitude": -75.85266,
      "city": "New Woodstock",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13123,
      "latitude": 43.236407,
      "longitude": -75.776918,
      "city": "North Bay",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13124,
      "latitude": 42.479801,
      "longitude": -75.622857,
      "city": "North Pitcher",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13126,
      "latitude": 43.465388,
      "longitude": -76.342172,
      "city": "Oswego",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13129,
      "latitude": 42.712735,
      "longitude": -75.731391,
      "city": "Georgetown",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13131,
      "latitude": 43.43397,
      "longitude": -76.120904,
      "city": "Parish",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13132,
      "latitude": 43.296808,
      "longitude": -76.229091,
      "city": "Pennellville",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13134,
      "latitude": 42.968619,
      "longitude": -75.679351,
      "city": "Peterboro",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13135,
      "latitude": 43.33021,
      "longitude": -76.260803,
      "city": "Phoenix",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13136,
      "latitude": 42.619466,
      "longitude": -75.845544,
      "city": "Pitcher",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13137,
      "latitude": 43.157653,
      "longitude": -76.44698,
      "city": "Plainville",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13138,
      "latitude": 42.89274,
      "longitude": -76.026459,
      "city": "Pompey",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13139,
      "latitude": 42.742057,
      "longitude": -76.628454,
      "city": "Poplar Ridge",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13140,
      "latitude": 42.886349,
      "longitude": -76.541321,
      "city": "Port Byron",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13141,
      "latitude": 42.739682,
      "longitude": -76.157108,
      "city": "Preble",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13142,
      "latitude": 43.560497,
      "longitude": -76.15532,
      "city": "Pulaski",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13143,
      "latitude": 43.238248,
      "longitude": -76.752077,
      "city": "Red Creek",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 13144,
      "latitude": 43.571095,
      "longitude": -75.972208,
      "city": "Richland",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13145,
      "latitude": 43.621562,
      "longitude": -76.11029,
      "city": "Sandy Creek",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13146,
      "latitude": 43.099386,
      "longitude": -76.762705,
      "city": "Savannah",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 13147,
      "latitude": 42.770829,
      "longitude": -76.586213,
      "city": "Scipio Center",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13148,
      "latitude": 42.905264,
      "longitude": -76.794921,
      "city": "Seneca Falls",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 13152,
      "latitude": 42.884394,
      "longitude": -76.285202,
      "city": "Skaneateles",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13153,
      "latitude": 42.991112,
      "longitude": -76.451147,
      "city": "Skaneateles Falls",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13154,
      "latitude": 43.134425,
      "longitude": -76.765591,
      "city": "South Butler",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 13155,
      "latitude": 42.654768,
      "longitude": -75.769855,
      "city": "South Otselic",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13156,
      "latitude": 43.341948,
      "longitude": -76.665228,
      "city": "Sterling",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13157,
      "latitude": 43.209147,
      "longitude": -75.72307,
      "city": "Sylvan Beach",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13158,
      "latitude": 42.696543,
      "longitude": -75.98409,
      "city": "Truxton",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13159,
      "latitude": 42.830037,
      "longitude": -76.124357,
      "city": "Tully",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13160,
      "latitude": 42.847447,
      "longitude": -76.649164,
      "city": "Union Springs",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13162,
      "latitude": 43.18852,
      "longitude": -75.712551,
      "city": "Verona Beach",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13163,
      "latitude": 43.078461,
      "longitude": -75.701639,
      "city": "Wampsville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13164,
      "latitude": 43.100588,
      "longitude": -76.321678,
      "city": "Warners",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13165,
      "latitude": 42.892556,
      "longitude": -76.882263,
      "city": "Waterloo",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 13166,
      "latitude": 43.046493,
      "longitude": -76.555316,
      "city": "Weedsport",
      "state": "NY",
      "county": "Cayuga"
  },
  {
      "zipCode": 13167,
      "latitude": 43.346453,
      "longitude": -76.229719,
      "city": "West Monroe",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13201,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13202,
      "latitude": 43.043116,
      "longitude": -76.150796,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13203,
      "latitude": 43.062016,
      "longitude": -76.134145,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13204,
      "latitude": 43.055766,
      "longitude": -76.177597,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13205,
      "latitude": 43.006816,
      "longitude": -76.142495,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13206,
      "latitude": 43.076416,
      "longitude": -76.109995,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13207,
      "latitude": 43.012216,
      "longitude": -76.170596,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13208,
      "latitude": 43.074466,
      "longitude": -76.145796,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13209,
      "latitude": 43.084666,
      "longitude": -76.240549,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13210,
      "latitude": 43.031416,
      "longitude": -76.110995,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13211,
      "latitude": 43.091916,
      "longitude": -76.124645,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13212,
      "latitude": 43.122566,
      "longitude": -76.128382,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13214,
      "latitude": 43.039666,
      "longitude": -76.072153,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13215,
      "latitude": 42.972161,
      "longitude": -76.227599,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13217,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13218,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13219,
      "latitude": 43.041703,
      "longitude": -76.222898,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13220,
      "latitude": 43.123415,
      "longitude": -76.12823,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13221,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13224,
      "latitude": 43.038366,
      "longitude": -76.099394,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13225,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13235,
      "latitude": 43.04236,
      "longitude": -76.134441,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13244,
      "latitude": 43.037716,
      "longitude": -76.139646,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13250,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13251,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13252,
      "latitude": 43.050966,
      "longitude": -76.156696,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13260,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13261,
      "latitude": 43.02143,
      "longitude": -76.197701,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13290,
      "latitude": 43.067576,
      "longitude": -76.171351,
      "city": "Syracuse",
      "state": "NY",
      "county": "Onondaga"
  },
  {
      "zipCode": 13301,
      "latitude": 43.419596,
      "longitude": -75.224784,
      "city": "Alder Creek",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13302,
      "latitude": 43.497512,
      "longitude": -75.988154,
      "city": "Altmar",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13303,
      "latitude": 43.364242,
      "longitude": -75.468514,
      "city": "Ava",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13304,
      "latitude": 43.244356,
      "longitude": -75.166319,
      "city": "Barneveld",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13305,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Beaver Falls",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13308,
      "latitude": 43.137703,
      "longitude": -75.523266,
      "city": "Blossvale",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13309,
      "latitude": 43.23331,
      "longitude": -75.294896,
      "city": "Boonville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13310,
      "latitude": 42.930711,
      "longitude": -75.560937,
      "city": "Bouckville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13312,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Brantingham",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13313,
      "latitude": 42.879227,
      "longitude": -75.267172,
      "city": "Bridgewater",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13314,
      "latitude": 42.954541,
      "longitude": -75.616793,
      "city": "Brookfield",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13315,
      "latitude": 42.734593,
      "longitude": -75.133065,
      "city": "Burlington Flats",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13316,
      "latitude": 43.285739,
      "longitude": -75.626755,
      "city": "Camden",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13317,
      "latitude": 42.867146,
      "longitude": -74.595636,
      "city": "Canajoharie",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 13318,
      "latitude": 42.921786,
      "longitude": -75.255163,
      "city": "Cassville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13319,
      "latitude": 43.028469,
      "longitude": -75.264008,
      "city": "Chadwicks",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13320,
      "latitude": 42.768195,
      "longitude": -74.759734,
      "city": "Cherry Valley",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13321,
      "latitude": 43.090027,
      "longitude": -75.387088,
      "city": "Clark Mills",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13322,
      "latitude": 42.961973,
      "longitude": -75.233003,
      "city": "Clayville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13323,
      "latitude": 43.036697,
      "longitude": -75.382414,
      "city": "Clinton",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13324,
      "latitude": 43.271433,
      "longitude": -75.04774,
      "city": "Cold Brook",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13325,
      "latitude": 43.56647,
      "longitude": -75.425289,
      "city": "Constableville",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13326,
      "latitude": 42.714065,
      "longitude": -74.890929,
      "city": "Cooperstown",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13327,
      "latitude": 43.909848,
      "longitude": -75.365264,
      "city": "Croghan",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13328,
      "latitude": 42.983981,
      "longitude": -75.422034,
      "city": "Deansboro",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13329,
      "latitude": 43.18486,
      "longitude": -74.941747,
      "city": "Dolgeville",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13331,
      "latitude": 43.816675,
      "longitude": -74.886163,
      "city": "Eagle Bay",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13332,
      "latitude": 42.719674,
      "longitude": -75.558861,
      "city": "Earlville",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13333,
      "latitude": 42.793309,
      "longitude": -74.837198,
      "city": "East Springfield",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13334,
      "latitude": 42.82982,
      "longitude": -75.65364,
      "city": "Eaton",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13335,
      "latitude": 42.711712,
      "longitude": -75.246539,
      "city": "Edmeston",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13337,
      "latitude": 42.755679,
      "longitude": -74.876761,
      "city": "Fly Creek",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13338,
      "latitude": 43.500975,
      "longitude": -75.153257,
      "city": "Forestport",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13339,
      "latitude": 42.924806,
      "longitude": -74.616883,
      "city": "Fort Plain",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 13340,
      "latitude": 43.039203,
      "longitude": -75.118168,
      "city": "Frankfort",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13341,
      "latitude": 43.036069,
      "longitude": -75.396214,
      "city": "Franklin Springs",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13342,
      "latitude": 42.63153,
      "longitude": -75.186593,
      "city": "Garrattsville",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13343,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Glenfield",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13345,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Greig",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13346,
      "latitude": 42.822667,
      "longitude": -75.553153,
      "city": "Hamilton",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13348,
      "latitude": 42.690531,
      "longitude": -75.063513,
      "city": "Hartwick",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13350,
      "latitude": 43.026867,
      "longitude": -74.933404,
      "city": "Herkimer",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13352,
      "latitude": 43.328187,
      "longitude": -75.117319,
      "city": "Hinckley",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13353,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Hoffmeister",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 13354,
      "latitude": 43.273266,
      "longitude": -75.301263,
      "city": "Holland Patent",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13355,
      "latitude": 42.817286,
      "longitude": -75.422764,
      "city": "Hubbardsville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13357,
      "latitude": 43.013625,
      "longitude": -74.958226,
      "city": "Ilion",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13360,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Inlet",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 13361,
      "latitude": 42.964369,
      "longitude": -74.881228,
      "city": "Jordanville",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13362,
      "latitude": 42.980334,
      "longitude": -75.518569,
      "city": "Knoxboro",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13363,
      "latitude": 43.328374,
      "longitude": -75.520067,
      "city": "Lee Center",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13364,
      "latitude": 42.954541,
      "longitude": -75.616793,
      "city": "Leonardsville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13365,
      "latitude": 43.078904,
      "longitude": -74.864422,
      "city": "Little Falls",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13367,
      "latitude": 43.789296,
      "longitude": -75.415599,
      "city": "Lowville",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13368,
      "latitude": 43.621838,
      "longitude": -75.365348,
      "city": "Lyons Falls",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13401,
      "latitude": 43.267243,
      "longitude": -75.688223,
      "city": "Mc Connellsville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13402,
      "latitude": 42.898322,
      "longitude": -75.549372,
      "city": "Madison",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13403,
      "latitude": 43.172019,
      "longitude": -75.27371,
      "city": "Marcy",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13404,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Martinsburg",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13406,
      "latitude": 43.135209,
      "longitude": -74.934473,
      "city": "Middleville",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13407,
      "latitude": 43.029258,
      "longitude": -75.011662,
      "city": "Mohawk",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13408,
      "latitude": 42.924089,
      "longitude": -75.651484,
      "city": "Morrisville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13409,
      "latitude": 42.972773,
      "longitude": -75.600722,
      "city": "Munnsville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13410,
      "latitude": 42.937863,
      "longitude": -74.611711,
      "city": "Nelliston",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 13411,
      "latitude": 42.631807,
      "longitude": -75.385073,
      "city": "New Berlin",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13413,
      "latitude": 43.067369,
      "longitude": -75.290911,
      "city": "New Hartford",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13415,
      "latitude": 42.590419,
      "longitude": -75.195695,
      "city": "New Lisbon",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13416,
      "latitude": 43.203313,
      "longitude": -74.971893,
      "city": "Newport",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13417,
      "latitude": 43.100869,
      "longitude": -75.29496,
      "city": "New York Mills",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13418,
      "latitude": 42.954541,
      "longitude": -75.616793,
      "city": "North Brookfield",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13420,
      "latitude": 43.752203,
      "longitude": -74.873176,
      "city": "Old Forge",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13421,
      "latitude": 43.047755,
      "longitude": -75.640944,
      "city": "Oneida",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13424,
      "latitude": 43.056581,
      "longitude": -75.38291,
      "city": "Oriskany",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13425,
      "latitude": 42.96965,
      "longitude": -75.485421,
      "city": "Oriskany Falls",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13426,
      "latitude": 43.562757,
      "longitude": -75.996773,
      "city": "Orwell",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13428,
      "latitude": 42.926617,
      "longitude": -74.54472,
      "city": "Palatine Bridge",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 13431,
      "latitude": 43.232502,
      "longitude": -75.07288,
      "city": "Poland",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13433,
      "latitude": 43.576823,
      "longitude": -75.315816,
      "city": "Port Leyden",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13435,
      "latitude": 43.305156,
      "longitude": -75.150183,
      "city": "Prospect",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13436,
      "latitude": 43.667618,
      "longitude": -74.456913,
      "city": "Raquette Lake",
      "state": "NY",
      "county": "Hamilton"
  },
  {
      "zipCode": 13437,
      "latitude": 43.577131,
      "longitude": -75.829419,
      "city": "Redfield",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13438,
      "latitude": 43.341666,
      "longitude": -75.221046,
      "city": "Remsen",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13439,
      "latitude": 42.84018,
      "longitude": -74.97159,
      "city": "Richfield Springs",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13440,
      "latitude": 43.20886,
      "longitude": -75.383365,
      "city": "Rome",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13441,
      "latitude": 43.164312,
      "longitude": -75.511301,
      "city": "Rome",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13442,
      "latitude": 43.239274,
      "longitude": -75.47796,
      "city": "Rome",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13449,
      "latitude": 43.239274,
      "longitude": -75.47796,
      "city": "Rome",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13450,
      "latitude": 42.70804,
      "longitude": -74.802488,
      "city": "Roseboom",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13452,
      "latitude": 42.975134,
      "longitude": -74.657384,
      "city": "Saint Johnsville",
      "state": "NY",
      "county": "Montgomery"
  },
  {
      "zipCode": 13454,
      "latitude": 43.460951,
      "longitude": -74.957099,
      "city": "Salisbury Center",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13455,
      "latitude": 42.916235,
      "longitude": -75.35448,
      "city": "Sangerfield",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13456,
      "latitude": 42.991124,
      "longitude": -75.270758,
      "city": "Sauquoit",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13457,
      "latitude": 42.775805,
      "longitude": -75.048502,
      "city": "Schuyler Lake",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13459,
      "latitude": 42.787077,
      "longitude": -74.584971,
      "city": "Sharon Springs",
      "state": "NY",
      "county": "Schoharie"
  },
  {
      "zipCode": 13460,
      "latitude": 42.648204,
      "longitude": -75.455824,
      "city": "Sherburne",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13461,
      "latitude": 43.074349,
      "longitude": -75.580839,
      "city": "Sherrill",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13464,
      "latitude": 42.672573,
      "longitude": -75.593362,
      "city": "Smyrna",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13465,
      "latitude": 42.954541,
      "longitude": -75.616793,
      "city": "Solsville",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13468,
      "latitude": 42.83883,
      "longitude": -74.85897,
      "city": "Springfield Center",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13469,
      "latitude": 43.219369,
      "longitude": -75.299112,
      "city": "Stittville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13470,
      "latitude": 43.190651,
      "longitude": -74.650311,
      "city": "Stratford",
      "state": "NY",
      "county": "Fulton"
  },
  {
      "zipCode": 13471,
      "latitude": 43.228682,
      "longitude": -75.591423,
      "city": "Taberg",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13472,
      "latitude": 43.460951,
      "longitude": -74.957099,
      "city": "Thendara",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13473,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Turin",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13475,
      "latitude": 42.893454,
      "longitude": -74.836685,
      "city": "Van Hornesville",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13476,
      "latitude": 43.092924,
      "longitude": -75.50161,
      "city": "Vernon",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13477,
      "latitude": 43.141536,
      "longitude": -75.530826,
      "city": "Vernon Center",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13478,
      "latitude": 43.146502,
      "longitude": -75.586218,
      "city": "Verona",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13479,
      "latitude": 43.053769,
      "longitude": -75.271559,
      "city": "Washington Mills",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13480,
      "latitude": 42.926167,
      "longitude": -75.360922,
      "city": "Waterville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13482,
      "latitude": 42.704333,
      "longitude": -75.184913,
      "city": "West Burlington",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13483,
      "latitude": 43.403751,
      "longitude": -75.825528,
      "city": "Westdale",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13484,
      "latitude": 42.854631,
      "longitude": -75.660462,
      "city": "West Eaton",
      "state": "NY",
      "county": "Madison"
  },
  {
      "zipCode": 13485,
      "latitude": 42.747058,
      "longitude": -75.160928,
      "city": "West Edmeston",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13486,
      "latitude": 43.339068,
      "longitude": -75.341898,
      "city": "Westernville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13488,
      "latitude": 42.680906,
      "longitude": -74.76529,
      "city": "Westford",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13489,
      "latitude": 43.44448,
      "longitude": -75.543231,
      "city": "West Leyden",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13490,
      "latitude": 43.124769,
      "longitude": -75.432867,
      "city": "Westmoreland",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13491,
      "latitude": 42.909122,
      "longitude": -75.12867,
      "city": "West Winfield",
      "state": "NY",
      "county": "Herkimer"
  },
  {
      "zipCode": 13492,
      "latitude": 43.197827,
      "longitude": -75.287643,
      "city": "Whitesboro",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13493,
      "latitude": 43.427301,
      "longitude": -75.880041,
      "city": "Williamstown",
      "state": "NY",
      "county": "Oswego"
  },
  {
      "zipCode": 13494,
      "latitude": 43.524904,
      "longitude": -75.142771,
      "city": "Woodgate",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13495,
      "latitude": 43.102744,
      "longitude": -75.321211,
      "city": "Yorkville",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13501,
      "latitude": 43.077369,
      "longitude": -75.233208,
      "city": "Utica",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13502,
      "latitude": 43.150119,
      "longitude": -75.192786,
      "city": "Utica",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13503,
      "latitude": 43.101869,
      "longitude": -75.231158,
      "city": "Utica",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13504,
      "latitude": 43.136033,
      "longitude": -75.432486,
      "city": "Utica",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13505,
      "latitude": 43.087169,
      "longitude": -75.260259,
      "city": "Utica",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13599,
      "latitude": 43.239274,
      "longitude": -75.47796,
      "city": "Utica",
      "state": "NY",
      "county": "Oneida"
  },
  {
      "zipCode": 13601,
      "latitude": 44.072542,
      "longitude": -76.016589,
      "city": "Watertown",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13602,
      "latitude": 44.032457,
      "longitude": -75.754468,
      "city": "Fort Drum",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13603,
      "latitude": 43.908739,
      "longitude": -75.896719,
      "city": "Watertown",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13605,
      "latitude": 43.960222,
      "longitude": -75.905634,
      "city": "Adams",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13606,
      "latitude": 43.831853,
      "longitude": -76.007188,
      "city": "Adams Center",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13607,
      "latitude": 44.074604,
      "longitude": -75.840719,
      "city": "Alexandria Bay",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13608,
      "latitude": 44.148052,
      "longitude": -75.759103,
      "city": "Antwerp",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13611,
      "latitude": 43.778046,
      "longitude": -76.125914,
      "city": "Belleville",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13612,
      "latitude": 43.98871,
      "longitude": -75.766746,
      "city": "Black River",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13613,
      "latitude": 44.788969,
      "longitude": -74.879786,
      "city": "Brasher Falls",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13614,
      "latitude": 44.538648,
      "longitude": -75.68666,
      "city": "Brier Hill",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13615,
      "latitude": 44.057685,
      "longitude": -76.019634,
      "city": "Brownville",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13616,
      "latitude": 43.951428,
      "longitude": -75.860442,
      "city": "Calcium",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13617,
      "latitude": 44.574484,
      "longitude": -75.059713,
      "city": "Canton",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13618,
      "latitude": 44.126469,
      "longitude": -76.272585,
      "city": "Cape Vincent",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13619,
      "latitude": 44.068221,
      "longitude": -75.920097,
      "city": "Carthage",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13620,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Castorland",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13621,
      "latitude": 44.845315,
      "longitude": -75.047975,
      "city": "Chase Mills",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13622,
      "latitude": 44.091892,
      "longitude": -76.108527,
      "city": "Chaumont",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13623,
      "latitude": 44.440171,
      "longitude": -75.757938,
      "city": "Chippewa Bay",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13624,
      "latitude": 44.144238,
      "longitude": -76.062002,
      "city": "Clayton",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13625,
      "latitude": 44.421607,
      "longitude": -75.105077,
      "city": "Colton",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13626,
      "latitude": 43.892324,
      "longitude": -75.703596,
      "city": "Copenhagen",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13627,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Deer River",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13628,
      "latitude": 43.885519,
      "longitude": -75.798319,
      "city": "Deferiet",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13630,
      "latitude": 44.462555,
      "longitude": -75.30067,
      "city": "De Kalb Junction",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13631,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Denmark",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13632,
      "latitude": 44.139501,
      "longitude": -76.061552,
      "city": "Depauville",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13633,
      "latitude": 44.49891,
      "longitude": -75.477156,
      "city": "De Peyster",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13634,
      "latitude": 44.014261,
      "longitude": -76.08002,
      "city": "Dexter",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13635,
      "latitude": 44.523528,
      "longitude": -75.160038,
      "city": "Edwards",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13636,
      "latitude": 43.75965,
      "longitude": -76.152508,
      "city": "Ellisburg",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13637,
      "latitude": 44.0981,
      "longitude": -75.817097,
      "city": "Evans Mills",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13638,
      "latitude": 44.01751,
      "longitude": -75.755032,
      "city": "Felts Mills",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13639,
      "latitude": 44.23896,
      "longitude": -75.122756,
      "city": "Fine",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13640,
      "latitude": 44.321282,
      "longitude": -76.017151,
      "city": "Wellesley Island",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13641,
      "latitude": 44.207681,
      "longitude": -75.914556,
      "city": "Fishers Landing",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13642,
      "latitude": 44.550897,
      "longitude": -75.237516,
      "city": "Gouverneur",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13643,
      "latitude": 44.014698,
      "longitude": -75.728357,
      "city": "Great Bend",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13645,
      "latitude": 44.533125,
      "longitude": -75.192865,
      "city": "Hailesboro",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13646,
      "latitude": 44.468,
      "longitude": -75.359907,
      "city": "Hammond",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13647,
      "latitude": 44.608735,
      "longitude": -74.973218,
      "city": "Hannawa Falls",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13648,
      "latitude": 43.818705,
      "longitude": -75.480342,
      "city": "Harrisville",
      "state": "NY",
      "county": "Lewis"
  },
  {
      "zipCode": 13649,
      "latitude": 44.921678,
      "longitude": -74.706763,
      "city": "Helena",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13650,
      "latitude": 43.812653,
      "longitude": -76.208109,
      "city": "Henderson",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13651,
      "latitude": 43.870842,
      "longitude": -76.180886,
      "city": "Henderson Harbor",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13652,
      "latitude": 44.549025,
      "longitude": -75.204351,
      "city": "Hermon",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13654,
      "latitude": 44.616648,
      "longitude": -75.185085,
      "city": "Heuvelton",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13655,
      "latitude": 44.980232,
      "longitude": -74.647334,
      "city": "Hogansburg",
      "state": "NY",
      "county": "Franklin"
  },
  {
      "zipCode": 13656,
      "latitude": 44.200392,
      "longitude": -75.984109,
      "city": "La Fargeville",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13657,
      "latitude": 44.035703,
      "longitude": -76.090424,
      "city": "Limerick",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13658,
      "latitude": 44.516168,
      "longitude": -75.21642,
      "city": "Lisbon",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13659,
      "latitude": 43.75037,
      "longitude": -75.899974,
      "city": "Lorraine",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13660,
      "latitude": 44.770682,
      "longitude": -75.019312,
      "city": "Madrid",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13661,
      "latitude": 43.727352,
      "longitude": -76.090851,
      "city": "Mannsville",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13662,
      "latitude": 44.57026,
      "longitude": -75.152249,
      "city": "Massena",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13664,
      "latitude": 44.555889,
      "longitude": -75.655877,
      "city": "Morristown",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13665,
      "latitude": 44.054012,
      "longitude": -75.514487,
      "city": "Natural Bridge",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13666,
      "latitude": 44.533125,
      "longitude": -75.192865,
      "city": "Newton Falls",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13667,
      "latitude": 44.855075,
      "longitude": -74.942946,
      "city": "Norfolk",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13668,
      "latitude": 44.53517,
      "longitude": -75.099289,
      "city": "Norwood",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13669,
      "latitude": 44.509143,
      "longitude": -75.249795,
      "city": "Ogdensburg",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13670,
      "latitude": 44.216681,
      "longitude": -75.084323,
      "city": "Oswegatchie",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13671,
      "latitude": 44.308475,
      "longitude": -75.66879,
      "city": "Oxbow",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13672,
      "latitude": 44.544283,
      "longitude": -74.780387,
      "city": "Parishville",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13673,
      "latitude": 44.116323,
      "longitude": -75.719346,
      "city": "Philadelphia",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13674,
      "latitude": 43.73345,
      "longitude": -76.054313,
      "city": "Pierrepont Manor",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13675,
      "latitude": 44.283403,
      "longitude": -75.857026,
      "city": "Plessis",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13676,
      "latitude": 44.539717,
      "longitude": -75.142415,
      "city": "Potsdam",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13677,
      "latitude": 44.673438,
      "longitude": -75.082041,
      "city": "Pyrites",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13678,
      "latitude": 44.828685,
      "longitude": -74.979811,
      "city": "Raymondville",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13679,
      "latitude": 44.327083,
      "longitude": -75.80354,
      "city": "Redwood",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13680,
      "latitude": 44.452668,
      "longitude": -75.342977,
      "city": "Rensselaer Falls",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13681,
      "latitude": 44.439964,
      "longitude": -75.377707,
      "city": "Richville",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13682,
      "latitude": 43.856677,
      "longitude": -75.907485,
      "city": "Rodman",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13683,
      "latitude": 44.533125,
      "longitude": -75.192865,
      "city": "Rooseveltown",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13684,
      "latitude": 44.382351,
      "longitude": -75.104276,
      "city": "Russell",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13685,
      "latitude": 43.872933,
      "longitude": -76.092563,
      "city": "Sackets Harbor",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13687,
      "latitude": 44.64472,
      "longitude": -74.879155,
      "city": "South Colton",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13688,
      "latitude": 43.951666,
      "longitude": -75.767772,
      "city": "South Rutland",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13690,
      "latitude": 44.174001,
      "longitude": -74.991929,
      "city": "Star Lake",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13691,
      "latitude": 44.220448,
      "longitude": -75.767059,
      "city": "Theresa",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13692,
      "latitude": 44.289833,
      "longitude": -76.02621,
      "city": "Thousand Island Park",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13693,
      "latitude": 44.029787,
      "longitude": -76.222374,
      "city": "Three Mile Bay",
      "state": "NY",
      "county": "Jefferson"
  },
  {
      "zipCode": 13694,
      "latitude": 44.550617,
      "longitude": -75.194099,
      "city": "Waddington",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13695,
      "latitude": 44.140791,
      "longitude": -74.912493,
      "city": "Wanakena",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13696,
      "latitude": 44.69563,
      "longitude": -74.89998,
      "city": "West Stockholm",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13697,
      "latitude": 44.642889,
      "longitude": -74.840761,
      "city": "Winthrop",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13699,
      "latitude": 44.496564,
      "longitude": -75.072951,
      "city": "Potsdam",
      "state": "NY",
      "county": "Saint Lawrence"
  },
  {
      "zipCode": 13730,
      "latitude": 42.255147,
      "longitude": -75.533335,
      "city": "Afton",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13731,
      "latitude": 42.12923,
      "longitude": -74.843187,
      "city": "Andes",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13732,
      "latitude": 42.049798,
      "longitude": -76.181009,
      "city": "Apalachin",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13733,
      "latitude": 42.295949,
      "longitude": -75.507589,
      "city": "Bainbridge",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13734,
      "latitude": 42.12518,
      "longitude": -76.405626,
      "city": "Barton",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13736,
      "latitude": 42.294616,
      "longitude": -76.198223,
      "city": "Berkshire",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13737,
      "latitude": 42.080494,
      "longitude": -76.097288,
      "city": "Bible School Park",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13738,
      "latitude": 42.567313,
      "longitude": -76.123795,
      "city": "Blodgett Mills",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13739,
      "latitude": 42.340298,
      "longitude": -74.874437,
      "city": "Bloomville",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13740,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Bovina Center",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13743,
      "latitude": 42.226448,
      "longitude": -76.309863,
      "city": "Candor",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13744,
      "latitude": 42.243783,
      "longitude": -75.90689,
      "city": "Castle Creek",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13745,
      "latitude": 42.173775,
      "longitude": -75.872839,
      "city": "Chenango Bridge",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13746,
      "latitude": 42.277777,
      "longitude": -75.846246,
      "city": "Chenango Forks",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13747,
      "latitude": 42.505122,
      "longitude": -74.982056,
      "city": "Colliersville",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13748,
      "latitude": 42.108659,
      "longitude": -75.809834,
      "city": "Conklin",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13749,
      "latitude": 42.206745,
      "longitude": -75.74488,
      "city": "Corbettsville",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13750,
      "latitude": 42.444889,
      "longitude": -74.840217,
      "city": "Davenport",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13751,
      "latitude": 42.424859,
      "longitude": -74.900195,
      "city": "Davenport Center",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13752,
      "latitude": 42.220641,
      "longitude": -74.916308,
      "city": "De Lancey",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13753,
      "latitude": 42.293718,
      "longitude": -74.920671,
      "city": "Delhi",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13754,
      "latitude": 42.097004,
      "longitude": -75.713691,
      "city": "Deposit",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13755,
      "latitude": 42.035768,
      "longitude": -74.900852,
      "city": "Downsville",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13756,
      "latitude": 42.003935,
      "longitude": -75.122615,
      "city": "East Branch",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13757,
      "latitude": 42.350091,
      "longitude": -74.977712,
      "city": "East Meredith",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13758,
      "latitude": 42.583518,
      "longitude": -75.721931,
      "city": "East Pharsalia",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13760,
      "latitude": 42.150588,
      "longitude": -76.055064,
      "city": "Endicott",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13761,
      "latitude": 42.206745,
      "longitude": -75.74488,
      "city": "Endicott",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13762,
      "latitude": 42.206745,
      "longitude": -75.74488,
      "city": "Endwell",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13763,
      "latitude": 42.206745,
      "longitude": -75.74488,
      "city": "Endicott",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13774,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Fishs Eddy",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13775,
      "latitude": 42.32887,
      "longitude": -75.136628,
      "city": "Franklin",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13776,
      "latitude": 42.458584,
      "longitude": -75.311836,
      "city": "Gilbertsville",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13777,
      "latitude": 42.244157,
      "longitude": -75.989275,
      "city": "Glen Aubrey",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13778,
      "latitude": 42.34013,
      "longitude": -75.734191,
      "city": "Greene",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13780,
      "latitude": 42.365134,
      "longitude": -75.611685,
      "city": "Guilford",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13782,
      "latitude": 42.192781,
      "longitude": -75.012689,
      "city": "Hamden",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13783,
      "latitude": 42.115853,
      "longitude": -75.028814,
      "city": "Hancock",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13784,
      "latitude": 42.431068,
      "longitude": -76.159312,
      "city": "Harford",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13786,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Harpersfield",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13787,
      "latitude": 42.186306,
      "longitude": -75.67618,
      "city": "Harpursville",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13788,
      "latitude": 42.380427,
      "longitude": -74.711604,
      "city": "Hobart",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13790,
      "latitude": 42.159206,
      "longitude": -75.930931,
      "city": "Johnson City",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13794,
      "latitude": 42.402816,
      "longitude": -76.036582,
      "city": "Killawog",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13795,
      "latitude": 42.056172,
      "longitude": -75.788126,
      "city": "Kirkwood",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13796,
      "latitude": 42.555454,
      "longitude": -75.135511,
      "city": "Laurens",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13797,
      "latitude": 42.339355,
      "longitude": -76.045026,
      "city": "Lisle",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13801,
      "latitude": 42.49712,
      "longitude": -75.731309,
      "city": "Mc Donough",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13802,
      "latitude": 42.253754,
      "longitude": -76.046399,
      "city": "Maine",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13803,
      "latitude": 42.497925,
      "longitude": -76.047429,
      "city": "Marathon",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13804,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Masonville",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13806,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Meridale",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13807,
      "latitude": 42.601799,
      "longitude": -74.975717,
      "city": "Milford",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13808,
      "latitude": 42.531661,
      "longitude": -75.131023,
      "city": "Morris",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13809,
      "latitude": 42.400281,
      "longitude": -75.451576,
      "city": "Mount Upton",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13810,
      "latitude": 42.610748,
      "longitude": -75.102602,
      "city": "Mount Vision",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13811,
      "latitude": 42.170428,
      "longitude": -76.213825,
      "city": "Newark Valley",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13812,
      "latitude": 42.039954,
      "longitude": -76.370793,
      "city": "Nichols",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13813,
      "latitude": 42.147454,
      "longitude": -75.55656,
      "city": "Nineveh",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13814,
      "latitude": 42.603574,
      "longitude": -75.528221,
      "city": "North Norwich",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13815,
      "latitude": 42.536226,
      "longitude": -75.601779,
      "city": "Norwich",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13820,
      "latitude": 42.461688,
      "longitude": -75.096727,
      "city": "Oneonta",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13825,
      "latitude": 42.441165,
      "longitude": -75.148268,
      "city": "Otego",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13826,
      "latitude": 42.112127,
      "longitude": -75.639586,
      "city": "Ouaquaga",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13827,
      "latitude": 42.176816,
      "longitude": -76.2568,
      "city": "Owego",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13830,
      "latitude": 42.437881,
      "longitude": -75.567327,
      "city": "Oxford",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13832,
      "latitude": 42.568979,
      "longitude": -75.654133,
      "city": "Plymouth",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13833,
      "latitude": 42.195785,
      "longitude": -75.759054,
      "city": "Port Crane",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13834,
      "latitude": 42.539439,
      "longitude": -74.966985,
      "city": "Portlandville",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13835,
      "latitude": 42.371552,
      "longitude": -76.197488,
      "city": "Richford",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13837,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Shinhopple",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13838,
      "latitude": 42.308884,
      "longitude": -75.391644,
      "city": "Sidney",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13839,
      "latitude": 42.217118,
      "longitude": -75.232488,
      "city": "Sidney Center",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13840,
      "latitude": 42.039512,
      "longitude": -76.400376,
      "city": "Smithboro",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13841,
      "latitude": 42.461525,
      "longitude": -75.632735,
      "city": "Smithville Flats",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13842,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "South Kortright",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13843,
      "latitude": 42.542579,
      "longitude": -75.412243,
      "city": "South New Berlin",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13844,
      "latitude": 42.624833,
      "longitude": -75.652767,
      "city": "South Plymouth",
      "state": "NY",
      "county": "Chenango"
  },
  {
      "zipCode": 13845,
      "latitude": 42.065842,
      "longitude": -76.381946,
      "city": "Tioga Center",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13846,
      "latitude": 42.380509,
      "longitude": -75.059702,
      "city": "Treadwell",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13847,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "Trout Creek",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13848,
      "latitude": 42.214705,
      "longitude": -75.727699,
      "city": "Tunnel",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13849,
      "latitude": 42.382962,
      "longitude": -75.292321,
      "city": "Unadilla",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13850,
      "latitude": 42.055476,
      "longitude": -76.027088,
      "city": "Vestal",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13851,
      "latitude": 42.206745,
      "longitude": -75.74488,
      "city": "Vestal",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13856,
      "latitude": 42.250118,
      "longitude": -75.125556,
      "city": "Walton",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13859,
      "latitude": 42.379383,
      "longitude": -75.264556,
      "city": "Wells Bridge",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13860,
      "latitude": 42.183045,
      "longitude": -74.925617,
      "city": "West Davenport",
      "state": "NY",
      "county": "Delaware"
  },
  {
      "zipCode": 13861,
      "latitude": 42.501093,
      "longitude": -75.140874,
      "city": "West Oneonta",
      "state": "NY",
      "county": "Otsego"
  },
  {
      "zipCode": 13862,
      "latitude": 42.321825,
      "longitude": -75.945183,
      "city": "Whitney Point",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13863,
      "latitude": 42.449946,
      "longitude": -75.924813,
      "city": "Willet",
      "state": "NY",
      "county": "Cortland"
  },
  {
      "zipCode": 13864,
      "latitude": 42.186744,
      "longitude": -76.348074,
      "city": "Willseyville",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 13865,
      "latitude": 42.069103,
      "longitude": -75.644078,
      "city": "Windsor",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13901,
      "latitude": 42.165629,
      "longitude": -75.890685,
      "city": "Binghamton",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13902,
      "latitude": 42.105375,
      "longitude": -75.8876,
      "city": "Binghamton",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13903,
      "latitude": 42.083005,
      "longitude": -75.923248,
      "city": "Binghamton",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13904,
      "latitude": 42.131088,
      "longitude": -75.787801,
      "city": "Binghamton",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 13905,
      "latitude": 42.173709,
      "longitude": -75.922159,
      "city": "Binghamton",
      "state": "NY",
      "county": "Broome"
  },
  {
      "zipCode": 14001,
      "latitude": 43.013808,
      "longitude": -78.525296,
      "city": "Akron",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14003,
      "latitude": 42.998052,
      "longitude": -78.184813,
      "city": "Alabama",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14004,
      "latitude": 42.872937,
      "longitude": -78.528446,
      "city": "Alden",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14005,
      "latitude": 42.917622,
      "longitude": -78.264873,
      "city": "Alexander",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14006,
      "latitude": 42.823386,
      "longitude": -78.858355,
      "city": "Angola",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14008,
      "latitude": 43.291696,
      "longitude": -78.626944,
      "city": "Appleton",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14009,
      "latitude": 42.597171,
      "longitude": -78.37463,
      "city": "Arcade",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14010,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Athol Springs",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14011,
      "latitude": 42.699424,
      "longitude": -78.287793,
      "city": "Attica",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14012,
      "latitude": 43.327031,
      "longitude": -78.534963,
      "city": "Barker",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14013,
      "latitude": 43.077884,
      "longitude": -78.394594,
      "city": "Basom",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14020,
      "latitude": 42.980544,
      "longitude": -78.231764,
      "city": "Batavia",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14021,
      "latitude": 42.998052,
      "longitude": -78.184813,
      "city": "Batavia",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14024,
      "latitude": 42.590046,
      "longitude": -78.246616,
      "city": "Bliss",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14025,
      "latitude": 42.625391,
      "longitude": -78.726767,
      "city": "Boston",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14026,
      "latitude": 42.941008,
      "longitude": -78.688009,
      "city": "Bowmansville",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14027,
      "latitude": 42.57075,
      "longitude": -79.030785,
      "city": "Brant",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14028,
      "latitude": 43.313814,
      "longitude": -78.736907,
      "city": "Burt",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14029,
      "latitude": 42.474283,
      "longitude": -78.247413,
      "city": "Centerville",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14030,
      "latitude": 42.560669,
      "longitude": -78.522178,
      "city": "Chaffee",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14031,
      "latitude": 42.992968,
      "longitude": -78.601269,
      "city": "Clarence",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14032,
      "latitude": 42.937314,
      "longitude": -78.593381,
      "city": "Clarence Center",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14033,
      "latitude": 42.646441,
      "longitude": -78.666272,
      "city": "Colden",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14034,
      "latitude": 42.668773,
      "longitude": -78.816333,
      "city": "Collins",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14035,
      "latitude": 42.49064,
      "longitude": -78.849861,
      "city": "Collins Center",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14036,
      "latitude": 42.978522,
      "longitude": -78.372338,
      "city": "Corfu",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14037,
      "latitude": 42.807705,
      "longitude": -78.446855,
      "city": "Cowlesville",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14038,
      "latitude": 42.947259,
      "longitude": -78.474444,
      "city": "Crittenden",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14039,
      "latitude": 42.837456,
      "longitude": -78.1719,
      "city": "Dale",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14040,
      "latitude": 42.895989,
      "longitude": -78.385667,
      "city": "Darien Center",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14041,
      "latitude": 42.408583,
      "longitude": -78.984391,
      "city": "Dayton",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14042,
      "latitude": 42.473934,
      "longitude": -78.494065,
      "city": "Delevan",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14043,
      "latitude": 42.898958,
      "longitude": -78.707872,
      "city": "Depew",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14047,
      "latitude": 42.683522,
      "longitude": -78.995736,
      "city": "Derby",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14048,
      "latitude": 42.487716,
      "longitude": -79.328287,
      "city": "Dunkirk",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14051,
      "latitude": 43.042907,
      "longitude": -78.698796,
      "city": "East Amherst",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14052,
      "latitude": 42.768209,
      "longitude": -78.577136,
      "city": "East Aurora",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14054,
      "latitude": 42.917037,
      "longitude": -78.126525,
      "city": "East Bethany",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14055,
      "latitude": 42.559423,
      "longitude": -78.621851,
      "city": "East Concord",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14056,
      "latitude": 42.991173,
      "longitude": -78.312171,
      "city": "East Pembroke",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14057,
      "latitude": 42.641429,
      "longitude": -78.875456,
      "city": "Eden",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14058,
      "latitude": 43.088434,
      "longitude": -78.16261,
      "city": "Elba",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14059,
      "latitude": 42.828309,
      "longitude": -78.628966,
      "city": "Elma",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14060,
      "latitude": 42.445698,
      "longitude": -78.341227,
      "city": "Farmersville Station",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14061,
      "latitude": 42.594627,
      "longitude": -79.08404,
      "city": "Farnham",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14062,
      "latitude": 42.441146,
      "longitude": -79.172343,
      "city": "Forestville",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14063,
      "latitude": 42.412217,
      "longitude": -79.319254,
      "city": "Fredonia",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14065,
      "latitude": 42.475344,
      "longitude": -78.370334,
      "city": "Freedom",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14066,
      "latitude": 42.629014,
      "longitude": -78.19448,
      "city": "Gainesville",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14067,
      "latitude": 43.208166,
      "longitude": -78.56623,
      "city": "Gasport",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14068,
      "latitude": 43.026737,
      "longitude": -78.762454,
      "city": "Getzville",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14069,
      "latitude": 42.610187,
      "longitude": -78.639976,
      "city": "Glenwood",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14070,
      "latitude": 42.41994,
      "longitude": -78.954628,
      "city": "Gowanda",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14072,
      "latitude": 43.015256,
      "longitude": -78.958945,
      "city": "Grand Island",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14075,
      "latitude": 42.729083,
      "longitude": -78.837596,
      "city": "Hamburg",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14080,
      "latitude": 42.640179,
      "longitude": -78.543738,
      "city": "Holland",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14081,
      "latitude": 42.543516,
      "longitude": -79.101301,
      "city": "Irving",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14082,
      "latitude": 42.65161,
      "longitude": -78.387099,
      "city": "Java Center",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14083,
      "latitude": 42.675193,
      "longitude": -78.436442,
      "city": "Java Village",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14085,
      "latitude": 42.716055,
      "longitude": -78.929357,
      "city": "Lake View",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14086,
      "latitude": 42.907658,
      "longitude": -78.632137,
      "city": "Lancaster",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14091,
      "latitude": 42.54386,
      "longitude": -78.94475,
      "city": "Lawtons",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14092,
      "latitude": 43.170805,
      "longitude": -78.989515,
      "city": "Lewiston",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14094,
      "latitude": 43.168463,
      "longitude": -78.706886,
      "city": "Lockport",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14095,
      "latitude": 43.32684,
      "longitude": -78.830681,
      "city": "Lockport",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14098,
      "latitude": 43.324031,
      "longitude": -78.367151,
      "city": "Lyndonville",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14101,
      "latitude": 42.38917,
      "longitude": -78.554217,
      "city": "Machias",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14102,
      "latitude": 42.851099,
      "longitude": -78.547172,
      "city": "Marilla",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14103,
      "latitude": 43.239442,
      "longitude": -78.359871,
      "city": "Medina",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14105,
      "latitude": 43.196947,
      "longitude": -78.502849,
      "city": "Middleport",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14107,
      "latitude": 43.32684,
      "longitude": -78.830681,
      "city": "Model City",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14108,
      "latitude": 43.271156,
      "longitude": -78.724806,
      "city": "Newfane",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14109,
      "latitude": 43.32684,
      "longitude": -78.830681,
      "city": "Niagara University",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14110,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "North Boston",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14111,
      "latitude": 42.583165,
      "longitude": -78.91888,
      "city": "North Collins",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14112,
      "latitude": 42.691742,
      "longitude": -78.992658,
      "city": "North Evans",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14113,
      "latitude": 42.656199,
      "longitude": -78.340092,
      "city": "North Java",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14120,
      "latitude": 43.179206,
      "longitude": -78.808908,
      "city": "North Tonawanda",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14125,
      "latitude": 43.087438,
      "longitude": -78.269677,
      "city": "Oakfield",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14126,
      "latitude": 43.330443,
      "longitude": -78.726661,
      "city": "Olcott",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14127,
      "latitude": 42.742541,
      "longitude": -78.704437,
      "city": "Orchard Park",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14129,
      "latitude": 42.472671,
      "longitude": -79.007621,
      "city": "Perrysburg",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14130,
      "latitude": 42.543108,
      "longitude": -78.153849,
      "city": "Pike",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14131,
      "latitude": 43.233055,
      "longitude": -78.906562,
      "city": "Ransomville",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14132,
      "latitude": 43.153105,
      "longitude": -78.885611,
      "city": "Sanborn",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14133,
      "latitude": 42.489129,
      "longitude": -78.366991,
      "city": "Sandusky",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14134,
      "latitude": 42.532281,
      "longitude": -78.517233,
      "city": "Sardinia",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14135,
      "latitude": 42.488983,
      "longitude": -79.238997,
      "city": "Sheridan",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14136,
      "latitude": 42.520038,
      "longitude": -79.207843,
      "city": "Silver Creek",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14138,
      "latitude": 42.374077,
      "longitude": -78.94643,
      "city": "South Dayton",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14139,
      "latitude": 42.716759,
      "longitude": -78.543478,
      "city": "South Wales",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14140,
      "latitude": 42.807159,
      "longitude": -78.667599,
      "city": "Spring Brook",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14141,
      "latitude": 42.538335,
      "longitude": -78.685169,
      "city": "Springville",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14143,
      "latitude": 42.975195,
      "longitude": -78.069868,
      "city": "Stafford",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14144,
      "latitude": 43.199494,
      "longitude": -79.042477,
      "city": "Stella Niagara",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14145,
      "latitude": 42.733778,
      "longitude": -78.423303,
      "city": "Strykersville",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14150,
      "latitude": 42.99704,
      "longitude": -78.878659,
      "city": "Tonawanda",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14151,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Tonawanda",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14166,
      "latitude": 42.4511,
      "longitude": -79.415421,
      "city": "Van Buren Point",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14167,
      "latitude": 42.738977,
      "longitude": -78.312478,
      "city": "Varysburg",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14168,
      "latitude": 42.509271,
      "longitude": -78.999471,
      "city": "Versailles",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14169,
      "latitude": 42.767333,
      "longitude": -78.585646,
      "city": "Wales Center",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14170,
      "latitude": 42.702797,
      "longitude": -78.670098,
      "city": "West Falls",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14171,
      "latitude": 42.406409,
      "longitude": -78.639581,
      "city": "West Valley",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14172,
      "latitude": 43.272729,
      "longitude": -78.83101,
      "city": "Wilson",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14173,
      "latitude": 42.524691,
      "longitude": -78.475474,
      "city": "Yorkshire",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14174,
      "latitude": 43.244454,
      "longitude": -78.988325,
      "city": "Youngstown",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14201,
      "latitude": 42.929303,
      "longitude": -78.832706,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14202,
      "latitude": 42.889662,
      "longitude": -78.884157,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14203,
      "latitude": 42.868057,
      "longitude": -78.867906,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14204,
      "latitude": 42.882957,
      "longitude": -78.864898,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14205,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14206,
      "latitude": 42.881012,
      "longitude": -78.814555,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14207,
      "latitude": 42.949557,
      "longitude": -78.897009,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14208,
      "latitude": 42.916107,
      "longitude": -78.858157,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14209,
      "latitude": 42.918107,
      "longitude": -78.865857,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14210,
      "latitude": 42.863608,
      "longitude": -78.825755,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14211,
      "latitude": 42.912257,
      "longitude": -78.819005,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14212,
      "latitude": 42.894558,
      "longitude": -78.818805,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14213,
      "latitude": 42.918057,
      "longitude": -78.891108,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14214,
      "latitude": 42.940107,
      "longitude": -78.835256,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14215,
      "latitude": 42.932957,
      "longitude": -78.812252,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14216,
      "latitude": 42.946407,
      "longitude": -78.860407,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14217,
      "latitude": 42.900044,
      "longitude": -78.808205,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14218,
      "latitude": 42.814558,
      "longitude": -78.807754,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14219,
      "latitude": 42.786308,
      "longitude": -78.826444,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14220,
      "latitude": 42.845908,
      "longitude": -78.823005,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14221,
      "latitude": 42.968507,
      "longitude": -78.749153,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14222,
      "latitude": 42.918207,
      "longitude": -78.875007,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14223,
      "latitude": 42.974607,
      "longitude": -78.850507,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14224,
      "latitude": 42.837128,
      "longitude": -78.748352,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14225,
      "latitude": 42.925458,
      "longitude": -78.748145,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14226,
      "latitude": 42.974389,
      "longitude": -78.794905,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14227,
      "latitude": 42.885308,
      "longitude": -78.746202,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14228,
      "latitude": 43.026016,
      "longitude": -78.766073,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14231,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14233,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14240,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14241,
      "latitude": 42.938258,
      "longitude": -78.744053,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14260,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14261,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14263,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14264,
      "latitude": 42.885568,
      "longitude": -78.873479,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14265,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14267,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14269,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14270,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14272,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14273,
      "latitude": 42.75495,
      "longitude": -78.784908,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14276,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14280,
      "latitude": 42.768413,
      "longitude": -78.887094,
      "city": "Buffalo",
      "state": "NY",
      "county": "Erie"
  },
  {
      "zipCode": 14301,
      "latitude": 43.089805,
      "longitude": -79.009414,
      "city": "Niagara Falls",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14302,
      "latitude": 43.32684,
      "longitude": -78.830681,
      "city": "Niagara Falls",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14303,
      "latitude": 43.086455,
      "longitude": -79.037965,
      "city": "Niagara Falls",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14304,
      "latitude": 43.100305,
      "longitude": -78.952112,
      "city": "Niagara Falls",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14305,
      "latitude": 43.117255,
      "longitude": -79.018415,
      "city": "Niagara Falls",
      "state": "NY",
      "county": "Niagara"
  },
  {
      "zipCode": 14410,
      "latitude": 43.195261,
      "longitude": -77.855883,
      "city": "Adams Basin",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14411,
      "latitude": 43.235143,
      "longitude": -78.194251,
      "city": "Albion",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14413,
      "latitude": 43.222735,
      "longitude": -76.982149,
      "city": "Alton",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14414,
      "latitude": 42.895631,
      "longitude": -77.735104,
      "city": "Avon",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14415,
      "latitude": 42.754115,
      "longitude": -77.026379,
      "city": "Bellona",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14416,
      "latitude": 43.073792,
      "longitude": -78.019684,
      "city": "Bergen",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14418,
      "latitude": 42.622634,
      "longitude": -77.227187,
      "city": "Branchport",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14420,
      "latitude": 43.223303,
      "longitude": -77.917335,
      "city": "Brockport",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14422,
      "latitude": 43.073142,
      "longitude": -78.056938,
      "city": "Byron",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14423,
      "latitude": 42.935329,
      "longitude": -77.838998,
      "city": "Caledonia",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14424,
      "latitude": 42.813576,
      "longitude": -77.289687,
      "city": "Canandaigua",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14425,
      "latitude": 42.958041,
      "longitude": -77.308256,
      "city": "Farmington",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14427,
      "latitude": 42.62674,
      "longitude": -78.059147,
      "city": "Castile",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14428,
      "latitude": 43.074911,
      "longitude": -77.835003,
      "city": "Churchville",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14429,
      "latitude": 43.381027,
      "longitude": -78.231338,
      "city": "Clarendon",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14430,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Clarkson",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14432,
      "latitude": 42.962932,
      "longitude": -77.150097,
      "city": "Clifton Springs",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14433,
      "latitude": 43.097869,
      "longitude": -76.872736,
      "city": "Clyde",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14435,
      "latitude": 42.723231,
      "longitude": -77.668291,
      "city": "Conesus",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14437,
      "latitude": 42.61837,
      "longitude": -77.737515,
      "city": "Dansville",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14441,
      "latitude": 42.722446,
      "longitude": -76.963823,
      "city": "Dresden",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14443,
      "latitude": 42.900966,
      "longitude": -77.42331,
      "city": "East Bloomfield",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14445,
      "latitude": 43.113563,
      "longitude": -77.482538,
      "city": "East Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14449,
      "latitude": 43.235369,
      "longitude": -77.137642,
      "city": "East Williamson",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14450,
      "latitude": 43.104287,
      "longitude": -77.428964,
      "city": "Fairport",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14452,
      "latitude": 43.381027,
      "longitude": -78.231338,
      "city": "Fancher",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14453,
      "latitude": 43.010908,
      "longitude": -77.47054,
      "city": "Fishers",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14454,
      "latitude": 42.783801,
      "longitude": -77.777872,
      "city": "Geneseo",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14456,
      "latitude": 42.857414,
      "longitude": -77.034883,
      "city": "Geneva",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14461,
      "latitude": 42.808127,
      "longitude": -77.287611,
      "city": "Gorham",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14462,
      "latitude": 42.694819,
      "longitude": -77.754628,
      "city": "Groveland",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14463,
      "latitude": 42.796578,
      "longitude": -77.063876,
      "city": "Hall",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14464,
      "latitude": 43.321659,
      "longitude": -77.925097,
      "city": "Hamlin",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14466,
      "latitude": 42.78481,
      "longitude": -77.618049,
      "city": "Hemlock",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14467,
      "latitude": 43.058863,
      "longitude": -77.558902,
      "city": "Henrietta",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14468,
      "latitude": 43.28521,
      "longitude": -77.832333,
      "city": "Hilton",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14469,
      "latitude": 42.869556,
      "longitude": -77.465843,
      "city": "Bloomfield",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14470,
      "latitude": 43.21586,
      "longitude": -78.073057,
      "city": "Holley",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14471,
      "latitude": 42.755573,
      "longitude": -77.491905,
      "city": "Honeoye",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14472,
      "latitude": 42.982513,
      "longitude": -77.606771,
      "city": "Honeoye Falls",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14475,
      "latitude": 42.935868,
      "longitude": -77.498231,
      "city": "Ionia",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14476,
      "latitude": 43.324058,
      "longitude": -78.049928,
      "city": "Kendall",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14477,
      "latitude": 43.330763,
      "longitude": -78.123328,
      "city": "Kent",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14478,
      "latitude": 42.570794,
      "longitude": -77.12259,
      "city": "Keuka Park",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14479,
      "latitude": 43.241502,
      "longitude": -78.318828,
      "city": "Knowlesville",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14480,
      "latitude": 42.837288,
      "longitude": -77.703091,
      "city": "Lakeville",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14481,
      "latitude": 42.760644,
      "longitude": -77.90355,
      "city": "Leicester",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14482,
      "latitude": 42.95619,
      "longitude": -77.989988,
      "city": "Le Roy",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14485,
      "latitude": 42.889864,
      "longitude": -77.608688,
      "city": "Lima",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14486,
      "latitude": 42.915297,
      "longitude": -77.949818,
      "city": "Linwood",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14487,
      "latitude": 42.809457,
      "longitude": -77.650938,
      "city": "Livonia",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14488,
      "latitude": 42.729839,
      "longitude": -77.773919,
      "city": "Livonia Center",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14489,
      "latitude": 43.099263,
      "longitude": -76.970937,
      "city": "Lyons",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14502,
      "latitude": 43.110807,
      "longitude": -77.31345,
      "city": "Macedon",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14504,
      "latitude": 42.968903,
      "longitude": -77.233194,
      "city": "Manchester",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14505,
      "latitude": 43.15594,
      "longitude": -77.172684,
      "city": "Marion",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14506,
      "latitude": 43.001323,
      "longitude": -77.504268,
      "city": "Mendon",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14507,
      "latitude": 42.686845,
      "longitude": -77.262987,
      "city": "Middlesex",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14508,
      "latitude": 43.381027,
      "longitude": -78.231338,
      "city": "Morton",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14510,
      "latitude": 42.683466,
      "longitude": -77.866402,
      "city": "Mount Morris",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14511,
      "latitude": 43.002612,
      "longitude": -77.86458,
      "city": "Mumford",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14512,
      "latitude": 42.667802,
      "longitude": -77.413496,
      "city": "Naples",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14513,
      "latitude": 43.087067,
      "longitude": -77.097867,
      "city": "Newark",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14514,
      "latitude": 43.085778,
      "longitude": -77.805628,
      "city": "North Chili",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14515,
      "latitude": 43.257819,
      "longitude": -77.735079,
      "city": "North Greece",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14516,
      "latitude": 43.195842,
      "longitude": -76.908805,
      "city": "North Rose",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14517,
      "latitude": 42.591883,
      "longitude": -77.894474,
      "city": "Nunda",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14518,
      "latitude": 42.931873,
      "longitude": -77.011839,
      "city": "Oaks Corners",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14519,
      "latitude": 43.221476,
      "longitude": -77.308614,
      "city": "Ontario",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14520,
      "latitude": 43.348369,
      "longitude": -77.045288,
      "city": "Ontario Center",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14521,
      "latitude": 42.689791,
      "longitude": -76.794102,
      "city": "Ovid",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 14522,
      "latitude": 43.081306,
      "longitude": -77.218849,
      "city": "Palmyra",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14525,
      "latitude": 42.910203,
      "longitude": -78.026899,
      "city": "Pavilion",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14526,
      "latitude": 43.145813,
      "longitude": -77.454446,
      "city": "Penfield",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14527,
      "latitude": 42.637423,
      "longitude": -77.069925,
      "city": "Penn Yan",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14529,
      "latitude": 42.535363,
      "longitude": -77.628857,
      "city": "Perkinsville",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14530,
      "latitude": 42.682391,
      "longitude": -78.056235,
      "city": "Perry",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14532,
      "latitude": 42.963394,
      "longitude": -77.043612,
      "city": "Phelps",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14533,
      "latitude": 42.843464,
      "longitude": -77.896153,
      "city": "Piffard",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14534,
      "latitude": 43.060229,
      "longitude": -77.520692,
      "city": "Pittsford",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14536,
      "latitude": 42.555988,
      "longitude": -78.098906,
      "city": "Portageville",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14537,
      "latitude": 43.033002,
      "longitude": -77.157465,
      "city": "Port Gibson",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14538,
      "latitude": 43.283582,
      "longitude": -77.142027,
      "city": "Pultneyville",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14539,
      "latitude": 42.834327,
      "longitude": -77.877876,
      "city": "Retsof",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14541,
      "latitude": 42.749713,
      "longitude": -76.844921,
      "city": "Romulus",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 14542,
      "latitude": 43.144795,
      "longitude": -76.860779,
      "city": "Rose",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14543,
      "latitude": 42.996613,
      "longitude": -77.666524,
      "city": "Rush",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14544,
      "latitude": 42.734157,
      "longitude": -77.242693,
      "city": "Rushville",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14545,
      "latitude": 42.652111,
      "longitude": -77.710539,
      "city": "Scottsburg",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14546,
      "latitude": 43.04471,
      "longitude": -77.772652,
      "city": "Scottsville",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14547,
      "latitude": 42.808127,
      "longitude": -77.287611,
      "city": "Seneca Castle",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14548,
      "latitude": 42.978834,
      "longitude": -77.252696,
      "city": "Shortsville",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14549,
      "latitude": 42.692874,
      "longitude": -78.022364,
      "city": "Silver Lake",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14550,
      "latitude": 42.674209,
      "longitude": -78.084485,
      "city": "Silver Springs",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14551,
      "latitude": 43.210384,
      "longitude": -77.053517,
      "city": "Sodus",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14555,
      "latitude": 43.254644,
      "longitude": -76.983518,
      "city": "Sodus Point",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14556,
      "latitude": 42.729839,
      "longitude": -77.773919,
      "city": "Sonyea",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14557,
      "latitude": 43.041606,
      "longitude": -78.057252,
      "city": "South Byron",
      "state": "NY",
      "county": "Genesee"
  },
  {
      "zipCode": 14558,
      "latitude": 42.855409,
      "longitude": -77.687643,
      "city": "South Lima",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14559,
      "latitude": 43.185709,
      "longitude": -77.830577,
      "city": "Spencerport",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14560,
      "latitude": 42.635525,
      "longitude": -77.594478,
      "city": "Springwater",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14561,
      "latitude": 42.83336,
      "longitude": -77.129398,
      "city": "Stanley",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14563,
      "latitude": 43.348369,
      "longitude": -77.045288,
      "city": "Union Hill",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14564,
      "latitude": 42.973715,
      "longitude": -77.426906,
      "city": "Victor",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14568,
      "latitude": 43.142795,
      "longitude": -77.287414,
      "city": "Walworth",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14569,
      "latitude": 42.740223,
      "longitude": -78.164804,
      "city": "Warsaw",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14571,
      "latitude": 43.328706,
      "longitude": -78.238501,
      "city": "Waterport",
      "state": "NY",
      "county": "Orleans"
  },
  {
      "zipCode": 14572,
      "latitude": 42.516793,
      "longitude": -77.564247,
      "city": "Wayland",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14580,
      "latitude": 43.21821,
      "longitude": -77.454075,
      "city": "Webster",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14585,
      "latitude": 42.905459,
      "longitude": -77.548423,
      "city": "West Bloomfield",
      "state": "NY",
      "county": "Ontario"
  },
  {
      "zipCode": 14586,
      "latitude": 43.041553,
      "longitude": -77.683575,
      "city": "West Henrietta",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14588,
      "latitude": 42.683451,
      "longitude": -76.872442,
      "city": "Willard",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 14589,
      "latitude": 43.23941,
      "longitude": -77.163811,
      "city": "Williamson",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14590,
      "latitude": 43.224693,
      "longitude": -76.840547,
      "city": "Wolcott",
      "state": "NY",
      "county": "Wayne"
  },
  {
      "zipCode": 14591,
      "latitude": 42.818578,
      "longitude": -78.102549,
      "city": "Wyoming",
      "state": "NY",
      "county": "Wyoming"
  },
  {
      "zipCode": 14592,
      "latitude": 42.875685,
      "longitude": -77.883511,
      "city": "York",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14601,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14602,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14603,
      "latitude": 43.161613,
      "longitude": -77.606774,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14604,
      "latitude": 43.156612,
      "longitude": -77.604023,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14605,
      "latitude": 43.166313,
      "longitude": -77.602973,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14606,
      "latitude": 43.173612,
      "longitude": -77.691577,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14607,
      "latitude": 43.152013,
      "longitude": -77.584888,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14608,
      "latitude": 43.154611,
      "longitude": -77.624001,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14609,
      "latitude": 43.198197,
      "longitude": -77.549971,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14610,
      "latitude": 43.140163,
      "longitude": -77.557871,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14611,
      "latitude": 43.140163,
      "longitude": -77.650275,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14612,
      "latitude": 43.257111,
      "longitude": -77.67824,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14613,
      "latitude": 43.13378,
      "longitude": -77.519325,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14614,
      "latitude": 43.104463,
      "longitude": -77.512569,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14615,
      "latitude": 43.22252,
      "longitude": -77.552021,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14616,
      "latitude": 43.228955,
      "longitude": -77.680927,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14617,
      "latitude": 43.207062,
      "longitude": -77.609425,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14618,
      "latitude": 43.112163,
      "longitude": -77.561771,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14619,
      "latitude": 43.135688,
      "longitude": -77.649126,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14620,
      "latitude": 43.129862,
      "longitude": -77.618523,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14621,
      "latitude": 43.149013,
      "longitude": -77.631124,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14622,
      "latitude": 43.199613,
      "longitude": -77.595823,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14623,
      "latitude": 43.082184,
      "longitude": -77.649074,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14624,
      "latitude": 43.121612,
      "longitude": -77.731127,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14625,
      "latitude": 43.152213,
      "longitude": -77.505705,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14626,
      "latitude": 43.190312,
      "longitude": -77.720018,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14627,
      "latitude": 43.127513,
      "longitude": -77.627674,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14638,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14639,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14642,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14643,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14644,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14645,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14646,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14647,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14649,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14650,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14651,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14652,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14653,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14660,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14664,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14673,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14683,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14692,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14694,
      "latitude": 43.286024,
      "longitude": -77.684264,
      "city": "Rochester",
      "state": "NY",
      "county": "Monroe"
  },
  {
      "zipCode": 14701,
      "latitude": 42.196125,
      "longitude": -79.432256,
      "city": "Jamestown",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14702,
      "latitude": 42.081657,
      "longitude": -79.294921,
      "city": "Jamestown",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14703,
      "latitude": 42.342529,
      "longitude": -79.41091,
      "city": "Jamestown",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14704,
      "latitude": 42.342529,
      "longitude": -79.41091,
      "city": "Jamestown",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14706,
      "latitude": 42.116168,
      "longitude": -78.516218,
      "city": "Allegany",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14707,
      "latitude": 42.073739,
      "longitude": -78.059374,
      "city": "Allentown",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14708,
      "latitude": 42.025431,
      "longitude": -78.084837,
      "city": "Alma",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14709,
      "latitude": 42.217609,
      "longitude": -78.090559,
      "city": "Angelica",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14710,
      "latitude": 42.084481,
      "longitude": -79.391738,
      "city": "Ashville",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14711,
      "latitude": 42.320366,
      "longitude": -78.12964,
      "city": "Belfast",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14712,
      "latitude": 42.164572,
      "longitude": -79.445292,
      "city": "Bemus Point",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14714,
      "latitude": 42.290569,
      "longitude": -78.237314,
      "city": "Black Creek",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14715,
      "latitude": 42.154417,
      "longitude": -78.139391,
      "city": "Bolivar",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14716,
      "latitude": 42.316748,
      "longitude": -79.432622,
      "city": "Brocton",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14717,
      "latitude": 42.257674,
      "longitude": -78.174601,
      "city": "Caneadea",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14718,
      "latitude": 42.250021,
      "longitude": -79.296028,
      "city": "Cassadaga",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14719,
      "latitude": 42.343336,
      "longitude": -78.869752,
      "city": "Cattaraugus",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14720,
      "latitude": 42.105907,
      "longitude": -79.27912,
      "city": "Celoron",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14721,
      "latitude": 42.013691,
      "longitude": -78.264775,
      "city": "Ceres",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14722,
      "latitude": 42.187468,
      "longitude": -79.450523,
      "city": "Chautauqua",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14723,
      "latitude": 42.308946,
      "longitude": -79.387594,
      "city": "Cherry Creek",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14724,
      "latitude": 42.07268,
      "longitude": -79.644932,
      "city": "Clymer",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14726,
      "latitude": 42.252473,
      "longitude": -79.008294,
      "city": "Conewango Valley",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14727,
      "latitude": 42.245997,
      "longitude": -78.209403,
      "city": "Cuba",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14728,
      "latitude": 42.2828,
      "longitude": -79.366516,
      "city": "Dewittville",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14729,
      "latitude": 42.405354,
      "longitude": -78.735158,
      "city": "East Otto",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14730,
      "latitude": 42.174736,
      "longitude": -78.94734,
      "city": "East Randolph",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14731,
      "latitude": 42.297229,
      "longitude": -78.649654,
      "city": "Ellicottville",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14732,
      "latitude": 42.229098,
      "longitude": -79.11347,
      "city": "Ellington",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14733,
      "latitude": 42.126604,
      "longitude": -79.182837,
      "city": "Falconer",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14735,
      "latitude": 42.45079,
      "longitude": -78.104285,
      "city": "Fillmore",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14736,
      "latitude": 42.133206,
      "longitude": -79.737701,
      "city": "Findley Lake",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14737,
      "latitude": 42.335753,
      "longitude": -78.491244,
      "city": "Franklinville",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14738,
      "latitude": 42.053586,
      "longitude": -79.118889,
      "city": "Frewsburg",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14739,
      "latitude": 42.187901,
      "longitude": -78.157246,
      "city": "Friendship",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14740,
      "latitude": 42.21129,
      "longitude": -79.170519,
      "city": "Gerry",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14741,
      "latitude": 42.21704,
      "longitude": -78.584117,
      "city": "Great Valley",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14742,
      "latitude": 42.120512,
      "longitude": -79.30958,
      "city": "Greenhurst",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14743,
      "latitude": 42.197916,
      "longitude": -78.415859,
      "city": "Hinsdale",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14744,
      "latitude": 42.422827,
      "longitude": -78.206289,
      "city": "Houghton",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14745,
      "latitude": 42.4777,
      "longitude": -78.140343,
      "city": "Hume",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14747,
      "latitude": 42.152725,
      "longitude": -79.111065,
      "city": "Kennedy",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14748,
      "latitude": 42.144977,
      "longitude": -78.646626,
      "city": "Kill Buck",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14750,
      "latitude": 42.12481,
      "longitude": -79.440009,
      "city": "Lakewood",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14751,
      "latitude": 42.298328,
      "longitude": -79.006231,
      "city": "Leon",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14752,
      "latitude": 42.352416,
      "longitude": -79.323502,
      "city": "Lily Dale",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14753,
      "latitude": 42.141266,
      "longitude": -78.607674,
      "city": "Limestone",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14754,
      "latitude": 42.031872,
      "longitude": -78.209708,
      "city": "Little Genesee",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14755,
      "latitude": 42.254567,
      "longitude": -78.823628,
      "city": "Little Valley",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14756,
      "latitude": 42.19815,
      "longitude": -79.417353,
      "city": "Maple Springs",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14757,
      "latitude": 42.23716,
      "longitude": -79.370911,
      "city": "Mayville",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14758,
      "latitude": 42.342529,
      "longitude": -79.41091,
      "city": "Niobe",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14760,
      "latitude": 42.082142,
      "longitude": -78.425973,
      "city": "Olean",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14766,
      "latitude": 42.357543,
      "longitude": -78.807283,
      "city": "Otto",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14767,
      "latitude": 42.191346,
      "longitude": -79.505101,
      "city": "Panama",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14769,
      "latitude": 42.371561,
      "longitude": -79.468963,
      "city": "Portland",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14770,
      "latitude": 42.092944,
      "longitude": -78.342341,
      "city": "Portville",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14772,
      "latitude": 42.156841,
      "longitude": -78.844412,
      "city": "Randolph",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14774,
      "latitude": 42.114385,
      "longitude": -78.168055,
      "city": "Richburg",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14775,
      "latitude": 42.230461,
      "longitude": -79.42678,
      "city": "Ripley",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14777,
      "latitude": 42.397363,
      "longitude": -78.23233,
      "city": "Rushford",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14778,
      "latitude": 42.270112,
      "longitude": -78.684684,
      "city": "Saint Bonaventure",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14779,
      "latitude": 42.180526,
      "longitude": -78.615625,
      "city": "Salamanca",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14781,
      "latitude": 42.172076,
      "longitude": -79.611348,
      "city": "Sherman",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14782,
      "latitude": 42.257762,
      "longitude": -79.253829,
      "city": "Sinclairville",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14783,
      "latitude": 42.082001,
      "longitude": -78.917738,
      "city": "Steamburg",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14784,
      "latitude": 42.31514,
      "longitude": -79.38833,
      "city": "Stockton",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14785,
      "latitude": 42.155693,
      "longitude": -79.412233,
      "city": "Stow",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14786,
      "latitude": 42.12267,
      "longitude": -78.221332,
      "city": "West Clarksville",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14787,
      "latitude": 42.253734,
      "longitude": -79.484286,
      "city": "Westfield",
      "state": "NY",
      "county": "Chautauqua"
  },
  {
      "zipCode": 14788,
      "latitude": 42.062125,
      "longitude": -78.377974,
      "city": "Westons Mills",
      "state": "NY",
      "county": "Cattaraugus"
  },
  {
      "zipCode": 14801,
      "latitude": 42.206391,
      "longitude": -77.306185,
      "city": "Addison",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14802,
      "latitude": 42.240535,
      "longitude": -77.783192,
      "city": "Alfred",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14803,
      "latitude": 42.254097,
      "longitude": -77.799317,
      "city": "Alfred Station",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14804,
      "latitude": 42.313285,
      "longitude": -77.851286,
      "city": "Almond",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14805,
      "latitude": 42.375566,
      "longitude": -76.732706,
      "city": "Alpine",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14806,
      "latitude": 42.145794,
      "longitude": -77.811901,
      "city": "Andover",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14807,
      "latitude": 42.465145,
      "longitude": -77.57387,
      "city": "Arkport",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14808,
      "latitude": 42.562012,
      "longitude": -77.468518,
      "city": "Atlanta",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14809,
      "latitude": 42.367861,
      "longitude": -77.464128,
      "city": "Avoca",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14810,
      "latitude": 42.357464,
      "longitude": -77.302789,
      "city": "Bath",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14812,
      "latitude": 42.322924,
      "longitude": -76.977336,
      "city": "Beaver Dams",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14813,
      "latitude": 42.242168,
      "longitude": -77.963148,
      "city": "Belmont",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14814,
      "latitude": 42.156516,
      "longitude": -76.93164,
      "city": "Big Flats",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14815,
      "latitude": 42.369808,
      "longitude": -77.235796,
      "city": "Bradford",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14816,
      "latitude": 42.199631,
      "longitude": -76.7312,
      "city": "Breesport",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14817,
      "latitude": 42.359694,
      "longitude": -76.349154,
      "city": "Brooktondale",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14818,
      "latitude": 42.464152,
      "longitude": -76.798018,
      "city": "Burdett",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14819,
      "latitude": 42.211491,
      "longitude": -77.453509,
      "city": "Cameron",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14820,
      "latitude": 42.180046,
      "longitude": -77.280502,
      "city": "Cameron Mills",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14821,
      "latitude": 42.262954,
      "longitude": -77.215478,
      "city": "Campbell",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14822,
      "latitude": 42.422577,
      "longitude": -77.835626,
      "city": "Canaseraga",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14823,
      "latitude": 42.348146,
      "longitude": -77.42713,
      "city": "Canisteo",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14824,
      "latitude": 42.272948,
      "longitude": -76.693628,
      "city": "Cayuta",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14825,
      "latitude": 42.052426,
      "longitude": -76.607029,
      "city": "Chemung",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14826,
      "latitude": 42.470356,
      "longitude": -77.391286,
      "city": "Cohocton",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14827,
      "latitude": 42.178527,
      "longitude": -77.141357,
      "city": "Coopers Plains",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14830,
      "latitude": 42.120541,
      "longitude": -77.048462,
      "city": "Corning",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14831,
      "latitude": 42.145265,
      "longitude": -77.56683,
      "city": "Corning",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14836,
      "latitude": 42.546164,
      "longitude": -77.90021,
      "city": "Dalton",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14837,
      "latitude": 42.555387,
      "longitude": -77.014409,
      "city": "Dundee",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14838,
      "latitude": 42.181224,
      "longitude": -76.671949,
      "city": "Erin",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14839,
      "latitude": 42.143242,
      "longitude": -77.562991,
      "city": "Greenwood",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14840,
      "latitude": 42.322455,
      "longitude": -77.198396,
      "city": "Hammondsport",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14841,
      "latitude": 42.492291,
      "longitude": -76.824556,
      "city": "Hector",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14842,
      "latitude": 42.577714,
      "longitude": -76.96452,
      "city": "Himrod",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14843,
      "latitude": 42.383978,
      "longitude": -77.479051,
      "city": "Hornell",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14844,
      "latitude": 42.160717,
      "longitude": -76.878456,
      "city": "Horseheads",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14845,
      "latitude": 42.21003,
      "longitude": -76.836629,
      "city": "Horseheads",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14846,
      "latitude": 42.555834,
      "longitude": -77.999451,
      "city": "Hunt",
      "state": "NY",
      "county": "Livingston"
  },
  {
      "zipCode": 14847,
      "latitude": 42.60844,
      "longitude": -76.735556,
      "city": "Interlaken",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 14850,
      "latitude": 42.402794,
      "longitude": -76.483999,
      "city": "Ithaca",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14851,
      "latitude": 42.460721,
      "longitude": -76.505435,
      "city": "Ithaca",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14852,
      "latitude": 42.445074,
      "longitude": -76.467233,
      "city": "Ithaca",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14853,
      "latitude": 42.447373,
      "longitude": -76.483703,
      "city": "Ithaca",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14854,
      "latitude": 42.50632,
      "longitude": -76.608222,
      "city": "Jacksonville",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14855,
      "latitude": 42.142197,
      "longitude": -77.497908,
      "city": "Jasper",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14856,
      "latitude": 42.374218,
      "longitude": -77.364812,
      "city": "Kanona",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14857,
      "latitude": 42.512632,
      "longitude": -76.927019,
      "city": "Lakemont",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14858,
      "latitude": 42.04507,
      "longitude": -77.106679,
      "city": "Lindley",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14859,
      "latitude": 42.115457,
      "longitude": -76.507823,
      "city": "Lockwood",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 14860,
      "latitude": 42.586029,
      "longitude": -76.801671,
      "city": "Lodi",
      "state": "NY",
      "county": "Seneca"
  },
  {
      "zipCode": 14861,
      "latitude": 42.087671,
      "longitude": -76.684615,
      "city": "Lowman",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14863,
      "latitude": 42.451569,
      "longitude": -76.706717,
      "city": "Mecklenburg",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14864,
      "latitude": 42.259644,
      "longitude": -76.842298,
      "city": "Millport",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14865,
      "latitude": 42.358866,
      "longitude": -76.841852,
      "city": "Montour Falls",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14867,
      "latitude": 42.365688,
      "longitude": -76.610212,
      "city": "Newfield",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14869,
      "latitude": 42.377454,
      "longitude": -76.77621,
      "city": "Odessa",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14870,
      "latitude": 42.159759,
      "longitude": -77.382462,
      "city": "Painted Post",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14871,
      "latitude": 42.047024,
      "longitude": -76.882455,
      "city": "Pine City",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14872,
      "latitude": 42.240529,
      "longitude": -76.875072,
      "city": "Pine Valley",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14873,
      "latitude": 42.38056,
      "longitude": -77.354408,
      "city": "Prattsburgh",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14874,
      "latitude": 42.520261,
      "longitude": -77.197141,
      "city": "Pulteney",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14876,
      "latitude": 42.429721,
      "longitude": -76.925846,
      "city": "Reading Center",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14877,
      "latitude": 42.071887,
      "longitude": -77.677653,
      "city": "Rexville",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14878,
      "latitude": 42.48015,
      "longitude": -76.946931,
      "city": "Rock Stream",
      "state": "NY",
      "county": "Yates"
  },
  {
      "zipCode": 14879,
      "latitude": 42.327262,
      "longitude": -77.193204,
      "city": "Savona",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14880,
      "latitude": 42.16615,
      "longitude": -77.965672,
      "city": "Scio",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14881,
      "latitude": 42.402463,
      "longitude": -76.360782,
      "city": "Slaterville Springs",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14882,
      "latitude": 42.573453,
      "longitude": -76.552378,
      "city": "Lansing",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14883,
      "latitude": 42.203102,
      "longitude": -76.471165,
      "city": "Spencer",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 14884,
      "latitude": 42.455859,
      "longitude": -77.889425,
      "city": "Swain",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14885,
      "latitude": 42.060366,
      "longitude": -77.562142,
      "city": "Troupsburg",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14886,
      "latitude": 42.4803,
      "longitude": -76.635158,
      "city": "Trumansburg",
      "state": "NY",
      "county": "Tompkins"
  },
  {
      "zipCode": 14887,
      "latitude": 42.399371,
      "longitude": -77.026768,
      "city": "Tyrone",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14889,
      "latitude": 42.210223,
      "longitude": -76.585084,
      "city": "Van Etten",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14891,
      "latitude": 42.37285,
      "longitude": -76.927135,
      "city": "Watkins Glen",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14892,
      "latitude": 42.107825,
      "longitude": -76.502218,
      "city": "Waverly",
      "state": "NY",
      "county": "Tioga"
  },
  {
      "zipCode": 14893,
      "latitude": 42.474143,
      "longitude": -77.097713,
      "city": "Wayne",
      "state": "NY",
      "county": "Schuyler"
  },
  {
      "zipCode": 14894,
      "latitude": 42.027102,
      "longitude": -76.764516,
      "city": "Wellsburg",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14895,
      "latitude": 42.230956,
      "longitude": -77.917245,
      "city": "Wellsville",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14897,
      "latitude": 42.050965,
      "longitude": -77.821349,
      "city": "Whitesville",
      "state": "NY",
      "county": "Allegany"
  },
  {
      "zipCode": 14898,
      "latitude": 42.069469,
      "longitude": -77.438058,
      "city": "Woodhull",
      "state": "NY",
      "county": "Steuben"
  },
  {
      "zipCode": 14901,
      "latitude": 42.082585,
      "longitude": -76.71857,
      "city": "Elmira",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14902,
      "latitude": 42.147285,
      "longitude": -76.750888,
      "city": "Elmira",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14903,
      "latitude": 42.119824,
      "longitude": -76.887659,
      "city": "Elmira",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14904,
      "latitude": 42.061824,
      "longitude": -76.823678,
      "city": "Elmira",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14905,
      "latitude": 42.093574,
      "longitude": -76.844329,
      "city": "Elmira",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 14925,
      "latitude": 42.147285,
      "longitude": -76.750888,
      "city": "Elmira",
      "state": "NY",
      "county": "Chemung"
  },
  {
      "zipCode": 15001,
      "latitude": 40.675225,
      "longitude": -80.304178,
      "city": "Aliquippa",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15003,
      "latitude": 40.600494,
      "longitude": -80.210521,
      "city": "Ambridge",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15004,
      "latitude": 40.341349,
      "longitude": -80.382247,
      "city": "Atlasburg",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15005,
      "latitude": 40.618418,
      "longitude": -80.204103,
      "city": "Baden",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15006,
      "latitude": 40.493128,
      "longitude": -79.903121,
      "city": "Bairdford",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15007,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Bakerstown",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15009,
      "latitude": 40.729053,
      "longitude": -80.385191,
      "city": "Beaver",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15010,
      "latitude": 40.665672,
      "longitude": -80.352049,
      "city": "Beaver Falls",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15012,
      "latitude": 40.070268,
      "longitude": -79.603426,
      "city": "Belle Vernon",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15014,
      "latitude": 40.272101,
      "longitude": -79.899562,
      "city": "Brackenridge",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15015,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Bradfordwoods",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15017,
      "latitude": 40.354589,
      "longitude": -80.091342,
      "city": "Bridgeville",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15018,
      "latitude": 40.457657,
      "longitude": -80.208374,
      "city": "Buena Vista",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15019,
      "latitude": 40.412663,
      "longitude": -80.317007,
      "city": "Bulger",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15020,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Bunola",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15021,
      "latitude": 40.382141,
      "longitude": -80.404366,
      "city": "Burgettstown",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15022,
      "latitude": 40.135834,
      "longitude": -79.932907,
      "city": "Charleroi",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15024,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Cheswick",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15025,
      "latitude": 40.324279,
      "longitude": -79.931735,
      "city": "Clairton",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15026,
      "latitude": 40.490003,
      "longitude": -80.290333,
      "city": "Clinton",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15027,
      "latitude": 40.699515,
      "longitude": -80.264683,
      "city": "Conway",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15028,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Coulters",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15030,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Creighton",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15031,
      "latitude": 40.354712,
      "longitude": -80.166906,
      "city": "Cuddy",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15032,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Curtisville",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15033,
      "latitude": 40.179896,
      "longitude": -79.868392,
      "city": "Donora",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15034,
      "latitude": 40.315793,
      "longitude": -79.892171,
      "city": "Dravosburg",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15035,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "East Mc Keesport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15036,
      "latitude": 40.214896,
      "longitude": -80.179139,
      "city": "Eldersville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15037,
      "latitude": 40.271965,
      "longitude": -79.896642,
      "city": "Elizabeth",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15038,
      "latitude": 40.252086,
      "longitude": -79.925235,
      "city": "Elrama",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15042,
      "latitude": 40.710283,
      "longitude": -80.240559,
      "city": "Freedom",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15043,
      "latitude": 40.639461,
      "longitude": -80.339953,
      "city": "Georgetown",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15044,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Gibsonia",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15045,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Glassport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15046,
      "latitude": 40.468068,
      "longitude": -80.123459,
      "city": "Crescent",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15047,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Greenock",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15049,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Harwick",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15050,
      "latitude": 40.554305,
      "longitude": -80.428223,
      "city": "Hookstown",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15051,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Indianola",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15052,
      "latitude": 40.702397,
      "longitude": -80.388271,
      "city": "Industry",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15053,
      "latitude": 40.380036,
      "longitude": -80.360544,
      "city": "Joffre",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15054,
      "latitude": 40.362515,
      "longitude": -80.408551,
      "city": "Langeloth",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15055,
      "latitude": 40.305838,
      "longitude": -80.121904,
      "city": "Lawrence",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15056,
      "latitude": 40.375069,
      "longitude": -80.015425,
      "city": "Leetsdale",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15057,
      "latitude": 40.359139,
      "longitude": -80.267451,
      "city": "Mc Donald",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15059,
      "latitude": 40.740063,
      "longitude": -80.397486,
      "city": "Midland",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15060,
      "latitude": 40.368121,
      "longitude": -80.289382,
      "city": "Midway",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15061,
      "latitude": 40.656366,
      "longitude": -80.327814,
      "city": "Monaca",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15062,
      "latitude": 40.361852,
      "longitude": -79.75128,
      "city": "Monessen",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15063,
      "latitude": 40.260001,
      "longitude": -80.124442,
      "city": "Monongahela",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15064,
      "latitude": 40.355865,
      "longitude": -80.14155,
      "city": "Morgan",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15065,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Natrona Heights",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15066,
      "latitude": 40.712175,
      "longitude": -80.321773,
      "city": "New Brighton",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15067,
      "latitude": 40.282786,
      "longitude": -80.173907,
      "city": "New Eagle",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15068,
      "latitude": 40.478821,
      "longitude": -79.581506,
      "city": "New Kensington",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15069,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "New Kensington",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15071,
      "latitude": 40.403348,
      "longitude": -80.184169,
      "city": "Oakdale",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15072,
      "latitude": 40.139296,
      "longitude": -79.856168,
      "city": "Pricedale",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15074,
      "latitude": 40.686473,
      "longitude": -80.326538,
      "city": "Rochester",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15075,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Rural Ridge",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15076,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Russellton",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15077,
      "latitude": 40.602535,
      "longitude": -80.386344,
      "city": "Shippingport",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15078,
      "latitude": 40.358435,
      "longitude": -80.387917,
      "city": "Slovan",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15081,
      "latitude": 40.576267,
      "longitude": -80.238332,
      "city": "South Heights",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 15082,
      "latitude": 40.386263,
      "longitude": -80.208316,
      "city": "Sturgeon",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15083,
      "latitude": 40.22559,
      "longitude": -79.751605,
      "city": "Sutersville",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15084,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Tarentum",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15085,
      "latitude": 40.382668,
      "longitude": -79.594643,
      "city": "Trafford",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15086,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Warrendale",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15087,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Webster",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15088,
      "latitude": 40.271597,
      "longitude": -79.89661,
      "city": "West Elizabeth",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15089,
      "latitude": 40.201808,
      "longitude": -79.727237,
      "city": "West Newton",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15090,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Wexford",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15091,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Wildwood",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15095,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Warrendale",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15096,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Warrendale",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15101,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Allison Park",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15102,
      "latitude": 40.371471,
      "longitude": -80.11595,
      "city": "Bethel Park",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15104,
      "latitude": 40.366287,
      "longitude": -79.858359,
      "city": "Braddock",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15106,
      "latitude": 40.44908,
      "longitude": -80.103165,
      "city": "Carnegie",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15108,
      "latitude": 40.469268,
      "longitude": -80.140228,
      "city": "Coraopolis",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15110,
      "latitude": 40.372069,
      "longitude": -79.85382,
      "city": "Duquesne",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15112,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "East Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15116,
      "latitude": 40.357719,
      "longitude": -79.979974,
      "city": "Glenshaw",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15120,
      "latitude": 40.392619,
      "longitude": -79.905171,
      "city": "Homestead",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15122,
      "latitude": 40.360575,
      "longitude": -79.908621,
      "city": "West Mifflin",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15123,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "West Mifflin",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15126,
      "latitude": 40.452644,
      "longitude": -80.128852,
      "city": "Imperial",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15127,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Ingomar",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15129,
      "latitude": 40.303119,
      "longitude": -79.997567,
      "city": "South Park",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15130,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Mc Keesport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15131,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Mc Keesport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15132,
      "latitude": 40.407269,
      "longitude": -80.048026,
      "city": "Mc Keesport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15133,
      "latitude": 40.323419,
      "longitude": -79.940172,
      "city": "Mc Keesport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15134,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Mc Keesport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15135,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Mc Keesport",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15136,
      "latitude": 40.467006,
      "longitude": -80.103669,
      "city": "Mc Kees Rocks",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15137,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "North Versailles",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15139,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Oakmont",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15140,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pitcairn",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15142,
      "latitude": 40.402452,
      "longitude": -80.166896,
      "city": "Presto",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15143,
      "latitude": 40.451367,
      "longitude": -80.20828,
      "city": "Sewickley",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15144,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Springdale",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15145,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Turtle Creek",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15146,
      "latitude": 40.548117,
      "longitude": -80.236882,
      "city": "Monroeville",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15147,
      "latitude": 40.351469,
      "longitude": -80.011574,
      "city": "Verona",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15148,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Wilmerding",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15189,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Sewickley",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15201,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15202,
      "latitude": 40.328598,
      "longitude": -79.949182,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15203,
      "latitude": 40.425369,
      "longitude": -79.979914,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15204,
      "latitude": 40.455368,
      "longitude": -80.064416,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15205,
      "latitude": 40.432205,
      "longitude": -80.102101,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15206,
      "latitude": 40.416419,
      "longitude": -80.075427,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15207,
      "latitude": 40.356169,
      "longitude": -79.982123,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15208,
      "latitude": 40.519017,
      "longitude": -80.16608,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15209,
      "latitude": 40.392719,
      "longitude": -80.042375,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15210,
      "latitude": 40.419662,
      "longitude": -80.027243,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15211,
      "latitude": 40.429519,
      "longitude": -80.014375,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15212,
      "latitude": 40.428169,
      "longitude": -80.074977,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15213,
      "latitude": 40.423069,
      "longitude": -79.980624,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15214,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15215,
      "latitude": 40.377569,
      "longitude": -79.994074,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15216,
      "latitude": 40.400091,
      "longitude": -80.046176,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15217,
      "latitude": 40.385219,
      "longitude": -80.054876,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15218,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15219,
      "latitude": 40.375351,
      "longitude": -80.017075,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15220,
      "latitude": 40.418119,
      "longitude": -80.052576,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15221,
      "latitude": 40.414611,
      "longitude": -80.043342,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15222,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15223,
      "latitude": 40.457418,
      "longitude": -80.044926,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15224,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15225,
      "latitude": 40.505118,
      "longitude": -80.115528,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15226,
      "latitude": 40.40014,
      "longitude": -80.016074,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15227,
      "latitude": 40.380519,
      "longitude": -79.966723,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15228,
      "latitude": 40.369569,
      "longitude": -80.043925,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15229,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15230,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15231,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15232,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15233,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15234,
      "latitude": 40.368819,
      "longitude": -80.022399,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15235,
      "latitude": 40.398608,
      "longitude": -80.000854,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15236,
      "latitude": 40.33514,
      "longitude": -79.983243,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15237,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15238,
      "latitude": 40.380019,
      "longitude": -80.006474,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15239,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15240,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15241,
      "latitude": 40.332266,
      "longitude": -80.081026,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15242,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15243,
      "latitude": 40.384169,
      "longitude": -80.072876,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15244,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15250,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15251,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15252,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15253,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15254,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15255,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15257,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15258,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15259,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15260,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15261,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15262,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15263,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15264,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15265,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15266,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15267,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15268,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15270,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15272,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15274,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15275,
      "latitude": 40.44952,
      "longitude": -80.179475,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15276,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15277,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15278,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15279,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15281,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15282,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15283,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15285,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15286,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15290,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15295,
      "latitude": 40.434436,
      "longitude": -80.024817,
      "city": "Pittsburgh",
      "state": "PA",
      "county": "Allegheny"
  },
  {
      "zipCode": 15301,
      "latitude": 40.135391,
      "longitude": -80.146709,
      "city": "Washington",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15310,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Aleppo",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15311,
      "latitude": 40.049765,
      "longitude": -80.168133,
      "city": "Amity",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15312,
      "latitude": 40.200737,
      "longitude": -80.267148,
      "city": "Avella",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15313,
      "latitude": 40.065496,
      "longitude": -80.022102,
      "city": "Beallsville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15314,
      "latitude": 40.153459,
      "longitude": -80.083967,
      "city": "Bentleyville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15315,
      "latitude": 39.817048,
      "longitude": -79.956728,
      "city": "Bobtown",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15316,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Brave",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15317,
      "latitude": 40.155704,
      "longitude": -80.12842,
      "city": "Canonsburg",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15320,
      "latitude": 39.918913,
      "longitude": -79.98532,
      "city": "Carmichaels",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15321,
      "latitude": 40.159268,
      "longitude": -80.104193,
      "city": "Cecil",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15322,
      "latitude": 39.966702,
      "longitude": -80.045233,
      "city": "Clarksville",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15323,
      "latitude": 40.11016,
      "longitude": -80.251693,
      "city": "Claysville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15324,
      "latitude": 40.050163,
      "longitude": -79.98324,
      "city": "Cokeburg",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15325,
      "latitude": 39.95017,
      "longitude": -79.967626,
      "city": "Crucible",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15327,
      "latitude": 39.764089,
      "longitude": -79.937419,
      "city": "Dilliner",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15329,
      "latitude": 40.049949,
      "longitude": -80.28121,
      "city": "Prosperity",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15330,
      "latitude": 40.17557,
      "longitude": -80.099336,
      "city": "Eighty Four",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15331,
      "latitude": 40.106824,
      "longitude": -80.021614,
      "city": "Ellsworth",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15332,
      "latitude": 40.19897,
      "longitude": -80.059974,
      "city": "Finleyville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15333,
      "latitude": 40.025105,
      "longitude": -80.011741,
      "city": "Fredericktown",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15334,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Garards Fort",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15336,
      "latitude": 40.260141,
      "longitude": -79.992678,
      "city": "Gastonville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15337,
      "latitude": 39.937962,
      "longitude": -80.001496,
      "city": "Graysville",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15338,
      "latitude": 39.820941,
      "longitude": -79.974551,
      "city": "Greensboro",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15339,
      "latitude": 40.301838,
      "longitude": -80.15431,
      "city": "Hendersonville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15340,
      "latitude": 40.226863,
      "longitude": -80.169026,
      "city": "Hickory",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15341,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Holbrook",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15342,
      "latitude": 40.133091,
      "longitude": -80.133451,
      "city": "Houston",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15344,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Jefferson",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15345,
      "latitude": 40.028432,
      "longitude": -80.104213,
      "city": "Marianna",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15346,
      "latitude": 39.934662,
      "longitude": -80.070275,
      "city": "Mather",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15347,
      "latitude": 40.217358,
      "longitude": -80.226885,
      "city": "Meadow Lands",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15348,
      "latitude": 39.987651,
      "longitude": -80.003686,
      "city": "Millsboro",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15349,
      "latitude": 39.774031,
      "longitude": -80.024576,
      "city": "Mount Morris",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15350,
      "latitude": 40.293731,
      "longitude": -80.200484,
      "city": "Muse",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15351,
      "latitude": 39.877901,
      "longitude": -79.925807,
      "city": "Nemacolin",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15352,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "New Freeport",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15353,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Nineveh",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15354,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Pine Bank",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15357,
      "latitude": 39.95437,
      "longitude": -79.990431,
      "city": "Rices Landing",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15358,
      "latitude": 40.056347,
      "longitude": -79.992554,
      "city": "Richeyville",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15359,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Rogersville",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15360,
      "latitude": 40.092344,
      "longitude": -80.099457,
      "city": "Scenery Hill",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15361,
      "latitude": 40.32819,
      "longitude": -80.256291,
      "city": "Southview",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15362,
      "latitude": 39.744657,
      "longitude": -80.214471,
      "city": "Spraggs",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15363,
      "latitude": 40.250454,
      "longitude": -80.198354,
      "city": "Strabane",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15364,
      "latitude": 39.870635,
      "longitude": -80.211335,
      "city": "Sycamore",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15365,
      "latitude": 40.16113,
      "longitude": -80.378093,
      "city": "Taylorstown",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15366,
      "latitude": 40.110068,
      "longitude": -80.031787,
      "city": "Van Voorhis",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15367,
      "latitude": 40.244339,
      "longitude": -80.247797,
      "city": "Venetia",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15368,
      "latitude": 40.023775,
      "longitude": -80.007731,
      "city": "Vestaburg",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15370,
      "latitude": 39.87477,
      "longitude": -80.08057,
      "city": "Waynesburg",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15376,
      "latitude": 40.128081,
      "longitude": -80.270231,
      "city": "West Alexander",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15377,
      "latitude": 40.024677,
      "longitude": -80.429726,
      "city": "West Finley",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15378,
      "latitude": 40.277534,
      "longitude": -80.27313,
      "city": "Westland",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15379,
      "latitude": 40.241731,
      "longitude": -80.424154,
      "city": "West Middletown",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15380,
      "latitude": 39.909365,
      "longitude": -80.43004,
      "city": "Wind Ridge",
      "state": "PA",
      "county": "Greene"
  },
  {
      "zipCode": 15401,
      "latitude": 39.944884,
      "longitude": -79.770063,
      "city": "Uniontown",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15410,
      "latitude": 39.912105,
      "longitude": -79.89636,
      "city": "Adah",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15411,
      "latitude": 39.752401,
      "longitude": -79.330768,
      "city": "Addison",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15412,
      "latitude": 40.095888,
      "longitude": -79.849887,
      "city": "Allenport",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15413,
      "latitude": 39.986828,
      "longitude": -79.863732,
      "city": "Allison",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15415,
      "latitude": 39.980787,
      "longitude": -79.850366,
      "city": "Brier Hill",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15416,
      "latitude": 39.932084,
      "longitude": -79.65217,
      "city": "Brownfield",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15417,
      "latitude": 39.921613,
      "longitude": -79.690472,
      "city": "Brownsville",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15419,
      "latitude": 40.068921,
      "longitude": -79.873163,
      "city": "California",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15420,
      "latitude": 39.932084,
      "longitude": -79.65217,
      "city": "Cardale",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15421,
      "latitude": 39.845088,
      "longitude": -79.599126,
      "city": "Chalk Hill",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15422,
      "latitude": 39.992254,
      "longitude": -79.817083,
      "city": "Chestnut Ridge",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15423,
      "latitude": 40.082192,
      "longitude": -79.930266,
      "city": "Coal Center",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15424,
      "latitude": 39.824325,
      "longitude": -79.306409,
      "city": "Confluence",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15425,
      "latitude": 40.00743,
      "longitude": -79.700949,
      "city": "Connellsville",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15427,
      "latitude": 40.065064,
      "longitude": -79.973302,
      "city": "Daisytown",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15428,
      "latitude": 39.983001,
      "longitude": -79.725516,
      "city": "Dawson",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15429,
      "latitude": 40.008082,
      "longitude": -79.939234,
      "city": "Denbo",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15430,
      "latitude": 40.041785,
      "longitude": -79.659978,
      "city": "Dickerson Run",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15431,
      "latitude": 39.949088,
      "longitude": -79.723151,
      "city": "Dunbar",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15432,
      "latitude": 40.099693,
      "longitude": -79.85968,
      "city": "Dunlevy",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15433,
      "latitude": 39.973512,
      "longitude": -79.704535,
      "city": "East Millsboro",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15434,
      "latitude": 40.080438,
      "longitude": -79.875826,
      "city": "Elco",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15435,
      "latitude": 39.950123,
      "longitude": -79.858471,
      "city": "Fairbank",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15436,
      "latitude": 39.954424,
      "longitude": -79.808073,
      "city": "Fairchance",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15437,
      "latitude": 39.79741,
      "longitude": -79.596769,
      "city": "Farmington",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15438,
      "latitude": 40.066729,
      "longitude": -79.844514,
      "city": "Fayette City",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15439,
      "latitude": 39.771324,
      "longitude": -79.635842,
      "city": "Gans",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15440,
      "latitude": 39.757751,
      "longitude": -79.628365,
      "city": "Gibbon Glade",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15442,
      "latitude": 40.015516,
      "longitude": -79.843978,
      "city": "Grindstone",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15443,
      "latitude": 39.926347,
      "longitude": -79.901324,
      "city": "Hibbs",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15444,
      "latitude": 40.05656,
      "longitude": -79.902101,
      "city": "Hiller",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15445,
      "latitude": 39.876835,
      "longitude": -79.671812,
      "city": "Hopwood",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15446,
      "latitude": 40.037453,
      "longitude": -79.377806,
      "city": "Indian Head",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15447,
      "latitude": 39.945987,
      "longitude": -79.939317,
      "city": "Isabella",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15448,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Jacobs Creek",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15449,
      "latitude": 39.963622,
      "longitude": -79.785349,
      "city": "Keisterville",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15450,
      "latitude": 39.998279,
      "longitude": -79.975391,
      "city": "La Belle",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15451,
      "latitude": 39.742861,
      "longitude": -79.854871,
      "city": "Lake Lynn",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15454,
      "latitude": 39.932084,
      "longitude": -79.65217,
      "city": "Leckrone",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15455,
      "latitude": 39.998536,
      "longitude": -79.643451,
      "city": "Leisenring",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15456,
      "latitude": 39.877276,
      "longitude": -79.805549,
      "city": "Lemont Furnace",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15458,
      "latitude": 39.888242,
      "longitude": -79.869352,
      "city": "Mc Clellandtown",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15459,
      "latitude": 39.822431,
      "longitude": -79.529731,
      "city": "Markleysburg",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15460,
      "latitude": 39.932084,
      "longitude": -79.65217,
      "city": "Martin",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15461,
      "latitude": 39.94577,
      "longitude": -79.681838,
      "city": "Masontown",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15462,
      "latitude": 40.05974,
      "longitude": -79.381435,
      "city": "Melcroft",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15463,
      "latitude": 39.882521,
      "longitude": -79.833585,
      "city": "Merrittstown",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15464,
      "latitude": 39.933738,
      "longitude": -79.432647,
      "city": "Mill Run",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15465,
      "latitude": 39.932084,
      "longitude": -79.65217,
      "city": "Mount Braddock",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15466,
      "latitude": 40.075064,
      "longitude": -79.894347,
      "city": "Newell",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15467,
      "latitude": 39.932084,
      "longitude": -79.65217,
      "city": "New Geneva",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15468,
      "latitude": 39.941838,
      "longitude": -79.841031,
      "city": "New Salem",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15469,
      "latitude": 40.00786,
      "longitude": -79.438215,
      "city": "Normalville",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15470,
      "latitude": 39.850675,
      "longitude": -79.523004,
      "city": "Ohiopyle",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15472,
      "latitude": 39.918319,
      "longitude": -79.715837,
      "city": "Oliver",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15473,
      "latitude": 40.064564,
      "longitude": -79.775594,
      "city": "Perryopolis",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15474,
      "latitude": 39.911834,
      "longitude": -79.71174,
      "city": "Point Marion",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15475,
      "latitude": 39.984667,
      "longitude": -79.881421,
      "city": "Republic",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15476,
      "latitude": 39.879384,
      "longitude": -79.916806,
      "city": "Ronco",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15477,
      "latitude": 40.080713,
      "longitude": -79.862734,
      "city": "Roscoe",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15478,
      "latitude": 39.92959,
      "longitude": -79.698875,
      "city": "Smithfield",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15479,
      "latitude": 40.155299,
      "longitude": -79.738092,
      "city": "Smithton",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15480,
      "latitude": 39.988339,
      "longitude": -79.771265,
      "city": "Smock",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15482,
      "latitude": 40.065106,
      "longitude": -79.769027,
      "city": "Star Junction",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15483,
      "latitude": 40.038498,
      "longitude": -79.920937,
      "city": "Stockdale",
      "state": "PA",
      "county": "Washington"
  },
  {
      "zipCode": 15484,
      "latitude": 39.893625,
      "longitude": -79.785642,
      "city": "Uledi",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15485,
      "latitude": 39.817346,
      "longitude": -79.330642,
      "city": "Ursina",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15486,
      "latitude": 40.010683,
      "longitude": -79.750787,
      "city": "Vanderbilt",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15488,
      "latitude": 39.976316,
      "longitude": -79.768901,
      "city": "Waltersburg",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15489,
      "latitude": 39.956056,
      "longitude": -79.700745,
      "city": "West Leisenring",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15490,
      "latitude": 40.075857,
      "longitude": -79.452796,
      "city": "White",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15492,
      "latitude": 40.117828,
      "longitude": -79.771797,
      "city": "Wickhaven",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15501,
      "latitude": 39.990045,
      "longitude": -79.08745,
      "city": "Somerset",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15502,
      "latitude": 40.046023,
      "longitude": -79.258458,
      "city": "Hidden Valley",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15510,
      "latitude": 39.963306,
      "longitude": -79.04089,
      "city": "Somerset",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15520,
      "latitude": 40.113021,
      "longitude": -79.070218,
      "city": "Acosta",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15521,
      "latitude": 40.198627,
      "longitude": -78.622801,
      "city": "Alum Bank",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15522,
      "latitude": 39.95464,
      "longitude": -78.574759,
      "city": "Bedford",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15530,
      "latitude": 39.942768,
      "longitude": -78.981234,
      "city": "Berlin",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15531,
      "latitude": 40.006392,
      "longitude": -78.989292,
      "city": "Boswell",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15532,
      "latitude": 39.767296,
      "longitude": -79.062012,
      "city": "Boynton",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15533,
      "latitude": 39.98344,
      "longitude": -78.251177,
      "city": "Breezewood",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15534,
      "latitude": 39.864251,
      "longitude": -78.674197,
      "city": "Buffalo Mills",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15535,
      "latitude": 39.839294,
      "longitude": -78.469881,
      "city": "Clearville",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15536,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Crystal Spring",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 15537,
      "latitude": 39.966962,
      "longitude": -78.458753,
      "city": "Everett",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15538,
      "latitude": 39.945164,
      "longitude": -78.954581,
      "city": "Fairhope",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15539,
      "latitude": 40.130173,
      "longitude": -78.591457,
      "city": "Fishertown",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15540,
      "latitude": 39.796137,
      "longitude": -79.247161,
      "city": "Fort Hill",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15541,
      "latitude": 40.085382,
      "longitude": -78.963508,
      "city": "Friedens",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15542,
      "latitude": 39.8659,
      "longitude": -79.074705,
      "city": "Garrett",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15544,
      "latitude": 40.137749,
      "longitude": -79.092551,
      "city": "Gray",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15545,
      "latitude": 39.886506,
      "longitude": -78.712775,
      "city": "Hyndman",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15546,
      "latitude": 40.145938,
      "longitude": -79.067583,
      "city": "Jenners",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15547,
      "latitude": 40.1595,
      "longitude": -79.061629,
      "city": "Jennerstown",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15548,
      "latitude": 40.101106,
      "longitude": -78.938653,
      "city": "Kantner",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15549,
      "latitude": 40.020764,
      "longitude": -79.012306,
      "city": "Listie",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15550,
      "latitude": 39.902488,
      "longitude": -78.658195,
      "city": "Manns Choice",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15551,
      "latitude": 39.887287,
      "longitude": -79.254694,
      "city": "Markleton",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15552,
      "latitude": 39.875014,
      "longitude": -79.038599,
      "city": "Meyersdale",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15553,
      "latitude": 39.982795,
      "longitude": -78.771929,
      "city": "New Baltimore",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15554,
      "latitude": 39.981413,
      "longitude": -78.611595,
      "city": "New Paris",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15555,
      "latitude": 40.091429,
      "longitude": -79.087198,
      "city": "Quecreek",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15557,
      "latitude": 39.955145,
      "longitude": -79.141476,
      "city": "Rockwood",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15558,
      "latitude": 39.941261,
      "longitude": -79.083318,
      "city": "Salisbury",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15559,
      "latitude": 40.057047,
      "longitude": -78.642513,
      "city": "Schellsburg",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 15560,
      "latitude": 40.017059,
      "longitude": -78.907682,
      "city": "Shanksville",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15561,
      "latitude": 40.096406,
      "longitude": -79.090158,
      "city": "Sipesville",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15562,
      "latitude": 39.74802,
      "longitude": -79.123732,
      "city": "Springs",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15563,
      "latitude": 40.094802,
      "longitude": -78.965817,
      "city": "Stoystown",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15564,
      "latitude": 39.729896,
      "longitude": -78.844105,
      "city": "Wellersburg",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15565,
      "latitude": 40.003476,
      "longitude": -79.037983,
      "city": "West Salisbury",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15601,
      "latitude": 40.347017,
      "longitude": -79.500729,
      "city": "Greensburg",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15605,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Greensburg",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15606,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Greensburg",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15610,
      "latitude": 40.120149,
      "longitude": -79.405069,
      "city": "Acme",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15611,
      "latitude": 40.303771,
      "longitude": -79.653058,
      "city": "Adamsburg",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15612,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Alverton",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15613,
      "latitude": 40.436827,
      "longitude": -79.617178,
      "city": "Apollo",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15615,
      "latitude": 40.360619,
      "longitude": -79.743317,
      "city": "Ardara",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15616,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Armbrust",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15617,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Arona",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15618,
      "latitude": 40.497049,
      "longitude": -79.489055,
      "city": "Avonmore",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15619,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Bovard",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15620,
      "latitude": 40.323845,
      "longitude": -79.343421,
      "city": "Bradenville",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15621,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Calumet",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15622,
      "latitude": 40.190193,
      "longitude": -79.30196,
      "city": "Champion",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15623,
      "latitude": 40.36921,
      "longitude": -79.619012,
      "city": "Claridge",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15624,
      "latitude": 40.357753,
      "longitude": -79.484028,
      "city": "Crabtree",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15625,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Darragh",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15626,
      "latitude": 40.36131,
      "longitude": -79.571423,
      "city": "Delmont",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15627,
      "latitude": 40.350227,
      "longitude": -79.402056,
      "city": "Derry",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15628,
      "latitude": 40.102807,
      "longitude": -79.377008,
      "city": "Donegal",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15629,
      "latitude": 40.598047,
      "longitude": -79.562383,
      "city": "East Vandergrift",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15630,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Edmon",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 15631,
      "latitude": 40.084517,
      "longitude": -79.590529,
      "city": "Everson",
      "state": "PA",
      "county": "Fayette"
  },
  {
      "zipCode": 15632,
      "latitude": 40.429723,
      "longitude": -79.634789,
      "city": "Export",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15633,
      "latitude": 40.357548,
      "longitude": -79.522546,
      "city": "Forbes Road",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15634,
      "latitude": 40.308892,
      "longitude": -79.66542,
      "city": "Grapeville",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15635,
      "latitude": 40.352037,
      "longitude": -79.49789,
      "city": "Hannastown",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15636,
      "latitude": 40.36602,
      "longitude": -79.656525,
      "city": "Harrison City",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15637,
      "latitude": 40.266168,
      "longitude": -79.56779,
      "city": "Herminie",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15638,
      "latitude": 40.26106,
      "longitude": -79.14313,
      "city": "Hostetter",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15639,
      "latitude": 40.328617,
      "longitude": -79.624473,
      "city": "Hunker",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15640,
      "latitude": 40.225086,
      "longitude": -79.732805,
      "city": "Hutchinson",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15641,
      "latitude": 40.628165,
      "longitude": -79.595648,
      "city": "Hyde Park",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15642,
      "latitude": 40.36502,
      "longitude": -79.58406,
      "city": "Irwin",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15644,
      "latitude": 40.374572,
      "longitude": -79.407913,
      "city": "Jeannette",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15646,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Jones Mills",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15647,
      "latitude": 40.341519,
      "longitude": -79.727216,
      "city": "Larimer",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15650,
      "latitude": 40.35577,
      "longitude": -79.421969,
      "city": "Latrobe",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15655,
      "latitude": 40.231409,
      "longitude": -79.171459,
      "city": "Laughlintown",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15656,
      "latitude": 40.63896,
      "longitude": -79.610962,
      "city": "Leechburg",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 15658,
      "latitude": 40.271322,
      "longitude": -79.251145,
      "city": "Ligonier",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15660,
      "latitude": 40.248798,
      "longitude": -79.764638,
      "city": "Lowber",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15661,
      "latitude": 40.286515,
      "longitude": -79.513433,
      "city": "Loyalhanna",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15662,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Luxor",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15663,
      "latitude": 40.24397,
      "longitude": -79.676516,
      "city": "Madison",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15664,
      "latitude": 40.310772,
      "longitude": -79.617187,
      "city": "Mammoth",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15665,
      "latitude": 40.292194,
      "longitude": -79.664868,
      "city": "Manor",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15666,
      "latitude": 40.202211,
      "longitude": -79.609982,
      "city": "Mount Pleasant",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15668,
      "latitude": 40.423293,
      "longitude": -79.663288,
      "city": "Murrysville",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15670,
      "latitude": 40.396655,
      "longitude": -79.444008,
      "city": "New Alexandria",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15671,
      "latitude": 40.349446,
      "longitude": -79.322549,
      "city": "New Derry",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15672,
      "latitude": 40.24375,
      "longitude": -79.658436,
      "city": "New Stanton",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15673,
      "latitude": 40.593035,
      "longitude": -79.558098,
      "city": "North Apollo",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 15674,
      "latitude": 40.216006,
      "longitude": -79.487239,
      "city": "Norvelt",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15675,
      "latitude": 40.314908,
      "longitude": -79.681435,
      "city": "Penn",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15676,
      "latitude": 40.242429,
      "longitude": -79.454212,
      "city": "Pleasant Unity",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15677,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Rector",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15678,
      "latitude": 40.282843,
      "longitude": -79.723278,
      "city": "Rillton",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15679,
      "latitude": 40.226934,
      "longitude": -79.673364,
      "city": "Ruffs Dale",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15680,
      "latitude": 40.518905,
      "longitude": -79.495871,
      "city": "Salina",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15681,
      "latitude": 40.542875,
      "longitude": -79.397809,
      "city": "Saltsburg",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15682,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Schenley",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 15683,
      "latitude": 40.35535,
      "longitude": -79.520544,
      "city": "Scottdale",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15684,
      "latitude": 40.46037,
      "longitude": -79.515628,
      "city": "Slickville",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15685,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Southwest",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15686,
      "latitude": 40.616816,
      "longitude": -79.479833,
      "city": "Spring Church",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 15687,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Stahlstown",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15688,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Tarrs",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15689,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "United",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15690,
      "latitude": 40.400412,
      "longitude": -79.546855,
      "city": "Vandergrift",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15691,
      "latitude": 40.294021,
      "longitude": -79.686626,
      "city": "Wendel",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15692,
      "latitude": 40.329386,
      "longitude": -79.680567,
      "city": "Westmoreland City",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15693,
      "latitude": 40.248274,
      "longitude": -79.408579,
      "city": "Whitney",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15695,
      "latitude": 40.196199,
      "longitude": -79.694244,
      "city": "Wyano",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15696,
      "latitude": 40.279337,
      "longitude": -79.366066,
      "city": "Youngstown",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15697,
      "latitude": 40.240536,
      "longitude": -79.505889,
      "city": "Youngwood",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15698,
      "latitude": 40.222685,
      "longitude": -79.691067,
      "city": "Yukon",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15701,
      "latitude": 40.621272,
      "longitude": -79.080422,
      "city": "Indiana",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15705,
      "latitude": 40.640031,
      "longitude": -79.129445,
      "city": "Indiana",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15710,
      "latitude": 40.633313,
      "longitude": -78.872314,
      "city": "Alverda",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15711,
      "latitude": 41.171119,
      "longitude": -78.913113,
      "city": "Anita",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15712,
      "latitude": 40.781719,
      "longitude": -78.853612,
      "city": "Arcadia",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15713,
      "latitude": 40.61927,
      "longitude": -79.083982,
      "city": "Aultman",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15714,
      "latitude": 40.658721,
      "longitude": -78.794582,
      "city": "Northern Cambria",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15715,
      "latitude": 40.970448,
      "longitude": -78.875799,
      "city": "Big Run",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15716,
      "latitude": 40.483646,
      "longitude": -79.206322,
      "city": "Black Lick",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15717,
      "latitude": 40.486445,
      "longitude": -79.195766,
      "city": "Blairsville",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15720,
      "latitude": 40.529238,
      "longitude": -79.0846,
      "city": "Brush Valley",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15721,
      "latitude": 41.054375,
      "longitude": -78.41095,
      "city": "Burnside",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15722,
      "latitude": 40.583025,
      "longitude": -78.728455,
      "city": "Carrolltown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15723,
      "latitude": 40.705639,
      "longitude": -79.16151,
      "city": "Chambersville",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15724,
      "latitude": 40.724976,
      "longitude": -78.860324,
      "city": "Cherry Tree",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15725,
      "latitude": 40.632813,
      "longitude": -79.132551,
      "city": "Clarksburg",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15727,
      "latitude": 40.55027,
      "longitude": -79.323745,
      "city": "Clune",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15728,
      "latitude": 40.748901,
      "longitude": -78.997726,
      "city": "Clymer",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15729,
      "latitude": 40.706462,
      "longitude": -78.924401,
      "city": "Commodore",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15730,
      "latitude": 40.967066,
      "longitude": -78.925563,
      "city": "Coolspring",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15731,
      "latitude": 40.498602,
      "longitude": -79.173892,
      "city": "Coral",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15732,
      "latitude": 40.661336,
      "longitude": -79.217127,
      "city": "Creekside",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15733,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "De Lancey",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15734,
      "latitude": 40.718817,
      "longitude": -78.979489,
      "city": "Dixonville",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15736,
      "latitude": 40.692153,
      "longitude": -79.34218,
      "city": "Elderton",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 15737,
      "latitude": 40.607635,
      "longitude": -78.752415,
      "city": "Elmora",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15738,
      "latitude": 40.693633,
      "longitude": -78.775076,
      "city": "Emeigh",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15739,
      "latitude": 40.677971,
      "longitude": -79.165708,
      "city": "Ernest",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15740,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Frostburg",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15741,
      "latitude": 40.796017,
      "longitude": -78.858614,
      "city": "Gipsy",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15742,
      "latitude": 40.804413,
      "longitude": -78.889036,
      "city": "Glen Campbell",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15744,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Hamilton",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15745,
      "latitude": 40.620736,
      "longitude": -78.918561,
      "city": "Heilwood",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15746,
      "latitude": 40.767854,
      "longitude": -78.877331,
      "city": "Hillsdale",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15747,
      "latitude": 40.701519,
      "longitude": -79.182743,
      "city": "Home",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15748,
      "latitude": 40.627752,
      "longitude": -79.089616,
      "city": "Homer City",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15750,
      "latitude": 40.482783,
      "longitude": -79.185048,
      "city": "Josephine",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15751,
      "latitude": 40.640031,
      "longitude": -79.129445,
      "city": "Juneau",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15752,
      "latitude": 40.5412,
      "longitude": -79.282047,
      "city": "Kent",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15753,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "La Jose",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15754,
      "latitude": 40.500198,
      "longitude": -79.176571,
      "city": "Lucernemines",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15756,
      "latitude": 40.568381,
      "longitude": -79.299931,
      "city": "Mc Intyre",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15757,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Mahaffey",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15758,
      "latitude": 40.640031,
      "longitude": -79.129445,
      "city": "Marchand",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15759,
      "latitude": 40.685945,
      "longitude": -79.051607,
      "city": "Marion Center",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15760,
      "latitude": 40.652187,
      "longitude": -78.80542,
      "city": "Marsteller",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15761,
      "latitude": 40.620066,
      "longitude": -78.887009,
      "city": "Mentcle",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15762,
      "latitude": 40.600962,
      "longitude": -78.82209,
      "city": "Nicktown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15763,
      "latitude": 40.640031,
      "longitude": -79.129445,
      "city": "Northpoint",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15764,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Oliveburg",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15765,
      "latitude": 40.621281,
      "longitude": -78.99431,
      "city": "Penn Run",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15767,
      "latitude": 40.944538,
      "longitude": -78.979845,
      "city": "Punxsutawney",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15770,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Ringgold",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15771,
      "latitude": 40.72193,
      "longitude": -79.006162,
      "city": "Rochester Mills",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15772,
      "latitude": 40.869654,
      "longitude": -78.900483,
      "city": "Rossiter",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15773,
      "latitude": 40.629818,
      "longitude": -78.732947,
      "city": "Saint Benedict",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15774,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Shelocta",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 15775,
      "latitude": 40.63513,
      "longitude": -78.769185,
      "city": "Spangler",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15776,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Sprankle Mills",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15777,
      "latitude": 40.702673,
      "longitude": -78.958847,
      "city": "Starford",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15778,
      "latitude": 40.967353,
      "longitude": -79.199017,
      "city": "Timblin",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15779,
      "latitude": 40.360223,
      "longitude": -79.439801,
      "city": "Torrance",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15780,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Valier",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15781,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Walston",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15783,
      "latitude": 40.583778,
      "longitude": -79.342331,
      "city": "West Lebanon",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15784,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Worthville",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15801,
      "latitude": 41.118045,
      "longitude": -78.720302,
      "city": "Du Bois",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15821,
      "latitude": 41.415878,
      "longitude": -78.665057,
      "city": "Benezett",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15822,
      "latitude": 41.415878,
      "longitude": -78.665057,
      "city": "Brandy Camp",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15823,
      "latitude": 41.253932,
      "longitude": -78.699711,
      "city": "Brockport",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15824,
      "latitude": 41.247202,
      "longitude": -78.7989,
      "city": "Brockway",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15825,
      "latitude": 41.162735,
      "longitude": -79.081609,
      "city": "Brookville",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15827,
      "latitude": 41.291008,
      "longitude": -78.50435,
      "city": "Byrnedale",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15828,
      "latitude": 41.475887,
      "longitude": -79.239101,
      "city": "Clarington",
      "state": "PA",
      "county": "Forest"
  },
  {
      "zipCode": 15829,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Corsica",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15831,
      "latitude": 41.304782,
      "longitude": -78.621286,
      "city": "Dagus Mines",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15832,
      "latitude": 41.417566,
      "longitude": -78.204927,
      "city": "Driftwood",
      "state": "PA",
      "county": "Cameron"
  },
  {
      "zipCode": 15834,
      "latitude": 41.51031,
      "longitude": -78.23157,
      "city": "Emporium",
      "state": "PA",
      "county": "Cameron"
  },
  {
      "zipCode": 15840,
      "latitude": 41.149752,
      "longitude": -78.820695,
      "city": "Falls Creek",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15841,
      "latitude": 41.283709,
      "longitude": -78.551668,
      "city": "Force",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15845,
      "latitude": 41.504669,
      "longitude": -78.687183,
      "city": "Johnsonburg",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15846,
      "latitude": 41.336887,
      "longitude": -78.605537,
      "city": "Kersey",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15847,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Knox Dale",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15848,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Luthersburg",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15849,
      "latitude": 41.20156,
      "longitude": -78.583502,
      "city": "Penfield",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15851,
      "latitude": 41.095556,
      "longitude": -78.886154,
      "city": "Reynoldsville",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15853,
      "latitude": 41.362112,
      "longitude": -78.726238,
      "city": "Ridgway",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15856,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Rockton",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15857,
      "latitude": 41.452935,
      "longitude": -78.529829,
      "city": "Saint Marys",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15860,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Sigel",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15861,
      "latitude": 41.417566,
      "longitude": -78.204927,
      "city": "Sinnamahoning",
      "state": "PA",
      "county": "Cameron"
  },
  {
      "zipCode": 15863,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Stump Creek",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15864,
      "latitude": 41.141079,
      "longitude": -78.962877,
      "city": "Summerville",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15865,
      "latitude": 41.047897,
      "longitude": -78.819682,
      "city": "Sykesville",
      "state": "PA",
      "county": "Jefferson"
  },
  {
      "zipCode": 15866,
      "latitude": 41.011686,
      "longitude": -78.785731,
      "city": "Troutville",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 15868,
      "latitude": 41.279081,
      "longitude": -78.527949,
      "city": "Weedville",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15870,
      "latitude": 41.572956,
      "longitude": -78.665262,
      "city": "Wilcox",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 15901,
      "latitude": 40.412707,
      "longitude": -78.854245,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15902,
      "latitude": 40.417111,
      "longitude": -78.80599,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15904,
      "latitude": 40.319279,
      "longitude": -78.828084,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15905,
      "latitude": 40.314074,
      "longitude": -78.870894,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15906,
      "latitude": 40.494132,
      "longitude": -78.838673,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15907,
      "latitude": 40.325374,
      "longitude": -78.915147,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15909,
      "latitude": 40.440314,
      "longitude": -78.85591,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15915,
      "latitude": 40.484487,
      "longitude": -78.702224,
      "city": "Johnstown",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15920,
      "latitude": 40.460262,
      "longitude": -79.009721,
      "city": "Armagh",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15921,
      "latitude": 40.319561,
      "longitude": -78.694043,
      "city": "Beaverdale",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15922,
      "latitude": 40.484487,
      "longitude": -78.702224,
      "city": "Belsano",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15923,
      "latitude": 40.348279,
      "longitude": -79.15321,
      "city": "Bolivar",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15924,
      "latitude": 40.11492,
      "longitude": -78.7851,
      "city": "Cairnbrook",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15925,
      "latitude": 40.4128,
      "longitude": -78.633698,
      "city": "Cassandra",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15926,
      "latitude": 40.074818,
      "longitude": -78.83817,
      "city": "Central City",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15927,
      "latitude": 40.538311,
      "longitude": -78.786549,
      "city": "Colver",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15928,
      "latitude": 40.23523,
      "longitude": -78.919007,
      "city": "Davidsville",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15929,
      "latitude": 40.462521,
      "longitude": -79.012834,
      "city": "Dilltown",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15930,
      "latitude": 40.293935,
      "longitude": -78.719217,
      "city": "Dunlo",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15931,
      "latitude": 40.459581,
      "longitude": -78.771875,
      "city": "Ebensburg",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15934,
      "latitude": 40.279925,
      "longitude": -78.803247,
      "city": "Elton",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15935,
      "latitude": 40.20137,
      "longitude": -78.958371,
      "city": "Hollsopple",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15936,
      "latitude": 40.155129,
      "longitude": -78.916785,
      "city": "Hooversville",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15937,
      "latitude": 40.208435,
      "longitude": -78.987265,
      "city": "Jerome",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15938,
      "latitude": 40.427588,
      "longitude": -78.639257,
      "city": "Lilly",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15940,
      "latitude": 40.517191,
      "longitude": -78.61487,
      "city": "Loretto",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15942,
      "latitude": 40.419784,
      "longitude": -78.823794,
      "city": "Mineral Point",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15943,
      "latitude": 40.392443,
      "longitude": -78.848566,
      "city": "Nanty Glo",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15944,
      "latitude": 40.326182,
      "longitude": -79.101925,
      "city": "New Florence",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15945,
      "latitude": 40.356723,
      "longitude": -78.873296,
      "city": "Parkhill",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15946,
      "latitude": 40.369239,
      "longitude": -78.752509,
      "city": "Portage",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15948,
      "latitude": 40.464069,
      "longitude": -78.685365,
      "city": "Revloc",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15949,
      "latitude": 40.403698,
      "longitude": -79.12204,
      "city": "Robinson",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15951,
      "latitude": 40.338615,
      "longitude": -78.776137,
      "city": "Saint Michael",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15952,
      "latitude": 40.298574,
      "longitude": -78.781592,
      "city": "Salix",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15953,
      "latitude": 40.206435,
      "longitude": -78.900533,
      "city": "Seanor",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15954,
      "latitude": 40.401071,
      "longitude": -79.029098,
      "city": "Seward",
      "state": "PA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 15955,
      "latitude": 40.334129,
      "longitude": -78.715511,
      "city": "Sidman",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15956,
      "latitude": 40.41164,
      "longitude": -78.778413,
      "city": "South Fork",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15957,
      "latitude": 40.572746,
      "longitude": -78.900034,
      "city": "Strongstown",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 15958,
      "latitude": 40.39114,
      "longitude": -78.735229,
      "city": "Summerhill",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15959,
      "latitude": 40.267975,
      "longitude": -78.915567,
      "city": "Tire Hill",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 15960,
      "latitude": 40.49963,
      "longitude": -78.861308,
      "city": "Twin Rocks",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15961,
      "latitude": 40.437721,
      "longitude": -78.924998,
      "city": "Vintondale",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15962,
      "latitude": 40.380188,
      "longitude": -78.719019,
      "city": "Wilmore",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 15963,
      "latitude": 40.180418,
      "longitude": -78.829021,
      "city": "Windber",
      "state": "PA",
      "county": "Somerset"
  },
  {
      "zipCode": 16001,
      "latitude": 40.885861,
      "longitude": -79.934048,
      "city": "Butler",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16002,
      "latitude": 40.8409,
      "longitude": -79.859175,
      "city": "Butler",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16003,
      "latitude": 40.921145,
      "longitude": -79.92758,
      "city": "Butler",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16016,
      "latitude": 40.921145,
      "longitude": -79.92758,
      "city": "Boyers",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16017,
      "latitude": 40.921145,
      "longitude": -79.92758,
      "city": "Boyers",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16018,
      "latitude": 40.921145,
      "longitude": -79.92758,
      "city": "Boyers",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16020,
      "latitude": 41.106846,
      "longitude": -79.900626,
      "city": "Boyers",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16021,
      "latitude": 40.921145,
      "longitude": -79.92758,
      "city": "Branchton",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16022,
      "latitude": 41.055515,
      "longitude": -79.731308,
      "city": "Bruin",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16023,
      "latitude": 40.792353,
      "longitude": -79.760048,
      "city": "Cabot",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16024,
      "latitude": 40.921867,
      "longitude": -79.994747,
      "city": "Callery",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16025,
      "latitude": 40.940484,
      "longitude": -79.777107,
      "city": "Chicora",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16027,
      "latitude": 40.826436,
      "longitude": -80.013785,
      "city": "Connoquenessing",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16028,
      "latitude": 40.98399,
      "longitude": -79.615234,
      "city": "East Brady",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16029,
      "latitude": 40.780174,
      "longitude": -79.780792,
      "city": "East Butler",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16030,
      "latitude": 41.136538,
      "longitude": -79.802384,
      "city": "Eau Claire",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16033,
      "latitude": 40.904798,
      "longitude": -79.936488,
      "city": "Evans City",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16034,
      "latitude": 40.864921,
      "longitude": -79.735066,
      "city": "Fenelton",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16035,
      "latitude": 41.106756,
      "longitude": -80.007017,
      "city": "Forestville",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16036,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Foxburg",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16037,
      "latitude": 40.8282,
      "longitude": -80.09825,
      "city": "Harmony",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16038,
      "latitude": 40.935262,
      "longitude": -80.00865,
      "city": "Harrisville",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16039,
      "latitude": 40.921145,
      "longitude": -79.92758,
      "city": "Herman",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16040,
      "latitude": 41.091444,
      "longitude": -79.835723,
      "city": "Hilliards",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16041,
      "latitude": 41.005368,
      "longitude": -79.766612,
      "city": "Karns City",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16045,
      "latitude": 40.801238,
      "longitude": -79.963774,
      "city": "Lyndora",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16046,
      "latitude": 40.866316,
      "longitude": -79.936986,
      "city": "Mars",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16048,
      "latitude": 41.04719,
      "longitude": -79.808899,
      "city": "North Washington",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16049,
      "latitude": 41.093079,
      "longitude": -79.682532,
      "city": "Parker",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16050,
      "latitude": 41.041059,
      "longitude": -79.762814,
      "city": "Petrolia",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16051,
      "latitude": 41.01782,
      "longitude": -80.072356,
      "city": "Portersville",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16052,
      "latitude": 40.956173,
      "longitude": -79.933127,
      "city": "Prospect",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16053,
      "latitude": 40.803054,
      "longitude": -79.990821,
      "city": "Renfrew",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16054,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Saint Petersburg",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16055,
      "latitude": 40.720919,
      "longitude": -79.76262,
      "city": "Sarver",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16056,
      "latitude": 40.861995,
      "longitude": -79.864282,
      "city": "Saxonburg",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16057,
      "latitude": 41.052427,
      "longitude": -80.043821,
      "city": "Slippery Rock",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16058,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Turkey City",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16059,
      "latitude": 40.710689,
      "longitude": -79.937074,
      "city": "Valencia",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16061,
      "latitude": 41.021754,
      "longitude": -79.884312,
      "city": "West Sunbury",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16063,
      "latitude": 40.7609,
      "longitude": -80.10939,
      "city": "Zelienople",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16066,
      "latitude": 40.709713,
      "longitude": -80.104597,
      "city": "Cranberry Twp",
      "state": "PA",
      "county": "Butler"
  },
  {
      "zipCode": 16101,
      "latitude": 40.964516,
      "longitude": -80.300736,
      "city": "New Castle",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16102,
      "latitude": 40.9613,
      "longitude": -80.408502,
      "city": "New Castle",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16103,
      "latitude": 40.989662,
      "longitude": -80.308376,
      "city": "New Castle",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16105,
      "latitude": 41.024051,
      "longitude": -80.345444,
      "city": "New Castle",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16107,
      "latitude": 40.989662,
      "longitude": -80.308376,
      "city": "New Castle",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16108,
      "latitude": 40.989662,
      "longitude": -80.308376,
      "city": "New Castle",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16110,
      "latitude": 41.629436,
      "longitude": -80.214024,
      "city": "Adamsville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16111,
      "latitude": 41.524812,
      "longitude": -80.32056,
      "city": "Atlantic",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16112,
      "latitude": 40.95621,
      "longitude": -80.486131,
      "city": "Bessemer",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16113,
      "latitude": 41.286567,
      "longitude": -80.423727,
      "city": "Clark",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16114,
      "latitude": 41.405788,
      "longitude": -80.184099,
      "city": "Clarks Mills",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16115,
      "latitude": 40.786244,
      "longitude": -80.391487,
      "city": "Darlington",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 16116,
      "latitude": 41.031749,
      "longitude": -80.457328,
      "city": "Edinburg",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16117,
      "latitude": 40.917478,
      "longitude": -80.363481,
      "city": "Ellwood City",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16120,
      "latitude": 40.905222,
      "longitude": -80.468225,
      "city": "Enon Valley",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16121,
      "latitude": 41.210606,
      "longitude": -80.487821,
      "city": "Farrell",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16123,
      "latitude": 40.813208,
      "longitude": -80.199126,
      "city": "Fombell",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 16124,
      "latitude": 41.334133,
      "longitude": -80.262473,
      "city": "Fredonia",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16125,
      "latitude": 41.326606,
      "longitude": -80.288302,
      "city": "Greenville",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16127,
      "latitude": 41.170823,
      "longitude": -80.089581,
      "city": "Grove City",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16130,
      "latitude": 41.32821,
      "longitude": -80.292168,
      "city": "Hadley",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16131,
      "latitude": 41.534292,
      "longitude": -80.417374,
      "city": "Hartstown",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16132,
      "latitude": 41.011263,
      "longitude": -80.497546,
      "city": "Hillsville",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16133,
      "latitude": 41.278208,
      "longitude": -80.113231,
      "city": "Jackson Center",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16134,
      "latitude": 41.456649,
      "longitude": -80.449185,
      "city": "Jamestown",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16136,
      "latitude": 40.834767,
      "longitude": -80.328009,
      "city": "Koppel",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 16137,
      "latitude": 41.222885,
      "longitude": -80.250704,
      "city": "Mercer",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16140,
      "latitude": 40.989662,
      "longitude": -80.308376,
      "city": "New Bedford",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16141,
      "latitude": 40.833615,
      "longitude": -80.384434,
      "city": "New Galilee",
      "state": "PA",
      "county": "Beaver"
  },
  {
      "zipCode": 16142,
      "latitude": 41.10446,
      "longitude": -80.352749,
      "city": "New Wilmington",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16143,
      "latitude": 41.087533,
      "longitude": -80.472593,
      "city": "Pulaski",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16145,
      "latitude": 41.380743,
      "longitude": -80.21727,
      "city": "Sandy Lake",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16146,
      "latitude": 41.234505,
      "longitude": -80.44793,
      "city": "Sharon",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16148,
      "latitude": 41.221984,
      "longitude": -80.438887,
      "city": "Hermitage",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16150,
      "latitude": 41.283776,
      "longitude": -80.400053,
      "city": "Sharpsville",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16151,
      "latitude": 41.44346,
      "longitude": -80.205513,
      "city": "Sheakleyville",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16153,
      "latitude": 41.335562,
      "longitude": -80.10361,
      "city": "Stoneboro",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16154,
      "latitude": 41.368354,
      "longitude": -80.360703,
      "city": "Transfer",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16155,
      "latitude": 40.989662,
      "longitude": -80.308376,
      "city": "Villa Maria",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16156,
      "latitude": 41.089458,
      "longitude": -80.19346,
      "city": "Volant",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16157,
      "latitude": 40.893341,
      "longitude": -80.33774,
      "city": "Wampum",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16159,
      "latitude": 41.238352,
      "longitude": -80.300747,
      "city": "West Middlesex",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16160,
      "latitude": 40.930132,
      "longitude": -80.361099,
      "city": "West Pittsburg",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16161,
      "latitude": 41.200256,
      "longitude": -80.502702,
      "city": "Wheatland",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16172,
      "latitude": 41.119992,
      "longitude": -80.333192,
      "city": "New Wilmington",
      "state": "PA",
      "county": "Lawrence"
  },
  {
      "zipCode": 16201,
      "latitude": 40.817197,
      "longitude": -79.419457,
      "city": "Kittanning",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16210,
      "latitude": 40.855727,
      "longitude": -79.545145,
      "city": "Adrian",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16211,
      "latitude": 40.640031,
      "longitude": -79.129445,
      "city": "Beyer",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 16212,
      "latitude": 40.754086,
      "longitude": -79.580912,
      "city": "Cadogan",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16213,
      "latitude": 41.123556,
      "longitude": -79.568457,
      "city": "Callensburg",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16214,
      "latitude": 41.143274,
      "longitude": -79.452051,
      "city": "Clarion",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16215,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Kittanning",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16216,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Climax",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16217,
      "latitude": 41.475887,
      "longitude": -79.239101,
      "city": "Cooksburg",
      "state": "PA",
      "county": "Forest"
  },
  {
      "zipCode": 16218,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Cowansville",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16220,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Crown",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16221,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Curllsville",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16222,
      "latitude": 40.881227,
      "longitude": -79.240054,
      "city": "Dayton",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16223,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Distant",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16224,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Fairmount City",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16225,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Fisher",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16226,
      "latitude": 40.79965,
      "longitude": -79.420198,
      "city": "Ford City",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16228,
      "latitude": 40.756829,
      "longitude": -79.522225,
      "city": "Ford Cliff",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16229,
      "latitude": 40.743732,
      "longitude": -79.46114,
      "city": "Freeport",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16230,
      "latitude": 41.017753,
      "longitude": -79.279932,
      "city": "Hawthorn",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16232,
      "latitude": 41.190299,
      "longitude": -79.592521,
      "city": "Knox",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16233,
      "latitude": 41.388826,
      "longitude": -79.273662,
      "city": "Leeper",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16234,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Limestone",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16235,
      "latitude": 41.300236,
      "longitude": -79.35779,
      "city": "Lucinda",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16236,
      "latitude": 40.781788,
      "longitude": -79.521842,
      "city": "Mc Grann",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16238,
      "latitude": 40.787688,
      "longitude": -79.521034,
      "city": "Manorville",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16239,
      "latitude": 41.469973,
      "longitude": -79.125004,
      "city": "Marienville",
      "state": "PA",
      "county": "Forest"
  },
  {
      "zipCode": 16240,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Mayport",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16242,
      "latitude": 41.12698,
      "longitude": -79.40791,
      "city": "New Bethlehem",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16244,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Nu Mine",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16245,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Oak Ridge",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16246,
      "latitude": 40.640031,
      "longitude": -79.129445,
      "city": "Plumville",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 16248,
      "latitude": 41.041098,
      "longitude": -79.501668,
      "city": "Rimersburg",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16249,
      "latitude": 40.746619,
      "longitude": -79.496645,
      "city": "Rural Valley",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16250,
      "latitude": 40.777387,
      "longitude": -79.233585,
      "city": "Sagamore",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16253,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Seminole",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16254,
      "latitude": 41.263674,
      "longitude": -79.434105,
      "city": "Shippenville",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16255,
      "latitude": 41.105702,
      "longitude": -79.489973,
      "city": "Sligo",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16256,
      "latitude": 40.867654,
      "longitude": -79.141344,
      "city": "Smicksburg",
      "state": "PA",
      "county": "Indiana"
  },
  {
      "zipCode": 16257,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Snydersburg",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16258,
      "latitude": 41.235244,
      "longitude": -79.281881,
      "city": "Strattanville",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16259,
      "latitude": 40.915899,
      "longitude": -79.461201,
      "city": "Templeton",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16260,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Vowinckel",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16261,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Widnoon",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16262,
      "latitude": 40.837764,
      "longitude": -79.629766,
      "city": "Worthington",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16263,
      "latitude": 40.847829,
      "longitude": -79.451575,
      "city": "Yatesboro",
      "state": "PA",
      "county": "Armstrong"
  },
  {
      "zipCode": 16301,
      "latitude": 41.330725,
      "longitude": -79.775153,
      "city": "Oil City",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16311,
      "latitude": 41.456666,
      "longitude": -80.043012,
      "city": "Carlton",
      "state": "PA",
      "county": "Mercer"
  },
  {
      "zipCode": 16312,
      "latitude": 41.935848,
      "longitude": -79.309668,
      "city": "Chandlers Valley",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16313,
      "latitude": 41.798453,
      "longitude": -79.193751,
      "city": "Clarendon",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16314,
      "latitude": 41.51838,
      "longitude": -80.047368,
      "city": "Cochranton",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16316,
      "latitude": 41.616247,
      "longitude": -80.306783,
      "city": "Conneaut Lake",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16317,
      "latitude": 41.496472,
      "longitude": -79.887384,
      "city": "Cooperstown",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16319,
      "latitude": 41.398105,
      "longitude": -79.738809,
      "city": "Cranberry",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16321,
      "latitude": 41.475887,
      "longitude": -79.239101,
      "city": "East Hickory",
      "state": "PA",
      "county": "Forest"
  },
  {
      "zipCode": 16322,
      "latitude": 41.475887,
      "longitude": -79.239101,
      "city": "Endeavor",
      "state": "PA",
      "county": "Forest"
  },
  {
      "zipCode": 16323,
      "latitude": 41.335291,
      "longitude": -79.783172,
      "city": "Franklin",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16326,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Fryburg",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16327,
      "latitude": 41.666057,
      "longitude": -80.065793,
      "city": "Guys Mills",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16328,
      "latitude": 41.666057,
      "longitude": -80.065793,
      "city": "Hydetown",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16329,
      "latitude": 41.811682,
      "longitude": -79.264322,
      "city": "Irvine",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16331,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Kossuth",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16332,
      "latitude": 41.355729,
      "longitude": -79.381225,
      "city": "Lickingville",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16333,
      "latitude": 41.742791,
      "longitude": -78.78726,
      "city": "Ludlow",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16334,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Marble",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16335,
      "latitude": 41.611599,
      "longitude": -80.114891,
      "city": "Meadville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16340,
      "latitude": 41.862381,
      "longitude": -79.432589,
      "city": "Pittsfield",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16341,
      "latitude": 41.501311,
      "longitude": -79.633336,
      "city": "Pleasantville",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16342,
      "latitude": 41.320273,
      "longitude": -79.926847,
      "city": "Polk",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16343,
      "latitude": 41.411018,
      "longitude": -79.749341,
      "city": "Reno",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16344,
      "latitude": 41.471714,
      "longitude": -79.688057,
      "city": "Rouseville",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16345,
      "latitude": 41.926402,
      "longitude": -79.090299,
      "city": "Russell",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16346,
      "latitude": 41.378581,
      "longitude": -79.707212,
      "city": "Seneca",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16347,
      "latitude": 41.68338,
      "longitude": -79.032776,
      "city": "Sheffield",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16350,
      "latitude": 41.890561,
      "longitude": -79.227866,
      "city": "Sugar Grove",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16351,
      "latitude": 41.830662,
      "longitude": -79.229452,
      "city": "Tidioute",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16352,
      "latitude": 41.743431,
      "longitude": -79.052756,
      "city": "Tiona",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16353,
      "latitude": 41.500915,
      "longitude": -79.407452,
      "city": "Tionesta",
      "state": "PA",
      "county": "Forest"
  },
  {
      "zipCode": 16354,
      "latitude": 41.661775,
      "longitude": -79.720068,
      "city": "Titusville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16360,
      "latitude": 41.689858,
      "longitude": -79.900897,
      "city": "Townville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16361,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Tylersburg",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16362,
      "latitude": 41.398105,
      "longitude": -79.738809,
      "city": "Utica",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16364,
      "latitude": 41.398105,
      "longitude": -79.738809,
      "city": "Venus",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16365,
      "latitude": 41.84356,
      "longitude": -79.172619,
      "city": "Warren",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16366,
      "latitude": 41.811682,
      "longitude": -79.264322,
      "city": "Warren",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16367,
      "latitude": 41.811682,
      "longitude": -79.264322,
      "city": "Warren",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16368,
      "latitude": 41.811682,
      "longitude": -79.264322,
      "city": "Warren",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16369,
      "latitude": 41.811682,
      "longitude": -79.264322,
      "city": "Warren",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16370,
      "latitude": 41.475887,
      "longitude": -79.239101,
      "city": "West Hickory",
      "state": "PA",
      "county": "Forest"
  },
  {
      "zipCode": 16371,
      "latitude": 41.823681,
      "longitude": -79.201275,
      "city": "Youngsville",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16372,
      "latitude": 41.199287,
      "longitude": -79.874548,
      "city": "Clintonville",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16373,
      "latitude": 41.273318,
      "longitude": -79.812491,
      "city": "Emlenton",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16374,
      "latitude": 41.398105,
      "longitude": -79.738809,
      "city": "Kennerdell",
      "state": "PA",
      "county": "Venango"
  },
  {
      "zipCode": 16375,
      "latitude": 41.202325,
      "longitude": -79.453751,
      "city": "Lamartine",
      "state": "PA",
      "county": "Clarion"
  },
  {
      "zipCode": 16388,
      "latitude": 41.659552,
      "longitude": -80.157553,
      "city": "Meadville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16401,
      "latitude": 41.894652,
      "longitude": -80.311081,
      "city": "Albion",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16402,
      "latitude": 41.969142,
      "longitude": -79.477037,
      "city": "Bear Lake",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16403,
      "latitude": 41.803393,
      "longitude": -80.059383,
      "city": "Cambridge Springs",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16404,
      "latitude": 41.666057,
      "longitude": -80.065793,
      "city": "Centerville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16405,
      "latitude": 41.943966,
      "longitude": -79.568294,
      "city": "Columbus",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16406,
      "latitude": 41.757871,
      "longitude": -80.370323,
      "city": "Conneautville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16407,
      "latitude": 41.924886,
      "longitude": -79.696549,
      "city": "Corry",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16410,
      "latitude": 41.921275,
      "longitude": -80.302761,
      "city": "Cranesville",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16411,
      "latitude": 41.982574,
      "longitude": -80.439619,
      "city": "East Springfield",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16412,
      "latitude": 41.90425,
      "longitude": -80.157715,
      "city": "Edinboro",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16413,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Elgin",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16415,
      "latitude": 42.011544,
      "longitude": -80.335032,
      "city": "Fairview",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16416,
      "latitude": 41.818831,
      "longitude": -79.446306,
      "city": "Garland",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16417,
      "latitude": 41.950719,
      "longitude": -80.295852,
      "city": "Girard",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16420,
      "latitude": 41.811682,
      "longitude": -79.264322,
      "city": "Grand Valley",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16421,
      "latitude": 42.172938,
      "longitude": -79.937675,
      "city": "Harborcreek",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16422,
      "latitude": 41.643145,
      "longitude": -80.394299,
      "city": "Harmonsburg",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16423,
      "latitude": 42.015789,
      "longitude": -80.346492,
      "city": "Lake City",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16424,
      "latitude": 41.624388,
      "longitude": -80.452295,
      "city": "Linesville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16426,
      "latitude": 41.985964,
      "longitude": -80.132523,
      "city": "Mc Kean",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16427,
      "latitude": 41.87726,
      "longitude": -79.969168,
      "city": "Mill Village",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16428,
      "latitude": 42.176845,
      "longitude": -79.841991,
      "city": "North East",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16430,
      "latitude": 41.999881,
      "longitude": -80.42585,
      "city": "North Springfield",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16432,
      "latitude": 41.666057,
      "longitude": -80.065793,
      "city": "Riceville",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16433,
      "latitude": 41.752837,
      "longitude": -80.207488,
      "city": "Saegertown",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16434,
      "latitude": 41.667286,
      "longitude": -80.097316,
      "city": "Spartansburg",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16435,
      "latitude": 41.800421,
      "longitude": -80.371429,
      "city": "Springboro",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16436,
      "latitude": 41.920018,
      "longitude": -79.448206,
      "city": "Spring Creek",
      "state": "PA",
      "county": "Warren"
  },
  {
      "zipCode": 16438,
      "latitude": 41.941617,
      "longitude": -79.845963,
      "city": "Union City",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16440,
      "latitude": 41.763965,
      "longitude": -80.098233,
      "city": "Venango",
      "state": "PA",
      "county": "Crawford"
  },
  {
      "zipCode": 16441,
      "latitude": 41.970601,
      "longitude": -79.997581,
      "city": "Waterford",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16442,
      "latitude": 42.030403,
      "longitude": -79.828985,
      "city": "Wattsburg",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16443,
      "latitude": 41.935633,
      "longitude": -80.471462,
      "city": "West Springfield",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16444,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Edinboro",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16475,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Albion",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16501,
      "latitude": 42.087337,
      "longitude": -80.087341,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16502,
      "latitude": 42.110357,
      "longitude": -80.100931,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16503,
      "latitude": 42.126757,
      "longitude": -80.061029,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16504,
      "latitude": 42.109556,
      "longitude": -80.050278,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16505,
      "latitude": 42.110857,
      "longitude": -80.153383,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16506,
      "latitude": 42.064456,
      "longitude": -80.165682,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16507,
      "latitude": 42.134257,
      "longitude": -80.084042,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16508,
      "latitude": 42.097156,
      "longitude": -80.09283,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16509,
      "latitude": 42.048568,
      "longitude": -80.027928,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16510,
      "latitude": 42.108655,
      "longitude": -79.953504,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16511,
      "latitude": 42.160153,
      "longitude": -79.984376,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16512,
      "latitude": 42.030201,
      "longitude": -80.257855,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16514,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16515,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16522,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16530,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16531,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16532,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16533,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16534,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16538,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16541,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16544,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16546,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16550,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16553,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16554,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16558,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16563,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16565,
      "latitude": 42.182748,
      "longitude": -80.064915,
      "city": "Erie",
      "state": "PA",
      "county": "Erie"
  },
  {
      "zipCode": 16601,
      "latitude": 40.489433,
      "longitude": -78.349874,
      "city": "Altoona",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16602,
      "latitude": 40.508219,
      "longitude": -78.382635,
      "city": "Altoona",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16603,
      "latitude": 40.50177,
      "longitude": -78.410035,
      "city": "Altoona",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16611,
      "latitude": 40.54851,
      "longitude": -78.095064,
      "city": "Alexandria",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16613,
      "latitude": 40.554875,
      "longitude": -78.544825,
      "city": "Ashville",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16614,
      "latitude": 40.262311,
      "longitude": -78.421434,
      "city": "Bakers Summit",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16616,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Beccaria",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16617,
      "latitude": 40.562667,
      "longitude": -78.363835,
      "city": "Bellwood",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16619,
      "latitude": 40.684687,
      "longitude": -78.413712,
      "city": "Blandburg",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16620,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Brisbin",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16621,
      "latitude": 40.1999,
      "longitude": -78.140812,
      "city": "Broad Top",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16622,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Calvin",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16623,
      "latitude": 40.285076,
      "longitude": -78.022032,
      "city": "Cassville",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16624,
      "latitude": 40.570219,
      "longitude": -78.599119,
      "city": "Chest Springs",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16625,
      "latitude": 40.354932,
      "longitude": -78.46266,
      "city": "Claysburg",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16627,
      "latitude": 40.749629,
      "longitude": -78.536067,
      "city": "Coalport",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16629,
      "latitude": 40.521778,
      "longitude": -78.526274,
      "city": "Coupon",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16630,
      "latitude": 40.470718,
      "longitude": -78.58946,
      "city": "Cresson",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16631,
      "latitude": 40.493522,
      "longitude": -78.368229,
      "city": "Curryville",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16633,
      "latitude": 40.160065,
      "longitude": -78.234638,
      "city": "Defiance",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16634,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Dudley",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16635,
      "latitude": 40.510278,
      "longitude": -78.407982,
      "city": "Duncansville",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16636,
      "latitude": 40.60772,
      "longitude": -78.526783,
      "city": "Dysart",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16637,
      "latitude": 40.349335,
      "longitude": -78.442466,
      "city": "East Freedom",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16638,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Entriken",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16639,
      "latitude": 40.671195,
      "longitude": -78.508823,
      "city": "Fallentimber",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16640,
      "latitude": 40.687598,
      "longitude": -78.552346,
      "city": "Flinton",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16641,
      "latitude": 40.507567,
      "longitude": -78.576208,
      "city": "Gallitzin",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16644,
      "latitude": 40.717845,
      "longitude": -78.463679,
      "city": "Glasgow",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16645,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Glen Hope",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16646,
      "latitude": 40.680896,
      "longitude": -78.599266,
      "city": "Hastings",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16647,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Hesston",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16648,
      "latitude": 40.432059,
      "longitude": -78.364965,
      "city": "Hollidaysburg",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16650,
      "latitude": 40.108984,
      "longitude": -78.279287,
      "city": "Hopewell",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16651,
      "latitude": 40.78995,
      "longitude": -78.377189,
      "city": "Houtzdale",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16652,
      "latitude": 40.515371,
      "longitude": -77.975879,
      "city": "Huntingdon",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16654,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Huntingdon",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16655,
      "latitude": 40.250384,
      "longitude": -78.537796,
      "city": "Imler",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16656,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Irvona",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16657,
      "latitude": 40.385496,
      "longitude": -78.172245,
      "city": "James Creek",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16659,
      "latitude": 40.170266,
      "longitude": -78.385341,
      "city": "Loysburg",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16660,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Mc Connellstown",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16661,
      "latitude": 40.830471,
      "longitude": -78.435525,
      "city": "Madera",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16662,
      "latitude": 40.33984,
      "longitude": -78.336709,
      "city": "Martinsburg",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16663,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Morann",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16664,
      "latitude": 40.193882,
      "longitude": -78.426719,
      "city": "New Enterprise",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16665,
      "latitude": 40.385507,
      "longitude": -78.447236,
      "city": "Newry",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16666,
      "latitude": 40.822197,
      "longitude": -78.455355,
      "city": "Osceola Mills",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16667,
      "latitude": 40.181801,
      "longitude": -78.493371,
      "city": "Osterburg",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16668,
      "latitude": 40.633518,
      "longitude": -78.608257,
      "city": "Patton",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16669,
      "latitude": 40.583226,
      "longitude": -78.073445,
      "city": "Petersburg",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16670,
      "latitude": 40.254864,
      "longitude": -78.512462,
      "city": "Queen",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16671,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Ramey",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16672,
      "latitude": 40.175325,
      "longitude": -78.249266,
      "city": "Riddlesburg",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16673,
      "latitude": 40.335467,
      "longitude": -78.387541,
      "city": "Roaring Spring",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16674,
      "latitude": 40.186465,
      "longitude": -78.116188,
      "city": "Robertsdale",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16675,
      "latitude": 40.671181,
      "longitude": -78.675548,
      "city": "Saint Boniface",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16677,
      "latitude": 40.813628,
      "longitude": -78.238986,
      "city": "Sandy Ridge",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16678,
      "latitude": 40.201876,
      "longitude": -78.252528,
      "city": "Saxton",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16679,
      "latitude": 40.169799,
      "longitude": -78.212185,
      "city": "Six Mile Run",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16680,
      "latitude": 40.760116,
      "longitude": -78.450043,
      "city": "Smithmill",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16681,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Smokerun",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16682,
      "latitude": 40.271126,
      "longitude": -78.45878,
      "city": "Sproul",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16683,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Spruce Creek",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16684,
      "latitude": 40.636691,
      "longitude": -78.303614,
      "city": "Tipton",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16685,
      "latitude": 40.270965,
      "longitude": -78.077244,
      "city": "Todd",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16686,
      "latitude": 40.522068,
      "longitude": -78.340045,
      "city": "Tyrone",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16689,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Waterfall",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 16691,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Wells Tannery",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 16692,
      "latitude": 40.745169,
      "longitude": -78.67683,
      "city": "Westover",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16693,
      "latitude": 40.402532,
      "longitude": -78.255764,
      "city": "Williamsburg",
      "state": "PA",
      "county": "Blair"
  },
  {
      "zipCode": 16694,
      "latitude": 40.165987,
      "longitude": -78.138144,
      "city": "Wood",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16695,
      "latitude": 40.230406,
      "longitude": -78.358877,
      "city": "Woodbury",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 16698,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Houtzdale",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16699,
      "latitude": 40.484487,
      "longitude": -78.702224,
      "city": "Cresson",
      "state": "PA",
      "county": "Cambria"
  },
  {
      "zipCode": 16701,
      "latitude": 41.862341,
      "longitude": -78.63731,
      "city": "Bradford",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16720,
      "latitude": 41.615099,
      "longitude": -77.957975,
      "city": "Austin",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16724,
      "latitude": 41.801078,
      "longitude": -78.579739,
      "city": "Crosby",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16725,
      "latitude": 41.885487,
      "longitude": -78.723198,
      "city": "Custer City",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16726,
      "latitude": 41.818645,
      "longitude": -78.595669,
      "city": "Cyclone",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16727,
      "latitude": 41.982786,
      "longitude": -78.546304,
      "city": "Derrick City",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16728,
      "latitude": 41.415878,
      "longitude": -78.665057,
      "city": "De Young",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 16729,
      "latitude": 41.96089,
      "longitude": -78.486099,
      "city": "Duke Center",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16730,
      "latitude": 41.801078,
      "longitude": -78.579739,
      "city": "East Smethport",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16731,
      "latitude": 41.941328,
      "longitude": -78.361927,
      "city": "Eldred",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16732,
      "latitude": 41.801078,
      "longitude": -78.579739,
      "city": "Gifford",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16733,
      "latitude": 41.801078,
      "longitude": -78.579739,
      "city": "Hazel Hurst",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16734,
      "latitude": 41.619213,
      "longitude": -78.839413,
      "city": "James City",
      "state": "PA",
      "county": "Elk"
  },
  {
      "zipCode": 16735,
      "latitude": 41.795662,
      "longitude": -78.631755,
      "city": "Kane",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16738,
      "latitude": 41.836866,
      "longitude": -78.691582,
      "city": "Lewis Run",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16740,
      "latitude": 41.719284,
      "longitude": -78.623042,
      "city": "Mount Jewett",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16743,
      "latitude": 41.881851,
      "longitude": -78.457878,
      "city": "Port Allegany",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16744,
      "latitude": 41.87833,
      "longitude": -78.557219,
      "city": "Rew",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16745,
      "latitude": 41.919565,
      "longitude": -78.48561,
      "city": "Rixford",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16746,
      "latitude": 41.737627,
      "longitude": -77.902366,
      "city": "Roulette",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16748,
      "latitude": 41.963791,
      "longitude": -78.188866,
      "city": "Shinglehouse",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16749,
      "latitude": 41.798748,
      "longitude": -78.539192,
      "city": "Smethport",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16750,
      "latitude": 41.801078,
      "longitude": -78.579739,
      "city": "Turtlepoint",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16751,
      "latitude": 41.801078,
      "longitude": -78.579739,
      "city": "Westline",
      "state": "PA",
      "county": "Mckean"
  },
  {
      "zipCode": 16801,
      "latitude": 40.881935,
      "longitude": -77.867822,
      "city": "State College",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16802,
      "latitude": 40.799672,
      "longitude": -77.862339,
      "city": "University Park",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16803,
      "latitude": 40.878721,
      "longitude": -77.757999,
      "city": "State College",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16804,
      "latitude": 40.972229,
      "longitude": -77.760172,
      "city": "State College",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16805,
      "latitude": 40.972229,
      "longitude": -77.760172,
      "city": "State College",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16820,
      "latitude": 40.89869,
      "longitude": -77.456184,
      "city": "Aaronsburg",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16821,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Allport",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16822,
      "latitude": 41.105476,
      "longitude": -77.50553,
      "city": "Beech Creek",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 16823,
      "latitude": 40.897823,
      "longitude": -77.773188,
      "city": "Bellefonte",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16825,
      "latitude": 40.990006,
      "longitude": -78.360612,
      "city": "Bigler",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16826,
      "latitude": 40.972229,
      "longitude": -77.760172,
      "city": "Blanchard",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16827,
      "latitude": 40.885162,
      "longitude": -77.644896,
      "city": "Boalsburg",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16828,
      "latitude": 40.807973,
      "longitude": -77.703942,
      "city": "Centre Hall",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16829,
      "latitude": 40.932545,
      "longitude": -77.763472,
      "city": "Clarence",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16830,
      "latitude": 41.037563,
      "longitude": -78.435492,
      "city": "Clearfield",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16832,
      "latitude": 40.838329,
      "longitude": -77.450929,
      "city": "Coburn",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16833,
      "latitude": 40.949208,
      "longitude": -78.582309,
      "city": "Curwensville",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16834,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Drifting",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16835,
      "latitude": 40.904645,
      "longitude": -77.875191,
      "city": "Fleming",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16836,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Frenchville",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16837,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Glen Richey",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16838,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Grampian",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16839,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Grassflat",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16840,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Hawk Run",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16841,
      "latitude": 40.918199,
      "longitude": -77.679655,
      "city": "Howard",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16843,
      "latitude": 41.003007,
      "longitude": -78.464189,
      "city": "Hyde",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16844,
      "latitude": 40.901934,
      "longitude": -77.750812,
      "city": "Julian",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16845,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Karthaus",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16847,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Kylertown",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16848,
      "latitude": 41.220635,
      "longitude": -77.618532,
      "city": "Lamar",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 16849,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Lanse",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16850,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Lecontes Mills",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16851,
      "latitude": 40.808151,
      "longitude": -77.812525,
      "city": "Lemont",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16852,
      "latitude": 40.972229,
      "longitude": -77.760172,
      "city": "Madisonburg",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16853,
      "latitude": 40.95408,
      "longitude": -77.781465,
      "city": "Milesburg",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16854,
      "latitude": 40.893455,
      "longitude": -77.473298,
      "city": "Millheim",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16855,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Mineral Springs",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16856,
      "latitude": 40.929851,
      "longitude": -77.638892,
      "city": "Mingoville",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16858,
      "latitude": 40.96891,
      "longitude": -78.191551,
      "city": "Morrisdale",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16859,
      "latitude": 40.900499,
      "longitude": -78.025894,
      "city": "Moshannon",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16860,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Munson",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16861,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "New Millport",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16863,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Olanta",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16864,
      "latitude": 40.972229,
      "longitude": -77.760172,
      "city": "Orviston",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16865,
      "latitude": 40.868075,
      "longitude": -77.957409,
      "city": "Pennsylvania Furnace",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16866,
      "latitude": 40.905166,
      "longitude": -77.844216,
      "city": "Philipsburg",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16868,
      "latitude": 40.726405,
      "longitude": -77.911776,
      "city": "Pine Grove Mills",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16870,
      "latitude": 40.913584,
      "longitude": -77.829726,
      "city": "Port Matilda",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16871,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Pottersdale",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16872,
      "latitude": 40.939915,
      "longitude": -77.448451,
      "city": "Rebersburg",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16873,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Shawville",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16874,
      "latitude": 40.992146,
      "longitude": -78.027865,
      "city": "Snow Shoe",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16875,
      "latitude": 40.88368,
      "longitude": -77.518716,
      "city": "Spring Mills",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16876,
      "latitude": 40.961582,
      "longitude": -78.292599,
      "city": "Wallaceton",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16877,
      "latitude": 40.692101,
      "longitude": -78.165044,
      "city": "Warriors Mark",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 16878,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "West Decatur",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16879,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Winburne",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16881,
      "latitude": 40.989115,
      "longitude": -78.422403,
      "city": "Woodland",
      "state": "PA",
      "county": "Clearfield"
  },
  {
      "zipCode": 16882,
      "latitude": 40.897081,
      "longitude": -77.402419,
      "city": "Woodward",
      "state": "PA",
      "county": "Centre"
  },
  {
      "zipCode": 16901,
      "latitude": 41.862339,
      "longitude": -77.258974,
      "city": "Wellsboro",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16910,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Alba",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 16911,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Arnot",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16912,
      "latitude": 41.822885,
      "longitude": -77.200274,
      "city": "Blossburg",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16914,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Columbia Cross Roads",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 16915,
      "latitude": 41.776165,
      "longitude": -77.956692,
      "city": "Coudersport",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16917,
      "latitude": 41.769456,
      "longitude": -77.000451,
      "city": "Covington",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16918,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Cowanesque",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16920,
      "latitude": 41.979118,
      "longitude": -77.370183,
      "city": "Elkland",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16921,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Gaines",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16922,
      "latitude": 41.733379,
      "longitude": -77.644214,
      "city": "Galeton",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16923,
      "latitude": 41.737627,
      "longitude": -77.902366,
      "city": "Genesee",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16925,
      "latitude": 41.938208,
      "longitude": -76.800579,
      "city": "Gillett",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 16926,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Granville Summit",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 16927,
      "latitude": 41.737627,
      "longitude": -77.902366,
      "city": "Harrison Valley",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16928,
      "latitude": 41.93602,
      "longitude": -77.445729,
      "city": "Knoxville",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16929,
      "latitude": 41.984078,
      "longitude": -77.181189,
      "city": "Lawrenceville",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16930,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Liberty",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16932,
      "latitude": 41.790065,
      "longitude": -77.035282,
      "city": "Mainesburg",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16933,
      "latitude": 41.793666,
      "longitude": -77.072151,
      "city": "Mansfield",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16935,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Middlebury Center",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16936,
      "latitude": 41.90843,
      "longitude": -77.011231,
      "city": "Millerton",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16937,
      "latitude": 41.737627,
      "longitude": -77.902366,
      "city": "Mills",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16938,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Morris",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16939,
      "latitude": 41.677542,
      "longitude": -77.015194,
      "city": "Morris Run",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16940,
      "latitude": 41.977039,
      "longitude": -77.24531,
      "city": "Nelson",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16941,
      "latitude": 41.737627,
      "longitude": -77.902366,
      "city": "Genesee",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16942,
      "latitude": 41.989264,
      "longitude": -77.347725,
      "city": "Osceola",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16943,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Sabinsville",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16945,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Sylvania",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 16946,
      "latitude": 41.943444,
      "longitude": -77.2292,
      "city": "Tioga",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 16947,
      "latitude": 41.788389,
      "longitude": -76.837246,
      "city": "Troy",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 16948,
      "latitude": 41.843678,
      "longitude": -77.75736,
      "city": "Ulysses",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 16950,
      "latitude": 41.919341,
      "longitude": -77.523033,
      "city": "Westfield",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 17001,
      "latitude": 40.21604,
      "longitude": -76.924996,
      "city": "Camp Hill",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17002,
      "latitude": 40.539667,
      "longitude": -77.790168,
      "city": "Allensville",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17003,
      "latitude": 40.352637,
      "longitude": -76.435873,
      "city": "Annville",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17004,
      "latitude": 40.591417,
      "longitude": -77.73103,
      "city": "Belleville",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17005,
      "latitude": 40.600992,
      "longitude": -76.811693,
      "city": "Berrysburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17006,
      "latitude": 40.29736,
      "longitude": -77.56437,
      "city": "Blain",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17007,
      "latitude": 40.141205,
      "longitude": -77.242351,
      "city": "Boiling Springs",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17008,
      "latitude": 40.167091,
      "longitude": -76.978448,
      "city": "Bowmansdale",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17009,
      "latitude": 40.629711,
      "longitude": -77.607196,
      "city": "Burnham",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17010,
      "latitude": 40.27124,
      "longitude": -76.582049,
      "city": "Campbelltown",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17011,
      "latitude": 40.251914,
      "longitude": -77.079091,
      "city": "Camp Hill",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17012,
      "latitude": 40.136687,
      "longitude": -77.242805,
      "city": "Camp Hill",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17013,
      "latitude": 40.168495,
      "longitude": -77.228817,
      "city": "Carlisle",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17014,
      "latitude": 40.478716,
      "longitude": -77.345514,
      "city": "Cocolamus",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17016,
      "latitude": 40.275536,
      "longitude": -76.405309,
      "city": "Cornwall",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17017,
      "latitude": 40.894363,
      "longitude": -76.596151,
      "city": "Dalmatia",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17018,
      "latitude": 40.361876,
      "longitude": -76.895539,
      "city": "Dauphin",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17019,
      "latitude": 40.091217,
      "longitude": -76.977835,
      "city": "Dillsburg",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17020,
      "latitude": 40.458261,
      "longitude": -77.060745,
      "city": "Duncannon",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17021,
      "latitude": 40.478716,
      "longitude": -77.345514,
      "city": "East Waterford",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17022,
      "latitude": 40.150963,
      "longitude": -76.61028,
      "city": "Elizabethtown",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17023,
      "latitude": 40.584804,
      "longitude": -76.767913,
      "city": "Elizabethville",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17024,
      "latitude": 40.442354,
      "longitude": -77.27088,
      "city": "Elliottsburg",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17025,
      "latitude": 40.235675,
      "longitude": -76.987249,
      "city": "Enola",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17026,
      "latitude": 40.456193,
      "longitude": -76.434639,
      "city": "Fredericksburg",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17027,
      "latitude": 40.157981,
      "longitude": -76.996398,
      "city": "Grantham",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17028,
      "latitude": 40.373438,
      "longitude": -76.682667,
      "city": "Grantville",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17029,
      "latitude": 40.550937,
      "longitude": -77.626095,
      "city": "Granville",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17030,
      "latitude": 40.608245,
      "longitude": -76.737545,
      "city": "Gratz",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17031,
      "latitude": 40.406766,
      "longitude": -77.292578,
      "city": "Green Park",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17032,
      "latitude": 40.507107,
      "longitude": -76.843844,
      "city": "Halifax",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17033,
      "latitude": 40.269748,
      "longitude": -76.636357,
      "city": "Hershey",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17034,
      "latitude": 40.329577,
      "longitude": -76.834076,
      "city": "Highspire",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17035,
      "latitude": 40.478716,
      "longitude": -77.345514,
      "city": "Honey Grove",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17036,
      "latitude": 40.340043,
      "longitude": -76.782764,
      "city": "Hummelstown",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17037,
      "latitude": 40.436276,
      "longitude": -77.397013,
      "city": "Ickesburg",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17038,
      "latitude": 40.430902,
      "longitude": -76.480732,
      "city": "Jonestown",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17039,
      "latitude": 40.300505,
      "longitude": -76.258399,
      "city": "Kleinfeltersville",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17040,
      "latitude": 40.34253,
      "longitude": -77.304476,
      "city": "Landisburg",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17041,
      "latitude": 40.223623,
      "longitude": -76.538007,
      "city": "Lawn",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17042,
      "latitude": 40.331594,
      "longitude": -76.397634,
      "city": "Lebanon",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17043,
      "latitude": 40.244339,
      "longitude": -76.918796,
      "city": "Lemoyne",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17044,
      "latitude": 40.564909,
      "longitude": -77.630152,
      "city": "Lewistown",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17045,
      "latitude": 40.574445,
      "longitude": -76.987538,
      "city": "Liverpool",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17046,
      "latitude": 40.381212,
      "longitude": -76.436791,
      "city": "Lebanon",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17047,
      "latitude": 40.406766,
      "longitude": -77.292578,
      "city": "Loysville",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17048,
      "latitude": 40.558844,
      "longitude": -76.790132,
      "city": "Lykens",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17049,
      "latitude": 40.651725,
      "longitude": -77.306056,
      "city": "Mc Alisterville",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17050,
      "latitude": 40.247547,
      "longitude": -77.030441,
      "city": "Mechanicsburg",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17051,
      "latitude": 40.478095,
      "longitude": -77.734919,
      "city": "Mc Veytown",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17052,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Mapleton Depot",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17053,
      "latitude": 40.325105,
      "longitude": -77.025886,
      "city": "Marysville",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17054,
      "latitude": 40.495979,
      "longitude": -77.723716,
      "city": "Mattawana",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17055,
      "latitude": 40.180953,
      "longitude": -77.177086,
      "city": "Mechanicsburg",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17056,
      "latitude": 40.478716,
      "longitude": -77.345514,
      "city": "Mexico",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17057,
      "latitude": 40.191241,
      "longitude": -76.720142,
      "city": "Middletown",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17058,
      "latitude": 40.555304,
      "longitude": -77.400062,
      "city": "Mifflin",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17059,
      "latitude": 40.571404,
      "longitude": -77.317266,
      "city": "Mifflintown",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17060,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Mill Creek",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17061,
      "latitude": 40.571494,
      "longitude": -76.840528,
      "city": "Millersburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17062,
      "latitude": 40.556195,
      "longitude": -77.154223,
      "city": "Millerstown",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17063,
      "latitude": 40.740026,
      "longitude": -77.493466,
      "city": "Milroy",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17064,
      "latitude": 40.254642,
      "longitude": -76.461544,
      "city": "Mount Gretna",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17065,
      "latitude": 40.202649,
      "longitude": -77.08213,
      "city": "Mount Holly Springs",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17066,
      "latitude": 40.382302,
      "longitude": -77.882222,
      "city": "Mount Union",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17067,
      "latitude": 40.368282,
      "longitude": -76.359975,
      "city": "Myerstown",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17068,
      "latitude": 40.405796,
      "longitude": -77.134043,
      "city": "New Bloomfield",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17069,
      "latitude": 40.455272,
      "longitude": -76.970942,
      "city": "New Buffalo",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17070,
      "latitude": 40.263875,
      "longitude": -76.898096,
      "city": "New Cumberland",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17071,
      "latitude": 40.406766,
      "longitude": -77.292578,
      "city": "New Germantown",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17072,
      "latitude": 40.230601,
      "longitude": -77.079425,
      "city": "New Kingstown",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17073,
      "latitude": 40.314112,
      "longitude": -76.26055,
      "city": "Newmanstown",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17074,
      "latitude": 40.506053,
      "longitude": -77.129129,
      "city": "Newport",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17075,
      "latitude": 40.39313,
      "longitude": -77.831635,
      "city": "Newton Hamilton",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17076,
      "latitude": 40.478716,
      "longitude": -77.345514,
      "city": "Oakland Mills",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17077,
      "latitude": 40.375404,
      "longitude": -76.414757,
      "city": "Ono",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17078,
      "latitude": 40.317606,
      "longitude": -76.416907,
      "city": "Palmyra",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17080,
      "latitude": 40.639199,
      "longitude": -76.80292,
      "city": "Pillow",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17081,
      "latitude": 40.203019,
      "longitude": -77.284761,
      "city": "Plainfield",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17082,
      "latitude": 40.549581,
      "longitude": -77.395272,
      "city": "Port Royal",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17083,
      "latitude": 40.27639,
      "longitude": -76.411239,
      "city": "Quentin",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17084,
      "latitude": 40.541706,
      "longitude": -77.692538,
      "city": "Reedsville",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17085,
      "latitude": 40.277076,
      "longitude": -76.385727,
      "city": "Rexmont",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17086,
      "latitude": 40.478716,
      "longitude": -77.345514,
      "city": "Richfield",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17087,
      "latitude": 40.362956,
      "longitude": -76.271943,
      "city": "Richland",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17088,
      "latitude": 40.308696,
      "longitude": -76.296308,
      "city": "Schaefferstown",
      "state": "PA",
      "county": "Lebanon"
  },
  {
      "zipCode": 17089,
      "latitude": 40.269738,
      "longitude": -76.936046,
      "city": "Camp Hill",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17090,
      "latitude": 40.342999,
      "longitude": -77.182569,
      "city": "Shermans Dale",
      "state": "PA",
      "county": "Perry"
  },
  {
      "zipCode": 17091,
      "latitude": 40.136687,
      "longitude": -77.242805,
      "city": "Camp Hill",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17093,
      "latitude": 40.293538,
      "longitude": -76.926346,
      "city": "Summerdale",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17094,
      "latitude": 40.565325,
      "longitude": -77.235359,
      "city": "Thompsontown",
      "state": "PA",
      "county": "Juniata"
  },
  {
      "zipCode": 17097,
      "latitude": 40.573734,
      "longitude": -76.65693,
      "city": "Wiconisco",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17098,
      "latitude": 40.588313,
      "longitude": -76.695547,
      "city": "Williamstown",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17099,
      "latitude": 40.644505,
      "longitude": -77.567708,
      "city": "Yeagertown",
      "state": "PA",
      "county": "Mifflin"
  },
  {
      "zipCode": 17101,
      "latitude": 40.264589,
      "longitude": -76.869644,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17102,
      "latitude": 40.272538,
      "longitude": -76.890695,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17103,
      "latitude": 40.272389,
      "longitude": -76.860994,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17104,
      "latitude": 40.253639,
      "longitude": -76.857944,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17105,
      "latitude": 40.278498,
      "longitude": -76.875207,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17106,
      "latitude": 40.292664,
      "longitude": -76.850061,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17107,
      "latitude": 40.297031,
      "longitude": -76.876437,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17108,
      "latitude": 40.308638,
      "longitude": -76.801693,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17109,
      "latitude": 40.290953,
      "longitude": -76.820294,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17110,
      "latitude": 40.302238,
      "longitude": -76.866348,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17111,
      "latitude": 40.272089,
      "longitude": -76.801693,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17112,
      "latitude": 40.344295,
      "longitude": -76.790248,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17113,
      "latitude": 40.23904,
      "longitude": -76.841644,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17120,
      "latitude": 40.265689,
      "longitude": -76.882745,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17121,
      "latitude": 40.294396,
      "longitude": -76.893751,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17122,
      "latitude": 40.249845,
      "longitude": -76.871209,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17123,
      "latitude": 40.267518,
      "longitude": -76.883734,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17124,
      "latitude": 40.26746,
      "longitude": -76.885967,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17125,
      "latitude": 40.266221,
      "longitude": -76.882854,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17126,
      "latitude": 40.26179,
      "longitude": -76.880021,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17127,
      "latitude": 40.261516,
      "longitude": -76.880884,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17128,
      "latitude": 40.389865,
      "longitude": -76.782323,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17129,
      "latitude": 40.261516,
      "longitude": -76.880884,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17130,
      "latitude": 40.270222,
      "longitude": -76.882889,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17140,
      "latitude": 40.30864,
      "longitude": -76.846449,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17177,
      "latitude": 40.298988,
      "longitude": -76.847194,
      "city": "Harrisburg",
      "state": "PA",
      "county": "Dauphin"
  },
  {
      "zipCode": 17201,
      "latitude": 39.908055,
      "longitude": -77.666445,
      "city": "Chambersburg",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17210,
      "latitude": 40.171667,
      "longitude": -77.661354,
      "city": "Amberson",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17211,
      "latitude": 39.755155,
      "longitude": -78.406388,
      "city": "Artemas",
      "state": "PA",
      "county": "Bedford"
  },
  {
      "zipCode": 17212,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Big Cove Tannery",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17213,
      "latitude": 40.17973,
      "longitude": -77.865942,
      "city": "Blairs Mills",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17214,
      "latitude": 39.739893,
      "longitude": -77.470729,
      "city": "Blue Ridge Summit",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17215,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Burnt Cabins",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17217,
      "latitude": 40.225168,
      "longitude": -77.725013,
      "city": "Concord",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17219,
      "latitude": 40.218448,
      "longitude": -77.679713,
      "city": "Doylesburg",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17220,
      "latitude": 40.178081,
      "longitude": -77.735336,
      "city": "Dry Run",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17221,
      "latitude": 40.073155,
      "longitude": -77.832158,
      "city": "Fannettsburg",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17222,
      "latitude": 39.900035,
      "longitude": -77.529619,
      "city": "Fayetteville",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17223,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Fort Littleton",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17224,
      "latitude": 40.002593,
      "longitude": -77.809574,
      "city": "Fort Loudon",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17225,
      "latitude": 39.805922,
      "longitude": -77.726127,
      "city": "Greencastle",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17228,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Harrisonville",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17229,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Hustontown",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17231,
      "latitude": 39.859432,
      "longitude": -77.857889,
      "city": "Lemasters",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17232,
      "latitude": 40.105495,
      "longitude": -77.640461,
      "city": "Lurgan",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17233,
      "latitude": 39.928812,
      "longitude": -77.981379,
      "city": "Mc Connellsburg",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17235,
      "latitude": 39.858699,
      "longitude": -77.69815,
      "city": "Marion",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17236,
      "latitude": 39.819034,
      "longitude": -77.798952,
      "city": "Mercersburg",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17237,
      "latitude": 39.83593,
      "longitude": -77.553776,
      "city": "Mont Alto",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17238,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Needmore",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17239,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Neelyton",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17240,
      "latitude": 40.174798,
      "longitude": -77.470285,
      "city": "Newburg",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17241,
      "latitude": 40.173093,
      "longitude": -77.421905,
      "city": "Newville",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17243,
      "latitude": 40.225376,
      "longitude": -77.887496,
      "city": "Orbisonia",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17244,
      "latitude": 40.079556,
      "longitude": -77.679344,
      "city": "Orrstown",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17246,
      "latitude": 40.049118,
      "longitude": -77.671754,
      "city": "Pleasant Hall",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17247,
      "latitude": 39.799481,
      "longitude": -77.581092,
      "city": "Quincy",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17249,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Rockhill Furnace",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17250,
      "latitude": 39.736395,
      "longitude": -77.52474,
      "city": "Rouzerville",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17251,
      "latitude": 40.112652,
      "longitude": -77.670626,
      "city": "Roxbury",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17252,
      "latitude": 39.916149,
      "longitude": -77.811826,
      "city": "Saint Thomas",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17253,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Saltillo",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17254,
      "latitude": 39.96959,
      "longitude": -77.584841,
      "city": "Scotland",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17255,
      "latitude": 40.133259,
      "longitude": -77.87475,
      "city": "Shade Gap",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17256,
      "latitude": 39.783391,
      "longitude": -77.674851,
      "city": "Shady Grove",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17257,
      "latitude": 40.070789,
      "longitude": -77.45983,
      "city": "Shippensburg",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17260,
      "latitude": 40.402523,
      "longitude": -77.966977,
      "city": "Shirleysburg",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17261,
      "latitude": 39.833095,
      "longitude": -77.488633,
      "city": "South Mountain",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17262,
      "latitude": 40.173439,
      "longitude": -77.709212,
      "city": "Spring Run",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17263,
      "latitude": 39.724825,
      "longitude": -77.718629,
      "city": "State Line",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17264,
      "latitude": 40.222408,
      "longitude": -77.941975,
      "city": "Three Springs",
      "state": "PA",
      "county": "Huntingdon"
  },
  {
      "zipCode": 17265,
      "latitude": 40.054782,
      "longitude": -77.775921,
      "city": "Upperstrasburg",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17266,
      "latitude": 40.104094,
      "longitude": -77.472794,
      "city": "Walnut Bottom",
      "state": "PA",
      "county": "Cumberland"
  },
  {
      "zipCode": 17267,
      "latitude": 39.943762,
      "longitude": -78.122265,
      "city": "Warfordsburg",
      "state": "PA",
      "county": "Fulton"
  },
  {
      "zipCode": 17268,
      "latitude": 39.793552,
      "longitude": -77.59228,
      "city": "Waynesboro",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17270,
      "latitude": 39.854018,
      "longitude": -77.799733,
      "city": "Williamson",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17271,
      "latitude": 40.108243,
      "longitude": -77.807221,
      "city": "Willow Hill",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17272,
      "latitude": 39.771372,
      "longitude": -77.62702,
      "city": "Zullinger",
      "state": "PA",
      "county": "Franklin"
  },
  {
      "zipCode": 17301,
      "latitude": 39.896923,
      "longitude": -76.994725,
      "city": "Abbottstown",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17302,
      "latitude": 39.821313,
      "longitude": -76.405931,
      "city": "Airville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17303,
      "latitude": 39.923644,
      "longitude": -77.300085,
      "city": "Arendtsville",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17304,
      "latitude": 39.976602,
      "longitude": -77.227126,
      "city": "Aspers",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17306,
      "latitude": 39.979154,
      "longitude": -77.249592,
      "city": "Bendersville",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17307,
      "latitude": 39.933286,
      "longitude": -77.287491,
      "city": "Biglerville",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17309,
      "latitude": 39.860758,
      "longitude": -76.467305,
      "city": "Brogue",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17310,
      "latitude": 39.890605,
      "longitude": -77.356642,
      "city": "Cashtown",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17311,
      "latitude": 39.871756,
      "longitude": -76.759884,
      "city": "Codorus",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17312,
      "latitude": 39.946739,
      "longitude": -76.506639,
      "city": "Craley",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17313,
      "latitude": 39.912423,
      "longitude": -76.65353,
      "city": "Dallastown",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17314,
      "latitude": 39.792142,
      "longitude": -76.54384,
      "city": "Delta",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17315,
      "latitude": 40.004544,
      "longitude": -76.884956,
      "city": "Dover",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17316,
      "latitude": 39.876563,
      "longitude": -77.027176,
      "city": "East Berlin",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17317,
      "latitude": 39.97014,
      "longitude": -76.521686,
      "city": "East Prospect",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17318,
      "latitude": 40.021496,
      "longitude": -76.726638,
      "city": "Emigsville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17319,
      "latitude": 40.150726,
      "longitude": -76.798353,
      "city": "Etters",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17320,
      "latitude": 39.780789,
      "longitude": -77.361859,
      "city": "Fairfield",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17321,
      "latitude": 39.784476,
      "longitude": -76.518286,
      "city": "Fawn Grove",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17322,
      "latitude": 39.844726,
      "longitude": -76.576556,
      "city": "Felton",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17323,
      "latitude": 40.07501,
      "longitude": -77.027926,
      "city": "Franklintown",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17324,
      "latitude": 40.017772,
      "longitude": -77.193663,
      "city": "Gardners",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17325,
      "latitude": 39.840922,
      "longitude": -77.226591,
      "city": "Gettysburg",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17326,
      "latitude": 39.8948,
      "longitude": -77.213493,
      "city": "Gettysburg",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17327,
      "latitude": 39.892205,
      "longitude": -76.750637,
      "city": "Glen Rock",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17329,
      "latitude": 39.766883,
      "longitude": -76.877706,
      "city": "Glenville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17331,
      "latitude": 39.935502,
      "longitude": -76.672739,
      "city": "Hanover",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17332,
      "latitude": 39.775037,
      "longitude": -76.735116,
      "city": "Hanover",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17333,
      "latitude": 39.972985,
      "longitude": -76.687826,
      "city": "Hanover",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17334,
      "latitude": 39.80899,
      "longitude": -76.968124,
      "city": "Hanover",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17337,
      "latitude": 40.015656,
      "longitude": -77.200131,
      "city": "Idaville",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17339,
      "latitude": 39.995219,
      "longitude": -76.889249,
      "city": "Lewisberry",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17340,
      "latitude": 39.829806,
      "longitude": -77.095399,
      "city": "Littlestown",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17342,
      "latitude": 39.853644,
      "longitude": -76.707997,
      "city": "Loganville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17343,
      "latitude": 39.869229,
      "longitude": -77.329248,
      "city": "Mc Knightstown",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17344,
      "latitude": 39.801004,
      "longitude": -77.022875,
      "city": "Mc Sherrystown",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17345,
      "latitude": 39.95406,
      "longitude": -76.835812,
      "city": "Manchester",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17347,
      "latitude": 39.942848,
      "longitude": -76.552798,
      "city": "Mount Wolf",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17349,
      "latitude": 39.866988,
      "longitude": -76.633324,
      "city": "New Freedom",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17350,
      "latitude": 39.881881,
      "longitude": -77.077431,
      "city": "New Oxford",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17352,
      "latitude": 39.838639,
      "longitude": -76.449874,
      "city": "New Park",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17353,
      "latitude": 39.882976,
      "longitude": -77.393593,
      "city": "Orrtanna",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17354,
      "latitude": 39.82457,
      "longitude": -76.89927,
      "city": "Porters Sideling",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17355,
      "latitude": 39.757328,
      "longitude": -76.699442,
      "city": "Railroad",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17356,
      "latitude": 39.905747,
      "longitude": -76.615805,
      "city": "Red Lion",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17358,
      "latitude": 39.972985,
      "longitude": -76.687826,
      "city": "Rossville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17360,
      "latitude": 39.823461,
      "longitude": -76.700714,
      "city": "Seven Valleys",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17361,
      "latitude": 39.799799,
      "longitude": -76.687931,
      "city": "Shrewsbury",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17362,
      "latitude": 39.944531,
      "longitude": -76.775774,
      "city": "Spring Grove",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17363,
      "latitude": 39.809151,
      "longitude": -76.606747,
      "city": "Stewartstown",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17364,
      "latitude": 39.933775,
      "longitude": -76.905317,
      "city": "Thomasville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17365,
      "latitude": 40.018835,
      "longitude": -76.784811,
      "city": "Wellsville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17366,
      "latitude": 40.006581,
      "longitude": -76.613232,
      "city": "Windsor",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17368,
      "latitude": 39.994482,
      "longitude": -76.677736,
      "city": "Wrightsville",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17370,
      "latitude": 40.067766,
      "longitude": -76.720322,
      "city": "York Haven",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17371,
      "latitude": 39.900579,
      "longitude": -76.789624,
      "city": "York New Salem",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17372,
      "latitude": 40.007937,
      "longitude": -77.100231,
      "city": "York Springs",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17375,
      "latitude": 39.8948,
      "longitude": -77.213493,
      "city": "Peach Glen",
      "state": "PA",
      "county": "Adams"
  },
  {
      "zipCode": 17401,
      "latitude": 39.962998,
      "longitude": -76.727139,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17402,
      "latitude": 40.001615,
      "longitude": -76.689918,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17403,
      "latitude": 39.93939,
      "longitude": -76.666257,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17404,
      "latitude": 39.934957,
      "longitude": -76.577982,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17405,
      "latitude": 40.008647,
      "longitude": -76.597187,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17406,
      "latitude": 40.004593,
      "longitude": -76.594727,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17407,
      "latitude": 39.897907,
      "longitude": -76.662569,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17415,
      "latitude": 39.972985,
      "longitude": -76.687826,
      "city": "York",
      "state": "PA",
      "county": "York"
  },
  {
      "zipCode": 17501,
      "latitude": 40.129894,
      "longitude": -76.361053,
      "city": "Akron",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17502,
      "latitude": 40.088469,
      "longitude": -76.462434,
      "city": "Bainbridge",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17503,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Bart",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17504,
      "latitude": 40.024047,
      "longitude": -76.328081,
      "city": "Bausman",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17505,
      "latitude": 40.066647,
      "longitude": -76.187926,
      "city": "Bird In Hand",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17506,
      "latitude": 40.117326,
      "longitude": -76.052379,
      "city": "Blue Ball",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17507,
      "latitude": 40.196641,
      "longitude": -76.016047,
      "city": "Bowmansville",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17508,
      "latitude": 40.125896,
      "longitude": -76.213013,
      "city": "Brownstown",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17509,
      "latitude": 39.903954,
      "longitude": -76.040115,
      "city": "Christiana",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17512,
      "latitude": 40.11675,
      "longitude": -76.369028,
      "city": "Columbia",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17516,
      "latitude": 40.026391,
      "longitude": -76.262044,
      "city": "Conestoga",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17517,
      "latitude": 40.188445,
      "longitude": -76.337734,
      "city": "Denver",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17518,
      "latitude": 39.818309,
      "longitude": -76.249931,
      "city": "Drumore",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17519,
      "latitude": 40.142759,
      "longitude": -76.019741,
      "city": "East Earl",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17520,
      "latitude": 40.097468,
      "longitude": -76.356832,
      "city": "East Petersburg",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17521,
      "latitude": 40.204393,
      "longitude": -76.34643,
      "city": "Elm",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17522,
      "latitude": 40.169662,
      "longitude": -76.356933,
      "city": "Ephrata",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17527,
      "latitude": 40.011961,
      "longitude": -76.021595,
      "city": "Gap",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17528,
      "latitude": 40.043523,
      "longitude": -76.238766,
      "city": "Goodville",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17529,
      "latitude": 40.043967,
      "longitude": -76.108543,
      "city": "Gordonville",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17532,
      "latitude": 39.86632,
      "longitude": -76.28062,
      "city": "Holtwood",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17533,
      "latitude": 40.233892,
      "longitude": -76.260725,
      "city": "Hopeland",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17534,
      "latitude": 40.036659,
      "longitude": -76.106902,
      "city": "Intercourse",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17535,
      "latitude": 40.005276,
      "longitude": -76.042921,
      "city": "Kinzers",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17536,
      "latitude": 39.842724,
      "longitude": -76.080469,
      "city": "Kirkwood",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17537,
      "latitude": 39.988548,
      "longitude": -76.238729,
      "city": "Lampeter",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17538,
      "latitude": 40.112477,
      "longitude": -76.36089,
      "city": "Landisville",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17540,
      "latitude": 40.100846,
      "longitude": -76.191873,
      "city": "Leola",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17543,
      "latitude": 40.134761,
      "longitude": -76.455212,
      "city": "Lititz",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17545,
      "latitude": 40.155927,
      "longitude": -76.464018,
      "city": "Manheim",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17547,
      "latitude": 40.061596,
      "longitude": -76.485935,
      "city": "Marietta",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17549,
      "latitude": 40.154498,
      "longitude": -76.08756,
      "city": "Martindale",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17550,
      "latitude": 40.072108,
      "longitude": -76.57779,
      "city": "Maytown",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17551,
      "latitude": 40.031882,
      "longitude": -76.236823,
      "city": "Millersville",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17552,
      "latitude": 40.113745,
      "longitude": -76.354,
      "city": "Mount Joy",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17554,
      "latitude": 40.038842,
      "longitude": -76.425978,
      "city": "Mountville",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17555,
      "latitude": 40.120279,
      "longitude": -75.961183,
      "city": "Narvon",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17557,
      "latitude": 40.14607,
      "longitude": -76.073814,
      "city": "New Holland",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17560,
      "latitude": 39.912288,
      "longitude": -76.237428,
      "city": "New Providence",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17562,
      "latitude": 39.898072,
      "longitude": -76.091234,
      "city": "Paradise",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17563,
      "latitude": 39.844788,
      "longitude": -76.155069,
      "city": "Peach Bottom",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17564,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Penryn",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17565,
      "latitude": 39.904409,
      "longitude": -76.322481,
      "city": "Pequea",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17566,
      "latitude": 39.856368,
      "longitude": -76.148506,
      "city": "Quarryville",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17567,
      "latitude": 40.209941,
      "longitude": -76.116445,
      "city": "Reamstown",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17568,
      "latitude": 39.9469,
      "longitude": -76.232229,
      "city": "Refton",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17569,
      "latitude": 40.152713,
      "longitude": -76.091179,
      "city": "Reinholds",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17570,
      "latitude": 40.129645,
      "longitude": -76.571739,
      "city": "Rheems",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17572,
      "latitude": 40.015026,
      "longitude": -76.149994,
      "city": "Ronks",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17573,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Ronks",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17575,
      "latitude": 40.064746,
      "longitude": -76.434285,
      "city": "Silver Spring",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17576,
      "latitude": 40.036398,
      "longitude": -76.203069,
      "city": "Smoketown",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17577,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Soudersburg",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17578,
      "latitude": 40.168624,
      "longitude": -76.330001,
      "city": "Stevens",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17579,
      "latitude": 40.082226,
      "longitude": -76.113772,
      "city": "Strasburg",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17580,
      "latitude": 40.116846,
      "longitude": -76.213075,
      "city": "Talmage",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17581,
      "latitude": 40.157576,
      "longitude": -76.046317,
      "city": "Terre Hill",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17582,
      "latitude": 39.990664,
      "longitude": -76.43485,
      "city": "Washington Boro",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17583,
      "latitude": 39.972349,
      "longitude": -76.28733,
      "city": "West Willow",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17584,
      "latitude": 39.959349,
      "longitude": -76.259779,
      "city": "Willow Street",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17585,
      "latitude": 40.048398,
      "longitude": -76.211427,
      "city": "Witmer",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17601,
      "latitude": 40.076553,
      "longitude": -76.31068,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17602,
      "latitude": 40.004048,
      "longitude": -76.249829,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17603,
      "latitude": 40.009147,
      "longitude": -76.367132,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17604,
      "latitude": 40.065096,
      "longitude": -76.335631,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17605,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17606,
      "latitude": 40.110201,
      "longitude": -76.305378,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17607,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17608,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17699,
      "latitude": 40.0185,
      "longitude": -76.297582,
      "city": "Lancaster",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 17701,
      "latitude": 41.266716,
      "longitude": -76.958314,
      "city": "Williamsport",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17702,
      "latitude": 41.194306,
      "longitude": -77.054665,
      "city": "Williamsport",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17703,
      "latitude": 41.33275,
      "longitude": -77.023601,
      "city": "Williamsport",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17705,
      "latitude": 41.33275,
      "longitude": -77.023601,
      "city": "Williamsport",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17720,
      "latitude": 41.186545,
      "longitude": -77.218368,
      "city": "Antes Fort",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17721,
      "latitude": 41.186028,
      "longitude": -77.316191,
      "city": "Avis",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17722,
      "latitude": 41.33275,
      "longitude": -77.023601,
      "city": "Bodines",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17723,
      "latitude": 41.33275,
      "longitude": -77.023601,
      "city": "Jersey Shore",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17724,
      "latitude": 41.639449,
      "longitude": -76.84143,
      "city": "Canton",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 17726,
      "latitude": 41.122741,
      "longitude": -77.431745,
      "city": "Castanea",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17727,
      "latitude": 41.33275,
      "longitude": -77.023601,
      "city": "Cedar Run",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17728,
      "latitude": 41.255299,
      "longitude": -76.948034,
      "city": "Cogan Station",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17729,
      "latitude": 41.737627,
      "longitude": -77.902366,
      "city": "Cross Fork",
      "state": "PA",
      "county": "Potter"
  },
  {
      "zipCode": 17730,
      "latitude": 41.10891,
      "longitude": -76.877491,
      "city": "Dewart",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17731,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Eagles Mere",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 17735,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Grover",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 17737,
      "latitude": 41.280128,
      "longitude": -76.6753,
      "city": "Hughesville",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17738,
      "latitude": 41.220635,
      "longitude": -77.618532,
      "city": "Hyner",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17739,
      "latitude": 41.33275,
      "longitude": -77.023601,
      "city": "Jersey Mills",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17740,
      "latitude": 41.208518,
      "longitude": -77.025687,
      "city": "Jersey Shore",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17742,
      "latitude": 41.229734,
      "longitude": -76.594727,
      "city": "Lairdsville",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17743,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Leroy",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 17744,
      "latitude": 41.251994,
      "longitude": -77.161674,
      "city": "Linden",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17745,
      "latitude": 41.229022,
      "longitude": -77.641745,
      "city": "Lock Haven",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17747,
      "latitude": 41.018519,
      "longitude": -77.341611,
      "city": "Loganton",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17748,
      "latitude": 41.135507,
      "longitude": -77.369964,
      "city": "Mc Elhattan",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17749,
      "latitude": 41.071982,
      "longitude": -76.818381,
      "city": "Mc Ewensville",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17750,
      "latitude": 41.032529,
      "longitude": -77.495147,
      "city": "Mackeyville",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17751,
      "latitude": 41.071123,
      "longitude": -77.476304,
      "city": "Mill Hall",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17752,
      "latitude": 41.214561,
      "longitude": -76.856502,
      "city": "Montgomery",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17754,
      "latitude": 41.307308,
      "longitude": -76.881055,
      "city": "Montoursville",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17756,
      "latitude": 41.244348,
      "longitude": -76.74048,
      "city": "Muncy",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17758,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Muncy Valley",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 17759,
      "latitude": 41.218259,
      "longitude": -77.12137,
      "city": "Nisbet",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17760,
      "latitude": 41.220635,
      "longitude": -77.618532,
      "city": "North Bend",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17762,
      "latitude": 41.221474,
      "longitude": -76.659535,
      "city": "Picture Rocks",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17763,
      "latitude": 41.498523,
      "longitude": -76.946724,
      "city": "Ralston",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17764,
      "latitude": 41.306045,
      "longitude": -77.571221,
      "city": "Renovo",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17765,
      "latitude": 41.77197,
      "longitude": -77.242228,
      "city": "Roaring Branch",
      "state": "PA",
      "county": "Tioga"
  },
  {
      "zipCode": 17767,
      "latitude": 41.220635,
      "longitude": -77.618532,
      "city": "Salona",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17768,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Shunk",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 17769,
      "latitude": 41.33275,
      "longitude": -77.023601,
      "city": "Slate Run",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17771,
      "latitude": 41.438766,
      "longitude": -77.06138,
      "city": "Trout Run",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17772,
      "latitude": 41.10285,
      "longitude": -76.823084,
      "city": "Turbotville",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17773,
      "latitude": 41.220635,
      "longitude": -77.618532,
      "city": "Tylersville",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17774,
      "latitude": 41.227207,
      "longitude": -76.646221,
      "city": "Unityville",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17776,
      "latitude": 41.37857,
      "longitude": -77.331293,
      "city": "Waterville",
      "state": "PA",
      "county": "Lycoming"
  },
  {
      "zipCode": 17777,
      "latitude": 41.097981,
      "longitude": -76.850306,
      "city": "Watsontown",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17778,
      "latitude": 41.270789,
      "longitude": -77.969664,
      "city": "Westport",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17779,
      "latitude": 41.187424,
      "longitude": -77.372827,
      "city": "Woolrich",
      "state": "PA",
      "county": "Clinton"
  },
  {
      "zipCode": 17801,
      "latitude": 40.869766,
      "longitude": -76.735577,
      "city": "Sunbury",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17810,
      "latitude": 40.978059,
      "longitude": -77.08184,
      "city": "Allenwood",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17812,
      "latitude": 40.726469,
      "longitude": -77.272828,
      "city": "Beaver Springs",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17813,
      "latitude": 40.754705,
      "longitude": -77.169508,
      "city": "Beavertown",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17814,
      "latitude": 41.218998,
      "longitude": -76.395426,
      "city": "Benton",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17815,
      "latitude": 41.005609,
      "longitude": -76.430563,
      "city": "Bloomsburg",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17820,
      "latitude": 41.020674,
      "longitude": -76.436239,
      "city": "Catawissa",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17821,
      "latitude": 40.991968,
      "longitude": -76.647082,
      "city": "Danville",
      "state": "PA",
      "county": "Montour"
  },
  {
      "zipCode": 17822,
      "latitude": 40.967437,
      "longitude": -76.604896,
      "city": "Danville",
      "state": "PA",
      "county": "Montour"
  },
  {
      "zipCode": 17823,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Dornsife",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17824,
      "latitude": 40.859435,
      "longitude": -76.550811,
      "city": "Elysburg",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17825,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Excelsior",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17827,
      "latitude": 40.765049,
      "longitude": -76.939544,
      "city": "Freeburg",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17828,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Gowen City",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17829,
      "latitude": 40.882861,
      "longitude": -77.189174,
      "city": "Hartleton",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17830,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Herndon",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17831,
      "latitude": 40.834408,
      "longitude": -76.835439,
      "city": "Hummels Wharf",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17832,
      "latitude": 40.805096,
      "longitude": -76.464607,
      "city": "Marion Heights",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17833,
      "latitude": 40.762675,
      "longitude": -77.07752,
      "city": "Kreamer",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17834,
      "latitude": 40.793938,
      "longitude": -76.476946,
      "city": "Kulpmont",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17835,
      "latitude": 40.978059,
      "longitude": -77.08184,
      "city": "Laurelton",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17836,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Leck Kill",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17837,
      "latitude": 40.969642,
      "longitude": -76.948072,
      "city": "Lewisburg",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17839,
      "latitude": 41.020062,
      "longitude": -76.438413,
      "city": "Light Street",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17840,
      "latitude": 40.772687,
      "longitude": -76.438311,
      "city": "Locust Gap",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17841,
      "latitude": 40.766765,
      "longitude": -77.150271,
      "city": "Mc Clure",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17842,
      "latitude": 40.779808,
      "longitude": -77.117558,
      "city": "Middleburg",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17843,
      "latitude": 40.762675,
      "longitude": -77.07752,
      "city": "Beaver Springs",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17844,
      "latitude": 40.947833,
      "longitude": -77.011087,
      "city": "Mifflinburg",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17845,
      "latitude": 40.978059,
      "longitude": -77.08184,
      "city": "Millmont",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17846,
      "latitude": 41.143816,
      "longitude": -76.525177,
      "city": "Millville",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17847,
      "latitude": 40.993883,
      "longitude": -76.847896,
      "city": "Milton",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17850,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Montandon",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17851,
      "latitude": 40.803636,
      "longitude": -76.428612,
      "city": "Mount Carmel",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17853,
      "latitude": 40.723706,
      "longitude": -77.013547,
      "city": "Mount Pleasant Mills",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17855,
      "latitude": 40.882689,
      "longitude": -76.984667,
      "city": "New Berlin",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17856,
      "latitude": 41.048043,
      "longitude": -76.903895,
      "city": "New Columbia",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17857,
      "latitude": 40.90805,
      "longitude": -76.784197,
      "city": "Northumberland",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17858,
      "latitude": 41.042106,
      "longitude": -76.424297,
      "city": "Numidia",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17859,
      "latitude": 41.061483,
      "longitude": -76.418093,
      "city": "Orangeville",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17860,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Paxinos",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17861,
      "latitude": 40.762675,
      "longitude": -77.07752,
      "city": "Paxtonville",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17862,
      "latitude": 40.857426,
      "longitude": -77.064941,
      "city": "Penns Creek",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17864,
      "latitude": 40.695087,
      "longitude": -76.903957,
      "city": "Port Trevorton",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17865,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Potts Grove",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17866,
      "latitude": 40.791688,
      "longitude": -76.551871,
      "city": "Coal Township",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17867,
      "latitude": 40.889632,
      "longitude": -76.664574,
      "city": "Rebuck",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17868,
      "latitude": 40.952908,
      "longitude": -76.631118,
      "city": "Riverside",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17870,
      "latitude": 40.825914,
      "longitude": -76.857577,
      "city": "Selinsgrove",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17872,
      "latitude": 40.791953,
      "longitude": -76.601592,
      "city": "Shamokin",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17876,
      "latitude": 40.845896,
      "longitude": -76.82241,
      "city": "Shamokin Dam",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17877,
      "latitude": 40.87897,
      "longitude": -76.667267,
      "city": "Snydertown",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17878,
      "latitude": 41.120644,
      "longitude": -76.412386,
      "city": "Stillwater",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17880,
      "latitude": 40.978059,
      "longitude": -77.08184,
      "city": "Swengel",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17881,
      "latitude": 40.782155,
      "longitude": -76.672737,
      "city": "Trevorton",
      "state": "PA",
      "county": "Northumberland"
  },
  {
      "zipCode": 17882,
      "latitude": 40.762675,
      "longitude": -77.07752,
      "city": "Troxelville",
      "state": "PA",
      "county": "Snyder"
  },
  {
      "zipCode": 17883,
      "latitude": 40.978059,
      "longitude": -77.08184,
      "city": "Vicksburg",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17884,
      "latitude": 41.027277,
      "longitude": -76.653633,
      "city": "Washingtonville",
      "state": "PA",
      "county": "Montour"
  },
  {
      "zipCode": 17885,
      "latitude": 40.978059,
      "longitude": -77.08184,
      "city": "Weikert",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17886,
      "latitude": 41.01789,
      "longitude": -76.870841,
      "city": "West Milton",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17887,
      "latitude": 40.978059,
      "longitude": -77.08184,
      "city": "White Deer",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17888,
      "latitude": 40.815401,
      "longitude": -76.368632,
      "city": "Wilburton",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17889,
      "latitude": 40.905947,
      "longitude": -76.859662,
      "city": "Winfield",
      "state": "PA",
      "county": "Union"
  },
  {
      "zipCode": 17901,
      "latitude": 40.708751,
      "longitude": -76.282986,
      "city": "Pottsville",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17920,
      "latitude": 40.816696,
      "longitude": -76.350279,
      "city": "Aristes",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17921,
      "latitude": 40.692886,
      "longitude": -76.303085,
      "city": "Ashland",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17922,
      "latitude": 40.59782,
      "longitude": -76.14932,
      "city": "Auburn",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17923,
      "latitude": 40.67602,
      "longitude": -76.316013,
      "city": "Branchdale",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17925,
      "latitude": 40.750796,
      "longitude": -76.062859,
      "city": "Brockton",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17927,
      "latitude": 40.804881,
      "longitude": -76.341156,
      "city": "Centralia",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 17929,
      "latitude": 40.675475,
      "longitude": -76.165297,
      "city": "Cressona",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17930,
      "latitude": 40.711356,
      "longitude": -76.139162,
      "city": "Cumbola",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17931,
      "latitude": 40.692869,
      "longitude": -76.348699,
      "city": "Frackville",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17932,
      "latitude": 40.649109,
      "longitude": -76.503339,
      "city": "Frackville",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17933,
      "latitude": 40.595462,
      "longitude": -76.246441,
      "city": "Friedensburg",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17934,
      "latitude": 40.798623,
      "longitude": -76.21553,
      "city": "Gilberton",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17935,
      "latitude": 40.795384,
      "longitude": -76.270574,
      "city": "Girardville",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17936,
      "latitude": 40.748879,
      "longitude": -76.335499,
      "city": "Gordon",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17938,
      "latitude": 40.688219,
      "longitude": -76.539205,
      "city": "Hegins",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17941,
      "latitude": 40.687178,
      "longitude": -76.578173,
      "city": "Klingerstown",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17942,
      "latitude": 40.722587,
      "longitude": -76.229623,
      "city": "Landingville",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17943,
      "latitude": 40.761438,
      "longitude": -76.387242,
      "city": "Lavelle",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17944,
      "latitude": 40.67578,
      "longitude": -76.2819,
      "city": "Llewellyn",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17945,
      "latitude": 40.774531,
      "longitude": -76.370427,
      "city": "Locustdale",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17946,
      "latitude": 40.808081,
      "longitude": -76.240747,
      "city": "Lost Creek",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17948,
      "latitude": 40.733004,
      "longitude": -76.306129,
      "city": "Mahanoy City",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17949,
      "latitude": 40.794007,
      "longitude": -76.243323,
      "city": "Mahanoy Plane",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17951,
      "latitude": 40.682985,
      "longitude": -76.238725,
      "city": "Mar Lin",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17952,
      "latitude": 40.747301,
      "longitude": -76.062825,
      "city": "Mary D",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17953,
      "latitude": 40.733072,
      "longitude": -76.085783,
      "city": "Middleport",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17954,
      "latitude": 40.711461,
      "longitude": -76.199621,
      "city": "Minersville",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17957,
      "latitude": 40.592578,
      "longitude": -76.516075,
      "city": "Muir",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17959,
      "latitude": 40.675413,
      "longitude": -76.155786,
      "city": "New Philadelphia",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17960,
      "latitude": 40.703416,
      "longitude": -75.959812,
      "city": "New Ringgold",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17961,
      "latitude": 40.618065,
      "longitude": -76.206854,
      "city": "Orwigsburg",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17963,
      "latitude": 40.577029,
      "longitude": -76.33716,
      "city": "Pine Grove",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17964,
      "latitude": 40.724376,
      "longitude": -76.488833,
      "city": "Pitman",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17965,
      "latitude": 40.771333,
      "longitude": -76.199259,
      "city": "Port Carbon",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17966,
      "latitude": 40.722587,
      "longitude": -76.229623,
      "city": "Ravine",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17967,
      "latitude": 40.856227,
      "longitude": -76.23464,
      "city": "Ringtown",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17968,
      "latitude": 40.636539,
      "longitude": -76.591109,
      "city": "Sacramento",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17970,
      "latitude": 40.707055,
      "longitude": -76.284374,
      "city": "Saint Clair",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17972,
      "latitude": 40.602048,
      "longitude": -76.154856,
      "city": "Schuylkill Haven",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17974,
      "latitude": 40.690632,
      "longitude": -76.227294,
      "city": "Seltzer",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17976,
      "latitude": 40.763239,
      "longitude": -76.148397,
      "city": "Shenandoah",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17978,
      "latitude": 40.625754,
      "longitude": -76.621769,
      "city": "Spring Glen",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17979,
      "latitude": 40.554516,
      "longitude": -76.203767,
      "city": "Summit Station",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17980,
      "latitude": 40.590428,
      "longitude": -76.53981,
      "city": "Tower City",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17981,
      "latitude": 40.694624,
      "longitude": -76.306539,
      "city": "Tremont",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17982,
      "latitude": 40.781484,
      "longitude": -76.013399,
      "city": "Tuscarora",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17983,
      "latitude": 40.645911,
      "longitude": -76.532384,
      "city": "Valley View",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 17985,
      "latitude": 40.914137,
      "longitude": -76.193099,
      "city": "Zion Grove",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18001,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Lehigh Valley",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18002,
      "latitude": 40.665597,
      "longitude": -75.426247,
      "city": "Lehigh Valley",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18003,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Lehigh Valley",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18010,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Ackermanville",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18011,
      "latitude": 40.514472,
      "longitude": -75.602941,
      "city": "Alburtis",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18012,
      "latitude": 40.813281,
      "longitude": -75.59202,
      "city": "Aquashicola",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18013,
      "latitude": 40.766465,
      "longitude": -75.315246,
      "city": "Bangor",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18014,
      "latitude": 40.733142,
      "longitude": -75.351785,
      "city": "Bath",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18015,
      "latitude": 40.58883,
      "longitude": -75.351958,
      "city": "Bethlehem",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18016,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Bethlehem",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18017,
      "latitude": 40.662211,
      "longitude": -75.39027,
      "city": "Bethlehem",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18018,
      "latitude": 40.625297,
      "longitude": -75.379174,
      "city": "Bethlehem",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18020,
      "latitude": 40.669251,
      "longitude": -75.334887,
      "city": "Bethlehem",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18025,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Bethlehem",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18030,
      "latitude": 40.801062,
      "longitude": -75.661425,
      "city": "Bowmanstown",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18031,
      "latitude": 40.550733,
      "longitude": -75.540673,
      "city": "Breinigsville",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18032,
      "latitude": 40.602633,
      "longitude": -75.472276,
      "city": "Catasauqua",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18034,
      "latitude": 40.543998,
      "longitude": -75.41242,
      "city": "Center Valley",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18035,
      "latitude": 40.750996,
      "longitude": -75.53937,
      "city": "Cherryville",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18036,
      "latitude": 40.502424,
      "longitude": -75.411145,
      "city": "Coopersburg",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18037,
      "latitude": 40.616645,
      "longitude": -75.489587,
      "city": "Coplay",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18038,
      "latitude": 40.79071,
      "longitude": -75.482967,
      "city": "Danielsville",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18039,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Durham",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18040,
      "latitude": 40.806206,
      "longitude": -75.20887,
      "city": "Easton",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18041,
      "latitude": 40.382488,
      "longitude": -75.51541,
      "city": "East Greenville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18042,
      "latitude": 40.683198,
      "longitude": -75.264861,
      "city": "Easton",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18043,
      "latitude": 40.792804,
      "longitude": -75.137186,
      "city": "Easton",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18044,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Easton",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18045,
      "latitude": 40.695731,
      "longitude": -75.286528,
      "city": "Easton",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18046,
      "latitude": 40.538843,
      "longitude": -75.568502,
      "city": "East Texas",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18049,
      "latitude": 40.520705,
      "longitude": -75.496131,
      "city": "Emmaus",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18050,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Flicksville",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18051,
      "latitude": 40.599843,
      "longitude": -75.663187,
      "city": "Fogelsville",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18052,
      "latitude": 40.656746,
      "longitude": -75.504128,
      "city": "Whitehall",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18053,
      "latitude": 40.718662,
      "longitude": -75.700739,
      "city": "Germansville",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18054,
      "latitude": 40.289663,
      "longitude": -75.450703,
      "city": "Green Lane",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18055,
      "latitude": 40.588799,
      "longitude": -75.313756,
      "city": "Hellertown",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18056,
      "latitude": 40.454205,
      "longitude": -75.581444,
      "city": "Hereford",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 18058,
      "latitude": 40.903599,
      "longitude": -75.476885,
      "city": "Kunkletown",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18059,
      "latitude": 40.717684,
      "longitude": -75.533488,
      "city": "Laurys Station",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18060,
      "latitude": 40.53484,
      "longitude": -75.57864,
      "city": "Limeport",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18062,
      "latitude": 40.517851,
      "longitude": -75.565028,
      "city": "Macungie",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18063,
      "latitude": 40.782456,
      "longitude": -75.173494,
      "city": "Martins Creek",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18064,
      "latitude": 40.737648,
      "longitude": -75.272892,
      "city": "Nazareth",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18065,
      "latitude": 40.696738,
      "longitude": -75.611647,
      "city": "Neffs",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18066,
      "latitude": 40.652611,
      "longitude": -75.759361,
      "city": "New Tripoli",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18067,
      "latitude": 40.724479,
      "longitude": -75.365412,
      "city": "Northampton",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18068,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Old Zionsville",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18069,
      "latitude": 40.629605,
      "longitude": -75.621885,
      "city": "Orefield",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18070,
      "latitude": 40.430181,
      "longitude": -75.531182,
      "city": "Palm",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18071,
      "latitude": 40.855728,
      "longitude": -75.58918,
      "city": "Palmerton",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18072,
      "latitude": 40.841099,
      "longitude": -75.258376,
      "city": "Pen Argyl",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18073,
      "latitude": 40.374347,
      "longitude": -75.482175,
      "city": "Pennsburg",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18074,
      "latitude": 40.320832,
      "longitude": -75.500492,
      "city": "Perkiomenville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18076,
      "latitude": 40.387419,
      "longitude": -75.505547,
      "city": "Red Hill",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18077,
      "latitude": 40.567316,
      "longitude": -75.236191,
      "city": "Riegelsville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18078,
      "latitude": 40.669675,
      "longitude": -75.620901,
      "city": "Schnecksville",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18079,
      "latitude": 40.745524,
      "longitude": -75.659209,
      "city": "Slatedale",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18080,
      "latitude": 40.734537,
      "longitude": -75.618611,
      "city": "Slatington",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18081,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Springtown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18083,
      "latitude": 40.772873,
      "longitude": -75.363531,
      "city": "Stockertown",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18084,
      "latitude": 40.327782,
      "longitude": -75.454043,
      "city": "Sumneytown",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18085,
      "latitude": 40.740625,
      "longitude": -75.254854,
      "city": "Tatamy",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18086,
      "latitude": 40.736119,
      "longitude": -75.54494,
      "city": "Treichlers",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18087,
      "latitude": 40.548196,
      "longitude": -75.596131,
      "city": "Trexlertown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18088,
      "latitude": 40.759591,
      "longitude": -75.428538,
      "city": "Walnutport",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18091,
      "latitude": 40.801131,
      "longitude": -75.310958,
      "city": "Wind Gap",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18092,
      "latitude": 40.467193,
      "longitude": -75.512091,
      "city": "Zionsville",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18098,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Emmaus",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18099,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Emmaus",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18101,
      "latitude": 40.607497,
      "longitude": -75.470026,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18102,
      "latitude": 40.607006,
      "longitude": -75.480877,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18103,
      "latitude": 40.604372,
      "longitude": -75.473127,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18104,
      "latitude": 40.621048,
      "longitude": -75.545306,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18105,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18106,
      "latitude": 40.582381,
      "longitude": -75.591132,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18109,
      "latitude": 40.693376,
      "longitude": -75.471156,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18175,
      "latitude": 40.585561,
      "longitude": -75.621058,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18195,
      "latitude": 40.57983,
      "longitude": -75.579884,
      "city": "Allentown",
      "state": "PA",
      "county": "Lehigh"
  },
  {
      "zipCode": 18201,
      "latitude": 40.964584,
      "longitude": -76.008333,
      "city": "Hazleton",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18202,
      "latitude": 40.954433,
      "longitude": -76.045987,
      "city": "Hazleton",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18210,
      "latitude": 41.012618,
      "longitude": -75.560751,
      "city": "Albrightsville",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18211,
      "latitude": 40.744932,
      "longitude": -75.83155,
      "city": "Andreas",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18212,
      "latitude": 40.784095,
      "longitude": -75.708281,
      "city": "Ashfield",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18214,
      "latitude": 40.80868,
      "longitude": -76.036009,
      "city": "Barnesville",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18216,
      "latitude": 40.932217,
      "longitude": -75.919196,
      "city": "Beaver Meadows",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18218,
      "latitude": 40.858951,
      "longitude": -76.013009,
      "city": "Coaldale",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18219,
      "latitude": 40.990662,
      "longitude": -76.056358,
      "city": "Conyngham",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18220,
      "latitude": 40.838915,
      "longitude": -76.069416,
      "city": "Delano",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18221,
      "latitude": 41.001634,
      "longitude": -75.905693,
      "city": "Drifton",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18222,
      "latitude": 41.034017,
      "longitude": -76.003059,
      "city": "Drums",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18223,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Ebervale",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18224,
      "latitude": 41.019593,
      "longitude": -75.881697,
      "city": "Freeland",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18225,
      "latitude": 40.988813,
      "longitude": -75.961651,
      "city": "Harleigh",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18229,
      "latitude": 40.896709,
      "longitude": -75.759303,
      "city": "Jim Thorpe",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18230,
      "latitude": 40.934763,
      "longitude": -75.73577,
      "city": "Junedale",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18231,
      "latitude": 40.900231,
      "longitude": -76.005382,
      "city": "Kelayres",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18232,
      "latitude": 40.872243,
      "longitude": -75.915416,
      "city": "Lansford",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18234,
      "latitude": 40.992517,
      "longitude": -75.962585,
      "city": "Lattimer Mines",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18235,
      "latitude": 40.886664,
      "longitude": -75.736164,
      "city": "Lehighton",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18237,
      "latitude": 40.897645,
      "longitude": -75.997791,
      "city": "Mcadoo",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18239,
      "latitude": 40.996051,
      "longitude": -75.981475,
      "city": "Milnesville",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18240,
      "latitude": 40.933194,
      "longitude": -75.76383,
      "city": "Nesquehoning",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18241,
      "latitude": 40.922289,
      "longitude": -76.14894,
      "city": "Nuremberg",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18242,
      "latitude": 40.910952,
      "longitude": -76.122324,
      "city": "Oneida",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18243,
      "latitude": 41.001683,
      "longitude": -75.966146,
      "city": "Pardeesville",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18244,
      "latitude": 40.824546,
      "longitude": -75.665068,
      "city": "Parryville",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18245,
      "latitude": 40.722587,
      "longitude": -76.229623,
      "city": "Quakake",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18246,
      "latitude": 40.954011,
      "longitude": -76.174707,
      "city": "Rock Glen",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18247,
      "latitude": 41.014808,
      "longitude": -76.058391,
      "city": "Saint Johns",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18248,
      "latitude": 40.901977,
      "longitude": -76.118764,
      "city": "Sheppton",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18249,
      "latitude": 40.975202,
      "longitude": -76.0845,
      "city": "Sugarloaf",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18250,
      "latitude": 40.90537,
      "longitude": -75.74156,
      "city": "Summit Hill",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18251,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Sybertsville",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18252,
      "latitude": 40.79849,
      "longitude": -75.937836,
      "city": "Tamaqua",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 18254,
      "latitude": 40.924803,
      "longitude": -75.938611,
      "city": "Tresckow",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18255,
      "latitude": 40.911443,
      "longitude": -75.779007,
      "city": "Weatherly",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18256,
      "latitude": 40.944777,
      "longitude": -76.145197,
      "city": "Weston",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18301,
      "latitude": 41.089642,
      "longitude": -75.199705,
      "city": "East Stroudsburg",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18320,
      "latitude": 41.071213,
      "longitude": -75.236436,
      "city": "Analomink",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18321,
      "latitude": 41.079679,
      "longitude": -75.319542,
      "city": "Bartonsville",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18322,
      "latitude": 41.054711,
      "longitude": -75.331879,
      "city": "Brodheadsville",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18323,
      "latitude": 41.033755,
      "longitude": -75.30798,
      "city": "Buck Hill Falls",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18324,
      "latitude": 41.185011,
      "longitude": -74.97889,
      "city": "Bushkill",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18325,
      "latitude": 41.187023,
      "longitude": -75.28593,
      "city": "Canadensis",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18326,
      "latitude": 41.152364,
      "longitude": -75.276395,
      "city": "Cresco",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18327,
      "latitude": 40.988562,
      "longitude": -75.151193,
      "city": "Delaware Water Gap",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18328,
      "latitude": 41.229482,
      "longitude": -74.967407,
      "city": "Dingmans Ferry",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18330,
      "latitude": 41.033192,
      "longitude": -75.449047,
      "city": "Effort",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18331,
      "latitude": 40.947398,
      "longitude": -75.366015,
      "city": "Gilbert",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18332,
      "latitude": 41.025342,
      "longitude": -75.323738,
      "city": "Henryville",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18333,
      "latitude": 40.95158,
      "longitude": -75.501306,
      "city": "Kresgeville",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18334,
      "latitude": 41.057236,
      "longitude": -75.465137,
      "city": "Long Pond",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18335,
      "latitude": 41.050764,
      "longitude": -75.208269,
      "city": "Marshalls Creek",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18336,
      "latitude": 41.370358,
      "longitude": -74.736057,
      "city": "Matamoras",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18337,
      "latitude": 41.320051,
      "longitude": -74.880639,
      "city": "Milford",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18340,
      "latitude": 41.413707,
      "longitude": -74.751138,
      "city": "Millrift",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18341,
      "latitude": 40.991053,
      "longitude": -75.299256,
      "city": "Minisink Hills",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18342,
      "latitude": 41.145778,
      "longitude": -75.294125,
      "city": "Mountainhome",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18343,
      "latitude": 40.898617,
      "longitude": -75.104575,
      "city": "Mount Bethel",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18344,
      "latitude": 41.136268,
      "longitude": -75.37092,
      "city": "Mount Pocono",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18346,
      "latitude": 41.094926,
      "longitude": -75.298177,
      "city": "Pocono Summit",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18347,
      "latitude": 41.13856,
      "longitude": -75.562988,
      "city": "Pocono Lake",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18348,
      "latitude": 41.033755,
      "longitude": -75.30798,
      "city": "Pocono Lake Preserve",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18349,
      "latitude": 41.122633,
      "longitude": -75.45882,
      "city": "Pocono Manor",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18350,
      "latitude": 41.083557,
      "longitude": -75.515614,
      "city": "Pocono Pines",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18351,
      "latitude": 40.921386,
      "longitude": -75.097007,
      "city": "Portland",
      "state": "PA",
      "county": "Northampton"
  },
  {
      "zipCode": 18352,
      "latitude": 40.986265,
      "longitude": -75.348026,
      "city": "Reeders",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18353,
      "latitude": 41.011645,
      "longitude": -75.254722,
      "city": "Saylorsburg",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18354,
      "latitude": 40.937939,
      "longitude": -75.292615,
      "city": "Sciota",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18355,
      "latitude": 41.090045,
      "longitude": -75.357801,
      "city": "Scotrun",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18356,
      "latitude": 41.031177,
      "longitude": -75.097435,
      "city": "Shawnee On Delaware",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18357,
      "latitude": 41.233562,
      "longitude": -75.238879,
      "city": "Skytop",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18360,
      "latitude": 41.041895,
      "longitude": -75.368964,
      "city": "Stroudsburg",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18370,
      "latitude": 41.105903,
      "longitude": -75.346913,
      "city": "Swiftwater",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18371,
      "latitude": 41.340936,
      "longitude": -75.024055,
      "city": "Tamiment",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18372,
      "latitude": 41.095823,
      "longitude": -75.323654,
      "city": "Tannersville",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18373,
      "latitude": 41.340936,
      "longitude": -75.024055,
      "city": "Unity House",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18401,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Aldenville",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18403,
      "latitude": 41.487598,
      "longitude": -75.533403,
      "city": "Archbald",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18405,
      "latitude": 41.582471,
      "longitude": -75.130121,
      "city": "Beach Lake",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18407,
      "latitude": 41.583122,
      "longitude": -75.505612,
      "city": "Carbondale",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18410,
      "latitude": 41.486527,
      "longitude": -75.693335,
      "city": "Chinchilla",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18411,
      "latitude": 41.463694,
      "longitude": -75.681992,
      "city": "Clarks Summit",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18413,
      "latitude": 41.654319,
      "longitude": -75.614463,
      "city": "Clifford",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18414,
      "latitude": 41.539797,
      "longitude": -75.719723,
      "city": "Dalton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18415,
      "latitude": 41.701677,
      "longitude": -75.133727,
      "city": "Damascus",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18416,
      "latitude": 41.375602,
      "longitude": -75.547966,
      "city": "Elmhurst",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18417,
      "latitude": 41.797309,
      "longitude": -75.206086,
      "city": "Equinunk",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18419,
      "latitude": 41.562365,
      "longitude": -75.782573,
      "city": "Factoryville",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18420,
      "latitude": 41.401881,
      "longitude": -75.637626,
      "city": "Fleetville",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18421,
      "latitude": 41.652272,
      "longitude": -75.531325,
      "city": "Forest City",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18424,
      "latitude": 41.346095,
      "longitude": -75.331956,
      "city": "Gouldsboro",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18425,
      "latitude": 41.41594,
      "longitude": -75.022543,
      "city": "Greeley",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18426,
      "latitude": 41.320734,
      "longitude": -75.199395,
      "city": "Greentown",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18427,
      "latitude": 41.422497,
      "longitude": -75.335384,
      "city": "Hamlin",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18428,
      "latitude": 41.482733,
      "longitude": -75.24019,
      "city": "Hawley",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18430,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Herrick Center",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18431,
      "latitude": 41.564258,
      "longitude": -75.265643,
      "city": "Honesdale",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18433,
      "latitude": 41.561174,
      "longitude": -75.618764,
      "city": "Jermyn",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18434,
      "latitude": 41.504326,
      "longitude": -75.572238,
      "city": "Jessup",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18435,
      "latitude": 41.475422,
      "longitude": -75.009368,
      "city": "Lackawaxen",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18436,
      "latitude": 41.452686,
      "longitude": -75.321884,
      "city": "Lake Ariel",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18437,
      "latitude": 41.617378,
      "longitude": -75.282339,
      "city": "Lake Como",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18438,
      "latitude": 41.431256,
      "longitude": -75.303874,
      "city": "Lakeville",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18439,
      "latitude": 41.828081,
      "longitude": -75.396741,
      "city": "Lakewood",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18440,
      "latitude": 41.551585,
      "longitude": -75.767583,
      "city": "La Plume",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18441,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Lenoxville",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18443,
      "latitude": 41.667024,
      "longitude": -75.118513,
      "city": "Milanville",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18444,
      "latitude": 41.321625,
      "longitude": -75.538602,
      "city": "Moscow",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18445,
      "latitude": 41.356766,
      "longitude": -75.381762,
      "city": "Newfoundland",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18446,
      "latitude": 41.627612,
      "longitude": -75.786129,
      "city": "Nicholson",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18447,
      "latitude": 41.494578,
      "longitude": -75.653932,
      "city": "Olyphant",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18448,
      "latitude": 41.46488,
      "longitude": -75.596231,
      "city": "Olyphant",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18449,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Orson",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18451,
      "latitude": 41.340936,
      "longitude": -75.024055,
      "city": "Paupack",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18452,
      "latitude": 41.350663,
      "longitude": -75.552502,
      "city": "Peckville",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18453,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Pleasant Mount",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18454,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Poyntelle",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18455,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Preston Park",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18456,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Prompton",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18457,
      "latitude": 41.340936,
      "longitude": -75.024055,
      "city": "Rowland",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18458,
      "latitude": 41.414928,
      "longitude": -74.934461,
      "city": "Shohola",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18459,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "South Canaan",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18460,
      "latitude": 41.259242,
      "longitude": -75.373122,
      "city": "South Sterling",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18461,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Starlight",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18462,
      "latitude": 41.89529,
      "longitude": -75.42428,
      "city": "Starrucca",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18463,
      "latitude": 41.376953,
      "longitude": -75.31601,
      "city": "Sterling",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18464,
      "latitude": 41.396988,
      "longitude": -75.186881,
      "city": "Tafton",
      "state": "PA",
      "county": "Pike"
  },
  {
      "zipCode": 18465,
      "latitude": 41.84015,
      "longitude": -75.531898,
      "city": "Thompson",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18466,
      "latitude": 41.17551,
      "longitude": -75.461757,
      "city": "Tobyhanna",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18469,
      "latitude": 41.615989,
      "longitude": -75.274708,
      "city": "Tyler Hill",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18470,
      "latitude": 41.698964,
      "longitude": -75.529304,
      "city": "Union Dale",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18471,
      "latitude": 41.523812,
      "longitude": -75.690608,
      "city": "Waverly",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18472,
      "latitude": 41.613395,
      "longitude": -75.371218,
      "city": "Waymart",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18473,
      "latitude": 41.540648,
      "longitude": -75.222927,
      "city": "White Mills",
      "state": "PA",
      "county": "Wayne"
  },
  {
      "zipCode": 18501,
      "latitude": 41.401881,
      "longitude": -75.637626,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18502,
      "latitude": 41.350303,
      "longitude": -75.689965,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18503,
      "latitude": 41.410079,
      "longitude": -75.666784,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18504,
      "latitude": 41.421572,
      "longitude": -75.691955,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18505,
      "latitude": 41.413137,
      "longitude": -75.65194,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18507,
      "latitude": 41.361332,
      "longitude": -75.706584,
      "city": "Moosic",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18508,
      "latitude": 41.439979,
      "longitude": -75.66843,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18509,
      "latitude": 41.42908,
      "longitude": -75.647633,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18510,
      "latitude": 41.396665,
      "longitude": -75.652869,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18512,
      "latitude": 41.43503,
      "longitude": -75.610332,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18514,
      "latitude": 41.401881,
      "longitude": -75.637626,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18515,
      "latitude": 41.403529,
      "longitude": -75.706485,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18517,
      "latitude": 41.382529,
      "longitude": -75.713185,
      "city": "Taylor",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18518,
      "latitude": 41.372779,
      "longitude": -75.730836,
      "city": "Old Forge",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18519,
      "latitude": 41.463118,
      "longitude": -75.629099,
      "city": "Dickson City",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18522,
      "latitude": 41.401881,
      "longitude": -75.637626,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18540,
      "latitude": 41.401881,
      "longitude": -75.637626,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18577,
      "latitude": 41.401881,
      "longitude": -75.637626,
      "city": "Scranton",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18601,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Beach Haven",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18602,
      "latitude": 41.192177,
      "longitude": -75.716073,
      "city": "Bear Creek",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18603,
      "latitude": 41.056142,
      "longitude": -76.299844,
      "city": "Berwick",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 18610,
      "latitude": 41.085529,
      "longitude": -75.499384,
      "city": "Blakeslee",
      "state": "PA",
      "county": "Monroe"
  },
  {
      "zipCode": 18611,
      "latitude": 41.110259,
      "longitude": -75.775517,
      "city": "Cambra",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18612,
      "latitude": 41.271121,
      "longitude": -75.999621,
      "city": "Dallas",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18614,
      "latitude": 41.524261,
      "longitude": -76.397554,
      "city": "Dushore",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 18615,
      "latitude": 41.447109,
      "longitude": -75.855544,
      "city": "Falls",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18616,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Forksville",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 18617,
      "latitude": 41.265528,
      "longitude": -76.052153,
      "city": "Glen Lyon",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18618,
      "latitude": 41.37385,
      "longitude": -75.969059,
      "city": "Harveys Lake",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18619,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Hillsgrove",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 18621,
      "latitude": 41.28317,
      "longitude": -76.064414,
      "city": "Hunlock Creek",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18622,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Huntington Mills",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18623,
      "latitude": 41.632632,
      "longitude": -76.154464,
      "city": "Laceyville",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18624,
      "latitude": 41.048947,
      "longitude": -75.60858,
      "city": "Lake Harmony",
      "state": "PA",
      "county": "Carbon"
  },
  {
      "zipCode": 18625,
      "latitude": 41.513852,
      "longitude": -75.841598,
      "city": "Lake Winola",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18626,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Laporte",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 18627,
      "latitude": 41.316584,
      "longitude": -76.021037,
      "city": "Lehman",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18628,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Lopez",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 18629,
      "latitude": 41.27708,
      "longitude": -75.960156,
      "city": "Mehoopany",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18630,
      "latitude": 41.27708,
      "longitude": -75.960156,
      "city": "Meshoppen",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18631,
      "latitude": 40.98713,
      "longitude": -76.287669,
      "city": "Mifflinville",
      "state": "PA",
      "county": "Columbia"
  },
  {
      "zipCode": 18632,
      "latitude": 41.432901,
      "longitude": -76.516887,
      "city": "Mildred",
      "state": "PA",
      "county": "Sullivan"
  },
  {
      "zipCode": 18634,
      "latitude": 41.249808,
      "longitude": -76.008195,
      "city": "Nanticoke",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18635,
      "latitude": 41.049051,
      "longitude": -76.189182,
      "city": "Nescopeck",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18636,
      "latitude": 41.27708,
      "longitude": -75.960156,
      "city": "Noxen",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18640,
      "latitude": 41.298152,
      "longitude": -75.85856,
      "city": "Pittston",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18641,
      "latitude": 41.238649,
      "longitude": -75.868267,
      "city": "Pittston",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18642,
      "latitude": 41.217263,
      "longitude": -75.938105,
      "city": "Duryea",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18643,
      "latitude": 41.262312,
      "longitude": -75.816983,
      "city": "Pittston",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18644,
      "latitude": 41.337161,
      "longitude": -75.912401,
      "city": "Wyoming",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18651,
      "latitude": 41.28871,
      "longitude": -75.98764,
      "city": "Plymouth",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18653,
      "latitude": 41.394526,
      "longitude": -75.824239,
      "city": "Ransom",
      "state": "PA",
      "county": "Lackawanna"
  },
  {
      "zipCode": 18654,
      "latitude": 41.350053,
      "longitude": -76.03193,
      "city": "Shawanese",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18655,
      "latitude": 41.151321,
      "longitude": -76.108103,
      "city": "Shickshinny",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18656,
      "latitude": 41.288634,
      "longitude": -76.142277,
      "city": "Sweet Valley",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18657,
      "latitude": 41.566386,
      "longitude": -75.975715,
      "city": "Tunkhannock",
      "state": "PA",
      "county": "Wyoming"
  },
  {
      "zipCode": 18660,
      "latitude": 41.142698,
      "longitude": -76.039935,
      "city": "Wapwallopen",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18661,
      "latitude": 41.114503,
      "longitude": -75.909158,
      "city": "White Haven",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18690,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Dallas",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18701,
      "latitude": 41.203631,
      "longitude": -75.88434,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18702,
      "latitude": 41.211028,
      "longitude": -75.838169,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18703,
      "latitude": 41.242081,
      "longitude": -75.88569,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18704,
      "latitude": 41.27843,
      "longitude": -75.905842,
      "city": "Kingston",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18705,
      "latitude": 41.261699,
      "longitude": -75.849123,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18706,
      "latitude": 41.2313,
      "longitude": -75.896241,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18707,
      "latitude": 41.166401,
      "longitude": -75.961432,
      "city": "Mountain Top",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18708,
      "latitude": 41.24083,
      "longitude": -75.947216,
      "city": "Shavertown",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18709,
      "latitude": 41.28603,
      "longitude": -75.89509,
      "city": "Luzerne",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18710,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18711,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18761,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18762,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18763,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18764,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18765,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18766,
      "latitude": 41.244781,
      "longitude": -75.88959,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18767,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18768,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18769,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18773,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18774,
      "latitude": 41.272248,
      "longitude": -75.880146,
      "city": "Wilkes Barre",
      "state": "PA",
      "county": "Luzerne"
  },
  {
      "zipCode": 18801,
      "latitude": 41.84414,
      "longitude": -75.831862,
      "city": "Montrose",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18810,
      "latitude": 41.861751,
      "longitude": -76.488418,
      "city": "Athens",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18812,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Brackney",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18813,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Brooklyn",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18814,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Burlington",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18815,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Camptown",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18816,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Dimock",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18817,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "East Smithfield",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18818,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Friendsville",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18820,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Gibson",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18821,
      "latitude": 41.973758,
      "longitude": -75.744802,
      "city": "Great Bend",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18822,
      "latitude": 41.960544,
      "longitude": -75.748053,
      "city": "Hallstead",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18823,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Harford",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18824,
      "latitude": 41.702924,
      "longitude": -75.767941,
      "city": "Hop Bottom",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18825,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Jackson",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18826,
      "latitude": 41.766724,
      "longitude": -75.732669,
      "city": "Kingsley",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18827,
      "latitude": 41.95752,
      "longitude": -75.637286,
      "city": "Lanesboro",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18828,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Lawton",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18829,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Le Raysville",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18830,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Little Meadows",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18831,
      "latitude": 41.880928,
      "longitude": -76.585448,
      "city": "Milan",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18832,
      "latitude": 41.713484,
      "longitude": -76.487176,
      "city": "Monroeton",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18833,
      "latitude": 41.599586,
      "longitude": -76.442518,
      "city": "New Albany",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18834,
      "latitude": 41.890486,
      "longitude": -75.688114,
      "city": "New Milford",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18837,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Rome",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18839,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Rushville",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18840,
      "latitude": 41.97819,
      "longitude": -76.599403,
      "city": "Sayre",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18842,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "South Gibson",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18843,
      "latitude": 41.8237,
      "longitude": -75.88314,
      "city": "South Montrose",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18844,
      "latitude": 41.820346,
      "longitude": -75.80463,
      "city": "Springville",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18845,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Stevensville",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18846,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Sugar Run",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18847,
      "latitude": 41.957087,
      "longitude": -75.664856,
      "city": "Susquehanna",
      "state": "PA",
      "county": "Susquehanna"
  },
  {
      "zipCode": 18848,
      "latitude": 41.773762,
      "longitude": -76.389299,
      "city": "Towanda",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18850,
      "latitude": 41.813972,
      "longitude": -76.549572,
      "city": "Ulster",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18851,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Warren Center",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18853,
      "latitude": 41.668617,
      "longitude": -76.265169,
      "city": "Wyalusing",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18854,
      "latitude": 41.77197,
      "longitude": -76.521266,
      "city": "Wysox",
      "state": "PA",
      "county": "Bradford"
  },
  {
      "zipCode": 18901,
      "latitude": 40.334863,
      "longitude": -75.118737,
      "city": "Doylestown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18910,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Bedminster",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18911,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Blooming Glen",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18912,
      "latitude": 40.309942,
      "longitude": -75.074252,
      "city": "Buckingham",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18913,
      "latitude": 40.376499,
      "longitude": -75.04129,
      "city": "Carversville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18914,
      "latitude": 40.288277,
      "longitude": -75.201848,
      "city": "Chalfont",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18915,
      "latitude": 40.272756,
      "longitude": -75.256285,
      "city": "Colmar",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18916,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Danboro",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18917,
      "latitude": 40.375008,
      "longitude": -75.204464,
      "city": "Dublin",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18918,
      "latitude": 40.320036,
      "longitude": -75.374155,
      "city": "Earlington",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18920,
      "latitude": 40.488543,
      "longitude": -75.09576,
      "city": "Erwinna",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18921,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Ferndale",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18922,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Forest Grove",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18923,
      "latitude": 40.272313,
      "longitude": -75.117414,
      "city": "Fountainville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18924,
      "latitude": 40.308116,
      "longitude": -75.358575,
      "city": "Franconia",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18925,
      "latitude": 40.283176,
      "longitude": -75.064112,
      "city": "Furlong",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18926,
      "latitude": 40.39473,
      "longitude": -75.098468,
      "city": "Gardenville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18927,
      "latitude": 40.323533,
      "longitude": -75.254829,
      "city": "Hilltown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18928,
      "latitude": 40.335974,
      "longitude": -75.04288,
      "city": "Holicong",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18929,
      "latitude": 40.254157,
      "longitude": -75.08854,
      "city": "Jamison",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18930,
      "latitude": 40.54199,
      "longitude": -75.207252,
      "city": "Kintnersville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18931,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Lahaska",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18932,
      "latitude": 40.299561,
      "longitude": -75.254097,
      "city": "Line Lexington",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18933,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Lumberville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18934,
      "latitude": 40.349443,
      "longitude": -75.067622,
      "city": "Mechanicsville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18935,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Milford Square",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18936,
      "latitude": 40.2375,
      "longitude": -75.23656,
      "city": "Montgomeryville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18938,
      "latitude": 40.357492,
      "longitude": -74.998605,
      "city": "New Hope",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18940,
      "latitude": 40.263042,
      "longitude": -74.955535,
      "city": "Newtown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18942,
      "latitude": 40.463447,
      "longitude": -75.167341,
      "city": "Ottsville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18943,
      "latitude": 40.268981,
      "longitude": -75.009401,
      "city": "Penns Park",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18944,
      "latitude": 40.390196,
      "longitude": -75.233253,
      "city": "Perkasie",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18946,
      "latitude": 40.271104,
      "longitude": -75.062204,
      "city": "Pineville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18947,
      "latitude": 40.43014,
      "longitude": -75.1172,
      "city": "Pipersville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18949,
      "latitude": 40.38805,
      "longitude": -75.142522,
      "city": "Plumsteadville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18950,
      "latitude": 40.416516,
      "longitude": -75.074832,
      "city": "Point Pleasant",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18951,
      "latitude": 40.452525,
      "longitude": -75.221391,
      "city": "Quakertown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18953,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Revere",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18954,
      "latitude": 40.224658,
      "longitude": -75.001662,
      "city": "Richboro",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18955,
      "latitude": 40.47963,
      "longitude": -75.314639,
      "city": "Richlandtown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18956,
      "latitude": 40.262783,
      "longitude": -75.01568,
      "city": "Rushland",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18957,
      "latitude": 40.302945,
      "longitude": -75.448579,
      "city": "Salford",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18958,
      "latitude": 40.290357,
      "longitude": -75.435539,
      "city": "Salfordville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18960,
      "latitude": 40.360473,
      "longitude": -75.317521,
      "city": "Sellersville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18962,
      "latitude": 40.344529,
      "longitude": -75.268738,
      "city": "Silverdale",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18963,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Solebury",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18964,
      "latitude": 40.288379,
      "longitude": -75.341026,
      "city": "Souderton",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18966,
      "latitude": 40.186758,
      "longitude": -75.007099,
      "city": "Southampton",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18968,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Spinnerstown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18969,
      "latitude": 40.325792,
      "longitude": -75.380495,
      "city": "Telford",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18970,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Trumbauersville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18971,
      "latitude": 40.34698,
      "longitude": -75.376985,
      "city": "Tylersport",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18972,
      "latitude": 40.529063,
      "longitude": -75.124333,
      "city": "Upper Black Eddy",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18974,
      "latitude": 40.208476,
      "longitude": -75.040613,
      "city": "Warminster",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18976,
      "latitude": 40.244807,
      "longitude": -75.142587,
      "city": "Warrington",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18977,
      "latitude": 40.284947,
      "longitude": -74.877828,
      "city": "Washington Crossing",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18979,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Woxall",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 18980,
      "latitude": 40.274185,
      "longitude": -75.020499,
      "city": "Wycombe",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18981,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Zionhill",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 18991,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Warminster",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19001,
      "latitude": 40.123805,
      "longitude": -75.114767,
      "city": "Abington",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19002,
      "latitude": 40.180876,
      "longitude": -75.215621,
      "city": "Ambler",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19003,
      "latitude": 39.998811,
      "longitude": -75.30793,
      "city": "Ardmore",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19004,
      "latitude": 40.013811,
      "longitude": -75.228224,
      "city": "Bala Cynwyd",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19006,
      "latitude": 40.1284,
      "longitude": -75.060665,
      "city": "Huntingdon Valley",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19007,
      "latitude": 40.115859,
      "longitude": -74.853608,
      "city": "Bristol",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19008,
      "latitude": 39.972965,
      "longitude": -75.365635,
      "city": "Broomall",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19009,
      "latitude": 40.135012,
      "longitude": -75.062292,
      "city": "Bryn Athyn",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19010,
      "latitude": 39.955561,
      "longitude": -75.344833,
      "city": "Bryn Mawr",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19012,
      "latitude": 40.059161,
      "longitude": -75.104468,
      "city": "Cheltenham",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19013,
      "latitude": 39.878817,
      "longitude": -75.437102,
      "city": "Chester",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19014,
      "latitude": 39.866766,
      "longitude": -75.398537,
      "city": "Aston",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19015,
      "latitude": 39.909511,
      "longitude": -75.366785,
      "city": "Brookhaven",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19016,
      "latitude": 39.934047,
      "longitude": -75.405987,
      "city": "Chester",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19017,
      "latitude": 39.883911,
      "longitude": -75.466949,
      "city": "Chester Heights",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19018,
      "latitude": 39.921562,
      "longitude": -75.29503,
      "city": "Clifton Heights",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19019,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19020,
      "latitude": 40.125609,
      "longitude": -74.994262,
      "city": "Bensalem",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19021,
      "latitude": 40.090878,
      "longitude": -74.887554,
      "city": "Croydon",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19022,
      "latitude": 39.898162,
      "longitude": -75.319632,
      "city": "Crum Lynne",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19023,
      "latitude": 39.917562,
      "longitude": -75.269628,
      "city": "Darby",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19025,
      "latitude": 40.162403,
      "longitude": -75.409776,
      "city": "Dresher",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19026,
      "latitude": 39.937562,
      "longitude": -75.307731,
      "city": "Drexel Hill",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19027,
      "latitude": 40.07501,
      "longitude": -75.131518,
      "city": "Elkins Park",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19028,
      "latitude": 39.934047,
      "longitude": -75.405987,
      "city": "Edgemont",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19029,
      "latitude": 39.867562,
      "longitude": -75.28586,
      "city": "Essington",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19030,
      "latitude": 40.177087,
      "longitude": -74.829107,
      "city": "Fairless Hills",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19031,
      "latitude": 40.106805,
      "longitude": -75.215071,
      "city": "Flourtown",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19032,
      "latitude": 39.895162,
      "longitude": -75.278829,
      "city": "Folcroft",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19033,
      "latitude": 39.890962,
      "longitude": -75.327282,
      "city": "Folsom",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19034,
      "latitude": 40.130959,
      "longitude": -75.207437,
      "city": "Fort Washington",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19035,
      "latitude": 40.04486,
      "longitude": -75.280686,
      "city": "Gladwyne",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19036,
      "latitude": 39.902512,
      "longitude": -75.29268,
      "city": "Glenolden",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19037,
      "latitude": 39.934047,
      "longitude": -75.405987,
      "city": "Glen Riddle Lima",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19038,
      "latitude": 40.101109,
      "longitude": -75.17802,
      "city": "Glenside",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19039,
      "latitude": 39.88211,
      "longitude": -75.337234,
      "city": "Gradyville",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19040,
      "latitude": 40.182406,
      "longitude": -75.106265,
      "city": "Hatboro",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19041,
      "latitude": 40.002911,
      "longitude": -75.321632,
      "city": "Haverford",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19043,
      "latitude": 39.899562,
      "longitude": -75.307731,
      "city": "Holmes",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19044,
      "latitude": 40.191808,
      "longitude": -75.150577,
      "city": "Horsham",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19046,
      "latitude": 40.09796,
      "longitude": -75.107767,
      "city": "Jenkintown",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19047,
      "latitude": 40.289021,
      "longitude": -75.065486,
      "city": "Langhorne",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19048,
      "latitude": 40.173509,
      "longitude": -74.92461,
      "city": "Fort Washington",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19049,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Fort Washington",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19050,
      "latitude": 39.914562,
      "longitude": -75.29458,
      "city": "Lansdowne",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19052,
      "latitude": 39.894161,
      "longitude": -75.445589,
      "city": "Lenni",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19053,
      "latitude": 40.15468,
      "longitude": -74.990378,
      "city": "Feasterville Trevose",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19054,
      "latitude": 40.174246,
      "longitude": -74.821857,
      "city": "Levittown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19055,
      "latitude": 40.145759,
      "longitude": -74.841107,
      "city": "Levittown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19056,
      "latitude": 40.147709,
      "longitude": -74.880059,
      "city": "Levittown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19057,
      "latitude": 40.143309,
      "longitude": -74.846373,
      "city": "Levittown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19058,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Levittown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19059,
      "latitude": 40.328645,
      "longitude": -75.10278,
      "city": "Levittown",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19061,
      "latitude": 39.85091,
      "longitude": -75.418228,
      "city": "Marcus Hook",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19063,
      "latitude": 39.918804,
      "longitude": -75.399118,
      "city": "Media",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19064,
      "latitude": 39.931858,
      "longitude": -75.341583,
      "city": "Springfield",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19065,
      "latitude": 39.921061,
      "longitude": -75.386136,
      "city": "Media",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19066,
      "latitude": 40.003412,
      "longitude": -75.249476,
      "city": "Merion Station",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19067,
      "latitude": 40.208408,
      "longitude": -74.82914,
      "city": "Morrisville",
      "state": "PA",
      "county": "Bucks"
  },
  {
      "zipCode": 19070,
      "latitude": 39.907762,
      "longitude": -75.325182,
      "city": "Morton",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19072,
      "latitude": 40.020911,
      "longitude": -75.259975,
      "city": "Narberth",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19073,
      "latitude": 39.955409,
      "longitude": -75.40172,
      "city": "Newtown Square",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19074,
      "latitude": 39.888412,
      "longitude": -75.29818,
      "city": "Norwood",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19075,
      "latitude": 40.113809,
      "longitude": -75.185219,
      "city": "Oreland",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19076,
      "latitude": 39.885984,
      "longitude": -75.307231,
      "city": "Prospect Park",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19078,
      "latitude": 39.878212,
      "longitude": -75.323082,
      "city": "Ridley Park",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19079,
      "latitude": 39.901112,
      "longitude": -75.267628,
      "city": "Sharon Hill",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19080,
      "latitude": 40.043201,
      "longitude": -75.357678,
      "city": "Wayne",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19081,
      "latitude": 39.897162,
      "longitude": -75.344083,
      "city": "Swarthmore",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19082,
      "latitude": 39.951662,
      "longitude": -75.285429,
      "city": "Upper Darby",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19083,
      "latitude": 39.974861,
      "longitude": -75.312081,
      "city": "Havertown",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19085,
      "latitude": 40.027909,
      "longitude": -75.369458,
      "city": "Villanova",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19086,
      "latitude": 39.896711,
      "longitude": -75.370385,
      "city": "Wallingford",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19087,
      "latitude": 39.98596,
      "longitude": -75.370934,
      "city": "Wayne",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19088,
      "latitude": 39.934047,
      "longitude": -75.405987,
      "city": "Wayne",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19089,
      "latitude": 40.043399,
      "longitude": -75.357308,
      "city": "Wayne",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19090,
      "latitude": 40.161856,
      "longitude": -75.10897,
      "city": "Willow Grove",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19091,
      "latitude": 39.934047,
      "longitude": -75.405987,
      "city": "Media",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19092,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19093,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19094,
      "latitude": 39.876862,
      "longitude": -75.346083,
      "city": "Woodlyn",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19095,
      "latitude": 40.08561,
      "longitude": -75.151569,
      "city": "Wyncote",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19096,
      "latitude": 40.051411,
      "longitude": -75.164822,
      "city": "Wynnewood",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19098,
      "latitude": 39.895362,
      "longitude": -75.310731,
      "city": "Holmes",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19099,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19101,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19102,
      "latitude": 39.952562,
      "longitude": -75.166472,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19103,
      "latitude": 40.003361,
      "longitude": -75.186822,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19104,
      "latitude": 39.960912,
      "longitude": -75.19668,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19105,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19106,
      "latitude": 39.950004,
      "longitude": -75.150071,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19107,
      "latitude": 39.962612,
      "longitude": -75.162871,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19108,
      "latitude": 39.959812,
      "longitude": -75.161622,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19109,
      "latitude": 39.949612,
      "longitude": -75.163722,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19110,
      "latitude": 39.950212,
      "longitude": -75.163572,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19111,
      "latitude": 40.06317,
      "longitude": -75.081317,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19112,
      "latitude": 39.892213,
      "longitude": -75.179623,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19113,
      "latitude": 39.869958,
      "longitude": -75.277458,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19114,
      "latitude": 40.019816,
      "longitude": -75.065617,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19115,
      "latitude": 40.07841,
      "longitude": -75.045065,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19116,
      "latitude": 40.104709,
      "longitude": -75.006771,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19118,
      "latitude": 40.07231,
      "longitude": -75.203371,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19119,
      "latitude": 40.068142,
      "longitude": -75.123024,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19120,
      "latitude": 40.031611,
      "longitude": -75.119019,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19121,
      "latitude": 39.983212,
      "longitude": -75.174172,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19122,
      "latitude": 39.977462,
      "longitude": -75.141271,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19123,
      "latitude": 39.98238,
      "longitude": -75.115369,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19124,
      "latitude": 40.021561,
      "longitude": -75.093668,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19125,
      "latitude": 39.977262,
      "longitude": -75.13292,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19126,
      "latitude": 40.061411,
      "longitude": -75.131769,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19127,
      "latitude": 40.024761,
      "longitude": -75.222473,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19128,
      "latitude": 40.049111,
      "longitude": -75.178489,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19129,
      "latitude": 40.022911,
      "longitude": -75.167771,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19130,
      "latitude": 40.001161,
      "longitude": -75.169671,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19131,
      "latitude": 39.996811,
      "longitude": -75.207774,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19132,
      "latitude": 40.040111,
      "longitude": -75.117719,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19133,
      "latitude": 39.992562,
      "longitude": -75.13732,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19134,
      "latitude": 39.994662,
      "longitude": -75.107969,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19135,
      "latitude": 40.017061,
      "longitude": -75.098618,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19136,
      "latitude": 40.044661,
      "longitude": -75.020665,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19137,
      "latitude": 39.986162,
      "longitude": -75.150671,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19138,
      "latitude": 40.056261,
      "longitude": -75.16117,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19139,
      "latitude": 39.996211,
      "longitude": -75.117519,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19140,
      "latitude": 40.014861,
      "longitude": -75.128302,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19141,
      "latitude": 40.009611,
      "longitude": -75.15032,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19142,
      "latitude": 39.963339,
      "longitude": -75.161672,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19143,
      "latitude": 39.957312,
      "longitude": -75.187023,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19144,
      "latitude": 40.033111,
      "longitude": -75.179871,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19145,
      "latitude": 39.917002,
      "longitude": -75.1877,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19146,
      "latitude": 39.945112,
      "longitude": -75.175822,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19147,
      "latitude": 39.936212,
      "longitude": -75.155973,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19148,
      "latitude": 39.950362,
      "longitude": -75.155371,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19149,
      "latitude": 40.024361,
      "longitude": -75.081668,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19150,
      "latitude": 40.07231,
      "longitude": -75.17122,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19151,
      "latitude": 39.986611,
      "longitude": -75.213174,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19152,
      "latitude": 40.014011,
      "longitude": -75.086818,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19153,
      "latitude": 39.899412,
      "longitude": -75.226976,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19154,
      "latitude": 40.084017,
      "longitude": -75.018265,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19155,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19160,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19161,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19162,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19170,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19171,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19172,
      "latitude": 39.947321,
      "longitude": -75.150011,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19173,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19175,
      "latitude": 39.990562,
      "longitude": -75.12957,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19177,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19178,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19179,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19181,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19182,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19183,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19184,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19185,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19187,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19188,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19191,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19192,
      "latitude": 39.951112,
      "longitude": -75.167622,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19193,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19194,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19196,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19197,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19244,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19255,
      "latitude": 40.001811,
      "longitude": -75.11787,
      "city": "Philadelphia",
      "state": "PA",
      "county": "Philadelphia"
  },
  {
      "zipCode": 19301,
      "latitude": 40.040057,
      "longitude": -75.488793,
      "city": "Paoli",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19310,
      "latitude": 39.941554,
      "longitude": -75.957993,
      "city": "Atglen",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19311,
      "latitude": 39.866821,
      "longitude": -75.839686,
      "city": "Avondale",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19312,
      "latitude": 40.030058,
      "longitude": -75.45644,
      "city": "Berwyn",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19316,
      "latitude": 40.054976,
      "longitude": -75.837767,
      "city": "Brandamore",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19317,
      "latitude": 39.865567,
      "longitude": -75.557698,
      "city": "Chadds Ford",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19318,
      "latitude": 39.852406,
      "longitude": -75.811789,
      "city": "Chatham",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19319,
      "latitude": 39.920918,
      "longitude": -75.522168,
      "city": "Cheyney",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19320,
      "latitude": 39.968862,
      "longitude": -75.829163,
      "city": "Coatesville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19330,
      "latitude": 39.916517,
      "longitude": -75.925805,
      "city": "Cochranville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19331,
      "latitude": 39.882274,
      "longitude": -75.516529,
      "city": "Concordville",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19333,
      "latitude": 40.042623,
      "longitude": -75.42746,
      "city": "Devon",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19335,
      "latitude": 40.031265,
      "longitude": -75.722614,
      "city": "Downingtown",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19339,
      "latitude": 39.934047,
      "longitude": -75.405987,
      "city": "Concordville",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19340,
      "latitude": 39.934047,
      "longitude": -75.405987,
      "city": "Concordville",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19341,
      "latitude": 40.049852,
      "longitude": -75.637035,
      "city": "Exton",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19342,
      "latitude": 39.902633,
      "longitude": -75.483224,
      "city": "Glen Mills",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19343,
      "latitude": 40.100892,
      "longitude": -75.753071,
      "city": "Glenmoore",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19344,
      "latitude": 40.089561,
      "longitude": -75.867533,
      "city": "Honey Brook",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19345,
      "latitude": 39.847803,
      "longitude": -75.719347,
      "city": "Immaculata",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19346,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Kelton",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19347,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Kemblesville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19348,
      "latitude": 39.867864,
      "longitude": -75.716333,
      "city": "Kennett Square",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19350,
      "latitude": 39.771506,
      "longitude": -75.799761,
      "city": "Landenberg",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19351,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Lewisville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19352,
      "latitude": 39.778703,
      "longitude": -75.88758,
      "city": "Lincoln University",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19353,
      "latitude": 40.013158,
      "longitude": -75.499046,
      "city": "Lionville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19354,
      "latitude": 40.058173,
      "longitude": -75.743272,
      "city": "Lyndell",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19355,
      "latitude": 40.02951,
      "longitude": -75.713599,
      "city": "Malvern",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19357,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Mendenhall",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19358,
      "latitude": 39.962081,
      "longitude": -75.802502,
      "city": "Modena",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19360,
      "latitude": 39.726143,
      "longitude": -75.7857,
      "city": "New London",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19362,
      "latitude": 39.757361,
      "longitude": -76.037603,
      "city": "Nottingham",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19363,
      "latitude": 39.849721,
      "longitude": -75.957276,
      "city": "Oxford",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19365,
      "latitude": 40.001064,
      "longitude": -75.830891,
      "city": "Parkesburg",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19366,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Pocopson",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19367,
      "latitude": 39.970601,
      "longitude": -75.895794,
      "city": "Pomeroy",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19369,
      "latitude": 39.990302,
      "longitude": -75.893598,
      "city": "Sadsburyville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19370,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Steelville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19371,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Suplee",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19372,
      "latitude": 39.993929,
      "longitude": -75.818572,
      "city": "Thorndale",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19373,
      "latitude": 39.905987,
      "longitude": -75.535383,
      "city": "Thornton",
      "state": "PA",
      "county": "Delaware"
  },
  {
      "zipCode": 19374,
      "latitude": 39.872825,
      "longitude": -75.848075,
      "city": "Toughkenamon",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19375,
      "latitude": 39.901177,
      "longitude": -75.750203,
      "city": "Unionville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19376,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Wagontown",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19380,
      "latitude": 39.980107,
      "longitude": -75.610503,
      "city": "West Chester",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19381,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "West Chester",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19382,
      "latitude": 39.912284,
      "longitude": -75.630235,
      "city": "West Chester",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19383,
      "latitude": 39.94545,
      "longitude": -75.602401,
      "city": "West Chester",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19390,
      "latitude": 39.841367,
      "longitude": -75.847944,
      "city": "West Grove",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19395,
      "latitude": 39.932821,
      "longitude": -75.544822,
      "city": "Westtown",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19397,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Southeastern",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19398,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Southeastern",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19399,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Southeastern",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19401,
      "latitude": 40.149154,
      "longitude": -75.356627,
      "city": "Norristown",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19403,
      "latitude": 40.124886,
      "longitude": -75.357027,
      "city": "Norristown",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19404,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Norristown",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19405,
      "latitude": 40.120931,
      "longitude": -75.362127,
      "city": "Bridgeport",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19406,
      "latitude": 40.179242,
      "longitude": -75.388022,
      "city": "King Of Prussia",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19407,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Audubon",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19408,
      "latitude": 40.156555,
      "longitude": -75.413127,
      "city": "Eagleville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19409,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Fairview Village",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19415,
      "latitude": 40.156144,
      "longitude": -75.402312,
      "city": "Eagleville",
      "state": "PA",
      "county": ""
  },
  {
      "zipCode": 19420,
      "latitude": 40.152871,
      "longitude": -75.456719,
      "city": "Arcola",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19421,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Birchrunville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19422,
      "latitude": 40.15755,
      "longitude": -75.279941,
      "city": "Blue Bell",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19423,
      "latitude": 40.21735,
      "longitude": -75.364973,
      "city": "Cedars",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19424,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Blue Bell",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19425,
      "latitude": 40.103858,
      "longitude": -75.639994,
      "city": "Chester Springs",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19426,
      "latitude": 40.236083,
      "longitude": -75.430272,
      "city": "Collegeville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19428,
      "latitude": 40.082509,
      "longitude": -75.304377,
      "city": "Conshohocken",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19429,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Conshohocken",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19430,
      "latitude": 40.185071,
      "longitude": -75.419966,
      "city": "Creamery",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19432,
      "latitude": 40.079629,
      "longitude": -75.559975,
      "city": "Devault",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19435,
      "latitude": 40.312552,
      "longitude": -75.55171,
      "city": "Frederick",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19436,
      "latitude": 40.200757,
      "longitude": -75.250665,
      "city": "Gwynedd",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19437,
      "latitude": 40.181054,
      "longitude": -75.257855,
      "city": "Gwynedd Valley",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19438,
      "latitude": 40.274188,
      "longitude": -75.395188,
      "city": "Harleysville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19440,
      "latitude": 40.28202,
      "longitude": -75.358491,
      "city": "Hatfield",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19441,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Harleysville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19442,
      "latitude": 40.146549,
      "longitude": -75.617739,
      "city": "Kimberton",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19443,
      "latitude": 40.241004,
      "longitude": -75.34392,
      "city": "Kulpsville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19444,
      "latitude": 40.08526,
      "longitude": -75.257423,
      "city": "Lafayette Hill",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19446,
      "latitude": 40.224245,
      "longitude": -75.29156,
      "city": "Lansdale",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19450,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Lederach",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19451,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Mainland",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19452,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Miquon",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19453,
      "latitude": 40.151918,
      "longitude": -75.504383,
      "city": "Mont Clare",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19454,
      "latitude": 40.220106,
      "longitude": -75.237877,
      "city": "North Wales",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19455,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "North Wales",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19456,
      "latitude": 40.133355,
      "longitude": -75.453631,
      "city": "Oaks",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19457,
      "latitude": 40.208976,
      "longitude": -75.5984,
      "city": "Parker Ford",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19460,
      "latitude": 40.128175,
      "longitude": -75.541769,
      "city": "Phoenixville",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19462,
      "latitude": 40.137759,
      "longitude": -75.352751,
      "city": "Plymouth Meeting",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19464,
      "latitude": 40.263457,
      "longitude": -75.617195,
      "city": "Pottstown",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19465,
      "latitude": 40.191907,
      "longitude": -75.66531,
      "city": "Pottstown",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19468,
      "latitude": 40.20894,
      "longitude": -75.490215,
      "city": "Royersford",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19470,
      "latitude": 40.186707,
      "longitude": -75.728759,
      "city": "Saint Peters",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19472,
      "latitude": 40.344773,
      "longitude": -75.577458,
      "city": "Sassamansville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19473,
      "latitude": 40.224789,
      "longitude": -75.478534,
      "city": "Schwenksville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19474,
      "latitude": 40.225109,
      "longitude": -75.403095,
      "city": "Skippack",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19475,
      "latitude": 40.146989,
      "longitude": -75.605398,
      "city": "Spring City",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19477,
      "latitude": 40.185934,
      "longitude": -75.232407,
      "city": "Spring House",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19478,
      "latitude": 40.275914,
      "longitude": -75.461756,
      "city": "Spring Mount",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19480,
      "latitude": 40.098032,
      "longitude": -75.688005,
      "city": "Uwchland",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19481,
      "latitude": 40.092269,
      "longitude": -75.453735,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19482,
      "latitude": 40.080323,
      "longitude": -75.455408,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19483,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19484,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19485,
      "latitude": 40.211896,
      "longitude": -75.35586,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19486,
      "latitude": 40.203291,
      "longitude": -75.301918,
      "city": "West Point",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19487,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "King Of Prussia",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19488,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Norristown",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19489,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Norristown",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19490,
      "latitude": 40.192881,
      "longitude": -75.357555,
      "city": "Worcester",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19492,
      "latitude": 40.290125,
      "longitude": -75.502974,
      "city": "Zieglerville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19493,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19494,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19495,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19496,
      "latitude": 39.983153,
      "longitude": -75.748055,
      "city": "Valley Forge",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19501,
      "latitude": 40.242327,
      "longitude": -76.057696,
      "city": "Adamstown",
      "state": "PA",
      "county": "Lancaster"
  },
  {
      "zipCode": 19503,
      "latitude": 40.447833,
      "longitude": -75.708672,
      "city": "Bally",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19504,
      "latitude": 40.335395,
      "longitude": -75.720131,
      "city": "Barto",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19505,
      "latitude": 40.458523,
      "longitude": -75.741863,
      "city": "Bechtelsville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19506,
      "latitude": 40.454997,
      "longitude": -75.90646,
      "city": "Bernville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19507,
      "latitude": 40.491214,
      "longitude": -76.269231,
      "city": "Bethel",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19508,
      "latitude": 40.404848,
      "longitude": -75.886642,
      "city": "Birdsboro",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19510,
      "latitude": 40.371492,
      "longitude": -75.887797,
      "city": "Blandon",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19511,
      "latitude": 40.482102,
      "longitude": -75.743476,
      "city": "Bowers",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19512,
      "latitude": 40.364068,
      "longitude": -75.870971,
      "city": "Boyertown",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19516,
      "latitude": 40.485843,
      "longitude": -76.005958,
      "city": "Centerport",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19518,
      "latitude": 40.365322,
      "longitude": -75.848729,
      "city": "Douglassville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19519,
      "latitude": 40.318986,
      "longitude": -75.733368,
      "city": "Earlville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19520,
      "latitude": 40.156293,
      "longitude": -75.786492,
      "city": "Elverson",
      "state": "PA",
      "county": "Chester"
  },
  {
      "zipCode": 19522,
      "latitude": 40.326392,
      "longitude": -75.808303,
      "city": "Fleetwood",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19523,
      "latitude": 40.189715,
      "longitude": -75.888112,
      "city": "Geigertown",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19525,
      "latitude": 40.309952,
      "longitude": -75.584885,
      "city": "Gilbertsville",
      "state": "PA",
      "county": "Montgomery"
  },
  {
      "zipCode": 19526,
      "latitude": 40.368035,
      "longitude": -75.861788,
      "city": "Hamburg",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19529,
      "latitude": 40.627864,
      "longitude": -75.883429,
      "city": "Kempton",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19530,
      "latitude": 40.473382,
      "longitude": -75.775241,
      "city": "Kutztown",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19533,
      "latitude": 40.420446,
      "longitude": -75.85715,
      "city": "Leesport",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19534,
      "latitude": 40.573618,
      "longitude": -75.864968,
      "city": "Lenhartsville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19535,
      "latitude": 40.335593,
      "longitude": -75.801009,
      "city": "Limekiln",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19536,
      "latitude": 40.521756,
      "longitude": -75.741859,
      "city": "Lyon Station",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19538,
      "latitude": 40.569818,
      "longitude": -75.740942,
      "city": "Maxatawny",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19539,
      "latitude": 40.432311,
      "longitude": -75.796571,
      "city": "Mertztown",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19540,
      "latitude": 40.321665,
      "longitude": -75.804757,
      "city": "Mohnton",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19541,
      "latitude": 40.400685,
      "longitude": -76.037566,
      "city": "Mohrsville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19542,
      "latitude": 40.260992,
      "longitude": -75.768437,
      "city": "Monocacy Station",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19543,
      "latitude": 40.367731,
      "longitude": -75.89161,
      "city": "Morgantown",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19544,
      "latitude": 40.418662,
      "longitude": -76.294364,
      "city": "Mount Aetna",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19545,
      "latitude": 40.33968,
      "longitude": -75.63016,
      "city": "New Berlinville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19547,
      "latitude": 40.343449,
      "longitude": -75.897566,
      "city": "Oley",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19548,
      "latitude": 40.407173,
      "longitude": -75.984901,
      "city": "Pine Forge",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19549,
      "latitude": 40.722587,
      "longitude": -76.229623,
      "city": "Port Clinton",
      "state": "PA",
      "county": "Schuylkill"
  },
  {
      "zipCode": 19550,
      "latitude": 40.455305,
      "longitude": -76.245345,
      "city": "Rehrersburg",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19551,
      "latitude": 40.326171,
      "longitude": -75.88889,
      "city": "Robesonia",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19554,
      "latitude": 40.505184,
      "longitude": -76.141334,
      "city": "Shartlesville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19555,
      "latitude": 40.411279,
      "longitude": -75.887016,
      "city": "Shoemakersville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19557,
      "latitude": 40.613868,
      "longitude": -75.814984,
      "city": "Stony Run",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19559,
      "latitude": 40.494368,
      "longitude": -76.18646,
      "city": "Strausstown",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19560,
      "latitude": 40.395908,
      "longitude": -75.889849,
      "city": "Temple",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19562,
      "latitude": 40.476019,
      "longitude": -75.849294,
      "city": "Topton",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19564,
      "latitude": 40.526803,
      "longitude": -75.869653,
      "city": "Virginville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19565,
      "latitude": 40.337188,
      "longitude": -75.951748,
      "city": "Wernersville",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19567,
      "latitude": 40.432759,
      "longitude": -75.925707,
      "city": "Womelsdorf",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19601,
      "latitude": 40.357242,
      "longitude": -75.940153,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19602,
      "latitude": 40.326546,
      "longitude": -75.915919,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19603,
      "latitude": 40.388442,
      "longitude": -75.963055,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19604,
      "latitude": 40.360942,
      "longitude": -75.927852,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19605,
      "latitude": 40.404942,
      "longitude": -75.941603,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19606,
      "latitude": 40.335119,
      "longitude": -75.874976,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19607,
      "latitude": 40.299463,
      "longitude": -75.987606,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19608,
      "latitude": 40.316342,
      "longitude": -75.935521,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19609,
      "latitude": 40.327992,
      "longitude": -75.990807,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19610,
      "latitude": 40.338017,
      "longitude": -75.978006,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19611,
      "latitude": 40.303892,
      "longitude": -75.938853,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19612,
      "latitude": 40.4389,
      "longitude": -75.885303,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19640,
      "latitude": 40.407173,
      "longitude": -75.984901,
      "city": "Reading",
      "state": "PA",
      "county": "Berks"
  },
  {
      "zipCode": 19701,
      "latitude": 39.594736,
      "longitude": -75.693903,
      "city": "Bear",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19702,
      "latitude": 39.614737,
      "longitude": -75.701277,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19703,
      "latitude": 39.80379,
      "longitude": -75.45814,
      "city": "Claymont",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19706,
      "latitude": 39.594256,
      "longitude": -75.608081,
      "city": "Delaware City",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19707,
      "latitude": 39.635202,
      "longitude": -75.680897,
      "city": "Hockessin",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19708,
      "latitude": 39.577567,
      "longitude": -75.690069,
      "city": "Kirkwood",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19709,
      "latitude": 39.485721,
      "longitude": -75.677535,
      "city": "Middletown",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19710,
      "latitude": 39.757783,
      "longitude": -75.639143,
      "city": "Montchanin",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19711,
      "latitude": 39.668282,
      "longitude": -75.688489,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19712,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19713,
      "latitude": 39.665662,
      "longitude": -75.719349,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19714,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19715,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19716,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19717,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19718,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19720,
      "latitude": 39.592895,
      "longitude": -75.651466,
      "city": "New Castle",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19721,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "New Castle",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19725,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19726,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Newark",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19730,
      "latitude": 39.51251,
      "longitude": -75.627281,
      "city": "Odessa",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19731,
      "latitude": 39.512902,
      "longitude": -75.58518,
      "city": "Port Penn",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19732,
      "latitude": 39.793864,
      "longitude": -75.57375,
      "city": "Rockland",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19733,
      "latitude": 39.555459,
      "longitude": -75.650462,
      "city": "Saint Georges",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19734,
      "latitude": 39.430666,
      "longitude": -75.646408,
      "city": "Townsend",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19735,
      "latitude": 39.794396,
      "longitude": -75.597598,
      "city": "Winterthur",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19736,
      "latitude": 39.797126,
      "longitude": -75.660413,
      "city": "Yorklyn",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19801,
      "latitude": 39.727113,
      "longitude": -75.547844,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19802,
      "latitude": 39.71987,
      "longitude": -75.593512,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19803,
      "latitude": 39.79936,
      "longitude": -75.531694,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19804,
      "latitude": 39.759577,
      "longitude": -75.617198,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19805,
      "latitude": 39.743107,
      "longitude": -75.593447,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19806,
      "latitude": 39.758562,
      "longitude": -75.568745,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19807,
      "latitude": 39.794943,
      "longitude": -75.616134,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19808,
      "latitude": 39.735912,
      "longitude": -75.664729,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19809,
      "latitude": 39.764712,
      "longitude": -75.506892,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19810,
      "latitude": 39.763961,
      "longitude": -75.527812,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19850,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19880,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19884,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19885,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19886,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19887,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19889,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19890,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19891,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19892,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19893,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19894,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19895,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19896,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19897,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19898,
      "latitude": 39.564499,
      "longitude": -75.597047,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19899,
      "latitude": 39.734792,
      "longitude": -75.624617,
      "city": "Wilmington",
      "state": "DE",
      "county": "New Castle"
  },
  {
      "zipCode": 19901,
      "latitude": 39.156376,
      "longitude": -75.49549,
      "city": "Dover",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19902,
      "latitude": 39.10868,
      "longitude": -75.448023,
      "city": "Dover Afb",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19903,
      "latitude": 39.10868,
      "longitude": -75.448023,
      "city": "Dover",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19904,
      "latitude": 39.160542,
      "longitude": -75.597381,
      "city": "Dover",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19905,
      "latitude": 39.10868,
      "longitude": -75.448023,
      "city": "Dover",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19906,
      "latitude": 39.158075,
      "longitude": -75.523001,
      "city": "Dover",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19930,
      "latitude": 38.555533,
      "longitude": -75.186955,
      "city": "Bethany Beach",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19931,
      "latitude": 38.569091,
      "longitude": -75.624087,
      "city": "Bethel",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19933,
      "latitude": 38.660295,
      "longitude": -75.330023,
      "city": "Bridgeville",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19934,
      "latitude": 39.086921,
      "longitude": -75.612286,
      "city": "Camden Wyoming",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19936,
      "latitude": 39.218448,
      "longitude": -75.584848,
      "city": "Cheswold",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19938,
      "latitude": 39.246071,
      "longitude": -75.688873,
      "city": "Clayton",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19939,
      "latitude": 38.621403,
      "longitude": -75.181502,
      "city": "Dagsboro",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19940,
      "latitude": 38.631744,
      "longitude": -75.324585,
      "city": "Delmar",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19941,
      "latitude": 38.652805,
      "longitude": -75.275018,
      "city": "Ellendale",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19943,
      "latitude": 39.033438,
      "longitude": -75.609891,
      "city": "Felton",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19944,
      "latitude": 38.486485,
      "longitude": -75.058874,
      "city": "Fenwick Island",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19945,
      "latitude": 38.55144,
      "longitude": -75.157634,
      "city": "Frankford",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19946,
      "latitude": 39.023141,
      "longitude": -75.48191,
      "city": "Frederica",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19947,
      "latitude": 38.632891,
      "longitude": -75.334263,
      "city": "Georgetown",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19950,
      "latitude": 38.82458,
      "longitude": -75.588771,
      "city": "Greenwood",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19951,
      "latitude": 38.691547,
      "longitude": -75.225025,
      "city": "Harbeson",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19952,
      "latitude": 38.912195,
      "longitude": -75.611322,
      "city": "Harrington",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19953,
      "latitude": 39.16343,
      "longitude": -75.679733,
      "city": "Hartly",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19954,
      "latitude": 38.894017,
      "longitude": -75.520078,
      "city": "Houston",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19955,
      "latitude": 39.225604,
      "longitude": -75.664153,
      "city": "Kenton",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19956,
      "latitude": 38.663322,
      "longitude": -75.378729,
      "city": "Laurel",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19958,
      "latitude": 38.65536,
      "longitude": -75.325612,
      "city": "Lewes",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19960,
      "latitude": 38.848792,
      "longitude": -75.41087,
      "city": "Lincoln",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19961,
      "latitude": 39.166323,
      "longitude": -75.448289,
      "city": "Little Creek",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19962,
      "latitude": 38.971647,
      "longitude": -75.591714,
      "city": "Magnolia",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19963,
      "latitude": 38.72712,
      "longitude": -75.322829,
      "city": "Milford",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19964,
      "latitude": 39.140254,
      "longitude": -75.667898,
      "city": "Marydel",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19966,
      "latitude": 38.659458,
      "longitude": -75.24641,
      "city": "Millsboro",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19967,
      "latitude": 38.700518,
      "longitude": -75.242294,
      "city": "Millville",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19968,
      "latitude": 38.692714,
      "longitude": -75.322223,
      "city": "Milton",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19969,
      "latitude": 38.725562,
      "longitude": -75.353387,
      "city": "Nassau",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19970,
      "latitude": 38.621587,
      "longitude": -75.104645,
      "city": "Ocean View",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19971,
      "latitude": 38.629763,
      "longitude": -75.319975,
      "city": "Rehoboth Beach",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19973,
      "latitude": 38.659891,
      "longitude": -75.380454,
      "city": "Seaford",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19975,
      "latitude": 38.550784,
      "longitude": -75.330307,
      "city": "Selbyville",
      "state": "DE",
      "county": "Sussex"
  },
  {
      "zipCode": 19977,
      "latitude": 39.194026,
      "longitude": -75.565131,
      "city": "Smyrna",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19979,
      "latitude": 38.971038,
      "longitude": -75.577158,
      "city": "Viola",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 19980,
      "latitude": 39.07239,
      "longitude": -75.571073,
      "city": "Woodside",
      "state": "DE",
      "county": "Kent"
  },
  {
      "zipCode": 20001,
      "latitude": 38.911936,
      "longitude": -77.016719,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20002,
      "latitude": 38.908218,
      "longitude": -76.982147,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20003,
      "latitude": 38.860315,
      "longitude": -76.988897,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20004,
      "latitude": 38.892247,
      "longitude": -77.019477,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20005,
      "latitude": 38.904011,
      "longitude": -77.031695,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20006,
      "latitude": 38.897131,
      "longitude": -77.041248,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20007,
      "latitude": 38.914561,
      "longitude": -77.075649,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20008,
      "latitude": 38.93521,
      "longitude": -77.060399,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20009,
      "latitude": 38.919261,
      "longitude": -77.039798,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20010,
      "latitude": 38.932711,
      "longitude": -77.030248,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20011,
      "latitude": 38.95261,
      "longitude": -77.017898,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20012,
      "latitude": 38.980231,
      "longitude": -77.026298,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20013,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20015,
      "latitude": 38.96926,
      "longitude": -77.070949,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20016,
      "latitude": 38.93826,
      "longitude": -77.091149,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20017,
      "latitude": 38.93846,
      "longitude": -76.993068,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20018,
      "latitude": 38.93056,
      "longitude": -76.977953,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20019,
      "latitude": 38.891561,
      "longitude": -76.939396,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20020,
      "latitude": 38.85761,
      "longitude": -76.971547,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20024,
      "latitude": 38.876494,
      "longitude": -77.022948,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20026,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20029,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20030,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20032,
      "latitude": 38.8368,
      "longitude": -76.997697,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20033,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20035,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20036,
      "latitude": 38.900659,
      "longitude": -77.039914,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20037,
      "latitude": 38.918903,
      "longitude": -77.061781,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20038,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20039,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20040,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20041,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20042,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20043,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20044,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20045,
      "latitude": 38.896599,
      "longitude": -77.0319,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20046,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20047,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20049,
      "latitude": 38.895911,
      "longitude": -77.020998,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20050,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20051,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20052,
      "latitude": 38.90014,
      "longitude": -77.047899,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20053,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20055,
      "latitude": 38.901633,
      "longitude": -77.020959,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20056,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20057,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20058,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20059,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20060,
      "latitude": 38.918011,
      "longitude": -77.020398,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20061,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20062,
      "latitude": 38.900009,
      "longitude": -77.036948,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20063,
      "latitude": 38.905317,
      "longitude": -77.046645,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20064,
      "latitude": 38.933161,
      "longitude": -76.996348,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20065,
      "latitude": 38.883412,
      "longitude": -77.028198,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20066,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20067,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20068,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20069,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20070,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20071,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20073,
      "latitude": 38.897011,
      "longitude": -77.025098,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20074,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20075,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20076,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20077,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20078,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20080,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20081,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20082,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20088,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20090,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20091,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20097,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20098,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20099,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20101,
      "latitude": 39.002125,
      "longitude": -77.442066,
      "city": "Dulles",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20102,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Dulles",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20103,
      "latitude": 38.996242,
      "longitude": -77.449952,
      "city": "Dulles",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20104,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Dulles",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20105,
      "latitude": 38.957718,
      "longitude": -77.60375,
      "city": "Aldie",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20106,
      "latitude": 38.6842,
      "longitude": -78.016813,
      "city": "Amissville",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 20107,
      "latitude": 38.968321,
      "longitude": -77.533263,
      "city": "Arcola",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20108,
      "latitude": 38.744685,
      "longitude": -77.487162,
      "city": "Manassas",
      "state": "VA",
      "county": "Manassas City"
  },
  {
      "zipCode": 20109,
      "latitude": 38.763215,
      "longitude": -77.493092,
      "city": "Manassas",
      "state": "VA",
      "county": "Manassas City"
  },
  {
      "zipCode": 20110,
      "latitude": 38.749207,
      "longitude": -77.487762,
      "city": "Manassas",
      "state": "VA",
      "county": "Manassas City"
  },
  {
      "zipCode": 20111,
      "latitude": 38.770741,
      "longitude": -77.449443,
      "city": "Manassas",
      "state": "VA",
      "county": "Manassas Park City"
  },
  {
      "zipCode": 20112,
      "latitude": 38.770864,
      "longitude": -77.449443,
      "city": "Manassas",
      "state": "VA",
      "county": "Manassas Park City"
  },
  {
      "zipCode": 20113,
      "latitude": 38.770864,
      "longitude": -77.449443,
      "city": "Manassas",
      "state": "VA",
      "county": "Manassas Park City"
  },
  {
      "zipCode": 20115,
      "latitude": 38.840456,
      "longitude": -77.891136,
      "city": "Marshall",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20116,
      "latitude": 38.853723,
      "longitude": -77.860127,
      "city": "Marshall",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20117,
      "latitude": 39.029599,
      "longitude": -77.693844,
      "city": "Middleburg",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20118,
      "latitude": 39.007548,
      "longitude": -77.765553,
      "city": "Middleburg",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20119,
      "latitude": 38.636964,
      "longitude": -77.638287,
      "city": "Catlett",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20120,
      "latitude": 38.84479,
      "longitude": -77.46701,
      "city": "Centreville",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20121,
      "latitude": 38.819513,
      "longitude": -77.45576,
      "city": "Centreville",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20122,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Centreville",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20124,
      "latitude": 38.781809,
      "longitude": -77.381808,
      "city": "Clifton",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20128,
      "latitude": 38.741639,
      "longitude": -77.977432,
      "city": "Orlean",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20129,
      "latitude": 39.160291,
      "longitude": -77.60967,
      "city": "Paeonian Springs",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20130,
      "latitude": 39.004843,
      "longitude": -77.954597,
      "city": "Paris",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20131,
      "latitude": 39.057952,
      "longitude": -77.743423,
      "city": "Philomont",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20132,
      "latitude": 39.143614,
      "longitude": -77.734232,
      "city": "Purcellville",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20134,
      "latitude": 39.152187,
      "longitude": -77.702576,
      "city": "Purcellville",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20135,
      "latitude": 39.082266,
      "longitude": -77.846661,
      "city": "Bluemont",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20136,
      "latitude": 38.734255,
      "longitude": -77.547383,
      "city": "Bristow",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20137,
      "latitude": 38.819464,
      "longitude": -77.736814,
      "city": "Broad Run",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20138,
      "latitude": 38.633774,
      "longitude": -77.686868,
      "city": "Calverton",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20139,
      "latitude": 38.654282,
      "longitude": -77.702542,
      "city": "Casanova",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20140,
      "latitude": 38.916291,
      "longitude": -77.864841,
      "city": "Rectortown",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20141,
      "latitude": 39.116425,
      "longitude": -77.780216,
      "city": "Round Hill",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20142,
      "latitude": 39.130665,
      "longitude": -77.774665,
      "city": "Round Hill",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20143,
      "latitude": 38.845535,
      "longitude": -77.566921,
      "city": "Catharpin",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20144,
      "latitude": 38.911315,
      "longitude": -77.967401,
      "city": "Delaplane",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20146,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Ashburn",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20147,
      "latitude": 39.037339,
      "longitude": -77.480542,
      "city": "Ashburn",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20148,
      "latitude": 39.014196,
      "longitude": -77.528452,
      "city": "Ashburn",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20149,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Ashburn",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20151,
      "latitude": 38.88668,
      "longitude": -77.44566,
      "city": "Chantilly",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20152,
      "latitude": 38.897591,
      "longitude": -77.509174,
      "city": "Chantilly",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20153,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Chantilly",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20155,
      "latitude": 38.815714,
      "longitude": -77.621613,
      "city": "Gainesville",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20156,
      "latitude": 38.721912,
      "longitude": -77.466897,
      "city": "Gainesville",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20158,
      "latitude": 39.138314,
      "longitude": -77.657328,
      "city": "Hamilton",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20159,
      "latitude": 39.133855,
      "longitude": -77.662072,
      "city": "Hamilton",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20160,
      "latitude": 39.098479,
      "longitude": -77.688338,
      "city": "Lincoln",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20163,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Sterling",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20164,
      "latitude": 39.023047,
      "longitude": -77.399367,
      "city": "Sterling",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20165,
      "latitude": 39.047157,
      "longitude": -77.386647,
      "city": "Sterling",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20166,
      "latitude": 38.981387,
      "longitude": -77.472333,
      "city": "Sterling",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20167,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Sterling",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20168,
      "latitude": 38.721912,
      "longitude": -77.466897,
      "city": "Haymarket",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20169,
      "latitude": 38.867441,
      "longitude": -77.644539,
      "city": "Haymarket",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20170,
      "latitude": 38.983887,
      "longitude": -77.367457,
      "city": "Herndon",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20171,
      "latitude": 38.92518,
      "longitude": -77.39284,
      "city": "Herndon",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20172,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Herndon",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20175,
      "latitude": 39.041987,
      "longitude": -77.605404,
      "city": "Leesburg",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20176,
      "latitude": 39.119727,
      "longitude": -77.603458,
      "city": "Leesburg",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20177,
      "latitude": 39.158095,
      "longitude": -77.666863,
      "city": "Leesburg",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20178,
      "latitude": 39.072918,
      "longitude": -77.608014,
      "city": "Leesburg",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20180,
      "latitude": 39.220432,
      "longitude": -77.659636,
      "city": "Lovettsville",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20181,
      "latitude": 38.700017,
      "longitude": -77.548271,
      "city": "Nokesville",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20182,
      "latitude": 38.700866,
      "longitude": -77.585708,
      "city": "Nokesville",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 20184,
      "latitude": 38.962661,
      "longitude": -77.884726,
      "city": "Upperville",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20185,
      "latitude": 38.992978,
      "longitude": -77.879855,
      "city": "Upperville",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20186,
      "latitude": 38.689832,
      "longitude": -77.836115,
      "city": "Warrenton",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20187,
      "latitude": 38.715296,
      "longitude": -77.741696,
      "city": "Warrenton",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20188,
      "latitude": 38.765619,
      "longitude": -77.820293,
      "city": "Warrenton",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20190,
      "latitude": 38.96146,
      "longitude": -77.3418,
      "city": "Reston",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20191,
      "latitude": 38.93181,
      "longitude": -77.352718,
      "city": "Reston",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20192,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Herndon",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20193,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Reston",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20194,
      "latitude": 38.980742,
      "longitude": -77.341878,
      "city": "Reston",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20195,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Reston",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20196,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Reston",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 20197,
      "latitude": 39.188205,
      "longitude": -77.630013,
      "city": "Waterford",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20198,
      "latitude": 38.870681,
      "longitude": -77.760779,
      "city": "The Plains",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 20199,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Dulles",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 20201,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20202,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20203,
      "latitude": 38.905317,
      "longitude": -77.046645,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20204,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20206,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20207,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20208,
      "latitude": 38.896611,
      "longitude": -77.011748,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20210,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20211,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20212,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20213,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20214,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20215,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20216,
      "latitude": 38.891911,
      "longitude": -77.014098,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20217,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20218,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20219,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20220,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20221,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20222,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20223,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20224,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20226,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20227,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20228,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20229,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20230,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20231,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20232,
      "latitude": 38.900561,
      "longitude": -77.039099,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20233,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20235,
      "latitude": 38.915361,
      "longitude": -77.057199,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20237,
      "latitude": 38.887555,
      "longitude": -77.016054,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20238,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20239,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20240,
      "latitude": 38.897143,
      "longitude": -77.040895,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20241,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20242,
      "latitude": 38.867812,
      "longitude": -77.028948,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20244,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20245,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20250,
      "latitude": 38.887323,
      "longitude": -77.03274,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20251,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20254,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20260,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20261,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20262,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20265,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20266,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20268,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20270,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20277,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20289,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20299,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20301,
      "latitude": 38.889357,
      "longitude": -77.031113,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20303,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20306,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20307,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20310,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20314,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20315,
      "latitude": 38.928861,
      "longitude": -77.017948,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20317,
      "latitude": 38.931161,
      "longitude": -77.010298,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20318,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20319,
      "latitude": 38.866745,
      "longitude": -77.016556,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20330,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20332,
      "latitude": 38.834563,
      "longitude": -77.015848,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20336,
      "latitude": 38.860415,
      "longitude": -77.022545,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20337,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20338,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20340,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20350,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20370,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20372,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20373,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Anacostia Annex",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20374,
      "latitude": 38.855522,
      "longitude": -77.002197,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20375,
      "latitude": 38.826163,
      "longitude": -77.017448,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20376,
      "latitude": 38.872883,
      "longitude": -76.997425,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20380,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20388,
      "latitude": 38.872553,
      "longitude": -76.996605,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20389,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20390,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20391,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20392,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20393,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20394,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20395,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20398,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20401,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20402,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20403,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20404,
      "latitude": 38.899161,
      "longitude": -77.008948,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20405,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20406,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20407,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20408,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20409,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20410,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20411,
      "latitude": 38.883962,
      "longitude": -77.022098,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20412,
      "latitude": 38.895261,
      "longitude": -77.022098,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20413,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20414,
      "latitude": 38.883962,
      "longitude": -77.022098,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20415,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20416,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20418,
      "latitude": 38.904253,
      "longitude": -77.05723,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20419,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20420,
      "latitude": 38.903461,
      "longitude": -77.027648,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20421,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20422,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20423,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20424,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20425,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20426,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20427,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20428,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20429,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20431,
      "latitude": 38.898618,
      "longitude": -77.042753,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20433,
      "latitude": 38.899996,
      "longitude": -77.041976,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20434,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20435,
      "latitude": 38.899399,
      "longitude": -77.040319,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20436,
      "latitude": 38.895927,
      "longitude": -77.021068,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20437,
      "latitude": 38.902761,
      "longitude": -77.048499,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20439,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20440,
      "latitude": 38.913911,
      "longitude": -77.045349,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20441,
      "latitude": 38.923861,
      "longitude": -77.036349,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20442,
      "latitude": 38.895977,
      "longitude": -77.017668,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20444,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20447,
      "latitude": 38.884712,
      "longitude": -77.025198,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20451,
      "latitude": 38.897711,
      "longitude": -77.044449,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20453,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20456,
      "latitude": 38.898111,
      "longitude": -77.040149,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20460,
      "latitude": 38.876362,
      "longitude": -77.018798,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20463,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20468,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20469,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20470,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20472,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20500,
      "latitude": 38.89876,
      "longitude": -77.03645,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20501,
      "latitude": 38.89872,
      "longitude": -77.036198,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20502,
      "latitude": 38.89872,
      "longitude": -77.036198,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20503,
      "latitude": 38.900711,
      "longitude": -77.043068,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20504,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20505,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20506,
      "latitude": 38.899364,
      "longitude": -77.037668,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20507,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20508,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20510,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20515,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20520,
      "latitude": 38.893217,
      "longitude": -77.048957,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20521,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20522,
      "latitude": 38.893217,
      "longitude": -77.048957,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20523,
      "latitude": 38.89449,
      "longitude": -77.047807,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20524,
      "latitude": 38.902414,
      "longitude": -77.032601,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20525,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20526,
      "latitude": 38.902217,
      "longitude": -77.043738,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20527,
      "latitude": 38.902933,
      "longitude": -77.036091,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20530,
      "latitude": 38.897561,
      "longitude": -77.026998,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20531,
      "latitude": 38.893761,
      "longitude": -77.021848,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20532,
      "latitude": 38.904486,
      "longitude": -77.017291,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20533,
      "latitude": 38.901102,
      "longitude": -77.032591,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20534,
      "latitude": 38.894075,
      "longitude": -77.01254,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20535,
      "latitude": 38.894097,
      "longitude": -77.025133,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20536,
      "latitude": 38.901211,
      "longitude": -77.016948,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20537,
      "latitude": 38.894097,
      "longitude": -77.025133,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20538,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20539,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20540,
      "latitude": 38.887405,
      "longitude": -77.004663,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20541,
      "latitude": 38.887405,
      "longitude": -77.004663,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20542,
      "latitude": 38.940761,
      "longitude": -77.028348,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20543,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20544,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20546,
      "latitude": 38.890976,
      "longitude": -77.021092,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20547,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20548,
      "latitude": 38.898111,
      "longitude": -77.017698,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20549,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20550,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20551,
      "latitude": 38.891995,
      "longitude": -77.045219,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20552,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20553,
      "latitude": 38.887333,
      "longitude": -77.023118,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20554,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20555,
      "latitude": 38.900011,
      "longitude": -77.040149,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20557,
      "latitude": 38.887405,
      "longitude": -77.004663,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20558,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20559,
      "latitude": 38.887405,
      "longitude": -77.004663,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20560,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20565,
      "latitude": 38.891941,
      "longitude": -77.018853,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20566,
      "latitude": 38.897074,
      "longitude": -77.05535,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20570,
      "latitude": 38.899061,
      "longitude": -77.040149,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20571,
      "latitude": 38.900559,
      "longitude": -77.034554,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20572,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20573,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20575,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20576,
      "latitude": 38.893662,
      "longitude": -77.023592,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20577,
      "latitude": 38.900758,
      "longitude": -77.034455,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20578,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20579,
      "latitude": 38.904311,
      "longitude": -77.044649,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20580,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20581,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20585,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20586,
      "latitude": 38.902211,
      "longitude": -77.047399,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20590,
      "latitude": 38.883962,
      "longitude": -77.022098,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20591,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20593,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20594,
      "latitude": 38.88494,
      "longitude": -77.018372,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20597,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20599,
      "latitude": 38.893311,
      "longitude": -77.014647,
      "city": "Washington",
      "state": "DC",
      "county": "District Of Columbia"
  },
  {
      "zipCode": 20601,
      "latitude": 38.603783,
      "longitude": -76.867928,
      "city": "Waldorf",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20602,
      "latitude": 38.518559,
      "longitude": -76.912165,
      "city": "Waldorf",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20603,
      "latitude": 38.52115,
      "longitude": -77.062012,
      "city": "Waldorf",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20604,
      "latitude": 38.509548,
      "longitude": -76.981651,
      "city": "Waldorf",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20606,
      "latitude": 38.247149,
      "longitude": -76.748088,
      "city": "Abell",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20607,
      "latitude": 38.666071,
      "longitude": -76.998396,
      "city": "Accokeek",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20608,
      "latitude": 38.588843,
      "longitude": -76.712639,
      "city": "Aquasco",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20609,
      "latitude": 38.300217,
      "longitude": -76.743445,
      "city": "Avenue",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20610,
      "latitude": 38.444727,
      "longitude": -76.533026,
      "city": "Barstow",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20611,
      "latitude": 38.454919,
      "longitude": -76.980364,
      "city": "Bel Alton",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20612,
      "latitude": 38.508689,
      "longitude": -76.687344,
      "city": "Benedict",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20613,
      "latitude": 38.658807,
      "longitude": -76.824293,
      "city": "Brandywine",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20615,
      "latitude": 38.493984,
      "longitude": -76.567469,
      "city": "Broomes Island",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20616,
      "latitude": 38.636227,
      "longitude": -77.084649,
      "city": "Bryans Road",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20617,
      "latitude": 38.536256,
      "longitude": -76.853752,
      "city": "Bryantown",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20618,
      "latitude": 38.309507,
      "longitude": -76.763069,
      "city": "Bushwood",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20619,
      "latitude": 38.324994,
      "longitude": -76.637383,
      "city": "California",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20620,
      "latitude": 38.347927,
      "longitude": -76.621613,
      "city": "Callaway",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20621,
      "latitude": 38.350987,
      "longitude": -76.783288,
      "city": "Chaptico",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20622,
      "latitude": 38.484421,
      "longitude": -76.754943,
      "city": "Charlotte Hall",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20623,
      "latitude": 38.745514,
      "longitude": -76.840572,
      "city": "Cheltenham",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20624,
      "latitude": 38.329424,
      "longitude": -76.730799,
      "city": "Clements",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20625,
      "latitude": 38.262037,
      "longitude": -76.85019,
      "city": "Cobb Island",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20626,
      "latitude": 38.236645,
      "longitude": -76.762227,
      "city": "Coltons Point",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20627,
      "latitude": 38.276828,
      "longitude": -76.703971,
      "city": "Compton",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20628,
      "latitude": 38.148114,
      "longitude": -76.359651,
      "city": "Dameron",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20629,
      "latitude": 38.376974,
      "longitude": -76.434476,
      "city": "Dowell",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20630,
      "latitude": 38.178948,
      "longitude": -76.47451,
      "city": "Drayden",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20632,
      "latitude": 38.421762,
      "longitude": -76.948718,
      "city": "Faulkner",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20634,
      "latitude": 38.242387,
      "longitude": -76.501513,
      "city": "Great Mills",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20635,
      "latitude": 38.312112,
      "longitude": -76.607676,
      "city": "Helen",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20636,
      "latitude": 38.342437,
      "longitude": -76.569163,
      "city": "Hollywood",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20637,
      "latitude": 38.520572,
      "longitude": -76.781313,
      "city": "Hughesville",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20639,
      "latitude": 38.519368,
      "longitude": -76.546326,
      "city": "Huntingtown",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20640,
      "latitude": 38.453999,
      "longitude": -77.052819,
      "city": "Indian Head",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20643,
      "latitude": 38.503876,
      "longitude": -77.148283,
      "city": "Ironsides",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20645,
      "latitude": 38.298477,
      "longitude": -76.88491,
      "city": "Issue",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20646,
      "latitude": 38.513875,
      "longitude": -77.01034,
      "city": "La Plata",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20650,
      "latitude": 38.278757,
      "longitude": -76.655917,
      "city": "Leonardtown",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20653,
      "latitude": 38.232909,
      "longitude": -76.430853,
      "city": "Lexington Park",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20656,
      "latitude": 38.359274,
      "longitude": -76.683325,
      "city": "Loveville",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20657,
      "latitude": 38.49343,
      "longitude": -76.459768,
      "city": "Lusby",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20658,
      "latitude": 38.563331,
      "longitude": -77.159621,
      "city": "Marbury",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20659,
      "latitude": 38.279211,
      "longitude": -76.608406,
      "city": "Mechanicsville",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20660,
      "latitude": 38.363994,
      "longitude": -76.694102,
      "city": "Morganza",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20661,
      "latitude": 38.343591,
      "longitude": -76.884559,
      "city": "Mount Victoria",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20662,
      "latitude": 38.430568,
      "longitude": -77.192545,
      "city": "Nanjemoy",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20664,
      "latitude": 38.364718,
      "longitude": -76.917398,
      "city": "Newburg",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20667,
      "latitude": 38.216529,
      "longitude": -76.433445,
      "city": "Park Hall",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20670,
      "latitude": 38.281094,
      "longitude": -76.420952,
      "city": "Patuxent River",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20674,
      "latitude": 38.16863,
      "longitude": -76.498367,
      "city": "Piney Point",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20675,
      "latitude": 38.576096,
      "longitude": -77.015916,
      "city": "Pomfret",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20676,
      "latitude": 38.560231,
      "longitude": -76.531761,
      "city": "Port Republic",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20677,
      "latitude": 38.504699,
      "longitude": -77.037862,
      "city": "Port Tobacco",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20678,
      "latitude": 38.511282,
      "longitude": -76.528578,
      "city": "Prince Frederick",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20680,
      "latitude": 38.122181,
      "longitude": -76.369412,
      "city": "Ridge",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20682,
      "latitude": 38.283551,
      "longitude": -76.848093,
      "city": "Rock Point",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20684,
      "latitude": 38.141377,
      "longitude": -76.38535,
      "city": "Saint Inigoes",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20685,
      "latitude": 38.440484,
      "longitude": -76.51736,
      "city": "Saint Leonard",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20686,
      "latitude": 38.188796,
      "longitude": -76.420738,
      "city": "Saint Marys City",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20687,
      "latitude": 38.078898,
      "longitude": -76.351659,
      "city": "Scotland",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20688,
      "latitude": 38.37741,
      "longitude": -76.440937,
      "city": "Solomons",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20689,
      "latitude": 38.556448,
      "longitude": -76.541436,
      "city": "Sunderland",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20690,
      "latitude": 38.160744,
      "longitude": -76.525504,
      "city": "Tall Timbers",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20692,
      "latitude": 38.192688,
      "longitude": -76.501604,
      "city": "Valley Lee",
      "state": "MD",
      "county": "Saint Marys"
  },
  {
      "zipCode": 20693,
      "latitude": 38.476246,
      "longitude": -77.084009,
      "city": "Welcome",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20695,
      "latitude": 38.59744,
      "longitude": -76.990278,
      "city": "White Plains",
      "state": "MD",
      "county": "Charles"
  },
  {
      "zipCode": 20697,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Southern Md Facility",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20701,
      "latitude": 39.1332,
      "longitude": -76.798843,
      "city": "Annapolis Junction",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 20703,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Lanham",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20704,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Beltsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20705,
      "latitude": 39.044858,
      "longitude": -76.886795,
      "city": "Beltsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20706,
      "latitude": 38.96076,
      "longitude": -76.856135,
      "city": "Lanham",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20707,
      "latitude": 39.093136,
      "longitude": -76.881945,
      "city": "Laurel",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20708,
      "latitude": 39.049859,
      "longitude": -76.834493,
      "city": "Laurel",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20709,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Laurel",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20710,
      "latitude": 38.901211,
      "longitude": -76.925943,
      "city": "Bladensburg",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20711,
      "latitude": 38.802263,
      "longitude": -76.645735,
      "city": "Lothian",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20712,
      "latitude": 38.94266,
      "longitude": -76.967057,
      "city": "Mount Rainier",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20714,
      "latitude": 38.610496,
      "longitude": -76.595901,
      "city": "North Beach",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20715,
      "latitude": 38.982612,
      "longitude": -76.74379,
      "city": "Bowie",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20716,
      "latitude": 38.926311,
      "longitude": -76.709789,
      "city": "Bowie",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20717,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Bowie",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20718,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Bowie",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20719,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Bowie",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20720,
      "latitude": 38.98851,
      "longitude": -76.79098,
      "city": "Bowie",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20721,
      "latitude": 38.919357,
      "longitude": -76.787141,
      "city": "Bowie",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20722,
      "latitude": 38.886849,
      "longitude": -76.865764,
      "city": "Brentwood",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20723,
      "latitude": 39.13652,
      "longitude": -76.868729,
      "city": "Laurel",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 20724,
      "latitude": 39.098008,
      "longitude": -76.804443,
      "city": "Laurel",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20725,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Laurel",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20726,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Laurel",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20731,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Capitol Heights",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20732,
      "latitude": 38.565841,
      "longitude": -76.526169,
      "city": "Chesapeake Beach",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20733,
      "latitude": 38.921313,
      "longitude": -76.532569,
      "city": "Churchton",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20735,
      "latitude": 38.739765,
      "longitude": -76.912096,
      "city": "Clinton",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20736,
      "latitude": 38.548775,
      "longitude": -76.562876,
      "city": "Owings",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20737,
      "latitude": 38.963206,
      "longitude": -76.917795,
      "city": "Riverdale",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20738,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Riverdale",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20740,
      "latitude": 38.979495,
      "longitude": -76.895852,
      "city": "College Park",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20741,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "College Park",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20742,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "College Park",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20743,
      "latitude": 38.889711,
      "longitude": -76.892544,
      "city": "Capitol Heights",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20744,
      "latitude": 38.757114,
      "longitude": -76.977596,
      "city": "Fort Washington",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20745,
      "latitude": 38.814362,
      "longitude": -76.957746,
      "city": "Oxon Hill",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20746,
      "latitude": 38.834912,
      "longitude": -76.912745,
      "city": "Suitland",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20747,
      "latitude": 38.851262,
      "longitude": -76.885744,
      "city": "District Heights",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20748,
      "latitude": 38.817263,
      "longitude": -76.935795,
      "city": "Temple Hills",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20749,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Fort Washington",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20750,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Oxon Hill",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20751,
      "latitude": 38.960712,
      "longitude": -76.621708,
      "city": "Deale",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20752,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Suitland",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20753,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "District Heights",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20754,
      "latitude": 38.604072,
      "longitude": -76.604364,
      "city": "Dunkirk",
      "state": "MD",
      "county": "Calvert"
  },
  {
      "zipCode": 20755,
      "latitude": 39.154307,
      "longitude": -76.69119,
      "city": "Fort George G Meade",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20757,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Temple Hills",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20758,
      "latitude": 38.736079,
      "longitude": -76.583547,
      "city": "Friendship",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20759,
      "latitude": 39.160806,
      "longitude": -76.927881,
      "city": "Fulton",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 20762,
      "latitude": 38.806207,
      "longitude": -76.875551,
      "city": "Andrews Air Force Base",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20763,
      "latitude": 39.136189,
      "longitude": -76.815044,
      "city": "Savage",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 20764,
      "latitude": 38.965727,
      "longitude": -76.589086,
      "city": "Shady Side",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20765,
      "latitude": 38.974512,
      "longitude": -76.545083,
      "city": "Galesville",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20768,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Greenbelt",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20769,
      "latitude": 38.98346,
      "longitude": -76.811392,
      "city": "Glenn Dale",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20770,
      "latitude": 38.995909,
      "longitude": -76.880966,
      "city": "Greenbelt",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20771,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Greenbelt",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20772,
      "latitude": 38.816171,
      "longitude": -76.786093,
      "city": "Upper Marlboro",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20773,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Upper Marlboro",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20774,
      "latitude": 38.868212,
      "longitude": -76.815591,
      "city": "Upper Marlboro",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20775,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Upper Marlboro",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20776,
      "latitude": 38.962992,
      "longitude": -76.564785,
      "city": "Harwood",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20777,
      "latitude": 39.173059,
      "longitude": -76.966718,
      "city": "Highland",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 20778,
      "latitude": 39.002411,
      "longitude": -76.563885,
      "city": "West River",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20779,
      "latitude": 38.942878,
      "longitude": -76.558783,
      "city": "Tracys Landing",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 20781,
      "latitude": 38.94161,
      "longitude": -76.937446,
      "city": "Hyattsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20782,
      "latitude": 38.912211,
      "longitude": -76.967247,
      "city": "Hyattsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20783,
      "latitude": 39.000509,
      "longitude": -76.972297,
      "city": "Hyattsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20784,
      "latitude": 38.95131,
      "longitude": -76.895795,
      "city": "Hyattsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20785,
      "latitude": 38.922261,
      "longitude": -76.875494,
      "city": "Hyattsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20787,
      "latitude": 38.98707,
      "longitude": -76.982405,
      "city": "Hyattsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20788,
      "latitude": 38.969434,
      "longitude": -76.950913,
      "city": "Hyattsville",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20790,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Capitol Heights",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20791,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Capitol Heights",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20792,
      "latitude": 38.903377,
      "longitude": -76.837204,
      "city": "Upper Marlboro",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20794,
      "latitude": 39.155266,
      "longitude": -76.813394,
      "city": "Jessup",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 20797,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Southern Md Facility",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20799,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Capitol Heights",
      "state": "MD",
      "county": "Prince Georges"
  },
  {
      "zipCode": 20812,
      "latitude": 38.96851,
      "longitude": -77.140801,
      "city": "Glen Echo",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20813,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20814,
      "latitude": 39.00452,
      "longitude": -77.1046,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20815,
      "latitude": 38.983759,
      "longitude": -77.079449,
      "city": "Chevy Chase",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20816,
      "latitude": 38.955907,
      "longitude": -77.1165,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20817,
      "latitude": 38.989586,
      "longitude": -77.153801,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20818,
      "latitude": 39.128407,
      "longitude": -77.180053,
      "city": "Cabin John",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20824,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20825,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Chevy Chase",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20827,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20830,
      "latitude": 39.15522,
      "longitude": -77.066745,
      "city": "Olney",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20832,
      "latitude": 39.150364,
      "longitude": -77.077651,
      "city": "Olney",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20833,
      "latitude": 39.204341,
      "longitude": -77.05055,
      "city": "Brookeville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20837,
      "latitude": 39.123705,
      "longitude": -77.403211,
      "city": "Poolesville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20838,
      "latitude": 39.228424,
      "longitude": -77.37097,
      "city": "Barnesville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20839,
      "latitude": 39.17877,
      "longitude": -77.419605,
      "city": "Beallsville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20841,
      "latitude": 39.184675,
      "longitude": -77.328764,
      "city": "Boyds",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20842,
      "latitude": 39.191093,
      "longitude": -77.421929,
      "city": "Dickerson",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20847,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20848,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20849,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20850,
      "latitude": 39.089557,
      "longitude": -77.184127,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20851,
      "latitude": 39.077858,
      "longitude": -77.125751,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20852,
      "latitude": 39.052158,
      "longitude": -77.121096,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20853,
      "latitude": 39.107357,
      "longitude": -77.0996,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20854,
      "latitude": 39.029839,
      "longitude": -77.234792,
      "city": "Potomac",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20855,
      "latitude": 39.142857,
      "longitude": -77.138145,
      "city": "Derwood",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20857,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Rockville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20859,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Potomac",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20860,
      "latitude": 39.142424,
      "longitude": -77.044614,
      "city": "Sandy Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20861,
      "latitude": 39.151468,
      "longitude": -76.994498,
      "city": "Ashton",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20862,
      "latitude": 39.175506,
      "longitude": -77.020626,
      "city": "Brinklow",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20866,
      "latitude": 39.105644,
      "longitude": -76.935861,
      "city": "Burtonsville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20868,
      "latitude": 39.122262,
      "longitude": -76.972157,
      "city": "Spencerville",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20871,
      "latitude": 39.207775,
      "longitude": -77.259816,
      "city": "Clarksburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20872,
      "latitude": 39.285477,
      "longitude": -77.215203,
      "city": "Damascus",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20874,
      "latitude": 39.135512,
      "longitude": -77.282223,
      "city": "Germantown",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20875,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Germantown",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20876,
      "latitude": 39.210218,
      "longitude": -77.239729,
      "city": "Germantown",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20877,
      "latitude": 39.139336,
      "longitude": -77.182953,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20878,
      "latitude": 39.091857,
      "longitude": -77.198452,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20879,
      "latitude": 39.173,
      "longitude": -77.185542,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20880,
      "latitude": 39.138815,
      "longitude": -77.172591,
      "city": "Washington Grove",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20882,
      "latitude": 39.233512,
      "longitude": -77.145781,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20883,
      "latitude": 39.121873,
      "longitude": -77.233664,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20884,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20885,
      "latitude": 39.187421,
      "longitude": -77.202798,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20886,
      "latitude": 39.175703,
      "longitude": -77.18725,
      "city": "Montgomery Village",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20889,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20891,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Kensington",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20892,
      "latitude": 39.002375,
      "longitude": -77.10341,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20894,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Bethesda",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20895,
      "latitude": 39.09538,
      "longitude": -77.111555,
      "city": "Kensington",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20896,
      "latitude": 39.036496,
      "longitude": -77.0931,
      "city": "Garrett Park",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20897,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Suburb Maryland Fac",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20898,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20899,
      "latitude": 39.140324,
      "longitude": -77.221984,
      "city": "Gaithersburg",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20901,
      "latitude": 39.036289,
      "longitude": -77.010948,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20902,
      "latitude": 39.039974,
      "longitude": -77.044449,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20903,
      "latitude": 39.015209,
      "longitude": -76.980597,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20904,
      "latitude": 39.066844,
      "longitude": -76.996866,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20905,
      "latitude": 39.114753,
      "longitude": -77.00587,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20906,
      "latitude": 39.083978,
      "longitude": -77.061278,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20907,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20908,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20910,
      "latitude": 39.003109,
      "longitude": -77.029648,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20911,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20912,
      "latitude": 38.98236,
      "longitude": -77.005798,
      "city": "Takoma Park",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20913,
      "latitude": 38.833563,
      "longitude": -76.877743,
      "city": "Takoma Park",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20914,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20915,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20916,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20918,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 20997,
      "latitude": 39.143979,
      "longitude": -77.207617,
      "city": "Silver Spring",
      "state": "MD",
      "county": "Montgomery"
  },
  {
      "zipCode": 21001,
      "latitude": 39.499909,
      "longitude": -76.231177,
      "city": "Aberdeen",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21005,
      "latitude": 39.477062,
      "longitude": -76.120824,
      "city": "Aberdeen Proving Ground",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21009,
      "latitude": 39.472579,
      "longitude": -76.277279,
      "city": "Abingdon",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21010,
      "latitude": 39.380158,
      "longitude": -76.295468,
      "city": "Gunpowder",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21012,
      "latitude": 39.031461,
      "longitude": -76.623203,
      "city": "Arnold",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21013,
      "latitude": 39.496406,
      "longitude": -76.486624,
      "city": "Baldwin",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21014,
      "latitude": 39.547557,
      "longitude": -76.321984,
      "city": "Bel Air",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21015,
      "latitude": 39.551757,
      "longitude": -76.298713,
      "city": "Bel Air",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21017,
      "latitude": 39.47366,
      "longitude": -76.238835,
      "city": "Belcamp",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21018,
      "latitude": 39.509276,
      "longitude": -76.385083,
      "city": "Benson",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21020,
      "latitude": 39.521276,
      "longitude": -76.804699,
      "city": "Boring",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21022,
      "latitude": 39.397861,
      "longitude": -76.671742,
      "city": "Brooklandville",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21023,
      "latitude": 39.532955,
      "longitude": -76.743196,
      "city": "Butler",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21027,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Chase",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21028,
      "latitude": 39.563172,
      "longitude": -76.236427,
      "city": "Churchville",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21029,
      "latitude": 39.212456,
      "longitude": -76.951498,
      "city": "Clarksville",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21030,
      "latitude": 39.491227,
      "longitude": -76.664077,
      "city": "Cockeysville",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21031,
      "latitude": 39.480493,
      "longitude": -76.655257,
      "city": "Hunt Valley",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21032,
      "latitude": 39.01811,
      "longitude": -76.588272,
      "city": "Crownsville",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21034,
      "latitude": 39.646205,
      "longitude": -76.226377,
      "city": "Darlington",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21035,
      "latitude": 39.04296,
      "longitude": -76.62863,
      "city": "Davidsonville",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21036,
      "latitude": 39.240155,
      "longitude": -77.00375,
      "city": "Dayton",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21037,
      "latitude": 38.975128,
      "longitude": -76.623684,
      "city": "Edgewater",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21040,
      "latitude": 39.434799,
      "longitude": -76.293579,
      "city": "Edgewood",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21041,
      "latitude": 39.236405,
      "longitude": -76.941902,
      "city": "Ellicott City",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21042,
      "latitude": 39.261897,
      "longitude": -76.897247,
      "city": "Ellicott City",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21043,
      "latitude": 39.254833,
      "longitude": -76.800124,
      "city": "Ellicott City",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21044,
      "latitude": 39.206132,
      "longitude": -76.887905,
      "city": "Columbia",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21045,
      "latitude": 39.207806,
      "longitude": -76.825475,
      "city": "Columbia",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21046,
      "latitude": 39.175406,
      "longitude": -76.834945,
      "city": "Columbia",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21047,
      "latitude": 39.531206,
      "longitude": -76.439035,
      "city": "Fallston",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21048,
      "latitude": 39.499102,
      "longitude": -76.910108,
      "city": "Finksburg",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21050,
      "latitude": 39.587155,
      "longitude": -76.392733,
      "city": "Forest Hill",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21051,
      "latitude": 39.47314,
      "longitude": -76.448385,
      "city": "Fork",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21052,
      "latitude": 39.206998,
      "longitude": -76.445599,
      "city": "Fort Howard",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21053,
      "latitude": 39.687103,
      "longitude": -76.717644,
      "city": "Freeland",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21054,
      "latitude": 39.048359,
      "longitude": -76.631238,
      "city": "Gambrills",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21055,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Garrison",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21056,
      "latitude": 39.086064,
      "longitude": -76.545474,
      "city": "Gibson Island",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21057,
      "latitude": 39.451006,
      "longitude": -76.501186,
      "city": "Glen Arm",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21060,
      "latitude": 39.170158,
      "longitude": -76.579836,
      "city": "Glen Burnie",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21061,
      "latitude": 38.967943,
      "longitude": -76.615637,
      "city": "Glen Burnie",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21062,
      "latitude": 38.974203,
      "longitude": -76.594942,
      "city": "Glen Burnie",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21071,
      "latitude": 39.515748,
      "longitude": -76.766097,
      "city": "Glyndon",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21074,
      "latitude": 39.614552,
      "longitude": -76.864427,
      "city": "Hampstead",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21075,
      "latitude": 39.205756,
      "longitude": -76.753093,
      "city": "Elkridge",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21076,
      "latitude": 39.057694,
      "longitude": -76.615557,
      "city": "Hanover",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21077,
      "latitude": 38.947102,
      "longitude": -76.629277,
      "city": "Harmans",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21078,
      "latitude": 39.560095,
      "longitude": -76.15517,
      "city": "Havre De Grace",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21080,
      "latitude": 39.534703,
      "longitude": -77.049302,
      "city": "Henryton",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21082,
      "latitude": 39.481685,
      "longitude": -76.471736,
      "city": "Hydes",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21084,
      "latitude": 39.552706,
      "longitude": -76.419734,
      "city": "Jarrettsville",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21085,
      "latitude": 39.449157,
      "longitude": -76.351881,
      "city": "Joppa",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21087,
      "latitude": 39.445257,
      "longitude": -76.421783,
      "city": "Kingsville",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21088,
      "latitude": 39.534703,
      "longitude": -77.049302,
      "city": "Lineboro",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21090,
      "latitude": 39.068309,
      "longitude": -76.631743,
      "city": "Linthicum Heights",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21092,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Long Green",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21093,
      "latitude": 39.433206,
      "longitude": -76.654593,
      "city": "Lutherville Timonium",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21094,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Lutherville Timonium",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21098,
      "latitude": 38.974203,
      "longitude": -76.594942,
      "city": "Hanover",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21102,
      "latitude": 39.556164,
      "longitude": -76.870849,
      "city": "Manchester",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21104,
      "latitude": 39.378104,
      "longitude": -76.916267,
      "city": "Marriottsville",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21105,
      "latitude": 39.711379,
      "longitude": -76.659492,
      "city": "Maryland Line",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21106,
      "latitude": 38.971475,
      "longitude": -76.580934,
      "city": "Mayo",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21108,
      "latitude": 38.986961,
      "longitude": -76.582479,
      "city": "Millersville",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21111,
      "latitude": 39.566189,
      "longitude": -76.597891,
      "city": "Monkton",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21113,
      "latitude": 38.975412,
      "longitude": -76.586886,
      "city": "Odenton",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21114,
      "latitude": 39.06436,
      "longitude": -76.622577,
      "city": "Crofton",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21117,
      "latitude": 39.429055,
      "longitude": -76.787696,
      "city": "Owings Mills",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21120,
      "latitude": 39.642203,
      "longitude": -76.673693,
      "city": "Parkton",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21122,
      "latitude": 38.976813,
      "longitude": -76.616185,
      "city": "Pasadena",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21123,
      "latitude": 38.974203,
      "longitude": -76.594942,
      "city": "Pasadena",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21128,
      "latitude": 39.404757,
      "longitude": -76.450084,
      "city": "Perry Hall",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21130,
      "latitude": 39.471559,
      "longitude": -76.211676,
      "city": "Perryman",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21131,
      "latitude": 39.483255,
      "longitude": -76.577619,
      "city": "Phoenix",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21132,
      "latitude": 39.692854,
      "longitude": -76.421834,
      "city": "Pylesville",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21133,
      "latitude": 39.377872,
      "longitude": -76.816675,
      "city": "Randallstown",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21136,
      "latitude": 39.487615,
      "longitude": -76.796347,
      "city": "Reisterstown",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21139,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Riderwood",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21140,
      "latitude": 39.031943,
      "longitude": -76.625199,
      "city": "Riva",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21144,
      "latitude": 38.983971,
      "longitude": -76.623121,
      "city": "Severn",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21146,
      "latitude": 38.951199,
      "longitude": -76.603722,
      "city": "Severna Park",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21150,
      "latitude": 39.236405,
      "longitude": -76.941902,
      "city": "Simpsonville",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21152,
      "latitude": 39.526441,
      "longitude": -76.715587,
      "city": "Sparks Glencoe",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21153,
      "latitude": 39.412905,
      "longitude": -76.730345,
      "city": "Stevenson",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21154,
      "latitude": 39.657355,
      "longitude": -76.371332,
      "city": "Street",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21155,
      "latitude": 39.567574,
      "longitude": -76.797213,
      "city": "Upperco",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21156,
      "latitude": 39.461038,
      "longitude": -76.583163,
      "city": "Upper Falls",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21157,
      "latitude": 39.564201,
      "longitude": -76.980701,
      "city": "Westminster",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21158,
      "latitude": 39.606981,
      "longitude": -77.029403,
      "city": "Westminster",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21160,
      "latitude": 39.703155,
      "longitude": -76.330641,
      "city": "Whiteford",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21161,
      "latitude": 39.658104,
      "longitude": -76.527937,
      "city": "White Hall",
      "state": "MD",
      "county": "Harford"
  },
  {
      "zipCode": 21162,
      "latitude": 39.389457,
      "longitude": -76.411976,
      "city": "White Marsh",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21163,
      "latitude": 39.349834,
      "longitude": -76.845587,
      "city": "Woodstock",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21201,
      "latitude": 39.296536,
      "longitude": -76.623489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21202,
      "latitude": 39.295907,
      "longitude": -76.608438,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21203,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21204,
      "latitude": 39.407156,
      "longitude": -76.60379,
      "city": "Towson",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21205,
      "latitude": 39.302757,
      "longitude": -76.567987,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21206,
      "latitude": 39.336857,
      "longitude": -76.553237,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21207,
      "latitude": 39.325605,
      "longitude": -76.736594,
      "city": "Gwynn Oak",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21208,
      "latitude": 39.385156,
      "longitude": -76.727036,
      "city": "Pikesville",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21209,
      "latitude": 39.354256,
      "longitude": -76.667892,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21210,
      "latitude": 39.352356,
      "longitude": -76.63329,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21211,
      "latitude": 39.327889,
      "longitude": -76.63899,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21212,
      "latitude": 39.356206,
      "longitude": -76.610989,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21213,
      "latitude": 39.306606,
      "longitude": -76.603338,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21214,
      "latitude": 39.350006,
      "longitude": -76.562187,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21215,
      "latitude": 39.343306,
      "longitude": -76.681192,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21216,
      "latitude": 39.310756,
      "longitude": -76.674792,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21217,
      "latitude": 39.308606,
      "longitude": -76.63749,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21218,
      "latitude": 39.328456,
      "longitude": -76.599139,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21219,
      "latitude": 39.229611,
      "longitude": -76.445532,
      "city": "Sparrows Point",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21220,
      "latitude": 39.344057,
      "longitude": -76.505086,
      "city": "Middle River",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21221,
      "latitude": 39.296007,
      "longitude": -76.559787,
      "city": "Essex",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21222,
      "latitude": 39.325991,
      "longitude": -76.451733,
      "city": "Dundalk",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21223,
      "latitude": 39.279506,
      "longitude": -76.65014,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21224,
      "latitude": 39.279207,
      "longitude": -76.557237,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21225,
      "latitude": 39.018973,
      "longitude": -76.616437,
      "city": "Brooklyn",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21226,
      "latitude": 39.06871,
      "longitude": -76.584137,
      "city": "Curtis Bay",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21227,
      "latitude": 39.241606,
      "longitude": -76.677291,
      "city": "Halethorpe",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21228,
      "latitude": 39.274156,
      "longitude": -76.631074,
      "city": "Catonsville",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21229,
      "latitude": 39.286613,
      "longitude": -76.65284,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21230,
      "latitude": 39.264548,
      "longitude": -76.622439,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21231,
      "latitude": 39.288757,
      "longitude": -76.592238,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21233,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21234,
      "latitude": 39.413106,
      "longitude": -76.530044,
      "city": "Parkville",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21235,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21236,
      "latitude": 39.392107,
      "longitude": -76.489277,
      "city": "Nottingham",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21237,
      "latitude": 39.339957,
      "longitude": -76.488035,
      "city": "Rosedale",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21239,
      "latitude": 39.356356,
      "longitude": -76.587588,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21240,
      "latitude": 39.142793,
      "longitude": -76.651589,
      "city": "Baltimore",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21241,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21244,
      "latitude": 39.333105,
      "longitude": -76.784896,
      "city": "Windsor Mill",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21250,
      "latitude": 39.258226,
      "longitude": -76.713149,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21251,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21252,
      "latitude": 39.388799,
      "longitude": -76.613325,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21263,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21264,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21265,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21268,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21270,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21273,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21274,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21275,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21278,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21279,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21280,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21281,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21282,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21283,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21284,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21285,
      "latitude": 39.438964,
      "longitude": -76.592139,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21286,
      "latitude": 39.414306,
      "longitude": -76.576089,
      "city": "Towson",
      "state": "MD",
      "county": "Baltimore"
  },
  {
      "zipCode": 21287,
      "latitude": 39.297457,
      "longitude": -76.592738,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21288,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21289,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21290,
      "latitude": 39.293256,
      "longitude": -76.623839,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21297,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21298,
      "latitude": 39.284707,
      "longitude": -76.620489,
      "city": "Baltimore",
      "state": "MD",
      "county": "Baltimore City"
  },
  {
      "zipCode": 21401,
      "latitude": 38.967311,
      "longitude": -76.610143,
      "city": "Annapolis",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21402,
      "latitude": 39.04811,
      "longitude": -76.452032,
      "city": "Annapolis",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21403,
      "latitude": 39.007361,
      "longitude": -76.584637,
      "city": "Annapolis",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21404,
      "latitude": 38.974203,
      "longitude": -76.594942,
      "city": "Annapolis",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21405,
      "latitude": 38.992124,
      "longitude": -76.506883,
      "city": "Annapolis",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21411,
      "latitude": 38.974203,
      "longitude": -76.594942,
      "city": "Annapolis",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21412,
      "latitude": 38.974203,
      "longitude": -76.594942,
      "city": "Annapolis",
      "state": "MD",
      "county": "Anne Arundel"
  },
  {
      "zipCode": 21501,
      "latitude": 39.580691,
      "longitude": -78.690593,
      "city": "Cumberland",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21502,
      "latitude": 39.59915,
      "longitude": -78.844425,
      "city": "Cumberland",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21503,
      "latitude": 39.580691,
      "longitude": -78.690593,
      "city": "Cumberland",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21504,
      "latitude": 39.580691,
      "longitude": -78.690593,
      "city": "Cumberland",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21505,
      "latitude": 39.594043,
      "longitude": -78.843432,
      "city": "Cumberland",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21520,
      "latitude": 39.58935,
      "longitude": -79.230969,
      "city": "Accident",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21521,
      "latitude": 39.536798,
      "longitude": -79.009008,
      "city": "Barton",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21522,
      "latitude": 39.601664,
      "longitude": -79.233691,
      "city": "Bittinger",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21523,
      "latitude": 39.486109,
      "longitude": -79.08297,
      "city": "Bloomington",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21524,
      "latitude": 39.69668,
      "longitude": -78.803067,
      "city": "Corriganville",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21528,
      "latitude": 39.652837,
      "longitude": -78.901395,
      "city": "Eckhart Mines",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21529,
      "latitude": 39.708261,
      "longitude": -78.777446,
      "city": "Ellerslie",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21530,
      "latitude": 39.679418,
      "longitude": -78.524164,
      "city": "Flintstone",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21531,
      "latitude": 39.637098,
      "longitude": -79.257117,
      "city": "Friendsville",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21532,
      "latitude": 39.64206,
      "longitude": -78.912502,
      "city": "Frostburg",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21536,
      "latitude": 39.655141,
      "longitude": -79.124143,
      "city": "Grantsville",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21538,
      "latitude": 39.416917,
      "longitude": -79.222202,
      "city": "Kitzmiller",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21539,
      "latitude": 39.571706,
      "longitude": -78.934521,
      "city": "Lonaconing",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21540,
      "latitude": 39.475381,
      "longitude": -79.058451,
      "city": "Luke",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21541,
      "latitude": 39.565589,
      "longitude": -79.382332,
      "city": "Mc Henry",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21542,
      "latitude": 39.595113,
      "longitude": -78.943082,
      "city": "Midland",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21543,
      "latitude": 39.633866,
      "longitude": -78.960403,
      "city": "Midlothian",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21545,
      "latitude": 39.695649,
      "longitude": -78.872465,
      "city": "Mount Savage",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21550,
      "latitude": 39.43392,
      "longitude": -79.316683,
      "city": "Oakland",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21555,
      "latitude": 39.596645,
      "longitude": -78.572564,
      "city": "Oldtown",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21556,
      "latitude": 39.572452,
      "longitude": -78.843962,
      "city": "Pinto",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21557,
      "latitude": 39.50582,
      "longitude": -78.921162,
      "city": "Rawlings",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21560,
      "latitude": 39.601008,
      "longitude": -78.687137,
      "city": "Spring Gap",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21561,
      "latitude": 39.538568,
      "longitude": -79.190791,
      "city": "Swanton",
      "state": "MD",
      "county": "Garrett"
  },
  {
      "zipCode": 21562,
      "latitude": 39.490547,
      "longitude": -79.013989,
      "city": "Westernport",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21601,
      "latitude": 38.800714,
      "longitude": -76.051486,
      "city": "Easton",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21606,
      "latitude": 38.758525,
      "longitude": -76.180166,
      "city": "Easton",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21607,
      "latitude": 39.073391,
      "longitude": -76.060665,
      "city": "Barclay",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21609,
      "latitude": 38.740562,
      "longitude": -75.958683,
      "city": "Bethlehem",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21610,
      "latitude": 39.366812,
      "longitude": -76.067079,
      "city": "Betterton",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21612,
      "latitude": 38.74955,
      "longitude": -76.273704,
      "city": "Bozman",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21613,
      "latitude": 38.479813,
      "longitude": -76.079864,
      "city": "Cambridge",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21617,
      "latitude": 39.084003,
      "longitude": -76.091375,
      "city": "Centreville",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21619,
      "latitude": 38.946034,
      "longitude": -76.279172,
      "city": "Chester",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21620,
      "latitude": 39.235988,
      "longitude": -76.059316,
      "city": "Chestertown",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21622,
      "latitude": 38.415211,
      "longitude": -76.191021,
      "city": "Church Creek",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21623,
      "latitude": 39.1305,
      "longitude": -75.961404,
      "city": "Church Hill",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21624,
      "latitude": 38.836766,
      "longitude": -76.27137,
      "city": "Claiborne",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21625,
      "latitude": 38.869275,
      "longitude": -76.000119,
      "city": "Cordova",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21626,
      "latitude": 38.323604,
      "longitude": -76.097337,
      "city": "Crapo",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21627,
      "latitude": 38.242608,
      "longitude": -76.053064,
      "city": "Crocheron",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21628,
      "latitude": 39.231771,
      "longitude": -75.929797,
      "city": "Crumpton",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21629,
      "latitude": 38.859523,
      "longitude": -75.835702,
      "city": "Denton",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21631,
      "latitude": 38.579447,
      "longitude": -75.939691,
      "city": "East New Market",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21632,
      "latitude": 38.823318,
      "longitude": -75.825197,
      "city": "Federalsburg",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21634,
      "latitude": 38.29868,
      "longitude": -76.203532,
      "city": "Fishing Creek",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21635,
      "latitude": 39.320381,
      "longitude": -75.836031,
      "city": "Galena",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21636,
      "latitude": 39.022245,
      "longitude": -75.809462,
      "city": "Goldsboro",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21638,
      "latitude": 39.019049,
      "longitude": -76.115897,
      "city": "Grasonville",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21639,
      "latitude": 38.968592,
      "longitude": -75.790732,
      "city": "Greensboro",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21640,
      "latitude": 39.081351,
      "longitude": -75.811781,
      "city": "Henderson",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21641,
      "latitude": 38.920588,
      "longitude": -75.93877,
      "city": "Hillsboro",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21643,
      "latitude": 38.643824,
      "longitude": -75.863003,
      "city": "Hurlock",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21644,
      "latitude": 39.118226,
      "longitude": -75.876881,
      "city": "Ingleside",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21645,
      "latitude": 39.313679,
      "longitude": -75.96152,
      "city": "Kennedyville",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21647,
      "latitude": 38.808497,
      "longitude": -76.287709,
      "city": "Mcdaniel",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21648,
      "latitude": 38.478205,
      "longitude": -76.241202,
      "city": "Madison",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21649,
      "latitude": 39.106088,
      "longitude": -75.79087,
      "city": "Marydel",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21650,
      "latitude": 39.308228,
      "longitude": -75.812769,
      "city": "Massey",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21651,
      "latitude": 39.274314,
      "longitude": -75.895115,
      "city": "Millington",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21652,
      "latitude": 38.795102,
      "longitude": -76.152762,
      "city": "Neavitt",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21653,
      "latitude": 38.751834,
      "longitude": -76.178041,
      "city": "Newcomb",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21654,
      "latitude": 38.710783,
      "longitude": -76.125184,
      "city": "Oxford",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21655,
      "latitude": 38.752146,
      "longitude": -75.90765,
      "city": "Preston",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21656,
      "latitude": 39.034637,
      "longitude": -76.092127,
      "city": "Price",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21657,
      "latitude": 38.975882,
      "longitude": -75.975322,
      "city": "Queen Anne",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21658,
      "latitude": 39.00255,
      "longitude": -76.142374,
      "city": "Queenstown",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21659,
      "latitude": 38.561727,
      "longitude": -75.80465,
      "city": "Rhodesdale",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21660,
      "latitude": 38.874255,
      "longitude": -75.866707,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21661,
      "latitude": 39.117823,
      "longitude": -76.223193,
      "city": "Rock Hall",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21662,
      "latitude": 38.713532,
      "longitude": -76.196838,
      "city": "Royal Oak",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21663,
      "latitude": 38.748765,
      "longitude": -76.167765,
      "city": "Saint Michaels",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21664,
      "latitude": 38.600971,
      "longitude": -75.947375,
      "city": "Secretary",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21665,
      "latitude": 38.745387,
      "longitude": -76.326606,
      "city": "Sherwood",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21666,
      "latitude": 39.012075,
      "longitude": -76.11568,
      "city": "Stevensville",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21667,
      "latitude": 39.339906,
      "longitude": -76.047396,
      "city": "Still Pond",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21668,
      "latitude": 39.119123,
      "longitude": -76.031257,
      "city": "Sudlersville",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21669,
      "latitude": 38.453204,
      "longitude": -76.2641,
      "city": "Taylors Island",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21670,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Templeville",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21671,
      "latitude": 38.704729,
      "longitude": -76.332647,
      "city": "Tilghman",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21672,
      "latitude": 38.274149,
      "longitude": -76.072171,
      "city": "Toddville",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21673,
      "latitude": 38.664452,
      "longitude": -76.058006,
      "city": "Trappe",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21675,
      "latitude": 38.307394,
      "longitude": -76.106344,
      "city": "Wingate",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21676,
      "latitude": 38.784568,
      "longitude": -76.300134,
      "city": "Wittman",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21677,
      "latitude": 38.49965,
      "longitude": -76.202456,
      "city": "Woolford",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21678,
      "latitude": 39.308842,
      "longitude": -76.08165,
      "city": "Worton",
      "state": "MD",
      "county": "Kent"
  },
  {
      "zipCode": 21679,
      "latitude": 38.91506,
      "longitude": -76.073905,
      "city": "Wye Mills",
      "state": "MD",
      "county": "Talbot"
  },
  {
      "zipCode": 21681,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21682,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21683,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21684,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21685,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21686,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21687,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21688,
      "latitude": 38.88927,
      "longitude": -75.861191,
      "city": "Ridgely",
      "state": "MD",
      "county": "Caroline"
  },
  {
      "zipCode": 21690,
      "latitude": 39.034637,
      "longitude": -76.092127,
      "city": "Chestertown",
      "state": "MD",
      "county": "Queen Annes"
  },
  {
      "zipCode": 21701,
      "latitude": 39.512748,
      "longitude": -77.369299,
      "city": "Frederick",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21702,
      "latitude": 39.492596,
      "longitude": -77.461211,
      "city": "Frederick",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21703,
      "latitude": 39.364713,
      "longitude": -77.463561,
      "city": "Frederick",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21704,
      "latitude": 39.345477,
      "longitude": -77.383229,
      "city": "Frederick",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21705,
      "latitude": 39.469961,
      "longitude": -77.392139,
      "city": "Frederick",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21709,
      "latitude": 39.469961,
      "longitude": -77.392139,
      "city": "Frederick",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21710,
      "latitude": 39.291049,
      "longitude": -77.455243,
      "city": "Adamstown",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21711,
      "latitude": 39.666266,
      "longitude": -77.954912,
      "city": "Big Pool",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21713,
      "latitude": 39.551986,
      "longitude": -77.695721,
      "city": "Boonsboro",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21714,
      "latitude": 39.4203,
      "longitude": -77.505098,
      "city": "Braddock Heights",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21715,
      "latitude": 39.386893,
      "longitude": -77.658015,
      "city": "Brownsville",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21716,
      "latitude": 39.317945,
      "longitude": -77.624701,
      "city": "Brunswick",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21717,
      "latitude": 39.330887,
      "longitude": -77.427405,
      "city": "Buckeystown",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21718,
      "latitude": 39.400868,
      "longitude": -77.627954,
      "city": "Burkittsville",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21719,
      "latitude": 39.695751,
      "longitude": -77.495542,
      "city": "Cascade",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21720,
      "latitude": 39.647296,
      "longitude": -77.584162,
      "city": "Cavetown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21721,
      "latitude": 39.642537,
      "longitude": -77.637152,
      "city": "Chewsville",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21722,
      "latitude": 39.665751,
      "longitude": -77.906389,
      "city": "Clear Spring",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21723,
      "latitude": 39.328104,
      "longitude": -77.0142,
      "city": "Cooksville",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21727,
      "latitude": 39.675397,
      "longitude": -77.336732,
      "city": "Emmitsburg",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21733,
      "latitude": 39.559419,
      "longitude": -77.76039,
      "city": "Fairplay",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21734,
      "latitude": 39.60596,
      "longitude": -77.707207,
      "city": "Funkstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21736,
      "latitude": 39.520696,
      "longitude": -77.916196,
      "city": "Gapland",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21737,
      "latitude": 39.259656,
      "longitude": -77.012699,
      "city": "Glenelg",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21738,
      "latitude": 39.281504,
      "longitude": -77.025362,
      "city": "Glenwood",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21740,
      "latitude": 39.58887,
      "longitude": -77.700213,
      "city": "Hagerstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21741,
      "latitude": 39.693944,
      "longitude": -77.742116,
      "city": "Hagerstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21742,
      "latitude": 39.642995,
      "longitude": -77.652779,
      "city": "Hagerstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21746,
      "latitude": 39.563787,
      "longitude": -77.720642,
      "city": "Hagerstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21747,
      "latitude": 39.520696,
      "longitude": -77.916196,
      "city": "Hagerstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21748,
      "latitude": 39.520696,
      "longitude": -77.916196,
      "city": "Hagerstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21749,
      "latitude": 39.520696,
      "longitude": -77.916196,
      "city": "Hagerstown",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21750,
      "latitude": 39.58895,
      "longitude": -78.050204,
      "city": "Hancock",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21754,
      "latitude": 39.339477,
      "longitude": -77.310622,
      "city": "Ijamsville",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21755,
      "latitude": 39.353688,
      "longitude": -77.567142,
      "city": "Jefferson",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21756,
      "latitude": 39.458488,
      "longitude": -77.693341,
      "city": "Keedysville",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21757,
      "latitude": 39.619399,
      "longitude": -77.252657,
      "city": "Keymar",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21758,
      "latitude": 39.34924,
      "longitude": -77.620514,
      "city": "Knoxville",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21759,
      "latitude": 39.569426,
      "longitude": -77.290519,
      "city": "Ladiesburg",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21762,
      "latitude": 39.482208,
      "longitude": -77.246761,
      "city": "Libertytown",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21764,
      "latitude": 39.534703,
      "longitude": -77.049302,
      "city": "Linwood",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21765,
      "latitude": 39.337804,
      "longitude": -77.071952,
      "city": "Lisbon",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21766,
      "latitude": 39.641946,
      "longitude": -78.408891,
      "city": "Little Orleans",
      "state": "MD",
      "county": "Allegany"
  },
  {
      "zipCode": 21767,
      "latitude": 39.696761,
      "longitude": -77.746766,
      "city": "Maugansville",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21769,
      "latitude": 39.53503,
      "longitude": -77.485034,
      "city": "Middletown",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21770,
      "latitude": 39.351552,
      "longitude": -77.265818,
      "city": "Monrovia",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21771,
      "latitude": 39.407252,
      "longitude": -77.214087,
      "city": "Mount Airy",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21773,
      "latitude": 39.550036,
      "longitude": -77.556275,
      "city": "Myersville",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21774,
      "latitude": 39.432571,
      "longitude": -77.220932,
      "city": "New Market",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21775,
      "latitude": 39.564472,
      "longitude": -77.29468,
      "city": "New Midway",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21776,
      "latitude": 39.521886,
      "longitude": -77.093303,
      "city": "New Windsor",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21777,
      "latitude": 39.27777,
      "longitude": -77.532068,
      "city": "Point Of Rocks",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21778,
      "latitude": 39.618999,
      "longitude": -77.335442,
      "city": "Rocky Ridge",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21779,
      "latitude": 39.430227,
      "longitude": -77.66013,
      "city": "Rohrersville",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21780,
      "latitude": 39.667035,
      "longitude": -77.481888,
      "city": "Sabillasville",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21781,
      "latitude": 39.56991,
      "longitude": -77.760697,
      "city": "Saint James",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21782,
      "latitude": 39.438718,
      "longitude": -77.771667,
      "city": "Sharpsburg",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21783,
      "latitude": 39.591355,
      "longitude": -77.614762,
      "city": "Smithsburg",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21784,
      "latitude": 39.45672,
      "longitude": -76.969601,
      "city": "Sykesville",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21787,
      "latitude": 39.657449,
      "longitude": -77.169055,
      "city": "Taneytown",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21788,
      "latitude": 39.610876,
      "longitude": -77.398931,
      "city": "Thurmont",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21790,
      "latitude": 39.265086,
      "longitude": -77.496646,
      "city": "Tuscarora",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21791,
      "latitude": 39.57985,
      "longitude": -77.131896,
      "city": "Union Bridge",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21792,
      "latitude": 39.469961,
      "longitude": -77.392139,
      "city": "Unionville",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21793,
      "latitude": 39.491247,
      "longitude": -77.343542,
      "city": "Walkersville",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21794,
      "latitude": 39.296204,
      "longitude": -76.98115,
      "city": "West Friendship",
      "state": "MD",
      "county": "Howard"
  },
  {
      "zipCode": 21795,
      "latitude": 39.589123,
      "longitude": -77.826258,
      "city": "Williamsport",
      "state": "MD",
      "county": "Washington"
  },
  {
      "zipCode": 21797,
      "latitude": 39.402352,
      "longitude": -77.081821,
      "city": "Woodbine",
      "state": "MD",
      "county": "Carroll"
  },
  {
      "zipCode": 21798,
      "latitude": 39.542724,
      "longitude": -77.292314,
      "city": "Woodsboro",
      "state": "MD",
      "county": "Frederick"
  },
  {
      "zipCode": 21801,
      "latitude": 38.382445,
      "longitude": -75.633621,
      "city": "Salisbury",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21802,
      "latitude": 38.388434,
      "longitude": -75.6276,
      "city": "Salisbury",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21803,
      "latitude": 38.388434,
      "longitude": -75.6276,
      "city": "Salisbury",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21804,
      "latitude": 38.350793,
      "longitude": -75.533782,
      "city": "Salisbury",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21810,
      "latitude": 38.388434,
      "longitude": -75.6276,
      "city": "Allen",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21811,
      "latitude": 38.310447,
      "longitude": -75.320901,
      "city": "Berlin",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21813,
      "latitude": 38.421121,
      "longitude": -75.19596,
      "city": "Bishopville",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21814,
      "latitude": 38.388434,
      "longitude": -75.6276,
      "city": "Bivalve",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21817,
      "latitude": 37.998308,
      "longitude": -75.818797,
      "city": "Crisfield",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21821,
      "latitude": 38.166101,
      "longitude": -75.92329,
      "city": "Deal Island",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21822,
      "latitude": 38.216972,
      "longitude": -75.55053,
      "city": "Eden",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21824,
      "latitude": 37.983912,
      "longitude": -76.035623,
      "city": "Ewell",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21826,
      "latitude": 38.320318,
      "longitude": -75.620806,
      "city": "Fruitland",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21829,
      "latitude": 38.102963,
      "longitude": -75.381853,
      "city": "Girdletree",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21830,
      "latitude": 38.409235,
      "longitude": -75.724848,
      "city": "Hebron",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21835,
      "latitude": 38.532301,
      "longitude": -75.949958,
      "city": "Linkwood",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21836,
      "latitude": 38.073294,
      "longitude": -75.734412,
      "city": "Manokin",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21837,
      "latitude": 38.458249,
      "longitude": -75.754804,
      "city": "Mardela Springs",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21838,
      "latitude": 38.018983,
      "longitude": -75.728768,
      "city": "Marion Station",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21840,
      "latitude": 38.388434,
      "longitude": -75.6276,
      "city": "Nanticoke",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21841,
      "latitude": 38.246348,
      "longitude": -75.301838,
      "city": "Newark",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21842,
      "latitude": 38.381107,
      "longitude": -75.113816,
      "city": "Ocean City",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21843,
      "latitude": 38.22309,
      "longitude": -75.324057,
      "city": "Ocean City",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21849,
      "latitude": 38.376894,
      "longitude": -75.462009,
      "city": "Parsonsburg",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21850,
      "latitude": 38.394572,
      "longitude": -75.410499,
      "city": "Pittsville",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21851,
      "latitude": 38.089201,
      "longitude": -75.537185,
      "city": "Pocomoke City",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21852,
      "latitude": 38.388434,
      "longitude": -75.6276,
      "city": "Powellville",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21853,
      "latitude": 38.200553,
      "longitude": -75.75292,
      "city": "Princess Anne",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21856,
      "latitude": 38.305282,
      "longitude": -75.763118,
      "city": "Quantico",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21857,
      "latitude": 38.092718,
      "longitude": -75.888207,
      "city": "Rehobeth",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21861,
      "latitude": 38.538904,
      "longitude": -75.719182,
      "city": "Sharptown",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21862,
      "latitude": 38.400306,
      "longitude": -75.216551,
      "city": "Showell",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21863,
      "latitude": 38.217824,
      "longitude": -75.326618,
      "city": "Snow Hill",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21864,
      "latitude": 38.042932,
      "longitude": -75.411789,
      "city": "Stockton",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21865,
      "latitude": 38.268914,
      "longitude": -75.790882,
      "city": "Tyaskin",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21866,
      "latitude": 37.966573,
      "longitude": -76.023482,
      "city": "Tylerton",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21867,
      "latitude": 38.10049,
      "longitude": -75.822318,
      "city": "Upper Fairmount",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21869,
      "latitude": 38.430928,
      "longitude": -75.897058,
      "city": "Vienna",
      "state": "MD",
      "county": "Dorchester"
  },
  {
      "zipCode": 21870,
      "latitude": 38.092718,
      "longitude": -75.888207,
      "city": "Wenona",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21871,
      "latitude": 38.094181,
      "longitude": -75.717406,
      "city": "Westover",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21872,
      "latitude": 38.412085,
      "longitude": -75.281074,
      "city": "Whaleyville",
      "state": "MD",
      "county": "Worcester"
  },
  {
      "zipCode": 21874,
      "latitude": 38.40406,
      "longitude": -75.351349,
      "city": "Willards",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21875,
      "latitude": 38.452527,
      "longitude": -75.574165,
      "city": "Delmar",
      "state": "MD",
      "county": "Wicomico"
  },
  {
      "zipCode": 21890,
      "latitude": 38.092718,
      "longitude": -75.888207,
      "city": "Westover",
      "state": "MD",
      "county": "Somerset"
  },
  {
      "zipCode": 21901,
      "latitude": 39.535078,
      "longitude": -75.894051,
      "city": "North East",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21902,
      "latitude": 39.552962,
      "longitude": -76.072471,
      "city": "Perry Point",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21903,
      "latitude": 39.571449,
      "longitude": -76.033281,
      "city": "Perryville",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21904,
      "latitude": 39.615086,
      "longitude": -76.063342,
      "city": "Port Deposit",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21911,
      "latitude": 39.671572,
      "longitude": -76.056651,
      "city": "Rising Sun",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21912,
      "latitude": 39.533058,
      "longitude": -75.915203,
      "city": "Warwick",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21913,
      "latitude": 39.410355,
      "longitude": -75.863287,
      "city": "Cecilton",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21914,
      "latitude": 39.574387,
      "longitude": -75.978564,
      "city": "Charlestown",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21915,
      "latitude": 39.469098,
      "longitude": -75.849327,
      "city": "Chesapeake City",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21916,
      "latitude": 39.541512,
      "longitude": -75.999972,
      "city": "Childs",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21917,
      "latitude": 39.537261,
      "longitude": -76.003452,
      "city": "Colora",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21918,
      "latitude": 39.543894,
      "longitude": -76.049157,
      "city": "Conowingo",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21919,
      "latitude": 39.470309,
      "longitude": -75.927952,
      "city": "Earleville",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21920,
      "latitude": 39.658031,
      "longitude": -75.828196,
      "city": "Elk Mills",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21921,
      "latitude": 39.56186,
      "longitude": -75.87574,
      "city": "Elkton",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21922,
      "latitude": 39.593612,
      "longitude": -75.947332,
      "city": "Elkton",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 21930,
      "latitude": 39.366183,
      "longitude": -75.884544,
      "city": "Georgetown",
      "state": "MD",
      "county": "Cecil"
  },
  {
      "zipCode": 22002,
      "latitude": 38.691448,
      "longitude": -78.137019,
      "city": "Amissville",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22003,
      "latitude": 38.835762,
      "longitude": -77.212794,
      "city": "Annandale",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22009,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Burke",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22015,
      "latitude": 38.785864,
      "longitude": -77.286156,
      "city": "Burke",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22026,
      "latitude": 38.584734,
      "longitude": -77.349935,
      "city": "Dumfries",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22027,
      "latitude": 38.895078,
      "longitude": -77.221453,
      "city": "Dunn Loring",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22030,
      "latitude": 38.853231,
      "longitude": -77.305097,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax City"
  },
  {
      "zipCode": 22031,
      "latitude": 38.853962,
      "longitude": -77.284195,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax City"
  },
  {
      "zipCode": 22032,
      "latitude": 38.819712,
      "longitude": -77.287105,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22033,
      "latitude": 38.879466,
      "longitude": -77.378858,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22034,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22035,
      "latitude": 38.855715,
      "longitude": -77.361633,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22036,
      "latitude": 38.735114,
      "longitude": -77.07965,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22037,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22038,
      "latitude": 38.852849,
      "longitude": -77.30199,
      "city": "Fairfax",
      "state": "VA",
      "county": "Fairfax City"
  },
  {
      "zipCode": 22039,
      "latitude": 38.759329,
      "longitude": -77.311588,
      "city": "Fairfax Station",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22040,
      "latitude": 38.884177,
      "longitude": -77.171752,
      "city": "Falls Church",
      "state": "VA",
      "county": "Falls Church City"
  },
  {
      "zipCode": 22041,
      "latitude": 38.850212,
      "longitude": -77.144801,
      "city": "Falls Church",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22042,
      "latitude": 38.863452,
      "longitude": -77.193915,
      "city": "Falls Church",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22043,
      "latitude": 38.899361,
      "longitude": -77.189502,
      "city": "Falls Church",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22044,
      "latitude": 38.858853,
      "longitude": -77.154761,
      "city": "Falls Church",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22046,
      "latitude": 38.884936,
      "longitude": -77.174052,
      "city": "Falls Church",
      "state": "VA",
      "county": "Falls Church City"
  },
  {
      "zipCode": 22047,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Falls Church",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22060,
      "latitude": 38.714562,
      "longitude": -77.167886,
      "city": "Fort Belvoir",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22066,
      "latitude": 39.001609,
      "longitude": -77.306416,
      "city": "Great Falls",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22067,
      "latitude": 38.96451,
      "longitude": -77.233103,
      "city": "Greenway",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22079,
      "latitude": 38.692865,
      "longitude": -77.204031,
      "city": "Lorton",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22081,
      "latitude": 38.873861,
      "longitude": -77.234454,
      "city": "Merrifield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22082,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Merrifield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22092,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Herndon",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22093,
      "latitude": 39.085309,
      "longitude": -77.645224,
      "city": "Ashburn",
      "state": "VA",
      "county": "Loudoun"
  },
  {
      "zipCode": 22095,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Herndon",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22096,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Reston",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22101,
      "latitude": 38.935202,
      "longitude": -77.18672,
      "city": "Mc Lean",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22102,
      "latitude": 38.953033,
      "longitude": -77.229496,
      "city": "Mc Lean",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22103,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "West Mclean",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22106,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Mc Lean",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22107,
      "latitude": 38.931453,
      "longitude": -77.218448,
      "city": "Mc Lean",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22108,
      "latitude": 38.931453,
      "longitude": -77.218448,
      "city": "Mc Lean",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22109,
      "latitude": 38.920165,
      "longitude": -77.229043,
      "city": "Mc Lean",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22116,
      "latitude": 38.871469,
      "longitude": -77.234428,
      "city": "Merrifield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22118,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Merrifield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22119,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Merrifield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22120,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Merrifield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22121,
      "latitude": 38.830912,
      "longitude": -77.432252,
      "city": "Mount Vernon",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22122,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Newington",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22124,
      "latitude": 38.892796,
      "longitude": -77.329906,
      "city": "Oakton",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22125,
      "latitude": 38.681615,
      "longitude": -77.26052,
      "city": "Occoquan",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22134,
      "latitude": 38.52602,
      "longitude": -77.322803,
      "city": "Quantico",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22135,
      "latitude": 38.721912,
      "longitude": -77.466897,
      "city": "Quantico",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22150,
      "latitude": 38.763463,
      "longitude": -77.186063,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22151,
      "latitude": 38.802858,
      "longitude": -77.211556,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22152,
      "latitude": 38.775687,
      "longitude": -77.233746,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22153,
      "latitude": 38.757015,
      "longitude": -77.181612,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22156,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22158,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22159,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22160,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22161,
      "latitude": 38.807462,
      "longitude": -77.219354,
      "city": "Springfield",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22172,
      "latitude": 38.581369,
      "longitude": -77.367205,
      "city": "Triangle",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22180,
      "latitude": 38.897361,
      "longitude": -77.253749,
      "city": "Vienna",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22181,
      "latitude": 38.90521,
      "longitude": -77.295256,
      "city": "Vienna",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22182,
      "latitude": 38.935292,
      "longitude": -77.268022,
      "city": "Vienna",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22183,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Vienna",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22184,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Vienna",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22185,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Vienna",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22191,
      "latitude": 38.625541,
      "longitude": -77.271644,
      "city": "Woodbridge",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22192,
      "latitude": 38.675952,
      "longitude": -77.316344,
      "city": "Woodbridge",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22193,
      "latitude": 38.643765,
      "longitude": -77.345106,
      "city": "Woodbridge",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22194,
      "latitude": 38.721912,
      "longitude": -77.466897,
      "city": "Woodbridge",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22195,
      "latitude": 38.721912,
      "longitude": -77.466897,
      "city": "Woodbridge",
      "state": "VA",
      "county": "Prince William"
  },
  {
      "zipCode": 22199,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Lorton",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22201,
      "latitude": 38.885484,
      "longitude": -77.099113,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22202,
      "latitude": 38.854462,
      "longitude": -77.059721,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22203,
      "latitude": 38.874775,
      "longitude": -77.119515,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22204,
      "latitude": 38.860335,
      "longitude": -77.100868,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22205,
      "latitude": 38.875811,
      "longitude": -77.140021,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22206,
      "latitude": 38.840403,
      "longitude": -77.0888,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22207,
      "latitude": 38.907416,
      "longitude": -77.122344,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22209,
      "latitude": 38.893596,
      "longitude": -77.110376,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22210,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22211,
      "latitude": 38.878674,
      "longitude": -77.077426,
      "city": "Ft Myer",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22212,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22213,
      "latitude": 38.894898,
      "longitude": -77.162829,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22214,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22215,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22216,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22217,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22218,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22219,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22222,
      "latitude": 38.861462,
      "longitude": -77.053599,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22223,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22225,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22226,
      "latitude": 38.883358,
      "longitude": -77.10281,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22227,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22229,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22230,
      "latitude": 38.87972,
      "longitude": -77.110817,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22234,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22240,
      "latitude": 38.856623,
      "longitude": -77.051788,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22241,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22242,
      "latitude": 38.850879,
      "longitude": -77.052336,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22243,
      "latitude": 38.86045,
      "longitude": -77.051569,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22244,
      "latitude": 38.854522,
      "longitude": -77.052035,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22245,
      "latitude": 38.851773,
      "longitude": -77.052296,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22246,
      "latitude": 38.880811,
      "longitude": -77.11295,
      "city": "Arlington",
      "state": "VA",
      "county": "Arlington"
  },
  {
      "zipCode": 22301,
      "latitude": 38.823062,
      "longitude": -77.079622,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22302,
      "latitude": 38.827627,
      "longitude": -77.089566,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22303,
      "latitude": 38.791231,
      "longitude": -77.08135,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22304,
      "latitude": 38.81522,
      "longitude": -77.10993,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22305,
      "latitude": 38.835412,
      "longitude": -77.063959,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22306,
      "latitude": 38.758914,
      "longitude": -77.087344,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22307,
      "latitude": 38.771363,
      "longitude": -77.065699,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22308,
      "latitude": 38.731564,
      "longitude": -77.060399,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22309,
      "latitude": 38.722264,
      "longitude": -77.171945,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22310,
      "latitude": 38.779391,
      "longitude": -77.119401,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22311,
      "latitude": 38.832762,
      "longitude": -77.119627,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22312,
      "latitude": 38.799956,
      "longitude": -77.150177,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22313,
      "latitude": 38.815762,
      "longitude": -77.09005,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22314,
      "latitude": 38.811436,
      "longitude": -77.063587,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22315,
      "latitude": 38.759553,
      "longitude": -77.148518,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22320,
      "latitude": 38.804413,
      "longitude": -77.046699,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22321,
      "latitude": 38.831813,
      "longitude": -77.288755,
      "city": "Alexandria",
      "state": "VA",
      "county": "Fairfax"
  },
  {
      "zipCode": 22331,
      "latitude": 38.801342,
      "longitude": -77.070733,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22332,
      "latitude": 38.803142,
      "longitude": -77.072679,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22333,
      "latitude": 38.815762,
      "longitude": -77.09005,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22334,
      "latitude": 38.815762,
      "longitude": -77.09005,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22336,
      "latitude": 38.815762,
      "longitude": -77.09005,
      "city": "Alexandria",
      "state": "VA",
      "county": "Alexandria City"
  },
  {
      "zipCode": 22401,
      "latitude": 38.295135,
      "longitude": -77.483689,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Fredericksburg City"
  },
  {
      "zipCode": 22402,
      "latitude": 38.299613,
      "longitude": -77.489666,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Fredericksburg City"
  },
  {
      "zipCode": 22403,
      "latitude": 38.417273,
      "longitude": -77.460814,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22404,
      "latitude": 38.298305,
      "longitude": -77.489913,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Fredericksburg City"
  },
  {
      "zipCode": 22405,
      "latitude": 38.336458,
      "longitude": -77.436618,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22406,
      "latitude": 38.414357,
      "longitude": -77.534708,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22407,
      "latitude": 38.271969,
      "longitude": -77.606772,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Spotsylvania"
  },
  {
      "zipCode": 22408,
      "latitude": 38.233933,
      "longitude": -77.562773,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Spotsylvania"
  },
  {
      "zipCode": 22412,
      "latitude": 38.184716,
      "longitude": -77.662559,
      "city": "Fredericksburg",
      "state": "VA",
      "county": "Spotsylvania"
  },
  {
      "zipCode": 22427,
      "latitude": 38.008401,
      "longitude": -77.260353,
      "city": "Bowling Green",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22428,
      "latitude": 38.014536,
      "longitude": -77.355902,
      "city": "Bowling Green",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22430,
      "latitude": 38.385704,
      "longitude": -77.374292,
      "city": "Brooke",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22432,
      "latitude": 37.837435,
      "longitude": -76.345493,
      "city": "Burgess",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22433,
      "latitude": 38.344608,
      "longitude": -77.871855,
      "city": "Burr Hill",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22435,
      "latitude": 37.961716,
      "longitude": -76.577422,
      "city": "Callao",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22436,
      "latitude": 38.024704,
      "longitude": -77.037147,
      "city": "Caret",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22437,
      "latitude": 37.818619,
      "longitude": -76.786335,
      "city": "Center Cross",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22438,
      "latitude": 38.056246,
      "longitude": -77.018147,
      "city": "Champlain",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22442,
      "latitude": 38.121793,
      "longitude": -76.79025,
      "city": "Coles Point",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22443,
      "latitude": 38.184926,
      "longitude": -76.823742,
      "city": "Colonial Beach",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22446,
      "latitude": 38.171755,
      "longitude": -77.408163,
      "city": "Corbin",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22448,
      "latitude": 38.326218,
      "longitude": -77.039825,
      "city": "Dahlgren",
      "state": "VA",
      "county": "King George"
  },
  {
      "zipCode": 22451,
      "latitude": 38.271077,
      "longitude": -77.17261,
      "city": "Dogue",
      "state": "VA",
      "county": "King George"
  },
  {
      "zipCode": 22454,
      "latitude": 37.852669,
      "longitude": -76.847543,
      "city": "Dunnsville",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22456,
      "latitude": 37.852316,
      "longitude": -76.40511,
      "city": "Edwardsville",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22460,
      "latitude": 37.879797,
      "longitude": -76.598782,
      "city": "Farnham",
      "state": "VA",
      "county": "Richmond"
  },
  {
      "zipCode": 22463,
      "latitude": 38.468432,
      "longitude": -77.461213,
      "city": "Garrisonville",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22469,
      "latitude": 38.059488,
      "longitude": -76.676452,
      "city": "Hague",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22471,
      "latitude": 38.39933,
      "longitude": -77.581351,
      "city": "Hartwood",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22472,
      "latitude": 37.950511,
      "longitude": -76.636722,
      "city": "Haynesville",
      "state": "VA",
      "county": "Richmond"
  },
  {
      "zipCode": 22473,
      "latitude": 37.876733,
      "longitude": -76.427334,
      "city": "Heathsville",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22476,
      "latitude": 38.032905,
      "longitude": -77.06328,
      "city": "Hustle",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22480,
      "latitude": 37.667319,
      "longitude": -76.404996,
      "city": "Irvington",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22481,
      "latitude": 38.271077,
      "longitude": -77.17261,
      "city": "Jersey",
      "state": "VA",
      "county": "King George"
  },
  {
      "zipCode": 22482,
      "latitude": 37.715738,
      "longitude": -76.450212,
      "city": "Kilmarnock",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22485,
      "latitude": 38.277209,
      "longitude": -77.17225,
      "city": "King George",
      "state": "VA",
      "county": "King George"
  },
  {
      "zipCode": 22488,
      "latitude": 38.102075,
      "longitude": -76.743458,
      "city": "Kinsale",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22501,
      "latitude": 38.015899,
      "longitude": -77.559298,
      "city": "Ladysmith",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22503,
      "latitude": 37.739451,
      "longitude": -76.50021,
      "city": "Lancaster",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22504,
      "latitude": 37.76914,
      "longitude": -76.712135,
      "city": "Laneview",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22507,
      "latitude": 37.757226,
      "longitude": -76.511804,
      "city": "Lively",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22508,
      "latitude": 38.308398,
      "longitude": -77.796156,
      "city": "Locust Grove",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22509,
      "latitude": 38.078932,
      "longitude": -77.04851,
      "city": "Loretto",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22511,
      "latitude": 37.985615,
      "longitude": -76.499878,
      "city": "Lottsburg",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22513,
      "latitude": 37.750261,
      "longitude": -76.509615,
      "city": "Merry Point",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22514,
      "latitude": 37.980787,
      "longitude": -77.242558,
      "city": "Milford",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22517,
      "latitude": 37.75028,
      "longitude": -76.578109,
      "city": "Mollusk",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22520,
      "latitude": 38.111955,
      "longitude": -76.82434,
      "city": "Montross",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22523,
      "latitude": 37.792761,
      "longitude": -76.60933,
      "city": "Morattico",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22524,
      "latitude": 38.118186,
      "longitude": -76.680504,
      "city": "Mount Holly",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22526,
      "latitude": 38.271077,
      "longitude": -77.17261,
      "city": "Ninde",
      "state": "VA",
      "county": "King George"
  },
  {
      "zipCode": 22528,
      "latitude": 37.713175,
      "longitude": -76.409946,
      "city": "Nuttsville",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22529,
      "latitude": 38.018054,
      "longitude": -76.68611,
      "city": "Oldhams",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22530,
      "latitude": 37.90938,
      "longitude": -76.293436,
      "city": "Ophelia",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22534,
      "latitude": 38.08462,
      "longitude": -77.666948,
      "city": "Partlow",
      "state": "VA",
      "county": "Spotsylvania"
  },
  {
      "zipCode": 22535,
      "latitude": 38.157349,
      "longitude": -77.180478,
      "city": "Port Royal",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22538,
      "latitude": 38.207873,
      "longitude": -77.2502,
      "city": "Rappahannock Academy",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22539,
      "latitude": 37.855567,
      "longitude": -76.312376,
      "city": "Reedville",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22542,
      "latitude": 38.292189,
      "longitude": -77.897386,
      "city": "Rhoadesville",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22544,
      "latitude": 38.271077,
      "longitude": -77.17261,
      "city": "Rollins Fork",
      "state": "VA",
      "county": "King George"
  },
  {
      "zipCode": 22545,
      "latitude": 38.508611,
      "longitude": -77.542957,
      "city": "Ruby",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22546,
      "latitude": 37.948572,
      "longitude": -77.437767,
      "city": "Ruther Glen",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22547,
      "latitude": 38.271077,
      "longitude": -77.17261,
      "city": "Sealston",
      "state": "VA",
      "county": "King George"
  },
  {
      "zipCode": 22548,
      "latitude": 37.837583,
      "longitude": -76.694775,
      "city": "Sharps",
      "state": "VA",
      "county": "Richmond"
  },
  {
      "zipCode": 22552,
      "latitude": 38.009438,
      "longitude": -77.225139,
      "city": "Sparta",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22553,
      "latitude": 38.182869,
      "longitude": -77.69932,
      "city": "Spotsylvania",
      "state": "VA",
      "county": "Spotsylvania"
  },
  {
      "zipCode": 22554,
      "latitude": 38.438958,
      "longitude": -77.4354,
      "city": "Stafford",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22555,
      "latitude": 38.417273,
      "longitude": -77.460814,
      "city": "Stafford",
      "state": "VA",
      "county": "Stafford"
  },
  {
      "zipCode": 22558,
      "latitude": 38.121793,
      "longitude": -76.79025,
      "city": "Stratford",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22560,
      "latitude": 37.916613,
      "longitude": -76.947547,
      "city": "Tappahannock",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 22565,
      "latitude": 38.137216,
      "longitude": -77.518865,
      "city": "Thornburg",
      "state": "VA",
      "county": "Spotsylvania"
  },
  {
      "zipCode": 22567,
      "latitude": 38.242901,
      "longitude": -77.917753,
      "city": "Unionville",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22570,
      "latitude": 37.947224,
      "longitude": -76.615358,
      "city": "Village",
      "state": "VA",
      "county": "Richmond"
  },
  {
      "zipCode": 22572,
      "latitude": 37.96954,
      "longitude": -76.766517,
      "city": "Warsaw",
      "state": "VA",
      "county": "Richmond"
  },
  {
      "zipCode": 22576,
      "latitude": 37.688877,
      "longitude": -76.431553,
      "city": "Weems",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22577,
      "latitude": 38.067325,
      "longitude": -76.550274,
      "city": "Sandy Point",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22578,
      "latitude": 37.688601,
      "longitude": -76.361585,
      "city": "White Stone",
      "state": "VA",
      "county": "Lancaster"
  },
  {
      "zipCode": 22579,
      "latitude": 37.802527,
      "longitude": -76.363603,
      "city": "Wicomico Church",
      "state": "VA",
      "county": "Northumberland"
  },
  {
      "zipCode": 22580,
      "latitude": 38.130103,
      "longitude": -77.417234,
      "city": "Woodford",
      "state": "VA",
      "county": "Caroline"
  },
  {
      "zipCode": 22581,
      "latitude": 38.121793,
      "longitude": -76.79025,
      "city": "Zacata",
      "state": "VA",
      "county": "Westmoreland"
  },
  {
      "zipCode": 22601,
      "latitude": 39.169668,
      "longitude": -78.16856,
      "city": "Winchester",
      "state": "VA",
      "county": "Winchester City"
  },
  {
      "zipCode": 22602,
      "latitude": 39.150074,
      "longitude": -78.268988,
      "city": "Winchester",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22603,
      "latitude": 39.263975,
      "longitude": -78.198932,
      "city": "Winchester",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22604,
      "latitude": 39.167603,
      "longitude": -78.168643,
      "city": "Winchester",
      "state": "VA",
      "county": "Winchester City"
  },
  {
      "zipCode": 22610,
      "latitude": 38.818692,
      "longitude": -78.275719,
      "city": "Bentonville",
      "state": "VA",
      "county": "Warren"
  },
  {
      "zipCode": 22611,
      "latitude": 39.135711,
      "longitude": -77.991926,
      "city": "Berryville",
      "state": "VA",
      "county": "Clarke"
  },
  {
      "zipCode": 22620,
      "latitude": 39.048614,
      "longitude": -78.060132,
      "city": "Boyce",
      "state": "VA",
      "county": "Clarke"
  },
  {
      "zipCode": 22622,
      "latitude": 39.2543,
      "longitude": -78.066373,
      "city": "Brucetown",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22623,
      "latitude": 38.853733,
      "longitude": -78.141239,
      "city": "Chester Gap",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22624,
      "latitude": 39.271865,
      "longitude": -78.099763,
      "city": "Clear Brook",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22625,
      "latitude": 39.296066,
      "longitude": -78.310539,
      "city": "Cross Junction",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22626,
      "latitude": 38.983383,
      "longitude": -78.403766,
      "city": "Fishers Hill",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22627,
      "latitude": 38.787434,
      "longitude": -78.083739,
      "city": "Flint Hill",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22630,
      "latitude": 38.928308,
      "longitude": -78.174537,
      "city": "Front Royal",
      "state": "VA",
      "county": "Warren"
  },
  {
      "zipCode": 22637,
      "latitude": 39.253982,
      "longitude": -78.352754,
      "city": "Gore",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22638,
      "latitude": 39.236944,
      "longitude": -78.288465,
      "city": "Winchester",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22639,
      "latitude": 38.830067,
      "longitude": -78.022322,
      "city": "Hume",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22640,
      "latitude": 38.812892,
      "longitude": -78.116528,
      "city": "Huntly",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22641,
      "latitude": 39.056218,
      "longitude": -78.37477,
      "city": "Strasburg",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22642,
      "latitude": 38.932418,
      "longitude": -78.065023,
      "city": "Linden",
      "state": "VA",
      "county": "Warren"
  },
  {
      "zipCode": 22643,
      "latitude": 38.895454,
      "longitude": -78.004435,
      "city": "Markham",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22644,
      "latitude": 38.963523,
      "longitude": -78.548229,
      "city": "Maurertown",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22645,
      "latitude": 39.048863,
      "longitude": -78.285171,
      "city": "Middletown",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22646,
      "latitude": 39.121941,
      "longitude": -77.990069,
      "city": "Millwood",
      "state": "VA",
      "county": "Clarke"
  },
  {
      "zipCode": 22649,
      "latitude": 39.004774,
      "longitude": -78.247806,
      "city": "Middletown",
      "state": "VA",
      "county": "Warren"
  },
  {
      "zipCode": 22650,
      "latitude": 38.763022,
      "longitude": -78.387191,
      "city": "Rileyville",
      "state": "VA",
      "county": "Page"
  },
  {
      "zipCode": 22652,
      "latitude": 38.840715,
      "longitude": -78.427584,
      "city": "Fort Valley",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22654,
      "latitude": 39.078491,
      "longitude": -78.443076,
      "city": "Star Tannery",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22655,
      "latitude": 39.163418,
      "longitude": -78.246159,
      "city": "Stephens City",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22656,
      "latitude": 39.213701,
      "longitude": -78.090144,
      "city": "Stephenson",
      "state": "VA",
      "county": "Frederick"
  },
  {
      "zipCode": 22657,
      "latitude": 39.020716,
      "longitude": -78.41733,
      "city": "Strasburg",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22660,
      "latitude": 38.94761,
      "longitude": -78.433111,
      "city": "Toms Brook",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22663,
      "latitude": 39.121941,
      "longitude": -77.990069,
      "city": "White Post",
      "state": "VA",
      "county": "Clarke"
  },
  {
      "zipCode": 22664,
      "latitude": 38.918791,
      "longitude": -78.512429,
      "city": "Woodstock",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22701,
      "latitude": 38.511694,
      "longitude": -77.9928,
      "city": "Culpeper",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22709,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Aroda",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22711,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Banco",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22712,
      "latitude": 38.545289,
      "longitude": -77.756064,
      "city": "Bealeton",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22713,
      "latitude": 38.537138,
      "longitude": -78.132778,
      "city": "Boston",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22714,
      "latitude": 38.511323,
      "longitude": -77.901265,
      "city": "Brandy Station",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22715,
      "latitude": 38.418207,
      "longitude": -78.189878,
      "city": "Brightwood",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22716,
      "latitude": 38.624123,
      "longitude": -78.102389,
      "city": "Castleton",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22718,
      "latitude": 38.457473,
      "longitude": -77.804343,
      "city": "Elkwood",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22719,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Etlan",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22720,
      "latitude": 38.472457,
      "longitude": -77.642308,
      "city": "Goldvein",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22721,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Graves Mill",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22722,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Haywood",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22723,
      "latitude": 38.396709,
      "longitude": -78.432237,
      "city": "Hood",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22724,
      "latitude": 38.622436,
      "longitude": -77.907403,
      "city": "Jeffersonton",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22725,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Leon",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22726,
      "latitude": 38.404605,
      "longitude": -77.770885,
      "city": "Lignum",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22727,
      "latitude": 38.369952,
      "longitude": -78.297605,
      "city": "Madison",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22728,
      "latitude": 38.602592,
      "longitude": -77.681065,
      "city": "Midland",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22729,
      "latitude": 38.368552,
      "longitude": -78.011837,
      "city": "Mitchells",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22730,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Oakpark",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22731,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Pratts",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22732,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Radiant",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22733,
      "latitude": 38.359195,
      "longitude": -78.060292,
      "city": "Rapidan",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22734,
      "latitude": 38.530877,
      "longitude": -77.803719,
      "city": "Remington",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22735,
      "latitude": 38.486703,
      "longitude": -78.150059,
      "city": "Reva",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22736,
      "latitude": 38.389874,
      "longitude": -77.711884,
      "city": "Richardsville",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22737,
      "latitude": 38.588388,
      "longitude": -78.002489,
      "city": "Rixeyville",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22738,
      "latitude": 38.3215,
      "longitude": -78.304169,
      "city": "Rochelle",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22739,
      "latitude": 38.505945,
      "longitude": -77.59557,
      "city": "Somerville",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22740,
      "latitude": 38.612785,
      "longitude": -78.227578,
      "city": "Sperryville",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22741,
      "latitude": 38.434991,
      "longitude": -77.860642,
      "city": "Stevensburg",
      "state": "VA",
      "county": "Culpeper"
  },
  {
      "zipCode": 22742,
      "latitude": 38.462651,
      "longitude": -77.706223,
      "city": "Sumerduck",
      "state": "VA",
      "county": "Fauquier"
  },
  {
      "zipCode": 22743,
      "latitude": 38.495692,
      "longitude": -78.331826,
      "city": "Syria",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22746,
      "latitude": 38.647841,
      "longitude": -78.029974,
      "city": "Viewtown",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22747,
      "latitude": 38.705616,
      "longitude": -78.174894,
      "city": "Washington",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22748,
      "latitude": 38.372652,
      "longitude": -78.380509,
      "city": "Wolftown",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22749,
      "latitude": 38.620549,
      "longitude": -78.191528,
      "city": "Woodville",
      "state": "VA",
      "county": "Rappahannock"
  },
  {
      "zipCode": 22801,
      "latitude": 38.422777,
      "longitude": -78.877137,
      "city": "Harrisonburg",
      "state": "VA",
      "county": "Harrisonburg City"
  },
  {
      "zipCode": 22802,
      "latitude": 38.490526,
      "longitude": -78.817876,
      "city": "Harrisonburg",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22803,
      "latitude": 38.45226,
      "longitude": -78.865952,
      "city": "Harrisonburg",
      "state": "VA",
      "county": "Harrisonburg City"
  },
  {
      "zipCode": 22807,
      "latitude": 38.440898,
      "longitude": -78.874173,
      "city": "Harrisonburg",
      "state": "VA",
      "county": "Harrisonburg City"
  },
  {
      "zipCode": 22810,
      "latitude": 38.808869,
      "longitude": -78.777598,
      "city": "Basye",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22811,
      "latitude": 38.781688,
      "longitude": -78.948643,
      "city": "Bergton",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22812,
      "latitude": 38.345632,
      "longitude": -78.854597,
      "city": "Bridgewater",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22815,
      "latitude": 38.619698,
      "longitude": -78.815219,
      "city": "Broadway",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22820,
      "latitude": 38.749089,
      "longitude": -79.000426,
      "city": "Criders",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22821,
      "latitude": 38.417919,
      "longitude": -79.044186,
      "city": "Dayton",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22824,
      "latitude": 38.818191,
      "longitude": -78.637768,
      "city": "Edinburg",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22827,
      "latitude": 38.4009,
      "longitude": -78.729679,
      "city": "Elkton",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22830,
      "latitude": 38.640083,
      "longitude": -78.940553,
      "city": "Fulks Run",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22831,
      "latitude": 38.534126,
      "longitude": -78.999618,
      "city": "Hinton",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22832,
      "latitude": 38.465855,
      "longitude": -78.749875,
      "city": "Keezletown",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22833,
      "latitude": 38.528361,
      "longitude": -78.855172,
      "city": "Lacey Spring",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22834,
      "latitude": 38.550848,
      "longitude": -78.876177,
      "city": "Linville",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22835,
      "latitude": 38.645981,
      "longitude": -78.481288,
      "city": "Luray",
      "state": "VA",
      "county": "Page"
  },
  {
      "zipCode": 22840,
      "latitude": 38.400968,
      "longitude": -78.734456,
      "city": "Mc Gaheysville",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22841,
      "latitude": 38.347167,
      "longitude": -78.838654,
      "city": "Mount Crawford",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22842,
      "latitude": 38.785693,
      "longitude": -78.680304,
      "city": "Mount Jackson",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22843,
      "latitude": 38.325455,
      "longitude": -79.094228,
      "city": "Mount Solon",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 22844,
      "latitude": 38.673777,
      "longitude": -78.677685,
      "city": "New Market",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22845,
      "latitude": 38.799978,
      "longitude": -78.81029,
      "city": "Orkney Springs",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22846,
      "latitude": 38.375012,
      "longitude": -78.77954,
      "city": "Penn Laird",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22847,
      "latitude": 38.761208,
      "longitude": -78.712058,
      "city": "Quicksburg",
      "state": "VA",
      "county": "Shenandoah"
  },
  {
      "zipCode": 22848,
      "latitude": 38.384681,
      "longitude": -78.891422,
      "city": "Pleasant Valley",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22849,
      "latitude": 38.525239,
      "longitude": -78.607184,
      "city": "Shenandoah",
      "state": "VA",
      "county": "Page"
  },
  {
      "zipCode": 22850,
      "latitude": 38.55743,
      "longitude": -78.922657,
      "city": "Singers Glen",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22851,
      "latitude": 38.559864,
      "longitude": -78.512018,
      "city": "Stanley",
      "state": "VA",
      "county": "Page"
  },
  {
      "zipCode": 22853,
      "latitude": 38.63976,
      "longitude": -78.781566,
      "city": "Timberville",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 22901,
      "latitude": 38.093604,
      "longitude": -78.561139,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22902,
      "latitude": 38.026588,
      "longitude": -78.48045,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22903,
      "latitude": 38.033938,
      "longitude": -78.492384,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22904,
      "latitude": 38.040101,
      "longitude": -78.485066,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22905,
      "latitude": 38.040101,
      "longitude": -78.485066,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22906,
      "latitude": 38.040101,
      "longitude": -78.485066,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22907,
      "latitude": 38.040101,
      "longitude": -78.485066,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22908,
      "latitude": 38.040101,
      "longitude": -78.485066,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22909,
      "latitude": 38.024474,
      "longitude": -78.448214,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22910,
      "latitude": 38.040101,
      "longitude": -78.485066,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Charlottesville City"
  },
  {
      "zipCode": 22911,
      "latitude": 38.099507,
      "longitude": -78.408498,
      "city": "Charlottesville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22920,
      "latitude": 37.972758,
      "longitude": -78.82231,
      "city": "Afton",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22922,
      "latitude": 37.690183,
      "longitude": -78.947944,
      "city": "Arrington",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22923,
      "latitude": 38.209534,
      "longitude": -78.309759,
      "city": "Barboursville",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22924,
      "latitude": 38.001317,
      "longitude": -78.727146,
      "city": "Batesville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22931,
      "latitude": 37.893092,
      "longitude": -78.709897,
      "city": "Covesville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22932,
      "latitude": 38.129592,
      "longitude": -78.710635,
      "city": "Crozet",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22935,
      "latitude": 38.270529,
      "longitude": -78.557765,
      "city": "Dyke",
      "state": "VA",
      "county": "Greene"
  },
  {
      "zipCode": 22936,
      "latitude": 38.166565,
      "longitude": -78.506832,
      "city": "Earlysville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22937,
      "latitude": 37.82019,
      "longitude": -78.609661,
      "city": "Esmont",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22938,
      "latitude": 37.859373,
      "longitude": -78.816071,
      "city": "Faber",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22939,
      "latitude": 38.096364,
      "longitude": -78.99295,
      "city": "Fishersville",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 22940,
      "latitude": 38.194421,
      "longitude": -78.584936,
      "city": "Free Union",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22942,
      "latitude": 38.175906,
      "longitude": -78.181467,
      "city": "Gordonsville",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22943,
      "latitude": 38.078772,
      "longitude": -78.521957,
      "city": "Greenwood",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22945,
      "latitude": 38.065383,
      "longitude": -78.595831,
      "city": "Ivy",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22946,
      "latitude": 37.850141,
      "longitude": -78.541911,
      "city": "Keene",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22947,
      "latitude": 38.053137,
      "longitude": -78.339571,
      "city": "Keswick",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22948,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Locust Dale",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22949,
      "latitude": 37.753,
      "longitude": -78.859153,
      "city": "Lovingston",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22952,
      "latitude": 37.974432,
      "longitude": -78.936098,
      "city": "Lyndhurst",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 22953,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Madison Mills",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 22954,
      "latitude": 37.78907,
      "longitude": -79.012459,
      "city": "Massies Mill",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22957,
      "latitude": 38.22702,
      "longitude": -78.176806,
      "city": "Montpelier Station",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22958,
      "latitude": 37.818439,
      "longitude": -78.893139,
      "city": "Nellysford",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22959,
      "latitude": 37.933631,
      "longitude": -78.63511,
      "city": "North Garden",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22960,
      "latitude": 38.219183,
      "longitude": -78.046093,
      "city": "Orange",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22963,
      "latitude": 37.893119,
      "longitude": -78.338579,
      "city": "Palmyra",
      "state": "VA",
      "county": "Fluvanna"
  },
  {
      "zipCode": 22964,
      "latitude": 37.803604,
      "longitude": -78.959134,
      "city": "Piney River",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22965,
      "latitude": 38.330132,
      "longitude": -78.475018,
      "city": "Quinque",
      "state": "VA",
      "county": "Greene"
  },
  {
      "zipCode": 22967,
      "latitude": 37.824173,
      "longitude": -78.962522,
      "city": "Roseland",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22968,
      "latitude": 38.258615,
      "longitude": -78.407377,
      "city": "Ruckersville",
      "state": "VA",
      "county": "Greene"
  },
  {
      "zipCode": 22969,
      "latitude": 37.818031,
      "longitude": -78.819906,
      "city": "Schuyler",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22971,
      "latitude": 37.759956,
      "longitude": -78.810459,
      "city": "Shipman",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22972,
      "latitude": 38.199692,
      "longitude": -78.239378,
      "city": "Somerset",
      "state": "VA",
      "county": "Orange"
  },
  {
      "zipCode": 22973,
      "latitude": 38.312057,
      "longitude": -78.482014,
      "city": "Stanardsville",
      "state": "VA",
      "county": "Greene"
  },
  {
      "zipCode": 22974,
      "latitude": 37.95591,
      "longitude": -78.276599,
      "city": "Troy",
      "state": "VA",
      "county": "Fluvanna"
  },
  {
      "zipCode": 22976,
      "latitude": 37.839132,
      "longitude": -79.069321,
      "city": "Tyro",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 22980,
      "latitude": 38.065794,
      "longitude": -78.903445,
      "city": "Waynesboro",
      "state": "VA",
      "county": "Waynesboro City"
  },
  {
      "zipCode": 22987,
      "latitude": 38.179859,
      "longitude": -78.618912,
      "city": "White Hall",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 22989,
      "latitude": 38.428509,
      "longitude": -78.267848,
      "city": "Woodberry Forest",
      "state": "VA",
      "county": "Madison"
  },
  {
      "zipCode": 23001,
      "latitude": 37.28822,
      "longitude": -76.426013,
      "city": "Achilles",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23002,
      "latitude": 37.342768,
      "longitude": -77.984092,
      "city": "Amelia Court House",
      "state": "VA",
      "county": "Amelia"
  },
  {
      "zipCode": 23003,
      "latitude": 37.432335,
      "longitude": -76.601616,
      "city": "Ark",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23004,
      "latitude": 37.563225,
      "longitude": -78.53643,
      "city": "Arvonia",
      "state": "VA",
      "county": "Buckingham"
  },
  {
      "zipCode": 23005,
      "latitude": 37.754912,
      "longitude": -77.480128,
      "city": "Ashland",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23009,
      "latitude": 37.798833,
      "longitude": -77.180912,
      "city": "Aylett",
      "state": "VA",
      "county": "King William"
  },
  {
      "zipCode": 23011,
      "latitude": 37.479524,
      "longitude": -76.949981,
      "city": "Barhamsville",
      "state": "VA",
      "county": "New Kent"
  },
  {
      "zipCode": 23014,
      "latitude": 37.733844,
      "longitude": -77.888082,
      "city": "Beaumont",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23015,
      "latitude": 37.914736,
      "longitude": -77.62341,
      "city": "Beaverdam",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23017,
      "latitude": 37.418242,
      "longitude": -76.508402,
      "city": "Bellamy",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23018,
      "latitude": 37.418242,
      "longitude": -76.508402,
      "city": "Bena",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23021,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Bohannon",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23022,
      "latitude": 37.848408,
      "longitude": -78.277393,
      "city": "Bremo Bluff",
      "state": "VA",
      "county": "Fluvanna"
  },
  {
      "zipCode": 23023,
      "latitude": 37.701823,
      "longitude": -76.916248,
      "city": "Bruington",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23024,
      "latitude": 37.90754,
      "longitude": -77.798047,
      "city": "Bumpass",
      "state": "VA",
      "county": "Louisa"
  },
  {
      "zipCode": 23025,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Cardinal",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23027,
      "latitude": 37.64794,
      "longitude": -78.138859,
      "city": "Cartersville",
      "state": "VA",
      "county": "Cumberland"
  },
  {
      "zipCode": 23030,
      "latitude": 37.354115,
      "longitude": -77.073103,
      "city": "Charles City",
      "state": "VA",
      "county": "Charles City"
  },
  {
      "zipCode": 23031,
      "latitude": 37.598391,
      "longitude": -76.447551,
      "city": "Christchurch",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23032,
      "latitude": 37.644319,
      "longitude": -76.446001,
      "city": "Church View",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23035,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Cobbs Creek",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23038,
      "latitude": 37.807863,
      "longitude": -78.093506,
      "city": "Columbia",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23039,
      "latitude": 37.657031,
      "longitude": -77.794731,
      "city": "Crozier",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23040,
      "latitude": 37.518167,
      "longitude": -78.214232,
      "city": "Cumberland",
      "state": "VA",
      "county": "Cumberland"
  },
  {
      "zipCode": 23043,
      "latitude": 37.551856,
      "longitude": -76.366544,
      "city": "Deltaville",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23045,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Diggs",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23047,
      "latitude": 37.846874,
      "longitude": -77.513156,
      "city": "Doswell",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23050,
      "latitude": 37.500094,
      "longitude": -76.454046,
      "city": "Dutton",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23054,
      "latitude": 37.733844,
      "longitude": -77.888082,
      "city": "Fife",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23055,
      "latitude": 37.848408,
      "longitude": -78.277393,
      "city": "Fork Union",
      "state": "VA",
      "county": "Fluvanna"
  },
  {
      "zipCode": 23056,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Foster",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23058,
      "latitude": 37.531296,
      "longitude": -77.416103,
      "city": "Glen Allen",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23059,
      "latitude": 37.728404,
      "longitude": -77.554393,
      "city": "Glen Allen",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23060,
      "latitude": 37.595064,
      "longitude": -77.508719,
      "city": "Glen Allen",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23061,
      "latitude": 37.41259,
      "longitude": -76.546416,
      "city": "Gloucester",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23062,
      "latitude": 37.277726,
      "longitude": -76.49988,
      "city": "Gloucester Point",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23063,
      "latitude": 37.725416,
      "longitude": -77.995,
      "city": "Goochland",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23064,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Grimstead",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23065,
      "latitude": 37.798243,
      "longitude": -77.937457,
      "city": "Gum Spring",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23066,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Gwynn",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23067,
      "latitude": 37.825208,
      "longitude": -77.989878,
      "city": "Hadensville",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23068,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Hallieford",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23069,
      "latitude": 37.735448,
      "longitude": -77.337944,
      "city": "Hanover",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23070,
      "latitude": 37.55118,
      "longitude": -76.384428,
      "city": "Hardyville",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23071,
      "latitude": 37.541289,
      "longitude": -76.4152,
      "city": "Hartfield",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23072,
      "latitude": 37.391501,
      "longitude": -76.514807,
      "city": "Hayes",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23075,
      "latitude": 37.545511,
      "longitude": -77.317838,
      "city": "Highland Springs",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23076,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Hudgins",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23079,
      "latitude": 37.644319,
      "longitude": -76.446001,
      "city": "Jamaica",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23081,
      "latitude": 37.223482,
      "longitude": -76.783317,
      "city": "Jamestown",
      "state": "VA",
      "county": "James City"
  },
  {
      "zipCode": 23083,
      "latitude": 37.322156,
      "longitude": -78.121631,
      "city": "Jetersville",
      "state": "VA",
      "county": "Amelia"
  },
  {
      "zipCode": 23084,
      "latitude": 37.848408,
      "longitude": -78.277393,
      "city": "Kents Store",
      "state": "VA",
      "county": "Fluvanna"
  },
  {
      "zipCode": 23085,
      "latitude": 37.701823,
      "longitude": -76.916248,
      "city": "King And Queen Court Hous",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23086,
      "latitude": 37.677991,
      "longitude": -77.028495,
      "city": "King William",
      "state": "VA",
      "county": "King William"
  },
  {
      "zipCode": 23089,
      "latitude": 37.465312,
      "longitude": -76.909711,
      "city": "Lanexa",
      "state": "VA",
      "county": "New Kent"
  },
  {
      "zipCode": 23090,
      "latitude": 37.235892,
      "longitude": -76.445567,
      "city": "Lightfoot",
      "state": "VA",
      "county": "York"
  },
  {
      "zipCode": 23091,
      "latitude": 37.617747,
      "longitude": -76.771273,
      "city": "Little Plymouth",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23092,
      "latitude": 37.582554,
      "longitude": -76.498426,
      "city": "Locust Hill",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23093,
      "latitude": 37.998422,
      "longitude": -77.967416,
      "city": "Louisa",
      "state": "VA",
      "county": "Louisa"
  },
  {
      "zipCode": 23101,
      "latitude": 37.553314,
      "longitude": -77.892964,
      "city": "Macon",
      "state": "VA",
      "county": "Powhatan"
  },
  {
      "zipCode": 23102,
      "latitude": 37.694226,
      "longitude": -77.796603,
      "city": "Maidens",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23103,
      "latitude": 37.663224,
      "longitude": -77.791971,
      "city": "Manakin Sabot",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23105,
      "latitude": 37.345032,
      "longitude": -77.944868,
      "city": "Mannboro",
      "state": "VA",
      "county": "Amelia"
  },
  {
      "zipCode": 23106,
      "latitude": 37.733093,
      "longitude": -77.206868,
      "city": "Manquin",
      "state": "VA",
      "county": "King William"
  },
  {
      "zipCode": 23107,
      "latitude": 37.28641,
      "longitude": -76.404783,
      "city": "Maryus",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23108,
      "latitude": 37.701823,
      "longitude": -76.916248,
      "city": "Mascot",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23109,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Mathews",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23110,
      "latitude": 37.613475,
      "longitude": -76.810087,
      "city": "Mattaponi",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23111,
      "latitude": 37.628179,
      "longitude": -77.281955,
      "city": "Mechanicsville",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23112,
      "latitude": 37.454138,
      "longitude": -77.66258,
      "city": "Midlothian",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23113,
      "latitude": 37.494897,
      "longitude": -77.663061,
      "city": "Midlothian",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23114,
      "latitude": 37.48301,
      "longitude": -77.660433,
      "city": "Midlothian",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23115,
      "latitude": 37.836374,
      "longitude": -76.913844,
      "city": "Millers Tavern",
      "state": "VA",
      "county": "Essex"
  },
  {
      "zipCode": 23116,
      "latitude": 37.669086,
      "longitude": -77.329421,
      "city": "Mechanicsville",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23117,
      "latitude": 37.937619,
      "longitude": -77.868959,
      "city": "Mineral",
      "state": "VA",
      "county": "Louisa"
  },
  {
      "zipCode": 23119,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Moon",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23120,
      "latitude": 37.416066,
      "longitude": -77.779015,
      "city": "Moseley",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23123,
      "latitude": 37.563225,
      "longitude": -78.53643,
      "city": "New Canton",
      "state": "VA",
      "county": "Buckingham"
  },
  {
      "zipCode": 23124,
      "latitude": 37.535579,
      "longitude": -77.005863,
      "city": "New Kent",
      "state": "VA",
      "county": "New Kent"
  },
  {
      "zipCode": 23125,
      "latitude": 37.343785,
      "longitude": -76.287792,
      "city": "New Point",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23126,
      "latitude": 37.701823,
      "longitude": -76.916248,
      "city": "Newtown",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23127,
      "latitude": 37.310451,
      "longitude": -76.746769,
      "city": "Norge",
      "state": "VA",
      "county": "James City"
  },
  {
      "zipCode": 23128,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "North",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23129,
      "latitude": 37.701136,
      "longitude": -77.790584,
      "city": "Oilville",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23130,
      "latitude": 37.410286,
      "longitude": -76.294103,
      "city": "Onemo",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23131,
      "latitude": 37.312159,
      "longitude": -76.518831,
      "city": "Ordinary",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23138,
      "latitude": 37.355641,
      "longitude": -76.292253,
      "city": "Port Haywood",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23139,
      "latitude": 37.541609,
      "longitude": -77.928759,
      "city": "Powhatan",
      "state": "VA",
      "county": "Powhatan"
  },
  {
      "zipCode": 23140,
      "latitude": 37.453467,
      "longitude": -77.033714,
      "city": "Providence Forge",
      "state": "VA",
      "county": "New Kent"
  },
  {
      "zipCode": 23141,
      "latitude": 37.518432,
      "longitude": -77.134289,
      "city": "Quinton",
      "state": "VA",
      "county": "New Kent"
  },
  {
      "zipCode": 23146,
      "latitude": 37.739029,
      "longitude": -77.708072,
      "city": "Rockville",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23147,
      "latitude": 37.37956,
      "longitude": -77.034752,
      "city": "Ruthville",
      "state": "VA",
      "county": "Charles City"
  },
  {
      "zipCode": 23148,
      "latitude": 37.753427,
      "longitude": -77.047727,
      "city": "Saint Stephens Church",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23149,
      "latitude": 37.644319,
      "longitude": -76.446001,
      "city": "Saluda",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23150,
      "latitude": 37.501495,
      "longitude": -77.271789,
      "city": "Sandston",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23153,
      "latitude": 37.779913,
      "longitude": -77.940549,
      "city": "Sandy Hook",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23154,
      "latitude": 37.418242,
      "longitude": -76.508402,
      "city": "Schley",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23155,
      "latitude": 37.418242,
      "longitude": -76.508402,
      "city": "Severn",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23156,
      "latitude": 37.544151,
      "longitude": -76.684306,
      "city": "Shacklefords",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23160,
      "latitude": 37.733844,
      "longitude": -77.888082,
      "city": "State Farm",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23161,
      "latitude": 37.701823,
      "longitude": -76.916248,
      "city": "Stevensville",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23162,
      "latitude": 37.772187,
      "longitude": -77.45264,
      "city": "Studley",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23163,
      "latitude": 37.350792,
      "longitude": -76.316136,
      "city": "Susan",
      "state": "VA",
      "county": "Mathews"
  },
  {
      "zipCode": 23168,
      "latitude": 37.394138,
      "longitude": -76.835415,
      "city": "Toano",
      "state": "VA",
      "county": "James City"
  },
  {
      "zipCode": 23169,
      "latitude": 37.644319,
      "longitude": -76.446001,
      "city": "Topping",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23170,
      "latitude": 37.940788,
      "longitude": -77.996956,
      "city": "Trevilians",
      "state": "VA",
      "county": "Louisa"
  },
  {
      "zipCode": 23173,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "University Of Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23175,
      "latitude": 37.635564,
      "longitude": -76.591805,
      "city": "Urbanna",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23176,
      "latitude": 37.644319,
      "longitude": -76.446001,
      "city": "Wake",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23177,
      "latitude": 37.701823,
      "longitude": -76.916248,
      "city": "Walkerton",
      "state": "VA",
      "county": "King And Queen"
  },
  {
      "zipCode": 23178,
      "latitude": 37.400387,
      "longitude": -76.452896,
      "city": "Ware Neck",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23180,
      "latitude": 37.725161,
      "longitude": -76.613446,
      "city": "Water View",
      "state": "VA",
      "county": "Middlesex"
  },
  {
      "zipCode": 23181,
      "latitude": 37.607215,
      "longitude": -76.891642,
      "city": "West Point",
      "state": "VA",
      "county": "King William"
  },
  {
      "zipCode": 23183,
      "latitude": 37.363201,
      "longitude": -76.532492,
      "city": "White Marsh",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23184,
      "latitude": 37.322121,
      "longitude": -76.53185,
      "city": "Wicomico",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23185,
      "latitude": 37.273209,
      "longitude": -76.732423,
      "city": "Williamsburg",
      "state": "VA",
      "county": "James City"
  },
  {
      "zipCode": 23186,
      "latitude": 37.310451,
      "longitude": -76.746769,
      "city": "Williamsburg",
      "state": "VA",
      "county": "Williamsburg City"
  },
  {
      "zipCode": 23187,
      "latitude": 37.310451,
      "longitude": -76.746769,
      "city": "Williamsburg",
      "state": "VA",
      "county": "Williamsburg City"
  },
  {
      "zipCode": 23188,
      "latitude": 37.34822,
      "longitude": -76.773832,
      "city": "Williamsburg",
      "state": "VA",
      "county": "James City"
  },
  {
      "zipCode": 23190,
      "latitude": 37.482039,
      "longitude": -76.636154,
      "city": "Woods Cross Roads",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23191,
      "latitude": 37.418242,
      "longitude": -76.508402,
      "city": "Zanoni",
      "state": "VA",
      "county": "Gloucester"
  },
  {
      "zipCode": 23192,
      "latitude": 37.820707,
      "longitude": -77.651831,
      "city": "Montpelier",
      "state": "VA",
      "county": "Hanover"
  },
  {
      "zipCode": 23218,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23219,
      "latitude": 37.541696,
      "longitude": -77.439204,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23220,
      "latitude": 37.552746,
      "longitude": -77.456602,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23221,
      "latitude": 37.553196,
      "longitude": -77.489706,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23222,
      "latitude": 37.568861,
      "longitude": -77.428004,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23223,
      "latitude": 37.533246,
      "longitude": -77.431054,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23224,
      "latitude": 37.497796,
      "longitude": -77.46726,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23225,
      "latitude": 37.519396,
      "longitude": -77.500757,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23226,
      "latitude": 37.587223,
      "longitude": -77.518407,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23227,
      "latitude": 37.6247,
      "longitude": -77.435149,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23228,
      "latitude": 37.624988,
      "longitude": -77.49592,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23229,
      "latitude": 37.595695,
      "longitude": -77.570359,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23230,
      "latitude": 37.592095,
      "longitude": -77.495206,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23231,
      "latitude": 37.457072,
      "longitude": -77.307326,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23232,
      "latitude": 37.520187,
      "longitude": -77.408359,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23233,
      "latitude": 37.615276,
      "longitude": -77.576641,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23234,
      "latitude": 37.43732,
      "longitude": -77.478825,
      "city": "Richmond",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23235,
      "latitude": 37.494147,
      "longitude": -77.556501,
      "city": "Richmond",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23236,
      "latitude": 37.466359,
      "longitude": -77.590818,
      "city": "Richmond",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23237,
      "latitude": 37.400822,
      "longitude": -77.471206,
      "city": "Richmond",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23238,
      "latitude": 37.733844,
      "longitude": -77.888082,
      "city": "Richmond",
      "state": "VA",
      "county": "Goochland"
  },
  {
      "zipCode": 23240,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23241,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23242,
      "latitude": 37.531296,
      "longitude": -77.416103,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23249,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23250,
      "latitude": 37.507498,
      "longitude": -77.332916,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23255,
      "latitude": 37.531296,
      "longitude": -77.416103,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23260,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23261,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23266,
      "latitude": 37.567896,
      "longitude": -77.528308,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23269,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23270,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23272,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23273,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23274,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23275,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23276,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23278,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23279,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23280,
      "latitude": 37.637622,
      "longitude": -77.46836,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23282,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23284,
      "latitude": 37.549446,
      "longitude": -77.451155,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23285,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23286,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23288,
      "latitude": 37.531296,
      "longitude": -77.416103,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23289,
      "latitude": 37.531296,
      "longitude": -77.416103,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23290,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23291,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23292,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23293,
      "latitude": 37.524246,
      "longitude": -77.493157,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23294,
      "latitude": 37.628657,
      "longitude": -77.542871,
      "city": "Richmond",
      "state": "VA",
      "county": "Henrico"
  },
  {
      "zipCode": 23295,
      "latitude": 37.5803,
      "longitude": -77.486661,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23297,
      "latitude": 37.389721,
      "longitude": -77.561266,
      "city": "Richmond",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23298,
      "latitude": 37.540646,
      "longitude": -77.431555,
      "city": "Richmond",
      "state": "VA",
      "county": "Richmond City"
  },
  {
      "zipCode": 23301,
      "latitude": 37.712919,
      "longitude": -75.661132,
      "city": "Accomac",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23302,
      "latitude": 37.865814,
      "longitude": -75.527697,
      "city": "Assawoman",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23303,
      "latitude": 37.896797,
      "longitude": -75.520581,
      "city": "Atlantic",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23304,
      "latitude": 36.989766,
      "longitude": -76.572291,
      "city": "Battery Park",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23306,
      "latitude": 37.567852,
      "longitude": -75.858617,
      "city": "Belle Haven",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23307,
      "latitude": 37.432247,
      "longitude": -75.880425,
      "city": "Birdsnest",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23308,
      "latitude": 37.823569,
      "longitude": -75.615138,
      "city": "Bloxom",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23310,
      "latitude": 37.259125,
      "longitude": -75.961975,
      "city": "Cape Charles",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23313,
      "latitude": 37.201937,
      "longitude": -75.952409,
      "city": "Capeville",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23314,
      "latitude": 36.956505,
      "longitude": -76.542783,
      "city": "Carrollton",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23315,
      "latitude": 36.73282,
      "longitude": -76.830545,
      "city": "Carrsville",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23316,
      "latitude": 37.294283,
      "longitude": -75.961858,
      "city": "Cheriton",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23320,
      "latitude": 36.749991,
      "longitude": -76.218759,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23321,
      "latitude": 36.781942,
      "longitude": -76.325762,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23322,
      "latitude": 36.6434,
      "longitude": -76.24201,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23323,
      "latitude": 36.710949,
      "longitude": -76.339913,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23324,
      "latitude": 36.802097,
      "longitude": -76.273934,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23325,
      "latitude": 36.79202,
      "longitude": -76.23856,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23326,
      "latitude": 36.777015,
      "longitude": -76.239417,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23327,
      "latitude": 36.7085,
      "longitude": -76.278531,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23328,
      "latitude": 36.7085,
      "longitude": -76.278531,
      "city": "Chesapeake",
      "state": "VA",
      "county": "Chesapeake City"
  },
  {
      "zipCode": 23336,
      "latitude": 37.948723,
      "longitude": -75.436285,
      "city": "Chincoteague Island",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23337,
      "latitude": 37.918556,
      "longitude": -75.490518,
      "city": "Wallops Island",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23341,
      "latitude": 37.577292,
      "longitude": -75.864607,
      "city": "Craddockville",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23345,
      "latitude": 37.738248,
      "longitude": -75.701449,
      "city": "Davis Wharf",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23347,
      "latitude": 37.356057,
      "longitude": -75.964939,
      "city": "Eastville",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23350,
      "latitude": 37.376126,
      "longitude": -75.882344,
      "city": "Exmore",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23354,
      "latitude": 37.458837,
      "longitude": -75.900724,
      "city": "Franktown",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23356,
      "latitude": 37.999789,
      "longitude": -75.413184,
      "city": "Greenbackville",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23357,
      "latitude": 37.750487,
      "longitude": -75.66704,
      "city": "Greenbush",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23358,
      "latitude": 37.639252,
      "longitude": -75.865009,
      "city": "Hacksneck",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23359,
      "latitude": 37.883709,
      "longitude": -75.604063,
      "city": "Hallwood",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23389,
      "latitude": 37.656158,
      "longitude": -75.848807,
      "city": "Harborton",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23395,
      "latitude": 37.975567,
      "longitude": -75.479748,
      "city": "Horntown",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23396,
      "latitude": 37.923682,
      "longitude": -75.555142,
      "city": "Oak Hall",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23397,
      "latitude": 36.898915,
      "longitude": -76.687745,
      "city": "Isle Of Wight",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23398,
      "latitude": 37.534336,
      "longitude": -75.877052,
      "city": "Jamesville",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23399,
      "latitude": 37.916218,
      "longitude": -75.616754,
      "city": "Jenkins Bridge",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23401,
      "latitude": 37.596576,
      "longitude": -75.789256,
      "city": "Keller",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23404,
      "latitude": 37.677193,
      "longitude": -75.672604,
      "city": "Locustville",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23405,
      "latitude": 37.413281,
      "longitude": -75.92133,
      "city": "Machipongo",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23407,
      "latitude": 37.838898,
      "longitude": -75.563439,
      "city": "Mappsville",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23408,
      "latitude": 37.424497,
      "longitude": -75.877143,
      "city": "Marionville",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23409,
      "latitude": 37.844421,
      "longitude": -75.611577,
      "city": "Mears",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23410,
      "latitude": 37.603823,
      "longitude": -75.75782,
      "city": "Melfa",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23412,
      "latitude": 37.790415,
      "longitude": -75.60347,
      "city": "Modest Town",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23413,
      "latitude": 37.469454,
      "longitude": -75.879019,
      "city": "Nassawadox",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23414,
      "latitude": 37.816896,
      "longitude": -75.583227,
      "city": "Nelsonia",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23415,
      "latitude": 37.92807,
      "longitude": -75.526526,
      "city": "New Church",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23416,
      "latitude": 37.922866,
      "longitude": -75.538591,
      "city": "Oak Hall",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23417,
      "latitude": 37.693197,
      "longitude": -75.746,
      "city": "Onancock",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23418,
      "latitude": 37.796421,
      "longitude": -75.650275,
      "city": "Onley",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23419,
      "latitude": 37.307411,
      "longitude": -75.926877,
      "city": "Oyster",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23420,
      "latitude": 37.59081,
      "longitude": -75.832274,
      "city": "Painter",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23421,
      "latitude": 37.840771,
      "longitude": -75.633717,
      "city": "Parksley",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23422,
      "latitude": 37.616183,
      "longitude": -75.791519,
      "city": "Pungoteague",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23423,
      "latitude": 37.542324,
      "longitude": -75.74122,
      "city": "Quinby",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23424,
      "latitude": 36.996881,
      "longitude": -76.564516,
      "city": "Rescue",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23426,
      "latitude": 37.922894,
      "longitude": -75.678111,
      "city": "Sanford",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23427,
      "latitude": 37.926441,
      "longitude": -75.721978,
      "city": "Saxis",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23429,
      "latitude": 37.271104,
      "longitude": -75.953608,
      "city": "Seaview",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23430,
      "latitude": 36.990826,
      "longitude": -76.661123,
      "city": "Smithfield",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23431,
      "latitude": 36.898915,
      "longitude": -76.687745,
      "city": "Smithfield",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23432,
      "latitude": 36.874916,
      "longitude": -76.553061,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23433,
      "latitude": 36.9061,
      "longitude": -76.493175,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23434,
      "latitude": 36.704592,
      "longitude": -76.636158,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23435,
      "latitude": 36.837412,
      "longitude": -76.477756,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23436,
      "latitude": 36.886856,
      "longitude": -76.523388,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23437,
      "latitude": 36.646717,
      "longitude": -76.796225,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23438,
      "latitude": 36.598524,
      "longitude": -76.710143,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23439,
      "latitude": 36.746101,
      "longitude": -76.665251,
      "city": "Suffolk",
      "state": "VA",
      "county": "Suffolk City"
  },
  {
      "zipCode": 23440,
      "latitude": 37.823118,
      "longitude": -75.994287,
      "city": "Tangier",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23441,
      "latitude": 37.732288,
      "longitude": -75.677292,
      "city": "Tasley",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23442,
      "latitude": 37.899545,
      "longitude": -75.580102,
      "city": "Temperanceville",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23443,
      "latitude": 37.188217,
      "longitude": -75.969003,
      "city": "Townsend",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23450,
      "latitude": 36.844004,
      "longitude": -76.12036,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23451,
      "latitude": 36.856348,
      "longitude": -76.053568,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23452,
      "latitude": 36.846147,
      "longitude": -76.097355,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23454,
      "latitude": 36.828586,
      "longitude": -76.070772,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23455,
      "latitude": 36.888865,
      "longitude": -76.146757,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23456,
      "latitude": 36.746599,
      "longitude": -76.039092,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23457,
      "latitude": 36.622351,
      "longitude": -76.02485,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23458,
      "latitude": 36.847388,
      "longitude": -76.15581,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23459,
      "latitude": 36.924496,
      "longitude": -76.018703,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23460,
      "latitude": 36.837598,
      "longitude": -76.026053,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23461,
      "latitude": 36.790249,
      "longitude": -76.002552,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23462,
      "latitude": 36.837052,
      "longitude": -76.146692,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23463,
      "latitude": 36.795669,
      "longitude": -76.012628,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23464,
      "latitude": 36.799473,
      "longitude": -76.178375,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23465,
      "latitude": 36.851243,
      "longitude": -76.169215,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23466,
      "latitude": 36.795669,
      "longitude": -76.012628,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23467,
      "latitude": 36.795669,
      "longitude": -76.012628,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23468,
      "latitude": 36.843948,
      "longitude": -76.142407,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23471,
      "latitude": 36.795669,
      "longitude": -76.012628,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23479,
      "latitude": 36.795669,
      "longitude": -76.012628,
      "city": "Virginia Beach",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23480,
      "latitude": 37.604378,
      "longitude": -75.692465,
      "city": "Wachapreague",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23482,
      "latitude": 37.273986,
      "longitude": -75.86913,
      "city": "Wardtown",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23483,
      "latitude": 37.943726,
      "longitude": -75.502306,
      "city": "Wattsville",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23486,
      "latitude": 37.518917,
      "longitude": -75.810482,
      "city": "Willis Wharf",
      "state": "VA",
      "county": "Northampton"
  },
  {
      "zipCode": 23487,
      "latitude": 36.845054,
      "longitude": -76.713731,
      "city": "Windsor",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23488,
      "latitude": 37.948229,
      "longitude": -75.601856,
      "city": "Withams",
      "state": "VA",
      "county": "Accomack"
  },
  {
      "zipCode": 23501,
      "latitude": 36.895911,
      "longitude": -76.208521,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23502,
      "latitude": 36.887433,
      "longitude": -76.212909,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23503,
      "latitude": 36.948097,
      "longitude": -76.256311,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23504,
      "latitude": 36.877847,
      "longitude": -76.265762,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23505,
      "latitude": 36.902947,
      "longitude": -76.278813,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23506,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23507,
      "latitude": 36.865697,
      "longitude": -76.299862,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23508,
      "latitude": 36.884447,
      "longitude": -76.299963,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23509,
      "latitude": 36.881197,
      "longitude": -76.261511,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23510,
      "latitude": 36.881047,
      "longitude": -76.292412,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23511,
      "latitude": 36.935579,
      "longitude": -76.303417,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23512,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23513,
      "latitude": 36.890747,
      "longitude": -76.23516,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23514,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23515,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23517,
      "latitude": 36.869597,
      "longitude": -76.292611,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23518,
      "latitude": 36.907997,
      "longitude": -76.216448,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23519,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23520,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23521,
      "latitude": 36.913144,
      "longitude": -76.147157,
      "city": "Norfolk",
      "state": "VA",
      "county": "Virginia Beach City"
  },
  {
      "zipCode": 23523,
      "latitude": 36.831647,
      "longitude": -76.270561,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23529,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23530,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23541,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23551,
      "latitude": 36.931166,
      "longitude": -76.23972,
      "city": "Norfolk",
      "state": "VA",
      "county": "Norfolk City"
  },
  {
      "zipCode": 23601,
      "latitude": 37.058296,
      "longitude": -76.463471,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23602,
      "latitude": 37.125384,
      "longitude": -76.510952,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23603,
      "latitude": 37.168276,
      "longitude": -76.541367,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23604,
      "latitude": 37.157438,
      "longitude": -76.584469,
      "city": "Fort Eustis",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23605,
      "latitude": 37.021946,
      "longitude": -76.436637,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23606,
      "latitude": 37.073526,
      "longitude": -76.493022,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23607,
      "latitude": 36.991097,
      "longitude": -76.420763,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23608,
      "latitude": 37.152619,
      "longitude": -76.542039,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23609,
      "latitude": 37.195924,
      "longitude": -76.524805,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23612,
      "latitude": 37.195924,
      "longitude": -76.524805,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23628,
      "latitude": 37.195924,
      "longitude": -76.524805,
      "city": "Newport News",
      "state": "VA",
      "county": "Newport News City"
  },
  {
      "zipCode": 23630,
      "latitude": 37.072658,
      "longitude": -76.38992,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23631,
      "latitude": 37.072658,
      "longitude": -76.38992,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23651,
      "latitude": 37.018012,
      "longitude": -76.304391,
      "city": "Fort Monroe",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23653,
      "latitude": 37.072658,
      "longitude": -76.38992,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23661,
      "latitude": 37.034746,
      "longitude": -76.385817,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23662,
      "latitude": 37.132326,
      "longitude": -76.365419,
      "city": "Poquoson",
      "state": "VA",
      "county": "Poquoson City"
  },
  {
      "zipCode": 23663,
      "latitude": 37.034207,
      "longitude": -76.319364,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23664,
      "latitude": 37.05165,
      "longitude": -76.334465,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23665,
      "latitude": 37.083118,
      "longitude": -76.360036,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23666,
      "latitude": 37.060646,
      "longitude": -76.371616,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23667,
      "latitude": 37.019346,
      "longitude": -76.331815,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23668,
      "latitude": 37.020596,
      "longitude": -76.337715,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23669,
      "latitude": 37.045715,
      "longitude": -76.353866,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23670,
      "latitude": 37.072658,
      "longitude": -76.38992,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23681,
      "latitude": 37.072658,
      "longitude": -76.38992,
      "city": "Hampton",
      "state": "VA",
      "county": "Hampton City"
  },
  {
      "zipCode": 23690,
      "latitude": 37.230251,
      "longitude": -76.524359,
      "city": "Yorktown",
      "state": "VA",
      "county": "York"
  },
  {
      "zipCode": 23691,
      "latitude": 37.203185,
      "longitude": -76.494054,
      "city": "Yorktown",
      "state": "VA",
      "county": "York"
  },
  {
      "zipCode": 23692,
      "latitude": 37.168632,
      "longitude": -76.458126,
      "city": "Yorktown",
      "state": "VA",
      "county": "York"
  },
  {
      "zipCode": 23693,
      "latitude": 37.152812,
      "longitude": -76.44791,
      "city": "Yorktown",
      "state": "VA",
      "county": "York"
  },
  {
      "zipCode": 23694,
      "latitude": 37.223152,
      "longitude": -76.55901,
      "city": "Lackey",
      "state": "VA",
      "county": "York"
  },
  {
      "zipCode": 23696,
      "latitude": 37.189902,
      "longitude": -76.426469,
      "city": "Seaford",
      "state": "VA",
      "county": "York"
  },
  {
      "zipCode": 23701,
      "latitude": 36.811498,
      "longitude": -76.367715,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23702,
      "latitude": 36.804098,
      "longitude": -76.329263,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23703,
      "latitude": 36.867948,
      "longitude": -76.380766,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23704,
      "latitude": 36.824298,
      "longitude": -76.315663,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23705,
      "latitude": 36.868553,
      "longitude": -76.355165,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23707,
      "latitude": 36.837153,
      "longitude": -76.347314,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23708,
      "latitude": 36.868553,
      "longitude": -76.355165,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23709,
      "latitude": 36.868553,
      "longitude": -76.355165,
      "city": "Portsmouth",
      "state": "VA",
      "county": "Portsmouth City"
  },
  {
      "zipCode": 23801,
      "latitude": 37.233941,
      "longitude": -77.334831,
      "city": "Fort Lee",
      "state": "VA",
      "county": "Prince George"
  },
  {
      "zipCode": 23803,
      "latitude": 37.21713,
      "longitude": -77.389253,
      "city": "Petersburg",
      "state": "VA",
      "county": "Petersburg City"
  },
  {
      "zipCode": 23804,
      "latitude": 37.204765,
      "longitude": -77.392784,
      "city": "Petersburg",
      "state": "VA",
      "county": "Petersburg City"
  },
  {
      "zipCode": 23805,
      "latitude": 37.1922,
      "longitude": -77.389641,
      "city": "Petersburg",
      "state": "VA",
      "county": "Petersburg City"
  },
  {
      "zipCode": 23806,
      "latitude": 37.204765,
      "longitude": -77.392784,
      "city": "Petersburg",
      "state": "VA",
      "county": "Petersburg City"
  },
  {
      "zipCode": 23821,
      "latitude": 36.858078,
      "longitude": -77.894632,
      "city": "Alberta",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23822,
      "latitude": 37.072361,
      "longitude": -77.647495,
      "city": "Ammon",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23824,
      "latitude": 37.12237,
      "longitude": -77.978923,
      "city": "Blackstone",
      "state": "VA",
      "county": "Nottoway"
  },
  {
      "zipCode": 23827,
      "latitude": 36.611614,
      "longitude": -77.207263,
      "city": "Boykins",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23828,
      "latitude": 36.597427,
      "longitude": -77.293341,
      "city": "Branchville",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23829,
      "latitude": 36.720629,
      "longitude": -77.236519,
      "city": "Capron",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23830,
      "latitude": 37.021522,
      "longitude": -77.453348,
      "city": "Carson",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23831,
      "latitude": 37.320744,
      "longitude": -77.454311,
      "city": "Chester",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23832,
      "latitude": 37.392683,
      "longitude": -77.605362,
      "city": "Chesterfield",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23833,
      "latitude": 37.168045,
      "longitude": -77.674226,
      "city": "Church Road",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23834,
      "latitude": 37.26219,
      "longitude": -77.396989,
      "city": "Colonial Heights",
      "state": "VA",
      "county": "Colonial Heights City"
  },
  {
      "zipCode": 23836,
      "latitude": 37.347458,
      "longitude": -77.338551,
      "city": "Chester",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23837,
      "latitude": 36.70656,
      "longitude": -77.215522,
      "city": "Courtland",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23838,
      "latitude": 37.333271,
      "longitude": -77.634265,
      "city": "Chesterfield",
      "state": "VA",
      "county": "Chesterfield"
  },
  {
      "zipCode": 23839,
      "latitude": 37.065785,
      "longitude": -76.915537,
      "city": "Dendron",
      "state": "VA",
      "county": "Surry"
  },
  {
      "zipCode": 23840,
      "latitude": 37.069327,
      "longitude": -77.677945,
      "city": "Dewitt",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23841,
      "latitude": 37.079304,
      "longitude": -77.601563,
      "city": "Dinwiddie",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23842,
      "latitude": 37.150869,
      "longitude": -77.213961,
      "city": "Disputanta",
      "state": "VA",
      "county": "Prince George"
  },
  {
      "zipCode": 23843,
      "latitude": 36.849008,
      "longitude": -77.807345,
      "city": "Dolphin",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23844,
      "latitude": 36.662286,
      "longitude": -77.335713,
      "city": "Drewryville",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23845,
      "latitude": 36.598591,
      "longitude": -78.001206,
      "city": "Ebony",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23846,
      "latitude": 37.07295,
      "longitude": -76.856852,
      "city": "Elberon",
      "state": "VA",
      "county": "Surry"
  },
  {
      "zipCode": 23847,
      "latitude": 36.694745,
      "longitude": -77.535517,
      "city": "Emporia",
      "state": "VA",
      "county": "Emporia City"
  },
  {
      "zipCode": 23850,
      "latitude": 37.134946,
      "longitude": -77.736402,
      "city": "Ford",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23851,
      "latitude": 36.682635,
      "longitude": -76.942233,
      "city": "Franklin",
      "state": "VA",
      "county": "Franklin City"
  },
  {
      "zipCode": 23856,
      "latitude": 36.808949,
      "longitude": -77.720027,
      "city": "Freeman",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23857,
      "latitude": 36.596172,
      "longitude": -77.890727,
      "city": "Gasburg",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23860,
      "latitude": 37.286676,
      "longitude": -77.296596,
      "city": "Hopewell",
      "state": "VA",
      "county": "Hopewell City"
  },
  {
      "zipCode": 23866,
      "latitude": 36.887634,
      "longitude": -76.900225,
      "city": "Ivor",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23867,
      "latitude": 36.864325,
      "longitude": -77.282567,
      "city": "Jarratt",
      "state": "VA",
      "county": "Sussex"
  },
  {
      "zipCode": 23868,
      "latitude": 36.734246,
      "longitude": -77.813561,
      "city": "Lawrenceville",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23870,
      "latitude": 36.721471,
      "longitude": -77.531996,
      "city": "Jarratt",
      "state": "VA",
      "county": "Greensville"
  },
  {
      "zipCode": 23872,
      "latitude": 37.010322,
      "longitude": -77.736044,
      "city": "Mc Kenney",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23873,
      "latitude": 36.80878,
      "longitude": -77.954397,
      "city": "Meredithville",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23874,
      "latitude": 36.607792,
      "longitude": -77.092706,
      "city": "Newsoms",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23875,
      "latitude": 37.234733,
      "longitude": -77.263465,
      "city": "Prince George",
      "state": "VA",
      "county": "Prince George"
  },
  {
      "zipCode": 23876,
      "latitude": 36.948942,
      "longitude": -77.841001,
      "city": "Rawlings",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23878,
      "latitude": 36.820784,
      "longitude": -77.026194,
      "city": "Sedley",
      "state": "VA",
      "county": "Southampton"
  },
  {
      "zipCode": 23879,
      "latitude": 36.595815,
      "longitude": -77.59207,
      "city": "Skippers",
      "state": "VA",
      "county": "Greensville"
  },
  {
      "zipCode": 23881,
      "latitude": 37.169453,
      "longitude": -76.967517,
      "city": "Spring Grove",
      "state": "VA",
      "county": "Surry"
  },
  {
      "zipCode": 23882,
      "latitude": 36.878331,
      "longitude": -77.392824,
      "city": "Stony Creek",
      "state": "VA",
      "county": "Sussex"
  },
  {
      "zipCode": 23883,
      "latitude": 37.095554,
      "longitude": -76.821125,
      "city": "Surry",
      "state": "VA",
      "county": "Surry"
  },
  {
      "zipCode": 23884,
      "latitude": 36.945667,
      "longitude": -77.253913,
      "city": "Sussex",
      "state": "VA",
      "county": "Sussex"
  },
  {
      "zipCode": 23885,
      "latitude": 37.180418,
      "longitude": -77.575347,
      "city": "Sutherland",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23887,
      "latitude": 36.6608,
      "longitude": -77.820567,
      "city": "Valentines",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23888,
      "latitude": 36.958091,
      "longitude": -77.040206,
      "city": "Wakefield",
      "state": "VA",
      "county": "Sussex"
  },
  {
      "zipCode": 23889,
      "latitude": 36.885672,
      "longitude": -77.764753,
      "city": "Warfield",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23890,
      "latitude": 36.973687,
      "longitude": -77.12993,
      "city": "Waverly",
      "state": "VA",
      "county": "Sussex"
  },
  {
      "zipCode": 23891,
      "latitude": 36.909845,
      "longitude": -77.286287,
      "city": "Waverly",
      "state": "VA",
      "county": "Sussex"
  },
  {
      "zipCode": 23893,
      "latitude": 36.61509,
      "longitude": -77.927891,
      "city": "White Plains",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23894,
      "latitude": 37.125964,
      "longitude": -77.703648,
      "city": "Wilsons",
      "state": "VA",
      "county": "Dinwiddie"
  },
  {
      "zipCode": 23897,
      "latitude": 36.838572,
      "longitude": -77.27587,
      "city": "Yale",
      "state": "VA",
      "county": "Sussex"
  },
  {
      "zipCode": 23898,
      "latitude": 36.833708,
      "longitude": -76.817727,
      "city": "Zuni",
      "state": "VA",
      "county": "Isle Of Wight"
  },
  {
      "zipCode": 23899,
      "latitude": 37.199009,
      "longitude": -76.984159,
      "city": "Claremont",
      "state": "VA",
      "county": "Surry"
  },
  {
      "zipCode": 23901,
      "latitude": 37.280694,
      "longitude": -78.421284,
      "city": "Farmville",
      "state": "VA",
      "county": "Prince Edward"
  },
  {
      "zipCode": 23909,
      "latitude": 37.301574,
      "longitude": -78.394944,
      "city": "Farmville",
      "state": "VA",
      "county": "Prince Edward"
  },
  {
      "zipCode": 23915,
      "latitude": 36.70774,
      "longitude": -78.281204,
      "city": "Baskerville",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23917,
      "latitude": 36.655033,
      "longitude": -78.350388,
      "city": "Boydton",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23919,
      "latitude": 36.612381,
      "longitude": -78.288907,
      "city": "Bracey",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23920,
      "latitude": 36.697808,
      "longitude": -77.935481,
      "city": "Brodnax",
      "state": "VA",
      "county": "Brunswick"
  },
  {
      "zipCode": 23921,
      "latitude": 37.563225,
      "longitude": -78.53643,
      "city": "Buckingham",
      "state": "VA",
      "county": "Buckingham"
  },
  {
      "zipCode": 23922,
      "latitude": 37.190922,
      "longitude": -78.182733,
      "city": "Burkeville",
      "state": "VA",
      "county": "Nottoway"
  },
  {
      "zipCode": 23923,
      "latitude": 37.087895,
      "longitude": -78.637846,
      "city": "Charlotte Court House",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23924,
      "latitude": 36.771496,
      "longitude": -78.425379,
      "city": "Chase City",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23927,
      "latitude": 36.65565,
      "longitude": -78.458282,
      "city": "Clarksville",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23930,
      "latitude": 37.150355,
      "longitude": -78.095474,
      "city": "Crewe",
      "state": "VA",
      "county": "Nottoway"
  },
  {
      "zipCode": 23934,
      "latitude": 37.153846,
      "longitude": -78.661108,
      "city": "Cullen",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23936,
      "latitude": 37.412369,
      "longitude": -78.434926,
      "city": "Dillwyn",
      "state": "VA",
      "county": "Buckingham"
  },
  {
      "zipCode": 23937,
      "latitude": 36.961434,
      "longitude": -78.556872,
      "city": "Drakes Branch",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23938,
      "latitude": 36.918641,
      "longitude": -78.052391,
      "city": "Dundas",
      "state": "VA",
      "county": "Lunenburg"
  },
  {
      "zipCode": 23939,
      "latitude": 37.377196,
      "longitude": -78.80648,
      "city": "Evergreen",
      "state": "VA",
      "county": "Appomattox"
  },
  {
      "zipCode": 23941,
      "latitude": 36.948129,
      "longitude": -78.248333,
      "city": "Fort Mitchell",
      "state": "VA",
      "county": "Lunenburg"
  },
  {
      "zipCode": 23942,
      "latitude": 37.238139,
      "longitude": -78.461789,
      "city": "Green Bay",
      "state": "VA",
      "county": "Prince Edward"
  },
  {
      "zipCode": 23943,
      "latitude": 37.238139,
      "longitude": -78.461789,
      "city": "Hampden Sydney",
      "state": "VA",
      "county": "Prince Edward"
  },
  {
      "zipCode": 23944,
      "latitude": 36.909212,
      "longitude": -78.142642,
      "city": "Kenbridge",
      "state": "VA",
      "county": "Lunenburg"
  },
  {
      "zipCode": 23947,
      "latitude": 37.013846,
      "longitude": -78.567247,
      "city": "Keysville",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23950,
      "latitude": 36.690021,
      "longitude": -78.232633,
      "city": "La Crosse",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23952,
      "latitude": 36.979498,
      "longitude": -78.285967,
      "city": "Lunenburg",
      "state": "VA",
      "county": "Lunenburg"
  },
  {
      "zipCode": 23954,
      "latitude": 37.238139,
      "longitude": -78.461789,
      "city": "Meherrin",
      "state": "VA",
      "county": "Prince Edward"
  },
  {
      "zipCode": 23955,
      "latitude": 37.116094,
      "longitude": -78.057832,
      "city": "Nottoway",
      "state": "VA",
      "county": "Nottoway"
  },
  {
      "zipCode": 23958,
      "latitude": 37.377196,
      "longitude": -78.80648,
      "city": "Pamplin",
      "state": "VA",
      "county": "Appomattox"
  },
  {
      "zipCode": 23959,
      "latitude": 37.108477,
      "longitude": -78.739921,
      "city": "Phenix",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23960,
      "latitude": 37.238139,
      "longitude": -78.461789,
      "city": "Prospect",
      "state": "VA",
      "county": "Prince Edward"
  },
  {
      "zipCode": 23962,
      "latitude": 36.951533,
      "longitude": -78.705413,
      "city": "Randolph",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23963,
      "latitude": 37.181814,
      "longitude": -78.77379,
      "city": "Red House",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23964,
      "latitude": 36.783999,
      "longitude": -78.618595,
      "city": "Red Oak",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23966,
      "latitude": 37.280047,
      "longitude": -78.346836,
      "city": "Rice",
      "state": "VA",
      "county": "Prince Edward"
  },
  {
      "zipCode": 23967,
      "latitude": 36.905289,
      "longitude": -78.632086,
      "city": "Saxe",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 23968,
      "latitude": 36.723976,
      "longitude": -78.530076,
      "city": "Skipwith",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23970,
      "latitude": 36.717223,
      "longitude": -78.168729,
      "city": "South Hill",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 23974,
      "latitude": 36.961136,
      "longitude": -78.258493,
      "city": "Victoria",
      "state": "VA",
      "county": "Lunenburg"
  },
  {
      "zipCode": 23976,
      "latitude": 36.839122,
      "longitude": -78.598225,
      "city": "Wylliesburg",
      "state": "VA",
      "county": "Charlotte"
  },
  {
      "zipCode": 24001,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24002,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24003,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24004,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24005,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24006,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24007,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24008,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24009,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24010,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24011,
      "latitude": 37.270237,
      "longitude": -79.941586,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24012,
      "latitude": 37.30529,
      "longitude": -79.92711,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24013,
      "latitude": 37.265687,
      "longitude": -79.923698,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24014,
      "latitude": 37.238643,
      "longitude": -79.940559,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24015,
      "latitude": 37.253866,
      "longitude": -79.974559,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24016,
      "latitude": 37.272486,
      "longitude": -79.952709,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24017,
      "latitude": 37.297919,
      "longitude": -79.98776,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24018,
      "latitude": 37.250349,
      "longitude": -80.05251,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke"
  },
  {
      "zipCode": 24019,
      "latitude": 37.351517,
      "longitude": -79.905211,
      "city": "Roanoke",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24020,
      "latitude": 37.358887,
      "longitude": -79.944789,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke"
  },
  {
      "zipCode": 24022,
      "latitude": 37.278439,
      "longitude": -79.933206,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24023,
      "latitude": 37.210663,
      "longitude": -80.406172,
      "city": "Roanoke",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24024,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24025,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24026,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24027,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24028,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24029,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24030,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24031,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24032,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24033,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24034,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24035,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24036,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24037,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24038,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24040,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24042,
      "latitude": 37.271687,
      "longitude": -79.939228,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24043,
      "latitude": 37.269168,
      "longitude": -79.939905,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24044,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24045,
      "latitude": 37.268618,
      "longitude": -79.940655,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24048,
      "latitude": 37.274175,
      "longitude": -79.95786,
      "city": "Roanoke",
      "state": "VA",
      "county": "Roanoke City"
  },
  {
      "zipCode": 24050,
      "latitude": 37.555083,
      "longitude": -79.786151,
      "city": "Roanoke",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24053,
      "latitude": 36.612434,
      "longitude": -80.504446,
      "city": "Ararat",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24054,
      "latitude": 36.670446,
      "longitude": -79.742557,
      "city": "Axton",
      "state": "VA",
      "county": "Henry"
  },
  {
      "zipCode": 24055,
      "latitude": 36.735045,
      "longitude": -79.975528,
      "city": "Bassett",
      "state": "VA",
      "county": "Henry"
  },
  {
      "zipCode": 24058,
      "latitude": 37.198248,
      "longitude": -80.615065,
      "city": "Belspring",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24059,
      "latitude": 37.154932,
      "longitude": -80.121522,
      "city": "Bent Mountain",
      "state": "VA",
      "county": "Roanoke"
  },
  {
      "zipCode": 24060,
      "latitude": 37.256283,
      "longitude": -80.43473,
      "city": "Blacksburg",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24061,
      "latitude": 37.179115,
      "longitude": -80.351482,
      "city": "Blacksburg",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24062,
      "latitude": 37.174227,
      "longitude": -80.395698,
      "city": "Blacksburg",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24063,
      "latitude": 37.174227,
      "longitude": -80.395698,
      "city": "Blacksburg",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24064,
      "latitude": 37.369287,
      "longitude": -79.819537,
      "city": "Blue Ridge",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24065,
      "latitude": 37.098391,
      "longitude": -79.95233,
      "city": "Boones Mill",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24066,
      "latitude": 37.547903,
      "longitude": -79.712321,
      "city": "Buchanan",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24067,
      "latitude": 37.024849,
      "longitude": -80.056685,
      "city": "Callaway",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24068,
      "latitude": 37.154804,
      "longitude": -80.418396,
      "city": "Christiansburg",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24069,
      "latitude": 36.594226,
      "longitude": -79.616614,
      "city": "Cascade",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24070,
      "latitude": 37.355418,
      "longitude": -80.158462,
      "city": "Catawba",
      "state": "VA",
      "county": "Roanoke"
  },
  {
      "zipCode": 24072,
      "latitude": 37.044622,
      "longitude": -80.227981,
      "city": "Check",
      "state": "VA",
      "county": "Floyd"
  },
  {
      "zipCode": 24073,
      "latitude": 37.118759,
      "longitude": -80.41787,
      "city": "Christiansburg",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24076,
      "latitude": 36.597733,
      "longitude": -80.36824,
      "city": "Claudville",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24077,
      "latitude": 37.372608,
      "longitude": -79.901331,
      "city": "Cloverdale",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24078,
      "latitude": 36.724691,
      "longitude": -79.914912,
      "city": "Collinsville",
      "state": "VA",
      "county": "Henry"
  },
  {
      "zipCode": 24079,
      "latitude": 37.048796,
      "longitude": -80.153614,
      "city": "Copper Hill",
      "state": "VA",
      "county": "Floyd"
  },
  {
      "zipCode": 24082,
      "latitude": 36.627165,
      "longitude": -80.11928,
      "city": "Critz",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24083,
      "latitude": 37.44197,
      "longitude": -79.914156,
      "city": "Daleville",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24084,
      "latitude": 37.122327,
      "longitude": -80.752614,
      "city": "Dublin",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24085,
      "latitude": 37.645695,
      "longitude": -79.864047,
      "city": "Eagle Rock",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24086,
      "latitude": 37.277231,
      "longitude": -80.670159,
      "city": "Eggleston",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24087,
      "latitude": 37.225683,
      "longitude": -80.184842,
      "city": "Elliston",
      "state": "VA",
      "county": "Roanoke"
  },
  {
      "zipCode": 24088,
      "latitude": 36.889337,
      "longitude": -80.025866,
      "city": "Ferrum",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24089,
      "latitude": 36.708873,
      "longitude": -79.970745,
      "city": "Fieldale",
      "state": "VA",
      "county": "Henry"
  },
  {
      "zipCode": 24090,
      "latitude": 37.527349,
      "longitude": -79.834474,
      "city": "Fincastle",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24091,
      "latitude": 36.895714,
      "longitude": -80.327524,
      "city": "Floyd",
      "state": "VA",
      "county": "Floyd"
  },
  {
      "zipCode": 24092,
      "latitude": 36.985894,
      "longitude": -79.772986,
      "city": "Glade Hill",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24093,
      "latitude": 37.384006,
      "longitude": -80.853048,
      "city": "Glen Lyn",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24094,
      "latitude": 37.385257,
      "longitude": -80.590627,
      "city": "Goldbond",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24095,
      "latitude": 37.21229,
      "longitude": -79.742886,
      "city": "Goodview",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24101,
      "latitude": 37.177891,
      "longitude": -79.667368,
      "city": "Hardy",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24102,
      "latitude": 36.840862,
      "longitude": -79.986739,
      "city": "Henry",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24104,
      "latitude": 37.154717,
      "longitude": -79.494856,
      "city": "Huddleston",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24105,
      "latitude": 36.90035,
      "longitude": -80.585888,
      "city": "Indian Valley",
      "state": "VA",
      "county": "Floyd"
  },
  {
      "zipCode": 24111,
      "latitude": 37.174227,
      "longitude": -80.395698,
      "city": "Mc Coy",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24112,
      "latitude": 36.681298,
      "longitude": -79.86626,
      "city": "Martinsville",
      "state": "VA",
      "county": "Martinsville City"
  },
  {
      "zipCode": 24113,
      "latitude": 36.679571,
      "longitude": -79.865168,
      "city": "Martinsville",
      "state": "VA",
      "county": "Martinsville City"
  },
  {
      "zipCode": 24114,
      "latitude": 36.679571,
      "longitude": -79.865168,
      "city": "Martinsville",
      "state": "VA",
      "county": "Martinsville City"
  },
  {
      "zipCode": 24115,
      "latitude": 36.679571,
      "longitude": -79.865168,
      "city": "Martinsville",
      "state": "VA",
      "county": "Martinsville City"
  },
  {
      "zipCode": 24120,
      "latitude": 36.700218,
      "longitude": -80.353031,
      "city": "Meadows Of Dan",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24121,
      "latitude": 37.185024,
      "longitude": -79.563579,
      "city": "Moneta",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24122,
      "latitude": 37.419582,
      "longitude": -79.710746,
      "city": "Montvale",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24124,
      "latitude": 37.342026,
      "longitude": -80.762866,
      "city": "Narrows",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24126,
      "latitude": 37.069206,
      "longitude": -80.689067,
      "city": "Newbern",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24127,
      "latitude": 37.498957,
      "longitude": -80.138591,
      "city": "New Castle",
      "state": "VA",
      "county": "Craig"
  },
  {
      "zipCode": 24128,
      "latitude": 37.331516,
      "longitude": -80.523039,
      "city": "Newport",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24129,
      "latitude": 37.096403,
      "longitude": -80.608056,
      "city": "New River",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24130,
      "latitude": 37.555083,
      "longitude": -79.786151,
      "city": "Oriskany",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24131,
      "latitude": 37.488235,
      "longitude": -80.222319,
      "city": "Paint Bank",
      "state": "VA",
      "county": "Craig"
  },
  {
      "zipCode": 24132,
      "latitude": 37.204788,
      "longitude": -80.620517,
      "city": "Parrott",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24133,
      "latitude": 36.682193,
      "longitude": -80.136393,
      "city": "Patrick Springs",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24134,
      "latitude": 37.261147,
      "longitude": -80.74373,
      "city": "Pearisburg",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24136,
      "latitude": 37.35623,
      "longitude": -80.690234,
      "city": "Pembroke",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24137,
      "latitude": 36.934169,
      "longitude": -79.682503,
      "city": "Penhook",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24138,
      "latitude": 37.06488,
      "longitude": -80.32637,
      "city": "Pilot",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24139,
      "latitude": 36.995808,
      "longitude": -79.470112,
      "city": "Pittsville",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24141,
      "latitude": 37.119732,
      "longitude": -80.556541,
      "city": "Radford",
      "state": "VA",
      "county": "Radford"
  },
  {
      "zipCode": 24142,
      "latitude": 37.138733,
      "longitude": -80.54802,
      "city": "Radford",
      "state": "VA",
      "county": "Radford"
  },
  {
      "zipCode": 24143,
      "latitude": 37.122583,
      "longitude": -80.562913,
      "city": "Radford",
      "state": "VA",
      "county": "Radford"
  },
  {
      "zipCode": 24146,
      "latitude": 37.006912,
      "longitude": -79.913875,
      "city": "Redwood",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24147,
      "latitude": 37.363616,
      "longitude": -80.749864,
      "city": "Rich Creek",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24148,
      "latitude": 36.650725,
      "longitude": -79.854972,
      "city": "Ridgeway",
      "state": "VA",
      "county": "Henry"
  },
  {
      "zipCode": 24149,
      "latitude": 37.057894,
      "longitude": -80.436385,
      "city": "Riner",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24150,
      "latitude": 37.37067,
      "longitude": -80.662674,
      "city": "Ripplemead",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24151,
      "latitude": 36.995127,
      "longitude": -79.852489,
      "city": "Rocky Mount",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24153,
      "latitude": 37.28814,
      "longitude": -80.067762,
      "city": "Salem",
      "state": "VA",
      "county": "Salem"
  },
  {
      "zipCode": 24155,
      "latitude": 37.288384,
      "longitude": -80.067062,
      "city": "Salem",
      "state": "VA",
      "county": "Salem"
  },
  {
      "zipCode": 24156,
      "latitude": 37.288384,
      "longitude": -80.067062,
      "city": "Salem",
      "state": "VA",
      "county": "Salem"
  },
  {
      "zipCode": 24157,
      "latitude": 37.288384,
      "longitude": -80.067062,
      "city": "Salem",
      "state": "VA",
      "county": "Salem"
  },
  {
      "zipCode": 24161,
      "latitude": 36.982776,
      "longitude": -79.548523,
      "city": "Sandy Level",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24162,
      "latitude": 37.152878,
      "longitude": -80.263621,
      "city": "Shawsville",
      "state": "VA",
      "county": "Montgomery"
  },
  {
      "zipCode": 24165,
      "latitude": 36.593912,
      "longitude": -80.005233,
      "city": "Spencer",
      "state": "VA",
      "county": "Henry"
  },
  {
      "zipCode": 24167,
      "latitude": 37.314577,
      "longitude": -80.722619,
      "city": "Staffordsville",
      "state": "VA",
      "county": "Giles"
  },
  {
      "zipCode": 24168,
      "latitude": 36.688926,
      "longitude": -79.944745,
      "city": "Stanleytown",
      "state": "VA",
      "county": "Henry"
  },
  {
      "zipCode": 24171,
      "latitude": 36.689183,
      "longitude": -80.251601,
      "city": "Stuart",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24174,
      "latitude": 37.350092,
      "longitude": -79.69189,
      "city": "Thaxton",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24175,
      "latitude": 37.416128,
      "longitude": -79.924765,
      "city": "Troutville",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24176,
      "latitude": 37.014893,
      "longitude": -79.757636,
      "city": "Union Hall",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24177,
      "latitude": 36.724064,
      "longitude": -80.35806,
      "city": "Vesta",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24178,
      "latitude": 37.310494,
      "longitude": -79.789799,
      "city": "Villamont",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24179,
      "latitude": 37.275674,
      "longitude": -79.877505,
      "city": "Vinton",
      "state": "VA",
      "county": "Roanoke"
  },
  {
      "zipCode": 24184,
      "latitude": 37.104127,
      "longitude": -79.799315,
      "city": "Wirtz",
      "state": "VA",
      "county": "Franklin"
  },
  {
      "zipCode": 24185,
      "latitude": 36.741838,
      "longitude": -80.267112,
      "city": "Woolwine",
      "state": "VA",
      "county": "Patrick"
  },
  {
      "zipCode": 24201,
      "latitude": 36.613815,
      "longitude": -82.176238,
      "city": "Bristol",
      "state": "VA",
      "county": "Bristol"
  },
  {
      "zipCode": 24202,
      "latitude": 36.621571,
      "longitude": -82.167633,
      "city": "Bristol",
      "state": "VA",
      "county": "Bristol"
  },
  {
      "zipCode": 24203,
      "latitude": 36.761613,
      "longitude": -81.968712,
      "city": "Bristol",
      "state": "VA",
      "county": "Bristol"
  },
  {
      "zipCode": 24209,
      "latitude": 36.761613,
      "longitude": -81.968712,
      "city": "Bristol",
      "state": "VA",
      "county": "Bristol"
  },
  {
      "zipCode": 24210,
      "latitude": 36.740217,
      "longitude": -82.026738,
      "city": "Abingdon",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24211,
      "latitude": 36.66726,
      "longitude": -81.964801,
      "city": "Abingdon",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24212,
      "latitude": 36.690942,
      "longitude": -81.970764,
      "city": "Abingdon",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24215,
      "latitude": 36.923445,
      "longitude": -82.796445,
      "city": "Andover",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24216,
      "latitude": 36.940452,
      "longitude": -82.665879,
      "city": "Appalachia",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24217,
      "latitude": 37.130943,
      "longitude": -82.344881,
      "city": "Bee",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24218,
      "latitude": 36.743491,
      "longitude": -83.22336,
      "city": "Ben Hur",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24219,
      "latitude": 36.894806,
      "longitude": -82.654215,
      "city": "Big Stone Gap",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24220,
      "latitude": 37.130943,
      "longitude": -82.344881,
      "city": "Birchleaf",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24221,
      "latitude": 36.743491,
      "longitude": -83.22336,
      "city": "Blackwater",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24224,
      "latitude": 37.043978,
      "longitude": -81.855318,
      "city": "Castlewood",
      "state": "VA",
      "county": "Russell"
  },
  {
      "zipCode": 24225,
      "latitude": 36.925928,
      "longitude": -82.093586,
      "city": "Cleveland",
      "state": "VA",
      "county": "Russell"
  },
  {
      "zipCode": 24226,
      "latitude": 37.159677,
      "longitude": -82.358274,
      "city": "Clinchco",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24228,
      "latitude": 37.149797,
      "longitude": -82.463069,
      "city": "Clintwood",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24230,
      "latitude": 36.971586,
      "longitude": -82.475496,
      "city": "Coeburn",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24236,
      "latitude": 36.645463,
      "longitude": -81.742678,
      "city": "Damascus",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24237,
      "latitude": 36.925928,
      "longitude": -82.093586,
      "city": "Dante",
      "state": "VA",
      "county": "Russell"
  },
  {
      "zipCode": 24239,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Davenport",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24243,
      "latitude": 36.771579,
      "longitude": -82.927728,
      "city": "Dryden",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24244,
      "latitude": 36.711382,
      "longitude": -82.833676,
      "city": "Duffield",
      "state": "VA",
      "county": "Scott"
  },
  {
      "zipCode": 24245,
      "latitude": 36.828738,
      "longitude": -82.471457,
      "city": "Dungannon",
      "state": "VA",
      "county": "Scott"
  },
  {
      "zipCode": 24246,
      "latitude": 36.864429,
      "longitude": -82.737469,
      "city": "East Stone Gap",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24248,
      "latitude": 36.743491,
      "longitude": -83.22336,
      "city": "Ewing",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24250,
      "latitude": 36.739474,
      "longitude": -82.639637,
      "city": "Fort Blackmore",
      "state": "VA",
      "county": "Scott"
  },
  {
      "zipCode": 24251,
      "latitude": 36.621664,
      "longitude": -82.542414,
      "city": "Gate City",
      "state": "VA",
      "county": "Scott"
  },
  {
      "zipCode": 24256,
      "latitude": 37.18399,
      "longitude": -82.323976,
      "city": "Haysi",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24258,
      "latitude": 36.739474,
      "longitude": -82.639637,
      "city": "Hiltons",
      "state": "VA",
      "county": "Scott"
  },
  {
      "zipCode": 24260,
      "latitude": 36.963056,
      "longitude": -82.01331,
      "city": "Honaker",
      "state": "VA",
      "county": "Russell"
  },
  {
      "zipCode": 24263,
      "latitude": 36.6885,
      "longitude": -83.114212,
      "city": "Jonesville",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24265,
      "latitude": 36.743491,
      "longitude": -83.22336,
      "city": "Keokee",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24266,
      "latitude": 36.908553,
      "longitude": -82.053655,
      "city": "Lebanon",
      "state": "VA",
      "county": "Russell"
  },
  {
      "zipCode": 24269,
      "latitude": 37.130943,
      "longitude": -82.344881,
      "city": "Mc Clure",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24270,
      "latitude": 36.721945,
      "longitude": -82.256139,
      "city": "Mendota",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24271,
      "latitude": 36.762954,
      "longitude": -82.399015,
      "city": "Nickelsville",
      "state": "VA",
      "county": "Scott"
  },
  {
      "zipCode": 24272,
      "latitude": 37.130943,
      "longitude": -82.344881,
      "city": "Nora",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24273,
      "latitude": 36.93767,
      "longitude": -82.622624,
      "city": "Norton",
      "state": "VA",
      "county": "Norton City"
  },
  {
      "zipCode": 24277,
      "latitude": 36.75823,
      "longitude": -83.032536,
      "city": "Pennington Gap",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24279,
      "latitude": 37.088727,
      "longitude": -82.623231,
      "city": "Pound",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24280,
      "latitude": 36.95095,
      "longitude": -81.964938,
      "city": "Rosedale",
      "state": "VA",
      "county": "Russell"
  },
  {
      "zipCode": 24281,
      "latitude": 36.743491,
      "longitude": -83.22336,
      "city": "Rose Hill",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24282,
      "latitude": 36.743491,
      "longitude": -83.22336,
      "city": "Saint Charles",
      "state": "VA",
      "county": "Lee"
  },
  {
      "zipCode": 24283,
      "latitude": 36.936767,
      "longitude": -82.364288,
      "city": "Saint Paul",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24285,
      "latitude": 36.950943,
      "longitude": -82.786715,
      "city": "Stonega",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24289,
      "latitude": 37.130943,
      "longitude": -82.344881,
      "city": "Trammel",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24290,
      "latitude": 36.613714,
      "longitude": -82.561825,
      "city": "Weber City",
      "state": "VA",
      "county": "Scott"
  },
  {
      "zipCode": 24292,
      "latitude": 36.608229,
      "longitude": -81.584934,
      "city": "Whitetop",
      "state": "VA",
      "county": "Grayson"
  },
  {
      "zipCode": 24293,
      "latitude": 37.002843,
      "longitude": -82.570697,
      "city": "Wise",
      "state": "VA",
      "county": "Wise"
  },
  {
      "zipCode": 24301,
      "latitude": 37.064714,
      "longitude": -80.771241,
      "city": "Pulaski",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24311,
      "latitude": 36.869374,
      "longitude": -81.399235,
      "city": "Atkins",
      "state": "VA",
      "county": "Smyth"
  },
  {
      "zipCode": 24312,
      "latitude": 36.854655,
      "longitude": -80.922512,
      "city": "Austinville",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24313,
      "latitude": 36.897395,
      "longitude": -80.789443,
      "city": "Barren Springs",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24314,
      "latitude": 37.127348,
      "longitude": -81.15842,
      "city": "Bastian",
      "state": "VA",
      "county": "Bland"
  },
  {
      "zipCode": 24315,
      "latitude": 37.127348,
      "longitude": -81.15842,
      "city": "Bland",
      "state": "VA",
      "county": "Bland"
  },
  {
      "zipCode": 24316,
      "latitude": 36.941611,
      "longitude": -81.620331,
      "city": "Broadford",
      "state": "VA",
      "county": "Smyth"
  },
  {
      "zipCode": 24317,
      "latitude": 36.664788,
      "longitude": -80.729669,
      "city": "Cana",
      "state": "VA",
      "county": "Carroll"
  },
  {
      "zipCode": 24318,
      "latitude": 37.127348,
      "longitude": -81.15842,
      "city": "Ceres",
      "state": "VA",
      "county": "Bland"
  },
  {
      "zipCode": 24319,
      "latitude": 36.798213,
      "longitude": -81.617482,
      "city": "Chilhowie",
      "state": "VA",
      "county": "Smyth"
  },
  {
      "zipCode": 24322,
      "latitude": 36.81781,
      "longitude": -81.111146,
      "city": "Cripple Creek",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24323,
      "latitude": 36.871709,
      "longitude": -81.198867,
      "city": "Crockett",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24324,
      "latitude": 36.981684,
      "longitude": -80.774844,
      "city": "Draper",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24325,
      "latitude": 36.812134,
      "longitude": -80.58895,
      "city": "Dugspur",
      "state": "VA",
      "county": "Carroll"
  },
  {
      "zipCode": 24326,
      "latitude": 36.718676,
      "longitude": -81.187968,
      "city": "Elk Creek",
      "state": "VA",
      "county": "Grayson"
  },
  {
      "zipCode": 24327,
      "latitude": 36.779961,
      "longitude": -81.817064,
      "city": "Emory",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24328,
      "latitude": 36.667972,
      "longitude": -80.675229,
      "city": "Fancy Gap",
      "state": "VA",
      "county": "Carroll"
  },
  {
      "zipCode": 24330,
      "latitude": 36.712696,
      "longitude": -81.042703,
      "city": "Fries",
      "state": "VA",
      "county": "Grayson"
  },
  {
      "zipCode": 24333,
      "latitude": 36.660281,
      "longitude": -80.913605,
      "city": "Galax",
      "state": "VA",
      "county": "Galax City"
  },
  {
      "zipCode": 24340,
      "latitude": 36.74578,
      "longitude": -81.773242,
      "city": "Glade Spring",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24343,
      "latitude": 36.730347,
      "longitude": -80.67507,
      "city": "Hillsville",
      "state": "VA",
      "county": "Carroll"
  },
  {
      "zipCode": 24347,
      "latitude": 36.975578,
      "longitude": -80.669513,
      "city": "Hiwassee",
      "state": "VA",
      "county": "Pulaski"
  },
  {
      "zipCode": 24348,
      "latitude": 36.638835,
      "longitude": -81.194296,
      "city": "Independence",
      "state": "VA",
      "county": "Grayson"
  },
  {
      "zipCode": 24350,
      "latitude": 36.915513,
      "longitude": -81.059955,
      "city": "Ivanhoe",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24351,
      "latitude": 36.58637,
      "longitude": -80.759966,
      "city": "Lambsburg",
      "state": "VA",
      "county": "Carroll"
  },
  {
      "zipCode": 24352,
      "latitude": 36.711129,
      "longitude": -80.535146,
      "city": "Laurel Fork",
      "state": "VA",
      "county": "Carroll"
  },
  {
      "zipCode": 24354,
      "latitude": 36.832324,
      "longitude": -81.597841,
      "city": "Marion",
      "state": "VA",
      "county": "Smyth"
  },
  {
      "zipCode": 24360,
      "latitude": 36.905629,
      "longitude": -80.924423,
      "city": "Max Meadows",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24361,
      "latitude": 36.752637,
      "longitude": -81.848288,
      "city": "Meadowview",
      "state": "VA",
      "county": "Washington"
  },
  {
      "zipCode": 24363,
      "latitude": 36.657529,
      "longitude": -81.367509,
      "city": "Mouth Of Wilson",
      "state": "VA",
      "county": "Grayson"
  },
  {
      "zipCode": 24366,
      "latitude": 37.127348,
      "longitude": -81.15842,
      "city": "Rocky Gap",
      "state": "VA",
      "county": "Bland"
  },
  {
      "zipCode": 24368,
      "latitude": 36.880988,
      "longitude": -81.25526,
      "city": "Rural Retreat",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24370,
      "latitude": 36.906696,
      "longitude": -81.645388,
      "city": "Saltville",
      "state": "VA",
      "county": "Smyth"
  },
  {
      "zipCode": 24373,
      "latitude": 36.826371,
      "longitude": -81.549231,
      "city": "Seven Mile Ford",
      "state": "VA",
      "county": "Smyth"
  },
  {
      "zipCode": 24374,
      "latitude": 36.802255,
      "longitude": -81.206262,
      "city": "Speedwell",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24375,
      "latitude": 36.787066,
      "longitude": -81.388192,
      "city": "Sugar Grove",
      "state": "VA",
      "county": "Smyth"
  },
  {
      "zipCode": 24377,
      "latitude": 37.006018,
      "longitude": -81.570038,
      "city": "Tannersville",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24378,
      "latitude": 36.688587,
      "longitude": -81.361748,
      "city": "Trout Dale",
      "state": "VA",
      "county": "Grayson"
  },
  {
      "zipCode": 24379,
      "latitude": 36.682782,
      "longitude": -81.257795,
      "city": "Volney",
      "state": "VA",
      "county": "Grayson"
  },
  {
      "zipCode": 24380,
      "latitude": 36.881393,
      "longitude": -80.504784,
      "city": "Willis",
      "state": "VA",
      "county": "Floyd"
  },
  {
      "zipCode": 24381,
      "latitude": 36.694308,
      "longitude": -80.776569,
      "city": "Woodlawn",
      "state": "VA",
      "county": "Carroll"
  },
  {
      "zipCode": 24382,
      "latitude": 36.92855,
      "longitude": -81.146559,
      "city": "Wytheville",
      "state": "VA",
      "county": "Wythe"
  },
  {
      "zipCode": 24401,
      "latitude": 38.157368,
      "longitude": -79.065116,
      "city": "Staunton",
      "state": "VA",
      "county": "Staunton City"
  },
  {
      "zipCode": 24402,
      "latitude": 38.159275,
      "longitude": -79.062858,
      "city": "Staunton",
      "state": "VA",
      "county": "Staunton City"
  },
  {
      "zipCode": 24407,
      "latitude": 38.179715,
      "longitude": -79.141332,
      "city": "Staunton",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24411,
      "latitude": 38.091705,
      "longitude": -79.322363,
      "city": "Augusta Springs",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24412,
      "latitude": 38.055311,
      "longitude": -79.746367,
      "city": "Bacova",
      "state": "VA",
      "county": "Bath"
  },
  {
      "zipCode": 24413,
      "latitude": 38.385967,
      "longitude": -79.559157,
      "city": "Blue Grass",
      "state": "VA",
      "county": "Highland"
  },
  {
      "zipCode": 24415,
      "latitude": 37.926477,
      "longitude": -79.329162,
      "city": "Brownsburg",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24416,
      "latitude": 37.730001,
      "longitude": -79.355121,
      "city": "Buena Vista",
      "state": "VA",
      "county": "Buena Vista City"
  },
  {
      "zipCode": 24421,
      "latitude": 38.243395,
      "longitude": -79.173229,
      "city": "Churchville",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24422,
      "latitude": 37.824232,
      "longitude": -79.823119,
      "city": "Clifton Forge",
      "state": "VA",
      "county": "Clifton Forge City"
  },
  {
      "zipCode": 24426,
      "latitude": 37.780216,
      "longitude": -79.986963,
      "city": "Covington",
      "state": "VA",
      "county": "Covington City"
  },
  {
      "zipCode": 24430,
      "latitude": 38.073868,
      "longitude": -79.359123,
      "city": "Craigsville",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24431,
      "latitude": 38.153856,
      "longitude": -78.860085,
      "city": "Crimora",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24432,
      "latitude": 38.139476,
      "longitude": -79.373366,
      "city": "Deerfield",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24433,
      "latitude": 38.385967,
      "longitude": -79.559157,
      "city": "Doe Hill",
      "state": "VA",
      "county": "Highland"
  },
  {
      "zipCode": 24435,
      "latitude": 37.875396,
      "longitude": -79.298708,
      "city": "Fairfield",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24437,
      "latitude": 38.2064,
      "longitude": -78.941138,
      "city": "Fort Defiance",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24438,
      "latitude": 37.555083,
      "longitude": -79.786151,
      "city": "Glen Wilton",
      "state": "VA",
      "county": "Botetourt"
  },
  {
      "zipCode": 24439,
      "latitude": 37.978313,
      "longitude": -79.503429,
      "city": "Goshen",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24440,
      "latitude": 37.983435,
      "longitude": -79.175646,
      "city": "Greenville",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24441,
      "latitude": 38.272465,
      "longitude": -78.805841,
      "city": "Grottoes",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 24442,
      "latitude": 38.385967,
      "longitude": -79.559157,
      "city": "Head Waters",
      "state": "VA",
      "county": "Highland"
  },
  {
      "zipCode": 24445,
      "latitude": 37.994443,
      "longitude": -79.831593,
      "city": "Hot Springs",
      "state": "VA",
      "county": "Bath"
  },
  {
      "zipCode": 24448,
      "latitude": 37.796454,
      "longitude": -79.785987,
      "city": "Iron Gate",
      "state": "VA",
      "county": "Alleghany"
  },
  {
      "zipCode": 24450,
      "latitude": 37.779976,
      "longitude": -79.443495,
      "city": "Lexington",
      "state": "VA",
      "county": "Lexington City"
  },
  {
      "zipCode": 24457,
      "latitude": 37.780396,
      "longitude": -79.88283,
      "city": "Low Moor",
      "state": "VA",
      "county": "Alleghany"
  },
  {
      "zipCode": 24458,
      "latitude": 38.385967,
      "longitude": -79.559157,
      "city": "Mc Dowell",
      "state": "VA",
      "county": "Highland"
  },
  {
      "zipCode": 24459,
      "latitude": 38.042838,
      "longitude": -79.242427,
      "city": "Middlebrook",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24460,
      "latitude": 38.055311,
      "longitude": -79.746367,
      "city": "Millboro",
      "state": "VA",
      "county": "Bath"
  },
  {
      "zipCode": 24463,
      "latitude": 38.179715,
      "longitude": -79.141332,
      "city": "Mint Spring",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24464,
      "latitude": 37.865358,
      "longitude": -79.0804,
      "city": "Montebello",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 24465,
      "latitude": 38.385967,
      "longitude": -79.559157,
      "city": "Monterey",
      "state": "VA",
      "county": "Highland"
  },
  {
      "zipCode": 24467,
      "latitude": 38.242038,
      "longitude": -78.96826,
      "city": "Mount Sidney",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24468,
      "latitude": 38.385967,
      "longitude": -79.559157,
      "city": "Mustoe",
      "state": "VA",
      "county": "Highland"
  },
  {
      "zipCode": 24469,
      "latitude": 38.179715,
      "longitude": -79.141332,
      "city": "New Hope",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24471,
      "latitude": 38.322592,
      "longitude": -78.788649,
      "city": "Port Republic",
      "state": "VA",
      "county": "Rockingham"
  },
  {
      "zipCode": 24472,
      "latitude": 37.941296,
      "longitude": -79.276332,
      "city": "Raphine",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24473,
      "latitude": 37.883036,
      "longitude": -79.448586,
      "city": "Rockbridge Baths",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24474,
      "latitude": 37.806241,
      "longitude": -79.840421,
      "city": "Selma",
      "state": "VA",
      "county": "Alleghany"
  },
  {
      "zipCode": 24476,
      "latitude": 38.179715,
      "longitude": -79.141332,
      "city": "Steeles Tavern",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24477,
      "latitude": 37.973609,
      "longitude": -79.065951,
      "city": "Stuarts Draft",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24479,
      "latitude": 38.188328,
      "longitude": -79.211901,
      "city": "Swoope",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24482,
      "latitude": 38.210585,
      "longitude": -78.989369,
      "city": "Verona",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24483,
      "latitude": 37.859217,
      "longitude": -79.230686,
      "city": "Vesuvius",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24484,
      "latitude": 38.055311,
      "longitude": -79.746367,
      "city": "Warm Springs",
      "state": "VA",
      "county": "Bath"
  },
  {
      "zipCode": 24485,
      "latitude": 38.174227,
      "longitude": -79.209622,
      "city": "West Augusta",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24486,
      "latitude": 38.282326,
      "longitude": -78.946136,
      "city": "Weyers Cave",
      "state": "VA",
      "county": "Augusta"
  },
  {
      "zipCode": 24487,
      "latitude": 38.055311,
      "longitude": -79.746367,
      "city": "Williamsville",
      "state": "VA",
      "county": "Bath"
  },
  {
      "zipCode": 24501,
      "latitude": 37.383112,
      "longitude": -79.178326,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24502,
      "latitude": 37.382542,
      "longitude": -79.218121,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24503,
      "latitude": 37.408091,
      "longitude": -79.209247,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24504,
      "latitude": 37.405242,
      "longitude": -79.125261,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24505,
      "latitude": 37.400944,
      "longitude": -79.178506,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24506,
      "latitude": 37.381692,
      "longitude": -79.161046,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24512,
      "latitude": 37.242503,
      "longitude": -79.109789,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24513,
      "latitude": 37.245798,
      "longitude": -79.133546,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24514,
      "latitude": 37.400944,
      "longitude": -79.178506,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24515,
      "latitude": 37.400944,
      "longitude": -79.178506,
      "city": "Lynchburg",
      "state": "VA",
      "county": "Lynchburg City"
  },
  {
      "zipCode": 24517,
      "latitude": 37.225543,
      "longitude": -79.24118,
      "city": "Altavista",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24520,
      "latitude": 36.596827,
      "longitude": -79.043054,
      "city": "Alton",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24521,
      "latitude": 37.59162,
      "longitude": -79.108441,
      "city": "Amherst",
      "state": "VA",
      "county": "Amherst"
  },
  {
      "zipCode": 24522,
      "latitude": 37.356134,
      "longitude": -78.848202,
      "city": "Appomattox",
      "state": "VA",
      "county": "Appomattox"
  },
  {
      "zipCode": 24523,
      "latitude": 37.336027,
      "longitude": -79.522585,
      "city": "Bedford",
      "state": "VA",
      "county": "Bedford City"
  },
  {
      "zipCode": 24526,
      "latitude": 37.516659,
      "longitude": -79.38752,
      "city": "Big Island",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24527,
      "latitude": 36.710176,
      "longitude": -79.3381,
      "city": "Blairs",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24528,
      "latitude": 37.117502,
      "longitude": -79.074279,
      "city": "Brookneal",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24529,
      "latitude": 36.61405,
      "longitude": -78.641645,
      "city": "Buffalo Junction",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 24530,
      "latitude": 36.785155,
      "longitude": -79.60708,
      "city": "Callands",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24531,
      "latitude": 36.830785,
      "longitude": -79.412735,
      "city": "Chatham",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24533,
      "latitude": 37.537969,
      "longitude": -78.946324,
      "city": "Clifford",
      "state": "VA",
      "county": "Amherst"
  },
  {
      "zipCode": 24534,
      "latitude": 36.875823,
      "longitude": -78.748218,
      "city": "Clover",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24535,
      "latitude": 36.611317,
      "longitude": -78.945519,
      "city": "Cluster Springs",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24536,
      "latitude": 37.496433,
      "longitude": -79.305838,
      "city": "Coleman Falls",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24538,
      "latitude": 37.296071,
      "longitude": -78.949355,
      "city": "Concord",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24539,
      "latitude": 36.866093,
      "longitude": -78.831701,
      "city": "Crystal Hill",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24540,
      "latitude": 36.603501,
      "longitude": -79.376228,
      "city": "Danville",
      "state": "VA",
      "county": "Danville City"
  },
  {
      "zipCode": 24541,
      "latitude": 36.577505,
      "longitude": -79.441637,
      "city": "Danville",
      "state": "VA",
      "county": "Danville City"
  },
  {
      "zipCode": 24543,
      "latitude": 36.592679,
      "longitude": -79.410983,
      "city": "Danville",
      "state": "VA",
      "county": "Danville City"
  },
  {
      "zipCode": 24544,
      "latitude": 36.592679,
      "longitude": -79.410983,
      "city": "Danville",
      "state": "VA",
      "county": "Danville City"
  },
  {
      "zipCode": 24549,
      "latitude": 36.704919,
      "longitude": -79.502331,
      "city": "Dry Fork",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24550,
      "latitude": 37.243659,
      "longitude": -79.270958,
      "city": "Evington",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24551,
      "latitude": 37.372437,
      "longitude": -79.318718,
      "city": "Forest",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24553,
      "latitude": 37.58939,
      "longitude": -78.860834,
      "city": "Gladstone",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 24554,
      "latitude": 37.148585,
      "longitude": -79.111743,
      "city": "Gladys",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24555,
      "latitude": 37.732638,
      "longitude": -79.525212,
      "city": "Glasgow",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24556,
      "latitude": 37.375464,
      "longitude": -79.403888,
      "city": "Goode",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24557,
      "latitude": 36.983015,
      "longitude": -79.321101,
      "city": "Gretna",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24558,
      "latitude": 36.783814,
      "longitude": -78.964257,
      "city": "Halifax",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24562,
      "latitude": 37.751112,
      "longitude": -78.631326,
      "city": "Howardsville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 24563,
      "latitude": 37.038301,
      "longitude": -79.36393,
      "city": "Hurt",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24565,
      "latitude": 36.846278,
      "longitude": -79.207927,
      "city": "Java",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24566,
      "latitude": 36.721431,
      "longitude": -79.255522,
      "city": "Keeling",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24569,
      "latitude": 37.093523,
      "longitude": -79.081614,
      "city": "Long Island",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24570,
      "latitude": 37.333936,
      "longitude": -79.406303,
      "city": "Lowry",
      "state": "VA",
      "county": "Bedford"
  },
  {
      "zipCode": 24571,
      "latitude": 37.141308,
      "longitude": -79.359182,
      "city": "Lynch Station",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24572,
      "latitude": 37.475715,
      "longitude": -79.140618,
      "city": "Madison Heights",
      "state": "VA",
      "county": "Amherst"
  },
  {
      "zipCode": 24574,
      "latitude": 37.544326,
      "longitude": -79.244826,
      "city": "Monroe",
      "state": "VA",
      "county": "Amherst"
  },
  {
      "zipCode": 24576,
      "latitude": 37.245798,
      "longitude": -79.133546,
      "city": "Naruna",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24577,
      "latitude": 36.811672,
      "longitude": -78.962546,
      "city": "Nathalie",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24578,
      "latitude": 37.677211,
      "longitude": -79.550792,
      "city": "Natural Bridge",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24579,
      "latitude": 37.59797,
      "longitude": -79.525047,
      "city": "Natural Bridge Station",
      "state": "VA",
      "county": "Rockbridge"
  },
  {
      "zipCode": 24580,
      "latitude": 36.589478,
      "longitude": -78.67745,
      "city": "Nelson",
      "state": "VA",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 24581,
      "latitude": 37.640813,
      "longitude": -78.803767,
      "city": "Norwood",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 24585,
      "latitude": 36.801515,
      "longitude": -78.883933,
      "city": "Republican Grove",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24586,
      "latitude": 36.6136,
      "longitude": -79.276568,
      "city": "Ringgold",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24588,
      "latitude": 37.283924,
      "longitude": -79.090832,
      "city": "Rustburg",
      "state": "VA",
      "county": "Campbell"
  },
  {
      "zipCode": 24589,
      "latitude": 36.777629,
      "longitude": -78.778726,
      "city": "Scottsburg",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24590,
      "latitude": 37.846277,
      "longitude": -78.529827,
      "city": "Scottsville",
      "state": "VA",
      "county": "Albemarle"
  },
  {
      "zipCode": 24592,
      "latitude": 36.679767,
      "longitude": -78.952947,
      "city": "South Boston",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24593,
      "latitude": 37.344169,
      "longitude": -78.918241,
      "city": "Spout Spring",
      "state": "VA",
      "county": "Appomattox"
  },
  {
      "zipCode": 24594,
      "latitude": 36.668449,
      "longitude": -79.355493,
      "city": "Sutherlin",
      "state": "VA",
      "county": "Pittsylvania"
  },
  {
      "zipCode": 24595,
      "latitude": 37.567427,
      "longitude": -79.075617,
      "city": "Sweet Briar",
      "state": "VA",
      "county": "Amherst"
  },
  {
      "zipCode": 24597,
      "latitude": 36.838178,
      "longitude": -79.067906,
      "city": "Vernon Hill",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24598,
      "latitude": 36.616452,
      "longitude": -78.798686,
      "city": "Virgilina",
      "state": "VA",
      "county": "Halifax"
  },
  {
      "zipCode": 24599,
      "latitude": 37.682224,
      "longitude": -78.731156,
      "city": "Wingina",
      "state": "VA",
      "county": "Nelson"
  },
  {
      "zipCode": 24601,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Amonate",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24602,
      "latitude": 36.999484,
      "longitude": -81.79514,
      "city": "Bandy",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24603,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Big Rock",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24604,
      "latitude": 37.203317,
      "longitude": -81.557499,
      "city": "Bishop",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24605,
      "latitude": 37.172408,
      "longitude": -81.507054,
      "city": "Bluefield",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24606,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Boissevain",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24607,
      "latitude": 37.130943,
      "longitude": -82.344881,
      "city": "Breaks",
      "state": "VA",
      "county": "Dickenson"
  },
  {
      "zipCode": 24608,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Burkes Garden",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24609,
      "latitude": 37.123379,
      "longitude": -81.54705,
      "city": "Cedar Bluff",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24612,
      "latitude": 37.094523,
      "longitude": -81.818037,
      "city": "Doran",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24613,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Falls Mills",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24614,
      "latitude": 37.257171,
      "longitude": -82.100073,
      "city": "Grundy",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24618,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Harman",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24619,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Horsepen",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24620,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Hurley",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24622,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Jewell Ridge",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24624,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Keen Mountain",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24627,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Mavisdale",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24628,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Maxie",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24630,
      "latitude": 37.175577,
      "longitude": -81.539856,
      "city": "North Tazewell",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24631,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Oakwood",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24634,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Pilgrims Knob",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24635,
      "latitude": 37.305417,
      "longitude": -81.34246,
      "city": "Pocahontas",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24637,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Pounding Mill",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24639,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Raven",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24640,
      "latitude": 37.13549,
      "longitude": -81.563406,
      "city": "Red Ash",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24641,
      "latitude": 37.186196,
      "longitude": -81.554668,
      "city": "Richlands",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24646,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Rowe",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24647,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Shortt Gap",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24649,
      "latitude": 37.045445,
      "longitude": -81.9271,
      "city": "Swords Creek",
      "state": "VA",
      "county": "Russell"
  },
  {
      "zipCode": 24651,
      "latitude": 37.174459,
      "longitude": -81.416087,
      "city": "Tazewell",
      "state": "VA",
      "county": "Tazewell"
  },
  {
      "zipCode": 24656,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Vansant",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24657,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Whitewood",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24658,
      "latitude": 37.287992,
      "longitude": -82.027408,
      "city": "Wolford",
      "state": "VA",
      "county": "Buchanan"
  },
  {
      "zipCode": 24701,
      "latitude": 37.332725,
      "longitude": -81.160066,
      "city": "Bluefield",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24712,
      "latitude": 37.452346,
      "longitude": -81.00916,
      "city": "Athens",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24714,
      "latitude": 37.460069,
      "longitude": -81.160053,
      "city": "Beeson",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24715,
      "latitude": 37.343532,
      "longitude": -81.325281,
      "city": "Bramwell",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24716,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Bud",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24719,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Covel",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24724,
      "latitude": 37.333273,
      "longitude": -81.311849,
      "city": "Freeman",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24726,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Herndon",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24729,
      "latitude": 37.416395,
      "longitude": -81.105803,
      "city": "Hiawatha",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24731,
      "latitude": 37.416395,
      "longitude": -81.105803,
      "city": "Kegley",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24732,
      "latitude": 37.416395,
      "longitude": -81.105803,
      "city": "Kellysville",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24733,
      "latitude": 37.440745,
      "longitude": -81.190841,
      "city": "Lashmeet",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24736,
      "latitude": 37.416393,
      "longitude": -81.202217,
      "city": "Matoaka",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24737,
      "latitude": 37.35391,
      "longitude": -81.251807,
      "city": "Montcalm",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24738,
      "latitude": 37.416395,
      "longitude": -81.105803,
      "city": "Nemours",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24739,
      "latitude": 37.416395,
      "longitude": -81.105803,
      "city": "Oakvale",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24740,
      "latitude": 37.379876,
      "longitude": -81.117475,
      "city": "Princeton",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24747,
      "latitude": 37.414224,
      "longitude": -81.178417,
      "city": "Rock",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24751,
      "latitude": 37.416395,
      "longitude": -81.105803,
      "city": "Wolfe",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 24801,
      "latitude": 37.411086,
      "longitude": -81.539352,
      "city": "Welch",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24808,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Anawalt",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24811,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Avondale",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24813,
      "latitude": 37.343847,
      "longitude": -81.683131,
      "city": "Bartley",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24815,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Berwind",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24816,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Big Sandy",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24817,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Bradshaw",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24818,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Brenton",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24820,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Capels",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24821,
      "latitude": 37.38297,
      "longitude": -81.634292,
      "city": "Caretta",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24822,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Clear Fork",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24823,
      "latitude": 37.626772,
      "longitude": -81.702061,
      "city": "Coal Mountain",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24824,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Coalwood",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24825,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Crumpler",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24826,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Cucumber",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24827,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Cyclone",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24828,
      "latitude": 37.467849,
      "longitude": -81.681104,
      "city": "Davy",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24829,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Eckman",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24830,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Elbert",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24831,
      "latitude": 37.432867,
      "longitude": -81.570983,
      "city": "Elkhorn",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24832,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "English",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24834,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Fanrock",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24836,
      "latitude": 37.36584,
      "longitude": -81.554444,
      "city": "Gary",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24839,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Hanover",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24841,
      "latitude": 37.405828,
      "longitude": -81.576548,
      "city": "Havaco",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24842,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Hemphill",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24843,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Hensley",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24844,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Iaeger",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24845,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Ikes Fork",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24846,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Isaban",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24847,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Itmann",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24848,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Jenkinjones",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24849,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Jesse",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24850,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Jolo",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24851,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Justice",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 24852,
      "latitude": 37.414357,
      "longitude": -81.431783,
      "city": "Keystone",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24853,
      "latitude": 37.442086,
      "longitude": -81.511965,
      "city": "Kimball",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24854,
      "latitude": 37.743967,
      "longitude": -81.574986,
      "city": "Kopperston",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24855,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Kyle",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24856,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Leckie",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24857,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Lynco",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24859,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Marianna",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24860,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Matheny",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24861,
      "latitude": 37.417549,
      "longitude": -81.423979,
      "city": "Maybeury",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24862,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Mohawk",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24866,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Newhall",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24867,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "New Richmond",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24868,
      "latitude": 37.389939,
      "longitude": -81.419578,
      "city": "Northfork",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24869,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "North Spring",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24870,
      "latitude": 37.74071,
      "longitude": -81.583069,
      "city": "Oceana",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24871,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Pageton",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24872,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Panther",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24873,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Paynesville",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24874,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Pineville",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24877,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Powhatan",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24878,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Premier",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24879,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Raysal",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24880,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Rock View",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24881,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Roderfield",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24882,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Simon",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24883,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Skygusty",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24884,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Squire",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24887,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Switchback",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24888,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Thorpe",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24889,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Twin Branch",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24892,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "War",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24894,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Warriormine",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24895,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Wilcoe",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24896,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Wolf Pen",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24897,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Worth",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24898,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Wyoming",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 24899,
      "latitude": 37.375246,
      "longitude": -81.653889,
      "city": "Yukon",
      "state": "WV",
      "county": "Mcdowell"
  },
  {
      "zipCode": 24901,
      "latitude": 37.844155,
      "longitude": -80.538781,
      "city": "Lewisburg",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24902,
      "latitude": 37.774666,
      "longitude": -80.461492,
      "city": "Fairlea",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24910,
      "latitude": 37.84394,
      "longitude": -80.572401,
      "city": "Alderson",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24915,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Arbovale",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24916,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Asbury",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24917,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Auto",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24918,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Ballard",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24919,
      "latitude": 37.674782,
      "longitude": -80.890043,
      "city": "Ballengee",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 24920,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Bartow",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24924,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Buckeye",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24925,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Caldwell",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24927,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Cass",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24931,
      "latitude": 37.918876,
      "longitude": -80.611705,
      "city": "Crawley",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24934,
      "latitude": 38.326214,
      "longitude": -79.898674,
      "city": "Dunmore",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24935,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Forest Hill",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 24936,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Fort Spring",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24938,
      "latitude": 37.915363,
      "longitude": -80.335257,
      "city": "Frankford",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24941,
      "latitude": 37.58902,
      "longitude": -80.340084,
      "city": "Gap Mills",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24942,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Glace",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24943,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Grassy Meadows",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24944,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Green Bank",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24945,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Greenville",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24946,
      "latitude": 38.110904,
      "longitude": -80.264059,
      "city": "Hillsboro",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24950,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Kieffer",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24951,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Lindside",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24954,
      "latitude": 38.258638,
      "longitude": -80.104625,
      "city": "Marlinton",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 24957,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Maxwelton",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24958,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Meadow Bluff",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24961,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Neola",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24962,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Pence Springs",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 24963,
      "latitude": 37.399956,
      "longitude": -80.797806,
      "city": "Peterstown",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24966,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Renick",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24970,
      "latitude": 37.744709,
      "longitude": -80.454189,
      "city": "Ronceverte",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24974,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Secondcreek",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24976,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Sinks Grove",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24977,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Smoot",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24981,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Talcott",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 24983,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Union",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24984,
      "latitude": 37.489872,
      "longitude": -80.420487,
      "city": "Waiteville",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24985,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Wayside",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 24986,
      "latitude": 37.782726,
      "longitude": -80.312877,
      "city": "White Sulphur Springs",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24991,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Williamsburg",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 24993,
      "latitude": 37.552029,
      "longitude": -80.539672,
      "city": "Wolfcreek",
      "state": "WV",
      "county": "Monroe"
  },
  {
      "zipCode": 25002,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Alloy",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25003,
      "latitude": 38.243675,
      "longitude": -81.771226,
      "city": "Alum Creek",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25004,
      "latitude": 37.784459,
      "longitude": -81.118491,
      "city": "Ameagle",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25005,
      "latitude": 38.591037,
      "longitude": -81.273681,
      "city": "Amma",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25007,
      "latitude": 37.82156,
      "longitude": -81.409586,
      "city": "Arnett",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25008,
      "latitude": 37.940013,
      "longitude": -81.354496,
      "city": "Artie",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25009,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Ashford",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25010,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Bald Knob",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25011,
      "latitude": 38.508559,
      "longitude": -81.841002,
      "city": "Bancroft",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25015,
      "latitude": 38.232406,
      "longitude": -81.502399,
      "city": "Belle",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25018,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Bentree",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25019,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Bickmore",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25021,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Bim",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25022,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Blair",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25024,
      "latitude": 38.154021,
      "longitude": -81.630599,
      "city": "Bloomingrose",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25025,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Blount",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25026,
      "latitude": 38.490789,
      "longitude": -81.392845,
      "city": "Blue Creek",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25028,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Bob White",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25030,
      "latitude": 38.387998,
      "longitude": -81.254838,
      "city": "Bomont",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25031,
      "latitude": 38.163248,
      "longitude": -81.307528,
      "city": "Boomer",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25033,
      "latitude": 38.610603,
      "longitude": -81.924758,
      "city": "Buffalo",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25035,
      "latitude": 38.193014,
      "longitude": -81.498415,
      "city": "Cabin Creek",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25036,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Cannelton",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25039,
      "latitude": 38.279199,
      "longitude": -81.59823,
      "city": "Cedar Grove",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25040,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Charlton Heights",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25043,
      "latitude": 38.467298,
      "longitude": -81.080031,
      "city": "Clay",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25044,
      "latitude": 37.880406,
      "longitude": -81.408766,
      "city": "Clear Creek",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25045,
      "latitude": 38.453862,
      "longitude": -81.357213,
      "city": "Clendenin",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25046,
      "latitude": 38.731737,
      "longitude": -81.314633,
      "city": "Clio",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25047,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Clothier",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25048,
      "latitude": 37.942986,
      "longitude": -81.402075,
      "city": "Colcord",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25049,
      "latitude": 38.126494,
      "longitude": -81.607191,
      "city": "Comfort",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25051,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Costa",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25053,
      "latitude": 38.01946,
      "longitude": -81.885268,
      "city": "Danville",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25054,
      "latitude": 38.073666,
      "longitude": -81.451066,
      "city": "Dawes",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25057,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Deep Water",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25059,
      "latitude": 38.254945,
      "longitude": -81.217588,
      "city": "Dixie",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 25060,
      "latitude": 37.949132,
      "longitude": -81.444561,
      "city": "Dorothy",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25061,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Drybranch",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25062,
      "latitude": 37.854572,
      "longitude": -81.453786,
      "city": "Dry Creek",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25063,
      "latitude": 38.582138,
      "longitude": -80.936783,
      "city": "Duck",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25064,
      "latitude": 38.348822,
      "longitude": -81.570048,
      "city": "Dunbar",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25067,
      "latitude": 38.209887,
      "longitude": -81.438053,
      "city": "East Bank",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25070,
      "latitude": 38.543573,
      "longitude": -81.941425,
      "city": "Eleanor",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25071,
      "latitude": 38.386942,
      "longitude": -81.450424,
      "city": "Elkview",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25075,
      "latitude": 38.178047,
      "longitude": -81.615909,
      "city": "Eskdale",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25076,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Ethel",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25079,
      "latitude": 38.495072,
      "longitude": -81.398422,
      "city": "Falling Rock",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25081,
      "latitude": 38.072562,
      "longitude": -81.825259,
      "city": "Foster",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25082,
      "latitude": 38.560972,
      "longitude": -82.022361,
      "city": "Fraziers Bottom",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25083,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Gallagher",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25085,
      "latitude": 38.156166,
      "longitude": -81.174906,
      "city": "Gauley Bridge",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25086,
      "latitude": 38.215547,
      "longitude": -81.406705,
      "city": "Glasgow",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25088,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Glen",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25090,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Glen Ferris",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25093,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Gordon",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25095,
      "latitude": 38.752312,
      "longitude": -81.997278,
      "city": "Grimms Landing",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25102,
      "latitude": 38.187888,
      "longitude": -81.3657,
      "city": "Handley",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25103,
      "latitude": 38.207048,
      "longitude": -81.39286,
      "city": "Hansford",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25106,
      "latitude": 38.833117,
      "longitude": -82.138543,
      "city": "Henderson",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25107,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Hernshaw",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25108,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Hewett",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25109,
      "latitude": 38.475369,
      "longitude": -81.880455,
      "city": "Hometown",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25110,
      "latitude": 38.21097,
      "longitude": -81.388446,
      "city": "Hugheston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25111,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Indore",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25112,
      "latitude": 38.282497,
      "longitude": -81.56514,
      "city": "Institute",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25113,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Ivydale",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25114,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Jeffrey",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25115,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Kanawha Falls",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25118,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Kimberly",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25119,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Kincaid",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25121,
      "latitude": 37.920381,
      "longitude": -81.903389,
      "city": "Lake",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25122,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Leewood",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25123,
      "latitude": 38.704805,
      "longitude": -81.914474,
      "city": "Leon",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25124,
      "latitude": 38.475369,
      "longitude": -81.880455,
      "city": "Liberty",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25125,
      "latitude": 38.334726,
      "longitude": -81.198102,
      "city": "Lizemores",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25126,
      "latitude": 38.195983,
      "longitude": -81.371367,
      "city": "London",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25130,
      "latitude": 38.063165,
      "longitude": -81.818718,
      "city": "Madison",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25132,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Mammoth",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25133,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Maysel",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25134,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Miami",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25136,
      "latitude": 38.172523,
      "longitude": -81.322175,
      "city": "Montgomery",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25139,
      "latitude": 38.139916,
      "longitude": -81.29309,
      "city": "Mount Carbon",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25140,
      "latitude": 37.877222,
      "longitude": -81.481192,
      "city": "Naoma",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25141,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Nebo",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25142,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Nellis",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25143,
      "latitude": 38.323042,
      "longitude": -81.616124,
      "city": "Nitro",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25147,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Ohley",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25148,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Orgas",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25149,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Ottawa",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25150,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Ovapa",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25152,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Page",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25154,
      "latitude": 38.136924,
      "longitude": -81.679052,
      "city": "Peytona",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25156,
      "latitude": 38.401962,
      "longitude": -81.461063,
      "city": "Pinch",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25159,
      "latitude": 38.479147,
      "longitude": -81.783795,
      "city": "Poca",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25160,
      "latitude": 38.290746,
      "longitude": -81.277293,
      "city": "Pond Gap",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25161,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Powellton",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25162,
      "latitude": 38.210104,
      "longitude": -81.385107,
      "city": "Pratt",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25164,
      "latitude": 38.491236,
      "longitude": -81.198904,
      "city": "Procious",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25165,
      "latitude": 38.140996,
      "longitude": -81.658829,
      "city": "Racine",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25168,
      "latitude": 38.555097,
      "longitude": -81.901394,
      "city": "Red House",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25169,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Ridgeview",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25173,
      "latitude": 38.076677,
      "longitude": -81.237015,
      "city": "Robson",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25174,
      "latitude": 37.854721,
      "longitude": -81.418351,
      "city": "Rock Creek",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25177,
      "latitude": 38.31461,
      "longitude": -81.636926,
      "city": "Saint Albans",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25180,
      "latitude": 37.793057,
      "longitude": -81.384755,
      "city": "Saxon",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25181,
      "latitude": 38.085024,
      "longitude": -81.622894,
      "city": "Seth",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25182,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Sharon",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25183,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Sharples",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25185,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Mount Olive",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25186,
      "latitude": 38.154338,
      "longitude": -81.278012,
      "city": "Smithers",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25187,
      "latitude": 38.725035,
      "longitude": -82.027474,
      "city": "Southside",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25193,
      "latitude": 38.010386,
      "longitude": -81.558467,
      "city": "Sylvester",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25201,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Tad",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25202,
      "latitude": 38.332069,
      "longitude": -81.8563,
      "city": "Tornado",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25203,
      "latitude": 37.986266,
      "longitude": -81.942704,
      "city": "Turtle Creek",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25204,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Twilight",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25205,
      "latitude": 38.042839,
      "longitude": -81.805265,
      "city": "Uneeda",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25206,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Van",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25208,
      "latitude": 37.997105,
      "longitude": -81.71704,
      "city": "Wharton",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25209,
      "latitude": 37.991591,
      "longitude": -81.542234,
      "city": "Whitesville",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25211,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Widen",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25213,
      "latitude": 38.50099,
      "longitude": -81.914048,
      "city": "Winfield",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25214,
      "latitude": 38.18983,
      "longitude": -81.565648,
      "city": "Winifrede",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25231,
      "latitude": 38.824924,
      "longitude": -81.70665,
      "city": "Advent",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25234,
      "latitude": 38.828582,
      "longitude": -81.132151,
      "city": "Arnoldsburg",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 25235,
      "latitude": 38.828582,
      "longitude": -81.132151,
      "city": "Chloe",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 25239,
      "latitude": 38.848369,
      "longitude": -81.847298,
      "city": "Cottageville",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25241,
      "latitude": 38.796245,
      "longitude": -81.807118,
      "city": "Evans",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25243,
      "latitude": 38.683119,
      "longitude": -81.453016,
      "city": "Gandeeville",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25244,
      "latitude": 38.824924,
      "longitude": -81.70665,
      "city": "Gay",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25245,
      "latitude": 38.70697,
      "longitude": -81.722296,
      "city": "Given",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25247,
      "latitude": 38.752312,
      "longitude": -81.997278,
      "city": "Hartford",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25248,
      "latitude": 38.623155,
      "longitude": -81.621667,
      "city": "Kenna",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25250,
      "latitude": 38.752312,
      "longitude": -81.997278,
      "city": "Lakin",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25251,
      "latitude": 38.731737,
      "longitude": -81.314633,
      "city": "Left Hand",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25252,
      "latitude": 38.824924,
      "longitude": -81.70665,
      "city": "Le Roy",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25253,
      "latitude": 38.989085,
      "longitude": -81.951871,
      "city": "Letart",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25256,
      "latitude": 38.731737,
      "longitude": -81.314633,
      "city": "Linden",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25258,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Lockney",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 25259,
      "latitude": 38.696744,
      "longitude": -81.233061,
      "city": "Looneyville",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25260,
      "latitude": 39.011467,
      "longitude": -82.016935,
      "city": "Mason",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25261,
      "latitude": 38.828582,
      "longitude": -81.132151,
      "city": "Millstone",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 25262,
      "latitude": 38.905595,
      "longitude": -81.835048,
      "city": "Millwood",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25264,
      "latitude": 38.824924,
      "longitude": -81.70665,
      "city": "Mount Alto",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25265,
      "latitude": 38.986319,
      "longitude": -81.965175,
      "city": "New Haven",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25266,
      "latitude": 38.731737,
      "longitude": -81.314633,
      "city": "Newton",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25267,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Normantown",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 25268,
      "latitude": 38.828582,
      "longitude": -81.132151,
      "city": "Orma",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 25270,
      "latitude": 38.888886,
      "longitude": -81.439879,
      "city": "Reedy",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25271,
      "latitude": 38.791845,
      "longitude": -81.728065,
      "city": "Ripley",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25275,
      "latitude": 38.889106,
      "longitude": -81.682596,
      "city": "Sandyville",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25276,
      "latitude": 38.777675,
      "longitude": -81.364262,
      "city": "Spencer",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25279,
      "latitude": 38.824924,
      "longitude": -81.70665,
      "city": "Statts Mills",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 25281,
      "latitude": 38.731737,
      "longitude": -81.314633,
      "city": "Tariff",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25283,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Valley Fork",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25285,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Wallback",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 25286,
      "latitude": 38.608737,
      "longitude": -81.419262,
      "city": "Walton",
      "state": "WV",
      "county": "Roane"
  },
  {
      "zipCode": 25287,
      "latitude": 38.752312,
      "longitude": -81.997278,
      "city": "West Columbia",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25301,
      "latitude": 38.328948,
      "longitude": -81.605094,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25302,
      "latitude": 38.401498,
      "longitude": -81.584087,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25303,
      "latitude": 38.352946,
      "longitude": -81.658246,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25304,
      "latitude": 38.305728,
      "longitude": -81.595903,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25305,
      "latitude": 38.335798,
      "longitude": -81.612344,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25306,
      "latitude": 38.317499,
      "longitude": -81.527433,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25309,
      "latitude": 38.310767,
      "longitude": -81.756808,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25311,
      "latitude": 38.371957,
      "longitude": -81.632845,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25312,
      "latitude": 38.455865,
      "longitude": -81.637823,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25313,
      "latitude": 38.414156,
      "longitude": -81.758172,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25314,
      "latitude": 38.32925,
      "longitude": -81.665927,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25315,
      "latitude": 38.235149,
      "longitude": -81.553608,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25317,
      "latitude": 38.335647,
      "longitude": -81.613794,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25320,
      "latitude": 38.542798,
      "longitude": -81.633378,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25321,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25322,
      "latitude": 38.534661,
      "longitude": -81.56035,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25323,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25324,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25325,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25326,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25327,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25328,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25329,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25330,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25331,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25332,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25333,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25334,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25335,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25336,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25337,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25338,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25339,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25350,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25356,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25357,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25358,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25360,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25361,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25362,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25364,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25365,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25375,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25387,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25389,
      "latitude": 38.354041,
      "longitude": -81.639389,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25392,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25396,
      "latitude": 38.296818,
      "longitude": -81.554655,
      "city": "Charleston",
      "state": "WV",
      "county": "Kanawha"
  },
  {
      "zipCode": 25401,
      "latitude": 39.444061,
      "longitude": -77.951924,
      "city": "Martinsburg",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25402,
      "latitude": 39.461663,
      "longitude": -78.011472,
      "city": "Martinsburg",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25410,
      "latitude": 39.315914,
      "longitude": -77.877223,
      "city": "Bakerton",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25411,
      "latitude": 39.552557,
      "longitude": -78.18773,
      "city": "Berkeley Springs",
      "state": "WV",
      "county": "Morgan"
  },
  {
      "zipCode": 25413,
      "latitude": 39.377876,
      "longitude": -78.064925,
      "city": "Bunker Hill",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25414,
      "latitude": 39.313538,
      "longitude": -77.860278,
      "city": "Charles Town",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25419,
      "latitude": 39.572966,
      "longitude": -77.882155,
      "city": "Falling Waters",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25420,
      "latitude": 39.384529,
      "longitude": -78.102777,
      "city": "Gerrardstown",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25421,
      "latitude": 39.381345,
      "longitude": -78.143466,
      "city": "Glengary",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25422,
      "latitude": 39.612559,
      "longitude": -78.391552,
      "city": "Great Cacapon",
      "state": "WV",
      "county": "Morgan"
  },
  {
      "zipCode": 25423,
      "latitude": 39.315914,
      "longitude": -77.877223,
      "city": "Halltown",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25425,
      "latitude": 39.314617,
      "longitude": -77.789387,
      "city": "Harpers Ferry",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25427,
      "latitude": 39.485043,
      "longitude": -78.034659,
      "city": "Hedgesville",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25428,
      "latitude": 39.362373,
      "longitude": -78.033065,
      "city": "Inwood",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25429,
      "latitude": 39.349586,
      "longitude": -77.878957,
      "city": "Kearneysville",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25430,
      "latitude": 39.344424,
      "longitude": -77.941833,
      "city": "Kearneysville",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25431,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Levels",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 25432,
      "latitude": 39.315914,
      "longitude": -77.877223,
      "city": "Millville",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25434,
      "latitude": 39.530566,
      "longitude": -78.45729,
      "city": "Paw Paw",
      "state": "WV",
      "county": "Morgan"
  },
  {
      "zipCode": 25437,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Points",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 25438,
      "latitude": 39.26006,
      "longitude": -77.831031,
      "city": "Ranson",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25440,
      "latitude": 39.442661,
      "longitude": -78.02628,
      "city": "Ridgeway",
      "state": "WV",
      "county": "Berkeley"
  },
  {
      "zipCode": 25441,
      "latitude": 39.315914,
      "longitude": -77.877223,
      "city": "Rippon",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25442,
      "latitude": 39.371605,
      "longitude": -77.822878,
      "city": "Shenandoah Junction",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25443,
      "latitude": 39.389587,
      "longitude": -77.838337,
      "city": "Shepherdstown",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25444,
      "latitude": 39.34125,
      "longitude": -78.754473,
      "city": "Slanesville",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 25446,
      "latitude": 39.224095,
      "longitude": -77.878788,
      "city": "Summit Point",
      "state": "WV",
      "county": "Jefferson"
  },
  {
      "zipCode": 25501,
      "latitude": 38.167112,
      "longitude": -81.968862,
      "city": "Alkol",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25502,
      "latitude": 38.657528,
      "longitude": -82.10679,
      "city": "Apple Grove",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25503,
      "latitude": 38.60323,
      "longitude": -82.112281,
      "city": "Ashton",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25504,
      "latitude": 38.379347,
      "longitude": -82.264954,
      "city": "Barboursville",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25505,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Big Creek",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25506,
      "latitude": 38.219908,
      "longitude": -82.145897,
      "city": "Branchland",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25507,
      "latitude": 38.384557,
      "longitude": -82.557785,
      "city": "Ceredo",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25508,
      "latitude": 37.96087,
      "longitude": -82.01076,
      "city": "Chapmanville",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25510,
      "latitude": 38.426471,
      "longitude": -82.110405,
      "city": "Culloden",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25511,
      "latitude": 38.132114,
      "longitude": -82.418277,
      "city": "Dunlow",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25512,
      "latitude": 38.271293,
      "longitude": -82.376703,
      "city": "East Lynn",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25514,
      "latitude": 38.072978,
      "longitude": -82.539781,
      "city": "Fort Gay",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25515,
      "latitude": 38.738211,
      "longitude": -82.155965,
      "city": "Gallipolis Ferry",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25517,
      "latitude": 38.132114,
      "longitude": -82.418277,
      "city": "Genoa",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25519,
      "latitude": 38.132114,
      "longitude": -82.418277,
      "city": "Glenhayes",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25520,
      "latitude": 38.590868,
      "longitude": -82.177263,
      "city": "Glenwood",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25521,
      "latitude": 38.246211,
      "longitude": -81.960658,
      "city": "Griffithsville",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25523,
      "latitude": 38.280522,
      "longitude": -82.083145,
      "city": "Hamlin",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25524,
      "latitude": 38.034057,
      "longitude": -82.100836,
      "city": "Harts",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25526,
      "latitude": 38.397097,
      "longitude": -81.961657,
      "city": "Hurricane",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25529,
      "latitude": 38.146531,
      "longitude": -81.86073,
      "city": "Julian",
      "state": "WV",
      "county": "Boone"
  },
  {
      "zipCode": 25530,
      "latitude": 38.356931,
      "longitude": -82.525529,
      "city": "Kenova",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25534,
      "latitude": 38.132114,
      "longitude": -82.418277,
      "city": "Kiahsville",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25535,
      "latitude": 38.299465,
      "longitude": -82.457353,
      "city": "Lavalette",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25537,
      "latitude": 38.523488,
      "longitude": -82.255114,
      "city": "Lesage",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25540,
      "latitude": 38.16021,
      "longitude": -82.054088,
      "city": "Midkiff",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25541,
      "latitude": 38.434487,
      "longitude": -82.134925,
      "city": "Milton",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25544,
      "latitude": 38.222163,
      "longitude": -82.113554,
      "city": "Myra",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25545,
      "latitude": 38.456541,
      "longitude": -82.234177,
      "city": "Ona",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25547,
      "latitude": 37.955852,
      "longitude": -82.005372,
      "city": "Pecks Mill",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25550,
      "latitude": 38.871527,
      "longitude": -82.098592,
      "city": "Point Pleasant",
      "state": "WV",
      "county": "Mason"
  },
  {
      "zipCode": 25555,
      "latitude": 38.240005,
      "longitude": -82.565378,
      "city": "Prichard",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25557,
      "latitude": 38.082564,
      "longitude": -82.072502,
      "city": "Ranger",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25559,
      "latitude": 38.317827,
      "longitude": -82.22918,
      "city": "Salt Rock",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25560,
      "latitude": 38.450355,
      "longitude": -81.896554,
      "city": "Scott Depot",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25562,
      "latitude": 38.343176,
      "longitude": -82.490494,
      "city": "Shoals",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25564,
      "latitude": 38.255874,
      "longitude": -81.890954,
      "city": "Sod",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25565,
      "latitude": 38.105713,
      "longitude": -82.027154,
      "city": "Spurlockville",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25567,
      "latitude": 38.228049,
      "longitude": -81.870544,
      "city": "Sumerco",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25569,
      "latitude": 38.475369,
      "longitude": -81.880455,
      "city": "Teays",
      "state": "WV",
      "county": "Putnam"
  },
  {
      "zipCode": 25570,
      "latitude": 38.222206,
      "longitude": -82.418862,
      "city": "Wayne",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25571,
      "latitude": 38.299231,
      "longitude": -82.153237,
      "city": "West Hamlin",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25572,
      "latitude": 38.187456,
      "longitude": -81.901236,
      "city": "Woodville",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25573,
      "latitude": 38.231798,
      "longitude": -81.942329,
      "city": "Yawkey",
      "state": "WV",
      "county": "Lincoln"
  },
  {
      "zipCode": 25601,
      "latitude": 37.865036,
      "longitude": -82.025158,
      "city": "Logan",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25606,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Accoville",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25607,
      "latitude": 37.795916,
      "longitude": -81.783341,
      "city": "Amherstdale",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25608,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Baisden",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25611,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Bruno",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25612,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Chauncey",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25614,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Cora",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25617,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Davin",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25621,
      "latitude": 37.636568,
      "longitude": -81.950082,
      "city": "Gilbert",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25623,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Hampden",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25624,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Henlawson",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25625,
      "latitude": 37.829956,
      "longitude": -82.058464,
      "city": "Holden",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25628,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Kistler",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25630,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Lorado",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25632,
      "latitude": 37.765557,
      "longitude": -81.921698,
      "city": "Lyburn",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25634,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Mallory",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25635,
      "latitude": 37.735653,
      "longitude": -81.875358,
      "city": "Man",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25636,
      "latitude": 37.821927,
      "longitude": -82.006203,
      "city": "Monaville",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25637,
      "latitude": 37.84639,
      "longitude": -82.048494,
      "city": "Mount Gay",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25638,
      "latitude": 37.741593,
      "longitude": -82.003139,
      "city": "Omar",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25639,
      "latitude": 37.875177,
      "longitude": -81.982637,
      "city": "Peach Creek",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25644,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Sarah Ann",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25645,
      "latitude": 37.833204,
      "longitude": -81.902401,
      "city": "Stirrat",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25646,
      "latitude": 37.830256,
      "longitude": -81.963853,
      "city": "Stollings",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25647,
      "latitude": 37.784635,
      "longitude": -81.990849,
      "city": "Switzer",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25649,
      "latitude": 37.88911,
      "longitude": -82.048823,
      "city": "Verdunville",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25650,
      "latitude": 37.637348,
      "longitude": -81.875714,
      "city": "Verner",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25651,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Wharncliffe",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25652,
      "latitude": 37.803545,
      "longitude": -82.046594,
      "city": "Whitman",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25653,
      "latitude": 37.808204,
      "longitude": -82.053011,
      "city": "Wilkinson",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25654,
      "latitude": 37.781792,
      "longitude": -81.891965,
      "city": "Yolyn",
      "state": "WV",
      "county": "Logan"
  },
  {
      "zipCode": 25661,
      "latitude": 37.702526,
      "longitude": -82.26002,
      "city": "Williamson",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25665,
      "latitude": 37.721351,
      "longitude": -82.30835,
      "city": "Borderland",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25666,
      "latitude": 37.926178,
      "longitude": -82.270292,
      "city": "Breeden",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25667,
      "latitude": 37.704739,
      "longitude": -82.273892,
      "city": "Chattaroy",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25669,
      "latitude": 38.132114,
      "longitude": -82.418277,
      "city": "Crum",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25670,
      "latitude": 37.718871,
      "longitude": -82.193059,
      "city": "Delbarton",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25671,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Dingess",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25672,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Edgarton",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25674,
      "latitude": 37.827155,
      "longitude": -82.404906,
      "city": "Kermit",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25676,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Lenore",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25678,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Matewan",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25682,
      "latitude": 37.6224,
      "longitude": -82.008561,
      "city": "Meador",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25685,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Naugatuck",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25686,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Newtown",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25687,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Nolan",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25688,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "North Matewan",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25690,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Ragland",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25691,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Rawl",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25692,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Red Jacket",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25694,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Thacker",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25696,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Varney",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25697,
      "latitude": 37.743063,
      "longitude": -82.10801,
      "city": "Vulcan",
      "state": "WV",
      "county": "Mingo"
  },
  {
      "zipCode": 25699,
      "latitude": 38.132114,
      "longitude": -82.418277,
      "city": "Wilsondale",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25701,
      "latitude": 38.371727,
      "longitude": -82.412866,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25702,
      "latitude": 38.444067,
      "longitude": -82.34872,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25703,
      "latitude": 38.422801,
      "longitude": -82.413818,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25704,
      "latitude": 38.397571,
      "longitude": -82.477733,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25705,
      "latitude": 38.406124,
      "longitude": -82.359088,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25706,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25707,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25708,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25709,
      "latitude": 38.132114,
      "longitude": -82.418277,
      "city": "Huntington",
      "state": "WV",
      "county": "Wayne"
  },
  {
      "zipCode": 25710,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25711,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25712,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25713,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25714,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25715,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25716,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25717,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25718,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25719,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25720,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25721,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25722,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25723,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25724,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25725,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25726,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25727,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25728,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25729,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25755,
      "latitude": 38.422115,
      "longitude": -82.431667,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25770,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25771,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25772,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25773,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25774,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25775,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25776,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25777,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25778,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25779,
      "latitude": 38.413384,
      "longitude": -82.277401,
      "city": "Huntington",
      "state": "WV",
      "county": "Cabell"
  },
  {
      "zipCode": 25801,
      "latitude": 37.767248,
      "longitude": -81.216446,
      "city": "Beckley",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25802,
      "latitude": 37.748935,
      "longitude": -81.224458,
      "city": "Beckley",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25810,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Allen Junction",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25811,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Amigo",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25812,
      "latitude": 38.135025,
      "longitude": -81.087871,
      "city": "Ansted",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25813,
      "latitude": 37.753437,
      "longitude": -81.1973,
      "city": "Beaver",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25816,
      "latitude": 37.735161,
      "longitude": -81.136328,
      "city": "Blue Jay",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25817,
      "latitude": 37.774581,
      "longitude": -81.388107,
      "city": "Bolt",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25818,
      "latitude": 37.862613,
      "longitude": -81.200997,
      "city": "Bradley",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25820,
      "latitude": 37.509522,
      "longitude": -81.099605,
      "city": "Camp Creek",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 25823,
      "latitude": 37.670797,
      "longitude": -81.174872,
      "city": "Coal City",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25825,
      "latitude": 37.665456,
      "longitude": -81.093339,
      "city": "Cool Ridge",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25826,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Corinne",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25827,
      "latitude": 37.700567,
      "longitude": -81.201446,
      "city": "Crab Orchard",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25831,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Danese",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25832,
      "latitude": 37.693341,
      "longitude": -81.216077,
      "city": "Daniels",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25833,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Dothan",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25836,
      "latitude": 37.779232,
      "longitude": -81.263538,
      "city": "Eccles",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25837,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Edmond",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25839,
      "latitude": 37.768902,
      "longitude": -81.371602,
      "city": "Fairdale",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25840,
      "latitude": 38.063982,
      "longitude": -81.094259,
      "city": "Fayetteville",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25841,
      "latitude": 37.554091,
      "longitude": -81.100627,
      "city": "Flat Top",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 25843,
      "latitude": 37.679167,
      "longitude": -81.151526,
      "city": "Ghent",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25844,
      "latitude": 37.825085,
      "longitude": -81.38583,
      "city": "Glen Daniel",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25845,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Glen Fork",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25846,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Glen Jean",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25847,
      "latitude": 37.713406,
      "longitude": -81.178779,
      "city": "Glen Morgan",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25848,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Glen Rogers",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25849,
      "latitude": 37.730624,
      "longitude": -81.279864,
      "city": "Glen White",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25851,
      "latitude": 37.802469,
      "longitude": -81.282759,
      "city": "Harper",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25853,
      "latitude": 37.748935,
      "longitude": -81.224458,
      "city": "Helen",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25854,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Hico",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25855,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Hilltop",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25856,
      "latitude": 37.647885,
      "longitude": -81.190628,
      "city": "Jonben",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25857,
      "latitude": 37.631543,
      "longitude": -81.227631,
      "city": "Josephine",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25859,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Kilsyth",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25860,
      "latitude": 37.748935,
      "longitude": -81.224458,
      "city": "Lanark",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25862,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Lansing",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25864,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Layland",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25865,
      "latitude": 37.73121,
      "longitude": -81.317326,
      "city": "Lester",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25866,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Lochgelly",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25868,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Lookout",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25870,
      "latitude": 37.610803,
      "longitude": -81.505936,
      "city": "Maben",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25871,
      "latitude": 37.770012,
      "longitude": -81.210253,
      "city": "Mabscott",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25873,
      "latitude": 37.7517,
      "longitude": -81.214825,
      "city": "Mac Arthur",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25875,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Mc Graws",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25876,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Saulsville",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25878,
      "latitude": 37.717052,
      "longitude": -81.240023,
      "city": "Midway",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25879,
      "latitude": 37.975773,
      "longitude": -81.114993,
      "city": "Minden",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25880,
      "latitude": 38.00309,
      "longitude": -81.19331,
      "city": "Mount Hope",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25882,
      "latitude": 37.582143,
      "longitude": -81.413815,
      "city": "Mullens",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25901,
      "latitude": 37.959435,
      "longitude": -81.15848,
      "city": "Oak Hill",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25902,
      "latitude": 37.596319,
      "longitude": -81.190678,
      "city": "Odd",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25904,
      "latitude": 37.91101,
      "longitude": -81.264824,
      "city": "Pax",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25906,
      "latitude": 37.837573,
      "longitude": -81.128631,
      "city": "Piney View",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25907,
      "latitude": 37.853198,
      "longitude": -81.081717,
      "city": "Prince",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25908,
      "latitude": 37.715831,
      "longitude": -81.246754,
      "city": "Princewick",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25909,
      "latitude": 37.838147,
      "longitude": -81.200383,
      "city": "Prosperity",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25911,
      "latitude": 37.758469,
      "longitude": -81.167549,
      "city": "Raleigh",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25912,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Ramsey",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25913,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Ravencliff",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25914,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Redstar",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25915,
      "latitude": 37.625414,
      "longitude": -81.27493,
      "city": "Rhodell",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25916,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Sabine",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25917,
      "latitude": 37.948765,
      "longitude": -81.15307,
      "city": "Scarbro",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25918,
      "latitude": 37.741625,
      "longitude": -81.014915,
      "city": "Shady Spring",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25919,
      "latitude": 37.800481,
      "longitude": -81.180561,
      "city": "Skelton",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25920,
      "latitude": 37.717933,
      "longitude": -81.37157,
      "city": "Slab Fork",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25921,
      "latitude": 37.777244,
      "longitude": -81.345004,
      "city": "Sophia",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25922,
      "latitude": 37.460452,
      "longitude": -81.112799,
      "city": "Spanishburg",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 25926,
      "latitude": 37.787252,
      "longitude": -81.195122,
      "city": "Sprague",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25927,
      "latitude": 37.748935,
      "longitude": -81.224458,
      "city": "Stanaford",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25928,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Stephenson",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25931,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Summerlee",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25932,
      "latitude": 37.764228,
      "longitude": -81.221724,
      "city": "Surveyor",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25934,
      "latitude": 37.848955,
      "longitude": -81.093678,
      "city": "Terry",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 25936,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Thurmond",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25938,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Victor",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25942,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Winona",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25943,
      "latitude": 37.603344,
      "longitude": -81.53998,
      "city": "Wyco",
      "state": "WV",
      "county": "Wyoming"
  },
  {
      "zipCode": 25951,
      "latitude": 37.645544,
      "longitude": -80.864634,
      "city": "Hinton",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25958,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Charmco",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 25961,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Crichton",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 25962,
      "latitude": 37.973212,
      "longitude": -80.74519,
      "city": "Rainelle",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 25965,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Elton",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25966,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Green Sulphur Springs",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25967,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Hines",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 25969,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Jumping Branch",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25971,
      "latitude": 37.416395,
      "longitude": -81.105803,
      "city": "Lerona",
      "state": "WV",
      "county": "Mercer"
  },
  {
      "zipCode": 25972,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Leslie",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 25976,
      "latitude": 37.865186,
      "longitude": -80.874366,
      "city": "Meadow Bridge",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25977,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Meadow Creek",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25978,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Nimitz",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25979,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Pipestem",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25981,
      "latitude": 37.975595,
      "longitude": -80.426874,
      "city": "Quinwood",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 25984,
      "latitude": 37.968468,
      "longitude": -80.669597,
      "city": "Rupert",
      "state": "WV",
      "county": "Greenbrier"
  },
  {
      "zipCode": 25985,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "Sandstone",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25986,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Spring Dale",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 25988,
      "latitude": 37.648541,
      "longitude": -80.877395,
      "city": "True",
      "state": "WV",
      "county": "Summers"
  },
  {
      "zipCode": 25989,
      "latitude": 37.684255,
      "longitude": -81.048923,
      "city": "White Oak",
      "state": "WV",
      "county": "Raleigh"
  },
  {
      "zipCode": 26003,
      "latitude": 40.102703,
      "longitude": -80.647599,
      "city": "Wheeling",
      "state": "WV",
      "county": "Ohio"
  },
  {
      "zipCode": 26030,
      "latitude": 40.225862,
      "longitude": -80.65135,
      "city": "Beech Bottom",
      "state": "WV",
      "county": "Brooke"
  },
  {
      "zipCode": 26031,
      "latitude": 39.955349,
      "longitude": -80.710507,
      "city": "Benwood",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26032,
      "latitude": 40.210727,
      "longitude": -80.550337,
      "city": "Bethany",
      "state": "WV",
      "county": "Brooke"
  },
  {
      "zipCode": 26033,
      "latitude": 39.872322,
      "longitude": -80.555275,
      "city": "Cameron",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26034,
      "latitude": 40.556137,
      "longitude": -80.565784,
      "city": "Chester",
      "state": "WV",
      "county": "Hancock"
  },
  {
      "zipCode": 26035,
      "latitude": 40.373181,
      "longitude": -80.566155,
      "city": "Colliers",
      "state": "WV",
      "county": "Brooke"
  },
  {
      "zipCode": 26036,
      "latitude": 40.015394,
      "longitude": -80.550114,
      "city": "Dallas",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26037,
      "latitude": 40.335218,
      "longitude": -80.570953,
      "city": "Follansbee",
      "state": "WV",
      "county": "Brooke"
  },
  {
      "zipCode": 26038,
      "latitude": 39.962545,
      "longitude": -80.719815,
      "city": "Glen Dale",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26039,
      "latitude": 39.868737,
      "longitude": -80.600655,
      "city": "Glen Easton",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26040,
      "latitude": 39.985905,
      "longitude": -80.719565,
      "city": "Mc Mechen",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26041,
      "latitude": 39.916748,
      "longitude": -80.737134,
      "city": "Moundsville",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26047,
      "latitude": 40.522005,
      "longitude": -80.571689,
      "city": "New Cumberland",
      "state": "WV",
      "county": "Hancock"
  },
  {
      "zipCode": 26050,
      "latitude": 40.608776,
      "longitude": -80.613199,
      "city": "Newell",
      "state": "WV",
      "county": "Hancock"
  },
  {
      "zipCode": 26055,
      "latitude": 39.877162,
      "longitude": -80.694606,
      "city": "Proctor",
      "state": "WV",
      "county": "Marshall"
  },
  {
      "zipCode": 26056,
      "latitude": 40.516234,
      "longitude": -80.592823,
      "city": "New Manchester",
      "state": "WV",
      "county": "Hancock"
  },
  {
      "zipCode": 26058,
      "latitude": 40.27932,
      "longitude": -80.599949,
      "city": "Short Creek",
      "state": "WV",
      "county": "Brooke"
  },
  {
      "zipCode": 26059,
      "latitude": 40.081064,
      "longitude": -80.59733,
      "city": "Triadelphia",
      "state": "WV",
      "county": "Ohio"
  },
  {
      "zipCode": 26060,
      "latitude": 40.078249,
      "longitude": -80.589611,
      "city": "Valley Grove",
      "state": "WV",
      "county": "Ohio"
  },
  {
      "zipCode": 26062,
      "latitude": 40.507729,
      "longitude": -80.565292,
      "city": "Weirton",
      "state": "WV",
      "county": "Hancock"
  },
  {
      "zipCode": 26070,
      "latitude": 40.293469,
      "longitude": -80.594036,
      "city": "Wellsburg",
      "state": "WV",
      "county": "Brooke"
  },
  {
      "zipCode": 26074,
      "latitude": 40.169839,
      "longitude": -80.595763,
      "city": "West Liberty",
      "state": "WV",
      "county": "Ohio"
  },
  {
      "zipCode": 26075,
      "latitude": 40.192679,
      "longitude": -80.665879,
      "city": "Windsor Heights",
      "state": "WV",
      "county": "Brooke"
  },
  {
      "zipCode": 26101,
      "latitude": 39.286315,
      "longitude": -81.55477,
      "city": "Parkersburg",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26102,
      "latitude": 39.218276,
      "longitude": -81.497865,
      "city": "Parkersburg",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26103,
      "latitude": 39.236427,
      "longitude": -81.540501,
      "city": "Parkersburg",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26104,
      "latitude": 39.28037,
      "longitude": -81.493574,
      "city": "Parkersburg",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26105,
      "latitude": 39.324924,
      "longitude": -81.538034,
      "city": "Vienna",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26106,
      "latitude": 39.218276,
      "longitude": -81.497865,
      "city": "Parkersburg",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26120,
      "latitude": 39.218276,
      "longitude": -81.497865,
      "city": "Mineral Wells",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26121,
      "latitude": 39.218276,
      "longitude": -81.497865,
      "city": "Mineral Wells",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26133,
      "latitude": 39.155706,
      "longitude": -81.736307,
      "city": "Belleville",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26134,
      "latitude": 39.375025,
      "longitude": -81.263875,
      "city": "Belmont",
      "state": "WV",
      "county": "Pleasants"
  },
  {
      "zipCode": 26135,
      "latitude": 39.450612,
      "longitude": -80.870081,
      "city": "Bens Run",
      "state": "WV",
      "county": "Tyler"
  },
  {
      "zipCode": 26136,
      "latitude": 38.828582,
      "longitude": -81.132151,
      "city": "Big Bend",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 26137,
      "latitude": 38.828582,
      "longitude": -81.132151,
      "city": "Big Springs",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 26138,
      "latitude": 39.040511,
      "longitude": -81.371951,
      "city": "Brohard",
      "state": "WV",
      "county": "Wirt"
  },
  {
      "zipCode": 26141,
      "latitude": 39.040511,
      "longitude": -81.371951,
      "city": "Creston",
      "state": "WV",
      "county": "Wirt"
  },
  {
      "zipCode": 26142,
      "latitude": 39.216011,
      "longitude": -81.48079,
      "city": "Davisville",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26143,
      "latitude": 39.032058,
      "longitude": -81.380692,
      "city": "Elizabeth",
      "state": "WV",
      "county": "Wirt"
  },
  {
      "zipCode": 26146,
      "latitude": 39.496137,
      "longitude": -81.057241,
      "city": "Friendly",
      "state": "WV",
      "county": "Tyler"
  },
  {
      "zipCode": 26147,
      "latitude": 38.921782,
      "longitude": -81.093773,
      "city": "Grantsville",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 26148,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Macfarlan",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26149,
      "latitude": 39.494178,
      "longitude": -80.907644,
      "city": "Middlebourne",
      "state": "WV",
      "county": "Tyler"
  },
  {
      "zipCode": 26150,
      "latitude": 39.189677,
      "longitude": -81.513911,
      "city": "Mineral Wells",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26151,
      "latitude": 38.828582,
      "longitude": -81.132151,
      "city": "Mount Zion",
      "state": "WV",
      "county": "Calhoun"
  },
  {
      "zipCode": 26152,
      "latitude": 39.040511,
      "longitude": -81.371951,
      "city": "Munday",
      "state": "WV",
      "county": "Wirt"
  },
  {
      "zipCode": 26155,
      "latitude": 39.611459,
      "longitude": -80.817928,
      "city": "New Martinsville",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26159,
      "latitude": 39.606691,
      "longitude": -80.929167,
      "city": "Paden City",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26160,
      "latitude": 39.040511,
      "longitude": -81.371951,
      "city": "Palestine",
      "state": "WV",
      "county": "Wirt"
  },
  {
      "zipCode": 26161,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Petroleum",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26162,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Porters Falls",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26164,
      "latitude": 38.98882,
      "longitude": -81.713983,
      "city": "Ravenswood",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 26167,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Reader",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26169,
      "latitude": 39.218276,
      "longitude": -81.497865,
      "city": "Rockport",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26170,
      "latitude": 39.408888,
      "longitude": -81.20786,
      "city": "Saint Marys",
      "state": "WV",
      "county": "Pleasants"
  },
  {
      "zipCode": 26173,
      "latitude": 38.95192,
      "longitude": -81.709496,
      "city": "Sherman",
      "state": "WV",
      "county": "Jackson"
  },
  {
      "zipCode": 26175,
      "latitude": 39.541445,
      "longitude": -80.975638,
      "city": "Sistersville",
      "state": "WV",
      "county": "Tyler"
  },
  {
      "zipCode": 26178,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Smithville",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26180,
      "latitude": 39.218276,
      "longitude": -81.497865,
      "city": "Walker",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26181,
      "latitude": 39.206816,
      "longitude": -81.65769,
      "city": "Washington",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26184,
      "latitude": 39.322871,
      "longitude": -81.401137,
      "city": "Waverly",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26186,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Wileyville",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26187,
      "latitude": 39.367796,
      "longitude": -81.455899,
      "city": "Williamstown",
      "state": "WV",
      "county": "Wood"
  },
  {
      "zipCode": 26201,
      "latitude": 38.975479,
      "longitude": -80.240664,
      "city": "Buckhannon",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26202,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Fenwick",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26203,
      "latitude": 38.522709,
      "longitude": -80.607651,
      "city": "Erbacon",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26205,
      "latitude": 38.329918,
      "longitude": -80.645376,
      "city": "Craigsville",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26206,
      "latitude": 38.423224,
      "longitude": -80.564685,
      "city": "Cowen",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26208,
      "latitude": 38.387147,
      "longitude": -80.590651,
      "city": "Camden On Gauley",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26209,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Snowshoe",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 26210,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "Adrian",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26215,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "Cleveland",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26217,
      "latitude": 38.601167,
      "longitude": -80.465431,
      "city": "Diana",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26218,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "French Creek",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26219,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "Frenchton",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26222,
      "latitude": 38.645017,
      "longitude": -80.432189,
      "city": "Hacker Valley",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26224,
      "latitude": 38.89487,
      "longitude": -79.850148,
      "city": "Helvetia",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26228,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "Kanawha Head",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26229,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "Lorentz",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26230,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Pickens",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26234,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "Rock Cave",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26236,
      "latitude": 38.899314,
      "longitude": -80.226855,
      "city": "Selbyville",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26237,
      "latitude": 38.864632,
      "longitude": -80.21241,
      "city": "Tallmansville",
      "state": "WV",
      "county": "Upshur"
  },
  {
      "zipCode": 26238,
      "latitude": 39.125078,
      "longitude": -80.017982,
      "city": "Volga",
      "state": "WV",
      "county": "Barbour"
  },
  {
      "zipCode": 26241,
      "latitude": 38.846815,
      "longitude": -79.892841,
      "city": "Elkins",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26250,
      "latitude": 39.022982,
      "longitude": -79.932,
      "city": "Belington",
      "state": "WV",
      "county": "Barbour"
  },
  {
      "zipCode": 26253,
      "latitude": 38.836615,
      "longitude": -79.872553,
      "city": "Beverly",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26254,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Bowden",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26257,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Coalton",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26259,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Dailey",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26260,
      "latitude": 39.130056,
      "longitude": -79.468329,
      "city": "Davis",
      "state": "WV",
      "county": "Tucker"
  },
  {
      "zipCode": 26261,
      "latitude": 38.230265,
      "longitude": -80.578921,
      "city": "Richwood",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26263,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Dryfork",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26264,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Durbin",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 26266,
      "latitude": 38.482539,
      "longitude": -80.431111,
      "city": "Upperglade",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26267,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Ellamore",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26268,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Glady",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26269,
      "latitude": 39.117906,
      "longitude": -79.563678,
      "city": "Hambleton",
      "state": "WV",
      "county": "Tucker"
  },
  {
      "zipCode": 26270,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Harman",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26271,
      "latitude": 39.117906,
      "longitude": -79.563678,
      "city": "Hendricks",
      "state": "WV",
      "county": "Tucker"
  },
  {
      "zipCode": 26273,
      "latitude": 38.696059,
      "longitude": -79.974846,
      "city": "Huttonsville",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26275,
      "latitude": 38.978405,
      "longitude": -79.949417,
      "city": "Junior",
      "state": "WV",
      "county": "Barbour"
  },
  {
      "zipCode": 26276,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Kerens",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26278,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Mabie",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26280,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Mill Creek",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26282,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Monterville",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26283,
      "latitude": 39.056458,
      "longitude": -79.821443,
      "city": "Montrose",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26285,
      "latitude": 38.93074,
      "longitude": -79.967754,
      "city": "Norton",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26287,
      "latitude": 39.096016,
      "longitude": -79.678724,
      "city": "Parsons",
      "state": "WV",
      "county": "Tucker"
  },
  {
      "zipCode": 26288,
      "latitude": 38.483244,
      "longitude": -80.447555,
      "city": "Webster Springs",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26289,
      "latitude": 39.117906,
      "longitude": -79.563678,
      "city": "Red Creek",
      "state": "WV",
      "county": "Tucker"
  },
  {
      "zipCode": 26291,
      "latitude": 38.387966,
      "longitude": -79.990585,
      "city": "Slatyfork",
      "state": "WV",
      "county": "Pocahontas"
  },
  {
      "zipCode": 26292,
      "latitude": 39.138003,
      "longitude": -79.481555,
      "city": "Thomas",
      "state": "WV",
      "county": "Tucker"
  },
  {
      "zipCode": 26293,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Valley Bend",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26294,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Valley Head",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26296,
      "latitude": 38.75338,
      "longitude": -79.814713,
      "city": "Whitmer",
      "state": "WV",
      "county": "Randolph"
  },
  {
      "zipCode": 26298,
      "latitude": 38.482539,
      "longitude": -80.431111,
      "city": "Bergoo",
      "state": "WV",
      "county": "Webster"
  },
  {
      "zipCode": 26301,
      "latitude": 39.287434,
      "longitude": -80.341883,
      "city": "Clarksburg",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26302,
      "latitude": 39.267736,
      "longitude": -80.307718,
      "city": "Clarksburg",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26306,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Clarksburg",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26320,
      "latitude": 39.450612,
      "longitude": -80.870081,
      "city": "Alma",
      "state": "WV",
      "county": "Tyler"
  },
  {
      "zipCode": 26321,
      "latitude": 39.09078,
      "longitude": -80.470734,
      "city": "Alum Bridge",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26323,
      "latitude": 39.258671,
      "longitude": -80.287544,
      "city": "Anmoore",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26325,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Auburn",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26327,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Berea",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26328,
      "latitude": 39.270846,
      "longitude": -80.719742,
      "city": "Blandville",
      "state": "WV",
      "county": "Doddridge"
  },
  {
      "zipCode": 26330,
      "latitude": 39.309303,
      "longitude": -80.282306,
      "city": "Bridgeport",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26332,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Bristol",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26334,
      "latitude": 39.125078,
      "longitude": -80.017982,
      "city": "Brownton",
      "state": "WV",
      "county": "Barbour"
  },
  {
      "zipCode": 26335,
      "latitude": 38.777546,
      "longitude": -80.656974,
      "city": "Burnsville",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26337,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Cairo",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26338,
      "latitude": 39.115329,
      "longitude": -80.58037,
      "city": "Camden",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26339,
      "latitude": 39.270846,
      "longitude": -80.719742,
      "city": "Center Point",
      "state": "WV",
      "county": "Doddridge"
  },
  {
      "zipCode": 26342,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Coxs Mills",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26343,
      "latitude": 38.945847,
      "longitude": -80.51449,
      "city": "Crawford",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26346,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Ellenboro",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26347,
      "latitude": 39.345364,
      "longitude": -80.04977,
      "city": "Flemington",
      "state": "WV",
      "county": "Taylor"
  },
  {
      "zipCode": 26348,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Folsom",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26349,
      "latitude": 39.125078,
      "longitude": -80.017982,
      "city": "Galloway",
      "state": "WV",
      "county": "Barbour"
  },
  {
      "zipCode": 26350,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Gilmer",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26351,
      "latitude": 38.940946,
      "longitude": -80.83133,
      "city": "Glenville",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26354,
      "latitude": 39.336266,
      "longitude": -80.015686,
      "city": "Grafton",
      "state": "WV",
      "county": "Taylor"
  },
  {
      "zipCode": 26361,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Gypsy",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26362,
      "latitude": 39.145318,
      "longitude": -81.034061,
      "city": "Harrisville",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26366,
      "latitude": 39.374487,
      "longitude": -80.348925,
      "city": "Haywood",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26369,
      "latitude": 39.331202,
      "longitude": -80.332455,
      "city": "Hepzibah",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26372,
      "latitude": 38.945847,
      "longitude": -80.51449,
      "city": "Horner",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26374,
      "latitude": 39.48274,
      "longitude": -79.836968,
      "city": "Independence",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26375,
      "latitude": 39.279818,
      "longitude": -80.575379,
      "city": "Industrial",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26376,
      "latitude": 38.945847,
      "longitude": -80.51449,
      "city": "Ireland",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26377,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Jacksonburg",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26378,
      "latitude": 39.109143,
      "longitude": -80.441713,
      "city": "Jane Lew",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26384,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Linn",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26385,
      "latitude": 39.152121,
      "longitude": -80.357602,
      "city": "Lost Creek",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26386,
      "latitude": 39.377263,
      "longitude": -80.354173,
      "city": "Lumberport",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26404,
      "latitude": 39.346214,
      "longitude": -80.313875,
      "city": "Meadowbrook",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26405,
      "latitude": 39.125078,
      "longitude": -80.017982,
      "city": "Moatsville",
      "state": "WV",
      "county": "Barbour"
  },
  {
      "zipCode": 26407,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Mountain",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26408,
      "latitude": 39.21751,
      "longitude": -80.295772,
      "city": "Mount Clare",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26410,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Newburg",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26411,
      "latitude": 39.270846,
      "longitude": -80.719742,
      "city": "New Milton",
      "state": "WV",
      "county": "Doddridge"
  },
  {
      "zipCode": 26412,
      "latitude": 38.945847,
      "longitude": -80.51449,
      "city": "Orlando",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26415,
      "latitude": 39.279104,
      "longitude": -81.001944,
      "city": "Pennsboro",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26416,
      "latitude": 39.161343,
      "longitude": -80.049182,
      "city": "Philippi",
      "state": "WV",
      "county": "Barbour"
  },
  {
      "zipCode": 26419,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Pine Grove",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26421,
      "latitude": 39.198501,
      "longitude": -81.068913,
      "city": "Pullman",
      "state": "WV",
      "county": "Ritchie"
  },
  {
      "zipCode": 26422,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Reynoldsville",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26424,
      "latitude": 39.345364,
      "longitude": -80.04977,
      "city": "Rosemont",
      "state": "WV",
      "county": "Taylor"
  },
  {
      "zipCode": 26425,
      "latitude": 39.312958,
      "longitude": -79.704927,
      "city": "Rowlesburg",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26426,
      "latitude": 39.245329,
      "longitude": -80.49102,
      "city": "Salem",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26430,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Sand Fork",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26431,
      "latitude": 39.328705,
      "longitude": -80.273372,
      "city": "Shinnston",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26434,
      "latitude": 39.450612,
      "longitude": -80.870081,
      "city": "Shirley",
      "state": "WV",
      "county": "Tyler"
  },
  {
      "zipCode": 26435,
      "latitude": 39.345364,
      "longitude": -80.04977,
      "city": "Simpson",
      "state": "WV",
      "county": "Taylor"
  },
  {
      "zipCode": 26436,
      "latitude": 39.270846,
      "longitude": -80.719742,
      "city": "Smithburg",
      "state": "WV",
      "county": "Doddridge"
  },
  {
      "zipCode": 26437,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Smithfield",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26438,
      "latitude": 39.346511,
      "longitude": -80.32056,
      "city": "Spelter",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26440,
      "latitude": 39.345364,
      "longitude": -80.04977,
      "city": "Thornton",
      "state": "WV",
      "county": "Taylor"
  },
  {
      "zipCode": 26443,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Troy",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26444,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Tunnelton",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26447,
      "latitude": 38.945847,
      "longitude": -80.51449,
      "city": "Walkersville",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26448,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Wallace",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26451,
      "latitude": 39.199606,
      "longitude": -80.40256,
      "city": "West Milford",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26452,
      "latitude": 39.042331,
      "longitude": -80.447878,
      "city": "Weston",
      "state": "WV",
      "county": "Lewis"
  },
  {
      "zipCode": 26456,
      "latitude": 39.296522,
      "longitude": -80.774491,
      "city": "West Union",
      "state": "WV",
      "county": "Doddridge"
  },
  {
      "zipCode": 26461,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Wilsonburg",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26463,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Wyatt",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26501,
      "latitude": 39.609911,
      "longitude": -79.983141,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26502,
      "latitude": 39.625302,
      "longitude": -79.967184,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26503,
      "latitude": 39.636606,
      "longitude": -79.895639,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26504,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26505,
      "latitude": 39.609596,
      "longitude": -79.967623,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26506,
      "latitude": 39.645276,
      "longitude": -79.962669,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26507,
      "latitude": 39.680786,
      "longitude": -79.836473,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26508,
      "latitude": 39.595329,
      "longitude": -79.922871,
      "city": "Morgantown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26519,
      "latitude": 39.516364,
      "longitude": -79.617653,
      "city": "Albright",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26520,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Arthurdale",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26521,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Blacksville",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26522,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Booth",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26524,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Bretz",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26525,
      "latitude": 39.666293,
      "longitude": -79.620241,
      "city": "Bruceton Mills",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26527,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Cassville",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26529,
      "latitude": 39.651628,
      "longitude": -80.159737,
      "city": "Core",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26531,
      "latitude": 39.605344,
      "longitude": -79.895832,
      "city": "Dellslow",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26533,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Everettville",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26534,
      "latitude": 39.62764,
      "longitude": -79.942937,
      "city": "Granville",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26535,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Hazelton",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26537,
      "latitude": 39.471673,
      "longitude": -79.67596,
      "city": "Kingwood",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26541,
      "latitude": 39.658416,
      "longitude": -80.028814,
      "city": "Maidsville",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26542,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Masontown",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26543,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Osage",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26544,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Pentress",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26546,
      "latitude": 39.674575,
      "longitude": -80.034965,
      "city": "Pursglove",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26547,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Reedsville",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26554,
      "latitude": 39.494115,
      "longitude": -80.221282,
      "city": "Fairmont",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26555,
      "latitude": 39.514343,
      "longitude": -80.218538,
      "city": "Fairmont",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26559,
      "latitude": 39.505155,
      "longitude": -80.172524,
      "city": "Barrackville",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26560,
      "latitude": 39.514343,
      "longitude": -80.218538,
      "city": "Baxter",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26561,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Big Run",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26562,
      "latitude": 39.651148,
      "longitude": -80.416065,
      "city": "Burton",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26563,
      "latitude": 39.478198,
      "longitude": -80.271988,
      "city": "Carolina",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26566,
      "latitude": 39.514343,
      "longitude": -80.218538,
      "city": "Colfax",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26568,
      "latitude": 39.285204,
      "longitude": -80.385344,
      "city": "Enterprise",
      "state": "WV",
      "county": "Harrison"
  },
  {
      "zipCode": 26570,
      "latitude": 39.584289,
      "longitude": -80.23895,
      "city": "Fairview",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26571,
      "latitude": 39.506654,
      "longitude": -80.252582,
      "city": "Farmington",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26572,
      "latitude": 39.514343,
      "longitude": -80.218538,
      "city": "Four States",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26574,
      "latitude": 39.558696,
      "longitude": -80.180468,
      "city": "Grant Town",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26575,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Hundred",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26576,
      "latitude": 39.48774,
      "longitude": -80.266588,
      "city": "Idamay",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26578,
      "latitude": 39.446631,
      "longitude": -80.176171,
      "city": "Kingmont",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26581,
      "latitude": 39.575518,
      "longitude": -80.666712,
      "city": "Littleton",
      "state": "WV",
      "county": "Wetzel"
  },
  {
      "zipCode": 26582,
      "latitude": 39.536663,
      "longitude": -80.23996,
      "city": "Mannington",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26585,
      "latitude": 39.579171,
      "longitude": -80.368694,
      "city": "Metz",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26586,
      "latitude": 39.519136,
      "longitude": -80.089673,
      "city": "Montana Mines",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26587,
      "latitude": 39.521541,
      "longitude": -80.299082,
      "city": "Rachel",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26588,
      "latitude": 39.535089,
      "longitude": -80.127883,
      "city": "Rivesville",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26589,
      "latitude": 39.6572,
      "longitude": -80.366185,
      "city": "Wadestown",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26590,
      "latitude": 39.578512,
      "longitude": -80.093007,
      "city": "Wana",
      "state": "WV",
      "county": "Monongalia"
  },
  {
      "zipCode": 26591,
      "latitude": 39.451967,
      "longitude": -80.26255,
      "city": "Worthington",
      "state": "WV",
      "county": "Marion"
  },
  {
      "zipCode": 26601,
      "latitude": 38.654141,
      "longitude": -80.67597,
      "city": "Sutton",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26610,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Birch River",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26611,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Cedarville",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26612,
      "latitude": 38.620506,
      "longitude": -80.586148,
      "city": "Centralia",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26615,
      "latitude": 38.706017,
      "longitude": -80.736884,
      "city": "Copen",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26617,
      "latitude": 38.465777,
      "longitude": -81.050662,
      "city": "Dille",
      "state": "WV",
      "county": "Clay"
  },
  {
      "zipCode": 26618,
      "latitude": 38.706017,
      "longitude": -80.736884,
      "city": "Elmira",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26619,
      "latitude": 38.735774,
      "longitude": -80.732359,
      "city": "Exchange",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26621,
      "latitude": 38.733696,
      "longitude": -80.574704,
      "city": "Flatwoods",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26623,
      "latitude": 38.630462,
      "longitude": -80.858859,
      "city": "Frametown",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26624,
      "latitude": 38.718522,
      "longitude": -80.687337,
      "city": "Gassaway",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26627,
      "latitude": 38.762122,
      "longitude": -80.640614,
      "city": "Heaters",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26629,
      "latitude": 38.580977,
      "longitude": -80.693054,
      "city": "Little Birch",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26631,
      "latitude": 38.706017,
      "longitude": -80.736884,
      "city": "Napier",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26634,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Perkins",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26636,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Rosedale",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26638,
      "latitude": 38.913649,
      "longitude": -80.831256,
      "city": "Shock",
      "state": "WV",
      "county": "Gilmer"
  },
  {
      "zipCode": 26639,
      "latitude": 38.574978,
      "longitude": -80.799944,
      "city": "Strange Creek",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26641,
      "latitude": 38.706017,
      "longitude": -80.736884,
      "city": "Wilsie",
      "state": "WV",
      "county": "Braxton"
  },
  {
      "zipCode": 26651,
      "latitude": 38.326251,
      "longitude": -80.854096,
      "city": "Summersville",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26656,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Belva",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26660,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Calvin",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26662,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Canvas",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26667,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Drennen",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26671,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Gilboa",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26674,
      "latitude": 38.20125,
      "longitude": -81.185153,
      "city": "Jodie",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 26675,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Keslers Cross Lanes",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26676,
      "latitude": 38.155842,
      "longitude": -80.682702,
      "city": "Leivasy",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26678,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Mount Lookout",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26679,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Mount Nebo",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26680,
      "latitude": 38.041293,
      "longitude": -81.064784,
      "city": "Nallen",
      "state": "WV",
      "county": "Fayette"
  },
  {
      "zipCode": 26681,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Nettie",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26684,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Pool",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26690,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Swiss",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26691,
      "latitude": 38.318389,
      "longitude": -80.834152,
      "city": "Tioga",
      "state": "WV",
      "county": "Nicholas"
  },
  {
      "zipCode": 26704,
      "latitude": 39.251133,
      "longitude": -78.618258,
      "city": "Augusta",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26705,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Aurora",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26707,
      "latitude": 38.986974,
      "longitude": -79.146211,
      "city": "Bayard",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26710,
      "latitude": 39.443055,
      "longitude": -78.964749,
      "city": "Burlington",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26711,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Capon Bridge",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26714,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Delray",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26716,
      "latitude": 39.458091,
      "longitude": -79.688154,
      "city": "Eglon",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26717,
      "latitude": 39.443055,
      "longitude": -78.964749,
      "city": "Elk Garden",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26719,
      "latitude": 39.500536,
      "longitude": -78.744739,
      "city": "Fort Ashby",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26720,
      "latitude": 39.070601,
      "longitude": -79.233536,
      "city": "Gormania",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26722,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Green Spring",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26726,
      "latitude": 39.452686,
      "longitude": -78.892554,
      "city": "Keyser",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26731,
      "latitude": 39.070601,
      "longitude": -79.233536,
      "city": "Lahmansville",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26734,
      "latitude": 39.070601,
      "longitude": -79.233536,
      "city": "Medley",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26739,
      "latitude": 39.070601,
      "longitude": -79.233536,
      "city": "Mount Storm",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26743,
      "latitude": 39.32047,
      "longitude": -79.045902,
      "city": "New Creek",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26750,
      "latitude": 39.546831,
      "longitude": -78.909518,
      "city": "Piedmont",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26753,
      "latitude": 39.600736,
      "longitude": -78.769112,
      "city": "Ridgeley",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26755,
      "latitude": 39.18471,
      "longitude": -78.726985,
      "city": "Rio",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26757,
      "latitude": 39.34487,
      "longitude": -78.758487,
      "city": "Romney",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26761,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Shanks",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26763,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Springfield",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26764,
      "latitude": 39.430634,
      "longitude": -79.519679,
      "city": "Terra Alta",
      "state": "WV",
      "county": "Preston"
  },
  {
      "zipCode": 26767,
      "latitude": 39.617003,
      "longitude": -78.763194,
      "city": "Wiley Ford",
      "state": "WV",
      "county": "Mineral"
  },
  {
      "zipCode": 26801,
      "latitude": 38.999518,
      "longitude": -78.821226,
      "city": "Baker",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26802,
      "latitude": 38.684692,
      "longitude": -79.353564,
      "city": "Brandywine",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 26804,
      "latitude": 38.684692,
      "longitude": -79.353564,
      "city": "Circleville",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 26807,
      "latitude": 38.648787,
      "longitude": -79.327196,
      "city": "Franklin",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 26808,
      "latitude": 39.211295,
      "longitude": -78.436258,
      "city": "High View",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26810,
      "latitude": 38.999518,
      "longitude": -78.821226,
      "city": "Lost City",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26812,
      "latitude": 38.999518,
      "longitude": -78.821226,
      "city": "Mathias",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26814,
      "latitude": 38.684692,
      "longitude": -79.353564,
      "city": "Riverton",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 26815,
      "latitude": 38.684692,
      "longitude": -79.353564,
      "city": "Sugar Grove",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 26817,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Bloomery",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26818,
      "latitude": 38.999518,
      "longitude": -78.821226,
      "city": "Fisher",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26823,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Capon Springs",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26824,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Junction",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26833,
      "latitude": 39.116709,
      "longitude": -79.134177,
      "city": "Maysville",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26836,
      "latitude": 39.018679,
      "longitude": -78.820561,
      "city": "Moorefield",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26838,
      "latitude": 38.999518,
      "longitude": -78.821226,
      "city": "Milam",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26845,
      "latitude": 38.999518,
      "longitude": -78.821226,
      "city": "Old Fields",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26847,
      "latitude": 38.990838,
      "longitude": -79.129301,
      "city": "Petersburg",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26851,
      "latitude": 39.077365,
      "longitude": -78.593865,
      "city": "Wardensville",
      "state": "WV",
      "county": "Hardy"
  },
  {
      "zipCode": 26852,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Purgitsville",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26855,
      "latitude": 39.070601,
      "longitude": -79.233536,
      "city": "Cabins",
      "state": "WV",
      "county": "Grant"
  },
  {
      "zipCode": 26865,
      "latitude": 39.314071,
      "longitude": -78.659428,
      "city": "Yellow Spring",
      "state": "WV",
      "county": "Hampshire"
  },
  {
      "zipCode": 26866,
      "latitude": 38.684692,
      "longitude": -79.353564,
      "city": "Upper Tract",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 26884,
      "latitude": 38.684692,
      "longitude": -79.353564,
      "city": "Seneca Rocks",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 26886,
      "latitude": 38.684692,
      "longitude": -79.353564,
      "city": "Onego",
      "state": "WV",
      "county": "Pendleton"
  },
  {
      "zipCode": 27006,
      "latitude": 35.945381,
      "longitude": -80.448686,
      "city": "Advance",
      "state": "NC",
      "county": "Davie"
  },
  {
      "zipCode": 27007,
      "latitude": 36.385903,
      "longitude": -80.589507,
      "city": "Ararat",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27009,
      "latitude": 36.213266,
      "longitude": -80.084667,
      "city": "Belews Creek",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27010,
      "latitude": 36.182975,
      "longitude": -80.338662,
      "city": "Bethania",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27011,
      "latitude": 36.21397,
      "longitude": -80.690386,
      "city": "Boonville",
      "state": "NC",
      "county": "Yadkin"
  },
  {
      "zipCode": 27012,
      "latitude": 36.081218,
      "longitude": -80.328298,
      "city": "Clemmons",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27013,
      "latitude": 35.755719,
      "longitude": -80.681056,
      "city": "Cleveland",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 27014,
      "latitude": 35.819175,
      "longitude": -80.54826,
      "city": "Cooleemee",
      "state": "NC",
      "county": "Davie"
  },
  {
      "zipCode": 27016,
      "latitude": 36.446831,
      "longitude": -80.220095,
      "city": "Danbury",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27017,
      "latitude": 36.357619,
      "longitude": -80.697202,
      "city": "Dobson",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27018,
      "latitude": 36.197851,
      "longitude": -80.575334,
      "city": "East Bend",
      "state": "NC",
      "county": "Yadkin"
  },
  {
      "zipCode": 27019,
      "latitude": 36.316152,
      "longitude": -80.234934,
      "city": "Germanton",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27020,
      "latitude": 36.119371,
      "longitude": -80.80111,
      "city": "Hamptonville",
      "state": "NC",
      "county": "Yadkin"
  },
  {
      "zipCode": 27021,
      "latitude": 36.320179,
      "longitude": -80.292174,
      "city": "King",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27022,
      "latitude": 36.503292,
      "longitude": -80.216866,
      "city": "Lawsonville",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27023,
      "latitude": 36.15511,
      "longitude": -80.441566,
      "city": "Lewisville",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27024,
      "latitude": 36.490434,
      "longitude": -80.823878,
      "city": "Lowgap",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27025,
      "latitude": 36.348831,
      "longitude": -79.894851,
      "city": "Madison",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27027,
      "latitude": 36.447936,
      "longitude": -79.930301,
      "city": "Mayodan",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27028,
      "latitude": 35.906241,
      "longitude": -80.54609,
      "city": "Mocksville",
      "state": "NC",
      "county": "Davie"
  },
  {
      "zipCode": 27030,
      "latitude": 36.444303,
      "longitude": -80.665406,
      "city": "Mount Airy",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27031,
      "latitude": 36.399366,
      "longitude": -80.70809,
      "city": "White Plains",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27040,
      "latitude": 36.160959,
      "longitude": -80.378143,
      "city": "Pfafftown",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27041,
      "latitude": 36.422632,
      "longitude": -80.505421,
      "city": "Pilot Mountain",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27042,
      "latitude": 36.340817,
      "longitude": -80.055357,
      "city": "Pine Hall",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27043,
      "latitude": 36.346198,
      "longitude": -80.404421,
      "city": "Pinnacle",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27045,
      "latitude": 36.230211,
      "longitude": -80.329602,
      "city": "Rural Hall",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27046,
      "latitude": 36.483901,
      "longitude": -80.105319,
      "city": "Sandy Ridge",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27047,
      "latitude": 36.329166,
      "longitude": -80.56777,
      "city": "Siloam",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27048,
      "latitude": 36.466883,
      "longitude": -79.912823,
      "city": "Stoneville",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27049,
      "latitude": 36.551332,
      "longitude": -80.655786,
      "city": "Toast",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27050,
      "latitude": 36.22358,
      "longitude": -80.395517,
      "city": "Tobaccoville",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27051,
      "latitude": 36.191405,
      "longitude": -80.16357,
      "city": "Walkertown",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27052,
      "latitude": 36.34518,
      "longitude": -80.159989,
      "city": "Walnut Cove",
      "state": "NC",
      "county": "Stokes"
  },
  {
      "zipCode": 27053,
      "latitude": 36.490659,
      "longitude": -80.463069,
      "city": "Westfield",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 27054,
      "latitude": 35.790116,
      "longitude": -80.605096,
      "city": "Woodleaf",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 27055,
      "latitude": 36.136665,
      "longitude": -80.682984,
      "city": "Yadkinville",
      "state": "NC",
      "county": "Yadkin"
  },
  {
      "zipCode": 27094,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Rural Hall",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27098,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Rural Hall",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27099,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Rural Hall",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27101,
      "latitude": 36.101817,
      "longitude": -80.28762,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27102,
      "latitude": 36.032304,
      "longitude": -80.396168,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27103,
      "latitude": 36.058599,
      "longitude": -80.320965,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27104,
      "latitude": 36.097952,
      "longitude": -80.329096,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27105,
      "latitude": 36.161935,
      "longitude": -80.241287,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27106,
      "latitude": 36.149337,
      "longitude": -80.325815,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27107,
      "latitude": 36.04803,
      "longitude": -80.182863,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27108,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27109,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27110,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27111,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27113,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27114,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27115,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27116,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27117,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27120,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27127,
      "latitude": 36.040815,
      "longitude": -80.282645,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27130,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27150,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27151,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27152,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27155,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27156,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27157,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27198,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27199,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Winston Salem",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27201,
      "latitude": 36.031836,
      "longitude": -79.485643,
      "city": "Alamance",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27202,
      "latitude": 36.185517,
      "longitude": -79.505479,
      "city": "Altamahaw",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27203,
      "latitude": 35.708376,
      "longitude": -79.85213,
      "city": "Asheboro",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27204,
      "latitude": 35.788286,
      "longitude": -79.720556,
      "city": "Asheboro",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27205,
      "latitude": 35.638259,
      "longitude": -79.850866,
      "city": "Asheboro",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27207,
      "latitude": 35.608354,
      "longitude": -79.372362,
      "city": "Bear Creek",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27208,
      "latitude": 35.573086,
      "longitude": -79.52309,
      "city": "Bennett",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27209,
      "latitude": 35.365836,
      "longitude": -79.943839,
      "city": "Biscoe",
      "state": "NC",
      "county": "Montgomery"
  },
  {
      "zipCode": 27212,
      "latitude": 36.466412,
      "longitude": -79.275549,
      "city": "Blanch",
      "state": "NC",
      "county": "Caswell"
  },
  {
      "zipCode": 27213,
      "latitude": 35.648194,
      "longitude": -79.414859,
      "city": "Bonlee",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27214,
      "latitude": 36.193624,
      "longitude": -79.674079,
      "city": "Browns Summit",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27215,
      "latitude": 36.091025,
      "longitude": -79.430129,
      "city": "Burlington",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27216,
      "latitude": 36.047523,
      "longitude": -79.479743,
      "city": "Burlington",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27217,
      "latitude": 36.150681,
      "longitude": -79.3944,
      "city": "Burlington",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27220,
      "latitude": 36.046677,
      "longitude": -79.389631,
      "city": "Burlington",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27228,
      "latitude": 35.783476,
      "longitude": -79.129791,
      "city": "Bynum",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27229,
      "latitude": 35.264204,
      "longitude": -79.800002,
      "city": "Candor",
      "state": "NC",
      "county": "Montgomery"
  },
  {
      "zipCode": 27230,
      "latitude": 35.802758,
      "longitude": -79.879711,
      "city": "Cedar Falls",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27231,
      "latitude": 36.181138,
      "longitude": -79.164741,
      "city": "Cedar Grove",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27233,
      "latitude": 35.937583,
      "longitude": -79.69325,
      "city": "Climax",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27235,
      "latitude": 36.094489,
      "longitude": -80.004721,
      "city": "Colfax",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27237,
      "latitude": 35.467567,
      "longitude": -79.165314,
      "city": "Cumnock",
      "state": "NC",
      "county": "Lee"
  },
  {
      "zipCode": 27239,
      "latitude": 35.608108,
      "longitude": -80.144554,
      "city": "Denton",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27242,
      "latitude": 35.326471,
      "longitude": -79.548614,
      "city": "Eagle Springs",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 27243,
      "latitude": 36.06984,
      "longitude": -79.198764,
      "city": "Efland",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27244,
      "latitude": 36.164963,
      "longitude": -79.446213,
      "city": "Elon College",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27247,
      "latitude": 35.451411,
      "longitude": -79.783404,
      "city": "Ether",
      "state": "NC",
      "county": "Montgomery"
  },
  {
      "zipCode": 27248,
      "latitude": 35.785766,
      "longitude": -79.710068,
      "city": "Franklinville",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27249,
      "latitude": 36.150393,
      "longitude": -79.610426,
      "city": "Gibsonville",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27252,
      "latitude": 35.576848,
      "longitude": -79.363115,
      "city": "Goldston",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27253,
      "latitude": 35.972114,
      "longitude": -79.347527,
      "city": "Graham",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27256,
      "latitude": 35.562695,
      "longitude": -79.33863,
      "city": "Gulf",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27258,
      "latitude": 36.042367,
      "longitude": -79.324239,
      "city": "Haw River",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27259,
      "latitude": 35.492077,
      "longitude": -79.479016,
      "city": "Highfalls",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 27260,
      "latitude": 35.993538,
      "longitude": -79.988711,
      "city": "High Point",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27261,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "High Point",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27262,
      "latitude": 35.955786,
      "longitude": -80.013043,
      "city": "High Point",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27263,
      "latitude": 35.935894,
      "longitude": -79.93955,
      "city": "High Point",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27264,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "High Point",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27265,
      "latitude": 36.029892,
      "longitude": -79.991542,
      "city": "High Point",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27278,
      "latitude": 36.077432,
      "longitude": -79.085469,
      "city": "Hillsborough",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27281,
      "latitude": 35.186814,
      "longitude": -79.561566,
      "city": "Jackson Springs",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 27282,
      "latitude": 35.996926,
      "longitude": -79.926902,
      "city": "Jamestown",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27283,
      "latitude": 35.952564,
      "longitude": -79.636747,
      "city": "Julian",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27284,
      "latitude": 36.118312,
      "longitude": -80.101271,
      "city": "Kernersville",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27285,
      "latitude": 36.027482,
      "longitude": -80.20728,
      "city": "Kernersville",
      "state": "NC",
      "county": "Forsyth"
  },
  {
      "zipCode": 27288,
      "latitude": 36.486369,
      "longitude": -79.749957,
      "city": "Eden",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27289,
      "latitude": 36.392135,
      "longitude": -79.773094,
      "city": "Eden",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27291,
      "latitude": 36.364834,
      "longitude": -79.187938,
      "city": "Leasburg",
      "state": "NC",
      "county": "Caswell"
  },
  {
      "zipCode": 27292,
      "latitude": 35.801894,
      "longitude": -80.210762,
      "city": "Lexington",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27293,
      "latitude": 35.882943,
      "longitude": -80.275963,
      "city": "Lexington",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27294,
      "latitude": 35.882943,
      "longitude": -80.275963,
      "city": "Lexington",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27295,
      "latitude": 35.813292,
      "longitude": -80.277877,
      "city": "Lexington",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27298,
      "latitude": 35.838293,
      "longitude": -79.674436,
      "city": "Liberty",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27299,
      "latitude": 35.743919,
      "longitude": -80.349473,
      "city": "Linwood",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27301,
      "latitude": 36.108629,
      "longitude": -79.670689,
      "city": "Mc Leansville",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27302,
      "latitude": 36.087114,
      "longitude": -79.306093,
      "city": "Mebane",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27305,
      "latitude": 36.512445,
      "longitude": -79.235356,
      "city": "Milton",
      "state": "NC",
      "county": "Caswell"
  },
  {
      "zipCode": 27306,
      "latitude": 35.247617,
      "longitude": -79.923328,
      "city": "Mount Gilead",
      "state": "NC",
      "county": "Montgomery"
  },
  {
      "zipCode": 27310,
      "latitude": 36.166822,
      "longitude": -79.948214,
      "city": "Oak Ridge",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27311,
      "latitude": 36.47025,
      "longitude": -79.460025,
      "city": "Pelham",
      "state": "NC",
      "county": "Caswell"
  },
  {
      "zipCode": 27312,
      "latitude": 35.745239,
      "longitude": -79.213195,
      "city": "Pittsboro",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27313,
      "latitude": 35.950913,
      "longitude": -79.765959,
      "city": "Pleasant Garden",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27314,
      "latitude": 36.293921,
      "longitude": -79.200365,
      "city": "Prospect Hill",
      "state": "NC",
      "county": "Caswell"
  },
  {
      "zipCode": 27315,
      "latitude": 36.487281,
      "longitude": -79.404114,
      "city": "Providence",
      "state": "NC",
      "county": "Caswell"
  },
  {
      "zipCode": 27316,
      "latitude": 35.714279,
      "longitude": -79.646945,
      "city": "Ramseur",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27317,
      "latitude": 35.823611,
      "longitude": -79.712537,
      "city": "Randleman",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27320,
      "latitude": 36.358275,
      "longitude": -79.742001,
      "city": "Reidsville",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27321,
      "latitude": 36.347568,
      "longitude": -79.680318,
      "city": "Reidsville",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27322,
      "latitude": 36.392135,
      "longitude": -79.773094,
      "city": "Reidsville",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27323,
      "latitude": 36.392135,
      "longitude": -79.773094,
      "city": "Reidsville",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27325,
      "latitude": 35.393319,
      "longitude": -79.518562,
      "city": "Robbins",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 27326,
      "latitude": 36.461083,
      "longitude": -79.597001,
      "city": "Ruffin",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27330,
      "latitude": 35.469782,
      "longitude": -79.171723,
      "city": "Sanford",
      "state": "NC",
      "county": "Lee"
  },
  {
      "zipCode": 27331,
      "latitude": 35.372577,
      "longitude": -79.276577,
      "city": "Sanford",
      "state": "NC",
      "county": "Lee"
  },
  {
      "zipCode": 27332,
      "latitude": 35.372219,
      "longitude": -79.148794,
      "city": "Sanford",
      "state": "NC",
      "county": "Lee"
  },
  {
      "zipCode": 27340,
      "latitude": 35.948814,
      "longitude": -79.329664,
      "city": "Saxapahaw",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27341,
      "latitude": 35.562753,
      "longitude": -79.698691,
      "city": "Seagrove",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27342,
      "latitude": 36.075623,
      "longitude": -79.620641,
      "city": "Sedalia",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27343,
      "latitude": 36.493537,
      "longitude": -79.060407,
      "city": "Semora",
      "state": "NC",
      "county": "Person"
  },
  {
      "zipCode": 27344,
      "latitude": 35.711578,
      "longitude": -79.333922,
      "city": "Siler City",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27349,
      "latitude": 35.91485,
      "longitude": -79.403671,
      "city": "Snow Camp",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27350,
      "latitude": 35.817452,
      "longitude": -79.908483,
      "city": "Sophia",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27351,
      "latitude": 35.660548,
      "longitude": -80.283647,
      "city": "Southmont",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27355,
      "latitude": 35.799927,
      "longitude": -79.593442,
      "city": "Staley",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27356,
      "latitude": 35.439894,
      "longitude": -79.825137,
      "city": "Star",
      "state": "NC",
      "county": "Montgomery"
  },
  {
      "zipCode": 27357,
      "latitude": 36.225751,
      "longitude": -79.974028,
      "city": "Stokesdale",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27358,
      "latitude": 36.200019,
      "longitude": -79.880028,
      "city": "Summerfield",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27359,
      "latitude": 36.021389,
      "longitude": -79.357399,
      "city": "Swepsonville",
      "state": "NC",
      "county": "Alamance"
  },
  {
      "zipCode": 27360,
      "latitude": 35.854539,
      "longitude": -80.124764,
      "city": "Thomasville",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27361,
      "latitude": 35.882943,
      "longitude": -80.275963,
      "city": "Thomasville",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27370,
      "latitude": 35.801501,
      "longitude": -79.961118,
      "city": "Trinity",
      "state": "NC",
      "county": "Randolph"
  },
  {
      "zipCode": 27371,
      "latitude": 35.393595,
      "longitude": -79.907346,
      "city": "Troy",
      "state": "NC",
      "county": "Montgomery"
  },
  {
      "zipCode": 27373,
      "latitude": 35.882943,
      "longitude": -80.275963,
      "city": "Wallburg",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27374,
      "latitude": 35.781848,
      "longitude": -80.203546,
      "city": "Welcome",
      "state": "NC",
      "county": "Davidson"
  },
  {
      "zipCode": 27375,
      "latitude": 36.392135,
      "longitude": -79.773094,
      "city": "Wentworth",
      "state": "NC",
      "county": "Rockingham"
  },
  {
      "zipCode": 27376,
      "latitude": 35.240268,
      "longitude": -79.468201,
      "city": "West End",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 27377,
      "latitude": 36.046202,
      "longitude": -79.608882,
      "city": "Whitsett",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27379,
      "latitude": 36.379479,
      "longitude": -79.366733,
      "city": "Yanceyville",
      "state": "NC",
      "county": "Caswell"
  },
  {
      "zipCode": 27401,
      "latitude": 36.055285,
      "longitude": -79.831692,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27402,
      "latitude": 36.106711,
      "longitude": -79.791901,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27403,
      "latitude": 36.089396,
      "longitude": -79.823336,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27404,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27405,
      "latitude": 36.114003,
      "longitude": -79.787099,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27406,
      "latitude": 35.991647,
      "longitude": -79.759794,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27407,
      "latitude": 36.001791,
      "longitude": -79.888672,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27408,
      "latitude": 36.103685,
      "longitude": -79.816254,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27409,
      "latitude": 36.087534,
      "longitude": -79.93439,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27410,
      "latitude": 36.119984,
      "longitude": -79.870925,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27411,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27412,
      "latitude": 36.066129,
      "longitude": -79.806735,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27413,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27415,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27416,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27417,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27419,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27420,
      "latitude": 36.112987,
      "longitude": -79.775913,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27425,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27427,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27429,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27435,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27438,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27455,
      "latitude": 36.182391,
      "longitude": -79.805975,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27480,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27495,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27498,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27499,
      "latitude": 36.080707,
      "longitude": -80.0244,
      "city": "Greensboro",
      "state": "NC",
      "county": "Guilford"
  },
  {
      "zipCode": 27501,
      "latitude": 35.478164,
      "longitude": -78.729411,
      "city": "Angier",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 27502,
      "latitude": 35.748012,
      "longitude": -78.834012,
      "city": "Apex",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27503,
      "latitude": 36.09206,
      "longitude": -78.885147,
      "city": "Bahama",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27504,
      "latitude": 35.40709,
      "longitude": -78.402967,
      "city": "Benson",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27505,
      "latitude": 35.471966,
      "longitude": -79.058238,
      "city": "Broadway",
      "state": "NC",
      "county": "Lee"
  },
  {
      "zipCode": 27506,
      "latitude": 35.420467,
      "longitude": -78.713729,
      "city": "Buies Creek",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 27507,
      "latitude": 36.451463,
      "longitude": -78.566691,
      "city": "Bullock",
      "state": "NC",
      "county": "Granville"
  },
  {
      "zipCode": 27508,
      "latitude": 35.982555,
      "longitude": -78.208581,
      "city": "Bunn",
      "state": "NC",
      "county": "Franklin"
  },
  {
      "zipCode": 27509,
      "latitude": 36.119588,
      "longitude": -78.768421,
      "city": "Butner",
      "state": "NC",
      "county": "Granville"
  },
  {
      "zipCode": 27510,
      "latitude": 35.905477,
      "longitude": -79.090077,
      "city": "Carrboro",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27511,
      "latitude": 35.751243,
      "longitude": -78.70732,
      "city": "Cary",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27512,
      "latitude": 35.808387,
      "longitude": -78.839488,
      "city": "Cary",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27513,
      "latitude": 35.801341,
      "longitude": -78.816564,
      "city": "Cary",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27514,
      "latitude": 36.004647,
      "longitude": -79.054475,
      "city": "Chapel Hill",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27515,
      "latitude": 36.05251,
      "longitude": -79.107692,
      "city": "Chapel Hill",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27516,
      "latitude": 35.946263,
      "longitude": -79.136862,
      "city": "Chapel Hill",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27517,
      "latitude": 35.842663,
      "longitude": -79.032015,
      "city": "Chapel Hill",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27518,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Cary",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27519,
      "latitude": 35.76812,
      "longitude": -78.829548,
      "city": "Cary",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27520,
      "latitude": 35.633257,
      "longitude": -78.435416,
      "city": "Clayton",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27521,
      "latitude": 35.425247,
      "longitude": -78.656553,
      "city": "Coats",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 27522,
      "latitude": 36.108089,
      "longitude": -78.671974,
      "city": "Creedmoor",
      "state": "NC",
      "county": "Granville"
  },
  {
      "zipCode": 27524,
      "latitude": 35.4282,
      "longitude": -78.339839,
      "city": "Four Oaks",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27525,
      "latitude": 36.068066,
      "longitude": -78.399881,
      "city": "Franklinton",
      "state": "NC",
      "county": "Franklin"
  },
  {
      "zipCode": 27526,
      "latitude": 35.758691,
      "longitude": -78.646886,
      "city": "Fuquay Varina",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27529,
      "latitude": 35.714139,
      "longitude": -78.66304,
      "city": "Garner",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27530,
      "latitude": 35.368277,
      "longitude": -78.092871,
      "city": "Goldsboro",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 27531,
      "latitude": 35.463121,
      "longitude": -77.995728,
      "city": "Goldsboro",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 27532,
      "latitude": 35.372045,
      "longitude": -78.052422,
      "city": "Goldsboro",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 27533,
      "latitude": 35.372045,
      "longitude": -78.052422,
      "city": "Goldsboro",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 27534,
      "latitude": 35.385571,
      "longitude": -78.03207,
      "city": "Goldsboro",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 27536,
      "latitude": 36.38698,
      "longitude": -78.396213,
      "city": "Henderson",
      "state": "NC",
      "county": "Vance"
  },
  {
      "zipCode": 27537,
      "latitude": 36.358726,
      "longitude": -78.398384,
      "city": "Henderson",
      "state": "NC",
      "county": "Vance"
  },
  {
      "zipCode": 27540,
      "latitude": 35.607666,
      "longitude": -78.829724,
      "city": "Holly Springs",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27541,
      "latitude": 36.314975,
      "longitude": -79.06404,
      "city": "Hurdle Mills",
      "state": "NC",
      "county": "Person"
  },
  {
      "zipCode": 27542,
      "latitude": 35.571141,
      "longitude": -78.287178,
      "city": "Kenly",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27543,
      "latitude": 35.429849,
      "longitude": -78.882395,
      "city": "Kipling",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 27544,
      "latitude": 36.220048,
      "longitude": -78.450087,
      "city": "Kittrell",
      "state": "NC",
      "county": "Vance"
  },
  {
      "zipCode": 27545,
      "latitude": 35.788031,
      "longitude": -78.459605,
      "city": "Knightdale",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27546,
      "latitude": 35.399221,
      "longitude": -78.871194,
      "city": "Lillington",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 27549,
      "latitude": 36.065143,
      "longitude": -78.277943,
      "city": "Louisburg",
      "state": "NC",
      "county": "Franklin"
  },
  {
      "zipCode": 27551,
      "latitude": 36.507009,
      "longitude": -77.997521,
      "city": "Macon",
      "state": "NC",
      "county": "Warren"
  },
  {
      "zipCode": 27552,
      "latitude": 35.413579,
      "longitude": -78.979964,
      "city": "Mamers",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 27553,
      "latitude": 36.468871,
      "longitude": -78.336604,
      "city": "Manson",
      "state": "NC",
      "county": "Vance"
  },
  {
      "zipCode": 27555,
      "latitude": 35.629518,
      "longitude": -78.204968,
      "city": "Micro",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27556,
      "latitude": 36.413473,
      "longitude": -78.327491,
      "city": "Middleburg",
      "state": "NC",
      "county": "Vance"
  },
  {
      "zipCode": 27557,
      "latitude": 35.587186,
      "longitude": -78.232745,
      "city": "Middlesex",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27559,
      "latitude": 35.623508,
      "longitude": -79.098782,
      "city": "Moncure",
      "state": "NC",
      "county": "Chatham"
  },
  {
      "zipCode": 27560,
      "latitude": 35.852458,
      "longitude": -78.843526,
      "city": "Morrisville",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27562,
      "latitude": 35.643693,
      "longitude": -78.867658,
      "city": "New Hill",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27563,
      "latitude": 36.449642,
      "longitude": -78.185544,
      "city": "Norlina",
      "state": "NC",
      "county": "Warren"
  },
  {
      "zipCode": 27564,
      "latitude": 36.101529,
      "longitude": -78.713346,
      "city": "Creedmoor",
      "state": "NC",
      "county": "Granville"
  },
  {
      "zipCode": 27565,
      "latitude": 36.348168,
      "longitude": -78.65313,
      "city": "Oxford",
      "state": "NC",
      "county": "Granville"
  },
  {
      "zipCode": 27568,
      "latitude": 35.488348,
      "longitude": -78.217864,
      "city": "Pine Level",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27569,
      "latitude": 35.471193,
      "longitude": -78.164309,
      "city": "Princeton",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27570,
      "latitude": 36.37367,
      "longitude": -78.110931,
      "city": "Ridgeway",
      "state": "NC",
      "county": "Warren"
  },
  {
      "zipCode": 27571,
      "latitude": 35.906468,
      "longitude": -78.45482,
      "city": "Rolesville",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27572,
      "latitude": 36.205828,
      "longitude": -78.878578,
      "city": "Rougemont",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27573,
      "latitude": 36.39165,
      "longitude": -78.950937,
      "city": "Roxboro",
      "state": "NC",
      "county": "Person"
  },
  {
      "zipCode": 27576,
      "latitude": 35.563572,
      "longitude": -78.244459,
      "city": "Selma",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27577,
      "latitude": 35.536543,
      "longitude": -78.336001,
      "city": "Smithfield",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27581,
      "latitude": 36.209675,
      "longitude": -78.72704,
      "city": "Stem",
      "state": "NC",
      "county": "Granville"
  },
  {
      "zipCode": 27582,
      "latitude": 36.442431,
      "longitude": -78.557098,
      "city": "Stovall",
      "state": "NC",
      "county": "Granville"
  },
  {
      "zipCode": 27583,
      "latitude": 36.311201,
      "longitude": -78.92292,
      "city": "Timberlake",
      "state": "NC",
      "county": "Person"
  },
  {
      "zipCode": 27584,
      "latitude": 36.518058,
      "longitude": -78.400865,
      "city": "Townsville",
      "state": "NC",
      "county": "Vance"
  },
  {
      "zipCode": 27586,
      "latitude": 36.37367,
      "longitude": -78.110931,
      "city": "Vaughan",
      "state": "NC",
      "county": "Warren"
  },
  {
      "zipCode": 27587,
      "latitude": 35.858041,
      "longitude": -78.586534,
      "city": "Wake Forest",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27588,
      "latitude": 35.973108,
      "longitude": -78.450754,
      "city": "Wake Forest",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27589,
      "latitude": 36.339089,
      "longitude": -78.190028,
      "city": "Warrenton",
      "state": "NC",
      "county": "Warren"
  },
  {
      "zipCode": 27591,
      "latitude": 35.811324,
      "longitude": -78.395966,
      "city": "Wendell",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27592,
      "latitude": 35.67238,
      "longitude": -78.534929,
      "city": "Willow Spring",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27593,
      "latitude": 35.590731,
      "longitude": -78.360723,
      "city": "Wilsons Mills",
      "state": "NC",
      "county": "Johnston"
  },
  {
      "zipCode": 27594,
      "latitude": 36.37367,
      "longitude": -78.110931,
      "city": "Wise",
      "state": "NC",
      "county": "Warren"
  },
  {
      "zipCode": 27596,
      "latitude": 36.018749,
      "longitude": -78.438181,
      "city": "Youngsville",
      "state": "NC",
      "county": "Franklin"
  },
  {
      "zipCode": 27597,
      "latitude": 35.87922,
      "longitude": -78.355582,
      "city": "Zebulon",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27599,
      "latitude": 36.05251,
      "longitude": -79.107692,
      "city": "Chapel Hill",
      "state": "NC",
      "county": "Orange"
  },
  {
      "zipCode": 27601,
      "latitude": 35.773755,
      "longitude": -78.604175,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27602,
      "latitude": 35.758667,
      "longitude": -78.671089,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27603,
      "latitude": 35.689086,
      "longitude": -78.675118,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27604,
      "latitude": 35.837111,
      "longitude": -78.555342,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27605,
      "latitude": 35.789601,
      "longitude": -78.652475,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27606,
      "latitude": 35.715972,
      "longitude": -78.747741,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27607,
      "latitude": 35.804237,
      "longitude": -78.707101,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27608,
      "latitude": 35.80895,
      "longitude": -78.646238,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27609,
      "latitude": 35.841146,
      "longitude": -78.630413,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27610,
      "latitude": 35.7429,
      "longitude": -78.564443,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27611,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27612,
      "latitude": 35.821942,
      "longitude": -78.675135,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27613,
      "latitude": 35.86952,
      "longitude": -78.705465,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27614,
      "latitude": 35.972686,
      "longitude": -78.620292,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27615,
      "latitude": 35.918893,
      "longitude": -78.627455,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27616,
      "latitude": 35.805765,
      "longitude": -78.688301,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27617,
      "latitude": 35.908845,
      "longitude": -78.772396,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27619,
      "latitude": 35.851463,
      "longitude": -78.63141,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27620,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27621,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27622,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27623,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27624,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27625,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27626,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27627,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27628,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27629,
      "latitude": 35.817497,
      "longitude": -78.552388,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27634,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27635,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27636,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27640,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27650,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27656,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27658,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27661,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27668,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27675,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27676,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27690,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27695,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27697,
      "latitude": 35.88224,
      "longitude": -78.413371,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27698,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27699,
      "latitude": 35.797692,
      "longitude": -78.625265,
      "city": "Raleigh",
      "state": "NC",
      "county": "Wake"
  },
  {
      "zipCode": 27701,
      "latitude": 36.004307,
      "longitude": -78.875467,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27702,
      "latitude": 36.051155,
      "longitude": -78.857651,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27703,
      "latitude": 35.957366,
      "longitude": -78.821694,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27704,
      "latitude": 36.037132,
      "longitude": -78.828086,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27705,
      "latitude": 36.019354,
      "longitude": -78.899525,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27706,
      "latitude": 35.997043,
      "longitude": -78.94223,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27707,
      "latitude": 35.94816,
      "longitude": -78.941599,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27708,
      "latitude": 36.028685,
      "longitude": -78.92398,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27709,
      "latitude": 36.051155,
      "longitude": -78.857651,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27710,
      "latitude": 36.051155,
      "longitude": -78.857651,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27711,
      "latitude": 36.051155,
      "longitude": -78.857651,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27712,
      "latitude": 36.091848,
      "longitude": -78.908043,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27713,
      "latitude": 35.911215,
      "longitude": -78.917768,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27715,
      "latitude": 36.051155,
      "longitude": -78.857651,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27717,
      "latitude": 36.051155,
      "longitude": -78.857651,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27722,
      "latitude": 36.051155,
      "longitude": -78.857651,
      "city": "Durham",
      "state": "NC",
      "county": "Durham"
  },
  {
      "zipCode": 27801,
      "latitude": 35.949051,
      "longitude": -77.641181,
      "city": "Rocky Mount",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27802,
      "latitude": 35.935563,
      "longitude": -77.78077,
      "city": "Rocky Mount",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27803,
      "latitude": 35.902088,
      "longitude": -77.874947,
      "city": "Rocky Mount",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27804,
      "latitude": 35.941739,
      "longitude": -77.939841,
      "city": "Rocky Mount",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27805,
      "latitude": 36.169398,
      "longitude": -77.097225,
      "city": "Aulander",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27806,
      "latitude": 35.373666,
      "longitude": -76.861978,
      "city": "Aurora",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27807,
      "latitude": 35.800198,
      "longitude": -78.10562,
      "city": "Bailey",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27808,
      "latitude": 35.469911,
      "longitude": -76.756914,
      "city": "Bath",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27809,
      "latitude": 36.006705,
      "longitude": -77.707389,
      "city": "Battleboro",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27810,
      "latitude": 35.445752,
      "longitude": -76.639119,
      "city": "Belhaven",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27811,
      "latitude": 35.584685,
      "longitude": -77.513357,
      "city": "Bellarthur",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27812,
      "latitude": 35.757803,
      "longitude": -77.39351,
      "city": "Bethel",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27813,
      "latitude": 35.616471,
      "longitude": -77.935161,
      "city": "Black Creek",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27814,
      "latitude": 35.500693,
      "longitude": -76.977521,
      "city": "Blounts Creek",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27816,
      "latitude": 36.09786,
      "longitude": -78.03876,
      "city": "Castalia",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27817,
      "latitude": 35.460458,
      "longitude": -77.063047,
      "city": "Chocowinity",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27818,
      "latitude": 36.491011,
      "longitude": -77.031055,
      "city": "Como",
      "state": "NC",
      "county": "Hertford"
  },
  {
      "zipCode": 27819,
      "latitude": 35.817763,
      "longitude": -77.453286,
      "city": "Conetoe",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27820,
      "latitude": 36.450554,
      "longitude": -77.452452,
      "city": "Conway",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27821,
      "latitude": 35.327785,
      "longitude": -76.872371,
      "city": "Edward",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27822,
      "latitude": 35.787273,
      "longitude": -77.856015,
      "city": "Elm City",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27823,
      "latitude": 36.279884,
      "longitude": -77.723443,
      "city": "Enfield",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27824,
      "latitude": 35.325223,
      "longitude": -76.028472,
      "city": "Engelhard",
      "state": "NC",
      "county": "Hyde"
  },
  {
      "zipCode": 27825,
      "latitude": 35.866711,
      "longitude": -77.084958,
      "city": "Everetts",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27826,
      "latitude": 35.510544,
      "longitude": -76.179248,
      "city": "Fairfield",
      "state": "NC",
      "county": "Hyde"
  },
  {
      "zipCode": 27827,
      "latitude": 35.693518,
      "longitude": -77.511147,
      "city": "Falkland",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27828,
      "latitude": 35.663061,
      "longitude": -77.456086,
      "city": "Farmville",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27829,
      "latitude": 35.687351,
      "longitude": -77.59113,
      "city": "Fountain",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27830,
      "latitude": 35.469355,
      "longitude": -77.979026,
      "city": "Fremont",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 27831,
      "latitude": 36.482084,
      "longitude": -77.568112,
      "city": "Garysburg",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27832,
      "latitude": 36.417965,
      "longitude": -77.441731,
      "city": "Gaston",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27833,
      "latitude": 35.580444,
      "longitude": -77.392609,
      "city": "Greenville",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27834,
      "latitude": 35.631003,
      "longitude": -77.381019,
      "city": "Greenville",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27835,
      "latitude": 35.588523,
      "longitude": -77.353092,
      "city": "Greenville",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27836,
      "latitude": 35.580444,
      "longitude": -77.392609,
      "city": "Greenville",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27837,
      "latitude": 35.506325,
      "longitude": -77.214568,
      "city": "Grimesland",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27838,
      "latitude": 36.356129,
      "longitude": -77.483072,
      "city": "Gumberry",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27839,
      "latitude": 36.33719,
      "longitude": -77.629192,
      "city": "Halifax",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27840,
      "latitude": 35.963174,
      "longitude": -77.223554,
      "city": "Hamilton",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27841,
      "latitude": 35.906104,
      "longitude": -77.273825,
      "city": "Hassell",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27842,
      "latitude": 36.525838,
      "longitude": -77.8555,
      "city": "Henrico",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27843,
      "latitude": 36.059381,
      "longitude": -77.406093,
      "city": "Hobgood",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27844,
      "latitude": 36.237677,
      "longitude": -77.706792,
      "city": "Hollister",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27845,
      "latitude": 36.374826,
      "longitude": -77.370513,
      "city": "Jackson",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27846,
      "latitude": 35.805568,
      "longitude": -76.997456,
      "city": "Jamesville",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27847,
      "latitude": 36.187122,
      "longitude": -77.189818,
      "city": "Kelford",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27849,
      "latitude": 36.093332,
      "longitude": -77.143875,
      "city": "Lewiston Woodville",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27850,
      "latitude": 36.376924,
      "longitude": -77.709017,
      "city": "Littleton",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27851,
      "latitude": 35.674077,
      "longitude": -78.026484,
      "city": "Lucama",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27852,
      "latitude": 35.750972,
      "longitude": -77.670582,
      "city": "Macclesfield",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27853,
      "latitude": 36.356129,
      "longitude": -77.483072,
      "city": "Margarettsville",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27854,
      "latitude": 36.363144,
      "longitude": -77.386748,
      "city": "Milwaukee",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27855,
      "latitude": 36.449543,
      "longitude": -77.052051,
      "city": "Murfreesboro",
      "state": "NC",
      "county": "Hertford"
  },
  {
      "zipCode": 27856,
      "latitude": 36.02049,
      "longitude": -77.982782,
      "city": "Nashville",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27857,
      "latitude": 35.970377,
      "longitude": -77.251106,
      "city": "Oak City",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27858,
      "latitude": 35.616949,
      "longitude": -77.402789,
      "city": "Greenville",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27860,
      "latitude": 35.610477,
      "longitude": -76.717723,
      "city": "Pantego",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27861,
      "latitude": 35.819037,
      "longitude": -77.314288,
      "city": "Parmele",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27862,
      "latitude": 36.356129,
      "longitude": -77.483072,
      "city": "Pendleton",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27863,
      "latitude": 35.488295,
      "longitude": -77.972713,
      "city": "Pikeville",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 27864,
      "latitude": 35.793,
      "longitude": -77.633263,
      "city": "Pinetops",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27865,
      "latitude": 35.574633,
      "longitude": -76.776212,
      "city": "Pinetown",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27866,
      "latitude": 36.5044,
      "longitude": -77.55694,
      "city": "Pleasant Hill",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27867,
      "latitude": 36.356129,
      "longitude": -77.483072,
      "city": "Potecasi",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27868,
      "latitude": 36.054832,
      "longitude": -77.911635,
      "city": "Red Oak",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27869,
      "latitude": 36.31037,
      "longitude": -77.318446,
      "city": "Rich Square",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27870,
      "latitude": 36.303962,
      "longitude": -77.641063,
      "city": "Roanoke Rapids",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27871,
      "latitude": 35.834564,
      "longitude": -77.248489,
      "city": "Robersonville",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27872,
      "latitude": 36.216644,
      "longitude": -77.222348,
      "city": "Roxobel",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27873,
      "latitude": 35.649494,
      "longitude": -77.791845,
      "city": "Saratoga",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27874,
      "latitude": 36.23245,
      "longitude": -77.608355,
      "city": "Scotland Neck",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27875,
      "latitude": 35.471657,
      "longitude": -76.495091,
      "city": "Scranton",
      "state": "NC",
      "county": "Hyde"
  },
  {
      "zipCode": 27876,
      "latitude": 36.490454,
      "longitude": -77.442173,
      "city": "Seaboard",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27877,
      "latitude": 36.356129,
      "longitude": -77.483072,
      "city": "Severn",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27878,
      "latitude": 35.865387,
      "longitude": -77.838886,
      "city": "Sharpsburg",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27879,
      "latitude": 35.567478,
      "longitude": -77.280169,
      "city": "Simpson",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27880,
      "latitude": 35.738821,
      "longitude": -78.10039,
      "city": "Sims",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27881,
      "latitude": 35.915927,
      "longitude": -77.585471,
      "city": "Speed",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27882,
      "latitude": 35.94476,
      "longitude": -78.093002,
      "city": "Spring Hope",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27883,
      "latitude": 35.656386,
      "longitude": -77.805965,
      "city": "Stantonsburg",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27884,
      "latitude": 35.711302,
      "longitude": -77.266682,
      "city": "Stokes",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 27885,
      "latitude": 35.465559,
      "longitude": -76.271685,
      "city": "Swanquarter",
      "state": "NC",
      "county": "Hyde"
  },
  {
      "zipCode": 27886,
      "latitude": 35.928437,
      "longitude": -77.581631,
      "city": "Tarboro",
      "state": "NC",
      "county": "Edgecombe"
  },
  {
      "zipCode": 27887,
      "latitude": 36.235198,
      "longitude": -77.502602,
      "city": "Tillery",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27888,
      "latitude": 35.609289,
      "longitude": -77.713073,
      "city": "Walstonburg",
      "state": "NC",
      "county": "Greene"
  },
  {
      "zipCode": 27889,
      "latitude": 35.519268,
      "longitude": -76.8892,
      "city": "Washington",
      "state": "NC",
      "county": "Beaufort"
  },
  {
      "zipCode": 27890,
      "latitude": 36.389174,
      "longitude": -77.750353,
      "city": "Weldon",
      "state": "NC",
      "county": "Halifax"
  },
  {
      "zipCode": 27891,
      "latitude": 36.124661,
      "longitude": -77.825049,
      "city": "Whitakers",
      "state": "NC",
      "county": "Nash"
  },
  {
      "zipCode": 27892,
      "latitude": 35.821205,
      "longitude": -77.102193,
      "city": "Williamston",
      "state": "NC",
      "county": "Martin"
  },
  {
      "zipCode": 27893,
      "latitude": 35.723264,
      "longitude": -77.925269,
      "city": "Wilson",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27894,
      "latitude": 35.715778,
      "longitude": -77.904283,
      "city": "Wilson",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27895,
      "latitude": 35.719923,
      "longitude": -77.926691,
      "city": "Wilson",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27896,
      "latitude": 35.771467,
      "longitude": -77.973007,
      "city": "Wilson",
      "state": "NC",
      "county": "Wilson"
  },
  {
      "zipCode": 27897,
      "latitude": 36.393691,
      "longitude": -77.313667,
      "city": "Woodland",
      "state": "NC",
      "county": "Northampton"
  },
  {
      "zipCode": 27906,
      "latitude": 36.285388,
      "longitude": -76.213284,
      "city": "Elizabeth City",
      "state": "NC",
      "county": "Pasquotank"
  },
  {
      "zipCode": 27907,
      "latitude": 36.285026,
      "longitude": -76.255312,
      "city": "Elizabeth City",
      "state": "NC",
      "county": "Pasquotank"
  },
  {
      "zipCode": 27909,
      "latitude": 36.293633,
      "longitude": -76.271958,
      "city": "Elizabeth City",
      "state": "NC",
      "county": "Pasquotank"
  },
  {
      "zipCode": 27910,
      "latitude": 36.339311,
      "longitude": -76.990498,
      "city": "Ahoskie",
      "state": "NC",
      "county": "Hertford"
  },
  {
      "zipCode": 27915,
      "latitude": 35.776474,
      "longitude": -75.635823,
      "city": "Avon",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27916,
      "latitude": 36.307995,
      "longitude": -75.918814,
      "city": "Aydlett",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27917,
      "latitude": 36.370898,
      "longitude": -75.989967,
      "city": "Barco",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27919,
      "latitude": 36.257499,
      "longitude": -76.544379,
      "city": "Belvidere",
      "state": "NC",
      "county": "Perquimans"
  },
  {
      "zipCode": 27920,
      "latitude": 35.252534,
      "longitude": -75.572155,
      "city": "Buxton",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27921,
      "latitude": 36.389961,
      "longitude": -76.199736,
      "city": "Camden",
      "state": "NC",
      "county": "Camden"
  },
  {
      "zipCode": 27922,
      "latitude": 36.360803,
      "longitude": -76.905529,
      "city": "Cofield",
      "state": "NC",
      "county": "Hertford"
  },
  {
      "zipCode": 27923,
      "latitude": 36.383368,
      "longitude": -75.954658,
      "city": "Coinjock",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27924,
      "latitude": 36.152409,
      "longitude": -76.852518,
      "city": "Colerain",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27925,
      "latitude": 35.936934,
      "longitude": -76.203339,
      "city": "Columbia",
      "state": "NC",
      "county": "Tyrrell"
  },
  {
      "zipCode": 27926,
      "latitude": 36.520104,
      "longitude": -76.59285,
      "city": "Corapeake",
      "state": "NC",
      "county": "Gates"
  },
  {
      "zipCode": 27927,
      "latitude": 36.391941,
      "longitude": -75.825512,
      "city": "Corolla",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27928,
      "latitude": 35.829692,
      "longitude": -76.567703,
      "city": "Creswell",
      "state": "NC",
      "county": "Washington"
  },
  {
      "zipCode": 27929,
      "latitude": 36.439774,
      "longitude": -76.005478,
      "city": "Currituck",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27930,
      "latitude": 36.198539,
      "longitude": -76.373908,
      "city": "Durants Neck",
      "state": "NC",
      "county": "Perquimans"
  },
  {
      "zipCode": 27932,
      "latitude": 36.135621,
      "longitude": -76.580107,
      "city": "Edenton",
      "state": "NC",
      "county": "Chowan"
  },
  {
      "zipCode": 27935,
      "latitude": 36.416392,
      "longitude": -76.797951,
      "city": "Eure",
      "state": "NC",
      "county": "Gates"
  },
  {
      "zipCode": 27936,
      "latitude": 35.233846,
      "longitude": -75.614493,
      "city": "Frisco",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27937,
      "latitude": 36.493521,
      "longitude": -76.815386,
      "city": "Gates",
      "state": "NC",
      "county": "Gates"
  },
  {
      "zipCode": 27938,
      "latitude": 36.422539,
      "longitude": -76.714802,
      "city": "Gatesville",
      "state": "NC",
      "county": "Gates"
  },
  {
      "zipCode": 27939,
      "latitude": 36.293128,
      "longitude": -75.975578,
      "city": "Grandy",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27941,
      "latitude": 36.157985,
      "longitude": -75.833856,
      "city": "Harbinger",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27942,
      "latitude": 36.274551,
      "longitude": -76.786498,
      "city": "Harrellsville",
      "state": "NC",
      "county": "Hertford"
  },
  {
      "zipCode": 27943,
      "latitude": 35.692774,
      "longitude": -75.713785,
      "city": "Hatteras",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27944,
      "latitude": 36.168529,
      "longitude": -76.373128,
      "city": "Hertford",
      "state": "NC",
      "county": "Perquimans"
  },
  {
      "zipCode": 27946,
      "latitude": 36.365847,
      "longitude": -76.625361,
      "city": "Hobbsville",
      "state": "NC",
      "county": "Gates"
  },
  {
      "zipCode": 27947,
      "latitude": 36.171411,
      "longitude": -75.862128,
      "city": "Jarvisburg",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27948,
      "latitude": 35.772912,
      "longitude": -75.634892,
      "city": "Kill Devil Hills",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27949,
      "latitude": 36.083688,
      "longitude": -75.696557,
      "city": "Kitty Hawk",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27950,
      "latitude": 36.427047,
      "longitude": -75.976651,
      "city": "Knotts Island",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27953,
      "latitude": 35.823791,
      "longitude": -75.846714,
      "city": "Manns Harbor",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27954,
      "latitude": 35.950867,
      "longitude": -75.681122,
      "city": "Manteo",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27956,
      "latitude": 36.401953,
      "longitude": -75.997651,
      "city": "Maple",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27957,
      "latitude": 36.113612,
      "longitude": -76.912879,
      "city": "Merry Hill",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27958,
      "latitude": 36.399081,
      "longitude": -76.064702,
      "city": "Moyock",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27959,
      "latitude": 36.02153,
      "longitude": -75.661879,
      "city": "Nags Head",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27960,
      "latitude": 35.355633,
      "longitude": -76.187429,
      "city": "Ocracoke",
      "state": "NC",
      "county": "Hyde"
  },
  {
      "zipCode": 27962,
      "latitude": 35.818681,
      "longitude": -76.609454,
      "city": "Plymouth",
      "state": "NC",
      "county": "Washington"
  },
  {
      "zipCode": 27964,
      "latitude": 36.084092,
      "longitude": -75.804485,
      "city": "Point Harbor",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27965,
      "latitude": 36.273673,
      "longitude": -75.909652,
      "city": "Poplar Branch",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27966,
      "latitude": 36.201721,
      "longitude": -75.865772,
      "city": "Powells Point",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27967,
      "latitude": 36.164672,
      "longitude": -76.967234,
      "city": "Powellsville",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27968,
      "latitude": 35.692774,
      "longitude": -75.713785,
      "city": "Rodanthe",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27969,
      "latitude": 36.424152,
      "longitude": -76.702125,
      "city": "Roduco",
      "state": "NC",
      "county": "Gates"
  },
  {
      "zipCode": 27970,
      "latitude": 35.835272,
      "longitude": -76.554122,
      "city": "Roper",
      "state": "NC",
      "county": "Washington"
  },
  {
      "zipCode": 27972,
      "latitude": 35.554569,
      "longitude": -75.469319,
      "city": "Salvo",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27973,
      "latitude": 36.400991,
      "longitude": -76.09242,
      "city": "Shawboro",
      "state": "NC",
      "county": "Currituck"
  },
  {
      "zipCode": 27974,
      "latitude": 36.239776,
      "longitude": -76.022486,
      "city": "Shiloh",
      "state": "NC",
      "county": "Camden"
  },
  {
      "zipCode": 27976,
      "latitude": 36.455346,
      "longitude": -76.280026,
      "city": "South Mills",
      "state": "NC",
      "county": "Camden"
  },
  {
      "zipCode": 27978,
      "latitude": 35.698493,
      "longitude": -75.772775,
      "city": "Stumpy Point",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27979,
      "latitude": 36.434329,
      "longitude": -76.620014,
      "city": "Sunbury",
      "state": "NC",
      "county": "Gates"
  },
  {
      "zipCode": 27980,
      "latitude": 36.249278,
      "longitude": -76.630775,
      "city": "Tyner",
      "state": "NC",
      "county": "Chowan"
  },
  {
      "zipCode": 27981,
      "latitude": 35.862175,
      "longitude": -75.643747,
      "city": "Wanchese",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27982,
      "latitude": 35.559564,
      "longitude": -75.465858,
      "city": "Waves",
      "state": "NC",
      "county": "Dare"
  },
  {
      "zipCode": 27983,
      "latitude": 36.039911,
      "longitude": -76.936954,
      "city": "Windsor",
      "state": "NC",
      "county": "Bertie"
  },
  {
      "zipCode": 27985,
      "latitude": 36.219345,
      "longitude": -76.466689,
      "city": "Winfall",
      "state": "NC",
      "county": "Perquimans"
  },
  {
      "zipCode": 27986,
      "latitude": 36.418251,
      "longitude": -76.954858,
      "city": "Winton",
      "state": "NC",
      "county": "Hertford"
  },
  {
      "zipCode": 28001,
      "latitude": 35.319817,
      "longitude": -80.253448,
      "city": "Albemarle",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28002,
      "latitude": 35.264179,
      "longitude": -80.108188,
      "city": "Albemarle",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28006,
      "latitude": 35.365255,
      "longitude": -81.087967,
      "city": "Alexis",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28007,
      "latitude": 35.021874,
      "longitude": -80.085232,
      "city": "Ansonville",
      "state": "NC",
      "county": "Anson"
  },
  {
      "zipCode": 28009,
      "latitude": 35.41569,
      "longitude": -80.159356,
      "city": "Badin",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28010,
      "latitude": 35.719228,
      "longitude": -80.89909,
      "city": "Barium Springs",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28012,
      "latitude": 35.278438,
      "longitude": -81.197088,
      "city": "Belmont",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28016,
      "latitude": 35.319564,
      "longitude": -81.234751,
      "city": "Bessemer City",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28017,
      "latitude": 35.254876,
      "longitude": -81.691402,
      "city": "Boiling Springs",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28018,
      "latitude": 35.440832,
      "longitude": -81.789415,
      "city": "Bostic",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28019,
      "latitude": 35.316793,
      "longitude": -81.831944,
      "city": "Caroleen",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28020,
      "latitude": 35.519973,
      "longitude": -81.627064,
      "city": "Casar",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28021,
      "latitude": 35.306707,
      "longitude": -81.236958,
      "city": "Cherryville",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28023,
      "latitude": 35.571468,
      "longitude": -80.596523,
      "city": "China Grove",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28024,
      "latitude": 35.241615,
      "longitude": -81.775456,
      "city": "Cliffside",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28025,
      "latitude": 35.371633,
      "longitude": -80.562141,
      "city": "Concord",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28026,
      "latitude": 35.346285,
      "longitude": -80.541088,
      "city": "Concord",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28027,
      "latitude": 35.410688,
      "longitude": -80.658405,
      "city": "Concord",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28031,
      "latitude": 35.473286,
      "longitude": -80.872585,
      "city": "Cornelius",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28032,
      "latitude": 35.314194,
      "longitude": -81.134336,
      "city": "Cramerton",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28033,
      "latitude": 35.435922,
      "longitude": -81.33848,
      "city": "Crouse",
      "state": "NC",
      "county": "Lincoln"
  },
  {
      "zipCode": 28034,
      "latitude": 35.349898,
      "longitude": -81.221463,
      "city": "Dallas",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28036,
      "latitude": 35.283427,
      "longitude": -80.844594,
      "city": "Davidson",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28037,
      "latitude": 35.486134,
      "longitude": -81.0553,
      "city": "Denver",
      "state": "NC",
      "county": "Lincoln"
  },
  {
      "zipCode": 28038,
      "latitude": 35.196998,
      "longitude": -81.538645,
      "city": "Earl",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28039,
      "latitude": 35.677889,
      "longitude": -80.44593,
      "city": "East Spencer",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28040,
      "latitude": 35.382054,
      "longitude": -81.774,
      "city": "Ellenboro",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28041,
      "latitude": 35.58167,
      "longitude": -80.45806,
      "city": "Faith",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28042,
      "latitude": 35.373884,
      "longitude": -81.543706,
      "city": "Fallston",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28043,
      "latitude": 35.325025,
      "longitude": -81.846013,
      "city": "Forest City",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28052,
      "latitude": 35.267916,
      "longitude": -81.178725,
      "city": "Gastonia",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28053,
      "latitude": 35.275073,
      "longitude": -81.213369,
      "city": "Gastonia",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28054,
      "latitude": 35.249452,
      "longitude": -81.133003,
      "city": "Gastonia",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28055,
      "latitude": 35.284018,
      "longitude": -81.189693,
      "city": "Gastonia",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28056,
      "latitude": 35.242038,
      "longitude": -81.169072,
      "city": "Gastonia",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28070,
      "latitude": 35.462187,
      "longitude": -80.898668,
      "city": "Huntersville",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28071,
      "latitude": 35.547494,
      "longitude": -80.310215,
      "city": "Gold Hill",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28072,
      "latitude": 35.610921,
      "longitude": -80.436124,
      "city": "Granite Quarry",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28073,
      "latitude": 35.204027,
      "longitude": -81.471909,
      "city": "Grover",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28074,
      "latitude": 35.224743,
      "longitude": -81.885081,
      "city": "Harris",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28075,
      "latitude": 35.353672,
      "longitude": -80.672368,
      "city": "Harrisburg",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28076,
      "latitude": 35.226581,
      "longitude": -81.795027,
      "city": "Henrietta",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28077,
      "latitude": 35.404157,
      "longitude": -81.194174,
      "city": "High Shoals",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28078,
      "latitude": 35.401071,
      "longitude": -80.869457,
      "city": "Huntersville",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28079,
      "latitude": 35.115644,
      "longitude": -80.597878,
      "city": "Indian Trail",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28080,
      "latitude": 35.472855,
      "longitude": -81.106202,
      "city": "Iron Station",
      "state": "NC",
      "county": "Lincoln"
  },
  {
      "zipCode": 28081,
      "latitude": 35.462981,
      "longitude": -80.672531,
      "city": "Kannapolis",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28082,
      "latitude": 35.346285,
      "longitude": -80.541088,
      "city": "Kannapolis",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28083,
      "latitude": 35.476586,
      "longitude": -80.571498,
      "city": "Kannapolis",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28086,
      "latitude": 35.296207,
      "longitude": -81.491436,
      "city": "Kings Mountain",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28088,
      "latitude": 35.545428,
      "longitude": -80.608038,
      "city": "Landis",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28089,
      "latitude": 35.308726,
      "longitude": -81.661675,
      "city": "Lattimore",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28090,
      "latitude": 35.433739,
      "longitude": -81.569729,
      "city": "Lawndale",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28091,
      "latitude": 34.967043,
      "longitude": -79.98678,
      "city": "Lilesville",
      "state": "NC",
      "county": "Anson"
  },
  {
      "zipCode": 28092,
      "latitude": 35.485145,
      "longitude": -81.181799,
      "city": "Lincolnton",
      "state": "NC",
      "county": "Lincoln"
  },
  {
      "zipCode": 28093,
      "latitude": 35.484822,
      "longitude": -81.239543,
      "city": "Lincolnton",
      "state": "NC",
      "county": "Lincoln"
  },
  {
      "zipCode": 28097,
      "latitude": 35.262131,
      "longitude": -80.424512,
      "city": "Locust",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28098,
      "latitude": 35.262444,
      "longitude": -81.097965,
      "city": "Lowell",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28101,
      "latitude": 35.26749,
      "longitude": -81.09104,
      "city": "Mc Adenville",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28102,
      "latitude": 34.813634,
      "longitude": -79.97756,
      "city": "Mc Farlan",
      "state": "NC",
      "county": "Anson"
  },
  {
      "zipCode": 28103,
      "latitude": 34.997268,
      "longitude": -80.364223,
      "city": "Marshville",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28104,
      "latitude": 35.089042,
      "longitude": -80.704424,
      "city": "Matthews",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28105,
      "latitude": 35.114887,
      "longitude": -80.704991,
      "city": "Matthews",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28106,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Matthews",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28107,
      "latitude": 35.250608,
      "longitude": -80.535601,
      "city": "Midland",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28108,
      "latitude": 34.916253,
      "longitude": -80.640383,
      "city": "Mineral Springs",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28109,
      "latitude": 35.480124,
      "longitude": -80.270445,
      "city": "Misenheimer",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28110,
      "latitude": 35.074176,
      "longitude": -80.527827,
      "city": "Monroe",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28111,
      "latitude": 35.011204,
      "longitude": -80.558743,
      "city": "Monroe",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28112,
      "latitude": 34.901591,
      "longitude": -80.527201,
      "city": "Monroe",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28114,
      "latitude": 35.256702,
      "longitude": -81.700869,
      "city": "Mooresboro",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28115,
      "latitude": 35.688136,
      "longitude": -80.819825,
      "city": "Mooresville",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28117,
      "latitude": 35.583962,
      "longitude": -80.868463,
      "city": "Mooresville",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28119,
      "latitude": 34.921646,
      "longitude": -80.135131,
      "city": "Morven",
      "state": "NC",
      "county": "Anson"
  },
  {
      "zipCode": 28120,
      "latitude": 35.318697,
      "longitude": -81.026738,
      "city": "Mount Holly",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28123,
      "latitude": 35.529792,
      "longitude": -80.947028,
      "city": "Mount Mourne",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28124,
      "latitude": 35.416779,
      "longitude": -80.416474,
      "city": "Mount Pleasant",
      "state": "NC",
      "county": "Cabarrus"
  },
  {
      "zipCode": 28125,
      "latitude": 35.656287,
      "longitude": -80.701616,
      "city": "Mount Ulla",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28126,
      "latitude": 35.276794,
      "longitude": -80.716495,
      "city": "Newell",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28127,
      "latitude": 35.44125,
      "longitude": -80.225068,
      "city": "New London",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28128,
      "latitude": 35.30281,
      "longitude": -80.196863,
      "city": "Norwood",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28129,
      "latitude": 35.240666,
      "longitude": -80.319154,
      "city": "Oakboro",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28130,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Paw Creek",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28133,
      "latitude": 35.011107,
      "longitude": -80.255799,
      "city": "Peachland",
      "state": "NC",
      "county": "Anson"
  },
  {
      "zipCode": 28134,
      "latitude": 35.07854,
      "longitude": -80.891141,
      "city": "Pineville",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28135,
      "latitude": 35.023262,
      "longitude": -80.210985,
      "city": "Polkton",
      "state": "NC",
      "county": "Anson"
  },
  {
      "zipCode": 28136,
      "latitude": 35.396126,
      "longitude": -81.630272,
      "city": "Polkville",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28137,
      "latitude": 35.454142,
      "longitude": -80.283764,
      "city": "Richfield",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28138,
      "latitude": 35.541642,
      "longitude": -80.455915,
      "city": "Rockwell",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28139,
      "latitude": 35.339729,
      "longitude": -82.004874,
      "city": "Rutherfordton",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28144,
      "latitude": 35.680441,
      "longitude": -80.45316,
      "city": "Salisbury",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28145,
      "latitude": 35.682683,
      "longitude": -80.477116,
      "city": "Salisbury",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28146,
      "latitude": 35.618694,
      "longitude": -80.402235,
      "city": "Salisbury",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28147,
      "latitude": 35.681714,
      "longitude": -80.561516,
      "city": "Salisbury",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28150,
      "latitude": 35.312012,
      "longitude": -81.556777,
      "city": "Shelby",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28151,
      "latitude": 35.233125,
      "longitude": -81.574711,
      "city": "Shelby",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28152,
      "latitude": 35.329336,
      "longitude": -81.580919,
      "city": "Shelby",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28159,
      "latitude": 35.691689,
      "longitude": -80.432679,
      "city": "Spencer",
      "state": "NC",
      "county": "Rowan"
  },
  {
      "zipCode": 28160,
      "latitude": 35.354511,
      "longitude": -81.9211,
      "city": "Spindale",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28163,
      "latitude": 35.258365,
      "longitude": -80.415177,
      "city": "Stanfield",
      "state": "NC",
      "county": "Stanly"
  },
  {
      "zipCode": 28164,
      "latitude": 35.329185,
      "longitude": -81.057419,
      "city": "Stanley",
      "state": "NC",
      "county": "Gaston"
  },
  {
      "zipCode": 28166,
      "latitude": 35.679407,
      "longitude": -80.898817,
      "city": "Troutman",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28167,
      "latitude": 35.486941,
      "longitude": -81.975821,
      "city": "Union Mills",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28168,
      "latitude": 35.521229,
      "longitude": -81.418729,
      "city": "Vale",
      "state": "NC",
      "county": "Lincoln"
  },
  {
      "zipCode": 28169,
      "latitude": 35.35289,
      "longitude": -81.425111,
      "city": "Waco",
      "state": "NC",
      "county": "Cleveland"
  },
  {
      "zipCode": 28170,
      "latitude": 34.924469,
      "longitude": -80.05003,
      "city": "Wadesboro",
      "state": "NC",
      "county": "Anson"
  },
  {
      "zipCode": 28173,
      "latitude": 34.925105,
      "longitude": -80.72775,
      "city": "Waxhaw",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28174,
      "latitude": 34.964812,
      "longitude": -80.427656,
      "city": "Wingate",
      "state": "NC",
      "county": "Union"
  },
  {
      "zipCode": 28201,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28202,
      "latitude": 35.226192,
      "longitude": -80.843493,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28203,
      "latitude": 35.208992,
      "longitude": -80.856343,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28204,
      "latitude": 35.215342,
      "longitude": -80.828706,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28205,
      "latitude": 35.325959,
      "longitude": -80.827444,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28206,
      "latitude": 35.250841,
      "longitude": -80.820091,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28207,
      "latitude": 35.195393,
      "longitude": -80.826393,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28208,
      "latitude": 35.209125,
      "longitude": -80.92175,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28209,
      "latitude": 35.174351,
      "longitude": -80.853396,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28210,
      "latitude": 35.156753,
      "longitude": -80.851319,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28211,
      "latitude": 35.169586,
      "longitude": -80.79076,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28212,
      "latitude": 35.186185,
      "longitude": -80.749617,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28213,
      "latitude": 35.283599,
      "longitude": -80.763757,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28214,
      "latitude": 35.279945,
      "longitude": -80.864057,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28215,
      "latitude": 35.246597,
      "longitude": -80.788726,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28216,
      "latitude": 35.298775,
      "longitude": -80.895731,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28217,
      "latitude": 35.133618,
      "longitude": -80.963642,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28218,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28219,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28220,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28221,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28222,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28223,
      "latitude": 35.304093,
      "longitude": -80.726687,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28224,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28225,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28226,
      "latitude": 35.108637,
      "longitude": -80.82747,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28227,
      "latitude": 35.136591,
      "longitude": -80.711197,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28228,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28229,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28230,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28231,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28232,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28233,
      "latitude": 35.489411,
      "longitude": -80.825368,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28234,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28235,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28236,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28237,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28240,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28241,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28242,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28243,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28244,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28246,
      "latitude": 35.227542,
      "longitude": -80.842543,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28247,
      "latitude": 35.065595,
      "longitude": -80.851149,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28250,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28253,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28254,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28255,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28256,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28258,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28260,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28261,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28262,
      "latitude": 35.318311,
      "longitude": -80.747559,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28265,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28266,
      "latitude": 35.284459,
      "longitude": -80.858155,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28269,
      "latitude": 35.331981,
      "longitude": -80.798583,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28270,
      "latitude": 35.112999,
      "longitude": -80.762565,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28271,
      "latitude": 35.054293,
      "longitude": -80.770509,
      "city": "Charlotte",
      "state": "NC",
      "county": ""
  },
  {
      "zipCode": 28272,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28273,
      "latitude": 35.128678,
      "longitude": -80.933793,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28274,
      "latitude": 35.187943,
      "longitude": -80.831693,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28275,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28277,
      "latitude": 35.055216,
      "longitude": -80.819488,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28278,
      "latitude": 35.20724,
      "longitude": -80.956766,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28280,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28281,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28282,
      "latitude": 35.224242,
      "longitude": -80.844743,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28283,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28284,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28285,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28286,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28287,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28288,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28289,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28290,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28296,
      "latitude": 35.225242,
      "longitude": -80.845843,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28297,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28299,
      "latitude": 35.26002,
      "longitude": -80.804151,
      "city": "Charlotte",
      "state": "NC",
      "county": "Mecklenburg"
  },
  {
      "zipCode": 28301,
      "latitude": 35.053416,
      "longitude": -78.781027,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28302,
      "latitude": 35.034307,
      "longitude": -78.908828,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28303,
      "latitude": 35.074168,
      "longitude": -78.964952,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28304,
      "latitude": 35.020629,
      "longitude": -78.99091,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28305,
      "latitude": 35.050713,
      "longitude": -78.906552,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28306,
      "latitude": 34.942986,
      "longitude": -78.884801,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28307,
      "latitude": 35.140193,
      "longitude": -78.987942,
      "city": "Fort Bragg",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28308,
      "latitude": 35.173377,
      "longitude": -79.013799,
      "city": "Pope A F B",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28309,
      "latitude": 35.039726,
      "longitude": -78.842868,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28310,
      "latitude": 35.050612,
      "longitude": -78.80384,
      "city": "Fort Bragg",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28311,
      "latitude": 35.156447,
      "longitude": -78.912281,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28314,
      "latitude": 35.05425,
      "longitude": -79.011328,
      "city": "Fayetteville",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28315,
      "latitude": 35.203802,
      "longitude": -79.517795,
      "city": "Aberdeen",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28318,
      "latitude": 35.066726,
      "longitude": -78.590479,
      "city": "Autryville",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28319,
      "latitude": 34.595364,
      "longitude": -79.197058,
      "city": "Barnesville",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28320,
      "latitude": 34.621027,
      "longitude": -78.725506,
      "city": "Bladenboro",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28323,
      "latitude": 35.312764,
      "longitude": -78.914817,
      "city": "Bunnlevel",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 28325,
      "latitude": 35.141931,
      "longitude": -78.099221,
      "city": "Calypso",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28326,
      "latitude": 35.310584,
      "longitude": -79.347244,
      "city": "Cameron",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28327,
      "latitude": 35.32304,
      "longitude": -79.428264,
      "city": "Carthage",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28328,
      "latitude": 35.006676,
      "longitude": -78.415415,
      "city": "Clinton",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28329,
      "latitude": 34.994005,
      "longitude": -78.277669,
      "city": "Clinton",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 66215,
      "latitude": 38.964438,
      "longitude": -94.77455,
      "city": "Lenexa",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 28330,
      "latitude": 34.974434,
      "longitude": -79.79851,
      "city": "Cordova",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28331,
      "latitude": 35.150896,
      "longitude": -78.915788,
      "city": "Cumberland",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28332,
      "latitude": 34.64374,
      "longitude": -78.737402,
      "city": "Dublin",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28333,
      "latitude": 35.266919,
      "longitude": -78.025587,
      "city": "Dudley",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 28334,
      "latitude": 35.325672,
      "longitude": -78.693354,
      "city": "Dunn",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 28335,
      "latitude": 35.350774,
      "longitude": -78.625362,
      "city": "Dunn",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 28337,
      "latitude": 34.660959,
      "longitude": -78.621643,
      "city": "Elizabethtown",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28338,
      "latitude": 35.095959,
      "longitude": -79.714244,
      "city": "Ellerbe",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28339,
      "latitude": 35.330059,
      "longitude": -78.705859,
      "city": "Erwin",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 28340,
      "latitude": 34.623018,
      "longitude": -79.148832,
      "city": "Fairmont",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28341,
      "latitude": 35.105419,
      "longitude": -78.102083,
      "city": "Faison",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28342,
      "latitude": 35.210565,
      "longitude": -78.649602,
      "city": "Falcon",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28343,
      "latitude": 34.795061,
      "longitude": -79.577405,
      "city": "Gibson",
      "state": "NC",
      "county": "Scotland"
  },
  {
      "zipCode": 28344,
      "latitude": 35.194383,
      "longitude": -78.678821,
      "city": "Godwin",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28345,
      "latitude": 34.937085,
      "longitude": -79.649111,
      "city": "Hamlet",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28347,
      "latitude": 35.035103,
      "longitude": -79.551692,
      "city": "Hoffman",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28348,
      "latitude": 34.921277,
      "longitude": -78.93292,
      "city": "Hope Mills",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28349,
      "latitude": 35.015157,
      "longitude": -77.896863,
      "city": "Kenansville",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28350,
      "latitude": 35.230608,
      "longitude": -79.315694,
      "city": "Lakeview",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28351,
      "latitude": 34.77465,
      "longitude": -79.571125,
      "city": "Laurel Hill",
      "state": "NC",
      "county": "Scotland"
  },
  {
      "zipCode": 28352,
      "latitude": 34.796929,
      "longitude": -79.475606,
      "city": "Laurinburg",
      "state": "NC",
      "county": "Scotland"
  },
  {
      "zipCode": 28353,
      "latitude": 34.781768,
      "longitude": -79.482423,
      "city": "Laurinburg",
      "state": "NC",
      "county": "Scotland"
  },
  {
      "zipCode": 28355,
      "latitude": 35.467567,
      "longitude": -79.165314,
      "city": "Lemon Springs",
      "state": "NC",
      "county": "Lee"
  },
  {
      "zipCode": 28356,
      "latitude": 35.218025,
      "longitude": -78.783749,
      "city": "Linden",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28357,
      "latitude": 34.778165,
      "longitude": -79.163606,
      "city": "Lumber Bridge",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28358,
      "latitude": 34.631016,
      "longitude": -79.106224,
      "city": "Lumberton",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28359,
      "latitude": 34.607669,
      "longitude": -79.014425,
      "city": "Lumberton",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28360,
      "latitude": 34.669719,
      "longitude": -79.108363,
      "city": "Lumberton",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28361,
      "latitude": 35.02363,
      "longitude": -79.246841,
      "city": "Mccain",
      "state": "NC",
      "county": "Hoke"
  },
  {
      "zipCode": 28362,
      "latitude": 34.379114,
      "longitude": -79.127936,
      "city": "Marietta",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28363,
      "latitude": 34.97281,
      "longitude": -79.61268,
      "city": "Marston",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28364,
      "latitude": 34.711437,
      "longitude": -79.318072,
      "city": "Maxton",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28365,
      "latitude": 35.229061,
      "longitude": -78.09006,
      "city": "Mount Olive",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 28366,
      "latitude": 35.14936,
      "longitude": -78.333526,
      "city": "Newton Grove",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28367,
      "latitude": 35.162723,
      "longitude": -79.73794,
      "city": "Norman",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28368,
      "latitude": 35.322949,
      "longitude": -79.107381,
      "city": "Olivia",
      "state": "NC",
      "county": "Harnett"
  },
  {
      "zipCode": 28369,
      "latitude": 34.473204,
      "longitude": -79.034509,
      "city": "Orrum",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28370,
      "latitude": 35.216222,
      "longitude": -79.452352,
      "city": "Pinehurst",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28371,
      "latitude": 34.896504,
      "longitude": -78.99298,
      "city": "Parkton",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28372,
      "latitude": 34.716443,
      "longitude": -79.127951,
      "city": "Pembroke",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28373,
      "latitude": 35.100733,
      "longitude": -79.466296,
      "city": "Pinebluff",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28374,
      "latitude": 35.211461,
      "longitude": -79.413311,
      "city": "Pinehurst",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28375,
      "latitude": 34.471259,
      "longitude": -79.041388,
      "city": "Proctorville",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28376,
      "latitude": 34.953431,
      "longitude": -79.233178,
      "city": "Raeford",
      "state": "NC",
      "county": "Hoke"
  },
  {
      "zipCode": 28377,
      "latitude": 34.700472,
      "longitude": -79.113936,
      "city": "Red Springs",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28378,
      "latitude": 34.854316,
      "longitude": -79.064165,
      "city": "Rex",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28379,
      "latitude": 34.974134,
      "longitude": -79.724173,
      "city": "Rockingham",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28380,
      "latitude": 34.994152,
      "longitude": -79.767341,
      "city": "Rockingham",
      "state": "NC",
      "county": "Richmond"
  },
  {
      "zipCode": 28382,
      "latitude": 35.00116,
      "longitude": -78.451568,
      "city": "Roseboro",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28383,
      "latitude": 34.665311,
      "longitude": -79.196744,
      "city": "Rowland",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28384,
      "latitude": 34.797081,
      "longitude": -78.968753,
      "city": "Saint Pauls",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28385,
      "latitude": 35.045096,
      "longitude": -78.508895,
      "city": "Salemburg",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28386,
      "latitude": 34.765637,
      "longitude": -79.031674,
      "city": "Shannon",
      "state": "NC",
      "county": "Robeson"
  },
  {
      "zipCode": 28387,
      "latitude": 35.218064,
      "longitude": -79.394988,
      "city": "Southern Pines",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28388,
      "latitude": 35.280335,
      "longitude": -79.432734,
      "city": "Southern Pines",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28390,
      "latitude": 35.189079,
      "longitude": -78.977243,
      "city": "Spring Lake",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28391,
      "latitude": 35.030903,
      "longitude": -78.700219,
      "city": "Stedman",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28392,
      "latitude": 34.745753,
      "longitude": -78.809292,
      "city": "Tar Heel",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28393,
      "latitude": 34.984688,
      "longitude": -78.198087,
      "city": "Turkey",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28394,
      "latitude": 35.230348,
      "longitude": -79.337956,
      "city": "Vass",
      "state": "NC",
      "county": "Moore"
  },
  {
      "zipCode": 28395,
      "latitude": 35.142518,
      "longitude": -78.745001,
      "city": "Wade",
      "state": "NC",
      "county": "Cumberland"
  },
  {
      "zipCode": 28396,
      "latitude": 34.903601,
      "longitude": -79.404128,
      "city": "Wagram",
      "state": "NC",
      "county": "Scotland"
  },
  {
      "zipCode": 28398,
      "latitude": 35.01803,
      "longitude": -78.031022,
      "city": "Warsaw",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28399,
      "latitude": 34.762175,
      "longitude": -78.679332,
      "city": "White Oak",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28401,
      "latitude": 34.163503,
      "longitude": -77.95481,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28402,
      "latitude": 34.340518,
      "longitude": -77.901408,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28403,
      "latitude": 34.139742,
      "longitude": -77.870418,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28404,
      "latitude": 33.926255,
      "longitude": -78.07127,
      "city": "Wilmington",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28405,
      "latitude": 34.184682,
      "longitude": -77.836109,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28406,
      "latitude": 34.088079,
      "longitude": -77.852599,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28407,
      "latitude": 34.088079,
      "longitude": -77.852599,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28408,
      "latitude": 34.212707,
      "longitude": -77.792177,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28409,
      "latitude": 34.13637,
      "longitude": -77.862667,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28410,
      "latitude": 34.088079,
      "longitude": -77.852599,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28411,
      "latitude": 34.303335,
      "longitude": -77.803856,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28412,
      "latitude": 34.127458,
      "longitude": -77.91859,
      "city": "Wilmington",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28420,
      "latitude": 34.06493,
      "longitude": -78.494942,
      "city": "Ash",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28421,
      "latitude": 34.510936,
      "longitude": -78.094517,
      "city": "Atkinson",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28422,
      "latitude": 34.017181,
      "longitude": -78.172242,
      "city": "Bolivia",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28423,
      "latitude": 34.275295,
      "longitude": -78.350029,
      "city": "Bolton",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28424,
      "latitude": 34.290716,
      "longitude": -78.699417,
      "city": "Brunswick",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28425,
      "latitude": 34.546108,
      "longitude": -77.937122,
      "city": "Burgaw",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28428,
      "latitude": 34.035151,
      "longitude": -77.895702,
      "city": "Carolina Beach",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28429,
      "latitude": 34.329932,
      "longitude": -77.887477,
      "city": "Castle Hayne",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28430,
      "latitude": 34.301907,
      "longitude": -78.725768,
      "city": "Cerro Gordo",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28431,
      "latitude": 34.316747,
      "longitude": -78.891483,
      "city": "Chadbourn",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28432,
      "latitude": 34.165516,
      "longitude": -78.789061,
      "city": "Clarendon",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28433,
      "latitude": 34.529167,
      "longitude": -78.592149,
      "city": "Clarkton",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28434,
      "latitude": 34.471042,
      "longitude": -78.477201,
      "city": "Council",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28435,
      "latitude": 34.441229,
      "longitude": -78.115064,
      "city": "Currie",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28436,
      "latitude": 34.283839,
      "longitude": -78.260664,
      "city": "Delco",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28438,
      "latitude": 34.420379,
      "longitude": -78.894652,
      "city": "Evergreen",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28439,
      "latitude": 34.278093,
      "longitude": -78.987194,
      "city": "Fair Bluff",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28441,
      "latitude": 34.822898,
      "longitude": -78.323226,
      "city": "Garland",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28442,
      "latitude": 34.337401,
      "longitude": -78.652989,
      "city": "Hallsboro",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28443,
      "latitude": 34.391419,
      "longitude": -77.658725,
      "city": "Hampstead",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28444,
      "latitude": 34.711792,
      "longitude": -78.26273,
      "city": "Harrells",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28445,
      "latitude": 34.527983,
      "longitude": -77.514707,
      "city": "Holly Ridge",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28446,
      "latitude": 34.83661,
      "longitude": -78.348111,
      "city": "Ingold",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28447,
      "latitude": 34.623287,
      "longitude": -78.234209,
      "city": "Ivanhoe",
      "state": "NC",
      "county": "Sampson"
  },
  {
      "zipCode": 28448,
      "latitude": 34.497157,
      "longitude": -78.322339,
      "city": "Kelly",
      "state": "NC",
      "county": "Bladen"
  },
  {
      "zipCode": 28449,
      "latitude": 33.986817,
      "longitude": -77.920117,
      "city": "Kure Beach",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28450,
      "latitude": 34.345825,
      "longitude": -78.751361,
      "city": "Lake Waccamaw",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28451,
      "latitude": 34.245499,
      "longitude": -78.083622,
      "city": "Leland",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28452,
      "latitude": 33.997292,
      "longitude": -78.554121,
      "city": "Longwood",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28453,
      "latitude": 34.885139,
      "longitude": -78.034098,
      "city": "Magnolia",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28454,
      "latitude": 34.659359,
      "longitude": -77.711665,
      "city": "Maple Hill",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28455,
      "latitude": 34.117032,
      "longitude": -78.690122,
      "city": "Nakina",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28456,
      "latitude": 34.353063,
      "longitude": -78.24873,
      "city": "Riegelwood",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28457,
      "latitude": 34.477635,
      "longitude": -77.816971,
      "city": "Rocky Point",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28458,
      "latitude": 34.829225,
      "longitude": -78.022982,
      "city": "Rose Hill",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28459,
      "latitude": 33.933388,
      "longitude": -78.412864,
      "city": "Shallotte",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28460,
      "latitude": 34.60694,
      "longitude": -77.426164,
      "city": "Sneads Ferry",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28461,
      "latitude": 33.966293,
      "longitude": -78.238126,
      "city": "Southport",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28462,
      "latitude": 34.002551,
      "longitude": -78.223678,
      "city": "Supply",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28463,
      "latitude": 34.153412,
      "longitude": -78.635372,
      "city": "Tabor City",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28464,
      "latitude": 34.778119,
      "longitude": -77.967075,
      "city": "Teachey",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28465,
      "latitude": 34.090202,
      "longitude": -78.127035,
      "city": "Oak Island",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28466,
      "latitude": 34.76743,
      "longitude": -77.940027,
      "city": "Wallace",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28467,
      "latitude": 33.911953,
      "longitude": -78.395641,
      "city": "Calabash",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28468,
      "latitude": 33.945533,
      "longitude": -78.327029,
      "city": "Sunset Beach",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28469,
      "latitude": 33.936658,
      "longitude": -78.271319,
      "city": "Ocean Isle Beach",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28470,
      "latitude": 33.992923,
      "longitude": -78.311346,
      "city": "Shallotte",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28471,
      "latitude": 34.613953,
      "longitude": -78.03154,
      "city": "Watha",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28472,
      "latitude": 34.262252,
      "longitude": -78.625479,
      "city": "Whiteville",
      "state": "NC",
      "county": "Columbus"
  },
  {
      "zipCode": 28478,
      "latitude": 34.67509,
      "longitude": -78.044929,
      "city": "Willard",
      "state": "NC",
      "county": "Pender"
  },
  {
      "zipCode": 28479,
      "latitude": 34.155253,
      "longitude": -78.055776,
      "city": "Winnabow",
      "state": "NC",
      "county": "Brunswick"
  },
  {
      "zipCode": 28480,
      "latitude": 34.214489,
      "longitude": -77.859467,
      "city": "Wrightsville Beach",
      "state": "NC",
      "county": "New Hanover"
  },
  {
      "zipCode": 28501,
      "latitude": 35.220079,
      "longitude": -77.615508,
      "city": "Kinston",
      "state": "NC",
      "county": "Lenoir"
  },
  {
      "zipCode": 28502,
      "latitude": 35.288558,
      "longitude": -77.662614,
      "city": "Kinston",
      "state": "NC",
      "county": "Lenoir"
  },
  {
      "zipCode": 28503,
      "latitude": 35.319066,
      "longitude": -77.595034,
      "city": "Kinston",
      "state": "NC",
      "county": "Lenoir"
  },
  {
      "zipCode": 28504,
      "latitude": 35.205977,
      "longitude": -77.657605,
      "city": "Kinston",
      "state": "NC",
      "county": "Lenoir"
  },
  {
      "zipCode": 28508,
      "latitude": 35.103676,
      "longitude": -77.824959,
      "city": "Albertson",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28509,
      "latitude": 35.156145,
      "longitude": -76.632264,
      "city": "Alliance",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28510,
      "latitude": 35.034156,
      "longitude": -76.798574,
      "city": "Arapahoe",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28511,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Atlantic",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28512,
      "latitude": 34.741717,
      "longitude": -76.755456,
      "city": "Atlantic Beach",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28513,
      "latitude": 35.463405,
      "longitude": -77.359224,
      "city": "Ayden",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 28515,
      "latitude": 35.13584,
      "longitude": -76.70564,
      "city": "Bayboro",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28516,
      "latitude": 34.753777,
      "longitude": -76.610744,
      "city": "Beaufort",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28518,
      "latitude": 34.895292,
      "longitude": -77.785362,
      "city": "Beulaville",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28519,
      "latitude": 35.129461,
      "longitude": -77.01996,
      "city": "Bridgeton",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28520,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Cedar Island",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28521,
      "latitude": 34.897753,
      "longitude": -77.77497,
      "city": "Chinquapin",
      "state": "NC",
      "county": "Duplin"
  },
  {
      "zipCode": 28522,
      "latitude": 35.005079,
      "longitude": -77.523102,
      "city": "Comfort",
      "state": "NC",
      "county": "Jones"
  },
  {
      "zipCode": 28523,
      "latitude": 35.229878,
      "longitude": -77.304943,
      "city": "Cove City",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28524,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Davis",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28525,
      "latitude": 35.134409,
      "longitude": -77.688191,
      "city": "Deep Run",
      "state": "NC",
      "county": "Lenoir"
  },
  {
      "zipCode": 28526,
      "latitude": 35.255441,
      "longitude": -77.364631,
      "city": "Dover",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28527,
      "latitude": 35.226396,
      "longitude": -77.020149,
      "city": "Ernul",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28528,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Gloucester",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28529,
      "latitude": 35.090185,
      "longitude": -76.828552,
      "city": "Grantsboro",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28530,
      "latitude": 35.440556,
      "longitude": -77.399501,
      "city": "Grifton",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 28531,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Harkers Island",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28532,
      "latitude": 35.028677,
      "longitude": -77.053443,
      "city": "Havelock",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28533,
      "latitude": 34.903793,
      "longitude": -76.899976,
      "city": "Cherry Point",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28537,
      "latitude": 35.266039,
      "longitude": -76.577201,
      "city": "Hobucken",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28538,
      "latitude": 35.421956,
      "longitude": -77.591228,
      "city": "Hookerton",
      "state": "NC",
      "county": "Greene"
  },
  {
      "zipCode": 28539,
      "latitude": 34.709544,
      "longitude": -77.222926,
      "city": "Hubert",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28540,
      "latitude": 34.726587,
      "longitude": -77.414673,
      "city": "Jacksonville",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28541,
      "latitude": 34.692056,
      "longitude": -77.391199,
      "city": "Jacksonville",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28542,
      "latitude": 34.664035,
      "longitude": -77.463687,
      "city": "Camp Lejeune",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28543,
      "latitude": 34.736045,
      "longitude": -77.382049,
      "city": "Tarawa Terrace",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28544,
      "latitude": 34.622795,
      "longitude": -77.417426,
      "city": "Midway Park",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28545,
      "latitude": 34.692056,
      "longitude": -77.391199,
      "city": "Mccutcheon Field",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28546,
      "latitude": 34.720825,
      "longitude": -77.444847,
      "city": "Jacksonville",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28547,
      "latitude": 34.690934,
      "longitude": -77.361429,
      "city": "Camp Lejeune",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28551,
      "latitude": 35.215944,
      "longitude": -77.631048,
      "city": "La Grange",
      "state": "NC",
      "county": "Lenoir"
  },
  {
      "zipCode": 28552,
      "latitude": 35.282911,
      "longitude": -76.583839,
      "city": "Lowland",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28553,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Marshallberg",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28554,
      "latitude": 35.49993,
      "longitude": -77.651024,
      "city": "Maury",
      "state": "NC",
      "county": "Greene"
  },
  {
      "zipCode": 28555,
      "latitude": 34.934067,
      "longitude": -77.280066,
      "city": "Maysville",
      "state": "NC",
      "county": "Jones"
  },
  {
      "zipCode": 28556,
      "latitude": 35.122377,
      "longitude": -76.671887,
      "city": "Merritt",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28557,
      "latitude": 34.727798,
      "longitude": -76.770987,
      "city": "Morehead City",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28560,
      "latitude": 35.098109,
      "longitude": -77.178429,
      "city": "New Bern",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28561,
      "latitude": 35.103736,
      "longitude": -77.07585,
      "city": "New Bern",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28562,
      "latitude": 35.116959,
      "longitude": -77.190548,
      "city": "New Bern",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28563,
      "latitude": 35.110855,
      "longitude": -77.06336,
      "city": "New Bern",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28564,
      "latitude": 35.110855,
      "longitude": -77.06336,
      "city": "New Bern",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28570,
      "latitude": 34.766818,
      "longitude": -76.878174,
      "city": "Newport",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28571,
      "latitude": 35.053819,
      "longitude": -76.723675,
      "city": "Oriental",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28572,
      "latitude": 35.057332,
      "longitude": -77.694287,
      "city": "Pink Hill",
      "state": "NC",
      "county": "Lenoir"
  },
  {
      "zipCode": 28573,
      "latitude": 35.009751,
      "longitude": -77.240202,
      "city": "Pollocksville",
      "state": "NC",
      "county": "Jones"
  },
  {
      "zipCode": 28574,
      "latitude": 34.860048,
      "longitude": -77.581206,
      "city": "Richlands",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28575,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Salter Path",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28577,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Sealevel",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28578,
      "latitude": 35.217099,
      "longitude": -77.897418,
      "city": "Seven Springs",
      "state": "NC",
      "county": "Wayne"
  },
  {
      "zipCode": 28579,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Smyrna",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28580,
      "latitude": 35.428349,
      "longitude": -77.701329,
      "city": "Snow Hill",
      "state": "NC",
      "county": "Greene"
  },
  {
      "zipCode": 28581,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Stacy",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28582,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Stella",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28583,
      "latitude": 35.156145,
      "longitude": -76.632264,
      "city": "Stonewall",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28584,
      "latitude": 34.776677,
      "longitude": -77.173963,
      "city": "Swansboro",
      "state": "NC",
      "county": "Onslow"
  },
  {
      "zipCode": 28585,
      "latitude": 35.057695,
      "longitude": -77.431386,
      "city": "Trenton",
      "state": "NC",
      "county": "Jones"
  },
  {
      "zipCode": 28586,
      "latitude": 35.31313,
      "longitude": -77.177912,
      "city": "Vanceboro",
      "state": "NC",
      "county": "Craven"
  },
  {
      "zipCode": 28587,
      "latitude": 35.191279,
      "longitude": -76.660435,
      "city": "Vandemere",
      "state": "NC",
      "county": "Pamlico"
  },
  {
      "zipCode": 28589,
      "latitude": 34.873922,
      "longitude": -76.587097,
      "city": "Williston",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28590,
      "latitude": 35.47461,
      "longitude": -77.327423,
      "city": "Winterville",
      "state": "NC",
      "county": "Pitt"
  },
  {
      "zipCode": 28594,
      "latitude": 34.695016,
      "longitude": -76.862415,
      "city": "Emerald Isle",
      "state": "NC",
      "county": "Carteret"
  },
  {
      "zipCode": 28601,
      "latitude": 35.758221,
      "longitude": -81.308224,
      "city": "Hickory",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28602,
      "latitude": 35.661511,
      "longitude": -81.269348,
      "city": "Hickory",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28603,
      "latitude": 35.679876,
      "longitude": -81.287181,
      "city": "Hickory",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28604,
      "latitude": 36.072025,
      "longitude": -81.910144,
      "city": "Banner Elk",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28605,
      "latitude": 36.163862,
      "longitude": -81.714505,
      "city": "Blowing Rock",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28606,
      "latitude": 36.122485,
      "longitude": -81.23358,
      "city": "Boomer",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28607,
      "latitude": 36.222156,
      "longitude": -81.699784,
      "city": "Boone",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28608,
      "latitude": 36.221334,
      "longitude": -81.713416,
      "city": "Boone",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28609,
      "latitude": 35.684978,
      "longitude": -81.044672,
      "city": "Catawba",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28610,
      "latitude": 35.730213,
      "longitude": -81.12693,
      "city": "Claremont",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28611,
      "latitude": 35.994554,
      "longitude": -81.726617,
      "city": "Collettsville",
      "state": "NC",
      "county": "Caldwell"
  },
  {
      "zipCode": 28612,
      "latitude": 35.689483,
      "longitude": -81.538697,
      "city": "Connellys Springs",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28613,
      "latitude": 35.740848,
      "longitude": -81.209458,
      "city": "Conover",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28615,
      "latitude": 36.464673,
      "longitude": -81.596886,
      "city": "Creston",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28616,
      "latitude": 36.059123,
      "longitude": -81.889238,
      "city": "Crossnore",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28617,
      "latitude": 36.494803,
      "longitude": -81.385406,
      "city": "Crumpler",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28618,
      "latitude": 36.207343,
      "longitude": -81.541072,
      "city": "Deep Gap",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28619,
      "latitude": 35.717868,
      "longitude": -81.640557,
      "city": "Drexel",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28621,
      "latitude": 36.318035,
      "longitude": -80.799944,
      "city": "Elkin",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 28622,
      "latitude": 36.185475,
      "longitude": -81.919892,
      "city": "Elk Park",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28623,
      "latitude": 36.520689,
      "longitude": -80.983323,
      "city": "Ennice",
      "state": "NC",
      "county": "Alleghany"
  },
  {
      "zipCode": 28624,
      "latitude": 36.114413,
      "longitude": -81.412744,
      "city": "Ferguson",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28625,
      "latitude": 35.790866,
      "longitude": -80.89757,
      "city": "Statesville",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28626,
      "latitude": 36.330187,
      "longitude": -81.508408,
      "city": "Fleetwood",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28627,
      "latitude": 36.470469,
      "longitude": -81.006386,
      "city": "Glade Valley",
      "state": "NC",
      "county": "Alleghany"
  },
  {
      "zipCode": 28628,
      "latitude": 35.721174,
      "longitude": -81.817998,
      "city": "Glen Alpine",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28629,
      "latitude": 36.348613,
      "longitude": -81.374732,
      "city": "Glendale Springs",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28630,
      "latitude": 35.834865,
      "longitude": -81.454664,
      "city": "Granite Falls",
      "state": "NC",
      "county": "Caldwell"
  },
  {
      "zipCode": 28631,
      "latitude": 36.545329,
      "longitude": -81.41463,
      "city": "Grassy Creek",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28633,
      "latitude": 35.937564,
      "longitude": -81.539847,
      "city": "Lenoir",
      "state": "NC",
      "county": "Caldwell"
  },
  {
      "zipCode": 28634,
      "latitude": 35.964392,
      "longitude": -80.777838,
      "city": "Harmony",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28635,
      "latitude": 36.308091,
      "longitude": -81.116941,
      "city": "Hays",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28636,
      "latitude": 35.938931,
      "longitude": -81.149233,
      "city": "Hiddenite",
      "state": "NC",
      "county": "Alexander"
  },
  {
      "zipCode": 28637,
      "latitude": 35.727107,
      "longitude": -81.497816,
      "city": "Hildebran",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28638,
      "latitude": 35.839777,
      "longitude": -81.479726,
      "city": "Hudson",
      "state": "NC",
      "county": "Caldwell"
  },
  {
      "zipCode": 28640,
      "latitude": 36.403558,
      "longitude": -81.414753,
      "city": "Jefferson",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28641,
      "latitude": 35.938422,
      "longitude": -81.884521,
      "city": "Jonas Ridge",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28642,
      "latitude": 36.210523,
      "longitude": -80.801365,
      "city": "Jonesville",
      "state": "NC",
      "county": "Yadkin"
  },
  {
      "zipCode": 28643,
      "latitude": 36.502581,
      "longitude": -81.553069,
      "city": "Lansing",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28644,
      "latitude": 36.422183,
      "longitude": -81.300052,
      "city": "Laurel Springs",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28645,
      "latitude": 35.947401,
      "longitude": -81.560742,
      "city": "Lenoir",
      "state": "NC",
      "county": "Caldwell"
  },
  {
      "zipCode": 28646,
      "latitude": 36.040964,
      "longitude": -81.917716,
      "city": "Linville",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28647,
      "latitude": 35.779182,
      "longitude": -81.675545,
      "city": "Linville Falls",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28649,
      "latitude": 36.307698,
      "longitude": -81.200766,
      "city": "Mc Grady",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28650,
      "latitude": 35.588869,
      "longitude": -81.162793,
      "city": "Maiden",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28651,
      "latitude": 36.241997,
      "longitude": -81.224811,
      "city": "Millers Creek",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28652,
      "latitude": 36.086466,
      "longitude": -81.934265,
      "city": "Minneapolis",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28653,
      "latitude": 36.065053,
      "longitude": -81.901715,
      "city": "Montezuma",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28654,
      "latitude": 36.100492,
      "longitude": -81.165221,
      "city": "Moravian Falls",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28655,
      "latitude": 35.772992,
      "longitude": -81.676229,
      "city": "Morganton",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28656,
      "latitude": 36.238438,
      "longitude": -81.045963,
      "city": "North Wilkesboro",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28657,
      "latitude": 36.059029,
      "longitude": -81.930337,
      "city": "Newland",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28658,
      "latitude": 35.631914,
      "longitude": -81.17193,
      "city": "Newton",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28659,
      "latitude": 36.208773,
      "longitude": -81.067495,
      "city": "North Wilkesboro",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28660,
      "latitude": 35.974905,
      "longitude": -80.864673,
      "city": "Olin",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28661,
      "latitude": 35.99726,
      "longitude": -81.562609,
      "city": "Patterson",
      "state": "NC",
      "county": "Caldwell"
  },
  {
      "zipCode": 28662,
      "latitude": 36.085582,
      "longitude": -81.876049,
      "city": "Pineola",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28663,
      "latitude": 36.527637,
      "longitude": -81.301935,
      "city": "Piney Creek",
      "state": "NC",
      "county": "Alleghany"
  },
  {
      "zipCode": 28664,
      "latitude": 36.044098,
      "longitude": -82.003691,
      "city": "Plumtree",
      "state": "NC",
      "county": "Avery"
  },
  {
      "zipCode": 28665,
      "latitude": 36.223349,
      "longitude": -81.179412,
      "city": "Purlear",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28666,
      "latitude": 35.73005,
      "longitude": -81.495395,
      "city": "Icard",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28667,
      "latitude": 35.777241,
      "longitude": -81.43023,
      "city": "Rhodhiss",
      "state": "NC",
      "county": "Caldwell"
  },
  {
      "zipCode": 28668,
      "latitude": 36.398983,
      "longitude": -80.987489,
      "city": "Roaring Gap",
      "state": "NC",
      "county": "Alleghany"
  },
  {
      "zipCode": 28669,
      "latitude": 36.227255,
      "longitude": -80.998644,
      "city": "Roaring River",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28670,
      "latitude": 36.20083,
      "longitude": -80.929782,
      "city": "Ronda",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28671,
      "latitude": 35.74858,
      "longitude": -81.563674,
      "city": "Rutherford College",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28672,
      "latitude": 36.414156,
      "longitude": -81.492983,
      "city": "Scottville",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28673,
      "latitude": 35.603076,
      "longitude": -80.997147,
      "city": "Sherrills Ford",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28674,
      "latitude": 36.216247,
      "longitude": -81.207166,
      "city": "North Wilkesboro",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28675,
      "latitude": 36.495157,
      "longitude": -81.140955,
      "city": "Sparta",
      "state": "NC",
      "county": "Alleghany"
  },
  {
      "zipCode": 28676,
      "latitude": 36.336635,
      "longitude": -80.852275,
      "city": "State Road",
      "state": "NC",
      "county": "Surry"
  },
  {
      "zipCode": 28677,
      "latitude": 35.773406,
      "longitude": -80.901036,
      "city": "Statesville",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28678,
      "latitude": 35.88313,
      "longitude": -81.162749,
      "city": "Stony Point",
      "state": "NC",
      "county": "Alexander"
  },
  {
      "zipCode": 28679,
      "latitude": 36.254958,
      "longitude": -81.835794,
      "city": "Sugar Grove",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28680,
      "latitude": 35.750657,
      "longitude": -81.695292,
      "city": "Morganton",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28681,
      "latitude": 35.916914,
      "longitude": -81.209656,
      "city": "Taylorsville",
      "state": "NC",
      "county": "Alexander"
  },
  {
      "zipCode": 28682,
      "latitude": 35.579418,
      "longitude": -80.968153,
      "city": "Terrell",
      "state": "NC",
      "county": "Catawba"
  },
  {
      "zipCode": 28683,
      "latitude": 36.346341,
      "longitude": -80.989986,
      "city": "Thurmond",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28684,
      "latitude": 36.340991,
      "longitude": -81.579496,
      "city": "Todd",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28685,
      "latitude": 36.331988,
      "longitude": -81.031598,
      "city": "Traphill",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28687,
      "latitude": 35.529792,
      "longitude": -80.947028,
      "city": "Statesville",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28688,
      "latitude": 35.529792,
      "longitude": -80.947028,
      "city": "Turnersburg",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28689,
      "latitude": 36.029749,
      "longitude": -80.887909,
      "city": "Union Grove",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28690,
      "latitude": 35.737757,
      "longitude": -81.6166,
      "city": "Valdese",
      "state": "NC",
      "county": "Burke"
  },
  {
      "zipCode": 28691,
      "latitude": 36.209158,
      "longitude": -81.778871,
      "city": "Valle Crucis",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28692,
      "latitude": 36.266185,
      "longitude": -81.802644,
      "city": "Vilas",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28693,
      "latitude": 36.455704,
      "longitude": -81.572935,
      "city": "Warrensville",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28694,
      "latitude": 36.359734,
      "longitude": -81.484857,
      "city": "West Jefferson",
      "state": "NC",
      "county": "Ashe"
  },
  {
      "zipCode": 28697,
      "latitude": 36.211148,
      "longitude": -81.129235,
      "city": "Wilkesboro",
      "state": "NC",
      "county": "Wilkes"
  },
  {
      "zipCode": 28698,
      "latitude": 36.324526,
      "longitude": -81.739308,
      "city": "Zionville",
      "state": "NC",
      "county": "Watauga"
  },
  {
      "zipCode": 28699,
      "latitude": 35.831037,
      "longitude": -81.00762,
      "city": "Scotts",
      "state": "NC",
      "county": "Iredell"
  },
  {
      "zipCode": 28701,
      "latitude": 35.628016,
      "longitude": -82.556751,
      "city": "Alexander",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28702,
      "latitude": 35.409905,
      "longitude": -83.479397,
      "city": "Almond",
      "state": "NC",
      "county": "Swain"
  },
  {
      "zipCode": 28704,
      "latitude": 35.589686,
      "longitude": -82.541808,
      "city": "Arden",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28705,
      "latitude": 36.038317,
      "longitude": -82.205783,
      "city": "Bakersville",
      "state": "NC",
      "county": "Mitchell"
  },
  {
      "zipCode": 28707,
      "latitude": 35.430091,
      "longitude": -83.092783,
      "city": "Balsam",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28708,
      "latitude": 35.205962,
      "longitude": -82.898272,
      "city": "Balsam Grove",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28709,
      "latitude": 35.71628,
      "longitude": -82.529787,
      "city": "Barnardsville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28710,
      "latitude": 35.4319,
      "longitude": -82.544826,
      "city": "Bat Cave",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28711,
      "latitude": 35.564616,
      "longitude": -82.461196,
      "city": "Black Mountain",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28712,
      "latitude": 35.174351,
      "longitude": -82.753971,
      "city": "Brevard",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28713,
      "latitude": 35.38563,
      "longitude": -83.508554,
      "city": "Bryson City",
      "state": "NC",
      "county": "Swain"
  },
  {
      "zipCode": 28714,
      "latitude": 35.835649,
      "longitude": -82.268193,
      "city": "Burnsville",
      "state": "NC",
      "county": "Yancey"
  },
  {
      "zipCode": 28715,
      "latitude": 35.535096,
      "longitude": -82.529962,
      "city": "Candler",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28716,
      "latitude": 35.492743,
      "longitude": -82.959555,
      "city": "Canton",
      "state": "NC",
      "county": "Haywood"
  },
  {
      "zipCode": 28717,
      "latitude": 35.101156,
      "longitude": -83.093769,
      "city": "Cashiers",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28718,
      "latitude": 35.15081,
      "longitude": -82.637352,
      "city": "Cedar Mountain",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28719,
      "latitude": 35.494881,
      "longitude": -83.34906,
      "city": "Cherokee",
      "state": "NC",
      "county": "Swain"
  },
  {
      "zipCode": 28720,
      "latitude": 35.436893,
      "longitude": -82.241744,
      "city": "Chimney Rock",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28721,
      "latitude": 35.637588,
      "longitude": -82.978588,
      "city": "Clyde",
      "state": "NC",
      "county": "Haywood"
  },
  {
      "zipCode": 28722,
      "latitude": 35.261201,
      "longitude": -82.123534,
      "city": "Columbus",
      "state": "NC",
      "county": "Polk"
  },
  {
      "zipCode": 28723,
      "latitude": 35.249256,
      "longitude": -83.132409,
      "city": "Cullowhee",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28724,
      "latitude": 35.295403,
      "longitude": -82.388325,
      "city": "Dana",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28725,
      "latitude": 35.373461,
      "longitude": -83.259211,
      "city": "Dillsboro",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28726,
      "latitude": 35.313136,
      "longitude": -82.442057,
      "city": "East Flat Rock",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28727,
      "latitude": 35.322276,
      "longitude": -82.503226,
      "city": "Edneyville",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28728,
      "latitude": 35.498797,
      "longitude": -82.708024,
      "city": "Enka",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28729,
      "latitude": 35.31252,
      "longitude": -82.587192,
      "city": "Etowah",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28730,
      "latitude": 35.529188,
      "longitude": -82.404704,
      "city": "Fairview",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28731,
      "latitude": 35.297055,
      "longitude": -82.403731,
      "city": "Flat Rock",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28732,
      "latitude": 35.370666,
      "longitude": -82.494647,
      "city": "Fletcher",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28733,
      "latitude": 35.423135,
      "longitude": -83.779522,
      "city": "Fontana Dam",
      "state": "NC",
      "county": "Graham"
  },
  {
      "zipCode": 28734,
      "latitude": 35.155829,
      "longitude": -83.422421,
      "city": "Franklin",
      "state": "NC",
      "county": "Macon"
  },
  {
      "zipCode": 28735,
      "latitude": 35.322276,
      "longitude": -82.503226,
      "city": "Gerton",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28736,
      "latitude": 35.230342,
      "longitude": -83.107379,
      "city": "Glenville",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28737,
      "latitude": 35.738725,
      "longitude": -82.059494,
      "city": "Glenwood",
      "state": "NC",
      "county": "Mcdowell"
  },
  {
      "zipCode": 28738,
      "latitude": 35.468264,
      "longitude": -83.002841,
      "city": "Hazelwood",
      "state": "NC",
      "county": "Haywood"
  },
  {
      "zipCode": 28739,
      "latitude": 35.318016,
      "longitude": -82.45034,
      "city": "Hendersonville",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28740,
      "latitude": 35.89163,
      "longitude": -82.319283,
      "city": "Green Mountain",
      "state": "NC",
      "county": "Yancey"
  },
  {
      "zipCode": 28741,
      "latitude": 35.123767,
      "longitude": -83.419534,
      "city": "Highlands",
      "state": "NC",
      "county": "Macon"
  },
  {
      "zipCode": 28742,
      "latitude": 35.333332,
      "longitude": -82.524772,
      "city": "Horse Shoe",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28743,
      "latitude": 35.808232,
      "longitude": -82.752619,
      "city": "Hot Springs",
      "state": "NC",
      "county": "Madison"
  },
  {
      "zipCode": 28744,
      "latitude": 35.117299,
      "longitude": -83.295244,
      "city": "Franklin",
      "state": "NC",
      "county": "Macon"
  },
  {
      "zipCode": 28745,
      "latitude": 35.56115,
      "longitude": -83.013022,
      "city": "Lake Junaluska",
      "state": "NC",
      "county": "Haywood"
  },
  {
      "zipCode": 28746,
      "latitude": 35.463527,
      "longitude": -82.183853,
      "city": "Lake Lure",
      "state": "NC",
      "county": "Rutherford"
  },
  {
      "zipCode": 28747,
      "latitude": 35.157059,
      "longitude": -82.826781,
      "city": "Lake Toxaway",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28748,
      "latitude": 35.579969,
      "longitude": -82.683175,
      "city": "Leicester",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28749,
      "latitude": 35.738725,
      "longitude": -82.059494,
      "city": "Little Switzerland",
      "state": "NC",
      "county": "Mcdowell"
  },
  {
      "zipCode": 28750,
      "latitude": 35.236179,
      "longitude": -82.236198,
      "city": "Lynn",
      "state": "NC",
      "county": "Polk"
  },
  {
      "zipCode": 28751,
      "latitude": 35.527318,
      "longitude": -83.036558,
      "city": "Maggie Valley",
      "state": "NC",
      "county": "Haywood"
  },
  {
      "zipCode": 28752,
      "latitude": 35.684187,
      "longitude": -82.034672,
      "city": "Marion",
      "state": "NC",
      "county": "Mcdowell"
  },
  {
      "zipCode": 28753,
      "latitude": 35.859719,
      "longitude": -82.710523,
      "city": "Marshall",
      "state": "NC",
      "county": "Madison"
  },
  {
      "zipCode": 28754,
      "latitude": 35.879456,
      "longitude": -82.516228,
      "city": "Mars Hill",
      "state": "NC",
      "county": "Madison"
  },
  {
      "zipCode": 28755,
      "latitude": 35.83924,
      "longitude": -82.196202,
      "city": "Micaville",
      "state": "NC",
      "county": "Yancey"
  },
  {
      "zipCode": 28756,
      "latitude": 35.336371,
      "longitude": -82.158975,
      "city": "Mill Spring",
      "state": "NC",
      "county": "Polk"
  },
  {
      "zipCode": 28757,
      "latitude": 35.641473,
      "longitude": -82.315637,
      "city": "Montreat",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28758,
      "latitude": 35.371065,
      "longitude": -82.49375,
      "city": "Mountain Home",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28760,
      "latitude": 35.381677,
      "longitude": -82.481257,
      "city": "Naples",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28761,
      "latitude": 35.669058,
      "longitude": -81.906612,
      "city": "Nebo",
      "state": "NC",
      "county": "Mcdowell"
  },
  {
      "zipCode": 28762,
      "latitude": 35.616257,
      "longitude": -82.148319,
      "city": "Old Fort",
      "state": "NC",
      "county": "Mcdowell"
  },
  {
      "zipCode": 28763,
      "latitude": 35.051539,
      "longitude": -83.38539,
      "city": "Otto",
      "state": "NC",
      "county": "Macon"
  },
  {
      "zipCode": 28765,
      "latitude": 35.921862,
      "longitude": -82.110672,
      "city": "Penland",
      "state": "NC",
      "county": "Mitchell"
  },
  {
      "zipCode": 28766,
      "latitude": 35.260991,
      "longitude": -82.644584,
      "city": "Penrose",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28768,
      "latitude": 35.231828,
      "longitude": -82.748346,
      "city": "Pisgah Forest",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28770,
      "latitude": 35.618596,
      "longitude": -82.30048,
      "city": "Ridgecrest",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28771,
      "latitude": 35.343758,
      "longitude": -83.773869,
      "city": "Robbinsville",
      "state": "NC",
      "county": "Graham"
  },
  {
      "zipCode": 28772,
      "latitude": 35.159152,
      "longitude": -82.83832,
      "city": "Rosman",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28773,
      "latitude": 35.257579,
      "longitude": -82.288029,
      "city": "Saluda",
      "state": "NC",
      "county": "Polk"
  },
  {
      "zipCode": 28774,
      "latitude": 35.097862,
      "longitude": -82.988872,
      "city": "Sapphire",
      "state": "NC",
      "county": "Transylvania"
  },
  {
      "zipCode": 28775,
      "latitude": 35.03107,
      "longitude": -83.327411,
      "city": "Scaly Mountain",
      "state": "NC",
      "county": "Macon"
  },
  {
      "zipCode": 28776,
      "latitude": 35.483482,
      "longitude": -82.520707,
      "city": "Skyland",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28777,
      "latitude": 35.91464,
      "longitude": -82.07647,
      "city": "Spruce Pine",
      "state": "NC",
      "county": "Mitchell"
  },
  {
      "zipCode": 28778,
      "latitude": 35.608576,
      "longitude": -82.453107,
      "city": "Swannanoa",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28779,
      "latitude": 35.275674,
      "longitude": -83.187963,
      "city": "Sylva",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28781,
      "latitude": 35.219991,
      "longitude": -83.785397,
      "city": "Topton",
      "state": "NC",
      "county": "Cherokee"
  },
  {
      "zipCode": 28782,
      "latitude": 35.279322,
      "longitude": -82.179654,
      "city": "Tryon",
      "state": "NC",
      "county": "Polk"
  },
  {
      "zipCode": 28783,
      "latitude": 35.246573,
      "longitude": -83.044292,
      "city": "Tuckasegee",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28784,
      "latitude": 35.217377,
      "longitude": -82.417819,
      "city": "Tuxedo",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28785,
      "latitude": 35.65327,
      "longitude": -83.139633,
      "city": "Waynesville",
      "state": "NC",
      "county": "Haywood"
  },
  {
      "zipCode": 28786,
      "latitude": 35.585619,
      "longitude": -82.959617,
      "city": "Waynesville",
      "state": "NC",
      "county": "Haywood"
  },
  {
      "zipCode": 28787,
      "latitude": 35.702704,
      "longitude": -82.588915,
      "city": "Weaverville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28788,
      "latitude": 35.328533,
      "longitude": -83.233302,
      "city": "Webster",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28789,
      "latitude": 35.423045,
      "longitude": -83.263368,
      "city": "Whittier",
      "state": "NC",
      "county": "Jackson"
  },
  {
      "zipCode": 28790,
      "latitude": 35.208041,
      "longitude": -82.488614,
      "city": "Zirconia",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28791,
      "latitude": 35.346415,
      "longitude": -82.525027,
      "city": "Hendersonville",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28792,
      "latitude": 35.317413,
      "longitude": -82.450455,
      "city": "Hendersonville",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28793,
      "latitude": 35.292714,
      "longitude": -82.503611,
      "city": "Hendersonville",
      "state": "NC",
      "county": "Henderson"
  },
  {
      "zipCode": 28801,
      "latitude": 35.602711,
      "longitude": -82.567281,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28802,
      "latitude": 35.623732,
      "longitude": -82.667132,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28803,
      "latitude": 35.573619,
      "longitude": -82.580635,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28804,
      "latitude": 35.624512,
      "longitude": -82.589146,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28805,
      "latitude": 35.615785,
      "longitude": -82.517148,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28806,
      "latitude": 35.570357,
      "longitude": -82.605913,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28810,
      "latitude": 35.620335,
      "longitude": -82.528558,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28813,
      "latitude": 35.50042,
      "longitude": -82.502644,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28814,
      "latitude": 35.664752,
      "longitude": -82.492664,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28815,
      "latitude": 35.620335,
      "longitude": -82.528558,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28816,
      "latitude": 35.620335,
      "longitude": -82.528558,
      "city": "Asheville",
      "state": "NC",
      "county": "Buncombe"
  },
  {
      "zipCode": 28901,
      "latitude": 35.170731,
      "longitude": -83.87111,
      "city": "Andrews",
      "state": "NC",
      "county": "Cherokee"
  },
  {
      "zipCode": 28902,
      "latitude": 35.025885,
      "longitude": -83.878025,
      "city": "Brasstown",
      "state": "NC",
      "county": "Clay"
  },
  {
      "zipCode": 28903,
      "latitude": 35.094379,
      "longitude": -84.035916,
      "city": "Culberson",
      "state": "NC",
      "county": "Cherokee"
  },
  {
      "zipCode": 28904,
      "latitude": 35.071416,
      "longitude": -83.778309,
      "city": "Hayesville",
      "state": "NC",
      "county": "Clay"
  },
  {
      "zipCode": 28905,
      "latitude": 35.14641,
      "longitude": -83.902545,
      "city": "Marble",
      "state": "NC",
      "county": "Cherokee"
  },
  {
      "zipCode": 28906,
      "latitude": 35.131159,
      "longitude": -84.038776,
      "city": "Murphy",
      "state": "NC",
      "county": "Cherokee"
  },
  {
      "zipCode": 28909,
      "latitude": 35.015083,
      "longitude": -83.905055,
      "city": "Warne",
      "state": "NC",
      "county": "Clay"
  },
  {
      "zipCode": 29001,
      "latitude": 33.685155,
      "longitude": -80.190733,
      "city": "Alcolu",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29002,
      "latitude": 34.126535,
      "longitude": -81.234811,
      "city": "Ballentine",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29003,
      "latitude": 33.206294,
      "longitude": -80.973559,
      "city": "Bamberg",
      "state": "SC",
      "county": "Bamberg"
  },
  {
      "zipCode": 29006,
      "latitude": 33.871866,
      "longitude": -81.414643,
      "city": "Batesburg",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29009,
      "latitude": 34.436891,
      "longitude": -80.532238,
      "city": "Bethune",
      "state": "SC",
      "county": "Kershaw"
  },
  {
      "zipCode": 29010,
      "latitude": 34.207324,
      "longitude": -80.306569,
      "city": "Bishopville",
      "state": "SC",
      "county": "Lee"
  },
  {
      "zipCode": 29014,
      "latitude": 34.596281,
      "longitude": -81.083171,
      "city": "Blackstock",
      "state": "SC",
      "county": "Chester"
  },
  {
      "zipCode": 29015,
      "latitude": 34.41478,
      "longitude": -81.270211,
      "city": "Blair",
      "state": "SC",
      "county": "Fairfield"
  },
  {
      "zipCode": 29016,
      "latitude": 34.104758,
      "longitude": -80.918919,
      "city": "Blythewood",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29018,
      "latitude": 33.341855,
      "longitude": -80.565482,
      "city": "Bowman",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29020,
      "latitude": 34.313477,
      "longitude": -80.612489,
      "city": "Camden",
      "state": "SC",
      "county": "Kershaw"
  },
  {
      "zipCode": 29030,
      "latitude": 33.570316,
      "longitude": -80.708141,
      "city": "Cameron",
      "state": "SC",
      "county": "Calhoun"
  },
  {
      "zipCode": 29031,
      "latitude": 34.567049,
      "longitude": -81.481439,
      "city": "Carlisle",
      "state": "SC",
      "county": "Union"
  },
  {
      "zipCode": 29032,
      "latitude": 34.35723,
      "longitude": -80.54149,
      "city": "Cassatt",
      "state": "SC",
      "county": "Kershaw"
  },
  {
      "zipCode": 29033,
      "latitude": 33.868493,
      "longitude": -81.294081,
      "city": "Cayce",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29036,
      "latitude": 34.135909,
      "longitude": -81.334355,
      "city": "Chapin",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29037,
      "latitude": 34.221772,
      "longitude": -81.872428,
      "city": "Chappells",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29038,
      "latitude": 33.381696,
      "longitude": -80.956389,
      "city": "Cope",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29039,
      "latitude": 33.417322,
      "longitude": -80.91752,
      "city": "Cordova",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29040,
      "latitude": 34.025111,
      "longitude": -80.478151,
      "city": "Dalzell",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29041,
      "latitude": 33.688373,
      "longitude": -80.208109,
      "city": "Davis Station",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29042,
      "latitude": 33.237543,
      "longitude": -81.104895,
      "city": "Denmark",
      "state": "SC",
      "county": "Bamberg"
  },
  {
      "zipCode": 29044,
      "latitude": 33.992252,
      "longitude": -80.94791,
      "city": "Eastover",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29045,
      "latitude": 34.19522,
      "longitude": -80.79861,
      "city": "Elgin",
      "state": "SC",
      "county": "Kershaw"
  },
  {
      "zipCode": 29046,
      "latitude": 34.15998,
      "longitude": -80.240955,
      "city": "Elliott",
      "state": "SC",
      "county": "Lee"
  },
  {
      "zipCode": 29047,
      "latitude": 33.496664,
      "longitude": -80.582264,
      "city": "Elloree",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29048,
      "latitude": 33.416449,
      "longitude": -80.402308,
      "city": "Eutawville",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29051,
      "latitude": 33.688373,
      "longitude": -80.208109,
      "city": "Gable",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29052,
      "latitude": 33.84642,
      "longitude": -80.733782,
      "city": "Gadsden",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29053,
      "latitude": 33.815305,
      "longitude": -81.104235,
      "city": "Gaston",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29054,
      "latitude": 33.925422,
      "longitude": -81.410107,
      "city": "Gilbert",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29055,
      "latitude": 34.612456,
      "longitude": -80.940759,
      "city": "Great Falls",
      "state": "SC",
      "county": "Chester"
  },
  {
      "zipCode": 29056,
      "latitude": 33.785465,
      "longitude": -79.832218,
      "city": "Greeleyville",
      "state": "SC",
      "county": "Williamsburg"
  },
  {
      "zipCode": 29058,
      "latitude": 34.588803,
      "longitude": -80.718482,
      "city": "Heath Springs",
      "state": "SC",
      "county": "Lancaster"
  },
  {
      "zipCode": 29059,
      "latitude": 33.349458,
      "longitude": -80.438902,
      "city": "Holly Hill",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29061,
      "latitude": 33.920355,
      "longitude": -80.823,
      "city": "Hopkins",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29062,
      "latitude": 34.012593,
      "longitude": -80.567589,
      "city": "Horatio",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29063,
      "latitude": 34.085949,
      "longitude": -81.131151,
      "city": "Irmo",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29065,
      "latitude": 34.301089,
      "longitude": -81.285308,
      "city": "Jenkinsville",
      "state": "SC",
      "county": "Fairfield"
  },
  {
      "zipCode": 29067,
      "latitude": 34.600417,
      "longitude": -80.542015,
      "city": "Kershaw",
      "state": "SC",
      "county": "Lancaster"
  },
  {
      "zipCode": 29069,
      "latitude": 34.269558,
      "longitude": -80.005897,
      "city": "Lamar",
      "state": "SC",
      "county": "Darlington"
  },
  {
      "zipCode": 29070,
      "latitude": 33.895434,
      "longitude": -81.423918,
      "city": "Leesville",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29071,
      "latitude": 33.925183,
      "longitude": -81.248345,
      "city": "Lexington",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29072,
      "latitude": 33.976907,
      "longitude": -81.253034,
      "city": "Lexington",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29073,
      "latitude": 33.905323,
      "longitude": -81.335083,
      "city": "Lexington",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29074,
      "latitude": 34.456984,
      "longitude": -80.789236,
      "city": "Liberty Hill",
      "state": "SC",
      "county": "Kershaw"
  },
  {
      "zipCode": 29075,
      "latitude": 34.193507,
      "longitude": -81.396654,
      "city": "Little Mountain",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29078,
      "latitude": 34.260265,
      "longitude": -80.731308,
      "city": "Lugoff",
      "state": "SC",
      "county": "Kershaw"
  },
  {
      "zipCode": 29079,
      "latitude": 34.278255,
      "longitude": -80.100896,
      "city": "Lydia",
      "state": "SC",
      "county": "Darlington"
  },
  {
      "zipCode": 29080,
      "latitude": 34.049335,
      "longitude": -80.043417,
      "city": "Lynchburg",
      "state": "SC",
      "county": "Lee"
  },
  {
      "zipCode": 29081,
      "latitude": 33.08885,
      "longitude": -81.014013,
      "city": "Ehrhardt",
      "state": "SC",
      "county": "Bamberg"
  },
  {
      "zipCode": 29082,
      "latitude": 33.029619,
      "longitude": -80.762449,
      "city": "Lodge",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29101,
      "latitude": 34.470238,
      "longitude": -80.256814,
      "city": "Mc Bee",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29102,
      "latitude": 33.618408,
      "longitude": -80.21155,
      "city": "Manning",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29104,
      "latitude": 33.950173,
      "longitude": -80.217283,
      "city": "Mayesville",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29105,
      "latitude": 33.88797,
      "longitude": -81.679404,
      "city": "Monetta",
      "state": "SC",
      "county": "Saluda"
  },
  {
      "zipCode": 29106,
      "latitude": 34.373596,
      "longitude": -81.098735,
      "city": "Monticello",
      "state": "SC",
      "county": "Fairfield"
  },
  {
      "zipCode": 29107,
      "latitude": 33.525625,
      "longitude": -81.121253,
      "city": "Neeses",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29108,
      "latitude": 34.310027,
      "longitude": -81.608611,
      "city": "Newberry",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29111,
      "latitude": 33.688373,
      "longitude": -80.208109,
      "city": "New Zion",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29112,
      "latitude": 33.554183,
      "longitude": -81.07622,
      "city": "North",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29113,
      "latitude": 33.409377,
      "longitude": -80.977637,
      "city": "Norway",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29114,
      "latitude": 33.937811,
      "longitude": -79.903726,
      "city": "Olanta",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29115,
      "latitude": 33.490935,
      "longitude": -80.821825,
      "city": "Orangeburg",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29116,
      "latitude": 33.49593,
      "longitude": -80.824603,
      "city": "Orangeburg",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29117,
      "latitude": 33.442019,
      "longitude": -80.797486,
      "city": "Orangeburg",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29118,
      "latitude": 33.567493,
      "longitude": -80.924805,
      "city": "Orangeburg",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29122,
      "latitude": 34.23862,
      "longitude": -81.327116,
      "city": "Peak",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29123,
      "latitude": 33.766538,
      "longitude": -81.247547,
      "city": "Pelion",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29125,
      "latitude": 33.756082,
      "longitude": -80.457854,
      "city": "Pinewood",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29126,
      "latitude": 34.323019,
      "longitude": -81.412148,
      "city": "Pomaria",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29127,
      "latitude": 34.188062,
      "longitude": -81.552888,
      "city": "Prosperity",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29128,
      "latitude": 34.059796,
      "longitude": -80.513051,
      "city": "Rembert",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29129,
      "latitude": 33.844203,
      "longitude": -81.65509,
      "city": "Ridge Spring",
      "state": "SC",
      "county": "Saluda"
  },
  {
      "zipCode": 29130,
      "latitude": 34.306879,
      "longitude": -80.926792,
      "city": "Ridgeway",
      "state": "SC",
      "county": "Fairfield"
  },
  {
      "zipCode": 29132,
      "latitude": 34.307413,
      "longitude": -81.12523,
      "city": "Rion",
      "state": "SC",
      "county": "Fairfield"
  },
  {
      "zipCode": 29133,
      "latitude": 33.4008,
      "longitude": -80.701135,
      "city": "Rowesville",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29135,
      "latitude": 33.696732,
      "longitude": -80.867833,
      "city": "Saint Matthews",
      "state": "SC",
      "county": "Calhoun"
  },
  {
      "zipCode": 29137,
      "latitude": 33.605723,
      "longitude": -81.365813,
      "city": "Salley",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29138,
      "latitude": 34.031353,
      "longitude": -81.77374,
      "city": "Saluda",
      "state": "SC",
      "county": "Saluda"
  },
  {
      "zipCode": 29142,
      "latitude": 33.466571,
      "longitude": -80.782531,
      "city": "Santee",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29143,
      "latitude": 33.688373,
      "longitude": -80.208109,
      "city": "Sardinia",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29145,
      "latitude": 34.28357,
      "longitude": -81.624583,
      "city": "Silverstreet",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29146,
      "latitude": 33.517097,
      "longitude": -81.269821,
      "city": "Springfield",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29147,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "State Park",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29148,
      "latitude": 33.55156,
      "longitude": -80.322756,
      "city": "Summerton",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29150,
      "latitude": 33.913678,
      "longitude": -80.354238,
      "city": "Sumter",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29151,
      "latitude": 33.899856,
      "longitude": -80.37434,
      "city": "Sumter",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29152,
      "latitude": 33.918824,
      "longitude": -80.370098,
      "city": "Shaw A F B",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29153,
      "latitude": 33.94462,
      "longitude": -80.320637,
      "city": "Sumter",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29154,
      "latitude": 33.866953,
      "longitude": -80.426703,
      "city": "Sumter",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29160,
      "latitude": 33.729014,
      "longitude": -81.102992,
      "city": "Swansea",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29161,
      "latitude": 34.062595,
      "longitude": -79.933777,
      "city": "Timmonsville",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29162,
      "latitude": 33.884239,
      "longitude": -80.022566,
      "city": "Turbeville",
      "state": "SC",
      "county": "Clarendon"
  },
  {
      "zipCode": 29163,
      "latitude": 33.417884,
      "longitude": -80.434809,
      "city": "Vance",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29164,
      "latitude": 33.655015,
      "longitude": -81.416321,
      "city": "Wagener",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29166,
      "latitude": 34.000971,
      "longitude": -81.741069,
      "city": "Ward",
      "state": "SC",
      "county": "Saluda"
  },
  {
      "zipCode": 29168,
      "latitude": 33.895272,
      "longitude": -80.498019,
      "city": "Wedgefield",
      "state": "SC",
      "county": "Sumter"
  },
  {
      "zipCode": 29169,
      "latitude": 33.953664,
      "longitude": -81.187338,
      "city": "West Columbia",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29170,
      "latitude": 33.932043,
      "longitude": -81.15429,
      "city": "West Columbia",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29171,
      "latitude": 33.925183,
      "longitude": -81.248345,
      "city": "West Columbia",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29172,
      "latitude": 33.901578,
      "longitude": -81.090419,
      "city": "West Columbia",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29175,
      "latitude": 34.441802,
      "longitude": -80.603298,
      "city": "Westville",
      "state": "SC",
      "county": "Kershaw"
  },
  {
      "zipCode": 29176,
      "latitude": 34.373596,
      "longitude": -81.098735,
      "city": "White Oak",
      "state": "SC",
      "county": "Fairfield"
  },
  {
      "zipCode": 29177,
      "latitude": 34.150446,
      "longitude": -81.260051,
      "city": "White Rock",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29178,
      "latitude": 34.454376,
      "longitude": -81.563222,
      "city": "Whitmire",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29180,
      "latitude": 34.387337,
      "longitude": -81.082923,
      "city": "Winnsboro",
      "state": "SC",
      "county": "Fairfield"
  },
  {
      "zipCode": 29201,
      "latitude": 33.987454,
      "longitude": -81.024864,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29202,
      "latitude": 33.962455,
      "longitude": -80.979652,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29203,
      "latitude": 34.075613,
      "longitude": -81.039585,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29204,
      "latitude": 34.051603,
      "longitude": -80.978511,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29205,
      "latitude": 33.987555,
      "longitude": -81.000013,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29206,
      "latitude": 34.030004,
      "longitude": -80.951029,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29207,
      "latitude": 34.01974,
      "longitude": -80.939131,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29208,
      "latitude": 33.993705,
      "longitude": -81.019913,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29209,
      "latitude": 33.944163,
      "longitude": -80.942597,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29210,
      "latitude": 33.981468,
      "longitude": -80.993483,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29211,
      "latitude": 34.096716,
      "longitude": -80.922338,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29212,
      "latitude": 34.020988,
      "longitude": -81.197778,
      "city": "Columbia",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29214,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29215,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29216,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29217,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29218,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29219,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29220,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29221,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29222,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29223,
      "latitude": 34.099488,
      "longitude": -80.924638,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29224,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29225,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29226,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29227,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29228,
      "latitude": 33.925183,
      "longitude": -81.248345,
      "city": "Columbia",
      "state": "SC",
      "county": "Lexington"
  },
  {
      "zipCode": 29229,
      "latitude": 34.12266,
      "longitude": -80.884194,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29230,
      "latitude": 34.107483,
      "longitude": -81.062623,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29240,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29250,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29260,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29290,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29292,
      "latitude": 34.005992,
      "longitude": -80.970766,
      "city": "Columbia",
      "state": "SC",
      "county": "Richland"
  },
  {
      "zipCode": 29301,
      "latitude": 35.011309,
      "longitude": -81.993403,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29302,
      "latitude": 34.893907,
      "longitude": -81.83404,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29303,
      "latitude": 34.980316,
      "longitude": -81.916778,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29304,
      "latitude": 34.916625,
      "longitude": -81.863874,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29305,
      "latitude": 35.111404,
      "longitude": -82.105503,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29306,
      "latitude": 34.899493,
      "longitude": -81.86309,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29307,
      "latitude": 35.058097,
      "longitude": -81.98003,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29316,
      "latitude": 35.030481,
      "longitude": -81.979347,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29318,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29319,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "Spartanburg",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29320,
      "latitude": 34.95717,
      "longitude": -81.991626,
      "city": "Arcadia",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29321,
      "latitude": 34.720999,
      "longitude": -81.733884,
      "city": "Buffalo",
      "state": "SC",
      "county": "Union"
  },
  {
      "zipCode": 29322,
      "latitude": 35.113668,
      "longitude": -82.040137,
      "city": "Campobello",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29323,
      "latitude": 35.109575,
      "longitude": -82.010515,
      "city": "Chesnee",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29324,
      "latitude": 34.99056,
      "longitude": -81.83271,
      "city": "Clifton",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29325,
      "latitude": 34.43492,
      "longitude": -81.893558,
      "city": "Clinton",
      "state": "SC",
      "county": "Laurens"
  },
  {
      "zipCode": 29329,
      "latitude": 35.001748,
      "longitude": -81.832999,
      "city": "Converse",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29330,
      "latitude": 35.04921,
      "longitude": -81.83128,
      "city": "Cowpens",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29331,
      "latitude": 34.638315,
      "longitude": -81.856745,
      "city": "Cross Anchor",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29332,
      "latitude": 34.281449,
      "longitude": -81.977214,
      "city": "Cross Hill",
      "state": "SC",
      "county": "Laurens"
  },
  {
      "zipCode": 29333,
      "latitude": 34.968472,
      "longitude": -81.906376,
      "city": "Drayton",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29334,
      "latitude": 34.935427,
      "longitude": -81.992532,
      "city": "Duncan",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29335,
      "latitude": 34.749045,
      "longitude": -81.882943,
      "city": "Enoree",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29336,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "Fairforest",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29338,
      "latitude": 34.999397,
      "longitude": -82.057103,
      "city": "Fingerville",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29340,
      "latitude": 35.00549,
      "longitude": -81.63383,
      "city": "Gaffney",
      "state": "SC",
      "county": "Cherokee"
  },
  {
      "zipCode": 29341,
      "latitude": 35.099884,
      "longitude": -81.705609,
      "city": "Gaffney",
      "state": "SC",
      "county": "Cherokee"
  },
  {
      "zipCode": 29342,
      "latitude": 34.996314,
      "longitude": -81.652395,
      "city": "Gaffney",
      "state": "SC",
      "county": "Cherokee"
  },
  {
      "zipCode": 29346,
      "latitude": 35.044719,
      "longitude": -81.977324,
      "city": "Glendale",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29348,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "Gramling",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29349,
      "latitude": 35.075367,
      "longitude": -82.02964,
      "city": "Inman",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29351,
      "latitude": 34.415357,
      "longitude": -81.804172,
      "city": "Joanna",
      "state": "SC",
      "county": "Laurens"
  },
  {
      "zipCode": 29353,
      "latitude": 34.81248,
      "longitude": -81.64306,
      "city": "Jonesville",
      "state": "SC",
      "county": "Union"
  },
  {
      "zipCode": 29355,
      "latitude": 34.35512,
      "longitude": -81.65907,
      "city": "Kinards",
      "state": "SC",
      "county": "Newberry"
  },
  {
      "zipCode": 29356,
      "latitude": 35.146058,
      "longitude": -82.153545,
      "city": "Landrum",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29360,
      "latitude": 34.528882,
      "longitude": -82.055091,
      "city": "Laurens",
      "state": "SC",
      "county": "Laurens"
  },
  {
      "zipCode": 29364,
      "latitude": 34.79259,
      "longitude": -81.477843,
      "city": "Lockhart",
      "state": "SC",
      "county": "Union"
  },
  {
      "zipCode": 29365,
      "latitude": 35.006816,
      "longitude": -81.985214,
      "city": "Lyman",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29368,
      "latitude": 34.949241,
      "longitude": -81.990216,
      "city": "Mayo",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29369,
      "latitude": 34.970097,
      "longitude": -82.02732,
      "city": "Moore",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29370,
      "latitude": 34.375065,
      "longitude": -81.967088,
      "city": "Mountville",
      "state": "SC",
      "county": "Laurens"
  },
  {
      "zipCode": 29372,
      "latitude": 34.889502,
      "longitude": -81.773952,
      "city": "Pacolet",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29373,
      "latitude": 34.912385,
      "longitude": -81.748663,
      "city": "Pacolet Mills",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29374,
      "latitude": 34.887498,
      "longitude": -81.944611,
      "city": "Pauline",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29375,
      "latitude": 34.863648,
      "longitude": -82.110149,
      "city": "Reidville",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29376,
      "latitude": 34.829502,
      "longitude": -81.947247,
      "city": "Roebuck",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29377,
      "latitude": 34.987543,
      "longitude": -82.135145,
      "city": "Startex",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29378,
      "latitude": 34.853013,
      "longitude": -81.772738,
      "city": "Una",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29379,
      "latitude": 34.700463,
      "longitude": -81.647687,
      "city": "Union",
      "state": "SC",
      "county": "Union"
  },
  {
      "zipCode": 29384,
      "latitude": 34.335762,
      "longitude": -82.095499,
      "city": "Waterloo",
      "state": "SC",
      "county": "Laurens"
  },
  {
      "zipCode": 29385,
      "latitude": 34.834143,
      "longitude": -82.009137,
      "city": "Wellford",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29386,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "White Stone",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29388,
      "latitude": 34.890526,
      "longitude": -81.991496,
      "city": "Woodruff",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29390,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "Duncan",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29391,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "Duncan",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29395,
      "latitude": 34.858562,
      "longitude": -81.743856,
      "city": "Jonesville",
      "state": "SC",
      "county": "Union"
  },
  {
      "zipCode": 29401,
      "latitude": 32.780326,
      "longitude": -79.940844,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29402,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29403,
      "latitude": 32.822391,
      "longitude": -79.965464,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29404,
      "latitude": 32.898194,
      "longitude": -80.068564,
      "city": "Charleston Afb",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29405,
      "latitude": 32.853019,
      "longitude": -79.991295,
      "city": "North Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29406,
      "latitude": 32.927344,
      "longitude": -80.022009,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29407,
      "latitude": 32.812039,
      "longitude": -80.093128,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29409,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29410,
      "latitude": 33.192514,
      "longitude": -80.03063,
      "city": "North Charleston",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29412,
      "latitude": 32.717954,
      "longitude": -79.953725,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29413,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29414,
      "latitude": 32.810035,
      "longitude": -80.025928,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29415,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "North Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29416,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29417,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29418,
      "latitude": 32.89295,
      "longitude": -80.045849,
      "city": "North Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29419,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "North Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29420,
      "latitude": 33.014817,
      "longitude": -80.195143,
      "city": "North Charleston",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29422,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29423,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29424,
      "latitude": 32.783076,
      "longitude": -79.93701,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29425,
      "latitude": 32.786176,
      "longitude": -79.94711,
      "city": "Charleston",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29426,
      "latitude": 32.779028,
      "longitude": -80.328772,
      "city": "Adams Run",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29429,
      "latitude": 33.006254,
      "longitude": -79.656119,
      "city": "Awendaw",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29430,
      "latitude": 33.214551,
      "longitude": -79.816871,
      "city": "Bethera",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29431,
      "latitude": 33.270301,
      "longitude": -79.873606,
      "city": "Bonneau",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29432,
      "latitude": 33.272291,
      "longitude": -80.792496,
      "city": "Branchville",
      "state": "SC",
      "county": "Orangeburg"
  },
  {
      "zipCode": 29433,
      "latitude": 33.061376,
      "longitude": -80.631208,
      "city": "Canadys",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29434,
      "latitude": 33.136208,
      "longitude": -79.88258,
      "city": "Cordesville",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29435,
      "latitude": 32.778856,
      "longitude": -80.42646,
      "city": "Cottageville",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29436,
      "latitude": 33.298903,
      "longitude": -80.123972,
      "city": "Cross",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29437,
      "latitude": 33.14975,
      "longitude": -80.493683,
      "city": "Dorchester",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29438,
      "latitude": 32.502398,
      "longitude": -80.307509,
      "city": "Edisto Island",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29439,
      "latitude": 32.663043,
      "longitude": -79.92697,
      "city": "Folly Beach",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29440,
      "latitude": 33.439327,
      "longitude": -79.367525,
      "city": "Georgetown",
      "state": "SC",
      "county": "Georgetown"
  },
  {
      "zipCode": 29442,
      "latitude": 33.403693,
      "longitude": -79.226361,
      "city": "Georgetown",
      "state": "SC",
      "county": "Georgetown"
  },
  {
      "zipCode": 29445,
      "latitude": 33.057972,
      "longitude": -80.010088,
      "city": "Goose Creek",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29446,
      "latitude": 32.656443,
      "longitude": -80.566105,
      "city": "Green Pond",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29447,
      "latitude": 33.086324,
      "longitude": -80.62279,
      "city": "Grover",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29448,
      "latitude": 33.239304,
      "longitude": -80.453386,
      "city": "Harleyville",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29449,
      "latitude": 32.710486,
      "longitude": -80.27443,
      "city": "Hollywood",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29450,
      "latitude": 33.043929,
      "longitude": -79.784137,
      "city": "Huger",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29451,
      "latitude": 32.807206,
      "longitude": -79.760516,
      "city": "Isle Of Palms",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29452,
      "latitude": 32.730451,
      "longitude": -80.460935,
      "city": "Jacksonboro",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29453,
      "latitude": 33.183389,
      "longitude": -79.714992,
      "city": "Jamestown",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29455,
      "latitude": 32.835713,
      "longitude": -79.821702,
      "city": "Johns Island",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29456,
      "latitude": 33.01317,
      "longitude": -80.107174,
      "city": "Ladson",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29457,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Johns Island",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29458,
      "latitude": 33.119382,
      "longitude": -79.507388,
      "city": "Mc Clellanville",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29461,
      "latitude": 33.197118,
      "longitude": -80.023339,
      "city": "Moncks Corner",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29464,
      "latitude": 32.847273,
      "longitude": -79.820563,
      "city": "Mount Pleasant",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29465,
      "latitude": 32.84885,
      "longitude": -79.85773,
      "city": "Mount Pleasant",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29466,
      "latitude": 32.867373,
      "longitude": -79.804893,
      "city": "Mount Pleasant",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29468,
      "latitude": 33.411753,
      "longitude": -80.071194,
      "city": "Pineville",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29469,
      "latitude": 33.245244,
      "longitude": -80.098656,
      "city": "Pinopolis",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29470,
      "latitude": 32.787774,
      "longitude": -80.224756,
      "city": "Ravenel",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29471,
      "latitude": 33.172596,
      "longitude": -80.660654,
      "city": "Reevesville",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29472,
      "latitude": 33.032573,
      "longitude": -80.37462,
      "city": "Ridgeville",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29474,
      "latitude": 32.846691,
      "longitude": -80.595162,
      "city": "Round O",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29475,
      "latitude": 32.899004,
      "longitude": -80.819421,
      "city": "Ruffin",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29476,
      "latitude": 33.164201,
      "longitude": -79.904182,
      "city": "Russellville",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29477,
      "latitude": 33.151503,
      "longitude": -80.478782,
      "city": "Saint George",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29479,
      "latitude": 33.33618,
      "longitude": -79.92364,
      "city": "Saint Stephen",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29481,
      "latitude": 33.098059,
      "longitude": -80.682465,
      "city": "Smoaks",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29482,
      "latitude": 32.766336,
      "longitude": -79.83951,
      "city": "Sullivans Island",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29483,
      "latitude": 33.040201,
      "longitude": -80.431751,
      "city": "Summerville",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29484,
      "latitude": 33.00234,
      "longitude": -80.226694,
      "city": "Summerville",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29485,
      "latitude": 32.999726,
      "longitude": -80.329328,
      "city": "Summerville",
      "state": "SC",
      "county": "Dorchester"
  },
  {
      "zipCode": 29487,
      "latitude": 32.659087,
      "longitude": -80.167357,
      "city": "Wadmalaw Island",
      "state": "SC",
      "county": "Charleston"
  },
  {
      "zipCode": 29488,
      "latitude": 32.925196,
      "longitude": -80.703213,
      "city": "Walterboro",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29492,
      "latitude": 32.96678,
      "longitude": -79.852835,
      "city": "Charleston",
      "state": "SC",
      "county": "Berkeley"
  },
  {
      "zipCode": 29493,
      "latitude": 33.032704,
      "longitude": -80.84452,
      "city": "Williams",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29501,
      "latitude": 34.049849,
      "longitude": -79.685361,
      "city": "Florence",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29502,
      "latitude": 34.200994,
      "longitude": -79.784721,
      "city": "Florence",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29503,
      "latitude": 34.062999,
      "longitude": -79.650627,
      "city": "Florence",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29504,
      "latitude": 34.041582,
      "longitude": -79.693294,
      "city": "Florence",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29505,
      "latitude": 34.049881,
      "longitude": -79.693907,
      "city": "Florence",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29506,
      "latitude": 34.061465,
      "longitude": -79.622227,
      "city": "Florence",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29510,
      "latitude": 33.435562,
      "longitude": -79.409083,
      "city": "Andrews",
      "state": "SC",
      "county": "Georgetown"
  },
  {
      "zipCode": 29511,
      "latitude": 33.939968,
      "longitude": -79.041027,
      "city": "Aynor",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29512,
      "latitude": 34.598755,
      "longitude": -79.707693,
      "city": "Bennettsville",
      "state": "SC",
      "county": "Marlboro"
  },
  {
      "zipCode": 29516,
      "latitude": 34.517149,
      "longitude": -79.644187,
      "city": "Blenheim",
      "state": "SC",
      "county": "Marlboro"
  },
  {
      "zipCode": 29518,
      "latitude": 33.598355,
      "longitude": -79.709369,
      "city": "Cades",
      "state": "SC",
      "county": "Williamsburg"
  },
  {
      "zipCode": 29519,
      "latitude": 34.026958,
      "longitude": -79.353715,
      "city": "Centenary",
      "state": "SC",
      "county": "Marion"
  },
  {
      "zipCode": 29520,
      "latitude": 34.691548,
      "longitude": -79.943741,
      "city": "Cheraw",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29525,
      "latitude": 34.584251,
      "longitude": -79.587852,
      "city": "Clio",
      "state": "SC",
      "county": "Marlboro"
  },
  {
      "zipCode": 29526,
      "latitude": 33.868775,
      "longitude": -78.948287,
      "city": "Conway",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29527,
      "latitude": 33.82352,
      "longitude": -79.022331,
      "city": "Conway",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29528,
      "latitude": 33.935814,
      "longitude": -78.919205,
      "city": "Conway",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29530,
      "latitude": 33.980551,
      "longitude": -79.756816,
      "city": "Coward",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29532,
      "latitude": 34.317738,
      "longitude": -79.931655,
      "city": "Darlington",
      "state": "SC",
      "county": "Darlington"
  },
  {
      "zipCode": 29536,
      "latitude": 34.422187,
      "longitude": -79.341359,
      "city": "Dillon",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29540,
      "latitude": 34.384261,
      "longitude": -79.842182,
      "city": "Darlington",
      "state": "SC",
      "county": "Darlington"
  },
  {
      "zipCode": 29541,
      "latitude": 34.063272,
      "longitude": -79.733541,
      "city": "Effingham",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29542,
      "latitude": 34.425629,
      "longitude": -79.352291,
      "city": "Floyd Dale",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29543,
      "latitude": 34.375384,
      "longitude": -79.276529,
      "city": "Fork",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29544,
      "latitude": 33.953007,
      "longitude": -78.994502,
      "city": "Galivants Ferry",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29545,
      "latitude": 34.166757,
      "longitude": -78.958922,
      "city": "Green Sea",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29546,
      "latitude": 33.952411,
      "longitude": -79.360418,
      "city": "Gresham",
      "state": "SC",
      "county": "Marion"
  },
  {
      "zipCode": 29547,
      "latitude": 34.484912,
      "longitude": -79.350207,
      "city": "Hamer",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29550,
      "latitude": 34.345106,
      "longitude": -80.073953,
      "city": "Hartsville",
      "state": "SC",
      "county": "Darlington"
  },
  {
      "zipCode": 29551,
      "latitude": 34.365898,
      "longitude": -80.132418,
      "city": "Hartsville",
      "state": "SC",
      "county": "Darlington"
  },
  {
      "zipCode": 29554,
      "latitude": 33.732071,
      "longitude": -79.430945,
      "city": "Hemingway",
      "state": "SC",
      "county": "Williamsburg"
  },
  {
      "zipCode": 29555,
      "latitude": 33.886864,
      "longitude": -79.489745,
      "city": "Johnsonville",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29556,
      "latitude": 33.663869,
      "longitude": -79.837756,
      "city": "Kingstree",
      "state": "SC",
      "county": "Williamsburg"
  },
  {
      "zipCode": 29560,
      "latitude": 33.908371,
      "longitude": -79.743104,
      "city": "Lake City",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29563,
      "latitude": 34.350121,
      "longitude": -79.240676,
      "city": "Lake View",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29564,
      "latitude": 33.598355,
      "longitude": -79.709369,
      "city": "Lane",
      "state": "SC",
      "county": "Williamsburg"
  },
  {
      "zipCode": 29565,
      "latitude": 34.384337,
      "longitude": -79.478122,
      "city": "Latta",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29566,
      "latitude": 33.816472,
      "longitude": -78.758018,
      "city": "Little River",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29567,
      "latitude": 34.495502,
      "longitude": -79.348165,
      "city": "Little Rock",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29568,
      "latitude": 33.833574,
      "longitude": -78.885268,
      "city": "Longs",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29569,
      "latitude": 33.977392,
      "longitude": -78.918109,
      "city": "Loris",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29570,
      "latitude": 34.686733,
      "longitude": -79.555879,
      "city": "Mc Coll",
      "state": "SC",
      "county": "Marlboro"
  },
  {
      "zipCode": 29571,
      "latitude": 34.164144,
      "longitude": -79.363948,
      "city": "Marion",
      "state": "SC",
      "county": "Marion"
  },
  {
      "zipCode": 29572,
      "latitude": 33.711741,
      "longitude": -78.852005,
      "city": "Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29573,
      "latitude": 34.488255,
      "longitude": -79.476859,
      "city": "Minturn",
      "state": "SC",
      "county": "Dillon"
  },
  {
      "zipCode": 29574,
      "latitude": 34.159262,
      "longitude": -79.273221,
      "city": "Mullins",
      "state": "SC",
      "county": "Marion"
  },
  {
      "zipCode": 29575,
      "latitude": 33.7577,
      "longitude": -78.907599,
      "city": "Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29576,
      "latitude": 33.529642,
      "longitude": -79.04822,
      "city": "Murrells Inlet",
      "state": "SC",
      "county": "Georgetown"
  },
  {
      "zipCode": 29577,
      "latitude": 33.788817,
      "longitude": -78.96918,
      "city": "Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29578,
      "latitude": 33.738887,
      "longitude": -78.999561,
      "city": "Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29579,
      "latitude": 33.737697,
      "longitude": -78.978653,
      "city": "Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29580,
      "latitude": 33.598355,
      "longitude": -79.709369,
      "city": "Nesmith",
      "state": "SC",
      "county": "Williamsburg"
  },
  {
      "zipCode": 29581,
      "latitude": 34.000746,
      "longitude": -79.102314,
      "city": "Nichols",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29582,
      "latitude": 33.770465,
      "longitude": -78.771832,
      "city": "North Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29583,
      "latitude": 33.972437,
      "longitude": -79.59917,
      "city": "Pamplico",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29584,
      "latitude": 34.591849,
      "longitude": -80.170691,
      "city": "Patrick",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29585,
      "latitude": 33.464107,
      "longitude": -79.183904,
      "city": "Pawleys Island",
      "state": "SC",
      "county": "Georgetown"
  },
  {
      "zipCode": 29587,
      "latitude": 33.621094,
      "longitude": -78.963155,
      "city": "Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29588,
      "latitude": 33.670794,
      "longitude": -79.023125,
      "city": "Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29589,
      "latitude": 34.105265,
      "longitude": -79.322116,
      "city": "Rains",
      "state": "SC",
      "county": "Marion"
  },
  {
      "zipCode": 29590,
      "latitude": 33.598355,
      "longitude": -79.709369,
      "city": "Salters",
      "state": "SC",
      "county": "Williamsburg"
  },
  {
      "zipCode": 29591,
      "latitude": 33.937275,
      "longitude": -79.769899,
      "city": "Scranton",
      "state": "SC",
      "county": "Florence"
  },
  {
      "zipCode": 29592,
      "latitude": 34.258011,
      "longitude": -79.478292,
      "city": "Sellers",
      "state": "SC",
      "county": "Marion"
  },
  {
      "zipCode": 29593,
      "latitude": 34.442018,
      "longitude": -79.880497,
      "city": "Society Hill",
      "state": "SC",
      "county": "Darlington"
  },
  {
      "zipCode": 29594,
      "latitude": 34.64051,
      "longitude": -79.586895,
      "city": "Tatum",
      "state": "SC",
      "county": "Marlboro"
  },
  {
      "zipCode": 29596,
      "latitude": 34.732322,
      "longitude": -79.829665,
      "city": "Wallace",
      "state": "SC",
      "county": "Marlboro"
  },
  {
      "zipCode": 29597,
      "latitude": 33.785803,
      "longitude": -78.971795,
      "city": "North Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29598,
      "latitude": 33.935814,
      "longitude": -78.919205,
      "city": "North Myrtle Beach",
      "state": "SC",
      "county": "Horry"
  },
  {
      "zipCode": 29601,
      "latitude": 34.848567,
      "longitude": -82.402871,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29602,
      "latitude": 34.800718,
      "longitude": -82.395594,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29603,
      "latitude": 34.837666,
      "longitude": -82.371519,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29604,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29605,
      "latitude": 34.772107,
      "longitude": -82.374371,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29606,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29607,
      "latitude": 34.909132,
      "longitude": -82.384232,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29608,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29609,
      "latitude": 34.902126,
      "longitude": -82.386943,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29610,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29611,
      "latitude": 34.83889,
      "longitude": -82.420851,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29612,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29613,
      "latitude": 34.924876,
      "longitude": -82.433132,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29614,
      "latitude": 34.872423,
      "longitude": -82.362585,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29615,
      "latitude": 34.938893,
      "longitude": -82.358999,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29616,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29617,
      "latitude": 34.955463,
      "longitude": -82.465633,
      "city": "Greenville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29620,
      "latitude": 34.215714,
      "longitude": -82.446307,
      "city": "Abbeville",
      "state": "SC",
      "county": "Abbeville"
  },
  {
      "zipCode": 29621,
      "latitude": 34.492718,
      "longitude": -82.638297,
      "city": "Anderson",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29622,
      "latitude": 34.491988,
      "longitude": -82.782868,
      "city": "Anderson",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29623,
      "latitude": 34.438087,
      "longitude": -82.835449,
      "city": "Anderson",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29624,
      "latitude": 34.51236,
      "longitude": -82.708334,
      "city": "Anderson",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29625,
      "latitude": 34.468005,
      "longitude": -82.685702,
      "city": "Anderson",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29626,
      "latitude": 34.415763,
      "longitude": -82.705099,
      "city": "Anderson",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29627,
      "latitude": 34.492422,
      "longitude": -82.569083,
      "city": "Belton",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29628,
      "latitude": 34.191846,
      "longitude": -82.490284,
      "city": "Calhoun Falls",
      "state": "SC",
      "county": "Abbeville"
  },
  {
      "zipCode": 29630,
      "latitude": 34.744649,
      "longitude": -82.797267,
      "city": "Central",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29631,
      "latitude": 34.75974,
      "longitude": -82.802154,
      "city": "Clemson",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29632,
      "latitude": 34.847372,
      "longitude": -82.710126,
      "city": "Clemson",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29633,
      "latitude": 34.847372,
      "longitude": -82.710126,
      "city": "Clemson",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29634,
      "latitude": 34.847372,
      "longitude": -82.710126,
      "city": "Clemson",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29635,
      "latitude": 35.078914,
      "longitude": -82.507625,
      "city": "Cleveland",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29636,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Conestee",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29638,
      "latitude": 34.330362,
      "longitude": -82.336693,
      "city": "Donalds",
      "state": "SC",
      "county": "Abbeville"
  },
  {
      "zipCode": 29639,
      "latitude": 34.326755,
      "longitude": -82.430787,
      "city": "Due West",
      "state": "SC",
      "county": "Abbeville"
  },
  {
      "zipCode": 29640,
      "latitude": 34.843968,
      "longitude": -82.643754,
      "city": "Easley",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29641,
      "latitude": 34.813542,
      "longitude": -82.653067,
      "city": "Easley",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29642,
      "latitude": 34.790129,
      "longitude": -82.593723,
      "city": "Easley",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29643,
      "latitude": 34.542683,
      "longitude": -83.029707,
      "city": "Fair Play",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29644,
      "latitude": 34.65781,
      "longitude": -82.2441,
      "city": "Fountain Inn",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29645,
      "latitude": 34.595311,
      "longitude": -82.114703,
      "city": "Gray Court",
      "state": "SC",
      "county": "Laurens"
  },
  {
      "zipCode": 29646,
      "latitude": 34.114209,
      "longitude": -82.128301,
      "city": "Greenwood",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29647,
      "latitude": 34.178813,
      "longitude": -82.163078,
      "city": "Greenwood",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29648,
      "latitude": 34.21241,
      "longitude": -82.168139,
      "city": "Greenwood",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29649,
      "latitude": 34.290916,
      "longitude": -82.154473,
      "city": "Greenwood",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29650,
      "latitude": 34.948738,
      "longitude": -82.368415,
      "city": "Greer",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29651,
      "latitude": 34.962338,
      "longitude": -82.330093,
      "city": "Greer",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29652,
      "latitude": 34.849745,
      "longitude": -82.453779,
      "city": "Greer",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29653,
      "latitude": 34.274203,
      "longitude": -82.21392,
      "city": "Hodges",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29654,
      "latitude": 34.416329,
      "longitude": -82.508821,
      "city": "Honea Path",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29655,
      "latitude": 34.420766,
      "longitude": -82.608768,
      "city": "Iva",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29656,
      "latitude": 34.625157,
      "longitude": -82.765393,
      "city": "La France",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29657,
      "latitude": 34.783697,
      "longitude": -82.70937,
      "city": "Liberty",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29658,
      "latitude": 34.763442,
      "longitude": -83.277599,
      "city": "Long Creek",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29659,
      "latitude": 34.221282,
      "longitude": -82.63181,
      "city": "Lowndesville",
      "state": "SC",
      "county": "Abbeville"
  },
  {
      "zipCode": 29661,
      "latitude": 35.071605,
      "longitude": -82.531215,
      "city": "Marietta",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29662,
      "latitude": 34.750238,
      "longitude": -82.267062,
      "city": "Mauldin",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29664,
      "latitude": 34.85656,
      "longitude": -83.191276,
      "city": "Mountain Rest",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29665,
      "latitude": 34.724743,
      "longitude": -82.910578,
      "city": "Newry",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29666,
      "latitude": 34.119292,
      "longitude": -81.998485,
      "city": "Ninety Six",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29667,
      "latitude": 34.764198,
      "longitude": -82.76478,
      "city": "Norris",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29669,
      "latitude": 34.656799,
      "longitude": -82.535325,
      "city": "Pelzer",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29670,
      "latitude": 34.608943,
      "longitude": -82.597346,
      "city": "Pendleton",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29671,
      "latitude": 34.873784,
      "longitude": -82.712484,
      "city": "Pickens",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29672,
      "latitude": 34.773984,
      "longitude": -82.945651,
      "city": "Seneca",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29673,
      "latitude": 34.72209,
      "longitude": -82.392453,
      "city": "Piedmont",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29675,
      "latitude": 34.764076,
      "longitude": -83.088332,
      "city": "Richland",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29676,
      "latitude": 34.866881,
      "longitude": -82.97658,
      "city": "Salem",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29677,
      "latitude": 34.59755,
      "longitude": -82.756844,
      "city": "Sandy Springs",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29678,
      "latitude": 34.715679,
      "longitude": -82.963909,
      "city": "Seneca",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29679,
      "latitude": 34.607898,
      "longitude": -82.939668,
      "city": "Seneca",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29680,
      "latitude": 34.696203,
      "longitude": -82.289322,
      "city": "Simpsonville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29681,
      "latitude": 34.80728,
      "longitude": -82.266734,
      "city": "Simpsonville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29682,
      "latitude": 34.837186,
      "longitude": -82.825562,
      "city": "Six Mile",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29683,
      "latitude": 35.028924,
      "longitude": -82.494068,
      "city": "Slater",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29684,
      "latitude": 34.409132,
      "longitude": -82.660151,
      "city": "Starr",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29685,
      "latitude": 34.977961,
      "longitude": -82.784806,
      "city": "Sunset",
      "state": "SC",
      "county": "Pickens"
  },
  {
      "zipCode": 29686,
      "latitude": 34.897008,
      "longitude": -83.037482,
      "city": "Tamassee",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29687,
      "latitude": 34.970915,
      "longitude": -82.38156,
      "city": "Taylors",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29688,
      "latitude": 35.047684,
      "longitude": -82.353038,
      "city": "Tigerville",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29689,
      "latitude": 34.533588,
      "longitude": -82.892202,
      "city": "Townville",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29690,
      "latitude": 35.071632,
      "longitude": -82.374186,
      "city": "Travelers Rest",
      "state": "SC",
      "county": "Greenville"
  },
  {
      "zipCode": 29691,
      "latitude": 34.845676,
      "longitude": -83.067197,
      "city": "Walhalla",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29692,
      "latitude": 34.388579,
      "longitude": -82.248844,
      "city": "Ware Shoals",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29693,
      "latitude": 34.696493,
      "longitude": -83.153509,
      "city": "Westminster",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29695,
      "latitude": 34.218582,
      "longitude": -82.304658,
      "city": "Hodges",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29696,
      "latitude": 34.703943,
      "longitude": -83.035205,
      "city": "West Union",
      "state": "SC",
      "county": "Oconee"
  },
  {
      "zipCode": 29697,
      "latitude": 34.553642,
      "longitude": -82.612123,
      "city": "Williamston",
      "state": "SC",
      "county": "Anderson"
  },
  {
      "zipCode": 29698,
      "latitude": 34.888237,
      "longitude": -81.96902,
      "city": "Greenville",
      "state": "SC",
      "county": "Spartanburg"
  },
  {
      "zipCode": 29702,
      "latitude": 35.10953,
      "longitude": -81.494193,
      "city": "Blacksburg",
      "state": "SC",
      "county": "Cherokee"
  },
  {
      "zipCode": 29703,
      "latitude": 34.992612,
      "longitude": -81.178712,
      "city": "Bowling Green",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29704,
      "latitude": 34.859604,
      "longitude": -80.938257,
      "city": "Catawba",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29706,
      "latitude": 34.693285,
      "longitude": -81.168022,
      "city": "Chester",
      "state": "SC",
      "county": "Chester"
  },
  {
      "zipCode": 29708,
      "latitude": 35.050243,
      "longitude": -80.990828,
      "city": "Fort Mill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29709,
      "latitude": 34.755564,
      "longitude": -80.129515,
      "city": "Chesterfield",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29710,
      "latitude": 35.035952,
      "longitude": -81.165152,
      "city": "Clover",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29712,
      "latitude": 34.778195,
      "longitude": -80.996122,
      "city": "Edgemoor",
      "state": "SC",
      "county": "Chester"
  },
  {
      "zipCode": 29714,
      "latitude": 34.696331,
      "longitude": -80.943356,
      "city": "Fort Lawn",
      "state": "SC",
      "county": "Chester"
  },
  {
      "zipCode": 29715,
      "latitude": 34.987625,
      "longitude": -81.155211,
      "city": "Fort Mill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29716,
      "latitude": 35.062815,
      "longitude": -80.969035,
      "city": "Fort Mill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29717,
      "latitude": 34.963339,
      "longitude": -81.433448,
      "city": "Hickory Grove",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29718,
      "latitude": 34.71154,
      "longitude": -80.392891,
      "city": "Jefferson",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29720,
      "latitude": 34.717915,
      "longitude": -80.683935,
      "city": "Lancaster",
      "state": "SC",
      "county": "Lancaster"
  },
  {
      "zipCode": 29721,
      "latitude": 34.699412,
      "longitude": -80.781721,
      "city": "Lancaster",
      "state": "SC",
      "county": "Lancaster"
  },
  {
      "zipCode": 29722,
      "latitude": 34.767269,
      "longitude": -80.658932,
      "city": "Lancaster",
      "state": "SC",
      "county": "Lancaster"
  },
  {
      "zipCode": 29724,
      "latitude": 34.775302,
      "longitude": -81.022816,
      "city": "Lando",
      "state": "SC",
      "county": "Chester"
  },
  {
      "zipCode": 29726,
      "latitude": 34.859857,
      "longitude": -81.227432,
      "city": "Mc Connells",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29727,
      "latitude": 34.591849,
      "longitude": -80.170691,
      "city": "Mount Croghan",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29728,
      "latitude": 34.768882,
      "longitude": -80.3751,
      "city": "Pageland",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29729,
      "latitude": 34.692493,
      "longitude": -81.003877,
      "city": "Richburg",
      "state": "SC",
      "county": "Chester"
  },
  {
      "zipCode": 29730,
      "latitude": 34.909109,
      "longitude": -81.052437,
      "city": "Rock Hill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29731,
      "latitude": 34.992612,
      "longitude": -81.178712,
      "city": "Rock Hill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29732,
      "latitude": 34.937706,
      "longitude": -81.092227,
      "city": "Rock Hill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29733,
      "latitude": 34.992612,
      "longitude": -81.178712,
      "city": "Rock Hill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29734,
      "latitude": 34.992612,
      "longitude": -81.178712,
      "city": "Rock Hill",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29741,
      "latitude": 34.721259,
      "longitude": -80.18961,
      "city": "Ruby",
      "state": "SC",
      "county": "Chesterfield"
  },
  {
      "zipCode": 29742,
      "latitude": 34.932788,
      "longitude": -81.338537,
      "city": "Sharon",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29743,
      "latitude": 35.025155,
      "longitude": -81.407268,
      "city": "Smyrna",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29744,
      "latitude": 34.973866,
      "longitude": -80.851836,
      "city": "Van Wyck",
      "state": "SC",
      "county": "Lancaster"
  },
  {
      "zipCode": 29745,
      "latitude": 34.991814,
      "longitude": -81.208605,
      "city": "York",
      "state": "SC",
      "county": "York"
  },
  {
      "zipCode": 29801,
      "latitude": 33.554433,
      "longitude": -81.69588,
      "city": "Aiken",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29802,
      "latitude": 33.723519,
      "longitude": -81.59072,
      "city": "Aiken",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29803,
      "latitude": 33.505914,
      "longitude": -81.695053,
      "city": "Aiken",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29804,
      "latitude": 33.53773,
      "longitude": -81.599891,
      "city": "Aiken",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29805,
      "latitude": 33.699335,
      "longitude": -81.646528,
      "city": "Aiken",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29808,
      "latitude": 33.53773,
      "longitude": -81.599891,
      "city": "Aiken",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29809,
      "latitude": 33.413029,
      "longitude": -81.689951,
      "city": "New Ellenton",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29810,
      "latitude": 32.990158,
      "longitude": -81.287561,
      "city": "Allendale",
      "state": "SC",
      "county": "Allendale"
  },
  {
      "zipCode": 29812,
      "latitude": 33.249806,
      "longitude": -81.349595,
      "city": "Barnwell",
      "state": "SC",
      "county": "Barnwell"
  },
  {
      "zipCode": 29813,
      "latitude": 33.291666,
      "longitude": -81.482658,
      "city": "Hilda",
      "state": "SC",
      "county": "Barnwell"
  },
  {
      "zipCode": 29816,
      "latitude": 33.496998,
      "longitude": -81.850871,
      "city": "Bath",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29817,
      "latitude": 33.363819,
      "longitude": -81.29751,
      "city": "Blackville",
      "state": "SC",
      "county": "Barnwell"
  },
  {
      "zipCode": 29819,
      "latitude": 34.059884,
      "longitude": -82.201429,
      "city": "Bradley",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29821,
      "latitude": 33.623057,
      "longitude": -82.132655,
      "city": "Clarks Hill",
      "state": "SC",
      "county": "Mccormick"
  },
  {
      "zipCode": 29822,
      "latitude": 33.499656,
      "longitude": -81.86485,
      "city": "Clearwater",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29824,
      "latitude": 33.767808,
      "longitude": -81.987524,
      "city": "Edgefield",
      "state": "SC",
      "county": "Edgefield"
  },
  {
      "zipCode": 29826,
      "latitude": 33.38599,
      "longitude": -81.378496,
      "city": "Elko",
      "state": "SC",
      "county": "Barnwell"
  },
  {
      "zipCode": 29827,
      "latitude": 32.959892,
      "longitude": -81.249224,
      "city": "Fairfax",
      "state": "SC",
      "county": "Allendale"
  },
  {
      "zipCode": 29828,
      "latitude": 33.518235,
      "longitude": -81.827708,
      "city": "Gloverville",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29829,
      "latitude": 33.641442,
      "longitude": -81.79287,
      "city": "Graniteville",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29831,
      "latitude": 33.421714,
      "longitude": -81.724702,
      "city": "Jackson",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29832,
      "latitude": 33.783177,
      "longitude": -81.818415,
      "city": "Johnston",
      "state": "SC",
      "county": "Edgefield"
  },
  {
      "zipCode": 29834,
      "latitude": 33.497937,
      "longitude": -81.844485,
      "city": "Langley",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29835,
      "latitude": 33.893238,
      "longitude": -82.260861,
      "city": "Mc Cormick",
      "state": "SC",
      "county": "Mccormick"
  },
  {
      "zipCode": 29836,
      "latitude": 32.951573,
      "longitude": -81.348337,
      "city": "Martin",
      "state": "SC",
      "county": "Allendale"
  },
  {
      "zipCode": 29838,
      "latitude": 33.705234,
      "longitude": -82.218723,
      "city": "Modoc",
      "state": "SC",
      "county": "Mccormick"
  },
  {
      "zipCode": 29839,
      "latitude": 33.507335,
      "longitude": -81.599198,
      "city": "Montmorenci",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29840,
      "latitude": 33.83562,
      "longitude": -82.319983,
      "city": "Mount Carmel",
      "state": "SC",
      "county": "Mccormick"
  },
  {
      "zipCode": 29841,
      "latitude": 33.466705,
      "longitude": -81.739059,
      "city": "North Augusta",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29842,
      "latitude": 33.470747,
      "longitude": -81.8639,
      "city": "Beech Island",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29843,
      "latitude": 33.178426,
      "longitude": -81.182699,
      "city": "Olar",
      "state": "SC",
      "county": "Bamberg"
  },
  {
      "zipCode": 29844,
      "latitude": 33.785444,
      "longitude": -82.211086,
      "city": "Parksville",
      "state": "SC",
      "county": "Mccormick"
  },
  {
      "zipCode": 29845,
      "latitude": 33.83562,
      "longitude": -82.319983,
      "city": "Plum Branch",
      "state": "SC",
      "county": "Mccormick"
  },
  {
      "zipCode": 29846,
      "latitude": 33.024104,
      "longitude": -81.225755,
      "city": "Sycamore",
      "state": "SC",
      "county": "Allendale"
  },
  {
      "zipCode": 29847,
      "latitude": 33.698302,
      "longitude": -81.864218,
      "city": "Trenton",
      "state": "SC",
      "county": "Edgefield"
  },
  {
      "zipCode": 29848,
      "latitude": 33.996062,
      "longitude": -82.150998,
      "city": "Troy",
      "state": "SC",
      "county": "Greenwood"
  },
  {
      "zipCode": 29849,
      "latitude": 33.098848,
      "longitude": -81.206789,
      "city": "Ulmer",
      "state": "SC",
      "county": "Allendale"
  },
  {
      "zipCode": 29850,
      "latitude": 33.545819,
      "longitude": -81.686183,
      "city": "Vaucluse",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29851,
      "latitude": 33.526017,
      "longitude": -81.795561,
      "city": "Warrenville",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29853,
      "latitude": 33.302264,
      "longitude": -81.376276,
      "city": "Williston",
      "state": "SC",
      "county": "Barnwell"
  },
  {
      "zipCode": 29856,
      "latitude": 33.480097,
      "longitude": -81.549415,
      "city": "Windsor",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29860,
      "latitude": 33.556645,
      "longitude": -81.855456,
      "city": "North Augusta",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29861,
      "latitude": 33.53773,
      "longitude": -81.599891,
      "city": "North Augusta",
      "state": "SC",
      "county": "Aiken"
  },
  {
      "zipCode": 29899,
      "latitude": 33.83562,
      "longitude": -82.319983,
      "city": "Mc Cormick",
      "state": "SC",
      "county": "Mccormick"
  },
  {
      "zipCode": 29901,
      "latitude": 32.424353,
      "longitude": -80.565343,
      "city": "Beaufort",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29902,
      "latitude": 32.404673,
      "longitude": -80.653104,
      "city": "Beaufort",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29903,
      "latitude": 32.443974,
      "longitude": -80.735245,
      "city": "Beaufort",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29904,
      "latitude": 32.390605,
      "longitude": -80.661027,
      "city": "Beaufort",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29905,
      "latitude": 32.340119,
      "longitude": -80.689041,
      "city": "Beaufort",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29906,
      "latitude": 32.382327,
      "longitude": -80.760332,
      "city": "Beaufort",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29910,
      "latitude": 32.34969,
      "longitude": -80.899506,
      "city": "Bluffton",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29911,
      "latitude": 32.885698,
      "longitude": -81.206373,
      "city": "Brunson",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29912,
      "latitude": 32.488929,
      "longitude": -80.989142,
      "city": "Coosawatchie",
      "state": "SC",
      "county": "Jasper"
  },
  {
      "zipCode": 29913,
      "latitude": 32.929902,
      "longitude": -81.184627,
      "city": "Crocketville",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29914,
      "latitude": 32.552652,
      "longitude": -80.677001,
      "city": "Dale",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29915,
      "latitude": 32.156011,
      "longitude": -80.787899,
      "city": "Daufuskie Island",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29916,
      "latitude": 32.718903,
      "longitude": -81.151373,
      "city": "Early Branch",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29918,
      "latitude": 32.80661,
      "longitude": -81.183087,
      "city": "Estill",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29920,
      "latitude": 32.371627,
      "longitude": -80.538322,
      "city": "Saint Helena Island",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29921,
      "latitude": 32.718212,
      "longitude": -81.207112,
      "city": "Furman",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29922,
      "latitude": 32.74172,
      "longitude": -81.193318,
      "city": "Garnett",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29923,
      "latitude": 32.788773,
      "longitude": -81.126913,
      "city": "Gifford",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29924,
      "latitude": 32.882332,
      "longitude": -81.132412,
      "city": "Hampton",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29925,
      "latitude": 32.21319,
      "longitude": -80.799698,
      "city": "Hilton Head Island",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29926,
      "latitude": 32.207724,
      "longitude": -80.747577,
      "city": "Hilton Head Island",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29927,
      "latitude": 32.244037,
      "longitude": -81.074835,
      "city": "Hardeeville",
      "state": "SC",
      "county": "Jasper"
  },
  {
      "zipCode": 29928,
      "latitude": 32.265135,
      "longitude": -80.644117,
      "city": "Hilton Head Island",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29929,
      "latitude": 32.946307,
      "longitude": -80.948043,
      "city": "Islandton",
      "state": "SC",
      "county": "Colleton"
  },
  {
      "zipCode": 29931,
      "latitude": 32.538432,
      "longitude": -80.702594,
      "city": "Lobeco",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29932,
      "latitude": 32.788773,
      "longitude": -81.126913,
      "city": "Luray",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29933,
      "latitude": 32.788773,
      "longitude": -81.126913,
      "city": "Miley",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29934,
      "latitude": 32.271811,
      "longitude": -81.061485,
      "city": "Pineland",
      "state": "SC",
      "county": "Jasper"
  },
  {
      "zipCode": 29935,
      "latitude": 32.387434,
      "longitude": -80.684392,
      "city": "Port Royal",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29936,
      "latitude": 32.486826,
      "longitude": -80.925708,
      "city": "Ridgeland",
      "state": "SC",
      "county": "Jasper"
  },
  {
      "zipCode": 29938,
      "latitude": 32.390605,
      "longitude": -80.661027,
      "city": "Hilton Head Island",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29939,
      "latitude": 32.678624,
      "longitude": -81.245487,
      "city": "Scotia",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29940,
      "latitude": 32.569296,
      "longitude": -80.719179,
      "city": "Seabrook",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29941,
      "latitude": 32.587814,
      "longitude": -80.718285,
      "city": "Sheldon",
      "state": "SC",
      "county": "Beaufort"
  },
  {
      "zipCode": 29943,
      "latitude": 32.393253,
      "longitude": -81.011496,
      "city": "Tillman",
      "state": "SC",
      "county": "Jasper"
  },
  {
      "zipCode": 29944,
      "latitude": 32.797222,
      "longitude": -81.079803,
      "city": "Varnville",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 29945,
      "latitude": 32.692321,
      "longitude": -80.887321,
      "city": "Yemassee",
      "state": "SC",
      "county": "Hampton"
  },
  {
      "zipCode": 30002,
      "latitude": 33.771704,
      "longitude": -84.23937,
      "city": "Avondale Estates",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30003,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "Norcross",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30004,
      "latitude": 34.112373,
      "longitude": -84.30205,
      "city": "Alpharetta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30005,
      "latitude": 34.078229,
      "longitude": -84.228131,
      "city": "Alpharetta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30006,
      "latitude": 33.912473,
      "longitude": -84.557181,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30007,
      "latitude": 33.912473,
      "longitude": -84.557181,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30008,
      "latitude": 33.897203,
      "longitude": -84.591983,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30009,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Alpharetta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30010,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "Norcross",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30011,
      "latitude": 34.019109,
      "longitude": -83.826094,
      "city": "Auburn",
      "state": "GA",
      "county": "Barrow"
  },
  {
      "zipCode": 30012,
      "latitude": 33.719178,
      "longitude": -84.002061,
      "city": "Conyers",
      "state": "GA",
      "county": "Rockdale"
  },
  {
      "zipCode": 30013,
      "latitude": 33.643597,
      "longitude": -83.968359,
      "city": "Conyers",
      "state": "GA",
      "county": "Rockdale"
  },
  {
      "zipCode": 30014,
      "latitude": 33.529341,
      "longitude": -83.849622,
      "city": "Covington",
      "state": "GA",
      "county": "Newton"
  },
  {
      "zipCode": 30015,
      "latitude": 33.555791,
      "longitude": -83.864915,
      "city": "Covington",
      "state": "GA",
      "county": "Newton"
  },
  {
      "zipCode": 30016,
      "latitude": 33.514565,
      "longitude": -83.862612,
      "city": "Covington",
      "state": "GA",
      "county": "Newton"
  },
  {
      "zipCode": 30017,
      "latitude": 33.916922,
      "longitude": -84.040973,
      "city": "Grayson",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30018,
      "latitude": 33.717989,
      "longitude": -83.801451,
      "city": "Jersey",
      "state": "GA",
      "county": "Walton"
  },
  {
      "zipCode": 30019,
      "latitude": 33.988309,
      "longitude": -83.879494,
      "city": "Dacula",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30021,
      "latitude": 33.810953,
      "longitude": -84.23797,
      "city": "Clarkston",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30022,
      "latitude": 34.02679,
      "longitude": -84.242235,
      "city": "Alpharetta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30023,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Alpharetta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30024,
      "latitude": 34.042479,
      "longitude": -84.026171,
      "city": "Suwanee",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30025,
      "latitude": 33.679092,
      "longitude": -83.683477,
      "city": "Social Circle",
      "state": "GA",
      "county": "Walton"
  },
  {
      "zipCode": 30026,
      "latitude": 33.962979,
      "longitude": -84.092267,
      "city": "North Metro",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30028,
      "latitude": 34.193041,
      "longitude": -84.092588,
      "city": "Cumming",
      "state": "GA",
      "county": "Forsyth"
  },
  {
      "zipCode": 30029,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "North Metro",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30030,
      "latitude": 33.772554,
      "longitude": -84.291723,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30031,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30032,
      "latitude": 33.749655,
      "longitude": -84.272871,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30033,
      "latitude": 33.816452,
      "longitude": -84.285772,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30034,
      "latitude": 33.690757,
      "longitude": -84.251171,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30035,
      "latitude": 33.732056,
      "longitude": -84.200919,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30036,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30037,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Decatur",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30038,
      "latitude": 33.667509,
      "longitude": -84.144816,
      "city": "Lithonia",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30039,
      "latitude": 33.817803,
      "longitude": -84.022911,
      "city": "Snellville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30040,
      "latitude": 34.232095,
      "longitude": -84.157975,
      "city": "Cumming",
      "state": "GA",
      "county": "Forsyth"
  },
  {
      "zipCode": 30041,
      "latitude": 34.203659,
      "longitude": -84.103108,
      "city": "Cumming",
      "state": "GA",
      "county": "Forsyth"
  },
  {
      "zipCode": 30042,
      "latitude": 33.929464,
      "longitude": -84.103226,
      "city": "Lawrenceville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30043,
      "latitude": 34.00309,
      "longitude": -84.012608,
      "city": "Lawrenceville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30044,
      "latitude": 33.941847,
      "longitude": -84.070567,
      "city": "Lawrenceville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30045,
      "latitude": 33.936729,
      "longitude": -83.957347,
      "city": "Lawrenceville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30046,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "Lawrenceville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30047,
      "latitude": 33.865587,
      "longitude": -84.072463,
      "city": "Lilburn",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30048,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "Lilburn",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30049,
      "latitude": 34.007758,
      "longitude": -93.988015,
      "city": "Lawrenceville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30052,
      "latitude": 33.876889,
      "longitude": -83.896781,
      "city": "Loganville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30054,
      "latitude": 33.670567,
      "longitude": -83.874013,
      "city": "Oxford",
      "state": "GA",
      "county": "Newton"
  },
  {
      "zipCode": 30055,
      "latitude": 33.431362,
      "longitude": -83.790756,
      "city": "Mansfield",
      "state": "GA",
      "county": "Jasper"
  },
  {
      "zipCode": 30056,
      "latitude": 33.514898,
      "longitude": -83.70718,
      "city": "Newborn",
      "state": "GA",
      "county": "Newton"
  },
  {
      "zipCode": 30058,
      "latitude": 33.735607,
      "longitude": -84.100915,
      "city": "Lithonia",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30060,
      "latitude": 33.938206,
      "longitude": -84.540333,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30061,
      "latitude": 33.932842,
      "longitude": -84.556004,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30062,
      "latitude": 33.982269,
      "longitude": -84.48803,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30063,
      "latitude": 33.965294,
      "longitude": -84.511209,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30064,
      "latitude": 33.923652,
      "longitude": -84.629241,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30065,
      "latitude": 33.912473,
      "longitude": -84.557181,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30066,
      "latitude": 34.024849,
      "longitude": -84.498365,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30067,
      "latitude": 33.932551,
      "longitude": -84.462379,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30068,
      "latitude": 33.970894,
      "longitude": -84.445734,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30069,
      "latitude": 33.912473,
      "longitude": -84.557181,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30070,
      "latitude": 33.571234,
      "longitude": -83.895063,
      "city": "Porterdale",
      "state": "GA",
      "county": "Newton"
  },
  {
      "zipCode": 30071,
      "latitude": 33.944461,
      "longitude": -84.210969,
      "city": "Norcross",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30072,
      "latitude": 33.790606,
      "longitude": -84.205269,
      "city": "Pine Lake",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30074,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Redan",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30075,
      "latitude": 34.055198,
      "longitude": -84.370475,
      "city": "Roswell",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30076,
      "latitude": 34.027783,
      "longitude": -84.320162,
      "city": "Roswell",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30077,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Roswell",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30078,
      "latitude": 33.863454,
      "longitude": -84.008066,
      "city": "Snellville",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30079,
      "latitude": 33.793491,
      "longitude": -84.258421,
      "city": "Scottdale",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30080,
      "latitude": 33.864604,
      "longitude": -84.556181,
      "city": "Smyrna",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30081,
      "latitude": 33.858836,
      "longitude": -84.71062,
      "city": "Smyrna",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30082,
      "latitude": 33.856754,
      "longitude": -84.534496,
      "city": "Smyrna",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30083,
      "latitude": 33.786405,
      "longitude": -84.203419,
      "city": "Stone Mountain",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30084,
      "latitude": 33.853945,
      "longitude": -84.215369,
      "city": "Tucker",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30085,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Tucker",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30086,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Stone Mountain",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30087,
      "latitude": 33.810475,
      "longitude": -84.136145,
      "city": "Stone Mountain",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30088,
      "latitude": 33.761106,
      "longitude": -84.176618,
      "city": "Stone Mountain",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30090,
      "latitude": 33.952451,
      "longitude": -84.547082,
      "city": "Marietta",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30091,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "Norcross",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30092,
      "latitude": 33.910725,
      "longitude": -84.138468,
      "city": "Norcross",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30093,
      "latitude": 33.932173,
      "longitude": -84.181881,
      "city": "Norcross",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30094,
      "latitude": 33.611119,
      "longitude": -84.068288,
      "city": "Conyers",
      "state": "GA",
      "county": "Rockdale"
  },
  {
      "zipCode": 30095,
      "latitude": 34.025598,
      "longitude": -84.13045,
      "city": "Duluth",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30096,
      "latitude": 33.984534,
      "longitude": -84.152915,
      "city": "Duluth",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30097,
      "latitude": 34.006019,
      "longitude": -84.144674,
      "city": "Duluth",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30098,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "Duluth",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30099,
      "latitude": 33.959535,
      "longitude": -84.105011,
      "city": "Duluth",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30101,
      "latitude": 34.023398,
      "longitude": -84.673784,
      "city": "Acworth",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30102,
      "latitude": 34.07068,
      "longitude": -84.589406,
      "city": "Acworth",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30103,
      "latitude": 34.296339,
      "longitude": -84.909216,
      "city": "Adairsville",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30104,
      "latitude": 34.053167,
      "longitude": -85.079865,
      "city": "Aragon",
      "state": "GA",
      "county": "Polk"
  },
  {
      "zipCode": 30105,
      "latitude": 34.479253,
      "longitude": -85.149208,
      "city": "Armuchee",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30106,
      "latitude": 33.836897,
      "longitude": -84.630729,
      "city": "Austell",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30107,
      "latitude": 34.30116,
      "longitude": -84.442937,
      "city": "Ball Ground",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30108,
      "latitude": 33.587706,
      "longitude": -85.123883,
      "city": "Bowdon",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30109,
      "latitude": 33.653355,
      "longitude": -85.136227,
      "city": "Bowdon Junction",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30110,
      "latitude": 33.767997,
      "longitude": -85.172928,
      "city": "Bremen",
      "state": "GA",
      "county": "Haralson"
  },
  {
      "zipCode": 30111,
      "latitude": 33.912473,
      "longitude": -84.557181,
      "city": "Clarkdale",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30113,
      "latitude": 33.836682,
      "longitude": -85.210444,
      "city": "Buchanan",
      "state": "GA",
      "county": "Haralson"
  },
  {
      "zipCode": 30114,
      "latitude": 34.250547,
      "longitude": -84.49093,
      "city": "Canton",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30115,
      "latitude": 34.199345,
      "longitude": -84.419935,
      "city": "Canton",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30116,
      "latitude": 33.604458,
      "longitude": -85.04987,
      "city": "Carrollton",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30117,
      "latitude": 33.566767,
      "longitude": -85.078863,
      "city": "Carrollton",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30118,
      "latitude": 33.571176,
      "longitude": -85.09612,
      "city": "Carrollton",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30119,
      "latitude": 33.618931,
      "longitude": -85.073582,
      "city": "Carrollton",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30120,
      "latitude": 34.200787,
      "longitude": -84.847108,
      "city": "Cartersville",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30121,
      "latitude": 34.20787,
      "longitude": -84.76727,
      "city": "Cartersville",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30122,
      "latitude": 33.765457,
      "longitude": -84.646946,
      "city": "Lithia Springs",
      "state": "GA",
      "county": "Douglas"
  },
  {
      "zipCode": 30123,
      "latitude": 34.244179,
      "longitude": -84.845684,
      "city": "Cassville",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30124,
      "latitude": 34.16159,
      "longitude": -85.33281,
      "city": "Cave Spring",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30125,
      "latitude": 33.998841,
      "longitude": -85.239429,
      "city": "Cedartown",
      "state": "GA",
      "county": "Polk"
  },
  {
      "zipCode": 30126,
      "latitude": 33.833161,
      "longitude": -84.603102,
      "city": "Mableton",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30127,
      "latitude": 33.91351,
      "longitude": -84.685885,
      "city": "Powder Springs",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30129,
      "latitude": 34.333308,
      "longitude": -85.233721,
      "city": "Coosa",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30132,
      "latitude": 33.935396,
      "longitude": -84.866654,
      "city": "Dallas",
      "state": "GA",
      "county": "Paulding"
  },
  {
      "zipCode": 30133,
      "latitude": 33.68966,
      "longitude": -84.744595,
      "city": "Douglasville",
      "state": "GA",
      "county": "Douglas"
  },
  {
      "zipCode": 30134,
      "latitude": 33.759543,
      "longitude": -84.777337,
      "city": "Douglasville",
      "state": "GA",
      "county": "Douglas"
  },
  {
      "zipCode": 30135,
      "latitude": 33.667491,
      "longitude": -84.750615,
      "city": "Douglasville",
      "state": "GA",
      "county": "Douglas"
  },
  {
      "zipCode": 30137,
      "latitude": 34.118352,
      "longitude": -84.762315,
      "city": "Emerson",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30138,
      "latitude": 33.99994,
      "longitude": -85.172297,
      "city": "Esom Hill",
      "state": "GA",
      "county": "Polk"
  },
  {
      "zipCode": 30139,
      "latitude": 34.462493,
      "longitude": -84.743027,
      "city": "Fairmount",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30140,
      "latitude": 33.88232,
      "longitude": -85.234946,
      "city": "Felton",
      "state": "GA",
      "county": "Haralson"
  },
  {
      "zipCode": 30141,
      "latitude": 33.879416,
      "longitude": -84.844274,
      "city": "Hiram",
      "state": "GA",
      "county": "Paulding"
  },
  {
      "zipCode": 30142,
      "latitude": 34.242944,
      "longitude": -84.458326,
      "city": "Holly Springs",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30143,
      "latitude": 34.469042,
      "longitude": -84.428243,
      "city": "Jasper",
      "state": "GA",
      "county": "Pickens"
  },
  {
      "zipCode": 30144,
      "latitude": 34.03384,
      "longitude": -84.597433,
      "city": "Kennesaw",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30145,
      "latitude": 34.233908,
      "longitude": -84.962055,
      "city": "Kingston",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30146,
      "latitude": 34.242944,
      "longitude": -84.458326,
      "city": "Lebanon",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30147,
      "latitude": 34.152211,
      "longitude": -85.220364,
      "city": "Lindale",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30148,
      "latitude": 34.439829,
      "longitude": -84.302964,
      "city": "Marble Hill",
      "state": "GA",
      "county": "Pickens"
  },
  {
      "zipCode": 30149,
      "latitude": 34.280355,
      "longitude": -85.182085,
      "city": "Mount Berry",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30150,
      "latitude": 33.643002,
      "longitude": -85.181782,
      "city": "Mount Zion",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30151,
      "latitude": 34.242944,
      "longitude": -84.458326,
      "city": "Nelson",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30152,
      "latitude": 33.995132,
      "longitude": -84.654385,
      "city": "Kennesaw",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30153,
      "latitude": 33.993766,
      "longitude": -85.091511,
      "city": "Rockmart",
      "state": "GA",
      "county": "Polk"
  },
  {
      "zipCode": 30154,
      "latitude": 33.68966,
      "longitude": -84.744595,
      "city": "Douglasville",
      "state": "GA",
      "county": "Douglas"
  },
  {
      "zipCode": 30156,
      "latitude": 34.019424,
      "longitude": -84.609051,
      "city": "Kennesaw",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30157,
      "latitude": 33.90448,
      "longitude": -84.862139,
      "city": "Dallas",
      "state": "GA",
      "county": "Paulding"
  },
  {
      "zipCode": 30160,
      "latitude": 34.005309,
      "longitude": -84.575739,
      "city": "Kennesaw",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30161,
      "latitude": 34.262335,
      "longitude": -85.22089,
      "city": "Rome",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30162,
      "latitude": 34.290508,
      "longitude": -85.213817,
      "city": "Rome",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30163,
      "latitude": 34.333308,
      "longitude": -85.233721,
      "city": "Rome",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30164,
      "latitude": 34.333308,
      "longitude": -85.233721,
      "city": "Rome",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30165,
      "latitude": 34.315817,
      "longitude": -85.272949,
      "city": "Rome",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30168,
      "latitude": 33.783756,
      "longitude": -84.595232,
      "city": "Austell",
      "state": "GA",
      "county": "Cobb"
  },
  {
      "zipCode": 30170,
      "latitude": 33.524163,
      "longitude": -85.165295,
      "city": "Roopville",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30171,
      "latitude": 34.337901,
      "longitude": -84.737644,
      "city": "Rydal",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30172,
      "latitude": 34.333308,
      "longitude": -85.233721,
      "city": "Shannon",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30173,
      "latitude": 34.145452,
      "longitude": -85.117749,
      "city": "Silver Creek",
      "state": "GA",
      "county": "Floyd"
  },
  {
      "zipCode": 30175,
      "latitude": 34.515653,
      "longitude": -84.523407,
      "city": "Talking Rock",
      "state": "GA",
      "county": "Pickens"
  },
  {
      "zipCode": 30176,
      "latitude": 33.770057,
      "longitude": -85.261585,
      "city": "Tallapoosa",
      "state": "GA",
      "county": "Haralson"
  },
  {
      "zipCode": 30177,
      "latitude": 34.402724,
      "longitude": -84.378511,
      "city": "Tate",
      "state": "GA",
      "county": "Pickens"
  },
  {
      "zipCode": 30178,
      "latitude": 34.129573,
      "longitude": -84.973364,
      "city": "Taylorsville",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30179,
      "latitude": 33.73746,
      "longitude": -85.026107,
      "city": "Temple",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30180,
      "latitude": 33.705546,
      "longitude": -84.975668,
      "city": "Villa Rica",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30182,
      "latitude": 33.680148,
      "longitude": -85.251297,
      "city": "Waco",
      "state": "GA",
      "county": "Haralson"
  },
  {
      "zipCode": 30183,
      "latitude": 34.338605,
      "longitude": -84.577601,
      "city": "Waleska",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30184,
      "latitude": 34.264331,
      "longitude": -84.753929,
      "city": "White",
      "state": "GA",
      "county": "Bartow"
  },
  {
      "zipCode": 30185,
      "latitude": 33.509001,
      "longitude": -84.910211,
      "city": "Whitesburg",
      "state": "GA",
      "county": "Carroll"
  },
  {
      "zipCode": 30187,
      "latitude": 33.659494,
      "longitude": -84.778163,
      "city": "Winston",
      "state": "GA",
      "county": "Douglas"
  },
  {
      "zipCode": 30188,
      "latitude": 34.127398,
      "longitude": -84.481787,
      "city": "Woodstock",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30189,
      "latitude": 34.147985,
      "longitude": -84.47725,
      "city": "Woodstock",
      "state": "GA",
      "county": "Cherokee"
  },
  {
      "zipCode": 30204,
      "latitude": 33.07577,
      "longitude": -84.145706,
      "city": "Barnesville",
      "state": "GA",
      "county": "Lamar"
  },
  {
      "zipCode": 30205,
      "latitude": 33.30009,
      "longitude": -84.453196,
      "city": "Brooks",
      "state": "GA",
      "county": "Fayette"
  },
  {
      "zipCode": 30206,
      "latitude": 33.104729,
      "longitude": -84.456917,
      "city": "Concord",
      "state": "GA",
      "county": "Pike"
  },
  {
      "zipCode": 30212,
      "latitude": 33.278205,
      "longitude": -84.288803,
      "city": "Experiment",
      "state": "GA",
      "county": "Spalding"
  },
  {
      "zipCode": 30213,
      "latitude": 33.591462,
      "longitude": -84.608907,
      "city": "Fairburn",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30214,
      "latitude": 33.467941,
      "longitude": -84.480626,
      "city": "Fayetteville",
      "state": "GA",
      "county": "Fayette"
  },
  {
      "zipCode": 30215,
      "latitude": 33.394307,
      "longitude": -84.473778,
      "city": "Fayetteville",
      "state": "GA",
      "county": "Fayette"
  },
  {
      "zipCode": 30216,
      "latitude": 33.237156,
      "longitude": -83.898277,
      "city": "Flovilla",
      "state": "GA",
      "county": "Butts"
  },
  {
      "zipCode": 30217,
      "latitude": 33.278005,
      "longitude": -85.134045,
      "city": "Franklin",
      "state": "GA",
      "county": "Heard"
  },
  {
      "zipCode": 30218,
      "latitude": 33.06913,
      "longitude": -84.613189,
      "city": "Gay",
      "state": "GA",
      "county": "Meriwether"
  },
  {
      "zipCode": 30219,
      "latitude": 33.157214,
      "longitude": -85.170588,
      "city": "Glenn",
      "state": "GA",
      "county": "Heard"
  },
  {
      "zipCode": 30220,
      "latitude": 33.275619,
      "longitude": -84.741266,
      "city": "Grantville",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30222,
      "latitude": 33.046754,
      "longitude": -84.740188,
      "city": "Greenville",
      "state": "GA",
      "county": "Meriwether"
  },
  {
      "zipCode": 30223,
      "latitude": 33.265854,
      "longitude": -84.289552,
      "city": "Griffin",
      "state": "GA",
      "county": "Spalding"
  },
  {
      "zipCode": 30224,
      "latitude": 33.240386,
      "longitude": -84.273402,
      "city": "Griffin",
      "state": "GA",
      "county": "Spalding"
  },
  {
      "zipCode": 30228,
      "latitude": 33.408112,
      "longitude": -84.273764,
      "city": "Hampton",
      "state": "GA",
      "county": "Henry"
  },
  {
      "zipCode": 30229,
      "latitude": 33.232156,
      "longitude": -84.56854,
      "city": "Haralson",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30230,
      "latitude": 33.144151,
      "longitude": -84.960566,
      "city": "Hogansville",
      "state": "GA",
      "county": "Troup"
  },
  {
      "zipCode": 30232,
      "latitude": 33.403811,
      "longitude": -84.504403,
      "city": "Inman",
      "state": "GA",
      "county": "Fayette"
  },
  {
      "zipCode": 30233,
      "latitude": 33.319863,
      "longitude": -83.981112,
      "city": "Jackson",
      "state": "GA",
      "county": "Butts"
  },
  {
      "zipCode": 30234,
      "latitude": 33.322351,
      "longitude": -84.028744,
      "city": "Jenkinsburg",
      "state": "GA",
      "county": "Butts"
  },
  {
      "zipCode": 30236,
      "latitude": 33.518505,
      "longitude": -84.337523,
      "city": "Jonesboro",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30237,
      "latitude": 33.500697,
      "longitude": -84.351273,
      "city": "Jonesboro",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30238,
      "latitude": 33.494387,
      "longitude": -84.379724,
      "city": "Jonesboro",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30240,
      "latitude": 33.024347,
      "longitude": -85.073906,
      "city": "Lagrange",
      "state": "GA",
      "county": "Troup"
  },
  {
      "zipCode": 30241,
      "latitude": 33.024935,
      "longitude": -84.957664,
      "city": "Lagrange",
      "state": "GA",
      "county": "Troup"
  },
  {
      "zipCode": 30248,
      "latitude": 33.372111,
      "longitude": -84.115645,
      "city": "Locust Grove",
      "state": "GA",
      "county": "Henry"
  },
  {
      "zipCode": 30250,
      "latitude": 33.442909,
      "longitude": -84.313582,
      "city": "Lovejoy",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30251,
      "latitude": 33.179184,
      "longitude": -84.699455,
      "city": "Luthersville",
      "state": "GA",
      "county": "Meriwether"
  },
  {
      "zipCode": 30252,
      "latitude": 33.476762,
      "longitude": -84.055005,
      "city": "Mcdonough",
      "state": "GA",
      "county": "Henry"
  },
  {
      "zipCode": 30253,
      "latitude": 33.451043,
      "longitude": -84.154412,
      "city": "Mcdonough",
      "state": "GA",
      "county": "Henry"
  },
  {
      "zipCode": 30256,
      "latitude": 33.039123,
      "longitude": -84.377639,
      "city": "Meansville",
      "state": "GA",
      "county": "Pike"
  },
  {
      "zipCode": 30257,
      "latitude": 33.1365,
      "longitude": -84.148695,
      "city": "Milner",
      "state": "GA",
      "county": "Lamar"
  },
  {
      "zipCode": 30258,
      "latitude": 33.022288,
      "longitude": -84.436925,
      "city": "Molena",
      "state": "GA",
      "county": "Pike"
  },
  {
      "zipCode": 30259,
      "latitude": 33.276279,
      "longitude": -84.738721,
      "city": "Moreland",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30260,
      "latitude": 33.58016,
      "longitude": -84.326757,
      "city": "Morrow",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30261,
      "latitude": 33.04567,
      "longitude": -85.048995,
      "city": "Lagrange",
      "state": "GA",
      "county": "Troup"
  },
  {
      "zipCode": 30263,
      "latitude": 33.369595,
      "longitude": -84.819397,
      "city": "Newnan",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30264,
      "latitude": 33.361003,
      "longitude": -84.814173,
      "city": "Newnan",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30265,
      "latitude": 33.415987,
      "longitude": -84.705681,
      "city": "Newnan",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30266,
      "latitude": 33.265656,
      "longitude": -84.298964,
      "city": "Orchard Hill",
      "state": "GA",
      "county": "Spalding"
  },
  {
      "zipCode": 30268,
      "latitude": 33.559191,
      "longitude": -84.710082,
      "city": "Palmetto",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30269,
      "latitude": 33.395759,
      "longitude": -84.568173,
      "city": "Peachtree City",
      "state": "GA",
      "county": "Fayette"
  },
  {
      "zipCode": 30271,
      "latitude": 33.351401,
      "longitude": -84.756109,
      "city": "Newnan",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30272,
      "latitude": 33.625894,
      "longitude": -84.516327,
      "city": "Red Oak",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30273,
      "latitude": 33.577049,
      "longitude": -84.272559,
      "city": "Rex",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30274,
      "latitude": 33.555536,
      "longitude": -84.397997,
      "city": "Riverdale",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30275,
      "latitude": 33.42985,
      "longitude": -84.874406,
      "city": "Sargent",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30276,
      "latitude": 33.285373,
      "longitude": -84.602516,
      "city": "Senoia",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30277,
      "latitude": 33.363069,
      "longitude": -84.659393,
      "city": "Sharpsburg",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30281,
      "latitude": 33.549947,
      "longitude": -84.207969,
      "city": "Stockbridge",
      "state": "GA",
      "county": "Henry"
  },
  {
      "zipCode": 30284,
      "latitude": 33.345253,
      "longitude": -84.289814,
      "city": "Sunny Side",
      "state": "GA",
      "county": "Spalding"
  },
  {
      "zipCode": 30285,
      "latitude": 32.965021,
      "longitude": -84.219349,
      "city": "The Rock",
      "state": "GA",
      "county": "Upson"
  },
  {
      "zipCode": 30286,
      "latitude": 32.884509,
      "longitude": -84.336486,
      "city": "Thomaston",
      "state": "GA",
      "county": "Upson"
  },
  {
      "zipCode": 30287,
      "latitude": 33.500697,
      "longitude": -84.351273,
      "city": "Morrow",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30288,
      "latitude": 33.636858,
      "longitude": -84.337139,
      "city": "Conley",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30289,
      "latitude": 33.32603,
      "longitude": -84.637108,
      "city": "Turin",
      "state": "GA",
      "county": "Coweta"
  },
  {
      "zipCode": 30290,
      "latitude": 33.460036,
      "longitude": -84.585773,
      "city": "Tyrone",
      "state": "GA",
      "county": "Fayette"
  },
  {
      "zipCode": 30291,
      "latitude": 33.573098,
      "longitude": -84.551011,
      "city": "Union City",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30292,
      "latitude": 33.117012,
      "longitude": -84.399149,
      "city": "Williamson",
      "state": "GA",
      "county": "Pike"
  },
  {
      "zipCode": 30293,
      "latitude": 32.968248,
      "longitude": -84.625409,
      "city": "Woodbury",
      "state": "GA",
      "county": "Meriwether"
  },
  {
      "zipCode": 30294,
      "latitude": 33.616559,
      "longitude": -84.293942,
      "city": "Ellenwood",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30295,
      "latitude": 33.077371,
      "longitude": -84.321736,
      "city": "Zebulon",
      "state": "GA",
      "county": "Pike"
  },
  {
      "zipCode": 30296,
      "latitude": 33.558361,
      "longitude": -84.434776,
      "city": "Riverdale",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30297,
      "latitude": 33.611509,
      "longitude": -84.374496,
      "city": "Forest Park",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30298,
      "latitude": 33.500697,
      "longitude": -84.351273,
      "city": "Forest Park",
      "state": "GA",
      "county": "Clayton"
  },
  {
      "zipCode": 30301,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30302,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30303,
      "latitude": 33.752456,
      "longitude": -84.392026,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30304,
      "latitude": 33.848196,
      "longitude": -84.429296,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30305,
      "latitude": 33.834989,
      "longitude": -84.386826,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30306,
      "latitude": 33.787804,
      "longitude": -84.359775,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30307,
      "latitude": 33.762855,
      "longitude": -84.357375,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30308,
      "latitude": 33.794055,
      "longitude": -84.377326,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30309,
      "latitude": 33.797854,
      "longitude": -84.387726,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30310,
      "latitude": 33.718257,
      "longitude": -84.430877,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30311,
      "latitude": 33.724907,
      "longitude": -84.468329,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30312,
      "latitude": 33.829743,
      "longitude": -84.381529,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30313,
      "latitude": 33.760505,
      "longitude": -84.399626,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30314,
      "latitude": 33.757806,
      "longitude": -84.433649,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30315,
      "latitude": 33.697257,
      "longitude": -84.383725,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30316,
      "latitude": 33.726906,
      "longitude": -84.358525,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30317,
      "latitude": 33.753555,
      "longitude": -84.351125,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30318,
      "latitude": 33.792005,
      "longitude": -84.442778,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30319,
      "latitude": 33.876752,
      "longitude": -84.329124,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30320,
      "latitude": 33.656824,
      "longitude": -84.423648,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30321,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30322,
      "latitude": 33.795204,
      "longitude": -84.324824,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30324,
      "latitude": 33.820104,
      "longitude": -84.366475,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30325,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30326,
      "latitude": 33.849615,
      "longitude": -84.360881,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30327,
      "latitude": 33.863953,
      "longitude": -84.422028,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30328,
      "latitude": 33.933543,
      "longitude": -84.395777,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30329,
      "latitude": 33.826553,
      "longitude": -84.324874,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30330,
      "latitude": 33.707107,
      "longitude": -84.432127,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30331,
      "latitude": 33.724275,
      "longitude": -84.578581,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30332,
      "latitude": 33.776305,
      "longitude": -84.397976,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30333,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30334,
      "latitude": 33.702657,
      "longitude": -84.439127,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30336,
      "latitude": 33.740581,
      "longitude": -84.554542,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30337,
      "latitude": 33.642809,
      "longitude": -84.461778,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30338,
      "latitude": 33.96685,
      "longitude": -84.324916,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30339,
      "latitude": 33.906202,
      "longitude": -84.433678,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30340,
      "latitude": 33.893201,
      "longitude": -84.253871,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30341,
      "latitude": 33.890102,
      "longitude": -84.278222,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30342,
      "latitude": 33.881052,
      "longitude": -84.379476,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30343,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30344,
      "latitude": 33.691858,
      "longitude": -84.448027,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30345,
      "latitude": 33.845603,
      "longitude": -84.281422,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30346,
      "latitude": 33.924401,
      "longitude": -84.337557,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30347,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30348,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30349,
      "latitude": 33.61806,
      "longitude": -84.550147,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30350,
      "latitude": 33.9632,
      "longitude": -84.314224,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30353,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30354,
      "latitude": 33.667508,
      "longitude": -84.389616,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30355,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30356,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30357,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30358,
      "latitude": 33.998151,
      "longitude": -84.34112,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30359,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30360,
      "latitude": 33.931069,
      "longitude": -84.277772,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30361,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30362,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30364,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30366,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 30368,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30369,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30370,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30371,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30374,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30375,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30376,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30377,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30378,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30379,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30380,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30384,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30385,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30386,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30387,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30388,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30389,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30390,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30392,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30394,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30396,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30398,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30399,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 30401,
      "latitude": 32.569871,
      "longitude": -82.346181,
      "city": "Swainsboro",
      "state": "GA",
      "county": "Emanuel"
  },
  {
      "zipCode": 30410,
      "latitude": 32.17966,
      "longitude": -82.516557,
      "city": "Ailey",
      "state": "GA",
      "county": "Montgomery"
  },
  {
      "zipCode": 30411,
      "latitude": 32.143021,
      "longitude": -82.82677,
      "city": "Alamo",
      "state": "GA",
      "county": "Wheeler"
  },
  {
      "zipCode": 30412,
      "latitude": 32.077458,
      "longitude": -82.482488,
      "city": "Alston",
      "state": "GA",
      "county": "Montgomery"
  },
  {
      "zipCode": 30413,
      "latitude": 32.88914,
      "longitude": -82.466644,
      "city": "Bartow",
      "state": "GA",
      "county": "Jefferson"
  },
  {
      "zipCode": 30414,
      "latitude": 32.163392,
      "longitude": -81.87191,
      "city": "Bellville",
      "state": "GA",
      "county": "Evans"
  },
  {
      "zipCode": 30415,
      "latitude": 32.352307,
      "longitude": -81.6571,
      "city": "Brooklet",
      "state": "GA",
      "county": "Bulloch"
  },
  {
      "zipCode": 30417,
      "latitude": 32.160611,
      "longitude": -81.923832,
      "city": "Claxton",
      "state": "GA",
      "county": "Evans"
  },
  {
      "zipCode": 30420,
      "latitude": 32.10597,
      "longitude": -82.03389,
      "city": "Cobbtown",
      "state": "GA",
      "county": "Tattnall"
  },
  {
      "zipCode": 30421,
      "latitude": 32.058597,
      "longitude": -82.027358,
      "city": "Collins",
      "state": "GA",
      "county": "Tattnall"
  },
  {
      "zipCode": 30423,
      "latitude": 32.163392,
      "longitude": -81.87191,
      "city": "Daisy",
      "state": "GA",
      "county": "Evans"
  },
  {
      "zipCode": 30424,
      "latitude": 32.767634,
      "longitude": -81.629062,
      "city": "Dover",
      "state": "GA",
      "county": "Screven"
  },
  {
      "zipCode": 30425,
      "latitude": 32.654937,
      "longitude": -82.100208,
      "city": "Garfield",
      "state": "GA",
      "county": "Emanuel"
  },
  {
      "zipCode": 30426,
      "latitude": 33.05387,
      "longitude": -81.684489,
      "city": "Girard",
      "state": "GA",
      "county": "Burke"
  },
  {
      "zipCode": 30427,
      "latitude": 32.066649,
      "longitude": -82.012344,
      "city": "Glennville",
      "state": "GA",
      "county": "Tattnall"
  },
  {
      "zipCode": 30428,
      "latitude": 32.118831,
      "longitude": -82.724555,
      "city": "Glenwood",
      "state": "GA",
      "county": "Wheeler"
  },
  {
      "zipCode": 30429,
      "latitude": 32.152808,
      "longitude": -81.925865,
      "city": "Hagan",
      "state": "GA",
      "county": "Evans"
  },
  {
      "zipCode": 30434,
      "latitude": 33.028798,
      "longitude": -82.437965,
      "city": "Louisville",
      "state": "GA",
      "county": "Jefferson"
  },
  {
      "zipCode": 30436,
      "latitude": 32.129147,
      "longitude": -82.330546,
      "city": "Lyons",
      "state": "GA",
      "county": "Toombs"
  },
  {
      "zipCode": 30438,
      "latitude": 32.050694,
      "longitude": -81.998348,
      "city": "Manassas",
      "state": "GA",
      "county": "Tattnall"
  },
  {
      "zipCode": 30439,
      "latitude": 32.39938,
      "longitude": -82.048201,
      "city": "Metter",
      "state": "GA",
      "county": "Candler"
  },
  {
      "zipCode": 30441,
      "latitude": 32.926431,
      "longitude": -82.172337,
      "city": "Midville",
      "state": "GA",
      "county": "Burke"
  },
  {
      "zipCode": 30442,
      "latitude": 32.779376,
      "longitude": -81.973904,
      "city": "Millen",
      "state": "GA",
      "county": "Jenkins"
  },
  {
      "zipCode": 30445,
      "latitude": 32.183157,
      "longitude": -82.566698,
      "city": "Mount Vernon",
      "state": "GA",
      "county": "Montgomery"
  },
  {
      "zipCode": 30446,
      "latitude": 32.580957,
      "longitude": -81.482625,
      "city": "Newington",
      "state": "GA",
      "county": "Screven"
  },
  {
      "zipCode": 30447,
      "latitude": 32.504092,
      "longitude": -82.484754,
      "city": "Norristown",
      "state": "GA",
      "county": "Emanuel"
  },
  {
      "zipCode": 30448,
      "latitude": 32.475892,
      "longitude": -82.372622,
      "city": "Nunez",
      "state": "GA",
      "county": "Emanuel"
  },
  {
      "zipCode": 30449,
      "latitude": 32.528104,
      "longitude": -81.533198,
      "city": "Oliver",
      "state": "GA",
      "county": "Screven"
  },
  {
      "zipCode": 30450,
      "latitude": 32.552055,
      "longitude": -81.924195,
      "city": "Portal",
      "state": "GA",
      "county": "Bulloch"
  },
  {
      "zipCode": 30451,
      "latitude": 32.414349,
      "longitude": -82.086658,
      "city": "Pulaski",
      "state": "GA",
      "county": "Candler"
  },
  {
      "zipCode": 30452,
      "latitude": 32.398006,
      "longitude": -81.889152,
      "city": "Register",
      "state": "GA",
      "county": "Bulloch"
  },
  {
      "zipCode": 30453,
      "latitude": 32.031081,
      "longitude": -82.146011,
      "city": "Reidsville",
      "state": "GA",
      "county": "Tattnall"
  },
  {
      "zipCode": 30454,
      "latitude": 32.50631,
      "longitude": -82.713743,
      "city": "Rockledge",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 30455,
      "latitude": 32.722971,
      "longitude": -81.793305,
      "city": "Rocky Ford",
      "state": "GA",
      "county": "Screven"
  },
  {
      "zipCode": 30456,
      "latitude": 32.980305,
      "longitude": -81.84486,
      "city": "Sardis",
      "state": "GA",
      "county": "Burke"
  },
  {
      "zipCode": 30457,
      "latitude": 32.383145,
      "longitude": -82.557135,
      "city": "Soperton",
      "state": "GA",
      "county": "Treutlen"
  },
  {
      "zipCode": 30458,
      "latitude": 32.425,
      "longitude": -81.784956,
      "city": "Statesboro",
      "state": "GA",
      "county": "Bulloch"
  },
  {
      "zipCode": 30459,
      "latitude": 32.447036,
      "longitude": -81.77772,
      "city": "Statesboro",
      "state": "GA",
      "county": "Bulloch"
  },
  {
      "zipCode": 30460,
      "latitude": 32.41795,
      "longitude": -81.78233,
      "city": "Statesboro",
      "state": "GA",
      "county": "Bulloch"
  },
  {
      "zipCode": 30461,
      "latitude": 32.450032,
      "longitude": -81.715799,
      "city": "Statesboro",
      "state": "GA",
      "county": "Bulloch"
  },
  {
      "zipCode": 30464,
      "latitude": 32.445915,
      "longitude": -82.258728,
      "city": "Stillmore",
      "state": "GA",
      "county": "Emanuel"
  },
  {
      "zipCode": 30467,
      "latitude": 32.743851,
      "longitude": -81.628687,
      "city": "Sylvania",
      "state": "GA",
      "county": "Screven"
  },
  {
      "zipCode": 30470,
      "latitude": 32.188768,
      "longitude": -82.522115,
      "city": "Tarrytown",
      "state": "GA",
      "county": "Montgomery"
  },
  {
      "zipCode": 30471,
      "latitude": 32.54978,
      "longitude": -82.183916,
      "city": "Twin City",
      "state": "GA",
      "county": "Emanuel"
  },
  {
      "zipCode": 30473,
      "latitude": 32.028823,
      "longitude": -82.516246,
      "city": "Uvalda",
      "state": "GA",
      "county": "Montgomery"
  },
  {
      "zipCode": 30474,
      "latitude": 32.198683,
      "longitude": -82.39208,
      "city": "Vidalia",
      "state": "GA",
      "county": "Toombs"
  },
  {
      "zipCode": 30475,
      "latitude": 32.177513,
      "longitude": -82.373889,
      "city": "Vidalia",
      "state": "GA",
      "county": "Toombs"
  },
  {
      "zipCode": 30477,
      "latitude": 32.863678,
      "longitude": -82.407516,
      "city": "Wadley",
      "state": "GA",
      "county": "Jefferson"
  },
  {
      "zipCode": 30499,
      "latitude": 32.050694,
      "longitude": -81.998348,
      "city": "Reidsville",
      "state": "GA",
      "county": "Tattnall"
  },
  {
      "zipCode": 30501,
      "latitude": 34.334398,
      "longitude": -83.837912,
      "city": "Gainesville",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30502,
      "latitude": 34.212993,
      "longitude": -83.794858,
      "city": "Chestnut Mountain",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30503,
      "latitude": 34.345432,
      "longitude": -83.950541,
      "city": "Gainesville",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30504,
      "latitude": 34.241066,
      "longitude": -83.886508,
      "city": "Gainesville",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30506,
      "latitude": 34.336238,
      "longitude": -83.860031,
      "city": "Gainesville",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30507,
      "latitude": 34.257212,
      "longitude": -83.824574,
      "city": "Gainesville",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30510,
      "latitude": 34.50429,
      "longitude": -83.594758,
      "city": "Alto",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30511,
      "latitude": 34.393713,
      "longitude": -83.473886,
      "city": "Baldwin",
      "state": "GA",
      "county": "Banks"
  },
  {
      "zipCode": 30512,
      "latitude": 34.867512,
      "longitude": -83.967636,
      "city": "Blairsville",
      "state": "GA",
      "county": "Union"
  },
  {
      "zipCode": 30513,
      "latitude": 34.831246,
      "longitude": -84.331339,
      "city": "Blue Ridge",
      "state": "GA",
      "county": "Fannin"
  },
  {
      "zipCode": 30514,
      "latitude": 34.903398,
      "longitude": -84.019157,
      "city": "Blairsville",
      "state": "GA",
      "county": "Union"
  },
  {
      "zipCode": 30515,
      "latitude": 33.960353,
      "longitude": -84.037859,
      "city": "Buford",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30516,
      "latitude": 34.383987,
      "longitude": -83.03321,
      "city": "Bowersville",
      "state": "GA",
      "county": "Hart"
  },
  {
      "zipCode": 30517,
      "latitude": 34.109873,
      "longitude": -83.685149,
      "city": "Braselton",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30518,
      "latitude": 34.113712,
      "longitude": -84.033984,
      "city": "Buford",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30519,
      "latitude": 34.079715,
      "longitude": -83.930765,
      "city": "Buford",
      "state": "GA",
      "county": "Gwinnett"
  },
  {
      "zipCode": 30520,
      "latitude": 34.355933,
      "longitude": -83.152947,
      "city": "Canon",
      "state": "GA",
      "county": "Franklin"
  },
  {
      "zipCode": 30521,
      "latitude": 34.363122,
      "longitude": -83.254745,
      "city": "Carnesville",
      "state": "GA",
      "county": "Franklin"
  },
  {
      "zipCode": 30522,
      "latitude": 34.73249,
      "longitude": -84.425035,
      "city": "Cherrylog",
      "state": "GA",
      "county": "Gilmer"
  },
  {
      "zipCode": 30523,
      "latitude": 34.655918,
      "longitude": -83.508274,
      "city": "Clarkesville",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30525,
      "latitude": 34.886868,
      "longitude": -83.375358,
      "city": "Clayton",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30527,
      "latitude": 34.397806,
      "longitude": -83.775657,
      "city": "Clermont",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30528,
      "latitude": 34.610466,
      "longitude": -83.74615,
      "city": "Cleveland",
      "state": "GA",
      "county": "White"
  },
  {
      "zipCode": 30529,
      "latitude": 34.169691,
      "longitude": -83.570671,
      "city": "Commerce",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30530,
      "latitude": 34.168274,
      "longitude": -83.402226,
      "city": "Commerce",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30531,
      "latitude": 34.535213,
      "longitude": -83.574032,
      "city": "Cornelia",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30533,
      "latitude": 34.541371,
      "longitude": -84.024387,
      "city": "Dahlonega",
      "state": "GA",
      "county": "Lumpkin"
  },
  {
      "zipCode": 30534,
      "latitude": 34.453661,
      "longitude": -84.155043,
      "city": "Dawsonville",
      "state": "GA",
      "county": "Dawson"
  },
  {
      "zipCode": 30535,
      "latitude": 34.631639,
      "longitude": -83.569657,
      "city": "Demorest",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30537,
      "latitude": 34.97,
      "longitude": -83.357507,
      "city": "Dillard",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30538,
      "latitude": 34.53512,
      "longitude": -83.258748,
      "city": "Eastanollee",
      "state": "GA",
      "county": "Stephens"
  },
  {
      "zipCode": 30539,
      "latitude": 34.658482,
      "longitude": -84.493207,
      "city": "East Ellijay",
      "state": "GA",
      "county": "Gilmer"
  },
  {
      "zipCode": 30540,
      "latitude": 34.70129,
      "longitude": -84.449007,
      "city": "Ellijay",
      "state": "GA",
      "county": "Gilmer"
  },
  {
      "zipCode": 30541,
      "latitude": 34.92873,
      "longitude": -84.436752,
      "city": "Epworth",
      "state": "GA",
      "county": "Fannin"
  },
  {
      "zipCode": 30542,
      "latitude": 34.282818,
      "longitude": -83.891451,
      "city": "Flowery Branch",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30543,
      "latitude": 34.364396,
      "longitude": -83.735421,
      "city": "Gillsville",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30544,
      "latitude": 34.593092,
      "longitude": -83.562922,
      "city": "Demorest",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30545,
      "latitude": 34.729196,
      "longitude": -83.720937,
      "city": "Helen",
      "state": "GA",
      "county": "White"
  },
  {
      "zipCode": 30546,
      "latitude": 34.906339,
      "longitude": -83.72739,
      "city": "Hiawassee",
      "state": "GA",
      "county": "Towns"
  },
  {
      "zipCode": 30547,
      "latitude": 34.373145,
      "longitude": -83.491925,
      "city": "Homer",
      "state": "GA",
      "county": "Banks"
  },
  {
      "zipCode": 30548,
      "latitude": 34.099031,
      "longitude": -83.71817,
      "city": "Hoschton",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30549,
      "latitude": 34.10601,
      "longitude": -83.589021,
      "city": "Jefferson",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30552,
      "latitude": 34.78992,
      "longitude": -83.443351,
      "city": "Lakemont",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30553,
      "latitude": 34.408031,
      "longitude": -83.144241,
      "city": "Lavonia",
      "state": "GA",
      "county": "Franklin"
  },
  {
      "zipCode": 30554,
      "latitude": 34.41088,
      "longitude": -83.703923,
      "city": "Lula",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30555,
      "latitude": 34.936986,
      "longitude": -84.403709,
      "city": "Mc Caysville",
      "state": "GA",
      "county": "Fannin"
  },
  {
      "zipCode": 30557,
      "latitude": 34.527955,
      "longitude": -83.23208,
      "city": "Martin",
      "state": "GA",
      "county": "Stephens"
  },
  {
      "zipCode": 30558,
      "latitude": 34.329372,
      "longitude": -83.543357,
      "city": "Maysville",
      "state": "GA",
      "county": "Banks"
  },
  {
      "zipCode": 30559,
      "latitude": 34.936697,
      "longitude": -84.268863,
      "city": "Mineral Bluff",
      "state": "GA",
      "county": "Fannin"
  },
  {
      "zipCode": 30560,
      "latitude": 34.86263,
      "longitude": -84.248612,
      "city": "Morganton",
      "state": "GA",
      "county": "Fannin"
  },
  {
      "zipCode": 30562,
      "latitude": 34.914393,
      "longitude": -83.402766,
      "city": "Mountain City",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30563,
      "latitude": 34.541683,
      "longitude": -83.52283,
      "city": "Mount Airy",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30564,
      "latitude": 34.445369,
      "longitude": -83.906516,
      "city": "Murrayville",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30565,
      "latitude": 34.113429,
      "longitude": -83.474576,
      "city": "Nicholson",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30566,
      "latitude": 34.239428,
      "longitude": -83.897612,
      "city": "Oakwood",
      "state": "GA",
      "county": "Hall"
  },
  {
      "zipCode": 30567,
      "latitude": 34.159431,
      "longitude": -83.59706,
      "city": "Pendergrass",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30568,
      "latitude": 34.944609,
      "longitude": -83.381415,
      "city": "Rabun Gap",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30571,
      "latitude": 34.66578,
      "longitude": -83.709564,
      "city": "Sautee Nacoochee",
      "state": "GA",
      "county": "White"
  },
  {
      "zipCode": 30572,
      "latitude": 34.712091,
      "longitude": -84.027629,
      "city": "Suches",
      "state": "GA",
      "county": "Union"
  },
  {
      "zipCode": 30573,
      "latitude": 34.742007,
      "longitude": -83.397716,
      "city": "Tallulah Falls",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30575,
      "latitude": 34.195058,
      "longitude": -83.713188,
      "city": "Talmo",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30576,
      "latitude": 34.856395,
      "longitude": -83.457267,
      "city": "Tiger",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30577,
      "latitude": 34.554103,
      "longitude": -83.288828,
      "city": "Toccoa",
      "state": "GA",
      "county": "Stephens"
  },
  {
      "zipCode": 30580,
      "latitude": 34.676584,
      "longitude": -83.43578,
      "city": "Turnerville",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30581,
      "latitude": 34.804473,
      "longitude": -83.419137,
      "city": "Wiley",
      "state": "GA",
      "county": "Rabun"
  },
  {
      "zipCode": 30582,
      "latitude": 34.934417,
      "longitude": -83.828504,
      "city": "Young Harris",
      "state": "GA",
      "county": "Towns"
  },
  {
      "zipCode": 30596,
      "latitude": 34.629391,
      "longitude": -83.509934,
      "city": "Alto",
      "state": "GA",
      "county": "Habersham"
  },
  {
      "zipCode": 30597,
      "latitude": 34.527671,
      "longitude": -83.980879,
      "city": "Dahlonega",
      "state": "GA",
      "county": "Lumpkin"
  },
  {
      "zipCode": 30598,
      "latitude": 34.59179,
      "longitude": -83.352534,
      "city": "Toccoa Falls",
      "state": "GA",
      "county": "Stephens"
  },
  {
      "zipCode": 30599,
      "latitude": 34.130594,
      "longitude": -83.587419,
      "city": "Commerce",
      "state": "GA",
      "county": "Jackson"
  },
  {
      "zipCode": 30601,
      "latitude": 33.992902,
      "longitude": -83.353697,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30602,
      "latitude": 33.925971,
      "longitude": -83.365029,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30603,
      "latitude": 33.947587,
      "longitude": -83.408897,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30604,
      "latitude": 33.944339,
      "longitude": -83.38908,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30605,
      "latitude": 33.923088,
      "longitude": -83.364038,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30606,
      "latitude": 33.951348,
      "longitude": -83.43064,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30607,
      "latitude": 33.998267,
      "longitude": -83.439569,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30608,
      "latitude": 33.944339,
      "longitude": -83.38908,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30609,
      "latitude": 33.946364,
      "longitude": -83.37743,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30612,
      "latitude": 33.944339,
      "longitude": -83.38908,
      "city": "Athens",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30619,
      "latitude": 33.888662,
      "longitude": -83.222254,
      "city": "Arnoldsville",
      "state": "GA",
      "county": "Oglethorpe"
  },
  {
      "zipCode": 30620,
      "latitude": 33.943652,
      "longitude": -83.721536,
      "city": "Bethlehem",
      "state": "GA",
      "county": "Barrow"
  },
  {
      "zipCode": 30621,
      "latitude": 33.805718,
      "longitude": -83.48171,
      "city": "Bishop",
      "state": "GA",
      "county": "Oconee"
  },
  {
      "zipCode": 30622,
      "latitude": 33.901988,
      "longitude": -83.518452,
      "city": "Bogart",
      "state": "GA",
      "county": "Oconee"
  },
  {
      "zipCode": 30623,
      "latitude": 33.754235,
      "longitude": -83.511055,
      "city": "Bostwick",
      "state": "GA",
      "county": "Morgan"
  },
  {
      "zipCode": 30624,
      "latitude": 34.164131,
      "longitude": -82.957102,
      "city": "Bowman",
      "state": "GA",
      "county": "Elbert"
  },
  {
      "zipCode": 30625,
      "latitude": 33.525614,
      "longitude": -83.365611,
      "city": "Buckhead",
      "state": "GA",
      "county": "Morgan"
  },
  {
      "zipCode": 30627,
      "latitude": 34.05918,
      "longitude": -83.099349,
      "city": "Carlton",
      "state": "GA",
      "county": "Madison"
  },
  {
      "zipCode": 30628,
      "latitude": 34.088156,
      "longitude": -83.202379,
      "city": "Colbert",
      "state": "GA",
      "county": "Madison"
  },
  {
      "zipCode": 30629,
      "latitude": 34.105661,
      "longitude": -83.144463,
      "city": "Comer",
      "state": "GA",
      "county": "Madison"
  },
  {
      "zipCode": 30630,
      "latitude": 33.907589,
      "longitude": -83.164573,
      "city": "Crawford",
      "state": "GA",
      "county": "Oglethorpe"
  },
  {
      "zipCode": 30631,
      "latitude": 33.577007,
      "longitude": -82.858099,
      "city": "Crawfordville",
      "state": "GA",
      "county": "Taliaferro"
  },
  {
      "zipCode": 30633,
      "latitude": 34.152968,
      "longitude": -83.262772,
      "city": "Danielsville",
      "state": "GA",
      "county": "Madison"
  },
  {
      "zipCode": 30634,
      "latitude": 34.185291,
      "longitude": -82.943399,
      "city": "Dewy Rose",
      "state": "GA",
      "county": "Elbert"
  },
  {
      "zipCode": 30635,
      "latitude": 34.129737,
      "longitude": -82.840971,
      "city": "Elberton",
      "state": "GA",
      "county": "Elbert"
  },
  {
      "zipCode": 30638,
      "latitude": 33.755811,
      "longitude": -83.420094,
      "city": "Farmington",
      "state": "GA",
      "county": "Oconee"
  },
  {
      "zipCode": 30639,
      "latitude": 34.274306,
      "longitude": -83.147024,
      "city": "Franklin Springs",
      "state": "GA",
      "county": "Franklin"
  },
  {
      "zipCode": 30641,
      "latitude": 33.778257,
      "longitude": -83.580359,
      "city": "Good Hope",
      "state": "GA",
      "county": "Walton"
  },
  {
      "zipCode": 30642,
      "latitude": 33.560655,
      "longitude": -83.193389,
      "city": "Greensboro",
      "state": "GA",
      "county": "Greene"
  },
  {
      "zipCode": 30643,
      "latitude": 34.361177,
      "longitude": -82.941703,
      "city": "Hartwell",
      "state": "GA",
      "county": "Hart"
  },
  {
      "zipCode": 30645,
      "latitude": 33.801274,
      "longitude": -83.516374,
      "city": "High Shoals",
      "state": "GA",
      "county": "Morgan"
  },
  {
      "zipCode": 30646,
      "latitude": 34.088693,
      "longitude": -83.30911,
      "city": "Hull",
      "state": "GA",
      "county": "Madison"
  },
  {
      "zipCode": 30647,
      "latitude": 34.120239,
      "longitude": -83.288117,
      "city": "Ila",
      "state": "GA",
      "county": "Madison"
  },
  {
      "zipCode": 30648,
      "latitude": 33.85281,
      "longitude": -83.020293,
      "city": "Lexington",
      "state": "GA",
      "county": "Oglethorpe"
  },
  {
      "zipCode": 30650,
      "latitude": 33.591117,
      "longitude": -83.492928,
      "city": "Madison",
      "state": "GA",
      "county": "Morgan"
  },
  {
      "zipCode": 30655,
      "latitude": 33.788322,
      "longitude": -83.701264,
      "city": "Monroe",
      "state": "GA",
      "county": "Walton"
  },
  {
      "zipCode": 30656,
      "latitude": 33.838638,
      "longitude": -83.710359,
      "city": "Monroe",
      "state": "GA",
      "county": "Walton"
  },
  {
      "zipCode": 30660,
      "latitude": 33.763817,
      "longitude": -82.899831,
      "city": "Rayle",
      "state": "GA",
      "county": "Wilkes"
  },
  {
      "zipCode": 30662,
      "latitude": 34.297968,
      "longitude": -83.192787,
      "city": "Royston",
      "state": "GA",
      "county": "Franklin"
  },
  {
      "zipCode": 30663,
      "latitude": 33.61101,
      "longitude": -83.602459,
      "city": "Rutledge",
      "state": "GA",
      "county": "Morgan"
  },
  {
      "zipCode": 30664,
      "latitude": 33.559964,
      "longitude": -82.794766,
      "city": "Sharon",
      "state": "GA",
      "county": "Taliaferro"
  },
  {
      "zipCode": 30665,
      "latitude": 33.561871,
      "longitude": -83.074186,
      "city": "Siloam",
      "state": "GA",
      "county": "Greene"
  },
  {
      "zipCode": 30666,
      "latitude": 33.980323,
      "longitude": -83.590148,
      "city": "Statham",
      "state": "GA",
      "county": "Barrow"
  },
  {
      "zipCode": 30667,
      "latitude": 33.804626,
      "longitude": -83.151661,
      "city": "Stephens",
      "state": "GA",
      "county": "Oglethorpe"
  },
  {
      "zipCode": 30668,
      "latitude": 33.877751,
      "longitude": -82.747023,
      "city": "Tignall",
      "state": "GA",
      "county": "Wilkes"
  },
  {
      "zipCode": 30669,
      "latitude": 33.634342,
      "longitude": -83.122065,
      "city": "Union Point",
      "state": "GA",
      "county": "Greene"
  },
  {
      "zipCode": 30671,
      "latitude": 33.740502,
      "longitude": -83.1706,
      "city": "Maxeys",
      "state": "GA",
      "county": "Oglethorpe"
  },
  {
      "zipCode": 30673,
      "latitude": 33.73149,
      "longitude": -82.710833,
      "city": "Washington",
      "state": "GA",
      "county": "Wilkes"
  },
  {
      "zipCode": 30677,
      "latitude": 33.81673,
      "longitude": -83.43347,
      "city": "Watkinsville",
      "state": "GA",
      "county": "Oconee"
  },
  {
      "zipCode": 30678,
      "latitude": 33.458056,
      "longitude": -83.08023,
      "city": "White Plains",
      "state": "GA",
      "county": "Greene"
  },
  {
      "zipCode": 30680,
      "latitude": 33.987138,
      "longitude": -83.698498,
      "city": "Winder",
      "state": "GA",
      "county": "Barrow"
  },
  {
      "zipCode": 30683,
      "latitude": 33.930735,
      "longitude": -83.292152,
      "city": "Winterville",
      "state": "GA",
      "county": "Clarke"
  },
  {
      "zipCode": 30701,
      "latitude": 34.496921,
      "longitude": -84.887942,
      "city": "Calhoun",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30703,
      "latitude": 34.479066,
      "longitude": -84.762161,
      "city": "Calhoun",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30705,
      "latitude": 34.785543,
      "longitude": -84.771623,
      "city": "Chatsworth",
      "state": "GA",
      "county": "Murray"
  },
  {
      "zipCode": 30707,
      "latitude": 34.76416,
      "longitude": -85.353517,
      "city": "Chickamauga",
      "state": "GA",
      "county": "Walker"
  },
  {
      "zipCode": 30708,
      "latitude": 34.872473,
      "longitude": -84.75688,
      "city": "Cisco",
      "state": "GA",
      "county": "Murray"
  },
  {
      "zipCode": 30710,
      "latitude": 34.867821,
      "longitude": -84.919498,
      "city": "Cohutta",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30711,
      "latitude": 34.887628,
      "longitude": -84.744577,
      "city": "Crandall",
      "state": "GA",
      "county": "Murray"
  },
  {
      "zipCode": 30719,
      "latitude": 34.801861,
      "longitude": -84.989796,
      "city": "Dalton",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30720,
      "latitude": 34.791428,
      "longitude": -84.992771,
      "city": "Dalton",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30721,
      "latitude": 34.788493,
      "longitude": -84.91381,
      "city": "Dalton",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30722,
      "latitude": 34.759522,
      "longitude": -84.951261,
      "city": "Dalton",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30724,
      "latitude": 34.78783,
      "longitude": -84.777863,
      "city": "Eton",
      "state": "GA",
      "county": "Murray"
  },
  {
      "zipCode": 30725,
      "latitude": 34.846843,
      "longitude": -85.238041,
      "city": "Flintstone",
      "state": "GA",
      "county": "Walker"
  },
  {
      "zipCode": 30726,
      "latitude": 34.980249,
      "longitude": -85.140798,
      "city": "Graysville",
      "state": "GA",
      "county": "Catoosa"
  },
  {
      "zipCode": 30728,
      "latitude": 34.697006,
      "longitude": -85.255273,
      "city": "La Fayette",
      "state": "GA",
      "county": "Walker"
  },
  {
      "zipCode": 30730,
      "latitude": 34.392073,
      "longitude": -85.417428,
      "city": "Lyerly",
      "state": "GA",
      "county": "Chattooga"
  },
  {
      "zipCode": 30731,
      "latitude": 34.518597,
      "longitude": -85.414081,
      "city": "Menlo",
      "state": "GA",
      "county": "Chattooga"
  },
  {
      "zipCode": 30732,
      "latitude": 34.569015,
      "longitude": -84.711863,
      "city": "Oakman",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30733,
      "latitude": 34.414048,
      "longitude": -85.052629,
      "city": "Plainville",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30734,
      "latitude": 34.535071,
      "longitude": -84.724186,
      "city": "Ranger",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30735,
      "latitude": 34.583318,
      "longitude": -84.906803,
      "city": "Resaca",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30736,
      "latitude": 34.888709,
      "longitude": -85.118699,
      "city": "Ringgold",
      "state": "GA",
      "county": "Catoosa"
  },
  {
      "zipCode": 30738,
      "latitude": 34.813904,
      "longitude": -85.501868,
      "city": "Rising Fawn",
      "state": "GA",
      "county": "Dade"
  },
  {
      "zipCode": 30739,
      "latitude": 34.801819,
      "longitude": -85.212809,
      "city": "Rock Spring",
      "state": "GA",
      "county": "Walker"
  },
  {
      "zipCode": 30740,
      "latitude": 34.766976,
      "longitude": -85.072681,
      "city": "Rocky Face",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30741,
      "latitude": 34.798084,
      "longitude": -85.246291,
      "city": "Rossville",
      "state": "GA",
      "county": "Walker"
  },
  {
      "zipCode": 30742,
      "latitude": 34.931497,
      "longitude": -85.175034,
      "city": "Fort Oglethorpe",
      "state": "GA",
      "county": "Catoosa"
  },
  {
      "zipCode": 30746,
      "latitude": 34.571753,
      "longitude": -85.025616,
      "city": "Sugar Valley",
      "state": "GA",
      "county": "Gordon"
  },
  {
      "zipCode": 30747,
      "latitude": 34.484159,
      "longitude": -85.315546,
      "city": "Summerville",
      "state": "GA",
      "county": "Chattooga"
  },
  {
      "zipCode": 30750,
      "latitude": 34.93473,
      "longitude": -85.384631,
      "city": "Lookout Mountain",
      "state": "GA",
      "county": "Walker"
  },
  {
      "zipCode": 30751,
      "latitude": 34.981449,
      "longitude": -84.735233,
      "city": "Tennga",
      "state": "GA",
      "county": "Murray"
  },
  {
      "zipCode": 30752,
      "latitude": 34.871687,
      "longitude": -85.50208,
      "city": "Trenton",
      "state": "GA",
      "county": "Dade"
  },
  {
      "zipCode": 30753,
      "latitude": 34.532713,
      "longitude": -85.368362,
      "city": "Trion",
      "state": "GA",
      "county": "Chattooga"
  },
  {
      "zipCode": 30755,
      "latitude": 34.875416,
      "longitude": -85.050047,
      "city": "Tunnel Hill",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30756,
      "latitude": 34.9298,
      "longitude": -84.988536,
      "city": "Varnell",
      "state": "GA",
      "county": "Whitfield"
  },
  {
      "zipCode": 30757,
      "latitude": 34.922998,
      "longitude": -85.448302,
      "city": "Wildwood",
      "state": "GA",
      "county": "Dade"
  },
  {
      "zipCode": 30802,
      "latitude": 33.573999,
      "longitude": -82.312058,
      "city": "Appling",
      "state": "GA",
      "county": "Columbia"
  },
  {
      "zipCode": 30803,
      "latitude": 33.141307,
      "longitude": -82.527009,
      "city": "Avera",
      "state": "GA",
      "county": "Jefferson"
  },
  {
      "zipCode": 30805,
      "latitude": 33.359709,
      "longitude": -82.198897,
      "city": "Blythe",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30806,
      "latitude": 33.527993,
      "longitude": -82.5104,
      "city": "Boneville",
      "state": "GA",
      "county": "Mcduffie"
  },
  {
      "zipCode": 30807,
      "latitude": 33.447102,
      "longitude": -82.642214,
      "city": "Camak",
      "state": "GA",
      "county": "Warren"
  },
  {
      "zipCode": 30808,
      "latitude": 33.404395,
      "longitude": -82.401622,
      "city": "Dearing",
      "state": "GA",
      "county": "Mcduffie"
  },
  {
      "zipCode": 30809,
      "latitude": 33.551132,
      "longitude": -82.143617,
      "city": "Evans",
      "state": "GA",
      "county": "Columbia"
  },
  {
      "zipCode": 30810,
      "latitude": 33.223471,
      "longitude": -82.599738,
      "city": "Gibson",
      "state": "GA",
      "county": "Glascock"
  },
  {
      "zipCode": 30811,
      "latitude": 33.050487,
      "longitude": -81.929165,
      "city": "Gough",
      "state": "GA",
      "county": "Burke"
  },
  {
      "zipCode": 30812,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Gracewood",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30813,
      "latitude": 33.466721,
      "longitude": -82.213991,
      "city": "Grovetown",
      "state": "GA",
      "county": "Columbia"
  },
  {
      "zipCode": 30814,
      "latitude": 33.456131,
      "longitude": -82.307337,
      "city": "Harlem",
      "state": "GA",
      "county": "Columbia"
  },
  {
      "zipCode": 30815,
      "latitude": 33.32229,
      "longitude": -82.058032,
      "city": "Hephzibah",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30816,
      "latitude": 33.155067,
      "longitude": -82.134871,
      "city": "Keysville",
      "state": "GA",
      "county": "Burke"
  },
  {
      "zipCode": 30817,
      "latitude": 33.812714,
      "longitude": -82.453456,
      "city": "Lincolnton",
      "state": "GA",
      "county": "Lincoln"
  },
  {
      "zipCode": 30818,
      "latitude": 33.189975,
      "longitude": -82.34339,
      "city": "Matthews",
      "state": "GA",
      "county": "Jefferson"
  },
  {
      "zipCode": 30819,
      "latitude": 33.432384,
      "longitude": -82.625213,
      "city": "Mesena",
      "state": "GA",
      "county": "Warren"
  },
  {
      "zipCode": 30820,
      "latitude": 33.231593,
      "longitude": -82.631164,
      "city": "Mitchell",
      "state": "GA",
      "county": "Glascock"
  },
  {
      "zipCode": 30821,
      "latitude": 33.484966,
      "longitude": -82.718794,
      "city": "Norwood",
      "state": "GA",
      "county": "Warren"
  },
  {
      "zipCode": 30822,
      "latitude": 32.873466,
      "longitude": -81.875482,
      "city": "Perkins",
      "state": "GA",
      "county": "Jenkins"
  },
  {
      "zipCode": 30823,
      "latitude": 33.181447,
      "longitude": -82.438568,
      "city": "Stapleton",
      "state": "GA",
      "county": "Jefferson"
  },
  {
      "zipCode": 30824,
      "latitude": 33.494908,
      "longitude": -82.513847,
      "city": "Thomson",
      "state": "GA",
      "county": "Mcduffie"
  },
  {
      "zipCode": 30828,
      "latitude": 33.406118,
      "longitude": -82.641067,
      "city": "Warrenton",
      "state": "GA",
      "county": "Warren"
  },
  {
      "zipCode": 30830,
      "latitude": 33.080724,
      "longitude": -81.986767,
      "city": "Waynesboro",
      "state": "GA",
      "county": "Burke"
  },
  {
      "zipCode": 30833,
      "latitude": 33.192426,
      "longitude": -82.361546,
      "city": "Wrens",
      "state": "GA",
      "county": "Jefferson"
  },
  {
      "zipCode": 30901,
      "latitude": 33.456687,
      "longitude": -81.969184,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30903,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30904,
      "latitude": 33.478686,
      "longitude": -82.015988,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30905,
      "latitude": 33.412986,
      "longitude": -82.133732,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30906,
      "latitude": 33.358865,
      "longitude": -82.009938,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30907,
      "latitude": 33.457921,
      "longitude": -82.068935,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30909,
      "latitude": 33.416616,
      "longitude": -82.055973,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30910,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30911,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30912,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30913,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30914,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30916,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30917,
      "latitude": 33.527678,
      "longitude": -82.235542,
      "city": "Augusta",
      "state": "GA",
      "county": "Columbia"
  },
  {
      "zipCode": 30919,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 30999,
      "latitude": 33.386041,
      "longitude": -82.090996,
      "city": "Augusta",
      "state": "GA",
      "county": "Richmond"
  },
  {
      "zipCode": 31001,
      "latitude": 31.977048,
      "longitude": -83.372515,
      "city": "Abbeville",
      "state": "GA",
      "county": "Wilcox"
  },
  {
      "zipCode": 31002,
      "latitude": 32.56887,
      "longitude": -82.540305,
      "city": "Adrian",
      "state": "GA",
      "county": "Emanuel"
  },
  {
      "zipCode": 31003,
      "latitude": 32.606433,
      "longitude": -83.20951,
      "city": "Allentown",
      "state": "GA",
      "county": "Wilkinson"
  },
  {
      "zipCode": 31004,
      "latitude": 32.946971,
      "longitude": -83.800628,
      "city": "Bolingbroke",
      "state": "GA",
      "county": "Monroe"
  },
  {
      "zipCode": 31005,
      "latitude": 32.539506,
      "longitude": -83.662733,
      "city": "Bonaire",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31006,
      "latitude": 32.572668,
      "longitude": -84.226075,
      "city": "Butler",
      "state": "GA",
      "county": "Taylor"
  },
  {
      "zipCode": 31007,
      "latitude": 32.185828,
      "longitude": -83.901376,
      "city": "Byromville",
      "state": "GA",
      "county": "Dooly"
  },
  {
      "zipCode": 31008,
      "latitude": 32.618051,
      "longitude": -83.788976,
      "city": "Byron",
      "state": "GA",
      "county": "Peach"
  },
  {
      "zipCode": 31009,
      "latitude": 32.303915,
      "longitude": -83.009422,
      "city": "Cadwell",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31010,
      "latitude": 31.917178,
      "longitude": -83.785365,
      "city": "Cordele",
      "state": "GA",
      "county": "Crisp"
  },
  {
      "zipCode": 31011,
      "latitude": 32.150374,
      "longitude": -83.055544,
      "city": "Chauncey",
      "state": "GA",
      "county": "Dodge"
  },
  {
      "zipCode": 31012,
      "latitude": 32.310632,
      "longitude": -83.160882,
      "city": "Chester",
      "state": "GA",
      "county": "Dodge"
  },
  {
      "zipCode": 31013,
      "latitude": 32.338639,
      "longitude": -83.692562,
      "city": "Clinchfield",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31014,
      "latitude": 32.383904,
      "longitude": -83.321095,
      "city": "Cochran",
      "state": "GA",
      "county": "Bleckley"
  },
  {
      "zipCode": 31015,
      "latitude": 31.919861,
      "longitude": -83.777354,
      "city": "Cordele",
      "state": "GA",
      "county": "Crisp"
  },
  {
      "zipCode": 31016,
      "latitude": 32.900292,
      "longitude": -84.062584,
      "city": "Culloden",
      "state": "GA",
      "county": "Monroe"
  },
  {
      "zipCode": 31017,
      "latitude": 32.607654,
      "longitude": -83.269106,
      "city": "Danville",
      "state": "GA",
      "county": "Twiggs"
  },
  {
      "zipCode": 31018,
      "latitude": 32.968449,
      "longitude": -82.648357,
      "city": "Davisboro",
      "state": "GA",
      "county": "Washington"
  },
  {
      "zipCode": 31019,
      "latitude": 32.428425,
      "longitude": -83.044502,
      "city": "Dexter",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31020,
      "latitude": 32.715133,
      "longitude": -83.455788,
      "city": "Dry Branch",
      "state": "GA",
      "county": "Twiggs"
  },
  {
      "zipCode": 31021,
      "latitude": 32.459291,
      "longitude": -82.938121,
      "city": "Dublin",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31022,
      "latitude": 32.505705,
      "longitude": -83.108975,
      "city": "Dudley",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31023,
      "latitude": 32.143564,
      "longitude": -83.194358,
      "city": "Eastman",
      "state": "GA",
      "county": "Dodge"
  },
  {
      "zipCode": 31024,
      "latitude": 33.320818,
      "longitude": -83.355865,
      "city": "Eatonton",
      "state": "GA",
      "county": "Putnam"
  },
  {
      "zipCode": 31025,
      "latitude": 32.360242,
      "longitude": -83.749048,
      "city": "Elko",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31027,
      "latitude": 32.552377,
      "longitude": -82.781684,
      "city": "Dublin",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31028,
      "latitude": 32.56822,
      "longitude": -83.70177,
      "city": "Centerville",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31029,
      "latitude": 33.025944,
      "longitude": -83.915021,
      "city": "Forsyth",
      "state": "GA",
      "county": "Monroe"
  },
  {
      "zipCode": 31030,
      "latitude": 32.560698,
      "longitude": -83.854528,
      "city": "Fort Valley",
      "state": "GA",
      "county": "Peach"
  },
  {
      "zipCode": 31031,
      "latitude": 32.881267,
      "longitude": -83.302945,
      "city": "Gordon",
      "state": "GA",
      "county": "Wilkinson"
  },
  {
      "zipCode": 31032,
      "latitude": 32.991607,
      "longitude": -83.567362,
      "city": "Gray",
      "state": "GA",
      "county": "Jones"
  },
  {
      "zipCode": 31033,
      "latitude": 33.056543,
      "longitude": -83.424672,
      "city": "Haddock",
      "state": "GA",
      "county": "Jones"
  },
  {
      "zipCode": 31034,
      "latitude": 33.022658,
      "longitude": -83.246975,
      "city": "Hardwick",
      "state": "GA",
      "county": "Baldwin"
  },
  {
      "zipCode": 31035,
      "latitude": 32.855295,
      "longitude": -82.702399,
      "city": "Harrison",
      "state": "GA",
      "county": "Washington"
  },
  {
      "zipCode": 31036,
      "latitude": 32.275664,
      "longitude": -83.49009,
      "city": "Hawkinsville",
      "state": "GA",
      "county": "Pulaski"
  },
  {
      "zipCode": 31037,
      "latitude": 32.047457,
      "longitude": -82.9747,
      "city": "Helena",
      "state": "GA",
      "county": "Telfair"
  },
  {
      "zipCode": 31038,
      "latitude": 33.207511,
      "longitude": -83.618059,
      "city": "Hillsboro",
      "state": "GA",
      "county": "Jasper"
  },
  {
      "zipCode": 31039,
      "latitude": 32.559781,
      "longitude": -84.227675,
      "city": "Howard",
      "state": "GA",
      "county": "Taylor"
  },
  {
      "zipCode": 31040,
      "latitude": 32.540073,
      "longitude": -82.914974,
      "city": "Dublin",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31041,
      "latitude": 32.372937,
      "longitude": -84.212159,
      "city": "Ideal",
      "state": "GA",
      "county": "Macon"
  },
  {
      "zipCode": 31042,
      "latitude": 32.751414,
      "longitude": -83.145237,
      "city": "Irwinton",
      "state": "GA",
      "county": "Wilkinson"
  },
  {
      "zipCode": 31044,
      "latitude": 32.661271,
      "longitude": -83.331033,
      "city": "Jeffersonville",
      "state": "GA",
      "county": "Twiggs"
  },
  {
      "zipCode": 31045,
      "latitude": 33.296633,
      "longitude": -82.755599,
      "city": "Jewell",
      "state": "GA",
      "county": "Warren"
  },
  {
      "zipCode": 31046,
      "latitude": 33.027607,
      "longitude": -83.803166,
      "city": "Juliette",
      "state": "GA",
      "county": "Monroe"
  },
  {
      "zipCode": 31047,
      "latitude": 32.454331,
      "longitude": -83.646436,
      "city": "Kathleen",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31049,
      "latitude": 32.698954,
      "longitude": -82.525306,
      "city": "Kite",
      "state": "GA",
      "county": "Johnson"
  },
  {
      "zipCode": 31050,
      "latitude": 32.715632,
      "longitude": -83.915762,
      "city": "Knoxville",
      "state": "GA",
      "county": "Crawford"
  },
  {
      "zipCode": 31051,
      "latitude": 32.146879,
      "longitude": -83.891349,
      "city": "Lilly",
      "state": "GA",
      "county": "Dooly"
  },
  {
      "zipCode": 31052,
      "latitude": 32.783332,
      "longitude": -83.823023,
      "city": "Lizella",
      "state": "GA",
      "county": "Crawford"
  },
  {
      "zipCode": 31054,
      "latitude": 32.886524,
      "longitude": -83.231724,
      "city": "Mc Intyre",
      "state": "GA",
      "county": "Wilkinson"
  },
  {
      "zipCode": 31055,
      "latitude": 32.047918,
      "longitude": -82.910927,
      "city": "Mc Rae",
      "state": "GA",
      "county": "Telfair"
  },
  {
      "zipCode": 31057,
      "latitude": 32.438321,
      "longitude": -83.905039,
      "city": "Marshallville",
      "state": "GA",
      "county": "Macon"
  },
  {
      "zipCode": 31058,
      "latitude": 32.559781,
      "longitude": -84.227675,
      "city": "Mauk",
      "state": "GA",
      "county": "Taylor"
  },
  {
      "zipCode": 31059,
      "latitude": 33.081335,
      "longitude": -83.226664,
      "city": "Milledgeville",
      "state": "GA",
      "county": "Baldwin"
  },
  {
      "zipCode": 31060,
      "latitude": 31.964005,
      "longitude": -82.926588,
      "city": "Milan",
      "state": "GA",
      "county": "Telfair"
  },
  {
      "zipCode": 31061,
      "latitude": 33.072279,
      "longitude": -83.239008,
      "city": "Milledgeville",
      "state": "GA",
      "county": "Baldwin"
  },
  {
      "zipCode": 31062,
      "latitude": 33.087138,
      "longitude": -83.22492,
      "city": "Milledgeville",
      "state": "GA",
      "county": "Baldwin"
  },
  {
      "zipCode": 31063,
      "latitude": 32.240448,
      "longitude": -83.863997,
      "city": "Montezuma",
      "state": "GA",
      "county": "Macon"
  },
  {
      "zipCode": 31064,
      "latitude": 33.337906,
      "longitude": -83.698508,
      "city": "Monticello",
      "state": "GA",
      "county": "Jasper"
  },
  {
      "zipCode": 31065,
      "latitude": 32.56204,
      "longitude": -83.159741,
      "city": "Montrose",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31066,
      "latitude": 32.786842,
      "longitude": -83.979228,
      "city": "Musella",
      "state": "GA",
      "county": "Crawford"
  },
  {
      "zipCode": 31067,
      "latitude": 32.876666,
      "longitude": -82.946903,
      "city": "Oconee",
      "state": "GA",
      "county": "Washington"
  },
  {
      "zipCode": 31068,
      "latitude": 32.327479,
      "longitude": -84.101472,
      "city": "Oglethorpe",
      "state": "GA",
      "county": "Macon"
  },
  {
      "zipCode": 31069,
      "latitude": 32.43194,
      "longitude": -83.722649,
      "city": "Perry",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31070,
      "latitude": 32.182652,
      "longitude": -83.778422,
      "city": "Pinehurst",
      "state": "GA",
      "county": "Dooly"
  },
  {
      "zipCode": 31071,
      "latitude": 32.09813,
      "longitude": -83.504479,
      "city": "Pineview",
      "state": "GA",
      "county": "Wilcox"
  },
  {
      "zipCode": 31072,
      "latitude": 31.948637,
      "longitude": -83.539522,
      "city": "Pitts",
      "state": "GA",
      "county": "Wilcox"
  },
  {
      "zipCode": 31073,
      "latitude": 32.176429,
      "longitude": -83.122891,
      "city": "Plainfield",
      "state": "GA",
      "county": "Dodge"
  },
  {
      "zipCode": 31075,
      "latitude": 32.379902,
      "longitude": -82.966995,
      "city": "Rentz",
      "state": "GA",
      "county": "Laurens"
  },
  {
      "zipCode": 31076,
      "latitude": 32.551695,
      "longitude": -84.092715,
      "city": "Reynolds",
      "state": "GA",
      "county": "Taylor"
  },
  {
      "zipCode": 31077,
      "latitude": 32.176429,
      "longitude": -83.122891,
      "city": "Rhine",
      "state": "GA",
      "county": "Dodge"
  },
  {
      "zipCode": 31078,
      "latitude": 32.687207,
      "longitude": -84.010581,
      "city": "Roberta",
      "state": "GA",
      "county": "Crawford"
  },
  {
      "zipCode": 31079,
      "latitude": 31.948411,
      "longitude": -83.453578,
      "city": "Rochelle",
      "state": "GA",
      "county": "Wilcox"
  },
  {
      "zipCode": 31081,
      "latitude": 32.559781,
      "longitude": -84.227675,
      "city": "Rupert",
      "state": "GA",
      "county": "Taylor"
  },
  {
      "zipCode": 31082,
      "latitude": 32.974982,
      "longitude": -82.840564,
      "city": "Sandersville",
      "state": "GA",
      "county": "Washington"
  },
  {
      "zipCode": 31083,
      "latitude": 32.084139,
      "longitude": -82.912341,
      "city": "Scotland",
      "state": "GA",
      "county": "Telfair"
  },
  {
      "zipCode": 31084,
      "latitude": 31.989039,
      "longitude": -83.394574,
      "city": "Seville",
      "state": "GA",
      "county": "Wilcox"
  },
  {
      "zipCode": 31085,
      "latitude": 33.435022,
      "longitude": -83.626885,
      "city": "Shady Dale",
      "state": "GA",
      "county": "Jasper"
  },
  {
      "zipCode": 31086,
      "latitude": 32.989503,
      "longitude": -83.876108,
      "city": "Smarr",
      "state": "GA",
      "county": "Monroe"
  },
  {
      "zipCode": 31087,
      "latitude": 33.257068,
      "longitude": -83.08922,
      "city": "Sparta",
      "state": "GA",
      "county": "Hancock"
  },
  {
      "zipCode": 31088,
      "latitude": 32.555039,
      "longitude": -83.644751,
      "city": "Warner Robins",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31089,
      "latitude": 32.873449,
      "longitude": -82.782879,
      "city": "Tennille",
      "state": "GA",
      "county": "Washington"
  },
  {
      "zipCode": 31090,
      "latitude": 32.806595,
      "longitude": -83.066814,
      "city": "Toomsboro",
      "state": "GA",
      "county": "Wilkinson"
  },
  {
      "zipCode": 31091,
      "latitude": 32.219249,
      "longitude": -83.731718,
      "city": "Unadilla",
      "state": "GA",
      "county": "Dooly"
  },
  {
      "zipCode": 31092,
      "latitude": 32.115981,
      "longitude": -83.791644,
      "city": "Vienna",
      "state": "GA",
      "county": "Dooly"
  },
  {
      "zipCode": 31093,
      "latitude": 32.587303,
      "longitude": -83.658174,
      "city": "Warner Robins",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31094,
      "latitude": 33.07975,
      "longitude": -82.805591,
      "city": "Warthen",
      "state": "GA",
      "county": "Washington"
  },
  {
      "zipCode": 31095,
      "latitude": 32.487403,
      "longitude": -83.669655,
      "city": "Warner Robins",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31096,
      "latitude": 32.635692,
      "longitude": -82.657495,
      "city": "Wrightsville",
      "state": "GA",
      "county": "Johnson"
  },
  {
      "zipCode": 31097,
      "latitude": 32.901627,
      "longitude": -84.212644,
      "city": "Yatesville",
      "state": "GA",
      "county": "Upson"
  },
  {
      "zipCode": 31098,
      "latitude": 32.49372,
      "longitude": -83.640099,
      "city": "Warner Robins",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31099,
      "latitude": 32.646236,
      "longitude": -83.6513,
      "city": "Warner Robins",
      "state": "GA",
      "county": "Houston"
  },
  {
      "zipCode": 31106,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31107,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31119,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 31126,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31131,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31139,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31141,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 31145,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 31146,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 31150,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31156,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31191,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31192,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31193,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31195,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31196,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31197,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31198,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31199,
      "latitude": 33.844371,
      "longitude": -84.47405,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": 31201,
      "latitude": 32.827949,
      "longitude": -83.595066,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31202,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31203,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31204,
      "latitude": 32.852249,
      "longitude": -83.667064,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31205,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31206,
      "latitude": 32.834861,
      "longitude": -83.668091,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31207,
      "latitude": 32.830389,
      "longitude": -83.648552,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31208,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31209,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31210,
      "latitude": 32.843368,
      "longitude": -83.680078,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31211,
      "latitude": 32.866987,
      "longitude": -83.607363,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31212,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31213,
      "latitude": 32.839289,
      "longitude": -83.638752,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31216,
      "latitude": 32.74857,
      "longitude": -83.747721,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31217,
      "latitude": 32.811767,
      "longitude": -83.565009,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31220,
      "latitude": 32.859461,
      "longitude": -83.802047,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31221,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31294,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31295,
      "latitude": 32.810189,
      "longitude": -83.569001,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31296,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31297,
      "latitude": 32.70045,
      "longitude": -83.657207,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31298,
      "latitude": 32.87333,
      "longitude": -83.708399,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31299,
      "latitude": 32.806707,
      "longitude": -83.691315,
      "city": "Macon",
      "state": "GA",
      "county": "Bibb"
  },
  {
      "zipCode": 31301,
      "latitude": 31.779116,
      "longitude": -81.60385,
      "city": "Allenhurst",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31302,
      "latitude": 32.107103,
      "longitude": -81.291335,
      "city": "Bloomingdale",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31303,
      "latitude": 32.488015,
      "longitude": -81.322392,
      "city": "Clyo",
      "state": "GA",
      "county": "Effingham"
  },
  {
      "zipCode": 31304,
      "latitude": 31.495071,
      "longitude": -81.381908,
      "city": "Crescent",
      "state": "GA",
      "county": "Mcintosh"
  },
  {
      "zipCode": 31305,
      "latitude": 31.413831,
      "longitude": -81.369263,
      "city": "Darien",
      "state": "GA",
      "county": "Mcintosh"
  },
  {
      "zipCode": 31307,
      "latitude": 32.174021,
      "longitude": -81.353756,
      "city": "Eden",
      "state": "GA",
      "county": "Effingham"
  },
  {
      "zipCode": 31308,
      "latitude": 32.159233,
      "longitude": -81.502963,
      "city": "Ellabell",
      "state": "GA",
      "county": "Bryan"
  },
  {
      "zipCode": 31309,
      "latitude": 31.87673,
      "longitude": -81.457959,
      "city": "Fleming",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31310,
      "latitude": 31.806832,
      "longitude": -81.437074,
      "city": "Hinesville",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31312,
      "latitude": 32.331563,
      "longitude": -81.384181,
      "city": "Guyton",
      "state": "GA",
      "county": "Effingham"
  },
  {
      "zipCode": 31313,
      "latitude": 31.804838,
      "longitude": -81.523649,
      "city": "Hinesville",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31314,
      "latitude": 31.806832,
      "longitude": -81.437074,
      "city": "Fort Stewart",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31315,
      "latitude": 31.844332,
      "longitude": -81.654696,
      "city": "Fort Stewart",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31316,
      "latitude": 31.780996,
      "longitude": -81.773916,
      "city": "Ludowici",
      "state": "GA",
      "county": "Long"
  },
  {
      "zipCode": 31318,
      "latitude": 32.149861,
      "longitude": -81.372184,
      "city": "Meldrim",
      "state": "GA",
      "county": "Effingham"
  },
  {
      "zipCode": 31319,
      "latitude": 31.459045,
      "longitude": -81.368884,
      "city": "Meridian",
      "state": "GA",
      "county": "Mcintosh"
  },
  {
      "zipCode": 31320,
      "latitude": 31.778847,
      "longitude": -81.420626,
      "city": "Midway",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31321,
      "latitude": 32.009967,
      "longitude": -81.514752,
      "city": "Pembroke",
      "state": "GA",
      "county": "Bryan"
  },
  {
      "zipCode": 31322,
      "latitude": 32.071917,
      "longitude": -81.083881,
      "city": "Pooler",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31323,
      "latitude": 31.785934,
      "longitude": -81.57385,
      "city": "Riceboro",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31324,
      "latitude": 31.951531,
      "longitude": -81.407336,
      "city": "Richmond Hill",
      "state": "GA",
      "county": "Bryan"
  },
  {
      "zipCode": 31326,
      "latitude": 32.285205,
      "longitude": -81.287526,
      "city": "Rincon",
      "state": "GA",
      "county": "Effingham"
  },
  {
      "zipCode": 31327,
      "latitude": 31.495071,
      "longitude": -81.381908,
      "city": "Sapelo Island",
      "state": "GA",
      "county": "Mcintosh"
  },
  {
      "zipCode": 31328,
      "latitude": 32.00593,
      "longitude": -80.855322,
      "city": "Tybee Island",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31329,
      "latitude": 32.369703,
      "longitude": -81.361775,
      "city": "Springfield",
      "state": "GA",
      "county": "Effingham"
  },
  {
      "zipCode": 31331,
      "latitude": 31.512024,
      "longitude": -81.358252,
      "city": "Townsend",
      "state": "GA",
      "county": "Mcintosh"
  },
  {
      "zipCode": 31333,
      "latitude": 31.770473,
      "longitude": -81.62083,
      "city": "Walthourville",
      "state": "GA",
      "county": "Liberty"
  },
  {
      "zipCode": 31401,
      "latitude": 32.072257,
      "longitude": -81.135618,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31402,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31403,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31404,
      "latitude": 32.048247,
      "longitude": -81.11748,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31405,
      "latitude": 32.065317,
      "longitude": -81.181413,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31406,
      "latitude": 31.975611,
      "longitude": -81.086617,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31407,
      "latitude": 32.167235,
      "longitude": -81.199897,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31408,
      "latitude": 32.108154,
      "longitude": -81.174612,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31409,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31410,
      "latitude": 32.017461,
      "longitude": -80.997043,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31411,
      "latitude": 31.942645,
      "longitude": -81.045795,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31412,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31414,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31415,
      "latitude": 32.075307,
      "longitude": -81.128865,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31416,
      "latitude": 32.005255,
      "longitude": -81.047711,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31418,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31419,
      "latitude": 32.008492,
      "longitude": -81.214369,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31420,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31421,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31422,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31498,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31499,
      "latitude": 31.971394,
      "longitude": -81.07156,
      "city": "Savannah",
      "state": "GA",
      "county": "Chatham"
  },
  {
      "zipCode": 31501,
      "latitude": 31.224262,
      "longitude": -82.359628,
      "city": "Waycross",
      "state": "GA",
      "county": "Ware"
  },
  {
      "zipCode": 31502,
      "latitude": 31.018954,
      "longitude": -82.416543,
      "city": "Waycross",
      "state": "GA",
      "county": "Ware"
  },
  {
      "zipCode": 31503,
      "latitude": 31.193229,
      "longitude": -82.376595,
      "city": "Waycross",
      "state": "GA",
      "county": "Ware"
  },
  {
      "zipCode": 31510,
      "latitude": 31.576952,
      "longitude": -82.461197,
      "city": "Alma",
      "state": "GA",
      "county": "Bacon"
  },
  {
      "zipCode": 31512,
      "latitude": 31.549597,
      "longitude": -82.987305,
      "city": "Ambrose",
      "state": "GA",
      "county": "Coffee"
  },
  {
      "zipCode": 31513,
      "latitude": 31.756861,
      "longitude": -82.350837,
      "city": "Baxley",
      "state": "GA",
      "county": "Appling"
  },
  {
      "zipCode": 31515,
      "latitude": 31.717717,
      "longitude": -82.299677,
      "city": "Baxley",
      "state": "GA",
      "county": "Appling"
  },
  {
      "zipCode": 31516,
      "latitude": 31.326324,
      "longitude": -82.271206,
      "city": "Blackshear",
      "state": "GA",
      "county": "Pierce"
  },
  {
      "zipCode": 31518,
      "latitude": 31.483312,
      "longitude": -82.214987,
      "city": "Bristol",
      "state": "GA",
      "county": "Pierce"
  },
  {
      "zipCode": 31519,
      "latitude": 31.671903,
      "longitude": -82.864903,
      "city": "Broxton",
      "state": "GA",
      "county": "Coffee"
  },
  {
      "zipCode": 31520,
      "latitude": 31.230428,
      "longitude": -81.492883,
      "city": "Brunswick",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31521,
      "latitude": 31.241505,
      "longitude": -81.532489,
      "city": "Brunswick",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31522,
      "latitude": 31.213223,
      "longitude": -81.449141,
      "city": "Saint Simons Island",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31523,
      "latitude": 31.218921,
      "longitude": -81.546047,
      "city": "Brunswick",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31524,
      "latitude": 31.221995,
      "longitude": -81.482551,
      "city": "Brunswick",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31525,
      "latitude": 31.280403,
      "longitude": -81.530477,
      "city": "Brunswick",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31527,
      "latitude": 31.059721,
      "longitude": -81.416665,
      "city": "Jekyll Island",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31532,
      "latitude": 31.795977,
      "longitude": -82.707079,
      "city": "Denton",
      "state": "GA",
      "county": "Jeff Davis"
  },
  {
      "zipCode": 31533,
      "latitude": 31.509338,
      "longitude": -82.857131,
      "city": "Douglas",
      "state": "GA",
      "county": "Coffee"
  },
  {
      "zipCode": 31534,
      "latitude": 31.571596,
      "longitude": -82.854631,
      "city": "Douglas",
      "state": "GA",
      "county": "Coffee"
  },
  {
      "zipCode": 31535,
      "latitude": 31.504581,
      "longitude": -82.824636,
      "city": "Douglas",
      "state": "GA",
      "county": "Coffee"
  },
  {
      "zipCode": 31537,
      "latitude": 30.855314,
      "longitude": -82.027252,
      "city": "Folkston",
      "state": "GA",
      "county": "Charlton"
  },
  {
      "zipCode": 31539,
      "latitude": 31.806871,
      "longitude": -82.626991,
      "city": "Hazlehurst",
      "state": "GA",
      "county": "Jeff Davis"
  },
  {
      "zipCode": 31542,
      "latitude": 31.178954,
      "longitude": -82.123686,
      "city": "Hoboken",
      "state": "GA",
      "county": "Brantley"
  },
  {
      "zipCode": 31543,
      "latitude": 31.26837,
      "longitude": -81.810366,
      "city": "Hortense",
      "state": "GA",
      "county": "Brantley"
  },
  {
      "zipCode": 31544,
      "latitude": 31.83245,
      "longitude": -82.977178,
      "city": "Jacksonville",
      "state": "GA",
      "county": "Telfair"
  },
  {
      "zipCode": 31545,
      "latitude": 31.600775,
      "longitude": -81.957787,
      "city": "Jesup",
      "state": "GA",
      "county": "Wayne"
  },
  {
      "zipCode": 31546,
      "latitude": 31.531938,
      "longitude": -81.804924,
      "city": "Jesup",
      "state": "GA",
      "county": "Wayne"
  },
  {
      "zipCode": 31547,
      "latitude": 30.804684,
      "longitude": -81.627717,
      "city": "Kings Bay",
      "state": "GA",
      "county": "Camden"
  },
  {
      "zipCode": 31548,
      "latitude": 30.784384,
      "longitude": -81.692042,
      "city": "Kingsland",
      "state": "GA",
      "county": "Camden"
  },
  {
      "zipCode": 31549,
      "latitude": 31.931373,
      "longitude": -82.682963,
      "city": "Lumber City",
      "state": "GA",
      "county": "Telfair"
  },
  {
      "zipCode": 31550,
      "latitude": 31.168101,
      "longitude": -82.594815,
      "city": "Manor",
      "state": "GA",
      "county": "Ware"
  },
  {
      "zipCode": 31551,
      "latitude": 31.453629,
      "longitude": -82.230385,
      "city": "Mershon",
      "state": "GA",
      "county": "Pierce"
  },
  {
      "zipCode": 31552,
      "latitude": 31.307256,
      "longitude": -82.600728,
      "city": "Millwood",
      "state": "GA",
      "county": "Ware"
  },
  {
      "zipCode": 31553,
      "latitude": 31.158299,
      "longitude": -81.954478,
      "city": "Nahunta",
      "state": "GA",
      "county": "Brantley"
  },
  {
      "zipCode": 31554,
      "latitude": 31.504909,
      "longitude": -82.703064,
      "city": "Nicholls",
      "state": "GA",
      "county": "Coffee"
  },
  {
      "zipCode": 31555,
      "latitude": 31.685101,
      "longitude": -82.037562,
      "city": "Odum",
      "state": "GA",
      "county": "Wayne"
  },
  {
      "zipCode": 31556,
      "latitude": 31.412649,
      "longitude": -82.115541,
      "city": "Offerman",
      "state": "GA",
      "county": "Pierce"
  },
  {
      "zipCode": 31557,
      "latitude": 31.369686,
      "longitude": -82.115363,
      "city": "Patterson",
      "state": "GA",
      "county": "Pierce"
  },
  {
      "zipCode": 31558,
      "latitude": 30.844019,
      "longitude": -81.630984,
      "city": "Saint Marys",
      "state": "GA",
      "county": "Camden"
  },
  {
      "zipCode": 31560,
      "latitude": 31.512923,
      "longitude": -82.010258,
      "city": "Screven",
      "state": "GA",
      "county": "Wayne"
  },
  {
      "zipCode": 31561,
      "latitude": 31.198914,
      "longitude": -81.332211,
      "city": "Sea Island",
      "state": "GA",
      "county": "Glynn"
  },
  {
      "zipCode": 31563,
      "latitude": 31.731163,
      "longitude": -82.194912,
      "city": "Surrency",
      "state": "GA",
      "county": "Appling"
  },
  {
      "zipCode": 31564,
      "latitude": 31.018954,
      "longitude": -82.416543,
      "city": "Waresboro",
      "state": "GA",
      "county": "Ware"
  },
  {
      "zipCode": 31565,
      "latitude": 31.069415,
      "longitude": -81.633658,
      "city": "Waverly",
      "state": "GA",
      "county": "Camden"
  },
  {
      "zipCode": 31566,
      "latitude": 31.187918,
      "longitude": -81.889563,
      "city": "Waynesville",
      "state": "GA",
      "county": "Brantley"
  },
  {
      "zipCode": 31567,
      "latitude": 31.614312,
      "longitude": -82.724308,
      "city": "West Green",
      "state": "GA",
      "county": "Coffee"
  },
  {
      "zipCode": 31568,
      "latitude": 30.995235,
      "longitude": -81.778181,
      "city": "White Oak",
      "state": "GA",
      "county": "Camden"
  },
  {
      "zipCode": 31569,
      "latitude": 30.939072,
      "longitude": -81.703469,
      "city": "Woodbine",
      "state": "GA",
      "county": "Camden"
  },
  {
      "zipCode": 31598,
      "latitude": 31.578181,
      "longitude": -81.880222,
      "city": "Jesup",
      "state": "GA",
      "county": "Wayne"
  },
  {
      "zipCode": 31599,
      "latitude": 31.578181,
      "longitude": -81.880222,
      "city": "Jesup",
      "state": "GA",
      "county": "Wayne"
  },
  {
      "zipCode": 31601,
      "latitude": 30.753904,
      "longitude": -83.332068,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31602,
      "latitude": 30.893109,
      "longitude": -83.327812,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31603,
      "latitude": 30.827965,
      "longitude": -83.252192,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31604,
      "latitude": 30.827965,
      "longitude": -83.252192,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31605,
      "latitude": 30.945994,
      "longitude": -83.247371,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31606,
      "latitude": 30.826306,
      "longitude": -83.245331,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31620,
      "latitude": 31.151742,
      "longitude": -83.407143,
      "city": "Adel",
      "state": "GA",
      "county": "Cook"
  },
  {
      "zipCode": 31622,
      "latitude": 31.383968,
      "longitude": -83.221348,
      "city": "Alapaha",
      "state": "GA",
      "county": "Berrien"
  },
  {
      "zipCode": 31623,
      "latitude": 31.064114,
      "longitude": -82.68954,
      "city": "Argyle",
      "state": "GA",
      "county": "Clinch"
  },
  {
      "zipCode": 31624,
      "latitude": 31.303159,
      "longitude": -82.847997,
      "city": "Axson",
      "state": "GA",
      "county": "Atkinson"
  },
  {
      "zipCode": 31625,
      "latitude": 30.856633,
      "longitude": -83.526479,
      "city": "Barney",
      "state": "GA",
      "county": "Brooks"
  },
  {
      "zipCode": 31626,
      "latitude": 30.828644,
      "longitude": -83.910971,
      "city": "Boston",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31627,
      "latitude": 31.040257,
      "longitude": -83.391519,
      "city": "Cecil",
      "state": "GA",
      "county": "Cook"
  },
  {
      "zipCode": 31629,
      "latitude": 30.761316,
      "longitude": -83.708223,
      "city": "Dixie",
      "state": "GA",
      "county": "Brooks"
  },
  {
      "zipCode": 31630,
      "latitude": 30.975874,
      "longitude": -82.880688,
      "city": "Du Pont",
      "state": "GA",
      "county": "Clinch"
  },
  {
      "zipCode": 31631,
      "latitude": 31.05682,
      "longitude": -82.772422,
      "city": "Fargo",
      "state": "GA",
      "county": "Clinch"
  },
  {
      "zipCode": 31632,
      "latitude": 30.942022,
      "longitude": -83.333206,
      "city": "Hahira",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31634,
      "latitude": 31.050929,
      "longitude": -82.76133,
      "city": "Homerville",
      "state": "GA",
      "county": "Clinch"
  },
  {
      "zipCode": 31635,
      "latitude": 31.035327,
      "longitude": -83.098737,
      "city": "Lakeland",
      "state": "GA",
      "county": "Lanier"
  },
  {
      "zipCode": 31636,
      "latitude": 30.745322,
      "longitude": -83.184325,
      "city": "Lake Park",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31637,
      "latitude": 31.271045,
      "longitude": -83.468529,
      "city": "Lenox",
      "state": "GA",
      "county": "Cook"
  },
  {
      "zipCode": 31638,
      "latitude": 30.924839,
      "longitude": -83.539952,
      "city": "Morven",
      "state": "GA",
      "county": "Brooks"
  },
  {
      "zipCode": 31639,
      "latitude": 31.199826,
      "longitude": -83.218233,
      "city": "Nashville",
      "state": "GA",
      "county": "Berrien"
  },
  {
      "zipCode": 31641,
      "latitude": 30.899018,
      "longitude": -83.128478,
      "city": "Naylor",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31642,
      "latitude": 31.340836,
      "longitude": -82.853406,
      "city": "Pearson",
      "state": "GA",
      "county": "Atkinson"
  },
  {
      "zipCode": 31643,
      "latitude": 30.78944,
      "longitude": -83.587777,
      "city": "Quitman",
      "state": "GA",
      "county": "Brooks"
  },
  {
      "zipCode": 31645,
      "latitude": 31.083619,
      "longitude": -83.228709,
      "city": "Ray City",
      "state": "GA",
      "county": "Berrien"
  },
  {
      "zipCode": 31646,
      "latitude": 30.434346,
      "longitude": -82.165527,
      "city": "Saint George",
      "state": "GA",
      "county": "Charlton"
  },
  {
      "zipCode": 31647,
      "latitude": 31.212878,
      "longitude": -83.412143,
      "city": "Sparks",
      "state": "GA",
      "county": "Cook"
  },
  {
      "zipCode": 31648,
      "latitude": 30.768894,
      "longitude": -83.037116,
      "city": "Statenville",
      "state": "GA",
      "county": "Echols"
  },
  {
      "zipCode": 31649,
      "latitude": 30.955111,
      "longitude": -83.013828,
      "city": "Stockton",
      "state": "GA",
      "county": "Lanier"
  },
  {
      "zipCode": 31650,
      "latitude": 31.361682,
      "longitude": -82.968235,
      "city": "Willacoochee",
      "state": "GA",
      "county": "Atkinson"
  },
  {
      "zipCode": 31698,
      "latitude": 30.84848,
      "longitude": -83.287753,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31699,
      "latitude": 30.975119,
      "longitude": -83.207206,
      "city": "Valdosta",
      "state": "GA",
      "county": "Lowndes"
  },
  {
      "zipCode": 31701,
      "latitude": 31.560674,
      "longitude": -84.176751,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31702,
      "latitude": 31.594782,
      "longitude": -84.194812,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31703,
      "latitude": 31.543065,
      "longitude": -84.219594,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31704,
      "latitude": 31.549975,
      "longitude": -84.061155,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31705,
      "latitude": 31.546375,
      "longitude": -84.078338,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31706,
      "latitude": 31.559274,
      "longitude": -84.176507,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31707,
      "latitude": 31.529269,
      "longitude": -84.293109,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31708,
      "latitude": 31.591073,
      "longitude": -84.132357,
      "city": "Albany",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31709,
      "latitude": 32.040432,
      "longitude": -84.215282,
      "city": "Americus",
      "state": "GA",
      "county": "Sumter"
  },
  {
      "zipCode": 31710,
      "latitude": 32.051235,
      "longitude": -84.181547,
      "city": "Americus",
      "state": "GA",
      "county": "Sumter"
  },
  {
      "zipCode": 31711,
      "latitude": 32.155949,
      "longitude": -84.163983,
      "city": "Andersonville",
      "state": "GA",
      "county": "Sumter"
  },
  {
      "zipCode": 31712,
      "latitude": 31.849613,
      "longitude": -83.7277,
      "city": "Arabi",
      "state": "GA",
      "county": "Crisp"
  },
  {
      "zipCode": 31713,
      "latitude": 31.47718,
      "longitude": -84.699156,
      "city": "Arlington",
      "state": "GA",
      "county": "Calhoun"
  },
  {
      "zipCode": 31714,
      "latitude": 31.723074,
      "longitude": -83.644063,
      "city": "Ashburn",
      "state": "GA",
      "county": "Turner"
  },
  {
      "zipCode": 31715,
      "latitude": 30.835331,
      "longitude": -84.511949,
      "city": "Attapulgus",
      "state": "GA",
      "county": "Decatur"
  },
  {
      "zipCode": 31716,
      "latitude": 31.358861,
      "longitude": -84.100301,
      "city": "Baconton",
      "state": "GA",
      "county": "Mitchell"
  },
  {
      "zipCode": 31717,
      "latitude": 30.888734,
      "longitude": -84.617836,
      "city": "Bainbridge",
      "state": "GA",
      "county": "Decatur"
  },
  {
      "zipCode": 31718,
      "latitude": 30.901863,
      "longitude": -84.570049,
      "city": "Bainbridge",
      "state": "GA",
      "county": "Decatur"
  },
  {
      "zipCode": 31720,
      "latitude": 30.856633,
      "longitude": -83.526479,
      "city": "Barwick",
      "state": "GA",
      "county": "Brooks"
  },
  {
      "zipCode": 31722,
      "latitude": 31.080682,
      "longitude": -83.649138,
      "city": "Berlin",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31723,
      "latitude": 31.346088,
      "longitude": -84.901178,
      "city": "Blakely",
      "state": "GA",
      "county": "Early"
  },
  {
      "zipCode": 31724,
      "latitude": 31.557533,
      "longitude": -84.854212,
      "city": "Bluffton",
      "state": "GA",
      "county": "Clay"
  },
  {
      "zipCode": 31725,
      "latitude": 30.907915,
      "longitude": -84.703795,
      "city": "Brinson",
      "state": "GA",
      "county": "Decatur"
  },
  {
      "zipCode": 31726,
      "latitude": 31.824017,
      "longitude": -84.343163,
      "city": "Bronwood",
      "state": "GA",
      "county": "Terrell"
  },
  {
      "zipCode": 31727,
      "latitude": 31.44172,
      "longitude": -83.503715,
      "city": "Brookfield",
      "state": "GA",
      "county": "Tift"
  },
  {
      "zipCode": 31728,
      "latitude": 30.857503,
      "longitude": -84.22903,
      "city": "Cairo",
      "state": "GA",
      "county": "Grady"
  },
  {
      "zipCode": 31729,
      "latitude": 30.71471,
      "longitude": -84.351846,
      "city": "Calvary",
      "state": "GA",
      "county": "Grady"
  },
  {
      "zipCode": 31730,
      "latitude": 31.249152,
      "longitude": -84.242837,
      "city": "Camilla",
      "state": "GA",
      "county": "Mitchell"
  },
  {
      "zipCode": 31732,
      "latitude": 31.29563,
      "longitude": -84.871091,
      "city": "Cedar Springs",
      "state": "GA",
      "county": "Early"
  },
  {
      "zipCode": 31733,
      "latitude": 31.557454,
      "longitude": -83.509385,
      "city": "Chula",
      "state": "GA",
      "county": "Tift"
  },
  {
      "zipCode": 31734,
      "latitude": 30.887347,
      "longitude": -84.468281,
      "city": "Climax",
      "state": "GA",
      "county": "Decatur"
  },
  {
      "zipCode": 31735,
      "latitude": 31.968395,
      "longitude": -83.978975,
      "city": "Cobb",
      "state": "GA",
      "county": "Sumter"
  },
  {
      "zipCode": 31736,
      "latitude": 31.775195,
      "longitude": -84.747201,
      "city": "Coleman",
      "state": "GA",
      "county": "Randolph"
  },
  {
      "zipCode": 31737,
      "latitude": 31.16251,
      "longitude": -84.713359,
      "city": "Colquitt",
      "state": "GA",
      "county": "Miller"
  },
  {
      "zipCode": 31738,
      "latitude": 30.976287,
      "longitude": -83.871284,
      "city": "Coolidge",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31739,
      "latitude": 31.260443,
      "longitude": -84.253089,
      "city": "Cotton",
      "state": "GA",
      "county": "Mitchell"
  },
  {
      "zipCode": 31740,
      "latitude": 31.731183,
      "longitude": -84.832234,
      "city": "Cuthbert",
      "state": "GA",
      "county": "Randolph"
  },
  {
      "zipCode": 31741,
      "latitude": 31.298124,
      "longitude": -84.71746,
      "city": "Damascus",
      "state": "GA",
      "county": "Early"
  },
  {
      "zipCode": 31742,
      "latitude": 31.76615,
      "longitude": -84.438586,
      "city": "Dawson",
      "state": "GA",
      "county": "Terrell"
  },
  {
      "zipCode": 31743,
      "latitude": 31.938088,
      "longitude": -84.03104,
      "city": "De Soto",
      "state": "GA",
      "county": "Sumter"
  },
  {
      "zipCode": 31744,
      "latitude": 31.272009,
      "longitude": -83.889951,
      "city": "Doerun",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31745,
      "latitude": 30.918229,
      "longitude": -84.867238,
      "city": "Donalsonville",
      "state": "GA",
      "county": "Seminole"
  },
  {
      "zipCode": 31746,
      "latitude": 31.506869,
      "longitude": -84.721045,
      "city": "Edison",
      "state": "GA",
      "county": "Calhoun"
  },
  {
      "zipCode": 31747,
      "latitude": 31.17885,
      "longitude": -83.588901,
      "city": "Ellenton",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31749,
      "latitude": 31.417797,
      "longitude": -83.355349,
      "city": "Enigma",
      "state": "GA",
      "county": "Berrien"
  },
  {
      "zipCode": 31750,
      "latitude": 31.748753,
      "longitude": -83.235412,
      "city": "Fitzgerald",
      "state": "GA",
      "county": "Ben Hill"
  },
  {
      "zipCode": 31751,
      "latitude": 31.63618,
      "longitude": -85.054942,
      "city": "Fort Gaines",
      "state": "GA",
      "county": "Clay"
  },
  {
      "zipCode": 31752,
      "latitude": 30.791856,
      "longitude": -84.549907,
      "city": "Fowlstown",
      "state": "GA",
      "county": "Decatur"
  },
  {
      "zipCode": 31753,
      "latitude": 31.205365,
      "longitude": -83.877554,
      "city": "Funston",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31754,
      "latitude": 31.844937,
      "longitude": -85.104383,
      "city": "Georgetown",
      "state": "GA",
      "county": "Quitman"
  },
  {
      "zipCode": 31756,
      "latitude": 31.200827,
      "longitude": -83.945527,
      "city": "Hartsfield",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31757,
      "latitude": 30.853592,
      "longitude": -83.888269,
      "city": "Thomasville",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31758,
      "latitude": 30.868187,
      "longitude": -83.927785,
      "city": "Thomasville",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31759,
      "latitude": 30.985082,
      "longitude": -84.834731,
      "city": "Iron City",
      "state": "GA",
      "county": "Seminole"
  },
  {
      "zipCode": 31760,
      "latitude": 31.706283,
      "longitude": -83.408633,
      "city": "Irwinville",
      "state": "GA",
      "county": "Irwin"
  },
  {
      "zipCode": 31761,
      "latitude": 31.089377,
      "longitude": -84.986113,
      "city": "Jakin",
      "state": "GA",
      "county": "Early"
  },
  {
      "zipCode": 31762,
      "latitude": 31.484266,
      "longitude": -84.513659,
      "city": "Leary",
      "state": "GA",
      "county": "Calhoun"
  },
  {
      "zipCode": 31763,
      "latitude": 31.763715,
      "longitude": -84.160359,
      "city": "Leesburg",
      "state": "GA",
      "county": "Lee"
  },
  {
      "zipCode": 31764,
      "latitude": 32.000148,
      "longitude": -84.088354,
      "city": "Leslie",
      "state": "GA",
      "county": "Sumter"
  },
  {
      "zipCode": 31765,
      "latitude": 31.021859,
      "longitude": -84.061389,
      "city": "Meigs",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31766,
      "latitude": 31.53796,
      "longitude": -84.611559,
      "city": "Morgan",
      "state": "GA",
      "county": "Calhoun"
  },
  {
      "zipCode": 31767,
      "latitude": 31.88141,
      "longitude": -85.024468,
      "city": "Morris",
      "state": "GA",
      "county": "Quitman"
  },
  {
      "zipCode": 31768,
      "latitude": 31.183438,
      "longitude": -83.789698,
      "city": "Moultrie",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31769,
      "latitude": 31.620866,
      "longitude": -83.249754,
      "city": "Mystic",
      "state": "GA",
      "county": "Irwin"
  },
  {
      "zipCode": 31770,
      "latitude": 31.37168,
      "longitude": -84.338325,
      "city": "Newton",
      "state": "GA",
      "county": "Baker"
  },
  {
      "zipCode": 31771,
      "latitude": 31.246179,
      "longitude": -83.654904,
      "city": "Norman Park",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31772,
      "latitude": 31.726629,
      "longitude": -83.959728,
      "city": "Oakfield",
      "state": "GA",
      "county": "Worth"
  },
  {
      "zipCode": 31773,
      "latitude": 30.968592,
      "longitude": -84.02725,
      "city": "Ochlocknee",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31774,
      "latitude": 31.571754,
      "longitude": -83.269632,
      "city": "Ocilla",
      "state": "GA",
      "county": "Irwin"
  },
  {
      "zipCode": 31775,
      "latitude": 31.359156,
      "longitude": -83.59029,
      "city": "Omega",
      "state": "GA",
      "county": "Tift"
  },
  {
      "zipCode": 31776,
      "latitude": 31.172696,
      "longitude": -83.792379,
      "city": "Moultrie",
      "state": "GA",
      "county": "Colquitt"
  },
  {
      "zipCode": 31777,
      "latitude": 31.911689,
      "longitude": -84.506058,
      "city": "Parrott",
      "state": "GA",
      "county": "Terrell"
  },
  {
      "zipCode": 31778,
      "latitude": 30.869228,
      "longitude": -83.900766,
      "city": "Pavo",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31779,
      "latitude": 31.225572,
      "longitude": -84.223371,
      "city": "Pelham",
      "state": "GA",
      "county": "Mitchell"
  },
  {
      "zipCode": 31780,
      "latitude": 32.027663,
      "longitude": -84.37249,
      "city": "Plains",
      "state": "GA",
      "county": "Sumter"
  },
  {
      "zipCode": 31781,
      "latitude": 31.538397,
      "longitude": -83.784347,
      "city": "Poulan",
      "state": "GA",
      "county": "Worth"
  },
  {
      "zipCode": 31782,
      "latitude": 31.543065,
      "longitude": -84.219594,
      "city": "Putney",
      "state": "GA",
      "county": "Dougherty"
  },
  {
      "zipCode": 31783,
      "latitude": 31.780373,
      "longitude": -83.526041,
      "city": "Rebecca",
      "state": "GA",
      "county": "Turner"
  },
  {
      "zipCode": 31784,
      "latitude": 31.27433,
      "longitude": -84.047656,
      "city": "Sale City",
      "state": "GA",
      "county": "Mitchell"
  },
  {
      "zipCode": 31785,
      "latitude": 31.689973,
      "longitude": -84.32748,
      "city": "Sasser",
      "state": "GA",
      "county": "Terrell"
  },
  {
      "zipCode": 31786,
      "latitude": 31.760361,
      "longitude": -84.614914,
      "city": "Shellman",
      "state": "GA",
      "county": "Randolph"
  },
  {
      "zipCode": 31787,
      "latitude": 31.772073,
      "longitude": -84.222541,
      "city": "Smithville",
      "state": "GA",
      "county": "Lee"
  },
  {
      "zipCode": 31789,
      "latitude": 31.481811,
      "longitude": -83.727288,
      "city": "Sumner",
      "state": "GA",
      "county": "Worth"
  },
  {
      "zipCode": 31790,
      "latitude": 31.652867,
      "longitude": -83.578336,
      "city": "Sycamore",
      "state": "GA",
      "county": "Turner"
  },
  {
      "zipCode": 31791,
      "latitude": 31.603336,
      "longitude": -83.850054,
      "city": "Sylvester",
      "state": "GA",
      "county": "Worth"
  },
  {
      "zipCode": 31792,
      "latitude": 30.825551,
      "longitude": -83.950795,
      "city": "Thomasville",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31793,
      "latitude": 31.485438,
      "longitude": -83.503621,
      "city": "Tifton",
      "state": "GA",
      "county": "Tift"
  },
  {
      "zipCode": 31794,
      "latitude": 31.463918,
      "longitude": -83.499944,
      "city": "Tifton",
      "state": "GA",
      "county": "Tift"
  },
  {
      "zipCode": 31795,
      "latitude": 31.475155,
      "longitude": -83.592123,
      "city": "Ty Ty",
      "state": "GA",
      "county": "Tift"
  },
  {
      "zipCode": 31796,
      "latitude": 31.762219,
      "longitude": -83.876179,
      "city": "Warwick",
      "state": "GA",
      "county": "Worth"
  },
  {
      "zipCode": 31797,
      "latitude": 30.901959,
      "longitude": -84.311493,
      "city": "Whigham",
      "state": "GA",
      "county": "Grady"
  },
  {
      "zipCode": 31798,
      "latitude": 31.571849,
      "longitude": -83.087813,
      "city": "Wray",
      "state": "GA",
      "county": "Irwin"
  },
  {
      "zipCode": 31799,
      "latitude": 30.880968,
      "longitude": -83.885902,
      "city": "Thomasville",
      "state": "GA",
      "county": "Thomas"
  },
  {
      "zipCode": 31801,
      "latitude": 32.701117,
      "longitude": -84.494946,
      "city": "Box Springs",
      "state": "GA",
      "county": "Talbot"
  },
  {
      "zipCode": 31803,
      "latitude": 32.254152,
      "longitude": -84.489818,
      "city": "Buena Vista",
      "state": "GA",
      "county": "Marion"
  },
  {
      "zipCode": 31804,
      "latitude": 32.661625,
      "longitude": -84.855447,
      "city": "Cataula",
      "state": "GA",
      "county": "Harris"
  },
  {
      "zipCode": 31805,
      "latitude": 32.292547,
      "longitude": -84.736706,
      "city": "Cusseta",
      "state": "GA",
      "county": "Chattahoochee"
  },
  {
      "zipCode": 31806,
      "latitude": 32.209028,
      "longitude": -84.277064,
      "city": "Ellaville",
      "state": "GA",
      "county": "Schley"
  },
  {
      "zipCode": 31807,
      "latitude": 32.631243,
      "longitude": -84.789524,
      "city": "Ellerslie",
      "state": "GA",
      "county": "Harris"
  },
  {
      "zipCode": 31808,
      "latitude": 32.563874,
      "longitude": -84.991501,
      "city": "Fortson",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31810,
      "latitude": 32.701117,
      "longitude": -84.494946,
      "city": "Geneva",
      "state": "GA",
      "county": "Talbot"
  },
  {
      "zipCode": 31811,
      "latitude": 32.746007,
      "longitude": -84.934906,
      "city": "Hamilton",
      "state": "GA",
      "county": "Harris"
  },
  {
      "zipCode": 31812,
      "latitude": 32.701117,
      "longitude": -84.494946,
      "city": "Junction City",
      "state": "GA",
      "county": "Talbot"
  },
  {
      "zipCode": 31814,
      "latitude": 32.07663,
      "longitude": -84.849724,
      "city": "Louvale",
      "state": "GA",
      "county": "Stewart"
  },
  {
      "zipCode": 31815,
      "latitude": 32.069538,
      "longitude": -84.728093,
      "city": "Lumpkin",
      "state": "GA",
      "county": "Stewart"
  },
  {
      "zipCode": 31816,
      "latitude": 32.91107,
      "longitude": -84.615478,
      "city": "Manchester",
      "state": "GA",
      "county": "Meriwether"
  },
  {
      "zipCode": 31820,
      "latitude": 32.565782,
      "longitude": -84.850953,
      "city": "Midland",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31821,
      "latitude": 32.07663,
      "longitude": -84.849724,
      "city": "Omaha",
      "state": "GA",
      "county": "Stewart"
  },
  {
      "zipCode": 31822,
      "latitude": 32.834241,
      "longitude": -84.904638,
      "city": "Pine Mountain",
      "state": "GA",
      "county": "Harris"
  },
  {
      "zipCode": 31823,
      "latitude": 32.822628,
      "longitude": -84.820364,
      "city": "Pine Mountain Valley",
      "state": "GA",
      "county": "Harris"
  },
  {
      "zipCode": 31824,
      "latitude": 32.076133,
      "longitude": -84.543517,
      "city": "Preston",
      "state": "GA",
      "county": "Webster"
  },
  {
      "zipCode": 31825,
      "latitude": 32.074331,
      "longitude": -84.718627,
      "city": "Richland",
      "state": "GA",
      "county": "Stewart"
  },
  {
      "zipCode": 31826,
      "latitude": 32.791278,
      "longitude": -84.733595,
      "city": "Shiloh",
      "state": "GA",
      "county": "Harris"
  },
  {
      "zipCode": 31827,
      "latitude": 32.673738,
      "longitude": -84.537989,
      "city": "Talbotton",
      "state": "GA",
      "county": "Talbot"
  },
  {
      "zipCode": 31829,
      "latitude": 32.564771,
      "longitude": -84.733865,
      "city": "Upatoi",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31830,
      "latitude": 32.906319,
      "longitude": -84.716723,
      "city": "Warm Springs",
      "state": "GA",
      "county": "Meriwether"
  },
  {
      "zipCode": 31831,
      "latitude": 32.738687,
      "longitude": -84.78546,
      "city": "Waverly Hall",
      "state": "GA",
      "county": "Harris"
  },
  {
      "zipCode": 31832,
      "latitude": 32.076133,
      "longitude": -84.543517,
      "city": "Weston",
      "state": "GA",
      "county": "Webster"
  },
  {
      "zipCode": 31833,
      "latitude": 32.907328,
      "longitude": -85.077535,
      "city": "West Point",
      "state": "GA",
      "county": "Troup"
  },
  {
      "zipCode": 31836,
      "latitude": 32.790007,
      "longitude": -84.56542,
      "city": "Woodland",
      "state": "GA",
      "county": "Talbot"
  },
  {
      "zipCode": 31901,
      "latitude": 32.472864,
      "longitude": -84.97823,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31902,
      "latitude": 32.524252,
      "longitude": -84.955843,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31903,
      "latitude": 32.41851,
      "longitude": -84.934344,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31904,
      "latitude": 32.534374,
      "longitude": -84.99698,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31905,
      "latitude": 32.392337,
      "longitude": -84.931457,
      "city": "Fort Benning",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31906,
      "latitude": 32.493455,
      "longitude": -84.95328,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31907,
      "latitude": 32.483084,
      "longitude": -84.914976,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31908,
      "latitude": 32.534852,
      "longitude": -84.906483,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31909,
      "latitude": 32.542198,
      "longitude": -84.920187,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31914,
      "latitude": 32.491005,
      "longitude": -84.874123,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31917,
      "latitude": 32.491005,
      "longitude": -84.874123,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31993,
      "latitude": 32.482142,
      "longitude": -84.977131,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31994,
      "latitude": 32.491005,
      "longitude": -84.874123,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31995,
      "latitude": 32.495841,
      "longitude": -84.963981,
      "city": "Fort Benning",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31997,
      "latitude": 32.491005,
      "longitude": -84.874123,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31998,
      "latitude": 32.491005,
      "longitude": -84.874123,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 31999,
      "latitude": 32.491005,
      "longitude": -84.874123,
      "city": "Columbus",
      "state": "GA",
      "county": "Muscogee"
  },
  {
      "zipCode": 32003,
      "latitude": 30.095584,
      "longitude": -81.710162,
      "city": "Orange Park",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32004,
      "latitude": 29.937673,
      "longitude": -81.420603,
      "city": "Ponte Vedra Beach",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32007,
      "latitude": 29.799631,
      "longitude": -81.627324,
      "city": "Bostwick",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32008,
      "latitude": 30.101927,
      "longitude": -82.908004,
      "city": "Branford",
      "state": "FL",
      "county": "Suwannee"
  },
  {
      "zipCode": 32009,
      "latitude": 30.521109,
      "longitude": -81.906051,
      "city": "Bryceville",
      "state": "FL",
      "county": "Nassau"
  },
  {
      "zipCode": 32011,
      "latitude": 30.556322,
      "longitude": -81.837594,
      "city": "Callahan",
      "state": "FL",
      "county": "Nassau"
  },
  {
      "zipCode": 32013,
      "latitude": 30.041449,
      "longitude": -83.123055,
      "city": "Day",
      "state": "FL",
      "county": "Lafayette"
  },
  {
      "zipCode": 32024,
      "latitude": 30.105451,
      "longitude": -82.68778,
      "city": "Lake City",
      "state": "FL",
      "county": "Columbia"
  },
  {
      "zipCode": 32025,
      "latitude": 30.160115,
      "longitude": -82.639606,
      "city": "Lake City",
      "state": "FL",
      "county": "Columbia"
  },
  {
      "zipCode": 32030,
      "latitude": 30.105553,
      "longitude": -81.768964,
      "city": "Doctors Inlet",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32033,
      "latitude": 29.813208,
      "longitude": -81.468724,
      "city": "Elkton",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32034,
      "latitude": 30.60778,
      "longitude": -81.682889,
      "city": "Fernandina Beach",
      "state": "FL",
      "county": "Nassau"
  },
  {
      "zipCode": 32035,
      "latitude": 30.551558,
      "longitude": -81.698422,
      "city": "Fernandina Beach",
      "state": "FL",
      "county": "Nassau"
  },
  {
      "zipCode": 32038,
      "latitude": 30.211903,
      "longitude": -82.626332,
      "city": "Fort White",
      "state": "FL",
      "county": "Columbia"
  },
  {
      "zipCode": 32040,
      "latitude": 30.302616,
      "longitude": -82.190828,
      "city": "Glen Saint Mary",
      "state": "FL",
      "county": "Baker"
  },
  {
      "zipCode": 32041,
      "latitude": 30.6233,
      "longitude": -81.590249,
      "city": "Yulee",
      "state": "FL",
      "county": "Nassau"
  },
  {
      "zipCode": 32042,
      "latitude": 29.968926,
      "longitude": -82.122553,
      "city": "Graham",
      "state": "FL",
      "county": "Bradford"
  },
  {
      "zipCode": 32043,
      "latitude": 29.998318,
      "longitude": -81.76468,
      "city": "Green Cove Springs",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32044,
      "latitude": 29.862285,
      "longitude": -82.143297,
      "city": "Hampton",
      "state": "FL",
      "county": "Bradford"
  },
  {
      "zipCode": 32046,
      "latitude": 30.670745,
      "longitude": -81.894528,
      "city": "Hilliard",
      "state": "FL",
      "county": "Nassau"
  },
  {
      "zipCode": 32050,
      "latitude": 30.031702,
      "longitude": -81.848401,
      "city": "Middleburg",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32052,
      "latitude": 30.495814,
      "longitude": -82.985428,
      "city": "Jasper",
      "state": "FL",
      "county": "Hamilton"
  },
  {
      "zipCode": 32053,
      "latitude": 30.555263,
      "longitude": -83.148179,
      "city": "Jennings",
      "state": "FL",
      "county": "Hamilton"
  },
  {
      "zipCode": 32054,
      "latitude": 30.007626,
      "longitude": -82.341422,
      "city": "Lake Butler",
      "state": "FL",
      "county": "Union"
  },
  {
      "zipCode": 32055,
      "latitude": 30.155907,
      "longitude": -82.652551,
      "city": "Lake City",
      "state": "FL",
      "county": "Columbia"
  },
  {
      "zipCode": 32056,
      "latitude": 30.162772,
      "longitude": -82.651184,
      "city": "Lake City",
      "state": "FL",
      "county": "Columbia"
  },
  {
      "zipCode": 32058,
      "latitude": 30.047063,
      "longitude": -82.12691,
      "city": "Lawtey",
      "state": "FL",
      "county": "Bradford"
  },
  {
      "zipCode": 32059,
      "latitude": 30.498591,
      "longitude": -83.361506,
      "city": "Lee",
      "state": "FL",
      "county": "Madison"
  },
  {
      "zipCode": 32060,
      "latitude": 30.175865,
      "longitude": -83.030438,
      "city": "Live Oak",
      "state": "FL",
      "county": "Suwannee"
  },
  {
      "zipCode": 32061,
      "latitude": 30.211903,
      "longitude": -82.626332,
      "city": "Lulu",
      "state": "FL",
      "county": "Columbia"
  },
  {
      "zipCode": 32062,
      "latitude": 30.186428,
      "longitude": -82.983576,
      "city": "Mc Alpin",
      "state": "FL",
      "county": "Suwannee"
  },
  {
      "zipCode": 32063,
      "latitude": 30.284628,
      "longitude": -82.160139,
      "city": "Macclenny",
      "state": "FL",
      "county": "Baker"
  },
  {
      "zipCode": 32064,
      "latitude": 30.331517,
      "longitude": -82.957151,
      "city": "Live Oak",
      "state": "FL",
      "county": "Suwannee"
  },
  {
      "zipCode": 32065,
      "latitude": 29.986046,
      "longitude": -81.883212,
      "city": "Orange Park",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32066,
      "latitude": 30.148296,
      "longitude": -83.210982,
      "city": "Mayo",
      "state": "FL",
      "county": "Lafayette"
  },
  {
      "zipCode": 32067,
      "latitude": 29.956068,
      "longitude": -81.815115,
      "city": "Orange Park",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32068,
      "latitude": 30.078597,
      "longitude": -81.847794,
      "city": "Middleburg",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32071,
      "latitude": 30.036193,
      "longitude": -82.932228,
      "city": "O Brien",
      "state": "FL",
      "county": "Suwannee"
  },
  {
      "zipCode": 32072,
      "latitude": 30.36036,
      "longitude": -82.25418,
      "city": "Olustee",
      "state": "FL",
      "county": "Baker"
  },
  {
      "zipCode": 32073,
      "latitude": 30.119884,
      "longitude": -81.791546,
      "city": "Orange Park",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32079,
      "latitude": 29.984882,
      "longitude": -81.802221,
      "city": "Penney Farms",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32080,
      "latitude": 29.816985,
      "longitude": -81.265123,
      "city": "Saint Augustine",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32082,
      "latitude": 30.102212,
      "longitude": -81.382302,
      "city": "Ponte Vedra Beach",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32083,
      "latitude": 30.054956,
      "longitude": -82.213361,
      "city": "Raiford",
      "state": "FL",
      "county": "Union"
  },
  {
      "zipCode": 32084,
      "latitude": 29.849505,
      "longitude": -81.332552,
      "city": "Saint Augustine",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32085,
      "latitude": 29.937673,
      "longitude": -81.420603,
      "city": "Saint Augustine",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32086,
      "latitude": 29.775409,
      "longitude": -81.302246,
      "city": "Saint Augustine",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32087,
      "latitude": 30.291034,
      "longitude": -82.334017,
      "city": "Sanderson",
      "state": "FL",
      "county": "Baker"
  },
  {
      "zipCode": 32091,
      "latitude": 29.928149,
      "longitude": -82.179651,
      "city": "Starke",
      "state": "FL",
      "county": "Bradford"
  },
  {
      "zipCode": 32092,
      "latitude": 29.921998,
      "longitude": -81.504509,
      "city": "Saint Augustine",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32094,
      "latitude": 30.194651,
      "longitude": -82.857328,
      "city": "Wellborn",
      "state": "FL",
      "county": "Suwannee"
  },
  {
      "zipCode": 32095,
      "latitude": 29.983058,
      "longitude": -81.450583,
      "city": "Saint Augustine",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32096,
      "latitude": 30.386063,
      "longitude": -82.77389,
      "city": "White Springs",
      "state": "FL",
      "county": "Hamilton"
  },
  {
      "zipCode": 32097,
      "latitude": 30.659689,
      "longitude": -81.683649,
      "city": "Yulee",
      "state": "FL",
      "county": "Nassau"
  },
  {
      "zipCode": 32099,
      "latitude": 30.337538,
      "longitude": -81.768622,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32102,
      "latitude": 29.174011,
      "longitude": -81.575044,
      "city": "Astor",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32105,
      "latitude": 29.200508,
      "longitude": -81.406458,
      "city": "Barberville",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32110,
      "latitude": 29.402177,
      "longitude": -81.33425,
      "city": "Bunnell",
      "state": "FL",
      "county": "Flagler"
  },
  {
      "zipCode": 32111,
      "latitude": 29.060711,
      "longitude": -81.969048,
      "city": "Candler",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32112,
      "latitude": 29.511518,
      "longitude": -81.681387,
      "city": "Crescent City",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32113,
      "latitude": 29.411299,
      "longitude": -82.084651,
      "city": "Citra",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32114,
      "latitude": 29.146163,
      "longitude": -81.053368,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32115,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32116,
      "latitude": 29.10909,
      "longitude": -80.984344,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32117,
      "latitude": 29.188732,
      "longitude": -81.231384,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32118,
      "latitude": 29.208908,
      "longitude": -81.00227,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32119,
      "latitude": 29.150622,
      "longitude": -81.025223,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32120,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32121,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32122,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32123,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32124,
      "latitude": 29.14192,
      "longitude": -81.140241,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32125,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32126,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32127,
      "latitude": 29.113539,
      "longitude": -80.976485,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32128,
      "latitude": 29.100739,
      "longitude": -81.071829,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32129,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Port Orange",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32130,
      "latitude": 29.183646,
      "longitude": -81.329387,
      "city": "De Leon Springs",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32131,
      "latitude": 29.612208,
      "longitude": -81.699606,
      "city": "East Palatka",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32132,
      "latitude": 28.974136,
      "longitude": -80.92291,
      "city": "Edgewater",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32133,
      "latitude": 29.008805,
      "longitude": -81.909445,
      "city": "Eastlake Weir",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32134,
      "latitude": 29.390965,
      "longitude": -81.855082,
      "city": "Fort Mc Coy",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32135,
      "latitude": 29.466085,
      "longitude": -81.282815,
      "city": "Palm Coast",
      "state": "FL",
      "county": "Flagler"
  },
  {
      "zipCode": 32136,
      "latitude": 29.509845,
      "longitude": -81.18295,
      "city": "Flagler Beach",
      "state": "FL",
      "county": "Flagler"
  },
  {
      "zipCode": 32137,
      "latitude": 29.564391,
      "longitude": -81.201389,
      "city": "Palm Coast",
      "state": "FL",
      "county": "Flagler"
  },
  {
      "zipCode": 32138,
      "latitude": 29.699255,
      "longitude": -81.922362,
      "city": "Grandin",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32139,
      "latitude": 29.384185,
      "longitude": -81.618309,
      "city": "Georgetown",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32140,
      "latitude": 29.611384,
      "longitude": -81.73499,
      "city": "Florahome",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32141,
      "latitude": 28.933217,
      "longitude": -80.900037,
      "city": "Edgewater",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32142,
      "latitude": 29.466085,
      "longitude": -81.282815,
      "city": "Palm Coast",
      "state": "FL",
      "county": "Flagler"
  },
  {
      "zipCode": 32145,
      "latitude": 29.68875,
      "longitude": -81.406081,
      "city": "Hastings",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32147,
      "latitude": 29.657611,
      "longitude": -81.779652,
      "city": "Hollister",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32148,
      "latitude": 29.598959,
      "longitude": -81.762249,
      "city": "Interlachen",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32149,
      "latitude": 29.58203,
      "longitude": -81.744852,
      "city": "Interlachen",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32151,
      "latitude": 29.466085,
      "longitude": -81.282815,
      "city": "Flagler Beach",
      "state": "FL",
      "county": "Flagler"
  },
  {
      "zipCode": 32157,
      "latitude": 29.45817,
      "longitude": -81.591447,
      "city": "Lake Como",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32158,
      "latitude": 28.811078,
      "longitude": -81.653642,
      "city": "Lady Lake",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32159,
      "latitude": 28.923468,
      "longitude": -81.894367,
      "city": "Lady Lake",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32160,
      "latitude": 29.768321,
      "longitude": -81.990729,
      "city": "Lake Geneva",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32162,
      "latitude": 28.922305,
      "longitude": -81.999287,
      "city": "Lady Lake",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 32164,
      "latitude": 29.486141,
      "longitude": -81.204491,
      "city": "Palm Coast",
      "state": "FL",
      "county": "Flagler"
  },
  {
      "zipCode": 32168,
      "latitude": 28.951931,
      "longitude": -81.033705,
      "city": "New Smyrna Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32169,
      "latitude": 29.131714,
      "longitude": -81.133519,
      "city": "New Smyrna Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32170,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "New Smyrna Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32173,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Ormond Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32174,
      "latitude": 29.30766,
      "longitude": -81.177846,
      "city": "Ormond Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32175,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Ormond Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32176,
      "latitude": 29.335111,
      "longitude": -81.249977,
      "city": "Ormond Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32177,
      "latitude": 29.611093,
      "longitude": -81.785957,
      "city": "Palatka",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32178,
      "latitude": 29.644306,
      "longitude": -81.668593,
      "city": "Palatka",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32179,
      "latitude": 29.086882,
      "longitude": -81.905259,
      "city": "Ocklawaha",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32180,
      "latitude": 29.244623,
      "longitude": -81.471982,
      "city": "Pierson",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32181,
      "latitude": 29.539751,
      "longitude": -81.613183,
      "city": "Pomona Park",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32182,
      "latitude": 29.485625,
      "longitude": -81.958901,
      "city": "Orange Springs",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32183,
      "latitude": 29.059673,
      "longitude": -81.905068,
      "city": "Ocklawaha",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32185,
      "latitude": 29.736833,
      "longitude": -81.957967,
      "city": "Putnam Hall",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32187,
      "latitude": 29.59344,
      "longitude": -81.589937,
      "city": "San Mateo",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32189,
      "latitude": 29.536502,
      "longitude": -81.633608,
      "city": "Satsuma",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32190,
      "latitude": 29.28926,
      "longitude": -81.503244,
      "city": "Seville",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32192,
      "latitude": 29.326821,
      "longitude": -82.104632,
      "city": "Sparr",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32193,
      "latitude": 29.463627,
      "longitude": -81.640527,
      "city": "Welaka",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32195,
      "latitude": 29.006144,
      "longitude": -81.87785,
      "city": "Weirsdale",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32198,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Daytona Beach",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32201,
      "latitude": 30.389438,
      "longitude": -81.680806,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32202,
      "latitude": 30.328439,
      "longitude": -81.65602,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32203,
      "latitude": 30.32281,
      "longitude": -81.547044,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32204,
      "latitude": 30.323539,
      "longitude": -81.681068,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32205,
      "latitude": 30.330938,
      "longitude": -81.833074,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32206,
      "latitude": 30.352488,
      "longitude": -81.665918,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32207,
      "latitude": 30.290839,
      "longitude": -81.631465,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32208,
      "latitude": 30.391988,
      "longitude": -81.685569,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32209,
      "latitude": 30.347838,
      "longitude": -81.813774,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32210,
      "latitude": 30.274539,
      "longitude": -81.786671,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32211,
      "latitude": 30.347139,
      "longitude": -81.581959,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32212,
      "latitude": 30.213189,
      "longitude": -81.690016,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32214,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32215,
      "latitude": 30.240689,
      "longitude": -81.892982,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32216,
      "latitude": 30.28434,
      "longitude": -81.54074,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32217,
      "latitude": 30.24058,
      "longitude": -81.618814,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32218,
      "latitude": 30.47548,
      "longitude": -81.67602,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32219,
      "latitude": 30.378738,
      "longitude": -81.745368,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32220,
      "latitude": 30.337236,
      "longitude": -81.863176,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32221,
      "latitude": 30.259438,
      "longitude": -81.848561,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32222,
      "latitude": 30.218289,
      "longitude": -81.820628,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32223,
      "latitude": 30.166809,
      "longitude": -81.63425,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32224,
      "latitude": 30.24359,
      "longitude": -81.715976,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32225,
      "latitude": 30.331905,
      "longitude": -81.50091,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32226,
      "latitude": 30.479666,
      "longitude": -81.527063,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32227,
      "latitude": 30.38024,
      "longitude": -81.41602,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32228,
      "latitude": 30.38239,
      "longitude": -81.436859,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32229,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32230,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32231,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32232,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32233,
      "latitude": 30.35239,
      "longitude": -81.517761,
      "city": "Atlantic Beach",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32234,
      "latitude": 30.275711,
      "longitude": -81.968614,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32235,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32236,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32237,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32238,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32239,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32240,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville Beach",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32241,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32244,
      "latitude": 30.220302,
      "longitude": -81.747414,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32245,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32246,
      "latitude": 30.293273,
      "longitude": -81.50916,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32247,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32250,
      "latitude": 30.280143,
      "longitude": -81.416506,
      "city": "Jacksonville Beach",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32254,
      "latitude": 30.357888,
      "longitude": -81.674018,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32255,
      "latitude": 30.287041,
      "longitude": -81.389305,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32256,
      "latitude": 30.213733,
      "longitude": -81.539129,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32257,
      "latitude": 30.191737,
      "longitude": -81.603557,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32258,
      "latitude": 30.156591,
      "longitude": -81.560415,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32259,
      "latitude": 29.877289,
      "longitude": -81.561245,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32260,
      "latitude": 29.937673,
      "longitude": -81.420603,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Saint Johns"
  },
  {
      "zipCode": 32266,
      "latitude": 30.315978,
      "longitude": -81.408006,
      "city": "Neptune Beach",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32267,
      "latitude": 30.34494,
      "longitude": -81.683107,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32276,
      "latitude": 30.325739,
      "longitude": -81.659017,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32277,
      "latitude": 30.370439,
      "longitude": -81.586394,
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32290,
      "latitude": "",
      "longitude": "",
      "city": "Jacksonville",
      "state": "FL",
      "county": "Duval"
  },
  {
      "zipCode": 32301,
      "latitude": 30.418514,
      "longitude": -84.203379,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32302,
      "latitude": 30.479347,
      "longitude": -84.346204,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32303,
      "latitude": 30.535698,
      "longitude": -84.247698,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32304,
      "latitude": 30.437954,
      "longitude": -84.347459,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32305,
      "latitude": 30.130775,
      "longitude": -84.406629,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Wakulla"
  },
  {
      "zipCode": 32306,
      "latitude": 30.442499,
      "longitude": -84.29855,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32307,
      "latitude": 30.42565,
      "longitude": -84.287749,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32308,
      "latitude": 30.556205,
      "longitude": -84.176838,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32309,
      "latitude": 30.514599,
      "longitude": -84.188446,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32310,
      "latitude": 30.364812,
      "longitude": -84.460793,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32311,
      "latitude": 30.433478,
      "longitude": -84.135913,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32312,
      "latitude": 30.572171,
      "longitude": -84.21548,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32313,
      "latitude": 30.479347,
      "longitude": -84.346204,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32314,
      "latitude": 30.479347,
      "longitude": -84.346204,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32315,
      "latitude": 30.479347,
      "longitude": -84.346204,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32316,
      "latitude": 30.479347,
      "longitude": -84.346204,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32317,
      "latitude": 30.479347,
      "longitude": -84.346204,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32318,
      "latitude": 30.496479,
      "longitude": -84.326057,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32320,
      "latitude": 29.819333,
      "longitude": -84.852937,
      "city": "Apalachicola",
      "state": "FL",
      "county": "Franklin"
  },
  {
      "zipCode": 32321,
      "latitude": 30.419284,
      "longitude": -84.97804,
      "city": "Bristol",
      "state": "FL",
      "county": "Liberty"
  },
  {
      "zipCode": 32322,
      "latitude": 29.853775,
      "longitude": -84.735838,
      "city": "Carrabelle",
      "state": "FL",
      "county": "Franklin"
  },
  {
      "zipCode": 32323,
      "latitude": 29.882567,
      "longitude": -84.596417,
      "city": "Lanark Village",
      "state": "FL",
      "county": "Franklin"
  },
  {
      "zipCode": 32324,
      "latitude": 30.640548,
      "longitude": -84.780758,
      "city": "Chattahoochee",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32326,
      "latitude": 30.183372,
      "longitude": -84.34906,
      "city": "Crawfordville",
      "state": "FL",
      "county": "Wakulla"
  },
  {
      "zipCode": 32327,
      "latitude": 30.165498,
      "longitude": -84.430777,
      "city": "Crawfordville",
      "state": "FL",
      "county": "Wakulla"
  },
  {
      "zipCode": 32328,
      "latitude": 29.749548,
      "longitude": -84.816238,
      "city": "Eastpoint",
      "state": "FL",
      "county": "Franklin"
  },
  {
      "zipCode": 32329,
      "latitude": 29.745526,
      "longitude": -85.023947,
      "city": "Apalachicola",
      "state": "FL",
      "county": "Franklin"
  },
  {
      "zipCode": 32330,
      "latitude": 30.565334,
      "longitude": -84.735972,
      "city": "Greensboro",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32331,
      "latitude": 30.441092,
      "longitude": -83.516133,
      "city": "Greenville",
      "state": "FL",
      "county": "Madison"
  },
  {
      "zipCode": 32332,
      "latitude": 30.589232,
      "longitude": -84.564944,
      "city": "Gretna",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32333,
      "latitude": 30.591733,
      "longitude": -84.574009,
      "city": "Havana",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32334,
      "latitude": 30.288896,
      "longitude": -84.848444,
      "city": "Hosford",
      "state": "FL",
      "county": "Liberty"
  },
  {
      "zipCode": 32335,
      "latitude": 30.288896,
      "longitude": -84.848444,
      "city": "Sumatra",
      "state": "FL",
      "county": "Liberty"
  },
  {
      "zipCode": 32336,
      "latitude": 30.298333,
      "longitude": -83.900753,
      "city": "Lamont",
      "state": "FL",
      "county": "Jefferson"
  },
  {
      "zipCode": 32337,
      "latitude": 30.342161,
      "longitude": -83.840177,
      "city": "Lloyd",
      "state": "FL",
      "county": "Jefferson"
  },
  {
      "zipCode": 32340,
      "latitude": 30.527068,
      "longitude": -83.388742,
      "city": "Madison",
      "state": "FL",
      "county": "Madison"
  },
  {
      "zipCode": 32341,
      "latitude": 30.47756,
      "longitude": -83.391393,
      "city": "Madison",
      "state": "FL",
      "county": "Madison"
  },
  {
      "zipCode": 32343,
      "latitude": 30.565524,
      "longitude": -84.429835,
      "city": "Midway",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32344,
      "latitude": 30.504216,
      "longitude": -83.843279,
      "city": "Monticello",
      "state": "FL",
      "county": "Jefferson"
  },
  {
      "zipCode": 32345,
      "latitude": 30.342161,
      "longitude": -83.840177,
      "city": "Monticello",
      "state": "FL",
      "county": "Jefferson"
  },
  {
      "zipCode": 32346,
      "latitude": 30.021079,
      "longitude": -84.386837,
      "city": "Panacea",
      "state": "FL",
      "county": "Wakulla"
  },
  {
      "zipCode": 32347,
      "latitude": 29.931864,
      "longitude": -83.55146,
      "city": "Perry",
      "state": "FL",
      "county": "Taylor"
  },
  {
      "zipCode": 32348,
      "latitude": 29.966454,
      "longitude": -83.659371,
      "city": "Perry",
      "state": "FL",
      "county": "Taylor"
  },
  {
      "zipCode": 32350,
      "latitude": 30.584683,
      "longitude": -83.328781,
      "city": "Pinetta",
      "state": "FL",
      "county": "Madison"
  },
  {
      "zipCode": 32351,
      "latitude": 30.555675,
      "longitude": -84.63183,
      "city": "Quincy",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32352,
      "latitude": 30.665715,
      "longitude": -84.75363,
      "city": "Quincy",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32353,
      "latitude": 30.549676,
      "longitude": -84.606864,
      "city": "Quincy",
      "state": "FL",
      "county": "Gadsden"
  },
  {
      "zipCode": 32355,
      "latitude": 30.163062,
      "longitude": -84.208346,
      "city": "Saint Marks",
      "state": "FL",
      "county": "Wakulla"
  },
  {
      "zipCode": 32356,
      "latitude": 29.853852,
      "longitude": -83.442125,
      "city": "Salem",
      "state": "FL",
      "county": "Taylor"
  },
  {
      "zipCode": 32357,
      "latitude": 30.214672,
      "longitude": -83.711084,
      "city": "Shady Grove",
      "state": "FL",
      "county": "Taylor"
  },
  {
      "zipCode": 32358,
      "latitude": 30.141958,
      "longitude": -84.552965,
      "city": "Sopchoppy",
      "state": "FL",
      "county": "Wakulla"
  },
  {
      "zipCode": 32359,
      "latitude": 29.693191,
      "longitude": -83.37638,
      "city": "Steinhatchee",
      "state": "FL",
      "county": "Taylor"
  },
  {
      "zipCode": 32360,
      "latitude": 30.288896,
      "longitude": -84.848444,
      "city": "Telogia",
      "state": "FL",
      "county": "Liberty"
  },
  {
      "zipCode": 32361,
      "latitude": 30.342161,
      "longitude": -83.840177,
      "city": "Wacissa",
      "state": "FL",
      "county": "Jefferson"
  },
  {
      "zipCode": 32362,
      "latitude": 30.319263,
      "longitude": -84.267397,
      "city": "Woodville",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32395,
      "latitude": 30.479347,
      "longitude": -84.346204,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32399,
      "latitude": 30.449428,
      "longitude": -84.29095,
      "city": "Tallahassee",
      "state": "FL",
      "county": "Leon"
  },
  {
      "zipCode": 32401,
      "latitude": 30.1922,
      "longitude": -85.663027,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32402,
      "latitude": 30.234451,
      "longitude": -85.692035,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32403,
      "latitude": 30.086746,
      "longitude": -85.630745,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32404,
      "latitude": 30.14697,
      "longitude": -85.521142,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32405,
      "latitude": 30.205121,
      "longitude": -85.668892,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32406,
      "latitude": 30.234451,
      "longitude": -85.692035,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32407,
      "latitude": 30.200682,
      "longitude": -85.813624,
      "city": "Panama City Beach",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32408,
      "latitude": 30.165546,
      "longitude": -85.711603,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32409,
      "latitude": 30.311713,
      "longitude": -85.692309,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32410,
      "latitude": 29.939543,
      "longitude": -85.409602,
      "city": "Mexico Beach",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32411,
      "latitude": 30.234451,
      "longitude": -85.692035,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32412,
      "latitude": 30.234451,
      "longitude": -85.692035,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32413,
      "latitude": 30.169689,
      "longitude": -85.693681,
      "city": "Panama City Beach",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32417,
      "latitude": 30.243832,
      "longitude": -85.916988,
      "city": "Panama City",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32420,
      "latitude": 30.641158,
      "longitude": -85.375585,
      "city": "Alford",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32421,
      "latitude": 30.553171,
      "longitude": -85.171315,
      "city": "Altha",
      "state": "FL",
      "county": "Calhoun"
  },
  {
      "zipCode": 32422,
      "latitude": 30.705557,
      "longitude": -86.031448,
      "city": "Argyle",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32423,
      "latitude": 30.940432,
      "longitude": -85.063884,
      "city": "Bascom",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32424,
      "latitude": 30.442582,
      "longitude": -85.046048,
      "city": "Blountstown",
      "state": "FL",
      "county": "Calhoun"
  },
  {
      "zipCode": 32425,
      "latitude": 30.875532,
      "longitude": -85.684243,
      "city": "Bonifay",
      "state": "FL",
      "county": "Holmes"
  },
  {
      "zipCode": 32426,
      "latitude": 30.935644,
      "longitude": -85.346802,
      "city": "Campbellton",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32427,
      "latitude": 30.707368,
      "longitude": -85.793737,
      "city": "Caryville",
      "state": "FL",
      "county": "Washington"
  },
  {
      "zipCode": 32428,
      "latitude": 30.634834,
      "longitude": -85.577618,
      "city": "Chipley",
      "state": "FL",
      "county": "Washington"
  },
  {
      "zipCode": 32430,
      "latitude": 30.403984,
      "longitude": -85.161075,
      "city": "Clarksville",
      "state": "FL",
      "county": "Calhoun"
  },
  {
      "zipCode": 32431,
      "latitude": 30.801747,
      "longitude": -85.287774,
      "city": "Cottondale",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32432,
      "latitude": 30.715807,
      "longitude": -85.078384,
      "city": "Cypress",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32433,
      "latitude": 30.6393,
      "longitude": -86.145909,
      "city": "Defuniak Springs",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32434,
      "latitude": 30.762486,
      "longitude": -86.342683,
      "city": "Mossy Head",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32435,
      "latitude": 30.603777,
      "longitude": -86.12111,
      "city": "Defuniak Springs",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32437,
      "latitude": 30.451272,
      "longitude": -85.872398,
      "city": "Ebro",
      "state": "FL",
      "county": "Washington"
  },
  {
      "zipCode": 32438,
      "latitude": 30.45245,
      "longitude": -85.491959,
      "city": "Fountain",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32439,
      "latitude": 30.561269,
      "longitude": -86.1706,
      "city": "Freeport",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32440,
      "latitude": 30.882994,
      "longitude": -85.361113,
      "city": "Graceville",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32442,
      "latitude": 30.719031,
      "longitude": -85.083627,
      "city": "Grand Ridge",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32443,
      "latitude": 30.859204,
      "longitude": -85.14758,
      "city": "Greenwood",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32444,
      "latitude": 30.094456,
      "longitude": -85.537104,
      "city": "Lynn Haven",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32445,
      "latitude": 30.963462,
      "longitude": -85.187828,
      "city": "Malone",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32446,
      "latitude": 30.799574,
      "longitude": -85.229347,
      "city": "Marianna",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32447,
      "latitude": 30.760299,
      "longitude": -85.202225,
      "city": "Marianna",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32448,
      "latitude": 30.674946,
      "longitude": -85.212174,
      "city": "Marianna",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32449,
      "latitude": 30.403984,
      "longitude": -85.161075,
      "city": "Kinard",
      "state": "FL",
      "county": "Calhoun"
  },
  {
      "zipCode": 32452,
      "latitude": 30.849972,
      "longitude": -85.768022,
      "city": "Noma",
      "state": "FL",
      "county": "Holmes"
  },
  {
      "zipCode": 32454,
      "latitude": 30.603777,
      "longitude": -86.12111,
      "city": "Point Washington",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32455,
      "latitude": 30.845927,
      "longitude": -85.930198,
      "city": "Ponce De Leon",
      "state": "FL",
      "county": "Holmes"
  },
  {
      "zipCode": 32456,
      "latitude": 29.9323,
      "longitude": -85.235414,
      "city": "Port Saint Joe",
      "state": "FL",
      "county": "Gulf"
  },
  {
      "zipCode": 32457,
      "latitude": 29.902484,
      "longitude": -85.242169,
      "city": "Port Saint Joe",
      "state": "FL",
      "county": "Gulf"
  },
  {
      "zipCode": 32459,
      "latitude": 30.513484,
      "longitude": -86.160893,
      "city": "Santa Rosa Beach",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32460,
      "latitude": 30.777609,
      "longitude": -84.955871,
      "city": "Sneads",
      "state": "FL",
      "county": "Jackson"
  },
  {
      "zipCode": 32461,
      "latitude": 30.252531,
      "longitude": -85.942389,
      "city": "Rosemary Bch",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32462,
      "latitude": 30.591268,
      "longitude": -85.739452,
      "city": "Vernon",
      "state": "FL",
      "county": "Washington"
  },
  {
      "zipCode": 32463,
      "latitude": 30.639134,
      "longitude": -85.587862,
      "city": "Wausau",
      "state": "FL",
      "county": "Washington"
  },
  {
      "zipCode": 32464,
      "latitude": 30.872298,
      "longitude": -85.929742,
      "city": "Westville",
      "state": "FL",
      "county": "Holmes"
  },
  {
      "zipCode": 32465,
      "latitude": 30.025584,
      "longitude": -85.215173,
      "city": "Wewahitchka",
      "state": "FL",
      "county": "Gulf"
  },
  {
      "zipCode": 32466,
      "latitude": 30.383156,
      "longitude": -85.51506,
      "city": "Youngstown",
      "state": "FL",
      "county": "Bay"
  },
  {
      "zipCode": 32501,
      "latitude": 30.424838,
      "longitude": -87.256471,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32502,
      "latitude": 30.474288,
      "longitude": -87.225691,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32503,
      "latitude": 30.462646,
      "longitude": -87.216523,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32504,
      "latitude": 30.488696,
      "longitude": -87.214171,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32505,
      "latitude": 30.457046,
      "longitude": -87.257722,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32506,
      "latitude": 30.391835,
      "longitude": -87.348931,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32507,
      "latitude": 30.358927,
      "longitude": -87.380931,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32508,
      "latitude": 30.359464,
      "longitude": -87.312205,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32509,
      "latitude": 30.464324,
      "longitude": -87.340262,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32511,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32512,
      "latitude": 30.394327,
      "longitude": -87.299129,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32513,
      "latitude": 30.557064,
      "longitude": -87.259592,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32514,
      "latitude": 30.537068,
      "longitude": -87.241202,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32516,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32520,
      "latitude": 30.412377,
      "longitude": -87.203543,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32521,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32522,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32523,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32524,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32526,
      "latitude": 30.493102,
      "longitude": -87.359621,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32530,
      "latitude": 30.598564,
      "longitude": -87.031496,
      "city": "Bagdad",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32531,
      "latitude": 30.85525,
      "longitude": -86.674139,
      "city": "Baker",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32533,
      "latitude": 30.665519,
      "longitude": -87.350295,
      "city": "Cantonment",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32534,
      "latitude": 30.534235,
      "longitude": -87.292335,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32535,
      "latitude": 30.955907,
      "longitude": -87.364552,
      "city": "Century",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32536,
      "latitude": 30.713591,
      "longitude": -86.557478,
      "city": "Crestview",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32537,
      "latitude": 30.741965,
      "longitude": -86.655208,
      "city": "Milligan",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32538,
      "latitude": 30.970853,
      "longitude": -86.311093,
      "city": "Paxton",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32539,
      "latitude": 30.777294,
      "longitude": -86.483154,
      "city": "Crestview",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32540,
      "latitude": 30.661155,
      "longitude": -86.594479,
      "city": "Destin",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32541,
      "latitude": 30.582064,
      "longitude": -86.57345,
      "city": "Destin",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32542,
      "latitude": 30.539331,
      "longitude": -86.608737,
      "city": "Eglin Afb",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32544,
      "latitude": 30.422926,
      "longitude": -86.698525,
      "city": "Hurlburt Field",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32547,
      "latitude": 30.448726,
      "longitude": -86.62545,
      "city": "Fort Walton Beach",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32548,
      "latitude": 30.42057,
      "longitude": -86.628607,
      "city": "Fort Walton Beach",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32549,
      "latitude": 30.661155,
      "longitude": -86.594479,
      "city": "Fort Walton Beach",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32550,
      "latitude": 30.381433,
      "longitude": -86.345668,
      "city": "Destin",
      "state": "FL",
      "county": "Walton"
  },
  {
      "zipCode": 32559,
      "latitude": 30.356287,
      "longitude": -87.277307,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32560,
      "latitude": 30.581825,
      "longitude": -87.292918,
      "city": "Gonzalez",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32561,
      "latitude": 30.669276,
      "longitude": -87.060696,
      "city": "Gulf Breeze",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32562,
      "latitude": 30.659159,
      "longitude": -87.04972,
      "city": "Gulf Breeze",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32563,
      "latitude": 30.659159,
      "longitude": -87.04972,
      "city": "Gulf Breeze",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32564,
      "latitude": 30.741616,
      "longitude": -86.719764,
      "city": "Holt",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32565,
      "latitude": 30.88276,
      "longitude": -87.102495,
      "city": "Jay",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32566,
      "latitude": 30.427685,
      "longitude": -86.927118,
      "city": "Navarre",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32567,
      "latitude": 30.715985,
      "longitude": -86.512784,
      "city": "Laurel Hill",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32568,
      "latitude": 30.868646,
      "longitude": -87.453852,
      "city": "Mc David",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32569,
      "latitude": 30.659587,
      "longitude": -86.602385,
      "city": "Mary Esther",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32570,
      "latitude": 30.792885,
      "longitude": -86.985834,
      "city": "Milton",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32571,
      "latitude": 30.669808,
      "longitude": -87.179447,
      "city": "Milton",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32572,
      "latitude": 30.659159,
      "longitude": -87.04972,
      "city": "Milton",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32573,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32574,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32575,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32576,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32577,
      "latitude": 30.690231,
      "longitude": -87.385248,
      "city": "Molino",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32578,
      "latitude": 30.687036,
      "longitude": -86.566325,
      "city": "Niceville",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32579,
      "latitude": 30.581826,
      "longitude": -86.581628,
      "city": "Shalimar",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32580,
      "latitude": 30.50821,
      "longitude": -86.49706,
      "city": "Valparaiso",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32581,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32582,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32583,
      "latitude": 30.577638,
      "longitude": -86.966835,
      "city": "Milton",
      "state": "FL",
      "county": "Santa Rosa"
  },
  {
      "zipCode": 32588,
      "latitude": 30.661155,
      "longitude": -86.594479,
      "city": "Niceville",
      "state": "FL",
      "county": "Okaloosa"
  },
  {
      "zipCode": 32589,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32590,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32591,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32592,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32593,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32594,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32595,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32596,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32597,
      "latitude": 30.529739,
      "longitude": -87.275693,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32598,
      "latitude": 30.61428,
      "longitude": -87.275772,
      "city": "Pensacola",
      "state": "FL",
      "county": "Escambia"
  },
  {
      "zipCode": 32601,
      "latitude": 29.68041,
      "longitude": -82.345739,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32602,
      "latitude": 29.629887,
      "longitude": -82.396567,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32603,
      "latitude": 29.718807,
      "longitude": -82.353874,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32604,
      "latitude": 29.573293,
      "longitude": -82.397904,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32605,
      "latitude": 29.677899,
      "longitude": -82.466362,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32606,
      "latitude": 29.697133,
      "longitude": -82.403746,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32607,
      "latitude": 29.653042,
      "longitude": -82.440667,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32608,
      "latitude": 29.575246,
      "longitude": -82.407395,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32609,
      "latitude": 29.761371,
      "longitude": -82.398315,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32610,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32611,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32612,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32613,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32614,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32615,
      "latitude": 29.813456,
      "longitude": -82.472049,
      "city": "Alachua",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32616,
      "latitude": 29.792034,
      "longitude": -82.495964,
      "city": "Alachua",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32617,
      "latitude": 29.337795,
      "longitude": -82.082367,
      "city": "Anthony",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32618,
      "latitude": 29.569316,
      "longitude": -82.506445,
      "city": "Archer",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32619,
      "latitude": 29.749093,
      "longitude": -82.865081,
      "city": "Bell",
      "state": "FL",
      "county": "Gilchrist"
  },
  {
      "zipCode": 32621,
      "latitude": 29.431045,
      "longitude": -82.549823,
      "city": "Bronson",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32622,
      "latitude": 29.905008,
      "longitude": -82.298477,
      "city": "Brooker",
      "state": "FL",
      "county": "Bradford"
  },
  {
      "zipCode": 32625,
      "latitude": 29.188034,
      "longitude": -82.991541,
      "city": "Cedar Key",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32626,
      "latitude": 29.389639,
      "longitude": -82.834776,
      "city": "Chiefland",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32627,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32628,
      "latitude": 29.680616,
      "longitude": -83.089669,
      "city": "Cross City",
      "state": "FL",
      "county": "Dixie"
  },
  {
      "zipCode": 32631,
      "latitude": 29.730413,
      "longitude": -82.097095,
      "city": "Earleton",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32633,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Evinston",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32634,
      "latitude": 29.35089,
      "longitude": -82.27648,
      "city": "Fairfield",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32635,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32639,
      "latitude": 29.24467,
      "longitude": -82.740173,
      "city": "Gulf Hammock",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32640,
      "latitude": 29.642025,
      "longitude": -82.327515,
      "city": "Hawthorne",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32641,
      "latitude": 29.682383,
      "longitude": -82.201383,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32643,
      "latitude": 29.740704,
      "longitude": -82.366367,
      "city": "High Springs",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32644,
      "latitude": 29.460221,
      "longitude": -82.855334,
      "city": "Chiefland",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32648,
      "latitude": 29.538213,
      "longitude": -83.19261,
      "city": "Horseshoe Beach",
      "state": "FL",
      "county": "Dixie"
  },
  {
      "zipCode": 32653,
      "latitude": 29.772819,
      "longitude": -82.378158,
      "city": "Gainesville",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32654,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Island Grove",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32655,
      "latitude": 29.817457,
      "longitude": -82.60064,
      "city": "High Springs",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32656,
      "latitude": 29.813555,
      "longitude": -81.946556,
      "city": "Keystone Heights",
      "state": "FL",
      "county": "Clay"
  },
  {
      "zipCode": 32658,
      "latitude": 29.826139,
      "longitude": -82.416024,
      "city": "La Crosse",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32662,
      "latitude": 29.681312,
      "longitude": -82.353862,
      "city": "Lochloosa",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32663,
      "latitude": 29.342404,
      "longitude": -82.212649,
      "city": "Lowell",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32664,
      "latitude": 29.437972,
      "longitude": -82.229534,
      "city": "Mc Intosh",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32666,
      "latitude": 29.697588,
      "longitude": -81.97397,
      "city": "Melrose",
      "state": "FL",
      "county": "Putnam"
  },
  {
      "zipCode": 32667,
      "latitude": 29.565077,
      "longitude": -82.417182,
      "city": "Micanopy",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32668,
      "latitude": 29.328402,
      "longitude": -82.703766,
      "city": "Morriston",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32669,
      "latitude": 29.687249,
      "longitude": -82.564624,
      "city": "Newberry",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32680,
      "latitude": 29.669866,
      "longitude": -83.004951,
      "city": "Old Town",
      "state": "FL",
      "county": "Dixie"
  },
  {
      "zipCode": 32681,
      "latitude": 29.428088,
      "longitude": -82.24489,
      "city": "Orange Lake",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32683,
      "latitude": 29.310929,
      "longitude": -82.794469,
      "city": "Otter Creek",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32686,
      "latitude": 29.362674,
      "longitude": -82.254723,
      "city": "Reddick",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 32692,
      "latitude": 29.329518,
      "longitude": -83.139957,
      "city": "Suwannee",
      "state": "FL",
      "county": "Dixie"
  },
  {
      "zipCode": 32693,
      "latitude": 29.674633,
      "longitude": -82.811678,
      "city": "Trenton",
      "state": "FL",
      "county": "Gilchrist"
  },
  {
      "zipCode": 32694,
      "latitude": 29.7968,
      "longitude": -82.156228,
      "city": "Waldo",
      "state": "FL",
      "county": "Alachua"
  },
  {
      "zipCode": 32696,
      "latitude": 29.354149,
      "longitude": -82.635652,
      "city": "Williston",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 32697,
      "latitude": 29.931522,
      "longitude": -82.425518,
      "city": "Worthington Springs",
      "state": "FL",
      "county": "Union"
  },
  {
      "zipCode": 32701,
      "latitude": 28.666625,
      "longitude": -81.365039,
      "city": "Altamonte Springs",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32702,
      "latitude": 29.059052,
      "longitude": -81.581964,
      "city": "Altoona",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32703,
      "latitude": 28.635425,
      "longitude": -81.488843,
      "city": "Apopka",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32704,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Apopka",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32706,
      "latitude": 28.966351,
      "longitude": -81.237061,
      "city": "Cassadaga",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32707,
      "latitude": 28.661468,
      "longitude": -81.313989,
      "city": "Casselberry",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32708,
      "latitude": 28.690114,
      "longitude": -81.222547,
      "city": "Winter Springs",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32709,
      "latitude": 28.486379,
      "longitude": -81.009032,
      "city": "Christmas",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32710,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Clarcona",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32712,
      "latitude": 28.731322,
      "longitude": -81.537602,
      "city": "Apopka",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32713,
      "latitude": 28.889107,
      "longitude": -81.319985,
      "city": "Debary",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32714,
      "latitude": 28.688256,
      "longitude": -81.280369,
      "city": "Altamonte Springs",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32715,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Altamonte Springs",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32716,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Altamonte Springs",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32718,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Casselberry",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32719,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Winter Springs",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32720,
      "latitude": 29.07198,
      "longitude": -81.403355,
      "city": "Deland",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32721,
      "latitude": 28.997288,
      "longitude": -81.299521,
      "city": "Deland",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32722,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Glenwood",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32724,
      "latitude": 29.056227,
      "longitude": -81.096461,
      "city": "Deland",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32725,
      "latitude": 28.900274,
      "longitude": -81.245074,
      "city": "Deltona",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32726,
      "latitude": 28.710129,
      "longitude": -81.683696,
      "city": "Eustis",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32727,
      "latitude": 28.855517,
      "longitude": -81.674147,
      "city": "Eustis",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32728,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Deltona",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32730,
      "latitude": 28.651275,
      "longitude": -81.34184,
      "city": "Casselberry",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32732,
      "latitude": 28.751078,
      "longitude": -81.107884,
      "city": "Geneva",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32733,
      "latitude": 28.613308,
      "longitude": -81.258108,
      "city": "Goldenrod",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32735,
      "latitude": 28.904429,
      "longitude": -81.743634,
      "city": "Grand Island",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32736,
      "latitude": 28.910207,
      "longitude": -81.523516,
      "city": "Eustis",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32738,
      "latitude": 28.939718,
      "longitude": -81.233099,
      "city": "Deltona",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32739,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Deltona",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32744,
      "latitude": 28.988027,
      "longitude": -81.220829,
      "city": "Lake Helen",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32746,
      "latitude": 28.7577,
      "longitude": -81.350772,
      "city": "Lake Mary",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32747,
      "latitude": 28.827219,
      "longitude": -81.332888,
      "city": "Lake Monroe",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32750,
      "latitude": 28.705924,
      "longitude": -81.340639,
      "city": "Longwood",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32751,
      "latitude": 28.625476,
      "longitude": -81.36459,
      "city": "Maitland",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32752,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Longwood",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32753,
      "latitude": 28.880381,
      "longitude": -81.30983,
      "city": "Debary",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32754,
      "latitude": 28.699314,
      "longitude": -80.895344,
      "city": "Mims",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32756,
      "latitude": 28.811078,
      "longitude": -81.653642,
      "city": "Mount Dora",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32757,
      "latitude": 28.773952,
      "longitude": -81.643943,
      "city": "Mount Dora",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32759,
      "latitude": 28.853312,
      "longitude": -80.993118,
      "city": "Oak Hill",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32762,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Oviedo",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32763,
      "latitude": 28.939904,
      "longitude": -81.301856,
      "city": "Orange City",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32764,
      "latitude": 28.854824,
      "longitude": -81.084188,
      "city": "Osteen",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32765,
      "latitude": 28.666574,
      "longitude": -81.207353,
      "city": "Oviedo",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32766,
      "latitude": 28.660674,
      "longitude": -81.113444,
      "city": "Oviedo",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32767,
      "latitude": 28.981525,
      "longitude": -81.482049,
      "city": "Paisley",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32768,
      "latitude": 28.698542,
      "longitude": -81.569764,
      "city": "Plymouth",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32771,
      "latitude": 28.793491,
      "longitude": -81.299169,
      "city": "Sanford",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32772,
      "latitude": 28.80722,
      "longitude": -81.250236,
      "city": "Sanford",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32773,
      "latitude": 28.754582,
      "longitude": -81.26418,
      "city": "Sanford",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32774,
      "latitude": 29.022729,
      "longitude": -81.172169,
      "city": "Orange City",
      "state": "FL",
      "county": "Volusia"
  },
  {
      "zipCode": 32775,
      "latitude": 28.77016,
      "longitude": -80.872042,
      "city": "Scottsmoor",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32776,
      "latitude": 28.831447,
      "longitude": -81.505121,
      "city": "Sorrento",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32777,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Tangerine",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32778,
      "latitude": 28.767762,
      "longitude": -81.725272,
      "city": "Tavares",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32779,
      "latitude": 28.72449,
      "longitude": -81.294452,
      "city": "Longwood",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32780,
      "latitude": 28.548877,
      "longitude": -80.853421,
      "city": "Titusville",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32781,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Titusville",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32782,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Titusville",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32783,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Titusville",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32784,
      "latitude": 28.925371,
      "longitude": -81.680144,
      "city": "Umatilla",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 32789,
      "latitude": 28.602327,
      "longitude": -81.35874,
      "city": "Winter Park",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32790,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Winter Park",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32791,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Longwood",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32792,
      "latitude": 28.597377,
      "longitude": -81.303592,
      "city": "Winter Park",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32793,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Winter Park",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32794,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Maitland",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32795,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Lake Mary",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32796,
      "latitude": 28.509529,
      "longitude": -80.831574,
      "city": "Titusville",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32798,
      "latitude": 28.717472,
      "longitude": -81.583045,
      "city": "Zellwood",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32799,
      "latitude": 28.744752,
      "longitude": -81.22328,
      "city": "Mid Florida",
      "state": "FL",
      "county": "Seminole"
  },
  {
      "zipCode": 32801,
      "latitude": 28.545179,
      "longitude": -81.373291,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32802,
      "latitude": 28.519024,
      "longitude": -81.343903,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32803,
      "latitude": 28.556479,
      "longitude": -81.33664,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32804,
      "latitude": 28.575428,
      "longitude": -81.395503,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32805,
      "latitude": 28.52823,
      "longitude": -81.408642,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32806,
      "latitude": 28.51103,
      "longitude": -81.357841,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32807,
      "latitude": 28.551479,
      "longitude": -81.30514,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32808,
      "latitude": 28.580344,
      "longitude": -81.439562,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32809,
      "latitude": 28.463682,
      "longitude": -81.394771,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32810,
      "latitude": 28.59965,
      "longitude": -81.433892,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32811,
      "latitude": 28.51633,
      "longitude": -81.451559,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32812,
      "latitude": 28.470392,
      "longitude": -81.386077,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32814,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32815,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Orlando",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32816,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32817,
      "latitude": 28.589077,
      "longitude": -81.227652,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32818,
      "latitude": 28.594747,
      "longitude": -81.496741,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32819,
      "latitude": 28.452157,
      "longitude": -81.46784,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32820,
      "latitude": 28.572527,
      "longitude": -81.121885,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32821,
      "latitude": 28.385285,
      "longitude": -81.472748,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32822,
      "latitude": 28.494385,
      "longitude": -81.29024,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32824,
      "latitude": 28.43522,
      "longitude": -81.349743,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32825,
      "latitude": 28.535094,
      "longitude": -81.220237,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32826,
      "latitude": 28.510802,
      "longitude": -81.34349,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32827,
      "latitude": 28.399634,
      "longitude": -81.290418,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32828,
      "latitude": 28.529582,
      "longitude": -81.175502,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32829,
      "latitude": 28.467101,
      "longitude": -81.241651,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32830,
      "latitude": 28.385549,
      "longitude": -81.505894,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32831,
      "latitude": 28.465553,
      "longitude": -81.151009,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32832,
      "latitude": 28.388834,
      "longitude": -81.175651,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32833,
      "latitude": 28.508779,
      "longitude": -81.070334,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32834,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32835,
      "latitude": 28.52038,
      "longitude": -81.483543,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32836,
      "latitude": 28.411534,
      "longitude": -81.525044,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32837,
      "latitude": 28.390234,
      "longitude": -81.433155,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32839,
      "latitude": 28.489931,
      "longitude": -81.406142,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32853,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32854,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32855,
      "latitude": 28.550028,
      "longitude": -81.104185,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32856,
      "latitude": 28.548379,
      "longitude": -81.420092,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32857,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32858,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32859,
      "latitude": 28.442926,
      "longitude": -81.402613,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32860,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32861,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32862,
      "latitude": 28.417368,
      "longitude": -81.332763,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32867,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32868,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32869,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32872,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32877,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32878,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32886,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32887,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32890,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32891,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32893,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32897,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32898,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Orlando",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 32899,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Orlando",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32901,
      "latitude": 28.012189,
      "longitude": -80.585519,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32902,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32903,
      "latitude": 28.107943,
      "longitude": -80.579625,
      "city": "Indialantic",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32904,
      "latitude": 28.051946,
      "longitude": -80.739647,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32905,
      "latitude": 27.96861,
      "longitude": -80.611642,
      "city": "Palm Bay",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32906,
      "latitude": 28.067128,
      "longitude": -80.650341,
      "city": "Palm Bay",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32907,
      "latitude": 28.017577,
      "longitude": -80.659539,
      "city": "Palm Bay",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32908,
      "latitude": 27.95504,
      "longitude": -80.692096,
      "city": "Palm Bay",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32909,
      "latitude": 27.933086,
      "longitude": -80.638504,
      "city": "Palm Bay",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32910,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Palm Bay",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32911,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Palm Bay",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32912,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32919,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32920,
      "latitude": 28.394642,
      "longitude": -80.614323,
      "city": "Cape Canaveral",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32922,
      "latitude": 28.315086,
      "longitude": -80.723445,
      "city": "Cocoa",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32923,
      "latitude": 28.427535,
      "longitude": -80.828991,
      "city": "Cocoa",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32924,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Cocoa",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32925,
      "latitude": 28.174349,
      "longitude": -80.583979,
      "city": "Patrick Afb",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32926,
      "latitude": 28.386561,
      "longitude": -80.799662,
      "city": "Cocoa",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32927,
      "latitude": 28.45663,
      "longitude": -80.797847,
      "city": "Cocoa",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32931,
      "latitude": 28.325685,
      "longitude": -80.623424,
      "city": "Cocoa Beach",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32932,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Cocoa Beach",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32934,
      "latitude": 28.088526,
      "longitude": -80.62782,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32935,
      "latitude": 28.210733,
      "longitude": -80.680583,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32936,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32937,
      "latitude": 28.167091,
      "longitude": -80.615835,
      "city": "Satellite Beach",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32940,
      "latitude": 28.216262,
      "longitude": -80.697829,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32941,
      "latitude": 27.924577,
      "longitude": -80.523463,
      "city": "Melbourne",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32948,
      "latitude": 27.775251,
      "longitude": -80.609403,
      "city": "Fellsmere",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32949,
      "latitude": 27.936416,
      "longitude": -80.555597,
      "city": "Grant",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32950,
      "latitude": 28.072093,
      "longitude": -80.596021,
      "city": "Malabar",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32951,
      "latitude": 27.953124,
      "longitude": -80.634,
      "city": "Melbourne Beach",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32952,
      "latitude": 28.276389,
      "longitude": -80.656825,
      "city": "Merritt Island",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32953,
      "latitude": 28.388797,
      "longitude": -80.730088,
      "city": "Merritt Island",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32954,
      "latitude": 28.22571,
      "longitude": -80.673356,
      "city": "Merritt Island",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32955,
      "latitude": 28.28714,
      "longitude": -80.723065,
      "city": "Rockledge",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32956,
      "latitude": 28.329785,
      "longitude": -80.732327,
      "city": "Rockledge",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32957,
      "latitude": 27.709049,
      "longitude": -80.572557,
      "city": "Roseland",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32958,
      "latitude": 27.799123,
      "longitude": -80.483098,
      "city": "Sebastian",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32959,
      "latitude": 28.306726,
      "longitude": -80.686159,
      "city": "Sharpes",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32960,
      "latitude": 27.638005,
      "longitude": -80.40294,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32961,
      "latitude": 27.617473,
      "longitude": -80.423083,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32962,
      "latitude": 27.658291,
      "longitude": -80.476926,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32963,
      "latitude": 27.689785,
      "longitude": -80.375726,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32964,
      "latitude": 27.709049,
      "longitude": -80.572557,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32965,
      "latitude": 27.709049,
      "longitude": -80.572557,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32966,
      "latitude": 27.697229,
      "longitude": -80.631131,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32967,
      "latitude": 27.696114,
      "longitude": -80.467458,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32968,
      "latitude": 27.588524,
      "longitude": -80.525331,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32969,
      "latitude": 27.709049,
      "longitude": -80.572557,
      "city": "Vero Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32970,
      "latitude": 27.752905,
      "longitude": -80.474297,
      "city": "Wabasso",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32971,
      "latitude": 27.709049,
      "longitude": -80.572557,
      "city": "Winter Beach",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 32976,
      "latitude": 27.867945,
      "longitude": -80.541647,
      "city": "Sebastian",
      "state": "FL",
      "county": "Brevard"
  },
  {
      "zipCode": 32978,
      "latitude": 27.709049,
      "longitude": -80.572557,
      "city": "Sebastian",
      "state": "FL",
      "county": "Indian River"
  },
  {
      "zipCode": 33001,
      "latitude": 24.83059,
      "longitude": -80.804872,
      "city": "Long Key",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33002,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33004,
      "latitude": 26.062416,
      "longitude": -80.159667,
      "city": "Dania",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33008,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Hallandale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33009,
      "latitude": 25.992118,
      "longitude": -80.146701,
      "city": "Hallandale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33010,
      "latitude": 25.829024,
      "longitude": -80.286355,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33011,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33012,
      "latitude": 25.859523,
      "longitude": -80.22387,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33013,
      "latitude": 25.860023,
      "longitude": -80.271571,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33014,
      "latitude": 25.910972,
      "longitude": -80.290572,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33015,
      "latitude": 25.909923,
      "longitude": -80.361224,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33016,
      "latitude": 25.891822,
      "longitude": -80.372824,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33017,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33018,
      "latitude": 25.909809,
      "longitude": -80.388874,
      "city": "Hialeah",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33019,
      "latitude": 26.091514,
      "longitude": -80.192966,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33020,
      "latitude": 26.022217,
      "longitude": -80.155017,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33021,
      "latitude": 26.023267,
      "longitude": -80.211942,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33022,
      "latitude": 26.013368,
      "longitude": -80.144217,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33023,
      "latitude": 25.996668,
      "longitude": -80.257444,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33024,
      "latitude": 26.029582,
      "longitude": -80.248916,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33025,
      "latitude": 26.142322,
      "longitude": -80.231595,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33026,
      "latitude": 26.153819,
      "longitude": -80.25357,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33027,
      "latitude": 26.039737,
      "longitude": -80.365429,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33028,
      "latitude": 26.004868,
      "longitude": -80.313445,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33029,
      "latitude": 26.097815,
      "longitude": -80.319019,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33030,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33031,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33032,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33033,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33034,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33035,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33036,
      "latitude": 24.90169,
      "longitude": -80.682667,
      "city": "Islamorada",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33037,
      "latitude": 24.963509,
      "longitude": -80.961294,
      "city": "Key Largo",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33039,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33040,
      "latitude": 24.655692,
      "longitude": -81.382372,
      "city": "Key West",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33041,
      "latitude": 25.100957,
      "longitude": -81.568862,
      "city": "Key West",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33042,
      "latitude": 24.860066,
      "longitude": -81.003803,
      "city": "Summerland Key",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33043,
      "latitude": 24.687493,
      "longitude": -81.249423,
      "city": "Big Pine Key",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33044,
      "latitude": 24.664649,
      "longitude": -81.565319,
      "city": "Sugarloaf Shores",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33045,
      "latitude": 25.100957,
      "longitude": -81.568862,
      "city": "Key West",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33050,
      "latitude": 24.687724,
      "longitude": -81.294685,
      "city": "Marathon",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33051,
      "latitude": 24.72339,
      "longitude": -81.020281,
      "city": "Key Colony Beach",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33052,
      "latitude": 24.723283,
      "longitude": -81.063249,
      "city": "Marathon Shores",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33054,
      "latitude": 25.924821,
      "longitude": -80.324273,
      "city": "Opa Locka",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33055,
      "latitude": 25.94762,
      "longitude": -80.277821,
      "city": "Opa Locka",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33056,
      "latitude": 25.94837,
      "longitude": -80.24792,
      "city": "Opa Locka",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33060,
      "latitude": 26.240059,
      "longitude": -80.139816,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33061,
      "latitude": 26.253909,
      "longitude": -80.134216,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33062,
      "latitude": 26.178661,
      "longitude": -80.144873,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33063,
      "latitude": 26.267436,
      "longitude": -80.209176,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33064,
      "latitude": 26.22116,
      "longitude": -80.125066,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33065,
      "latitude": 26.22131,
      "longitude": -80.26137,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33066,
      "latitude": 26.251906,
      "longitude": -80.165406,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33067,
      "latitude": 26.303307,
      "longitude": -80.241539,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33068,
      "latitude": 26.154262,
      "longitude": -80.208569,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33069,
      "latitude": 26.247379,
      "longitude": -80.182393,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33070,
      "latitude": 24.925108,
      "longitude": -80.713465,
      "city": "Tavernier",
      "state": "FL",
      "county": "Monroe"
  },
  {
      "zipCode": 33071,
      "latitude": 26.135692,
      "longitude": -80.253284,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33072,
      "latitude": 26.233459,
      "longitude": -80.092365,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33073,
      "latitude": 26.298352,
      "longitude": -80.180078,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33074,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33075,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33076,
      "latitude": 26.221054,
      "longitude": -80.313415,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33077,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33081,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33082,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Pembroke Pines",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33083,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33084,
      "latitude": 26.289057,
      "longitude": -80.129816,
      "city": "Hollywood",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33090,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33092,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Homestead",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33093,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33097,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Pompano Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33101,
      "latitude": 25.779076,
      "longitude": -80.19782,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33102,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33107,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33109,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami Beach",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33110,
      "latitude": 25.846874,
      "longitude": -80.20827,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33111,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33112,
      "latitude": 25.79685,
      "longitude": -80.384771,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33114,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33116,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33119,
      "latitude": 25.784526,
      "longitude": -80.131967,
      "city": "Miami Beach",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33121,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33122,
      "latitude": 25.800075,
      "longitude": -80.280972,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33124,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33125,
      "latitude": 25.783676,
      "longitude": -80.235421,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33126,
      "latitude": 25.790376,
      "longitude": -80.288372,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33127,
      "latitude": 25.813625,
      "longitude": -80.203169,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33128,
      "latitude": 25.775926,
      "longitude": -80.205019,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33129,
      "latitude": 25.754277,
      "longitude": -80.203519,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33130,
      "latitude": 25.807425,
      "longitude": -80.22952,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33131,
      "latitude": 25.759926,
      "longitude": -80.187519,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33132,
      "latitude": 25.782275,
      "longitude": -80.179407,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33133,
      "latitude": 25.737777,
      "longitude": -80.22477,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33134,
      "latitude": 25.767977,
      "longitude": -80.271372,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33135,
      "latitude": 25.767476,
      "longitude": -80.234971,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33136,
      "latitude": 25.783426,
      "longitude": -80.204469,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33137,
      "latitude": 25.814275,
      "longitude": -80.187219,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33138,
      "latitude": 25.852073,
      "longitude": -80.182118,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33139,
      "latitude": 25.787326,
      "longitude": -80.156368,
      "city": "Miami Beach",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33140,
      "latitude": 25.819825,
      "longitude": -80.133717,
      "city": "Miami Beach",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33141,
      "latitude": 25.848623,
      "longitude": -80.144617,
      "city": "Miami Beach",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33142,
      "latitude": 25.811575,
      "longitude": -80.236821,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33143,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33144,
      "latitude": 25.766427,
      "longitude": -80.304172,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33145,
      "latitude": 25.753877,
      "longitude": -80.22527,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33146,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33147,
      "latitude": 25.851523,
      "longitude": -80.23812,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33148,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33149,
      "latitude": 25.709978,
      "longitude": -80.173295,
      "city": "Key Biscayne",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33150,
      "latitude": 25.852273,
      "longitude": -80.207219,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33151,
      "latitude": 25.832074,
      "longitude": -80.20942,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33152,
      "latitude": 25.795476,
      "longitude": -80.312873,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33153,
      "latitude": 25.865523,
      "longitude": -80.193619,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33154,
      "latitude": 25.912671,
      "longitude": -80.195719,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33155,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33156,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33157,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33158,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33159,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33160,
      "latitude": 25.944859,
      "longitude": -80.139067,
      "city": "North Miami Beach",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33161,
      "latitude": 25.893422,
      "longitude": -80.175768,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33162,
      "latitude": 25.92862,
      "longitude": -80.182969,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33163,
      "latitude": 25.94497,
      "longitude": -80.21452,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33164,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33165,
      "latitude": 25.826996,
      "longitude": -80.319991,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33166,
      "latitude": 25.830124,
      "longitude": -80.292572,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33167,
      "latitude": 25.891872,
      "longitude": -80.22267,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33168,
      "latitude": 25.853667,
      "longitude": -80.174618,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33169,
      "latitude": 25.94262,
      "longitude": -80.214621,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33170,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33172,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33173,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33174,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33175,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33176,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33177,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33178,
      "latitude": 25.874473,
      "longitude": -80.350023,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33179,
      "latitude": 25.954336,
      "longitude": -80.182018,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33180,
      "latitude": 25.959719,
      "longitude": -80.140267,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33181,
      "latitude": 25.896522,
      "longitude": -80.156988,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33182,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33183,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33184,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33185,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33186,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33187,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33188,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33189,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33190,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33192,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33193,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33194,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33195,
      "latitude": 25.772876,
      "longitude": -80.187019,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33196,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33197,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33199,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33231,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33233,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33234,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33238,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33239,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami Beach",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33242,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33243,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33245,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33247,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33255,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33256,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33257,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33261,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33265,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33266,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33269,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33280,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33283,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33296,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33299,
      "latitude": 25.558428,
      "longitude": -80.458168,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": 33301,
      "latitude": 26.085115,
      "longitude": -80.159317,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33302,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33303,
      "latitude": 26.196911,
      "longitude": -80.095165,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33304,
      "latitude": 26.124513,
      "longitude": -80.120216,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33305,
      "latitude": 26.149662,
      "longitude": -80.122916,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33306,
      "latitude": 26.165612,
      "longitude": -80.111766,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33307,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33308,
      "latitude": 26.098414,
      "longitude": -80.182168,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33309,
      "latitude": 26.186109,
      "longitude": -80.179318,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33310,
      "latitude": 26.144263,
      "longitude": -80.206919,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33311,
      "latitude": 26.144303,
      "longitude": -80.183868,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33312,
      "latitude": 26.177411,
      "longitude": -80.209085,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33313,
      "latitude": 26.148662,
      "longitude": -80.207519,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33314,
      "latitude": 26.069666,
      "longitude": -80.224569,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33315,
      "latitude": 26.08329,
      "longitude": -80.162266,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33316,
      "latitude": 26.103114,
      "longitude": -80.123866,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33317,
      "latitude": 26.091765,
      "longitude": -80.286534,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33318,
      "latitude": 26.11842,
      "longitude": -80.251988,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33319,
      "latitude": 26.184761,
      "longitude": -80.24057,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33320,
      "latitude": 26.162462,
      "longitude": -80.25822,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33321,
      "latitude": 26.144589,
      "longitude": -80.26052,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33322,
      "latitude": 26.118845,
      "longitude": -80.331401,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33323,
      "latitude": 26.139766,
      "longitude": -80.359157,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33324,
      "latitude": 26.125463,
      "longitude": -80.264371,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33325,
      "latitude": 26.070747,
      "longitude": -80.24417,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33326,
      "latitude": 26.202757,
      "longitude": -80.281527,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33327,
      "latitude": 26.11363,
      "longitude": -80.41699,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33328,
      "latitude": 26.105514,
      "longitude": -80.255133,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33329,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33330,
      "latitude": 26.066316,
      "longitude": -80.333872,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33331,
      "latitude": 26.048032,
      "longitude": -80.374894,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33332,
      "latitude": 26.059583,
      "longitude": -80.414605,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33334,
      "latitude": 26.184511,
      "longitude": -80.134366,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33335,
      "latitude": 26.089215,
      "longitude": -80.335973,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33337,
      "latitude": 26.129013,
      "longitude": -80.260071,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33338,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33339,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33340,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33345,
      "latitude": 26.165424,
      "longitude": -80.29589,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33346,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33348,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33349,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33351,
      "latitude": 26.108554,
      "longitude": -80.266437,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33355,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33359,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33388,
      "latitude": 26.120864,
      "longitude": -80.25327,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33394,
      "latitude": 26.122063,
      "longitude": -80.138967,
      "city": "Fort Lauderdale",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33401,
      "latitude": 26.672643,
      "longitude": -80.070613,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33402,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33403,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33404,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33405,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33406,
      "latitude": 26.639594,
      "longitude": -80.082714,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33407,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33408,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "North Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33409,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33410,
      "latitude": 26.598446,
      "longitude": -80.054613,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33411,
      "latitude": 26.664443,
      "longitude": -80.174116,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33412,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33413,
      "latitude": 26.655494,
      "longitude": -80.159616,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33414,
      "latitude": 26.645659,
      "longitude": -80.25144,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33415,
      "latitude": 26.656044,
      "longitude": -80.125952,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33416,
      "latitude": 26.665394,
      "longitude": -80.092864,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33417,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33418,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33419,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33420,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33421,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33422,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "West Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33424,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boynton Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33425,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boynton Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33426,
      "latitude": 26.528233,
      "longitude": -80.084764,
      "city": "Boynton Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33427,
      "latitude": 26.375954,
      "longitude": -80.10717,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33428,
      "latitude": 26.348017,
      "longitude": -80.218991,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33429,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33430,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Belle Glade",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33431,
      "latitude": 26.378704,
      "longitude": -80.104343,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33432,
      "latitude": 26.384203,
      "longitude": -80.081114,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33433,
      "latitude": 26.350274,
      "longitude": -80.158367,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33434,
      "latitude": 26.381132,
      "longitude": -80.170567,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33435,
      "latitude": 26.518049,
      "longitude": -80.079114,
      "city": "Boynton Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33436,
      "latitude": 26.535402,
      "longitude": -80.112447,
      "city": "Boynton Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33437,
      "latitude": 26.513629,
      "longitude": -80.174297,
      "city": "Boynton Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33438,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Canal Point",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33439,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Bryant",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33440,
      "latitude": 26.630066,
      "longitude": -81.090897,
      "city": "Clewiston",
      "state": "FL",
      "county": "Hendry"
  },
  {
      "zipCode": 33441,
      "latitude": 26.273761,
      "longitude": -80.140769,
      "city": "Deerfield Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33442,
      "latitude": 26.301407,
      "longitude": -80.138516,
      "city": "Deerfield Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33443,
      "latitude": 26.145724,
      "longitude": -80.448254,
      "city": "Deerfield Beach",
      "state": "FL",
      "county": "Broward"
  },
  {
      "zipCode": 33444,
      "latitude": 26.457598,
      "longitude": -80.081614,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33445,
      "latitude": 26.548504,
      "longitude": -80.100015,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33446,
      "latitude": 26.454017,
      "longitude": -80.181862,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33447,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33448,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33454,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33455,
      "latitude": 27.050934,
      "longitude": -80.158594,
      "city": "Hobe Sound",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 33458,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Jupiter",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33459,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Lake Harbor",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33460,
      "latitude": 26.619627,
      "longitude": -80.059063,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33461,
      "latitude": 26.619546,
      "longitude": -80.091704,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33462,
      "latitude": 26.574736,
      "longitude": -80.079405,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33463,
      "latitude": 26.595537,
      "longitude": -80.129065,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33464,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33465,
      "latitude": 26.628272,
      "longitude": -80.132569,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33466,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33467,
      "latitude": 26.595867,
      "longitude": -80.22087,
      "city": "Lake Worth",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33468,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Jupiter",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33469,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Jupiter",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33470,
      "latitude": 26.649816,
      "longitude": -80.294771,
      "city": "Loxahatchee",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33471,
      "latitude": 26.886471,
      "longitude": -81.195575,
      "city": "Moore Haven",
      "state": "FL",
      "county": "Glades"
  },
  {
      "zipCode": 33474,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boynton Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33475,
      "latitude": 27.110182,
      "longitude": -80.454196,
      "city": "Hobe Sound",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 33476,
      "latitude": 26.623067,
      "longitude": -80.17864,
      "city": "Pahokee",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33477,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Jupiter",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33478,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Jupiter",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33480,
      "latitude": 26.600995,
      "longitude": -80.038113,
      "city": "Palm Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33481,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33482,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33483,
      "latitude": 26.464145,
      "longitude": -80.064844,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33484,
      "latitude": 26.454218,
      "longitude": -80.13473,
      "city": "Delray Beach",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33486,
      "latitude": 26.348293,
      "longitude": -80.117265,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33487,
      "latitude": 26.411642,
      "longitude": -80.09277,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33488,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33493,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "South Bay",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33496,
      "latitude": 26.409333,
      "longitude": -80.162567,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33497,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33498,
      "latitude": 26.39341,
      "longitude": -80.222751,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33499,
      "latitude": 26.645895,
      "longitude": -80.430269,
      "city": "Boca Raton",
      "state": "FL",
      "county": "Palm Beach"
  },
  {
      "zipCode": 33503,
      "latitude": 27.764761,
      "longitude": -82.273409,
      "city": "Balm",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33508,
      "latitude": 27.893618,
      "longitude": -82.296166,
      "city": "Brandon",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33509,
      "latitude": 28.119579,
      "longitude": -82.451959,
      "city": "Brandon",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33510,
      "latitude": 27.83083,
      "longitude": -82.356458,
      "city": "Brandon",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33511,
      "latitude": 27.903679,
      "longitude": -82.294021,
      "city": "Brandon",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33513,
      "latitude": 28.676288,
      "longitude": -82.149168,
      "city": "Bushnell",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 33514,
      "latitude": 28.668113,
      "longitude": -82.02883,
      "city": "Center Hill",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 33521,
      "latitude": 28.775537,
      "longitude": -82.059745,
      "city": "Coleman",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 33523,
      "latitude": 28.36886,
      "longitude": -82.322756,
      "city": "Dade City",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33524,
      "latitude": 28.182209,
      "longitude": -82.152341,
      "city": "Crystal Springs",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33525,
      "latitude": 28.331765,
      "longitude": -82.244624,
      "city": "Dade City",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33526,
      "latitude": 28.310134,
      "longitude": -82.247783,
      "city": "Dade City",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33527,
      "latitude": 27.975742,
      "longitude": -82.240415,
      "city": "Dover",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33530,
      "latitude": 27.906823,
      "longitude": -82.176749,
      "city": "Durant",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33534,
      "latitude": 27.911823,
      "longitude": -82.385728,
      "city": "Gibsonton",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33537,
      "latitude": 28.324796,
      "longitude": -82.481766,
      "city": "Lacoochee",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33538,
      "latitude": 28.781677,
      "longitude": -82.121086,
      "city": "Lake Panasoffkee",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 33539,
      "latitude": 28.21305,
      "longitude": -82.16568,
      "city": "Zephyrhills",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33540,
      "latitude": 28.240942,
      "longitude": -82.156491,
      "city": "Zephyrhills",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33541,
      "latitude": 28.240543,
      "longitude": -82.446251,
      "city": "Zephyrhills",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33543,
      "latitude": 28.20592,
      "longitude": -82.306326,
      "city": "Zephyrhills",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33544,
      "latitude": 28.271989,
      "longitude": -82.284738,
      "city": "Zephyrhills",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33547,
      "latitude": 27.893718,
      "longitude": -82.205331,
      "city": "Lithia",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33548,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Lutz",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33549,
      "latitude": 28.060825,
      "longitude": -82.391666,
      "city": "Lutz",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33550,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Mango",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33556,
      "latitude": 28.128688,
      "longitude": -82.584113,
      "city": "Odessa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33558,
      "latitude": 28.165344,
      "longitude": -82.510685,
      "city": "Lutz",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33559,
      "latitude": 28.160954,
      "longitude": -82.414663,
      "city": "Lutz",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33564,
      "latitude": 28.029627,
      "longitude": -82.134741,
      "city": "Plant City",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33565,
      "latitude": 28.082724,
      "longitude": -82.156607,
      "city": "Plant City",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33566,
      "latitude": 28.008056,
      "longitude": -82.341905,
      "city": "Plant City",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33567,
      "latitude": 27.980218,
      "longitude": -82.322484,
      "city": "Plant City",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33568,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Riverview",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33569,
      "latitude": 27.846419,
      "longitude": -82.301249,
      "city": "Riverview",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33570,
      "latitude": 27.69913,
      "longitude": -82.45263,
      "city": "Ruskin",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33571,
      "latitude": 27.720111,
      "longitude": -82.453041,
      "city": "Sun City Center",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33572,
      "latitude": 27.84579,
      "longitude": -82.331983,
      "city": "Apollo Beach",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33573,
      "latitude": 27.704046,
      "longitude": -82.35742,
      "city": "Sun City Center",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33574,
      "latitude": 28.334752,
      "longitude": -82.269323,
      "city": "Saint Leo",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33576,
      "latitude": 28.331729,
      "longitude": -82.300982,
      "city": "San Antonio",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33583,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Seffner",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33584,
      "latitude": 27.999687,
      "longitude": -82.287957,
      "city": "Seffner",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33585,
      "latitude": 28.735643,
      "longitude": -82.061556,
      "city": "Sumterville",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 33586,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Sun City",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33587,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Sydney",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33592,
      "latitude": 28.092775,
      "longitude": -82.278977,
      "city": "Thonotosassa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33593,
      "latitude": 28.324796,
      "longitude": -82.481766,
      "city": "Trilby",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 33594,
      "latitude": 27.937779,
      "longitude": -82.347371,
      "city": "Valrico",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33595,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Valrico",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33597,
      "latitude": 28.647306,
      "longitude": -82.108078,
      "city": "Webster",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 33598,
      "latitude": 27.73383,
      "longitude": -82.297468,
      "city": "Wimauma",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33601,
      "latitude": 27.996097,
      "longitude": -82.582035,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33602,
      "latitude": 27.950898,
      "longitude": -82.461517,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33603,
      "latitude": 27.986147,
      "longitude": -82.463804,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33604,
      "latitude": 28.014096,
      "longitude": -82.449816,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33605,
      "latitude": 27.946665,
      "longitude": -82.427265,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33606,
      "latitude": 27.930552,
      "longitude": -82.465326,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33607,
      "latitude": 27.966697,
      "longitude": -82.556868,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33608,
      "latitude": 27.84343,
      "longitude": -82.488413,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33609,
      "latitude": 27.942648,
      "longitude": -82.513067,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33610,
      "latitude": 27.995997,
      "longitude": -82.375733,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33611,
      "latitude": 27.942099,
      "longitude": -82.512017,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33612,
      "latitude": 28.054195,
      "longitude": -82.442683,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33613,
      "latitude": 28.077403,
      "longitude": -82.444209,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33614,
      "latitude": 28.031495,
      "longitude": -82.496367,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33615,
      "latitude": 28.039847,
      "longitude": -82.548749,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33616,
      "latitude": 27.867201,
      "longitude": -82.529567,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33617,
      "latitude": 28.030714,
      "longitude": -82.386351,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33618,
      "latitude": 28.076299,
      "longitude": -82.485188,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33619,
      "latitude": 27.92485,
      "longitude": -82.379447,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33620,
      "latitude": 28.059994,
      "longitude": -82.407887,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33621,
      "latitude": 27.849135,
      "longitude": -82.494582,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33622,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33623,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33624,
      "latitude": 28.074809,
      "longitude": -82.554404,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33625,
      "latitude": 28.072671,
      "longitude": -82.559501,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33626,
      "latitude": 28.066478,
      "longitude": -82.611596,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33629,
      "latitude": 27.921056,
      "longitude": -82.508867,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33630,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33631,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33633,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33634,
      "latitude": 28.002755,
      "longitude": -82.545466,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33635,
      "latitude": 28.026337,
      "longitude": -82.613819,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33637,
      "latitude": 28.047044,
      "longitude": -82.363024,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33647,
      "latitude": 28.127834,
      "longitude": -82.350338,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33650,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33651,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33655,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33660,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33661,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33662,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33663,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33664,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33672,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33673,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33674,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33675,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33677,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33679,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33680,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33681,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33682,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33684,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33685,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33686,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33687,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33688,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33689,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33690,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33694,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33697,
      "latitude": 27.871964,
      "longitude": -82.438841,
      "city": "Tampa",
      "state": "FL",
      "county": "Hillsborough"
  },
  {
      "zipCode": 33701,
      "latitude": 27.775654,
      "longitude": -82.64092,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33702,
      "latitude": 27.863201,
      "longitude": -82.631509,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33703,
      "latitude": 27.816003,
      "longitude": -82.62092,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33704,
      "latitude": 27.798053,
      "longitude": -82.63077,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33705,
      "latitude": 27.743555,
      "longitude": -82.64182,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33706,
      "latitude": 27.749529,
      "longitude": -82.754223,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33707,
      "latitude": 27.757005,
      "longitude": -82.726422,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33708,
      "latitude": 27.811608,
      "longitude": -82.80143,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33709,
      "latitude": 27.820082,
      "longitude": -82.730798,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33710,
      "latitude": 27.788853,
      "longitude": -82.699621,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33711,
      "latitude": 27.733448,
      "longitude": -82.690035,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33712,
      "latitude": 27.735925,
      "longitude": -82.672426,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33713,
      "latitude": 27.789403,
      "longitude": -82.677321,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33714,
      "latitude": 27.817335,
      "longitude": -82.68324,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33715,
      "latitude": 27.670536,
      "longitude": -82.711927,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33716,
      "latitude": 27.890727,
      "longitude": -82.692961,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33728,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33729,
      "latitude": 27.881909,
      "longitude": -82.664359,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33730,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33731,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33732,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33733,
      "latitude": 27.925755,
      "longitude": -82.75211,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33734,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33736,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33737,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33738,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33740,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33741,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33742,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33743,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33744,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Bay Pines",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33747,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33755,
      "latitude": 27.978147,
      "longitude": -82.781523,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33756,
      "latitude": 27.946998,
      "longitude": -82.794324,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33757,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33758,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33759,
      "latitude": 27.984851,
      "longitude": -82.704321,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33760,
      "latitude": 27.90045,
      "longitude": -82.715166,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33761,
      "latitude": 27.925501,
      "longitude": -82.725852,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33762,
      "latitude": 27.89417,
      "longitude": -82.674571,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33763,
      "latitude": 28.017296,
      "longitude": -82.746082,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33764,
      "latitude": 27.916038,
      "longitude": -82.734299,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33765,
      "latitude": 27.990179,
      "longitude": -82.743298,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33766,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33767,
      "latitude": 27.959847,
      "longitude": -82.828625,
      "city": "Clearwater Beach",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33769,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Clearwater",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33770,
      "latitude": 27.916998,
      "longitude": -82.802668,
      "city": "Largo",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33771,
      "latitude": 27.908479,
      "longitude": -82.756823,
      "city": "Largo",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33772,
      "latitude": 27.846601,
      "longitude": -82.795385,
      "city": "Seminole",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33773,
      "latitude": 27.88015,
      "longitude": -82.753373,
      "city": "Largo",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33774,
      "latitude": 27.883949,
      "longitude": -82.826525,
      "city": "Largo",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33775,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Seminole",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33776,
      "latitude": 27.850547,
      "longitude": -82.826267,
      "city": "Seminole",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33777,
      "latitude": 27.854588,
      "longitude": -82.754528,
      "city": "Seminole",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33778,
      "latitude": 27.88403,
      "longitude": -82.802466,
      "city": "Largo",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33779,
      "latitude": 27.839698,
      "longitude": -82.772483,
      "city": "Largo",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33780,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Pinellas Park",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33781,
      "latitude": 27.868101,
      "longitude": -82.758523,
      "city": "Pinellas Park",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33782,
      "latitude": 27.868115,
      "longitude": -82.708639,
      "city": "Pinellas Park",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33784,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Saint Petersburg",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33785,
      "latitude": 27.886757,
      "longitude": -82.843525,
      "city": "Indian Rocks Beach",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33786,
      "latitude": 27.922922,
      "longitude": -82.839325,
      "city": "Belleair Beach",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 33801,
      "latitude": 28.059997,
      "longitude": -81.956122,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33802,
      "latitude": 28.020992,
      "longitude": -81.985185,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33803,
      "latitude": 27.894389,
      "longitude": -81.850798,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33804,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33805,
      "latitude": 28.094746,
      "longitude": -81.947117,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33806,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33807,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33809,
      "latitude": 28.176194,
      "longitude": -81.959132,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33810,
      "latitude": 28.147923,
      "longitude": -82.037153,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33811,
      "latitude": 27.986538,
      "longitude": -82.013855,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33813,
      "latitude": 27.963896,
      "longitude": -81.917604,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33815,
      "latitude": 28.049648,
      "longitude": -82.006855,
      "city": "Lakeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33820,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Alturas",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33823,
      "latitude": 28.086751,
      "longitude": -81.742864,
      "city": "Auburndale",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33825,
      "latitude": 27.473059,
      "longitude": -81.464053,
      "city": "Avon Park",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33826,
      "latitude": 27.339483,
      "longitude": -81.252872,
      "city": "Avon Park",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33827,
      "latitude": 27.906571,
      "longitude": -81.549715,
      "city": "Babson Park",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33830,
      "latitude": 27.918445,
      "longitude": -81.790794,
      "city": "Bartow",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33831,
      "latitude": 27.955426,
      "longitude": -81.951673,
      "city": "Bartow",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33834,
      "latitude": 27.601936,
      "longitude": -81.85069,
      "city": "Bowling Green",
      "state": "FL",
      "county": "Hardee"
  },
  {
      "zipCode": 33835,
      "latitude": 27.699258,
      "longitude": -81.949419,
      "city": "Bradley",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33836,
      "latitude": 28.167211,
      "longitude": -81.631589,
      "city": "Davenport",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33837,
      "latitude": 28.164093,
      "longitude": -81.647317,
      "city": "Davenport",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33838,
      "latitude": 28.009099,
      "longitude": -81.677776,
      "city": "Dundee",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33839,
      "latitude": 28.005662,
      "longitude": -81.702448,
      "city": "Eagle Lake",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33840,
      "latitude": 28.084383,
      "longitude": -81.5415,
      "city": "Eaton Park",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33841,
      "latitude": 27.742463,
      "longitude": -81.723311,
      "city": "Fort Meade",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33843,
      "latitude": 27.72938,
      "longitude": -81.55934,
      "city": "Frostproof",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33844,
      "latitude": 28.075834,
      "longitude": -81.592933,
      "city": "Haines City",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33845,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Haines City",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33846,
      "latitude": 27.964651,
      "longitude": -81.867153,
      "city": "Highland City",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33847,
      "latitude": 27.984091,
      "longitude": -81.716682,
      "city": "Homeland",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33848,
      "latitude": 28.263489,
      "longitude": -81.512387,
      "city": "Intercession City",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 33849,
      "latitude": 28.197539,
      "longitude": -82.039587,
      "city": "Kathleen",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33850,
      "latitude": 28.092424,
      "longitude": -81.727551,
      "city": "Lake Alfred",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33851,
      "latitude": 28.036937,
      "longitude": -81.627998,
      "city": "Lake Hamilton",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33852,
      "latitude": 27.336002,
      "longitude": -81.36628,
      "city": "Lake Placid",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33853,
      "latitude": 27.991865,
      "longitude": -81.480831,
      "city": "Lake Wales",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33854,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Fedhaven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33855,
      "latitude": 27.798022,
      "longitude": -81.357154,
      "city": "Indian Lake Estates",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33856,
      "latitude": 27.855686,
      "longitude": -81.430915,
      "city": "Nalcrest",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33857,
      "latitude": 27.391447,
      "longitude": -81.228096,
      "city": "Lorida",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33858,
      "latitude": 28.050121,
      "longitude": -81.505211,
      "city": "Loughman",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33859,
      "latitude": 27.885359,
      "longitude": -81.522998,
      "city": "Lake Wales",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33860,
      "latitude": 27.844612,
      "longitude": -81.835864,
      "city": "Mulberry",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33862,
      "latitude": 27.244668,
      "longitude": -81.288424,
      "city": "Lake Placid",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33863,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Nichols",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33865,
      "latitude": 27.450538,
      "longitude": -81.899723,
      "city": "Ona",
      "state": "FL",
      "county": "Hardee"
  },
  {
      "zipCode": 33867,
      "latitude": 27.768567,
      "longitude": -81.19664,
      "city": "River Ranch",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33868,
      "latitude": 28.03668,
      "longitude": -81.760824,
      "city": "Polk City",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33870,
      "latitude": 27.457947,
      "longitude": -81.373012,
      "city": "Sebring",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33871,
      "latitude": 27.485803,
      "longitude": -81.407884,
      "city": "Sebring",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33872,
      "latitude": 27.393804,
      "longitude": -81.287076,
      "city": "Sebring",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33873,
      "latitude": 27.518602,
      "longitude": -81.784123,
      "city": "Wauchula",
      "state": "FL",
      "county": "Hardee"
  },
  {
      "zipCode": 33875,
      "latitude": 27.423426,
      "longitude": -81.500649,
      "city": "Sebring",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33876,
      "latitude": 27.425161,
      "longitude": -81.33921,
      "city": "Sebring",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33877,
      "latitude": 27.976883,
      "longitude": -81.614414,
      "city": "Waverly",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33880,
      "latitude": 27.987284,
      "longitude": -81.762456,
      "city": "Winter Haven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33881,
      "latitude": 28.06374,
      "longitude": -81.711071,
      "city": "Winter Haven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33882,
      "latitude": 28.029402,
      "longitude": -81.732139,
      "city": "Winter Haven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33883,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Winter Haven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33884,
      "latitude": 28.053322,
      "longitude": -81.668575,
      "city": "Winter Haven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33885,
      "latitude": 28.002553,
      "longitude": -81.61864,
      "city": "Winter Haven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33888,
      "latitude": 28.023115,
      "longitude": -81.723417,
      "city": "Winter Haven",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33890,
      "latitude": 27.457687,
      "longitude": -81.721609,
      "city": "Zolfo Springs",
      "state": "FL",
      "county": "Hardee"
  },
  {
      "zipCode": 33896,
      "latitude": 28.250175,
      "longitude": -81.58077,
      "city": "Davenport",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33897,
      "latitude": 28.285129,
      "longitude": -81.691024,
      "city": "Davenport",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33898,
      "latitude": 27.880552,
      "longitude": -81.317534,
      "city": "Lake Wales",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 33901,
      "latitude": 26.564355,
      "longitude": -81.925065,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33902,
      "latitude": 26.623908,
      "longitude": -81.883648,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33903,
      "latitude": 26.693048,
      "longitude": -81.912454,
      "city": "North Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33904,
      "latitude": 26.606491,
      "longitude": -81.95016,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33905,
      "latitude": 26.607784,
      "longitude": -81.832214,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33906,
      "latitude": 26.552895,
      "longitude": -81.94861,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33907,
      "latitude": 26.634845,
      "longitude": -81.959923,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33908,
      "latitude": 26.561955,
      "longitude": -81.911422,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33909,
      "latitude": 26.623638,
      "longitude": -81.968,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33910,
      "latitude": 26.552895,
      "longitude": -81.94861,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33911,
      "latitude": 26.596286,
      "longitude": -81.882373,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33912,
      "latitude": 26.563728,
      "longitude": -81.726965,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33913,
      "latitude": 26.529206,
      "longitude": -81.693572,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33914,
      "latitude": 26.622535,
      "longitude": -81.923974,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33915,
      "latitude": 26.659942,
      "longitude": -81.893427,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33916,
      "latitude": 26.635461,
      "longitude": -81.843807,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33917,
      "latitude": 26.648211,
      "longitude": -81.844716,
      "city": "North Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33918,
      "latitude": 26.71613,
      "longitude": -81.607007,
      "city": "North Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33919,
      "latitude": 26.572627,
      "longitude": -81.937186,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33920,
      "latitude": 26.595182,
      "longitude": -81.6815,
      "city": "Alva",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33921,
      "latitude": 26.754493,
      "longitude": -82.261149,
      "city": "Boca Grande",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33922,
      "latitude": 26.624433,
      "longitude": -82.128052,
      "city": "Bokeelia",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33924,
      "latitude": 26.521464,
      "longitude": -82.180242,
      "city": "Captiva",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33927,
      "latitude": 26.901981,
      "longitude": -82.000005,
      "city": "El Jobean",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33928,
      "latitude": 26.472274,
      "longitude": -81.705902,
      "city": "Estero",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33930,
      "latitude": 26.612772,
      "longitude": -81.479912,
      "city": "Felda",
      "state": "FL",
      "county": "Hendry"
  },
  {
      "zipCode": 33931,
      "latitude": 26.5761,
      "longitude": -82.071178,
      "city": "Fort Myers Beach",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33932,
      "latitude": 26.552895,
      "longitude": -81.94861,
      "city": "Fort Myers Beach",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33935,
      "latitude": 26.690881,
      "longitude": -81.448873,
      "city": "Labelle",
      "state": "FL",
      "county": "Hendry"
  },
  {
      "zipCode": 33936,
      "latitude": 26.593573,
      "longitude": -81.661922,
      "city": "Lehigh Acres",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33938,
      "latitude": 26.901981,
      "longitude": -82.000005,
      "city": "Murdock",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33944,
      "latitude": 26.946449,
      "longitude": -81.309114,
      "city": "Palmdale",
      "state": "FL",
      "county": "Glades"
  },
  {
      "zipCode": 33945,
      "latitude": 26.658313,
      "longitude": -82.1434,
      "city": "Pineland",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33946,
      "latitude": 26.879424,
      "longitude": -82.266405,
      "city": "Placida",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33947,
      "latitude": 26.884228,
      "longitude": -82.269126,
      "city": "Rotonda West",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33948,
      "latitude": 26.94099,
      "longitude": -82.20688,
      "city": "Port Charlotte",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33949,
      "latitude": 26.993904,
      "longitude": -82.098418,
      "city": "Port Charlotte",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33950,
      "latitude": 26.860103,
      "longitude": -82.013411,
      "city": "Punta Gorda",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33951,
      "latitude": 26.970756,
      "longitude": -81.984504,
      "city": "Punta Gorda",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33952,
      "latitude": 26.994048,
      "longitude": -82.156128,
      "city": "Port Charlotte",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33953,
      "latitude": 26.997956,
      "longitude": -82.200524,
      "city": "Port Charlotte",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33954,
      "latitude": 27.019741,
      "longitude": -82.123194,
      "city": "Port Charlotte",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33955,
      "latitude": 26.828631,
      "longitude": -81.909078,
      "city": "Punta Gorda",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33956,
      "latitude": 26.575762,
      "longitude": -82.107459,
      "city": "Saint James City",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33957,
      "latitude": 26.458308,
      "longitude": -82.100065,
      "city": "Sanibel",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33960,
      "latitude": 27.120281,
      "longitude": -81.390945,
      "city": "Venus",
      "state": "FL",
      "county": "Highlands"
  },
  {
      "zipCode": 33965,
      "latitude": 26.552895,
      "longitude": -81.94861,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33970,
      "latitude": 26.564718,
      "longitude": -81.620778,
      "city": "Lehigh Acres",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33971,
      "latitude": 26.589408,
      "longitude": -81.670757,
      "city": "Lehigh Acres",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33972,
      "latitude": 26.641661,
      "longitude": -81.913575,
      "city": "Lehigh Acres",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33975,
      "latitude": 26.763312,
      "longitude": -81.438833,
      "city": "Labelle",
      "state": "FL",
      "county": "Hendry"
  },
  {
      "zipCode": 33980,
      "latitude": 26.986122,
      "longitude": -82.055747,
      "city": "Port Charlotte",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33981,
      "latitude": 26.93457,
      "longitude": -82.232398,
      "city": "Port Charlotte",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33982,
      "latitude": 26.959685,
      "longitude": -81.819036,
      "city": "Punta Gorda",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33983,
      "latitude": 27.001845,
      "longitude": -82.018382,
      "city": "Punta Gorda",
      "state": "FL",
      "county": "Charlotte"
  },
  {
      "zipCode": 33990,
      "latitude": 26.6265,
      "longitude": -81.967657,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33991,
      "latitude": 26.628091,
      "longitude": -82.018158,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33993,
      "latitude": 26.678619,
      "longitude": -82.025384,
      "city": "Cape Coral",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 33994,
      "latitude": 26.552895,
      "longitude": -81.94861,
      "city": "Fort Myers",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 34002,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34003,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34009,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34020,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34021,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34022,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34023,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34024,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34025,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34030,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34031,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34032,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34033,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34034,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34035,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34036,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34037,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34038,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34039,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34040,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34041,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34042,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34050,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34051,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34053,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34054,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34055,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34058,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34060,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34071,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34077,
      "latitude": "",
      "longitude": "",
      "city": "Apo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34078,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34079,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34086,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34090,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34091,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34092,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34093,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34095,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34098,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34099,
      "latitude": "",
      "longitude": "",
      "city": "Fpo",
      "state": "AA",
      "county": ""
  },
  {
      "zipCode": 34101,
      "latitude": 25.855534,
      "longitude": -81.38719,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34102,
      "latitude": 26.133968,
      "longitude": -81.795309,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34103,
      "latitude": 26.191666,
      "longitude": -81.803944,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34104,
      "latitude": 26.178752,
      "longitude": -81.745447,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34105,
      "latitude": 26.193769,
      "longitude": -81.763609,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34106,
      "latitude": 26.14326,
      "longitude": -81.389124,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34107,
      "latitude": 26.14326,
      "longitude": -81.389124,
      "city": "Vanderbilt Beach",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34108,
      "latitude": 26.241595,
      "longitude": -81.807056,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34109,
      "latitude": 26.253416,
      "longitude": -81.764364,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34110,
      "latitude": 26.282328,
      "longitude": -81.757284,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34112,
      "latitude": 26.118429,
      "longitude": -81.736052,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34113,
      "latitude": 26.042638,
      "longitude": -81.718172,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34114,
      "latitude": 26.014314,
      "longitude": -81.585552,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34116,
      "latitude": 26.187256,
      "longitude": -81.711048,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34117,
      "latitude": 26.115558,
      "longitude": -81.523853,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34119,
      "latitude": 26.239288,
      "longitude": -81.66871,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34120,
      "latitude": 26.330438,
      "longitude": -81.587104,
      "city": "Naples",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34133,
      "latitude": 26.552895,
      "longitude": -81.94861,
      "city": "Bonita Springs",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 34134,
      "latitude": 26.362566,
      "longitude": -81.818279,
      "city": "Bonita Springs",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 34135,
      "latitude": 26.377129,
      "longitude": -81.733377,
      "city": "Bonita Springs",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 34136,
      "latitude": 26.552895,
      "longitude": -81.94861,
      "city": "Bonita Springs",
      "state": "FL",
      "county": "Lee"
  },
  {
      "zipCode": 34137,
      "latitude": 26.14326,
      "longitude": -81.389124,
      "city": "Copeland",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34138,
      "latitude": 25.836746,
      "longitude": -81.121963,
      "city": "Chokoloskee",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34139,
      "latitude": 25.856971,
      "longitude": -81.377791,
      "city": "Everglades City",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34140,
      "latitude": 26.14326,
      "longitude": -81.389124,
      "city": "Goodland",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34141,
      "latitude": 25.873412,
      "longitude": -81.159938,
      "city": "Ochopee",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34142,
      "latitude": 26.18437,
      "longitude": -81.415175,
      "city": "Immokalee",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34143,
      "latitude": 26.464183,
      "longitude": -81.504661,
      "city": "Immokalee",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34145,
      "latitude": 25.938768,
      "longitude": -81.696751,
      "city": "Marco Island",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34146,
      "latitude": 26.14326,
      "longitude": -81.389124,
      "city": "Marco Island",
      "state": "FL",
      "county": "Collier"
  },
  {
      "zipCode": 34201,
      "latitude": 27.404731,
      "longitude": -82.470456,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34202,
      "latitude": 27.471379,
      "longitude": -82.37934,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34203,
      "latitude": 27.454723,
      "longitude": -82.535883,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34204,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34205,
      "latitude": 27.466869,
      "longitude": -82.456033,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34206,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34207,
      "latitude": 27.439419,
      "longitude": -82.577779,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34208,
      "latitude": 27.467817,
      "longitude": -82.512018,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34209,
      "latitude": 27.475853,
      "longitude": -82.616719,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34210,
      "latitude": 27.473598,
      "longitude": -82.660725,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34211,
      "latitude": 27.454365,
      "longitude": -82.383383,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34212,
      "latitude": 27.493816,
      "longitude": -82.406103,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34215,
      "latitude": 27.471256,
      "longitude": -82.682262,
      "city": "Cortez",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34216,
      "latitude": 27.529113,
      "longitude": -82.731722,
      "city": "Anna Maria",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34217,
      "latitude": 27.485915,
      "longitude": -82.710221,
      "city": "Bradenton Beach",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34218,
      "latitude": 27.499515,
      "longitude": -82.709871,
      "city": "Holmes Beach",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34219,
      "latitude": 27.566457,
      "longitude": -82.269458,
      "city": "Parrish",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34220,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Palmetto",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34221,
      "latitude": 27.577315,
      "longitude": -82.516004,
      "city": "Palmetto",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34222,
      "latitude": 27.546332,
      "longitude": -82.496732,
      "city": "Ellenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34223,
      "latitude": 27.143931,
      "longitude": -82.404959,
      "city": "Englewood",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34224,
      "latitude": 27.064882,
      "longitude": -82.400124,
      "city": "Englewood",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34228,
      "latitude": 27.357231,
      "longitude": -82.443071,
      "city": "Longboat Key",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34229,
      "latitude": 27.213378,
      "longitude": -82.488818,
      "city": "Osprey",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34230,
      "latitude": 27.335023,
      "longitude": -82.537169,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34231,
      "latitude": 27.227589,
      "longitude": -82.432111,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34232,
      "latitude": 27.203872,
      "longitude": -82.443249,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34233,
      "latitude": 27.127372,
      "longitude": -82.423776,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34234,
      "latitude": 27.368769,
      "longitude": -82.526768,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34235,
      "latitude": 27.363072,
      "longitude": -82.478376,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34236,
      "latitude": 27.326863,
      "longitude": -82.543319,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34237,
      "latitude": 27.337173,
      "longitude": -82.514176,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34238,
      "latitude": 27.242689,
      "longitude": -82.475145,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34239,
      "latitude": 27.310774,
      "longitude": -82.520868,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34240,
      "latitude": 27.231941,
      "longitude": -82.3504,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34241,
      "latitude": 27.241077,
      "longitude": -82.351349,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34242,
      "latitude": 27.256626,
      "longitude": -82.539819,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34243,
      "latitude": 27.415606,
      "longitude": -82.528065,
      "city": "Sarasota",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34250,
      "latitude": 27.572236,
      "longitude": -82.583168,
      "city": "Terra Ceia",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34251,
      "latitude": 27.372771,
      "longitude": -82.225911,
      "city": "Myakka City",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34260,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Manasota",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34264,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Oneco",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34265,
      "latitude": 27.186146,
      "longitude": -81.809939,
      "city": "Arcadia",
      "state": "FL",
      "county": "De Soto"
  },
  {
      "zipCode": 34266,
      "latitude": 27.186075,
      "longitude": -81.866661,
      "city": "Arcadia",
      "state": "FL",
      "county": "De Soto"
  },
  {
      "zipCode": 34267,
      "latitude": 27.186146,
      "longitude": -81.809939,
      "city": "Fort Ogden",
      "state": "FL",
      "county": "De Soto"
  },
  {
      "zipCode": 34268,
      "latitude": 27.203827,
      "longitude": -81.864443,
      "city": "Nocatee",
      "state": "FL",
      "county": "De Soto"
  },
  {
      "zipCode": 34269,
      "latitude": 27.089943,
      "longitude": -81.96148,
      "city": "Arcadia",
      "state": "FL",
      "county": "De Soto"
  },
  {
      "zipCode": 34270,
      "latitude": 27.405429,
      "longitude": -82.543519,
      "city": "Tallevast",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34272,
      "latitude": 27.146963,
      "longitude": -82.425512,
      "city": "Laurel",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34274,
      "latitude": 27.144031,
      "longitude": -82.464468,
      "city": "Nokomis",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34275,
      "latitude": 27.229227,
      "longitude": -82.459848,
      "city": "Nokomis",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34276,
      "latitude": 27.167521,
      "longitude": -82.380967,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34277,
      "latitude": 27.167521,
      "longitude": -82.380967,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34278,
      "latitude": 27.331628,
      "longitude": -82.528488,
      "city": "Sarasota",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34280,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34281,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34282,
      "latitude": 27.427213,
      "longitude": -82.438689,
      "city": "Bradenton",
      "state": "FL",
      "county": "Manatee"
  },
  {
      "zipCode": 34284,
      "latitude": 27.167521,
      "longitude": -82.380967,
      "city": "Venice",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34285,
      "latitude": 27.09639,
      "longitude": -82.433818,
      "city": "Venice",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34286,
      "latitude": 27.074755,
      "longitude": -82.175602,
      "city": "North Port",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34287,
      "latitude": 27.189487,
      "longitude": -82.334882,
      "city": "North Port",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34288,
      "latitude": 27.0536,
      "longitude": -82.113335,
      "city": "North Port",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34289,
      "latitude": 27.088812,
      "longitude": -82.139203,
      "city": "North Port",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34292,
      "latitude": 27.090034,
      "longitude": -82.370028,
      "city": "Venice",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34293,
      "latitude": 27.060576,
      "longitude": -82.352038,
      "city": "Venice",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34295,
      "latitude": 27.085985,
      "longitude": -82.438918,
      "city": "Englewood",
      "state": "FL",
      "county": "Sarasota"
  },
  {
      "zipCode": 34420,
      "latitude": 29.101476,
      "longitude": -82.044371,
      "city": "Belleview",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34421,
      "latitude": 29.240728,
      "longitude": -82.087458,
      "city": "Belleview",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34423,
      "latitude": 28.867027,
      "longitude": -82.572703,
      "city": "Crystal River",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34428,
      "latitude": 28.958393,
      "longitude": -82.599279,
      "city": "Crystal River",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34429,
      "latitude": 28.904358,
      "longitude": -82.596051,
      "city": "Crystal River",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34430,
      "latitude": 29.240728,
      "longitude": -82.087458,
      "city": "Dunnellon",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34431,
      "latitude": 29.09481,
      "longitude": -82.249223,
      "city": "Dunnellon",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34432,
      "latitude": 29.101529,
      "longitude": -82.34129,
      "city": "Dunnellon",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34433,
      "latitude": 28.994902,
      "longitude": -82.519629,
      "city": "Dunnellon",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34434,
      "latitude": 28.993778,
      "longitude": -82.424132,
      "city": "Dunnellon",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34436,
      "latitude": 28.73035,
      "longitude": -82.307734,
      "city": "Floral City",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34442,
      "latitude": 28.922317,
      "longitude": -82.390044,
      "city": "Hernando",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34445,
      "latitude": 28.948753,
      "longitude": -82.406476,
      "city": "Holder",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34446,
      "latitude": 28.7508,
      "longitude": -82.513889,
      "city": "Homosassa",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34447,
      "latitude": 28.804911,
      "longitude": -82.574264,
      "city": "Homosassa Springs",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34448,
      "latitude": 28.787963,
      "longitude": -82.567981,
      "city": "Homosassa",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34449,
      "latitude": 29.095501,
      "longitude": -82.656099,
      "city": "Inglis",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 34450,
      "latitude": 28.834011,
      "longitude": -82.282224,
      "city": "Inverness",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34451,
      "latitude": 28.803207,
      "longitude": -82.32167,
      "city": "Inverness",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34452,
      "latitude": 28.777849,
      "longitude": -82.360295,
      "city": "Inverness",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34453,
      "latitude": 28.872256,
      "longitude": -82.345411,
      "city": "Inverness",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34460,
      "latitude": 28.859286,
      "longitude": -82.508694,
      "city": "Lecanto",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34461,
      "latitude": 28.819711,
      "longitude": -82.464061,
      "city": "Lecanto",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34464,
      "latitude": 28.859286,
      "longitude": -82.508694,
      "city": "Beverly Hills",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34465,
      "latitude": 28.817853,
      "longitude": -82.446209,
      "city": "Beverly Hills",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34470,
      "latitude": 29.238672,
      "longitude": -82.169401,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34471,
      "latitude": 29.160525,
      "longitude": -81.942516,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34472,
      "latitude": 29.125344,
      "longitude": -82.008571,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34473,
      "latitude": 29.113464,
      "longitude": -82.195635,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34474,
      "latitude": 29.150148,
      "longitude": -82.137774,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34475,
      "latitude": 29.154639,
      "longitude": -82.304525,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34476,
      "latitude": 29.115896,
      "longitude": -82.242211,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34477,
      "latitude": 29.240728,
      "longitude": -82.087458,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34478,
      "latitude": 29.187178,
      "longitude": -82.112278,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34479,
      "latitude": 29.178235,
      "longitude": -82.02819,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34480,
      "latitude": 29.180072,
      "longitude": -82.03227,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34481,
      "latitude": 29.128062,
      "longitude": -82.297544,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34482,
      "latitude": 29.261079,
      "longitude": -82.219513,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34483,
      "latitude": 29.240728,
      "longitude": -82.087458,
      "city": "Ocala",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34484,
      "latitude": 28.905906,
      "longitude": -82.061193,
      "city": "Oxford",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 34487,
      "latitude": 28.859286,
      "longitude": -82.508694,
      "city": "Homosassa",
      "state": "FL",
      "county": "Citrus"
  },
  {
      "zipCode": 34488,
      "latitude": 29.263549,
      "longitude": -81.95316,
      "city": "Silver Springs",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34489,
      "latitude": 29.215153,
      "longitude": -82.097163,
      "city": "Silver Springs",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34491,
      "latitude": 29.01123,
      "longitude": -82.032463,
      "city": "Summerfield",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34492,
      "latitude": 28.99796,
      "longitude": -82.016139,
      "city": "Summerfield",
      "state": "FL",
      "county": "Marion"
  },
  {
      "zipCode": 34498,
      "latitude": 29.030531,
      "longitude": -82.719003,
      "city": "Yankeetown",
      "state": "FL",
      "county": "Levy"
  },
  {
      "zipCode": 34601,
      "latitude": 28.586197,
      "longitude": -82.395858,
      "city": "Brooksville",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34602,
      "latitude": 28.509319,
      "longitude": -82.295675,
      "city": "Brooksville",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34603,
      "latitude": 28.564167,
      "longitude": -82.416515,
      "city": "Brooksville",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34604,
      "latitude": 28.477875,
      "longitude": -82.432707,
      "city": "Brooksville",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34605,
      "latitude": 28.505896,
      "longitude": -82.422554,
      "city": "Brooksville",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34606,
      "latitude": 28.496411,
      "longitude": -82.40101,
      "city": "Spring Hill",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34607,
      "latitude": 28.504888,
      "longitude": -82.625672,
      "city": "Spring Hill",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34608,
      "latitude": 28.483643,
      "longitude": -82.542698,
      "city": "Spring Hill",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34609,
      "latitude": 28.494344,
      "longitude": -82.450658,
      "city": "Spring Hill",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34610,
      "latitude": 28.377455,
      "longitude": -82.524063,
      "city": "Spring Hill",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34611,
      "latitude": 28.564167,
      "longitude": -82.416515,
      "city": "Spring Hill",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34613,
      "latitude": 28.604951,
      "longitude": -82.531987,
      "city": "Brooksville",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34614,
      "latitude": 28.627958,
      "longitude": -82.536465,
      "city": "Brooksville",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34636,
      "latitude": 28.65503,
      "longitude": -82.267706,
      "city": "Istachatta",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34639,
      "latitude": 28.250774,
      "longitude": -82.46263,
      "city": "Land O Lakes",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34652,
      "latitude": 28.239369,
      "longitude": -82.736882,
      "city": "New Port Richey",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34653,
      "latitude": 28.242508,
      "longitude": -82.695536,
      "city": "New Port Richey",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34654,
      "latitude": 28.296388,
      "longitude": -82.617095,
      "city": "New Port Richey",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34655,
      "latitude": 28.216016,
      "longitude": -82.67162,
      "city": "New Port Richey",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34656,
      "latitude": 28.324796,
      "longitude": -82.481766,
      "city": "New Port Richey",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34660,
      "latitude": 28.067043,
      "longitude": -82.778424,
      "city": "Ozona",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34661,
      "latitude": 28.64364,
      "longitude": -82.263836,
      "city": "Nobleton",
      "state": "FL",
      "county": "Hernando"
  },
  {
      "zipCode": 34667,
      "latitude": 28.382489,
      "longitude": -82.64803,
      "city": "Hudson",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34668,
      "latitude": 28.297976,
      "longitude": -82.704101,
      "city": "Port Richey",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34669,
      "latitude": 28.360086,
      "longitude": -82.62074,
      "city": "Hudson",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34673,
      "latitude": 28.324796,
      "longitude": -82.481766,
      "city": "Port Richey",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34674,
      "latitude": 28.324796,
      "longitude": -82.481766,
      "city": "Hudson",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34677,
      "latitude": 28.050324,
      "longitude": -82.677379,
      "city": "Oldsmar",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34679,
      "latitude": 28.430213,
      "longitude": -82.661555,
      "city": "Aripeka",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34680,
      "latitude": 28.324796,
      "longitude": -82.481766,
      "city": "Elfers",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34681,
      "latitude": 28.087401,
      "longitude": -82.777683,
      "city": "Crystal Beach",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34682,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Palm Harbor",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34683,
      "latitude": 28.074144,
      "longitude": -82.744889,
      "city": "Palm Harbor",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34684,
      "latitude": 28.084797,
      "longitude": -82.725257,
      "city": "Palm Harbor",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34685,
      "latitude": 28.109717,
      "longitude": -82.692882,
      "city": "Palm Harbor",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34688,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Tarpon Springs",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34689,
      "latitude": 28.092104,
      "longitude": -82.732165,
      "city": "Tarpon Springs",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34690,
      "latitude": 28.191434,
      "longitude": -82.724448,
      "city": "Holiday",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34691,
      "latitude": 28.196394,
      "longitude": -82.762191,
      "city": "Holiday",
      "state": "FL",
      "county": "Pasco"
  },
  {
      "zipCode": 34695,
      "latitude": 28.017645,
      "longitude": -82.747692,
      "city": "Safety Harbor",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34697,
      "latitude": 27.891809,
      "longitude": -82.724763,
      "city": "Dunedin",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34698,
      "latitude": 28.010895,
      "longitude": -82.773995,
      "city": "Dunedin",
      "state": "FL",
      "county": "Pinellas"
  },
  {
      "zipCode": 34705,
      "latitude": 28.706723,
      "longitude": -81.718455,
      "city": "Astatula",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34711,
      "latitude": 28.584025,
      "longitude": -81.779423,
      "city": "Clermont",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34712,
      "latitude": 28.811078,
      "longitude": -81.653642,
      "city": "Clermont",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34713,
      "latitude": 28.811078,
      "longitude": -81.653642,
      "city": "Clermont",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34729,
      "latitude": 28.811078,
      "longitude": -81.653642,
      "city": "Ferndale",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34731,
      "latitude": 28.863361,
      "longitude": -81.905624,
      "city": "Fruitland Park",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34734,
      "latitude": 28.538407,
      "longitude": -81.520774,
      "city": "Gotha",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34736,
      "latitude": 28.587996,
      "longitude": -81.870269,
      "city": "Groveland",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34737,
      "latitude": 28.697081,
      "longitude": -81.797564,
      "city": "Howey In The Hills",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34739,
      "latitude": 27.933215,
      "longitude": -81.092447,
      "city": "Kenansville",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34740,
      "latitude": 28.545385,
      "longitude": -81.650667,
      "city": "Killarney",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34741,
      "latitude": 28.307138,
      "longitude": -81.461385,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34742,
      "latitude": 27.995287,
      "longitude": -81.259332,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34743,
      "latitude": 28.326039,
      "longitude": -81.405894,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34744,
      "latitude": 28.297638,
      "longitude": -81.32402,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34745,
      "latitude": 27.995287,
      "longitude": -81.259332,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34746,
      "latitude": 28.243011,
      "longitude": -81.400997,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34747,
      "latitude": 28.303738,
      "longitude": -81.589782,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34748,
      "latitude": 28.758895,
      "longitude": -81.888695,
      "city": "Leesburg",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34749,
      "latitude": 28.811078,
      "longitude": -81.653642,
      "city": "Leesburg",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34753,
      "latitude": 28.58299,
      "longitude": -81.894119,
      "city": "Mascotte",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34755,
      "latitude": 28.578234,
      "longitude": -81.831883,
      "city": "Minneola",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34756,
      "latitude": 28.581499,
      "longitude": -81.685146,
      "city": "Montverde",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34758,
      "latitude": 28.203877,
      "longitude": -81.487992,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34759,
      "latitude": 28.094636,
      "longitude": -81.498979,
      "city": "Kissimmee",
      "state": "FL",
      "county": "Polk"
  },
  {
      "zipCode": 34760,
      "latitude": 28.550978,
      "longitude": -81.621546,
      "city": "Oakland",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34761,
      "latitude": 28.569438,
      "longitude": -81.530251,
      "city": "Ocoee",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34762,
      "latitude": 28.74751,
      "longitude": -81.895885,
      "city": "Okahumpka",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34769,
      "latitude": 28.230351,
      "longitude": -81.359092,
      "city": "Saint Cloud",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34770,
      "latitude": 27.995287,
      "longitude": -81.259332,
      "city": "Saint Cloud",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34771,
      "latitude": 28.246439,
      "longitude": -81.231589,
      "city": "Saint Cloud",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34772,
      "latitude": 28.172669,
      "longitude": -81.26899,
      "city": "Saint Cloud",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34773,
      "latitude": 28.146663,
      "longitude": -80.993806,
      "city": "Saint Cloud",
      "state": "FL",
      "county": "Osceola"
  },
  {
      "zipCode": 34777,
      "latitude": 28.541579,
      "longitude": -81.605846,
      "city": "Winter Garden",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34778,
      "latitude": 28.566338,
      "longitude": -81.260818,
      "city": "Winter Garden",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34785,
      "latitude": 28.844473,
      "longitude": -82.078342,
      "city": "Wildwood",
      "state": "FL",
      "county": "Sumter"
  },
  {
      "zipCode": 34786,
      "latitude": 28.481031,
      "longitude": -81.543979,
      "city": "Windermere",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34787,
      "latitude": 28.468581,
      "longitude": -81.512343,
      "city": "Winter Garden",
      "state": "FL",
      "county": "Orange"
  },
  {
      "zipCode": 34788,
      "latitude": 28.888724,
      "longitude": -81.782704,
      "city": "Leesburg",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34789,
      "latitude": 28.811078,
      "longitude": -81.653642,
      "city": "Leesburg",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34797,
      "latitude": 28.712017,
      "longitude": -81.808159,
      "city": "Yalaha",
      "state": "FL",
      "county": "Lake"
  },
  {
      "zipCode": 34945,
      "latitude": 27.412128,
      "longitude": -80.534169,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34946,
      "latitude": 27.511616,
      "longitude": -80.364447,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34947,
      "latitude": 27.445611,
      "longitude": -80.387742,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34948,
      "latitude": 27.382196,
      "longitude": -80.409024,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34949,
      "latitude": 27.456687,
      "longitude": -80.285812,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34950,
      "latitude": 27.448066,
      "longitude": -80.333224,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34951,
      "latitude": 27.513925,
      "longitude": -80.457475,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34952,
      "latitude": 27.312492,
      "longitude": -80.313322,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34953,
      "latitude": 27.257707,
      "longitude": -80.380277,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34954,
      "latitude": 27.382196,
      "longitude": -80.409024,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34956,
      "latitude": 27.106451,
      "longitude": -80.45192,
      "city": "Indiantown",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34957,
      "latitude": 27.236473,
      "longitude": -80.237943,
      "city": "Jensen Beach",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34958,
      "latitude": 27.242402,
      "longitude": -80.224613,
      "city": "Jensen Beach",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34972,
      "latitude": 27.420316,
      "longitude": -80.945361,
      "city": "Okeechobee",
      "state": "FL",
      "county": "Okeechobee"
  },
  {
      "zipCode": 34973,
      "latitude": 27.297631,
      "longitude": -80.802656,
      "city": "Okeechobee",
      "state": "FL",
      "county": "Okeechobee"
  },
  {
      "zipCode": 34974,
      "latitude": 27.216626,
      "longitude": -80.827757,
      "city": "Okeechobee",
      "state": "FL",
      "county": "Okeechobee"
  },
  {
      "zipCode": 34979,
      "latitude": 27.382196,
      "longitude": -80.409024,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34981,
      "latitude": 27.391213,
      "longitude": -80.374042,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34982,
      "latitude": 27.362859,
      "longitude": -80.307717,
      "city": "Fort Pierce",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34983,
      "latitude": 27.347284,
      "longitude": -80.347722,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34984,
      "latitude": 27.251266,
      "longitude": -80.345407,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34985,
      "latitude": 27.382196,
      "longitude": -80.409024,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34986,
      "latitude": 27.333392,
      "longitude": -80.397427,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34987,
      "latitude": 27.29076,
      "longitude": -80.484851,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34988,
      "latitude": 27.386818,
      "longitude": -80.503746,
      "city": "Port Saint Lucie",
      "state": "FL",
      "county": "Saint Lucie"
  },
  {
      "zipCode": 34990,
      "latitude": 27.111235,
      "longitude": -80.419042,
      "city": "Palm City",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34991,
      "latitude": 27.110182,
      "longitude": -80.454196,
      "city": "Palm City",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34992,
      "latitude": 27.110182,
      "longitude": -80.454196,
      "city": "Port Salerno",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34994,
      "latitude": 27.189748,
      "longitude": -80.254768,
      "city": "Stuart",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34995,
      "latitude": 27.17537,
      "longitude": -80.241512,
      "city": "Stuart",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34996,
      "latitude": 27.205819,
      "longitude": -80.195911,
      "city": "Stuart",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 34997,
      "latitude": 27.108081,
      "longitude": -80.230818,
      "city": "Stuart",
      "state": "FL",
      "county": "Martin"
  },
  {
      "zipCode": 35004,
      "latitude": 33.603543,
      "longitude": -86.466833,
      "city": "Moody",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35005,
      "latitude": 33.578097,
      "longitude": -86.987228,
      "city": "Adamsville",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35006,
      "latitude": 33.437653,
      "longitude": -87.207592,
      "city": "Adger",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35007,
      "latitude": 33.193415,
      "longitude": -86.794377,
      "city": "Alabaster",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35010,
      "latitude": 32.930079,
      "longitude": -85.805026,
      "city": "Alexander City",
      "state": "AL",
      "county": "Tallapoosa"
  },
  {
      "zipCode": 35011,
      "latitude": 32.990191,
      "longitude": -85.958001,
      "city": "Alexander City",
      "state": "AL",
      "county": "Tallapoosa"
  },
  {
      "zipCode": 35013,
      "latitude": 33.922162,
      "longitude": -86.449262,
      "city": "Allgood",
      "state": "AL",
      "county": "Blount"
  },
  {
      "zipCode": 35014,
      "latitude": 33.403969,
      "longitude": -86.233878,
      "city": "Alpine",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35015,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Alton",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35016,
      "latitude": 34.393855,
      "longitude": -86.365403,
      "city": "Arab",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35019,
      "latitude": 34.265451,
      "longitude": -86.712779,
      "city": "Baileyton",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35020,
      "latitude": 33.441602,
      "longitude": -86.88857,
      "city": "Bessemer",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35021,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Bessemer",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35022,
      "latitude": 33.352745,
      "longitude": -86.968328,
      "city": "Bessemer",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35023,
      "latitude": 33.478989,
      "longitude": -86.944763,
      "city": "Bessemer",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35031,
      "latitude": 34.091285,
      "longitude": -86.559625,
      "city": "Blountsville",
      "state": "AL",
      "county": "Blount"
  },
  {
      "zipCode": 35032,
      "latitude": 33.256886,
      "longitude": -86.338572,
      "city": "Bon Air",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35033,
      "latitude": 33.957886,
      "longitude": -87.003767,
      "city": "Bremen",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35034,
      "latitude": 32.989499,
      "longitude": -87.220392,
      "city": "Brent",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 35035,
      "latitude": 33.016616,
      "longitude": -86.9598,
      "city": "Brierfield",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 35036,
      "latitude": 33.63628,
      "longitude": -86.916202,
      "city": "Brookside",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35038,
      "latitude": 33.804411,
      "longitude": -87.202136,
      "city": "Burnwell",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35040,
      "latitude": 33.138832,
      "longitude": -86.725315,
      "city": "Calera",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35041,
      "latitude": 33.64672,
      "longitude": -86.931818,
      "city": "Cardiff",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35042,
      "latitude": 32.948233,
      "longitude": -87.139316,
      "city": "Centreville",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 35043,
      "latitude": 33.298238,
      "longitude": -86.656328,
      "city": "Chelsea",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35044,
      "latitude": 33.245885,
      "longitude": -86.364148,
      "city": "Childersburg",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35045,
      "latitude": 32.856632,
      "longitude": -86.652875,
      "city": "Clanton",
      "state": "AL",
      "county": "Chilton"
  },
  {
      "zipCode": 35046,
      "latitude": 32.886365,
      "longitude": -86.648931,
      "city": "Clanton",
      "state": "AL",
      "county": "Chilton"
  },
  {
      "zipCode": 35048,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Clay",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35049,
      "latitude": 33.977816,
      "longitude": -86.55985,
      "city": "Cleveland",
      "state": "AL",
      "county": "Blount"
  },
  {
      "zipCode": 35051,
      "latitude": 33.232849,
      "longitude": -86.554849,
      "city": "Columbiana",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35052,
      "latitude": 33.589518,
      "longitude": -86.392283,
      "city": "Cook Springs",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35053,
      "latitude": 34.044614,
      "longitude": -87.051404,
      "city": "Crane Hill",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35054,
      "latitude": 33.529643,
      "longitude": -86.300691,
      "city": "Cropwell",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35055,
      "latitude": 34.111067,
      "longitude": -86.853185,
      "city": "Cullman",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35056,
      "latitude": 34.196448,
      "longitude": -86.895226,
      "city": "Cullman",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35057,
      "latitude": 34.087769,
      "longitude": -86.944713,
      "city": "Cullman",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35058,
      "latitude": 34.209766,
      "longitude": -86.752029,
      "city": "Cullman",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35060,
      "latitude": 33.558905,
      "longitude": -86.929443,
      "city": "Docena",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35061,
      "latitude": 33.460807,
      "longitude": -86.960864,
      "city": "Dolomite",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35062,
      "latitude": 33.735709,
      "longitude": -87.054641,
      "city": "Dora",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35063,
      "latitude": 33.827269,
      "longitude": -87.027469,
      "city": "Empire",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35064,
      "latitude": 33.475407,
      "longitude": -86.925391,
      "city": "Fairfield",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35068,
      "latitude": 33.601739,
      "longitude": -86.826491,
      "city": "Fultondale",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35070,
      "latitude": 34.005773,
      "longitude": -86.74995,
      "city": "Garden City",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35071,
      "latitude": 33.70615,
      "longitude": -86.848289,
      "city": "Gardendale",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35072,
      "latitude": 33.058725,
      "longitude": -86.079106,
      "city": "Goodwater",
      "state": "AL",
      "county": "Coosa"
  },
  {
      "zipCode": 35073,
      "latitude": 33.659545,
      "longitude": -86.801693,
      "city": "Graysville",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35074,
      "latitude": 33.202691,
      "longitude": -87.118044,
      "city": "Green Pond",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 35077,
      "latitude": 34.034276,
      "longitude": -86.841753,
      "city": "Hanceville",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35078,
      "latitude": 33.199274,
      "longitude": -86.459291,
      "city": "Harpersville",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35079,
      "latitude": 33.953352,
      "longitude": -86.745469,
      "city": "Hayden",
      "state": "AL",
      "county": "Blount"
  },
  {
      "zipCode": 35080,
      "latitude": 33.266341,
      "longitude": -86.901975,
      "city": "Helena",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35082,
      "latitude": 33.116229,
      "longitude": -86.138624,
      "city": "Hollins",
      "state": "AL",
      "county": "Clay"
  },
  {
      "zipCode": 35083,
      "latitude": 34.152149,
      "longitude": -86.791286,
      "city": "Holly Pond",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35085,
      "latitude": 32.970056,
      "longitude": -86.734146,
      "city": "Jemison",
      "state": "AL",
      "county": "Chilton"
  },
  {
      "zipCode": 35087,
      "latitude": 34.272537,
      "longitude": -86.576125,
      "city": "Joppa",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35089,
      "latitude": 32.934298,
      "longitude": -86.095801,
      "city": "Kellyton",
      "state": "AL",
      "county": "Coosa"
  },
  {
      "zipCode": 35091,
      "latitude": 33.769928,
      "longitude": -86.800573,
      "city": "Kimberly",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35094,
      "latitude": 33.51828,
      "longitude": -86.584472,
      "city": "Leeds",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35096,
      "latitude": 33.445821,
      "longitude": -86.255605,
      "city": "Lincoln",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35097,
      "latitude": 33.904975,
      "longitude": -86.626094,
      "city": "Locust Fork",
      "state": "AL",
      "county": "Blount"
  },
  {
      "zipCode": 35098,
      "latitude": 34.137678,
      "longitude": -87.026554,
      "city": "Logan",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35111,
      "latitude": 33.299704,
      "longitude": -87.048881,
      "city": "Mc Calla",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35112,
      "latitude": 33.695814,
      "longitude": -86.46564,
      "city": "Margaret",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35114,
      "latitude": 33.337916,
      "longitude": -86.733796,
      "city": "Maylene",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35115,
      "latitude": 33.136658,
      "longitude": -86.883586,
      "city": "Montevallo",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35116,
      "latitude": 33.727106,
      "longitude": -86.763967,
      "city": "Morris",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35117,
      "latitude": 33.637322,
      "longitude": -86.875181,
      "city": "Mount Olive",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35118,
      "latitude": 33.536339,
      "longitude": -87.051031,
      "city": "Mulga",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35119,
      "latitude": 33.650832,
      "longitude": -86.769698,
      "city": "New Castle",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35120,
      "latitude": 33.657096,
      "longitude": -86.38336,
      "city": "Odenville",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35121,
      "latitude": 33.968171,
      "longitude": -86.5535,
      "city": "Oneonta",
      "state": "AL",
      "county": "Blount"
  },
  {
      "zipCode": 35123,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Palmerdale",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35124,
      "latitude": 33.293157,
      "longitude": -86.767961,
      "city": "Pelham",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35125,
      "latitude": 33.592484,
      "longitude": -86.325995,
      "city": "Pell City",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35126,
      "latitude": 33.72988,
      "longitude": -86.645081,
      "city": "Pinson",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35127,
      "latitude": 33.549138,
      "longitude": -86.884204,
      "city": "Pleasant Grove",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35128,
      "latitude": 33.587299,
      "longitude": -86.336607,
      "city": "Pell City",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35130,
      "latitude": 33.721814,
      "longitude": -87.124359,
      "city": "Quinton",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35131,
      "latitude": 33.715229,
      "longitude": -86.24746,
      "city": "Ragland",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35133,
      "latitude": 33.845846,
      "longitude": -86.586724,
      "city": "Remlap",
      "state": "AL",
      "county": "Blount"
  },
  {
      "zipCode": 35135,
      "latitude": 33.622079,
      "longitude": -86.221589,
      "city": "Riverside",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35136,
      "latitude": 32.897085,
      "longitude": -86.156068,
      "city": "Rockford",
      "state": "AL",
      "county": "Coosa"
  },
  {
      "zipCode": 35137,
      "latitude": 33.206302,
      "longitude": -86.781162,
      "city": "Saginaw",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35139,
      "latitude": 33.711096,
      "longitude": -86.975856,
      "city": "Sayre",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35142,
      "latitude": 33.406869,
      "longitude": -86.87347,
      "city": "Shannon",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35143,
      "latitude": 33.080188,
      "longitude": -86.550392,
      "city": "Shelby",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35144,
      "latitude": 33.28241,
      "longitude": -86.683891,
      "city": "Siluria",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35146,
      "latitude": 33.705871,
      "longitude": -86.403202,
      "city": "Springville",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35147,
      "latitude": 33.419068,
      "longitude": -86.544426,
      "city": "Sterrett",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35148,
      "latitude": 33.757716,
      "longitude": -87.0461,
      "city": "Sumiton",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35149,
      "latitude": 33.272776,
      "longitude": -86.204925,
      "city": "Sycamore",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35150,
      "latitude": 33.316003,
      "longitude": -86.228703,
      "city": "Sylacauga",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35151,
      "latitude": 33.175552,
      "longitude": -86.357359,
      "city": "Sylacauga",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35160,
      "latitude": 33.387375,
      "longitude": -86.144681,
      "city": "Talladega",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35161,
      "latitude": 33.420206,
      "longitude": -86.102782,
      "city": "Talladega",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 35171,
      "latitude": 32.893156,
      "longitude": -86.756368,
      "city": "Thorsby",
      "state": "AL",
      "county": "Chilton"
  },
  {
      "zipCode": 35172,
      "latitude": 33.810179,
      "longitude": -86.724855,
      "city": "Trafford",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35173,
      "latitude": 33.646334,
      "longitude": -86.58589,
      "city": "Trussville",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35175,
      "latitude": 34.433986,
      "longitude": -86.501338,
      "city": "Union Grove",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35176,
      "latitude": 33.464255,
      "longitude": -86.509972,
      "city": "Vandiver",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35178,
      "latitude": 33.309634,
      "longitude": -86.5613,
      "city": "Vincent",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35179,
      "latitude": 34.258747,
      "longitude": -86.925471,
      "city": "Vinemont",
      "state": "AL",
      "county": "Cullman"
  },
  {
      "zipCode": 35180,
      "latitude": 33.790134,
      "longitude": -86.829871,
      "city": "Warrior",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35181,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Watson",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35182,
      "latitude": 33.706786,
      "longitude": -86.256658,
      "city": "Wattsville",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35183,
      "latitude": 33.06767,
      "longitude": -86.303403,
      "city": "Weogufka",
      "state": "AL",
      "county": "Coosa"
  },
  {
      "zipCode": 35184,
      "latitude": 33.102111,
      "longitude": -87.141467,
      "city": "West Blocton",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 35185,
      "latitude": 33.335629,
      "longitude": -86.543692,
      "city": "Westover",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35186,
      "latitude": 33.285635,
      "longitude": -86.496647,
      "city": "Wilsonville",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35187,
      "latitude": 33.08104,
      "longitude": -86.879328,
      "city": "Wilton",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35188,
      "latitude": 33.17994,
      "longitude": -87.165935,
      "city": "Woodstock",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 35201,
      "latitude": 33.456412,
      "longitude": -86.801904,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35202,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35203,
      "latitude": 33.519204,
      "longitude": -86.807889,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35204,
      "latitude": 33.524455,
      "longitude": -86.837898,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35205,
      "latitude": 33.569398,
      "longitude": -86.782584,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35206,
      "latitude": 33.566137,
      "longitude": -86.705192,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35207,
      "latitude": 33.572576,
      "longitude": -86.837341,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35208,
      "latitude": 33.498055,
      "longitude": -86.879941,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35209,
      "latitude": 33.465336,
      "longitude": -86.808213,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35210,
      "latitude": 33.579661,
      "longitude": -86.734969,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35211,
      "latitude": 33.45717,
      "longitude": -86.864909,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35212,
      "latitude": 33.547803,
      "longitude": -86.749538,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35213,
      "latitude": 33.455408,
      "longitude": -86.749588,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35214,
      "latitude": 33.575142,
      "longitude": -86.905859,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35215,
      "latitude": 33.542055,
      "longitude": -86.80604,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35216,
      "latitude": 33.56014,
      "longitude": -86.78134,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35217,
      "latitude": 33.56076,
      "longitude": -86.856553,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35218,
      "latitude": 33.506305,
      "longitude": -86.895191,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35219,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35220,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35221,
      "latitude": 33.453807,
      "longitude": -86.898341,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35222,
      "latitude": 33.523204,
      "longitude": -86.763788,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35223,
      "latitude": 33.499513,
      "longitude": -86.726838,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35224,
      "latitude": 33.521455,
      "longitude": -86.974735,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35225,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35226,
      "latitude": 33.396723,
      "longitude": -86.834563,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35228,
      "latitude": 33.455807,
      "longitude": -86.911841,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35229,
      "latitude": 33.462856,
      "longitude": -86.790389,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35230,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35231,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35232,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35233,
      "latitude": 33.508205,
      "longitude": -86.802339,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35234,
      "latitude": 33.513505,
      "longitude": -86.83244,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35235,
      "latitude": 33.632816,
      "longitude": -86.649293,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35236,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35237,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35238,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35240,
      "latitude": 33.425892,
      "longitude": -86.776926,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35242,
      "latitude": 33.381339,
      "longitude": -86.704571,
      "city": "Birmingham",
      "state": "AL",
      "county": "Shelby"
  },
  {
      "zipCode": 35243,
      "latitude": 33.445932,
      "longitude": -86.750203,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35244,
      "latitude": 33.351944,
      "longitude": -86.949784,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35245,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35246,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35249,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35253,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35254,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35255,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35259,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35260,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35261,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35263,
      "latitude": 33.522505,
      "longitude": -86.80939,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35266,
      "latitude": 33.372861,
      "longitude": -86.853075,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35277,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35278,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35279,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35280,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35281,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35282,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35283,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35285,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35286,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35287,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35288,
      "latitude": 33.445808,
      "longitude": -86.928542,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35289,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35290,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35291,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35292,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35293,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35294,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35295,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35296,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35297,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35298,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35299,
      "latitude": 33.544622,
      "longitude": -86.929208,
      "city": "Birmingham",
      "state": "AL",
      "county": "Jefferson"
  },
  {
      "zipCode": 35401,
      "latitude": 33.241899,
      "longitude": -87.597599,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35402,
      "latitude": 33.306606,
      "longitude": -87.453224,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35403,
      "latitude": 33.20445,
      "longitude": -87.526953,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35404,
      "latitude": 33.182563,
      "longitude": -87.482744,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35405,
      "latitude": 33.10407,
      "longitude": -87.518144,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35406,
      "latitude": 33.335596,
      "longitude": -87.533495,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35407,
      "latitude": 33.306606,
      "longitude": -87.453224,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35440,
      "latitude": 33.311014,
      "longitude": -87.18975,
      "city": "Abernant",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35441,
      "latitude": 32.855347,
      "longitude": -87.721868,
      "city": "Akron",
      "state": "AL",
      "county": "Hale"
  },
  {
      "zipCode": 35442,
      "latitude": 33.236962,
      "longitude": -88.061517,
      "city": "Aliceville",
      "state": "AL",
      "county": "Pickens"
  },
  {
      "zipCode": 35443,
      "latitude": 32.737366,
      "longitude": -87.967724,
      "city": "Boligee",
      "state": "AL",
      "county": "Greene"
  },
  {
      "zipCode": 35444,
      "latitude": 33.333139,
      "longitude": -87.277818,
      "city": "Brookwood",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35446,
      "latitude": 33.230568,
      "longitude": -87.754733,
      "city": "Buhl",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35447,
      "latitude": 33.289682,
      "longitude": -88.117113,
      "city": "Carrollton",
      "state": "AL",
      "county": "Pickens"
  },
  {
      "zipCode": 35448,
      "latitude": 32.835692,
      "longitude": -87.956884,
      "city": "Clinton",
      "state": "AL",
      "county": "Greene"
  },
  {
      "zipCode": 35449,
      "latitude": 33.135876,
      "longitude": -87.338369,
      "city": "Coaling",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35452,
      "latitude": 33.293358,
      "longitude": -87.688789,
      "city": "Coker",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35453,
      "latitude": 33.18178,
      "longitude": -87.383843,
      "city": "Cottondale",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35456,
      "latitude": 33.072735,
      "longitude": -87.432921,
      "city": "Duncanville",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35457,
      "latitude": 33.294004,
      "longitude": -87.776584,
      "city": "Echola",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35458,
      "latitude": 33.367757,
      "longitude": -87.801689,
      "city": "Elrod",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35459,
      "latitude": 32.621168,
      "longitude": -88.285932,
      "city": "Emelle",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 35460,
      "latitude": 32.672049,
      "longitude": -88.120605,
      "city": "Epes",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 35461,
      "latitude": 33.413277,
      "longitude": -88.209276,
      "city": "Ethelsville",
      "state": "AL",
      "county": "Pickens"
  },
  {
      "zipCode": 35462,
      "latitude": 32.836298,
      "longitude": -87.893016,
      "city": "Eutaw",
      "state": "AL",
      "county": "Greene"
  },
  {
      "zipCode": 35463,
      "latitude": 33.084337,
      "longitude": -87.697507,
      "city": "Fosters",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35464,
      "latitude": 32.778274,
      "longitude": -88.145715,
      "city": "Gainesville",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 35466,
      "latitude": 33.290717,
      "longitude": -87.875835,
      "city": "Gordo",
      "state": "AL",
      "county": "Pickens"
  },
  {
      "zipCode": 35468,
      "latitude": 33.306606,
      "longitude": -87.453224,
      "city": "Kellerman",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35469,
      "latitude": 32.835692,
      "longitude": -87.956884,
      "city": "Knoxville",
      "state": "AL",
      "county": "Greene"
  },
  {
      "zipCode": 35470,
      "latitude": 32.571432,
      "longitude": -88.133322,
      "city": "Livingston",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 35471,
      "latitude": 33.262603,
      "longitude": -88.088514,
      "city": "Mc Shan",
      "state": "AL",
      "county": "Pickens"
  },
  {
      "zipCode": 35473,
      "latitude": 33.347539,
      "longitude": -87.537152,
      "city": "Northport",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35474,
      "latitude": 32.910756,
      "longitude": -87.593514,
      "city": "Moundville",
      "state": "AL",
      "county": "Hale"
  },
  {
      "zipCode": 35475,
      "latitude": 33.39924,
      "longitude": -87.559894,
      "city": "Northport",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35476,
      "latitude": 33.356502,
      "longitude": -87.538006,
      "city": "Northport",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35477,
      "latitude": 32.872597,
      "longitude": -88.237388,
      "city": "Panola",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 35478,
      "latitude": 33.306606,
      "longitude": -87.453224,
      "city": "Peterson",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35480,
      "latitude": 33.127407,
      "longitude": -87.762393,
      "city": "Ralph",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35481,
      "latitude": 33.314928,
      "longitude": -88.042923,
      "city": "Reform",
      "state": "AL",
      "county": "Pickens"
  },
  {
      "zipCode": 35482,
      "latitude": 33.306606,
      "longitude": -87.453224,
      "city": "Samantha",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35485,
      "latitude": 33.306606,
      "longitude": -87.453224,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35486,
      "latitude": 33.272662,
      "longitude": -87.793794,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35487,
      "latitude": 33.307222,
      "longitude": -87.585902,
      "city": "Tuscaloosa",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35490,
      "latitude": 33.226133,
      "longitude": -87.225842,
      "city": "Vance",
      "state": "AL",
      "county": "Tuscaloosa"
  },
  {
      "zipCode": 35491,
      "latitude": 32.835692,
      "longitude": -87.956884,
      "city": "West Greene",
      "state": "AL",
      "county": "Greene"
  },
  {
      "zipCode": 35501,
      "latitude": 33.796234,
      "longitude": -87.316923,
      "city": "Jasper",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35502,
      "latitude": 33.901993,
      "longitude": -87.293888,
      "city": "Jasper",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35503,
      "latitude": 33.915182,
      "longitude": -87.307386,
      "city": "Jasper",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35504,
      "latitude": 33.818309,
      "longitude": -87.235474,
      "city": "Jasper",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35540,
      "latitude": 34.229897,
      "longitude": -87.358538,
      "city": "Addison",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35541,
      "latitude": 34.095345,
      "longitude": -87.188237,
      "city": "Arley",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35542,
      "latitude": 33.712675,
      "longitude": -87.774117,
      "city": "Bankston",
      "state": "AL",
      "county": "Fayette"
  },
  {
      "zipCode": 35543,
      "latitude": 34.16493,
      "longitude": -87.739592,
      "city": "Bear Creek",
      "state": "AL",
      "county": "Marion"
  },
  {
      "zipCode": 35544,
      "latitude": 33.950211,
      "longitude": -88.018981,
      "city": "Beaverton",
      "state": "AL",
      "county": "Lamar"
  },
  {
      "zipCode": 35545,
      "latitude": 33.645228,
      "longitude": -87.923872,
      "city": "Belk",
      "state": "AL",
      "county": "Fayette"
  },
  {
      "zipCode": 35546,
      "latitude": 33.634468,
      "longitude": -87.676862,
      "city": "Berry",
      "state": "AL",
      "county": "Fayette"
  },
  {
      "zipCode": 35548,
      "latitude": 34.038126,
      "longitude": -87.75667,
      "city": "Brilliant",
      "state": "AL",
      "county": "Marion"
  },
  {
      "zipCode": 35549,
      "latitude": 33.772696,
      "longitude": -87.455456,
      "city": "Carbon Hill",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35550,
      "latitude": 33.740956,
      "longitude": -87.225644,
      "city": "Cordova",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35551,
      "latitude": 34.145939,
      "longitude": -87.373241,
      "city": "Delmar",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35552,
      "latitude": 34.025325,
      "longitude": -88.153299,
      "city": "Detroit",
      "state": "AL",
      "county": "Lamar"
  },
  {
      "zipCode": 35553,
      "latitude": 34.121357,
      "longitude": -87.360437,
      "city": "Double Springs",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35554,
      "latitude": 33.92856,
      "longitude": -87.613508,
      "city": "Eldridge",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35555,
      "latitude": 33.72005,
      "longitude": -87.809908,
      "city": "Fayette",
      "state": "AL",
      "county": "Fayette"
  },
  {
      "zipCode": 35559,
      "latitude": 33.889566,
      "longitude": -87.696401,
      "city": "Glen Allen",
      "state": "AL",
      "county": "Fayette"
  },
  {
      "zipCode": 35560,
      "latitude": 33.679503,
      "longitude": -87.237372,
      "city": "Goodsprings",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35563,
      "latitude": 34.078901,
      "longitude": -87.813808,
      "city": "Guin",
      "state": "AL",
      "county": "Marion"
  },
  {
      "zipCode": 35564,
      "latitude": 34.122514,
      "longitude": -87.828236,
      "city": "Hackleburg",
      "state": "AL",
      "county": "Marion"
  },
  {
      "zipCode": 35565,
      "latitude": 34.179569,
      "longitude": -87.505293,
      "city": "Haleyville",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35570,
      "latitude": 34.167583,
      "longitude": -87.994648,
      "city": "Hamilton",
      "state": "AL",
      "county": "Marion"
  },
  {
      "zipCode": 35571,
      "latitude": 34.373392,
      "longitude": -87.946916,
      "city": "Hodges",
      "state": "AL",
      "county": "Franklin"
  },
  {
      "zipCode": 35572,
      "latitude": 34.188775,
      "longitude": -87.2786,
      "city": "Houston",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35573,
      "latitude": 33.92169,
      "longitude": -87.524264,
      "city": "Kansas",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35574,
      "latitude": 33.615308,
      "longitude": -87.99477,
      "city": "Kennedy",
      "state": "AL",
      "county": "Lamar"
  },
  {
      "zipCode": 35575,
      "latitude": 34.049571,
      "longitude": -87.552646,
      "city": "Lynn",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35576,
      "latitude": 33.606342,
      "longitude": -88.131425,
      "city": "Millport",
      "state": "AL",
      "county": "Lamar"
  },
  {
      "zipCode": 35577,
      "latitude": 34.093409,
      "longitude": -87.600815,
      "city": "Natural Bridge",
      "state": "AL",
      "county": "Winston"
  },
  {
      "zipCode": 35578,
      "latitude": 33.885529,
      "longitude": -87.469627,
      "city": "Nauvoo",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35579,
      "latitude": 33.664909,
      "longitude": -87.376844,
      "city": "Oakman",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35580,
      "latitude": 33.713348,
      "longitude": -87.298601,
      "city": "Parrish",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35581,
      "latitude": 34.390917,
      "longitude": -87.859392,
      "city": "Phil Campbell",
      "state": "AL",
      "county": "Franklin"
  },
  {
      "zipCode": 35582,
      "latitude": 34.453468,
      "longitude": -88.067792,
      "city": "Red Bay",
      "state": "AL",
      "county": "Franklin"
  },
  {
      "zipCode": 35584,
      "latitude": 33.820144,
      "longitude": -87.077898,
      "city": "Sipsey",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35585,
      "latitude": 34.424572,
      "longitude": -87.81446,
      "city": "Spruce Pine",
      "state": "AL",
      "county": "Franklin"
  },
  {
      "zipCode": 35586,
      "latitude": 33.854224,
      "longitude": -88.10667,
      "city": "Sulligent",
      "state": "AL",
      "county": "Lamar"
  },
  {
      "zipCode": 35587,
      "latitude": 33.793322,
      "longitude": -87.440648,
      "city": "Townley",
      "state": "AL",
      "county": "Walker"
  },
  {
      "zipCode": 35592,
      "latitude": 33.732286,
      "longitude": -88.086009,
      "city": "Vernon",
      "state": "AL",
      "county": "Lamar"
  },
  {
      "zipCode": 35593,
      "latitude": 34.404307,
      "longitude": -87.954816,
      "city": "Vina",
      "state": "AL",
      "county": "Franklin"
  },
  {
      "zipCode": 35594,
      "latitude": 34.062,
      "longitude": -87.814078,
      "city": "Winfield",
      "state": "AL",
      "county": "Marion"
  },
  {
      "zipCode": 35601,
      "latitude": 34.549833,
      "longitude": -86.995551,
      "city": "Decatur",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35602,
      "latitude": 34.606216,
      "longitude": -87.088142,
      "city": "Decatur",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35603,
      "latitude": 34.53832,
      "longitude": -86.96556,
      "city": "Decatur",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35609,
      "latitude": 34.495365,
      "longitude": -86.82993,
      "city": "Decatur",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35610,
      "latitude": 34.942388,
      "longitude": -87.273375,
      "city": "Anderson",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35611,
      "latitude": 34.764976,
      "longitude": -87.103488,
      "city": "Athens",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35612,
      "latitude": 34.774907,
      "longitude": -87.030479,
      "city": "Athens",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35613,
      "latitude": 34.831704,
      "longitude": -86.886679,
      "city": "Athens",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35614,
      "latitude": 34.847364,
      "longitude": -87.060696,
      "city": "Athens",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35615,
      "latitude": 34.774907,
      "longitude": -87.030479,
      "city": "Belle Mina",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35616,
      "latitude": 34.733866,
      "longitude": -87.90624,
      "city": "Cherokee",
      "state": "AL",
      "county": "Colbert"
  },
  {
      "zipCode": 35617,
      "latitude": 34.869964,
      "longitude": -87.706859,
      "city": "Cloverdale",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35618,
      "latitude": 34.673954,
      "longitude": -87.28962,
      "city": "Courtland",
      "state": "AL",
      "county": "Lawrence"
  },
  {
      "zipCode": 35619,
      "latitude": 34.459389,
      "longitude": -87.054151,
      "city": "Danville",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35620,
      "latitude": 34.899993,
      "longitude": -86.972554,
      "city": "Elkmont",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35621,
      "latitude": 34.367528,
      "longitude": -86.73641,
      "city": "Eva",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35622,
      "latitude": 34.361909,
      "longitude": -86.899143,
      "city": "Falkville",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35630,
      "latitude": 34.869601,
      "longitude": -87.686915,
      "city": "Florence",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35631,
      "latitude": 34.869964,
      "longitude": -87.706859,
      "city": "Florence",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35632,
      "latitude": 34.869964,
      "longitude": -87.706859,
      "city": "Florence",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35633,
      "latitude": 34.880706,
      "longitude": -87.742362,
      "city": "Florence",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35634,
      "latitude": 34.900446,
      "longitude": -87.794596,
      "city": "Florence",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35640,
      "latitude": 34.491606,
      "longitude": -86.934715,
      "city": "Hartselle",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35643,
      "latitude": 34.635459,
      "longitude": -87.267871,
      "city": "Hillsboro",
      "state": "AL",
      "county": "Lawrence"
  },
  {
      "zipCode": 35645,
      "latitude": 34.907715,
      "longitude": -87.50135,
      "city": "Killen",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35646,
      "latitude": 34.677233,
      "longitude": -87.53399,
      "city": "Leighton",
      "state": "AL",
      "county": "Colbert"
  },
  {
      "zipCode": 35647,
      "latitude": 34.959647,
      "longitude": -87.100579,
      "city": "Lester",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35648,
      "latitude": 34.944077,
      "longitude": -87.365663,
      "city": "Lexington",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35649,
      "latitude": 34.625877,
      "longitude": -86.874641,
      "city": "Mooresville",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35650,
      "latitude": 34.492894,
      "longitude": -87.299281,
      "city": "Moulton",
      "state": "AL",
      "county": "Lawrence"
  },
  {
      "zipCode": 35651,
      "latitude": 34.47926,
      "longitude": -87.476911,
      "city": "Mount Hope",
      "state": "AL",
      "county": "Lawrence"
  },
  {
      "zipCode": 35652,
      "latitude": 34.844868,
      "longitude": -87.321426,
      "city": "Rogersville",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35653,
      "latitude": 34.444903,
      "longitude": -87.854726,
      "city": "Russellville",
      "state": "AL",
      "county": "Franklin"
  },
  {
      "zipCode": 35654,
      "latitude": 34.46752,
      "longitude": -87.647762,
      "city": "Russellville",
      "state": "AL",
      "county": "Franklin"
  },
  {
      "zipCode": 35660,
      "latitude": 34.691165,
      "longitude": -87.710054,
      "city": "Sheffield",
      "state": "AL",
      "county": "Colbert"
  },
  {
      "zipCode": 35661,
      "latitude": 34.758537,
      "longitude": -87.70004,
      "city": "Muscle Shoals",
      "state": "AL",
      "county": "Colbert"
  },
  {
      "zipCode": 35662,
      "latitude": 34.757152,
      "longitude": -87.966575,
      "city": "Muscle Shoals",
      "state": "AL",
      "county": "Colbert"
  },
  {
      "zipCode": 35670,
      "latitude": 34.436256,
      "longitude": -86.729737,
      "city": "Somerville",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35671,
      "latitude": 34.679634,
      "longitude": -86.962519,
      "city": "Tanner",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35672,
      "latitude": 34.633251,
      "longitude": -87.418659,
      "city": "Town Creek",
      "state": "AL",
      "county": "Lawrence"
  },
  {
      "zipCode": 35673,
      "latitude": 34.591393,
      "longitude": -87.078896,
      "city": "Trinity",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35674,
      "latitude": 34.66604,
      "longitude": -87.749232,
      "city": "Tuscumbia",
      "state": "AL",
      "county": "Colbert"
  },
  {
      "zipCode": 35677,
      "latitude": 34.925311,
      "longitude": -87.991142,
      "city": "Waterloo",
      "state": "AL",
      "county": "Lauderdale"
  },
  {
      "zipCode": 35699,
      "latitude": 34.495365,
      "longitude": -86.82993,
      "city": "Decatur",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35739,
      "latitude": 34.961697,
      "longitude": -86.844257,
      "city": "Ardmore",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35740,
      "latitude": 34.799983,
      "longitude": -85.803464,
      "city": "Bridgeport",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35741,
      "latitude": 34.719303,
      "longitude": -86.475425,
      "city": "Brownsboro",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35742,
      "latitude": 34.774907,
      "longitude": -87.030479,
      "city": "Capshaw",
      "state": "AL",
      "county": "Limestone"
  },
  {
      "zipCode": 35744,
      "latitude": 34.60335,
      "longitude": -85.911106,
      "city": "Dutton",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35745,
      "latitude": 34.925315,
      "longitude": -86.190432,
      "city": "Estillfork",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35746,
      "latitude": 34.75343,
      "longitude": -85.98022,
      "city": "Fackler",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35747,
      "latitude": 34.41847,
      "longitude": -86.289976,
      "city": "Grant",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35748,
      "latitude": 34.722737,
      "longitude": -86.40353,
      "city": "Gurley",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35749,
      "latitude": 34.829768,
      "longitude": -86.703349,
      "city": "Harvest",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35750,
      "latitude": 34.937132,
      "longitude": -86.581015,
      "city": "Hazel Green",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35751,
      "latitude": 34.79549,
      "longitude": -86.274287,
      "city": "Hollytree",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35752,
      "latitude": 34.807443,
      "longitude": -85.887684,
      "city": "Hollywood",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35754,
      "latitude": 34.50544,
      "longitude": -86.62488,
      "city": "Laceys Spring",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35755,
      "latitude": 34.733585,
      "longitude": -86.020239,
      "city": "Langston",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35756,
      "latitude": 34.651409,
      "longitude": -86.754233,
      "city": "Madison",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35757,
      "latitude": 34.778246,
      "longitude": -86.744163,
      "city": "Madison",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35758,
      "latitude": 34.710815,
      "longitude": -86.742478,
      "city": "Madison",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35759,
      "latitude": 34.88186,
      "longitude": -86.555622,
      "city": "Meridianville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35760,
      "latitude": 34.56112,
      "longitude": -86.385064,
      "city": "New Hope",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35761,
      "latitude": 34.893332,
      "longitude": -86.452042,
      "city": "New Market",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35762,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Normal",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35763,
      "latitude": 34.724161,
      "longitude": -86.464438,
      "city": "Owens Cross Roads",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35764,
      "latitude": 34.703063,
      "longitude": -86.306794,
      "city": "Paint Rock",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35765,
      "latitude": 34.669329,
      "longitude": -86.016699,
      "city": "Pisgah",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35766,
      "latitude": 34.871329,
      "longitude": -86.251275,
      "city": "Princeton",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35767,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Ryland",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35768,
      "latitude": 34.750724,
      "longitude": -86.142151,
      "city": "Scottsboro",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35769,
      "latitude": 34.617951,
      "longitude": -86.056162,
      "city": "Scottsboro",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35771,
      "latitude": 34.671536,
      "longitude": -85.958355,
      "city": "Section",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35772,
      "latitude": 34.856233,
      "longitude": -85.871317,
      "city": "Stevenson",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35773,
      "latitude": 34.904492,
      "longitude": -86.681104,
      "city": "Toney",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35774,
      "latitude": 34.717551,
      "longitude": -86.073788,
      "city": "Trenton",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35775,
      "latitude": 34.541985,
      "longitude": -86.687136,
      "city": "Valhermoso Springs",
      "state": "AL",
      "county": "Morgan"
  },
  {
      "zipCode": 35776,
      "latitude": 34.746425,
      "longitude": -86.076117,
      "city": "Woodville",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35801,
      "latitude": 34.718428,
      "longitude": -86.556439,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35802,
      "latitude": 34.660866,
      "longitude": -86.560608,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35803,
      "latitude": 34.58639,
      "longitude": -86.536754,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35804,
      "latitude": 34.728366,
      "longitude": -86.585312,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35805,
      "latitude": 34.702327,
      "longitude": -86.628864,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35806,
      "latitude": 34.76184,
      "longitude": -86.686994,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35807,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35808,
      "latitude": 34.677627,
      "longitude": -86.612147,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35809,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35810,
      "latitude": 34.797685,
      "longitude": -86.593583,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35811,
      "latitude": 34.791497,
      "longitude": -86.513152,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35812,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35813,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35814,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35815,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35816,
      "latitude": 34.753078,
      "longitude": -86.628643,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35824,
      "latitude": 34.645603,
      "longitude": -86.752294,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35893,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35894,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35895,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35896,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35897,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35898,
      "latitude": 34.733964,
      "longitude": -86.522913,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35899,
      "latitude": 34.733898,
      "longitude": -86.645592,
      "city": "Huntsville",
      "state": "AL",
      "county": "Madison"
  },
  {
      "zipCode": 35901,
      "latitude": 34.024285,
      "longitude": -86.074145,
      "city": "Gadsden",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35902,
      "latitude": 33.984062,
      "longitude": -85.803434,
      "city": "Gadsden",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35903,
      "latitude": 33.984547,
      "longitude": -85.907668,
      "city": "Gadsden",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35904,
      "latitude": 34.05744,
      "longitude": -86.107869,
      "city": "Gadsden",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35905,
      "latitude": 33.93523,
      "longitude": -85.957593,
      "city": "Gadsden",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35906,
      "latitude": 34.033764,
      "longitude": -86.085173,
      "city": "Rainbow City",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35907,
      "latitude": 33.959647,
      "longitude": -85.973173,
      "city": "Gadsden",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35950,
      "latitude": 34.314291,
      "longitude": -86.242033,
      "city": "Albertville",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35951,
      "latitude": 34.322334,
      "longitude": -86.193529,
      "city": "Albertville",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35952,
      "latitude": 34.07097,
      "longitude": -86.209599,
      "city": "Altoona",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35953,
      "latitude": 33.785173,
      "longitude": -86.216884,
      "city": "Ashville",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35954,
      "latitude": 34.051848,
      "longitude": -86.061397,
      "city": "Attalla",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35956,
      "latitude": 34.267,
      "longitude": -86.166021,
      "city": "Boaz",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35957,
      "latitude": 34.280737,
      "longitude": -86.229092,
      "city": "Boaz",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35958,
      "latitude": 34.917606,
      "longitude": -85.64924,
      "city": "Bryant",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35959,
      "latitude": 34.359072,
      "longitude": -85.611793,
      "city": "Cedar Bluff",
      "state": "AL",
      "county": "Cherokee"
  },
  {
      "zipCode": 35960,
      "latitude": 34.110859,
      "longitude": -85.590218,
      "city": "Centre",
      "state": "AL",
      "county": "Cherokee"
  },
  {
      "zipCode": 35961,
      "latitude": 34.337295,
      "longitude": -85.85769,
      "city": "Collinsville",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35962,
      "latitude": 34.302028,
      "longitude": -86.004164,
      "city": "Crossville",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35963,
      "latitude": 34.359123,
      "longitude": -85.923978,
      "city": "Dawson",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35964,
      "latitude": 34.34962,
      "longitude": -86.319824,
      "city": "Douglas",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35966,
      "latitude": 34.803085,
      "longitude": -85.720245,
      "city": "Flat Rock",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35967,
      "latitude": 34.417112,
      "longitude": -85.828068,
      "city": "Fort Payne",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35968,
      "latitude": 34.507242,
      "longitude": -85.797851,
      "city": "Fort Payne",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35971,
      "latitude": 34.43038,
      "longitude": -85.898669,
      "city": "Fyffe",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35972,
      "latitude": 34.00233,
      "longitude": -86.224672,
      "city": "Gallant",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 35973,
      "latitude": 34.373726,
      "longitude": -85.557307,
      "city": "Gaylesville",
      "state": "AL",
      "county": "Cherokee"
  },
  {
      "zipCode": 35974,
      "latitude": 34.359204,
      "longitude": -86.02397,
      "city": "Geraldine",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35975,
      "latitude": 34.435197,
      "longitude": -86.038915,
      "city": "Groveoak",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35976,
      "latitude": 34.344851,
      "longitude": -86.27518,
      "city": "Guntersville",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35978,
      "latitude": 34.600393,
      "longitude": -85.730615,
      "city": "Henagar",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35979,
      "latitude": 34.839084,
      "longitude": -85.619582,
      "city": "Higdon",
      "state": "AL",
      "county": "Jackson"
  },
  {
      "zipCode": 35980,
      "latitude": 34.209898,
      "longitude": -86.343937,
      "city": "Horton",
      "state": "AL",
      "county": "Marshall"
  },
  {
      "zipCode": 35981,
      "latitude": 34.719358,
      "longitude": -85.658943,
      "city": "Ider",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35983,
      "latitude": 34.144013,
      "longitude": -85.748018,
      "city": "Leesburg",
      "state": "AL",
      "county": "Cherokee"
  },
  {
      "zipCode": 35984,
      "latitude": 34.556856,
      "longitude": -85.578584,
      "city": "Mentone",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35986,
      "latitude": 34.419163,
      "longitude": -85.761585,
      "city": "Rainsville",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35987,
      "latitude": 33.916778,
      "longitude": -86.243593,
      "city": "Steele",
      "state": "AL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 35988,
      "latitude": 34.570198,
      "longitude": -85.729289,
      "city": "Sylvania",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35989,
      "latitude": 34.4879,
      "longitude": -85.689133,
      "city": "Valley Head",
      "state": "AL",
      "county": "De Kalb"
  },
  {
      "zipCode": 35990,
      "latitude": 34.056618,
      "longitude": -86.275434,
      "city": "Walnut Grove",
      "state": "AL",
      "county": "Etowah"
  },
  {
      "zipCode": 36003,
      "latitude": 32.450328,
      "longitude": -86.721712,
      "city": "Autaugaville",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36005,
      "latitude": 31.839131,
      "longitude": -85.928538,
      "city": "Banks",
      "state": "AL",
      "county": "Pike"
  },
  {
      "zipCode": 36006,
      "latitude": 32.576407,
      "longitude": -86.711472,
      "city": "Billingsley",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36008,
      "latitude": 32.501649,
      "longitude": -86.572711,
      "city": "Booth",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36009,
      "latitude": 31.560143,
      "longitude": -86.327501,
      "city": "Brantley",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36010,
      "latitude": 31.713427,
      "longitude": -85.816261,
      "city": "Brundidge",
      "state": "AL",
      "county": "Pike"
  },
  {
      "zipCode": 36013,
      "latitude": 32.310624,
      "longitude": -86.186132,
      "city": "Cecil",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36015,
      "latitude": 31.642713,
      "longitude": -86.734317,
      "city": "Chapman",
      "state": "AL",
      "county": "Butler"
  },
  {
      "zipCode": 36016,
      "latitude": 31.805322,
      "longitude": -85.421299,
      "city": "Clayton",
      "state": "AL",
      "county": "Barbour"
  },
  {
      "zipCode": 36017,
      "latitude": 31.749142,
      "longitude": -85.561944,
      "city": "Clio",
      "state": "AL",
      "county": "Barbour"
  },
  {
      "zipCode": 36020,
      "latitude": 32.505625,
      "longitude": -86.332245,
      "city": "Coosada",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36022,
      "latitude": 32.614355,
      "longitude": -86.35842,
      "city": "Deatsville",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36023,
      "latitude": 32.800155,
      "longitude": -85.803427,
      "city": "East Tallassee",
      "state": "AL",
      "county": "Tallapoosa"
  },
  {
      "zipCode": 36024,
      "latitude": 32.645178,
      "longitude": -86.036529,
      "city": "Eclectic",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36025,
      "latitude": 32.632954,
      "longitude": -86.134553,
      "city": "Elmore",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36026,
      "latitude": 32.77522,
      "longitude": -86.1216,
      "city": "Equality",
      "state": "AL",
      "county": "Coosa"
  },
  {
      "zipCode": 36027,
      "latitude": 31.914745,
      "longitude": -85.389969,
      "city": "Eufaula",
      "state": "AL",
      "county": "Barbour"
  },
  {
      "zipCode": 36028,
      "latitude": 31.518541,
      "longitude": -86.354365,
      "city": "Dozier",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36029,
      "latitude": 32.092808,
      "longitude": -85.704649,
      "city": "Fitzpatrick",
      "state": "AL",
      "county": "Bullock"
  },
  {
      "zipCode": 36030,
      "latitude": 31.850138,
      "longitude": -86.837211,
      "city": "Forest Home",
      "state": "AL",
      "county": "Butler"
  },
  {
      "zipCode": 36031,
      "latitude": 32.250651,
      "longitude": -85.752417,
      "city": "Fort Davis",
      "state": "AL",
      "county": "Macon"
  },
  {
      "zipCode": 36032,
      "latitude": 32.01285,
      "longitude": -86.608238,
      "city": "Fort Deposit",
      "state": "AL",
      "county": "Lowndes"
  },
  {
      "zipCode": 36033,
      "latitude": 31.624666,
      "longitude": -86.634844,
      "city": "Georgiana",
      "state": "AL",
      "county": "Butler"
  },
  {
      "zipCode": 36034,
      "latitude": 31.611203,
      "longitude": -86.167727,
      "city": "Glenwood",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36035,
      "latitude": 31.810302,
      "longitude": -86.092603,
      "city": "Goshen",
      "state": "AL",
      "county": "Pike"
  },
  {
      "zipCode": 36036,
      "latitude": 32.011697,
      "longitude": -86.166847,
      "city": "Grady",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36037,
      "latitude": 31.796729,
      "longitude": -86.596233,
      "city": "Greenville",
      "state": "AL",
      "county": "Butler"
  },
  {
      "zipCode": 36038,
      "latitude": 31.260882,
      "longitude": -86.444833,
      "city": "Gantt",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36039,
      "latitude": 32.293879,
      "longitude": -85.813517,
      "city": "Hardaway",
      "state": "AL",
      "county": "Macon"
  },
  {
      "zipCode": 36040,
      "latitude": 32.152198,
      "longitude": -86.597157,
      "city": "Hayneville",
      "state": "AL",
      "county": "Lowndes"
  },
  {
      "zipCode": 36041,
      "latitude": 31.945789,
      "longitude": -86.306248,
      "city": "Highland Home",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36042,
      "latitude": 31.871334,
      "longitude": -86.405406,
      "city": "Honoraville",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36043,
      "latitude": 32.206441,
      "longitude": -86.380464,
      "city": "Hope Hull",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36045,
      "latitude": 32.587631,
      "longitude": -86.132981,
      "city": "Kent",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36046,
      "latitude": 32.081409,
      "longitude": -86.356906,
      "city": "Lapine",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36047,
      "latitude": 32.072616,
      "longitude": -86.510331,
      "city": "Letohatchee",
      "state": "AL",
      "county": "Lowndes"
  },
  {
      "zipCode": 36048,
      "latitude": 31.785579,
      "longitude": -85.551104,
      "city": "Louisville",
      "state": "AL",
      "county": "Barbour"
  },
  {
      "zipCode": 36049,
      "latitude": 31.754655,
      "longitude": -86.291595,
      "city": "Luverne",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36051,
      "latitude": 32.601807,
      "longitude": -86.516424,
      "city": "Marbury",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36052,
      "latitude": 32.239196,
      "longitude": -86.036354,
      "city": "Mathews",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36053,
      "latitude": 32.092808,
      "longitude": -85.704649,
      "city": "Midway",
      "state": "AL",
      "county": "Bullock"
  },
  {
      "zipCode": 36054,
      "latitude": 32.584717,
      "longitude": -86.225602,
      "city": "Millbrook",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36057,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Mount Meigs",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36061,
      "latitude": 32.092808,
      "longitude": -85.704649,
      "city": "Perote",
      "state": "AL",
      "county": "Bullock"
  },
  {
      "zipCode": 36062,
      "latitude": 31.74589,
      "longitude": -86.323949,
      "city": "Petrey",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36064,
      "latitude": 32.296092,
      "longitude": -86.09259,
      "city": "Pike Road",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36065,
      "latitude": 32.072585,
      "longitude": -86.060586,
      "city": "Pine Level",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36066,
      "latitude": 32.50919,
      "longitude": -86.440879,
      "city": "Prattville",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36067,
      "latitude": 32.540642,
      "longitude": -86.658347,
      "city": "Prattville",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36068,
      "latitude": 32.507893,
      "longitude": -86.666272,
      "city": "Prattville",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36069,
      "latitude": 32.089321,
      "longitude": -86.174619,
      "city": "Ramer",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36071,
      "latitude": 31.706155,
      "longitude": -86.371489,
      "city": "Rutledge",
      "state": "AL",
      "county": "Crenshaw"
  },
  {
      "zipCode": 36072,
      "latitude": 31.826055,
      "longitude": -85.166028,
      "city": "Eufaula",
      "state": "AL",
      "county": "Barbour"
  },
  {
      "zipCode": 36075,
      "latitude": 32.383939,
      "longitude": -85.910123,
      "city": "Shorter",
      "state": "AL",
      "county": "Macon"
  },
  {
      "zipCode": 36078,
      "latitude": 32.594995,
      "longitude": -85.985078,
      "city": "Tallassee",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36079,
      "latitude": 31.802786,
      "longitude": -85.954983,
      "city": "Troy",
      "state": "AL",
      "county": "Pike"
  },
  {
      "zipCode": 36080,
      "latitude": 32.700393,
      "longitude": -86.257604,
      "city": "Titus",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36081,
      "latitude": 31.869128,
      "longitude": -85.966268,
      "city": "Troy",
      "state": "AL",
      "county": "Pike"
  },
  {
      "zipCode": 36082,
      "latitude": 31.816474,
      "longitude": -85.964713,
      "city": "Troy",
      "state": "AL",
      "county": "Pike"
  },
  {
      "zipCode": 36083,
      "latitude": 32.372144,
      "longitude": -85.66671,
      "city": "Tuskegee",
      "state": "AL",
      "county": "Macon"
  },
  {
      "zipCode": 36087,
      "latitude": 32.40926,
      "longitude": -85.738032,
      "city": "Tuskegee Institute",
      "state": "AL",
      "county": "Macon"
  },
  {
      "zipCode": 36088,
      "latitude": 32.414501,
      "longitude": -85.72525,
      "city": "Tuskegee Institute",
      "state": "AL",
      "county": "Macon"
  },
  {
      "zipCode": 36089,
      "latitude": 32.142191,
      "longitude": -85.742406,
      "city": "Union Springs",
      "state": "AL",
      "county": "Bullock"
  },
  {
      "zipCode": 36091,
      "latitude": 32.776052,
      "longitude": -86.644088,
      "city": "Verbena",
      "state": "AL",
      "county": "Chilton"
  },
  {
      "zipCode": 36092,
      "latitude": 32.592659,
      "longitude": -86.215304,
      "city": "Wetumpka",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36093,
      "latitude": 32.562179,
      "longitude": -86.099371,
      "city": "Wetumpka",
      "state": "AL",
      "county": "Elmore"
  },
  {
      "zipCode": 36101,
      "latitude": 32.356988,
      "longitude": -86.257817,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36102,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36103,
      "latitude": 32.346158,
      "longitude": -86.287316,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36104,
      "latitude": 32.373788,
      "longitude": -86.289182,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36105,
      "latitude": 32.22558,
      "longitude": -86.290586,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36106,
      "latitude": 32.353338,
      "longitude": -86.257517,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36107,
      "latitude": 32.380587,
      "longitude": -86.280718,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36108,
      "latitude": 32.340688,
      "longitude": -86.37382,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36109,
      "latitude": 32.390547,
      "longitude": -86.239667,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36110,
      "latitude": 32.441374,
      "longitude": -86.253327,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36111,
      "latitude": 32.342438,
      "longitude": -86.267317,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36112,
      "latitude": 32.374287,
      "longitude": -86.345719,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36113,
      "latitude": 32.384587,
      "longitude": -86.303868,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36114,
      "latitude": 32.403987,
      "longitude": -86.253867,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36115,
      "latitude": 32.407587,
      "longitude": -86.247417,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36116,
      "latitude": 32.285501,
      "longitude": -86.228765,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36117,
      "latitude": 32.370048,
      "longitude": -86.116486,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36118,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36119,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36120,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36121,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36123,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36124,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36125,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36130,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36131,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36132,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36133,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36134,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36135,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36140,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36141,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36142,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36177,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36191,
      "latitude": 32.233377,
      "longitude": -86.208528,
      "city": "Montgomery",
      "state": "AL",
      "county": "Montgomery"
  },
  {
      "zipCode": 36201,
      "latitude": 33.759768,
      "longitude": -85.792328,
      "city": "Anniston",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36202,
      "latitude": 33.762195,
      "longitude": -85.837828,
      "city": "Anniston",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36203,
      "latitude": 33.725908,
      "longitude": -85.899397,
      "city": "Oxford",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36204,
      "latitude": 33.762195,
      "longitude": -85.837828,
      "city": "Anniston",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36205,
      "latitude": 33.735842,
      "longitude": -85.793257,
      "city": "Anniston",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36206,
      "latitude": 33.806783,
      "longitude": -85.759952,
      "city": "Anniston",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36207,
      "latitude": 33.720373,
      "longitude": -85.753344,
      "city": "Anniston",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36250,
      "latitude": 33.780861,
      "longitude": -85.904456,
      "city": "Alexandria",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36251,
      "latitude": 33.234539,
      "longitude": -85.847477,
      "city": "Ashland",
      "state": "AL",
      "county": "Clay"
  },
  {
      "zipCode": 36253,
      "latitude": 33.762195,
      "longitude": -85.837828,
      "city": "Bynum",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36254,
      "latitude": 33.657349,
      "longitude": -85.699116,
      "city": "Choccolocco",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36255,
      "latitude": 33.193639,
      "longitude": -85.745014,
      "city": "Cragford",
      "state": "AL",
      "county": "Clay"
  },
  {
      "zipCode": 36256,
      "latitude": 33.022741,
      "longitude": -85.696391,
      "city": "Daviston",
      "state": "AL",
      "county": "Tallapoosa"
  },
  {
      "zipCode": 36257,
      "latitude": 33.762195,
      "longitude": -85.837828,
      "city": "De Armanville",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36258,
      "latitude": 33.418514,
      "longitude": -85.711041,
      "city": "Delta",
      "state": "AL",
      "county": "Clay"
  },
  {
      "zipCode": 36260,
      "latitude": 33.640094,
      "longitude": -85.991753,
      "city": "Eastaboga",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36261,
      "latitude": 33.706595,
      "longitude": -85.509902,
      "city": "Edwardsville",
      "state": "AL",
      "county": "Cleburne"
  },
  {
      "zipCode": 36262,
      "latitude": 33.779335,
      "longitude": -85.463112,
      "city": "Fruithurst",
      "state": "AL",
      "county": "Cleburne"
  },
  {
      "zipCode": 36263,
      "latitude": 33.456308,
      "longitude": -85.369167,
      "city": "Graham",
      "state": "AL",
      "county": "Randolph"
  },
  {
      "zipCode": 36264,
      "latitude": 33.627166,
      "longitude": -85.502022,
      "city": "Heflin",
      "state": "AL",
      "county": "Cleburne"
  },
  {
      "zipCode": 36265,
      "latitude": 33.853627,
      "longitude": -85.758295,
      "city": "Jacksonville",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36266,
      "latitude": 33.340127,
      "longitude": -85.789238,
      "city": "Lineville",
      "state": "AL",
      "county": "Clay"
  },
  {
      "zipCode": 36267,
      "latitude": 33.18082,
      "longitude": -85.941752,
      "city": "Millerville",
      "state": "AL",
      "county": "Clay"
  },
  {
      "zipCode": 36268,
      "latitude": 33.486183,
      "longitude": -85.920854,
      "city": "Munford",
      "state": "AL",
      "county": "Talladega"
  },
  {
      "zipCode": 36269,
      "latitude": 33.729152,
      "longitude": -85.394198,
      "city": "Muscadine",
      "state": "AL",
      "county": "Cleburne"
  },
  {
      "zipCode": 36270,
      "latitude": 33.42978,
      "longitude": -85.468493,
      "city": "Newell",
      "state": "AL",
      "county": "Randolph"
  },
  {
      "zipCode": 36271,
      "latitude": 33.807215,
      "longitude": -85.905573,
      "city": "Ohatchee",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36272,
      "latitude": 33.827328,
      "longitude": -85.795257,
      "city": "Piedmont",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36273,
      "latitude": 33.565683,
      "longitude": -85.38201,
      "city": "Ranburne",
      "state": "AL",
      "county": "Cleburne"
  },
  {
      "zipCode": 36274,
      "latitude": 33.216876,
      "longitude": -85.405707,
      "city": "Roanoke",
      "state": "AL",
      "county": "Randolph"
  },
  {
      "zipCode": 36275,
      "latitude": 33.977829,
      "longitude": -85.552143,
      "city": "Spring Garden",
      "state": "AL",
      "county": "Cherokee"
  },
  {
      "zipCode": 36276,
      "latitude": 33.207578,
      "longitude": -85.480892,
      "city": "Wadley",
      "state": "AL",
      "county": "Randolph"
  },
  {
      "zipCode": 36277,
      "latitude": 33.81408,
      "longitude": -85.835693,
      "city": "Weaver",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36278,
      "latitude": 33.318053,
      "longitude": -85.498377,
      "city": "Wedowee",
      "state": "AL",
      "county": "Randolph"
  },
  {
      "zipCode": 36279,
      "latitude": 33.866182,
      "longitude": -85.87097,
      "city": "Wellington",
      "state": "AL",
      "county": "Calhoun"
  },
  {
      "zipCode": 36280,
      "latitude": 33.351626,
      "longitude": -85.372497,
      "city": "Woodland",
      "state": "AL",
      "county": "Randolph"
  },
  {
      "zipCode": 36301,
      "latitude": 31.148124,
      "longitude": -85.371845,
      "city": "Dothan",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36302,
      "latitude": 31.156018,
      "longitude": -85.355931,
      "city": "Dothan",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36303,
      "latitude": 31.160148,
      "longitude": -85.322984,
      "city": "Dothan",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36304,
      "latitude": 31.156018,
      "longitude": -85.355931,
      "city": "Dothan",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36305,
      "latitude": 31.219697,
      "longitude": -85.4828,
      "city": "Dothan",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36310,
      "latitude": 31.559402,
      "longitude": -85.222965,
      "city": "Abbeville",
      "state": "AL",
      "county": "Henry"
  },
  {
      "zipCode": 36311,
      "latitude": 31.455485,
      "longitude": -85.633907,
      "city": "Ariton",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36312,
      "latitude": 31.189627,
      "longitude": -85.262314,
      "city": "Ashford",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36313,
      "latitude": 31.150094,
      "longitude": -85.813687,
      "city": "Bellwood",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36314,
      "latitude": 31.096354,
      "longitude": -85.83934,
      "city": "Black",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36316,
      "latitude": 31.176749,
      "longitude": -85.813483,
      "city": "Chancellor",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36317,
      "latitude": 31.407872,
      "longitude": -85.603458,
      "city": "Clopton",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36318,
      "latitude": 31.149764,
      "longitude": -85.946093,
      "city": "Coffee Springs",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36319,
      "latitude": 31.22514,
      "longitude": -85.171875,
      "city": "Columbia",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36320,
      "latitude": 31.099623,
      "longitude": -85.314295,
      "city": "Cottonwood",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36321,
      "latitude": 31.202473,
      "longitude": -85.301373,
      "city": "Cowarts",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36322,
      "latitude": 31.272415,
      "longitude": -85.705129,
      "city": "Daleville",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36323,
      "latitude": 31.407434,
      "longitude": -86.083517,
      "city": "Elba",
      "state": "AL",
      "county": "Coffee"
  },
  {
      "zipCode": 36330,
      "latitude": 31.315846,
      "longitude": -85.942956,
      "city": "Enterprise",
      "state": "AL",
      "county": "Coffee"
  },
  {
      "zipCode": 36331,
      "latitude": 31.297817,
      "longitude": -85.90364,
      "city": "Enterprise",
      "state": "AL",
      "county": "Coffee"
  },
  {
      "zipCode": 36340,
      "latitude": 31.053473,
      "longitude": -85.784393,
      "city": "Geneva",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36343,
      "latitude": 31.080374,
      "longitude": -85.168005,
      "city": "Gordon",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36344,
      "latitude": 31.105822,
      "longitude": -85.690252,
      "city": "Hartford",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36345,
      "latitude": 31.357917,
      "longitude": -85.30124,
      "city": "Headland",
      "state": "AL",
      "county": "Henry"
  },
  {
      "zipCode": 36346,
      "latitude": 31.565157,
      "longitude": -85.970956,
      "city": "Jack",
      "state": "AL",
      "county": "Coffee"
  },
  {
      "zipCode": 36349,
      "latitude": 31.121244,
      "longitude": -85.559235,
      "city": "Malvern",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36350,
      "latitude": 31.33494,
      "longitude": -85.511331,
      "city": "Midland City",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36351,
      "latitude": 31.398322,
      "longitude": -85.927933,
      "city": "New Brockton",
      "state": "AL",
      "county": "Coffee"
  },
  {
      "zipCode": 36352,
      "latitude": 31.352897,
      "longitude": -85.659319,
      "city": "Newton",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36353,
      "latitude": 31.411109,
      "longitude": -85.366472,
      "city": "Newville",
      "state": "AL",
      "county": "Henry"
  },
  {
      "zipCode": 36360,
      "latitude": 31.450171,
      "longitude": -85.65182,
      "city": "Ozark",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36361,
      "latitude": 31.407872,
      "longitude": -85.603458,
      "city": "Ozark",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36362,
      "latitude": 31.327347,
      "longitude": -85.737811,
      "city": "Fort Rucker",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36370,
      "latitude": 31.14031,
      "longitude": -85.157345,
      "city": "Pansey",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36371,
      "latitude": 31.307446,
      "longitude": -85.52633,
      "city": "Pinckard",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36373,
      "latitude": 31.501042,
      "longitude": -85.113506,
      "city": "Shorterville",
      "state": "AL",
      "county": "Henry"
  },
  {
      "zipCode": 36374,
      "latitude": 31.574779,
      "longitude": -85.536957,
      "city": "Skipperville",
      "state": "AL",
      "county": "Dale"
  },
  {
      "zipCode": 36375,
      "latitude": 31.137138,
      "longitude": -85.564508,
      "city": "Slocomb",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36376,
      "latitude": 31.252306,
      "longitude": -85.264387,
      "city": "Webb",
      "state": "AL",
      "county": "Houston"
  },
  {
      "zipCode": 36401,
      "latitude": 31.489241,
      "longitude": -87.052004,
      "city": "Evergreen",
      "state": "AL",
      "county": "Conecuh"
  },
  {
      "zipCode": 36420,
      "latitude": 31.304166,
      "longitude": -86.386398,
      "city": "Andalusia",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36425,
      "latitude": 31.56779,
      "longitude": -87.250028,
      "city": "Beatrice",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36426,
      "latitude": 31.129427,
      "longitude": -87.096126,
      "city": "Brewton",
      "state": "AL",
      "county": "Escambia"
  },
  {
      "zipCode": 36427,
      "latitude": 31.091784,
      "longitude": -87.26404,
      "city": "Brewton",
      "state": "AL",
      "county": "Escambia"
  },
  {
      "zipCode": 36429,
      "latitude": 31.466945,
      "longitude": -87.044687,
      "city": "Brooklyn",
      "state": "AL",
      "county": "Conecuh"
  },
  {
      "zipCode": 36431,
      "latitude": 31.529531,
      "longitude": -87.345345,
      "city": "Burnt Corn",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36432,
      "latitude": 31.370941,
      "longitude": -86.998104,
      "city": "Castleberry",
      "state": "AL",
      "county": "Conecuh"
  },
  {
      "zipCode": 36435,
      "latitude": 31.871803,
      "longitude": -87.412893,
      "city": "Coy",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36436,
      "latitude": 31.58842,
      "longitude": -87.840875,
      "city": "Dickinson",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36439,
      "latitude": 31.429472,
      "longitude": -87.339329,
      "city": "Excel",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36441,
      "latitude": 31.060013,
      "longitude": -87.199942,
      "city": "Flomaton",
      "state": "AL",
      "county": "Escambia"
  },
  {
      "zipCode": 36442,
      "latitude": 31.019653,
      "longitude": -86.329568,
      "city": "Florala",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36444,
      "latitude": 31.590428,
      "longitude": -87.447935,
      "city": "Franklin",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36445,
      "latitude": 31.522005,
      "longitude": -87.381527,
      "city": "Frisco City",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36446,
      "latitude": 31.782639,
      "longitude": -87.741085,
      "city": "Fulton",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36449,
      "latitude": 31.529531,
      "longitude": -87.345345,
      "city": "Goodway",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36451,
      "latitude": 31.704953,
      "longitude": -87.801307,
      "city": "Grove Hill",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36453,
      "latitude": 31.238943,
      "longitude": -86.124023,
      "city": "Kinston",
      "state": "AL",
      "county": "Coffee"
  },
  {
      "zipCode": 36454,
      "latitude": 31.466945,
      "longitude": -87.044687,
      "city": "Lenox",
      "state": "AL",
      "county": "Conecuh"
  },
  {
      "zipCode": 36455,
      "latitude": 31.260882,
      "longitude": -86.444833,
      "city": "Lockhart",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36456,
      "latitude": 31.546733,
      "longitude": -86.713793,
      "city": "Mc Kenzie",
      "state": "AL",
      "county": "Butler"
  },
  {
      "zipCode": 36457,
      "latitude": 31.361214,
      "longitude": -87.435279,
      "city": "Megargel",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36458,
      "latitude": 31.514659,
      "longitude": -87.408362,
      "city": "Mexia",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36460,
      "latitude": 31.56713,
      "longitude": -87.314222,
      "city": "Monroeville",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36461,
      "latitude": 31.522093,
      "longitude": -87.341108,
      "city": "Monroeville",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36462,
      "latitude": 31.529531,
      "longitude": -87.345345,
      "city": "Monroeville",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36467,
      "latitude": 31.260401,
      "longitude": -86.356208,
      "city": "Opp",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36470,
      "latitude": 31.508073,
      "longitude": -87.428429,
      "city": "Perdue Hill",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36471,
      "latitude": 31.606263,
      "longitude": -87.21944,
      "city": "Peterman",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36473,
      "latitude": 31.466945,
      "longitude": -87.044687,
      "city": "Range",
      "state": "AL",
      "county": "Conecuh"
  },
  {
      "zipCode": 36474,
      "latitude": 31.260882,
      "longitude": -86.444833,
      "city": "Red Level",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36475,
      "latitude": 31.371175,
      "longitude": -87.298377,
      "city": "Repton",
      "state": "AL",
      "county": "Conecuh"
  },
  {
      "zipCode": 36476,
      "latitude": 31.260882,
      "longitude": -86.444833,
      "city": "River Falls",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36477,
      "latitude": 31.07181,
      "longitude": -86.113647,
      "city": "Samson",
      "state": "AL",
      "county": "Geneva"
  },
  {
      "zipCode": 36480,
      "latitude": 31.541789,
      "longitude": -87.500236,
      "city": "Uriah",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36481,
      "latitude": 31.808458,
      "longitude": -87.316372,
      "city": "Vredenburgh",
      "state": "AL",
      "county": "Monroe"
  },
  {
      "zipCode": 36482,
      "latitude": 31.67448,
      "longitude": -87.675884,
      "city": "Whatley",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36483,
      "latitude": 31.022268,
      "longitude": -86.629331,
      "city": "Wing",
      "state": "AL",
      "county": "Covington"
  },
  {
      "zipCode": 36501,
      "latitude": 31.482494,
      "longitude": -87.880106,
      "city": "Alma",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36502,
      "latitude": 31.119972,
      "longitude": -87.470001,
      "city": "Atmore",
      "state": "AL",
      "county": "Escambia"
  },
  {
      "zipCode": 36503,
      "latitude": 31.128242,
      "longitude": -87.152068,
      "city": "Atmore",
      "state": "AL",
      "county": "Escambia"
  },
  {
      "zipCode": 36504,
      "latitude": 31.015817,
      "longitude": -87.497165,
      "city": "Atmore",
      "state": "AL",
      "county": "Escambia"
  },
  {
      "zipCode": 36505,
      "latitude": 30.939368,
      "longitude": -88.015868,
      "city": "Axis",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36507,
      "latitude": 30.763492,
      "longitude": -87.756371,
      "city": "Bay Minette",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36509,
      "latitude": 30.419006,
      "longitude": -88.253643,
      "city": "Bayou La Batre",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36511,
      "latitude": 30.465497,
      "longitude": -87.753507,
      "city": "Bon Secour",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36512,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Bucks",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36513,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Calvert",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36515,
      "latitude": 31.58842,
      "longitude": -87.840875,
      "city": "Carlton",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36518,
      "latitude": 31.436682,
      "longitude": -88.245363,
      "city": "Chatom",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36521,
      "latitude": 30.929148,
      "longitude": -88.181921,
      "city": "Chunchula",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36522,
      "latitude": 30.798501,
      "longitude": -88.273482,
      "city": "Citronelle",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36523,
      "latitude": 30.390301,
      "longitude": -88.207586,
      "city": "Coden",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36524,
      "latitude": 31.816246,
      "longitude": -88.061865,
      "city": "Coffeeville",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36525,
      "latitude": 30.944616,
      "longitude": -88.090905,
      "city": "Creola",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36526,
      "latitude": 30.540328,
      "longitude": -87.6912,
      "city": "Daphne",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36527,
      "latitude": 30.737792,
      "longitude": -87.900287,
      "city": "Spanish Fort",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36528,
      "latitude": 30.254867,
      "longitude": -88.1349,
      "city": "Dauphin Island",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36529,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Deer Park",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36530,
      "latitude": 30.412773,
      "longitude": -87.549041,
      "city": "Elberta",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36532,
      "latitude": 30.562883,
      "longitude": -87.810464,
      "city": "Fairhope",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36533,
      "latitude": 30.484131,
      "longitude": -87.860613,
      "city": "Fairhope",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36535,
      "latitude": 30.467608,
      "longitude": -87.692401,
      "city": "Foley",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36536,
      "latitude": 30.42216,
      "longitude": -87.706371,
      "city": "Foley",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36538,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Frankville",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36539,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Fruitdale",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36540,
      "latitude": 31.58842,
      "longitude": -87.840875,
      "city": "Gainestown",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36541,
      "latitude": 30.498046,
      "longitude": -88.332468,
      "city": "Grand Bay",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36542,
      "latitude": 30.284936,
      "longitude": -87.794246,
      "city": "Gulf Shores",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36543,
      "latitude": 31.216132,
      "longitude": -87.461537,
      "city": "Huxford",
      "state": "AL",
      "county": "Escambia"
  },
  {
      "zipCode": 36544,
      "latitude": 30.479141,
      "longitude": -88.232843,
      "city": "Irvington",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36545,
      "latitude": 31.592985,
      "longitude": -87.898539,
      "city": "Jackson",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36547,
      "latitude": 30.265325,
      "longitude": -87.629926,
      "city": "Gulf Shores",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36548,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Leroy",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36549,
      "latitude": 30.39736,
      "longitude": -87.458796,
      "city": "Lillian",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36550,
      "latitude": 31.22896,
      "longitude": -87.713373,
      "city": "Little River",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36551,
      "latitude": 30.513519,
      "longitude": -87.667604,
      "city": "Loxley",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36553,
      "latitude": 31.209178,
      "longitude": -88.075266,
      "city": "Mc Intosh",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36555,
      "latitude": 30.427641,
      "longitude": -87.796011,
      "city": "Magnolia Springs",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36556,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Malcolm",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36558,
      "latitude": 31.553004,
      "longitude": -88.294124,
      "city": "Millry",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36559,
      "latitude": 30.562308,
      "longitude": -87.900545,
      "city": "Montrose",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36560,
      "latitude": 31.080313,
      "longitude": -88.057203,
      "city": "Mount Vernon",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36561,
      "latitude": 30.281046,
      "longitude": -87.581457,
      "city": "Orange Beach",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36562,
      "latitude": 31.040286,
      "longitude": -87.666699,
      "city": "Perdido",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36564,
      "latitude": 30.475955,
      "longitude": -87.917867,
      "city": "Point Clear",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36567,
      "latitude": 30.749915,
      "longitude": -87.577231,
      "city": "Robertsdale",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36568,
      "latitude": 30.49434,
      "longitude": -88.269865,
      "city": "Saint Elmo",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36569,
      "latitude": 31.552362,
      "longitude": -88.090115,
      "city": "Saint Stephens",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36570,
      "latitude": 31.58842,
      "longitude": -87.840875,
      "city": "Salitpa",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36571,
      "latitude": 30.869984,
      "longitude": -88.118401,
      "city": "Saraland",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36572,
      "latitude": 30.585158,
      "longitude": -88.087879,
      "city": "Satsuma",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36574,
      "latitude": 30.51715,
      "longitude": -87.603901,
      "city": "Seminole",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36575,
      "latitude": 30.779545,
      "longitude": -88.272544,
      "city": "Semmes",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36576,
      "latitude": 30.527068,
      "longitude": -87.735,
      "city": "Silverhill",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36577,
      "latitude": 30.732789,
      "longitude": -87.70195,
      "city": "Spanish Fort",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36578,
      "latitude": 30.85852,
      "longitude": -87.728987,
      "city": "Stapleton",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36579,
      "latitude": 31.079113,
      "longitude": -87.798576,
      "city": "Stockton",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36580,
      "latitude": 30.468691,
      "longitude": -87.703035,
      "city": "Summerdale",
      "state": "AL",
      "county": "Baldwin"
  },
  {
      "zipCode": 36581,
      "latitude": 31.369572,
      "longitude": -88.012236,
      "city": "Sunflower",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36582,
      "latitude": 30.486565,
      "longitude": -88.20225,
      "city": "Theodore",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36583,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Tibbie",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36584,
      "latitude": 31.213691,
      "longitude": -88.393047,
      "city": "Vinegar Bend",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36585,
      "latitude": 31.406683,
      "longitude": -88.175846,
      "city": "Wagarville",
      "state": "AL",
      "county": "Washington"
  },
  {
      "zipCode": 36586,
      "latitude": 31.58842,
      "longitude": -87.840875,
      "city": "Walker Springs",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36587,
      "latitude": 30.812649,
      "longitude": -88.318487,
      "city": "Wilmer",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36590,
      "latitude": 30.487953,
      "longitude": -88.253278,
      "city": "Theodore",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36601,
      "latitude": 30.701142,
      "longitude": -88.103184,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36602,
      "latitude": 30.692389,
      "longitude": -88.046698,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36603,
      "latitude": 30.69117,
      "longitude": -88.057773,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36604,
      "latitude": 30.684039,
      "longitude": -88.067048,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36605,
      "latitude": 30.601842,
      "longitude": -88.118975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36606,
      "latitude": 30.66709,
      "longitude": -88.1052,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36607,
      "latitude": 30.788123,
      "longitude": -88.088073,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36608,
      "latitude": 30.639867,
      "longitude": -88.25445,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36609,
      "latitude": 30.551887,
      "longitude": -88.185603,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36610,
      "latitude": 30.7309,
      "longitude": -88.078949,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36611,
      "latitude": 30.768787,
      "longitude": -88.11085,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36612,
      "latitude": 30.747387,
      "longitude": -88.118001,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36613,
      "latitude": 30.769758,
      "longitude": -88.196296,
      "city": "Eight Mile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36614,
      "latitude": 30.601892,
      "longitude": -88.211103,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36615,
      "latitude": 30.64109,
      "longitude": -88.062248,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36616,
      "latitude": 30.671048,
      "longitude": -88.126656,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36617,
      "latitude": 30.715588,
      "longitude": -88.096,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36618,
      "latitude": 30.681689,
      "longitude": -88.149001,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36619,
      "latitude": 30.462987,
      "longitude": -88.216493,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36621,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36622,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36623,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36625,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36626,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36628,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36630,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36631,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36633,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36640,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36641,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36644,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36652,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36660,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36663,
      "latitude": 30.81901,
      "longitude": -88.231726,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36670,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36671,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36675,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36685,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36688,
      "latitude": 30.696589,
      "longitude": -88.173652,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36689,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36690,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36691,
      "latitude": 30.658865,
      "longitude": -88.177975,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36693,
      "latitude": 30.635741,
      "longitude": -88.174502,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36695,
      "latitude": 30.666959,
      "longitude": -88.226989,
      "city": "Mobile",
      "state": "AL",
      "county": "Mobile"
  },
  {
      "zipCode": 36701,
      "latitude": 32.43372,
      "longitude": -87.055532,
      "city": "Selma",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36702,
      "latitude": 32.419259,
      "longitude": -87.124708,
      "city": "Selma",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36703,
      "latitude": 32.467164,
      "longitude": -86.959844,
      "city": "Selma",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36720,
      "latitude": 32.145564,
      "longitude": -87.354953,
      "city": "Alberta",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36721,
      "latitude": 32.048079,
      "longitude": -87.26226,
      "city": "Annemanie",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36722,
      "latitude": 32.051214,
      "longitude": -87.569778,
      "city": "Arlington",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36723,
      "latitude": 32.082415,
      "longitude": -87.294855,
      "city": "Boykin",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36726,
      "latitude": 31.998366,
      "longitude": -87.351944,
      "city": "Camden",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36727,
      "latitude": 31.58842,
      "longitude": -87.840875,
      "city": "Campbell",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36728,
      "latitude": 32.192472,
      "longitude": -87.467873,
      "city": "Catherine",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36732,
      "latitude": 32.384949,
      "longitude": -87.824556,
      "city": "Demopolis",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36736,
      "latitude": 32.261286,
      "longitude": -87.77611,
      "city": "Dixons Mills",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36738,
      "latitude": 32.365963,
      "longitude": -87.721033,
      "city": "Faunsdale",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36740,
      "latitude": 32.610762,
      "longitude": -87.844122,
      "city": "Forkland",
      "state": "AL",
      "county": "Greene"
  },
  {
      "zipCode": 36741,
      "latitude": 32.006129,
      "longitude": -86.981703,
      "city": "Furman",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36742,
      "latitude": 32.52418,
      "longitude": -87.672092,
      "city": "Gallion",
      "state": "AL",
      "county": "Hale"
  },
  {
      "zipCode": 36744,
      "latitude": 32.699872,
      "longitude": -87.586079,
      "city": "Greensboro",
      "state": "AL",
      "county": "Hale"
  },
  {
      "zipCode": 36745,
      "latitude": 32.414036,
      "longitude": -87.898611,
      "city": "Jefferson",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36748,
      "latitude": 32.321943,
      "longitude": -87.749847,
      "city": "Linden",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36749,
      "latitude": 32.510383,
      "longitude": -86.813834,
      "city": "Jones",
      "state": "AL",
      "county": "Autauga"
  },
  {
      "zipCode": 36750,
      "latitude": 32.83217,
      "longitude": -86.843808,
      "city": "Maplesville",
      "state": "AL",
      "county": "Chilton"
  },
  {
      "zipCode": 36751,
      "latitude": 31.902773,
      "longitude": -87.577911,
      "city": "Lower Peach Tree",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36752,
      "latitude": 32.294321,
      "longitude": -86.647119,
      "city": "Lowndesboro",
      "state": "AL",
      "county": "Lowndes"
  },
  {
      "zipCode": 36753,
      "latitude": 32.048079,
      "longitude": -87.26226,
      "city": "Mc Williams",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36754,
      "latitude": 32.129123,
      "longitude": -87.706019,
      "city": "Magnolia",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36756,
      "latitude": 32.608995,
      "longitude": -87.350401,
      "city": "Marion",
      "state": "AL",
      "county": "Perry"
  },
  {
      "zipCode": 36758,
      "latitude": 32.484147,
      "longitude": -87.076236,
      "city": "Plantersville",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36759,
      "latitude": 32.388235,
      "longitude": -87.26262,
      "city": "Marion Junction",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36761,
      "latitude": 32.189926,
      "longitude": -87.063331,
      "city": "Minter",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36762,
      "latitude": 31.940191,
      "longitude": -88.00336,
      "city": "Morvin",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36763,
      "latitude": 32.251626,
      "longitude": -87.949363,
      "city": "Myrtlewood",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36764,
      "latitude": 32.268329,
      "longitude": -87.795275,
      "city": "Nanafalia",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36765,
      "latitude": 32.58819,
      "longitude": -87.56625,
      "city": "Newbern",
      "state": "AL",
      "county": "Hale"
  },
  {
      "zipCode": 36766,
      "latitude": 31.947005,
      "longitude": -87.074497,
      "city": "Oak Hill",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36767,
      "latitude": 32.289861,
      "longitude": -87.219864,
      "city": "Orrville",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36768,
      "latitude": 31.932526,
      "longitude": -87.044855,
      "city": "Pine Apple",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36769,
      "latitude": 31.994807,
      "longitude": -87.545406,
      "city": "Pine Hill",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36773,
      "latitude": 32.322859,
      "longitude": -87.376446,
      "city": "Safford",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36775,
      "latitude": 32.213977,
      "longitude": -87.078655,
      "city": "Sardis",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36776,
      "latitude": 32.755975,
      "longitude": -87.705829,
      "city": "Sawyerville",
      "state": "AL",
      "county": "Hale"
  },
  {
      "zipCode": 36778,
      "latitude": 32.01998,
      "longitude": -87.054284,
      "city": "Snow Hill",
      "state": "AL",
      "county": "Wilcox"
  },
  {
      "zipCode": 36779,
      "latitude": 32.591207,
      "longitude": -87.271667,
      "city": "Sprott",
      "state": "AL",
      "county": "Perry"
  },
  {
      "zipCode": 36782,
      "latitude": 32.131469,
      "longitude": -87.83266,
      "city": "Sweet Water",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36783,
      "latitude": 32.302397,
      "longitude": -87.678612,
      "city": "Thomaston",
      "state": "AL",
      "county": "Marengo"
  },
  {
      "zipCode": 36784,
      "latitude": 31.745135,
      "longitude": -87.769476,
      "city": "Thomasville",
      "state": "AL",
      "county": "Clarke"
  },
  {
      "zipCode": 36785,
      "latitude": 32.323895,
      "longitude": -86.86458,
      "city": "Tyler",
      "state": "AL",
      "county": "Dallas"
  },
  {
      "zipCode": 36786,
      "latitude": 32.446243,
      "longitude": -87.503256,
      "city": "Uniontown",
      "state": "AL",
      "county": "Perry"
  },
  {
      "zipCode": 36790,
      "latitude": 32.723537,
      "longitude": -86.875759,
      "city": "Stanton",
      "state": "AL",
      "county": "Chilton"
  },
  {
      "zipCode": 36792,
      "latitude": 32.931293,
      "longitude": -86.917506,
      "city": "Randolph",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 36793,
      "latitude": 32.891444,
      "longitude": -86.983385,
      "city": "Lawley",
      "state": "AL",
      "county": "Bibb"
  },
  {
      "zipCode": 36801,
      "latitude": 32.621147,
      "longitude": -85.392878,
      "city": "Opelika",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36802,
      "latitude": 32.578185,
      "longitude": -85.349003,
      "city": "Opelika",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36803,
      "latitude": 32.522567,
      "longitude": -85.431517,
      "city": "Opelika",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36804,
      "latitude": 32.576792,
      "longitude": -85.311984,
      "city": "Opelika",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36830,
      "latitude": 32.547542,
      "longitude": -85.468222,
      "city": "Auburn",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36831,
      "latitude": 32.578185,
      "longitude": -85.349003,
      "city": "Auburn",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36832,
      "latitude": 32.592036,
      "longitude": -85.518913,
      "city": "Auburn",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36849,
      "latitude": 32.578185,
      "longitude": -85.349003,
      "city": "Auburn University",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36850,
      "latitude": 32.808407,
      "longitude": -85.703776,
      "city": "Camp Hill",
      "state": "AL",
      "county": "Tallapoosa"
  },
  {
      "zipCode": 36851,
      "latitude": 32.286092,
      "longitude": -85.161497,
      "city": "Cottonton",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36852,
      "latitude": 32.783137,
      "longitude": -85.275581,
      "city": "Cusseta",
      "state": "AL",
      "county": "Chambers"
  },
  {
      "zipCode": 36853,
      "latitude": 32.794796,
      "longitude": -85.744428,
      "city": "Dadeville",
      "state": "AL",
      "county": "Tallapoosa"
  },
  {
      "zipCode": 36854,
      "latitude": 32.885631,
      "longitude": -85.264695,
      "city": "Valley",
      "state": "AL",
      "county": "Chambers"
  },
  {
      "zipCode": 36855,
      "latitude": 32.91934,
      "longitude": -85.311223,
      "city": "Five Points",
      "state": "AL",
      "county": "Chambers"
  },
  {
      "zipCode": 36856,
      "latitude": 32.296518,
      "longitude": -84.997165,
      "city": "Fort Mitchell",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36858,
      "latitude": 32.295844,
      "longitude": -85.292938,
      "city": "Hatchechubbee",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36859,
      "latitude": 32.229846,
      "longitude": -85.008178,
      "city": "Holy Trinity",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36860,
      "latitude": 32.238147,
      "longitude": -85.36233,
      "city": "Hurtsboro",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36861,
      "latitude": 32.900767,
      "longitude": -85.822787,
      "city": "Jacksons Gap",
      "state": "AL",
      "county": "Tallapoosa"
  },
  {
      "zipCode": 36862,
      "latitude": 32.912315,
      "longitude": -85.388079,
      "city": "Lafayette",
      "state": "AL",
      "county": "Chambers"
  },
  {
      "zipCode": 36863,
      "latitude": 32.900173,
      "longitude": -85.296659,
      "city": "Lanett",
      "state": "AL",
      "county": "Chambers"
  },
  {
      "zipCode": 36865,
      "latitude": 32.578185,
      "longitude": -85.349003,
      "city": "Loachapoka",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36866,
      "latitude": 32.536635,
      "longitude": -85.697354,
      "city": "Notasulga",
      "state": "AL",
      "county": "Macon"
  },
  {
      "zipCode": 36867,
      "latitude": 32.375318,
      "longitude": -85.203923,
      "city": "Phenix City",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36868,
      "latitude": 32.464614,
      "longitude": -85.020604,
      "city": "Phenix City",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36869,
      "latitude": 32.318701,
      "longitude": -85.111997,
      "city": "Phenix City",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36870,
      "latitude": 32.478145,
      "longitude": -85.123979,
      "city": "Phenix City",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36871,
      "latitude": 32.166569,
      "longitude": -85.165575,
      "city": "Pittsview",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36872,
      "latitude": 32.578185,
      "longitude": -85.349003,
      "city": "Valley",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36874,
      "latitude": 32.580449,
      "longitude": -85.217224,
      "city": "Salem",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36875,
      "latitude": 32.257831,
      "longitude": -85.129772,
      "city": "Seale",
      "state": "AL",
      "county": "Russell"
  },
  {
      "zipCode": 36877,
      "latitude": 32.558314,
      "longitude": -85.091498,
      "city": "Smiths",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36879,
      "latitude": 32.681539,
      "longitude": -85.565954,
      "city": "Waverly",
      "state": "AL",
      "county": "Lee"
  },
  {
      "zipCode": 36901,
      "latitude": 32.467134,
      "longitude": -88.140634,
      "city": "Bellamy",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 36904,
      "latitude": 32.115939,
      "longitude": -88.273598,
      "city": "Butler",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36906,
      "latitude": 32.004227,
      "longitude": -88.200738,
      "city": "Cromwell",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36907,
      "latitude": 32.435545,
      "longitude": -88.329881,
      "city": "Cuba",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 36908,
      "latitude": 31.922384,
      "longitude": -88.323669,
      "city": "Gilbertown",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36910,
      "latitude": 32.004227,
      "longitude": -88.200738,
      "city": "Jachin",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36912,
      "latitude": 32.233182,
      "longitude": -88.321388,
      "city": "Lisman",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36913,
      "latitude": 32.004227,
      "longitude": -88.200738,
      "city": "Melvin",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36915,
      "latitude": 32.004227,
      "longitude": -88.200738,
      "city": "Needham",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36916,
      "latitude": 32.209362,
      "longitude": -88.048983,
      "city": "Pennington",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36919,
      "latitude": 31.947815,
      "longitude": -88.32911,
      "city": "Silas",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36921,
      "latitude": 31.915506,
      "longitude": -88.151048,
      "city": "Toxey",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36922,
      "latitude": 32.210259,
      "longitude": -88.065479,
      "city": "Ward",
      "state": "AL",
      "county": "Choctaw"
  },
  {
      "zipCode": 36925,
      "latitude": 32.444907,
      "longitude": -88.193305,
      "city": "York",
      "state": "AL",
      "county": "Sumter"
  },
  {
      "zipCode": 37010,
      "latitude": 36.556825,
      "longitude": -87.018127,
      "city": "Adams",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37011,
      "latitude": 36.170556,
      "longitude": -86.8825,
      "city": "Antioch",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37012,
      "latitude": 36.034524,
      "longitude": -85.997115,
      "city": "Alexandria",
      "state": "TN",
      "county": "Dekalb"
  },
  {
      "zipCode": 37013,
      "latitude": 36.036032,
      "longitude": -86.645598,
      "city": "Antioch",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37014,
      "latitude": 35.856609,
      "longitude": -86.663307,
      "city": "Arrington",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37015,
      "latitude": 36.297807,
      "longitude": -87.083657,
      "city": "Ashland City",
      "state": "TN",
      "county": "Cheatham"
  },
  {
      "zipCode": 37016,
      "latitude": 35.933832,
      "longitude": -86.112561,
      "city": "Auburntown",
      "state": "TN",
      "county": "Cannon"
  },
  {
      "zipCode": 37018,
      "latitude": 35.643787,
      "longitude": -86.183248,
      "city": "Beechgrove",
      "state": "TN",
      "county": "Coffee"
  },
  {
      "zipCode": 37019,
      "latitude": 35.431968,
      "longitude": -86.762712,
      "city": "Belfast",
      "state": "TN",
      "county": "Marshall"
  },
  {
      "zipCode": 37020,
      "latitude": 35.580793,
      "longitude": -86.399852,
      "city": "Bell Buckle",
      "state": "TN",
      "county": "Bedford"
  },
  {
      "zipCode": 37022,
      "latitude": 36.477191,
      "longitude": -86.460853,
      "city": "Bethpage",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37023,
      "latitude": 36.580898,
      "longitude": -87.774854,
      "city": "Big Rock",
      "state": "TN",
      "county": "Stewart"
  },
  {
      "zipCode": 37024,
      "latitude": 35.874553,
      "longitude": -86.907565,
      "city": "Brentwood",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37025,
      "latitude": 35.92171,
      "longitude": -87.348964,
      "city": "Bon Aqua",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 37026,
      "latitude": 35.701738,
      "longitude": -86.107742,
      "city": "Bradyville",
      "state": "TN",
      "county": "Cannon"
  },
  {
      "zipCode": 37027,
      "latitude": 35.982981,
      "longitude": -86.816058,
      "city": "Brentwood",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37028,
      "latitude": 36.584621,
      "longitude": -87.843106,
      "city": "Bumpus Mills",
      "state": "TN",
      "county": "Stewart"
  },
  {
      "zipCode": 37029,
      "latitude": 36.039122,
      "longitude": -87.26284,
      "city": "Burns",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37030,
      "latitude": 36.276278,
      "longitude": -85.95174,
      "city": "Carthage",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 37031,
      "latitude": 36.380555,
      "longitude": -86.310674,
      "city": "Castalian Springs",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37032,
      "latitude": 36.521638,
      "longitude": -87.008437,
      "city": "Cedar Hill",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37033,
      "latitude": 35.750099,
      "longitude": -87.502405,
      "city": "Centerville",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 37034,
      "latitude": 35.640617,
      "longitude": -86.718225,
      "city": "Chapel Hill",
      "state": "TN",
      "county": "Marshall"
  },
  {
      "zipCode": 37035,
      "latitude": 36.376837,
      "longitude": -87.112668,
      "city": "Chapmansboro",
      "state": "TN",
      "county": "Cheatham"
  },
  {
      "zipCode": 37036,
      "latitude": 36.232191,
      "longitude": -87.278993,
      "city": "Charlotte",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37037,
      "latitude": 35.695974,
      "longitude": -86.368041,
      "city": "Christiana",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37040,
      "latitude": 36.51674,
      "longitude": -87.308491,
      "city": "Clarksville",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37041,
      "latitude": 36.477428,
      "longitude": -87.377197,
      "city": "Clarksville",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37042,
      "latitude": 36.501766,
      "longitude": -87.399188,
      "city": "Clarksville",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37043,
      "latitude": 36.512666,
      "longitude": -87.301283,
      "city": "Clarksville",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37044,
      "latitude": 36.531398,
      "longitude": -87.353008,
      "city": "Clarksville",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37046,
      "latitude": 35.790897,
      "longitude": -86.726133,
      "city": "College Grove",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37047,
      "latitude": 35.354815,
      "longitude": -86.829364,
      "city": "Cornersville",
      "state": "TN",
      "county": "Marshall"
  },
  {
      "zipCode": 37048,
      "latitude": 36.485607,
      "longitude": -86.528398,
      "city": "Cottontown",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37049,
      "latitude": 36.521044,
      "longitude": -86.712734,
      "city": "Cross Plains",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37050,
      "latitude": 36.452109,
      "longitude": -87.701641,
      "city": "Cumberland City",
      "state": "TN",
      "county": "Stewart"
  },
  {
      "zipCode": 37051,
      "latitude": 36.272071,
      "longitude": -87.391396,
      "city": "Cumberland Furnace",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37052,
      "latitude": 36.398614,
      "longitude": -87.376496,
      "city": "Cunningham",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37055,
      "latitude": 36.141461,
      "longitude": -87.396417,
      "city": "Dickson",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37056,
      "latitude": 36.146949,
      "longitude": -87.352971,
      "city": "Dickson",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37057,
      "latitude": 36.358212,
      "longitude": -86.037379,
      "city": "Dixon Springs",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 37058,
      "latitude": 36.494203,
      "longitude": -87.878742,
      "city": "Dover",
      "state": "TN",
      "county": "Stewart"
  },
  {
      "zipCode": 37059,
      "latitude": 35.993753,
      "longitude": -85.945143,
      "city": "Dowelltown",
      "state": "TN",
      "county": "Dekalb"
  },
  {
      "zipCode": 37060,
      "latitude": 35.73168,
      "longitude": -86.558626,
      "city": "Eagleville",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37061,
      "latitude": 36.288768,
      "longitude": -87.749245,
      "city": "Erin",
      "state": "TN",
      "county": "Houston"
  },
  {
      "zipCode": 37062,
      "latitude": 35.97805,
      "longitude": -87.135938,
      "city": "Fairview",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37063,
      "latitude": 35.859565,
      "longitude": -86.420958,
      "city": "Fosterville",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37064,
      "latitude": 35.890746,
      "longitude": -86.965691,
      "city": "Franklin",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37065,
      "latitude": 35.895085,
      "longitude": -86.921416,
      "city": "Franklin",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37066,
      "latitude": 36.401201,
      "longitude": -86.449479,
      "city": "Gallatin",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37067,
      "latitude": 35.912072,
      "longitude": -86.765537,
      "city": "Franklin",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37068,
      "latitude": 35.874553,
      "longitude": -86.907565,
      "city": "Franklin",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37069,
      "latitude": 35.979553,
      "longitude": -86.910623,
      "city": "Franklin",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37070,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Goodlettsville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37071,
      "latitude": 36.156231,
      "longitude": -86.304922,
      "city": "Gladeville",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37072,
      "latitude": 36.34145,
      "longitude": -86.775747,
      "city": "Goodlettsville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37073,
      "latitude": 36.437397,
      "longitude": -86.809567,
      "city": "Greenbrier",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37074,
      "latitude": 36.402012,
      "longitude": -86.143252,
      "city": "Hartsville",
      "state": "TN",
      "county": "Trousdale"
  },
  {
      "zipCode": 37075,
      "latitude": 36.347551,
      "longitude": -86.612045,
      "city": "Hendersonville",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37076,
      "latitude": 36.153572,
      "longitude": -86.583994,
      "city": "Hermitage",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37077,
      "latitude": 36.304701,
      "longitude": -86.621095,
      "city": "Hendersonville",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37078,
      "latitude": 35.923959,
      "longitude": -87.810501,
      "city": "Hurricane Mills",
      "state": "TN",
      "county": "Humphreys"
  },
  {
      "zipCode": 37079,
      "latitude": 36.490214,
      "longitude": -87.697982,
      "city": "Indian Mound",
      "state": "TN",
      "county": "Stewart"
  },
  {
      "zipCode": 37080,
      "latitude": 36.326101,
      "longitude": -86.899299,
      "city": "Joelton",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37082,
      "latitude": 36.189656,
      "longitude": -87.089254,
      "city": "Kingston Springs",
      "state": "TN",
      "county": "Cheatham"
  },
  {
      "zipCode": 37083,
      "latitude": 36.52772,
      "longitude": -86.025585,
      "city": "Lafayette",
      "state": "TN",
      "county": "Macon"
  },
  {
      "zipCode": 37085,
      "latitude": 35.936886,
      "longitude": -86.291678,
      "city": "Lascassas",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37086,
      "latitude": 36.008511,
      "longitude": -86.559194,
      "city": "La Vergne",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37087,
      "latitude": 36.267266,
      "longitude": -86.291167,
      "city": "Lebanon",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37088,
      "latitude": 36.156231,
      "longitude": -86.304922,
      "city": "Lebanon",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37089,
      "latitude": 35.859565,
      "longitude": -86.420958,
      "city": "La Vergne",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37090,
      "latitude": 36.118515,
      "longitude": -86.263,
      "city": "Lebanon",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37091,
      "latitude": 35.504165,
      "longitude": -86.792003,
      "city": "Lewisburg",
      "state": "TN",
      "county": "Marshall"
  },
  {
      "zipCode": 37095,
      "latitude": 35.999286,
      "longitude": -85.944738,
      "city": "Liberty",
      "state": "TN",
      "county": "Dekalb"
  },
  {
      "zipCode": 37096,
      "latitude": 35.594367,
      "longitude": -87.856665,
      "city": "Linden",
      "state": "TN",
      "county": "Perry"
  },
  {
      "zipCode": 37097,
      "latitude": 35.786732,
      "longitude": -87.787992,
      "city": "Lobelville",
      "state": "TN",
      "county": "Perry"
  },
  {
      "zipCode": 37098,
      "latitude": 35.850248,
      "longitude": -87.312683,
      "city": "Lyles",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 37101,
      "latitude": 36.066188,
      "longitude": -87.659245,
      "city": "Mc Ewen",
      "state": "TN",
      "county": "Humphreys"
  },
  {
      "zipCode": 37110,
      "latitude": 35.684033,
      "longitude": -85.778151,
      "city": "Mc Minnville",
      "state": "TN",
      "county": "Warren"
  },
  {
      "zipCode": 37111,
      "latitude": 35.678368,
      "longitude": -85.777633,
      "city": "Mc Minnville",
      "state": "TN",
      "county": "Warren"
  },
  {
      "zipCode": 37115,
      "latitude": 36.259503,
      "longitude": -86.706296,
      "city": "Madison",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37116,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Madison",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37118,
      "latitude": 35.908701,
      "longitude": -86.19487,
      "city": "Milton",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37119,
      "latitude": 36.631981,
      "longitude": -86.539028,
      "city": "Mitchellville",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37121,
      "latitude": 36.156231,
      "longitude": -86.304922,
      "city": "Mount Juliet",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37122,
      "latitude": 36.184603,
      "longitude": -86.468695,
      "city": "Mount Juliet",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37127,
      "latitude": 35.762951,
      "longitude": -86.372158,
      "city": "Murfreesboro",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37128,
      "latitude": 35.845418,
      "longitude": -86.486683,
      "city": "Murfreesboro",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37129,
      "latitude": 35.901746,
      "longitude": -86.423535,
      "city": "Murfreesboro",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37130,
      "latitude": 35.886984,
      "longitude": -86.35778,
      "city": "Murfreesboro",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37131,
      "latitude": 35.859565,
      "longitude": -86.420958,
      "city": "Murfreesboro",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37132,
      "latitude": 35.859565,
      "longitude": -86.420958,
      "city": "Murfreesboro",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37133,
      "latitude": 35.859565,
      "longitude": -86.420958,
      "city": "Murfreesboro",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37134,
      "latitude": 36.014193,
      "longitude": -87.938738,
      "city": "New Johnsonville",
      "state": "TN",
      "county": "Humphreys"
  },
  {
      "zipCode": 37135,
      "latitude": 35.925201,
      "longitude": -86.675349,
      "city": "Nolensville",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37136,
      "latitude": 36.156231,
      "longitude": -86.304922,
      "city": "Norene",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37137,
      "latitude": 35.873344,
      "longitude": -87.491254,
      "city": "Nunnelly",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 37138,
      "latitude": 36.137706,
      "longitude": -86.633545,
      "city": "Old Hickory",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37140,
      "latitude": 35.877706,
      "longitude": -87.642386,
      "city": "Only",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 37141,
      "latitude": 36.616801,
      "longitude": -86.66518,
      "city": "Orlinda",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37142,
      "latitude": 36.402215,
      "longitude": -87.492837,
      "city": "Palmyra",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37143,
      "latitude": 36.135105,
      "longitude": -87.039855,
      "city": "Pegram",
      "state": "TN",
      "county": "Cheatham"
  },
  {
      "zipCode": 37144,
      "latitude": 35.285712,
      "longitude": -86.639625,
      "city": "Petersburg",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 37145,
      "latitude": 36.346164,
      "longitude": -85.918944,
      "city": "Pleasant Shade",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 37146,
      "latitude": 36.271077,
      "longitude": -87.047966,
      "city": "Pleasant View",
      "state": "TN",
      "county": "Cheatham"
  },
  {
      "zipCode": 37147,
      "latitude": 35.692395,
      "longitude": -87.662335,
      "city": "Pleasantville",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 37148,
      "latitude": 36.547545,
      "longitude": -86.515887,
      "city": "Portland",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37149,
      "latitude": 35.80362,
      "longitude": -86.148505,
      "city": "Readyville",
      "state": "TN",
      "county": "Cannon"
  },
  {
      "zipCode": 37150,
      "latitude": 36.517717,
      "longitude": -85.866182,
      "city": "Red Boiling Springs",
      "state": "TN",
      "county": "Macon"
  },
  {
      "zipCode": 37151,
      "latitude": 36.314537,
      "longitude": -86.045992,
      "city": "Riddleton",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 37152,
      "latitude": 36.402548,
      "longitude": -86.772247,
      "city": "Ridgetop",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37153,
      "latitude": 35.766806,
      "longitude": -86.542707,
      "city": "Rockvale",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37155,
      "latitude": 36.477428,
      "longitude": -87.377197,
      "city": "Saint Bethlehem",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37160,
      "latitude": 35.486335,
      "longitude": -86.462357,
      "city": "Shelbyville",
      "state": "TN",
      "county": "Bedford"
  },
  {
      "zipCode": 37161,
      "latitude": 35.471531,
      "longitude": -86.489019,
      "city": "Shelbyville",
      "state": "TN",
      "county": "Bedford"
  },
  {
      "zipCode": 37162,
      "latitude": 35.510825,
      "longitude": -86.450042,
      "city": "Shelbyville",
      "state": "TN",
      "county": "Bedford"
  },
  {
      "zipCode": 37165,
      "latitude": 36.283543,
      "longitude": -87.458058,
      "city": "Slayden",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37166,
      "latitude": 35.958503,
      "longitude": -85.835635,
      "city": "Smithville",
      "state": "TN",
      "county": "Dekalb"
  },
  {
      "zipCode": 37167,
      "latitude": 35.856167,
      "longitude": -86.45336,
      "city": "Smyrna",
      "state": "TN",
      "county": "Rutherford"
  },
  {
      "zipCode": 37171,
      "latitude": 36.361093,
      "longitude": -87.316272,
      "city": "Southside",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37172,
      "latitude": 36.51351,
      "longitude": -86.888624,
      "city": "Springfield",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37174,
      "latitude": 35.712381,
      "longitude": -86.901661,
      "city": "Spring Hill",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 37175,
      "latitude": 36.272979,
      "longitude": -87.747374,
      "city": "Stewart",
      "state": "TN",
      "county": "Houston"
  },
  {
      "zipCode": 37178,
      "latitude": 36.315226,
      "longitude": -87.798852,
      "city": "Tennessee Ridge",
      "state": "TN",
      "county": "Houston"
  },
  {
      "zipCode": 37179,
      "latitude": 35.809026,
      "longitude": -86.891286,
      "city": "Thompsons Station",
      "state": "TN",
      "county": "Williamson"
  },
  {
      "zipCode": 37180,
      "latitude": 35.622306,
      "longitude": -86.573246,
      "city": "Unionville",
      "state": "TN",
      "county": "Bedford"
  },
  {
      "zipCode": 37181,
      "latitude": 36.245657,
      "longitude": -87.465507,
      "city": "Vanleer",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37183,
      "latitude": 35.501239,
      "longitude": -86.335135,
      "city": "Wartrace",
      "state": "TN",
      "county": "Bedford"
  },
  {
      "zipCode": 37184,
      "latitude": 36.087694,
      "longitude": -86.151145,
      "city": "Watertown",
      "state": "TN",
      "county": "Wilson"
  },
  {
      "zipCode": 37185,
      "latitude": 36.032353,
      "longitude": -87.82746,
      "city": "Waverly",
      "state": "TN",
      "county": "Humphreys"
  },
  {
      "zipCode": 37186,
      "latitude": 36.572762,
      "longitude": -86.305594,
      "city": "Westmoreland",
      "state": "TN",
      "county": "Sumner"
  },
  {
      "zipCode": 37187,
      "latitude": 36.124378,
      "longitude": -87.226354,
      "city": "White Bluff",
      "state": "TN",
      "county": "Dickson"
  },
  {
      "zipCode": 37188,
      "latitude": 36.481635,
      "longitude": -86.689867,
      "city": "White House",
      "state": "TN",
      "county": "Robertson"
  },
  {
      "zipCode": 37189,
      "latitude": 36.276652,
      "longitude": -86.832048,
      "city": "Whites Creek",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37190,
      "latitude": 35.836335,
      "longitude": -86.033176,
      "city": "Woodbury",
      "state": "TN",
      "county": "Cannon"
  },
  {
      "zipCode": 37191,
      "latitude": 36.495848,
      "longitude": -87.538989,
      "city": "Woodlawn",
      "state": "TN",
      "county": "Montgomery"
  },
  {
      "zipCode": 37201,
      "latitude": 36.165688,
      "longitude": -86.778098,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37202,
      "latitude": 36.340304,
      "longitude": -86.827254,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37203,
      "latitude": 36.150406,
      "longitude": -86.791598,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37204,
      "latitude": 36.119294,
      "longitude": -86.850099,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37205,
      "latitude": 36.108558,
      "longitude": -86.865776,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37206,
      "latitude": 36.180143,
      "longitude": -86.73575,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37207,
      "latitude": 36.233916,
      "longitude": -86.784698,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37208,
      "latitude": 36.174256,
      "longitude": -86.810198,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37209,
      "latitude": 36.142007,
      "longitude": -86.89995,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37210,
      "latitude": 36.137475,
      "longitude": -86.729947,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37211,
      "latitude": 36.070359,
      "longitude": -86.718347,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37212,
      "latitude": 36.132607,
      "longitude": -86.803748,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37213,
      "latitude": 36.168626,
      "longitude": -86.762899,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37214,
      "latitude": 36.174205,
      "longitude": -86.662146,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37215,
      "latitude": 36.086531,
      "longitude": -86.885987,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37216,
      "latitude": 36.204255,
      "longitude": -86.723997,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37217,
      "latitude": 36.107372,
      "longitude": -86.662914,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37218,
      "latitude": 36.234304,
      "longitude": -86.89585,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37219,
      "latitude": 36.161906,
      "longitude": -86.781748,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37220,
      "latitude": 36.067109,
      "longitude": -86.782098,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37221,
      "latitude": 36.06616,
      "longitude": -86.963852,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37222,
      "latitude": 36.056164,
      "longitude": -86.980116,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37224,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37227,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37228,
      "latitude": 36.193205,
      "longitude": -86.8083,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37229,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37230,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37232,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37234,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37235,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37236,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37237,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37238,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37239,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37240,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37241,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37242,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37243,
      "latitude": 36.167606,
      "longitude": -86.784498,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37244,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37245,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37246,
      "latitude": 36.158606,
      "longitude": -86.789998,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37247,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37248,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37249,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37250,
      "latitude": 36.186605,
      "longitude": -86.785248,
      "city": "Nashville",
      "state": "TN",
      "county": "Davidson"
  },
  {
      "zipCode": 37301,
      "latitude": 35.360328,
      "longitude": -85.789682,
      "city": "Altamont",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37302,
      "latitude": 35.017572,
      "longitude": -85.018914,
      "city": "Apison",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37303,
      "latitude": 35.457491,
      "longitude": -84.63982,
      "city": "Athens",
      "state": "TN",
      "county": "Mcminn"
  },
  {
      "zipCode": 37304,
      "latitude": 35.345216,
      "longitude": -85.138046,
      "city": "Bakewell",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37305,
      "latitude": 35.373473,
      "longitude": -85.699601,
      "city": "Beersheba Springs",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37306,
      "latitude": 35.089483,
      "longitude": -86.175941,
      "city": "Belvidere",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37307,
      "latitude": 35.130815,
      "longitude": -84.521805,
      "city": "Benton",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37308,
      "latitude": 35.312806,
      "longitude": -85.009853,
      "city": "Birchwood",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37309,
      "latitude": 35.322816,
      "longitude": -84.736414,
      "city": "Calhoun",
      "state": "TN",
      "county": "Mcminn"
  },
  {
      "zipCode": 37310,
      "latitude": 35.2637,
      "longitude": -84.793619,
      "city": "Charleston",
      "state": "TN",
      "county": "Bradley"
  },
  {
      "zipCode": 37311,
      "latitude": 35.149223,
      "longitude": -84.874031,
      "city": "Cleveland",
      "state": "TN",
      "county": "Bradley"
  },
  {
      "zipCode": 37312,
      "latitude": 35.255097,
      "longitude": -84.86844,
      "city": "Cleveland",
      "state": "TN",
      "county": "Bradley"
  },
  {
      "zipCode": 37313,
      "latitude": 35.351615,
      "longitude": -85.70031,
      "city": "Coalmont",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37314,
      "latitude": 35.246689,
      "longitude": -84.303121,
      "city": "Cokercreek",
      "state": "TN",
      "county": "Monroe"
  },
  {
      "zipCode": 37315,
      "latitude": 35.047891,
      "longitude": -85.057359,
      "city": "Collegedale",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37316,
      "latitude": 34.997057,
      "longitude": -84.732656,
      "city": "Conasauga",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37317,
      "latitude": 35.020673,
      "longitude": -84.374828,
      "city": "Copperhill",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37318,
      "latitude": 35.178335,
      "longitude": -86.016657,
      "city": "Cowan",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37320,
      "latitude": 35.17272,
      "longitude": -84.861885,
      "city": "Cleveland",
      "state": "TN",
      "county": "Bradley"
  },
  {
      "zipCode": 37321,
      "latitude": 35.556414,
      "longitude": -84.979171,
      "city": "Dayton",
      "state": "TN",
      "county": "Rhea"
  },
  {
      "zipCode": 37322,
      "latitude": 35.484053,
      "longitude": -84.84546,
      "city": "Decatur",
      "state": "TN",
      "county": "Meigs"
  },
  {
      "zipCode": 37323,
      "latitude": 35.136098,
      "longitude": -84.845707,
      "city": "Cleveland",
      "state": "TN",
      "county": "Bradley"
  },
  {
      "zipCode": 37324,
      "latitude": 35.255505,
      "longitude": -86.068439,
      "city": "Decherd",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37325,
      "latitude": 35.240205,
      "longitude": -84.573882,
      "city": "Delano",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37326,
      "latitude": 35.033927,
      "longitude": -84.379169,
      "city": "Ducktown",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37327,
      "latitude": 35.386535,
      "longitude": -85.376504,
      "city": "Dunlap",
      "state": "TN",
      "county": "Sequatchie"
  },
  {
      "zipCode": 37328,
      "latitude": 35.068833,
      "longitude": -86.412827,
      "city": "Elora",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 37329,
      "latitude": 35.374591,
      "longitude": -84.488998,
      "city": "Englewood",
      "state": "TN",
      "county": "Mcminn"
  },
  {
      "zipCode": 37330,
      "latitude": 35.270666,
      "longitude": -86.056548,
      "city": "Estill Springs",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37331,
      "latitude": 35.347928,
      "longitude": -84.534548,
      "city": "Etowah",
      "state": "TN",
      "county": "Mcminn"
  },
  {
      "zipCode": 37332,
      "latitude": 35.531335,
      "longitude": -84.945661,
      "city": "Evensville",
      "state": "TN",
      "county": "Rhea"
  },
  {
      "zipCode": 37333,
      "latitude": 35.13081,
      "longitude": -84.328172,
      "city": "Farner",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37334,
      "latitude": 35.168227,
      "longitude": -86.601925,
      "city": "Fayetteville",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 37335,
      "latitude": 35.058083,
      "longitude": -86.412954,
      "city": "Flintville",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 37336,
      "latitude": 35.310427,
      "longitude": -84.935342,
      "city": "Georgetown",
      "state": "TN",
      "county": "Meigs"
  },
  {
      "zipCode": 37337,
      "latitude": 35.623951,
      "longitude": -84.942494,
      "city": "Grandview",
      "state": "TN",
      "county": "Rhea"
  },
  {
      "zipCode": 37338,
      "latitude": 35.602658,
      "longitude": -84.998978,
      "city": "Graysville",
      "state": "TN",
      "county": "Rhea"
  },
  {
      "zipCode": 37339,
      "latitude": 35.385223,
      "longitude": -85.653738,
      "city": "Gruetli Laager",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37340,
      "latitude": 35.029115,
      "longitude": -85.517231,
      "city": "Guild",
      "state": "TN",
      "county": "Marion"
  },
  {
      "zipCode": 37341,
      "latitude": 35.214176,
      "longitude": -85.091744,
      "city": "Harrison",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37342,
      "latitude": 35.380586,
      "longitude": -85.965585,
      "city": "Hillsboro",
      "state": "TN",
      "county": "Coffee"
  },
  {
      "zipCode": 37343,
      "latitude": 35.166775,
      "longitude": -85.21024,
      "city": "Hixson",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37345,
      "latitude": 35.045745,
      "longitude": -86.25541,
      "city": "Huntland",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37347,
      "latitude": 35.097012,
      "longitude": -85.589691,
      "city": "Jasper",
      "state": "TN",
      "county": "Marion"
  },
  {
      "zipCode": 37348,
      "latitude": 35.103977,
      "longitude": -86.418625,
      "city": "Kelso",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 37349,
      "latitude": 35.497637,
      "longitude": -86.07482,
      "city": "Manchester",
      "state": "TN",
      "county": "Coffee"
  },
  {
      "zipCode": 37350,
      "latitude": 35.130697,
      "longitude": -85.301627,
      "city": "Lookout Mountain",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37351,
      "latitude": 35.104522,
      "longitude": -85.2631,
      "city": "Lupton City",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37352,
      "latitude": 35.282767,
      "longitude": -86.305403,
      "city": "Lynchburg",
      "state": "TN",
      "county": "Moore"
  },
  {
      "zipCode": 37353,
      "latitude": 35.160258,
      "longitude": -84.889237,
      "city": "Mc Donald",
      "state": "TN",
      "county": "Bradley"
  },
  {
      "zipCode": 37354,
      "latitude": 35.491574,
      "longitude": -84.33952,
      "city": "Madisonville",
      "state": "TN",
      "county": "Monroe"
  },
  {
      "zipCode": 37355,
      "latitude": 35.517946,
      "longitude": -86.06988,
      "city": "Manchester",
      "state": "TN",
      "county": "Coffee"
  },
  {
      "zipCode": 37356,
      "latitude": 35.264698,
      "longitude": -85.822933,
      "city": "Monteagle",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37357,
      "latitude": 35.621365,
      "longitude": -85.875966,
      "city": "Morrison",
      "state": "TN",
      "county": "Warren"
  },
  {
      "zipCode": 37359,
      "latitude": 35.194599,
      "longitude": -86.467197,
      "city": "Mulberry",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 37360,
      "latitude": 35.468961,
      "longitude": -86.305194,
      "city": "Normandy",
      "state": "TN",
      "county": "Bedford"
  },
  {
      "zipCode": 37361,
      "latitude": 35.101062,
      "longitude": -84.689716,
      "city": "Ocoee",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37362,
      "latitude": 35.049197,
      "longitude": -84.735366,
      "city": "Oldfort",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37363,
      "latitude": 35.120227,
      "longitude": -85.060369,
      "city": "Ooltewah",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37364,
      "latitude": 35.17272,
      "longitude": -84.861885,
      "city": "Cleveland",
      "state": "TN",
      "county": "Bradley"
  },
  {
      "zipCode": 37365,
      "latitude": 35.373473,
      "longitude": -85.699601,
      "city": "Palmer",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37366,
      "latitude": 35.373473,
      "longitude": -85.699601,
      "city": "Pelham",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37367,
      "latitude": 35.609437,
      "longitude": -85.210253,
      "city": "Pikeville",
      "state": "TN",
      "county": "Bledsoe"
  },
  {
      "zipCode": 37369,
      "latitude": 35.171646,
      "longitude": -84.502303,
      "city": "Reliance",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37370,
      "latitude": 35.373143,
      "longitude": -84.695085,
      "city": "Riceville",
      "state": "TN",
      "county": "Mcminn"
  },
  {
      "zipCode": 37371,
      "latitude": 35.441376,
      "longitude": -84.641623,
      "city": "Athens",
      "state": "TN",
      "county": "Mcminn"
  },
  {
      "zipCode": 37372,
      "latitude": 35.175522,
      "longitude": -86.098338,
      "city": "Saint Andrews",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37373,
      "latitude": 35.388975,
      "longitude": -85.104336,
      "city": "Sale Creek",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37374,
      "latitude": 35.170416,
      "longitude": -85.632114,
      "city": "Sequatchie",
      "state": "TN",
      "county": "Marion"
  },
  {
      "zipCode": 37375,
      "latitude": 35.184313,
      "longitude": -86.06546,
      "city": "Sewanee",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37376,
      "latitude": 35.045233,
      "longitude": -85.930195,
      "city": "Sherwood",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37377,
      "latitude": 35.175117,
      "longitude": -85.319805,
      "city": "Signal Mountain",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37378,
      "latitude": 35.678368,
      "longitude": -85.777633,
      "city": "Smartt",
      "state": "TN",
      "county": "Warren"
  },
  {
      "zipCode": 37379,
      "latitude": 35.211052,
      "longitude": -85.198979,
      "city": "Soddy Daisy",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37380,
      "latitude": 35.109722,
      "longitude": -85.655679,
      "city": "South Pittsburg",
      "state": "TN",
      "county": "Marion"
  },
  {
      "zipCode": 37381,
      "latitude": 35.693499,
      "longitude": -84.819767,
      "city": "Spring City",
      "state": "TN",
      "county": "Rhea"
  },
  {
      "zipCode": 37382,
      "latitude": 35.497637,
      "longitude": -86.07482,
      "city": "Summitville",
      "state": "TN",
      "county": "Coffee"
  },
  {
      "zipCode": 37383,
      "latitude": 35.180503,
      "longitude": -85.903522,
      "city": "Sewanee",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37384,
      "latitude": 35.221092,
      "longitude": -85.209082,
      "city": "Soddy Daisy",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37385,
      "latitude": 35.376907,
      "longitude": -84.272243,
      "city": "Tellico Plains",
      "state": "TN",
      "county": "Monroe"
  },
  {
      "zipCode": 37387,
      "latitude": 35.273159,
      "longitude": -85.761237,
      "city": "Tracy City",
      "state": "TN",
      "county": "Grundy"
  },
  {
      "zipCode": 37388,
      "latitude": 35.497968,
      "longitude": -86.197898,
      "city": "Tullahoma",
      "state": "TN",
      "county": "Coffee"
  },
  {
      "zipCode": 37389,
      "latitude": 35.497637,
      "longitude": -86.07482,
      "city": "Arnold Afb",
      "state": "TN",
      "county": "Coffee"
  },
  {
      "zipCode": 37391,
      "latitude": 35.089723,
      "longitude": -84.376621,
      "city": "Turtletown",
      "state": "TN",
      "county": "Polk"
  },
  {
      "zipCode": 37394,
      "latitude": 35.527616,
      "longitude": -85.842163,
      "city": "Viola",
      "state": "TN",
      "county": "Warren"
  },
  {
      "zipCode": 37395,
      "latitude": 35.61722,
      "longitude": -84.928921,
      "city": "Watts Bar Dam",
      "state": "TN",
      "county": "Rhea"
  },
  {
      "zipCode": 37396,
      "latitude": 35.020638,
      "longitude": -85.529023,
      "city": "Whiteside",
      "state": "TN",
      "county": "Marion"
  },
  {
      "zipCode": 37397,
      "latitude": 35.171226,
      "longitude": -85.530468,
      "city": "Whitwell",
      "state": "TN",
      "county": "Sequatchie"
  },
  {
      "zipCode": 37398,
      "latitude": 35.146602,
      "longitude": -86.102966,
      "city": "Winchester",
      "state": "TN",
      "county": "Franklin"
  },
  {
      "zipCode": 37401,
      "latitude": 35.017818,
      "longitude": -85.206426,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37402,
      "latitude": 35.046323,
      "longitude": -85.312907,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37403,
      "latitude": 35.047273,
      "longitude": -85.292207,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37404,
      "latitude": 35.024174,
      "longitude": -85.271756,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37405,
      "latitude": 35.089664,
      "longitude": -85.335675,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37406,
      "latitude": 35.067272,
      "longitude": -85.250058,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37407,
      "latitude": 35.002474,
      "longitude": -85.289806,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37408,
      "latitude": 35.029154,
      "longitude": -85.30685,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37409,
      "latitude": 35.003374,
      "longitude": -85.331395,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37410,
      "latitude": 35.00307,
      "longitude": -85.308956,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37411,
      "latitude": 35.035273,
      "longitude": -85.245455,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37412,
      "latitude": 35.001524,
      "longitude": -85.238392,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37414,
      "latitude": 35.221092,
      "longitude": -85.209082,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37415,
      "latitude": 35.129075,
      "longitude": -85.265017,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37416,
      "latitude": 35.102747,
      "longitude": -85.182663,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37419,
      "latitude": 35.050162,
      "longitude": -85.340522,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37421,
      "latitude": 35.040495,
      "longitude": -85.153781,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37422,
      "latitude": 35.221092,
      "longitude": -85.209082,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37424,
      "latitude": 35.221092,
      "longitude": -85.209082,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37450,
      "latitude": 35.221092,
      "longitude": -85.209082,
      "city": "Chattanooga",
      "state": "TN",
      "county": "Hamilton"
  },
  {
      "zipCode": 37501,
      "latitude": 35.169255,
      "longitude": -89.990415,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 37601,
      "latitude": 36.3417,
      "longitude": -82.387716,
      "city": "Johnson City",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37602,
      "latitude": 36.271729,
      "longitude": -82.501212,
      "city": "Johnson City",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37604,
      "latitude": 36.308229,
      "longitude": -82.387422,
      "city": "Johnson City",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37605,
      "latitude": 36.315795,
      "longitude": -82.383792,
      "city": "Johnson City",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37614,
      "latitude": 36.157086,
      "longitude": -82.562649,
      "city": "Johnson City",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37615,
      "latitude": 36.352451,
      "longitude": -82.492958,
      "city": "Johnson City",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37616,
      "latitude": 36.227409,
      "longitude": -82.734005,
      "city": "Afton",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37617,
      "latitude": 36.525039,
      "longitude": -82.369895,
      "city": "Blountville",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37618,
      "latitude": 36.479403,
      "longitude": -82.261059,
      "city": "Bluff City",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37620,
      "latitude": 36.531703,
      "longitude": -82.192545,
      "city": "Bristol",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37621,
      "latitude": 36.504158,
      "longitude": -82.26446,
      "city": "Bristol",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37625,
      "latitude": 36.504158,
      "longitude": -82.26446,
      "city": "Bristol",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37640,
      "latitude": 36.362685,
      "longitude": -81.919401,
      "city": "Butler",
      "state": "TN",
      "county": "Johnson"
  },
  {
      "zipCode": 37641,
      "latitude": 36.256939,
      "longitude": -82.690504,
      "city": "Chuckey",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37642,
      "latitude": 36.495148,
      "longitude": -82.732009,
      "city": "Church Hill",
      "state": "TN",
      "county": "Hawkins"
  },
  {
      "zipCode": 37643,
      "latitude": 36.330784,
      "longitude": -82.122892,
      "city": "Elizabethton",
      "state": "TN",
      "county": "Carter"
  },
  {
      "zipCode": 37644,
      "latitude": 36.436637,
      "longitude": -82.051765,
      "city": "Elizabethton",
      "state": "TN",
      "county": "Carter"
  },
  {
      "zipCode": 37645,
      "latitude": 36.564092,
      "longitude": -82.683281,
      "city": "Mount Carmel",
      "state": "TN",
      "county": "Hawkins"
  },
  {
      "zipCode": 37650,
      "latitude": 36.06833,
      "longitude": -82.399677,
      "city": "Erwin",
      "state": "TN",
      "county": "Unicoi"
  },
  {
      "zipCode": 37656,
      "latitude": 36.307671,
      "longitude": -82.558738,
      "city": "Fall Branch",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37657,
      "latitude": 36.027038,
      "longitude": -82.545757,
      "city": "Flag Pond",
      "state": "TN",
      "county": "Unicoi"
  },
  {
      "zipCode": 37658,
      "latitude": 36.25366,
      "longitude": -82.149749,
      "city": "Hampton",
      "state": "TN",
      "county": "Carter"
  },
  {
      "zipCode": 37659,
      "latitude": 36.289189,
      "longitude": -82.489065,
      "city": "Jonesborough",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37660,
      "latitude": 36.514384,
      "longitude": -82.547615,
      "city": "Kingsport",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37662,
      "latitude": 36.530517,
      "longitude": -82.529816,
      "city": "Kingsport",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37663,
      "latitude": 36.47152,
      "longitude": -82.4834,
      "city": "Kingsport",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37664,
      "latitude": 36.503789,
      "longitude": -82.496969,
      "city": "Kingsport",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37665,
      "latitude": 36.579851,
      "longitude": -82.573274,
      "city": "Kingsport",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37669,
      "latitude": 36.504158,
      "longitude": -82.26446,
      "city": "Kingsport",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37680,
      "latitude": 36.526206,
      "longitude": -81.796303,
      "city": "Laurel Bloomery",
      "state": "TN",
      "county": "Johnson"
  },
  {
      "zipCode": 37681,
      "latitude": 36.251851,
      "longitude": -82.582954,
      "city": "Limestone",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37682,
      "latitude": 36.296007,
      "longitude": -82.305359,
      "city": "Milligan College",
      "state": "TN",
      "county": "Carter"
  },
  {
      "zipCode": 37683,
      "latitude": 36.451666,
      "longitude": -81.823043,
      "city": "Mountain City",
      "state": "TN",
      "county": "Johnson"
  },
  {
      "zipCode": 37684,
      "latitude": 36.271729,
      "longitude": -82.501212,
      "city": "Mountain Home",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37686,
      "latitude": 36.449142,
      "longitude": -82.340731,
      "city": "Piney Flats",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37687,
      "latitude": 36.21957,
      "longitude": -82.097785,
      "city": "Roan Mountain",
      "state": "TN",
      "county": "Carter"
  },
  {
      "zipCode": 37688,
      "latitude": 36.5407,
      "longitude": -81.879863,
      "city": "Shady Valley",
      "state": "TN",
      "county": "Johnson"
  },
  {
      "zipCode": 37690,
      "latitude": 36.244143,
      "longitude": -82.56404,
      "city": "Telford",
      "state": "TN",
      "county": "Washington"
  },
  {
      "zipCode": 37691,
      "latitude": 36.375484,
      "longitude": -81.75824,
      "city": "Trade",
      "state": "TN",
      "county": "Johnson"
  },
  {
      "zipCode": 37692,
      "latitude": 36.201952,
      "longitude": -82.316667,
      "city": "Unicoi",
      "state": "TN",
      "county": "Unicoi"
  },
  {
      "zipCode": 37694,
      "latitude": 36.384473,
      "longitude": -82.266921,
      "city": "Watauga",
      "state": "TN",
      "county": "Carter"
  },
  {
      "zipCode": 37699,
      "latitude": 36.504158,
      "longitude": -82.26446,
      "city": "Piney Flats",
      "state": "TN",
      "county": "Sullivan"
  },
  {
      "zipCode": 37701,
      "latitude": 35.815758,
      "longitude": -83.987698,
      "city": "Alcoa",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37705,
      "latitude": 36.202528,
      "longitude": -84.068808,
      "city": "Andersonville",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37707,
      "latitude": 36.566257,
      "longitude": -83.636479,
      "city": "Arthur",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37708,
      "latitude": 36.250636,
      "longitude": -83.493998,
      "city": "Bean Station",
      "state": "TN",
      "county": "Grainger"
  },
  {
      "zipCode": 37709,
      "latitude": 36.161793,
      "longitude": -83.68192,
      "city": "Blaine",
      "state": "TN",
      "county": "Grainger"
  },
  {
      "zipCode": 37710,
      "latitude": 36.162585,
      "longitude": -84.299217,
      "city": "Briceville",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37711,
      "latitude": 36.314262,
      "longitude": -83.12129,
      "city": "Bulls Gap",
      "state": "TN",
      "county": "Hawkins"
  },
  {
      "zipCode": 37713,
      "latitude": 36.104208,
      "longitude": -83.121619,
      "city": "Bybee",
      "state": "TN",
      "county": "Cocke"
  },
  {
      "zipCode": 37714,
      "latitude": 36.302779,
      "longitude": -84.20298,
      "city": "Caryville",
      "state": "TN",
      "county": "Campbell"
  },
  {
      "zipCode": 37715,
      "latitude": 36.560275,
      "longitude": -83.947827,
      "city": "Clairfield",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37716,
      "latitude": 36.105314,
      "longitude": -84.17446,
      "city": "Clinton",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37717,
      "latitude": 36.110028,
      "longitude": -84.167208,
      "city": "Clinton",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37719,
      "latitude": 36.035211,
      "longitude": -84.390727,
      "city": "Coalfield",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37721,
      "latitude": 36.029286,
      "longitude": -83.817508,
      "city": "Corryton",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37722,
      "latitude": 35.839769,
      "longitude": -83.203929,
      "city": "Cosby",
      "state": "TN",
      "county": "Cocke"
  },
  {
      "zipCode": 37723,
      "latitude": 35.951478,
      "longitude": -84.812093,
      "city": "Crab Orchard",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 37724,
      "latitude": 36.556666,
      "longitude": -83.690318,
      "city": "Cumberland Gap",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37725,
      "latitude": 36.000789,
      "longitude": -83.423276,
      "city": "Dandridge",
      "state": "TN",
      "county": "Jefferson"
  },
  {
      "zipCode": 37726,
      "latitude": 36.202427,
      "longitude": -84.79351,
      "city": "Deer Lodge",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37727,
      "latitude": 35.909233,
      "longitude": -83.006316,
      "city": "Del Rio",
      "state": "TN",
      "county": "Cocke"
  },
  {
      "zipCode": 37729,
      "latitude": 36.508642,
      "longitude": -84.045356,
      "city": "Duff",
      "state": "TN",
      "county": "Campbell"
  },
  {
      "zipCode": 37730,
      "latitude": 36.550453,
      "longitude": -83.964644,
      "city": "Eagan",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37731,
      "latitude": 36.510756,
      "longitude": -83.057056,
      "city": "Eidson",
      "state": "TN",
      "county": "Hawkins"
  },
  {
      "zipCode": 37732,
      "latitude": 36.387879,
      "longitude": -84.616597,
      "city": "Elgin",
      "state": "TN",
      "county": "Scott"
  },
  {
      "zipCode": 37733,
      "latitude": 36.351914,
      "longitude": -84.713666,
      "city": "Rugby",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37737,
      "latitude": 35.758228,
      "longitude": -84.101182,
      "city": "Friendsville",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37738,
      "latitude": 35.727782,
      "longitude": -83.436982,
      "city": "Gatlinburg",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37742,
      "latitude": 35.674723,
      "longitude": -84.189652,
      "city": "Greenback",
      "state": "TN",
      "county": "Loudon"
  },
  {
      "zipCode": 37743,
      "latitude": 36.131582,
      "longitude": -82.869219,
      "city": "Greeneville",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37744,
      "latitude": 36.168271,
      "longitude": -82.854836,
      "city": "Greeneville",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37745,
      "latitude": 36.245521,
      "longitude": -82.82378,
      "city": "Greeneville",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37748,
      "latitude": 35.919953,
      "longitude": -84.516642,
      "city": "Harriman",
      "state": "TN",
      "county": "Roane"
  },
  {
      "zipCode": 37752,
      "latitude": 36.532527,
      "longitude": -83.573282,
      "city": "Harrogate",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37753,
      "latitude": 35.82414,
      "longitude": -83.098214,
      "city": "Hartford",
      "state": "TN",
      "county": "Cocke"
  },
  {
      "zipCode": 37754,
      "latitude": 36.087461,
      "longitude": -84.041266,
      "city": "Heiskell",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37755,
      "latitude": 36.425232,
      "longitude": -84.543615,
      "city": "Helenwood",
      "state": "TN",
      "county": "Scott"
  },
  {
      "zipCode": 37756,
      "latitude": 36.354403,
      "longitude": -84.449208,
      "city": "Huntsville",
      "state": "TN",
      "county": "Scott"
  },
  {
      "zipCode": 37757,
      "latitude": 36.307449,
      "longitude": -84.15575,
      "city": "Jacksboro",
      "state": "TN",
      "county": "Campbell"
  },
  {
      "zipCode": 37760,
      "latitude": 36.09903,
      "longitude": -83.457262,
      "city": "Jefferson City",
      "state": "TN",
      "county": "Jefferson"
  },
  {
      "zipCode": 37762,
      "latitude": 36.446193,
      "longitude": -84.148034,
      "city": "Jellico",
      "state": "TN",
      "county": "Campbell"
  },
  {
      "zipCode": 37763,
      "latitude": 35.816595,
      "longitude": -84.521104,
      "city": "Kingston",
      "state": "TN",
      "county": "Roane"
  },
  {
      "zipCode": 37764,
      "latitude": 35.974032,
      "longitude": -83.602564,
      "city": "Kodak",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37765,
      "latitude": 36.493188,
      "longitude": -83.15127,
      "city": "Kyles Ford",
      "state": "TN",
      "county": "Hancock"
  },
  {
      "zipCode": 37766,
      "latitude": 36.424766,
      "longitude": -84.090654,
      "city": "La Follette",
      "state": "TN",
      "county": "Campbell"
  },
  {
      "zipCode": 37769,
      "latitude": 36.198793,
      "longitude": -84.118107,
      "city": "Lake City",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37770,
      "latitude": 36.146631,
      "longitude": -84.695508,
      "city": "Lancing",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37771,
      "latitude": 35.826473,
      "longitude": -84.28365,
      "city": "Lenoir City",
      "state": "TN",
      "county": "Loudon"
  },
  {
      "zipCode": 37772,
      "latitude": 35.787243,
      "longitude": -84.256679,
      "city": "Lenoir City",
      "state": "TN",
      "county": "Loudon"
  },
  {
      "zipCode": 37773,
      "latitude": 36.466398,
      "longitude": -83.678621,
      "city": "Lone Mountain",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37774,
      "latitude": 35.738092,
      "longitude": -84.344062,
      "city": "Loudon",
      "state": "TN",
      "county": "Loudon"
  },
  {
      "zipCode": 37777,
      "latitude": 35.827859,
      "longitude": -84.04546,
      "city": "Louisville",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37778,
      "latitude": 36.213293,
      "longitude": -83.275211,
      "city": "Lowland",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37779,
      "latitude": 36.218058,
      "longitude": -83.771137,
      "city": "Luttrell",
      "state": "TN",
      "county": "Union"
  },
  {
      "zipCode": 37801,
      "latitude": 35.688378,
      "longitude": -84.076895,
      "city": "Maryville",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37802,
      "latitude": 35.728283,
      "longitude": -83.933815,
      "city": "Maryville",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37803,
      "latitude": 35.653917,
      "longitude": -83.995621,
      "city": "Maryville",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37804,
      "latitude": 35.799053,
      "longitude": -83.885212,
      "city": "Maryville",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37806,
      "latitude": 36.085904,
      "longitude": -83.737262,
      "city": "Mascot",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37807,
      "latitude": 36.294236,
      "longitude": -83.806991,
      "city": "Maynardville",
      "state": "TN",
      "county": "Union"
  },
  {
      "zipCode": 37809,
      "latitude": 36.154227,
      "longitude": -82.994077,
      "city": "Midway",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37810,
      "latitude": 36.179069,
      "longitude": -83.083417,
      "city": "Mohawk",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37811,
      "latitude": 36.356017,
      "longitude": -83.184274,
      "city": "Mooresburg",
      "state": "TN",
      "county": "Hawkins"
  },
  {
      "zipCode": 37813,
      "latitude": 36.188826,
      "longitude": -83.261343,
      "city": "Morristown",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37814,
      "latitude": 36.224258,
      "longitude": -83.30984,
      "city": "Morristown",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37815,
      "latitude": 36.213293,
      "longitude": -83.275211,
      "city": "Morristown",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37816,
      "latitude": 36.213293,
      "longitude": -83.275211,
      "city": "Morristown",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37818,
      "latitude": 36.173296,
      "longitude": -82.976161,
      "city": "Mosheim",
      "state": "TN",
      "county": "Greene"
  },
  {
      "zipCode": 37819,
      "latitude": 36.556524,
      "longitude": -84.192358,
      "city": "Newcomb",
      "state": "TN",
      "county": "Campbell"
  },
  {
      "zipCode": 37820,
      "latitude": 36.086723,
      "longitude": -83.559174,
      "city": "New Market",
      "state": "TN",
      "county": "Jefferson"
  },
  {
      "zipCode": 37821,
      "latitude": 36.00244,
      "longitude": -83.159605,
      "city": "Newport",
      "state": "TN",
      "county": "Cocke"
  },
  {
      "zipCode": 37822,
      "latitude": 35.947688,
      "longitude": -83.106554,
      "city": "Newport",
      "state": "TN",
      "county": "Cocke"
  },
  {
      "zipCode": 37824,
      "latitude": 36.449963,
      "longitude": -83.591129,
      "city": "New Tazewell",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37825,
      "latitude": 36.454861,
      "longitude": -83.583199,
      "city": "New Tazewell",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37826,
      "latitude": 35.542073,
      "longitude": -84.582782,
      "city": "Niota",
      "state": "TN",
      "county": "Mcminn"
  },
  {
      "zipCode": 37828,
      "latitude": 36.200476,
      "longitude": -84.085785,
      "city": "Norris",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37829,
      "latitude": 35.984852,
      "longitude": -84.595764,
      "city": "Oakdale",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37830,
      "latitude": 36.019381,
      "longitude": -84.243175,
      "city": "Oak Ridge",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37831,
      "latitude": 36.105971,
      "longitude": -84.195796,
      "city": "Oak Ridge",
      "state": "TN",
      "county": "Anderson"
  },
  {
      "zipCode": 37840,
      "latitude": 36.049427,
      "longitude": -84.440586,
      "city": "Oliver Springs",
      "state": "TN",
      "county": "Roane"
  },
  {
      "zipCode": 37841,
      "latitude": 36.509395,
      "longitude": -84.517984,
      "city": "Oneida",
      "state": "TN",
      "county": "Scott"
  },
  {
      "zipCode": 37842,
      "latitude": 35.87543,
      "longitude": -84.792927,
      "city": "Ozone",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 37843,
      "latitude": 36.023406,
      "longitude": -83.054705,
      "city": "Parrottsville",
      "state": "TN",
      "county": "Cocke"
  },
  {
      "zipCode": 37845,
      "latitude": 36.081406,
      "longitude": -84.442969,
      "city": "Petros",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37846,
      "latitude": 35.678118,
      "longitude": -84.431236,
      "city": "Philadelphia",
      "state": "TN",
      "county": "Loudon"
  },
  {
      "zipCode": 37847,
      "latitude": 36.418966,
      "longitude": -84.201074,
      "city": "Pioneer",
      "state": "TN",
      "county": "Campbell"
  },
  {
      "zipCode": 37848,
      "latitude": 36.250636,
      "longitude": -83.493998,
      "city": "Powder Springs",
      "state": "TN",
      "county": "Grainger"
  },
  {
      "zipCode": 37849,
      "latitude": 36.071877,
      "longitude": -84.021456,
      "city": "Powell",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37851,
      "latitude": 36.557884,
      "longitude": -83.81392,
      "city": "Pruden",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37852,
      "latitude": 36.377044,
      "longitude": -84.581147,
      "city": "Robbins",
      "state": "TN",
      "county": "Scott"
  },
  {
      "zipCode": 37853,
      "latitude": 35.837548,
      "longitude": -83.906157,
      "city": "Rockford",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37854,
      "latitude": 35.843277,
      "longitude": -84.656532,
      "city": "Rockwood",
      "state": "TN",
      "county": "Roane"
  },
  {
      "zipCode": 37857,
      "latitude": 36.415976,
      "longitude": -82.949145,
      "city": "Rogersville",
      "state": "TN",
      "county": "Hawkins"
  },
  {
      "zipCode": 37860,
      "latitude": 36.244307,
      "longitude": -83.185535,
      "city": "Russellville",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37861,
      "latitude": 36.230696,
      "longitude": -83.554826,
      "city": "Rutledge",
      "state": "TN",
      "county": "Grainger"
  },
  {
      "zipCode": 37862,
      "latitude": 35.833278,
      "longitude": -83.572214,
      "city": "Sevierville",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37863,
      "latitude": 35.83391,
      "longitude": -83.568939,
      "city": "Pigeon Forge",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37864,
      "latitude": 35.854211,
      "longitude": -83.613849,
      "city": "Sevierville",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37865,
      "latitude": 35.86053,
      "longitude": -83.711272,
      "city": "Seymour",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37866,
      "latitude": 36.342768,
      "longitude": -83.82039,
      "city": "Sharps Chapel",
      "state": "TN",
      "county": "Union"
  },
  {
      "zipCode": 37867,
      "latitude": 36.580283,
      "longitude": -83.647254,
      "city": "Shawanee",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37868,
      "latitude": 35.880902,
      "longitude": -83.556134,
      "city": "Pigeon Forge",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37869,
      "latitude": 36.509867,
      "longitude": -83.185624,
      "city": "Sneedville",
      "state": "TN",
      "county": "Hancock"
  },
  {
      "zipCode": 37870,
      "latitude": 36.48404,
      "longitude": -83.823282,
      "city": "Speedwell",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37871,
      "latitude": 36.068694,
      "longitude": -83.656829,
      "city": "Strawberry Plains",
      "state": "TN",
      "county": "Jefferson"
  },
  {
      "zipCode": 37872,
      "latitude": 36.182465,
      "longitude": -84.599987,
      "city": "Sunbright",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37873,
      "latitude": 36.520167,
      "longitude": -82.857862,
      "city": "Surgoinsville",
      "state": "TN",
      "county": "Hawkins"
  },
  {
      "zipCode": 37874,
      "latitude": 35.571343,
      "longitude": -84.410561,
      "city": "Sweetwater",
      "state": "TN",
      "county": "Monroe"
  },
  {
      "zipCode": 37876,
      "latitude": 35.849046,
      "longitude": -83.51432,
      "city": "Sevierville",
      "state": "TN",
      "county": "Sevier"
  },
  {
      "zipCode": 37877,
      "latitude": 36.177707,
      "longitude": -83.407043,
      "city": "Talbott",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37878,
      "latitude": 35.584404,
      "longitude": -83.992315,
      "city": "Tallassee",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37879,
      "latitude": 36.471886,
      "longitude": -83.52214,
      "city": "Tazewell",
      "state": "TN",
      "county": "Claiborne"
  },
  {
      "zipCode": 37880,
      "latitude": 35.520244,
      "longitude": -84.825054,
      "city": "Ten Mile",
      "state": "TN",
      "county": "Meigs"
  },
  {
      "zipCode": 37881,
      "latitude": 36.250636,
      "longitude": -83.493998,
      "city": "Thorn Hill",
      "state": "TN",
      "county": "Grainger"
  },
  {
      "zipCode": 37882,
      "latitude": 35.682298,
      "longitude": -83.751242,
      "city": "Townsend",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37885,
      "latitude": 35.531891,
      "longitude": -84.225767,
      "city": "Vonore",
      "state": "TN",
      "county": "Monroe"
  },
  {
      "zipCode": 37886,
      "latitude": 35.754464,
      "longitude": -83.804833,
      "city": "Walland",
      "state": "TN",
      "county": "Blount"
  },
  {
      "zipCode": 37887,
      "latitude": 36.031584,
      "longitude": -84.560491,
      "city": "Wartburg",
      "state": "TN",
      "county": "Morgan"
  },
  {
      "zipCode": 37888,
      "latitude": 36.250636,
      "longitude": -83.493998,
      "city": "Washburn",
      "state": "TN",
      "county": "Grainger"
  },
  {
      "zipCode": 37890,
      "latitude": 36.077612,
      "longitude": -83.299826,
      "city": "White Pine",
      "state": "TN",
      "county": "Jefferson"
  },
  {
      "zipCode": 37891,
      "latitude": 36.256858,
      "longitude": -83.146114,
      "city": "Whitesburg",
      "state": "TN",
      "county": "Hamblen"
  },
  {
      "zipCode": 37892,
      "latitude": 36.5482,
      "longitude": -84.414551,
      "city": "Winfield",
      "state": "TN",
      "county": "Scott"
  },
  {
      "zipCode": 37901,
      "latitude": 36.032334,
      "longitude": -83.884804,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37902,
      "latitude": 35.944976,
      "longitude": -83.875822,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37909,
      "latitude": 35.944822,
      "longitude": -84.010109,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37912,
      "latitude": 35.981701,
      "longitude": -84.015635,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37914,
      "latitude": 35.983366,
      "longitude": -83.784109,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37915,
      "latitude": 35.990369,
      "longitude": -83.905415,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37916,
      "latitude": 35.933783,
      "longitude": -83.973635,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37917,
      "latitude": 35.998447,
      "longitude": -83.910527,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37918,
      "latitude": 36.065908,
      "longitude": -83.909603,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37919,
      "latitude": 35.937391,
      "longitude": -84.04224,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37920,
      "latitude": 35.89292,
      "longitude": -83.938689,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37921,
      "latitude": 36.021873,
      "longitude": -83.932092,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37922,
      "latitude": 35.858018,
      "longitude": -84.119383,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37923,
      "latitude": 35.924795,
      "longitude": -84.082405,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37924,
      "latitude": 35.985081,
      "longitude": -83.975603,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37927,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37928,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37929,
      "latitude": 35.922421,
      "longitude": -83.795492,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37930,
      "latitude": 35.9029,
      "longitude": -83.953554,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37931,
      "latitude": 35.976167,
      "longitude": -84.125653,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37932,
      "latitude": 35.933487,
      "longitude": -84.148074,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37933,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37938,
      "latitude": 36.116665,
      "longitude": -83.935206,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37939,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37940,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37950,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37990,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37995,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37996,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37997,
      "latitude": 35.990142,
      "longitude": -83.96218,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 37998,
      "latitude": 35.935134,
      "longitude": -83.750258,
      "city": "Knoxville",
      "state": "TN",
      "county": "Knox"
  },
  {
      "zipCode": 38001,
      "latitude": 35.806106,
      "longitude": -89.086594,
      "city": "Alamo",
      "state": "TN",
      "county": "Crockett"
  },
  {
      "zipCode": 38002,
      "latitude": 35.292102,
      "longitude": -89.752615,
      "city": "Arlington",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38004,
      "latitude": 35.454301,
      "longitude": -89.809562,
      "city": "Atoka",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38006,
      "latitude": 35.75357,
      "longitude": -89.104516,
      "city": "Bells",
      "state": "TN",
      "county": "Crockett"
  },
  {
      "zipCode": 38007,
      "latitude": 36.158668,
      "longitude": -89.437684,
      "city": "Bogota",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38008,
      "latitude": 35.197635,
      "longitude": -88.986448,
      "city": "Bolivar",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38010,
      "latitude": 35.288624,
      "longitude": -89.48802,
      "city": "Braden",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38011,
      "latitude": 35.473412,
      "longitude": -89.745307,
      "city": "Brighton",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38012,
      "latitude": 35.633418,
      "longitude": -89.276381,
      "city": "Brownsville",
      "state": "TN",
      "county": "Haywood"
  },
  {
      "zipCode": 38014,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Brunswick",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38015,
      "latitude": 35.551009,
      "longitude": -89.738952,
      "city": "Burlison",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38016,
      "latitude": 35.18086,
      "longitude": -89.759838,
      "city": "Cordova",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38017,
      "latitude": 35.080992,
      "longitude": -89.699083,
      "city": "Collierville",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38018,
      "latitude": 35.104589,
      "longitude": -89.900314,
      "city": "Cordova",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38019,
      "latitude": 35.541624,
      "longitude": -89.63552,
      "city": "Covington",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38021,
      "latitude": 35.871014,
      "longitude": -89.175275,
      "city": "Crockett Mills",
      "state": "TN",
      "county": "Crockett"
  },
  {
      "zipCode": 38023,
      "latitude": 35.460696,
      "longitude": -89.93429,
      "city": "Drummonds",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38024,
      "latitude": 36.036069,
      "longitude": -89.438461,
      "city": "Dyersburg",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38025,
      "latitude": 36.046851,
      "longitude": -89.443779,
      "city": "Dyersburg",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38027,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Collierville",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38028,
      "latitude": 35.175796,
      "longitude": -89.684982,
      "city": "Eads",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38029,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Ellendale",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38030,
      "latitude": 36.068517,
      "longitude": -89.561464,
      "city": "Finley",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38034,
      "latitude": 35.843638,
      "longitude": -89.207168,
      "city": "Friendship",
      "state": "TN",
      "county": "Crockett"
  },
  {
      "zipCode": 38036,
      "latitude": 35.327428,
      "longitude": -89.619816,
      "city": "Gallaway",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38037,
      "latitude": 35.792984,
      "longitude": -89.646955,
      "city": "Gates",
      "state": "TN",
      "county": "Lauderdale"
  },
  {
      "zipCode": 38039,
      "latitude": 35.055153,
      "longitude": -89.140622,
      "city": "Grand Junction",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38040,
      "latitude": 35.840794,
      "longitude": -89.531217,
      "city": "Halls",
      "state": "TN",
      "county": "Lauderdale"
  },
  {
      "zipCode": 38041,
      "latitude": 35.659259,
      "longitude": -89.696162,
      "city": "Henning",
      "state": "TN",
      "county": "Lauderdale"
  },
  {
      "zipCode": 38042,
      "latitude": 35.124195,
      "longitude": -89.075852,
      "city": "Hickory Valley",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38044,
      "latitude": 35.189276,
      "longitude": -88.955968,
      "city": "Hornsby",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38045,
      "latitude": 35.290428,
      "longitude": -89.232185,
      "city": "Laconia",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38046,
      "latitude": 35.10486,
      "longitude": -89.350387,
      "city": "La Grange",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38047,
      "latitude": 36.063765,
      "longitude": -89.560879,
      "city": "Lenox",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38048,
      "latitude": 35.15066,
      "longitude": -89.481362,
      "city": "Macon",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38049,
      "latitude": 35.443164,
      "longitude": -89.563798,
      "city": "Mason",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38050,
      "latitude": 35.837913,
      "longitude": -89.227284,
      "city": "Maury City",
      "state": "TN",
      "county": "Crockett"
  },
  {
      "zipCode": 38052,
      "latitude": 35.086247,
      "longitude": -88.912495,
      "city": "Middleton",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38053,
      "latitude": 35.320197,
      "longitude": -89.892286,
      "city": "Millington",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38054,
      "latitude": 35.334132,
      "longitude": -89.870636,
      "city": "Millington",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38055,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Millington",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38056,
      "latitude": 36.046851,
      "longitude": -89.443779,
      "city": "Miston",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38057,
      "latitude": 35.077518,
      "longitude": -89.33787,
      "city": "Moscow",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38058,
      "latitude": 35.463075,
      "longitude": -89.711843,
      "city": "Munford",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38059,
      "latitude": 36.113717,
      "longitude": -89.272827,
      "city": "Newbern",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38060,
      "latitude": 35.234809,
      "longitude": -89.489977,
      "city": "Oakland",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38061,
      "latitude": 35.075589,
      "longitude": -88.957929,
      "city": "Pocahontas",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38063,
      "latitude": 35.754505,
      "longitude": -89.66112,
      "city": "Ripley",
      "state": "TN",
      "county": "Lauderdale"
  },
  {
      "zipCode": 38066,
      "latitude": 35.084994,
      "longitude": -89.421234,
      "city": "Rossville",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38067,
      "latitude": 35.084166,
      "longitude": -89.005719,
      "city": "Saulsbury",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38068,
      "latitude": 35.259373,
      "longitude": -89.409857,
      "city": "Somerville",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38069,
      "latitude": 35.456111,
      "longitude": -89.339146,
      "city": "Stanton",
      "state": "TN",
      "county": "Haywood"
  },
  {
      "zipCode": 38070,
      "latitude": 35.942019,
      "longitude": -89.243405,
      "city": "Tigrett",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38071,
      "latitude": 35.414319,
      "longitude": -89.818781,
      "city": "Tipton",
      "state": "TN",
      "county": "Tipton"
  },
  {
      "zipCode": 38074,
      "latitude": 35.213371,
      "longitude": -88.990028,
      "city": "Bolivar",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38075,
      "latitude": 35.314672,
      "longitude": -89.126324,
      "city": "Whiteville",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38076,
      "latitude": 35.141626,
      "longitude": -89.422466,
      "city": "Williston",
      "state": "TN",
      "county": "Fayette"
  },
  {
      "zipCode": 38077,
      "latitude": 36.344296,
      "longitude": -89.522851,
      "city": "Wynnburg",
      "state": "TN",
      "county": "Lake"
  },
  {
      "zipCode": 38079,
      "latitude": 36.35529,
      "longitude": -89.472468,
      "city": "Tiptonville",
      "state": "TN",
      "county": "Lake"
  },
  {
      "zipCode": 38080,
      "latitude": 36.25973,
      "longitude": -89.465379,
      "city": "Ridgely",
      "state": "TN",
      "county": "Lake"
  },
  {
      "zipCode": 38083,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Millington",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38088,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Cordova",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38101,
      "latitude": 35.050667,
      "longitude": -89.847782,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38103,
      "latitude": 35.154875,
      "longitude": -90.049631,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38104,
      "latitude": 35.13573,
      "longitude": -90.002079,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38105,
      "latitude": 35.15182,
      "longitude": -90.033959,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38106,
      "latitude": 35.096351,
      "longitude": -90.042984,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38107,
      "latitude": 35.170953,
      "longitude": -90.017716,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38108,
      "latitude": 35.175239,
      "longitude": -89.97306,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38109,
      "latitude": 35.053795,
      "longitude": -90.094757,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38110,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38111,
      "latitude": 35.10956,
      "longitude": -89.948119,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38112,
      "latitude": 35.146285,
      "longitude": -89.981523,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38113,
      "latitude": 35.12738,
      "longitude": -89.984484,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38114,
      "latitude": 35.099348,
      "longitude": -89.985187,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38115,
      "latitude": 35.057906,
      "longitude": -89.864023,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38116,
      "latitude": 35.032879,
      "longitude": -90.01109,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38117,
      "latitude": 35.113588,
      "longitude": -89.906749,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38118,
      "latitude": 35.037585,
      "longitude": -89.930187,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38119,
      "latitude": 35.078775,
      "longitude": -89.841963,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38120,
      "latitude": 35.123644,
      "longitude": -89.842541,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38122,
      "latitude": 35.157958,
      "longitude": -89.918514,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38124,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38125,
      "latitude": 35.028829,
      "longitude": -89.782647,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38126,
      "latitude": 35.127163,
      "longitude": -90.044782,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38127,
      "latitude": 35.24346,
      "longitude": -90.011979,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38128,
      "latitude": 35.223442,
      "longitude": -89.920266,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38130,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38131,
      "latitude": 35.064837,
      "longitude": -89.992905,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38132,
      "latitude": 35.068014,
      "longitude": -90.003349,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38133,
      "latitude": 35.208941,
      "longitude": -89.791656,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38134,
      "latitude": 35.184469,
      "longitude": -89.857389,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38135,
      "latitude": 35.234976,
      "longitude": -89.843594,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38136,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38137,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38138,
      "latitude": 35.08753,
      "longitude": -89.792638,
      "city": "Germantown",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38139,
      "latitude": 35.077091,
      "longitude": -89.759979,
      "city": "Germantown",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38140,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38141,
      "latitude": 35.014466,
      "longitude": -89.853346,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38142,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38143,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38145,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38146,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38147,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38148,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38150,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38151,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38152,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38157,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38159,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38161,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38163,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38165,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38166,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38167,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38168,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38173,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38174,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38175,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38177,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38181,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38182,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38183,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Germantown",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38184,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38186,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38187,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38188,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38190,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38193,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38194,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38195,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38197,
      "latitude": 35.201738,
      "longitude": -89.971538,
      "city": "Memphis",
      "state": "TN",
      "county": "Shelby"
  },
  {
      "zipCode": 38201,
      "latitude": 36.077501,
      "longitude": -88.495094,
      "city": "Mc Kenzie",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38220,
      "latitude": 35.98991,
      "longitude": -88.635869,
      "city": "Atwood",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38221,
      "latitude": 36.246599,
      "longitude": -88.046704,
      "city": "Big Sandy",
      "state": "TN",
      "county": "Benton"
  },
  {
      "zipCode": 38222,
      "latitude": 36.428005,
      "longitude": -88.179868,
      "city": "Buchanan",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38223,
      "latitude": 36.310995,
      "longitude": -88.260367,
      "city": "Como",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38224,
      "latitude": 36.401598,
      "longitude": -88.466035,
      "city": "Cottage Grove",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38225,
      "latitude": 36.313605,
      "longitude": -88.742473,
      "city": "Dresden",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38226,
      "latitude": 36.347497,
      "longitude": -88.677715,
      "city": "Dukedom",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38229,
      "latitude": 36.224842,
      "longitude": -88.616346,
      "city": "Gleason",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38230,
      "latitude": 36.213708,
      "longitude": -88.781253,
      "city": "Greenfield",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38231,
      "latitude": 36.221214,
      "longitude": -88.421701,
      "city": "Henry",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38232,
      "latitude": 36.35288,
      "longitude": -89.333345,
      "city": "Hornbeak",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38233,
      "latitude": 36.134323,
      "longitude": -89.028993,
      "city": "Kenton",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38235,
      "latitude": 35.977824,
      "longitude": -88.574447,
      "city": "Mc Lemoresville",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38236,
      "latitude": 36.176184,
      "longitude": -88.277355,
      "city": "Mansfield",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38237,
      "latitude": 36.324103,
      "longitude": -88.782561,
      "city": "Martin",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38238,
      "latitude": 36.282357,
      "longitude": -88.738132,
      "city": "Martin",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38240,
      "latitude": 36.271215,
      "longitude": -89.292228,
      "city": "Obion",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38241,
      "latitude": 36.391905,
      "longitude": -88.611055,
      "city": "Palmersville",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38242,
      "latitude": 36.31586,
      "longitude": -88.315916,
      "city": "Paris",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38251,
      "latitude": 36.333902,
      "longitude": -88.304762,
      "city": "Puryear",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38253,
      "latitude": 36.310755,
      "longitude": -89.073128,
      "city": "Rives",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38254,
      "latitude": 36.379051,
      "longitude": -89.353821,
      "city": "Samburg",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38255,
      "latitude": 36.241614,
      "longitude": -88.860371,
      "city": "Sharon",
      "state": "TN",
      "county": "Weakley"
  },
  {
      "zipCode": 38256,
      "latitude": 36.295353,
      "longitude": -88.157201,
      "city": "Springville",
      "state": "TN",
      "county": "Henry"
  },
  {
      "zipCode": 38257,
      "latitude": 36.381482,
      "longitude": -89.08396,
      "city": "South Fulton",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38258,
      "latitude": 36.040631,
      "longitude": -88.606605,
      "city": "Trezevant",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38259,
      "latitude": 36.168626,
      "longitude": -89.198503,
      "city": "Trimble",
      "state": "TN",
      "county": "Dyer"
  },
  {
      "zipCode": 38260,
      "latitude": 36.358606,
      "longitude": -89.210108,
      "city": "Troy",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38261,
      "latitude": 36.354638,
      "longitude": -89.122231,
      "city": "Union City",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38271,
      "latitude": 36.458295,
      "longitude": -89.233227,
      "city": "Woodland Mills",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38281,
      "latitude": 36.354384,
      "longitude": -89.149881,
      "city": "Union City",
      "state": "TN",
      "county": "Obion"
  },
  {
      "zipCode": 38301,
      "latitude": 35.570424,
      "longitude": -88.862742,
      "city": "Jackson",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38302,
      "latitude": 35.612405,
      "longitude": -88.841225,
      "city": "Jackson",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38303,
      "latitude": 35.612405,
      "longitude": -88.841225,
      "city": "Jackson",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38305,
      "latitude": 35.652446,
      "longitude": -88.835682,
      "city": "Jackson",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38308,
      "latitude": 35.612405,
      "longitude": -88.841225,
      "city": "Jackson",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38310,
      "latitude": 35.271057,
      "longitude": -88.447528,
      "city": "Adamsville",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38311,
      "latitude": 35.448494,
      "longitude": -88.121113,
      "city": "Bath Springs",
      "state": "TN",
      "county": "Decatur"
  },
  {
      "zipCode": 38313,
      "latitude": 35.613916,
      "longitude": -88.660454,
      "city": "Beech Bluff",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38314,
      "latitude": 35.612405,
      "longitude": -88.841225,
      "city": "Jackson",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38315,
      "latitude": 35.272195,
      "longitude": -88.626639,
      "city": "Bethel Springs",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38316,
      "latitude": 36.057402,
      "longitude": -88.804199,
      "city": "Bradford",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38317,
      "latitude": 36.056026,
      "longitude": -88.282103,
      "city": "Bruceton",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38318,
      "latitude": 35.961327,
      "longitude": -88.270214,
      "city": "Buena Vista",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38320,
      "latitude": 36.082972,
      "longitude": -88.101856,
      "city": "Camden",
      "state": "TN",
      "county": "Benton"
  },
  {
      "zipCode": 38321,
      "latitude": 35.87179,
      "longitude": -88.529538,
      "city": "Cedar Grove",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38324,
      "latitude": 35.867454,
      "longitude": -88.39343,
      "city": "Clarksburg",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38326,
      "latitude": 35.110079,
      "longitude": -88.292167,
      "city": "Counce",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38327,
      "latitude": 35.219095,
      "longitude": -88.319171,
      "city": "Crump",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38328,
      "latitude": 35.666113,
      "longitude": -88.215928,
      "city": "Darden",
      "state": "TN",
      "county": "Henderson"
  },
  {
      "zipCode": 38329,
      "latitude": 35.536264,
      "longitude": -88.114375,
      "city": "Decaturville",
      "state": "TN",
      "county": "Decatur"
  },
  {
      "zipCode": 38330,
      "latitude": 36.080961,
      "longitude": -89.036758,
      "city": "Dyer",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38331,
      "latitude": 35.969336,
      "longitude": -89.131915,
      "city": "Eaton",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38332,
      "latitude": 35.428488,
      "longitude": -88.429204,
      "city": "Enville",
      "state": "TN",
      "county": "Chester"
  },
  {
      "zipCode": 38333,
      "latitude": 36.114158,
      "longitude": -87.98197,
      "city": "Eva",
      "state": "TN",
      "county": "Benton"
  },
  {
      "zipCode": 38334,
      "latitude": 35.268959,
      "longitude": -88.567203,
      "city": "Finger",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38336,
      "latitude": 35.748414,
      "longitude": -89.032672,
      "city": "Fruitvale",
      "state": "TN",
      "county": "Crockett"
  },
  {
      "zipCode": 38337,
      "latitude": 35.780292,
      "longitude": -89.026456,
      "city": "Gadsden",
      "state": "TN",
      "county": "Crockett"
  },
  {
      "zipCode": 38338,
      "latitude": 35.870849,
      "longitude": -88.846372,
      "city": "Gibson",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38339,
      "latitude": 35.056023,
      "longitude": -88.504368,
      "city": "Guys",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38340,
      "latitude": 35.407562,
      "longitude": -88.642991,
      "city": "Henderson",
      "state": "TN",
      "county": "Chester"
  },
  {
      "zipCode": 38341,
      "latitude": 35.905992,
      "longitude": -88.078052,
      "city": "Holladay",
      "state": "TN",
      "county": "Benton"
  },
  {
      "zipCode": 38342,
      "latitude": 36.05038,
      "longitude": -88.291871,
      "city": "Hollow Rock",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38343,
      "latitude": 35.880408,
      "longitude": -88.93101,
      "city": "Humboldt",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38344,
      "latitude": 35.985967,
      "longitude": -88.433709,
      "city": "Huntingdon",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38345,
      "latitude": 35.621106,
      "longitude": -88.496219,
      "city": "Huron",
      "state": "TN",
      "county": "Henderson"
  },
  {
      "zipCode": 38346,
      "latitude": 36.030588,
      "longitude": -88.805599,
      "city": "Idlewild",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38347,
      "latitude": 35.471954,
      "longitude": -88.491318,
      "city": "Jacks Creek",
      "state": "TN",
      "county": "Chester"
  },
  {
      "zipCode": 38348,
      "latitude": 35.860641,
      "longitude": -88.640325,
      "city": "Lavinia",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38351,
      "latitude": 35.675767,
      "longitude": -88.412139,
      "city": "Lexington",
      "state": "TN",
      "county": "Henderson"
  },
  {
      "zipCode": 38352,
      "latitude": 35.535462,
      "longitude": -88.537854,
      "city": "Luray",
      "state": "TN",
      "county": "Chester"
  },
  {
      "zipCode": 38355,
      "latitude": 35.813893,
      "longitude": -88.753157,
      "city": "Medina",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38356,
      "latitude": 35.482255,
      "longitude": -88.911302,
      "city": "Medon",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38357,
      "latitude": 35.139103,
      "longitude": -88.565035,
      "city": "Michie",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38358,
      "latitude": 35.920312,
      "longitude": -88.813982,
      "city": "Milan",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38359,
      "latitude": 35.367207,
      "longitude": -88.388143,
      "city": "Milledgeville",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38361,
      "latitude": 35.300156,
      "longitude": -88.274636,
      "city": "Morris Chapel",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38362,
      "latitude": 35.724238,
      "longitude": -88.780118,
      "city": "Oakfield",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38363,
      "latitude": 35.697465,
      "longitude": -88.111755,
      "city": "Parsons",
      "state": "TN",
      "county": "Decatur"
  },
  {
      "zipCode": 38365,
      "latitude": 35.038284,
      "longitude": -88.225601,
      "city": "Pickwick Dam",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38366,
      "latitude": 35.485,
      "longitude": -88.747695,
      "city": "Pinson",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38367,
      "latitude": 35.065178,
      "longitude": -88.641833,
      "city": "Ramer",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38368,
      "latitude": 35.501858,
      "longitude": -88.368317,
      "city": "Reagan",
      "state": "TN",
      "county": "Henderson"
  },
  {
      "zipCode": 38369,
      "latitude": 36.127152,
      "longitude": -88.959639,
      "city": "Rutherford",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38370,
      "latitude": 35.322354,
      "longitude": -88.24275,
      "city": "Saltillo",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38371,
      "latitude": 35.467143,
      "longitude": -88.298898,
      "city": "Sardis",
      "state": "TN",
      "county": "Henderson"
  },
  {
      "zipCode": 38372,
      "latitude": 35.210571,
      "longitude": -88.179203,
      "city": "Savannah",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38374,
      "latitude": 35.466358,
      "longitude": -88.2138,
      "city": "Scotts Hill",
      "state": "TN",
      "county": "Decatur"
  },
  {
      "zipCode": 38375,
      "latitude": 35.214443,
      "longitude": -88.580145,
      "city": "Selmer",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38376,
      "latitude": 35.126018,
      "longitude": -88.346807,
      "city": "Shiloh",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38377,
      "latitude": 35.35604,
      "longitude": -88.826923,
      "city": "Silerton",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38378,
      "latitude": 35.766822,
      "longitude": -88.683882,
      "city": "Spring Creek",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38379,
      "latitude": 35.164604,
      "longitude": -88.443952,
      "city": "Stantonville",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38380,
      "latitude": 35.785097,
      "longitude": -88.041426,
      "city": "Sugar Tree",
      "state": "TN",
      "county": "Decatur"
  },
  {
      "zipCode": 38381,
      "latitude": 35.343473,
      "longitude": -88.942458,
      "city": "Toone",
      "state": "TN",
      "county": "Hardeman"
  },
  {
      "zipCode": 38382,
      "latitude": 35.96713,
      "longitude": -88.9815,
      "city": "Trenton",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38387,
      "latitude": 35.884516,
      "longitude": -88.289175,
      "city": "Westport",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38388,
      "latitude": 35.768119,
      "longitude": -88.348263,
      "city": "Wildersville",
      "state": "TN",
      "county": "Henderson"
  },
  {
      "zipCode": 38389,
      "latitude": 36.135364,
      "longitude": -89.111653,
      "city": "Yorkville",
      "state": "TN",
      "county": "Gibson"
  },
  {
      "zipCode": 38390,
      "latitude": 35.8458,
      "longitude": -88.357504,
      "city": "Yuma",
      "state": "TN",
      "county": "Carroll"
  },
  {
      "zipCode": 38391,
      "latitude": 35.540108,
      "longitude": -88.988401,
      "city": "Denmark",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38392,
      "latitude": 35.481628,
      "longitude": -89.027133,
      "city": "Mercer",
      "state": "TN",
      "county": "Madison"
  },
  {
      "zipCode": 38393,
      "latitude": 34.99687,
      "longitude": -88.645577,
      "city": "Chewalla",
      "state": "TN",
      "county": "Mcnairy"
  },
  {
      "zipCode": 38401,
      "latitude": 35.623997,
      "longitude": -87.00404,
      "city": "Columbia",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 38402,
      "latitude": 35.629413,
      "longitude": -87.068172,
      "city": "Columbia",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 38425,
      "latitude": 35.388038,
      "longitude": -87.930163,
      "city": "Clifton",
      "state": "TN",
      "county": "Wayne"
  },
  {
      "zipCode": 38449,
      "latitude": 35.055109,
      "longitude": -86.883959,
      "city": "Ardmore",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38450,
      "latitude": 35.180623,
      "longitude": -87.786778,
      "city": "Collinwood",
      "state": "TN",
      "county": "Wayne"
  },
  {
      "zipCode": 38451,
      "latitude": 35.493211,
      "longitude": -87.093094,
      "city": "Culleoka",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 38452,
      "latitude": 35.195385,
      "longitude": -87.827937,
      "city": "Cypress Inn",
      "state": "TN",
      "county": "Wayne"
  },
  {
      "zipCode": 38453,
      "latitude": 35.092452,
      "longitude": -86.716719,
      "city": "Dellrose",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 38454,
      "latitude": 35.74803,
      "longitude": -87.357729,
      "city": "Duck River",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 38455,
      "latitude": 35.05392,
      "longitude": -86.895305,
      "city": "Elkton",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38456,
      "latitude": 35.339549,
      "longitude": -87.307286,
      "city": "Ethridge",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38457,
      "latitude": 35.035472,
      "longitude": -87.291424,
      "city": "Five Points",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38459,
      "latitude": 35.183444,
      "longitude": -86.850038,
      "city": "Frankewing",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38460,
      "latitude": 35.077182,
      "longitude": -87.143713,
      "city": "Goodspring",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38461,
      "latitude": 35.627022,
      "longitude": -87.29402,
      "city": "Hampshire",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 38462,
      "latitude": 35.526554,
      "longitude": -87.489057,
      "city": "Hohenwald",
      "state": "TN",
      "county": "Lewis"
  },
  {
      "zipCode": 38463,
      "latitude": 35.07132,
      "longitude": -87.549745,
      "city": "Iron City",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38464,
      "latitude": 35.235493,
      "longitude": -87.397143,
      "city": "Lawrenceburg",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38468,
      "latitude": 35.149182,
      "longitude": -87.295169,
      "city": "Leoma",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38469,
      "latitude": 35.078858,
      "longitude": -87.433561,
      "city": "Loretto",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38471,
      "latitude": 35.083472,
      "longitude": -87.901086,
      "city": "Lutts",
      "state": "TN",
      "county": "Wayne"
  },
  {
      "zipCode": 38472,
      "latitude": 35.254073,
      "longitude": -87.023994,
      "city": "Lynnville",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38473,
      "latitude": 35.036274,
      "longitude": -87.150744,
      "city": "Minor Hill",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38474,
      "latitude": 35.541801,
      "longitude": -87.209525,
      "city": "Mount Pleasant",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 38475,
      "latitude": 35.264369,
      "longitude": -88.053366,
      "city": "Olivehill",
      "state": "TN",
      "county": "Hardin"
  },
  {
      "zipCode": 38476,
      "latitude": 35.803869,
      "longitude": -87.25914,
      "city": "Primm Springs",
      "state": "TN",
      "county": "Hickman"
  },
  {
      "zipCode": 38477,
      "latitude": 35.093795,
      "longitude": -87.000953,
      "city": "Prospect",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38478,
      "latitude": 35.217373,
      "longitude": -87.015664,
      "city": "Pulaski",
      "state": "TN",
      "county": "Giles"
  },
  {
      "zipCode": 38481,
      "latitude": 35.034741,
      "longitude": -87.49336,
      "city": "Saint Joseph",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38482,
      "latitude": 35.772652,
      "longitude": -87.146428,
      "city": "Santa Fe",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 38483,
      "latitude": 35.239335,
      "longitude": -87.399717,
      "city": "Summertown",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38485,
      "latitude": 35.345882,
      "longitude": -87.775459,
      "city": "Waynesboro",
      "state": "TN",
      "county": "Wayne"
  },
  {
      "zipCode": 38486,
      "latitude": 35.170415,
      "longitude": -87.402375,
      "city": "Westpoint",
      "state": "TN",
      "county": "Lawrence"
  },
  {
      "zipCode": 38487,
      "latitude": 35.722628,
      "longitude": -87.212698,
      "city": "Williamsport",
      "state": "TN",
      "county": "Maury"
  },
  {
      "zipCode": 38488,
      "latitude": 35.052581,
      "longitude": -86.717885,
      "city": "Taft",
      "state": "TN",
      "county": "Lincoln"
  },
  {
      "zipCode": 38501,
      "latitude": 36.217846,
      "longitude": -85.542327,
      "city": "Cookeville",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38502,
      "latitude": 36.141824,
      "longitude": -85.454779,
      "city": "Cookeville",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38503,
      "latitude": 36.141824,
      "longitude": -85.454779,
      "city": "Cookeville",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38504,
      "latitude": 36.381838,
      "longitude": -84.775579,
      "city": "Allardt",
      "state": "TN",
      "county": "Fentress"
  },
  {
      "zipCode": 38505,
      "latitude": 36.141824,
      "longitude": -85.454779,
      "city": "Cookeville",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38506,
      "latitude": 36.182723,
      "longitude": -85.469451,
      "city": "Cookeville",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38541,
      "latitude": 36.481086,
      "longitude": -85.346687,
      "city": "Allons",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38542,
      "latitude": 36.323952,
      "longitude": -85.203098,
      "city": "Allred",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38543,
      "latitude": 36.364735,
      "longitude": -85.189932,
      "city": "Alpine",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38544,
      "latitude": 36.118922,
      "longitude": -85.663021,
      "city": "Baxter",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38545,
      "latitude": 36.207497,
      "longitude": -85.664611,
      "city": "Bloomington Springs",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38547,
      "latitude": 36.166413,
      "longitude": -86.00636,
      "city": "Brush Creek",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 38548,
      "latitude": 36.163921,
      "longitude": -85.533995,
      "city": "Buffalo Valley",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38549,
      "latitude": 36.552982,
      "longitude": -85.162523,
      "city": "Byrdstown",
      "state": "TN",
      "county": "Pickett"
  },
  {
      "zipCode": 38550,
      "latitude": 35.773417,
      "longitude": -85.619169,
      "city": "Campaign",
      "state": "TN",
      "county": "Warren"
  },
  {
      "zipCode": 38551,
      "latitude": 36.547777,
      "longitude": -85.512924,
      "city": "Celina",
      "state": "TN",
      "county": "Clay"
  },
  {
      "zipCode": 38552,
      "latitude": 36.212422,
      "longitude": -85.825952,
      "city": "Chestnut Mound",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 38553,
      "latitude": 36.197616,
      "longitude": -85.011311,
      "city": "Clarkrange",
      "state": "TN",
      "county": "Fentress"
  },
  {
      "zipCode": 38554,
      "latitude": 36.244711,
      "longitude": -85.160197,
      "city": "Crawford",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38555,
      "latitude": 35.962348,
      "longitude": -85.051431,
      "city": "Crossville",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 38556,
      "latitude": 36.384844,
      "longitude": -84.935655,
      "city": "Jamestown",
      "state": "TN",
      "county": "Fentress"
  },
  {
      "zipCode": 38557,
      "latitude": 35.988457,
      "longitude": -85.012389,
      "city": "Crossville",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 38558,
      "latitude": 35.960491,
      "longitude": -85.014224,
      "city": "Crossville",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 38559,
      "latitude": 35.835572,
      "longitude": -85.519057,
      "city": "Doyle",
      "state": "TN",
      "county": "White"
  },
  {
      "zipCode": 38560,
      "latitude": 36.218759,
      "longitude": -85.862182,
      "city": "Elmwood",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 38562,
      "latitude": 36.369292,
      "longitude": -85.681124,
      "city": "Gainesboro",
      "state": "TN",
      "county": "Jackson"
  },
  {
      "zipCode": 38563,
      "latitude": 36.209988,
      "longitude": -85.982033,
      "city": "Gordonsville",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 38564,
      "latitude": 36.278335,
      "longitude": -85.748452,
      "city": "Granville",
      "state": "TN",
      "county": "Jackson"
  },
  {
      "zipCode": 38565,
      "latitude": 36.259849,
      "longitude": -85.00154,
      "city": "Grimsley",
      "state": "TN",
      "county": "Fentress"
  },
  {
      "zipCode": 38567,
      "latitude": 36.138347,
      "longitude": -85.914313,
      "city": "Hickman",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 38568,
      "latitude": 36.422652,
      "longitude": -85.446431,
      "city": "Hilham",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38569,
      "latitude": 36.124389,
      "longitude": -85.859146,
      "city": "Lancaster",
      "state": "TN",
      "county": "Smith"
  },
  {
      "zipCode": 38570,
      "latitude": 36.38519,
      "longitude": -85.329392,
      "city": "Livingston",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38571,
      "latitude": 36.051604,
      "longitude": -85.012804,
      "city": "Crossville",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 38572,
      "latitude": 35.849059,
      "longitude": -85.12896,
      "city": "Crossville",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 38573,
      "latitude": 36.452484,
      "longitude": -85.229094,
      "city": "Monroe",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38574,
      "latitude": 36.117716,
      "longitude": -85.234938,
      "city": "Monterey",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38575,
      "latitude": 36.552546,
      "longitude": -85.631181,
      "city": "Moss",
      "state": "TN",
      "county": "Clay"
  },
  {
      "zipCode": 38577,
      "latitude": 36.532956,
      "longitude": -84.951245,
      "city": "Pall Mall",
      "state": "TN",
      "county": "Fentress"
  },
  {
      "zipCode": 38578,
      "latitude": 36.007405,
      "longitude": -85.162272,
      "city": "Pleasant Hill",
      "state": "TN",
      "county": "Cumberland"
  },
  {
      "zipCode": 38579,
      "latitude": 35.836776,
      "longitude": -85.546347,
      "city": "Quebeck",
      "state": "TN",
      "county": "White"
  },
  {
      "zipCode": 38580,
      "latitude": 36.281225,
      "longitude": -85.304796,
      "city": "Rickman",
      "state": "TN",
      "county": "Overton"
  },
  {
      "zipCode": 38581,
      "latitude": 35.766758,
      "longitude": -85.678847,
      "city": "Rock Island",
      "state": "TN",
      "county": "Warren"
  },
  {
      "zipCode": 38582,
      "latitude": 36.107377,
      "longitude": -85.729463,
      "city": "Silver Point",
      "state": "TN",
      "county": "Putnam"
  },
  {
      "zipCode": 38583,
      "latitude": 35.943857,
      "longitude": -85.439246,
      "city": "Sparta",
      "state": "TN",
      "county": "White"
  },
  {
      "zipCode": 38585,
      "latitude": 35.677816,
      "longitude": -85.434355,
      "city": "Spencer",
      "state": "TN",
      "county": "Van Buren"
  },
  {
      "zipCode": 38587,
      "latitude": 35.866476,
      "longitude": -85.609235,
      "city": "Walling",
      "state": "TN",
      "county": "White"
  },
  {
      "zipCode": 38588,
      "latitude": 36.433214,
      "longitude": -85.707955,
      "city": "Whitleyville",
      "state": "TN",
      "county": "Jackson"
  },
  {
      "zipCode": 38589,
      "latitude": 36.282181,
      "longitude": -85.091312,
      "city": "Wilder",
      "state": "TN",
      "county": "Fentress"
  },
  {
      "zipCode": 38601,
      "latitude": 34.471941,
      "longitude": -89.447507,
      "city": "Abbeville",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38602,
      "latitude": 34.686272,
      "longitude": -90.106756,
      "city": "Arkabutla",
      "state": "MS",
      "county": "Tate"
  },
  {
      "zipCode": 38603,
      "latitude": 34.741457,
      "longitude": -89.163282,
      "city": "Ashland",
      "state": "MS",
      "county": "Benton"
  },
  {
      "zipCode": 38606,
      "latitude": 34.343102,
      "longitude": -89.956497,
      "city": "Batesville",
      "state": "MS",
      "county": "Panola"
  },
  {
      "zipCode": 38609,
      "latitude": 34.235111,
      "longitude": -90.29272,
      "city": "Belen",
      "state": "MS",
      "county": "Quitman"
  },
  {
      "zipCode": 38610,
      "latitude": 34.667999,
      "longitude": -88.99877,
      "city": "Blue Mountain",
      "state": "MS",
      "county": "Tippah"
  },
  {
      "zipCode": 38611,
      "latitude": 34.848108,
      "longitude": -89.617691,
      "city": "Byhalia",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38614,
      "latitude": 34.256003,
      "longitude": -90.634809,
      "city": "Clarksdale",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38617,
      "latitude": 34.308025,
      "longitude": -90.519949,
      "city": "Coahoma",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38618,
      "latitude": 34.69018,
      "longitude": -89.962513,
      "city": "Coldwater",
      "state": "MS",
      "county": "Tate"
  },
  {
      "zipCode": 38619,
      "latitude": 34.474028,
      "longitude": -89.954427,
      "city": "Como",
      "state": "MS",
      "county": "Panola"
  },
  {
      "zipCode": 38620,
      "latitude": 34.25082,
      "longitude": -89.962994,
      "city": "Courtland",
      "state": "MS",
      "county": "Panola"
  },
  {
      "zipCode": 38621,
      "latitude": 34.447051,
      "longitude": -90.101204,
      "city": "Crenshaw",
      "state": "MS",
      "county": "Panola"
  },
  {
      "zipCode": 38622,
      "latitude": 34.173057,
      "longitude": -90.136429,
      "city": "Crowder",
      "state": "MS",
      "county": "Quitman"
  },
  {
      "zipCode": 38623,
      "latitude": 34.291611,
      "longitude": -90.292717,
      "city": "Darling",
      "state": "MS",
      "county": "Quitman"
  },
  {
      "zipCode": 38625,
      "latitude": 34.641739,
      "longitude": -88.827123,
      "city": "Dumas",
      "state": "MS",
      "county": "Tippah"
  },
  {
      "zipCode": 38626,
      "latitude": 34.535704,
      "longitude": -90.369304,
      "city": "Dundee",
      "state": "MS",
      "county": "Tunica"
  },
  {
      "zipCode": 38627,
      "latitude": 34.432943,
      "longitude": -89.05727,
      "city": "Etta",
      "state": "MS",
      "county": "Union"
  },
  {
      "zipCode": 38628,
      "latitude": 34.291611,
      "longitude": -90.292717,
      "city": "Falcon",
      "state": "MS",
      "county": "Quitman"
  },
  {
      "zipCode": 38629,
      "latitude": 34.850879,
      "longitude": -88.933747,
      "city": "Falkner",
      "state": "MS",
      "county": "Tippah"
  },
  {
      "zipCode": 38630,
      "latitude": 34.242356,
      "longitude": -90.648864,
      "city": "Farrell",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38631,
      "latitude": 34.37406,
      "longitude": -90.606054,
      "city": "Friars Point",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38632,
      "latitude": 34.80684,
      "longitude": -89.994591,
      "city": "Hernando",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38633,
      "latitude": 34.623448,
      "longitude": -89.180301,
      "city": "Hickory Flat",
      "state": "MS",
      "county": "Benton"
  },
  {
      "zipCode": 38634,
      "latitude": 34.74504,
      "longitude": -89.484963,
      "city": "Holly Springs",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38635,
      "latitude": 34.774733,
      "longitude": -89.513878,
      "city": "Holly Springs",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38637,
      "latitude": 34.950609,
      "longitude": -90.03355,
      "city": "Horn Lake",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38638,
      "latitude": 34.695853,
      "longitude": -89.828798,
      "city": "Independence",
      "state": "MS",
      "county": "Tate"
  },
  {
      "zipCode": 38639,
      "latitude": 34.30373,
      "longitude": -90.429351,
      "city": "Jonestown",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38641,
      "latitude": 34.911835,
      "longitude": -90.14603,
      "city": "Lake Cormorant",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38642,
      "latitude": 34.930329,
      "longitude": -89.399923,
      "city": "Lamar",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38643,
      "latitude": 34.189235,
      "longitude": -90.215916,
      "city": "Lambert",
      "state": "MS",
      "county": "Quitman"
  },
  {
      "zipCode": 38644,
      "latitude": 34.461795,
      "longitude": -90.498088,
      "city": "Lula",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38645,
      "latitude": 34.236389,
      "longitude": -90.47941,
      "city": "Lyon",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38646,
      "latitude": 34.314968,
      "longitude": -90.214465,
      "city": "Marks",
      "state": "MS",
      "county": "Quitman"
  },
  {
      "zipCode": 38647,
      "latitude": 34.788948,
      "longitude": -89.185048,
      "city": "Michigan City",
      "state": "MS",
      "county": "Benton"
  },
  {
      "zipCode": 38649,
      "latitude": 34.953584,
      "longitude": -89.523027,
      "city": "Mount Pleasant",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38650,
      "latitude": 34.512992,
      "longitude": -89.133523,
      "city": "Myrtle",
      "state": "MS",
      "county": "Union"
  },
  {
      "zipCode": 38651,
      "latitude": 34.875133,
      "longitude": -89.985853,
      "city": "Nesbit",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38652,
      "latitude": 34.488692,
      "longitude": -88.963649,
      "city": "New Albany",
      "state": "MS",
      "county": "Union"
  },
  {
      "zipCode": 38654,
      "latitude": 34.921172,
      "longitude": -89.869062,
      "city": "Olive Branch",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38655,
      "latitude": 34.330814,
      "longitude": -89.483519,
      "city": "Oxford",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38658,
      "latitude": 34.196241,
      "longitude": -89.888559,
      "city": "Pope",
      "state": "MS",
      "county": "Panola"
  },
  {
      "zipCode": 38659,
      "latitude": 34.62921,
      "longitude": -89.366355,
      "city": "Potts Camp",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38661,
      "latitude": 34.893744,
      "longitude": -89.564928,
      "city": "Red Banks",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38663,
      "latitude": 34.722961,
      "longitude": -88.903624,
      "city": "Ripley",
      "state": "MS",
      "county": "Tippah"
  },
  {
      "zipCode": 38664,
      "latitude": 34.780008,
      "longitude": -90.332582,
      "city": "Robinsonville",
      "state": "MS",
      "county": "Tunica"
  },
  {
      "zipCode": 38665,
      "latitude": 34.414146,
      "longitude": -90.039377,
      "city": "Sarah",
      "state": "MS",
      "county": "Panola"
  },
  {
      "zipCode": 38666,
      "latitude": 34.358883,
      "longitude": -89.930441,
      "city": "Sardis",
      "state": "MS",
      "county": "Panola"
  },
  {
      "zipCode": 38668,
      "latitude": 34.621336,
      "longitude": -89.930083,
      "city": "Senatobia",
      "state": "MS",
      "county": "Tate"
  },
  {
      "zipCode": 38669,
      "latitude": 34.195345,
      "longitude": -90.733538,
      "city": "Sherard",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38670,
      "latitude": 34.561069,
      "longitude": -90.330278,
      "city": "Sledge",
      "state": "MS",
      "county": "Tunica"
  },
  {
      "zipCode": 38671,
      "latitude": 34.956633,
      "longitude": -89.99624,
      "city": "Southaven",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38672,
      "latitude": 34.947407,
      "longitude": -89.925792,
      "city": "Southaven",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38673,
      "latitude": 34.291869,
      "longitude": -89.611016,
      "city": "Taylor",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38674,
      "latitude": 34.877104,
      "longitude": -88.900175,
      "city": "Tiplersville",
      "state": "MS",
      "county": "Tippah"
  },
  {
      "zipCode": 38675,
      "latitude": 34.358201,
      "longitude": -89.483865,
      "city": "Tula",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38676,
      "latitude": 34.692892,
      "longitude": -90.338981,
      "city": "Tunica",
      "state": "MS",
      "county": "Tunica"
  },
  {
      "zipCode": 38677,
      "latitude": 34.33962,
      "longitude": -89.573605,
      "city": "University",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38679,
      "latitude": 34.870804,
      "longitude": -89.64082,
      "city": "Victoria",
      "state": "MS",
      "county": "Marshall"
  },
  {
      "zipCode": 38680,
      "latitude": 34.960712,
      "longitude": -90.113913,
      "city": "Walls",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38683,
      "latitude": 34.89462,
      "longitude": -88.885227,
      "city": "Walnut",
      "state": "MS",
      "county": "Tippah"
  },
  {
      "zipCode": 38685,
      "latitude": 34.547621,
      "longitude": -89.612918,
      "city": "Waterford",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38686,
      "latitude": 34.875277,
      "longitude": -89.991991,
      "city": "Walls",
      "state": "MS",
      "county": "De Soto"
  },
  {
      "zipCode": 38701,
      "latitude": 33.314539,
      "longitude": -91.02297,
      "city": "Greenville",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38702,
      "latitude": 33.425792,
      "longitude": -90.994597,
      "city": "Greenville",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38703,
      "latitude": 33.414186,
      "longitude": -91.009518,
      "city": "Greenville",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38704,
      "latitude": 33.253401,
      "longitude": -90.918503,
      "city": "Greenville",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38720,
      "latitude": 34.074216,
      "longitude": -90.75974,
      "city": "Alligator",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38721,
      "latitude": 32.973917,
      "longitude": -90.831601,
      "city": "Anguilla",
      "state": "MS",
      "county": "Sharkey"
  },
  {
      "zipCode": 38722,
      "latitude": 33.26597,
      "longitude": -90.844813,
      "city": "Arcola",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38723,
      "latitude": 33.218997,
      "longitude": -91.047905,
      "city": "Avon",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38725,
      "latitude": 33.627816,
      "longitude": -90.994878,
      "city": "Benoit",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38726,
      "latitude": 33.75291,
      "longitude": -90.958277,
      "city": "Beulah",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38730,
      "latitude": 33.741709,
      "longitude": -90.773978,
      "city": "Boyle",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38731,
      "latitude": 33.085379,
      "longitude": -91.088255,
      "city": "Chatham",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38732,
      "latitude": 33.847591,
      "longitude": -90.84313,
      "city": "Cleveland",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38733,
      "latitude": 33.624024,
      "longitude": -90.819547,
      "city": "Cleveland",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38736,
      "latitude": 33.690173,
      "longitude": -90.549626,
      "city": "Doddsville",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38737,
      "latitude": 33.685766,
      "longitude": -90.607299,
      "city": "Drew",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38738,
      "latitude": 33.628715,
      "longitude": -90.607457,
      "city": "Parchman",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38739,
      "latitude": 34.060359,
      "longitude": -90.501063,
      "city": "Dublin",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38740,
      "latitude": 33.94809,
      "longitude": -90.770386,
      "city": "Duncan",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38744,
      "latitude": 33.045837,
      "longitude": -91.019421,
      "city": "Glen Allan",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38745,
      "latitude": 32.711257,
      "longitude": -90.952716,
      "city": "Grace",
      "state": "MS",
      "county": "Issaquena"
  },
  {
      "zipCode": 38746,
      "latitude": 34.000588,
      "longitude": -90.919616,
      "city": "Gunnison",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38748,
      "latitude": 33.230409,
      "longitude": -90.910451,
      "city": "Hollandale",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38749,
      "latitude": 33.628715,
      "longitude": -90.607457,
      "city": "Holly Ridge",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38751,
      "latitude": 33.443446,
      "longitude": -90.608662,
      "city": "Indianola",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38753,
      "latitude": 33.350655,
      "longitude": -90.583441,
      "city": "Inverness",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38754,
      "latitude": 33.263048,
      "longitude": -90.59677,
      "city": "Isola",
      "state": "MS",
      "county": "Humphreys"
  },
  {
      "zipCode": 38756,
      "latitude": 33.398505,
      "longitude": -90.88387,
      "city": "Leland",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38758,
      "latitude": 34.076795,
      "longitude": -90.507012,
      "city": "Mattson",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38759,
      "latitude": 33.818742,
      "longitude": -90.840631,
      "city": "Merigold",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38760,
      "latitude": 33.454614,
      "longitude": -90.994264,
      "city": "Metcalfe",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38761,
      "latitude": 33.443386,
      "longitude": -90.506817,
      "city": "Moorhead",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38762,
      "latitude": 33.767126,
      "longitude": -90.846978,
      "city": "Mound Bayou",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38763,
      "latitude": 32.879795,
      "longitude": -90.81096,
      "city": "Nitta Yuma",
      "state": "MS",
      "county": "Sharkey"
  },
  {
      "zipCode": 38764,
      "latitude": 33.725717,
      "longitude": -90.92728,
      "city": "Pace",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38765,
      "latitude": 32.879795,
      "longitude": -90.81096,
      "city": "Panther Burn",
      "state": "MS",
      "county": "Sharkey"
  },
  {
      "zipCode": 38767,
      "latitude": 34.1412,
      "longitude": -90.7865,
      "city": "Rena Lara",
      "state": "MS",
      "county": "Coahoma"
  },
  {
      "zipCode": 38768,
      "latitude": 33.958959,
      "longitude": -90.477605,
      "city": "Rome",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38769,
      "latitude": 33.841892,
      "longitude": -90.882598,
      "city": "Rosedale",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38771,
      "latitude": 33.66479,
      "longitude": -90.555537,
      "city": "Ruleville",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38772,
      "latitude": 33.562871,
      "longitude": -91.039093,
      "city": "Scott",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38773,
      "latitude": 33.608538,
      "longitude": -90.794341,
      "city": "Shaw",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38774,
      "latitude": 33.957757,
      "longitude": -90.74504,
      "city": "Shelby",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38776,
      "latitude": 33.409614,
      "longitude": -90.910404,
      "city": "Stoneville",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38778,
      "latitude": 33.556746,
      "longitude": -90.545111,
      "city": "Sunflower",
      "state": "MS",
      "county": "Sunflower"
  },
  {
      "zipCode": 38780,
      "latitude": 33.272695,
      "longitude": -90.995399,
      "city": "Wayside",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38781,
      "latitude": 33.887774,
      "longitude": -90.737149,
      "city": "Winstonville",
      "state": "MS",
      "county": "Bolivar"
  },
  {
      "zipCode": 38782,
      "latitude": 33.269118,
      "longitude": -90.957291,
      "city": "Winterville",
      "state": "MS",
      "county": "Washington"
  },
  {
      "zipCode": 38801,
      "latitude": 34.291889,
      "longitude": -88.683828,
      "city": "Tupelo",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38802,
      "latitude": 34.234527,
      "longitude": -88.767127,
      "city": "Tupelo",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38803,
      "latitude": 34.187638,
      "longitude": -88.77852,
      "city": "Tupelo",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38804,
      "latitude": 34.3371,
      "longitude": -88.684672,
      "city": "Tupelo",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38820,
      "latitude": 34.171543,
      "longitude": -89.032791,
      "city": "Algoma",
      "state": "MS",
      "county": "Pontotoc"
  },
  {
      "zipCode": 38821,
      "latitude": 33.942639,
      "longitude": -88.411284,
      "city": "Amory",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 38824,
      "latitude": 34.314813,
      "longitude": -88.63922,
      "city": "Baldwyn",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38825,
      "latitude": 33.869763,
      "longitude": -88.462173,
      "city": "Becker",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 38826,
      "latitude": 34.269844,
      "longitude": -88.745933,
      "city": "Belden",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38827,
      "latitude": 34.550663,
      "longitude": -88.22674,
      "city": "Belmont",
      "state": "MS",
      "county": "Tishomingo"
  },
  {
      "zipCode": 38828,
      "latitude": 34.464253,
      "longitude": -88.958608,
      "city": "Blue Springs",
      "state": "MS",
      "county": "Union"
  },
  {
      "zipCode": 38829,
      "latitude": 34.632922,
      "longitude": -88.533107,
      "city": "Booneville",
      "state": "MS",
      "county": "Prentiss"
  },
  {
      "zipCode": 38833,
      "latitude": 34.855985,
      "longitude": -88.286923,
      "city": "Burnsville",
      "state": "MS",
      "county": "Tishomingo"
  },
  {
      "zipCode": 38834,
      "latitude": 34.87589,
      "longitude": -88.591575,
      "city": "Corinth",
      "state": "MS",
      "county": "Alcorn"
  },
  {
      "zipCode": 38835,
      "latitude": 34.920451,
      "longitude": -88.521772,
      "city": "Corinth",
      "state": "MS",
      "county": "Alcorn"
  },
  {
      "zipCode": 38838,
      "latitude": 34.584628,
      "longitude": -88.248391,
      "city": "Dennis",
      "state": "MS",
      "county": "Tishomingo"
  },
  {
      "zipCode": 38839,
      "latitude": 33.856165,
      "longitude": -89.303214,
      "city": "Derma",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38841,
      "latitude": 34.336606,
      "longitude": -89.010873,
      "city": "Ecru",
      "state": "MS",
      "county": "Pontotoc"
  },
  {
      "zipCode": 38843,
      "latitude": 34.276489,
      "longitude": -88.355013,
      "city": "Fulton",
      "state": "MS",
      "county": "Itawamba"
  },
  {
      "zipCode": 38844,
      "latitude": 33.84026,
      "longitude": -88.2635,
      "city": "Gattman",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 38846,
      "latitude": 34.868523,
      "longitude": -88.41982,
      "city": "Glen",
      "state": "MS",
      "county": "Alcorn"
  },
  {
      "zipCode": 38847,
      "latitude": 34.346321,
      "longitude": -88.246027,
      "city": "Golden",
      "state": "MS",
      "county": "Itawamba"
  },
  {
      "zipCode": 38848,
      "latitude": 33.940078,
      "longitude": -88.320711,
      "city": "Greenwood Springs",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 38849,
      "latitude": 34.421084,
      "longitude": -88.665975,
      "city": "Guntown",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38850,
      "latitude": 34.013571,
      "longitude": -88.996107,
      "city": "Houlka",
      "state": "MS",
      "county": "Chickasaw"
  },
  {
      "zipCode": 38851,
      "latitude": 33.911901,
      "longitude": -88.931148,
      "city": "Houston",
      "state": "MS",
      "county": "Chickasaw"
  },
  {
      "zipCode": 38852,
      "latitude": 34.81583,
      "longitude": -88.2318,
      "city": "Iuka",
      "state": "MS",
      "county": "Tishomingo"
  },
  {
      "zipCode": 38854,
      "latitude": 33.906827,
      "longitude": -88.955351,
      "city": "Mc Condy",
      "state": "MS",
      "county": "Chickasaw"
  },
  {
      "zipCode": 38855,
      "latitude": 34.324376,
      "longitude": -88.393184,
      "city": "Mantachie",
      "state": "MS",
      "county": "Itawamba"
  },
  {
      "zipCode": 38856,
      "latitude": 34.592786,
      "longitude": -88.510356,
      "city": "Marietta",
      "state": "MS",
      "county": "Prentiss"
  },
  {
      "zipCode": 38857,
      "latitude": 34.275006,
      "longitude": -88.592851,
      "city": "Mooreville",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38858,
      "latitude": 34.161842,
      "longitude": -88.472146,
      "city": "Nettleton",
      "state": "MS",
      "county": "Itawamba"
  },
  {
      "zipCode": 38859,
      "latitude": 34.531462,
      "longitude": -88.387511,
      "city": "New Site",
      "state": "MS",
      "county": "Prentiss"
  },
  {
      "zipCode": 38860,
      "latitude": 33.94295,
      "longitude": -88.868022,
      "city": "Okolona",
      "state": "MS",
      "county": "Chickasaw"
  },
  {
      "zipCode": 38862,
      "latitude": 34.239541,
      "longitude": -88.617454,
      "city": "Plantersville",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38863,
      "latitude": 34.227246,
      "longitude": -89.040537,
      "city": "Pontotoc",
      "state": "MS",
      "county": "Pontotoc"
  },
  {
      "zipCode": 38864,
      "latitude": 34.161748,
      "longitude": -89.179467,
      "city": "Randolph",
      "state": "MS",
      "county": "Pontotoc"
  },
  {
      "zipCode": 38865,
      "latitude": 34.818882,
      "longitude": -88.56325,
      "city": "Rienzi",
      "state": "MS",
      "county": "Alcorn"
  },
  {
      "zipCode": 38866,
      "latitude": 34.304376,
      "longitude": -88.667209,
      "city": "Saltillo",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38868,
      "latitude": 34.144146,
      "longitude": -88.68396,
      "city": "Shannon",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38869,
      "latitude": 34.349542,
      "longitude": -88.836268,
      "city": "Sherman",
      "state": "MS",
      "county": "Pontotoc"
  },
  {
      "zipCode": 38870,
      "latitude": 34.045269,
      "longitude": -88.365609,
      "city": "Smithville",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 38871,
      "latitude": 34.317365,
      "longitude": -89.159059,
      "city": "Thaxton",
      "state": "MS",
      "county": "Pontotoc"
  },
  {
      "zipCode": 38873,
      "latitude": 34.671205,
      "longitude": -88.238068,
      "city": "Tishomingo",
      "state": "MS",
      "county": "Tishomingo"
  },
  {
      "zipCode": 38874,
      "latitude": 34.226527,
      "longitude": -89.251922,
      "city": "Toccopola",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38875,
      "latitude": 33.906827,
      "longitude": -88.955351,
      "city": "Trebloc",
      "state": "MS",
      "county": "Chickasaw"
  },
  {
      "zipCode": 38876,
      "latitude": 34.235149,
      "longitude": -88.333454,
      "city": "Tremont",
      "state": "MS",
      "county": "Itawamba"
  },
  {
      "zipCode": 38877,
      "latitude": 33.987089,
      "longitude": -88.898807,
      "city": "Van Vleet",
      "state": "MS",
      "county": "Chickasaw"
  },
  {
      "zipCode": 38878,
      "latitude": 33.896961,
      "longitude": -89.221899,
      "city": "Vardaman",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38879,
      "latitude": 34.170691,
      "longitude": -88.759077,
      "city": "Verona",
      "state": "MS",
      "county": "Lee"
  },
  {
      "zipCode": 38880,
      "latitude": 34.612636,
      "longitude": -88.582231,
      "city": "Wheeler",
      "state": "MS",
      "county": "Prentiss"
  },
  {
      "zipCode": 38901,
      "latitude": 33.786016,
      "longitude": -89.845462,
      "city": "Grenada",
      "state": "MS",
      "county": "Grenada"
  },
  {
      "zipCode": 38902,
      "latitude": 33.824113,
      "longitude": -89.794687,
      "city": "Grenada",
      "state": "MS",
      "county": "Grenada"
  },
  {
      "zipCode": 38912,
      "latitude": 33.65497,
      "longitude": -90.053958,
      "city": "Avalon",
      "state": "MS",
      "county": "Carroll"
  },
  {
      "zipCode": 38913,
      "latitude": 34.12266,
      "longitude": -89.395779,
      "city": "Banner",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38914,
      "latitude": 33.898213,
      "longitude": -89.431864,
      "city": "Big Creek",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38915,
      "latitude": 34.034636,
      "longitude": -89.342661,
      "city": "Bruce",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38916,
      "latitude": 33.857172,
      "longitude": -89.352482,
      "city": "Calhoun City",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38917,
      "latitude": 33.518425,
      "longitude": -89.995978,
      "city": "Carrollton",
      "state": "MS",
      "county": "Carroll"
  },
  {
      "zipCode": 38920,
      "latitude": 33.929206,
      "longitude": -90.188462,
      "city": "Cascilla",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38921,
      "latitude": 34.007428,
      "longitude": -90.115364,
      "city": "Charleston",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38922,
      "latitude": 33.961287,
      "longitude": -89.64824,
      "city": "Coffeeville",
      "state": "MS",
      "county": "Yalobusha"
  },
  {
      "zipCode": 38923,
      "latitude": 33.445684,
      "longitude": -89.910076,
      "city": "Coila",
      "state": "MS",
      "county": "Carroll"
  },
  {
      "zipCode": 38924,
      "latitude": 33.255582,
      "longitude": -90.183344,
      "city": "Cruger",
      "state": "MS",
      "county": "Holmes"
  },
  {
      "zipCode": 38925,
      "latitude": 33.563223,
      "longitude": -89.641871,
      "city": "Duck Hill",
      "state": "MS",
      "county": "Montgomery"
  },
  {
      "zipCode": 38926,
      "latitude": 33.689476,
      "longitude": -89.758302,
      "city": "Elliott",
      "state": "MS",
      "county": "Grenada"
  },
  {
      "zipCode": 38927,
      "latitude": 34.125054,
      "longitude": -90.013183,
      "city": "Enid",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38928,
      "latitude": 33.929206,
      "longitude": -90.188462,
      "city": "Glendora",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38929,
      "latitude": 33.78764,
      "longitude": -89.647197,
      "city": "Gore Springs",
      "state": "MS",
      "county": "Grenada"
  },
  {
      "zipCode": 38930,
      "latitude": 33.550408,
      "longitude": -90.224856,
      "city": "Greenwood",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38935,
      "latitude": 33.523355,
      "longitude": -90.27757,
      "city": "Greenwood",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38940,
      "latitude": 33.742966,
      "longitude": -90.000461,
      "city": "Holcomb",
      "state": "MS",
      "county": "Grenada"
  },
  {
      "zipCode": 38941,
      "latitude": 33.467852,
      "longitude": -90.294639,
      "city": "Itta Bena",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38943,
      "latitude": 33.489564,
      "longitude": -89.834467,
      "city": "Mc Carley",
      "state": "MS",
      "county": "Carroll"
  },
  {
      "zipCode": 38944,
      "latitude": 33.752133,
      "longitude": -90.374127,
      "city": "Minter City",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38945,
      "latitude": 33.650681,
      "longitude": -90.196909,
      "city": "Money",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38946,
      "latitude": 33.404573,
      "longitude": -90.371993,
      "city": "Morgan City",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38947,
      "latitude": 33.512759,
      "longitude": -89.920125,
      "city": "North Carrollton",
      "state": "MS",
      "county": "Carroll"
  },
  {
      "zipCode": 38948,
      "latitude": 34.102656,
      "longitude": -89.855317,
      "city": "Oakland",
      "state": "MS",
      "county": "Yalobusha"
  },
  {
      "zipCode": 38949,
      "latitude": 34.199242,
      "longitude": -89.381994,
      "city": "Paris",
      "state": "MS",
      "county": "Lafayette"
  },
  {
      "zipCode": 38950,
      "latitude": 33.733437,
      "longitude": -90.237053,
      "city": "Philipp",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38951,
      "latitude": 33.942942,
      "longitude": -89.283006,
      "city": "Pittsboro",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38952,
      "latitude": 33.627351,
      "longitude": -90.301026,
      "city": "Schlater",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38953,
      "latitude": 34.029737,
      "longitude": -89.719526,
      "city": "Scobey",
      "state": "MS",
      "county": "Yalobusha"
  },
  {
      "zipCode": 38954,
      "latitude": 33.445684,
      "longitude": -89.910076,
      "city": "Sidon",
      "state": "MS",
      "county": "Carroll"
  },
  {
      "zipCode": 38955,
      "latitude": 33.743871,
      "longitude": -89.375281,
      "city": "Slate Spring",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 38957,
      "latitude": 33.983323,
      "longitude": -90.389521,
      "city": "Sumner",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38958,
      "latitude": 33.929206,
      "longitude": -90.188462,
      "city": "Swan Lake",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38959,
      "latitude": 33.380913,
      "longitude": -90.349601,
      "city": "Swiftown",
      "state": "MS",
      "county": "Leflore"
  },
  {
      "zipCode": 38960,
      "latitude": 33.787157,
      "longitude": -89.821941,
      "city": "Tie Plant",
      "state": "MS",
      "county": "Grenada"
  },
  {
      "zipCode": 38961,
      "latitude": 34.029737,
      "longitude": -89.719526,
      "city": "Tillatoba",
      "state": "MS",
      "county": "Yalobusha"
  },
  {
      "zipCode": 38962,
      "latitude": 33.929206,
      "longitude": -90.188462,
      "city": "Tippo",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38963,
      "latitude": 34.022051,
      "longitude": -90.349894,
      "city": "Tutwiler",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38964,
      "latitude": 34.09953,
      "longitude": -90.443027,
      "city": "Vance",
      "state": "MS",
      "county": "Quitman"
  },
  {
      "zipCode": 38965,
      "latitude": 34.101631,
      "longitude": -89.633458,
      "city": "Water Valley",
      "state": "MS",
      "county": "Yalobusha"
  },
  {
      "zipCode": 38966,
      "latitude": 33.898064,
      "longitude": -90.335055,
      "city": "Webb",
      "state": "MS",
      "county": "Tallahatchie"
  },
  {
      "zipCode": 38967,
      "latitude": 33.514926,
      "longitude": -89.628584,
      "city": "Winona",
      "state": "MS",
      "county": "Montgomery"
  },
  {
      "zipCode": 39038,
      "latitude": 33.170596,
      "longitude": -90.475786,
      "city": "Belzoni",
      "state": "MS",
      "county": "Humphreys"
  },
  {
      "zipCode": 39039,
      "latitude": 32.845368,
      "longitude": -90.241776,
      "city": "Benton",
      "state": "MS",
      "county": "Yazoo"
  },
  {
      "zipCode": 39040,
      "latitude": 32.645794,
      "longitude": -90.423646,
      "city": "Bentonia",
      "state": "MS",
      "county": "Yazoo"
  },
  {
      "zipCode": 39041,
      "latitude": 32.406956,
      "longitude": -90.46141,
      "city": "Bolton",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39042,
      "latitude": 32.300019,
      "longitude": -89.938693,
      "city": "Brandon",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39043,
      "latitude": 32.269041,
      "longitude": -89.987491,
      "city": "Brandon",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39044,
      "latitude": 31.96499,
      "longitude": -89.981393,
      "city": "Braxton",
      "state": "MS",
      "county": "Simpson"
  },
  {
      "zipCode": 39045,
      "latitude": 32.785348,
      "longitude": -89.869807,
      "city": "Camden",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39046,
      "latitude": 32.625298,
      "longitude": -90.008601,
      "city": "Canton",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39047,
      "latitude": 32.388633,
      "longitude": -89.958406,
      "city": "Brandon",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39051,
      "latitude": 32.785217,
      "longitude": -89.524057,
      "city": "Carthage",
      "state": "MS",
      "county": "Leake"
  },
  {
      "zipCode": 39054,
      "latitude": 32.812875,
      "longitude": -90.927522,
      "city": "Cary",
      "state": "MS",
      "county": "Sharkey"
  },
  {
      "zipCode": 39056,
      "latitude": 32.370736,
      "longitude": -90.312371,
      "city": "Clinton",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39057,
      "latitude": 32.477116,
      "longitude": -89.26359,
      "city": "Conehatta",
      "state": "MS",
      "county": "Newton"
  },
  {
      "zipCode": 39058,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Clinton",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39059,
      "latitude": 31.965088,
      "longitude": -90.375733,
      "city": "Crystal Springs",
      "state": "MS",
      "county": "Copiah"
  },
  {
      "zipCode": 39060,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Clinton",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39061,
      "latitude": 32.879795,
      "longitude": -90.81096,
      "city": "Delta City",
      "state": "MS",
      "county": "Sharkey"
  },
  {
      "zipCode": 39062,
      "latitude": 31.986101,
      "longitude": -89.919219,
      "city": "D Lo",
      "state": "MS",
      "county": "Simpson"
  },
  {
      "zipCode": 39063,
      "latitude": 33.071531,
      "longitude": -89.899593,
      "city": "Durant",
      "state": "MS",
      "county": "Holmes"
  },
  {
      "zipCode": 39066,
      "latitude": 32.27307,
      "longitude": -90.50985,
      "city": "Edwards",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39067,
      "latitude": 33.08167,
      "longitude": -89.541348,
      "city": "Ethel",
      "state": "MS",
      "county": "Attala"
  },
  {
      "zipCode": 39069,
      "latitude": 31.731988,
      "longitude": -91.056503,
      "city": "Fayette",
      "state": "MS",
      "county": "Jefferson"
  },
  {
      "zipCode": 39071,
      "latitude": 32.553269,
      "longitude": -90.326653,
      "city": "Flora",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39072,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Pocahontas",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39073,
      "latitude": 32.280972,
      "longitude": -90.003854,
      "city": "Florence",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39074,
      "latitude": 32.399853,
      "longitude": -89.505298,
      "city": "Forest",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39077,
      "latitude": 31.928751,
      "longitude": -90.392603,
      "city": "Gallman",
      "state": "MS",
      "county": "Copiah"
  },
  {
      "zipCode": 39078,
      "latitude": 31.87408,
      "longitude": -90.206289,
      "city": "Georgetown",
      "state": "MS",
      "county": "Copiah"
  },
  {
      "zipCode": 39079,
      "latitude": 32.989226,
      "longitude": -89.943323,
      "city": "Goodman",
      "state": "MS",
      "county": "Holmes"
  },
  {
      "zipCode": 39080,
      "latitude": 32.492533,
      "longitude": -89.494585,
      "city": "Harperville",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39081,
      "latitude": 31.739958,
      "longitude": -91.050948,
      "city": "Harriston",
      "state": "MS",
      "county": "Jefferson"
  },
  {
      "zipCode": 39082,
      "latitude": 31.94173,
      "longitude": -90.133459,
      "city": "Harrisville",
      "state": "MS",
      "county": "Simpson"
  },
  {
      "zipCode": 39083,
      "latitude": 31.868475,
      "longitude": -90.429426,
      "city": "Hazlehurst",
      "state": "MS",
      "county": "Copiah"
  },
  {
      "zipCode": 39086,
      "latitude": 31.997408,
      "longitude": -90.716602,
      "city": "Hermanville",
      "state": "MS",
      "county": "Claiborne"
  },
  {
      "zipCode": 39087,
      "latitude": 32.470606,
      "longitude": -89.517069,
      "city": "Hillsboro",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39088,
      "latitude": 32.841523,
      "longitude": -90.723119,
      "city": "Holly Bluff",
      "state": "MS",
      "county": "Yazoo"
  },
  {
      "zipCode": 39090,
      "latitude": 33.055142,
      "longitude": -89.5913,
      "city": "Kosciusko",
      "state": "MS",
      "county": "Attala"
  },
  {
      "zipCode": 39092,
      "latitude": 32.384482,
      "longitude": -89.415089,
      "city": "Lake",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39094,
      "latitude": 32.620305,
      "longitude": -89.614259,
      "city": "Lena",
      "state": "MS",
      "county": "Leake"
  },
  {
      "zipCode": 39095,
      "latitude": 33.126082,
      "longitude": -90.057116,
      "city": "Lexington",
      "state": "MS",
      "county": "Holmes"
  },
  {
      "zipCode": 39096,
      "latitude": 31.739958,
      "longitude": -91.050948,
      "city": "Lorman",
      "state": "MS",
      "county": "Jefferson"
  },
  {
      "zipCode": 39097,
      "latitude": 32.980758,
      "longitude": -90.592812,
      "city": "Louise",
      "state": "MS",
      "county": "Humphreys"
  },
  {
      "zipCode": 39098,
      "latitude": 32.566039,
      "longitude": -89.713545,
      "city": "Ludlow",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39107,
      "latitude": 33.082754,
      "longitude": -89.641184,
      "city": "Mc Adams",
      "state": "MS",
      "county": "Attala"
  },
  {
      "zipCode": 39108,
      "latitude": 33.082754,
      "longitude": -89.641184,
      "city": "Mc Cool",
      "state": "MS",
      "county": "Attala"
  },
  {
      "zipCode": 39109,
      "latitude": 32.690864,
      "longitude": -89.355388,
      "city": "Madden",
      "state": "MS",
      "county": "Leake"
  },
  {
      "zipCode": 39110,
      "latitude": 32.553769,
      "longitude": -90.138906,
      "city": "Madison",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39111,
      "latitude": 31.910321,
      "longitude": -89.775032,
      "city": "Magee",
      "state": "MS",
      "county": "Simpson"
  },
  {
      "zipCode": 39112,
      "latitude": 31.896236,
      "longitude": -89.780694,
      "city": "Sanatorium",
      "state": "MS",
      "county": "Simpson"
  },
  {
      "zipCode": 39113,
      "latitude": 32.711257,
      "longitude": -90.952716,
      "city": "Mayersville",
      "state": "MS",
      "county": "Issaquena"
  },
  {
      "zipCode": 39114,
      "latitude": 31.907972,
      "longitude": -89.813907,
      "city": "Mendenhall",
      "state": "MS",
      "county": "Simpson"
  },
  {
      "zipCode": 39115,
      "latitude": 33.125998,
      "longitude": -90.531759,
      "city": "Midnight",
      "state": "MS",
      "county": "Humphreys"
  },
  {
      "zipCode": 39116,
      "latitude": 31.852342,
      "longitude": -89.50805,
      "city": "Mize",
      "state": "MS",
      "county": "Smith"
  },
  {
      "zipCode": 39117,
      "latitude": 32.402897,
      "longitude": -89.594886,
      "city": "Morton",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39119,
      "latitude": 31.717905,
      "longitude": -89.623543,
      "city": "Mount Olive",
      "state": "MS",
      "county": "Covington"
  },
  {
      "zipCode": 39120,
      "latitude": 31.527752,
      "longitude": -91.307025,
      "city": "Natchez",
      "state": "MS",
      "county": "Adams"
  },
  {
      "zipCode": 39121,
      "latitude": 31.470602,
      "longitude": -91.404404,
      "city": "Natchez",
      "state": "MS",
      "county": "Adams"
  },
  {
      "zipCode": 39122,
      "latitude": 31.470602,
      "longitude": -91.404404,
      "city": "Natchez",
      "state": "MS",
      "county": "Adams"
  },
  {
      "zipCode": 39130,
      "latitude": 32.642448,
      "longitude": -90.090683,
      "city": "Madison",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39140,
      "latitude": 31.71093,
      "longitude": -90.038237,
      "city": "Newhebron",
      "state": "MS",
      "county": "Lawrence"
  },
  {
      "zipCode": 39144,
      "latitude": 32.005479,
      "longitude": -90.981274,
      "city": "Pattison",
      "state": "MS",
      "county": "Claiborne"
  },
  {
      "zipCode": 39145,
      "latitude": 32.33857,
      "longitude": -89.833228,
      "city": "Pelahatchie",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39146,
      "latitude": 32.950379,
      "longitude": -90.02631,
      "city": "Pickens",
      "state": "MS",
      "county": "Holmes"
  },
  {
      "zipCode": 39148,
      "latitude": 32.319905,
      "longitude": -89.992257,
      "city": "Piney Woods",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39149,
      "latitude": 31.837604,
      "longitude": -90.016517,
      "city": "Pinola",
      "state": "MS",
      "county": "Simpson"
  },
  {
      "zipCode": 39150,
      "latitude": 31.916949,
      "longitude": -90.975632,
      "city": "Port Gibson",
      "state": "MS",
      "county": "Claiborne"
  },
  {
      "zipCode": 39151,
      "latitude": 32.106173,
      "longitude": -89.783633,
      "city": "Puckett",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39152,
      "latitude": 32.274536,
      "longitude": -89.562728,
      "city": "Pulaski",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39153,
      "latitude": 32.033656,
      "longitude": -89.524458,
      "city": "Raleigh",
      "state": "MS",
      "county": "Smith"
  },
  {
      "zipCode": 39154,
      "latitude": 32.196072,
      "longitude": -90.47528,
      "city": "Raymond",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39156,
      "latitude": 32.52792,
      "longitude": -90.754175,
      "city": "Redwood",
      "state": "MS",
      "county": "Warren"
  },
  {
      "zipCode": 39157,
      "latitude": 32.436064,
      "longitude": -90.13731,
      "city": "Ridgeland",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39158,
      "latitude": 32.642448,
      "longitude": -90.090683,
      "city": "Ridgeland",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39159,
      "latitude": 32.859106,
      "longitude": -90.897658,
      "city": "Rolling Fork",
      "state": "MS",
      "county": "Sharkey"
  },
  {
      "zipCode": 39160,
      "latitude": 33.082754,
      "longitude": -89.641184,
      "city": "Sallis",
      "state": "MS",
      "county": "Attala"
  },
  {
      "zipCode": 39161,
      "latitude": 32.498294,
      "longitude": -89.868563,
      "city": "Sandhill",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39162,
      "latitude": 32.640397,
      "longitude": -90.595701,
      "city": "Satartia",
      "state": "MS",
      "county": "Yazoo"
  },
  {
      "zipCode": 39163,
      "latitude": 32.642448,
      "longitude": -90.090683,
      "city": "Sharon",
      "state": "MS",
      "county": "Madison"
  },
  {
      "zipCode": 39165,
      "latitude": 31.470602,
      "longitude": -91.404404,
      "city": "Sibley",
      "state": "MS",
      "county": "Adams"
  },
  {
      "zipCode": 39166,
      "latitude": 33.094729,
      "longitude": -90.490483,
      "city": "Silver City",
      "state": "MS",
      "county": "Humphreys"
  },
  {
      "zipCode": 39167,
      "latitude": 32.096124,
      "longitude": -90.061508,
      "city": "Star",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39168,
      "latitude": 31.82958,
      "longitude": -89.403302,
      "city": "Taylorsville",
      "state": "MS",
      "county": "Smith"
  },
  {
      "zipCode": 39169,
      "latitude": 33.134414,
      "longitude": -90.237888,
      "city": "Tchula",
      "state": "MS",
      "county": "Holmes"
  },
  {
      "zipCode": 39170,
      "latitude": 32.189393,
      "longitude": -90.422369,
      "city": "Terry",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39171,
      "latitude": 32.753923,
      "longitude": -89.52414,
      "city": "Thomastown",
      "state": "MS",
      "county": "Leake"
  },
  {
      "zipCode": 39173,
      "latitude": 32.762751,
      "longitude": -90.36285,
      "city": "Tinsley",
      "state": "MS",
      "county": "Yazoo"
  },
  {
      "zipCode": 39174,
      "latitude": 32.395343,
      "longitude": -90.154436,
      "city": "Tougaloo",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39175,
      "latitude": 32.22149,
      "longitude": -90.4372,
      "city": "Utica",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39176,
      "latitude": 33.329023,
      "longitude": -89.751563,
      "city": "Vaiden",
      "state": "MS",
      "county": "Carroll"
  },
  {
      "zipCode": 39177,
      "latitude": 32.711257,
      "longitude": -90.952716,
      "city": "Valley Park",
      "state": "MS",
      "county": "Issaquena"
  },
  {
      "zipCode": 39179,
      "latitude": 32.801526,
      "longitude": -90.098134,
      "city": "Vaughan",
      "state": "MS",
      "county": "Yazoo"
  },
  {
      "zipCode": 39180,
      "latitude": 32.339533,
      "longitude": -90.834407,
      "city": "Vicksburg",
      "state": "MS",
      "county": "Warren"
  },
  {
      "zipCode": 39181,
      "latitude": 32.348565,
      "longitude": -90.864199,
      "city": "Vicksburg",
      "state": "MS",
      "county": "Warren"
  },
  {
      "zipCode": 39182,
      "latitude": 32.348565,
      "longitude": -90.864199,
      "city": "Vicksburg",
      "state": "MS",
      "county": "Warren"
  },
  {
      "zipCode": 39183,
      "latitude": 32.386006,
      "longitude": -90.840021,
      "city": "Vicksburg",
      "state": "MS",
      "county": "Warren"
  },
  {
      "zipCode": 39189,
      "latitude": 32.62897,
      "longitude": -89.406315,
      "city": "Walnut Grove",
      "state": "MS",
      "county": "Leake"
  },
  {
      "zipCode": 39190,
      "latitude": 31.470602,
      "longitude": -91.404404,
      "city": "Washington",
      "state": "MS",
      "county": "Adams"
  },
  {
      "zipCode": 39191,
      "latitude": 31.847453,
      "longitude": -90.377153,
      "city": "Wesson",
      "state": "MS",
      "county": "Copiah"
  },
  {
      "zipCode": 39192,
      "latitude": 33.198544,
      "longitude": -89.853487,
      "city": "West",
      "state": "MS",
      "county": "Holmes"
  },
  {
      "zipCode": 39193,
      "latitude": 32.234775,
      "longitude": -90.07263,
      "city": "Whitfield",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39194,
      "latitude": 32.815898,
      "longitude": -90.475849,
      "city": "Yazoo City",
      "state": "MS",
      "county": "Yazoo"
  },
  {
      "zipCode": 39201,
      "latitude": 32.291095,
      "longitude": -90.192687,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39202,
      "latitude": 32.305395,
      "longitude": -90.176737,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39203,
      "latitude": 32.308595,
      "longitude": -90.210438,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39204,
      "latitude": 32.30148,
      "longitude": -90.290313,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39205,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39206,
      "latitude": 32.370044,
      "longitude": -90.169987,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39207,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39208,
      "latitude": 32.267238,
      "longitude": -89.958708,
      "city": "Pearl",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39209,
      "latitude": 32.374632,
      "longitude": -90.362921,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39210,
      "latitude": 32.321845,
      "longitude": -90.177137,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39211,
      "latitude": 32.366844,
      "longitude": -90.123786,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39212,
      "latitude": 32.238808,
      "longitude": -90.28773,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39213,
      "latitude": 32.306096,
      "longitude": -90.216282,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39215,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39216,
      "latitude": 32.336744,
      "longitude": -90.216096,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39217,
      "latitude": 32.297396,
      "longitude": -90.208088,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39218,
      "latitude": 32.215304,
      "longitude": -90.126353,
      "city": "Richland",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39225,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39232,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Flowood",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39235,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39236,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39250,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39269,
      "latitude": 32.300296,
      "longitude": -90.188638,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39271,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39272,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Byram",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39282,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39283,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39284,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39286,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39288,
      "latitude": 32.313127,
      "longitude": -89.799625,
      "city": "Pearl",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39289,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39296,
      "latitude": 32.311287,
      "longitude": -90.397157,
      "city": "Jackson",
      "state": "MS",
      "county": "Hinds"
  },
  {
      "zipCode": 39298,
      "latitude": 32.319905,
      "longitude": -89.992257,
      "city": "Jackson",
      "state": "MS",
      "county": "Rankin"
  },
  {
      "zipCode": 39301,
      "latitude": 32.381605,
      "longitude": -88.663489,
      "city": "Meridian",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39302,
      "latitude": 32.431958,
      "longitude": -88.641888,
      "city": "Meridian",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39303,
      "latitude": 32.401233,
      "longitude": -88.652279,
      "city": "Meridian",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39304,
      "latitude": 32.420847,
      "longitude": -88.646016,
      "city": "Meridian",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39305,
      "latitude": 32.403111,
      "longitude": -88.658234,
      "city": "Meridian",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39307,
      "latitude": 32.386718,
      "longitude": -88.676575,
      "city": "Meridian",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39309,
      "latitude": 32.337389,
      "longitude": -88.726474,
      "city": "Meridian",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39320,
      "latitude": 32.52258,
      "longitude": -88.745613,
      "city": "Bailey",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39322,
      "latitude": 31.582003,
      "longitude": -88.548006,
      "city": "Buckatunna",
      "state": "MS",
      "county": "Wayne"
  },
  {
      "zipCode": 39323,
      "latitude": 32.35048,
      "longitude": -88.955099,
      "city": "Chunky",
      "state": "MS",
      "county": "Newton"
  },
  {
      "zipCode": 39324,
      "latitude": 31.593682,
      "longitude": -88.704829,
      "city": "Clara",
      "state": "MS",
      "county": "Wayne"
  },
  {
      "zipCode": 39325,
      "latitude": 32.434473,
      "longitude": -88.78778,
      "city": "Collinsville",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39326,
      "latitude": 32.556534,
      "longitude": -88.679062,
      "city": "Daleville",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39327,
      "latitude": 32.427135,
      "longitude": -89.092952,
      "city": "Decatur",
      "state": "MS",
      "county": "Newton"
  },
  {
      "zipCode": 39328,
      "latitude": 32.799065,
      "longitude": -88.698657,
      "city": "De Kalb",
      "state": "MS",
      "county": "Kemper"
  },
  {
      "zipCode": 39330,
      "latitude": 32.14342,
      "longitude": -88.819543,
      "city": "Enterprise",
      "state": "MS",
      "county": "Clarke"
  },
  {
      "zipCode": 39332,
      "latitude": 32.323107,
      "longitude": -89.083928,
      "city": "Hickory",
      "state": "MS",
      "county": "Newton"
  },
  {
      "zipCode": 39335,
      "latitude": 32.498068,
      "longitude": -88.534433,
      "city": "Lauderdale",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39336,
      "latitude": 32.319198,
      "longitude": -89.258254,
      "city": "Lawrence",
      "state": "MS",
      "county": "Newton"
  },
  {
      "zipCode": 39337,
      "latitude": 32.5049,
      "longitude": -88.995468,
      "city": "Little Rock",
      "state": "MS",
      "county": "Newton"
  },
  {
      "zipCode": 39338,
      "latitude": 32.109313,
      "longitude": -89.187423,
      "city": "Louin",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39339,
      "latitude": 33.101687,
      "longitude": -89.068098,
      "city": "Louisville",
      "state": "MS",
      "county": "Winston"
  },
  {
      "zipCode": 39341,
      "latitude": 33.125769,
      "longitude": -88.54474,
      "city": "Macon",
      "state": "MS",
      "county": "Noxubee"
  },
  {
      "zipCode": 39342,
      "latitude": 32.336743,
      "longitude": -88.616297,
      "city": "Marion",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39345,
      "latitude": 32.39983,
      "longitude": -89.08313,
      "city": "Newton",
      "state": "MS",
      "county": "Newton"
  },
  {
      "zipCode": 39346,
      "latitude": 32.980751,
      "longitude": -89.108325,
      "city": "Noxapater",
      "state": "MS",
      "county": "Winston"
  },
  {
      "zipCode": 39347,
      "latitude": 32.021692,
      "longitude": -88.885093,
      "city": "Pachuta",
      "state": "MS",
      "county": "Clarke"
  },
  {
      "zipCode": 39348,
      "latitude": 32.069413,
      "longitude": -89.027181,
      "city": "Paulding",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39350,
      "latitude": 32.754387,
      "longitude": -89.116797,
      "city": "Philadelphia",
      "state": "MS",
      "county": "Neshoba"
  },
  {
      "zipCode": 39352,
      "latitude": 32.797547,
      "longitude": -88.523668,
      "city": "Porterville",
      "state": "MS",
      "county": "Kemper"
  },
  {
      "zipCode": 39354,
      "latitude": 32.855983,
      "longitude": -88.827847,
      "city": "Preston",
      "state": "MS",
      "county": "Kemper"
  },
  {
      "zipCode": 39355,
      "latitude": 32.013802,
      "longitude": -88.663429,
      "city": "Quitman",
      "state": "MS",
      "county": "Clarke"
  },
  {
      "zipCode": 39356,
      "latitude": 32.13603,
      "longitude": -88.998801,
      "city": "Rose Hill",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39358,
      "latitude": 32.822624,
      "longitude": -88.478138,
      "city": "Scooba",
      "state": "MS",
      "county": "Kemper"
  },
  {
      "zipCode": 39359,
      "latitude": 32.551832,
      "longitude": -89.357384,
      "city": "Sebastopol",
      "state": "MS",
      "county": "Scott"
  },
  {
      "zipCode": 39360,
      "latitude": 31.960687,
      "longitude": -88.716818,
      "city": "Shubuta",
      "state": "MS",
      "county": "Clarke"
  },
  {
      "zipCode": 39361,
      "latitude": 33.082645,
      "longitude": -88.659645,
      "city": "Shuqualak",
      "state": "MS",
      "county": "Noxubee"
  },
  {
      "zipCode": 39362,
      "latitude": 31.360647,
      "longitude": -88.606456,
      "city": "State Line",
      "state": "MS",
      "county": "Greene"
  },
  {
      "zipCode": 39363,
      "latitude": 32.140683,
      "longitude": -88.770572,
      "city": "Stonewall",
      "state": "MS",
      "county": "Clarke"
  },
  {
      "zipCode": 39364,
      "latitude": 32.424306,
      "longitude": -88.660633,
      "city": "Toomsuba",
      "state": "MS",
      "county": "Lauderdale"
  },
  {
      "zipCode": 39365,
      "latitude": 32.643921,
      "longitude": -89.111993,
      "city": "Union",
      "state": "MS",
      "county": "Neshoba"
  },
  {
      "zipCode": 39366,
      "latitude": 31.950645,
      "longitude": -88.970738,
      "city": "Vossburg",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39367,
      "latitude": 31.659327,
      "longitude": -88.704113,
      "city": "Waynesboro",
      "state": "MS",
      "county": "Wayne"
  },
  {
      "zipCode": 39401,
      "latitude": 31.245138,
      "longitude": -89.28071,
      "city": "Hattiesburg",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39402,
      "latitude": 31.335865,
      "longitude": -89.379246,
      "city": "Hattiesburg",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39403,
      "latitude": 31.356642,
      "longitude": -89.382415,
      "city": "Hattiesburg",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39404,
      "latitude": 31.172142,
      "longitude": -89.294772,
      "city": "Hattiesburg",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39406,
      "latitude": 31.172142,
      "longitude": -89.294772,
      "city": "Hattiesburg",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39407,
      "latitude": 31.172142,
      "longitude": -89.294772,
      "city": "Hattiesburg",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39421,
      "latitude": 31.582332,
      "longitude": -89.781429,
      "city": "Bassfield",
      "state": "MS",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 39422,
      "latitude": 31.989146,
      "longitude": -89.209877,
      "city": "Bay Springs",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39423,
      "latitude": 31.185365,
      "longitude": -88.920132,
      "city": "Beaumont",
      "state": "MS",
      "county": "Perry"
  },
  {
      "zipCode": 39425,
      "latitude": 31.181756,
      "longitude": -89.266547,
      "city": "Brooklyn",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39426,
      "latitude": 30.661121,
      "longitude": -89.646536,
      "city": "Carriere",
      "state": "MS",
      "county": "Pearl River"
  },
  {
      "zipCode": 39427,
      "latitude": 31.601319,
      "longitude": -89.865962,
      "city": "Carson",
      "state": "MS",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 39428,
      "latitude": 31.652811,
      "longitude": -89.578002,
      "city": "Collins",
      "state": "MS",
      "county": "Covington"
  },
  {
      "zipCode": 39429,
      "latitude": 31.218188,
      "longitude": -89.801872,
      "city": "Columbia",
      "state": "MS",
      "county": "Marion"
  },
  {
      "zipCode": 39436,
      "latitude": 31.455471,
      "longitude": -89.302042,
      "city": "Eastabuchie",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39437,
      "latitude": 31.611088,
      "longitude": -89.171825,
      "city": "Ellisville",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39439,
      "latitude": 31.892668,
      "longitude": -89.077368,
      "city": "Heidelberg",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39440,
      "latitude": 31.711733,
      "longitude": -89.114317,
      "city": "Laurel",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39441,
      "latitude": 31.727664,
      "longitude": -89.075482,
      "city": "Laurel",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39442,
      "latitude": 31.682254,
      "longitude": -89.040622,
      "city": "Laurel",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39443,
      "latitude": 31.686952,
      "longitude": -89.121296,
      "city": "Laurel",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39451,
      "latitude": 31.167734,
      "longitude": -88.637767,
      "city": "Leakesville",
      "state": "MS",
      "county": "Greene"
  },
  {
      "zipCode": 39452,
      "latitude": 30.866791,
      "longitude": -88.649035,
      "city": "Lucedale",
      "state": "MS",
      "county": "George"
  },
  {
      "zipCode": 39455,
      "latitude": 31.100426,
      "longitude": -89.503417,
      "city": "Lumberton",
      "state": "MS",
      "county": "Lamar"
  },
  {
      "zipCode": 39456,
      "latitude": 31.086666,
      "longitude": -88.797692,
      "city": "Mc Lain",
      "state": "MS",
      "county": "Greene"
  },
  {
      "zipCode": 39457,
      "latitude": 30.666936,
      "longitude": -89.673392,
      "city": "Mc Neill",
      "state": "MS",
      "county": "Pearl River"
  },
  {
      "zipCode": 39459,
      "latitude": 31.500214,
      "longitude": -89.296883,
      "city": "Moselle",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39460,
      "latitude": 32.013235,
      "longitude": -89.11656,
      "city": "Moss",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39461,
      "latitude": 31.210172,
      "longitude": -88.754841,
      "city": "Neely",
      "state": "MS",
      "county": "Greene"
  },
  {
      "zipCode": 39462,
      "latitude": 31.171903,
      "longitude": -88.991561,
      "city": "New Augusta",
      "state": "MS",
      "county": "Perry"
  },
  {
      "zipCode": 39463,
      "latitude": 30.563724,
      "longitude": -89.557751,
      "city": "Nicholson",
      "state": "MS",
      "county": "Pearl River"
  },
  {
      "zipCode": 39464,
      "latitude": 31.311643,
      "longitude": -88.999044,
      "city": "Ovett",
      "state": "MS",
      "county": "Perry"
  },
  {
      "zipCode": 39465,
      "latitude": 31.331528,
      "longitude": -89.215061,
      "city": "Petal",
      "state": "MS",
      "county": "Forrest"
  },
  {
      "zipCode": 39466,
      "latitude": 30.627655,
      "longitude": -89.63128,
      "city": "Picayune",
      "state": "MS",
      "county": "Pearl River"
  },
  {
      "zipCode": 39470,
      "latitude": 30.756774,
      "longitude": -89.58072,
      "city": "Poplarville",
      "state": "MS",
      "county": "Pearl River"
  },
  {
      "zipCode": 39474,
      "latitude": 31.59763,
      "longitude": -89.886038,
      "city": "Prentiss",
      "state": "MS",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 39475,
      "latitude": 31.228126,
      "longitude": -89.501513,
      "city": "Purvis",
      "state": "MS",
      "county": "Lamar"
  },
  {
      "zipCode": 39476,
      "latitude": 31.318239,
      "longitude": -88.993126,
      "city": "Richton",
      "state": "MS",
      "county": "Perry"
  },
  {
      "zipCode": 39477,
      "latitude": 31.790929,
      "longitude": -89.041363,
      "city": "Sandersville",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39478,
      "latitude": 31.078868,
      "longitude": -89.847732,
      "city": "Sandy Hook",
      "state": "MS",
      "county": "Marion"
  },
  {
      "zipCode": 39479,
      "latitude": 31.5378,
      "longitude": -89.502846,
      "city": "Seminary",
      "state": "MS",
      "county": "Covington"
  },
  {
      "zipCode": 39480,
      "latitude": 31.713226,
      "longitude": -89.297336,
      "city": "Soso",
      "state": "MS",
      "county": "Jones"
  },
  {
      "zipCode": 39481,
      "latitude": 31.870465,
      "longitude": -89.229337,
      "city": "Stringer",
      "state": "MS",
      "county": "Jasper"
  },
  {
      "zipCode": 39482,
      "latitude": 31.216581,
      "longitude": -89.531088,
      "city": "Sumrall",
      "state": "MS",
      "county": "Lamar"
  },
  {
      "zipCode": 39483,
      "latitude": 31.247873,
      "longitude": -89.92857,
      "city": "Foxworth",
      "state": "MS",
      "county": "Marion"
  },
  {
      "zipCode": 39501,
      "latitude": 30.396277,
      "longitude": -89.064103,
      "city": "Gulfport",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39502,
      "latitude": 30.415795,
      "longitude": -89.068448,
      "city": "Gulfport",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39503,
      "latitude": 30.467067,
      "longitude": -89.151485,
      "city": "Gulfport",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39505,
      "latitude": 30.415795,
      "longitude": -89.068448,
      "city": "Gulfport",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39506,
      "latitude": 30.415795,
      "longitude": -89.068448,
      "city": "Gulfport",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39507,
      "latitude": 30.400079,
      "longitude": -89.031635,
      "city": "Gulfport",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39520,
      "latitude": 30.295608,
      "longitude": -89.463187,
      "city": "Bay Saint Louis",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39521,
      "latitude": 30.403156,
      "longitude": -89.49821,
      "city": "Bay Saint Louis",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39522,
      "latitude": 30.403156,
      "longitude": -89.49821,
      "city": "Stennis Space Center",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39525,
      "latitude": 30.372821,
      "longitude": -89.377942,
      "city": "Diamondhead",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39529,
      "latitude": 30.403156,
      "longitude": -89.49821,
      "city": "Stennis Space Center",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39530,
      "latitude": 30.432454,
      "longitude": -88.978634,
      "city": "Biloxi",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39531,
      "latitude": 30.401498,
      "longitude": -88.971229,
      "city": "Biloxi",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39532,
      "latitude": 30.510988,
      "longitude": -88.96808,
      "city": "Biloxi",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39533,
      "latitude": 30.415795,
      "longitude": -89.068448,
      "city": "Biloxi",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39534,
      "latitude": 30.415795,
      "longitude": -89.068448,
      "city": "Biloxi",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39535,
      "latitude": 30.415795,
      "longitude": -89.068448,
      "city": "Biloxi",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39540,
      "latitude": 30.442815,
      "longitude": -88.897306,
      "city": "Diberville",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39552,
      "latitude": 30.441072,
      "longitude": -88.634482,
      "city": "Escatawpa",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39553,
      "latitude": 30.421676,
      "longitude": -88.66487,
      "city": "Gautier",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39555,
      "latitude": 30.71325,
      "longitude": -88.519743,
      "city": "Hurley",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39556,
      "latitude": 30.487498,
      "longitude": -89.418722,
      "city": "Kiln",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39558,
      "latitude": 30.239124,
      "longitude": -89.459526,
      "city": "Lakeshore",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39560,
      "latitude": 30.374646,
      "longitude": -89.177143,
      "city": "Long Beach",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39561,
      "latitude": 30.721036,
      "longitude": -89.172285,
      "city": "Mc Henry",
      "state": "MS",
      "county": "Stone"
  },
  {
      "zipCode": 39562,
      "latitude": 30.544726,
      "longitude": -88.604298,
      "city": "Moss Point",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39563,
      "latitude": 30.539088,
      "longitude": -88.621049,
      "city": "Moss Point",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39564,
      "latitude": 30.50028,
      "longitude": -88.655024,
      "city": "Ocean Springs",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39565,
      "latitude": 30.547365,
      "longitude": -88.708582,
      "city": "Ocean Springs",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39566,
      "latitude": 30.441072,
      "longitude": -88.634482,
      "city": "Ocean Springs",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39567,
      "latitude": 30.534162,
      "longitude": -88.489379,
      "city": "Pascagoula",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39568,
      "latitude": 30.441072,
      "longitude": -88.634482,
      "city": "Pascagoula",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39569,
      "latitude": 30.441072,
      "longitude": -88.634482,
      "city": "Pascagoula",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39571,
      "latitude": 30.426162,
      "longitude": -89.264327,
      "city": "Pass Christian",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39572,
      "latitude": 30.249299,
      "longitude": -89.520472,
      "city": "Pearlington",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39573,
      "latitude": 30.773109,
      "longitude": -89.112944,
      "city": "Perkinston",
      "state": "MS",
      "county": "Stone"
  },
  {
      "zipCode": 39574,
      "latitude": 30.599773,
      "longitude": -89.112683,
      "city": "Saucier",
      "state": "MS",
      "county": "Harrison"
  },
  {
      "zipCode": 39576,
      "latitude": 30.285046,
      "longitude": -89.371231,
      "city": "Waveland",
      "state": "MS",
      "county": "Hancock"
  },
  {
      "zipCode": 39577,
      "latitude": 30.847778,
      "longitude": -89.086992,
      "city": "Wiggins",
      "state": "MS",
      "county": "Stone"
  },
  {
      "zipCode": 39581,
      "latitude": 30.539159,
      "longitude": -88.621046,
      "city": "Pascagoula",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39595,
      "latitude": 30.441072,
      "longitude": -88.634482,
      "city": "Pascagoula",
      "state": "MS",
      "county": "Jackson"
  },
  {
      "zipCode": 39601,
      "latitude": 31.521489,
      "longitude": -90.460786,
      "city": "Brookhaven",
      "state": "MS",
      "county": "Lincoln"
  },
  {
      "zipCode": 39602,
      "latitude": 31.533354,
      "longitude": -90.490661,
      "city": "Brookhaven",
      "state": "MS",
      "county": "Lincoln"
  },
  {
      "zipCode": 39603,
      "latitude": 31.612287,
      "longitude": -90.446702,
      "city": "Brookhaven",
      "state": "MS",
      "county": "Lincoln"
  },
  {
      "zipCode": 39629,
      "latitude": 31.447124,
      "longitude": -90.45787,
      "city": "Bogue Chitto",
      "state": "MS",
      "county": "Lincoln"
  },
  {
      "zipCode": 39630,
      "latitude": 31.465718,
      "longitude": -90.895386,
      "city": "Bude",
      "state": "MS",
      "county": "Franklin"
  },
  {
      "zipCode": 39631,
      "latitude": 31.134359,
      "longitude": -91.175079,
      "city": "Centreville",
      "state": "MS",
      "county": "Wilkinson"
  },
  {
      "zipCode": 39632,
      "latitude": 31.114483,
      "longitude": -90.487486,
      "city": "Chatawa",
      "state": "MS",
      "county": "Pike"
  },
  {
      "zipCode": 39633,
      "latitude": 31.247847,
      "longitude": -90.885075,
      "city": "Crosby",
      "state": "MS",
      "county": "Amite"
  },
  {
      "zipCode": 39635,
      "latitude": 31.18537,
      "longitude": -90.427966,
      "city": "Fernwood",
      "state": "MS",
      "county": "Pike"
  },
  {
      "zipCode": 39638,
      "latitude": 31.199644,
      "longitude": -90.916006,
      "city": "Gloster",
      "state": "MS",
      "county": "Amite"
  },
  {
      "zipCode": 39641,
      "latitude": 31.409296,
      "longitude": -90.142429,
      "city": "Jayess",
      "state": "MS",
      "county": "Lawrence"
  },
  {
      "zipCode": 39643,
      "latitude": 31.243549,
      "longitude": -89.978849,
      "city": "Kokomo",
      "state": "MS",
      "county": "Marion"
  },
  {
      "zipCode": 39645,
      "latitude": 31.17427,
      "longitude": -90.803528,
      "city": "Liberty",
      "state": "MS",
      "county": "Amite"
  },
  {
      "zipCode": 39647,
      "latitude": 31.465718,
      "longitude": -90.895386,
      "city": "Mc Call Creek",
      "state": "MS",
      "county": "Franklin"
  },
  {
      "zipCode": 39648,
      "latitude": 31.1769,
      "longitude": -90.40156,
      "city": "Mccomb",
      "state": "MS",
      "county": "Pike"
  },
  {
      "zipCode": 39649,
      "latitude": 31.17494,
      "longitude": -90.403987,
      "city": "Mccomb",
      "state": "MS",
      "county": "Pike"
  },
  {
      "zipCode": 39652,
      "latitude": 31.143395,
      "longitude": -90.403989,
      "city": "Magnolia",
      "state": "MS",
      "county": "Pike"
  },
  {
      "zipCode": 39653,
      "latitude": 31.469888,
      "longitude": -90.891482,
      "city": "Meadville",
      "state": "MS",
      "county": "Franklin"
  },
  {
      "zipCode": 39654,
      "latitude": 31.540111,
      "longitude": -90.161012,
      "city": "Monticello",
      "state": "MS",
      "county": "Lawrence"
  },
  {
      "zipCode": 39656,
      "latitude": 31.48225,
      "longitude": -90.036767,
      "city": "Oak Vale",
      "state": "MS",
      "county": "Lawrence"
  },
  {
      "zipCode": 39657,
      "latitude": 31.043653,
      "longitude": -90.403651,
      "city": "Osyka",
      "state": "MS",
      "county": "Pike"
  },
  {
      "zipCode": 39661,
      "latitude": 31.50115,
      "longitude": -91.069486,
      "city": "Roxie",
      "state": "MS",
      "county": "Franklin"
  },
  {
      "zipCode": 39662,
      "latitude": 31.409907,
      "longitude": -90.291051,
      "city": "Ruth",
      "state": "MS",
      "county": "Lincoln"
  },
  {
      "zipCode": 39663,
      "latitude": 31.594604,
      "longitude": -90.044711,
      "city": "Silver Creek",
      "state": "MS",
      "county": "Lawrence"
  },
  {
      "zipCode": 39664,
      "latitude": 31.268945,
      "longitude": -90.670185,
      "city": "Smithdale",
      "state": "MS",
      "county": "Amite"
  },
  {
      "zipCode": 39665,
      "latitude": 31.640256,
      "longitude": -90.189547,
      "city": "Sontag",
      "state": "MS",
      "county": "Lawrence"
  },
  {
      "zipCode": 39666,
      "latitude": 31.258892,
      "longitude": -90.404555,
      "city": "Summit",
      "state": "MS",
      "county": "Pike"
  },
  {
      "zipCode": 39667,
      "latitude": 31.175558,
      "longitude": -90.090824,
      "city": "Tylertown",
      "state": "MS",
      "county": "Walthall"
  },
  {
      "zipCode": 39668,
      "latitude": 31.714577,
      "longitude": -90.746168,
      "city": "Union Church",
      "state": "MS",
      "county": "Jefferson"
  },
  {
      "zipCode": 39669,
      "latitude": 31.175443,
      "longitude": -91.324666,
      "city": "Woodville",
      "state": "MS",
      "county": "Wilkinson"
  },
  {
      "zipCode": 39701,
      "latitude": 33.516258,
      "longitude": -88.461318,
      "city": "Columbus",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39702,
      "latitude": 33.429569,
      "longitude": -88.460218,
      "city": "Columbus",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39703,
      "latitude": 33.484147,
      "longitude": -88.283998,
      "city": "Columbus",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39704,
      "latitude": 33.548523,
      "longitude": -88.379614,
      "city": "Columbus",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39705,
      "latitude": 33.550752,
      "longitude": -88.486521,
      "city": "Columbus",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39710,
      "latitude": 33.51633,
      "longitude": -88.460083,
      "city": "Columbus",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39730,
      "latitude": 33.847638,
      "longitude": -88.514694,
      "city": "Aberdeen",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 39735,
      "latitude": 33.278252,
      "longitude": -89.191298,
      "city": "Ackerman",
      "state": "MS",
      "county": "Choctaw"
  },
  {
      "zipCode": 39736,
      "latitude": 33.411531,
      "longitude": -88.644261,
      "city": "Artesia",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39737,
      "latitude": 33.648956,
      "longitude": -89.334234,
      "city": "Bellefontaine",
      "state": "MS",
      "county": "Webster"
  },
  {
      "zipCode": 39739,
      "latitude": 33.220204,
      "longitude": -88.482218,
      "city": "Brooksville",
      "state": "MS",
      "county": "Noxubee"
  },
  {
      "zipCode": 39740,
      "latitude": 33.81523,
      "longitude": -88.271904,
      "city": "Caledonia",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 39741,
      "latitude": 33.659271,
      "longitude": -88.753976,
      "city": "Cedarbluff",
      "state": "MS",
      "county": "Clay"
  },
  {
      "zipCode": 39743,
      "latitude": 33.334688,
      "longitude": -88.556056,
      "city": "Crawford",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39744,
      "latitude": 33.549384,
      "longitude": -89.290417,
      "city": "Eupora",
      "state": "MS",
      "county": "Webster"
  },
  {
      "zipCode": 39745,
      "latitude": 33.289753,
      "longitude": -89.399875,
      "city": "French Camp",
      "state": "MS",
      "county": "Choctaw"
  },
  {
      "zipCode": 39746,
      "latitude": 33.778926,
      "longitude": -88.358784,
      "city": "Hamilton",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 39747,
      "latitude": 33.4346,
      "longitude": -89.585663,
      "city": "Kilmichael",
      "state": "MS",
      "county": "Montgomery"
  },
  {
      "zipCode": 39750,
      "latitude": 33.599618,
      "longitude": -89.262792,
      "city": "Maben",
      "state": "MS",
      "county": "Webster"
  },
  {
      "zipCode": 39751,
      "latitude": 33.94185,
      "longitude": -89.323857,
      "city": "Mantee",
      "state": "MS",
      "county": "Calhoun"
  },
  {
      "zipCode": 39752,
      "latitude": 33.540161,
      "longitude": -89.130294,
      "city": "Mathiston",
      "state": "MS",
      "county": "Webster"
  },
  {
      "zipCode": 39753,
      "latitude": 33.483814,
      "longitude": -88.640881,
      "city": "Mayhew",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39754,
      "latitude": 33.659271,
      "longitude": -88.753976,
      "city": "Montpelier",
      "state": "MS",
      "county": "Clay"
  },
  {
      "zipCode": 39755,
      "latitude": 33.659271,
      "longitude": -88.753976,
      "city": "Pheba",
      "state": "MS",
      "county": "Clay"
  },
  {
      "zipCode": 39756,
      "latitude": 33.783578,
      "longitude": -88.664183,
      "city": "Prairie",
      "state": "MS",
      "county": "Monroe"
  },
  {
      "zipCode": 39759,
      "latitude": 33.42554,
      "longitude": -88.879117,
      "city": "Starkville",
      "state": "MS",
      "county": "Oktibbeha"
  },
  {
      "zipCode": 39760,
      "latitude": 33.459924,
      "longitude": -88.832163,
      "city": "Starkville",
      "state": "MS",
      "county": "Oktibbeha"
  },
  {
      "zipCode": 39762,
      "latitude": 33.415601,
      "longitude": -88.743329,
      "city": "Mississippi State",
      "state": "MS",
      "county": "Oktibbeha"
  },
  {
      "zipCode": 39766,
      "latitude": 33.603342,
      "longitude": -88.321007,
      "city": "Steens",
      "state": "MS",
      "county": "Lowndes"
  },
  {
      "zipCode": 39767,
      "latitude": 33.518048,
      "longitude": -89.467862,
      "city": "Stewart",
      "state": "MS",
      "county": "Montgomery"
  },
  {
      "zipCode": 39769,
      "latitude": 33.405675,
      "longitude": -88.993677,
      "city": "Sturgis",
      "state": "MS",
      "county": "Oktibbeha"
  },
  {
      "zipCode": 39771,
      "latitude": 33.595758,
      "longitude": -89.298621,
      "city": "Walthall",
      "state": "MS",
      "county": "Webster"
  },
  {
      "zipCode": 39772,
      "latitude": 33.321036,
      "longitude": -89.271149,
      "city": "Weir",
      "state": "MS",
      "county": "Choctaw"
  },
  {
      "zipCode": 39773,
      "latitude": 33.621135,
      "longitude": -88.609136,
      "city": "West Point",
      "state": "MS",
      "county": "Clay"
  },
  {
      "zipCode": 39776,
      "latitude": 33.804808,
      "longitude": -89.060746,
      "city": "Woodland",
      "state": "MS",
      "county": "Chickasaw"
  },
  {
      "zipCode": 39901,
      "latitude": 33.891251,
      "longitude": -84.07456,
      "city": "Atlanta",
      "state": "GA",
      "county": "Dekalb"
  },
  {
      "zipCode": 40003,
      "latitude": 38.274456,
      "longitude": -85.090374,
      "city": "Bagdad",
      "state": "KY",
      "county": "Shelby"
  },
  {
      "zipCode": 40004,
      "latitude": 37.822585,
      "longitude": -85.466944,
      "city": "Bardstown",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40006,
      "latitude": 38.603011,
      "longitude": -85.329441,
      "city": "Bedford",
      "state": "KY",
      "county": "Trimble"
  },
  {
      "zipCode": 40007,
      "latitude": 38.452892,
      "longitude": -85.016907,
      "city": "Bethlehem",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40008,
      "latitude": 37.826549,
      "longitude": -85.327083,
      "city": "Bloomfield",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40009,
      "latitude": 37.504057,
      "longitude": -85.107213,
      "city": "Bradfordsville",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40010,
      "latitude": 38.389195,
      "longitude": -85.44584,
      "city": "Buckner",
      "state": "KY",
      "county": "Oldham"
  },
  {
      "zipCode": 40011,
      "latitude": 38.45807,
      "longitude": -85.132315,
      "city": "Campbellsburg",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40012,
      "latitude": 37.903151,
      "longitude": -85.191533,
      "city": "Chaplin",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40013,
      "latitude": 37.912465,
      "longitude": -85.465785,
      "city": "Coxs Creek",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40014,
      "latitude": 38.358083,
      "longitude": -85.446974,
      "city": "Crestwood",
      "state": "KY",
      "county": "Oldham"
  },
  {
      "zipCode": 40018,
      "latitude": 38.22977,
      "longitude": -85.66304,
      "city": "Eastwood",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40019,
      "latitude": 38.416293,
      "longitude": -85.171224,
      "city": "Eminence",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40020,
      "latitude": 37.933981,
      "longitude": -85.386242,
      "city": "Fairfield",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40022,
      "latitude": 38.15483,
      "longitude": -85.340174,
      "city": "Finchville",
      "state": "KY",
      "county": "Shelby"
  },
  {
      "zipCode": 40023,
      "latitude": 38.188936,
      "longitude": -85.446269,
      "city": "Fisherville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40025,
      "latitude": 38.309597,
      "longitude": -85.643359,
      "city": "Glenview",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40026,
      "latitude": 38.412346,
      "longitude": -85.527464,
      "city": "Goshen",
      "state": "KY",
      "county": "Oldham"
  },
  {
      "zipCode": 40027,
      "latitude": 38.329718,
      "longitude": -85.63299,
      "city": "Harrods Creek",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40031,
      "latitude": 38.423186,
      "longitude": -85.454359,
      "city": "La Grange",
      "state": "KY",
      "county": "Oldham"
  },
  {
      "zipCode": 40032,
      "latitude": 38.40464,
      "longitude": -85.460504,
      "city": "La Grange",
      "state": "KY",
      "county": "Oldham"
  },
  {
      "zipCode": 40033,
      "latitude": 37.541558,
      "longitude": -85.256025,
      "city": "Lebanon",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40036,
      "latitude": 38.455714,
      "longitude": -84.988603,
      "city": "Lockport",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40037,
      "latitude": 37.647483,
      "longitude": -85.417876,
      "city": "Loretto",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40040,
      "latitude": 37.756812,
      "longitude": -85.059008,
      "city": "Mackville",
      "state": "KY",
      "county": "Washington"
  },
  {
      "zipCode": 40041,
      "latitude": 38.25361,
      "longitude": -85.662215,
      "city": "Masonic Home",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40045,
      "latitude": 38.687402,
      "longitude": -85.378762,
      "city": "Milton",
      "state": "KY",
      "county": "Trimble"
  },
  {
      "zipCode": 40046,
      "latitude": 38.019718,
      "longitude": -85.181188,
      "city": "Mount Eden",
      "state": "KY",
      "county": "Spencer"
  },
  {
      "zipCode": 40047,
      "latitude": 38.04141,
      "longitude": -85.581303,
      "city": "Mount Washington",
      "state": "KY",
      "county": "Bullitt"
  },
  {
      "zipCode": 40048,
      "latitude": 37.756941,
      "longitude": -85.44671,
      "city": "Nazareth",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40049,
      "latitude": 37.660251,
      "longitude": -85.393204,
      "city": "Nerinx",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40050,
      "latitude": 38.427404,
      "longitude": -85.18706,
      "city": "New Castle",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40051,
      "latitude": 37.643761,
      "longitude": -85.547037,
      "city": "New Haven",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40052,
      "latitude": 37.602081,
      "longitude": -85.516424,
      "city": "New Hope",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40055,
      "latitude": 38.473652,
      "longitude": -85.300936,
      "city": "Pendleton",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40056,
      "latitude": 38.303255,
      "longitude": -85.485175,
      "city": "Pewee Valley",
      "state": "KY",
      "county": "Oldham"
  },
  {
      "zipCode": 40057,
      "latitude": 38.431413,
      "longitude": -85.032581,
      "city": "Pleasureville",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40058,
      "latitude": 38.494055,
      "longitude": -85.124192,
      "city": "Port Royal",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40059,
      "latitude": 38.341558,
      "longitude": -85.598451,
      "city": "Prospect",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40060,
      "latitude": 37.533664,
      "longitude": -85.429061,
      "city": "Raywick",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40061,
      "latitude": 37.773962,
      "longitude": -85.201068,
      "city": "Saint Catharine",
      "state": "KY",
      "county": "Washington"
  },
  {
      "zipCode": 40062,
      "latitude": 37.604695,
      "longitude": -85.425149,
      "city": "Saint Francis",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40063,
      "latitude": 37.579519,
      "longitude": -85.355132,
      "city": "Saint Mary",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40065,
      "latitude": 38.222445,
      "longitude": -85.172072,
      "city": "Shelbyville",
      "state": "KY",
      "county": "Shelby"
  },
  {
      "zipCode": 40066,
      "latitude": 38.197268,
      "longitude": -85.212156,
      "city": "Shelbyville",
      "state": "KY",
      "county": "Shelby"
  },
  {
      "zipCode": 40067,
      "latitude": 38.223861,
      "longitude": -85.360625,
      "city": "Simpsonville",
      "state": "KY",
      "county": "Shelby"
  },
  {
      "zipCode": 40068,
      "latitude": 38.408032,
      "longitude": -85.267223,
      "city": "Smithfield",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40069,
      "latitude": 37.734249,
      "longitude": -85.21065,
      "city": "Springfield",
      "state": "KY",
      "county": "Washington"
  },
  {
      "zipCode": 40070,
      "latitude": 38.476096,
      "longitude": -85.248914,
      "city": "Sulphur",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40071,
      "latitude": 38.032735,
      "longitude": -85.366055,
      "city": "Taylorsville",
      "state": "KY",
      "county": "Spencer"
  },
  {
      "zipCode": 40075,
      "latitude": 38.552133,
      "longitude": -85.101879,
      "city": "Turners Station",
      "state": "KY",
      "county": "Henry"
  },
  {
      "zipCode": 40076,
      "latitude": 38.122662,
      "longitude": -85.103096,
      "city": "Waddy",
      "state": "KY",
      "county": "Shelby"
  },
  {
      "zipCode": 40077,
      "latitude": 38.491635,
      "longitude": -85.424726,
      "city": "Westport",
      "state": "KY",
      "county": "Oldham"
  },
  {
      "zipCode": 40078,
      "latitude": 37.843697,
      "longitude": -85.128804,
      "city": "Willisburg",
      "state": "KY",
      "county": "Washington"
  },
  {
      "zipCode": 40104,
      "latitude": 38.093864,
      "longitude": -86.345301,
      "city": "Battletown",
      "state": "KY",
      "county": "Meade"
  },
  {
      "zipCode": 40107,
      "latitude": 37.740827,
      "longitude": -85.59479,
      "city": "Boston",
      "state": "KY",
      "county": "Nelson"
  },
  {
      "zipCode": 40108,
      "latitude": 37.97552,
      "longitude": -86.179546,
      "city": "Brandenburg",
      "state": "KY",
      "county": "Meade"
  },
  {
      "zipCode": 40109,
      "latitude": 38.051236,
      "longitude": -85.755592,
      "city": "Brooks",
      "state": "KY",
      "county": "Bullitt"
  },
  {
      "zipCode": 40110,
      "latitude": 37.93462,
      "longitude": -85.65545,
      "city": "Clermont",
      "state": "KY",
      "county": "Bullitt"
  },
  {
      "zipCode": 40111,
      "latitude": 37.847839,
      "longitude": -86.463591,
      "city": "Cloverport",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40115,
      "latitude": 37.735817,
      "longitude": -86.237827,
      "city": "Custer",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40117,
      "latitude": 37.884962,
      "longitude": -86.115209,
      "city": "Ekron",
      "state": "KY",
      "county": "Meade"
  },
  {
      "zipCode": 40118,
      "latitude": 38.096526,
      "longitude": -85.758416,
      "city": "Fairdale",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40119,
      "latitude": 37.552184,
      "longitude": -86.483592,
      "city": "Falls Of Rough",
      "state": "KY",
      "county": "Grayson"
  },
  {
      "zipCode": 40121,
      "latitude": 37.899466,
      "longitude": -85.952833,
      "city": "Fort Knox",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 40129,
      "latitude": 38.081191,
      "longitude": -85.682825,
      "city": "Hillview",
      "state": "KY",
      "county": "Bullitt"
  },
  {
      "zipCode": 40140,
      "latitude": 37.802295,
      "longitude": -86.414768,
      "city": "Garfield",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40142,
      "latitude": 37.889946,
      "longitude": -86.199842,
      "city": "Guston",
      "state": "KY",
      "county": "Meade"
  },
  {
      "zipCode": 40143,
      "latitude": 37.751214,
      "longitude": -86.45371,
      "city": "Hardinsburg",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40144,
      "latitude": 37.802295,
      "longitude": -86.414768,
      "city": "Harned",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40145,
      "latitude": 37.606992,
      "longitude": -86.321158,
      "city": "Hudson",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40146,
      "latitude": 37.828452,
      "longitude": -86.28417,
      "city": "Irvington",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40150,
      "latitude": 37.90878,
      "longitude": -85.688029,
      "city": "Lebanon Junction",
      "state": "KY",
      "county": "Bullitt"
  },
  {
      "zipCode": 40152,
      "latitude": 37.619425,
      "longitude": -86.449076,
      "city": "Mc Daniels",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40153,
      "latitude": 37.802295,
      "longitude": -86.414768,
      "city": "Mc Quady",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40155,
      "latitude": 37.934131,
      "longitude": -86.081991,
      "city": "Muldraugh",
      "state": "KY",
      "county": "Meade"
  },
  {
      "zipCode": 40157,
      "latitude": 38.022328,
      "longitude": -86.355493,
      "city": "Payneville",
      "state": "KY",
      "county": "Meade"
  },
  {
      "zipCode": 40159,
      "latitude": 37.723474,
      "longitude": -85.976854,
      "city": "Radcliff",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 40160,
      "latitude": 37.826634,
      "longitude": -85.849432,
      "city": "Radcliff",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 40161,
      "latitude": 38.005963,
      "longitude": -86.398391,
      "city": "Rhodelia",
      "state": "KY",
      "county": "Meade"
  },
  {
      "zipCode": 40162,
      "latitude": 37.755939,
      "longitude": -86.032496,
      "city": "Rineyville",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 40164,
      "latitude": 37.802295,
      "longitude": -86.414768,
      "city": "Se Ree",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40165,
      "latitude": 37.959505,
      "longitude": -85.704609,
      "city": "Shepherdsville",
      "state": "KY",
      "county": "Bullitt"
  },
  {
      "zipCode": 40170,
      "latitude": 37.913755,
      "longitude": -86.52421,
      "city": "Stephensport",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40171,
      "latitude": 37.802295,
      "longitude": -86.414768,
      "city": "Union Star",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40175,
      "latitude": 37.803725,
      "longitude": -86.050646,
      "city": "Vine Grove",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 40176,
      "latitude": 37.881048,
      "longitude": -86.287044,
      "city": "Webster",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40177,
      "latitude": 37.980384,
      "longitude": -85.966061,
      "city": "West Point",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 40178,
      "latitude": 37.645844,
      "longitude": -86.405203,
      "city": "Westview",
      "state": "KY",
      "county": "Breckinridge"
  },
  {
      "zipCode": 40201,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40202,
      "latitude": 38.25247,
      "longitude": -85.753812,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40203,
      "latitude": 38.249273,
      "longitude": -85.769412,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40204,
      "latitude": 38.23942,
      "longitude": -85.722711,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40205,
      "latitude": 38.224181,
      "longitude": -85.60026,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40206,
      "latitude": 38.25032,
      "longitude": -85.703411,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40207,
      "latitude": 38.262769,
      "longitude": -85.666284,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40208,
      "latitude": 38.21782,
      "longitude": -85.763912,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40209,
      "latitude": 38.214391,
      "longitude": -85.769189,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40210,
      "latitude": 38.23207,
      "longitude": -85.788702,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40211,
      "latitude": 38.232811,
      "longitude": -85.815363,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40212,
      "latitude": 38.234969,
      "longitude": -85.794213,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40213,
      "latitude": 38.182609,
      "longitude": -85.707133,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40214,
      "latitude": 38.163269,
      "longitude": -85.788862,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40215,
      "latitude": 38.19188,
      "longitude": -85.785512,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40216,
      "latitude": 38.186519,
      "longitude": -85.833463,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40217,
      "latitude": 38.21787,
      "longitude": -85.739861,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40218,
      "latitude": 38.171856,
      "longitude": -85.67806,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40219,
      "latitude": 38.138118,
      "longitude": -85.69526,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40220,
      "latitude": 38.214603,
      "longitude": -85.620304,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40221,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40222,
      "latitude": 38.267429,
      "longitude": -85.623671,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40223,
      "latitude": 38.265072,
      "longitude": -85.558228,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40224,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40225,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40228,
      "latitude": 38.144155,
      "longitude": -85.626526,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40229,
      "latitude": 38.135961,
      "longitude": -85.649393,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40231,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40232,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40233,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40241,
      "latitude": 38.304531,
      "longitude": -85.581536,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40242,
      "latitude": 38.278469,
      "longitude": -85.59396,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40243,
      "latitude": 38.242201,
      "longitude": -85.535309,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40245,
      "latitude": 38.258384,
      "longitude": -85.475021,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40250,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40251,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40252,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40253,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40255,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40256,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40257,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40258,
      "latitude": 38.145719,
      "longitude": -85.864063,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40259,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40261,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40266,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40268,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40269,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40270,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40272,
      "latitude": 38.084554,
      "longitude": -85.851022,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40280,
      "latitude": 38.246721,
      "longitude": -85.685261,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40281,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40282,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40283,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40285,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40287,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40289,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40290,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40291,
      "latitude": 38.13132,
      "longitude": -85.575352,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40292,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40293,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40294,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40295,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40296,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40297,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40298,
      "latitude": 38.188962,
      "longitude": -85.676819,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40299,
      "latitude": 38.176762,
      "longitude": -85.521751,
      "city": "Louisville",
      "state": "KY",
      "county": "Jefferson"
  },
  {
      "zipCode": 40310,
      "latitude": 37.754839,
      "longitude": -84.74994,
      "city": "Burgin",
      "state": "KY",
      "county": "Mercer"
  },
  {
      "zipCode": 40311,
      "latitude": 38.334059,
      "longitude": -83.994865,
      "city": "Carlisle",
      "state": "KY",
      "county": "Nicholas"
  },
  {
      "zipCode": 40312,
      "latitude": 37.847517,
      "longitude": -83.859331,
      "city": "Clay City",
      "state": "KY",
      "county": "Powell"
  },
  {
      "zipCode": 40313,
      "latitude": 38.128726,
      "longitude": -83.442495,
      "city": "Clearfield",
      "state": "KY",
      "county": "Rowan"
  },
  {
      "zipCode": 40316,
      "latitude": 37.938651,
      "longitude": -83.605538,
      "city": "Denniston",
      "state": "KY",
      "county": "Menifee"
  },
  {
      "zipCode": 40317,
      "latitude": 38.176471,
      "longitude": -83.268234,
      "city": "Elliottville",
      "state": "KY",
      "county": "Rowan"
  },
  {
      "zipCode": 40319,
      "latitude": 38.140258,
      "longitude": -83.533691,
      "city": "Farmers",
      "state": "KY",
      "county": "Rowan"
  },
  {
      "zipCode": 40320,
      "latitude": 37.968496,
      "longitude": -84.157766,
      "city": "Ford",
      "state": "KY",
      "county": "Clark"
  },
  {
      "zipCode": 40322,
      "latitude": 37.947025,
      "longitude": -83.608449,
      "city": "Frenchburg",
      "state": "KY",
      "county": "Menifee"
  },
  {
      "zipCode": 40324,
      "latitude": 38.239718,
      "longitude": -84.574498,
      "city": "Georgetown",
      "state": "KY",
      "county": "Scott"
  },
  {
      "zipCode": 40328,
      "latitude": 37.585972,
      "longitude": -85.08651,
      "city": "Gravel Switch",
      "state": "KY",
      "county": "Marion"
  },
  {
      "zipCode": 40329,
      "latitude": 38.206706,
      "longitude": -83.41725,
      "city": "Haldeman",
      "state": "KY",
      "county": "Rowan"
  },
  {
      "zipCode": 40330,
      "latitude": 37.803283,
      "longitude": -84.860707,
      "city": "Harrodsburg",
      "state": "KY",
      "county": "Mercer"
  },
  {
      "zipCode": 40334,
      "latitude": 38.016064,
      "longitude": -83.771261,
      "city": "Hope",
      "state": "KY",
      "county": "Montgomery"
  },
  {
      "zipCode": 40336,
      "latitude": 37.70424,
      "longitude": -83.944815,
      "city": "Irvine",
      "state": "KY",
      "county": "Estill"
  },
  {
      "zipCode": 40337,
      "latitude": 37.964096,
      "longitude": -83.870275,
      "city": "Jeffersonville",
      "state": "KY",
      "county": "Montgomery"
  },
  {
      "zipCode": 40339,
      "latitude": 37.950381,
      "longitude": -84.656887,
      "city": "Keene",
      "state": "KY",
      "county": "Jessamine"
  },
  {
      "zipCode": 40340,
      "latitude": 37.866238,
      "longitude": -84.579491,
      "city": "Nicholasville",
      "state": "KY",
      "county": "Jessamine"
  },
  {
      "zipCode": 40342,
      "latitude": 38.006484,
      "longitude": -84.979923,
      "city": "Lawrenceburg",
      "state": "KY",
      "county": "Anderson"
  },
  {
      "zipCode": 40346,
      "latitude": 37.973921,
      "longitude": -83.743488,
      "city": "Means",
      "state": "KY",
      "county": "Menifee"
  },
  {
      "zipCode": 40347,
      "latitude": 38.145835,
      "longitude": -84.718028,
      "city": "Midway",
      "state": "KY",
      "county": "Woodford"
  },
  {
      "zipCode": 40348,
      "latitude": 38.296337,
      "longitude": -84.151319,
      "city": "Millersburg",
      "state": "KY",
      "county": "Bourbon"
  },
  {
      "zipCode": 40350,
      "latitude": 38.303859,
      "longitude": -83.886328,
      "city": "Moorefield",
      "state": "KY",
      "county": "Nicholas"
  },
  {
      "zipCode": 40351,
      "latitude": 38.210471,
      "longitude": -83.42039,
      "city": "Morehead",
      "state": "KY",
      "county": "Rowan"
  },
  {
      "zipCode": 40353,
      "latitude": 38.052103,
      "longitude": -83.924711,
      "city": "Mount Sterling",
      "state": "KY",
      "county": "Montgomery"
  },
  {
      "zipCode": 40355,
      "latitude": 38.627652,
      "longitude": -84.87562,
      "city": "New Liberty",
      "state": "KY",
      "county": "Owen"
  },
  {
      "zipCode": 40356,
      "latitude": 37.863751,
      "longitude": -84.565075,
      "city": "Nicholasville",
      "state": "KY",
      "county": "Jessamine"
  },
  {
      "zipCode": 40357,
      "latitude": 38.146395,
      "longitude": -84.107655,
      "city": "North Middletown",
      "state": "KY",
      "county": "Bourbon"
  },
  {
      "zipCode": 40358,
      "latitude": 38.084908,
      "longitude": -83.700824,
      "city": "Olympia",
      "state": "KY",
      "county": "Bath"
  },
  {
      "zipCode": 40359,
      "latitude": 38.493552,
      "longitude": -84.843115,
      "city": "Owenton",
      "state": "KY",
      "county": "Owen"
  },
  {
      "zipCode": 40360,
      "latitude": 38.146022,
      "longitude": -83.78456,
      "city": "Owingsville",
      "state": "KY",
      "county": "Bath"
  },
  {
      "zipCode": 40361,
      "latitude": 38.217248,
      "longitude": -84.213426,
      "city": "Paris",
      "state": "KY",
      "county": "Bourbon"
  },
  {
      "zipCode": 40362,
      "latitude": 38.219407,
      "longitude": -84.205944,
      "city": "Paris",
      "state": "KY",
      "county": "Bourbon"
  },
  {
      "zipCode": 40363,
      "latitude": 38.5218,
      "longitude": -85.013882,
      "city": "Perry Park",
      "state": "KY",
      "county": "Owen"
  },
  {
      "zipCode": 40366,
      "latitude": 38.086673,
      "longitude": -83.757123,
      "city": "Preston",
      "state": "KY",
      "county": "Bath"
  },
  {
      "zipCode": 40370,
      "latitude": 38.383618,
      "longitude": -84.553016,
      "city": "Sadieville",
      "state": "KY",
      "county": "Scott"
  },
  {
      "zipCode": 40371,
      "latitude": 38.10437,
      "longitude": -83.621715,
      "city": "Salt Lick",
      "state": "KY",
      "county": "Bath"
  },
  {
      "zipCode": 40372,
      "latitude": 37.866351,
      "longitude": -84.884036,
      "city": "Salvisa",
      "state": "KY",
      "county": "Mercer"
  },
  {
      "zipCode": 40374,
      "latitude": 38.228942,
      "longitude": -83.88438,
      "city": "Sharpsburg",
      "state": "KY",
      "county": "Bath"
  },
  {
      "zipCode": 40376,
      "latitude": 37.816074,
      "longitude": -83.693949,
      "city": "Slade",
      "state": "KY",
      "county": "Powell"
  },
  {
      "zipCode": 40379,
      "latitude": 38.306329,
      "longitude": -84.676123,
      "city": "Stamping Ground",
      "state": "KY",
      "county": "Scott"
  },
  {
      "zipCode": 40380,
      "latitude": 37.822307,
      "longitude": -83.785309,
      "city": "Stanton",
      "state": "KY",
      "county": "Powell"
  },
  {
      "zipCode": 40383,
      "latitude": 37.998762,
      "longitude": -84.737666,
      "city": "Versailles",
      "state": "KY",
      "county": "Woodford"
  },
  {
      "zipCode": 40384,
      "latitude": 38.021292,
      "longitude": -84.745488,
      "city": "Versailles",
      "state": "KY",
      "county": "Woodford"
  },
  {
      "zipCode": 40385,
      "latitude": 37.717603,
      "longitude": -84.14483,
      "city": "Waco",
      "state": "KY",
      "county": "Madison"
  },
  {
      "zipCode": 40386,
      "latitude": 38.021292,
      "longitude": -84.745488,
      "city": "Versailles",
      "state": "KY",
      "county": "Woodford"
  },
  {
      "zipCode": 40387,
      "latitude": 37.978036,
      "longitude": -83.467497,
      "city": "Wellington",
      "state": "KY",
      "county": "Menifee"
  },
  {
      "zipCode": 40390,
      "latitude": 37.860199,
      "longitude": -84.671365,
      "city": "Wilmore",
      "state": "KY",
      "county": "Jessamine"
  },
  {
      "zipCode": 40391,
      "latitude": 37.964922,
      "longitude": -84.160724,
      "city": "Winchester",
      "state": "KY",
      "county": "Clark"
  },
  {
      "zipCode": 40392,
      "latitude": 37.968496,
      "longitude": -84.157766,
      "city": "Winchester",
      "state": "KY",
      "county": "Clark"
  },
  {
      "zipCode": 40402,
      "latitude": 37.305202,
      "longitude": -83.971127,
      "city": "Annville",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40403,
      "latitude": 37.592589,
      "longitude": -84.235247,
      "city": "Berea",
      "state": "KY",
      "county": "Madison"
  },
  {
      "zipCode": 40404,
      "latitude": 37.716443,
      "longitude": -84.299699,
      "city": "Berea",
      "state": "KY",
      "county": "Madison"
  },
  {
      "zipCode": 40405,
      "latitude": 37.716443,
      "longitude": -84.299699,
      "city": "Bighill",
      "state": "KY",
      "county": "Madison"
  },
  {
      "zipCode": 40409,
      "latitude": 37.373811,
      "longitude": -84.412242,
      "city": "Brodhead",
      "state": "KY",
      "county": "Rockcastle"
  },
  {
      "zipCode": 40410,
      "latitude": 37.651397,
      "longitude": -84.546693,
      "city": "Bryantsville",
      "state": "KY",
      "county": "Garrard"
  },
  {
      "zipCode": 40419,
      "latitude": 37.43221,
      "longitude": -84.517065,
      "city": "Crab Orchard",
      "state": "KY",
      "county": "Lincoln"
  },
  {
      "zipCode": 40421,
      "latitude": 37.416008,
      "longitude": -83.993588,
      "city": "Dabolt",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40422,
      "latitude": 37.636006,
      "longitude": -84.849682,
      "city": "Danville",
      "state": "KY",
      "county": "Boyle"
  },
  {
      "zipCode": 40423,
      "latitude": 37.624659,
      "longitude": -84.845767,
      "city": "Danville",
      "state": "KY",
      "county": "Boyle"
  },
  {
      "zipCode": 40434,
      "latitude": 37.395468,
      "longitude": -83.908492,
      "city": "Gray Hawk",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40437,
      "latitude": 37.443361,
      "longitude": -84.767288,
      "city": "Hustonville",
      "state": "KY",
      "county": "Lincoln"
  },
  {
      "zipCode": 40440,
      "latitude": 37.581754,
      "longitude": -84.846678,
      "city": "Junction City",
      "state": "KY",
      "county": "Boyle"
  },
  {
      "zipCode": 40442,
      "latitude": 37.362372,
      "longitude": -84.695425,
      "city": "Kings Mountain",
      "state": "KY",
      "county": "Lincoln"
  },
  {
      "zipCode": 40444,
      "latitude": 37.683184,
      "longitude": -84.583605,
      "city": "Lancaster",
      "state": "KY",
      "county": "Garrard"
  },
  {
      "zipCode": 40445,
      "latitude": 37.290742,
      "longitude": -84.213618,
      "city": "Livingston",
      "state": "KY",
      "county": "Rockcastle"
  },
  {
      "zipCode": 40446,
      "latitude": 37.651397,
      "longitude": -84.546693,
      "city": "Lancaster",
      "state": "KY",
      "county": "Garrard"
  },
  {
      "zipCode": 40447,
      "latitude": 37.433778,
      "longitude": -84.025131,
      "city": "Mc Kee",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40448,
      "latitude": 37.445989,
      "longitude": -84.699802,
      "city": "Mc Kinney",
      "state": "KY",
      "county": "Lincoln"
  },
  {
      "zipCode": 40452,
      "latitude": 37.603345,
      "longitude": -84.94918,
      "city": "Mitchellsburg",
      "state": "KY",
      "county": "Boyle"
  },
  {
      "zipCode": 40456,
      "latitude": 37.398303,
      "longitude": -84.337882,
      "city": "Mount Vernon",
      "state": "KY",
      "county": "Rockcastle"
  },
  {
      "zipCode": 40460,
      "latitude": 37.345751,
      "longitude": -84.31614,
      "city": "Orlando",
      "state": "KY",
      "county": "Rockcastle"
  },
  {
      "zipCode": 40461,
      "latitude": 37.593625,
      "longitude": -84.426456,
      "city": "Paint Lick",
      "state": "KY",
      "county": "Garrard"
  },
  {
      "zipCode": 40464,
      "latitude": 37.571105,
      "longitude": -84.924963,
      "city": "Parksville",
      "state": "KY",
      "county": "Boyle"
  },
  {
      "zipCode": 40467,
      "latitude": 37.416008,
      "longitude": -83.993588,
      "city": "Peoples",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40468,
      "latitude": 37.637433,
      "longitude": -84.97602,
      "city": "Perryville",
      "state": "KY",
      "county": "Boyle"
  },
  {
      "zipCode": 40472,
      "latitude": 37.713915,
      "longitude": -83.856874,
      "city": "Ravenna",
      "state": "KY",
      "county": "Estill"
  },
  {
      "zipCode": 40473,
      "latitude": 37.345751,
      "longitude": -84.31614,
      "city": "Renfro Valley",
      "state": "KY",
      "county": "Rockcastle"
  },
  {
      "zipCode": 40475,
      "latitude": 37.764867,
      "longitude": -84.296913,
      "city": "Richmond",
      "state": "KY",
      "county": "Madison"
  },
  {
      "zipCode": 40476,
      "latitude": 37.716443,
      "longitude": -84.299699,
      "city": "Richmond",
      "state": "KY",
      "county": "Madison"
  },
  {
      "zipCode": 40481,
      "latitude": 37.46592,
      "longitude": -84.059717,
      "city": "Sandgap",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40484,
      "latitude": 37.522046,
      "longitude": -84.684467,
      "city": "Stanford",
      "state": "KY",
      "county": "Lincoln"
  },
  {
      "zipCode": 40486,
      "latitude": 37.357587,
      "longitude": -83.930007,
      "city": "Tyner",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40488,
      "latitude": 37.47477,
      "longitude": -84.042748,
      "city": "Waneta",
      "state": "KY",
      "county": "Jackson"
  },
  {
      "zipCode": 40489,
      "latitude": 37.377689,
      "longitude": -84.620613,
      "city": "Waynesburg",
      "state": "KY",
      "county": "Lincoln"
  },
  {
      "zipCode": 40492,
      "latitude": 37.345751,
      "longitude": -84.31614,
      "city": "Wildie",
      "state": "KY",
      "county": "Rockcastle"
  },
  {
      "zipCode": 40495,
      "latitude": 37.709721,
      "longitude": -84.082894,
      "city": "Winston",
      "state": "KY",
      "county": "Estill"
  },
  {
      "zipCode": 40502,
      "latitude": 38.016674,
      "longitude": -84.486043,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40503,
      "latitude": 38.002578,
      "longitude": -84.539437,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40504,
      "latitude": 38.038613,
      "longitude": -84.539422,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40505,
      "latitude": 38.060845,
      "longitude": -84.462937,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40506,
      "latitude": 38.028712,
      "longitude": -84.507471,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40507,
      "latitude": 38.045571,
      "longitude": -84.495969,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40508,
      "latitude": 38.051285,
      "longitude": -84.498954,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40509,
      "latitude": 37.986629,
      "longitude": -84.372883,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40510,
      "latitude": 38.06146,
      "longitude": -84.594901,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40511,
      "latitude": 38.132747,
      "longitude": -84.492226,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40512,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40513,
      "latitude": 38.01513,
      "longitude": -84.605827,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40514,
      "latitude": 37.982677,
      "longitude": -84.560827,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40515,
      "latitude": 37.924774,
      "longitude": -84.437207,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40516,
      "latitude": 38.064867,
      "longitude": -84.430752,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40517,
      "latitude": 37.986751,
      "longitude": -84.487251,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40522,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40523,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40524,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40526,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40533,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40536,
      "latitude": 38.032075,
      "longitude": -84.508361,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40544,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40546,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40550,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40555,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40574,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40575,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40576,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40577,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40578,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40579,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40580,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40581,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40582,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40583,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40584,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40585,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40586,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40587,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40588,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40589,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40590,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40591,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40592,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40593,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40594,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40595,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40596,
      "latitude": 38.028269,
      "longitude": -84.471505,
      "city": "Lexington",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 40601,
      "latitude": 38.228115,
      "longitude": -84.869708,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40602,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40603,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40604,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40618,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40619,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40620,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40621,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40622,
      "latitude": 38.234133,
      "longitude": -84.874809,
      "city": "Frankfort",
      "state": "KY",
      "county": "Franklin"
  },
  {
      "zipCode": 40701,
      "latitude": 36.854371,
      "longitude": -84.184688,
      "city": "Corbin",
      "state": "KY",
      "county": "Whitley"
  },
  {
      "zipCode": 40702,
      "latitude": 36.937426,
      "longitude": -84.1031,
      "city": "Corbin",
      "state": "KY",
      "county": "Whitley"
  },
  {
      "zipCode": 40724,
      "latitude": 37.152074,
      "longitude": -84.140847,
      "city": "Bush",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40729,
      "latitude": 37.248761,
      "longitude": -84.13796,
      "city": "East Bernstadt",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40730,
      "latitude": 36.703868,
      "longitude": -84.141486,
      "city": "Emlyn",
      "state": "KY",
      "county": "Whitley"
  },
  {
      "zipCode": 40734,
      "latitude": 36.936999,
      "longitude": -83.933848,
      "city": "Gray",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40737,
      "latitude": 36.997587,
      "longitude": -84.129021,
      "city": "Keavy",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40740,
      "latitude": 37.025647,
      "longitude": -84.028217,
      "city": "Lily",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40741,
      "latitude": 37.154931,
      "longitude": -84.096062,
      "city": "London",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40742,
      "latitude": 37.137615,
      "longitude": -84.115617,
      "city": "London",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40743,
      "latitude": 37.134455,
      "longitude": -84.045746,
      "city": "London",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40744,
      "latitude": 37.09949,
      "longitude": -84.113057,
      "city": "London",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40745,
      "latitude": 37.137615,
      "longitude": -84.115617,
      "city": "London",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40751,
      "latitude": 37.137615,
      "longitude": -84.115617,
      "city": "Marydell",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40754,
      "latitude": 36.780333,
      "longitude": -84.11678,
      "city": "Nevisdale",
      "state": "KY",
      "county": "Whitley"
  },
  {
      "zipCode": 40755,
      "latitude": 37.167752,
      "longitude": -84.116614,
      "city": "Pittsburg",
      "state": "KY",
      "county": "Laurel"
  },
  {
      "zipCode": 40759,
      "latitude": 36.832568,
      "longitude": -84.070522,
      "city": "Rockholds",
      "state": "KY",
      "county": "Whitley"
  },
  {
      "zipCode": 40763,
      "latitude": 36.702504,
      "longitude": -83.955033,
      "city": "Siler",
      "state": "KY",
      "county": "Whitley"
  },
  {
      "zipCode": 40769,
      "latitude": 36.747032,
      "longitude": -84.139406,
      "city": "Williamsburg",
      "state": "KY",
      "county": "Whitley"
  },
  {
      "zipCode": 40771,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Woodbine",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40801,
      "latitude": 36.865351,
      "longitude": -83.287927,
      "city": "Ages Brookside",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40803,
      "latitude": 37.011357,
      "longitude": -83.4553,
      "city": "Asher",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40806,
      "latitude": 36.879484,
      "longitude": -83.304537,
      "city": "Baxter",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40807,
      "latitude": 36.971157,
      "longitude": -82.955273,
      "city": "Benham",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40808,
      "latitude": 36.984102,
      "longitude": -83.206701,
      "city": "Big Laurel",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40810,
      "latitude": 36.934041,
      "longitude": -83.324159,
      "city": "Bledsoe",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40813,
      "latitude": 36.714629,
      "longitude": -83.64046,
      "city": "Calvin",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40815,
      "latitude": 36.781218,
      "longitude": -83.291362,
      "city": "Cawood",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40816,
      "latitude": 37.009799,
      "longitude": -83.349418,
      "city": "Chappell",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40818,
      "latitude": 36.789038,
      "longitude": -83.264806,
      "city": "Coalgood",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40819,
      "latitude": 36.820072,
      "longitude": -83.470712,
      "city": "Coldiron",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40820,
      "latitude": 36.847188,
      "longitude": -83.170137,
      "city": "Cranks",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40823,
      "latitude": 36.912209,
      "longitude": -83.163759,
      "city": "Cumberland",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40824,
      "latitude": 36.835555,
      "longitude": -83.375307,
      "city": "Dayhoit",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40826,
      "latitude": 37.043638,
      "longitude": -82.809267,
      "city": "Eolia",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 40827,
      "latitude": 37.054676,
      "longitude": -83.456094,
      "city": "Essie",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40828,
      "latitude": 36.839806,
      "longitude": -83.223251,
      "city": "Evarts",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40829,
      "latitude": 36.797447,
      "longitude": -83.264497,
      "city": "Grays Knob",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40830,
      "latitude": 36.77011,
      "longitude": -83.330675,
      "city": "Gulston",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40831,
      "latitude": 36.861519,
      "longitude": -83.203025,
      "city": "Harlan",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40840,
      "latitude": 36.954292,
      "longitude": -83.431346,
      "city": "Helton",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40843,
      "latitude": 36.876106,
      "longitude": -82.988157,
      "city": "Holmes Mill",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40844,
      "latitude": 37.070198,
      "longitude": -83.424395,
      "city": "Hoskinston",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40845,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Hulen",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40847,
      "latitude": 36.87338,
      "longitude": -83.145061,
      "city": "Kenvir",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40849,
      "latitude": 36.889879,
      "longitude": -83.14331,
      "city": "Lejunior",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40854,
      "latitude": 36.848097,
      "longitude": -83.353034,
      "city": "Loyall",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40855,
      "latitude": 36.960303,
      "longitude": -82.919767,
      "city": "Lynch",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40856,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Miracle",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40858,
      "latitude": 36.998854,
      "longitude": -83.394253,
      "city": "Mozelle",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40862,
      "latitude": 37.012888,
      "longitude": -82.879541,
      "city": "Partridge",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 40863,
      "latitude": 36.74962,
      "longitude": -83.45935,
      "city": "Pathfork",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40865,
      "latitude": 36.903881,
      "longitude": -83.238139,
      "city": "Putney",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40868,
      "latitude": 37.08308,
      "longitude": -83.419745,
      "city": "Stinnett",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40870,
      "latitude": 36.932991,
      "longitude": -83.161655,
      "city": "Totz",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40873,
      "latitude": 36.817164,
      "longitude": -83.419056,
      "city": "Wallins Creek",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40874,
      "latitude": 36.98397,
      "longitude": -83.466672,
      "city": "Warbranch",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40902,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Arjay",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40903,
      "latitude": 36.839539,
      "longitude": -83.850573,
      "city": "Artemus",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40906,
      "latitude": 36.810109,
      "longitude": -83.894888,
      "city": "Barbourville",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40913,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Beverly",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40914,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Big Creek",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40915,
      "latitude": 36.908172,
      "longitude": -83.789161,
      "city": "Bimble",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40921,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Bryants Store",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40923,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Cannon",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40927,
      "latitude": 36.88452,
      "longitude": -83.030287,
      "city": "Closplint",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40930,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Dewitt",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40931,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Eriline",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40932,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Fall Rock",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40935,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Flat Lick",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40939,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Fourmile",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40940,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Frakes",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40941,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Garrard",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40943,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Girdler",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40944,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Goose Rock",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40946,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Green Road",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40949,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Heidrick",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40951,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Hima",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40953,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Hinkle",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40955,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Ingram",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40958,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Kettle Island",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40962,
      "latitude": 37.151101,
      "longitude": -83.779273,
      "city": "Manchester",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40964,
      "latitude": 36.842977,
      "longitude": -83.183446,
      "city": "Mary Alice",
      "state": "KY",
      "county": "Harlan"
  },
  {
      "zipCode": 40965,
      "latitude": 36.669566,
      "longitude": -83.693159,
      "city": "Middlesboro",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40972,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Oneida",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40977,
      "latitude": 36.715863,
      "longitude": -83.766776,
      "city": "Pineville",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40979,
      "latitude": 37.043772,
      "longitude": -83.494862,
      "city": "Roark",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 40981,
      "latitude": 37.331259,
      "longitude": -83.386991,
      "city": "Saul",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 40982,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Scalf",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40983,
      "latitude": 37.147687,
      "longitude": -83.737014,
      "city": "Sextons Creek",
      "state": "KY",
      "county": "Clay"
  },
  {
      "zipCode": 40988,
      "latitude": 36.769184,
      "longitude": -83.708181,
      "city": "Stoney Fork",
      "state": "KY",
      "county": "Bell"
  },
  {
      "zipCode": 40995,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Trosper",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40997,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Walker",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 40999,
      "latitude": 36.871254,
      "longitude": -83.829985,
      "city": "Woollum",
      "state": "KY",
      "county": "Knox"
  },
  {
      "zipCode": 41001,
      "latitude": 38.921584,
      "longitude": -84.374584,
      "city": "Alexandria",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41002,
      "latitude": 38.72342,
      "longitude": -83.982911,
      "city": "Augusta",
      "state": "KY",
      "county": "Bracken"
  },
  {
      "zipCode": 41003,
      "latitude": 38.442076,
      "longitude": -84.347201,
      "city": "Berry",
      "state": "KY",
      "county": "Harrison"
  },
  {
      "zipCode": 41004,
      "latitude": 38.638654,
      "longitude": -84.093504,
      "city": "Brooksville",
      "state": "KY",
      "county": "Bracken"
  },
  {
      "zipCode": 41005,
      "latitude": 39.015027,
      "longitude": -84.773564,
      "city": "Burlington",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41006,
      "latitude": 38.739446,
      "longitude": -84.392841,
      "city": "Butler",
      "state": "KY",
      "county": "Pendleton"
  },
  {
      "zipCode": 41007,
      "latitude": 38.905563,
      "longitude": -84.317079,
      "city": "California",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41008,
      "latitude": 38.663866,
      "longitude": -85.098906,
      "city": "Carrollton",
      "state": "KY",
      "county": "Carroll"
  },
  {
      "zipCode": 41010,
      "latitude": 38.552305,
      "longitude": -84.611567,
      "city": "Corinth",
      "state": "KY",
      "county": "Grant"
  },
  {
      "zipCode": 41011,
      "latitude": 39.05906,
      "longitude": -84.542748,
      "city": "Covington",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41012,
      "latitude": 38.944663,
      "longitude": -84.520501,
      "city": "Covington",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41014,
      "latitude": 39.064211,
      "longitude": -84.511793,
      "city": "Covington",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41015,
      "latitude": 38.94711,
      "longitude": -84.490048,
      "city": "Latonia",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41016,
      "latitude": 39.082839,
      "longitude": -84.574749,
      "city": "Covington",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41017,
      "latitude": 39.029983,
      "longitude": -84.55902,
      "city": "Ft Mitchell",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41018,
      "latitude": 39.019684,
      "longitude": -84.591389,
      "city": "Erlanger",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41019,
      "latitude": 38.944663,
      "longitude": -84.520501,
      "city": "Covington",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41022,
      "latitude": 38.962372,
      "longitude": -84.747789,
      "city": "Florence",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41030,
      "latitude": 38.770542,
      "longitude": -84.597256,
      "city": "Crittenden",
      "state": "KY",
      "county": "Grant"
  },
  {
      "zipCode": 41031,
      "latitude": 38.356954,
      "longitude": -84.323033,
      "city": "Cynthiana",
      "state": "KY",
      "county": "Harrison"
  },
  {
      "zipCode": 41033,
      "latitude": 38.762312,
      "longitude": -84.485907,
      "city": "De Mossville",
      "state": "KY",
      "county": "Pendleton"
  },
  {
      "zipCode": 41034,
      "latitude": 38.707093,
      "longitude": -83.904101,
      "city": "Dover",
      "state": "KY",
      "county": "Mason"
  },
  {
      "zipCode": 41035,
      "latitude": 38.691789,
      "longitude": -84.650966,
      "city": "Dry Ridge",
      "state": "KY",
      "county": "Grant"
  },
  {
      "zipCode": 41037,
      "latitude": 38.3481,
      "longitude": -83.718626,
      "city": "Elizaville",
      "state": "KY",
      "county": "Fleming"
  },
  {
      "zipCode": 41039,
      "latitude": 38.3481,
      "longitude": -83.718626,
      "city": "Ewing",
      "state": "KY",
      "county": "Fleming"
  },
  {
      "zipCode": 41040,
      "latitude": 38.637848,
      "longitude": -84.318142,
      "city": "Falmouth",
      "state": "KY",
      "county": "Pendleton"
  },
  {
      "zipCode": 41041,
      "latitude": 38.438363,
      "longitude": -83.732284,
      "city": "Flemingsburg",
      "state": "KY",
      "county": "Fleming"
  },
  {
      "zipCode": 41042,
      "latitude": 38.989548,
      "longitude": -84.656711,
      "city": "Florence",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41043,
      "latitude": 38.766691,
      "longitude": -84.171226,
      "city": "Foster",
      "state": "KY",
      "county": "Bracken"
  },
  {
      "zipCode": 41044,
      "latitude": 38.635744,
      "longitude": -83.989965,
      "city": "Germantown",
      "state": "KY",
      "county": "Bracken"
  },
  {
      "zipCode": 41045,
      "latitude": 38.715638,
      "longitude": -85.047073,
      "city": "Ghent",
      "state": "KY",
      "county": "Carroll"
  },
  {
      "zipCode": 41046,
      "latitude": 38.732647,
      "longitude": -84.805495,
      "city": "Glencoe",
      "state": "KY",
      "county": "Gallatin"
  },
  {
      "zipCode": 41048,
      "latitude": 39.086656,
      "longitude": -84.712389,
      "city": "Hebron",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41049,
      "latitude": 38.237176,
      "longitude": -83.584351,
      "city": "Hillsboro",
      "state": "KY",
      "county": "Fleming"
  },
  {
      "zipCode": 41051,
      "latitude": 38.923041,
      "longitude": -84.54745,
      "city": "Independence",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41052,
      "latitude": 38.658689,
      "longitude": -84.750673,
      "city": "Jonesville",
      "state": "KY",
      "county": "Grant"
  },
  {
      "zipCode": 41053,
      "latitude": 38.944663,
      "longitude": -84.520501,
      "city": "Kenton",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41054,
      "latitude": 38.593226,
      "longitude": -84.578552,
      "city": "Mason",
      "state": "KY",
      "county": "Grant"
  },
  {
      "zipCode": 41055,
      "latitude": 38.522012,
      "longitude": -83.860969,
      "city": "Mayslick",
      "state": "KY",
      "county": "Mason"
  },
  {
      "zipCode": 41056,
      "latitude": 38.620653,
      "longitude": -83.806657,
      "city": "Maysville",
      "state": "KY",
      "county": "Mason"
  },
  {
      "zipCode": 41059,
      "latitude": 39.003401,
      "longitude": -84.35698,
      "city": "Melbourne",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41061,
      "latitude": 38.688556,
      "longitude": -84.070074,
      "city": "Milford",
      "state": "KY",
      "county": "Bracken"
  },
  {
      "zipCode": 41062,
      "latitude": 38.719573,
      "longitude": -83.910276,
      "city": "Minerva",
      "state": "KY",
      "county": "Mason"
  },
  {
      "zipCode": 41063,
      "latitude": 38.866297,
      "longitude": -84.517548,
      "city": "Morning View",
      "state": "KY",
      "county": "Kenton"
  },
  {
      "zipCode": 41064,
      "latitude": 38.531698,
      "longitude": -84.035565,
      "city": "Mount Olivet",
      "state": "KY",
      "county": "Robertson"
  },
  {
      "zipCode": 41065,
      "latitude": 38.3481,
      "longitude": -83.718626,
      "city": "Muses Mills",
      "state": "KY",
      "county": "Fleming"
  },
  {
      "zipCode": 41071,
      "latitude": 39.056261,
      "longitude": -84.478742,
      "city": "Newport",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41072,
      "latitude": 38.963761,
      "longitude": -84.368926,
      "city": "Newport",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41073,
      "latitude": 39.10441,
      "longitude": -84.473642,
      "city": "Bellevue",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41074,
      "latitude": 39.057835,
      "longitude": -84.396404,
      "city": "Dayton",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41075,
      "latitude": 39.080411,
      "longitude": -84.453991,
      "city": "Fort Thomas",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41076,
      "latitude": 39.029015,
      "longitude": -84.440757,
      "city": "Newport",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41080,
      "latitude": 39.039096,
      "longitude": -84.837832,
      "city": "Petersburg",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41081,
      "latitude": 38.3481,
      "longitude": -83.718626,
      "city": "Plummers Landing",
      "state": "KY",
      "county": "Fleming"
  },
  {
      "zipCode": 41083,
      "latitude": 38.660372,
      "longitude": -85.005783,
      "city": "Sanders",
      "state": "KY",
      "county": "Carroll"
  },
  {
      "zipCode": 41085,
      "latitude": 39.034045,
      "longitude": -84.388143,
      "city": "Silver Grove",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41086,
      "latitude": 38.731246,
      "longitude": -84.895823,
      "city": "Sparta",
      "state": "KY",
      "county": "Gallatin"
  },
  {
      "zipCode": 41091,
      "latitude": 38.921583,
      "longitude": -84.746057,
      "city": "Union",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41092,
      "latitude": 38.824585,
      "longitude": -84.692136,
      "city": "Verona",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41093,
      "latitude": 38.3481,
      "longitude": -83.718626,
      "city": "Wallingford",
      "state": "KY",
      "county": "Fleming"
  },
  {
      "zipCode": 41094,
      "latitude": 38.88142,
      "longitude": -84.665597,
      "city": "Walton",
      "state": "KY",
      "county": "Boone"
  },
  {
      "zipCode": 41095,
      "latitude": 38.762505,
      "longitude": -84.877785,
      "city": "Warsaw",
      "state": "KY",
      "county": "Gallatin"
  },
  {
      "zipCode": 41096,
      "latitude": 38.61225,
      "longitude": -83.808021,
      "city": "Washington",
      "state": "KY",
      "county": "Mason"
  },
  {
      "zipCode": 41097,
      "latitude": 38.654385,
      "longitude": -84.603041,
      "city": "Williamstown",
      "state": "KY",
      "county": "Grant"
  },
  {
      "zipCode": 41098,
      "latitude": 38.637181,
      "longitude": -85.047765,
      "city": "Worthville",
      "state": "KY",
      "county": "Carroll"
  },
  {
      "zipCode": 41099,
      "latitude": 38.963761,
      "longitude": -84.368926,
      "city": "Newport",
      "state": "KY",
      "county": "Campbell"
  },
  {
      "zipCode": 41101,
      "latitude": 38.43296,
      "longitude": -82.693914,
      "city": "Ashland",
      "state": "KY",
      "county": "Boyd"
  },
  {
      "zipCode": 41102,
      "latitude": 38.421818,
      "longitude": -82.717284,
      "city": "Ashland",
      "state": "KY",
      "county": "Boyd"
  },
  {
      "zipCode": 41105,
      "latitude": 38.370285,
      "longitude": -82.694757,
      "city": "Ashland",
      "state": "KY",
      "county": "Boyd"
  },
  {
      "zipCode": 41114,
      "latitude": 38.370285,
      "longitude": -82.694757,
      "city": "Ashland",
      "state": "KY",
      "county": "Boyd"
  },
  {
      "zipCode": 41121,
      "latitude": 38.511714,
      "longitude": -82.872362,
      "city": "Argillite",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41124,
      "latitude": 38.074113,
      "longitude": -82.744663,
      "city": "Blaine",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41127,
      "latitude": 38.602881,
      "longitude": -83.361625,
      "city": "Camp Dix",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41128,
      "latitude": 38.433912,
      "longitude": -83.133597,
      "city": "Carter",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41129,
      "latitude": 38.349127,
      "longitude": -82.649833,
      "city": "Catlettsburg",
      "state": "KY",
      "county": "Boyd"
  },
  {
      "zipCode": 41132,
      "latitude": 38.259701,
      "longitude": -82.856231,
      "city": "Denton",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41135,
      "latitude": 38.521926,
      "longitude": -83.335329,
      "city": "Emerson",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41137,
      "latitude": 38.521926,
      "longitude": -83.335329,
      "city": "Firebrick",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41139,
      "latitude": 38.50316,
      "longitude": -82.728176,
      "city": "Flatwoods",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41141,
      "latitude": 38.521926,
      "longitude": -83.335329,
      "city": "Garrison",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41142,
      "latitude": 38.28974,
      "longitude": -83.081077,
      "city": "Grahn",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41143,
      "latitude": 38.324893,
      "longitude": -83.000742,
      "city": "Grayson",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41144,
      "latitude": 38.617863,
      "longitude": -82.839401,
      "city": "Greenup",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41146,
      "latitude": 38.271432,
      "longitude": -83.061941,
      "city": "Hitchins",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41149,
      "latitude": 38.131568,
      "longitude": -83.079708,
      "city": "Isonville",
      "state": "KY",
      "county": "Elliott"
  },
  {
      "zipCode": 41150,
      "latitude": 38.225087,
      "longitude": -83.242129,
      "city": "Jacobs",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41156,
      "latitude": 38.565211,
      "longitude": -82.916586,
      "city": "Lloyd",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41159,
      "latitude": 38.074113,
      "longitude": -82.744663,
      "city": "Martha",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41160,
      "latitude": 38.074113,
      "longitude": -82.744663,
      "city": "Mazie",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41164,
      "latitude": 38.349219,
      "longitude": -83.177987,
      "city": "Olive Hill",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41166,
      "latitude": 38.521926,
      "longitude": -83.335329,
      "city": "Quincy",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41168,
      "latitude": 38.30911,
      "longitude": -82.717666,
      "city": "Rush",
      "state": "KY",
      "county": "Boyd"
  },
  {
      "zipCode": 41169,
      "latitude": 38.607408,
      "longitude": -82.827053,
      "city": "Russell",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41170,
      "latitude": 38.521926,
      "longitude": -83.335329,
      "city": "Saint Paul",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41171,
      "latitude": 38.131568,
      "longitude": -83.079708,
      "city": "Sandy Hook",
      "state": "KY",
      "county": "Elliott"
  },
  {
      "zipCode": 41173,
      "latitude": 38.262029,
      "longitude": -83.28472,
      "city": "Soldier",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41174,
      "latitude": 38.565211,
      "longitude": -82.916586,
      "city": "South Portsmouth",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41175,
      "latitude": 38.714809,
      "longitude": -82.936626,
      "city": "South Shore",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41179,
      "latitude": 38.469945,
      "longitude": -83.270248,
      "city": "Vanceburg",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41180,
      "latitude": 38.190296,
      "longitude": -82.882533,
      "city": "Webbville",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41181,
      "latitude": 38.204459,
      "longitude": -82.907615,
      "city": "Willard",
      "state": "KY",
      "county": "Carter"
  },
  {
      "zipCode": 41183,
      "latitude": 38.546615,
      "longitude": -82.727678,
      "city": "Worthington",
      "state": "KY",
      "county": "Greenup"
  },
  {
      "zipCode": 41189,
      "latitude": 38.521926,
      "longitude": -83.335329,
      "city": "Tollesboro",
      "state": "KY",
      "county": "Lewis"
  },
  {
      "zipCode": 41201,
      "latitude": 38.074113,
      "longitude": -82.744663,
      "city": "Adams",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41203,
      "latitude": 37.761149,
      "longitude": -82.622288,
      "city": "Beauty",
      "state": "KY",
      "county": "Martin"
  },
  {
      "zipCode": 41204,
      "latitude": 37.822653,
      "longitude": -82.694671,
      "city": "Boons Camp",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41214,
      "latitude": 37.800137,
      "longitude": -82.559438,
      "city": "Debord",
      "state": "KY",
      "county": "Martin"
  },
  {
      "zipCode": 41215,
      "latitude": 37.771963,
      "longitude": -82.865092,
      "city": "Denver",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41216,
      "latitude": 37.757935,
      "longitude": -82.818187,
      "city": "East Point",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41219,
      "latitude": 37.923134,
      "longitude": -82.922077,
      "city": "Flatgap",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41222,
      "latitude": 37.780451,
      "longitude": -82.841565,
      "city": "Hagerhill",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41224,
      "latitude": 37.8754,
      "longitude": -82.533718,
      "city": "Inez",
      "state": "KY",
      "county": "Martin"
  },
  {
      "zipCode": 41226,
      "latitude": 37.973831,
      "longitude": -82.966183,
      "city": "Keaton",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41228,
      "latitude": 37.739663,
      "longitude": -82.865598,
      "city": "Leander",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41230,
      "latitude": 38.104327,
      "longitude": -82.605591,
      "city": "Louisa",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41231,
      "latitude": 37.815648,
      "longitude": -82.492731,
      "city": "Lovely",
      "state": "KY",
      "county": "Martin"
  },
  {
      "zipCode": 41232,
      "latitude": 38.074113,
      "longitude": -82.744663,
      "city": "Lowmansville",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41234,
      "latitude": 37.807776,
      "longitude": -82.741549,
      "city": "Meally",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41238,
      "latitude": 37.812772,
      "longitude": -82.939108,
      "city": "Oil Springs",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41240,
      "latitude": 37.824167,
      "longitude": -82.794504,
      "city": "Paintsville",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41250,
      "latitude": 37.815648,
      "longitude": -82.492731,
      "city": "Pilgrim",
      "state": "KY",
      "county": "Martin"
  },
  {
      "zipCode": 41254,
      "latitude": 37.879486,
      "longitude": -82.725052,
      "city": "River",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41255,
      "latitude": 37.920063,
      "longitude": -82.832532,
      "city": "Sitka",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41256,
      "latitude": 37.825284,
      "longitude": -82.882251,
      "city": "Staffordsville",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41257,
      "latitude": 37.916598,
      "longitude": -82.803427,
      "city": "Stambaugh",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41260,
      "latitude": 37.810703,
      "longitude": -82.766705,
      "city": "Thelma",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41262,
      "latitude": 37.825114,
      "longitude": -82.645668,
      "city": "Tomahawk",
      "state": "KY",
      "county": "Martin"
  },
  {
      "zipCode": 41263,
      "latitude": 37.850698,
      "longitude": -82.760226,
      "city": "Tutor Key",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41264,
      "latitude": 38.074113,
      "longitude": -82.744663,
      "city": "Ulysses",
      "state": "KY",
      "county": "Lawrence"
  },
  {
      "zipCode": 41265,
      "latitude": 37.784196,
      "longitude": -82.740121,
      "city": "Van Lear",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41267,
      "latitude": 37.815648,
      "longitude": -82.492731,
      "city": "Warfield",
      "state": "KY",
      "county": "Martin"
  },
  {
      "zipCode": 41268,
      "latitude": 37.797057,
      "longitude": -82.791012,
      "city": "West Van Lear",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41271,
      "latitude": 37.831277,
      "longitude": -82.726407,
      "city": "Williamsport",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41274,
      "latitude": 37.899527,
      "longitude": -82.781153,
      "city": "Wittensville",
      "state": "KY",
      "county": "Johnson"
  },
  {
      "zipCode": 41301,
      "latitude": 37.728737,
      "longitude": -83.494029,
      "city": "Campton",
      "state": "KY",
      "county": "Wolfe"
  },
  {
      "zipCode": 41307,
      "latitude": 37.515013,
      "longitude": -83.26432,
      "city": "Athol",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41310,
      "latitude": 37.640513,
      "longitude": -83.244088,
      "city": "Bays",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41311,
      "latitude": 37.599904,
      "longitude": -83.714013,
      "city": "Beattyville",
      "state": "KY",
      "county": "Lee"
  },
  {
      "zipCode": 41313,
      "latitude": 37.740503,
      "longitude": -83.475446,
      "city": "Bethany",
      "state": "KY",
      "county": "Wolfe"
  },
  {
      "zipCode": 41314,
      "latitude": 37.496523,
      "longitude": -83.657351,
      "city": "Booneville",
      "state": "KY",
      "county": "Owsley"
  },
  {
      "zipCode": 41317,
      "latitude": 37.462104,
      "longitude": -83.235959,
      "city": "Clayhole",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41332,
      "latitude": 37.798052,
      "longitude": -83.420976,
      "city": "Hazel Green",
      "state": "KY",
      "county": "Wolfe"
  },
  {
      "zipCode": 41333,
      "latitude": 37.556255,
      "longitude": -83.776253,
      "city": "Heidelberg",
      "state": "KY",
      "county": "Lee"
  },
  {
      "zipCode": 41338,
      "latitude": 37.398617,
      "longitude": -83.704908,
      "city": "Island City",
      "state": "KY",
      "county": "Owsley"
  },
  {
      "zipCode": 41339,
      "latitude": 37.486809,
      "longitude": -83.291331,
      "city": "Jackson",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41342,
      "latitude": 37.740503,
      "longitude": -83.475446,
      "city": "Lee City",
      "state": "KY",
      "county": "Wolfe"
  },
  {
      "zipCode": 41344,
      "latitude": 37.398617,
      "longitude": -83.704908,
      "city": "Lerose",
      "state": "KY",
      "county": "Owsley"
  },
  {
      "zipCode": 41347,
      "latitude": 37.54418,
      "longitude": -83.600784,
      "city": "Lone",
      "state": "KY",
      "county": "Lee"
  },
  {
      "zipCode": 41348,
      "latitude": 37.441782,
      "longitude": -83.297614,
      "city": "Lost Creek",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41351,
      "latitude": 37.398617,
      "longitude": -83.704908,
      "city": "Mistletoe",
      "state": "KY",
      "county": "Owsley"
  },
  {
      "zipCode": 41352,
      "latitude": 37.914002,
      "longitude": -83.233761,
      "city": "Mize",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41360,
      "latitude": 37.772774,
      "longitude": -83.631377,
      "city": "Pine Ridge",
      "state": "KY",
      "county": "Wolfe"
  },
  {
      "zipCode": 41362,
      "latitude": 37.60332,
      "longitude": -83.713974,
      "city": "Primrose",
      "state": "KY",
      "county": "Lee"
  },
  {
      "zipCode": 41364,
      "latitude": 37.387127,
      "longitude": -83.675179,
      "city": "Ricetown",
      "state": "KY",
      "county": "Owsley"
  },
  {
      "zipCode": 41365,
      "latitude": 37.715699,
      "longitude": -83.636349,
      "city": "Rogers",
      "state": "KY",
      "county": "Wolfe"
  },
  {
      "zipCode": 41366,
      "latitude": 37.593059,
      "longitude": -83.287774,
      "city": "Rousseau",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41367,
      "latitude": 37.397017,
      "longitude": -83.226964,
      "city": "Rowdy",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41368,
      "latitude": 37.611684,
      "longitude": -83.64341,
      "city": "Saint Helens",
      "state": "KY",
      "county": "Lee"
  },
  {
      "zipCode": 41377,
      "latitude": 37.417538,
      "longitude": -83.444462,
      "city": "Talbert",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41385,
      "latitude": 37.628827,
      "longitude": -83.360738,
      "city": "Vancleve",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41386,
      "latitude": 37.398617,
      "longitude": -83.704908,
      "city": "Vincent",
      "state": "KY",
      "county": "Owsley"
  },
  {
      "zipCode": 41390,
      "latitude": 37.421929,
      "longitude": -83.377999,
      "city": "Whick",
      "state": "KY",
      "county": "Breathitt"
  },
  {
      "zipCode": 41397,
      "latitude": 37.672317,
      "longitude": -83.687173,
      "city": "Zoe",
      "state": "KY",
      "county": "Lee"
  },
  {
      "zipCode": 41408,
      "latitude": 37.914002,
      "longitude": -83.233761,
      "city": "Cannel City",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41410,
      "latitude": 37.690636,
      "longitude": -83.074592,
      "city": "Cisco",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41413,
      "latitude": 37.970507,
      "longitude": -83.125202,
      "city": "Crockett",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41419,
      "latitude": 37.690636,
      "longitude": -83.074592,
      "city": "Edna",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41421,
      "latitude": 37.970781,
      "longitude": -83.11931,
      "city": "Elkfork",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41422,
      "latitude": 37.690636,
      "longitude": -83.074592,
      "city": "Elsie",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41425,
      "latitude": 37.914002,
      "longitude": -83.233761,
      "city": "Ezel",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41426,
      "latitude": 37.784255,
      "longitude": -83.010863,
      "city": "Falcon",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41433,
      "latitude": 37.690636,
      "longitude": -83.074592,
      "city": "Gapville",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41444,
      "latitude": 37.690636,
      "longitude": -83.074592,
      "city": "Ivyton",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41451,
      "latitude": 37.914002,
      "longitude": -83.233761,
      "city": "Malone",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41459,
      "latitude": 37.914002,
      "longitude": -83.233761,
      "city": "Ophir",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41464,
      "latitude": 37.676288,
      "longitude": -83.0225,
      "city": "Royalton",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41465,
      "latitude": 37.732536,
      "longitude": -83.029761,
      "city": "Salyersville",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41472,
      "latitude": 37.97621,
      "longitude": -83.198234,
      "city": "West Liberty",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41477,
      "latitude": 37.914002,
      "longitude": -83.233761,
      "city": "Wrigley",
      "state": "KY",
      "county": "Morgan"
  },
  {
      "zipCode": 41501,
      "latitude": 37.482901,
      "longitude": -82.447075,
      "city": "Pikeville",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41502,
      "latitude": 37.481637,
      "longitude": -82.550507,
      "city": "Pikeville",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41503,
      "latitude": 37.48871,
      "longitude": -82.310048,
      "city": "South Williamson",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41512,
      "latitude": 37.462655,
      "longitude": -82.448017,
      "city": "Ashcamp",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41513,
      "latitude": 37.34781,
      "longitude": -82.340599,
      "city": "Belcher",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41514,
      "latitude": 37.640727,
      "longitude": -82.313128,
      "city": "Belfry",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41517,
      "latitude": 37.184477,
      "longitude": -82.61109,
      "city": "Burdine",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41519,
      "latitude": 37.589934,
      "longitude": -82.330826,
      "city": "Canada",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41520,
      "latitude": 37.279027,
      "longitude": -82.57732,
      "city": "Dorton",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41522,
      "latitude": 37.42367,
      "longitude": -82.384689,
      "city": "Elkhorn City",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41524,
      "latitude": 37.426582,
      "longitude": -82.256227,
      "city": "Fedscreek",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41526,
      "latitude": 37.437194,
      "longitude": -82.518527,
      "city": "Fords Branch",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41527,
      "latitude": 37.630682,
      "longitude": -82.279522,
      "city": "Forest Hills",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41528,
      "latitude": 37.551774,
      "longitude": -82.129733,
      "city": "Freeburn",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41531,
      "latitude": 37.649157,
      "longitude": -82.264495,
      "city": "Hardy",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41534,
      "latitude": 37.281894,
      "longitude": -82.480755,
      "city": "Hellier",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41535,
      "latitude": 37.606484,
      "longitude": -82.288195,
      "city": "Huddy",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41537,
      "latitude": 37.191103,
      "longitude": -82.651342,
      "city": "Jenkins",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41538,
      "latitude": 37.470577,
      "longitude": -82.348837,
      "city": "Jonancy",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41539,
      "latitude": 37.411318,
      "longitude": -82.330705,
      "city": "Kimper",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41540,
      "latitude": 37.359184,
      "longitude": -82.309224,
      "city": "Lick Creek",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41542,
      "latitude": 37.314848,
      "longitude": -82.464979,
      "city": "Lookout",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41543,
      "latitude": 37.562421,
      "longitude": -82.280348,
      "city": "Mc Andrews",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41544,
      "latitude": 37.628873,
      "longitude": -82.226783,
      "city": "Mc Carr",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41546,
      "latitude": 37.519774,
      "longitude": -82.301252,
      "city": "Mc Veigh",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41547,
      "latitude": 37.531145,
      "longitude": -82.091605,
      "city": "Majestic",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41548,
      "latitude": 37.395235,
      "longitude": -82.348728,
      "city": "Mouthcard",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41549,
      "latitude": 37.470577,
      "longitude": -82.348837,
      "city": "Myra",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41553,
      "latitude": 37.541435,
      "longitude": -82.229946,
      "city": "Phelps",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41554,
      "latitude": 37.408938,
      "longitude": -82.424168,
      "city": "Phyllis",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41555,
      "latitude": 37.546621,
      "longitude": -82.262124,
      "city": "Pinsonfork",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41557,
      "latitude": 37.493468,
      "longitude": -82.422135,
      "city": "Raccoon",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41558,
      "latitude": 37.542575,
      "longitude": -82.208487,
      "city": "Ransom",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41559,
      "latitude": 37.370376,
      "longitude": -82.407751,
      "city": "Regina",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41560,
      "latitude": 37.379875,
      "longitude": -82.561632,
      "city": "Robinson Creek",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41561,
      "latitude": 37.33162,
      "longitude": -82.462574,
      "city": "Rockhouse",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41562,
      "latitude": 37.40628,
      "longitude": -82.467345,
      "city": "Shelbiana",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41563,
      "latitude": 37.231149,
      "longitude": -82.528583,
      "city": "Shelby Gap",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41564,
      "latitude": 37.557911,
      "longitude": -82.411486,
      "city": "Sidney",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41566,
      "latitude": 37.399777,
      "longitude": -82.197771,
      "city": "Steele",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41567,
      "latitude": 37.580715,
      "longitude": -82.27437,
      "city": "Stone",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41568,
      "latitude": 37.509279,
      "longitude": -82.078363,
      "city": "Stopover",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41569,
      "latitude": 37.608343,
      "longitude": -82.286675,
      "city": "Toler",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41571,
      "latitude": 37.621659,
      "longitude": -82.507019,
      "city": "Varney",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41572,
      "latitude": 37.319685,
      "longitude": -82.611355,
      "city": "Virgie",
      "state": "KY",
      "county": "Pike"
  },
  {
      "zipCode": 41601,
      "latitude": 37.479896,
      "longitude": -82.6769,
      "city": "Allen",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41602,
      "latitude": 37.715759,
      "longitude": -82.695892,
      "city": "Auxier",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41603,
      "latitude": 37.570682,
      "longitude": -82.680631,
      "city": "Banner",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41604,
      "latitude": 37.377377,
      "longitude": -82.686866,
      "city": "Beaver",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41605,
      "latitude": 37.54042,
      "longitude": -82.701035,
      "city": "Betsy Layne",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41606,
      "latitude": 37.346493,
      "longitude": -82.740255,
      "city": "Bevinsville",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41607,
      "latitude": 37.633196,
      "longitude": -82.740742,
      "city": "Blue River",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41612,
      "latitude": 37.346545,
      "longitude": -82.716529,
      "city": "Bypro",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41615,
      "latitude": 37.561699,
      "longitude": -82.684788,
      "city": "Dana",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41616,
      "latitude": 37.576668,
      "longitude": -82.870801,
      "city": "David",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41619,
      "latitude": 37.493261,
      "longitude": -82.75754,
      "city": "Drift",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41621,
      "latitude": 37.624553,
      "longitude": -82.722679,
      "city": "Dwale",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41622,
      "latitude": 37.512564,
      "longitude": -82.818388,
      "city": "Eastern",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41630,
      "latitude": 37.472094,
      "longitude": -82.834287,
      "city": "Garrett",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41631,
      "latitude": 37.457565,
      "longitude": -82.664491,
      "city": "Grethel",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41632,
      "latitude": 37.589246,
      "longitude": -82.945214,
      "city": "Gunlock",
      "state": "KY",
      "county": "Magoffin"
  },
  {
      "zipCode": 41635,
      "latitude": 37.580715,
      "longitude": -82.677387,
      "city": "Harold",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41636,
      "latitude": 37.428614,
      "longitude": -82.714841,
      "city": "Hi Hat",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41640,
      "latitude": 37.508668,
      "longitude": -82.851807,
      "city": "Hueysville",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41642,
      "latitude": 37.469203,
      "longitude": -82.677517,
      "city": "Ivel",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41643,
      "latitude": 37.470999,
      "longitude": -82.829405,
      "city": "Lackey",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41645,
      "latitude": 37.586933,
      "longitude": -82.797386,
      "city": "Langley",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41647,
      "latitude": 37.560801,
      "longitude": -82.713723,
      "city": "Mc Dowell",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41649,
      "latitude": 37.600698,
      "longitude": -82.78595,
      "city": "Martin",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41650,
      "latitude": 37.353614,
      "longitude": -82.676257,
      "city": "Melvin",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41651,
      "latitude": 37.454849,
      "longitude": -82.76464,
      "city": "Minnie",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41653,
      "latitude": 37.536748,
      "longitude": -82.771139,
      "city": "Prestonsburg",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41655,
      "latitude": 37.568892,
      "longitude": -82.762194,
      "city": "Printer",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41659,
      "latitude": 37.45838,
      "longitude": -82.673213,
      "city": "Stanville",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41660,
      "latitude": 37.413736,
      "longitude": -82.648485,
      "city": "Teaberry",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41663,
      "latitude": 37.572499,
      "longitude": -82.645206,
      "city": "Tram",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41666,
      "latitude": 37.514783,
      "longitude": -82.729805,
      "city": "Wayland",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41667,
      "latitude": 37.316961,
      "longitude": -82.704127,
      "city": "Weeksbury",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41669,
      "latitude": 37.331441,
      "longitude": -82.715606,
      "city": "Wheelwright",
      "state": "KY",
      "county": "Floyd"
  },
  {
      "zipCode": 41701,
      "latitude": 37.299666,
      "longitude": -83.239904,
      "city": "Hazard",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41702,
      "latitude": 37.320076,
      "longitude": -83.206495,
      "city": "Hazard",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41712,
      "latitude": 37.363778,
      "longitude": -83.154594,
      "city": "Ary",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41713,
      "latitude": 37.22539,
      "longitude": -83.273609,
      "city": "Avawam",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41714,
      "latitude": 37.193912,
      "longitude": -83.503508,
      "city": "Bear Branch",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41719,
      "latitude": 37.310308,
      "longitude": -83.309013,
      "city": "Bonnyman",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41721,
      "latitude": 37.302001,
      "longitude": -83.47173,
      "city": "Buckhorn",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41722,
      "latitude": 37.315033,
      "longitude": -83.156088,
      "city": "Bulan",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41723,
      "latitude": 37.277522,
      "longitude": -83.238897,
      "city": "Busy",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41725,
      "latitude": 37.318236,
      "longitude": -83.068963,
      "city": "Carrie",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41727,
      "latitude": 37.277764,
      "longitude": -83.262663,
      "city": "Chavies",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41729,
      "latitude": 37.264854,
      "longitude": -83.217443,
      "city": "Combs",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41730,
      "latitude": 37.258617,
      "longitude": -83.395648,
      "city": "Confluence",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41731,
      "latitude": 37.1408,
      "longitude": -83.088916,
      "city": "Cornettsville",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41735,
      "latitude": 37.033615,
      "longitude": -83.096311,
      "city": "Delphia",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41736,
      "latitude": 37.375823,
      "longitude": -83.241868,
      "city": "Dice",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41739,
      "latitude": 37.339408,
      "longitude": -83.130273,
      "city": "Dwarf",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41740,
      "latitude": 37.349857,
      "longitude": -83.047116,
      "city": "Emmalena",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41743,
      "latitude": 37.30636,
      "longitude": -83.090441,
      "city": "Fisty",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41745,
      "latitude": 37.349687,
      "longitude": -83.436773,
      "city": "Gays Creek",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41746,
      "latitude": 37.210078,
      "longitude": -83.091716,
      "city": "Happy",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41747,
      "latitude": 37.300202,
      "longitude": -83.122477,
      "city": "Hardburly",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41749,
      "latitude": 37.187746,
      "longitude": -83.416902,
      "city": "Hyden",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41751,
      "latitude": 37.218395,
      "longitude": -83.142655,
      "city": "Jeff",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41754,
      "latitude": 37.347409,
      "longitude": -83.275015,
      "city": "Krypton",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41759,
      "latitude": 37.231962,
      "longitude": -83.02461,
      "city": "Sassafras",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41760,
      "latitude": 37.197107,
      "longitude": -83.081735,
      "city": "Scuddy",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41762,
      "latitude": 37.218801,
      "longitude": -83.506407,
      "city": "Sizerock",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41763,
      "latitude": 37.070492,
      "longitude": -83.113638,
      "city": "Slemp",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41764,
      "latitude": 37.121424,
      "longitude": -83.253867,
      "city": "Smilax",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41766,
      "latitude": 37.158641,
      "longitude": -83.384749,
      "city": "Thousandsticks",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41772,
      "latitude": 37.407264,
      "longitude": -83.01531,
      "city": "Vest",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41773,
      "latitude": 37.201968,
      "longitude": -83.065816,
      "city": "Vicco",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41774,
      "latitude": 37.24369,
      "longitude": -83.13639,
      "city": "Viper",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41775,
      "latitude": 37.107867,
      "longitude": -83.356305,
      "city": "Wendover",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41776,
      "latitude": 37.126066,
      "longitude": -83.267303,
      "city": "Wooton",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41777,
      "latitude": 37.056529,
      "longitude": -83.236134,
      "city": "Yeaddiss",
      "state": "KY",
      "county": "Leslie"
  },
  {
      "zipCode": 41778,
      "latitude": 37.283017,
      "longitude": -83.321215,
      "city": "Yerkes",
      "state": "KY",
      "county": "Perry"
  },
  {
      "zipCode": 41804,
      "latitude": 37.155055,
      "longitude": -82.995605,
      "city": "Blackey",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41810,
      "latitude": 37.178814,
      "longitude": -82.69498,
      "city": "Cromona",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41812,
      "latitude": 37.240912,
      "longitude": -82.769564,
      "city": "Deane",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41815,
      "latitude": 37.140336,
      "longitude": -82.814691,
      "city": "Ermine",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41817,
      "latitude": 37.367552,
      "longitude": -82.910653,
      "city": "Garner",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41819,
      "latitude": 36.989061,
      "longitude": -83.065458,
      "city": "Gordon",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41821,
      "latitude": 37.083683,
      "longitude": -83.002662,
      "city": "Hallie",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41822,
      "latitude": 37.367278,
      "longitude": -82.961977,
      "city": "Hindman",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41824,
      "latitude": 37.193211,
      "longitude": -82.875397,
      "city": "Isom",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41825,
      "latitude": 37.224615,
      "longitude": -82.722772,
      "city": "Jackhorn",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41826,
      "latitude": 37.135577,
      "longitude": -82.837148,
      "city": "Jeremiah",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41828,
      "latitude": 37.313565,
      "longitude": -82.797478,
      "city": "Kite",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41831,
      "latitude": 37.370519,
      "longitude": -82.967672,
      "city": "Leburn",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41832,
      "latitude": 37.153075,
      "longitude": -82.954758,
      "city": "Letcher",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41833,
      "latitude": 37.023328,
      "longitude": -82.987812,
      "city": "Linefork",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41834,
      "latitude": 37.275003,
      "longitude": -82.944607,
      "city": "Littcarr",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41835,
      "latitude": 37.213652,
      "longitude": -82.673426,
      "city": "Mc Roberts",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41836,
      "latitude": 37.269218,
      "longitude": -82.91613,
      "city": "Mallie",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41837,
      "latitude": 37.130234,
      "longitude": -82.742726,
      "city": "Mayking",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41838,
      "latitude": 37.176559,
      "longitude": -82.751669,
      "city": "Millstone",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41839,
      "latitude": 37.419099,
      "longitude": -82.885808,
      "city": "Mousie",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41840,
      "latitude": 37.187588,
      "longitude": -82.711046,
      "city": "Neon",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41843,
      "latitude": 37.28679,
      "longitude": -82.874059,
      "city": "Pine Top",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41844,
      "latitude": 37.349054,
      "longitude": -82.874239,
      "city": "Pippa Passes",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41845,
      "latitude": 37.125231,
      "longitude": -82.903459,
      "city": "Premium",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41847,
      "latitude": 37.217206,
      "longitude": -82.942357,
      "city": "Redfox",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41848,
      "latitude": 37.110216,
      "longitude": -82.94073,
      "city": "Roxana",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41849,
      "latitude": 37.173926,
      "longitude": -82.716166,
      "city": "Seco",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41855,
      "latitude": 37.178608,
      "longitude": -82.795137,
      "city": "Thornton",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41858,
      "latitude": 37.13885,
      "longitude": -82.855032,
      "city": "Whitesburg",
      "state": "KY",
      "county": "Letcher"
  },
  {
      "zipCode": 41859,
      "latitude": 37.397578,
      "longitude": -82.783552,
      "city": "Dema",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41861,
      "latitude": 37.387621,
      "longitude": -82.836144,
      "city": "Raven",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41862,
      "latitude": 37.348498,
      "longitude": -82.792578,
      "city": "Topmost",
      "state": "KY",
      "county": "Knott"
  },
  {
      "zipCode": 41901,
      "latitude": "",
      "longitude": "",
      "city": "Migrate",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 41902,
      "latitude": "",
      "longitude": "",
      "city": "Migrate",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 41903,
      "latitude": "",
      "longitude": "",
      "city": "Migrate",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 41904,
      "latitude": "",
      "longitude": "",
      "city": "Migrate",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 41905,
      "latitude": "",
      "longitude": "",
      "city": "Migrate",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 41906,
      "latitude": "",
      "longitude": "",
      "city": "Migrate",
      "state": "KY",
      "county": "Fayette"
  },
  {
      "zipCode": 42001,
      "latitude": 37.031586,
      "longitude": -88.713292,
      "city": "Paducah",
      "state": "KY",
      "county": "Mccracken"
  },
  {
      "zipCode": 42002,
      "latitude": 37.085511,
      "longitude": -88.71247,
      "city": "Paducah",
      "state": "KY",
      "county": "Mccracken"
  },
  {
      "zipCode": 42003,
      "latitude": 37.015159,
      "longitude": -88.581289,
      "city": "Paducah",
      "state": "KY",
      "county": "Mccracken"
  },
  {
      "zipCode": 42020,
      "latitude": 36.690186,
      "longitude": -88.283872,
      "city": "Almo",
      "state": "KY",
      "county": "Calloway"
  },
  {
      "zipCode": 42021,
      "latitude": 36.789033,
      "longitude": -89.009178,
      "city": "Arlington",
      "state": "KY",
      "county": "Carlisle"
  },
  {
      "zipCode": 42022,
      "latitude": 37.148007,
      "longitude": -88.948562,
      "city": "Bandana",
      "state": "KY",
      "county": "Ballard"
  },
  {
      "zipCode": 42023,
      "latitude": 36.87522,
      "longitude": -89.006956,
      "city": "Bardwell",
      "state": "KY",
      "county": "Carlisle"
  },
  {
      "zipCode": 42024,
      "latitude": 37.081273,
      "longitude": -89.01148,
      "city": "Barlow",
      "state": "KY",
      "county": "Ballard"
  },
  {
      "zipCode": 42025,
      "latitude": 36.888765,
      "longitude": -88.305502,
      "city": "Benton",
      "state": "KY",
      "county": "Marshall"
  },
  {
      "zipCode": 42027,
      "latitude": 36.900149,
      "longitude": -88.637039,
      "city": "Boaz",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42028,
      "latitude": 37.155977,
      "longitude": -88.339599,
      "city": "Burna",
      "state": "KY",
      "county": "Livingston"
  },
  {
      "zipCode": 42029,
      "latitude": 36.986553,
      "longitude": -88.373197,
      "city": "Calvert City",
      "state": "KY",
      "county": "Marshall"
  },
  {
      "zipCode": 42031,
      "latitude": 36.680798,
      "longitude": -88.979474,
      "city": "Clinton",
      "state": "KY",
      "county": "Hickman"
  },
  {
      "zipCode": 42032,
      "latitude": 36.732689,
      "longitude": -89.1019,
      "city": "Columbus",
      "state": "KY",
      "county": "Hickman"
  },
  {
      "zipCode": 42033,
      "latitude": 37.336533,
      "longitude": -88.079607,
      "city": "Crayne",
      "state": "KY",
      "county": "Crittenden"
  },
  {
      "zipCode": 42035,
      "latitude": 36.863147,
      "longitude": -88.995991,
      "city": "Cunningham",
      "state": "KY",
      "county": "Carlisle"
  },
  {
      "zipCode": 42036,
      "latitude": 36.715535,
      "longitude": -88.235991,
      "city": "Dexter",
      "state": "KY",
      "county": "Calloway"
  },
  {
      "zipCode": 42037,
      "latitude": 37.157866,
      "longitude": -88.183598,
      "city": "Dycusburg",
      "state": "KY",
      "county": "Crittenden"
  },
  {
      "zipCode": 42038,
      "latitude": 37.044697,
      "longitude": -88.024871,
      "city": "Eddyville",
      "state": "KY",
      "county": "Lyon"
  },
  {
      "zipCode": 42039,
      "latitude": 36.780953,
      "longitude": -88.78873,
      "city": "Fancy Farm",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42040,
      "latitude": 36.600013,
      "longitude": -88.51864,
      "city": "Farmington",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42041,
      "latitude": 36.546939,
      "longitude": -89.081143,
      "city": "Fulton",
      "state": "KY",
      "county": "Fulton"
  },
  {
      "zipCode": 42044,
      "latitude": 36.965547,
      "longitude": -88.266684,
      "city": "Gilbertsville",
      "state": "KY",
      "county": "Marshall"
  },
  {
      "zipCode": 42045,
      "latitude": 37.076174,
      "longitude": -88.264746,
      "city": "Grand Rivers",
      "state": "KY",
      "county": "Livingston"
  },
  {
      "zipCode": 42046,
      "latitude": 36.6033,
      "longitude": -88.092285,
      "city": "Hamlin",
      "state": "KY",
      "county": "Calloway"
  },
  {
      "zipCode": 42047,
      "latitude": 37.302521,
      "longitude": -88.393763,
      "city": "Hampton",
      "state": "KY",
      "county": "Livingston"
  },
  {
      "zipCode": 42048,
      "latitude": 36.817672,
      "longitude": -88.30444,
      "city": "Hardin",
      "state": "KY",
      "county": "Marshall"
  },
  {
      "zipCode": 42049,
      "latitude": 36.578831,
      "longitude": -88.322336,
      "city": "Hazel",
      "state": "KY",
      "county": "Calloway"
  },
  {
      "zipCode": 42050,
      "latitude": 36.548671,
      "longitude": -89.208001,
      "city": "Hickman",
      "state": "KY",
      "county": "Fulton"
  },
  {
      "zipCode": 42051,
      "latitude": 36.852197,
      "longitude": -88.640928,
      "city": "Hickory",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42053,
      "latitude": 37.098119,
      "longitude": -88.851344,
      "city": "Kevil",
      "state": "KY",
      "county": "Ballard"
  },
  {
      "zipCode": 42054,
      "latitude": 36.699095,
      "longitude": -88.421888,
      "city": "Kirksey",
      "state": "KY",
      "county": "Calloway"
  },
  {
      "zipCode": 42055,
      "latitude": 37.073525,
      "longitude": -88.144971,
      "city": "Kuttawa",
      "state": "KY",
      "county": "Lyon"
  },
  {
      "zipCode": 42056,
      "latitude": 37.080505,
      "longitude": -88.986278,
      "city": "La Center",
      "state": "KY",
      "county": "Ballard"
  },
  {
      "zipCode": 42058,
      "latitude": 37.061146,
      "longitude": -88.466549,
      "city": "Ledbetter",
      "state": "KY",
      "county": "Livingston"
  },
  {
      "zipCode": 42060,
      "latitude": 36.96365,
      "longitude": -88.836371,
      "city": "Lovelaceville",
      "state": "KY",
      "county": "Ballard"
  },
  {
      "zipCode": 42061,
      "latitude": 36.886172,
      "longitude": -88.772824,
      "city": "Lowes",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42063,
      "latitude": 36.723872,
      "longitude": -88.652009,
      "city": "Lynnville",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42064,
      "latitude": 37.322088,
      "longitude": -88.087479,
      "city": "Marion",
      "state": "KY",
      "county": "Crittenden"
  },
  {
      "zipCode": 42066,
      "latitude": 36.715599,
      "longitude": -88.651839,
      "city": "Mayfield",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42069,
      "latitude": 36.904548,
      "longitude": -88.758968,
      "city": "Melber",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42070,
      "latitude": 36.863147,
      "longitude": -88.995991,
      "city": "Milburn",
      "state": "KY",
      "county": "Carlisle"
  },
  {
      "zipCode": 42071,
      "latitude": 36.625349,
      "longitude": -88.279562,
      "city": "Murray",
      "state": "KY",
      "county": "Calloway"
  },
  {
      "zipCode": 42076,
      "latitude": 36.562065,
      "longitude": -88.150463,
      "city": "New Concord",
      "state": "KY",
      "county": "Calloway"
  },
  {
      "zipCode": 42078,
      "latitude": 37.269417,
      "longitude": -88.329698,
      "city": "Salem",
      "state": "KY",
      "county": "Livingston"
  },
  {
      "zipCode": 42079,
      "latitude": 36.574835,
      "longitude": -88.581458,
      "city": "Sedalia",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42081,
      "latitude": 37.238666,
      "longitude": -88.375665,
      "city": "Smithland",
      "state": "KY",
      "county": "Livingston"
  },
  {
      "zipCode": 42082,
      "latitude": 36.904412,
      "longitude": -88.515287,
      "city": "Symsonia",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42083,
      "latitude": 37.145668,
      "longitude": -88.285031,
      "city": "Tiline",
      "state": "KY",
      "county": "Livingston"
  },
  {
      "zipCode": 42084,
      "latitude": 37.434913,
      "longitude": -88.246148,
      "city": "Tolu",
      "state": "KY",
      "county": "Crittenden"
  },
  {
      "zipCode": 42085,
      "latitude": 36.572137,
      "longitude": -88.761466,
      "city": "Water Valley",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42086,
      "latitude": 37.082918,
      "longitude": -88.810261,
      "city": "West Paducah",
      "state": "KY",
      "county": "Mccracken"
  },
  {
      "zipCode": 42087,
      "latitude": 37.056862,
      "longitude": -88.961579,
      "city": "Wickliffe",
      "state": "KY",
      "county": "Ballard"
  },
  {
      "zipCode": 42088,
      "latitude": 36.60784,
      "longitude": -88.725226,
      "city": "Wingo",
      "state": "KY",
      "county": "Graves"
  },
  {
      "zipCode": 42101,
      "latitude": 37.017407,
      "longitude": -86.451752,
      "city": "Bowling Green",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42102,
      "latitude": 36.922292,
      "longitude": -86.387046,
      "city": "Bowling Green",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42103,
      "latitude": 36.950002,
      "longitude": -86.358413,
      "city": "Bowling Green",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42104,
      "latitude": 36.887214,
      "longitude": -86.453985,
      "city": "Bowling Green",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42120,
      "latitude": 36.67931,
      "longitude": -86.17603,
      "city": "Adolphus",
      "state": "KY",
      "county": "Allen"
  },
  {
      "zipCode": 42122,
      "latitude": 36.866699,
      "longitude": -86.348542,
      "city": "Alvaton",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42123,
      "latitude": 36.815869,
      "longitude": -85.992039,
      "city": "Austin",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42124,
      "latitude": 36.857666,
      "longitude": -85.649583,
      "city": "Beaumont",
      "state": "KY",
      "county": "Metcalfe"
  },
  {
      "zipCode": 42127,
      "latitude": 37.10279,
      "longitude": -85.908887,
      "city": "Cave City",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42128,
      "latitude": 36.983441,
      "longitude": -86.394012,
      "city": "Drake",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42129,
      "latitude": 37.000783,
      "longitude": -85.609522,
      "city": "Edmonton",
      "state": "KY",
      "county": "Metcalfe"
  },
  {
      "zipCode": 42130,
      "latitude": 36.91297,
      "longitude": -85.775282,
      "city": "Eighty Eight",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42131,
      "latitude": 36.819311,
      "longitude": -85.91168,
      "city": "Etoile",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42133,
      "latitude": 36.726089,
      "longitude": -85.846991,
      "city": "Fountain Run",
      "state": "KY",
      "county": "Monroe"
  },
  {
      "zipCode": 42134,
      "latitude": 36.740598,
      "longitude": -86.583305,
      "city": "Franklin",
      "state": "KY",
      "county": "Simpson"
  },
  {
      "zipCode": 42135,
      "latitude": 36.758166,
      "longitude": -86.581957,
      "city": "Franklin",
      "state": "KY",
      "county": "Simpson"
  },
  {
      "zipCode": 42140,
      "latitude": 36.669398,
      "longitude": -85.856306,
      "city": "Gamaliel",
      "state": "KY",
      "county": "Monroe"
  },
  {
      "zipCode": 42141,
      "latitude": 36.942763,
      "longitude": -85.941203,
      "city": "Glasgow",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42142,
      "latitude": 36.946326,
      "longitude": -85.957797,
      "city": "Glasgow",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42150,
      "latitude": 36.782131,
      "longitude": -86.184088,
      "city": "Halfway",
      "state": "KY",
      "county": "Allen"
  },
  {
      "zipCode": 42151,
      "latitude": 36.647144,
      "longitude": -85.567555,
      "city": "Hestand",
      "state": "KY",
      "county": "Monroe"
  },
  {
      "zipCode": 42152,
      "latitude": 37.098753,
      "longitude": -85.816472,
      "city": "Hiseville",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42153,
      "latitude": 36.678703,
      "longitude": -86.038273,
      "city": "Holland",
      "state": "KY",
      "county": "Allen"
  },
  {
      "zipCode": 42154,
      "latitude": 37.055613,
      "longitude": -85.698474,
      "city": "Knob Lick",
      "state": "KY",
      "county": "Metcalfe"
  },
  {
      "zipCode": 42156,
      "latitude": 36.840503,
      "longitude": -85.995572,
      "city": "Lucas",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42157,
      "latitude": 36.772341,
      "longitude": -85.819518,
      "city": "Mount Hermon",
      "state": "KY",
      "county": "Monroe"
  },
  {
      "zipCode": 42159,
      "latitude": 36.998452,
      "longitude": -86.250131,
      "city": "Oakland",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42160,
      "latitude": 36.965149,
      "longitude": -86.013864,
      "city": "Park City",
      "state": "KY",
      "county": "Barren"
  },
  {
      "zipCode": 42163,
      "latitude": 37.09425,
      "longitude": -86.143188,
      "city": "Rocky Hill",
      "state": "KY",
      "county": "Edmonson"
  },
  {
      "zipCode": 42164,
      "latitude": 36.783817,
      "longitude": -86.188104,
      "city": "Scottsville",
      "state": "KY",
      "county": "Allen"
  },
  {
      "zipCode": 42166,
      "latitude": 36.956935,
      "longitude": -85.660577,
      "city": "Summer Shade",
      "state": "KY",
      "county": "Metcalfe"
  },
  {
      "zipCode": 42167,
      "latitude": 36.723634,
      "longitude": -85.700865,
      "city": "Tompkinsville",
      "state": "KY",
      "county": "Monroe"
  },
  {
      "zipCode": 42170,
      "latitude": 36.90641,
      "longitude": -86.538,
      "city": "Woodburn",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42171,
      "latitude": 37.01823,
      "longitude": -86.187045,
      "city": "Smiths Grove",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42201,
      "latitude": 37.279564,
      "longitude": -86.670181,
      "city": "Aberdeen",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42202,
      "latitude": 36.772851,
      "longitude": -86.860422,
      "city": "Adairville",
      "state": "KY",
      "county": "Logan"
  },
  {
      "zipCode": 42203,
      "latitude": 36.929243,
      "longitude": -87.219328,
      "city": "Allegre",
      "state": "KY",
      "county": "Todd"
  },
  {
      "zipCode": 42204,
      "latitude": 36.720672,
      "longitude": -87.104104,
      "city": "Allensville",
      "state": "KY",
      "county": "Todd"
  },
  {
      "zipCode": 42206,
      "latitude": 36.835067,
      "longitude": -86.797432,
      "city": "Auburn",
      "state": "KY",
      "county": "Logan"
  },
  {
      "zipCode": 42207,
      "latitude": 37.300469,
      "longitude": -86.25426,
      "city": "Bee Spring",
      "state": "KY",
      "county": "Edmonson"
  },
  {
      "zipCode": 42209,
      "latitude": 37.197491,
      "longitude": -86.67216,
      "city": "Brooklyn",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42210,
      "latitude": 37.222931,
      "longitude": -86.292256,
      "city": "Brownsville",
      "state": "KY",
      "county": "Edmonson"
  },
  {
      "zipCode": 42211,
      "latitude": 36.80201,
      "longitude": -87.828578,
      "city": "Cadiz",
      "state": "KY",
      "county": "Trigg"
  },
  {
      "zipCode": 42214,
      "latitude": 37.11767,
      "longitude": -85.703791,
      "city": "Center",
      "state": "KY",
      "county": "Metcalfe"
  },
  {
      "zipCode": 42215,
      "latitude": 36.953435,
      "longitude": -87.718377,
      "city": "Cerulean",
      "state": "KY",
      "county": "Trigg"
  },
  {
      "zipCode": 42216,
      "latitude": 37.004492,
      "longitude": -87.152148,
      "city": "Clifty",
      "state": "KY",
      "county": "Todd"
  },
  {
      "zipCode": 42217,
      "latitude": 37.043116,
      "longitude": -87.487724,
      "city": "Crofton",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42219,
      "latitude": 37.170909,
      "longitude": -86.767822,
      "city": "Dunbar",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42220,
      "latitude": 36.862405,
      "longitude": -87.177881,
      "city": "Elkton",
      "state": "KY",
      "county": "Todd"
  },
  {
      "zipCode": 42221,
      "latitude": 36.898071,
      "longitude": -87.496529,
      "city": "Fairview",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42223,
      "latitude": 36.657253,
      "longitude": -87.458858,
      "city": "Fort Campbell",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42232,
      "latitude": 36.918213,
      "longitude": -87.580011,
      "city": "Gracey",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42234,
      "latitude": 36.714895,
      "longitude": -87.150248,
      "city": "Guthrie",
      "state": "KY",
      "county": "Todd"
  },
  {
      "zipCode": 42235,
      "latitude": 36.983441,
      "longitude": -86.394012,
      "city": "Hadley",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42236,
      "latitude": 36.719274,
      "longitude": -87.604569,
      "city": "Herndon",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42240,
      "latitude": 36.876568,
      "longitude": -87.471106,
      "city": "Hopkinsville",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42241,
      "latitude": 36.898071,
      "longitude": -87.496529,
      "city": "Hopkinsville",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42251,
      "latitude": 37.157967,
      "longitude": -86.885846,
      "city": "Huntsville",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42252,
      "latitude": 37.248326,
      "longitude": -86.509271,
      "city": "Jetson",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42254,
      "latitude": 36.677547,
      "longitude": -87.645839,
      "city": "La Fayette",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42256,
      "latitude": 36.907058,
      "longitude": -86.918045,
      "city": "Lewisburg",
      "state": "KY",
      "county": "Logan"
  },
  {
      "zipCode": 42257,
      "latitude": 37.237211,
      "longitude": -86.299361,
      "city": "Lindseyville",
      "state": "KY",
      "county": "Edmonson"
  },
  {
      "zipCode": 42259,
      "latitude": 37.280199,
      "longitude": -86.168796,
      "city": "Mammoth Cave",
      "state": "KY",
      "county": "Edmonson"
  },
  {
      "zipCode": 42261,
      "latitude": 37.20017,
      "longitude": -86.685898,
      "city": "Morgantown",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42262,
      "latitude": 36.738092,
      "longitude": -87.429302,
      "city": "Oak Grove",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42265,
      "latitude": 36.759526,
      "longitude": -86.980398,
      "city": "Olmstead",
      "state": "KY",
      "county": "Logan"
  },
  {
      "zipCode": 42266,
      "latitude": 36.864233,
      "longitude": -87.409139,
      "city": "Pembroke",
      "state": "KY",
      "county": "Christian"
  },
  {
      "zipCode": 42267,
      "latitude": 37.229645,
      "longitude": -86.804803,
      "city": "Provo",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42270,
      "latitude": 36.983441,
      "longitude": -86.394012,
      "city": "Richardsville",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42273,
      "latitude": 37.207489,
      "longitude": -86.859847,
      "city": "Rochester",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42274,
      "latitude": 36.924137,
      "longitude": -86.597869,
      "city": "Rockfield",
      "state": "KY",
      "county": "Warren"
  },
  {
      "zipCode": 42275,
      "latitude": 37.242474,
      "longitude": -86.411435,
      "city": "Roundhill",
      "state": "KY",
      "county": "Edmonson"
  },
  {
      "zipCode": 42276,
      "latitude": 36.845273,
      "longitude": -86.882321,
      "city": "Russellville",
      "state": "KY",
      "county": "Logan"
  },
  {
      "zipCode": 42280,
      "latitude": 36.948365,
      "longitude": -87.1095,
      "city": "Sharon Grove",
      "state": "KY",
      "county": "Todd"
  },
  {
      "zipCode": 42283,
      "latitude": 36.869206,
      "longitude": -86.663225,
      "city": "South Union",
      "state": "KY",
      "county": "Logan"
  },
  {
      "zipCode": 42285,
      "latitude": 37.236073,
      "longitude": -86.27074,
      "city": "Sweeden",
      "state": "KY",
      "county": "Edmonson"
  },
  {
      "zipCode": 42286,
      "latitude": 36.74195,
      "longitude": -87.24589,
      "city": "Trenton",
      "state": "KY",
      "county": "Todd"
  },
  {
      "zipCode": 42287,
      "latitude": 37.318319,
      "longitude": -86.522996,
      "city": "Welchs Creek",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42288,
      "latitude": 37.197491,
      "longitude": -86.67216,
      "city": "Woodbury",
      "state": "KY",
      "county": "Butler"
  },
  {
      "zipCode": 42301,
      "latitude": 37.751818,
      "longitude": -87.257303,
      "city": "Owensboro",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42302,
      "latitude": 37.745491,
      "longitude": -87.112823,
      "city": "Owensboro",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42303,
      "latitude": 37.779387,
      "longitude": -87.042939,
      "city": "Owensboro",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42304,
      "latitude": 37.745491,
      "longitude": -87.112823,
      "city": "Owensboro",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42320,
      "latitude": 37.355569,
      "longitude": -86.864676,
      "city": "Beaver Dam",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42321,
      "latitude": 37.153324,
      "longitude": -87.024457,
      "city": "Beech Creek",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42322,
      "latitude": 37.615062,
      "longitude": -87.406866,
      "city": "Beech Grove",
      "state": "KY",
      "county": "Mclean"
  },
  {
      "zipCode": 42323,
      "latitude": 37.233477,
      "longitude": -87.076198,
      "city": "Beechmont",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42324,
      "latitude": 37.137789,
      "longitude": -87.004852,
      "city": "Belton",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42325,
      "latitude": 37.337159,
      "longitude": -87.271176,
      "city": "Bremen",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42326,
      "latitude": 37.199946,
      "longitude": -87.00006,
      "city": "Browder",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42327,
      "latitude": 37.58095,
      "longitude": -87.287491,
      "city": "Calhoun",
      "state": "KY",
      "county": "Mclean"
  },
  {
      "zipCode": 42328,
      "latitude": 37.394873,
      "longitude": -87.026469,
      "city": "Centertown",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42330,
      "latitude": 37.243098,
      "longitude": -87.111579,
      "city": "Central City",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42332,
      "latitude": 37.253342,
      "longitude": -87.089689,
      "city": "Cleaton",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42333,
      "latitude": 37.373832,
      "longitude": -86.764417,
      "city": "Cromwell",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42334,
      "latitude": 37.745491,
      "longitude": -87.112823,
      "city": "Curdsville",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42337,
      "latitude": 37.211475,
      "longitude": -87.008634,
      "city": "Drakesboro",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42338,
      "latitude": 37.552013,
      "longitude": -86.77762,
      "city": "Dundee",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42339,
      "latitude": 37.194268,
      "longitude": -87.018401,
      "city": "Dunmor",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42343,
      "latitude": 37.614496,
      "longitude": -86.729497,
      "city": "Fordsville",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42344,
      "latitude": 37.246726,
      "longitude": -87.297584,
      "city": "Graham",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42345,
      "latitude": 37.20083,
      "longitude": -87.164385,
      "city": "Greenville",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42347,
      "latitude": 37.472545,
      "longitude": -86.869715,
      "city": "Hartford",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42348,
      "latitude": 37.832151,
      "longitude": -86.782723,
      "city": "Hawesville",
      "state": "KY",
      "county": "Hancock"
  },
  {
      "zipCode": 42349,
      "latitude": 37.415242,
      "longitude": -86.689904,
      "city": "Horse Branch",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42350,
      "latitude": 37.459336,
      "longitude": -87.206541,
      "city": "Island",
      "state": "KY",
      "county": "Mclean"
  },
  {
      "zipCode": 42351,
      "latitude": 37.911077,
      "longitude": -86.882034,
      "city": "Lewisport",
      "state": "KY",
      "county": "Hancock"
  },
  {
      "zipCode": 42352,
      "latitude": 37.513281,
      "longitude": -87.112413,
      "city": "Livermore",
      "state": "KY",
      "county": "Mclean"
  },
  {
      "zipCode": 42354,
      "latitude": 37.363601,
      "longitude": -86.924358,
      "city": "Mc Henry",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42355,
      "latitude": 37.859021,
      "longitude": -86.977249,
      "city": "Maceo",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42356,
      "latitude": 37.693229,
      "longitude": -87.323841,
      "city": "Maple Mount",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42361,
      "latitude": 37.518118,
      "longitude": -86.718192,
      "city": "Olaton",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42364,
      "latitude": 37.827235,
      "longitude": -86.804814,
      "city": "Pellville",
      "state": "KY",
      "county": "Hancock"
  },
  {
      "zipCode": 42365,
      "latitude": 37.112633,
      "longitude": -86.994053,
      "city": "Penrod",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42366,
      "latitude": 37.719598,
      "longitude": -86.929674,
      "city": "Philpot",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42367,
      "latitude": 37.242948,
      "longitude": -87.154898,
      "city": "Powderly",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42368,
      "latitude": 37.740277,
      "longitude": -86.741434,
      "city": "Reynolds Station",
      "state": "KY",
      "county": "Hancock"
  },
  {
      "zipCode": 42369,
      "latitude": 37.389897,
      "longitude": -86.88526,
      "city": "Rockport",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42370,
      "latitude": 37.545044,
      "longitude": -86.778272,
      "city": "Rosine",
      "state": "KY",
      "county": "Ohio"
  },
  {
      "zipCode": 42371,
      "latitude": 37.470441,
      "longitude": -87.246152,
      "city": "Rumsey",
      "state": "KY",
      "county": "Mclean"
  },
  {
      "zipCode": 42372,
      "latitude": 37.488059,
      "longitude": -87.258644,
      "city": "Sacramento",
      "state": "KY",
      "county": "Mclean"
  },
  {
      "zipCode": 42374,
      "latitude": 37.353575,
      "longitude": -87.165762,
      "city": "South Carrollton",
      "state": "KY",
      "county": "Muhlenberg"
  },
  {
      "zipCode": 42375,
      "latitude": 37.745491,
      "longitude": -87.112823,
      "city": "Stanley",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42376,
      "latitude": 37.633807,
      "longitude": -87.12177,
      "city": "Utica",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42377,
      "latitude": 37.745491,
      "longitude": -87.112823,
      "city": "West Louisville",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42378,
      "latitude": 37.682485,
      "longitude": -86.883013,
      "city": "Whitesville",
      "state": "KY",
      "county": "Daviess"
  },
  {
      "zipCode": 42402,
      "latitude": 37.778911,
      "longitude": -87.60124,
      "city": "Baskett",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42403,
      "latitude": 37.446023,
      "longitude": -87.933437,
      "city": "Blackford",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42404,
      "latitude": 37.486077,
      "longitude": -87.812941,
      "city": "Clay",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42406,
      "latitude": 37.754734,
      "longitude": -87.689478,
      "city": "Corydon",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42408,
      "latitude": 37.220571,
      "longitude": -87.624098,
      "city": "Dawson Springs",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42409,
      "latitude": 37.519247,
      "longitude": -87.67168,
      "city": "Dixon",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42410,
      "latitude": 37.27427,
      "longitude": -87.513052,
      "city": "Earlington",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42411,
      "latitude": 37.201173,
      "longitude": -87.988003,
      "city": "Fredonia",
      "state": "KY",
      "county": "Caldwell"
  },
  {
      "zipCode": 42413,
      "latitude": 37.335312,
      "longitude": -87.469567,
      "city": "Hanson",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42419,
      "latitude": 37.807173,
      "longitude": -87.599052,
      "city": "Henderson",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42420,
      "latitude": 37.785068,
      "longitude": -87.527383,
      "city": "Henderson",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42431,
      "latitude": 37.299458,
      "longitude": -87.532576,
      "city": "Madisonville",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42436,
      "latitude": 37.406689,
      "longitude": -87.563868,
      "city": "Manitou",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42437,
      "latitude": 37.686939,
      "longitude": -87.943938,
      "city": "Morganfield",
      "state": "KY",
      "county": "Union"
  },
  {
      "zipCode": 42440,
      "latitude": 37.249018,
      "longitude": -87.461536,
      "city": "Mortons Gap",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42441,
      "latitude": 37.364469,
      "longitude": -87.651863,
      "city": "Nebo",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42442,
      "latitude": 37.257874,
      "longitude": -87.506773,
      "city": "Nortonville",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42444,
      "latitude": 37.641002,
      "longitude": -87.643862,
      "city": "Poole",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42445,
      "latitude": 37.140606,
      "longitude": -87.865635,
      "city": "Princeton",
      "state": "KY",
      "county": "Caldwell"
  },
  {
      "zipCode": 42450,
      "latitude": 37.484437,
      "longitude": -87.734014,
      "city": "Providence",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42451,
      "latitude": 37.8497,
      "longitude": -87.378292,
      "city": "Reed",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42452,
      "latitude": 37.700857,
      "longitude": -87.551576,
      "city": "Robards",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42453,
      "latitude": 37.160353,
      "longitude": -87.569718,
      "city": "Saint Charles",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42455,
      "latitude": 37.570081,
      "longitude": -87.636436,
      "city": "Sebree",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42456,
      "latitude": 37.505442,
      "longitude": -87.534622,
      "city": "Slaughters",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42457,
      "latitude": 37.83233,
      "longitude": -87.788906,
      "city": "Smith Mills",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42458,
      "latitude": 37.840368,
      "longitude": -87.547026,
      "city": "Spottsville",
      "state": "KY",
      "county": "Henderson"
  },
  {
      "zipCode": 42459,
      "latitude": 37.628913,
      "longitude": -87.989378,
      "city": "Sturgis",
      "state": "KY",
      "county": "Union"
  },
  {
      "zipCode": 42460,
      "latitude": 37.493997,
      "longitude": -87.932773,
      "city": "Sullivan",
      "state": "KY",
      "county": "Union"
  },
  {
      "zipCode": 42461,
      "latitude": 37.717433,
      "longitude": -87.914375,
      "city": "Uniontown",
      "state": "KY",
      "county": "Union"
  },
  {
      "zipCode": 42462,
      "latitude": 37.631341,
      "longitude": -87.866487,
      "city": "Waverly",
      "state": "KY",
      "county": "Union"
  },
  {
      "zipCode": 42463,
      "latitude": 37.487972,
      "longitude": -87.866125,
      "city": "Wheatcroft",
      "state": "KY",
      "county": "Webster"
  },
  {
      "zipCode": 42464,
      "latitude": 37.20031,
      "longitude": -87.400477,
      "city": "White Plains",
      "state": "KY",
      "county": "Hopkins"
  },
  {
      "zipCode": 42501,
      "latitude": 37.10288,
      "longitude": -84.544294,
      "city": "Somerset",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42502,
      "latitude": 37.09325,
      "longitude": -84.427729,
      "city": "Somerset",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42503,
      "latitude": 37.131938,
      "longitude": -84.518445,
      "city": "Somerset",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42516,
      "latitude": 37.242524,
      "longitude": -84.89986,
      "city": "Bethelridge",
      "state": "KY",
      "county": "Casey"
  },
  {
      "zipCode": 42518,
      "latitude": 36.952751,
      "longitude": -84.624731,
      "city": "Bronston",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42519,
      "latitude": 37.029301,
      "longitude": -84.537413,
      "city": "Burnside",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42528,
      "latitude": 37.190615,
      "longitude": -84.983484,
      "city": "Dunnville",
      "state": "KY",
      "county": "Casey"
  },
  {
      "zipCode": 42533,
      "latitude": 37.114754,
      "longitude": -84.69969,
      "city": "Ferguson",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42539,
      "latitude": 37.302162,
      "longitude": -84.957722,
      "city": "Liberty",
      "state": "KY",
      "county": "Casey"
  },
  {
      "zipCode": 42541,
      "latitude": 37.36719,
      "longitude": -84.798875,
      "city": "Middleburg",
      "state": "KY",
      "county": "Casey"
  },
  {
      "zipCode": 42544,
      "latitude": 37.063143,
      "longitude": -84.719207,
      "city": "Nancy",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42553,
      "latitude": 37.110682,
      "longitude": -84.686815,
      "city": "Science Hill",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42558,
      "latitude": 36.94919,
      "longitude": -84.581482,
      "city": "Tateville",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42564,
      "latitude": 37.111433,
      "longitude": -84.592898,
      "city": "West Somerset",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42565,
      "latitude": 37.15679,
      "longitude": -84.879909,
      "city": "Windsor",
      "state": "KY",
      "county": "Casey"
  },
  {
      "zipCode": 42566,
      "latitude": 37.291935,
      "longitude": -84.82628,
      "city": "Yosemite",
      "state": "KY",
      "county": "Casey"
  },
  {
      "zipCode": 42567,
      "latitude": 37.128053,
      "longitude": -84.59699,
      "city": "Eubank",
      "state": "KY",
      "county": "Pulaski"
  },
  {
      "zipCode": 42602,
      "latitude": 36.745738,
      "longitude": -85.181967,
      "city": "Albany",
      "state": "KY",
      "county": "Clinton"
  },
  {
      "zipCode": 42603,
      "latitude": 36.751732,
      "longitude": -85.135354,
      "city": "Alpha",
      "state": "KY",
      "county": "Clinton"
  },
  {
      "zipCode": 42629,
      "latitude": 36.945046,
      "longitude": -85.124561,
      "city": "Jamestown",
      "state": "KY",
      "county": "Russell"
  },
  {
      "zipCode": 42631,
      "latitude": 36.777331,
      "longitude": -84.502512,
      "city": "Marshes Siding",
      "state": "KY",
      "county": "Mccreary"
  },
  {
      "zipCode": 42632,
      "latitude": 36.800363,
      "longitude": -84.818345,
      "city": "Mill Springs",
      "state": "KY",
      "county": "Wayne"
  },
  {
      "zipCode": 42633,
      "latitude": 36.8674,
      "longitude": -84.8254,
      "city": "Monticello",
      "state": "KY",
      "county": "Wayne"
  },
  {
      "zipCode": 42634,
      "latitude": 36.777331,
      "longitude": -84.502512,
      "city": "Parkers Lake",
      "state": "KY",
      "county": "Mccreary"
  },
  {
      "zipCode": 42635,
      "latitude": 36.777331,
      "longitude": -84.502512,
      "city": "Pine Knot",
      "state": "KY",
      "county": "Mccreary"
  },
  {
      "zipCode": 42638,
      "latitude": 36.777331,
      "longitude": -84.502512,
      "city": "Revelo",
      "state": "KY",
      "county": "Mccreary"
  },
  {
      "zipCode": 42642,
      "latitude": 37.019307,
      "longitude": -85.069717,
      "city": "Russell Springs",
      "state": "KY",
      "county": "Russell"
  },
  {
      "zipCode": 42647,
      "latitude": 36.777331,
      "longitude": -84.502512,
      "city": "Stearns",
      "state": "KY",
      "county": "Mccreary"
  },
  {
      "zipCode": 42649,
      "latitude": 36.777331,
      "longitude": -84.502512,
      "city": "Strunk",
      "state": "KY",
      "county": "Mccreary"
  },
  {
      "zipCode": 42653,
      "latitude": 36.721677,
      "longitude": -84.467609,
      "city": "Whitley City",
      "state": "KY",
      "county": "Mccreary"
  },
  {
      "zipCode": 42701,
      "latitude": 37.684807,
      "longitude": -85.878391,
      "city": "Elizabethtown",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42702,
      "latitude": 37.723474,
      "longitude": -85.976854,
      "city": "Elizabethtown",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42711,
      "latitude": 36.879564,
      "longitude": -85.317785,
      "city": "Bakerton",
      "state": "KY",
      "county": "Cumberland"
  },
  {
      "zipCode": 42712,
      "latitude": 37.493818,
      "longitude": -86.286363,
      "city": "Big Clifty",
      "state": "KY",
      "county": "Grayson"
  },
  {
      "zipCode": 42713,
      "latitude": 37.370142,
      "longitude": -85.913432,
      "city": "Bonnieville",
      "state": "KY",
      "county": "Hart"
  },
  {
      "zipCode": 42715,
      "latitude": 36.955287,
      "longitude": -85.403561,
      "city": "Breeding",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42716,
      "latitude": 37.485493,
      "longitude": -85.626798,
      "city": "Buffalo",
      "state": "KY",
      "county": "Larue"
  },
  {
      "zipCode": 42717,
      "latitude": 36.769106,
      "longitude": -85.408741,
      "city": "Burkesville",
      "state": "KY",
      "county": "Cumberland"
  },
  {
      "zipCode": 42718,
      "latitude": 37.337416,
      "longitude": -85.357952,
      "city": "Campbellsville",
      "state": "KY",
      "county": "Taylor"
  },
  {
      "zipCode": 42719,
      "latitude": 37.337936,
      "longitude": -85.330374,
      "city": "Campbellsville",
      "state": "KY",
      "county": "Taylor"
  },
  {
      "zipCode": 42720,
      "latitude": 37.121145,
      "longitude": -85.370926,
      "city": "Cane Valley",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42721,
      "latitude": 37.430259,
      "longitude": -86.370024,
      "city": "Caneyville",
      "state": "KY",
      "county": "Grayson"
  },
  {
      "zipCode": 42722,
      "latitude": 37.277025,
      "longitude": -85.727548,
      "city": "Canmer",
      "state": "KY",
      "county": "Hart"
  },
  {
      "zipCode": 42724,
      "latitude": 37.669044,
      "longitude": -86.054481,
      "city": "Cecilia",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42726,
      "latitude": 37.433583,
      "longitude": -86.208318,
      "city": "Clarkson",
      "state": "KY",
      "county": "Grayson"
  },
  {
      "zipCode": 42728,
      "latitude": 37.116131,
      "longitude": -85.265586,
      "city": "Columbia",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42729,
      "latitude": 37.260115,
      "longitude": -85.982964,
      "city": "Cub Run",
      "state": "KY",
      "county": "Hart"
  },
  {
      "zipCode": 42731,
      "latitude": 36.842115,
      "longitude": -85.539565,
      "city": "Dubre",
      "state": "KY",
      "county": "Cumberland"
  },
  {
      "zipCode": 42732,
      "latitude": 37.606115,
      "longitude": -86.090565,
      "city": "Eastview",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42733,
      "latitude": 37.340494,
      "longitude": -85.218987,
      "city": "Elk Horn",
      "state": "KY",
      "county": "Taylor"
  },
  {
      "zipCode": 42735,
      "latitude": 37.045089,
      "longitude": -85.29683,
      "city": "Fairplay",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42740,
      "latitude": 37.602581,
      "longitude": -86.007199,
      "city": "Glendale",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42741,
      "latitude": 37.006969,
      "longitude": -85.248697,
      "city": "Glens Fork",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42742,
      "latitude": 37.091158,
      "longitude": -85.465005,
      "city": "Gradyville",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42743,
      "latitude": 37.27586,
      "longitude": -85.519334,
      "city": "Greensburg",
      "state": "KY",
      "county": "Green"
  },
  {
      "zipCode": 42746,
      "latitude": 37.216477,
      "longitude": -85.772032,
      "city": "Hardyville",
      "state": "KY",
      "county": "Hart"
  },
  {
      "zipCode": 42748,
      "latitude": 37.559321,
      "longitude": -85.707267,
      "city": "Hodgenville",
      "state": "KY",
      "county": "Larue"
  },
  {
      "zipCode": 42749,
      "latitude": 37.202266,
      "longitude": -85.89552,
      "city": "Horse Cave",
      "state": "KY",
      "county": "Hart"
  },
  {
      "zipCode": 42753,
      "latitude": 37.231312,
      "longitude": -85.17202,
      "city": "Knifley",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42754,
      "latitude": 37.460336,
      "longitude": -86.324894,
      "city": "Leitchfield",
      "state": "KY",
      "county": "Grayson"
  },
  {
      "zipCode": 42755,
      "latitude": 37.471943,
      "longitude": -86.343941,
      "city": "Leitchfield",
      "state": "KY",
      "county": "Grayson"
  },
  {
      "zipCode": 42757,
      "latitude": 37.469063,
      "longitude": -85.723511,
      "city": "Magnolia",
      "state": "KY",
      "county": "Larue"
  },
  {
      "zipCode": 42758,
      "latitude": 37.360392,
      "longitude": -85.198031,
      "city": "Mannsville",
      "state": "KY",
      "county": "Taylor"
  },
  {
      "zipCode": 42759,
      "latitude": 36.830573,
      "longitude": -85.50154,
      "city": "Marrowbone",
      "state": "KY",
      "county": "Cumberland"
  },
  {
      "zipCode": 42761,
      "latitude": 37.107146,
      "longitude": -85.459565,
      "city": "Milltown",
      "state": "KY",
      "county": "Adair"
  },
  {
      "zipCode": 42762,
      "latitude": 37.444623,
      "longitude": -86.400828,
      "city": "Millwood",
      "state": "KY",
      "county": "Grayson"
  },
  {
      "zipCode": 42764,
      "latitude": 37.463961,
      "longitude": -85.639231,
      "city": "Mount Sherman",
      "state": "KY",
      "county": "Larue"
  },
  {
      "zipCode": 42765,
      "latitude": 37.29204,
      "longitude": -85.914004,
      "city": "Munfordville",
      "state": "KY",
      "county": "Hart"
  },
  {
      "zipCode": 42776,
      "latitude": 37.515024,
      "longitude": -85.950238,
      "city": "Sonora",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42782,
      "latitude": 37.354827,
      "longitude": -85.614043,
      "city": "Summersville",
      "state": "KY",
      "county": "Green"
  },
  {
      "zipCode": 42783,
      "latitude": 37.567134,
      "longitude": -86.085259,
      "city": "Summit",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42784,
      "latitude": 37.478353,
      "longitude": -85.963435,
      "city": "Upton",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 42786,
      "latitude": 36.819839,
      "longitude": -85.462435,
      "city": "Waterview",
      "state": "KY",
      "county": "Cumberland"
  },
  {
      "zipCode": 42788,
      "latitude": 37.534951,
      "longitude": -86.033456,
      "city": "White Mills",
      "state": "KY",
      "county": "Hardin"
  },
  {
      "zipCode": 43001,
      "latitude": 40.100513,
      "longitude": -82.612389,
      "city": "Alexandria",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43002,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Amlin",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43003,
      "latitude": 40.389346,
      "longitude": -82.98071,
      "city": "Ashley",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43004,
      "latitude": 40.016713,
      "longitude": -82.839198,
      "city": "Blacklick",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43005,
      "latitude": 40.29623,
      "longitude": -82.27362,
      "city": "Bladensburg",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43006,
      "latitude": 40.477294,
      "longitude": -82.191485,
      "city": "Brinkhaven",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43007,
      "latitude": 40.340633,
      "longitude": -83.416306,
      "city": "Broadway",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43008,
      "latitude": 39.944674,
      "longitude": -82.47972,
      "city": "Buckeye Lake",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43009,
      "latitude": 40.161025,
      "longitude": -83.648461,
      "city": "Cable",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43010,
      "latitude": 39.99914,
      "longitude": -83.622204,
      "city": "Catawba",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 43011,
      "latitude": 40.313394,
      "longitude": -82.665177,
      "city": "Centerburg",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43013,
      "latitude": 40.115251,
      "longitude": -82.690898,
      "city": "Croton",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43014,
      "latitude": 40.417167,
      "longitude": -82.270253,
      "city": "Danville",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43015,
      "latitude": 40.289886,
      "longitude": -82.981095,
      "city": "Delaware",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43016,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Dublin",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43017,
      "latitude": 40.076041,
      "longitude": -82.814456,
      "city": "Dublin",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43018,
      "latitude": 39.957167,
      "longitude": -82.683686,
      "city": "Etna",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43019,
      "latitude": 40.487135,
      "longitude": -82.512463,
      "city": "Fredericktown",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43021,
      "latitude": 40.201142,
      "longitude": -82.874886,
      "city": "Galena",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43022,
      "latitude": 40.338527,
      "longitude": -82.348673,
      "city": "Gambier",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43023,
      "latitude": 40.048971,
      "longitude": -82.539941,
      "city": "Granville",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43025,
      "latitude": 39.974804,
      "longitude": -82.511108,
      "city": "Hebron",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43026,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Hilliard",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43027,
      "latitude": 40.132821,
      "longitude": -82.561556,
      "city": "Homer",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43028,
      "latitude": 40.418367,
      "longitude": -82.283974,
      "city": "Howard",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43029,
      "latitude": 40.128208,
      "longitude": -83.442548,
      "city": "Irwin",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43030,
      "latitude": 39.968846,
      "longitude": -82.430017,
      "city": "Jacksontown",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43031,
      "latitude": 40.16145,
      "longitude": -82.60439,
      "city": "Johnstown",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43032,
      "latitude": 40.313445,
      "longitude": -82.961585,
      "city": "Kilbourne",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43033,
      "latitude": 39.953532,
      "longitude": -82.593998,
      "city": "Kirkersville",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43035,
      "latitude": 40.187886,
      "longitude": -82.987804,
      "city": "Lewis Center",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43036,
      "latitude": 40.352835,
      "longitude": -83.263418,
      "city": "Magnetic Springs",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43037,
      "latitude": 40.29519,
      "longitude": -82.322187,
      "city": "Martinsburg",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43040,
      "latitude": 40.267651,
      "longitude": -83.376632,
      "city": "Marysville",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43041,
      "latitude": 40.306924,
      "longitude": -83.360645,
      "city": "Marysville",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43044,
      "latitude": 40.117805,
      "longitude": -83.587808,
      "city": "Mechanicsburg",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43045,
      "latitude": 40.170089,
      "longitude": -83.441008,
      "city": "Milford Center",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43046,
      "latitude": 39.884758,
      "longitude": -82.539214,
      "city": "Millersport",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43047,
      "latitude": 40.204914,
      "longitude": -83.646581,
      "city": "Mingo",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43048,
      "latitude": 40.406113,
      "longitude": -82.465777,
      "city": "Mount Liberty",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43050,
      "latitude": 40.37066,
      "longitude": -82.481346,
      "city": "Mount Vernon",
      "state": "OH",
      "county": "Knox"
  },
  {
      "zipCode": 43054,
      "latitude": 40.085113,
      "longitude": -82.814347,
      "city": "New Albany",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43055,
      "latitude": 40.085963,
      "longitude": -82.485985,
      "city": "Newark",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43056,
      "latitude": 40.004407,
      "longitude": -82.467105,
      "city": "Heath",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43058,
      "latitude": 40.095148,
      "longitude": -82.482659,
      "city": "Newark",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43060,
      "latitude": 40.204788,
      "longitude": -83.568531,
      "city": "North Lewisburg",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43061,
      "latitude": 40.295612,
      "longitude": -83.199506,
      "city": "Ostrander",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43062,
      "latitude": 40.014869,
      "longitude": -82.624424,
      "city": "Pataskala",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43064,
      "latitude": 40.057901,
      "longitude": -83.307323,
      "city": "Plain City",
      "state": "OH",
      "county": "Madison"
  },
  {
      "zipCode": 43065,
      "latitude": 40.183405,
      "longitude": -83.091235,
      "city": "Powell",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43066,
      "latitude": 40.380541,
      "longitude": -83.172985,
      "city": "Radnor",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43067,
      "latitude": 40.337997,
      "longitude": -83.465462,
      "city": "Raymond",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43068,
      "latitude": 39.951513,
      "longitude": -82.802896,
      "city": "Reynoldsburg",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43070,
      "latitude": 40.172288,
      "longitude": -83.962411,
      "city": "Rosewood",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43071,
      "latitude": 40.189158,
      "longitude": -82.353301,
      "city": "Saint Louisville",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43072,
      "latitude": 40.121683,
      "longitude": -83.947262,
      "city": "Saint Paris",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43073,
      "latitude": 39.996516,
      "longitude": -82.754034,
      "city": "Summit Station",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43074,
      "latitude": 40.266029,
      "longitude": -82.854334,
      "city": "Sunbury",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43076,
      "latitude": 39.851267,
      "longitude": -82.385238,
      "city": "Thornville",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43077,
      "latitude": 40.124392,
      "longitude": -83.30822,
      "city": "Unionville Center",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43078,
      "latitude": 40.121722,
      "longitude": -83.797312,
      "city": "Urbana",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43080,
      "latitude": 40.17595,
      "longitude": -82.444279,
      "city": "Utica",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43081,
      "latitude": 40.10447,
      "longitude": -82.896457,
      "city": "Westerville",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43082,
      "latitude": 40.167712,
      "longitude": -82.869636,
      "city": "Westerville",
      "state": "OH",
      "county": "Delaware"
  },
  {
      "zipCode": 43083,
      "latitude": 40.102185,
      "longitude": -83.83786,
      "city": "Westville",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43084,
      "latitude": 40.1511,
      "longitude": -83.545445,
      "city": "Woodstock",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 43085,
      "latitude": 40.040113,
      "longitude": -82.897222,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43086,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Westerville",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43093,
      "latitude": 40.095148,
      "longitude": -82.482659,
      "city": "Newark",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43098,
      "latitude": 40.095148,
      "longitude": -82.482659,
      "city": "Hebron",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43101,
      "latitude": 39.466524,
      "longitude": -82.754731,
      "city": "Adelphi",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 43102,
      "latitude": 39.698161,
      "longitude": -82.688692,
      "city": "Amanda",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43103,
      "latitude": 39.67194,
      "longitude": -83.015457,
      "city": "Ashville",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43105,
      "latitude": 39.77796,
      "longitude": -82.665141,
      "city": "Baltimore",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43106,
      "latitude": 39.64064,
      "longitude": -83.419366,
      "city": "Bloomingburg",
      "state": "OH",
      "county": "Fayette"
  },
  {
      "zipCode": 43107,
      "latitude": 39.697655,
      "longitude": -82.554876,
      "city": "Bremen",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43109,
      "latitude": 39.924238,
      "longitude": -82.846098,
      "city": "Brice",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43110,
      "latitude": 39.821343,
      "longitude": -82.764939,
      "city": "Canal Winchester",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43111,
      "latitude": 39.504455,
      "longitude": -82.242899,
      "city": "Carbon Hill",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43112,
      "latitude": 39.80114,
      "longitude": -82.706291,
      "city": "Carroll",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43113,
      "latitude": 39.597084,
      "longitude": -82.945899,
      "city": "Circleville",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43115,
      "latitude": 39.479662,
      "longitude": -83.099899,
      "city": "Clarksburg",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 43116,
      "latitude": 39.769857,
      "longitude": -83.060153,
      "city": "Commercial Point",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43117,
      "latitude": 39.773025,
      "longitude": -83.199472,
      "city": "Derby",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43119,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Galloway",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43123,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Grove City",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43125,
      "latitude": 39.84692,
      "longitude": -82.88487,
      "city": "Groveport",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43126,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Harrisburg",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43127,
      "latitude": 39.476564,
      "longitude": -82.328099,
      "city": "Haydenville",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43128,
      "latitude": 39.654055,
      "longitude": -83.572613,
      "city": "Jeffersonville",
      "state": "OH",
      "county": "Fayette"
  },
  {
      "zipCode": 43130,
      "latitude": 39.68965,
      "longitude": -82.609282,
      "city": "Lancaster",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43135,
      "latitude": 39.462546,
      "longitude": -82.666506,
      "city": "Laurelville",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43136,
      "latitude": 39.76155,
      "longitude": -82.7219,
      "city": "Lithopolis",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43137,
      "latitude": 39.761894,
      "longitude": -82.98932,
      "city": "Lockbourne",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43138,
      "latitude": 39.52535,
      "longitude": -82.437424,
      "city": "Logan",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43140,
      "latitude": 39.886277,
      "longitude": -83.407733,
      "city": "London",
      "state": "OH",
      "county": "Madison"
  },
  {
      "zipCode": 43142,
      "latitude": 39.588319,
      "longitude": -83.583292,
      "city": "Milledgeville",
      "state": "OH",
      "county": "Fayette"
  },
  {
      "zipCode": 43143,
      "latitude": 39.765249,
      "longitude": -83.344831,
      "city": "Mount Sterling",
      "state": "OH",
      "county": "Madison"
  },
  {
      "zipCode": 43144,
      "latitude": 39.505508,
      "longitude": -82.171089,
      "city": "Murray City",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43145,
      "latitude": 39.56981,
      "longitude": -83.21419,
      "city": "New Holland",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43146,
      "latitude": 39.682607,
      "longitude": -83.14155,
      "city": "Orient",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43147,
      "latitude": 39.781747,
      "longitude": -82.664536,
      "city": "Pickerington",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43148,
      "latitude": 39.82724,
      "longitude": -82.500765,
      "city": "Pleasantville",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43149,
      "latitude": 39.525792,
      "longitude": -82.606994,
      "city": "Rockbridge",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43150,
      "latitude": 39.77882,
      "longitude": -82.438446,
      "city": "Rushville",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43151,
      "latitude": 39.727083,
      "longitude": -83.452833,
      "city": "Sedalia",
      "state": "OH",
      "county": "Madison"
  },
  {
      "zipCode": 43152,
      "latitude": 39.417379,
      "longitude": -82.592291,
      "city": "South Bloomingville",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43153,
      "latitude": 39.748496,
      "longitude": -83.531355,
      "city": "South Solon",
      "state": "OH",
      "county": "Madison"
  },
  {
      "zipCode": 43154,
      "latitude": 39.616,
      "longitude": -82.80858,
      "city": "Stoutsville",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43155,
      "latitude": 39.63769,
      "longitude": -82.532092,
      "city": "Sugar Grove",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43156,
      "latitude": 39.555583,
      "longitude": -82.782171,
      "city": "Tarlton",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43157,
      "latitude": 39.842263,
      "longitude": -82.560131,
      "city": "Thurston",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43158,
      "latitude": 39.433804,
      "longitude": -82.366742,
      "city": "Union Furnace",
      "state": "OH",
      "county": "Hocking"
  },
  {
      "zipCode": 43160,
      "latitude": 39.536986,
      "longitude": -83.455012,
      "city": "Washington Court House",
      "state": "OH",
      "county": "Fayette"
  },
  {
      "zipCode": 43162,
      "latitude": 39.94886,
      "longitude": -83.309892,
      "city": "West Jefferson",
      "state": "OH",
      "county": "Madison"
  },
  {
      "zipCode": 43163,
      "latitude": 39.7631,
      "longitude": -82.447024,
      "city": "West Rushville",
      "state": "OH",
      "county": "Fairfield"
  },
  {
      "zipCode": 43164,
      "latitude": 39.604282,
      "longitude": -83.126646,
      "city": "Williamsport",
      "state": "OH",
      "county": "Pickaway"
  },
  {
      "zipCode": 43196,
      "latitude": "",
      "longitude": "",
      "city": "Groveport",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43198,
      "latitude": "",
      "longitude": "",
      "city": "Groveport",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43199,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Groveport",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43201,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43202,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43203,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43204,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43205,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43206,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43207,
      "latitude": 39.86905,
      "longitude": -82.958539,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43209,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43210,
      "latitude": 39.914038,
      "longitude": -82.832558,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43211,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43212,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43213,
      "latitude": 39.972734,
      "longitude": -82.832887,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43214,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43215,
      "latitude": 39.891745,
      "longitude": -82.877131,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43216,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43217,
      "latitude": 39.827675,
      "longitude": -82.934198,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43218,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43219,
      "latitude": 40.032615,
      "longitude": -82.910251,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43220,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43221,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43222,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43223,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43224,
      "latitude": 40.080339,
      "longitude": -82.928604,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43226,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43227,
      "latitude": 39.922863,
      "longitude": -82.858048,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43228,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43229,
      "latitude": 40.095713,
      "longitude": -82.962671,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43230,
      "latitude": 40.034713,
      "longitude": -82.872611,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43231,
      "latitude": 40.084463,
      "longitude": -82.933811,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43232,
      "latitude": 39.922663,
      "longitude": -82.85664,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43234,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43235,
      "latitude": 40.108836,
      "longitude": -82.974204,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43236,
      "latitude": 40.135711,
      "longitude": -83.007626,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43240,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43251,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43260,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43265,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43266,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43268,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43270,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43271,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43272,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43279,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43287,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43291,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43299,
      "latitude": 39.969036,
      "longitude": -83.011389,
      "city": "Columbus",
      "state": "OH",
      "county": "Franklin"
  },
  {
      "zipCode": 43301,
      "latitude": 40.616604,
      "longitude": -83.069296,
      "city": "Marion",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43302,
      "latitude": 40.584028,
      "longitude": -83.101687,
      "city": "Marion",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43306,
      "latitude": 40.56941,
      "longitude": -83.139341,
      "city": "Marion",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43307,
      "latitude": 40.56941,
      "longitude": -83.139341,
      "city": "Marion",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43310,
      "latitude": 40.491005,
      "longitude": -83.752068,
      "city": "Belle Center",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43311,
      "latitude": 40.378783,
      "longitude": -83.787784,
      "city": "Bellefontaine",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43314,
      "latitude": 40.631257,
      "longitude": -82.958179,
      "city": "Caledonia",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43315,
      "latitude": 40.479648,
      "longitude": -82.852969,
      "city": "Cardington",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43316,
      "latitude": 40.927265,
      "longitude": -83.387038,
      "city": "Carey",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 43317,
      "latitude": 40.478905,
      "longitude": -82.682805,
      "city": "Chesterville",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43318,
      "latitude": 40.374272,
      "longitude": -83.784554,
      "city": "De Graff",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43319,
      "latitude": 40.303893,
      "longitude": -83.728511,
      "city": "East Liberty",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43320,
      "latitude": 40.588234,
      "longitude": -82.890436,
      "city": "Edison",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43321,
      "latitude": 40.49712,
      "longitude": -82.834207,
      "city": "Fulton",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43322,
      "latitude": 40.541659,
      "longitude": -83.230407,
      "city": "Green Camp",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43323,
      "latitude": 40.7346,
      "longitude": -83.253668,
      "city": "Harpster",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 43324,
      "latitude": 40.43991,
      "longitude": -83.809604,
      "city": "Huntsville",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43325,
      "latitude": 40.60796,
      "longitude": -82.872954,
      "city": "Iberia",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43326,
      "latitude": 40.6401,
      "longitude": -83.616923,
      "city": "Kenton",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 43330,
      "latitude": 40.813023,
      "longitude": -83.419606,
      "city": "Kirby",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 43331,
      "latitude": 40.501851,
      "longitude": -83.92023,
      "city": "Lakeview",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43332,
      "latitude": 40.596476,
      "longitude": -83.344599,
      "city": "La Rue",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43333,
      "latitude": 40.436912,
      "longitude": -83.926452,
      "city": "Lewistown",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43334,
      "latitude": 40.406317,
      "longitude": -82.782091,
      "city": "Marengo",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43335,
      "latitude": 40.668497,
      "longitude": -82.909984,
      "city": "Martel",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43336,
      "latitude": 40.294825,
      "longitude": -83.57726,
      "city": "Middleburg",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43337,
      "latitude": 40.667978,
      "longitude": -83.26335,
      "city": "Morral",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43338,
      "latitude": 40.525904,
      "longitude": -82.752848,
      "city": "Mount Gilead",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43340,
      "latitude": 40.561493,
      "longitude": -83.495423,
      "city": "Mount Victory",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 43341,
      "latitude": 40.599228,
      "longitude": -83.308663,
      "city": "New Bloomington",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43342,
      "latitude": 40.492106,
      "longitude": -83.178414,
      "city": "Prospect",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43343,
      "latitude": 40.391089,
      "longitude": -83.792897,
      "city": "Quincy",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43344,
      "latitude": 40.427182,
      "longitude": -83.352079,
      "city": "Richwood",
      "state": "OH",
      "county": "Union"
  },
  {
      "zipCode": 43345,
      "latitude": 40.532668,
      "longitude": -83.58265,
      "city": "Ridgeway",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 43346,
      "latitude": 40.573227,
      "longitude": -83.846199,
      "city": "Roundhead",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 43347,
      "latitude": 40.474334,
      "longitude": -83.650983,
      "city": "Rushsylvania",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43348,
      "latitude": 40.402746,
      "longitude": -83.890243,
      "city": "Russells Point",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43349,
      "latitude": 40.614239,
      "longitude": -82.661938,
      "city": "Shauck",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43350,
      "latitude": 40.528862,
      "longitude": -82.82292,
      "city": "Sparta",
      "state": "OH",
      "county": "Morrow"
  },
  {
      "zipCode": 43351,
      "latitude": 40.828153,
      "longitude": -83.313786,
      "city": "Upper Sandusky",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 43356,
      "latitude": 40.475651,
      "longitude": -83.048607,
      "city": "Waldo",
      "state": "OH",
      "county": "Marion"
  },
  {
      "zipCode": 43357,
      "latitude": 40.278247,
      "longitude": -83.713148,
      "city": "West Liberty",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43358,
      "latitude": 40.36691,
      "longitude": -83.625912,
      "city": "West Mansfield",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43359,
      "latitude": 40.875991,
      "longitude": -83.448021,
      "city": "Wharton",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 43360,
      "latitude": 40.321114,
      "longitude": -83.640462,
      "city": "Zanesfield",
      "state": "OH",
      "county": "Logan"
  },
  {
      "zipCode": 43402,
      "latitude": 41.376449,
      "longitude": -83.613714,
      "city": "Bowling Green",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43403,
      "latitude": 41.39235,
      "longitude": -83.648996,
      "city": "Bowling Green",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43406,
      "latitude": 41.334031,
      "longitude": -83.542092,
      "city": "Bradner",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43407,
      "latitude": 41.278621,
      "longitude": -83.244604,
      "city": "Burgoon",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43408,
      "latitude": 41.568599,
      "longitude": -83.363224,
      "city": "Clay Center",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43410,
      "latitude": 41.321281,
      "longitude": -82.948337,
      "city": "Clyde",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43412,
      "latitude": 41.597798,
      "longitude": -83.357328,
      "city": "Curtice",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43413,
      "latitude": 41.226545,
      "longitude": -83.664525,
      "city": "Cygnet",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43414,
      "latitude": 41.39235,
      "longitude": -83.648996,
      "city": "Dunbridge",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43416,
      "latitude": 41.57267,
      "longitude": -83.078075,
      "city": "Elmore",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43420,
      "latitude": 41.35622,
      "longitude": -83.133981,
      "city": "Fremont",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43430,
      "latitude": 41.524657,
      "longitude": -83.352477,
      "city": "Genoa",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43431,
      "latitude": 41.392094,
      "longitude": -83.317648,
      "city": "Gibsonburg",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43432,
      "latitude": 41.564723,
      "longitude": -83.26128,
      "city": "Graytown",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43433,
      "latitude": 41.503759,
      "longitude": -82.87084,
      "city": "Gypsum",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43434,
      "latitude": 41.645699,
      "longitude": -83.620233,
      "city": "Harbor View",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43435,
      "latitude": 41.325996,
      "longitude": -83.318648,
      "city": "Helena",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43436,
      "latitude": 41.715332,
      "longitude": -82.822697,
      "city": "Isle Saint George",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43437,
      "latitude": 41.255902,
      "longitude": -83.602198,
      "city": "Jerry City",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43438,
      "latitude": 41.59593,
      "longitude": -82.706816,
      "city": "Kelleys Island",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 43439,
      "latitude": 41.518115,
      "longitude": -83.041312,
      "city": "Lacarne",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43440,
      "latitude": 41.524698,
      "longitude": -82.776567,
      "city": "Lakeside Marblehead",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43441,
      "latitude": 41.536132,
      "longitude": -83.45938,
      "city": "Lemoyne",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43442,
      "latitude": 41.422628,
      "longitude": -83.231171,
      "city": "Lindsey",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43443,
      "latitude": 41.457596,
      "longitude": -83.473648,
      "city": "Luckey",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43445,
      "latitude": 41.569398,
      "longitude": -83.311577,
      "city": "Martin",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43446,
      "latitude": 41.683477,
      "longitude": -82.804677,
      "city": "Middle Bass",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43447,
      "latitude": 41.426265,
      "longitude": -83.512454,
      "city": "Millbury",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43449,
      "latitude": 41.53653,
      "longitude": -83.144869,
      "city": "Oak Harbor",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43450,
      "latitude": 41.398798,
      "longitude": -83.484086,
      "city": "Pemberville",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43451,
      "latitude": 41.315346,
      "longitude": -83.621091,
      "city": "Portage",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43452,
      "latitude": 41.558318,
      "longitude": -83.050219,
      "city": "Port Clinton",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43456,
      "latitude": 41.647236,
      "longitude": -82.819009,
      "city": "Put In Bay",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43457,
      "latitude": 41.336722,
      "longitude": -83.466992,
      "city": "Risingsun",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43458,
      "latitude": 41.530157,
      "longitude": -83.21276,
      "city": "Rocky Ridge",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43460,
      "latitude": 41.458371,
      "longitude": -83.529646,
      "city": "Rossford",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43462,
      "latitude": 41.283902,
      "longitude": -83.72564,
      "city": "Rudolph",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43463,
      "latitude": 41.509051,
      "longitude": -83.508531,
      "city": "Stony Ridge",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43464,
      "latitude": 41.396673,
      "longitude": -82.921319,
      "city": "Vickery",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43465,
      "latitude": 41.462958,
      "longitude": -83.49109,
      "city": "Walbridge",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43466,
      "latitude": 41.296984,
      "longitude": -83.524683,
      "city": "Wayne",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43467,
      "latitude": 41.24264,
      "longitude": -83.489583,
      "city": "West Millgrove",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43468,
      "latitude": 41.605048,
      "longitude": -83.338128,
      "city": "Williston",
      "state": "OH",
      "county": "Ottawa"
  },
  {
      "zipCode": 43469,
      "latitude": 41.42021,
      "longitude": -83.31686,
      "city": "Woodville",
      "state": "OH",
      "county": "Sandusky"
  },
  {
      "zipCode": 43501,
      "latitude": 41.577786,
      "longitude": -84.584125,
      "city": "Alvordton",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43502,
      "latitude": 41.578355,
      "longitude": -84.25106,
      "city": "Archbold",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43504,
      "latitude": 41.606301,
      "longitude": -83.822288,
      "city": "Berkey",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43505,
      "latitude": 41.523945,
      "longitude": -84.730275,
      "city": "Blakeslee",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43506,
      "latitude": 41.553851,
      "longitude": -84.585209,
      "city": "Bryan",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43510,
      "latitude": 41.419878,
      "longitude": -84.013674,
      "city": "Colton",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43511,
      "latitude": 41.259285,
      "longitude": -83.811421,
      "city": "Custar",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43512,
      "latitude": 41.296657,
      "longitude": -84.390413,
      "city": "Defiance",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43515,
      "latitude": 41.58568,
      "longitude": -84.124257,
      "city": "Delta",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43516,
      "latitude": 41.232641,
      "longitude": -83.949391,
      "city": "Deshler",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43517,
      "latitude": 41.554099,
      "longitude": -84.620862,
      "city": "Edgerton",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43518,
      "latitude": 41.58754,
      "longitude": -84.738401,
      "city": "Edon",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43519,
      "latitude": 41.422158,
      "longitude": -84.396619,
      "city": "Evansport",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43520,
      "latitude": 41.39066,
      "longitude": -84.631322,
      "city": "Farmer",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43521,
      "latitude": 41.649036,
      "longitude": -84.28596,
      "city": "Fayette",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43522,
      "latitude": 41.394707,
      "longitude": -83.834935,
      "city": "Grand Rapids",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43523,
      "latitude": 41.341081,
      "longitude": -84.000532,
      "city": "Grelton",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43524,
      "latitude": 41.230082,
      "longitude": -84.035807,
      "city": "Hamler",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43525,
      "latitude": 41.466702,
      "longitude": -83.705486,
      "city": "Haskins",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43526,
      "latitude": 41.325013,
      "longitude": -84.678021,
      "city": "Hicksville",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43527,
      "latitude": 41.23175,
      "longitude": -84.129612,
      "city": "Holgate",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43528,
      "latitude": 41.628501,
      "longitude": -83.751138,
      "city": "Holland",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43529,
      "latitude": 41.187234,
      "longitude": -83.784704,
      "city": "Hoytville",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43530,
      "latitude": 41.325764,
      "longitude": -84.279306,
      "city": "Jewell",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43531,
      "latitude": 41.634007,
      "longitude": -84.502598,
      "city": "Kunkle",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43532,
      "latitude": 41.429356,
      "longitude": -84.008448,
      "city": "Liberty Center",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43533,
      "latitude": 41.68575,
      "longitude": -84.161891,
      "city": "Lyons",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43534,
      "latitude": 41.356582,
      "longitude": -83.941313,
      "city": "Mc Clure",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43535,
      "latitude": 41.305439,
      "longitude": -84.003632,
      "city": "Malinta",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43536,
      "latitude": 41.31085,
      "longitude": -84.640393,
      "city": "Mark Center",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43537,
      "latitude": 41.571251,
      "longitude": -83.685036,
      "city": "Maumee",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43540,
      "latitude": 41.688656,
      "longitude": -84.104541,
      "city": "Metamora",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43541,
      "latitude": 41.300891,
      "longitude": -83.829596,
      "city": "Milton Center",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43542,
      "latitude": 41.577251,
      "longitude": -83.772378,
      "city": "Monclova",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43543,
      "latitude": 41.606061,
      "longitude": -84.588777,
      "city": "Montpelier",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43545,
      "latitude": 41.355586,
      "longitude": -84.123028,
      "city": "Napoleon",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43547,
      "latitude": 41.491952,
      "longitude": -83.870598,
      "city": "Neapolis",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43548,
      "latitude": 41.194308,
      "longitude": -84.141421,
      "city": "New Bavaria",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43549,
      "latitude": 41.361864,
      "longitude": -84.51582,
      "city": "Ney",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43550,
      "latitude": 41.326664,
      "longitude": -84.111701,
      "city": "Okolona",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43551,
      "latitude": 41.535057,
      "longitude": -83.577701,
      "city": "Perrysburg",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43552,
      "latitude": 41.39235,
      "longitude": -83.648996,
      "city": "Perrysburg",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43553,
      "latitude": 41.531033,
      "longitude": -84.225646,
      "city": "Pettisville",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43554,
      "latitude": 41.648218,
      "longitude": -84.585241,
      "city": "Pioneer",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43555,
      "latitude": 41.435059,
      "longitude": -84.254414,
      "city": "Ridgeville Corners",
      "state": "OH",
      "county": "Henry"
  },
  {
      "zipCode": 43556,
      "latitude": 41.318045,
      "longitude": -84.569545,
      "city": "Sherwood",
      "state": "OH",
      "county": "Defiance"
  },
  {
      "zipCode": 43557,
      "latitude": 41.488708,
      "longitude": -84.409876,
      "city": "Stryker",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43558,
      "latitude": 41.593787,
      "longitude": -84.103035,
      "city": "Swanton",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43560,
      "latitude": 41.6929,
      "longitude": -83.727737,
      "city": "Sylvania",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43565,
      "latitude": 41.423102,
      "longitude": -83.747936,
      "city": "Tontogany",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43566,
      "latitude": 41.513751,
      "longitude": -83.765287,
      "city": "Waterville",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43567,
      "latitude": 41.59151,
      "longitude": -84.178148,
      "city": "Wauseon",
      "state": "OH",
      "county": "Fulton"
  },
  {
      "zipCode": 43569,
      "latitude": 41.35002,
      "longitude": -83.796033,
      "city": "Weston",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43570,
      "latitude": 41.591453,
      "longitude": -84.447033,
      "city": "West Unity",
      "state": "OH",
      "county": "Williams"
  },
  {
      "zipCode": 43571,
      "latitude": 41.509602,
      "longitude": -83.801789,
      "city": "Whitehouse",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43601,
      "latitude": 41.720684,
      "longitude": -83.569359,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43602,
      "latitude": 41.647449,
      "longitude": -83.548128,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43603,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43604,
      "latitude": 41.655798,
      "longitude": -83.536833,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43605,
      "latitude": 41.652548,
      "longitude": -83.508482,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43606,
      "latitude": 41.674099,
      "longitude": -83.604535,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43607,
      "latitude": 41.647399,
      "longitude": -83.606785,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43608,
      "latitude": 41.681648,
      "longitude": -83.531983,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43609,
      "latitude": 41.624749,
      "longitude": -83.584234,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43610,
      "latitude": 41.673449,
      "longitude": -83.556083,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43611,
      "latitude": 41.698298,
      "longitude": -83.486382,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43612,
      "latitude": 41.695847,
      "longitude": -83.460181,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43613,
      "latitude": 41.703548,
      "longitude": -83.606235,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43614,
      "latitude": 41.609041,
      "longitude": -83.633941,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43615,
      "latitude": 41.6496,
      "longitude": -83.678586,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43616,
      "latitude": 41.656948,
      "longitude": -83.44433,
      "city": "Oregon",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43617,
      "latitude": 41.66765,
      "longitude": -83.722437,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43618,
      "latitude": 41.656948,
      "longitude": -83.399129,
      "city": "Oregon",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43619,
      "latitude": 41.596099,
      "longitude": -83.485681,
      "city": "Northwood",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 43620,
      "latitude": 41.664849,
      "longitude": -83.553933,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43623,
      "latitude": 41.702949,
      "longitude": -83.682686,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43624,
      "latitude": 41.654599,
      "longitude": -83.546233,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43635,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43652,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43653,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43654,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43655,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43656,
      "latitude": 41.678167,
      "longitude": -83.497155,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43657,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43659,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43660,
      "latitude": 41.654649,
      "longitude": -83.532883,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43661,
      "latitude": 41.678167,
      "longitude": -83.497155,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43666,
      "latitude": 41.678167,
      "longitude": -83.497155,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43667,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43681,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43682,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43697,
      "latitude": 41.686778,
      "longitude": -83.43943,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43699,
      "latitude": 41.653788,
      "longitude": -83.658937,
      "city": "Toledo",
      "state": "OH",
      "county": "Lucas"
  },
  {
      "zipCode": 43701,
      "latitude": 39.927415,
      "longitude": -82.004058,
      "city": "Zanesville",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43702,
      "latitude": 39.961236,
      "longitude": -81.962539,
      "city": "Zanesville",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43711,
      "latitude": 39.827556,
      "longitude": -81.554854,
      "city": "Ava",
      "state": "OH",
      "county": "Noble"
  },
  {
      "zipCode": 43713,
      "latitude": 39.985318,
      "longitude": -81.137495,
      "city": "Barnesville",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43716,
      "latitude": 39.780188,
      "longitude": -80.991731,
      "city": "Beallsville",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43717,
      "latitude": 39.768475,
      "longitude": -81.465752,
      "city": "Belle Valley",
      "state": "OH",
      "county": "Noble"
  },
  {
      "zipCode": 43718,
      "latitude": 40.004009,
      "longitude": -80.988043,
      "city": "Belmont",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43719,
      "latitude": 39.996312,
      "longitude": -81.065991,
      "city": "Bethesda",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43720,
      "latitude": 39.820021,
      "longitude": -81.876343,
      "city": "Blue Rock",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43721,
      "latitude": 39.94518,
      "longitude": -82.255984,
      "city": "Brownsville",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43722,
      "latitude": 39.91944,
      "longitude": -81.53113,
      "city": "Buffalo",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43723,
      "latitude": 39.953185,
      "longitude": -81.531919,
      "city": "Byesville",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43724,
      "latitude": 39.744446,
      "longitude": -81.483049,
      "city": "Caldwell",
      "state": "OH",
      "county": "Noble"
  },
  {
      "zipCode": 43725,
      "latitude": 40.02701,
      "longitude": -81.582025,
      "city": "Cambridge",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43727,
      "latitude": 39.865211,
      "longitude": -81.799363,
      "city": "Chandlersville",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43728,
      "latitude": 39.497841,
      "longitude": -81.898307,
      "city": "Chesterhill",
      "state": "OH",
      "county": "Morgan"
  },
  {
      "zipCode": 43730,
      "latitude": 39.636069,
      "longitude": -82.110219,
      "city": "Corning",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43731,
      "latitude": 39.704142,
      "longitude": -82.165011,
      "city": "Crooksville",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43732,
      "latitude": 39.88812,
      "longitude": -81.617372,
      "city": "Cumberland",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43733,
      "latitude": 39.923616,
      "longitude": -81.542965,
      "city": "Derwent",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43734,
      "latitude": 39.872071,
      "longitude": -81.89422,
      "city": "Duncan Falls",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43735,
      "latitude": 39.853931,
      "longitude": -82.121262,
      "city": "East Fultonham",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43736,
      "latitude": 40.064971,
      "longitude": -81.255793,
      "city": "Fairview",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43738,
      "latitude": 39.855525,
      "longitude": -82.137754,
      "city": "Fultonham",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43739,
      "latitude": 39.884312,
      "longitude": -82.297706,
      "city": "Glenford",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43740,
      "latitude": 39.953262,
      "longitude": -82.211784,
      "city": "Gratiot",
      "state": "OH",
      "county": "Licking"
  },
  {
      "zipCode": 43746,
      "latitude": 39.970024,
      "longitude": -82.174647,
      "city": "Hopewell",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43747,
      "latitude": 39.82588,
      "longitude": -81.123561,
      "city": "Jerusalem",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43748,
      "latitude": 39.684697,
      "longitude": -82.302787,
      "city": "Junction City",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43749,
      "latitude": 40.158911,
      "longitude": -81.548992,
      "city": "Kimbolton",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43750,
      "latitude": 39.994467,
      "longitude": -81.500579,
      "city": "Kipling",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43752,
      "latitude": 39.717086,
      "longitude": -81.009999,
      "city": "Laings",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43754,
      "latitude": 39.74607,
      "longitude": -81.227706,
      "city": "Lewisville",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43755,
      "latitude": 40.052671,
      "longitude": -81.435723,
      "city": "Lore City",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43756,
      "latitude": 39.676688,
      "longitude": -81.81256,
      "city": "Mc Connelsville",
      "state": "OH",
      "county": "Morgan"
  },
  {
      "zipCode": 43757,
      "latitude": 39.859373,
      "longitude": -81.151604,
      "city": "Malaga",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43758,
      "latitude": 39.632381,
      "longitude": -81.908715,
      "city": "Malta",
      "state": "OH",
      "county": "Morgan"
  },
  {
      "zipCode": 43759,
      "latitude": 40.011338,
      "longitude": -80.970135,
      "city": "Morristown",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43760,
      "latitude": 39.760789,
      "longitude": -82.20775,
      "city": "Mount Perry",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43761,
      "latitude": 39.740299,
      "longitude": -82.248369,
      "city": "Moxahala",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43762,
      "latitude": 40.024342,
      "longitude": -81.767017,
      "city": "New Concord",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43764,
      "latitude": 39.706051,
      "longitude": -82.20614,
      "city": "New Lexington",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43766,
      "latitude": 39.619477,
      "longitude": -82.252758,
      "city": "New Straitsville",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43767,
      "latitude": 40.011106,
      "longitude": -81.804302,
      "city": "Norwich",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43768,
      "latitude": 40.039201,
      "longitude": -81.452164,
      "city": "Old Washington",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43771,
      "latitude": 39.822159,
      "longitude": -81.954582,
      "city": "Philo",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43772,
      "latitude": 39.918285,
      "longitude": -81.551647,
      "city": "Pleasant City",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43773,
      "latitude": 40.040755,
      "longitude": -81.313561,
      "city": "Quaker City",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43777,
      "latitude": 39.821869,
      "longitude": -82.039389,
      "city": "Roseville",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43778,
      "latitude": 40.015591,
      "longitude": -81.373692,
      "city": "Salesville",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43779,
      "latitude": 39.816285,
      "longitude": -81.428046,
      "city": "Sarahsville",
      "state": "OH",
      "county": "Noble"
  },
  {
      "zipCode": 43780,
      "latitude": 39.9427,
      "longitude": -81.458577,
      "city": "Senecaville",
      "state": "OH",
      "county": "Guernsey"
  },
  {
      "zipCode": 43782,
      "latitude": 39.625239,
      "longitude": -82.214886,
      "city": "Shawnee",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43783,
      "latitude": 39.736529,
      "longitude": -82.278894,
      "city": "Somerset",
      "state": "OH",
      "county": "Perry"
  },
  {
      "zipCode": 43786,
      "latitude": 39.711168,
      "longitude": -81.275824,
      "city": "Stafford",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43787,
      "latitude": 39.548994,
      "longitude": -81.826194,
      "city": "Stockport",
      "state": "OH",
      "county": "Morgan"
  },
  {
      "zipCode": 43788,
      "latitude": 39.795107,
      "longitude": -81.370927,
      "city": "Summerfield",
      "state": "OH",
      "county": "Noble"
  },
  {
      "zipCode": 43789,
      "latitude": 39.654386,
      "longitude": -81.240732,
      "city": "Sycamore Valley",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43791,
      "latitude": 39.87133,
      "longitude": -82.098668,
      "city": "White Cottage",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43793,
      "latitude": 39.751516,
      "longitude": -81.075921,
      "city": "Woodsfield",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43802,
      "latitude": 40.090767,
      "longitude": -81.855203,
      "city": "Adamsville",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43803,
      "latitude": 40.357237,
      "longitude": -81.643638,
      "city": "Bakersville",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43804,
      "latitude": 40.426559,
      "longitude": -81.67444,
      "city": "Baltic",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 43805,
      "latitude": 40.398274,
      "longitude": -81.968787,
      "city": "Blissfield",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43811,
      "latitude": 40.247685,
      "longitude": -81.929225,
      "city": "Conesville",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43812,
      "latitude": 40.300934,
      "longitude": -81.864066,
      "city": "Coshocton",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43821,
      "latitude": 40.106916,
      "longitude": -81.999822,
      "city": "Dresden",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43822,
      "latitude": 40.108668,
      "longitude": -82.103212,
      "city": "Frazeysburg",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43824,
      "latitude": 40.364667,
      "longitude": -81.755507,
      "city": "Fresno",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43828,
      "latitude": 40.351271,
      "longitude": -81.873607,
      "city": "Keene",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43830,
      "latitude": 40.063883,
      "longitude": -82.099574,
      "city": "Nashport",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43832,
      "latitude": 40.33031,
      "longitude": -81.571033,
      "city": "Newcomerstown",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 43836,
      "latitude": 40.200382,
      "longitude": -81.720675,
      "city": "Plainfield",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43837,
      "latitude": 40.306936,
      "longitude": -81.467895,
      "city": "Port Washington",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 43840,
      "latitude": 40.404888,
      "longitude": -81.602889,
      "city": "Stone Creek",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 43842,
      "latitude": 40.143323,
      "longitude": -81.995016,
      "city": "Trinway",
      "state": "OH",
      "county": "Muskingum"
  },
  {
      "zipCode": 43843,
      "latitude": 40.340702,
      "longitude": -82.155887,
      "city": "Walhonding",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43844,
      "latitude": 40.3198,
      "longitude": -81.958266,
      "city": "Warsaw",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43845,
      "latitude": 40.27446,
      "longitude": -81.82464,
      "city": "West Lafayette",
      "state": "OH",
      "county": "Coshocton"
  },
  {
      "zipCode": 43901,
      "latitude": 40.260579,
      "longitude": -80.789377,
      "city": "Adena",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43902,
      "latitude": 39.894917,
      "longitude": -80.980056,
      "city": "Alledonia",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43903,
      "latitude": 40.486354,
      "longitude": -80.84505,
      "city": "Amsterdam",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43905,
      "latitude": 40.107367,
      "longitude": -80.84441,
      "city": "Barton",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43906,
      "latitude": 39.991864,
      "longitude": -80.805716,
      "city": "Bellaire",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43907,
      "latitude": 40.263163,
      "longitude": -81.030687,
      "city": "Cadiz",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43908,
      "latitude": 40.50022,
      "longitude": -80.856529,
      "city": "Bergholz",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43909,
      "latitude": 40.070102,
      "longitude": -80.817605,
      "city": "Blaine",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43910,
      "latitude": 40.341244,
      "longitude": -80.774815,
      "city": "Bloomingdale",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43912,
      "latitude": 40.092799,
      "longitude": -80.788948,
      "city": "Bridgeport",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43913,
      "latitude": 40.323767,
      "longitude": -80.768624,
      "city": "Brilliant",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43914,
      "latitude": 39.768729,
      "longitude": -80.945431,
      "city": "Cameron",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43915,
      "latitude": 39.744008,
      "longitude": -80.903818,
      "city": "Clarington",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43916,
      "latitude": 40.122351,
      "longitude": -80.814948,
      "city": "Colerain",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43917,
      "latitude": 40.333811,
      "longitude": -80.77266,
      "city": "Dillonvale",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43920,
      "latitude": 40.677372,
      "longitude": -80.600629,
      "city": "East Liverpool",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 43925,
      "latitude": 40.415106,
      "longitude": -80.756661,
      "city": "East Springfield",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43926,
      "latitude": 40.509225,
      "longitude": -80.624051,
      "city": "Empire",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43927,
      "latitude": 40.12245,
      "longitude": -80.938031,
      "city": "Fairpoint",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43928,
      "latitude": 40.010896,
      "longitude": -80.899286,
      "city": "Glencoe",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43930,
      "latitude": 40.460885,
      "longitude": -80.719014,
      "city": "Hammondsville",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43931,
      "latitude": 39.676317,
      "longitude": -80.891369,
      "city": "Hannibal",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43932,
      "latitude": 40.525504,
      "longitude": -80.763971,
      "city": "Irondale",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43933,
      "latitude": 39.937228,
      "longitude": -80.888014,
      "city": "Jacobsburg",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43934,
      "latitude": 40.079975,
      "longitude": -80.800316,
      "city": "Lansing",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43935,
      "latitude": 40.117231,
      "longitude": -80.758087,
      "city": "Martins Ferry",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43937,
      "latitude": 40.130167,
      "longitude": -80.877264,
      "city": "Maynard",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43938,
      "latitude": 40.358475,
      "longitude": -80.762054,
      "city": "Mingo Junction",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43939,
      "latitude": 40.17699,
      "longitude": -80.782734,
      "city": "Mount Pleasant",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43940,
      "latitude": 40.028448,
      "longitude": -80.824454,
      "city": "Neffs",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43941,
      "latitude": 40.263948,
      "longitude": -80.835767,
      "city": "Piney Fork",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43942,
      "latitude": 40.006175,
      "longitude": -80.960745,
      "city": "Powhatan Point",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43943,
      "latitude": 40.337357,
      "longitude": -80.771562,
      "city": "Rayland",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43944,
      "latitude": 40.441964,
      "longitude": -80.791768,
      "city": "Richmond",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43945,
      "latitude": 40.649533,
      "longitude": -80.822333,
      "city": "Salineville",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 43946,
      "latitude": 39.646018,
      "longitude": -80.970678,
      "city": "Sardis",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 43947,
      "latitude": 39.950713,
      "longitude": -80.804364,
      "city": "Shadyside",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43948,
      "latitude": 40.28006,
      "longitude": -80.784927,
      "city": "Smithfield",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43950,
      "latitude": 40.077792,
      "longitude": -80.978797,
      "city": "Saint Clairsville",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43951,
      "latitude": 40.11098,
      "longitude": -81.010169,
      "city": "Lafferty",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43952,
      "latitude": 40.349541,
      "longitude": -80.746759,
      "city": "Steubenville",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43953,
      "latitude": 40.352418,
      "longitude": -80.678099,
      "city": "Steubenville",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43961,
      "latitude": 40.518479,
      "longitude": -80.628515,
      "city": "Stratton",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43962,
      "latitude": 40.679456,
      "longitude": -80.881583,
      "city": "Summitville",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 43963,
      "latitude": 40.22676,
      "longitude": -80.738107,
      "city": "Tiltonsville",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43964,
      "latitude": 40.477049,
      "longitude": -80.670057,
      "city": "Toronto",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43966,
      "latitude": 40.360979,
      "longitude": -80.839519,
      "city": "Unionport",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43967,
      "latitude": 40.020007,
      "longitude": -80.916901,
      "city": "Warnock",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43968,
      "latitude": 40.650362,
      "longitude": -80.692008,
      "city": "Wellsville",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 43970,
      "latitude": 40.46947,
      "longitude": -80.889206,
      "city": "Wolf Run",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43971,
      "latitude": 40.338699,
      "longitude": -80.810216,
      "city": "Yorkville",
      "state": "OH",
      "county": "Jefferson"
  },
  {
      "zipCode": 43972,
      "latitude": 40.103155,
      "longitude": -80.975639,
      "city": "Bannock",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43973,
      "latitude": 40.230697,
      "longitude": -81.223669,
      "city": "Freeport",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43974,
      "latitude": 40.181476,
      "longitude": -80.888159,
      "city": "Harrisville",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43976,
      "latitude": 40.314449,
      "longitude": -81.070506,
      "city": "Hopedale",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43977,
      "latitude": 40.115634,
      "longitude": -81.090224,
      "city": "Flushing",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43981,
      "latitude": 40.183933,
      "longitude": -80.995856,
      "city": "New Athens",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43983,
      "latitude": 40.134188,
      "longitude": -81.194538,
      "city": "Piedmont",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43984,
      "latitude": 40.29649,
      "longitude": -81.102502,
      "city": "New Rumley",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43985,
      "latitude": 40.011338,
      "longitude": -80.970135,
      "city": "Holloway",
      "state": "OH",
      "county": "Belmont"
  },
  {
      "zipCode": 43986,
      "latitude": 40.370274,
      "longitude": -80.996826,
      "city": "Jewett",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43988,
      "latitude": 40.307421,
      "longitude": -81.104831,
      "city": "Scio",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 43989,
      "latitude": 40.29649,
      "longitude": -81.102502,
      "city": "Short Creek",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 44001,
      "latitude": 41.361746,
      "longitude": -82.253828,
      "city": "Amherst",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44003,
      "latitude": 41.740136,
      "longitude": -80.602278,
      "city": "Andover",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44004,
      "latitude": 41.772583,
      "longitude": -80.74349,
      "city": "Ashtabula",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44005,
      "latitude": 41.730146,
      "longitude": -80.955313,
      "city": "Ashtabula",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44010,
      "latitude": 41.772459,
      "longitude": -80.856463,
      "city": "Austinburg",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44011,
      "latitude": 41.448492,
      "longitude": -82.019866,
      "city": "Avon",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44012,
      "latitude": 41.49674,
      "longitude": -82.017527,
      "city": "Avon Lake",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44017,
      "latitude": 41.456905,
      "longitude": -81.693019,
      "city": "Berea",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44021,
      "latitude": 41.447117,
      "longitude": -81.152938,
      "city": "Burton",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44022,
      "latitude": 41.449353,
      "longitude": -81.431436,
      "city": "Chagrin Falls",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44023,
      "latitude": 41.387203,
      "longitude": -81.304222,
      "city": "Chagrin Falls",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44024,
      "latitude": 41.572432,
      "longitude": -81.157142,
      "city": "Chardon",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44026,
      "latitude": 41.530351,
      "longitude": -81.307472,
      "city": "Chesterland",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44028,
      "latitude": 41.3056,
      "longitude": -81.949111,
      "city": "Columbia Station",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44030,
      "latitude": 41.756356,
      "longitude": -80.616195,
      "city": "Conneaut",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44032,
      "latitude": 41.677076,
      "longitude": -80.673288,
      "city": "Dorset",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44033,
      "latitude": 41.53335,
      "longitude": -81.111175,
      "city": "East Claridon",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44035,
      "latitude": 41.371396,
      "longitude": -82.105112,
      "city": "Elyria",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44036,
      "latitude": 41.401494,
      "longitude": -82.077068,
      "city": "Elyria",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44039,
      "latitude": 41.377776,
      "longitude": -82.019365,
      "city": "North Ridgeville",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44040,
      "latitude": 41.513251,
      "longitude": -81.414597,
      "city": "Gates Mills",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44041,
      "latitude": 41.736396,
      "longitude": -80.785627,
      "city": "Geneva",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44044,
      "latitude": 41.2745,
      "longitude": -82.046168,
      "city": "Grafton",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44045,
      "latitude": 41.742693,
      "longitude": -81.282125,
      "city": "Grand River",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44046,
      "latitude": 41.538433,
      "longitude": -81.073366,
      "city": "Huntsburg",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44047,
      "latitude": 41.700536,
      "longitude": -80.693315,
      "city": "Jefferson",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44048,
      "latitude": 41.846175,
      "longitude": -80.647299,
      "city": "Kingsville",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44049,
      "latitude": 41.26635,
      "longitude": -82.306383,
      "city": "Kipton",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44050,
      "latitude": 41.258201,
      "longitude": -82.119671,
      "city": "Lagrange",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44052,
      "latitude": 41.450991,
      "longitude": -82.166534,
      "city": "Lorain",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44053,
      "latitude": 41.425292,
      "longitude": -82.218185,
      "city": "Lorain",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44054,
      "latitude": 41.463541,
      "longitude": -82.089769,
      "city": "Sheffield Lake",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44055,
      "latitude": 41.434492,
      "longitude": -82.134021,
      "city": "Lorain",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44056,
      "latitude": 41.311288,
      "longitude": -81.492511,
      "city": "Macedonia",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44057,
      "latitude": 41.781467,
      "longitude": -81.073247,
      "city": "Madison",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44060,
      "latitude": 41.679291,
      "longitude": -81.328779,
      "city": "Mentor",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44061,
      "latitude": 41.910676,
      "longitude": -81.249027,
      "city": "Mentor",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44062,
      "latitude": 41.453242,
      "longitude": -81.068303,
      "city": "Middlefield",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44064,
      "latitude": 41.603114,
      "longitude": -81.051767,
      "city": "Montville",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44065,
      "latitude": 41.456099,
      "longitude": -81.273831,
      "city": "Newbury",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44067,
      "latitude": 41.306454,
      "longitude": -81.54079,
      "city": "Northfield",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44068,
      "latitude": 41.904594,
      "longitude": -80.685034,
      "city": "North Kingsville",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44070,
      "latitude": 41.417347,
      "longitude": -81.922461,
      "city": "North Olmsted",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44072,
      "latitude": 41.468754,
      "longitude": -81.328211,
      "city": "Novelty",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44073,
      "latitude": 41.837043,
      "longitude": -80.955545,
      "city": "Novelty",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44074,
      "latitude": 41.294649,
      "longitude": -82.227628,
      "city": "Oberlin",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44076,
      "latitude": 41.588584,
      "longitude": -80.834349,
      "city": "Orwell",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44077,
      "latitude": 41.707918,
      "longitude": -81.19903,
      "city": "Painesville",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44080,
      "latitude": 41.364495,
      "longitude": -81.053411,
      "city": "Parkman",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44081,
      "latitude": 41.768495,
      "longitude": -81.14626,
      "city": "Perry",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44082,
      "latitude": 41.769302,
      "longitude": -80.573251,
      "city": "Pierpont",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44084,
      "latitude": 41.689765,
      "longitude": -80.905118,
      "city": "Rock Creek",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44085,
      "latitude": 41.624828,
      "longitude": -80.770777,
      "city": "Rome",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44086,
      "latitude": 41.673116,
      "longitude": -81.052453,
      "city": "Thompson",
      "state": "OH",
      "county": "Geauga"
  },
  {
      "zipCode": 44087,
      "latitude": 41.313304,
      "longitude": -81.440435,
      "city": "Twinsburg",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44088,
      "latitude": 41.783323,
      "longitude": -81.003385,
      "city": "Unionville",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44089,
      "latitude": 41.380755,
      "longitude": -82.401637,
      "city": "Vermilion",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44090,
      "latitude": 41.160846,
      "longitude": -82.211778,
      "city": "Wellington",
      "state": "OH",
      "county": "Lorain"
  },
  {
      "zipCode": 44092,
      "latitude": 41.597299,
      "longitude": -81.458638,
      "city": "Wickliffe",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44093,
      "latitude": 41.535263,
      "longitude": -80.726693,
      "city": "Williamsfield",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44094,
      "latitude": 41.637997,
      "longitude": -81.377383,
      "city": "Willoughby",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44095,
      "latitude": 41.658653,
      "longitude": -81.444531,
      "city": "Eastlake",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44096,
      "latitude": 41.910676,
      "longitude": -81.249027,
      "city": "Willoughby",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44097,
      "latitude": 41.910676,
      "longitude": -81.249027,
      "city": "Eastlake",
      "state": "OH",
      "county": "Lake"
  },
  {
      "zipCode": 44099,
      "latitude": 41.549414,
      "longitude": -80.967673,
      "city": "Windsor",
      "state": "OH",
      "county": "Ashtabula"
  },
  {
      "zipCode": 44101,
      "latitude": 41.523401,
      "longitude": -81.599648,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44102,
      "latitude": 41.47225,
      "longitude": -81.740305,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44103,
      "latitude": 41.51805,
      "longitude": -81.6425,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44104,
      "latitude": 41.480502,
      "longitude": -81.629599,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44105,
      "latitude": 41.452502,
      "longitude": -81.632912,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44106,
      "latitude": 41.504751,
      "longitude": -81.602799,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44107,
      "latitude": 41.514849,
      "longitude": -81.637249,
      "city": "Lakewood",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44108,
      "latitude": 41.54345,
      "longitude": -81.608297,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44109,
      "latitude": 41.445963,
      "longitude": -81.698483,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44110,
      "latitude": 41.5641,
      "longitude": -81.57156,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44111,
      "latitude": 41.4595,
      "longitude": -81.787006,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44112,
      "latitude": 41.528632,
      "longitude": -81.545576,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44113,
      "latitude": 41.473782,
      "longitude": -81.64297,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44114,
      "latitude": 41.5145,
      "longitude": -81.677302,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44115,
      "latitude": 41.490351,
      "longitude": -81.675602,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44116,
      "latitude": 41.469497,
      "longitude": -81.849209,
      "city": "Rocky River",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44117,
      "latitude": 41.566834,
      "longitude": -81.526142,
      "city": "Euclid",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44118,
      "latitude": 41.500752,
      "longitude": -81.551645,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44119,
      "latitude": 41.583849,
      "longitude": -81.544409,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44120,
      "latitude": 41.473102,
      "longitude": -81.579746,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44121,
      "latitude": 41.527651,
      "longitude": -81.532344,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44122,
      "latitude": 41.473248,
      "longitude": -81.505389,
      "city": "Beachwood",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44123,
      "latitude": 41.602548,
      "longitude": -81.525842,
      "city": "Euclid",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44124,
      "latitude": 41.501001,
      "longitude": -81.469427,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44125,
      "latitude": 41.43349,
      "longitude": -81.632298,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44126,
      "latitude": 41.442927,
      "longitude": -81.852959,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44127,
      "latitude": 41.472551,
      "longitude": -81.64919,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44128,
      "latitude": 41.439352,
      "longitude": -81.533893,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44129,
      "latitude": 41.437052,
      "longitude": -81.626348,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44130,
      "latitude": 41.3826,
      "longitude": -81.796406,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44131,
      "latitude": 41.395652,
      "longitude": -81.64231,
      "city": "Independence",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44132,
      "latitude": 41.606049,
      "longitude": -81.510858,
      "city": "Euclid",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44133,
      "latitude": 41.314603,
      "longitude": -81.745102,
      "city": "North Royalton",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44134,
      "latitude": 41.381701,
      "longitude": -81.726551,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44135,
      "latitude": 41.4797,
      "longitude": -81.653799,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44136,
      "latitude": 41.313268,
      "longitude": -81.831656,
      "city": "Strongsville",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44137,
      "latitude": 41.404703,
      "longitude": -81.557493,
      "city": "Maple Heights",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44138,
      "latitude": 41.4656,
      "longitude": -81.768304,
      "city": "Olmsted Falls",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44139,
      "latitude": 41.386753,
      "longitude": -81.440322,
      "city": "Solon",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44140,
      "latitude": 41.490967,
      "longitude": -81.921513,
      "city": "Bay Village",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44141,
      "latitude": 41.362052,
      "longitude": -81.643247,
      "city": "Brecksville",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44142,
      "latitude": 41.401,
      "longitude": -81.832107,
      "city": "Brookpark",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44143,
      "latitude": 41.55915,
      "longitude": -81.48279,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44144,
      "latitude": 41.438501,
      "longitude": -81.739804,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44145,
      "latitude": 41.445145,
      "longitude": -81.921811,
      "city": "Westlake",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44146,
      "latitude": 41.389054,
      "longitude": -81.536842,
      "city": "Bedford",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44147,
      "latitude": 41.314053,
      "longitude": -81.673099,
      "city": "Broadview Heights",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44149,
      "latitude": 41.311458,
      "longitude": -81.853087,
      "city": "Strongsville",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44177,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44178,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44179,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44181,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44184,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44185,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44186,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44188,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44189,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44190,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44191,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44192,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44193,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44194,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44195,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44197,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44198,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44199,
      "latitude": 41.685744,
      "longitude": -81.672797,
      "city": "Cleveland",
      "state": "OH",
      "county": "Cuyahoga"
  },
  {
      "zipCode": 44201,
      "latitude": 41.040507,
      "longitude": -81.194614,
      "city": "Atwater",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44202,
      "latitude": 41.311704,
      "longitude": -81.292977,
      "city": "Aurora",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44203,
      "latitude": 41.019655,
      "longitude": -81.621195,
      "city": "Barberton",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44210,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Bath",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44211,
      "latitude": 41.169816,
      "longitude": -81.312416,
      "city": "Brady Lake",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44212,
      "latitude": 41.206132,
      "longitude": -81.82281,
      "city": "Brunswick",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44214,
      "latitude": 40.927483,
      "longitude": -81.996351,
      "city": "Burbank",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44215,
      "latitude": 41.065342,
      "longitude": -81.901693,
      "city": "Chippewa Lake",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44216,
      "latitude": 40.948706,
      "longitude": -81.571443,
      "city": "Clinton",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44217,
      "latitude": 40.945417,
      "longitude": -81.912937,
      "city": "Creston",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44221,
      "latitude": 41.143906,
      "longitude": -81.473585,
      "city": "Cuyahoga Falls",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44222,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Cuyahoga Falls",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44223,
      "latitude": 41.170605,
      "longitude": -81.526738,
      "city": "Cuyahoga Falls",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44224,
      "latitude": 41.172402,
      "longitude": -81.450184,
      "city": "Stow",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44230,
      "latitude": 40.958926,
      "longitude": -81.770787,
      "city": "Doylestown",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44231,
      "latitude": 41.291405,
      "longitude": -81.081534,
      "city": "Garrettsville",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44232,
      "latitude": 40.932506,
      "longitude": -81.461987,
      "city": "Green",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44233,
      "latitude": 41.23648,
      "longitude": -81.736072,
      "city": "Hinckley",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44234,
      "latitude": 41.310354,
      "longitude": -81.1652,
      "city": "Hiram",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44235,
      "latitude": 41.038796,
      "longitude": -82.113597,
      "city": "Homerville",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44236,
      "latitude": 41.244023,
      "longitude": -81.476335,
      "city": "Hudson",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44237,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Hudson",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44238,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Hudson",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44240,
      "latitude": 41.205705,
      "longitude": -81.313278,
      "city": "Kent",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44241,
      "latitude": 41.241655,
      "longitude": -81.340528,
      "city": "Streetsboro",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44242,
      "latitude": 41.170006,
      "longitude": -81.196615,
      "city": "Kent",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44243,
      "latitude": 41.147537,
      "longitude": -81.341531,
      "city": "Kent",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44250,
      "latitude": 41.022156,
      "longitude": -81.427942,
      "city": "Lakemore",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44251,
      "latitude": 41.028837,
      "longitude": -81.928342,
      "city": "Westfield Center",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44253,
      "latitude": 41.162553,
      "longitude": -82.020109,
      "city": "Litchfield",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44254,
      "latitude": 41.044913,
      "longitude": -81.992065,
      "city": "Lodi",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44255,
      "latitude": 41.282355,
      "longitude": -81.229924,
      "city": "Mantua",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44256,
      "latitude": 41.109975,
      "longitude": -81.855048,
      "city": "Medina",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44258,
      "latitude": 41.127594,
      "longitude": -81.841079,
      "city": "Medina",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44260,
      "latitude": 41.026954,
      "longitude": -81.404982,
      "city": "Mogadore",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44262,
      "latitude": 41.138206,
      "longitude": -81.431677,
      "city": "Munroe Falls",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44264,
      "latitude": 41.230355,
      "longitude": -81.549261,
      "city": "Peninsula",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44265,
      "latitude": 41.010907,
      "longitude": -81.297727,
      "city": "Randolph",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44266,
      "latitude": 41.163755,
      "longitude": -81.172922,
      "city": "Ravenna",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44270,
      "latitude": 40.949448,
      "longitude": -81.862397,
      "city": "Rittman",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44272,
      "latitude": 41.078728,
      "longitude": -81.173572,
      "city": "Rootstown",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44273,
      "latitude": 41.044552,
      "longitude": -81.960694,
      "city": "Seville",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44274,
      "latitude": 41.09924,
      "longitude": -81.734329,
      "city": "Sharon Center",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44275,
      "latitude": 41.081789,
      "longitude": -82.052299,
      "city": "Spencer",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44276,
      "latitude": 40.936853,
      "longitude": -81.830515,
      "city": "Sterling",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44278,
      "latitude": 41.096656,
      "longitude": -81.428883,
      "city": "Tallmadge",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44280,
      "latitude": 41.233736,
      "longitude": -81.917187,
      "city": "Valley City",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44281,
      "latitude": 41.071841,
      "longitude": -81.751008,
      "city": "Wadsworth",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44282,
      "latitude": 41.132725,
      "longitude": -81.929185,
      "city": "Wadsworth",
      "state": "OH",
      "county": "Medina"
  },
  {
      "zipCode": 44285,
      "latitude": 41.159706,
      "longitude": -81.070017,
      "city": "Wayland",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44286,
      "latitude": 41.235492,
      "longitude": -81.621093,
      "city": "Richfield",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44287,
      "latitude": 40.925084,
      "longitude": -82.070909,
      "city": "West Salem",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44288,
      "latitude": 41.239066,
      "longitude": -81.096118,
      "city": "Windham",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44301,
      "latitude": 41.012239,
      "longitude": -81.507831,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44302,
      "latitude": 41.090605,
      "longitude": -81.539039,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44303,
      "latitude": 41.104355,
      "longitude": -81.535089,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44304,
      "latitude": 41.083293,
      "longitude": -81.506187,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44305,
      "latitude": 41.078706,
      "longitude": -81.458435,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44306,
      "latitude": 41.041805,
      "longitude": -81.479986,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44307,
      "latitude": 41.073207,
      "longitude": -81.543889,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44308,
      "latitude": 41.082455,
      "longitude": -81.517438,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44309,
      "latitude": 41.096205,
      "longitude": -81.512338,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44310,
      "latitude": 41.105505,
      "longitude": -81.498182,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44311,
      "latitude": 41.064955,
      "longitude": -81.522838,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44312,
      "latitude": 41.018961,
      "longitude": -81.443312,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44313,
      "latitude": 41.16112,
      "longitude": -81.576591,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44314,
      "latitude": 41.041555,
      "longitude": -81.559791,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44315,
      "latitude": 41.027977,
      "longitude": -81.463238,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44316,
      "latitude": 41.067506,
      "longitude": -81.484686,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44317,
      "latitude": 41.052455,
      "longitude": -81.529139,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44319,
      "latitude": 40.978755,
      "longitude": -81.524376,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44320,
      "latitude": 41.07739,
      "longitude": -81.579391,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44321,
      "latitude": 41.100154,
      "longitude": -81.644346,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44322,
      "latitude": 41.049143,
      "longitude": -81.581032,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44325,
      "latitude": 41.076425,
      "longitude": -81.51026,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44326,
      "latitude": 41.172713,
      "longitude": -81.472701,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44328,
      "latitude": 41.075955,
      "longitude": -81.520588,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44333,
      "latitude": 41.155154,
      "longitude": -81.631394,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44334,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Fairlawn",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44372,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44393,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44396,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44397,
      "latitude": 41.071603,
      "longitude": -81.525556,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44398,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44399,
      "latitude": 41.128705,
      "longitude": -81.53999,
      "city": "Akron",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44401,
      "latitude": 41.034545,
      "longitude": -80.928626,
      "city": "Berlin Center",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44402,
      "latitude": 41.376806,
      "longitude": -80.866704,
      "city": "Bristolville",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44403,
      "latitude": 41.238176,
      "longitude": -80.573511,
      "city": "Brookfield",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44404,
      "latitude": 41.329006,
      "longitude": -80.559111,
      "city": "Burghill",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44405,
      "latitude": 41.077708,
      "longitude": -80.591894,
      "city": "Campbell",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44406,
      "latitude": 41.008621,
      "longitude": -80.822094,
      "city": "Canfield",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44408,
      "latitude": 40.857858,
      "longitude": -80.730023,
      "city": "Columbiana",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44410,
      "latitude": 41.354945,
      "longitude": -80.778301,
      "city": "Cortland",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44411,
      "latitude": 41.037807,
      "longitude": -81.061016,
      "city": "Deerfield",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44412,
      "latitude": 41.102836,
      "longitude": -81.049216,
      "city": "Diamond",
      "state": "OH",
      "county": "Portage"
  },
  {
      "zipCode": 44413,
      "latitude": 40.837509,
      "longitude": -80.646556,
      "city": "East Palestine",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44415,
      "latitude": 40.76304,
      "longitude": -80.704245,
      "city": "Elkton",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44416,
      "latitude": 41.017082,
      "longitude": -80.802854,
      "city": "Ellsworth",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44417,
      "latitude": 41.416766,
      "longitude": -80.667111,
      "city": "Farmdale",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44418,
      "latitude": 41.307463,
      "longitude": -80.602868,
      "city": "Fowler",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44420,
      "latitude": 41.17133,
      "longitude": -80.795028,
      "city": "Girard",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44422,
      "latitude": 41.017082,
      "longitude": -80.802854,
      "city": "Greenford",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44423,
      "latitude": 40.760843,
      "longitude": -80.887866,
      "city": "Hanoverton",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44424,
      "latitude": 41.309096,
      "longitude": -80.584681,
      "city": "Hartford",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44425,
      "latitude": 41.236088,
      "longitude": -80.736973,
      "city": "Hubbard",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44427,
      "latitude": 40.729298,
      "longitude": -80.942791,
      "city": "Kensington",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44428,
      "latitude": 41.327247,
      "longitude": -80.601336,
      "city": "Kinsman",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44429,
      "latitude": 41.097587,
      "longitude": -80.973988,
      "city": "Lake Milton",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44430,
      "latitude": 41.24112,
      "longitude": -80.886138,
      "city": "Leavittsburg",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44431,
      "latitude": 40.837984,
      "longitude": -80.749844,
      "city": "Leetonia",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44432,
      "latitude": 40.743051,
      "longitude": -80.745313,
      "city": "Lisbon",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44436,
      "latitude": 41.056428,
      "longitude": -80.549561,
      "city": "Lowellville",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44437,
      "latitude": 41.151895,
      "longitude": -80.751899,
      "city": "Mc Donald",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44438,
      "latitude": 41.263559,
      "longitude": -80.54444,
      "city": "Masury",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44439,
      "latitude": 41.459359,
      "longitude": -80.942716,
      "city": "Mesopotamia",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44440,
      "latitude": 41.168441,
      "longitude": -80.78209,
      "city": "Mineral Ridge",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44441,
      "latitude": 40.763029,
      "longitude": -80.572088,
      "city": "Negley",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44442,
      "latitude": 40.965525,
      "longitude": -80.54902,
      "city": "New Middletown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44443,
      "latitude": 40.928876,
      "longitude": -80.587833,
      "city": "New Springfield",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44444,
      "latitude": 41.197392,
      "longitude": -80.952731,
      "city": "Newton Falls",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44445,
      "latitude": 40.843906,
      "longitude": -80.614381,
      "city": "New Waterford",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44446,
      "latitude": 41.186106,
      "longitude": -80.742459,
      "city": "Niles",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44449,
      "latitude": 40.967155,
      "longitude": -81.021052,
      "city": "North Benton",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44450,
      "latitude": 41.443995,
      "longitude": -80.818751,
      "city": "North Bloomfield",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44451,
      "latitude": 41.075435,
      "longitude": -80.86694,
      "city": "North Jackson",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44452,
      "latitude": 40.951177,
      "longitude": -80.662484,
      "city": "North Lima",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44453,
      "latitude": 41.32519,
      "longitude": -80.533572,
      "city": "Orangeville",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44454,
      "latitude": 40.929,
      "longitude": -80.54758,
      "city": "Petersburg",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44455,
      "latitude": 40.778137,
      "longitude": -80.605674,
      "city": "Rogers",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44460,
      "latitude": 40.862743,
      "longitude": -80.763459,
      "city": "Salem",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44470,
      "latitude": 41.283579,
      "longitude": -80.952784,
      "city": "Southington",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44471,
      "latitude": 41.050058,
      "longitude": -80.591298,
      "city": "Struthers",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44473,
      "latitude": 41.2656,
      "longitude": -80.704184,
      "city": "Vienna",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44481,
      "latitude": 41.248912,
      "longitude": -80.867431,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44482,
      "latitude": 41.317424,
      "longitude": -80.761269,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44483,
      "latitude": 41.242576,
      "longitude": -80.720029,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44484,
      "latitude": 41.241952,
      "longitude": -80.760752,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44485,
      "latitude": 41.249104,
      "longitude": -80.8439,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44486,
      "latitude": 41.317424,
      "longitude": -80.761269,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44487,
      "latitude": 41.317424,
      "longitude": -80.761269,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44488,
      "latitude": 41.317424,
      "longitude": -80.761269,
      "city": "Warren",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44490,
      "latitude": 40.896699,
      "longitude": -80.767064,
      "city": "Washingtonville",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44491,
      "latitude": 41.368863,
      "longitude": -80.950285,
      "city": "West Farmington",
      "state": "OH",
      "county": "Trumbull"
  },
  {
      "zipCode": 44492,
      "latitude": 40.709282,
      "longitude": -80.70141,
      "city": "West Point",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44493,
      "latitude": 40.828584,
      "longitude": -80.896714,
      "city": "Winona",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44501,
      "latitude": 41.017082,
      "longitude": -80.802854,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44502,
      "latitude": 41.087352,
      "longitude": -80.638546,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44503,
      "latitude": 41.099258,
      "longitude": -80.655146,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44504,
      "latitude": 41.122607,
      "longitude": -80.65525,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44505,
      "latitude": 41.108057,
      "longitude": -80.616596,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44506,
      "latitude": 41.095258,
      "longitude": -80.626295,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44507,
      "latitude": 41.074413,
      "longitude": -80.655046,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44509,
      "latitude": 41.109533,
      "longitude": -80.693098,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44510,
      "latitude": 41.120407,
      "longitude": -80.668447,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44511,
      "latitude": 41.067608,
      "longitude": -80.698121,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44512,
      "latitude": 41.025158,
      "longitude": -80.668667,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44513,
      "latitude": 41.017082,
      "longitude": -80.802854,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44514,
      "latitude": 41.009287,
      "longitude": -80.618344,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44515,
      "latitude": 41.097937,
      "longitude": -80.759813,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44555,
      "latitude": 41.103858,
      "longitude": -80.643646,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44598,
      "latitude": 41.017082,
      "longitude": -80.802854,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44599,
      "latitude": 41.017082,
      "longitude": -80.802854,
      "city": "Youngstown",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44601,
      "latitude": 40.820292,
      "longitude": -81.335275,
      "city": "Alliance",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44606,
      "latitude": 40.733099,
      "longitude": -81.763389,
      "city": "Apple Creek",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44607,
      "latitude": 40.686028,
      "longitude": -81.02187,
      "city": "Augusta",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44608,
      "latitude": 40.665824,
      "longitude": -81.592696,
      "city": "Beach City",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44609,
      "latitude": 40.941802,
      "longitude": -80.969891,
      "city": "Beloit",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44610,
      "latitude": 40.547385,
      "longitude": -81.876602,
      "city": "Berlin",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44611,
      "latitude": 40.598522,
      "longitude": -82.086715,
      "city": "Big Prairie",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44612,
      "latitude": 40.618002,
      "longitude": -81.449534,
      "city": "Bolivar",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44613,
      "latitude": 40.714208,
      "longitude": -81.595746,
      "city": "Brewster",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44614,
      "latitude": 40.808555,
      "longitude": -81.488163,
      "city": "Canal Fulton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44615,
      "latitude": 40.571861,
      "longitude": -81.064859,
      "city": "Carrollton",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44617,
      "latitude": 40.507088,
      "longitude": -81.782872,
      "city": "Charm",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44618,
      "latitude": 40.757197,
      "longitude": -81.76092,
      "city": "Dalton",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44619,
      "latitude": 40.902146,
      "longitude": -80.962843,
      "city": "Damascus",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44620,
      "latitude": 40.587709,
      "longitude": -81.226442,
      "city": "Dellroy",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44621,
      "latitude": 40.416656,
      "longitude": -81.352297,
      "city": "Dennison",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44622,
      "latitude": 40.492563,
      "longitude": -81.47911,
      "city": "Dover",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44624,
      "latitude": 40.589023,
      "longitude": -81.605824,
      "city": "Dundee",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44625,
      "latitude": 40.770542,
      "longitude": -80.981107,
      "city": "East Rochester",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44626,
      "latitude": 40.695388,
      "longitude": -81.381386,
      "city": "East Sparta",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44627,
      "latitude": 40.696545,
      "longitude": -81.831036,
      "city": "Fredericksburg",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44628,
      "latitude": 40.53833,
      "longitude": -82.128771,
      "city": "Glenmont",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44629,
      "latitude": 40.335928,
      "longitude": -81.430921,
      "city": "Gnadenhutten",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44630,
      "latitude": 40.929507,
      "longitude": -81.400134,
      "city": "Greentown",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44631,
      "latitude": 40.521924,
      "longitude": -81.003619,
      "city": "Harlem Springs",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44632,
      "latitude": 40.822158,
      "longitude": -81.442635,
      "city": "Hartville",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44633,
      "latitude": 40.624618,
      "longitude": -81.937501,
      "city": "Holmesville",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44634,
      "latitude": 40.830059,
      "longitude": -81.033298,
      "city": "Homeworth",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44636,
      "latitude": 40.738387,
      "longitude": -81.742811,
      "city": "Kidron",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44637,
      "latitude": 40.501625,
      "longitude": -82.041222,
      "city": "Killbuck",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44638,
      "latitude": 40.614615,
      "longitude": -82.128493,
      "city": "Lakeville",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44639,
      "latitude": 40.547688,
      "longitude": -81.243498,
      "city": "Leesville",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44640,
      "latitude": 40.983558,
      "longitude": -81.149664,
      "city": "Limaville",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44641,
      "latitude": 40.802406,
      "longitude": -81.390056,
      "city": "Louisville",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44643,
      "latitude": 40.679881,
      "longitude": -81.312133,
      "city": "Magnolia",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44644,
      "latitude": 40.590393,
      "longitude": -81.200395,
      "city": "Malvern",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44645,
      "latitude": 40.908987,
      "longitude": -81.725668,
      "city": "Marshallville",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44646,
      "latitude": 40.850643,
      "longitude": -81.43535,
      "city": "Massillon",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44647,
      "latitude": 40.801057,
      "longitude": -81.575795,
      "city": "Massillon",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44648,
      "latitude": 40.811809,
      "longitude": -81.368284,
      "city": "Massillon",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44650,
      "latitude": 40.874558,
      "longitude": -81.173873,
      "city": "Maximo",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44651,
      "latitude": 40.629918,
      "longitude": -80.959514,
      "city": "Mechanicstown",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44652,
      "latitude": 40.895057,
      "longitude": -81.326195,
      "city": "Middlebranch",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44653,
      "latitude": 40.437981,
      "longitude": -81.372875,
      "city": "Midvale",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44654,
      "latitude": 40.544738,
      "longitude": -81.878772,
      "city": "Millersburg",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44656,
      "latitude": 40.570507,
      "longitude": -81.343581,
      "city": "Mineral City",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44657,
      "latitude": 40.749759,
      "longitude": -81.175266,
      "city": "Minerva",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44659,
      "latitude": 40.690225,
      "longitude": -81.692787,
      "city": "Mount Eaton",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44660,
      "latitude": 40.622284,
      "longitude": -81.782532,
      "city": "Mount Hope",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44661,
      "latitude": 40.593256,
      "longitude": -82.11676,
      "city": "Nashville",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44662,
      "latitude": 40.708566,
      "longitude": -81.535805,
      "city": "Navarre",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44663,
      "latitude": 40.511632,
      "longitude": -81.458777,
      "city": "New Philadelphia",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44665,
      "latitude": 40.843563,
      "longitude": -80.979435,
      "city": "North Georgetown",
      "state": "OH",
      "county": "Columbiana"
  },
  {
      "zipCode": 44666,
      "latitude": 40.824937,
      "longitude": -81.613396,
      "city": "North Lawrence",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44667,
      "latitude": 40.788723,
      "longitude": -81.850598,
      "city": "Orrville",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44669,
      "latitude": 40.797908,
      "longitude": -81.145349,
      "city": "Paris",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44670,
      "latitude": 40.764741,
      "longitude": -81.187875,
      "city": "Robertsville",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44671,
      "latitude": 40.644174,
      "longitude": -81.365254,
      "city": "Sandyville",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44672,
      "latitude": 40.920492,
      "longitude": -81.021736,
      "city": "Sebring",
      "state": "OH",
      "county": "Mahoning"
  },
  {
      "zipCode": 44675,
      "latitude": 40.49571,
      "longitude": -81.216271,
      "city": "Sherrodsville",
      "state": "OH",
      "county": "Carroll"
  },
  {
      "zipCode": 44676,
      "latitude": 40.71617,
      "longitude": -82.028231,
      "city": "Shreve",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44677,
      "latitude": 40.872545,
      "longitude": -81.855266,
      "city": "Smithville",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44678,
      "latitude": 40.56488,
      "longitude": -81.352352,
      "city": "Somerdale",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44679,
      "latitude": 40.440407,
      "longitude": -81.488516,
      "city": "Stillwater",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44680,
      "latitude": 40.598698,
      "longitude": -81.549884,
      "city": "Strasburg",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44681,
      "latitude": 40.473446,
      "longitude": -81.553581,
      "city": "Sugarcreek",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44682,
      "latitude": 40.395881,
      "longitude": -81.406883,
      "city": "Tuscarawas",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44683,
      "latitude": 40.451788,
      "longitude": -81.356471,
      "city": "Uhrichsville",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44685,
      "latitude": 40.969521,
      "longitude": -81.458185,
      "city": "Uniontown",
      "state": "OH",
      "county": "Summit"
  },
  {
      "zipCode": 44687,
      "latitude": 40.551575,
      "longitude": -81.728416,
      "city": "Walnut Creek",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44688,
      "latitude": 40.692071,
      "longitude": -81.274199,
      "city": "Waynesburg",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44689,
      "latitude": 40.657981,
      "longitude": -81.638348,
      "city": "Wilmot",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44690,
      "latitude": 40.616536,
      "longitude": -81.681707,
      "city": "Winesburg",
      "state": "OH",
      "county": "Holmes"
  },
  {
      "zipCode": 44691,
      "latitude": 40.830699,
      "longitude": -81.895377,
      "city": "Wooster",
      "state": "OH",
      "county": "Wayne"
  },
  {
      "zipCode": 44693,
      "latitude": 40.294686,
      "longitude": -81.183933,
      "city": "Deersville",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 44695,
      "latitude": 40.398846,
      "longitude": -81.175864,
      "city": "Bowerston",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 44697,
      "latitude": 40.61829,
      "longitude": -81.414245,
      "city": "Zoar",
      "state": "OH",
      "county": "Tuscarawas"
  },
  {
      "zipCode": 44699,
      "latitude": 40.277046,
      "longitude": -81.262892,
      "city": "Tippecanoe",
      "state": "OH",
      "county": "Harrison"
  },
  {
      "zipCode": 44701,
      "latitude": 40.782408,
      "longitude": -81.371185,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44702,
      "latitude": 40.800108,
      "longitude": -81.379535,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44703,
      "latitude": 40.808808,
      "longitude": -81.379886,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44704,
      "latitude": 40.799158,
      "longitude": -81.345934,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44705,
      "latitude": 40.830092,
      "longitude": -81.330583,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44706,
      "latitude": 40.741553,
      "longitude": -81.430161,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44707,
      "latitude": 40.759808,
      "longitude": -81.350034,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44708,
      "latitude": 40.825707,
      "longitude": -81.436938,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44709,
      "latitude": 40.80488,
      "longitude": -81.387905,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44710,
      "latitude": 40.789508,
      "longitude": -81.426988,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44711,
      "latitude": 40.811809,
      "longitude": -81.368284,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44712,
      "latitude": 40.811809,
      "longitude": -81.368284,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44714,
      "latitude": 40.834457,
      "longitude": -81.360834,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44718,
      "latitude": 40.846532,
      "longitude": -81.440791,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44720,
      "latitude": 40.895607,
      "longitude": -81.433036,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44721,
      "latitude": 40.891608,
      "longitude": -81.318381,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44730,
      "latitude": 40.770507,
      "longitude": -81.26598,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44735,
      "latitude": 40.811809,
      "longitude": -81.368284,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44750,
      "latitude": 40.784646,
      "longitude": -81.418943,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44760,
      "latitude": 40.853958,
      "longitude": -81.42778,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44767,
      "latitude": 40.895721,
      "longitude": -81.424552,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44798,
      "latitude": 40.811809,
      "longitude": -81.368284,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44799,
      "latitude": 40.811809,
      "longitude": -81.368284,
      "city": "Canton",
      "state": "OH",
      "county": "Stark"
  },
  {
      "zipCode": 44801,
      "latitude": 41.090712,
      "longitude": -83.365404,
      "city": "Adrian",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44802,
      "latitude": 41.054782,
      "longitude": -83.391792,
      "city": "Alvada",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44803,
      "latitude": 41.037511,
      "longitude": -82.910552,
      "city": "Amsden",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44804,
      "latitude": 41.115906,
      "longitude": -83.535416,
      "city": "Arcadia",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 44805,
      "latitude": 40.826965,
      "longitude": -82.296131,
      "city": "Ashland",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44807,
      "latitude": 41.077343,
      "longitude": -82.903207,
      "city": "Attica",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44809,
      "latitude": 41.132816,
      "longitude": -83.285361,
      "city": "Bascom",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44811,
      "latitude": 41.216488,
      "longitude": -82.793687,
      "city": "Bellevue",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44813,
      "latitude": 40.646184,
      "longitude": -82.481491,
      "city": "Bellville",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44814,
      "latitude": 41.338364,
      "longitude": -82.459915,
      "city": "Berlin Heights",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44815,
      "latitude": 41.246929,
      "longitude": -83.239806,
      "city": "Bettsville",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44816,
      "latitude": 41.334898,
      "longitude": -82.355035,
      "city": "Birmingham",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44817,
      "latitude": 41.217112,
      "longitude": -83.535075,
      "city": "Bloomdale",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 44818,
      "latitude": 41.046546,
      "longitude": -82.968764,
      "city": "Bloomville",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44820,
      "latitude": 40.832674,
      "longitude": -82.973876,
      "city": "Bucyrus",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44822,
      "latitude": 40.587193,
      "longitude": -82.406952,
      "city": "Butler",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44824,
      "latitude": 41.366624,
      "longitude": -82.785818,
      "city": "Castalia",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44825,
      "latitude": 40.965022,
      "longitude": -82.936698,
      "city": "Chatfield",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44826,
      "latitude": 41.166039,
      "longitude": -82.489,
      "city": "Collins",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44827,
      "latitude": 40.824607,
      "longitude": -82.909054,
      "city": "Crestline",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44828,
      "latitude": 41.23707,
      "longitude": -82.859705,
      "city": "Flat Rock",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44830,
      "latitude": 41.159551,
      "longitude": -83.152472,
      "city": "Fostoria",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44833,
      "latitude": 40.746972,
      "longitude": -82.823,
      "city": "Galion",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44836,
      "latitude": 41.186418,
      "longitude": -83.042327,
      "city": "Green Springs",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44837,
      "latitude": 41.119451,
      "longitude": -82.564392,
      "city": "Greenwich",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44838,
      "latitude": 40.768006,
      "longitude": -82.249991,
      "city": "Hayesville",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44839,
      "latitude": 41.375658,
      "longitude": -82.538619,
      "city": "Huron",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44840,
      "latitude": 40.78933,
      "longitude": -82.206323,
      "city": "Jeromesville",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44841,
      "latitude": 41.228968,
      "longitude": -83.310194,
      "city": "Kansas",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44842,
      "latitude": 40.656997,
      "longitude": -82.236734,
      "city": "Loudonville",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44843,
      "latitude": 40.653256,
      "longitude": -82.454979,
      "city": "Lucas",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44844,
      "latitude": 40.958503,
      "longitude": -83.247403,
      "city": "Mc Cutchenville",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 44845,
      "latitude": 41.024204,
      "longitude": -83.109783,
      "city": "Melmore",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44846,
      "latitude": 41.320775,
      "longitude": -82.610479,
      "city": "Milan",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44847,
      "latitude": 41.192976,
      "longitude": -82.696315,
      "city": "Monroeville",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44848,
      "latitude": 40.920617,
      "longitude": -82.281695,
      "city": "Nankin",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44849,
      "latitude": 40.837199,
      "longitude": -83.150116,
      "city": "Nevada",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 44850,
      "latitude": 41.034952,
      "longitude": -82.676973,
      "city": "New Haven",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44851,
      "latitude": 41.124479,
      "longitude": -82.437501,
      "city": "New London",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44853,
      "latitude": 41.061468,
      "longitude": -83.298986,
      "city": "New Riegel",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44854,
      "latitude": 40.942627,
      "longitude": -82.841736,
      "city": "New Washington",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44855,
      "latitude": 41.109479,
      "longitude": -82.58654,
      "city": "North Fairfield",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44856,
      "latitude": 40.793372,
      "longitude": -82.856557,
      "city": "North Robinson",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44857,
      "latitude": 41.204562,
      "longitude": -82.573222,
      "city": "Norwalk",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44859,
      "latitude": 41.011373,
      "longitude": -82.329107,
      "city": "Nova",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44860,
      "latitude": 40.835677,
      "longitude": -83.102857,
      "city": "Oceola",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44861,
      "latitude": 41.235206,
      "longitude": -83.15236,
      "city": "Old Fort",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44862,
      "latitude": 40.772874,
      "longitude": -82.532057,
      "city": "Ontario",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44864,
      "latitude": 40.674683,
      "longitude": -82.308743,
      "city": "Perrysville",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44865,
      "latitude": 40.849371,
      "longitude": -82.57178,
      "city": "Plymouth",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44866,
      "latitude": 40.922051,
      "longitude": -82.192733,
      "city": "Polk",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44867,
      "latitude": 41.146881,
      "longitude": -82.970007,
      "city": "Republic",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44870,
      "latitude": 41.399129,
      "longitude": -82.724417,
      "city": "Sandusky",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44871,
      "latitude": 41.49181,
      "longitude": -82.647824,
      "city": "Sandusky",
      "state": "OH",
      "county": "Erie"
  },
  {
      "zipCode": 44874,
      "latitude": 40.964517,
      "longitude": -82.388476,
      "city": "Savannah",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44875,
      "latitude": 40.848784,
      "longitude": -82.571998,
      "city": "Shelby",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44878,
      "latitude": 40.926203,
      "longitude": -82.528622,
      "city": "Shiloh",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44880,
      "latitude": 41.028371,
      "longitude": -82.242915,
      "city": "Sullivan",
      "state": "OH",
      "county": "Ashland"
  },
  {
      "zipCode": 44881,
      "latitude": 40.868645,
      "longitude": -82.875027,
      "city": "Sulphur Springs",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44882,
      "latitude": 40.94245,
      "longitude": -83.192192,
      "city": "Sycamore",
      "state": "OH",
      "county": "Wyandot"
  },
  {
      "zipCode": 44883,
      "latitude": 41.12453,
      "longitude": -83.144773,
      "city": "Tiffin",
      "state": "OH",
      "county": "Seneca"
  },
  {
      "zipCode": 44887,
      "latitude": 40.914732,
      "longitude": -82.806099,
      "city": "Tiro",
      "state": "OH",
      "county": "Crawford"
  },
  {
      "zipCode": 44888,
      "latitude": 41.141541,
      "longitude": -82.588928,
      "city": "Willard",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44889,
      "latitude": 41.211052,
      "longitude": -82.417989,
      "city": "Wakeman",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44890,
      "latitude": 41.120678,
      "longitude": -82.697369,
      "city": "Willard",
      "state": "OH",
      "county": "Huron"
  },
  {
      "zipCode": 44901,
      "latitude": 40.850833,
      "longitude": -82.511369,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44902,
      "latitude": 40.762711,
      "longitude": -82.51189,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44903,
      "latitude": 40.80142,
      "longitude": -82.538356,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44904,
      "latitude": 40.682416,
      "longitude": -82.528564,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44905,
      "latitude": 40.777896,
      "longitude": -82.461347,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44906,
      "latitude": 40.756992,
      "longitude": -82.588394,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44907,
      "latitude": 40.726256,
      "longitude": -82.52284,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 44999,
      "latitude": 40.772874,
      "longitude": -82.532057,
      "city": "Mansfield",
      "state": "OH",
      "county": "Richland"
  },
  {
      "zipCode": 45001,
      "latitude": 39.135158,
      "longitude": -84.709797,
      "city": "Addyston",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45002,
      "latitude": 39.191546,
      "longitude": -84.742376,
      "city": "Cleves",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45003,
      "latitude": 39.547254,
      "longitude": -84.599698,
      "city": "College Corner",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45004,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Collinsville",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45005,
      "latitude": 39.526049,
      "longitude": -84.186961,
      "city": "Franklin",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45011,
      "latitude": 39.425137,
      "longitude": -84.472925,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45012,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45013,
      "latitude": 39.436146,
      "longitude": -84.618498,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45014,
      "latitude": 39.387357,
      "longitude": -84.499743,
      "city": "Fairfield",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45015,
      "latitude": 39.349361,
      "longitude": -84.537563,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45018,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Fairfield",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45020,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45023,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45025,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45026,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Hamilton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45030,
      "latitude": 39.244275,
      "longitude": -84.736254,
      "city": "Harrison",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45032,
      "latitude": 39.501283,
      "longitude": -84.006729,
      "city": "Harveysburg",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45033,
      "latitude": 39.177259,
      "longitude": -84.762731,
      "city": "Hooven",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45034,
      "latitude": 39.357963,
      "longitude": -84.247288,
      "city": "Kings Mills",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45036,
      "latitude": 39.429284,
      "longitude": -84.173507,
      "city": "Lebanon",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45039,
      "latitude": 39.316973,
      "longitude": -84.243763,
      "city": "Maineville",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45040,
      "latitude": 39.397999,
      "longitude": -84.180906,
      "city": "Mason",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45041,
      "latitude": 39.176088,
      "longitude": -84.708517,
      "city": "Miamitown",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45042,
      "latitude": 39.543446,
      "longitude": -84.442394,
      "city": "Middletown",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45043,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Middletown",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45044,
      "latitude": 39.418175,
      "longitude": -84.424549,
      "city": "Middletown",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45050,
      "latitude": 39.448492,
      "longitude": -84.374549,
      "city": "Monroe",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45051,
      "latitude": 39.09646,
      "longitude": -84.643096,
      "city": "Mount Saint Joseph",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45052,
      "latitude": 39.149862,
      "longitude": -84.750268,
      "city": "North Bend",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45053,
      "latitude": 39.354854,
      "longitude": -84.776769,
      "city": "Okeana",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45054,
      "latitude": 39.458117,
      "longitude": -84.079543,
      "city": "Oregonia",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45055,
      "latitude": 39.451806,
      "longitude": -84.515245,
      "city": "Overpeck",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45056,
      "latitude": 39.479227,
      "longitude": -84.68575,
      "city": "Oxford",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45061,
      "latitude": 39.31235,
      "longitude": -84.64828,
      "city": "Ross",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45062,
      "latitude": 39.478956,
      "longitude": -84.551196,
      "city": "Seven Mile",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45063,
      "latitude": 39.325805,
      "longitude": -84.712099,
      "city": "Shandon",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45064,
      "latitude": 39.545634,
      "longitude": -84.6275,
      "city": "Somerville",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45065,
      "latitude": 39.356027,
      "longitude": -84.207204,
      "city": "South Lebanon",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45066,
      "latitude": 39.465762,
      "longitude": -84.14633,
      "city": "Springboro",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45067,
      "latitude": 39.48332,
      "longitude": -84.489895,
      "city": "Trenton",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45068,
      "latitude": 39.51812,
      "longitude": -84.0806,
      "city": "Waynesville",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45069,
      "latitude": 39.336058,
      "longitude": -84.40536,
      "city": "West Chester",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45070,
      "latitude": 39.594686,
      "longitude": -84.54682,
      "city": "West Elkton",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45071,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "West Chester",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45073,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Monroe",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45099,
      "latitude": 39.440956,
      "longitude": -84.575746,
      "city": "Monroe",
      "state": "OH",
      "county": "Butler"
  },
  {
      "zipCode": 45101,
      "latitude": 38.693525,
      "longitude": -83.740804,
      "city": "Aberdeen",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45102,
      "latitude": 39.014823,
      "longitude": -84.205859,
      "city": "Amelia",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45103,
      "latitude": 39.025905,
      "longitude": -84.157048,
      "city": "Batavia",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45105,
      "latitude": 38.749779,
      "longitude": -83.612574,
      "city": "Bentonville",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45106,
      "latitude": 38.922008,
      "longitude": -84.109327,
      "city": "Bethel",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45107,
      "latitude": 39.40252,
      "longitude": -83.894217,
      "city": "Blanchester",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45110,
      "latitude": 39.074262,
      "longitude": -83.848414,
      "city": "Buford",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45111,
      "latitude": 39.19977,
      "longitude": -84.303075,
      "city": "Camp Dennison",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45112,
      "latitude": 38.792553,
      "longitude": -84.138221,
      "city": "Chilo",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45113,
      "latitude": 39.398125,
      "longitude": -83.932644,
      "city": "Clarksville",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45114,
      "latitude": 39.356846,
      "longitude": -83.857237,
      "city": "Cuba",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45115,
      "latitude": 38.823707,
      "longitude": -83.699476,
      "city": "Decatur",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45118,
      "latitude": 39.1748,
      "longitude": -83.935821,
      "city": "Fayetteville",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45119,
      "latitude": 38.880632,
      "longitude": -84.00874,
      "city": "Feesburg",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45120,
      "latitude": 38.820683,
      "longitude": -84.126923,
      "city": "Felicity",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45121,
      "latitude": 38.981336,
      "longitude": -83.903529,
      "city": "Georgetown",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45122,
      "latitude": 39.21292,
      "longitude": -84.10444,
      "city": "Goshen",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45123,
      "latitude": 39.288414,
      "longitude": -83.583223,
      "city": "Greenfield",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45130,
      "latitude": 38.902187,
      "longitude": -83.982347,
      "city": "Hamersville",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45131,
      "latitude": 38.790301,
      "longitude": -83.966624,
      "city": "Higginsport",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45132,
      "latitude": 39.352669,
      "longitude": -83.602424,
      "city": "Highland",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45133,
      "latitude": 39.169164,
      "longitude": -83.59454,
      "city": "Hillsboro",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45135,
      "latitude": 39.270862,
      "longitude": -83.595228,
      "city": "Leesburg",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45138,
      "latitude": 39.416747,
      "longitude": -83.647583,
      "city": "Lees Creek",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45140,
      "latitude": 39.223187,
      "longitude": -84.245103,
      "city": "Loveland",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45142,
      "latitude": 39.200697,
      "longitude": -83.777545,
      "city": "Lynchburg",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45144,
      "latitude": 38.698772,
      "longitude": -83.574357,
      "city": "Manchester",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45145,
      "latitude": 39.145698,
      "longitude": -84.007023,
      "city": "Marathon",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45146,
      "latitude": 39.313796,
      "longitude": -83.796942,
      "city": "Martinsville",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45147,
      "latitude": 39.213739,
      "longitude": -84.300232,
      "city": "Miamiville",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45148,
      "latitude": 39.291345,
      "longitude": -83.885714,
      "city": "Midland",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45150,
      "latitude": 39.016349,
      "longitude": -84.197778,
      "city": "Milford",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45152,
      "latitude": 39.342034,
      "longitude": -84.106916,
      "city": "Morrow",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45153,
      "latitude": 38.867485,
      "longitude": -84.192222,
      "city": "Moscow",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45154,
      "latitude": 39.057857,
      "longitude": -83.913915,
      "city": "Mount Orab",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45155,
      "latitude": 39.038774,
      "longitude": -83.750522,
      "city": "Mowrystown",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45156,
      "latitude": 38.815645,
      "longitude": -84.204877,
      "city": "Neville",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45157,
      "latitude": 38.9124,
      "longitude": -84.206745,
      "city": "New Richmond",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45158,
      "latitude": 39.18779,
      "longitude": -84.078313,
      "city": "Newtonsville",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45159,
      "latitude": 39.349254,
      "longitude": -83.702933,
      "city": "New Vienna",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45160,
      "latitude": 39.123187,
      "longitude": -84.135023,
      "city": "Owensville",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45162,
      "latitude": 39.294913,
      "longitude": -84.092498,
      "city": "Pleasant Plain",
      "state": "OH",
      "county": "Warren"
  },
  {
      "zipCode": 45164,
      "latitude": 39.551413,
      "longitude": -83.788084,
      "city": "Port William",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45165,
      "latitude": 39.198813,
      "longitude": -83.607858,
      "city": "Greenfield",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45166,
      "latitude": 39.480543,
      "longitude": -83.677197,
      "city": "Reesville",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45167,
      "latitude": 38.758164,
      "longitude": -83.822146,
      "city": "Ripley",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45168,
      "latitude": 38.84798,
      "longitude": -83.767404,
      "city": "Russellville",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45169,
      "latitude": 39.438429,
      "longitude": -83.680807,
      "city": "Sabina",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45171,
      "latitude": 39.005468,
      "longitude": -83.799563,
      "city": "Sardinia",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45172,
      "latitude": 39.07382,
      "longitude": -83.385303,
      "city": "Sinking Spring",
      "state": "OH",
      "county": "Highland"
  },
  {
      "zipCode": 45174,
      "latitude": 39.153831,
      "longitude": -84.329171,
      "city": "Terrace Park",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45176,
      "latitude": 39.013595,
      "longitude": -84.050406,
      "city": "Williamsburg",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45177,
      "latitude": 39.444985,
      "longitude": -83.824424,
      "city": "Wilmington",
      "state": "OH",
      "county": "Clinton"
  },
  {
      "zipCode": 45201,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45202,
      "latitude": 39.11026,
      "longitude": -84.486142,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45203,
      "latitude": 39.10886,
      "longitude": -84.531993,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45204,
      "latitude": 39.10016,
      "longitude": -84.589645,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45205,
      "latitude": 39.11161,
      "longitude": -84.577994,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45206,
      "latitude": 39.12616,
      "longitude": -84.482642,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45207,
      "latitude": 39.14311,
      "longitude": -84.468842,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45208,
      "latitude": 39.13786,
      "longitude": -84.434891,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45209,
      "latitude": 39.15471,
      "longitude": -84.450692,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45210,
      "latitude": 39.11451,
      "longitude": -84.511093,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45211,
      "latitude": 39.158409,
      "longitude": -84.602646,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45212,
      "latitude": 39.187659,
      "longitude": -84.454342,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45213,
      "latitude": 39.180214,
      "longitude": -84.420441,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45214,
      "latitude": 39.12186,
      "longitude": -84.550594,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45215,
      "latitude": 39.214259,
      "longitude": -84.464093,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45216,
      "latitude": 39.200309,
      "longitude": -84.485893,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45217,
      "latitude": 39.166109,
      "longitude": -84.495893,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45218,
      "latitude": 39.2774,
      "longitude": -84.520544,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45219,
      "latitude": 39.12851,
      "longitude": -84.515191,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45220,
      "latitude": 39.145559,
      "longitude": -84.520093,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45221,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45222,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45223,
      "latitude": 39.160002,
      "longitude": -84.593605,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45224,
      "latitude": 39.199058,
      "longitude": -84.525094,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45225,
      "latitude": 39.144309,
      "longitude": -84.548944,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45226,
      "latitude": 39.110111,
      "longitude": -84.43088,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45227,
      "latitude": 39.15926,
      "longitude": -84.37284,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45228,
      "latitude": 39.070561,
      "longitude": -84.417891,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45229,
      "latitude": 39.153559,
      "longitude": -84.490043,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45230,
      "latitude": 39.071262,
      "longitude": -84.37584,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45231,
      "latitude": 39.259325,
      "longitude": -84.532395,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45232,
      "latitude": 39.181109,
      "longitude": -84.51166,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45233,
      "latitude": 39.110994,
      "longitude": -84.659396,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45234,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45235,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45236,
      "latitude": 39.210001,
      "longitude": -84.395046,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45237,
      "latitude": 39.19069,
      "longitude": -84.447342,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45238,
      "latitude": 39.109209,
      "longitude": -84.610845,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45239,
      "latitude": 39.201708,
      "longitude": -84.621296,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45240,
      "latitude": 39.284357,
      "longitude": -84.439066,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45241,
      "latitude": 39.263837,
      "longitude": -84.409191,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45242,
      "latitude": 39.244659,
      "longitude": -84.345489,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45243,
      "latitude": 39.18481,
      "longitude": -84.344758,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45244,
      "latitude": 39.119102,
      "longitude": -84.350974,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45245,
      "latitude": 39.068815,
      "longitude": -84.280171,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Clermont"
  },
  {
      "zipCode": 45246,
      "latitude": 39.283858,
      "longitude": -84.474394,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45247,
      "latitude": 39.220761,
      "longitude": -84.641752,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45248,
      "latitude": 39.165158,
      "longitude": -84.662509,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45249,
      "latitude": 39.269226,
      "longitude": -84.330686,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45250,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45251,
      "latitude": 39.267157,
      "longitude": -84.599285,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45252,
      "latitude": 39.272557,
      "longitude": -84.632143,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45253,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45254,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45255,
      "latitude": 39.058412,
      "longitude": -84.339639,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45258,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45262,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45263,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45264,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45267,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45268,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45269,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45270,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45271,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45273,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45274,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45275,
      "latitude": 38.946921,
      "longitude": -83.862877,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Brown"
  },
  {
      "zipCode": 45277,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45280,
      "latitude": 39.113405,
      "longitude": -84.535972,
      "city": "Cincinnati",
      "state": "OH",
      "county": ""
  },
  {
      "zipCode": 45296,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45298,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45299,
      "latitude": 39.262158,
      "longitude": -84.509268,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45301,
      "latitude": 39.706459,
      "longitude": -84.016233,
      "city": "Alpha",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45302,
      "latitude": 40.407239,
      "longitude": -84.203271,
      "city": "Anna",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45303,
      "latitude": 40.214675,
      "longitude": -84.653188,
      "city": "Ansonia",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45304,
      "latitude": 40.126915,
      "longitude": -84.539928,
      "city": "Arcanum",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45305,
      "latitude": 39.632829,
      "longitude": -84.049985,
      "city": "Bellbrook",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45306,
      "latitude": 40.439778,
      "longitude": -84.189245,
      "city": "Botkins",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45307,
      "latitude": 39.575597,
      "longitude": -83.715323,
      "city": "Bowersville",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45308,
      "latitude": 40.115737,
      "longitude": -84.279352,
      "city": "Bradford",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45309,
      "latitude": 39.836157,
      "longitude": -84.330392,
      "city": "Brookville",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45310,
      "latitude": 40.354106,
      "longitude": -84.643532,
      "city": "Burkettsville",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45311,
      "latitude": 39.640948,
      "longitude": -84.647001,
      "city": "Camden",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45312,
      "latitude": 40.066567,
      "longitude": -84.08161,
      "city": "Casstown",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45314,
      "latitude": 39.747459,
      "longitude": -83.759973,
      "city": "Cedarville",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45315,
      "latitude": 39.854454,
      "longitude": -84.340345,
      "city": "Clayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45316,
      "latitude": 39.795971,
      "longitude": -83.819766,
      "city": "Clifton",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45317,
      "latitude": 40.137029,
      "longitude": -84.046873,
      "city": "Conover",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45318,
      "latitude": 40.124386,
      "longitude": -84.281167,
      "city": "Covington",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45319,
      "latitude": 39.918936,
      "longitude": -83.944909,
      "city": "Donnelsville",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45320,
      "latitude": 39.77485,
      "longitude": -84.674889,
      "city": "Eaton",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45321,
      "latitude": 39.872177,
      "longitude": -84.681067,
      "city": "Eldorado",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45322,
      "latitude": 39.877005,
      "longitude": -84.331945,
      "city": "Englewood",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45323,
      "latitude": 39.857967,
      "longitude": -83.933431,
      "city": "Enon",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45324,
      "latitude": 39.728549,
      "longitude": -84.014834,
      "city": "Fairborn",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45325,
      "latitude": 39.781301,
      "longitude": -84.41397,
      "city": "Farmersville",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45326,
      "latitude": 40.144491,
      "longitude": -84.100988,
      "city": "Fletcher",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45327,
      "latitude": 39.747497,
      "longitude": -84.396912,
      "city": "Germantown",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45328,
      "latitude": 40.114729,
      "longitude": -84.493439,
      "city": "Gettysburg",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45330,
      "latitude": 39.641558,
      "longitude": -84.527613,
      "city": "Gratis",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45331,
      "latitude": 40.156478,
      "longitude": -84.649068,
      "city": "Greenville",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45332,
      "latitude": 39.993964,
      "longitude": -84.783714,
      "city": "Hollansburg",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45333,
      "latitude": 40.248489,
      "longitude": -84.345612,
      "city": "Houston",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45334,
      "latitude": 40.434921,
      "longitude": -84.058495,
      "city": "Jackson Center",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45335,
      "latitude": 39.674084,
      "longitude": -83.766709,
      "city": "Jamestown",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45336,
      "latitude": 40.441325,
      "longitude": -84.262602,
      "city": "Kettlersville",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45337,
      "latitude": 39.985177,
      "longitude": -84.399364,
      "city": "Laura",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45338,
      "latitude": 39.780916,
      "longitude": -84.567331,
      "city": "Lewisburg",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45339,
      "latitude": 40.005764,
      "longitude": -84.351781,
      "city": "Ludlow Falls",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45340,
      "latitude": 40.364305,
      "longitude": -84.056464,
      "city": "Maplewood",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45341,
      "latitude": 39.878918,
      "longitude": -84.021827,
      "city": "Medway",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45342,
      "latitude": 39.641658,
      "longitude": -84.27464,
      "city": "Miamisburg",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45343,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Miamisburg",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45344,
      "latitude": 39.959249,
      "longitude": -83.986855,
      "city": "New Carlisle",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45345,
      "latitude": 39.800056,
      "longitude": -84.327,
      "city": "New Lebanon",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45346,
      "latitude": 39.982103,
      "longitude": -84.705736,
      "city": "New Madison",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45347,
      "latitude": 39.783378,
      "longitude": -84.668892,
      "city": "New Paris",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45348,
      "latitude": 40.316833,
      "longitude": -84.633911,
      "city": "New Weston",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45349,
      "latitude": 39.989309,
      "longitude": -83.938933,
      "city": "North Hampton",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45350,
      "latitude": 40.135426,
      "longitude": -84.619129,
      "city": "North Star",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45351,
      "latitude": 40.340616,
      "longitude": -84.496342,
      "city": "Osgood",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45352,
      "latitude": 40.050323,
      "longitude": -84.745706,
      "city": "Palestine",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45353,
      "latitude": 40.29522,
      "longitude": -84.032272,
      "city": "Pemberton",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45354,
      "latitude": 39.905385,
      "longitude": -84.402785,
      "city": "Phillipsburg",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45356,
      "latitude": 40.123618,
      "longitude": -84.228811,
      "city": "Piqua",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45358,
      "latitude": 39.987043,
      "longitude": -84.486582,
      "city": "Pitsburg",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45359,
      "latitude": 40.050483,
      "longitude": -84.348699,
      "city": "Pleasant Hill",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45360,
      "latitude": 40.330713,
      "longitude": -84.092589,
      "city": "Port Jefferson",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45361,
      "latitude": 39.963492,
      "longitude": -84.414484,
      "city": "Potsdam",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45362,
      "latitude": 40.287621,
      "longitude": -84.637078,
      "city": "Rossburg",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45363,
      "latitude": 40.263253,
      "longitude": -84.263227,
      "city": "Russia",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45365,
      "latitude": 40.293558,
      "longitude": -84.209198,
      "city": "Sidney",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45367,
      "latitude": 40.333611,
      "longitude": -84.218308,
      "city": "Sidney",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45368,
      "latitude": 39.854349,
      "longitude": -83.66528,
      "city": "South Charleston",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45369,
      "latitude": 39.957723,
      "longitude": -83.614481,
      "city": "South Vienna",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45370,
      "latitude": 39.608285,
      "longitude": -84.025972,
      "city": "Spring Valley",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45371,
      "latitude": 39.941967,
      "longitude": -84.16626,
      "city": "Tipp City",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45372,
      "latitude": 40.013871,
      "longitude": -83.83325,
      "city": "Tremont City",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45373,
      "latitude": 40.062621,
      "longitude": -84.226398,
      "city": "Troy",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45374,
      "latitude": 40.03997,
      "longitude": -84.229799,
      "city": "Troy",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45377,
      "latitude": 39.889006,
      "longitude": -84.242243,
      "city": "Vandalia",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45378,
      "latitude": 39.897035,
      "longitude": -84.499044,
      "city": "Verona",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45380,
      "latitude": 40.25304,
      "longitude": -84.523891,
      "city": "Versailles",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45381,
      "latitude": 39.750097,
      "longitude": -84.537597,
      "city": "West Alexandria",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45382,
      "latitude": 39.88133,
      "longitude": -84.621617,
      "city": "West Manchester",
      "state": "OH",
      "county": "Preble"
  },
  {
      "zipCode": 45383,
      "latitude": 39.98791,
      "longitude": -84.350107,
      "city": "West Milton",
      "state": "OH",
      "county": "Miami"
  },
  {
      "zipCode": 45384,
      "latitude": 39.712811,
      "longitude": -83.878088,
      "city": "Wilberforce",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45385,
      "latitude": 39.684731,
      "longitude": -83.90813,
      "city": "Xenia",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45387,
      "latitude": 39.760531,
      "longitude": -83.8836,
      "city": "Yellow Springs",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45388,
      "latitude": 40.321853,
      "longitude": -84.484466,
      "city": "Yorkshire",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45389,
      "latitude": 40.0564,
      "longitude": -84.025444,
      "city": "Christiansburg",
      "state": "OH",
      "county": "Champaign"
  },
  {
      "zipCode": 45390,
      "latitude": 40.211787,
      "longitude": -84.758818,
      "city": "Union City",
      "state": "OH",
      "county": "Darke"
  },
  {
      "zipCode": 45401,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45402,
      "latitude": 39.756658,
      "longitude": -84.181639,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45403,
      "latitude": 39.764658,
      "longitude": -84.150738,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45404,
      "latitude": 39.794958,
      "longitude": -84.163589,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45405,
      "latitude": 39.789857,
      "longitude": -84.217391,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45406,
      "latitude": 39.782457,
      "longitude": -84.239391,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45407,
      "latitude": 39.758658,
      "longitude": -84.226041,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45408,
      "latitude": 39.730258,
      "longitude": -84.219846,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45409,
      "latitude": 39.723809,
      "longitude": -84.185439,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45410,
      "latitude": 39.748008,
      "longitude": -84.156938,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45412,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45413,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45414,
      "latitude": 39.805957,
      "longitude": -84.199737,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45415,
      "latitude": 39.834756,
      "longitude": -84.252292,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45416,
      "latitude": 39.801057,
      "longitude": -84.257792,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45417,
      "latitude": 39.75344,
      "longitude": -84.245841,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45418,
      "latitude": 39.707057,
      "longitude": -84.276891,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45419,
      "latitude": 39.681909,
      "longitude": -84.166388,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45420,
      "latitude": 39.717059,
      "longitude": -84.134172,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45422,
      "latitude": 39.758058,
      "longitude": -84.20009,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45423,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45424,
      "latitude": 39.835257,
      "longitude": -84.112288,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45426,
      "latitude": 39.798187,
      "longitude": -84.321119,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45427,
      "latitude": 39.755506,
      "longitude": -84.304893,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45428,
      "latitude": 39.746656,
      "longitude": -84.259292,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45429,
      "latitude": 39.684059,
      "longitude": -84.163337,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45430,
      "latitude": 39.709159,
      "longitude": -84.104836,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45431,
      "latitude": 39.757377,
      "longitude": -84.056934,
      "city": "Dayton",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45432,
      "latitude": 39.732309,
      "longitude": -84.160422,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45433,
      "latitude": 39.798503,
      "longitude": -84.052053,
      "city": "Dayton",
      "state": "OH",
      "county": "Greene"
  },
  {
      "zipCode": 45434,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45435,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45437,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45439,
      "latitude": 39.749308,
      "longitude": -84.19954,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45440,
      "latitude": 39.675548,
      "longitude": -84.121086,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45441,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45448,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45449,
      "latitude": 39.665109,
      "longitude": -84.24009,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45454,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45458,
      "latitude": 39.606209,
      "longitude": -84.169519,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45459,
      "latitude": 39.646371,
      "longitude": -84.171733,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45463,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45469,
      "latitude": 39.740509,
      "longitude": -84.178939,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45470,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45475,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45479,
      "latitude": 39.734409,
      "longitude": -84.194389,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45481,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45482,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45490,
      "latitude": 39.750471,
      "longitude": -84.268593,
      "city": "Dayton",
      "state": "OH",
      "county": "Montgomery"
  },
  {
      "zipCode": 45501,
      "latitude": 39.927059,
      "longitude": -83.813228,
      "city": "Springfield",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45502,
      "latitude": 39.917208,
      "longitude": -83.854642,
      "city": "Springfield",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45503,
      "latitude": 39.963784,
      "longitude": -83.77139,
      "city": "Springfield",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45504,
      "latitude": 39.950457,
      "longitude": -83.890862,
      "city": "Springfield",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45505,
      "latitude": 39.906009,
      "longitude": -83.7482,
      "city": "Springfield",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45506,
      "latitude": 39.883209,
      "longitude": -83.86983,
      "city": "Springfield",
      "state": "OH",
      "county": "Clark"
  },
  {
      "zipCode": 45601,
      "latitude": 39.337384,
      "longitude": -82.991332,
      "city": "Chillicothe",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45612,
      "latitude": 39.244298,
      "longitude": -83.219712,
      "city": "Bainbridge",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45613,
      "latitude": 39.040682,
      "longitude": -82.887103,
      "city": "Beaver",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45614,
      "latitude": 38.92307,
      "longitude": -82.276201,
      "city": "Bidwell",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45616,
      "latitude": 38.756374,
      "longitude": -83.340308,
      "city": "Blue Creek",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45617,
      "latitude": 39.280388,
      "longitude": -83.158991,
      "city": "Bourneville",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45618,
      "latitude": 38.892198,
      "longitude": -83.621787,
      "city": "Cherry Fork",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45619,
      "latitude": 38.482955,
      "longitude": -82.450548,
      "city": "Chesapeake",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45620,
      "latitude": 38.798525,
      "longitude": -82.189958,
      "city": "Cheshire",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45621,
      "latitude": 39.110614,
      "longitude": -82.613028,
      "city": "Coalton",
      "state": "OH",
      "county": "Jackson"
  },
  {
      "zipCode": 45622,
      "latitude": 39.354339,
      "longitude": -82.503012,
      "city": "Creola",
      "state": "OH",
      "county": "Vinton"
  },
  {
      "zipCode": 45623,
      "latitude": 38.796783,
      "longitude": -82.271583,
      "city": "Crown City",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45624,
      "latitude": 39.16378,
      "longitude": -83.342941,
      "city": "Cynthiana",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45628,
      "latitude": 39.387165,
      "longitude": -83.182591,
      "city": "Frankfort",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45629,
      "latitude": 38.650439,
      "longitude": -82.802128,
      "city": "Franklin Furnace",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45630,
      "latitude": 38.793335,
      "longitude": -82.96046,
      "city": "Friendship",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45631,
      "latitude": 38.793762,
      "longitude": -82.2692,
      "city": "Gallipolis",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45633,
      "latitude": 39.46557,
      "longitude": -82.747853,
      "city": "Hallsville",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45634,
      "latitude": 39.178317,
      "longitude": -82.503194,
      "city": "Hamden",
      "state": "OH",
      "county": "Vinton"
  },
  {
      "zipCode": 45636,
      "latitude": 38.592446,
      "longitude": -82.828817,
      "city": "Haverhill",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45638,
      "latitude": 38.544457,
      "longitude": -82.596777,
      "city": "Ironton",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45640,
      "latitude": 39.03738,
      "longitude": -82.628954,
      "city": "Jackson",
      "state": "OH",
      "county": "Jackson"
  },
  {
      "zipCode": 45642,
      "latitude": 39.052267,
      "longitude": -83.050031,
      "city": "Jasper",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45643,
      "latitude": 38.86833,
      "longitude": -82.256185,
      "city": "Kerr",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45644,
      "latitude": 39.424824,
      "longitude": -82.862234,
      "city": "Kingston",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45645,
      "latitude": 38.562503,
      "longitude": -82.545723,
      "city": "Kitts Hill",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45646,
      "latitude": 39.081031,
      "longitude": -83.312422,
      "city": "Latham",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45647,
      "latitude": 39.292098,
      "longitude": -82.795487,
      "city": "Londonderry",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45648,
      "latitude": 38.888422,
      "longitude": -82.98132,
      "city": "Lucasville",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45650,
      "latitude": 38.73906,
      "longitude": -83.42617,
      "city": "Lynx",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45651,
      "latitude": 39.280013,
      "longitude": -82.47532,
      "city": "Mc Arthur",
      "state": "OH",
      "county": "Vinton"
  },
  {
      "zipCode": 45652,
      "latitude": 38.852654,
      "longitude": -83.135398,
      "city": "Mc Dermott",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45653,
      "latitude": 38.889049,
      "longitude": -82.911143,
      "city": "Minford",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45654,
      "latitude": 39.332256,
      "longitude": -82.379663,
      "city": "New Plymouth",
      "state": "OH",
      "county": "Vinton"
  },
  {
      "zipCode": 45656,
      "latitude": 38.976464,
      "longitude": -82.603687,
      "city": "Oak Hill",
      "state": "OH",
      "county": "Jackson"
  },
  {
      "zipCode": 45657,
      "latitude": 38.875128,
      "longitude": -83.159778,
      "city": "Otway",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45658,
      "latitude": 38.765791,
      "longitude": -82.390542,
      "city": "Patriot",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45659,
      "latitude": 38.674554,
      "longitude": -82.614563,
      "city": "Pedro",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45660,
      "latitude": 38.858792,
      "longitude": -83.461114,
      "city": "Peebles",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45661,
      "latitude": 39.043707,
      "longitude": -83.12101,
      "city": "Piketon",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45662,
      "latitude": 38.793214,
      "longitude": -82.930576,
      "city": "Portsmouth",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45663,
      "latitude": 38.749539,
      "longitude": -83.133477,
      "city": "West Portsmouth",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45669,
      "latitude": 38.49585,
      "longitude": -82.364902,
      "city": "Proctorville",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45671,
      "latitude": 38.957722,
      "longitude": -83.236465,
      "city": "Rarden",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45672,
      "latitude": 39.230553,
      "longitude": -82.66956,
      "city": "Ray",
      "state": "OH",
      "county": "Vinton"
  },
  {
      "zipCode": 45673,
      "latitude": 39.203167,
      "longitude": -82.854167,
      "city": "Richmond Dale",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45674,
      "latitude": 38.881447,
      "longitude": -82.378213,
      "city": "Rio Grande",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45675,
      "latitude": 38.536685,
      "longitude": -82.532715,
      "city": "Rock Camp",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45677,
      "latitude": 38.794115,
      "longitude": -82.755455,
      "city": "Scioto Furnace",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45678,
      "latitude": 38.598567,
      "longitude": -82.385511,
      "city": "Scottown",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45679,
      "latitude": 38.957941,
      "longitude": -83.535617,
      "city": "Seaman",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45680,
      "latitude": 38.465171,
      "longitude": -82.501206,
      "city": "South Point",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45681,
      "latitude": 39.328523,
      "longitude": -83.242873,
      "city": "South Salem",
      "state": "OH",
      "county": "Ross"
  },
  {
      "zipCode": 45682,
      "latitude": 38.819687,
      "longitude": -82.727978,
      "city": "South Webster",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45683,
      "latitude": 38.955485,
      "longitude": -82.858402,
      "city": "Stockdale",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45684,
      "latitude": 38.783492,
      "longitude": -83.102804,
      "city": "Stout",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45685,
      "latitude": 38.870525,
      "longitude": -82.406298,
      "city": "Thurman",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45686,
      "latitude": 38.961601,
      "longitude": -82.345709,
      "city": "Vinton",
      "state": "OH",
      "county": "Gallia"
  },
  {
      "zipCode": 45687,
      "latitude": 39.072829,
      "longitude": -83.085764,
      "city": "Wakefield",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45688,
      "latitude": 38.719009,
      "longitude": -82.535141,
      "city": "Waterloo",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45690,
      "latitude": 39.072309,
      "longitude": -83.024014,
      "city": "Waverly",
      "state": "OH",
      "county": "Pike"
  },
  {
      "zipCode": 45692,
      "latitude": 39.116467,
      "longitude": -82.553489,
      "city": "Wellston",
      "state": "OH",
      "county": "Jackson"
  },
  {
      "zipCode": 45693,
      "latitude": 38.82346,
      "longitude": -83.504048,
      "city": "West Union",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45694,
      "latitude": 38.745832,
      "longitude": -82.847351,
      "city": "Wheelersburg",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45695,
      "latitude": 39.141589,
      "longitude": -82.36824,
      "city": "Wilkesville",
      "state": "OH",
      "county": "Vinton"
  },
  {
      "zipCode": 45696,
      "latitude": 38.590602,
      "longitude": -82.456062,
      "city": "Willow Wood",
      "state": "OH",
      "county": "Lawrence"
  },
  {
      "zipCode": 45697,
      "latitude": 38.9163,
      "longitude": -83.580733,
      "city": "Winchester",
      "state": "OH",
      "county": "Adams"
  },
  {
      "zipCode": 45698,
      "latitude": 39.283031,
      "longitude": -82.397708,
      "city": "Zaleski",
      "state": "OH",
      "county": "Vinton"
  },
  {
      "zipCode": 45699,
      "latitude": 38.793335,
      "longitude": -82.96046,
      "city": "Lucasville",
      "state": "OH",
      "county": "Scioto"
  },
  {
      "zipCode": 45701,
      "latitude": 39.339279,
      "longitude": -82.013803,
      "city": "Athens",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45710,
      "latitude": 39.240816,
      "longitude": -82.177096,
      "city": "Albany",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45711,
      "latitude": 39.410743,
      "longitude": -81.936397,
      "city": "Amesville",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45712,
      "latitude": 39.386825,
      "longitude": -81.395219,
      "city": "Barlow",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45713,
      "latitude": 39.430738,
      "longitude": -81.44512,
      "city": "Bartlett",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45714,
      "latitude": 39.449988,
      "longitude": -81.559698,
      "city": "Belpre",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45715,
      "latitude": 39.541791,
      "longitude": -81.606295,
      "city": "Beverly",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45716,
      "latitude": 39.452247,
      "longitude": -82.179423,
      "city": "Buchtel",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45717,
      "latitude": 39.368235,
      "longitude": -82.010132,
      "city": "Carbondale",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45719,
      "latitude": 39.400154,
      "longitude": -82.130241,
      "city": "Chauncey",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45720,
      "latitude": 39.085629,
      "longitude": -81.921443,
      "city": "Chester",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45721,
      "latitude": 39.430738,
      "longitude": -81.44512,
      "city": "Coal Run",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45723,
      "latitude": 39.246862,
      "longitude": -81.830623,
      "city": "Coolville",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45724,
      "latitude": 39.430738,
      "longitude": -81.44512,
      "city": "Cutler",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45727,
      "latitude": 39.67387,
      "longitude": -81.485687,
      "city": "Dexter City",
      "state": "OH",
      "county": "Noble"
  },
  {
      "zipCode": 45729,
      "latitude": 39.430738,
      "longitude": -81.44512,
      "city": "Fleming",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45732,
      "latitude": 39.475277,
      "longitude": -82.063319,
      "city": "Glouster",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45734,
      "latitude": 39.663015,
      "longitude": -81.182232,
      "city": "Graysville",
      "state": "OH",
      "county": "Monroe"
  },
  {
      "zipCode": 45735,
      "latitude": 39.249339,
      "longitude": -81.922005,
      "city": "Guysville",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45739,
      "latitude": 39.197888,
      "longitude": -81.744573,
      "city": "Hockingport",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45740,
      "latitude": 39.461905,
      "longitude": -82.073188,
      "city": "Jacksonville",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45741,
      "latitude": 39.069673,
      "longitude": -82.24991,
      "city": "Langsville",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45742,
      "latitude": 39.274635,
      "longitude": -81.655976,
      "city": "Little Hocking",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45743,
      "latitude": 39.071167,
      "longitude": -81.839685,
      "city": "Long Bottom",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45744,
      "latitude": 39.538823,
      "longitude": -81.47884,
      "city": "Lowell",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45745,
      "latitude": 39.561107,
      "longitude": -81.39676,
      "city": "Lower Salem",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45746,
      "latitude": 39.580501,
      "longitude": -81.479272,
      "city": "Macksburg",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45750,
      "latitude": 39.413446,
      "longitude": -81.351051,
      "city": "Marietta",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45760,
      "latitude": 39.069059,
      "longitude": -82.021994,
      "city": "Middleport",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45761,
      "latitude": 39.379936,
      "longitude": -82.088793,
      "city": "Millfield",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45764,
      "latitude": 39.357211,
      "longitude": -82.045466,
      "city": "Nelsonville",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45766,
      "latitude": 39.307332,
      "longitude": -82.167867,
      "city": "New Marshfield",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45767,
      "latitude": 39.523303,
      "longitude": -81.064116,
      "city": "New Matamoras",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45768,
      "latitude": 39.393315,
      "longitude": -81.225093,
      "city": "Newport",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45769,
      "latitude": 39.07866,
      "longitude": -82.069422,
      "city": "Pomeroy",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45770,
      "latitude": 38.981015,
      "longitude": -81.812092,
      "city": "Portland",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45771,
      "latitude": 38.984835,
      "longitude": -81.892503,
      "city": "Racine",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45772,
      "latitude": 39.13447,
      "longitude": -81.835405,
      "city": "Reedsville",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45773,
      "latitude": 39.430738,
      "longitude": -81.44512,
      "city": "Reno",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45775,
      "latitude": 39.090877,
      "longitude": -82.148554,
      "city": "Rutland",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45776,
      "latitude": 39.214673,
      "longitude": -82.018739,
      "city": "Shade",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45777,
      "latitude": 39.368235,
      "longitude": -82.010132,
      "city": "Sharpsburg",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45778,
      "latitude": 39.362514,
      "longitude": -81.906878,
      "city": "Stewart",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45779,
      "latitude": 39.003291,
      "longitude": -81.966122,
      "city": "Syracuse",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45780,
      "latitude": 39.35076,
      "longitude": -81.968751,
      "city": "The Plains",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45781,
      "latitude": 39.368235,
      "longitude": -82.010132,
      "city": "Torch",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45782,
      "latitude": 39.484517,
      "longitude": -82.080528,
      "city": "Trimble",
      "state": "OH",
      "county": "Athens"
  },
  {
      "zipCode": 45783,
      "latitude": 39.168349,
      "longitude": -81.842313,
      "city": "Tuppers Plains",
      "state": "OH",
      "county": "Meigs"
  },
  {
      "zipCode": 45784,
      "latitude": 39.367877,
      "longitude": -81.665232,
      "city": "Vincent",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45786,
      "latitude": 39.538788,
      "longitude": -81.639888,
      "city": "Waterford",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45787,
      "latitude": 39.430738,
      "longitude": -81.44512,
      "city": "Watertown",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45788,
      "latitude": 39.430738,
      "longitude": -81.44512,
      "city": "Whipple",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45789,
      "latitude": 39.544391,
      "longitude": -81.245804,
      "city": "Wingett Run",
      "state": "OH",
      "county": "Washington"
  },
  {
      "zipCode": 45801,
      "latitude": 40.772147,
      "longitude": -84.0471,
      "city": "Lima",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45802,
      "latitude": 40.781785,
      "longitude": -84.138566,
      "city": "Lima",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45804,
      "latitude": 40.694996,
      "longitude": -84.063491,
      "city": "Lima",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45805,
      "latitude": 40.733043,
      "longitude": -84.169749,
      "city": "Lima",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45806,
      "latitude": 40.589876,
      "longitude": -84.12786,
      "city": "Lima",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45807,
      "latitude": 40.795463,
      "longitude": -84.138298,
      "city": "Lima",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45808,
      "latitude": 40.831419,
      "longitude": -83.975831,
      "city": "Beaverdam",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45809,
      "latitude": 40.847946,
      "longitude": -84.183152,
      "city": "Gomer",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45810,
      "latitude": 40.676042,
      "longitude": -83.700633,
      "city": "Ada",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 45812,
      "latitude": 40.679192,
      "longitude": -83.795649,
      "city": "Alger",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 45813,
      "latitude": 41.186851,
      "longitude": -84.727141,
      "city": "Antwerp",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45814,
      "latitude": 40.908204,
      "longitude": -83.617612,
      "city": "Arlington",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45815,
      "latitude": 41.153855,
      "longitude": -83.941281,
      "city": "Belmore",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45816,
      "latitude": 41.003087,
      "longitude": -83.793147,
      "city": "Benton Ridge",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45817,
      "latitude": 40.78635,
      "longitude": -83.928268,
      "city": "Bluffton",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45819,
      "latitude": 40.62408,
      "longitude": -84.260275,
      "city": "Buckland",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45820,
      "latitude": 40.830388,
      "longitude": -84.085154,
      "city": "Cairo",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45821,
      "latitude": 41.223404,
      "longitude": -84.571519,
      "city": "Cecil",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45822,
      "latitude": 40.556645,
      "longitude": -84.628714,
      "city": "Celina",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45826,
      "latitude": 40.428177,
      "longitude": -84.633484,
      "city": "Chickasaw",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45827,
      "latitude": 41.003391,
      "longitude": -84.298718,
      "city": "Cloverdale",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45828,
      "latitude": 40.476443,
      "longitude": -84.68752,
      "city": "Coldwater",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45830,
      "latitude": 40.97793,
      "longitude": -84.15053,
      "city": "Columbus Grove",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45831,
      "latitude": 41.104335,
      "longitude": -84.236754,
      "city": "Continental",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45832,
      "latitude": 40.896997,
      "longitude": -84.716607,
      "city": "Convoy",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45833,
      "latitude": 40.794444,
      "longitude": -84.311596,
      "city": "Delphos",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45835,
      "latitude": 40.761389,
      "longitude": -83.702404,
      "city": "Dola",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 45836,
      "latitude": 40.782629,
      "longitude": -83.652433,
      "city": "Dunkirk",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 45837,
      "latitude": 41.046252,
      "longitude": -84.319494,
      "city": "Dupont",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45838,
      "latitude": 40.735491,
      "longitude": -84.485028,
      "city": "Elgin",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45839,
      "latitude": 40.993263,
      "longitude": -83.650714,
      "city": "Findlay",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45840,
      "latitude": 41.015219,
      "longitude": -83.664786,
      "city": "Findlay",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45841,
      "latitude": 40.885048,
      "longitude": -83.722963,
      "city": "Jenera",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45843,
      "latitude": 40.755387,
      "longitude": -83.671142,
      "city": "Forest",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 45844,
      "latitude": 40.922538,
      "longitude": -84.295652,
      "city": "Fort Jennings",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45845,
      "latitude": 40.364003,
      "longitude": -84.23261,
      "city": "Fort Loramie",
      "state": "OH",
      "county": "Shelby"
  },
  {
      "zipCode": 45846,
      "latitude": 40.452405,
      "longitude": -84.7169,
      "city": "Fort Recovery",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45848,
      "latitude": 41.096421,
      "longitude": -84.112031,
      "city": "Glandorf",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45849,
      "latitude": 41.032277,
      "longitude": -84.479422,
      "city": "Grover Hill",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45850,
      "latitude": 40.739067,
      "longitude": -83.941613,
      "city": "Harrod",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45851,
      "latitude": 41.03321,
      "longitude": -84.60076,
      "city": "Haviland",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45853,
      "latitude": 40.979612,
      "longitude": -84.219208,
      "city": "Kalida",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45854,
      "latitude": 40.758211,
      "longitude": -83.949934,
      "city": "Lafayette",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45855,
      "latitude": 41.07712,
      "longitude": -84.583005,
      "city": "Latty",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45856,
      "latitude": 41.050948,
      "longitude": -84.11025,
      "city": "Leipsic",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45858,
      "latitude": 41.09817,
      "longitude": -83.784671,
      "city": "Mc Comb",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45859,
      "latitude": 40.689897,
      "longitude": -83.811072,
      "city": "Mc Guffey",
      "state": "OH",
      "county": "Hardin"
  },
  {
      "zipCode": 45860,
      "latitude": 40.394413,
      "longitude": -84.535296,
      "city": "Maria Stein",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45861,
      "latitude": 41.08119,
      "longitude": -84.428684,
      "city": "Melrose",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45862,
      "latitude": 40.575306,
      "longitude": -84.547735,
      "city": "Mendon",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45863,
      "latitude": 40.899321,
      "longitude": -84.454113,
      "city": "Middle Point",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45864,
      "latitude": 41.103776,
      "longitude": -84.131541,
      "city": "Miller City",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45865,
      "latitude": 40.410299,
      "longitude": -84.39708,
      "city": "Minster",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45866,
      "latitude": 40.489028,
      "longitude": -84.549391,
      "city": "Montezuma",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45867,
      "latitude": 40.904671,
      "longitude": -83.528932,
      "city": "Mount Blanchard",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45868,
      "latitude": 40.970761,
      "longitude": -83.842293,
      "city": "Mount Cory",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45869,
      "latitude": 40.504687,
      "longitude": -84.2145,
      "city": "New Bremen",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45870,
      "latitude": 40.549772,
      "longitude": -83.950093,
      "city": "New Hampshire",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45871,
      "latitude": 40.496141,
      "longitude": -84.282659,
      "city": "New Knoxville",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45872,
      "latitude": 41.204236,
      "longitude": -83.6837,
      "city": "North Baltimore",
      "state": "OH",
      "county": "Wood"
  },
  {
      "zipCode": 45873,
      "latitude": 41.121548,
      "longitude": -84.534212,
      "city": "Oakwood",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45874,
      "latitude": 40.821778,
      "longitude": -84.658335,
      "city": "Ohio City",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45875,
      "latitude": 41.019657,
      "longitude": -84.111533,
      "city": "Ottawa",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45876,
      "latitude": 41.027573,
      "longitude": -84.238754,
      "city": "Ottoville",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45877,
      "latitude": 40.956609,
      "longitude": -83.937984,
      "city": "Pandora",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45879,
      "latitude": 41.121205,
      "longitude": -84.532406,
      "city": "Paulding",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45880,
      "latitude": 41.062763,
      "longitude": -84.716982,
      "city": "Payne",
      "state": "OH",
      "county": "Paulding"
  },
  {
      "zipCode": 45881,
      "latitude": 40.941562,
      "longitude": -83.770206,
      "city": "Rawson",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45882,
      "latitude": 40.669681,
      "longitude": -84.657861,
      "city": "Rockford",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45883,
      "latitude": 40.408584,
      "longitude": -84.635417,
      "city": "Saint Henry",
      "state": "OH",
      "county": "Mercer"
  },
  {
      "zipCode": 45884,
      "latitude": 40.520289,
      "longitude": -84.16797,
      "city": "Saint Johns",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45885,
      "latitude": 40.584975,
      "longitude": -84.376744,
      "city": "Saint Marys",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45886,
      "latitude": 40.975363,
      "longitude": -84.603141,
      "city": "Scott",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45887,
      "latitude": 40.732147,
      "longitude": -84.159688,
      "city": "Spencerville",
      "state": "OH",
      "county": "Allen"
  },
  {
      "zipCode": 45888,
      "latitude": 40.602259,
      "longitude": -84.086022,
      "city": "Uniopolis",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45889,
      "latitude": 41.142264,
      "longitude": -83.628337,
      "city": "Van Buren",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45890,
      "latitude": 40.935704,
      "longitude": -83.502731,
      "city": "Vanlue",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45891,
      "latitude": 40.873247,
      "longitude": -84.594525,
      "city": "Van Wert",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45893,
      "latitude": 40.882077,
      "longitude": -84.148028,
      "city": "Vaughnsville",
      "state": "OH",
      "county": "Putnam"
  },
  {
      "zipCode": 45894,
      "latitude": 40.771925,
      "longitude": -84.47458,
      "city": "Venedocia",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45895,
      "latitude": 40.582252,
      "longitude": -84.152138,
      "city": "Wapakoneta",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45896,
      "latitude": 40.589899,
      "longitude": -83.953648,
      "city": "Waynesfield",
      "state": "OH",
      "county": "Auglaize"
  },
  {
      "zipCode": 45897,
      "latitude": 40.834004,
      "longitude": -83.651785,
      "city": "Williamstown",
      "state": "OH",
      "county": "Hancock"
  },
  {
      "zipCode": 45898,
      "latitude": 40.775148,
      "longitude": -84.745809,
      "city": "Willshire",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45899,
      "latitude": 40.794002,
      "longitude": -84.785735,
      "city": "Wren",
      "state": "OH",
      "county": "Van Wert"
  },
  {
      "zipCode": 45944,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 45999,
      "latitude": 39.166759,
      "longitude": -84.53822,
      "city": "Cincinnati",
      "state": "OH",
      "county": "Hamilton"
  },
  {
      "zipCode": 46001,
      "latitude": 40.213154,
      "longitude": -85.680698,
      "city": "Alexandria",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46011,
      "latitude": 40.141033,
      "longitude": -85.766164,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46012,
      "latitude": 40.165726,
      "longitude": -85.633225,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46013,
      "latitude": 40.05668,
      "longitude": -85.674976,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46014,
      "latitude": 40.161733,
      "longitude": -85.719659,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46015,
      "latitude": 40.093792,
      "longitude": -85.657825,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46016,
      "latitude": 40.144894,
      "longitude": -85.742377,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46017,
      "latitude": 40.100275,
      "longitude": -85.710226,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46018,
      "latitude": 40.161733,
      "longitude": -85.719659,
      "city": "Anderson",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46030,
      "latitude": 40.167756,
      "longitude": -86.045789,
      "city": "Arcadia",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46031,
      "latitude": 40.174289,
      "longitude": -86.018909,
      "city": "Atlanta",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46032,
      "latitude": 40.071102,
      "longitude": -86.117215,
      "city": "Carmel",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46033,
      "latitude": 39.974404,
      "longitude": -86.082936,
      "city": "Carmel",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46034,
      "latitude": 40.154903,
      "longitude": -86.038337,
      "city": "Cicero",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46035,
      "latitude": 40.211041,
      "longitude": -86.64787,
      "city": "Colfax",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46036,
      "latitude": 40.27843,
      "longitude": -85.719657,
      "city": "Elwood",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46038,
      "latitude": 39.967406,
      "longitude": -85.964894,
      "city": "Fishers",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46039,
      "latitude": 40.373825,
      "longitude": -86.309246,
      "city": "Forest",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46040,
      "latitude": 39.894912,
      "longitude": -85.792762,
      "city": "Fortville",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46041,
      "latitude": 40.304355,
      "longitude": -86.468915,
      "city": "Frankfort",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46044,
      "latitude": 40.213173,
      "longitude": -85.786049,
      "city": "Frankton",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46045,
      "latitude": 40.289836,
      "longitude": -86.149423,
      "city": "Goldsmith",
      "state": "IN",
      "county": "Tipton"
  },
  {
      "zipCode": 46046,
      "latitude": 40.304473,
      "longitude": -86.469044,
      "city": "Hillisburg",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46047,
      "latitude": 40.284101,
      "longitude": -85.945505,
      "city": "Hobbs",
      "state": "IN",
      "county": "Tipton"
  },
  {
      "zipCode": 46048,
      "latitude": 39.95729,
      "longitude": -85.798078,
      "city": "Ingalls",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46049,
      "latitude": 40.307545,
      "longitude": -86.146767,
      "city": "Kempton",
      "state": "IN",
      "county": "Tipton"
  },
  {
      "zipCode": 46050,
      "latitude": 40.259929,
      "longitude": -86.45201,
      "city": "Kirklin",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46051,
      "latitude": 40.05956,
      "longitude": -85.834429,
      "city": "Lapel",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46052,
      "latitude": 40.050302,
      "longitude": -86.490381,
      "city": "Lebanon",
      "state": "IN",
      "county": "Boone"
  },
  {
      "zipCode": 46055,
      "latitude": 39.878113,
      "longitude": -85.897957,
      "city": "Mc Cordsville",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46056,
      "latitude": 39.985793,
      "longitude": -85.617925,
      "city": "Markleville",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46057,
      "latitude": 40.306276,
      "longitude": -86.490945,
      "city": "Michigantown",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46058,
      "latitude": 40.359433,
      "longitude": -86.628659,
      "city": "Mulberry",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46060,
      "latitude": 40.073328,
      "longitude": -85.999521,
      "city": "Noblesville",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46061,
      "latitude": 40.072462,
      "longitude": -86.052285,
      "city": "Noblesville",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46063,
      "latitude": 40.271524,
      "longitude": -85.72808,
      "city": "Orestes",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46064,
      "latitude": 40.107304,
      "longitude": -85.757678,
      "city": "Pendleton",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46065,
      "latitude": 40.360973,
      "longitude": -86.603449,
      "city": "Rossville",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46067,
      "latitude": 40.304473,
      "longitude": -86.469044,
      "city": "Sedalia",
      "state": "IN",
      "county": "Clinton"
  },
  {
      "zipCode": 46068,
      "latitude": 40.354088,
      "longitude": -86.118782,
      "city": "Sharpsville",
      "state": "IN",
      "county": "Tipton"
  },
  {
      "zipCode": 46069,
      "latitude": 40.129468,
      "longitude": -86.185273,
      "city": "Sheridan",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46070,
      "latitude": 40.314012,
      "longitude": -85.666794,
      "city": "Summitville",
      "state": "IN",
      "county": "Madison"
  },
  {
      "zipCode": 46071,
      "latitude": 40.068631,
      "longitude": -86.58186,
      "city": "Thorntown",
      "state": "IN",
      "county": "Boone"
  },
  {
      "zipCode": 46072,
      "latitude": 40.311418,
      "longitude": -86.063107,
      "city": "Tipton",
      "state": "IN",
      "county": "Tipton"
  },
  {
      "zipCode": 46074,
      "latitude": 40.097028,
      "longitude": -86.125716,
      "city": "Westfield",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46075,
      "latitude": 40.022608,
      "longitude": -86.346179,
      "city": "Whitestown",
      "state": "IN",
      "county": "Boone"
  },
  {
      "zipCode": 46076,
      "latitude": 40.348927,
      "longitude": -85.937642,
      "city": "Windfall",
      "state": "IN",
      "county": "Tipton"
  },
  {
      "zipCode": 46077,
      "latitude": 39.997457,
      "longitude": -86.298135,
      "city": "Zionsville",
      "state": "IN",
      "county": "Boone"
  },
  {
      "zipCode": 46082,
      "latitude": 40.072462,
      "longitude": -86.052285,
      "city": "Carmel",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46102,
      "latitude": 39.995599,
      "longitude": -86.619774,
      "city": "Advance",
      "state": "IN",
      "county": "Boone"
  },
  {
      "zipCode": 46103,
      "latitude": 39.736926,
      "longitude": -86.483474,
      "city": "Amo",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46104,
      "latitude": 39.663342,
      "longitude": -85.590869,
      "city": "Arlington",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46105,
      "latitude": 39.752428,
      "longitude": -86.812416,
      "city": "Bainbridge",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46106,
      "latitude": 39.489652,
      "longitude": -86.102271,
      "city": "Bargersville",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46107,
      "latitude": 39.713893,
      "longitude": -86.093293,
      "city": "Beech Grove",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46110,
      "latitude": 39.566614,
      "longitude": -85.91406,
      "city": "Boggstown",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 46111,
      "latitude": 39.539599,
      "longitude": -86.370057,
      "city": "Brooklyn",
      "state": "IN",
      "county": "Morgan"
  },
  {
      "zipCode": 46112,
      "latitude": 39.807038,
      "longitude": -86.470429,
      "city": "Brownsburg",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46113,
      "latitude": 39.6533,
      "longitude": -86.30309,
      "city": "Camby",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46114,
      "latitude": 39.762448,
      "longitude": -86.510386,
      "city": "Cartersburg",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46115,
      "latitude": 39.735455,
      "longitude": -85.552199,
      "city": "Carthage",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46117,
      "latitude": 39.838824,
      "longitude": -85.625798,
      "city": "Charlottesville",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46118,
      "latitude": 39.711088,
      "longitude": -86.495365,
      "city": "Clayton",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46120,
      "latitude": 39.658826,
      "longitude": -86.782523,
      "city": "Cloverdale",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46121,
      "latitude": 39.688598,
      "longitude": -86.594745,
      "city": "Coatesville",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46122,
      "latitude": 39.766794,
      "longitude": -86.530319,
      "city": "Danville",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46123,
      "latitude": 39.721434,
      "longitude": -86.488881,
      "city": "Avon",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46124,
      "latitude": 39.430795,
      "longitude": -86.099838,
      "city": "Edinburgh",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46125,
      "latitude": 39.506641,
      "longitude": -86.641773,
      "city": "Eminence",
      "state": "IN",
      "county": "Morgan"
  },
  {
      "zipCode": 46126,
      "latitude": 39.617344,
      "longitude": -85.87593,
      "city": "Fairland",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 46127,
      "latitude": 39.729032,
      "longitude": -85.333088,
      "city": "Falmouth",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46128,
      "latitude": 39.652271,
      "longitude": -86.73796,
      "city": "Fillmore",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46129,
      "latitude": 39.821516,
      "longitude": -85.765169,
      "city": "Finly",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46130,
      "latitude": 39.580663,
      "longitude": -85.809775,
      "city": "Fountaintown",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 46131,
      "latitude": 39.469172,
      "longitude": -86.068718,
      "city": "Franklin",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46133,
      "latitude": 39.58598,
      "longitude": -85.276882,
      "city": "Glenwood",
      "state": "IN",
      "county": "Fayette"
  },
  {
      "zipCode": 46135,
      "latitude": 39.699485,
      "longitude": -86.857172,
      "city": "Greencastle",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46140,
      "latitude": 39.821787,
      "longitude": -85.775403,
      "city": "Greenfield",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46142,
      "latitude": 39.491246,
      "longitude": -86.100454,
      "city": "Greenwood",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46143,
      "latitude": 39.50708,
      "longitude": -86.10149,
      "city": "Greenwood",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46144,
      "latitude": 39.66172,
      "longitude": -85.647634,
      "city": "Gwynneville",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 46146,
      "latitude": 39.619923,
      "longitude": -85.46554,
      "city": "Homer",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46147,
      "latitude": 39.988674,
      "longitude": -86.563204,
      "city": "Jamestown",
      "state": "IN",
      "county": "Boone"
  },
  {
      "zipCode": 46148,
      "latitude": 39.836197,
      "longitude": -85.474444,
      "city": "Knightstown",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 46149,
      "latitude": 39.871667,
      "longitude": -86.560328,
      "city": "Lizton",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46150,
      "latitude": 39.546509,
      "longitude": -85.583666,
      "city": "Manilla",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46151,
      "latitude": 39.477625,
      "longitude": -86.466792,
      "city": "Martinsville",
      "state": "IN",
      "county": "Morgan"
  },
  {
      "zipCode": 46154,
      "latitude": 39.856763,
      "longitude": -85.768357,
      "city": "Maxwell",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46155,
      "latitude": 39.743769,
      "longitude": -85.431184,
      "city": "Mays",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46156,
      "latitude": 39.488994,
      "longitude": -85.486278,
      "city": "Milroy",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46157,
      "latitude": 39.555178,
      "longitude": -86.531957,
      "city": "Monrovia",
      "state": "IN",
      "county": "Morgan"
  },
  {
      "zipCode": 46158,
      "latitude": 39.50942,
      "longitude": -86.437089,
      "city": "Mooresville",
      "state": "IN",
      "county": "Morgan"
  },
  {
      "zipCode": 46160,
      "latitude": 39.244566,
      "longitude": -86.267561,
      "city": "Morgantown",
      "state": "IN",
      "county": "Brown"
  },
  {
      "zipCode": 46161,
      "latitude": 39.65521,
      "longitude": -85.71264,
      "city": "Morristown",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 46162,
      "latitude": 39.560511,
      "longitude": -85.96563,
      "city": "Needham",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46163,
      "latitude": 39.739927,
      "longitude": -85.885962,
      "city": "New Palestine",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46164,
      "latitude": 39.391505,
      "longitude": -86.109534,
      "city": "Nineveh",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46165,
      "latitude": 39.839162,
      "longitude": -86.640794,
      "city": "North Salem",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46166,
      "latitude": 39.427654,
      "longitude": -86.533865,
      "city": "Paragon",
      "state": "IN",
      "county": "Morgan"
  },
  {
      "zipCode": 46167,
      "latitude": 39.784191,
      "longitude": -86.475318,
      "city": "Pittsboro",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46168,
      "latitude": 39.729892,
      "longitude": -86.423412,
      "city": "Plainfield",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46170,
      "latitude": 39.668441,
      "longitude": -86.828402,
      "city": "Putnamville",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46171,
      "latitude": 39.543141,
      "longitude": -86.955955,
      "city": "Reelsville",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46172,
      "latitude": 39.821381,
      "longitude": -86.814317,
      "city": "Roachdale",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46173,
      "latitude": 39.612836,
      "longitude": -85.439133,
      "city": "Rushville",
      "state": "IN",
      "county": "Rush"
  },
  {
      "zipCode": 46175,
      "latitude": 39.822491,
      "longitude": -86.973306,
      "city": "Russellville",
      "state": "IN",
      "county": "Putnam"
  },
  {
      "zipCode": 46176,
      "latitude": 39.511568,
      "longitude": -85.791993,
      "city": "Shelbyville",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 46180,
      "latitude": 39.632997,
      "longitude": -86.607678,
      "city": "Stilesville",
      "state": "IN",
      "county": "Hendricks"
  },
  {
      "zipCode": 46181,
      "latitude": 39.416652,
      "longitude": -86.103674,
      "city": "Trafalgar",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46182,
      "latitude": 39.515,
      "longitude": -85.781538,
      "city": "Waldron",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 46183,
      "latitude": 39.648251,
      "longitude": -86.28509,
      "city": "West Newton",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46184,
      "latitude": 39.50607,
      "longitude": -86.08434,
      "city": "Whiteland",
      "state": "IN",
      "county": "Johnson"
  },
  {
      "zipCode": 46186,
      "latitude": 39.895139,
      "longitude": -85.643767,
      "city": "Wilkinson",
      "state": "IN",
      "county": "Hancock"
  },
  {
      "zipCode": 46201,
      "latitude": 39.775092,
      "longitude": -86.13216,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46202,
      "latitude": 39.762392,
      "longitude": -86.143736,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46203,
      "latitude": 39.743493,
      "longitude": -86.113535,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46204,
      "latitude": 39.772692,
      "longitude": -86.158038,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46205,
      "latitude": 39.822641,
      "longitude": -86.129886,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46206,
      "latitude": 39.761293,
      "longitude": -86.161336,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46207,
      "latitude": 39.767293,
      "longitude": -86.160616,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46208,
      "latitude": 39.832741,
      "longitude": -86.190186,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46209,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46211,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46214,
      "latitude": 39.792379,
      "longitude": -86.287465,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46216,
      "latitude": 39.864689,
      "longitude": -86.013632,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46217,
      "latitude": 39.678245,
      "longitude": -86.197637,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46218,
      "latitude": 39.806991,
      "longitude": -86.100535,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46219,
      "latitude": 39.782841,
      "longitude": -86.081084,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46220,
      "latitude": 39.86904,
      "longitude": -86.10895,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46221,
      "latitude": 39.764258,
      "longitude": -86.130655,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46222,
      "latitude": 39.783642,
      "longitude": -86.207687,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46223,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46224,
      "latitude": 39.794042,
      "longitude": -86.27074,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46225,
      "latitude": 39.743793,
      "longitude": -86.167886,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46226,
      "latitude": 39.832577,
      "longitude": -86.083634,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46227,
      "latitude": 39.678945,
      "longitude": -86.130154,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46228,
      "latitude": 39.846191,
      "longitude": -86.195081,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46229,
      "latitude": 39.798941,
      "longitude": -86.035533,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46230,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46231,
      "latitude": 39.703844,
      "longitude": -86.30294,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46234,
      "latitude": 39.808793,
      "longitude": -86.316288,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46235,
      "latitude": 39.84709,
      "longitude": -85.974081,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46236,
      "latitude": 39.868939,
      "longitude": -85.976532,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46237,
      "latitude": 39.742726,
      "longitude": -86.171117,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46239,
      "latitude": 39.726493,
      "longitude": -86.000482,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46240,
      "latitude": 39.877991,
      "longitude": -86.200688,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46241,
      "latitude": 39.709624,
      "longitude": -86.261389,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46242,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46244,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46247,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46249,
      "latitude": 39.858989,
      "longitude": -86.006082,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46250,
      "latitude": 39.904838,
      "longitude": -86.067263,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46251,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46253,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46254,
      "latitude": 39.81594,
      "longitude": -86.22681,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46255,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46256,
      "latitude": 39.897708,
      "longitude": -86.009447,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46259,
      "latitude": 39.667045,
      "longitude": -85.998092,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46260,
      "latitude": 39.89384,
      "longitude": -86.137988,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46266,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46268,
      "latitude": 39.868173,
      "longitude": -86.21231,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46274,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46275,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46277,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46278,
      "latitude": 39.872591,
      "longitude": -86.276791,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46280,
      "latitude": 39.9416,
      "longitude": -86.115707,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46282,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46283,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46285,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46290,
      "latitude": 39.93731,
      "longitude": -86.199445,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Hamilton"
  },
  {
      "zipCode": 46291,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46295,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46296,
      "latitude": 39.7683,
      "longitude": -86.158,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46298,
      "latitude": 39.779492,
      "longitude": -86.132837,
      "city": "Indianapolis",
      "state": "IN",
      "county": "Marion"
  },
  {
      "zipCode": 46301,
      "latitude": 41.683796,
      "longitude": -86.975178,
      "city": "Beverly Shores",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46302,
      "latitude": 41.354186,
      "longitude": -87.130431,
      "city": "Boone Grove",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46303,
      "latitude": 41.311861,
      "longitude": -87.41665,
      "city": "Cedar Lake",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46304,
      "latitude": 41.498946,
      "longitude": -87.087021,
      "city": "Chesterton",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46307,
      "latitude": 41.444409,
      "longitude": -87.360089,
      "city": "Crown Point",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46308,
      "latitude": 41.461543,
      "longitude": -87.372779,
      "city": "Crown Point",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46310,
      "latitude": 41.107336,
      "longitude": -87.208423,
      "city": "Demotte",
      "state": "IN",
      "county": "Jasper"
  },
  {
      "zipCode": 46311,
      "latitude": 41.451993,
      "longitude": -87.499489,
      "city": "Dyer",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46312,
      "latitude": 41.640835,
      "longitude": -87.46149,
      "city": "East Chicago",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46319,
      "latitude": 41.522129,
      "longitude": -87.390338,
      "city": "Griffith",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46320,
      "latitude": 41.444246,
      "longitude": -87.487242,
      "city": "Hammond",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46321,
      "latitude": 41.550535,
      "longitude": -87.502585,
      "city": "Munster",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46322,
      "latitude": 41.577699,
      "longitude": -87.47744,
      "city": "Highland",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46323,
      "latitude": 41.590519,
      "longitude": -87.45644,
      "city": "Hammond",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46324,
      "latitude": 41.585185,
      "longitude": -87.496665,
      "city": "Hammond",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46325,
      "latitude": 41.461543,
      "longitude": -87.372779,
      "city": "Hammond",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46327,
      "latitude": 41.615085,
      "longitude": -87.49039,
      "city": "Hammond",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46340,
      "latitude": 41.378592,
      "longitude": -86.788687,
      "city": "Hanna",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46341,
      "latitude": 41.347188,
      "longitude": -87.152358,
      "city": "Hebron",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46342,
      "latitude": 41.520515,
      "longitude": -87.347486,
      "city": "Hobart",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46345,
      "latitude": 41.526023,
      "longitude": -86.699703,
      "city": "Kingsbury",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46346,
      "latitude": 41.480172,
      "longitude": -86.691914,
      "city": "Kingsford Heights",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46347,
      "latitude": 41.301319,
      "longitude": -87.066825,
      "city": "Kouts",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46348,
      "latitude": 41.394105,
      "longitude": -86.806742,
      "city": "La Crosse",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46349,
      "latitude": 41.108242,
      "longitude": -87.418963,
      "city": "Lake Village",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 46350,
      "latitude": 41.537585,
      "longitude": -86.73764,
      "city": "La Porte",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46352,
      "latitude": 41.498992,
      "longitude": -86.709883,
      "city": "La Porte",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46355,
      "latitude": 41.359415,
      "longitude": -87.27081,
      "city": "Leroy",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46356,
      "latitude": 41.280102,
      "longitude": -87.411308,
      "city": "Lowell",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46360,
      "latitude": 41.594185,
      "longitude": -86.756985,
      "city": "Michigan City",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46361,
      "latitude": 41.70354,
      "longitude": -86.915077,
      "city": "Michigan City",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46365,
      "latitude": 41.609081,
      "longitude": -86.543956,
      "city": "Mill Creek",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46366,
      "latitude": 41.25163,
      "longitude": -86.735127,
      "city": "North Judson",
      "state": "IN",
      "county": "Starke"
  },
  {
      "zipCode": 46368,
      "latitude": 41.473367,
      "longitude": -87.12927,
      "city": "Portage",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46371,
      "latitude": 41.686609,
      "longitude": -86.599414,
      "city": "Rolling Prairie",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46372,
      "latitude": 41.143358,
      "longitude": -87.322049,
      "city": "Roselawn",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 46373,
      "latitude": 41.453965,
      "longitude": -87.453086,
      "city": "Saint John",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46374,
      "latitude": 41.216655,
      "longitude": -86.884,
      "city": "San Pierre",
      "state": "IN",
      "county": "Starke"
  },
  {
      "zipCode": 46375,
      "latitude": 41.487733,
      "longitude": -87.441525,
      "city": "Schererville",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46376,
      "latitude": 41.189953,
      "longitude": -87.477881,
      "city": "Schneider",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46377,
      "latitude": 41.191734,
      "longitude": -87.339778,
      "city": "Shelby",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46379,
      "latitude": 41.167119,
      "longitude": -87.43355,
      "city": "Sumava Resorts",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 46380,
      "latitude": 41.194816,
      "longitude": -86.968614,
      "city": "Tefft",
      "state": "IN",
      "county": "Jasper"
  },
  {
      "zipCode": 46381,
      "latitude": 41.17115,
      "longitude": -87.331323,
      "city": "Thayer",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 46382,
      "latitude": 41.466863,
      "longitude": -86.772226,
      "city": "Union Mills",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46383,
      "latitude": 41.454731,
      "longitude": -87.065565,
      "city": "Valparaiso",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46384,
      "latitude": 41.490545,
      "longitude": -87.076094,
      "city": "Valparaiso",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46385,
      "latitude": 41.470567,
      "longitude": -87.078309,
      "city": "Valparaiso",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46390,
      "latitude": 41.421123,
      "longitude": -86.872078,
      "city": "Wanatah",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46391,
      "latitude": 41.549933,
      "longitude": -86.742979,
      "city": "Westville",
      "state": "IN",
      "county": "La Porte"
  },
  {
      "zipCode": 46392,
      "latitude": 41.133299,
      "longitude": -87.063818,
      "city": "Wheatfield",
      "state": "IN",
      "county": "Jasper"
  },
  {
      "zipCode": 46393,
      "latitude": 41.511578,
      "longitude": -87.179177,
      "city": "Wheeler",
      "state": "IN",
      "county": "Porter"
  },
  {
      "zipCode": 46394,
      "latitude": 41.656785,
      "longitude": -87.493392,
      "city": "Whiting",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46401,
      "latitude": 41.590686,
      "longitude": -87.319937,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46402,
      "latitude": 41.599336,
      "longitude": -87.336338,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46403,
      "latitude": 41.605636,
      "longitude": -87.268536,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46404,
      "latitude": 41.584136,
      "longitude": -87.377538,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46405,
      "latitude": 41.555785,
      "longitude": -87.354587,
      "city": "Lake Station",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46406,
      "latitude": 41.596012,
      "longitude": -87.388189,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46407,
      "latitude": 41.578886,
      "longitude": -87.319987,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46408,
      "latitude": 41.544936,
      "longitude": -87.375307,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46409,
      "latitude": 41.545636,
      "longitude": -87.304186,
      "city": "Gary",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46410,
      "latitude": 41.497212,
      "longitude": -87.338656,
      "city": "Merrillville",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46411,
      "latitude": 41.461543,
      "longitude": -87.372779,
      "city": "Merrillville",
      "state": "IN",
      "county": "Lake"
  },
  {
      "zipCode": 46501,
      "latitude": 41.242669,
      "longitude": -86.248052,
      "city": "Argos",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46502,
      "latitude": 41.254035,
      "longitude": -85.969708,
      "city": "Atwood",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46504,
      "latitude": 41.323904,
      "longitude": -86.150818,
      "city": "Bourbon",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46506,
      "latitude": 41.40577,
      "longitude": -86.242235,
      "city": "Bremen",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46507,
      "latitude": 41.712983,
      "longitude": -85.824845,
      "city": "Bristol",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46508,
      "latitude": 41.154726,
      "longitude": -85.969282,
      "city": "Burket",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46510,
      "latitude": 41.136771,
      "longitude": -85.871487,
      "city": "Claypool",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46511,
      "latitude": 41.314629,
      "longitude": -86.386582,
      "city": "Culver",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46513,
      "latitude": 41.361903,
      "longitude": -86.444254,
      "city": "Donaldson",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46514,
      "latitude": 41.71873,
      "longitude": -85.937024,
      "city": "Elkhart",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46515,
      "latitude": 41.641449,
      "longitude": -85.938348,
      "city": "Elkhart",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46516,
      "latitude": 41.620856,
      "longitude": -85.897236,
      "city": "Elkhart",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46517,
      "latitude": 41.617705,
      "longitude": -85.965717,
      "city": "Elkhart",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46524,
      "latitude": 41.296306,
      "longitude": -85.99843,
      "city": "Etna Green",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46526,
      "latitude": 41.584839,
      "longitude": -85.858059,
      "city": "Goshen",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46527,
      "latitude": 41.597739,
      "longitude": -85.858076,
      "city": "Goshen",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46528,
      "latitude": 41.624763,
      "longitude": -85.839139,
      "city": "Goshen",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46530,
      "latitude": 41.734912,
      "longitude": -86.139868,
      "city": "Granger",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46531,
      "latitude": 41.34963,
      "longitude": -86.524321,
      "city": "Grovertown",
      "state": "IN",
      "county": "Starke"
  },
  {
      "zipCode": 46532,
      "latitude": 41.393141,
      "longitude": -86.594849,
      "city": "Hamlet",
      "state": "IN",
      "county": "Starke"
  },
  {
      "zipCode": 46534,
      "latitude": 41.280907,
      "longitude": -86.608249,
      "city": "Knox",
      "state": "IN",
      "county": "Starke"
  },
  {
      "zipCode": 46536,
      "latitude": 41.526373,
      "longitude": -86.270086,
      "city": "Lakeville",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46537,
      "latitude": 41.455431,
      "longitude": -86.309368,
      "city": "Lapaz",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46538,
      "latitude": 41.342883,
      "longitude": -85.823785,
      "city": "Leesburg",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46539,
      "latitude": 41.177648,
      "longitude": -86.008586,
      "city": "Mentone",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46540,
      "latitude": 41.646192,
      "longitude": -85.831327,
      "city": "Middlebury",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46542,
      "latitude": 41.28296,
      "longitude": -85.871138,
      "city": "Milford",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46543,
      "latitude": 41.531255,
      "longitude": -85.712083,
      "city": "Millersburg",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46544,
      "latitude": 41.61536,
      "longitude": -86.287884,
      "city": "Mishawaka",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46545,
      "latitude": 41.696407,
      "longitude": -86.304735,
      "city": "Mishawaka",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46546,
      "latitude": 41.688432,
      "longitude": -86.196485,
      "city": "Mishawaka",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46550,
      "latitude": 41.574743,
      "longitude": -85.972724,
      "city": "Nappanee",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46552,
      "latitude": 41.701172,
      "longitude": -86.455494,
      "city": "New Carlisle",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46553,
      "latitude": 41.47867,
      "longitude": -85.8192,
      "city": "New Paris",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46554,
      "latitude": 41.561716,
      "longitude": -86.414045,
      "city": "North Liberty",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46555,
      "latitude": 41.332568,
      "longitude": -85.710285,
      "city": "North Webster",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46556,
      "latitude": 41.596769,
      "longitude": -86.292991,
      "city": "Notre Dame",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46561,
      "latitude": 41.669504,
      "longitude": -86.087002,
      "city": "Osceola",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46562,
      "latitude": 41.265769,
      "longitude": -85.854745,
      "city": "Pierceton",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46563,
      "latitude": 41.352997,
      "longitude": -86.301486,
      "city": "Plymouth",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46565,
      "latitude": 41.67572,
      "longitude": -85.567362,
      "city": "Shipshewana",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46566,
      "latitude": 41.137923,
      "longitude": -85.88883,
      "city": "Sidney",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46567,
      "latitude": 41.296386,
      "longitude": -85.844729,
      "city": "Syracuse",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46570,
      "latitude": 41.215971,
      "longitude": -86.12282,
      "city": "Tippecanoe",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46571,
      "latitude": 41.578904,
      "longitude": -85.540055,
      "city": "Topeka",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46572,
      "latitude": 41.325513,
      "longitude": -86.260569,
      "city": "Tyner",
      "state": "IN",
      "county": "Marshall"
  },
  {
      "zipCode": 46573,
      "latitude": 41.58574,
      "longitude": -85.882695,
      "city": "Wakarusa",
      "state": "IN",
      "county": "Elkhart"
  },
  {
      "zipCode": 46574,
      "latitude": 41.488957,
      "longitude": -86.446734,
      "city": "Walkerton",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46580,
      "latitude": 41.2228,
      "longitude": -85.860179,
      "city": "Warsaw",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46581,
      "latitude": 41.239365,
      "longitude": -85.864267,
      "city": "Warsaw",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46582,
      "latitude": 41.254225,
      "longitude": -85.849508,
      "city": "Warsaw",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46590,
      "latitude": 41.232725,
      "longitude": -85.826346,
      "city": "Winona Lake",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46595,
      "latitude": 41.518136,
      "longitude": -86.165227,
      "city": "Wyatt",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46601,
      "latitude": 41.673383,
      "longitude": -86.251654,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46604,
      "latitude": 41.664198,
      "longitude": -86.221805,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46612,
      "latitude": 41.628483,
      "longitude": -86.234589,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46613,
      "latitude": 41.656573,
      "longitude": -86.255696,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46614,
      "latitude": 41.616532,
      "longitude": -86.278463,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46615,
      "latitude": 41.677046,
      "longitude": -86.216105,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46616,
      "latitude": 41.695957,
      "longitude": -86.264293,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46617,
      "latitude": 41.682897,
      "longitude": -86.238906,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46619,
      "latitude": 41.672919,
      "longitude": -86.296056,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46620,
      "latitude": 41.596769,
      "longitude": -86.292991,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46624,
      "latitude": 41.733223,
      "longitude": -86.283278,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46626,
      "latitude": 41.677397,
      "longitude": -86.252506,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46628,
      "latitude": 41.716559,
      "longitude": -86.335738,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46629,
      "latitude": 41.671919,
      "longitude": -86.27911,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46634,
      "latitude": 41.596769,
      "longitude": -86.292991,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46635,
      "latitude": 41.686358,
      "longitude": -86.338666,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46637,
      "latitude": 41.699077,
      "longitude": -86.315321,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46660,
      "latitude": 41.596769,
      "longitude": -86.292991,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46680,
      "latitude": 41.596769,
      "longitude": -86.292991,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46699,
      "latitude": 41.596769,
      "longitude": -86.292991,
      "city": "South Bend",
      "state": "IN",
      "county": "St Joseph"
  },
  {
      "zipCode": 46701,
      "latitude": 41.376028,
      "longitude": -85.413862,
      "city": "Albion",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46702,
      "latitude": 40.820278,
      "longitude": -85.597862,
      "city": "Andrews",
      "state": "IN",
      "county": "Huntington"
  },
  {
      "zipCode": 46703,
      "latitude": 41.672567,
      "longitude": -85.000474,
      "city": "Angola",
      "state": "IN",
      "county": "Steuben"
  },
  {
      "zipCode": 46704,
      "latitude": 41.103797,
      "longitude": -85.292474,
      "city": "Arcola",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46705,
      "latitude": 41.501455,
      "longitude": -85.071275,
      "city": "Ashley",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46706,
      "latitude": 41.377758,
      "longitude": -85.027689,
      "city": "Auburn",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46710,
      "latitude": 41.368123,
      "longitude": -85.391019,
      "city": "Avilla",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46711,
      "latitude": 40.671628,
      "longitude": -84.934331,
      "city": "Berne",
      "state": "IN",
      "county": "Adams"
  },
  {
      "zipCode": 46713,
      "latitude": 40.829196,
      "longitude": -85.489198,
      "city": "Bippus",
      "state": "IN",
      "county": "Huntington"
  },
  {
      "zipCode": 46714,
      "latitude": 40.71327,
      "longitude": -85.2023,
      "city": "Bluffton",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46720,
      "latitude": 41.395275,
      "longitude": -85.423462,
      "city": "Brimfield",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46721,
      "latitude": 41.426441,
      "longitude": -84.912517,
      "city": "Butler",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46723,
      "latitude": 41.190811,
      "longitude": -85.389672,
      "city": "Churubusco",
      "state": "IN",
      "county": "Whitley"
  },
  {
      "zipCode": 46725,
      "latitude": 41.14956,
      "longitude": -85.50042,
      "city": "Columbia City",
      "state": "IN",
      "county": "Whitley"
  },
  {
      "zipCode": 46730,
      "latitude": 41.463082,
      "longitude": -85.137324,
      "city": "Corunna",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46731,
      "latitude": 40.794056,
      "longitude": -85.104206,
      "city": "Craigville",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46732,
      "latitude": 41.377903,
      "longitude": -85.59875,
      "city": "Cromwell",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46733,
      "latitude": 40.81911,
      "longitude": -84.93785,
      "city": "Decatur",
      "state": "IN",
      "county": "Adams"
  },
  {
      "zipCode": 46737,
      "latitude": 41.711462,
      "longitude": -84.945643,
      "city": "Fremont",
      "state": "IN",
      "county": "Steuben"
  },
  {
      "zipCode": 46738,
      "latitude": 41.323007,
      "longitude": -85.140707,
      "city": "Garrett",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46740,
      "latitude": 40.612058,
      "longitude": -84.936072,
      "city": "Geneva",
      "state": "IN",
      "county": "Adams"
  },
  {
      "zipCode": 46741,
      "latitude": 41.195015,
      "longitude": -84.963984,
      "city": "Grabill",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46742,
      "latitude": 41.572304,
      "longitude": -84.897465,
      "city": "Hamilton",
      "state": "IN",
      "county": "Steuben"
  },
  {
      "zipCode": 46743,
      "latitude": 41.227324,
      "longitude": -84.987882,
      "city": "Harlan",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46745,
      "latitude": 40.957347,
      "longitude": -85.002148,
      "city": "Hoagland",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46746,
      "latitude": 41.711914,
      "longitude": -85.367474,
      "city": "Howe",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46747,
      "latitude": 41.577125,
      "longitude": -85.113166,
      "city": "Hudson",
      "state": "IN",
      "county": "Steuben"
  },
  {
      "zipCode": 46748,
      "latitude": 41.237988,
      "longitude": -85.159437,
      "city": "Huntertown",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46750,
      "latitude": 40.842697,
      "longitude": -85.489225,
      "city": "Huntington",
      "state": "IN",
      "county": "Huntington"
  },
  {
      "zipCode": 46755,
      "latitude": 41.431488,
      "longitude": -85.389073,
      "city": "Kendallville",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46759,
      "latitude": 40.603317,
      "longitude": -85.205635,
      "city": "Keystone",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46760,
      "latitude": 41.369423,
      "longitude": -85.543448,
      "city": "Kimmell",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46761,
      "latitude": 41.655135,
      "longitude": -85.387208,
      "city": "Lagrange",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46763,
      "latitude": 41.292913,
      "longitude": -85.27904,
      "city": "Laotto",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46764,
      "latitude": 41.206938,
      "longitude": -85.591994,
      "city": "Larwill",
      "state": "IN",
      "county": "Whitley"
  },
  {
      "zipCode": 46765,
      "latitude": 41.232203,
      "longitude": -85.043118,
      "city": "Leo",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46766,
      "latitude": 40.698551,
      "longitude": -85.278222,
      "city": "Liberty Center",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46767,
      "latitude": 41.405478,
      "longitude": -85.42573,
      "city": "Ligonier",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46769,
      "latitude": 40.74532,
      "longitude": -84.937926,
      "city": "Linn Grove",
      "state": "IN",
      "county": "Adams"
  },
  {
      "zipCode": 46770,
      "latitude": 40.862309,
      "longitude": -85.354611,
      "city": "Markle",
      "state": "IN",
      "county": "Huntington"
  },
  {
      "zipCode": 46771,
      "latitude": 41.665489,
      "longitude": -85.267567,
      "city": "Mongo",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46772,
      "latitude": 40.709518,
      "longitude": -84.919164,
      "city": "Monroe",
      "state": "IN",
      "county": "Adams"
  },
  {
      "zipCode": 46773,
      "latitude": 41.059007,
      "longitude": -84.90402,
      "city": "Monroeville",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46774,
      "latitude": 41.102098,
      "longitude": -84.962038,
      "city": "New Haven",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46776,
      "latitude": 41.71364,
      "longitude": -85.140168,
      "city": "Orland",
      "state": "IN",
      "county": "Steuben"
  },
  {
      "zipCode": 46777,
      "latitude": 40.855435,
      "longitude": -85.203797,
      "city": "Ossian",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46778,
      "latitude": 40.611419,
      "longitude": -85.152604,
      "city": "Petroleum",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46779,
      "latitude": 41.577443,
      "longitude": -85.031873,
      "city": "Pleasant Lake",
      "state": "IN",
      "county": "Steuben"
  },
  {
      "zipCode": 46780,
      "latitude": 40.74532,
      "longitude": -84.937926,
      "city": "Pleasant Mills",
      "state": "IN",
      "county": "Adams"
  },
  {
      "zipCode": 46781,
      "latitude": 40.639684,
      "longitude": -85.259163,
      "city": "Poneto",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46782,
      "latitude": 40.83204,
      "longitude": -85.005402,
      "city": "Preble",
      "state": "IN",
      "county": "Adams"
  },
  {
      "zipCode": 46783,
      "latitude": 40.954446,
      "longitude": -85.393868,
      "city": "Roanoke",
      "state": "IN",
      "county": "Huntington"
  },
  {
      "zipCode": 46784,
      "latitude": 41.401296,
      "longitude": -85.401402,
      "city": "Rome City",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46785,
      "latitude": 41.319754,
      "longitude": -84.888552,
      "city": "Saint Joe",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46786,
      "latitude": 41.536899,
      "longitude": -85.319241,
      "city": "South Milford",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46787,
      "latitude": 41.071204,
      "longitude": -85.510981,
      "city": "South Whitley",
      "state": "IN",
      "county": "Whitley"
  },
  {
      "zipCode": 46788,
      "latitude": 41.302927,
      "longitude": -84.902416,
      "city": "Spencerville",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46789,
      "latitude": 41.583493,
      "longitude": -85.199186,
      "city": "Stroh",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46791,
      "latitude": 40.859358,
      "longitude": -85.270039,
      "city": "Uniondale",
      "state": "IN",
      "county": "Wells"
  },
  {
      "zipCode": 46792,
      "latitude": 40.712066,
      "longitude": -85.468798,
      "city": "Warren",
      "state": "IN",
      "county": "Huntington"
  },
  {
      "zipCode": 46793,
      "latitude": 41.461019,
      "longitude": -85.019562,
      "city": "Waterloo",
      "state": "IN",
      "county": "De Kalb"
  },
  {
      "zipCode": 46794,
      "latitude": 41.476682,
      "longitude": -85.386856,
      "city": "Wawaka",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46795,
      "latitude": 41.583475,
      "longitude": -85.337737,
      "city": "Wolcottville",
      "state": "IN",
      "county": "Lagrange"
  },
  {
      "zipCode": 46796,
      "latitude": 41.336115,
      "longitude": -85.500246,
      "city": "Wolflake",
      "state": "IN",
      "county": "Noble"
  },
  {
      "zipCode": 46797,
      "latitude": 41.125044,
      "longitude": -84.874249,
      "city": "Woodburn",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46798,
      "latitude": 41.009498,
      "longitude": -85.086518,
      "city": "Yoder",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46799,
      "latitude": 40.918797,
      "longitude": -85.282422,
      "city": "Zanesville",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46801,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46802,
      "latitude": 41.065397,
      "longitude": -85.15842,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46803,
      "latitude": 41.065648,
      "longitude": -85.084069,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46804,
      "latitude": 41.057168,
      "longitude": -85.239172,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46805,
      "latitude": 41.099948,
      "longitude": -85.078019,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46806,
      "latitude": 41.049248,
      "longitude": -85.082319,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46807,
      "latitude": 41.042342,
      "longitude": -85.14682,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46808,
      "latitude": 41.093898,
      "longitude": -85.184121,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46809,
      "latitude": 41.037097,
      "longitude": -85.205071,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46814,
      "latitude": 41.045647,
      "longitude": -85.305823,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46815,
      "latitude": 41.141189,
      "longitude": -85.072068,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46816,
      "latitude": 40.988047,
      "longitude": -85.033817,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46818,
      "latitude": 41.112548,
      "longitude": -85.203895,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46819,
      "latitude": 40.978697,
      "longitude": -85.126419,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46825,
      "latitude": 41.138003,
      "longitude": -85.104001,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46835,
      "latitude": 41.156698,
      "longitude": -85.057218,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46845,
      "latitude": 41.095957,
      "longitude": -85.084581,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46850,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46851,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46852,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46853,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46854,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46855,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46856,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46857,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46858,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46859,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46860,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46861,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46862,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46863,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46864,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46865,
      "latitude": 41.126298,
      "longitude": -85.090669,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46866,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46867,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46868,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46869,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46885,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46895,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46896,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46897,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46898,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46899,
      "latitude": 41.093763,
      "longitude": -85.070713,
      "city": "Fort Wayne",
      "state": "IN",
      "county": "Allen"
  },
  {
      "zipCode": 46901,
      "latitude": 40.506851,
      "longitude": -86.171054,
      "city": "Kokomo",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46902,
      "latitude": 40.444291,
      "longitude": -86.17559,
      "city": "Kokomo",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46903,
      "latitude": 40.469625,
      "longitude": -86.11894,
      "city": "Kokomo",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46904,
      "latitude": 40.469625,
      "longitude": -86.11894,
      "city": "Kokomo",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46910,
      "latitude": 41.047932,
      "longitude": -86.069172,
      "city": "Akron",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46911,
      "latitude": 40.650476,
      "longitude": -85.943552,
      "city": "Amboy",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46912,
      "latitude": 41.040988,
      "longitude": -86.206925,
      "city": "Athens",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46913,
      "latitude": 40.500141,
      "longitude": -86.507065,
      "city": "Bringhurst",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46914,
      "latitude": 40.659068,
      "longitude": -86.102125,
      "city": "Bunker Hill",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46915,
      "latitude": 40.495181,
      "longitude": -86.394248,
      "city": "Burlington",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46916,
      "latitude": 40.584272,
      "longitude": -86.573952,
      "city": "Burrows",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46917,
      "latitude": 40.626954,
      "longitude": -86.499148,
      "city": "Camden",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46919,
      "latitude": 40.578034,
      "longitude": -85.878104,
      "city": "Converse",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46920,
      "latitude": 40.485424,
      "longitude": -86.488525,
      "city": "Cutler",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46921,
      "latitude": 40.960538,
      "longitude": -86.12908,
      "city": "Deedsville",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46922,
      "latitude": 41.040988,
      "longitude": -86.206925,
      "city": "Delong",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46923,
      "latitude": 40.580452,
      "longitude": -86.637891,
      "city": "Delphi",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46926,
      "latitude": 40.867036,
      "longitude": -86.07524,
      "city": "Denver",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46928,
      "latitude": 40.515421,
      "longitude": -85.661196,
      "city": "Fairmount",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46929,
      "latitude": 40.556269,
      "longitude": -86.490521,
      "city": "Flora",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46930,
      "latitude": 40.40993,
      "longitude": -85.571035,
      "city": "Fowlerton",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46931,
      "latitude": 40.948405,
      "longitude": -86.265814,
      "city": "Fulton",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46932,
      "latitude": 40.634254,
      "longitude": -86.269625,
      "city": "Galveston",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46933,
      "latitude": 40.54429,
      "longitude": -85.585245,
      "city": "Gas City",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46935,
      "latitude": 41.040988,
      "longitude": -86.206925,
      "city": "Grass Creek",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46936,
      "latitude": 40.485442,
      "longitude": -85.997326,
      "city": "Greentown",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46937,
      "latitude": 40.418662,
      "longitude": -86.018139,
      "city": "Hemlock",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46938,
      "latitude": 40.44673,
      "longitude": -85.650398,
      "city": "Jonesboro",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46939,
      "latitude": 41.040533,
      "longitude": -86.374647,
      "city": "Kewanna",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46940,
      "latitude": 40.696155,
      "longitude": -85.740428,
      "city": "La Fontaine",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46941,
      "latitude": 40.826475,
      "longitude": -85.700909,
      "city": "Lagro",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46942,
      "latitude": 40.736111,
      "longitude": -86.37341,
      "city": "Lake Cicott",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46943,
      "latitude": 40.974279,
      "longitude": -85.837546,
      "city": "Laketon",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46945,
      "latitude": 41.040988,
      "longitude": -86.206925,
      "city": "Leiters Ford",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46946,
      "latitude": 41.035629,
      "longitude": -85.735756,
      "city": "Liberty Mills",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46947,
      "latitude": 40.77927,
      "longitude": -86.375168,
      "city": "Logansport",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46950,
      "latitude": 40.875277,
      "longitude": -86.354066,
      "city": "Lucerne",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46951,
      "latitude": 40.95841,
      "longitude": -86.129366,
      "city": "Macy",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46952,
      "latitude": 40.519756,
      "longitude": -85.636731,
      "city": "Marion",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46953,
      "latitude": 40.499959,
      "longitude": -85.626401,
      "city": "Marion",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46957,
      "latitude": 40.389831,
      "longitude": -85.476619,
      "city": "Matthews",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46958,
      "latitude": 40.822384,
      "longitude": -86.116189,
      "city": "Mexico",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46959,
      "latitude": 40.61466,
      "longitude": -86.106432,
      "city": "Miami",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46960,
      "latitude": 41.124552,
      "longitude": -86.544617,
      "city": "Monterey",
      "state": "IN",
      "county": "Pulaski"
  },
  {
      "zipCode": 46961,
      "latitude": 40.762054,
      "longitude": -86.193648,
      "city": "New Waverly",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46962,
      "latitude": 40.859877,
      "longitude": -85.794138,
      "city": "North Manchester",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46965,
      "latitude": 40.412741,
      "longitude": -86.101484,
      "city": "Oakford",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46967,
      "latitude": 40.694298,
      "longitude": -86.204238,
      "city": "Onward",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46968,
      "latitude": 41.175557,
      "longitude": -86.554326,
      "city": "Ora",
      "state": "IN",
      "county": "Starke"
  },
  {
      "zipCode": 46970,
      "latitude": 40.73991,
      "longitude": -86.07581,
      "city": "Peru",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46971,
      "latitude": 40.780955,
      "longitude": -86.016441,
      "city": "Grissom Arb",
      "state": "IN",
      "county": "Miami"
  },
  {
      "zipCode": 46974,
      "latitude": 40.951589,
      "longitude": -85.854151,
      "city": "Roann",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46975,
      "latitude": 41.040988,
      "longitude": -86.254272,
      "city": "Rochester",
      "state": "IN",
      "county": "Fulton"
  },
  {
      "zipCode": 46977,
      "latitude": 40.584272,
      "longitude": -86.573952,
      "city": "Rockfield",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 46978,
      "latitude": 40.851622,
      "longitude": -86.492019,
      "city": "Royal Center",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46979,
      "latitude": 40.42573,
      "longitude": -86.269717,
      "city": "Russiaville",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46980,
      "latitude": 40.848927,
      "longitude": -85.792501,
      "city": "Servia",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46982,
      "latitude": 41.130963,
      "longitude": -85.856589,
      "city": "Silver Lake",
      "state": "IN",
      "county": "Kosciusko"
  },
  {
      "zipCode": 46984,
      "latitude": 40.671369,
      "longitude": -85.828846,
      "city": "Somerset",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46985,
      "latitude": 41.003004,
      "longitude": -86.589005,
      "city": "Star City",
      "state": "IN",
      "county": "Pulaski"
  },
  {
      "zipCode": 46986,
      "latitude": 40.505141,
      "longitude": -85.815617,
      "city": "Swayzee",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46987,
      "latitude": 40.564673,
      "longitude": -85.765569,
      "city": "Sweetser",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46988,
      "latitude": 40.866096,
      "longitude": -86.233899,
      "city": "Twelve Mile",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46989,
      "latitude": 40.455985,
      "longitude": -85.62512,
      "city": "Upland",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46990,
      "latitude": 40.886571,
      "longitude": -85.736386,
      "city": "Urbana",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46991,
      "latitude": 40.617379,
      "longitude": -85.514796,
      "city": "Van Buren",
      "state": "IN",
      "county": "Grant"
  },
  {
      "zipCode": 46992,
      "latitude": 40.801867,
      "longitude": -85.791823,
      "city": "Wabash",
      "state": "IN",
      "county": "Wabash"
  },
  {
      "zipCode": 46994,
      "latitude": 40.677128,
      "longitude": -86.260927,
      "city": "Walton",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 46995,
      "latitude": 40.442117,
      "longitude": -86.215482,
      "city": "West Middleton",
      "state": "IN",
      "county": "Howard"
  },
  {
      "zipCode": 46996,
      "latitude": 41.035862,
      "longitude": -86.622156,
      "city": "Winamac",
      "state": "IN",
      "county": "Pulaski"
  },
  {
      "zipCode": 46998,
      "latitude": 40.866023,
      "longitude": -86.225982,
      "city": "Young America",
      "state": "IN",
      "county": "Cass"
  },
  {
      "zipCode": 47001,
      "latitude": 39.091081,
      "longitude": -84.988904,
      "city": "Aurora",
      "state": "IN",
      "county": "Dearborn"
  },
  {
      "zipCode": 47003,
      "latitude": 39.515851,
      "longitude": -84.862979,
      "city": "West College Corner",
      "state": "IN",
      "county": "Union"
  },
  {
      "zipCode": 47006,
      "latitude": 39.19017,
      "longitude": -85.229151,
      "city": "Batesville",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47010,
      "latitude": 39.490701,
      "longitude": -84.853529,
      "city": "Bath",
      "state": "IN",
      "county": "Franklin"
  },
  {
      "zipCode": 47011,
      "latitude": 38.864386,
      "longitude": -85.074867,
      "city": "Bennington",
      "state": "IN",
      "county": "Switzerland"
  },
  {
      "zipCode": 47012,
      "latitude": 39.416398,
      "longitude": -85.00028,
      "city": "Brookville",
      "state": "IN",
      "county": "Franklin"
  },
  {
      "zipCode": 47016,
      "latitude": 39.367379,
      "longitude": -84.910391,
      "city": "Cedar Grove",
      "state": "IN",
      "county": "Franklin"
  },
  {
      "zipCode": 47017,
      "latitude": 38.945037,
      "longitude": -85.191159,
      "city": "Cross Plains",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47018,
      "latitude": 39.002217,
      "longitude": -85.02623,
      "city": "Dillsboro",
      "state": "IN",
      "county": "Dearborn"
  },
  {
      "zipCode": 47019,
      "latitude": 38.868591,
      "longitude": -84.925998,
      "city": "East Enterprise",
      "state": "IN",
      "county": "Switzerland"
  },
  {
      "zipCode": 47020,
      "latitude": 38.82353,
      "longitude": -84.950119,
      "city": "Florence",
      "state": "IN",
      "county": "Switzerland"
  },
  {
      "zipCode": 47021,
      "latitude": 38.970266,
      "longitude": -85.15039,
      "city": "Friendship",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47022,
      "latitude": 39.193261,
      "longitude": -84.964354,
      "city": "Guilford",
      "state": "IN",
      "county": "Dearborn"
  },
  {
      "zipCode": 47023,
      "latitude": 39.082863,
      "longitude": -85.374353,
      "city": "Holton",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47024,
      "latitude": 39.467957,
      "longitude": -85.178057,
      "city": "Laurel",
      "state": "IN",
      "county": "Franklin"
  },
  {
      "zipCode": 47025,
      "latitude": 39.152741,
      "longitude": -84.891015,
      "city": "Lawrenceburg",
      "state": "IN",
      "county": "Dearborn"
  },
  {
      "zipCode": 47030,
      "latitude": 39.433095,
      "longitude": -85.111826,
      "city": "Metamora",
      "state": "IN",
      "county": "Franklin"
  },
  {
      "zipCode": 47031,
      "latitude": 39.115266,
      "longitude": -85.22819,
      "city": "Milan",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47032,
      "latitude": 39.16259,
      "longitude": -84.980417,
      "city": "Moores Hill",
      "state": "IN",
      "county": "Dearborn"
  },
  {
      "zipCode": 47033,
      "latitude": 39.281429,
      "longitude": -85.1739,
      "city": "Morris",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47034,
      "latitude": 39.204833,
      "longitude": -85.326962,
      "city": "Napoleon",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47035,
      "latitude": 39.309983,
      "longitude": -84.900711,
      "city": "New Trenton",
      "state": "IN",
      "county": "Franklin"
  },
  {
      "zipCode": 47036,
      "latitude": 39.386175,
      "longitude": -85.238529,
      "city": "Oldenburg",
      "state": "IN",
      "county": "Franklin"
  },
  {
      "zipCode": 47037,
      "latitude": 39.172808,
      "longitude": -85.285367,
      "city": "Osgood",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47038,
      "latitude": 38.84188,
      "longitude": -84.881782,
      "city": "Patriot",
      "state": "IN",
      "county": "Switzerland"
  },
  {
      "zipCode": 47039,
      "latitude": 39.111625,
      "longitude": -85.255114,
      "city": "Pierceville",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47040,
      "latitude": 38.958134,
      "longitude": -84.984085,
      "city": "Rising Sun",
      "state": "IN",
      "county": "Ohio"
  },
  {
      "zipCode": 47041,
      "latitude": 39.231396,
      "longitude": -85.140001,
      "city": "Sunman",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47042,
      "latitude": 39.039682,
      "longitude": -85.244161,
      "city": "Versailles",
      "state": "IN",
      "county": "Ripley"
  },
  {
      "zipCode": 47043,
      "latitude": 38.809388,
      "longitude": -85.061136,
      "city": "Vevay",
      "state": "IN",
      "county": "Switzerland"
  },
  {
      "zipCode": 47060,
      "latitude": 39.25151,
      "longitude": -84.91437,
      "city": "West Harrison",
      "state": "IN",
      "county": "Dearborn"
  },
  {
      "zipCode": 47102,
      "latitude": 38.748455,
      "longitude": -85.750469,
      "city": "Austin",
      "state": "IN",
      "county": "Scott"
  },
  {
      "zipCode": 47104,
      "latitude": 38.539899,
      "longitude": -85.421753,
      "city": "Bethlehem",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47106,
      "latitude": 38.482335,
      "longitude": -85.76776,
      "city": "Borden",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47107,
      "latitude": 38.190314,
      "longitude": -86.115257,
      "city": "Bradford",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47108,
      "latitude": 38.614255,
      "longitude": -86.220645,
      "city": "Campbellsburg",
      "state": "IN",
      "county": "Washington"
  },
  {
      "zipCode": 47110,
      "latitude": 38.096971,
      "longitude": -86.172327,
      "city": "Central",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47111,
      "latitude": 38.459992,
      "longitude": -85.615235,
      "city": "Charlestown",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47112,
      "latitude": 38.1863,
      "longitude": -86.145211,
      "city": "Corydon",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47114,
      "latitude": 38.289882,
      "longitude": -86.08239,
      "city": "Crandall",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47115,
      "latitude": 38.338462,
      "longitude": -86.212957,
      "city": "Depauw",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47116,
      "latitude": 38.329303,
      "longitude": -86.575635,
      "city": "Eckerty",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47117,
      "latitude": 38.094629,
      "longitude": -85.988349,
      "city": "Elizabeth",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47118,
      "latitude": 38.288549,
      "longitude": -86.501185,
      "city": "English",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47119,
      "latitude": 38.35257,
      "longitude": -85.890727,
      "city": "Floyds Knobs",
      "state": "IN",
      "county": "Floyd"
  },
  {
      "zipCode": 47120,
      "latitude": 38.46772,
      "longitude": -86.185333,
      "city": "Fredericksburg",
      "state": "IN",
      "county": "Washington"
  },
  {
      "zipCode": 47122,
      "latitude": 38.31098,
      "longitude": -85.960009,
      "city": "Georgetown",
      "state": "IN",
      "county": "Floyd"
  },
  {
      "zipCode": 47123,
      "latitude": 38.281966,
      "longitude": -86.484327,
      "city": "Grantsburg",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47124,
      "latitude": 38.373209,
      "longitude": -85.986626,
      "city": "Greenville",
      "state": "IN",
      "county": "Floyd"
  },
  {
      "zipCode": 47125,
      "latitude": 38.500408,
      "longitude": -86.244689,
      "city": "Hardinsburg",
      "state": "IN",
      "county": "Washington"
  },
  {
      "zipCode": 47126,
      "latitude": 38.5504,
      "longitude": -85.774327,
      "city": "Henryville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47129,
      "latitude": 38.310958,
      "longitude": -85.764463,
      "city": "Clarksville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47130,
      "latitude": 38.404909,
      "longitude": -85.716548,
      "city": "Jeffersonville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47131,
      "latitude": 38.437021,
      "longitude": -85.704973,
      "city": "Jeffersonville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47132,
      "latitude": 38.286819,
      "longitude": -85.732062,
      "city": "Jeffersonville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47133,
      "latitude": 38.286819,
      "longitude": -85.732062,
      "city": "Jeffersonville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47134,
      "latitude": 38.286819,
      "longitude": -85.732062,
      "city": "Jeffersonville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47135,
      "latitude": 38.039957,
      "longitude": -86.06555,
      "city": "Laconia",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47136,
      "latitude": 38.227684,
      "longitude": -85.990389,
      "city": "Lanesville",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47137,
      "latitude": 38.195771,
      "longitude": -86.368905,
      "city": "Leavenworth",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47138,
      "latitude": 38.671281,
      "longitude": -85.6333,
      "city": "Lexington",
      "state": "IN",
      "county": "Scott"
  },
  {
      "zipCode": 47139,
      "latitude": 38.600666,
      "longitude": -86.078828,
      "city": "Little York",
      "state": "IN",
      "county": "Washington"
  },
  {
      "zipCode": 47140,
      "latitude": 38.357884,
      "longitude": -86.372687,
      "city": "Marengo",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47141,
      "latitude": 38.549871,
      "longitude": -85.597466,
      "city": "Marysville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47142,
      "latitude": 38.073945,
      "longitude": -86.204065,
      "city": "Mauckport",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47143,
      "latitude": 38.467595,
      "longitude": -85.769631,
      "city": "Memphis",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47144,
      "latitude": 38.286819,
      "longitude": -85.732062,
      "city": "Jeffersonville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47145,
      "latitude": 38.337832,
      "longitude": -86.314971,
      "city": "Milltown",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47146,
      "latitude": 38.298486,
      "longitude": -85.896961,
      "city": "Mount Saint Francis",
      "state": "IN",
      "county": "Floyd"
  },
  {
      "zipCode": 47147,
      "latitude": 38.580326,
      "longitude": -85.532171,
      "city": "Nabb",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47150,
      "latitude": 38.293355,
      "longitude": -85.837911,
      "city": "New Albany",
      "state": "IN",
      "county": "Floyd"
  },
  {
      "zipCode": 47151,
      "latitude": 38.298486,
      "longitude": -85.896961,
      "city": "New Albany",
      "state": "IN",
      "county": "Floyd"
  },
  {
      "zipCode": 47160,
      "latitude": 38.148843,
      "longitude": -86.049282,
      "city": "New Middletown",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47161,
      "latitude": 38.328025,
      "longitude": -86.105996,
      "city": "New Salisbury",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47162,
      "latitude": 38.550289,
      "longitude": -85.486131,
      "city": "New Washington",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47163,
      "latitude": 38.535058,
      "longitude": -85.669829,
      "city": "Otisco",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47164,
      "latitude": 38.380353,
      "longitude": -86.105954,
      "city": "Palmyra",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47165,
      "latitude": 38.512878,
      "longitude": -86.010567,
      "city": "Pekin",
      "state": "IN",
      "county": "Washington"
  },
  {
      "zipCode": 47166,
      "latitude": 38.303364,
      "longitude": -86.168979,
      "city": "Ramsey",
      "state": "IN",
      "county": "Harrison"
  },
  {
      "zipCode": 47167,
      "latitude": 38.608621,
      "longitude": -86.079404,
      "city": "Salem",
      "state": "IN",
      "county": "Washington"
  },
  {
      "zipCode": 47170,
      "latitude": 38.695648,
      "longitude": -85.772815,
      "city": "Scottsburg",
      "state": "IN",
      "county": "Scott"
  },
  {
      "zipCode": 47172,
      "latitude": 38.404577,
      "longitude": -85.787963,
      "city": "Sellersburg",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47174,
      "latitude": 38.22588,
      "longitude": -86.490751,
      "city": "Sulphur",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47175,
      "latitude": 38.345487,
      "longitude": -86.54608,
      "city": "Taswell",
      "state": "IN",
      "county": "Crawford"
  },
  {
      "zipCode": 47177,
      "latitude": 38.584823,
      "longitude": -85.748631,
      "city": "Underwood",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47199,
      "latitude": 38.286819,
      "longitude": -85.732062,
      "city": "Jeffersonville",
      "state": "IN",
      "county": "Clark"
  },
  {
      "zipCode": 47201,
      "latitude": 39.191447,
      "longitude": -85.891338,
      "city": "Columbus",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47202,
      "latitude": 39.192972,
      "longitude": -85.885168,
      "city": "Columbus",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47203,
      "latitude": 39.241129,
      "longitude": -85.827439,
      "city": "Columbus",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47220,
      "latitude": 38.866192,
      "longitude": -86.046452,
      "city": "Brownstown",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47223,
      "latitude": 39.046146,
      "longitude": -85.486831,
      "city": "Butlerville",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47224,
      "latitude": 38.873448,
      "longitude": -85.291935,
      "city": "Canaan",
      "state": "IN",
      "county": "Jefferson"
  },
  {
      "zipCode": 47225,
      "latitude": 39.424072,
      "longitude": -85.347677,
      "city": "Clarksburg",
      "state": "IN",
      "county": "Decatur"
  },
  {
      "zipCode": 47226,
      "latitude": 39.282609,
      "longitude": -85.868533,
      "city": "Clifford",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47227,
      "latitude": 38.868992,
      "longitude": -85.660004,
      "city": "Commiskey",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47228,
      "latitude": 38.974458,
      "longitude": -85.962761,
      "city": "Cortland",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47229,
      "latitude": 38.815289,
      "longitude": -85.985182,
      "city": "Crothersville",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47230,
      "latitude": 38.794988,
      "longitude": -85.60731,
      "city": "Deputy",
      "state": "IN",
      "county": "Jefferson"
  },
  {
      "zipCode": 47231,
      "latitude": 38.876272,
      "longitude": -85.500519,
      "city": "Dupont",
      "state": "IN",
      "county": "Jefferson"
  },
  {
      "zipCode": 47232,
      "latitude": 39.118884,
      "longitude": -85.773533,
      "city": "Elizabethtown",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47234,
      "latitude": 39.393802,
      "longitude": -85.77201,
      "city": "Flat Rock",
      "state": "IN",
      "county": "Shelby"
  },
  {
      "zipCode": 47235,
      "latitude": 38.962837,
      "longitude": -86.155545,
      "city": "Freetown",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47236,
      "latitude": 39.152228,
      "longitude": -85.726135,
      "city": "Grammer",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47240,
      "latitude": 39.299802,
      "longitude": -85.491781,
      "city": "Greensburg",
      "state": "IN",
      "county": "Decatur"
  },
  {
      "zipCode": 47243,
      "latitude": 38.734755,
      "longitude": -85.376804,
      "city": "Hanover",
      "state": "IN",
      "county": "Jefferson"
  },
  {
      "zipCode": 47244,
      "latitude": 39.218578,
      "longitude": -85.717648,
      "city": "Hartsville",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47245,
      "latitude": 39.001301,
      "longitude": -85.619906,
      "city": "Hayden",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47246,
      "latitude": 39.20336,
      "longitude": -85.787611,
      "city": "Hope",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47247,
      "latitude": 39.059349,
      "longitude": -85.888579,
      "city": "Jonesville",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47249,
      "latitude": 38.897974,
      "longitude": -86.056681,
      "city": "Kurtz",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47250,
      "latitude": 38.804552,
      "longitude": -85.420605,
      "city": "Madison",
      "state": "IN",
      "county": "Jefferson"
  },
  {
      "zipCode": 47260,
      "latitude": 38.84003,
      "longitude": -86.194693,
      "city": "Medora",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47261,
      "latitude": 39.292004,
      "longitude": -85.491788,
      "city": "Millhousen",
      "state": "IN",
      "county": "Decatur"
  },
  {
      "zipCode": 47262,
      "latitude": 39.001301,
      "longitude": -85.619906,
      "city": "Nebraska",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47263,
      "latitude": 39.308252,
      "longitude": -85.329695,
      "city": "New Point",
      "state": "IN",
      "county": "Decatur"
  },
  {
      "zipCode": 47264,
      "latitude": 38.947371,
      "longitude": -86.23671,
      "city": "Norman",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47265,
      "latitude": 39.023539,
      "longitude": -85.622328,
      "city": "North Vernon",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47270,
      "latitude": 38.86752,
      "longitude": -85.716186,
      "city": "Paris Crossing",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47272,
      "latitude": 39.318,
      "longitude": -85.463684,
      "city": "Saint Paul",
      "state": "IN",
      "county": "Decatur"
  },
  {
      "zipCode": 47273,
      "latitude": 39.064795,
      "longitude": -85.733853,
      "city": "Scipio",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47274,
      "latitude": 38.944827,
      "longitude": -86.010789,
      "city": "Seymour",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47280,
      "latitude": 39.295266,
      "longitude": -85.949844,
      "city": "Taylorsville",
      "state": "IN",
      "county": "Bartholomew"
  },
  {
      "zipCode": 47281,
      "latitude": 38.814419,
      "longitude": -86.092983,
      "city": "Vallonia",
      "state": "IN",
      "county": "Jackson"
  },
  {
      "zipCode": 47282,
      "latitude": 38.982509,
      "longitude": -85.616595,
      "city": "Vernon",
      "state": "IN",
      "county": "Jennings"
  },
  {
      "zipCode": 47283,
      "latitude": 39.241181,
      "longitude": -85.564278,
      "city": "Westport",
      "state": "IN",
      "county": "Decatur"
  },
  {
      "zipCode": 47302,
      "latitude": 40.142124,
      "longitude": -85.389874,
      "city": "Muncie",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47303,
      "latitude": 40.280332,
      "longitude": -85.383447,
      "city": "Muncie",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47304,
      "latitude": 40.238544,
      "longitude": -85.484122,
      "city": "Muncie",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47305,
      "latitude": 40.247979,
      "longitude": -85.433223,
      "city": "Muncie",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47306,
      "latitude": 40.202293,
      "longitude": -85.408221,
      "city": "Muncie",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47307,
      "latitude": 40.162093,
      "longitude": -85.442772,
      "city": "Muncie",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47308,
      "latitude": 40.227938,
      "longitude": -85.396685,
      "city": "Muncie",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47320,
      "latitude": 40.277967,
      "longitude": -85.274125,
      "city": "Albany",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47322,
      "latitude": 39.656994,
      "longitude": -85.167996,
      "city": "Bentonville",
      "state": "IN",
      "county": "Fayette"
  },
  {
      "zipCode": 47324,
      "latitude": 39.757864,
      "longitude": -84.848442,
      "city": "Boston",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47325,
      "latitude": 39.682946,
      "longitude": -84.995266,
      "city": "Brownsville",
      "state": "IN",
      "county": "Union"
  },
  {
      "zipCode": 47326,
      "latitude": 40.54116,
      "longitude": -84.982803,
      "city": "Bryant",
      "state": "IN",
      "county": "Jay"
  },
  {
      "zipCode": 47327,
      "latitude": 39.848569,
      "longitude": -85.035364,
      "city": "Cambridge City",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47330,
      "latitude": 39.787438,
      "longitude": -84.986277,
      "city": "Centerville",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47331,
      "latitude": 39.656933,
      "longitude": -85.168099,
      "city": "Connersville",
      "state": "IN",
      "county": "Fayette"
  },
  {
      "zipCode": 47334,
      "latitude": 40.143843,
      "longitude": -85.508457,
      "city": "Daleville",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47335,
      "latitude": 39.812688,
      "longitude": -85.204359,
      "city": "Dublin",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47336,
      "latitude": 40.43525,
      "longitude": -85.091124,
      "city": "Dunkirk",
      "state": "IN",
      "county": "Jay"
  },
  {
      "zipCode": 47337,
      "latitude": 39.803063,
      "longitude": -85.43711,
      "city": "Dunreith",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47338,
      "latitude": 40.252982,
      "longitude": -85.338738,
      "city": "Eaton",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47339,
      "latitude": 39.961044,
      "longitude": -85.102314,
      "city": "Economy",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47340,
      "latitude": 40.187565,
      "longitude": -85.14558,
      "city": "Farmland",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47341,
      "latitude": 39.864218,
      "longitude": -84.995365,
      "city": "Fountain City",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47342,
      "latitude": 40.309361,
      "longitude": -85.432657,
      "city": "Gaston",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47344,
      "latitude": 39.878933,
      "longitude": -85.464036,
      "city": "Greensboro",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47345,
      "latitude": 39.893845,
      "longitude": -85.013775,
      "city": "Greens Fork",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47346,
      "latitude": 39.895789,
      "longitude": -85.05967,
      "city": "Hagerstown",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47348,
      "latitude": 40.472672,
      "longitude": -85.326447,
      "city": "Hartford City",
      "state": "IN",
      "county": "Blackford"
  },
  {
      "zipCode": 47351,
      "latitude": 39.904018,
      "longitude": -85.520548,
      "city": "Kennard",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47352,
      "latitude": 39.830372,
      "longitude": -85.372732,
      "city": "Lewisville",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47353,
      "latitude": 39.625149,
      "longitude": -84.925002,
      "city": "Liberty",
      "state": "IN",
      "county": "Union"
  },
  {
      "zipCode": 47354,
      "latitude": 40.048148,
      "longitude": -85.17051,
      "city": "Losantville",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47355,
      "latitude": 40.055775,
      "longitude": -84.941102,
      "city": "Lynn",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47356,
      "latitude": 39.941555,
      "longitude": -85.462793,
      "city": "Middletown",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47357,
      "latitude": 39.764911,
      "longitude": -85.109015,
      "city": "Milton",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47358,
      "latitude": 40.055419,
      "longitude": -85.108514,
      "city": "Modoc",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47359,
      "latitude": 40.515895,
      "longitude": -85.295619,
      "city": "Montpelier",
      "state": "IN",
      "county": "Blackford"
  },
  {
      "zipCode": 47360,
      "latitude": 40.01641,
      "longitude": -85.293037,
      "city": "Mooreland",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47361,
      "latitude": 40.001295,
      "longitude": -85.319923,
      "city": "Mount Summit",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47362,
      "latitude": 39.92487,
      "longitude": -85.366926,
      "city": "New Castle",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47366,
      "latitude": 39.931785,
      "longitude": -85.398625,
      "city": "New Lisbon",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47367,
      "latitude": 40.079197,
      "longitude": -85.389978,
      "city": "Oakville",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47368,
      "latitude": 40.174184,
      "longitude": -85.173858,
      "city": "Parker City",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47369,
      "latitude": 40.457413,
      "longitude": -85.133813,
      "city": "Pennville",
      "state": "IN",
      "county": "Jay"
  },
  {
      "zipCode": 47370,
      "latitude": 39.861314,
      "longitude": -85.147437,
      "city": "Pershing",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47371,
      "latitude": 40.431634,
      "longitude": -84.983015,
      "city": "Portland",
      "state": "IN",
      "county": "Jay"
  },
  {
      "zipCode": 47373,
      "latitude": 40.359568,
      "longitude": -85.151994,
      "city": "Redkey",
      "state": "IN",
      "county": "Jay"
  },
  {
      "zipCode": 47374,
      "latitude": 39.84081,
      "longitude": -85.010442,
      "city": "Richmond",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47375,
      "latitude": 39.860417,
      "longitude": -85.015983,
      "city": "Richmond",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47380,
      "latitude": 40.167082,
      "longitude": -85.048286,
      "city": "Ridgeville",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47381,
      "latitude": 40.378574,
      "longitude": -84.882263,
      "city": "Salamonia",
      "state": "IN",
      "county": "Jay"
  },
  {
      "zipCode": 47382,
      "latitude": 40.234703,
      "longitude": -84.91848,
      "city": "Saratoga",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47383,
      "latitude": 40.15178,
      "longitude": -85.265562,
      "city": "Selma",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47384,
      "latitude": 39.946332,
      "longitude": -85.485528,
      "city": "Shirley",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47385,
      "latitude": 39.91725,
      "longitude": -85.450834,
      "city": "Spiceland",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47386,
      "latitude": 40.011128,
      "longitude": -85.383768,
      "city": "Springport",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47387,
      "latitude": 39.83059,
      "longitude": -85.284275,
      "city": "Straughn",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47388,
      "latitude": 40.005999,
      "longitude": -85.44341,
      "city": "Sulphur Springs",
      "state": "IN",
      "county": "Henry"
  },
  {
      "zipCode": 47390,
      "latitude": 40.166457,
      "longitude": -85.006297,
      "city": "Union City",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47392,
      "latitude": 39.911895,
      "longitude": -84.935057,
      "city": "Webster",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47393,
      "latitude": 39.930541,
      "longitude": -85.028032,
      "city": "Williamsburg",
      "state": "IN",
      "county": "Wayne"
  },
  {
      "zipCode": 47394,
      "latitude": 40.159854,
      "longitude": -84.984682,
      "city": "Winchester",
      "state": "IN",
      "county": "Randolph"
  },
  {
      "zipCode": 47396,
      "latitude": 40.19855,
      "longitude": -85.509272,
      "city": "Yorktown",
      "state": "IN",
      "county": "Delaware"
  },
  {
      "zipCode": 47401,
      "latitude": 39.07881,
      "longitude": -86.435094,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47402,
      "latitude": 39.173234,
      "longitude": -86.501543,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47403,
      "latitude": 39.07884,
      "longitude": -86.592389,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47404,
      "latitude": 39.25007,
      "longitude": -86.580849,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47405,
      "latitude": 39.173447,
      "longitude": -86.515145,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47406,
      "latitude": 39.174541,
      "longitude": -86.518296,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47407,
      "latitude": 39.173234,
      "longitude": -86.501543,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47408,
      "latitude": 39.230326,
      "longitude": -86.469187,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47420,
      "latitude": 38.839482,
      "longitude": -86.47943,
      "city": "Avoca",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47421,
      "latitude": 38.890524,
      "longitude": -86.525843,
      "city": "Bedford",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47424,
      "latitude": 39.031541,
      "longitude": -86.838359,
      "city": "Bloomfield",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47426,
      "latitude": 39.173234,
      "longitude": -86.501543,
      "city": "Clear Creek",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47427,
      "latitude": 39.319527,
      "longitude": -86.842743,
      "city": "Coal City",
      "state": "IN",
      "county": "Owen"
  },
  {
      "zipCode": 47429,
      "latitude": 39.276356,
      "longitude": -86.62658,
      "city": "Ellettsville",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47430,
      "latitude": 38.839482,
      "longitude": -86.47943,
      "city": "Fort Ritner",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47431,
      "latitude": 39.319527,
      "longitude": -86.842743,
      "city": "Freedom",
      "state": "IN",
      "county": "Owen"
  },
  {
      "zipCode": 47432,
      "latitude": 38.486914,
      "longitude": -86.587861,
      "city": "French Lick",
      "state": "IN",
      "county": "Orange"
  },
  {
      "zipCode": 47433,
      "latitude": 39.346964,
      "longitude": -86.701332,
      "city": "Gosport",
      "state": "IN",
      "county": "Owen"
  },
  {
      "zipCode": 47434,
      "latitude": 39.012984,
      "longitude": -86.545732,
      "city": "Harrodsburg",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47435,
      "latitude": 39.196272,
      "longitude": -86.230212,
      "city": "Helmsburg",
      "state": "IN",
      "county": "Brown"
  },
  {
      "zipCode": 47436,
      "latitude": 38.839482,
      "longitude": -86.47943,
      "city": "Heltonville",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47437,
      "latitude": 38.722153,
      "longitude": -86.670987,
      "city": "Huron",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47438,
      "latitude": 39.078835,
      "longitude": -86.992896,
      "city": "Jasonville",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47439,
      "latitude": 39.037144,
      "longitude": -86.961577,
      "city": "Koleen",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47441,
      "latitude": 39.04963,
      "longitude": -87.126965,
      "city": "Linton",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47443,
      "latitude": 38.988255,
      "longitude": -87.102958,
      "city": "Lyons",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47445,
      "latitude": 39.037144,
      "longitude": -86.961577,
      "city": "Midland",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47446,
      "latitude": 38.803928,
      "longitude": -86.50326,
      "city": "Mitchell",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47448,
      "latitude": 39.180583,
      "longitude": -86.226228,
      "city": "Nashville",
      "state": "IN",
      "county": "Brown"
  },
  {
      "zipCode": 47449,
      "latitude": 39.037144,
      "longitude": -86.961577,
      "city": "Newberry",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47451,
      "latitude": 38.895608,
      "longitude": -86.525522,
      "city": "Oolitic",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47452,
      "latitude": 38.586595,
      "longitude": -86.451946,
      "city": "Orleans",
      "state": "IN",
      "county": "Orange"
  },
  {
      "zipCode": 47453,
      "latitude": 39.037144,
      "longitude": -86.961577,
      "city": "Owensburg",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47454,
      "latitude": 38.513132,
      "longitude": -86.459473,
      "city": "Paoli",
      "state": "IN",
      "county": "Orange"
  },
  {
      "zipCode": 47455,
      "latitude": 39.319527,
      "longitude": -86.842743,
      "city": "Patricksburg",
      "state": "IN",
      "county": "Owen"
  },
  {
      "zipCode": 47456,
      "latitude": 39.442888,
      "longitude": -86.669559,
      "city": "Quincy",
      "state": "IN",
      "county": "Owen"
  },
  {
      "zipCode": 47457,
      "latitude": 39.037144,
      "longitude": -86.961577,
      "city": "Scotland",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47458,
      "latitude": 39.173234,
      "longitude": -86.501543,
      "city": "Smithville",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47459,
      "latitude": 39.104137,
      "longitude": -86.73706,
      "city": "Solsberry",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47460,
      "latitude": 39.313258,
      "longitude": -86.756423,
      "city": "Spencer",
      "state": "IN",
      "county": "Owen"
  },
  {
      "zipCode": 47462,
      "latitude": 38.941408,
      "longitude": -86.584408,
      "city": "Springville",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47463,
      "latitude": 39.085896,
      "longitude": -86.6689,
      "city": "Stanford",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47464,
      "latitude": 39.299823,
      "longitude": -86.648201,
      "city": "Stinesville",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47465,
      "latitude": 39.037144,
      "longitude": -86.961577,
      "city": "Switz City",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47467,
      "latitude": 38.839482,
      "longitude": -86.47943,
      "city": "Tunnelton",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47468,
      "latitude": 39.274735,
      "longitude": -86.42106,
      "city": "Unionville",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47469,
      "latitude": 38.620834,
      "longitude": -86.589851,
      "city": "West Baden Springs",
      "state": "IN",
      "county": "Orange"
  },
  {
      "zipCode": 47470,
      "latitude": 38.839482,
      "longitude": -86.47943,
      "city": "Williams",
      "state": "IN",
      "county": "Lawrence"
  },
  {
      "zipCode": 47471,
      "latitude": 39.124289,
      "longitude": -86.970755,
      "city": "Worthington",
      "state": "IN",
      "county": "Greene"
  },
  {
      "zipCode": 47490,
      "latitude": 39.173234,
      "longitude": -86.501543,
      "city": "Bloomington",
      "state": "IN",
      "county": "Monroe"
  },
  {
      "zipCode": 47501,
      "latitude": 38.689503,
      "longitude": -87.121505,
      "city": "Washington",
      "state": "IN",
      "county": "Daviess"
  },
  {
      "zipCode": 47512,
      "latitude": 38.741286,
      "longitude": -87.346192,
      "city": "Bicknell",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47513,
      "latitude": 38.326772,
      "longitude": -86.820726,
      "city": "Birdseye",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47514,
      "latitude": 38.053876,
      "longitude": -86.621994,
      "city": "Branchville",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47515,
      "latitude": 38.198133,
      "longitude": -86.682068,
      "city": "Bristow",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47516,
      "latitude": 38.761354,
      "longitude": -87.417076,
      "city": "Bruceville",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47519,
      "latitude": 38.697289,
      "longitude": -87.091762,
      "city": "Cannelburg",
      "state": "IN",
      "county": "Daviess"
  },
  {
      "zipCode": 47520,
      "latitude": 37.949544,
      "longitude": -86.715557,
      "city": "Cannelton",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47521,
      "latitude": 38.406745,
      "longitude": -86.742618,
      "city": "Celestine",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47522,
      "latitude": 38.894879,
      "longitude": -86.900174,
      "city": "Crane",
      "state": "IN",
      "county": "Martin"
  },
  {
      "zipCode": 47523,
      "latitude": 38.149611,
      "longitude": -86.946217,
      "city": "Dale",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47524,
      "latitude": 38.626138,
      "longitude": -87.511863,
      "city": "Decker",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47525,
      "latitude": 38.020017,
      "longitude": -86.565129,
      "city": "Derby",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47527,
      "latitude": 38.439461,
      "longitude": -86.777335,
      "city": "Dubois",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47528,
      "latitude": 38.786795,
      "longitude": -87.38271,
      "city": "Edwardsport",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47529,
      "latitude": 38.877167,
      "longitude": -87.085001,
      "city": "Elnora",
      "state": "IN",
      "county": "Daviess"
  },
  {
      "zipCode": 47531,
      "latitude": 38.045062,
      "longitude": -86.864745,
      "city": "Evanston",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47532,
      "latitude": 38.251171,
      "longitude": -86.867492,
      "city": "Ferdinand",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47535,
      "latitude": 38.862735,
      "longitude": -87.312678,
      "city": "Freelandville",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47536,
      "latitude": 37.993813,
      "longitude": -87.016971,
      "city": "Fulda",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47537,
      "latitude": 38.080052,
      "longitude": -87.049834,
      "city": "Gentryville",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47541,
      "latitude": 38.249617,
      "longitude": -87.007734,
      "city": "Holland",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47542,
      "latitude": 38.298972,
      "longitude": -86.952413,
      "city": "Huntingburg",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47545,
      "latitude": 38.413687,
      "longitude": -87.000879,
      "city": "Ireland",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47546,
      "latitude": 38.360357,
      "longitude": -86.929481,
      "city": "Jasper",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47547,
      "latitude": 38.364749,
      "longitude": -86.876173,
      "city": "Jasper",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47549,
      "latitude": 38.364749,
      "longitude": -86.876173,
      "city": "Jasper",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47550,
      "latitude": 38.042888,
      "longitude": -86.94971,
      "city": "Lamar",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47551,
      "latitude": 38.127658,
      "longitude": -86.570969,
      "city": "Leopold",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47552,
      "latitude": 38.124192,
      "longitude": -86.995988,
      "city": "Lincoln City",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47553,
      "latitude": 38.771691,
      "longitude": -86.912985,
      "city": "Loogootee",
      "state": "IN",
      "county": "Martin"
  },
  {
      "zipCode": 47556,
      "latitude": 38.16644,
      "longitude": -86.917205,
      "city": "Mariah Hill",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47557,
      "latitude": 38.590433,
      "longitude": -87.368456,
      "city": "Monroe City",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47558,
      "latitude": 38.697289,
      "longitude": -87.091762,
      "city": "Montgomery",
      "state": "IN",
      "county": "Daviess"
  },
  {
      "zipCode": 47561,
      "latitude": 38.709818,
      "longitude": -87.388134,
      "city": "Oaktown",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47562,
      "latitude": 38.841866,
      "longitude": -86.986145,
      "city": "Odon",
      "state": "IN",
      "county": "Daviess"
  },
  {
      "zipCode": 47564,
      "latitude": 38.477976,
      "longitude": -87.128545,
      "city": "Otwell",
      "state": "IN",
      "county": "Pike"
  },
  {
      "zipCode": 47567,
      "latitude": 38.459787,
      "longitude": -87.298174,
      "city": "Petersburg",
      "state": "IN",
      "county": "Pike"
  },
  {
      "zipCode": 47568,
      "latitude": 38.697289,
      "longitude": -87.091762,
      "city": "Plainville",
      "state": "IN",
      "county": "Daviess"
  },
  {
      "zipCode": 47573,
      "latitude": 38.744136,
      "longitude": -87.321357,
      "city": "Ragsdale",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47574,
      "latitude": 37.937193,
      "longitude": -86.534002,
      "city": "Rome",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47575,
      "latitude": 38.321125,
      "longitude": -86.823444,
      "city": "Saint Anthony",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47576,
      "latitude": 38.152479,
      "longitude": -86.64902,
      "city": "Saint Croix",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47577,
      "latitude": 38.141725,
      "longitude": -86.821891,
      "city": "Saint Meinrad",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47578,
      "latitude": 38.881471,
      "longitude": -87.19594,
      "city": "Sandborn",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47579,
      "latitude": 38.117579,
      "longitude": -86.928645,
      "city": "Santa Claus",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47580,
      "latitude": 38.352759,
      "longitude": -86.912488,
      "city": "Schnellville",
      "state": "IN",
      "county": "Dubois"
  },
  {
      "zipCode": 47581,
      "latitude": 38.669976,
      "longitude": -86.788412,
      "city": "Shoals",
      "state": "IN",
      "county": "Martin"
  },
  {
      "zipCode": 47584,
      "latitude": 38.26376,
      "longitude": -87.230482,
      "city": "Spurgeon",
      "state": "IN",
      "county": "Pike"
  },
  {
      "zipCode": 47585,
      "latitude": 38.271004,
      "longitude": -87.147783,
      "city": "Stendal",
      "state": "IN",
      "county": "Pike"
  },
  {
      "zipCode": 47586,
      "latitude": 37.999711,
      "longitude": -86.731971,
      "city": "Tell City",
      "state": "IN",
      "county": "Perry"
  },
  {
      "zipCode": 47588,
      "latitude": 38.033118,
      "longitude": -86.798123,
      "city": "Troy",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47590,
      "latitude": 38.367402,
      "longitude": -87.109044,
      "city": "Velpen",
      "state": "IN",
      "county": "Pike"
  },
  {
      "zipCode": 47591,
      "latitude": 38.707231,
      "longitude": -87.417306,
      "city": "Vincennes",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47596,
      "latitude": 38.791556,
      "longitude": -87.3318,
      "city": "Westphalia",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47597,
      "latitude": 38.656969,
      "longitude": -87.329395,
      "city": "Wheatland",
      "state": "IN",
      "county": "Knox"
  },
  {
      "zipCode": 47598,
      "latitude": 38.372926,
      "longitude": -87.211093,
      "city": "Winslow",
      "state": "IN",
      "county": "Pike"
  },
  {
      "zipCode": 47601,
      "latitude": 38.04247,
      "longitude": -87.287145,
      "city": "Boonville",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47610,
      "latitude": 38.054569,
      "longitude": -87.267847,
      "city": "Chandler",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47611,
      "latitude": 38.031396,
      "longitude": -86.970053,
      "city": "Chrisney",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47612,
      "latitude": 38.175698,
      "longitude": -87.711423,
      "city": "Cynthiana",
      "state": "IN",
      "county": "Posey"
  },
  {
      "zipCode": 47613,
      "latitude": 38.128931,
      "longitude": -87.416104,
      "city": "Elberfeld",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47614,
      "latitude": 38.130034,
      "longitude": -87.163636,
      "city": "Folsomville",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47615,
      "latitude": 38.003542,
      "longitude": -86.996351,
      "city": "Grandview",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47616,
      "latitude": 38.190994,
      "longitude": -87.91701,
      "city": "Griffin",
      "state": "IN",
      "county": "Posey"
  },
  {
      "zipCode": 47617,
      "latitude": 37.903557,
      "longitude": -87.249886,
      "city": "Hatfield",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47618,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Inglefield",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47619,
      "latitude": 38.162899,
      "longitude": -87.283955,
      "city": "Lynnville",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47620,
      "latitude": 37.961781,
      "longitude": -87.868228,
      "city": "Mount Vernon",
      "state": "IN",
      "county": "Posey"
  },
  {
      "zipCode": 47629,
      "latitude": 38.06242,
      "longitude": -87.245214,
      "city": "Newburgh",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47630,
      "latitude": 38.038331,
      "longitude": -87.283581,
      "city": "Newburgh",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47631,
      "latitude": 38.101827,
      "longitude": -87.898765,
      "city": "New Harmony",
      "state": "IN",
      "county": "Posey"
  },
  {
      "zipCode": 47633,
      "latitude": 38.148016,
      "longitude": -87.786303,
      "city": "Poseyville",
      "state": "IN",
      "county": "Posey"
  },
  {
      "zipCode": 47634,
      "latitude": 37.975392,
      "longitude": -87.136282,
      "city": "Richland",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47635,
      "latitude": 37.929173,
      "longitude": -87.033681,
      "city": "Rockport",
      "state": "IN",
      "county": "Spencer"
  },
  {
      "zipCode": 47637,
      "latitude": 38.118439,
      "longitude": -87.244937,
      "city": "Tennyson",
      "state": "IN",
      "county": "Warrick"
  },
  {
      "zipCode": 47638,
      "latitude": 38.069277,
      "longitude": -87.783602,
      "city": "Wadesville",
      "state": "IN",
      "county": "Posey"
  },
  {
      "zipCode": 47639,
      "latitude": 38.202701,
      "longitude": -87.577358,
      "city": "Haubstadt",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47640,
      "latitude": 38.384971,
      "longitude": -87.473674,
      "city": "Hazleton",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47647,
      "latitude": 38.34949,
      "longitude": -87.651777,
      "city": "Buckskin",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47648,
      "latitude": 38.30512,
      "longitude": -87.518339,
      "city": "Fort Branch",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47649,
      "latitude": 38.331581,
      "longitude": -87.447826,
      "city": "Francisco",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47654,
      "latitude": 38.34949,
      "longitude": -87.651777,
      "city": "Mackey",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47660,
      "latitude": 38.306265,
      "longitude": -87.514796,
      "city": "Oakland City",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47665,
      "latitude": 38.272833,
      "longitude": -87.681174,
      "city": "Owensville",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47666,
      "latitude": 38.401233,
      "longitude": -87.583761,
      "city": "Patoka",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47670,
      "latitude": 38.303369,
      "longitude": -87.534003,
      "city": "Princeton",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47683,
      "latitude": 38.281095,
      "longitude": -87.37734,
      "city": "Somerville",
      "state": "IN",
      "county": "Gibson"
  },
  {
      "zipCode": 47701,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47702,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47703,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47704,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47705,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47706,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47708,
      "latitude": 37.976692,
      "longitude": -87.564842,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47710,
      "latitude": 38.029714,
      "longitude": -87.569024,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47711,
      "latitude": 38.061732,
      "longitude": -87.554802,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47712,
      "latitude": 37.984112,
      "longitude": -87.580057,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47713,
      "latitude": 37.95219,
      "longitude": -87.547564,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47714,
      "latitude": 37.959092,
      "longitude": -87.519682,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47715,
      "latitude": 37.984202,
      "longitude": -87.489212,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47716,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47719,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47720,
      "latitude": 38.059937,
      "longitude": -87.637962,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47721,
      "latitude": 37.978035,
      "longitude": -87.600791,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47722,
      "latitude": 37.970192,
      "longitude": -87.542014,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47724,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47725,
      "latitude": 38.045237,
      "longitude": -87.547704,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47727,
      "latitude": 38.031942,
      "longitude": -87.538915,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47728,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47730,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47731,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47732,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47733,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47734,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47735,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47736,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47737,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47739,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47740,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47741,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47744,
      "latitude": 37.977492,
      "longitude": -87.597316,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47747,
      "latitude": 37.997128,
      "longitude": -87.574963,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47750,
      "latitude": 37.962292,
      "longitude": -87.505512,
      "city": "Evansville",
      "state": "IN",
      "county": "Vanderburgh"
  },
  {
      "zipCode": 47801,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47802,
      "latitude": 39.357875,
      "longitude": -87.42014,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47803,
      "latitude": 39.468961,
      "longitude": -87.315069,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47804,
      "latitude": 39.505836,
      "longitude": -87.342968,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47805,
      "latitude": 39.532737,
      "longitude": -87.325458,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47807,
      "latitude": 39.467536,
      "longitude": -87.392019,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47808,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47809,
      "latitude": 39.470986,
      "longitude": -87.41107,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47811,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47812,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47813,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47814,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47830,
      "latitude": 39.779078,
      "longitude": -87.221819,
      "city": "Bellmore",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47831,
      "latitude": 39.877847,
      "longitude": -87.443566,
      "city": "Blanford",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47832,
      "latitude": 39.779078,
      "longitude": -87.221819,
      "city": "Bloomingdale",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47833,
      "latitude": 39.415414,
      "longitude": -87.065524,
      "city": "Bowling Green",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47834,
      "latitude": 39.387611,
      "longitude": -87.126509,
      "city": "Brazil",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47836,
      "latitude": 39.779078,
      "longitude": -87.221819,
      "city": "Bridgeton",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47837,
      "latitude": 39.591282,
      "longitude": -87.113196,
      "city": "Carbon",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47838,
      "latitude": 38.966806,
      "longitude": -87.384354,
      "city": "Carlisle",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47840,
      "latitude": 39.408241,
      "longitude": -87.053309,
      "city": "Centerpoint",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47841,
      "latitude": 39.301664,
      "longitude": -87.136902,
      "city": "Clay City",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47842,
      "latitude": 39.746685,
      "longitude": -87.458654,
      "city": "Clinton",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47845,
      "latitude": 39.387544,
      "longitude": -87.090363,
      "city": "Coalmont",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47846,
      "latitude": 39.368643,
      "longitude": -87.175317,
      "city": "Cory",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47847,
      "latitude": 39.838952,
      "longitude": -87.471163,
      "city": "Dana",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47848,
      "latitude": 39.039488,
      "longitude": -87.30662,
      "city": "Dugger",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47849,
      "latitude": 39.190101,
      "longitude": -87.547036,
      "city": "Fairbanks",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47850,
      "latitude": 39.222063,
      "longitude": -87.425279,
      "city": "Farmersburg",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47851,
      "latitude": 39.573663,
      "longitude": -87.24536,
      "city": "Fontanet",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47852,
      "latitude": 39.080474,
      "longitude": -87.449558,
      "city": "Graysville",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47853,
      "latitude": 39.537199,
      "longitude": -87.072888,
      "city": "Harmony",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47854,
      "latitude": 39.808476,
      "longitude": -87.424521,
      "city": "Hillsdale",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47855,
      "latitude": 39.183335,
      "longitude": -87.299448,
      "city": "Hymera",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47856,
      "latitude": 39.779078,
      "longitude": -87.221819,
      "city": "Judson",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47857,
      "latitude": 39.526913,
      "longitude": -87.086882,
      "city": "Knightsville",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47858,
      "latitude": 39.302796,
      "longitude": -87.277737,
      "city": "Lewis",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47859,
      "latitude": 39.779078,
      "longitude": -87.221819,
      "city": "Marshall",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47860,
      "latitude": 39.779078,
      "longitude": -87.221819,
      "city": "Mecca",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47861,
      "latitude": 39.057641,
      "longitude": -87.566595,
      "city": "Merom",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47862,
      "latitude": 39.791725,
      "longitude": -87.369135,
      "city": "Montezuma",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47863,
      "latitude": 39.580285,
      "longitude": -87.46157,
      "city": "New Goshen",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47864,
      "latitude": 39.041245,
      "longitude": -87.475747,
      "city": "New Lebanon",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47865,
      "latitude": 39.01849,
      "longitude": -87.389047,
      "city": "Paxton",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47866,
      "latitude": 39.292886,
      "longitude": -87.33555,
      "city": "Pimento",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47868,
      "latitude": 39.444565,
      "longitude": -86.960667,
      "city": "Poland",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47869,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Prairie Creek",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47870,
      "latitude": 39.371187,
      "longitude": -87.475871,
      "city": "Prairieton",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47871,
      "latitude": 39.388638,
      "longitude": -87.301367,
      "city": "Riley",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47872,
      "latitude": 39.712361,
      "longitude": -87.222646,
      "city": "Rockville",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47874,
      "latitude": 39.622405,
      "longitude": -87.320767,
      "city": "Rosedale",
      "state": "IN",
      "county": "Parke"
  },
  {
      "zipCode": 47875,
      "latitude": 39.877847,
      "longitude": -87.443566,
      "city": "Saint Bernice",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47876,
      "latitude": 39.433602,
      "longitude": -87.410094,
      "city": "Saint Mary Of The Woods",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47878,
      "latitude": 39.492838,
      "longitude": -87.266549,
      "city": "Seelyville",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47879,
      "latitude": 39.10494,
      "longitude": -87.382988,
      "city": "Shelburn",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47880,
      "latitude": 39.600636,
      "longitude": -87.419569,
      "city": "Shepardsville",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47881,
      "latitude": 39.491743,
      "longitude": -87.186673,
      "city": "Staunton",
      "state": "IN",
      "county": "Clay"
  },
  {
      "zipCode": 47882,
      "latitude": 39.084199,
      "longitude": -87.419003,
      "city": "Sullivan",
      "state": "IN",
      "county": "Sullivan"
  },
  {
      "zipCode": 47884,
      "latitude": 39.622129,
      "longitude": -87.454769,
      "city": "Universal",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47885,
      "latitude": 39.498419,
      "longitude": -87.468825,
      "city": "West Terre Haute",
      "state": "IN",
      "county": "Vigo"
  },
  {
      "zipCode": 47901,
      "latitude": 40.39905,
      "longitude": -86.830286,
      "city": "Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47902,
      "latitude": 40.388656,
      "longitude": -86.894908,
      "city": "Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47903,
      "latitude": 40.304402,
      "longitude": -86.824468,
      "city": "Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47904,
      "latitude": 40.482287,
      "longitude": -86.917273,
      "city": "Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47905,
      "latitude": 40.392686,
      "longitude": -86.884071,
      "city": "Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47906,
      "latitude": 40.467303,
      "longitude": -86.928132,
      "city": "West Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47907,
      "latitude": 40.424923,
      "longitude": -86.916215,
      "city": "West Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47909,
      "latitude": 40.322769,
      "longitude": -86.888066,
      "city": "Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47916,
      "latitude": 39.962271,
      "longitude": -87.050635,
      "city": "Alamo",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47917,
      "latitude": 40.527877,
      "longitude": -87.499343,
      "city": "Ambia",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47918,
      "latitude": 40.240204,
      "longitude": -87.213921,
      "city": "Attica",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47920,
      "latitude": 40.533131,
      "longitude": -86.822842,
      "city": "Battle Ground",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47921,
      "latitude": 40.577648,
      "longitude": -87.311618,
      "city": "Boswell",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47922,
      "latitude": 40.883043,
      "longitude": -87.394076,
      "city": "Brook",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 47923,
      "latitude": 40.661019,
      "longitude": -86.845433,
      "city": "Brookston",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47924,
      "latitude": 40.487603,
      "longitude": -86.763111,
      "city": "Buck Creek",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47925,
      "latitude": 40.750414,
      "longitude": -86.789632,
      "city": "Buffalo",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47926,
      "latitude": 40.813168,
      "longitude": -86.619353,
      "city": "Burnettsville",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47928,
      "latitude": 39.927176,
      "longitude": -87.471616,
      "city": "Cayuga",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47929,
      "latitude": 40.754782,
      "longitude": -86.934599,
      "city": "Chalmers",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47930,
      "latitude": 40.250791,
      "longitude": -86.75244,
      "city": "Clarks Hill",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47932,
      "latitude": 40.096833,
      "longitude": -87.352877,
      "city": "Covington",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47933,
      "latitude": 40.042425,
      "longitude": -86.893538,
      "city": "Crawfordsville",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47934,
      "latitude": 40.040014,
      "longitude": -86.893614,
      "city": "Crawfordsville",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47935,
      "latitude": 40.040014,
      "longitude": -86.893614,
      "city": "Crawfordsville",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47936,
      "latitude": 40.040014,
      "longitude": -86.893614,
      "city": "Crawfordsville",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47937,
      "latitude": 40.040014,
      "longitude": -86.893614,
      "city": "Crawfordsville",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47938,
      "latitude": 40.040014,
      "longitude": -86.893614,
      "city": "Crawfordsville",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47939,
      "latitude": 40.040014,
      "longitude": -86.893614,
      "city": "Crawfordsville",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47940,
      "latitude": 40.107625,
      "longitude": -86.777653,
      "city": "Darlington",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47941,
      "latitude": 40.362781,
      "longitude": -86.763031,
      "city": "Dayton",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47942,
      "latitude": 40.679382,
      "longitude": -87.415972,
      "city": "Earl Park",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47943,
      "latitude": 41.076883,
      "longitude": -87.208786,
      "city": "Fair Oaks",
      "state": "IN",
      "county": "Jasper"
  },
  {
      "zipCode": 47944,
      "latitude": 40.606541,
      "longitude": -87.311247,
      "city": "Fowler",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47946,
      "latitude": 40.984442,
      "longitude": -86.83465,
      "city": "Francesville",
      "state": "IN",
      "county": "Pulaski"
  },
  {
      "zipCode": 47948,
      "latitude": 40.794039,
      "longitude": -87.324812,
      "city": "Goodland",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 47949,
      "latitude": 40.045073,
      "longitude": -87.175046,
      "city": "Hillsboro",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47950,
      "latitude": 40.809887,
      "longitude": -86.739391,
      "city": "Idaville",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47951,
      "latitude": 40.794191,
      "longitude": -87.439821,
      "city": "Kentland",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 47952,
      "latitude": 40.017364,
      "longitude": -87.256877,
      "city": "Kingman",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47954,
      "latitude": 40.050732,
      "longitude": -86.855491,
      "city": "Ladoga",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47955,
      "latitude": 40.191498,
      "longitude": -86.901826,
      "city": "Linden",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47957,
      "latitude": 41.072624,
      "longitude": -86.849745,
      "city": "Medaryville",
      "state": "IN",
      "county": "Pulaski"
  },
  {
      "zipCode": 47958,
      "latitude": 40.164139,
      "longitude": -87.14754,
      "city": "Mellott",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47959,
      "latitude": 40.795391,
      "longitude": -86.93711,
      "city": "Monon",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47960,
      "latitude": 40.779211,
      "longitude": -86.749521,
      "city": "Monticello",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47962,
      "latitude": 40.473159,
      "longitude": -87.027571,
      "city": "Montmorenci",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47963,
      "latitude": 40.95469,
      "longitude": -87.411025,
      "city": "Morocco",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 47964,
      "latitude": 40.977567,
      "longitude": -87.396982,
      "city": "Mount Ayr",
      "state": "IN",
      "county": "Newton"
  },
  {
      "zipCode": 47965,
      "latitude": 39.952351,
      "longitude": -86.920609,
      "city": "New Market",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47966,
      "latitude": 39.87356,
      "longitude": -87.406937,
      "city": "Newport",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47967,
      "latitude": 40.193583,
      "longitude": -86.976237,
      "city": "New Richmond",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47968,
      "latitude": 40.026229,
      "longitude": -86.827059,
      "city": "New Ross",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47969,
      "latitude": 40.203459,
      "longitude": -87.147014,
      "city": "Newtown",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47970,
      "latitude": 40.598666,
      "longitude": -87.13607,
      "city": "Otterbein",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47971,
      "latitude": 40.533538,
      "longitude": -87.259375,
      "city": "Oxford",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47974,
      "latitude": 40.023673,
      "longitude": -87.475513,
      "city": "Perrysville",
      "state": "IN",
      "county": "Vermillion"
  },
  {
      "zipCode": 47975,
      "latitude": 40.439726,
      "longitude": -87.255833,
      "city": "Pine Village",
      "state": "IN",
      "county": "Warren"
  },
  {
      "zipCode": 47977,
      "latitude": 40.903837,
      "longitude": -87.140991,
      "city": "Remington",
      "state": "IN",
      "county": "Jasper"
  },
  {
      "zipCode": 47978,
      "latitude": 40.994793,
      "longitude": -87.10368,
      "city": "Rensselaer",
      "state": "IN",
      "county": "Jasper"
  },
  {
      "zipCode": 47980,
      "latitude": 40.782613,
      "longitude": -86.919278,
      "city": "Reynolds",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47981,
      "latitude": 40.258026,
      "longitude": -86.920786,
      "city": "Romney",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47982,
      "latitude": 40.19674,
      "longitude": -87.526532,
      "city": "State Line",
      "state": "IN",
      "county": "Warren"
  },
  {
      "zipCode": 47983,
      "latitude": 40.284511,
      "longitude": -86.772436,
      "city": "Stockwell",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47984,
      "latitude": 40.606301,
      "longitude": -87.310042,
      "city": "Talbot",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47986,
      "latitude": 40.505497,
      "longitude": -87.20696,
      "city": "Templeton",
      "state": "IN",
      "county": "Benton"
  },
  {
      "zipCode": 47987,
      "latitude": 40.110318,
      "longitude": -87.228444,
      "city": "Veedersburg",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47988,
      "latitude": 39.986538,
      "longitude": -87.147674,
      "city": "Wallace",
      "state": "IN",
      "county": "Fountain"
  },
  {
      "zipCode": 47989,
      "latitude": 39.913473,
      "longitude": -86.980365,
      "city": "Waveland",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47990,
      "latitude": 40.055655,
      "longitude": -87.035366,
      "city": "Waynetown",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47991,
      "latitude": 40.265862,
      "longitude": -87.438432,
      "city": "West Lebanon",
      "state": "IN",
      "county": "Warren"
  },
  {
      "zipCode": 47992,
      "latitude": 40.318084,
      "longitude": -87.046004,
      "city": "Westpoint",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47993,
      "latitude": 40.314172,
      "longitude": -87.403882,
      "city": "Williamsport",
      "state": "IN",
      "county": "Warren"
  },
  {
      "zipCode": 47994,
      "latitude": 40.118361,
      "longitude": -86.964894,
      "city": "Wingate",
      "state": "IN",
      "county": "Montgomery"
  },
  {
      "zipCode": 47995,
      "latitude": 40.726466,
      "longitude": -86.989485,
      "city": "Wolcott",
      "state": "IN",
      "county": "White"
  },
  {
      "zipCode": 47996,
      "latitude": 40.388656,
      "longitude": -86.894908,
      "city": "West Lafayette",
      "state": "IN",
      "county": "Tippecanoe"
  },
  {
      "zipCode": 47997,
      "latitude": 40.670628,
      "longitude": -86.722352,
      "city": "Yeoman",
      "state": "IN",
      "county": "Carroll"
  },
  {
      "zipCode": 48001,
      "latitude": 42.614984,
      "longitude": -82.597768,
      "city": "Algonac",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48002,
      "latitude": 42.938828,
      "longitude": -82.919818,
      "city": "Allenton",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48003,
      "latitude": 42.934027,
      "longitude": -83.052481,
      "city": "Almont",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48004,
      "latitude": 42.824095,
      "longitude": -82.66522,
      "city": "Anchorville",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48005,
      "latitude": 42.850051,
      "longitude": -82.894993,
      "city": "Armada",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48006,
      "latitude": 43.075152,
      "longitude": -82.695467,
      "city": "Avoca",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48007,
      "latitude": 42.606088,
      "longitude": -83.297593,
      "city": "Troy",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48009,
      "latitude": 42.534834,
      "longitude": -83.22416,
      "city": "Birmingham",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48012,
      "latitude": 42.604425,
      "longitude": -83.292382,
      "city": "Birmingham",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48014,
      "latitude": 43.026326,
      "longitude": -82.929772,
      "city": "Capac",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48015,
      "latitude": 42.480186,
      "longitude": -83.027221,
      "city": "Center Line",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48017,
      "latitude": 42.536785,
      "longitude": -83.146427,
      "city": "Clawson",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48021,
      "latitude": 42.464986,
      "longitude": -82.944318,
      "city": "Eastpointe",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48022,
      "latitude": 43.03545,
      "longitude": -82.800896,
      "city": "Emmett",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48023,
      "latitude": 42.702264,
      "longitude": -82.668829,
      "city": "Fair Haven",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48025,
      "latitude": 42.520184,
      "longitude": -83.264031,
      "city": "Franklin",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48026,
      "latitude": 42.538702,
      "longitude": -82.949421,
      "city": "Fraser",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48027,
      "latitude": 42.944333,
      "longitude": -82.691559,
      "city": "Goodells",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48028,
      "latitude": 42.597245,
      "longitude": -82.596757,
      "city": "Harsens Island",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48030,
      "latitude": 42.496485,
      "longitude": -83.098474,
      "city": "Hazel Park",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48032,
      "latitude": 43.12226,
      "longitude": -82.599804,
      "city": "Jeddo",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48034,
      "latitude": 42.478495,
      "longitude": -83.279164,
      "city": "Southfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48035,
      "latitude": 42.551185,
      "longitude": -82.91672,
      "city": "Clinton Township",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48036,
      "latitude": 42.593834,
      "longitude": -82.913321,
      "city": "Clinton Township",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48037,
      "latitude": 42.567543,
      "longitude": -83.149978,
      "city": "Southfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48038,
      "latitude": 42.620607,
      "longitude": -82.960775,
      "city": "Clinton Township",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48039,
      "latitude": 42.68586,
      "longitude": -82.549857,
      "city": "Marine City",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48040,
      "latitude": 42.78269,
      "longitude": -82.491538,
      "city": "Marysville",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48041,
      "latitude": 42.941032,
      "longitude": -82.804636,
      "city": "Memphis",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48042,
      "latitude": 42.673476,
      "longitude": -82.916262,
      "city": "Macomb",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48043,
      "latitude": 42.597984,
      "longitude": -82.877669,
      "city": "Mount Clemens",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48044,
      "latitude": 42.644211,
      "longitude": -82.898513,
      "city": "Macomb",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48045,
      "latitude": 42.587734,
      "longitude": -82.821517,
      "city": "Harrison Township",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48046,
      "latitude": 42.672322,
      "longitude": -82.903065,
      "city": "Mount Clemens",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48047,
      "latitude": 42.601469,
      "longitude": -82.821874,
      "city": "New Baltimore",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48048,
      "latitude": 42.744267,
      "longitude": -82.804166,
      "city": "New Haven",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48049,
      "latitude": 43.034915,
      "longitude": -82.574831,
      "city": "North Street",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48050,
      "latitude": 42.785908,
      "longitude": -82.797858,
      "city": "New Haven",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48051,
      "latitude": 42.67518,
      "longitude": -82.809082,
      "city": "New Baltimore",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48054,
      "latitude": 42.776889,
      "longitude": -82.543697,
      "city": "East China",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48059,
      "latitude": 43.088821,
      "longitude": -82.490486,
      "city": "Fort Gratiot",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48060,
      "latitude": 42.984014,
      "longitude": -82.467461,
      "city": "Port Huron",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48061,
      "latitude": 42.824095,
      "longitude": -82.66522,
      "city": "Port Huron",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48062,
      "latitude": 42.838913,
      "longitude": -82.799617,
      "city": "Richmond",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48063,
      "latitude": 42.889238,
      "longitude": -82.667478,
      "city": "Columbus",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48064,
      "latitude": 42.767364,
      "longitude": -82.672786,
      "city": "Casco",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48065,
      "latitude": 42.839951,
      "longitude": -83.038836,
      "city": "Romeo",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48066,
      "latitude": 42.509635,
      "longitude": -82.936069,
      "city": "Roseville",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48067,
      "latitude": 42.492485,
      "longitude": -83.145375,
      "city": "Royal Oak",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48068,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Royal Oak",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48069,
      "latitude": 42.471385,
      "longitude": -83.144026,
      "city": "Pleasant Ridge",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48070,
      "latitude": 42.481786,
      "longitude": -83.166376,
      "city": "Huntington Woods",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48071,
      "latitude": 42.505292,
      "longitude": -83.136126,
      "city": "Madison Heights",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48072,
      "latitude": 42.499235,
      "longitude": -83.181077,
      "city": "Berkley",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48073,
      "latitude": 42.5216,
      "longitude": -83.163477,
      "city": "Royal Oak",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48074,
      "latitude": 42.905056,
      "longitude": -82.567942,
      "city": "Smiths Creek",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48075,
      "latitude": 42.462215,
      "longitude": -83.230578,
      "city": "Southfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48076,
      "latitude": 42.498117,
      "longitude": -83.205798,
      "city": "Southfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48079,
      "latitude": 42.876235,
      "longitude": -82.547672,
      "city": "Saint Clair",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48080,
      "latitude": 42.470639,
      "longitude": -82.902194,
      "city": "Saint Clair Shores",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48081,
      "latitude": 42.494085,
      "longitude": -82.898986,
      "city": "Saint Clair Shores",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48082,
      "latitude": 42.526935,
      "longitude": -82.884117,
      "city": "Saint Clair Shores",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48083,
      "latitude": 42.555985,
      "longitude": -83.119206,
      "city": "Troy",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48084,
      "latitude": 42.554751,
      "longitude": -83.17688,
      "city": "Troy",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48085,
      "latitude": 42.604032,
      "longitude": -83.122111,
      "city": "Troy",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48086,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Southfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48088,
      "latitude": 42.515746,
      "longitude": -82.982986,
      "city": "Warren",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48089,
      "latitude": 42.474786,
      "longitude": -82.995121,
      "city": "Warren",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48090,
      "latitude": 42.672322,
      "longitude": -82.903065,
      "city": "Warren",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48091,
      "latitude": 42.470938,
      "longitude": -83.054623,
      "city": "Warren",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48092,
      "latitude": 42.513435,
      "longitude": -83.056988,
      "city": "Warren",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48093,
      "latitude": 42.514886,
      "longitude": -83.006613,
      "city": "Warren",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48094,
      "latitude": 42.755979,
      "longitude": -83.037598,
      "city": "Washington",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48095,
      "latitude": 42.768153,
      "longitude": -83.039474,
      "city": "Washington",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48096,
      "latitude": 42.754039,
      "longitude": -82.916348,
      "city": "Ray",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48097,
      "latitude": 42.879217,
      "longitude": -82.739491,
      "city": "Yale",
      "state": "MI",
      "county": "Saint Clair"
  },
  {
      "zipCode": 48098,
      "latitude": 42.599883,
      "longitude": -83.14908,
      "city": "Troy",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48099,
      "latitude": 42.587643,
      "longitude": -83.173666,
      "city": "Troy",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48101,
      "latitude": 42.27633,
      "longitude": -83.194425,
      "city": "Allen Park",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48103,
      "latitude": 42.266638,
      "longitude": -83.849042,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48104,
      "latitude": 42.270903,
      "longitude": -83.727826,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48105,
      "latitude": 42.323077,
      "longitude": -83.713891,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48106,
      "latitude": 42.253502,
      "longitude": -83.836571,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48107,
      "latitude": 42.253502,
      "longitude": -83.836571,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48108,
      "latitude": 42.223305,
      "longitude": -83.729342,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48109,
      "latitude": 42.276324,
      "longitude": -83.711228,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48110,
      "latitude": 42.008164,
      "longitude": -83.664277,
      "city": "Azalia",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48111,
      "latitude": 42.263968,
      "longitude": -83.416284,
      "city": "Belleville",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48112,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Belleville",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48113,
      "latitude": 42.253502,
      "longitude": -83.836571,
      "city": "Ann Arbor",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48114,
      "latitude": 42.546402,
      "longitude": -83.877776,
      "city": "Brighton",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48115,
      "latitude": 42.160216,
      "longitude": -83.911693,
      "city": "Bridgewater",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48116,
      "latitude": 42.529541,
      "longitude": -83.776055,
      "city": "Brighton",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48117,
      "latitude": 42.0413,
      "longitude": -83.428904,
      "city": "Carleton",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48118,
      "latitude": 42.31375,
      "longitude": -84.013999,
      "city": "Chelsea",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48120,
      "latitude": 42.310037,
      "longitude": -83.177625,
      "city": "Dearborn",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48121,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Dearborn",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48122,
      "latitude": 42.353686,
      "longitude": -83.223427,
      "city": "Melvindale",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48123,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Dearborn",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48124,
      "latitude": 42.299302,
      "longitude": -83.242577,
      "city": "Dearborn",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48125,
      "latitude": 42.342272,
      "longitude": -83.298123,
      "city": "Dearborn Heights",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48126,
      "latitude": 42.271811,
      "longitude": -83.225877,
      "city": "Dearborn",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48127,
      "latitude": 42.334386,
      "longitude": -83.273929,
      "city": "Dearborn Heights",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48128,
      "latitude": 42.31927,
      "longitude": -83.251428,
      "city": "Dearborn",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48130,
      "latitude": 42.353887,
      "longitude": -83.898295,
      "city": "Dexter",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48131,
      "latitude": 41.964903,
      "longitude": -83.668808,
      "city": "Dundee",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48133,
      "latitude": 41.790287,
      "longitude": -83.504033,
      "city": "Erie",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48134,
      "latitude": 42.116811,
      "longitude": -83.300439,
      "city": "Flat Rock",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48135,
      "latitude": 42.326437,
      "longitude": -83.343431,
      "city": "Garden City",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48136,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Garden City",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48137,
      "latitude": 42.496024,
      "longitude": -84.084724,
      "city": "Gregory",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48138,
      "latitude": 42.135191,
      "longitude": -83.156124,
      "city": "Grosse Ile",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48139,
      "latitude": 42.452284,
      "longitude": -83.814654,
      "city": "Hamburg",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48140,
      "latitude": 41.887078,
      "longitude": -83.58177,
      "city": "Ida",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48141,
      "latitude": 42.293338,
      "longitude": -83.314674,
      "city": "Inkster",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48143,
      "latitude": 42.457317,
      "longitude": -83.836643,
      "city": "Lakeland",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48144,
      "latitude": 41.759998,
      "longitude": -83.618485,
      "city": "Lambertville",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48145,
      "latitude": 41.845509,
      "longitude": -83.462383,
      "city": "La Salle",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48146,
      "latitude": 42.242038,
      "longitude": -83.180375,
      "city": "Lincoln Park",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48150,
      "latitude": 42.369351,
      "longitude": -83.371753,
      "city": "Livonia",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48151,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Livonia",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48152,
      "latitude": 42.426235,
      "longitude": -83.370682,
      "city": "Livonia",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48153,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Livonia",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48154,
      "latitude": 42.375986,
      "longitude": -83.389962,
      "city": "Livonia",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48157,
      "latitude": 41.813365,
      "longitude": -83.436032,
      "city": "Luna Pier",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48158,
      "latitude": 42.18314,
      "longitude": -84.013795,
      "city": "Manchester",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48159,
      "latitude": 42.041832,
      "longitude": -83.557254,
      "city": "Maybee",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48160,
      "latitude": 42.001525,
      "longitude": -83.659763,
      "city": "Milan",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48161,
      "latitude": 41.928024,
      "longitude": -83.430682,
      "city": "Monroe",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48162,
      "latitude": 41.929341,
      "longitude": -83.444773,
      "city": "Monroe",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48164,
      "latitude": 42.135225,
      "longitude": -83.390422,
      "city": "New Boston",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48165,
      "latitude": 42.498145,
      "longitude": -83.608727,
      "city": "New Hudson",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48166,
      "latitude": 41.921912,
      "longitude": -83.330583,
      "city": "Newport",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48167,
      "latitude": 42.41729,
      "longitude": -83.432434,
      "city": "Northville",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48169,
      "latitude": 42.467503,
      "longitude": -83.946982,
      "city": "Pinckney",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48170,
      "latitude": 42.366737,
      "longitude": -83.489679,
      "city": "Plymouth",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48173,
      "latitude": 42.112182,
      "longitude": -83.272588,
      "city": "Rockwood",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48174,
      "latitude": 42.20284,
      "longitude": -83.351255,
      "city": "Romulus",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48175,
      "latitude": 42.405636,
      "longitude": -83.578088,
      "city": "Salem",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48176,
      "latitude": 42.147636,
      "longitude": -83.802375,
      "city": "Saline",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48177,
      "latitude": 41.807647,
      "longitude": -83.579334,
      "city": "Samaria",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48178,
      "latitude": 42.474564,
      "longitude": -83.612188,
      "city": "South Lyon",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48179,
      "latitude": 42.051135,
      "longitude": -83.27095,
      "city": "South Rockwood",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48180,
      "latitude": 42.245837,
      "longitude": -83.249067,
      "city": "Taylor",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48182,
      "latitude": 41.790497,
      "longitude": -83.584998,
      "city": "Temperance",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 48183,
      "latitude": 42.138209,
      "longitude": -83.217937,
      "city": "Trenton",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48184,
      "latitude": 42.263487,
      "longitude": -83.395975,
      "city": "Wayne",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48185,
      "latitude": 42.31507,
      "longitude": -83.373093,
      "city": "Westland",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48186,
      "latitude": 42.28902,
      "longitude": -83.368582,
      "city": "Westland",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48187,
      "latitude": 42.374118,
      "longitude": -83.418143,
      "city": "Canton",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48188,
      "latitude": 42.291734,
      "longitude": -83.485776,
      "city": "Canton",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48189,
      "latitude": 42.386914,
      "longitude": -83.783736,
      "city": "Whitmore Lake",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48190,
      "latitude": 42.124398,
      "longitude": -83.594567,
      "city": "Whittaker",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48191,
      "latitude": 42.129589,
      "longitude": -83.569965,
      "city": "Willis",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48192,
      "latitude": 42.19499,
      "longitude": -83.206572,
      "city": "Wyandotte",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48195,
      "latitude": 42.257801,
      "longitude": -83.285939,
      "city": "Southgate",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48197,
      "latitude": 42.202139,
      "longitude": -83.620494,
      "city": "Ypsilanti",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48198,
      "latitude": 42.309687,
      "longitude": -83.772991,
      "city": "Ypsilanti",
      "state": "MI",
      "county": "Washtenaw"
  },
  {
      "zipCode": 48201,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48202,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48203,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Highland Park",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48204,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48205,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48206,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48207,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48208,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48209,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48210,
      "latitude": 42.326937,
      "longitude": -83.148424,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48211,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48212,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Hamtramck",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48213,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48214,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48215,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48216,
      "latitude": 42.337787,
      "longitude": -83.167925,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48217,
      "latitude": 42.273038,
      "longitude": -83.152573,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48218,
      "latitude": 42.268761,
      "longitude": -83.135137,
      "city": "River Rouge",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48219,
      "latitude": 42.437192,
      "longitude": -83.287477,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48220,
      "latitude": 42.460586,
      "longitude": -83.140675,
      "city": "Ferndale",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48221,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48222,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48223,
      "latitude": 42.395986,
      "longitude": -83.275979,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48224,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48225,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Harper Woods",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48226,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48227,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48228,
      "latitude": 42.335937,
      "longitude": -83.217127,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48229,
      "latitude": 42.249838,
      "longitude": -83.143121,
      "city": "Ecorse",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48230,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Grosse Pointe",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48231,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48232,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48233,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48234,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48235,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48236,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Grosse Pointe",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48237,
      "latitude": 42.466585,
      "longitude": -83.178177,
      "city": "Oak Park",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48238,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48239,
      "latitude": 42.392286,
      "longitude": -83.282029,
      "city": "Redford",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48240,
      "latitude": 42.423035,
      "longitude": -83.30358,
      "city": "Redford",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48242,
      "latitude": 42.216575,
      "longitude": -83.35324,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48243,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48244,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48255,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48260,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48264,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48265,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48266,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48267,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48268,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48269,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48272,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48275,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48277,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48278,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48279,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48288,
      "latitude": 42.239933,
      "longitude": -83.150823,
      "city": "Detroit",
      "state": "MI",
      "county": "Wayne"
  },
  {
      "zipCode": 48301,
      "latitude": 42.544534,
      "longitude": -83.279231,
      "city": "Bloomfield Hills",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48302,
      "latitude": 42.584789,
      "longitude": -83.282116,
      "city": "Bloomfield Hills",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48303,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Bloomfield Hills",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48304,
      "latitude": 42.587034,
      "longitude": -83.235931,
      "city": "Bloomfield Hills",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48306,
      "latitude": 42.726216,
      "longitude": -83.156629,
      "city": "Rochester",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48307,
      "latitude": 42.659283,
      "longitude": -83.122482,
      "city": "Rochester",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48308,
      "latitude": 42.638533,
      "longitude": -83.131426,
      "city": "Rochester",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48309,
      "latitude": 42.662644,
      "longitude": -83.183666,
      "city": "Rochester",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48310,
      "latitude": 42.564395,
      "longitude": -83.068475,
      "city": "Sterling Heights",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48311,
      "latitude": 42.672322,
      "longitude": -82.903065,
      "city": "Sterling Heights",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48312,
      "latitude": 42.566338,
      "longitude": -83.028532,
      "city": "Sterling Heights",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48313,
      "latitude": 42.606847,
      "longitude": -83.01041,
      "city": "Sterling Heights",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48314,
      "latitude": 42.603976,
      "longitude": -83.036259,
      "city": "Sterling Heights",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48315,
      "latitude": 42.671025,
      "longitude": -82.998811,
      "city": "Utica",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48316,
      "latitude": 42.688531,
      "longitude": -83.05483,
      "city": "Utica",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48317,
      "latitude": 42.641276,
      "longitude": -83.05043,
      "city": "Utica",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48318,
      "latitude": 42.672322,
      "longitude": -82.903065,
      "city": "Utica",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48320,
      "latitude": 42.611409,
      "longitude": -83.335629,
      "city": "Keego Harbor",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48321,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Auburn Hills",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48322,
      "latitude": 42.542715,
      "longitude": -83.379535,
      "city": "West Bloomfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48323,
      "latitude": 42.568292,
      "longitude": -83.380535,
      "city": "West Bloomfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48324,
      "latitude": 42.596093,
      "longitude": -83.381886,
      "city": "West Bloomfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48325,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "West Bloomfield",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48326,
      "latitude": 42.673931,
      "longitude": -83.295052,
      "city": "Auburn Hills",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48327,
      "latitude": 42.644824,
      "longitude": -83.406111,
      "city": "Waterford",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48328,
      "latitude": 42.647831,
      "longitude": -83.355433,
      "city": "Waterford",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48329,
      "latitude": 42.68258,
      "longitude": -83.386338,
      "city": "Waterford",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48330,
      "latitude": 42.675435,
      "longitude": -83.363697,
      "city": "Drayton Plains",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48331,
      "latitude": 42.505138,
      "longitude": -83.407233,
      "city": "Farmington",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48332,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Farmington",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48333,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Farmington",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48334,
      "latitude": 42.506468,
      "longitude": -83.348394,
      "city": "Farmington",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48335,
      "latitude": 42.461669,
      "longitude": -83.405333,
      "city": "Farmington",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48336,
      "latitude": 42.465635,
      "longitude": -83.363784,
      "city": "Farmington",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48340,
      "latitude": 42.670272,
      "longitude": -83.289036,
      "city": "Pontiac",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48341,
      "latitude": 42.633716,
      "longitude": -83.291471,
      "city": "Pontiac",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48342,
      "latitude": 42.694224,
      "longitude": -83.288516,
      "city": "Pontiac",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48343,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Pontiac",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48346,
      "latitude": 42.723851,
      "longitude": -83.423242,
      "city": "Clarkston",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48347,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Clarkston",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48348,
      "latitude": 42.760507,
      "longitude": -83.403984,
      "city": "Clarkston",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48350,
      "latitude": 42.749639,
      "longitude": -83.535805,
      "city": "Davisburg",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48353,
      "latitude": 42.648484,
      "longitude": -83.717806,
      "city": "Hartland",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48356,
      "latitude": 42.701111,
      "longitude": -83.422549,
      "city": "Highland",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48357,
      "latitude": 42.654444,
      "longitude": -83.561306,
      "city": "Highland",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48359,
      "latitude": 42.723081,
      "longitude": -83.276903,
      "city": "Lake Orion",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48360,
      "latitude": 42.74288,
      "longitude": -83.272037,
      "city": "Lake Orion",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48361,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Lake Orion",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48362,
      "latitude": 42.776329,
      "longitude": -83.274778,
      "city": "Lake Orion",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48363,
      "latitude": 42.766093,
      "longitude": -83.157643,
      "city": "Oakland",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48366,
      "latitude": 42.803277,
      "longitude": -83.18412,
      "city": "Lakeville",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48367,
      "latitude": 42.843653,
      "longitude": -83.140608,
      "city": "Leonard",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48370,
      "latitude": 42.840328,
      "longitude": -83.200206,
      "city": "Oxford",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48371,
      "latitude": 42.772209,
      "longitude": -83.339754,
      "city": "Oxford",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48374,
      "latitude": 42.468959,
      "longitude": -83.522221,
      "city": "Novi",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48375,
      "latitude": 42.463569,
      "longitude": -83.46663,
      "city": "Novi",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48376,
      "latitude": 42.470984,
      "longitude": -83.474785,
      "city": "Novi",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48377,
      "latitude": 42.49917,
      "longitude": -83.477335,
      "city": "Novi",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48380,
      "latitude": 42.593161,
      "longitude": -83.645451,
      "city": "Milford",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48381,
      "latitude": 42.554322,
      "longitude": -83.579098,
      "city": "Milford",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48382,
      "latitude": 42.583382,
      "longitude": -83.500878,
      "city": "Commerce Township",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48383,
      "latitude": 42.657698,
      "longitude": -83.534444,
      "city": "White Lake",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48386,
      "latitude": 42.657843,
      "longitude": -83.478714,
      "city": "White Lake",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48387,
      "latitude": 42.7253,
      "longitude": -83.311605,
      "city": "Union Lake",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48390,
      "latitude": 42.558183,
      "longitude": -83.47726,
      "city": "Walled Lake",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48391,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Walled Lake",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48393,
      "latitude": 42.520533,
      "longitude": -83.549145,
      "city": "Wixom",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48397,
      "latitude": 42.491736,
      "longitude": -83.040222,
      "city": "Warren",
      "state": "MI",
      "county": "Macomb"
  },
  {
      "zipCode": 48398,
      "latitude": 42.660091,
      "longitude": -83.3863,
      "city": "Clawson",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48401,
      "latitude": 43.35159,
      "longitude": -82.670835,
      "city": "Applegate",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48410,
      "latitude": 43.558856,
      "longitude": -82.945483,
      "city": "Argyle",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48411,
      "latitude": 42.940019,
      "longitude": -83.536923,
      "city": "Atlas",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48412,
      "latitude": 43.066173,
      "longitude": -83.257592,
      "city": "Attica",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48413,
      "latitude": 43.79455,
      "longitude": -82.949138,
      "city": "Bad Axe",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48414,
      "latitude": 42.865732,
      "longitude": -84.083097,
      "city": "Bancroft",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48415,
      "latitude": 43.27849,
      "longitude": -83.81449,
      "city": "Birch Run",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48416,
      "latitude": 43.242602,
      "longitude": -82.918819,
      "city": "Brown City",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48417,
      "latitude": 43.263947,
      "longitude": -83.893403,
      "city": "Burt",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48418,
      "latitude": 42.826333,
      "longitude": -84.020577,
      "city": "Byron",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48419,
      "latitude": 43.443662,
      "longitude": -82.663858,
      "city": "Carsonville",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48420,
      "latitude": 43.170497,
      "longitude": -83.707374,
      "city": "Clio",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48421,
      "latitude": 43.072417,
      "longitude": -83.292729,
      "city": "Columbiaville",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48422,
      "latitude": 43.252807,
      "longitude": -82.644104,
      "city": "Croswell",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48423,
      "latitude": 43.041994,
      "longitude": -83.622843,
      "city": "Davison",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48426,
      "latitude": 43.517467,
      "longitude": -82.853426,
      "city": "Decker",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48427,
      "latitude": 43.535064,
      "longitude": -82.722491,
      "city": "Deckerville",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48428,
      "latitude": 42.937629,
      "longitude": -83.154685,
      "city": "Dryden",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48429,
      "latitude": 42.894936,
      "longitude": -84.02596,
      "city": "Durand",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48430,
      "latitude": 42.83333,
      "longitude": -83.763433,
      "city": "Fenton",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48432,
      "latitude": 43.952572,
      "longitude": -82.973268,
      "city": "Filion",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48433,
      "latitude": 42.978274,
      "longitude": -83.808006,
      "city": "Flushing",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48434,
      "latitude": 43.663206,
      "longitude": -82.613319,
      "city": "Forestville",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48435,
      "latitude": 43.277734,
      "longitude": -83.39199,
      "city": "Fostoria",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48436,
      "latitude": 42.872466,
      "longitude": -83.858445,
      "city": "Gaines",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48437,
      "latitude": 43.018423,
      "longitude": -83.691666,
      "city": "Genesee",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48438,
      "latitude": 42.91884,
      "longitude": -83.51249,
      "city": "Goodrich",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48439,
      "latitude": 42.9227,
      "longitude": -83.67376,
      "city": "Grand Blanc",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48440,
      "latitude": 42.93062,
      "longitude": -83.360013,
      "city": "Hadley",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48441,
      "latitude": 43.867295,
      "longitude": -82.800293,
      "city": "Harbor Beach",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48442,
      "latitude": 42.775922,
      "longitude": -83.579384,
      "city": "Holly",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48444,
      "latitude": 43.065642,
      "longitude": -83.060893,
      "city": "Imlay City",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48445,
      "latitude": 43.961537,
      "longitude": -82.944266,
      "city": "Kinde",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48446,
      "latitude": 43.121459,
      "longitude": -83.319558,
      "city": "Lapeer",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48449,
      "latitude": 42.98966,
      "longitude": -83.900264,
      "city": "Lennon",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48450,
      "latitude": 43.392172,
      "longitude": -82.78895,
      "city": "Lexington",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48451,
      "latitude": 42.825944,
      "longitude": -83.811322,
      "city": "Linden",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48453,
      "latitude": 43.357539,
      "longitude": -83.002816,
      "city": "Marlette",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48454,
      "latitude": 43.20471,
      "longitude": -82.810553,
      "city": "Melvin",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48455,
      "latitude": 42.942128,
      "longitude": -83.29607,
      "city": "Metamora",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48456,
      "latitude": 43.651302,
      "longitude": -82.756025,
      "city": "Minden City",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48457,
      "latitude": 43.003962,
      "longitude": -83.873179,
      "city": "Montrose",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48458,
      "latitude": 43.120587,
      "longitude": -83.692978,
      "city": "Mount Morris",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48460,
      "latitude": 43.109913,
      "longitude": -83.998893,
      "city": "New Lothrop",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48461,
      "latitude": 43.112848,
      "longitude": -83.250511,
      "city": "North Branch",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48462,
      "latitude": 42.835019,
      "longitude": -83.42298,
      "city": "Ortonville",
      "state": "MI",
      "county": "Oakland"
  },
  {
      "zipCode": 48463,
      "latitude": 43.157936,
      "longitude": -83.529863,
      "city": "Otisville",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48464,
      "latitude": 43.211722,
      "longitude": -83.405752,
      "city": "Otter Lake",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48465,
      "latitude": 43.614613,
      "longitude": -82.707455,
      "city": "Palms",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48466,
      "latitude": 43.274949,
      "longitude": -82.807228,
      "city": "Peck",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48467,
      "latitude": 43.99756,
      "longitude": -83.052623,
      "city": "Port Austin",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48468,
      "latitude": 43.944086,
      "longitude": -82.907943,
      "city": "Port Hope",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48469,
      "latitude": 43.458914,
      "longitude": -82.566894,
      "city": "Port Sanilac",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48470,
      "latitude": 43.743805,
      "longitude": -82.740132,
      "city": "Ruth",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48471,
      "latitude": 43.374676,
      "longitude": -82.858995,
      "city": "Sandusky",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48472,
      "latitude": 43.40401,
      "longitude": -82.951494,
      "city": "Snover",
      "state": "MI",
      "county": "Sanilac"
  },
  {
      "zipCode": 48473,
      "latitude": 42.950323,
      "longitude": -83.812575,
      "city": "Swartz Creek",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48475,
      "latitude": 43.80794,
      "longitude": -83.023993,
      "city": "Ubly",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48476,
      "latitude": 42.940553,
      "longitude": -84.032828,
      "city": "Vernon",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48501,
      "latitude": 42.965926,
      "longitude": -83.780835,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48502,
      "latitude": 43.015127,
      "longitude": -83.694756,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48503,
      "latitude": 43.010835,
      "longitude": -83.69278,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48504,
      "latitude": 43.020626,
      "longitude": -83.697666,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48505,
      "latitude": 43.076926,
      "longitude": -83.690207,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48506,
      "latitude": 43.065313,
      "longitude": -83.631004,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48507,
      "latitude": 42.982076,
      "longitude": -83.734004,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48509,
      "latitude": 43.025934,
      "longitude": -83.604053,
      "city": "Burton",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48519,
      "latitude": 42.985927,
      "longitude": -83.613452,
      "city": "Burton",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48529,
      "latitude": 42.974427,
      "longitude": -83.662853,
      "city": "Burton",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48531,
      "latitude": 43.002,
      "longitude": -83.692507,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48532,
      "latitude": 43.011127,
      "longitude": -83.803045,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48550,
      "latitude": 43.034927,
      "longitude": -83.688706,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48551,
      "latitude": 42.978995,
      "longitude": -83.713124,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48552,
      "latitude": 42.977895,
      "longitude": -83.713074,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48553,
      "latitude": 42.973627,
      "longitude": -83.720306,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48554,
      "latitude": 42.972244,
      "longitude": -83.79463,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48555,
      "latitude": 43.011277,
      "longitude": -83.710756,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48556,
      "latitude": 43.032677,
      "longitude": -83.646255,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48557,
      "latitude": 43.080578,
      "longitude": -83.783675,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48559,
      "latitude": 43.002,
      "longitude": -83.692507,
      "city": "Flint",
      "state": "MI",
      "county": "Genesee"
  },
  {
      "zipCode": 48601,
      "latitude": 43.41242,
      "longitude": -83.886873,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48602,
      "latitude": 43.42926,
      "longitude": -84.056977,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48603,
      "latitude": 43.460115,
      "longitude": -84.026587,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48604,
      "latitude": 43.482403,
      "longitude": -83.969391,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48605,
      "latitude": 43.458776,
      "longitude": -84.051827,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48606,
      "latitude": 43.348535,
      "longitude": -84.032612,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48607,
      "latitude": 43.431875,
      "longitude": -83.933672,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48608,
      "latitude": 43.348535,
      "longitude": -84.032612,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48609,
      "latitude": 43.38689,
      "longitude": -84.092633,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48610,
      "latitude": 44.324353,
      "longitude": -84.162443,
      "city": "Alger",
      "state": "MI",
      "county": "Ogemaw"
  },
  {
      "zipCode": 48611,
      "latitude": 43.639444,
      "longitude": -84.059141,
      "city": "Auburn",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48612,
      "latitude": 43.965212,
      "longitude": -84.436146,
      "city": "Beaverton",
      "state": "MI",
      "county": "Gladwin"
  },
  {
      "zipCode": 48613,
      "latitude": 43.92673,
      "longitude": -84.105306,
      "city": "Bentley",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48614,
      "latitude": 43.237044,
      "longitude": -84.288736,
      "city": "Brant",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48615,
      "latitude": 43.38631,
      "longitude": -84.46894,
      "city": "Breckenridge",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48616,
      "latitude": 43.329465,
      "longitude": -84.136197,
      "city": "Chesaning",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48617,
      "latitude": 43.976073,
      "longitude": -84.739685,
      "city": "Clare",
      "state": "MI",
      "county": "Clare"
  },
  {
      "zipCode": 48618,
      "latitude": 43.720284,
      "longitude": -84.52035,
      "city": "Coleman",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48619,
      "latitude": 44.756363,
      "longitude": -84.046449,
      "city": "Comins",
      "state": "MI",
      "county": "Oscoda"
  },
  {
      "zipCode": 48620,
      "latitude": 43.777368,
      "longitude": -84.381307,
      "city": "Edenville",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48621,
      "latitude": 44.689286,
      "longitude": -84.011959,
      "city": "Fairview",
      "state": "MI",
      "county": "Oscoda"
  },
  {
      "zipCode": 48622,
      "latitude": 43.951093,
      "longitude": -84.866432,
      "city": "Farwell",
      "state": "MI",
      "county": "Clare"
  },
  {
      "zipCode": 48623,
      "latitude": 43.496884,
      "longitude": -84.102426,
      "city": "Freeland",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48624,
      "latitude": 44.032603,
      "longitude": -84.429231,
      "city": "Gladwin",
      "state": "MI",
      "county": "Gladwin"
  },
  {
      "zipCode": 48625,
      "latitude": 44.005299,
      "longitude": -84.85096,
      "city": "Harrison",
      "state": "MI",
      "county": "Clare"
  },
  {
      "zipCode": 48626,
      "latitude": 43.428523,
      "longitude": -84.230091,
      "city": "Hemlock",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48627,
      "latitude": 44.387123,
      "longitude": -84.701346,
      "city": "Higgins Lake",
      "state": "MI",
      "county": "Roscommon"
  },
  {
      "zipCode": 48628,
      "latitude": 43.770317,
      "longitude": -84.4046,
      "city": "Hope",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48629,
      "latitude": 44.351007,
      "longitude": -84.733596,
      "city": "Houghton Lake",
      "state": "MI",
      "county": "Roscommon"
  },
  {
      "zipCode": 48630,
      "latitude": 44.332152,
      "longitude": -84.71945,
      "city": "Houghton Lake Heights",
      "state": "MI",
      "county": "Roscommon"
  },
  {
      "zipCode": 48631,
      "latitude": 43.688372,
      "longitude": -84.026419,
      "city": "Kawkawlin",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48632,
      "latitude": 43.914196,
      "longitude": -84.867626,
      "city": "Lake",
      "state": "MI",
      "county": "Clare"
  },
  {
      "zipCode": 48633,
      "latitude": 43.958329,
      "longitude": -84.919332,
      "city": "Lake George",
      "state": "MI",
      "county": "Clare"
  },
  {
      "zipCode": 48634,
      "latitude": 43.771397,
      "longitude": -84.051257,
      "city": "Linwood",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48635,
      "latitude": 44.377795,
      "longitude": -84.072974,
      "city": "Lupton",
      "state": "MI",
      "county": "Ogemaw"
  },
  {
      "zipCode": 48636,
      "latitude": 44.622852,
      "longitude": -84.14818,
      "city": "Luzerne",
      "state": "MI",
      "county": "Oscoda"
  },
  {
      "zipCode": 48637,
      "latitude": 43.389643,
      "longitude": -84.309397,
      "city": "Merrill",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48640,
      "latitude": 43.626132,
      "longitude": -84.33803,
      "city": "Midland",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48641,
      "latitude": 43.538252,
      "longitude": -84.387753,
      "city": "Midland",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48642,
      "latitude": 43.68698,
      "longitude": -84.280136,
      "city": "Midland",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48647,
      "latitude": 44.662952,
      "longitude": -84.089518,
      "city": "Mio",
      "state": "MI",
      "county": "Oscoda"
  },
  {
      "zipCode": 48649,
      "latitude": 43.165075,
      "longitude": -84.207683,
      "city": "Oakley",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48650,
      "latitude": 43.839179,
      "longitude": -84.038715,
      "city": "Pinconning",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48651,
      "latitude": 44.343508,
      "longitude": -84.647139,
      "city": "Prudenville",
      "state": "MI",
      "county": "Roscommon"
  },
  {
      "zipCode": 48652,
      "latitude": 43.868833,
      "longitude": -84.228499,
      "city": "Rhodes",
      "state": "MI",
      "county": "Gladwin"
  },
  {
      "zipCode": 48653,
      "latitude": 44.394517,
      "longitude": -84.611194,
      "city": "Roscommon",
      "state": "MI",
      "county": "Roscommon"
  },
  {
      "zipCode": 48654,
      "latitude": 44.334415,
      "longitude": -84.190661,
      "city": "Rose City",
      "state": "MI",
      "county": "Ogemaw"
  },
  {
      "zipCode": 48655,
      "latitude": 43.25677,
      "longitude": -84.180476,
      "city": "Saint Charles",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48656,
      "latitude": 44.359863,
      "longitude": -84.543576,
      "city": "Saint Helen",
      "state": "MI",
      "county": "Roscommon"
  },
  {
      "zipCode": 48657,
      "latitude": 43.712095,
      "longitude": -84.399532,
      "city": "Sanford",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48658,
      "latitude": 43.979427,
      "longitude": -83.900702,
      "city": "Standish",
      "state": "MI",
      "county": "Arenac"
  },
  {
      "zipCode": 48659,
      "latitude": 44.079473,
      "longitude": -83.956748,
      "city": "Sterling",
      "state": "MI",
      "county": "Arenac"
  },
  {
      "zipCode": 48661,
      "latitude": 44.33095,
      "longitude": -84.202733,
      "city": "West Branch",
      "state": "MI",
      "county": "Ogemaw"
  },
  {
      "zipCode": 48662,
      "latitude": 43.379034,
      "longitude": -84.419329,
      "city": "Wheeler",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48663,
      "latitude": 43.467315,
      "longitude": -83.975475,
      "city": "Saginaw",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48667,
      "latitude": 43.647297,
      "longitude": -84.387331,
      "city": "Midland",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48670,
      "latitude": 43.637471,
      "longitude": -84.256758,
      "city": "Midland",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48674,
      "latitude": 43.612884,
      "longitude": -84.197125,
      "city": "Midland",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48686,
      "latitude": 43.647297,
      "longitude": -84.387331,
      "city": "Midland",
      "state": "MI",
      "county": "Midland"
  },
  {
      "zipCode": 48701,
      "latitude": 43.582147,
      "longitude": -83.546114,
      "city": "Akron",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48703,
      "latitude": 44.063621,
      "longitude": -83.686379,
      "city": "Au Gres",
      "state": "MI",
      "county": "Arenac"
  },
  {
      "zipCode": 48705,
      "latitude": 44.678354,
      "longitude": -83.599977,
      "city": "Barton City",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48706,
      "latitude": 43.608773,
      "longitude": -83.953007,
      "city": "Bay City",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48707,
      "latitude": 43.737798,
      "longitude": -83.933337,
      "city": "Bay City",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48708,
      "latitude": 43.559572,
      "longitude": -83.820876,
      "city": "Bay City",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48710,
      "latitude": 43.556574,
      "longitude": -83.994328,
      "city": "University Center",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48720,
      "latitude": 43.815182,
      "longitude": -83.341775,
      "city": "Bay Port",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48721,
      "latitude": 44.794881,
      "longitude": -83.34884,
      "city": "Black River",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48722,
      "latitude": 43.343016,
      "longitude": -83.832323,
      "city": "Bridgeport",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48723,
      "latitude": 43.489001,
      "longitude": -83.385078,
      "city": "Caro",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48724,
      "latitude": 43.465024,
      "longitude": -83.925473,
      "city": "Carrollton",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48725,
      "latitude": 43.84953,
      "longitude": -83.232873,
      "city": "Caseville",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48726,
      "latitude": 43.586075,
      "longitude": -83.293496,
      "city": "Cass City",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48727,
      "latitude": 43.28298,
      "longitude": -83.178182,
      "city": "Clifford",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48728,
      "latitude": 44.705591,
      "longitude": -83.78745,
      "city": "Curran",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48729,
      "latitude": 43.491974,
      "longitude": -83.198239,
      "city": "Deford",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48730,
      "latitude": 44.349592,
      "longitude": -83.562942,
      "city": "East Tawas",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48731,
      "latitude": 43.826222,
      "longitude": -83.141633,
      "city": "Elkton",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48732,
      "latitude": 43.601522,
      "longitude": -83.778971,
      "city": "Essexville",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48733,
      "latitude": 43.534504,
      "longitude": -83.573677,
      "city": "Fairgrove",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48734,
      "latitude": 43.353763,
      "longitude": -83.756467,
      "city": "Frankenmuth",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48735,
      "latitude": 43.599096,
      "longitude": -83.340629,
      "city": "Gagetown",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48736,
      "latitude": 43.49442,
      "longitude": -83.624431,
      "city": "Gilford",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48737,
      "latitude": 44.57341,
      "longitude": -83.708328,
      "city": "Glennie",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48738,
      "latitude": 44.557782,
      "longitude": -83.338437,
      "city": "Greenbush",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48739,
      "latitude": 44.358808,
      "longitude": -83.764739,
      "city": "Hale",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48740,
      "latitude": 44.656694,
      "longitude": -83.41973,
      "city": "Harrisville",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48741,
      "latitude": 43.398564,
      "longitude": -83.195025,
      "city": "Kingston",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48742,
      "latitude": 44.65922,
      "longitude": -83.454865,
      "city": "Lincoln",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48743,
      "latitude": 44.441563,
      "longitude": -83.851644,
      "city": "Long Lake",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48744,
      "latitude": 43.344868,
      "longitude": -83.367939,
      "city": "Mayville",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48745,
      "latitude": 44.578092,
      "longitude": -83.591419,
      "city": "Mikado",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48746,
      "latitude": 43.297434,
      "longitude": -83.47392,
      "city": "Millington",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48747,
      "latitude": 43.529339,
      "longitude": -83.762898,
      "city": "Munger",
      "state": "MI",
      "county": "Bay"
  },
  {
      "zipCode": 48748,
      "latitude": 44.280833,
      "longitude": -83.71748,
      "city": "National City",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48749,
      "latitude": 44.065941,
      "longitude": -83.846526,
      "city": "Omer",
      "state": "MI",
      "county": "Arenac"
  },
  {
      "zipCode": 48750,
      "latitude": 44.422917,
      "longitude": -83.550866,
      "city": "Oscoda",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48754,
      "latitude": 43.768927,
      "longitude": -83.228252,
      "city": "Owendale",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48755,
      "latitude": 43.84452,
      "longitude": -83.241886,
      "city": "Pigeon",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48756,
      "latitude": 44.291742,
      "longitude": -83.985725,
      "city": "Prescott",
      "state": "MI",
      "county": "Ogemaw"
  },
  {
      "zipCode": 48757,
      "latitude": 43.478735,
      "longitude": -83.619435,
      "city": "Reese",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48758,
      "latitude": 43.407321,
      "longitude": -83.676156,
      "city": "Richville",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48759,
      "latitude": 43.73616,
      "longitude": -83.381857,
      "city": "Sebewaing",
      "state": "MI",
      "county": "Huron"
  },
  {
      "zipCode": 48760,
      "latitude": 43.285214,
      "longitude": -83.261468,
      "city": "Silverwood",
      "state": "MI",
      "county": "Lapeer"
  },
  {
      "zipCode": 48761,
      "latitude": 44.472352,
      "longitude": -83.959844,
      "city": "South Branch",
      "state": "MI",
      "county": "Ogemaw"
  },
  {
      "zipCode": 48762,
      "latitude": 44.792293,
      "longitude": -83.489404,
      "city": "Spruce",
      "state": "MI",
      "county": "Alcona"
  },
  {
      "zipCode": 48763,
      "latitude": 44.277551,
      "longitude": -83.677131,
      "city": "Tawas City",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48764,
      "latitude": 44.266549,
      "longitude": -83.519166,
      "city": "Tawas City",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48765,
      "latitude": 44.110526,
      "longitude": -83.701888,
      "city": "Turner",
      "state": "MI",
      "county": "Arenac"
  },
  {
      "zipCode": 48766,
      "latitude": 44.104905,
      "longitude": -83.865674,
      "city": "Twining",
      "state": "MI",
      "county": "Arenac"
  },
  {
      "zipCode": 48767,
      "latitude": 43.649504,
      "longitude": -83.452826,
      "city": "Unionville",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48768,
      "latitude": 43.404594,
      "longitude": -83.561187,
      "city": "Vassar",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48769,
      "latitude": 43.327048,
      "longitude": -83.657355,
      "city": "Tuscola",
      "state": "MI",
      "county": "Tuscola"
  },
  {
      "zipCode": 48770,
      "latitude": 44.262826,
      "longitude": -83.688778,
      "city": "Whittemore",
      "state": "MI",
      "county": "Iosco"
  },
  {
      "zipCode": 48787,
      "latitude": 43.334958,
      "longitude": -83.749418,
      "city": "Frankenmuth",
      "state": "MI",
      "county": "Saginaw"
  },
  {
      "zipCode": 48801,
      "latitude": 43.298805,
      "longitude": -84.63017,
      "city": "Alma",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48802,
      "latitude": 43.389262,
      "longitude": -84.666661,
      "city": "Alma",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48804,
      "latitude": 43.640574,
      "longitude": -84.847417,
      "city": "Mount Pleasant",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 48805,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Okemos",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48806,
      "latitude": 43.204648,
      "longitude": -84.494123,
      "city": "Ashley",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48807,
      "latitude": 43.161282,
      "longitude": -84.433708,
      "city": "Bannister",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48808,
      "latitude": 42.819509,
      "longitude": -84.483853,
      "city": "Bath",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48809,
      "latitude": 42.98004,
      "longitude": -85.128727,
      "city": "Belding",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48811,
      "latitude": 43.279958,
      "longitude": -85.05493,
      "city": "Carson City",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48812,
      "latitude": 43.413761,
      "longitude": -84.975271,
      "city": "Cedar Lake",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48813,
      "latitude": 42.574191,
      "longitude": -84.838452,
      "city": "Charlotte",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48815,
      "latitude": 42.835039,
      "longitude": -85.252353,
      "city": "Clarksville",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48816,
      "latitude": 42.757633,
      "longitude": -83.938967,
      "city": "Cohoctah",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48817,
      "latitude": 42.995625,
      "longitude": -84.140012,
      "city": "Corunna",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48818,
      "latitude": 43.267035,
      "longitude": -84.95489,
      "city": "Crystal",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48819,
      "latitude": 42.549235,
      "longitude": -84.273815,
      "city": "Dansville",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48820,
      "latitude": 42.85479,
      "longitude": -84.616108,
      "city": "Dewitt",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48821,
      "latitude": 42.654335,
      "longitude": -84.654181,
      "city": "Dimondale",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48822,
      "latitude": 42.85703,
      "longitude": -84.759243,
      "city": "Eagle",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48823,
      "latitude": 42.737334,
      "longitude": -84.503527,
      "city": "East Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48824,
      "latitude": 42.728334,
      "longitude": -84.488226,
      "city": "East Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48825,
      "latitude": 42.723784,
      "longitude": -84.464775,
      "city": "East Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48826,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "East Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48827,
      "latitude": 42.590246,
      "longitude": -84.797012,
      "city": "Eaton Rapids",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48829,
      "latitude": 43.401096,
      "longitude": -85.022308,
      "city": "Edmore",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48830,
      "latitude": 43.363931,
      "longitude": -84.836198,
      "city": "Elm Hall",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48831,
      "latitude": 43.08143,
      "longitude": -84.445584,
      "city": "Elsie",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48832,
      "latitude": 43.408141,
      "longitude": -84.775817,
      "city": "Elwell",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48833,
      "latitude": 42.944209,
      "longitude": -84.600723,
      "city": "Eureka",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48834,
      "latitude": 43.292973,
      "longitude": -85.045217,
      "city": "Fenwick",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48835,
      "latitude": 42.993295,
      "longitude": -84.761244,
      "city": "Fowler",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48836,
      "latitude": 42.641649,
      "longitude": -84.054241,
      "city": "Fowlerville",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48837,
      "latitude": 42.700035,
      "longitude": -84.766787,
      "city": "Grand Ledge",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48838,
      "latitude": 43.282538,
      "longitude": -85.077695,
      "city": "Greenville",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48840,
      "latitude": 42.750324,
      "longitude": -84.378655,
      "city": "Haslett",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48841,
      "latitude": 42.983136,
      "longitude": -84.229888,
      "city": "Henderson",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48842,
      "latitude": 42.639785,
      "longitude": -84.506522,
      "city": "Holt",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48843,
      "latitude": 42.630687,
      "longitude": -83.910266,
      "city": "Howell",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48844,
      "latitude": 42.603479,
      "longitude": -83.911173,
      "city": "Howell",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48845,
      "latitude": 43.090457,
      "longitude": -84.896225,
      "city": "Hubbardston",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48846,
      "latitude": 42.973427,
      "longitude": -85.090548,
      "city": "Ionia",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48847,
      "latitude": 43.249453,
      "longitude": -84.60379,
      "city": "Ithaca",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48848,
      "latitude": 42.879874,
      "longitude": -84.313294,
      "city": "Laingsburg",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48849,
      "latitude": 42.827728,
      "longitude": -85.143286,
      "city": "Lake Odessa",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48850,
      "latitude": 43.398672,
      "longitude": -85.175752,
      "city": "Lakeview",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48851,
      "latitude": 42.956819,
      "longitude": -84.958071,
      "city": "Lyons",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48852,
      "latitude": 43.350251,
      "longitude": -85.051692,
      "city": "Mcbrides",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48853,
      "latitude": 43.099153,
      "longitude": -84.68977,
      "city": "Maple Rapids",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48854,
      "latitude": 42.574335,
      "longitude": -84.460273,
      "city": "Mason",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48856,
      "latitude": 43.197902,
      "longitude": -84.730738,
      "city": "Middleton",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48857,
      "latitude": 42.844288,
      "longitude": -84.136097,
      "city": "Morrice",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48858,
      "latitude": 43.636855,
      "longitude": -84.807511,
      "city": "Mount Pleasant",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 48859,
      "latitude": 43.564736,
      "longitude": -84.847297,
      "city": "Mount Pleasant",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 48860,
      "latitude": 43.046851,
      "longitude": -84.900482,
      "city": "Muir",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48861,
      "latitude": 42.724229,
      "longitude": -84.916222,
      "city": "Mulliken",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48862,
      "latitude": 43.356518,
      "longitude": -84.497138,
      "city": "North Star",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48863,
      "latitude": 42.603479,
      "longitude": -83.911173,
      "city": "Oak Grove",
      "state": "MI",
      "county": "Livingston"
  },
  {
      "zipCode": 48864,
      "latitude": 42.719534,
      "longitude": -84.425136,
      "city": "Okemos",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48865,
      "latitude": 43.078789,
      "longitude": -85.102387,
      "city": "Orleans",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48866,
      "latitude": 43.015906,
      "longitude": -84.52884,
      "city": "Ovid",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48867,
      "latitude": 42.982578,
      "longitude": -84.184937,
      "city": "Owosso",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48870,
      "latitude": 43.113587,
      "longitude": -85.007665,
      "city": "Palo",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48871,
      "latitude": 43.162586,
      "longitude": -84.679727,
      "city": "Perrinton",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48872,
      "latitude": 42.823317,
      "longitude": -84.211149,
      "city": "Perry",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48873,
      "latitude": 42.970044,
      "longitude": -84.916118,
      "city": "Pewamo",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48874,
      "latitude": 43.186268,
      "longitude": -84.601718,
      "city": "Pompeii",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48875,
      "latitude": 42.883458,
      "longitude": -84.965969,
      "city": "Portland",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48876,
      "latitude": 42.643807,
      "longitude": -84.729716,
      "city": "Potterville",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48877,
      "latitude": 43.382803,
      "longitude": -84.816107,
      "city": "Riverdale",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48878,
      "latitude": 43.709243,
      "longitude": -84.770931,
      "city": "Rosebush",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 48879,
      "latitude": 42.979673,
      "longitude": -84.581962,
      "city": "Saint Johns",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48880,
      "latitude": 43.324772,
      "longitude": -84.603002,
      "city": "Saint Louis",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48881,
      "latitude": 42.93571,
      "longitude": -85.20298,
      "city": "Saranac",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48882,
      "latitude": 42.803973,
      "longitude": -84.295949,
      "city": "Shaftsburg",
      "state": "MI",
      "county": "Shiawassee"
  },
  {
      "zipCode": 48883,
      "latitude": 43.530865,
      "longitude": -84.756824,
      "city": "Shepherd",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 48884,
      "latitude": 43.206319,
      "longitude": -85.069087,
      "city": "Sheridan",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48885,
      "latitude": 43.235402,
      "longitude": -85.191444,
      "city": "Sidney",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48886,
      "latitude": 43.341346,
      "longitude": -85.177052,
      "city": "Six Lakes",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48887,
      "latitude": 42.944929,
      "longitude": -85.074665,
      "city": "Smyrna",
      "state": "MI",
      "county": "Ionia"
  },
  {
      "zipCode": 48888,
      "latitude": 43.289347,
      "longitude": -85.059556,
      "city": "Stanton",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48889,
      "latitude": 43.26895,
      "longitude": -84.660821,
      "city": "Sumner",
      "state": "MI",
      "county": "Gratiot"
  },
  {
      "zipCode": 48890,
      "latitude": 42.703177,
      "longitude": -84.896833,
      "city": "Sunfield",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48891,
      "latitude": 43.294643,
      "longitude": -84.955903,
      "city": "Vestaburg",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 48892,
      "latitude": 42.656484,
      "longitude": -84.200923,
      "city": "Webberville",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48893,
      "latitude": 43.67589,
      "longitude": -84.977751,
      "city": "Weidman",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 48894,
      "latitude": 42.932843,
      "longitude": -84.808267,
      "city": "Westphalia",
      "state": "MI",
      "county": "Clinton"
  },
  {
      "zipCode": 48895,
      "latitude": 42.680434,
      "longitude": -84.271215,
      "city": "Williamston",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48896,
      "latitude": 43.530832,
      "longitude": -84.906289,
      "city": "Winn",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 48897,
      "latitude": 42.697951,
      "longitude": -85.133685,
      "city": "Woodland",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 48901,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48906,
      "latitude": 42.755284,
      "longitude": -84.562979,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48907,
      "latitude": 42.596071,
      "longitude": -84.838206,
      "city": "Lansing",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48908,
      "latitude": 42.596071,
      "longitude": -84.838206,
      "city": "Lansing",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48909,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48910,
      "latitude": 42.703435,
      "longitude": -84.518543,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48911,
      "latitude": 42.680335,
      "longitude": -84.543494,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48912,
      "latitude": 42.740054,
      "longitude": -84.524939,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48913,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48915,
      "latitude": 42.738764,
      "longitude": -84.571179,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48916,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48917,
      "latitude": 42.722414,
      "longitude": -84.652032,
      "city": "Lansing",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 48918,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48919,
      "latitude": 42.728585,
      "longitude": -84.551728,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48921,
      "latitude": 42.723735,
      "longitude": -84.555629,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48922,
      "latitude": 42.732535,
      "longitude": -84.558679,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48924,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48929,
      "latitude": 42.732535,
      "longitude": -84.558679,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48930,
      "latitude": 42.732535,
      "longitude": -84.558679,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48933,
      "latitude": 42.731384,
      "longitude": -84.553698,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48937,
      "latitude": 42.748734,
      "longitude": -84.559029,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48950,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48951,
      "latitude": 42.775979,
      "longitude": -84.605066,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48956,
      "latitude": 42.732535,
      "longitude": -84.558679,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 48980,
      "latitude": 42.599184,
      "longitude": -84.371973,
      "city": "Lansing",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 49001,
      "latitude": 42.261596,
      "longitude": -85.510095,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49002,
      "latitude": 42.193793,
      "longitude": -85.563901,
      "city": "Portage",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49003,
      "latitude": 42.245412,
      "longitude": -85.529858,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49004,
      "latitude": 42.306859,
      "longitude": -85.56816,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49005,
      "latitude": 42.323045,
      "longitude": -85.493232,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49006,
      "latitude": 42.292247,
      "longitude": -85.633033,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49007,
      "latitude": 42.242774,
      "longitude": -85.560265,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49008,
      "latitude": 42.263007,
      "longitude": -85.618046,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49009,
      "latitude": 42.299211,
      "longitude": -85.592635,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49010,
      "latitude": 42.539295,
      "longitude": -85.894873,
      "city": "Allegan",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49011,
      "latitude": 42.119031,
      "longitude": -85.214348,
      "city": "Athens",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49012,
      "latitude": 42.353739,
      "longitude": -85.346599,
      "city": "Augusta",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49013,
      "latitude": 42.312771,
      "longitude": -86.096133,
      "city": "Bangor",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49014,
      "latitude": 42.303041,
      "longitude": -85.13044,
      "city": "Battle Creek",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49015,
      "latitude": 42.219313,
      "longitude": -85.229238,
      "city": "Battle Creek",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49016,
      "latitude": 42.246402,
      "longitude": -85.004511,
      "city": "Battle Creek",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49017,
      "latitude": 42.367389,
      "longitude": -85.194996,
      "city": "Battle Creek",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49018,
      "latitude": 42.246402,
      "longitude": -85.004511,
      "city": "Battle Creek",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49019,
      "latitude": 42.245412,
      "longitude": -85.529858,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49020,
      "latitude": 42.246402,
      "longitude": -85.004511,
      "city": "Bedford",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49021,
      "latitude": 42.514158,
      "longitude": -85.014665,
      "city": "Bellevue",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 49022,
      "latitude": 42.14338,
      "longitude": -86.348568,
      "city": "Benton Harbor",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49023,
      "latitude": 42.001551,
      "longitude": -86.715294,
      "city": "Benton Harbor",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49024,
      "latitude": 42.197442,
      "longitude": -85.619352,
      "city": "Portage",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49026,
      "latitude": 42.367738,
      "longitude": -85.964714,
      "city": "Bloomingdale",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49027,
      "latitude": 42.346504,
      "longitude": -86.072501,
      "city": "Breedsville",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49028,
      "latitude": 41.880399,
      "longitude": -85.162804,
      "city": "Bronson",
      "state": "MI",
      "county": "Branch"
  },
  {
      "zipCode": 49029,
      "latitude": 42.130843,
      "longitude": -85.1023,
      "city": "Burlington",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49030,
      "latitude": 41.839785,
      "longitude": -85.365433,
      "city": "Burr Oak",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49031,
      "latitude": 41.904174,
      "longitude": -85.999067,
      "city": "Cassopolis",
      "state": "MI",
      "county": "Cass"
  },
  {
      "zipCode": 49032,
      "latitude": 41.904488,
      "longitude": -85.479907,
      "city": "Centreville",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49033,
      "latitude": 42.235692,
      "longitude": -85.090633,
      "city": "Ceresco",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49034,
      "latitude": 42.226193,
      "longitude": -85.363194,
      "city": "Climax",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49035,
      "latitude": 42.595121,
      "longitude": -85.308555,
      "city": "Cloverdale",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49036,
      "latitude": 41.905358,
      "longitude": -85.058716,
      "city": "Coldwater",
      "state": "MI",
      "county": "Branch"
  },
  {
      "zipCode": 49038,
      "latitude": 42.147551,
      "longitude": -86.365588,
      "city": "Coloma",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49039,
      "latitude": 42.224757,
      "longitude": -86.372276,
      "city": "Hagar Shores",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49040,
      "latitude": 41.977796,
      "longitude": -85.351074,
      "city": "Colon",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49041,
      "latitude": 42.245412,
      "longitude": -85.529858,
      "city": "Comstock",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49042,
      "latitude": 41.936948,
      "longitude": -85.533122,
      "city": "Constantine",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49043,
      "latitude": 42.28054,
      "longitude": -86.266608,
      "city": "Covert",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49045,
      "latitude": 42.174485,
      "longitude": -86.071429,
      "city": "Decatur",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49046,
      "latitude": 42.532723,
      "longitude": -85.384885,
      "city": "Delton",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49047,
      "latitude": 41.961597,
      "longitude": -86.019304,
      "city": "Dowagiac",
      "state": "MI",
      "county": "Cass"
  },
  {
      "zipCode": 49048,
      "latitude": 42.274268,
      "longitude": -85.493081,
      "city": "Kalamazoo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49050,
      "latitude": 42.605588,
      "longitude": -85.339902,
      "city": "Dowling",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49051,
      "latitude": 42.1779,
      "longitude": -85.234287,
      "city": "East Leroy",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49052,
      "latitude": 42.13344,
      "longitude": -85.342481,
      "city": "Fulton",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49053,
      "latitude": 42.303241,
      "longitude": -85.395798,
      "city": "Galesburg",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49055,
      "latitude": 42.357819,
      "longitude": -85.859198,
      "city": "Gobles",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49056,
      "latitude": 42.367516,
      "longitude": -86.07133,
      "city": "Grand Junction",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49057,
      "latitude": 42.218519,
      "longitude": -86.052434,
      "city": "Hartford",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49058,
      "latitude": 42.639121,
      "longitude": -85.28988,
      "city": "Hastings",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49060,
      "latitude": 42.500132,
      "longitude": -85.396204,
      "city": "Hickory Corners",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49061,
      "latitude": 41.885831,
      "longitude": -85.833847,
      "city": "Jones",
      "state": "MI",
      "county": "Cass"
  },
  {
      "zipCode": 49062,
      "latitude": 42.36175,
      "longitude": -85.814072,
      "city": "Kendall",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49063,
      "latitude": 42.393586,
      "longitude": -86.209904,
      "city": "Lacota",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49064,
      "latitude": 42.214896,
      "longitude": -86.047077,
      "city": "Lawrence",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49065,
      "latitude": 42.185207,
      "longitude": -86.011811,
      "city": "Lawton",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49066,
      "latitude": 42.028161,
      "longitude": -85.35224,
      "city": "Leonidas",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49067,
      "latitude": 42.008635,
      "longitude": -85.857579,
      "city": "Marcellus",
      "state": "MI",
      "county": "Cass"
  },
  {
      "zipCode": 49068,
      "latitude": 42.265016,
      "longitude": -84.948946,
      "city": "Marshall",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49069,
      "latitude": 42.204494,
      "longitude": -84.949919,
      "city": "Marshall",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49070,
      "latitude": 42.537142,
      "longitude": -85.626918,
      "city": "Martin",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49071,
      "latitude": 42.244501,
      "longitude": -85.805041,
      "city": "Mattawan",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49072,
      "latitude": 42.009094,
      "longitude": -85.449665,
      "city": "Mendon",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49073,
      "latitude": 42.621104,
      "longitude": -85.197412,
      "city": "Nashville",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49074,
      "latitude": 42.245412,
      "longitude": -85.529858,
      "city": "Nazareth",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49075,
      "latitude": 41.918535,
      "longitude": -85.452884,
      "city": "Nottawa",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49076,
      "latitude": 42.559717,
      "longitude": -84.922599,
      "city": "Olivet",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 49077,
      "latitude": 42.245412,
      "longitude": -85.529858,
      "city": "Oshtemo",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49078,
      "latitude": 42.486075,
      "longitude": -85.724612,
      "city": "Otsego",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49079,
      "latitude": 42.243011,
      "longitude": -85.930846,
      "city": "Paw Paw",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49080,
      "latitude": 42.479577,
      "longitude": -85.633538,
      "city": "Plainwell",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49081,
      "latitude": 42.171777,
      "longitude": -85.617824,
      "city": "Portage",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49082,
      "latitude": 41.949119,
      "longitude": -85.032201,
      "city": "Quincy",
      "state": "MI",
      "county": "Branch"
  },
  {
      "zipCode": 49083,
      "latitude": 42.376138,
      "longitude": -85.454755,
      "city": "Richland",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49084,
      "latitude": 42.178035,
      "longitude": -86.38557,
      "city": "Riverside",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49085,
      "latitude": 41.977412,
      "longitude": -86.533144,
      "city": "Saint Joseph",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49087,
      "latitude": 42.135078,
      "longitude": -85.656846,
      "city": "Schoolcraft",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49088,
      "latitude": 42.254992,
      "longitude": -85.424395,
      "city": "Scotts",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49089,
      "latitude": 42.000564,
      "longitude": -85.225195,
      "city": "Sherwood",
      "state": "MI",
      "county": "Branch"
  },
  {
      "zipCode": 49090,
      "latitude": 42.363778,
      "longitude": -86.138952,
      "city": "South Haven",
      "state": "MI",
      "county": "Van Buren"
  },
  {
      "zipCode": 49091,
      "latitude": 41.842913,
      "longitude": -85.478265,
      "city": "Sturgis",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49092,
      "latitude": 42.13422,
      "longitude": -84.972831,
      "city": "Tekonsha",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49093,
      "latitude": 41.928972,
      "longitude": -85.538215,
      "city": "Three Rivers",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49094,
      "latitude": 42.025271,
      "longitude": -85.078799,
      "city": "Union City",
      "state": "MI",
      "county": "Branch"
  },
  {
      "zipCode": 49095,
      "latitude": 41.936454,
      "longitude": -85.885793,
      "city": "Vandalia",
      "state": "MI",
      "county": "Cass"
  },
  {
      "zipCode": 49096,
      "latitude": 42.639932,
      "longitude": -85.004661,
      "city": "Vermontville",
      "state": "MI",
      "county": "Eaton"
  },
  {
      "zipCode": 49097,
      "latitude": 42.17954,
      "longitude": -85.473371,
      "city": "Vicksburg",
      "state": "MI",
      "county": "Kalamazoo"
  },
  {
      "zipCode": 49098,
      "latitude": 42.161397,
      "longitude": -86.271878,
      "city": "Watervliet",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49099,
      "latitude": 41.859335,
      "longitude": -85.553193,
      "city": "White Pigeon",
      "state": "MI",
      "county": "Saint Joseph"
  },
  {
      "zipCode": 49101,
      "latitude": 41.949647,
      "longitude": -86.475504,
      "city": "Baroda",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49102,
      "latitude": 41.954986,
      "longitude": -86.2681,
      "city": "Berrien Center",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49103,
      "latitude": 41.907363,
      "longitude": -86.472582,
      "city": "Berrien Springs",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49104,
      "latitude": 42.001551,
      "longitude": -86.715294,
      "city": "Berrien Springs",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49106,
      "latitude": 41.942151,
      "longitude": -86.544561,
      "city": "Bridgman",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49107,
      "latitude": 41.841369,
      "longitude": -86.429249,
      "city": "Buchanan",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49111,
      "latitude": 42.027591,
      "longitude": -86.299153,
      "city": "Eau Claire",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49112,
      "latitude": 41.807011,
      "longitude": -85.997234,
      "city": "Edwardsburg",
      "state": "MI",
      "county": "Cass"
  },
  {
      "zipCode": 49113,
      "latitude": 41.81146,
      "longitude": -86.499237,
      "city": "Galien",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49115,
      "latitude": 41.877103,
      "longitude": -86.630243,
      "city": "Harbert",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49116,
      "latitude": 41.833625,
      "longitude": -86.662174,
      "city": "Lakeside",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49117,
      "latitude": 41.79273,
      "longitude": -86.746022,
      "city": "New Buffalo",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49119,
      "latitude": 41.868746,
      "longitude": -86.547369,
      "city": "New Troy",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49120,
      "latitude": 41.844732,
      "longitude": -86.421005,
      "city": "Niles",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49121,
      "latitude": 42.001551,
      "longitude": -86.715294,
      "city": "Niles",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49125,
      "latitude": 41.899733,
      "longitude": -86.571325,
      "city": "Sawyer",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49126,
      "latitude": 42.029487,
      "longitude": -86.378199,
      "city": "Sodus",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49127,
      "latitude": 42.00743,
      "longitude": -86.508146,
      "city": "Stevensville",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49128,
      "latitude": 41.827553,
      "longitude": -86.600498,
      "city": "Three Oaks",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49129,
      "latitude": 41.825532,
      "longitude": -86.684816,
      "city": "Union Pier",
      "state": "MI",
      "county": "Berrien"
  },
  {
      "zipCode": 49130,
      "latitude": 41.800617,
      "longitude": -85.866011,
      "city": "Union",
      "state": "MI",
      "county": "Cass"
  },
  {
      "zipCode": 49201,
      "latitude": 42.248979,
      "longitude": -84.421104,
      "city": "Jackson",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49202,
      "latitude": 42.275635,
      "longitude": -84.398207,
      "city": "Jackson",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49203,
      "latitude": 42.186861,
      "longitude": -84.46471,
      "city": "Jackson",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49204,
      "latitude": 42.252847,
      "longitude": -84.213755,
      "city": "Jackson",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49220,
      "latitude": 41.985346,
      "longitude": -84.314543,
      "city": "Addison",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49221,
      "latitude": 41.889943,
      "longitude": -84.065892,
      "city": "Adrian",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49224,
      "latitude": 42.2906,
      "longitude": -84.792885,
      "city": "Albion",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49227,
      "latitude": 41.956346,
      "longitude": -84.767913,
      "city": "Allen",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49228,
      "latitude": 41.852645,
      "longitude": -83.873899,
      "city": "Blissfield",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49229,
      "latitude": 41.997944,
      "longitude": -83.857925,
      "city": "Britton",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49230,
      "latitude": 42.160388,
      "longitude": -84.388821,
      "city": "Brooklyn",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49232,
      "latitude": 41.747377,
      "longitude": -84.638542,
      "city": "Camden",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49233,
      "latitude": 42.048091,
      "longitude": -84.315009,
      "city": "Cement City",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49234,
      "latitude": 42.119607,
      "longitude": -84.369085,
      "city": "Clarklake",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49235,
      "latitude": 41.930577,
      "longitude": -84.156209,
      "city": "Clayton",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49236,
      "latitude": 42.061881,
      "longitude": -83.957586,
      "city": "Clinton",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49237,
      "latitude": 42.186022,
      "longitude": -84.641897,
      "city": "Concord",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49238,
      "latitude": 41.900279,
      "longitude": -83.817849,
      "city": "Deerfield",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49239,
      "latitude": 41.782499,
      "longitude": -84.604662,
      "city": "Frontier",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49240,
      "latitude": 42.258533,
      "longitude": -84.344285,
      "city": "Grass Lake",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49241,
      "latitude": 42.111258,
      "longitude": -84.601588,
      "city": "Hanover",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49242,
      "latitude": 41.853903,
      "longitude": -84.582913,
      "city": "Hillsdale",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49245,
      "latitude": 42.144355,
      "longitude": -84.848182,
      "city": "Homer",
      "state": "MI",
      "county": "Calhoun"
  },
  {
      "zipCode": 49246,
      "latitude": 42.131129,
      "longitude": -84.479081,
      "city": "Horton",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49247,
      "latitude": 41.870383,
      "longitude": -84.095955,
      "city": "Hudson",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49248,
      "latitude": 41.770362,
      "longitude": -84.001125,
      "city": "Jasper",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49249,
      "latitude": 42.029587,
      "longitude": -84.468662,
      "city": "Jerome",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49250,
      "latitude": 41.898745,
      "longitude": -84.585577,
      "city": "Jonesville",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49251,
      "latitude": 42.478186,
      "longitude": -84.407118,
      "city": "Leslie",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 49252,
      "latitude": 42.029101,
      "longitude": -84.619228,
      "city": "Litchfield",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49253,
      "latitude": 41.970384,
      "longitude": -84.270369,
      "city": "Manitou Beach",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49254,
      "latitude": 42.208747,
      "longitude": -84.465922,
      "city": "Michigan Center",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49255,
      "latitude": 41.792652,
      "longitude": -84.900917,
      "city": "Montgomery",
      "state": "MI",
      "county": "Branch"
  },
  {
      "zipCode": 49256,
      "latitude": 41.84736,
      "longitude": -84.229012,
      "city": "Morenci",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49257,
      "latitude": 42.054795,
      "longitude": -84.503905,
      "city": "Moscow",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49258,
      "latitude": 41.884795,
      "longitude": -84.593491,
      "city": "Mosherville",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49259,
      "latitude": 42.360385,
      "longitude": -84.233087,
      "city": "Munith",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49261,
      "latitude": 42.164338,
      "longitude": -84.24575,
      "city": "Napoleon",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49262,
      "latitude": 41.963943,
      "longitude": -84.473084,
      "city": "North Adams",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49263,
      "latitude": 42.158189,
      "longitude": -84.183828,
      "city": "Norvell",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49264,
      "latitude": 42.472836,
      "longitude": -84.541672,
      "city": "Onondaga",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 49265,
      "latitude": 42.007537,
      "longitude": -84.182082,
      "city": "Onsted",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49266,
      "latitude": 41.832935,
      "longitude": -84.548539,
      "city": "Osseo",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49267,
      "latitude": 41.772724,
      "longitude": -83.704835,
      "city": "Ottawa Lake",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 49268,
      "latitude": 41.86957,
      "longitude": -83.920024,
      "city": "Palmyra",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49269,
      "latitude": 42.257902,
      "longitude": -84.580681,
      "city": "Parma",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49270,
      "latitude": 41.865026,
      "longitude": -83.670689,
      "city": "Petersburg",
      "state": "MI",
      "county": "Monroe"
  },
  {
      "zipCode": 49271,
      "latitude": 41.854948,
      "longitude": -84.452585,
      "city": "Pittsford",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49272,
      "latitude": 42.387785,
      "longitude": -84.347636,
      "city": "Pleasant Lake",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49274,
      "latitude": 41.846897,
      "longitude": -84.728441,
      "city": "Reading",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49275,
      "latitude": 41.988026,
      "longitude": -83.865674,
      "city": "Ridgeway",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49276,
      "latitude": 41.791518,
      "longitude": -83.814189,
      "city": "Riga",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49277,
      "latitude": 42.384636,
      "longitude": -84.465133,
      "city": "Rives Junction",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49278,
      "latitude": 41.912292,
      "longitude": -84.32652,
      "city": "Rollin",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49279,
      "latitude": 41.775793,
      "longitude": -84.112125,
      "city": "Sand Creek",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49280,
      "latitude": 41.79583,
      "longitude": -84.186608,
      "city": "Seneca",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49281,
      "latitude": 42.023026,
      "longitude": -84.379391,
      "city": "Somerset",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49282,
      "latitude": 42.050199,
      "longitude": -84.392191,
      "city": "Somerset Center",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49283,
      "latitude": 42.272863,
      "longitude": -84.605612,
      "city": "Spring Arbor",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49284,
      "latitude": 42.37823,
      "longitude": -84.630106,
      "city": "Springport",
      "state": "MI",
      "county": "Jackson"
  },
  {
      "zipCode": 49285,
      "latitude": 42.496734,
      "longitude": -84.24306,
      "city": "Stockbridge",
      "state": "MI",
      "county": "Ingham"
  },
  {
      "zipCode": 49286,
      "latitude": 42.008398,
      "longitude": -83.922214,
      "city": "Tecumseh",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49287,
      "latitude": 42.02172,
      "longitude": -84.064971,
      "city": "Tipton",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49288,
      "latitude": 41.743114,
      "longitude": -84.447488,
      "city": "Waldron",
      "state": "MI",
      "county": "Hillsdale"
  },
  {
      "zipCode": 49289,
      "latitude": 41.768254,
      "longitude": -84.107609,
      "city": "Weston",
      "state": "MI",
      "county": "Lenawee"
  },
  {
      "zipCode": 49301,
      "latitude": 42.963833,
      "longitude": -85.482266,
      "city": "Ada",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49302,
      "latitude": 42.856096,
      "longitude": -85.402859,
      "city": "Alto",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49303,
      "latitude": 43.272182,
      "longitude": -85.854471,
      "city": "Bailey",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49304,
      "latitude": 43.929225,
      "longitude": -85.840774,
      "city": "Baldwin",
      "state": "MI",
      "county": "Lake"
  },
  {
      "zipCode": 49305,
      "latitude": 43.737488,
      "longitude": -85.166327,
      "city": "Barryton",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49306,
      "latitude": 43.074996,
      "longitude": -85.563211,
      "city": "Belmont",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49307,
      "latitude": 43.683493,
      "longitude": -85.3518,
      "city": "Big Rapids",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49309,
      "latitude": 43.712217,
      "longitude": -85.860379,
      "city": "Bitely",
      "state": "MI",
      "county": "Newaygo"
  },
  {
      "zipCode": 49310,
      "latitude": 43.552836,
      "longitude": -84.966988,
      "city": "Blanchard",
      "state": "MI",
      "county": "Isabella"
  },
  {
      "zipCode": 49311,
      "latitude": 42.633006,
      "longitude": -85.643043,
      "city": "Bradley",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49312,
      "latitude": 43.666006,
      "longitude": -85.791055,
      "city": "Brohman",
      "state": "MI",
      "county": "Newaygo"
  },
  {
      "zipCode": 49314,
      "latitude": 42.731193,
      "longitude": -85.840372,
      "city": "Burnips",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49315,
      "latitude": 42.835828,
      "longitude": -85.664425,
      "city": "Byron Center",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49316,
      "latitude": 42.81868,
      "longitude": -85.524437,
      "city": "Caledonia",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49317,
      "latitude": 43.071201,
      "longitude": -85.480856,
      "city": "Cannonsburg",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49318,
      "latitude": 43.234658,
      "longitude": -85.840115,
      "city": "Casnovia",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49319,
      "latitude": 43.175953,
      "longitude": -85.514105,
      "city": "Cedar Springs",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49320,
      "latitude": 43.755335,
      "longitude": -85.278303,
      "city": "Chippewa Lake",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49321,
      "latitude": 43.081164,
      "longitude": -85.679652,
      "city": "Comstock Park",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49322,
      "latitude": 43.359517,
      "longitude": -85.353161,
      "city": "Coral",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 49323,
      "latitude": 42.721585,
      "longitude": -85.788666,
      "city": "Dorr",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49325,
      "latitude": 42.738867,
      "longitude": -85.364444,
      "city": "Freeport",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49326,
      "latitude": 43.310955,
      "longitude": -85.21072,
      "city": "Gowen",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 49327,
      "latitude": 43.539032,
      "longitude": -85.850409,
      "city": "Grant",
      "state": "MI",
      "county": "Newaygo"
  },
  {
      "zipCode": 49328,
      "latitude": 42.623664,
      "longitude": -85.780339,
      "city": "Hopkins",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49329,
      "latitude": 43.382007,
      "longitude": -85.442836,
      "city": "Howard City",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 49330,
      "latitude": 43.23506,
      "longitude": -85.670248,
      "city": "Kent City",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49331,
      "latitude": 42.969378,
      "longitude": -85.384134,
      "city": "Lowell",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49332,
      "latitude": 43.60077,
      "longitude": -85.31329,
      "city": "Mecosta",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49333,
      "latitude": 42.670351,
      "longitude": -85.427281,
      "city": "Middleville",
      "state": "MI",
      "county": "Barry"
  },
  {
      "zipCode": 49335,
      "latitude": 42.736987,
      "longitude": -85.663538,
      "city": "Moline",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49336,
      "latitude": 43.52199,
      "longitude": -85.423262,
      "city": "Morley",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49337,
      "latitude": 43.444927,
      "longitude": -85.73898,
      "city": "Newaygo",
      "state": "MI",
      "county": "Newaygo"
  },
  {
      "zipCode": 49338,
      "latitude": 43.72777,
      "longitude": -85.365385,
      "city": "Paris",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49339,
      "latitude": 43.368549,
      "longitude": -85.473124,
      "city": "Pierson",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 49340,
      "latitude": 43.637851,
      "longitude": -85.198945,
      "city": "Remus",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49341,
      "latitude": 43.132429,
      "longitude": -85.551631,
      "city": "Rockford",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49342,
      "latitude": 43.691187,
      "longitude": -85.318534,
      "city": "Rodney",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49343,
      "latitude": 43.258296,
      "longitude": -85.504509,
      "city": "Sand Lake",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49344,
      "latitude": 42.612772,
      "longitude": -85.612828,
      "city": "Shelbyville",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49345,
      "latitude": 43.157685,
      "longitude": -85.683555,
      "city": "Sparta",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49346,
      "latitude": 43.590861,
      "longitude": -85.403676,
      "city": "Stanwood",
      "state": "MI",
      "county": "Mecosta"
  },
  {
      "zipCode": 49347,
      "latitude": 43.330898,
      "longitude": -85.414422,
      "city": "Trufant",
      "state": "MI",
      "county": "Montcalm"
  },
  {
      "zipCode": 49348,
      "latitude": 42.685065,
      "longitude": -85.642406,
      "city": "Wayland",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49349,
      "latitude": 43.611483,
      "longitude": -85.797989,
      "city": "White Cloud",
      "state": "MI",
      "county": "Newaygo"
  },
  {
      "zipCode": 49351,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Rockford",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49355,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Ada",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49356,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Ada",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49357,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Ada",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49401,
      "latitude": 42.979522,
      "longitude": -85.93927,
      "city": "Allendale",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49402,
      "latitude": 43.967633,
      "longitude": -85.980028,
      "city": "Branch",
      "state": "MI",
      "county": "Lake"
  },
  {
      "zipCode": 49403,
      "latitude": 43.118172,
      "longitude": -85.848391,
      "city": "Conklin",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49404,
      "latitude": 43.062122,
      "longitude": -85.959758,
      "city": "Coopersville",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49405,
      "latitude": 43.932434,
      "longitude": -86.177683,
      "city": "Custer",
      "state": "MI",
      "county": "Mason"
  },
  {
      "zipCode": 49406,
      "latitude": 42.636883,
      "longitude": -86.202158,
      "city": "Douglas",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49408,
      "latitude": 42.542809,
      "longitude": -86.11849,
      "city": "Fennville",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49409,
      "latitude": 43.080865,
      "longitude": -86.215413,
      "city": "Ferrysburg",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49410,
      "latitude": 44.030126,
      "longitude": -86.142483,
      "city": "Fountain",
      "state": "MI",
      "county": "Mason"
  },
  {
      "zipCode": 49411,
      "latitude": 44.071924,
      "longitude": -86.252729,
      "city": "Free Soil",
      "state": "MI",
      "county": "Mason"
  },
  {
      "zipCode": 49412,
      "latitude": 43.513456,
      "longitude": -85.9132,
      "city": "Fremont",
      "state": "MI",
      "county": "Newaygo"
  },
  {
      "zipCode": 49413,
      "latitude": 43.554311,
      "longitude": -85.800884,
      "city": "Fremont",
      "state": "MI",
      "county": "Newaygo"
  },
  {
      "zipCode": 49415,
      "latitude": 43.275502,
      "longitude": -86.07655,
      "city": "Fruitport",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49416,
      "latitude": 42.630533,
      "longitude": -86.072032,
      "city": "Glenn",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49417,
      "latitude": 43.008786,
      "longitude": -86.129031,
      "city": "Grand Haven",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49418,
      "latitude": 42.882726,
      "longitude": -85.753056,
      "city": "Grandville",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49419,
      "latitude": 42.693859,
      "longitude": -85.980959,
      "city": "Hamilton",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49420,
      "latitude": 43.700968,
      "longitude": -86.334122,
      "city": "Hart",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49421,
      "latitude": 43.574326,
      "longitude": -86.223175,
      "city": "Hesperia",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49422,
      "latitude": 42.985596,
      "longitude": -86.444751,
      "city": "Holland",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49423,
      "latitude": 42.812579,
      "longitude": -86.037218,
      "city": "Holland",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49424,
      "latitude": 42.924578,
      "longitude": -86.121901,
      "city": "Holland",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49425,
      "latitude": 43.417206,
      "longitude": -86.130268,
      "city": "Holton",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49426,
      "latitude": 42.861575,
      "longitude": -85.935917,
      "city": "Hudsonville",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49427,
      "latitude": 42.826676,
      "longitude": -85.844378,
      "city": "Jamestown",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49428,
      "latitude": 42.918544,
      "longitude": -85.842586,
      "city": "Jenison",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49429,
      "latitude": 42.985596,
      "longitude": -86.444751,
      "city": "Jenison",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49430,
      "latitude": 43.010337,
      "longitude": -85.89754,
      "city": "Lamont",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49431,
      "latitude": 43.998926,
      "longitude": -86.349439,
      "city": "Ludington",
      "state": "MI",
      "county": "Mason"
  },
  {
      "zipCode": 49434,
      "latitude": 42.76973,
      "longitude": -86.205471,
      "city": "Macatawa",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49435,
      "latitude": 43.023673,
      "longitude": -85.844039,
      "city": "Marne",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49436,
      "latitude": 43.681533,
      "longitude": -86.468975,
      "city": "Mears",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49437,
      "latitude": 43.42404,
      "longitude": -86.339517,
      "city": "Montague",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49440,
      "latitude": 43.233764,
      "longitude": -86.232858,
      "city": "Muskegon",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49441,
      "latitude": 43.245871,
      "longitude": -86.264015,
      "city": "Muskegon",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49442,
      "latitude": 43.237314,
      "longitude": -86.14768,
      "city": "Muskegon",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49443,
      "latitude": 43.295493,
      "longitude": -86.468853,
      "city": "Muskegon",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49444,
      "latitude": 43.245821,
      "longitude": -86.185694,
      "city": "Muskegon",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49445,
      "latitude": 43.295208,
      "longitude": -86.278985,
      "city": "Muskegon",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49446,
      "latitude": 43.536651,
      "longitude": -86.322856,
      "city": "New Era",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49448,
      "latitude": 43.080186,
      "longitude": -86.068544,
      "city": "Nunica",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49449,
      "latitude": 43.728586,
      "longitude": -86.291994,
      "city": "Pentwater",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49450,
      "latitude": 42.483692,
      "longitude": -86.06653,
      "city": "Pullman",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49451,
      "latitude": 43.20596,
      "longitude": -85.968106,
      "city": "Ravenna",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49452,
      "latitude": 43.626346,
      "longitude": -86.233687,
      "city": "Rothbury",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49453,
      "latitude": 42.645611,
      "longitude": -86.165451,
      "city": "Saugatuck",
      "state": "MI",
      "county": "Allegan"
  },
  {
      "zipCode": 49454,
      "latitude": 43.940385,
      "longitude": -86.26351,
      "city": "Scottville",
      "state": "MI",
      "county": "Mason"
  },
  {
      "zipCode": 49455,
      "latitude": 43.638343,
      "longitude": -86.349283,
      "city": "Shelby",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49456,
      "latitude": 43.076609,
      "longitude": -86.19538,
      "city": "Spring Lake",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49457,
      "latitude": 43.358015,
      "longitude": -86.157722,
      "city": "Twin Lake",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49458,
      "latitude": 43.949422,
      "longitude": -86.10421,
      "city": "Walhalla",
      "state": "MI",
      "county": "Mason"
  },
  {
      "zipCode": 49459,
      "latitude": 43.721873,
      "longitude": -86.141107,
      "city": "Walkerville",
      "state": "MI",
      "county": "Oceana"
  },
  {
      "zipCode": 49460,
      "latitude": 42.929518,
      "longitude": -86.095232,
      "city": "West Olive",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49461,
      "latitude": 43.345831,
      "longitude": -86.327092,
      "city": "Whitehall",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49463,
      "latitude": 43.295493,
      "longitude": -86.468853,
      "city": "Wabaningo",
      "state": "MI",
      "county": "Muskegon"
  },
  {
      "zipCode": 49464,
      "latitude": 42.869816,
      "longitude": -85.969722,
      "city": "Zeeland",
      "state": "MI",
      "county": "Ottawa"
  },
  {
      "zipCode": 49468,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grandville",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49501,
      "latitude": 42.984226,
      "longitude": -85.629101,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49502,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49503,
      "latitude": 42.956176,
      "longitude": -85.660693,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49504,
      "latitude": 42.973687,
      "longitude": -85.726496,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49505,
      "latitude": 43.017776,
      "longitude": -85.611596,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49506,
      "latitude": 42.943577,
      "longitude": -85.577113,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49507,
      "latitude": 42.930777,
      "longitude": -85.656978,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49508,
      "latitude": 42.889378,
      "longitude": -85.621876,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49509,
      "latitude": 42.899917,
      "longitude": -85.710077,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49510,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49512,
      "latitude": 42.880192,
      "longitude": -85.535214,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49514,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49515,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49516,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49518,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49523,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49525,
      "latitude": 43.013527,
      "longitude": -85.602729,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49530,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49544,
      "latitude": 43.007274,
      "longitude": -85.725535,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49546,
      "latitude": 42.930146,
      "longitude": -85.53904,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49548,
      "latitude": 42.864793,
      "longitude": -85.615175,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49550,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49555,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49560,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49588,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49599,
      "latitude": 43.031413,
      "longitude": -85.550267,
      "city": "Grand Rapids",
      "state": "MI",
      "county": "Kent"
  },
  {
      "zipCode": 49601,
      "latitude": 44.335278,
      "longitude": -85.549093,
      "city": "Cadillac",
      "state": "MI",
      "county": "Wexford"
  },
  {
      "zipCode": 49610,
      "latitude": 44.789382,
      "longitude": -85.488449,
      "city": "Acme",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49611,
      "latitude": 44.983262,
      "longitude": -84.961776,
      "city": "Alba",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49612,
      "latitude": 44.900641,
      "longitude": -85.232932,
      "city": "Alden",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49613,
      "latitude": 44.478837,
      "longitude": -86.209391,
      "city": "Arcadia",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49614,
      "latitude": 44.396671,
      "longitude": -86.116727,
      "city": "Bear Lake",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49615,
      "latitude": 44.997248,
      "longitude": -85.198742,
      "city": "Bellaire",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49616,
      "latitude": 44.574478,
      "longitude": -86.07937,
      "city": "Benzonia",
      "state": "MI",
      "county": "Benzie"
  },
  {
      "zipCode": 49617,
      "latitude": 44.630634,
      "longitude": -86.002509,
      "city": "Beulah",
      "state": "MI",
      "county": "Benzie"
  },
  {
      "zipCode": 49618,
      "latitude": 44.289721,
      "longitude": -85.675458,
      "city": "Boon",
      "state": "MI",
      "county": "Wexford"
  },
  {
      "zipCode": 49619,
      "latitude": 44.309195,
      "longitude": -85.972541,
      "city": "Brethren",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49620,
      "latitude": 44.465123,
      "longitude": -85.681485,
      "city": "Buckley",
      "state": "MI",
      "county": "Wexford"
  },
  {
      "zipCode": 49621,
      "latitude": 44.860517,
      "longitude": -85.813768,
      "city": "Cedar",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49622,
      "latitude": 45.075986,
      "longitude": -85.25591,
      "city": "Central Lake",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49623,
      "latitude": 43.95217,
      "longitude": -85.672695,
      "city": "Chase",
      "state": "MI",
      "county": "Lake"
  },
  {
      "zipCode": 49625,
      "latitude": 44.42757,
      "longitude": -86.028192,
      "city": "Copemish",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49626,
      "latitude": 44.241337,
      "longitude": -86.291309,
      "city": "Eastlake",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49627,
      "latitude": 45.08806,
      "longitude": -85.3219,
      "city": "Eastport",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49628,
      "latitude": 44.599526,
      "longitude": -86.21407,
      "city": "Elberta",
      "state": "MI",
      "county": "Benzie"
  },
  {
      "zipCode": 49629,
      "latitude": 44.92902,
      "longitude": -85.319467,
      "city": "Elk Rapids",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49630,
      "latitude": 44.837888,
      "longitude": -85.976477,
      "city": "Empire",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49631,
      "latitude": 43.958084,
      "longitude": -85.302063,
      "city": "Evart",
      "state": "MI",
      "county": "Osceola"
  },
  {
      "zipCode": 49632,
      "latitude": 44.242162,
      "longitude": -85.043655,
      "city": "Falmouth",
      "state": "MI",
      "county": "Missaukee"
  },
  {
      "zipCode": 49633,
      "latitude": 44.584533,
      "longitude": -85.393948,
      "city": "Fife Lake",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49634,
      "latitude": 44.238191,
      "longitude": -86.057975,
      "city": "Filer City",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49635,
      "latitude": 44.613058,
      "longitude": -86.177255,
      "city": "Frankfort",
      "state": "MI",
      "county": "Benzie"
  },
  {
      "zipCode": 49636,
      "latitude": 44.873421,
      "longitude": -85.98867,
      "city": "Glen Arbor",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49637,
      "latitude": 44.634126,
      "longitude": -85.703494,
      "city": "Grawn",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49638,
      "latitude": 44.280319,
      "longitude": -85.710694,
      "city": "Harrietta",
      "state": "MI",
      "county": "Wexford"
  },
  {
      "zipCode": 49639,
      "latitude": 43.981883,
      "longitude": -85.360457,
      "city": "Hersey",
      "state": "MI",
      "county": "Osceola"
  },
  {
      "zipCode": 49640,
      "latitude": 44.690037,
      "longitude": -86.050384,
      "city": "Honor",
      "state": "MI",
      "county": "Benzie"
  },
  {
      "zipCode": 49642,
      "latitude": 43.887179,
      "longitude": -85.775872,
      "city": "Idlewild",
      "state": "MI",
      "county": "Lake"
  },
  {
      "zipCode": 49643,
      "latitude": 44.633562,
      "longitude": -85.782278,
      "city": "Interlochen",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49644,
      "latitude": 44.091983,
      "longitude": -85.90658,
      "city": "Irons",
      "state": "MI",
      "county": "Lake"
  },
  {
      "zipCode": 49645,
      "latitude": 44.377928,
      "longitude": -86.041189,
      "city": "Kaleva",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49646,
      "latitude": 44.697144,
      "longitude": -85.092905,
      "city": "Kalkaska",
      "state": "MI",
      "county": "Kalkaska"
  },
  {
      "zipCode": 49648,
      "latitude": 45.006971,
      "longitude": -85.355394,
      "city": "Kewadin",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49649,
      "latitude": 44.577555,
      "longitude": -85.530156,
      "city": "Kingsley",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49650,
      "latitude": 44.722002,
      "longitude": -85.882364,
      "city": "Lake Ann",
      "state": "MI",
      "county": "Benzie"
  },
  {
      "zipCode": 49651,
      "latitude": 44.376692,
      "longitude": -85.0946,
      "city": "Lake City",
      "state": "MI",
      "county": "Missaukee"
  },
  {
      "zipCode": 49653,
      "latitude": 44.980451,
      "longitude": -85.736199,
      "city": "Lake Leelanau",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49654,
      "latitude": 44.989416,
      "longitude": -85.770227,
      "city": "Leland",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49655,
      "latitude": 44.013375,
      "longitude": -85.32548,
      "city": "Leroy",
      "state": "MI",
      "county": "Osceola"
  },
  {
      "zipCode": 49656,
      "latitude": 44.04206,
      "longitude": -85.712873,
      "city": "Luther",
      "state": "MI",
      "county": "Lake"
  },
  {
      "zipCode": 49657,
      "latitude": 44.258292,
      "longitude": -85.154382,
      "city": "Mc Bain",
      "state": "MI",
      "county": "Missaukee"
  },
  {
      "zipCode": 49659,
      "latitude": 44.947938,
      "longitude": -85.122624,
      "city": "Mancelona",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49660,
      "latitude": 44.263534,
      "longitude": -86.182473,
      "city": "Manistee",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49663,
      "latitude": 44.412765,
      "longitude": -85.468268,
      "city": "Manton",
      "state": "MI",
      "county": "Wexford"
  },
  {
      "zipCode": 49664,
      "latitude": 44.869321,
      "longitude": -85.902284,
      "city": "Maple City",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49665,
      "latitude": 43.989936,
      "longitude": -85.271121,
      "city": "Marion",
      "state": "MI",
      "county": "Osceola"
  },
  {
      "zipCode": 49666,
      "latitude": 44.625041,
      "longitude": -85.542496,
      "city": "Mayfield",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49667,
      "latitude": 44.343261,
      "longitude": -84.944639,
      "city": "Merritt",
      "state": "MI",
      "county": "Missaukee"
  },
  {
      "zipCode": 49668,
      "latitude": 44.39912,
      "longitude": -85.629669,
      "city": "Mesick",
      "state": "MI",
      "county": "Wexford"
  },
  {
      "zipCode": 49670,
      "latitude": 45.12216,
      "longitude": -85.61847,
      "city": "Northport",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49673,
      "latitude": 44.955872,
      "longitude": -85.490005,
      "city": "Old Mission",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49674,
      "latitude": 45.066568,
      "longitude": -85.597374,
      "city": "Omena",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49675,
      "latitude": 44.418184,
      "longitude": -86.222187,
      "city": "Onekama",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49676,
      "latitude": 44.80389,
      "longitude": -85.250629,
      "city": "Rapid City",
      "state": "MI",
      "county": "Kalkaska"
  },
  {
      "zipCode": 49677,
      "latitude": 43.952029,
      "longitude": -85.414718,
      "city": "Reed City",
      "state": "MI",
      "county": "Osceola"
  },
  {
      "zipCode": 49679,
      "latitude": 43.963767,
      "longitude": -85.187258,
      "city": "Sears",
      "state": "MI",
      "county": "Osceola"
  },
  {
      "zipCode": 49680,
      "latitude": 44.634438,
      "longitude": -85.252891,
      "city": "South Boardman",
      "state": "MI",
      "county": "Kalkaska"
  },
  {
      "zipCode": 49682,
      "latitude": 44.988569,
      "longitude": -85.644068,
      "city": "Suttons Bay",
      "state": "MI",
      "county": "Leelanau"
  },
  {
      "zipCode": 49683,
      "latitude": 44.58678,
      "longitude": -85.931969,
      "city": "Thompsonville",
      "state": "MI",
      "county": "Benzie"
  },
  {
      "zipCode": 49684,
      "latitude": 44.693963,
      "longitude": -85.676342,
      "city": "Traverse City",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49685,
      "latitude": 44.816178,
      "longitude": -85.575147,
      "city": "Traverse City",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49686,
      "latitude": 44.784919,
      "longitude": -85.490673,
      "city": "Traverse City",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49688,
      "latitude": 44.112055,
      "longitude": -85.405091,
      "city": "Tustin",
      "state": "MI",
      "county": "Osceola"
  },
  {
      "zipCode": 49689,
      "latitude": 44.330169,
      "longitude": -86.027194,
      "city": "Wellston",
      "state": "MI",
      "county": "Manistee"
  },
  {
      "zipCode": 49690,
      "latitude": 44.767181,
      "longitude": -85.427605,
      "city": "Williamsburg",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49696,
      "latitude": 44.816178,
      "longitude": -85.575147,
      "city": "Traverse City",
      "state": "MI",
      "county": "Grand Traverse"
  },
  {
      "zipCode": 49701,
      "latitude": 45.753019,
      "longitude": -84.691745,
      "city": "Mackinaw City",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49705,
      "latitude": 45.366479,
      "longitude": -84.429893,
      "city": "Afton",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49706,
      "latitude": 45.539802,
      "longitude": -84.805792,
      "city": "Alanson",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49707,
      "latitude": 45.033143,
      "longitude": -83.495091,
      "city": "Alpena",
      "state": "MI",
      "county": "Alpena"
  },
  {
      "zipCode": 49709,
      "latitude": 45.030626,
      "longitude": -84.173067,
      "city": "Atlanta",
      "state": "MI",
      "county": "Montmorency"
  },
  {
      "zipCode": 49710,
      "latitude": 46.278437,
      "longitude": -84.196899,
      "city": "Barbeau",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49711,
      "latitude": 45.523425,
      "longitude": -85.332038,
      "city": "Bay Shore",
      "state": "MI",
      "county": "Charlevoix"
  },
  {
      "zipCode": 49712,
      "latitude": 45.226478,
      "longitude": -85.016896,
      "city": "Boyne City",
      "state": "MI",
      "county": "Charlevoix"
  },
  {
      "zipCode": 49713,
      "latitude": 45.203875,
      "longitude": -84.848126,
      "city": "Boyne Falls",
      "state": "MI",
      "county": "Charlevoix"
  },
  {
      "zipCode": 49715,
      "latitude": 46.389131,
      "longitude": -84.698136,
      "city": "Brimley",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49716,
      "latitude": 45.506991,
      "longitude": -84.703893,
      "city": "Brutus",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49717,
      "latitude": 45.430705,
      "longitude": -84.691207,
      "city": "Burt Lake",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49718,
      "latitude": 45.59626,
      "longitude": -84.851386,
      "city": "Carp Lake",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49719,
      "latitude": 46.007211,
      "longitude": -84.276199,
      "city": "Cedarville",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49720,
      "latitude": 45.26536,
      "longitude": -85.229723,
      "city": "Charlevoix",
      "state": "MI",
      "county": "Charlevoix"
  },
  {
      "zipCode": 49721,
      "latitude": 45.592842,
      "longitude": -84.468639,
      "city": "Cheboygan",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49722,
      "latitude": 45.423616,
      "longitude": -84.852401,
      "city": "Conway",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49723,
      "latitude": 45.625353,
      "longitude": -85.041872,
      "city": "Cross Village",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49724,
      "latitude": 46.311604,
      "longitude": -84.617131,
      "city": "Dafter",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49725,
      "latitude": 45.997182,
      "longitude": -83.923941,
      "city": "De Tour Village",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49726,
      "latitude": 46.011531,
      "longitude": -83.692502,
      "city": "Drummond Island",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49727,
      "latitude": 45.203995,
      "longitude": -85.102843,
      "city": "East Jordan",
      "state": "MI",
      "county": "Charlevoix"
  },
  {
      "zipCode": 49728,
      "latitude": 46.536871,
      "longitude": -84.862889,
      "city": "Eckerman",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49729,
      "latitude": 45.156457,
      "longitude": -85.303853,
      "city": "Ellsworth",
      "state": "MI",
      "county": "Antrim"
  },
  {
      "zipCode": 49730,
      "latitude": 45.02396,
      "longitude": -84.755439,
      "city": "Elmira",
      "state": "MI",
      "county": "Otsego"
  },
  {
      "zipCode": 49733,
      "latitude": 44.786738,
      "longitude": -84.697844,
      "city": "Frederic",
      "state": "MI",
      "county": "Crawford"
  },
  {
      "zipCode": 49734,
      "latitude": 45.028411,
      "longitude": -84.612207,
      "city": "Gaylord",
      "state": "MI",
      "county": "Otsego"
  },
  {
      "zipCode": 49735,
      "latitude": 45.000937,
      "longitude": -84.624586,
      "city": "Gaylord",
      "state": "MI",
      "county": "Otsego"
  },
  {
      "zipCode": 49736,
      "latitude": 46.078984,
      "longitude": -84.144989,
      "city": "Goetzville",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49737,
      "latitude": 45.580183,
      "longitude": -85.113678,
      "city": "Good Hart",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49738,
      "latitude": 44.691777,
      "longitude": -84.612941,
      "city": "Grayling",
      "state": "MI",
      "county": "Crawford"
  },
  {
      "zipCode": 49739,
      "latitude": 44.682996,
      "longitude": -84.610441,
      "city": "Grayling",
      "state": "MI",
      "county": "Crawford"
  },
  {
      "zipCode": 49740,
      "latitude": 45.55423,
      "longitude": -84.925826,
      "city": "Harbor Springs",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49743,
      "latitude": 45.283467,
      "longitude": -83.873719,
      "city": "Hawks",
      "state": "MI",
      "county": "Presque Isle"
  },
  {
      "zipCode": 49744,
      "latitude": 44.996329,
      "longitude": -83.667641,
      "city": "Herron",
      "state": "MI",
      "county": "Alpena"
  },
  {
      "zipCode": 49745,
      "latitude": 46.021715,
      "longitude": -84.42845,
      "city": "Hessel",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49746,
      "latitude": 45.05958,
      "longitude": -84.022691,
      "city": "Hillman",
      "state": "MI",
      "county": "Montmorency"
  },
  {
      "zipCode": 49747,
      "latitude": 44.909723,
      "longitude": -83.608911,
      "city": "Hubbard Lake",
      "state": "MI",
      "county": "Alpena"
  },
  {
      "zipCode": 49748,
      "latitude": 46.390752,
      "longitude": -85.176386,
      "city": "Hulbert",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49749,
      "latitude": 45.447209,
      "longitude": -84.578019,
      "city": "Indian River",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49751,
      "latitude": 44.963223,
      "longitude": -84.505983,
      "city": "Johannesburg",
      "state": "MI",
      "county": "Otsego"
  },
  {
      "zipCode": 49752,
      "latitude": 46.259604,
      "longitude": -84.647332,
      "city": "Kinross",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49753,
      "latitude": 45.013075,
      "longitude": -83.761958,
      "city": "Lachine",
      "state": "MI",
      "county": "Alpena"
  },
  {
      "zipCode": 49755,
      "latitude": 45.592565,
      "longitude": -84.86323,
      "city": "Levering",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49756,
      "latitude": 44.91306,
      "longitude": -84.21857,
      "city": "Lewiston",
      "state": "MI",
      "county": "Montmorency"
  },
  {
      "zipCode": 49757,
      "latitude": 45.853721,
      "longitude": -84.618203,
      "city": "Mackinac Island",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49759,
      "latitude": 45.430946,
      "longitude": -84.085375,
      "city": "Millersburg",
      "state": "MI",
      "county": "Presque Isle"
  },
  {
      "zipCode": 49760,
      "latitude": 46.07039,
      "longitude": -84.992591,
      "city": "Moran",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49761,
      "latitude": 45.559536,
      "longitude": -84.522098,
      "city": "Mullett Lake",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49762,
      "latitude": 46.109525,
      "longitude": -85.34592,
      "city": "Naubinway",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49764,
      "latitude": 45.480257,
      "longitude": -84.814645,
      "city": "Oden",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49765,
      "latitude": 45.363283,
      "longitude": -83.871571,
      "city": "Onaway",
      "state": "MI",
      "county": "Presque Isle"
  },
  {
      "zipCode": 49766,
      "latitude": 45.028249,
      "longitude": -83.53661,
      "city": "Ossineke",
      "state": "MI",
      "county": "Alpena"
  },
  {
      "zipCode": 49768,
      "latitude": 46.595083,
      "longitude": -85.097324,
      "city": "Paradise",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49769,
      "latitude": 45.566112,
      "longitude": -84.863241,
      "city": "Pellston",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49770,
      "latitude": 45.355915,
      "longitude": -84.913255,
      "city": "Petoskey",
      "state": "MI",
      "county": "Emmet"
  },
  {
      "zipCode": 49774,
      "latitude": 46.179987,
      "longitude": -84.315417,
      "city": "Pickford",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49775,
      "latitude": 45.969075,
      "longitude": -84.991195,
      "city": "Pointe Aux Pins",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49776,
      "latitude": 45.278913,
      "longitude": -83.734615,
      "city": "Posen",
      "state": "MI",
      "county": "Presque Isle"
  },
  {
      "zipCode": 49777,
      "latitude": 45.283057,
      "longitude": -83.586073,
      "city": "Presque Isle",
      "state": "MI",
      "county": "Presque Isle"
  },
  {
      "zipCode": 49778,
      "latitude": 46.410777,
      "longitude": -84.336519,
      "city": "Brimley",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49779,
      "latitude": 45.384168,
      "longitude": -83.74985,
      "city": "Rogers City",
      "state": "MI",
      "county": "Presque Isle"
  },
  {
      "zipCode": 49780,
      "latitude": 46.258514,
      "longitude": -84.800411,
      "city": "Rudyard",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49781,
      "latitude": 45.99852,
      "longitude": -84.697818,
      "city": "Saint Ignace",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49782,
      "latitude": 45.668447,
      "longitude": -85.547299,
      "city": "Beaver Island",
      "state": "MI",
      "county": "Charlevoix"
  },
  {
      "zipCode": 49783,
      "latitude": 46.357439,
      "longitude": -84.587315,
      "city": "Sault Sainte Marie",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49784,
      "latitude": 46.410777,
      "longitude": -84.336519,
      "city": "Kincheloe",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49785,
      "latitude": 46.181451,
      "longitude": -84.405367,
      "city": "Kincheloe",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49786,
      "latitude": 46.410777,
      "longitude": -84.336519,
      "city": "Kincheloe",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49788,
      "latitude": 46.288432,
      "longitude": -84.541577,
      "city": "Kincheloe",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49790,
      "latitude": 46.387377,
      "longitude": -84.966792,
      "city": "Strongs",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49791,
      "latitude": 45.483842,
      "longitude": -84.593561,
      "city": "Topinabee",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49792,
      "latitude": 45.358825,
      "longitude": -84.295283,
      "city": "Tower",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49793,
      "latitude": 46.215929,
      "longitude": -85.006769,
      "city": "Trout Lake",
      "state": "MI",
      "county": "Chippewa"
  },
  {
      "zipCode": 49795,
      "latitude": 45.139667,
      "longitude": -84.552604,
      "city": "Vanderbilt",
      "state": "MI",
      "county": "Otsego"
  },
  {
      "zipCode": 49796,
      "latitude": 45.245371,
      "longitude": -84.938228,
      "city": "Walloon Lake",
      "state": "MI",
      "county": "Charlevoix"
  },
  {
      "zipCode": 49797,
      "latitude": 44.870234,
      "longitude": -84.659847,
      "city": "Waters",
      "state": "MI",
      "county": "Otsego"
  },
  {
      "zipCode": 49799,
      "latitude": 45.326831,
      "longitude": -84.549533,
      "city": "Wolverine",
      "state": "MI",
      "county": "Cheboygan"
  },
  {
      "zipCode": 49801,
      "latitude": 46.021627,
      "longitude": -87.874496,
      "city": "Iron Mountain",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49802,
      "latitude": 45.807905,
      "longitude": -88.084302,
      "city": "Kingsford",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49805,
      "latitude": 47.287939,
      "longitude": -88.412893,
      "city": "Allouez",
      "state": "MI",
      "county": "Keweenaw"
  },
  {
      "zipCode": 49806,
      "latitude": 46.444586,
      "longitude": -86.911506,
      "city": "Au Train",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49807,
      "latitude": 45.695595,
      "longitude": -87.207306,
      "city": "Bark River",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49808,
      "latitude": 46.770788,
      "longitude": -87.672566,
      "city": "Big Bay",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49812,
      "latitude": 45.601899,
      "longitude": -87.519075,
      "city": "Carney",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49813,
      "latitude": 45.443789,
      "longitude": -87.401604,
      "city": "Cedar River",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49814,
      "latitude": 46.466637,
      "longitude": -87.920429,
      "city": "Champion",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49815,
      "latitude": 46.17823,
      "longitude": -87.934279,
      "city": "Channing",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49816,
      "latitude": 46.336962,
      "longitude": -86.993579,
      "city": "Chatham",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49817,
      "latitude": 45.915778,
      "longitude": -86.473246,
      "city": "Cooks",
      "state": "MI",
      "county": "Schoolcraft"
  },
  {
      "zipCode": 49818,
      "latitude": 45.910352,
      "longitude": -87.20714,
      "city": "Cornell",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49819,
      "latitude": 46.604141,
      "longitude": -87.614805,
      "city": "Arnold",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49820,
      "latitude": 46.159286,
      "longitude": -85.699294,
      "city": "Curtis",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49821,
      "latitude": 45.524441,
      "longitude": -87.614772,
      "city": "Daggett",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49822,
      "latitude": 46.426964,
      "longitude": -87.049704,
      "city": "Deerton",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49825,
      "latitude": 46.329926,
      "longitude": -87.013761,
      "city": "Eben Junction",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49826,
      "latitude": 46.348071,
      "longitude": -87.043622,
      "city": "Rumely",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49827,
      "latitude": 46.169384,
      "longitude": -85.587976,
      "city": "Engadine",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49829,
      "latitude": 45.851343,
      "longitude": -87.057049,
      "city": "Escanaba",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49831,
      "latitude": 46.022634,
      "longitude": -87.793865,
      "city": "Felch",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49833,
      "latitude": 46.279714,
      "longitude": -87.331099,
      "city": "Little Lake",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49834,
      "latitude": 45.939891,
      "longitude": -87.805995,
      "city": "Foster City",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49835,
      "latitude": 45.756636,
      "longitude": -86.586125,
      "city": "Garden",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49836,
      "latitude": 46.042076,
      "longitude": -86.235756,
      "city": "Germfask",
      "state": "MI",
      "county": "Schoolcraft"
  },
  {
      "zipCode": 49837,
      "latitude": 45.881256,
      "longitude": -87.115221,
      "city": "Gladstone",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49838,
      "latitude": 46.074658,
      "longitude": -85.733841,
      "city": "Gould City",
      "state": "MI",
      "county": "Mackinac"
  },
  {
      "zipCode": 49839,
      "latitude": 46.668806,
      "longitude": -85.964145,
      "city": "Grand Marais",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49840,
      "latitude": 46.042076,
      "longitude": -86.235756,
      "city": "Gulliver",
      "state": "MI",
      "county": "Schoolcraft"
  },
  {
      "zipCode": 49841,
      "latitude": 46.331119,
      "longitude": -87.439658,
      "city": "Gwinn",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49845,
      "latitude": 45.692178,
      "longitude": -87.351342,
      "city": "Harris",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49847,
      "latitude": 45.700102,
      "longitude": -87.642374,
      "city": "Hermansville",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49848,
      "latitude": 45.371769,
      "longitude": -87.623735,
      "city": "Ingalls",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49849,
      "latitude": 46.43869,
      "longitude": -87.709096,
      "city": "Ishpeming",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49852,
      "latitude": 45.827514,
      "longitude": -87.776381,
      "city": "Loretto",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49853,
      "latitude": 46.347592,
      "longitude": -85.658525,
      "city": "Mc Millan",
      "state": "MI",
      "county": "Luce"
  },
  {
      "zipCode": 49854,
      "latitude": 46.006223,
      "longitude": -86.25549,
      "city": "Manistique",
      "state": "MI",
      "county": "Schoolcraft"
  },
  {
      "zipCode": 49855,
      "latitude": 46.593435,
      "longitude": -87.615282,
      "city": "Marquette",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49858,
      "latitude": 45.228959,
      "longitude": -87.56772,
      "city": "Menominee",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49861,
      "latitude": 46.530795,
      "longitude": -88.091432,
      "city": "Michigamme",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49862,
      "latitude": 46.414779,
      "longitude": -86.460319,
      "city": "Munising",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49863,
      "latitude": 45.608658,
      "longitude": -87.56331,
      "city": "Nadeau",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49864,
      "latitude": 45.842437,
      "longitude": -86.655849,
      "city": "Nahma",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49865,
      "latitude": 46.604141,
      "longitude": -87.614805,
      "city": "National Mine",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49866,
      "latitude": 46.494904,
      "longitude": -87.799518,
      "city": "Negaunee",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49868,
      "latitude": 46.460159,
      "longitude": -85.515605,
      "city": "Newberry",
      "state": "MI",
      "county": "Luce"
  },
  {
      "zipCode": 49870,
      "latitude": 45.857201,
      "longitude": -87.917602,
      "city": "Norway",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49871,
      "latitude": 46.441565,
      "longitude": -87.576355,
      "city": "Palmer",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49872,
      "latitude": 45.992476,
      "longitude": -87.079231,
      "city": "Perkins",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49873,
      "latitude": 45.84102,
      "longitude": -87.481565,
      "city": "Perronville",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49874,
      "latitude": 45.703409,
      "longitude": -87.512409,
      "city": "Powers",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49876,
      "latitude": 45.80759,
      "longitude": -87.959862,
      "city": "Quinnesec",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49877,
      "latitude": 46.129666,
      "longitude": -87.736665,
      "city": "Ralph",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49878,
      "latitude": 45.913952,
      "longitude": -86.804734,
      "city": "Rapid River",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49879,
      "latitude": 46.36558,
      "longitude": -87.89039,
      "city": "Republic",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49880,
      "latitude": 46.057396,
      "longitude": -87.122594,
      "city": "Rock",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49881,
      "latitude": 46.074737,
      "longitude": -88.039613,
      "city": "Sagola",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49883,
      "latitude": 46.042076,
      "longitude": -86.235756,
      "city": "Seney",
      "state": "MI",
      "county": "Schoolcraft"
  },
  {
      "zipCode": 49884,
      "latitude": 46.434256,
      "longitude": -86.367899,
      "city": "Shingleton",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49885,
      "latitude": 46.396896,
      "longitude": -87.300505,
      "city": "Skandia",
      "state": "MI",
      "county": "Marquette"
  },
  {
      "zipCode": 49886,
      "latitude": 45.677744,
      "longitude": -87.485236,
      "city": "Spalding",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49887,
      "latitude": 45.447416,
      "longitude": -87.61396,
      "city": "Stephenson",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49891,
      "latitude": 46.238695,
      "longitude": -87.014443,
      "city": "Trenary",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49892,
      "latitude": 45.830389,
      "longitude": -87.807258,
      "city": "Vulcan",
      "state": "MI",
      "county": "Dickinson"
  },
  {
      "zipCode": 49893,
      "latitude": 45.295383,
      "longitude": -87.585611,
      "city": "Wallace",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49894,
      "latitude": 45.788216,
      "longitude": -87.078454,
      "city": "Wells",
      "state": "MI",
      "county": "Delta"
  },
  {
      "zipCode": 49895,
      "latitude": 46.291471,
      "longitude": -86.671351,
      "city": "Wetmore",
      "state": "MI",
      "county": "Alger"
  },
  {
      "zipCode": 49896,
      "latitude": 45.679339,
      "longitude": -87.389877,
      "city": "Wilson",
      "state": "MI",
      "county": "Menominee"
  },
  {
      "zipCode": 49901,
      "latitude": 47.293287,
      "longitude": -88.389708,
      "city": "Ahmeek",
      "state": "MI",
      "county": "Keweenaw"
  },
  {
      "zipCode": 49902,
      "latitude": 46.030951,
      "longitude": -88.377097,
      "city": "Alpha",
      "state": "MI",
      "county": "Iron"
  },
  {
      "zipCode": 49903,
      "latitude": 46.249415,
      "longitude": -88.417593,
      "city": "Amasa",
      "state": "MI",
      "county": "Iron"
  },
  {
      "zipCode": 49905,
      "latitude": 47.072716,
      "longitude": -88.634939,
      "city": "Atlantic Mine",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49908,
      "latitude": 46.80476,
      "longitude": -88.575842,
      "city": "Baraga",
      "state": "MI",
      "county": "Baraga"
  },
  {
      "zipCode": 49910,
      "latitude": 46.585302,
      "longitude": -89.517464,
      "city": "Bergland",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49911,
      "latitude": 46.423427,
      "longitude": -89.992677,
      "city": "Bessemer",
      "state": "MI",
      "county": "Gogebic"
  },
  {
      "zipCode": 49912,
      "latitude": 46.475105,
      "longitude": -89.222897,
      "city": "Bruce Crossing",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49913,
      "latitude": 47.154742,
      "longitude": -88.525475,
      "city": "Calumet",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49915,
      "latitude": 46.065734,
      "longitude": -88.630972,
      "city": "Caspian",
      "state": "MI",
      "county": "Iron"
  },
  {
      "zipCode": 49916,
      "latitude": 47.020152,
      "longitude": -88.572499,
      "city": "Chassell",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49917,
      "latitude": 47.284183,
      "longitude": -88.387535,
      "city": "Copper City",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49918,
      "latitude": 47.471616,
      "longitude": -87.946283,
      "city": "Copper Harbor",
      "state": "MI",
      "county": "Keweenaw"
  },
  {
      "zipCode": 49919,
      "latitude": 46.526495,
      "longitude": -88.584598,
      "city": "Covington",
      "state": "MI",
      "county": "Baraga"
  },
  {
      "zipCode": 49920,
      "latitude": 46.143009,
      "longitude": -88.376197,
      "city": "Crystal Falls",
      "state": "MI",
      "county": "Iron"
  },
  {
      "zipCode": 49921,
      "latitude": 47.092838,
      "longitude": -88.583578,
      "city": "Dodgeville",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49922,
      "latitude": 47.120346,
      "longitude": -88.461337,
      "city": "Dollar Bay",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49925,
      "latitude": 46.548352,
      "longitude": -89.365311,
      "city": "Ewen",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49927,
      "latitude": 46.035213,
      "longitude": -88.56094,
      "city": "Gaastra",
      "state": "MI",
      "county": "Iron"
  },
  {
      "zipCode": 49929,
      "latitude": 46.780865,
      "longitude": -89.114601,
      "city": "Greenland",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49930,
      "latitude": 47.156614,
      "longitude": -88.558554,
      "city": "Hancock",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49931,
      "latitude": 47.105336,
      "longitude": -88.548157,
      "city": "Houghton",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49934,
      "latitude": 47.167087,
      "longitude": -88.466785,
      "city": "Hubbell",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49935,
      "latitude": 46.12047,
      "longitude": -88.618995,
      "city": "Iron River",
      "state": "MI",
      "county": "Iron"
  },
  {
      "zipCode": 49938,
      "latitude": 46.479574,
      "longitude": -90.167111,
      "city": "Ironwood",
      "state": "MI",
      "county": "Gogebic"
  },
  {
      "zipCode": 49942,
      "latitude": 47.269416,
      "longitude": -88.41841,
      "city": "Kearsarge",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49945,
      "latitude": 47.162481,
      "longitude": -88.503108,
      "city": "Lake Linden",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49946,
      "latitude": 46.740227,
      "longitude": -88.44705,
      "city": "Lanse",
      "state": "MI",
      "county": "Baraga"
  },
  {
      "zipCode": 49947,
      "latitude": 46.379315,
      "longitude": -89.612621,
      "city": "Marenisco",
      "state": "MI",
      "county": "Gogebic"
  },
  {
      "zipCode": 49948,
      "latitude": 46.722794,
      "longitude": -89.051191,
      "city": "Mass City",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49950,
      "latitude": 47.352566,
      "longitude": -88.288905,
      "city": "Mohawk",
      "state": "MI",
      "county": "Keweenaw"
  },
  {
      "zipCode": 49952,
      "latitude": 46.774818,
      "longitude": -88.879888,
      "city": "Nisula",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49953,
      "latitude": 46.73807,
      "longitude": -89.334696,
      "city": "Ontonagon",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49955,
      "latitude": 47.04038,
      "longitude": -88.670361,
      "city": "Painesdale",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49958,
      "latitude": 46.7402,
      "longitude": -88.7479,
      "city": "Pelkie",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49959,
      "latitude": 46.472742,
      "longitude": -89.99761,
      "city": "Ramsay",
      "state": "MI",
      "county": "Gogebic"
  },
  {
      "zipCode": 49960,
      "latitude": 46.753715,
      "longitude": -89.195837,
      "city": "Rockland",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49961,
      "latitude": 46.501487,
      "longitude": -88.79715,
      "city": "Sidnaw",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49962,
      "latitude": 46.867128,
      "longitude": -88.139072,
      "city": "Skanee",
      "state": "MI",
      "county": "Baraga"
  },
  {
      "zipCode": 49963,
      "latitude": 47.071862,
      "longitude": -88.651973,
      "city": "South Range",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49964,
      "latitude": 46.079655,
      "longitude": -88.629006,
      "city": "Stambaugh",
      "state": "MI",
      "county": "Iron"
  },
  {
      "zipCode": 49965,
      "latitude": 46.910918,
      "longitude": -88.85321,
      "city": "Toivola",
      "state": "MI",
      "county": "Houghton"
  },
  {
      "zipCode": 49967,
      "latitude": 46.4931,
      "longitude": -89.085164,
      "city": "Trout Creek",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 49968,
      "latitude": 46.490844,
      "longitude": -89.875182,
      "city": "Wakefield",
      "state": "MI",
      "county": "Gogebic"
  },
  {
      "zipCode": 49969,
      "latitude": 46.22184,
      "longitude": -89.240577,
      "city": "Watersmeet",
      "state": "MI",
      "county": "Gogebic"
  },
  {
      "zipCode": 49970,
      "latitude": 46.731668,
      "longitude": -88.335388,
      "city": "Watton",
      "state": "MI",
      "county": "Baraga"
  },
  {
      "zipCode": 49971,
      "latitude": 46.782698,
      "longitude": -89.597404,
      "city": "White Pine",
      "state": "MI",
      "county": "Ontonagon"
  },
  {
      "zipCode": 50001,
      "latitude": 41.384345,
      "longitude": -93.424294,
      "city": "Ackworth",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50002,
      "latitude": 41.439109,
      "longitude": -94.633805,
      "city": "Adair",
      "state": "IA",
      "county": "Adair"
  },
  {
      "zipCode": 50003,
      "latitude": 41.617591,
      "longitude": -94.034515,
      "city": "Adel",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50005,
      "latitude": 42.143933,
      "longitude": -92.961868,
      "city": "Albion",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50006,
      "latitude": 42.430997,
      "longitude": -93.390638,
      "city": "Alden",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50007,
      "latitude": 41.813089,
      "longitude": -93.609929,
      "city": "Alleman",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50008,
      "latitude": 40.672872,
      "longitude": -93.405589,
      "city": "Allerton",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 50009,
      "latitude": 41.65097,
      "longitude": -93.4685,
      "city": "Altoona",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50010,
      "latitude": 42.037879,
      "longitude": -93.600254,
      "city": "Ames",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50011,
      "latitude": 42.035998,
      "longitude": -93.465172,
      "city": "Ames",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50012,
      "latitude": 42.023557,
      "longitude": -93.648465,
      "city": "Ames",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50013,
      "latitude": 42.023535,
      "longitude": -93.640818,
      "city": "Ames",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50014,
      "latitude": 42.045748,
      "longitude": -93.647237,
      "city": "Ames",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50015,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Ankeny",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50020,
      "latitude": 41.390677,
      "longitude": -94.813882,
      "city": "Anita",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 50021,
      "latitude": 41.756321,
      "longitude": -93.601467,
      "city": "Ankeny",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50022,
      "latitude": 41.381871,
      "longitude": -94.960765,
      "city": "Atlantic",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 50025,
      "latitude": 41.704535,
      "longitude": -94.918652,
      "city": "Audubon",
      "state": "IA",
      "county": "Audubon"
  },
  {
      "zipCode": 50026,
      "latitude": 41.827251,
      "longitude": -94.449511,
      "city": "Bagley",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50027,
      "latitude": 41.46974,
      "longitude": -92.487177,
      "city": "Barnes City",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 50028,
      "latitude": 41.811839,
      "longitude": -93.159506,
      "city": "Baxter",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50029,
      "latitude": 41.6872,
      "longitude": -94.528322,
      "city": "Bayard",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50031,
      "latitude": 42.038746,
      "longitude": -94.143566,
      "city": "Beaver",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50032,
      "latitude": 41.665838,
      "longitude": -93.54323,
      "city": "Berwick",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50033,
      "latitude": 41.334078,
      "longitude": -94.015337,
      "city": "Bevington",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50034,
      "latitude": 42.485099,
      "longitude": -93.68778,
      "city": "Blairsburg",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50035,
      "latitude": 41.730186,
      "longitude": -93.45694,
      "city": "Bondurant",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50036,
      "latitude": 42.0425,
      "longitude": -93.904897,
      "city": "Boone",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50037,
      "latitude": 42.036552,
      "longitude": -93.931686,
      "city": "Boone",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50038,
      "latitude": 41.630419,
      "longitude": -93.946297,
      "city": "Booneville",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50039,
      "latitude": 41.82003,
      "longitude": -94.00881,
      "city": "Bouton",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50040,
      "latitude": 42.161647,
      "longitude": -94.088499,
      "city": "Boxholm",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50041,
      "latitude": 42.600335,
      "longitude": -93.223204,
      "city": "Bradford",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50042,
      "latitude": 41.54069,
      "longitude": -94.878382,
      "city": "Brayton",
      "state": "IA",
      "county": "Audubon"
  },
  {
      "zipCode": 50043,
      "latitude": 42.417866,
      "longitude": -93.375544,
      "city": "Buckeye",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50044,
      "latitude": 41.211897,
      "longitude": -93.054149,
      "city": "Bussey",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50046,
      "latitude": 41.914036,
      "longitude": -93.51988,
      "city": "Cambridge",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50047,
      "latitude": 41.416785,
      "longitude": -93.520445,
      "city": "Carlisle",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50048,
      "latitude": 41.576333,
      "longitude": -94.521205,
      "city": "Casey",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50049,
      "latitude": 41.046868,
      "longitude": -93.309464,
      "city": "Chariton",
      "state": "IA",
      "county": "Lucas"
  },
  {
      "zipCode": 50050,
      "latitude": 42.141856,
      "longitude": -94.503538,
      "city": "Churdan",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 50051,
      "latitude": 42.11412,
      "longitude": -93.149072,
      "city": "Clemons",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50052,
      "latitude": 40.634202,
      "longitude": -93.447953,
      "city": "Clio",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 50054,
      "latitude": 41.684507,
      "longitude": -93.222321,
      "city": "Colfax",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50055,
      "latitude": 41.906479,
      "longitude": -93.294572,
      "city": "Collins",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50056,
      "latitude": 42.000921,
      "longitude": -93.380733,
      "city": "Colo",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50057,
      "latitude": 41.196557,
      "longitude": -93.159664,
      "city": "Columbia",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50058,
      "latitude": 41.957228,
      "longitude": -94.735083,
      "city": "Coon Rapids",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 50059,
      "latitude": 42.036334,
      "longitude": -94.396956,
      "city": "Cooper",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 50060,
      "latitude": 40.777093,
      "longitude": -93.327355,
      "city": "Corydon",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 50061,
      "latitude": 41.468663,
      "longitude": -93.745358,
      "city": "Cumming",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50062,
      "latitude": 41.334487,
      "longitude": -93.14778,
      "city": "Dallas",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50063,
      "latitude": 41.713107,
      "longitude": -93.930807,
      "city": "Dallas Center",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50064,
      "latitude": 42.100422,
      "longitude": -94.230723,
      "city": "Dana",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 50065,
      "latitude": 40.634604,
      "longitude": -93.8105,
      "city": "Davis City",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50066,
      "latitude": 41.800769,
      "longitude": -94.202714,
      "city": "Dawson",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50067,
      "latitude": 40.743001,
      "longitude": -93.831719,
      "city": "Decatur",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50068,
      "latitude": 41.029481,
      "longitude": -93.327475,
      "city": "Derby",
      "state": "IA",
      "county": "Lucas"
  },
  {
      "zipCode": 50069,
      "latitude": 41.521176,
      "longitude": -94.107532,
      "city": "De Soto",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50070,
      "latitude": 41.531827,
      "longitude": -94.205252,
      "city": "Dexter",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50071,
      "latitude": 42.651566,
      "longitude": -93.582732,
      "city": "Dows",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50072,
      "latitude": 41.43059,
      "longitude": -94.107202,
      "city": "Earlham",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50073,
      "latitude": 41.783265,
      "longitude": -93.5171,
      "city": "Elkhart",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50074,
      "latitude": 40.846834,
      "longitude": -94.088656,
      "city": "Ellston",
      "state": "IA",
      "county": "Ringgold"
  },
  {
      "zipCode": 50075,
      "latitude": 42.325487,
      "longitude": -93.55153,
      "city": "Ellsworth",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50076,
      "latitude": 41.589212,
      "longitude": -94.870749,
      "city": "Exira",
      "state": "IA",
      "county": "Audubon"
  },
  {
      "zipCode": 50078,
      "latitude": 41.937357,
      "longitude": -92.865043,
      "city": "Ferguson",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50101,
      "latitude": 42.666494,
      "longitude": -93.627104,
      "city": "Galt",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50102,
      "latitude": 42.235432,
      "longitude": -93.373262,
      "city": "Garden City",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50103,
      "latitude": 40.736644,
      "longitude": -93.785839,
      "city": "Garden Grove",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50104,
      "latitude": 41.480854,
      "longitude": -92.371164,
      "city": "Gibson",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 50105,
      "latitude": 42.114752,
      "longitude": -93.63986,
      "city": "Gilbert",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50106,
      "latitude": 41.987444,
      "longitude": -92.963098,
      "city": "Gilman",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50107,
      "latitude": 42.035745,
      "longitude": -94.242288,
      "city": "Grand Junction",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 50108,
      "latitude": 40.819397,
      "longitude": -93.962986,
      "city": "Grand River",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50109,
      "latitude": 41.761574,
      "longitude": -93.853297,
      "city": "Granger",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50110,
      "latitude": 41.840109,
      "longitude": -94.981191,
      "city": "Gray",
      "state": "IA",
      "county": "Audubon"
  },
  {
      "zipCode": 50111,
      "latitude": 41.652116,
      "longitude": -93.600453,
      "city": "Grimes",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50112,
      "latitude": 41.685324,
      "longitude": -92.630258,
      "city": "Grinnell",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 50115,
      "latitude": 41.683516,
      "longitude": -94.559702,
      "city": "Guthrie Center",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50116,
      "latitude": 41.189953,
      "longitude": -92.95896,
      "city": "Hamilton",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50117,
      "latitude": 41.746719,
      "longitude": -94.847093,
      "city": "Hamlin",
      "state": "IA",
      "county": "Audubon"
  },
  {
      "zipCode": 50118,
      "latitude": 41.439093,
      "longitude": -93.36963,
      "city": "Hartford",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50119,
      "latitude": 41.299888,
      "longitude": -92.928147,
      "city": "Harvey",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50120,
      "latitude": 41.938248,
      "longitude": -92.968108,
      "city": "Haverhill",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50122,
      "latitude": 42.303338,
      "longitude": -93.329584,
      "city": "Hubbard",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50123,
      "latitude": 40.80286,
      "longitude": -93.518445,
      "city": "Humeston",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 50124,
      "latitude": 41.906086,
      "longitude": -93.616342,
      "city": "Huxley",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50125,
      "latitude": 41.314309,
      "longitude": -93.588041,
      "city": "Indianola",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50126,
      "latitude": 42.43026,
      "longitude": -93.253363,
      "city": "Iowa Falls",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50127,
      "latitude": 41.777174,
      "longitude": -93.21383,
      "city": "Ira",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50128,
      "latitude": 41.820851,
      "longitude": -94.319081,
      "city": "Jamaica",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50129,
      "latitude": 42.007323,
      "longitude": -94.372586,
      "city": "Jefferson",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 50130,
      "latitude": 42.339466,
      "longitude": -93.721503,
      "city": "Jewell",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50131,
      "latitude": 41.714435,
      "longitude": -93.732559,
      "city": "Johnston",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50132,
      "latitude": 42.390667,
      "longitude": -93.684048,
      "city": "Kamrar",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50133,
      "latitude": 40.709922,
      "longitude": -94.049307,
      "city": "Kellerton",
      "state": "IA",
      "county": "Ringgold"
  },
  {
      "zipCode": 50134,
      "latitude": 41.932278,
      "longitude": -93.654229,
      "city": "Kelley",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50135,
      "latitude": 41.706656,
      "longitude": -93.023296,
      "city": "Kellogg",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50136,
      "latitude": 41.455621,
      "longitude": -92.288871,
      "city": "Keswick",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 50137,
      "latitude": 41.659444,
      "longitude": -92.939073,
      "city": "Killduff",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50138,
      "latitude": 41.295893,
      "longitude": -93.104232,
      "city": "Knoxville",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50139,
      "latitude": 41.218741,
      "longitude": -93.414713,
      "city": "Lacona",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50140,
      "latitude": 40.618769,
      "longitude": -93.927187,
      "city": "Lamoni",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50141,
      "latitude": 41.898521,
      "longitude": -92.967122,
      "city": "Laurel",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50142,
      "latitude": 42.020946,
      "longitude": -92.775909,
      "city": "Le Grand",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50143,
      "latitude": 41.338137,
      "longitude": -92.794397,
      "city": "Leighton",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 50144,
      "latitude": 40.740697,
      "longitude": -93.748889,
      "city": "Leon",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50145,
      "latitude": 41.207133,
      "longitude": -93.499347,
      "city": "Liberty Center",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50146,
      "latitude": 41.674068,
      "longitude": -94.203105,
      "city": "Linden",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50147,
      "latitude": 40.586053,
      "longitude": -93.523318,
      "city": "Lineville",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 50148,
      "latitude": 42.175223,
      "longitude": -92.969854,
      "city": "Liscomb",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50149,
      "latitude": 41.108111,
      "longitude": -94.102318,
      "city": "Lorimor",
      "state": "IA",
      "county": "Union"
  },
  {
      "zipCode": 50150,
      "latitude": 41.028469,
      "longitude": -92.976822,
      "city": "Lovilia",
      "state": "IA",
      "county": "Monroe"
  },
  {
      "zipCode": 50151,
      "latitude": 41.004925,
      "longitude": -93.492145,
      "city": "Lucas",
      "state": "IA",
      "county": "Lucas"
  },
  {
      "zipCode": 50152,
      "latitude": 41.938679,
      "longitude": -93.837061,
      "city": "Luther",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50153,
      "latitude": 41.60565,
      "longitude": -92.846323,
      "city": "Lynnville",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50154,
      "latitude": 42.158029,
      "longitude": -93.395647,
      "city": "Mc Callsburg",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50155,
      "latitude": 41.210365,
      "longitude": -94.182509,
      "city": "Macksburg",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50156,
      "latitude": 41.92886,
      "longitude": -93.797086,
      "city": "Madrid",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50157,
      "latitude": 41.732036,
      "longitude": -92.512822,
      "city": "Malcom",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 50158,
      "latitude": 42.036096,
      "longitude": -92.998867,
      "city": "Marshalltown",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50160,
      "latitude": 41.385401,
      "longitude": -93.741773,
      "city": "Martensdale",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50161,
      "latitude": 41.920999,
      "longitude": -93.395946,
      "city": "Maxwell",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50162,
      "latitude": 42.035719,
      "longitude": -93.115647,
      "city": "Melbourne",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50163,
      "latitude": 41.242194,
      "longitude": -93.173193,
      "city": "Melcher",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50164,
      "latitude": 41.553004,
      "longitude": -94.395245,
      "city": "Menlo",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50165,
      "latitude": 40.843741,
      "longitude": -93.362185,
      "city": "Millerton",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 50166,
      "latitude": 41.283124,
      "longitude": -93.428847,
      "city": "Milo",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50167,
      "latitude": 41.732252,
      "longitude": -94.047607,
      "city": "Minburn",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50168,
      "latitude": 41.775852,
      "longitude": -93.246431,
      "city": "Mingo",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50169,
      "latitude": 41.626952,
      "longitude": -93.373903,
      "city": "Mitchellville",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50170,
      "latitude": 41.569003,
      "longitude": -93.101184,
      "city": "Monroe",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50171,
      "latitude": 41.605693,
      "longitude": -92.473857,
      "city": "Montezuma",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 50173,
      "latitude": 41.95619,
      "longitude": -92.712756,
      "city": "Montour",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 50174,
      "latitude": 41.026682,
      "longitude": -93.925695,
      "city": "Murray",
      "state": "IA",
      "county": "Clarke"
  },
  {
      "zipCode": 50177,
      "latitude": 41.685742,
      "longitude": -92.532032,
      "city": "Grinnell",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 50197,
      "latitude": 41.334549,
      "longitude": -93.099205,
      "city": "Knoxville",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50198,
      "latitude": 41.334549,
      "longitude": -93.099205,
      "city": "Knoxville",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50201,
      "latitude": 42.00207,
      "longitude": -93.503866,
      "city": "Nevada",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50206,
      "latitude": 42.272076,
      "longitude": -93.256399,
      "city": "New Providence",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50207,
      "latitude": 41.393559,
      "longitude": -92.634295,
      "city": "New Sharon",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 50208,
      "latitude": 41.718107,
      "longitude": -93.012335,
      "city": "Newton",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50210,
      "latitude": 41.319061,
      "longitude": -93.672017,
      "city": "New Virginia",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50211,
      "latitude": 41.442071,
      "longitude": -93.667713,
      "city": "Norwalk",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50212,
      "latitude": 42.043714,
      "longitude": -94.052358,
      "city": "Ogden",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50213,
      "latitude": 41.029937,
      "longitude": -93.775845,
      "city": "Osceola",
      "state": "IA",
      "county": "Clarke"
  },
  {
      "zipCode": 50214,
      "latitude": 41.426958,
      "longitude": -93.030605,
      "city": "Otley",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50216,
      "latitude": 41.650933,
      "longitude": -94.425111,
      "city": "Panora",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50217,
      "latitude": 42.166202,
      "longitude": -94.280982,
      "city": "Paton",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 50218,
      "latitude": 41.349901,
      "longitude": -93.880265,
      "city": "Patterson",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50219,
      "latitude": 41.341915,
      "longitude": -93.092797,
      "city": "Pella",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50220,
      "latitude": 41.790296,
      "longitude": -94.06178,
      "city": "Perry",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50222,
      "latitude": 41.221731,
      "longitude": -93.962334,
      "city": "Peru",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50223,
      "latitude": 42.059677,
      "longitude": -94.040876,
      "city": "Pilot Mound",
      "state": "IA",
      "county": "Boone"
  },
  {
      "zipCode": 50225,
      "latitude": 41.351116,
      "longitude": -93.23362,
      "city": "Pleasantville",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50226,
      "latitude": 41.75673,
      "longitude": -93.698063,
      "city": "Polk City",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50227,
      "latitude": 42.644276,
      "longitude": -93.450081,
      "city": "Popejoy",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50228,
      "latitude": 41.590964,
      "longitude": -93.234793,
      "city": "Prairie City",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50229,
      "latitude": 41.368045,
      "longitude": -93.703126,
      "city": "Prole",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50230,
      "latitude": 42.310875,
      "longitude": -93.347563,
      "city": "Radcliffe",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50231,
      "latitude": 42.240121,
      "longitude": -93.628266,
      "city": "Randall",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50232,
      "latitude": 41.560308,
      "longitude": -92.981908,
      "city": "Reasnor",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50233,
      "latitude": 41.621146,
      "longitude": -94.212048,
      "city": "Redfield",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50234,
      "latitude": 41.906231,
      "longitude": -93.154445,
      "city": "Rhodes",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50235,
      "latitude": 41.928403,
      "longitude": -94.222127,
      "city": "Rippey",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 50236,
      "latitude": 42.161972,
      "longitude": -93.496057,
      "city": "Roland",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50237,
      "latitude": 41.561367,
      "longitude": -93.398946,
      "city": "Runnells",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50238,
      "latitude": 40.981221,
      "longitude": -93.200472,
      "city": "Russell",
      "state": "IA",
      "county": "Lucas"
  },
  {
      "zipCode": 50239,
      "latitude": 42.140911,
      "longitude": -93.174505,
      "city": "Saint Anthony",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50240,
      "latitude": 41.289336,
      "longitude": -93.862695,
      "city": "Saint Charles",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50241,
      "latitude": 41.308413,
      "longitude": -93.736916,
      "city": "Saint Marys",
      "state": "IA",
      "county": "Warren"
  },
  {
      "zipCode": 50242,
      "latitude": 41.569631,
      "longitude": -92.654204,
      "city": "Searsboro",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 50243,
      "latitude": 41.857068,
      "longitude": -93.697325,
      "city": "Sheldahl",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50244,
      "latitude": 41.8925,
      "longitude": -93.664006,
      "city": "Slater",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50246,
      "latitude": 42.296671,
      "longitude": -93.776637,
      "city": "Stanhope",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50247,
      "latitude": 42.02551,
      "longitude": -93.144785,
      "city": "State Center",
      "state": "IA",
      "county": "Marshall"
  },
  {
      "zipCode": 50248,
      "latitude": 42.158072,
      "longitude": -93.610141,
      "city": "Story City",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50249,
      "latitude": 42.296737,
      "longitude": -93.721315,
      "city": "Stratford",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50250,
      "latitude": 41.573966,
      "longitude": -94.339598,
      "city": "Stuart",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50251,
      "latitude": 41.576832,
      "longitude": -92.924355,
      "city": "Sully",
      "state": "IA",
      "county": "Jasper"
  },
  {
      "zipCode": 50252,
      "latitude": 41.457722,
      "longitude": -93.219421,
      "city": "Swan",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50254,
      "latitude": 40.978688,
      "longitude": -94.10039,
      "city": "Thayer",
      "state": "IA",
      "county": "Union"
  },
  {
      "zipCode": 50255,
      "latitude": 41.336267,
      "longitude": -92.178366,
      "city": "Thornburg",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 50256,
      "latitude": 41.263991,
      "longitude": -92.938838,
      "city": "Tracy",
      "state": "IA",
      "county": "Marion"
  },
  {
      "zipCode": 50257,
      "latitude": 41.206381,
      "longitude": -93.863473,
      "city": "Truro",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50258,
      "latitude": 42.252265,
      "longitude": -93.089028,
      "city": "Union",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50259,
      "latitude": 42.383133,
      "longitude": -93.250567,
      "city": "Gifford",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50261,
      "latitude": 41.658215,
      "longitude": -93.944641,
      "city": "Van Meter",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50262,
      "latitude": 40.736644,
      "longitude": -93.785839,
      "city": "Van Wert",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50263,
      "latitude": 41.59896,
      "longitude": -93.875317,
      "city": "Waukee",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50264,
      "latitude": 40.885821,
      "longitude": -93.761591,
      "city": "Weldon",
      "state": "IA",
      "county": "Decatur"
  },
  {
      "zipCode": 50265,
      "latitude": 41.567138,
      "longitude": -93.717232,
      "city": "West Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50266,
      "latitude": 41.653027,
      "longitude": -93.747073,
      "city": "West Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50268,
      "latitude": 41.396307,
      "longitude": -92.331335,
      "city": "What Cheer",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 50269,
      "latitude": 42.271774,
      "longitude": -93.021413,
      "city": "Whitten",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50271,
      "latitude": 42.477733,
      "longitude": -93.554643,
      "city": "Williams",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50272,
      "latitude": 41.029481,
      "longitude": -93.327475,
      "city": "Williamson",
      "state": "IA",
      "county": "Lucas"
  },
  {
      "zipCode": 50273,
      "latitude": 41.351621,
      "longitude": -94.042219,
      "city": "Winterset",
      "state": "IA",
      "county": "Madison"
  },
  {
      "zipCode": 50274,
      "latitude": 41.374394,
      "longitude": -94.904879,
      "city": "Wiota",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 50275,
      "latitude": 41.029822,
      "longitude": -93.614875,
      "city": "Woodburn",
      "state": "IA",
      "county": "Clarke"
  },
  {
      "zipCode": 50276,
      "latitude": 41.776121,
      "longitude": -93.955694,
      "city": "Woodward",
      "state": "IA",
      "county": "Dallas"
  },
  {
      "zipCode": 50277,
      "latitude": 41.775934,
      "longitude": -94.357971,
      "city": "Yale",
      "state": "IA",
      "county": "Guthrie"
  },
  {
      "zipCode": 50278,
      "latitude": 42.149851,
      "longitude": -93.29898,
      "city": "Zearing",
      "state": "IA",
      "county": "Story"
  },
  {
      "zipCode": 50301,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50302,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50303,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50304,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50305,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50306,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50307,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50308,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50309,
      "latitude": 41.584089,
      "longitude": -93.620267,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50310,
      "latitude": 41.623438,
      "longitude": -93.680954,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50311,
      "latitude": 41.63998,
      "longitude": -93.71657,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50312,
      "latitude": 41.582645,
      "longitude": -93.691719,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50313,
      "latitude": 41.643316,
      "longitude": -93.621343,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50314,
      "latitude": 41.603938,
      "longitude": -93.613817,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50315,
      "latitude": 41.545107,
      "longitude": -93.620917,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50316,
      "latitude": 41.609988,
      "longitude": -93.600816,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50317,
      "latitude": 41.612221,
      "longitude": -93.529588,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50318,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50319,
      "latitude": 41.592089,
      "longitude": -93.603967,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50320,
      "latitude": 41.544539,
      "longitude": -93.582366,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50321,
      "latitude": 41.546945,
      "longitude": -93.659668,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50322,
      "latitude": 41.630449,
      "longitude": -93.753628,
      "city": "Urbandale",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50323,
      "latitude": 41.62938,
      "longitude": -93.771676,
      "city": "Urbandale",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50325,
      "latitude": 41.607588,
      "longitude": -93.744905,
      "city": "Clive",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50327,
      "latitude": 41.586369,
      "longitude": -93.487707,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50328,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50329,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50330,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50331,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50332,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50333,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50334,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50335,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50336,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50338,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50339,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50340,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50347,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50350,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50359,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50360,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50361,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50362,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50363,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50364,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50367,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50368,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50369,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50380,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50381,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50391,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50392,
      "latitude": 41.587839,
      "longitude": -93.627368,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50393,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50394,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50395,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50396,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50397,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50398,
      "latitude": 41.594543,
      "longitude": -93.785502,
      "city": "West Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50401,
      "latitude": 43.116477,
      "longitude": -93.270559,
      "city": "Mason City",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50402,
      "latitude": 43.081604,
      "longitude": -93.260879,
      "city": "Mason City",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50420,
      "latitude": 42.819841,
      "longitude": -93.429379,
      "city": "Alexander",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50421,
      "latitude": 42.783801,
      "longitude": -93.712614,
      "city": "Belmond",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50423,
      "latitude": 43.081779,
      "longitude": -93.734689,
      "city": "Britt",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50424,
      "latitude": 43.37731,
      "longitude": -93.803062,
      "city": "Buffalo Center",
      "state": "IA",
      "county": "Winnebago"
  },
  {
      "zipCode": 50426,
      "latitude": 43.414935,
      "longitude": -93.002846,
      "city": "Carpenter",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50427,
      "latitude": 42.834852,
      "longitude": -93.222587,
      "city": "Chapin",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50428,
      "latitude": 43.100399,
      "longitude": -93.260676,
      "city": "Clear Lake",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50430,
      "latitude": 42.980309,
      "longitude": -93.872724,
      "city": "Corwith",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50431,
      "latitude": 42.738164,
      "longitude": -93.369996,
      "city": "Coulter",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50432,
      "latitude": 43.21835,
      "longitude": -93.800697,
      "city": "Crystal Lake",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50433,
      "latitude": 43.053101,
      "longitude": -93.261024,
      "city": "Dougherty",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50434,
      "latitude": 43.299228,
      "longitude": -93.28496,
      "city": "Fertile",
      "state": "IA",
      "county": "Worth"
  },
  {
      "zipCode": 50435,
      "latitude": 43.136067,
      "longitude": -92.77932,
      "city": "Floyd",
      "state": "IA",
      "county": "Floyd"
  },
  {
      "zipCode": 50436,
      "latitude": 43.299419,
      "longitude": -93.684781,
      "city": "Forest City",
      "state": "IA",
      "county": "Winnebago"
  },
  {
      "zipCode": 50438,
      "latitude": 43.089116,
      "longitude": -93.734009,
      "city": "Garner",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50439,
      "latitude": 43.074783,
      "longitude": -93.675089,
      "city": "Goodell",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50440,
      "latitude": 43.320548,
      "longitude": -93.200006,
      "city": "Grafton",
      "state": "IA",
      "county": "Worth"
  },
  {
      "zipCode": 50441,
      "latitude": 42.731454,
      "longitude": -93.230657,
      "city": "Hampton",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50444,
      "latitude": 43.32067,
      "longitude": -93.378938,
      "city": "Hanlontown",
      "state": "IA",
      "county": "Worth"
  },
  {
      "zipCode": 50446,
      "latitude": 43.353565,
      "longitude": -93.3832,
      "city": "Joice",
      "state": "IA",
      "county": "Worth"
  },
  {
      "zipCode": 50447,
      "latitude": 43.069212,
      "longitude": -93.734839,
      "city": "Kanawha",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50448,
      "latitude": 43.359809,
      "longitude": -93.240306,
      "city": "Kensett",
      "state": "IA",
      "county": "Worth"
  },
  {
      "zipCode": 50449,
      "latitude": 42.991727,
      "longitude": -93.566985,
      "city": "Klemme",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50450,
      "latitude": 43.408993,
      "longitude": -93.567204,
      "city": "Lake Mills",
      "state": "IA",
      "county": "Winnebago"
  },
  {
      "zipCode": 50451,
      "latitude": 43.366038,
      "longitude": -94.10309,
      "city": "Lakota",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50452,
      "latitude": 42.782628,
      "longitude": -93.389744,
      "city": "Latimer",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50453,
      "latitude": 43.342548,
      "longitude": -93.689464,
      "city": "Leland",
      "state": "IA",
      "county": "Winnebago"
  },
  {
      "zipCode": 50454,
      "latitude": 43.385605,
      "longitude": -92.738099,
      "city": "Little Cedar",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50455,
      "latitude": 43.457197,
      "longitude": -92.63316,
      "city": "Mc Intire",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50456,
      "latitude": 43.342237,
      "longitude": -93.271287,
      "city": "Manly",
      "state": "IA",
      "county": "Worth"
  },
  {
      "zipCode": 50457,
      "latitude": 43.059175,
      "longitude": -93.292969,
      "city": "Meservey",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50458,
      "latitude": 43.149677,
      "longitude": -92.974488,
      "city": "Nora Springs",
      "state": "IA",
      "county": "Floyd"
  },
  {
      "zipCode": 50459,
      "latitude": 43.428355,
      "longitude": -93.261042,
      "city": "Northwood",
      "state": "IA",
      "county": "Worth"
  },
  {
      "zipCode": 50460,
      "latitude": 43.234006,
      "longitude": -92.686031,
      "city": "Orchard",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50461,
      "latitude": 43.320773,
      "longitude": -92.789683,
      "city": "Osage",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50464,
      "latitude": 43.234199,
      "longitude": -93.082854,
      "city": "Plymouth",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50465,
      "latitude": 43.464673,
      "longitude": -93.872202,
      "city": "Rake",
      "state": "IA",
      "county": "Winnebago"
  },
  {
      "zipCode": 50466,
      "latitude": 43.381795,
      "longitude": -92.475226,
      "city": "Riceville",
      "state": "IA",
      "county": "Howard"
  },
  {
      "zipCode": 50467,
      "latitude": 43.206593,
      "longitude": -93.08574,
      "city": "Rock Falls",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50468,
      "latitude": 43.016911,
      "longitude": -92.907599,
      "city": "Rockford",
      "state": "IA",
      "county": "Floyd"
  },
  {
      "zipCode": 50469,
      "latitude": 43.00211,
      "longitude": -93.181709,
      "city": "Rockwell",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50470,
      "latitude": 42.724794,
      "longitude": -93.558042,
      "city": "Rowan",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50471,
      "latitude": 43.128382,
      "longitude": -92.887374,
      "city": "Rudd",
      "state": "IA",
      "county": "Floyd"
  },
  {
      "zipCode": 50472,
      "latitude": 43.406399,
      "longitude": -92.810582,
      "city": "Saint Ansgar",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50473,
      "latitude": 43.457336,
      "longitude": -93.674587,
      "city": "Scarville",
      "state": "IA",
      "county": "Winnebago"
  },
  {
      "zipCode": 50475,
      "latitude": 42.732312,
      "longitude": -93.262211,
      "city": "Sheffield",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50476,
      "latitude": 43.435628,
      "longitude": -92.853243,
      "city": "Stacyville",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50477,
      "latitude": 42.984273,
      "longitude": -93.35609,
      "city": "Swaledale",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50478,
      "latitude": 43.406406,
      "longitude": -93.748603,
      "city": "Thompson",
      "state": "IA",
      "county": "Winnebago"
  },
  {
      "zipCode": 50479,
      "latitude": 43.077155,
      "longitude": -93.312569,
      "city": "Thornton",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50480,
      "latitude": 43.253389,
      "longitude": -94.05929,
      "city": "Titonka",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50481,
      "latitude": 43.356439,
      "longitude": -92.788967,
      "city": "Toeterville",
      "state": "IA",
      "county": "Mitchell"
  },
  {
      "zipCode": 50482,
      "latitude": 43.111474,
      "longitude": -93.467873,
      "city": "Ventura",
      "state": "IA",
      "county": "Cerro Gordo"
  },
  {
      "zipCode": 50483,
      "latitude": 43.103297,
      "longitude": -94.029455,
      "city": "Wesley",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50484,
      "latitude": 43.197698,
      "longitude": -93.881521,
      "city": "Woden",
      "state": "IA",
      "county": "Hancock"
  },
  {
      "zipCode": 50501,
      "latitude": 42.438596,
      "longitude": -94.166587,
      "city": "Fort Dodge",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50510,
      "latitude": 42.735002,
      "longitude": -95.151118,
      "city": "Albert City",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 50511,
      "latitude": 43.109313,
      "longitude": -94.166994,
      "city": "Algona",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50514,
      "latitude": 43.407517,
      "longitude": -94.531703,
      "city": "Armstrong",
      "state": "IA",
      "county": "Emmet"
  },
  {
      "zipCode": 50515,
      "latitude": 43.01825,
      "longitude": -94.855316,
      "city": "Ayrshire",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 50516,
      "latitude": 42.601287,
      "longitude": -94.148103,
      "city": "Badger",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50517,
      "latitude": 43.306166,
      "longitude": -94.251434,
      "city": "Bancroft",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50518,
      "latitude": 42.552043,
      "longitude": -94.369181,
      "city": "Barnum",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50519,
      "latitude": 42.863891,
      "longitude": -94.295698,
      "city": "Bode",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50520,
      "latitude": 42.801126,
      "longitude": -94.358387,
      "city": "Bradgate",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50521,
      "latitude": 42.346605,
      "longitude": -94.116205,
      "city": "Burnside",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50522,
      "latitude": 43.183395,
      "longitude": -94.177422,
      "city": "Burt",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50523,
      "latitude": 42.347134,
      "longitude": -94.280864,
      "city": "Callender",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50524,
      "latitude": 42.514307,
      "longitude": -94.212455,
      "city": "Clare",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50525,
      "latitude": 42.703045,
      "longitude": -93.745623,
      "city": "Clarion",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50526,
      "latitude": 42.733001,
      "longitude": -93.73523,
      "city": "Clarion",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50527,
      "latitude": 42.970187,
      "longitude": -94.769596,
      "city": "Curlew",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 50528,
      "latitude": 43.124819,
      "longitude": -94.531189,
      "city": "Cylinder",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 50529,
      "latitude": 42.73695,
      "longitude": -94.243675,
      "city": "Dakota City",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50530,
      "latitude": 42.281924,
      "longitude": -94.110777,
      "city": "Dayton",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50531,
      "latitude": 43.449975,
      "longitude": -94.610236,
      "city": "Dolliver",
      "state": "IA",
      "county": "Emmet"
  },
  {
      "zipCode": 50532,
      "latitude": 42.463053,
      "longitude": -94.016459,
      "city": "Duncombe",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50533,
      "latitude": 42.646337,
      "longitude": -93.882675,
      "city": "Eagle Grove",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50535,
      "latitude": 42.458243,
      "longitude": -95.16624,
      "city": "Early",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 50536,
      "latitude": 43.118345,
      "longitude": -94.690755,
      "city": "Emmetsburg",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 50538,
      "latitude": 42.279298,
      "longitude": -94.465144,
      "city": "Farnhamville",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50539,
      "latitude": 43.241336,
      "longitude": -94.384458,
      "city": "Fenton",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50540,
      "latitude": 42.646211,
      "longitude": -94.797046,
      "city": "Fonda",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50541,
      "latitude": 42.720638,
      "longitude": -94.393684,
      "city": "Gilmore City",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50542,
      "latitude": 42.785534,
      "longitude": -93.91222,
      "city": "Goldfield",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50543,
      "latitude": 42.325308,
      "longitude": -94.25193,
      "city": "Gowrie",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50544,
      "latitude": 42.260243,
      "longitude": -94.16463,
      "city": "Harcourt",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50545,
      "latitude": 42.797529,
      "longitude": -94.079932,
      "city": "Hardy",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50546,
      "latitude": 42.842446,
      "longitude": -94.721926,
      "city": "Havelock",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50548,
      "latitude": 42.775833,
      "longitude": -94.207763,
      "city": "Humboldt",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50551,
      "latitude": 42.487057,
      "longitude": -94.756959,
      "city": "Jolley",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50552,
      "latitude": 42.42361,
      "longitude": -94.458642,
      "city": "Knierim",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50554,
      "latitude": 42.821248,
      "longitude": -94.835889,
      "city": "Laurens",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50556,
      "latitude": 43.443079,
      "longitude": -94.187319,
      "city": "Ledyard",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50557,
      "latitude": 42.390452,
      "longitude": -94.048108,
      "city": "Lehigh",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50558,
      "latitude": 42.841534,
      "longitude": -94.163578,
      "city": "Livermore",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50559,
      "latitude": 43.219959,
      "longitude": -94.309997,
      "city": "Lone Rock",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50560,
      "latitude": 42.976166,
      "longitude": -94.124477,
      "city": "Lu Verne",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50561,
      "latitude": 42.421312,
      "longitude": -94.821328,
      "city": "Lytton",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50562,
      "latitude": 42.973755,
      "longitude": -94.644417,
      "city": "Mallard",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 50563,
      "latitude": 42.50089,
      "longitude": -94.57695,
      "city": "Manson",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50565,
      "latitude": 42.858254,
      "longitude": -94.9984,
      "city": "Marathon",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 50566,
      "latitude": 42.440996,
      "longitude": -94.315601,
      "city": "Moorland",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50567,
      "latitude": 42.509655,
      "longitude": -95.104435,
      "city": "Nemaha",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 50568,
      "latitude": 42.705762,
      "longitude": -95.003782,
      "city": "Newell",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 50569,
      "latitude": 42.437722,
      "longitude": -94.220655,
      "city": "Otho",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50570,
      "latitude": 42.841797,
      "longitude": -94.374343,
      "city": "Ottosen",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50571,
      "latitude": 42.645733,
      "longitude": -94.560735,
      "city": "Palmer",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50573,
      "latitude": 42.864825,
      "longitude": -94.619745,
      "city": "Plover",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50574,
      "latitude": 42.726507,
      "longitude": -94.650755,
      "city": "Pocahontas",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50575,
      "latitude": 42.519205,
      "longitude": -94.678445,
      "city": "Pomeroy",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50576,
      "latitude": 42.825241,
      "longitude": -95.141444,
      "city": "Rembrandt",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 50577,
      "latitude": 42.844755,
      "longitude": -94.1314,
      "city": "Renwick",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50578,
      "latitude": 43.31281,
      "longitude": -94.560669,
      "city": "Ringsted",
      "state": "IA",
      "county": "Emmet"
  },
  {
      "zipCode": 50579,
      "latitude": 42.393114,
      "longitude": -94.633664,
      "city": "Rockwell City",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50581,
      "latitude": 42.744133,
      "longitude": -94.642302,
      "city": "Rolfe",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50582,
      "latitude": 42.79315,
      "longitude": -94.305614,
      "city": "Rutland",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50583,
      "latitude": 42.439752,
      "longitude": -94.992967,
      "city": "Sac City",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 50585,
      "latitude": 42.757096,
      "longitude": -95.103677,
      "city": "Sioux Rapids",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 50586,
      "latitude": 42.386479,
      "longitude": -94.454529,
      "city": "Somers",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 50588,
      "latitude": 42.693184,
      "longitude": -95.151811,
      "city": "Storm Lake",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 50590,
      "latitude": 43.283285,
      "longitude": -94.295584,
      "city": "Swea City",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50591,
      "latitude": 42.77609,
      "longitude": -94.059946,
      "city": "Thor",
      "state": "IA",
      "county": "Humboldt"
  },
  {
      "zipCode": 50592,
      "latitude": 42.726167,
      "longitude": -95.185325,
      "city": "Truesdale",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 50593,
      "latitude": 42.659794,
      "longitude": -94.897858,
      "city": "Varina",
      "state": "IA",
      "county": "Pocahontas"
  },
  {
      "zipCode": 50594,
      "latitude": 42.581749,
      "longitude": -94.030651,
      "city": "Vincent",
      "state": "IA",
      "county": "Webster"
  },
  {
      "zipCode": 50595,
      "latitude": 42.434161,
      "longitude": -93.825581,
      "city": "Webster City",
      "state": "IA",
      "county": "Hamilton"
  },
  {
      "zipCode": 50597,
      "latitude": 43.002465,
      "longitude": -94.501553,
      "city": "West Bend",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 50598,
      "latitude": 43.082501,
      "longitude": -94.389877,
      "city": "Whittemore",
      "state": "IA",
      "county": "Kossuth"
  },
  {
      "zipCode": 50599,
      "latitude": 42.600976,
      "longitude": -93.794414,
      "city": "Woolstock",
      "state": "IA",
      "county": "Wright"
  },
  {
      "zipCode": 50601,
      "latitude": 42.408805,
      "longitude": -93.09251,
      "city": "Ackley",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50602,
      "latitude": 42.697108,
      "longitude": -92.7981,
      "city": "Allison",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50603,
      "latitude": 43.13992,
      "longitude": -92.424719,
      "city": "Alta Vista",
      "state": "IA",
      "county": "Chickasaw"
  },
  {
      "zipCode": 50604,
      "latitude": 42.657249,
      "longitude": -92.895287,
      "city": "Aplington",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50605,
      "latitude": 42.804004,
      "longitude": -92.985938,
      "city": "Aredale",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50606,
      "latitude": 42.752653,
      "longitude": -91.69556,
      "city": "Arlington",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 50607,
      "latitude": 42.570346,
      "longitude": -91.748063,
      "city": "Aurora",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50608,
      "latitude": 42.58243,
      "longitude": -92.95988,
      "city": "Austinville",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50609,
      "latitude": 42.260476,
      "longitude": -92.806788,
      "city": "Beaman",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50611,
      "latitude": 42.811045,
      "longitude": -92.882596,
      "city": "Bristow",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50612,
      "latitude": 42.271906,
      "longitude": -92.388908,
      "city": "Buckingham",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 50613,
      "latitude": 42.517091,
      "longitude": -92.43585,
      "city": "Cedar Falls",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50614,
      "latitude": 42.469768,
      "longitude": -92.309475,
      "city": "Cedar Falls",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50616,
      "latitude": 43.059926,
      "longitude": -92.789058,
      "city": "Charles City",
      "state": "IA",
      "county": "Floyd"
  },
  {
      "zipCode": 50619,
      "latitude": 42.793119,
      "longitude": -92.653238,
      "city": "Clarksville",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50620,
      "latitude": 43.110765,
      "longitude": -92.744821,
      "city": "Colwell",
      "state": "IA",
      "county": "Floyd"
  },
  {
      "zipCode": 50621,
      "latitude": 42.261859,
      "longitude": -92.913373,
      "city": "Conrad",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50622,
      "latitude": 42.686195,
      "longitude": -92.387102,
      "city": "Denver",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50623,
      "latitude": 42.525282,
      "longitude": -92.220701,
      "city": "Dewar",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50624,
      "latitude": 42.377438,
      "longitude": -92.713296,
      "city": "Dike",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50625,
      "latitude": 42.753663,
      "longitude": -92.912537,
      "city": "Dumont",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50626,
      "latitude": 42.562505,
      "longitude": -92.178342,
      "city": "Dunkerton",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50627,
      "latitude": 42.335289,
      "longitude": -93.141651,
      "city": "Eldora",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50628,
      "latitude": 43.296535,
      "longitude": -92.3868,
      "city": "Elma",
      "state": "IA",
      "county": "Howard"
  },
  {
      "zipCode": 50629,
      "latitude": 42.577399,
      "longitude": -91.981702,
      "city": "Fairbank",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50630,
      "latitude": 43.052855,
      "longitude": -92.205513,
      "city": "Fredericksburg",
      "state": "IA",
      "county": "Chickasaw"
  },
  {
      "zipCode": 50631,
      "latitude": 42.882661,
      "longitude": -92.306271,
      "city": "Frederika",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50632,
      "latitude": 42.101125,
      "longitude": -92.674254,
      "city": "Garwin",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 50633,
      "latitude": 42.669601,
      "longitude": -93.114687,
      "city": "Geneva",
      "state": "IA",
      "county": "Franklin"
  },
  {
      "zipCode": 50634,
      "latitude": 42.419686,
      "longitude": -92.21986,
      "city": "Gilbertville",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50635,
      "latitude": 42.194806,
      "longitude": -92.533334,
      "city": "Gladbrook",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 50636,
      "latitude": 42.826237,
      "longitude": -92.84909,
      "city": "Greene",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50638,
      "latitude": 42.369118,
      "longitude": -92.807878,
      "city": "Grundy Center",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50641,
      "latitude": 42.574258,
      "longitude": -91.924112,
      "city": "Hazleton",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50642,
      "latitude": 42.44797,
      "longitude": -92.817924,
      "city": "Holland",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50643,
      "latitude": 42.383317,
      "longitude": -92.416125,
      "city": "Hudson",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50644,
      "latitude": 42.506055,
      "longitude": -91.81214,
      "city": "Independence",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50645,
      "latitude": 43.052822,
      "longitude": -92.445706,
      "city": "Ionia",
      "state": "IA",
      "county": "Chickasaw"
  },
  {
      "zipCode": 50647,
      "latitude": 42.665934,
      "longitude": -92.465858,
      "city": "Janesville",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50648,
      "latitude": 42.482524,
      "longitude": -91.90519,
      "city": "Jesup",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50649,
      "latitude": 42.662619,
      "longitude": -92.911151,
      "city": "Kesley",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50650,
      "latitude": 42.585655,
      "longitude": -91.716494,
      "city": "Lamont",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50651,
      "latitude": 42.369559,
      "longitude": -92.206097,
      "city": "La Porte City",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50652,
      "latitude": 42.264291,
      "longitude": -92.693018,
      "city": "Lincoln",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 50653,
      "latitude": 43.060131,
      "longitude": -92.877776,
      "city": "Marble Rock",
      "state": "IA",
      "county": "Floyd"
  },
  {
      "zipCode": 50654,
      "latitude": 42.457286,
      "longitude": -91.564233,
      "city": "Masonville",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 50655,
      "latitude": 42.878853,
      "longitude": -91.917241,
      "city": "Maynard",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 50657,
      "latitude": 42.343304,
      "longitude": -92.673819,
      "city": "Morrison",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50658,
      "latitude": 42.985096,
      "longitude": -92.475463,
      "city": "Nashua",
      "state": "IA",
      "county": "Chickasaw"
  },
  {
      "zipCode": 50659,
      "latitude": 43.065359,
      "longitude": -92.338474,
      "city": "New Hampton",
      "state": "IA",
      "county": "Chickasaw"
  },
  {
      "zipCode": 50660,
      "latitude": 42.599292,
      "longitude": -92.623199,
      "city": "New Hartford",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50661,
      "latitude": 43.117425,
      "longitude": -92.414422,
      "city": "North Washington",
      "state": "IA",
      "county": "Chickasaw"
  },
  {
      "zipCode": 50662,
      "latitude": 42.693463,
      "longitude": -91.853578,
      "city": "Oelwein",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 50664,
      "latitude": 42.862275,
      "longitude": -91.843639,
      "city": "Oran",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 50665,
      "latitude": 42.655813,
      "longitude": -92.824122,
      "city": "Parkersburg",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50666,
      "latitude": 42.840591,
      "longitude": -92.470373,
      "city": "Plainfield",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50667,
      "latitude": 42.466587,
      "longitude": -92.21642,
      "city": "Raymond",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50668,
      "latitude": 42.693337,
      "longitude": -92.219605,
      "city": "Readlyn",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50669,
      "latitude": 42.332039,
      "longitude": -92.705292,
      "city": "Reinbeck",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50670,
      "latitude": 42.674311,
      "longitude": -92.633495,
      "city": "Shell Rock",
      "state": "IA",
      "county": "Butler"
  },
  {
      "zipCode": 50671,
      "latitude": 42.614435,
      "longitude": -91.795647,
      "city": "Stanley",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50672,
      "latitude": 42.412327,
      "longitude": -93.084751,
      "city": "Steamboat Rock",
      "state": "IA",
      "county": "Hardin"
  },
  {
      "zipCode": 50673,
      "latitude": 42.525507,
      "longitude": -92.70291,
      "city": "Stout",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50674,
      "latitude": 42.787919,
      "longitude": -92.309263,
      "city": "Sumner",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50675,
      "latitude": 42.173618,
      "longitude": -92.527236,
      "city": "Traer",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 50676,
      "latitude": 42.786204,
      "longitude": -92.278632,
      "city": "Tripoli",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50677,
      "latitude": 42.774781,
      "longitude": -92.403969,
      "city": "Waverly",
      "state": "IA",
      "county": "Bremer"
  },
  {
      "zipCode": 50680,
      "latitude": 42.445049,
      "longitude": -92.926227,
      "city": "Wellsburg",
      "state": "IA",
      "county": "Grundy"
  },
  {
      "zipCode": 50681,
      "latitude": 42.781206,
      "longitude": -92.012235,
      "city": "Westgate",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 50682,
      "latitude": 42.433855,
      "longitude": -91.831019,
      "city": "Winthrop",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 50701,
      "latitude": 42.441117,
      "longitude": -92.339209,
      "city": "Waterloo",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50702,
      "latitude": 42.458761,
      "longitude": -92.313876,
      "city": "Waterloo",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50703,
      "latitude": 42.549802,
      "longitude": -92.258493,
      "city": "Waterloo",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50704,
      "latitude": 42.469768,
      "longitude": -92.309475,
      "city": "Waterloo",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50706,
      "latitude": 42.415638,
      "longitude": -92.270339,
      "city": "Waterloo",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50707,
      "latitude": 42.482297,
      "longitude": -92.276183,
      "city": "Evansdale",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50799,
      "latitude": 42.469768,
      "longitude": -92.309475,
      "city": "Waterloo",
      "state": "IA",
      "county": "Black Hawk"
  },
  {
      "zipCode": 50801,
      "latitude": 41.049896,
      "longitude": -94.347137,
      "city": "Creston",
      "state": "IA",
      "county": "Union"
  },
  {
      "zipCode": 50830,
      "latitude": 41.033593,
      "longitude": -94.19714,
      "city": "Afton",
      "state": "IA",
      "county": "Union"
  },
  {
      "zipCode": 50831,
      "latitude": 40.931394,
      "longitude": -94.219334,
      "city": "Arispe",
      "state": "IA",
      "county": "Union"
  },
  {
      "zipCode": 50833,
      "latitude": 40.687613,
      "longitude": -94.690576,
      "city": "Bedford",
      "state": "IA",
      "county": "Taylor"
  },
  {
      "zipCode": 50835,
      "latitude": 40.735117,
      "longitude": -94.243258,
      "city": "Benton",
      "state": "IA",
      "county": "Ringgold"
  },
  {
      "zipCode": 50836,
      "latitude": 40.624823,
      "longitude": -94.547407,
      "city": "Blockton",
      "state": "IA",
      "county": "Taylor"
  },
  {
      "zipCode": 50837,
      "latitude": 41.258885,
      "longitude": -94.666868,
      "city": "Bridgewater",
      "state": "IA",
      "county": "Adair"
  },
  {
      "zipCode": 50839,
      "latitude": 41.048733,
      "longitude": -94.823088,
      "city": "Carbon",
      "state": "IA",
      "county": "Adams"
  },
  {
      "zipCode": 50840,
      "latitude": 40.782221,
      "longitude": -94.606348,
      "city": "Clearfield",
      "state": "IA",
      "county": "Taylor"
  },
  {
      "zipCode": 50841,
      "latitude": 41.028942,
      "longitude": -94.746591,
      "city": "Corning",
      "state": "IA",
      "county": "Adams"
  },
  {
      "zipCode": 50842,
      "latitude": 41.039762,
      "longitude": -94.461622,
      "city": "Cromwell",
      "state": "IA",
      "county": "Union"
  },
  {
      "zipCode": 50843,
      "latitude": 41.255042,
      "longitude": -94.947597,
      "city": "Cumberland",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 50845,
      "latitude": 40.727999,
      "longitude": -94.292656,
      "city": "Diagonal",
      "state": "IA",
      "county": "Ringgold"
  },
  {
      "zipCode": 50846,
      "latitude": 41.300894,
      "longitude": -94.54783,
      "city": "Fontanelle",
      "state": "IA",
      "county": "Adair"
  },
  {
      "zipCode": 50847,
      "latitude": 41.142675,
      "longitude": -94.984159,
      "city": "Grant",
      "state": "IA",
      "county": "Montgomery"
  },
  {
      "zipCode": 50848,
      "latitude": 40.772971,
      "longitude": -94.737189,
      "city": "Gravity",
      "state": "IA",
      "county": "Taylor"
  },
  {
      "zipCode": 50849,
      "latitude": 41.299977,
      "longitude": -94.456404,
      "city": "Greenfield",
      "state": "IA",
      "county": "Adair"
  },
  {
      "zipCode": 50851,
      "latitude": 40.844837,
      "longitude": -94.585174,
      "city": "Lenox",
      "state": "IA",
      "county": "Taylor"
  },
  {
      "zipCode": 50853,
      "latitude": 41.229493,
      "longitude": -94.784375,
      "city": "Massena",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 50854,
      "latitude": 40.716275,
      "longitude": -94.228794,
      "city": "Mount Ayr",
      "state": "IA",
      "county": "Ringgold"
  },
  {
      "zipCode": 50857,
      "latitude": 40.9765,
      "longitude": -94.871606,
      "city": "Nodaway",
      "state": "IA",
      "county": "Adams"
  },
  {
      "zipCode": 50858,
      "latitude": 41.215096,
      "longitude": -94.423224,
      "city": "Orient",
      "state": "IA",
      "county": "Adair"
  },
  {
      "zipCode": 50859,
      "latitude": 41.057677,
      "longitude": -94.594228,
      "city": "Prescott",
      "state": "IA",
      "county": "Adams"
  },
  {
      "zipCode": 50860,
      "latitude": 40.604641,
      "longitude": -94.388509,
      "city": "Redding",
      "state": "IA",
      "county": "Ringgold"
  },
  {
      "zipCode": 50861,
      "latitude": 40.933655,
      "longitude": -94.242881,
      "city": "Shannon City",
      "state": "IA",
      "county": "Union"
  },
  {
      "zipCode": 50862,
      "latitude": 40.812934,
      "longitude": -94.662137,
      "city": "Sharpsburg",
      "state": "IA",
      "county": "Taylor"
  },
  {
      "zipCode": 50863,
      "latitude": 40.853588,
      "longitude": -94.194013,
      "city": "Tingley",
      "state": "IA",
      "county": "Ringgold"
  },
  {
      "zipCode": 50864,
      "latitude": 41.029639,
      "longitude": -95.051602,
      "city": "Villisca",
      "state": "IA",
      "county": "Montgomery"
  },
  {
      "zipCode": 50936,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50940,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50947,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50950,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50980,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 50981,
      "latitude": 41.672687,
      "longitude": -93.572173,
      "city": "Des Moines",
      "state": "IA",
      "county": "Polk"
  },
  {
      "zipCode": 51001,
      "latitude": 42.822482,
      "longitude": -96.462952,
      "city": "Akron",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51002,
      "latitude": 42.735364,
      "longitude": -95.151121,
      "city": "Alta",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 51003,
      "latitude": 43.03801,
      "longitude": -95.970314,
      "city": "Alton",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51004,
      "latitude": 42.336668,
      "longitude": -95.962495,
      "city": "Anthon",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51005,
      "latitude": 42.719513,
      "longitude": -95.534693,
      "city": "Aurelia",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51006,
      "latitude": 42.359052,
      "longitude": -95.546574,
      "city": "Battle Creek",
      "state": "IA",
      "county": "Ida"
  },
  {
      "zipCode": 51007,
      "latitude": 42.421334,
      "longitude": -96.165179,
      "city": "Bronson",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51008,
      "latitude": 42.809252,
      "longitude": -96.26257,
      "city": "Brunsville",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51009,
      "latitude": 42.9552,
      "longitude": -95.570082,
      "city": "Calumet",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51010,
      "latitude": 42.080842,
      "longitude": -95.903339,
      "city": "Castana",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51011,
      "latitude": 42.926468,
      "longitude": -96.494824,
      "city": "Chatsworth",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51012,
      "latitude": 42.721535,
      "longitude": -95.574297,
      "city": "Cherokee",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51014,
      "latitude": 42.779201,
      "longitude": -95.701743,
      "city": "Cleghorn",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51015,
      "latitude": 42.345024,
      "longitude": -96.087523,
      "city": "Climbing Hill",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51016,
      "latitude": 42.394029,
      "longitude": -95.969596,
      "city": "Correctionville",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51017,
      "latitude": 42.896225,
      "longitude": -96.309509,
      "city": "Craig",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51018,
      "latitude": 42.355069,
      "longitude": -96.015944,
      "city": "Cushing",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51019,
      "latitude": 42.339186,
      "longitude": -96.016316,
      "city": "Danbury",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51020,
      "latitude": 42.487916,
      "longitude": -95.430208,
      "city": "Galva",
      "state": "IA",
      "county": "Ida"
  },
  {
      "zipCode": 51022,
      "latitude": 42.974677,
      "longitude": -96.086114,
      "city": "Granville",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51023,
      "latitude": 43.022799,
      "longitude": -96.263075,
      "city": "Hawarden",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51024,
      "latitude": 42.625126,
      "longitude": -96.299512,
      "city": "Hinton",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51025,
      "latitude": 42.443085,
      "longitude": -95.530596,
      "city": "Holstein",
      "state": "IA",
      "county": "Ida"
  },
  {
      "zipCode": 51026,
      "latitude": 42.300778,
      "longitude": -96.092119,
      "city": "Hornick",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51027,
      "latitude": 43.039676,
      "longitude": -96.303174,
      "city": "Ireton",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51028,
      "latitude": 42.619263,
      "longitude": -96.077531,
      "city": "Kingsley",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51029,
      "latitude": 42.873445,
      "longitude": -95.565095,
      "city": "Larrabee",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51030,
      "latitude": 42.475035,
      "longitude": -96.03323,
      "city": "Lawton",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51031,
      "latitude": 42.749146,
      "longitude": -96.261735,
      "city": "Le Mars",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51033,
      "latitude": 42.865889,
      "longitude": -95.274623,
      "city": "Linn Grove",
      "state": "IA",
      "county": "Buena Vista"
  },
  {
      "zipCode": 51034,
      "latitude": 42.137392,
      "longitude": -95.913273,
      "city": "Mapleton",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51035,
      "latitude": 42.76416,
      "longitude": -95.786406,
      "city": "Marcus",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51036,
      "latitude": 42.975118,
      "longitude": -96.307201,
      "city": "Maurice",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51037,
      "latitude": 42.822577,
      "longitude": -95.642519,
      "city": "Meriden",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51038,
      "latitude": 42.764455,
      "longitude": -96.354983,
      "city": "Merrill",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51039,
      "latitude": 42.444948,
      "longitude": -96.069377,
      "city": "Moville",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51040,
      "latitude": 42.021131,
      "longitude": -96.129525,
      "city": "Onawa",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51041,
      "latitude": 43.033432,
      "longitude": -96.076051,
      "city": "Orange City",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51044,
      "latitude": 42.298397,
      "longitude": -95.972137,
      "city": "Oto",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51045,
      "latitude": 42.8199,
      "longitude": -96.056556,
      "city": "Oyens",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51046,
      "latitude": 42.982521,
      "longitude": -95.69489,
      "city": "Paullina",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51047,
      "latitude": 43.004019,
      "longitude": -95.299649,
      "city": "Peterson",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51048,
      "latitude": 42.394713,
      "longitude": -96.017208,
      "city": "Pierson",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51049,
      "latitude": 42.701397,
      "longitude": -95.70264,
      "city": "Quimby",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51050,
      "latitude": 42.793867,
      "longitude": -96.207399,
      "city": "Remsen",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51051,
      "latitude": 42.197953,
      "longitude": -95.976865,
      "city": "Rodney",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51052,
      "latitude": 42.399824,
      "longitude": -96.11349,
      "city": "Salix",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51053,
      "latitude": 42.480673,
      "longitude": -95.265117,
      "city": "Schaller",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 51054,
      "latitude": 42.387867,
      "longitude": -96.335924,
      "city": "Sergeant Bluff",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51055,
      "latitude": 42.345513,
      "longitude": -96.048349,
      "city": "Sloan",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51056,
      "latitude": 42.347007,
      "longitude": -96.037409,
      "city": "Smithland",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51058,
      "latitude": 42.997133,
      "longitude": -95.486296,
      "city": "Sutherland",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51059,
      "latitude": 42.033205,
      "longitude": -95.965126,
      "city": "Turin",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51060,
      "latitude": 42.033712,
      "longitude": -95.720898,
      "city": "Ute",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51061,
      "latitude": 42.691698,
      "longitude": -95.628595,
      "city": "Washta",
      "state": "IA",
      "county": "Cherokee"
  },
  {
      "zipCode": 51062,
      "latitude": 42.70123,
      "longitude": -96.50939,
      "city": "Westfield",
      "state": "IA",
      "county": "Plymouth"
  },
  {
      "zipCode": 51063,
      "latitude": 42.149647,
      "longitude": -96.179563,
      "city": "Whiting",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51101,
      "latitude": 42.494745,
      "longitude": -96.399356,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51102,
      "latitude": 42.368406,
      "longitude": -96.317951,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51103,
      "latitude": 42.523895,
      "longitude": -96.435508,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51104,
      "latitude": 42.472367,
      "longitude": -96.106247,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51105,
      "latitude": 42.481003,
      "longitude": -96.403691,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51106,
      "latitude": 42.471097,
      "longitude": -96.338435,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51108,
      "latitude": 42.5336,
      "longitude": -96.354682,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51109,
      "latitude": 42.527558,
      "longitude": -96.453358,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51111,
      "latitude": 42.423688,
      "longitude": -96.378007,
      "city": "Sioux City",
      "state": "IA",
      "county": "Woodbury"
  },
  {
      "zipCode": 51201,
      "latitude": 43.170886,
      "longitude": -95.792885,
      "city": "Sheldon",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51230,
      "latitude": 43.352717,
      "longitude": -96.311777,
      "city": "Alvord",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51231,
      "latitude": 43.098882,
      "longitude": -95.743011,
      "city": "Archer",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51232,
      "latitude": 43.309073,
      "longitude": -95.772984,
      "city": "Ashton",
      "state": "IA",
      "county": "Osceola"
  },
  {
      "zipCode": 51234,
      "latitude": 43.186085,
      "longitude": -96.005794,
      "city": "Boyden",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51235,
      "latitude": 43.301697,
      "longitude": -96.244934,
      "city": "Doon",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51237,
      "latitude": 43.345209,
      "longitude": -95.988879,
      "city": "George",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51238,
      "latitude": 43.07719,
      "longitude": -95.938839,
      "city": "Hospers",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51239,
      "latitude": 43.192887,
      "longitude": -96.180883,
      "city": "Hull",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51240,
      "latitude": 43.338402,
      "longitude": -96.293245,
      "city": "Inwood",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51241,
      "latitude": 43.423375,
      "longitude": -96.457839,
      "city": "Larchwood",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51242,
      "latitude": 43.444333,
      "longitude": -96.336111,
      "city": "Lester",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51243,
      "latitude": 43.389621,
      "longitude": -96.081644,
      "city": "Little Rock",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51244,
      "latitude": 43.214976,
      "longitude": -95.915089,
      "city": "Matlock",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51245,
      "latitude": 43.083854,
      "longitude": -95.625042,
      "city": "Primghar",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51246,
      "latitude": 43.415126,
      "longitude": -96.146555,
      "city": "Rock Rapids",
      "state": "IA",
      "county": "Lyon"
  },
  {
      "zipCode": 51247,
      "latitude": 43.084636,
      "longitude": -96.204802,
      "city": "Rock Valley",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51248,
      "latitude": 43.172865,
      "longitude": -95.643967,
      "city": "Sanborn",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51249,
      "latitude": 43.377681,
      "longitude": -95.624809,
      "city": "Sibley",
      "state": "IA",
      "county": "Osceola"
  },
  {
      "zipCode": 51250,
      "latitude": 43.084013,
      "longitude": -96.269648,
      "city": "Sioux Center",
      "state": "IA",
      "county": "Sioux"
  },
  {
      "zipCode": 51301,
      "latitude": 43.13291,
      "longitude": -95.177909,
      "city": "Spencer",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51330,
      "latitude": 43.415305,
      "longitude": -95.643435,
      "city": "Allendorf",
      "state": "IA",
      "county": "Osceola"
  },
  {
      "zipCode": 51331,
      "latitude": 43.377945,
      "longitude": -95.24015,
      "city": "Arnolds Park",
      "state": "IA",
      "county": "Dickinson"
  },
  {
      "zipCode": 51333,
      "latitude": 43.135131,
      "longitude": -94.983739,
      "city": "Dickens",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51334,
      "latitude": 43.377886,
      "longitude": -94.737792,
      "city": "Estherville",
      "state": "IA",
      "county": "Emmet"
  },
  {
      "zipCode": 51338,
      "latitude": 43.176799,
      "longitude": -95.308899,
      "city": "Everly",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51340,
      "latitude": 43.082426,
      "longitude": -95.151095,
      "city": "Fostoria",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51341,
      "latitude": 43.015704,
      "longitude": -95.043088,
      "city": "Gillett Grove",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51342,
      "latitude": 43.19802,
      "longitude": -94.736878,
      "city": "Graettinger",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 51343,
      "latitude": 43.107743,
      "longitude": -95.140993,
      "city": "Greenville",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51344,
      "latitude": 43.393593,
      "longitude": -94.704925,
      "city": "Gruver",
      "state": "IA",
      "county": "Emmet"
  },
  {
      "zipCode": 51345,
      "latitude": 43.399433,
      "longitude": -95.437464,
      "city": "Harris",
      "state": "IA",
      "county": "Osceola"
  },
  {
      "zipCode": 51346,
      "latitude": 43.154712,
      "longitude": -95.505959,
      "city": "Hartley",
      "state": "IA",
      "county": "Obrien"
  },
  {
      "zipCode": 51347,
      "latitude": 43.399671,
      "longitude": -95.284164,
      "city": "Lake Park",
      "state": "IA",
      "county": "Dickinson"
  },
  {
      "zipCode": 51349,
      "latitude": 43.377882,
      "longitude": -95.624809,
      "city": "May City",
      "state": "IA",
      "county": "Osceola"
  },
  {
      "zipCode": 51350,
      "latitude": 43.315953,
      "longitude": -95.614641,
      "city": "Melvin",
      "state": "IA",
      "county": "Osceola"
  },
  {
      "zipCode": 51351,
      "latitude": 43.367677,
      "longitude": -95.208331,
      "city": "Milford",
      "state": "IA",
      "county": "Dickinson"
  },
  {
      "zipCode": 51354,
      "latitude": 43.378852,
      "longitude": -95.626901,
      "city": "Ocheyedan",
      "state": "IA",
      "county": "Osceola"
  },
  {
      "zipCode": 51355,
      "latitude": 43.429006,
      "longitude": -95.077599,
      "city": "Okoboji",
      "state": "IA",
      "county": "Dickinson"
  },
  {
      "zipCode": 51357,
      "latitude": 43.043676,
      "longitude": -95.199867,
      "city": "Royal",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51358,
      "latitude": 43.130459,
      "longitude": -94.855389,
      "city": "Ruthven",
      "state": "IA",
      "county": "Palo Alto"
  },
  {
      "zipCode": 51360,
      "latitude": 43.407002,
      "longitude": -95.121813,
      "city": "Spirit Lake",
      "state": "IA",
      "county": "Dickinson"
  },
  {
      "zipCode": 51363,
      "latitude": 43.431836,
      "longitude": -94.946943,
      "city": "Superior",
      "state": "IA",
      "county": "Dickinson"
  },
  {
      "zipCode": 51364,
      "latitude": 43.35271,
      "longitude": -95.119487,
      "city": "Terril",
      "state": "IA",
      "county": "Dickinson"
  },
  {
      "zipCode": 51365,
      "latitude": 43.318868,
      "longitude": -94.796359,
      "city": "Wallingford",
      "state": "IA",
      "county": "Emmet"
  },
  {
      "zipCode": 51366,
      "latitude": 42.967221,
      "longitude": -95.013216,
      "city": "Webb",
      "state": "IA",
      "county": "Clay"
  },
  {
      "zipCode": 51401,
      "latitude": 42.042547,
      "longitude": -94.8338,
      "city": "Carroll",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51430,
      "latitude": 42.087657,
      "longitude": -95.015299,
      "city": "Arcadia",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51431,
      "latitude": 42.341553,
      "longitude": -95.371381,
      "city": "Arthur",
      "state": "IA",
      "county": "Ida"
  },
  {
      "zipCode": 51432,
      "latitude": 41.911924,
      "longitude": -95.135602,
      "city": "Aspinwall",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51433,
      "latitude": 42.340836,
      "longitude": -95.080439,
      "city": "Auburn",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 51436,
      "latitude": 42.15864,
      "longitude": -94.991919,
      "city": "Breda",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51439,
      "latitude": 42.08798,
      "longitude": -95.372611,
      "city": "Charter Oak",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51440,
      "latitude": 41.960798,
      "longitude": -94.833323,
      "city": "Dedham",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51441,
      "latitude": 42.123762,
      "longitude": -95.304518,
      "city": "Deloit",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51442,
      "latitude": 42.037235,
      "longitude": -95.381819,
      "city": "Denison",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51443,
      "latitude": 42.049241,
      "longitude": -94.802643,
      "city": "Glidden",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51444,
      "latitude": 42.008229,
      "longitude": -94.994311,
      "city": "Halbur",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51445,
      "latitude": 42.326783,
      "longitude": -95.477979,
      "city": "Ida Grove",
      "state": "IA",
      "county": "Ida"
  },
  {
      "zipCode": 51446,
      "latitude": 41.684667,
      "longitude": -95.325315,
      "city": "Irwin",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51447,
      "latitude": 41.718539,
      "longitude": -95.189811,
      "city": "Kirkman",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51448,
      "latitude": 42.046292,
      "longitude": -95.294668,
      "city": "Kiron",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51449,
      "latitude": 42.29709,
      "longitude": -94.721051,
      "city": "Lake City",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 51450,
      "latitude": 42.312348,
      "longitude": -95.028491,
      "city": "Lake View",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 51451,
      "latitude": 42.184481,
      "longitude": -94.691739,
      "city": "Lanesboro",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51452,
      "latitude": 42.125396,
      "longitude": -94.775361,
      "city": "Lidderdale",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51453,
      "latitude": 42.281733,
      "longitude": -94.542337,
      "city": "Lohrville",
      "state": "IA",
      "county": "Calhoun"
  },
  {
      "zipCode": 51454,
      "latitude": 41.968254,
      "longitude": -95.222803,
      "city": "Manilla",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51455,
      "latitude": 42.024754,
      "longitude": -94.861182,
      "city": "Manning",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51458,
      "latitude": 42.319844,
      "longitude": -95.100688,
      "city": "Odebolt",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 51459,
      "latitude": 42.041412,
      "longitude": -94.632143,
      "city": "Ralston",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51460,
      "latitude": 42.130428,
      "longitude": -95.574593,
      "city": "Ricketts",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51461,
      "latitude": 42.137253,
      "longitude": -95.388295,
      "city": "Schleswig",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51462,
      "latitude": 42.02913,
      "longitude": -94.541989,
      "city": "Scranton",
      "state": "IA",
      "county": "Greene"
  },
  {
      "zipCode": 51463,
      "latitude": 41.912702,
      "longitude": -94.898698,
      "city": "Templeton",
      "state": "IA",
      "county": "Carroll"
  },
  {
      "zipCode": 51465,
      "latitude": 42.037189,
      "longitude": -95.256476,
      "city": "Vail",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51466,
      "latitude": 42.268076,
      "longitude": -95.121242,
      "city": "Wall Lake",
      "state": "IA",
      "county": "Sac"
  },
  {
      "zipCode": 51467,
      "latitude": 42.080572,
      "longitude": -95.26403,
      "city": "Westside",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51501,
      "latitude": 41.23238,
      "longitude": -95.875086,
      "city": "Council Bluffs",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51502,
      "latitude": 41.332943,
      "longitude": -95.587197,
      "city": "Council Bluffs",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51503,
      "latitude": 41.286046,
      "longitude": -95.711085,
      "city": "Council Bluffs",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51510,
      "latitude": 41.287335,
      "longitude": -95.91689,
      "city": "Carter Lake",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51520,
      "latitude": 41.979124,
      "longitude": -95.450804,
      "city": "Arion",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51521,
      "latitude": 41.433448,
      "longitude": -95.342363,
      "city": "Avoca",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51523,
      "latitude": 41.915049,
      "longitude": -96.049414,
      "city": "Blencoe",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51525,
      "latitude": 41.224143,
      "longitude": -95.413327,
      "city": "Carson",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51526,
      "latitude": 41.363436,
      "longitude": -95.887827,
      "city": "Crescent",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51527,
      "latitude": 41.80531,
      "longitude": -95.353707,
      "city": "Defiance",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51528,
      "latitude": 41.950237,
      "longitude": -95.470108,
      "city": "Dow City",
      "state": "IA",
      "county": "Crawford"
  },
  {
      "zipCode": 51529,
      "latitude": 41.779196,
      "longitude": -95.716392,
      "city": "Dunlap",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51530,
      "latitude": 41.787073,
      "longitude": -95.438704,
      "city": "Earling",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51531,
      "latitude": 41.562409,
      "longitude": -95.129522,
      "city": "Elk Horn",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51532,
      "latitude": 41.119637,
      "longitude": -95.108675,
      "city": "Elliott",
      "state": "IA",
      "county": "Montgomery"
  },
  {
      "zipCode": 51533,
      "latitude": 41.016355,
      "longitude": -95.422695,
      "city": "Emerson",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51534,
      "latitude": 41.031806,
      "longitude": -95.71558,
      "city": "Glenwood",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51535,
      "latitude": 41.236242,
      "longitude": -95.041216,
      "city": "Griswold",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 51536,
      "latitude": 41.389941,
      "longitude": -95.372891,
      "city": "Hancock",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51537,
      "latitude": 41.667566,
      "longitude": -95.29087,
      "city": "Harlan",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51540,
      "latitude": 41.013156,
      "longitude": -95.500756,
      "city": "Hastings",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51541,
      "latitude": 41.12933,
      "longitude": -95.453376,
      "city": "Henderson",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51542,
      "latitude": 41.435092,
      "longitude": -95.831903,
      "city": "Honey Creek",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51543,
      "latitude": 41.657834,
      "longitude": -95.044476,
      "city": "Kimballton",
      "state": "IA",
      "county": "Audubon"
  },
  {
      "zipCode": 51544,
      "latitude": 41.252785,
      "longitude": -95.098198,
      "city": "Lewis",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 51545,
      "latitude": 41.787434,
      "longitude": -96.005056,
      "city": "Little Sioux",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51546,
      "latitude": 41.657856,
      "longitude": -95.786047,
      "city": "Logan",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51548,
      "latitude": 41.377671,
      "longitude": -95.599683,
      "city": "Mc Clelland",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51549,
      "latitude": 41.327892,
      "longitude": -95.514083,
      "city": "Macedonia",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51550,
      "latitude": 41.693412,
      "longitude": -95.889638,
      "city": "Magnolia",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51551,
      "latitude": 41.006817,
      "longitude": -95.594792,
      "city": "Malvern",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51552,
      "latitude": 41.447152,
      "longitude": -95.104078,
      "city": "Marne",
      "state": "IA",
      "county": "Cass"
  },
  {
      "zipCode": 51553,
      "latitude": 41.404922,
      "longitude": -95.549781,
      "city": "Minden",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51554,
      "latitude": 41.141404,
      "longitude": -95.700491,
      "city": "Mineola",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51555,
      "latitude": 41.605102,
      "longitude": -95.908803,
      "city": "Missouri Valley",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51556,
      "latitude": 41.643973,
      "longitude": -96.013027,
      "city": "Modale",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51557,
      "latitude": 41.739098,
      "longitude": -95.99577,
      "city": "Mondamin",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51558,
      "latitude": 41.939436,
      "longitude": -95.853673,
      "city": "Moorhead",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51559,
      "latitude": 41.390749,
      "longitude": -95.583086,
      "city": "Neola",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51560,
      "latitude": 41.324842,
      "longitude": -95.422978,
      "city": "Oakland",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51561,
      "latitude": 41.011257,
      "longitude": -95.808797,
      "city": "Pacific Junction",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51562,
      "latitude": 41.701435,
      "longitude": -95.492423,
      "city": "Panama",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51563,
      "latitude": 41.634002,
      "longitude": -95.788095,
      "city": "Persia",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51564,
      "latitude": 41.805536,
      "longitude": -95.898207,
      "city": "Pisgah",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51565,
      "latitude": 41.708156,
      "longitude": -95.44989,
      "city": "Portsmouth",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51566,
      "latitude": 41.030516,
      "longitude": -95.19404,
      "city": "Red Oak",
      "state": "IA",
      "county": "Montgomery"
  },
  {
      "zipCode": 51570,
      "latitude": 41.476766,
      "longitude": -95.370203,
      "city": "Shelby",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51571,
      "latitude": 41.110642,
      "longitude": -95.604984,
      "city": "Silver City",
      "state": "IA",
      "county": "Mills"
  },
  {
      "zipCode": 51572,
      "latitude": 41.994014,
      "longitude": -95.778514,
      "city": "Soldier",
      "state": "IA",
      "county": "Monona"
  },
  {
      "zipCode": 51573,
      "latitude": 40.980119,
      "longitude": -95.115799,
      "city": "Stanton",
      "state": "IA",
      "county": "Montgomery"
  },
  {
      "zipCode": 51574,
      "latitude": 41.595663,
      "longitude": -95.441923,
      "city": "Tennant",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51575,
      "latitude": 41.230742,
      "longitude": -95.608582,
      "city": "Treynor",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51576,
      "latitude": 41.382994,
      "longitude": -95.721535,
      "city": "Underwood",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51577,
      "latitude": 41.418923,
      "longitude": -95.302966,
      "city": "Walnut",
      "state": "IA",
      "county": "Pottawattamie"
  },
  {
      "zipCode": 51578,
      "latitude": 41.719899,
      "longitude": -95.395187,
      "city": "Westphalia",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51579,
      "latitude": 41.745172,
      "longitude": -95.716704,
      "city": "Woodbine",
      "state": "IA",
      "county": "Harrison"
  },
  {
      "zipCode": 51591,
      "latitude": 41.030256,
      "longitude": -95.156205,
      "city": "Red Oak",
      "state": "IA",
      "county": "Montgomery"
  },
  {
      "zipCode": 51593,
      "latitude": 41.332943,
      "longitude": -95.587197,
      "city": "Harlan",
      "state": "IA",
      "county": "Shelby"
  },
  {
      "zipCode": 51601,
      "latitude": 40.718258,
      "longitude": -95.16372,
      "city": "Shenandoah",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51602,
      "latitude": 40.738309,
      "longitude": -95.149305,
      "city": "Shenandoah",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51603,
      "latitude": 40.738309,
      "longitude": -95.149305,
      "city": "Shenandoah",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51630,
      "latitude": 40.616851,
      "longitude": -95.152493,
      "city": "Blanchard",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51631,
      "latitude": 40.604915,
      "longitude": -95.017884,
      "city": "Braddyville",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51632,
      "latitude": 40.738247,
      "longitude": -95.108481,
      "city": "Clarinda",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51636,
      "latitude": 40.646512,
      "longitude": -95.199797,
      "city": "Coin",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51637,
      "latitude": 40.63122,
      "longitude": -95.12241,
      "city": "College Springs",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51638,
      "latitude": 40.77046,
      "longitude": -95.261263,
      "city": "Essex",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51639,
      "latitude": 40.72146,
      "longitude": -95.477606,
      "city": "Farragut",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51640,
      "latitude": 40.741699,
      "longitude": -95.583179,
      "city": "Hamburg",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51645,
      "latitude": 40.858205,
      "longitude": -95.432381,
      "city": "Imogene",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51646,
      "latitude": 40.722464,
      "longitude": -94.842837,
      "city": "New Market",
      "state": "IA",
      "county": "Taylor"
  },
  {
      "zipCode": 51647,
      "latitude": 40.609974,
      "longitude": -95.316476,
      "city": "Northboro",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51648,
      "latitude": 40.742301,
      "longitude": -95.761779,
      "city": "Percival",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51649,
      "latitude": 40.847951,
      "longitude": -95.537377,
      "city": "Randolph",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51650,
      "latitude": 40.662884,
      "longitude": -95.577257,
      "city": "Riverton",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51651,
      "latitude": 40.65747,
      "longitude": -95.024969,
      "city": "Shambaugh",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 51652,
      "latitude": 40.77152,
      "longitude": -95.626355,
      "city": "Sidney",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51653,
      "latitude": 40.822599,
      "longitude": -95.694406,
      "city": "Tabor",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51654,
      "latitude": 40.79334,
      "longitude": -95.758636,
      "city": "Thurman",
      "state": "IA",
      "county": "Fremont"
  },
  {
      "zipCode": 51656,
      "latitude": 40.737446,
      "longitude": -95.156217,
      "city": "Yorktown",
      "state": "IA",
      "county": "Page"
  },
  {
      "zipCode": 52001,
      "latitude": 42.458876,
      "longitude": -90.877135,
      "city": "Dubuque",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52002,
      "latitude": 42.532019,
      "longitude": -90.7718,
      "city": "Dubuque",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52003,
      "latitude": 42.436426,
      "longitude": -90.716921,
      "city": "Dubuque",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52004,
      "latitude": 42.484861,
      "longitude": -90.804116,
      "city": "Dubuque",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52030,
      "latitude": 42.164053,
      "longitude": -90.61074,
      "city": "Andrew",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52031,
      "latitude": 42.207444,
      "longitude": -90.49192,
      "city": "Bellevue",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52032,
      "latitude": 42.338928,
      "longitude": -90.864175,
      "city": "Bernard",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52033,
      "latitude": 42.362066,
      "longitude": -90.903195,
      "city": "Cascade",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52035,
      "latitude": 42.560859,
      "longitude": -91.347333,
      "city": "Colesburg",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52036,
      "latitude": 42.476609,
      "longitude": -91.311248,
      "city": "Delaware",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52037,
      "latitude": 41.9604,
      "longitude": -90.636408,
      "city": "Delmar",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52038,
      "latitude": 42.559127,
      "longitude": -91.47609,
      "city": "Dundee",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52039,
      "latitude": 42.534545,
      "longitude": -90.851368,
      "city": "Durango",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52040,
      "latitude": 42.421746,
      "longitude": -91.035655,
      "city": "Dyersville",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52041,
      "latitude": 42.519834,
      "longitude": -91.299742,
      "city": "Earlville",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52042,
      "latitude": 42.756961,
      "longitude": -91.34453,
      "city": "Edgewood",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52043,
      "latitude": 42.842609,
      "longitude": -91.408507,
      "city": "Elkader",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52044,
      "latitude": 42.762431,
      "longitude": -91.320305,
      "city": "Elkport",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52045,
      "latitude": 42.458113,
      "longitude": -90.933197,
      "city": "Epworth",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52046,
      "latitude": 42.45328,
      "longitude": -91.026374,
      "city": "Farley",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52047,
      "latitude": 42.984477,
      "longitude": -91.354816,
      "city": "Farmersburg",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52048,
      "latitude": 42.736871,
      "longitude": -91.249013,
      "city": "Garber",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52049,
      "latitude": 42.808244,
      "longitude": -91.257708,
      "city": "Garnavillo",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52050,
      "latitude": 42.554136,
      "longitude": -91.332816,
      "city": "Greeley",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52052,
      "latitude": 42.845668,
      "longitude": -91.249472,
      "city": "Guttenberg",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52053,
      "latitude": 42.539813,
      "longitude": -90.90914,
      "city": "Holy Cross",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52054,
      "latitude": 42.284057,
      "longitude": -90.595351,
      "city": "La Motte",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52055,
      "latitude": 42.862739,
      "longitude": -91.252459,
      "city": "Littleport",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52056,
      "latitude": 42.578283,
      "longitude": -91.062066,
      "city": "Luxemburg",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52057,
      "latitude": 42.492356,
      "longitude": -91.39658,
      "city": "Manchester",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52060,
      "latitude": 42.19807,
      "longitude": -90.605827,
      "city": "Maquoketa",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52064,
      "latitude": 42.120881,
      "longitude": -90.329993,
      "city": "Miles",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52065,
      "latitude": 42.547149,
      "longitude": -90.991701,
      "city": "New Vienna",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52066,
      "latitude": 42.667786,
      "longitude": -90.986518,
      "city": "North Buena Vista",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52068,
      "latitude": 42.432281,
      "longitude": -90.808309,
      "city": "Peosta",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52069,
      "latitude": 42.076366,
      "longitude": -90.432502,
      "city": "Preston",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52070,
      "latitude": 42.093989,
      "longitude": -90.360035,
      "city": "Sabula",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52071,
      "latitude": 42.319577,
      "longitude": -90.490269,
      "city": "Saint Donatus",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52072,
      "latitude": 42.876289,
      "longitude": -91.386044,
      "city": "Saint Olaf",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52073,
      "latitude": 42.5854,
      "longitude": -90.811885,
      "city": "Sherrill",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52074,
      "latitude": 42.098358,
      "longitude": -90.478972,
      "city": "Spragueville",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52075,
      "latitude": 42.164766,
      "longitude": -90.498366,
      "city": "Springbrook",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52076,
      "latitude": 42.720409,
      "longitude": -91.490023,
      "city": "Strawberry Point",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52077,
      "latitude": 42.844453,
      "longitude": -91.496533,
      "city": "Volga",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52078,
      "latitude": 42.388741,
      "longitude": -91.089374,
      "city": "Worthington",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52079,
      "latitude": 42.338446,
      "longitude": -90.714464,
      "city": "Zwingle",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52099,
      "latitude": 42.484861,
      "longitude": -90.804116,
      "city": "Dubuque",
      "state": "IA",
      "county": "Dubuque"
  },
  {
      "zipCode": 52101,
      "latitude": 43.294252,
      "longitude": -91.789582,
      "city": "Decorah",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52132,
      "latitude": 43.168758,
      "longitude": -91.867666,
      "city": "Calmar",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52133,
      "latitude": 43.136114,
      "longitude": -91.664324,
      "city": "Castalia",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52134,
      "latitude": 43.464701,
      "longitude": -92.40617,
      "city": "Chester",
      "state": "IA",
      "county": "Howard"
  },
  {
      "zipCode": 52135,
      "latitude": 42.942633,
      "longitude": -91.674712,
      "city": "Clermont",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52136,
      "latitude": 43.35666,
      "longitude": -92.189287,
      "city": "Cresco",
      "state": "IA",
      "county": "Howard"
  },
  {
      "zipCode": 52140,
      "latitude": 43.43073,
      "longitude": -91.509087,
      "city": "Dorchester",
      "state": "IA",
      "county": "Allamakee"
  },
  {
      "zipCode": 52141,
      "latitude": 42.941219,
      "longitude": -91.699573,
      "city": "Elgin",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52142,
      "latitude": 42.885443,
      "longitude": -91.8084,
      "city": "Fayette",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52144,
      "latitude": 43.268916,
      "longitude": -91.924788,
      "city": "Fort Atkinson",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52146,
      "latitude": 43.288601,
      "longitude": -91.209339,
      "city": "Harpers Ferry",
      "state": "IA",
      "county": "Allamakee"
  },
  {
      "zipCode": 52147,
      "latitude": 42.938391,
      "longitude": -91.929151,
      "city": "Hawkeye",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52149,
      "latitude": 43.463765,
      "longitude": -91.689862,
      "city": "Highlandville",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52151,
      "latitude": 43.346574,
      "longitude": -91.277117,
      "city": "Lansing",
      "state": "IA",
      "county": "Allamakee"
  },
  {
      "zipCode": 52154,
      "latitude": 43.110826,
      "longitude": -92.169251,
      "city": "Lawler",
      "state": "IA",
      "county": "Chickasaw"
  },
  {
      "zipCode": 52155,
      "latitude": 43.410582,
      "longitude": -92.276168,
      "city": "Lime Springs",
      "state": "IA",
      "county": "Howard"
  },
  {
      "zipCode": 52156,
      "latitude": 43.020695,
      "longitude": -91.47665,
      "city": "Luana",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52157,
      "latitude": 42.91563,
      "longitude": -91.295555,
      "city": "Mc Gregor",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52158,
      "latitude": 43.036006,
      "longitude": -91.193057,
      "city": "Marquette",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52159,
      "latitude": 43.034025,
      "longitude": -91.393408,
      "city": "Monona",
      "state": "IA",
      "county": "Clayton"
  },
  {
      "zipCode": 52160,
      "latitude": 43.457526,
      "longitude": -91.354022,
      "city": "New Albin",
      "state": "IA",
      "county": "Allamakee"
  },
  {
      "zipCode": 52161,
      "latitude": 43.142181,
      "longitude": -91.763097,
      "city": "Ossian",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52162,
      "latitude": 43.175423,
      "longitude": -91.507751,
      "city": "Postville",
      "state": "IA",
      "county": "Allamakee"
  },
  {
      "zipCode": 52163,
      "latitude": 43.216975,
      "longitude": -92.092682,
      "city": "Protivin",
      "state": "IA",
      "county": "Howard"
  },
  {
      "zipCode": 52164,
      "latitude": 42.846576,
      "longitude": -91.896581,
      "city": "Randalia",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52165,
      "latitude": 43.253702,
      "longitude": -91.880018,
      "city": "Ridgeway",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52166,
      "latitude": 43.060717,
      "longitude": -91.923226,
      "city": "Saint Lucas",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52168,
      "latitude": 43.203195,
      "longitude": -91.944055,
      "city": "Spillville",
      "state": "IA",
      "county": "Winneshiek"
  },
  {
      "zipCode": 52169,
      "latitude": 42.841014,
      "longitude": -91.674168,
      "city": "Wadena",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52170,
      "latitude": 43.217292,
      "longitude": -91.310349,
      "city": "Waterville",
      "state": "IA",
      "county": "Allamakee"
  },
  {
      "zipCode": 52171,
      "latitude": 42.92664,
      "longitude": -91.976961,
      "city": "Waucoma",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52172,
      "latitude": 43.290026,
      "longitude": -91.445754,
      "city": "Waukon",
      "state": "IA",
      "county": "Allamakee"
  },
  {
      "zipCode": 52175,
      "latitude": 42.943828,
      "longitude": -91.833409,
      "city": "West Union",
      "state": "IA",
      "county": "Fayette"
  },
  {
      "zipCode": 52201,
      "latitude": 41.351113,
      "longitude": -91.581212,
      "city": "Ainsworth",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52202,
      "latitude": 42.157086,
      "longitude": -91.645825,
      "city": "Alburnett",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52203,
      "latitude": 41.812242,
      "longitude": -91.913869,
      "city": "Amana",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52204,
      "latitude": 41.686351,
      "longitude": -92.063603,
      "city": "Amana",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52205,
      "latitude": 42.10352,
      "longitude": -91.240145,
      "city": "Anamosa",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52206,
      "latitude": 42.057516,
      "longitude": -92.058526,
      "city": "Atkins",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52207,
      "latitude": 42.120007,
      "longitude": -90.816341,
      "city": "Baldwin",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52208,
      "latitude": 41.917133,
      "longitude": -92.242382,
      "city": "Belle Plaine",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52209,
      "latitude": 41.913181,
      "longitude": -92.112381,
      "city": "Blairstown",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52210,
      "latitude": 42.348147,
      "longitude": -92.003981,
      "city": "Brandon",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 52211,
      "latitude": 41.759702,
      "longitude": -92.436452,
      "city": "Brooklyn",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 52212,
      "latitude": 42.120097,
      "longitude": -91.029004,
      "city": "Center Junction",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52213,
      "latitude": 42.197041,
      "longitude": -91.744447,
      "city": "Center Point",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52214,
      "latitude": 42.186959,
      "longitude": -91.520359,
      "city": "Central City",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52215,
      "latitude": 42.036478,
      "longitude": -92.400098,
      "city": "Chelsea",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 52216,
      "latitude": 41.864242,
      "longitude": -91.024771,
      "city": "Clarence",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52217,
      "latitude": 42.083382,
      "longitude": -92.420884,
      "city": "Clutier",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 52218,
      "latitude": 42.250724,
      "longitude": -91.53445,
      "city": "Coggon",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52219,
      "latitude": 42.231046,
      "longitude": -91.395429,
      "city": "Prairieburg",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52220,
      "latitude": 41.73543,
      "longitude": -92.010162,
      "city": "Conroy",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52221,
      "latitude": 41.637767,
      "longitude": -92.347941,
      "city": "Guernsey",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 52222,
      "latitude": 41.59165,
      "longitude": -92.373998,
      "city": "Deep River",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 52223,
      "latitude": 42.413678,
      "longitude": -91.31071,
      "city": "Delhi",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52224,
      "latitude": 42.195645,
      "longitude": -92.36492,
      "city": "Dysart",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 52225,
      "latitude": 41.990954,
      "longitude": -92.363359,
      "city": "Elberon",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 52226,
      "latitude": 41.988971,
      "longitude": -90.73596,
      "city": "Elwood",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52227,
      "latitude": 41.910425,
      "longitude": -91.575268,
      "city": "Ely",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52228,
      "latitude": 41.912837,
      "longitude": -91.764387,
      "city": "Fairfax",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52229,
      "latitude": 42.137698,
      "longitude": -92.160995,
      "city": "Garrison",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52231,
      "latitude": 41.357763,
      "longitude": -92.063442,
      "city": "Harper",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52232,
      "latitude": 41.810889,
      "longitude": -92.342571,
      "city": "Hartwick",
      "state": "IA",
      "county": "Poweshiek"
  },
  {
      "zipCode": 52233,
      "latitude": 42.047863,
      "longitude": -91.685645,
      "city": "Hiawatha",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52235,
      "latitude": 41.555704,
      "longitude": -91.530842,
      "city": "Hills",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52236,
      "latitude": 41.730806,
      "longitude": -91.881072,
      "city": "Homestead",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52237,
      "latitude": 42.366671,
      "longitude": -91.276347,
      "city": "Hopkinton",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52240,
      "latitude": 41.648207,
      "longitude": -91.541579,
      "city": "Iowa City",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52241,
      "latitude": 41.699942,
      "longitude": -91.6332,
      "city": "Coralville",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52242,
      "latitude": 41.733924,
      "longitude": -91.555328,
      "city": "Iowa City",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52243,
      "latitude": 41.642657,
      "longitude": -91.599974,
      "city": "Iowa City",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52244,
      "latitude": 41.572682,
      "longitude": -91.661901,
      "city": "Iowa City",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52245,
      "latitude": 41.668592,
      "longitude": -91.518388,
      "city": "Iowa City",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52246,
      "latitude": 41.600628,
      "longitude": -91.589264,
      "city": "Iowa City",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52247,
      "latitude": 41.460979,
      "longitude": -91.698928,
      "city": "Kalona",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52248,
      "latitude": 41.356073,
      "longitude": -92.001798,
      "city": "Keota",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52249,
      "latitude": 42.007605,
      "longitude": -92.071971,
      "city": "Keystone",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52251,
      "latitude": 41.710377,
      "longitude": -92.189413,
      "city": "Ladora",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52252,
      "latitude": 42.191979,
      "longitude": -91.225836,
      "city": "Langworthy",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52253,
      "latitude": 41.914581,
      "longitude": -91.385445,
      "city": "Lisbon",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52254,
      "latitude": 41.876909,
      "longitude": -90.541188,
      "city": "Lost Nation",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52255,
      "latitude": 41.856539,
      "longitude": -90.966516,
      "city": "Lowden",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52257,
      "latitude": 42.003812,
      "longitude": -92.163137,
      "city": "Luzerne",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52301,
      "latitude": 41.768214,
      "longitude": -92.094866,
      "city": "Marengo",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52302,
      "latitude": 42.056938,
      "longitude": -91.573963,
      "city": "Marion",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52305,
      "latitude": 42.018308,
      "longitude": -91.321727,
      "city": "Martelle",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52306,
      "latitude": 41.866983,
      "longitude": -91.269378,
      "city": "Mechanicsville",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52307,
      "latitude": 41.79544,
      "longitude": -91.901776,
      "city": "Middle Amana",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52308,
      "latitude": 41.576444,
      "longitude": -92.166645,
      "city": "Millersburg",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52309,
      "latitude": 42.13894,
      "longitude": -90.864621,
      "city": "Monmouth",
      "state": "IA",
      "county": "Jackson"
  },
  {
      "zipCode": 52310,
      "latitude": 42.139945,
      "longitude": -91.158852,
      "city": "Monticello",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52312,
      "latitude": 42.048397,
      "longitude": -91.178455,
      "city": "Morley",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52313,
      "latitude": 42.254106,
      "longitude": -92.098959,
      "city": "Mount Auburn",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52314,
      "latitude": 41.939802,
      "longitude": -91.450762,
      "city": "Mount Vernon",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52315,
      "latitude": 42.006958,
      "longitude": -91.970161,
      "city": "Newhall",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52316,
      "latitude": 41.555588,
      "longitude": -92.067635,
      "city": "North English",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52317,
      "latitude": 41.763944,
      "longitude": -91.604798,
      "city": "North Liberty",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52318,
      "latitude": 41.905263,
      "longitude": -91.900096,
      "city": "Norway",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52319,
      "latitude": 41.642657,
      "longitude": -91.599974,
      "city": "Oakdale",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52320,
      "latitude": 42.08132,
      "longitude": -91.116054,
      "city": "Olin",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52321,
      "latitude": 42.1065,
      "longitude": -91.020988,
      "city": "Onslow",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52322,
      "latitude": 41.700994,
      "longitude": -91.730339,
      "city": "Oxford",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52323,
      "latitude": 42.030946,
      "longitude": -91.005688,
      "city": "Oxford Junction",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52324,
      "latitude": 42.049832,
      "longitude": -91.788933,
      "city": "Palo",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52325,
      "latitude": 41.575705,
      "longitude": -91.939701,
      "city": "Parnell",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52326,
      "latitude": 42.469908,
      "longitude": -91.710553,
      "city": "Quasqueton",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 52327,
      "latitude": 41.39539,
      "longitude": -91.580364,
      "city": "Riverside",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52328,
      "latitude": 42.073943,
      "longitude": -91.665177,
      "city": "Robins",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52329,
      "latitude": 42.469721,
      "longitude": -91.773824,
      "city": "Rowley",
      "state": "IA",
      "county": "Buchanan"
  },
  {
      "zipCode": 52330,
      "latitude": 42.367794,
      "longitude": -91.445994,
      "city": "Ryan",
      "state": "IA",
      "county": "Delaware"
  },
  {
      "zipCode": 52332,
      "latitude": 42.101443,
      "longitude": -91.914187,
      "city": "Shellsburg",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52333,
      "latitude": 41.792851,
      "longitude": -91.506086,
      "city": "Solon",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52334,
      "latitude": 41.724365,
      "longitude": -91.939343,
      "city": "South Amana",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52335,
      "latitude": 41.452856,
      "longitude": -92.067973,
      "city": "South English",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52336,
      "latitude": 42.063342,
      "longitude": -91.471893,
      "city": "Springville",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52337,
      "latitude": 41.89206,
      "longitude": -91.152099,
      "city": "Stanwood",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52338,
      "latitude": 41.827112,
      "longitude": -91.721642,
      "city": "Swisher",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52339,
      "latitude": 41.934093,
      "longitude": -92.585494,
      "city": "Tama",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 52340,
      "latitude": 41.701791,
      "longitude": -91.677347,
      "city": "Tiffin",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52341,
      "latitude": 42.111669,
      "longitude": -91.732863,
      "city": "Toddville",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52342,
      "latitude": 42.068965,
      "longitude": -92.551725,
      "city": "Toledo",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 52344,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Troy Mills",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52345,
      "latitude": 42.236512,
      "longitude": -91.888136,
      "city": "Urbana",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52346,
      "latitude": 42.014398,
      "longitude": -92.067227,
      "city": "Van Horne",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52347,
      "latitude": 41.706644,
      "longitude": -92.25127,
      "city": "Victor",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52348,
      "latitude": 41.980201,
      "longitude": -92.375713,
      "city": "Vining",
      "state": "IA",
      "county": "Tama"
  },
  {
      "zipCode": 52349,
      "latitude": 42.087347,
      "longitude": -91.987332,
      "city": "Vinton",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52350,
      "latitude": 42.091217,
      "longitude": -91.385047,
      "city": "Viola",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52351,
      "latitude": 41.877045,
      "longitude": -91.836855,
      "city": "Walford",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52352,
      "latitude": 42.255159,
      "longitude": -91.726637,
      "city": "Walker",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52353,
      "latitude": 41.31663,
      "longitude": -91.751808,
      "city": "Washington",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52354,
      "latitude": 41.92003,
      "longitude": -91.978744,
      "city": "Watkins",
      "state": "IA",
      "county": "Benton"
  },
  {
      "zipCode": 52355,
      "latitude": 41.449144,
      "longitude": -92.169002,
      "city": "Webster",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52356,
      "latitude": 41.448663,
      "longitude": -91.840264,
      "city": "Wellman",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52358,
      "latitude": 41.728915,
      "longitude": -91.25056,
      "city": "West Branch",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52359,
      "latitude": 41.393969,
      "longitude": -91.740025,
      "city": "West Chester",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52361,
      "latitude": 41.649999,
      "longitude": -92.065181,
      "city": "Williamsburg",
      "state": "IA",
      "county": "Iowa"
  },
  {
      "zipCode": 52362,
      "latitude": 42.065281,
      "longitude": -91.078891,
      "city": "Wyoming",
      "state": "IA",
      "county": "Jones"
  },
  {
      "zipCode": 52401,
      "latitude": 41.976612,
      "longitude": -91.657578,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52402,
      "latitude": 42.020105,
      "longitude": -91.681029,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52403,
      "latitude": 41.972936,
      "longitude": -91.58127,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52404,
      "latitude": 41.919542,
      "longitude": -91.704467,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52405,
      "latitude": 41.987067,
      "longitude": -91.753326,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52406,
      "latitude": 42.287191,
      "longitude": -91.777472,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52407,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52408,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52409,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52410,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52411,
      "latitude": 42.049262,
      "longitude": -91.726331,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52497,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52498,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52499,
      "latitude": 42.079415,
      "longitude": -91.599215,
      "city": "Cedar Rapids",
      "state": "IA",
      "county": "Linn"
  },
  {
      "zipCode": 52501,
      "latitude": 41.030933,
      "longitude": -92.409827,
      "city": "Ottumwa",
      "state": "IA",
      "county": "Wapello"
  },
  {
      "zipCode": 52530,
      "latitude": 40.979036,
      "longitude": -92.426183,
      "city": "Agency",
      "state": "IA",
      "county": "Wapello"
  },
  {
      "zipCode": 52531,
      "latitude": 41.00004,
      "longitude": -92.835186,
      "city": "Albia",
      "state": "IA",
      "county": "Monroe"
  },
  {
      "zipCode": 52533,
      "latitude": 41.013559,
      "longitude": -92.0835,
      "city": "Batavia",
      "state": "IA",
      "county": "Jefferson"
  },
  {
      "zipCode": 52534,
      "latitude": 41.29511,
      "longitude": -92.598024,
      "city": "Beacon",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 52535,
      "latitude": 40.827181,
      "longitude": -91.951174,
      "city": "Birmingham",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52536,
      "latitude": 40.981083,
      "longitude": -92.570024,
      "city": "Blakesburg",
      "state": "IA",
      "county": "Wapello"
  },
  {
      "zipCode": 52537,
      "latitude": 40.746315,
      "longitude": -92.406245,
      "city": "Bloomfield",
      "state": "IA",
      "county": "Davis"
  },
  {
      "zipCode": 52538,
      "latitude": 40.745286,
      "longitude": -92.408679,
      "city": "West Grove",
      "state": "IA",
      "county": "Davis"
  },
  {
      "zipCode": 52540,
      "latitude": 41.211747,
      "longitude": -91.845034,
      "city": "Brighton",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52542,
      "latitude": 40.643077,
      "longitude": -92.069754,
      "city": "Cantril",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52543,
      "latitude": 41.219972,
      "longitude": -92.506291,
      "city": "Cedar",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 52544,
      "latitude": 40.686447,
      "longitude": -92.881614,
      "city": "Centerville",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52548,
      "latitude": 41.060681,
      "longitude": -92.552769,
      "city": "Chillicothe",
      "state": "IA",
      "county": "Wapello"
  },
  {
      "zipCode": 52549,
      "latitude": 40.626921,
      "longitude": -92.953118,
      "city": "Cincinnati",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52550,
      "latitude": 41.28437,
      "longitude": -92.351218,
      "city": "Delta",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52551,
      "latitude": 40.837786,
      "longitude": -92.085948,
      "city": "Douds",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52552,
      "latitude": 40.819643,
      "longitude": -92.500244,
      "city": "Drakesville",
      "state": "IA",
      "county": "Davis"
  },
  {
      "zipCode": 52553,
      "latitude": 41.112342,
      "longitude": -92.570765,
      "city": "Eddyville",
      "state": "IA",
      "county": "Wapello"
  },
  {
      "zipCode": 52554,
      "latitude": 41.030032,
      "longitude": -92.408837,
      "city": "Eldon",
      "state": "IA",
      "county": "Wapello"
  },
  {
      "zipCode": 52555,
      "latitude": 40.630992,
      "longitude": -92.884722,
      "city": "Exline",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52556,
      "latitude": 41.030998,
      "longitude": -91.957527,
      "city": "Fairfield",
      "state": "IA",
      "county": "Jefferson"
  },
  {
      "zipCode": 52557,
      "latitude": 41.016566,
      "longitude": -91.96821,
      "city": "Fairfield",
      "state": "IA",
      "county": "Jefferson"
  },
  {
      "zipCode": 52560,
      "latitude": 40.84047,
      "longitude": -92.284098,
      "city": "Floris",
      "state": "IA",
      "county": "Davis"
  },
  {
      "zipCode": 52561,
      "latitude": 41.227386,
      "longitude": -92.467531,
      "city": "Fremont",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 52562,
      "latitude": 41.336267,
      "longitude": -92.178366,
      "city": "Hayesville",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52563,
      "latitude": 41.211206,
      "longitude": -92.297854,
      "city": "Hedrick",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52565,
      "latitude": 40.704158,
      "longitude": -91.853076,
      "city": "Keosauqua",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52566,
      "latitude": 41.143796,
      "longitude": -92.5006,
      "city": "Kirkville",
      "state": "IA",
      "county": "Wapello"
  },
  {
      "zipCode": 52567,
      "latitude": 40.951469,
      "longitude": -92.082459,
      "city": "Libertyville",
      "state": "IA",
      "county": "Jefferson"
  },
  {
      "zipCode": 52568,
      "latitude": 41.194806,
      "longitude": -92.271472,
      "city": "Martinsburg",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52569,
      "latitude": 41.03227,
      "longitude": -93.035367,
      "city": "Melrose",
      "state": "IA",
      "county": "Monroe"
  },
  {
      "zipCode": 52570,
      "latitude": 40.671781,
      "longitude": -92.162092,
      "city": "Milton",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52571,
      "latitude": 40.866821,
      "longitude": -92.736442,
      "city": "Moravia",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52572,
      "latitude": 40.740795,
      "longitude": -92.806081,
      "city": "Moulton",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52573,
      "latitude": 40.749913,
      "longitude": -91.948106,
      "city": "Mount Sterling",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52574,
      "latitude": 40.780847,
      "longitude": -92.979322,
      "city": "Mystic",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52576,
      "latitude": 41.218708,
      "longitude": -92.120482,
      "city": "Ollie",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52577,
      "latitude": 41.283366,
      "longitude": -92.662675,
      "city": "Oskaloosa",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 52580,
      "latitude": 41.119571,
      "longitude": -92.092371,
      "city": "Packwood",
      "state": "IA",
      "county": "Jefferson"
  },
  {
      "zipCode": 52581,
      "latitude": 40.818638,
      "longitude": -92.975714,
      "city": "Plano",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52583,
      "latitude": 40.786539,
      "longitude": -93.141255,
      "city": "Promise City",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 52584,
      "latitude": 40.649038,
      "longitude": -92.265179,
      "city": "Pulaski",
      "state": "IA",
      "county": "Davis"
  },
  {
      "zipCode": 52585,
      "latitude": 41.20699,
      "longitude": -92.126742,
      "city": "Richland",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52586,
      "latitude": 41.390468,
      "longitude": -92.560102,
      "city": "Rose Hill",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 52588,
      "latitude": 40.749913,
      "longitude": -91.948106,
      "city": "Selma",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52590,
      "latitude": 40.710521,
      "longitude": -93.130436,
      "city": "Seymour",
      "state": "IA",
      "county": "Wayne"
  },
  {
      "zipCode": 52591,
      "latitude": 41.310884,
      "longitude": -92.159246,
      "city": "Sigourney",
      "state": "IA",
      "county": "Keokuk"
  },
  {
      "zipCode": 52593,
      "latitude": 40.80475,
      "longitude": -92.714834,
      "city": "Udell",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52594,
      "latitude": 40.812488,
      "longitude": -92.701454,
      "city": "Unionville",
      "state": "IA",
      "county": "Appanoose"
  },
  {
      "zipCode": 52595,
      "latitude": 41.286383,
      "longitude": -92.618357,
      "city": "University Park",
      "state": "IA",
      "county": "Mahaska"
  },
  {
      "zipCode": 52601,
      "latitude": 40.898412,
      "longitude": -91.164393,
      "city": "Burlington",
      "state": "IA",
      "county": "Des Moines"
  },
  {
      "zipCode": 52619,
      "latitude": 40.546499,
      "longitude": -91.585217,
      "city": "Argyle",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52620,
      "latitude": 40.672405,
      "longitude": -91.770841,
      "city": "Bonaparte",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52621,
      "latitude": 41.20951,
      "longitude": -91.551283,
      "city": "Crawfordsville",
      "state": "IA",
      "county": "Washington"
  },
  {
      "zipCode": 52623,
      "latitude": 40.866863,
      "longitude": -91.307634,
      "city": "Danville",
      "state": "IA",
      "county": "Des Moines"
  },
  {
      "zipCode": 52624,
      "latitude": 40.743432,
      "longitude": -91.323858,
      "city": "Denmark",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52625,
      "latitude": 40.631583,
      "longitude": -91.56008,
      "city": "Donnellson",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52626,
      "latitude": 40.639028,
      "longitude": -91.7394,
      "city": "Farmington",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52627,
      "latitude": 40.596949,
      "longitude": -91.434473,
      "city": "Fort Madison",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52630,
      "latitude": 40.845708,
      "longitude": -91.670699,
      "city": "Hillsboro",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52631,
      "latitude": 40.769982,
      "longitude": -91.613795,
      "city": "Houghton",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52632,
      "latitude": 40.584716,
      "longitude": -91.471682,
      "city": "Keokuk",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52635,
      "latitude": 40.988685,
      "longitude": -91.785249,
      "city": "Lockridge",
      "state": "IA",
      "county": "Jefferson"
  },
  {
      "zipCode": 52637,
      "latitude": 41.012819,
      "longitude": -91.127994,
      "city": "Mediapolis",
      "state": "IA",
      "county": "Des Moines"
  },
  {
      "zipCode": 52638,
      "latitude": 40.848173,
      "longitude": -91.269867,
      "city": "Middletown",
      "state": "IA",
      "county": "Des Moines"
  },
  {
      "zipCode": 52639,
      "latitude": 40.542856,
      "longitude": -91.468901,
      "city": "Montrose",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52640,
      "latitude": 41.135562,
      "longitude": -91.291067,
      "city": "Morning Sun",
      "state": "IA",
      "county": "Louisa"
  },
  {
      "zipCode": 52641,
      "latitude": 40.994595,
      "longitude": -91.573713,
      "city": "Mount Pleasant",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52642,
      "latitude": 40.977395,
      "longitude": -91.692312,
      "city": "Rome",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52644,
      "latitude": 41.010776,
      "longitude": -91.43819,
      "city": "Mount Union",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52645,
      "latitude": 40.907221,
      "longitude": -91.527126,
      "city": "New London",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52646,
      "latitude": 41.117324,
      "longitude": -91.074161,
      "city": "Oakville",
      "state": "IA",
      "county": "Louisa"
  },
  {
      "zipCode": 52647,
      "latitude": 41.146979,
      "longitude": -91.544426,
      "city": "Olds",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52648,
      "latitude": 40.594704,
      "longitude": -91.415648,
      "city": "Pilot Grove",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52649,
      "latitude": 40.857375,
      "longitude": -91.610895,
      "city": "Salem",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52650,
      "latitude": 40.920383,
      "longitude": -91.208938,
      "city": "Sperry",
      "state": "IA",
      "county": "Des Moines"
  },
  {
      "zipCode": 52651,
      "latitude": 40.868097,
      "longitude": -91.889766,
      "city": "Stockport",
      "state": "IA",
      "county": "Van Buren"
  },
  {
      "zipCode": 52652,
      "latitude": 41.104302,
      "longitude": -91.546988,
      "city": "Swedesburg",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52653,
      "latitude": 41.203527,
      "longitude": -91.207463,
      "city": "Wapello",
      "state": "IA",
      "county": "Louisa"
  },
  {
      "zipCode": 52654,
      "latitude": 41.033746,
      "longitude": -91.561327,
      "city": "Wayland",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52655,
      "latitude": 40.850783,
      "longitude": -91.229873,
      "city": "West Burlington",
      "state": "IA",
      "county": "Des Moines"
  },
  {
      "zipCode": 52656,
      "latitude": 40.610778,
      "longitude": -91.461569,
      "city": "West Point",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52657,
      "latitude": 40.758742,
      "longitude": -91.478305,
      "city": "Saint Paul",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52658,
      "latitude": 40.7129,
      "longitude": -91.23819,
      "city": "Wever",
      "state": "IA",
      "county": "Lee"
  },
  {
      "zipCode": 52659,
      "latitude": 41.082887,
      "longitude": -91.543142,
      "city": "Winfield",
      "state": "IA",
      "county": "Henry"
  },
  {
      "zipCode": 52660,
      "latitude": 40.983507,
      "longitude": -91.302644,
      "city": "Yarmouth",
      "state": "IA",
      "county": "Des Moines"
  },
  {
      "zipCode": 52701,
      "latitude": 41.964393,
      "longitude": -90.222348,
      "city": "Andover",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52720,
      "latitude": 41.475075,
      "longitude": -91.16406,
      "city": "Atalissa",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52721,
      "latitude": 41.749923,
      "longitude": -90.96289,
      "city": "Bennett",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52722,
      "latitude": 41.57048,
      "longitude": -90.529138,
      "city": "Bettendorf",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52726,
      "latitude": 41.61105,
      "longitude": -90.660178,
      "city": "Blue Grass",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52727,
      "latitude": 41.953426,
      "longitude": -90.326961,
      "city": "Bryant",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52728,
      "latitude": 41.469263,
      "longitude": -90.736557,
      "city": "Buffalo",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52729,
      "latitude": 41.829003,
      "longitude": -90.740567,
      "city": "Calamus",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52730,
      "latitude": 41.813438,
      "longitude": -90.317574,
      "city": "Camanche",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52731,
      "latitude": 41.966744,
      "longitude": -90.555111,
      "city": "Charlotte",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52732,
      "latitude": 41.899176,
      "longitude": -90.301599,
      "city": "Clinton",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52733,
      "latitude": 41.880619,
      "longitude": -90.519519,
      "city": "Clinton",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52736,
      "latitude": 41.880619,
      "longitude": -90.519519,
      "city": "Clinton",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52737,
      "latitude": 41.25926,
      "longitude": -91.374686,
      "city": "Columbus City",
      "state": "IA",
      "county": "Louisa"
  },
  {
      "zipCode": 52738,
      "latitude": 41.292638,
      "longitude": -91.300624,
      "city": "Columbus Junction",
      "state": "IA",
      "county": "Louisa"
  },
  {
      "zipCode": 52739,
      "latitude": 41.401379,
      "longitude": -91.338658,
      "city": "Conesville",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52742,
      "latitude": 41.841735,
      "longitude": -90.55621,
      "city": "De Witt",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52745,
      "latitude": 41.711283,
      "longitude": -90.75534,
      "city": "Dixon",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52746,
      "latitude": 41.71654,
      "longitude": -90.646985,
      "city": "Donahue",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52747,
      "latitude": 41.772184,
      "longitude": -91.132045,
      "city": "Durant",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52748,
      "latitude": 41.59972,
      "longitude": -90.564348,
      "city": "Eldridge",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52749,
      "latitude": 41.3486,
      "longitude": -91.123729,
      "city": "Fruitland",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52750,
      "latitude": 41.942428,
      "longitude": -90.378578,
      "city": "Goose Lake",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52751,
      "latitude": 41.850144,
      "longitude": -90.669527,
      "city": "Grand Mound",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52752,
      "latitude": 41.256712,
      "longitude": -91.19669,
      "city": "Grandview",
      "state": "IA",
      "county": "Louisa"
  },
  {
      "zipCode": 52753,
      "latitude": 41.568131,
      "longitude": -90.537234,
      "city": "Le Claire",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52754,
      "latitude": 41.304284,
      "longitude": -91.249503,
      "city": "Letts",
      "state": "IA",
      "county": "Louisa"
  },
  {
      "zipCode": 52755,
      "latitude": 41.510935,
      "longitude": -91.440616,
      "city": "Lone Tree",
      "state": "IA",
      "county": "Johnson"
  },
  {
      "zipCode": 52756,
      "latitude": 41.726242,
      "longitude": -90.540219,
      "city": "Long Grove",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52757,
      "latitude": 41.806528,
      "longitude": -90.370566,
      "city": "Low Moor",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52758,
      "latitude": 41.743048,
      "longitude": -90.453905,
      "city": "Mc Causland",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52759,
      "latitude": 41.462494,
      "longitude": -90.808767,
      "city": "Montpelier",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52760,
      "latitude": 41.552779,
      "longitude": -91.058797,
      "city": "Moscow",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52761,
      "latitude": 41.462426,
      "longitude": -91.053888,
      "city": "Muscatine",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52765,
      "latitude": 41.713165,
      "longitude": -90.850502,
      "city": "New Liberty",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52766,
      "latitude": 41.492577,
      "longitude": -91.196155,
      "city": "Nichols",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52767,
      "latitude": 41.574582,
      "longitude": -90.42128,
      "city": "Pleasant Valley",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52768,
      "latitude": 41.69565,
      "longitude": -90.394692,
      "city": "Princeton",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52769,
      "latitude": 41.538729,
      "longitude": -90.856921,
      "city": "Stockton",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52771,
      "latitude": 41.999113,
      "longitude": -90.218704,
      "city": "Teeds Grove",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52772,
      "latitude": 41.772503,
      "longitude": -91.13248,
      "city": "Tipton",
      "state": "IA",
      "county": "Cedar"
  },
  {
      "zipCode": 52773,
      "latitude": 41.61358,
      "longitude": -90.753478,
      "city": "Walcott",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52774,
      "latitude": 41.917411,
      "longitude": -90.597017,
      "city": "Welton",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52776,
      "latitude": 41.529715,
      "longitude": -91.190607,
      "city": "West Liberty",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52777,
      "latitude": 41.873852,
      "longitude": -90.682244,
      "city": "Wheatland",
      "state": "IA",
      "county": "Clinton"
  },
  {
      "zipCode": 52778,
      "latitude": 41.559742,
      "longitude": -90.959154,
      "city": "Wilton",
      "state": "IA",
      "county": "Muscatine"
  },
  {
      "zipCode": 52801,
      "latitude": 41.527232,
      "longitude": -90.573686,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52802,
      "latitude": 41.497582,
      "longitude": -90.623887,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52803,
      "latitude": 41.538332,
      "longitude": -90.556985,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52804,
      "latitude": 41.556481,
      "longitude": -90.598757,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52805,
      "latitude": 41.613034,
      "longitude": -90.606284,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52806,
      "latitude": 41.545731,
      "longitude": -90.633688,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52807,
      "latitude": 41.571131,
      "longitude": -90.555825,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52808,
      "latitude": 41.613034,
      "longitude": -90.606284,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 52809,
      "latitude": 41.613034,
      "longitude": -90.606284,
      "city": "Davenport",
      "state": "IA",
      "county": "Scott"
  },
  {
      "zipCode": 53001,
      "latitude": 43.658488,
      "longitude": -87.973717,
      "city": "Adell",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53002,
      "latitude": 43.382128,
      "longitude": -88.26219,
      "city": "Allenton",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53003,
      "latitude": 43.217506,
      "longitude": -88.525315,
      "city": "Ashippun",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53004,
      "latitude": 43.499631,
      "longitude": -87.918042,
      "city": "Belgium",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53005,
      "latitude": 43.062707,
      "longitude": -88.105906,
      "city": "Brookfield",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53006,
      "latitude": 43.602789,
      "longitude": -88.52302,
      "city": "Brownsville",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53007,
      "latitude": 43.108459,
      "longitude": -88.071489,
      "city": "Butler",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53008,
      "latitude": 43.018696,
      "longitude": -88.302997,
      "city": "Brookfield",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53010,
      "latitude": 43.682727,
      "longitude": -88.350947,
      "city": "Campbellsport",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53011,
      "latitude": 43.725504,
      "longitude": -88.048378,
      "city": "Cascade",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53012,
      "latitude": 43.321475,
      "longitude": -88.004033,
      "city": "Cedarburg",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53013,
      "latitude": 43.590548,
      "longitude": -87.85532,
      "city": "Cedar Grove",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53014,
      "latitude": 44.020561,
      "longitude": -88.185906,
      "city": "Chilton",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 53015,
      "latitude": 43.935653,
      "longitude": -87.80346,
      "city": "Cleveland",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 53016,
      "latitude": 43.31546,
      "longitude": -88.720642,
      "city": "Clyman",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53017,
      "latitude": 43.209445,
      "longitude": -88.241501,
      "city": "Colgate",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53018,
      "latitude": 43.015666,
      "longitude": -88.386519,
      "city": "Delafield",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53019,
      "latitude": 43.672543,
      "longitude": -88.50384,
      "city": "Eden",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53020,
      "latitude": 43.827363,
      "longitude": -87.9915,
      "city": "Elkhart Lake",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53021,
      "latitude": 43.477015,
      "longitude": -87.961162,
      "city": "Fredonia",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53022,
      "latitude": 43.235477,
      "longitude": -88.124711,
      "city": "Germantown",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53023,
      "latitude": 43.772128,
      "longitude": -88.041299,
      "city": "Glenbeulah",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53024,
      "latitude": 43.299956,
      "longitude": -87.94104,
      "city": "Grafton",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53026,
      "latitude": 43.718294,
      "longitude": -87.618716,
      "city": "Greenbush",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53027,
      "latitude": 43.357616,
      "longitude": -88.319032,
      "city": "Hartford",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53029,
      "latitude": 43.123679,
      "longitude": -88.336087,
      "city": "Hartland",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53031,
      "latitude": 43.639395,
      "longitude": -87.915705,
      "city": "Hingham",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53032,
      "latitude": 43.422045,
      "longitude": -88.624268,
      "city": "Horicon",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53033,
      "latitude": 43.367185,
      "longitude": -88.252892,
      "city": "Hubertus",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53034,
      "latitude": 43.330523,
      "longitude": -88.603032,
      "city": "Hustisford",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53035,
      "latitude": 43.36296,
      "longitude": -88.581515,
      "city": "Iron Ridge",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53036,
      "latitude": 43.147748,
      "longitude": -88.588628,
      "city": "Ixonia",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53037,
      "latitude": 43.32393,
      "longitude": -88.152212,
      "city": "Jackson",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53038,
      "latitude": 43.092348,
      "longitude": -88.743276,
      "city": "Johnson Creek",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53039,
      "latitude": 43.372221,
      "longitude": -88.710157,
      "city": "Juneau",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53040,
      "latitude": 43.499425,
      "longitude": -88.206447,
      "city": "Kewaskum",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53042,
      "latitude": 43.96317,
      "longitude": -87.955059,
      "city": "Kiel",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 53044,
      "latitude": 43.748441,
      "longitude": -87.776246,
      "city": "Kohler",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53045,
      "latitude": 43.06081,
      "longitude": -88.15589,
      "city": "Brookfield",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53046,
      "latitude": 43.153279,
      "longitude": -88.164427,
      "city": "Lannon",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53047,
      "latitude": 43.256793,
      "longitude": -88.6281,
      "city": "Lebanon",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53048,
      "latitude": 43.516912,
      "longitude": -88.480811,
      "city": "Lomira",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53049,
      "latitude": 43.875302,
      "longitude": -88.287108,
      "city": "Malone",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53050,
      "latitude": 43.483463,
      "longitude": -88.545925,
      "city": "Mayville",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53051,
      "latitude": 43.124189,
      "longitude": -88.227543,
      "city": "Menomonee Falls",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53052,
      "latitude": 43.018696,
      "longitude": -88.302997,
      "city": "Menomonee Falls",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53056,
      "latitude": 43.146023,
      "longitude": -88.30975,
      "city": "Merton",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53057,
      "latitude": 43.711957,
      "longitude": -88.443585,
      "city": "Mount Calvary",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53058,
      "latitude": 43.109127,
      "longitude": -88.40248,
      "city": "Nashotah",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53059,
      "latitude": 43.289809,
      "longitude": -88.533958,
      "city": "Neosho",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53060,
      "latitude": 43.433807,
      "longitude": -88.062338,
      "city": "Newburg",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53061,
      "latitude": 43.949886,
      "longitude": -88.101954,
      "city": "New Holstein",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 53062,
      "latitude": 44.067942,
      "longitude": -88.223131,
      "city": "New Holstein",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 53063,
      "latitude": 43.957599,
      "longitude": -87.792815,
      "city": "Newton",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 53064,
      "latitude": 43.018696,
      "longitude": -88.302997,
      "city": "North Lake",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53065,
      "latitude": 43.690246,
      "longitude": -88.678209,
      "city": "Oakfield",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53066,
      "latitude": 43.087062,
      "longitude": -88.463305,
      "city": "Oconomowoc",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53069,
      "latitude": 43.117886,
      "longitude": -88.439353,
      "city": "Okauchee",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53070,
      "latitude": 43.620857,
      "longitude": -87.810616,
      "city": "Oostburg",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53072,
      "latitude": 43.080185,
      "longitude": -88.264875,
      "city": "Pewaukee",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53073,
      "latitude": 43.768454,
      "longitude": -87.975773,
      "city": "Plymouth",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53074,
      "latitude": 43.387702,
      "longitude": -87.881232,
      "city": "Port Washington",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53075,
      "latitude": 43.582924,
      "longitude": -88.000555,
      "city": "Random Lake",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53076,
      "latitude": 43.2727,
      "longitude": -88.206716,
      "city": "Richfield",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53078,
      "latitude": 43.304408,
      "longitude": -88.504428,
      "city": "Rubicon",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53079,
      "latitude": 43.797531,
      "longitude": -88.201679,
      "city": "Saint Cloud",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53080,
      "latitude": 43.432544,
      "longitude": -87.947537,
      "city": "Saukville",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53081,
      "latitude": 43.722489,
      "longitude": -87.856793,
      "city": "Sheboygan",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53082,
      "latitude": 43.718294,
      "longitude": -87.618716,
      "city": "Sheboygan",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53083,
      "latitude": 43.775428,
      "longitude": -87.860803,
      "city": "Sheboygan",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53085,
      "latitude": 43.726404,
      "longitude": -87.853069,
      "city": "Sheboygan Falls",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53086,
      "latitude": 43.327417,
      "longitude": -88.260878,
      "city": "Slinger",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53088,
      "latitude": 44.080455,
      "longitude": -88.312404,
      "city": "Stockbridge",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 53089,
      "latitude": 43.148887,
      "longitude": -88.245381,
      "city": "Sussex",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53090,
      "latitude": 43.443839,
      "longitude": -88.19631,
      "city": "West Bend",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53091,
      "latitude": 43.483635,
      "longitude": -88.450107,
      "city": "Theresa",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53092,
      "latitude": 43.235856,
      "longitude": -87.981188,
      "city": "Thiensville",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53093,
      "latitude": 43.662704,
      "longitude": -87.930254,
      "city": "Waldo",
      "state": "WI",
      "county": "Sheboygan"
  },
  {
      "zipCode": 53094,
      "latitude": 43.132599,
      "longitude": -88.743595,
      "city": "Watertown",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53095,
      "latitude": 43.422223,
      "longitude": -88.195862,
      "city": "West Bend",
      "state": "WI",
      "county": "Washington"
  },
  {
      "zipCode": 53097,
      "latitude": 43.247112,
      "longitude": -87.980914,
      "city": "Mequon",
      "state": "WI",
      "county": "Ozaukee"
  },
  {
      "zipCode": 53098,
      "latitude": 43.276444,
      "longitude": -88.715408,
      "city": "Watertown",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53099,
      "latitude": 43.414202,
      "longitude": -88.704914,
      "city": "Woodland",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53101,
      "latitude": 42.58098,
      "longitude": -87.662878,
      "city": "Bassett",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53102,
      "latitude": 42.500141,
      "longitude": -88.079983,
      "city": "Benet Lake",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53103,
      "latitude": 42.882441,
      "longitude": -88.205514,
      "city": "Big Bend",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53104,
      "latitude": 42.553518,
      "longitude": -88.028986,
      "city": "Bristol",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53105,
      "latitude": 42.692103,
      "longitude": -88.228793,
      "city": "Burlington",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53108,
      "latitude": 42.803697,
      "longitude": -87.930936,
      "city": "Caledonia",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53109,
      "latitude": 42.535968,
      "longitude": -88.144386,
      "city": "Camp Lake",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53110,
      "latitude": 42.948015,
      "longitude": -87.866831,
      "city": "Cudahy",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53114,
      "latitude": 42.658281,
      "longitude": -88.660964,
      "city": "Darien",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53115,
      "latitude": 42.641556,
      "longitude": -88.653496,
      "city": "Delavan",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53118,
      "latitude": 42.951536,
      "longitude": -88.444097,
      "city": "Dousman",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53119,
      "latitude": 42.929074,
      "longitude": -88.47489,
      "city": "Eagle",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53120,
      "latitude": 42.756533,
      "longitude": -88.507327,
      "city": "East Troy",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53121,
      "latitude": 42.671666,
      "longitude": -88.563961,
      "city": "Elkhorn",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53122,
      "latitude": 43.048211,
      "longitude": -88.087888,
      "city": "Elm Grove",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53125,
      "latitude": 42.545625,
      "longitude": -88.463036,
      "city": "Fontana",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53126,
      "latitude": 42.785553,
      "longitude": -87.995471,
      "city": "Franksville",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53127,
      "latitude": 42.960098,
      "longitude": -88.374455,
      "city": "Genesee Depot",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53128,
      "latitude": 42.549412,
      "longitude": -88.438624,
      "city": "Genoa City",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53129,
      "latitude": 42.937765,
      "longitude": -87.998935,
      "city": "Greendale",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53130,
      "latitude": 42.943614,
      "longitude": -88.046454,
      "city": "Hales Corners",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53132,
      "latitude": 42.887289,
      "longitude": -88.009597,
      "city": "Franklin",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53137,
      "latitude": 43.008958,
      "longitude": -88.6726,
      "city": "Helenville",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53138,
      "latitude": 42.66749,
      "longitude": -88.541721,
      "city": "Honey Creek",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53139,
      "latitude": 42.720022,
      "longitude": -88.120616,
      "city": "Kansasville",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53140,
      "latitude": 42.622449,
      "longitude": -87.830375,
      "city": "Kenosha",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53141,
      "latitude": 42.58098,
      "longitude": -87.662878,
      "city": "Kenosha",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53142,
      "latitude": 42.558221,
      "longitude": -87.925876,
      "city": "Kenosha",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53143,
      "latitude": 42.53607,
      "longitude": -87.824828,
      "city": "Kenosha",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53144,
      "latitude": 42.618427,
      "longitude": -87.948079,
      "city": "Kenosha",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53146,
      "latitude": 42.969924,
      "longitude": -88.177554,
      "city": "New Berlin",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53147,
      "latitude": 42.617877,
      "longitude": -88.52023,
      "city": "Lake Geneva",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53148,
      "latitude": 42.648665,
      "longitude": -88.359044,
      "city": "Lyons",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53149,
      "latitude": 42.97594,
      "longitude": -88.329731,
      "city": "Mukwonago",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53150,
      "latitude": 42.88799,
      "longitude": -88.132708,
      "city": "Muskego",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53151,
      "latitude": 42.973459,
      "longitude": -88.127689,
      "city": "New Berlin",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53152,
      "latitude": 42.574616,
      "longitude": -88.232632,
      "city": "New Munster",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53153,
      "latitude": 42.940971,
      "longitude": -88.401712,
      "city": "North Prairie",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53154,
      "latitude": 42.886266,
      "longitude": -87.89198,
      "city": "Oak Creek",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53156,
      "latitude": 42.893937,
      "longitude": -88.59712,
      "city": "Palmyra",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53157,
      "latitude": 42.540048,
      "longitude": -88.358167,
      "city": "Pell Lake",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53158,
      "latitude": 42.52926,
      "longitude": -87.885546,
      "city": "Pleasant Prairie",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53159,
      "latitude": 42.555695,
      "longitude": -88.296914,
      "city": "Powers Lake",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53167,
      "latitude": 42.748168,
      "longitude": -88.243586,
      "city": "Rochester",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53168,
      "latitude": 42.574598,
      "longitude": -88.137535,
      "city": "Salem",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53170,
      "latitude": 42.552018,
      "longitude": -88.160836,
      "city": "Silver Lake",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53171,
      "latitude": 42.642298,
      "longitude": -87.903161,
      "city": "Somers",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53172,
      "latitude": 42.911616,
      "longitude": -87.950533,
      "city": "South Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53176,
      "latitude": 42.636038,
      "longitude": -88.415987,
      "city": "Springfield",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53177,
      "latitude": 42.712962,
      "longitude": -87.934013,
      "city": "Sturtevant",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53178,
      "latitude": 43.018327,
      "longitude": -88.600429,
      "city": "Sullivan",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53179,
      "latitude": 42.517168,
      "longitude": -88.138001,
      "city": "Trevor",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53181,
      "latitude": 42.523887,
      "longitude": -88.235389,
      "city": "Twin Lakes",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53182,
      "latitude": 42.739435,
      "longitude": -88.083092,
      "city": "Union Grove",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53183,
      "latitude": 43.003573,
      "longitude": -88.376916,
      "city": "Wales",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53184,
      "latitude": 42.561543,
      "longitude": -88.597153,
      "city": "Walworth",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53185,
      "latitude": 42.763168,
      "longitude": -88.197361,
      "city": "Waterford",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53186,
      "latitude": 42.987835,
      "longitude": -88.217715,
      "city": "Waukesha",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53187,
      "latitude": 43.018696,
      "longitude": -88.302997,
      "city": "Waukesha",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53188,
      "latitude": 42.994564,
      "longitude": -88.241786,
      "city": "Waukesha",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53189,
      "latitude": 42.95163,
      "longitude": -88.296321,
      "city": "Waukesha",
      "state": "WI",
      "county": "Waukesha"
  },
  {
      "zipCode": 53190,
      "latitude": 42.784255,
      "longitude": -88.592559,
      "city": "Whitewater",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53191,
      "latitude": 42.691288,
      "longitude": -88.635035,
      "city": "Williams Bay",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53192,
      "latitude": 42.507165,
      "longitude": -88.191337,
      "city": "Wilmot",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53194,
      "latitude": 42.58098,
      "longitude": -87.662878,
      "city": "Woodworth",
      "state": "WI",
      "county": "Kenosha"
  },
  {
      "zipCode": 53195,
      "latitude": 42.513649,
      "longitude": -88.484322,
      "city": "Zenda",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53201,
      "latitude": 43.011264,
      "longitude": -87.958409,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53202,
      "latitude": 43.043213,
      "longitude": -87.894734,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53203,
      "latitude": 43.038313,
      "longitude": -87.916534,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53204,
      "latitude": 43.019463,
      "longitude": -87.925534,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53205,
      "latitude": 43.053112,
      "longitude": -87.933185,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53206,
      "latitude": 43.074311,
      "longitude": -87.933135,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53207,
      "latitude": 42.975115,
      "longitude": -87.894682,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53208,
      "latitude": 43.045962,
      "longitude": -87.963536,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53209,
      "latitude": 43.140909,
      "longitude": -87.942653,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53210,
      "latitude": 43.071111,
      "longitude": -87.973786,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53211,
      "latitude": 43.082018,
      "longitude": -87.889534,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53212,
      "latitude": 43.074862,
      "longitude": -87.907178,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53213,
      "latitude": 43.075461,
      "longitude": -87.999511,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53214,
      "latitude": 43.021514,
      "longitude": -88.017587,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53215,
      "latitude": 43.006014,
      "longitude": -87.942935,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53216,
      "latitude": 43.086244,
      "longitude": -87.976988,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53217,
      "latitude": 43.13446,
      "longitude": -87.933386,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53218,
      "latitude": 43.11546,
      "longitude": -87.991687,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53219,
      "latitude": 42.995814,
      "longitude": -87.990335,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53220,
      "latitude": 42.955864,
      "longitude": -87.993285,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53221,
      "latitude": 42.950565,
      "longitude": -87.987933,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53222,
      "latitude": 43.082161,
      "longitude": -88.036938,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53223,
      "latitude": 43.162959,
      "longitude": -87.977896,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53224,
      "latitude": 43.163122,
      "longitude": -88.022586,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53225,
      "latitude": 43.115159,
      "longitude": -88.040188,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53226,
      "latitude": 43.049312,
      "longitude": -88.041387,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53227,
      "latitude": 43.000926,
      "longitude": -88.031418,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53228,
      "latitude": 42.967564,
      "longitude": -88.043386,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53233,
      "latitude": 43.037213,
      "longitude": -87.933529,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53234,
      "latitude": 43.017412,
      "longitude": -87.569664,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53235,
      "latitude": 42.969865,
      "longitude": -87.873732,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53237,
      "latitude": 43.017412,
      "longitude": -87.569664,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53259,
      "latitude": 43.038663,
      "longitude": -87.913934,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53263,
      "latitude": 43.074583,
      "longitude": -88.06044,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53267,
      "latitude": 43.044013,
      "longitude": -87.909834,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53268,
      "latitude": 43.038513,
      "longitude": -87.909584,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53270,
      "latitude": 43.038763,
      "longitude": -87.903634,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53274,
      "latitude": 43.017412,
      "longitude": -87.569664,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53277,
      "latitude": 43.038863,
      "longitude": -87.902384,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53278,
      "latitude": 43.038863,
      "longitude": -87.902384,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53280,
      "latitude": 43.040963,
      "longitude": -87.957786,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53281,
      "latitude": 43.040963,
      "longitude": -87.957786,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53284,
      "latitude": 43.017412,
      "longitude": -87.569664,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53285,
      "latitude": 43.017412,
      "longitude": -87.569664,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53288,
      "latitude": 43.040613,
      "longitude": -87.909784,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53290,
      "latitude": 43.037263,
      "longitude": -87.914034,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53293,
      "latitude": 43.040813,
      "longitude": -87.919135,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53295,
      "latitude": 43.017412,
      "longitude": -87.569664,
      "city": "Milwaukee",
      "state": "WI",
      "county": "Milwaukee"
  },
  {
      "zipCode": 53401,
      "latitude": 42.727153,
      "longitude": -87.675979,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53402,
      "latitude": 42.755628,
      "longitude": -87.821815,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53403,
      "latitude": 42.700619,
      "longitude": -87.835901,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53404,
      "latitude": 42.756718,
      "longitude": -87.812275,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53405,
      "latitude": 42.731827,
      "longitude": -87.820925,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53406,
      "latitude": 42.72993,
      "longitude": -87.866213,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53407,
      "latitude": 42.731224,
      "longitude": -87.782818,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53408,
      "latitude": 42.727153,
      "longitude": -87.675979,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53490,
      "latitude": 42.727153,
      "longitude": -87.675979,
      "city": "Racine",
      "state": "WI",
      "county": "Racine"
  },
  {
      "zipCode": 53501,
      "latitude": 42.605454,
      "longitude": -89.070448,
      "city": "Afton",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53502,
      "latitude": 42.712827,
      "longitude": -89.45123,
      "city": "Albany",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53503,
      "latitude": 43.119643,
      "longitude": -89.947679,
      "city": "Arena",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53504,
      "latitude": 42.690143,
      "longitude": -89.922697,
      "city": "Argyle",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53505,
      "latitude": 42.659301,
      "longitude": -88.83065,
      "city": "Avalon",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53506,
      "latitude": 43.138475,
      "longitude": -90.280725,
      "city": "Avoca",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53507,
      "latitude": 43.008396,
      "longitude": -89.897387,
      "city": "Barneveld",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53508,
      "latitude": 42.903829,
      "longitude": -89.586339,
      "city": "Belleville",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53510,
      "latitude": 42.685375,
      "longitude": -90.263828,
      "city": "Belmont",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53511,
      "latitude": 42.562039,
      "longitude": -89.086045,
      "city": "Beloit",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53512,
      "latitude": 42.669779,
      "longitude": -89.072779,
      "city": "Beloit",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53515,
      "latitude": 43.113953,
      "longitude": -89.624544,
      "city": "Black Earth",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53516,
      "latitude": 42.693258,
      "longitude": -90.104765,
      "city": "Blanchardville",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53517,
      "latitude": 43.03822,
      "longitude": -89.804576,
      "city": "Blue Mounds",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53518,
      "latitude": 42.97045,
      "longitude": -90.571655,
      "city": "Blue River",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53520,
      "latitude": 42.605797,
      "longitude": -89.580126,
      "city": "Brodhead",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53521,
      "latitude": 42.717122,
      "longitude": -89.582703,
      "city": "Brooklyn",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53522,
      "latitude": 42.563199,
      "longitude": -89.778793,
      "city": "Browntown",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53523,
      "latitude": 42.987505,
      "longitude": -89.080742,
      "city": "Cambridge",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53525,
      "latitude": 42.56239,
      "longitude": -88.859993,
      "city": "Clinton",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53526,
      "latitude": 42.963325,
      "longitude": -90.369319,
      "city": "Cobb",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53527,
      "latitude": 43.071165,
      "longitude": -89.196996,
      "city": "Cottage Grove",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53528,
      "latitude": 43.106453,
      "longitude": -89.695091,
      "city": "Cross Plains",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53529,
      "latitude": 43.239966,
      "longitude": -89.523346,
      "city": "Dane",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53530,
      "latitude": 42.69112,
      "longitude": -90.134781,
      "city": "Darlington",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53531,
      "latitude": 43.057662,
      "longitude": -89.091798,
      "city": "Deerfield",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53532,
      "latitude": 43.149066,
      "longitude": -89.260855,
      "city": "De Forest",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53533,
      "latitude": 43.007063,
      "longitude": -90.192522,
      "city": "Dodgeville",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53534,
      "latitude": 42.803797,
      "longitude": -89.101389,
      "city": "Edgerton",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53535,
      "latitude": 43.011323,
      "longitude": -90.133932,
      "city": "Edmund",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53536,
      "latitude": 42.766359,
      "longitude": -89.250152,
      "city": "Evansville",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53537,
      "latitude": 42.672575,
      "longitude": -89.211151,
      "city": "Footville",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53538,
      "latitude": 42.926467,
      "longitude": -88.841626,
      "city": "Fort Atkinson",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53540,
      "latitude": 43.234509,
      "longitude": -90.261804,
      "city": "Gotham",
      "state": "WI",
      "county": "Richland"
  },
  {
      "zipCode": 53541,
      "latitude": 42.579894,
      "longitude": -90.031186,
      "city": "Gratiot",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53542,
      "latitude": 42.632325,
      "longitude": -89.15942,
      "city": "Hanover",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53543,
      "latitude": 42.991078,
      "longitude": -90.133812,
      "city": "Highland",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53544,
      "latitude": 42.871497,
      "longitude": -89.923881,
      "city": "Hollandale",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53545,
      "latitude": 42.710981,
      "longitude": -89.112201,
      "city": "Janesville",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53546,
      "latitude": 42.663574,
      "longitude": -88.947859,
      "city": "Janesville",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53547,
      "latitude": 42.729359,
      "longitude": -89.030111,
      "city": "Janesville",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53549,
      "latitude": 42.993502,
      "longitude": -88.759793,
      "city": "Jefferson",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53550,
      "latitude": 42.564434,
      "longitude": -89.507906,
      "city": "Juda",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53551,
      "latitude": 43.080902,
      "longitude": -88.913251,
      "city": "Lake Mills",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53553,
      "latitude": 42.963106,
      "longitude": -90.320561,
      "city": "Linden",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53554,
      "latitude": 42.898716,
      "longitude": -90.511593,
      "city": "Livingston",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53555,
      "latitude": 43.342813,
      "longitude": -89.557023,
      "city": "Lodi",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53556,
      "latitude": 43.241475,
      "longitude": -90.265197,
      "city": "Lone Rock",
      "state": "WI",
      "county": "Richland"
  },
  {
      "zipCode": 53557,
      "latitude": 43.343056,
      "longitude": -88.791442,
      "city": "Lowell",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53558,
      "latitude": 43.010519,
      "longitude": -89.363862,
      "city": "Mc Farland",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53559,
      "latitude": 43.173921,
      "longitude": -89.091315,
      "city": "Marshall",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53560,
      "latitude": 43.190983,
      "longitude": -89.729261,
      "city": "Mazomanie",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53561,
      "latitude": 43.364531,
      "longitude": -89.66396,
      "city": "Merrimac",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53562,
      "latitude": 43.114772,
      "longitude": -89.529153,
      "city": "Middleton",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53563,
      "latitude": 42.737804,
      "longitude": -89.041277,
      "city": "Milton",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53565,
      "latitude": 42.989701,
      "longitude": -90.152558,
      "city": "Mineral Point",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53566,
      "latitude": 42.612966,
      "longitude": -89.656994,
      "city": "Monroe",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53569,
      "latitude": 43.031263,
      "longitude": -90.492475,
      "city": "Montfort",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53570,
      "latitude": 42.74305,
      "longitude": -89.621013,
      "city": "Monticello",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53571,
      "latitude": 43.277325,
      "longitude": -89.356403,
      "city": "Morrisonville",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53572,
      "latitude": 42.973438,
      "longitude": -89.513903,
      "city": "Mount Horeb",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53573,
      "latitude": 43.1234,
      "longitude": -90.488423,
      "city": "Muscoda",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53574,
      "latitude": 42.816273,
      "longitude": -89.651287,
      "city": "New Glarus",
      "state": "WI",
      "county": "Green"
  },
  {
      "zipCode": 53575,
      "latitude": 42.933918,
      "longitude": -89.389642,
      "city": "Oregon",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53576,
      "latitude": 42.614273,
      "longitude": -89.233129,
      "city": "Orfordville",
      "state": "WI",
      "county": "Rock"
  },
  {
      "zipCode": 53577,
      "latitude": 43.293776,
      "longitude": -90.022327,
      "city": "Plain",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53578,
      "latitude": 43.360759,
      "longitude": -89.808407,
      "city": "Prairie Du Sac",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53579,
      "latitude": 43.287987,
      "longitude": -88.887444,
      "city": "Reeseville",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53580,
      "latitude": 42.856934,
      "longitude": -90.377784,
      "city": "Rewey",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53581,
      "latitude": 43.366148,
      "longitude": -90.430225,
      "city": "Richland Center",
      "state": "WI",
      "county": "Richland"
  },
  {
      "zipCode": 53582,
      "latitude": 43.016577,
      "longitude": -89.986458,
      "city": "Ridgeway",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53583,
      "latitude": 43.251127,
      "longitude": -89.89584,
      "city": "Sauk City",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53584,
      "latitude": 43.279435,
      "longitude": -90.287566,
      "city": "Sextonville",
      "state": "WI",
      "county": "Richland"
  },
  {
      "zipCode": 53585,
      "latitude": 42.607688,
      "longitude": -88.623745,
      "city": "Sharon",
      "state": "WI",
      "county": "Walworth"
  },
  {
      "zipCode": 53586,
      "latitude": 42.605221,
      "longitude": -90.113635,
      "city": "Shullsburg",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53587,
      "latitude": 42.579926,
      "longitude": -89.931751,
      "city": "South Wayne",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53588,
      "latitude": 43.230001,
      "longitude": -90.040066,
      "city": "Spring Green",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53589,
      "latitude": 42.931289,
      "longitude": -89.168354,
      "city": "Stoughton",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53590,
      "latitude": 43.195712,
      "longitude": -89.209418,
      "city": "Sun Prairie",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53591,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Sun Prairie",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53593,
      "latitude": 42.995697,
      "longitude": -89.566512,
      "city": "Verona",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53594,
      "latitude": 43.12904,
      "longitude": -88.947513,
      "city": "Waterloo",
      "state": "WI",
      "county": "Jefferson"
  },
  {
      "zipCode": 53595,
      "latitude": 42.976078,
      "longitude": -90.141299,
      "city": "Dodgeville",
      "state": "WI",
      "county": "Iowa"
  },
  {
      "zipCode": 53596,
      "latitude": 43.192403,
      "longitude": -89.26288,
      "city": "Sun Prairie",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53597,
      "latitude": 43.183972,
      "longitude": -89.322655,
      "city": "Waunakee",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53598,
      "latitude": 43.207783,
      "longitude": -89.34178,
      "city": "Windsor",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53599,
      "latitude": 42.649309,
      "longitude": -89.862202,
      "city": "Woodford",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53701,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53702,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53703,
      "latitude": 43.053085,
      "longitude": -89.352764,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53704,
      "latitude": 43.104429,
      "longitude": -89.322135,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53705,
      "latitude": 43.073147,
      "longitude": -89.45681,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53706,
      "latitude": 43.077755,
      "longitude": -89.413326,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53707,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53708,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53709,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53710,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53711,
      "latitude": 43.021252,
      "longitude": -89.417973,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53713,
      "latitude": 43.037167,
      "longitude": -89.397066,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53714,
      "latitude": 43.118663,
      "longitude": -89.312644,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53715,
      "latitude": 43.061839,
      "longitude": -89.394619,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53716,
      "latitude": 43.063103,
      "longitude": -89.313327,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53717,
      "latitude": 43.015966,
      "longitude": -89.656734,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53718,
      "latitude": 43.11416,
      "longitude": -89.249067,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53719,
      "latitude": 43.03125,
      "longitude": -89.494568,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53725,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53726,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53744,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53777,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53778,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53779,
      "latitude": 43.098202,
      "longitude": -89.324196,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53780,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53782,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53783,
      "latitude": 43.15955,
      "longitude": -89.285235,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53784,
      "latitude": 43.048908,
      "longitude": -89.338447,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53785,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53786,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53787,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53788,
      "latitude": 43.076691,
      "longitude": -89.37632,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53789,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53790,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53791,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53792,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53793,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53794,
      "latitude": 43.06956,
      "longitude": -89.423861,
      "city": "Madison",
      "state": "WI",
      "county": "Dane"
  },
  {
      "zipCode": 53801,
      "latitude": 42.796401,
      "longitude": -90.823145,
      "city": "Bagley",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53802,
      "latitude": 42.873625,
      "longitude": -90.936354,
      "city": "Beetown",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53803,
      "latitude": 42.554499,
      "longitude": -90.350817,
      "city": "Benton",
      "state": "WI",
      "county": "Lafayette"
  },
  {
      "zipCode": 53804,
      "latitude": 42.838191,
      "longitude": -90.907665,
      "city": "Bloomington",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53805,
      "latitude": 42.896051,
      "longitude": -90.779071,
      "city": "Boscobel",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53806,
      "latitude": 42.782451,
      "longitude": -90.945303,
      "city": "Cassville",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53807,
      "latitude": 42.751647,
      "longitude": -90.699166,
      "city": "Cuba City",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53808,
      "latitude": 42.635081,
      "longitude": -90.583298,
      "city": "Dickeyville",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53809,
      "latitude": 42.806387,
      "longitude": -90.677324,
      "city": "Fennimore",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53810,
      "latitude": 42.822065,
      "longitude": -90.982153,
      "city": "Glen Haven",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53811,
      "latitude": 42.543893,
      "longitude": -90.536322,
      "city": "Hazel Green",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53812,
      "latitude": 42.859325,
      "longitude": -90.791337,
      "city": "Kieler",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53813,
      "latitude": 42.864155,
      "longitude": -90.684965,
      "city": "Lancaster",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53816,
      "latitude": 42.975521,
      "longitude": -90.84962,
      "city": "Mount Hope",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53817,
      "latitude": 42.942183,
      "longitude": -90.964596,
      "city": "Patch Grove",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53818,
      "latitude": 42.795971,
      "longitude": -90.644975,
      "city": "Platteville",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53820,
      "latitude": 42.692445,
      "longitude": -90.731654,
      "city": "Potosi",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53821,
      "latitude": 43.084008,
      "longitude": -91.069064,
      "city": "Prairie Du Chien",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 53824,
      "latitude": 42.859325,
      "longitude": -90.791337,
      "city": "Sinsinawa",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53825,
      "latitude": 42.926596,
      "longitude": -90.583575,
      "city": "Stitzer",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53826,
      "latitude": 43.110147,
      "longitude": -90.957097,
      "city": "Wauzeka",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 53827,
      "latitude": 43.035813,
      "longitude": -90.853002,
      "city": "Woodman",
      "state": "WI",
      "county": "Grant"
  },
  {
      "zipCode": 53901,
      "latitude": 43.50666,
      "longitude": -89.399335,
      "city": "Portage",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53910,
      "latitude": 43.892066,
      "longitude": -89.827509,
      "city": "Adams",
      "state": "WI",
      "county": "Adams"
  },
  {
      "zipCode": 53911,
      "latitude": 43.336846,
      "longitude": -89.36061,
      "city": "Arlington",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53913,
      "latitude": 43.446602,
      "longitude": -89.863957,
      "city": "Baraboo",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53916,
      "latitude": 43.466482,
      "longitude": -88.862852,
      "city": "Beaver Dam",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53917,
      "latitude": 43.414202,
      "longitude": -88.704914,
      "city": "Beaver Dam",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53919,
      "latitude": 43.740975,
      "longitude": -88.62245,
      "city": "Brandon",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53920,
      "latitude": 43.7076,
      "longitude": -89.551629,
      "city": "Briggsville",
      "state": "WI",
      "county": "Marquette"
  },
  {
      "zipCode": 53922,
      "latitude": 43.517272,
      "longitude": -88.73346,
      "city": "Burnett",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53923,
      "latitude": 43.550235,
      "longitude": -89.15173,
      "city": "Cambria",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53924,
      "latitude": 43.46358,
      "longitude": -90.28225,
      "city": "Cazenovia",
      "state": "WI",
      "county": "Richland"
  },
  {
      "zipCode": 53925,
      "latitude": 43.3569,
      "longitude": -89.12088,
      "city": "Columbus",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53926,
      "latitude": 43.679317,
      "longitude": -89.186656,
      "city": "Dalton",
      "state": "WI",
      "county": "Green Lake"
  },
  {
      "zipCode": 53927,
      "latitude": 43.95546,
      "longitude": -89.941771,
      "city": "Dellwood",
      "state": "WI",
      "county": "Adams"
  },
  {
      "zipCode": 53928,
      "latitude": 43.426605,
      "longitude": -89.149069,
      "city": "Doylestown",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53929,
      "latitude": 43.82702,
      "longitude": -90.10446,
      "city": "Elroy",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 53930,
      "latitude": 43.6866,
      "longitude": -89.483441,
      "city": "Endeavor",
      "state": "WI",
      "county": "Marquette"
  },
  {
      "zipCode": 53931,
      "latitude": 43.747481,
      "longitude": -88.866516,
      "city": "Fairwater",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 53932,
      "latitude": 43.417126,
      "longitude": -89.070459,
      "city": "Fall River",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53933,
      "latitude": 43.568169,
      "longitude": -88.895723,
      "city": "Fox Lake",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53934,
      "latitude": 43.997966,
      "longitude": -89.777051,
      "city": "Friendship",
      "state": "WI",
      "county": "Adams"
  },
  {
      "zipCode": 53935,
      "latitude": 43.590116,
      "longitude": -89.05931,
      "city": "Friesland",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53936,
      "latitude": 43.947894,
      "longitude": -89.72751,
      "city": "Grand Marsh",
      "state": "WI",
      "county": "Adams"
  },
  {
      "zipCode": 53937,
      "latitude": 43.390863,
      "longitude": -90.128539,
      "city": "Hillpoint",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53939,
      "latitude": 43.6911,
      "longitude": -89.130237,
      "city": "Kingston",
      "state": "WI",
      "county": "Green Lake"
  },
  {
      "zipCode": 53940,
      "latitude": 43.593912,
      "longitude": -89.791242,
      "city": "Lake Delton",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53941,
      "latitude": 43.547459,
      "longitude": -90.117243,
      "city": "La Valle",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53942,
      "latitude": 43.468211,
      "longitude": -90.161478,
      "city": "Lime Ridge",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53943,
      "latitude": 43.385775,
      "longitude": -90.017885,
      "city": "Loganville",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53944,
      "latitude": 43.714061,
      "longitude": -89.949337,
      "city": "Lyndon Station",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 53946,
      "latitude": 43.723578,
      "longitude": -89.065361,
      "city": "Markesan",
      "state": "WI",
      "county": "Green Lake"
  },
  {
      "zipCode": 53947,
      "latitude": 43.746464,
      "longitude": -89.138441,
      "city": "Marquette",
      "state": "WI",
      "county": "Green Lake"
  },
  {
      "zipCode": 53948,
      "latitude": 43.908679,
      "longitude": -90.07242,
      "city": "Mauston",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 53949,
      "latitude": 43.812581,
      "longitude": -89.372011,
      "city": "Montello",
      "state": "WI",
      "county": "Marquette"
  },
  {
      "zipCode": 53950,
      "latitude": 43.911145,
      "longitude": -90.165918,
      "city": "New Lisbon",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 53951,
      "latitude": 43.4031,
      "longitude": -89.872232,
      "city": "North Freedom",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53952,
      "latitude": 43.767789,
      "longitude": -89.463944,
      "city": "Oxford",
      "state": "WI",
      "county": "Marquette"
  },
  {
      "zipCode": 53953,
      "latitude": 43.764385,
      "longitude": -89.4576,
      "city": "Packwaukee",
      "state": "WI",
      "county": "Marquette"
  },
  {
      "zipCode": 53954,
      "latitude": 43.516772,
      "longitude": -89.31459,
      "city": "Pardeeville",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53955,
      "latitude": 43.433273,
      "longitude": -89.394518,
      "city": "Poynette",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53956,
      "latitude": 43.534002,
      "longitude": -88.946714,
      "city": "Randolph",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53957,
      "latitude": 43.535249,
      "longitude": -89.006845,
      "city": "Randolph",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53958,
      "latitude": 43.393767,
      "longitude": -89.95589,
      "city": "Reedsburg",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53959,
      "latitude": 43.482108,
      "longitude": -89.966806,
      "city": "Reedsburg",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53960,
      "latitude": 43.398335,
      "longitude": -89.250177,
      "city": "Rio",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53961,
      "latitude": 43.459884,
      "longitude": -89.931931,
      "city": "Rock Springs",
      "state": "WI",
      "county": "Sauk"
  },
  {
      "zipCode": 53962,
      "latitude": 43.94521,
      "longitude": -90.049489,
      "city": "Union Center",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 53963,
      "latitude": 43.459309,
      "longitude": -88.754483,
      "city": "Waupun",
      "state": "WI",
      "county": "Dodge"
  },
  {
      "zipCode": 53964,
      "latitude": 43.848698,
      "longitude": -89.478459,
      "city": "Westfield",
      "state": "WI",
      "county": "Marquette"
  },
  {
      "zipCode": 53965,
      "latitude": 43.568982,
      "longitude": -89.44168,
      "city": "Wisconsin Dells",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 53968,
      "latitude": 43.690867,
      "longitude": -90.203595,
      "city": "Wonewoc",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 53969,
      "latitude": 43.498907,
      "longitude": -89.30497,
      "city": "Wyocena",
      "state": "WI",
      "county": "Columbia"
  },
  {
      "zipCode": 54001,
      "latitude": 45.348566,
      "longitude": -92.401379,
      "city": "Amery",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54002,
      "latitude": 44.969171,
      "longitude": -92.441061,
      "city": "Baldwin",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54003,
      "latitude": 44.789742,
      "longitude": -92.448434,
      "city": "Beldenville",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54004,
      "latitude": 45.31484,
      "longitude": -92.430772,
      "city": "Clayton",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54005,
      "latitude": 45.282945,
      "longitude": -92.429816,
      "city": "Clear Lake",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54006,
      "latitude": 45.554321,
      "longitude": -92.662155,
      "city": "Cushing",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54007,
      "latitude": 45.087504,
      "longitude": -92.379021,
      "city": "Deer Park",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54009,
      "latitude": 45.36179,
      "longitude": -92.549952,
      "city": "Dresser",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54010,
      "latitude": 44.734759,
      "longitude": -92.465532,
      "city": "East Ellsworth",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54011,
      "latitude": 44.740142,
      "longitude": -92.548289,
      "city": "Ellsworth",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54012,
      "latitude": 45.11453,
      "longitude": -92.276235,
      "city": "Emerald",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54013,
      "latitude": 45.060365,
      "longitude": -92.248403,
      "city": "Glenwood City",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54014,
      "latitude": 44.655504,
      "longitude": -92.573652,
      "city": "Hager City",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54015,
      "latitude": 44.966939,
      "longitude": -92.456512,
      "city": "Hammond",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54016,
      "latitude": 44.971853,
      "longitude": -92.481156,
      "city": "Hudson",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54017,
      "latitude": 45.090925,
      "longitude": -92.496993,
      "city": "New Richmond",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54020,
      "latitude": 45.283783,
      "longitude": -92.535618,
      "city": "Osceola",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54021,
      "latitude": 44.747209,
      "longitude": -92.640483,
      "city": "Prescott",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54022,
      "latitude": 44.802351,
      "longitude": -92.595365,
      "city": "River Falls",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54023,
      "latitude": 44.974357,
      "longitude": -92.59762,
      "city": "Roberts",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54024,
      "latitude": 45.509689,
      "longitude": -92.611733,
      "city": "Saint Croix Falls",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54025,
      "latitude": 45.081379,
      "longitude": -92.474419,
      "city": "Somerset",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54026,
      "latitude": 45.265619,
      "longitude": -92.480793,
      "city": "Star Prairie",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54027,
      "latitude": 44.945813,
      "longitude": -92.25605,
      "city": "Wilson",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54028,
      "latitude": 44.946202,
      "longitude": -92.376526,
      "city": "Woodville",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54082,
      "latitude": 45.080119,
      "longitude": -92.746568,
      "city": "Houlton",
      "state": "WI",
      "county": "Saint Croix"
  },
  {
      "zipCode": 54101,
      "latitude": 44.792284,
      "longitude": -88.044067,
      "city": "Abrams",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54102,
      "latitude": 45.492839,
      "longitude": -88.049604,
      "city": "Amberg",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54103,
      "latitude": 45.672311,
      "longitude": -88.530545,
      "city": "Armstrong Creek",
      "state": "WI",
      "county": "Forest"
  },
  {
      "zipCode": 54104,
      "latitude": 45.420602,
      "longitude": -88.246901,
      "city": "Athelstane",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54106,
      "latitude": 44.470235,
      "longitude": -88.454719,
      "city": "Black Creek",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54107,
      "latitude": 44.699153,
      "longitude": -88.454312,
      "city": "Bonduel",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54110,
      "latitude": 44.163209,
      "longitude": -88.133876,
      "city": "Brillion",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 54111,
      "latitude": 44.810274,
      "longitude": -88.391648,
      "city": "Cecil",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54112,
      "latitude": 45.306273,
      "longitude": -88.002389,
      "city": "Coleman",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54113,
      "latitude": 44.348711,
      "longitude": -88.361208,
      "city": "Combined Locks",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54114,
      "latitude": 45.261661,
      "longitude": -88.078001,
      "city": "Crivitz",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54115,
      "latitude": 44.453629,
      "longitude": -87.978279,
      "city": "De Pere",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54119,
      "latitude": 45.591557,
      "longitude": -88.222435,
      "city": "Dunbar",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54120,
      "latitude": 45.74447,
      "longitude": -88.47565,
      "city": "Fence",
      "state": "WI",
      "county": "Florence"
  },
  {
      "zipCode": 54121,
      "latitude": 45.845525,
      "longitude": -88.284254,
      "city": "Florence",
      "state": "WI",
      "county": "Florence"
  },
  {
      "zipCode": 54123,
      "latitude": 44.211065,
      "longitude": -88.151532,
      "city": "Forest Junction",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 54124,
      "latitude": 44.916397,
      "longitude": -88.174299,
      "city": "Gillett",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54125,
      "latitude": 45.648826,
      "longitude": -88.333152,
      "city": "Goodman",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54126,
      "latitude": 44.347365,
      "longitude": -88.037736,
      "city": "Greenleaf",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54127,
      "latitude": 44.804687,
      "longitude": -88.269238,
      "city": "Green Valley",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54128,
      "latitude": 44.806061,
      "longitude": -88.732786,
      "city": "Gresham",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54129,
      "latitude": 44.146516,
      "longitude": -88.195124,
      "city": "Hilbert",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 54130,
      "latitude": 44.334465,
      "longitude": -88.295776,
      "city": "Kaukauna",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54131,
      "latitude": 44.416326,
      "longitude": -88.464873,
      "city": "Freedom",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54135,
      "latitude": 44.88646,
      "longitude": -88.575133,
      "city": "Keshena",
      "state": "WI",
      "county": "Menominee"
  },
  {
      "zipCode": 54136,
      "latitude": 44.303208,
      "longitude": -88.473445,
      "city": "Kimberly",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54137,
      "latitude": 44.755693,
      "longitude": -88.289822,
      "city": "Krakow",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54138,
      "latitude": 45.308254,
      "longitude": -88.477771,
      "city": "Lakewood",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54139,
      "latitude": 45.063468,
      "longitude": -88.214802,
      "city": "Lena",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54140,
      "latitude": 44.294746,
      "longitude": -88.316305,
      "city": "Little Chute",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54141,
      "latitude": 44.744566,
      "longitude": -87.995493,
      "city": "Little Suamico",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54143,
      "latitude": 45.086805,
      "longitude": -87.716311,
      "city": "Marinette",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54149,
      "latitude": 45.095725,
      "longitude": -88.47889,
      "city": "Mountain",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54150,
      "latitude": 44.86893,
      "longitude": -88.618552,
      "city": "Neopit",
      "state": "WI",
      "county": "Menominee"
  },
  {
      "zipCode": 54151,
      "latitude": 45.579448,
      "longitude": -87.918821,
      "city": "Niagara",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54152,
      "latitude": 44.566799,
      "longitude": -88.458164,
      "city": "Nichols",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54153,
      "latitude": 44.897088,
      "longitude": -88.082586,
      "city": "Oconto",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54154,
      "latitude": 44.901999,
      "longitude": -88.125829,
      "city": "Oconto Falls",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54155,
      "latitude": 44.447167,
      "longitude": -88.23333,
      "city": "Oneida",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54156,
      "latitude": 45.392437,
      "longitude": -87.904337,
      "city": "Pembine",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54157,
      "latitude": 45.174269,
      "longitude": -87.902,
      "city": "Peshtigo",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54159,
      "latitude": 45.205901,
      "longitude": -87.8274,
      "city": "Porterfield",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54160,
      "latitude": 44.119917,
      "longitude": -88.096495,
      "city": "Potter",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 54161,
      "latitude": 45.117544,
      "longitude": -87.977136,
      "city": "Pound",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54162,
      "latitude": 44.583761,
      "longitude": -88.103417,
      "city": "Pulaski",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54165,
      "latitude": 44.430443,
      "longitude": -88.463328,
      "city": "Seymour",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54166,
      "latitude": 44.745058,
      "longitude": -88.664156,
      "city": "Shawano",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54169,
      "latitude": 44.167388,
      "longitude": -88.229438,
      "city": "Sherwood",
      "state": "WI",
      "county": "Calumet"
  },
  {
      "zipCode": 54170,
      "latitude": 44.482935,
      "longitude": -88.589547,
      "city": "Shiocton",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54171,
      "latitude": 44.726641,
      "longitude": -88.121792,
      "city": "Sobieski",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54173,
      "latitude": 44.635701,
      "longitude": -88.122034,
      "city": "Suamico",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54174,
      "latitude": 45.111843,
      "longitude": -88.418694,
      "city": "Suring",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54175,
      "latitude": 45.309457,
      "longitude": -88.605896,
      "city": "Townsend",
      "state": "WI",
      "county": "Oconto"
  },
  {
      "zipCode": 54177,
      "latitude": 45.383307,
      "longitude": -87.876231,
      "city": "Wausaukee",
      "state": "WI",
      "county": "Marinette"
  },
  {
      "zipCode": 54180,
      "latitude": 44.494921,
      "longitude": -88.124743,
      "city": "Wrightstown",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54182,
      "latitude": 44.731453,
      "longitude": -88.369842,
      "city": "Zachow",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54201,
      "latitude": 44.596017,
      "longitude": -87.540266,
      "city": "Algoma",
      "state": "WI",
      "county": "Kewaunee"
  },
  {
      "zipCode": 54202,
      "latitude": 45.077002,
      "longitude": -87.14603,
      "city": "Baileys Harbor",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54203,
      "latitude": 44.109853,
      "longitude": -87.483874,
      "city": "Branch",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54204,
      "latitude": 44.752379,
      "longitude": -87.625888,
      "city": "Brussels",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54205,
      "latitude": 44.596082,
      "longitude": -87.637308,
      "city": "Casco",
      "state": "WI",
      "county": "Kewaunee"
  },
  {
      "zipCode": 54207,
      "latitude": 44.091021,
      "longitude": -87.990241,
      "city": "Collins",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54208,
      "latitude": 44.459153,
      "longitude": -87.885734,
      "city": "Denmark",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54209,
      "latitude": 45.012261,
      "longitude": -87.266031,
      "city": "Egg Harbor",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54210,
      "latitude": 45.253108,
      "longitude": -87.044698,
      "city": "Ellison Bay",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54211,
      "latitude": 45.159184,
      "longitude": -87.171024,
      "city": "Ephraim",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54212,
      "latitude": 45.11038,
      "longitude": -87.209899,
      "city": "Fish Creek",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54213,
      "latitude": 44.722418,
      "longitude": -87.528122,
      "city": "Forestville",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54214,
      "latitude": 44.204875,
      "longitude": -87.715629,
      "city": "Francis Creek",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54215,
      "latitude": 44.222585,
      "longitude": -87.801651,
      "city": "Kellnersville",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54216,
      "latitude": 44.438185,
      "longitude": -87.592719,
      "city": "Kewaunee",
      "state": "WI",
      "county": "Kewaunee"
  },
  {
      "zipCode": 54217,
      "latitude": 44.541977,
      "longitude": -87.670551,
      "city": "Luxemburg",
      "state": "WI",
      "county": "Kewaunee"
  },
  {
      "zipCode": 54220,
      "latitude": 44.109709,
      "longitude": -87.714403,
      "city": "Manitowoc",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54221,
      "latitude": 44.132295,
      "longitude": -87.599031,
      "city": "Manitowoc",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54226,
      "latitude": 45.059713,
      "longitude": -87.006012,
      "city": "Maplewood",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54227,
      "latitude": 44.276554,
      "longitude": -87.801651,
      "city": "Maribel",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54228,
      "latitude": 44.167783,
      "longitude": -87.739206,
      "city": "Mishicot",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54229,
      "latitude": 44.571416,
      "longitude": -87.833306,
      "city": "New Franken",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54230,
      "latitude": 44.142382,
      "longitude": -87.904684,
      "city": "Reedsville",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54232,
      "latitude": 44.005714,
      "longitude": -87.922351,
      "city": "Saint Nazianz",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54234,
      "latitude": 45.178293,
      "longitude": -87.101748,
      "city": "Sister Bay",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54235,
      "latitude": 44.945314,
      "longitude": -87.384142,
      "city": "Sturgeon Bay",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54240,
      "latitude": 44.282802,
      "longitude": -87.632398,
      "city": "Tisch Mills",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54241,
      "latitude": 44.225538,
      "longitude": -87.627759,
      "city": "Two Rivers",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54245,
      "latitude": 44.053321,
      "longitude": -87.899912,
      "city": "Valders",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54246,
      "latitude": 45.373786,
      "longitude": -86.897472,
      "city": "Washington Island",
      "state": "WI",
      "county": "Door"
  },
  {
      "zipCode": 54247,
      "latitude": 44.189293,
      "longitude": -87.781594,
      "city": "Whitelaw",
      "state": "WI",
      "county": "Manitowoc"
  },
  {
      "zipCode": 54301,
      "latitude": 44.494385,
      "longitude": -87.976051,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54302,
      "latitude": 44.495042,
      "longitude": -87.978652,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54303,
      "latitude": 44.552247,
      "longitude": -88.078803,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54304,
      "latitude": 44.497541,
      "longitude": -88.032443,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54305,
      "latitude": 44.460064,
      "longitude": -88.007382,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54306,
      "latitude": 44.460064,
      "longitude": -88.007382,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54307,
      "latitude": 44.460064,
      "longitude": -88.007382,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54308,
      "latitude": 44.459509,
      "longitude": -87.805912,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54311,
      "latitude": 44.523605,
      "longitude": -87.957687,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54313,
      "latitude": 44.54964,
      "longitude": -87.99597,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54324,
      "latitude": 44.460064,
      "longitude": -88.007382,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54344,
      "latitude": 44.42504,
      "longitude": -88.111252,
      "city": "Green Bay",
      "state": "WI",
      "county": "Brown"
  },
  {
      "zipCode": 54401,
      "latitude": 44.961874,
      "longitude": -89.794002,
      "city": "Wausau",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54402,
      "latitude": 44.900936,
      "longitude": -89.7701,
      "city": "Wausau",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54403,
      "latitude": 44.952863,
      "longitude": -89.531804,
      "city": "Wausau",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54404,
      "latitude": 44.466554,
      "longitude": -90.02136,
      "city": "Marshfield",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54405,
      "latitude": 44.960186,
      "longitude": -90.374893,
      "city": "Abbotsford",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54406,
      "latitude": 44.393087,
      "longitude": -89.335313,
      "city": "Amherst",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54407,
      "latitude": 44.530435,
      "longitude": -89.356252,
      "city": "Amherst Junction",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54408,
      "latitude": 45.008812,
      "longitude": -89.357822,
      "city": "Aniwa",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54409,
      "latitude": 45.160809,
      "longitude": -89.093942,
      "city": "Antigo",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54410,
      "latitude": 44.535658,
      "longitude": -90.007245,
      "city": "Arpin",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54411,
      "latitude": 44.982348,
      "longitude": -90.007765,
      "city": "Athens",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54412,
      "latitude": 44.559652,
      "longitude": -90.00532,
      "city": "Auburndale",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54413,
      "latitude": 44.298272,
      "longitude": -90.140404,
      "city": "Babcock",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54414,
      "latitude": 44.919163,
      "longitude": -89.1042,
      "city": "Birnamwood",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54415,
      "latitude": 44.619128,
      "longitude": -89.918563,
      "city": "Blenker",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54416,
      "latitude": 44.898739,
      "longitude": -88.838922,
      "city": "Bowler",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54417,
      "latitude": 45.02735,
      "longitude": -89.654118,
      "city": "Brokaw",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54418,
      "latitude": 45.160395,
      "longitude": -88.994008,
      "city": "Bryant",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54420,
      "latitude": 44.606893,
      "longitude": -90.375852,
      "city": "Chili",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54421,
      "latitude": 44.879977,
      "longitude": -90.384734,
      "city": "Colby",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54422,
      "latitude": 44.944027,
      "longitude": -90.52792,
      "city": "Curtiss",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54423,
      "latitude": 44.580724,
      "longitude": -89.586502,
      "city": "Custer",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54424,
      "latitude": 45.272303,
      "longitude": -89.086848,
      "city": "Deerbrook",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54425,
      "latitude": 44.996496,
      "longitude": -90.374411,
      "city": "Dorchester",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54426,
      "latitude": 44.904647,
      "longitude": -90.012469,
      "city": "Edgar",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54427,
      "latitude": 44.808419,
      "longitude": -89.301349,
      "city": "Eland",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54428,
      "latitude": 45.413361,
      "longitude": -89.143347,
      "city": "Elcho",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54429,
      "latitude": 44.780171,
      "longitude": -89.247809,
      "city": "Elderon",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54430,
      "latitude": 45.212455,
      "longitude": -88.83459,
      "city": "Elton",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54432,
      "latitude": 44.900936,
      "longitude": -89.7701,
      "city": "Galloway",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54433,
      "latitude": 45.197791,
      "longitude": -90.632113,
      "city": "Gilman",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54434,
      "latitude": 45.206757,
      "longitude": -90.484132,
      "city": "Jump River",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54435,
      "latitude": 45.38097,
      "longitude": -89.607232,
      "city": "Gleason",
      "state": "WI",
      "county": "Lincoln"
  },
  {
      "zipCode": 54436,
      "latitude": 44.607247,
      "longitude": -90.457313,
      "city": "Granton",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54437,
      "latitude": 44.660403,
      "longitude": -90.6756,
      "city": "Greenwood",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54439,
      "latitude": 45.206757,
      "longitude": -90.484132,
      "city": "Hannibal",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54440,
      "latitude": 44.797963,
      "longitude": -89.513916,
      "city": "Hatley",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54441,
      "latitude": 44.645875,
      "longitude": -90.105056,
      "city": "Hewitt",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54442,
      "latitude": 45.366386,
      "longitude": -89.670022,
      "city": "Irma",
      "state": "WI",
      "county": "Lincoln"
  },
  {
      "zipCode": 54443,
      "latitude": 44.588962,
      "longitude": -89.707997,
      "city": "Junction City",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54444,
      "latitude": 45.249382,
      "longitude": -89.032136,
      "city": "Kempster",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54446,
      "latitude": 44.729482,
      "longitude": -90.634557,
      "city": "Loyal",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54447,
      "latitude": 45.16658,
      "longitude": -90.769811,
      "city": "Lublin",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54448,
      "latitude": 44.875437,
      "longitude": -89.80077,
      "city": "Marathon",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54449,
      "latitude": 44.589394,
      "longitude": -90.190595,
      "city": "Marshfield",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54450,
      "latitude": 45.006232,
      "longitude": -89.047379,
      "city": "Mattoon",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54451,
      "latitude": 45.164705,
      "longitude": -90.445798,
      "city": "Medford",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54452,
      "latitude": 45.217518,
      "longitude": -89.713291,
      "city": "Merrill",
      "state": "WI",
      "county": "Lincoln"
  },
  {
      "zipCode": 54454,
      "latitude": 44.598179,
      "longitude": -89.904543,
      "city": "Milladore",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54455,
      "latitude": 44.809226,
      "longitude": -89.701277,
      "city": "Mosinee",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54456,
      "latitude": 44.688149,
      "longitude": -90.669407,
      "city": "Neillsville",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54457,
      "latitude": 44.299968,
      "longitude": -89.920843,
      "city": "Nekoosa",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54458,
      "latitude": 44.490241,
      "longitude": -89.310944,
      "city": "Nelsonville",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54459,
      "latitude": 45.451541,
      "longitude": -90.287732,
      "city": "Ogema",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54460,
      "latitude": 44.900923,
      "longitude": -90.627475,
      "city": "Owen",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54462,
      "latitude": 45.386148,
      "longitude": -88.971242,
      "city": "Pearson",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54463,
      "latitude": 45.525897,
      "longitude": -89.236033,
      "city": "Pelican Lake",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54464,
      "latitude": 45.249382,
      "longitude": -89.032136,
      "city": "Phlox",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54465,
      "latitude": 45.362544,
      "longitude": -88.950339,
      "city": "Pickerel",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54466,
      "latitude": 44.408897,
      "longitude": -90.190911,
      "city": "Pittsville",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54467,
      "latitude": 44.413942,
      "longitude": -89.565507,
      "city": "Plover",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54469,
      "latitude": 44.350934,
      "longitude": -89.876274,
      "city": "Port Edwards",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54470,
      "latitude": 45.208013,
      "longitude": -90.168991,
      "city": "Rib Lake",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54471,
      "latitude": 44.929692,
      "longitude": -89.364964,
      "city": "Ringle",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54472,
      "latitude": 44.466554,
      "longitude": -90.02136,
      "city": "Marshfield",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54473,
      "latitude": 44.605786,
      "longitude": -89.356834,
      "city": "Rosholt",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54474,
      "latitude": 44.898819,
      "longitude": -89.712935,
      "city": "Rothschild",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54475,
      "latitude": 44.474097,
      "longitude": -89.795501,
      "city": "Rudolph",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54476,
      "latitude": 44.866501,
      "longitude": -89.576968,
      "city": "Schofield",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54479,
      "latitude": 44.805167,
      "longitude": -90.141112,
      "city": "Spencer",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54480,
      "latitude": 45.19405,
      "longitude": -90.302783,
      "city": "Stetsonville",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54481,
      "latitude": 44.551808,
      "longitude": -89.531871,
      "city": "Stevens Point",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54484,
      "latitude": 44.809072,
      "longitude": -90.030674,
      "city": "Stratford",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54485,
      "latitude": 45.396466,
      "longitude": -89.217933,
      "city": "Summit Lake",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54486,
      "latitude": 44.75984,
      "longitude": -89.039052,
      "city": "Tigerton",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54487,
      "latitude": 45.337814,
      "longitude": -89.765239,
      "city": "Tomahawk",
      "state": "WI",
      "county": "Lincoln"
  },
  {
      "zipCode": 54488,
      "latitude": 44.840956,
      "longitude": -90.257456,
      "city": "Unity",
      "state": "WI",
      "county": "Marathon"
  },
  {
      "zipCode": 54489,
      "latitude": 44.462338,
      "longitude": -90.003283,
      "city": "Vesper",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54490,
      "latitude": 45.330939,
      "longitude": -90.401519,
      "city": "Westboro",
      "state": "WI",
      "county": "Taylor"
  },
  {
      "zipCode": 54491,
      "latitude": 45.248003,
      "longitude": -88.803154,
      "city": "White Lake",
      "state": "WI",
      "county": "Langlade"
  },
  {
      "zipCode": 54492,
      "latitude": 44.509433,
      "longitude": -89.528584,
      "city": "Stevens Point",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54493,
      "latitude": 44.715341,
      "longitude": -90.769733,
      "city": "Willard",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54494,
      "latitude": 44.379694,
      "longitude": -89.918546,
      "city": "Wisconsin Rapids",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54495,
      "latitude": 44.388082,
      "longitude": -89.922751,
      "city": "Wisconsin Rapids",
      "state": "WI",
      "county": "Wood"
  },
  {
      "zipCode": 54498,
      "latitude": 44.944248,
      "longitude": -90.638388,
      "city": "Withee",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54499,
      "latitude": 44.801314,
      "longitude": -89.11476,
      "city": "Wittenberg",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54501,
      "latitude": 45.704474,
      "longitude": -89.386562,
      "city": "Rhinelander",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54511,
      "latitude": 45.711791,
      "longitude": -88.810386,
      "city": "Argonne",
      "state": "WI",
      "county": "Forest"
  },
  {
      "zipCode": 54512,
      "latitude": 46.078442,
      "longitude": -89.531393,
      "city": "Boulder Junction",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54513,
      "latitude": 45.584525,
      "longitude": -90.166914,
      "city": "Brantwood",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54514,
      "latitude": 46.394779,
      "longitude": -90.628174,
      "city": "Butternut",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54515,
      "latitude": 45.535762,
      "longitude": -90.504465,
      "city": "Catawba",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54517,
      "latitude": 46.645163,
      "longitude": -90.442721,
      "city": "Clam Lake",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54519,
      "latitude": 46.072385,
      "longitude": -89.260939,
      "city": "Conover",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54520,
      "latitude": 45.511846,
      "longitude": -88.891846,
      "city": "Crandon",
      "state": "WI",
      "county": "Forest"
  },
  {
      "zipCode": 54521,
      "latitude": 45.988126,
      "longitude": -89.265609,
      "city": "Eagle River",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54524,
      "latitude": 45.814336,
      "longitude": -90.453045,
      "city": "Fifield",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54525,
      "latitude": 46.395746,
      "longitude": -90.191139,
      "city": "Gile",
      "state": "WI",
      "county": "Iron"
  },
  {
      "zipCode": 54526,
      "latitude": 45.486195,
      "longitude": -90.847935,
      "city": "Glen Flora",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54527,
      "latitude": 46.126107,
      "longitude": -90.611172,
      "city": "Glidden",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54529,
      "latitude": 45.690115,
      "longitude": -89.663974,
      "city": "Harshaw",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54530,
      "latitude": 45.518558,
      "longitude": -90.743138,
      "city": "Hawkins",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54531,
      "latitude": 45.74835,
      "longitude": -89.821598,
      "city": "Hazelhurst",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54532,
      "latitude": 45.337678,
      "longitude": -89.735524,
      "city": "Heafford Junction",
      "state": "WI",
      "county": "Lincoln"
  },
  {
      "zipCode": 54534,
      "latitude": 46.371032,
      "longitude": -90.221645,
      "city": "Hurley",
      "state": "WI",
      "county": "Iron"
  },
  {
      "zipCode": 54536,
      "latitude": 46.318241,
      "longitude": -90.342024,
      "city": "Iron Belt",
      "state": "WI",
      "county": "Iron"
  },
  {
      "zipCode": 54537,
      "latitude": 45.528816,
      "longitude": -90.606178,
      "city": "Kennan",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54538,
      "latitude": 46.068073,
      "longitude": -89.76566,
      "city": "Lac Du Flambeau",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54539,
      "latitude": 45.775685,
      "longitude": -89.574808,
      "city": "Lake Tomahawk",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54540,
      "latitude": 46.073659,
      "longitude": -89.444514,
      "city": "Land O Lakes",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54541,
      "latitude": 45.561723,
      "longitude": -88.706496,
      "city": "Laona",
      "state": "WI",
      "county": "Forest"
  },
  {
      "zipCode": 54542,
      "latitude": 45.913837,
      "longitude": -88.623498,
      "city": "Long Lake",
      "state": "WI",
      "county": "Florence"
  },
  {
      "zipCode": 54543,
      "latitude": 45.72293,
      "longitude": -89.557263,
      "city": "Mc Naughton",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54545,
      "latitude": 46.155097,
      "longitude": -89.845465,
      "city": "Manitowish Waters",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54546,
      "latitude": 46.320677,
      "longitude": -90.746141,
      "city": "Mellen",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54547,
      "latitude": 46.143746,
      "longitude": -90.110725,
      "city": "Mercer",
      "state": "WI",
      "county": "Iron"
  },
  {
      "zipCode": 54548,
      "latitude": 45.827583,
      "longitude": -89.824294,
      "city": "Minocqua",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54550,
      "latitude": 46.396613,
      "longitude": -90.309085,
      "city": "Montreal",
      "state": "WI",
      "county": "Iron"
  },
  {
      "zipCode": 54552,
      "latitude": 45.846473,
      "longitude": -90.360373,
      "city": "Park Falls",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54554,
      "latitude": 46.092807,
      "longitude": -89.257762,
      "city": "Phelps",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54555,
      "latitude": 45.724607,
      "longitude": -90.370984,
      "city": "Phillips",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54556,
      "latitude": 45.532944,
      "longitude": -90.28166,
      "city": "Prentice",
      "state": "WI",
      "county": "Price"
  },
  {
      "zipCode": 54557,
      "latitude": 46.200011,
      "longitude": -89.738691,
      "city": "Presque Isle",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54558,
      "latitude": 45.942092,
      "longitude": -89.686925,
      "city": "Saint Germain",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54559,
      "latitude": 46.502552,
      "longitude": -90.402719,
      "city": "Saxon",
      "state": "WI",
      "county": "Iron"
  },
  {
      "zipCode": 54560,
      "latitude": 46.02023,
      "longitude": -89.497889,
      "city": "Sayner",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54561,
      "latitude": 46.061297,
      "longitude": -89.485834,
      "city": "Star Lake",
      "state": "WI",
      "county": "Vilas"
  },
  {
      "zipCode": 54562,
      "latitude": 45.762075,
      "longitude": -89.157339,
      "city": "Three Lakes",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54563,
      "latitude": 45.45197,
      "longitude": -90.964443,
      "city": "Tony",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54564,
      "latitude": 45.725515,
      "longitude": -89.943993,
      "city": "Tripoli",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54565,
      "latitude": 46.370313,
      "longitude": -90.434344,
      "city": "Upson",
      "state": "WI",
      "county": "Iron"
  },
  {
      "zipCode": 54566,
      "latitude": 45.443574,
      "longitude": -88.619413,
      "city": "Wabeno",
      "state": "WI",
      "county": "Forest"
  },
  {
      "zipCode": 54568,
      "latitude": 45.859618,
      "longitude": -89.653705,
      "city": "Woodruff",
      "state": "WI",
      "county": "Oneida"
  },
  {
      "zipCode": 54601,
      "latitude": 43.85456,
      "longitude": -91.132072,
      "city": "La Crosse",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54602,
      "latitude": 43.907739,
      "longitude": -91.167621,
      "city": "La Crosse",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54603,
      "latitude": 43.853763,
      "longitude": -91.246238,
      "city": "La Crosse",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54610,
      "latitude": 44.346721,
      "longitude": -91.836481,
      "city": "Alma",
      "state": "WI",
      "county": "Buffalo"
  },
  {
      "zipCode": 54611,
      "latitude": 44.451027,
      "longitude": -90.975918,
      "city": "Alma Center",
      "state": "WI",
      "county": "Jackson"
  },
  {
      "zipCode": 54612,
      "latitude": 44.251711,
      "longitude": -91.387699,
      "city": "Arcadia",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54613,
      "latitude": 44.079992,
      "longitude": -89.803877,
      "city": "Arkdale",
      "state": "WI",
      "county": "Adams"
  },
  {
      "zipCode": 54614,
      "latitude": 43.919308,
      "longitude": -91.081457,
      "city": "Bangor",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54615,
      "latitude": 44.295394,
      "longitude": -90.83128,
      "city": "Black River Falls",
      "state": "WI",
      "county": "Jackson"
  },
  {
      "zipCode": 54616,
      "latitude": 44.251701,
      "longitude": -91.264753,
      "city": "Blair",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54618,
      "latitude": 43.988481,
      "longitude": -90.239261,
      "city": "Camp Douglas",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 54619,
      "latitude": 43.851133,
      "longitude": -90.699047,
      "city": "Cashton",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54620,
      "latitude": 44.087601,
      "longitude": -90.842289,
      "city": "Cataract",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54621,
      "latitude": 43.667006,
      "longitude": -90.741409,
      "city": "Chaseburg",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54622,
      "latitude": 44.237714,
      "longitude": -91.741701,
      "city": "Cochrane",
      "state": "WI",
      "county": "Buffalo"
  },
  {
      "zipCode": 54623,
      "latitude": 43.574486,
      "longitude": -91.043819,
      "city": "Coon Valley",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54624,
      "latitude": 43.480668,
      "longitude": -91.133309,
      "city": "De Soto",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54625,
      "latitude": 44.14309,
      "longitude": -91.520432,
      "city": "Dodge",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54626,
      "latitude": 43.220573,
      "longitude": -91.034135,
      "city": "Eastman",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54627,
      "latitude": 44.184171,
      "longitude": -91.257707,
      "city": "Ettrick",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54628,
      "latitude": 43.319219,
      "longitude": -91.054678,
      "city": "Ferryville",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54629,
      "latitude": 44.170774,
      "longitude": -91.674962,
      "city": "Fountain City",
      "state": "WI",
      "county": "Buffalo"
  },
  {
      "zipCode": 54630,
      "latitude": 44.094809,
      "longitude": -91.339528,
      "city": "Galesville",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54631,
      "latitude": 43.269667,
      "longitude": -90.83178,
      "city": "Gays Mills",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54632,
      "latitude": 43.618089,
      "longitude": -90.837971,
      "city": "Genoa",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54634,
      "latitude": 43.616843,
      "longitude": -90.659829,
      "city": "Hillsboro",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54635,
      "latitude": 44.40376,
      "longitude": -91.044171,
      "city": "Hixton",
      "state": "WI",
      "county": "Jackson"
  },
  {
      "zipCode": 54636,
      "latitude": 43.968617,
      "longitude": -91.223484,
      "city": "Holmen",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54637,
      "latitude": 43.88013,
      "longitude": -90.272928,
      "city": "Hustler",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 54638,
      "latitude": 43.816431,
      "longitude": -90.40265,
      "city": "Kendall",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54639,
      "latitude": 43.600044,
      "longitude": -90.636482,
      "city": "La Farge",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54640,
      "latitude": 43.266172,
      "longitude": -91.022031,
      "city": "Lynxville",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54641,
      "latitude": 43.94521,
      "longitude": -90.049489,
      "city": "Mather",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 54642,
      "latitude": 44.196358,
      "longitude": -90.979249,
      "city": "Melrose",
      "state": "WI",
      "county": "Jackson"
  },
  {
      "zipCode": 54643,
      "latitude": 44.186869,
      "longitude": -90.635831,
      "city": "Millston",
      "state": "WI",
      "county": "Jackson"
  },
  {
      "zipCode": 54644,
      "latitude": 44.021422,
      "longitude": -91.03295,
      "city": "Mindoro",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54645,
      "latitude": 43.206361,
      "longitude": -90.940479,
      "city": "Mount Sterling",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54646,
      "latitude": 44.022637,
      "longitude": -90.130552,
      "city": "Necedah",
      "state": "WI",
      "county": "Juneau"
  },
  {
      "zipCode": 54648,
      "latitude": 43.83159,
      "longitude": -90.647476,
      "city": "Norwalk",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54649,
      "latitude": 43.971514,
      "longitude": -90.361161,
      "city": "Oakdale",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54650,
      "latitude": 43.886664,
      "longitude": -91.130399,
      "city": "Onalaska",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54651,
      "latitude": 43.696538,
      "longitude": -90.548053,
      "city": "Ontario",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54652,
      "latitude": 43.470286,
      "longitude": -90.774539,
      "city": "Readstown",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54653,
      "latitude": 43.841142,
      "longitude": -90.950732,
      "city": "Rockland",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54654,
      "latitude": 43.206361,
      "longitude": -90.940479,
      "city": "Seneca",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54655,
      "latitude": 43.369813,
      "longitude": -90.808363,
      "city": "Soldiers Grove",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54656,
      "latitude": 43.949925,
      "longitude": -90.752783,
      "city": "Sparta",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54657,
      "latitude": 43.274205,
      "longitude": -90.924336,
      "city": "Steuben",
      "state": "WI",
      "county": "Crawford"
  },
  {
      "zipCode": 54658,
      "latitude": 43.669102,
      "longitude": -91.134346,
      "city": "Stoddard",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54659,
      "latitude": 44.291449,
      "longitude": -91.087671,
      "city": "Taylor",
      "state": "WI",
      "county": "Jackson"
  },
  {
      "zipCode": 54660,
      "latitude": 43.983826,
      "longitude": -90.473336,
      "city": "Tomah",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54661,
      "latitude": 44.076755,
      "longitude": -91.460168,
      "city": "Trempealeau",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54662,
      "latitude": 44.003084,
      "longitude": -90.562005,
      "city": "Tunnel City",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54664,
      "latitude": 43.501783,
      "longitude": -90.706816,
      "city": "Viola",
      "state": "WI",
      "county": "Richland"
  },
  {
      "zipCode": 54665,
      "latitude": 43.530515,
      "longitude": -90.991573,
      "city": "Viroqua",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54666,
      "latitude": 43.987879,
      "longitude": -90.466582,
      "city": "Warrens",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54667,
      "latitude": 43.653701,
      "longitude": -90.841723,
      "city": "Westby",
      "state": "WI",
      "county": "Vernon"
  },
  {
      "zipCode": 54669,
      "latitude": 43.897572,
      "longitude": -91.120198,
      "city": "West Salem",
      "state": "WI",
      "county": "La Crosse"
  },
  {
      "zipCode": 54670,
      "latitude": 43.848835,
      "longitude": -90.468791,
      "city": "Wilton",
      "state": "WI",
      "county": "Monroe"
  },
  {
      "zipCode": 54701,
      "latitude": 44.75653,
      "longitude": -91.473097,
      "city": "Eau Claire",
      "state": "WI",
      "county": "Eau Claire"
  },
  {
      "zipCode": 54702,
      "latitude": 44.726626,
      "longitude": -91.285931,
      "city": "Eau Claire",
      "state": "WI",
      "county": "Eau Claire"
  },
  {
      "zipCode": 54703,
      "latitude": 44.80456,
      "longitude": -91.477897,
      "city": "Eau Claire",
      "state": "WI",
      "county": "Eau Claire"
  },
  {
      "zipCode": 54720,
      "latitude": 44.803612,
      "longitude": -91.442253,
      "city": "Altoona",
      "state": "WI",
      "county": "Eau Claire"
  },
  {
      "zipCode": 54721,
      "latitude": 44.602774,
      "longitude": -92.062219,
      "city": "Arkansaw",
      "state": "WI",
      "county": "Pepin"
  },
  {
      "zipCode": 54722,
      "latitude": 44.726811,
      "longitude": -91.212598,
      "city": "Augusta",
      "state": "WI",
      "county": "Eau Claire"
  },
  {
      "zipCode": 54723,
      "latitude": 44.631517,
      "longitude": -92.435186,
      "city": "Bay City",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54724,
      "latitude": 45.042352,
      "longitude": -91.408026,
      "city": "Bloomer",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54725,
      "latitude": 45.075804,
      "longitude": -92.013036,
      "city": "Boyceville",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54726,
      "latitude": 45.00521,
      "longitude": -91.175615,
      "city": "Boyd",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54727,
      "latitude": 45.015803,
      "longitude": -91.353301,
      "city": "Cadott",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54728,
      "latitude": 45.405423,
      "longitude": -91.828102,
      "city": "Chetek",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54729,
      "latitude": 44.958689,
      "longitude": -91.319492,
      "city": "Chippewa Falls",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54730,
      "latitude": 45.037889,
      "longitude": -91.888046,
      "city": "Colfax",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54731,
      "latitude": 45.357563,
      "longitude": -91.088353,
      "city": "Conrath",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54732,
      "latitude": 45.125153,
      "longitude": -91.170922,
      "city": "Cornell",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54733,
      "latitude": 45.261871,
      "longitude": -91.845156,
      "city": "Dallas",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54734,
      "latitude": 45.001949,
      "longitude": -92.099665,
      "city": "Downing",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54735,
      "latitude": 44.946496,
      "longitude": -91.90344,
      "city": "Downsville",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54736,
      "latitude": 44.640224,
      "longitude": -91.831869,
      "city": "Durand",
      "state": "WI",
      "county": "Pepin"
  },
  {
      "zipCode": 54737,
      "latitude": 44.749316,
      "longitude": -92.04191,
      "city": "Eau Galle",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54738,
      "latitude": 44.394025,
      "longitude": -91.411701,
      "city": "Eleva",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54739,
      "latitude": 44.855107,
      "longitude": -91.736968,
      "city": "Elk Mound",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54740,
      "latitude": 44.744453,
      "longitude": -92.226414,
      "city": "Elmwood",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54741,
      "latitude": 44.659822,
      "longitude": -91.014753,
      "city": "Fairchild",
      "state": "WI",
      "county": "Eau Claire"
  },
  {
      "zipCode": 54742,
      "latitude": 44.762892,
      "longitude": -91.306304,
      "city": "Fall Creek",
      "state": "WI",
      "county": "Eau Claire"
  },
  {
      "zipCode": 54743,
      "latitude": 44.311074,
      "longitude": -91.806396,
      "city": "Gilmanton",
      "state": "WI",
      "county": "Buffalo"
  },
  {
      "zipCode": 54744,
      "latitude": 45.319786,
      "longitude": -91.881754,
      "city": "Hillsdale",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54745,
      "latitude": 45.150752,
      "longitude": -91.121308,
      "city": "Holcombe",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54746,
      "latitude": 44.516708,
      "longitude": -90.709853,
      "city": "Humbird",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54747,
      "latitude": 44.356483,
      "longitude": -91.443718,
      "city": "Independence",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54748,
      "latitude": 45.088875,
      "longitude": -91.256794,
      "city": "Jim Falls",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54749,
      "latitude": 44.947538,
      "longitude": -92.07521,
      "city": "Knapp",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54750,
      "latitude": 44.623923,
      "longitude": -92.29072,
      "city": "Maiden Rock",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54751,
      "latitude": 44.867809,
      "longitude": -91.943198,
      "city": "Menomonie",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54754,
      "latitude": 44.436841,
      "longitude": -90.796318,
      "city": "Merrillan",
      "state": "WI",
      "county": "Jackson"
  },
  {
      "zipCode": 54755,
      "latitude": 44.48773,
      "longitude": -91.68068,
      "city": "Mondovi",
      "state": "WI",
      "county": "Buffalo"
  },
  {
      "zipCode": 54756,
      "latitude": 44.473584,
      "longitude": -91.910518,
      "city": "Nelson",
      "state": "WI",
      "county": "Buffalo"
  },
  {
      "zipCode": 54757,
      "latitude": 45.118159,
      "longitude": -91.486707,
      "city": "New Auburn",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54758,
      "latitude": 44.333502,
      "longitude": -91.347015,
      "city": "Osseo",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54759,
      "latitude": 44.509108,
      "longitude": -92.131821,
      "city": "Pepin",
      "state": "WI",
      "county": "Pepin"
  },
  {
      "zipCode": 54760,
      "latitude": 44.424863,
      "longitude": -91.207421,
      "city": "Pigeon Falls",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54761,
      "latitude": 44.619607,
      "longitude": -92.180419,
      "city": "Plum City",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54762,
      "latitude": 45.262353,
      "longitude": -91.992842,
      "city": "Prairie Farm",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54763,
      "latitude": 45.168279,
      "longitude": -91.875797,
      "city": "Ridgeland",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54764,
      "latitude": 44.946496,
      "longitude": -91.90344,
      "city": "Rock Falls",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54765,
      "latitude": 45.142866,
      "longitude": -91.699794,
      "city": "Sand Creek",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54766,
      "latitude": 45.349548,
      "longitude": -90.887173,
      "city": "Sheldon",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54767,
      "latitude": 44.781928,
      "longitude": -92.283761,
      "city": "Spring Valley",
      "state": "WI",
      "county": "Pierce"
  },
  {
      "zipCode": 54768,
      "latitude": 44.993395,
      "longitude": -91.069525,
      "city": "Stanley",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54769,
      "latitude": 44.508365,
      "longitude": -92.232594,
      "city": "Stockholm",
      "state": "WI",
      "county": "Pepin"
  },
  {
      "zipCode": 54770,
      "latitude": 44.387425,
      "longitude": -91.394415,
      "city": "Strum",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54771,
      "latitude": 44.894112,
      "longitude": -90.790684,
      "city": "Thorp",
      "state": "WI",
      "county": "Clark"
  },
  {
      "zipCode": 54772,
      "latitude": 45.094148,
      "longitude": -91.879257,
      "city": "Wheeler",
      "state": "WI",
      "county": "Dunn"
  },
  {
      "zipCode": 54773,
      "latitude": 44.443778,
      "longitude": -91.285758,
      "city": "Whitehall",
      "state": "WI",
      "county": "Trempealeau"
  },
  {
      "zipCode": 54774,
      "latitude": 45.07413,
      "longitude": -91.294397,
      "city": "Chippewa Falls",
      "state": "WI",
      "county": "Chippewa"
  },
  {
      "zipCode": 54801,
      "latitude": 45.922016,
      "longitude": -91.811707,
      "city": "Spooner",
      "state": "WI",
      "county": "Washburn"
  },
  {
      "zipCode": 54805,
      "latitude": 45.434373,
      "longitude": -91.965942,
      "city": "Almena",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54806,
      "latitude": 46.558577,
      "longitude": -90.738773,
      "city": "Ashland",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54810,
      "latitude": 45.421648,
      "longitude": -92.378752,
      "city": "Balsam Lake",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54812,
      "latitude": 45.47734,
      "longitude": -91.86779,
      "city": "Barron",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54813,
      "latitude": 45.557393,
      "longitude": -92.016728,
      "city": "Barronett",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54814,
      "latitude": 46.853517,
      "longitude": -90.915934,
      "city": "Bayfield",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54816,
      "latitude": 46.682796,
      "longitude": -91.143254,
      "city": "Benoit",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54817,
      "latitude": 45.732675,
      "longitude": -91.615044,
      "city": "Birchwood",
      "state": "WI",
      "county": "Washburn"
  },
  {
      "zipCode": 54818,
      "latitude": 45.423409,
      "longitude": -91.848206,
      "city": "Brill",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54819,
      "latitude": 45.469246,
      "longitude": -91.293073,
      "city": "Bruce",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54820,
      "latitude": 46.555409,
      "longitude": -91.605419,
      "city": "Brule",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54821,
      "latitude": 46.21018,
      "longitude": -91.189305,
      "city": "Cable",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54822,
      "latitude": 45.465645,
      "longitude": -91.68441,
      "city": "Cameron",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54824,
      "latitude": 45.420898,
      "longitude": -92.544088,
      "city": "Centuria",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54826,
      "latitude": 45.495679,
      "longitude": -92.037821,
      "city": "Comstock",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54827,
      "latitude": 46.834502,
      "longitude": -91.092821,
      "city": "Cornucopia",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54828,
      "latitude": 45.837967,
      "longitude": -91.291784,
      "city": "Couderay",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54829,
      "latitude": 45.42772,
      "longitude": -91.899489,
      "city": "Cumberland",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54830,
      "latitude": 45.972603,
      "longitude": -92.292996,
      "city": "Danbury",
      "state": "WI",
      "county": "Burnett"
  },
  {
      "zipCode": 54832,
      "latitude": 46.682796,
      "longitude": -91.143254,
      "city": "Drummond",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54834,
      "latitude": 45.742371,
      "longitude": -91.476541,
      "city": "Edgewater",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54835,
      "latitude": 45.780382,
      "longitude": -91.224718,
      "city": "Exeland",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54836,
      "latitude": 46.452892,
      "longitude": -92.171445,
      "city": "Foxboro",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54837,
      "latitude": 45.526263,
      "longitude": -92.429333,
      "city": "Frederic",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54838,
      "latitude": 46.293033,
      "longitude": -91.894633,
      "city": "Gordon",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54839,
      "latitude": 46.682796,
      "longitude": -91.143254,
      "city": "Grand View",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54840,
      "latitude": 45.795342,
      "longitude": -92.693513,
      "city": "Grantsburg",
      "state": "WI",
      "county": "Burnett"
  },
  {
      "zipCode": 54841,
      "latitude": 45.620445,
      "longitude": -91.801751,
      "city": "Haugen",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54842,
      "latitude": 46.514162,
      "longitude": -91.862136,
      "city": "Hawthorne",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54843,
      "latitude": 45.955223,
      "longitude": -91.278308,
      "city": "Hayward",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54844,
      "latitude": 46.682796,
      "longitude": -91.143254,
      "city": "Herbster",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54845,
      "latitude": 45.799612,
      "longitude": -92.153743,
      "city": "Hertel",
      "state": "WI",
      "county": "Burnett"
  },
  {
      "zipCode": 54846,
      "latitude": 46.376072,
      "longitude": -90.761153,
      "city": "High Bridge",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54847,
      "latitude": 46.599552,
      "longitude": -91.437437,
      "city": "Iron River",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54848,
      "latitude": 45.490835,
      "longitude": -91.0943,
      "city": "Ladysmith",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54849,
      "latitude": 46.493011,
      "longitude": -91.71342,
      "city": "Lake Nebagamon",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54850,
      "latitude": 46.803272,
      "longitude": -90.692287,
      "city": "La Pointe",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54851,
      "latitude": 45.468941,
      "longitude": -92.521938,
      "city": "Lewis",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54853,
      "latitude": 45.455357,
      "longitude": -92.467658,
      "city": "Luck",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54854,
      "latitude": 46.652638,
      "longitude": -91.733798,
      "city": "Maple",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54855,
      "latitude": 46.366834,
      "longitude": -90.840821,
      "city": "Marengo",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54856,
      "latitude": 46.362341,
      "longitude": -91.116899,
      "city": "Mason",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54857,
      "latitude": 45.609562,
      "longitude": -91.61965,
      "city": "Mikana",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54858,
      "latitude": 45.51583,
      "longitude": -92.461712,
      "city": "Milltown",
      "state": "WI",
      "county": "Polk"
  },
  {
      "zipCode": 54859,
      "latitude": 46.09709,
      "longitude": -91.859913,
      "city": "Minong",
      "state": "WI",
      "county": "Washburn"
  },
  {
      "zipCode": 54861,
      "latitude": 46.560783,
      "longitude": -90.619716,
      "city": "Odanah",
      "state": "WI",
      "county": "Ashland"
  },
  {
      "zipCode": 54862,
      "latitude": 45.797008,
      "longitude": -91.127234,
      "city": "Ojibwa",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54864,
      "latitude": 46.594249,
      "longitude": -91.815333,
      "city": "Poplar",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54865,
      "latitude": 46.715564,
      "longitude": -91.353195,
      "city": "Port Wing",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54867,
      "latitude": 45.800649,
      "longitude": -91.268979,
      "city": "Radisson",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54868,
      "latitude": 45.519692,
      "longitude": -91.826575,
      "city": "Rice Lake",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54870,
      "latitude": 45.73714,
      "longitude": -91.775875,
      "city": "Sarona",
      "state": "WI",
      "county": "Washburn"
  },
  {
      "zipCode": 54871,
      "latitude": 45.878356,
      "longitude": -91.79215,
      "city": "Shell Lake",
      "state": "WI",
      "county": "Washburn"
  },
  {
      "zipCode": 54872,
      "latitude": 45.782741,
      "longitude": -92.385751,
      "city": "Siren",
      "state": "WI",
      "county": "Burnett"
  },
  {
      "zipCode": 54873,
      "latitude": 46.417261,
      "longitude": -91.810992,
      "city": "Solon Springs",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54874,
      "latitude": 46.568809,
      "longitude": -91.935156,
      "city": "South Range",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54875,
      "latitude": 45.945201,
      "longitude": -91.675448,
      "city": "Springbrook",
      "state": "WI",
      "county": "Washburn"
  },
  {
      "zipCode": 54876,
      "latitude": 45.821216,
      "longitude": -91.462771,
      "city": "Stone Lake",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54880,
      "latitude": 46.574982,
      "longitude": -92.117578,
      "city": "Superior",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54888,
      "latitude": 45.966368,
      "longitude": -91.886372,
      "city": "Trego",
      "state": "WI",
      "county": "Washburn"
  },
  {
      "zipCode": 54889,
      "latitude": 45.400115,
      "longitude": -92.074695,
      "city": "Turtle Lake",
      "state": "WI",
      "county": "Barron"
  },
  {
      "zipCode": 54890,
      "latitude": 46.525129,
      "longitude": -91.921631,
      "city": "Wascott",
      "state": "WI",
      "county": "Douglas"
  },
  {
      "zipCode": 54891,
      "latitude": 46.697289,
      "longitude": -90.904151,
      "city": "Washburn",
      "state": "WI",
      "county": "Bayfield"
  },
  {
      "zipCode": 54893,
      "latitude": 45.869606,
      "longitude": -92.30286,
      "city": "Webster",
      "state": "WI",
      "county": "Burnett"
  },
  {
      "zipCode": 54895,
      "latitude": 45.40913,
      "longitude": -91.423051,
      "city": "Weyerhaeuser",
      "state": "WI",
      "county": "Rusk"
  },
  {
      "zipCode": 54896,
      "latitude": 45.832746,
      "longitude": -91.014362,
      "city": "Winter",
      "state": "WI",
      "county": "Sawyer"
  },
  {
      "zipCode": 54901,
      "latitude": 44.005661,
      "longitude": -88.55756,
      "city": "Oshkosh",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54902,
      "latitude": 43.99461,
      "longitude": -88.526025,
      "city": "Oshkosh",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54903,
      "latitude": 44.06858,
      "longitude": -88.644873,
      "city": "Oshkosh",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54904,
      "latitude": 44.062366,
      "longitude": -88.623779,
      "city": "Oshkosh",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54906,
      "latitude": 44.06858,
      "longitude": -88.644873,
      "city": "Oshkosh",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54909,
      "latitude": 44.379544,
      "longitude": -89.356552,
      "city": "Almond",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54911,
      "latitude": 44.275702,
      "longitude": -88.370856,
      "city": "Appleton",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54912,
      "latitude": 44.416326,
      "longitude": -88.464873,
      "city": "Appleton",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54913,
      "latitude": 44.345553,
      "longitude": -88.434297,
      "city": "Appleton",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54914,
      "latitude": 44.271285,
      "longitude": -88.486307,
      "city": "Appleton",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54915,
      "latitude": 44.373778,
      "longitude": -88.444396,
      "city": "Appleton",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54919,
      "latitude": 44.416326,
      "longitude": -88.464873,
      "city": "Appleton",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54921,
      "latitude": 44.341602,
      "longitude": -89.526303,
      "city": "Bancroft",
      "state": "WI",
      "county": "Portage"
  },
  {
      "zipCode": 54922,
      "latitude": 44.432373,
      "longitude": -88.559628,
      "city": "Bear Creek",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54923,
      "latitude": 43.915726,
      "longitude": -89.027124,
      "city": "Berlin",
      "state": "WI",
      "county": "Green Lake"
  },
  {
      "zipCode": 54926,
      "latitude": 44.617819,
      "longitude": -89.016622,
      "city": "Big Falls",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54927,
      "latitude": 44.103137,
      "longitude": -88.653949,
      "city": "Butte Des Morts",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54928,
      "latitude": 44.735836,
      "longitude": -88.876673,
      "city": "Caroline",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54929,
      "latitude": 44.593386,
      "longitude": -88.868093,
      "city": "Clintonville",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54930,
      "latitude": 44.026085,
      "longitude": -89.486854,
      "city": "Coloma",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54931,
      "latitude": 44.416326,
      "longitude": -88.464873,
      "city": "Dale",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54932,
      "latitude": 43.832253,
      "longitude": -88.582166,
      "city": "Eldorado",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 54933,
      "latitude": 44.668605,
      "longitude": -88.704318,
      "city": "Embarrass",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54934,
      "latitude": 44.003153,
      "longitude": -88.839692,
      "city": "Eureka",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54935,
      "latitude": 43.734724,
      "longitude": -88.523176,
      "city": "Fond Du Lac",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 54936,
      "latitude": 43.740559,
      "longitude": -88.522984,
      "city": "Fond Du Lac",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 54937,
      "latitude": 43.765009,
      "longitude": -88.605657,
      "city": "Fond Du Lac",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 54940,
      "latitude": 44.272779,
      "longitude": -88.833282,
      "city": "Fremont",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54941,
      "latitude": 43.85627,
      "longitude": -88.985883,
      "city": "Green Lake",
      "state": "WI",
      "county": "Green Lake"
  },
  {
      "zipCode": 54942,
      "latitude": 44.286983,
      "longitude": -88.556406,
      "city": "Greenville",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54943,
      "latitude": 44.103548,
      "longitude": -89.491566,
      "city": "Hancock",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54944,
      "latitude": 44.342058,
      "longitude": -88.588244,
      "city": "Hortonville",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54945,
      "latitude": 44.572209,
      "longitude": -89.071142,
      "city": "Iola",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54946,
      "latitude": 44.336537,
      "longitude": -89.146258,
      "city": "King",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54947,
      "latitude": 44.194993,
      "longitude": -88.688629,
      "city": "Larsen",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54948,
      "latitude": 44.781006,
      "longitude": -88.890714,
      "city": "Leopolis",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54949,
      "latitude": 44.488057,
      "longitude": -88.926954,
      "city": "Manawa",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54950,
      "latitude": 44.546623,
      "longitude": -88.937717,
      "city": "Marion",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54951,
      "latitude": 44.416326,
      "longitude": -88.464873,
      "city": "Medina",
      "state": "WI",
      "county": "Outagamie"
  },
  {
      "zipCode": 54952,
      "latitude": 44.141025,
      "longitude": -88.569088,
      "city": "Menasha",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54956,
      "latitude": 44.170785,
      "longitude": -88.658087,
      "city": "Neenah",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54957,
      "latitude": 44.198944,
      "longitude": -88.678863,
      "city": "Neenah",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54960,
      "latitude": 43.922097,
      "longitude": -89.310982,
      "city": "Neshkoro",
      "state": "WI",
      "county": "Marquette"
  },
  {
      "zipCode": 54961,
      "latitude": 44.405595,
      "longitude": -88.859107,
      "city": "New London",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54962,
      "latitude": 44.426783,
      "longitude": -88.993214,
      "city": "Ogdensburg",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54963,
      "latitude": 44.050782,
      "longitude": -88.754887,
      "city": "Omro",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54964,
      "latitude": 43.956653,
      "longitude": -88.71918,
      "city": "Pickett",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54965,
      "latitude": 44.155967,
      "longitude": -89.02096,
      "city": "Pine River",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54966,
      "latitude": 44.191887,
      "longitude": -89.297481,
      "city": "Plainfield",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54967,
      "latitude": 44.134044,
      "longitude": -88.986361,
      "city": "Poy Sippi",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54968,
      "latitude": 43.832741,
      "longitude": -89.122651,
      "city": "Princeton",
      "state": "WI",
      "county": "Green Lake"
  },
  {
      "zipCode": 54969,
      "latitude": 44.269991,
      "longitude": -88.775457,
      "city": "Readfield",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54970,
      "latitude": 44.080217,
      "longitude": -89.217031,
      "city": "Redgranite",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54971,
      "latitude": 43.782526,
      "longitude": -88.533445,
      "city": "Ripon",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 54974,
      "latitude": 43.775697,
      "longitude": -88.659504,
      "city": "Rosendale",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 54975,
      "latitude": 44.461926,
      "longitude": -88.915027,
      "city": "Royalton",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54976,
      "latitude": 44.209313,
      "longitude": -89.096856,
      "city": "Saxeville",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54977,
      "latitude": 44.451051,
      "longitude": -89.146915,
      "city": "Scandinavia",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54978,
      "latitude": 44.79604,
      "longitude": -88.898362,
      "city": "Tilleda",
      "state": "WI",
      "county": "Shawano"
  },
  {
      "zipCode": 54979,
      "latitude": 43.839613,
      "longitude": -88.543924,
      "city": "Van Dyne",
      "state": "WI",
      "county": "Fond Du Lac"
  },
  {
      "zipCode": 54980,
      "latitude": 43.988578,
      "longitude": -88.771167,
      "city": "Waukau",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54981,
      "latitude": 44.429163,
      "longitude": -89.04668,
      "city": "Waupaca",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54982,
      "latitude": 44.075321,
      "longitude": -89.271177,
      "city": "Wautoma",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54983,
      "latitude": 44.332498,
      "longitude": -88.922821,
      "city": "Weyauwega",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 54984,
      "latitude": 44.168882,
      "longitude": -89.223228,
      "city": "Wild Rose",
      "state": "WI",
      "county": "Waushara"
  },
  {
      "zipCode": 54985,
      "latitude": 44.070584,
      "longitude": -88.517762,
      "city": "Winnebago",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54986,
      "latitude": 44.124886,
      "longitude": -88.745129,
      "city": "Winneconne",
      "state": "WI",
      "county": "Winnebago"
  },
  {
      "zipCode": 54990,
      "latitude": 44.461926,
      "longitude": -88.915027,
      "city": "Iola",
      "state": "WI",
      "county": "Waupaca"
  },
  {
      "zipCode": 55001,
      "latitude": 44.90717,
      "longitude": -92.816193,
      "city": "Afton",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55002,
      "latitude": 45.513447,
      "longitude": -92.894239,
      "city": "Almelund",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55003,
      "latitude": 45.013918,
      "longitude": -92.781055,
      "city": "Bayport",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55005,
      "latitude": 45.388716,
      "longitude": -93.231538,
      "city": "Bethel",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55006,
      "latitude": 45.69146,
      "longitude": -93.278348,
      "city": "Braham",
      "state": "MN",
      "county": "Isanti"
  },
  {
      "zipCode": 55007,
      "latitude": 45.947444,
      "longitude": -93.073628,
      "city": "Brook Park",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55008,
      "latitude": 45.602457,
      "longitude": -93.263457,
      "city": "Cambridge",
      "state": "MN",
      "county": "Isanti"
  },
  {
      "zipCode": 55009,
      "latitude": 44.483189,
      "longitude": -92.885609,
      "city": "Cannon Falls",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55010,
      "latitude": 44.547215,
      "longitude": -93.153115,
      "city": "Castle Rock",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55011,
      "latitude": 45.341448,
      "longitude": -93.235026,
      "city": "Cedar",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55012,
      "latitude": 45.448211,
      "longitude": -92.789369,
      "city": "Center City",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55013,
      "latitude": 45.362621,
      "longitude": -92.902261,
      "city": "Chisago City",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55014,
      "latitude": 45.185565,
      "longitude": -93.129272,
      "city": "Circle Pines",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55016,
      "latitude": 44.818216,
      "longitude": -92.92861,
      "city": "Cottage Grove",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55017,
      "latitude": 45.660954,
      "longitude": -93.430806,
      "city": "Dalbo",
      "state": "MN",
      "county": "Isanti"
  },
  {
      "zipCode": 55018,
      "latitude": 44.426537,
      "longitude": -92.955359,
      "city": "Dennison",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55019,
      "latitude": 44.398613,
      "longitude": -93.194444,
      "city": "Dundas",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55020,
      "latitude": 44.588963,
      "longitude": -93.369473,
      "city": "Elko",
      "state": "MN",
      "county": "Scott"
  },
  {
      "zipCode": 55021,
      "latitude": 44.36287,
      "longitude": -93.267456,
      "city": "Faribault",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55024,
      "latitude": 44.640515,
      "longitude": -93.14196,
      "city": "Farmington",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55025,
      "latitude": 45.246466,
      "longitude": -92.949266,
      "city": "Forest Lake",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55026,
      "latitude": 44.520038,
      "longitude": -92.358158,
      "city": "Frontenac",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55027,
      "latitude": 44.427157,
      "longitude": -92.620355,
      "city": "Goodhue",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55029,
      "latitude": 45.642235,
      "longitude": -93.201107,
      "city": "Grandy",
      "state": "MN",
      "county": "Isanti"
  },
  {
      "zipCode": 55030,
      "latitude": 45.856598,
      "longitude": -93.081255,
      "city": "Grasston",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55031,
      "latitude": 44.608467,
      "longitude": -92.955479,
      "city": "Hampton",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55032,
      "latitude": 45.593629,
      "longitude": -92.998865,
      "city": "Harris",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55033,
      "latitude": 44.737166,
      "longitude": -93.032909,
      "city": "Hastings",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55036,
      "latitude": 45.854335,
      "longitude": -93.124235,
      "city": "Henriette",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55037,
      "latitude": 46.014689,
      "longitude": -92.938103,
      "city": "Hinckley",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55038,
      "latitude": 45.182366,
      "longitude": -92.945218,
      "city": "Hugo",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55040,
      "latitude": 45.565289,
      "longitude": -93.287101,
      "city": "Isanti",
      "state": "MN",
      "county": "Isanti"
  },
  {
      "zipCode": 55041,
      "latitude": 44.421753,
      "longitude": -92.232221,
      "city": "Lake City",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55042,
      "latitude": 44.992866,
      "longitude": -92.898177,
      "city": "Lake Elmo",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55043,
      "latitude": 44.940859,
      "longitude": -92.789447,
      "city": "Lakeland",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55044,
      "latitude": 44.633421,
      "longitude": -93.25812,
      "city": "Lakeville",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55045,
      "latitude": 45.404477,
      "longitude": -92.823088,
      "city": "Lindstrom",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55046,
      "latitude": 44.447704,
      "longitude": -93.425156,
      "city": "Lonsdale",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55047,
      "latitude": 45.198774,
      "longitude": -92.825767,
      "city": "Marine On Saint Croix",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55049,
      "latitude": 44.160283,
      "longitude": -93.246471,
      "city": "Medford",
      "state": "MN",
      "county": "Steele"
  },
  {
      "zipCode": 55051,
      "latitude": 45.918782,
      "longitude": -93.297792,
      "city": "Mora",
      "state": "MN",
      "county": "Kanabec"
  },
  {
      "zipCode": 55052,
      "latitude": 44.328365,
      "longitude": -93.342652,
      "city": "Morristown",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55053,
      "latitude": 44.32738,
      "longitude": -93.242155,
      "city": "Nerstrand",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55054,
      "latitude": 44.571056,
      "longitude": -93.354267,
      "city": "New Market",
      "state": "MN",
      "county": "Scott"
  },
  {
      "zipCode": 55055,
      "latitude": 44.872395,
      "longitude": -92.993263,
      "city": "Newport",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55056,
      "latitude": 45.556522,
      "longitude": -92.885433,
      "city": "North Branch",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55057,
      "latitude": 44.376816,
      "longitude": -93.241838,
      "city": "Northfield",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55060,
      "latitude": 44.047613,
      "longitude": -93.223724,
      "city": "Owatonna",
      "state": "MN",
      "county": "Steele"
  },
  {
      "zipCode": 55063,
      "latitude": 45.949599,
      "longitude": -92.892997,
      "city": "Pine City",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55065,
      "latitude": 44.545066,
      "longitude": -93.02496,
      "city": "Randolph",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55066,
      "latitude": 44.521909,
      "longitude": -92.537774,
      "city": "Red Wing",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55067,
      "latitude": 46.074687,
      "longitude": -92.718004,
      "city": "Rock Creek",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55068,
      "latitude": 44.661216,
      "longitude": -93.076163,
      "city": "Rosemount",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55069,
      "latitude": 45.67987,
      "longitude": -92.978158,
      "city": "Rush City",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55070,
      "latitude": 45.393554,
      "longitude": -93.357614,
      "city": "Saint Francis",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55071,
      "latitude": 44.822826,
      "longitude": -92.989204,
      "city": "Saint Paul Park",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55072,
      "latitude": 46.132453,
      "longitude": -92.588959,
      "city": "Sandstone",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55073,
      "latitude": 45.253166,
      "longitude": -92.837344,
      "city": "Scandia",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55074,
      "latitude": 45.382835,
      "longitude": -92.726135,
      "city": "Shafer",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55075,
      "latitude": 44.890315,
      "longitude": -93.049879,
      "city": "South Saint Paul",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55076,
      "latitude": 44.828816,
      "longitude": -93.039064,
      "city": "Inver Grove Heights",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55077,
      "latitude": 44.828265,
      "longitude": -93.093969,
      "city": "Inver Grove Heights",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55078,
      "latitude": 45.513447,
      "longitude": -92.894239,
      "city": "Stacy",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55079,
      "latitude": 45.405278,
      "longitude": -92.969118,
      "city": "Stacy",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55080,
      "latitude": 45.651313,
      "longitude": -93.275622,
      "city": "Stanchfield",
      "state": "MN",
      "county": "Isanti"
  },
  {
      "zipCode": 55082,
      "latitude": 45.061416,
      "longitude": -92.84736,
      "city": "Stillwater",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55083,
      "latitude": 45.021016,
      "longitude": -92.983726,
      "city": "Stillwater",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55084,
      "latitude": 45.457557,
      "longitude": -92.733042,
      "city": "Taylors Falls",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55085,
      "latitude": 44.674799,
      "longitude": -92.968309,
      "city": "Vermillion",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55087,
      "latitude": 44.239946,
      "longitude": -93.397354,
      "city": "Warsaw",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55088,
      "latitude": 44.500341,
      "longitude": -93.382574,
      "city": "Webster",
      "state": "MN",
      "county": "Rice"
  },
  {
      "zipCode": 55089,
      "latitude": 44.575372,
      "longitude": -92.704731,
      "city": "Welch",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55090,
      "latitude": 45.053466,
      "longitude": -92.957034,
      "city": "Willernie",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55092,
      "latitude": 45.336417,
      "longitude": -92.967517,
      "city": "Wyoming",
      "state": "MN",
      "county": "Chisago"
  },
  {
      "zipCode": 55101,
      "latitude": 44.967965,
      "longitude": -93.092168,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55102,
      "latitude": 44.932929,
      "longitude": -93.118968,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55103,
      "latitude": 44.967215,
      "longitude": -93.125319,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55104,
      "latitude": 44.955615,
      "longitude": -93.16702,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55105,
      "latitude": 44.934515,
      "longitude": -93.16097,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55106,
      "latitude": 44.957065,
      "longitude": -93.041616,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55107,
      "latitude": 44.932465,
      "longitude": -93.087967,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55108,
      "latitude": 44.980614,
      "longitude": -93.177122,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55109,
      "latitude": 45.013234,
      "longitude": -93.029667,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55110,
      "latitude": 45.079965,
      "longitude": -93.022317,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55111,
      "latitude": 44.882838,
      "longitude": -93.200671,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55112,
      "latitude": 45.078815,
      "longitude": -93.187223,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55113,
      "latitude": 45.013895,
      "longitude": -93.157071,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55114,
      "latitude": 44.964115,
      "longitude": -93.194872,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55115,
      "latitude": 45.070951,
      "longitude": -92.939113,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55116,
      "latitude": 44.911215,
      "longitude": -93.165356,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55117,
      "latitude": 45.002115,
      "longitude": -93.106269,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55118,
      "latitude": 44.856615,
      "longitude": -93.113028,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55119,
      "latitude": 44.941415,
      "longitude": -93.010714,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55120,
      "latitude": 44.870365,
      "longitude": -93.143369,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55121,
      "latitude": 44.822093,
      "longitude": -93.15908,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55122,
      "latitude": 44.786018,
      "longitude": -93.220205,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55123,
      "latitude": 44.805989,
      "longitude": -93.140878,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55124,
      "latitude": 44.749701,
      "longitude": -93.202881,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55125,
      "latitude": 44.919716,
      "longitude": -92.943876,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55126,
      "latitude": 45.073561,
      "longitude": -93.138022,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55127,
      "latitude": 45.080265,
      "longitude": -93.08752,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55128,
      "latitude": 44.991316,
      "longitude": -92.948738,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55129,
      "latitude": 44.898516,
      "longitude": -92.92301,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Washington"
  },
  {
      "zipCode": 55133,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55144,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55145,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55146,
      "latitude": 44.942656,
      "longitude": -93.082793,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55150,
      "latitude": 44.886554,
      "longitude": -93.161258,
      "city": "Mendota",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55155,
      "latitude": 44.952165,
      "longitude": -93.095518,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55161,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55164,
      "latitude": 44.990915,
      "longitude": -93.106593,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55165,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55166,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55168,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55169,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55170,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55171,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55172,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55175,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55177,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55182,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55187,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55188,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55190,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55191,
      "latitude": 45.005902,
      "longitude": -93.105869,
      "city": "Saint Paul",
      "state": "MN",
      "county": "Ramsey"
  },
  {
      "zipCode": 55301,
      "latitude": 45.258673,
      "longitude": -93.664342,
      "city": "Albertville",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55302,
      "latitude": 45.218652,
      "longitude": -94.105948,
      "city": "Annandale",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55303,
      "latitude": 45.282482,
      "longitude": -93.418574,
      "city": "Anoka",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55304,
      "latitude": 45.237661,
      "longitude": -93.272428,
      "city": "Andover",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55305,
      "latitude": 44.952763,
      "longitude": -93.43723,
      "city": "Hopkins",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55306,
      "latitude": 44.762208,
      "longitude": -93.221535,
      "city": "Burnsville",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55307,
      "latitude": 44.597449,
      "longitude": -94.105481,
      "city": "Arlington",
      "state": "MN",
      "county": "Sibley"
  },
  {
      "zipCode": 55308,
      "latitude": 45.451864,
      "longitude": -93.842187,
      "city": "Becker",
      "state": "MN",
      "county": "Sherburne"
  },
  {
      "zipCode": 55309,
      "latitude": 45.367294,
      "longitude": -93.686916,
      "city": "Big Lake",
      "state": "MN",
      "county": "Sherburne"
  },
  {
      "zipCode": 55310,
      "latitude": 44.753182,
      "longitude": -94.848215,
      "city": "Bird Island",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 55311,
      "latitude": 45.124263,
      "longitude": -93.499583,
      "city": "Osseo",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55312,
      "latitude": 44.758102,
      "longitude": -94.251718,
      "city": "Brownton",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55313,
      "latitude": 45.180732,
      "longitude": -93.927556,
      "city": "Buffalo",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55314,
      "latitude": 44.71031,
      "longitude": -94.752767,
      "city": "Buffalo Lake",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 55315,
      "latitude": 44.723162,
      "longitude": -93.701637,
      "city": "Carver",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55316,
      "latitude": 45.176914,
      "longitude": -93.397481,
      "city": "Champlin",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55317,
      "latitude": 44.853364,
      "longitude": -93.572584,
      "city": "Chanhassen",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55318,
      "latitude": 44.810062,
      "longitude": -93.653336,
      "city": "Chaska",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55319,
      "latitude": 45.466197,
      "longitude": -93.952504,
      "city": "Clear Lake",
      "state": "MN",
      "county": "Sherburne"
  },
  {
      "zipCode": 55320,
      "latitude": 45.226627,
      "longitude": -93.92441,
      "city": "Clearwater",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55321,
      "latitude": 45.086044,
      "longitude": -94.185096,
      "city": "Cokato",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55322,
      "latitude": 44.768262,
      "longitude": -93.787041,
      "city": "Cologne",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55323,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Crystal Bay",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55324,
      "latitude": 45.070558,
      "longitude": -94.420292,
      "city": "Darwin",
      "state": "MN",
      "county": "Meeker"
  },
  {
      "zipCode": 55325,
      "latitude": 45.102011,
      "longitude": -94.368691,
      "city": "Dassel",
      "state": "MN",
      "county": "Meeker"
  },
  {
      "zipCode": 55327,
      "latitude": 45.201514,
      "longitude": -93.482833,
      "city": "Dayton",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55328,
      "latitude": 45.041472,
      "longitude": -93.97792,
      "city": "Delano",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55329,
      "latitude": 45.282592,
      "longitude": -94.524712,
      "city": "Eden Valley",
      "state": "MN",
      "county": "Meeker"
  },
  {
      "zipCode": 55330,
      "latitude": 45.403289,
      "longitude": -93.644522,
      "city": "Elk River",
      "state": "MN",
      "county": "Sherburne"
  },
  {
      "zipCode": 55331,
      "latitude": 44.912712,
      "longitude": -93.623186,
      "city": "Excelsior",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55332,
      "latitude": 44.595338,
      "longitude": -94.726284,
      "city": "Fairfax",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 55333,
      "latitude": 44.564953,
      "longitude": -94.891273,
      "city": "Franklin",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 55334,
      "latitude": 44.572583,
      "longitude": -94.191699,
      "city": "Gaylord",
      "state": "MN",
      "county": "Sibley"
  },
  {
      "zipCode": 55335,
      "latitude": 44.55017,
      "longitude": -94.543258,
      "city": "Gibbon",
      "state": "MN",
      "county": "Sibley"
  },
  {
      "zipCode": 55336,
      "latitude": 44.770238,
      "longitude": -94.195685,
      "city": "Glencoe",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55337,
      "latitude": 44.771547,
      "longitude": -93.226258,
      "city": "Burnsville",
      "state": "MN",
      "county": "Dakota"
  },
  {
      "zipCode": 55338,
      "latitude": 44.63575,
      "longitude": -94.057781,
      "city": "Green Isle",
      "state": "MN",
      "county": "Sibley"
  },
  {
      "zipCode": 55339,
      "latitude": 44.785812,
      "longitude": -93.837873,
      "city": "Hamburg",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55340,
      "latitude": 45.073663,
      "longitude": -93.568735,
      "city": "Hamel",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55341,
      "latitude": 45.160196,
      "longitude": -93.673418,
      "city": "Hanover",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55342,
      "latitude": 44.70976,
      "longitude": -94.756704,
      "city": "Hector",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 55343,
      "latitude": 44.913969,
      "longitude": -93.44813,
      "city": "Hopkins",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55344,
      "latitude": 44.850563,
      "longitude": -93.440429,
      "city": "Eden Prairie",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55345,
      "latitude": 44.916963,
      "longitude": -93.481749,
      "city": "Minnetonka",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55346,
      "latitude": 44.875463,
      "longitude": -93.47808,
      "city": "Eden Prairie",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55347,
      "latitude": 44.841713,
      "longitude": -93.459829,
      "city": "Eden Prairie",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55348,
      "latitude": 44.848263,
      "longitude": -93.398727,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55349,
      "latitude": 45.101679,
      "longitude": -94.047497,
      "city": "Howard Lake",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55350,
      "latitude": 44.875565,
      "longitude": -94.266332,
      "city": "Hutchinson",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55352,
      "latitude": 44.658362,
      "longitude": -93.601183,
      "city": "Jordan",
      "state": "MN",
      "county": "Scott"
  },
  {
      "zipCode": 55353,
      "latitude": 45.36261,
      "longitude": -94.309928,
      "city": "Kimball",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 55354,
      "latitude": 44.880454,
      "longitude": -94.100616,
      "city": "Lester Prairie",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55355,
      "latitude": 45.122737,
      "longitude": -94.529861,
      "city": "Litchfield",
      "state": "MN",
      "county": "Meeker"
  },
  {
      "zipCode": 55356,
      "latitude": 44.995129,
      "longitude": -93.593836,
      "city": "Long Lake",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55357,
      "latitude": 45.100098,
      "longitude": -93.657256,
      "city": "Loretto",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55358,
      "latitude": 45.214072,
      "longitude": -94.060149,
      "city": "Maple Lake",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55359,
      "latitude": 44.983312,
      "longitude": -93.689162,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55360,
      "latitude": 44.905962,
      "longitude": -93.913561,
      "city": "Mayer",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55361,
      "latitude": 44.940212,
      "longitude": -93.592735,
      "city": "Minnetonka Beach",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55362,
      "latitude": 45.249636,
      "longitude": -93.851533,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55363,
      "latitude": 45.048202,
      "longitude": -94.056543,
      "city": "Montrose",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55364,
      "latitude": 44.935062,
      "longitude": -93.662938,
      "city": "Mound",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55365,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55366,
      "latitude": 44.673454,
      "longitude": -94.229311,
      "city": "New Auburn",
      "state": "MN",
      "county": "Sibley"
  },
  {
      "zipCode": 55367,
      "latitude": 44.899612,
      "longitude": -93.970832,
      "city": "New Germany",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55368,
      "latitude": 44.738862,
      "longitude": -93.89995,
      "city": "Norwood",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55369,
      "latitude": 45.128414,
      "longitude": -93.458932,
      "city": "Osseo",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55370,
      "latitude": 44.839939,
      "longitude": -94.050518,
      "city": "Plato",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55371,
      "latitude": 45.740703,
      "longitude": -93.63663,
      "city": "Princeton",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 55372,
      "latitude": 44.682763,
      "longitude": -93.464428,
      "city": "Prior Lake",
      "state": "MN",
      "county": "Scott"
  },
  {
      "zipCode": 55373,
      "latitude": 45.155019,
      "longitude": -93.865168,
      "city": "Rockford",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55374,
      "latitude": 45.168896,
      "longitude": -93.574586,
      "city": "Rogers",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55375,
      "latitude": 44.904062,
      "longitude": -93.74904,
      "city": "Saint Bonifacius",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55376,
      "latitude": 45.16826,
      "longitude": -93.893628,
      "city": "Saint Michael",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55377,
      "latitude": 45.540181,
      "longitude": -93.815434,
      "city": "Santiago",
      "state": "MN",
      "county": "Sherburne"
  },
  {
      "zipCode": 55378,
      "latitude": 44.751113,
      "longitude": -93.367975,
      "city": "Savage",
      "state": "MN",
      "county": "Scott"
  },
  {
      "zipCode": 55379,
      "latitude": 44.731113,
      "longitude": -93.474144,
      "city": "Shakopee",
      "state": "MN",
      "county": "Scott"
  },
  {
      "zipCode": 55380,
      "latitude": 45.315823,
      "longitude": -93.979766,
      "city": "Silver Creek",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55381,
      "latitude": 44.921134,
      "longitude": -94.195425,
      "city": "Silver Lake",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55382,
      "latitude": 45.265248,
      "longitude": -94.165984,
      "city": "South Haven",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55383,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Norwood",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55384,
      "latitude": 44.936862,
      "longitude": -93.630286,
      "city": "Spring Park",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55385,
      "latitude": 44.766671,
      "longitude": -94.344376,
      "city": "Stewart",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55386,
      "latitude": 44.846645,
      "longitude": -93.661737,
      "city": "Victoria",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55387,
      "latitude": 44.844847,
      "longitude": -93.746148,
      "city": "Waconia",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55388,
      "latitude": 44.924416,
      "longitude": -93.853894,
      "city": "Watertown",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55389,
      "latitude": 45.268619,
      "longitude": -94.444561,
      "city": "Watkins",
      "state": "MN",
      "county": "Meeker"
  },
  {
      "zipCode": 55390,
      "latitude": 45.060676,
      "longitude": -93.974555,
      "city": "Waverly",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55391,
      "latitude": 44.984663,
      "longitude": -93.542233,
      "city": "Wayzata",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55392,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Navarre",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55393,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55394,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55395,
      "latitude": 44.946121,
      "longitude": -94.07572,
      "city": "Winsted",
      "state": "MN",
      "county": "Mcleod"
  },
  {
      "zipCode": 55396,
      "latitude": 44.550833,
      "longitude": -94.347525,
      "city": "Winthrop",
      "state": "MN",
      "county": "Sibley"
  },
  {
      "zipCode": 55397,
      "latitude": 44.800912,
      "longitude": -93.919675,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55398,
      "latitude": 45.467503,
      "longitude": -93.602475,
      "city": "Zimmerman",
      "state": "MN",
      "county": "Sherburne"
  },
  {
      "zipCode": 55399,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55401,
      "latitude": 44.979265,
      "longitude": -93.273024,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55402,
      "latitude": 44.975915,
      "longitude": -93.271825,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55403,
      "latitude": 44.972615,
      "longitude": -93.287275,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55404,
      "latitude": 44.948614,
      "longitude": -93.329926,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55405,
      "latitude": 44.970114,
      "longitude": -93.300275,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55406,
      "latitude": 44.976015,
      "longitude": -93.278975,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55407,
      "latitude": 44.935465,
      "longitude": -93.254023,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55408,
      "latitude": 44.947515,
      "longitude": -93.288975,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55409,
      "latitude": 44.925014,
      "longitude": -93.289224,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55410,
      "latitude": 44.912364,
      "longitude": -93.318825,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55411,
      "latitude": 44.999514,
      "longitude": -93.297393,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55412,
      "latitude": 45.025115,
      "longitude": -93.298876,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55413,
      "latitude": 44.994365,
      "longitude": -93.240774,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55414,
      "latitude": 44.974515,
      "longitude": -93.234173,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55415,
      "latitude": 44.974215,
      "longitude": -93.258474,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55416,
      "latitude": 44.949714,
      "longitude": -93.337326,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55417,
      "latitude": 44.962965,
      "longitude": -93.253624,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55418,
      "latitude": 45.017765,
      "longitude": -93.244524,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55419,
      "latitude": 44.890914,
      "longitude": -93.282724,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55420,
      "latitude": 44.835164,
      "longitude": -93.255222,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55421,
      "latitude": 45.052315,
      "longitude": -93.254075,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55422,
      "latitude": 45.009601,
      "longitude": -93.342428,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55423,
      "latitude": 44.875614,
      "longitude": -93.255272,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55424,
      "latitude": 44.905164,
      "longitude": -93.340326,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55425,
      "latitude": 44.842664,
      "longitude": -93.236286,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55426,
      "latitude": 44.955014,
      "longitude": -93.382928,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55427,
      "latitude": 44.999964,
      "longitude": -93.390979,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55428,
      "latitude": 44.981413,
      "longitude": -93.372979,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55429,
      "latitude": 44.975664,
      "longitude": -93.336926,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55430,
      "latitude": 45.063923,
      "longitude": -93.302227,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55431,
      "latitude": 44.828764,
      "longitude": -93.311823,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55432,
      "latitude": 45.094965,
      "longitude": -93.23957,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55433,
      "latitude": 45.164263,
      "longitude": -93.319278,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55434,
      "latitude": 45.170399,
      "longitude": -93.226925,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55435,
      "latitude": 44.932864,
      "longitude": -93.367327,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55436,
      "latitude": 44.901163,
      "longitude": -93.42267,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55437,
      "latitude": 44.826064,
      "longitude": -93.353791,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55438,
      "latitude": 44.826613,
      "longitude": -93.375027,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55439,
      "latitude": 44.874414,
      "longitude": -93.375277,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55440,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55441,
      "latitude": 45.005804,
      "longitude": -93.419323,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55442,
      "latitude": 45.04674,
      "longitude": -93.431047,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55443,
      "latitude": 45.119364,
      "longitude": -93.34312,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55444,
      "latitude": 45.117765,
      "longitude": -93.305378,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55445,
      "latitude": 45.123064,
      "longitude": -93.352439,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55446,
      "latitude": 45.040013,
      "longitude": -93.486482,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55447,
      "latitude": 45.003335,
      "longitude": -93.487482,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55448,
      "latitude": 45.174056,
      "longitude": -93.313274,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55449,
      "latitude": 45.169739,
      "longitude": -93.188924,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Anoka"
  },
  {
      "zipCode": 55450,
      "latitude": 44.881113,
      "longitude": -93.220658,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55454,
      "latitude": 44.980859,
      "longitude": -93.252524,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55455,
      "latitude": 45.038364,
      "longitude": -93.298376,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55458,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55459,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55460,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55468,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55470,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55472,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55473,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55474,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55478,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55479,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55480,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55483,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55484,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55485,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55486,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55487,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55488,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Minneapolis",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55550,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55551,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55552,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55553,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55554,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Norwood",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55555,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55556,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55557,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55558,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55559,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55560,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55561,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Monticello",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55562,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55563,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Monticello",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55564,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55565,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55566,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55567,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55568,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55569,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Osseo",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55570,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55571,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55572,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55573,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Young America",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55574,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55575,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Howard Lake",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55576,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55577,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55578,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55579,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55580,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55581,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55582,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55583,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Norwood",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55584,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55585,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55586,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55587,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55588,
      "latitude": 44.989512,
      "longitude": -93.880245,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55589,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55590,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55591,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Monticello",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55592,
      "latitude": 45.200875,
      "longitude": -93.888099,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Wright"
  },
  {
      "zipCode": 55593,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Maple Plain",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55594,
      "latitude": 44.805487,
      "longitude": -93.766524,
      "city": "Young America",
      "state": "MN",
      "county": "Carver"
  },
  {
      "zipCode": 55595,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Loretto",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55596,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Loretto",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55597,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Loretto",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55598,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Loretto",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55599,
      "latitude": 45.015914,
      "longitude": -93.47188,
      "city": "Loretto",
      "state": "MN",
      "county": "Hennepin"
  },
  {
      "zipCode": 55601,
      "latitude": 47.256021,
      "longitude": -91.356586,
      "city": "Beaver Bay",
      "state": "MN",
      "county": "Lake"
  },
  {
      "zipCode": 55602,
      "latitude": 47.256933,
      "longitude": -92.00427,
      "city": "Brimson",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55603,
      "latitude": 47.497114,
      "longitude": -91.320571,
      "city": "Finland",
      "state": "MN",
      "county": "Lake"
  },
  {
      "zipCode": 55604,
      "latitude": 47.872285,
      "longitude": -90.42294,
      "city": "Grand Marais",
      "state": "MN",
      "county": "Cook"
  },
  {
      "zipCode": 55605,
      "latitude": 47.923022,
      "longitude": -89.851983,
      "city": "Grand Portage",
      "state": "MN",
      "county": "Cook"
  },
  {
      "zipCode": 55606,
      "latitude": 47.851669,
      "longitude": -90.001214,
      "city": "Hovland",
      "state": "MN",
      "county": "Cook"
  },
  {
      "zipCode": 55607,
      "latitude": 47.660406,
      "longitude": -91.498861,
      "city": "Isabella",
      "state": "MN",
      "county": "Lake"
  },
  {
      "zipCode": 55609,
      "latitude": 46.95388,
      "longitude": -91.777997,
      "city": "Knife River",
      "state": "MN",
      "county": "Lake"
  },
  {
      "zipCode": 55612,
      "latitude": 47.7059,
      "longitude": -90.682372,
      "city": "Lutsen",
      "state": "MN",
      "county": "Cook"
  },
  {
      "zipCode": 55613,
      "latitude": 47.518552,
      "longitude": -90.949997,
      "city": "Schroeder",
      "state": "MN",
      "county": "Cook"
  },
  {
      "zipCode": 55614,
      "latitude": 47.358488,
      "longitude": -91.220483,
      "city": "Silver Bay",
      "state": "MN",
      "county": "Lake"
  },
  {
      "zipCode": 55615,
      "latitude": 47.648636,
      "longitude": -90.801861,
      "city": "Tofte",
      "state": "MN",
      "county": "Cook"
  },
  {
      "zipCode": 55616,
      "latitude": 47.134891,
      "longitude": -91.545363,
      "city": "Two Harbors",
      "state": "MN",
      "county": "Lake"
  },
  {
      "zipCode": 55701,
      "latitude": 47.640367,
      "longitude": -92.442797,
      "city": "Adolph",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55702,
      "latitude": 47.014861,
      "longitude": -92.612312,
      "city": "Alborn",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55703,
      "latitude": 47.753747,
      "longitude": -92.756769,
      "city": "Angora",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55704,
      "latitude": 46.215511,
      "longitude": -92.759076,
      "city": "Askov",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55705,
      "latitude": 47.634557,
      "longitude": -92.071317,
      "city": "Aurora",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55706,
      "latitude": 47.742305,
      "longitude": -91.953532,
      "city": "Babbitt",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55707,
      "latitude": 46.556833,
      "longitude": -92.720097,
      "city": "Barnum",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55708,
      "latitude": 47.532826,
      "longitude": -92.340774,
      "city": "Biwabik",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55709,
      "latitude": 47.347269,
      "longitude": -93.388826,
      "city": "Bovey",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55710,
      "latitude": 47.645047,
      "longitude": -92.651923,
      "city": "Britt",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55711,
      "latitude": 46.837747,
      "longitude": -92.680451,
      "city": "Brookston",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55712,
      "latitude": 46.2263,
      "longitude": -92.705581,
      "city": "Bruno",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55713,
      "latitude": 47.493197,
      "longitude": -92.764262,
      "city": "Buhl",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55716,
      "latitude": 47.322883,
      "longitude": -93.276267,
      "city": "Calumet",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55717,
      "latitude": 47.0686,
      "longitude": -92.442794,
      "city": "Canyon",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55718,
      "latitude": 46.622795,
      "longitude": -92.675569,
      "city": "Carlton",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55719,
      "latitude": 47.563308,
      "longitude": -92.443251,
      "city": "Chisholm",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55720,
      "latitude": 46.592512,
      "longitude": -92.549564,
      "city": "Cloquet",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55721,
      "latitude": 47.238241,
      "longitude": -93.516501,
      "city": "Cohasset",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55722,
      "latitude": 47.377503,
      "longitude": -93.385597,
      "city": "Coleraine",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55723,
      "latitude": 47.877563,
      "longitude": -92.768568,
      "city": "Cook",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55724,
      "latitude": 47.152067,
      "longitude": -92.435223,
      "city": "Cotton",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55725,
      "latitude": 48.241245,
      "longitude": -92.525385,
      "city": "Crane Lake",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55726,
      "latitude": 46.654061,
      "longitude": -92.836499,
      "city": "Cromwell",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55730,
      "latitude": 47.087782,
      "longitude": -93.921429,
      "city": "Grand Rapids",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55731,
      "latitude": 47.918943,
      "longitude": -92.020778,
      "city": "Ely",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55732,
      "latitude": 47.662641,
      "longitude": -92.228145,
      "city": "Embarrass",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55733,
      "latitude": 46.712582,
      "longitude": -92.364896,
      "city": "Esko",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55734,
      "latitude": 47.386893,
      "longitude": -92.452058,
      "city": "Eveleth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55735,
      "latitude": 46.23792,
      "longitude": -92.950358,
      "city": "Finlayson",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55736,
      "latitude": 46.937895,
      "longitude": -92.837735,
      "city": "Floodwood",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55738,
      "latitude": 47.273191,
      "longitude": -92.675563,
      "city": "Forbes",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55741,
      "latitude": 47.447686,
      "longitude": -92.366335,
      "city": "Gilbert",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55742,
      "latitude": 47.167838,
      "longitude": -93.132367,
      "city": "Goodland",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55744,
      "latitude": 47.232889,
      "longitude": -93.393555,
      "city": "Grand Rapids",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55745,
      "latitude": 47.087782,
      "longitude": -93.921429,
      "city": "Grand Rapids",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55746,
      "latitude": 47.413263,
      "longitude": -92.87621,
      "city": "Hibbing",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55747,
      "latitude": 47.640367,
      "longitude": -92.442797,
      "city": "Hibbing",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55748,
      "latitude": 46.671645,
      "longitude": -93.432392,
      "city": "Hill City",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 55749,
      "latitude": 46.482697,
      "longitude": -92.410931,
      "city": "Holyoke",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55750,
      "latitude": 47.507262,
      "longitude": -92.112844,
      "city": "Hoyt Lakes",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55751,
      "latitude": 47.420472,
      "longitude": -92.681078,
      "city": "Iron",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55752,
      "latitude": 46.592204,
      "longitude": -93.433078,
      "city": "Jacobson",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 55753,
      "latitude": 47.398025,
      "longitude": -93.078443,
      "city": "Keewatin",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55756,
      "latitude": 46.331356,
      "longitude": -92.662237,
      "city": "Kerrick",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55757,
      "latitude": 46.52049,
      "longitude": -92.908622,
      "city": "Kettle River",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55758,
      "latitude": 47.512132,
      "longitude": -92.740216,
      "city": "Kinney",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55760,
      "latitude": 46.607188,
      "longitude": -93.30756,
      "city": "Mcgregor",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 55763,
      "latitude": 47.264753,
      "longitude": -92.181209,
      "city": "Makinen",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55764,
      "latitude": 47.321916,
      "longitude": -93.29388,
      "city": "Marble",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55765,
      "latitude": 47.116076,
      "longitude": -92.803506,
      "city": "Meadowlands",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55766,
      "latitude": 47.249626,
      "longitude": -92.412343,
      "city": "Melrude",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55767,
      "latitude": 46.552827,
      "longitude": -92.756405,
      "city": "Moose Lake",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55768,
      "latitude": 47.454993,
      "longitude": -92.686192,
      "city": "Mountain Iron",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55769,
      "latitude": 47.441216,
      "longitude": -93.243688,
      "city": "Nashwauk",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55771,
      "latitude": 47.742195,
      "longitude": -92.757902,
      "city": "Orr",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55772,
      "latitude": 48.081686,
      "longitude": -93.083438,
      "city": "Nett Lake",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55775,
      "latitude": 47.287391,
      "longitude": -93.212906,
      "city": "Pengilly",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55777,
      "latitude": 47.640367,
      "longitude": -92.442797,
      "city": "Virginia",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55779,
      "latitude": 46.910179,
      "longitude": -92.448123,
      "city": "Saginaw",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55780,
      "latitude": 46.701794,
      "longitude": -92.639079,
      "city": "Sawyer",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55781,
      "latitude": 47.551459,
      "longitude": -92.994885,
      "city": "Side Lake",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55782,
      "latitude": 47.821007,
      "longitude": -92.246359,
      "city": "Soudan",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55783,
      "latitude": 46.367039,
      "longitude": -92.824278,
      "city": "Sturgeon Lake",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55784,
      "latitude": 47.06717,
      "longitude": -93.190708,
      "city": "Swan River",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55785,
      "latitude": 46.696774,
      "longitude": -93.645502,
      "city": "Swatara",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 55786,
      "latitude": 47.316395,
      "longitude": -93.342118,
      "city": "Taconite",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55787,
      "latitude": 46.630318,
      "longitude": -93.213416,
      "city": "Tamarack",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 55790,
      "latitude": 47.787247,
      "longitude": -92.338233,
      "city": "Tower",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55791,
      "latitude": 47.640367,
      "longitude": -92.442797,
      "city": "Twig",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55792,
      "latitude": 47.646075,
      "longitude": -92.499975,
      "city": "Virginia",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55793,
      "latitude": 47.241169,
      "longitude": -93.228104,
      "city": "Warba",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 55795,
      "latitude": 46.310484,
      "longitude": -92.863863,
      "city": "Willow River",
      "state": "MN",
      "county": "Pine"
  },
  {
      "zipCode": 55796,
      "latitude": 47.720643,
      "longitude": -92.266525,
      "city": "Winton",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55797,
      "latitude": 46.565172,
      "longitude": -92.657895,
      "city": "Wrenshall",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55798,
      "latitude": 46.614151,
      "longitude": -92.735406,
      "city": "Wright",
      "state": "MN",
      "county": "Carlton"
  },
  {
      "zipCode": 55801,
      "latitude": 47.005566,
      "longitude": -92.001934,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55802,
      "latitude": 46.904912,
      "longitude": -92.039109,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55803,
      "latitude": 47.217311,
      "longitude": -92.1184,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55804,
      "latitude": 46.886239,
      "longitude": -92.005488,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55805,
      "latitude": 46.800389,
      "longitude": -92.094589,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55806,
      "latitude": 46.774939,
      "longitude": -92.133189,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55807,
      "latitude": 46.735978,
      "longitude": -92.17764,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55808,
      "latitude": 46.683891,
      "longitude": -92.242241,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55810,
      "latitude": 46.76062,
      "longitude": -92.266038,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55811,
      "latitude": 46.814712,
      "longitude": -92.199825,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55812,
      "latitude": 46.810788,
      "longitude": -92.072288,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55814,
      "latitude": 47.640367,
      "longitude": -92.442797,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55815,
      "latitude": 47.640367,
      "longitude": -92.442797,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55816,
      "latitude": 47.640367,
      "longitude": -92.442797,
      "city": "Duluth",
      "state": "MN",
      "county": "Saint Louis"
  },
  {
      "zipCode": 55901,
      "latitude": 44.075285,
      "longitude": -92.516916,
      "city": "Rochester",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55902,
      "latitude": 43.972494,
      "longitude": -92.389901,
      "city": "Rochester",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55903,
      "latitude": 43.996613,
      "longitude": -92.540929,
      "city": "Rochester",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55904,
      "latitude": 43.98622,
      "longitude": -92.302649,
      "city": "Rochester",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55905,
      "latitude": 44.022513,
      "longitude": -92.466826,
      "city": "Rochester",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55906,
      "latitude": 44.107815,
      "longitude": -92.405294,
      "city": "Rochester",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55909,
      "latitude": 43.565168,
      "longitude": -92.74389,
      "city": "Adams",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55910,
      "latitude": 44.085616,
      "longitude": -91.946134,
      "city": "Altura",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55912,
      "latitude": 43.699305,
      "longitude": -92.976818,
      "city": "Austin",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55917,
      "latitude": 44.011627,
      "longitude": -93.144007,
      "city": "Blooming Prairie",
      "state": "MN",
      "county": "Steele"
  },
  {
      "zipCode": 55918,
      "latitude": 43.746834,
      "longitude": -92.866996,
      "city": "Brownsdale",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55919,
      "latitude": 43.648232,
      "longitude": -91.410977,
      "city": "Brownsville",
      "state": "MN",
      "county": "Houston"
  },
  {
      "zipCode": 55920,
      "latitude": 43.988227,
      "longitude": -92.599372,
      "city": "Byron",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55921,
      "latitude": 43.635474,
      "longitude": -91.458938,
      "city": "Caledonia",
      "state": "MN",
      "county": "Houston"
  },
  {
      "zipCode": 55922,
      "latitude": 43.527479,
      "longitude": -91.85297,
      "city": "Canton",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55923,
      "latitude": 43.758684,
      "longitude": -92.139962,
      "city": "Chatfield",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55924,
      "latitude": 44.046168,
      "longitude": -92.975313,
      "city": "Claremont",
      "state": "MN",
      "county": "Dodge"
  },
  {
      "zipCode": 55925,
      "latitude": 43.935613,
      "longitude": -91.606021,
      "city": "Dakota",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55926,
      "latitude": 43.616293,
      "longitude": -92.786355,
      "city": "Dexter",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55927,
      "latitude": 44.045362,
      "longitude": -92.910808,
      "city": "Dodge Center",
      "state": "MN",
      "county": "Dodge"
  },
  {
      "zipCode": 55929,
      "latitude": 43.989637,
      "longitude": -92.138889,
      "city": "Dover",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55931,
      "latitude": 43.508371,
      "longitude": -91.463204,
      "city": "Eitzen",
      "state": "MN",
      "county": "Houston"
  },
  {
      "zipCode": 55932,
      "latitude": 44.123683,
      "longitude": -92.252177,
      "city": "Elgin",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55933,
      "latitude": 43.664334,
      "longitude": -92.682753,
      "city": "Elkton",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55934,
      "latitude": 44.009932,
      "longitude": -92.264837,
      "city": "Eyota",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55935,
      "latitude": 43.651181,
      "longitude": -92.075216,
      "city": "Fountain",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55936,
      "latitude": 43.668417,
      "longitude": -92.544587,
      "city": "Grand Meadow",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55939,
      "latitude": 43.534993,
      "longitude": -92.069594,
      "city": "Harmony",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55940,
      "latitude": 43.896909,
      "longitude": -92.797679,
      "city": "Hayfield",
      "state": "MN",
      "county": "Dodge"
  },
  {
      "zipCode": 55941,
      "latitude": 43.759533,
      "longitude": -91.398755,
      "city": "Hokah",
      "state": "MN",
      "county": "Houston"
  },
  {
      "zipCode": 55942,
      "latitude": 44.019989,
      "longitude": -91.68187,
      "city": "Homer",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55943,
      "latitude": 43.781431,
      "longitude": -91.571198,
      "city": "Houston",
      "state": "MN",
      "county": "Houston"
  },
  {
      "zipCode": 55944,
      "latitude": 44.017216,
      "longitude": -92.790593,
      "city": "Kasson",
      "state": "MN",
      "county": "Dodge"
  },
  {
      "zipCode": 55945,
      "latitude": 44.273871,
      "longitude": -92.109479,
      "city": "Kellogg",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55946,
      "latitude": 44.294333,
      "longitude": -92.905937,
      "city": "Kenyon",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55947,
      "latitude": 43.770564,
      "longitude": -91.352968,
      "city": "La Crescent",
      "state": "MN",
      "county": "Houston"
  },
  {
      "zipCode": 55949,
      "latitude": 43.721194,
      "longitude": -91.977384,
      "city": "Lanesboro",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55950,
      "latitude": 43.762936,
      "longitude": -92.965279,
      "city": "Lansing",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55951,
      "latitude": 43.546515,
      "longitude": -92.532554,
      "city": "Le Roy",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55952,
      "latitude": 43.944412,
      "longitude": -91.880535,
      "city": "Lewiston",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55953,
      "latitude": 43.506952,
      "longitude": -92.942939,
      "city": "Lyle",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55954,
      "latitude": 43.521277,
      "longitude": -91.768082,
      "city": "Mabel",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55955,
      "latitude": 44.065741,
      "longitude": -92.760046,
      "city": "Mantorville",
      "state": "MN",
      "county": "Dodge"
  },
  {
      "zipCode": 55956,
      "latitude": 44.241752,
      "longitude": -92.513947,
      "city": "Mazeppa",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55957,
      "latitude": 44.234483,
      "longitude": -92.336216,
      "city": "Millville",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55959,
      "latitude": 44.081907,
      "longitude": -91.73508,
      "city": "Minnesota City",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55960,
      "latitude": 44.084556,
      "longitude": -92.373869,
      "city": "Oronoco",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55961,
      "latitude": 43.714209,
      "longitude": -92.087863,
      "city": "Ostrander",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55962,
      "latitude": 43.747262,
      "longitude": -92.048539,
      "city": "Peterson",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55963,
      "latitude": 44.261029,
      "longitude": -92.710905,
      "city": "Pine Island",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55964,
      "latitude": 44.15121,
      "longitude": -92.202044,
      "city": "Plainview",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55965,
      "latitude": 43.706377,
      "longitude": -92.09459,
      "city": "Preston",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55967,
      "latitude": 43.784072,
      "longitude": -92.483567,
      "city": "Racine",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55968,
      "latitude": 44.340826,
      "longitude": -92.282467,
      "city": "Reads Landing",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55969,
      "latitude": 44.099266,
      "longitude": -91.819882,
      "city": "Rollingstone",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55970,
      "latitude": 43.669317,
      "longitude": -92.830439,
      "city": "Rose Creek",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55971,
      "latitude": 43.809873,
      "longitude": -91.793376,
      "city": "Rushford",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55972,
      "latitude": 43.960809,
      "longitude": -91.922346,
      "city": "Saint Charles",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55973,
      "latitude": 43.804657,
      "longitude": -92.802913,
      "city": "Sargeant",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55974,
      "latitude": 43.571029,
      "longitude": -91.635822,
      "city": "Spring Grove",
      "state": "MN",
      "county": "Houston"
  },
  {
      "zipCode": 55975,
      "latitude": 43.689711,
      "longitude": -92.334603,
      "city": "Spring Valley",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55976,
      "latitude": 43.884346,
      "longitude": -92.503744,
      "city": "Stewartville",
      "state": "MN",
      "county": "Olmsted"
  },
  {
      "zipCode": 55977,
      "latitude": 43.557786,
      "longitude": -92.660555,
      "city": "Taopi",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55979,
      "latitude": 43.92098,
      "longitude": -91.969704,
      "city": "Utica",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55981,
      "latitude": 44.3579,
      "longitude": -92.087925,
      "city": "Wabasha",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55982,
      "latitude": 43.694738,
      "longitude": -92.79693,
      "city": "Waltham",
      "state": "MN",
      "county": "Mower"
  },
  {
      "zipCode": 55983,
      "latitude": 44.272099,
      "longitude": -92.812034,
      "city": "Wanamingo",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 55985,
      "latitude": 44.148244,
      "longitude": -92.903452,
      "city": "West Concord",
      "state": "MN",
      "county": "Dodge"
  },
  {
      "zipCode": 55987,
      "latitude": 44.029975,
      "longitude": -91.700889,
      "city": "Winona",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55988,
      "latitude": 44.025217,
      "longitude": -91.770781,
      "city": "Stockton",
      "state": "MN",
      "county": "Winona"
  },
  {
      "zipCode": 55990,
      "latitude": 43.704566,
      "longitude": -92.237117,
      "city": "Wykoff",
      "state": "MN",
      "county": "Fillmore"
  },
  {
      "zipCode": 55991,
      "latitude": 44.242705,
      "longitude": -92.425643,
      "city": "Zumbro Falls",
      "state": "MN",
      "county": "Wabasha"
  },
  {
      "zipCode": 55992,
      "latitude": 44.287597,
      "longitude": -92.693235,
      "city": "Zumbrota",
      "state": "MN",
      "county": "Goodhue"
  },
  {
      "zipCode": 56001,
      "latitude": 44.061451,
      "longitude": -94.003112,
      "city": "Mankato",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56002,
      "latitude": 44.056047,
      "longitude": -94.069828,
      "city": "Mankato",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56003,
      "latitude": 44.217193,
      "longitude": -94.094192,
      "city": "Mankato",
      "state": "MN",
      "county": "Nicollet"
  },
  {
      "zipCode": 56006,
      "latitude": 44.056047,
      "longitude": -94.069828,
      "city": "Mankato",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56007,
      "latitude": 43.686288,
      "longitude": -93.389838,
      "city": "Albert Lea",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56009,
      "latitude": 43.733525,
      "longitude": -93.532143,
      "city": "Alden",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56010,
      "latitude": 43.886884,
      "longitude": -94.15839,
      "city": "Amboy",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56011,
      "latitude": 44.608912,
      "longitude": -93.757888,
      "city": "Belle Plaine",
      "state": "MN",
      "county": "Scott"
  },
  {
      "zipCode": 56013,
      "latitude": 43.6503,
      "longitude": -93.977974,
      "city": "Blue Earth",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56014,
      "latitude": 43.669878,
      "longitude": -93.826733,
      "city": "Bricelyn",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56016,
      "latitude": 43.761669,
      "longitude": -93.326712,
      "city": "Clarks Grove",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56017,
      "latitude": 44.298188,
      "longitude": -93.817622,
      "city": "Cleveland",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56019,
      "latitude": 44.111351,
      "longitude": -94.907833,
      "city": "Comfrey",
      "state": "MN",
      "county": "Brown"
  },
  {
      "zipCode": 56020,
      "latitude": 43.62105,
      "longitude": -93.548214,
      "city": "Conger",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56021,
      "latitude": 44.265888,
      "longitude": -94.272911,
      "city": "Courtland",
      "state": "MN",
      "county": "Nicollet"
  },
  {
      "zipCode": 56022,
      "latitude": 44.05483,
      "longitude": -94.790185,
      "city": "Darfur",
      "state": "MN",
      "county": "Watonwan"
  },
  {
      "zipCode": 56023,
      "latitude": 43.768062,
      "longitude": -94.007655,
      "city": "Delavan",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56024,
      "latitude": 44.163231,
      "longitude": -93.882127,
      "city": "Eagle Lake",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56025,
      "latitude": 43.760823,
      "longitude": -93.897589,
      "city": "Easton",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56026,
      "latitude": 43.927861,
      "longitude": -93.286367,
      "city": "Ellendale",
      "state": "MN",
      "county": "Steele"
  },
  {
      "zipCode": 56027,
      "latitude": 43.575712,
      "longitude": -93.96984,
      "city": "Elmore",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56028,
      "latitude": 44.199317,
      "longitude": -93.68198,
      "city": "Elysian",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56029,
      "latitude": 43.652544,
      "longitude": -93.403429,
      "city": "Emmons",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56030,
      "latitude": 44.325833,
      "longitude": -94.605226,
      "city": "Essig",
      "state": "MN",
      "county": "Brown"
  },
  {
      "zipCode": 56031,
      "latitude": 43.674049,
      "longitude": -94.51078,
      "city": "Fairmont",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56032,
      "latitude": 43.783807,
      "longitude": -93.525396,
      "city": "Freeborn",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56033,
      "latitude": 43.564879,
      "longitude": -93.908248,
      "city": "Frost",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56034,
      "latitude": 44.052118,
      "longitude": -94.165036,
      "city": "Garden City",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56035,
      "latitude": 43.673904,
      "longitude": -93.348869,
      "city": "Geneva",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56036,
      "latitude": 43.664991,
      "longitude": -93.36173,
      "city": "Glenville",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56037,
      "latitude": 44.029182,
      "longitude": -94.112395,
      "city": "Good Thunder",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56039,
      "latitude": 43.659429,
      "longitude": -94.440978,
      "city": "Granada",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56041,
      "latitude": 44.133457,
      "longitude": -94.499485,
      "city": "Hanska",
      "state": "MN",
      "county": "Brown"
  },
  {
      "zipCode": 56042,
      "latitude": 43.803384,
      "longitude": -93.485456,
      "city": "Hartland",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56043,
      "latitude": 43.646968,
      "longitude": -93.244932,
      "city": "Hayward",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56044,
      "latitude": 44.564912,
      "longitude": -93.962668,
      "city": "Henderson",
      "state": "MN",
      "county": "Sibley"
  },
  {
      "zipCode": 56045,
      "latitude": 43.759484,
      "longitude": -93.204246,
      "city": "Hollandale",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56046,
      "latitude": 43.955103,
      "longitude": -93.274017,
      "city": "Hope",
      "state": "MN",
      "county": "Steele"
  },
  {
      "zipCode": 56047,
      "latitude": 43.738397,
      "longitude": -94.228897,
      "city": "Huntley",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56048,
      "latitude": 44.051012,
      "longitude": -93.58735,
      "city": "Janesville",
      "state": "MN",
      "county": "Waseca"
  },
  {
      "zipCode": 56050,
      "latitude": 44.273281,
      "longitude": -93.931119,
      "city": "Kasota",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56051,
      "latitude": 43.550446,
      "longitude": -93.708504,
      "city": "Kiester",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56052,
      "latitude": 44.313417,
      "longitude": -93.574653,
      "city": "Kilkenny",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56054,
      "latitude": 44.361224,
      "longitude": -94.293887,
      "city": "Lafayette",
      "state": "MN",
      "county": "Nicollet"
  },
  {
      "zipCode": 56055,
      "latitude": 44.147701,
      "longitude": -94.212574,
      "city": "Lake Crystal",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56056,
      "latitude": 43.978335,
      "longitude": -94.614361,
      "city": "La Salle",
      "state": "MN",
      "county": "Watonwan"
  },
  {
      "zipCode": 56057,
      "latitude": 44.360047,
      "longitude": -93.781405,
      "city": "Le Center",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56058,
      "latitude": 44.390864,
      "longitude": -93.903348,
      "city": "Le Sueur",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56060,
      "latitude": 43.923423,
      "longitude": -94.434135,
      "city": "Lewisville",
      "state": "MN",
      "county": "Watonwan"
  },
  {
      "zipCode": 56062,
      "latitude": 44.050715,
      "longitude": -94.41548,
      "city": "Madelia",
      "state": "MN",
      "county": "Watonwan"
  },
  {
      "zipCode": 56063,
      "latitude": 44.08391,
      "longitude": -93.862052,
      "city": "Madison Lake",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56064,
      "latitude": 43.763839,
      "longitude": -93.468959,
      "city": "Manchester",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56065,
      "latitude": 43.925112,
      "longitude": -93.952056,
      "city": "Mapleton",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56068,
      "latitude": 43.811561,
      "longitude": -93.817817,
      "city": "Minnesota Lake",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56069,
      "latitude": 44.349609,
      "longitude": -93.580277,
      "city": "Montgomery",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56071,
      "latitude": 44.536713,
      "longitude": -93.55598,
      "city": "New Prague",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56072,
      "latitude": 43.981294,
      "longitude": -93.561426,
      "city": "New Richland",
      "state": "MN",
      "county": "Waseca"
  },
  {
      "zipCode": 56073,
      "latitude": 44.259924,
      "longitude": -94.511407,
      "city": "New Ulm",
      "state": "MN",
      "county": "Brown"
  },
  {
      "zipCode": 56074,
      "latitude": 44.273214,
      "longitude": -94.188233,
      "city": "Nicollet",
      "state": "MN",
      "county": "Nicollet"
  },
  {
      "zipCode": 56075,
      "latitude": 43.735278,
      "longitude": -94.435705,
      "city": "Northrop",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56076,
      "latitude": 43.673904,
      "longitude": -93.348869,
      "city": "Oakland",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56078,
      "latitude": 44.007674,
      "longitude": -93.783274,
      "city": "Pemberton",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56080,
      "latitude": 44.081669,
      "longitude": -93.857123,
      "city": "Saint Clair",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56081,
      "latitude": 43.982851,
      "longitude": -94.604116,
      "city": "Saint James",
      "state": "MN",
      "county": "Watonwan"
  },
  {
      "zipCode": 56082,
      "latitude": 44.337793,
      "longitude": -94.070153,
      "city": "Saint Peter",
      "state": "MN",
      "county": "Nicollet"
  },
  {
      "zipCode": 56083,
      "latitude": 44.282403,
      "longitude": -95.167551,
      "city": "Sanborn",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56084,
      "latitude": 44.302893,
      "longitude": -94.738827,
      "city": "Searles",
      "state": "MN",
      "county": "Brown"
  },
  {
      "zipCode": 56085,
      "latitude": 44.317309,
      "longitude": -94.777163,
      "city": "Sleepy Eye",
      "state": "MN",
      "county": "Brown"
  },
  {
      "zipCode": 56087,
      "latitude": 44.253427,
      "longitude": -94.903534,
      "city": "Springfield",
      "state": "MN",
      "county": "Brown"
  },
  {
      "zipCode": 56088,
      "latitude": 43.789856,
      "longitude": -94.430809,
      "city": "Truman",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56089,
      "latitude": 43.559269,
      "longitude": -93.420578,
      "city": "Twin Lakes",
      "state": "MN",
      "county": "Freeborn"
  },
  {
      "zipCode": 56090,
      "latitude": 43.925892,
      "longitude": -94.233683,
      "city": "Vernon Center",
      "state": "MN",
      "county": "Blue Earth"
  },
  {
      "zipCode": 56091,
      "latitude": 43.907326,
      "longitude": -93.682513,
      "city": "Waldorf",
      "state": "MN",
      "county": "Waseca"
  },
  {
      "zipCode": 56093,
      "latitude": 44.065547,
      "longitude": -93.550495,
      "city": "Waseca",
      "state": "MN",
      "county": "Waseca"
  },
  {
      "zipCode": 56096,
      "latitude": 44.324235,
      "longitude": -93.569726,
      "city": "Waterville",
      "state": "MN",
      "county": "Le Sueur"
  },
  {
      "zipCode": 56097,
      "latitude": 43.702814,
      "longitude": -93.912041,
      "city": "Wells",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56098,
      "latitude": 43.673848,
      "longitude": -93.948241,
      "city": "Winnebago",
      "state": "MN",
      "county": "Faribault"
  },
  {
      "zipCode": 56101,
      "latitude": 43.900192,
      "longitude": -95.046828,
      "city": "Windom",
      "state": "MN",
      "county": "Cottonwood"
  },
  {
      "zipCode": 56110,
      "latitude": 43.620754,
      "longitude": -95.953225,
      "city": "Adrian",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56111,
      "latitude": 43.674174,
      "longitude": -95.154494,
      "city": "Alpha",
      "state": "MN",
      "county": "Jackson"
  },
  {
      "zipCode": 56113,
      "latitude": 44.382668,
      "longitude": -96.1842,
      "city": "Arco",
      "state": "MN",
      "county": "Lincoln"
  },
  {
      "zipCode": 56114,
      "latitude": 43.960084,
      "longitude": -95.60317,
      "city": "Avoca",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56115,
      "latitude": 44.261464,
      "longitude": -95.889647,
      "city": "Balaton",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56116,
      "latitude": 43.612344,
      "longitude": -96.364663,
      "city": "Beaver Creek",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56117,
      "latitude": 43.540643,
      "longitude": -95.687951,
      "city": "Bigelow",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56118,
      "latitude": 43.895565,
      "longitude": -95.048996,
      "city": "Bingham Lake",
      "state": "MN",
      "county": "Cottonwood"
  },
  {
      "zipCode": 56119,
      "latitude": 43.732427,
      "longitude": -95.512519,
      "city": "Brewster",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56120,
      "latitude": 44.002201,
      "longitude": -94.814932,
      "city": "Butterfield",
      "state": "MN",
      "county": "Watonwan"
  },
  {
      "zipCode": 56121,
      "latitude": 43.584995,
      "longitude": -94.606517,
      "city": "Ceylon",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56122,
      "latitude": 43.92199,
      "longitude": -95.81997,
      "city": "Chandler",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56123,
      "latitude": 44.048028,
      "longitude": -95.704397,
      "city": "Currie",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56125,
      "latitude": 44.053323,
      "longitude": -95.549899,
      "city": "Dovray",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56127,
      "latitude": 43.674184,
      "longitude": -94.550932,
      "city": "Dunnell",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56128,
      "latitude": 43.930332,
      "longitude": -96.149676,
      "city": "Edgerton",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56129,
      "latitude": 43.536342,
      "longitude": -95.983538,
      "city": "Ellsworth",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56131,
      "latitude": 43.905962,
      "longitude": -95.593288,
      "city": "Fulda",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56132,
      "latitude": 44.305584,
      "longitude": -95.86177,
      "city": "Garvin",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56134,
      "latitude": 43.810378,
      "longitude": -96.218283,
      "city": "Hardwick",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56136,
      "latitude": 44.460984,
      "longitude": -96.33771,
      "city": "Hendricks",
      "state": "MN",
      "county": "Lincoln"
  },
  {
      "zipCode": 56137,
      "latitude": 43.795977,
      "longitude": -95.320571,
      "city": "Heron Lake",
      "state": "MN",
      "county": "Jackson"
  },
  {
      "zipCode": 56138,
      "latitude": 43.525185,
      "longitude": -96.358365,
      "city": "Hills",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56139,
      "latitude": 44.09239,
      "longitude": -96.188124,
      "city": "Holland",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56140,
      "latitude": 43.895464,
      "longitude": -96.364032,
      "city": "Ihlen",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56141,
      "latitude": 43.891474,
      "longitude": -95.784003,
      "city": "Iona",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56142,
      "latitude": 44.460411,
      "longitude": -96.246374,
      "city": "Ivanhoe",
      "state": "MN",
      "county": "Lincoln"
  },
  {
      "zipCode": 56143,
      "latitude": 43.650174,
      "longitude": -95.021954,
      "city": "Jackson",
      "state": "MN",
      "county": "Jackson"
  },
  {
      "zipCode": 56144,
      "latitude": 43.879857,
      "longitude": -96.342955,
      "city": "Jasper",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56145,
      "latitude": 44.055769,
      "longitude": -95.195219,
      "city": "Jeffers",
      "state": "MN",
      "county": "Cottonwood"
  },
  {
      "zipCode": 56146,
      "latitude": 43.674883,
      "longitude": -96.252794,
      "city": "Kanaranzi",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56147,
      "latitude": 43.674883,
      "longitude": -96.252794,
      "city": "Kenneth",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56149,
      "latitude": 44.294793,
      "longitude": -96.270936,
      "city": "Lake Benton",
      "state": "MN",
      "county": "Lincoln"
  },
  {
      "zipCode": 56150,
      "latitude": 43.678125,
      "longitude": -95.171548,
      "city": "Lakefield",
      "state": "MN",
      "county": "Jackson"
  },
  {
      "zipCode": 56151,
      "latitude": 44.00692,
      "longitude": -95.825082,
      "city": "Lake Wilson",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56152,
      "latitude": 44.282497,
      "longitude": -95.269338,
      "city": "Lamberton",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56153,
      "latitude": 43.840423,
      "longitude": -96.012811,
      "city": "Leota",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56155,
      "latitude": 43.682995,
      "longitude": -95.942501,
      "city": "Lismore",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56156,
      "latitude": 43.698546,
      "longitude": -96.163242,
      "city": "Luverne",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56157,
      "latitude": 44.399913,
      "longitude": -95.937984,
      "city": "Lynd",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56158,
      "latitude": 43.644047,
      "longitude": -96.07695,
      "city": "Magnolia",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56159,
      "latitude": 43.939276,
      "longitude": -94.924319,
      "city": "Mountain Lake",
      "state": "MN",
      "county": "Cottonwood"
  },
  {
      "zipCode": 56160,
      "latitude": 43.867848,
      "longitude": -94.742716,
      "city": "Odin",
      "state": "MN",
      "county": "Watonwan"
  },
  {
      "zipCode": 56161,
      "latitude": 43.738986,
      "longitude": -95.316815,
      "city": "Okabena",
      "state": "MN",
      "county": "Jackson"
  },
  {
      "zipCode": 56162,
      "latitude": 43.826503,
      "longitude": -94.663493,
      "city": "Ormsby",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56164,
      "latitude": 43.989267,
      "longitude": -96.265153,
      "city": "Pipestone",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56165,
      "latitude": 43.732304,
      "longitude": -95.703514,
      "city": "Reading",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56166,
      "latitude": 44.23913,
      "longitude": -95.355744,
      "city": "Revere",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56167,
      "latitude": 43.631841,
      "longitude": -95.640477,
      "city": "Round Lake",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56168,
      "latitude": 43.623088,
      "longitude": -95.803869,
      "city": "Rushmore",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56169,
      "latitude": 44.335085,
      "longitude": -95.97804,
      "city": "Russell",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56170,
      "latitude": 44.153916,
      "longitude": -96.275913,
      "city": "Ruthton",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56171,
      "latitude": 43.667369,
      "longitude": -94.759431,
      "city": "Sherburn",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56172,
      "latitude": 43.993479,
      "longitude": -95.763493,
      "city": "Slayton",
      "state": "MN",
      "county": "Murray"
  },
  {
      "zipCode": 56173,
      "latitude": 43.674883,
      "longitude": -96.252794,
      "city": "Steen",
      "state": "MN",
      "county": "Rock"
  },
  {
      "zipCode": 56174,
      "latitude": 44.039624,
      "longitude": -95.319366,
      "city": "Storden",
      "state": "MN",
      "county": "Cottonwood"
  },
  {
      "zipCode": 56175,
      "latitude": 44.290092,
      "longitude": -95.773754,
      "city": "Tracy",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56176,
      "latitude": 43.783229,
      "longitude": -94.713525,
      "city": "Trimont",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56177,
      "latitude": 43.889477,
      "longitude": -96.260066,
      "city": "Trosky",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56178,
      "latitude": 44.275371,
      "longitude": -96.141967,
      "city": "Tyler",
      "state": "MN",
      "county": "Lincoln"
  },
  {
      "zipCode": 56180,
      "latitude": 44.283007,
      "longitude": -95.48189,
      "city": "Walnut Grove",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56181,
      "latitude": 43.66727,
      "longitude": -94.673433,
      "city": "Welcome",
      "state": "MN",
      "county": "Martin"
  },
  {
      "zipCode": 56183,
      "latitude": 44.007813,
      "longitude": -95.196562,
      "city": "Westbrook",
      "state": "MN",
      "county": "Cottonwood"
  },
  {
      "zipCode": 56185,
      "latitude": 43.790084,
      "longitude": -95.826712,
      "city": "Wilmont",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56186,
      "latitude": 44.009283,
      "longitude": -96.09925,
      "city": "Woodstock",
      "state": "MN",
      "county": "Pipestone"
  },
  {
      "zipCode": 56187,
      "latitude": 43.645207,
      "longitude": -95.735375,
      "city": "Worthington",
      "state": "MN",
      "county": "Nobles"
  },
  {
      "zipCode": 56201,
      "latitude": 45.147104,
      "longitude": -94.977723,
      "city": "Willmar",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56207,
      "latitude": 45.585961,
      "longitude": -96.000761,
      "city": "Alberta",
      "state": "MN",
      "county": "Stevens"
  },
  {
      "zipCode": 56208,
      "latitude": 45.282008,
      "longitude": -95.95757,
      "city": "Appleton",
      "state": "MN",
      "county": "Swift"
  },
  {
      "zipCode": 56209,
      "latitude": 45.109205,
      "longitude": -94.968572,
      "city": "Atwater",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56210,
      "latitude": 45.559291,
      "longitude": -96.558886,
      "city": "Barry",
      "state": "MN",
      "county": "Big Stone"
  },
  {
      "zipCode": 56211,
      "latitude": 45.381402,
      "longitude": -96.469532,
      "city": "Beardsley",
      "state": "MN",
      "county": "Big Stone"
  },
  {
      "zipCode": 56212,
      "latitude": 45.053253,
      "longitude": -96.095554,
      "city": "Bellingham",
      "state": "MN",
      "county": "Lac Qui Parle"
  },
  {
      "zipCode": 56214,
      "latitude": 44.553001,
      "longitude": -95.324839,
      "city": "Belview",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56215,
      "latitude": 45.281669,
      "longitude": -95.672102,
      "city": "Benson",
      "state": "MN",
      "county": "Swift"
  },
  {
      "zipCode": 56216,
      "latitude": 44.950076,
      "longitude": -95.058849,
      "city": "Blomkest",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56218,
      "latitude": 45.053253,
      "longitude": -96.095554,
      "city": "Boyd",
      "state": "MN",
      "county": "Lac Qui Parle"
  },
  {
      "zipCode": 56219,
      "latitude": 45.59432,
      "longitude": -96.834959,
      "city": "Browns Valley",
      "state": "MN",
      "county": "Traverse"
  },
  {
      "zipCode": 56220,
      "latitude": 44.775235,
      "longitude": -95.916433,
      "city": "Canby",
      "state": "MN",
      "county": "Yellow Medicine"
  },
  {
      "zipCode": 56221,
      "latitude": 45.573876,
      "longitude": -96.172979,
      "city": "Chokio",
      "state": "MN",
      "county": "Stevens"
  },
  {
      "zipCode": 56222,
      "latitude": 44.992549,
      "longitude": -95.36042,
      "city": "Clara City",
      "state": "MN",
      "county": "Chippewa"
  },
  {
      "zipCode": 56223,
      "latitude": 44.790853,
      "longitude": -95.806933,
      "city": "Clarkfield",
      "state": "MN",
      "county": "Yellow Medicine"
  },
  {
      "zipCode": 56224,
      "latitude": 44.418189,
      "longitude": -95.261177,
      "city": "Clements",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56225,
      "latitude": 45.461092,
      "longitude": -96.431538,
      "city": "Clinton",
      "state": "MN",
      "county": "Big Stone"
  },
  {
      "zipCode": 56226,
      "latitude": 45.305865,
      "longitude": -95.838919,
      "city": "Clontarf",
      "state": "MN",
      "county": "Swift"
  },
  {
      "zipCode": 56227,
      "latitude": 45.381402,
      "longitude": -96.469532,
      "city": "Correll",
      "state": "MN",
      "county": "Big Stone"
  },
  {
      "zipCode": 56228,
      "latitude": 45.022087,
      "longitude": -94.660884,
      "city": "Cosmos",
      "state": "MN",
      "county": "Meeker"
  },
  {
      "zipCode": 56229,
      "latitude": 44.56956,
      "longitude": -95.744921,
      "city": "Cottonwood",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56230,
      "latitude": 44.76007,
      "longitude": -95.09754,
      "city": "Danube",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 56231,
      "latitude": 45.281751,
      "longitude": -95.721936,
      "city": "Danvers",
      "state": "MN",
      "county": "Swift"
  },
  {
      "zipCode": 56232,
      "latitude": 44.929289,
      "longitude": -96.056499,
      "city": "Dawson",
      "state": "MN",
      "county": "Lac Qui Parle"
  },
  {
      "zipCode": 56235,
      "latitude": 45.690959,
      "longitude": -96.010121,
      "city": "Donnelly",
      "state": "MN",
      "county": "Stevens"
  },
  {
      "zipCode": 56236,
      "latitude": 45.718556,
      "longitude": -96.422981,
      "city": "Dumont",
      "state": "MN",
      "county": "Traverse"
  },
  {
      "zipCode": 56237,
      "latitude": 44.617739,
      "longitude": -95.411535,
      "city": "Echo",
      "state": "MN",
      "county": "Yellow Medicine"
  },
  {
      "zipCode": 56239,
      "latitude": 44.485432,
      "longitude": -95.907811,
      "city": "Ghent",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56240,
      "latitude": 45.521519,
      "longitude": -96.440429,
      "city": "Graceville",
      "state": "MN",
      "county": "Big Stone"
  },
  {
      "zipCode": 56241,
      "latitude": 44.780794,
      "longitude": -95.670577,
      "city": "Granite Falls",
      "state": "MN",
      "county": "Yellow Medicine"
  },
  {
      "zipCode": 56243,
      "latitude": 45.146898,
      "longitude": -94.674112,
      "city": "Grove City",
      "state": "MN",
      "county": "Meeker"
  },
  {
      "zipCode": 56244,
      "latitude": 45.497402,
      "longitude": -95.79426,
      "city": "Hancock",
      "state": "MN",
      "county": "Stevens"
  },
  {
      "zipCode": 56245,
      "latitude": 44.692039,
      "longitude": -95.62058,
      "city": "Hanley Falls",
      "state": "MN",
      "county": "Yellow Medicine"
  },
  {
      "zipCode": 56246,
      "latitude": 45.33763,
      "longitude": -94.85611,
      "city": "Hawick",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56248,
      "latitude": 45.838396,
      "longitude": -96.141993,
      "city": "Herman",
      "state": "MN",
      "county": "Grant"
  },
  {
      "zipCode": 56249,
      "latitude": 45.29184,
      "longitude": -95.624619,
      "city": "Holloway",
      "state": "MN",
      "county": "Swift"
  },
  {
      "zipCode": 56251,
      "latitude": 45.142577,
      "longitude": -94.918264,
      "city": "Kandiyohi",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56252,
      "latitude": 45.231737,
      "longitude": -95.317927,
      "city": "Kerkhoven",
      "state": "MN",
      "county": "Swift"
  },
  {
      "zipCode": 56253,
      "latitude": 45.011036,
      "longitude": -94.900783,
      "city": "Lake Lillian",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56255,
      "latitude": 44.472194,
      "longitude": -95.409783,
      "city": "Lucan",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56256,
      "latitude": 45.038164,
      "longitude": -96.311044,
      "city": "Madison",
      "state": "MN",
      "county": "Lac Qui Parle"
  },
  {
      "zipCode": 56257,
      "latitude": 45.067384,
      "longitude": -96.440184,
      "city": "Marietta",
      "state": "MN",
      "county": "Lac Qui Parle"
  },
  {
      "zipCode": 56258,
      "latitude": 44.460429,
      "longitude": -95.785872,
      "city": "Marshall",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56260,
      "latitude": 44.999613,
      "longitude": -95.573816,
      "city": "Maynard",
      "state": "MN",
      "county": "Chippewa"
  },
  {
      "zipCode": 56262,
      "latitude": 45.108407,
      "longitude": -95.817699,
      "city": "Milan",
      "state": "MN",
      "county": "Chippewa"
  },
  {
      "zipCode": 56263,
      "latitude": 44.417632,
      "longitude": -95.531336,
      "city": "Milroy",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56264,
      "latitude": 44.52307,
      "longitude": -95.954362,
      "city": "Minneota",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56265,
      "latitude": 45.014054,
      "longitude": -95.601718,
      "city": "Montevideo",
      "state": "MN",
      "county": "Chippewa"
  },
  {
      "zipCode": 56266,
      "latitude": 44.404589,
      "longitude": -94.976823,
      "city": "Morgan",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56267,
      "latitude": 45.595739,
      "longitude": -95.923233,
      "city": "Morris",
      "state": "MN",
      "county": "Stevens"
  },
  {
      "zipCode": 56270,
      "latitude": 44.586097,
      "longitude": -94.97066,
      "city": "Morton",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 56271,
      "latitude": 45.281997,
      "longitude": -95.512817,
      "city": "Murdock",
      "state": "MN",
      "county": "Swift"
  },
  {
      "zipCode": 56273,
      "latitude": 45.167597,
      "longitude": -95.049378,
      "city": "New London",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56274,
      "latitude": 45.934055,
      "longitude": -96.012359,
      "city": "Norcross",
      "state": "MN",
      "county": "Grant"
  },
  {
      "zipCode": 56276,
      "latitude": 45.381402,
      "longitude": -96.469532,
      "city": "Odessa",
      "state": "MN",
      "county": "Big Stone"
  },
  {
      "zipCode": 56277,
      "latitude": 44.760343,
      "longitude": -95.032641,
      "city": "Olivia",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 56278,
      "latitude": 45.376691,
      "longitude": -96.516214,
      "city": "Ortonville",
      "state": "MN",
      "county": "Big Stone"
  },
  {
      "zipCode": 56279,
      "latitude": 45.219496,
      "longitude": -95.141233,
      "city": "Pennock",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56280,
      "latitude": 44.674215,
      "longitude": -96.11204,
      "city": "Porter",
      "state": "MN",
      "county": "Yellow Medicine"
  },
  {
      "zipCode": 56281,
      "latitude": 45.029662,
      "longitude": -95.000152,
      "city": "Prinsburg",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56282,
      "latitude": 45.094173,
      "longitude": -95.111335,
      "city": "Raymond",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56283,
      "latitude": 44.521759,
      "longitude": -95.200255,
      "city": "Redwood Falls",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56284,
      "latitude": 44.760675,
      "longitude": -95.240065,
      "city": "Renville",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 56285,
      "latitude": 44.779761,
      "longitude": -95.370482,
      "city": "Sacred Heart",
      "state": "MN",
      "county": "Renville"
  },
  {
      "zipCode": 56287,
      "latitude": 44.461267,
      "longitude": -95.328167,
      "city": "Seaforth",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56288,
      "latitude": 45.164862,
      "longitude": -95.020124,
      "city": "Spicer",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56289,
      "latitude": 45.258882,
      "longitude": -95.141945,
      "city": "Sunburg",
      "state": "MN",
      "county": "Kandiyohi"
  },
  {
      "zipCode": 56291,
      "latitude": 44.580923,
      "longitude": -95.883387,
      "city": "Taunton",
      "state": "MN",
      "county": "Lyon"
  },
  {
      "zipCode": 56292,
      "latitude": 44.492318,
      "longitude": -95.447604,
      "city": "Vesta",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56293,
      "latitude": 44.420734,
      "longitude": -95.248847,
      "city": "Wabasso",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56294,
      "latitude": 44.32978,
      "longitude": -95.211785,
      "city": "Wanda",
      "state": "MN",
      "county": "Redwood"
  },
  {
      "zipCode": 56295,
      "latitude": 45.019892,
      "longitude": -95.630814,
      "city": "Watson",
      "state": "MN",
      "county": "Chippewa"
  },
  {
      "zipCode": 56296,
      "latitude": 45.703481,
      "longitude": -96.633211,
      "city": "Wheaton",
      "state": "MN",
      "county": "Traverse"
  },
  {
      "zipCode": 56297,
      "latitude": 44.667478,
      "longitude": -95.576938,
      "city": "Wood Lake",
      "state": "MN",
      "county": "Yellow Medicine"
  },
  {
      "zipCode": 56301,
      "latitude": 45.519196,
      "longitude": -94.330619,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56302,
      "latitude": 45.49343,
      "longitude": -94.643922,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56303,
      "latitude": 45.627994,
      "longitude": -94.223023,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56304,
      "latitude": 45.544864,
      "longitude": -94.440969,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56307,
      "latitude": 45.614724,
      "longitude": -94.494229,
      "city": "Albany",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56308,
      "latitude": 45.902017,
      "longitude": -95.420589,
      "city": "Alexandria",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56309,
      "latitude": 46.09171,
      "longitude": -95.816743,
      "city": "Ashby",
      "state": "MN",
      "county": "Grant"
  },
  {
      "zipCode": 56310,
      "latitude": 45.599386,
      "longitude": -94.436477,
      "city": "Avon",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56311,
      "latitude": 45.902775,
      "longitude": -95.85025,
      "city": "Barrett",
      "state": "MN",
      "county": "Grant"
  },
  {
      "zipCode": 56312,
      "latitude": 45.509715,
      "longitude": -94.963049,
      "city": "Belgrade",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56313,
      "latitude": 45.784462,
      "longitude": -93.552152,
      "city": "Bock",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 56314,
      "latitude": 45.868809,
      "longitude": -94.422896,
      "city": "Bowlus",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56315,
      "latitude": 45.966435,
      "longitude": -95.516619,
      "city": "Brandon",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56316,
      "latitude": 45.513441,
      "longitude": -95.056661,
      "city": "Brooten",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56317,
      "latitude": 46.061307,
      "longitude": -94.208731,
      "city": "Buckman",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56318,
      "latitude": 45.86575,
      "longitude": -94.685781,
      "city": "Burtrum",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56319,
      "latitude": 45.991208,
      "longitude": -95.371207,
      "city": "Carlos",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56320,
      "latitude": 45.470708,
      "longitude": -94.661654,
      "city": "Cold Spring",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56321,
      "latitude": 45.578278,
      "longitude": -94.419941,
      "city": "Collegeville",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56323,
      "latitude": 45.641425,
      "longitude": -95.709642,
      "city": "Cyrus",
      "state": "MN",
      "county": "Pope"
  },
  {
      "zipCode": 56324,
      "latitude": 46.172907,
      "longitude": -95.918542,
      "city": "Dalton",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56325,
      "latitude": 45.563556,
      "longitude": -94.946428,
      "city": "Elrosa",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56326,
      "latitude": 45.984659,
      "longitude": -95.670545,
      "city": "Evansville",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56327,
      "latitude": 45.728746,
      "longitude": -95.623944,
      "city": "Farwell",
      "state": "MN",
      "county": "Pope"
  },
  {
      "zipCode": 56328,
      "latitude": 45.953341,
      "longitude": -94.546845,
      "city": "Flensburg",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56329,
      "latitude": 45.691931,
      "longitude": -93.914797,
      "city": "Foley",
      "state": "MN",
      "county": "Benton"
  },
  {
      "zipCode": 56330,
      "latitude": 45.735675,
      "longitude": -93.647135,
      "city": "Foreston",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 56331,
      "latitude": 45.643851,
      "longitude": -94.660815,
      "city": "Freeport",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56332,
      "latitude": 45.984672,
      "longitude": -95.506622,
      "city": "Garfield",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56333,
      "latitude": 45.691714,
      "longitude": -94.05629,
      "city": "Gilman",
      "state": "MN",
      "county": "Benton"
  },
  {
      "zipCode": 56334,
      "latitude": 45.589131,
      "longitude": -95.357347,
      "city": "Glenwood",
      "state": "MN",
      "county": "Pope"
  },
  {
      "zipCode": 56335,
      "latitude": 45.597122,
      "longitude": -94.851494,
      "city": "Greenwald",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56336,
      "latitude": 45.827791,
      "longitude": -94.77757,
      "city": "Grey Eagle",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56338,
      "latitude": 45.990074,
      "longitude": -93.888513,
      "city": "Hillman",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56339,
      "latitude": 45.836077,
      "longitude": -95.791353,
      "city": "Hoffman",
      "state": "MN",
      "county": "Grant"
  },
  {
      "zipCode": 56340,
      "latitude": 45.623632,
      "longitude": -94.41914,
      "city": "Holdingford",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56341,
      "latitude": 45.830998,
      "longitude": -95.541618,
      "city": "Holmes City",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56342,
      "latitude": 45.973268,
      "longitude": -93.536504,
      "city": "Isle",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 56343,
      "latitude": 45.811895,
      "longitude": -95.665241,
      "city": "Kensington",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56344,
      "latitude": 46.061307,
      "longitude": -94.208731,
      "city": "Lastrup",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56345,
      "latitude": 45.980055,
      "longitude": -94.245867,
      "city": "Little Falls",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56347,
      "latitude": 45.904136,
      "longitude": -94.815114,
      "city": "Long Prairie",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56349,
      "latitude": 45.715846,
      "longitude": -95.540402,
      "city": "Lowry",
      "state": "MN",
      "county": "Pope"
  },
  {
      "zipCode": 56350,
      "latitude": 46.195728,
      "longitude": -93.377414,
      "city": "Mc Grath",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 56352,
      "latitude": 45.614071,
      "longitude": -94.634556,
      "city": "Melrose",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56353,
      "latitude": 45.90321,
      "longitude": -93.620355,
      "city": "Milaca",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 56354,
      "latitude": 46.06187,
      "longitude": -95.295146,
      "city": "Miltona",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56355,
      "latitude": 45.935551,
      "longitude": -95.23975,
      "city": "Nelson",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56356,
      "latitude": 45.62974,
      "longitude": -94.751937,
      "city": "New Munich",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56357,
      "latitude": 45.702225,
      "longitude": -93.816445,
      "city": "Oak Park",
      "state": "MN",
      "county": "Benton"
  },
  {
      "zipCode": 56358,
      "latitude": 45.833199,
      "longitude": -93.402621,
      "city": "Ogilvie",
      "state": "MN",
      "county": "Kanabec"
  },
  {
      "zipCode": 56359,
      "latitude": 45.943108,
      "longitude": -93.663152,
      "city": "Onamia",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 56360,
      "latitude": 45.871169,
      "longitude": -95.237474,
      "city": "Osakis",
      "state": "MN",
      "county": "Douglas"
  },
  {
      "zipCode": 56361,
      "latitude": 46.150334,
      "longitude": -95.350438,
      "city": "Parkers Prairie",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56362,
      "latitude": 45.506403,
      "longitude": -94.734316,
      "city": "Paynesville",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56363,
      "latitude": 45.697362,
      "longitude": -93.646503,
      "city": "Pease",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 56364,
      "latitude": 45.994539,
      "longitude": -94.123384,
      "city": "Pierz",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56367,
      "latitude": 45.745866,
      "longitude": -94.124878,
      "city": "Rice",
      "state": "MN",
      "county": "Benton"
  },
  {
      "zipCode": 56368,
      "latitude": 45.460536,
      "longitude": -94.536053,
      "city": "Richmond",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56369,
      "latitude": 45.468679,
      "longitude": -94.340582,
      "city": "Rockville",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56371,
      "latitude": 45.426781,
      "longitude": -94.633502,
      "city": "Roscoe",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56372,
      "latitude": 45.52886,
      "longitude": -94.593338,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56373,
      "latitude": 45.871308,
      "longitude": -94.161377,
      "city": "Royalton",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56374,
      "latitude": 45.614235,
      "longitude": -94.350962,
      "city": "Saint Joseph",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56375,
      "latitude": 45.587011,
      "longitude": -94.380968,
      "city": "Saint Stephen",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56376,
      "latitude": 45.489612,
      "longitude": -94.718248,
      "city": "Saint Martin",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56377,
      "latitude": 45.573808,
      "longitude": -94.355049,
      "city": "Sartell",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56378,
      "latitude": 45.638568,
      "longitude": -94.974275,
      "city": "Sauk Centre",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56379,
      "latitude": 45.654829,
      "longitude": -94.073533,
      "city": "Sauk Rapids",
      "state": "MN",
      "county": "Benton"
  },
  {
      "zipCode": 56381,
      "latitude": 45.572758,
      "longitude": -95.573436,
      "city": "Starbuck",
      "state": "MN",
      "county": "Pope"
  },
  {
      "zipCode": 56382,
      "latitude": 45.904382,
      "longitude": -94.540074,
      "city": "Swanville",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56384,
      "latitude": 45.804875,
      "longitude": -94.565187,
      "city": "Upsala",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56385,
      "latitude": 45.719299,
      "longitude": -95.225097,
      "city": "Villard",
      "state": "MN",
      "county": "Pope"
  },
  {
      "zipCode": 56386,
      "latitude": 45.989733,
      "longitude": -93.620235,
      "city": "Wahkon",
      "state": "MN",
      "county": "Mille Lacs"
  },
  {
      "zipCode": 56387,
      "latitude": 45.510622,
      "longitude": -94.667422,
      "city": "Waite Park",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56388,
      "latitude": 45.548852,
      "longitude": -94.219024,
      "city": "Waite Park",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56389,
      "latitude": 45.799542,
      "longitude": -95.08213,
      "city": "West Union",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56393,
      "latitude": 45.52886,
      "longitude": -94.593338,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56395,
      "latitude": 45.52886,
      "longitude": -94.593338,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56396,
      "latitude": 45.52886,
      "longitude": -94.593338,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56397,
      "latitude": 45.52886,
      "longitude": -94.593338,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56398,
      "latitude": 45.52886,
      "longitude": -94.593338,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56399,
      "latitude": 45.52886,
      "longitude": -94.593338,
      "city": "Saint Cloud",
      "state": "MN",
      "county": "Stearns"
  },
  {
      "zipCode": 56401,
      "latitude": 46.350195,
      "longitude": -94.099983,
      "city": "Brainerd",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56425,
      "latitude": 46.373474,
      "longitude": -94.196884,
      "city": "Baxter",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56430,
      "latitude": 46.862332,
      "longitude": -94.641872,
      "city": "Ah Gwah Ching",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56431,
      "latitude": 46.563605,
      "longitude": -93.430495,
      "city": "Aitkin",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 56433,
      "latitude": 46.987609,
      "longitude": -94.726405,
      "city": "Akeley",
      "state": "MN",
      "county": "Hubbard"
  },
  {
      "zipCode": 56434,
      "latitude": 46.379683,
      "longitude": -94.936381,
      "city": "Aldrich",
      "state": "MN",
      "county": "Wadena"
  },
  {
      "zipCode": 56435,
      "latitude": 46.803348,
      "longitude": -94.521914,
      "city": "Backus",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56436,
      "latitude": 47.108153,
      "longitude": -94.921064,
      "city": "Benedict",
      "state": "MN",
      "county": "Hubbard"
  },
  {
      "zipCode": 56437,
      "latitude": 46.268233,
      "longitude": -95.06077,
      "city": "Bertha",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56438,
      "latitude": 46.063955,
      "longitude": -94.867727,
      "city": "Browerville",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56440,
      "latitude": 46.128416,
      "longitude": -94.950401,
      "city": "Clarissa",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56441,
      "latitude": 46.537059,
      "longitude": -93.928197,
      "city": "Crosby",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56442,
      "latitude": 46.67734,
      "longitude": -94.112783,
      "city": "Crosslake",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56443,
      "latitude": 46.205759,
      "longitude": -94.561294,
      "city": "Cushing",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56444,
      "latitude": 46.429194,
      "longitude": -93.878493,
      "city": "Deerwood",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56446,
      "latitude": 46.149778,
      "longitude": -94.99949,
      "city": "Eagle Bend",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56447,
      "latitude": 46.697119,
      "longitude": -94.117266,
      "city": "Emily",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56448,
      "latitude": 46.727867,
      "longitude": -94.040303,
      "city": "Fifty Lakes",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56449,
      "latitude": 46.200293,
      "longitude": -94.245167,
      "city": "Fort Ripley",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56450,
      "latitude": 46.312962,
      "longitude": -93.866016,
      "city": "Garrison",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56452,
      "latitude": 46.939139,
      "longitude": -94.450641,
      "city": "Hackensack",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56453,
      "latitude": 46.330157,
      "longitude": -94.945126,
      "city": "Hewitt",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56455,
      "latitude": 46.477792,
      "longitude": -93.978854,
      "city": "Ironton",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56456,
      "latitude": 46.480723,
      "longitude": -94.08587,
      "city": "Jenkins",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56458,
      "latitude": 47.108153,
      "longitude": -94.921064,
      "city": "Lake George",
      "state": "MN",
      "county": "Hubbard"
  },
  {
      "zipCode": 56459,
      "latitude": 46.498749,
      "longitude": -94.251926,
      "city": "Lake Hubert",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56461,
      "latitude": 47.108153,
      "longitude": -94.921064,
      "city": "Laporte",
      "state": "MN",
      "county": "Hubbard"
  },
  {
      "zipCode": 56464,
      "latitude": 46.730538,
      "longitude": -94.975221,
      "city": "Menahga",
      "state": "MN",
      "county": "Wadena"
  },
  {
      "zipCode": 56465,
      "latitude": 46.539308,
      "longitude": -94.134385,
      "city": "Merrifield",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56466,
      "latitude": 46.288105,
      "longitude": -94.563773,
      "city": "Motley",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56467,
      "latitude": 46.94336,
      "longitude": -94.844112,
      "city": "Nevis",
      "state": "MN",
      "county": "Hubbard"
  },
  {
      "zipCode": 56468,
      "latitude": 46.401244,
      "longitude": -94.237094,
      "city": "Nisswa",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56469,
      "latitude": 46.712878,
      "longitude": -93.489808,
      "city": "Palisade",
      "state": "MN",
      "county": "Aitkin"
  },
  {
      "zipCode": 56470,
      "latitude": 46.984699,
      "longitude": -95.09935,
      "city": "Park Rapids",
      "state": "MN",
      "county": "Hubbard"
  },
  {
      "zipCode": 56472,
      "latitude": 46.616147,
      "longitude": -94.235561,
      "city": "Pequot Lakes",
      "state": "MN",
      "county": "Crow Wing"
  },
  {
      "zipCode": 56473,
      "latitude": 46.693034,
      "longitude": -94.464381,
      "city": "Pillager",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56474,
      "latitude": 46.712158,
      "longitude": -94.251126,
      "city": "Pine River",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56475,
      "latitude": 46.105236,
      "longitude": -94.531738,
      "city": "Randall",
      "state": "MN",
      "county": "Morrison"
  },
  {
      "zipCode": 56477,
      "latitude": 46.652292,
      "longitude": -94.974942,
      "city": "Sebeka",
      "state": "MN",
      "county": "Wadena"
  },
  {
      "zipCode": 56478,
      "latitude": 46.605266,
      "longitude": -94.900729,
      "city": "Nimrod",
      "state": "MN",
      "county": "Wadena"
  },
  {
      "zipCode": 56479,
      "latitude": 46.250882,
      "longitude": -94.934361,
      "city": "Staples",
      "state": "MN",
      "county": "Todd"
  },
  {
      "zipCode": 56481,
      "latitude": 46.506528,
      "longitude": -94.967564,
      "city": "Verndale",
      "state": "MN",
      "county": "Wadena"
  },
  {
      "zipCode": 56482,
      "latitude": 46.564002,
      "longitude": -95.082796,
      "city": "Wadena",
      "state": "MN",
      "county": "Wadena"
  },
  {
      "zipCode": 56484,
      "latitude": 47.067057,
      "longitude": -94.489824,
      "city": "Walker",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56501,
      "latitude": 46.834262,
      "longitude": -95.746871,
      "city": "Detroit Lakes",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56502,
      "latitude": 46.933961,
      "longitude": -95.678375,
      "city": "Detroit Lakes",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56510,
      "latitude": 47.325283,
      "longitude": -96.597259,
      "city": "Ada",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56511,
      "latitude": 46.850852,
      "longitude": -95.995824,
      "city": "Audubon",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56513,
      "latitude": 46.890034,
      "longitude": -96.506156,
      "city": "Baker",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56514,
      "latitude": 46.649467,
      "longitude": -96.391637,
      "city": "Barnesville",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56515,
      "latitude": 46.294519,
      "longitude": -95.707485,
      "city": "Battle Lake",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56516,
      "latitude": 47.325198,
      "longitude": -95.80918,
      "city": "Bejou",
      "state": "MN",
      "county": "Mahnomen"
  },
  {
      "zipCode": 56517,
      "latitude": 47.801705,
      "longitude": -96.43368,
      "city": "Beltrami",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56518,
      "latitude": 46.412413,
      "longitude": -95.713452,
      "city": "Bluffton",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56519,
      "latitude": 47.201971,
      "longitude": -96.500374,
      "city": "Borup",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56520,
      "latitude": 46.191367,
      "longitude": -96.500224,
      "city": "Breckenridge",
      "state": "MN",
      "county": "Wilkin"
  },
  {
      "zipCode": 56521,
      "latitude": 46.979328,
      "longitude": -95.912192,
      "city": "Callaway",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56522,
      "latitude": 46.140224,
      "longitude": -96.443327,
      "city": "Campbell",
      "state": "MN",
      "county": "Wilkin"
  },
  {
      "zipCode": 56523,
      "latitude": 47.683698,
      "longitude": -96.87154,
      "city": "Climax",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56524,
      "latitude": 46.275667,
      "longitude": -95.630788,
      "city": "Clitherall",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56525,
      "latitude": 46.890034,
      "longitude": -96.506156,
      "city": "Comstock",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56527,
      "latitude": 46.392933,
      "longitude": -95.318951,
      "city": "Deer Creek",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56528,
      "latitude": 46.57101,
      "longitude": -95.728629,
      "city": "Dent",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56529,
      "latitude": 46.877143,
      "longitude": -96.709806,
      "city": "Dilworth",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56531,
      "latitude": 45.997662,
      "longitude": -95.963007,
      "city": "Elbow Lake",
      "state": "MN",
      "county": "Grant"
  },
  {
      "zipCode": 56533,
      "latitude": 46.380324,
      "longitude": -96.132614,
      "city": "Elizabeth",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56534,
      "latitude": 46.483858,
      "longitude": -96.097914,
      "city": "Erhard",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56535,
      "latitude": 47.665688,
      "longitude": -95.99807,
      "city": "Erskine",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56536,
      "latitude": 47.077246,
      "longitude": -96.503987,
      "city": "Felton",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56537,
      "latitude": 46.3194,
      "longitude": -95.657003,
      "city": "Fergus Falls",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56538,
      "latitude": 46.412413,
      "longitude": -95.713452,
      "city": "Fergus Falls",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56540,
      "latitude": 47.534788,
      "longitude": -96.285663,
      "city": "Fertile",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56541,
      "latitude": 47.325074,
      "longitude": -96.469194,
      "city": "Flom",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56542,
      "latitude": 47.597727,
      "longitude": -96.270444,
      "city": "Fosston",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56543,
      "latitude": 46.326166,
      "longitude": -96.528032,
      "city": "Foxhome",
      "state": "MN",
      "county": "Wilkin"
  },
  {
      "zipCode": 56544,
      "latitude": 46.803165,
      "longitude": -95.579405,
      "city": "Frazee",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56545,
      "latitude": 47.372863,
      "longitude": -96.264276,
      "city": "Gary",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56546,
      "latitude": 46.890034,
      "longitude": -96.506156,
      "city": "Georgetown",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56547,
      "latitude": 46.870695,
      "longitude": -96.576425,
      "city": "Glyndon",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56548,
      "latitude": 47.350668,
      "longitude": -96.82368,
      "city": "Halstad",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56549,
      "latitude": 46.977738,
      "longitude": -96.409155,
      "city": "Hawley",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56550,
      "latitude": 47.263731,
      "longitude": -96.811279,
      "city": "Hendrum",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56551,
      "latitude": 46.293243,
      "longitude": -95.483624,
      "city": "Henning",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56552,
      "latitude": 46.972026,
      "longitude": -96.25589,
      "city": "Hitterdal",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56553,
      "latitude": 46.326166,
      "longitude": -96.528032,
      "city": "Kent",
      "state": "MN",
      "county": "Wilkin"
  },
  {
      "zipCode": 56554,
      "latitude": 46.891231,
      "longitude": -96.102425,
      "city": "Lake Park",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56556,
      "latitude": 47.637117,
      "longitude": -95.884768,
      "city": "Mcintosh",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56557,
      "latitude": 47.287889,
      "longitude": -95.939586,
      "city": "Mahnomen",
      "state": "MN",
      "county": "Mahnomen"
  },
  {
      "zipCode": 56560,
      "latitude": 46.803546,
      "longitude": -96.557389,
      "city": "Moorhead",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56561,
      "latitude": 46.890034,
      "longitude": -96.506156,
      "city": "Moorhead",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56562,
      "latitude": 46.890034,
      "longitude": -96.506156,
      "city": "Moorhead",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56563,
      "latitude": 46.890034,
      "longitude": -96.506156,
      "city": "Moorhead",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56565,
      "latitude": 46.326166,
      "longitude": -96.528032,
      "city": "Nashua",
      "state": "MN",
      "county": "Wilkin"
  },
  {
      "zipCode": 56566,
      "latitude": 47.325198,
      "longitude": -95.80918,
      "city": "Naytahwaush",
      "state": "MN",
      "county": "Mahnomen"
  },
  {
      "zipCode": 56567,
      "latitude": 46.491294,
      "longitude": -95.366068,
      "city": "New York Mills",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56568,
      "latitude": 47.836367,
      "longitude": -96.3504,
      "city": "Nielsville",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56569,
      "latitude": 47.10914,
      "longitude": -95.782254,
      "city": "Ogema",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56570,
      "latitude": 46.923974,
      "longitude": -95.362298,
      "city": "Osage",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56571,
      "latitude": 46.465546,
      "longitude": -95.564365,
      "city": "Ottertail",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56572,
      "latitude": 46.611549,
      "longitude": -96.059669,
      "city": "Pelican Rapids",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56573,
      "latitude": 46.597093,
      "longitude": -95.822634,
      "city": "Perham",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56574,
      "latitude": 47.177615,
      "longitude": -96.804613,
      "city": "Perley",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56575,
      "latitude": 47.061724,
      "longitude": -95.429915,
      "city": "Ponsford",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56576,
      "latitude": 46.443365,
      "longitude": -95.792367,
      "city": "Richville",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56577,
      "latitude": 46.933961,
      "longitude": -95.678375,
      "city": "Richwood",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56578,
      "latitude": 46.886152,
      "longitude": -95.724599,
      "city": "Rochert",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56579,
      "latitude": 46.529553,
      "longitude": -96.349085,
      "city": "Rothsay",
      "state": "MN",
      "county": "Wilkin"
  },
  {
      "zipCode": 56580,
      "latitude": 46.779487,
      "longitude": -96.651185,
      "city": "Sabin",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56581,
      "latitude": 47.320042,
      "longitude": -96.54441,
      "city": "Shelly",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56583,
      "latitude": 46.007415,
      "longitude": -96.359342,
      "city": "Tintah",
      "state": "MN",
      "county": "Traverse"
  },
  {
      "zipCode": 56584,
      "latitude": 47.271463,
      "longitude": -96.182441,
      "city": "Twin Valley",
      "state": "MN",
      "county": "Norman"
  },
  {
      "zipCode": 56585,
      "latitude": 47.0663,
      "longitude": -96.258706,
      "city": "Ulen",
      "state": "MN",
      "county": "Clay"
  },
  {
      "zipCode": 56586,
      "latitude": 46.285081,
      "longitude": -95.874117,
      "city": "Underwood",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56587,
      "latitude": 46.457968,
      "longitude": -95.919355,
      "city": "Vergas",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56588,
      "latitude": 46.412413,
      "longitude": -95.713452,
      "city": "Vining",
      "state": "MN",
      "county": "Otter Tail"
  },
  {
      "zipCode": 56589,
      "latitude": 47.184343,
      "longitude": -95.939849,
      "city": "Waubun",
      "state": "MN",
      "county": "Mahnomen"
  },
  {
      "zipCode": 56590,
      "latitude": 45.934055,
      "longitude": -96.012359,
      "city": "Wendell",
      "state": "MN",
      "county": "Grant"
  },
  {
      "zipCode": 56591,
      "latitude": 46.933961,
      "longitude": -95.678375,
      "city": "White Earth",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56592,
      "latitude": 47.631462,
      "longitude": -95.889453,
      "city": "Winger",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56593,
      "latitude": 46.821648,
      "longitude": -95.391968,
      "city": "Wolf Lake",
      "state": "MN",
      "county": "Becker"
  },
  {
      "zipCode": 56594,
      "latitude": 46.326166,
      "longitude": -96.528032,
      "city": "Wolverton",
      "state": "MN",
      "county": "Wilkin"
  },
  {
      "zipCode": 56601,
      "latitude": 47.571964,
      "longitude": -94.801272,
      "city": "Bemidji",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56619,
      "latitude": 47.625699,
      "longitude": -94.822154,
      "city": "Bemidji",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56621,
      "latitude": 47.531644,
      "longitude": -95.377949,
      "city": "Bagley",
      "state": "MN",
      "county": "Clearwater"
  },
  {
      "zipCode": 56623,
      "latitude": 48.750473,
      "longitude": -94.84626,
      "city": "Baudette",
      "state": "MN",
      "county": "Lake Of The Woods"
  },
  {
      "zipCode": 56626,
      "latitude": 47.370372,
      "longitude": -94.251376,
      "city": "Bena",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56627,
      "latitude": 48.091615,
      "longitude": -93.81606,
      "city": "Big Falls",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56628,
      "latitude": 47.710353,
      "longitude": -93.612694,
      "city": "Bigfork",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56629,
      "latitude": 48.642546,
      "longitude": -94.06358,
      "city": "Birchdale",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56630,
      "latitude": 47.804493,
      "longitude": -94.575871,
      "city": "Blackduck",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56631,
      "latitude": 47.087782,
      "longitude": -93.921429,
      "city": "Bowstring",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56633,
      "latitude": 47.327719,
      "longitude": -94.476853,
      "city": "Cass Lake",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56634,
      "latitude": 47.610498,
      "longitude": -95.421104,
      "city": "Clearbrook",
      "state": "MN",
      "county": "Clearwater"
  },
  {
      "zipCode": 56636,
      "latitude": 47.46843,
      "longitude": -93.810949,
      "city": "Deer River",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56637,
      "latitude": 47.615375,
      "longitude": -93.837811,
      "city": "Talmoon",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56639,
      "latitude": 47.852226,
      "longitude": -93.524252,
      "city": "Effie",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56641,
      "latitude": 47.206552,
      "longitude": -94.263384,
      "city": "Federal Dam",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56644,
      "latitude": 47.715406,
      "longitude": -95.470782,
      "city": "Gonvick",
      "state": "MN",
      "county": "Clearwater"
  },
  {
      "zipCode": 56646,
      "latitude": 47.836367,
      "longitude": -96.3504,
      "city": "Gully",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56647,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Hines",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56649,
      "latitude": 48.232494,
      "longitude": -93.640382,
      "city": "International Falls",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56650,
      "latitude": 47.940789,
      "longitude": -94.45001,
      "city": "Kelliher",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56651,
      "latitude": 47.836367,
      "longitude": -96.3504,
      "city": "Lengby",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56652,
      "latitude": 47.585873,
      "longitude": -95.375974,
      "city": "Leonard",
      "state": "MN",
      "county": "Clearwater"
  },
  {
      "zipCode": 56653,
      "latitude": 48.357144,
      "longitude": -93.612836,
      "city": "Littlefork",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56654,
      "latitude": 48.538803,
      "longitude": -93.840769,
      "city": "Loman",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56655,
      "latitude": 46.994097,
      "longitude": -94.243551,
      "city": "Longville",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56657,
      "latitude": 47.548518,
      "longitude": -93.62356,
      "city": "Marcell",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56658,
      "latitude": 48.27888,
      "longitude": -93.755536,
      "city": "Margie",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56659,
      "latitude": 47.661759,
      "longitude": -94.015607,
      "city": "Max",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56660,
      "latitude": 47.933964,
      "longitude": -94.23693,
      "city": "Mizpah",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56661,
      "latitude": 47.919215,
      "longitude": -94.097415,
      "city": "Northome",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56662,
      "latitude": 46.879043,
      "longitude": -93.918172,
      "city": "Outing",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56663,
      "latitude": 47.448274,
      "longitude": -94.471485,
      "city": "Pennington",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56666,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Ponemah",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56667,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Puposky",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56668,
      "latitude": 48.603143,
      "longitude": -93.29771,
      "city": "Ranier",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56669,
      "latitude": 48.394721,
      "longitude": -93.310667,
      "city": "Ray",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56670,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Redby",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56671,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Redlake",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56672,
      "latitude": 47.095793,
      "longitude": -94.021032,
      "city": "Remer",
      "state": "MN",
      "county": "Cass"
  },
  {
      "zipCode": 56673,
      "latitude": 48.769244,
      "longitude": -95.747559,
      "city": "Roosevelt",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56676,
      "latitude": 47.585873,
      "longitude": -95.375974,
      "city": "Shevlin",
      "state": "MN",
      "county": "Clearwater"
  },
  {
      "zipCode": 56678,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Solway",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56679,
      "latitude": 48.27888,
      "longitude": -93.755536,
      "city": "South International Falls",
      "state": "MN",
      "county": "Koochiching"
  },
  {
      "zipCode": 56680,
      "latitude": 47.635946,
      "longitude": -93.922032,
      "city": "Spring Lake",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56681,
      "latitude": 47.624415,
      "longitude": -94.187735,
      "city": "Squaw Lake",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56682,
      "latitude": 48.769244,
      "longitude": -95.747559,
      "city": "Swift",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56683,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Tenstrike",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56684,
      "latitude": 47.836367,
      "longitude": -96.3504,
      "city": "Trail",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56685,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Waskish",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56686,
      "latitude": 48.820843,
      "longitude": -94.933138,
      "city": "Williams",
      "state": "MN",
      "county": "Lake Of The Woods"
  },
  {
      "zipCode": 56687,
      "latitude": 47.974989,
      "longitude": -95.008708,
      "city": "Wilton",
      "state": "MN",
      "county": "Beltrami"
  },
  {
      "zipCode": 56688,
      "latitude": 47.746177,
      "longitude": -93.962961,
      "city": "Wirt",
      "state": "MN",
      "county": "Itasca"
  },
  {
      "zipCode": 56701,
      "latitude": 48.076333,
      "longitude": -96.149002,
      "city": "Thief River Falls",
      "state": "MN",
      "county": "Pennington"
  },
  {
      "zipCode": 56710,
      "latitude": 48.191085,
      "longitude": -96.998433,
      "city": "Alvarado",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56711,
      "latitude": 48.875164,
      "longitude": -94.885668,
      "city": "Angle Inlet",
      "state": "MN",
      "county": "Lake Of The Woods"
  },
  {
      "zipCode": 56712,
      "latitude": 47.836367,
      "longitude": -96.3504,
      "city": "Angus",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56713,
      "latitude": 48.331455,
      "longitude": -96.816197,
      "city": "Argyle",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56714,
      "latitude": 48.774428,
      "longitude": -96.020334,
      "city": "Badger",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56715,
      "latitude": 47.814152,
      "longitude": -96.005263,
      "city": "Brooks",
      "state": "MN",
      "county": "Red Lake"
  },
  {
      "zipCode": 56716,
      "latitude": 47.705082,
      "longitude": -96.412,
      "city": "Crookston",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56720,
      "latitude": 48.771938,
      "longitude": -96.812921,
      "city": "Donaldson",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56721,
      "latitude": 47.874048,
      "longitude": -96.924085,
      "city": "East Grand Forks",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56722,
      "latitude": 47.836367,
      "longitude": -96.3504,
      "city": "Euclid",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56723,
      "latitude": 47.799949,
      "longitude": -96.798532,
      "city": "Fisher",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56724,
      "latitude": 48.358371,
      "longitude": -96.378062,
      "city": "Gatzke",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56725,
      "latitude": 48.144158,
      "longitude": -95.804345,
      "city": "Goodridge",
      "state": "MN",
      "county": "Pennington"
  },
  {
      "zipCode": 56726,
      "latitude": 48.834783,
      "longitude": -96.286287,
      "city": "Greenbush",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56727,
      "latitude": 48.358371,
      "longitude": -96.378062,
      "city": "Grygla",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56728,
      "latitude": 48.774787,
      "longitude": -96.942022,
      "city": "Hallock",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56729,
      "latitude": 48.771938,
      "longitude": -96.812921,
      "city": "Halma",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56731,
      "latitude": 48.771938,
      "longitude": -96.812921,
      "city": "Humboldt",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56732,
      "latitude": 48.565003,
      "longitude": -96.53266,
      "city": "Karlstad",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56733,
      "latitude": 48.643714,
      "longitude": -96.914605,
      "city": "Kennedy",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56734,
      "latitude": 48.771938,
      "longitude": -96.812921,
      "city": "Lake Bronson",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56735,
      "latitude": 48.771938,
      "longitude": -96.812921,
      "city": "Lancaster",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56736,
      "latitude": 47.681728,
      "longitude": -96.154311,
      "city": "Mentor",
      "state": "MN",
      "county": "Polk"
  },
  {
      "zipCode": 56737,
      "latitude": 48.438104,
      "longitude": -96.513779,
      "city": "Middle River",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56738,
      "latitude": 48.358371,
      "longitude": -96.378062,
      "city": "Newfolden",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56740,
      "latitude": 48.771938,
      "longitude": -96.812921,
      "city": "Noyes",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56741,
      "latitude": 48.875164,
      "longitude": -94.885668,
      "city": "Oak Island",
      "state": "MN",
      "county": "Lake Of The Woods"
  },
  {
      "zipCode": 56742,
      "latitude": 47.838345,
      "longitude": -95.853261,
      "city": "Oklee",
      "state": "MN",
      "county": "Red Lake"
  },
  {
      "zipCode": 56744,
      "latitude": 48.19941,
      "longitude": -97.130755,
      "city": "Oslo",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56748,
      "latitude": 47.86217,
      "longitude": -96.095883,
      "city": "Plummer",
      "state": "MN",
      "county": "Red Lake"
  },
  {
      "zipCode": 56750,
      "latitude": 47.885347,
      "longitude": -96.270368,
      "city": "Red Lake Falls",
      "state": "MN",
      "county": "Red Lake"
  },
  {
      "zipCode": 56751,
      "latitude": 48.704839,
      "longitude": -95.750383,
      "city": "Roseau",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56754,
      "latitude": 48.014969,
      "longitude": -96.213472,
      "city": "Saint Hilaire",
      "state": "MN",
      "county": "Pennington"
  },
  {
      "zipCode": 56755,
      "latitude": 48.871994,
      "longitude": -97.092442,
      "city": "Saint Vincent",
      "state": "MN",
      "county": "Kittson"
  },
  {
      "zipCode": 56756,
      "latitude": 48.769244,
      "longitude": -95.747559,
      "city": "Salol",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56757,
      "latitude": 48.477158,
      "longitude": -96.867548,
      "city": "Stephen",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56758,
      "latitude": 48.358371,
      "longitude": -96.378062,
      "city": "Strandquist",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56759,
      "latitude": 48.749138,
      "longitude": -96.062783,
      "city": "Strathcona",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56760,
      "latitude": 48.358371,
      "longitude": -96.378062,
      "city": "Viking",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56761,
      "latitude": 48.769244,
      "longitude": -95.747559,
      "city": "Wannaska",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 56762,
      "latitude": 48.261376,
      "longitude": -96.772583,
      "city": "Warren",
      "state": "MN",
      "county": "Marshall"
  },
  {
      "zipCode": 56763,
      "latitude": 48.900663,
      "longitude": -95.250324,
      "city": "Warroad",
      "state": "MN",
      "county": "Roseau"
  },
  {
      "zipCode": 57001,
      "latitude": 42.931698,
      "longitude": -96.624298,
      "city": "Alcester",
      "state": "SD",
      "county": "Union"
  },
  {
      "zipCode": 57002,
      "latitude": 44.304195,
      "longitude": -96.667977,
      "city": "Aurora",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57003,
      "latitude": 43.64514,
      "longitude": -96.814589,
      "city": "Baltic",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57004,
      "latitude": 42.842947,
      "longitude": -96.681135,
      "city": "Beresford",
      "state": "SD",
      "county": "Union"
  },
  {
      "zipCode": 57005,
      "latitude": 43.603401,
      "longitude": -96.793708,
      "city": "Brandon",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57006,
      "latitude": 44.355091,
      "longitude": -96.762102,
      "city": "Brookings",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57007,
      "latitude": 44.369696,
      "longitude": -96.790705,
      "city": "Brookings",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57010,
      "latitude": 42.827796,
      "longitude": -96.847373,
      "city": "Burbank",
      "state": "SD",
      "county": "Clay"
  },
  {
      "zipCode": 57012,
      "latitude": 43.572888,
      "longitude": -97.248418,
      "city": "Canistota",
      "state": "SD",
      "county": "Mccook"
  },
  {
      "zipCode": 57013,
      "latitude": 43.29831,
      "longitude": -96.70718,
      "city": "Canton",
      "state": "SD",
      "county": "Lincoln"
  },
  {
      "zipCode": 57014,
      "latitude": 43.291296,
      "longitude": -97.042271,
      "city": "Centerville",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57015,
      "latitude": 43.408226,
      "longitude": -96.983852,
      "city": "Chancellor",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57016,
      "latitude": 43.895505,
      "longitude": -96.928024,
      "city": "Chester",
      "state": "SD",
      "county": "Lake"
  },
  {
      "zipCode": 57017,
      "latitude": 43.984778,
      "longitude": -96.814237,
      "city": "Colman",
      "state": "SD",
      "county": "Moody"
  },
  {
      "zipCode": 57018,
      "latitude": 43.790192,
      "longitude": -96.907845,
      "city": "Colton",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57020,
      "latitude": 43.666894,
      "longitude": -96.822137,
      "city": "Crooks",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57021,
      "latitude": 43.272502,
      "longitude": -96.983494,
      "city": "Davis",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57022,
      "latitude": 43.803585,
      "longitude": -96.699766,
      "city": "Dell Rapids",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57024,
      "latitude": 43.998434,
      "longitude": -96.648034,
      "city": "Egan",
      "state": "SD",
      "county": "Moody"
  },
  {
      "zipCode": 57025,
      "latitude": 42.76399,
      "longitude": -96.676742,
      "city": "Elk Point",
      "state": "SD",
      "county": "Union"
  },
  {
      "zipCode": 57026,
      "latitude": 44.289677,
      "longitude": -96.540249,
      "city": "Elkton",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57027,
      "latitude": 43.197041,
      "longitude": -96.513506,
      "city": "Fairview",
      "state": "SD",
      "county": "Lincoln"
  },
  {
      "zipCode": 57028,
      "latitude": 43.984004,
      "longitude": -96.616189,
      "city": "Flandreau",
      "state": "SD",
      "county": "Moody"
  },
  {
      "zipCode": 57029,
      "latitude": 43.321384,
      "longitude": -97.547869,
      "city": "Freeman",
      "state": "SD",
      "county": "Hutchinson"
  },
  {
      "zipCode": 57030,
      "latitude": 43.747639,
      "longitude": -96.596379,
      "city": "Garretson",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57031,
      "latitude": 42.886136,
      "longitude": -97.209902,
      "city": "Gayville",
      "state": "SD",
      "county": "Yankton"
  },
  {
      "zipCode": 57032,
      "latitude": 43.425258,
      "longitude": -96.659737,
      "city": "Harrisburg",
      "state": "SD",
      "county": "Lincoln"
  },
  {
      "zipCode": 57033,
      "latitude": 43.630545,
      "longitude": -96.814539,
      "city": "Hartford",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57034,
      "latitude": 43.184976,
      "longitude": -96.580939,
      "city": "Hudson",
      "state": "SD",
      "county": "Lincoln"
  },
  {
      "zipCode": 57035,
      "latitude": 43.637836,
      "longitude": -96.816129,
      "city": "Humboldt",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57036,
      "latitude": 43.278943,
      "longitude": -97.141677,
      "city": "Hurley",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57037,
      "latitude": 43.068331,
      "longitude": -97.130498,
      "city": "Irene",
      "state": "SD",
      "county": "Clay"
  },
  {
      "zipCode": 57038,
      "latitude": 42.580112,
      "longitude": -96.576256,
      "city": "Jefferson",
      "state": "SD",
      "county": "Union"
  },
  {
      "zipCode": 57039,
      "latitude": 43.298098,
      "longitude": -96.856021,
      "city": "Lennox",
      "state": "SD",
      "county": "Lincoln"
  },
  {
      "zipCode": 57040,
      "latitude": 43.06062,
      "longitude": -97.568901,
      "city": "Lesterville",
      "state": "SD",
      "county": "Yankton"
  },
  {
      "zipCode": 57041,
      "latitude": 43.728488,
      "longitude": -96.870928,
      "city": "Lyons",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57042,
      "latitude": 44.054223,
      "longitude": -97.091339,
      "city": "Madison",
      "state": "SD",
      "county": "Lake"
  },
  {
      "zipCode": 57043,
      "latitude": 43.292364,
      "longitude": -97.278318,
      "city": "Marion",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57044,
      "latitude": 42.840984,
      "longitude": -97.091959,
      "city": "Meckling",
      "state": "SD",
      "county": "Clay"
  },
  {
      "zipCode": 57045,
      "latitude": 43.248825,
      "longitude": -97.538733,
      "city": "Menno",
      "state": "SD",
      "county": "Hutchinson"
  },
  {
      "zipCode": 57046,
      "latitude": 42.969251,
      "longitude": -97.297103,
      "city": "Mission Hill",
      "state": "SD",
      "county": "Yankton"
  },
  {
      "zipCode": 57047,
      "latitude": 43.489841,
      "longitude": -97.250932,
      "city": "Monroe",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57048,
      "latitude": 43.696853,
      "longitude": -97.18593,
      "city": "Montrose",
      "state": "SD",
      "county": "Mccook"
  },
  {
      "zipCode": 57049,
      "latitude": 42.587483,
      "longitude": -96.585026,
      "city": "North Sioux City",
      "state": "SD",
      "county": "Union"
  },
  {
      "zipCode": 57050,
      "latitude": 44.021983,
      "longitude": -97.129455,
      "city": "Nunda",
      "state": "SD",
      "county": "Lake"
  },
  {
      "zipCode": 57051,
      "latitude": 44.245942,
      "longitude": -97.32031,
      "city": "Oldham",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57052,
      "latitude": 43.284183,
      "longitude": -97.718224,
      "city": "Olivet",
      "state": "SD",
      "county": "Hutchinson"
  },
  {
      "zipCode": 57053,
      "latitude": 43.399595,
      "longitude": -97.134211,
      "city": "Parker",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57054,
      "latitude": 44.1186,
      "longitude": -97.215191,
      "city": "Ramona",
      "state": "SD",
      "county": "Lake"
  },
  {
      "zipCode": 57055,
      "latitude": 43.666979,
      "longitude": -96.760473,
      "city": "Renner",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57056,
      "latitude": 43.521559,
      "longitude": -96.558738,
      "city": "Rowena",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57057,
      "latitude": 44.021983,
      "longitude": -97.129455,
      "city": "Rutland",
      "state": "SD",
      "county": "Lake"
  },
  {
      "zipCode": 57058,
      "latitude": 43.639298,
      "longitude": -97.343417,
      "city": "Salem",
      "state": "SD",
      "county": "Mccook"
  },
  {
      "zipCode": 57059,
      "latitude": 43.024378,
      "longitude": -97.783482,
      "city": "Scotland",
      "state": "SD",
      "county": "Bon Homme"
  },
  {
      "zipCode": 57061,
      "latitude": 44.242764,
      "longitude": -97.018205,
      "city": "Sinai",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57062,
      "latitude": 42.881672,
      "longitude": -97.926212,
      "city": "Springfield",
      "state": "SD",
      "county": "Bon Homme"
  },
  {
      "zipCode": 57063,
      "latitude": 42.947515,
      "longitude": -97.714195,
      "city": "Tabor",
      "state": "SD",
      "county": "Bon Homme"
  },
  {
      "zipCode": 57064,
      "latitude": 43.41434,
      "longitude": -96.842783,
      "city": "Tea",
      "state": "SD",
      "county": "Lincoln"
  },
  {
      "zipCode": 57065,
      "latitude": 43.907616,
      "longitude": -96.653301,
      "city": "Trent",
      "state": "SD",
      "county": "Moody"
  },
  {
      "zipCode": 57066,
      "latitude": 42.98853,
      "longitude": -97.873263,
      "city": "Tyndall",
      "state": "SD",
      "county": "Bon Homme"
  },
  {
      "zipCode": 57067,
      "latitude": 43.016376,
      "longitude": -97.369385,
      "city": "Utica",
      "state": "SD",
      "county": "Yankton"
  },
  {
      "zipCode": 57068,
      "latitude": 43.594402,
      "longitude": -96.528511,
      "city": "Valley Springs",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57069,
      "latitude": 42.854124,
      "longitude": -96.973318,
      "city": "Vermillion",
      "state": "SD",
      "county": "Clay"
  },
  {
      "zipCode": 57070,
      "latitude": 43.188383,
      "longitude": -97.180373,
      "city": "Viborg",
      "state": "SD",
      "county": "Turner"
  },
  {
      "zipCode": 57071,
      "latitude": 44.369694,
      "longitude": -96.790512,
      "city": "Volga",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57072,
      "latitude": 43.002044,
      "longitude": -97.239829,
      "city": "Volin",
      "state": "SD",
      "county": "Yankton"
  },
  {
      "zipCode": 57073,
      "latitude": 42.996509,
      "longitude": -97.058654,
      "city": "Wakonda",
      "state": "SD",
      "county": "Clay"
  },
  {
      "zipCode": 57075,
      "latitude": 43.996979,
      "longitude": -96.967095,
      "city": "Wentworth",
      "state": "SD",
      "county": "Lake"
  },
  {
      "zipCode": 57076,
      "latitude": 44.021983,
      "longitude": -97.129455,
      "city": "Winfred",
      "state": "SD",
      "county": "Lake"
  },
  {
      "zipCode": 57077,
      "latitude": 43.286596,
      "longitude": -96.776784,
      "city": "Worthing",
      "state": "SD",
      "county": "Lincoln"
  },
  {
      "zipCode": 57078,
      "latitude": 42.94396,
      "longitude": -97.37689,
      "city": "Yankton",
      "state": "SD",
      "county": "Yankton"
  },
  {
      "zipCode": 57079,
      "latitude": 42.867556,
      "longitude": -97.390268,
      "city": "Yankton",
      "state": "SD",
      "county": "Yankton"
  },
  {
      "zipCode": 57101,
      "latitude": 43.546358,
      "longitude": -96.69063,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57103,
      "latitude": 43.548433,
      "longitude": -96.63871,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57104,
      "latitude": 43.589218,
      "longitude": -96.685294,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57105,
      "latitude": 43.518636,
      "longitude": -96.732266,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57106,
      "latitude": 43.529244,
      "longitude": -96.780065,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57107,
      "latitude": 43.600064,
      "longitude": -96.801033,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57108,
      "latitude": 43.550404,
      "longitude": -96.696878,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57109,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57110,
      "latitude": 43.548613,
      "longitude": -96.633203,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57115,
      "latitude": 43.521895,
      "longitude": -96.868464,
      "city": "Buffalo Ridge",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57117,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57118,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57186,
      "latitude": 43.544455,
      "longitude": -96.723779,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57188,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57189,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57192,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57193,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57194,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57195,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57196,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57197,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57198,
      "latitude": 43.674582,
      "longitude": -96.79134,
      "city": "Sioux Falls",
      "state": "SD",
      "county": "Minnehaha"
  },
  {
      "zipCode": 57201,
      "latitude": 44.955643,
      "longitude": -97.177954,
      "city": "Watertown",
      "state": "SD",
      "county": "Codington"
  },
  {
      "zipCode": 57202,
      "latitude": 44.977634,
      "longitude": -97.188372,
      "city": "Waverly",
      "state": "SD",
      "county": "Codington"
  },
  {
      "zipCode": 57212,
      "latitude": 44.368264,
      "longitude": -97.246086,
      "city": "Arlington",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57213,
      "latitude": 44.562302,
      "longitude": -96.591264,
      "city": "Astoria",
      "state": "SD",
      "county": "Deuel"
  },
  {
      "zipCode": 57214,
      "latitude": 44.484381,
      "longitude": -97.209173,
      "city": "Badger",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57216,
      "latitude": 45.170552,
      "longitude": -96.534574,
      "city": "Big Stone City",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57217,
      "latitude": 44.90626,
      "longitude": -97.708049,
      "city": "Bradley",
      "state": "SD",
      "county": "Clark"
  },
  {
      "zipCode": 57218,
      "latitude": 44.76048,
      "longitude": -96.668424,
      "city": "Brandt",
      "state": "SD",
      "county": "Deuel"
  },
  {
      "zipCode": 57219,
      "latitude": 45.319218,
      "longitude": -97.637812,
      "city": "Bristol",
      "state": "SD",
      "county": "Day"
  },
  {
      "zipCode": 57220,
      "latitude": 44.456735,
      "longitude": -96.89302,
      "city": "Bruce",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57221,
      "latitude": 44.590094,
      "longitude": -97.466776,
      "city": "Bryant",
      "state": "SD",
      "county": "Hamlin"
  },
  {
      "zipCode": 57223,
      "latitude": 44.65665,
      "longitude": -97.116149,
      "city": "Castlewood",
      "state": "SD",
      "county": "Hamlin"
  },
  {
      "zipCode": 57224,
      "latitude": 45.616422,
      "longitude": -96.849416,
      "city": "Claire City",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57225,
      "latitude": 44.891983,
      "longitude": -97.832972,
      "city": "Clark",
      "state": "SD",
      "county": "Clark"
  },
  {
      "zipCode": 57226,
      "latitude": 44.761249,
      "longitude": -96.690651,
      "city": "Clear Lake",
      "state": "SD",
      "county": "Deuel"
  },
  {
      "zipCode": 57227,
      "latitude": 45.616422,
      "longitude": -96.849416,
      "city": "Corona",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57231,
      "latitude": 44.369482,
      "longitude": -97.542049,
      "city": "De Smet",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57232,
      "latitude": 45.631054,
      "longitude": -97.40057,
      "city": "Eden",
      "state": "SD",
      "county": "Marshall"
  },
  {
      "zipCode": 57233,
      "latitude": 44.470856,
      "longitude": -97.370412,
      "city": "Erwin",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57234,
      "latitude": 44.673762,
      "longitude": -97.188324,
      "city": "Estelline",
      "state": "SD",
      "county": "Hamlin"
  },
  {
      "zipCode": 57235,
      "latitude": 45.015481,
      "longitude": -97.306014,
      "city": "Florence",
      "state": "SD",
      "county": "Codington"
  },
  {
      "zipCode": 57236,
      "latitude": 44.848565,
      "longitude": -97.737227,
      "city": "Garden City",
      "state": "SD",
      "county": "Clark"
  },
  {
      "zipCode": 57237,
      "latitude": 44.792302,
      "longitude": -96.45927,
      "city": "Gary",
      "state": "SD",
      "county": "Deuel"
  },
  {
      "zipCode": 57238,
      "latitude": 44.76048,
      "longitude": -96.668424,
      "city": "Goodwin",
      "state": "SD",
      "county": "Deuel"
  },
  {
      "zipCode": 57239,
      "latitude": 45.369855,
      "longitude": -97.603949,
      "city": "Grenville",
      "state": "SD",
      "county": "Day"
  },
  {
      "zipCode": 57241,
      "latitude": 44.657338,
      "longitude": -97.20497,
      "city": "Hayti",
      "state": "SD",
      "county": "Hamlin"
  },
  {
      "zipCode": 57242,
      "latitude": 44.759144,
      "longitude": -97.381575,
      "city": "Hazel",
      "state": "SD",
      "county": "Hamlin"
  },
  {
      "zipCode": 57243,
      "latitude": 44.929255,
      "longitude": -97.391955,
      "city": "Henry",
      "state": "SD",
      "county": "Codington"
  },
  {
      "zipCode": 57244,
      "latitude": 44.425409,
      "longitude": -97.289881,
      "city": "Hetland",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57245,
      "latitude": 44.892254,
      "longitude": -96.917412,
      "city": "Kranzburg",
      "state": "SD",
      "county": "Codington"
  },
  {
      "zipCode": 57246,
      "latitude": 45.115408,
      "longitude": -96.670622,
      "city": "Labolt",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57247,
      "latitude": 45.717904,
      "longitude": -97.470748,
      "city": "Lake City",
      "state": "SD",
      "county": "Marshall"
  },
  {
      "zipCode": 57248,
      "latitude": 44.588234,
      "longitude": -97.142872,
      "city": "Lake Norden",
      "state": "SD",
      "county": "Hamlin"
  },
  {
      "zipCode": 57249,
      "latitude": 44.369159,
      "longitude": -97.382824,
      "city": "Lake Preston",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57251,
      "latitude": 45.274296,
      "longitude": -96.942159,
      "city": "Marvin",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57252,
      "latitude": 45.171617,
      "longitude": -96.629203,
      "city": "Milbank",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57253,
      "latitude": 45.151477,
      "longitude": -96.839235,
      "city": "Milbank",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57255,
      "latitude": 45.616422,
      "longitude": -96.849416,
      "city": "New Effington",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57256,
      "latitude": 45.616422,
      "longitude": -96.849416,
      "city": "Ortley",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57257,
      "latitude": 45.542151,
      "longitude": -96.951675,
      "city": "Peever",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57258,
      "latitude": 44.910756,
      "longitude": -97.939298,
      "city": "Raymond",
      "state": "SD",
      "county": "Clark"
  },
  {
      "zipCode": 57259,
      "latitude": 45.080517,
      "longitude": -96.555664,
      "city": "Revillo",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57260,
      "latitude": 45.616422,
      "longitude": -96.849416,
      "city": "Rosholt",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57261,
      "latitude": 45.369855,
      "longitude": -97.603949,
      "city": "Roslyn",
      "state": "SD",
      "county": "Day"
  },
  {
      "zipCode": 57262,
      "latitude": 45.541443,
      "longitude": -96.970202,
      "city": "Sisseton",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57263,
      "latitude": 45.100745,
      "longitude": -97.036356,
      "city": "South Shore",
      "state": "SD",
      "county": "Codington"
  },
  {
      "zipCode": 57264,
      "latitude": 45.106694,
      "longitude": -96.802259,
      "city": "Stockholm",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57265,
      "latitude": 45.021579,
      "longitude": -96.74078,
      "city": "Strandburg",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57266,
      "latitude": 45.30204,
      "longitude": -97.036537,
      "city": "Summit",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57268,
      "latitude": 44.567419,
      "longitude": -96.59444,
      "city": "Toronto",
      "state": "SD",
      "county": "Deuel"
  },
  {
      "zipCode": 57269,
      "latitude": 45.230876,
      "longitude": -96.823706,
      "city": "Twin Brooks",
      "state": "SD",
      "county": "Grant"
  },
  {
      "zipCode": 57270,
      "latitude": 45.849458,
      "longitude": -97.376617,
      "city": "Veblen",
      "state": "SD",
      "county": "Marshall"
  },
  {
      "zipCode": 57271,
      "latitude": 44.848565,
      "longitude": -97.737227,
      "city": "Vienna",
      "state": "SD",
      "county": "Clark"
  },
  {
      "zipCode": 57272,
      "latitude": 45.093607,
      "longitude": -97.453149,
      "city": "Wallace",
      "state": "SD",
      "county": "Codington"
  },
  {
      "zipCode": 57273,
      "latitude": 45.332568,
      "longitude": -97.307239,
      "city": "Waubay",
      "state": "SD",
      "county": "Day"
  },
  {
      "zipCode": 57274,
      "latitude": 45.31506,
      "longitude": -97.525395,
      "city": "Webster",
      "state": "SD",
      "county": "Day"
  },
  {
      "zipCode": 57276,
      "latitude": 44.405596,
      "longitude": -96.599788,
      "city": "White",
      "state": "SD",
      "county": "Brookings"
  },
  {
      "zipCode": 57278,
      "latitude": 44.659815,
      "longitude": -97.573152,
      "city": "Willow Lake",
      "state": "SD",
      "county": "Clark"
  },
  {
      "zipCode": 57279,
      "latitude": 45.39857,
      "longitude": -96.832256,
      "city": "Wilmot",
      "state": "SD",
      "county": "Roberts"
  },
  {
      "zipCode": 57301,
      "latitude": 43.69591,
      "longitude": -98.086401,
      "city": "Mitchell",
      "state": "SD",
      "county": "Davison"
  },
  {
      "zipCode": 57311,
      "latitude": 43.66844,
      "longitude": -97.75632,
      "city": "Alexandria",
      "state": "SD",
      "county": "Hanson"
  },
  {
      "zipCode": 57312,
      "latitude": 44.122811,
      "longitude": -98.412198,
      "city": "Alpena",
      "state": "SD",
      "county": "Jerauld"
  },
  {
      "zipCode": 57313,
      "latitude": 43.341035,
      "longitude": -98.398964,
      "city": "Armour",
      "state": "SD",
      "county": "Douglas"
  },
  {
      "zipCode": 57314,
      "latitude": 44.020961,
      "longitude": -98.01144,
      "city": "Artesian",
      "state": "SD",
      "county": "Sanborn"
  },
  {
      "zipCode": 57315,
      "latitude": 42.988284,
      "longitude": -97.878579,
      "city": "Avon",
      "state": "SD",
      "county": "Bon Homme"
  },
  {
      "zipCode": 57317,
      "latitude": 43.248832,
      "longitude": -99.016376,
      "city": "Bonesteel",
      "state": "SD",
      "county": "Gregory"
  },
  {
      "zipCode": 57319,
      "latitude": 43.550264,
      "longitude": -97.469058,
      "city": "Bridgewater",
      "state": "SD",
      "county": "Mccook"
  },
  {
      "zipCode": 57321,
      "latitude": 43.892123,
      "longitude": -97.540053,
      "city": "Canova",
      "state": "SD",
      "county": "Miner"
  },
  {
      "zipCode": 57322,
      "latitude": 44.848565,
      "longitude": -97.737227,
      "city": "Carpenter",
      "state": "SD",
      "county": "Clark"
  },
  {
      "zipCode": 57323,
      "latitude": 44.138176,
      "longitude": -97.720661,
      "city": "Carthage",
      "state": "SD",
      "county": "Miner"
  },
  {
      "zipCode": 57324,
      "latitude": 44.325314,
      "longitude": -98.013968,
      "city": "Cavour",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57325,
      "latitude": 43.772585,
      "longitude": -99.149806,
      "city": "Chamberlain",
      "state": "SD",
      "county": "Brule"
  },
  {
      "zipCode": 57326,
      "latitude": 43.71725,
      "longitude": -99.131736,
      "city": "Chamberlain",
      "state": "SD",
      "county": "Brule"
  },
  {
      "zipCode": 57328,
      "latitude": 43.420306,
      "longitude": -98.439859,
      "city": "Corsica",
      "state": "SD",
      "county": "Douglas"
  },
  {
      "zipCode": 57329,
      "latitude": 42.984707,
      "longitude": -98.175204,
      "city": "Dante",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57330,
      "latitude": 43.290614,
      "longitude": -98.178372,
      "city": "Delmont",
      "state": "SD",
      "county": "Douglas"
  },
  {
      "zipCode": 57331,
      "latitude": 43.470444,
      "longitude": -97.999352,
      "city": "Dimock",
      "state": "SD",
      "county": "Hutchinson"
  },
  {
      "zipCode": 57332,
      "latitude": 43.586451,
      "longitude": -97.65539,
      "city": "Emery",
      "state": "SD",
      "county": "Hanson"
  },
  {
      "zipCode": 57334,
      "latitude": 43.59997,
      "longitude": -98.074101,
      "city": "Ethan",
      "state": "SD",
      "county": "Davison"
  },
  {
      "zipCode": 57335,
      "latitude": 43.248832,
      "longitude": -99.016376,
      "city": "Fairfax",
      "state": "SD",
      "county": "Gregory"
  },
  {
      "zipCode": 57337,
      "latitude": 44.001852,
      "longitude": -97.750838,
      "city": "Fedora",
      "state": "SD",
      "county": "Miner"
  },
  {
      "zipCode": 57339,
      "latitude": 44.064873,
      "longitude": -99.251509,
      "city": "Fort Thompson",
      "state": "SD",
      "county": "Buffalo"
  },
  {
      "zipCode": 57340,
      "latitude": 43.762939,
      "longitude": -97.869025,
      "city": "Fulton",
      "state": "SD",
      "county": "Hanson"
  },
  {
      "zipCode": 57341,
      "latitude": 44.064873,
      "longitude": -99.251509,
      "city": "Gann Valley",
      "state": "SD",
      "county": "Buffalo"
  },
  {
      "zipCode": 57342,
      "latitude": 43.251263,
      "longitude": -98.736404,
      "city": "Geddes",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57344,
      "latitude": 43.409186,
      "longitude": -98.528068,
      "city": "Harrison",
      "state": "SD",
      "county": "Douglas"
  },
  {
      "zipCode": 57345,
      "latitude": 44.574651,
      "longitude": -99.486417,
      "city": "Highmore",
      "state": "SD",
      "county": "Hyde"
  },
  {
      "zipCode": 57346,
      "latitude": 44.545094,
      "longitude": -99.488221,
      "city": "Stephan",
      "state": "SD",
      "county": "Hyde"
  },
  {
      "zipCode": 57348,
      "latitude": 44.567404,
      "longitude": -98.406424,
      "city": "Hitchcock",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57349,
      "latitude": 44.037102,
      "longitude": -97.56034,
      "city": "Howard",
      "state": "SD",
      "county": "Miner"
  },
  {
      "zipCode": 57350,
      "latitude": 44.398874,
      "longitude": -98.180113,
      "city": "Huron",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57353,
      "latitude": 44.369593,
      "longitude": -97.652194,
      "city": "Iroquois",
      "state": "SD",
      "county": "Kingsbury"
  },
  {
      "zipCode": 57354,
      "latitude": 43.334069,
      "longitude": -97.756924,
      "city": "Kaylor",
      "state": "SD",
      "county": "Hutchinson"
  },
  {
      "zipCode": 57355,
      "latitude": 43.744477,
      "longitude": -98.95381,
      "city": "Kimball",
      "state": "SD",
      "county": "Brule"
  },
  {
      "zipCode": 57356,
      "latitude": 43.147572,
      "longitude": -98.527479,
      "city": "Lake Andes",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57357,
      "latitude": 43.136365,
      "longitude": -98.427173,
      "city": "Ravinia",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57358,
      "latitude": 44.069628,
      "longitude": -98.425228,
      "city": "Lane",
      "state": "SD",
      "county": "Jerauld"
  },
  {
      "zipCode": 57359,
      "latitude": 43.953471,
      "longitude": -98.151193,
      "city": "Letcher",
      "state": "SD",
      "county": "Sanborn"
  },
  {
      "zipCode": 57361,
      "latitude": 43.01957,
      "longitude": -98.446929,
      "city": "Marty",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57362,
      "latitude": 44.480979,
      "longitude": -99.062766,
      "city": "Miller",
      "state": "SD",
      "county": "Hand"
  },
  {
      "zipCode": 57363,
      "latitude": 43.674994,
      "longitude": -98.164483,
      "city": "Mount Vernon",
      "state": "SD",
      "county": "Davison"
  },
  {
      "zipCode": 57364,
      "latitude": 43.427358,
      "longitude": -98.608778,
      "city": "New Holland",
      "state": "SD",
      "county": "Douglas"
  },
  {
      "zipCode": 57365,
      "latitude": 43.797502,
      "longitude": -99.394735,
      "city": "Oacoma",
      "state": "SD",
      "county": "Lyman"
  },
  {
      "zipCode": 57366,
      "latitude": 43.383849,
      "longitude": -97.915618,
      "city": "Parkston",
      "state": "SD",
      "county": "Hutchinson"
  },
  {
      "zipCode": 57367,
      "latitude": 43.067306,
      "longitude": -98.522951,
      "city": "Pickstown",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57368,
      "latitude": 43.746753,
      "longitude": -98.518533,
      "city": "Plankinton",
      "state": "SD",
      "county": "Aurora"
  },
  {
      "zipCode": 57369,
      "latitude": 43.2916,
      "longitude": -98.767186,
      "city": "Platte",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57370,
      "latitude": 43.776542,
      "longitude": -99.186931,
      "city": "Pukwana",
      "state": "SD",
      "county": "Brule"
  },
  {
      "zipCode": 57371,
      "latitude": 44.422833,
      "longitude": -99.218556,
      "city": "Ree Heights",
      "state": "SD",
      "county": "Hand"
  },
  {
      "zipCode": 57373,
      "latitude": 44.495497,
      "longitude": -98.869647,
      "city": "Saint Lawrence",
      "state": "SD",
      "county": "Hand"
  },
  {
      "zipCode": 57374,
      "latitude": 43.723565,
      "longitude": -97.581441,
      "city": "Spencer",
      "state": "SD",
      "county": "Mccook"
  },
  {
      "zipCode": 57375,
      "latitude": 43.571628,
      "longitude": -98.498361,
      "city": "Stickney",
      "state": "SD",
      "county": "Aurora"
  },
  {
      "zipCode": 57376,
      "latitude": 43.24846,
      "longitude": -97.923502,
      "city": "Tripp",
      "state": "SD",
      "county": "Hutchinson"
  },
  {
      "zipCode": 57379,
      "latitude": 44.283816,
      "longitude": -98.50273,
      "city": "Virgil",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57380,
      "latitude": 43.034725,
      "longitude": -98.315504,
      "city": "Wagner",
      "state": "SD",
      "county": "Charles Mix"
  },
  {
      "zipCode": 57381,
      "latitude": 44.415437,
      "longitude": -98.642453,
      "city": "Wessington",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57382,
      "latitude": 44.066415,
      "longitude": -98.675133,
      "city": "Wessington Springs",
      "state": "SD",
      "county": "Jerauld"
  },
  {
      "zipCode": 57383,
      "latitude": 43.714955,
      "longitude": -98.644922,
      "city": "White Lake",
      "state": "SD",
      "county": "Aurora"
  },
  {
      "zipCode": 57384,
      "latitude": 44.44945,
      "longitude": -98.487894,
      "city": "Wolsey",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57385,
      "latitude": 44.04271,
      "longitude": -98.231397,
      "city": "Woonsocket",
      "state": "SD",
      "county": "Sanborn"
  },
  {
      "zipCode": 57386,
      "latitude": 44.522526,
      "longitude": -98.040081,
      "city": "Yale",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57399,
      "latitude": 44.414319,
      "longitude": -98.279548,
      "city": "Huron",
      "state": "SD",
      "county": "Beadle"
  },
  {
      "zipCode": 57401,
      "latitude": 45.476172,
      "longitude": -98.410405,
      "city": "Aberdeen",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57402,
      "latitude": 45.589567,
      "longitude": -98.352014,
      "city": "Aberdeen",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57420,
      "latitude": 45.29069,
      "longitude": -99.991823,
      "city": "Akaska",
      "state": "SD",
      "county": "Walworth"
  },
  {
      "zipCode": 57421,
      "latitude": 45.758528,
      "longitude": -97.927389,
      "city": "Amherst",
      "state": "SD",
      "county": "Marshall"
  },
  {
      "zipCode": 57422,
      "latitude": 45.369855,
      "longitude": -97.603949,
      "city": "Andover",
      "state": "SD",
      "county": "Day"
  },
  {
      "zipCode": 57424,
      "latitude": 45.046485,
      "longitude": -98.397319,
      "city": "Ashton",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57426,
      "latitude": 45.735409,
      "longitude": -98.569848,
      "city": "Barnard",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57427,
      "latitude": 45.626851,
      "longitude": -98.270454,
      "city": "Bath",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57428,
      "latitude": 45.469377,
      "longitude": -99.595667,
      "city": "Bowdle",
      "state": "SD",
      "county": "Edmunds"
  },
  {
      "zipCode": 57429,
      "latitude": 45.163452,
      "longitude": -98.319296,
      "city": "Brentford",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57430,
      "latitude": 45.761704,
      "longitude": -97.72957,
      "city": "Britton",
      "state": "SD",
      "county": "Marshall"
  },
  {
      "zipCode": 57432,
      "latitude": 45.682867,
      "longitude": -98.051948,
      "city": "Claremont",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57433,
      "latitude": 45.537847,
      "longitude": -98.339885,
      "city": "Columbia",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57434,
      "latitude": 45.139317,
      "longitude": -98.15339,
      "city": "Conde",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57435,
      "latitude": 45.136245,
      "longitude": -98.915424,
      "city": "Cresbard",
      "state": "SD",
      "county": "Faulk"
  },
  {
      "zipCode": 57436,
      "latitude": 44.846523,
      "longitude": -98.10236,
      "city": "Doland",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57437,
      "latitude": 45.77074,
      "longitude": -99.330778,
      "city": "Eureka",
      "state": "SD",
      "county": "Mcpherson"
  },
  {
      "zipCode": 57438,
      "latitude": 45.085504,
      "longitude": -99.195418,
      "city": "Faulkton",
      "state": "SD",
      "county": "Faulk"
  },
  {
      "zipCode": 57439,
      "latitude": 45.32904,
      "longitude": -98.083721,
      "city": "Ferney",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57440,
      "latitude": 44.870365,
      "longitude": -98.225875,
      "city": "Frankfort",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57441,
      "latitude": 45.693116,
      "longitude": -98.368918,
      "city": "Frederick",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57442,
      "latitude": 45.033588,
      "longitude": -100.035273,
      "city": "Gettysburg",
      "state": "SD",
      "county": "Potter"
  },
  {
      "zipCode": 57445,
      "latitude": 45.589027,
      "longitude": -98.245639,
      "city": "Groton",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57446,
      "latitude": 45.849431,
      "longitude": -98.157861,
      "city": "Hecla",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57448,
      "latitude": 45.552579,
      "longitude": -99.461653,
      "city": "Hosmer",
      "state": "SD",
      "county": "Edmunds"
  },
  {
      "zipCode": 57449,
      "latitude": 45.60711,
      "longitude": -98.170069,
      "city": "Houghton",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57450,
      "latitude": 45.241265,
      "longitude": -99.777259,
      "city": "Hoven",
      "state": "SD",
      "county": "Potter"
  },
  {
      "zipCode": 57451,
      "latitude": 45.421905,
      "longitude": -99.258683,
      "city": "Ipswich",
      "state": "SD",
      "county": "Edmunds"
  },
  {
      "zipCode": 57452,
      "latitude": 45.449196,
      "longitude": -99.821987,
      "city": "Java",
      "state": "SD",
      "county": "Walworth"
  },
  {
      "zipCode": 57454,
      "latitude": 45.692919,
      "longitude": -97.768102,
      "city": "Langford",
      "state": "SD",
      "county": "Marshall"
  },
  {
      "zipCode": 57455,
      "latitude": 45.072088,
      "longitude": -99.998438,
      "city": "Lebanon",
      "state": "SD",
      "county": "Potter"
  },
  {
      "zipCode": 57456,
      "latitude": 45.773436,
      "longitude": -98.983305,
      "city": "Leola",
      "state": "SD",
      "county": "Mcpherson"
  },
  {
      "zipCode": 57457,
      "latitude": 45.882332,
      "longitude": -99.169825,
      "city": "Longlake",
      "state": "SD",
      "county": "Mcpherson"
  },
  {
      "zipCode": 57460,
      "latitude": 45.22295,
      "longitude": -98.599388,
      "city": "Mansfield",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57461,
      "latitude": 45.155829,
      "longitude": -98.426714,
      "city": "Mellette",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57462,
      "latitude": 45.404015,
      "longitude": -98.806895,
      "city": "Mina",
      "state": "SD",
      "county": "Edmunds"
  },
  {
      "zipCode": 57465,
      "latitude": 45.125985,
      "longitude": -98.625836,
      "city": "Northville",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57466,
      "latitude": 45.180068,
      "longitude": -99.46426,
      "city": "Onaka",
      "state": "SD",
      "county": "Faulk"
  },
  {
      "zipCode": 57467,
      "latitude": 44.911775,
      "longitude": -99.073204,
      "city": "Orient",
      "state": "SD",
      "county": "Faulk"
  },
  {
      "zipCode": 57468,
      "latitude": 45.369855,
      "longitude": -97.603949,
      "city": "Pierpont",
      "state": "SD",
      "county": "Day"
  },
  {
      "zipCode": 57469,
      "latitude": 44.954101,
      "longitude": -98.406878,
      "city": "Redfield",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57470,
      "latitude": 45.033056,
      "longitude": -98.882787,
      "city": "Rockham",
      "state": "SD",
      "county": "Faulk"
  },
  {
      "zipCode": 57471,
      "latitude": 45.419289,
      "longitude": -99.346593,
      "city": "Roscoe",
      "state": "SD",
      "county": "Edmunds"
  },
  {
      "zipCode": 57472,
      "latitude": 45.434632,
      "longitude": -100.098171,
      "city": "Selby",
      "state": "SD",
      "county": "Walworth"
  },
  {
      "zipCode": 57473,
      "latitude": 44.99142,
      "longitude": -99.340678,
      "city": "Seneca",
      "state": "SD",
      "county": "Faulk"
  },
  {
      "zipCode": 57474,
      "latitude": 45.306691,
      "longitude": -98.258376,
      "city": "Stratford",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57475,
      "latitude": 45.22375,
      "longitude": -99.694578,
      "city": "Tolstoy",
      "state": "SD",
      "county": "Potter"
  },
  {
      "zipCode": 57476,
      "latitude": 44.893778,
      "longitude": -98.402047,
      "city": "Tulare",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57477,
      "latitude": 45.045466,
      "longitude": -98.182922,
      "city": "Turton",
      "state": "SD",
      "county": "Spink"
  },
  {
      "zipCode": 57479,
      "latitude": 45.329227,
      "longitude": -98.47485,
      "city": "Warner",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57481,
      "latitude": 45.670534,
      "longitude": -98.580207,
      "city": "Westport",
      "state": "SD",
      "county": "Brown"
  },
  {
      "zipCode": 57501,
      "latitude": 44.334073,
      "longitude": -100.094195,
      "city": "Pierre",
      "state": "SD",
      "county": "Hughes"
  },
  {
      "zipCode": 57520,
      "latitude": 44.802621,
      "longitude": -100.158903,
      "city": "Agar",
      "state": "SD",
      "county": "Sully"
  },
  {
      "zipCode": 57521,
      "latitude": 43.691966,
      "longitude": -101.601609,
      "city": "Belvidere",
      "state": "SD",
      "county": "Jackson"
  },
  {
      "zipCode": 57522,
      "latitude": 44.43719,
      "longitude": -99.926952,
      "city": "Blunt",
      "state": "SD",
      "county": "Hughes"
  },
  {
      "zipCode": 57523,
      "latitude": 43.182547,
      "longitude": -99.293674,
      "city": "Burke",
      "state": "SD",
      "county": "Gregory"
  },
  {
      "zipCode": 57526,
      "latitude": 43.382122,
      "longitude": -99.88231,
      "city": "Carter",
      "state": "SD",
      "county": "Tripp"
  },
  {
      "zipCode": 57528,
      "latitude": 43.260083,
      "longitude": -99.716537,
      "city": "Colome",
      "state": "SD",
      "county": "Tripp"
  },
  {
      "zipCode": 57529,
      "latitude": 43.23571,
      "longitude": -99.515887,
      "city": "Dallas",
      "state": "SD",
      "county": "Gregory"
  },
  {
      "zipCode": 57531,
      "latitude": 43.938049,
      "longitude": -100.701672,
      "city": "Draper",
      "state": "SD",
      "county": "Jones"
  },
  {
      "zipCode": 57532,
      "latitude": 44.343326,
      "longitude": -100.635312,
      "city": "Fort Pierre",
      "state": "SD",
      "county": "Stanley"
  },
  {
      "zipCode": 57533,
      "latitude": 43.213649,
      "longitude": -99.36219,
      "city": "Gregory",
      "state": "SD",
      "county": "Gregory"
  },
  {
      "zipCode": 57534,
      "latitude": 43.382122,
      "longitude": -99.88231,
      "city": "Hamill",
      "state": "SD",
      "county": "Tripp"
  },
  {
      "zipCode": 57536,
      "latitude": 44.333927,
      "longitude": -99.767237,
      "city": "Harrold",
      "state": "SD",
      "county": "Hughes"
  },
  {
      "zipCode": 57537,
      "latitude": 44.531281,
      "longitude": -101.014052,
      "city": "Hayes",
      "state": "SD",
      "county": "Stanley"
  },
  {
      "zipCode": 57538,
      "latitude": 43.114883,
      "longitude": -99.195945,
      "city": "Herrick",
      "state": "SD",
      "county": "Gregory"
  },
  {
      "zipCode": 57540,
      "latitude": 44.490558,
      "longitude": -99.589344,
      "city": "Holabird",
      "state": "SD",
      "county": "Hyde"
  },
  {
      "zipCode": 57541,
      "latitude": 43.382122,
      "longitude": -99.88231,
      "city": "Ideal",
      "state": "SD",
      "county": "Tripp"
  },
  {
      "zipCode": 57542,
      "latitude": 43.85862,
      "longitude": -99.824337,
      "city": "Iona",
      "state": "SD",
      "county": "Lyman"
  },
  {
      "zipCode": 57543,
      "latitude": 43.824178,
      "longitude": -101.389524,
      "city": "Kadoka",
      "state": "SD",
      "county": "Jackson"
  },
  {
      "zipCode": 57544,
      "latitude": 43.85862,
      "longitude": -99.824337,
      "city": "Kennebec",
      "state": "SD",
      "county": "Lyman"
  },
  {
      "zipCode": 57547,
      "latitude": 43.691966,
      "longitude": -101.601609,
      "city": "Long Valley",
      "state": "SD",
      "county": "Jackson"
  },
  {
      "zipCode": 57548,
      "latitude": 43.85862,
      "longitude": -99.824337,
      "city": "Lower Brule",
      "state": "SD",
      "county": "Lyman"
  },
  {
      "zipCode": 57551,
      "latitude": 43.172158,
      "longitude": -101.734068,
      "city": "Martin",
      "state": "SD",
      "county": "Bennett"
  },
  {
      "zipCode": 57552,
      "latitude": 44.370733,
      "longitude": -101.527178,
      "city": "Midland",
      "state": "SD",
      "county": "Haakon"
  },
  {
      "zipCode": 57553,
      "latitude": 44.370733,
      "longitude": -101.527178,
      "city": "Milesville",
      "state": "SD",
      "county": "Haakon"
  },
  {
      "zipCode": 57555,
      "latitude": 43.304572,
      "longitude": -100.662079,
      "city": "Mission",
      "state": "SD",
      "county": "Todd"
  },
  {
      "zipCode": 57557,
      "latitude": 44.475844,
      "longitude": -100.529517,
      "city": "Mission Ridge",
      "state": "SD",
      "county": "Stanley"
  },
  {
      "zipCode": 57559,
      "latitude": 43.879993,
      "longitude": -100.75734,
      "city": "Murdo",
      "state": "SD",
      "county": "Jones"
  },
  {
      "zipCode": 57560,
      "latitude": 43.623523,
      "longitude": -100.721218,
      "city": "Norris",
      "state": "SD",
      "county": "Mellette"
  },
  {
      "zipCode": 57562,
      "latitude": 43.938049,
      "longitude": -100.701672,
      "city": "Okaton",
      "state": "SD",
      "county": "Jones"
  },
  {
      "zipCode": 57563,
      "latitude": 43.194063,
      "longitude": -100.713373,
      "city": "Okreek",
      "state": "SD",
      "county": "Todd"
  },
  {
      "zipCode": 57564,
      "latitude": 44.722873,
      "longitude": -99.979546,
      "city": "Onida",
      "state": "SD",
      "county": "Sully"
  },
  {
      "zipCode": 57566,
      "latitude": 43.194063,
      "longitude": -100.713373,
      "city": "Parmelee",
      "state": "SD",
      "county": "Todd"
  },
  {
      "zipCode": 57567,
      "latitude": 44.046063,
      "longitude": -101.630722,
      "city": "Philip",
      "state": "SD",
      "county": "Haakon"
  },
  {
      "zipCode": 57568,
      "latitude": 43.908372,
      "longitude": -100.046855,
      "city": "Presho",
      "state": "SD",
      "county": "Lyman"
  },
  {
      "zipCode": 57569,
      "latitude": 43.85862,
      "longitude": -99.824337,
      "city": "Reliance",
      "state": "SD",
      "county": "Lyman"
  },
  {
      "zipCode": 57570,
      "latitude": 43.302375,
      "longitude": -100.626863,
      "city": "Rosebud",
      "state": "SD",
      "county": "Todd"
  },
  {
      "zipCode": 57571,
      "latitude": 43.248832,
      "longitude": -99.016376,
      "city": "Saint Charles",
      "state": "SD",
      "county": "Gregory"
  },
  {
      "zipCode": 57572,
      "latitude": 43.194063,
      "longitude": -100.713373,
      "city": "Saint Francis",
      "state": "SD",
      "county": "Todd"
  },
  {
      "zipCode": 57574,
      "latitude": 43.193203,
      "longitude": -101.66933,
      "city": "Tuthill",
      "state": "SD",
      "county": "Bennett"
  },
  {
      "zipCode": 57576,
      "latitude": 43.85862,
      "longitude": -99.824337,
      "city": "Vivian",
      "state": "SD",
      "county": "Lyman"
  },
  {
      "zipCode": 57577,
      "latitude": 43.691966,
      "longitude": -101.601609,
      "city": "Wanblee",
      "state": "SD",
      "county": "Jackson"
  },
  {
      "zipCode": 57578,
      "latitude": 43.382122,
      "longitude": -99.88231,
      "city": "Wewela",
      "state": "SD",
      "county": "Tripp"
  },
  {
      "zipCode": 57579,
      "latitude": 43.566116,
      "longitude": -100.745912,
      "city": "White River",
      "state": "SD",
      "county": "Mellette"
  },
  {
      "zipCode": 57580,
      "latitude": 43.331763,
      "longitude": -99.803286,
      "city": "Winner",
      "state": "SD",
      "county": "Tripp"
  },
  {
      "zipCode": 57584,
      "latitude": 43.382122,
      "longitude": -99.88231,
      "city": "Witten",
      "state": "SD",
      "county": "Tripp"
  },
  {
      "zipCode": 57585,
      "latitude": 43.623523,
      "longitude": -100.721218,
      "city": "Wood",
      "state": "SD",
      "county": "Mellette"
  },
  {
      "zipCode": 57601,
      "latitude": 45.550822,
      "longitude": -100.330283,
      "city": "Mobridge",
      "state": "SD",
      "county": "Walworth"
  },
  {
      "zipCode": 57620,
      "latitude": 45.409748,
      "longitude": -102.513087,
      "city": "Bison",
      "state": "SD",
      "county": "Perkins"
  },
  {
      "zipCode": 57621,
      "latitude": 45.847567,
      "longitude": -101.09974,
      "city": "Bullhead",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57622,
      "latitude": 44.992051,
      "longitude": -101.568656,
      "city": "Cherry Creek",
      "state": "SD",
      "county": "Ziebach"
  },
  {
      "zipCode": 57623,
      "latitude": 45.050456,
      "longitude": -101.607171,
      "city": "Dupree",
      "state": "SD",
      "county": "Ziebach"
  },
  {
      "zipCode": 57625,
      "latitude": 45.001611,
      "longitude": -101.232885,
      "city": "Eagle Butte",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57626,
      "latitude": 45.024533,
      "longitude": -102.026734,
      "city": "Faith",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57628,
      "latitude": 45.098685,
      "longitude": -100.879214,
      "city": "Firesteel",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57629,
      "latitude": 44.992051,
      "longitude": -101.568656,
      "city": "Glad Valley",
      "state": "SD",
      "county": "Ziebach"
  },
  {
      "zipCode": 57630,
      "latitude": 45.098685,
      "longitude": -100.879214,
      "city": "Glencross",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57631,
      "latitude": 45.504288,
      "longitude": -100.311701,
      "city": "Glenham",
      "state": "SD",
      "county": "Walworth"
  },
  {
      "zipCode": 57632,
      "latitude": 45.833733,
      "longitude": -100.020048,
      "city": "Herreid",
      "state": "SD",
      "county": "Campbell"
  },
  {
      "zipCode": 57633,
      "latitude": 45.396212,
      "longitude": -101.438525,
      "city": "Isabel",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57634,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Keldron",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57636,
      "latitude": 45.098685,
      "longitude": -100.879214,
      "city": "Lantry",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57638,
      "latitude": 45.732219,
      "longitude": -102.410975,
      "city": "Lemmon",
      "state": "SD",
      "county": "Perkins"
  },
  {
      "zipCode": 57639,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Little Eagle",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57640,
      "latitude": 45.703777,
      "longitude": -102.663011,
      "city": "Lodgepole",
      "state": "SD",
      "county": "Perkins"
  },
  {
      "zipCode": 57641,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Mc Intosh",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57642,
      "latitude": 45.876336,
      "longitude": -100.891469,
      "city": "Mc Laughlin",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57643,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Mahto",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57644,
      "latitude": 45.453221,
      "longitude": -102.280948,
      "city": "Meadow",
      "state": "SD",
      "county": "Perkins"
  },
  {
      "zipCode": 57645,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Morristown",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57646,
      "latitude": 45.715461,
      "longitude": -100.048071,
      "city": "Mound City",
      "state": "SD",
      "county": "Campbell"
  },
  {
      "zipCode": 57647,
      "latitude": 45.098685,
      "longitude": -100.879214,
      "city": "Parade",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57648,
      "latitude": 45.844431,
      "longitude": -100.283026,
      "city": "Pollock",
      "state": "SD",
      "county": "Campbell"
  },
  {
      "zipCode": 57649,
      "latitude": 45.440744,
      "longitude": -102.704216,
      "city": "Prairie City",
      "state": "SD",
      "county": "Perkins"
  },
  {
      "zipCode": 57650,
      "latitude": 45.578941,
      "longitude": -103.493668,
      "city": "Ralph",
      "state": "SD",
      "county": "Harding"
  },
  {
      "zipCode": 57651,
      "latitude": 45.578941,
      "longitude": -103.493668,
      "city": "Reva",
      "state": "SD",
      "county": "Harding"
  },
  {
      "zipCode": 57652,
      "latitude": 45.098685,
      "longitude": -100.879214,
      "city": "Ridgeview",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57653,
      "latitude": 45.680798,
      "longitude": -102.292753,
      "city": "Shadehill",
      "state": "SD",
      "county": "Perkins"
  },
  {
      "zipCode": 57656,
      "latitude": 45.427032,
      "longitude": -101.07469,
      "city": "Timber Lake",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57657,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Trail City",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57658,
      "latitude": 45.655452,
      "longitude": -100.537928,
      "city": "Wakpala",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57659,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Walker",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57660,
      "latitude": 45.708402,
      "longitude": -101.154581,
      "city": "Watauga",
      "state": "SD",
      "county": "Corson"
  },
  {
      "zipCode": 57661,
      "latitude": 45.098685,
      "longitude": -100.879214,
      "city": "Whitehorse",
      "state": "SD",
      "county": "Dewey"
  },
  {
      "zipCode": 57701,
      "latitude": 44.004363,
      "longitude": -103.240024,
      "city": "Rapid City",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57702,
      "latitude": 44.003641,
      "longitude": -103.358868,
      "city": "Rapid City",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57703,
      "latitude": 44.048039,
      "longitude": -103.176287,
      "city": "Rapid City",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57706,
      "latitude": 44.185222,
      "longitude": -103.229087,
      "city": "Ellsworth Afb",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57709,
      "latitude": 44.076106,
      "longitude": -103.317149,
      "city": "Rapid City",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57714,
      "latitude": 43.193203,
      "longitude": -101.66933,
      "city": "Allen",
      "state": "SD",
      "county": "Bennett"
  },
  {
      "zipCode": 57716,
      "latitude": 43.352617,
      "longitude": -102.5411,
      "city": "Batesland",
      "state": "SD",
      "county": "Shannon"
  },
  {
      "zipCode": 57717,
      "latitude": 44.93037,
      "longitude": -103.87313,
      "city": "Belle Fourche",
      "state": "SD",
      "county": "Butte"
  },
  {
      "zipCode": 57718,
      "latitude": 44.183216,
      "longitude": -103.326118,
      "city": "Black Hawk",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57719,
      "latitude": 44.03667,
      "longitude": -103.204293,
      "city": "Box Elder",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57720,
      "latitude": 45.576652,
      "longitude": -103.911719,
      "city": "Buffalo",
      "state": "SD",
      "county": "Harding"
  },
  {
      "zipCode": 57722,
      "latitude": 43.666816,
      "longitude": -103.371071,
      "city": "Buffalo Gap",
      "state": "SD",
      "county": "Custer"
  },
  {
      "zipCode": 57724,
      "latitude": 45.578941,
      "longitude": -103.493668,
      "city": "Camp Crook",
      "state": "SD",
      "county": "Harding"
  },
  {
      "zipCode": 57725,
      "latitude": 43.948063,
      "longitude": -102.793461,
      "city": "Caputa",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57729,
      "latitude": 44.312407,
      "longitude": -102.150335,
      "city": "Creighton",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57730,
      "latitude": 43.657304,
      "longitude": -103.425807,
      "city": "Custer",
      "state": "SD",
      "county": "Custer"
  },
  {
      "zipCode": 57732,
      "latitude": 44.36485,
      "longitude": -103.735731,
      "city": "Deadwood",
      "state": "SD",
      "county": "Lawrence"
  },
  {
      "zipCode": 57735,
      "latitude": 43.294549,
      "longitude": -103.830431,
      "city": "Edgemont",
      "state": "SD",
      "county": "Fall River"
  },
  {
      "zipCode": 57736,
      "latitude": 44.348777,
      "longitude": -102.52386,
      "city": "Elm Springs",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57737,
      "latitude": 44.589806,
      "longitude": -102.787188,
      "city": "Enning",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57738,
      "latitude": 43.685927,
      "longitude": -103.20817,
      "city": "Fairburn",
      "state": "SD",
      "county": "Custer"
  },
  {
      "zipCode": 57741,
      "latitude": 44.40932,
      "longitude": -103.455428,
      "city": "Fort Meade",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57742,
      "latitude": 44.66913,
      "longitude": -103.697073,
      "city": "Fruitdale",
      "state": "SD",
      "county": "Butte"
  },
  {
      "zipCode": 57744,
      "latitude": 43.838528,
      "longitude": -103.19146,
      "city": "Hermosa",
      "state": "SD",
      "county": "Custer"
  },
  {
      "zipCode": 57745,
      "latitude": 44.0082,
      "longitude": -103.651357,
      "city": "Hill City",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57747,
      "latitude": 43.314186,
      "longitude": -103.528353,
      "city": "Hot Springs",
      "state": "SD",
      "county": "Fall River"
  },
  {
      "zipCode": 57748,
      "latitude": 44.589806,
      "longitude": -102.787188,
      "city": "Howes",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57750,
      "latitude": 43.691966,
      "longitude": -101.601609,
      "city": "Interior",
      "state": "SD",
      "county": "Jackson"
  },
  {
      "zipCode": 57751,
      "latitude": 43.884764,
      "longitude": -103.399471,
      "city": "Keystone",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57752,
      "latitude": 43.352617,
      "longitude": -102.5411,
      "city": "Kyle",
      "state": "SD",
      "county": "Shannon"
  },
  {
      "zipCode": 57754,
      "latitude": 44.444505,
      "longitude": -103.752683,
      "city": "Lead",
      "state": "SD",
      "county": "Lawrence"
  },
  {
      "zipCode": 57755,
      "latitude": 45.578941,
      "longitude": -103.493668,
      "city": "Ludlow",
      "state": "SD",
      "county": "Harding"
  },
  {
      "zipCode": 57756,
      "latitude": 43.352617,
      "longitude": -102.5411,
      "city": "Manderson",
      "state": "SD",
      "county": "Shannon"
  },
  {
      "zipCode": 57758,
      "latitude": 44.589806,
      "longitude": -102.787188,
      "city": "Mud Butte",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57759,
      "latitude": 44.372688,
      "longitude": -103.754204,
      "city": "Nemo",
      "state": "SD",
      "county": "Lawrence"
  },
  {
      "zipCode": 57760,
      "latitude": 44.715217,
      "longitude": -103.417574,
      "city": "Newell",
      "state": "SD",
      "county": "Butte"
  },
  {
      "zipCode": 57761,
      "latitude": 44.056691,
      "longitude": -102.79897,
      "city": "New Underwood",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57762,
      "latitude": 44.673533,
      "longitude": -103.556354,
      "city": "Nisland",
      "state": "SD",
      "county": "Butte"
  },
  {
      "zipCode": 57763,
      "latitude": 43.180956,
      "longitude": -103.243935,
      "city": "Oelrichs",
      "state": "SD",
      "county": "Fall River"
  },
  {
      "zipCode": 57764,
      "latitude": 43.352617,
      "longitude": -102.5411,
      "city": "Oglala",
      "state": "SD",
      "county": "Shannon"
  },
  {
      "zipCode": 57765,
      "latitude": 44.589806,
      "longitude": -102.787188,
      "city": "Opal",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57766,
      "latitude": 43.239655,
      "longitude": -103.527757,
      "city": "Oral",
      "state": "SD",
      "county": "Fall River"
  },
  {
      "zipCode": 57767,
      "latitude": 44.067413,
      "longitude": -102.680781,
      "city": "Owanka",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57769,
      "latitude": 44.220524,
      "longitude": -103.272284,
      "city": "Piedmont",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57770,
      "latitude": 43.024319,
      "longitude": -102.535178,
      "city": "Pine Ridge",
      "state": "SD",
      "county": "Shannon"
  },
  {
      "zipCode": 57772,
      "latitude": 43.352617,
      "longitude": -102.5411,
      "city": "Porcupine",
      "state": "SD",
      "county": "Shannon"
  },
  {
      "zipCode": 57773,
      "latitude": 43.666816,
      "longitude": -103.371071,
      "city": "Pringle",
      "state": "SD",
      "county": "Custer"
  },
  {
      "zipCode": 57774,
      "latitude": 43.239655,
      "longitude": -103.527757,
      "city": "Provo",
      "state": "SD",
      "county": "Fall River"
  },
  {
      "zipCode": 57775,
      "latitude": 44.068731,
      "longitude": -102.222633,
      "city": "Quinn",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57776,
      "latitude": 45.578941,
      "longitude": -103.493668,
      "city": "Redig",
      "state": "SD",
      "county": "Harding"
  },
  {
      "zipCode": 57777,
      "latitude": 44.691005,
      "longitude": -102.587048,
      "city": "Red Owl",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57778,
      "latitude": 44.117606,
      "longitude": -103.761058,
      "city": "Rochford",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57779,
      "latitude": 44.547307,
      "longitude": -103.723214,
      "city": "Saint Onge",
      "state": "SD",
      "county": "Lawrence"
  },
  {
      "zipCode": 57780,
      "latitude": 43.788416,
      "longitude": -102.346797,
      "city": "Scenic",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57782,
      "latitude": 43.239655,
      "longitude": -103.527757,
      "city": "Smithwick",
      "state": "SD",
      "county": "Fall River"
  },
  {
      "zipCode": 57783,
      "latitude": 44.467137,
      "longitude": -103.772331,
      "city": "Spearfish",
      "state": "SD",
      "county": "Lawrence"
  },
  {
      "zipCode": 57785,
      "latitude": 44.369172,
      "longitude": -103.361658,
      "city": "Sturgis",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57787,
      "latitude": 44.653923,
      "longitude": -102.725189,
      "city": "Union Center",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57788,
      "latitude": 44.719176,
      "longitude": -103.42562,
      "city": "Vale",
      "state": "SD",
      "county": "Butte"
  },
  {
      "zipCode": 57790,
      "latitude": 44.013772,
      "longitude": -102.238397,
      "city": "Wall",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57791,
      "latitude": 44.016848,
      "longitude": -102.59866,
      "city": "Wasta",
      "state": "SD",
      "county": "Pennington"
  },
  {
      "zipCode": 57792,
      "latitude": 44.589806,
      "longitude": -102.787188,
      "city": "White Owl",
      "state": "SD",
      "county": "Meade"
  },
  {
      "zipCode": 57793,
      "latitude": 44.46498,
      "longitude": -103.644136,
      "city": "Whitewood",
      "state": "SD",
      "county": "Lawrence"
  },
  {
      "zipCode": 57794,
      "latitude": 43.352617,
      "longitude": -102.5411,
      "city": "Wounded Knee",
      "state": "SD",
      "county": "Shannon"
  },
  {
      "zipCode": 57799,
      "latitude": 44.495846,
      "longitude": -103.870251,
      "city": "Spearfish",
      "state": "SD",
      "county": "Lawrence"
  },
  {
      "zipCode": 58001,
      "latitude": 46.447936,
      "longitude": -96.727788,
      "city": "Abercrombie",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58002,
      "latitude": 46.859694,
      "longitude": -97.214587,
      "city": "Absaraka",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58004,
      "latitude": 47.021032,
      "longitude": -97.271545,
      "city": "Amenia",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58005,
      "latitude": 47.057026,
      "longitude": -96.975162,
      "city": "Argusville",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58006,
      "latitude": 47.102334,
      "longitude": -97.140334,
      "city": "Arthur",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58007,
      "latitude": 47.01392,
      "longitude": -97.462894,
      "city": "Ayr",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58008,
      "latitude": 46.273086,
      "longitude": -96.864373,
      "city": "Barney",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58009,
      "latitude": 47.454888,
      "longitude": -97.151692,
      "city": "Blanchard",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58011,
      "latitude": 46.920016,
      "longitude": -97.557491,
      "city": "Buffalo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58012,
      "latitude": 47.050272,
      "longitude": -97.062647,
      "city": "Casselton",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58013,
      "latitude": 46.062144,
      "longitude": -97.529438,
      "city": "Cayuga",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58014,
      "latitude": 46.775027,
      "longitude": -97.352437,
      "city": "Chaffee",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58015,
      "latitude": 46.593582,
      "longitude": -96.809354,
      "city": "Christine",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58016,
      "latitude": 47.454888,
      "longitude": -97.151692,
      "city": "Clifford",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58017,
      "latitude": 46.06593,
      "longitude": -97.820733,
      "city": "Cogswell",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58018,
      "latitude": 46.457386,
      "longitude": -96.873653,
      "city": "Colfax",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58021,
      "latitude": 46.731743,
      "longitude": -97.063137,
      "city": "Davenport",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58027,
      "latitude": 46.456337,
      "longitude": -97.777909,
      "city": "Enderlin",
      "state": "ND",
      "county": "Ransom"
  },
  {
      "zipCode": 58029,
      "latitude": 47.115505,
      "longitude": -97.377138,
      "city": "Erie",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58030,
      "latitude": 46.094599,
      "longitude": -96.907594,
      "city": "Fairmount",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58031,
      "latitude": 46.775099,
      "longitude": -97.797553,
      "city": "Fingal",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58032,
      "latitude": 46.058634,
      "longitude": -97.633282,
      "city": "Forman",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58033,
      "latitude": 46.449387,
      "longitude": -97.909114,
      "city": "Fort Ransom",
      "state": "ND",
      "county": "Ransom"
  },
  {
      "zipCode": 58035,
      "latitude": 47.270447,
      "longitude": -97.408156,
      "city": "Galesburg",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58036,
      "latitude": 47.157949,
      "longitude": -96.957752,
      "city": "Gardner",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58038,
      "latitude": 47.072418,
      "longitude": -96.96915,
      "city": "Grandin",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58039,
      "latitude": 46.160427,
      "longitude": -96.843465,
      "city": "Great Bend",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58040,
      "latitude": 46.152725,
      "longitude": -97.767735,
      "city": "Gwinner",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58041,
      "latitude": 46.073423,
      "longitude": -96.92473,
      "city": "Hankinson",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58042,
      "latitude": 46.956511,
      "longitude": -96.965445,
      "city": "Harwood",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58043,
      "latitude": 45.978943,
      "longitude": -97.54423,
      "city": "Havana",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58045,
      "latitude": 47.383461,
      "longitude": -97.060324,
      "city": "Hillsboro",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58046,
      "latitude": 47.323726,
      "longitude": -97.713371,
      "city": "Hope",
      "state": "ND",
      "county": "Steele"
  },
  {
      "zipCode": 58047,
      "latitude": 46.710147,
      "longitude": -96.88506,
      "city": "Horace",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58048,
      "latitude": 46.981541,
      "longitude": -97.150669,
      "city": "Hunter",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58049,
      "latitude": 46.717679,
      "longitude": -97.976311,
      "city": "Kathryn",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58051,
      "latitude": 46.673526,
      "longitude": -97.050584,
      "city": "Kindred",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58052,
      "latitude": 46.765575,
      "longitude": -97.294037,
      "city": "Leonard",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58053,
      "latitude": 46.191782,
      "longitude": -96.994919,
      "city": "Lidgerwood",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58054,
      "latitude": 46.455974,
      "longitude": -97.636917,
      "city": "Lisbon",
      "state": "ND",
      "county": "Ransom"
  },
  {
      "zipCode": 58056,
      "latitude": 47.45565,
      "longitude": -97.718955,
      "city": "Luverne",
      "state": "ND",
      "county": "Steele"
  },
  {
      "zipCode": 58057,
      "latitude": 46.443826,
      "longitude": -97.343373,
      "city": "Mcleod",
      "state": "ND",
      "county": "Ransom"
  },
  {
      "zipCode": 58058,
      "latitude": 46.16038,
      "longitude": -96.969366,
      "city": "Mantador",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58059,
      "latitude": 46.861424,
      "longitude": -97.115726,
      "city": "Mapleton",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58060,
      "latitude": 46.200657,
      "longitude": -97.477179,
      "city": "Milnor",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58061,
      "latitude": 46.315035,
      "longitude": -96.861092,
      "city": "Mooreton",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58062,
      "latitude": 46.680795,
      "longitude": -97.786984,
      "city": "Nome",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58063,
      "latitude": 46.964791,
      "longitude": -97.789289,
      "city": "Oriska",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58064,
      "latitude": 47.000029,
      "longitude": -97.515646,
      "city": "Page",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58065,
      "latitude": 46.650741,
      "longitude": -98.195722,
      "city": "Pillsbury",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58067,
      "latitude": 46.036974,
      "longitude": -97.431104,
      "city": "Rutland",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58068,
      "latitude": 46.521481,
      "longitude": -97.416257,
      "city": "Sheldon",
      "state": "ND",
      "county": "Ransom"
  },
  {
      "zipCode": 58069,
      "latitude": 46.221613,
      "longitude": -97.62307,
      "city": "Stirum",
      "state": "ND",
      "county": "Sargent"
  },
  {
      "zipCode": 58071,
      "latitude": 46.949236,
      "longitude": -97.626101,
      "city": "Tower City",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58072,
      "latitude": 46.972286,
      "longitude": -98.007534,
      "city": "Valley City",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58074,
      "latitude": 46.283291,
      "longitude": -96.917614,
      "city": "Wahpeton",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58075,
      "latitude": 46.336591,
      "longitude": -96.792057,
      "city": "Wahpeton",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58076,
      "latitude": 46.271839,
      "longitude": -96.608142,
      "city": "Wahpeton",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58077,
      "latitude": 46.445916,
      "longitude": -97.013693,
      "city": "Walcott",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58078,
      "latitude": 46.890692,
      "longitude": -96.925828,
      "city": "West Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58079,
      "latitude": 46.832617,
      "longitude": -97.346067,
      "city": "Wheatland",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58081,
      "latitude": 46.384021,
      "longitude": -97.11408,
      "city": "Wyndmere",
      "state": "ND",
      "county": "Richland"
  },
  {
      "zipCode": 58102,
      "latitude": 46.92536,
      "longitude": -96.990615,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58103,
      "latitude": 46.832152,
      "longitude": -96.825841,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58104,
      "latitude": 46.781589,
      "longitude": -96.884106,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58105,
      "latitude": 46.9414,
      "longitude": -96.967371,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58106,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58107,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58108,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58109,
      "latitude": 46.82352,
      "longitude": -96.8148,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58121,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58122,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58123,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58124,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58125,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58126,
      "latitude": 46.934596,
      "longitude": -97.229718,
      "city": "Fargo",
      "state": "ND",
      "county": "Cass"
  },
  {
      "zipCode": 58201,
      "latitude": 47.9041,
      "longitude": -97.431501,
      "city": "Grand Forks",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58202,
      "latitude": 47.933481,
      "longitude": -97.394446,
      "city": "Grand Forks",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58203,
      "latitude": 47.892243,
      "longitude": -97.301297,
      "city": "Grand Forks",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58204,
      "latitude": 47.967909,
      "longitude": -97.367534,
      "city": "Grand Forks Afb",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58205,
      "latitude": 47.933481,
      "longitude": -97.394446,
      "city": "Grand Forks Afb",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58206,
      "latitude": 47.933481,
      "longitude": -97.394446,
      "city": "Grand Forks",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58207,
      "latitude": 47.933481,
      "longitude": -97.394446,
      "city": "Grand Forks",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58208,
      "latitude": 47.933481,
      "longitude": -97.394446,
      "city": "Grand Forks",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58210,
      "latitude": 48.383547,
      "longitude": -97.997964,
      "city": "Adams",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58212,
      "latitude": 47.766493,
      "longitude": -98.043481,
      "city": "Aneta",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58213,
      "latitude": 48.237795,
      "longitude": -97.275528,
      "city": "Ardoch",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58214,
      "latitude": 47.925956,
      "longitude": -97.494107,
      "city": "Arvilla",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58216,
      "latitude": 48.874863,
      "longitude": -97.42543,
      "city": "Bathgate",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58218,
      "latitude": 47.602249,
      "longitude": -97.099519,
      "city": "Buxton",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58219,
      "latitude": 47.454888,
      "longitude": -97.151692,
      "city": "Caledonia",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58220,
      "latitude": 48.738902,
      "longitude": -97.699122,
      "city": "Cavalier",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58222,
      "latitude": 48.6449,
      "longitude": -97.633342,
      "city": "Crystal",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58223,
      "latitude": 47.454888,
      "longitude": -97.151692,
      "city": "Cummings",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58224,
      "latitude": 48.165871,
      "longitude": -97.957485,
      "city": "Dahlen",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58225,
      "latitude": 48.668146,
      "longitude": -97.362726,
      "city": "Drayton",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58227,
      "latitude": 48.499712,
      "longitude": -97.893218,
      "city": "Edinburg",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58228,
      "latitude": 47.889676,
      "longitude": -97.280993,
      "city": "Emerado",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58229,
      "latitude": 48.380603,
      "longitude": -98.054828,
      "city": "Fairdale",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58230,
      "latitude": 47.51207,
      "longitude": -97.83707,
      "city": "Finley",
      "state": "ND",
      "county": "Steele"
  },
  {
      "zipCode": 58231,
      "latitude": 48.281947,
      "longitude": -97.827805,
      "city": "Fordville",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58233,
      "latitude": 48.259974,
      "longitude": -97.522485,
      "city": "Forest River",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58235,
      "latitude": 48.085827,
      "longitude": -97.514081,
      "city": "Gilby",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58236,
      "latitude": 48.720149,
      "longitude": -97.469623,
      "city": "Glasston",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58237,
      "latitude": 48.368876,
      "longitude": -97.437265,
      "city": "Grafton",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58238,
      "latitude": 48.778717,
      "longitude": -97.449975,
      "city": "Hamilton",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58239,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Hannah",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58240,
      "latitude": 47.638521,
      "longitude": -97.459319,
      "city": "Hatton",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58241,
      "latitude": 48.720338,
      "longitude": -97.699287,
      "city": "Hensel",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58243,
      "latitude": 48.490539,
      "longitude": -97.677015,
      "city": "Hoople",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58244,
      "latitude": 48.107863,
      "longitude": -97.683056,
      "city": "Inkster",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58249,
      "latitude": 48.753679,
      "longitude": -98.288629,
      "city": "Langdon",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58250,
      "latitude": 48.282007,
      "longitude": -98.076663,
      "city": "Lankin",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58251,
      "latitude": 47.911531,
      "longitude": -97.626389,
      "city": "Larimore",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58254,
      "latitude": 47.856367,
      "longitude": -98.134365,
      "city": "Mcville",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58255,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Maida",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58256,
      "latitude": 48.107279,
      "longitude": -97.28791,
      "city": "Manvel",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58257,
      "latitude": 47.487262,
      "longitude": -97.345104,
      "city": "Mayville",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58258,
      "latitude": 48.007368,
      "longitude": -97.430451,
      "city": "Mekinock",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58259,
      "latitude": 48.020355,
      "longitude": -98.118574,
      "city": "Michigan",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58260,
      "latitude": 48.624861,
      "longitude": -98.042871,
      "city": "Milton",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58261,
      "latitude": 48.25997,
      "longitude": -97.314367,
      "city": "Minto",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58262,
      "latitude": 48.693099,
      "longitude": -97.884115,
      "city": "Mountain",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58265,
      "latitude": 48.943793,
      "longitude": -97.512602,
      "city": "Neche",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58266,
      "latitude": 48.015728,
      "longitude": -97.817106,
      "city": "Niagara",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58267,
      "latitude": 47.759107,
      "longitude": -97.603773,
      "city": "Northwood",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58269,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Osnabrock",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58270,
      "latitude": 48.420411,
      "longitude": -97.774812,
      "city": "Park River",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58271,
      "latitude": 48.85897,
      "longitude": -97.288585,
      "city": "Pembina",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58272,
      "latitude": 48.00598,
      "longitude": -98.000193,
      "city": "Petersburg",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58273,
      "latitude": 48.323898,
      "longitude": -97.81439,
      "city": "Pisek",
      "state": "ND",
      "county": "Walsh"
  },
  {
      "zipCode": 58274,
      "latitude": 47.582141,
      "longitude": -97.244353,
      "city": "Portland",
      "state": "ND",
      "county": "Traill"
  },
  {
      "zipCode": 58275,
      "latitude": 47.872332,
      "longitude": -97.236128,
      "city": "Reynolds",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58276,
      "latitude": 48.630369,
      "longitude": -97.471056,
      "city": "Saint Thomas",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58277,
      "latitude": 47.45565,
      "longitude": -97.718955,
      "city": "Sharon",
      "state": "ND",
      "county": "Steele"
  },
  {
      "zipCode": 58278,
      "latitude": 47.785848,
      "longitude": -97.34703,
      "city": "Thompson",
      "state": "ND",
      "county": "Grand Forks"
  },
  {
      "zipCode": 58281,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Wales",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58282,
      "latitude": 48.880795,
      "longitude": -97.797358,
      "city": "Walhalla",
      "state": "ND",
      "county": "Pembina"
  },
  {
      "zipCode": 58301,
      "latitude": 48.264352,
      "longitude": -98.781979,
      "city": "Devils Lake",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58310,
      "latitude": 48.770913,
      "longitude": -99.772702,
      "city": "Agate",
      "state": "ND",
      "county": "Rolette"
  },
  {
      "zipCode": 58311,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Alsen",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58313,
      "latitude": 48.104177,
      "longitude": -99.992145,
      "city": "Balta",
      "state": "ND",
      "county": "Pierce"
  },
  {
      "zipCode": 58316,
      "latitude": 48.777557,
      "longitude": -99.799919,
      "city": "Belcourt",
      "state": "ND",
      "county": "Rolette"
  },
  {
      "zipCode": 58317,
      "latitude": 48.58807,
      "longitude": -99.23173,
      "city": "Bisbee",
      "state": "ND",
      "county": "Towner"
  },
  {
      "zipCode": 58318,
      "latitude": 48.830347,
      "longitude": -100.601174,
      "city": "Bottineau",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58319,
      "latitude": 47.663508,
      "longitude": -99.664406,
      "city": "Bremen",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58320,
      "latitude": 48.28641,
      "longitude": -99.404705,
      "city": "Brinsmade",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58321,
      "latitude": 48.238222,
      "longitude": -98.400599,
      "city": "Brocket",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58323,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Calvin",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58324,
      "latitude": 48.500121,
      "longitude": -99.231923,
      "city": "Cando",
      "state": "ND",
      "county": "Towner"
  },
  {
      "zipCode": 58325,
      "latitude": 48.29,
      "longitude": -99.070499,
      "city": "Churchs Ferry",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58327,
      "latitude": 48.091012,
      "longitude": -98.573116,
      "city": "Crary",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58329,
      "latitude": 48.856083,
      "longitude": -100.024102,
      "city": "Dunseith",
      "state": "ND",
      "county": "Rolette"
  },
  {
      "zipCode": 58330,
      "latitude": 48.422614,
      "longitude": -98.49963,
      "city": "Edmore",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58331,
      "latitude": 48.652908,
      "longitude": -99.123224,
      "city": "Egeland",
      "state": "ND",
      "county": "Towner"
  },
  {
      "zipCode": 58332,
      "latitude": 48.059736,
      "longitude": -99.538336,
      "city": "Esmond",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58335,
      "latitude": 48.108758,
      "longitude": -99.186842,
      "city": "Fort Totten",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58337,
      "latitude": 47.587372,
      "longitude": -99.667737,
      "city": "Hamberg",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58338,
      "latitude": 48.499953,
      "longitude": -98.644371,
      "city": "Hampden",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58339,
      "latitude": 48.900063,
      "longitude": -99.427769,
      "city": "Hansboro",
      "state": "ND",
      "county": "Towner"
  },
  {
      "zipCode": 58341,
      "latitude": 47.703539,
      "longitude": -99.747755,
      "city": "Harvey",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58343,
      "latitude": 48.301215,
      "longitude": -99.689358,
      "city": "Knox",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58344,
      "latitude": 47.971966,
      "longitude": -98.324631,
      "city": "Lakota",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58345,
      "latitude": 48.275146,
      "longitude": -98.438346,
      "city": "Lawton",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58346,
      "latitude": 48.165312,
      "longitude": -99.432466,
      "city": "Leeds",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58348,
      "latitude": 47.999093,
      "longitude": -99.535801,
      "city": "Maddock",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58351,
      "latitude": 48.130016,
      "longitude": -99.38981,
      "city": "Minnewaukan",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58352,
      "latitude": 48.66937,
      "longitude": -98.835465,
      "city": "Munich",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58353,
      "latitude": 48.772022,
      "longitude": -99.838063,
      "city": "Mylo",
      "state": "ND",
      "county": "Rolette"
  },
  {
      "zipCode": 58355,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Nekoma",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58356,
      "latitude": 47.71736,
      "longitude": -98.898693,
      "city": "New Rockford",
      "state": "ND",
      "county": "Eddy"
  },
  {
      "zipCode": 58357,
      "latitude": 47.949694,
      "longitude": -99.133201,
      "city": "Oberon",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58359,
      "latitude": 48.089537,
      "longitude": -100.164357,
      "city": "Orrin",
      "state": "ND",
      "county": "Pierce"
  },
  {
      "zipCode": 58361,
      "latitude": 47.798489,
      "longitude": -98.364994,
      "city": "Pekin",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58362,
      "latitude": 48.275494,
      "longitude": -99.070415,
      "city": "Penn",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58363,
      "latitude": 48.754621,
      "longitude": -99.438351,
      "city": "Perth",
      "state": "ND",
      "county": "Towner"
  },
  {
      "zipCode": 58365,
      "latitude": 48.844548,
      "longitude": -99.26309,
      "city": "Rocklake",
      "state": "ND",
      "county": "Towner"
  },
  {
      "zipCode": 58366,
      "latitude": 48.700886,
      "longitude": -99.878456,
      "city": "Rolette",
      "state": "ND",
      "county": "Rolette"
  },
  {
      "zipCode": 58367,
      "latitude": 48.826826,
      "longitude": -99.786546,
      "city": "Rolla",
      "state": "ND",
      "county": "Rolette"
  },
  {
      "zipCode": 58368,
      "latitude": 48.254504,
      "longitude": -99.989912,
      "city": "Rugby",
      "state": "ND",
      "county": "Pierce"
  },
  {
      "zipCode": 58369,
      "latitude": 48.880498,
      "longitude": -99.734073,
      "city": "Saint John",
      "state": "ND",
      "county": "Rolette"
  },
  {
      "zipCode": 58370,
      "latitude": 47.985595,
      "longitude": -98.849264,
      "city": "Saint Michael",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58372,
      "latitude": 48.77178,
      "longitude": -98.464102,
      "city": "Sarles",
      "state": "ND",
      "county": "Cavalier"
  },
  {
      "zipCode": 58374,
      "latitude": 47.761272,
      "longitude": -98.90997,
      "city": "Sheyenne",
      "state": "ND",
      "county": "Eddy"
  },
  {
      "zipCode": 58377,
      "latitude": 48.449321,
      "longitude": -98.760596,
      "city": "Starkweather",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58379,
      "latitude": 48.108758,
      "longitude": -99.186842,
      "city": "Tokio",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58380,
      "latitude": 47.846213,
      "longitude": -98.451175,
      "city": "Tolna",
      "state": "ND",
      "county": "Nelson"
  },
  {
      "zipCode": 58381,
      "latitude": 47.910133,
      "longitude": -98.75889,
      "city": "Warwick",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58382,
      "latitude": 48.317012,
      "longitude": -98.800481,
      "city": "Webster",
      "state": "ND",
      "county": "Ramsey"
  },
  {
      "zipCode": 58384,
      "latitude": 48.660609,
      "longitude": -100.352409,
      "city": "Willow City",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58385,
      "latitude": 48.465282,
      "longitude": -99.645681,
      "city": "Wolford",
      "state": "ND",
      "county": "Pierce"
  },
  {
      "zipCode": 58386,
      "latitude": 48.256479,
      "longitude": -99.602542,
      "city": "York",
      "state": "ND",
      "county": "Benson"
  },
  {
      "zipCode": 58401,
      "latitude": 46.921005,
      "longitude": -98.745485,
      "city": "Jamestown",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58402,
      "latitude": 46.97882,
      "longitude": -98.960032,
      "city": "Jamestown",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58405,
      "latitude": 46.913344,
      "longitude": -98.699553,
      "city": "Jamestown",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58413,
      "latitude": 46.056209,
      "longitude": -99.285013,
      "city": "Ashley",
      "state": "ND",
      "county": "Mcintosh"
  },
  {
      "zipCode": 58415,
      "latitude": 46.362715,
      "longitude": -98.473702,
      "city": "Berlin",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58416,
      "latitude": 47.489506,
      "longitude": -98.410133,
      "city": "Binford",
      "state": "ND",
      "county": "Griggs"
  },
  {
      "zipCode": 58418,
      "latitude": 47.457667,
      "longitude": -99.649702,
      "city": "Bowdon",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58420,
      "latitude": 47.005084,
      "longitude": -98.900709,
      "city": "Buchanan",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58421,
      "latitude": 47.457278,
      "longitude": -98.893314,
      "city": "Carrington",
      "state": "ND",
      "county": "Foster"
  },
  {
      "zipCode": 58422,
      "latitude": 47.628946,
      "longitude": -99.404936,
      "city": "Cathay",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58423,
      "latitude": 47.464366,
      "longitude": -99.830601,
      "city": "Chaseley",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58424,
      "latitude": 46.893112,
      "longitude": -99.128846,
      "city": "Cleveland",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58425,
      "latitude": 47.366922,
      "longitude": -98.295699,
      "city": "Cooperstown",
      "state": "ND",
      "county": "Griggs"
  },
  {
      "zipCode": 58426,
      "latitude": 47.196576,
      "longitude": -98.594244,
      "city": "Courtenay",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58428,
      "latitude": 46.806728,
      "longitude": -99.772396,
      "city": "Dawson",
      "state": "ND",
      "county": "Kidder"
  },
  {
      "zipCode": 58429,
      "latitude": 47.182819,
      "longitude": -98.161621,
      "city": "Dazey",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58430,
      "latitude": 47.529679,
      "longitude": -100.271687,
      "city": "Denhoff",
      "state": "ND",
      "county": "Sheridan"
  },
  {
      "zipCode": 58431,
      "latitude": 46.47261,
      "longitude": -98.54261,
      "city": "Dickey",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58432,
      "latitude": 46.892518,
      "longitude": -98.375536,
      "city": "Eckelson",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58433,
      "latitude": 46.41198,
      "longitude": -98.713086,
      "city": "Edgeley",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58436,
      "latitude": 46.106141,
      "longitude": -98.620707,
      "city": "Ellendale",
      "state": "ND",
      "county": "Dickey"
  },
  {
      "zipCode": 58438,
      "latitude": 47.625048,
      "longitude": -99.564274,
      "city": "Fessenden",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58439,
      "latitude": 46.011902,
      "longitude": -98.848644,
      "city": "Forbes",
      "state": "ND",
      "county": "Dickey"
  },
  {
      "zipCode": 58440,
      "latitude": 46.37014,
      "longitude": -99.173337,
      "city": "Fredonia",
      "state": "ND",
      "county": "Logan"
  },
  {
      "zipCode": 58441,
      "latitude": 46.175992,
      "longitude": -98.379846,
      "city": "Fullerton",
      "state": "ND",
      "county": "Dickey"
  },
  {
      "zipCode": 58442,
      "latitude": 46.522452,
      "longitude": -99.173063,
      "city": "Gackle",
      "state": "ND",
      "county": "Logan"
  },
  {
      "zipCode": 58443,
      "latitude": 47.456948,
      "longitude": -98.691563,
      "city": "Glenfield",
      "state": "ND",
      "county": "Foster"
  },
  {
      "zipCode": 58444,
      "latitude": 47.479529,
      "longitude": -100.14659,
      "city": "Goodrich",
      "state": "ND",
      "county": "Sheridan"
  },
  {
      "zipCode": 58445,
      "latitude": 47.457057,
      "longitude": -98.702131,
      "city": "Grace City",
      "state": "ND",
      "county": "Foster"
  },
  {
      "zipCode": 58448,
      "latitude": 47.378619,
      "longitude": -98.350201,
      "city": "Hannaford",
      "state": "ND",
      "county": "Griggs"
  },
  {
      "zipCode": 58451,
      "latitude": 47.472804,
      "longitude": -99.937233,
      "city": "Hurdsfield",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58452,
      "latitude": 47.456477,
      "longitude": -98.230415,
      "city": "Jessie",
      "state": "ND",
      "county": "Griggs"
  },
  {
      "zipCode": 58454,
      "latitude": 46.551079,
      "longitude": -98.86671,
      "city": "Jud",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58455,
      "latitude": 47.240135,
      "longitude": -98.753458,
      "city": "Kensal",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58456,
      "latitude": 46.391635,
      "longitude": -98.603141,
      "city": "Kulm",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58458,
      "latitude": 46.384379,
      "longitude": -98.358671,
      "city": "Lamoure",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58460,
      "latitude": 46.140661,
      "longitude": -99.378147,
      "city": "Lehr",
      "state": "ND",
      "county": "Mcintosh"
  },
  {
      "zipCode": 58461,
      "latitude": 46.717981,
      "longitude": -98.186443,
      "city": "Litchville",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58463,
      "latitude": 47.506755,
      "longitude": -100.41715,
      "city": "Mcclusky",
      "state": "ND",
      "county": "Sheridan"
  },
  {
      "zipCode": 58464,
      "latitude": 47.507777,
      "longitude": -98.627951,
      "city": "Mchenry",
      "state": "ND",
      "county": "Foster"
  },
  {
      "zipCode": 58466,
      "latitude": 46.551382,
      "longitude": -98.368983,
      "city": "Marion",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58467,
      "latitude": 46.899774,
      "longitude": -99.316908,
      "city": "Medina",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58472,
      "latitude": 46.681609,
      "longitude": -98.660404,
      "city": "Montpelier",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58474,
      "latitude": 46.109493,
      "longitude": -98.225652,
      "city": "Oakes",
      "state": "ND",
      "county": "Dickey"
  },
  {
      "zipCode": 58475,
      "latitude": 47.153918,
      "longitude": -99.584222,
      "city": "Pettibone",
      "state": "ND",
      "county": "Kidder"
  },
  {
      "zipCode": 58476,
      "latitude": 47.203846,
      "longitude": -99.014385,
      "city": "Pingree",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58477,
      "latitude": 47.212951,
      "longitude": -100.548434,
      "city": "Regan",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58478,
      "latitude": 46.979659,
      "longitude": -99.689234,
      "city": "Robinson",
      "state": "ND",
      "county": "Kidder"
  },
  {
      "zipCode": 58479,
      "latitude": 47.074088,
      "longitude": -98.213603,
      "city": "Rogers",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58480,
      "latitude": 46.892615,
      "longitude": -98.258971,
      "city": "Sanborn",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58481,
      "latitude": 46.986518,
      "longitude": -98.521889,
      "city": "Spiritwood",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58482,
      "latitude": 46.875966,
      "longitude": -99.798975,
      "city": "Steele",
      "state": "ND",
      "county": "Kidder"
  },
  {
      "zipCode": 58483,
      "latitude": 46.718326,
      "longitude": -99.344287,
      "city": "Streeter",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58484,
      "latitude": 47.39903,
      "longitude": -98.403593,
      "city": "Sutton",
      "state": "ND",
      "county": "Griggs"
  },
  {
      "zipCode": 58486,
      "latitude": 47.421182,
      "longitude": -99.404828,
      "city": "Sykeston",
      "state": "ND",
      "county": "Wells"
  },
  {
      "zipCode": 58487,
      "latitude": 46.806274,
      "longitude": -99.594248,
      "city": "Tappen",
      "state": "ND",
      "county": "Kidder"
  },
  {
      "zipCode": 58488,
      "latitude": 47.132159,
      "longitude": -99.970526,
      "city": "Tuttle",
      "state": "ND",
      "county": "Kidder"
  },
  {
      "zipCode": 58489,
      "latitude": 46.027975,
      "longitude": -99.649774,
      "city": "Venturia",
      "state": "ND",
      "county": "Mcintosh"
  },
  {
      "zipCode": 58490,
      "latitude": 46.398713,
      "longitude": -98.099446,
      "city": "Verona",
      "state": "ND",
      "county": "Lamoure"
  },
  {
      "zipCode": 58492,
      "latitude": 47.110075,
      "longitude": -98.397443,
      "city": "Wimbledon",
      "state": "ND",
      "county": "Barnes"
  },
  {
      "zipCode": 58494,
      "latitude": 47.157924,
      "longitude": -100.294569,
      "city": "Wing",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58495,
      "latitude": 46.191724,
      "longitude": -99.613132,
      "city": "Wishek",
      "state": "ND",
      "county": "Mcintosh"
  },
  {
      "zipCode": 58496,
      "latitude": 47.153721,
      "longitude": -99.259784,
      "city": "Woodworth",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58497,
      "latitude": 46.768695,
      "longitude": -98.565136,
      "city": "Ypsilanti",
      "state": "ND",
      "county": "Stutsman"
  },
  {
      "zipCode": 58501,
      "latitude": 46.981207,
      "longitude": -100.502724,
      "city": "Bismarck",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58502,
      "latitude": 46.8887,
      "longitude": -100.681855,
      "city": "Bismarck",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58503,
      "latitude": 46.906399,
      "longitude": -100.752189,
      "city": "Bismarck",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58504,
      "latitude": 46.829938,
      "longitude": -100.45598,
      "city": "Bismarck",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58505,
      "latitude": 46.816543,
      "longitude": -100.780505,
      "city": "Bismarck",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58506,
      "latitude": 46.980475,
      "longitude": -100.520063,
      "city": "Bismarck",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58507,
      "latitude": 46.980475,
      "longitude": -100.520063,
      "city": "Bismarck",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58520,
      "latitude": 46.720185,
      "longitude": -101.498156,
      "city": "Almont",
      "state": "ND",
      "county": "Morton"
  },
  {
      "zipCode": 58521,
      "latitude": 47.016148,
      "longitude": -100.693721,
      "city": "Baldwin",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58523,
      "latitude": 47.277895,
      "longitude": -101.853161,
      "city": "Beulah",
      "state": "ND",
      "county": "Mercer"
  },
  {
      "zipCode": 58524,
      "latitude": 46.56035,
      "longitude": -100.107546,
      "city": "Braddock",
      "state": "ND",
      "county": "Emmons"
  },
  {
      "zipCode": 58528,
      "latitude": 46.361639,
      "longitude": -100.688991,
      "city": "Cannon Ball",
      "state": "ND",
      "county": "Sioux"
  },
  {
      "zipCode": 58529,
      "latitude": 46.300853,
      "longitude": -101.673608,
      "city": "Carson",
      "state": "ND",
      "county": "Grant"
  },
  {
      "zipCode": 58530,
      "latitude": 47.145874,
      "longitude": -101.159363,
      "city": "Center",
      "state": "ND",
      "county": "Oliver"
  },
  {
      "zipCode": 58531,
      "latitude": 47.581047,
      "longitude": -101.143206,
      "city": "Coleharbor",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58532,
      "latitude": 46.851141,
      "longitude": -100.18329,
      "city": "Driscoll",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58533,
      "latitude": 46.367859,
      "longitude": -101.755819,
      "city": "Elgin",
      "state": "ND",
      "county": "Grant"
  },
  {
      "zipCode": 58535,
      "latitude": 46.599807,
      "longitude": -101.316642,
      "city": "Flasher",
      "state": "ND",
      "county": "Morton"
  },
  {
      "zipCode": 58538,
      "latitude": 46.170605,
      "longitude": -100.710536,
      "city": "Fort Yates",
      "state": "ND",
      "county": "Sioux"
  },
  {
      "zipCode": 58540,
      "latitude": 47.667515,
      "longitude": -101.61418,
      "city": "Garrison",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58541,
      "latitude": 47.312542,
      "longitude": -102.056796,
      "city": "Golden Valley",
      "state": "ND",
      "county": "Mercer"
  },
  {
      "zipCode": 58542,
      "latitude": 46.080221,
      "longitude": -100.097715,
      "city": "Hague",
      "state": "ND",
      "county": "Emmons"
  },
  {
      "zipCode": 58544,
      "latitude": 46.517223,
      "longitude": -100.337283,
      "city": "Hazelton",
      "state": "ND",
      "county": "Emmons"
  },
  {
      "zipCode": 58545,
      "latitude": 47.386092,
      "longitude": -101.760142,
      "city": "Hazen",
      "state": "ND",
      "county": "Mercer"
  },
  {
      "zipCode": 58549,
      "latitude": 46.455044,
      "longitude": -99.969631,
      "city": "Kintyre",
      "state": "ND",
      "county": "Emmons"
  },
  {
      "zipCode": 58552,
      "latitude": 46.185512,
      "longitude": -100.233084,
      "city": "Linton",
      "state": "ND",
      "county": "Emmons"
  },
  {
      "zipCode": 58553,
      "latitude": 46.871308,
      "longitude": -100.402135,
      "city": "Mckenzie",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58554,
      "latitude": 46.71312,
      "longitude": -100.952585,
      "city": "Mandan",
      "state": "ND",
      "county": "Morton"
  },
  {
      "zipCode": 58558,
      "latitude": 46.808449,
      "longitude": -100.45556,
      "city": "Menoken",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58559,
      "latitude": 47.501822,
      "longitude": -100.758394,
      "city": "Mercer",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58560,
      "latitude": 46.673047,
      "longitude": -100.300199,
      "city": "Moffit",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58561,
      "latitude": 46.464858,
      "longitude": -99.65682,
      "city": "Napoleon",
      "state": "ND",
      "county": "Logan"
  },
  {
      "zipCode": 58562,
      "latitude": 46.382285,
      "longitude": -101.961907,
      "city": "New Leipzig",
      "state": "ND",
      "county": "Grant"
  },
  {
      "zipCode": 58563,
      "latitude": 46.805999,
      "longitude": -101.429122,
      "city": "New Salem",
      "state": "ND",
      "county": "Morton"
  },
  {
      "zipCode": 58564,
      "latitude": 46.267125,
      "longitude": -101.372162,
      "city": "Raleigh",
      "state": "ND",
      "county": "Grant"
  },
  {
      "zipCode": 58565,
      "latitude": 47.396354,
      "longitude": -101.203255,
      "city": "Riverdale",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58566,
      "latitude": 46.646616,
      "longitude": -100.94428,
      "city": "Saint Anthony",
      "state": "ND",
      "county": "Morton"
  },
  {
      "zipCode": 58568,
      "latitude": 46.186442,
      "longitude": -101.252738,
      "city": "Selfridge",
      "state": "ND",
      "county": "Sioux"
  },
  {
      "zipCode": 58569,
      "latitude": 46.244657,
      "longitude": -101.218713,
      "city": "Shields",
      "state": "ND",
      "county": "Grant"
  },
  {
      "zipCode": 58570,
      "latitude": 46.365588,
      "longitude": -100.783143,
      "city": "Solen",
      "state": "ND",
      "county": "Sioux"
  },
  {
      "zipCode": 58571,
      "latitude": 47.377264,
      "longitude": -101.394939,
      "city": "Stanton",
      "state": "ND",
      "county": "Mercer"
  },
  {
      "zipCode": 58572,
      "latitude": 46.876069,
      "longitude": -100.277434,
      "city": "Sterling",
      "state": "ND",
      "county": "Burleigh"
  },
  {
      "zipCode": 58573,
      "latitude": 46.080388,
      "longitude": -100.223542,
      "city": "Strasburg",
      "state": "ND",
      "county": "Emmons"
  },
  {
      "zipCode": 58575,
      "latitude": 47.501425,
      "longitude": -100.895863,
      "city": "Turtle Lake",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58576,
      "latitude": 47.445896,
      "longitude": -101.21736,
      "city": "Underwood",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58577,
      "latitude": 47.380715,
      "longitude": -101.095673,
      "city": "Washburn",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58579,
      "latitude": 47.488872,
      "longitude": -101.085504,
      "city": "Wilton",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58580,
      "latitude": 47.263087,
      "longitude": -101.929416,
      "city": "Zap",
      "state": "ND",
      "county": "Mercer"
  },
  {
      "zipCode": 58581,
      "latitude": 46.064366,
      "longitude": -99.786023,
      "city": "Zeeland",
      "state": "ND",
      "county": "Mcintosh"
  },
  {
      "zipCode": 58601,
      "latitude": 46.805716,
      "longitude": -102.756514,
      "city": "Dickinson",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58602,
      "latitude": 46.820185,
      "longitude": -102.663913,
      "city": "Dickinson",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58620,
      "latitude": 46.454966,
      "longitude": -103.485325,
      "city": "Amidon",
      "state": "ND",
      "county": "Slope"
  },
  {
      "zipCode": 58621,
      "latitude": 46.963728,
      "longitude": -103.898766,
      "city": "Beach",
      "state": "ND",
      "county": "Golden Valley"
  },
  {
      "zipCode": 58622,
      "latitude": 46.819805,
      "longitude": -103.126111,
      "city": "Belfield",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58623,
      "latitude": 46.205537,
      "longitude": -103.521263,
      "city": "Bowman",
      "state": "ND",
      "county": "Bowman"
  },
  {
      "zipCode": 58625,
      "latitude": 47.305726,
      "longitude": -102.202834,
      "city": "Dodge",
      "state": "ND",
      "county": "Dunn"
  },
  {
      "zipCode": 58626,
      "latitude": 47.336337,
      "longitude": -102.663492,
      "city": "Dunn Center",
      "state": "ND",
      "county": "Dunn"
  },
  {
      "zipCode": 58627,
      "latitude": 46.921188,
      "longitude": -103.509564,
      "city": "Fairfield",
      "state": "ND",
      "county": "Billings"
  },
  {
      "zipCode": 58630,
      "latitude": 46.848918,
      "longitude": -102.6399,
      "city": "Gladstone",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58631,
      "latitude": 46.813372,
      "longitude": -101.822672,
      "city": "Glen Ullin",
      "state": "ND",
      "county": "Morton"
  },
  {
      "zipCode": 58632,
      "latitude": 46.672225,
      "longitude": -103.952158,
      "city": "Golva",
      "state": "ND",
      "county": "Golden Valley"
  },
  {
      "zipCode": 58634,
      "latitude": 47.737435,
      "longitude": -103.298383,
      "city": "Grassy Butte",
      "state": "ND",
      "county": "Mckenzie"
  },
  {
      "zipCode": 58636,
      "latitude": 47.351748,
      "longitude": -102.336761,
      "city": "Halliday",
      "state": "ND",
      "county": "Dunn"
  },
  {
      "zipCode": 58638,
      "latitude": 46.849592,
      "longitude": -101.972303,
      "city": "Hebron",
      "state": "ND",
      "county": "Morton"
  },
  {
      "zipCode": 58639,
      "latitude": 46.057786,
      "longitude": -102.785054,
      "city": "Hettinger",
      "state": "ND",
      "county": "Adams"
  },
  {
      "zipCode": 58640,
      "latitude": 47.526978,
      "longitude": -102.703916,
      "city": "Killdeer",
      "state": "ND",
      "county": "Dunn"
  },
  {
      "zipCode": 58641,
      "latitude": 46.724077,
      "longitude": -102.663709,
      "city": "Lefor",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58642,
      "latitude": 47.402085,
      "longitude": -102.622139,
      "city": "Manning",
      "state": "ND",
      "county": "Dunn"
  },
  {
      "zipCode": 58643,
      "latitude": 46.454966,
      "longitude": -103.485325,
      "city": "Marmarth",
      "state": "ND",
      "county": "Slope"
  },
  {
      "zipCode": 58644,
      "latitude": 47.402085,
      "longitude": -102.622139,
      "city": "Marshall",
      "state": "ND",
      "county": "Dunn"
  },
  {
      "zipCode": 58645,
      "latitude": 46.979437,
      "longitude": -103.349989,
      "city": "Medora",
      "state": "ND",
      "county": "Billings"
  },
  {
      "zipCode": 58646,
      "latitude": 46.369616,
      "longitude": -102.326958,
      "city": "Mott",
      "state": "ND",
      "county": "Hettinger"
  },
  {
      "zipCode": 58647,
      "latitude": 46.459446,
      "longitude": -102.595864,
      "city": "New England",
      "state": "ND",
      "county": "Hettinger"
  },
  {
      "zipCode": 58649,
      "latitude": 46.113891,
      "longitude": -102.496805,
      "city": "Reeder",
      "state": "ND",
      "county": "Adams"
  },
  {
      "zipCode": 58650,
      "latitude": 46.418659,
      "longitude": -102.56228,
      "city": "Regent",
      "state": "ND",
      "county": "Hettinger"
  },
  {
      "zipCode": 58651,
      "latitude": 46.112755,
      "longitude": -103.520928,
      "city": "Rhame",
      "state": "ND",
      "county": "Bowman"
  },
  {
      "zipCode": 58652,
      "latitude": 46.820533,
      "longitude": -102.264513,
      "city": "Richardton",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58653,
      "latitude": 46.112755,
      "longitude": -103.520928,
      "city": "Scranton",
      "state": "ND",
      "county": "Bowman"
  },
  {
      "zipCode": 58654,
      "latitude": 46.847632,
      "longitude": -103.874736,
      "city": "Sentinel Butte",
      "state": "ND",
      "county": "Golden Valley"
  },
  {
      "zipCode": 58655,
      "latitude": 46.767691,
      "longitude": -103.072219,
      "city": "South Heart",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58656,
      "latitude": 46.856469,
      "longitude": -102.784581,
      "city": "Taylor",
      "state": "ND",
      "county": "Stark"
  },
  {
      "zipCode": 58701,
      "latitude": 48.084625,
      "longitude": -101.419006,
      "city": "Minot",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58702,
      "latitude": 48.336898,
      "longitude": -101.451285,
      "city": "Minot",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58703,
      "latitude": 48.299433,
      "longitude": -101.606,
      "city": "Minot",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58704,
      "latitude": 48.42429,
      "longitude": -101.319942,
      "city": "Minot Afb",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58705,
      "latitude": 48.234184,
      "longitude": -101.297161,
      "city": "Minot Afb",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58707,
      "latitude": 48.245267,
      "longitude": -101.301179,
      "city": "Minot",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58710,
      "latitude": 47.926998,
      "longitude": -100.284856,
      "city": "Anamoose",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58711,
      "latitude": 48.813251,
      "longitude": -101.227611,
      "city": "Antler",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58712,
      "latitude": 47.992915,
      "longitude": -100.497516,
      "city": "Balfour",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58713,
      "latitude": 48.528579,
      "longitude": -100.635538,
      "city": "Bantry",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58716,
      "latitude": 47.761315,
      "longitude": -101.109594,
      "city": "Benedict",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58718,
      "latitude": 48.306397,
      "longitude": -101.686644,
      "city": "Berthold",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58721,
      "latitude": 48.837753,
      "longitude": -102.283897,
      "city": "Bowbells",
      "state": "ND",
      "county": "Burke"
  },
  {
      "zipCode": 58722,
      "latitude": 48.10469,
      "longitude": -101.543429,
      "city": "Burlington",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58723,
      "latitude": 47.69365,
      "longitude": -100.692443,
      "city": "Butte",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58725,
      "latitude": 48.417859,
      "longitude": -101.705933,
      "city": "Carpio",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58727,
      "latitude": 48.852362,
      "longitude": -102.592042,
      "city": "Columbus",
      "state": "ND",
      "county": "Burke"
  },
  {
      "zipCode": 58730,
      "latitude": 48.928166,
      "longitude": -103.296728,
      "city": "Crosby",
      "state": "ND",
      "county": "Divide"
  },
  {
      "zipCode": 58731,
      "latitude": 48.490508,
      "longitude": -100.961945,
      "city": "Deering",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58733,
      "latitude": 48.187543,
      "longitude": -101.668759,
      "city": "Des Lacs",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58734,
      "latitude": 48.495604,
      "longitude": -102.006123,
      "city": "Donnybrook",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58735,
      "latitude": 47.952635,
      "longitude": -101.464689,
      "city": "Douglas",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58736,
      "latitude": 47.978309,
      "longitude": -100.384482,
      "city": "Drake",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58737,
      "latitude": 48.877183,
      "longitude": -102.382327,
      "city": "Flaxton",
      "state": "ND",
      "county": "Burke"
  },
  {
      "zipCode": 58740,
      "latitude": 48.647206,
      "longitude": -101.540749,
      "city": "Glenburn",
      "state": "ND",
      "county": "Renville"
  },
  {
      "zipCode": 58741,
      "latitude": 48.305738,
      "longitude": -100.67856,
      "city": "Granville",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58744,
      "latitude": 48.194146,
      "longitude": -100.591093,
      "city": "Karlsruhe",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58746,
      "latitude": 48.523046,
      "longitude": -101.670989,
      "city": "Kenmare",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58747,
      "latitude": 47.876848,
      "longitude": -100.660544,
      "city": "Kief",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58748,
      "latitude": 48.697742,
      "longitude": -100.634566,
      "city": "Kramer",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58750,
      "latitude": 48.634598,
      "longitude": -101.297326,
      "city": "Lansford",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58752,
      "latitude": 48.821804,
      "longitude": -102.611948,
      "city": "Lignite",
      "state": "ND",
      "county": "Burke"
  },
  {
      "zipCode": 58755,
      "latitude": 48.405038,
      "longitude": -103.407189,
      "city": "Mcgregor",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58756,
      "latitude": 47.957203,
      "longitude": -101.778246,
      "city": "Makoti",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58757,
      "latitude": 47.737435,
      "longitude": -103.298383,
      "city": "Mandaree",
      "state": "ND",
      "county": "Mckenzie"
  },
  {
      "zipCode": 58758,
      "latitude": 47.717951,
      "longitude": -100.21234,
      "city": "Martin",
      "state": "ND",
      "county": "Sheridan"
  },
  {
      "zipCode": 58759,
      "latitude": 47.567805,
      "longitude": -101.164109,
      "city": "Max",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58760,
      "latitude": 48.736656,
      "longitude": -101.197701,
      "city": "Maxbass",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58761,
      "latitude": 48.726868,
      "longitude": -101.605847,
      "city": "Mohall",
      "state": "ND",
      "county": "Renville"
  },
  {
      "zipCode": 58762,
      "latitude": 48.770759,
      "longitude": -101.067192,
      "city": "Newburg",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58763,
      "latitude": 47.971759,
      "longitude": -102.471636,
      "city": "New Town",
      "state": "ND",
      "county": "Mountrail"
  },
  {
      "zipCode": 58765,
      "latitude": 48.925358,
      "longitude": -103.071081,
      "city": "Noonan",
      "state": "ND",
      "county": "Divide"
  },
  {
      "zipCode": 58768,
      "latitude": 48.269539,
      "longitude": -100.950246,
      "city": "Norwich",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58769,
      "latitude": 48.284698,
      "longitude": -102.257478,
      "city": "Palermo",
      "state": "ND",
      "county": "Mountrail"
  },
  {
      "zipCode": 58770,
      "latitude": 47.946158,
      "longitude": -102.103378,
      "city": "Parshall",
      "state": "ND",
      "county": "Mountrail"
  },
  {
      "zipCode": 58771,
      "latitude": 48.065743,
      "longitude": -102.030195,
      "city": "Plaza",
      "state": "ND",
      "county": "Mountrail"
  },
  {
      "zipCode": 58772,
      "latitude": 48.953774,
      "longitude": -102.622651,
      "city": "Portal",
      "state": "ND",
      "county": "Burke"
  },
  {
      "zipCode": 58773,
      "latitude": 48.655093,
      "longitude": -102.679499,
      "city": "Powers Lake",
      "state": "ND",
      "county": "Burke"
  },
  {
      "zipCode": 58775,
      "latitude": 47.681855,
      "longitude": -101.866895,
      "city": "Roseglen",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58776,
      "latitude": 48.272077,
      "longitude": -102.547886,
      "city": "Ross",
      "state": "ND",
      "county": "Mountrail"
  },
  {
      "zipCode": 58778,
      "latitude": 47.746583,
      "longitude": -100.931173,
      "city": "Ruso",
      "state": "ND",
      "county": "Mclean"
  },
  {
      "zipCode": 58779,
      "latitude": 47.978815,
      "longitude": -101.668252,
      "city": "Ryder",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58781,
      "latitude": 48.181645,
      "longitude": -101.340912,
      "city": "Sawyer",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58782,
      "latitude": 48.863353,
      "longitude": -101.770037,
      "city": "Sherwood",
      "state": "ND",
      "county": "Renville"
  },
  {
      "zipCode": 58783,
      "latitude": 48.87384,
      "longitude": -100.735863,
      "city": "Souris",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58784,
      "latitude": 48.345765,
      "longitude": -102.418466,
      "city": "Stanley",
      "state": "ND",
      "county": "Mountrail"
  },
  {
      "zipCode": 58785,
      "latitude": 48.290933,
      "longitude": -101.111794,
      "city": "Surrey",
      "state": "ND",
      "county": "Ward"
  },
  {
      "zipCode": 58787,
      "latitude": 48.759404,
      "longitude": -101.862477,
      "city": "Tolley",
      "state": "ND",
      "county": "Renville"
  },
  {
      "zipCode": 58788,
      "latitude": 48.363638,
      "longitude": -100.4854,
      "city": "Towner",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58789,
      "latitude": 48.348495,
      "longitude": -100.831317,
      "city": "Upham",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58790,
      "latitude": 48.021268,
      "longitude": -100.629723,
      "city": "Velva",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58792,
      "latitude": 47.98605,
      "longitude": -100.798821,
      "city": "Voltaire",
      "state": "ND",
      "county": "Mchenry"
  },
  {
      "zipCode": 58793,
      "latitude": 48.86655,
      "longitude": -100.836782,
      "city": "Westhope",
      "state": "ND",
      "county": "Bottineau"
  },
  {
      "zipCode": 58794,
      "latitude": 48.299352,
      "longitude": -102.770085,
      "city": "White Earth",
      "state": "ND",
      "county": "Mountrail"
  },
  {
      "zipCode": 58795,
      "latitude": 48.567814,
      "longitude": -103.153622,
      "city": "Wildrose",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58801,
      "latitude": 48.225729,
      "longitude": -103.649047,
      "city": "Williston",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58802,
      "latitude": 48.168836,
      "longitude": -103.614824,
      "city": "Williston",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58830,
      "latitude": 48.538749,
      "longitude": -103.47341,
      "city": "Alamo",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58831,
      "latitude": 47.737435,
      "longitude": -103.298383,
      "city": "Alexander",
      "state": "ND",
      "county": "Mckenzie"
  },
  {
      "zipCode": 58833,
      "latitude": 48.816502,
      "longitude": -103.467251,
      "city": "Ambrose",
      "state": "ND",
      "county": "Divide"
  },
  {
      "zipCode": 58835,
      "latitude": 47.807748,
      "longitude": -103.438887,
      "city": "Arnegard",
      "state": "ND",
      "county": "Mckenzie"
  },
  {
      "zipCode": 58838,
      "latitude": 47.737435,
      "longitude": -103.298383,
      "city": "Cartwright",
      "state": "ND",
      "county": "Mckenzie"
  },
  {
      "zipCode": 58843,
      "latitude": 48.276356,
      "longitude": -103.376284,
      "city": "Epping",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58844,
      "latitude": 48.816502,
      "longitude": -103.467251,
      "city": "Fortuna",
      "state": "ND",
      "county": "Divide"
  },
  {
      "zipCode": 58845,
      "latitude": 48.5252,
      "longitude": -103.917588,
      "city": "Grenora",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58847,
      "latitude": 47.737435,
      "longitude": -103.298383,
      "city": "Keene",
      "state": "ND",
      "county": "Mckenzie"
  },
  {
      "zipCode": 58849,
      "latitude": 48.321696,
      "longitude": -103.165391,
      "city": "Ray",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58852,
      "latitude": 48.364623,
      "longitude": -102.957597,
      "city": "Tioga",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58853,
      "latitude": 48.295057,
      "longitude": -103.438083,
      "city": "Trenton",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 58854,
      "latitude": 47.802905,
      "longitude": -103.2667,
      "city": "Watford City",
      "state": "ND",
      "county": "Mckenzie"
  },
  {
      "zipCode": 58856,
      "latitude": 48.532242,
      "longitude": -103.772098,
      "city": "Zahl",
      "state": "ND",
      "county": "Williams"
  },
  {
      "zipCode": 59001,
      "latitude": 45.515571,
      "longitude": -109.455156,
      "city": "Absarokee",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59002,
      "latitude": 45.929056,
      "longitude": -108.688831,
      "city": "Acton",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59003,
      "latitude": 45.605673,
      "longitude": -106.278272,
      "city": "Ashland",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59004,
      "latitude": 46.017965,
      "longitude": -106.99199,
      "city": "Ashland",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59006,
      "latitude": 45.892314,
      "longitude": -108.098389,
      "city": "Ballantine",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59007,
      "latitude": 45.161913,
      "longitude": -109.158349,
      "city": "Bearcreek",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59008,
      "latitude": 45.091835,
      "longitude": -109.034212,
      "city": "Belfry",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59010,
      "latitude": 46.176296,
      "longitude": -107.33875,
      "city": "Bighorn",
      "state": "MT",
      "county": "Treasure"
  },
  {
      "zipCode": 59011,
      "latitude": 45.834206,
      "longitude": -109.950025,
      "city": "Big Timber",
      "state": "MT",
      "county": "Sweet Grass"
  },
  {
      "zipCode": 59012,
      "latitude": 46.017965,
      "longitude": -106.99199,
      "city": "Birney",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59013,
      "latitude": 45.410149,
      "longitude": -109.136458,
      "city": "Boyd",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59014,
      "latitude": 45.335953,
      "longitude": -108.920853,
      "city": "Bridger",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59015,
      "latitude": 46.041717,
      "longitude": -108.759561,
      "city": "Broadview",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59016,
      "latitude": 45.536218,
      "longitude": -106.961213,
      "city": "Busby",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59018,
      "latitude": 45.834082,
      "longitude": -110.622234,
      "city": "Clyde Park",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59019,
      "latitude": 45.657453,
      "longitude": -109.206617,
      "city": "Columbus",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59020,
      "latitude": 45.023373,
      "longitude": -109.90697,
      "city": "Cooke City",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59022,
      "latitude": 45.608203,
      "longitude": -107.469868,
      "city": "Crow Agency",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59024,
      "latitude": 46.212579,
      "longitude": -107.693279,
      "city": "Custer",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59025,
      "latitude": 45.518708,
      "longitude": -107.481697,
      "city": "Decker",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59026,
      "latitude": 45.444257,
      "longitude": -108.848692,
      "city": "Edgar",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59027,
      "latitude": 45.270789,
      "longitude": -110.792071,
      "city": "Emigrant",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59028,
      "latitude": 45.410217,
      "longitude": -109.771658,
      "city": "Fishtail",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59029,
      "latitude": 45.398686,
      "longitude": -108.817298,
      "city": "Fromberg",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59030,
      "latitude": 45.114735,
      "longitude": -110.809991,
      "city": "Gardiner",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59031,
      "latitude": 45.518708,
      "longitude": -107.481697,
      "city": "Garryowen",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59032,
      "latitude": 47.286641,
      "longitude": -108.763415,
      "city": "Grass Range",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59033,
      "latitude": 45.696301,
      "longitude": -109.855062,
      "city": "Greycliff",
      "state": "MT",
      "county": "Sweet Grass"
  },
  {
      "zipCode": 59034,
      "latitude": 45.52731,
      "longitude": -107.6963,
      "city": "Hardin",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59035,
      "latitude": 45.308898,
      "longitude": -107.925665,
      "city": "Yellowtail",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59036,
      "latitude": 46.447745,
      "longitude": -109.843492,
      "city": "Harlowton",
      "state": "MT",
      "county": "Wheatland"
  },
  {
      "zipCode": 59037,
      "latitude": 45.876735,
      "longitude": -108.211535,
      "city": "Huntley",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59038,
      "latitude": 46.290753,
      "longitude": -107.228948,
      "city": "Hysham",
      "state": "MT",
      "county": "Treasure"
  },
  {
      "zipCode": 59039,
      "latitude": 46.017965,
      "longitude": -106.99199,
      "city": "Ingomar",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59041,
      "latitude": 45.494126,
      "longitude": -108.992234,
      "city": "Joliet",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59043,
      "latitude": 45.620185,
      "longitude": -106.678901,
      "city": "Lame Deer",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59044,
      "latitude": 45.80418,
      "longitude": -108.227571,
      "city": "Laurel",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59046,
      "latitude": 46.383858,
      "longitude": -109.003382,
      "city": "Lavina",
      "state": "MT",
      "county": "Golden Valley"
  },
  {
      "zipCode": 59047,
      "latitude": 45.530664,
      "longitude": -110.369054,
      "city": "Livingston",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59050,
      "latitude": 45.316565,
      "longitude": -107.367494,
      "city": "Lodge Grass",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59052,
      "latitude": 45.832835,
      "longitude": -109.955671,
      "city": "Mc Leod",
      "state": "MT",
      "county": "Sweet Grass"
  },
  {
      "zipCode": 59053,
      "latitude": 46.619039,
      "longitude": -110.845396,
      "city": "Martinsdale",
      "state": "MT",
      "county": "Meagher"
  },
  {
      "zipCode": 59054,
      "latitude": 46.598623,
      "longitude": -107.869188,
      "city": "Melstone",
      "state": "MT",
      "county": "Musselshell"
  },
  {
      "zipCode": 59055,
      "latitude": 45.696301,
      "longitude": -109.855062,
      "city": "Melville",
      "state": "MT",
      "county": "Sweet Grass"
  },
  {
      "zipCode": 59057,
      "latitude": 45.944684,
      "longitude": -108.911917,
      "city": "Molt",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59058,
      "latitude": 47.414133,
      "longitude": -107.027417,
      "city": "Mosby",
      "state": "MT",
      "county": "Garfield"
  },
  {
      "zipCode": 59059,
      "latitude": 46.515315,
      "longitude": -108.089742,
      "city": "Musselshell",
      "state": "MT",
      "county": "Musselshell"
  },
  {
      "zipCode": 59061,
      "latitude": 45.475459,
      "longitude": -109.595984,
      "city": "Nye",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59062,
      "latitude": 45.391078,
      "longitude": -105.630461,
      "city": "Otter",
      "state": "MT",
      "county": "Powder River"
  },
  {
      "zipCode": 59063,
      "latitude": 45.657911,
      "longitude": -109.047266,
      "city": "Park City",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59064,
      "latitude": 45.964417,
      "longitude": -107.938592,
      "city": "Pompeys Pillar",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59065,
      "latitude": 45.333104,
      "longitude": -110.713691,
      "city": "Pray",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59066,
      "latitude": 45.518708,
      "longitude": -107.481697,
      "city": "Pryor",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59067,
      "latitude": 45.948864,
      "longitude": -109.230246,
      "city": "Rapelje",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59068,
      "latitude": 45.241262,
      "longitude": -109.323626,
      "city": "Red Lodge",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59069,
      "latitude": 45.743871,
      "longitude": -109.522372,
      "city": "Reed Point",
      "state": "MT",
      "county": "Stillwater"
  },
  {
      "zipCode": 59070,
      "latitude": 45.354073,
      "longitude": -109.207199,
      "city": "Roberts",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59071,
      "latitude": 45.319054,
      "longitude": -108.948445,
      "city": "Roscoe",
      "state": "MT",
      "county": "Carbon"
  },
  {
      "zipCode": 59072,
      "latitude": 46.440929,
      "longitude": -108.427325,
      "city": "Roundup",
      "state": "MT",
      "county": "Musselshell"
  },
  {
      "zipCode": 59073,
      "latitude": 46.444224,
      "longitude": -108.395473,
      "city": "Roundup",
      "state": "MT",
      "county": "Musselshell"
  },
  {
      "zipCode": 59074,
      "latitude": 46.378527,
      "longitude": -109.257354,
      "city": "Ryegate",
      "state": "MT",
      "county": "Golden Valley"
  },
  {
      "zipCode": 59075,
      "latitude": 45.518708,
      "longitude": -107.481697,
      "city": "Saint Xavier",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59076,
      "latitude": 46.176296,
      "longitude": -107.33875,
      "city": "Sanders",
      "state": "MT",
      "county": "Treasure"
  },
  {
      "zipCode": 59077,
      "latitude": 47.414133,
      "longitude": -107.027417,
      "city": "Sand Springs",
      "state": "MT",
      "county": "Garfield"
  },
  {
      "zipCode": 59078,
      "latitude": 46.485721,
      "longitude": -109.834856,
      "city": "Shawmut",
      "state": "MT",
      "county": "Wheatland"
  },
  {
      "zipCode": 59079,
      "latitude": 46.082782,
      "longitude": -108.435851,
      "city": "Shepherd",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59081,
      "latitude": 45.004618,
      "longitude": -109.98539,
      "city": "Silver Gate",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59082,
      "latitude": 45.33333,
      "longitude": -110.477384,
      "city": "Springdale",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59083,
      "latitude": 46.017965,
      "longitude": -106.99199,
      "city": "Sumatra",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59084,
      "latitude": 47.173641,
      "longitude": -108.281168,
      "city": "Teigen",
      "state": "MT",
      "county": "Petroleum"
  },
  {
      "zipCode": 59085,
      "latitude": 46.424187,
      "longitude": -110.071194,
      "city": "Two Dot",
      "state": "MT",
      "county": "Wheatland"
  },
  {
      "zipCode": 59086,
      "latitude": 46.016146,
      "longitude": -110.599503,
      "city": "Wilsall",
      "state": "MT",
      "county": "Park"
  },
  {
      "zipCode": 59087,
      "latitude": 47.002053,
      "longitude": -108.122735,
      "city": "Winnett",
      "state": "MT",
      "county": "Petroleum"
  },
  {
      "zipCode": 59088,
      "latitude": 46.091269,
      "longitude": -108.011128,
      "city": "Worden",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59089,
      "latitude": 45.518708,
      "longitude": -107.481697,
      "city": "Wyola",
      "state": "MT",
      "county": "Big Horn"
  },
  {
      "zipCode": 59101,
      "latitude": 45.686966,
      "longitude": -108.387392,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59102,
      "latitude": 45.790379,
      "longitude": -108.588144,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59103,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59104,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59105,
      "latitude": 45.949726,
      "longitude": -108.599037,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59106,
      "latitude": 45.799217,
      "longitude": -108.568842,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59107,
      "latitude": 45.825204,
      "longitude": -108.393388,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59108,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59111,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59112,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59114,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59115,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59116,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59117,
      "latitude": 45.978288,
      "longitude": -108.194508,
      "city": "Billings",
      "state": "MT",
      "county": "Yellowstone"
  },
  {
      "zipCode": 59201,
      "latitude": 48.2088,
      "longitude": -105.072426,
      "city": "Wolf Point",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59211,
      "latitude": 48.700718,
      "longitude": -104.360729,
      "city": "Antelope",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59212,
      "latitude": 48.139784,
      "longitude": -104.219188,
      "city": "Bainville",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59213,
      "latitude": 48.280119,
      "longitude": -104.94413,
      "city": "Brockton",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59214,
      "latitude": 47.24993,
      "longitude": -105.906002,
      "city": "Brockway",
      "state": "MT",
      "county": "Mccone"
  },
  {
      "zipCode": 59215,
      "latitude": 47.484243,
      "longitude": -105.758852,
      "city": "Circle",
      "state": "MT",
      "county": "Mccone"
  },
  {
      "zipCode": 59217,
      "latitude": 47.711026,
      "longitude": -104.183625,
      "city": "Crane",
      "state": "MT",
      "county": "Richland"
  },
  {
      "zipCode": 59218,
      "latitude": 48.150238,
      "longitude": -104.517106,
      "city": "Culbertson",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59219,
      "latitude": 48.558851,
      "longitude": -104.294475,
      "city": "Dagmar",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59221,
      "latitude": 47.853542,
      "longitude": -104.061953,
      "city": "Fairview",
      "state": "MT",
      "county": "Richland"
  },
  {
      "zipCode": 59222,
      "latitude": 48.781396,
      "longitude": -105.562544,
      "city": "Flaxville",
      "state": "MT",
      "county": "Daniels"
  },
  {
      "zipCode": 59223,
      "latitude": 48.019588,
      "longitude": -106.446962,
      "city": "Fort Peck",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59225,
      "latitude": 48.295916,
      "longitude": -106.032354,
      "city": "Frazer",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59226,
      "latitude": 48.335745,
      "longitude": -104.492637,
      "city": "Froid",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59230,
      "latitude": 48.43923,
      "longitude": -106.474987,
      "city": "Glasgow",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59231,
      "latitude": 48.403894,
      "longitude": -106.542815,
      "city": "Saint Marie",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59240,
      "latitude": 48.330241,
      "longitude": -106.609665,
      "city": "Glentana",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59241,
      "latitude": 48.342617,
      "longitude": -106.854457,
      "city": "Hinsdale",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59242,
      "latitude": 48.292043,
      "longitude": -104.813996,
      "city": "Homestead",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59243,
      "latitude": 47.752074,
      "longitude": -104.638367,
      "city": "Lambert",
      "state": "MT",
      "county": "Richland"
  },
  {
      "zipCode": 59244,
      "latitude": 48.678589,
      "longitude": -106.357152,
      "city": "Larslan",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59245,
      "latitude": 48.280119,
      "longitude": -104.94413,
      "city": "Mc Cabe",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59247,
      "latitude": 48.544896,
      "longitude": -104.418145,
      "city": "Medicine Lake",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59248,
      "latitude": 48.216068,
      "longitude": -106.320808,
      "city": "Nashua",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59250,
      "latitude": 48.912477,
      "longitude": -106.272664,
      "city": "Opheim",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59252,
      "latitude": 48.899627,
      "longitude": -104.793406,
      "city": "Outlook",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59253,
      "latitude": 48.72942,
      "longitude": -105.885542,
      "city": "Peerless",
      "state": "MT",
      "county": "Daniels"
  },
  {
      "zipCode": 59254,
      "latitude": 48.747919,
      "longitude": -104.468029,
      "city": "Plentywood",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59255,
      "latitude": 48.115522,
      "longitude": -105.189701,
      "city": "Poplar",
      "state": "MT",
      "county": "Roosevelt"
  },
  {
      "zipCode": 59256,
      "latitude": 48.694565,
      "longitude": -104.552352,
      "city": "Raymond",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59257,
      "latitude": 48.728018,
      "longitude": -104.940164,
      "city": "Redstone",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59258,
      "latitude": 48.569892,
      "longitude": -104.570289,
      "city": "Reserve",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59259,
      "latitude": 47.463408,
      "longitude": -104.988111,
      "city": "Richey",
      "state": "MT",
      "county": "Dawson"
  },
  {
      "zipCode": 59260,
      "latitude": 48.650714,
      "longitude": -106.119229,
      "city": "Richland",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59261,
      "latitude": 48.457237,
      "longitude": -107.342553,
      "city": "Saco",
      "state": "MT",
      "county": "Phillips"
  },
  {
      "zipCode": 59262,
      "latitude": 47.452578,
      "longitude": -104.342955,
      "city": "Savage",
      "state": "MT",
      "county": "Richland"
  },
  {
      "zipCode": 59263,
      "latitude": 48.712594,
      "longitude": -105.535656,
      "city": "Scobey",
      "state": "MT",
      "county": "Daniels"
  },
  {
      "zipCode": 59270,
      "latitude": 47.655825,
      "longitude": -104.195206,
      "city": "Sidney",
      "state": "MT",
      "county": "Richland"
  },
  {
      "zipCode": 59273,
      "latitude": 48.330241,
      "longitude": -106.609665,
      "city": "Vandalia",
      "state": "MT",
      "county": "Valley"
  },
  {
      "zipCode": 59274,
      "latitude": 47.874088,
      "longitude": -105.535752,
      "city": "Vida",
      "state": "MT",
      "county": "Mccone"
  },
  {
      "zipCode": 59275,
      "latitude": 48.868265,
      "longitude": -104.314372,
      "city": "Westby",
      "state": "MT",
      "county": "Sheridan"
  },
  {
      "zipCode": 59276,
      "latitude": 48.8956,
      "longitude": -105.161657,
      "city": "Whitetail",
      "state": "MT",
      "county": "Daniels"
  },
  {
      "zipCode": 59301,
      "latitude": 46.447808,
      "longitude": -105.795343,
      "city": "Miles City",
      "state": "MT",
      "county": "Custer"
  },
  {
      "zipCode": 59311,
      "latitude": 45.567085,
      "longitude": -104.539066,
      "city": "Alzada",
      "state": "MT",
      "county": "Carter"
  },
  {
      "zipCode": 59312,
      "latitude": 46.017965,
      "longitude": -106.99199,
      "city": "Angela",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59313,
      "latitude": 46.351496,
      "longitude": -104.273377,
      "city": "Baker",
      "state": "MT",
      "county": "Fallon"
  },
  {
      "zipCode": 59314,
      "latitude": 45.391078,
      "longitude": -105.630461,
      "city": "Biddle",
      "state": "MT",
      "county": "Powder River"
  },
  {
      "zipCode": 59315,
      "latitude": 47.409962,
      "longitude": -104.959584,
      "city": "Bloomfield",
      "state": "MT",
      "county": "Dawson"
  },
  {
      "zipCode": 59316,
      "latitude": 45.567085,
      "longitude": -104.539066,
      "city": "Boyes",
      "state": "MT",
      "county": "Carter"
  },
  {
      "zipCode": 59317,
      "latitude": 45.25719,
      "longitude": -105.256813,
      "city": "Broadus",
      "state": "MT",
      "county": "Powder River"
  },
  {
      "zipCode": 59318,
      "latitude": 47.414133,
      "longitude": -107.027417,
      "city": "Brusett",
      "state": "MT",
      "county": "Garfield"
  },
  {
      "zipCode": 59319,
      "latitude": 45.567085,
      "longitude": -104.539066,
      "city": "Capitol",
      "state": "MT",
      "county": "Carter"
  },
  {
      "zipCode": 59322,
      "latitude": 47.414133,
      "longitude": -107.027417,
      "city": "Cohagen",
      "state": "MT",
      "county": "Garfield"
  },
  {
      "zipCode": 59323,
      "latitude": 45.934423,
      "longitude": -106.636783,
      "city": "Colstrip",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59324,
      "latitude": 45.897576,
      "longitude": -104.640001,
      "city": "Ekalaka",
      "state": "MT",
      "county": "Carter"
  },
  {
      "zipCode": 59326,
      "latitude": 46.860997,
      "longitude": -105.345182,
      "city": "Fallon",
      "state": "MT",
      "county": "Prairie"
  },
  {
      "zipCode": 59327,
      "latitude": 46.174223,
      "longitude": -106.6303,
      "city": "Forsyth",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59330,
      "latitude": 47.180623,
      "longitude": -104.774873,
      "city": "Glendive",
      "state": "MT",
      "county": "Dawson"
  },
  {
      "zipCode": 59332,
      "latitude": 45.567085,
      "longitude": -104.539066,
      "city": "Hammond",
      "state": "MT",
      "county": "Carter"
  },
  {
      "zipCode": 59333,
      "latitude": 46.017965,
      "longitude": -106.99199,
      "city": "Hathaway",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59336,
      "latitude": 46.323967,
      "longitude": -105.462341,
      "city": "Ismay",
      "state": "MT",
      "county": "Custer"
  },
  {
      "zipCode": 59337,
      "latitude": 47.325641,
      "longitude": -106.943963,
      "city": "Jordan",
      "state": "MT",
      "county": "Garfield"
  },
  {
      "zipCode": 59338,
      "latitude": 46.323967,
      "longitude": -105.462341,
      "city": "Kinsey",
      "state": "MT",
      "county": "Custer"
  },
  {
      "zipCode": 59339,
      "latitude": 47.254164,
      "longitude": -105.172545,
      "city": "Lindsay",
      "state": "MT",
      "county": "Dawson"
  },
  {
      "zipCode": 59341,
      "latitude": 46.860997,
      "longitude": -105.345182,
      "city": "Mildred",
      "state": "MT",
      "county": "Prairie"
  },
  {
      "zipCode": 59343,
      "latitude": 45.391078,
      "longitude": -105.630461,
      "city": "Olive",
      "state": "MT",
      "county": "Powder River"
  },
  {
      "zipCode": 59344,
      "latitude": 46.283486,
      "longitude": -104.475079,
      "city": "Plevna",
      "state": "MT",
      "county": "Fallon"
  },
  {
      "zipCode": 59345,
      "latitude": 45.391078,
      "longitude": -105.630461,
      "city": "Powderville",
      "state": "MT",
      "county": "Powder River"
  },
  {
      "zipCode": 59347,
      "latitude": 46.267953,
      "longitude": -106.666959,
      "city": "Rosebud",
      "state": "MT",
      "county": "Rosebud"
  },
  {
      "zipCode": 59348,
      "latitude": 45.391078,
      "longitude": -105.630461,
      "city": "Sonnette",
      "state": "MT",
      "county": "Powder River"
  },
  {
      "zipCode": 59349,
      "latitude": 46.789256,
      "longitude": -105.312757,
      "city": "Terry",
      "state": "MT",
      "county": "Prairie"
  },
  {
      "zipCode": 59351,
      "latitude": 46.323967,
      "longitude": -105.462341,
      "city": "Volborg",
      "state": "MT",
      "county": "Custer"
  },
  {
      "zipCode": 59353,
      "latitude": 46.824454,
      "longitude": -104.122311,
      "city": "Wibaux",
      "state": "MT",
      "county": "Wibaux"
  },
  {
      "zipCode": 59354,
      "latitude": 46.283486,
      "longitude": -104.475079,
      "city": "Willard",
      "state": "MT",
      "county": "Fallon"
  },
  {
      "zipCode": 59401,
      "latitude": 47.402024,
      "longitude": -111.422948,
      "city": "Great Falls",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59402,
      "latitude": 47.510209,
      "longitude": -111.195906,
      "city": "Malmstrom A F B",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59403,
      "latitude": 47.258392,
      "longitude": -111.341975,
      "city": "Great Falls",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59404,
      "latitude": 47.554109,
      "longitude": -111.391407,
      "city": "Great Falls",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59405,
      "latitude": 47.277623,
      "longitude": -111.290803,
      "city": "Great Falls",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59406,
      "latitude": 47.619998,
      "longitude": -111.239305,
      "city": "Great Falls",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59410,
      "latitude": 47.444053,
      "longitude": -112.513362,
      "city": "Augusta",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59411,
      "latitude": 48.904169,
      "longitude": -113.298384,
      "city": "Babb",
      "state": "MT",
      "county": "Glacier"
  },
  {
      "zipCode": 59412,
      "latitude": 47.319621,
      "longitude": -110.884712,
      "city": "Belt",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59414,
      "latitude": 47.533156,
      "longitude": -111.234986,
      "city": "Black Eagle",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59416,
      "latitude": 48.065733,
      "longitude": -111.715365,
      "city": "Brady",
      "state": "MT",
      "county": "Pondera"
  },
  {
      "zipCode": 59417,
      "latitude": 48.649105,
      "longitude": -112.793626,
      "city": "Browning",
      "state": "MT",
      "county": "Glacier"
  },
  {
      "zipCode": 59418,
      "latitude": 47.248497,
      "longitude": -109.26308,
      "city": "Buffalo",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59419,
      "latitude": 48.052324,
      "longitude": -112.457343,
      "city": "Bynum",
      "state": "MT",
      "county": "Teton"
  },
  {
      "zipCode": 59420,
      "latitude": 47.861021,
      "longitude": -110.471075,
      "city": "Carter",
      "state": "MT",
      "county": "Chouteau"
  },
  {
      "zipCode": 59421,
      "latitude": 47.276562,
      "longitude": -111.730457,
      "city": "Cascade",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59422,
      "latitude": 47.824102,
      "longitude": -112.266872,
      "city": "Choteau",
      "state": "MT",
      "county": "Teton"
  },
  {
      "zipCode": 59424,
      "latitude": 47.248497,
      "longitude": -109.26308,
      "city": "Coffee Creek",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59425,
      "latitude": 48.182782,
      "longitude": -111.845931,
      "city": "Conrad",
      "state": "MT",
      "county": "Pondera"
  },
  {
      "zipCode": 59427,
      "latitude": 48.654298,
      "longitude": -112.93062,
      "city": "Cut Bank",
      "state": "MT",
      "county": "Glacier"
  },
  {
      "zipCode": 59430,
      "latitude": 47.432933,
      "longitude": -109.973645,
      "city": "Denton",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59432,
      "latitude": 48.181533,
      "longitude": -112.594329,
      "city": "Dupuyer",
      "state": "MT",
      "county": "Pondera"
  },
  {
      "zipCode": 59433,
      "latitude": 47.842372,
      "longitude": -111.795709,
      "city": "Dutton",
      "state": "MT",
      "county": "Teton"
  },
  {
      "zipCode": 59434,
      "latitude": 48.445686,
      "longitude": -113.218976,
      "city": "East Glacier Park",
      "state": "MT",
      "county": "Glacier"
  },
  {
      "zipCode": 59435,
      "latitude": 48.609072,
      "longitude": -111.731852,
      "city": "Ethridge",
      "state": "MT",
      "county": "Toole"
  },
  {
      "zipCode": 59436,
      "latitude": 47.61386,
      "longitude": -112.207191,
      "city": "Fairfield",
      "state": "MT",
      "county": "Teton"
  },
  {
      "zipCode": 59440,
      "latitude": 47.861021,
      "longitude": -110.471075,
      "city": "Floweree",
      "state": "MT",
      "county": "Chouteau"
  },
  {
      "zipCode": 59441,
      "latitude": 47.248497,
      "longitude": -109.26308,
      "city": "Forestgrove",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59442,
      "latitude": 47.67977,
      "longitude": -110.44038,
      "city": "Fort Benton",
      "state": "MT",
      "county": "Chouteau"
  },
  {
      "zipCode": 59443,
      "latitude": 47.545978,
      "longitude": -111.875624,
      "city": "Fort Shaw",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59444,
      "latitude": 48.811061,
      "longitude": -111.470386,
      "city": "Galata",
      "state": "MT",
      "county": "Toole"
  },
  {
      "zipCode": 59445,
      "latitude": 47.055715,
      "longitude": -109.468299,
      "city": "Garneill",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59446,
      "latitude": 47.600099,
      "longitude": -110.26891,
      "city": "Geraldine",
      "state": "MT",
      "county": "Chouteau"
  },
  {
      "zipCode": 59447,
      "latitude": 47.258941,
      "longitude": -110.494703,
      "city": "Geyser",
      "state": "MT",
      "county": "Judith Basin"
  },
  {
      "zipCode": 59448,
      "latitude": 48.297291,
      "longitude": -112.845587,
      "city": "Heart Butte",
      "state": "MT",
      "county": "Pondera"
  },
  {
      "zipCode": 59450,
      "latitude": 47.861021,
      "longitude": -110.471075,
      "city": "Highwood",
      "state": "MT",
      "county": "Chouteau"
  },
  {
      "zipCode": 59451,
      "latitude": 47.415174,
      "longitude": -109.395158,
      "city": "Hilger",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59452,
      "latitude": 46.996773,
      "longitude": -109.875615,
      "city": "Hobson",
      "state": "MT",
      "county": "Judith Basin"
  },
  {
      "zipCode": 59453,
      "latitude": 46.485721,
      "longitude": -109.834856,
      "city": "Judith Gap",
      "state": "MT",
      "county": "Wheatland"
  },
  {
      "zipCode": 59454,
      "latitude": 48.750313,
      "longitude": -111.579092,
      "city": "Kevin",
      "state": "MT",
      "county": "Toole"
  },
  {
      "zipCode": 59456,
      "latitude": 48.27717,
      "longitude": -111.828592,
      "city": "Ledger",
      "state": "MT",
      "county": "Pondera"
  },
  {
      "zipCode": 59457,
      "latitude": 47.250047,
      "longitude": -109.381996,
      "city": "Lewistown",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59460,
      "latitude": 47.936513,
      "longitude": -110.502597,
      "city": "Loma",
      "state": "MT",
      "county": "Chouteau"
  },
  {
      "zipCode": 59461,
      "latitude": 48.609072,
      "longitude": -111.731852,
      "city": "Lothair",
      "state": "MT",
      "county": "Liberty"
  },
  {
      "zipCode": 59462,
      "latitude": 47.051079,
      "longitude": -109.910548,
      "city": "Moccasin",
      "state": "MT",
      "county": "Judith Basin"
  },
  {
      "zipCode": 59463,
      "latitude": 47.05149,
      "longitude": -110.883026,
      "city": "Monarch",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59464,
      "latitude": 46.974485,
      "longitude": -109.693316,
      "city": "Moore",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59465,
      "latitude": 47.293879,
      "longitude": -111.153717,
      "city": "Neihart",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59466,
      "latitude": 48.825895,
      "longitude": -111.691145,
      "city": "Oilmont",
      "state": "MT",
      "county": "Toole"
  },
  {
      "zipCode": 59467,
      "latitude": 48.042871,
      "longitude": -112.316655,
      "city": "Pendroy",
      "state": "MT",
      "county": "Teton"
  },
  {
      "zipCode": 59468,
      "latitude": 47.726972,
      "longitude": -111.6938,
      "city": "Power",
      "state": "MT",
      "county": "Teton"
  },
  {
      "zipCode": 59469,
      "latitude": 47.044583,
      "longitude": -110.267003,
      "city": "Raynesford",
      "state": "MT",
      "county": "Judith Basin"
  },
  {
      "zipCode": 59471,
      "latitude": 47.308278,
      "longitude": -109.085342,
      "city": "Roy",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59472,
      "latitude": 47.408651,
      "longitude": -111.136243,
      "city": "Sand Coulee",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59473,
      "latitude": 48.65417,
      "longitude": -113.126262,
      "city": "Santa Rita",
      "state": "MT",
      "county": "Glacier"
  },
  {
      "zipCode": 59474,
      "latitude": 48.610551,
      "longitude": -111.73233,
      "city": "Shelby",
      "state": "MT",
      "county": "Toole"
  },
  {
      "zipCode": 59477,
      "latitude": 47.495784,
      "longitude": -111.886092,
      "city": "Simms",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59479,
      "latitude": 47.151357,
      "longitude": -110.222384,
      "city": "Stanford",
      "state": "MT",
      "county": "Judith Basin"
  },
  {
      "zipCode": 59480,
      "latitude": 47.25466,
      "longitude": -111.083851,
      "city": "Stockett",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59482,
      "latitude": 48.879199,
      "longitude": -111.735136,
      "city": "Sunburst",
      "state": "MT",
      "county": "Toole"
  },
  {
      "zipCode": 59483,
      "latitude": 47.4808,
      "longitude": -111.753303,
      "city": "Sun River",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59484,
      "latitude": 48.940067,
      "longitude": -111.849008,
      "city": "Sweet Grass",
      "state": "MT",
      "county": "Toole"
  },
  {
      "zipCode": 59485,
      "latitude": 47.353925,
      "longitude": -111.595425,
      "city": "Ulm",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59486,
      "latitude": 48.270833,
      "longitude": -112.311973,
      "city": "Valier",
      "state": "MT",
      "county": "Pondera"
  },
  {
      "zipCode": 59487,
      "latitude": 47.580761,
      "longitude": -111.637014,
      "city": "Vaughn",
      "state": "MT",
      "county": "Cascade"
  },
  {
      "zipCode": 59489,
      "latitude": 47.065531,
      "longitude": -109.430106,
      "city": "Winifred",
      "state": "MT",
      "county": "Fergus"
  },
  {
      "zipCode": 59501,
      "latitude": 48.663002,
      "longitude": -110.09437,
      "city": "Havre",
      "state": "MT",
      "county": "Hill"
  },
  {
      "zipCode": 59520,
      "latitude": 48.17641,
      "longitude": -110.311201,
      "city": "Big Sandy",
      "state": "MT",
      "county": "Chouteau"
  },
  {
      "zipCode": 59521,
      "latitude": 48.320405,
      "longitude": -110.215299,
      "city": "Box Elder",
      "state": "MT",
      "county": "Hill"
  },
  {
      "zipCode": 59522,
      "latitude": 48.510659,
      "longitude": -110.967372,
      "city": "Chester",
      "state": "MT",
      "county": "Liberty"
  },
  {
      "zipCode": 59523,
      "latitude": 48.487741,
      "longitude": -109.261678,
      "city": "Chinook",
      "state": "MT",
      "county": "Blaine"
  },
  {
      "zipCode": 59524,
      "latitude": 48.394962,
      "longitude": -108.246539,
      "city": "Dodson",
      "state": "MT",
      "county": "Phillips"
  },
  {
      "zipCode": 59525,
      "latitude": 48.571498,
      "longitude": -110.301301,
      "city": "Gildford",
      "state": "MT",
      "county": "Hill"
  },
  {
      "zipCode": 59526,
      "latitude": 48.655123,
      "longitude": -108.818157,
      "city": "Harlem",
      "state": "MT",
      "county": "Blaine"
  },
  {
      "zipCode": 59527,
      "latitude": 48.360516,
      "longitude": -108.89392,
      "city": "Hays",
      "state": "MT",
      "county": "Blaine"
  },
  {
      "zipCode": 59528,
      "latitude": 48.571498,
      "longitude": -110.301301,
      "city": "Hingham",
      "state": "MT",
      "county": "Hill"
  },
  {
      "zipCode": 59529,
      "latitude": 48.360516,
      "longitude": -108.89392,
      "city": "Hogeland",
      "state": "MT",
      "county": "Blaine"
  },
  {
      "zipCode": 59530,
      "latitude": 48.566602,
      "longitude": -110.110287,
      "city": "Inverness",
      "state": "MT",
      "county": "Hill"
  },
  {
      "zipCode": 59531,
      "latitude": 48.565402,
      "longitude": -111.075884,
      "city": "Joplin",
      "state": "MT",
      "county": "Liberty"
  },
  {
      "zipCode": 59532,
      "latitude": 48.449359,
      "longitude": -110.106003,
      "city": "Kremlin",
      "state": "MT",
      "county": "Hill"
  },
  {
      "zipCode": 59535,
      "latitude": 48.360516,
      "longitude": -108.89392,
      "city": "Lloyd",
      "state": "MT",
      "county": "Blaine"
  },
  {
      "zipCode": 59537,
      "latitude": 48.224749,
      "longitude": -108.035444,
      "city": "Loring",
      "state": "MT",
      "county": "Phillips"
  },
  {
      "zipCode": 59538,
      "latitude": 48.124479,
      "longitude": -107.783145,
      "city": "Malta",
      "state": "MT",
      "county": "Phillips"
  },
  {
      "zipCode": 59540,
      "latitude": 48.566602,
      "longitude": -110.110287,
      "city": "Rudyard",
      "state": "MT",
      "county": "Hill"
  },
  {
      "zipCode": 59542,
      "latitude": 48.360516,
      "longitude": -108.89392,
      "city": "Turner",
      "state": "MT",
      "county": "Blaine"
  },
  {
      "zipCode": 59544,
      "latitude": 48.224749,
      "longitude": -108.035444,
      "city": "Whitewater",
      "state": "MT",
      "county": "Phillips"
  },
  {
      "zipCode": 59545,
      "latitude": 48.50968,
      "longitude": -110.966631,
      "city": "Whitlash",
      "state": "MT",
      "county": "Liberty"
  },
  {
      "zipCode": 59546,
      "latitude": 48.224749,
      "longitude": -108.035444,
      "city": "Zortman",
      "state": "MT",
      "county": "Phillips"
  },
  {
      "zipCode": 59547,
      "latitude": 48.360516,
      "longitude": -108.89392,
      "city": "Zurich",
      "state": "MT",
      "county": "Blaine"
  },
  {
      "zipCode": 59601,
      "latitude": 46.70934,
      "longitude": -112.197616,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59602,
      "latitude": 46.707449,
      "longitude": -111.957961,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59604,
      "latitude": 46.6672,
      "longitude": -111.968877,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59620,
      "latitude": 47.184233,
      "longitude": -112.330214,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59623,
      "latitude": 46.590083,
      "longitude": -112.040173,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59624,
      "latitude": 46.610002,
      "longitude": -112.062393,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59625,
      "latitude": 46.601832,
      "longitude": -112.041346,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59626,
      "latitude": 47.184233,
      "longitude": -112.330214,
      "city": "Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59631,
      "latitude": 46.329577,
      "longitude": -112.196662,
      "city": "Basin",
      "state": "MT",
      "county": "Jefferson"
  },
  {
      "zipCode": 59632,
      "latitude": 46.141948,
      "longitude": -111.942989,
      "city": "Boulder",
      "state": "MT",
      "county": "Jefferson"
  },
  {
      "zipCode": 59633,
      "latitude": 47.184233,
      "longitude": -112.330214,
      "city": "Canyon Creek",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59634,
      "latitude": 46.388739,
      "longitude": -112.07162,
      "city": "Clancy",
      "state": "MT",
      "county": "Jefferson"
  },
  {
      "zipCode": 59635,
      "latitude": 46.633769,
      "longitude": -111.852594,
      "city": "East Helena",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59636,
      "latitude": 46.609677,
      "longitude": -112.121494,
      "city": "Fort Harrison",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59638,
      "latitude": 46.367883,
      "longitude": -112.023155,
      "city": "Jefferson City",
      "state": "MT",
      "county": "Jefferson"
  },
  {
      "zipCode": 59639,
      "latitude": 46.958253,
      "longitude": -112.623833,
      "city": "Lincoln",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59640,
      "latitude": 46.749945,
      "longitude": -112.299402,
      "city": "Marysville",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59641,
      "latitude": 46.206922,
      "longitude": -111.634364,
      "city": "Radersburg",
      "state": "MT",
      "county": "Broadwater"
  },
  {
      "zipCode": 59642,
      "latitude": 46.636196,
      "longitude": -110.965623,
      "city": "Ringling",
      "state": "MT",
      "county": "Meagher"
  },
  {
      "zipCode": 59643,
      "latitude": 46.285118,
      "longitude": -111.526373,
      "city": "Toston",
      "state": "MT",
      "county": "Broadwater"
  },
  {
      "zipCode": 59644,
      "latitude": 46.406137,
      "longitude": -111.399649,
      "city": "Townsend",
      "state": "MT",
      "county": "Broadwater"
  },
  {
      "zipCode": 59645,
      "latitude": 46.63316,
      "longitude": -111.050627,
      "city": "White Sulphur Springs",
      "state": "MT",
      "county": "Meagher"
  },
  {
      "zipCode": 59647,
      "latitude": 46.45447,
      "longitude": -111.650954,
      "city": "Winston",
      "state": "MT",
      "county": "Broadwater"
  },
  {
      "zipCode": 59648,
      "latitude": 47.085616,
      "longitude": -112.147602,
      "city": "Wolf Creek",
      "state": "MT",
      "county": "Lewis And Clark"
  },
  {
      "zipCode": 59701,
      "latitude": 45.997895,
      "longitude": -112.598765,
      "city": "Butte",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59702,
      "latitude": 45.905345,
      "longitude": -112.637705,
      "city": "Butte",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59703,
      "latitude": 45.905345,
      "longitude": -112.637705,
      "city": "Butte",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59707,
      "latitude": 45.905345,
      "longitude": -112.637705,
      "city": "Butte",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59710,
      "latitude": 45.193898,
      "longitude": -112.056807,
      "city": "Alder",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59711,
      "latitude": 46.15821,
      "longitude": -113.009421,
      "city": "Anaconda",
      "state": "MT",
      "county": "Deer Lodge"
  },
  {
      "zipCode": 59713,
      "latitude": 46.565725,
      "longitude": -112.649859,
      "city": "Avon",
      "state": "MT",
      "county": "Powell"
  },
  {
      "zipCode": 59714,
      "latitude": 45.426786,
      "longitude": -111.099517,
      "city": "Belgrade",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59715,
      "latitude": 45.809998,
      "longitude": -111.168212,
      "city": "Bozeman",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59716,
      "latitude": 45.27826,
      "longitude": -111.270773,
      "city": "Big Sky",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59717,
      "latitude": 45.627982,
      "longitude": -110.90128,
      "city": "Bozeman",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59718,
      "latitude": 45.66812,
      "longitude": -111.240393,
      "city": "Bozeman",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59719,
      "latitude": 45.627982,
      "longitude": -110.90128,
      "city": "Bozeman",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59720,
      "latitude": 44.992962,
      "longitude": -111.528472,
      "city": "Cameron",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59721,
      "latitude": 45.870517,
      "longitude": -111.889682,
      "city": "Cardwell",
      "state": "MT",
      "county": "Jefferson"
  },
  {
      "zipCode": 59722,
      "latitude": 46.401859,
      "longitude": -112.642136,
      "city": "Deer Lodge",
      "state": "MT",
      "county": "Powell"
  },
  {
      "zipCode": 59724,
      "latitude": 45.149805,
      "longitude": -112.70073,
      "city": "Dell",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59725,
      "latitude": 45.244373,
      "longitude": -112.99687,
      "city": "Dillon",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59727,
      "latitude": 45.905345,
      "longitude": -112.637705,
      "city": "Divide",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59728,
      "latitude": 46.503396,
      "longitude": -112.400742,
      "city": "Elliston",
      "state": "MT",
      "county": "Powell"
  },
  {
      "zipCode": 59729,
      "latitude": 45.289586,
      "longitude": -111.665136,
      "city": "Ennis",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59730,
      "latitude": 45.338987,
      "longitude": -111.24848,
      "city": "Gallatin Gateway",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59731,
      "latitude": 46.594845,
      "longitude": -112.775572,
      "city": "Garrison",
      "state": "MT",
      "county": "Powell"
  },
  {
      "zipCode": 59732,
      "latitude": 45.149805,
      "longitude": -112.70073,
      "city": "Glen",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59733,
      "latitude": 46.58791,
      "longitude": -112.936757,
      "city": "Gold Creek",
      "state": "MT",
      "county": "Powell"
  },
  {
      "zipCode": 59735,
      "latitude": 45.283594,
      "longitude": -112.027561,
      "city": "Harrison",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59736,
      "latitude": 45.368653,
      "longitude": -113.359826,
      "city": "Jackson",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59739,
      "latitude": 44.699033,
      "longitude": -112.663633,
      "city": "Lima",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59740,
      "latitude": 45.408045,
      "longitude": -111.776123,
      "city": "Mc Allister",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59741,
      "latitude": 45.771228,
      "longitude": -111.374118,
      "city": "Manhattan",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59743,
      "latitude": 45.905345,
      "longitude": -112.637705,
      "city": "Melrose",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59745,
      "latitude": 45.566095,
      "longitude": -111.721631,
      "city": "Norris",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59746,
      "latitude": 45.56265,
      "longitude": -113.018805,
      "city": "Polaris",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59747,
      "latitude": 45.283594,
      "longitude": -112.027561,
      "city": "Pony",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59748,
      "latitude": 46.028844,
      "longitude": -112.758914,
      "city": "Ramsay",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59749,
      "latitude": 45.309586,
      "longitude": -111.932378,
      "city": "Sheridan",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59750,
      "latitude": 45.939758,
      "longitude": -112.736528,
      "city": "Butte",
      "state": "MT",
      "county": "Silver Bow"
  },
  {
      "zipCode": 59751,
      "latitude": 45.700002,
      "longitude": -112.253501,
      "city": "Silver Star",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59752,
      "latitude": 45.375515,
      "longitude": -111.45624,
      "city": "Three Forks",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59754,
      "latitude": 45.492611,
      "longitude": -112.416508,
      "city": "Twin Bridges",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59755,
      "latitude": 45.325565,
      "longitude": -111.868085,
      "city": "Virginia City",
      "state": "MT",
      "county": "Madison"
  },
  {
      "zipCode": 59756,
      "latitude": 46.218279,
      "longitude": -112.811742,
      "city": "Warm Springs",
      "state": "MT",
      "county": "Deer Lodge"
  },
  {
      "zipCode": 59758,
      "latitude": 44.763016,
      "longitude": -111.237705,
      "city": "West Yellowstone",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59759,
      "latitude": 45.996345,
      "longitude": -112.088845,
      "city": "Whitehall",
      "state": "MT",
      "county": "Jefferson"
  },
  {
      "zipCode": 59760,
      "latitude": 45.782744,
      "longitude": -111.634532,
      "city": "Willow Creek",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59761,
      "latitude": 45.542434,
      "longitude": -113.473541,
      "city": "Wisdom",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59762,
      "latitude": 45.720048,
      "longitude": -113.250784,
      "city": "Wise River",
      "state": "MT",
      "county": "Beaverhead"
  },
  {
      "zipCode": 59771,
      "latitude": 45.72465,
      "longitude": -111.123775,
      "city": "Bozeman",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59772,
      "latitude": 45.636149,
      "longitude": -111.064676,
      "city": "Bozeman",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59773,
      "latitude": 45.627982,
      "longitude": -110.90128,
      "city": "Bozeman",
      "state": "MT",
      "county": "Gallatin"
  },
  {
      "zipCode": 59801,
      "latitude": 46.853606,
      "longitude": -113.909123,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59802,
      "latitude": 46.989689,
      "longitude": -113.954788,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59803,
      "latitude": 46.811758,
      "longitude": -113.987645,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59804,
      "latitude": 46.846708,
      "longitude": -114.169784,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59806,
      "latitude": 47.116034,
      "longitude": -114.049824,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59807,
      "latitude": 46.910342,
      "longitude": -113.958686,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59808,
      "latitude": 46.977603,
      "longitude": -114.061856,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59812,
      "latitude": 47.116034,
      "longitude": -114.049824,
      "city": "Missoula",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59820,
      "latitude": 46.989275,
      "longitude": -114.558456,
      "city": "Alberton",
      "state": "MT",
      "county": "Mineral"
  },
  {
      "zipCode": 59821,
      "latitude": 47.220055,
      "longitude": -114.024625,
      "city": "Arlee",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59823,
      "latitude": 46.870183,
      "longitude": -113.727871,
      "city": "Bonner",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59824,
      "latitude": 47.312398,
      "longitude": -114.176098,
      "city": "Charlo",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59825,
      "latitude": 46.778468,
      "longitude": -113.683052,
      "city": "Clinton",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59826,
      "latitude": 47.479334,
      "longitude": -113.677639,
      "city": "Condon",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59827,
      "latitude": 45.918972,
      "longitude": -114.05904,
      "city": "Conner",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59828,
      "latitude": 46.446801,
      "longitude": -114.117918,
      "city": "Corvallis",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59829,
      "latitude": 46.086434,
      "longitude": -114.176841,
      "city": "Darby",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59830,
      "latitude": 47.388827,
      "longitude": -115.347934,
      "city": "De Borgia",
      "state": "MT",
      "county": "Mineral"
  },
  {
      "zipCode": 59831,
      "latitude": 47.306957,
      "longitude": -114.328588,
      "city": "Dixon",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59832,
      "latitude": 46.687633,
      "longitude": -113.215445,
      "city": "Drummond",
      "state": "MT",
      "county": "Granite"
  },
  {
      "zipCode": 59833,
      "latitude": 46.617296,
      "longitude": -114.04192,
      "city": "Florence",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59834,
      "latitude": 47.065558,
      "longitude": -114.251384,
      "city": "Frenchtown",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59835,
      "latitude": 46.060169,
      "longitude": -114.040777,
      "city": "Grantsdale",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59836,
      "latitude": 47.013278,
      "longitude": -113.444116,
      "city": "Greenough",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59837,
      "latitude": 46.466704,
      "longitude": -113.23873,
      "city": "Hall",
      "state": "MT",
      "county": "Granite"
  },
  {
      "zipCode": 59840,
      "latitude": 46.234189,
      "longitude": -114.165774,
      "city": "Hamilton",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59841,
      "latitude": 46.332893,
      "longitude": -114.223493,
      "city": "Pinesdale",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59842,
      "latitude": 47.402824,
      "longitude": -115.516347,
      "city": "Haugan",
      "state": "MT",
      "county": "Mineral"
  },
  {
      "zipCode": 59843,
      "latitude": 46.863363,
      "longitude": -112.931166,
      "city": "Helmville",
      "state": "MT",
      "county": "Powell"
  },
  {
      "zipCode": 59844,
      "latitude": 48.047863,
      "longitude": -115.969619,
      "city": "Heron",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59845,
      "latitude": 47.633565,
      "longitude": -114.553693,
      "city": "Hot Springs",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59846,
      "latitude": 47.067886,
      "longitude": -114.398643,
      "city": "Huson",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59847,
      "latitude": 46.7454,
      "longitude": -114.299241,
      "city": "Lolo",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59848,
      "latitude": 47.725065,
      "longitude": -114.657042,
      "city": "Lonepine",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59851,
      "latitude": 46.873695,
      "longitude": -113.878294,
      "city": "Milltown",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59853,
      "latitude": 48.076781,
      "longitude": -115.858153,
      "city": "Noxon",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59854,
      "latitude": 47.035882,
      "longitude": -113.097461,
      "city": "Ovando",
      "state": "MT",
      "county": "Powell"
  },
  {
      "zipCode": 59855,
      "latitude": 47.651275,
      "longitude": -114.212413,
      "city": "Pablo",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59856,
      "latitude": 47.387824,
      "longitude": -114.799,
      "city": "Paradise",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59858,
      "latitude": 46.272241,
      "longitude": -113.435709,
      "city": "Philipsburg",
      "state": "MT",
      "county": "Granite"
  },
  {
      "zipCode": 59859,
      "latitude": 47.603143,
      "longitude": -115.083253,
      "city": "Plains",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59860,
      "latitude": 47.737279,
      "longitude": -114.230256,
      "city": "Polson",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59863,
      "latitude": 47.594957,
      "longitude": -114.101443,
      "city": "Ravalli",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59864,
      "latitude": 47.507347,
      "longitude": -114.178287,
      "city": "Ronan",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59865,
      "latitude": 47.37245,
      "longitude": -114.064086,
      "city": "Saint Ignatius",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59866,
      "latitude": 47.311106,
      "longitude": -115.140511,
      "city": "Saint Regis",
      "state": "MT",
      "county": "Mineral"
  },
  {
      "zipCode": 59867,
      "latitude": 47.399653,
      "longitude": -115.422358,
      "city": "Saltese",
      "state": "MT",
      "county": "Mineral"
  },
  {
      "zipCode": 59868,
      "latitude": 47.300776,
      "longitude": -113.548481,
      "city": "Seeley Lake",
      "state": "MT",
      "county": "Missoula"
  },
  {
      "zipCode": 59870,
      "latitude": 46.320508,
      "longitude": -114.047154,
      "city": "Stevensville",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59871,
      "latitude": 45.843266,
      "longitude": -113.874776,
      "city": "Sula",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59872,
      "latitude": 47.137198,
      "longitude": -114.747599,
      "city": "Superior",
      "state": "MT",
      "county": "Mineral"
  },
  {
      "zipCode": 59873,
      "latitude": 47.744885,
      "longitude": -115.407291,
      "city": "Thompson Falls",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59874,
      "latitude": 47.794472,
      "longitude": -115.538422,
      "city": "Trout Creek",
      "state": "MT",
      "county": "Sanders"
  },
  {
      "zipCode": 59875,
      "latitude": 46.395224,
      "longitude": -114.160159,
      "city": "Victor",
      "state": "MT",
      "county": "Ravalli"
  },
  {
      "zipCode": 59901,
      "latitude": 48.202765,
      "longitude": -114.303919,
      "city": "Kalispell",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59902,
      "latitude": 48.189424,
      "longitude": -114.143531,
      "city": "Kalispell",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59903,
      "latitude": 48.22372,
      "longitude": -114.429648,
      "city": "Kalispell",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59904,
      "latitude": 48.240382,
      "longitude": -114.256123,
      "city": "Kalispell",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59910,
      "latitude": 47.76942,
      "longitude": -114.281296,
      "city": "Big Arm",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59911,
      "latitude": 48.145854,
      "longitude": -113.921121,
      "city": "Bigfork",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59912,
      "latitude": 48.358322,
      "longitude": -114.345902,
      "city": "Columbia Falls",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59913,
      "latitude": 48.434243,
      "longitude": -114.058384,
      "city": "Coram",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59914,
      "latitude": 47.854921,
      "longitude": -114.281873,
      "city": "Dayton",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59915,
      "latitude": 47.696598,
      "longitude": -114.214962,
      "city": "Elmo",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59916,
      "latitude": 48.324238,
      "longitude": -113.893384,
      "city": "Essex",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59917,
      "latitude": 48.729135,
      "longitude": -115.345992,
      "city": "Eureka",
      "state": "MT",
      "county": "Lincoln"
  },
  {
      "zipCode": 59918,
      "latitude": 48.758601,
      "longitude": -114.880925,
      "city": "Fortine",
      "state": "MT",
      "county": "Lincoln"
  },
  {
      "zipCode": 59919,
      "latitude": 48.279332,
      "longitude": -113.910545,
      "city": "Hungry Horse",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59920,
      "latitude": 48.047758,
      "longitude": -114.513752,
      "city": "Kila",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59921,
      "latitude": 48.298875,
      "longitude": -113.949739,
      "city": "Lake Mc Donald",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59922,
      "latitude": 48.17669,
      "longitude": -114.234902,
      "city": "Lakeside",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59923,
      "latitude": 48.380992,
      "longitude": -115.394062,
      "city": "Libby",
      "state": "MT",
      "county": "Lincoln"
  },
  {
      "zipCode": 59925,
      "latitude": 48.09368,
      "longitude": -114.727422,
      "city": "Marion",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59926,
      "latitude": 48.400447,
      "longitude": -114.087311,
      "city": "Martin City",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59927,
      "latitude": 48.545453,
      "longitude": -114.591981,
      "city": "Olney",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59928,
      "latitude": 48.77808,
      "longitude": -114.338774,
      "city": "Polebridge",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59929,
      "latitude": 47.907058,
      "longitude": -114.341333,
      "city": "Proctor",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59930,
      "latitude": 48.867183,
      "longitude": -115.118207,
      "city": "Rexford",
      "state": "MT",
      "county": "Lincoln"
  },
  {
      "zipCode": 59931,
      "latitude": 47.899392,
      "longitude": -114.206827,
      "city": "Rollins",
      "state": "MT",
      "county": "Lake"
  },
  {
      "zipCode": 59932,
      "latitude": 48.06771,
      "longitude": -114.2017,
      "city": "Somers",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59933,
      "latitude": 48.44572,
      "longitude": -115.330964,
      "city": "Stryker",
      "state": "MT",
      "county": "Lincoln"
  },
  {
      "zipCode": 59934,
      "latitude": 48.44572,
      "longitude": -115.330964,
      "city": "Trego",
      "state": "MT",
      "county": "Lincoln"
  },
  {
      "zipCode": 59935,
      "latitude": 48.580494,
      "longitude": -115.816915,
      "city": "Troy",
      "state": "MT",
      "county": "Lincoln"
  },
  {
      "zipCode": 59936,
      "latitude": 48.432962,
      "longitude": -114.062198,
      "city": "West Glacier",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 59937,
      "latitude": 48.274564,
      "longitude": -114.365126,
      "city": "Whitefish",
      "state": "MT",
      "county": "Flathead"
  },
  {
      "zipCode": 60001,
      "latitude": 42.324761,
      "longitude": -88.452481,
      "city": "Alden",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60002,
      "latitude": 42.451419,
      "longitude": -88.075733,
      "city": "Antioch",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60004,
      "latitude": 42.085626,
      "longitude": -87.99822,
      "city": "Arlington Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60005,
      "latitude": 42.060928,
      "longitude": -87.883073,
      "city": "Arlington Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60006,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Arlington Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60007,
      "latitude": 42.020278,
      "longitude": -87.905866,
      "city": "Elk Grove Village",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60008,
      "latitude": 42.074226,
      "longitude": -88.026871,
      "city": "Rolling Meadows",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60009,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Elk Grove Village",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60010,
      "latitude": 42.2735,
      "longitude": -88.072583,
      "city": "Barrington",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60011,
      "latitude": 42.322814,
      "longitude": -87.610053,
      "city": "Barrington",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60012,
      "latitude": 42.232096,
      "longitude": -88.305292,
      "city": "Crystal Lake",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60013,
      "latitude": 42.204626,
      "longitude": -88.250783,
      "city": "Cary",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60014,
      "latitude": 42.243772,
      "longitude": -88.323986,
      "city": "Crystal Lake",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60015,
      "latitude": 42.178175,
      "longitude": -87.866818,
      "city": "Deerfield",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60016,
      "latitude": 42.048278,
      "longitude": -87.890466,
      "city": "Des Plaines",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60017,
      "latitude": 42.028779,
      "longitude": -87.894366,
      "city": "Des Plaines",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60018,
      "latitude": 42.015479,
      "longitude": -87.868714,
      "city": "Des Plaines",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60019,
      "latitude": 42.024278,
      "longitude": -87.907066,
      "city": "Des Plaines",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60020,
      "latitude": 42.40247,
      "longitude": -88.170234,
      "city": "Fox Lake",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60021,
      "latitude": 42.213058,
      "longitude": -88.250406,
      "city": "Fox River Grove",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60022,
      "latitude": 42.102927,
      "longitude": -87.815914,
      "city": "Glencoe",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60025,
      "latitude": 42.082715,
      "longitude": -87.819714,
      "city": "Glenview",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60026,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Glenview Nas",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60029,
      "latitude": 42.058029,
      "longitude": -87.791636,
      "city": "Golf",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60030,
      "latitude": 42.352423,
      "longitude": -88.054502,
      "city": "Grayslake",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60031,
      "latitude": 42.375121,
      "longitude": -87.939683,
      "city": "Gurnee",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60033,
      "latitude": 42.362962,
      "longitude": -88.495375,
      "city": "Harvard",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60034,
      "latitude": 42.455151,
      "longitude": -88.430802,
      "city": "Hebron",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60035,
      "latitude": 42.187424,
      "longitude": -87.811865,
      "city": "Highland Park",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60037,
      "latitude": 42.255073,
      "longitude": -87.818268,
      "city": "Fort Sheridan",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60038,
      "latitude": 42.097976,
      "longitude": -88.014072,
      "city": "Palatine",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60039,
      "latitude": 42.324761,
      "longitude": -88.452481,
      "city": "Crystal Lake",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60040,
      "latitude": 42.207374,
      "longitude": -87.814666,
      "city": "Highwood",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60041,
      "latitude": 42.366521,
      "longitude": -88.145683,
      "city": "Ingleside",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60042,
      "latitude": 42.27179,
      "longitude": -88.188464,
      "city": "Island Lake",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60043,
      "latitude": 42.088428,
      "longitude": -87.71456,
      "city": "Kenilworth",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60044,
      "latitude": 42.294172,
      "longitude": -87.86767,
      "city": "Lake Bluff",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60045,
      "latitude": 42.240027,
      "longitude": -87.877569,
      "city": "Lake Forest",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60046,
      "latitude": 42.381271,
      "longitude": -87.999077,
      "city": "Lake Villa",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60047,
      "latitude": 42.216543,
      "longitude": -88.076942,
      "city": "Lake Zurich",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60048,
      "latitude": 42.28947,
      "longitude": -87.957724,
      "city": "Libertyville",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60049,
      "latitude": 42.198674,
      "longitude": -88.041875,
      "city": "Long Grove",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60050,
      "latitude": 42.296671,
      "longitude": -88.320886,
      "city": "Mchenry",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60051,
      "latitude": 42.324761,
      "longitude": -88.452481,
      "city": "Mchenry",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60053,
      "latitude": 42.039329,
      "longitude": -87.793815,
      "city": "Morton Grove",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60055,
      "latitude": 42.097976,
      "longitude": -88.014072,
      "city": "Palatine",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60056,
      "latitude": 42.062377,
      "longitude": -87.931797,
      "city": "Mount Prospect",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60060,
      "latitude": 42.252523,
      "longitude": -88.027327,
      "city": "Mundelein",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60061,
      "latitude": 42.231183,
      "longitude": -87.962973,
      "city": "Vernon Hills",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60062,
      "latitude": 42.120176,
      "longitude": -87.838815,
      "city": "Northbrook",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60064,
      "latitude": 42.347324,
      "longitude": -87.962976,
      "city": "North Chicago",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60065,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Northbrook",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60067,
      "latitude": 42.101977,
      "longitude": -88.055804,
      "city": "Palatine",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60068,
      "latitude": 42.037228,
      "longitude": -87.843714,
      "city": "Park Ridge",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60069,
      "latitude": 42.309143,
      "longitude": -87.987213,
      "city": "Lincolnshire",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60070,
      "latitude": 42.105805,
      "longitude": -87.939519,
      "city": "Prospect Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60071,
      "latitude": 42.328463,
      "longitude": -88.429864,
      "city": "Richmond",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60072,
      "latitude": 42.404829,
      "longitude": -88.305439,
      "city": "Ringwood",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60073,
      "latitude": 42.279215,
      "longitude": -88.050226,
      "city": "Round Lake",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60074,
      "latitude": 42.129875,
      "longitude": -88.049424,
      "city": "Palatine",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60075,
      "latitude": 42.322814,
      "longitude": -87.610053,
      "city": "Russell",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60076,
      "latitude": 42.057878,
      "longitude": -87.883566,
      "city": "Skokie",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60077,
      "latitude": 42.03468,
      "longitude": -87.76266,
      "city": "Skokie",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60078,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Palatine",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60079,
      "latitude": 42.322814,
      "longitude": -87.610053,
      "city": "Waukegan",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60080,
      "latitude": 42.442519,
      "longitude": -88.276047,
      "city": "Solon Mills",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60081,
      "latitude": 42.367273,
      "longitude": -88.252639,
      "city": "Spring Grove",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60082,
      "latitude": 42.116377,
      "longitude": -87.812064,
      "city": "Techny",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60083,
      "latitude": 42.440139,
      "longitude": -87.940027,
      "city": "Wadsworth",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60084,
      "latitude": 42.249873,
      "longitude": -88.073127,
      "city": "Wauconda",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60085,
      "latitude": 42.327366,
      "longitude": -87.982025,
      "city": "Waukegan",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60086,
      "latitude": 42.43335,
      "longitude": -87.776595,
      "city": "North Chicago",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60087,
      "latitude": 42.372921,
      "longitude": -87.868471,
      "city": "Waukegan",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60088,
      "latitude": 42.309272,
      "longitude": -87.85427,
      "city": "Great Lakes",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60089,
      "latitude": 42.180411,
      "longitude": -87.960771,
      "city": "Buffalo Grove",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60090,
      "latitude": 42.124176,
      "longitude": -87.933819,
      "city": "Wheeling",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60091,
      "latitude": 42.079228,
      "longitude": -87.72836,
      "city": "Wilmette",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60092,
      "latitude": 42.322814,
      "longitude": -87.610053,
      "city": "Libertyville",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60093,
      "latitude": 42.112176,
      "longitude": -87.940168,
      "city": "Winnetka",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60094,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Palatine",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60095,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Palatine",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60096,
      "latitude": 42.47832,
      "longitude": -87.834222,
      "city": "Winthrop Harbor",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60097,
      "latitude": 42.355165,
      "longitude": -88.332794,
      "city": "Wonder Lake",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60098,
      "latitude": 42.320271,
      "longitude": -88.462832,
      "city": "Woodstock",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60099,
      "latitude": 42.45577,
      "longitude": -87.884975,
      "city": "Zion",
      "state": "IL",
      "county": "Lake"
  },
  {
      "zipCode": 60101,
      "latitude": 41.887579,
      "longitude": -88.022516,
      "city": "Addison",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60102,
      "latitude": 42.280715,
      "longitude": -88.461937,
      "city": "Algonquin",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60103,
      "latitude": 42.033376,
      "longitude": -88.157756,
      "city": "Bartlett",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60104,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Bellwood",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60105,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Bensenville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60106,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Bensenville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60107,
      "latitude": 42.019093,
      "longitude": -88.178475,
      "city": "Streamwood",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60108,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Bloomingdale",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60109,
      "latitude": 42.045792,
      "longitude": -88.539027,
      "city": "Burlington",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60110,
      "latitude": 42.125008,
      "longitude": -88.297238,
      "city": "Carpentersville",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60111,
      "latitude": 42.008626,
      "longitude": -88.83055,
      "city": "Clare",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60112,
      "latitude": 41.926177,
      "longitude": -88.684007,
      "city": "Cortland",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60113,
      "latitude": 41.931158,
      "longitude": -88.956564,
      "city": "Creston",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 60115,
      "latitude": 41.816826,
      "longitude": -88.79101,
      "city": "Dekalb",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60116,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Carol Stream",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60117,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Bloomingdale",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60118,
      "latitude": 42.022867,
      "longitude": -88.417789,
      "city": "Dundee",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60119,
      "latitude": 41.834928,
      "longitude": -88.469931,
      "city": "Elburn",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60120,
      "latitude": 41.990689,
      "longitude": -88.429777,
      "city": "Elgin",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60121,
      "latitude": 42.04133,
      "longitude": -88.3126,
      "city": "Elgin",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60122,
      "latitude": 42.067101,
      "longitude": -88.304994,
      "city": "Elgin",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60123,
      "latitude": 42.036325,
      "longitude": -88.371044,
      "city": "Elgin",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60125,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Carol Stream",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60126,
      "latitude": 41.88353,
      "longitude": -87.946413,
      "city": "Elmhurst",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60128,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Carol Stream",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60129,
      "latitude": 42.042932,
      "longitude": -88.919791,
      "city": "Esmond",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60130,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Forest Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60131,
      "latitude": 41.93598,
      "longitude": -87.884962,
      "city": "Franklin Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60132,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Carol Stream",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60133,
      "latitude": 41.977842,
      "longitude": -88.143199,
      "city": "Hanover Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60134,
      "latitude": 41.97974,
      "longitude": -88.406165,
      "city": "Geneva",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60135,
      "latitude": 42.10153,
      "longitude": -88.718101,
      "city": "Genoa",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60136,
      "latitude": 42.102524,
      "longitude": -88.379932,
      "city": "Gilberts",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60137,
      "latitude": 41.872034,
      "longitude": -88.029385,
      "city": "Glen Ellyn",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60138,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Glen Ellyn",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60139,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Glendale Heights",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60140,
      "latitude": 42.027886,
      "longitude": -88.454812,
      "city": "Hampshire",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60141,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Hines",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60142,
      "latitude": 42.197379,
      "longitude": -88.450815,
      "city": "Huntley",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60143,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Itasca",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60144,
      "latitude": 41.936977,
      "longitude": -88.420178,
      "city": "Kaneville",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60145,
      "latitude": 42.090787,
      "longitude": -88.762765,
      "city": "Kingston",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60146,
      "latitude": 42.087039,
      "longitude": -88.871153,
      "city": "Kirkland",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60147,
      "latitude": 41.936977,
      "longitude": -88.420178,
      "city": "Lafox",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60148,
      "latitude": 41.869829,
      "longitude": -88.003864,
      "city": "Lombard",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60150,
      "latitude": 41.931731,
      "longitude": -88.88516,
      "city": "Malta",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60151,
      "latitude": 41.922915,
      "longitude": -88.507929,
      "city": "Maple Park",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60152,
      "latitude": 42.255176,
      "longitude": -88.607897,
      "city": "Marengo",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60153,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Maywood",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60154,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Westchester",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60155,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Broadview",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60156,
      "latitude": 42.203063,
      "longitude": -88.320474,
      "city": "Lake In The Hills",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60157,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Medinah",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60159,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60160,
      "latitude": 41.91488,
      "longitude": -87.858707,
      "city": "Melrose Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60161,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Melrose Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60162,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Hillside",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60163,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Berkeley",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60164,
      "latitude": 41.92138,
      "longitude": -87.892412,
      "city": "Melrose Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60165,
      "latitude": 41.90743,
      "longitude": -87.878011,
      "city": "Stone Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60168,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60170,
      "latitude": 42.025776,
      "longitude": -88.425931,
      "city": "Plato Center",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60171,
      "latitude": 41.923168,
      "longitude": -87.83931,
      "city": "River Grove",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60172,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Roselle",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60173,
      "latitude": 42.079837,
      "longitude": -88.046403,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60174,
      "latitude": 41.937151,
      "longitude": -88.430167,
      "city": "Saint Charles",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60175,
      "latitude": 41.943171,
      "longitude": -88.386905,
      "city": "Saint Charles",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60176,
      "latitude": 41.95918,
      "longitude": -87.873412,
      "city": "Schiller Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60177,
      "latitude": 42.007476,
      "longitude": -88.405895,
      "city": "South Elgin",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60178,
      "latitude": 41.893687,
      "longitude": -88.68562,
      "city": "Sycamore",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60179,
      "latitude": 42.079336,
      "longitude": -88.223655,
      "city": "Hoffman Estates",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60180,
      "latitude": 42.222569,
      "longitude": -88.525091,
      "city": "Union",
      "state": "IL",
      "county": "Mchenry"
  },
  {
      "zipCode": 60181,
      "latitude": 41.875379,
      "longitude": -87.97643,
      "city": "Villa Park",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60182,
      "latitude": 41.908736,
      "longitude": -88.59915,
      "city": "Virgil",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60183,
      "latitude": 41.936977,
      "longitude": -88.420178,
      "city": "Wasco",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60184,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Wayne",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60185,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "West Chicago",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60186,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "West Chicago",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60187,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Wheaton",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60188,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Carol Stream",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60189,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Wheaton",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60190,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Winfield",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60191,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Wood Dale",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60192,
      "latitude": 42.092487,
      "longitude": -88.116129,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60193,
      "latitude": 42.016827,
      "longitude": -88.117844,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60194,
      "latitude": 42.028926,
      "longitude": -88.116655,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60195,
      "latitude": 42.076376,
      "longitude": -88.109263,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60196,
      "latitude": 42.056376,
      "longitude": -88.072522,
      "city": "Schaumburg",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60197,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Carol Stream",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60199,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Carol Stream",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60201,
      "latitude": 42.049148,
      "longitude": -87.702155,
      "city": "Evanston",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60202,
      "latitude": 42.044529,
      "longitude": -87.687969,
      "city": "Evanston",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60203,
      "latitude": 42.047928,
      "longitude": -87.718159,
      "city": "Evanston",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60204,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Evanston",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60208,
      "latitude": 42.058629,
      "longitude": -87.684506,
      "city": "Evanston",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60209,
      "latitude": 42.04973,
      "longitude": -87.679408,
      "city": "Evanston",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60301,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Oak Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60302,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Oak Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60303,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Oak Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60304,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Oak Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60305,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "River Forest",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60399,
      "latitude": 41.987574,
      "longitude": -87.979792,
      "city": "Bensenville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60401,
      "latitude": 41.356872,
      "longitude": -87.626723,
      "city": "Beecher",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60402,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Berwyn",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60406,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Blue Island",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60407,
      "latitude": 41.238676,
      "longitude": -88.278834,
      "city": "Braceville",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60408,
      "latitude": 41.285878,
      "longitude": -88.017171,
      "city": "Braidwood",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60409,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Calumet City",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60410,
      "latitude": 41.43313,
      "longitude": -88.199325,
      "city": "Channahon",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60411,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60412,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60415,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago Ridge",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60416,
      "latitude": 41.284033,
      "longitude": -88.374819,
      "city": "Coal City",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60417,
      "latitude": 41.426684,
      "longitude": -87.621223,
      "city": "Crete",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60419,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Dolton",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60420,
      "latitude": 41.059551,
      "longitude": -88.417086,
      "city": "Dwight",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 60421,
      "latitude": 41.44288,
      "longitude": -88.090588,
      "city": "Elwood",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60422,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Flossmoor",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60423,
      "latitude": 41.451744,
      "longitude": -87.896109,
      "city": "Frankfort",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60424,
      "latitude": 41.1775,
      "longitude": -88.338018,
      "city": "Gardner",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60425,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Glenwood",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60426,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Harvey",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60429,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Hazel Crest",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60430,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Homewood",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60431,
      "latitude": 41.471206,
      "longitude": -87.93909,
      "city": "Joliet",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60432,
      "latitude": 41.54273,
      "longitude": -88.045146,
      "city": "Joliet",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60433,
      "latitude": 41.476265,
      "longitude": -87.882249,
      "city": "Joliet",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60434,
      "latitude": 41.52543,
      "longitude": -88.084208,
      "city": "Joliet",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60435,
      "latitude": 41.468729,
      "longitude": -87.938034,
      "city": "Joliet",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60436,
      "latitude": 41.48843,
      "longitude": -88.15716,
      "city": "Joliet",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60437,
      "latitude": 41.188078,
      "longitude": -88.472407,
      "city": "Kinsman",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60438,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Lansing",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60439,
      "latitude": 41.707431,
      "longitude": -87.975568,
      "city": "Lemont",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60440,
      "latitude": 41.623336,
      "longitude": -88.022464,
      "city": "Bolingbrook",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60441,
      "latitude": 41.552615,
      "longitude": -88.031268,
      "city": "Lockport",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60442,
      "latitude": 41.491903,
      "longitude": -87.979113,
      "city": "Manhattan",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60443,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Matteson",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60444,
      "latitude": 41.226425,
      "longitude": -88.421748,
      "city": "Mazon",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60445,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Midlothian",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60446,
      "latitude": 41.64038,
      "longitude": -88.069621,
      "city": "Romeoville",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60447,
      "latitude": 41.429869,
      "longitude": -88.422884,
      "city": "Minooka",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60448,
      "latitude": 41.426002,
      "longitude": -88.006767,
      "city": "Mokena",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60449,
      "latitude": 41.412269,
      "longitude": -87.786645,
      "city": "Monee",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60450,
      "latitude": 41.337433,
      "longitude": -88.430183,
      "city": "Morris",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60451,
      "latitude": 41.510802,
      "longitude": -87.961808,
      "city": "New Lenox",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60452,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Oak Forest",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60453,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Oak Lawn",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60454,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Oak Lawn",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60455,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Bridgeview",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60456,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Hometown",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60457,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Hickory Hills",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60458,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Justice",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60459,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Burbank",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60460,
      "latitude": 40.998811,
      "longitude": -88.538538,
      "city": "Odell",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 60461,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Olympia Fields",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60462,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Orland Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60463,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Palos Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60464,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Palos Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60465,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Palos Hills",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60466,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Park Forest",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60467,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Orland Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60468,
      "latitude": 41.346316,
      "longitude": -87.772133,
      "city": "Peotone",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60469,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Posen",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60470,
      "latitude": 41.172532,
      "longitude": -88.663755,
      "city": "Ransom",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 60471,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Richton Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60472,
      "latitude": 41.95273,
      "longitude": -87.878888,
      "city": "Robbins",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60473,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "South Holland",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60474,
      "latitude": 41.171883,
      "longitude": -88.267338,
      "city": "South Wilmington",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60475,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Steger",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60476,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Thornton",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60477,
      "latitude": 42.143475,
      "longitude": -88.026517,
      "city": "Tinley Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60478,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Country Club Hills",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60479,
      "latitude": 41.247346,
      "longitude": -88.505582,
      "city": "Verona",
      "state": "IL",
      "county": "Grundy"
  },
  {
      "zipCode": 60480,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Willow Springs",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60481,
      "latitude": 41.35934,
      "longitude": -88.084716,
      "city": "Wilmington",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60482,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Worth",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60490,
      "latitude": 41.679041,
      "longitude": -88.140332,
      "city": "Bolingbrook",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60499,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Bedford Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60501,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Summit Argo",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60504,
      "latitude": 41.768399,
      "longitude": -88.136616,
      "city": "Aurora",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60505,
      "latitude": 41.765478,
      "longitude": -88.405446,
      "city": "Aurora",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60506,
      "latitude": 41.79083,
      "longitude": -88.416837,
      "city": "Aurora",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60507,
      "latitude": 41.936977,
      "longitude": -88.420178,
      "city": "Aurora",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60510,
      "latitude": 41.828292,
      "longitude": -88.407334,
      "city": "Batavia",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60511,
      "latitude": 41.764324,
      "longitude": -88.54574,
      "city": "Big Rock",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60512,
      "latitude": 41.698414,
      "longitude": -88.427873,
      "city": "Bristol",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60513,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Brookfield",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60514,
      "latitude": 41.774295,
      "longitude": -87.966094,
      "city": "Clarendon Hills",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60515,
      "latitude": 41.796279,
      "longitude": -88.012267,
      "city": "Downers Grove",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60516,
      "latitude": 41.75231,
      "longitude": -88.021797,
      "city": "Downers Grove",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60517,
      "latitude": 41.736788,
      "longitude": -88.041731,
      "city": "Woodridge",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60518,
      "latitude": 41.549922,
      "longitude": -88.935981,
      "city": "Earlville",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 60519,
      "latitude": 41.777235,
      "longitude": -88.246193,
      "city": "Eola",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60520,
      "latitude": 41.740713,
      "longitude": -88.686052,
      "city": "Hinckley",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60521,
      "latitude": 41.78213,
      "longitude": -87.966845,
      "city": "Hinsdale",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60522,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Hinsdale",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60523,
      "latitude": 41.83713,
      "longitude": -87.963759,
      "city": "Oak Brook",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60525,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "La Grange",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60526,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "La Grange Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60527,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Willowbrook",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60530,
      "latitude": 41.784398,
      "longitude": -88.97506,
      "city": "Lee",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 60531,
      "latitude": 41.47805,
      "longitude": -88.7265,
      "city": "Leland",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 60532,
      "latitude": 41.79818,
      "longitude": -88.037714,
      "city": "Lisle",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60534,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Lyons",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60536,
      "latitude": 41.590315,
      "longitude": -88.562023,
      "city": "Millbrook",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60537,
      "latitude": 41.561386,
      "longitude": -88.59747,
      "city": "Millington",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60538,
      "latitude": 41.643752,
      "longitude": -88.457951,
      "city": "Montgomery",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60539,
      "latitude": 41.823928,
      "longitude": -88.334023,
      "city": "Mooseheart",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60540,
      "latitude": 41.759029,
      "longitude": -88.152381,
      "city": "Naperville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60541,
      "latitude": 41.540661,
      "longitude": -88.492909,
      "city": "Newark",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60542,
      "latitude": 41.807369,
      "longitude": -88.335182,
      "city": "North Aurora",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60543,
      "latitude": 41.631012,
      "longitude": -88.420476,
      "city": "Oswego",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60544,
      "latitude": 41.632457,
      "longitude": -88.169698,
      "city": "Plainfield",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60545,
      "latitude": 41.628567,
      "longitude": -88.513814,
      "city": "Plano",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60546,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Riverside",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60548,
      "latitude": 41.702091,
      "longitude": -88.688906,
      "city": "Sandwich",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60549,
      "latitude": 41.48974,
      "longitude": -88.752495,
      "city": "Serena",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 60550,
      "latitude": 41.746774,
      "longitude": -88.836821,
      "city": "Shabbona",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60551,
      "latitude": 41.521219,
      "longitude": -88.710974,
      "city": "Sheridan",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 60552,
      "latitude": 41.860645,
      "longitude": -88.783041,
      "city": "Somonauk",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60553,
      "latitude": 41.796742,
      "longitude": -89.086129,
      "city": "Steward",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 60554,
      "latitude": 41.776787,
      "longitude": -88.451655,
      "city": "Sugar Grove",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60555,
      "latitude": 41.805422,
      "longitude": -88.171551,
      "city": "Warrenville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60556,
      "latitude": 41.755215,
      "longitude": -88.778554,
      "city": "Waterman",
      "state": "IL",
      "county": "De Kalb"
  },
  {
      "zipCode": 60557,
      "latitude": 41.44088,
      "longitude": -88.770327,
      "city": "Wedron",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 60558,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Western Springs",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60559,
      "latitude": 41.799876,
      "longitude": -87.970713,
      "city": "Westmont",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60560,
      "latitude": 41.601679,
      "longitude": -88.437533,
      "city": "Yorkville",
      "state": "IL",
      "county": "Kendall"
  },
  {
      "zipCode": 60561,
      "latitude": 41.743437,
      "longitude": -87.98046,
      "city": "Darien",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60563,
      "latitude": 41.782455,
      "longitude": -88.149884,
      "city": "Naperville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60564,
      "latitude": 41.681479,
      "longitude": -88.201424,
      "city": "Naperville",
      "state": "IL",
      "county": "Will"
  },
  {
      "zipCode": 60565,
      "latitude": 41.737929,
      "longitude": -88.112714,
      "city": "Naperville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60566,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Naperville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60567,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Naperville",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60568,
      "latitude": 41.935616,
      "longitude": -88.43238,
      "city": "Aurora",
      "state": "IL",
      "county": "Kane"
  },
  {
      "zipCode": 60570,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Hinsdale",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60572,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Aurora",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60597,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Fox Valley",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60598,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Aurora",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60599,
      "latitude": 41.839679,
      "longitude": -88.088716,
      "city": "Fox Valley",
      "state": "IL",
      "county": "Du Page"
  },
  {
      "zipCode": 60601,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60602,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60603,
      "latitude": 42.055179,
      "longitude": -87.72596,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60604,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60605,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60606,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60607,
      "latitude": 41.928881,
      "longitude": -87.81381,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60608,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60609,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60610,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60611,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60612,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60613,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60614,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60615,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60616,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60617,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60618,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60619,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60620,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60621,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60622,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60623,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60624,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60625,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60626,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60628,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60629,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60630,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60631,
      "latitude": 42.00583,
      "longitude": -87.808611,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60632,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60633,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60634,
      "latitude": 41.933355,
      "longitude": -87.816309,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60636,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60637,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60638,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60639,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60640,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60641,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60643,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60644,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60645,
      "latitude": 42.00808,
      "longitude": -87.721458,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60646,
      "latitude": 42.00663,
      "longitude": -87.75901,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60647,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60649,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60651,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60652,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60653,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60654,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60655,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60656,
      "latitude": 41.97353,
      "longitude": -87.865763,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60657,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60659,
      "latitude": 41.997247,
      "longitude": -87.716621,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60660,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60661,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60663,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60664,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60665,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60666,
      "latitude": 41.968029,
      "longitude": -87.891214,
      "city": "Amf Ohare",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60667,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60668,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60669,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60670,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60671,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60672,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60673,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60674,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60675,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60677,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60678,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60679,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60680,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60681,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60683,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60684,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60685,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60687,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60688,
      "latitude": 41.952307,
      "longitude": -87.90975,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60690,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60691,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60693,
      "latitude": 42.096428,
      "longitude": -87.71791,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60694,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60696,
      "latitude": 41.879106,
      "longitude": -87.630772,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60697,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60699,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60701,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60706,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Harwood Heights",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60707,
      "latitude": 41.923181,
      "longitude": -87.818509,
      "city": "Elmwood Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60712,
      "latitude": 42.00798,
      "longitude": -87.736135,
      "city": "Lincolnwood",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60714,
      "latitude": 42.031179,
      "longitude": -87.811163,
      "city": "Niles",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60803,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Alsip",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60804,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Cicero",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60805,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Evergreen Park",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60827,
      "latitude": 41.811929,
      "longitude": -87.68732,
      "city": "Riverdale",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": 60901,
      "latitude": 41.111151,
      "longitude": -87.874618,
      "city": "Kankakee",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60902,
      "latitude": 41.146464,
      "longitude": -87.888971,
      "city": "Kankakee",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60910,
      "latitude": 41.091582,
      "longitude": -87.684284,
      "city": "Aroma Park",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60911,
      "latitude": 40.876259,
      "longitude": -87.973929,
      "city": "Ashkum",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60912,
      "latitude": 40.963099,
      "longitude": -87.625942,
      "city": "Beaverville",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60913,
      "latitude": 41.1388,
      "longitude": -88.053534,
      "city": "Bonfield",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60914,
      "latitude": 41.180968,
      "longitude": -87.847038,
      "city": "Bourbonnais",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60915,
      "latitude": 41.207762,
      "longitude": -87.842583,
      "city": "Bradley",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60917,
      "latitude": 41.056333,
      "longitude": -88.073844,
      "city": "Buckingham",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60918,
      "latitude": 40.600817,
      "longitude": -88.023748,
      "city": "Buckley",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60919,
      "latitude": 40.967141,
      "longitude": -88.188841,
      "city": "Cabery",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60920,
      "latitude": 41.024755,
      "longitude": -88.307221,
      "city": "Campus",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 60921,
      "latitude": 40.736954,
      "longitude": -88.294923,
      "city": "Chatsworth",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 60922,
      "latitude": 40.968795,
      "longitude": -87.951233,
      "city": "Chebanse",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60924,
      "latitude": 40.738175,
      "longitude": -87.907874,
      "city": "Cissna Park",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60926,
      "latitude": 40.567574,
      "longitude": -87.804046,
      "city": "Claytonville",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60927,
      "latitude": 40.929595,
      "longitude": -87.945327,
      "city": "Clifton",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60928,
      "latitude": 40.66202,
      "longitude": -87.95062,
      "city": "Crescent City",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60929,
      "latitude": 40.882509,
      "longitude": -88.30019,
      "city": "Cullom",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 60930,
      "latitude": 40.811396,
      "longitude": -87.967134,
      "city": "Danforth",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60931,
      "latitude": 40.880529,
      "longitude": -87.598831,
      "city": "Donovan",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60932,
      "latitude": 40.344417,
      "longitude": -87.701583,
      "city": "East Lynn",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 60933,
      "latitude": 40.46483,
      "longitude": -88.271245,
      "city": "Elliott",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60934,
      "latitude": 40.969209,
      "longitude": -88.358928,
      "city": "Emington",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 60935,
      "latitude": 41.155397,
      "longitude": -88.157189,
      "city": "Essex",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60936,
      "latitude": 40.696479,
      "longitude": -88.318387,
      "city": "Gibson City",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60938,
      "latitude": 40.765951,
      "longitude": -87.834645,
      "city": "Gilman",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60939,
      "latitude": 40.748087,
      "longitude": -87.82876,
      "city": "Goodwine",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60940,
      "latitude": 41.204947,
      "longitude": -87.644151,
      "city": "Grant Park",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60941,
      "latitude": 41.12077,
      "longitude": -87.899733,
      "city": "Herscher",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60942,
      "latitude": 40.442138,
      "longitude": -87.672943,
      "city": "Hoopeston",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 60944,
      "latitude": 41.146464,
      "longitude": -87.888971,
      "city": "Hopkins Park",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60945,
      "latitude": 40.826919,
      "longitude": -87.584668,
      "city": "Iroquois",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60946,
      "latitude": 40.897321,
      "longitude": -88.186897,
      "city": "Kempton",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60948,
      "latitude": 40.537227,
      "longitude": -87.9663,
      "city": "Loda",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60949,
      "latitude": 40.370897,
      "longitude": -88.152546,
      "city": "Ludlow",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 60950,
      "latitude": 41.173292,
      "longitude": -87.87675,
      "city": "Manteno",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60951,
      "latitude": 40.931202,
      "longitude": -87.747179,
      "city": "Martinton",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60952,
      "latitude": 40.537915,
      "longitude": -88.299338,
      "city": "Melvin",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60953,
      "latitude": 40.75071,
      "longitude": -87.695039,
      "city": "Milford",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60954,
      "latitude": 41.116572,
      "longitude": -87.868519,
      "city": "Momence",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60955,
      "latitude": 40.758445,
      "longitude": -87.852046,
      "city": "Onarga",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60956,
      "latitude": 40.966993,
      "longitude": -87.716074,
      "city": "Papineau",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60957,
      "latitude": 40.660905,
      "longitude": -88.197834,
      "city": "Paxton",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60959,
      "latitude": 40.720981,
      "longitude": -88.186732,
      "city": "Piper City",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60960,
      "latitude": 40.422456,
      "longitude": -87.858213,
      "city": "Rankin",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 60961,
      "latitude": 41.099001,
      "longitude": -88.198405,
      "city": "Reddick",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60962,
      "latitude": 40.57087,
      "longitude": -88.234682,
      "city": "Roberts",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 60963,
      "latitude": 40.363126,
      "longitude": -87.682897,
      "city": "Rossville",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 60964,
      "latitude": 41.068206,
      "longitude": -87.687329,
      "city": "Saint Anne",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60966,
      "latitude": 40.75308,
      "longitude": -87.58941,
      "city": "Sheldon",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60967,
      "latitude": 40.748087,
      "longitude": -87.82876,
      "city": "Stockland",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60968,
      "latitude": 40.673257,
      "longitude": -88.099724,
      "city": "Thawville",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60969,
      "latitude": 41.108802,
      "longitude": -88.146523,
      "city": "Union Hill",
      "state": "IL",
      "county": "Kankakee"
  },
  {
      "zipCode": 60970,
      "latitude": 40.749938,
      "longitude": -87.798478,
      "city": "Watseka",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60973,
      "latitude": 40.633196,
      "longitude": -87.658329,
      "city": "Wellington",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 60974,
      "latitude": 40.715072,
      "longitude": -87.730743,
      "city": "Woodland",
      "state": "IL",
      "county": "Iroquois"
  },
  {
      "zipCode": 61001,
      "latitude": 42.454161,
      "longitude": -90.118471,
      "city": "Apple River",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61006,
      "latitude": 41.838351,
      "longitude": -89.193176,
      "city": "Ashton",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61007,
      "latitude": 42.178584,
      "longitude": -89.595377,
      "city": "Baileyville",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61008,
      "latitude": 42.276381,
      "longitude": -88.823215,
      "city": "Belvidere",
      "state": "IL",
      "county": "Boone"
  },
  {
      "zipCode": 61010,
      "latitude": 42.091976,
      "longitude": -89.322625,
      "city": "Byron",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61011,
      "latitude": 42.397024,
      "longitude": -88.843027,
      "city": "Caledonia",
      "state": "IL",
      "county": "Boone"
  },
  {
      "zipCode": 61012,
      "latitude": 42.41042,
      "longitude": -88.759953,
      "city": "Capron",
      "state": "IL",
      "county": "Boone"
  },
  {
      "zipCode": 61013,
      "latitude": 42.376127,
      "longitude": -89.636483,
      "city": "Cedarville",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61014,
      "latitude": 41.994827,
      "longitude": -89.877636,
      "city": "Chadwick",
      "state": "IL",
      "county": "Carroll"
  },
  {
      "zipCode": 61015,
      "latitude": 41.99742,
      "longitude": -89.19964,
      "city": "Chana",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61016,
      "latitude": 42.240704,
      "longitude": -88.983748,
      "city": "Cherry Valley",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61018,
      "latitude": 42.410796,
      "longitude": -89.583179,
      "city": "Dakota",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61019,
      "latitude": 42.398148,
      "longitude": -89.611026,
      "city": "Davis",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61020,
      "latitude": 42.106337,
      "longitude": -89.099201,
      "city": "Davis Junction",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61021,
      "latitude": 41.809229,
      "longitude": -89.417156,
      "city": "Dixon",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61024,
      "latitude": 42.434686,
      "longitude": -89.213377,
      "city": "Durand",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61025,
      "latitude": 42.46626,
      "longitude": -90.540556,
      "city": "East Dubuque",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61027,
      "latitude": 42.332038,
      "longitude": -89.761171,
      "city": "Eleroy",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61028,
      "latitude": 42.309263,
      "longitude": -90.181064,
      "city": "Elizabeth",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61030,
      "latitude": 42.120046,
      "longitude": -89.312592,
      "city": "Forreston",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61031,
      "latitude": 41.816006,
      "longitude": -89.311037,
      "city": "Franklin Grove",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61032,
      "latitude": 42.323529,
      "longitude": -89.591606,
      "city": "Freeport",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61036,
      "latitude": 42.382277,
      "longitude": -90.312588,
      "city": "Galena",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61037,
      "latitude": 41.786534,
      "longitude": -89.761042,
      "city": "Galt",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61038,
      "latitude": 42.261184,
      "longitude": -88.761434,
      "city": "Garden Prairie",
      "state": "IL",
      "county": "Boone"
  },
  {
      "zipCode": 61039,
      "latitude": 42.238319,
      "longitude": -89.459521,
      "city": "German Valley",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61041,
      "latitude": 42.274653,
      "longitude": -90.295132,
      "city": "Hanover",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61042,
      "latitude": 41.689533,
      "longitude": -89.545604,
      "city": "Harmon",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61043,
      "latitude": 42.045123,
      "longitude": -89.313593,
      "city": "Holcomb",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61044,
      "latitude": 42.282256,
      "longitude": -89.891281,
      "city": "Kent",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61046,
      "latitude": 42.078942,
      "longitude": -89.814738,
      "city": "Lanark",
      "state": "IL",
      "county": "Carroll"
  },
  {
      "zipCode": 61047,
      "latitude": 42.147816,
      "longitude": -89.278387,
      "city": "Leaf River",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61048,
      "latitude": 42.386301,
      "longitude": -89.816756,
      "city": "Lena",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61049,
      "latitude": 42.024619,
      "longitude": -89.004111,
      "city": "Lindenwood",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61050,
      "latitude": 42.428135,
      "longitude": -89.7643,
      "city": "Mc Connell",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61051,
      "latitude": 41.991827,
      "longitude": -89.764016,
      "city": "Milledgeville",
      "state": "IL",
      "county": "Carroll"
  },
  {
      "zipCode": 61052,
      "latitude": 42.108224,
      "longitude": -88.998128,
      "city": "Monroe Center",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61053,
      "latitude": 42.093181,
      "longitude": -89.968127,
      "city": "Mount Carroll",
      "state": "IL",
      "county": "Carroll"
  },
  {
      "zipCode": 61054,
      "latitude": 42.058183,
      "longitude": -89.352968,
      "city": "Mount Morris",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61057,
      "latitude": 41.831577,
      "longitude": -89.38957,
      "city": "Nachusa",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61058,
      "latitude": 41.797256,
      "longitude": -89.599529,
      "city": "Nelson",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61059,
      "latitude": 42.463658,
      "longitude": -89.941363,
      "city": "Nora",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61060,
      "latitude": 42.473576,
      "longitude": -89.60519,
      "city": "Orangeville",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61061,
      "latitude": 42.011025,
      "longitude": -89.253887,
      "city": "Oregon",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61062,
      "latitude": 42.332702,
      "longitude": -89.782606,
      "city": "Pearl City",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61063,
      "latitude": 42.3104,
      "longitude": -89.311162,
      "city": "Pecatonica",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61064,
      "latitude": 41.989578,
      "longitude": -89.323812,
      "city": "Polo",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61065,
      "latitude": 42.392537,
      "longitude": -88.811894,
      "city": "Poplar Grove",
      "state": "IL",
      "county": "Boone"
  },
  {
      "zipCode": 61067,
      "latitude": 42.334675,
      "longitude": -89.559845,
      "city": "Ridott",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61068,
      "latitude": 42.007405,
      "longitude": -89.100359,
      "city": "Rochelle",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61070,
      "latitude": 42.418515,
      "longitude": -89.470711,
      "city": "Rock City",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61071,
      "latitude": 41.687083,
      "longitude": -89.728269,
      "city": "Rock Falls",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61072,
      "latitude": 42.381926,
      "longitude": -89.142856,
      "city": "Rockton",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61073,
      "latitude": 42.416908,
      "longitude": -89.000227,
      "city": "Roscoe",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61074,
      "latitude": 42.077282,
      "longitude": -90.005039,
      "city": "Savanna",
      "state": "IL",
      "county": "Carroll"
  },
  {
      "zipCode": 61075,
      "latitude": 42.452479,
      "longitude": -90.26684,
      "city": "Scales Mound",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61076,
      "latitude": 42.355422,
      "longitude": -89.667939,
      "city": "Scioto Mills",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61077,
      "latitude": 42.236834,
      "longitude": -89.357998,
      "city": "Seward",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61078,
      "latitude": 42.154795,
      "longitude": -89.764627,
      "city": "Shannon",
      "state": "IL",
      "county": "Carroll"
  },
  {
      "zipCode": 61079,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Shirland",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61080,
      "latitude": 42.38648,
      "longitude": -89.090975,
      "city": "South Beloit",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61081,
      "latitude": 41.835669,
      "longitude": -89.745941,
      "city": "Sterling",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61084,
      "latitude": 42.1133,
      "longitude": -89.186271,
      "city": "Stillman Valley",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61085,
      "latitude": 42.349517,
      "longitude": -90.085356,
      "city": "Stockton",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61087,
      "latitude": 42.470405,
      "longitude": -90.087359,
      "city": "Warren",
      "state": "IL",
      "county": "Jo Daviess"
  },
  {
      "zipCode": 61088,
      "latitude": 42.347321,
      "longitude": -89.194327,
      "city": "Winnebago",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61089,
      "latitude": 42.460658,
      "longitude": -89.814639,
      "city": "Winslow",
      "state": "IL",
      "county": "Stephenson"
  },
  {
      "zipCode": 61091,
      "latitude": 41.903068,
      "longitude": -89.540365,
      "city": "Woosung",
      "state": "IL",
      "county": "Ogle"
  },
  {
      "zipCode": 61101,
      "latitude": 42.33342,
      "longitude": -89.157198,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61102,
      "latitude": 42.22974,
      "longitude": -89.155825,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61103,
      "latitude": 42.341983,
      "longitude": -89.078829,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61104,
      "latitude": 42.25012,
      "longitude": -89.080254,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61105,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61106,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61107,
      "latitude": 42.284055,
      "longitude": -89.01621,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61108,
      "latitude": 42.247643,
      "longitude": -88.999892,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61109,
      "latitude": 42.196681,
      "longitude": -89.065539,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61110,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61111,
      "latitude": 42.341767,
      "longitude": -89.004716,
      "city": "Loves Park",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61112,
      "latitude": 42.246231,
      "longitude": -88.97517,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61114,
      "latitude": 42.318492,
      "longitude": -88.997248,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61115,
      "latitude": 42.354546,
      "longitude": -89.039675,
      "city": "Machesney Park",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61125,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61126,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Rockford",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61130,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Loves Park",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61131,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Loves Park",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61132,
      "latitude": 42.325364,
      "longitude": -89.170527,
      "city": "Loves Park",
      "state": "IL",
      "county": "Winnebago"
  },
  {
      "zipCode": 61201,
      "latitude": 41.495083,
      "longitude": -90.604899,
      "city": "Rock Island",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61204,
      "latitude": 41.554901,
      "longitude": -90.615975,
      "city": "Rock Island",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61230,
      "latitude": 41.729562,
      "longitude": -90.159676,
      "city": "Albany",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61231,
      "latitude": 41.197762,
      "longitude": -90.730944,
      "city": "Aledo",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61232,
      "latitude": 41.440131,
      "longitude": -90.742806,
      "city": "Andalusia",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61233,
      "latitude": 41.295367,
      "longitude": -90.290476,
      "city": "Andover",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61234,
      "latitude": 41.425519,
      "longitude": -89.924636,
      "city": "Annawan",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61235,
      "latitude": 41.405502,
      "longitude": -89.998274,
      "city": "Atkinson",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61236,
      "latitude": 41.51469,
      "longitude": -90.357727,
      "city": "Barstow",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61237,
      "latitude": 41.336752,
      "longitude": -90.852226,
      "city": "Buffalo Prairie",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61238,
      "latitude": 41.285666,
      "longitude": -90.104144,
      "city": "Cambridge",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61239,
      "latitude": 41.495034,
      "longitude": -90.393778,
      "city": "Carbon Cliff",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61240,
      "latitude": 41.401019,
      "longitude": -90.575785,
      "city": "Coal Valley",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61241,
      "latitude": 41.437419,
      "longitude": -90.166719,
      "city": "Colona",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61242,
      "latitude": 41.702549,
      "longitude": -90.27691,
      "city": "Cordova",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61243,
      "latitude": 41.620757,
      "longitude": -89.710301,
      "city": "Deer Grove",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61244,
      "latitude": 41.562693,
      "longitude": -90.512483,
      "city": "East Moline",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61250,
      "latitude": 41.667507,
      "longitude": -90.098292,
      "city": "Erie",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61251,
      "latitude": 41.737836,
      "longitude": -90.078132,
      "city": "Fenton",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61252,
      "latitude": 41.79088,
      "longitude": -90.112432,
      "city": "Fulton",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61254,
      "latitude": 41.437746,
      "longitude": -90.158163,
      "city": "Geneseo",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61256,
      "latitude": 41.527582,
      "longitude": -90.446341,
      "city": "Hampton",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61257,
      "latitude": 41.501771,
      "longitude": -90.606468,
      "city": "Hillsdale",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61258,
      "latitude": 41.522044,
      "longitude": -89.91216,
      "city": "Hooppole",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61259,
      "latitude": 41.384813,
      "longitude": -90.835558,
      "city": "Illinois City",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61260,
      "latitude": 41.237259,
      "longitude": -90.688648,
      "city": "Joy",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61261,
      "latitude": 41.73562,
      "longitude": -89.910699,
      "city": "Lyndon",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61262,
      "latitude": 41.285461,
      "longitude": -90.319658,
      "city": "Lynn Center",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61263,
      "latitude": 41.259938,
      "longitude": -90.612757,
      "city": "Matherville",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61264,
      "latitude": 41.420305,
      "longitude": -90.595736,
      "city": "Milan",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61265,
      "latitude": 41.529431,
      "longitude": -90.528972,
      "city": "Moline",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61266,
      "latitude": 41.554901,
      "longitude": -90.615975,
      "city": "Moline",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61270,
      "latitude": 41.781381,
      "longitude": -90.002217,
      "city": "Morrison",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61272,
      "latitude": 41.238072,
      "longitude": -90.987063,
      "city": "New Boston",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61273,
      "latitude": 41.36211,
      "longitude": -90.376529,
      "city": "Orion",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61274,
      "latitude": 41.371081,
      "longitude": -90.194276,
      "city": "Osco",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61275,
      "latitude": 41.545132,
      "longitude": -90.41449,
      "city": "Port Byron",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61276,
      "latitude": 41.299563,
      "longitude": -90.586643,
      "city": "Preemption",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61277,
      "latitude": 41.654462,
      "longitude": -89.924411,
      "city": "Prophetstown",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61278,
      "latitude": 41.585883,
      "longitude": -90.343228,
      "city": "Rapids City",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61279,
      "latitude": 41.472195,
      "longitude": -90.595329,
      "city": "Reynolds",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61281,
      "latitude": 41.280317,
      "longitude": -90.520183,
      "city": "Sherrard",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61282,
      "latitude": 41.560381,
      "longitude": -90.51753,
      "city": "Silvis",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61283,
      "latitude": 41.637929,
      "longitude": -89.756769,
      "city": "Tampico",
      "state": "IL",
      "county": "Whiteside"
  },
  {
      "zipCode": 61284,
      "latitude": 41.391078,
      "longitude": -90.714746,
      "city": "Taylor Ridge",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61285,
      "latitude": 41.986802,
      "longitude": -89.917167,
      "city": "Thomson",
      "state": "IL",
      "county": "Carroll"
  },
  {
      "zipCode": 61299,
      "latitude": 41.520333,
      "longitude": -90.541585,
      "city": "Rock Island",
      "state": "IL",
      "county": "Rock Island"
  },
  {
      "zipCode": 61301,
      "latitude": 41.365092,
      "longitude": -89.0866,
      "city": "La Salle",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61310,
      "latitude": 41.721016,
      "longitude": -89.299671,
      "city": "Amboy",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61311,
      "latitude": 41.042631,
      "longitude": -88.864211,
      "city": "Ancona",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61312,
      "latitude": 41.43043,
      "longitude": -89.240006,
      "city": "Arlington",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61313,
      "latitude": 41.063241,
      "longitude": -88.680876,
      "city": "Blackstone",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61314,
      "latitude": 41.306814,
      "longitude": -89.653036,
      "city": "Buda",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61315,
      "latitude": 41.289394,
      "longitude": -89.368617,
      "city": "Bureau",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61316,
      "latitude": 41.261714,
      "longitude": -89.123735,
      "city": "Cedar Point",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61317,
      "latitude": 41.426433,
      "longitude": -89.212609,
      "city": "Cherry",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61318,
      "latitude": 41.713223,
      "longitude": -89.091458,
      "city": "Compton",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61319,
      "latitude": 41.03103,
      "longitude": -88.766797,
      "city": "Cornell",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61320,
      "latitude": 41.366748,
      "longitude": -89.479804,
      "city": "Dalzell",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61321,
      "latitude": 40.999418,
      "longitude": -88.989269,
      "city": "Dana",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61322,
      "latitude": 41.309194,
      "longitude": -89.325221,
      "city": "Depue",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61323,
      "latitude": 41.436173,
      "longitude": -89.396006,
      "city": "Dover",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61324,
      "latitude": 41.771078,
      "longitude": -89.412282,
      "city": "Eldena",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61325,
      "latitude": 41.237122,
      "longitude": -88.817458,
      "city": "Grand Ridge",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61326,
      "latitude": 41.250055,
      "longitude": -89.24284,
      "city": "Granville",
      "state": "IL",
      "county": "Putnam"
  },
  {
      "zipCode": 61327,
      "latitude": 41.252096,
      "longitude": -89.338005,
      "city": "Hennepin",
      "state": "IL",
      "county": "Putnam"
  },
  {
      "zipCode": 61328,
      "latitude": 41.366883,
      "longitude": -89.513065,
      "city": "Kasbeer",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61329,
      "latitude": 41.37951,
      "longitude": -89.203827,
      "city": "Ladd",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61330,
      "latitude": 41.454193,
      "longitude": -89.448445,
      "city": "La Moille",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61331,
      "latitude": 41.74885,
      "longitude": -89.282726,
      "city": "Lee Center",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61332,
      "latitude": 41.148788,
      "longitude": -89.009131,
      "city": "Leonore",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61333,
      "latitude": 40.980115,
      "longitude": -88.873628,
      "city": "Long Point",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61334,
      "latitude": 41.148202,
      "longitude": -89.076145,
      "city": "Lostant",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61335,
      "latitude": 41.146046,
      "longitude": -89.225915,
      "city": "Mc Nabb",
      "state": "IL",
      "county": "Putnam"
  },
  {
      "zipCode": 61336,
      "latitude": 41.213097,
      "longitude": -89.314316,
      "city": "Magnolia",
      "state": "IL",
      "county": "Putnam"
  },
  {
      "zipCode": 61337,
      "latitude": 41.422315,
      "longitude": -89.335467,
      "city": "Malden",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61338,
      "latitude": 41.455508,
      "longitude": -89.669712,
      "city": "Manlius",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61340,
      "latitude": 41.265997,
      "longitude": -89.249143,
      "city": "Mark",
      "state": "IL",
      "county": "Putnam"
  },
  {
      "zipCode": 61341,
      "latitude": 41.212787,
      "longitude": -88.833689,
      "city": "Marseilles",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61342,
      "latitude": 41.385519,
      "longitude": -89.051012,
      "city": "Mendota",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61344,
      "latitude": 41.379654,
      "longitude": -89.828368,
      "city": "Mineral",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61345,
      "latitude": 41.338444,
      "longitude": -89.796313,
      "city": "Neponset",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61346,
      "latitude": 41.512158,
      "longitude": -89.719934,
      "city": "New Bedford",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61348,
      "latitude": 41.225813,
      "longitude": -89.040428,
      "city": "Oglesby",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61349,
      "latitude": 41.459337,
      "longitude": -89.596261,
      "city": "Ohio",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61350,
      "latitude": 41.248925,
      "longitude": -88.833337,
      "city": "Ottawa",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61353,
      "latitude": 41.69376,
      "longitude": -88.995749,
      "city": "Paw Paw",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61354,
      "latitude": 41.326408,
      "longitude": -89.08578,
      "city": "Peru",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61356,
      "latitude": 41.424736,
      "longitude": -89.452044,
      "city": "Princeton",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61358,
      "latitude": 40.985846,
      "longitude": -88.989048,
      "city": "Rutland",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61359,
      "latitude": 41.361251,
      "longitude": -89.269125,
      "city": "Seatonville",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61360,
      "latitude": 41.221524,
      "longitude": -88.835029,
      "city": "Seneca",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61361,
      "latitude": 41.404427,
      "longitude": -89.530149,
      "city": "Sheffield",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61362,
      "latitude": 41.423455,
      "longitude": -89.506797,
      "city": "Spring Valley",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61363,
      "latitude": 41.256668,
      "longitude": -89.17784,
      "city": "Standard",
      "state": "IL",
      "county": "Putnam"
  },
  {
      "zipCode": 61364,
      "latitude": 41.164177,
      "longitude": -88.85632,
      "city": "Streator",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61367,
      "latitude": 41.628086,
      "longitude": -89.274356,
      "city": "Sublette",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61368,
      "latitude": 41.248455,
      "longitude": -89.521465,
      "city": "Tiskilwa",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61369,
      "latitude": 41.006568,
      "longitude": -89.134078,
      "city": "Toluca",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61370,
      "latitude": 41.222005,
      "longitude": -88.989972,
      "city": "Tonica",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61371,
      "latitude": 41.499089,
      "longitude": -89.021881,
      "city": "Triumph",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61372,
      "latitude": 41.451786,
      "longitude": -89.070516,
      "city": "Troy Grove",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61373,
      "latitude": 41.411759,
      "longitude": -89.008197,
      "city": "Utica",
      "state": "IL",
      "county": "La Salle"
  },
  {
      "zipCode": 61374,
      "latitude": 41.366883,
      "longitude": -89.513065,
      "city": "Van Orin",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61375,
      "latitude": 41.033886,
      "longitude": -89.255983,
      "city": "Varna",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61376,
      "latitude": 41.453979,
      "longitude": -89.478453,
      "city": "Walnut",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61377,
      "latitude": 41.029236,
      "longitude": -89.134955,
      "city": "Wenona",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61378,
      "latitude": 41.722648,
      "longitude": -89.193026,
      "city": "West Brooklyn",
      "state": "IL",
      "county": "Lee"
  },
  {
      "zipCode": 61379,
      "latitude": 41.442109,
      "longitude": -89.44646,
      "city": "Wyanet",
      "state": "IL",
      "county": "Bureau"
  },
  {
      "zipCode": 61401,
      "latitude": 40.964613,
      "longitude": -90.213654,
      "city": "Galesburg",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61402,
      "latitude": 40.932207,
      "longitude": -90.214412,
      "city": "Galesburg",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61410,
      "latitude": 40.927973,
      "longitude": -90.304039,
      "city": "Abingdon",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61411,
      "latitude": 40.387329,
      "longitude": -90.506582,
      "city": "Adair",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61412,
      "latitude": 41.136994,
      "longitude": -90.631224,
      "city": "Alexis",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61413,
      "latitude": 41.20188,
      "longitude": -90.245619,
      "city": "Alpha",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61414,
      "latitude": 41.096307,
      "longitude": -90.138335,
      "city": "Altona",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61415,
      "latitude": 40.608139,
      "longitude": -90.362984,
      "city": "Avon",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61416,
      "latitude": 40.497896,
      "longitude": -90.508816,
      "city": "Bardolph",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61417,
      "latitude": 40.769783,
      "longitude": -90.534172,
      "city": "Berwick",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61418,
      "latitude": 40.853583,
      "longitude": -90.864536,
      "city": "Biggsville",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61419,
      "latitude": 41.197682,
      "longitude": -90.118344,
      "city": "Bishop Hill",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61420,
      "latitude": 40.547789,
      "longitude": -90.762736,
      "city": "Blandinsville",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61421,
      "latitude": 41.176471,
      "longitude": -89.657789,
      "city": "Bradford",
      "state": "IL",
      "county": "Stark"
  },
  {
      "zipCode": 61422,
      "latitude": 40.548189,
      "longitude": -90.657031,
      "city": "Bushnell",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61423,
      "latitude": 40.904171,
      "longitude": -90.556645,
      "city": "Cameron",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61424,
      "latitude": 41.077977,
      "longitude": -89.633233,
      "city": "Camp Grove",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61425,
      "latitude": 40.852582,
      "longitude": -90.985293,
      "city": "Carman",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61426,
      "latitude": 41.118265,
      "longitude": -89.70715,
      "city": "Castleton",
      "state": "IL",
      "county": "Stark"
  },
  {
      "zipCode": 61427,
      "latitude": 40.501446,
      "longitude": -90.201152,
      "city": "Cuba",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61428,
      "latitude": 41.007962,
      "longitude": -90.102455,
      "city": "Dahinda",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61430,
      "latitude": 40.980167,
      "longitude": -90.329573,
      "city": "East Galesburg",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61431,
      "latitude": 40.577403,
      "longitude": -90.305162,
      "city": "Ellisville",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61432,
      "latitude": 40.645183,
      "longitude": -90.158064,
      "city": "Fairview",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61433,
      "latitude": 40.449305,
      "longitude": -90.161761,
      "city": "Fiatt",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61434,
      "latitude": 41.212281,
      "longitude": -90.091976,
      "city": "Galva",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61435,
      "latitude": 40.986267,
      "longitude": -90.549089,
      "city": "Gerlaw",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61436,
      "latitude": 40.968763,
      "longitude": -90.201205,
      "city": "Gilson",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61437,
      "latitude": 40.852582,
      "longitude": -90.985293,
      "city": "Gladstone",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61438,
      "latitude": 40.57311,
      "longitude": -90.646976,
      "city": "Good Hope",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61439,
      "latitude": 41.027582,
      "longitude": -90.357532,
      "city": "Henderson",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61440,
      "latitude": 40.41622,
      "longitude": -90.648351,
      "city": "Industry",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61441,
      "latitude": 40.333536,
      "longitude": -90.307389,
      "city": "Ipava",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61442,
      "latitude": 41.133733,
      "longitude": -90.711121,
      "city": "Keithsburg",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61443,
      "latitude": 41.257262,
      "longitude": -90.118355,
      "city": "Kewanee",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61447,
      "latitude": 40.871331,
      "longitude": -90.656439,
      "city": "Kirkwood",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61448,
      "latitude": 40.972611,
      "longitude": -90.168778,
      "city": "Knoxville",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61449,
      "latitude": 41.109823,
      "longitude": -89.97363,
      "city": "La Fayette",
      "state": "IL",
      "county": "Stark"
  },
  {
      "zipCode": 61450,
      "latitude": 40.549636,
      "longitude": -90.997312,
      "city": "La Harpe",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 61451,
      "latitude": 40.938808,
      "longitude": -89.889949,
      "city": "Laura",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61452,
      "latitude": 40.20621,
      "longitude": -90.652911,
      "city": "Littleton",
      "state": "IL",
      "county": "Schuyler"
  },
  {
      "zipCode": 61453,
      "latitude": 40.991276,
      "longitude": -90.707712,
      "city": "Little York",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61454,
      "latitude": 40.852582,
      "longitude": -90.985293,
      "city": "Lomax",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61455,
      "latitude": 40.459904,
      "longitude": -90.660675,
      "city": "Macomb",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61458,
      "latitude": 40.802776,
      "longitude": -90.213566,
      "city": "Maquon",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61459,
      "latitude": 40.510851,
      "longitude": -90.393855,
      "city": "Marietta",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61460,
      "latitude": 40.852582,
      "longitude": -90.985293,
      "city": "Media",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61462,
      "latitude": 40.924563,
      "longitude": -90.618313,
      "city": "Monmouth",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61465,
      "latitude": 41.176867,
      "longitude": -90.687247,
      "city": "New Windsor",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61466,
      "latitude": 41.107653,
      "longitude": -90.485206,
      "city": "North Henderson",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61467,
      "latitude": 41.076845,
      "longitude": -90.242298,
      "city": "Oneida",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61468,
      "latitude": 41.257228,
      "longitude": -90.400667,
      "city": "Opheim",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61469,
      "latitude": 40.937485,
      "longitude": -90.948392,
      "city": "Oquawka",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61470,
      "latitude": 40.591144,
      "longitude": -90.512266,
      "city": "Prairie City",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61471,
      "latitude": 40.852582,
      "longitude": -90.985293,
      "city": "Raritan",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61472,
      "latitude": 41.100464,
      "longitude": -90.38161,
      "city": "Rio",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61473,
      "latitude": 40.747768,
      "longitude": -90.654131,
      "city": "Roseville",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61474,
      "latitude": 40.739649,
      "longitude": -90.358419,
      "city": "Saint Augustine",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61475,
      "latitude": 40.583158,
      "longitude": -90.731564,
      "city": "Sciota",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 61476,
      "latitude": 41.136652,
      "longitude": -90.760811,
      "city": "Seaton",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61477,
      "latitude": 40.495137,
      "longitude": -90.345369,
      "city": "Smithfield",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61478,
      "latitude": 40.749815,
      "longitude": -90.750256,
      "city": "Smithshire",
      "state": "IL",
      "county": "Warren"
  },
  {
      "zipCode": 61479,
      "latitude": 40.987171,
      "longitude": -89.651961,
      "city": "Speer",
      "state": "IL",
      "county": "Stark"
  },
  {
      "zipCode": 61480,
      "latitude": 40.719278,
      "longitude": -90.894204,
      "city": "Stronghurst",
      "state": "IL",
      "county": "Henderson"
  },
  {
      "zipCode": 61482,
      "latitude": 40.376602,
      "longitude": -90.408961,
      "city": "Table Grove",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61483,
      "latitude": 41.09404,
      "longitude": -89.864391,
      "city": "Toulon",
      "state": "IL",
      "county": "Stark"
  },
  {
      "zipCode": 61484,
      "latitude": 40.285383,
      "longitude": -90.393432,
      "city": "Vermont",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61485,
      "latitude": 41.00733,
      "longitude": -90.085007,
      "city": "Victoria",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61486,
      "latitude": 41.208497,
      "longitude": -90.575522,
      "city": "Viola",
      "state": "IL",
      "county": "Mercer"
  },
  {
      "zipCode": 61488,
      "latitude": 41.016591,
      "longitude": -90.251635,
      "city": "Wataga",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61489,
      "latitude": 40.94837,
      "longitude": -90.042908,
      "city": "Williamsfield",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61490,
      "latitude": 41.208513,
      "longitude": -90.230506,
      "city": "Woodhull",
      "state": "IL",
      "county": "Henry"
  },
  {
      "zipCode": 61491,
      "latitude": 41.085606,
      "longitude": -89.788462,
      "city": "Wyoming",
      "state": "IL",
      "county": "Stark"
  },
  {
      "zipCode": 61501,
      "latitude": 40.243173,
      "longitude": -90.324653,
      "city": "Astoria",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61516,
      "latitude": 40.853248,
      "longitude": -89.131937,
      "city": "Benson",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61517,
      "latitude": 40.814233,
      "longitude": -89.859603,
      "city": "Brimfield",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61519,
      "latitude": 40.466267,
      "longitude": -90.091027,
      "city": "Bryant",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61520,
      "latitude": 40.445611,
      "longitude": -90.151535,
      "city": "Canton",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61523,
      "latitude": 40.831823,
      "longitude": -89.636673,
      "city": "Chillicothe",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61524,
      "latitude": 40.491606,
      "longitude": -90.041936,
      "city": "Dunfermline",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61525,
      "latitude": 40.84674,
      "longitude": -89.678884,
      "city": "Dunlap",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61526,
      "latitude": 40.911838,
      "longitude": -89.644497,
      "city": "Edelstein",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61528,
      "latitude": 40.836603,
      "longitude": -89.726242,
      "city": "Edwards",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61529,
      "latitude": 40.778516,
      "longitude": -89.910219,
      "city": "Elmwood",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61530,
      "latitude": 40.705084,
      "longitude": -89.376702,
      "city": "Eureka",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61531,
      "latitude": 40.471426,
      "longitude": -90.170475,
      "city": "Farmington",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61532,
      "latitude": 40.305929,
      "longitude": -89.8716,
      "city": "Forest City",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 61533,
      "latitude": 40.589183,
      "longitude": -89.815144,
      "city": "Glasford",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61534,
      "latitude": 40.425189,
      "longitude": -89.62064,
      "city": "Green Valley",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61535,
      "latitude": 40.600997,
      "longitude": -89.557074,
      "city": "Groveland",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61536,
      "latitude": 40.685646,
      "longitude": -89.781809,
      "city": "Hanna City",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61537,
      "latitude": 41.053004,
      "longitude": -89.339283,
      "city": "Henry",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61539,
      "latitude": 40.557119,
      "longitude": -89.768481,
      "city": "Kingston Mines",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61540,
      "latitude": 41.007987,
      "longitude": -89.337636,
      "city": "Lacon",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61541,
      "latitude": 41.006325,
      "longitude": -89.22692,
      "city": "La Rose",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61542,
      "latitude": 40.463185,
      "longitude": -90.166942,
      "city": "Lewistown",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61543,
      "latitude": 40.390243,
      "longitude": -90.002625,
      "city": "Liverpool",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61544,
      "latitude": 40.672692,
      "longitude": -90.291576,
      "city": "London Mills",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61545,
      "latitude": 40.879257,
      "longitude": -89.370304,
      "city": "Lowpoint",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61546,
      "latitude": 40.366447,
      "longitude": -89.880969,
      "city": "Manito",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 61547,
      "latitude": 40.60896,
      "longitude": -89.742265,
      "city": "Mapleton",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61548,
      "latitude": 40.833004,
      "longitude": -89.358019,
      "city": "Metamora",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61550,
      "latitude": 40.591322,
      "longitude": -89.425339,
      "city": "Morton",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61552,
      "latitude": 40.817987,
      "longitude": -89.567996,
      "city": "Mossville",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61553,
      "latitude": 40.449305,
      "longitude": -90.161761,
      "city": "Norris",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61554,
      "latitude": 40.548928,
      "longitude": -89.638839,
      "city": "Pekin",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61555,
      "latitude": 40.56071,
      "longitude": -89.650232,
      "city": "Pekin",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61558,
      "latitude": 40.554538,
      "longitude": -89.609951,
      "city": "Pekin",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61559,
      "latitude": 40.99564,
      "longitude": -89.747439,
      "city": "Princeville",
      "state": "IL",
      "county": "Stark"
  },
  {
      "zipCode": 61560,
      "latitude": 41.205454,
      "longitude": -89.430236,
      "city": "Putnam",
      "state": "IL",
      "county": "Putnam"
  },
  {
      "zipCode": 61561,
      "latitude": 40.793501,
      "longitude": -89.238985,
      "city": "Roanoke",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61562,
      "latitude": 40.874269,
      "longitude": -89.506695,
      "city": "Rome",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61563,
      "latitude": 40.491232,
      "longitude": -90.050499,
      "city": "Saint David",
      "state": "IL",
      "county": "Fulton"
  },
  {
      "zipCode": 61564,
      "latitude": 40.494804,
      "longitude": -89.654598,
      "city": "South Pekin",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61565,
      "latitude": 41.006602,
      "longitude": -89.34804,
      "city": "Sparland",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61567,
      "latitude": 40.344336,
      "longitude": -89.885895,
      "city": "Topeka",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 61568,
      "latitude": 40.456128,
      "longitude": -89.542656,
      "city": "Tremont",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61569,
      "latitude": 40.677626,
      "longitude": -89.909859,
      "city": "Trivoli",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61570,
      "latitude": 40.935265,
      "longitude": -89.33833,
      "city": "Washburn",
      "state": "IL",
      "county": "Marshall"
  },
  {
      "zipCode": 61571,
      "latitude": 40.575996,
      "longitude": -89.487463,
      "city": "Washington",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61572,
      "latitude": 40.785753,
      "longitude": -90.042993,
      "city": "Yates City",
      "state": "IL",
      "county": "Knox"
  },
  {
      "zipCode": 61601,
      "latitude": 40.693137,
      "longitude": -89.589847,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61602,
      "latitude": 40.683283,
      "longitude": -89.604898,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61603,
      "latitude": 40.779845,
      "longitude": -89.554055,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61604,
      "latitude": 40.779003,
      "longitude": -89.617464,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61605,
      "latitude": 40.676637,
      "longitude": -89.633787,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61606,
      "latitude": 40.698087,
      "longitude": -89.608697,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61607,
      "latitude": 40.632085,
      "longitude": -89.690349,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61610,
      "latitude": 40.654065,
      "longitude": -89.571837,
      "city": "Creve Coeur",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61611,
      "latitude": 40.563589,
      "longitude": -89.543319,
      "city": "East Peoria",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61612,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61613,
      "latitude": 40.742537,
      "longitude": -89.627948,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61614,
      "latitude": 40.768117,
      "longitude": -89.602647,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61615,
      "latitude": 40.766087,
      "longitude": -89.644982,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61616,
      "latitude": 40.746726,
      "longitude": -89.571641,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61625,
      "latitude": 40.696287,
      "longitude": -89.616648,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61628,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61629,
      "latitude": 40.692037,
      "longitude": -89.588747,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61630,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61632,
      "latitude": 40.765301,
      "longitude": -89.569207,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61633,
      "latitude": 40.731165,
      "longitude": -89.603081,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61634,
      "latitude": 40.689637,
      "longitude": -89.592597,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61635,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61636,
      "latitude": 40.699937,
      "longitude": -89.595147,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61637,
      "latitude": 40.702487,
      "longitude": -89.589797,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61638,
      "latitude": 40.796887,
      "longitude": -89.611146,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61639,
      "latitude": 40.709837,
      "longitude": -89.563646,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61640,
      "latitude": 40.785653,
      "longitude": -89.617657,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61641,
      "latitude": 40.640002,
      "longitude": -89.651987,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61643,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61644,
      "latitude": 40.765301,
      "longitude": -89.569207,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61650,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61651,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61652,
      "latitude": 40.87671,
      "longitude": -89.509113,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61653,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61654,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61655,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61656,
      "latitude": 40.744179,
      "longitude": -89.718401,
      "city": "Peoria",
      "state": "IL",
      "county": "Peoria"
  },
  {
      "zipCode": 61701,
      "latitude": 40.462041,
      "longitude": -88.850396,
      "city": "Bloomington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61702,
      "latitude": 40.519236,
      "longitude": -88.864303,
      "city": "Bloomington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61704,
      "latitude": 40.491715,
      "longitude": -88.982373,
      "city": "Bloomington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61709,
      "latitude": 40.461431,
      "longitude": -88.953015,
      "city": "Bloomington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61710,
      "latitude": 40.477735,
      "longitude": -88.954174,
      "city": "Bloomington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61720,
      "latitude": 40.568549,
      "longitude": -88.722113,
      "city": "Anchor",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61721,
      "latitude": 40.36129,
      "longitude": -89.323143,
      "city": "Armington",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61722,
      "latitude": 40.447857,
      "longitude": -88.632584,
      "city": "Arrowsmith",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61723,
      "latitude": 40.233769,
      "longitude": -89.257602,
      "city": "Atlanta",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 61724,
      "latitude": 40.332543,
      "longitude": -88.549114,
      "city": "Bellflower",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61725,
      "latitude": 40.580974,
      "longitude": -89.178681,
      "city": "Carlock",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61726,
      "latitude": 40.535221,
      "longitude": -88.895001,
      "city": "Chenoa",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61727,
      "latitude": 40.152822,
      "longitude": -88.947118,
      "city": "Clinton",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61728,
      "latitude": 40.566345,
      "longitude": -88.614116,
      "city": "Colfax",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61729,
      "latitude": 40.617077,
      "longitude": -89.183981,
      "city": "Congerville",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61730,
      "latitude": 40.54215,
      "longitude": -88.716816,
      "city": "Cooksville",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61731,
      "latitude": 40.608711,
      "longitude": -88.480229,
      "city": "Cropsey",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61732,
      "latitude": 40.43644,
      "longitude": -88.882841,
      "city": "Danvers",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61733,
      "latitude": 40.561464,
      "longitude": -89.406261,
      "city": "Deer Creek",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61734,
      "latitude": 40.424081,
      "longitude": -89.600252,
      "city": "Delavan",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61735,
      "latitude": 40.208276,
      "longitude": -88.808015,
      "city": "Dewitt",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61736,
      "latitude": 40.398907,
      "longitude": -88.868647,
      "city": "Downs",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61737,
      "latitude": 40.44998,
      "longitude": -88.717168,
      "city": "Ellsworth",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61738,
      "latitude": 40.684846,
      "longitude": -89.106751,
      "city": "El Paso",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61739,
      "latitude": 40.76723,
      "longitude": -88.627756,
      "city": "Fairbury",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61740,
      "latitude": 40.882185,
      "longitude": -88.844393,
      "city": "Flanagan",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61741,
      "latitude": 40.754176,
      "longitude": -88.393085,
      "city": "Forrest",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61742,
      "latitude": 40.661248,
      "longitude": -89.289652,
      "city": "Goodfield",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61743,
      "latitude": 40.879417,
      "longitude": -88.608644,
      "city": "Graymont",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61744,
      "latitude": 40.744776,
      "longitude": -88.881354,
      "city": "Gridley",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61745,
      "latitude": 40.458782,
      "longitude": -88.985385,
      "city": "Heyworth",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61747,
      "latitude": 40.535696,
      "longitude": -89.445054,
      "city": "Hopedale",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61748,
      "latitude": 40.62502,
      "longitude": -88.989005,
      "city": "Hudson",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61749,
      "latitude": 40.111484,
      "longitude": -89.062222,
      "city": "Kenney",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61750,
      "latitude": 40.098291,
      "longitude": -88.841501,
      "city": "Lane",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61751,
      "latitude": 40.21927,
      "longitude": -89.285172,
      "city": "Lawndale",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 61752,
      "latitude": 40.459553,
      "longitude": -88.849599,
      "city": "Le Roy",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61753,
      "latitude": 40.483327,
      "longitude": -88.874304,
      "city": "Lexington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61754,
      "latitude": 40.31522,
      "longitude": -89.194639,
      "city": "Mc Lean",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61755,
      "latitude": 40.530344,
      "longitude": -89.369718,
      "city": "Mackinaw",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61756,
      "latitude": 40.037523,
      "longitude": -88.961338,
      "city": "Maroa",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 61758,
      "latitude": 40.519236,
      "longitude": -88.864303,
      "city": "Merna",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61759,
      "latitude": 40.447572,
      "longitude": -89.334398,
      "city": "Minier",
      "state": "IL",
      "county": "Tazewell"
  },
  {
      "zipCode": 61760,
      "latitude": 40.838957,
      "longitude": -89.066126,
      "city": "Minonk",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61761,
      "latitude": 40.530375,
      "longitude": -88.7989,
      "city": "Normal",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61764,
      "latitude": 40.87479,
      "longitude": -88.630826,
      "city": "Pontiac",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61769,
      "latitude": 40.883768,
      "longitude": -88.394974,
      "city": "Saunemin",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61770,
      "latitude": 40.454332,
      "longitude": -88.865256,
      "city": "Saybrook",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61771,
      "latitude": 40.742182,
      "longitude": -89.134822,
      "city": "Secor",
      "state": "IL",
      "county": "Woodford"
  },
  {
      "zipCode": 61772,
      "latitude": 40.519236,
      "longitude": -88.864303,
      "city": "Shirley",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61773,
      "latitude": 40.57361,
      "longitude": -88.384737,
      "city": "Sibley",
      "state": "IL",
      "county": "Ford"
  },
  {
      "zipCode": 61774,
      "latitude": 40.435192,
      "longitude": -89.220679,
      "city": "Stanford",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61775,
      "latitude": 40.652662,
      "longitude": -88.39463,
      "city": "Strawn",
      "state": "IL",
      "county": "Livingston"
  },
  {
      "zipCode": 61776,
      "latitude": 40.562341,
      "longitude": -88.897711,
      "city": "Towanda",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61777,
      "latitude": 40.223337,
      "longitude": -88.943985,
      "city": "Wapella",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61778,
      "latitude": 40.241747,
      "longitude": -89.092664,
      "city": "Waynesville",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61790,
      "latitude": 40.51032,
      "longitude": -88.99803,
      "city": "Normal",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61791,
      "latitude": 40.519236,
      "longitude": -88.864303,
      "city": "Bloomington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61799,
      "latitude": 40.488468,
      "longitude": -88.939637,
      "city": "Bloomington",
      "state": "IL",
      "county": "Mclean"
  },
  {
      "zipCode": 61801,
      "latitude": 40.133657,
      "longitude": -88.182071,
      "city": "Urbana",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61802,
      "latitude": 40.074573,
      "longitude": -88.169093,
      "city": "Urbana",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61803,
      "latitude": 40.10593,
      "longitude": -88.224669,
      "city": "Urbana",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61810,
      "latitude": 39.923693,
      "longitude": -87.899719,
      "city": "Allerton",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61811,
      "latitude": 40.285456,
      "longitude": -87.632675,
      "city": "Alvin",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61812,
      "latitude": 40.258038,
      "longitude": -87.874221,
      "city": "Armstrong",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61813,
      "latitude": 39.908567,
      "longitude": -88.599987,
      "city": "Bement",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61814,
      "latitude": 40.24097,
      "longitude": -87.576801,
      "city": "Bismarck",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61815,
      "latitude": 40.10838,
      "longitude": -88.378167,
      "city": "Bondville",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61816,
      "latitude": 39.923208,
      "longitude": -87.992677,
      "city": "Broadlands",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61817,
      "latitude": 40.015467,
      "longitude": -87.804585,
      "city": "Catlin",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61818,
      "latitude": 39.88603,
      "longitude": -88.68207,
      "city": "Cerro Gordo",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61820,
      "latitude": 40.101777,
      "longitude": -88.197166,
      "city": "Champaign",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61821,
      "latitude": 40.117337,
      "longitude": -88.312996,
      "city": "Champaign",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61822,
      "latitude": 40.131682,
      "longitude": -88.28541,
      "city": "Champaign",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61824,
      "latitude": 40.139946,
      "longitude": -88.196102,
      "city": "Champaign",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61825,
      "latitude": 40.139946,
      "longitude": -88.196102,
      "city": "Champaign",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61826,
      "latitude": 40.113139,
      "longitude": -88.361251,
      "city": "Champaign",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61830,
      "latitude": 40.028868,
      "longitude": -88.69568,
      "city": "Cisco",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61831,
      "latitude": 40.227338,
      "longitude": -87.80397,
      "city": "Collison",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61832,
      "latitude": 40.10398,
      "longitude": -87.757792,
      "city": "Danville",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61833,
      "latitude": 40.064586,
      "longitude": -87.637493,
      "city": "Tilton",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61834,
      "latitude": 40.160162,
      "longitude": -87.67293,
      "city": "Danville",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61839,
      "latitude": 40.124321,
      "longitude": -88.623262,
      "city": "De Land",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61840,
      "latitude": 40.307054,
      "longitude": -88.301259,
      "city": "Dewey",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61841,
      "latitude": 40.135318,
      "longitude": -87.808538,
      "city": "Fairmount",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61842,
      "latitude": 40.18631,
      "longitude": -88.69107,
      "city": "Farmer City",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61843,
      "latitude": 40.137239,
      "longitude": -88.208104,
      "city": "Fisher",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61844,
      "latitude": 40.139763,
      "longitude": -87.86895,
      "city": "Fithian",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61845,
      "latitude": 40.347405,
      "longitude": -88.384648,
      "city": "Foosland",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61846,
      "latitude": 39.979714,
      "longitude": -87.734359,
      "city": "Georgetown",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61847,
      "latitude": 40.278553,
      "longitude": -88.011017,
      "city": "Gifford",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61848,
      "latitude": 40.304034,
      "longitude": -87.700689,
      "city": "Henning",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61849,
      "latitude": 40.110684,
      "longitude": -88.154018,
      "city": "Homer",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61850,
      "latitude": 39.939685,
      "longitude": -87.730068,
      "city": "Indianola",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61851,
      "latitude": 39.959789,
      "longitude": -88.25522,
      "city": "Ivesdale",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61852,
      "latitude": 39.91593,
      "longitude": -88.034929,
      "city": "Longview",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61853,
      "latitude": 40.111848,
      "longitude": -88.20431,
      "city": "Mahomet",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61854,
      "latitude": 40.193243,
      "longitude": -88.545144,
      "city": "Mansfield",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61855,
      "latitude": 39.923765,
      "longitude": -88.659875,
      "city": "Milmine",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61856,
      "latitude": 40.050289,
      "longitude": -88.597923,
      "city": "Monticello",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61857,
      "latitude": 40.116478,
      "longitude": -87.844689,
      "city": "Muncie",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61858,
      "latitude": 40.133222,
      "longitude": -87.756376,
      "city": "Oakwood",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61859,
      "latitude": 40.069274,
      "longitude": -88.105359,
      "city": "Ogden",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61862,
      "latitude": 40.305199,
      "longitude": -87.966332,
      "city": "Penfield",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61863,
      "latitude": 40.090766,
      "longitude": -88.1675,
      "city": "Pesotum",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61864,
      "latitude": 40.011073,
      "longitude": -88.151394,
      "city": "Philo",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61865,
      "latitude": 40.211734,
      "longitude": -87.785617,
      "city": "Potomac",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61866,
      "latitude": 40.249954,
      "longitude": -88.113686,
      "city": "Rantoul",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61870,
      "latitude": 39.910953,
      "longitude": -87.615286,
      "city": "Ridge Farm",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61871,
      "latitude": 40.192376,
      "longitude": -87.974197,
      "city": "Royal",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61872,
      "latitude": 39.945436,
      "longitude": -88.359609,
      "city": "Sadorus",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61873,
      "latitude": 40.144962,
      "longitude": -88.072851,
      "city": "Saint Joseph",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61874,
      "latitude": 40.068677,
      "longitude": -88.253105,
      "city": "Savoy",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61875,
      "latitude": 40.092808,
      "longitude": -88.406344,
      "city": "Seymour",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61876,
      "latitude": 39.935302,
      "longitude": -87.83913,
      "city": "Sidell",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61877,
      "latitude": 39.98922,
      "longitude": -88.076343,
      "city": "Sidney",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61878,
      "latitude": 40.241697,
      "longitude": -88.155556,
      "city": "Thomasboro",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61880,
      "latitude": 40.03821,
      "longitude": -88.174757,
      "city": "Tolono",
      "state": "IL",
      "county": "Champaign"
  },
  {
      "zipCode": 61882,
      "latitude": 40.134768,
      "longitude": -88.745542,
      "city": "Weldon",
      "state": "IL",
      "county": "Dewitt"
  },
  {
      "zipCode": 61883,
      "latitude": 40.103625,
      "longitude": -87.744819,
      "city": "Westville",
      "state": "IL",
      "county": "Vermilion"
  },
  {
      "zipCode": 61884,
      "latitude": 40.091056,
      "longitude": -88.503949,
      "city": "White Heath",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61910,
      "latitude": 39.725828,
      "longitude": -88.305182,
      "city": "Arcola",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61911,
      "latitude": 39.728925,
      "longitude": -88.249344,
      "city": "Arthur",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61912,
      "latitude": 39.531041,
      "longitude": -88.040616,
      "city": "Ashmore",
      "state": "IL",
      "county": "Coles"
  },
  {
      "zipCode": 61913,
      "latitude": 39.766094,
      "longitude": -88.273085,
      "city": "Atwood",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61914,
      "latitude": 39.638,
      "longitude": -88.75367,
      "city": "Bethany",
      "state": "IL",
      "county": "Moultrie"
  },
  {
      "zipCode": 61917,
      "latitude": 39.713401,
      "longitude": -87.818005,
      "city": "Brocton",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61919,
      "latitude": 39.752952,
      "longitude": -88.149912,
      "city": "Camargo",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61920,
      "latitude": 39.499622,
      "longitude": -88.160348,
      "city": "Charleston",
      "state": "IL",
      "county": "Coles"
  },
  {
      "zipCode": 61924,
      "latitude": 39.77859,
      "longitude": -87.687638,
      "city": "Chrisman",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61925,
      "latitude": 39.679871,
      "longitude": -88.774951,
      "city": "Dalton City",
      "state": "IL",
      "county": "Moultrie"
  },
  {
      "zipCode": 61928,
      "latitude": 39.585282,
      "longitude": -88.639372,
      "city": "Gays",
      "state": "IL",
      "county": "Moultrie"
  },
  {
      "zipCode": 61929,
      "latitude": 39.843006,
      "longitude": -88.585389,
      "city": "Hammond",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61930,
      "latitude": 39.695357,
      "longitude": -88.127173,
      "city": "Hindsboro",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61931,
      "latitude": 39.586199,
      "longitude": -88.347315,
      "city": "Humboldt",
      "state": "IL",
      "county": "Coles"
  },
  {
      "zipCode": 61932,
      "latitude": 39.718148,
      "longitude": -87.873472,
      "city": "Hume",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61933,
      "latitude": 39.637094,
      "longitude": -87.896219,
      "city": "Kansas",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61936,
      "latitude": 39.798287,
      "longitude": -88.727157,
      "city": "La Place",
      "state": "IL",
      "county": "Piatt"
  },
  {
      "zipCode": 61937,
      "latitude": 39.701633,
      "longitude": -88.606722,
      "city": "Lovington",
      "state": "IL",
      "county": "Moultrie"
  },
  {
      "zipCode": 61938,
      "latitude": 39.505679,
      "longitude": -88.247012,
      "city": "Mattoon",
      "state": "IL",
      "county": "Coles"
  },
  {
      "zipCode": 61940,
      "latitude": 39.80461,
      "longitude": -87.780032,
      "city": "Metcalf",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61941,
      "latitude": 39.800028,
      "longitude": -88.183049,
      "city": "Murdock",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61942,
      "latitude": 39.800771,
      "longitude": -88.029696,
      "city": "Newman",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61943,
      "latitude": 39.62812,
      "longitude": -88.217663,
      "city": "Oakland",
      "state": "IL",
      "county": "Coles"
  },
  {
      "zipCode": 61944,
      "latitude": 39.638496,
      "longitude": -87.740488,
      "city": "Paris",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61949,
      "latitude": 39.660054,
      "longitude": -87.863306,
      "city": "Redmon",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61951,
      "latitude": 39.588093,
      "longitude": -88.633539,
      "city": "Sullivan",
      "state": "IL",
      "county": "Moultrie"
  },
  {
      "zipCode": 61953,
      "latitude": 39.787385,
      "longitude": -88.274137,
      "city": "Tuscola",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61955,
      "latitude": 39.679882,
      "longitude": -87.750216,
      "city": "Vermilion",
      "state": "IL",
      "county": "Edgar"
  },
  {
      "zipCode": 61956,
      "latitude": 39.766275,
      "longitude": -88.091437,
      "city": "Villa Grove",
      "state": "IL",
      "county": "Douglas"
  },
  {
      "zipCode": 61957,
      "latitude": 39.425509,
      "longitude": -88.714896,
      "city": "Windsor",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62001,
      "latitude": 38.882109,
      "longitude": -89.739366,
      "city": "Alhambra",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62002,
      "latitude": 38.929065,
      "longitude": -89.968862,
      "city": "Alton",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62006,
      "latitude": 39.004588,
      "longitude": -90.653455,
      "city": "Batchtown",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62009,
      "latitude": 39.120709,
      "longitude": -89.940021,
      "city": "Benld",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62010,
      "latitude": 38.923994,
      "longitude": -90.054615,
      "city": "Bethalto",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62011,
      "latitude": 39.112821,
      "longitude": -89.212452,
      "city": "Bingham",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62012,
      "latitude": 39.07267,
      "longitude": -90.254596,
      "city": "Brighton",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62013,
      "latitude": 38.925543,
      "longitude": -90.596798,
      "city": "Brussels",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62014,
      "latitude": 39.049428,
      "longitude": -89.980834,
      "city": "Bunker Hill",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62015,
      "latitude": 39.210135,
      "longitude": -89.532057,
      "city": "Butler",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62016,
      "latitude": 39.380638,
      "longitude": -90.399251,
      "city": "Carrollton",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62017,
      "latitude": 39.070254,
      "longitude": -89.380042,
      "city": "Coffeen",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62018,
      "latitude": 38.909971,
      "longitude": -90.064279,
      "city": "Cottage Hills",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62019,
      "latitude": 39.004543,
      "longitude": -89.464108,
      "city": "Donnellson",
      "state": "IL",
      "county": "Bond"
  },
  {
      "zipCode": 62021,
      "latitude": 38.964212,
      "longitude": -89.97814,
      "city": "Dorsey",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62022,
      "latitude": 38.992751,
      "longitude": -90.316504,
      "city": "Dow",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62023,
      "latitude": 39.111806,
      "longitude": -89.785514,
      "city": "Eagarville",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62024,
      "latitude": 38.831578,
      "longitude": -89.99119,
      "city": "East Alton",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62025,
      "latitude": 38.841819,
      "longitude": -89.877841,
      "city": "Edwardsville",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62026,
      "latitude": 38.733748,
      "longitude": -89.94305,
      "city": "Edwardsville",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62027,
      "latitude": 39.286993,
      "longitude": -90.553924,
      "city": "Eldred",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62028,
      "latitude": 38.959647,
      "longitude": -90.338648,
      "city": "Elsah",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62030,
      "latitude": 39.092772,
      "longitude": -90.374651,
      "city": "Fidelity",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62031,
      "latitude": 39.107914,
      "longitude": -90.501701,
      "city": "Fieldon",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62032,
      "latitude": 39.11551,
      "longitude": -89.362709,
      "city": "Fillmore",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62033,
      "latitude": 39.25627,
      "longitude": -89.888832,
      "city": "Gillespie",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62034,
      "latitude": 38.810731,
      "longitude": -90.023867,
      "city": "Glen Carbon",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62035,
      "latitude": 38.884108,
      "longitude": -90.110532,
      "city": "Godfrey",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62036,
      "latitude": 38.966585,
      "longitude": -90.660769,
      "city": "Golden Eagle",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62037,
      "latitude": 38.98414,
      "longitude": -90.43136,
      "city": "Grafton",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62040,
      "latitude": 38.802398,
      "longitude": -90.1289,
      "city": "Granite City",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62044,
      "latitude": 39.355962,
      "longitude": -90.244338,
      "city": "Greenfield",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62045,
      "latitude": 39.13427,
      "longitude": -90.69376,
      "city": "Hamburg",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62046,
      "latitude": 38.880823,
      "longitude": -89.860115,
      "city": "Hamel",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62047,
      "latitude": 39.149443,
      "longitude": -90.622789,
      "city": "Hardin",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62048,
      "latitude": 38.827848,
      "longitude": -90.092606,
      "city": "Hartford",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62049,
      "latitude": 39.139201,
      "longitude": -89.471754,
      "city": "Hillsboro",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62050,
      "latitude": 39.319696,
      "longitude": -90.385171,
      "city": "Hillview",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62051,
      "latitude": 39.199223,
      "longitude": -89.371698,
      "city": "Irving",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62052,
      "latitude": 39.101894,
      "longitude": -90.304917,
      "city": "Jerseyville",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62053,
      "latitude": 39.311587,
      "longitude": -90.636915,
      "city": "Kampsville",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62054,
      "latitude": 39.191892,
      "longitude": -90.352339,
      "city": "Kane",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62056,
      "latitude": 39.169422,
      "longitude": -89.552405,
      "city": "Litchfield",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62058,
      "latitude": 38.968616,
      "longitude": -89.794284,
      "city": "Livingston",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62059,
      "latitude": 38.439983,
      "longitude": -89.983521,
      "city": "Lovejoy",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62060,
      "latitude": 38.683349,
      "longitude": -90.149108,
      "city": "Madison",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62061,
      "latitude": 38.779256,
      "longitude": -89.778648,
      "city": "Marine",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62062,
      "latitude": 38.713829,
      "longitude": -89.965751,
      "city": "Maryville",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62063,
      "latitude": 39.092772,
      "longitude": -90.374651,
      "city": "Medora",
      "state": "IL",
      "county": "Jersey"
  },
  {
      "zipCode": 62065,
      "latitude": 39.13427,
      "longitude": -90.69376,
      "city": "Michael",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62067,
      "latitude": 38.928421,
      "longitude": -89.984339,
      "city": "Moro",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62069,
      "latitude": 39.265646,
      "longitude": -89.922605,
      "city": "Mount Olive",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62070,
      "latitude": 39.13427,
      "longitude": -90.69376,
      "city": "Mozier",
      "state": "IL",
      "county": "Calhoun"
  },
  {
      "zipCode": 62071,
      "latitude": 38.6516,
      "longitude": -90.163909,
      "city": "National Stock Yards",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62074,
      "latitude": 38.892137,
      "longitude": -89.714645,
      "city": "New Douglas",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62075,
      "latitude": 39.241367,
      "longitude": -89.322889,
      "city": "Nokomis",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62076,
      "latitude": 39.261791,
      "longitude": -89.421584,
      "city": "Ohlman",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62077,
      "latitude": 39.031684,
      "longitude": -89.52368,
      "city": "Panama",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62078,
      "latitude": 39.319696,
      "longitude": -90.385171,
      "city": "Patterson",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62079,
      "latitude": 39.112843,
      "longitude": -90.11977,
      "city": "Piasa",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62080,
      "latitude": 39.144177,
      "longitude": -89.110549,
      "city": "Ramsey",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62081,
      "latitude": 39.319696,
      "longitude": -90.385171,
      "city": "Rockbridge",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62082,
      "latitude": 39.483354,
      "longitude": -90.373258,
      "city": "Roodhouse",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62083,
      "latitude": 39.37433,
      "longitude": -89.198256,
      "city": "Rosamond",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62084,
      "latitude": 38.846947,
      "longitude": -90.080752,
      "city": "Roxana",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62085,
      "latitude": 39.082793,
      "longitude": -89.804349,
      "city": "Sawyerville",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62086,
      "latitude": 38.961617,
      "longitude": -89.554727,
      "city": "Sorento",
      "state": "IL",
      "county": "Bond"
  },
  {
      "zipCode": 62087,
      "latitude": 38.822547,
      "longitude": -90.058255,
      "city": "South Roxana",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62088,
      "latitude": 39.038526,
      "longitude": -89.784732,
      "city": "Staunton",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62089,
      "latitude": 39.121757,
      "longitude": -89.494172,
      "city": "Taylor Springs",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62090,
      "latitude": 38.67025,
      "longitude": -90.168859,
      "city": "Venice",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62091,
      "latitude": 39.061381,
      "longitude": -89.61133,
      "city": "Walshville",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62092,
      "latitude": 39.43886,
      "longitude": -90.402937,
      "city": "White Hall",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62093,
      "latitude": 39.069484,
      "longitude": -89.856534,
      "city": "Wilsonville",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62094,
      "latitude": 39.162463,
      "longitude": -89.40593,
      "city": "Witt",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62095,
      "latitude": 38.861597,
      "longitude": -90.085657,
      "city": "Wood River",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62097,
      "latitude": 38.921075,
      "longitude": -89.922418,
      "city": "Worden",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62098,
      "latitude": 39.376153,
      "longitude": -90.293302,
      "city": "Wrights",
      "state": "IL",
      "county": "Greene"
  },
  {
      "zipCode": 62201,
      "latitude": 38.612317,
      "longitude": -90.115857,
      "city": "East Saint Louis",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62202,
      "latitude": 38.6163,
      "longitude": -90.159059,
      "city": "East Saint Louis",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62203,
      "latitude": 38.595757,
      "longitude": -90.077007,
      "city": "East Saint Louis",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62204,
      "latitude": 38.6308,
      "longitude": -90.095007,
      "city": "East Saint Louis",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62205,
      "latitude": 38.6123,
      "longitude": -90.124308,
      "city": "East Saint Louis",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62206,
      "latitude": 38.551401,
      "longitude": -90.15441,
      "city": "East Saint Louis",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62207,
      "latitude": 38.58705,
      "longitude": -90.127808,
      "city": "East Saint Louis",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62208,
      "latitude": 38.59666,
      "longitude": -90.006304,
      "city": "Fairview Heights",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62214,
      "latitude": 38.381545,
      "longitude": -89.579449,
      "city": "Addieville",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62215,
      "latitude": 38.514316,
      "longitude": -89.627154,
      "city": "Albers",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62216,
      "latitude": 38.608924,
      "longitude": -89.603386,
      "city": "Aviston",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62217,
      "latitude": 38.155348,
      "longitude": -89.752849,
      "city": "Baldwin",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62218,
      "latitude": 38.542321,
      "longitude": -89.462267,
      "city": "Bartelso",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62219,
      "latitude": 38.601825,
      "longitude": -89.427944,
      "city": "Beckemeyer",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62220,
      "latitude": 38.46435,
      "longitude": -89.973877,
      "city": "Belleville",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62221,
      "latitude": 38.523569,
      "longitude": -89.911601,
      "city": "Belleville",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62222,
      "latitude": 38.439983,
      "longitude": -89.983521,
      "city": "Belleville",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62223,
      "latitude": 38.55025,
      "longitude": -90.076357,
      "city": "Belleville",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62224,
      "latitude": 38.439983,
      "longitude": -89.983521,
      "city": "Mascoutah",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62225,
      "latitude": 38.543201,
      "longitude": -89.85902,
      "city": "Scott Air Force Base",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62226,
      "latitude": 38.509999,
      "longitude": -89.998685,
      "city": "Belleville",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62230,
      "latitude": 38.640028,
      "longitude": -89.52961,
      "city": "Breese",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62231,
      "latitude": 38.620776,
      "longitude": -89.402155,
      "city": "Carlyle",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62232,
      "latitude": 38.630199,
      "longitude": -90.012477,
      "city": "Caseyville",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62233,
      "latitude": 38.048847,
      "longitude": -89.815308,
      "city": "Chester",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62234,
      "latitude": 38.733747,
      "longitude": -89.908644,
      "city": "Collinsville",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62236,
      "latitude": 38.409442,
      "longitude": -90.16382,
      "city": "Columbia",
      "state": "IL",
      "county": "Monroe"
  },
  {
      "zipCode": 62237,
      "latitude": 38.175298,
      "longitude": -89.647936,
      "city": "Coulterville",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62238,
      "latitude": 38.046458,
      "longitude": -89.478358,
      "city": "Cutler",
      "state": "IL",
      "county": "Perry"
  },
  {
      "zipCode": 62239,
      "latitude": 38.519424,
      "longitude": -90.181561,
      "city": "Dupo",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62240,
      "latitude": 38.524005,
      "longitude": -90.200514,
      "city": "East Carondelet",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62241,
      "latitude": 38.041586,
      "longitude": -89.893045,
      "city": "Ellis Grove",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62242,
      "latitude": 38.10828,
      "longitude": -89.943684,
      "city": "Evansville",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62243,
      "latitude": 38.428067,
      "longitude": -89.895886,
      "city": "Freeburg",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62244,
      "latitude": 38.187143,
      "longitude": -90.194704,
      "city": "Fults",
      "state": "IL",
      "county": "Monroe"
  },
  {
      "zipCode": 62245,
      "latitude": 38.560662,
      "longitude": -89.559413,
      "city": "Germantown",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62246,
      "latitude": 38.884421,
      "longitude": -89.423032,
      "city": "Greenville",
      "state": "IL",
      "county": "Bond"
  },
  {
      "zipCode": 62247,
      "latitude": 38.977111,
      "longitude": -88.975507,
      "city": "Hagarstown",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62248,
      "latitude": 38.299507,
      "longitude": -89.961825,
      "city": "Hecker",
      "state": "IL",
      "county": "Monroe"
  },
  {
      "zipCode": 62249,
      "latitude": 38.758429,
      "longitude": -89.741694,
      "city": "Highland",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62250,
      "latitude": 38.540877,
      "longitude": -89.436298,
      "city": "Hoffman",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62252,
      "latitude": 38.601768,
      "longitude": -89.291549,
      "city": "Huey",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62253,
      "latitude": 38.588112,
      "longitude": -89.472555,
      "city": "Keyesport",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62254,
      "latitude": 38.605448,
      "longitude": -89.82823,
      "city": "Lebanon",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62255,
      "latitude": 38.30606,
      "longitude": -89.782156,
      "city": "Lenzburg",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62256,
      "latitude": 38.30426,
      "longitude": -90.135645,
      "city": "Maeystown",
      "state": "IL",
      "county": "Monroe"
  },
  {
      "zipCode": 62257,
      "latitude": 38.311672,
      "longitude": -89.778855,
      "city": "Marissa",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62258,
      "latitude": 38.421239,
      "longitude": -89.769993,
      "city": "Mascoutah",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62259,
      "latitude": 38.013132,
      "longitude": -89.899569,
      "city": "Menard",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62260,
      "latitude": 38.471651,
      "longitude": -90.092336,
      "city": "Millstadt",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62261,
      "latitude": 38.01529,
      "longitude": -90.00377,
      "city": "Modoc",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62262,
      "latitude": 38.93611,
      "longitude": -89.313739,
      "city": "Mulberry Grove",
      "state": "IL",
      "county": "Bond"
  },
  {
      "zipCode": 62263,
      "latitude": 38.331439,
      "longitude": -89.3674,
      "city": "Nashville",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62264,
      "latitude": 38.30799,
      "longitude": -89.921695,
      "city": "New Athens",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62265,
      "latitude": 38.513254,
      "longitude": -89.587348,
      "city": "New Baden",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62266,
      "latitude": 38.485708,
      "longitude": -89.681587,
      "city": "New Memphis",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62268,
      "latitude": 38.274439,
      "longitude": -89.553239,
      "city": "Oakdale",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62269,
      "latitude": 38.571835,
      "longitude": -89.89568,
      "city": "O Fallon",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62271,
      "latitude": 38.433952,
      "longitude": -89.511013,
      "city": "Okawville",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62272,
      "latitude": 37.997145,
      "longitude": -89.626074,
      "city": "Percy",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62273,
      "latitude": 38.884459,
      "longitude": -89.444898,
      "city": "Pierron",
      "state": "IL",
      "county": "Bond"
  },
  {
      "zipCode": 62274,
      "latitude": 38.083297,
      "longitude": -89.386589,
      "city": "Pinckneyville",
      "state": "IL",
      "county": "Perry"
  },
  {
      "zipCode": 62275,
      "latitude": 38.822283,
      "longitude": -89.525591,
      "city": "Pocahontas",
      "state": "IL",
      "county": "Bond"
  },
  {
      "zipCode": 62277,
      "latitude": 38.114151,
      "longitude": -90.034123,
      "city": "Prairie Du Rocher",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62278,
      "latitude": 38.119605,
      "longitude": -89.818578,
      "city": "Red Bud",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62279,
      "latitude": 38.152057,
      "longitude": -90.134596,
      "city": "Renault",
      "state": "IL",
      "county": "Monroe"
  },
  {
      "zipCode": 62280,
      "latitude": 37.861143,
      "longitude": -89.698387,
      "city": "Rockwood",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62281,
      "latitude": 38.705928,
      "longitude": -89.792463,
      "city": "Saint Jacob",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62282,
      "latitude": 38.363228,
      "longitude": -89.713921,
      "city": "Saint Libory",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62283,
      "latitude": 38.652071,
      "longitude": -89.214562,
      "city": "Shattuc",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62284,
      "latitude": 38.890378,
      "longitude": -89.314242,
      "city": "Smithboro",
      "state": "IL",
      "county": "Bond"
  },
  {
      "zipCode": 62285,
      "latitude": 38.475288,
      "longitude": -89.898651,
      "city": "Smithton",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62286,
      "latitude": 38.108083,
      "longitude": -89.726486,
      "city": "Sparta",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62288,
      "latitude": 38.004698,
      "longitude": -89.676103,
      "city": "Steeleville",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62289,
      "latitude": 38.596698,
      "longitude": -89.751194,
      "city": "Summerfield",
      "state": "IL",
      "county": "Saint Clair"
  },
  {
      "zipCode": 62292,
      "latitude": 38.212224,
      "longitude": -89.689539,
      "city": "Tilden",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62293,
      "latitude": 38.620028,
      "longitude": -89.569004,
      "city": "Trenton",
      "state": "IL",
      "county": "Clinton"
  },
  {
      "zipCode": 62294,
      "latitude": 38.721612,
      "longitude": -89.895817,
      "city": "Troy",
      "state": "IL",
      "county": "Madison"
  },
  {
      "zipCode": 62295,
      "latitude": 38.280514,
      "longitude": -90.312208,
      "city": "Valmeyer",
      "state": "IL",
      "county": "Monroe"
  },
  {
      "zipCode": 62297,
      "latitude": 38.036046,
      "longitude": -89.83918,
      "city": "Walsh",
      "state": "IL",
      "county": "Randolph"
  },
  {
      "zipCode": 62298,
      "latitude": 38.327408,
      "longitude": -90.155869,
      "city": "Waterloo",
      "state": "IL",
      "county": "Monroe"
  },
  {
      "zipCode": 62301,
      "latitude": 39.952413,
      "longitude": -91.200676,
      "city": "Quincy",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62305,
      "latitude": 39.96008,
      "longitude": -91.302633,
      "city": "Quincy",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62306,
      "latitude": 39.97863,
      "longitude": -91.21256,
      "city": "Quincy",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62310,
      "latitude": 40.416145,
      "longitude": -91.205805,
      "city": "Adrian",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62311,
      "latitude": 40.330707,
      "longitude": -91.045874,
      "city": "Augusta",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62312,
      "latitude": 39.611334,
      "longitude": -90.894104,
      "city": "Barry",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62313,
      "latitude": 40.31943,
      "longitude": -91.240855,
      "city": "Basco",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62314,
      "latitude": 39.729666,
      "longitude": -90.90981,
      "city": "Baylis",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62316,
      "latitude": 40.376123,
      "longitude": -91.17718,
      "city": "Bowen",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62318,
      "latitude": 40.488126,
      "longitude": -91.135819,
      "city": "Burnside",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62319,
      "latitude": 40.131603,
      "longitude": -90.556586,
      "city": "Camden",
      "state": "IL",
      "county": "Schuyler"
  },
  {
      "zipCode": 62320,
      "latitude": 40.018202,
      "longitude": -91.060449,
      "city": "Camp Point",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62321,
      "latitude": 40.432148,
      "longitude": -91.135365,
      "city": "Carthage",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62323,
      "latitude": 39.782477,
      "longitude": -90.749883,
      "city": "Chambersburg",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62324,
      "latitude": 39.982894,
      "longitude": -90.949268,
      "city": "Clayton",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62325,
      "latitude": 40.012663,
      "longitude": -91.152206,
      "city": "Coatsburg",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62326,
      "latitude": 40.400892,
      "longitude": -90.734536,
      "city": "Colchester",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 62329,
      "latitude": 40.571353,
      "longitude": -91.168221,
      "city": "Colusa",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62330,
      "latitude": 40.435191,
      "longitude": -91.077508,
      "city": "Dallas City",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62334,
      "latitude": 40.41707,
      "longitude": -91.221434,
      "city": "Elvaston",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62336,
      "latitude": 40.468178,
      "longitude": -91.170959,
      "city": "Ferris",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62338,
      "latitude": 39.960187,
      "longitude": -91.282143,
      "city": "Fowler",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62339,
      "latitude": 40.110022,
      "longitude": -91.027961,
      "city": "Golden",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62340,
      "latitude": 39.741573,
      "longitude": -90.749386,
      "city": "Griggsville",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62341,
      "latitude": 40.475714,
      "longitude": -91.271002,
      "city": "Hamilton",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62343,
      "latitude": 39.620372,
      "longitude": -90.967919,
      "city": "Hull",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62344,
      "latitude": 40.131603,
      "longitude": -90.556586,
      "city": "Huntsville",
      "state": "IL",
      "county": "Schuyler"
  },
  {
      "zipCode": 62345,
      "latitude": 39.620372,
      "longitude": -90.967919,
      "city": "Kinderhook",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62346,
      "latitude": 40.148051,
      "longitude": -91.004209,
      "city": "La Prairie",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62347,
      "latitude": 39.887579,
      "longitude": -91.106747,
      "city": "Liberty",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62348,
      "latitude": 40.165558,
      "longitude": -91.387504,
      "city": "Lima",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62349,
      "latitude": 40.154457,
      "longitude": -91.210203,
      "city": "Loraine",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62351,
      "latitude": 40.074962,
      "longitude": -91.283098,
      "city": "Mendon",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62352,
      "latitude": 39.563497,
      "longitude": -90.650336,
      "city": "Milton",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62353,
      "latitude": 39.978147,
      "longitude": -90.756924,
      "city": "Mount Sterling",
      "state": "IL",
      "county": "Brown"
  },
  {
      "zipCode": 62354,
      "latitude": 40.432598,
      "longitude": -91.208266,
      "city": "Nauvoo",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62355,
      "latitude": 39.442983,
      "longitude": -90.787696,
      "city": "Nebo",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62356,
      "latitude": 39.620372,
      "longitude": -90.967919,
      "city": "New Canton",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62357,
      "latitude": 39.705209,
      "longitude": -90.845884,
      "city": "New Salem",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62358,
      "latitude": 40.49093,
      "longitude": -91.247972,
      "city": "Niota",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62359,
      "latitude": 39.97863,
      "longitude": -91.21256,
      "city": "Paloma",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62360,
      "latitude": 39.829819,
      "longitude": -91.286815,
      "city": "Payson",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62361,
      "latitude": 39.460514,
      "longitude": -90.627114,
      "city": "Pearl",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62362,
      "latitude": 39.78247,
      "longitude": -90.747978,
      "city": "Perry",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62363,
      "latitude": 39.615433,
      "longitude": -90.798482,
      "city": "Pittsfield",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62365,
      "latitude": 39.935995,
      "longitude": -91.232952,
      "city": "Plainville",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62366,
      "latitude": 39.447008,
      "longitude": -90.87168,
      "city": "Pleasant Hill",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62367,
      "latitude": 40.323553,
      "longitude": -90.967177,
      "city": "Plymouth",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62370,
      "latitude": 39.620372,
      "longitude": -90.967919,
      "city": "Rockport",
      "state": "IL",
      "county": "Pike"
  },
  {
      "zipCode": 62373,
      "latitude": 40.286511,
      "longitude": -91.372569,
      "city": "Sutter",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62374,
      "latitude": 40.388444,
      "longitude": -90.764268,
      "city": "Tennessee",
      "state": "IL",
      "county": "Mcdonough"
  },
  {
      "zipCode": 62375,
      "latitude": 40.007601,
      "longitude": -90.874657,
      "city": "Timewell",
      "state": "IL",
      "county": "Brown"
  },
  {
      "zipCode": 62376,
      "latitude": 40.073445,
      "longitude": -91.206586,
      "city": "Ursa",
      "state": "IL",
      "county": "Adams"
  },
  {
      "zipCode": 62378,
      "latitude": 39.944465,
      "longitude": -90.737957,
      "city": "Versailles",
      "state": "IL",
      "county": "Brown"
  },
  {
      "zipCode": 62379,
      "latitude": 40.297801,
      "longitude": -91.198818,
      "city": "Warsaw",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62380,
      "latitude": 40.241584,
      "longitude": -91.240017,
      "city": "West Point",
      "state": "IL",
      "county": "Hancock"
  },
  {
      "zipCode": 62401,
      "latitude": 39.116649,
      "longitude": -88.545705,
      "city": "Effingham",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62410,
      "latitude": 38.527638,
      "longitude": -87.706344,
      "city": "Allendale",
      "state": "IL",
      "county": "Wabash"
  },
  {
      "zipCode": 62411,
      "latitude": 39.053436,
      "longitude": -88.719754,
      "city": "Altamont",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62413,
      "latitude": 39.134986,
      "longitude": -87.838419,
      "city": "Annapolis",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62414,
      "latitude": 39.194098,
      "longitude": -88.760126,
      "city": "Beecher City",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62415,
      "latitude": 38.710336,
      "longitude": -87.703639,
      "city": "Birds",
      "state": "IL",
      "county": "Lawrence"
  },
  {
      "zipCode": 62417,
      "latitude": 38.654076,
      "longitude": -87.706902,
      "city": "Bridgeport",
      "state": "IL",
      "county": "Lawrence"
  },
  {
      "zipCode": 62418,
      "latitude": 38.994303,
      "longitude": -88.955042,
      "city": "Brownstown",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62419,
      "latitude": 38.65,
      "longitude": -88.047886,
      "city": "Calhoun",
      "state": "IL",
      "county": "Richland"
  },
  {
      "zipCode": 62420,
      "latitude": 39.293482,
      "longitude": -87.954456,
      "city": "Casey",
      "state": "IL",
      "county": "Clark"
  },
  {
      "zipCode": 62421,
      "latitude": 38.743413,
      "longitude": -87.966843,
      "city": "Claremont",
      "state": "IL",
      "county": "Richland"
  },
  {
      "zipCode": 62422,
      "latitude": 39.250015,
      "longitude": -88.862961,
      "city": "Cowden",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62423,
      "latitude": 39.411823,
      "longitude": -87.573146,
      "city": "Dennison",
      "state": "IL",
      "county": "Clark"
  },
  {
      "zipCode": 62424,
      "latitude": 39.049843,
      "longitude": -88.576045,
      "city": "Dieterich",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62425,
      "latitude": 38.819798,
      "longitude": -88.090524,
      "city": "Dundas",
      "state": "IL",
      "county": "Richland"
  },
  {
      "zipCode": 62426,
      "latitude": 38.988768,
      "longitude": -88.524406,
      "city": "Edgewood",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62427,
      "latitude": 38.895585,
      "longitude": -87.672672,
      "city": "Flat Rock",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62428,
      "latitude": 39.274017,
      "longitude": -88.124598,
      "city": "Greenup",
      "state": "IL",
      "county": "Cumberland"
  },
  {
      "zipCode": 62431,
      "latitude": 39.219603,
      "longitude": -88.981896,
      "city": "Herrick",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62432,
      "latitude": 39.127107,
      "longitude": -88.117007,
      "city": "Hidalgo",
      "state": "IL",
      "county": "Jasper"
  },
  {
      "zipCode": 62433,
      "latitude": 39.085312,
      "longitude": -87.707636,
      "city": "Hutsonville",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62434,
      "latitude": 38.757302,
      "longitude": -88.473594,
      "city": "Ingraham",
      "state": "IL",
      "county": "Clay"
  },
  {
      "zipCode": 62435,
      "latitude": 39.374287,
      "longitude": -88.244487,
      "city": "Janesville",
      "state": "IL",
      "county": "Cumberland"
  },
  {
      "zipCode": 62436,
      "latitude": 39.207405,
      "longitude": -88.273197,
      "city": "Jewett",
      "state": "IL",
      "county": "Cumberland"
  },
  {
      "zipCode": 62438,
      "latitude": 39.434607,
      "longitude": -88.805278,
      "city": "Lakewood",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62439,
      "latitude": 38.685176,
      "longitude": -87.634235,
      "city": "Lawrenceville",
      "state": "IL",
      "county": "Lawrence"
  },
  {
      "zipCode": 62440,
      "latitude": 39.418401,
      "longitude": -88.270006,
      "city": "Lerna",
      "state": "IL",
      "county": "Coles"
  },
  {
      "zipCode": 62441,
      "latitude": 39.331139,
      "longitude": -87.747363,
      "city": "Marshall",
      "state": "IL",
      "county": "Clark"
  },
  {
      "zipCode": 62442,
      "latitude": 39.325782,
      "longitude": -87.850086,
      "city": "Martinsville",
      "state": "IL",
      "county": "Clark"
  },
  {
      "zipCode": 62443,
      "latitude": 38.95267,
      "longitude": -88.635111,
      "city": "Mason",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62444,
      "latitude": 39.434607,
      "longitude": -88.805278,
      "city": "Mode",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62445,
      "latitude": 39.163869,
      "longitude": -88.37756,
      "city": "Montrose",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62446,
      "latitude": 38.381926,
      "longitude": -88.405596,
      "city": "Mount Erie",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62447,
      "latitude": 39.290276,
      "longitude": -88.241333,
      "city": "Neoga",
      "state": "IL",
      "county": "Cumberland"
  },
  {
      "zipCode": 62448,
      "latitude": 38.980049,
      "longitude": -88.184675,
      "city": "Newton",
      "state": "IL",
      "county": "Jasper"
  },
  {
      "zipCode": 62449,
      "latitude": 39.029652,
      "longitude": -87.782569,
      "city": "Oblong",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62450,
      "latitude": 38.709154,
      "longitude": -88.085204,
      "city": "Olney",
      "state": "IL",
      "county": "Richland"
  },
  {
      "zipCode": 62451,
      "latitude": 39.000528,
      "longitude": -87.604191,
      "city": "Palestine",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62452,
      "latitude": 38.587985,
      "longitude": -88.011149,
      "city": "Parkersburg",
      "state": "IL",
      "county": "Richland"
  },
  {
      "zipCode": 62454,
      "latitude": 39.004559,
      "longitude": -87.751776,
      "city": "Robinson",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62458,
      "latitude": 39.025319,
      "longitude": -88.852372,
      "city": "Saint Elmo",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62459,
      "latitude": 38.927986,
      "longitude": -88.029084,
      "city": "Sainte Marie",
      "state": "IL",
      "county": "Jasper"
  },
  {
      "zipCode": 62460,
      "latitude": 38.592132,
      "longitude": -87.648661,
      "city": "Saint Francisville",
      "state": "IL",
      "county": "Lawrence"
  },
  {
      "zipCode": 62461,
      "latitude": 39.112465,
      "longitude": -88.670484,
      "city": "Shumway",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62462,
      "latitude": 39.251684,
      "longitude": -88.510776,
      "city": "Sigel",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62463,
      "latitude": 39.273518,
      "longitude": -88.80134,
      "city": "Stewardson",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62464,
      "latitude": 38.98981,
      "longitude": -87.839627,
      "city": "Stoy",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62465,
      "latitude": 39.349589,
      "longitude": -88.616381,
      "city": "Strasburg",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62466,
      "latitude": 38.717105,
      "longitude": -87.863812,
      "city": "Sumner",
      "state": "IL",
      "county": "Lawrence"
  },
  {
      "zipCode": 62467,
      "latitude": 39.132706,
      "longitude": -88.450793,
      "city": "Teutopolis",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62468,
      "latitude": 39.282208,
      "longitude": -88.272657,
      "city": "Toledo",
      "state": "IL",
      "county": "Cumberland"
  },
  {
      "zipCode": 62469,
      "latitude": 39.393753,
      "longitude": -88.348819,
      "city": "Trilla",
      "state": "IL",
      "county": "Coles"
  },
  {
      "zipCode": 62471,
      "latitude": 38.982404,
      "longitude": -89.051628,
      "city": "Vandalia",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62473,
      "latitude": 38.990551,
      "longitude": -88.558435,
      "city": "Watson",
      "state": "IL",
      "county": "Effingham"
  },
  {
      "zipCode": 62474,
      "latitude": 39.407315,
      "longitude": -87.949053,
      "city": "Westfield",
      "state": "IL",
      "county": "Clark"
  },
  {
      "zipCode": 62475,
      "latitude": 39.003369,
      "longitude": -88.067535,
      "city": "West Liberty",
      "state": "IL",
      "county": "Jasper"
  },
  {
      "zipCode": 62476,
      "latitude": 38.519678,
      "longitude": -88.009457,
      "city": "West Salem",
      "state": "IL",
      "county": "Edwards"
  },
  {
      "zipCode": 62477,
      "latitude": 39.236906,
      "longitude": -87.674977,
      "city": "West Union",
      "state": "IL",
      "county": "Clark"
  },
  {
      "zipCode": 62478,
      "latitude": 39.158846,
      "longitude": -87.723392,
      "city": "West York",
      "state": "IL",
      "county": "Crawford"
  },
  {
      "zipCode": 62479,
      "latitude": 39.028977,
      "longitude": -88.299931,
      "city": "Wheeler",
      "state": "IL",
      "county": "Jasper"
  },
  {
      "zipCode": 62480,
      "latitude": 38.992322,
      "longitude": -88.031057,
      "city": "Willow Hill",
      "state": "IL",
      "county": "Jasper"
  },
  {
      "zipCode": 62481,
      "latitude": 39.121515,
      "longitude": -88.157258,
      "city": "Yale",
      "state": "IL",
      "county": "Jasper"
  },
  {
      "zipCode": 62501,
      "latitude": 39.965322,
      "longitude": -88.848695,
      "city": "Argenta",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62510,
      "latitude": 39.527214,
      "longitude": -89.249643,
      "city": "Assumption",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62511,
      "latitude": 39.339104,
      "longitude": -89.735704,
      "city": "Atwater",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62512,
      "latitude": 40.150457,
      "longitude": -89.228483,
      "city": "Beason",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62513,
      "latitude": 39.76891,
      "longitude": -89.044099,
      "city": "Blue Mound",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62514,
      "latitude": 39.764726,
      "longitude": -89.039766,
      "city": "Boody",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62515,
      "latitude": 39.849893,
      "longitude": -89.387944,
      "city": "Buffalo",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62517,
      "latitude": 39.588232,
      "longitude": -89.417117,
      "city": "Bulpitt",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62518,
      "latitude": 40.05335,
      "longitude": -89.19891,
      "city": "Chestnut",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62519,
      "latitude": 39.937364,
      "longitude": -89.40234,
      "city": "Cornland",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62520,
      "latitude": 39.826185,
      "longitude": -89.43989,
      "city": "Dawson",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62521,
      "latitude": 39.839477,
      "longitude": -88.946486,
      "city": "Decatur",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62522,
      "latitude": 39.865621,
      "longitude": -89.018696,
      "city": "Decatur",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62523,
      "latitude": 39.911662,
      "longitude": -88.890104,
      "city": "Decatur",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62524,
      "latitude": 39.853981,
      "longitude": -88.981511,
      "city": "Decatur",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62525,
      "latitude": 39.853981,
      "longitude": -88.981511,
      "city": "Decatur",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62526,
      "latitude": 39.858284,
      "longitude": -88.938244,
      "city": "Decatur",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62527,
      "latitude": 39.853981,
      "longitude": -88.981511,
      "city": "Decatur",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62530,
      "latitude": 39.569165,
      "longitude": -89.662571,
      "city": "Divernon",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62531,
      "latitude": 39.612132,
      "longitude": -89.311109,
      "city": "Edinburg",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62532,
      "latitude": 39.780021,
      "longitude": -88.980838,
      "city": "Elwin",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62533,
      "latitude": 39.440138,
      "longitude": -89.617023,
      "city": "Farmersville",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62534,
      "latitude": 39.519283,
      "longitude": -88.746756,
      "city": "Findlay",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62535,
      "latitude": 39.884409,
      "longitude": -89.001417,
      "city": "Forsyth",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62536,
      "latitude": 39.641606,
      "longitude": -89.650041,
      "city": "Glenarm",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62537,
      "latitude": 39.856678,
      "longitude": -89.085041,
      "city": "Harristown",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62538,
      "latitude": 39.367208,
      "longitude": -89.522401,
      "city": "Harvel",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62539,
      "latitude": 39.860619,
      "longitude": -89.378449,
      "city": "Illiopolis",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62540,
      "latitude": 39.500009,
      "longitude": -89.416708,
      "city": "Kincaid",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62541,
      "latitude": 39.970619,
      "longitude": -89.350528,
      "city": "Lake Fork",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62543,
      "latitude": 40.075633,
      "longitude": -89.315464,
      "city": "Latham",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62544,
      "latitude": 39.700267,
      "longitude": -89.022026,
      "city": "Macon",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62545,
      "latitude": 39.777807,
      "longitude": -89.406447,
      "city": "Mechanicsburg",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62546,
      "latitude": 39.469098,
      "longitude": -89.396732,
      "city": "Morrisonville",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62547,
      "latitude": 39.702903,
      "longitude": -89.282367,
      "city": "Mount Auburn",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62548,
      "latitude": 39.989734,
      "longitude": -89.294027,
      "city": "Mount Pulaski",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62549,
      "latitude": 39.825599,
      "longitude": -88.907654,
      "city": "Mt Zion",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62550,
      "latitude": 39.575086,
      "longitude": -88.889483,
      "city": "Moweaqua",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62551,
      "latitude": 39.91497,
      "longitude": -88.997459,
      "city": "Niantic",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62552,
      "latitude": 39.883505,
      "longitude": -88.8048,
      "city": "Oakley",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62553,
      "latitude": 39.434607,
      "longitude": -88.805278,
      "city": "Oconee",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62554,
      "latitude": 39.927262,
      "longitude": -88.850218,
      "city": "Oreana",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62555,
      "latitude": 39.458365,
      "longitude": -89.217453,
      "city": "Owaneco",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62556,
      "latitude": 39.429374,
      "longitude": -89.30808,
      "city": "Palmer",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62557,
      "latitude": 39.503638,
      "longitude": -89.243695,
      "city": "Pana",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62558,
      "latitude": 39.60855,
      "longitude": -89.567791,
      "city": "Pawnee",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62560,
      "latitude": 39.311814,
      "longitude": -89.580236,
      "city": "Raymond",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62561,
      "latitude": 39.750415,
      "longitude": -89.542678,
      "city": "Riverton",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62563,
      "latitude": 39.718237,
      "longitude": -89.564891,
      "city": "Rochester",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62565,
      "latitude": 39.458013,
      "longitude": -88.805788,
      "city": "Shelbyville",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62567,
      "latitude": 39.646379,
      "longitude": -89.218939,
      "city": "Stonington",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62568,
      "latitude": 39.559092,
      "longitude": -89.34063,
      "city": "Taylorville",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62570,
      "latitude": 39.588002,
      "longitude": -89.449507,
      "city": "Tovey",
      "state": "IL",
      "county": "Christian"
  },
  {
      "zipCode": 62571,
      "latitude": 39.38996,
      "longitude": -88.959746,
      "city": "Tower Hill",
      "state": "IL",
      "county": "Shelby"
  },
  {
      "zipCode": 62572,
      "latitude": 39.368777,
      "longitude": -89.654507,
      "city": "Waggoner",
      "state": "IL",
      "county": "Montgomery"
  },
  {
      "zipCode": 62573,
      "latitude": 39.917841,
      "longitude": -89.017515,
      "city": "Warrensburg",
      "state": "IL",
      "county": "Macon"
  },
  {
      "zipCode": 62601,
      "latitude": 39.751657,
      "longitude": -90.045839,
      "city": "Alexander",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62610,
      "latitude": 39.551987,
      "longitude": -90.386156,
      "city": "Alsey",
      "state": "IL",
      "county": "Scott"
  },
  {
      "zipCode": 62611,
      "latitude": 39.913938,
      "longitude": -90.370736,
      "city": "Arenzville",
      "state": "IL",
      "county": "Cass"
  },
  {
      "zipCode": 62612,
      "latitude": 39.938647,
      "longitude": -90.079241,
      "city": "Ashland",
      "state": "IL",
      "county": "Cass"
  },
  {
      "zipCode": 62613,
      "latitude": 39.950205,
      "longitude": -89.683294,
      "city": "Athens",
      "state": "IL",
      "county": "Menard"
  },
  {
      "zipCode": 62615,
      "latitude": 39.588654,
      "longitude": -89.749692,
      "city": "Auburn",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62617,
      "latitude": 40.153626,
      "longitude": -90.166065,
      "city": "Bath",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 62618,
      "latitude": 39.962623,
      "longitude": -90.265277,
      "city": "Beardstown",
      "state": "IL",
      "county": "Cass"
  },
  {
      "zipCode": 62621,
      "latitude": 39.729634,
      "longitude": -90.531307,
      "city": "Bluffs",
      "state": "IL",
      "county": "Scott"
  },
  {
      "zipCode": 62622,
      "latitude": 39.979614,
      "longitude": -90.352444,
      "city": "Bluff Springs",
      "state": "IL",
      "county": "Cass"
  },
  {
      "zipCode": 62624,
      "latitude": 40.131603,
      "longitude": -90.556586,
      "city": "Browning",
      "state": "IL",
      "county": "Schuyler"
  },
  {
      "zipCode": 62625,
      "latitude": 39.916942,
      "longitude": -89.689148,
      "city": "Cantrall",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62626,
      "latitude": 39.288546,
      "longitude": -89.866054,
      "city": "Carlinville",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62627,
      "latitude": 40.037402,
      "longitude": -90.116282,
      "city": "Chandlerville",
      "state": "IL",
      "county": "Cass"
  },
  {
      "zipCode": 62628,
      "latitude": 39.80547,
      "longitude": -90.375324,
      "city": "Chapin",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62629,
      "latitude": 39.793982,
      "longitude": -89.695888,
      "city": "Chatham",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62630,
      "latitude": 39.337165,
      "longitude": -90.070475,
      "city": "Chesterfield",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62631,
      "latitude": 39.825847,
      "longitude": -90.37469,
      "city": "Concord",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62633,
      "latitude": 40.227887,
      "longitude": -89.890271,
      "city": "Easton",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 62634,
      "latitude": 40.109099,
      "longitude": -89.471352,
      "city": "Elkhart",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62635,
      "latitude": 40.195101,
      "longitude": -89.483718,
      "city": "Emden",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62638,
      "latitude": 39.622203,
      "longitude": -90.088945,
      "city": "Franklin",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62639,
      "latitude": 40.131603,
      "longitude": -90.556586,
      "city": "Frederick",
      "state": "IL",
      "county": "Schuyler"
  },
  {
      "zipCode": 62640,
      "latitude": 39.383843,
      "longitude": -89.85006,
      "city": "Girard",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62642,
      "latitude": 40.101722,
      "longitude": -89.746851,
      "city": "Greenview",
      "state": "IL",
      "county": "Menard"
  },
  {
      "zipCode": 62643,
      "latitude": 40.251211,
      "longitude": -89.47409,
      "city": "Hartsburg",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62644,
      "latitude": 40.308441,
      "longitude": -89.938334,
      "city": "Havana",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 62649,
      "latitude": 39.34792,
      "longitude": -90.071437,
      "city": "Hettick",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62650,
      "latitude": 39.737044,
      "longitude": -90.301388,
      "city": "Jacksonville",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62651,
      "latitude": 39.698311,
      "longitude": -90.26153,
      "city": "Jacksonville",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62655,
      "latitude": 40.214332,
      "longitude": -89.855105,
      "city": "Kilbourne",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 62656,
      "latitude": 40.162779,
      "longitude": -89.398361,
      "city": "Lincoln",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62659,
      "latitude": 40.031115,
      "longitude": -89.786723,
      "city": "Lincoln's New Salem",
      "state": "IL",
      "county": "Menard"
  },
  {
      "zipCode": 62660,
      "latitude": 39.858933,
      "longitude": -90.200747,
      "city": "Literberry",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62661,
      "latitude": 39.671039,
      "longitude": -89.845401,
      "city": "Loami",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62662,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Lowder",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62663,
      "latitude": 39.540479,
      "longitude": -90.331705,
      "city": "Manchester",
      "state": "IL",
      "county": "Scott"
  },
  {
      "zipCode": 62664,
      "latitude": 40.226923,
      "longitude": -89.731758,
      "city": "Mason City",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 62665,
      "latitude": 39.724007,
      "longitude": -90.426529,
      "city": "Meredosia",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62666,
      "latitude": 40.085044,
      "longitude": -89.522027,
      "city": "Middletown",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62667,
      "latitude": 39.480647,
      "longitude": -89.982149,
      "city": "Modesto",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62668,
      "latitude": 39.675739,
      "longitude": -90.323925,
      "city": "Murrayville",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62670,
      "latitude": 39.768067,
      "longitude": -89.854952,
      "city": "New Berlin",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62671,
      "latitude": 40.16912,
      "longitude": -89.542183,
      "city": "New Holland",
      "state": "IL",
      "county": "Logan"
  },
  {
      "zipCode": 62672,
      "latitude": 39.397082,
      "longitude": -89.89125,
      "city": "Nilwood",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62673,
      "latitude": 40.100737,
      "longitude": -89.966028,
      "city": "Oakford",
      "state": "IL",
      "county": "Menard"
  },
  {
      "zipCode": 62674,
      "latitude": 39.422719,
      "longitude": -90.031093,
      "city": "Palmyra",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62675,
      "latitude": 39.972828,
      "longitude": -89.807192,
      "city": "Petersburg",
      "state": "IL",
      "county": "Menard"
  },
  {
      "zipCode": 62677,
      "latitude": 39.83314,
      "longitude": -89.868578,
      "city": "Pleasant Plains",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62681,
      "latitude": 40.113259,
      "longitude": -90.543205,
      "city": "Rushville",
      "state": "IL",
      "county": "Schuyler"
  },
  {
      "zipCode": 62682,
      "latitude": 40.301103,
      "longitude": -89.687211,
      "city": "San Jose",
      "state": "IL",
      "county": "Mason"
  },
  {
      "zipCode": 62683,
      "latitude": 39.260862,
      "longitude": -89.926068,
      "city": "Scottville",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62684,
      "latitude": 39.912115,
      "longitude": -89.587697,
      "city": "Sherman",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62685,
      "latitude": 39.195161,
      "longitude": -89.960661,
      "city": "Shipman",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62686,
      "latitude": 39.23868,
      "longitude": -89.889363,
      "city": "Standard City",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62688,
      "latitude": 39.938136,
      "longitude": -89.943256,
      "city": "Tallula",
      "state": "IL",
      "county": "Menard"
  },
  {
      "zipCode": 62689,
      "latitude": 39.539389,
      "longitude": -89.762086,
      "city": "Thayer",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62690,
      "latitude": 39.480838,
      "longitude": -89.903356,
      "city": "Virden",
      "state": "IL",
      "county": "Macoupin"
  },
  {
      "zipCode": 62691,
      "latitude": 39.958003,
      "longitude": -90.176415,
      "city": "Virginia",
      "state": "IL",
      "county": "Cass"
  },
  {
      "zipCode": 62692,
      "latitude": 39.622723,
      "longitude": -90.003569,
      "city": "Waverly",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62693,
      "latitude": 39.920091,
      "longitude": -89.570001,
      "city": "Williamsville",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62694,
      "latitude": 39.62663,
      "longitude": -90.466389,
      "city": "Winchester",
      "state": "IL",
      "county": "Scott"
  },
  {
      "zipCode": 62695,
      "latitude": 39.618903,
      "longitude": -90.225738,
      "city": "Woodson",
      "state": "IL",
      "county": "Morgan"
  },
  {
      "zipCode": 62701,
      "latitude": 39.820839,
      "longitude": -89.598978,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62702,
      "latitude": 39.831699,
      "longitude": -89.646507,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62703,
      "latitude": 39.762206,
      "longitude": -89.627547,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62704,
      "latitude": 39.772463,
      "longitude": -89.688924,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62705,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62706,
      "latitude": 39.79885,
      "longitude": -89.653399,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62707,
      "latitude": 39.785116,
      "longitude": -89.632205,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62708,
      "latitude": 39.806089,
      "longitude": -89.586356,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62709,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62713,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62715,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62716,
      "latitude": 39.848201,
      "longitude": -89.536369,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62718,
      "latitude": 39.778044,
      "longitude": -89.646555,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62719,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62720,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62721,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62722,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62723,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62726,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62736,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62739,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62746,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62756,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62757,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62761,
      "latitude": 39.852361,
      "longitude": -89.541017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62762,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62763,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62764,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62765,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62766,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62767,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62769,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62776,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62777,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62781,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62786,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62791,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62794,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62796,
      "latitude": 39.749457,
      "longitude": -89.606017,
      "city": "Springfield",
      "state": "IL",
      "county": "Sangamon"
  },
  {
      "zipCode": 62801,
      "latitude": 38.63831,
      "longitude": -88.957758,
      "city": "Centralia",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62803,
      "latitude": 38.371071,
      "longitude": -89.300072,
      "city": "Hoyleton",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62805,
      "latitude": 37.994296,
      "longitude": -88.941665,
      "city": "Akin",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62806,
      "latitude": 38.328816,
      "longitude": -88.064182,
      "city": "Albion",
      "state": "IL",
      "county": "Edwards"
  },
  {
      "zipCode": 62807,
      "latitude": 38.758394,
      "longitude": -88.929039,
      "city": "Alma",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62808,
      "latitude": 38.290049,
      "longitude": -89.232163,
      "city": "Ashley",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62809,
      "latitude": 38.431273,
      "longitude": -88.424978,
      "city": "Barnhill",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62810,
      "latitude": 38.202361,
      "longitude": -88.774226,
      "city": "Belle Rive",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62811,
      "latitude": 38.384525,
      "longitude": -87.908101,
      "city": "Bellmont",
      "state": "IL",
      "county": "Wabash"
  },
  {
      "zipCode": 62812,
      "latitude": 37.994423,
      "longitude": -88.907464,
      "city": "Benton",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62814,
      "latitude": 38.384549,
      "longitude": -88.746322,
      "city": "Bluford",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62815,
      "latitude": 38.413149,
      "longitude": -88.043941,
      "city": "Bone Gap",
      "state": "IL",
      "county": "Edwards"
  },
  {
      "zipCode": 62816,
      "latitude": 38.20359,
      "longitude": -88.936697,
      "city": "Bonnie",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62817,
      "latitude": 37.921925,
      "longitude": -88.468436,
      "city": "Broughton",
      "state": "IL",
      "county": "Hamilton"
  },
  {
      "zipCode": 62818,
      "latitude": 38.413149,
      "longitude": -88.043941,
      "city": "Browns",
      "state": "IL",
      "county": "Edwards"
  },
  {
      "zipCode": 62819,
      "latitude": 37.976448,
      "longitude": -89.020912,
      "city": "Buckner",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62820,
      "latitude": 38.220716,
      "longitude": -88.222704,
      "city": "Burnt Prairie",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62821,
      "latitude": 38.089387,
      "longitude": -88.175742,
      "city": "Carmi",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62822,
      "latitude": 38.028648,
      "longitude": -89.07209,
      "city": "Christopher",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62823,
      "latitude": 38.431273,
      "longitude": -88.424978,
      "city": "Cisne",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62824,
      "latitude": 38.725325,
      "longitude": -88.417422,
      "city": "Clay City",
      "state": "IL",
      "county": "Clay"
  },
  {
      "zipCode": 62825,
      "latitude": 37.994633,
      "longitude": -89.067228,
      "city": "Coello",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62827,
      "latitude": 38.163144,
      "longitude": -88.03546,
      "city": "Crossville",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62828,
      "latitude": 38.168641,
      "longitude": -88.629728,
      "city": "Dahlgren",
      "state": "IL",
      "county": "Hamilton"
  },
  {
      "zipCode": 62829,
      "latitude": 38.081592,
      "longitude": -88.538609,
      "city": "Dale",
      "state": "IL",
      "county": "Hamilton"
  },
  {
      "zipCode": 62830,
      "latitude": 38.357503,
      "longitude": -88.910561,
      "city": "Dix",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62831,
      "latitude": 38.249974,
      "longitude": -89.230396,
      "city": "Du Bois",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62832,
      "latitude": 38.044645,
      "longitude": -89.253006,
      "city": "Du Quoin",
      "state": "IL",
      "county": "Perry"
  },
  {
      "zipCode": 62833,
      "latitude": 38.413149,
      "longitude": -88.043941,
      "city": "Ellery",
      "state": "IL",
      "county": "Edwards"
  },
  {
      "zipCode": 62834,
      "latitude": 37.97622,
      "longitude": -88.120182,
      "city": "Emma",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62835,
      "latitude": 38.100523,
      "longitude": -88.29927,
      "city": "Enfield",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62836,
      "latitude": 38.082002,
      "longitude": -88.790486,
      "city": "Ewing",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62837,
      "latitude": 38.389091,
      "longitude": -88.367146,
      "city": "Fairfield",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62838,
      "latitude": 38.832201,
      "longitude": -88.776244,
      "city": "Farina",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62839,
      "latitude": 38.710797,
      "longitude": -88.493686,
      "city": "Flora",
      "state": "IL",
      "county": "Clay"
  },
  {
      "zipCode": 62840,
      "latitude": 37.994296,
      "longitude": -88.941665,
      "city": "Frankfort Heights",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62841,
      "latitude": 37.80308,
      "longitude": -89.009201,
      "city": "Freeman Spur",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62842,
      "latitude": 38.441055,
      "longitude": -88.409905,
      "city": "Geff",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62843,
      "latitude": 38.357551,
      "longitude": -88.206145,
      "city": "Golden Gate",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62844,
      "latitude": 38.147618,
      "longitude": -88.122963,
      "city": "Grayville",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62845,
      "latitude": 38.073931,
      "longitude": -88.142657,
      "city": "Herald",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62846,
      "latitude": 38.155485,
      "longitude": -88.819501,
      "city": "Ina",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62847,
      "latitude": 38.757302,
      "longitude": -88.473594,
      "city": "Iola",
      "state": "IL",
      "county": "Clay"
  },
  {
      "zipCode": 62848,
      "latitude": 38.413658,
      "longitude": -89.167834,
      "city": "Irvington",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62849,
      "latitude": 38.595441,
      "longitude": -88.809517,
      "city": "Iuka",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62850,
      "latitude": 38.519401,
      "longitude": -88.536564,
      "city": "Johnsonville",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62851,
      "latitude": 38.431273,
      "longitude": -88.424978,
      "city": "Keenes",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62852,
      "latitude": 38.351408,
      "longitude": -87.86607,
      "city": "Keensburg",
      "state": "IL",
      "county": "Wabash"
  },
  {
      "zipCode": 62853,
      "latitude": 38.59718,
      "longitude": -88.901043,
      "city": "Kell",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62854,
      "latitude": 38.657892,
      "longitude": -88.91335,
      "city": "Kinmundy",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62855,
      "latitude": 38.403424,
      "longitude": -87.818672,
      "city": "Lancaster",
      "state": "IL",
      "county": "Wabash"
  },
  {
      "zipCode": 62856,
      "latitude": 37.994296,
      "longitude": -88.941665,
      "city": "Logan",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62857,
      "latitude": 38.977111,
      "longitude": -88.975507,
      "city": "Loogootee",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62858,
      "latitude": 38.771768,
      "longitude": -88.506465,
      "city": "Louisville",
      "state": "IL",
      "county": "Clay"
  },
  {
      "zipCode": 62859,
      "latitude": 38.08963,
      "longitude": -88.53653,
      "city": "Mc Leansboro",
      "state": "IL",
      "county": "Hamilton"
  },
  {
      "zipCode": 62860,
      "latitude": 38.081592,
      "longitude": -88.538609,
      "city": "Macedonia",
      "state": "IL",
      "county": "Hamilton"
  },
  {
      "zipCode": 62861,
      "latitude": 38.036393,
      "longitude": -88.075864,
      "city": "Maunie",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62862,
      "latitude": 38.164114,
      "longitude": -88.258325,
      "city": "Mill Shoals",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62863,
      "latitude": 38.441814,
      "longitude": -87.828423,
      "city": "Mount Carmel",
      "state": "IL",
      "county": "Wabash"
  },
  {
      "zipCode": 62864,
      "latitude": 38.293269,
      "longitude": -88.892273,
      "city": "Mount Vernon",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62865,
      "latitude": 37.97746,
      "longitude": -89.081507,
      "city": "Mulkeytown",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62866,
      "latitude": 38.16542,
      "longitude": -88.967551,
      "city": "Nason",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62867,
      "latitude": 37.887676,
      "longitude": -88.146409,
      "city": "New Haven",
      "state": "IL",
      "county": "Gallatin"
  },
  {
      "zipCode": 62868,
      "latitude": 38.715532,
      "longitude": -88.225831,
      "city": "Noble",
      "state": "IL",
      "county": "Richland"
  },
  {
      "zipCode": 62869,
      "latitude": 38.076688,
      "longitude": -88.211579,
      "city": "Norris City",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62870,
      "latitude": 38.636324,
      "longitude": -88.946253,
      "city": "Odin",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62871,
      "latitude": 37.845963,
      "longitude": -88.240226,
      "city": "Omaha",
      "state": "IL",
      "county": "Gallatin"
  },
  {
      "zipCode": 62872,
      "latitude": 38.273854,
      "longitude": -88.805414,
      "city": "Opdyke",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62874,
      "latitude": 37.91819,
      "longitude": -88.976706,
      "city": "Orient",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62875,
      "latitude": 38.701337,
      "longitude": -88.962515,
      "city": "Patoka",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62876,
      "latitude": 38.260696,
      "longitude": -89.198858,
      "city": "Radom",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62877,
      "latitude": 38.394883,
      "longitude": -89.224511,
      "city": "Richview",
      "state": "IL",
      "county": "Washington"
  },
  {
      "zipCode": 62878,
      "latitude": 38.431273,
      "longitude": -88.424978,
      "city": "Rinard",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62879,
      "latitude": 38.757302,
      "longitude": -88.473594,
      "city": "Sailor Springs",
      "state": "IL",
      "county": "Clay"
  },
  {
      "zipCode": 62880,
      "latitude": 38.867965,
      "longitude": -88.852749,
      "city": "Saint Peter",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62881,
      "latitude": 38.643793,
      "longitude": -88.974839,
      "city": "Salem",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62882,
      "latitude": 38.597,
      "longitude": -88.968203,
      "city": "Sandoval",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62883,
      "latitude": 38.176165,
      "longitude": -89.094644,
      "city": "Scheller",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62884,
      "latitude": 38.053351,
      "longitude": -89.056638,
      "city": "Sesser",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62885,
      "latitude": 38.977111,
      "longitude": -88.975507,
      "city": "Shobonier",
      "state": "IL",
      "county": "Fayette"
  },
  {
      "zipCode": 62886,
      "latitude": 38.362874,
      "longitude": -88.533608,
      "city": "Sims",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62887,
      "latitude": 38.15015,
      "longitude": -88.216053,
      "city": "Springerton",
      "state": "IL",
      "county": "White"
  },
  {
      "zipCode": 62888,
      "latitude": 38.133323,
      "longitude": -89.217309,
      "city": "Tamaroa",
      "state": "IL",
      "county": "Perry"
  },
  {
      "zipCode": 62889,
      "latitude": 38.44683,
      "longitude": -88.826247,
      "city": "Texico",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62890,
      "latitude": 37.936421,
      "longitude": -88.770913,
      "city": "Thompsonville",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62891,
      "latitude": 38.022241,
      "longitude": -89.035053,
      "city": "Valier",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62892,
      "latitude": 38.787524,
      "longitude": -89.040989,
      "city": "Vernon",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62893,
      "latitude": 38.547927,
      "longitude": -88.929247,
      "city": "Walnut Hill",
      "state": "IL",
      "county": "Marion"
  },
  {
      "zipCode": 62894,
      "latitude": 38.212153,
      "longitude": -89.043552,
      "city": "Waltonville",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62895,
      "latitude": 38.350936,
      "longitude": -88.566951,
      "city": "Wayne City",
      "state": "IL",
      "county": "Wayne"
  },
  {
      "zipCode": 62896,
      "latitude": 37.976856,
      "longitude": -88.910164,
      "city": "West Frankfort",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62897,
      "latitude": 38.087223,
      "longitude": -88.901443,
      "city": "Whittington",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62898,
      "latitude": 38.314373,
      "longitude": -89.050259,
      "city": "Woodlawn",
      "state": "IL",
      "county": "Jefferson"
  },
  {
      "zipCode": 62899,
      "latitude": 38.710776,
      "longitude": -88.664186,
      "city": "Xenia",
      "state": "IL",
      "county": "Clay"
  },
  {
      "zipCode": 62901,
      "latitude": 37.77452,
      "longitude": -89.387286,
      "city": "Carbondale",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62902,
      "latitude": 37.758646,
      "longitude": -89.415831,
      "city": "Carbondale",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62903,
      "latitude": 37.758646,
      "longitude": -89.415831,
      "city": "Carbondale",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62905,
      "latitude": 37.546479,
      "longitude": -89.357896,
      "city": "Alto Pass",
      "state": "IL",
      "county": "Union"
  },
  {
      "zipCode": 62906,
      "latitude": 37.474268,
      "longitude": -89.163042,
      "city": "Anna",
      "state": "IL",
      "county": "Union"
  },
  {
      "zipCode": 62907,
      "latitude": 37.832038,
      "longitude": -89.43068,
      "city": "Ava",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62908,
      "latitude": 37.335571,
      "longitude": -88.909205,
      "city": "Belknap",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62909,
      "latitude": 37.44725,
      "longitude": -88.876792,
      "city": "Boles",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62910,
      "latitude": 37.212897,
      "longitude": -88.605104,
      "city": "Brookport",
      "state": "IL",
      "county": "Massac"
  },
  {
      "zipCode": 62912,
      "latitude": 37.467463,
      "longitude": -89.000586,
      "city": "Buncombe",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62913,
      "latitude": 37.152775,
      "longitude": -89.325581,
      "city": "Cache",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62914,
      "latitude": 37.028797,
      "longitude": -89.192754,
      "city": "Cairo",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62915,
      "latitude": 37.784657,
      "longitude": -89.118111,
      "city": "Cambria",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62916,
      "latitude": 37.883589,
      "longitude": -89.557933,
      "city": "Campbell Hill",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62917,
      "latitude": 37.680285,
      "longitude": -88.619902,
      "city": "Carrier Mills",
      "state": "IL",
      "county": "Saline"
  },
  {
      "zipCode": 62918,
      "latitude": 37.730777,
      "longitude": -88.931157,
      "city": "Carterville",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62919,
      "latitude": 37.500757,
      "longitude": -88.238292,
      "city": "Cave In Rock",
      "state": "IL",
      "county": "Hardin"
  },
  {
      "zipCode": 62920,
      "latitude": 37.526817,
      "longitude": -89.219012,
      "city": "Cobden",
      "state": "IL",
      "county": "Union"
  },
  {
      "zipCode": 62921,
      "latitude": 37.805677,
      "longitude": -89.075999,
      "city": "Colp",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62922,
      "latitude": 37.682,
      "longitude": -88.817787,
      "city": "Creal Springs",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62923,
      "latitude": 37.365007,
      "longitude": -88.974743,
      "city": "Cypress",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62924,
      "latitude": 37.837009,
      "longitude": -89.350508,
      "city": "De Soto",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62926,
      "latitude": 37.42204,
      "longitude": -89.225572,
      "city": "Dongola",
      "state": "IL",
      "county": "Union"
  },
  {
      "zipCode": 62927,
      "latitude": 37.939205,
      "longitude": -89.231786,
      "city": "Dowell",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62928,
      "latitude": 37.333591,
      "longitude": -88.561428,
      "city": "Eddyville",
      "state": "IL",
      "county": "Pope"
  },
  {
      "zipCode": 62930,
      "latitude": 37.810121,
      "longitude": -88.452662,
      "city": "Eldorado",
      "state": "IL",
      "county": "Saline"
  },
  {
      "zipCode": 62931,
      "latitude": 37.500757,
      "longitude": -88.238292,
      "city": "Elizabethtown",
      "state": "IL",
      "county": "Hardin"
  },
  {
      "zipCode": 62932,
      "latitude": 37.894281,
      "longitude": -89.217138,
      "city": "Elkville",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62933,
      "latitude": 37.77783,
      "longitude": -89.063381,
      "city": "Energy",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62934,
      "latitude": 37.706289,
      "longitude": -88.330839,
      "city": "Equality",
      "state": "IL",
      "county": "Gallatin"
  },
  {
      "zipCode": 62935,
      "latitude": 37.823001,
      "longitude": -88.596123,
      "city": "Galatia",
      "state": "IL",
      "county": "Saline"
  },
  {
      "zipCode": 62938,
      "latitude": 37.360193,
      "longitude": -88.488571,
      "city": "Golconda",
      "state": "IL",
      "county": "Pope"
  },
  {
      "zipCode": 62939,
      "latitude": 37.534099,
      "longitude": -88.971292,
      "city": "Goreville",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62940,
      "latitude": 37.736732,
      "longitude": -89.469534,
      "city": "Gorham",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62941,
      "latitude": 37.240291,
      "longitude": -88.995404,
      "city": "Grand Chain",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62942,
      "latitude": 37.634633,
      "longitude": -89.479836,
      "city": "Grand Tower",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62943,
      "latitude": 37.379384,
      "longitude": -88.758329,
      "city": "Grantsburg",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62944,
      "latitude": 37.333591,
      "longitude": -88.561428,
      "city": "Hamletsburg",
      "state": "IL",
      "county": "Pope"
  },
  {
      "zipCode": 62946,
      "latitude": 37.720129,
      "longitude": -88.536736,
      "city": "Harrisburg",
      "state": "IL",
      "county": "Saline"
  },
  {
      "zipCode": 62947,
      "latitude": 37.333591,
      "longitude": -88.561428,
      "city": "Herod",
      "state": "IL",
      "county": "Pope"
  },
  {
      "zipCode": 62948,
      "latitude": 37.740444,
      "longitude": -88.992312,
      "city": "Herrin",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62949,
      "latitude": 37.836584,
      "longitude": -89.142415,
      "city": "Hurst",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62950,
      "latitude": 37.764248,
      "longitude": -89.584575,
      "city": "Jacob",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62951,
      "latitude": 37.802518,
      "longitude": -88.961656,
      "city": "Johnston City",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62952,
      "latitude": 37.422793,
      "longitude": -89.266574,
      "city": "Jonesboro",
      "state": "IL",
      "county": "Union"
  },
  {
      "zipCode": 62953,
      "latitude": 37.209231,
      "longitude": -88.844181,
      "city": "Joppa",
      "state": "IL",
      "county": "Massac"
  },
  {
      "zipCode": 62954,
      "latitude": 37.695153,
      "longitude": -88.260046,
      "city": "Junction",
      "state": "IL",
      "county": "Gallatin"
  },
  {
      "zipCode": 62955,
      "latitude": 37.500757,
      "longitude": -88.238292,
      "city": "Karbers Ridge",
      "state": "IL",
      "county": "Hardin"
  },
  {
      "zipCode": 62956,
      "latitude": 37.262019,
      "longitude": -89.005155,
      "city": "Karnak",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62957,
      "latitude": 37.295842,
      "longitude": -89.46653,
      "city": "Mc Clure",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62958,
      "latitude": 37.687072,
      "longitude": -89.250933,
      "city": "Makanda",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62959,
      "latitude": 37.729571,
      "longitude": -88.912852,
      "city": "Marion",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62960,
      "latitude": 37.228995,
      "longitude": -88.714524,
      "city": "Metropolis",
      "state": "IL",
      "county": "Massac"
  },
  {
      "zipCode": 62961,
      "latitude": 37.340918,
      "longitude": -89.254203,
      "city": "Millcreek",
      "state": "IL",
      "county": "Union"
  },
  {
      "zipCode": 62962,
      "latitude": 37.152775,
      "longitude": -89.325581,
      "city": "Miller City",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62963,
      "latitude": 37.178242,
      "longitude": -89.179333,
      "city": "Mound City",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62964,
      "latitude": 37.198411,
      "longitude": -89.124001,
      "city": "Mounds",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62965,
      "latitude": 37.801972,
      "longitude": -88.548695,
      "city": "Muddy",
      "state": "IL",
      "county": "Saline"
  },
  {
      "zipCode": 62966,
      "latitude": 37.782223,
      "longitude": -89.396839,
      "city": "Murphysboro",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62967,
      "latitude": 37.577998,
      "longitude": -88.745405,
      "city": "New Burnside",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62969,
      "latitude": 37.167445,
      "longitude": -89.353662,
      "city": "Olive Branch",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62970,
      "latitude": 37.210643,
      "longitude": -89.104341,
      "city": "Olmsted",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62971,
      "latitude": 37.758646,
      "longitude": -89.415831,
      "city": "Oraville",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62972,
      "latitude": 37.54725,
      "longitude": -88.770166,
      "city": "Ozark",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62973,
      "latitude": 37.311333,
      "longitude": -89.083056,
      "city": "Perks",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62974,
      "latitude": 37.804734,
      "longitude": -88.808056,
      "city": "Pittsburg",
      "state": "IL",
      "county": "Williamson"
  },
  {
      "zipCode": 62975,
      "latitude": 37.650115,
      "longitude": -89.369308,
      "city": "Pomona",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62976,
      "latitude": 37.212957,
      "longitude": -89.136274,
      "city": "Pulaski",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62977,
      "latitude": 37.822856,
      "longitude": -88.549793,
      "city": "Raleigh",
      "state": "IL",
      "county": "Saline"
  },
  {
      "zipCode": 62979,
      "latitude": 37.796978,
      "longitude": -88.243498,
      "city": "Ridgway",
      "state": "IL",
      "county": "Gallatin"
  },
  {
      "zipCode": 62982,
      "latitude": 37.417713,
      "longitude": -88.354451,
      "city": "Rosiclare",
      "state": "IL",
      "county": "Hardin"
  },
  {
      "zipCode": 62983,
      "latitude": 37.910274,
      "longitude": -89.100865,
      "city": "Royalton",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 62984,
      "latitude": 37.710189,
      "longitude": -88.183169,
      "city": "Shawneetown",
      "state": "IL",
      "county": "Gallatin"
  },
  {
      "zipCode": 62985,
      "latitude": 37.451868,
      "longitude": -88.801413,
      "city": "Simpson",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62987,
      "latitude": 37.643233,
      "longitude": -88.622821,
      "city": "Stonefort",
      "state": "IL",
      "county": "Saline"
  },
  {
      "zipCode": 62988,
      "latitude": 37.239072,
      "longitude": -89.269752,
      "city": "Tamms",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62990,
      "latitude": 37.221042,
      "longitude": -89.459863,
      "city": "Thebes",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62991,
      "latitude": 37.503628,
      "longitude": -88.847731,
      "city": "Tunnel Hill",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62992,
      "latitude": 37.28344,
      "longitude": -89.13646,
      "city": "Ullin",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62993,
      "latitude": 37.152775,
      "longitude": -89.325581,
      "city": "Unity",
      "state": "IL",
      "county": "Alexander"
  },
  {
      "zipCode": 62994,
      "latitude": 37.907239,
      "longitude": -89.410304,
      "city": "Vergennes",
      "state": "IL",
      "county": "Jackson"
  },
  {
      "zipCode": 62995,
      "latitude": 37.42417,
      "longitude": -88.898049,
      "city": "Vienna",
      "state": "IL",
      "county": "Johnson"
  },
  {
      "zipCode": 62996,
      "latitude": 37.164229,
      "longitude": -89.170924,
      "city": "Villa Ridge",
      "state": "IL",
      "county": "Pulaski"
  },
  {
      "zipCode": 62997,
      "latitude": 37.984776,
      "longitude": -89.589862,
      "city": "Willisville",
      "state": "IL",
      "county": "Perry"
  },
  {
      "zipCode": 62998,
      "latitude": 37.509618,
      "longitude": -89.424913,
      "city": "Wolf Lake",
      "state": "IL",
      "county": "Union"
  },
  {
      "zipCode": 62999,
      "latitude": 37.899054,
      "longitude": -89.052317,
      "city": "Zeigler",
      "state": "IL",
      "county": "Franklin"
  },
  {
      "zipCode": 63001,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Allenton",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63005,
      "latitude": 38.637002,
      "longitude": -90.650024,
      "city": "Chesterfield",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63006,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Chesterfield",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63010,
      "latitude": 38.341665,
      "longitude": -90.410219,
      "city": "Arnold",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63011,
      "latitude": 38.609102,
      "longitude": -90.559763,
      "city": "Ballwin",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63012,
      "latitude": 38.334232,
      "longitude": -90.453027,
      "city": "Barnhart",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63013,
      "latitude": 38.416838,
      "longitude": -91.142077,
      "city": "Beaufort",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63014,
      "latitude": 38.632726,
      "longitude": -91.321404,
      "city": "Berger",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63015,
      "latitude": 38.404667,
      "longitude": -90.780631,
      "city": "Catawissa",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63016,
      "latitude": 38.352262,
      "longitude": -90.62569,
      "city": "Cedar Hill",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63017,
      "latitude": 38.649052,
      "longitude": -90.535804,
      "city": "Chesterfield",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63019,
      "latitude": 38.225684,
      "longitude": -90.384036,
      "city": "Crystal City",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63020,
      "latitude": 38.139054,
      "longitude": -90.559045,
      "city": "De Soto",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63021,
      "latitude": 38.566653,
      "longitude": -90.535646,
      "city": "Ballwin",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63022,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Ballwin",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63023,
      "latitude": 38.26891,
      "longitude": -90.705561,
      "city": "Dittmer",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63024,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Ballwin",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63025,
      "latitude": 38.512803,
      "longitude": -90.630576,
      "city": "Eureka",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63026,
      "latitude": 38.529903,
      "longitude": -90.46737,
      "city": "Fenton",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63028,
      "latitude": 38.270941,
      "longitude": -90.413534,
      "city": "Festus",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63030,
      "latitude": 38.170261,
      "longitude": -90.718536,
      "city": "Fletcher",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63031,
      "latitude": 38.805499,
      "longitude": -90.351314,
      "city": "Florissant",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63032,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Florissant",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63033,
      "latitude": 38.796049,
      "longitude": -90.274512,
      "city": "Florissant",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63034,
      "latitude": 38.838382,
      "longitude": -90.284174,
      "city": "Florissant",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63036,
      "latitude": 37.89192,
      "longitude": -90.38,
      "city": "French Village",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63037,
      "latitude": 38.417801,
      "longitude": -91.313644,
      "city": "Gerald",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63038,
      "latitude": 38.587752,
      "longitude": -90.663876,
      "city": "Glencoe",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63039,
      "latitude": 38.474458,
      "longitude": -90.824377,
      "city": "Gray Summit",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63040,
      "latitude": 38.566718,
      "longitude": -90.630975,
      "city": "Grover",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63041,
      "latitude": 38.283477,
      "longitude": -90.761238,
      "city": "Grubville",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63042,
      "latitude": 38.7844,
      "longitude": -90.399166,
      "city": "Hazelwood",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63043,
      "latitude": 38.723951,
      "longitude": -90.447418,
      "city": "Maryland Heights",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63044,
      "latitude": 38.7514,
      "longitude": -90.411542,
      "city": "Bridgeton",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63045,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Earth City",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63047,
      "latitude": 38.194104,
      "longitude": -90.472495,
      "city": "Hematite",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63048,
      "latitude": 38.262482,
      "longitude": -90.38961,
      "city": "Herculaneum",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63049,
      "latitude": 38.456203,
      "longitude": -90.551323,
      "city": "High Ridge",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63050,
      "latitude": 38.308596,
      "longitude": -90.524641,
      "city": "Hillsboro",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63051,
      "latitude": 38.250874,
      "longitude": -90.603575,
      "city": "House Springs",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63052,
      "latitude": 38.406865,
      "longitude": -90.438051,
      "city": "Imperial",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63053,
      "latitude": 38.252726,
      "longitude": -90.514875,
      "city": "Kimmswick",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63055,
      "latitude": 38.483372,
      "longitude": -91.006293,
      "city": "Labadie",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63056,
      "latitude": 38.455547,
      "longitude": -91.232805,
      "city": "Leslie",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63057,
      "latitude": 38.341918,
      "longitude": -90.408223,
      "city": "Liguori",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63060,
      "latitude": 38.274313,
      "longitude": -90.890278,
      "city": "Lonedell",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63061,
      "latitude": 38.263227,
      "longitude": -90.831198,
      "city": "Luebbering",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63065,
      "latitude": 38.252726,
      "longitude": -90.514875,
      "city": "Mapaville",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63066,
      "latitude": 38.280943,
      "longitude": -90.652057,
      "city": "Morse Mill",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63068,
      "latitude": 38.558487,
      "longitude": -91.247283,
      "city": "New Haven",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63069,
      "latitude": 38.504462,
      "longitude": -90.788981,
      "city": "Pacific",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63070,
      "latitude": 38.293157,
      "longitude": -90.429283,
      "city": "Pevely",
      "state": "MO",
      "county": "Jefferson"
  },
  {
      "zipCode": 63071,
      "latitude": 38.062194,
      "longitude": -90.809893,
      "city": "Richwoods",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63072,
      "latitude": 38.353892,
      "longitude": -90.931777,
      "city": "Robertsville",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63073,
      "latitude": 38.527729,
      "longitude": -91.017274,
      "city": "Saint Albans",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63074,
      "latitude": 38.724651,
      "longitude": -90.389516,
      "city": "Saint Ann",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63077,
      "latitude": 38.363431,
      "longitude": -90.968672,
      "city": "Saint Clair",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63079,
      "latitude": 38.274398,
      "longitude": -91.085533,
      "city": "Stanton",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63080,
      "latitude": 38.349197,
      "longitude": -91.091755,
      "city": "Sullivan",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63084,
      "latitude": 38.36198,
      "longitude": -90.96706,
      "city": "Union",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63087,
      "latitude": 37.999797,
      "longitude": -90.440701,
      "city": "Valles Mines",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63088,
      "latitude": 38.553203,
      "longitude": -90.488896,
      "city": "Valley Park",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63089,
      "latitude": 38.453668,
      "longitude": -90.889121,
      "city": "Villa Ridge",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63090,
      "latitude": 38.523478,
      "longitude": -91.023546,
      "city": "Washington",
      "state": "MO",
      "county": "Franklin"
  },
  {
      "zipCode": 63091,
      "latitude": 38.395272,
      "longitude": -91.406851,
      "city": "Rosebud",
      "state": "MO",
      "county": "Gasconade"
  },
  {
      "zipCode": 63099,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Fenton",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63101,
      "latitude": 38.631501,
      "longitude": -90.19231,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63102,
      "latitude": 38.6336,
      "longitude": -90.191221,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63103,
      "latitude": 38.629901,
      "longitude": -90.216411,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63104,
      "latitude": 38.611401,
      "longitude": -90.214461,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63105,
      "latitude": 38.645901,
      "longitude": -90.326365,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63106,
      "latitude": 38.644501,
      "longitude": -90.208361,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63107,
      "latitude": 38.66535,
      "longitude": -90.212561,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63108,
      "latitude": 38.644801,
      "longitude": -90.251962,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63109,
      "latitude": 38.581652,
      "longitude": -90.294714,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63110,
      "latitude": 38.625401,
      "longitude": -90.268132,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63111,
      "latitude": 38.559551,
      "longitude": -90.249744,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63112,
      "latitude": 38.663851,
      "longitude": -90.281813,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63113,
      "latitude": 38.630051,
      "longitude": -90.253212,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63114,
      "latitude": 38.702301,
      "longitude": -90.364365,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63115,
      "latitude": 38.6772,
      "longitude": -90.241962,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63116,
      "latitude": 38.581401,
      "longitude": -90.268563,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63117,
      "latitude": 38.629452,
      "longitude": -90.334215,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63118,
      "latitude": 38.593751,
      "longitude": -90.222012,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63119,
      "latitude": 38.589302,
      "longitude": -90.348116,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63120,
      "latitude": 38.6901,
      "longitude": -90.259212,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63121,
      "latitude": 38.70715,
      "longitude": -90.305463,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63122,
      "latitude": 38.578052,
      "longitude": -90.42563,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63123,
      "latitude": 38.547552,
      "longitude": -90.324115,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63124,
      "latitude": 38.637201,
      "longitude": -90.377566,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63125,
      "latitude": 38.522152,
      "longitude": -90.302115,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63126,
      "latitude": 38.549452,
      "longitude": -90.381069,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63127,
      "latitude": 38.535503,
      "longitude": -90.407018,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63128,
      "latitude": 38.491503,
      "longitude": -90.377167,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63129,
      "latitude": 38.456553,
      "longitude": -90.328212,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63130,
      "latitude": 38.666851,
      "longitude": -90.322514,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63131,
      "latitude": 38.617102,
      "longitude": -90.450368,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63132,
      "latitude": 38.674601,
      "longitude": -90.374716,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63133,
      "latitude": 38.678651,
      "longitude": -90.305113,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63134,
      "latitude": 38.74355,
      "longitude": -90.340964,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63135,
      "latitude": 38.74965,
      "longitude": -90.301213,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63136,
      "latitude": 38.7196,
      "longitude": -90.269962,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63137,
      "latitude": 38.746799,
      "longitude": -90.213103,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63138,
      "latitude": 38.803299,
      "longitude": -90.20651,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63139,
      "latitude": 38.613501,
      "longitude": -90.291814,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63140,
      "latitude": 38.73745,
      "longitude": -90.326464,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63141,
      "latitude": 38.656502,
      "longitude": -90.454219,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63143,
      "latitude": 38.611099,
      "longitude": -90.322475,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63144,
      "latitude": 38.618152,
      "longitude": -90.348865,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63145,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63146,
      "latitude": 38.703251,
      "longitude": -90.461818,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63147,
      "latitude": 38.6774,
      "longitude": -90.21968,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63150,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63151,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63155,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63156,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63157,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63158,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63160,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63163,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63164,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63166,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63167,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63169,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63171,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63177,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63178,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63179,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63180,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63182,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63188,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63190,
      "latitude": "",
      "longitude": "",
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63195,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63196,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63197,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63198,
      "latitude": 38.638318,
      "longitude": -90.427118,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis"
  },
  {
      "zipCode": 63199,
      "latitude": 38.6531,
      "longitude": -90.243462,
      "city": "Saint Louis",
      "state": "MO",
      "county": "Saint Louis City"
  },
  {
      "zipCode": 63301,
      "latitude": 38.754788,
      "longitude": -90.613131,
      "city": "Saint Charles",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63302,
      "latitude": 38.581299,
      "longitude": -90.872987,
      "city": "Saint Charles",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63303,
      "latitude": 38.759034,
      "longitude": -90.595151,
      "city": "Saint Charles",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63304,
      "latitude": 38.731527,
      "longitude": -90.704616,
      "city": "Saint Charles",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63330,
      "latitude": 39.253624,
      "longitude": -90.814756,
      "city": "Annada",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63332,
      "latitude": 38.619089,
      "longitude": -90.880321,
      "city": "Augusta",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63333,
      "latitude": 39.03433,
      "longitude": -91.33188,
      "city": "Bellflower",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 63334,
      "latitude": 39.316369,
      "longitude": -91.174859,
      "city": "Bowling Green",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63336,
      "latitude": 39.331503,
      "longitude": -90.950068,
      "city": "Clarksville",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63338,
      "latitude": 38.750949,
      "longitude": -90.536784,
      "city": "Cottleville",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63339,
      "latitude": 39.333342,
      "longitude": -91.197593,
      "city": "Curryville",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63341,
      "latitude": 38.663411,
      "longitude": -90.841195,
      "city": "Defiance",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63342,
      "latitude": 38.605967,
      "longitude": -90.996216,
      "city": "Dutzow",
      "state": "MO",
      "county": "Warren"
  },
  {
      "zipCode": 63343,
      "latitude": 39.121393,
      "longitude": -90.848269,
      "city": "Elsberry",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63344,
      "latitude": 39.280278,
      "longitude": -90.980854,
      "city": "Eolia",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63345,
      "latitude": 39.270895,
      "longitude": -91.557429,
      "city": "Farber",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 63346,
      "latitude": 38.750949,
      "longitude": -90.536784,
      "city": "Flinthill",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63347,
      "latitude": 39.081579,
      "longitude": -90.795683,
      "city": "Foley",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63348,
      "latitude": 38.777039,
      "longitude": -90.916101,
      "city": "Foristell",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63349,
      "latitude": 38.980781,
      "longitude": -91.127211,
      "city": "Hawk Point",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63350,
      "latitude": 38.909013,
      "longitude": -91.372292,
      "city": "High Hill",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 63351,
      "latitude": 38.896897,
      "longitude": -91.326207,
      "city": "Jonesburg",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 63352,
      "latitude": 39.243096,
      "longitude": -91.66077,
      "city": "Laddonia",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 63353,
      "latitude": 39.440073,
      "longitude": -91.088401,
      "city": "Louisiana",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63357,
      "latitude": 38.651157,
      "longitude": -91.151576,
      "city": "Marthasville",
      "state": "MO",
      "county": "Warren"
  },
  {
      "zipCode": 63359,
      "latitude": 39.104548,
      "longitude": -91.382863,
      "city": "Middletown",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 63361,
      "latitude": 38.94604,
      "longitude": -91.472754,
      "city": "Montgomery City",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 63362,
      "latitude": 38.948248,
      "longitude": -90.913818,
      "city": "Moscow Mills",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63363,
      "latitude": 38.892612,
      "longitude": -91.453372,
      "city": "New Florence",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 63365,
      "latitude": 38.716287,
      "longitude": -90.875127,
      "city": "New Melle",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63366,
      "latitude": 38.823944,
      "longitude": -90.742745,
      "city": "O Fallon",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63367,
      "latitude": 38.791341,
      "longitude": -90.74284,
      "city": "Lake Saint Louis",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63369,
      "latitude": 38.94773,
      "longitude": -90.8106,
      "city": "Old Monroe",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63370,
      "latitude": 39.077584,
      "longitude": -91.231719,
      "city": "Olney",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63373,
      "latitude": 38.92592,
      "longitude": -90.38632,
      "city": "Portage Des Sioux",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63376,
      "latitude": 38.798819,
      "longitude": -90.623673,
      "city": "Saint Peters",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63377,
      "latitude": 39.076463,
      "longitude": -90.996268,
      "city": "Silex",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63378,
      "latitude": 38.770187,
      "longitude": -91.188586,
      "city": "Treloar",
      "state": "MO",
      "county": "Warren"
  },
  {
      "zipCode": 63379,
      "latitude": 39.016299,
      "longitude": -90.940268,
      "city": "Troy",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63381,
      "latitude": 39.022137,
      "longitude": -90.996785,
      "city": "Truxton",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63382,
      "latitude": 39.251403,
      "longitude": -91.530807,
      "city": "Vandalia",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 63383,
      "latitude": 38.808454,
      "longitude": -91.2166,
      "city": "Warrenton",
      "state": "MO",
      "county": "Warren"
  },
  {
      "zipCode": 63384,
      "latitude": 39.073061,
      "longitude": -91.533545,
      "city": "Wellsville",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 63385,
      "latitude": 38.79067,
      "longitude": -90.852228,
      "city": "Wentzville",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63386,
      "latitude": 38.83275,
      "longitude": -90.403416,
      "city": "West Alton",
      "state": "MO",
      "county": "Saint Charles"
  },
  {
      "zipCode": 63387,
      "latitude": 39.185508,
      "longitude": -91.022733,
      "city": "Whiteside",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63388,
      "latitude": 38.887421,
      "longitude": -91.768887,
      "city": "Williamsburg",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 63389,
      "latitude": 39.058354,
      "longitude": -90.850945,
      "city": "Winfield",
      "state": "MO",
      "county": "Lincoln"
  },
  {
      "zipCode": 63390,
      "latitude": 38.797733,
      "longitude": -91.048471,
      "city": "Wright City",
      "state": "MO",
      "county": "Warren"
  },
  {
      "zipCode": 63401,
      "latitude": 39.736986,
      "longitude": -91.45683,
      "city": "Hannibal",
      "state": "MO",
      "county": "Marion"
  },
  {
      "zipCode": 63430,
      "latitude": 40.357643,
      "longitude": -91.45886,
      "city": "Alexandria",
      "state": "MO",
      "county": "Clark"
  },
  {
      "zipCode": 63431,
      "latitude": 39.742779,
      "longitude": -92.327977,
      "city": "Anabel",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63432,
      "latitude": 40.453333,
      "longitude": -92.1474,
      "city": "Arbela",
      "state": "MO",
      "county": "Scotland"
  },
  {
      "zipCode": 63433,
      "latitude": 39.56103,
      "longitude": -91.184425,
      "city": "Ashburn",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63434,
      "latitude": 39.779288,
      "longitude": -92.069741,
      "city": "Bethel",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63435,
      "latitude": 40.107512,
      "longitude": -91.705811,
      "city": "Canton",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63436,
      "latitude": 39.508451,
      "longitude": -91.53793,
      "city": "Center",
      "state": "MO",
      "county": "Ralls"
  },
  {
      "zipCode": 63437,
      "latitude": 39.74294,
      "longitude": -92.26344,
      "city": "Clarence",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63438,
      "latitude": 39.975702,
      "longitude": -91.665056,
      "city": "Durham",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63439,
      "latitude": 39.779288,
      "longitude": -92.069741,
      "city": "Emden",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63440,
      "latitude": 40.008146,
      "longitude": -91.710266,
      "city": "Ewing",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63441,
      "latitude": 39.466564,
      "longitude": -91.332846,
      "city": "Frankford",
      "state": "MO",
      "county": "Pike"
  },
  {
      "zipCode": 63442,
      "latitude": 40.453333,
      "longitude": -92.1474,
      "city": "Granger",
      "state": "MO",
      "county": "Scotland"
  },
  {
      "zipCode": 63443,
      "latitude": 39.667948,
      "longitude": -91.858547,
      "city": "Hunnewell",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63445,
      "latitude": 40.41361,
      "longitude": -91.659318,
      "city": "Kahoka",
      "state": "MO",
      "county": "Clark"
  },
  {
      "zipCode": 63446,
      "latitude": 40.126767,
      "longitude": -92.147994,
      "city": "Knox City",
      "state": "MO",
      "county": "Knox"
  },
  {
      "zipCode": 63447,
      "latitude": 40.114981,
      "longitude": -91.915396,
      "city": "La Belle",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63448,
      "latitude": 40.059903,
      "longitude": -91.705042,
      "city": "La Grange",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63450,
      "latitude": 39.779288,
      "longitude": -92.069741,
      "city": "Lentner",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63451,
      "latitude": 39.90938,
      "longitude": -92.206724,
      "city": "Leonard",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63452,
      "latitude": 40.084114,
      "longitude": -91.813451,
      "city": "Lewistown",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63453,
      "latitude": 40.431201,
      "longitude": -91.685103,
      "city": "Luray",
      "state": "MO",
      "county": "Clark"
  },
  {
      "zipCode": 63454,
      "latitude": 39.903389,
      "longitude": -91.654578,
      "city": "Maywood",
      "state": "MO",
      "county": "Marion"
  },
  {
      "zipCode": 63456,
      "latitude": 39.624018,
      "longitude": -91.762318,
      "city": "Monroe City",
      "state": "MO",
      "county": "Monroe"
  },
  {
      "zipCode": 63457,
      "latitude": 40.090883,
      "longitude": -91.68774,
      "city": "Monticello",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63458,
      "latitude": 40.126767,
      "longitude": -92.147994,
      "city": "Newark",
      "state": "MO",
      "county": "Knox"
  },
  {
      "zipCode": 63459,
      "latitude": 39.582974,
      "longitude": -91.382389,
      "city": "New London",
      "state": "MO",
      "county": "Ralls"
  },
  {
      "zipCode": 63460,
      "latitude": 40.126767,
      "longitude": -92.147994,
      "city": "Novelty",
      "state": "MO",
      "county": "Knox"
  },
  {
      "zipCode": 63461,
      "latitude": 39.794531,
      "longitude": -91.609424,
      "city": "Palmyra",
      "state": "MO",
      "county": "Marion"
  },
  {
      "zipCode": 63462,
      "latitude": 39.422833,
      "longitude": -91.623364,
      "city": "Perry",
      "state": "MO",
      "county": "Ralls"
  },
  {
      "zipCode": 63463,
      "latitude": 39.82473,
      "longitude": -91.738022,
      "city": "Philadelphia",
      "state": "MO",
      "county": "Marion"
  },
  {
      "zipCode": 63464,
      "latitude": 40.126767,
      "longitude": -92.147994,
      "city": "Plevna",
      "state": "MO",
      "county": "Knox"
  },
  {
      "zipCode": 63465,
      "latitude": 40.45696,
      "longitude": -91.695843,
      "city": "Revere",
      "state": "MO",
      "county": "Clark"
  },
  {
      "zipCode": 63466,
      "latitude": 40.431201,
      "longitude": -91.685103,
      "city": "Saint Patrick",
      "state": "MO",
      "county": "Clark"
  },
  {
      "zipCode": 63467,
      "latitude": 39.650045,
      "longitude": -91.270502,
      "city": "Saverton",
      "state": "MO",
      "county": "Ralls"
  },
  {
      "zipCode": 63468,
      "latitude": 39.739181,
      "longitude": -92.067449,
      "city": "Shelbina",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63469,
      "latitude": 39.778816,
      "longitude": -92.075059,
      "city": "Shelbyville",
      "state": "MO",
      "county": "Shelby"
  },
  {
      "zipCode": 63471,
      "latitude": 39.913861,
      "longitude": -91.541024,
      "city": "Taylor",
      "state": "MO",
      "county": "Marion"
  },
  {
      "zipCode": 63472,
      "latitude": 40.372995,
      "longitude": -91.61903,
      "city": "Wayland",
      "state": "MO",
      "county": "Clark"
  },
  {
      "zipCode": 63473,
      "latitude": 40.101236,
      "longitude": -91.694121,
      "city": "Williamstown",
      "state": "MO",
      "county": "Lewis"
  },
  {
      "zipCode": 63474,
      "latitude": 40.392193,
      "longitude": -91.925945,
      "city": "Wyaconda",
      "state": "MO",
      "county": "Clark"
  },
  {
      "zipCode": 63501,
      "latitude": 40.165717,
      "longitude": -92.609514,
      "city": "Kirksville",
      "state": "MO",
      "county": "Adair"
  },
  {
      "zipCode": 63530,
      "latitude": 39.896469,
      "longitude": -92.46348,
      "city": "Atlanta",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63531,
      "latitude": 40.149624,
      "longitude": -92.303293,
      "city": "Baring",
      "state": "MO",
      "county": "Knox"
  },
  {
      "zipCode": 63532,
      "latitude": 39.752572,
      "longitude": -92.576944,
      "city": "Bevier",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63533,
      "latitude": 40.149263,
      "longitude": -92.378005,
      "city": "Brashear",
      "state": "MO",
      "county": "Adair"
  },
  {
      "zipCode": 63534,
      "latitude": 39.740612,
      "longitude": -92.644013,
      "city": "Callao",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63535,
      "latitude": 40.47037,
      "longitude": -92.535566,
      "city": "Coatsville",
      "state": "MO",
      "county": "Schuyler"
  },
  {
      "zipCode": 63536,
      "latitude": 40.47037,
      "longitude": -92.535566,
      "city": "Downing",
      "state": "MO",
      "county": "Schuyler"
  },
  {
      "zipCode": 63537,
      "latitude": 40.161431,
      "longitude": -92.233699,
      "city": "Edina",
      "state": "MO",
      "county": "Knox"
  },
  {
      "zipCode": 63538,
      "latitude": 39.96045,
      "longitude": -92.684957,
      "city": "Elmer",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63539,
      "latitude": 39.934265,
      "longitude": -92.768069,
      "city": "Ethel",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63540,
      "latitude": 40.191837,
      "longitude": -92.600838,
      "city": "Gibbs",
      "state": "MO",
      "county": "Adair"
  },
  {
      "zipCode": 63541,
      "latitude": 40.47037,
      "longitude": -92.535566,
      "city": "Glenwood",
      "state": "MO",
      "county": "Schuyler"
  },
  {
      "zipCode": 63543,
      "latitude": 40.453333,
      "longitude": -92.1474,
      "city": "Gorin",
      "state": "MO",
      "county": "Scotland"
  },
  {
      "zipCode": 63544,
      "latitude": 40.245907,
      "longitude": -92.872023,
      "city": "Green Castle",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 63545,
      "latitude": 40.218108,
      "longitude": -92.945787,
      "city": "Green City",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 63546,
      "latitude": 40.271035,
      "longitude": -92.438547,
      "city": "Greentop",
      "state": "MO",
      "county": "Adair"
  },
  {
      "zipCode": 63547,
      "latitude": 40.155779,
      "longitude": -92.23384,
      "city": "Hurdland",
      "state": "MO",
      "county": "Knox"
  },
  {
      "zipCode": 63548,
      "latitude": 40.47037,
      "longitude": -92.535566,
      "city": "Lancaster",
      "state": "MO",
      "county": "Schuyler"
  },
  {
      "zipCode": 63549,
      "latitude": 39.988763,
      "longitude": -92.531207,
      "city": "La Plata",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63551,
      "latitude": 40.465883,
      "longitude": -93.019815,
      "city": "Livonia",
      "state": "MO",
      "county": "Putnam"
  },
  {
      "zipCode": 63552,
      "latitude": 39.773979,
      "longitude": -92.451742,
      "city": "Macon",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63555,
      "latitude": 40.460129,
      "longitude": -92.198203,
      "city": "Memphis",
      "state": "MO",
      "county": "Scotland"
  },
  {
      "zipCode": 63556,
      "latitude": 40.166282,
      "longitude": -93.120132,
      "city": "Milan",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 63557,
      "latitude": 39.897218,
      "longitude": -92.983838,
      "city": "New Boston",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 63558,
      "latitude": 39.779466,
      "longitude": -92.757639,
      "city": "New Cambria",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 63559,
      "latitude": 40.254906,
      "longitude": -92.705779,
      "city": "Novinger",
      "state": "MO",
      "county": "Adair"
  },
  {
      "zipCode": 63560,
      "latitude": 40.358563,
      "longitude": -93.080651,
      "city": "Pollock",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 63561,
      "latitude": 40.409719,
      "longitude": -92.569221,
      "city": "Queen City",
      "state": "MO",
      "county": "Schuyler"
  },
  {
      "zipCode": 63563,
      "latitude": 40.306854,
      "longitude": -92.085363,
      "city": "Rutledge",
      "state": "MO",
      "county": "Scotland"
  },
  {
      "zipCode": 63565,
      "latitude": 40.50952,
      "longitude": -93.012329,
      "city": "Unionville",
      "state": "MO",
      "county": "Putnam"
  },
  {
      "zipCode": 63566,
      "latitude": 40.209765,
      "longitude": -93.111314,
      "city": "Winigan",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 63567,
      "latitude": 40.465883,
      "longitude": -93.019815,
      "city": "Worthington",
      "state": "MO",
      "county": "Putnam"
  },
  {
      "zipCode": 63601,
      "latitude": 37.849808,
      "longitude": -90.488486,
      "city": "Park Hills",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63620,
      "latitude": 37.4913,
      "longitude": -90.6646,
      "city": "Annapolis",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63621,
      "latitude": 37.60362,
      "longitude": -90.634928,
      "city": "Arcadia",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63622,
      "latitude": 37.789859,
      "longitude": -90.846322,
      "city": "Belgrade",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63623,
      "latitude": 37.665689,
      "longitude": -90.898189,
      "city": "Belleview",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63624,
      "latitude": 37.753919,
      "longitude": -90.59839,
      "city": "Bismarck",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63625,
      "latitude": 37.327153,
      "longitude": -91.024337,
      "city": "Black",
      "state": "MO",
      "county": "Reynolds"
  },
  {
      "zipCode": 63626,
      "latitude": 38.054424,
      "longitude": -90.627911,
      "city": "Blackwell",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63627,
      "latitude": 38.023217,
      "longitude": -90.252806,
      "city": "Bloomsdale",
      "state": "MO",
      "county": "Sainte Genevieve"
  },
  {
      "zipCode": 63628,
      "latitude": 37.879195,
      "longitude": -90.487988,
      "city": "Bonne Terre",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63629,
      "latitude": 37.45584,
      "longitude": -91.207476,
      "city": "Bunker",
      "state": "MO",
      "county": "Reynolds"
  },
  {
      "zipCode": 63630,
      "latitude": 38.018265,
      "longitude": -90.728895,
      "city": "Cadet",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63631,
      "latitude": 37.77726,
      "longitude": -90.809566,
      "city": "Caledonia",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63632,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Cascade",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63633,
      "latitude": 37.240134,
      "longitude": -90.966089,
      "city": "Centerville",
      "state": "MO",
      "county": "Reynolds"
  },
  {
      "zipCode": 63636,
      "latitude": 37.505462,
      "longitude": -90.845047,
      "city": "Des Arc",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63637,
      "latitude": 37.718886,
      "longitude": -90.529447,
      "city": "Doe Run",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63638,
      "latitude": 37.232968,
      "longitude": -90.969767,
      "city": "Ellington",
      "state": "MO",
      "county": "Reynolds"
  },
  {
      "zipCode": 63640,
      "latitude": 37.785545,
      "longitude": -90.443298,
      "city": "Farmington",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63645,
      "latitude": 37.490565,
      "longitude": -90.336244,
      "city": "Fredericktown",
      "state": "MO",
      "county": "Madison"
  },
  {
      "zipCode": 63646,
      "latitude": 37.505462,
      "longitude": -90.845047,
      "city": "Glover",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63648,
      "latitude": 37.82135,
      "longitude": -90.692287,
      "city": "Irondale",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63650,
      "latitude": 37.616808,
      "longitude": -90.598469,
      "city": "Ironton",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63651,
      "latitude": 37.675442,
      "longitude": -90.367713,
      "city": "Knob Lick",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63653,
      "latitude": 37.857721,
      "longitude": -90.587889,
      "city": "Leadwood",
      "state": "MO",
      "county": "Saint Francois"
  },
  {
      "zipCode": 63654,
      "latitude": 37.327153,
      "longitude": -91.024337,
      "city": "Lesterville",
      "state": "MO",
      "county": "Reynolds"
  },
  {
      "zipCode": 63655,
      "latitude": 37.413591,
      "longitude": -90.236118,
      "city": "Marquand",
      "state": "MO",
      "county": "Madison"
  },
  {
      "zipCode": 63656,
      "latitude": 37.505462,
      "longitude": -90.845047,
      "city": "Middle Brook",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63660,
      "latitude": 37.936112,
      "longitude": -90.742423,
      "city": "Mineral Point",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63661,
      "latitude": 37.89872,
      "longitude": -90.191972,
      "city": "New Offenburg",
      "state": "MO",
      "county": "Sainte Genevieve"
  },
  {
      "zipCode": 63662,
      "latitude": 37.319652,
      "longitude": -90.04218,
      "city": "Patton",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63663,
      "latitude": 37.617092,
      "longitude": -90.612259,
      "city": "Pilot Knob",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63664,
      "latitude": 37.98535,
      "longitude": -90.820073,
      "city": "Potosi",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63665,
      "latitude": 37.327153,
      "longitude": -91.024337,
      "city": "Redford",
      "state": "MO",
      "county": "Reynolds"
  },
  {
      "zipCode": 63666,
      "latitude": 37.327153,
      "longitude": -91.024337,
      "city": "Reynolds",
      "state": "MO",
      "county": "Reynolds"
  },
  {
      "zipCode": 63670,
      "latitude": 37.865528,
      "longitude": -90.175244,
      "city": "Sainte Genevieve",
      "state": "MO",
      "county": "Sainte Genevieve"
  },
  {
      "zipCode": 63673,
      "latitude": 37.819949,
      "longitude": -90.016922,
      "city": "Saint Mary",
      "state": "MO",
      "county": "Sainte Genevieve"
  },
  {
      "zipCode": 63674,
      "latitude": 37.970441,
      "longitude": -90.870001,
      "city": "Tiff",
      "state": "MO",
      "county": "Washington"
  },
  {
      "zipCode": 63675,
      "latitude": 37.505462,
      "longitude": -90.845047,
      "city": "Vulcan",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 63701,
      "latitude": 37.341398,
      "longitude": -89.611016,
      "city": "Cape Girardeau",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63702,
      "latitude": 37.350624,
      "longitude": -89.509405,
      "city": "Cape Girardeau",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63703,
      "latitude": 37.284366,
      "longitude": -89.571485,
      "city": "Cape Girardeau",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63705,
      "latitude": 37.366166,
      "longitude": -89.643941,
      "city": "Cape Girardeau",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63730,
      "latitude": 37.001191,
      "longitude": -90.033351,
      "city": "Advance",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63732,
      "latitude": 37.627908,
      "longitude": -89.574503,
      "city": "Altenburg",
      "state": "MO",
      "county": "Perry"
  },
  {
      "zipCode": 63735,
      "latitude": 36.992886,
      "longitude": -89.789402,
      "city": "Bell City",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63736,
      "latitude": 37.025936,
      "longitude": -89.538588,
      "city": "Benton",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63737,
      "latitude": 37.663253,
      "longitude": -89.652912,
      "city": "Brazeau",
      "state": "MO",
      "county": "Perry"
  },
  {
      "zipCode": 63738,
      "latitude": 37.082824,
      "longitude": -89.952257,
      "city": "Brownwood",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63739,
      "latitude": 37.353526,
      "longitude": -89.793056,
      "city": "Burfordville",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63740,
      "latitude": 37.151512,
      "longitude": -89.610468,
      "city": "Chaffee",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63742,
      "latitude": 37.156982,
      "longitude": -89.448389,
      "city": "Commerce",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63743,
      "latitude": 37.515722,
      "longitude": -89.826775,
      "city": "Daisy",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63744,
      "latitude": 37.197279,
      "longitude": -89.739429,
      "city": "Delta",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63745,
      "latitude": 37.24237,
      "longitude": -89.69768,
      "city": "Dutchtown",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63746,
      "latitude": 37.734776,
      "longitude": -89.811616,
      "city": "Farrar",
      "state": "MO",
      "county": "Perry"
  },
  {
      "zipCode": 63747,
      "latitude": 37.516792,
      "longitude": -89.815114,
      "city": "Friedheim",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63748,
      "latitude": 37.66,
      "longitude": -89.616248,
      "city": "Frohna",
      "state": "MO",
      "county": "Perry"
  },
  {
      "zipCode": 63750,
      "latitude": 37.319652,
      "longitude": -90.04218,
      "city": "Gipsy",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63751,
      "latitude": 37.319652,
      "longitude": -90.04218,
      "city": "Glenallen",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63752,
      "latitude": 37.30925,
      "longitude": -89.69889,
      "city": "Gordonville",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63753,
      "latitude": 37.319652,
      "longitude": -90.04218,
      "city": "Grassy",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63755,
      "latitude": 37.434218,
      "longitude": -89.634617,
      "city": "Jackson",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63758,
      "latitude": 37.185801,
      "longitude": -89.557524,
      "city": "Kelso",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63760,
      "latitude": 37.319652,
      "longitude": -90.04218,
      "city": "Leopold",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63763,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Mc Gee",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63764,
      "latitude": 37.306063,
      "longitude": -89.982306,
      "city": "Marble Hill",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63766,
      "latitude": 37.437333,
      "longitude": -89.810954,
      "city": "Millersville",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63767,
      "latitude": 37.041104,
      "longitude": -89.607642,
      "city": "Morley",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63769,
      "latitude": 37.514047,
      "longitude": -89.752719,
      "city": "Oak Ridge",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63770,
      "latitude": 37.589021,
      "longitude": -89.679373,
      "city": "Old Appleton",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63771,
      "latitude": 37.056528,
      "longitude": -89.670685,
      "city": "Oran",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63772,
      "latitude": 37.04613,
      "longitude": -89.779248,
      "city": "Painton",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63774,
      "latitude": 37.056383,
      "longitude": -89.549853,
      "city": "Perkins",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63775,
      "latitude": 37.727156,
      "longitude": -89.862749,
      "city": "Perryville",
      "state": "MO",
      "county": "Perry"
  },
  {
      "zipCode": 63776,
      "latitude": 37.734776,
      "longitude": -89.811616,
      "city": "Mc Bride",
      "state": "MO",
      "county": "Perry"
  },
  {
      "zipCode": 63779,
      "latitude": 37.500656,
      "longitude": -89.639595,
      "city": "Pocahontas",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63780,
      "latitude": 37.060767,
      "longitude": -89.55001,
      "city": "Scott City",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63781,
      "latitude": 37.319652,
      "longitude": -90.04218,
      "city": "Sedgewickville",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63782,
      "latitude": 37.319652,
      "longitude": -90.04218,
      "city": "Sturdivant",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63783,
      "latitude": 37.628496,
      "longitude": -89.688532,
      "city": "Uniontown",
      "state": "MO",
      "county": "Perry"
  },
  {
      "zipCode": 63784,
      "latitude": 36.991187,
      "longitude": -89.688469,
      "city": "Vanduser",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63785,
      "latitude": 37.278096,
      "longitude": -89.806091,
      "city": "Whitewater",
      "state": "MO",
      "county": "Cape Girardeau"
  },
  {
      "zipCode": 63787,
      "latitude": 37.119918,
      "longitude": -90.088925,
      "city": "Zalma",
      "state": "MO",
      "county": "Bollinger"
  },
  {
      "zipCode": 63801,
      "latitude": 37.027479,
      "longitude": -89.563988,
      "city": "Sikeston",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63820,
      "latitude": 36.822596,
      "longitude": -89.346154,
      "city": "Anniston",
      "state": "MO",
      "county": "Mississippi"
  },
  {
      "zipCode": 63821,
      "latitude": 36.064878,
      "longitude": -90.229246,
      "city": "Arbyrd",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63822,
      "latitude": 36.67072,
      "longitude": -89.996555,
      "city": "Bernie",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63823,
      "latitude": 36.879394,
      "longitude": -89.369007,
      "city": "Bertrand",
      "state": "MO",
      "county": "Mississippi"
  },
  {
      "zipCode": 63824,
      "latitude": 37.004204,
      "longitude": -89.526567,
      "city": "Blodgett",
      "state": "MO",
      "county": "Scott"
  },
  {
      "zipCode": 63825,
      "latitude": 36.917174,
      "longitude": -89.920641,
      "city": "Bloomfield",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63826,
      "latitude": 36.178122,
      "longitude": -89.671646,
      "city": "Braggadocio",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63827,
      "latitude": 36.269702,
      "longitude": -89.872827,
      "city": "Bragg City",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63828,
      "latitude": 36.685031,
      "longitude": -89.748273,
      "city": "Canalou",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63829,
      "latitude": 36.066896,
      "longitude": -90.257353,
      "city": "Cardwell",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63830,
      "latitude": 36.145823,
      "longitude": -89.699719,
      "city": "Caruthersville",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63833,
      "latitude": 36.62761,
      "longitude": -89.74355,
      "city": "Catron",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63834,
      "latitude": 36.885612,
      "longitude": -89.296639,
      "city": "Charleston",
      "state": "MO",
      "county": "Mississippi"
  },
  {
      "zipCode": 63837,
      "latitude": 36.297884,
      "longitude": -90.058362,
      "city": "Clarkton",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63838,
      "latitude": 36.603844,
      "longitude": -89.650005,
      "city": "Conran",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63839,
      "latitude": 36.047566,
      "longitude": -89.809116,
      "city": "Cooter",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63840,
      "latitude": 36.213318,
      "longitude": -89.748102,
      "city": "Deering",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63841,
      "latitude": 36.882467,
      "longitude": -89.98146,
      "city": "Dexter",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63845,
      "latitude": 36.758552,
      "longitude": -89.334246,
      "city": "East Prairie",
      "state": "MO",
      "county": "Mississippi"
  },
  {
      "zipCode": 63846,
      "latitude": 36.809172,
      "longitude": -89.815472,
      "city": "Essex",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63847,
      "latitude": 36.441318,
      "longitude": -90.029294,
      "city": "Gibson",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63848,
      "latitude": 36.489808,
      "longitude": -89.774875,
      "city": "Gideon",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63849,
      "latitude": 36.153706,
      "longitude": -89.954388,
      "city": "Gobler",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63850,
      "latitude": 36.828337,
      "longitude": -89.756518,
      "city": "Grayridge",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63851,
      "latitude": 36.257216,
      "longitude": -89.798519,
      "city": "Hayti",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63852,
      "latitude": 36.383876,
      "longitude": -89.998681,
      "city": "Holcomb",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63853,
      "latitude": 36.092984,
      "longitude": -89.831121,
      "city": "Holland",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63855,
      "latitude": 36.052985,
      "longitude": -90.077133,
      "city": "Hornersville",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63857,
      "latitude": 36.252946,
      "longitude": -90.127039,
      "city": "Kennett",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63860,
      "latitude": 36.672641,
      "longitude": -89.563896,
      "city": "Kewanee",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63862,
      "latitude": 36.588189,
      "longitude": -89.700138,
      "city": "Lilbourn",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63863,
      "latitude": 36.377184,
      "longitude": -90.057096,
      "city": "Malden",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63866,
      "latitude": 36.518643,
      "longitude": -89.646396,
      "city": "Marston",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63867,
      "latitude": 36.715404,
      "longitude": -89.628723,
      "city": "Matthews",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63868,
      "latitude": 36.850353,
      "longitude": -89.68472,
      "city": "Morehouse",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63869,
      "latitude": 36.679465,
      "longitude": -89.657159,
      "city": "New Madrid",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63870,
      "latitude": 36.568845,
      "longitude": -89.85627,
      "city": "Parma",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63871,
      "latitude": 36.267825,
      "longitude": -89.822514,
      "city": "Pascola",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63873,
      "latitude": 36.475274,
      "longitude": -89.748064,
      "city": "Portageville",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63874,
      "latitude": 36.678325,
      "longitude": -89.770008,
      "city": "Risco",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63875,
      "latitude": 36.313319,
      "longitude": -90.165758,
      "city": "Rives",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63876,
      "latitude": 36.127573,
      "longitude": -90.18349,
      "city": "Senath",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63877,
      "latitude": 36.177001,
      "longitude": -89.860675,
      "city": "Steele",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63878,
      "latitude": 36.603844,
      "longitude": -89.650005,
      "city": "Tallapoosa",
      "state": "MO",
      "county": "New Madrid"
  },
  {
      "zipCode": 63879,
      "latitude": 36.347807,
      "longitude": -89.818059,
      "city": "Wardell",
      "state": "MO",
      "county": "Pemiscot"
  },
  {
      "zipCode": 63880,
      "latitude": 36.313319,
      "longitude": -90.165758,
      "city": "Whiteoak",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63881,
      "latitude": 36.779519,
      "longitude": -89.206329,
      "city": "Wolf Island",
      "state": "MO",
      "county": "Mississippi"
  },
  {
      "zipCode": 63882,
      "latitude": 36.93603,
      "longitude": -89.216333,
      "city": "Wyatt",
      "state": "MO",
      "county": "Mississippi"
  },
  {
      "zipCode": 63901,
      "latitude": 36.778764,
      "longitude": -90.440688,
      "city": "Poplar Bluff",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63902,
      "latitude": 36.712451,
      "longitude": -90.407013,
      "city": "Poplar Bluff",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63931,
      "latitude": 36.66101,
      "longitude": -90.850785,
      "city": "Briar",
      "state": "MO",
      "county": "Ripley"
  },
  {
      "zipCode": 63932,
      "latitude": 36.712451,
      "longitude": -90.407013,
      "city": "Broseley",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63933,
      "latitude": 36.492269,
      "longitude": -90.048069,
      "city": "Campbell",
      "state": "MO",
      "county": "Dunklin"
  },
  {
      "zipCode": 63934,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Clubb",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63935,
      "latitude": 36.650121,
      "longitude": -90.810644,
      "city": "Doniphan",
      "state": "MO",
      "county": "Ripley"
  },
  {
      "zipCode": 63936,
      "latitude": 36.824646,
      "longitude": -90.12135,
      "city": "Dudley",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63937,
      "latitude": 36.934404,
      "longitude": -90.746313,
      "city": "Ellsinore",
      "state": "MO",
      "county": "Carter"
  },
  {
      "zipCode": 63938,
      "latitude": 36.712451,
      "longitude": -90.407013,
      "city": "Fagus",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63939,
      "latitude": 36.66101,
      "longitude": -90.850785,
      "city": "Fairdealing",
      "state": "MO",
      "county": "Ripley"
  },
  {
      "zipCode": 63940,
      "latitude": 36.782328,
      "longitude": -90.207712,
      "city": "Fisk",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63941,
      "latitude": 36.920383,
      "longitude": -91.099461,
      "city": "Fremont",
      "state": "MO",
      "county": "Carter"
  },
  {
      "zipCode": 63942,
      "latitude": 36.66101,
      "longitude": -90.850785,
      "city": "Gatewood",
      "state": "MO",
      "county": "Ripley"
  },
  {
      "zipCode": 63943,
      "latitude": 36.955926,
      "longitude": -90.943027,
      "city": "Grandin",
      "state": "MO",
      "county": "Carter"
  },
  {
      "zipCode": 63944,
      "latitude": 37.126875,
      "longitude": -90.450586,
      "city": "Greenville",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63945,
      "latitude": 36.712451,
      "longitude": -90.407013,
      "city": "Harviell",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63947,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Hiram",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63950,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Lodi",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63951,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Lowndes",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63952,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Mill Spring",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63953,
      "latitude": 36.66101,
      "longitude": -90.850785,
      "city": "Naylor",
      "state": "MO",
      "county": "Ripley"
  },
  {
      "zipCode": 63954,
      "latitude": 36.712451,
      "longitude": -90.407013,
      "city": "Neelyville",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63955,
      "latitude": 36.66101,
      "longitude": -90.850785,
      "city": "Oxly",
      "state": "MO",
      "county": "Ripley"
  },
  {
      "zipCode": 63956,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Patterson",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63957,
      "latitude": 37.148461,
      "longitude": -90.574279,
      "city": "Piedmont",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63960,
      "latitude": 36.978223,
      "longitude": -90.081609,
      "city": "Puxico",
      "state": "MO",
      "county": "Stoddard"
  },
  {
      "zipCode": 63961,
      "latitude": 36.588977,
      "longitude": -90.264918,
      "city": "Qulin",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63962,
      "latitude": 36.712451,
      "longitude": -90.407013,
      "city": "Rombauer",
      "state": "MO",
      "county": "Butler"
  },
  {
      "zipCode": 63963,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Shook",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63964,
      "latitude": 37.207287,
      "longitude": -90.419576,
      "city": "Silva",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63965,
      "latitude": 36.922108,
      "longitude": -91.052088,
      "city": "Van Buren",
      "state": "MO",
      "county": "Carter"
  },
  {
      "zipCode": 63966,
      "latitude": 36.974454,
      "longitude": -90.277511,
      "city": "Wappapello",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 63967,
      "latitude": 37.119566,
      "longitude": -90.444953,
      "city": "Williamsville",
      "state": "MO",
      "county": "Wayne"
  },
  {
      "zipCode": 64001,
      "latitude": 39.105644,
      "longitude": -93.590916,
      "city": "Alma",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64011,
      "latitude": 38.974639,
      "longitude": -94.063718,
      "city": "Bates City",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64012,
      "latitude": 38.784041,
      "longitude": -94.532397,
      "city": "Belton",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64013,
      "latitude": 38.964518,
      "longitude": -94.370275,
      "city": "Blue Springs",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64014,
      "latitude": 39.00569,
      "longitude": -94.252933,
      "city": "Blue Springs",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64015,
      "latitude": 39.01504,
      "longitude": -94.311776,
      "city": "Blue Springs",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64016,
      "latitude": 39.113263,
      "longitude": -94.211568,
      "city": "Buckner",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64017,
      "latitude": 39.260591,
      "longitude": -94.10539,
      "city": "Camden",
      "state": "MO",
      "county": "Ray"
  },
  {
      "zipCode": 64018,
      "latitude": 39.396278,
      "longitude": -94.725832,
      "city": "Camden Point",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64019,
      "latitude": 38.77934,
      "longitude": -93.928302,
      "city": "Centerview",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 64020,
      "latitude": 39.015918,
      "longitude": -93.59571,
      "city": "Concordia",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64021,
      "latitude": 39.118071,
      "longitude": -93.61919,
      "city": "Corder",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64022,
      "latitude": 39.100752,
      "longitude": -93.795752,
      "city": "Dover",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64024,
      "latitude": 39.332601,
      "longitude": -94.274632,
      "city": "Excelsior Springs",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64028,
      "latitude": 39.283928,
      "longitude": -94.830159,
      "city": "Farley",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64029,
      "latitude": 38.984966,
      "longitude": -94.193874,
      "city": "Grain Valley",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64030,
      "latitude": 38.876107,
      "longitude": -94.527063,
      "city": "Grandview",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64034,
      "latitude": 38.86436,
      "longitude": -94.281453,
      "city": "Greenwood",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64035,
      "latitude": 39.315365,
      "longitude": -93.849333,
      "city": "Hardin",
      "state": "MO",
      "county": "Ray"
  },
  {
      "zipCode": 64036,
      "latitude": 39.221835,
      "longitude": -93.938595,
      "city": "Henrietta",
      "state": "MO",
      "county": "Ray"
  },
  {
      "zipCode": 64037,
      "latitude": 39.063374,
      "longitude": -93.74083,
      "city": "Higginsville",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64040,
      "latitude": 38.74013,
      "longitude": -93.995995,
      "city": "Holden",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 64048,
      "latitude": 39.333967,
      "longitude": -94.391095,
      "city": "Holt",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64050,
      "latitude": 39.107798,
      "longitude": -94.409828,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64051,
      "latitude": 39.080369,
      "longitude": -94.387956,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64052,
      "latitude": 39.075554,
      "longitude": -94.451179,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64053,
      "latitude": 39.106204,
      "longitude": -94.466479,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64054,
      "latitude": 39.124904,
      "longitude": -94.427115,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64055,
      "latitude": 39.048523,
      "longitude": -94.415628,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64056,
      "latitude": 39.103572,
      "longitude": -94.32281,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64057,
      "latitude": 39.077509,
      "longitude": -94.31505,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64058,
      "latitude": 39.165267,
      "longitude": -94.317602,
      "city": "Independence",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64060,
      "latitude": 39.374073,
      "longitude": -94.363191,
      "city": "Kearney",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64061,
      "latitude": 38.744077,
      "longitude": -93.817661,
      "city": "Kingsville",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 64062,
      "latitude": 39.443208,
      "longitude": -94.142768,
      "city": "Lawson",
      "state": "MO",
      "county": "Ray"
  },
  {
      "zipCode": 64063,
      "latitude": 38.938517,
      "longitude": -94.348739,
      "city": "Lees Summit",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64064,
      "latitude": 38.987374,
      "longitude": -94.333116,
      "city": "Lees Summit",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64065,
      "latitude": 38.966641,
      "longitude": -94.392885,
      "city": "Lees Summit",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64066,
      "latitude": 39.129238,
      "longitude": -94.133715,
      "city": "Levasy",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64067,
      "latitude": 39.14086,
      "longitude": -93.849284,
      "city": "Lexington",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64068,
      "latitude": 39.298945,
      "longitude": -94.436314,
      "city": "Liberty",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64069,
      "latitude": 39.282869,
      "longitude": -94.408998,
      "city": "Liberty",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64070,
      "latitude": 38.894304,
      "longitude": -94.168262,
      "city": "Lone Jack",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64071,
      "latitude": 39.020768,
      "longitude": -93.758998,
      "city": "Mayview",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64072,
      "latitude": 39.245755,
      "longitude": -94.292406,
      "city": "Missouri City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64073,
      "latitude": 39.314899,
      "longitude": -94.293888,
      "city": "Mosby",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64074,
      "latitude": 39.037902,
      "longitude": -94.038529,
      "city": "Napoleon",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64075,
      "latitude": 39.040656,
      "longitude": -94.238045,
      "city": "Oak Grove",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64076,
      "latitude": 39.020995,
      "longitude": -93.820021,
      "city": "Odessa",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64077,
      "latitude": 39.234415,
      "longitude": -94.024126,
      "city": "Orrick",
      "state": "MO",
      "county": "Ray"
  },
  {
      "zipCode": 64078,
      "latitude": 38.715254,
      "longitude": -94.472568,
      "city": "Peculiar",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64079,
      "latitude": 39.364716,
      "longitude": -94.780639,
      "city": "Platte City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64080,
      "latitude": 38.753028,
      "longitude": -94.270505,
      "city": "Pleasant Hill",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64081,
      "latitude": 38.914352,
      "longitude": -94.400625,
      "city": "Lees Summit",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64082,
      "latitude": 38.865864,
      "longitude": -94.405809,
      "city": "Lees Summit",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64083,
      "latitude": 38.79502,
      "longitude": -94.435542,
      "city": "Raymore",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64084,
      "latitude": 39.380157,
      "longitude": -94.068581,
      "city": "Rayville",
      "state": "MO",
      "county": "Ray"
  },
  {
      "zipCode": 64085,
      "latitude": 39.341906,
      "longitude": -93.984387,
      "city": "Richmond",
      "state": "MO",
      "county": "Ray"
  },
  {
      "zipCode": 64086,
      "latitude": 38.944006,
      "longitude": -94.288086,
      "city": "Lees Summit",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64087,
      "latitude": 39.282869,
      "longitude": -94.408998,
      "city": "Liberty",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64088,
      "latitude": 39.146285,
      "longitude": -94.203996,
      "city": "Sibley",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64089,
      "latitude": 39.38706,
      "longitude": -94.473232,
      "city": "Smithville",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64090,
      "latitude": 38.765833,
      "longitude": -94.160854,
      "city": "Strasburg",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64092,
      "latitude": 39.228875,
      "longitude": -94.805673,
      "city": "Waldron",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64093,
      "latitude": 38.769906,
      "longitude": -93.811697,
      "city": "Warrensburg",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 64096,
      "latitude": 39.188917,
      "longitude": -93.586904,
      "city": "Waverly",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64097,
      "latitude": 39.131379,
      "longitude": -93.992983,
      "city": "Wellington",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 64098,
      "latitude": 39.377692,
      "longitude": -94.928011,
      "city": "Weston",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64101,
      "latitude": 39.102404,
      "longitude": -94.598583,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64102,
      "latitude": 39.091354,
      "longitude": -94.603634,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64105,
      "latitude": 39.102704,
      "longitude": -94.595033,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64106,
      "latitude": 39.105054,
      "longitude": -94.571182,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64108,
      "latitude": 39.083804,
      "longitude": -94.584133,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64109,
      "latitude": 39.066654,
      "longitude": -94.575933,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64110,
      "latitude": 39.032255,
      "longitude": -94.573333,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64111,
      "latitude": 39.057,
      "longitude": -94.593783,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64112,
      "latitude": 39.035705,
      "longitude": -94.594981,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64113,
      "latitude": 39.013805,
      "longitude": -94.525243,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64114,
      "latitude": 38.952273,
      "longitude": -94.596233,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64116,
      "latitude": 39.147932,
      "longitude": -94.568032,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64117,
      "latitude": 39.165103,
      "longitude": -94.525581,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64118,
      "latitude": 39.213348,
      "longitude": -94.574313,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64119,
      "latitude": 39.211552,
      "longitude": -94.527381,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64120,
      "latitude": 39.123203,
      "longitude": -94.522231,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64121,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64123,
      "latitude": 39.068154,
      "longitude": -94.505231,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64124,
      "latitude": 39.107504,
      "longitude": -94.538531,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64125,
      "latitude": 39.105836,
      "longitude": -94.49598,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64126,
      "latitude": 39.094904,
      "longitude": -94.49278,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64127,
      "latitude": 39.08833,
      "longitude": -94.538131,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64128,
      "latitude": 39.065454,
      "longitude": -94.533731,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64129,
      "latitude": 39.046404,
      "longitude": -94.498325,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64130,
      "latitude": 39.033712,
      "longitude": -94.541868,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64131,
      "latitude": 38.962543,
      "longitude": -94.574582,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64132,
      "latitude": 38.984205,
      "longitude": -94.546556,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64133,
      "latitude": 39.032319,
      "longitude": -94.469977,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64134,
      "latitude": 38.930506,
      "longitude": -94.48358,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64136,
      "latitude": 39.009602,
      "longitude": -94.39896,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64137,
      "latitude": 38.935256,
      "longitude": -94.547178,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64138,
      "latitude": 38.952842,
      "longitude": -94.470494,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64139,
      "latitude": 38.964815,
      "longitude": -94.40798,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64141,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64142,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64144,
      "latitude": 39.282869,
      "longitude": -94.408998,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64145,
      "latitude": 38.88057,
      "longitude": -94.595958,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64146,
      "latitude": 38.879006,
      "longitude": -94.574296,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64147,
      "latitude": 38.854895,
      "longitude": -94.556782,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64148,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64149,
      "latitude": 38.873543,
      "longitude": -94.474229,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64150,
      "latitude": 39.177602,
      "longitude": -94.632084,
      "city": "Riverside",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64151,
      "latitude": 39.212702,
      "longitude": -94.638334,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64152,
      "latitude": 39.217552,
      "longitude": -94.723817,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64153,
      "latitude": 39.270474,
      "longitude": -94.741617,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64154,
      "latitude": 39.278393,
      "longitude": -94.641184,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64155,
      "latitude": 39.276001,
      "longitude": -94.570132,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64156,
      "latitude": 39.277706,
      "longitude": -94.51824,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64157,
      "latitude": 39.267855,
      "longitude": -94.472963,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64158,
      "latitude": 39.233302,
      "longitude": -94.476143,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64160,
      "latitude": 39.282869,
      "longitude": -94.408998,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64161,
      "latitude": 39.166079,
      "longitude": -94.463954,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64163,
      "latitude": 39.340159,
      "longitude": -94.690802,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64164,
      "latitude": 39.33035,
      "longitude": -94.636092,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64165,
      "latitude": 39.311252,
      "longitude": -94.543075,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64166,
      "latitude": 39.323206,
      "longitude": -94.510714,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64167,
      "latitude": 39.320008,
      "longitude": -94.487679,
      "city": "Kansas City",
      "state": "MO",
      "county": "Clay"
  },
  {
      "zipCode": 64168,
      "latitude": 39.343225,
      "longitude": -94.85161,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64170,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64171,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64172,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64173,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64179,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64180,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64183,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64184,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64185,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64187,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64188,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64189,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64190,
      "latitude": 39.343225,
      "longitude": -94.85161,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64191,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64192,
      "latitude": 38.953106,
      "longitude": -94.523731,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64193,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64194,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64195,
      "latitude": 39.343225,
      "longitude": -94.85161,
      "city": "Kansas City",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64196,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64197,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64198,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64199,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64401,
      "latitude": 39.631108,
      "longitude": -94.714003,
      "city": "Agency",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64402,
      "latitude": 40.256596,
      "longitude": -94.330949,
      "city": "Albany",
      "state": "MO",
      "county": "Gentry"
  },
  {
      "zipCode": 64420,
      "latitude": 40.478118,
      "longitude": -94.423294,
      "city": "Allendale",
      "state": "MO",
      "county": "Worth"
  },
  {
      "zipCode": 64421,
      "latitude": 39.908392,
      "longitude": -94.923508,
      "city": "Amazonia",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64422,
      "latitude": 39.904361,
      "longitude": -94.490744,
      "city": "Amity",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64423,
      "latitude": 40.266817,
      "longitude": -94.858117,
      "city": "Barnard",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64424,
      "latitude": 40.258215,
      "longitude": -94.020972,
      "city": "Bethany",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64426,
      "latitude": 40.510552,
      "longitude": -93.875682,
      "city": "Blythedale",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64427,
      "latitude": 40.095823,
      "longitude": -94.855903,
      "city": "Bolckow",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64428,
      "latitude": 40.447528,
      "longitude": -95.063022,
      "city": "Burlington Junction",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64429,
      "latitude": 39.649352,
      "longitude": -94.383777,
      "city": "Cameron",
      "state": "MO",
      "county": "Clinton"
  },
  {
      "zipCode": 64430,
      "latitude": 39.827651,
      "longitude": -94.556849,
      "city": "Clarksdale",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64431,
      "latitude": 40.507079,
      "longitude": -95.034077,
      "city": "Clearmont",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64432,
      "latitude": 40.352306,
      "longitude": -94.900456,
      "city": "Clyde",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64433,
      "latitude": 40.352306,
      "longitude": -94.900456,
      "city": "Conception",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64434,
      "latitude": 40.265859,
      "longitude": -94.691489,
      "city": "Conception Junction",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64436,
      "latitude": 39.864915,
      "longitude": -94.68387,
      "city": "Cosby",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64437,
      "latitude": 40.126706,
      "longitude": -95.329064,
      "city": "Craig",
      "state": "MO",
      "county": "Holt"
  },
  {
      "zipCode": 64438,
      "latitude": 40.197663,
      "longitude": -94.397811,
      "city": "Darlington",
      "state": "MO",
      "county": "Gentry"
  },
  {
      "zipCode": 64439,
      "latitude": 39.487839,
      "longitude": -94.763263,
      "city": "Dearborn",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64440,
      "latitude": 39.628826,
      "longitude": -94.807989,
      "city": "De Kalb",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64441,
      "latitude": 40.478118,
      "longitude": -94.423294,
      "city": "Denver",
      "state": "MO",
      "county": "Worth"
  },
  {
      "zipCode": 64442,
      "latitude": 40.50037,
      "longitude": -94.013848,
      "city": "Eagleville",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64443,
      "latitude": 39.750787,
      "longitude": -94.657139,
      "city": "Easton",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64444,
      "latitude": 39.464918,
      "longitude": -94.65705,
      "city": "Edgerton",
      "state": "MO",
      "county": "Platte"
  },
  {
      "zipCode": 64445,
      "latitude": 40.352306,
      "longitude": -94.900456,
      "city": "Elmo",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64446,
      "latitude": 40.347147,
      "longitude": -95.390747,
      "city": "Fairfax",
      "state": "MO",
      "county": "Atchison"
  },
  {
      "zipCode": 64447,
      "latitude": 39.892645,
      "longitude": -94.404415,
      "city": "Fairport",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64448,
      "latitude": 39.604946,
      "longitude": -94.854124,
      "city": "Faucett",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64449,
      "latitude": 40.041019,
      "longitude": -94.971508,
      "city": "Fillmore",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64451,
      "latitude": 40.022472,
      "longitude": -95.185611,
      "city": "Forest City",
      "state": "MO",
      "county": "Holt"
  },
  {
      "zipCode": 64453,
      "latitude": 40.210903,
      "longitude": -94.410425,
      "city": "Gentry",
      "state": "MO",
      "county": "Gentry"
  },
  {
      "zipCode": 64454,
      "latitude": 39.640606,
      "longitude": -94.552925,
      "city": "Gower",
      "state": "MO",
      "county": "Clinton"
  },
  {
      "zipCode": 64455,
      "latitude": 40.200798,
      "longitude": -95.039961,
      "city": "Graham",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64456,
      "latitude": 40.516557,
      "longitude": -94.386821,
      "city": "Grant City",
      "state": "MO",
      "county": "Worth"
  },
  {
      "zipCode": 64457,
      "latitude": 40.352306,
      "longitude": -94.900456,
      "city": "Guilford",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64458,
      "latitude": 40.50452,
      "longitude": -94.141837,
      "city": "Hatfield",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64459,
      "latitude": 39.912051,
      "longitude": -94.698,
      "city": "Helena",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64461,
      "latitude": 40.365638,
      "longitude": -94.815191,
      "city": "Hopkins",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64463,
      "latitude": 40.050583,
      "longitude": -94.522013,
      "city": "King City",
      "state": "MO",
      "county": "Gentry"
  },
  {
      "zipCode": 64465,
      "latitude": 39.609693,
      "longitude": -94.387584,
      "city": "Lathrop",
      "state": "MO",
      "county": "Clinton"
  },
  {
      "zipCode": 64466,
      "latitude": 40.153899,
      "longitude": -95.127221,
      "city": "Maitland",
      "state": "MO",
      "county": "Holt"
  },
  {
      "zipCode": 64467,
      "latitude": 40.411357,
      "longitude": -94.148382,
      "city": "Martinsville",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64468,
      "latitude": 40.277742,
      "longitude": -94.860299,
      "city": "Maryville",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64469,
      "latitude": 39.932762,
      "longitude": -94.357746,
      "city": "Maysville",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64470,
      "latitude": 40.16754,
      "longitude": -95.230117,
      "city": "Mound City",
      "state": "MO",
      "county": "Holt"
  },
  {
      "zipCode": 64471,
      "latitude": 40.260057,
      "longitude": -94.178041,
      "city": "New Hampton",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64473,
      "latitude": 39.981457,
      "longitude": -95.092325,
      "city": "Oregon",
      "state": "MO",
      "county": "Holt"
  },
  {
      "zipCode": 64474,
      "latitude": 39.791179,
      "longitude": -94.397441,
      "city": "Osborn",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64475,
      "latitude": 40.439298,
      "longitude": -94.62247,
      "city": "Parnell",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64476,
      "latitude": 40.352306,
      "longitude": -94.900456,
      "city": "Pickering",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64477,
      "latitude": 39.572431,
      "longitude": -94.497237,
      "city": "Plattsburg",
      "state": "MO",
      "county": "Clinton"
  },
  {
      "zipCode": 64478,
      "latitude": 40.287996,
      "longitude": -95.082241,
      "city": "Quitman",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64479,
      "latitude": 40.35257,
      "longitude": -94.671958,
      "city": "Ravenwood",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64480,
      "latitude": 40.059384,
      "longitude": -94.710629,
      "city": "Rea",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64481,
      "latitude": 40.366488,
      "longitude": -93.935114,
      "city": "Ridgeway",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64482,
      "latitude": 40.411994,
      "longitude": -95.507551,
      "city": "Rock Port",
      "state": "MO",
      "county": "Atchison"
  },
  {
      "zipCode": 64483,
      "latitude": 40.006832,
      "longitude": -94.837165,
      "city": "Rosendale",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64484,
      "latitude": 39.592806,
      "longitude": -95.037469,
      "city": "Rushville",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64485,
      "latitude": 39.953094,
      "longitude": -94.837827,
      "city": "Savannah",
      "state": "MO",
      "county": "Andrew"
  },
  {
      "zipCode": 64486,
      "latitude": 40.517058,
      "longitude": -94.61484,
      "city": "Sheridan",
      "state": "MO",
      "county": "Worth"
  },
  {
      "zipCode": 64487,
      "latitude": 40.286914,
      "longitude": -95.078313,
      "city": "Skidmore",
      "state": "MO",
      "county": "Nodaway"
  },
  {
      "zipCode": 64489,
      "latitude": 40.216886,
      "longitude": -94.539859,
      "city": "Stanberry",
      "state": "MO",
      "county": "Gentry"
  },
  {
      "zipCode": 64490,
      "latitude": 39.798435,
      "longitude": -94.517452,
      "city": "Stewartsville",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64491,
      "latitude": 40.393783,
      "longitude": -95.386166,
      "city": "Tarkio",
      "state": "MO",
      "county": "Atchison"
  },
  {
      "zipCode": 64492,
      "latitude": 39.503195,
      "longitude": -94.525232,
      "city": "Trimble",
      "state": "MO",
      "county": "Clinton"
  },
  {
      "zipCode": 64493,
      "latitude": 39.656015,
      "longitude": -94.385893,
      "city": "Turney",
      "state": "MO",
      "county": "Clinton"
  },
  {
      "zipCode": 64494,
      "latitude": 39.965872,
      "longitude": -94.557711,
      "city": "Union Star",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64496,
      "latitude": 40.423502,
      "longitude": -95.476036,
      "city": "Watson",
      "state": "MO",
      "county": "Atchison"
  },
  {
      "zipCode": 64497,
      "latitude": 39.927682,
      "longitude": -94.242906,
      "city": "Weatherby",
      "state": "MO",
      "county": "Dekalb"
  },
  {
      "zipCode": 64498,
      "latitude": 40.536073,
      "longitude": -95.321135,
      "city": "Westboro",
      "state": "MO",
      "county": "Atchison"
  },
  {
      "zipCode": 64499,
      "latitude": 40.478118,
      "longitude": -94.423294,
      "city": "Worth",
      "state": "MO",
      "county": "Worth"
  },
  {
      "zipCode": 64501,
      "latitude": 39.757494,
      "longitude": -94.836598,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64502,
      "latitude": 39.676333,
      "longitude": -94.857364,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64503,
      "latitude": 39.759092,
      "longitude": -94.800879,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64504,
      "latitude": 39.687683,
      "longitude": -94.899901,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64505,
      "latitude": 39.795418,
      "longitude": -94.823192,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64506,
      "latitude": 39.836488,
      "longitude": -94.81526,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64507,
      "latitude": 39.707229,
      "longitude": -94.725917,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64508,
      "latitude": 39.676333,
      "longitude": -94.857364,
      "city": "Saint Joseph",
      "state": "MO",
      "county": "Buchanan"
  },
  {
      "zipCode": 64601,
      "latitude": 39.795225,
      "longitude": -93.588876,
      "city": "Chillicothe",
      "state": "MO",
      "county": "Livingston"
  },
  {
      "zipCode": 64620,
      "latitude": 39.893337,
      "longitude": -94.090928,
      "city": "Altamont",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64622,
      "latitude": 39.410971,
      "longitude": -93.431928,
      "city": "Bogard",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64623,
      "latitude": 39.469659,
      "longitude": -93.337526,
      "city": "Bosworth",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64624,
      "latitude": 39.598991,
      "longitude": -93.828565,
      "city": "Braymer",
      "state": "MO",
      "county": "Caldwell"
  },
  {
      "zipCode": 64625,
      "latitude": 39.687628,
      "longitude": -93.876612,
      "city": "Breckenridge",
      "state": "MO",
      "county": "Caldwell"
  },
  {
      "zipCode": 64628,
      "latitude": 39.822644,
      "longitude": -93.116,
      "city": "Brookfield",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64630,
      "latitude": 40.008344,
      "longitude": -93.179382,
      "city": "Browning",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64631,
      "latitude": 39.839738,
      "longitude": -93.009571,
      "city": "Bucklin",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64632,
      "latitude": 40.46136,
      "longitude": -93.83198,
      "city": "Cainsville",
      "state": "MO",
      "county": "Harrison"
  },
  {
      "zipCode": 64633,
      "latitude": 39.405818,
      "longitude": -93.425127,
      "city": "Carrollton",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64635,
      "latitude": 39.921825,
      "longitude": -93.446294,
      "city": "Chula",
      "state": "MO",
      "county": "Livingston"
  },
  {
      "zipCode": 64636,
      "latitude": 40.104835,
      "longitude": -93.990293,
      "city": "Coffey",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64637,
      "latitude": 39.590145,
      "longitude": -93.941327,
      "city": "Cowgill",
      "state": "MO",
      "county": "Caldwell"
  },
  {
      "zipCode": 64638,
      "latitude": 39.666701,
      "longitude": -93.603832,
      "city": "Dawn",
      "state": "MO",
      "county": "Livingston"
  },
  {
      "zipCode": 64639,
      "latitude": 39.383962,
      "longitude": -93.223803,
      "city": "De Witt",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64640,
      "latitude": 39.897521,
      "longitude": -93.934987,
      "city": "Gallatin",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64641,
      "latitude": 40.160044,
      "longitude": -93.416387,
      "city": "Galt",
      "state": "MO",
      "county": "Grundy"
  },
  {
      "zipCode": 64642,
      "latitude": 40.010139,
      "longitude": -93.927739,
      "city": "Gilman City",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64643,
      "latitude": 39.605442,
      "longitude": -93.342669,
      "city": "Hale",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64644,
      "latitude": 39.719176,
      "longitude": -94.002251,
      "city": "Hamilton",
      "state": "MO",
      "county": "Caldwell"
  },
  {
      "zipCode": 64645,
      "latitude": 40.209765,
      "longitude": -93.111314,
      "city": "Harris",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 64646,
      "latitude": 40.209765,
      "longitude": -93.111314,
      "city": "Humphreys",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 64647,
      "latitude": 40.023245,
      "longitude": -93.93515,
      "city": "Jameson",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64648,
      "latitude": 39.988049,
      "longitude": -93.838563,
      "city": "Jamesport",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64649,
      "latitude": 39.734774,
      "longitude": -94.08478,
      "city": "Kidder",
      "state": "MO",
      "county": "Caldwell"
  },
  {
      "zipCode": 64650,
      "latitude": 39.633522,
      "longitude": -94.078328,
      "city": "Kingston",
      "state": "MO",
      "county": "Caldwell"
  },
  {
      "zipCode": 64651,
      "latitude": 39.772169,
      "longitude": -93.186626,
      "city": "Laclede",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64652,
      "latitude": 40.036047,
      "longitude": -93.446087,
      "city": "Laredo",
      "state": "MO",
      "county": "Grundy"
  },
  {
      "zipCode": 64653,
      "latitude": 39.886336,
      "longitude": -93.205383,
      "city": "Linneus",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64654,
      "latitude": 39.96054,
      "longitude": -93.988865,
      "city": "Lock Springs",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64655,
      "latitude": 40.465883,
      "longitude": -93.019815,
      "city": "Lucerne",
      "state": "MO",
      "county": "Putnam"
  },
  {
      "zipCode": 64656,
      "latitude": 39.654952,
      "longitude": -93.686524,
      "city": "Ludlow",
      "state": "MO",
      "county": "Livingston"
  },
  {
      "zipCode": 64657,
      "latitude": 40.111077,
      "longitude": -94.223134,
      "city": "Mc Fall",
      "state": "MO",
      "county": "Gentry"
  },
  {
      "zipCode": 64658,
      "latitude": 39.731085,
      "longitude": -92.936247,
      "city": "Marceline",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64659,
      "latitude": 39.795407,
      "longitude": -93.295805,
      "city": "Meadville",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64660,
      "latitude": 39.590245,
      "longitude": -93.133852,
      "city": "Mendon",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 64661,
      "latitude": 40.422119,
      "longitude": -93.570687,
      "city": "Mercer",
      "state": "MO",
      "county": "Mercer"
  },
  {
      "zipCode": 64664,
      "latitude": 39.729002,
      "longitude": -93.667032,
      "city": "Mooresville",
      "state": "MO",
      "county": "Livingston"
  },
  {
      "zipCode": 64667,
      "latitude": 40.23408,
      "longitude": -93.288613,
      "city": "Newtown",
      "state": "MO",
      "county": "Sullivan"
  },
  {
      "zipCode": 64668,
      "latitude": 39.382851,
      "longitude": -93.510329,
      "city": "Norborne",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64670,
      "latitude": 40.033605,
      "longitude": -94.088967,
      "city": "Pattonsburg",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64671,
      "latitude": 39.579927,
      "longitude": -94.050144,
      "city": "Polo",
      "state": "MO",
      "county": "Caldwell"
  },
  {
      "zipCode": 64672,
      "latitude": 40.465883,
      "longitude": -93.019815,
      "city": "Powersville",
      "state": "MO",
      "county": "Putnam"
  },
  {
      "zipCode": 64673,
      "latitude": 40.406448,
      "longitude": -93.584314,
      "city": "Princeton",
      "state": "MO",
      "county": "Mercer"
  },
  {
      "zipCode": 64674,
      "latitude": 39.964179,
      "longitude": -93.152305,
      "city": "Purdin",
      "state": "MO",
      "county": "Linn"
  },
  {
      "zipCode": 64676,
      "latitude": 39.653615,
      "longitude": -93.062349,
      "city": "Rothville",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 64679,
      "latitude": 40.21786,
      "longitude": -93.589114,
      "city": "Spickard",
      "state": "MO",
      "county": "Grundy"
  },
  {
      "zipCode": 64680,
      "latitude": 39.410971,
      "longitude": -93.431928,
      "city": "Stet",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64681,
      "latitude": 39.656057,
      "longitude": -93.243509,
      "city": "Sumner",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 64682,
      "latitude": 39.410971,
      "longitude": -93.431928,
      "city": "Tina",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64683,
      "latitude": 40.083658,
      "longitude": -93.598888,
      "city": "Trenton",
      "state": "MO",
      "county": "Grundy"
  },
  {
      "zipCode": 64686,
      "latitude": 39.789418,
      "longitude": -93.511055,
      "city": "Utica",
      "state": "MO",
      "county": "Livingston"
  },
  {
      "zipCode": 64687,
      "latitude": 39.315544,
      "longitude": -93.377455,
      "city": "Wakenda",
      "state": "MO",
      "county": "Carroll"
  },
  {
      "zipCode": 64688,
      "latitude": 39.871946,
      "longitude": -93.397106,
      "city": "Wheeling",
      "state": "MO",
      "county": "Livingston"
  },
  {
      "zipCode": 64689,
      "latitude": 39.874071,
      "longitude": -94.153677,
      "city": "Winston",
      "state": "MO",
      "county": "Daviess"
  },
  {
      "zipCode": 64701,
      "latitude": 38.615239,
      "longitude": -94.321556,
      "city": "Harrisonville",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64720,
      "latitude": 38.397029,
      "longitude": -94.380375,
      "city": "Adrian",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64722,
      "latitude": 38.251879,
      "longitude": -94.331993,
      "city": "Amoret",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64723,
      "latitude": 38.251879,
      "longitude": -94.331993,
      "city": "Amsterdam",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64724,
      "latitude": 38.167943,
      "longitude": -93.882831,
      "city": "Appleton City",
      "state": "MO",
      "county": "Saint Clair"
  },
  {
      "zipCode": 64725,
      "latitude": 38.521563,
      "longitude": -94.352149,
      "city": "Archie",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64726,
      "latitude": 38.388723,
      "longitude": -93.924453,
      "city": "Blairstown",
      "state": "MO",
      "county": "Henry"
  },
  {
      "zipCode": 64728,
      "latitude": 37.693038,
      "longitude": -94.466251,
      "city": "Bronaugh",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64730,
      "latitude": 38.241174,
      "longitude": -94.3491,
      "city": "Butler",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64733,
      "latitude": 38.621259,
      "longitude": -93.8462,
      "city": "Chilhowee",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 64734,
      "latitude": 38.655025,
      "longitude": -94.559725,
      "city": "Cleveland",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64735,
      "latitude": 38.401811,
      "longitude": -93.785012,
      "city": "Clinton",
      "state": "MO",
      "county": "Henry"
  },
  {
      "zipCode": 64738,
      "latitude": 37.898354,
      "longitude": -93.620259,
      "city": "Collins",
      "state": "MO",
      "county": "Saint Clair"
  },
  {
      "zipCode": 64739,
      "latitude": 38.510491,
      "longitude": -94.130557,
      "city": "Creighton",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64740,
      "latitude": 38.243827,
      "longitude": -93.788188,
      "city": "Deepwater",
      "state": "MO",
      "county": "Henry"
  },
  {
      "zipCode": 64741,
      "latitude": 37.849889,
      "longitude": -94.338133,
      "city": "Deerfield",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64742,
      "latitude": 38.476542,
      "longitude": -94.580083,
      "city": "Drexel",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64743,
      "latitude": 38.668158,
      "longitude": -94.233336,
      "city": "East Lynne",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64744,
      "latitude": 37.865205,
      "longitude": -94.012373,
      "city": "El Dorado Springs",
      "state": "MO",
      "county": "Cedar"
  },
  {
      "zipCode": 64745,
      "latitude": 38.251879,
      "longitude": -94.331993,
      "city": "Foster",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64746,
      "latitude": 38.576898,
      "longitude": -94.400181,
      "city": "Freeman",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64747,
      "latitude": 38.57134,
      "longitude": -94.360846,
      "city": "Garden City",
      "state": "MO",
      "county": "Cass"
  },
  {
      "zipCode": 64748,
      "latitude": 37.417327,
      "longitude": -94.144032,
      "city": "Golden City",
      "state": "MO",
      "county": "Barton"
  },
  {
      "zipCode": 64750,
      "latitude": 37.849889,
      "longitude": -94.338133,
      "city": "Harwood",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64751,
      "latitude": 37.849889,
      "longitude": -94.338133,
      "city": "Horton",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64752,
      "latitude": 38.125999,
      "longitude": -94.547035,
      "city": "Hume",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64755,
      "latitude": 37.211097,
      "longitude": -94.289465,
      "city": "Jasper",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64756,
      "latitude": 37.618851,
      "longitude": -94.010074,
      "city": "Jerico Springs",
      "state": "MO",
      "county": "Cedar"
  },
  {
      "zipCode": 64759,
      "latitude": 37.519054,
      "longitude": -94.336385,
      "city": "Lamar",
      "state": "MO",
      "county": "Barton"
  },
  {
      "zipCode": 64761,
      "latitude": 38.623836,
      "longitude": -93.67341,
      "city": "Leeton",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 64762,
      "latitude": 37.515295,
      "longitude": -94.509371,
      "city": "Liberal",
      "state": "MO",
      "county": "Barton"
  },
  {
      "zipCode": 64763,
      "latitude": 38.141625,
      "longitude": -93.725048,
      "city": "Lowry City",
      "state": "MO",
      "county": "Saint Clair"
  },
  {
      "zipCode": 64765,
      "latitude": 37.849889,
      "longitude": -94.338133,
      "city": "Metz",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64766,
      "latitude": 37.501451,
      "longitude": -94.345591,
      "city": "Milford",
      "state": "MO",
      "county": "Barton"
  },
  {
      "zipCode": 64767,
      "latitude": 37.849889,
      "longitude": -94.338133,
      "city": "Milo",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64769,
      "latitude": 37.471409,
      "longitude": -94.554307,
      "city": "Mindenmines",
      "state": "MO",
      "county": "Barton"
  },
  {
      "zipCode": 64770,
      "latitude": 38.283423,
      "longitude": -93.972124,
      "city": "Montrose",
      "state": "MO",
      "county": "Henry"
  },
  {
      "zipCode": 64771,
      "latitude": 37.764889,
      "longitude": -94.453255,
      "city": "Moundville",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64772,
      "latitude": 37.776465,
      "longitude": -94.386553,
      "city": "Nevada",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64776,
      "latitude": 38.067372,
      "longitude": -93.634122,
      "city": "Osceola",
      "state": "MO",
      "county": "Saint Clair"
  },
  {
      "zipCode": 64777,
      "latitude": 38.251879,
      "longitude": -94.331993,
      "city": "Passaic",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64778,
      "latitude": 37.693773,
      "longitude": -94.46718,
      "city": "Richards",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64779,
      "latitude": 38.096228,
      "longitude": -94.360832,
      "city": "Rich Hill",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64780,
      "latitude": 38.251879,
      "longitude": -94.331993,
      "city": "Rockville",
      "state": "MO",
      "county": "Bates"
  },
  {
      "zipCode": 64781,
      "latitude": 38.024133,
      "longitude": -93.781478,
      "city": "Roscoe",
      "state": "MO",
      "county": "Saint Clair"
  },
  {
      "zipCode": 64783,
      "latitude": 37.849889,
      "longitude": -94.338133,
      "city": "Schell City",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64784,
      "latitude": 37.658203,
      "longitude": -94.295103,
      "city": "Sheldon",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64788,
      "latitude": 38.403809,
      "longitude": -93.989228,
      "city": "Urich",
      "state": "MO",
      "county": "Henry"
  },
  {
      "zipCode": 64789,
      "latitude": 38.024133,
      "longitude": -93.781478,
      "city": "Vista",
      "state": "MO",
      "county": "Saint Clair"
  },
  {
      "zipCode": 64790,
      "latitude": 37.898554,
      "longitude": -94.229787,
      "city": "Walker",
      "state": "MO",
      "county": "Vernon"
  },
  {
      "zipCode": 64801,
      "latitude": 37.113343,
      "longitude": -94.502663,
      "city": "Joplin",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64802,
      "latitude": 37.206219,
      "longitude": -94.335453,
      "city": "Joplin",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64803,
      "latitude": 37.206219,
      "longitude": -94.335453,
      "city": "Joplin",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64804,
      "latitude": 37.099335,
      "longitude": -94.374452,
      "city": "Joplin",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64830,
      "latitude": 37.236677,
      "longitude": -94.417984,
      "city": "Alba",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64831,
      "latitude": 36.666988,
      "longitude": -94.419201,
      "city": "Anderson",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64832,
      "latitude": 37.266718,
      "longitude": -94.517971,
      "city": "Asbury",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64833,
      "latitude": 37.194801,
      "longitude": -94.145193,
      "city": "Avilla",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64834,
      "latitude": 37.188074,
      "longitude": -94.337616,
      "city": "Carl Junction",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64835,
      "latitude": 37.146258,
      "longitude": -94.43545,
      "city": "Carterville",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64836,
      "latitude": 37.182292,
      "longitude": -94.314002,
      "city": "Carthage",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64840,
      "latitude": 36.930776,
      "longitude": -94.248796,
      "city": "Diamond",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64841,
      "latitude": 37.076045,
      "longitude": -94.407063,
      "city": "Duenweg",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64842,
      "latitude": 36.878779,
      "longitude": -94.190596,
      "city": "Fairview",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64843,
      "latitude": 36.738049,
      "longitude": -94.409579,
      "city": "Goodman",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64844,
      "latitude": 36.905518,
      "longitude": -94.253163,
      "city": "Granby",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64847,
      "latitude": 36.608799,
      "longitude": -94.450609,
      "city": "Lanagan",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64848,
      "latitude": 37.164327,
      "longitude": -94.073527,
      "city": "La Russell",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64849,
      "latitude": 37.252361,
      "longitude": -94.431555,
      "city": "Neck City",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64850,
      "latitude": 36.904539,
      "longitude": -94.372328,
      "city": "Neosho",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64853,
      "latitude": 36.828416,
      "longitude": -94.152072,
      "city": "Newtonia",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64854,
      "latitude": 36.579739,
      "longitude": -94.457864,
      "city": "Noel",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64855,
      "latitude": 37.271703,
      "longitude": -94.486518,
      "city": "Oronogo",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64856,
      "latitude": 36.573955,
      "longitude": -94.377022,
      "city": "Pineville",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64857,
      "latitude": 37.216855,
      "longitude": -94.374764,
      "city": "Purcell",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64858,
      "latitude": 36.90103,
      "longitude": -94.532109,
      "city": "Racine",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64859,
      "latitude": 37.165311,
      "longitude": -94.248598,
      "city": "Reeds",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64861,
      "latitude": 36.632573,
      "longitude": -94.342802,
      "city": "Rocky Comfort",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64862,
      "latitude": 37.159934,
      "longitude": -94.156371,
      "city": "Sarcoxie",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64863,
      "latitude": 36.621335,
      "longitude": -94.512968,
      "city": "South West City",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64864,
      "latitude": 36.902332,
      "longitude": -94.338957,
      "city": "Saginaw",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64865,
      "latitude": 36.878572,
      "longitude": -94.345348,
      "city": "Seneca",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64866,
      "latitude": 36.868113,
      "longitude": -94.137992,
      "city": "Stark City",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64867,
      "latitude": 36.814525,
      "longitude": -94.379315,
      "city": "Stella",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64868,
      "latitude": 36.632573,
      "longitude": -94.342802,
      "city": "Tiff City",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 64869,
      "latitude": 37.206219,
      "longitude": -94.335453,
      "city": "Waco",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64870,
      "latitude": 37.185063,
      "longitude": -94.330289,
      "city": "Webb City",
      "state": "MO",
      "county": "Jasper"
  },
  {
      "zipCode": 64873,
      "latitude": 36.982951,
      "longitude": -94.187929,
      "city": "Wentworth",
      "state": "MO",
      "county": "Newton"
  },
  {
      "zipCode": 64874,
      "latitude": 36.761726,
      "longitude": -94.054723,
      "city": "Wheaton",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 64944,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 64999,
      "latitude": 39.035038,
      "longitude": -94.356728,
      "city": "Kansas City",
      "state": "MO",
      "county": "Jackson"
  },
  {
      "zipCode": 65001,
      "latitude": 38.498027,
      "longitude": -91.918023,
      "city": "Argyle",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65010,
      "latitude": 38.781337,
      "longitude": -92.265908,
      "city": "Ashland",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65011,
      "latitude": 38.373142,
      "longitude": -92.715162,
      "city": "Barnett",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65013,
      "latitude": 38.204321,
      "longitude": -91.756633,
      "city": "Belle",
      "state": "MO",
      "county": "Maries"
  },
  {
      "zipCode": 65014,
      "latitude": 38.393844,
      "longitude": -91.520173,
      "city": "Bland",
      "state": "MO",
      "county": "Gasconade"
  },
  {
      "zipCode": 65016,
      "latitude": 38.498027,
      "longitude": -91.918023,
      "city": "Bonnots Mill",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65017,
      "latitude": 38.187229,
      "longitude": -92.499823,
      "city": "Brumley",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65018,
      "latitude": 38.625055,
      "longitude": -92.550973,
      "city": "California",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65020,
      "latitude": 38.024609,
      "longitude": -92.744846,
      "city": "Camdenton",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65022,
      "latitude": 38.599175,
      "longitude": -92.178057,
      "city": "Cedar City",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65023,
      "latitude": 38.595556,
      "longitude": -92.370285,
      "city": "Centertown",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65024,
      "latitude": 38.606181,
      "longitude": -91.738215,
      "city": "Chamois",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65025,
      "latitude": 38.635322,
      "longitude": -92.584502,
      "city": "Clarksburg",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65026,
      "latitude": 38.320057,
      "longitude": -92.478035,
      "city": "Eldon",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65031,
      "latitude": 38.223649,
      "longitude": -92.438629,
      "city": "Etterville",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65032,
      "latitude": 38.393423,
      "longitude": -92.363038,
      "city": "Eugene",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65034,
      "latitude": 38.545388,
      "longitude": -92.760087,
      "city": "Fortuna",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65035,
      "latitude": 38.395172,
      "longitude": -91.956786,
      "city": "Freeburg",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65036,
      "latitude": 38.670303,
      "longitude": -91.559163,
      "city": "Gasconade",
      "state": "MO",
      "county": "Gasconade"
  },
  {
      "zipCode": 65037,
      "latitude": 38.26795,
      "longitude": -92.847886,
      "city": "Gravois Mills",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65038,
      "latitude": 38.196429,
      "longitude": -92.867804,
      "city": "Laurie",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65039,
      "latitude": 38.72719,
      "longitude": -92.301413,
      "city": "Hartsburg",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65040,
      "latitude": 38.390269,
      "longitude": -92.312425,
      "city": "Henley",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65041,
      "latitude": 38.587608,
      "longitude": -91.499131,
      "city": "Hermann",
      "state": "MO",
      "county": "Gasconade"
  },
  {
      "zipCode": 65042,
      "latitude": 38.674914,
      "longitude": -92.610941,
      "city": "High Point",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65043,
      "latitude": 38.658869,
      "longitude": -92.110858,
      "city": "Holts Summit",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65046,
      "latitude": 38.771269,
      "longitude": -92.481749,
      "city": "Jamestown",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65047,
      "latitude": 38.172743,
      "longitude": -92.557722,
      "city": "Kaiser",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65048,
      "latitude": 38.498027,
      "longitude": -91.918023,
      "city": "Koeltztown",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65049,
      "latitude": 38.113071,
      "longitude": -92.682496,
      "city": "Lake Ozark",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65050,
      "latitude": 38.546636,
      "longitude": -92.681747,
      "city": "Latham",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65051,
      "latitude": 38.497711,
      "longitude": -91.787954,
      "city": "Linn",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65052,
      "latitude": 38.058544,
      "longitude": -92.719289,
      "city": "Linn Creek",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65053,
      "latitude": 38.54395,
      "longitude": -92.375577,
      "city": "Lohman",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65054,
      "latitude": 38.498027,
      "longitude": -91.918023,
      "city": "Loose Creek",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65055,
      "latitude": 38.674914,
      "longitude": -92.610941,
      "city": "Mc Girk",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65058,
      "latitude": 38.314864,
      "longitude": -92.127749,
      "city": "Meta",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65059,
      "latitude": 38.705147,
      "longitude": -91.89325,
      "city": "Mokane",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65061,
      "latitude": 38.488169,
      "longitude": -91.599416,
      "city": "Morrison",
      "state": "MO",
      "county": "Gasconade"
  },
  {
      "zipCode": 65062,
      "latitude": 38.484502,
      "longitude": -91.621808,
      "city": "Mount Sterling",
      "state": "MO",
      "county": "Gasconade"
  },
  {
      "zipCode": 65063,
      "latitude": 38.730467,
      "longitude": -92.08044,
      "city": "New Bloomfield",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65064,
      "latitude": 38.397892,
      "longitude": -92.488014,
      "city": "Olean",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65065,
      "latitude": 38.131001,
      "longitude": -92.671943,
      "city": "Osage Beach",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65066,
      "latitude": 38.360691,
      "longitude": -91.477511,
      "city": "Owensville",
      "state": "MO",
      "county": "Gasconade"
  },
  {
      "zipCode": 65067,
      "latitude": 38.770559,
      "longitude": -91.713568,
      "city": "Portland",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65068,
      "latitude": 38.819678,
      "longitude": -92.606295,
      "city": "Prairie Home",
      "state": "MO",
      "county": "Cooper"
  },
  {
      "zipCode": 65069,
      "latitude": 38.794225,
      "longitude": -91.573653,
      "city": "Rhineland",
      "state": "MO",
      "county": "Montgomery"
  },
  {
      "zipCode": 65072,
      "latitude": 38.265465,
      "longitude": -92.734795,
      "city": "Rocky Mount",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65074,
      "latitude": 38.485106,
      "longitude": -92.409339,
      "city": "Russellville",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65075,
      "latitude": 38.251613,
      "longitude": -92.254481,
      "city": "Saint Elizabeth",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65076,
      "latitude": 38.421857,
      "longitude": -92.317745,
      "city": "Saint Thomas",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65077,
      "latitude": 38.764377,
      "longitude": -91.798654,
      "city": "Steedman",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65078,
      "latitude": 38.437941,
      "longitude": -92.995989,
      "city": "Stover",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65079,
      "latitude": 38.164874,
      "longitude": -92.720128,
      "city": "Sunrise Beach",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65080,
      "latitude": 38.635304,
      "longitude": -91.99659,
      "city": "Tebbetts",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65081,
      "latitude": 38.656781,
      "longitude": -92.661396,
      "city": "Tipton",
      "state": "MO",
      "county": "Moniteau"
  },
  {
      "zipCode": 65082,
      "latitude": 38.21813,
      "longitude": -92.446098,
      "city": "Tuscumbia",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65083,
      "latitude": 38.150536,
      "longitude": -92.43301,
      "city": "Ulman",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65084,
      "latitude": 38.420142,
      "longitude": -92.835322,
      "city": "Versailles",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65085,
      "latitude": 38.406348,
      "longitude": -92.049397,
      "city": "Westphalia",
      "state": "MO",
      "county": "Osage"
  },
  {
      "zipCode": 65101,
      "latitude": 38.494029,
      "longitude": -92.165194,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65102,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65103,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65104,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65105,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65106,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65107,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65108,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65109,
      "latitude": 38.550219,
      "longitude": -92.299267,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65110,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65111,
      "latitude": 38.530921,
      "longitude": -92.249342,
      "city": "Jefferson City",
      "state": "MO",
      "county": "Cole"
  },
  {
      "zipCode": 65201,
      "latitude": 38.894165,
      "longitude": -92.274145,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65202,
      "latitude": 39.015904,
      "longitude": -92.311885,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65203,
      "latitude": 38.88248,
      "longitude": -92.397824,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65205,
      "latitude": 39.044719,
      "longitude": -92.349574,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65211,
      "latitude": 38.903318,
      "longitude": -92.102153,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65212,
      "latitude": 38.937608,
      "longitude": -92.33043,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65215,
      "latitude": 38.953238,
      "longitude": -92.320783,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65216,
      "latitude": 38.903318,
      "longitude": -92.102153,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65217,
      "latitude": 38.903318,
      "longitude": -92.102153,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65218,
      "latitude": 38.903318,
      "longitude": -92.102153,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65230,
      "latitude": 39.242532,
      "longitude": -92.69974,
      "city": "Armstrong",
      "state": "MO",
      "county": "Howard"
  },
  {
      "zipCode": 65231,
      "latitude": 39.008551,
      "longitude": -91.911773,
      "city": "Auxvasse",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65232,
      "latitude": 39.156071,
      "longitude": -91.755227,
      "city": "Benton City",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 65233,
      "latitude": 38.884444,
      "longitude": -92.733329,
      "city": "Boonville",
      "state": "MO",
      "county": "Cooper"
  },
  {
      "zipCode": 65236,
      "latitude": 39.425365,
      "longitude": -93.128077,
      "city": "Brunswick",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 65237,
      "latitude": 38.759637,
      "longitude": -92.790412,
      "city": "Bunceton",
      "state": "MO",
      "county": "Cooper"
  },
  {
      "zipCode": 65239,
      "latitude": 39.534445,
      "longitude": -92.470668,
      "city": "Cairo",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65240,
      "latitude": 39.143437,
      "longitude": -92.179684,
      "city": "Centralia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65243,
      "latitude": 39.295025,
      "longitude": -92.374105,
      "city": "Clark",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65244,
      "latitude": 39.428482,
      "longitude": -92.504195,
      "city": "Clifton Hill",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65246,
      "latitude": 39.464326,
      "longitude": -92.988621,
      "city": "Dalton",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 65247,
      "latitude": 39.638988,
      "longitude": -92.46581,
      "city": "Excello",
      "state": "MO",
      "county": "Macon"
  },
  {
      "zipCode": 65248,
      "latitude": 39.149242,
      "longitude": -92.673441,
      "city": "Fayette",
      "state": "MO",
      "county": "Howard"
  },
  {
      "zipCode": 65250,
      "latitude": 39.04337,
      "longitude": -92.827618,
      "city": "Franklin",
      "state": "MO",
      "county": "Howard"
  },
  {
      "zipCode": 65251,
      "latitude": 38.830203,
      "longitude": -91.966756,
      "city": "Fulton",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65254,
      "latitude": 39.159075,
      "longitude": -92.826306,
      "city": "Glasgow",
      "state": "MO",
      "county": "Howard"
  },
  {
      "zipCode": 65255,
      "latitude": 39.102338,
      "longitude": -92.240267,
      "city": "Hallsville",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65256,
      "latitude": 39.148359,
      "longitude": -92.425698,
      "city": "Harrisburg",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65257,
      "latitude": 39.307179,
      "longitude": -92.5135,
      "city": "Higbee",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65258,
      "latitude": 39.488707,
      "longitude": -92.149089,
      "city": "Holliday",
      "state": "MO",
      "county": "Monroe"
  },
  {
      "zipCode": 65259,
      "latitude": 39.470411,
      "longitude": -92.504087,
      "city": "Huntsville",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65260,
      "latitude": 39.60614,
      "longitude": -92.361437,
      "city": "Jacksonville",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65261,
      "latitude": 39.425165,
      "longitude": -92.908657,
      "city": "Keytesville",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 65262,
      "latitude": 38.959176,
      "longitude": -91.92418,
      "city": "Kingdom City",
      "state": "MO",
      "county": "Callaway"
  },
  {
      "zipCode": 65263,
      "latitude": 39.471942,
      "longitude": -92.206875,
      "city": "Madison",
      "state": "MO",
      "county": "Monroe"
  },
  {
      "zipCode": 65264,
      "latitude": 39.11089,
      "longitude": -91.702079,
      "city": "Martinsburg",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 65265,
      "latitude": 39.201449,
      "longitude": -91.838271,
      "city": "Mexico",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 65270,
      "latitude": 39.425513,
      "longitude": -92.428348,
      "city": "Moberly",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65274,
      "latitude": 39.095462,
      "longitude": -92.705966,
      "city": "New Franklin",
      "state": "MO",
      "county": "Howard"
  },
  {
      "zipCode": 65275,
      "latitude": 39.467456,
      "longitude": -92.017068,
      "city": "Paris",
      "state": "MO",
      "county": "Monroe"
  },
  {
      "zipCode": 65276,
      "latitude": 38.849273,
      "longitude": -92.926931,
      "city": "Pilot Grove",
      "state": "MO",
      "county": "Cooper"
  },
  {
      "zipCode": 65278,
      "latitude": 39.341357,
      "longitude": -92.410961,
      "city": "Renick",
      "state": "MO",
      "county": "Randolph"
  },
  {
      "zipCode": 65279,
      "latitude": 38.996588,
      "longitude": -92.50403,
      "city": "Rocheport",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65280,
      "latitude": 39.211548,
      "longitude": -91.708681,
      "city": "Rush Hill",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 65281,
      "latitude": 39.425568,
      "longitude": -92.802909,
      "city": "Salisbury",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 65282,
      "latitude": 39.360312,
      "longitude": -91.865315,
      "city": "Santa Fe",
      "state": "MO",
      "county": "Monroe"
  },
  {
      "zipCode": 65283,
      "latitude": 39.481463,
      "longitude": -91.848796,
      "city": "Stoutsville",
      "state": "MO",
      "county": "Monroe"
  },
  {
      "zipCode": 65284,
      "latitude": 39.180874,
      "longitude": -92.327183,
      "city": "Sturgeon",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65285,
      "latitude": 39.212787,
      "longitude": -92.004617,
      "city": "Thompson",
      "state": "MO",
      "county": "Audrain"
  },
  {
      "zipCode": 65286,
      "latitude": 39.499974,
      "longitude": -93.193552,
      "city": "Triplett",
      "state": "MO",
      "county": "Chariton"
  },
  {
      "zipCode": 65287,
      "latitude": 38.898275,
      "longitude": -92.566685,
      "city": "Wooldridge",
      "state": "MO",
      "county": "Cooper"
  },
  {
      "zipCode": 65299,
      "latitude": 38.903318,
      "longitude": -92.102153,
      "city": "Columbia",
      "state": "MO",
      "county": "Boone"
  },
  {
      "zipCode": 65301,
      "latitude": 38.703138,
      "longitude": -93.240166,
      "city": "Sedalia",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65302,
      "latitude": 38.724577,
      "longitude": -93.281672,
      "city": "Sedalia",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65305,
      "latitude": 38.731819,
      "longitude": -93.573071,
      "city": "Whiteman Air Force Base",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 65320,
      "latitude": 39.069352,
      "longitude": -92.948668,
      "city": "Arrow Rock",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65321,
      "latitude": 39.105316,
      "longitude": -93.484742,
      "city": "Blackburn",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65322,
      "latitude": 38.940391,
      "longitude": -92.868132,
      "city": "Blackwater",
      "state": "MO",
      "county": "Cooper"
  },
  {
      "zipCode": 65323,
      "latitude": 38.454863,
      "longitude": -93.608588,
      "city": "Calhoun",
      "state": "MO",
      "county": "Henry"
  },
  {
      "zipCode": 65324,
      "latitude": 38.101843,
      "longitude": -93.053635,
      "city": "Climax Springs",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65325,
      "latitude": 38.337832,
      "longitude": -93.314592,
      "city": "Cole Camp",
      "state": "MO",
      "county": "Benton"
  },
  {
      "zipCode": 65326,
      "latitude": 38.298955,
      "longitude": -93.291274,
      "city": "Edwards",
      "state": "MO",
      "county": "Benton"
  },
  {
      "zipCode": 65327,
      "latitude": 38.974573,
      "longitude": -93.503803,
      "city": "Emma",
      "state": "MO",
      "county": "Lafayette"
  },
  {
      "zipCode": 65329,
      "latitude": 38.608009,
      "longitude": -92.982845,
      "city": "Florence",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65330,
      "latitude": 39.232673,
      "longitude": -93.004028,
      "city": "Gilliam",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65332,
      "latitude": 38.605883,
      "longitude": -93.40918,
      "city": "Green Ridge",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65333,
      "latitude": 38.854841,
      "longitude": -93.313267,
      "city": "Houstonia",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65334,
      "latitude": 38.826683,
      "longitude": -93.22994,
      "city": "Hughesville",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65335,
      "latitude": 38.503854,
      "longitude": -93.323687,
      "city": "Ionia",
      "state": "MO",
      "county": "Benton"
  },
  {
      "zipCode": 65336,
      "latitude": 38.743365,
      "longitude": -93.582884,
      "city": "Knob Noster",
      "state": "MO",
      "county": "Johnson"
  },
  {
      "zipCode": 65337,
      "latitude": 38.783542,
      "longitude": -93.417093,
      "city": "La Monte",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65338,
      "latitude": 38.395302,
      "longitude": -93.334544,
      "city": "Lincoln",
      "state": "MO",
      "county": "Benton"
  },
  {
      "zipCode": 65339,
      "latitude": 39.148119,
      "longitude": -93.377596,
      "city": "Malta Bend",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65340,
      "latitude": 39.161394,
      "longitude": -93.244357,
      "city": "Marshall",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65344,
      "latitude": 39.321402,
      "longitude": -93.226114,
      "city": "Miami",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65345,
      "latitude": 38.556891,
      "longitude": -93.158732,
      "city": "Mora",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65347,
      "latitude": 39.029347,
      "longitude": -92.992939,
      "city": "Nelson",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65348,
      "latitude": 38.742077,
      "longitude": -92.96287,
      "city": "Otterville",
      "state": "MO",
      "county": "Cooper"
  },
  {
      "zipCode": 65349,
      "latitude": 39.101211,
      "longitude": -93.264214,
      "city": "Slater",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65350,
      "latitude": 38.659963,
      "longitude": -93.108757,
      "city": "Smithton",
      "state": "MO",
      "county": "Pettis"
  },
  {
      "zipCode": 65351,
      "latitude": 39.070204,
      "longitude": -93.430344,
      "city": "Sweet Springs",
      "state": "MO",
      "county": "Saline"
  },
  {
      "zipCode": 65354,
      "latitude": 38.654746,
      "longitude": -92.892923,
      "city": "Syracuse",
      "state": "MO",
      "county": "Morgan"
  },
  {
      "zipCode": 65355,
      "latitude": 38.31555,
      "longitude": -93.327381,
      "city": "Warsaw",
      "state": "MO",
      "county": "Benton"
  },
  {
      "zipCode": 65360,
      "latitude": 38.488076,
      "longitude": -93.588108,
      "city": "Windsor",
      "state": "MO",
      "county": "Henry"
  },
  {
      "zipCode": 65401,
      "latitude": 37.898204,
      "longitude": -91.797641,
      "city": "Rolla",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65402,
      "latitude": 37.963208,
      "longitude": -91.817936,
      "city": "Rolla",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65409,
      "latitude": 37.876191,
      "longitude": -91.777182,
      "city": "Rolla",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65433,
      "latitude": 37.309426,
      "longitude": -92.214372,
      "city": "Bendavis",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65436,
      "latitude": 37.615826,
      "longitude": -91.906391,
      "city": "Beulah",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65438,
      "latitude": 36.997849,
      "longitude": -91.494175,
      "city": "Birch Tree",
      "state": "MO",
      "county": "Shannon"
  },
  {
      "zipCode": 65439,
      "latitude": 37.505462,
      "longitude": -90.845047,
      "city": "Bixby",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 65440,
      "latitude": 37.612779,
      "longitude": -91.652493,
      "city": "Boss",
      "state": "MO",
      "county": "Dent"
  },
  {
      "zipCode": 65441,
      "latitude": 38.10595,
      "longitude": -91.248694,
      "city": "Bourbon",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65443,
      "latitude": 38.148315,
      "longitude": -92.101525,
      "city": "Brinktown",
      "state": "MO",
      "county": "Maries"
  },
  {
      "zipCode": 65444,
      "latitude": 37.384157,
      "longitude": -92.077182,
      "city": "Bucyrus",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65446,
      "latitude": 37.866661,
      "longitude": -91.279649,
      "city": "Cherryville",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65449,
      "latitude": 37.85671,
      "longitude": -91.470109,
      "city": "Cook Station",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65452,
      "latitude": 37.944664,
      "longitude": -92.26771,
      "city": "Crocker",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65453,
      "latitude": 38.100081,
      "longitude": -91.368908,
      "city": "Cuba",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65456,
      "latitude": 37.786953,
      "longitude": -91.211555,
      "city": "Davisville",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65457,
      "latitude": 37.851231,
      "longitude": -92.056898,
      "city": "Devils Elbow",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65459,
      "latitude": 37.928603,
      "longitude": -92.107438,
      "city": "Dixon",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65461,
      "latitude": 37.705975,
      "longitude": -92.006428,
      "city": "Duke",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65462,
      "latitude": 37.694596,
      "longitude": -91.890325,
      "city": "Edgar Springs",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65463,
      "latitude": 37.769908,
      "longitude": -92.74866,
      "city": "Eldridge",
      "state": "MO",
      "county": "Laclede"
  },
  {
      "zipCode": 65464,
      "latitude": 37.187845,
      "longitude": -91.913367,
      "city": "Elk Creek",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65466,
      "latitude": 37.144556,
      "longitude": -91.357626,
      "city": "Eminence",
      "state": "MO",
      "county": "Shannon"
  },
  {
      "zipCode": 65468,
      "latitude": 37.247968,
      "longitude": -91.783379,
      "city": "Eunice",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65470,
      "latitude": 37.563472,
      "longitude": -92.385957,
      "city": "Falcon",
      "state": "MO",
      "county": "Laclede"
  },
  {
      "zipCode": 65473,
      "latitude": 37.767687,
      "longitude": -92.111975,
      "city": "Fort Leonard Wood",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65479,
      "latitude": 37.307074,
      "longitude": -91.73234,
      "city": "Hartshorn",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65483,
      "latitude": 37.305192,
      "longitude": -91.946292,
      "city": "Houston",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65484,
      "latitude": 37.3599,
      "longitude": -92.188628,
      "city": "Huggins",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65486,
      "latitude": 38.108574,
      "longitude": -92.31017,
      "city": "Iberia",
      "state": "MO",
      "county": "Miller"
  },
  {
      "zipCode": 65501,
      "latitude": 37.603341,
      "longitude": -91.481225,
      "city": "Jadwin",
      "state": "MO",
      "county": "Dent"
  },
  {
      "zipCode": 65529,
      "latitude": 37.924112,
      "longitude": -91.983231,
      "city": "Jerome",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65532,
      "latitude": 37.603341,
      "longitude": -91.481225,
      "city": "Lake Spring",
      "state": "MO",
      "county": "Dent"
  },
  {
      "zipCode": 65534,
      "latitude": 37.718409,
      "longitude": -92.323916,
      "city": "Laquey",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65535,
      "latitude": 38.074206,
      "longitude": -91.265783,
      "city": "Leasburg",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65536,
      "latitude": 37.685459,
      "longitude": -92.620542,
      "city": "Lebanon",
      "state": "MO",
      "county": "Laclede"
  },
  {
      "zipCode": 65540,
      "latitude": 37.603341,
      "longitude": -91.481225,
      "city": "Lecoma",
      "state": "MO",
      "county": "Dent"
  },
  {
      "zipCode": 65541,
      "latitude": 37.603341,
      "longitude": -91.481225,
      "city": "Lenox",
      "state": "MO",
      "county": "Dent"
  },
  {
      "zipCode": 65542,
      "latitude": 37.390505,
      "longitude": -91.867049,
      "city": "Licking",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65543,
      "latitude": 37.522073,
      "longitude": -92.30882,
      "city": "Lynchburg",
      "state": "MO",
      "county": "Laclede"
  },
  {
      "zipCode": 65546,
      "latitude": 37.153294,
      "longitude": -91.337506,
      "city": "Montier",
      "state": "MO",
      "county": "Shannon"
  },
  {
      "zipCode": 65548,
      "latitude": 36.974825,
      "longitude": -91.76979,
      "city": "Mountain View",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65550,
      "latitude": 37.839291,
      "longitude": -91.91236,
      "city": "Newburg",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65552,
      "latitude": 37.484252,
      "longitude": -92.157003,
      "city": "Plato",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65555,
      "latitude": 37.366338,
      "longitude": -91.748856,
      "city": "Raymondville",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65556,
      "latitude": 37.85021,
      "longitude": -92.328289,
      "city": "Richland",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65557,
      "latitude": 37.499376,
      "longitude": -92.132343,
      "city": "Roby",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65559,
      "latitude": 37.971347,
      "longitude": -91.636399,
      "city": "Saint James",
      "state": "MO",
      "county": "Phelps"
  },
  {
      "zipCode": 65560,
      "latitude": 37.640709,
      "longitude": -91.53927,
      "city": "Salem",
      "state": "MO",
      "county": "Dent"
  },
  {
      "zipCode": 65564,
      "latitude": 37.242824,
      "longitude": -91.962351,
      "city": "Solo",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65565,
      "latitude": 37.890403,
      "longitude": -91.303229,
      "city": "Steelville",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65566,
      "latitude": 37.713211,
      "longitude": -91.133384,
      "city": "Viburnum",
      "state": "MO",
      "county": "Iron"
  },
  {
      "zipCode": 65567,
      "latitude": 37.837263,
      "longitude": -92.462635,
      "city": "Stoutland",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65570,
      "latitude": 37.462582,
      "longitude": -92.090859,
      "city": "Success",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65571,
      "latitude": 37.17649,
      "longitude": -91.712763,
      "city": "Summersville",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65572,
      "latitude": 37.81186,
      "longitude": -92.217391,
      "city": "Swedeborg",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65573,
      "latitude": 37.153294,
      "longitude": -91.337506,
      "city": "Teresita",
      "state": "MO",
      "county": "Shannon"
  },
  {
      "zipCode": 65580,
      "latitude": 38.130967,
      "longitude": -91.794844,
      "city": "Vichy",
      "state": "MO",
      "county": "Maries"
  },
  {
      "zipCode": 65582,
      "latitude": 38.16992,
      "longitude": -91.968002,
      "city": "Vienna",
      "state": "MO",
      "county": "Maries"
  },
  {
      "zipCode": 65583,
      "latitude": 37.767552,
      "longitude": -92.210471,
      "city": "Waynesville",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65584,
      "latitude": 37.828894,
      "longitude": -92.131217,
      "city": "Saint Robert",
      "state": "MO",
      "county": "Pulaski"
  },
  {
      "zipCode": 65586,
      "latitude": 37.858464,
      "longitude": -91.426693,
      "city": "Wesco",
      "state": "MO",
      "county": "Crawford"
  },
  {
      "zipCode": 65588,
      "latitude": 36.998455,
      "longitude": -91.331701,
      "city": "Winona",
      "state": "MO",
      "county": "Shannon"
  },
  {
      "zipCode": 65589,
      "latitude": 37.23762,
      "longitude": -91.82048,
      "city": "Yukon",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65590,
      "latitude": 37.6596,
      "longitude": -93.018874,
      "city": "Long Lane",
      "state": "MO",
      "county": "Dallas"
  },
  {
      "zipCode": 65591,
      "latitude": 38.034962,
      "longitude": -92.744219,
      "city": "Montreal",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65601,
      "latitude": 37.534753,
      "longitude": -93.543226,
      "city": "Aldrich",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65603,
      "latitude": 37.431754,
      "longitude": -93.849903,
      "city": "Arcola",
      "state": "MO",
      "county": "Dade"
  },
  {
      "zipCode": 65604,
      "latitude": 37.311381,
      "longitude": -93.578642,
      "city": "Ash Grove",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65605,
      "latitude": 37.005666,
      "longitude": -93.790376,
      "city": "Aurora",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65606,
      "latitude": 36.692896,
      "longitude": -91.397129,
      "city": "Alton",
      "state": "MO",
      "county": "Oregon"
  },
  {
      "zipCode": 65607,
      "latitude": 37.736871,
      "longitude": -93.842468,
      "city": "Caplinger Mills",
      "state": "MO",
      "county": "Cedar"
  },
  {
      "zipCode": 65608,
      "latitude": 36.951914,
      "longitude": -92.665499,
      "city": "Ava",
      "state": "MO",
      "county": "Douglas"
  },
  {
      "zipCode": 65609,
      "latitude": 36.52365,
      "longitude": -92.144958,
      "city": "Bakersfield",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65610,
      "latitude": 37.046128,
      "longitude": -93.405929,
      "city": "Billings",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65611,
      "latitude": 36.549301,
      "longitude": -93.338838,
      "city": "Blue Eye",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65612,
      "latitude": 37.224385,
      "longitude": -93.540803,
      "city": "Bois D Arc",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65613,
      "latitude": 37.626464,
      "longitude": -93.413616,
      "city": "Bolivar",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65614,
      "latitude": 36.722523,
      "longitude": -92.914606,
      "city": "Bradleyville",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65615,
      "latitude": 36.660981,
      "longitude": -93.235798,
      "city": "Branson",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65616,
      "latitude": 36.653256,
      "longitude": -93.05634,
      "city": "Branson",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65617,
      "latitude": 37.462457,
      "longitude": -93.347992,
      "city": "Brighton",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65618,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Brixey",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65619,
      "latitude": 37.139291,
      "longitude": -93.384921,
      "city": "Brookline Station",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65620,
      "latitude": 37.011657,
      "longitude": -92.951702,
      "city": "Bruner",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65622,
      "latitude": 37.642882,
      "longitude": -93.090618,
      "city": "Buffalo",
      "state": "MO",
      "county": "Dallas"
  },
  {
      "zipCode": 65623,
      "latitude": 36.74901,
      "longitude": -93.906436,
      "city": "Butterfield",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65624,
      "latitude": 36.747083,
      "longitude": -93.458626,
      "city": "Cape Fair",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65625,
      "latitude": 36.683825,
      "longitude": -93.875744,
      "city": "Cassville",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65626,
      "latitude": 36.578961,
      "longitude": -92.051654,
      "city": "Caulfield",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65627,
      "latitude": 36.560345,
      "longitude": -93.014906,
      "city": "Cedarcreek",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65629,
      "latitude": 36.885838,
      "longitude": -93.040981,
      "city": "Chadwick",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65630,
      "latitude": 36.83595,
      "longitude": -93.229058,
      "city": "Chestnutridge",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65631,
      "latitude": 37.044729,
      "longitude": -93.438301,
      "city": "Clever",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65632,
      "latitude": 37.532463,
      "longitude": -92.72801,
      "city": "Conway",
      "state": "MO",
      "county": "Laclede"
  },
  {
      "zipCode": 65633,
      "latitude": 36.905731,
      "longitude": -93.486809,
      "city": "Crane",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65634,
      "latitude": 38.024206,
      "longitude": -93.228867,
      "city": "Cross Timbers",
      "state": "MO",
      "county": "Hickory"
  },
  {
      "zipCode": 65635,
      "latitude": 37.431754,
      "longitude": -93.849903,
      "city": "Dadeville",
      "state": "MO",
      "county": "Dade"
  },
  {
      "zipCode": 65636,
      "latitude": 37.277247,
      "longitude": -92.877874,
      "city": "Diggins",
      "state": "MO",
      "county": "Webster"
  },
  {
      "zipCode": 65637,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Dora",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65638,
      "latitude": 36.932331,
      "longitude": -92.499044,
      "city": "Drury",
      "state": "MO",
      "county": "Douglas"
  },
  {
      "zipCode": 65640,
      "latitude": 37.698301,
      "longitude": -93.542218,
      "city": "Dunnegan",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65641,
      "latitude": 36.715249,
      "longitude": -93.825497,
      "city": "Eagle Rock",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65644,
      "latitude": 37.277247,
      "longitude": -92.877874,
      "city": "Elkland",
      "state": "MO",
      "county": "Webster"
  },
  {
      "zipCode": 65645,
      "latitude": 37.476695,
      "longitude": -93.539707,
      "city": "Eudora",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65646,
      "latitude": 37.34335,
      "longitude": -93.702108,
      "city": "Everton",
      "state": "MO",
      "county": "Dade"
  },
  {
      "zipCode": 65647,
      "latitude": 36.677628,
      "longitude": -93.927466,
      "city": "Exeter",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65648,
      "latitude": 37.370209,
      "longitude": -93.175093,
      "city": "Fair Grove",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65649,
      "latitude": 37.631792,
      "longitude": -93.559197,
      "city": "Fair Play",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65650,
      "latitude": 37.739853,
      "longitude": -93.463116,
      "city": "Flemington",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65652,
      "latitude": 37.165856,
      "longitude": -92.958202,
      "city": "Fordland",
      "state": "MO",
      "county": "Webster"
  },
  {
      "zipCode": 65653,
      "latitude": 36.726771,
      "longitude": -93.107824,
      "city": "Forsyth",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65654,
      "latitude": 37.021147,
      "longitude": -93.897434,
      "city": "Freistatt",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65655,
      "latitude": 36.605575,
      "longitude": -92.415322,
      "city": "Gainesville",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65656,
      "latitude": 36.780532,
      "longitude": -93.406941,
      "city": "Galena",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65657,
      "latitude": 36.863017,
      "longitude": -92.99999,
      "city": "Garrison",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65658,
      "latitude": 36.715249,
      "longitude": -93.825497,
      "city": "Golden",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65659,
      "latitude": 37.750035,
      "longitude": -93.246729,
      "city": "Goodson",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65660,
      "latitude": 37.345392,
      "longitude": -92.278799,
      "city": "Graff",
      "state": "MO",
      "county": "Wright"
  },
  {
      "zipCode": 65661,
      "latitude": 37.406208,
      "longitude": -93.895184,
      "city": "Greenfield",
      "state": "MO",
      "county": "Dade"
  },
  {
      "zipCode": 65662,
      "latitude": 37.396509,
      "longitude": -92.581914,
      "city": "Grovespring",
      "state": "MO",
      "county": "Wright"
  },
  {
      "zipCode": 65663,
      "latitude": 37.630967,
      "longitude": -93.258038,
      "city": "Half Way",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65664,
      "latitude": 37.194385,
      "longitude": -93.627547,
      "city": "Halltown",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65666,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Hardenville",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65667,
      "latitude": 37.319401,
      "longitude": -92.512497,
      "city": "Hartville",
      "state": "MO",
      "county": "Wright"
  },
  {
      "zipCode": 65668,
      "latitude": 37.946659,
      "longitude": -93.320033,
      "city": "Hermitage",
      "state": "MO",
      "county": "Hickory"
  },
  {
      "zipCode": 65669,
      "latitude": 36.921415,
      "longitude": -93.280185,
      "city": "Highlandville",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65672,
      "latitude": 36.630652,
      "longitude": -93.17315,
      "city": "Hollister",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65673,
      "latitude": 36.617892,
      "longitude": -93.216187,
      "city": "Hollister",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65674,
      "latitude": 37.729288,
      "longitude": -93.554646,
      "city": "Humansville",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65675,
      "latitude": 36.930494,
      "longitude": -93.496491,
      "city": "Hurley",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65676,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Isabella",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65679,
      "latitude": 36.579153,
      "longitude": -93.126321,
      "city": "Kirbyville",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65680,
      "latitude": 36.69779,
      "longitude": -92.985076,
      "city": "Kissee Mills",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65681,
      "latitude": 36.747083,
      "longitude": -93.458626,
      "city": "Lampe",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65682,
      "latitude": 37.38595,
      "longitude": -93.954122,
      "city": "Lockwood",
      "state": "MO",
      "county": "Dade"
  },
  {
      "zipCode": 65685,
      "latitude": 37.6596,
      "longitude": -93.018874,
      "city": "Louisburg",
      "state": "MO",
      "county": "Dallas"
  },
  {
      "zipCode": 65686,
      "latitude": 36.638577,
      "longitude": -93.432117,
      "city": "Kimberling City",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65688,
      "latitude": 36.648817,
      "longitude": -91.697596,
      "city": "Brandsville",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65689,
      "latitude": 37.193593,
      "longitude": -92.078527,
      "city": "Cabool",
      "state": "MO",
      "county": "Texas"
  },
  {
      "zipCode": 65690,
      "latitude": 36.692896,
      "longitude": -91.397129,
      "city": "Couch",
      "state": "MO",
      "county": "Oregon"
  },
  {
      "zipCode": 65692,
      "latitude": 36.596757,
      "longitude": -91.645491,
      "city": "Koshkonong",
      "state": "MO",
      "county": "Oregon"
  },
  {
      "zipCode": 65701,
      "latitude": 36.702612,
      "longitude": -92.781063,
      "city": "Mc Clurg",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65702,
      "latitude": 37.100803,
      "longitude": -92.535335,
      "city": "Macomb",
      "state": "MO",
      "county": "Wright"
  },
  {
      "zipCode": 65704,
      "latitude": 37.168407,
      "longitude": -92.573487,
      "city": "Mansfield",
      "state": "MO",
      "county": "Wright"
  },
  {
      "zipCode": 65705,
      "latitude": 37.065338,
      "longitude": -93.628454,
      "city": "Marionville",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65706,
      "latitude": 37.274421,
      "longitude": -92.932494,
      "city": "Marshfield",
      "state": "MO",
      "county": "Webster"
  },
  {
      "zipCode": 65707,
      "latitude": 37.229699,
      "longitude": -93.847381,
      "city": "Miller",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65708,
      "latitude": 36.802435,
      "longitude": -93.934986,
      "city": "Monett",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65710,
      "latitude": 37.488021,
      "longitude": -93.439073,
      "city": "Morrisville",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65711,
      "latitude": 37.265652,
      "longitude": -92.432734,
      "city": "Mountain Grove",
      "state": "MO",
      "county": "Wright"
  },
  {
      "zipCode": 65712,
      "latitude": 37.064637,
      "longitude": -93.824061,
      "city": "Mount Vernon",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65713,
      "latitude": 37.318077,
      "longitude": -92.757673,
      "city": "Niangua",
      "state": "MO",
      "county": "Webster"
  },
  {
      "zipCode": 65714,
      "latitude": 36.961768,
      "longitude": -93.237749,
      "city": "Nixa",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65715,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Noble",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65717,
      "latitude": 37.160913,
      "longitude": -92.415178,
      "city": "Norwood",
      "state": "MO",
      "county": "Wright"
  },
  {
      "zipCode": 65720,
      "latitude": 36.972932,
      "longitude": -93.096909,
      "city": "Oldfield",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65721,
      "latitude": 36.972577,
      "longitude": -93.122301,
      "city": "Ozark",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65722,
      "latitude": 37.577499,
      "longitude": -92.759488,
      "city": "Phillipsburg",
      "state": "MO",
      "county": "Laclede"
  },
  {
      "zipCode": 65723,
      "latitude": 36.984539,
      "longitude": -93.97419,
      "city": "Pierce City",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65724,
      "latitude": 37.845874,
      "longitude": -93.305582,
      "city": "Pittsburg",
      "state": "MO",
      "county": "Hickory"
  },
  {
      "zipCode": 65725,
      "latitude": 37.455661,
      "longitude": -93.265019,
      "city": "Pleasant Hope",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65726,
      "latitude": 36.616515,
      "longitude": -93.241839,
      "city": "Point Lookout",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65727,
      "latitude": 37.743835,
      "longitude": -93.320582,
      "city": "Polk",
      "state": "MO",
      "county": "Polk"
  },
  {
      "zipCode": 65728,
      "latitude": 36.747083,
      "longitude": -93.458626,
      "city": "Ponce De Leon",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65729,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Pontiac",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65730,
      "latitude": 36.632573,
      "longitude": -94.342802,
      "city": "Powell",
      "state": "MO",
      "county": "Mcdonald"
  },
  {
      "zipCode": 65731,
      "latitude": 36.657825,
      "longitude": -93.124271,
      "city": "Powersite",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65732,
      "latitude": 37.942744,
      "longitude": -93.324817,
      "city": "Preston",
      "state": "MO",
      "county": "Hickory"
  },
  {
      "zipCode": 65733,
      "latitude": 36.546632,
      "longitude": -93.02939,
      "city": "Protem",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65734,
      "latitude": 36.78885,
      "longitude": -93.917312,
      "city": "Purdy",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65735,
      "latitude": 37.937887,
      "longitude": -93.319651,
      "city": "Quincy",
      "state": "MO",
      "county": "Hickory"
  },
  {
      "zipCode": 65737,
      "latitude": 36.690661,
      "longitude": -93.344687,
      "city": "Reeds Spring",
      "state": "MO",
      "county": "Stone"
  },
  {
      "zipCode": 65738,
      "latitude": 37.256089,
      "longitude": -93.501003,
      "city": "Republic",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65739,
      "latitude": 36.610226,
      "longitude": -93.230334,
      "city": "Ridgedale",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65740,
      "latitude": 36.714583,
      "longitude": -93.158501,
      "city": "Rockaway Beach",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65741,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Rockbridge",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65742,
      "latitude": 37.224224,
      "longitude": -92.983202,
      "city": "Rogersville",
      "state": "MO",
      "county": "Webster"
  },
  {
      "zipCode": 65744,
      "latitude": 36.628353,
      "longitude": -92.909254,
      "city": "Rueter",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65745,
      "latitude": 36.527016,
      "longitude": -93.937458,
      "city": "Seligman",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65746,
      "latitude": 37.17598,
      "longitude": -92.820353,
      "city": "Seymour",
      "state": "MO",
      "county": "Webster"
  },
  {
      "zipCode": 65747,
      "latitude": 36.715249,
      "longitude": -93.825497,
      "city": "Shell Knob",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65752,
      "latitude": 37.431754,
      "longitude": -93.849903,
      "city": "South Greenfield",
      "state": "MO",
      "county": "Dade"
  },
  {
      "zipCode": 65753,
      "latitude": 36.993376,
      "longitude": -93.039554,
      "city": "Sparta",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65754,
      "latitude": 36.854253,
      "longitude": -93.292806,
      "city": "Spokane",
      "state": "MO",
      "county": "Christian"
  },
  {
      "zipCode": 65755,
      "latitude": 36.932331,
      "longitude": -92.499044,
      "city": "Squires",
      "state": "MO",
      "county": "Douglas"
  },
  {
      "zipCode": 65756,
      "latitude": 37.104238,
      "longitude": -93.995261,
      "city": "Stotts City",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65757,
      "latitude": 37.310494,
      "longitude": -93.307207,
      "city": "Strafford",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65759,
      "latitude": 36.750537,
      "longitude": -93.016322,
      "city": "Taneyville",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65760,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Tecumseh",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65761,
      "latitude": 36.583048,
      "longitude": -92.662803,
      "city": "Theodosia",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65762,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Thornfield",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65764,
      "latitude": 37.6596,
      "longitude": -93.018874,
      "city": "Tunas",
      "state": "MO",
      "county": "Dallas"
  },
  {
      "zipCode": 65765,
      "latitude": 37.25807,
      "longitude": -93.343673,
      "city": "Turners",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65766,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Udall",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65767,
      "latitude": 37.803727,
      "longitude": -93.154651,
      "city": "Urbana",
      "state": "MO",
      "county": "Dallas"
  },
  {
      "zipCode": 65768,
      "latitude": 36.932331,
      "longitude": -92.499044,
      "city": "Vanzant",
      "state": "MO",
      "county": "Douglas"
  },
  {
      "zipCode": 65769,
      "latitude": 36.985316,
      "longitude": -93.809406,
      "city": "Verona",
      "state": "MO",
      "county": "Lawrence"
  },
  {
      "zipCode": 65770,
      "latitude": 37.369849,
      "longitude": -93.343077,
      "city": "Walnut Grove",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65771,
      "latitude": 36.772257,
      "longitude": -93.222445,
      "city": "Walnut Shade",
      "state": "MO",
      "county": "Taney"
  },
  {
      "zipCode": 65772,
      "latitude": 36.62333,
      "longitude": -93.940707,
      "city": "Washburn",
      "state": "MO",
      "county": "Barry"
  },
  {
      "zipCode": 65773,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Wasola",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65774,
      "latitude": 37.891711,
      "longitude": -93.540576,
      "city": "Weaubleau",
      "state": "MO",
      "county": "Hickory"
  },
  {
      "zipCode": 65775,
      "latitude": 36.74651,
      "longitude": -91.882912,
      "city": "West Plains",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65776,
      "latitude": 36.629135,
      "longitude": -91.986785,
      "city": "South Fork",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65777,
      "latitude": 36.581303,
      "longitude": -91.989349,
      "city": "Moody",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65778,
      "latitude": 36.692896,
      "longitude": -91.397129,
      "city": "Myrtle",
      "state": "MO",
      "county": "Oregon"
  },
  {
      "zipCode": 65779,
      "latitude": 37.944587,
      "longitude": -93.365137,
      "city": "Wheatland",
      "state": "MO",
      "county": "Hickory"
  },
  {
      "zipCode": 65781,
      "latitude": 37.348973,
      "longitude": -93.315876,
      "city": "Willard",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65783,
      "latitude": 37.6596,
      "longitude": -93.018874,
      "city": "Windyville",
      "state": "MO",
      "county": "Dallas"
  },
  {
      "zipCode": 65784,
      "latitude": 36.651607,
      "longitude": -92.442341,
      "city": "Zanoni",
      "state": "MO",
      "county": "Ozark"
  },
  {
      "zipCode": 65785,
      "latitude": 37.681676,
      "longitude": -93.797692,
      "city": "Stockton",
      "state": "MO",
      "county": "Cedar"
  },
  {
      "zipCode": 65786,
      "latitude": 38.034962,
      "longitude": -92.744219,
      "city": "Macks Creek",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65787,
      "latitude": 38.102482,
      "longitude": -92.914785,
      "city": "Roach",
      "state": "MO",
      "county": "Camden"
  },
  {
      "zipCode": 65788,
      "latitude": 36.870043,
      "longitude": -91.777528,
      "city": "Peace Valley",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65789,
      "latitude": 36.876581,
      "longitude": -91.895914,
      "city": "Pomona",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65790,
      "latitude": 36.701608,
      "longitude": -92.04286,
      "city": "Pottersville",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65791,
      "latitude": 36.526267,
      "longitude": -91.548742,
      "city": "Thayer",
      "state": "MO",
      "county": "Oregon"
  },
  {
      "zipCode": 65793,
      "latitude": 36.933184,
      "longitude": -91.89891,
      "city": "Willow Springs",
      "state": "MO",
      "county": "Howell"
  },
  {
      "zipCode": 65801,
      "latitude": 37.25807,
      "longitude": -93.343673,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65802,
      "latitude": 37.18457,
      "longitude": -93.31994,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65803,
      "latitude": 37.299377,
      "longitude": -93.324456,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65804,
      "latitude": 37.151377,
      "longitude": -93.238034,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65805,
      "latitude": 37.25807,
      "longitude": -93.343673,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65806,
      "latitude": 37.177044,
      "longitude": -93.303288,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65807,
      "latitude": 37.158811,
      "longitude": -93.348705,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65808,
      "latitude": 37.25807,
      "longitude": -93.343673,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65809,
      "latitude": 37.150649,
      "longitude": -93.21641,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65810,
      "latitude": 37.122249,
      "longitude": -93.31318,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65814,
      "latitude": 37.25807,
      "longitude": -93.343673,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65817,
      "latitude": 37.25807,
      "longitude": -93.343673,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65890,
      "latitude": 37.25807,
      "longitude": -93.343673,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65898,
      "latitude": 37.180349,
      "longitude": -93.295137,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 65899,
      "latitude": 37.181498,
      "longitude": -93.259586,
      "city": "Springfield",
      "state": "MO",
      "county": "Greene"
  },
  {
      "zipCode": 66002,
      "latitude": 39.535948,
      "longitude": -95.225098,
      "city": "Atchison",
      "state": "KS",
      "county": "Atchison"
  },
  {
      "zipCode": 66006,
      "latitude": 38.821502,
      "longitude": -95.223906,
      "city": "Baldwin City",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66007,
      "latitude": 39.133448,
      "longitude": -94.947234,
      "city": "Basehor",
      "state": "KS",
      "county": "Leavenworth"
  },
  {
      "zipCode": 66008,
      "latitude": 39.712615,
      "longitude": -95.186894,
      "city": "Bendena",
      "state": "KS",
      "county": "Doniphan"
  },
  {
      "zipCode": 66010,
      "latitude": 38.089756,
      "longitude": -95.007693,
      "city": "Blue Mound",
      "state": "KS",
      "county": "Linn"
  },
  {
      "zipCode": 66012,
      "latitude": 39.068153,
      "longitude": -94.763938,
      "city": "Bonner Springs",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66013,
      "latitude": 38.676056,
      "longitude": -94.685052,
      "city": "Bucyrus",
      "state": "KS",
      "county": "Miami"
  },
  {
      "zipCode": 66014,
      "latitude": 38.282583,
      "longitude": -94.890305,
      "city": "Centerville",
      "state": "KS",
      "county": "Linn"
  },
  {
      "zipCode": 66015,
      "latitude": 38.213685,
      "longitude": -95.299014,
      "city": "Colony",
      "state": "KS",
      "county": "Anderson"
  },
  {
      "zipCode": 66016,
      "latitude": 39.483518,
      "longitude": -95.240978,
      "city": "Cummings",
      "state": "KS",
      "county": "Atchison"
  },
  {
      "zipCode": 66017,
      "latitude": 39.70398,
      "longitude": -95.103098,
      "city": "Denton",
      "state": "KS",
      "county": "Doniphan"
  },
  {
      "zipCode": 66018,
      "latitude": 38.946156,
      "longitude": -94.97143,
      "city": "De Soto",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66019,
      "latitude": 38.946041,
      "longitude": -95.003661,
      "city": "Clearview City",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66020,
      "latitude": 39.339141,
      "longitude": -95.099858,
      "city": "Easton",
      "state": "KS",
      "county": "Leavenworth"
  },
  {
      "zipCode": 66021,
      "latitude": 38.817766,
      "longitude": -94.94316,
      "city": "Edgerton",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66023,
      "latitude": 39.507561,
      "longitude": -95.393716,
      "city": "Effingham",
      "state": "KS",
      "county": "Atchison"
  },
  {
      "zipCode": 66024,
      "latitude": 39.757262,
      "longitude": -94.882439,
      "city": "Elwood",
      "state": "KS",
      "county": "Doniphan"
  },
  {
      "zipCode": 66025,
      "latitude": 38.879142,
      "longitude": -95.125903,
      "city": "Eudora",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66026,
      "latitude": 38.417885,
      "longitude": -94.88344,
      "city": "Fontana",
      "state": "KS",
      "county": "Miami"
  },
  {
      "zipCode": 66027,
      "latitude": 39.346798,
      "longitude": -94.931329,
      "city": "Fort Leavenworth",
      "state": "KS",
      "county": "Leavenworth"
  },
  {
      "zipCode": 66030,
      "latitude": 38.814998,
      "longitude": -94.94139,
      "city": "Gardner",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66031,
      "latitude": 38.824863,
      "longitude": -94.899201,
      "city": "New Century",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66032,
      "latitude": 38.232767,
      "longitude": -95.286458,
      "city": "Garnett",
      "state": "KS",
      "county": "Anderson"
  },
  {
      "zipCode": 66033,
      "latitude": 38.346417,
      "longitude": -95.139231,
      "city": "Greeley",
      "state": "KS",
      "county": "Anderson"
  },
  {
      "zipCode": 66035,
      "latitude": 39.878677,
      "longitude": -95.227692,
      "city": "Highland",
      "state": "KS",
      "county": "Doniphan"
  },
  {
      "zipCode": 66036,
      "latitude": 38.658068,
      "longitude": -94.852061,
      "city": "Hillsdale",
      "state": "KS",
      "county": "Miami"
  },
  {
      "zipCode": 66039,
      "latitude": 38.110393,
      "longitude": -95.188557,
      "city": "Kincaid",
      "state": "KS",
      "county": "Anderson"
  },
  {
      "zipCode": 66040,
      "latitude": 38.362869,
      "longitude": -94.731773,
      "city": "La Cygne",
      "state": "KS",
      "county": "Linn"
  },
  {
      "zipCode": 66041,
      "latitude": 39.536445,
      "longitude": -95.306304,
      "city": "Lancaster",
      "state": "KS",
      "county": "Atchison"
  },
  {
      "zipCode": 66042,
      "latitude": 38.550993,
      "longitude": -95.263735,
      "city": "Lane",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66043,
      "latitude": 39.233204,
      "longitude": -94.883026,
      "city": "Lansing",
      "state": "KS",
      "county": "Leavenworth"
  },
  {
      "zipCode": 66044,
      "latitude": 38.907518,
      "longitude": -95.283982,
      "city": "Lawrence",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66045,
      "latitude": 38.952526,
      "longitude": -95.275623,
      "city": "Lawrence",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66046,
      "latitude": 38.878682,
      "longitude": -95.315453,
      "city": "Lawrence",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66047,
      "latitude": 38.88437,
      "longitude": -95.338066,
      "city": "Lawrence",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66048,
      "latitude": 39.279656,
      "longitude": -94.982216,
      "city": "Leavenworth",
      "state": "KS",
      "county": "Leavenworth"
  },
  {
      "zipCode": 66049,
      "latitude": 38.903685,
      "longitude": -95.344647,
      "city": "Lawrence",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66050,
      "latitude": 39.004574,
      "longitude": -95.417804,
      "city": "Lecompton",
      "state": "KS",
      "county": "Douglas"
  },
  {
      "zipCode": 66051,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Olathe",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66052,
      "latitude": 39.00755,
      "longitude": -95.056044,
      "city": "Linwood",
      "state": "KS",
      "county": "Leavenworth"
  },
  {
      "zipCode": 66053,
      "latitude": 38.587195,
      "longitude": -94.689876,
      "city": "Louisburg",
      "state": "KS",
      "county": "Miami"
  },
  {
      "zipCode": 66054,
      "latitude": 39.198095,
      "longitude": -95.231788,
      "city": "Mc Louth",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66056,
      "latitude": 38.243571,
      "longitude": -94.766019,
      "city": "Mound City",
      "state": "KS",
      "county": "Linn"
  },
  {
      "zipCode": 66058,
      "latitude": 39.529523,
      "longitude": -95.483992,
      "city": "Muscotah",
      "state": "KS",
      "county": "Atchison"
  },
  {
      "zipCode": 66060,
      "latitude": 39.383848,
      "longitude": -95.319744,
      "city": "Nortonville",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66061,
      "latitude": 38.891427,
      "longitude": -94.881709,
      "city": "Olathe",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66062,
      "latitude": 38.847391,
      "longitude": -94.778714,
      "city": "Olathe",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66063,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Olathe",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66064,
      "latitude": 38.558533,
      "longitude": -94.891655,
      "city": "Osawatomie",
      "state": "KS",
      "county": "Miami"
  },
  {
      "zipCode": 66066,
      "latitude": 39.255747,
      "longitude": -95.356589,
      "city": "Oskaloosa",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66067,
      "latitude": 38.564337,
      "longitude": -95.282668,
      "city": "Ottawa",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66070,
      "latitude": 39.17715,
      "longitude": -95.456501,
      "city": "Ozawkie",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66071,
      "latitude": 38.577932,
      "longitude": -94.880328,
      "city": "Paola",
      "state": "KS",
      "county": "Miami"
  },
  {
      "zipCode": 66072,
      "latitude": 38.255903,
      "longitude": -94.852908,
      "city": "Parker",
      "state": "KS",
      "county": "Linn"
  },
  {
      "zipCode": 66073,
      "latitude": 39.229717,
      "longitude": -95.396681,
      "city": "Perry",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66075,
      "latitude": 38.176349,
      "longitude": -94.713323,
      "city": "Pleasanton",
      "state": "KS",
      "county": "Linn"
  },
  {
      "zipCode": 66076,
      "latitude": 38.61504,
      "longitude": -95.3418,
      "city": "Pomona",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66077,
      "latitude": 39.423624,
      "longitude": -95.140829,
      "city": "Potter",
      "state": "KS",
      "county": "Atchison"
  },
  {
      "zipCode": 66078,
      "latitude": 38.470428,
      "longitude": -95.258362,
      "city": "Princeton",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66079,
      "latitude": 38.550188,
      "longitude": -95.138748,
      "city": "Rantoul",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66080,
      "latitude": 38.436338,
      "longitude": -95.312506,
      "city": "Richmond",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66083,
      "latitude": 38.789323,
      "longitude": -94.788441,
      "city": "Spring Hill",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66085,
      "latitude": 38.81799,
      "longitude": -94.656698,
      "city": "Stilwell",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66086,
      "latitude": 39.115489,
      "longitude": -95.084717,
      "city": "Tonganoxie",
      "state": "KS",
      "county": "Leavenworth"
  },
  {
      "zipCode": 66087,
      "latitude": 39.790573,
      "longitude": -95.143417,
      "city": "Troy",
      "state": "KS",
      "county": "Doniphan"
  },
  {
      "zipCode": 66088,
      "latitude": 39.248329,
      "longitude": -95.428183,
      "city": "Valley Falls",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66090,
      "latitude": 39.806075,
      "longitude": -95.083528,
      "city": "Wathena",
      "state": "KS",
      "county": "Doniphan"
  },
  {
      "zipCode": 66091,
      "latitude": 38.150424,
      "longitude": -95.343798,
      "city": "Welda",
      "state": "KS",
      "county": "Anderson"
  },
  {
      "zipCode": 66092,
      "latitude": 38.642244,
      "longitude": -95.149986,
      "city": "Wellsville",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66093,
      "latitude": 38.210603,
      "longitude": -95.457728,
      "city": "Westphalia",
      "state": "KS",
      "county": "Anderson"
  },
  {
      "zipCode": 66094,
      "latitude": 39.873744,
      "longitude": -95.29357,
      "city": "White Cloud",
      "state": "KS",
      "county": "Doniphan"
  },
  {
      "zipCode": 66095,
      "latitude": 38.47913,
      "longitude": -95.406321,
      "city": "Williamsburg",
      "state": "KS",
      "county": "Franklin"
  },
  {
      "zipCode": 66097,
      "latitude": 39.2314,
      "longitude": -95.285222,
      "city": "Winchester",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66101,
      "latitude": 39.103053,
      "longitude": -94.630384,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66102,
      "latitude": 39.101553,
      "longitude": -94.750537,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66103,
      "latitude": 39.066804,
      "longitude": -94.628184,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66104,
      "latitude": 39.145049,
      "longitude": -94.688896,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66105,
      "latitude": 39.082662,
      "longitude": -94.635528,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66106,
      "latitude": 39.069404,
      "longitude": -94.717837,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66109,
      "latitude": 39.143692,
      "longitude": -94.817719,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66110,
      "latitude": 39.096551,
      "longitude": -94.749538,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66111,
      "latitude": 39.086354,
      "longitude": -94.786033,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66112,
      "latitude": 39.116953,
      "longitude": -94.765822,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66113,
      "latitude": 39.073539,
      "longitude": -94.723271,
      "city": "Edwardsville",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66115,
      "latitude": 39.136353,
      "longitude": -94.615984,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66117,
      "latitude": 39.096551,
      "longitude": -94.749538,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66118,
      "latitude": 39.101054,
      "longitude": -94.614434,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66119,
      "latitude": 39.096551,
      "longitude": -94.749538,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66160,
      "latitude": 39.096551,
      "longitude": -94.749538,
      "city": "Kansas City",
      "state": "KS",
      "county": "Wyandotte"
  },
  {
      "zipCode": 66201,
      "latitude": 39.007755,
      "longitude": -94.679486,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66202,
      "latitude": 39.024819,
      "longitude": -94.682563,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66203,
      "latitude": 39.015629,
      "longitude": -94.693187,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66204,
      "latitude": 38.974815,
      "longitude": -94.683188,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66205,
      "latitude": 38.994381,
      "longitude": -94.827369,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66206,
      "latitude": 38.959356,
      "longitude": -94.716155,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66207,
      "latitude": 38.955156,
      "longitude": -94.644701,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66208,
      "latitude": 38.993805,
      "longitude": -94.634038,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66209,
      "latitude": 38.898356,
      "longitude": -94.637684,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66210,
      "latitude": 38.927256,
      "longitude": -94.714336,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66211,
      "latitude": 38.924838,
      "longitude": -94.637888,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66212,
      "latitude": 38.956806,
      "longitude": -94.683236,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66213,
      "latitude": 38.898243,
      "longitude": -94.704936,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66214,
      "latitude": 38.964879,
      "longitude": -94.720914,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66215,
      "latitude": 38.949007,
      "longitude": -94.740503,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66216,
      "latitude": 39.014655,
      "longitude": -94.741448,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66217,
      "latitude": 39.014604,
      "longitude": -94.770868,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66218,
      "latitude": 39.014005,
      "longitude": -94.81079,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66219,
      "latitude": 38.955326,
      "longitude": -94.779899,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66220,
      "latitude": 38.961334,
      "longitude": -94.822249,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66221,
      "latitude": 38.861933,
      "longitude": -94.714388,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66222,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66223,
      "latitude": 38.861887,
      "longitude": -94.660994,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66224,
      "latitude": 38.859094,
      "longitude": -94.631442,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66225,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66226,
      "latitude": 39.017826,
      "longitude": -94.862667,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66227,
      "latitude": 38.974684,
      "longitude": -94.868066,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66250,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66251,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66276,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66279,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66282,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66283,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66285,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66286,
      "latitude": 38.899901,
      "longitude": -94.831991,
      "city": "Shawnee Mission",
      "state": "KS",
      "county": "Johnson"
  },
  {
      "zipCode": 66401,
      "latitude": 38.953556,
      "longitude": -96.204558,
      "city": "Alma",
      "state": "KS",
      "county": "Wabaunsee"
  },
  {
      "zipCode": 66402,
      "latitude": 38.916916,
      "longitude": -95.839181,
      "city": "Auburn",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66403,
      "latitude": 39.885826,
      "longitude": -96.295457,
      "city": "Axtell",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66404,
      "latitude": 39.753031,
      "longitude": -96.108737,
      "city": "Baileyville",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66406,
      "latitude": 39.906945,
      "longitude": -96.416779,
      "city": "Beattie",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66407,
      "latitude": 39.277337,
      "longitude": -96.164714,
      "city": "Belvue",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66408,
      "latitude": 39.960927,
      "longitude": -95.967077,
      "city": "Bern",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66409,
      "latitude": 38.927847,
      "longitude": -95.584708,
      "city": "Berryton",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66411,
      "latitude": 39.713306,
      "longitude": -96.563601,
      "city": "Blue Rapids",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66412,
      "latitude": 39.907206,
      "longitude": -96.758998,
      "city": "Bremen",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66413,
      "latitude": 38.73799,
      "longitude": -95.815754,
      "city": "Burlingame",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66414,
      "latitude": 38.811675,
      "longitude": -95.706138,
      "city": "Carbondale",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66415,
      "latitude": 39.697428,
      "longitude": -96.032867,
      "city": "Centralia",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66416,
      "latitude": 39.528134,
      "longitude": -95.848729,
      "city": "Circleville",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66417,
      "latitude": 39.654314,
      "longitude": -96.031758,
      "city": "Corning",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66418,
      "latitude": 39.300065,
      "longitude": -95.900691,
      "city": "Delia",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66419,
      "latitude": 39.353979,
      "longitude": -95.612718,
      "city": "Denison",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66420,
      "latitude": 38.964532,
      "longitude": -95.917186,
      "city": "Dover",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66422,
      "latitude": 39.347682,
      "longitude": -96.10416,
      "city": "Emmett",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66423,
      "latitude": 38.858895,
      "longitude": -96.145799,
      "city": "Eskridge",
      "state": "KS",
      "county": "Wabaunsee"
  },
  {
      "zipCode": 66424,
      "latitude": 39.699141,
      "longitude": -95.462469,
      "city": "Everest",
      "state": "KS",
      "county": "Brown"
  },
  {
      "zipCode": 66425,
      "latitude": 39.821847,
      "longitude": -95.727038,
      "city": "Fairview",
      "state": "KS",
      "county": "Brown"
  },
  {
      "zipCode": 66426,
      "latitude": 39.43982,
      "longitude": -96.506769,
      "city": "Fostoria",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66427,
      "latitude": 39.733313,
      "longitude": -96.522272,
      "city": "Frankfort",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66428,
      "latitude": 39.664216,
      "longitude": -95.930454,
      "city": "Goff",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66429,
      "latitude": 39.104047,
      "longitude": -95.539169,
      "city": "Grantville",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66431,
      "latitude": 38.789698,
      "longitude": -95.963421,
      "city": "Harveyville",
      "state": "KS",
      "county": "Wabaunsee"
  },
  {
      "zipCode": 66432,
      "latitude": 39.44655,
      "longitude": -96.37473,
      "city": "Havensville",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66434,
      "latitude": 39.791367,
      "longitude": -95.600405,
      "city": "Hiawatha",
      "state": "KS",
      "county": "Brown"
  },
  {
      "zipCode": 66436,
      "latitude": 39.481012,
      "longitude": -95.765586,
      "city": "Holton",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66438,
      "latitude": 39.848788,
      "longitude": -96.506345,
      "city": "Home",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66439,
      "latitude": 39.664076,
      "longitude": -95.527212,
      "city": "Horton",
      "state": "KS",
      "county": "Brown"
  },
  {
      "zipCode": 66440,
      "latitude": 39.305187,
      "longitude": -95.685374,
      "city": "Hoyt",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66441,
      "latitude": 39.026465,
      "longitude": -96.783359,
      "city": "Junction City",
      "state": "KS",
      "county": "Geary"
  },
  {
      "zipCode": 66442,
      "latitude": 39.113738,
      "longitude": -96.837821,
      "city": "Fort Riley",
      "state": "KS",
      "county": "Geary"
  },
  {
      "zipCode": 66449,
      "latitude": 39.401675,
      "longitude": -96.848345,
      "city": "Leonardville",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66450,
      "latitude": 39.261802,
      "longitude": -96.333203,
      "city": "Louisville",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66451,
      "latitude": 38.622848,
      "longitude": -95.656131,
      "city": "Lyndon",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66501,
      "latitude": 39.053494,
      "longitude": -96.237918,
      "city": "Mc Farland",
      "state": "KS",
      "county": "Wabaunsee"
  },
  {
      "zipCode": 66502,
      "latitude": 39.209375,
      "longitude": -96.564589,
      "city": "Manhattan",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66503,
      "latitude": 39.245797,
      "longitude": -96.633596,
      "city": "Manhattan",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66505,
      "latitude": 39.304937,
      "longitude": -96.675295,
      "city": "Manhattan",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66506,
      "latitude": 39.196032,
      "longitude": -96.583875,
      "city": "Manhattan",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66507,
      "latitude": 39.070808,
      "longitude": -96.131584,
      "city": "Maple Hill",
      "state": "KS",
      "county": "Wabaunsee"
  },
  {
      "zipCode": 66508,
      "latitude": 39.863544,
      "longitude": -96.617219,
      "city": "Marysville",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66509,
      "latitude": 39.312432,
      "longitude": -95.760939,
      "city": "Mayetta",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66510,
      "latitude": 38.499728,
      "longitude": -95.611239,
      "city": "Melvern",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66512,
      "latitude": 39.202791,
      "longitude": -95.530598,
      "city": "Meriden",
      "state": "KS",
      "county": "Jefferson"
  },
  {
      "zipCode": 66514,
      "latitude": 39.127855,
      "longitude": -96.874028,
      "city": "Milford",
      "state": "KS",
      "county": "Geary"
  },
  {
      "zipCode": 66515,
      "latitude": 39.923097,
      "longitude": -95.694761,
      "city": "Morrill",
      "state": "KS",
      "county": "Brown"
  },
  {
      "zipCode": 66516,
      "latitude": 39.601523,
      "longitude": -95.716332,
      "city": "Netawaka",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66517,
      "latitude": 39.115313,
      "longitude": -96.71009,
      "city": "Ogden",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66518,
      "latitude": 39.964838,
      "longitude": -96.551054,
      "city": "Oketo",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66520,
      "latitude": 39.411089,
      "longitude": -96.439613,
      "city": "Olsburg",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66521,
      "latitude": 39.442426,
      "longitude": -96.34428,
      "city": "Onaga",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66522,
      "latitude": 39.865912,
      "longitude": -95.93919,
      "city": "Oneida",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66523,
      "latitude": 38.629558,
      "longitude": -95.737112,
      "city": "Osage City",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66524,
      "latitude": 38.724549,
      "longitude": -95.574894,
      "city": "Overbrook",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66526,
      "latitude": 39.069282,
      "longitude": -96.167821,
      "city": "Paxico",
      "state": "KS",
      "county": "Wabaunsee"
  },
  {
      "zipCode": 66527,
      "latitude": 39.761448,
      "longitude": -95.635089,
      "city": "Powhattan",
      "state": "KS",
      "county": "Brown"
  },
  {
      "zipCode": 66528,
      "latitude": 38.634939,
      "longitude": -95.55521,
      "city": "Quenemo",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66531,
      "latitude": 39.300456,
      "longitude": -96.822207,
      "city": "Riley",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66532,
      "latitude": 39.818216,
      "longitude": -95.456658,
      "city": "Robinson",
      "state": "KS",
      "county": "Brown"
  },
  {
      "zipCode": 66533,
      "latitude": 39.158392,
      "longitude": -95.959031,
      "city": "Rossville",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66534,
      "latitude": 39.899941,
      "longitude": -95.802372,
      "city": "Sabetha",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66535,
      "latitude": 39.28971,
      "longitude": -96.435394,
      "city": "Saint George",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66536,
      "latitude": 39.256719,
      "longitude": -96.128154,
      "city": "Saint Marys",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66537,
      "latitude": 38.74633,
      "longitude": -95.675159,
      "city": "Scranton",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66538,
      "latitude": 39.847328,
      "longitude": -96.031629,
      "city": "Seneca",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66539,
      "latitude": 39.151302,
      "longitude": -95.86381,
      "city": "Silver Lake",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66540,
      "latitude": 39.478973,
      "longitude": -95.825452,
      "city": "Soldier",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66541,
      "latitude": 39.847244,
      "longitude": -96.510692,
      "city": "Summerfield",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66542,
      "latitude": 39.002048,
      "longitude": -95.628841,
      "city": "Tecumseh",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66543,
      "latitude": 38.641898,
      "longitude": -95.601158,
      "city": "Vassar",
      "state": "KS",
      "county": "Osage"
  },
  {
      "zipCode": 66544,
      "latitude": 39.689591,
      "longitude": -96.495257,
      "city": "Vermillion",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66546,
      "latitude": 38.905693,
      "longitude": -95.729868,
      "city": "Wakarusa",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66547,
      "latitude": 39.298416,
      "longitude": -96.341383,
      "city": "Wamego",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66548,
      "latitude": 39.763462,
      "longitude": -96.701387,
      "city": "Waterville",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66549,
      "latitude": 39.4138,
      "longitude": -96.437389,
      "city": "Westmoreland",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66550,
      "latitude": 39.636115,
      "longitude": -95.811894,
      "city": "Wetmore",
      "state": "KS",
      "county": "Nemaha"
  },
  {
      "zipCode": 66551,
      "latitude": 39.471738,
      "longitude": -96.257561,
      "city": "Wheaton",
      "state": "KS",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 66552,
      "latitude": 39.59411,
      "longitude": -95.639154,
      "city": "Whiting",
      "state": "KS",
      "county": "Jackson"
  },
  {
      "zipCode": 66554,
      "latitude": 39.465421,
      "longitude": -96.806367,
      "city": "Randolph",
      "state": "KS",
      "county": "Riley"
  },
  {
      "zipCode": 66555,
      "latitude": 39.783753,
      "longitude": -96.522806,
      "city": "Marysville",
      "state": "KS",
      "county": "Marshall"
  },
  {
      "zipCode": 66601,
      "latitude": 38.988075,
      "longitude": -95.780662,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66603,
      "latitude": 39.05215,
      "longitude": -95.675758,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66604,
      "latitude": 39.045716,
      "longitude": -95.758369,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66605,
      "latitude": 39.01115,
      "longitude": -95.592088,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66606,
      "latitude": 39.059349,
      "longitude": -95.72161,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66607,
      "latitude": 39.044349,
      "longitude": -95.630107,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66608,
      "latitude": 39.080299,
      "longitude": -95.645607,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66609,
      "latitude": 38.96474,
      "longitude": -95.615101,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66610,
      "latitude": 38.975466,
      "longitude": -95.817109,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66611,
      "latitude": 39.0152,
      "longitude": -95.695559,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66612,
      "latitude": 39.04045,
      "longitude": -95.679459,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66614,
      "latitude": 39.017305,
      "longitude": -95.779995,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66615,
      "latitude": 39.055155,
      "longitude": -95.854169,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66616,
      "latitude": 39.065477,
      "longitude": -95.626357,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66617,
      "latitude": 39.15275,
      "longitude": -95.793795,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66618,
      "latitude": 39.139907,
      "longitude": -95.801975,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66619,
      "latitude": 38.953556,
      "longitude": -95.723636,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66620,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66621,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66622,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66624,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66625,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66626,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66628,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66629,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66634,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66636,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66637,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66638,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66642,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66647,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66652,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66653,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66658,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66667,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66675,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66683,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66686,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66692,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66699,
      "latitude": 39.042939,
      "longitude": -95.769657,
      "city": "Topeka",
      "state": "KS",
      "county": "Shawnee"
  },
  {
      "zipCode": 66701,
      "latitude": 37.821629,
      "longitude": -94.714793,
      "city": "Fort Scott",
      "state": "KS",
      "county": "Bourbon"
  },
  {
      "zipCode": 66710,
      "latitude": 37.525023,
      "longitude": -95.661801,
      "city": "Altoona",
      "state": "KS",
      "county": "Wilson"
  },
  {
      "zipCode": 66711,
      "latitude": 37.601241,
      "longitude": -94.697518,
      "city": "Arcadia",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66712,
      "latitude": 37.557295,
      "longitude": -94.694906,
      "city": "Arma",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66713,
      "latitude": 37.139184,
      "longitude": -94.789814,
      "city": "Baxter Springs",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66714,
      "latitude": 37.611817,
      "longitude": -95.703812,
      "city": "Benedict",
      "state": "KS",
      "county": "Wilson"
  },
  {
      "zipCode": 66716,
      "latitude": 37.874455,
      "longitude": -95.02624,
      "city": "Bronson",
      "state": "KS",
      "county": "Bourbon"
  },
  {
      "zipCode": 66717,
      "latitude": 37.614292,
      "longitude": -95.703647,
      "city": "Buffalo",
      "state": "KS",
      "county": "Wilson"
  },
  {
      "zipCode": 66720,
      "latitude": 37.609218,
      "longitude": -95.416422,
      "city": "Chanute",
      "state": "KS",
      "county": "Neosho"
  },
  {
      "zipCode": 66724,
      "latitude": 37.368216,
      "longitude": -94.832276,
      "city": "Cherokee",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66725,
      "latitude": 37.169887,
      "longitude": -94.889857,
      "city": "Columbus",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66727,
      "latitude": 37.559151,
      "longitude": -95.743835,
      "city": "Coyville",
      "state": "KS",
      "county": "Wilson"
  },
  {
      "zipCode": 66728,
      "latitude": 37.16944,
      "longitude": -94.704133,
      "city": "Crestline",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66732,
      "latitude": 37.838456,
      "longitude": -95.215073,
      "city": "Elsmore",
      "state": "KS",
      "county": "Allen"
  },
  {
      "zipCode": 66733,
      "latitude": 37.580055,
      "longitude": -95.29892,
      "city": "Erie",
      "state": "KS",
      "county": "Neosho"
  },
  {
      "zipCode": 66734,
      "latitude": 37.624035,
      "longitude": -94.798259,
      "city": "Farlington",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66735,
      "latitude": 37.437747,
      "longitude": -94.683265,
      "city": "Franklin",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66736,
      "latitude": 37.571672,
      "longitude": -95.748418,
      "city": "Fredonia",
      "state": "KS",
      "county": "Wilson"
  },
  {
      "zipCode": 66738,
      "latitude": 38.01017,
      "longitude": -94.720191,
      "city": "Fulton",
      "state": "KS",
      "county": "Bourbon"
  },
  {
      "zipCode": 66739,
      "latitude": 37.15456,
      "longitude": -94.833896,
      "city": "Galena",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66740,
      "latitude": 37.472432,
      "longitude": -95.370691,
      "city": "Galesburg",
      "state": "KS",
      "county": "Neosho"
  },
  {
      "zipCode": 66741,
      "latitude": 37.855416,
      "longitude": -94.850996,
      "city": "Garland",
      "state": "KS",
      "county": "Bourbon"
  },
  {
      "zipCode": 66742,
      "latitude": 37.923162,
      "longitude": -95.346697,
      "city": "Gas",
      "state": "KS",
      "county": "Allen"
  },
  {
      "zipCode": 66743,
      "latitude": 37.471611,
      "longitude": -94.881385,
      "city": "Girard",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66746,
      "latitude": 37.638333,
      "longitude": -94.942113,
      "city": "Hepler",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66748,
      "latitude": 37.829342,
      "longitude": -95.34549,
      "city": "Humboldt",
      "state": "KS",
      "county": "Allen"
  },
  {
      "zipCode": 66749,
      "latitude": 37.885757,
      "longitude": -95.391057,
      "city": "Iola",
      "state": "KS",
      "county": "Allen"
  },
  {
      "zipCode": 66751,
      "latitude": 37.914528,
      "longitude": -95.255758,
      "city": "La Harpe",
      "state": "KS",
      "county": "Allen"
  },
  {
      "zipCode": 66753,
      "latitude": 37.448486,
      "longitude": -94.856644,
      "city": "Mc Cune",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66754,
      "latitude": 37.855416,
      "longitude": -94.850996,
      "city": "Mapleton",
      "state": "KS",
      "county": "Bourbon"
  },
  {
      "zipCode": 66755,
      "latitude": 37.914394,
      "longitude": -95.197671,
      "city": "Moran",
      "state": "KS",
      "county": "Allen"
  },
  {
      "zipCode": 66756,
      "latitude": 37.54259,
      "longitude": -94.661105,
      "city": "Mulberry",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66757,
      "latitude": 37.56084,
      "longitude": -95.668445,
      "city": "Neodesha",
      "state": "KS",
      "county": "Wilson"
  },
  {
      "zipCode": 66758,
      "latitude": 37.998206,
      "longitude": -95.622106,
      "city": "Neosho Falls",
      "state": "KS",
      "county": "Woodson"
  },
  {
      "zipCode": 66759,
      "latitude": 37.568982,
      "longitude": -95.937861,
      "city": "New Albany",
      "state": "KS",
      "county": "Wilson"
  },
  {
      "zipCode": 66760,
      "latitude": 37.34199,
      "longitude": -94.619885,
      "city": "Opolis",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66761,
      "latitude": 37.914531,
      "longitude": -95.56937,
      "city": "Piqua",
      "state": "KS",
      "county": "Woodson"
  },
  {
      "zipCode": 66762,
      "latitude": 37.448044,
      "longitude": -94.819212,
      "city": "Pittsburg",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66763,
      "latitude": 37.446615,
      "longitude": -94.691566,
      "city": "Frontenac",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66767,
      "latitude": 38.178868,
      "longitude": -94.705448,
      "city": "Prescott",
      "state": "KS",
      "county": "Linn"
  },
  {
      "zipCode": 66769,
      "latitude": 37.836666,
      "longitude": -94.880482,
      "city": "Redfield",
      "state": "KS",
      "county": "Bourbon"
  },
  {
      "zipCode": 66770,
      "latitude": 37.073183,
      "longitude": -94.758958,
      "city": "Riverton",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66771,
      "latitude": 37.545144,
      "longitude": -95.183687,
      "city": "Saint Paul",
      "state": "KS",
      "county": "Neosho"
  },
  {
      "zipCode": 66772,
      "latitude": 37.768978,
      "longitude": -95.24453,
      "city": "Savonburg",
      "state": "KS",
      "county": "Allen"
  },
  {
      "zipCode": 66773,
      "latitude": 37.280945,
      "longitude": -94.809198,
      "city": "Scammon",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66775,
      "latitude": 37.675371,
      "longitude": -95.178823,
      "city": "Stark",
      "state": "KS",
      "county": "Neosho"
  },
  {
      "zipCode": 66776,
      "latitude": 37.480322,
      "longitude": -95.452585,
      "city": "Thayer",
      "state": "KS",
      "county": "Neosho"
  },
  {
      "zipCode": 66777,
      "latitude": 37.814423,
      "longitude": -95.88888,
      "city": "Toronto",
      "state": "KS",
      "county": "Woodson"
  },
  {
      "zipCode": 66778,
      "latitude": 37.14002,
      "longitude": -94.881789,
      "city": "Treece",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66779,
      "latitude": 37.847185,
      "longitude": -94.975786,
      "city": "Uniontown",
      "state": "KS",
      "county": "Bourbon"
  },
  {
      "zipCode": 66780,
      "latitude": 37.57292,
      "longitude": -94.856834,
      "city": "Walnut",
      "state": "KS",
      "county": "Crawford"
  },
  {
      "zipCode": 66781,
      "latitude": 37.258838,
      "longitude": -94.734382,
      "city": "Weir",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66782,
      "latitude": 37.285147,
      "longitude": -94.926124,
      "city": "West Mineral",
      "state": "KS",
      "county": "Cherokee"
  },
  {
      "zipCode": 66783,
      "latitude": 37.884741,
      "longitude": -95.723555,
      "city": "Yates Center",
      "state": "KS",
      "county": "Woodson"
  },
  {
      "zipCode": 66801,
      "latitude": 38.434153,
      "longitude": -96.152766,
      "city": "Emporia",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66830,
      "latitude": 38.608394,
      "longitude": -96.108283,
      "city": "Admire",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66833,
      "latitude": 38.652011,
      "longitude": -96.173461,
      "city": "Allen",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66834,
      "latitude": 38.864507,
      "longitude": -96.488112,
      "city": "Alta Vista",
      "state": "KS",
      "county": "Wabaunsee"
  },
  {
      "zipCode": 66835,
      "latitude": 38.556174,
      "longitude": -96.261884,
      "city": "Americus",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66838,
      "latitude": 38.565758,
      "longitude": -96.801336,
      "city": "Burdick",
      "state": "KS",
      "county": "Morris"
  },
  {
      "zipCode": 66839,
      "latitude": 38.236316,
      "longitude": -95.733598,
      "city": "Burlington",
      "state": "KS",
      "county": "Coffey"
  },
  {
      "zipCode": 66840,
      "latitude": 38.089221,
      "longitude": -96.888518,
      "city": "Burns",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 66842,
      "latitude": 37.995545,
      "longitude": -96.661017,
      "city": "Cassoday",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 66843,
      "latitude": 38.260042,
      "longitude": -96.822766,
      "city": "Cedar Point",
      "state": "KS",
      "county": "Chase"
  },
  {
      "zipCode": 66845,
      "latitude": 38.37674,
      "longitude": -96.54945,
      "city": "Cottonwood Falls",
      "state": "KS",
      "county": "Chase"
  },
  {
      "zipCode": 66846,
      "latitude": 38.69588,
      "longitude": -96.5469,
      "city": "Council Grove",
      "state": "KS",
      "county": "Morris"
  },
  {
      "zipCode": 66849,
      "latitude": 38.826679,
      "longitude": -96.579805,
      "city": "Dwight",
      "state": "KS",
      "county": "Morris"
  },
  {
      "zipCode": 66850,
      "latitude": 38.372982,
      "longitude": -96.646064,
      "city": "Elmdale",
      "state": "KS",
      "county": "Chase"
  },
  {
      "zipCode": 66851,
      "latitude": 38.293353,
      "longitude": -96.974518,
      "city": "Florence",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 66852,
      "latitude": 38.105539,
      "longitude": -95.794536,
      "city": "Gridley",
      "state": "KS",
      "county": "Coffey"
  },
  {
      "zipCode": 66853,
      "latitude": 37.980393,
      "longitude": -96.199508,
      "city": "Hamilton",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 66854,
      "latitude": 38.345691,
      "longitude": -96.103771,
      "city": "Hartford",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66855,
      "latitude": 37.888276,
      "longitude": -96.242059,
      "city": "Lamont",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 66856,
      "latitude": 38.237155,
      "longitude": -95.790281,
      "city": "Lebo",
      "state": "KS",
      "county": "Coffey"
  },
  {
      "zipCode": 66857,
      "latitude": 38.11486,
      "longitude": -95.685599,
      "city": "Le Roy",
      "state": "KS",
      "county": "Coffey"
  },
  {
      "zipCode": 66858,
      "latitude": 38.493293,
      "longitude": -96.96264,
      "city": "Lincolnville",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 66859,
      "latitude": 38.566392,
      "longitude": -96.967811,
      "city": "Lost Springs",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 66860,
      "latitude": 38.061943,
      "longitude": -96.144795,
      "city": "Madison",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 66861,
      "latitude": 38.284561,
      "longitude": -96.965255,
      "city": "Marion",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 66862,
      "latitude": 38.303962,
      "longitude": -96.597442,
      "city": "Matfield Green",
      "state": "KS",
      "county": "Chase"
  },
  {
      "zipCode": 66863,
      "latitude": 37.888276,
      "longitude": -96.242059,
      "city": "Neal",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 66864,
      "latitude": 38.413332,
      "longitude": -96.103875,
      "city": "Neosho Rapids",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66865,
      "latitude": 38.354006,
      "longitude": -96.1903,
      "city": "Olpe",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66866,
      "latitude": 38.134801,
      "longitude": -97.003776,
      "city": "Peabody",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 66868,
      "latitude": 38.533232,
      "longitude": -96.01436,
      "city": "Reading",
      "state": "KS",
      "county": "Lyon"
  },
  {
      "zipCode": 66869,
      "latitude": 38.390683,
      "longitude": -96.538737,
      "city": "Strong City",
      "state": "KS",
      "county": "Chase"
  },
  {
      "zipCode": 66870,
      "latitude": 37.978282,
      "longitude": -96.012585,
      "city": "Virgil",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 66871,
      "latitude": 38.346908,
      "longitude": -95.628088,
      "city": "Waverly",
      "state": "KS",
      "county": "Coffey"
  },
  {
      "zipCode": 66872,
      "latitude": 38.783116,
      "longitude": -96.779581,
      "city": "White City",
      "state": "KS",
      "county": "Morris"
  },
  {
      "zipCode": 66873,
      "latitude": 38.682093,
      "longitude": -96.663865,
      "city": "Wilsey",
      "state": "KS",
      "county": "Morris"
  },
  {
      "zipCode": 66901,
      "latitude": 39.551634,
      "longitude": -97.656764,
      "city": "Concordia",
      "state": "KS",
      "county": "Cloud"
  },
  {
      "zipCode": 66930,
      "latitude": 39.70438,
      "longitude": -97.446521,
      "city": "Agenda",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66932,
      "latitude": 39.766784,
      "longitude": -98.919297,
      "city": "Athol",
      "state": "KS",
      "county": "Smith"
  },
  {
      "zipCode": 66933,
      "latitude": 39.681367,
      "longitude": -96.862609,
      "city": "Barnes",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66935,
      "latitude": 39.836218,
      "longitude": -97.601461,
      "city": "Belleville",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66936,
      "latitude": 39.784792,
      "longitude": -98.218469,
      "city": "Burr Oak",
      "state": "KS",
      "county": "Jewell"
  },
  {
      "zipCode": 66937,
      "latitude": 39.655509,
      "longitude": -97.17341,
      "city": "Clifton",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66938,
      "latitude": 39.59127,
      "longitude": -97.398639,
      "city": "Clyde",
      "state": "KS",
      "county": "Cloud"
  },
  {
      "zipCode": 66939,
      "latitude": 39.746555,
      "longitude": -97.666524,
      "city": "Courtland",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66940,
      "latitude": 39.801787,
      "longitude": -97.458269,
      "city": "Cuba",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66941,
      "latitude": 39.820964,
      "longitude": -98.433369,
      "city": "Esbon",
      "state": "KS",
      "county": "Jewell"
  },
  {
      "zipCode": 66942,
      "latitude": 39.778286,
      "longitude": -97.993161,
      "city": "Formoso",
      "state": "KS",
      "county": "Jewell"
  },
  {
      "zipCode": 66943,
      "latitude": 39.687997,
      "longitude": -96.975097,
      "city": "Greenleaf",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66944,
      "latitude": 39.83551,
      "longitude": -97.269445,
      "city": "Haddam",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66945,
      "latitude": 39.867079,
      "longitude": -96.896534,
      "city": "Hanover",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66946,
      "latitude": 39.841523,
      "longitude": -96.966275,
      "city": "Hollenberg",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66948,
      "latitude": 39.598963,
      "longitude": -97.86117,
      "city": "Jamestown",
      "state": "KS",
      "county": "Cloud"
  },
  {
      "zipCode": 66949,
      "latitude": 39.671874,
      "longitude": -98.147215,
      "city": "Jewell",
      "state": "KS",
      "county": "Jewell"
  },
  {
      "zipCode": 66951,
      "latitude": 39.783621,
      "longitude": -98.796287,
      "city": "Kensington",
      "state": "KS",
      "county": "Smith"
  },
  {
      "zipCode": 66952,
      "latitude": 39.807699,
      "longitude": -98.556016,
      "city": "Lebanon",
      "state": "KS",
      "county": "Smith"
  },
  {
      "zipCode": 66953,
      "latitude": 39.784861,
      "longitude": -97.168514,
      "city": "Linn",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66955,
      "latitude": 39.841669,
      "longitude": -97.22614,
      "city": "Mahaska",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66956,
      "latitude": 39.787529,
      "longitude": -98.20595,
      "city": "Mankato",
      "state": "KS",
      "county": "Jewell"
  },
  {
      "zipCode": 66958,
      "latitude": 39.885808,
      "longitude": -97.172061,
      "city": "Morrowville",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66959,
      "latitude": 39.860279,
      "longitude": -97.498488,
      "city": "Munden",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66960,
      "latitude": 39.960731,
      "longitude": -97.428585,
      "city": "Narka",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66961,
      "latitude": 39.827894,
      "longitude": -97.650902,
      "city": "Norway",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66962,
      "latitude": 39.610229,
      "longitude": -97.12993,
      "city": "Palmer",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66963,
      "latitude": 39.643012,
      "longitude": -98.044991,
      "city": "Randall",
      "state": "KS",
      "county": "Jewell"
  },
  {
      "zipCode": 66964,
      "latitude": 39.875728,
      "longitude": -97.628516,
      "city": "Republic",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66966,
      "latitude": 39.794591,
      "longitude": -97.783734,
      "city": "Scandia",
      "state": "KS",
      "county": "Republic"
  },
  {
      "zipCode": 66967,
      "latitude": 39.788577,
      "longitude": -98.673356,
      "city": "Smith Center",
      "state": "KS",
      "county": "Smith"
  },
  {
      "zipCode": 66968,
      "latitude": 39.781107,
      "longitude": -97.054286,
      "city": "Washington",
      "state": "KS",
      "county": "Washington"
  },
  {
      "zipCode": 66970,
      "latitude": 39.935373,
      "longitude": -98.035371,
      "city": "Webber",
      "state": "KS",
      "county": "Jewell"
  },
  {
      "zipCode": 67001,
      "latitude": 37.779733,
      "longitude": -97.636619,
      "city": "Andale",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67002,
      "latitude": 37.701532,
      "longitude": -97.098431,
      "city": "Andover",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67003,
      "latitude": 37.111488,
      "longitude": -98.048121,
      "city": "Anthony",
      "state": "KS",
      "county": "Harper"
  },
  {
      "zipCode": 67004,
      "latitude": 37.248959,
      "longitude": -97.690887,
      "city": "Argonia",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67005,
      "latitude": 37.149582,
      "longitude": -96.904215,
      "city": "Arkansas City",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67008,
      "latitude": 37.378279,
      "longitude": -96.720974,
      "city": "Atlanta",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67009,
      "latitude": 37.224885,
      "longitude": -98.210087,
      "city": "Attica",
      "state": "KS",
      "county": "Harper"
  },
  {
      "zipCode": 67010,
      "latitude": 37.674347,
      "longitude": -96.970128,
      "city": "Augusta",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67012,
      "latitude": 37.658007,
      "longitude": -96.532862,
      "city": "Beaumont",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67013,
      "latitude": 37.359755,
      "longitude": -97.287694,
      "city": "Belle Plaine",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67016,
      "latitude": 37.886596,
      "longitude": -97.516631,
      "city": "Bentley",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67017,
      "latitude": 37.831767,
      "longitude": -97.097363,
      "city": "Benton",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67018,
      "latitude": 37.062263,
      "longitude": -97.865887,
      "city": "Bluff City",
      "state": "KS",
      "county": "Harper"
  },
  {
      "zipCode": 67019,
      "latitude": 37.37062,
      "longitude": -96.759983,
      "city": "Burden",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67020,
      "latitude": 38.021434,
      "longitude": -97.537188,
      "city": "Burrton",
      "state": "KS",
      "county": "Harvey"
  },
  {
      "zipCode": 67021,
      "latitude": 37.777955,
      "longitude": -98.931119,
      "city": "Byers",
      "state": "KS",
      "county": "Pratt"
  },
  {
      "zipCode": 67022,
      "latitude": 37.133257,
      "longitude": -97.601664,
      "city": "Caldwell",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67023,
      "latitude": 37.309434,
      "longitude": -96.717001,
      "city": "Cambridge",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67024,
      "latitude": 37.130234,
      "longitude": -96.444127,
      "city": "Cedar Vale",
      "state": "KS",
      "county": "Chautauqua"
  },
  {
      "zipCode": 67025,
      "latitude": 37.633711,
      "longitude": -97.73513,
      "city": "Cheney",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67026,
      "latitude": 37.548282,
      "longitude": -97.488432,
      "city": "Clearwater",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67028,
      "latitude": 37.638236,
      "longitude": -98.889244,
      "city": "Coats",
      "state": "KS",
      "county": "Pratt"
  },
  {
      "zipCode": 67029,
      "latitude": 37.25486,
      "longitude": -99.322574,
      "city": "Coldwater",
      "state": "KS",
      "county": "Comanche"
  },
  {
      "zipCode": 67030,
      "latitude": 37.77081,
      "longitude": -97.545886,
      "city": "Colwich",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67031,
      "latitude": 37.394301,
      "longitude": -97.658248,
      "city": "Conway Springs",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67035,
      "latitude": 37.630922,
      "longitude": -98.35639,
      "city": "Cunningham",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67036,
      "latitude": 37.299891,
      "longitude": -97.86969,
      "city": "Danville",
      "state": "KS",
      "county": "Harper"
  },
  {
      "zipCode": 67037,
      "latitude": 37.566129,
      "longitude": -97.228475,
      "city": "Derby",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67038,
      "latitude": 37.178743,
      "longitude": -96.715776,
      "city": "Dexter",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67039,
      "latitude": 37.535867,
      "longitude": -96.998807,
      "city": "Douglass",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67041,
      "latitude": 38.054538,
      "longitude": -97.128356,
      "city": "Elbing",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67042,
      "latitude": 37.830491,
      "longitude": -96.865969,
      "city": "El Dorado",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67045,
      "latitude": 37.755154,
      "longitude": -96.170019,
      "city": "Eureka",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 67047,
      "latitude": 37.607625,
      "longitude": -96.029221,
      "city": "Fall River",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 67049,
      "latitude": 37.175377,
      "longitude": -97.857243,
      "city": "Freeport",
      "state": "KS",
      "county": "Harper"
  },
  {
      "zipCode": 67050,
      "latitude": 37.674242,
      "longitude": -97.654917,
      "city": "Garden Plain",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67051,
      "latitude": 37.139313,
      "longitude": -97.458635,
      "city": "Geuda Springs",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67052,
      "latitude": 37.663432,
      "longitude": -97.579616,
      "city": "Goddard",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67053,
      "latitude": 38.219081,
      "longitude": -97.325769,
      "city": "Goessel",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 67054,
      "latitude": 37.607534,
      "longitude": -99.204968,
      "city": "Greensburg",
      "state": "KS",
      "county": "Kiowa"
  },
  {
      "zipCode": 67055,
      "latitude": 37.78335,
      "longitude": -97.205419,
      "city": "Greenwich",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67056,
      "latitude": 38.02428,
      "longitude": -97.509713,
      "city": "Halstead",
      "state": "KS",
      "county": "Harvey"
  },
  {
      "zipCode": 67057,
      "latitude": 37.076952,
      "longitude": -98.751916,
      "city": "Hardtner",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67058,
      "latitude": 37.291318,
      "longitude": -98.038455,
      "city": "Harper",
      "state": "KS",
      "county": "Harper"
  },
  {
      "zipCode": 67059,
      "latitude": 37.612557,
      "longitude": -99.192552,
      "city": "Haviland",
      "state": "KS",
      "county": "Kiowa"
  },
  {
      "zipCode": 67060,
      "latitude": 37.542404,
      "longitude": -97.36324,
      "city": "Haysville",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67061,
      "latitude": 37.114569,
      "longitude": -98.410166,
      "city": "Hazelton",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67062,
      "latitude": 38.137404,
      "longitude": -97.443222,
      "city": "Hesston",
      "state": "KS",
      "county": "Harvey"
  },
  {
      "zipCode": 67063,
      "latitude": 38.347988,
      "longitude": -97.205656,
      "city": "Hillsboro",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 67065,
      "latitude": 37.427542,
      "longitude": -98.551564,
      "city": "Isabel",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67066,
      "latitude": 37.75535,
      "longitude": -98.756921,
      "city": "Iuka",
      "state": "KS",
      "county": "Pratt"
  },
  {
      "zipCode": 67067,
      "latitude": 37.810488,
      "longitude": -97.259353,
      "city": "Kechi",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67068,
      "latitude": 37.596364,
      "longitude": -98.130432,
      "city": "Kingman",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67070,
      "latitude": 37.138561,
      "longitude": -98.503736,
      "city": "Kiowa",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67071,
      "latitude": 37.264177,
      "longitude": -98.818915,
      "city": "Lake City",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67072,
      "latitude": 37.606889,
      "longitude": -96.658181,
      "city": "Latham",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67073,
      "latitude": 38.372882,
      "longitude": -97.302858,
      "city": "Lehigh",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 67074,
      "latitude": 37.66475,
      "longitude": -96.748795,
      "city": "Leon",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67101,
      "latitude": 37.789561,
      "longitude": -97.486701,
      "city": "Maize",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67102,
      "latitude": 37.2377,
      "longitude": -96.838911,
      "city": "Maple City",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67103,
      "latitude": 37.268787,
      "longitude": -97.556978,
      "city": "Mayfield",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67104,
      "latitude": 37.291739,
      "longitude": -98.620996,
      "city": "Medicine Lodge",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67105,
      "latitude": 37.227503,
      "longitude": -97.674141,
      "city": "Milan",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67106,
      "latitude": 37.430408,
      "longitude": -97.742966,
      "city": "Milton",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67107,
      "latitude": 38.217149,
      "longitude": -97.523327,
      "city": "Moundridge",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67108,
      "latitude": 37.823852,
      "longitude": -97.637834,
      "city": "Mount Hope",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67109,
      "latitude": 37.593142,
      "longitude": -99.387747,
      "city": "Mullinville",
      "state": "KS",
      "county": "Kiowa"
  },
  {
      "zipCode": 67110,
      "latitude": 37.3732,
      "longitude": -97.441654,
      "city": "Mulvane",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67111,
      "latitude": 37.619803,
      "longitude": -97.963093,
      "city": "Murdock",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67112,
      "latitude": 37.471011,
      "longitude": -98.391558,
      "city": "Nashville",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67114,
      "latitude": 38.044857,
      "longitude": -97.317279,
      "city": "Newton",
      "state": "KS",
      "county": "Harvey"
  },
  {
      "zipCode": 67117,
      "latitude": 38.048707,
      "longitude": -97.355813,
      "city": "North Newton",
      "state": "KS",
      "county": "Harvey"
  },
  {
      "zipCode": 67118,
      "latitude": 37.486261,
      "longitude": -97.923919,
      "city": "Norwich",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67119,
      "latitude": 37.231595,
      "longitude": -97.212224,
      "city": "Oxford",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67120,
      "latitude": 37.50541,
      "longitude": -97.340797,
      "city": "Peck",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67122,
      "latitude": 37.888276,
      "longitude": -96.242059,
      "city": "Piedmont",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 67123,
      "latitude": 37.937595,
      "longitude": -97.038364,
      "city": "Potwin",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67124,
      "latitude": 37.63857,
      "longitude": -98.758512,
      "city": "Pratt",
      "state": "KS",
      "county": "Pratt"
  },
  {
      "zipCode": 67127,
      "latitude": 37.200135,
      "longitude": -99.482888,
      "city": "Protection",
      "state": "KS",
      "county": "Comanche"
  },
  {
      "zipCode": 67128,
      "latitude": 37.436647,
      "longitude": -97.995064,
      "city": "Rago",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67131,
      "latitude": 37.441278,
      "longitude": -97.005906,
      "city": "Rock",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67132,
      "latitude": 37.828375,
      "longitude": -96.80597,
      "city": "Rosalia",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67133,
      "latitude": 37.56297,
      "longitude": -97.090019,
      "city": "Rose Hill",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67134,
      "latitude": 37.509541,
      "longitude": -98.647923,
      "city": "Sawyer",
      "state": "KS",
      "county": "Pratt"
  },
  {
      "zipCode": 67135,
      "latitude": 37.955789,
      "longitude": -97.473214,
      "city": "Sedgwick",
      "state": "KS",
      "county": "Harvey"
  },
  {
      "zipCode": 67137,
      "latitude": 37.61705,
      "longitude": -96.225157,
      "city": "Severy",
      "state": "KS",
      "county": "Greenwood"
  },
  {
      "zipCode": 67138,
      "latitude": 37.224446,
      "longitude": -98.42233,
      "city": "Sharon",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67140,
      "latitude": 37.136607,
      "longitude": -97.329247,
      "city": "South Haven",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67142,
      "latitude": 37.491201,
      "longitude": -98.209956,
      "city": "Spivey",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67143,
      "latitude": 37.357385,
      "longitude": -98.91273,
      "city": "Sun City",
      "state": "KS",
      "county": "Barber"
  },
  {
      "zipCode": 67144,
      "latitude": 37.905383,
      "longitude": -96.85903,
      "city": "Towanda",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67146,
      "latitude": 37.387823,
      "longitude": -97.117926,
      "city": "Udall",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67147,
      "latitude": 37.845078,
      "longitude": -97.335537,
      "city": "Valley Center",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67149,
      "latitude": 37.583241,
      "longitude": -97.519347,
      "city": "Viola",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67150,
      "latitude": 37.140233,
      "longitude": -98.238653,
      "city": "Waldron",
      "state": "KS",
      "county": "Harper"
  },
  {
      "zipCode": 67151,
      "latitude": 38.123189,
      "longitude": -97.244464,
      "city": "Walton",
      "state": "KS",
      "county": "Harvey"
  },
  {
      "zipCode": 67152,
      "latitude": 37.280877,
      "longitude": -97.42223,
      "city": "Wellington",
      "state": "KS",
      "county": "Sumner"
  },
  {
      "zipCode": 67154,
      "latitude": 37.977983,
      "longitude": -97.088361,
      "city": "Whitewater",
      "state": "KS",
      "county": "Butler"
  },
  {
      "zipCode": 67155,
      "latitude": 37.191758,
      "longitude": -99.272769,
      "city": "Wilmore",
      "state": "KS",
      "county": "Comanche"
  },
  {
      "zipCode": 67156,
      "latitude": 37.232126,
      "longitude": -96.996334,
      "city": "Winfield",
      "state": "KS",
      "county": "Cowley"
  },
  {
      "zipCode": 67159,
      "latitude": 37.449631,
      "longitude": -98.3057,
      "city": "Zenda",
      "state": "KS",
      "county": "Kingman"
  },
  {
      "zipCode": 67201,
      "latitude": 37.651974,
      "longitude": -97.258997,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67202,
      "latitude": 37.686189,
      "longitude": -97.334713,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67203,
      "latitude": 37.706514,
      "longitude": -97.395351,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67204,
      "latitude": 37.787925,
      "longitude": -97.381233,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67205,
      "latitude": 37.773675,
      "longitude": -97.444035,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67206,
      "latitude": 37.613954,
      "longitude": -97.342752,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67207,
      "latitude": 37.671048,
      "longitude": -97.217907,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67208,
      "latitude": 37.74451,
      "longitude": -97.276421,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67209,
      "latitude": 37.666579,
      "longitude": -97.435012,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67210,
      "latitude": 37.615901,
      "longitude": -97.247359,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67211,
      "latitude": 37.661,
      "longitude": -97.312573,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67212,
      "latitude": 37.701795,
      "longitude": -97.435443,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67213,
      "latitude": 37.668005,
      "longitude": -97.36497,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67214,
      "latitude": 37.706143,
      "longitude": -97.319593,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67215,
      "latitude": 37.626635,
      "longitude": -97.425673,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67216,
      "latitude": 37.605237,
      "longitude": -97.314573,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67217,
      "latitude": 37.643576,
      "longitude": -97.392906,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67218,
      "latitude": 37.668282,
      "longitude": -97.280784,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67219,
      "latitude": 37.773805,
      "longitude": -97.265034,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67220,
      "latitude": 37.766663,
      "longitude": -97.280524,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67221,
      "latitude": 37.628158,
      "longitude": -97.26626,
      "city": "Mcconnell Afb",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67223,
      "latitude": 37.73674,
      "longitude": -97.499,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67226,
      "latitude": 37.774074,
      "longitude": -97.212044,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67227,
      "latitude": 37.62812,
      "longitude": -97.491566,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67228,
      "latitude": 37.774174,
      "longitude": -97.171098,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67230,
      "latitude": 37.679502,
      "longitude": -97.171332,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67231,
      "latitude": 37.556692,
      "longitude": -97.410154,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67232,
      "latitude": 37.639491,
      "longitude": -97.171372,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67233,
      "latitude": 37.542472,
      "longitude": -97.351987,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67235,
      "latitude": 37.714891,
      "longitude": -97.499024,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67236,
      "latitude": 37.542182,
      "longitude": -97.287134,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67251,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67256,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67257,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67259,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67260,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67275,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67276,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67277,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67278,
      "latitude": 37.693588,
      "longitude": -97.480419,
      "city": "Wichita",
      "state": "KS",
      "county": "Sedgwick"
  },
  {
      "zipCode": 67301,
      "latitude": 37.180636,
      "longitude": -95.756197,
      "city": "Independence",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67330,
      "latitude": 37.222616,
      "longitude": -95.360819,
      "city": "Altamont",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67332,
      "latitude": 37.045463,
      "longitude": -95.229916,
      "city": "Bartlett",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67333,
      "latitude": 37.048046,
      "longitude": -95.884949,
      "city": "Caney",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67334,
      "latitude": 37.026243,
      "longitude": -96.178281,
      "city": "Chautauqua",
      "state": "KS",
      "county": "Chautauqua"
  },
  {
      "zipCode": 67335,
      "latitude": 37.167997,
      "longitude": -95.732508,
      "city": "Cherryvale",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67336,
      "latitude": 37.04542,
      "longitude": -95.218047,
      "city": "Chetopa",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67337,
      "latitude": 37.146267,
      "longitude": -95.748313,
      "city": "Coffeyville",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67340,
      "latitude": 37.043836,
      "longitude": -95.732624,
      "city": "Dearing",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67341,
      "latitude": 37.220501,
      "longitude": -95.434853,
      "city": "Dennis",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67342,
      "latitude": 37.071835,
      "longitude": -95.347225,
      "city": "Edna",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67344,
      "latitude": 37.288869,
      "longitude": -95.908001,
      "city": "Elk City",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67345,
      "latitude": 37.383228,
      "longitude": -96.272149,
      "city": "Elk Falls",
      "state": "KS",
      "county": "Elk"
  },
  {
      "zipCode": 67346,
      "latitude": 37.406927,
      "longitude": -96.384362,
      "city": "Grenola",
      "state": "KS",
      "county": "Elk"
  },
  {
      "zipCode": 67347,
      "latitude": 37.092548,
      "longitude": -95.94175,
      "city": "Havana",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67349,
      "latitude": 37.419933,
      "longitude": -96.278611,
      "city": "Howard",
      "state": "KS",
      "county": "Elk"
  },
  {
      "zipCode": 67351,
      "latitude": 37.09922,
      "longitude": -95.583588,
      "city": "Liberty",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67352,
      "latitude": 37.374756,
      "longitude": -96.13358,
      "city": "Longton",
      "state": "KS",
      "county": "Elk"
  },
  {
      "zipCode": 67353,
      "latitude": 37.357438,
      "longitude": -96.373849,
      "city": "Moline",
      "state": "KS",
      "county": "Elk"
  },
  {
      "zipCode": 67354,
      "latitude": 37.215113,
      "longitude": -95.422081,
      "city": "Mound Valley",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67355,
      "latitude": 37.037043,
      "longitude": -96.012058,
      "city": "Niotaze",
      "state": "KS",
      "county": "Chautauqua"
  },
  {
      "zipCode": 67356,
      "latitude": 37.18919,
      "longitude": -95.242611,
      "city": "Oswego",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67357,
      "latitude": 37.217756,
      "longitude": -95.266407,
      "city": "Parsons",
      "state": "KS",
      "county": "Labette"
  },
  {
      "zipCode": 67360,
      "latitude": 37.048401,
      "longitude": -96.07768,
      "city": "Peru",
      "state": "KS",
      "county": "Chautauqua"
  },
  {
      "zipCode": 67361,
      "latitude": 37.134351,
      "longitude": -96.219212,
      "city": "Sedan",
      "state": "KS",
      "county": "Chautauqua"
  },
  {
      "zipCode": 67363,
      "latitude": 37.328119,
      "longitude": -95.71697,
      "city": "Sycamore",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67364,
      "latitude": 37.036984,
      "longitude": -95.823721,
      "city": "Tyro",
      "state": "KS",
      "county": "Montgomery"
  },
  {
      "zipCode": 67401,
      "latitude": 38.823673,
      "longitude": -97.642113,
      "city": "Salina",
      "state": "KS",
      "county": "Saline"
  },
  {
      "zipCode": 67402,
      "latitude": 38.78359,
      "longitude": -97.650446,
      "city": "Salina",
      "state": "KS",
      "county": "Saline"
  },
  {
      "zipCode": 67410,
      "latitude": 38.9111,
      "longitude": -97.195283,
      "city": "Abilene",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67416,
      "latitude": 38.688891,
      "longitude": -97.59426,
      "city": "Assaria",
      "state": "KS",
      "county": "Saline"
  },
  {
      "zipCode": 67417,
      "latitude": 39.45212,
      "longitude": -97.529562,
      "city": "Aurora",
      "state": "KS",
      "county": "Cloud"
  },
  {
      "zipCode": 67418,
      "latitude": 39.188663,
      "longitude": -98.044605,
      "city": "Barnard",
      "state": "KS",
      "county": "Lincoln"
  },
  {
      "zipCode": 67420,
      "latitude": 39.441631,
      "longitude": -98.119189,
      "city": "Beloit",
      "state": "KS",
      "county": "Mitchell"
  },
  {
      "zipCode": 67422,
      "latitude": 39.019928,
      "longitude": -97.622108,
      "city": "Bennington",
      "state": "KS",
      "county": "Ottawa"
  },
  {
      "zipCode": 67423,
      "latitude": 39.045239,
      "longitude": -98.209248,
      "city": "Beverly",
      "state": "KS",
      "county": "Lincoln"
  },
  {
      "zipCode": 67425,
      "latitude": 38.783921,
      "longitude": -97.854579,
      "city": "Brookville",
      "state": "KS",
      "county": "Saline"
  },
  {
      "zipCode": 67427,
      "latitude": 38.463875,
      "longitude": -98.387168,
      "city": "Bushton",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67428,
      "latitude": 38.392631,
      "longitude": -97.426953,
      "city": "Canton",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67430,
      "latitude": 39.451105,
      "longitude": -98.187156,
      "city": "Cawker City",
      "state": "KS",
      "county": "Mitchell"
  },
  {
      "zipCode": 67431,
      "latitude": 38.972672,
      "longitude": -97.018914,
      "city": "Chapman",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67432,
      "latitude": 39.349452,
      "longitude": -97.164849,
      "city": "Clay Center",
      "state": "KS",
      "county": "Clay"
  },
  {
      "zipCode": 67436,
      "latitude": 39.263098,
      "longitude": -97.714625,
      "city": "Delphos",
      "state": "KS",
      "county": "Ottawa"
  },
  {
      "zipCode": 67437,
      "latitude": 39.449456,
      "longitude": -98.581432,
      "city": "Downs",
      "state": "KS",
      "county": "Osborne"
  },
  {
      "zipCode": 67438,
      "latitude": 38.523968,
      "longitude": -97.251974,
      "city": "Durham",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 67439,
      "latitude": 38.696579,
      "longitude": -98.205437,
      "city": "Ellsworth",
      "state": "KS",
      "county": "Ellsworth"
  },
  {
      "zipCode": 67441,
      "latitude": 38.889721,
      "longitude": -97.09319,
      "city": "Enterprise",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67442,
      "latitude": 38.69098,
      "longitude": -97.787364,
      "city": "Falun",
      "state": "KS",
      "county": "Saline"
  },
  {
      "zipCode": 67443,
      "latitude": 38.382827,
      "longitude": -97.47325,
      "city": "Galva",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67444,
      "latitude": 38.362194,
      "longitude": -98.15843,
      "city": "Geneseo",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67445,
      "latitude": 39.36117,
      "longitude": -97.83825,
      "city": "Glasco",
      "state": "KS",
      "county": "Cloud"
  },
  {
      "zipCode": 67446,
      "latitude": 39.501034,
      "longitude": -98.302257,
      "city": "Glen Elder",
      "state": "KS",
      "county": "Mitchell"
  },
  {
      "zipCode": 67447,
      "latitude": 39.480323,
      "longitude": -97.01489,
      "city": "Green",
      "state": "KS",
      "county": "Clay"
  },
  {
      "zipCode": 67448,
      "latitude": 38.710913,
      "longitude": -97.492119,
      "city": "Gypsum",
      "state": "KS",
      "county": "Saline"
  },
  {
      "zipCode": 67449,
      "latitude": 38.792473,
      "longitude": -96.993297,
      "city": "Herington",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67450,
      "latitude": 38.613667,
      "longitude": -98.420938,
      "city": "Holyrood",
      "state": "KS",
      "county": "Ellsworth"
  },
  {
      "zipCode": 67451,
      "latitude": 38.826292,
      "longitude": -97.121874,
      "city": "Hope",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67452,
      "latitude": 39.287497,
      "longitude": -98.433673,
      "city": "Hunter",
      "state": "KS",
      "county": "Mitchell"
  },
  {
      "zipCode": 67454,
      "latitude": 38.696847,
      "longitude": -98.138181,
      "city": "Kanopolis",
      "state": "KS",
      "county": "Ellsworth"
  },
  {
      "zipCode": 67455,
      "latitude": 39.102718,
      "longitude": -98.214909,
      "city": "Lincoln",
      "state": "KS",
      "county": "Lincoln"
  },
  {
      "zipCode": 67456,
      "latitude": 38.497407,
      "longitude": -97.628859,
      "city": "Lindsborg",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67457,
      "latitude": 38.378672,
      "longitude": -98.095004,
      "city": "Little River",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67458,
      "latitude": 39.183428,
      "longitude": -97.249882,
      "city": "Longford",
      "state": "KS",
      "county": "Clay"
  },
  {
      "zipCode": 67459,
      "latitude": 38.601673,
      "longitude": -98.273284,
      "city": "Lorraine",
      "state": "KS",
      "county": "Ellsworth"
  },
  {
      "zipCode": 67460,
      "latitude": 38.376302,
      "longitude": -97.670184,
      "city": "Mcpherson",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67464,
      "latitude": 38.529808,
      "longitude": -97.675384,
      "city": "Marquette",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67466,
      "latitude": 39.349959,
      "longitude": -97.454812,
      "city": "Miltonvale",
      "state": "KS",
      "county": "Cloud"
  },
  {
      "zipCode": 67467,
      "latitude": 39.141097,
      "longitude": -97.650674,
      "city": "Minneapolis",
      "state": "KS",
      "county": "Ottawa"
  },
  {
      "zipCode": 67468,
      "latitude": 39.328382,
      "longitude": -97.164888,
      "city": "Morganville",
      "state": "KS",
      "county": "Clay"
  },
  {
      "zipCode": 67470,
      "latitude": 38.905381,
      "longitude": -97.515705,
      "city": "New Cambria",
      "state": "KS",
      "county": "Saline"
  },
  {
      "zipCode": 67473,
      "latitude": 39.385738,
      "longitude": -98.795417,
      "city": "Osborne",
      "state": "KS",
      "county": "Osborne"
  },
  {
      "zipCode": 67474,
      "latitude": 39.531927,
      "longitude": -98.738629,
      "city": "Portis",
      "state": "KS",
      "county": "Osborne"
  },
  {
      "zipCode": 67475,
      "latitude": 38.59749,
      "longitude": -97.062601,
      "city": "Ramona",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 67476,
      "latitude": 38.391722,
      "longitude": -97.648402,
      "city": "Roxbury",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67478,
      "latitude": 39.385112,
      "longitude": -97.932764,
      "city": "Simpson",
      "state": "KS",
      "county": "Mitchell"
  },
  {
      "zipCode": 67480,
      "latitude": 38.910885,
      "longitude": -97.339456,
      "city": "Solomon",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67481,
      "latitude": 38.969795,
      "longitude": -98.435528,
      "city": "Sylvan Grove",
      "state": "KS",
      "county": "Lincoln"
  },
  {
      "zipCode": 67482,
      "latitude": 39.02241,
      "longitude": -97.259116,
      "city": "Talmage",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67483,
      "latitude": 38.54879,
      "longitude": -97.17004,
      "city": "Tampa",
      "state": "KS",
      "county": "Marion"
  },
  {
      "zipCode": 67484,
      "latitude": 39.041646,
      "longitude": -97.719466,
      "city": "Tescott",
      "state": "KS",
      "county": "Ottawa"
  },
  {
      "zipCode": 67485,
      "latitude": 39.339425,
      "longitude": -98.470261,
      "city": "Tipton",
      "state": "KS",
      "county": "Mitchell"
  },
  {
      "zipCode": 67487,
      "latitude": 39.190937,
      "longitude": -97.075406,
      "city": "Wakefield",
      "state": "KS",
      "county": "Clay"
  },
  {
      "zipCode": 67490,
      "latitude": 38.784047,
      "longitude": -98.271846,
      "city": "Wilson",
      "state": "KS",
      "county": "Ellsworth"
  },
  {
      "zipCode": 67491,
      "latitude": 38.389365,
      "longitude": -97.694453,
      "city": "Windom",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67492,
      "latitude": 38.812401,
      "longitude": -96.964022,
      "city": "Woodbine",
      "state": "KS",
      "county": "Dickinson"
  },
  {
      "zipCode": 67501,
      "latitude": 37.954312,
      "longitude": -97.88569,
      "city": "Hutchinson",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67502,
      "latitude": 38.115643,
      "longitude": -97.893663,
      "city": "Hutchinson",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67504,
      "latitude": 37.953219,
      "longitude": -98.085924,
      "city": "Hutchinson",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67505,
      "latitude": 38.003541,
      "longitude": -97.944252,
      "city": "South Hutchinson",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67510,
      "latitude": 38.013705,
      "longitude": -98.169523,
      "city": "Abbyville",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67511,
      "latitude": 38.420451,
      "longitude": -98.913448,
      "city": "Albert",
      "state": "KS",
      "county": "Barton"
  },
  {
      "zipCode": 67512,
      "latitude": 38.233295,
      "longitude": -98.316515,
      "city": "Alden",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67513,
      "latitude": 38.470116,
      "longitude": -99.553222,
      "city": "Alexander",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67514,
      "latitude": 37.829877,
      "longitude": -98.196898,
      "city": "Arlington",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67515,
      "latitude": 38.479822,
      "longitude": -99.916519,
      "city": "Arnold",
      "state": "KS",
      "county": "Ness"
  },
  {
      "zipCode": 67516,
      "latitude": 38.445565,
      "longitude": -99.692538,
      "city": "Bazine",
      "state": "KS",
      "county": "Ness"
  },
  {
      "zipCode": 67518,
      "latitude": 38.479822,
      "longitude": -99.916519,
      "city": "Beeler",
      "state": "KS",
      "county": "Ness"
  },
  {
      "zipCode": 67519,
      "latitude": 37.951204,
      "longitude": -99.098947,
      "city": "Belpre",
      "state": "KS",
      "county": "Edwards"
  },
  {
      "zipCode": 67520,
      "latitude": 38.563156,
      "longitude": -99.195772,
      "city": "Bison",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67521,
      "latitude": 38.479822,
      "longitude": -99.916519,
      "city": "Brownell",
      "state": "KS",
      "county": "Ness"
  },
  {
      "zipCode": 67522,
      "latitude": 38.122794,
      "longitude": -97.766352,
      "city": "Buhler",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67523,
      "latitude": 38.13957,
      "longitude": -99.385256,
      "city": "Burdett",
      "state": "KS",
      "county": "Pawnee"
  },
  {
      "zipCode": 67524,
      "latitude": 38.362338,
      "longitude": -98.246676,
      "city": "Chase",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67525,
      "latitude": 38.565876,
      "longitude": -98.627068,
      "city": "Claflin",
      "state": "KS",
      "county": "Barton"
  },
  {
      "zipCode": 67526,
      "latitude": 38.46396,
      "longitude": -98.733001,
      "city": "Ellinwood",
      "state": "KS",
      "county": "Barton"
  },
  {
      "zipCode": 67529,
      "latitude": 38.076733,
      "longitude": -99.245071,
      "city": "Garfield",
      "state": "KS",
      "county": "Pawnee"
  },
  {
      "zipCode": 67530,
      "latitude": 38.393567,
      "longitude": -98.775074,
      "city": "Great Bend",
      "state": "KS",
      "county": "Barton"
  },
  {
      "zipCode": 67543,
      "latitude": 37.881183,
      "longitude": -97.793755,
      "city": "Haven",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67544,
      "latitude": 38.478935,
      "longitude": -98.756485,
      "city": "Hoisington",
      "state": "KS",
      "county": "Barton"
  },
  {
      "zipCode": 67545,
      "latitude": 38.166197,
      "longitude": -98.628964,
      "city": "Hudson",
      "state": "KS",
      "county": "Stafford"
  },
  {
      "zipCode": 67546,
      "latitude": 38.246179,
      "longitude": -97.795694,
      "city": "Inman",
      "state": "KS",
      "county": "Mcpherson"
  },
  {
      "zipCode": 67547,
      "latitude": 37.91204,
      "longitude": -99.478881,
      "city": "Kinsley",
      "state": "KS",
      "county": "Edwards"
  },
  {
      "zipCode": 67548,
      "latitude": 38.531867,
      "longitude": -99.300546,
      "city": "La Crosse",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67550,
      "latitude": 38.194644,
      "longitude": -99.10096,
      "city": "Larned",
      "state": "KS",
      "county": "Pawnee"
  },
  {
      "zipCode": 67552,
      "latitude": 37.936208,
      "longitude": -99.254501,
      "city": "Lewis",
      "state": "KS",
      "county": "Edwards"
  },
  {
      "zipCode": 67553,
      "latitude": 38.655719,
      "longitude": -99.320339,
      "city": "Liebenthal",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67554,
      "latitude": 38.376979,
      "longitude": -98.193184,
      "city": "Lyons",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67556,
      "latitude": 38.586345,
      "longitude": -99.571844,
      "city": "Mc Cracken",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67557,
      "latitude": 37.95853,
      "longitude": -98.968997,
      "city": "Macksville",
      "state": "KS",
      "county": "Stafford"
  },
  {
      "zipCode": 67559,
      "latitude": 38.52286,
      "longitude": -99.309591,
      "city": "Nekoma",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67560,
      "latitude": 38.455316,
      "longitude": -99.905723,
      "city": "Ness City",
      "state": "KS",
      "county": "Ness"
  },
  {
      "zipCode": 67561,
      "latitude": 38.100868,
      "longitude": -98.105772,
      "city": "Nickerson",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67563,
      "latitude": 37.890172,
      "longitude": -99.562672,
      "city": "Offerle",
      "state": "KS",
      "county": "Edwards"
  },
  {
      "zipCode": 67564,
      "latitude": 38.543586,
      "longitude": -98.895484,
      "city": "Olmitz",
      "state": "KS",
      "county": "Barton"
  },
  {
      "zipCode": 67565,
      "latitude": 38.53527,
      "longitude": -99.053404,
      "city": "Otis",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67566,
      "latitude": 38.005705,
      "longitude": -97.977327,
      "city": "Partridge",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67567,
      "latitude": 38.451554,
      "longitude": -98.959346,
      "city": "Pawnee Rock",
      "state": "KS",
      "county": "Barton"
  },
  {
      "zipCode": 67568,
      "latitude": 38.006255,
      "longitude": -98.261811,
      "city": "Plevna",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67570,
      "latitude": 37.813853,
      "longitude": -97.99759,
      "city": "Pretty Prairie",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67572,
      "latitude": 38.635962,
      "longitude": -99.932676,
      "city": "Ransom",
      "state": "KS",
      "county": "Ness"
  },
  {
      "zipCode": 67573,
      "latitude": 38.26748,
      "longitude": -98.392836,
      "city": "Raymond",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67574,
      "latitude": 38.196248,
      "longitude": -99.403343,
      "city": "Rozel",
      "state": "KS",
      "county": "Pawnee"
  },
  {
      "zipCode": 67575,
      "latitude": 38.467528,
      "longitude": -99.24642,
      "city": "Rush Center",
      "state": "KS",
      "county": "Rush"
  },
  {
      "zipCode": 67576,
      "latitude": 38.060799,
      "longitude": -98.78677,
      "city": "Saint John",
      "state": "KS",
      "county": "Stafford"
  },
  {
      "zipCode": 67578,
      "latitude": 37.961811,
      "longitude": -98.775,
      "city": "Stafford",
      "state": "KS",
      "county": "Stafford"
  },
  {
      "zipCode": 67579,
      "latitude": 38.224187,
      "longitude": -98.183033,
      "city": "Sterling",
      "state": "KS",
      "county": "Rice"
  },
  {
      "zipCode": 67581,
      "latitude": 38.006382,
      "longitude": -98.121898,
      "city": "Sylvia",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67583,
      "latitude": 37.822393,
      "longitude": -98.359075,
      "city": "Turon",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67584,
      "latitude": 38.641893,
      "longitude": -100.170396,
      "city": "Utica",
      "state": "KS",
      "county": "Ness"
  },
  {
      "zipCode": 67585,
      "latitude": 37.94119,
      "longitude": -97.871147,
      "city": "Yoder",
      "state": "KS",
      "county": "Reno"
  },
  {
      "zipCode": 67601,
      "latitude": 38.878202,
      "longitude": -99.334803,
      "city": "Hays",
      "state": "KS",
      "county": "Ellis"
  },
  {
      "zipCode": 67621,
      "latitude": 39.760857,
      "longitude": -99.119565,
      "city": "Agra",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67622,
      "latitude": 39.891272,
      "longitude": -99.711523,
      "city": "Almena",
      "state": "KS",
      "county": "Norton"
  },
  {
      "zipCode": 67623,
      "latitude": 39.430147,
      "longitude": -98.928883,
      "city": "Alton",
      "state": "KS",
      "county": "Osborne"
  },
  {
      "zipCode": 67625,
      "latitude": 39.360027,
      "longitude": -99.6876,
      "city": "Bogue",
      "state": "KS",
      "county": "Graham"
  },
  {
      "zipCode": 67626,
      "latitude": 38.868931,
      "longitude": -98.676303,
      "city": "Bunker Hill",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67627,
      "latitude": 38.927648,
      "longitude": -99.216051,
      "city": "Catharine",
      "state": "KS",
      "county": "Ellis"
  },
  {
      "zipCode": 67628,
      "latitude": 39.784963,
      "longitude": -98.785622,
      "city": "Cedar",
      "state": "KS",
      "county": "Smith"
  },
  {
      "zipCode": 67629,
      "latitude": 39.78432,
      "longitude": -99.903004,
      "city": "Clayton",
      "state": "KS",
      "county": "Norton"
  },
  {
      "zipCode": 67631,
      "latitude": 38.91436,
      "longitude": -99.872931,
      "city": "Collyer",
      "state": "KS",
      "county": "Trego"
  },
  {
      "zipCode": 67632,
      "latitude": 39.334908,
      "longitude": -99.39947,
      "city": "Damar",
      "state": "KS",
      "county": "Rooks"
  },
  {
      "zipCode": 67634,
      "latitude": 38.821918,
      "longitude": -98.775418,
      "city": "Dorrance",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67635,
      "latitude": 39.784578,
      "longitude": -100.459467,
      "city": "Dresden",
      "state": "KS",
      "county": "Decatur"
  },
  {
      "zipCode": 67637,
      "latitude": 38.950015,
      "longitude": -99.336337,
      "city": "Ellis",
      "state": "KS",
      "county": "Ellis"
  },
  {
      "zipCode": 67638,
      "latitude": 39.64705,
      "longitude": -98.846513,
      "city": "Gaylord",
      "state": "KS",
      "county": "Smith"
  },
  {
      "zipCode": 67639,
      "latitude": 39.784708,
      "longitude": -99.347075,
      "city": "Glade",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67640,
      "latitude": 38.852325,
      "longitude": -99.010803,
      "city": "Gorham",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67642,
      "latitude": 39.366018,
      "longitude": -99.842382,
      "city": "Hill City",
      "state": "KS",
      "county": "Graham"
  },
  {
      "zipCode": 67643,
      "latitude": 39.679281,
      "longitude": -100.294812,
      "city": "Jennings",
      "state": "KS",
      "county": "Decatur"
  },
  {
      "zipCode": 67644,
      "latitude": 39.668671,
      "longitude": -99.342534,
      "city": "Kirwin",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67645,
      "latitude": 39.610503,
      "longitude": -100.002777,
      "city": "Lenora",
      "state": "KS",
      "county": "Norton"
  },
  {
      "zipCode": 67646,
      "latitude": 39.664328,
      "longitude": -99.568879,
      "city": "Logan",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67647,
      "latitude": 39.784708,
      "longitude": -99.347075,
      "city": "Long Island",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67648,
      "latitude": 39.008115,
      "longitude": -98.754407,
      "city": "Lucas",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67649,
      "latitude": 39.08201,
      "longitude": -98.651072,
      "city": "Luray",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67650,
      "latitude": 39.352426,
      "longitude": -100.075464,
      "city": "Morland",
      "state": "KS",
      "county": "Graham"
  },
  {
      "zipCode": 67651,
      "latitude": 39.227878,
      "longitude": -98.945419,
      "city": "Natoma",
      "state": "KS",
      "county": "Osborne"
  },
  {
      "zipCode": 67653,
      "latitude": 39.837074,
      "longitude": -100.188191,
      "city": "Norcatur",
      "state": "KS",
      "county": "Decatur"
  },
  {
      "zipCode": 67654,
      "latitude": 39.827955,
      "longitude": -99.891726,
      "city": "Norton",
      "state": "KS",
      "county": "Norton"
  },
  {
      "zipCode": 67656,
      "latitude": 38.91436,
      "longitude": -99.872931,
      "city": "Ogallah",
      "state": "KS",
      "county": "Trego"
  },
  {
      "zipCode": 67657,
      "latitude": 39.229778,
      "longitude": -99.346263,
      "city": "Palco",
      "state": "KS",
      "county": "Rooks"
  },
  {
      "zipCode": 67658,
      "latitude": 39.079529,
      "longitude": -98.936673,
      "city": "Paradise",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67659,
      "latitude": 39.348243,
      "longitude": -99.971939,
      "city": "Penokee",
      "state": "KS",
      "county": "Graham"
  },
  {
      "zipCode": 67660,
      "latitude": 38.715293,
      "longitude": -99.171847,
      "city": "Pfeifer",
      "state": "KS",
      "county": "Ellis"
  },
  {
      "zipCode": 67661,
      "latitude": 39.779113,
      "longitude": -99.346302,
      "city": "Phillipsburg",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67663,
      "latitude": 39.248844,
      "longitude": -99.316373,
      "city": "Plainville",
      "state": "KS",
      "county": "Rooks"
  },
  {
      "zipCode": 67664,
      "latitude": 39.75337,
      "longitude": -99.348129,
      "city": "Prairie View",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67665,
      "latitude": 38.905159,
      "longitude": -98.784797,
      "city": "Russell",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67667,
      "latitude": 38.712772,
      "longitude": -99.332221,
      "city": "Schoenchen",
      "state": "KS",
      "county": "Ellis"
  },
  {
      "zipCode": 67669,
      "latitude": 39.412166,
      "longitude": -99.369872,
      "city": "Stockton",
      "state": "KS",
      "county": "Rooks"
  },
  {
      "zipCode": 67670,
      "latitude": 39.784708,
      "longitude": -99.347075,
      "city": "Stuttgart",
      "state": "KS",
      "county": "Phillips"
  },
  {
      "zipCode": 67671,
      "latitude": 38.915745,
      "longitude": -99.141972,
      "city": "Victoria",
      "state": "KS",
      "county": "Ellis"
  },
  {
      "zipCode": 67672,
      "latitude": 39.020792,
      "longitude": -99.888083,
      "city": "Wa Keeney",
      "state": "KS",
      "county": "Trego"
  },
  {
      "zipCode": 67673,
      "latitude": 39.067957,
      "longitude": -98.788651,
      "city": "Waldo",
      "state": "KS",
      "county": "Russell"
  },
  {
      "zipCode": 67674,
      "latitude": 38.873144,
      "longitude": -99.094913,
      "city": "Walker",
      "state": "KS",
      "county": "Ellis"
  },
  {
      "zipCode": 67675,
      "latitude": 39.401699,
      "longitude": -99.244147,
      "city": "Woodston",
      "state": "KS",
      "county": "Rooks"
  },
  {
      "zipCode": 67701,
      "latitude": 39.40152,
      "longitude": -100.973696,
      "city": "Colby",
      "state": "KS",
      "county": "Thomas"
  },
  {
      "zipCode": 67730,
      "latitude": 39.824688,
      "longitude": -101.041371,
      "city": "Atwood",
      "state": "KS",
      "county": "Rawlins"
  },
  {
      "zipCode": 67731,
      "latitude": 39.750443,
      "longitude": -101.532798,
      "city": "Bird City",
      "state": "KS",
      "county": "Cheyenne"
  },
  {
      "zipCode": 67732,
      "latitude": 39.358974,
      "longitude": -101.061905,
      "city": "Brewster",
      "state": "KS",
      "county": "Thomas"
  },
  {
      "zipCode": 67733,
      "latitude": 39.366545,
      "longitude": -101.49405,
      "city": "Edson",
      "state": "KS",
      "county": "Sherman"
  },
  {
      "zipCode": 67734,
      "latitude": 39.459642,
      "longitude": -100.934517,
      "city": "Gem",
      "state": "KS",
      "county": "Thomas"
  },
  {
      "zipCode": 67735,
      "latitude": 39.358123,
      "longitude": -101.798885,
      "city": "Goodland",
      "state": "KS",
      "county": "Sherman"
  },
  {
      "zipCode": 67736,
      "latitude": 38.915304,
      "longitude": -100.483367,
      "city": "Gove",
      "state": "KS",
      "county": "Gove"
  },
  {
      "zipCode": 67737,
      "latitude": 39.11464,
      "longitude": -100.46688,
      "city": "Grainfield",
      "state": "KS",
      "county": "Gove"
  },
  {
      "zipCode": 67738,
      "latitude": 39.125891,
      "longitude": -100.631872,
      "city": "Grinnell",
      "state": "KS",
      "county": "Gove"
  },
  {
      "zipCode": 67739,
      "latitude": 39.909207,
      "longitude": -100.784803,
      "city": "Herndon",
      "state": "KS",
      "county": "Rawlins"
  },
  {
      "zipCode": 67740,
      "latitude": 39.355013,
      "longitude": -100.440513,
      "city": "Hoxie",
      "state": "KS",
      "county": "Sheridan"
  },
  {
      "zipCode": 67741,
      "latitude": 39.365231,
      "longitude": -101.967481,
      "city": "Kanorado",
      "state": "KS",
      "county": "Sherman"
  },
  {
      "zipCode": 67743,
      "latitude": 39.413268,
      "longitude": -101.148818,
      "city": "Levant",
      "state": "KS",
      "county": "Thomas"
  },
  {
      "zipCode": 67744,
      "latitude": 39.785211,
      "longitude": -101.076282,
      "city": "Ludell",
      "state": "KS",
      "county": "Rawlins"
  },
  {
      "zipCode": 67745,
      "latitude": 39.785619,
      "longitude": -101.370071,
      "city": "Mc Donald",
      "state": "KS",
      "county": "Rawlins"
  },
  {
      "zipCode": 67747,
      "latitude": 39.046409,
      "longitude": -101.063728,
      "city": "Monument",
      "state": "KS",
      "county": "Logan"
  },
  {
      "zipCode": 67748,
      "latitude": 38.945805,
      "longitude": -100.970176,
      "city": "Oakley",
      "state": "KS",
      "county": "Logan"
  },
  {
      "zipCode": 67749,
      "latitude": 39.820834,
      "longitude": -100.529554,
      "city": "Oberlin",
      "state": "KS",
      "county": "Decatur"
  },
  {
      "zipCode": 67751,
      "latitude": 39.063922,
      "longitude": -100.233439,
      "city": "Park",
      "state": "KS",
      "county": "Gove"
  },
  {
      "zipCode": 67752,
      "latitude": 39.063613,
      "longitude": -100.238001,
      "city": "Quinter",
      "state": "KS",
      "county": "Gove"
  },
  {
      "zipCode": 67753,
      "latitude": 39.390715,
      "longitude": -101.049033,
      "city": "Rexford",
      "state": "KS",
      "county": "Thomas"
  },
  {
      "zipCode": 67756,
      "latitude": 39.680371,
      "longitude": -101.909252,
      "city": "Saint Francis",
      "state": "KS",
      "county": "Cheyenne"
  },
  {
      "zipCode": 67757,
      "latitude": 39.541125,
      "longitude": -100.567722,
      "city": "Selden",
      "state": "KS",
      "county": "Sheridan"
  },
  {
      "zipCode": 67758,
      "latitude": 38.89428,
      "longitude": -101.748965,
      "city": "Sharon Springs",
      "state": "KS",
      "county": "Wallace"
  },
  {
      "zipCode": 67761,
      "latitude": 38.916183,
      "longitude": -101.762678,
      "city": "Wallace",
      "state": "KS",
      "county": "Wallace"
  },
  {
      "zipCode": 67762,
      "latitude": 38.916183,
      "longitude": -101.762678,
      "city": "Weskan",
      "state": "KS",
      "county": "Wallace"
  },
  {
      "zipCode": 67764,
      "latitude": 38.921702,
      "longitude": -101.094305,
      "city": "Winona",
      "state": "KS",
      "county": "Logan"
  },
  {
      "zipCode": 67801,
      "latitude": 37.690531,
      "longitude": -99.902421,
      "city": "Dodge City",
      "state": "KS",
      "county": "Ford"
  },
  {
      "zipCode": 67831,
      "latitude": 37.171946,
      "longitude": -99.769576,
      "city": "Ashland",
      "state": "KS",
      "county": "Clark"
  },
  {
      "zipCode": 67834,
      "latitude": 37.690525,
      "longitude": -99.882532,
      "city": "Bucklin",
      "state": "KS",
      "county": "Ford"
  },
  {
      "zipCode": 67835,
      "latitude": 37.803608,
      "longitude": -100.338634,
      "city": "Cimarron",
      "state": "KS",
      "county": "Gray"
  },
  {
      "zipCode": 67836,
      "latitude": 37.999701,
      "longitude": -101.785968,
      "city": "Coolidge",
      "state": "KS",
      "county": "Hamilton"
  },
  {
      "zipCode": 67837,
      "latitude": 37.738785,
      "longitude": -100.505348,
      "city": "Copeland",
      "state": "KS",
      "county": "Gray"
  },
  {
      "zipCode": 67838,
      "latitude": 37.964591,
      "longitude": -101.190998,
      "city": "Deerfield",
      "state": "KS",
      "county": "Kearny"
  },
  {
      "zipCode": 67839,
      "latitude": 38.474188,
      "longitude": -100.464651,
      "city": "Dighton",
      "state": "KS",
      "county": "Lane"
  },
  {
      "zipCode": 67840,
      "latitude": 37.237186,
      "longitude": -99.82422,
      "city": "Englewood",
      "state": "KS",
      "county": "Clark"
  },
  {
      "zipCode": 67841,
      "latitude": 37.624913,
      "longitude": -100.27543,
      "city": "Ensign",
      "state": "KS",
      "county": "Gray"
  },
  {
      "zipCode": 67842,
      "latitude": 37.587839,
      "longitude": -99.799449,
      "city": "Ford",
      "state": "KS",
      "county": "Ford"
  },
  {
      "zipCode": 67844,
      "latitude": 37.279983,
      "longitude": -100.211034,
      "city": "Fowler",
      "state": "KS",
      "county": "Meade"
  },
  {
      "zipCode": 67846,
      "latitude": 38.000771,
      "longitude": -100.664407,
      "city": "Garden City",
      "state": "KS",
      "county": "Finney"
  },
  {
      "zipCode": 67849,
      "latitude": 38.121492,
      "longitude": -99.709532,
      "city": "Hanston",
      "state": "KS",
      "county": "Hodgeman"
  },
  {
      "zipCode": 67850,
      "latitude": 38.578991,
      "longitude": -100.600786,
      "city": "Healy",
      "state": "KS",
      "county": "Lane"
  },
  {
      "zipCode": 67851,
      "latitude": 38.098129,
      "longitude": -100.961803,
      "city": "Holcomb",
      "state": "KS",
      "county": "Finney"
  },
  {
      "zipCode": 67853,
      "latitude": 37.789051,
      "longitude": -100.46093,
      "city": "Ingalls",
      "state": "KS",
      "county": "Gray"
  },
  {
      "zipCode": 67854,
      "latitude": 38.023937,
      "longitude": -99.893681,
      "city": "Jetmore",
      "state": "KS",
      "county": "Hodgeman"
  },
  {
      "zipCode": 67855,
      "latitude": 37.562997,
      "longitude": -101.707858,
      "city": "Johnson",
      "state": "KS",
      "county": "Stanton"
  },
  {
      "zipCode": 67857,
      "latitude": 37.999701,
      "longitude": -101.785968,
      "city": "Kendall",
      "state": "KS",
      "county": "Hamilton"
  },
  {
      "zipCode": 67859,
      "latitude": 37.204223,
      "longitude": -100.701401,
      "city": "Kismet",
      "state": "KS",
      "county": "Seward"
  },
  {
      "zipCode": 67860,
      "latitude": 37.938907,
      "longitude": -101.264981,
      "city": "Lakin",
      "state": "KS",
      "county": "Kearny"
  },
  {
      "zipCode": 67861,
      "latitude": 38.477827,
      "longitude": -101.293036,
      "city": "Leoti",
      "state": "KS",
      "county": "Wichita"
  },
  {
      "zipCode": 67862,
      "latitude": 37.536283,
      "longitude": -101.904726,
      "city": "Manter",
      "state": "KS",
      "county": "Stanton"
  },
  {
      "zipCode": 67863,
      "latitude": 38.483749,
      "longitude": -101.283946,
      "city": "Marienthal",
      "state": "KS",
      "county": "Wichita"
  },
  {
      "zipCode": 67864,
      "latitude": 37.238357,
      "longitude": -100.316583,
      "city": "Meade",
      "state": "KS",
      "county": "Meade"
  },
  {
      "zipCode": 67865,
      "latitude": 37.442546,
      "longitude": -100.008811,
      "city": "Minneola",
      "state": "KS",
      "county": "Clark"
  },
  {
      "zipCode": 67867,
      "latitude": 37.598638,
      "longitude": -100.415568,
      "city": "Montezuma",
      "state": "KS",
      "county": "Gray"
  },
  {
      "zipCode": 67868,
      "latitude": 37.905994,
      "longitude": -100.752256,
      "city": "Pierceville",
      "state": "KS",
      "county": "Finney"
  },
  {
      "zipCode": 67869,
      "latitude": 37.2419,
      "longitude": -100.435253,
      "city": "Plains",
      "state": "KS",
      "county": "Meade"
  },
  {
      "zipCode": 67870,
      "latitude": 37.441023,
      "longitude": -100.998474,
      "city": "Satanta",
      "state": "KS",
      "county": "Haskell"
  },
  {
      "zipCode": 67871,
      "latitude": 38.482307,
      "longitude": -100.906427,
      "city": "Scott City",
      "state": "KS",
      "county": "Scott"
  },
  {
      "zipCode": 67876,
      "latitude": 37.729884,
      "longitude": -99.715817,
      "city": "Spearville",
      "state": "KS",
      "county": "Ford"
  },
  {
      "zipCode": 67877,
      "latitude": 37.482236,
      "longitude": -100.848783,
      "city": "Sublette",
      "state": "KS",
      "county": "Haskell"
  },
  {
      "zipCode": 67878,
      "latitude": 37.986428,
      "longitude": -101.751732,
      "city": "Syracuse",
      "state": "KS",
      "county": "Hamilton"
  },
  {
      "zipCode": 67879,
      "latitude": 38.469704,
      "longitude": -101.747853,
      "city": "Tribune",
      "state": "KS",
      "county": "Greeley"
  },
  {
      "zipCode": 67880,
      "latitude": 37.562467,
      "longitude": -101.308565,
      "city": "Ulysses",
      "state": "KS",
      "county": "Grant"
  },
  {
      "zipCode": 67882,
      "latitude": 37.814013,
      "longitude": -99.903061,
      "city": "Wright",
      "state": "KS",
      "county": "Ford"
  },
  {
      "zipCode": 67901,
      "latitude": 37.043237,
      "longitude": -100.873809,
      "city": "Liberal",
      "state": "KS",
      "county": "Seward"
  },
  {
      "zipCode": 67905,
      "latitude": 37.021641,
      "longitude": -100.938049,
      "city": "Liberal",
      "state": "KS",
      "county": "Seward"
  },
  {
      "zipCode": 67950,
      "latitude": 37.00405,
      "longitude": -101.896973,
      "city": "Elkhart",
      "state": "KS",
      "county": "Morton"
  },
  {
      "zipCode": 67951,
      "latitude": 37.243161,
      "longitude": -101.281684,
      "city": "Hugoton",
      "state": "KS",
      "county": "Stevens"
  },
  {
      "zipCode": 67952,
      "latitude": 37.325308,
      "longitude": -101.205904,
      "city": "Moscow",
      "state": "KS",
      "county": "Stevens"
  },
  {
      "zipCode": 67953,
      "latitude": 37.191239,
      "longitude": -101.798749,
      "city": "Richfield",
      "state": "KS",
      "county": "Morton"
  },
  {
      "zipCode": 67954,
      "latitude": 37.081137,
      "longitude": -101.60469,
      "city": "Rolla",
      "state": "KS",
      "county": "Morton"
  },
  {
      "zipCode": 68001,
      "latitude": 41.332034,
      "longitude": -96.949369,
      "city": "Abie",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68002,
      "latitude": 41.501979,
      "longitude": -96.317932,
      "city": "Arlington",
      "state": "NE",
      "county": "Washington"
  },
  {
      "zipCode": 68003,
      "latitude": 41.102696,
      "longitude": -96.577634,
      "city": "Ashland",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68004,
      "latitude": 41.995729,
      "longitude": -96.671008,
      "city": "Bancroft",
      "state": "NE",
      "county": "Cuming"
  },
  {
      "zipCode": 68005,
      "latitude": 41.132948,
      "longitude": -95.903956,
      "city": "Bellevue",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68007,
      "latitude": 41.357015,
      "longitude": -96.203393,
      "city": "Bennington",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68008,
      "latitude": 41.543582,
      "longitude": -96.17653,
      "city": "Blair",
      "state": "NE",
      "county": "Washington"
  },
  {
      "zipCode": 68009,
      "latitude": 41.53827,
      "longitude": -96.182295,
      "city": "Blair",
      "state": "NE",
      "county": "Washington"
  },
  {
      "zipCode": 68010,
      "latitude": 41.2587,
      "longitude": -96.133047,
      "city": "Boys Town",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68014,
      "latitude": 41.278245,
      "longitude": -96.975393,
      "city": "Bruno",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68015,
      "latitude": 41.365495,
      "longitude": -96.631576,
      "city": "Cedar Bluffs",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68016,
      "latitude": 41.042487,
      "longitude": -96.104359,
      "city": "Cedar Creek",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68017,
      "latitude": 41.096052,
      "longitude": -96.638116,
      "city": "Ceresco",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68018,
      "latitude": 41.307157,
      "longitude": -96.610014,
      "city": "Colon",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68019,
      "latitude": 41.792415,
      "longitude": -96.376462,
      "city": "Craig",
      "state": "NE",
      "county": "Burt"
  },
  {
      "zipCode": 68020,
      "latitude": 41.974318,
      "longitude": -96.250681,
      "city": "Decatur",
      "state": "NE",
      "county": "Burt"
  },
  {
      "zipCode": 68022,
      "latitude": 41.288631,
      "longitude": -96.238818,
      "city": "Elkhorn",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68023,
      "latitude": 41.447307,
      "longitude": -96.023829,
      "city": "Fort Calhoun",
      "state": "NE",
      "county": "Washington"
  },
  {
      "zipCode": 68025,
      "latitude": 41.487786,
      "longitude": -96.501672,
      "city": "Fremont",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68026,
      "latitude": 41.567907,
      "longitude": -96.617443,
      "city": "Fremont",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68028,
      "latitude": 41.098888,
      "longitude": -96.239744,
      "city": "Gretna",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68029,
      "latitude": 41.63256,
      "longitude": -96.273735,
      "city": "Herman",
      "state": "NE",
      "county": "Washington"
  },
  {
      "zipCode": 68030,
      "latitude": 42.332188,
      "longitude": -96.465599,
      "city": "Homer",
      "state": "NE",
      "county": "Dakota"
  },
  {
      "zipCode": 68031,
      "latitude": 41.621961,
      "longitude": -96.549316,
      "city": "Hooper",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68033,
      "latitude": 41.147084,
      "longitude": -96.523011,
      "city": "Ithaca",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68034,
      "latitude": 41.45256,
      "longitude": -96.190348,
      "city": "Kennard",
      "state": "NE",
      "county": "Washington"
  },
  {
      "zipCode": 68035,
      "latitude": 41.339383,
      "longitude": -96.444769,
      "city": "Leshara",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68036,
      "latitude": 41.375639,
      "longitude": -96.983695,
      "city": "Linwood",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68037,
      "latitude": 40.979898,
      "longitude": -96.084122,
      "city": "Louisville",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68038,
      "latitude": 41.951479,
      "longitude": -96.450944,
      "city": "Lyons",
      "state": "NE",
      "county": "Burt"
  },
  {
      "zipCode": 68039,
      "latitude": 42.146597,
      "longitude": -96.545297,
      "city": "Macy",
      "state": "NE",
      "county": "Thurston"
  },
  {
      "zipCode": 68040,
      "latitude": 41.270638,
      "longitude": -96.647937,
      "city": "Malmo",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68041,
      "latitude": 41.19999,
      "longitude": -96.640076,
      "city": "Mead",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68042,
      "latitude": 41.094587,
      "longitude": -96.430553,
      "city": "Memphis",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68044,
      "latitude": 41.536902,
      "longitude": -96.520741,
      "city": "Nickerson",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68045,
      "latitude": 41.845492,
      "longitude": -96.401432,
      "city": "Oakland",
      "state": "NE",
      "county": "Burt"
  },
  {
      "zipCode": 68046,
      "latitude": 41.118594,
      "longitude": -96.060367,
      "city": "Papillion",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68047,
      "latitude": 42.110449,
      "longitude": -96.723917,
      "city": "Pender",
      "state": "NE",
      "county": "Thurston"
  },
  {
      "zipCode": 68048,
      "latitude": 40.971961,
      "longitude": -96.001244,
      "city": "Plattsmouth",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68050,
      "latitude": 41.314461,
      "longitude": -96.823281,
      "city": "Prague",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68054,
      "latitude": 41.089048,
      "longitude": -96.070433,
      "city": "Richfield",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68055,
      "latitude": 42.056416,
      "longitude": -96.513859,
      "city": "Rosalie",
      "state": "NE",
      "county": "Thurston"
  },
  {
      "zipCode": 68056,
      "latitude": 41.092703,
      "longitude": -96.090546,
      "city": "St Columbans",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68057,
      "latitude": 41.567768,
      "longitude": -96.588672,
      "city": "Scribner",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68058,
      "latitude": 41.006712,
      "longitude": -96.246207,
      "city": "South Bend",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68059,
      "latitude": 41.066988,
      "longitude": -96.184792,
      "city": "Springfield",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68061,
      "latitude": 41.808435,
      "longitude": -96.25543,
      "city": "Tekamah",
      "state": "NE",
      "county": "Burt"
  },
  {
      "zipCode": 68062,
      "latitude": 42.146597,
      "longitude": -96.545297,
      "city": "Thurston",
      "state": "NE",
      "county": "Thurston"
  },
  {
      "zipCode": 68063,
      "latitude": 41.736896,
      "longitude": -96.503746,
      "city": "Uehling",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68064,
      "latitude": 41.342714,
      "longitude": -96.344273,
      "city": "Valley",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68065,
      "latitude": 41.108919,
      "longitude": -96.831839,
      "city": "Valparaiso",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68066,
      "latitude": 41.197043,
      "longitude": -96.669114,
      "city": "Wahoo",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68067,
      "latitude": 42.14967,
      "longitude": -96.493734,
      "city": "Walthill",
      "state": "NE",
      "county": "Thurston"
  },
  {
      "zipCode": 68068,
      "latitude": 41.395315,
      "longitude": -96.209006,
      "city": "Washington",
      "state": "NE",
      "county": "Washington"
  },
  {
      "zipCode": 68069,
      "latitude": 41.248299,
      "longitude": -96.305914,
      "city": "Waterloo",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68070,
      "latitude": 41.20724,
      "longitude": -96.758162,
      "city": "Weston",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68071,
      "latitude": 42.238161,
      "longitude": -96.472847,
      "city": "Winnebago",
      "state": "NE",
      "county": "Thurston"
  },
  {
      "zipCode": 68072,
      "latitude": 41.61023,
      "longitude": -96.503281,
      "city": "Winslow",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68073,
      "latitude": 41.211111,
      "longitude": -96.453285,
      "city": "Yutan",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68101,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68102,
      "latitude": 41.262003,
      "longitude": -95.933564,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68103,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68104,
      "latitude": 41.295396,
      "longitude": -95.99895,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68105,
      "latitude": 41.240468,
      "longitude": -95.964396,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68106,
      "latitude": 41.239005,
      "longitude": -95.999367,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68107,
      "latitude": 41.211348,
      "longitude": -95.950312,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68108,
      "latitude": 41.23814,
      "longitude": -95.964985,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68109,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68110,
      "latitude": 41.295598,
      "longitude": -95.914481,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68111,
      "latitude": 41.295395,
      "longitude": -95.964477,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68112,
      "latitude": 41.353658,
      "longitude": -95.95064,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68113,
      "latitude": 41.128191,
      "longitude": -95.917392,
      "city": "Offutt A F B",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68114,
      "latitude": 41.263262,
      "longitude": -96.053634,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68116,
      "latitude": 41.293997,
      "longitude": -96.167398,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68117,
      "latitude": 41.207194,
      "longitude": -96.001636,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68118,
      "latitude": 41.264155,
      "longitude": -96.176419,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68119,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68120,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68122,
      "latitude": 41.353003,
      "longitude": -96.07026,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68123,
      "latitude": 41.115628,
      "longitude": -95.939269,
      "city": "Bellevue",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68124,
      "latitude": 41.23467,
      "longitude": -96.052206,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68127,
      "latitude": 41.207414,
      "longitude": -96.061154,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68128,
      "latitude": 41.164994,
      "longitude": -96.061055,
      "city": "La Vista",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68130,
      "latitude": 41.234004,
      "longitude": -96.19506,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68131,
      "latitude": 41.265611,
      "longitude": -95.963832,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68132,
      "latitude": 41.264309,
      "longitude": -96.000888,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68133,
      "latitude": 41.129114,
      "longitude": -96.100064,
      "city": "Papillion",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68134,
      "latitude": 41.302434,
      "longitude": -96.055426,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68135,
      "latitude": 41.205998,
      "longitude": -96.193498,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68136,
      "latitude": 41.169034,
      "longitude": -96.172007,
      "city": "Omaha",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68137,
      "latitude": 41.207498,
      "longitude": -96.114696,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68138,
      "latitude": 41.154898,
      "longitude": -96.137617,
      "city": "Omaha",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68139,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68142,
      "latitude": 41.35585,
      "longitude": -96.101846,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68144,
      "latitude": 41.233498,
      "longitude": -96.118847,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68145,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68147,
      "latitude": 41.175798,
      "longitude": -95.95529,
      "city": "Bellevue",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68152,
      "latitude": 41.354544,
      "longitude": -95.988532,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68154,
      "latitude": 41.286298,
      "longitude": -96.117202,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68155,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68157,
      "latitude": 41.180985,
      "longitude": -95.990942,
      "city": "Omaha",
      "state": "NE",
      "county": "Sarpy"
  },
  {
      "zipCode": 68164,
      "latitude": 41.306689,
      "longitude": -96.104795,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68172,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68175,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68176,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68178,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68179,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68180,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68181,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68182,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68183,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68197,
      "latitude": 41.259697,
      "longitude": -95.937836,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68198,
      "latitude": 41.291736,
      "longitude": -96.171104,
      "city": "Omaha",
      "state": "NE",
      "county": "Douglas"
  },
  {
      "zipCode": 68301,
      "latitude": 40.45603,
      "longitude": -96.513526,
      "city": "Adams",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68303,
      "latitude": 40.244068,
      "longitude": -97.386612,
      "city": "Alexandria",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68304,
      "latitude": 40.885316,
      "longitude": -96.37898,
      "city": "Alvo",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68305,
      "latitude": 40.428801,
      "longitude": -95.843077,
      "city": "Auburn",
      "state": "NE",
      "county": "Nemaha"
  },
  {
      "zipCode": 68307,
      "latitude": 40.881809,
      "longitude": -96.239403,
      "city": "Avoca",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68309,
      "latitude": 40.041196,
      "longitude": -96.5963,
      "city": "Barneston",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68310,
      "latitude": 40.313364,
      "longitude": -96.693082,
      "city": "Beatrice",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68313,
      "latitude": 40.785436,
      "longitude": -97.23499,
      "city": "Beaver Crossing",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68314,
      "latitude": 41.002864,
      "longitude": -97.034527,
      "city": "Bee",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68315,
      "latitude": 40.176221,
      "longitude": -97.594987,
      "city": "Belvidere",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68316,
      "latitude": 41.003042,
      "longitude": -97.610404,
      "city": "Benedict",
      "state": "NE",
      "county": "York"
  },
  {
      "zipCode": 68317,
      "latitude": 40.664491,
      "longitude": -96.518083,
      "city": "Bennet",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68318,
      "latitude": 40.299157,
      "longitude": -96.753208,
      "city": "Blue Springs",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68319,
      "latitude": 40.930567,
      "longitude": -97.750193,
      "city": "Bradshaw",
      "state": "NE",
      "county": "York"
  },
  {
      "zipCode": 68320,
      "latitude": 40.479009,
      "longitude": -95.960638,
      "city": "Brock",
      "state": "NE",
      "county": "Nemaha"
  },
  {
      "zipCode": 68321,
      "latitude": 40.397541,
      "longitude": -95.663987,
      "city": "Brownville",
      "state": "NE",
      "county": "Nemaha"
  },
  {
      "zipCode": 68322,
      "latitude": 40.335959,
      "longitude": -97.56495,
      "city": "Bruning",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68323,
      "latitude": 40.146335,
      "longitude": -96.348151,
      "city": "Burchard",
      "state": "NE",
      "county": "Pawnee"
  },
  {
      "zipCode": 68324,
      "latitude": 40.536353,
      "longitude": -96.299662,
      "city": "Burr",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68325,
      "latitude": 40.005757,
      "longitude": -97.76566,
      "city": "Byron",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68326,
      "latitude": 40.303471,
      "longitude": -97.67615,
      "city": "Carleton",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68327,
      "latitude": 40.00937,
      "longitude": -97.617985,
      "city": "Chester",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68328,
      "latitude": 40.466038,
      "longitude": -96.850863,
      "city": "Clatonia",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68329,
      "latitude": 40.510888,
      "longitude": -96.161196,
      "city": "Cook",
      "state": "NE",
      "county": "Johnson"
  },
  {
      "zipCode": 68330,
      "latitude": 40.720174,
      "longitude": -97.311257,
      "city": "Cordova",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68331,
      "latitude": 40.50528,
      "longitude": -96.752373,
      "city": "Cortland",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68332,
      "latitude": 40.392595,
      "longitude": -96.26478,
      "city": "Crab Orchard",
      "state": "NE",
      "county": "Johnson"
  },
  {
      "zipCode": 68333,
      "latitude": 40.628937,
      "longitude": -97.016925,
      "city": "Crete",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68335,
      "latitude": 40.312473,
      "longitude": -97.811467,
      "city": "Davenport",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68336,
      "latitude": 40.955071,
      "longitude": -96.699697,
      "city": "Davey",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68337,
      "latitude": 40.132685,
      "longitude": -95.830408,
      "city": "Dawson",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68338,
      "latitude": 40.306449,
      "longitude": -97.14239,
      "city": "Daykin",
      "state": "NE",
      "county": "Jefferson"
  },
  {
      "zipCode": 68339,
      "latitude": 40.719464,
      "longitude": -96.850518,
      "city": "Denton",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68340,
      "latitude": 40.139448,
      "longitude": -97.723461,
      "city": "Deshler",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68341,
      "latitude": 40.374875,
      "longitude": -96.960827,
      "city": "De Witt",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68342,
      "latitude": 40.109484,
      "longitude": -97.125705,
      "city": "Diller",
      "state": "NE",
      "county": "Jefferson"
  },
  {
      "zipCode": 68343,
      "latitude": 40.646087,
      "longitude": -97.114965,
      "city": "Dorchester",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68344,
      "latitude": 40.569758,
      "longitude": -96.42238,
      "city": "Douglas",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68345,
      "latitude": 40.037725,
      "longitude": -96.069797,
      "city": "Du Bois",
      "state": "NE",
      "county": "Pawnee"
  },
  {
      "zipCode": 68346,
      "latitude": 40.634055,
      "longitude": -96.030125,
      "city": "Dunbar",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68347,
      "latitude": 40.899936,
      "longitude": -96.378887,
      "city": "Eagle",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68348,
      "latitude": 40.287778,
      "longitude": -96.12748,
      "city": "Elk Creek",
      "state": "NE",
      "county": "Johnson"
  },
  {
      "zipCode": 68349,
      "latitude": 40.834419,
      "longitude": -96.323714,
      "city": "Elmwood",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68350,
      "latitude": 40.049596,
      "longitude": -97.081254,
      "city": "Endicott",
      "state": "NE",
      "county": "Jefferson"
  },
  {
      "zipCode": 68351,
      "latitude": 40.653635,
      "longitude": -97.430884,
      "city": "Exeter",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68352,
      "latitude": 40.175641,
      "longitude": -97.171023,
      "city": "Fairbury",
      "state": "NE",
      "county": "Jefferson"
  },
  {
      "zipCode": 68354,
      "latitude": 40.641428,
      "longitude": -97.559927,
      "city": "Fairmont",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68355,
      "latitude": 40.073908,
      "longitude": -95.602053,
      "city": "Falls City",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68357,
      "latitude": 40.284748,
      "longitude": -96.535195,
      "city": "Filley",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68358,
      "latitude": 40.560657,
      "longitude": -96.58393,
      "city": "Firth",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68359,
      "latitude": 40.526915,
      "longitude": -97.111028,
      "city": "Friend",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68360,
      "latitude": 40.966358,
      "longitude": -96.96783,
      "city": "Garland",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68361,
      "latitude": 40.52749,
      "longitude": -97.600691,
      "city": "Geneva",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68362,
      "latitude": 40.176221,
      "longitude": -97.594987,
      "city": "Gilead",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68364,
      "latitude": 40.829068,
      "longitude": -97.196716,
      "city": "Goehner",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68365,
      "latitude": 40.629407,
      "longitude": -97.714675,
      "city": "Grafton",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68366,
      "latitude": 40.905385,
      "longitude": -96.290223,
      "city": "Greenwood",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68367,
      "latitude": 41.010433,
      "longitude": -97.425506,
      "city": "Gresham",
      "state": "NE",
      "county": "York"
  },
  {
      "zipCode": 68368,
      "latitude": 40.573856,
      "longitude": -96.79982,
      "city": "Hallam",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68370,
      "latitude": 40.240562,
      "longitude": -97.64485,
      "city": "Hebron",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68371,
      "latitude": 40.796446,
      "longitude": -97.773811,
      "city": "Henderson",
      "state": "NE",
      "county": "York"
  },
  {
      "zipCode": 68372,
      "latitude": 40.61189,
      "longitude": -96.62468,
      "city": "Hickman",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68375,
      "latitude": 40.008563,
      "longitude": -97.497878,
      "city": "Hubbell",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68376,
      "latitude": 40.167245,
      "longitude": -95.945731,
      "city": "Humboldt",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68377,
      "latitude": 40.232006,
      "longitude": -97.028942,
      "city": "Jansen",
      "state": "NE",
      "county": "Jefferson"
  },
  {
      "zipCode": 68378,
      "latitude": 40.345196,
      "longitude": -96.000386,
      "city": "Johnson",
      "state": "NE",
      "county": "Nemaha"
  },
  {
      "zipCode": 68380,
      "latitude": 40.195573,
      "longitude": -96.379188,
      "city": "Lewiston",
      "state": "NE",
      "county": "Pawnee"
  },
  {
      "zipCode": 68381,
      "latitude": 40.262266,
      "longitude": -96.689998,
      "city": "Liberty",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68382,
      "latitude": 40.653589,
      "longitude": -96.086904,
      "city": "Lorton",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68401,
      "latitude": 40.734852,
      "longitude": -97.568381,
      "city": "Mc Cool Junction",
      "state": "NE",
      "county": "York"
  },
  {
      "zipCode": 68402,
      "latitude": 40.908246,
      "longitude": -96.813503,
      "city": "Malcolm",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68403,
      "latitude": 40.923199,
      "longitude": -96.171189,
      "city": "Manley",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68404,
      "latitude": 40.617579,
      "longitude": -96.669936,
      "city": "Martell",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68405,
      "latitude": 40.77786,
      "longitude": -97.082489,
      "city": "Milford",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68406,
      "latitude": 40.501806,
      "longitude": -97.385947,
      "city": "Milligan",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68407,
      "latitude": 40.907906,
      "longitude": -96.268221,
      "city": "Murdock",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68409,
      "latitude": 40.90767,
      "longitude": -95.949383,
      "city": "Murray",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68410,
      "latitude": 40.707742,
      "longitude": -95.907718,
      "city": "Nebraska City",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68413,
      "latitude": 40.856763,
      "longitude": -96.015428,
      "city": "Nehawka",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68414,
      "latitude": 40.339435,
      "longitude": -95.676576,
      "city": "Nemaha",
      "state": "NE",
      "county": "Nemaha"
  },
  {
      "zipCode": 68415,
      "latitude": 40.050592,
      "longitude": -96.801542,
      "city": "Odell",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68416,
      "latitude": 40.454112,
      "longitude": -97.421084,
      "city": "Ohiowa",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68417,
      "latitude": 40.750553,
      "longitude": -96.080921,
      "city": "Otoe",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68418,
      "latitude": 40.705559,
      "longitude": -96.397545,
      "city": "Palmyra",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68419,
      "latitude": 40.599807,
      "longitude": -96.512915,
      "city": "Panama",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68420,
      "latitude": 40.111565,
      "longitude": -96.151758,
      "city": "Pawnee City",
      "state": "NE",
      "county": "Pawnee"
  },
  {
      "zipCode": 68421,
      "latitude": 40.477743,
      "longitude": -95.729589,
      "city": "Peru",
      "state": "NE",
      "county": "Nemaha"
  },
  {
      "zipCode": 68422,
      "latitude": 40.378063,
      "longitude": -96.728578,
      "city": "Pickrell",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68423,
      "latitude": 40.813869,
      "longitude": -96.968018,
      "city": "Pleasant Dale",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68424,
      "latitude": 40.291721,
      "longitude": -97.029005,
      "city": "Plymouth",
      "state": "NE",
      "county": "Jefferson"
  },
  {
      "zipCode": 68428,
      "latitude": 40.934176,
      "longitude": -96.786573,
      "city": "Raymond",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68429,
      "latitude": 40.059775,
      "longitude": -97.380646,
      "city": "Reynolds",
      "state": "NE",
      "county": "Thayer"
  },
  {
      "zipCode": 68430,
      "latitude": 40.661609,
      "longitude": -96.664859,
      "city": "Roca",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68431,
      "latitude": 40.046277,
      "longitude": -95.428531,
      "city": "Rulo",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68433,
      "latitude": 40.131136,
      "longitude": -95.659484,
      "city": "Salem",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68434,
      "latitude": 40.91582,
      "longitude": -97.101341,
      "city": "Seward",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68436,
      "latitude": 40.416166,
      "longitude": -97.722218,
      "city": "Shickley",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68437,
      "latitude": 40.236756,
      "longitude": -95.684024,
      "city": "Shubert",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68438,
      "latitude": 40.626661,
      "longitude": -96.745622,
      "city": "Sprague",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68439,
      "latitude": 40.95971,
      "longitude": -97.251684,
      "city": "Staplehurst",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68440,
      "latitude": 40.152861,
      "longitude": -97.126435,
      "city": "Steele City",
      "state": "NE",
      "county": "Jefferson"
  },
  {
      "zipCode": 68441,
      "latitude": 40.207313,
      "longitude": -96.231879,
      "city": "Steinauer",
      "state": "NE",
      "county": "Pawnee"
  },
  {
      "zipCode": 68442,
      "latitude": 40.23102,
      "longitude": -95.77285,
      "city": "Stella",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68443,
      "latitude": 40.463451,
      "longitude": -96.378249,
      "city": "Sterling",
      "state": "NE",
      "county": "Johnson"
  },
  {
      "zipCode": 68444,
      "latitude": 40.418921,
      "longitude": -97.578418,
      "city": "Strang",
      "state": "NE",
      "county": "Fillmore"
  },
  {
      "zipCode": 68445,
      "latitude": 40.379788,
      "longitude": -97.080431,
      "city": "Swanton",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68446,
      "latitude": 40.645896,
      "longitude": -96.169603,
      "city": "Syracuse",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68447,
      "latitude": 40.179541,
      "longitude": -96.097374,
      "city": "Table Rock",
      "state": "NE",
      "county": "Pawnee"
  },
  {
      "zipCode": 68448,
      "latitude": 40.55711,
      "longitude": -95.998514,
      "city": "Talmage",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68450,
      "latitude": 40.410949,
      "longitude": -96.280341,
      "city": "Tecumseh",
      "state": "NE",
      "county": "Johnson"
  },
  {
      "zipCode": 68452,
      "latitude": 40.524526,
      "longitude": -98.051184,
      "city": "Ong",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68453,
      "latitude": 40.418436,
      "longitude": -97.336671,
      "city": "Tobias",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68454,
      "latitude": 40.684941,
      "longitude": -96.270409,
      "city": "Unadilla",
      "state": "NE",
      "county": "Otoe"
  },
  {
      "zipCode": 68455,
      "latitude": 40.831238,
      "longitude": -95.903802,
      "city": "Union",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68456,
      "latitude": 40.938023,
      "longitude": -97.292178,
      "city": "Utica",
      "state": "NE",
      "county": "Seward"
  },
  {
      "zipCode": 68457,
      "latitude": 40.149228,
      "longitude": -95.710477,
      "city": "Verdon",
      "state": "NE",
      "county": "Richardson"
  },
  {
      "zipCode": 68458,
      "latitude": 40.262266,
      "longitude": -96.689998,
      "city": "Virginia",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68460,
      "latitude": 40.886702,
      "longitude": -97.437812,
      "city": "Waco",
      "state": "NE",
      "county": "York"
  },
  {
      "zipCode": 68461,
      "latitude": 40.762763,
      "longitude": -96.515859,
      "city": "Walton",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68462,
      "latitude": 40.935601,
      "longitude": -96.543431,
      "city": "Waverly",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68463,
      "latitude": 40.884503,
      "longitude": -96.146512,
      "city": "Weeping Water",
      "state": "NE",
      "county": "Cass"
  },
  {
      "zipCode": 68464,
      "latitude": 40.403717,
      "longitude": -97.265045,
      "city": "Western",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68465,
      "latitude": 40.484136,
      "longitude": -96.96829,
      "city": "Wilber",
      "state": "NE",
      "county": "Saline"
  },
  {
      "zipCode": 68466,
      "latitude": 40.122407,
      "longitude": -96.666863,
      "city": "Wymore",
      "state": "NE",
      "county": "Gage"
  },
  {
      "zipCode": 68467,
      "latitude": 40.890132,
      "longitude": -97.597278,
      "city": "York",
      "state": "NE",
      "county": "York"
  },
  {
      "zipCode": 68501,
      "latitude": 40.865142,
      "longitude": -96.823133,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68502,
      "latitude": 40.813101,
      "longitude": -96.683702,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68503,
      "latitude": 40.84505,
      "longitude": -96.698646,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68504,
      "latitude": 40.8532,
      "longitude": -96.662454,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68505,
      "latitude": 40.900275,
      "longitude": -96.618058,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68506,
      "latitude": 40.804064,
      "longitude": -96.687195,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68507,
      "latitude": 40.889449,
      "longitude": -96.652274,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68508,
      "latitude": 40.7981,
      "longitude": -96.624692,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68509,
      "latitude": 40.784451,
      "longitude": -96.688799,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68510,
      "latitude": 40.83545,
      "longitude": -96.630741,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68512,
      "latitude": 40.785689,
      "longitude": -96.686643,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68514,
      "latitude": 40.940125,
      "longitude": -96.662138,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68516,
      "latitude": 40.777556,
      "longitude": -96.661769,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68517,
      "latitude": 40.939903,
      "longitude": -96.609721,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68520,
      "latitude": 40.7842,
      "longitude": -96.534981,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68521,
      "latitude": 40.863301,
      "longitude": -96.718994,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68522,
      "latitude": 40.78456,
      "longitude": -96.750075,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68523,
      "latitude": 40.735793,
      "longitude": -96.763247,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68524,
      "latitude": 40.879252,
      "longitude": -96.815309,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68526,
      "latitude": 40.743346,
      "longitude": -96.582442,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68527,
      "latitude": 40.855121,
      "longitude": -96.525245,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68528,
      "latitude": 40.855107,
      "longitude": -96.813579,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68529,
      "latitude": 40.879752,
      "longitude": -96.779796,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68531,
      "latitude": 40.900801,
      "longitude": -96.720143,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68532,
      "latitude": 40.777663,
      "longitude": -96.853616,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68542,
      "latitude": 40.784451,
      "longitude": -96.688799,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68583,
      "latitude": 40.784451,
      "longitude": -96.688799,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68588,
      "latitude": 40.820645,
      "longitude": -96.692843,
      "city": "Lincoln",
      "state": "NE",
      "county": "Lancaster"
  },
  {
      "zipCode": 68601,
      "latitude": 41.542962,
      "longitude": -97.376314,
      "city": "Columbus",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68602,
      "latitude": 41.53808,
      "longitude": -97.541795,
      "city": "Columbus",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68620,
      "latitude": 41.648736,
      "longitude": -98.011092,
      "city": "Albion",
      "state": "NE",
      "county": "Boone"
  },
  {
      "zipCode": 68621,
      "latitude": 41.499208,
      "longitude": -96.624657,
      "city": "Ames",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68622,
      "latitude": 41.914601,
      "longitude": -98.528373,
      "city": "Bartlett",
      "state": "NE",
      "county": "Wheeler"
  },
  {
      "zipCode": 68623,
      "latitude": 41.471865,
      "longitude": -98.068163,
      "city": "Belgrade",
      "state": "NE",
      "county": "Nance"
  },
  {
      "zipCode": 68624,
      "latitude": 41.342556,
      "longitude": -97.214802,
      "city": "Bellwood",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68626,
      "latitude": 41.143956,
      "longitude": -96.992319,
      "city": "Brainard",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68627,
      "latitude": 41.558087,
      "longitude": -98.150139,
      "city": "Cedar Rapids",
      "state": "NE",
      "county": "Boone"
  },
  {
      "zipCode": 68628,
      "latitude": 41.202853,
      "longitude": -97.80937,
      "city": "Clarks",
      "state": "NE",
      "county": "Merrick"
  },
  {
      "zipCode": 68629,
      "latitude": 41.663195,
      "longitude": -97.137751,
      "city": "Clarkson",
      "state": "NE",
      "county": "Colfax"
  },
  {
      "zipCode": 68631,
      "latitude": 41.718708,
      "longitude": -97.372671,
      "city": "Creston",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68632,
      "latitude": 41.220715,
      "longitude": -97.157229,
      "city": "David City",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68633,
      "latitude": 41.593378,
      "longitude": -96.675369,
      "city": "Dodge",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68634,
      "latitude": 41.393146,
      "longitude": -97.595435,
      "city": "Duncan",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68635,
      "latitude": 41.066016,
      "longitude": -97.114365,
      "city": "Dwight",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68636,
      "latitude": 41.982761,
      "longitude": -98.083714,
      "city": "Elgin",
      "state": "NE",
      "county": "Antelope"
  },
  {
      "zipCode": 68637,
      "latitude": 41.779992,
      "longitude": -98.679988,
      "city": "Ericson",
      "state": "NE",
      "county": "Wheeler"
  },
  {
      "zipCode": 68638,
      "latitude": 41.414475,
      "longitude": -97.899889,
      "city": "Fullerton",
      "state": "NE",
      "county": "Nance"
  },
  {
      "zipCode": 68640,
      "latitude": 41.445863,
      "longitude": -97.732584,
      "city": "Genoa",
      "state": "NE",
      "county": "Nance"
  },
  {
      "zipCode": 68641,
      "latitude": 41.66172,
      "longitude": -97.103325,
      "city": "Howells",
      "state": "NE",
      "county": "Colfax"
  },
  {
      "zipCode": 68642,
      "latitude": 41.690226,
      "longitude": -97.487407,
      "city": "Humphrey",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68643,
      "latitude": 41.647114,
      "longitude": -97.184984,
      "city": "Leigh",
      "state": "NE",
      "county": "Colfax"
  },
  {
      "zipCode": 68644,
      "latitude": 41.701476,
      "longitude": -97.694247,
      "city": "Lindsay",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68647,
      "latitude": 41.465573,
      "longitude": -97.625525,
      "city": "Monroe",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68648,
      "latitude": 41.375782,
      "longitude": -96.811409,
      "city": "Morse Bluff",
      "state": "NE",
      "county": "Saunders"
  },
  {
      "zipCode": 68649,
      "latitude": 41.54148,
      "longitude": -96.685416,
      "city": "North Bend",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68651,
      "latitude": 41.170633,
      "longitude": -97.57,
      "city": "Osceola",
      "state": "NE",
      "county": "Polk"
  },
  {
      "zipCode": 68652,
      "latitude": 41.853086,
      "longitude": -98.07996,
      "city": "Petersburg",
      "state": "NE",
      "county": "Boone"
  },
  {
      "zipCode": 68653,
      "latitude": 41.537151,
      "longitude": -97.48488,
      "city": "Platte Center",
      "state": "NE",
      "county": "Platte"
  },
  {
      "zipCode": 68654,
      "latitude": 41.075237,
      "longitude": -97.78707,
      "city": "Polk",
      "state": "NE",
      "county": "Polk"
  },
  {
      "zipCode": 68655,
      "latitude": 41.698452,
      "longitude": -98.062909,
      "city": "Primrose",
      "state": "NE",
      "county": "Boone"
  },
  {
      "zipCode": 68658,
      "latitude": 41.19712,
      "longitude": -97.181952,
      "city": "Rising City",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68659,
      "latitude": 41.593926,
      "longitude": -96.956019,
      "city": "Rogers",
      "state": "NE",
      "county": "Colfax"
  },
  {
      "zipCode": 68660,
      "latitude": 41.570444,
      "longitude": -97.863484,
      "city": "Saint Edward",
      "state": "NE",
      "county": "Boone"
  },
  {
      "zipCode": 68661,
      "latitude": 41.559744,
      "longitude": -97.079635,
      "city": "Schuyler",
      "state": "NE",
      "county": "Colfax"
  },
  {
      "zipCode": 68662,
      "latitude": 41.154703,
      "longitude": -97.519253,
      "city": "Shelby",
      "state": "NE",
      "county": "Polk"
  },
  {
      "zipCode": 68663,
      "latitude": 41.303844,
      "longitude": -97.723453,
      "city": "Silver Creek",
      "state": "NE",
      "county": "Merrick"
  },
  {
      "zipCode": 68664,
      "latitude": 41.648478,
      "longitude": -96.787252,
      "city": "Snyder",
      "state": "NE",
      "county": "Dodge"
  },
  {
      "zipCode": 68665,
      "latitude": 41.688144,
      "longitude": -98.363282,
      "city": "Spalding",
      "state": "NE",
      "county": "Greeley"
  },
  {
      "zipCode": 68666,
      "latitude": 41.114446,
      "longitude": -97.591433,
      "city": "Stromsburg",
      "state": "NE",
      "county": "Polk"
  },
  {
      "zipCode": 68667,
      "latitude": 41.126818,
      "longitude": -97.292178,
      "city": "Surprise",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68669,
      "latitude": 41.09176,
      "longitude": -97.224683,
      "city": "Ulysses",
      "state": "NE",
      "county": "Butler"
  },
  {
      "zipCode": 68701,
      "latitude": 41.99641,
      "longitude": -97.601189,
      "city": "Norfolk",
      "state": "NE",
      "county": "Madison"
  },
  {
      "zipCode": 68702,
      "latitude": 41.916476,
      "longitude": -97.601348,
      "city": "Norfolk",
      "state": "NE",
      "county": "Madison"
  },
  {
      "zipCode": 68710,
      "latitude": 42.445278,
      "longitude": -96.884378,
      "city": "Allen",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68711,
      "latitude": 42.556421,
      "longitude": -99.032048,
      "city": "Amelia",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68713,
      "latitude": 42.533852,
      "longitude": -98.975603,
      "city": "Atkinson",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68714,
      "latitude": 42.583147,
      "longitude": -99.551245,
      "city": "Bassett",
      "state": "NE",
      "county": "Rock"
  },
  {
      "zipCode": 68715,
      "latitude": 41.976904,
      "longitude": -97.568855,
      "city": "Battle Creek",
      "state": "NE",
      "county": "Madison"
  },
  {
      "zipCode": 68716,
      "latitude": 41.909399,
      "longitude": -96.816403,
      "city": "Beemer",
      "state": "NE",
      "county": "Cuming"
  },
  {
      "zipCode": 68717,
      "latitude": 42.429295,
      "longitude": -97.212986,
      "city": "Belden",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68718,
      "latitude": 42.628731,
      "longitude": -97.659606,
      "city": "Bloomfield",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68719,
      "latitude": 42.879903,
      "longitude": -98.781334,
      "city": "Bristow",
      "state": "NE",
      "county": "Boyd"
  },
  {
      "zipCode": 68720,
      "latitude": 42.176397,
      "longitude": -98.066911,
      "city": "Brunswick",
      "state": "NE",
      "county": "Antelope"
  },
  {
      "zipCode": 68722,
      "latitude": 42.911492,
      "longitude": -98.847065,
      "city": "Butte",
      "state": "NE",
      "county": "Boyd"
  },
  {
      "zipCode": 68723,
      "latitude": 42.286328,
      "longitude": -97.193337,
      "city": "Carroll",
      "state": "NE",
      "county": "Wayne"
  },
  {
      "zipCode": 68724,
      "latitude": 42.597304,
      "longitude": -97.866951,
      "city": "Center",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68725,
      "latitude": 42.20186,
      "longitude": -98.749451,
      "city": "Chambers",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68726,
      "latitude": 42.176397,
      "longitude": -98.066911,
      "city": "Clearwater",
      "state": "NE",
      "county": "Antelope"
  },
  {
      "zipCode": 68727,
      "latitude": 42.530505,
      "longitude": -97.260393,
      "city": "Coleridge",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68728,
      "latitude": 42.493637,
      "longitude": -96.853429,
      "city": "Concord",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68729,
      "latitude": 42.490856,
      "longitude": -97.919351,
      "city": "Creighton",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68730,
      "latitude": 42.758387,
      "longitude": -97.603391,
      "city": "Crofton",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68731,
      "latitude": 42.381775,
      "longitude": -96.565385,
      "city": "Dakota City",
      "state": "NE",
      "county": "Dakota"
  },
  {
      "zipCode": 68732,
      "latitude": 42.480297,
      "longitude": -96.967598,
      "city": "Dixon",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68733,
      "latitude": 42.361551,
      "longitude": -96.639317,
      "city": "Emerson",
      "state": "NE",
      "county": "Dakota"
  },
  {
      "zipCode": 68734,
      "latitude": 42.492295,
      "longitude": -98.778556,
      "city": "Emmet",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68735,
      "latitude": 42.259207,
      "longitude": -98.344313,
      "city": "Ewing",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68736,
      "latitude": 42.728606,
      "longitude": -97.319381,
      "city": "Fordyce",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68737,
      "latitude": 42.264356,
      "longitude": -97.601337,
      "city": "Foster",
      "state": "NE",
      "county": "Pierce"
  },
  {
      "zipCode": 68738,
      "latitude": 42.264356,
      "longitude": -97.601337,
      "city": "Hadar",
      "state": "NE",
      "county": "Pierce"
  },
  {
      "zipCode": 68739,
      "latitude": 42.591685,
      "longitude": -97.250509,
      "city": "Hartington",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68740,
      "latitude": 42.221141,
      "longitude": -97.309909,
      "city": "Hoskins",
      "state": "NE",
      "county": "Wayne"
  },
  {
      "zipCode": 68741,
      "latitude": 42.357595,
      "longitude": -96.603097,
      "city": "Hubbard",
      "state": "NE",
      "county": "Dakota"
  },
  {
      "zipCode": 68742,
      "latitude": 42.380604,
      "longitude": -98.530866,
      "city": "Inman",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68743,
      "latitude": 42.452053,
      "longitude": -96.631738,
      "city": "Jackson",
      "state": "NE",
      "county": "Dakota"
  },
  {
      "zipCode": 68745,
      "latitude": 42.475072,
      "longitude": -97.142457,
      "city": "Laurel",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68746,
      "latitude": 42.879903,
      "longitude": -98.781334,
      "city": "Lynch",
      "state": "NE",
      "county": "Boyd"
  },
  {
      "zipCode": 68747,
      "latitude": 42.438634,
      "longitude": -97.475151,
      "city": "Mclean",
      "state": "NE",
      "county": "Pierce"
  },
  {
      "zipCode": 68748,
      "latitude": 41.881046,
      "longitude": -97.48448,
      "city": "Madison",
      "state": "NE",
      "county": "Madison"
  },
  {
      "zipCode": 68749,
      "latitude": 42.609687,
      "longitude": -97.250378,
      "city": "Magnet",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68751,
      "latitude": 42.481711,
      "longitude": -96.926042,
      "city": "Maskell",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68752,
      "latitude": 42.034395,
      "longitude": -97.782829,
      "city": "Meadow Grove",
      "state": "NE",
      "county": "Madison"
  },
  {
      "zipCode": 68753,
      "latitude": 42.858168,
      "longitude": -99.726221,
      "city": "Mills",
      "state": "NE",
      "county": "Keya Paha"
  },
  {
      "zipCode": 68755,
      "latitude": 42.879903,
      "longitude": -98.781334,
      "city": "Naper",
      "state": "NE",
      "county": "Boyd"
  },
  {
      "zipCode": 68756,
      "latitude": 42.216925,
      "longitude": -98.13176,
      "city": "Neligh",
      "state": "NE",
      "county": "Antelope"
  },
  {
      "zipCode": 68757,
      "latitude": 42.494685,
      "longitude": -96.861558,
      "city": "Newcastle",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68758,
      "latitude": 41.747884,
      "longitude": -97.777198,
      "city": "Newman Grove",
      "state": "NE",
      "county": "Madison"
  },
  {
      "zipCode": 68759,
      "latitude": 42.858168,
      "longitude": -99.726221,
      "city": "Newport",
      "state": "NE",
      "county": "Keya Paha"
  },
  {
      "zipCode": 68760,
      "latitude": 42.739127,
      "longitude": -97.924783,
      "city": "Niobrara",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68761,
      "latitude": 42.176397,
      "longitude": -98.066911,
      "city": "Oakdale",
      "state": "NE",
      "county": "Antelope"
  },
  {
      "zipCode": 68763,
      "latitude": 42.370456,
      "longitude": -98.665685,
      "city": "Oneill",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68764,
      "latitude": 42.337003,
      "longitude": -98.239908,
      "city": "Orchard",
      "state": "NE",
      "county": "Antelope"
  },
  {
      "zipCode": 68765,
      "latitude": 42.360661,
      "longitude": -97.598511,
      "city": "Osmond",
      "state": "NE",
      "county": "Pierce"
  },
  {
      "zipCode": 68766,
      "latitude": 42.492295,
      "longitude": -98.778556,
      "city": "Page",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68767,
      "latitude": 42.224218,
      "longitude": -97.581551,
      "city": "Pierce",
      "state": "NE",
      "county": "Pierce"
  },
  {
      "zipCode": 68768,
      "latitude": 41.976143,
      "longitude": -97.07791,
      "city": "Pilger",
      "state": "NE",
      "county": "Stanton"
  },
  {
      "zipCode": 68769,
      "latitude": 42.35378,
      "longitude": -97.788065,
      "city": "Plainview",
      "state": "NE",
      "county": "Pierce"
  },
  {
      "zipCode": 68770,
      "latitude": 42.565991,
      "longitude": -96.711058,
      "city": "Ponca",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68771,
      "latitude": 42.438743,
      "longitude": -97.319835,
      "city": "Randolph",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68772,
      "latitude": 42.445152,
      "longitude": -99.455891,
      "city": "Rose",
      "state": "NE",
      "county": "Rock"
  },
  {
      "zipCode": 68773,
      "latitude": 42.176397,
      "longitude": -98.066911,
      "city": "Royal",
      "state": "NE",
      "county": "Antelope"
  },
  {
      "zipCode": 68774,
      "latitude": 42.804591,
      "longitude": -97.304691,
      "city": "Saint Helena",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68776,
      "latitude": 42.459711,
      "longitude": -96.457068,
      "city": "South Sioux City",
      "state": "NE",
      "county": "Dakota"
  },
  {
      "zipCode": 68777,
      "latitude": 42.872702,
      "longitude": -98.701552,
      "city": "Spencer",
      "state": "NE",
      "county": "Boyd"
  },
  {
      "zipCode": 68778,
      "latitude": 42.836424,
      "longitude": -99.747046,
      "city": "Springview",
      "state": "NE",
      "county": "Keya Paha"
  },
  {
      "zipCode": 68779,
      "latitude": 41.982175,
      "longitude": -97.203396,
      "city": "Stanton",
      "state": "NE",
      "county": "Stanton"
  },
  {
      "zipCode": 68780,
      "latitude": 42.599715,
      "longitude": -99.141514,
      "city": "Stuart",
      "state": "NE",
      "county": "Holt"
  },
  {
      "zipCode": 68781,
      "latitude": 42.042732,
      "longitude": -97.829855,
      "city": "Tilden",
      "state": "NE",
      "county": "Madison"
  },
  {
      "zipCode": 68783,
      "latitude": 42.624821,
      "longitude": -98.129817,
      "city": "Verdigre",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68784,
      "latitude": 42.307797,
      "longitude": -96.930286,
      "city": "Wakefield",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68785,
      "latitude": 42.512745,
      "longitude": -96.824742,
      "city": "Waterbury",
      "state": "NE",
      "county": "Dixon"
  },
  {
      "zipCode": 68786,
      "latitude": 42.518247,
      "longitude": -97.608966,
      "city": "Wausa",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68787,
      "latitude": 42.206417,
      "longitude": -96.979049,
      "city": "Wayne",
      "state": "NE",
      "county": "Wayne"
  },
  {
      "zipCode": 68788,
      "latitude": 41.870968,
      "longitude": -96.777787,
      "city": "West Point",
      "state": "NE",
      "county": "Cuming"
  },
  {
      "zipCode": 68789,
      "latitude": 42.533387,
      "longitude": -98.004988,
      "city": "Winnetoon",
      "state": "NE",
      "county": "Knox"
  },
  {
      "zipCode": 68790,
      "latitude": 42.155768,
      "longitude": -97.193232,
      "city": "Winside",
      "state": "NE",
      "county": "Wayne"
  },
  {
      "zipCode": 68791,
      "latitude": 41.960145,
      "longitude": -96.893932,
      "city": "Wisner",
      "state": "NE",
      "county": "Cuming"
  },
  {
      "zipCode": 68792,
      "latitude": 42.708262,
      "longitude": -97.161919,
      "city": "Wynot",
      "state": "NE",
      "county": "Cedar"
  },
  {
      "zipCode": 68801,
      "latitude": 40.87227,
      "longitude": -98.368698,
      "city": "Grand Island",
      "state": "NE",
      "county": "Hall"
  },
  {
      "zipCode": 68802,
      "latitude": 40.872457,
      "longitude": -98.50213,
      "city": "Grand Island",
      "state": "NE",
      "county": "Hall"
  },
  {
      "zipCode": 68803,
      "latitude": 40.872531,
      "longitude": -98.521343,
      "city": "Grand Island",
      "state": "NE",
      "county": "Hall"
  },
  {
      "zipCode": 68810,
      "latitude": 40.860721,
      "longitude": -98.504787,
      "city": "Alda",
      "state": "NE",
      "county": "Hall"
  },
  {
      "zipCode": 68812,
      "latitude": 40.916581,
      "longitude": -99.292974,
      "city": "Amherst",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68813,
      "latitude": 41.620773,
      "longitude": -99.864392,
      "city": "Anselmo",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68814,
      "latitude": 41.289827,
      "longitude": -99.381735,
      "city": "Ansley",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68815,
      "latitude": 41.52244,
      "longitude": -99.038945,
      "city": "Arcadia",
      "state": "NE",
      "county": "Valley"
  },
  {
      "zipCode": 68816,
      "latitude": 41.140845,
      "longitude": -98.13559,
      "city": "Archer",
      "state": "NE",
      "county": "Merrick"
  },
  {
      "zipCode": 68817,
      "latitude": 41.249476,
      "longitude": -98.79476,
      "city": "Ashton",
      "state": "NE",
      "county": "Sherman"
  },
  {
      "zipCode": 68818,
      "latitude": 40.850832,
      "longitude": -98.054272,
      "city": "Aurora",
      "state": "NE",
      "county": "Hamilton"
  },
  {
      "zipCode": 68819,
      "latitude": 41.35201,
      "longitude": -99.502374,
      "city": "Berwyn",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68820,
      "latitude": 41.11924,
      "longitude": -98.681367,
      "city": "Boelus",
      "state": "NE",
      "county": "Howard"
  },
  {
      "zipCode": 68821,
      "latitude": 41.936376,
      "longitude": -99.857111,
      "city": "Brewster",
      "state": "NE",
      "county": "Blaine"
  },
  {
      "zipCode": 68822,
      "latitude": 41.451119,
      "longitude": -99.621491,
      "city": "Broken Bow",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68823,
      "latitude": 41.782277,
      "longitude": -99.134041,
      "city": "Burwell",
      "state": "NE",
      "county": "Garfield"
  },
  {
      "zipCode": 68824,
      "latitude": 40.908706,
      "longitude": -98.568185,
      "city": "Cairo",
      "state": "NE",
      "county": "Hall"
  },
  {
      "zipCode": 68825,
      "latitude": 41.290502,
      "longitude": -99.921245,
      "city": "Callaway",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68826,
      "latitude": 41.162876,
      "longitude": -98.010548,
      "city": "Central City",
      "state": "NE",
      "county": "Merrick"
  },
  {
      "zipCode": 68827,
      "latitude": 41.046263,
      "longitude": -98.188441,
      "city": "Chapman",
      "state": "NE",
      "county": "Merrick"
  },
  {
      "zipCode": 68828,
      "latitude": 41.393902,
      "longitude": -99.728097,
      "city": "Comstock",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68831,
      "latitude": 41.16614,
      "longitude": -98.546616,
      "city": "Dannebrog",
      "state": "NE",
      "county": "Howard"
  },
  {
      "zipCode": 68832,
      "latitude": 40.800367,
      "longitude": -98.446665,
      "city": "Doniphan",
      "state": "NE",
      "county": "Hall"
  },
  {
      "zipCode": 68833,
      "latitude": 41.912303,
      "longitude": -99.977135,
      "city": "Dunning",
      "state": "NE",
      "county": "Blaine"
  },
  {
      "zipCode": 68834,
      "latitude": 40.980376,
      "longitude": -99.685939,
      "city": "Eddyville",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 68835,
      "latitude": 41.32178,
      "longitude": -98.604437,
      "city": "Elba",
      "state": "NE",
      "county": "Howard"
  },
  {
      "zipCode": 68836,
      "latitude": 40.779914,
      "longitude": -99.331426,
      "city": "Elm Creek",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68837,
      "latitude": 41.567344,
      "longitude": -98.981592,
      "city": "Elyria",
      "state": "NE",
      "county": "Valley"
  },
  {
      "zipCode": 68838,
      "latitude": 41.206635,
      "longitude": -98.679842,
      "city": "Farwell",
      "state": "NE",
      "county": "Howard"
  },
  {
      "zipCode": 68840,
      "latitude": 40.770884,
      "longitude": -98.882818,
      "city": "Gibbon",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68841,
      "latitude": 40.763612,
      "longitude": -98.158838,
      "city": "Giltner",
      "state": "NE",
      "county": "Hamilton"
  },
  {
      "zipCode": 68842,
      "latitude": 41.563806,
      "longitude": -98.530685,
      "city": "Greeley",
      "state": "NE",
      "county": "Greeley"
  },
  {
      "zipCode": 68843,
      "latitude": 40.933448,
      "longitude": -98.021035,
      "city": "Hampton",
      "state": "NE",
      "county": "Hamilton"
  },
  {
      "zipCode": 68844,
      "latitude": 41.220515,
      "longitude": -98.975997,
      "city": "Hazard",
      "state": "NE",
      "county": "Sherman"
  },
  {
      "zipCode": 68845,
      "latitude": 40.75141,
      "longitude": -99.129054,
      "city": "Kearney",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68846,
      "latitude": 40.962667,
      "longitude": -97.991525,
      "city": "Hordville",
      "state": "NE",
      "county": "Hamilton"
  },
  {
      "zipCode": 68847,
      "latitude": 40.850493,
      "longitude": -99.073398,
      "city": "Kearney",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68848,
      "latitude": 40.686053,
      "longitude": -99.069318,
      "city": "Kearney",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68849,
      "latitude": 40.849654,
      "longitude": -99.074058,
      "city": "Kearney",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68850,
      "latitude": 40.873227,
      "longitude": -99.718408,
      "city": "Lexington",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 68852,
      "latitude": 41.156948,
      "longitude": -99.153012,
      "city": "Litchfield",
      "state": "NE",
      "county": "Sherman"
  },
  {
      "zipCode": 68853,
      "latitude": 41.276435,
      "longitude": -98.966954,
      "city": "Loup City",
      "state": "NE",
      "county": "Sherman"
  },
  {
      "zipCode": 68854,
      "latitude": 41.015778,
      "longitude": -98.008324,
      "city": "Marquette",
      "state": "NE",
      "county": "Hamilton"
  },
  {
      "zipCode": 68855,
      "latitude": 41.283369,
      "longitude": -99.37871,
      "city": "Mason City",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68856,
      "latitude": 41.484951,
      "longitude": -99.760782,
      "city": "Merna",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68858,
      "latitude": 40.952708,
      "longitude": -99.364921,
      "city": "Miller",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68859,
      "latitude": 41.567344,
      "longitude": -98.981592,
      "city": "North Loup",
      "state": "NE",
      "county": "Valley"
  },
  {
      "zipCode": 68860,
      "latitude": 41.393902,
      "longitude": -99.728097,
      "city": "Oconto",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68861,
      "latitude": 40.72996,
      "longitude": -99.283748,
      "city": "Odessa",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68862,
      "latitude": 41.603603,
      "longitude": -98.932718,
      "city": "Ord",
      "state": "NE",
      "county": "Valley"
  },
  {
      "zipCode": 68863,
      "latitude": 40.801246,
      "longitude": -99.521933,
      "city": "Overton",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 68864,
      "latitude": 41.244701,
      "longitude": -98.182952,
      "city": "Palmer",
      "state": "NE",
      "county": "Merrick"
  },
  {
      "zipCode": 68865,
      "latitude": 40.893539,
      "longitude": -98.102223,
      "city": "Phillips",
      "state": "NE",
      "county": "Hamilton"
  },
  {
      "zipCode": 68866,
      "latitude": 40.960145,
      "longitude": -99.121861,
      "city": "Pleasanton",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68869,
      "latitude": 40.960075,
      "longitude": -98.902302,
      "city": "Ravenna",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68870,
      "latitude": 40.829478,
      "longitude": -99.126061,
      "city": "Riverdale",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68871,
      "latitude": 41.117178,
      "longitude": -98.832654,
      "city": "Rockville",
      "state": "NE",
      "county": "Sherman"
  },
  {
      "zipCode": 68872,
      "latitude": 41.096855,
      "longitude": -98.450297,
      "city": "Saint Libory",
      "state": "NE",
      "county": "Howard"
  },
  {
      "zipCode": 68873,
      "latitude": 41.232405,
      "longitude": -98.503283,
      "city": "Saint Paul",
      "state": "NE",
      "county": "Howard"
  },
  {
      "zipCode": 68874,
      "latitude": 41.645695,
      "longitude": -99.371585,
      "city": "Sargent",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68875,
      "latitude": 41.467579,
      "longitude": -98.703727,
      "city": "Scotia",
      "state": "NE",
      "county": "Greeley"
  },
  {
      "zipCode": 68876,
      "latitude": 40.812713,
      "longitude": -98.788349,
      "city": "Shelton",
      "state": "NE",
      "county": "Buffalo"
  },
  {
      "zipCode": 68878,
      "latitude": 40.959402,
      "longitude": -99.539866,
      "city": "Sumner",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 68879,
      "latitude": 41.91406,
      "longitude": -99.454335,
      "city": "Taylor",
      "state": "NE",
      "county": "Loup"
  },
  {
      "zipCode": 68880,
      "latitude": 41.393902,
      "longitude": -99.728097,
      "city": "Weissert",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68881,
      "latitude": 41.393902,
      "longitude": -99.728097,
      "city": "Westerville",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 68882,
      "latitude": 41.397214,
      "longitude": -98.431898,
      "city": "Wolbach",
      "state": "NE",
      "county": "Greeley"
  },
  {
      "zipCode": 68883,
      "latitude": 40.872494,
      "longitude": -98.589324,
      "city": "Wood River",
      "state": "NE",
      "county": "Hall"
  },
  {
      "zipCode": 68901,
      "latitude": 40.582425,
      "longitude": -98.389035,
      "city": "Hastings",
      "state": "NE",
      "county": "Adams"
  },
  {
      "zipCode": 68902,
      "latitude": 40.589594,
      "longitude": -98.397248,
      "city": "Hastings",
      "state": "NE",
      "county": "Adams"
  },
  {
      "zipCode": 68920,
      "latitude": 40.101761,
      "longitude": -99.352846,
      "city": "Alma",
      "state": "NE",
      "county": "Harlan"
  },
  {
      "zipCode": 68922,
      "latitude": 40.294628,
      "longitude": -99.842844,
      "city": "Arapahoe",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 68923,
      "latitude": 40.410446,
      "longitude": -99.405055,
      "city": "Atlanta",
      "state": "NE",
      "county": "Phelps"
  },
  {
      "zipCode": 68924,
      "latitude": 40.50339,
      "longitude": -99.091654,
      "city": "Axtell",
      "state": "NE",
      "county": "Kearney"
  },
  {
      "zipCode": 68925,
      "latitude": 40.429989,
      "longitude": -98.463055,
      "city": "Ayr",
      "state": "NE",
      "county": "Adams"
  },
  {
      "zipCode": 68926,
      "latitude": 40.137671,
      "longitude": -99.82693,
      "city": "Beaver City",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 68927,
      "latitude": 40.525235,
      "longitude": -99.634275,
      "city": "Bertrand",
      "state": "NE",
      "county": "Phelps"
  },
  {
      "zipCode": 68928,
      "latitude": 40.323796,
      "longitude": -98.597026,
      "city": "Bladen",
      "state": "NE",
      "county": "Webster"
  },
  {
      "zipCode": 68929,
      "latitude": 40.097871,
      "longitude": -99.037737,
      "city": "Bloomington",
      "state": "NE",
      "county": "Franklin"
  },
  {
      "zipCode": 68930,
      "latitude": 40.316739,
      "longitude": -98.450468,
      "city": "Blue Hill",
      "state": "NE",
      "county": "Webster"
  },
  {
      "zipCode": 68932,
      "latitude": 40.306364,
      "longitude": -98.814655,
      "city": "Campbell",
      "state": "NE",
      "county": "Franklin"
  },
  {
      "zipCode": 68933,
      "latitude": 40.565856,
      "longitude": -98.070907,
      "city": "Clay Center",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68934,
      "latitude": 40.35398,
      "longitude": -98.140015,
      "city": "Deweese",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68935,
      "latitude": 40.379736,
      "longitude": -97.905641,
      "city": "Edgar",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68936,
      "latitude": 40.277359,
      "longitude": -99.776089,
      "city": "Edison",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 68937,
      "latitude": 40.56962,
      "longitude": -99.831972,
      "city": "Elwood",
      "state": "NE",
      "county": "Gosper"
  },
  {
      "zipCode": 68938,
      "latitude": 40.395335,
      "longitude": -98.053432,
      "city": "Fairfield",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68939,
      "latitude": 40.096305,
      "longitude": -98.995406,
      "city": "Franklin",
      "state": "NE",
      "county": "Franklin"
  },
  {
      "zipCode": 68940,
      "latitude": 40.464046,
      "longitude": -99.251676,
      "city": "Funk",
      "state": "NE",
      "county": "Phelps"
  },
  {
      "zipCode": 68941,
      "latitude": 40.503252,
      "longitude": -98.255757,
      "city": "Glenvil",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68942,
      "latitude": 40.071648,
      "longitude": -98.330001,
      "city": "Guide Rock",
      "state": "NE",
      "county": "Webster"
  },
  {
      "zipCode": 68943,
      "latitude": 40.074907,
      "longitude": -97.900651,
      "city": "Hardy",
      "state": "NE",
      "county": "Nuckolls"
  },
  {
      "zipCode": 68944,
      "latitude": 40.620578,
      "longitude": -98.096849,
      "city": "Harvard",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68945,
      "latitude": 40.55428,
      "longitude": -98.790623,
      "city": "Heartwell",
      "state": "NE",
      "county": "Kearney"
  },
  {
      "zipCode": 68946,
      "latitude": 40.176314,
      "longitude": -99.913076,
      "city": "Hendley",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 68947,
      "latitude": 40.337828,
      "longitude": -99.043138,
      "city": "Hildreth",
      "state": "NE",
      "county": "Franklin"
  },
  {
      "zipCode": 68948,
      "latitude": 40.304641,
      "longitude": -99.959503,
      "city": "Holbrook",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 68949,
      "latitude": 40.429207,
      "longitude": -99.325955,
      "city": "Holdrege",
      "state": "NE",
      "county": "Phelps"
  },
  {
      "zipCode": 68950,
      "latitude": 40.437334,
      "longitude": -98.638633,
      "city": "Holstein",
      "state": "NE",
      "county": "Adams"
  },
  {
      "zipCode": 68952,
      "latitude": 40.176368,
      "longitude": -98.500037,
      "city": "Inavale",
      "state": "NE",
      "county": "Webster"
  },
  {
      "zipCode": 68954,
      "latitude": 40.524526,
      "longitude": -98.051184,
      "city": "Inland",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68955,
      "latitude": 40.596951,
      "longitude": -98.506045,
      "city": "Juniata",
      "state": "NE",
      "county": "Adams"
  },
  {
      "zipCode": 68956,
      "latitude": 40.611581,
      "longitude": -98.610696,
      "city": "Kenesaw",
      "state": "NE",
      "county": "Adams"
  },
  {
      "zipCode": 68957,
      "latitude": 40.291047,
      "longitude": -98.25954,
      "city": "Lawrence",
      "state": "NE",
      "county": "Nuckolls"
  },
  {
      "zipCode": 68958,
      "latitude": 40.477867,
      "longitude": -99.508075,
      "city": "Loomis",
      "state": "NE",
      "county": "Phelps"
  },
  {
      "zipCode": 68959,
      "latitude": 40.502515,
      "longitude": -98.907731,
      "city": "Minden",
      "state": "NE",
      "county": "Kearney"
  },
  {
      "zipCode": 68960,
      "latitude": 40.074785,
      "longitude": -99.137325,
      "city": "Naponee",
      "state": "NE",
      "county": "Franklin"
  },
  {
      "zipCode": 68961,
      "latitude": 40.20105,
      "longitude": -98.068371,
      "city": "Nelson",
      "state": "NE",
      "county": "Nuckolls"
  },
  {
      "zipCode": 68963,
      "latitude": 40.478879,
      "longitude": -98.792699,
      "city": "Norman",
      "state": "NE",
      "county": "Kearney"
  },
  {
      "zipCode": 68964,
      "latitude": 40.237813,
      "longitude": -97.903047,
      "city": "Oak",
      "state": "NE",
      "county": "Nuckolls"
  },
  {
      "zipCode": 68966,
      "latitude": 40.130072,
      "longitude": -99.45511,
      "city": "Orleans",
      "state": "NE",
      "county": "Harlan"
  },
  {
      "zipCode": 68967,
      "latitude": 40.254078,
      "longitude": -99.637884,
      "city": "Oxford",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 68969,
      "latitude": 40.176513,
      "longitude": -99.404864,
      "city": "Ragan",
      "state": "NE",
      "county": "Harlan"
  },
  {
      "zipCode": 68970,
      "latitude": 40.201919,
      "longitude": -98.500021,
      "city": "Red Cloud",
      "state": "NE",
      "county": "Webster"
  },
  {
      "zipCode": 68971,
      "latitude": 40.093106,
      "longitude": -99.246197,
      "city": "Republican City",
      "state": "NE",
      "county": "Harlan"
  },
  {
      "zipCode": 68972,
      "latitude": 40.176335,
      "longitude": -98.952642,
      "city": "Riverton",
      "state": "NE",
      "county": "Franklin"
  },
  {
      "zipCode": 68973,
      "latitude": 40.466384,
      "longitude": -98.544036,
      "city": "Roseland",
      "state": "NE",
      "county": "Adams"
  },
  {
      "zipCode": 68974,
      "latitude": 40.152394,
      "longitude": -97.91468,
      "city": "Ruskin",
      "state": "NE",
      "county": "Nuckolls"
  },
  {
      "zipCode": 68975,
      "latitude": 40.602128,
      "longitude": -97.938811,
      "city": "Saronville",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68976,
      "latitude": 40.539998,
      "longitude": -99.830345,
      "city": "Smithfield",
      "state": "NE",
      "county": "Gosper"
  },
  {
      "zipCode": 68977,
      "latitude": 40.176513,
      "longitude": -99.404864,
      "city": "Stamford",
      "state": "NE",
      "county": "Harlan"
  },
  {
      "zipCode": 68978,
      "latitude": 40.036673,
      "longitude": -98.069593,
      "city": "Superior",
      "state": "NE",
      "county": "Nuckolls"
  },
  {
      "zipCode": 68979,
      "latitude": 40.507387,
      "longitude": -97.85543,
      "city": "Sutton",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68980,
      "latitude": 40.639247,
      "longitude": -98.25112,
      "city": "Trumbull",
      "state": "NE",
      "county": "Clay"
  },
  {
      "zipCode": 68981,
      "latitude": 40.31743,
      "longitude": -98.904418,
      "city": "Upland",
      "state": "NE",
      "county": "Franklin"
  },
  {
      "zipCode": 68982,
      "latitude": 40.378795,
      "longitude": -99.152181,
      "city": "Wilcox",
      "state": "NE",
      "county": "Kearney"
  },
  {
      "zipCode": 69001,
      "latitude": 40.207969,
      "longitude": -100.620628,
      "city": "Mc Cook",
      "state": "NE",
      "county": "Red Willow"
  },
  {
      "zipCode": 69020,
      "latitude": 40.252545,
      "longitude": -100.305432,
      "city": "Bartley",
      "state": "NE",
      "county": "Red Willow"
  },
  {
      "zipCode": 69021,
      "latitude": 40.103088,
      "longitude": -101.789168,
      "city": "Benkelman",
      "state": "NE",
      "county": "Dundy"
  },
  {
      "zipCode": 69022,
      "latitude": 40.269966,
      "longitude": -100.171378,
      "city": "Cambridge",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 69023,
      "latitude": 40.52473,
      "longitude": -101.69724,
      "city": "Champion",
      "state": "NE",
      "county": "Chase"
  },
  {
      "zipCode": 69024,
      "latitude": 40.231495,
      "longitude": -100.835774,
      "city": "Culbertson",
      "state": "NE",
      "county": "Hitchcock"
  },
  {
      "zipCode": 69025,
      "latitude": 40.643861,
      "longitude": -100.54399,
      "city": "Curtis",
      "state": "NE",
      "county": "Frontier"
  },
  {
      "zipCode": 69026,
      "latitude": 40.176507,
      "longitude": -100.476977,
      "city": "Danbury",
      "state": "NE",
      "county": "Red Willow"
  },
  {
      "zipCode": 69027,
      "latitude": 40.52473,
      "longitude": -101.69724,
      "city": "Enders",
      "state": "NE",
      "county": "Chase"
  },
  {
      "zipCode": 69028,
      "latitude": 40.564186,
      "longitude": -100.00978,
      "city": "Eustis",
      "state": "NE",
      "county": "Frontier"
  },
  {
      "zipCode": 69029,
      "latitude": 40.740544,
      "longitude": -100.173314,
      "city": "Farnam",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 69030,
      "latitude": 40.176417,
      "longitude": -101.687688,
      "city": "Haigler",
      "state": "NE",
      "county": "Dundy"
  },
  {
      "zipCode": 69031,
      "latitude": 40.524819,
      "longitude": -101.061328,
      "city": "Hamlet",
      "state": "NE",
      "county": "Hayes"
  },
  {
      "zipCode": 69032,
      "latitude": 40.524819,
      "longitude": -101.061328,
      "city": "Hayes Center",
      "state": "NE",
      "county": "Hayes"
  },
  {
      "zipCode": 69033,
      "latitude": 40.515114,
      "longitude": -101.632038,
      "city": "Imperial",
      "state": "NE",
      "county": "Chase"
  },
  {
      "zipCode": 69034,
      "latitude": 40.240034,
      "longitude": -100.365256,
      "city": "Indianola",
      "state": "NE",
      "county": "Red Willow"
  },
  {
      "zipCode": 69036,
      "latitude": 40.176507,
      "longitude": -100.476977,
      "city": "Lebanon",
      "state": "NE",
      "county": "Red Willow"
  },
  {
      "zipCode": 69037,
      "latitude": 40.176417,
      "longitude": -101.687688,
      "city": "Max",
      "state": "NE",
      "county": "Dundy"
  },
  {
      "zipCode": 69038,
      "latitude": 40.524809,
      "longitude": -100.38203,
      "city": "Maywood",
      "state": "NE",
      "county": "Frontier"
  },
  {
      "zipCode": 69039,
      "latitude": 40.524809,
      "longitude": -100.38203,
      "city": "Moorefield",
      "state": "NE",
      "county": "Frontier"
  },
  {
      "zipCode": 69040,
      "latitude": 40.267333,
      "longitude": -101.065001,
      "city": "Palisade",
      "state": "NE",
      "county": "Hitchcock"
  },
  {
      "zipCode": 69041,
      "latitude": 40.176417,
      "longitude": -101.687688,
      "city": "Parks",
      "state": "NE",
      "county": "Dundy"
  },
  {
      "zipCode": 69042,
      "latitude": 40.524809,
      "longitude": -100.38203,
      "city": "Stockville",
      "state": "NE",
      "county": "Frontier"
  },
  {
      "zipCode": 69043,
      "latitude": 40.15014,
      "longitude": -101.227939,
      "city": "Stratton",
      "state": "NE",
      "county": "Hitchcock"
  },
  {
      "zipCode": 69044,
      "latitude": 40.176356,
      "longitude": -101.010823,
      "city": "Trenton",
      "state": "NE",
      "county": "Hitchcock"
  },
  {
      "zipCode": 69045,
      "latitude": 40.416806,
      "longitude": -101.370507,
      "city": "Wauneta",
      "state": "NE",
      "county": "Chase"
  },
  {
      "zipCode": 69046,
      "latitude": 40.112009,
      "longitude": -100.106003,
      "city": "Wilsonville",
      "state": "NE",
      "county": "Furnas"
  },
  {
      "zipCode": 69101,
      "latitude": 41.102555,
      "longitude": -100.74907,
      "city": "North Platte",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69103,
      "latitude": 41.046447,
      "longitude": -100.746912,
      "city": "North Platte",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69120,
      "latitude": 41.423996,
      "longitude": -100.194253,
      "city": "Arnold",
      "state": "NE",
      "county": "Custer"
  },
  {
      "zipCode": 69121,
      "latitude": 41.554073,
      "longitude": -101.714495,
      "city": "Arthur",
      "state": "NE",
      "county": "Arthur"
  },
  {
      "zipCode": 69122,
      "latitude": 41.111961,
      "longitude": -102.261262,
      "city": "Big Springs",
      "state": "NE",
      "county": "Deuel"
  },
  {
      "zipCode": 69123,
      "latitude": 41.049046,
      "longitude": -100.391826,
      "city": "Brady",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69125,
      "latitude": 41.598622,
      "longitude": -102.852752,
      "city": "Broadwater",
      "state": "NE",
      "county": "Morrill"
  },
  {
      "zipCode": 69127,
      "latitude": 41.135819,
      "longitude": -101.921709,
      "city": "Brule",
      "state": "NE",
      "county": "Keith"
  },
  {
      "zipCode": 69128,
      "latitude": 41.232626,
      "longitude": -103.891757,
      "city": "Bushnell",
      "state": "NE",
      "county": "Kimball"
  },
  {
      "zipCode": 69129,
      "latitude": 41.112122,
      "longitude": -102.421494,
      "city": "Chappell",
      "state": "NE",
      "county": "Deuel"
  },
  {
      "zipCode": 69130,
      "latitude": 40.895003,
      "longitude": -99.967448,
      "city": "Cozad",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 69131,
      "latitude": 41.380172,
      "longitude": -103.031435,
      "city": "Dalton",
      "state": "NE",
      "county": "Cheyenne"
  },
  {
      "zipCode": 69132,
      "latitude": 41.046447,
      "longitude": -100.746912,
      "city": "Dickens",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69133,
      "latitude": 41.19801,
      "longitude": -103.714501,
      "city": "Dix",
      "state": "NE",
      "county": "Kimball"
  },
  {
      "zipCode": 69134,
      "latitude": 40.850739,
      "longitude": -101.649075,
      "city": "Elsie",
      "state": "NE",
      "county": "Perkins"
  },
  {
      "zipCode": 69135,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Elsmere",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69138,
      "latitude": 40.945256,
      "longitude": -100.129125,
      "city": "Gothenburg",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 69140,
      "latitude": 40.846046,
      "longitude": -101.7258,
      "city": "Grant",
      "state": "NE",
      "county": "Perkins"
  },
  {
      "zipCode": 69141,
      "latitude": 41.315041,
      "longitude": -102.983464,
      "city": "Gurley",
      "state": "NE",
      "county": "Cheyenne"
  },
  {
      "zipCode": 69142,
      "latitude": 41.913806,
      "longitude": -100.555805,
      "city": "Halsey",
      "state": "NE",
      "county": "Thomas"
  },
  {
      "zipCode": 69143,
      "latitude": 40.98993,
      "longitude": -100.96308,
      "city": "Hershey",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69144,
      "latitude": 41.293525,
      "longitude": -101.604569,
      "city": "Keystone",
      "state": "NE",
      "county": "Keith"
  },
  {
      "zipCode": 69145,
      "latitude": 41.206972,
      "longitude": -103.769557,
      "city": "Kimball",
      "state": "NE",
      "county": "Kimball"
  },
  {
      "zipCode": 69146,
      "latitude": 41.323607,
      "longitude": -101.767329,
      "city": "Lemoyne",
      "state": "NE",
      "county": "Keith"
  },
  {
      "zipCode": 69147,
      "latitude": 41.315509,
      "longitude": -102.153084,
      "city": "Lewellen",
      "state": "NE",
      "county": "Garden"
  },
  {
      "zipCode": 69148,
      "latitude": 41.409518,
      "longitude": -102.476752,
      "city": "Lisco",
      "state": "NE",
      "county": "Garden"
  },
  {
      "zipCode": 69149,
      "latitude": 41.223638,
      "longitude": -102.794277,
      "city": "Lodgepole",
      "state": "NE",
      "county": "Cheyenne"
  },
  {
      "zipCode": 69150,
      "latitude": 40.850739,
      "longitude": -101.649075,
      "city": "Madrid",
      "state": "NE",
      "county": "Perkins"
  },
  {
      "zipCode": 69151,
      "latitude": 41.096752,
      "longitude": -100.583269,
      "city": "Maxwell",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69152,
      "latitude": 42.042143,
      "longitude": -101.045192,
      "city": "Mullen",
      "state": "NE",
      "county": "Hooker"
  },
  {
      "zipCode": 69153,
      "latitude": 41.131676,
      "longitude": -101.663033,
      "city": "Ogallala",
      "state": "NE",
      "county": "Keith"
  },
  {
      "zipCode": 69154,
      "latitude": 41.377536,
      "longitude": -102.385678,
      "city": "Oshkosh",
      "state": "NE",
      "county": "Garden"
  },
  {
      "zipCode": 69155,
      "latitude": 41.199097,
      "longitude": -101.423214,
      "city": "Paxton",
      "state": "NE",
      "county": "Keith"
  },
  {
      "zipCode": 69156,
      "latitude": 41.212817,
      "longitude": -103.247811,
      "city": "Potter",
      "state": "NE",
      "county": "Cheyenne"
  },
  {
      "zipCode": 69157,
      "latitude": 41.912303,
      "longitude": -99.977135,
      "city": "Purdum",
      "state": "NE",
      "county": "Blaine"
  },
  {
      "zipCode": 69160,
      "latitude": 41.220549,
      "longitude": -102.995925,
      "city": "Sidney",
      "state": "NE",
      "county": "Cheyenne"
  },
  {
      "zipCode": 69161,
      "latitude": 41.913806,
      "longitude": -100.555805,
      "city": "Seneca",
      "state": "NE",
      "county": "Thomas"
  },
  {
      "zipCode": 69162,
      "latitude": 41.161943,
      "longitude": -103.066015,
      "city": "Sidney",
      "state": "NE",
      "county": "Cheyenne"
  },
  {
      "zipCode": 69163,
      "latitude": 41.480166,
      "longitude": -100.512463,
      "city": "Stapleton",
      "state": "NE",
      "county": "Logan"
  },
  {
      "zipCode": 69165,
      "latitude": 41.1597,
      "longitude": -101.136616,
      "city": "Sutherland",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69166,
      "latitude": 41.979407,
      "longitude": -100.557354,
      "city": "Thedford",
      "state": "NE",
      "county": "Thomas"
  },
  {
      "zipCode": 69167,
      "latitude": 41.568548,
      "longitude": -101.059489,
      "city": "Tryon",
      "state": "NE",
      "county": "Mcpherson"
  },
  {
      "zipCode": 69168,
      "latitude": 40.850739,
      "longitude": -101.649075,
      "city": "Venango",
      "state": "NE",
      "county": "Perkins"
  },
  {
      "zipCode": 69169,
      "latitude": 41.046447,
      "longitude": -100.746912,
      "city": "Wallace",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69170,
      "latitude": 40.762172,
      "longitude": -100.760429,
      "city": "Wellfleet",
      "state": "NE",
      "county": "Lincoln"
  },
  {
      "zipCode": 69171,
      "latitude": 40.892469,
      "longitude": -100.070257,
      "city": "Willow Island",
      "state": "NE",
      "county": "Dawson"
  },
  {
      "zipCode": 69190,
      "latitude": 41.615307,
      "longitude": -102.331762,
      "city": "Oshkosh",
      "state": "NE",
      "county": "Garden"
  },
  {
      "zipCode": 69201,
      "latitude": 42.906836,
      "longitude": -100.996747,
      "city": "Valentine",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69210,
      "latitude": 42.542734,
      "longitude": -99.852364,
      "city": "Ainsworth",
      "state": "NE",
      "county": "Brown"
  },
  {
      "zipCode": 69211,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Cody",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69212,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Crookston",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69214,
      "latitude": 42.465698,
      "longitude": -99.930477,
      "city": "Johnstown",
      "state": "NE",
      "county": "Brown"
  },
  {
      "zipCode": 69216,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Kilgore",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69217,
      "latitude": 42.533987,
      "longitude": -99.702813,
      "city": "Long Pine",
      "state": "NE",
      "county": "Brown"
  },
  {
      "zipCode": 69218,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Merriman",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69219,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Nenzel",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69220,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Sparks",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69221,
      "latitude": 42.542631,
      "longitude": -101.126167,
      "city": "Wood Lake",
      "state": "NE",
      "county": "Cherry"
  },
  {
      "zipCode": 69301,
      "latitude": 42.219844,
      "longitude": -103.073327,
      "city": "Alliance",
      "state": "NE",
      "county": "Box Butte"
  },
  {
      "zipCode": 69331,
      "latitude": 41.721468,
      "longitude": -103.003835,
      "city": "Angora",
      "state": "NE",
      "county": "Morrill"
  },
  {
      "zipCode": 69333,
      "latitude": 41.919117,
      "longitude": -101.746919,
      "city": "Ashby",
      "state": "NE",
      "county": "Grant"
  },
  {
      "zipCode": 69334,
      "latitude": 41.760711,
      "longitude": -103.32705,
      "city": "Bayard",
      "state": "NE",
      "county": "Morrill"
  },
  {
      "zipCode": 69335,
      "latitude": 42.730616,
      "longitude": -102.471549,
      "city": "Bingham",
      "state": "NE",
      "county": "Sheridan"
  },
  {
      "zipCode": 69336,
      "latitude": 41.667733,
      "longitude": -103.102335,
      "city": "Bridgeport",
      "state": "NE",
      "county": "Morrill"
  },
  {
      "zipCode": 69337,
      "latitude": 42.774479,
      "longitude": -103.138479,
      "city": "Chadron",
      "state": "NE",
      "county": "Dawes"
  },
  {
      "zipCode": 69339,
      "latitude": 42.611517,
      "longitude": -103.303223,
      "city": "Crawford",
      "state": "NE",
      "county": "Dawes"
  },
  {
      "zipCode": 69340,
      "latitude": 42.502412,
      "longitude": -102.399697,
      "city": "Ellsworth",
      "state": "NE",
      "county": "Sheridan"
  },
  {
      "zipCode": 69341,
      "latitude": 41.80602,
      "longitude": -103.75796,
      "city": "Gering",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69343,
      "latitude": 42.763456,
      "longitude": -102.314593,
      "city": "Gordon",
      "state": "NE",
      "county": "Sheridan"
  },
  {
      "zipCode": 69345,
      "latitude": 41.546441,
      "longitude": -103.710045,
      "city": "Harrisburg",
      "state": "NE",
      "county": "Banner"
  },
  {
      "zipCode": 69346,
      "latitude": 42.631216,
      "longitude": -103.782027,
      "city": "Harrison",
      "state": "NE",
      "county": "Sioux"
  },
  {
      "zipCode": 69347,
      "latitude": 42.636894,
      "longitude": -102.665536,
      "city": "Hay Springs",
      "state": "NE",
      "county": "Sheridan"
  },
  {
      "zipCode": 69348,
      "latitude": 42.314537,
      "longitude": -103.097258,
      "city": "Hemingford",
      "state": "NE",
      "county": "Box Butte"
  },
  {
      "zipCode": 69349,
      "latitude": 41.987327,
      "longitude": -104.045244,
      "city": "Henry",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69350,
      "latitude": 41.959181,
      "longitude": -101.751854,
      "city": "Hyannis",
      "state": "NE",
      "county": "Grant"
  },
  {
      "zipCode": 69351,
      "latitude": 42.502412,
      "longitude": -102.399697,
      "city": "Lakeside",
      "state": "NE",
      "county": "Sheridan"
  },
  {
      "zipCode": 69352,
      "latitude": 41.835492,
      "longitude": -103.974153,
      "city": "Lyman",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69353,
      "latitude": 41.746944,
      "longitude": -103.416351,
      "city": "Mcgrew",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69354,
      "latitude": 42.524433,
      "longitude": -103.25644,
      "city": "Marsland",
      "state": "NE",
      "county": "Dawes"
  },
  {
      "zipCode": 69355,
      "latitude": 41.765008,
      "longitude": -103.468748,
      "city": "Melbeta",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69356,
      "latitude": 41.861192,
      "longitude": -103.643939,
      "city": "Minatare",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69357,
      "latitude": 41.874333,
      "longitude": -103.803504,
      "city": "Mitchell",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69358,
      "latitude": 41.929379,
      "longitude": -103.950162,
      "city": "Morrill",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69360,
      "latitude": 42.71634,
      "longitude": -102.46359,
      "city": "Rushville",
      "state": "NE",
      "county": "Sheridan"
  },
  {
      "zipCode": 69361,
      "latitude": 41.872005,
      "longitude": -103.77023,
      "city": "Scottsbluff",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69363,
      "latitude": 41.851012,
      "longitude": -103.70726,
      "city": "Scottsbluff",
      "state": "NE",
      "county": "Scotts Bluff"
  },
  {
      "zipCode": 69365,
      "latitude": 42.502412,
      "longitude": -102.399697,
      "city": "Whiteclay",
      "state": "NE",
      "county": "Sheridan"
  },
  {
      "zipCode": 69366,
      "latitude": 41.919117,
      "longitude": -101.746919,
      "city": "Whitman",
      "state": "NE",
      "county": "Grant"
  },
  {
      "zipCode": 69367,
      "latitude": 42.788579,
      "longitude": -103.299462,
      "city": "Whitney",
      "state": "NE",
      "county": "Dawes"
  },
  {
      "zipCode": 70001,
      "latitude": 29.984643,
      "longitude": -90.161821,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70002,
      "latitude": 30.008003,
      "longitude": -90.176491,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70003,
      "latitude": 30.005653,
      "longitude": -90.20733,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70004,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70005,
      "latitude": 29.998821,
      "longitude": -90.134428,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70006,
      "latitude": 30.013413,
      "longitude": -90.195842,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70009,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70010,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70011,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70030,
      "latitude": 29.841219,
      "longitude": -90.430699,
      "city": "Des Allemands",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70031,
      "latitude": 29.947005,
      "longitude": -90.295997,
      "city": "Ama",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70032,
      "latitude": 29.956803,
      "longitude": -89.987547,
      "city": "Arabi",
      "state": "LA",
      "county": "Saint Bernard"
  },
  {
      "zipCode": 70033,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70036,
      "latitude": 29.696362,
      "longitude": -90.112182,
      "city": "Barataria",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70037,
      "latitude": 29.620092,
      "longitude": -89.771638,
      "city": "Belle Chasse",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70038,
      "latitude": 29.303827,
      "longitude": -89.401545,
      "city": "Boothville",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70039,
      "latitude": 29.904159,
      "longitude": -90.374038,
      "city": "Boutte",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70040,
      "latitude": 29.736105,
      "longitude": -89.919691,
      "city": "Braithwaite",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70041,
      "latitude": 29.427314,
      "longitude": -89.571937,
      "city": "Buras",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70042,
      "latitude": 29.380066,
      "longitude": -89.477464,
      "city": "Carlisle",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70043,
      "latitude": 29.947746,
      "longitude": -89.954906,
      "city": "Chalmette",
      "state": "LA",
      "county": "Saint Bernard"
  },
  {
      "zipCode": 70044,
      "latitude": 29.96765,
      "longitude": -89.951441,
      "city": "Chalmette",
      "state": "LA",
      "county": "Saint Bernard"
  },
  {
      "zipCode": 70046,
      "latitude": 29.61829,
      "longitude": -89.872599,
      "city": "Davant",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70047,
      "latitude": 29.964207,
      "longitude": -90.368185,
      "city": "Destrehan",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70049,
      "latitude": 30.033264,
      "longitude": -90.575432,
      "city": "Edgard",
      "state": "LA",
      "county": "St John The Baptist"
  },
  {
      "zipCode": 70050,
      "latitude": 29.404065,
      "longitude": -89.59563,
      "city": "Empire",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70051,
      "latitude": 29.998705,
      "longitude": -90.619382,
      "city": "Garyville",
      "state": "LA",
      "county": "St John The Baptist"
  },
  {
      "zipCode": 70052,
      "latitude": 30.048935,
      "longitude": -90.711904,
      "city": "Gramercy",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70053,
      "latitude": 29.911385,
      "longitude": -90.055722,
      "city": "Gretna",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70054,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Gretna",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70055,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70056,
      "latitude": 29.887204,
      "longitude": -90.033138,
      "city": "Gretna",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70057,
      "latitude": 29.966812,
      "longitude": -90.408167,
      "city": "Hahnville",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70058,
      "latitude": 29.875555,
      "longitude": -90.069558,
      "city": "Harvey",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70059,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Harvey",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70060,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Metairie",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70062,
      "latitude": 29.994398,
      "longitude": -90.20446,
      "city": "Kenner",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70063,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Kenner",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70064,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Kenner",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70065,
      "latitude": 30.027041,
      "longitude": -90.250547,
      "city": "Kenner",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70066,
      "latitude": 30.003409,
      "longitude": -90.45072,
      "city": "Killona",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70067,
      "latitude": 29.825895,
      "longitude": -90.113675,
      "city": "Lafitte",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70068,
      "latitude": 30.065367,
      "longitude": -90.527515,
      "city": "La Place",
      "state": "LA",
      "county": "St John The Baptist"
  },
  {
      "zipCode": 70069,
      "latitude": 30.091158,
      "longitude": -90.483189,
      "city": "La Place",
      "state": "LA",
      "county": "St John The Baptist"
  },
  {
      "zipCode": 70070,
      "latitude": 29.944447,
      "longitude": -90.348009,
      "city": "Luling",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70071,
      "latitude": 30.03757,
      "longitude": -90.73039,
      "city": "Lutcher",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70072,
      "latitude": 29.819729,
      "longitude": -90.078264,
      "city": "Marrero",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70073,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Marrero",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70075,
      "latitude": 29.932133,
      "longitude": -89.923522,
      "city": "Meraux",
      "state": "LA",
      "county": "Saint Bernard"
  },
  {
      "zipCode": 70076,
      "latitude": 30.057525,
      "longitude": -90.636855,
      "city": "Mount Airy",
      "state": "LA",
      "county": "St John The Baptist"
  },
  {
      "zipCode": 70078,
      "latitude": 29.981674,
      "longitude": -90.38595,
      "city": "New Sarpy",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70079,
      "latitude": 29.973479,
      "longitude": -90.404699,
      "city": "Norco",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70080,
      "latitude": 29.891541,
      "longitude": -90.378697,
      "city": "Paradis",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70081,
      "latitude": 29.285081,
      "longitude": -89.364034,
      "city": "Pilottown",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70082,
      "latitude": 29.587389,
      "longitude": -89.831317,
      "city": "Pointe A La Hache",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70083,
      "latitude": 29.502725,
      "longitude": -89.69023,
      "city": "Port Sulphur",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70084,
      "latitude": 30.05819,
      "longitude": -90.605871,
      "city": "Reserve",
      "state": "LA",
      "county": "St John The Baptist"
  },
  {
      "zipCode": 70085,
      "latitude": 29.818387,
      "longitude": -89.775097,
      "city": "Saint Bernard",
      "state": "LA",
      "county": "Saint Bernard"
  },
  {
      "zipCode": 70086,
      "latitude": 30.033127,
      "longitude": -90.867755,
      "city": "Saint James",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70087,
      "latitude": 29.945998,
      "longitude": -90.354891,
      "city": "Saint Rose",
      "state": "LA",
      "county": "Saint Charles"
  },
  {
      "zipCode": 70090,
      "latitude": 29.989546,
      "longitude": -90.761722,
      "city": "Vacherie",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70091,
      "latitude": 29.279536,
      "longitude": -89.373855,
      "city": "Venice",
      "state": "LA",
      "county": "Plaquemines"
  },
  {
      "zipCode": 70092,
      "latitude": 29.900262,
      "longitude": -89.894926,
      "city": "Violet",
      "state": "LA",
      "county": "Saint Bernard"
  },
  {
      "zipCode": 70094,
      "latitude": 29.920768,
      "longitude": -90.204326,
      "city": "Westwego",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70096,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "Westwego",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70112,
      "latitude": 29.958304,
      "longitude": -90.077,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70113,
      "latitude": 29.943769,
      "longitude": -90.083172,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70114,
      "latitude": 29.929605,
      "longitude": -90.013164,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70115,
      "latitude": 29.938343,
      "longitude": -90.100773,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70116,
      "latitude": 29.968504,
      "longitude": -90.067122,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70117,
      "latitude": 29.968553,
      "longitude": -90.02845,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70118,
      "latitude": 29.948283,
      "longitude": -90.122498,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70119,
      "latitude": 29.973053,
      "longitude": -90.08542,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70121,
      "latitude": 29.961448,
      "longitude": -90.157715,
      "city": "New Orleans",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70122,
      "latitude": 30.009063,
      "longitude": -90.062147,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70123,
      "latitude": 29.951082,
      "longitude": -90.206031,
      "city": "New Orleans",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70124,
      "latitude": 30.002253,
      "longitude": -90.103673,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70125,
      "latitude": 29.952659,
      "longitude": -90.102965,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70126,
      "latitude": 29.998653,
      "longitude": -90.044561,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70127,
      "latitude": 30.031251,
      "longitude": -89.975819,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70128,
      "latitude": 30.048653,
      "longitude": -89.958519,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70129,
      "latitude": 30.087708,
      "longitude": -89.846226,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70130,
      "latitude": 29.939504,
      "longitude": -90.074122,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70131,
      "latitude": 29.915873,
      "longitude": -89.962403,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70139,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70140,
      "latitude": 29.95612,
      "longitude": -90.078312,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70141,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "New Orleans",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70142,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70143,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70145,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70146,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70148,
      "latitude": 30.030902,
      "longitude": -90.068022,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70149,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70150,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70151,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70152,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70153,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70154,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70156,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70157,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70158,
      "latitude": 29.922905,
      "longitude": -90.070922,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70159,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70160,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70161,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70162,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70163,
      "latitude": 29.950659,
      "longitude": -90.075121,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70164,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70165,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70166,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70167,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70170,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70172,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70174,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70175,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70176,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70177,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70178,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70179,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70181,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "New Orleans",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70182,
      "latitude": 30.067646,
      "longitude": -89.815993,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70183,
      "latitude": 29.677893,
      "longitude": -90.0901,
      "city": "New Orleans",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70184,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70185,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70186,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70187,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70189,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70190,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70195,
      "latitude": 30.032997,
      "longitude": -89.882564,
      "city": "New Orleans",
      "state": "LA",
      "county": "Orleans"
  },
  {
      "zipCode": 70301,
      "latitude": 29.640426,
      "longitude": -90.599039,
      "city": "Thibodaux",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70302,
      "latitude": 29.458689,
      "longitude": -90.502848,
      "city": "Thibodaux",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70310,
      "latitude": 29.803282,
      "longitude": -90.816914,
      "city": "Thibodaux",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70339,
      "latitude": 29.947562,
      "longitude": -91.188549,
      "city": "Pierre Part",
      "state": "LA",
      "county": "Assumption"
  },
  {
      "zipCode": 70340,
      "latitude": 29.688425,
      "longitude": -91.220844,
      "city": "Amelia",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70341,
      "latitude": 30.03307,
      "longitude": -91.077279,
      "city": "Belle Rose",
      "state": "LA",
      "county": "Assumption"
  },
  {
      "zipCode": 70342,
      "latitude": 29.692879,
      "longitude": -91.251811,
      "city": "Berwick",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70343,
      "latitude": 29.543768,
      "longitude": -90.607587,
      "city": "Bourg",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70344,
      "latitude": 29.393661,
      "longitude": -90.609142,
      "city": "Chauvin",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70345,
      "latitude": 29.509589,
      "longitude": -90.335128,
      "city": "Cut Off",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70346,
      "latitude": 30.159584,
      "longitude": -90.967424,
      "city": "Donaldsonville",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70352,
      "latitude": 29.696385,
      "longitude": -90.944418,
      "city": "Donner",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70353,
      "latitude": 29.369707,
      "longitude": -90.712335,
      "city": "Dulac",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70354,
      "latitude": 29.437009,
      "longitude": -90.304724,
      "city": "Galliano",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70355,
      "latitude": 29.64798,
      "longitude": -90.48031,
      "city": "Gheens",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70356,
      "latitude": 29.654609,
      "longitude": -90.912489,
      "city": "Gibson",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70357,
      "latitude": 29.269324,
      "longitude": -90.195917,
      "city": "Golden Meadow",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70358,
      "latitude": 29.227559,
      "longitude": -90.018901,
      "city": "Grand Isle",
      "state": "LA",
      "county": "Jefferson"
  },
  {
      "zipCode": 70359,
      "latitude": 29.6936,
      "longitude": -90.770768,
      "city": "Gray",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70360,
      "latitude": 29.6421,
      "longitude": -90.852551,
      "city": "Houma",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70361,
      "latitude": 29.382117,
      "longitude": -90.865568,
      "city": "Houma",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70363,
      "latitude": 29.561855,
      "longitude": -90.752351,
      "city": "Houma",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70364,
      "latitude": 29.597188,
      "longitude": -90.732776,
      "city": "Houma",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70371,
      "latitude": 29.865202,
      "longitude": -90.596159,
      "city": "Kraemer",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70372,
      "latitude": 29.917298,
      "longitude": -90.985769,
      "city": "Labadieville",
      "state": "LA",
      "county": "Assumption"
  },
  {
      "zipCode": 70373,
      "latitude": 29.520061,
      "longitude": -90.347406,
      "city": "Larose",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70374,
      "latitude": 29.533839,
      "longitude": -90.409864,
      "city": "Lockport",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70375,
      "latitude": 29.693833,
      "longitude": -90.549555,
      "city": "Mathews",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70376,
      "latitude": 30.204707,
      "longitude": -90.869481,
      "city": "Modeste",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70377,
      "latitude": 29.456248,
      "longitude": -90.524964,
      "city": "Montegut",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70380,
      "latitude": 29.697653,
      "longitude": -91.265374,
      "city": "Morgan City",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70381,
      "latitude": 29.694629,
      "longitude": -91.259347,
      "city": "Morgan City",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70390,
      "latitude": 29.908353,
      "longitude": -91.064291,
      "city": "Napoleonville",
      "state": "LA",
      "county": "Assumption"
  },
  {
      "zipCode": 70391,
      "latitude": 29.994968,
      "longitude": -91.063485,
      "city": "Paincourtville",
      "state": "LA",
      "county": "Assumption"
  },
  {
      "zipCode": 70392,
      "latitude": 29.751746,
      "longitude": -91.383904,
      "city": "Patterson",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70393,
      "latitude": 29.9974,
      "longitude": -91.023196,
      "city": "Plattenville",
      "state": "LA",
      "county": "Assumption"
  },
  {
      "zipCode": 70394,
      "latitude": 29.683764,
      "longitude": -90.627822,
      "city": "Raceland",
      "state": "LA",
      "county": "Lafourche"
  },
  {
      "zipCode": 70395,
      "latitude": 29.691176,
      "longitude": -90.863923,
      "city": "Schriever",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70397,
      "latitude": 29.396246,
      "longitude": -90.78627,
      "city": "Theriot",
      "state": "LA",
      "county": "Terrebonne"
  },
  {
      "zipCode": 70401,
      "latitude": 30.549037,
      "longitude": -90.464466,
      "city": "Hammond",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70402,
      "latitude": 30.513982,
      "longitude": -90.480403,
      "city": "Hammond",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70403,
      "latitude": 30.529506,
      "longitude": -90.465499,
      "city": "Hammond",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70404,
      "latitude": 30.505061,
      "longitude": -90.422504,
      "city": "Hammond",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70420,
      "latitude": 30.477483,
      "longitude": -89.955328,
      "city": "Abita Springs",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70421,
      "latitude": 30.612371,
      "longitude": -90.405312,
      "city": "Akers",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70422,
      "latitude": 30.74483,
      "longitude": -90.41058,
      "city": "Amite",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70426,
      "latitude": 30.915579,
      "longitude": -89.882844,
      "city": "Angie",
      "state": "LA",
      "county": "Washington"
  },
  {
      "zipCode": 70427,
      "latitude": 30.791723,
      "longitude": -90.044253,
      "city": "Bogalusa",
      "state": "LA",
      "county": "Washington"
  },
  {
      "zipCode": 70429,
      "latitude": 30.83503,
      "longitude": -90.037115,
      "city": "Bogalusa",
      "state": "LA",
      "county": "Washington"
  },
  {
      "zipCode": 70431,
      "latitude": 30.549629,
      "longitude": -90.030882,
      "city": "Bush",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70433,
      "latitude": 30.435893,
      "longitude": -90.011991,
      "city": "Covington",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70434,
      "latitude": 30.492753,
      "longitude": -90.125666,
      "city": "Covington",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70435,
      "latitude": 30.566088,
      "longitude": -90.109823,
      "city": "Covington",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70436,
      "latitude": 30.819086,
      "longitude": -90.532641,
      "city": "Fluker",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70437,
      "latitude": 30.593426,
      "longitude": -90.144908,
      "city": "Folsom",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70438,
      "latitude": 30.840587,
      "longitude": -90.120807,
      "city": "Franklinton",
      "state": "LA",
      "county": "Washington"
  },
  {
      "zipCode": 70441,
      "latitude": 30.657123,
      "longitude": -90.651536,
      "city": "Greensburg",
      "state": "LA",
      "county": "Saint Helena"
  },
  {
      "zipCode": 70442,
      "latitude": 30.655912,
      "longitude": -90.438581,
      "city": "Husser",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70443,
      "latitude": 30.651594,
      "longitude": -90.491168,
      "city": "Independence",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70444,
      "latitude": 30.814466,
      "longitude": -90.428043,
      "city": "Kentwood",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70445,
      "latitude": 30.386606,
      "longitude": -89.900397,
      "city": "Lacombe",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70446,
      "latitude": 30.635647,
      "longitude": -90.361759,
      "city": "Loranger",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70447,
      "latitude": 30.389107,
      "longitude": -90.001553,
      "city": "Madisonville",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70448,
      "latitude": 30.37706,
      "longitude": -89.977719,
      "city": "Mandeville",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70449,
      "latitude": 30.342352,
      "longitude": -90.685853,
      "city": "Maurepas",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70450,
      "latitude": 30.91821,
      "longitude": -90.043651,
      "city": "Mount Hermon",
      "state": "LA",
      "county": "Washington"
  },
  {
      "zipCode": 70451,
      "latitude": 30.531157,
      "longitude": -90.475981,
      "city": "Natalbany",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70452,
      "latitude": 30.423603,
      "longitude": -89.921823,
      "city": "Pearl River",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70453,
      "latitude": 30.676164,
      "longitude": -90.80277,
      "city": "Pine Grove",
      "state": "LA",
      "county": "Saint Helena"
  },
  {
      "zipCode": 70454,
      "latitude": 30.467958,
      "longitude": -90.393867,
      "city": "Ponchatoula",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70455,
      "latitude": 30.533384,
      "longitude": -90.300373,
      "city": "Robert",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70456,
      "latitude": 30.654562,
      "longitude": -90.501975,
      "city": "Roseland",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70457,
      "latitude": 30.42551,
      "longitude": -89.881256,
      "city": "Saint Benedict",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70458,
      "latitude": 30.446993,
      "longitude": -89.976592,
      "city": "Slidell",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70459,
      "latitude": 30.42551,
      "longitude": -89.881256,
      "city": "Slidell",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70460,
      "latitude": 30.379561,
      "longitude": -89.90975,
      "city": "Slidell",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70461,
      "latitude": 30.24974,
      "longitude": -89.712618,
      "city": "Slidell",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70462,
      "latitude": 30.387413,
      "longitude": -90.588723,
      "city": "Springfield",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70463,
      "latitude": 30.653805,
      "longitude": -89.895502,
      "city": "Sun",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70464,
      "latitude": 30.534697,
      "longitude": -89.885927,
      "city": "Talisheek",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70465,
      "latitude": 30.863873,
      "longitude": -90.51295,
      "city": "Tangipahoa",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70466,
      "latitude": 30.572825,
      "longitude": -90.439399,
      "city": "Tickfaw",
      "state": "LA",
      "county": "Tangipahoa"
  },
  {
      "zipCode": 70467,
      "latitude": 30.90169,
      "longitude": -89.820725,
      "city": "Angie",
      "state": "LA",
      "county": "Washington"
  },
  {
      "zipCode": 70469,
      "latitude": 30.42551,
      "longitude": -89.881256,
      "city": "Slidell",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70470,
      "latitude": 30.42551,
      "longitude": -89.881256,
      "city": "Mandeville",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70471,
      "latitude": 30.343159,
      "longitude": -89.975815,
      "city": "Mandeville",
      "state": "LA",
      "county": "Saint Tammany"
  },
  {
      "zipCode": 70501,
      "latitude": 30.232955,
      "longitude": -92.066574,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70502,
      "latitude": 30.319799,
      "longitude": -92.026969,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70503,
      "latitude": 30.202892,
      "longitude": -92.059521,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70504,
      "latitude": 30.21385,
      "longitude": -92.01866,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70505,
      "latitude": 30.202251,
      "longitude": -92.01877,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70506,
      "latitude": 30.176093,
      "longitude": -92.066315,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70507,
      "latitude": 30.2528,
      "longitude": -92.038679,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70508,
      "latitude": 30.181866,
      "longitude": -92.026859,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70509,
      "latitude": 30.156506,
      "longitude": -92.000019,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70510,
      "latitude": 29.946603,
      "longitude": -92.241595,
      "city": "Abbeville",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70511,
      "latitude": 30.032434,
      "longitude": -92.176655,
      "city": "Abbeville",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70512,
      "latitude": 30.474902,
      "longitude": -91.967292,
      "city": "Arnaudville",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70513,
      "latitude": 30.045066,
      "longitude": -91.878675,
      "city": "Avery Island",
      "state": "LA",
      "county": "Iberia"
  },
  {
      "zipCode": 70514,
      "latitude": 29.846077,
      "longitude": -91.545263,
      "city": "Baldwin",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70515,
      "latitude": 30.67837,
      "longitude": -92.519207,
      "city": "Basile",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 70516,
      "latitude": 30.344615,
      "longitude": -92.345888,
      "city": "Branch",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70517,
      "latitude": 30.240316,
      "longitude": -91.828827,
      "city": "Breaux Bridge",
      "state": "LA",
      "county": "Saint Martin"
  },
  {
      "zipCode": 70518,
      "latitude": 30.1606,
      "longitude": -91.963869,
      "city": "Broussard",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70519,
      "latitude": 30.079692,
      "longitude": -91.911387,
      "city": "Cade",
      "state": "LA",
      "county": "Saint Martin"
  },
  {
      "zipCode": 70520,
      "latitude": 30.238176,
      "longitude": -92.047919,
      "city": "Carencro",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70521,
      "latitude": 30.344923,
      "longitude": -91.854758,
      "city": "Cecilia",
      "state": "LA",
      "county": "Saint Martin"
  },
  {
      "zipCode": 70522,
      "latitude": 29.75226,
      "longitude": -91.439679,
      "city": "Centerville",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70523,
      "latitude": 29.867131,
      "longitude": -91.539372,
      "city": "Charenton",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70524,
      "latitude": 30.575361,
      "longitude": -92.287372,
      "city": "Chataignier",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 70525,
      "latitude": 30.382556,
      "longitude": -92.318696,
      "city": "Church Point",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70526,
      "latitude": 30.228294,
      "longitude": -92.438484,
      "city": "Crowley",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70527,
      "latitude": 30.228409,
      "longitude": -92.301795,
      "city": "Crowley",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70528,
      "latitude": 29.984693,
      "longitude": -91.987226,
      "city": "Delcambre",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70529,
      "latitude": 30.187978,
      "longitude": -92.168953,
      "city": "Duson",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70531,
      "latitude": 30.256951,
      "longitude": -92.486465,
      "city": "Egan",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70532,
      "latitude": 30.426249,
      "longitude": -92.71368,
      "city": "Elton",
      "state": "LA",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 70533,
      "latitude": 29.947394,
      "longitude": -92.045277,
      "city": "Erath",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70534,
      "latitude": 30.184157,
      "longitude": -92.447152,
      "city": "Estherwood",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70535,
      "latitude": 30.507236,
      "longitude": -92.210855,
      "city": "Eunice",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70537,
      "latitude": 30.203238,
      "longitude": -92.471305,
      "city": "Evangeline",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70538,
      "latitude": 29.720652,
      "longitude": -91.540902,
      "city": "Franklin",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70540,
      "latitude": 29.76327,
      "longitude": -91.466962,
      "city": "Garden City",
      "state": "LA",
      "county": "Saint Mary"
  },
  {
      "zipCode": 70541,
      "latitude": 30.417402,
      "longitude": -92.031178,
      "city": "Grand Coteau",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70542,
      "latitude": 30.010373,
      "longitude": -92.560894,
      "city": "Gueydan",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70543,
      "latitude": 30.299682,
      "longitude": -92.469182,
      "city": "Iota",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70544,
      "latitude": 29.936497,
      "longitude": -91.724248,
      "city": "Jeanerette",
      "state": "LA",
      "county": "Iberia"
  },
  {
      "zipCode": 70546,
      "latitude": 30.259096,
      "longitude": -92.69411,
      "city": "Jennings",
      "state": "LA",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 70548,
      "latitude": 29.875194,
      "longitude": -92.315721,
      "city": "Kaplan",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70549,
      "latitude": 30.260341,
      "longitude": -92.781945,
      "city": "Lake Arthur",
      "state": "LA",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 70550,
      "latitude": 30.543842,
      "longitude": -92.160849,
      "city": "Lawtell",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70551,
      "latitude": 30.475194,
      "longitude": -91.973736,
      "city": "Leonville",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70552,
      "latitude": 30.052016,
      "longitude": -91.683294,
      "city": "Loreauville",
      "state": "LA",
      "county": "Iberia"
  },
  {
      "zipCode": 70554,
      "latitude": 30.714716,
      "longitude": -92.425103,
      "city": "Mamou",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 70555,
      "latitude": 30.074655,
      "longitude": -92.147849,
      "city": "Maurice",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70556,
      "latitude": 30.186145,
      "longitude": -92.572176,
      "city": "Mermentau",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70558,
      "latitude": 30.098819,
      "longitude": -92.064797,
      "city": "Milton",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70559,
      "latitude": 30.129756,
      "longitude": -92.476684,
      "city": "Morse",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70560,
      "latitude": 29.949527,
      "longitude": -91.832747,
      "city": "New Iberia",
      "state": "LA",
      "county": "Iberia"
  },
  {
      "zipCode": 70562,
      "latitude": 29.739932,
      "longitude": -91.6331,
      "city": "New Iberia",
      "state": "LA",
      "county": "Iberia"
  },
  {
      "zipCode": 70563,
      "latitude": 30.024515,
      "longitude": -91.749604,
      "city": "New Iberia",
      "state": "LA",
      "county": "Iberia"
  },
  {
      "zipCode": 70569,
      "latitude": 29.909643,
      "longitude": -91.812552,
      "city": "Lydia",
      "state": "LA",
      "county": "Iberia"
  },
  {
      "zipCode": 70570,
      "latitude": 30.529765,
      "longitude": -92.00983,
      "city": "Opelousas",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70571,
      "latitude": 30.57445,
      "longitude": -92.086077,
      "city": "Opelousas",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70575,
      "latitude": 29.866543,
      "longitude": -92.158982,
      "city": "Perry",
      "state": "LA",
      "county": "Vermilion"
  },
  {
      "zipCode": 70576,
      "latitude": 30.756671,
      "longitude": -92.411722,
      "city": "Pine Prairie",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 70577,
      "latitude": 30.544276,
      "longitude": -91.918924,
      "city": "Port Barre",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70578,
      "latitude": 30.256405,
      "longitude": -92.261328,
      "city": "Rayne",
      "state": "LA",
      "county": "Acadia"
  },
  {
      "zipCode": 70580,
      "latitude": 30.700768,
      "longitude": -92.421915,
      "city": "Reddell",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 70581,
      "latitude": 30.246362,
      "longitude": -92.742134,
      "city": "Roanoke",
      "state": "LA",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 70582,
      "latitude": 30.223672,
      "longitude": -91.779374,
      "city": "Saint Martinville",
      "state": "LA",
      "county": "Saint Martin"
  },
  {
      "zipCode": 70583,
      "latitude": 30.273,
      "longitude": -92.100669,
      "city": "Scott",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70584,
      "latitude": 30.471399,
      "longitude": -92.065702,
      "city": "Sunset",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70585,
      "latitude": 30.901424,
      "longitude": -92.430522,
      "city": "Turkey Creek",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 70586,
      "latitude": 30.724974,
      "longitude": -92.386968,
      "city": "Ville Platte",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 70589,
      "latitude": 30.694115,
      "longitude": -92.048517,
      "city": "Washington",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70591,
      "latitude": 30.294276,
      "longitude": -92.833656,
      "city": "Welsh",
      "state": "LA",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 70592,
      "latitude": 30.14033,
      "longitude": -92.022573,
      "city": "Youngsville",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70593,
      "latitude": 30.20812,
      "longitude": -92.095109,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70596,
      "latitude": 30.20812,
      "longitude": -92.095109,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70598,
      "latitude": 30.20812,
      "longitude": -92.095109,
      "city": "Lafayette",
      "state": "LA",
      "county": "Lafayette"
  },
  {
      "zipCode": 70601,
      "latitude": 30.233355,
      "longitude": -93.214903,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70602,
      "latitude": 30.264232,
      "longitude": -93.326527,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70605,
      "latitude": 30.130378,
      "longitude": -93.245056,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70606,
      "latitude": 30.264232,
      "longitude": -93.326527,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70607,
      "latitude": 30.124378,
      "longitude": -93.183478,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70609,
      "latitude": 30.264232,
      "longitude": -93.326527,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70611,
      "latitude": 30.335834,
      "longitude": -93.204847,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70612,
      "latitude": 30.264232,
      "longitude": -93.326527,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70615,
      "latitude": 30.258498,
      "longitude": -93.114623,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70616,
      "latitude": 30.264232,
      "longitude": -93.326527,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70629,
      "latitude": 30.264232,
      "longitude": -93.326527,
      "city": "Lake Charles",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70630,
      "latitude": 30.105386,
      "longitude": -93.037173,
      "city": "Bell City",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70631,
      "latitude": 29.791099,
      "longitude": -93.437979,
      "city": "Cameron",
      "state": "LA",
      "county": "Cameron"
  },
  {
      "zipCode": 70632,
      "latitude": 29.807493,
      "longitude": -93.051554,
      "city": "Creole",
      "state": "LA",
      "county": "Cameron"
  },
  {
      "zipCode": 70633,
      "latitude": 30.358944,
      "longitude": -93.268731,
      "city": "Dequincy",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70634,
      "latitude": 30.651656,
      "longitude": -93.299574,
      "city": "Deridder",
      "state": "LA",
      "county": "Beauregard"
  },
  {
      "zipCode": 70637,
      "latitude": 30.743467,
      "longitude": -93.020236,
      "city": "Dry Creek",
      "state": "LA",
      "county": "Beauregard"
  },
  {
      "zipCode": 70638,
      "latitude": 30.818176,
      "longitude": -92.758262,
      "city": "Elizabeth",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 70639,
      "latitude": 30.96842,
      "longitude": -93.520348,
      "city": "Evans",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 70640,
      "latitude": 30.35502,
      "longitude": -92.946269,
      "city": "Fenton",
      "state": "LA",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 70642,
      "latitude": 31.111979,
      "longitude": -93.200936,
      "city": "Fullerton",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 70643,
      "latitude": 29.764417,
      "longitude": -92.926076,
      "city": "Grand Chenier",
      "state": "LA",
      "county": "Cameron"
  },
  {
      "zipCode": 70644,
      "latitude": 30.770681,
      "longitude": -92.933723,
      "city": "Grant",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 70645,
      "latitude": 29.897443,
      "longitude": -93.368241,
      "city": "Hackberry",
      "state": "LA",
      "county": "Cameron"
  },
  {
      "zipCode": 70646,
      "latitude": 30.11138,
      "longitude": -92.945098,
      "city": "Hayes",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70647,
      "latitude": 30.239624,
      "longitude": -93.033305,
      "city": "Iowa",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70648,
      "latitude": 30.578402,
      "longitude": -92.928777,
      "city": "Kinder",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 70650,
      "latitude": 30.261021,
      "longitude": -92.877409,
      "city": "Lacassine",
      "state": "LA",
      "county": "Jefferson Davis"
  },
  {
      "zipCode": 70651,
      "latitude": 30.512276,
      "longitude": -92.980987,
      "city": "Leblanc",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 70652,
      "latitude": 30.585628,
      "longitude": -93.223551,
      "city": "Longville",
      "state": "LA",
      "county": "Beauregard"
  },
  {
      "zipCode": 70653,
      "latitude": 30.682899,
      "longitude": -93.441983,
      "city": "Merryville",
      "state": "LA",
      "county": "Beauregard"
  },
  {
      "zipCode": 70654,
      "latitude": 30.703476,
      "longitude": -92.906757,
      "city": "Mittie",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 70655,
      "latitude": 30.644704,
      "longitude": -92.763802,
      "city": "Oberlin",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 70656,
      "latitude": 30.987139,
      "longitude": -93.046679,
      "city": "Pitkin",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 70657,
      "latitude": 30.528185,
      "longitude": -93.148903,
      "city": "Ragley",
      "state": "LA",
      "county": "Beauregard"
  },
  {
      "zipCode": 70658,
      "latitude": 30.511148,
      "longitude": -93.036823,
      "city": "Reeves",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 70659,
      "latitude": 30.923606,
      "longitude": -93.195312,
      "city": "Rosepine",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 70660,
      "latitude": 30.593966,
      "longitude": -93.369139,
      "city": "Singer",
      "state": "LA",
      "county": "Beauregard"
  },
  {
      "zipCode": 70661,
      "latitude": 30.312173,
      "longitude": -93.643003,
      "city": "Starks",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70662,
      "latitude": 30.803752,
      "longitude": -93.010616,
      "city": "Sugartown",
      "state": "LA",
      "county": "Beauregard"
  },
  {
      "zipCode": 70663,
      "latitude": 30.300185,
      "longitude": -93.435063,
      "city": "Sulphur",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70664,
      "latitude": 30.264232,
      "longitude": -93.326527,
      "city": "Sulphur",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70665,
      "latitude": 30.200948,
      "longitude": -93.443701,
      "city": "Sulphur",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70668,
      "latitude": 30.208206,
      "longitude": -93.583787,
      "city": "Vinton",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70669,
      "latitude": 30.240705,
      "longitude": -93.275454,
      "city": "Westlake",
      "state": "LA",
      "county": "Calcasieu"
  },
  {
      "zipCode": 70704,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baker",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70706,
      "latitude": 30.570887,
      "longitude": -90.877022,
      "city": "Denham Springs",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70707,
      "latitude": 30.204707,
      "longitude": -90.869481,
      "city": "Gonzales",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70710,
      "latitude": 30.362992,
      "longitude": -91.263088,
      "city": "Addis",
      "state": "LA",
      "county": "West Baton Rouge"
  },
  {
      "zipCode": 70711,
      "latitude": 30.527008,
      "longitude": -90.742229,
      "city": "Albany",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70712,
      "latitude": 30.97337,
      "longitude": -91.538682,
      "city": "Angola",
      "state": "LA",
      "county": "West Feliciana"
  },
  {
      "zipCode": 70714,
      "latitude": 30.578637,
      "longitude": -91.126275,
      "city": "Baker",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70715,
      "latitude": 30.829754,
      "longitude": -91.694354,
      "city": "Batchelor",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70716,
      "latitude": 30.261384,
      "longitude": -91.36026,
      "city": "Bayou Goula",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70717,
      "latitude": 30.566266,
      "longitude": -91.58857,
      "city": "Blanks",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70718,
      "latitude": 30.201004,
      "longitude": -90.868876,
      "city": "Brittany",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70719,
      "latitude": 30.390091,
      "longitude": -91.273501,
      "city": "Brusly",
      "state": "LA",
      "county": "West Baton Rouge"
  },
  {
      "zipCode": 70720,
      "latitude": 30.596789,
      "longitude": -91.339546,
      "city": "Bueche",
      "state": "LA",
      "county": "West Baton Rouge"
  },
  {
      "zipCode": 70721,
      "latitude": 30.232366,
      "longitude": -91.104709,
      "city": "Carville",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70722,
      "latitude": 30.842734,
      "longitude": -90.960294,
      "city": "Clinton",
      "state": "LA",
      "county": "East Feliciana"
  },
  {
      "zipCode": 70723,
      "latitude": 30.051774,
      "longitude": -90.858531,
      "city": "Convent",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70725,
      "latitude": 30.134188,
      "longitude": -90.963042,
      "city": "Darrow",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70726,
      "latitude": 30.459026,
      "longitude": -90.902723,
      "city": "Denham Springs",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70727,
      "latitude": 30.337495,
      "longitude": -90.843449,
      "city": "Denham Springs",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70728,
      "latitude": 30.295446,
      "longitude": -90.945836,
      "city": "Duplessis",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70729,
      "latitude": 30.551335,
      "longitude": -91.394396,
      "city": "Erwinville",
      "state": "LA",
      "county": "West Baton Rouge"
  },
  {
      "zipCode": 70730,
      "latitude": 30.828705,
      "longitude": -91.124098,
      "city": "Ethel",
      "state": "LA",
      "county": "East Feliciana"
  },
  {
      "zipCode": 70732,
      "latitude": 30.657644,
      "longitude": -91.626406,
      "city": "Fordoche",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70733,
      "latitude": 30.30657,
      "longitude": -90.779704,
      "city": "French Settlement",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70734,
      "latitude": 30.205118,
      "longitude": -90.992636,
      "city": "Geismar",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70736,
      "latitude": 30.643098,
      "longitude": -91.32916,
      "city": "Glynn",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70737,
      "latitude": 30.219419,
      "longitude": -90.9248,
      "city": "Gonzales",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70738,
      "latitude": 30.204707,
      "longitude": -90.869481,
      "city": "Burnside",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70739,
      "latitude": 30.574145,
      "longitude": -90.963516,
      "city": "Greenwell Springs",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70740,
      "latitude": 30.371497,
      "longitude": -91.412678,
      "city": "Grosse Tete",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70743,
      "latitude": 30.020595,
      "longitude": -90.781355,
      "city": "Hester",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70744,
      "latitude": 30.507099,
      "longitude": -90.696724,
      "city": "Holden",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70747,
      "latitude": 30.902018,
      "longitude": -91.71085,
      "city": "Innis",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70748,
      "latitude": 30.808675,
      "longitude": -91.198736,
      "city": "Jackson",
      "state": "LA",
      "county": "East Feliciana"
  },
  {
      "zipCode": 70749,
      "latitude": 30.630136,
      "longitude": -91.411031,
      "city": "Jarreau",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70750,
      "latitude": 30.525441,
      "longitude": -91.907178,
      "city": "Krotz Springs",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 70751,
      "latitude": 30.7513,
      "longitude": -91.566273,
      "city": "Labarre",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70752,
      "latitude": 30.575993,
      "longitude": -91.446813,
      "city": "Lakeland",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70753,
      "latitude": 30.936504,
      "longitude": -91.73539,
      "city": "Lettsworth",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70754,
      "latitude": 30.407207,
      "longitude": -90.743125,
      "city": "Livingston",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70755,
      "latitude": 30.574555,
      "longitude": -91.548373,
      "city": "Livonia",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70756,
      "latitude": 30.572542,
      "longitude": -91.628222,
      "city": "Lottie",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70757,
      "latitude": 30.435891,
      "longitude": -91.490655,
      "city": "Maringouin",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70759,
      "latitude": 30.778438,
      "longitude": -91.651669,
      "city": "Morganza",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70760,
      "latitude": 30.687199,
      "longitude": -91.500474,
      "city": "New Roads",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70761,
      "latitude": 30.959067,
      "longitude": -91.024752,
      "city": "Norwood",
      "state": "LA",
      "county": "East Feliciana"
  },
  {
      "zipCode": 70762,
      "latitude": 30.616102,
      "longitude": -91.456649,
      "city": "Oscar",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70763,
      "latitude": 30.041815,
      "longitude": -90.743705,
      "city": "Paulina",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70764,
      "latitude": 30.215233,
      "longitude": -91.267904,
      "city": "Plaquemine",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70765,
      "latitude": 30.261384,
      "longitude": -91.36026,
      "city": "Plaquemine",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70767,
      "latitude": 30.494882,
      "longitude": -91.309262,
      "city": "Port Allen",
      "state": "LA",
      "county": "West Baton Rouge"
  },
  {
      "zipCode": 70769,
      "latitude": 30.287275,
      "longitude": -90.926014,
      "city": "Prairieville",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70770,
      "latitude": 30.646585,
      "longitude": -90.974252,
      "city": "Pride",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70772,
      "latitude": 30.435586,
      "longitude": -91.466482,
      "city": "Rosedale",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70773,
      "latitude": 30.602606,
      "longitude": -91.415059,
      "city": "Rougon",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70774,
      "latitude": 30.236364,
      "longitude": -90.827056,
      "city": "Saint Amant",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70775,
      "latitude": 30.869398,
      "longitude": -91.418642,
      "city": "Saint Francisville",
      "state": "LA",
      "county": "West Feliciana"
  },
  {
      "zipCode": 70776,
      "latitude": 30.25279,
      "longitude": -91.085716,
      "city": "Saint Gabriel",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70777,
      "latitude": 30.75711,
      "longitude": -91.07376,
      "city": "Slaughter",
      "state": "LA",
      "county": "East Feliciana"
  },
  {
      "zipCode": 70778,
      "latitude": 30.186506,
      "longitude": -90.880484,
      "city": "Sorrento",
      "state": "LA",
      "county": "Ascension"
  },
  {
      "zipCode": 70780,
      "latitude": 30.295921,
      "longitude": -91.166024,
      "city": "Sunshine",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70781,
      "latitude": 30.561711,
      "longitude": -91.487668,
      "city": "Torbert",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70782,
      "latitude": 30.969401,
      "longitude": -91.518541,
      "city": "Tunica",
      "state": "LA",
      "county": "West Feliciana"
  },
  {
      "zipCode": 70783,
      "latitude": 30.689213,
      "longitude": -91.476768,
      "city": "Ventress",
      "state": "LA",
      "county": "Pointe Coupee"
  },
  {
      "zipCode": 70784,
      "latitude": 30.917453,
      "longitude": -91.358149,
      "city": "Wakefield",
      "state": "LA",
      "county": "West Feliciana"
  },
  {
      "zipCode": 70785,
      "latitude": 30.549316,
      "longitude": -90.836232,
      "city": "Walker",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70786,
      "latitude": 30.550219,
      "longitude": -90.958219,
      "city": "Watson",
      "state": "LA",
      "county": "Livingston"
  },
  {
      "zipCode": 70787,
      "latitude": 30.936227,
      "longitude": -91.456167,
      "city": "Weyanoke",
      "state": "LA",
      "county": "West Feliciana"
  },
  {
      "zipCode": 70788,
      "latitude": 30.15929,
      "longitude": -91.146472,
      "city": "White Castle",
      "state": "LA",
      "county": "Iberville"
  },
  {
      "zipCode": 70789,
      "latitude": 30.914247,
      "longitude": -91.098267,
      "city": "Wilson",
      "state": "LA",
      "county": "East Feliciana"
  },
  {
      "zipCode": 70791,
      "latitude": 30.641035,
      "longitude": -91.129053,
      "city": "Zachary",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70792,
      "latitude": 30.027914,
      "longitude": -90.802759,
      "city": "Uncle Sam",
      "state": "LA",
      "county": "Saint James"
  },
  {
      "zipCode": 70801,
      "latitude": 30.44924,
      "longitude": -91.185607,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70802,
      "latitude": 30.44304,
      "longitude": -91.155956,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70803,
      "latitude": 30.405002,
      "longitude": -91.186834,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70804,
      "latitude": 30.386267,
      "longitude": -91.133905,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70805,
      "latitude": 30.473889,
      "longitude": -91.104954,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70806,
      "latitude": 30.45104,
      "longitude": -91.138455,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70807,
      "latitude": 30.50791,
      "longitude": -91.183243,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70808,
      "latitude": 30.408291,
      "longitude": -91.143906,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70809,
      "latitude": 30.429365,
      "longitude": -91.077942,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70810,
      "latitude": 30.355342,
      "longitude": -91.072118,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70811,
      "latitude": 30.555687,
      "longitude": -91.152456,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70812,
      "latitude": 30.499538,
      "longitude": -91.110905,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70813,
      "latitude": 30.520111,
      "longitude": -91.194863,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70814,
      "latitude": 30.488289,
      "longitude": -91.072704,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70815,
      "latitude": 30.45304,
      "longitude": -91.068254,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70816,
      "latitude": 30.42949,
      "longitude": -91.023502,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70817,
      "latitude": 30.384123,
      "longitude": -90.972423,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70818,
      "latitude": 30.4731,
      "longitude": -91.037528,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70819,
      "latitude": 30.467539,
      "longitude": -91.012194,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70820,
      "latitude": 30.373042,
      "longitude": -91.176156,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70821,
      "latitude": 30.461252,
      "longitude": -91.044723,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70822,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70823,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70825,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70826,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70827,
      "latitude": 30.433837,
      "longitude": -91.082468,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70831,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70833,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70835,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70836,
      "latitude": 30.391991,
      "longitude": -91.089154,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70837,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70874,
      "latitude": 30.590238,
      "longitude": -91.2054,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70879,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70883,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70884,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70892,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70893,
      "latitude": 30.412991,
      "longitude": -91.171456,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70894,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70895,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70896,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 70898,
      "latitude": 30.51589,
      "longitude": -91.080373,
      "city": "Baton Rouge",
      "state": "LA",
      "county": "East Baton Rouge"
  },
  {
      "zipCode": 71001,
      "latitude": 32.376273,
      "longitude": -92.970592,
      "city": "Arcadia",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71002,
      "latitude": 32.137843,
      "longitude": -93.090606,
      "city": "Ashland",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71003,
      "latitude": 32.68552,
      "longitude": -93.033603,
      "city": "Athens",
      "state": "LA",
      "county": "Claiborne"
  },
  {
      "zipCode": 71004,
      "latitude": 32.774283,
      "longitude": -93.883624,
      "city": "Belcher",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71006,
      "latitude": 32.725032,
      "longitude": -93.63759,
      "city": "Benton",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71007,
      "latitude": 32.339309,
      "longitude": -94.004646,
      "city": "Bethany",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71008,
      "latitude": 32.355774,
      "longitude": -92.996065,
      "city": "Bienville",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71009,
      "latitude": 32.632741,
      "longitude": -93.896895,
      "city": "Blanchard",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71016,
      "latitude": 32.240987,
      "longitude": -93.104385,
      "city": "Castor",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71018,
      "latitude": 32.801138,
      "longitude": -93.407704,
      "city": "Cotton Valley",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71019,
      "latitude": 32.023096,
      "longitude": -93.264819,
      "city": "Coushatta",
      "state": "LA",
      "county": "Red River"
  },
  {
      "zipCode": 71021,
      "latitude": 32.934771,
      "longitude": -93.448474,
      "city": "Cullen",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71023,
      "latitude": 32.688512,
      "longitude": -93.392015,
      "city": "Doyline",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71024,
      "latitude": 32.687275,
      "longitude": -93.308919,
      "city": "Dubberly",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71025,
      "latitude": 32.062391,
      "longitude": -93.367949,
      "city": "East Point",
      "state": "LA",
      "county": "Red River"
  },
  {
      "zipCode": 71027,
      "latitude": 32.172214,
      "longitude": -93.790471,
      "city": "Frierson",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71028,
      "latitude": 32.475847,
      "longitude": -93.047718,
      "city": "Gibsland",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71029,
      "latitude": 32.835482,
      "longitude": -93.830134,
      "city": "Gilliam",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71030,
      "latitude": 32.111056,
      "longitude": -93.842634,
      "city": "Gloster",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71031,
      "latitude": 31.953139,
      "longitude": -92.945356,
      "city": "Goldonna",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71032,
      "latitude": 32.09591,
      "longitude": -93.820738,
      "city": "Grand Cane",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71033,
      "latitude": 32.429968,
      "longitude": -93.989086,
      "city": "Greenwood",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71034,
      "latitude": 32.062391,
      "longitude": -93.367949,
      "city": "Hall Summit",
      "state": "LA",
      "county": "Red River"
  },
  {
      "zipCode": 71036,
      "latitude": 32.062391,
      "longitude": -93.367949,
      "city": "Harmon",
      "state": "LA",
      "county": "Red River"
  },
  {
      "zipCode": 71037,
      "latitude": 32.585005,
      "longitude": -93.533104,
      "city": "Haughton",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71038,
      "latitude": 32.833762,
      "longitude": -93.033657,
      "city": "Haynesville",
      "state": "LA",
      "county": "Claiborne"
  },
  {
      "zipCode": 71039,
      "latitude": 32.474113,
      "longitude": -93.288906,
      "city": "Heflin",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71040,
      "latitude": 32.775361,
      "longitude": -93.000406,
      "city": "Homer",
      "state": "LA",
      "county": "Claiborne"
  },
  {
      "zipCode": 71043,
      "latitude": 32.896272,
      "longitude": -93.877571,
      "city": "Hosston",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71044,
      "latitude": 32.924377,
      "longitude": -93.900321,
      "city": "Ida",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71045,
      "latitude": 32.415006,
      "longitude": -93.078363,
      "city": "Jamestown",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71046,
      "latitude": 32.107786,
      "longitude": -93.839512,
      "city": "Keatchie",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71047,
      "latitude": 32.388579,
      "longitude": -93.90393,
      "city": "Keithville",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71048,
      "latitude": 32.818938,
      "longitude": -92.866469,
      "city": "Lisbon",
      "state": "LA",
      "county": "Claiborne"
  },
  {
      "zipCode": 71049,
      "latitude": 32.046684,
      "longitude": -93.865925,
      "city": "Logansport",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71050,
      "latitude": 32.117539,
      "longitude": -93.913834,
      "city": "Longstreet",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71051,
      "latitude": 32.361547,
      "longitude": -93.523207,
      "city": "Elm Grove",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71052,
      "latitude": 32.039947,
      "longitude": -93.709777,
      "city": "Mansfield",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71055,
      "latitude": 32.725217,
      "longitude": -93.312837,
      "city": "Minden",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71058,
      "latitude": 32.576948,
      "longitude": -93.250705,
      "city": "Minden",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71060,
      "latitude": 32.594798,
      "longitude": -93.976775,
      "city": "Mooringsport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71061,
      "latitude": 32.755816,
      "longitude": -93.966734,
      "city": "Oil City",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71063,
      "latitude": 31.911977,
      "longitude": -93.563309,
      "city": "Pelican",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71064,
      "latitude": 32.768412,
      "longitude": -93.647824,
      "city": "Plain Dealing",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71065,
      "latitude": 31.794655,
      "longitude": -93.534539,
      "city": "Pleasant Hill",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71066,
      "latitude": 31.874059,
      "longitude": -93.196609,
      "city": "Powhatan",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71067,
      "latitude": 32.597435,
      "longitude": -93.50865,
      "city": "Princeton",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71068,
      "latitude": 32.382223,
      "longitude": -93.238047,
      "city": "Ringgold",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71069,
      "latitude": 32.96331,
      "longitude": -93.968905,
      "city": "Rodessa",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71070,
      "latitude": 32.19698,
      "longitude": -92.91702,
      "city": "Saline",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71071,
      "latitude": 32.758417,
      "longitude": -93.391177,
      "city": "Sarepta",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71072,
      "latitude": 32.74333,
      "longitude": -93.327139,
      "city": "Shongaloo",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71073,
      "latitude": 32.652472,
      "longitude": -93.342675,
      "city": "Sibley",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71075,
      "latitude": 32.771459,
      "longitude": -93.406102,
      "city": "Springhill",
      "state": "LA",
      "county": "Webster"
  },
  {
      "zipCode": 71078,
      "latitude": 32.173318,
      "longitude": -93.867325,
      "city": "Stonewall",
      "state": "LA",
      "county": "De Soto"
  },
  {
      "zipCode": 71079,
      "latitude": 32.945404,
      "longitude": -92.811145,
      "city": "Summerfield",
      "state": "LA",
      "county": "Claiborne"
  },
  {
      "zipCode": 71080,
      "latitude": 32.366002,
      "longitude": -93.101086,
      "city": "Taylor",
      "state": "LA",
      "county": "Bienville"
  },
  {
      "zipCode": 71082,
      "latitude": 32.84233,
      "longitude": -93.950401,
      "city": "Vivian",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71101,
      "latitude": 32.498202,
      "longitude": -93.750228,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71102,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71103,
      "latitude": 32.492552,
      "longitude": -93.775978,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71104,
      "latitude": 32.483313,
      "longitude": -93.730764,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71105,
      "latitude": 32.450453,
      "longitude": -93.712906,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71106,
      "latitude": 32.39117,
      "longitude": -93.711612,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71107,
      "latitude": 32.601593,
      "longitude": -93.87377,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71108,
      "latitude": 32.602557,
      "longitude": -93.816458,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71109,
      "latitude": 32.464053,
      "longitude": -93.814327,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71110,
      "latitude": 32.513749,
      "longitude": -93.640362,
      "city": "Barksdale Afb",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71111,
      "latitude": 32.516708,
      "longitude": -93.586698,
      "city": "Bossier City",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71112,
      "latitude": 32.460209,
      "longitude": -93.64562,
      "city": "Bossier City",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71113,
      "latitude": 32.62762,
      "longitude": -93.608968,
      "city": "Bossier City",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71115,
      "latitude": 32.340125,
      "longitude": -93.609198,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71118,
      "latitude": 32.535809,
      "longitude": -93.805255,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71119,
      "latitude": 32.478881,
      "longitude": -93.925724,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71120,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71129,
      "latitude": 32.346853,
      "longitude": -93.924607,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71130,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71133,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71134,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71135,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71136,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71137,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71138,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71148,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71149,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71151,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71152,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71153,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71154,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71156,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71161,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71162,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71163,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71164,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71165,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71166,
      "latitude": 32.607556,
      "longitude": -93.75256,
      "city": "Shreveport",
      "state": "LA",
      "county": "Caddo"
  },
  {
      "zipCode": 71171,
      "latitude": 32.62762,
      "longitude": -93.608968,
      "city": "Bossier City",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71172,
      "latitude": 32.62762,
      "longitude": -93.608968,
      "city": "Bossier City",
      "state": "LA",
      "county": "Bossier"
  },
  {
      "zipCode": 71201,
      "latitude": 32.502833,
      "longitude": -92.094583,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71202,
      "latitude": 32.386844,
      "longitude": -92.072206,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71203,
      "latitude": 32.600213,
      "longitude": -92.027228,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71207,
      "latitude": 32.490777,
      "longitude": -92.159354,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71208,
      "latitude": 32.496764,
      "longitude": -92.075615,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71209,
      "latitude": 32.527693,
      "longitude": -92.075583,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71210,
      "latitude": 32.490777,
      "longitude": -92.159354,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71211,
      "latitude": 32.490777,
      "longitude": -92.159354,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71212,
      "latitude": 32.528593,
      "longitude": -92.072683,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71213,
      "latitude": 32.490777,
      "longitude": -92.159354,
      "city": "Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71218,
      "latitude": 32.409446,
      "longitude": -91.732788,
      "city": "Archibald",
      "state": "LA",
      "county": "Richland"
  },
  {
      "zipCode": 71219,
      "latitude": 32.311261,
      "longitude": -91.68543,
      "city": "Baskin",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71220,
      "latitude": 32.795041,
      "longitude": -91.873723,
      "city": "Bastrop",
      "state": "LA",
      "county": "Morehouse"
  },
  {
      "zipCode": 71221,
      "latitude": 32.823863,
      "longitude": -91.843528,
      "city": "Bastrop",
      "state": "LA",
      "county": "Morehouse"
  },
  {
      "zipCode": 71222,
      "latitude": 32.837199,
      "longitude": -92.471522,
      "city": "Bernice",
      "state": "LA",
      "county": "Union"
  },
  {
      "zipCode": 71223,
      "latitude": 32.906387,
      "longitude": -91.653002,
      "city": "Bonita",
      "state": "LA",
      "county": "Morehouse"
  },
  {
      "zipCode": 71225,
      "latitude": 32.496897,
      "longitude": -92.337078,
      "city": "Calhoun",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71226,
      "latitude": 32.29969,
      "longitude": -92.44118,
      "city": "Chatham",
      "state": "LA",
      "county": "Jackson"
  },
  {
      "zipCode": 71227,
      "latitude": 32.585408,
      "longitude": -92.611834,
      "city": "Choudrant",
      "state": "LA",
      "county": "Lincoln"
  },
  {
      "zipCode": 71229,
      "latitude": 32.663142,
      "longitude": -91.812865,
      "city": "Collinston",
      "state": "LA",
      "county": "Morehouse"
  },
  {
      "zipCode": 71230,
      "latitude": 32.138935,
      "longitude": -91.691899,
      "city": "Crowville",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71232,
      "latitude": 32.445598,
      "longitude": -91.631433,
      "city": "Delhi",
      "state": "LA",
      "county": "Richland"
  },
  {
      "zipCode": 71233,
      "latitude": 32.341594,
      "longitude": -91.216458,
      "city": "Delta",
      "state": "LA",
      "county": "Madison"
  },
  {
      "zipCode": 71234,
      "latitude": 32.664242,
      "longitude": -92.36232,
      "city": "Downsville",
      "state": "LA",
      "county": "Union"
  },
  {
      "zipCode": 71235,
      "latitude": 32.644564,
      "longitude": -92.699642,
      "city": "Dubach",
      "state": "LA",
      "county": "Lincoln"
  },
  {
      "zipCode": 71237,
      "latitude": 32.718098,
      "longitude": -91.499713,
      "city": "Epps",
      "state": "LA",
      "county": "West Carroll"
  },
  {
      "zipCode": 71238,
      "latitude": 32.353757,
      "longitude": -92.459917,
      "city": "Eros",
      "state": "LA",
      "county": "Jackson"
  },
  {
      "zipCode": 71240,
      "latitude": 32.490777,
      "longitude": -92.159354,
      "city": "Fairbanks",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71241,
      "latitude": 32.777031,
      "longitude": -92.39525,
      "city": "Farmerville",
      "state": "LA",
      "county": "Union"
  },
  {
      "zipCode": 71242,
      "latitude": 32.82388,
      "longitude": -91.398944,
      "city": "Forest",
      "state": "LA",
      "county": "West Carroll"
  },
  {
      "zipCode": 71243,
      "latitude": 31.981305,
      "longitude": -91.766549,
      "city": "Fort Necessity",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71245,
      "latitude": 32.515626,
      "longitude": -92.73593,
      "city": "Grambling",
      "state": "LA",
      "county": "Lincoln"
  },
  {
      "zipCode": 71247,
      "latitude": 32.280774,
      "longitude": -92.71463,
      "city": "Hodge",
      "state": "LA",
      "county": "Jackson"
  },
  {
      "zipCode": 71249,
      "latitude": 32.138935,
      "longitude": -91.691899,
      "city": "Jigger",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71250,
      "latitude": 32.94656,
      "longitude": -91.580528,
      "city": "Jones",
      "state": "LA",
      "county": "Morehouse"
  },
  {
      "zipCode": 71251,
      "latitude": 32.228472,
      "longitude": -92.575528,
      "city": "Jonesboro",
      "state": "LA",
      "county": "Jackson"
  },
  {
      "zipCode": 71253,
      "latitude": 32.794027,
      "longitude": -91.450183,
      "city": "Kilbourne",
      "state": "LA",
      "county": "West Carroll"
  },
  {
      "zipCode": 71254,
      "latitude": 32.796407,
      "longitude": -91.252622,
      "city": "Lake Providence",
      "state": "LA",
      "county": "East Carroll"
  },
  {
      "zipCode": 71256,
      "latitude": 32.956279,
      "longitude": -92.687277,
      "city": "Lillie",
      "state": "LA",
      "county": "Union"
  },
  {
      "zipCode": 71259,
      "latitude": 32.343608,
      "longitude": -91.741639,
      "city": "Mangham",
      "state": "LA",
      "county": "Richland"
  },
  {
      "zipCode": 71260,
      "latitude": 32.871556,
      "longitude": -92.28619,
      "city": "Marion",
      "state": "LA",
      "county": "Union"
  },
  {
      "zipCode": 71261,
      "latitude": 32.793556,
      "longitude": -91.727614,
      "city": "Mer Rouge",
      "state": "LA",
      "county": "Morehouse"
  },
  {
      "zipCode": 71263,
      "latitude": 32.873125,
      "longitude": -91.430209,
      "city": "Oak Grove",
      "state": "LA",
      "county": "West Carroll"
  },
  {
      "zipCode": 71264,
      "latitude": 32.606213,
      "longitude": -91.759778,
      "city": "Oak Ridge",
      "state": "LA",
      "county": "Morehouse"
  },
  {
      "zipCode": 71266,
      "latitude": 32.736836,
      "longitude": -91.510077,
      "city": "Pioneer",
      "state": "LA",
      "county": "West Carroll"
  },
  {
      "zipCode": 71268,
      "latitude": 32.337632,
      "longitude": -92.672165,
      "city": "Quitman",
      "state": "LA",
      "county": "Jackson"
  },
  {
      "zipCode": 71269,
      "latitude": 32.445645,
      "longitude": -91.743314,
      "city": "Rayville",
      "state": "LA",
      "county": "Richland"
  },
  {
      "zipCode": 71270,
      "latitude": 32.580553,
      "longitude": -92.639756,
      "city": "Ruston",
      "state": "LA",
      "county": "Lincoln"
  },
  {
      "zipCode": 71272,
      "latitude": 32.525825,
      "longitude": -92.649298,
      "city": "Ruston",
      "state": "LA",
      "county": "Lincoln"
  },
  {
      "zipCode": 71273,
      "latitude": 32.606535,
      "longitude": -92.648384,
      "city": "Ruston",
      "state": "LA",
      "county": "Lincoln"
  },
  {
      "zipCode": 71275,
      "latitude": 32.597482,
      "longitude": -92.708389,
      "city": "Simsboro",
      "state": "LA",
      "county": "Lincoln"
  },
  {
      "zipCode": 71276,
      "latitude": 32.771315,
      "longitude": -91.230175,
      "city": "Sondheimer",
      "state": "LA",
      "county": "East Carroll"
  },
  {
      "zipCode": 71277,
      "latitude": 32.916505,
      "longitude": -92.574611,
      "city": "Spearsville",
      "state": "LA",
      "county": "Union"
  },
  {
      "zipCode": 71279,
      "latitude": 32.49191,
      "longitude": -91.856326,
      "city": "Start",
      "state": "LA",
      "county": "Richland"
  },
  {
      "zipCode": 71280,
      "latitude": 32.617816,
      "longitude": -92.060082,
      "city": "Sterlington",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71281,
      "latitude": 32.490777,
      "longitude": -92.159354,
      "city": "Swartz",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71282,
      "latitude": 32.4326,
      "longitude": -91.064535,
      "city": "Tallulah",
      "state": "LA",
      "county": "Madison"
  },
  {
      "zipCode": 71284,
      "latitude": 32.341594,
      "longitude": -91.216458,
      "city": "Tallulah",
      "state": "LA",
      "county": "Madison"
  },
  {
      "zipCode": 71286,
      "latitude": 32.771315,
      "longitude": -91.230175,
      "city": "Transylvania",
      "state": "LA",
      "county": "East Carroll"
  },
  {
      "zipCode": 71291,
      "latitude": 32.497932,
      "longitude": -92.16588,
      "city": "West Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71292,
      "latitude": 32.402367,
      "longitude": -92.203654,
      "city": "West Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71294,
      "latitude": 32.490777,
      "longitude": -92.159354,
      "city": "West Monroe",
      "state": "LA",
      "county": "Ouachita"
  },
  {
      "zipCode": 71295,
      "latitude": 32.118121,
      "longitude": -91.705926,
      "city": "Winnsboro",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71301,
      "latitude": 31.175885,
      "longitude": -92.431184,
      "city": "Alexandria",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71302,
      "latitude": 31.164904,
      "longitude": -92.367497,
      "city": "Alexandria",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71303,
      "latitude": 31.259983,
      "longitude": -92.53434,
      "city": "Alexandria",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71306,
      "latitude": 31.075638,
      "longitude": -92.446135,
      "city": "Alexandria",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71307,
      "latitude": 31.203393,
      "longitude": -92.526927,
      "city": "Alexandria",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71309,
      "latitude": 31.30473,
      "longitude": -92.619593,
      "city": "Alexandria",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71315,
      "latitude": 31.139651,
      "longitude": -92.398384,
      "city": "Alexandria",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71316,
      "latitude": 31.308416,
      "longitude": -91.823219,
      "city": "Acme",
      "state": "LA",
      "county": "Concordia"
  },
  {
      "zipCode": 71320,
      "latitude": 31.091942,
      "longitude": -91.906093,
      "city": "Bordelonville",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71322,
      "latitude": 30.939962,
      "longitude": -92.110503,
      "city": "Bunkie",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71323,
      "latitude": 31.235297,
      "longitude": -92.194478,
      "city": "Center Point",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71324,
      "latitude": 32.138935,
      "longitude": -91.691899,
      "city": "Chase",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71325,
      "latitude": 31.047041,
      "longitude": -92.383486,
      "city": "Cheneyville",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71326,
      "latitude": 31.715764,
      "longitude": -91.516323,
      "city": "Clayton",
      "state": "LA",
      "county": "Concordia"
  },
  {
      "zipCode": 71327,
      "latitude": 30.973616,
      "longitude": -91.995281,
      "city": "Cottonport",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71328,
      "latitude": 31.358696,
      "longitude": -92.2233,
      "city": "Deville",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71329,
      "latitude": 31.095889,
      "longitude": -91.97899,
      "city": "Dupont",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71330,
      "latitude": 31.119858,
      "longitude": -92.25888,
      "city": "Echo",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71331,
      "latitude": 31.222424,
      "longitude": -92.113774,
      "city": "Effie",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71333,
      "latitude": 30.91614,
      "longitude": -92.067027,
      "city": "Evergreen",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71334,
      "latitude": 31.660219,
      "longitude": -91.550218,
      "city": "Ferriday",
      "state": "LA",
      "county": "Concordia"
  },
  {
      "zipCode": 71336,
      "latitude": 31.999512,
      "longitude": -91.619049,
      "city": "Gilbert",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71339,
      "latitude": 31.037572,
      "longitude": -91.904161,
      "city": "Hamburg",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71340,
      "latitude": 31.746835,
      "longitude": -91.890463,
      "city": "Harrisonburg",
      "state": "LA",
      "county": "Catahoula"
  },
  {
      "zipCode": 71341,
      "latitude": 31.112517,
      "longitude": -92.041225,
      "city": "Hessmer",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71342,
      "latitude": 31.60603,
      "longitude": -92.140733,
      "city": "Jena",
      "state": "LA",
      "county": "La Salle"
  },
  {
      "zipCode": 71343,
      "latitude": 31.686225,
      "longitude": -91.86778,
      "city": "Jonesville",
      "state": "LA",
      "county": "Catahoula"
  },
  {
      "zipCode": 71345,
      "latitude": 30.770459,
      "longitude": -92.01484,
      "city": "Lebeau",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 71346,
      "latitude": 31.092797,
      "longitude": -92.439933,
      "city": "Lecompte",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71348,
      "latitude": 31.203393,
      "longitude": -92.526927,
      "city": "Libuse",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71350,
      "latitude": 31.064478,
      "longitude": -92.049086,
      "city": "Mansura",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71351,
      "latitude": 31.114424,
      "longitude": -92.019522,
      "city": "Marksville",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71353,
      "latitude": 30.716462,
      "longitude": -91.793232,
      "city": "Melville",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 71354,
      "latitude": 31.347431,
      "longitude": -91.724573,
      "city": "Monterey",
      "state": "LA",
      "county": "Concordia"
  },
  {
      "zipCode": 71355,
      "latitude": 31.09884,
      "longitude": -91.874829,
      "city": "Moreauville",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71356,
      "latitude": 30.791878,
      "longitude": -92.031647,
      "city": "Morrow",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 71357,
      "latitude": 32.038254,
      "longitude": -91.242511,
      "city": "Newellton",
      "state": "LA",
      "county": "Tensas"
  },
  {
      "zipCode": 71358,
      "latitude": 30.72662,
      "longitude": -91.895195,
      "city": "Palmetto",
      "state": "LA",
      "county": "Saint Landry"
  },
  {
      "zipCode": 71359,
      "latitude": 31.203393,
      "longitude": -92.526927,
      "city": "Pineville",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71360,
      "latitude": 31.223184,
      "longitude": -92.320275,
      "city": "Pineville",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71361,
      "latitude": 31.36923,
      "longitude": -92.419836,
      "city": "Pineville",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71362,
      "latitude": 30.992478,
      "longitude": -92.001767,
      "city": "Plaucheville",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71363,
      "latitude": 31.596272,
      "longitude": -91.77941,
      "city": "Rhinehart",
      "state": "LA",
      "county": "Catahoula"
  },
  {
      "zipCode": 71365,
      "latitude": 31.203393,
      "longitude": -92.526927,
      "city": "Ruby",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71366,
      "latitude": 31.919051,
      "longitude": -91.239631,
      "city": "Saint Joseph",
      "state": "LA",
      "county": "Tensas"
  },
  {
      "zipCode": 71367,
      "latitude": 30.899787,
      "longitude": -92.296364,
      "city": "Saint Landry",
      "state": "LA",
      "county": "Evangeline"
  },
  {
      "zipCode": 71368,
      "latitude": 31.848184,
      "longitude": -91.661308,
      "city": "Sicily Island",
      "state": "LA",
      "county": "Catahoula"
  },
  {
      "zipCode": 71369,
      "latitude": 30.946808,
      "longitude": -91.894355,
      "city": "Simmesport",
      "state": "LA",
      "county": "Avoyelles"
  },
  {
      "zipCode": 71371,
      "latitude": 31.697711,
      "longitude": -92.177637,
      "city": "Trout",
      "state": "LA",
      "county": "La Salle"
  },
  {
      "zipCode": 71373,
      "latitude": 31.408218,
      "longitude": -91.546425,
      "city": "Vidalia",
      "state": "LA",
      "county": "Concordia"
  },
  {
      "zipCode": 71375,
      "latitude": 31.805761,
      "longitude": -91.38711,
      "city": "Waterproof",
      "state": "LA",
      "county": "Tensas"
  },
  {
      "zipCode": 71377,
      "latitude": 31.590328,
      "longitude": -91.752932,
      "city": "Wildsville",
      "state": "LA",
      "county": "Concordia"
  },
  {
      "zipCode": 71378,
      "latitude": 31.961337,
      "longitude": -91.660042,
      "city": "Wisner",
      "state": "LA",
      "county": "Franklin"
  },
  {
      "zipCode": 71401,
      "latitude": 31.596272,
      "longitude": -91.77941,
      "city": "Aimwell",
      "state": "LA",
      "county": "Catahoula"
  },
  {
      "zipCode": 71403,
      "latitude": 31.142302,
      "longitude": -93.268452,
      "city": "Anacoco",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71404,
      "latitude": 31.775958,
      "longitude": -92.795773,
      "city": "Atlanta",
      "state": "LA",
      "county": "Winn"
  },
  {
      "zipCode": 71405,
      "latitude": 31.415125,
      "longitude": -92.394536,
      "city": "Ball",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71406,
      "latitude": 31.730633,
      "longitude": -93.493958,
      "city": "Belmont",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71407,
      "latitude": 31.518701,
      "longitude": -92.480731,
      "city": "Bentley",
      "state": "LA",
      "county": "Grant"
  },
  {
      "zipCode": 71409,
      "latitude": 31.251382,
      "longitude": -92.655743,
      "city": "Boyce",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71410,
      "latitude": 31.961279,
      "longitude": -92.792418,
      "city": "Calvin",
      "state": "LA",
      "county": "Winn"
  },
  {
      "zipCode": 71411,
      "latitude": 31.83009,
      "longitude": -93.148989,
      "city": "Campti",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71414,
      "latitude": 31.818209,
      "longitude": -93.021608,
      "city": "Clarence",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71415,
      "latitude": 32.055505,
      "longitude": -92.164903,
      "city": "Clarks",
      "state": "LA",
      "county": "Caldwell"
  },
  {
      "zipCode": 71416,
      "latitude": 31.517836,
      "longitude": -92.85577,
      "city": "Cloutierville",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71417,
      "latitude": 31.527636,
      "longitude": -92.653269,
      "city": "Colfax",
      "state": "LA",
      "county": "Grant"
  },
  {
      "zipCode": 71418,
      "latitude": 32.102189,
      "longitude": -92.11767,
      "city": "Columbia",
      "state": "LA",
      "county": "Caldwell"
  },
  {
      "zipCode": 71419,
      "latitude": 31.733858,
      "longitude": -93.690163,
      "city": "Converse",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71422,
      "latitude": 32.072883,
      "longitude": -92.565469,
      "city": "Dodson",
      "state": "LA",
      "county": "Winn"
  },
  {
      "zipCode": 71423,
      "latitude": 31.552466,
      "longitude": -92.673718,
      "city": "Dry Prong",
      "state": "LA",
      "county": "Grant"
  },
  {
      "zipCode": 71424,
      "latitude": 31.178183,
      "longitude": -92.700294,
      "city": "Elmer",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71425,
      "latitude": 31.920835,
      "longitude": -91.922802,
      "city": "Enterprise",
      "state": "LA",
      "county": "Catahoula"
  },
  {
      "zipCode": 71426,
      "latitude": 31.493332,
      "longitude": -93.464539,
      "city": "Fisher",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71427,
      "latitude": 31.375488,
      "longitude": -92.893591,
      "city": "Flatwoods",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71428,
      "latitude": 31.612444,
      "longitude": -93.097958,
      "city": "Flora",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71429,
      "latitude": 31.343315,
      "longitude": -93.430975,
      "city": "Florien",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71430,
      "latitude": 31.157736,
      "longitude": -92.448853,
      "city": "Forest Hill",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71431,
      "latitude": 31.258681,
      "longitude": -92.677545,
      "city": "Gardner",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71432,
      "latitude": 31.648593,
      "longitude": -92.470407,
      "city": "Georgetown",
      "state": "LA",
      "county": "Grant"
  },
  {
      "zipCode": 71433,
      "latitude": 31.026188,
      "longitude": -92.616008,
      "city": "Glenmora",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71434,
      "latitude": 31.503751,
      "longitude": -92.998576,
      "city": "Gorum",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71435,
      "latitude": 32.066996,
      "longitude": -92.169835,
      "city": "Grayson",
      "state": "LA",
      "county": "Caldwell"
  },
  {
      "zipCode": 71438,
      "latitude": 31.166914,
      "longitude": -92.737515,
      "city": "Hineston",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71439,
      "latitude": 31.316133,
      "longitude": -93.221228,
      "city": "Hornbeck",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71440,
      "latitude": 31.951166,
      "longitude": -92.565884,
      "city": "Joyce",
      "state": "LA",
      "county": "Winn"
  },
  {
      "zipCode": 71441,
      "latitude": 31.964848,
      "longitude": -92.18248,
      "city": "Kelly",
      "state": "LA",
      "county": "Caldwell"
  },
  {
      "zipCode": 71443,
      "latitude": 31.1019,
      "longitude": -93.361238,
      "city": "Kurthwood",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71444,
      "latitude": 31.120174,
      "longitude": -93.104943,
      "city": "Lacamp",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71446,
      "latitude": 31.125636,
      "longitude": -93.173622,
      "city": "Leesville",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71447,
      "latitude": 31.361027,
      "longitude": -92.792811,
      "city": "Lena",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71448,
      "latitude": 31.203393,
      "longitude": -92.526927,
      "city": "Longleaf",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71449,
      "latitude": 31.530019,
      "longitude": -93.51155,
      "city": "Many",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71450,
      "latitude": 31.78699,
      "longitude": -93.361235,
      "city": "Marthaville",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71452,
      "latitude": 31.679336,
      "longitude": -93.171095,
      "city": "Melrose",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71454,
      "latitude": 31.671495,
      "longitude": -92.660865,
      "city": "Montgomery",
      "state": "LA",
      "county": "Grant"
  },
  {
      "zipCode": 71455,
      "latitude": 31.423432,
      "longitude": -92.953251,
      "city": "Mora",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71456,
      "latitude": 31.690431,
      "longitude": -93.072487,
      "city": "Natchez",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71457,
      "latitude": 31.70988,
      "longitude": -93.109141,
      "city": "Natchitoches",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71458,
      "latitude": 31.747563,
      "longitude": -93.079055,
      "city": "Natchitoches",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71459,
      "latitude": 31.029825,
      "longitude": -93.23479,
      "city": "Leesville",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71460,
      "latitude": 31.448907,
      "longitude": -93.621234,
      "city": "Negreet",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71461,
      "latitude": 31.096279,
      "longitude": -93.311433,
      "city": "Newllano",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71462,
      "latitude": 31.699522,
      "longitude": -93.709535,
      "city": "Noble",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71463,
      "latitude": 30.769826,
      "longitude": -92.771752,
      "city": "Oakdale",
      "state": "LA",
      "county": "Allen"
  },
  {
      "zipCode": 71465,
      "latitude": 31.805736,
      "longitude": -92.208993,
      "city": "Olla",
      "state": "LA",
      "county": "La Salle"
  },
  {
      "zipCode": 71466,
      "latitude": 31.200683,
      "longitude": -92.637241,
      "city": "Otis",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71467,
      "latitude": 31.56668,
      "longitude": -92.396314,
      "city": "Pollock",
      "state": "LA",
      "county": "Grant"
  },
  {
      "zipCode": 71468,
      "latitude": 31.583252,
      "longitude": -93.118485,
      "city": "Provencal",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71469,
      "latitude": 31.633931,
      "longitude": -93.186289,
      "city": "Robeline",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71471,
      "latitude": 31.749699,
      "longitude": -92.927989,
      "city": "Saint Maurice",
      "state": "LA",
      "county": "Winn"
  },
  {
      "zipCode": 71472,
      "latitude": 31.197632,
      "longitude": -92.785799,
      "city": "Sieper",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71473,
      "latitude": 32.042737,
      "longitude": -92.449469,
      "city": "Sikes",
      "state": "LA",
      "county": "Winn"
  },
  {
      "zipCode": 71474,
      "latitude": 31.257037,
      "longitude": -93.016983,
      "city": "Simpson",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71475,
      "latitude": 31.111979,
      "longitude": -93.200936,
      "city": "Slagle",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71477,
      "latitude": 31.399855,
      "longitude": -92.604193,
      "city": "Tioga",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71479,
      "latitude": 31.861954,
      "longitude": -92.291333,
      "city": "Tullos",
      "state": "LA",
      "county": "La Salle"
  },
  {
      "zipCode": 71480,
      "latitude": 31.864998,
      "longitude": -92.291463,
      "city": "Urania",
      "state": "LA",
      "county": "La Salle"
  },
  {
      "zipCode": 71481,
      "latitude": 31.592547,
      "longitude": -92.58483,
      "city": "Verda",
      "state": "LA",
      "county": "Grant"
  },
  {
      "zipCode": 71483,
      "latitude": 31.914948,
      "longitude": -92.669269,
      "city": "Winnfield",
      "state": "LA",
      "county": "Winn"
  },
  {
      "zipCode": 71485,
      "latitude": 31.163485,
      "longitude": -92.532189,
      "city": "Woodworth",
      "state": "LA",
      "county": "Rapides"
  },
  {
      "zipCode": 71486,
      "latitude": 31.64176,
      "longitude": -93.637939,
      "city": "Zwolle",
      "state": "LA",
      "county": "Sabine"
  },
  {
      "zipCode": 71496,
      "latitude": 31.110298,
      "longitude": -93.289988,
      "city": "Leesville",
      "state": "LA",
      "county": "Vernon"
  },
  {
      "zipCode": 71497,
      "latitude": 31.747563,
      "longitude": -93.079055,
      "city": "Natchitoches",
      "state": "LA",
      "county": "Natchitoches"
  },
  {
      "zipCode": 71601,
      "latitude": 34.208962,
      "longitude": -91.985946,
      "city": "Pine Bluff",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 71602,
      "latitude": 34.257661,
      "longitude": -92.012103,
      "city": "White Hall",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 71603,
      "latitude": 34.148699,
      "longitude": -92.083653,
      "city": "Pine Bluff",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 71611,
      "latitude": 34.261465,
      "longitude": -91.955115,
      "city": "Pine Bluff",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 71612,
      "latitude": 34.277534,
      "longitude": -91.832539,
      "city": "White Hall",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 71613,
      "latitude": 34.157876,
      "longitude": -92.071284,
      "city": "Pine Bluff",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 71630,
      "latitude": 33.608922,
      "longitude": -91.207356,
      "city": "Arkansas City",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 71631,
      "latitude": 33.566349,
      "longitude": -92.240352,
      "city": "Banks",
      "state": "AR",
      "county": "Bradley"
  },
  {
      "zipCode": 71635,
      "latitude": 33.180855,
      "longitude": -91.806256,
      "city": "Crossett",
      "state": "AR",
      "county": "Ashley"
  },
  {
      "zipCode": 71638,
      "latitude": 33.470414,
      "longitude": -91.394712,
      "city": "Dermott",
      "state": "AR",
      "county": "Chicot"
  },
  {
      "zipCode": 71639,
      "latitude": 33.875244,
      "longitude": -91.488393,
      "city": "Dumas",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 71640,
      "latitude": 33.148807,
      "longitude": -91.310103,
      "city": "Eudora",
      "state": "AR",
      "county": "Chicot"
  },
  {
      "zipCode": 71642,
      "latitude": 33.363143,
      "longitude": -91.908776,
      "city": "Fountain Hill",
      "state": "AR",
      "county": "Ashley"
  },
  {
      "zipCode": 71643,
      "latitude": 34.024648,
      "longitude": -91.626178,
      "city": "Gould",
      "state": "AR",
      "county": "Lincoln"
  },
  {
      "zipCode": 71644,
      "latitude": 34.103233,
      "longitude": -91.705699,
      "city": "Grady",
      "state": "AR",
      "county": "Lincoln"
  },
  {
      "zipCode": 71646,
      "latitude": 33.215895,
      "longitude": -91.804371,
      "city": "Hamburg",
      "state": "AR",
      "county": "Ashley"
  },
  {
      "zipCode": 71647,
      "latitude": 33.390044,
      "longitude": -92.155958,
      "city": "Hermitage",
      "state": "AR",
      "county": "Bradley"
  },
  {
      "zipCode": 71649,
      "latitude": 33.283901,
      "longitude": -91.25206,
      "city": "Jennie",
      "state": "AR",
      "county": "Chicot"
  },
  {
      "zipCode": 71650,
      "latitude": 33.590601,
      "longitude": -91.732829,
      "city": "Jerome",
      "state": "AR",
      "county": "Drew"
  },
  {
      "zipCode": 71651,
      "latitude": 33.368404,
      "longitude": -92.290223,
      "city": "Jersey",
      "state": "AR",
      "county": "Bradley"
  },
  {
      "zipCode": 71652,
      "latitude": 33.866338,
      "longitude": -92.276397,
      "city": "Kingsland",
      "state": "AR",
      "county": "Cleveland"
  },
  {
      "zipCode": 71653,
      "latitude": 33.331905,
      "longitude": -91.297702,
      "city": "Lake Village",
      "state": "AR",
      "county": "Chicot"
  },
  {
      "zipCode": 71654,
      "latitude": 33.644573,
      "longitude": -91.401113,
      "city": "Mc Gehee",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 71655,
      "latitude": 33.604967,
      "longitude": -91.755955,
      "city": "Monticello",
      "state": "AR",
      "county": "Drew"
  },
  {
      "zipCode": 71656,
      "latitude": 33.590601,
      "longitude": -91.732829,
      "city": "Monticello",
      "state": "AR",
      "county": "Drew"
  },
  {
      "zipCode": 71657,
      "latitude": 33.590601,
      "longitude": -91.732829,
      "city": "Monticello",
      "state": "AR",
      "county": "Drew"
  },
  {
      "zipCode": 71658,
      "latitude": 33.261721,
      "longitude": -91.649931,
      "city": "Montrose",
      "state": "AR",
      "county": "Ashley"
  },
  {
      "zipCode": 71659,
      "latitude": 34.134089,
      "longitude": -91.79456,
      "city": "Moscow",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 71660,
      "latitude": 33.884167,
      "longitude": -92.214399,
      "city": "New Edinburg",
      "state": "AR",
      "county": "Cleveland"
  },
  {
      "zipCode": 71661,
      "latitude": 33.139243,
      "longitude": -91.576612,
      "city": "Parkdale",
      "state": "AR",
      "county": "Ashley"
  },
  {
      "zipCode": 71662,
      "latitude": 33.818318,
      "longitude": -91.214054,
      "city": "Pickens",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 71663,
      "latitude": 33.212137,
      "longitude": -91.512163,
      "city": "Portland",
      "state": "AR",
      "county": "Ashley"
  },
  {
      "zipCode": 71665,
      "latitude": 33.900651,
      "longitude": -92.131777,
      "city": "Rison",
      "state": "AR",
      "county": "Cleveland"
  },
  {
      "zipCode": 71666,
      "latitude": 33.818318,
      "longitude": -91.214054,
      "city": "Mc Gehee",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 71667,
      "latitude": 33.999462,
      "longitude": -91.709308,
      "city": "Star City",
      "state": "AR",
      "county": "Lincoln"
  },
  {
      "zipCode": 71670,
      "latitude": 33.698144,
      "longitude": -91.443347,
      "city": "Tillar",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 71671,
      "latitude": 33.573602,
      "longitude": -92.097852,
      "city": "Warren",
      "state": "AR",
      "county": "Bradley"
  },
  {
      "zipCode": 71674,
      "latitude": 33.905202,
      "longitude": -91.279828,
      "city": "Watson",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 71675,
      "latitude": 33.614151,
      "longitude": -91.916698,
      "city": "Wilmar",
      "state": "AR",
      "county": "Drew"
  },
  {
      "zipCode": 71676,
      "latitude": 33.151992,
      "longitude": -91.559968,
      "city": "Wilmot",
      "state": "AR",
      "county": "Ashley"
  },
  {
      "zipCode": 71677,
      "latitude": 33.747125,
      "longitude": -91.490123,
      "city": "Winchester",
      "state": "AR",
      "county": "Drew"
  },
  {
      "zipCode": 71678,
      "latitude": 33.978738,
      "longitude": -91.699077,
      "city": "Yorktown",
      "state": "AR",
      "county": "Lincoln"
  },
  {
      "zipCode": 71701,
      "latitude": 33.594817,
      "longitude": -92.851335,
      "city": "Camden",
      "state": "AR",
      "county": "Ouachita"
  },
  {
      "zipCode": 71711,
      "latitude": 33.589009,
      "longitude": -92.842649,
      "city": "Camden",
      "state": "AR",
      "county": "Ouachita"
  },
  {
      "zipCode": 71720,
      "latitude": 33.715918,
      "longitude": -92.844808,
      "city": "Bearden",
      "state": "AR",
      "county": "Ouachita"
  },
  {
      "zipCode": 71721,
      "latitude": 34.055486,
      "longitude": -93.189406,
      "city": "Beirne",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71722,
      "latitude": 33.70082,
      "longitude": -93.294126,
      "city": "Bluff City",
      "state": "AR",
      "county": "Nevada"
  },
  {
      "zipCode": 71724,
      "latitude": 33.266043,
      "longitude": -92.597514,
      "city": "Calion",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71725,
      "latitude": 34.101037,
      "longitude": -92.516827,
      "city": "Carthage",
      "state": "AR",
      "county": "Dallas"
  },
  {
      "zipCode": 71726,
      "latitude": 33.665157,
      "longitude": -92.996873,
      "city": "Chidester",
      "state": "AR",
      "county": "Ouachita"
  },
  {
      "zipCode": 71728,
      "latitude": 34.010316,
      "longitude": -93.097627,
      "city": "Curtis",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71730,
      "latitude": 33.187446,
      "longitude": -92.630038,
      "city": "El Dorado",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71731,
      "latitude": 33.198024,
      "longitude": -92.52891,
      "city": "El Dorado",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71740,
      "latitude": 33.112106,
      "longitude": -93.203998,
      "city": "Emerson",
      "state": "AR",
      "county": "Columbia"
  },
  {
      "zipCode": 71742,
      "latitude": 33.935138,
      "longitude": -92.610536,
      "city": "Fordyce",
      "state": "AR",
      "county": "Dallas"
  },
  {
      "zipCode": 71743,
      "latitude": 33.930011,
      "longitude": -93.129994,
      "city": "Gurdon",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71744,
      "latitude": 33.516751,
      "longitude": -92.582269,
      "city": "Hampton",
      "state": "AR",
      "county": "Calhoun"
  },
  {
      "zipCode": 71745,
      "latitude": 33.535994,
      "longitude": -92.543915,
      "city": "Harrell",
      "state": "AR",
      "county": "Calhoun"
  },
  {
      "zipCode": 71747,
      "latitude": 33.164568,
      "longitude": -92.393701,
      "city": "Huttig",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71748,
      "latitude": 33.97547,
      "longitude": -92.647817,
      "city": "Ivan",
      "state": "AR",
      "county": "Dallas"
  },
  {
      "zipCode": 71749,
      "latitude": 33.16579,
      "longitude": -92.768091,
      "city": "Junction City",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71750,
      "latitude": 33.198024,
      "longitude": -92.52891,
      "city": "Lawson",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71751,
      "latitude": 33.416913,
      "longitude": -92.777026,
      "city": "Louann",
      "state": "AR",
      "county": "Ouachita"
  },
  {
      "zipCode": 71752,
      "latitude": 33.373136,
      "longitude": -93.18731,
      "city": "Mc Neil",
      "state": "AR",
      "county": "Columbia"
  },
  {
      "zipCode": 71753,
      "latitude": 33.206807,
      "longitude": -93.224021,
      "city": "Magnolia",
      "state": "AR",
      "county": "Columbia"
  },
  {
      "zipCode": 71754,
      "latitude": 33.242571,
      "longitude": -93.22996,
      "city": "Magnolia",
      "state": "AR",
      "county": "Columbia"
  },
  {
      "zipCode": 71758,
      "latitude": 33.302589,
      "longitude": -92.817651,
      "city": "Mount Holly",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71759,
      "latitude": 33.321864,
      "longitude": -92.646905,
      "city": "Norphlet",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71762,
      "latitude": 33.194725,
      "longitude": -92.523809,
      "city": "Smackover",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71763,
      "latitude": 33.914288,
      "longitude": -92.853193,
      "city": "Sparkman",
      "state": "AR",
      "county": "Dallas"
  },
  {
      "zipCode": 71764,
      "latitude": 33.46053,
      "longitude": -93.011863,
      "city": "Stephens",
      "state": "AR",
      "county": "Ouachita"
  },
  {
      "zipCode": 71765,
      "latitude": 33.169586,
      "longitude": -92.331829,
      "city": "Strong",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71766,
      "latitude": 33.776795,
      "longitude": -92.48616,
      "city": "Thornton",
      "state": "AR",
      "county": "Calhoun"
  },
  {
      "zipCode": 71767,
      "latitude": 33.535994,
      "longitude": -92.543915,
      "city": "Hampton",
      "state": "AR",
      "county": "Calhoun"
  },
  {
      "zipCode": 71768,
      "latitude": 33.198024,
      "longitude": -92.52891,
      "city": "Urbana",
      "state": "AR",
      "county": "Union"
  },
  {
      "zipCode": 71769,
      "latitude": 33.236156,
      "longitude": -93.234494,
      "city": "Village",
      "state": "AR",
      "county": "Columbia"
  },
  {
      "zipCode": 71770,
      "latitude": 33.350437,
      "longitude": -93.302374,
      "city": "Waldo",
      "state": "AR",
      "county": "Columbia"
  },
  {
      "zipCode": 71772,
      "latitude": 33.832537,
      "longitude": -93.1238,
      "city": "Whelen Springs",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71801,
      "latitude": 33.673559,
      "longitude": -93.606807,
      "city": "Hope",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71802,
      "latitude": 33.765584,
      "longitude": -93.559162,
      "city": "Hope",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71820,
      "latitude": 33.778125,
      "longitude": -94.270165,
      "city": "Alleene",
      "state": "AR",
      "county": "Little River"
  },
  {
      "zipCode": 71822,
      "latitude": 33.704625,
      "longitude": -94.130221,
      "city": "Ashdown",
      "state": "AR",
      "county": "Little River"
  },
  {
      "zipCode": 71823,
      "latitude": 33.837053,
      "longitude": -94.120289,
      "city": "Ben Lomond",
      "state": "AR",
      "county": "Sevier"
  },
  {
      "zipCode": 71825,
      "latitude": 33.882525,
      "longitude": -93.621434,
      "city": "Blevins",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71826,
      "latitude": 33.202695,
      "longitude": -93.556966,
      "city": "Bradley",
      "state": "AR",
      "county": "Lafayette"
  },
  {
      "zipCode": 71827,
      "latitude": 33.397484,
      "longitude": -93.434895,
      "city": "Buckner",
      "state": "AR",
      "county": "Lafayette"
  },
  {
      "zipCode": 71828,
      "latitude": 33.70082,
      "longitude": -93.294126,
      "city": "Cale",
      "state": "AR",
      "county": "Nevada"
  },
  {
      "zipCode": 71831,
      "latitude": 33.793524,
      "longitude": -93.807007,
      "city": "Columbus",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71832,
      "latitude": 34.050929,
      "longitude": -94.28072,
      "city": "De Queen",
      "state": "AR",
      "county": "Sevier"
  },
  {
      "zipCode": 71833,
      "latitude": 34.136218,
      "longitude": -94.044286,
      "city": "Dierks",
      "state": "AR",
      "county": "Howard"
  },
  {
      "zipCode": 71834,
      "latitude": 33.441724,
      "longitude": -94.023737,
      "city": "Doddridge",
      "state": "AR",
      "county": "Miller"
  },
  {
      "zipCode": 71835,
      "latitude": 33.761471,
      "longitude": -93.42928,
      "city": "Emmet",
      "state": "AR",
      "county": "Nevada"
  },
  {
      "zipCode": 71836,
      "latitude": 33.734105,
      "longitude": -94.37246,
      "city": "Foreman",
      "state": "AR",
      "county": "Little River"
  },
  {
      "zipCode": 71837,
      "latitude": 33.261629,
      "longitude": -93.886621,
      "city": "Fouke",
      "state": "AR",
      "county": "Miller"
  },
  {
      "zipCode": 71838,
      "latitude": 33.669917,
      "longitude": -93.79752,
      "city": "Fulton",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71839,
      "latitude": 33.25024,
      "longitude": -93.612966,
      "city": "Garland City",
      "state": "AR",
      "county": "Lafayette"
  },
  {
      "zipCode": 71840,
      "latitude": 33.316578,
      "longitude": -93.854484,
      "city": "Genoa",
      "state": "AR",
      "county": "Miller"
  },
  {
      "zipCode": 71841,
      "latitude": 34.065882,
      "longitude": -94.358778,
      "city": "Gillham",
      "state": "AR",
      "county": "Sevier"
  },
  {
      "zipCode": 71842,
      "latitude": 34.004404,
      "longitude": -94.255693,
      "city": "Horatio",
      "state": "AR",
      "county": "Sevier"
  },
  {
      "zipCode": 71844,
      "latitude": 33.70082,
      "longitude": -93.294126,
      "city": "Laneburg",
      "state": "AR",
      "county": "Nevada"
  },
  {
      "zipCode": 71845,
      "latitude": 33.292401,
      "longitude": -93.597793,
      "city": "Lewisville",
      "state": "AR",
      "county": "Lafayette"
  },
  {
      "zipCode": 71846,
      "latitude": 33.967541,
      "longitude": -94.194636,
      "city": "Lockesburg",
      "state": "AR",
      "county": "Sevier"
  },
  {
      "zipCode": 71847,
      "latitude": 33.915909,
      "longitude": -93.61992,
      "city": "Mc Caskill",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71851,
      "latitude": 33.895583,
      "longitude": -93.925108,
      "city": "Mineral Springs",
      "state": "AR",
      "county": "Howard"
  },
  {
      "zipCode": 71852,
      "latitude": 34.024903,
      "longitude": -93.942206,
      "city": "Nashville",
      "state": "AR",
      "county": "Howard"
  },
  {
      "zipCode": 71853,
      "latitude": 33.596108,
      "longitude": -93.960638,
      "city": "Ogden",
      "state": "AR",
      "county": "Little River"
  },
  {
      "zipCode": 71854,
      "latitude": 33.431043,
      "longitude": -93.876485,
      "city": "Texarkana",
      "state": "AR",
      "county": "Miller"
  },
  {
      "zipCode": 71855,
      "latitude": 33.83663,
      "longitude": -93.732727,
      "city": "Ozan",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71857,
      "latitude": 33.776791,
      "longitude": -93.289132,
      "city": "Prescott",
      "state": "AR",
      "county": "Nevada"
  },
  {
      "zipCode": 71858,
      "latitude": 33.70082,
      "longitude": -93.294126,
      "city": "Rosston",
      "state": "AR",
      "county": "Nevada"
  },
  {
      "zipCode": 71859,
      "latitude": 33.78127,
      "longitude": -93.884355,
      "city": "Saratoga",
      "state": "AR",
      "county": "Howard"
  },
  {
      "zipCode": 71860,
      "latitude": 33.343809,
      "longitude": -93.50573,
      "city": "Stamps",
      "state": "AR",
      "county": "Lafayette"
  },
  {
      "zipCode": 71861,
      "latitude": 33.112208,
      "longitude": -93.322254,
      "city": "Taylor",
      "state": "AR",
      "county": "Columbia"
  },
  {
      "zipCode": 71862,
      "latitude": 33.776095,
      "longitude": -93.713625,
      "city": "Washington",
      "state": "AR",
      "county": "Hempstead"
  },
  {
      "zipCode": 71864,
      "latitude": 33.70082,
      "longitude": -93.294126,
      "city": "Willisville",
      "state": "AR",
      "county": "Nevada"
  },
  {
      "zipCode": 71865,
      "latitude": 33.733218,
      "longitude": -94.147399,
      "city": "Wilton",
      "state": "AR",
      "county": "Little River"
  },
  {
      "zipCode": 71866,
      "latitude": 33.857142,
      "longitude": -94.3748,
      "city": "Winthrop",
      "state": "AR",
      "county": "Little River"
  },
  {
      "zipCode": 71901,
      "latitude": 34.526841,
      "longitude": -92.958697,
      "city": "Hot Springs National Park",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71902,
      "latitude": 34.581374,
      "longitude": -93.099403,
      "city": "Hot Springs National Park",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71903,
      "latitude": 34.581374,
      "longitude": -93.099403,
      "city": "Hot Springs National Park",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71909,
      "latitude": 34.576246,
      "longitude": -92.989435,
      "city": "Hot Springs Village",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71910,
      "latitude": 34.581374,
      "longitude": -93.099403,
      "city": "Hot Springs Village",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71913,
      "latitude": 34.482214,
      "longitude": -93.043902,
      "city": "Hot Springs National Park",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71914,
      "latitude": 34.513682,
      "longitude": -92.968531,
      "city": "Hot Springs National Park",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71920,
      "latitude": 34.055486,
      "longitude": -93.189406,
      "city": "Alpine",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71921,
      "latitude": 34.232509,
      "longitude": -93.313673,
      "city": "Amity",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71922,
      "latitude": 34.146678,
      "longitude": -93.653733,
      "city": "Antoine",
      "state": "AR",
      "county": "Pike"
  },
  {
      "zipCode": 71923,
      "latitude": 34.031343,
      "longitude": -93.111452,
      "city": "Arkadelphia",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71929,
      "latitude": 34.276538,
      "longitude": -93.11742,
      "city": "Bismarck",
      "state": "AR",
      "county": "Hot Spring"
  },
  {
      "zipCode": 71932,
      "latitude": 34.537164,
      "longitude": -94.0972,
      "city": "Board Camp",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71933,
      "latitude": 34.356772,
      "longitude": -93.350643,
      "city": "Bonnerdale",
      "state": "AR",
      "county": "Hot Spring"
  },
  {
      "zipCode": 71935,
      "latitude": 34.392489,
      "longitude": -93.683883,
      "city": "Caddo Gap",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71937,
      "latitude": 34.354193,
      "longitude": -94.383483,
      "city": "Cove",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71940,
      "latitude": 34.146678,
      "longitude": -93.653733,
      "city": "Delight",
      "state": "AR",
      "county": "Pike"
  },
  {
      "zipCode": 71941,
      "latitude": 34.229869,
      "longitude": -92.963796,
      "city": "Donaldson",
      "state": "AR",
      "county": "Hot Spring"
  },
  {
      "zipCode": 71942,
      "latitude": 34.223546,
      "longitude": -93.002367,
      "city": "Friendship",
      "state": "AR",
      "county": "Hot Spring"
  },
  {
      "zipCode": 71943,
      "latitude": 34.206025,
      "longitude": -93.589297,
      "city": "Glenwood",
      "state": "AR",
      "county": "Pike"
  },
  {
      "zipCode": 71944,
      "latitude": 34.338462,
      "longitude": -94.356273,
      "city": "Grannis",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71945,
      "latitude": 34.5019,
      "longitude": -94.362242,
      "city": "Hatfield",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71946,
      "latitude": 34.459413,
      "longitude": -94.200221,
      "city": "Hatton",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71949,
      "latitude": 34.709408,
      "longitude": -93.038231,
      "city": "Jessieville",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71950,
      "latitude": 34.146678,
      "longitude": -93.653733,
      "city": "Kirby",
      "state": "AR",
      "county": "Pike"
  },
  {
      "zipCode": 71951,
      "latitude": 34.581374,
      "longitude": -93.099403,
      "city": "Hot Springs National Park",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71952,
      "latitude": 34.146678,
      "longitude": -93.653733,
      "city": "Langley",
      "state": "AR",
      "county": "Pike"
  },
  {
      "zipCode": 71953,
      "latitude": 34.503699,
      "longitude": -94.194756,
      "city": "Mena",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71956,
      "latitude": 34.637603,
      "longitude": -93.233142,
      "city": "Mountain Pine",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71957,
      "latitude": 34.550661,
      "longitude": -93.585206,
      "city": "Mount Ida",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71958,
      "latitude": 34.050402,
      "longitude": -93.75435,
      "city": "Murfreesboro",
      "state": "AR",
      "county": "Pike"
  },
  {
      "zipCode": 71959,
      "latitude": 34.146678,
      "longitude": -93.653733,
      "city": "Newhope",
      "state": "AR",
      "county": "Pike"
  },
  {
      "zipCode": 71960,
      "latitude": 34.474709,
      "longitude": -93.669613,
      "city": "Norman",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71961,
      "latitude": 34.597982,
      "longitude": -93.803388,
      "city": "Oden",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71962,
      "latitude": 34.041476,
      "longitude": -93.267115,
      "city": "Okolona",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71964,
      "latitude": 34.431902,
      "longitude": -93.249203,
      "city": "Pearcy",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71965,
      "latitude": 34.651272,
      "longitude": -93.740852,
      "city": "Pencil Bluff",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71966,
      "latitude": 34.542995,
      "longitude": -93.66463,
      "city": "Oden",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71968,
      "latitude": 34.514125,
      "longitude": -93.282723,
      "city": "Royal",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 71969,
      "latitude": 34.653145,
      "longitude": -93.614286,
      "city": "Sims",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71970,
      "latitude": 34.673248,
      "longitude": -93.53027,
      "city": "Story",
      "state": "AR",
      "county": "Montgomery"
  },
  {
      "zipCode": 71971,
      "latitude": 34.294469,
      "longitude": -94.095969,
      "city": "Umpire",
      "state": "AR",
      "county": "Howard"
  },
  {
      "zipCode": 71972,
      "latitude": 34.394091,
      "longitude": -94.253511,
      "city": "Vandervoort",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71973,
      "latitude": 34.307662,
      "longitude": -94.36157,
      "city": "Wickes",
      "state": "AR",
      "county": "Polk"
  },
  {
      "zipCode": 71998,
      "latitude": 34.055486,
      "longitude": -93.189406,
      "city": "Arkadelphia",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 71999,
      "latitude": 34.055486,
      "longitude": -93.189406,
      "city": "Arkadelphia",
      "state": "AR",
      "county": "Clark"
  },
  {
      "zipCode": 72001,
      "latitude": 34.943809,
      "longitude": -92.923191,
      "city": "Adona",
      "state": "AR",
      "county": "Perry"
  },
  {
      "zipCode": 72002,
      "latitude": 34.694468,
      "longitude": -92.425087,
      "city": "Alexander",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72003,
      "latitude": 34.38443,
      "longitude": -91.392364,
      "city": "Almyra",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72004,
      "latitude": 34.284273,
      "longitude": -91.698007,
      "city": "Altheimer",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72005,
      "latitude": 35.502463,
      "longitude": -91.081687,
      "city": "Amagon",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72006,
      "latitude": 35.207566,
      "longitude": -91.372792,
      "city": "Augusta",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72007,
      "latitude": 34.980269,
      "longitude": -91.979545,
      "city": "Austin",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72010,
      "latitude": 35.331399,
      "longitude": -91.520754,
      "city": "Bald Knob",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72011,
      "latitude": 34.509071,
      "longitude": -92.482828,
      "city": "Bauxite",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72012,
      "latitude": 35.173395,
      "longitude": -91.808393,
      "city": "Beebe",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72013,
      "latitude": 35.404671,
      "longitude": -92.4808,
      "city": "Bee Branch",
      "state": "AR",
      "county": "Van Buren"
  },
  {
      "zipCode": 72014,
      "latitude": 35.418352,
      "longitude": -91.116292,
      "city": "Beedeville",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72015,
      "latitude": 34.614356,
      "longitude": -92.668034,
      "city": "Benton",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72016,
      "latitude": 34.968975,
      "longitude": -92.626882,
      "city": "Bigelow",
      "state": "AR",
      "county": "Perry"
  },
  {
      "zipCode": 72017,
      "latitude": 34.785525,
      "longitude": -91.573785,
      "city": "Biscoe",
      "state": "AR",
      "county": "Prairie"
  },
  {
      "zipCode": 72018,
      "latitude": 34.597345,
      "longitude": -92.622857,
      "city": "Benton",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72020,
      "latitude": 35.380014,
      "longitude": -91.525309,
      "city": "Bradford",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72021,
      "latitude": 34.883421,
      "longitude": -91.194574,
      "city": "Brinkley",
      "state": "AR",
      "county": "Monroe"
  },
  {
      "zipCode": 72022,
      "latitude": 34.612417,
      "longitude": -92.493519,
      "city": "Bryant",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72023,
      "latitude": 34.826103,
      "longitude": -91.931526,
      "city": "Cabot",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72024,
      "latitude": 34.7309,
      "longitude": -91.75149,
      "city": "Carlisle",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72025,
      "latitude": 34.943809,
      "longitude": -92.923191,
      "city": "Casa",
      "state": "AR",
      "county": "Perry"
  },
  {
      "zipCode": 72026,
      "latitude": 34.509537,
      "longitude": -91.299038,
      "city": "Casscoe",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72027,
      "latitude": 35.311464,
      "longitude": -92.572763,
      "city": "Center Ridge",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72028,
      "latitude": 35.57727,
      "longitude": -92.545572,
      "city": "Choctaw",
      "state": "AR",
      "county": "Van Buren"
  },
  {
      "zipCode": 72029,
      "latitude": 34.745361,
      "longitude": -91.247509,
      "city": "Clarendon",
      "state": "AR",
      "county": "Monroe"
  },
  {
      "zipCode": 72030,
      "latitude": 35.395535,
      "longitude": -92.701955,
      "city": "Cleveland",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72031,
      "latitude": 35.568709,
      "longitude": -92.41875,
      "city": "Clinton",
      "state": "AR",
      "county": "Van Buren"
  },
  {
      "zipCode": 72032,
      "latitude": 35.146446,
      "longitude": -92.376466,
      "city": "Conway",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72033,
      "latitude": 35.105306,
      "longitude": -92.354915,
      "city": "Conway",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72034,
      "latitude": 35.053787,
      "longitude": -92.47871,
      "city": "Conway",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72035,
      "latitude": 35.105306,
      "longitude": -92.354915,
      "city": "Conway",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72036,
      "latitude": 35.004397,
      "longitude": -91.252284,
      "city": "Cotton Plant",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72037,
      "latitude": 34.541185,
      "longitude": -91.875767,
      "city": "Coy",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72038,
      "latitude": 34.459355,
      "longitude": -91.317774,
      "city": "Crocketts Bluff",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72039,
      "latitude": 35.302414,
      "longitude": -92.401428,
      "city": "Damascus",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72040,
      "latitude": 34.831514,
      "longitude": -91.53744,
      "city": "Des Arc",
      "state": "AR",
      "county": "Prairie"
  },
  {
      "zipCode": 72041,
      "latitude": 34.787106,
      "longitude": -91.465859,
      "city": "De Valls Bluff",
      "state": "AR",
      "county": "Prairie"
  },
  {
      "zipCode": 72042,
      "latitude": 34.2746,
      "longitude": -91.414442,
      "city": "De Witt",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72043,
      "latitude": 35.632918,
      "longitude": -91.262651,
      "city": "Diaz",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72044,
      "latitude": 35.632128,
      "longitude": -92.157022,
      "city": "Edgemont",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72045,
      "latitude": 35.128619,
      "longitude": -92.043231,
      "city": "El Paso",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72046,
      "latitude": 34.759526,
      "longitude": -91.846164,
      "city": "England",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72047,
      "latitude": 35.230211,
      "longitude": -92.202242,
      "city": "Enola",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72048,
      "latitude": 34.298173,
      "longitude": -91.1484,
      "city": "Ethel",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72051,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Fox",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72052,
      "latitude": 35.144271,
      "longitude": -91.77722,
      "city": "Garner",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72053,
      "latitude": 34.709342,
      "longitude": -92.228271,
      "city": "College Station",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72055,
      "latitude": 34.126257,
      "longitude": -91.347993,
      "city": "Gillett",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72057,
      "latitude": 34.152591,
      "longitude": -92.304314,
      "city": "Grapevine",
      "state": "AR",
      "county": "Grant"
  },
  {
      "zipCode": 72058,
      "latitude": 35.211744,
      "longitude": -92.376242,
      "city": "Greenbrier",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72059,
      "latitude": 35.179148,
      "longitude": -91.259428,
      "city": "Gregory",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72060,
      "latitude": 35.113187,
      "longitude": -91.606092,
      "city": "Griffithville",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72061,
      "latitude": 35.322662,
      "longitude": -92.334843,
      "city": "Guy",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72063,
      "latitude": 35.318809,
      "longitude": -92.67533,
      "city": "Hattieville",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72064,
      "latitude": 34.782306,
      "longitude": -91.569612,
      "city": "Hazen",
      "state": "AR",
      "county": "Prairie"
  },
  {
      "zipCode": 72065,
      "latitude": 34.520167,
      "longitude": -92.205503,
      "city": "Hensley",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72066,
      "latitude": 34.785525,
      "longitude": -91.573785,
      "city": "Hickory Plains",
      "state": "AR",
      "county": "Prairie"
  },
  {
      "zipCode": 72067,
      "latitude": 35.568521,
      "longitude": -92.134082,
      "city": "Higden",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72068,
      "latitude": 35.152032,
      "longitude": -91.710779,
      "city": "Higginson",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72069,
      "latitude": 34.598059,
      "longitude": -91.183945,
      "city": "Holly Grove",
      "state": "AR",
      "county": "Monroe"
  },
  {
      "zipCode": 72070,
      "latitude": 35.055779,
      "longitude": -92.692323,
      "city": "Houston",
      "state": "AR",
      "county": "Perry"
  },
  {
      "zipCode": 72071,
      "latitude": 35.179148,
      "longitude": -91.259428,
      "city": "Howell",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72072,
      "latitude": 34.757194,
      "longitude": -91.833442,
      "city": "Humnoke",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72073,
      "latitude": 34.374078,
      "longitude": -91.638613,
      "city": "Humphrey",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72074,
      "latitude": 35.179148,
      "longitude": -91.259428,
      "city": "Hunter",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72075,
      "latitude": 35.641406,
      "longitude": -91.307189,
      "city": "Jacksonport",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72076,
      "latitude": 34.918774,
      "longitude": -92.15149,
      "city": "Jacksonville",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72078,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Jacksonville",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72079,
      "latitude": 34.402504,
      "longitude": -92.150644,
      "city": "Jefferson",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72080,
      "latitude": 35.427817,
      "longitude": -92.819567,
      "city": "Jerusalem",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72081,
      "latitude": 35.39438,
      "longitude": -91.758198,
      "city": "Judsonia",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72082,
      "latitude": 35.14574,
      "longitude": -91.847466,
      "city": "Kensett",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72083,
      "latitude": 34.60737,
      "longitude": -92.014365,
      "city": "Keo",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72084,
      "latitude": 34.218652,
      "longitude": -92.613185,
      "city": "Leola",
      "state": "AR",
      "county": "Grant"
  },
  {
      "zipCode": 72085,
      "latitude": 35.359669,
      "longitude": -91.820426,
      "city": "Letona",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72086,
      "latitude": 34.81247,
      "longitude": -91.905399,
      "city": "Lonoke",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72087,
      "latitude": 34.574419,
      "longitude": -92.85784,
      "city": "Lonsdale",
      "state": "AR",
      "county": "Garland"
  },
  {
      "zipCode": 72088,
      "latitude": 35.602704,
      "longitude": -92.274195,
      "city": "Fairfield Bay",
      "state": "AR",
      "county": "Van Buren"
  },
  {
      "zipCode": 72089,
      "latitude": 34.635041,
      "longitude": -92.65974,
      "city": "Bryant",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72099,
      "latitude": 34.788014,
      "longitude": -92.217343,
      "city": "Little Rock Air Force Base",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72101,
      "latitude": 35.259987,
      "longitude": -91.219846,
      "city": "Mc Crory",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72102,
      "latitude": 35.246445,
      "longitude": -91.848847,
      "city": "Mc Rae",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72103,
      "latitude": 34.647826,
      "longitude": -92.384905,
      "city": "Mabelvale",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72104,
      "latitude": 34.36975,
      "longitude": -92.839331,
      "city": "Malvern",
      "state": "AR",
      "county": "Hot Spring"
  },
  {
      "zipCode": 72105,
      "latitude": 34.39588,
      "longitude": -92.851558,
      "city": "Jones Mill",
      "state": "AR",
      "county": "Hot Spring"
  },
  {
      "zipCode": 72106,
      "latitude": 34.963658,
      "longitude": -92.423585,
      "city": "Mayflower",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72107,
      "latitude": 35.153852,
      "longitude": -92.552168,
      "city": "Menifee",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72108,
      "latitude": 34.670187,
      "longitude": -91.224126,
      "city": "Monroe",
      "state": "AR",
      "county": "Monroe"
  },
  {
      "zipCode": 72110,
      "latitude": 35.169237,
      "longitude": -92.817379,
      "city": "Morrilton",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72111,
      "latitude": 35.23159,
      "longitude": -92.168875,
      "city": "Mount Vernon",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72112,
      "latitude": 35.569278,
      "longitude": -91.203951,
      "city": "Newport",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72113,
      "latitude": 34.857241,
      "longitude": -92.390298,
      "city": "Maumelle",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72114,
      "latitude": 34.766561,
      "longitude": -92.262899,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72115,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72116,
      "latitude": 34.805945,
      "longitude": -92.230488,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72117,
      "latitude": 34.765751,
      "longitude": -92.152405,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72118,
      "latitude": 34.833714,
      "longitude": -92.3289,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72119,
      "latitude": 34.80165,
      "longitude": -92.259781,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72120,
      "latitude": 34.880689,
      "longitude": -92.230285,
      "city": "Sherwood",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72121,
      "latitude": 35.329969,
      "longitude": -91.652684,
      "city": "Pangburn",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72122,
      "latitude": 34.738557,
      "longitude": -92.785514,
      "city": "Paron",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72123,
      "latitude": 35.25881,
      "longitude": -91.236983,
      "city": "Patterson",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72124,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72125,
      "latitude": 35.044346,
      "longitude": -92.795821,
      "city": "Perry",
      "state": "AR",
      "county": "Perry"
  },
  {
      "zipCode": 72126,
      "latitude": 35.025904,
      "longitude": -92.786535,
      "city": "Perryville",
      "state": "AR",
      "county": "Perry"
  },
  {
      "zipCode": 72127,
      "latitude": 35.15351,
      "longitude": -92.662307,
      "city": "Plumerville",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72128,
      "latitude": 34.3023,
      "longitude": -92.609528,
      "city": "Poyen",
      "state": "AR",
      "county": "Grant"
  },
  {
      "zipCode": 72129,
      "latitude": 34.31491,
      "longitude": -92.539414,
      "city": "Prattsville",
      "state": "AR",
      "county": "Grant"
  },
  {
      "zipCode": 72130,
      "latitude": 35.667434,
      "longitude": -92.093319,
      "city": "Prim",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72131,
      "latitude": 35.449492,
      "longitude": -92.151804,
      "city": "Quitman",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72132,
      "latitude": 34.436424,
      "longitude": -92.150356,
      "city": "Redfield",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72133,
      "latitude": 34.210031,
      "longitude": -91.673115,
      "city": "Reydell",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72134,
      "latitude": 34.670187,
      "longitude": -91.224126,
      "city": "Roe",
      "state": "AR",
      "county": "Monroe"
  },
  {
      "zipCode": 72135,
      "latitude": 34.87923,
      "longitude": -92.572248,
      "city": "Roland",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72136,
      "latitude": 35.235771,
      "longitude": -92.014311,
      "city": "Romance",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72137,
      "latitude": 35.281826,
      "longitude": -91.855634,
      "city": "Rose Bud",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72139,
      "latitude": 35.356592,
      "longitude": -91.495392,
      "city": "Russell",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72140,
      "latitude": 34.337186,
      "longitude": -91.139284,
      "city": "Saint Charles",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72141,
      "latitude": 35.57727,
      "longitude": -92.545572,
      "city": "Scotland",
      "state": "AR",
      "county": "Van Buren"
  },
  {
      "zipCode": 72142,
      "latitude": 34.670904,
      "longitude": -92.152336,
      "city": "Scott",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72143,
      "latitude": 35.243622,
      "longitude": -91.731729,
      "city": "Searcy",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72145,
      "latitude": 35.277393,
      "longitude": -91.732556,
      "city": "Searcy",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72149,
      "latitude": 35.247376,
      "longitude": -91.73139,
      "city": "Searcy",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72150,
      "latitude": 34.306936,
      "longitude": -92.398709,
      "city": "Sheridan",
      "state": "AR",
      "county": "Grant"
  },
  {
      "zipCode": 72152,
      "latitude": 34.363527,
      "longitude": -91.927642,
      "city": "Sherrill",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72153,
      "latitude": 35.61711,
      "longitude": -92.262595,
      "city": "Shirley",
      "state": "AR",
      "county": "Van Buren"
  },
  {
      "zipCode": 72156,
      "latitude": 35.267859,
      "longitude": -92.673551,
      "city": "Solgohachia",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72157,
      "latitude": 35.244463,
      "longitude": -92.614982,
      "city": "Springfield",
      "state": "AR",
      "county": "Conway"
  },
  {
      "zipCode": 72158,
      "latitude": 34.635041,
      "longitude": -92.65974,
      "city": "Benton",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72160,
      "latitude": 34.387577,
      "longitude": -91.419955,
      "city": "Stuttgart",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72164,
      "latitude": 34.687292,
      "longitude": -92.2399,
      "city": "Sweet Home",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72165,
      "latitude": 35.576231,
      "longitude": -91.453861,
      "city": "Thida",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72166,
      "latitude": 34.079836,
      "longitude": -91.227608,
      "city": "Tichnor",
      "state": "AR",
      "county": "Arkansas"
  },
  {
      "zipCode": 72167,
      "latitude": 34.469233,
      "longitude": -92.658212,
      "city": "Traskwood",
      "state": "AR",
      "county": "Saline"
  },
  {
      "zipCode": 72168,
      "latitude": 34.431352,
      "longitude": -91.96546,
      "city": "Tucker",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72169,
      "latitude": 35.384449,
      "longitude": -91.223248,
      "city": "Tupelo",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72170,
      "latitude": 34.577224,
      "longitude": -91.463319,
      "city": "Ulm",
      "state": "AR",
      "county": "Prairie"
  },
  {
      "zipCode": 72173,
      "latitude": 35.104753,
      "longitude": -92.228182,
      "city": "Vilonia",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72175,
      "latitude": 34.348461,
      "longitude": -91.862772,
      "city": "Wabbaseka",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72176,
      "latitude": 34.988267,
      "longitude": -91.905266,
      "city": "Ward",
      "state": "AR",
      "county": "Lonoke"
  },
  {
      "zipCode": 72178,
      "latitude": 35.203213,
      "longitude": -91.606367,
      "city": "West Point",
      "state": "AR",
      "county": "White"
  },
  {
      "zipCode": 72179,
      "latitude": 35.510638,
      "longitude": -91.864757,
      "city": "Wilburn",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72180,
      "latitude": 34.533557,
      "longitude": -92.221807,
      "city": "Woodson",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72181,
      "latitude": 35.187728,
      "longitude": -92.449953,
      "city": "Wooster",
      "state": "AR",
      "county": "Faulkner"
  },
  {
      "zipCode": 72182,
      "latitude": 34.429059,
      "longitude": -92.059863,
      "city": "Wright",
      "state": "AR",
      "county": "Jefferson"
  },
  {
      "zipCode": 72183,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Wrightsville",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72189,
      "latitude": 35.179148,
      "longitude": -91.259428,
      "city": "Mc Crory",
      "state": "AR",
      "county": "Woodruff"
  },
  {
      "zipCode": 72190,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72199,
      "latitude": 34.827174,
      "longitude": -92.284718,
      "city": "North Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72201,
      "latitude": 34.755998,
      "longitude": -92.284832,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72202,
      "latitude": 34.752299,
      "longitude": -92.290419,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72203,
      "latitude": 34.883484,
      "longitude": -92.390766,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72204,
      "latitude": 34.711026,
      "longitude": -92.297304,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72205,
      "latitude": 34.742911,
      "longitude": -92.346652,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72206,
      "latitude": 34.624693,
      "longitude": -92.29525,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72207,
      "latitude": 34.772646,
      "longitude": -92.340152,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72209,
      "latitude": 34.674055,
      "longitude": -92.368649,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72210,
      "latitude": 34.723533,
      "longitude": -92.478032,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72211,
      "latitude": 34.783664,
      "longitude": -92.430851,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72212,
      "latitude": 34.800793,
      "longitude": -92.441752,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72214,
      "latitude": 34.762141,
      "longitude": -92.228248,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72215,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72216,
      "latitude": 34.817834,
      "longitude": -92.235668,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72217,
      "latitude": 34.830158,
      "longitude": -92.170174,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72219,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72221,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72222,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72223,
      "latitude": 34.790155,
      "longitude": -92.504359,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72225,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72227,
      "latitude": 34.775747,
      "longitude": -92.372386,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72231,
      "latitude": 34.80189,
      "longitude": -92.189397,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72260,
      "latitude": 34.658344,
      "longitude": -92.427982,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72295,
      "latitude": 34.751918,
      "longitude": -92.392487,
      "city": "Little Rock",
      "state": "AR",
      "county": "Pulaski"
  },
  {
      "zipCode": 72301,
      "latitude": 35.210811,
      "longitude": -90.203849,
      "city": "West Memphis",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72303,
      "latitude": 35.137366,
      "longitude": -90.285543,
      "city": "West Memphis",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72310,
      "latitude": 35.852046,
      "longitude": -89.972762,
      "city": "Armorel",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72311,
      "latitude": 34.719477,
      "longitude": -90.89691,
      "city": "Aubrey",
      "state": "AR",
      "county": "Lee"
  },
  {
      "zipCode": 72312,
      "latitude": 34.546696,
      "longitude": -90.767879,
      "city": "Barton",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72313,
      "latitude": 35.712249,
      "longitude": -90.028429,
      "city": "Bassett",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72314,
      "latitude": 35.357184,
      "longitude": -90.677331,
      "city": "Birdeye",
      "state": "AR",
      "county": "Cross"
  },
  {
      "zipCode": 72315,
      "latitude": 35.754685,
      "longitude": -89.926797,
      "city": "Blytheville",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72316,
      "latitude": 35.694942,
      "longitude": -89.966819,
      "city": "Blytheville",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72319,
      "latitude": 35.694942,
      "longitude": -89.966819,
      "city": "Gosnell",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72320,
      "latitude": 34.839836,
      "longitude": -90.73371,
      "city": "Brickeys",
      "state": "AR",
      "county": "Lee"
  },
  {
      "zipCode": 72321,
      "latitude": 35.816872,
      "longitude": -89.944221,
      "city": "Burdette",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72322,
      "latitude": 35.08844,
      "longitude": -90.81081,
      "city": "Caldwell",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72324,
      "latitude": 35.340002,
      "longitude": -90.788851,
      "city": "Cherry Valley",
      "state": "AR",
      "county": "Cross"
  },
  {
      "zipCode": 72325,
      "latitude": 35.286354,
      "longitude": -90.253781,
      "city": "Clarkedale",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72326,
      "latitude": 35.088055,
      "longitude": -90.889489,
      "city": "Colt",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72327,
      "latitude": 35.232042,
      "longitude": -90.35832,
      "city": "Crawfordsville",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72328,
      "latitude": 34.382204,
      "longitude": -90.82932,
      "city": "Crumrod",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72329,
      "latitude": 35.635225,
      "longitude": -89.978877,
      "city": "Driver",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72330,
      "latitude": 35.593098,
      "longitude": -90.21404,
      "city": "Dyess",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72331,
      "latitude": 35.270213,
      "longitude": -90.355335,
      "city": "Earle",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72332,
      "latitude": 35.119869,
      "longitude": -90.304367,
      "city": "Edmondson",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72333,
      "latitude": 34.311793,
      "longitude": -90.893927,
      "city": "Elaine",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72335,
      "latitude": 35.026272,
      "longitude": -90.859555,
      "city": "Forrest City",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72336,
      "latitude": 35.100867,
      "longitude": -90.726069,
      "city": "Forrest City",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72338,
      "latitude": 35.459134,
      "longitude": -90.188375,
      "city": "Frenchmans Bayou",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72339,
      "latitude": 35.405678,
      "longitude": -90.27136,
      "city": "Gilmore",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72340,
      "latitude": 34.935798,
      "longitude": -91.029194,
      "city": "Goodwin",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72341,
      "latitude": 34.894534,
      "longitude": -90.750779,
      "city": "Haynes",
      "state": "AR",
      "county": "Lee"
  },
  {
      "zipCode": 72342,
      "latitude": 34.45192,
      "longitude": -90.751249,
      "city": "Helena",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72346,
      "latitude": 35.055842,
      "longitude": -90.508034,
      "city": "Heth",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72347,
      "latitude": 35.358936,
      "longitude": -90.958977,
      "city": "Hickory Ridge",
      "state": "AR",
      "county": "Cross"
  },
  {
      "zipCode": 72348,
      "latitude": 34.959855,
      "longitude": -90.564357,
      "city": "Hughes",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72350,
      "latitude": 35.501635,
      "longitude": -90.145752,
      "city": "Joiner",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72351,
      "latitude": 35.695435,
      "longitude": -90.08845,
      "city": "Keiser",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72352,
      "latitude": 34.769714,
      "longitude": -90.755195,
      "city": "La Grange",
      "state": "AR",
      "county": "Lee"
  },
  {
      "zipCode": 72353,
      "latitude": 34.328604,
      "longitude": -90.965177,
      "city": "Lambrook",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72354,
      "latitude": 35.596339,
      "longitude": -90.340023,
      "city": "Lepanto",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72355,
      "latitude": 34.522109,
      "longitude": -90.746809,
      "city": "Lexa",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72358,
      "latitude": 35.773408,
      "longitude": -89.887854,
      "city": "Luxora",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72359,
      "latitude": 35.025243,
      "longitude": -90.708551,
      "city": "Madison",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72360,
      "latitude": 34.768479,
      "longitude": -90.783138,
      "city": "Marianna",
      "state": "AR",
      "county": "Lee"
  },
  {
      "zipCode": 72364,
      "latitude": 35.174997,
      "longitude": -90.294424,
      "city": "Marion",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72365,
      "latitude": 35.531267,
      "longitude": -90.43642,
      "city": "Marked Tree",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72366,
      "latitude": 34.5334,
      "longitude": -90.954242,
      "city": "Marvell",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72367,
      "latitude": 34.25968,
      "longitude": -90.936604,
      "city": "Mellwood",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72368,
      "latitude": 34.791849,
      "longitude": -90.935139,
      "city": "Moro",
      "state": "AR",
      "county": "Lee"
  },
  {
      "zipCode": 72369,
      "latitude": 34.443028,
      "longitude": -90.785809,
      "city": "Oneida",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72370,
      "latitude": 35.646832,
      "longitude": -90.094653,
      "city": "Osceola",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72372,
      "latitude": 35.027132,
      "longitude": -90.901168,
      "city": "Palestine",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72373,
      "latitude": 35.293247,
      "longitude": -90.635505,
      "city": "Parkin",
      "state": "AR",
      "county": "Cross"
  },
  {
      "zipCode": 72374,
      "latitude": 34.571702,
      "longitude": -90.819627,
      "city": "Poplar Grove",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72376,
      "latitude": 35.116116,
      "longitude": -90.238064,
      "city": "Proctor",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72377,
      "latitude": 35.674952,
      "longitude": -90.346901,
      "city": "Rivervale",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72379,
      "latitude": 33.818318,
      "longitude": -91.214054,
      "city": "Snow Lake",
      "state": "AR",
      "county": "Desha"
  },
  {
      "zipCode": 72381,
      "latitude": 35.694942,
      "longitude": -89.966819,
      "city": "Tomato",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72383,
      "latitude": 34.498511,
      "longitude": -91.027962,
      "city": "Turner",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72384,
      "latitude": 35.343902,
      "longitude": -90.297822,
      "city": "Turrell",
      "state": "AR",
      "county": "Crittenden"
  },
  {
      "zipCode": 72385,
      "latitude": 35.296539,
      "longitude": -90.772419,
      "city": "Twist",
      "state": "AR",
      "county": "Cross"
  },
  {
      "zipCode": 72386,
      "latitude": 35.49813,
      "longitude": -90.397619,
      "city": "Tyronza",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72387,
      "latitude": 35.3182,
      "longitude": -90.771505,
      "city": "Vanndale",
      "state": "AR",
      "county": "Cross"
  },
  {
      "zipCode": 72389,
      "latitude": 34.346621,
      "longitude": -90.886949,
      "city": "Wabash",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72390,
      "latitude": 34.530216,
      "longitude": -90.681442,
      "city": "West Helena",
      "state": "AR",
      "county": "Phillips"
  },
  {
      "zipCode": 72391,
      "latitude": 35.694942,
      "longitude": -89.966819,
      "city": "West Ridge",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72392,
      "latitude": 34.950619,
      "longitude": -91.091003,
      "city": "Wheatley",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72394,
      "latitude": 35.030375,
      "longitude": -90.800681,
      "city": "Widener",
      "state": "AR",
      "county": "Saint Francis"
  },
  {
      "zipCode": 72395,
      "latitude": 35.63816,
      "longitude": -90.043135,
      "city": "Wilson",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72396,
      "latitude": 35.274844,
      "longitude": -90.798946,
      "city": "Wynne",
      "state": "AR",
      "county": "Cross"
  },
  {
      "zipCode": 72401,
      "latitude": 35.835847,
      "longitude": -90.623071,
      "city": "Jonesboro",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72402,
      "latitude": 35.80881,
      "longitude": -90.652887,
      "city": "Jonesboro",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72403,
      "latitude": 35.830541,
      "longitude": -90.703915,
      "city": "Jonesboro",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72404,
      "latitude": 35.779183,
      "longitude": -90.766012,
      "city": "Jonesboro",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72410,
      "latitude": 35.952057,
      "longitude": -91.030074,
      "city": "Alicia",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72411,
      "latitude": 35.810496,
      "longitude": -90.641731,
      "city": "Bay",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72412,
      "latitude": 36.128069,
      "longitude": -90.691421,
      "city": "Beech Grove",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72413,
      "latitude": 36.284433,
      "longitude": -90.853751,
      "city": "Biggers",
      "state": "AR",
      "county": "Randolph"
  },
  {
      "zipCode": 72414,
      "latitude": 35.836846,
      "longitude": -90.400484,
      "city": "Black Oak",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72415,
      "latitude": 36.132344,
      "longitude": -91.187361,
      "city": "Black Rock",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72416,
      "latitude": 35.85642,
      "longitude": -90.627804,
      "city": "Bono",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72417,
      "latitude": 35.891366,
      "longitude": -90.489547,
      "city": "Brookland",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72419,
      "latitude": 35.767307,
      "longitude": -90.338001,
      "city": "Caraway",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72421,
      "latitude": 35.795933,
      "longitude": -90.963448,
      "city": "Cash",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72422,
      "latitude": 36.379467,
      "longitude": -90.538667,
      "city": "Corning",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72424,
      "latitude": 36.384747,
      "longitude": -90.730808,
      "city": "Datto",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72425,
      "latitude": 36.208255,
      "longitude": -90.721173,
      "city": "Delaplaine",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72426,
      "latitude": 35.881256,
      "longitude": -90.054689,
      "city": "Dell",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72427,
      "latitude": 35.863996,
      "longitude": -90.94278,
      "city": "Egypt",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72428,
      "latitude": 35.733677,
      "longitude": -90.183815,
      "city": "Etowah",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72429,
      "latitude": 35.503918,
      "longitude": -90.944428,
      "city": "Fisher",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72430,
      "latitude": 36.327813,
      "longitude": -90.182485,
      "city": "Greenway",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72431,
      "latitude": 35.654913,
      "longitude": -91.079185,
      "city": "Grubbs",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72432,
      "latitude": 35.575374,
      "longitude": -90.791023,
      "city": "Harrisburg",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72433,
      "latitude": 36.050395,
      "longitude": -91.021301,
      "city": "Hoxie",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72434,
      "latitude": 36.183681,
      "longitude": -91.215672,
      "city": "Imboden",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72435,
      "latitude": 36.360817,
      "longitude": -90.472819,
      "city": "Knobel",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72436,
      "latitude": 36.202584,
      "longitude": -90.469652,
      "city": "Lafe",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72437,
      "latitude": 35.824808,
      "longitude": -90.510018,
      "city": "Lake City",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72438,
      "latitude": 35.884302,
      "longitude": -90.210167,
      "city": "Leachville",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72439,
      "latitude": 36.06805,
      "longitude": -90.748876,
      "city": "Light",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72440,
      "latitude": 36.015239,
      "longitude": -91.229356,
      "city": "Lynn",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72441,
      "latitude": 36.429953,
      "longitude": -90.386431,
      "city": "Mc Dougal",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72442,
      "latitude": 35.843919,
      "longitude": -90.1806,
      "city": "Manila",
      "state": "AR",
      "county": "Mississippi"
  },
  {
      "zipCode": 72443,
      "latitude": 36.156146,
      "longitude": -90.39974,
      "city": "Marmaduke",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72444,
      "latitude": 36.396694,
      "longitude": -90.936899,
      "city": "Maynard",
      "state": "AR",
      "county": "Randolph"
  },
  {
      "zipCode": 72445,
      "latitude": 35.974287,
      "longitude": -91.031799,
      "city": "Minturn",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72447,
      "latitude": 35.909972,
      "longitude": -90.347446,
      "city": "Monette",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72449,
      "latitude": 36.306745,
      "longitude": -91.07791,
      "city": "O Kean",
      "state": "AR",
      "county": "Randolph"
  },
  {
      "zipCode": 72450,
      "latitude": 36.113222,
      "longitude": -90.551092,
      "city": "Paragould",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72451,
      "latitude": 36.11635,
      "longitude": -90.525077,
      "city": "Paragould",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72453,
      "latitude": 36.277564,
      "longitude": -90.687882,
      "city": "Peach Orchard",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72454,
      "latitude": 36.378787,
      "longitude": -90.313223,
      "city": "Piggott",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72455,
      "latitude": 36.324042,
      "longitude": -91.084748,
      "city": "Pocahontas",
      "state": "AR",
      "county": "Randolph"
  },
  {
      "zipCode": 72456,
      "latitude": 36.38157,
      "longitude": -90.375252,
      "city": "Pollard",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72457,
      "latitude": 36.082104,
      "longitude": -91.047594,
      "city": "Portia",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72458,
      "latitude": 36.053597,
      "longitude": -91.218089,
      "city": "Powhatan",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72459,
      "latitude": 36.18246,
      "longitude": -91.223314,
      "city": "Ravenden",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72460,
      "latitude": 36.337228,
      "longitude": -91.009405,
      "city": "Ravenden Springs",
      "state": "AR",
      "county": "Randolph"
  },
  {
      "zipCode": 72461,
      "latitude": 36.310788,
      "longitude": -90.329565,
      "city": "Rector",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72462,
      "latitude": 36.351441,
      "longitude": -90.780125,
      "city": "Reyno",
      "state": "AR",
      "county": "Randolph"
  },
  {
      "zipCode": 72464,
      "latitude": 36.454544,
      "longitude": -90.142521,
      "city": "Saint Francis",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72465,
      "latitude": 35.964317,
      "longitude": -90.895421,
      "city": "Sedgwick",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72466,
      "latitude": 36.057939,
      "longitude": -91.240065,
      "city": "Smithville",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72467,
      "latitude": 35.842196,
      "longitude": -90.673464,
      "city": "State University",
      "state": "AR",
      "county": "Craighead"
  },
  {
      "zipCode": 72469,
      "latitude": 35.967306,
      "longitude": -91.293503,
      "city": "Strawberry",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72470,
      "latitude": 36.429663,
      "longitude": -90.692099,
      "city": "Success",
      "state": "AR",
      "county": "Clay"
  },
  {
      "zipCode": 72471,
      "latitude": 35.817646,
      "longitude": -91.092685,
      "city": "Swifton",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72472,
      "latitude": 35.588215,
      "longitude": -90.561977,
      "city": "Trumann",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72473,
      "latitude": 35.71528,
      "longitude": -91.173296,
      "city": "Tuckerman",
      "state": "AR",
      "county": "Jackson"
  },
  {
      "zipCode": 72474,
      "latitude": 36.041332,
      "longitude": -90.671802,
      "city": "Walcott",
      "state": "AR",
      "county": "Greene"
  },
  {
      "zipCode": 72475,
      "latitude": 35.546575,
      "longitude": -90.965815,
      "city": "Waldenburg",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72476,
      "latitude": 36.042979,
      "longitude": -90.998707,
      "city": "Walnut Ridge",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72478,
      "latitude": 36.44533,
      "longitude": -91.054035,
      "city": "Warm Springs",
      "state": "AR",
      "county": "Randolph"
  },
  {
      "zipCode": 72479,
      "latitude": 35.611992,
      "longitude": -90.873808,
      "city": "Weiner",
      "state": "AR",
      "county": "Poinsett"
  },
  {
      "zipCode": 72482,
      "latitude": 36.285559,
      "longitude": -91.355691,
      "city": "Williford",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72501,
      "latitude": 35.768738,
      "longitude": -91.652338,
      "city": "Batesville",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72503,
      "latitude": 35.734617,
      "longitude": -91.534303,
      "city": "Batesville",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72512,
      "latitude": 36.185457,
      "longitude": -91.915187,
      "city": "Horseshoe Bend",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72513,
      "latitude": 36.234637,
      "longitude": -91.568849,
      "city": "Ash Flat",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72515,
      "latitude": 36.374704,
      "longitude": -91.804608,
      "city": "Bexar",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72516,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Boswell",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72517,
      "latitude": 36.121391,
      "longitude": -92.134903,
      "city": "Brockwell",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72519,
      "latitude": 36.178897,
      "longitude": -92.129961,
      "city": "Calico Rock",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72520,
      "latitude": 36.374704,
      "longitude": -91.804608,
      "city": "Camp",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72521,
      "latitude": 35.948292,
      "longitude": -91.5317,
      "city": "Cave City",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72522,
      "latitude": 35.803978,
      "longitude": -91.456609,
      "city": "Charlotte",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72523,
      "latitude": 35.642663,
      "longitude": -91.857781,
      "city": "Concord",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72524,
      "latitude": 35.843546,
      "longitude": -91.36155,
      "city": "Cord",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72525,
      "latitude": 36.295879,
      "longitude": -91.488845,
      "city": "Cherokee Village",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72526,
      "latitude": 35.872871,
      "longitude": -91.758171,
      "city": "Cushman",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72527,
      "latitude": 35.726232,
      "longitude": -91.603429,
      "city": "Desha",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72528,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Dolph",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72529,
      "latitude": 36.298192,
      "longitude": -91.534657,
      "city": "Cherokee Village",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72530,
      "latitude": 35.607847,
      "longitude": -92.022867,
      "city": "Drasco",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72531,
      "latitude": 36.374704,
      "longitude": -91.804608,
      "city": "Elizabeth",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72532,
      "latitude": 36.061757,
      "longitude": -91.607603,
      "city": "Evening Shade",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72533,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Fifty Six",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72534,
      "latitude": 35.663046,
      "longitude": -91.712976,
      "city": "Floral",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72536,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Franklin",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72537,
      "latitude": 36.42957,
      "longitude": -92.325688,
      "city": "Gamaliel",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72538,
      "latitude": 36.370988,
      "longitude": -91.823914,
      "city": "Gepp",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72539,
      "latitude": 36.374704,
      "longitude": -91.804608,
      "city": "Glencoe",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72540,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Guion",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72542,
      "latitude": 36.178441,
      "longitude": -91.481669,
      "city": "Hardy",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72543,
      "latitude": 35.453106,
      "longitude": -91.98883,
      "city": "Heber Springs",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72544,
      "latitude": 36.410457,
      "longitude": -92.195113,
      "city": "Henderson",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72545,
      "latitude": 35.535238,
      "longitude": -92.021291,
      "city": "Heber Springs",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72546,
      "latitude": 35.581607,
      "longitude": -91.930535,
      "city": "Ida",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72550,
      "latitude": 35.712421,
      "longitude": -91.778953,
      "city": "Locust Grove",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72553,
      "latitude": 35.707658,
      "longitude": -91.474841,
      "city": "Magness",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72554,
      "latitude": 36.492437,
      "longitude": -91.546818,
      "city": "Mammoth Spring",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72555,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Marcella",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72556,
      "latitude": 36.063529,
      "longitude": -91.907947,
      "city": "Melbourne",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72557,
      "latitude": 36.374704,
      "longitude": -91.804608,
      "city": "Moko",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72560,
      "latitude": 35.820825,
      "longitude": -92.038894,
      "city": "Mountain View",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72561,
      "latitude": 35.961824,
      "longitude": -91.819741,
      "city": "Mount Pleasant",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72562,
      "latitude": 35.732409,
      "longitude": -91.42517,
      "city": "Newark",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72564,
      "latitude": 35.59985,
      "longitude": -91.475445,
      "city": "Oil Trough",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72565,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Oxford",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72566,
      "latitude": 36.157991,
      "longitude": -92.112983,
      "city": "Pineville",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72567,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Pleasant Grove",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72568,
      "latitude": 35.654477,
      "longitude": -91.619627,
      "city": "Pleasant Plains",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72569,
      "latitude": 36.046345,
      "longitude": -91.455012,
      "city": "Poughkeepsie",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72571,
      "latitude": 35.645494,
      "longitude": -91.536246,
      "city": "Rosie",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72572,
      "latitude": 35.953043,
      "longitude": -91.283897,
      "city": "Saffell",
      "state": "AR",
      "county": "Lawrence"
  },
  {
      "zipCode": 72573,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Sage",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72575,
      "latitude": 35.691868,
      "longitude": -91.598917,
      "city": "Salado",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72576,
      "latitude": 36.425836,
      "longitude": -91.699502,
      "city": "Salem",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72577,
      "latitude": 36.066371,
      "longitude": -91.64203,
      "city": "Sidney",
      "state": "AR",
      "county": "Sharp"
  },
  {
      "zipCode": 72578,
      "latitude": 36.374704,
      "longitude": -91.804608,
      "city": "Sturkie",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72579,
      "latitude": 35.762212,
      "longitude": -91.531986,
      "city": "Sulphur Rock",
      "state": "AR",
      "county": "Independence"
  },
  {
      "zipCode": 72581,
      "latitude": 35.570591,
      "longitude": -91.929979,
      "city": "Tumbling Shoals",
      "state": "AR",
      "county": "Cleburne"
  },
  {
      "zipCode": 72583,
      "latitude": 36.392508,
      "longitude": -91.93942,
      "city": "Viola",
      "state": "AR",
      "county": "Fulton"
  },
  {
      "zipCode": 72584,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Violet Hill",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72585,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Wideman",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72587,
      "latitude": 36.064329,
      "longitude": -91.944673,
      "city": "Wiseman",
      "state": "AR",
      "county": "Izard"
  },
  {
      "zipCode": 72601,
      "latitude": 36.295086,
      "longitude": -93.092861,
      "city": "Harrison",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72602,
      "latitude": 36.355213,
      "longitude": -93.122554,
      "city": "Harrison",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72610,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Alco",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72611,
      "latitude": 36.310814,
      "longitude": -93.252276,
      "city": "Alpena",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72613,
      "latitude": 36.431111,
      "longitude": -93.699392,
      "city": "Beaver",
      "state": "AR",
      "county": "Carroll"
  },
  {
      "zipCode": 72615,
      "latitude": 36.308349,
      "longitude": -93.032099,
      "city": "Bergman",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72616,
      "latitude": 36.316779,
      "longitude": -93.5616,
      "city": "Berryville",
      "state": "AR",
      "county": "Carroll"
  },
  {
      "zipCode": 72617,
      "latitude": 36.23638,
      "longitude": -92.372635,
      "city": "Big Flat",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72619,
      "latitude": 36.318875,
      "longitude": -92.629141,
      "city": "Bull Shoals",
      "state": "AR",
      "county": "Marion"
  },
  {
      "zipCode": 72623,
      "latitude": 36.472426,
      "longitude": -92.329645,
      "city": "Clarkridge",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72624,
      "latitude": 35.924868,
      "longitude": -93.232298,
      "city": "Compton",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72626,
      "latitude": 36.308162,
      "longitude": -92.542469,
      "city": "Cotter",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72628,
      "latitude": 35.924868,
      "longitude": -93.232298,
      "city": "Deer",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72629,
      "latitude": 35.57727,
      "longitude": -92.545572,
      "city": "Dennard",
      "state": "AR",
      "county": "Van Buren"
  },
  {
      "zipCode": 72630,
      "latitude": 36.458431,
      "longitude": -92.913268,
      "city": "Diamond City",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72631,
      "latitude": 36.435013,
      "longitude": -93.767298,
      "city": "Eureka Springs",
      "state": "AR",
      "county": "Carroll"
  },
  {
      "zipCode": 72632,
      "latitude": 36.399338,
      "longitude": -93.755084,
      "city": "Eureka Springs",
      "state": "AR",
      "county": "Carroll"
  },
  {
      "zipCode": 72633,
      "latitude": 36.164402,
      "longitude": -92.953069,
      "city": "Everton",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72634,
      "latitude": 36.317483,
      "longitude": -92.595011,
      "city": "Flippin",
      "state": "AR",
      "county": "Marion"
  },
  {
      "zipCode": 72635,
      "latitude": 36.318799,
      "longitude": -92.487866,
      "city": "Gassville",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72636,
      "latitude": 35.916744,
      "longitude": -92.681408,
      "city": "Gilbert",
      "state": "AR",
      "county": "Searcy"
  },
  {
      "zipCode": 72638,
      "latitude": 36.310046,
      "longitude": -93.57875,
      "city": "Green Forest",
      "state": "AR",
      "county": "Carroll"
  },
  {
      "zipCode": 72639,
      "latitude": 35.916744,
      "longitude": -92.681408,
      "city": "Harriet",
      "state": "AR",
      "county": "Searcy"
  },
  {
      "zipCode": 72640,
      "latitude": 35.924868,
      "longitude": -93.232298,
      "city": "Hasty",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72641,
      "latitude": 36.005808,
      "longitude": -93.187651,
      "city": "Jasper",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72642,
      "latitude": 36.372572,
      "longitude": -92.494857,
      "city": "Lakeview",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72644,
      "latitude": 36.384844,
      "longitude": -93.003892,
      "city": "Lead Hill",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72645,
      "latitude": 35.835751,
      "longitude": -92.554222,
      "city": "Leslie",
      "state": "AR",
      "county": "Searcy"
  },
  {
      "zipCode": 72648,
      "latitude": 35.924868,
      "longitude": -93.232298,
      "city": "Marble Falls",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72650,
      "latitude": 35.908133,
      "longitude": -92.63861,
      "city": "Marshall",
      "state": "AR",
      "county": "Searcy"
  },
  {
      "zipCode": 72651,
      "latitude": 36.37792,
      "longitude": -92.474246,
      "city": "Midway",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72653,
      "latitude": 36.318679,
      "longitude": -92.372153,
      "city": "Mountain Home",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72654,
      "latitude": 36.23638,
      "longitude": -92.372635,
      "city": "Mountain Home",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72655,
      "latitude": 35.940667,
      "longitude": -93.065393,
      "city": "Mount Judea",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72657,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Timbo",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72658,
      "latitude": 36.250583,
      "longitude": -92.310805,
      "city": "Norfork",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72659,
      "latitude": 36.23638,
      "longitude": -92.372635,
      "city": "Norfork",
      "state": "AR",
      "county": "Baxter"
  },
  {
      "zipCode": 72660,
      "latitude": 36.466366,
      "longitude": -93.370349,
      "city": "Oak Grove",
      "state": "AR",
      "county": "Carroll"
  },
  {
      "zipCode": 72661,
      "latitude": 36.457556,
      "longitude": -92.581535,
      "city": "Oakland",
      "state": "AR",
      "county": "Marion"
  },
  {
      "zipCode": 72662,
      "latitude": 36.421611,
      "longitude": -93.166451,
      "city": "Omaha",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72663,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Onia",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72666,
      "latitude": 35.924868,
      "longitude": -93.232298,
      "city": "Parthenon",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72668,
      "latitude": 36.280677,
      "longitude": -92.648858,
      "city": "Peel",
      "state": "AR",
      "county": "Marion"
  },
  {
      "zipCode": 72669,
      "latitude": 35.916744,
      "longitude": -92.681408,
      "city": "Pindall",
      "state": "AR",
      "county": "Searcy"
  },
  {
      "zipCode": 72670,
      "latitude": 35.924868,
      "longitude": -93.232298,
      "city": "Ponca",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72672,
      "latitude": 36.248623,
      "longitude": -92.841645,
      "city": "Pyatt",
      "state": "AR",
      "county": "Marion"
  },
  {
      "zipCode": 72675,
      "latitude": 35.908381,
      "longitude": -92.699416,
      "city": "Saint Joe",
      "state": "AR",
      "county": "Searcy"
  },
  {
      "zipCode": 72677,
      "latitude": 36.267239,
      "longitude": -92.685913,
      "city": "Summit",
      "state": "AR",
      "county": "Marion"
  },
  {
      "zipCode": 72679,
      "latitude": 35.706942,
      "longitude": -92.843116,
      "city": "Tilly",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72680,
      "latitude": 35.91896,
      "longitude": -92.127207,
      "city": "Timbo",
      "state": "AR",
      "county": "Stone"
  },
  {
      "zipCode": 72682,
      "latitude": 36.148259,
      "longitude": -92.989569,
      "city": "Valley Springs",
      "state": "AR",
      "county": "Boone"
  },
  {
      "zipCode": 72683,
      "latitude": 35.924868,
      "longitude": -93.232298,
      "city": "Vendor",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72685,
      "latitude": 36.098936,
      "longitude": -92.961082,
      "city": "Western Grove",
      "state": "AR",
      "county": "Newton"
  },
  {
      "zipCode": 72686,
      "latitude": 35.916744,
      "longitude": -92.681408,
      "city": "Witts Springs",
      "state": "AR",
      "county": "Searcy"
  },
  {
      "zipCode": 72687,
      "latitude": 36.254905,
      "longitude": -92.688068,
      "city": "Yellville",
      "state": "AR",
      "county": "Marion"
  },
  {
      "zipCode": 72701,
      "latitude": 35.974602,
      "longitude": -94.190997,
      "city": "Fayetteville",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72702,
      "latitude": 35.99403,
      "longitude": -94.219977,
      "city": "Fayetteville",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72703,
      "latitude": 36.126249,
      "longitude": -94.074168,
      "city": "Fayetteville",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72704,
      "latitude": 36.087732,
      "longitude": -94.309322,
      "city": "Fayetteville",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72711,
      "latitude": 36.397805,
      "longitude": -94.043837,
      "city": "Avoca",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72712,
      "latitude": 36.347107,
      "longitude": -94.223419,
      "city": "Bentonville",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72714,
      "latitude": 36.426659,
      "longitude": -94.330765,
      "city": "Bella Vista",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72715,
      "latitude": 36.442323,
      "longitude": -94.427298,
      "city": "Bella Vista",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72716,
      "latitude": 36.299507,
      "longitude": -93.956801,
      "city": "Bentonville",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72717,
      "latitude": 35.847701,
      "longitude": -94.444142,
      "city": "Canehill",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72718,
      "latitude": 36.266213,
      "longitude": -94.245722,
      "city": "Cave Springs",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72719,
      "latitude": 36.328519,
      "longitude": -94.366608,
      "city": "Centerton",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72721,
      "latitude": 36.033601,
      "longitude": -93.704255,
      "city": "Combs",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72722,
      "latitude": 36.321366,
      "longitude": -94.462415,
      "city": "Decatur",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72727,
      "latitude": 35.972331,
      "longitude": -93.997572,
      "city": "Elkins",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72728,
      "latitude": 36.210013,
      "longitude": -94.254573,
      "city": "Elm Springs",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72729,
      "latitude": 35.80515,
      "longitude": -94.460202,
      "city": "Evansville",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72730,
      "latitude": 36.081641,
      "longitude": -94.209785,
      "city": "Farmington",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72732,
      "latitude": 36.365048,
      "longitude": -94.184187,
      "city": "Garfield",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72733,
      "latitude": 36.486424,
      "longitude": -93.927748,
      "city": "Gateway",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72734,
      "latitude": 36.288541,
      "longitude": -94.435512,
      "city": "Gentry",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72735,
      "latitude": 36.087551,
      "longitude": -93.966645,
      "city": "Goshen",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72736,
      "latitude": 36.381245,
      "longitude": -94.471388,
      "city": "Gravette",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72737,
      "latitude": 35.928186,
      "longitude": -94.152899,
      "city": "Greenland",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72738,
      "latitude": 36.033601,
      "longitude": -93.704255,
      "city": "Hindsville",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72739,
      "latitude": 36.447008,
      "longitude": -94.339813,
      "city": "Hiwasse",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72740,
      "latitude": 36.028046,
      "longitude": -93.778748,
      "city": "Huntsville",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72741,
      "latitude": 35.99403,
      "longitude": -94.219977,
      "city": "Johnson",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72742,
      "latitude": 36.033601,
      "longitude": -93.704255,
      "city": "Kingston",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72744,
      "latitude": 35.935038,
      "longitude": -94.362211,
      "city": "Lincoln",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72745,
      "latitude": 36.29589,
      "longitude": -94.12368,
      "city": "Lowell",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72747,
      "latitude": 36.391882,
      "longitude": -94.583717,
      "city": "Maysville",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72749,
      "latitude": 35.861658,
      "longitude": -94.467398,
      "city": "Morrow",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72751,
      "latitude": 36.461444,
      "longitude": -94.129727,
      "city": "Pea Ridge",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72752,
      "latitude": 36.033601,
      "longitude": -93.704255,
      "city": "Pettigrew",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72753,
      "latitude": 35.927985,
      "longitude": -94.317796,
      "city": "Prairie Grove",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72756,
      "latitude": 36.355417,
      "longitude": -94.181568,
      "city": "Rogers",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72757,
      "latitude": 36.371981,
      "longitude": -94.115649,
      "city": "Rogers",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72758,
      "latitude": 36.316945,
      "longitude": -94.154459,
      "city": "Rogers",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72760,
      "latitude": 36.033601,
      "longitude": -93.704255,
      "city": "Saint Paul",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72761,
      "latitude": 36.21791,
      "longitude": -94.383778,
      "city": "Siloam Springs",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72762,
      "latitude": 36.16722,
      "longitude": -94.240112,
      "city": "Springdale",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72764,
      "latitude": 36.171598,
      "longitude": -94.042788,
      "city": "Springdale",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72765,
      "latitude": 36.172541,
      "longitude": -94.153512,
      "city": "Springdale",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72766,
      "latitude": 35.99403,
      "longitude": -94.219977,
      "city": "Springdale",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72768,
      "latitude": 36.384794,
      "longitude": -94.449942,
      "city": "Sulphur Springs",
      "state": "AR",
      "county": "Benton"
  },
  {
      "zipCode": 72769,
      "latitude": 36.027589,
      "longitude": -94.49393,
      "city": "Summers",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72770,
      "latitude": 36.14998,
      "longitude": -94.242884,
      "city": "Tontitown",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72773,
      "latitude": 36.033601,
      "longitude": -93.704255,
      "city": "Wesley",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72774,
      "latitude": 35.98025,
      "longitude": -94.198446,
      "city": "West Fork",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 72776,
      "latitude": 36.033601,
      "longitude": -93.704255,
      "city": "Witter",
      "state": "AR",
      "county": "Madison"
  },
  {
      "zipCode": 72801,
      "latitude": 35.29465,
      "longitude": -93.072885,
      "city": "Russellville",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72802,
      "latitude": 35.306683,
      "longitude": -93.071219,
      "city": "Russellville",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72811,
      "latitude": 35.314678,
      "longitude": -93.113308,
      "city": "Russellville",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72812,
      "latitude": 35.423102,
      "longitude": -93.054388,
      "city": "Russellville",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72820,
      "latitude": 35.43356,
      "longitude": -93.752642,
      "city": "Alix",
      "state": "AR",
      "county": "Franklin"
  },
  {
      "zipCode": 72821,
      "latitude": 35.434638,
      "longitude": -93.759811,
      "city": "Altus",
      "state": "AR",
      "county": "Franklin"
  },
  {
      "zipCode": 72823,
      "latitude": 35.325504,
      "longitude": -92.992285,
      "city": "Atkins",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72824,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Belleville",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72826,
      "latitude": 35.130561,
      "longitude": -93.749342,
      "city": "Blue Mountain",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72827,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Bluffton",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72828,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Briggsville",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72829,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Centerville",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72830,
      "latitude": 35.473886,
      "longitude": -93.516564,
      "city": "Clarksville",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72832,
      "latitude": 35.430098,
      "longitude": -93.672986,
      "city": "Coal Hill",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72833,
      "latitude": 35.052818,
      "longitude": -93.381315,
      "city": "Danville",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72834,
      "latitude": 35.235025,
      "longitude": -93.184774,
      "city": "Dardanelle",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72835,
      "latitude": 35.283987,
      "longitude": -93.3532,
      "city": "Delaware",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72837,
      "latitude": 35.498142,
      "longitude": -93.063411,
      "city": "Dover",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72838,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Gravelly",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72839,
      "latitude": 35.547714,
      "longitude": -93.437264,
      "city": "Hagarville",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72840,
      "latitude": 35.436024,
      "longitude": -93.64356,
      "city": "Hartman",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72841,
      "latitude": 34.882558,
      "longitude": -94.079724,
      "city": "Harvey",
      "state": "AR",
      "county": "Scott"
  },
  {
      "zipCode": 72842,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Havana",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72843,
      "latitude": 35.549856,
      "longitude": -92.961624,
      "city": "Hector",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72845,
      "latitude": 35.385081,
      "longitude": -93.335178,
      "city": "Knoxville",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72846,
      "latitude": 35.476393,
      "longitude": -93.369461,
      "city": "Lamar",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72847,
      "latitude": 35.368118,
      "longitude": -93.244365,
      "city": "London",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72851,
      "latitude": 35.264248,
      "longitude": -93.452038,
      "city": "New Blaine",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72852,
      "latitude": 35.547714,
      "longitude": -93.437264,
      "city": "Oark",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72853,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Ola",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72854,
      "latitude": 35.634994,
      "longitude": -93.445104,
      "city": "Ozone",
      "state": "AR",
      "county": "Johnson"
  },
  {
      "zipCode": 72855,
      "latitude": 35.274106,
      "longitude": -93.615999,
      "city": "Paris",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72856,
      "latitude": 35.617231,
      "longitude": -93.034092,
      "city": "Pelsor",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72857,
      "latitude": 34.992068,
      "longitude": -93.295552,
      "city": "Plainview",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72858,
      "latitude": 35.232993,
      "longitude": -93.026834,
      "city": "Pottsville",
      "state": "AR",
      "county": "Pope"
  },
  {
      "zipCode": 72860,
      "latitude": 35.030397,
      "longitude": -93.303203,
      "city": "Rover",
      "state": "AR",
      "county": "Yell"
  },
  {
      "zipCode": 72863,
      "latitude": 35.344745,
      "longitude": -93.53721,
      "city": "Scranton",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72865,
      "latitude": 35.315285,
      "longitude": -93.599117,
      "city": "Subiaco",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72901,
      "latitude": 35.231245,
      "longitude": -94.339412,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72902,
      "latitude": 35.38619,
      "longitude": -94.409114,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72903,
      "latitude": 35.354491,
      "longitude": -94.356112,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72904,
      "latitude": 35.410754,
      "longitude": -94.387568,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72905,
      "latitude": 35.191046,
      "longitude": -94.23816,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72906,
      "latitude": 35.191046,
      "longitude": -94.23816,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72908,
      "latitude": 35.321912,
      "longitude": -94.402779,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72913,
      "latitude": 35.191046,
      "longitude": -94.23816,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72914,
      "latitude": 35.191046,
      "longitude": -94.23816,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72916,
      "latitude": 35.299584,
      "longitude": -94.373858,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72917,
      "latitude": 35.191046,
      "longitude": -94.23816,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72918,
      "latitude": 35.191046,
      "longitude": -94.23816,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72919,
      "latitude": 35.191046,
      "longitude": -94.23816,
      "city": "Fort Smith",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72921,
      "latitude": 35.479157,
      "longitude": -94.193334,
      "city": "Alma",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72923,
      "latitude": 35.216137,
      "longitude": -94.354857,
      "city": "Barling",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72924,
      "latitude": 34.882558,
      "longitude": -94.079724,
      "city": "Bates",
      "state": "AR",
      "county": "Scott"
  },
  {
      "zipCode": 72926,
      "latitude": 34.882558,
      "longitude": -94.079724,
      "city": "Boles",
      "state": "AR",
      "county": "Scott"
  },
  {
      "zipCode": 72927,
      "latitude": 35.19356,
      "longitude": -93.930763,
      "city": "Booneville",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72928,
      "latitude": 35.311075,
      "longitude": -93.922527,
      "city": "Branch",
      "state": "AR",
      "county": "Franklin"
  },
  {
      "zipCode": 72930,
      "latitude": 35.486917,
      "longitude": -93.889389,
      "city": "Cecil",
      "state": "AR",
      "county": "Franklin"
  },
  {
      "zipCode": 72932,
      "latitude": 35.654281,
      "longitude": -94.369383,
      "city": "Cedarville",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72933,
      "latitude": 35.387924,
      "longitude": -93.95098,
      "city": "Charleston",
      "state": "AR",
      "county": "Franklin"
  },
  {
      "zipCode": 72934,
      "latitude": 35.67603,
      "longitude": -94.265465,
      "city": "Chester",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72935,
      "latitude": 35.496721,
      "longitude": -94.114259,
      "city": "Dyer",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72936,
      "latitude": 35.181209,
      "longitude": -94.206011,
      "city": "Greenwood",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72937,
      "latitude": 35.119452,
      "longitude": -94.329759,
      "city": "Hackett",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72938,
      "latitude": 35.060177,
      "longitude": -94.353779,
      "city": "Hartford",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72940,
      "latitude": 35.13786,
      "longitude": -94.24613,
      "city": "Huntington",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72941,
      "latitude": 35.241045,
      "longitude": -94.18783,
      "city": "Lavaca",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72943,
      "latitude": 35.182673,
      "longitude": -93.812592,
      "city": "Magazine",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72944,
      "latitude": 35.063448,
      "longitude": -94.243268,
      "city": "Mansfield",
      "state": "AR",
      "county": "Scott"
  },
  {
      "zipCode": 72945,
      "latitude": 35.096272,
      "longitude": -94.349565,
      "city": "Midland",
      "state": "AR",
      "county": "Sebastian"
  },
  {
      "zipCode": 72946,
      "latitude": 35.64644,
      "longitude": -94.149687,
      "city": "Mountainburg",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72947,
      "latitude": 35.565805,
      "longitude": -94.124776,
      "city": "Mulberry",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72948,
      "latitude": 35.680583,
      "longitude": -94.404185,
      "city": "Natural Dam",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72949,
      "latitude": 35.405649,
      "longitude": -93.909428,
      "city": "Ozark",
      "state": "AR",
      "county": "Franklin"
  },
  {
      "zipCode": 72950,
      "latitude": 34.882558,
      "longitude": -94.079724,
      "city": "Parks",
      "state": "AR",
      "county": "Scott"
  },
  {
      "zipCode": 72951,
      "latitude": 35.291693,
      "longitude": -93.889921,
      "city": "Ratcliff",
      "state": "AR",
      "county": "Logan"
  },
  {
      "zipCode": 72952,
      "latitude": 35.546818,
      "longitude": -94.291296,
      "city": "Rudy",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72955,
      "latitude": 35.56794,
      "longitude": -94.411402,
      "city": "Uniontown",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72956,
      "latitude": 35.496491,
      "longitude": -94.299086,
      "city": "Van Buren",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72957,
      "latitude": 35.494412,
      "longitude": -94.318648,
      "city": "Van Buren",
      "state": "AR",
      "county": "Crawford"
  },
  {
      "zipCode": 72958,
      "latitude": 34.908133,
      "longitude": -94.089381,
      "city": "Waldron",
      "state": "AR",
      "county": "Scott"
  },
  {
      "zipCode": 72959,
      "latitude": 35.862402,
      "longitude": -94.106004,
      "city": "Winslow",
      "state": "AR",
      "county": "Washington"
  },
  {
      "zipCode": 73001,
      "latitude": 35.203206,
      "longitude": -98.357423,
      "city": "Albert",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73002,
      "latitude": 34.914788,
      "longitude": -97.78122,
      "city": "Alex",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73003,
      "latitude": 35.674777,
      "longitude": -97.499681,
      "city": "Edmond",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73004,
      "latitude": 35.031247,
      "longitude": -97.881959,
      "city": "Amber",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73005,
      "latitude": 35.167108,
      "longitude": -98.325598,
      "city": "Anadarko",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73006,
      "latitude": 34.891016,
      "longitude": -98.361483,
      "city": "Apache",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73007,
      "latitude": 35.672506,
      "longitude": -97.309856,
      "city": "Arcadia",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73008,
      "latitude": 35.576674,
      "longitude": -97.498858,
      "city": "Bethany",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73009,
      "latitude": 35.308457,
      "longitude": -98.344265,
      "city": "Binger",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73010,
      "latitude": 35.094914,
      "longitude": -97.527291,
      "city": "Blanchard",
      "state": "OK",
      "county": "Mcclain"
  },
  {
      "zipCode": 73011,
      "latitude": 35.031247,
      "longitude": -97.881959,
      "city": "Bradley",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73012,
      "latitude": 34.48532,
      "longitude": -97.852223,
      "city": "Bray",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73013,
      "latitude": 35.555542,
      "longitude": -97.515011,
      "city": "Edmond",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73014,
      "latitude": 35.547575,
      "longitude": -98.151236,
      "city": "Calumet",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73015,
      "latitude": 35.105404,
      "longitude": -98.594067,
      "city": "Carnegie",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73016,
      "latitude": 35.818432,
      "longitude": -97.699679,
      "city": "Cashion",
      "state": "OK",
      "county": "Kingfisher"
  },
  {
      "zipCode": 73017,
      "latitude": 34.924452,
      "longitude": -98.163436,
      "city": "Cement",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73018,
      "latitude": 35.127804,
      "longitude": -97.937083,
      "city": "Chickasha",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73019,
      "latitude": 35.208566,
      "longitude": -97.44451,
      "city": "Norman",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73020,
      "latitude": 35.51876,
      "longitude": -97.249071,
      "city": "Choctaw",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73021,
      "latitude": 35.281428,
      "longitude": -98.991373,
      "city": "Colony",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73022,
      "latitude": 35.525192,
      "longitude": -97.992347,
      "city": "Concho",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73023,
      "latitude": 35.031247,
      "longitude": -97.881959,
      "city": "Chickasha",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73024,
      "latitude": 35.378409,
      "longitude": -98.781794,
      "city": "Corn",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73026,
      "latitude": 35.23429,
      "longitude": -97.291356,
      "city": "Norman",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73027,
      "latitude": 35.950214,
      "longitude": -97.291688,
      "city": "Coyle",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73028,
      "latitude": 35.977031,
      "longitude": -97.628098,
      "city": "Crescent",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73029,
      "latitude": 34.897258,
      "longitude": -98.204397,
      "city": "Cyril",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73030,
      "latitude": 34.501771,
      "longitude": -97.047346,
      "city": "Davis",
      "state": "OK",
      "county": "Murray"
  },
  {
      "zipCode": 73031,
      "latitude": 35.095739,
      "longitude": -97.301103,
      "city": "Dibble",
      "state": "OK",
      "county": "Mcclain"
  },
  {
      "zipCode": 73032,
      "latitude": 34.484702,
      "longitude": -97.089708,
      "city": "Dougherty",
      "state": "OK",
      "county": "Murray"
  },
  {
      "zipCode": 73033,
      "latitude": 35.303031,
      "longitude": -98.555431,
      "city": "Eakly",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73034,
      "latitude": 35.631005,
      "longitude": -97.463291,
      "city": "Edmond",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73036,
      "latitude": 35.508128,
      "longitude": -98.026708,
      "city": "El Reno",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73038,
      "latitude": 35.100632,
      "longitude": -98.44173,
      "city": "Fort Cobb",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73040,
      "latitude": 35.630034,
      "longitude": -98.32054,
      "city": "Geary",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73041,
      "latitude": 35.069516,
      "longitude": -98.875945,
      "city": "Gotebo",
      "state": "OK",
      "county": "Kiowa"
  },
  {
      "zipCode": 73042,
      "latitude": 35.042316,
      "longitude": -98.233336,
      "city": "Gracemont",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73043,
      "latitude": 35.858554,
      "longitude": -98.421836,
      "city": "Greenfield",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73044,
      "latitude": 35.877129,
      "longitude": -97.456312,
      "city": "Guthrie",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73045,
      "latitude": 35.53316,
      "longitude": -97.395314,
      "city": "Harrah",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73047,
      "latitude": 35.473233,
      "longitude": -98.36371,
      "city": "Hinton",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73048,
      "latitude": 35.327225,
      "longitude": -98.43108,
      "city": "Hydro",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73049,
      "latitude": 35.578623,
      "longitude": -97.276428,
      "city": "Jones",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73050,
      "latitude": 35.941924,
      "longitude": -97.25565,
      "city": "Langston",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73051,
      "latitude": 35.057992,
      "longitude": -97.26999,
      "city": "Lexington",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73052,
      "latitude": 34.836481,
      "longitude": -97.51668,
      "city": "Lindsay",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73053,
      "latitude": 35.203206,
      "longitude": -98.357423,
      "city": "Lookeba",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73054,
      "latitude": 35.632093,
      "longitude": -97.212206,
      "city": "Luther",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73055,
      "latitude": 34.640543,
      "longitude": -97.908875,
      "city": "Marlow",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73056,
      "latitude": 36.154158,
      "longitude": -97.626084,
      "city": "Marshall",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73057,
      "latitude": 34.816265,
      "longitude": -97.405333,
      "city": "Maysville",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73058,
      "latitude": 35.775549,
      "longitude": -97.22659,
      "city": "Meridian",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73059,
      "latitude": 35.31278,
      "longitude": -97.950402,
      "city": "Minco",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73061,
      "latitude": 36.288043,
      "longitude": -97.029796,
      "city": "Morrison",
      "state": "OK",
      "county": "Noble"
  },
  {
      "zipCode": 73062,
      "latitude": 35.087227,
      "longitude": -98.806836,
      "city": "Mountain View",
      "state": "OK",
      "county": "Kiowa"
  },
  {
      "zipCode": 73063,
      "latitude": 36.062875,
      "longitude": -97.404562,
      "city": "Mulhall",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73064,
      "latitude": 35.390376,
      "longitude": -97.759874,
      "city": "Mustang",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73065,
      "latitude": 35.219694,
      "longitude": -97.574274,
      "city": "Newcastle",
      "state": "OK",
      "county": "Mcclain"
  },
  {
      "zipCode": 73066,
      "latitude": 35.469296,
      "longitude": -97.267769,
      "city": "Nicoma Park",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73067,
      "latitude": 34.932908,
      "longitude": -97.939225,
      "city": "Ninnekah",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73068,
      "latitude": 35.152314,
      "longitude": -97.276226,
      "city": "Noble",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73069,
      "latitude": 35.232966,
      "longitude": -97.438246,
      "city": "Norman",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73070,
      "latitude": 35.187611,
      "longitude": -97.397509,
      "city": "Norman",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73071,
      "latitude": 35.254049,
      "longitude": -97.300313,
      "city": "Norman",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73072,
      "latitude": 35.218728,
      "longitude": -97.363181,
      "city": "Norman",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73073,
      "latitude": 35.944569,
      "longitude": -97.408181,
      "city": "Orlando",
      "state": "OK",
      "county": "Logan"
  },
  {
      "zipCode": 73074,
      "latitude": 34.827711,
      "longitude": -97.262188,
      "city": "Paoli",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73075,
      "latitude": 34.759696,
      "longitude": -97.308819,
      "city": "Pauls Valley",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73077,
      "latitude": 36.318709,
      "longitude": -97.285561,
      "city": "Perry",
      "state": "OK",
      "county": "Noble"
  },
  {
      "zipCode": 73078,
      "latitude": 35.653019,
      "longitude": -97.753948,
      "city": "Piedmont",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73079,
      "latitude": 35.031247,
      "longitude": -97.881959,
      "city": "Pocasset",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73080,
      "latitude": 35.00181,
      "longitude": -97.379902,
      "city": "Purcell",
      "state": "OK",
      "county": "Mcclain"
  },
  {
      "zipCode": 73082,
      "latitude": 34.775535,
      "longitude": -97.954459,
      "city": "Rush Springs",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73083,
      "latitude": 35.666481,
      "longitude": -97.465436,
      "city": "Edmond",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73084,
      "latitude": 35.540113,
      "longitude": -97.387602,
      "city": "Spencer",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73085,
      "latitude": 35.489527,
      "longitude": -97.750009,
      "city": "Yukon",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73086,
      "latitude": 34.520837,
      "longitude": -96.996244,
      "city": "Sulphur",
      "state": "OK",
      "county": "Murray"
  },
  {
      "zipCode": 73089,
      "latitude": 35.058651,
      "longitude": -97.81648,
      "city": "Tuttle",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73090,
      "latitude": 35.392567,
      "longitude": -97.944388,
      "city": "Union City",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73092,
      "latitude": 35.081997,
      "longitude": -98.087971,
      "city": "Verden",
      "state": "OK",
      "county": "Grady"
  },
  {
      "zipCode": 73093,
      "latitude": 35.105058,
      "longitude": -97.468258,
      "city": "Washington",
      "state": "OK",
      "county": "Mcclain"
  },
  {
      "zipCode": 73094,
      "latitude": 35.203206,
      "longitude": -98.357423,
      "city": "Washita",
      "state": "OK",
      "county": "Caddo"
  },
  {
      "zipCode": 73095,
      "latitude": 34.917497,
      "longitude": -97.315974,
      "city": "Wayne",
      "state": "OK",
      "county": "Mcclain"
  },
  {
      "zipCode": 73096,
      "latitude": 35.538228,
      "longitude": -98.703175,
      "city": "Weatherford",
      "state": "OK",
      "county": "Custer"
  },
  {
      "zipCode": 73097,
      "latitude": 35.400759,
      "longitude": -97.644368,
      "city": "Wheatland",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73098,
      "latitude": 34.645418,
      "longitude": -97.162257,
      "city": "Wynnewood",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73099,
      "latitude": 35.521448,
      "longitude": -97.770532,
      "city": "Yukon",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73101,
      "latitude": 35.491608,
      "longitude": -97.562817,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73102,
      "latitude": 35.475209,
      "longitude": -97.518916,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73103,
      "latitude": 35.549197,
      "longitude": -97.486407,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73104,
      "latitude": 35.44541,
      "longitude": -97.506415,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73105,
      "latitude": 35.518576,
      "longitude": -97.505416,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73106,
      "latitude": 35.482014,
      "longitude": -97.536666,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73107,
      "latitude": 35.481908,
      "longitude": -97.542666,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73108,
      "latitude": 35.447559,
      "longitude": -97.566497,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73109,
      "latitude": 35.435221,
      "longitude": -97.525265,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73110,
      "latitude": 35.460511,
      "longitude": -97.397441,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73111,
      "latitude": 35.526182,
      "longitude": -97.502631,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73112,
      "latitude": 35.511007,
      "longitude": -97.578865,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73113,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73114,
      "latitude": 35.544882,
      "longitude": -97.499605,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73115,
      "latitude": 35.444931,
      "longitude": -97.446013,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73116,
      "latitude": 35.551571,
      "longitude": -97.556318,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73117,
      "latitude": 35.485021,
      "longitude": -97.463314,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73118,
      "latitude": 35.518458,
      "longitude": -97.523316,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73119,
      "latitude": 35.462144,
      "longitude": -97.583267,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73120,
      "latitude": 35.587155,
      "longitude": -97.568418,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73121,
      "latitude": 35.532257,
      "longitude": -97.451938,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73122,
      "latitude": 35.518768,
      "longitude": -97.636119,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73123,
      "latitude": 35.535989,
      "longitude": -97.202331,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73124,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73125,
      "latitude": 35.465418,
      "longitude": -97.521816,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73126,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73127,
      "latitude": 35.475459,
      "longitude": -97.600817,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73128,
      "latitude": 35.427335,
      "longitude": -97.636284,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73129,
      "latitude": 35.428579,
      "longitude": -97.486065,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73130,
      "latitude": 35.459312,
      "longitude": -97.298733,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73131,
      "latitude": 35.566004,
      "longitude": -97.459414,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73132,
      "latitude": 35.558349,
      "longitude": -97.637519,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73134,
      "latitude": 35.624134,
      "longitude": -97.567199,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73135,
      "latitude": 35.401512,
      "longitude": -97.416562,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73136,
      "latitude": 35.61534,
      "longitude": -97.326151,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73137,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73139,
      "latitude": 35.370262,
      "longitude": -97.525464,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73140,
      "latitude": 35.518509,
      "longitude": -97.427464,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73141,
      "latitude": 35.583757,
      "longitude": -97.397064,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73142,
      "latitude": 35.62034,
      "longitude": -97.612588,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73143,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73144,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73145,
      "latitude": 35.430406,
      "longitude": -97.396184,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73146,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73147,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73148,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73149,
      "latitude": 35.391712,
      "longitude": -97.477463,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73150,
      "latitude": 35.406963,
      "longitude": -97.320363,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73151,
      "latitude": 35.574858,
      "longitude": -97.371621,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73152,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73153,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73154,
      "latitude": 35.523758,
      "longitude": -97.525467,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73155,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73156,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73157,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73159,
      "latitude": 35.394212,
      "longitude": -97.566716,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73160,
      "latitude": 35.326895,
      "longitude": -97.406805,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73162,
      "latitude": 35.565419,
      "longitude": -97.575218,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73163,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73164,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73165,
      "latitude": 35.326665,
      "longitude": -97.353359,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73167,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73169,
      "latitude": 35.392473,
      "longitude": -97.636161,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73170,
      "latitude": 35.2972,
      "longitude": -97.501459,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73172,
      "latitude": 35.517456,
      "longitude": -97.621769,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73173,
      "latitude": 35.343003,
      "longitude": -97.625383,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Cleveland"
  },
  {
      "zipCode": 73177,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73178,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73179,
      "latitude": 35.424819,
      "longitude": -97.6363,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73180,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73184,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73185,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73189,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73190,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73193,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73194,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73195,
      "latitude": 35.400731,
      "longitude": -97.594754,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73196,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73197,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73198,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73199,
      "latitude": 35.551409,
      "longitude": -97.407537,
      "city": "Oklahoma City",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 73301,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 73344,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 73401,
      "latitude": 34.156627,
      "longitude": -97.179214,
      "city": "Ardmore",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73402,
      "latitude": 34.288884,
      "longitude": -97.248074,
      "city": "Ardmore",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73403,
      "latitude": 34.288884,
      "longitude": -97.248074,
      "city": "Ardmore",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73425,
      "latitude": 34.48532,
      "longitude": -97.852223,
      "city": "Countyline",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73430,
      "latitude": 33.925982,
      "longitude": -97.310324,
      "city": "Burneyville",
      "state": "OK",
      "county": "Love"
  },
  {
      "zipCode": 73432,
      "latitude": 34.310191,
      "longitude": -96.670349,
      "city": "Coleman",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 73433,
      "latitude": 34.719832,
      "longitude": -97.34077,
      "city": "Elmore City",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73434,
      "latitude": 34.48532,
      "longitude": -97.852223,
      "city": "Foster",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73435,
      "latitude": 34.288884,
      "longitude": -97.248074,
      "city": "Fox",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73436,
      "latitude": 34.293948,
      "longitude": -97.033905,
      "city": "Gene Autry",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73437,
      "latitude": 34.288884,
      "longitude": -97.248074,
      "city": "Graham",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73438,
      "latitude": 34.217164,
      "longitude": -97.402923,
      "city": "Healdton",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73439,
      "latitude": 33.974518,
      "longitude": -96.698204,
      "city": "Kingston",
      "state": "OK",
      "county": "Marshall"
  },
  {
      "zipCode": 73440,
      "latitude": 33.998567,
      "longitude": -96.758835,
      "city": "Lebanon",
      "state": "OK",
      "county": "Marshall"
  },
  {
      "zipCode": 73441,
      "latitude": 33.894135,
      "longitude": -97.247886,
      "city": "Leon",
      "state": "OK",
      "county": "Love"
  },
  {
      "zipCode": 73442,
      "latitude": 34.48532,
      "longitude": -97.852223,
      "city": "Loco",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73443,
      "latitude": 34.141332,
      "longitude": -97.321977,
      "city": "Lone Grove",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73444,
      "latitude": 34.680871,
      "longitude": -97.300429,
      "city": "Hennepin",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73446,
      "latitude": 34.010202,
      "longitude": -96.754984,
      "city": "Madill",
      "state": "OK",
      "county": "Marshall"
  },
  {
      "zipCode": 73447,
      "latitude": 34.183418,
      "longitude": -96.878673,
      "city": "Mannsville",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 73448,
      "latitude": 33.935268,
      "longitude": -97.111939,
      "city": "Marietta",
      "state": "OK",
      "county": "Love"
  },
  {
      "zipCode": 73449,
      "latitude": 33.971979,
      "longitude": -96.457371,
      "city": "Mead",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 73450,
      "latitude": 34.310191,
      "longitude": -96.670349,
      "city": "Milburn",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 73453,
      "latitude": 33.894135,
      "longitude": -97.247886,
      "city": "Overbrook",
      "state": "OK",
      "county": "Love"
  },
  {
      "zipCode": 73455,
      "latitude": 34.244023,
      "longitude": -96.752906,
      "city": "Ravia",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 73456,
      "latitude": 34.200318,
      "longitude": -97.810259,
      "city": "Ringling",
      "state": "OK",
      "county": "Jefferson"
  },
  {
      "zipCode": 73458,
      "latitude": 34.304534,
      "longitude": -97.134372,
      "city": "Springer",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73459,
      "latitude": 33.894135,
      "longitude": -97.247886,
      "city": "Thackerville",
      "state": "OK",
      "county": "Love"
  },
  {
      "zipCode": 73460,
      "latitude": 34.275856,
      "longitude": -96.655188,
      "city": "Tishomingo",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 73461,
      "latitude": 34.371445,
      "longitude": -96.429558,
      "city": "Wapanucka",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 73463,
      "latitude": 34.169282,
      "longitude": -97.354691,
      "city": "Wilson",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73476,
      "latitude": 34.680871,
      "longitude": -97.300429,
      "city": "Pernell",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 73481,
      "latitude": 34.288884,
      "longitude": -97.248074,
      "city": "Ratliff City",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73487,
      "latitude": 34.288884,
      "longitude": -97.248074,
      "city": "Tatums",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73488,
      "latitude": 34.288884,
      "longitude": -97.248074,
      "city": "Tussy",
      "state": "OK",
      "county": "Carter"
  },
  {
      "zipCode": 73491,
      "latitude": 34.456505,
      "longitude": -97.668866,
      "city": "Velma",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73501,
      "latitude": 34.635378,
      "longitude": -98.448452,
      "city": "Lawton",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73502,
      "latitude": 34.630879,
      "longitude": -98.457646,
      "city": "Lawton",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73503,
      "latitude": 34.663978,
      "longitude": -98.437482,
      "city": "Fort Sill",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73505,
      "latitude": 34.678734,
      "longitude": -98.436365,
      "city": "Lawton",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73506,
      "latitude": 34.630879,
      "longitude": -98.457646,
      "city": "Lawton",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73507,
      "latitude": 34.560957,
      "longitude": -98.387482,
      "city": "Lawton",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73520,
      "latitude": 34.069661,
      "longitude": -97.838026,
      "city": "Addington",
      "state": "OK",
      "county": "Jefferson"
  },
  {
      "zipCode": 73521,
      "latitude": 34.663039,
      "longitude": -99.280343,
      "city": "Altus",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73522,
      "latitude": 34.723795,
      "longitude": -99.383928,
      "city": "Altus",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73523,
      "latitude": 34.598197,
      "longitude": -99.440839,
      "city": "Altus Afb",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73526,
      "latitude": 34.783568,
      "longitude": -99.331758,
      "city": "Blair",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73527,
      "latitude": 34.545031,
      "longitude": -98.508297,
      "city": "Cache",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73528,
      "latitude": 34.424316,
      "longitude": -98.654352,
      "city": "Chattanooga",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73529,
      "latitude": 34.393689,
      "longitude": -97.970651,
      "city": "Comanche",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73530,
      "latitude": 34.242236,
      "longitude": -99.076314,
      "city": "Davidson",
      "state": "OK",
      "county": "Tillman"
  },
  {
      "zipCode": 73531,
      "latitude": 34.284738,
      "longitude": -98.40044,
      "city": "Devol",
      "state": "OK",
      "county": "Cotton"
  },
  {
      "zipCode": 73532,
      "latitude": 34.663508,
      "longitude": -99.569018,
      "city": "Duke",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73533,
      "latitude": 34.547749,
      "longitude": -97.933279,
      "city": "Duncan",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73534,
      "latitude": 34.518944,
      "longitude": -97.973331,
      "city": "Duncan",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73536,
      "latitude": 34.48532,
      "longitude": -97.852223,
      "city": "Duncan",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73537,
      "latitude": 34.472021,
      "longitude": -99.647324,
      "city": "Eldorado",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73538,
      "latitude": 34.609391,
      "longitude": -98.406833,
      "city": "Elgin",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73539,
      "latitude": 34.481717,
      "longitude": -99.347951,
      "city": "Elmer",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73540,
      "latitude": 34.630879,
      "longitude": -98.457646,
      "city": "Faxon",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73541,
      "latitude": 34.826905,
      "longitude": -98.228076,
      "city": "Fletcher",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73542,
      "latitude": 34.386281,
      "longitude": -99.001688,
      "city": "Frederick",
      "state": "OK",
      "county": "Tillman"
  },
  {
      "zipCode": 73543,
      "latitude": 34.482402,
      "longitude": -98.381879,
      "city": "Geronimo",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73544,
      "latitude": 34.768632,
      "longitude": -99.833341,
      "city": "Gould",
      "state": "OK",
      "county": "Harmon"
  },
  {
      "zipCode": 73546,
      "latitude": 34.362887,
      "longitude": -98.911705,
      "city": "Grandfield",
      "state": "OK",
      "county": "Tillman"
  },
  {
      "zipCode": 73547,
      "latitude": 34.950884,
      "longitude": -99.394148,
      "city": "Granite",
      "state": "OK",
      "county": "Greer"
  },
  {
      "zipCode": 73548,
      "latitude": 34.069661,
      "longitude": -97.838026,
      "city": "Hastings",
      "state": "OK",
      "county": "Jefferson"
  },
  {
      "zipCode": 73549,
      "latitude": 34.626509,
      "longitude": -99.139047,
      "city": "Headrick",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73550,
      "latitude": 34.694028,
      "longitude": -99.912395,
      "city": "Hollis",
      "state": "OK",
      "county": "Harmon"
  },
  {
      "zipCode": 73551,
      "latitude": 34.381102,
      "longitude": -98.923769,
      "city": "Hollister",
      "state": "OK",
      "county": "Tillman"
  },
  {
      "zipCode": 73552,
      "latitude": 34.618969,
      "longitude": -98.75235,
      "city": "Indiahoma",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73553,
      "latitude": 34.381102,
      "longitude": -98.923769,
      "city": "Loveland",
      "state": "OK",
      "county": "Tillman"
  },
  {
      "zipCode": 73554,
      "latitude": 34.912592,
      "longitude": -99.460577,
      "city": "Mangum",
      "state": "OK",
      "county": "Greer"
  },
  {
      "zipCode": 73555,
      "latitude": 34.507462,
      "longitude": -98.979191,
      "city": "Manitou",
      "state": "OK",
      "county": "Tillman"
  },
  {
      "zipCode": 73556,
      "latitude": 34.719237,
      "longitude": -99.4154,
      "city": "Martha",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73557,
      "latitude": 34.630879,
      "longitude": -98.457646,
      "city": "Medicine Park",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73558,
      "latitude": 34.630879,
      "longitude": -98.457646,
      "city": "Meers",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73559,
      "latitude": 34.69694,
      "longitude": -98.94888,
      "city": "Mountain Park",
      "state": "OK",
      "county": "Kiowa"
  },
  {
      "zipCode": 73560,
      "latitude": 34.54794,
      "longitude": -99.421959,
      "city": "Olustee",
      "state": "OK",
      "county": "Jackson"
  },
  {
      "zipCode": 73561,
      "latitude": 34.069661,
      "longitude": -97.838026,
      "city": "Oscar",
      "state": "OK",
      "county": "Jefferson"
  },
  {
      "zipCode": 73562,
      "latitude": 34.284738,
      "longitude": -98.40044,
      "city": "Randlett",
      "state": "OK",
      "county": "Cotton"
  },
  {
      "zipCode": 73564,
      "latitude": 34.850598,
      "longitude": -99.021205,
      "city": "Roosevelt",
      "state": "OK",
      "county": "Kiowa"
  },
  {
      "zipCode": 73565,
      "latitude": 34.021911,
      "longitude": -97.954867,
      "city": "Ryan",
      "state": "OK",
      "county": "Jefferson"
  },
  {
      "zipCode": 73566,
      "latitude": 34.673689,
      "longitude": -98.95239,
      "city": "Snyder",
      "state": "OK",
      "county": "Kiowa"
  },
  {
      "zipCode": 73567,
      "latitude": 34.749594,
      "longitude": -98.167941,
      "city": "Sterling",
      "state": "OK",
      "county": "Comanche"
  },
  {
      "zipCode": 73568,
      "latitude": 34.271467,
      "longitude": -98.260334,
      "city": "Temple",
      "state": "OK",
      "county": "Cotton"
  },
  {
      "zipCode": 73569,
      "latitude": 34.069661,
      "longitude": -97.838026,
      "city": "Terral",
      "state": "OK",
      "county": "Jefferson"
  },
  {
      "zipCode": 73570,
      "latitude": 34.488104,
      "longitude": -99.158545,
      "city": "Tipton",
      "state": "OK",
      "county": "Tillman"
  },
  {
      "zipCode": 73571,
      "latitude": 34.768632,
      "longitude": -99.833341,
      "city": "Vinson",
      "state": "OK",
      "county": "Harmon"
  },
  {
      "zipCode": 73572,
      "latitude": 34.321541,
      "longitude": -98.319264,
      "city": "Walters",
      "state": "OK",
      "county": "Cotton"
  },
  {
      "zipCode": 73573,
      "latitude": 34.168309,
      "longitude": -98.000691,
      "city": "Waurika",
      "state": "OK",
      "county": "Jefferson"
  },
  {
      "zipCode": 73575,
      "latitude": 34.48532,
      "longitude": -97.852223,
      "city": "Duncan",
      "state": "OK",
      "county": "Stephens"
  },
  {
      "zipCode": 73601,
      "latitude": 35.60603,
      "longitude": -98.902136,
      "city": "Clinton",
      "state": "OK",
      "county": "Custer"
  },
  {
      "zipCode": 73620,
      "latitude": 35.566643,
      "longitude": -98.960209,
      "city": "Arapaho",
      "state": "OK",
      "county": "Custer"
  },
  {
      "zipCode": 73622,
      "latitude": 35.387478,
      "longitude": -98.989778,
      "city": "Bessie",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73624,
      "latitude": 35.349154,
      "longitude": -99.187984,
      "city": "Burns Flat",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73625,
      "latitude": 35.63862,
      "longitude": -99.00005,
      "city": "Butler",
      "state": "OK",
      "county": "Custer"
  },
  {
      "zipCode": 73626,
      "latitude": 35.418882,
      "longitude": -99.280304,
      "city": "Canute",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73627,
      "latitude": 35.26946,
      "longitude": -99.680146,
      "city": "Carter",
      "state": "OK",
      "county": "Beckham"
  },
  {
      "zipCode": 73628,
      "latitude": 35.608071,
      "longitude": -99.672542,
      "city": "Cheyenne",
      "state": "OK",
      "county": "Roger Mills"
  },
  {
      "zipCode": 73632,
      "latitude": 35.232961,
      "longitude": -99.05841,
      "city": "Cordell",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73638,
      "latitude": 35.717616,
      "longitude": -99.682177,
      "city": "Crawford",
      "state": "OK",
      "county": "Roger Mills"
  },
  {
      "zipCode": 73639,
      "latitude": 35.62389,
      "longitude": -98.912212,
      "city": "Custer City",
      "state": "OK",
      "county": "Custer"
  },
  {
      "zipCode": 73641,
      "latitude": 35.224845,
      "longitude": -99.150331,
      "city": "Dill City",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73642,
      "latitude": 35.717616,
      "longitude": -99.682177,
      "city": "Durham",
      "state": "OK",
      "county": "Roger Mills"
  },
  {
      "zipCode": 73644,
      "latitude": 35.320621,
      "longitude": -99.624253,
      "city": "Elk City",
      "state": "OK",
      "county": "Beckham"
  },
  {
      "zipCode": 73645,
      "latitude": 35.203883,
      "longitude": -99.870825,
      "city": "Erick",
      "state": "OK",
      "county": "Beckham"
  },
  {
      "zipCode": 73646,
      "latitude": 35.988409,
      "longitude": -99.007165,
      "city": "Fay",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73647,
      "latitude": 35.281428,
      "longitude": -98.991373,
      "city": "Foss",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73648,
      "latitude": 35.26946,
      "longitude": -99.680146,
      "city": "Elk City",
      "state": "OK",
      "county": "Beckham"
  },
  {
      "zipCode": 73650,
      "latitude": 35.630294,
      "longitude": -99.381869,
      "city": "Hammon",
      "state": "OK",
      "county": "Roger Mills"
  },
  {
      "zipCode": 73651,
      "latitude": 35.012688,
      "longitude": -99.136501,
      "city": "Hobart",
      "state": "OK",
      "county": "Kiowa"
  },
  {
      "zipCode": 73654,
      "latitude": 35.868275,
      "longitude": -99.344373,
      "city": "Leedey",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73655,
      "latitude": 34.989694,
      "longitude": -99.24543,
      "city": "Lone Wolf",
      "state": "OK",
      "county": "Kiowa"
  },
  {
      "zipCode": 73658,
      "latitude": 35.93284,
      "longitude": -98.706518,
      "city": "Oakwood",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73659,
      "latitude": 35.854883,
      "longitude": -98.966584,
      "city": "Putnam",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73660,
      "latitude": 35.633338,
      "longitude": -99.819077,
      "city": "Reydon",
      "state": "OK",
      "county": "Roger Mills"
  },
  {
      "zipCode": 73661,
      "latitude": 35.155439,
      "longitude": -99.059758,
      "city": "Rocky",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73662,
      "latitude": 35.237521,
      "longitude": -99.74838,
      "city": "Sayre",
      "state": "OK",
      "county": "Beckham"
  },
  {
      "zipCode": 73663,
      "latitude": 36.100391,
      "longitude": -98.94537,
      "city": "Seiling",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73664,
      "latitude": 35.283787,
      "longitude": -99.170439,
      "city": "Sentinel",
      "state": "OK",
      "county": "Washita"
  },
  {
      "zipCode": 73666,
      "latitude": 35.717616,
      "longitude": -99.682177,
      "city": "Sweetwater",
      "state": "OK",
      "county": "Roger Mills"
  },
  {
      "zipCode": 73667,
      "latitude": 36.040765,
      "longitude": -98.961606,
      "city": "Taloga",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73668,
      "latitude": 35.26946,
      "longitude": -99.680146,
      "city": "Texola",
      "state": "OK",
      "county": "Beckham"
  },
  {
      "zipCode": 73669,
      "latitude": 35.709894,
      "longitude": -98.81099,
      "city": "Thomas",
      "state": "OK",
      "county": "Custer"
  },
  {
      "zipCode": 73673,
      "latitude": 34.920817,
      "longitude": -99.567494,
      "city": "Willow",
      "state": "OK",
      "county": "Greer"
  },
  {
      "zipCode": 73701,
      "latitude": 36.402681,
      "longitude": -97.843091,
      "city": "Enid",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73702,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Enid",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73703,
      "latitude": 36.398065,
      "longitude": -97.943813,
      "city": "Enid",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73705,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Enid",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73706,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Enid",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73716,
      "latitude": 36.51176,
      "longitude": -98.449895,
      "city": "Aline",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73717,
      "latitude": 36.826594,
      "longitude": -98.640045,
      "city": "Alva",
      "state": "OK",
      "county": "Woods"
  },
  {
      "zipCode": 73718,
      "latitude": 36.246582,
      "longitude": -98.185445,
      "city": "Ames",
      "state": "OK",
      "county": "Major"
  },
  {
      "zipCode": 73719,
      "latitude": 36.925116,
      "longitude": -98.29243,
      "city": "Amorita",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73720,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Bison",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73722,
      "latitude": 36.730959,
      "longitude": -98.324548,
      "city": "Burlington",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73724,
      "latitude": 36.09281,
      "longitude": -98.577618,
      "city": "Canton",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73726,
      "latitude": 36.578946,
      "longitude": -98.45608,
      "city": "Carmen",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73727,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Carrier",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73728,
      "latitude": 36.665351,
      "longitude": -98.366783,
      "city": "Cherokee",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73729,
      "latitude": 36.4053,
      "longitude": -98.453278,
      "city": "Cleo Springs",
      "state": "OK",
      "county": "Major"
  },
  {
      "zipCode": 73730,
      "latitude": 36.30749,
      "longitude": -97.58643,
      "city": "Covington",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73731,
      "latitude": 36.662062,
      "longitude": -98.562972,
      "city": "Dacoma",
      "state": "OK",
      "county": "Woods"
  },
  {
      "zipCode": 73733,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Douglas",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73734,
      "latitude": 35.982319,
      "longitude": -97.912342,
      "city": "Dover",
      "state": "OK",
      "county": "Kingfisher"
  },
  {
      "zipCode": 73735,
      "latitude": 36.307449,
      "longitude": -98.04182,
      "city": "Drummond",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73736,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Fairmont",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73737,
      "latitude": 36.323942,
      "longitude": -98.337597,
      "city": "Fairview",
      "state": "OK",
      "county": "Major"
  },
  {
      "zipCode": 73738,
      "latitude": 36.436334,
      "longitude": -97.580931,
      "city": "Garber",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73739,
      "latitude": 36.531985,
      "longitude": -98.151279,
      "city": "Goltry",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73741,
      "latitude": 36.546639,
      "longitude": -98.271091,
      "city": "Helena",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73742,
      "latitude": 35.924916,
      "longitude": -97.938778,
      "city": "Hennessey",
      "state": "OK",
      "county": "Kingfisher"
  },
  {
      "zipCode": 73743,
      "latitude": 36.379072,
      "longitude": -97.782595,
      "city": "Hillsdale",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73744,
      "latitude": 35.970011,
      "longitude": -98.350529,
      "city": "Hitchcock",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73746,
      "latitude": 36.695512,
      "longitude": -98.994721,
      "city": "Hopeton",
      "state": "OK",
      "county": "Woods"
  },
  {
      "zipCode": 73747,
      "latitude": 36.334047,
      "longitude": -98.532338,
      "city": "Isabella",
      "state": "OK",
      "county": "Major"
  },
  {
      "zipCode": 73749,
      "latitude": 36.666492,
      "longitude": -98.181223,
      "city": "Jet",
      "state": "OK",
      "county": "Alfalfa"
  },
  {
      "zipCode": 73750,
      "latitude": 35.897508,
      "longitude": -97.92563,
      "city": "Kingfisher",
      "state": "OK",
      "county": "Kingfisher"
  },
  {
      "zipCode": 73753,
      "latitude": 36.528516,
      "longitude": -97.849762,
      "city": "Kremlin",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73754,
      "latitude": 36.388985,
      "longitude": -98.091121,
      "city": "Lahoma",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73755,
      "latitude": 36.133739,
      "longitude": -98.551349,
      "city": "Longdale",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73756,
      "latitude": 35.945046,
      "longitude": -97.942225,
      "city": "Loyal",
      "state": "OK",
      "county": "Kingfisher"
  },
  {
      "zipCode": 73757,
      "latitude": 36.272842,
      "longitude": -97.454971,
      "city": "Lucien",
      "state": "OK",
      "county": "Noble"
  },
  {
      "zipCode": 73758,
      "latitude": 36.79613,
      "longitude": -97.787099,
      "city": "Manchester",
      "state": "OK",
      "county": "Grant"
  },
  {
      "zipCode": 73759,
      "latitude": 36.7394,
      "longitude": -97.889923,
      "city": "Medford",
      "state": "OK",
      "county": "Grant"
  },
  {
      "zipCode": 73760,
      "latitude": 36.384201,
      "longitude": -98.209947,
      "city": "Meno",
      "state": "OK",
      "county": "Major"
  },
  {
      "zipCode": 73761,
      "latitude": 36.664875,
      "longitude": -98.0517,
      "city": "Nash",
      "state": "OK",
      "county": "Grant"
  },
  {
      "zipCode": 73762,
      "latitude": 35.732016,
      "longitude": -97.97746,
      "city": "Okarche",
      "state": "OK",
      "county": "Canadian"
  },
  {
      "zipCode": 73763,
      "latitude": 36.122914,
      "longitude": -98.42913,
      "city": "Okeene",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73764,
      "latitude": 35.945046,
      "longitude": -97.942225,
      "city": "Omega",
      "state": "OK",
      "county": "Kingfisher"
  },
  {
      "zipCode": 73766,
      "latitude": 36.661992,
      "longitude": -97.841842,
      "city": "Pond Creek",
      "state": "OK",
      "county": "Grant"
  },
  {
      "zipCode": 73768,
      "latitude": 36.379012,
      "longitude": -98.243516,
      "city": "Ringwood",
      "state": "OK",
      "county": "Major"
  },
  {
      "zipCode": 73770,
      "latitude": 36.058133,
      "longitude": -98.588383,
      "city": "Southard",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73771,
      "latitude": 36.882468,
      "longitude": -97.923858,
      "city": "Wakita",
      "state": "OK",
      "county": "Grant"
  },
  {
      "zipCode": 73772,
      "latitude": 35.980824,
      "longitude": -98.37882,
      "city": "Watonga",
      "state": "OK",
      "county": "Blaine"
  },
  {
      "zipCode": 73773,
      "latitude": 36.241968,
      "longitude": -97.911828,
      "city": "Waukomis",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 73801,
      "latitude": 36.438017,
      "longitude": -99.442977,
      "city": "Woodward",
      "state": "OK",
      "county": "Woodward"
  },
  {
      "zipCode": 73802,
      "latitude": 36.431095,
      "longitude": -99.534157,
      "city": "Woodward",
      "state": "OK",
      "county": "Woodward"
  },
  {
      "zipCode": 73832,
      "latitude": 36.204191,
      "longitude": -99.818311,
      "city": "Arnett",
      "state": "OK",
      "county": "Ellis"
  },
  {
      "zipCode": 73834,
      "latitude": 36.791802,
      "longitude": -99.753511,
      "city": "Buffalo",
      "state": "OK",
      "county": "Harper"
  },
  {
      "zipCode": 73835,
      "latitude": 36.019939,
      "longitude": -99.287978,
      "city": "Camargo",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73838,
      "latitude": 36.334047,
      "longitude": -98.532338,
      "city": "Chester",
      "state": "OK",
      "county": "Major"
  },
  {
      "zipCode": 73840,
      "latitude": 36.373497,
      "longitude": -99.622067,
      "city": "Fargo",
      "state": "OK",
      "county": "Ellis"
  },
  {
      "zipCode": 73841,
      "latitude": 36.572183,
      "longitude": -99.573764,
      "city": "Fort Supply",
      "state": "OK",
      "county": "Woodward"
  },
  {
      "zipCode": 73842,
      "latitude": 36.768905,
      "longitude": -99.112676,
      "city": "Freedom",
      "state": "OK",
      "county": "Woods"
  },
  {
      "zipCode": 73843,
      "latitude": 36.255493,
      "longitude": -99.753814,
      "city": "Gage",
      "state": "OK",
      "county": "Ellis"
  },
  {
      "zipCode": 73844,
      "latitude": 36.750783,
      "longitude": -100.478393,
      "city": "Gate",
      "state": "OK",
      "county": "Beaver"
  },
  {
      "zipCode": 73847,
      "latitude": 36.750783,
      "longitude": -100.478393,
      "city": "Knowles",
      "state": "OK",
      "county": "Beaver"
  },
  {
      "zipCode": 73848,
      "latitude": 36.764793,
      "longitude": -99.765785,
      "city": "Laverne",
      "state": "OK",
      "county": "Harper"
  },
  {
      "zipCode": 73851,
      "latitude": 36.797229,
      "longitude": -99.64949,
      "city": "May",
      "state": "OK",
      "county": "Harper"
  },
  {
      "zipCode": 73852,
      "latitude": 36.460397,
      "longitude": -99.202631,
      "city": "Mooreland",
      "state": "OK",
      "county": "Woodward"
  },
  {
      "zipCode": 73853,
      "latitude": 36.490567,
      "longitude": -99.280828,
      "city": "Mutual",
      "state": "OK",
      "county": "Woodward"
  },
  {
      "zipCode": 73855,
      "latitude": 36.797229,
      "longitude": -99.64949,
      "city": "Rosston",
      "state": "OK",
      "county": "Harper"
  },
  {
      "zipCode": 73857,
      "latitude": 36.27581,
      "longitude": -99.33784,
      "city": "Sharon",
      "state": "OK",
      "county": "Woodward"
  },
  {
      "zipCode": 73858,
      "latitude": 36.318631,
      "longitude": -99.758319,
      "city": "Shattuck",
      "state": "OK",
      "county": "Ellis"
  },
  {
      "zipCode": 73859,
      "latitude": 36.149508,
      "longitude": -99.301912,
      "city": "Vici",
      "state": "OK",
      "county": "Dewey"
  },
  {
      "zipCode": 73860,
      "latitude": 36.671897,
      "longitude": -98.974629,
      "city": "Waynoka",
      "state": "OK",
      "county": "Woods"
  },
  {
      "zipCode": 73901,
      "latitude": 36.755,
      "longitude": -101.077969,
      "city": "Adams",
      "state": "OK",
      "county": "Texas"
  },
  {
      "zipCode": 73931,
      "latitude": 36.750783,
      "longitude": -100.478393,
      "city": "Balko",
      "state": "OK",
      "county": "Beaver"
  },
  {
      "zipCode": 73932,
      "latitude": 36.834113,
      "longitude": -100.51773,
      "city": "Beaver",
      "state": "OK",
      "county": "Beaver"
  },
  {
      "zipCode": 73933,
      "latitude": 36.742042,
      "longitude": -102.510484,
      "city": "Boise City",
      "state": "OK",
      "county": "Cimarron"
  },
  {
      "zipCode": 73937,
      "latitude": 36.75051,
      "longitude": -102.515316,
      "city": "Felt",
      "state": "OK",
      "county": "Cimarron"
  },
  {
      "zipCode": 73938,
      "latitude": 36.86081,
      "longitude": -100.531611,
      "city": "Forgan",
      "state": "OK",
      "county": "Beaver"
  },
  {
      "zipCode": 73939,
      "latitude": 36.594672,
      "longitude": -101.630164,
      "city": "Goodwell",
      "state": "OK",
      "county": "Texas"
  },
  {
      "zipCode": 73942,
      "latitude": 36.729713,
      "longitude": -101.426147,
      "city": "Guymon",
      "state": "OK",
      "county": "Texas"
  },
  {
      "zipCode": 73944,
      "latitude": 36.615367,
      "longitude": -101.198155,
      "city": "Hardesty",
      "state": "OK",
      "county": "Texas"
  },
  {
      "zipCode": 73945,
      "latitude": 36.810851,
      "longitude": -101.216333,
      "city": "Hooker",
      "state": "OK",
      "county": "Texas"
  },
  {
      "zipCode": 73946,
      "latitude": 36.903144,
      "longitude": -102.965301,
      "city": "Kenton",
      "state": "OK",
      "county": "Cimarron"
  },
  {
      "zipCode": 73947,
      "latitude": 36.8079,
      "longitude": -102.250924,
      "city": "Keyes",
      "state": "OK",
      "county": "Cimarron"
  },
  {
      "zipCode": 73949,
      "latitude": 36.507508,
      "longitude": -101.780668,
      "city": "Texhoma",
      "state": "OK",
      "county": "Texas"
  },
  {
      "zipCode": 73950,
      "latitude": 36.866365,
      "longitude": -100.877878,
      "city": "Turpin",
      "state": "OK",
      "county": "Beaver"
  },
  {
      "zipCode": 73951,
      "latitude": 36.971874,
      "longitude": -101.073973,
      "city": "Tyrone",
      "state": "OK",
      "county": "Texas"
  },
  {
      "zipCode": 74001,
      "latitude": 36.490056,
      "longitude": -96.061608,
      "city": "Avant",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74002,
      "latitude": 36.561588,
      "longitude": -96.162409,
      "city": "Barnsdall",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74003,
      "latitude": 36.759485,
      "longitude": -95.970182,
      "city": "Bartlesville",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74004,
      "latitude": 36.711386,
      "longitude": -95.894042,
      "city": "Bartlesville",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74005,
      "latitude": 36.711386,
      "longitude": -95.894042,
      "city": "Bartlesville",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74006,
      "latitude": 36.728057,
      "longitude": -95.89064,
      "city": "Bartlesville",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74008,
      "latitude": 35.93345,
      "longitude": -95.877617,
      "city": "Bixby",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74009,
      "latitude": 36.665794,
      "longitude": -96.398424,
      "city": "Bowring",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74010,
      "latitude": 35.835606,
      "longitude": -96.303922,
      "city": "Bristow",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74011,
      "latitude": 35.986399,
      "longitude": -95.818064,
      "city": "Broken Arrow",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74012,
      "latitude": 36.14226,
      "longitude": -95.879069,
      "city": "Broken Arrow",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74013,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Broken Arrow",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74014,
      "latitude": 36.005673,
      "longitude": -95.654486,
      "city": "Broken Arrow",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74015,
      "latitude": 36.212092,
      "longitude": -95.72584,
      "city": "Catoosa",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74016,
      "latitude": 36.495713,
      "longitude": -95.435688,
      "city": "Chelsea",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74017,
      "latitude": 36.348616,
      "longitude": -95.604344,
      "city": "Claremore",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74018,
      "latitude": 36.343579,
      "longitude": -95.605964,
      "city": "Claremore",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74020,
      "latitude": 36.263766,
      "longitude": -96.443319,
      "city": "Cleveland",
      "state": "OK",
      "county": "Pawnee"
  },
  {
      "zipCode": 74021,
      "latitude": 36.370042,
      "longitude": -95.857143,
      "city": "Collinsville",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74022,
      "latitude": 36.800524,
      "longitude": -95.922953,
      "city": "Copan",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74023,
      "latitude": 36.029294,
      "longitude": -96.855949,
      "city": "Cushing",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74026,
      "latitude": 35.708886,
      "longitude": -96.766992,
      "city": "Davenport",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74027,
      "latitude": 36.777208,
      "longitude": -95.641981,
      "city": "Delaware",
      "state": "OK",
      "county": "Nowata"
  },
  {
      "zipCode": 74028,
      "latitude": 35.769827,
      "longitude": -96.49192,
      "city": "Depew",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74029,
      "latitude": 36.676086,
      "longitude": -95.896743,
      "city": "Dewey",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74030,
      "latitude": 36.012363,
      "longitude": -96.548022,
      "city": "Drumright",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74031,
      "latitude": 36.336457,
      "longitude": -95.571646,
      "city": "Foyil",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74032,
      "latitude": 36.225349,
      "longitude": -96.933795,
      "city": "Glencoe",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74033,
      "latitude": 36.102858,
      "longitude": -95.998172,
      "city": "Glenpool",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74034,
      "latitude": 36.36216,
      "longitude": -96.637553,
      "city": "Hallett",
      "state": "OK",
      "county": "Pawnee"
  },
  {
      "zipCode": 74035,
      "latitude": 36.363731,
      "longitude": -96.236595,
      "city": "Hominy",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74036,
      "latitude": 36.169526,
      "longitude": -95.521145,
      "city": "Inola",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74037,
      "latitude": 36.006567,
      "longitude": -95.980972,
      "city": "Jenks",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74038,
      "latitude": 36.180965,
      "longitude": -96.570239,
      "city": "Jennings",
      "state": "OK",
      "county": "Pawnee"
  },
  {
      "zipCode": 74039,
      "latitude": 35.908301,
      "longitude": -96.240843,
      "city": "Kellyville",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74041,
      "latitude": 35.948245,
      "longitude": -96.071685,
      "city": "Kiefer",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74042,
      "latitude": 36.922429,
      "longitude": -95.629628,
      "city": "Lenapah",
      "state": "OK",
      "county": "Nowata"
  },
  {
      "zipCode": 74043,
      "latitude": 35.918277,
      "longitude": -95.798809,
      "city": "Leonard",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74044,
      "latitude": 36.079255,
      "longitude": -96.378373,
      "city": "Mannford",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74045,
      "latitude": 36.36216,
      "longitude": -96.637553,
      "city": "Maramec",
      "state": "OK",
      "county": "Pawnee"
  },
  {
      "zipCode": 74046,
      "latitude": 35.901277,
      "longitude": -96.326053,
      "city": "Milfay",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74047,
      "latitude": 35.889588,
      "longitude": -96.088684,
      "city": "Mounds",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74048,
      "latitude": 36.69908,
      "longitude": -95.631607,
      "city": "Nowata",
      "state": "OK",
      "county": "Nowata"
  },
  {
      "zipCode": 74050,
      "latitude": 36.07694,
      "longitude": -96.069726,
      "city": "Oakhurst",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74051,
      "latitude": 36.624717,
      "longitude": -95.951734,
      "city": "Ochelata",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74052,
      "latitude": 36.068495,
      "longitude": -96.595845,
      "city": "Oilton",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74053,
      "latitude": 36.448241,
      "longitude": -95.704667,
      "city": "Oologah",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74054,
      "latitude": 36.265311,
      "longitude": -96.341334,
      "city": "Osage",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74055,
      "latitude": 36.140225,
      "longitude": -95.909669,
      "city": "Owasso",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74056,
      "latitude": 36.620131,
      "longitude": -96.324443,
      "city": "Pawhuska",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74058,
      "latitude": 36.306976,
      "longitude": -96.706208,
      "city": "Pawnee",
      "state": "OK",
      "county": "Pawnee"
  },
  {
      "zipCode": 74059,
      "latitude": 36.096244,
      "longitude": -97.000469,
      "city": "Perkins",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74060,
      "latitude": 36.580102,
      "longitude": -96.532552,
      "city": "Prue",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74061,
      "latitude": 36.533445,
      "longitude": -95.905906,
      "city": "Ramona",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74062,
      "latitude": 36.049649,
      "longitude": -96.928923,
      "city": "Ripley",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74063,
      "latitude": 36.118535,
      "longitude": -96.186776,
      "city": "Sand Springs",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74066,
      "latitude": 35.982642,
      "longitude": -96.163955,
      "city": "Sapulpa",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74067,
      "latitude": 36.019575,
      "longitude": -96.093682,
      "city": "Sapulpa",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74068,
      "latitude": 35.912399,
      "longitude": -96.57803,
      "city": "Shamrock",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74070,
      "latitude": 36.413662,
      "longitude": -96.168498,
      "city": "Skiatook",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74071,
      "latitude": 35.901277,
      "longitude": -96.326053,
      "city": "Slick",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74072,
      "latitude": 36.927212,
      "longitude": -95.621099,
      "city": "S Coffeyville",
      "state": "OK",
      "county": "Nowata"
  },
  {
      "zipCode": 74073,
      "latitude": 36.298452,
      "longitude": -95.951622,
      "city": "Sperry",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74074,
      "latitude": 36.101521,
      "longitude": -97.069061,
      "city": "Stillwater",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74075,
      "latitude": 36.167933,
      "longitude": -97.068895,
      "city": "Stillwater",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74076,
      "latitude": 36.072372,
      "longitude": -97.055141,
      "city": "Stillwater",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74077,
      "latitude": 36.093665,
      "longitude": -96.987458,
      "city": "Stillwater",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74078,
      "latitude": 36.093665,
      "longitude": -96.987458,
      "city": "Stillwater",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74079,
      "latitude": 35.709676,
      "longitude": -96.745384,
      "city": "Stroud",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74080,
      "latitude": 36.496258,
      "longitude": -95.70201,
      "city": "Talala",
      "state": "OK",
      "county": "Rogers"
  },
  {
      "zipCode": 74081,
      "latitude": 36.188021,
      "longitude": -96.491832,
      "city": "Terlton",
      "state": "OK",
      "county": "Pawnee"
  },
  {
      "zipCode": 74082,
      "latitude": 36.450115,
      "longitude": -95.881381,
      "city": "Vera",
      "state": "OK",
      "county": "Washington"
  },
  {
      "zipCode": 74083,
      "latitude": 36.798384,
      "longitude": -95.608896,
      "city": "Wann",
      "state": "OK",
      "county": "Nowata"
  },
  {
      "zipCode": 74084,
      "latitude": 36.546423,
      "longitude": -96.326019,
      "city": "Wynona",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74085,
      "latitude": 36.164752,
      "longitude": -96.803185,
      "city": "Yale",
      "state": "OK",
      "county": "Payne"
  },
  {
      "zipCode": 74101,
      "latitude": 36.039147,
      "longitude": -95.868667,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74102,
      "latitude": 36.063095,
      "longitude": -95.804231,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74103,
      "latitude": 36.155392,
      "longitude": -95.994424,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74104,
      "latitude": 36.146143,
      "longitude": -95.951483,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74105,
      "latitude": 36.097094,
      "longitude": -95.91987,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74106,
      "latitude": 36.190442,
      "longitude": -95.979824,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74107,
      "latitude": 36.076795,
      "longitude": -95.954871,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74108,
      "latitude": 36.147696,
      "longitude": -95.797314,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74110,
      "latitude": 36.158314,
      "longitude": -95.927771,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74112,
      "latitude": 36.146741,
      "longitude": -95.90447,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74114,
      "latitude": 36.125893,
      "longitude": -95.947421,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74115,
      "latitude": 36.147343,
      "longitude": -95.90717,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74116,
      "latitude": 36.189944,
      "longitude": -95.817465,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74117,
      "latitude": 36.218091,
      "longitude": -95.947122,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74119,
      "latitude": 36.115461,
      "longitude": -95.930271,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74120,
      "latitude": 36.148593,
      "longitude": -95.979824,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74121,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74126,
      "latitude": 36.249491,
      "longitude": -95.977624,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74127,
      "latitude": 36.221189,
      "longitude": -96.05762,
      "city": "Tulsa",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74128,
      "latitude": 36.125545,
      "longitude": -95.885568,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74129,
      "latitude": 36.125996,
      "longitude": -95.868767,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74130,
      "latitude": 36.242342,
      "longitude": -95.955453,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74131,
      "latitude": 36.04989,
      "longitude": -96.074326,
      "city": "Tulsa",
      "state": "OK",
      "county": "Creek"
  },
  {
      "zipCode": 74132,
      "latitude": 36.045543,
      "longitude": -95.956267,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74133,
      "latitude": 36.062203,
      "longitude": -95.873067,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74134,
      "latitude": 36.104447,
      "longitude": -95.804014,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74135,
      "latitude": 36.092236,
      "longitude": -95.923175,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74136,
      "latitude": 36.053498,
      "longitude": -95.920371,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74137,
      "latitude": 36.017296,
      "longitude": -95.93902,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74141,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74145,
      "latitude": 36.118687,
      "longitude": -95.884368,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74146,
      "latitude": 36.097046,
      "longitude": -95.846066,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74147,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74148,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74149,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74150,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74152,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74153,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74155,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74156,
      "latitude": 36.302391,
      "longitude": -95.960472,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74157,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74158,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74159,
      "latitude": 36.077221,
      "longitude": -96.083477,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74169,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74170,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74171,
      "latitude": 36.054345,
      "longitude": -95.957721,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74172,
      "latitude": 36.154342,
      "longitude": -95.992323,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74182,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74183,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74184,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74186,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74187,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74189,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74192,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74193,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74194,
      "latitude": 36.139826,
      "longitude": -96.029725,
      "city": "Tulsa",
      "state": "OK",
      "county": "Tulsa"
  },
  {
      "zipCode": 74301,
      "latitude": 36.723649,
      "longitude": -95.197639,
      "city": "Vinita",
      "state": "OK",
      "county": "Craig"
  },
  {
      "zipCode": 74330,
      "latitude": 36.43736,
      "longitude": -95.26975,
      "city": "Adair",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74331,
      "latitude": 36.82215,
      "longitude": -94.916222,
      "city": "Afton",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74332,
      "latitude": 36.533954,
      "longitude": -95.224568,
      "city": "Big Cabin",
      "state": "OK",
      "county": "Craig"
  },
  {
      "zipCode": 74333,
      "latitude": 36.844913,
      "longitude": -95.073159,
      "city": "Bluejacket",
      "state": "OK",
      "county": "Craig"
  },
  {
      "zipCode": 74335,
      "latitude": 36.833508,
      "longitude": -94.82788,
      "city": "Cardin",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74337,
      "latitude": 36.187866,
      "longitude": -95.261554,
      "city": "Chouteau",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74338,
      "latitude": 36.265655,
      "longitude": -94.694674,
      "city": "Colcord",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74339,
      "latitude": 36.829945,
      "longitude": -94.876086,
      "city": "Commerce",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74340,
      "latitude": 36.292495,
      "longitude": -95.222792,
      "city": "Disney",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74342,
      "latitude": 36.415938,
      "longitude": -94.787091,
      "city": "Eucha",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74343,
      "latitude": 36.741636,
      "longitude": -94.826053,
      "city": "Fairland",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74344,
      "latitude": 36.545757,
      "longitude": -94.74162,
      "city": "Grove",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74345,
      "latitude": 36.603587,
      "longitude": -94.72974,
      "city": "Grove",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74346,
      "latitude": 36.482043,
      "longitude": -94.802653,
      "city": "Jay",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74347,
      "latitude": 36.210142,
      "longitude": -94.759426,
      "city": "Kansas",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74349,
      "latitude": 36.489096,
      "longitude": -95.027854,
      "city": "Ketchum",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74350,
      "latitude": 36.464494,
      "longitude": -95.047773,
      "city": "Langley",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74352,
      "latitude": 36.198011,
      "longitude": -95.167408,
      "city": "Locust Grove",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74354,
      "latitude": 36.842401,
      "longitude": -94.854482,
      "city": "Miami",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74355,
      "latitude": 36.83408,
      "longitude": -94.877031,
      "city": "Miami",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74358,
      "latitude": 36.916334,
      "longitude": -94.885709,
      "city": "North Miami",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74359,
      "latitude": 36.415938,
      "longitude": -94.787091,
      "city": "Oaks",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74360,
      "latitude": 36.946023,
      "longitude": -94.740343,
      "city": "Picher",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74361,
      "latitude": 36.307279,
      "longitude": -95.271696,
      "city": "Pryor",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74362,
      "latitude": 36.292495,
      "longitude": -95.222792,
      "city": "Pryor",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74363,
      "latitude": 36.942292,
      "longitude": -94.733392,
      "city": "Quapaw",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74364,
      "latitude": 36.292495,
      "longitude": -95.222792,
      "city": "Rose",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74365,
      "latitude": 36.286057,
      "longitude": -95.232816,
      "city": "Salina",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74366,
      "latitude": 36.396264,
      "longitude": -95.049656,
      "city": "Spavinaw",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74367,
      "latitude": 36.414108,
      "longitude": -95.077276,
      "city": "Strang",
      "state": "OK",
      "county": "Mayes"
  },
  {
      "zipCode": 74368,
      "latitude": 36.205961,
      "longitude": -94.797319,
      "city": "Twin Oaks",
      "state": "OK",
      "county": "Delaware"
  },
  {
      "zipCode": 74369,
      "latitude": 36.885732,
      "longitude": -95.215685,
      "city": "Welch",
      "state": "OK",
      "county": "Craig"
  },
  {
      "zipCode": 74370,
      "latitude": 36.798561,
      "longitude": -94.789475,
      "city": "Wyandotte",
      "state": "OK",
      "county": "Ottawa"
  },
  {
      "zipCode": 74401,
      "latitude": 35.70582,
      "longitude": -95.50025,
      "city": "Muskogee",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74402,
      "latitude": 35.764223,
      "longitude": -95.306916,
      "city": "Muskogee",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74403,
      "latitude": 35.654483,
      "longitude": -95.433598,
      "city": "Muskogee",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74421,
      "latitude": 35.737125,
      "longitude": -96.026538,
      "city": "Beggs",
      "state": "OK",
      "county": "Okmulgee"
  },
  {
      "zipCode": 74422,
      "latitude": 35.559244,
      "longitude": -95.408146,
      "city": "Boynton",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74423,
      "latitude": 35.662957,
      "longitude": -95.19778,
      "city": "Braggs",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74425,
      "latitude": 35.157132,
      "longitude": -95.621536,
      "city": "Canadian",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74426,
      "latitude": 35.492196,
      "longitude": -95.517816,
      "city": "Checotah",
      "state": "OK",
      "county": "Mcintosh"
  },
  {
      "zipCode": 74427,
      "latitude": 35.8093,
      "longitude": -95.076549,
      "city": "Cookson",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74428,
      "latitude": 35.559244,
      "longitude": -95.408146,
      "city": "Council Hill",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74429,
      "latitude": 36.002092,
      "longitude": -95.674795,
      "city": "Coweta",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74430,
      "latitude": 34.947259,
      "longitude": -95.720708,
      "city": "Crowder",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74431,
      "latitude": 35.455066,
      "longitude": -95.949771,
      "city": "Dewar",
      "state": "OK",
      "county": "Okmulgee"
  },
  {
      "zipCode": 74432,
      "latitude": 35.318019,
      "longitude": -95.588523,
      "city": "Eufaula",
      "state": "OK",
      "county": "Mcintosh"
  },
  {
      "zipCode": 74434,
      "latitude": 35.739927,
      "longitude": -95.40064,
      "city": "Fort Gibson",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74435,
      "latitude": 35.532577,
      "longitude": -95.109916,
      "city": "Gore",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74436,
      "latitude": 35.813548,
      "longitude": -95.681972,
      "city": "Haskell",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74437,
      "latitude": 35.462159,
      "longitude": -96.012229,
      "city": "Henryetta",
      "state": "OK",
      "county": "Okmulgee"
  },
  {
      "zipCode": 74438,
      "latitude": 35.347368,
      "longitude": -95.664118,
      "city": "Hitchita",
      "state": "OK",
      "county": "Mcintosh"
  },
  {
      "zipCode": 74440,
      "latitude": 35.258196,
      "longitude": -95.134492,
      "city": "Hoyt",
      "state": "OK",
      "county": "Haskell"
  },
  {
      "zipCode": 74441,
      "latitude": 35.94161,
      "longitude": -95.198082,
      "city": "Hulbert",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74442,
      "latitude": 34.947259,
      "longitude": -95.720708,
      "city": "Indianola",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74444,
      "latitude": 36.025728,
      "longitude": -94.957751,
      "city": "Moodys",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74445,
      "latitude": 35.624291,
      "longitude": -95.841592,
      "city": "Morris",
      "state": "OK",
      "county": "Okmulgee"
  },
  {
      "zipCode": 74446,
      "latitude": 35.857722,
      "longitude": -95.285648,
      "city": "Okay",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74447,
      "latitude": 35.612514,
      "longitude": -95.966857,
      "city": "Okmulgee",
      "state": "OK",
      "county": "Okmulgee"
  },
  {
      "zipCode": 74450,
      "latitude": 35.604611,
      "longitude": -95.463621,
      "city": "Oktaha",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74451,
      "latitude": 35.727468,
      "longitude": -94.997651,
      "city": "Park Hill",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74452,
      "latitude": 35.900074,
      "longitude": -95.040008,
      "city": "Peggs",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74454,
      "latitude": 35.86881,
      "longitude": -95.522072,
      "city": "Porter",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74455,
      "latitude": 35.46187,
      "longitude": -95.297144,
      "city": "Porum",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74456,
      "latitude": 35.616975,
      "longitude": -95.952741,
      "city": "Preston",
      "state": "OK",
      "county": "Okmulgee"
  },
  {
      "zipCode": 74457,
      "latitude": 35.900019,
      "longitude": -94.640218,
      "city": "Proctor",
      "state": "OK",
      "county": "Adair"
  },
  {
      "zipCode": 74458,
      "latitude": 35.963567,
      "longitude": -95.513856,
      "city": "Redbird",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74459,
      "latitude": 35.521423,
      "longitude": -95.494952,
      "city": "Rentiesville",
      "state": "OK",
      "county": "Mcintosh"
  },
  {
      "zipCode": 74460,
      "latitude": 35.513169,
      "longitude": -95.958271,
      "city": "Schulter",
      "state": "OK",
      "county": "Okmulgee"
  },
  {
      "zipCode": 74461,
      "latitude": 35.347368,
      "longitude": -95.664118,
      "city": "Stidham",
      "state": "OK",
      "county": "Mcintosh"
  },
  {
      "zipCode": 74462,
      "latitude": 35.266094,
      "longitude": -95.061902,
      "city": "Stigler",
      "state": "OK",
      "county": "Haskell"
  },
  {
      "zipCode": 74463,
      "latitude": 35.763648,
      "longitude": -95.544501,
      "city": "Taft",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74464,
      "latitude": 35.911304,
      "longitude": -94.911331,
      "city": "Tahlequah",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74465,
      "latitude": 35.900074,
      "longitude": -95.040008,
      "city": "Tahlequah",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74466,
      "latitude": 35.963567,
      "longitude": -95.513856,
      "city": "Tullahassee",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74467,
      "latitude": 35.943419,
      "longitude": -95.415011,
      "city": "Wagoner",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74468,
      "latitude": 35.559244,
      "longitude": -95.408146,
      "city": "Wainwright",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74469,
      "latitude": 35.493529,
      "longitude": -95.390328,
      "city": "Warner",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74470,
      "latitude": 35.511412,
      "longitude": -95.131499,
      "city": "Webbers Falls",
      "state": "OK",
      "county": "Muskogee"
  },
  {
      "zipCode": 74471,
      "latitude": 35.764427,
      "longitude": -94.881607,
      "city": "Welling",
      "state": "OK",
      "county": "Cherokee"
  },
  {
      "zipCode": 74472,
      "latitude": 35.258196,
      "longitude": -95.134492,
      "city": "Whitefield",
      "state": "OK",
      "county": "Haskell"
  },
  {
      "zipCode": 74477,
      "latitude": 35.963567,
      "longitude": -95.513856,
      "city": "Wagoner",
      "state": "OK",
      "county": "Wagoner"
  },
  {
      "zipCode": 74501,
      "latitude": 34.922756,
      "longitude": -95.685544,
      "city": "Mcalester",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74502,
      "latitude": 34.947259,
      "longitude": -95.720708,
      "city": "Mcalester",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74521,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Albion",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74522,
      "latitude": 34.904489,
      "longitude": -95.69806,
      "city": "Alderson",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74523,
      "latitude": 34.215441,
      "longitude": -95.626306,
      "city": "Antlers",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74525,
      "latitude": 34.358254,
      "longitude": -96.0825,
      "city": "Atoka",
      "state": "OK",
      "county": "Atoka"
  },
  {
      "zipCode": 74528,
      "latitude": 34.947259,
      "longitude": -95.720708,
      "city": "Blanco",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74529,
      "latitude": 34.947259,
      "longitude": -95.720708,
      "city": "Blocker",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74530,
      "latitude": 34.310191,
      "longitude": -96.670349,
      "city": "Bromide",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 74531,
      "latitude": 35.028521,
      "longitude": -96.235928,
      "city": "Calvin",
      "state": "OK",
      "county": "Hughes"
  },
  {
      "zipCode": 74533,
      "latitude": 34.208512,
      "longitude": -96.197853,
      "city": "Caney",
      "state": "OK",
      "county": "Atoka"
  },
  {
      "zipCode": 74534,
      "latitude": 34.592949,
      "longitude": -96.303069,
      "city": "Centrahoma",
      "state": "OK",
      "county": "Coal"
  },
  {
      "zipCode": 74535,
      "latitude": 34.592949,
      "longitude": -96.303069,
      "city": "Clarita",
      "state": "OK",
      "county": "Coal"
  },
  {
      "zipCode": 74536,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Clayton",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74538,
      "latitude": 34.538327,
      "longitude": -96.229156,
      "city": "Coalgate",
      "state": "OK",
      "county": "Coal"
  },
  {
      "zipCode": 74540,
      "latitude": 34.4187,
      "longitude": -96.039727,
      "city": "Daisy",
      "state": "OK",
      "county": "Atoka"
  },
  {
      "zipCode": 74542,
      "latitude": 34.4187,
      "longitude": -96.039727,
      "city": "Atoka",
      "state": "OK",
      "county": "Atoka"
  },
  {
      "zipCode": 74543,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Finley",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74545,
      "latitude": 34.869457,
      "longitude": -95.221099,
      "city": "Gowen",
      "state": "OK",
      "county": "Latimer"
  },
  {
      "zipCode": 74546,
      "latitude": 34.849958,
      "longitude": -95.577718,
      "city": "Haileyville",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74547,
      "latitude": 34.982457,
      "longitude": -95.544861,
      "city": "Hartshorne",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74549,
      "latitude": 35.025939,
      "longitude": -94.618339,
      "city": "Honobia",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74552,
      "latitude": 35.258196,
      "longitude": -95.134492,
      "city": "Kinta",
      "state": "OK",
      "county": "Haskell"
  },
  {
      "zipCode": 74553,
      "latitude": 34.723584,
      "longitude": -95.906795,
      "city": "Kiowa",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74554,
      "latitude": 34.894194,
      "longitude": -95.761561,
      "city": "Krebs",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74555,
      "latitude": 34.247711,
      "longitude": -95.935842,
      "city": "Lane",
      "state": "OK",
      "county": "Atoka"
  },
  {
      "zipCode": 74556,
      "latitude": 34.592949,
      "longitude": -96.303069,
      "city": "Lehigh",
      "state": "OK",
      "county": "Coal"
  },
  {
      "zipCode": 74557,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Moyers",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74558,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Nashoba",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74559,
      "latitude": 34.869457,
      "longitude": -95.221099,
      "city": "Panola",
      "state": "OK",
      "county": "Latimer"
  },
  {
      "zipCode": 74560,
      "latitude": 34.718754,
      "longitude": -95.897915,
      "city": "Pittsburg",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74561,
      "latitude": 35.125294,
      "longitude": -95.364874,
      "city": "Quinton",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74562,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Rattan",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74563,
      "latitude": 34.951776,
      "longitude": -95.079806,
      "city": "Red Oak",
      "state": "OK",
      "county": "Latimer"
  },
  {
      "zipCode": 74565,
      "latitude": 34.835378,
      "longitude": -95.838039,
      "city": "Savanna",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74567,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Snow",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74569,
      "latitude": 34.456722,
      "longitude": -96.012309,
      "city": "Stringtown",
      "state": "OK",
      "county": "Atoka"
  },
  {
      "zipCode": 74570,
      "latitude": 34.903001,
      "longitude": -96.099488,
      "city": "Stuart",
      "state": "OK",
      "county": "Hughes"
  },
  {
      "zipCode": 74571,
      "latitude": 34.745742,
      "longitude": -95.044014,
      "city": "Talihina",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74572,
      "latitude": 34.603933,
      "longitude": -96.420055,
      "city": "Tupelo",
      "state": "OK",
      "county": "Coal"
  },
  {
      "zipCode": 74574,
      "latitude": 34.418801,
      "longitude": -95.358406,
      "city": "Tuskahoma",
      "state": "OK",
      "county": "Pushmataha"
  },
  {
      "zipCode": 74576,
      "latitude": 34.947259,
      "longitude": -95.720708,
      "city": "Wardville",
      "state": "OK",
      "county": "Pittsburg"
  },
  {
      "zipCode": 74577,
      "latitude": 34.943627,
      "longitude": -94.745782,
      "city": "Whitesboro",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74578,
      "latitude": 34.855219,
      "longitude": -95.287712,
      "city": "Wilburton",
      "state": "OK",
      "county": "Latimer"
  },
  {
      "zipCode": 74601,
      "latitude": 36.736868,
      "longitude": -97.18908,
      "city": "Ponca City",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74602,
      "latitude": 36.695368,
      "longitude": -97.137693,
      "city": "Ponca City",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74603,
      "latitude": 36.796349,
      "longitude": -97.106166,
      "city": "Ponca City",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74604,
      "latitude": 36.767551,
      "longitude": -97.154165,
      "city": "Ponca City",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74630,
      "latitude": 36.513543,
      "longitude": -97.408128,
      "city": "Billings",
      "state": "OK",
      "county": "Noble"
  },
  {
      "zipCode": 74631,
      "latitude": 36.803494,
      "longitude": -97.20684,
      "city": "Blackwell",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74632,
      "latitude": 36.941212,
      "longitude": -97.336335,
      "city": "Braman",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74633,
      "latitude": 36.709687,
      "longitude": -96.806094,
      "city": "Burbank",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74636,
      "latitude": 36.807305,
      "longitude": -97.520322,
      "city": "Deer Creek",
      "state": "OK",
      "county": "Grant"
  },
  {
      "zipCode": 74637,
      "latitude": 36.593236,
      "longitude": -96.778701,
      "city": "Fairfax",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74640,
      "latitude": 36.563723,
      "longitude": -97.662492,
      "city": "Hunter",
      "state": "OK",
      "county": "Garfield"
  },
  {
      "zipCode": 74641,
      "latitude": 36.838239,
      "longitude": -96.883292,
      "city": "Kaw City",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74643,
      "latitude": 36.690445,
      "longitude": -97.557809,
      "city": "Lamont",
      "state": "OK",
      "county": "Grant"
  },
  {
      "zipCode": 74644,
      "latitude": 36.543244,
      "longitude": -97.123973,
      "city": "Marland",
      "state": "OK",
      "county": "Noble"
  },
  {
      "zipCode": 74646,
      "latitude": 36.818186,
      "longitude": -97.430104,
      "city": "Nardin",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74647,
      "latitude": 36.840009,
      "longitude": -97.056112,
      "city": "Newkirk",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74650,
      "latitude": 36.503442,
      "longitude": -96.735613,
      "city": "Ralston",
      "state": "OK",
      "county": "Pawnee"
  },
  {
      "zipCode": 74651,
      "latitude": 36.455368,
      "longitude": -97.187383,
      "city": "Red Rock",
      "state": "OK",
      "county": "Noble"
  },
  {
      "zipCode": 74652,
      "latitude": 36.781943,
      "longitude": -96.6607,
      "city": "Shidler",
      "state": "OK",
      "county": "Osage"
  },
  {
      "zipCode": 74653,
      "latitude": 36.678755,
      "longitude": -97.166131,
      "city": "Tonkawa",
      "state": "OK",
      "county": "Kay"
  },
  {
      "zipCode": 74701,
      "latitude": 34.044944,
      "longitude": -96.321428,
      "city": "Durant",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74702,
      "latitude": 33.921979,
      "longitude": -96.191767,
      "city": "Durant",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74720,
      "latitude": 33.924443,
      "longitude": -96.264411,
      "city": "Achille",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74721,
      "latitude": 33.921979,
      "longitude": -96.191767,
      "city": "Albany",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74722,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Battiest",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74723,
      "latitude": 34.002019,
      "longitude": -96.038191,
      "city": "Bennington",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74724,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Bethel",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74726,
      "latitude": 34.017405,
      "longitude": -96.141469,
      "city": "Bokchito",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74727,
      "latitude": 34.028048,
      "longitude": -95.869986,
      "city": "Boswell",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74728,
      "latitude": 33.953477,
      "longitude": -94.828998,
      "city": "Broken Bow",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74729,
      "latitude": 34.092082,
      "longitude": -96.301579,
      "city": "Caddo",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74730,
      "latitude": 33.932249,
      "longitude": -96.430781,
      "city": "Calera",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74731,
      "latitude": 33.921979,
      "longitude": -96.191767,
      "city": "Cartwright",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74733,
      "latitude": 33.857674,
      "longitude": -96.528187,
      "city": "Colbert",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74734,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Eagletown",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74735,
      "latitude": 34.01168,
      "longitude": -95.573733,
      "city": "Fort Towson",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74736,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Garvin",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74737,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Golden",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74738,
      "latitude": 34.01168,
      "longitude": -95.573733,
      "city": "Grant",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74740,
      "latitude": 33.851471,
      "longitude": -94.733795,
      "city": "Haworth",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74741,
      "latitude": 33.772161,
      "longitude": -96.354429,
      "city": "Hendrix",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74743,
      "latitude": 34.018892,
      "longitude": -95.514473,
      "city": "Hugo",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74745,
      "latitude": 33.956429,
      "longitude": -94.869464,
      "city": "Idabel",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74747,
      "latitude": 33.921979,
      "longitude": -96.191767,
      "city": "Kemp",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74748,
      "latitude": 34.131629,
      "longitude": -96.263566,
      "city": "Kenefic",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74750,
      "latitude": 33.980066,
      "longitude": -95.002496,
      "city": "Millerton",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74752,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Pickens",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74753,
      "latitude": 33.921979,
      "longitude": -96.191767,
      "city": "Platter",
      "state": "OK",
      "county": "Bryan"
  },
  {
      "zipCode": 74754,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Ringold",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74755,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Rufe",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74756,
      "latitude": 34.01168,
      "longitude": -95.573733,
      "city": "Sawyer",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74759,
      "latitude": 34.03264,
      "longitude": -95.696492,
      "city": "Soper",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74760,
      "latitude": 34.01168,
      "longitude": -95.573733,
      "city": "Spencerville",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74761,
      "latitude": 34.01168,
      "longitude": -95.573733,
      "city": "Swink",
      "state": "OK",
      "county": "Choctaw"
  },
  {
      "zipCode": 74764,
      "latitude": 34.00463,
      "longitude": -95.090099,
      "city": "Valliant",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74766,
      "latitude": 34.061785,
      "longitude": -95.005676,
      "city": "Wright City",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74801,
      "latitude": 35.295843,
      "longitude": -96.960092,
      "city": "Shawnee",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74802,
      "latitude": 35.365621,
      "longitude": -96.959601,
      "city": "Shawnee",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74804,
      "latitude": 35.386758,
      "longitude": -96.933148,
      "city": "Shawnee",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74818,
      "latitude": 35.162228,
      "longitude": -96.609139,
      "city": "Seminole",
      "state": "OK",
      "county": "Seminole"
  },
  {
      "zipCode": 74820,
      "latitude": 34.748961,
      "longitude": -96.685325,
      "city": "Ada",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74821,
      "latitude": 34.735301,
      "longitude": -96.669321,
      "city": "Ada",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74824,
      "latitude": 35.908981,
      "longitude": -96.873793,
      "city": "Agra",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74825,
      "latitude": 34.869145,
      "longitude": -96.415107,
      "city": "Allen",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74826,
      "latitude": 34.96959,
      "longitude": -96.946216,
      "city": "Asher",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74827,
      "latitude": 35.028521,
      "longitude": -96.235928,
      "city": "Atwood",
      "state": "OK",
      "county": "Hughes"
  },
  {
      "zipCode": 74829,
      "latitude": 35.513491,
      "longitude": -96.495334,
      "city": "Boley",
      "state": "OK",
      "county": "Okfuskee"
  },
  {
      "zipCode": 74830,
      "latitude": 35.162228,
      "longitude": -96.609139,
      "city": "Bowlegs",
      "state": "OK",
      "county": "Seminole"
  },
  {
      "zipCode": 74831,
      "latitude": 34.873374,
      "longitude": -97.048399,
      "city": "Byars",
      "state": "OK",
      "county": "Mcclain"
  },
  {
      "zipCode": 74832,
      "latitude": 35.804507,
      "longitude": -97.028182,
      "city": "Carney",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74833,
      "latitude": 35.464506,
      "longitude": -96.303082,
      "city": "Castle",
      "state": "OK",
      "county": "Okfuskee"
  },
  {
      "zipCode": 74834,
      "latitude": 35.647953,
      "longitude": -96.871215,
      "city": "Chandler",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74836,
      "latitude": 34.419411,
      "longitude": -96.629095,
      "city": "Connerville",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 74837,
      "latitude": 35.340458,
      "longitude": -96.454578,
      "city": "Cromwell",
      "state": "OK",
      "county": "Seminole"
  },
  {
      "zipCode": 74839,
      "latitude": 35.270328,
      "longitude": -96.033734,
      "city": "Dustin",
      "state": "OK",
      "county": "Hughes"
  },
  {
      "zipCode": 74840,
      "latitude": 35.324924,
      "longitude": -96.800685,
      "city": "Earlsboro",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74842,
      "latitude": 34.735301,
      "longitude": -96.669321,
      "city": "Fittstown",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74843,
      "latitude": 34.735301,
      "longitude": -96.669321,
      "city": "Fitzhugh",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74844,
      "latitude": 34.874058,
      "longitude": -96.628899,
      "city": "Francis",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74845,
      "latitude": 35.347368,
      "longitude": -95.664118,
      "city": "Hanna",
      "state": "OK",
      "county": "Mcintosh"
  },
  {
      "zipCode": 74848,
      "latitude": 35.087498,
      "longitude": -96.387256,
      "city": "Holdenville",
      "state": "OK",
      "county": "Hughes"
  },
  {
      "zipCode": 74849,
      "latitude": 34.957481,
      "longitude": -96.750209,
      "city": "Konawa",
      "state": "OK",
      "county": "Seminole"
  },
  {
      "zipCode": 74850,
      "latitude": 35.028521,
      "longitude": -96.235928,
      "city": "Lamar",
      "state": "OK",
      "county": "Hughes"
  },
  {
      "zipCode": 74851,
      "latitude": 35.319161,
      "longitude": -97.000737,
      "city": "Mcloud",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74852,
      "latitude": 35.116442,
      "longitude": -97.01139,
      "city": "Macomb",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74854,
      "latitude": 35.06577,
      "longitude": -96.850202,
      "city": "Maud",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74855,
      "latitude": 35.486112,
      "longitude": -96.907623,
      "city": "Meeker",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74856,
      "latitude": 34.310191,
      "longitude": -96.670349,
      "city": "Mill Creek",
      "state": "OK",
      "county": "Johnston"
  },
  {
      "zipCode": 74857,
      "latitude": 35.443973,
      "longitude": -97.354813,
      "city": "Newalla",
      "state": "OK",
      "county": "Oklahoma"
  },
  {
      "zipCode": 74859,
      "latitude": 35.462681,
      "longitude": -96.430286,
      "city": "Okemah",
      "state": "OK",
      "county": "Okfuskee"
  },
  {
      "zipCode": 74860,
      "latitude": 35.464803,
      "longitude": -96.580729,
      "city": "Paden",
      "state": "OK",
      "county": "Okfuskee"
  },
  {
      "zipCode": 74864,
      "latitude": 35.58473,
      "longitude": -96.777761,
      "city": "Prague",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74865,
      "latitude": 34.626335,
      "longitude": -96.841231,
      "city": "Roff",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74866,
      "latitude": 35.181436,
      "longitude": -96.882996,
      "city": "Saint Louis",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74867,
      "latitude": 35.162228,
      "longitude": -96.609139,
      "city": "Sasakwa",
      "state": "OK",
      "county": "Seminole"
  },
  {
      "zipCode": 74868,
      "latitude": 35.229281,
      "longitude": -96.652931,
      "city": "Seminole",
      "state": "OK",
      "county": "Seminole"
  },
  {
      "zipCode": 74869,
      "latitude": 35.610392,
      "longitude": -96.814633,
      "city": "Sparks",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74871,
      "latitude": 34.651102,
      "longitude": -96.52735,
      "city": "Stonewall",
      "state": "OK",
      "county": "Pontotoc"
  },
  {
      "zipCode": 74872,
      "latitude": 34.723793,
      "longitude": -96.962983,
      "city": "Stratford",
      "state": "OK",
      "county": "Garvin"
  },
  {
      "zipCode": 74873,
      "latitude": 35.183973,
      "longitude": -96.959439,
      "city": "Tecumseh",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74875,
      "latitude": 35.875943,
      "longitude": -96.962261,
      "city": "Tryon",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74878,
      "latitude": 34.962658,
      "longitude": -97.031451,
      "city": "Wanette",
      "state": "OK",
      "county": "Pottawatomie"
  },
  {
      "zipCode": 74880,
      "latitude": 35.344476,
      "longitude": -96.077141,
      "city": "Weleetka",
      "state": "OK",
      "county": "Okfuskee"
  },
  {
      "zipCode": 74881,
      "latitude": 35.634929,
      "longitude": -97.098322,
      "city": "Wellston",
      "state": "OK",
      "county": "Lincoln"
  },
  {
      "zipCode": 74883,
      "latitude": 35.237428,
      "longitude": -96.248595,
      "city": "Wetumka",
      "state": "OK",
      "county": "Hughes"
  },
  {
      "zipCode": 74884,
      "latitude": 35.146252,
      "longitude": -96.627985,
      "city": "Wewoka",
      "state": "OK",
      "county": "Seminole"
  },
  {
      "zipCode": 74901,
      "latitude": 35.202297,
      "longitude": -94.551914,
      "city": "Arkoma",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74902,
      "latitude": 35.158316,
      "longitude": -94.579744,
      "city": "Pocola",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74930,
      "latitude": 34.943627,
      "longitude": -94.745782,
      "city": "Bokoshe",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74931,
      "latitude": 35.900019,
      "longitude": -94.640218,
      "city": "Bunch",
      "state": "OK",
      "county": "Adair"
  },
  {
      "zipCode": 74932,
      "latitude": 35.126904,
      "longitude": -94.600976,
      "city": "Cameron",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74935,
      "latitude": 34.897962,
      "longitude": -94.980334,
      "city": "Fanshawe",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74936,
      "latitude": 35.394647,
      "longitude": -94.690149,
      "city": "Gans",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74937,
      "latitude": 34.967535,
      "longitude": -94.662657,
      "city": "Heavener",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74939,
      "latitude": 34.849951,
      "longitude": -94.64237,
      "city": "Hodgen",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74940,
      "latitude": 34.946433,
      "longitude": -94.637378,
      "city": "Howe",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74941,
      "latitude": 35.258196,
      "longitude": -95.134492,
      "city": "Keota",
      "state": "OK",
      "county": "Haskell"
  },
  {
      "zipCode": 74942,
      "latitude": 34.897872,
      "longitude": -94.975763,
      "city": "Leflore",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74943,
      "latitude": 35.218128,
      "longitude": -95.118733,
      "city": "Lequire",
      "state": "OK",
      "county": "Haskell"
  },
  {
      "zipCode": 74944,
      "latitude": 35.15159,
      "longitude": -94.966276,
      "city": "Mccurtain",
      "state": "OK",
      "county": "Haskell"
  },
  {
      "zipCode": 74945,
      "latitude": 35.465054,
      "longitude": -94.781885,
      "city": "Marble City",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74946,
      "latitude": 35.389791,
      "longitude": -94.446215,
      "city": "Moffett",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74947,
      "latitude": 34.943627,
      "longitude": -94.745782,
      "city": "Monroe",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74948,
      "latitude": 35.470128,
      "longitude": -94.539715,
      "city": "Muldrow",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74949,
      "latitude": 34.943627,
      "longitude": -94.745782,
      "city": "Muse",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74951,
      "latitude": 35.153493,
      "longitude": -94.670941,
      "city": "Panama",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74953,
      "latitude": 35.16394,
      "longitude": -94.578787,
      "city": "Poteau",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74954,
      "latitude": 35.416163,
      "longitude": -94.52271,
      "city": "Roland",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74955,
      "latitude": 35.450662,
      "longitude": -94.706796,
      "city": "Sallisaw",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74956,
      "latitude": 34.943627,
      "longitude": -94.745782,
      "city": "Shady Point",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74957,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Smithville",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74959,
      "latitude": 35.264931,
      "longitude": -94.55985,
      "city": "Spiro",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 74960,
      "latitude": 35.931689,
      "longitude": -94.606985,
      "city": "Stilwell",
      "state": "OK",
      "county": "Adair"
  },
  {
      "zipCode": 74962,
      "latitude": 35.497703,
      "longitude": -94.968442,
      "city": "Vian",
      "state": "OK",
      "county": "Sequoyah"
  },
  {
      "zipCode": 74963,
      "latitude": 34.061674,
      "longitude": -94.808868,
      "city": "Watson",
      "state": "OK",
      "county": "Mccurtain"
  },
  {
      "zipCode": 74964,
      "latitude": 36.051727,
      "longitude": -94.571431,
      "city": "Watts",
      "state": "OK",
      "county": "Adair"
  },
  {
      "zipCode": 74965,
      "latitude": 35.991414,
      "longitude": -94.570433,
      "city": "Westville",
      "state": "OK",
      "county": "Adair"
  },
  {
      "zipCode": 74966,
      "latitude": 34.967414,
      "longitude": -94.72241,
      "city": "Wister",
      "state": "OK",
      "county": "Le Flore"
  },
  {
      "zipCode": 75001,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Addison",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75002,
      "latitude": 33.208033,
      "longitude": -96.646773,
      "city": "Allen",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75006,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Carrollton",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75007,
      "latitude": 33.007436,
      "longitude": -96.895893,
      "city": "Carrollton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75008,
      "latitude": 33.20743,
      "longitude": -97.116282,
      "city": "Carrollton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75009,
      "latitude": 33.332852,
      "longitude": -96.726103,
      "city": "Celina",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75010,
      "latitude": 33.029268,
      "longitude": -96.901417,
      "city": "Carrollton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75011,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Carrollton",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75013,
      "latitude": 33.118645,
      "longitude": -96.677328,
      "city": "Allen",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75014,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75015,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75016,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75017,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75019,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Coppell",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75020,
      "latitude": 33.76672,
      "longitude": -96.546662,
      "city": "Denison",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75021,
      "latitude": 33.716947,
      "longitude": -96.523482,
      "city": "Denison",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75022,
      "latitude": 33.026795,
      "longitude": -97.119331,
      "city": "Flower Mound",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75023,
      "latitude": 33.104885,
      "longitude": -96.659574,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75024,
      "latitude": 33.096727,
      "longitude": -96.805334,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75025,
      "latitude": 33.107261,
      "longitude": -96.734968,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75026,
      "latitude": 33.19359,
      "longitude": -96.569879,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75027,
      "latitude": 33.20743,
      "longitude": -97.116282,
      "city": "Flower Mound",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75028,
      "latitude": 33.030903,
      "longitude": -97.102543,
      "city": "Flower Mound",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75029,
      "latitude": 33.20743,
      "longitude": -97.116282,
      "city": "Lewisville",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75030,
      "latitude": 32.91747,
      "longitude": -96.534737,
      "city": "Rowlett",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75032,
      "latitude": 32.886,
      "longitude": -96.409502,
      "city": "Rockwall",
      "state": "TX",
      "county": "Rockwall"
  },
  {
      "zipCode": 75034,
      "latitude": 33.152222,
      "longitude": -96.796437,
      "city": "Frisco",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75035,
      "latitude": 33.157083,
      "longitude": -96.772929,
      "city": "Frisco",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75037,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75038,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75039,
      "latitude": 32.73178,
      "longitude": -96.82273,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75040,
      "latitude": 32.931523,
      "longitude": -96.615821,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75041,
      "latitude": 32.856976,
      "longitude": -96.690775,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75042,
      "latitude": 32.829977,
      "longitude": -96.690125,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75043,
      "latitude": 32.876046,
      "longitude": -96.58227,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75044,
      "latitude": 32.952929,
      "longitude": -96.646873,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75045,
      "latitude": 32.913695,
      "longitude": -96.627131,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75046,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75047,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75048,
      "latitude": 32.950372,
      "longitude": -96.575302,
      "city": "Sachse",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75049,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Garland",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75050,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Grand Prairie",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75051,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Grand Prairie",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75052,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Grand Prairie",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75053,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Grand Prairie",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75054,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Grand Prairie",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75056,
      "latitude": 33.077136,
      "longitude": -96.901605,
      "city": "The Colony",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75057,
      "latitude": 33.088471,
      "longitude": -96.977396,
      "city": "Lewisville",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75058,
      "latitude": 33.449135,
      "longitude": -96.709494,
      "city": "Gunter",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75060,
      "latitude": 32.75453,
      "longitude": -96.782279,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75061,
      "latitude": 32.921476,
      "longitude": -96.587847,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75062,
      "latitude": 32.980974,
      "longitude": -96.604557,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75063,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Irving",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75065,
      "latitude": 33.131089,
      "longitude": -97.008563,
      "city": "Lake Dallas",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75067,
      "latitude": 33.047871,
      "longitude": -97.050897,
      "city": "Lewisville",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75068,
      "latitude": 33.17679,
      "longitude": -96.958293,
      "city": "Little Elm",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75069,
      "latitude": 33.141438,
      "longitude": -96.588295,
      "city": "Mc Kinney",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75070,
      "latitude": 33.230381,
      "longitude": -96.627018,
      "city": "Mc Kinney",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75071,
      "latitude": 33.245816,
      "longitude": -96.63072,
      "city": "Mc Kinney",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75074,
      "latitude": 33.109044,
      "longitude": -96.578819,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75075,
      "latitude": 33.162417,
      "longitude": -96.71546,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75076,
      "latitude": 33.817503,
      "longitude": -96.676191,
      "city": "Pottsboro",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75077,
      "latitude": 33.101069,
      "longitude": -97.054032,
      "city": "Lewisville",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 75078,
      "latitude": 33.254191,
      "longitude": -96.78524,
      "city": "Prosper",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75080,
      "latitude": 32.962811,
      "longitude": -96.726826,
      "city": "Richardson",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75081,
      "latitude": 32.949266,
      "longitude": -96.721626,
      "city": "Richardson",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75082,
      "latitude": 32.979315,
      "longitude": -96.69137,
      "city": "Richardson",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75083,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Richardson",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75085,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Richardson",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75086,
      "latitude": 33.002395,
      "longitude": -96.615837,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75087,
      "latitude": 32.897823,
      "longitude": -96.42713,
      "city": "Rockwall",
      "state": "TX",
      "county": "Rockwall"
  },
  {
      "zipCode": 75088,
      "latitude": 32.928105,
      "longitude": -96.555019,
      "city": "Rowlett",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75089,
      "latitude": 32.934572,
      "longitude": -96.554399,
      "city": "Rowlett",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75090,
      "latitude": 33.647425,
      "longitude": -96.659726,
      "city": "Sherman",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75091,
      "latitude": 33.678665,
      "longitude": -96.662289,
      "city": "Sherman",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75092,
      "latitude": 33.637151,
      "longitude": -96.618419,
      "city": "Sherman",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75093,
      "latitude": 33.032674,
      "longitude": -96.805055,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75094,
      "latitude": 33.011889,
      "longitude": -96.643043,
      "city": "Plano",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75097,
      "latitude": 33.351238,
      "longitude": -96.664632,
      "city": "Weston",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75098,
      "latitude": 32.970309,
      "longitude": -96.552397,
      "city": "Wylie",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75099,
      "latitude": 32.77103,
      "longitude": -96.79963,
      "city": "Coppell",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75101,
      "latitude": 32.211518,
      "longitude": -96.736746,
      "city": "Bardwell",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75102,
      "latitude": 32.036797,
      "longitude": -96.666279,
      "city": "Barry",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 75103,
      "latitude": 32.611471,
      "longitude": -95.900705,
      "city": "Canton",
      "state": "TX",
      "county": "Van Zandt"
  },
  {
      "zipCode": 75104,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Cedar Hill",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75105,
      "latitude": 32.243775,
      "longitude": -96.414423,
      "city": "Chatfield",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 75106,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Cedar Hill",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75109,
      "latitude": 32.042784,
      "longitude": -96.343754,
      "city": "Corsicana",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 75110,
      "latitude": 32.059928,
      "longitude": -96.369648,
      "city": "Corsicana",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 75114,
      "latitude": 32.661312,
      "longitude": -96.444347,
      "city": "Crandall",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75115,
      "latitude": 32.620585,
      "longitude": -96.822778,
      "city": "Desoto",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75116,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Duncanville",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75117,
      "latitude": 32.71476,
      "longitude": -95.85635,
      "city": "Edgewood",
      "state": "TX",
      "county": "Van Zandt"
  },
  {
      "zipCode": 75118,
      "latitude": 32.599614,
      "longitude": -96.302743,
      "city": "Elmo",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75119,
      "latitude": 32.333778,
      "longitude": -96.63479,
      "city": "Ennis",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75120,
      "latitude": 32.334709,
      "longitude": -96.633546,
      "city": "Ennis",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75121,
      "latitude": 33.061849,
      "longitude": -96.417438,
      "city": "Copeville",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75123,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Desoto",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75124,
      "latitude": 32.289838,
      "longitude": -95.791559,
      "city": "Eustace",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75125,
      "latitude": 32.494337,
      "longitude": -96.640443,
      "city": "Ferris",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75126,
      "latitude": 32.723931,
      "longitude": -96.450819,
      "city": "Forney",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75127,
      "latitude": 32.685045,
      "longitude": -95.79425,
      "city": "Fruitvale",
      "state": "TX",
      "county": "Van Zandt"
  },
  {
      "zipCode": 75132,
      "latitude": 32.955476,
      "longitude": -96.367821,
      "city": "Fate",
      "state": "TX",
      "county": "Rockwall"
  },
  {
      "zipCode": 75134,
      "latitude": 32.621185,
      "longitude": -96.76442,
      "city": "Lancaster",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75135,
      "latitude": 33.076823,
      "longitude": -96.219803,
      "city": "Caddo Mills",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75137,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Duncanville",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75138,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Duncanville",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75140,
      "latitude": 32.661269,
      "longitude": -95.77607,
      "city": "Grand Saline",
      "state": "TX",
      "county": "Van Zandt"
  },
  {
      "zipCode": 75141,
      "latitude": 32.633257,
      "longitude": -96.689174,
      "city": "Hutchins",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75142,
      "latitude": 32.581306,
      "longitude": -96.265477,
      "city": "Kaufman",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75143,
      "latitude": 32.24862,
      "longitude": -96.216065,
      "city": "Kemp",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75144,
      "latitude": 32.124332,
      "longitude": -96.368686,
      "city": "Kerens",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 75146,
      "latitude": 32.580136,
      "longitude": -96.748525,
      "city": "Lancaster",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75147,
      "latitude": 32.463647,
      "longitude": -96.135356,
      "city": "Mabank",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75148,
      "latitude": 32.146014,
      "longitude": -96.029222,
      "city": "Malakoff",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75149,
      "latitude": 32.777779,
      "longitude": -96.62315,
      "city": "Mesquite",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75150,
      "latitude": 32.790964,
      "longitude": -96.635868,
      "city": "Mesquite",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75151,
      "latitude": 32.062395,
      "longitude": -96.473459,
      "city": "Corsicana",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 75152,
      "latitude": 32.460524,
      "longitude": -96.675575,
      "city": "Palmer",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75153,
      "latitude": 32.165005,
      "longitude": -96.338746,
      "city": "Powell",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 75154,
      "latitude": 32.484383,
      "longitude": -96.795429,
      "city": "Red Oak",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75155,
      "latitude": 32.300757,
      "longitude": -96.735335,
      "city": "Rice",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75157,
      "latitude": 32.455407,
      "longitude": -96.439495,
      "city": "Rosser",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75158,
      "latitude": 32.463618,
      "longitude": -96.384263,
      "city": "Scurry",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75159,
      "latitude": 32.620763,
      "longitude": -96.573555,
      "city": "Seagoville",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75160,
      "latitude": 32.641903,
      "longitude": -96.25138,
      "city": "Terrell",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75161,
      "latitude": 32.733179,
      "longitude": -96.195026,
      "city": "Terrell",
      "state": "TX",
      "county": "Kaufman"
  },
  {
      "zipCode": 75163,
      "latitude": 32.208934,
      "longitude": -96.114682,
      "city": "Trinidad",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75164,
      "latitude": 33.084304,
      "longitude": -96.321337,
      "city": "Josephine",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75165,
      "latitude": 32.376984,
      "longitude": -96.843682,
      "city": "Waxahachie",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75166,
      "latitude": 33.013879,
      "longitude": -96.437656,
      "city": "Lavon",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75167,
      "latitude": 32.377291,
      "longitude": -96.916207,
      "city": "Waxahachie",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75168,
      "latitude": 32.374937,
      "longitude": -96.716638,
      "city": "Waxahachie",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 75169,
      "latitude": 32.695514,
      "longitude": -95.974996,
      "city": "Wills Point",
      "state": "TX",
      "county": "Van Zandt"
  },
  {
      "zipCode": 75172,
      "latitude": 32.603835,
      "longitude": -96.673223,
      "city": "Wilmer",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75173,
      "latitude": 33.073224,
      "longitude": -96.428356,
      "city": "Nevada",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75180,
      "latitude": 32.721225,
      "longitude": -96.615271,
      "city": "Mesquite",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75181,
      "latitude": 32.727857,
      "longitude": -96.564919,
      "city": "Mesquite",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75182,
      "latitude": 32.797048,
      "longitude": -96.561606,
      "city": "Sunnyvale",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75185,
      "latitude": 32.740332,
      "longitude": -96.561846,
      "city": "Mesquite",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75187,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Mesquite",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75189,
      "latitude": 32.919754,
      "longitude": -96.35631,
      "city": "Royse City",
      "state": "TX",
      "county": "Rockwall"
  },
  {
      "zipCode": 75201,
      "latitude": 32.781179,
      "longitude": -96.790329,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75202,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75203,
      "latitude": 32.741481,
      "longitude": -96.795879,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75204,
      "latitude": 32.800028,
      "longitude": -96.781229,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75205,
      "latitude": 32.826028,
      "longitude": -96.784329,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75206,
      "latitude": 32.833881,
      "longitude": -96.771528,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75207,
      "latitude": 32.76313,
      "longitude": -96.798372,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75208,
      "latitude": 32.736344,
      "longitude": -96.82303,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75209,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75210,
      "latitude": 32.769479,
      "longitude": -96.750478,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75211,
      "latitude": 32.736481,
      "longitude": -96.835013,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75212,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75214,
      "latitude": 32.841252,
      "longitude": -96.698402,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75215,
      "latitude": 32.75368,
      "longitude": -96.76587,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75216,
      "latitude": 32.682883,
      "longitude": -96.779278,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75217,
      "latitude": 32.702531,
      "longitude": -96.684525,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75218,
      "latitude": 32.838526,
      "longitude": -96.699475,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75219,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75220,
      "latitude": 32.778412,
      "longitude": -96.806376,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75221,
      "latitude": 32.814728,
      "longitude": -96.787725,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75222,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75223,
      "latitude": 32.794228,
      "longitude": -96.749578,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75224,
      "latitude": 32.710281,
      "longitude": -96.82623,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75225,
      "latitude": 32.851127,
      "longitude": -96.771829,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75226,
      "latitude": 32.783879,
      "longitude": -96.775578,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75227,
      "latitude": 32.773128,
      "longitude": -96.685609,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75228,
      "latitude": 32.832277,
      "longitude": -96.682075,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75229,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75230,
      "latitude": 32.886576,
      "longitude": -96.769578,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75231,
      "latitude": 32.880025,
      "longitude": -96.740103,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75232,
      "latitude": 32.652934,
      "longitude": -96.820845,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75233,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75234,
      "latitude": 32.909545,
      "longitude": -96.819289,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75235,
      "latitude": 32.692682,
      "longitude": -96.623022,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75236,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75237,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75238,
      "latitude": 32.878923,
      "longitude": -96.704725,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75239,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75240,
      "latitude": 32.910185,
      "longitude": -96.789191,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75241,
      "latitude": 32.660114,
      "longitude": -96.759227,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75242,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75243,
      "latitude": 32.777979,
      "longitude": -96.733826,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75244,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75245,
      "latitude": 32.922499,
      "longitude": -96.535191,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75246,
      "latitude": 32.793328,
      "longitude": -96.773179,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75247,
      "latitude": 32.660247,
      "longitude": -96.752254,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75248,
      "latitude": 32.986006,
      "longitude": -96.767999,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75249,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75250,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75251,
      "latitude": 32.895102,
      "longitude": -96.734178,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75252,
      "latitude": 32.986259,
      "longitude": -96.768049,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75253,
      "latitude": 32.672132,
      "longitude": -96.60847,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75254,
      "latitude": 32.944116,
      "longitude": -96.80009,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75258,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75260,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75261,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75262,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75263,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75264,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75265,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75266,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75267,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75270,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75275,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75277,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75283,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75284,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75285,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75286,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75287,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75295,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75301,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75303,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75310,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75312,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75313,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75315,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75320,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75323,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75326,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75336,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75339,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75342,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75346,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75353,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75354,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75355,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75356,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75357,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75359,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75360,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75363,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75364,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75367,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75368,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75370,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75371,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75372,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75373,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75374,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75376,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75378,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75379,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75380,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75381,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75382,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75386,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75387,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75388,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75389,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75390,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75391,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75392,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75393,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75394,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75395,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75396,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75397,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75398,
      "latitude": 32.767268,
      "longitude": -96.777626,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": 75401,
      "latitude": 33.104859,
      "longitude": -96.115491,
      "city": "Greenville",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75402,
      "latitude": 33.104707,
      "longitude": -96.092747,
      "city": "Greenville",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75403,
      "latitude": 33.218505,
      "longitude": -96.048665,
      "city": "Greenville",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75404,
      "latitude": 33.056265,
      "longitude": -96.080973,
      "city": "Greenville",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75407,
      "latitude": 33.152612,
      "longitude": -96.47527,
      "city": "Princeton",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75409,
      "latitude": 33.323709,
      "longitude": -96.524804,
      "city": "Anna",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75410,
      "latitude": 32.815881,
      "longitude": -95.705636,
      "city": "Alba",
      "state": "TX",
      "county": "Wood"
  },
  {
      "zipCode": 75411,
      "latitude": 33.65973,
      "longitude": -95.582906,
      "city": "Arthur City",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75412,
      "latitude": 33.639683,
      "longitude": -95.021683,
      "city": "Bagwell",
      "state": "TX",
      "county": "Red River"
  },
  {
      "zipCode": 75413,
      "latitude": 33.416579,
      "longitude": -96.213438,
      "city": "Bailey",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75414,
      "latitude": 33.623738,
      "longitude": -96.442444,
      "city": "Bells",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75415,
      "latitude": 33.356865,
      "longitude": -95.584162,
      "city": "Ben Franklin",
      "state": "TX",
      "county": "Delta"
  },
  {
      "zipCode": 75416,
      "latitude": 33.600079,
      "longitude": -95.520367,
      "city": "Blossom",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75417,
      "latitude": 33.470494,
      "longitude": -95.211712,
      "city": "Bogata",
      "state": "TX",
      "county": "Red River"
  },
  {
      "zipCode": 75418,
      "latitude": 33.527267,
      "longitude": -96.172998,
      "city": "Bonham",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75420,
      "latitude": 33.168606,
      "longitude": -95.585464,
      "city": "Brashear",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75421,
      "latitude": 33.655316,
      "longitude": -95.646417,
      "city": "Brookston",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75422,
      "latitude": 33.12254,
      "longitude": -95.934428,
      "city": "Campbell",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75423,
      "latitude": 33.23896,
      "longitude": -96.175726,
      "city": "Celeste",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75424,
      "latitude": 33.318638,
      "longitude": -96.396186,
      "city": "Blue Ridge",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75425,
      "latitude": 33.65973,
      "longitude": -95.582906,
      "city": "Chicota",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75426,
      "latitude": 33.61306,
      "longitude": -95.096511,
      "city": "Clarksville",
      "state": "TX",
      "county": "Red River"
  },
  {
      "zipCode": 75428,
      "latitude": 33.289045,
      "longitude": -95.976965,
      "city": "Commerce",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75429,
      "latitude": 33.237722,
      "longitude": -95.90886,
      "city": "Commerce",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75431,
      "latitude": 33.095582,
      "longitude": -95.656717,
      "city": "Como",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75432,
      "latitude": 33.344751,
      "longitude": -95.74361,
      "city": "Cooper",
      "state": "TX",
      "county": "Delta"
  },
  {
      "zipCode": 75433,
      "latitude": 33.136079,
      "longitude": -95.844353,
      "city": "Cumby",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75434,
      "latitude": 33.65973,
      "longitude": -95.582906,
      "city": "Cunningham",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75435,
      "latitude": 33.530359,
      "longitude": -95.318244,
      "city": "Deport",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75436,
      "latitude": 33.664645,
      "longitude": -95.262837,
      "city": "Detroit",
      "state": "TX",
      "county": "Red River"
  },
  {
      "zipCode": 75437,
      "latitude": 33.168606,
      "longitude": -95.585464,
      "city": "Dike",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75438,
      "latitude": 33.57047,
      "longitude": -96.054433,
      "city": "Dodd City",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75439,
      "latitude": 33.575694,
      "longitude": -96.138464,
      "city": "Ector",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75440,
      "latitude": 32.834006,
      "longitude": -95.724069,
      "city": "Emory",
      "state": "TX",
      "county": "Rains"
  },
  {
      "zipCode": 75441,
      "latitude": 33.356865,
      "longitude": -95.584162,
      "city": "Enloe",
      "state": "TX",
      "county": "Delta"
  },
  {
      "zipCode": 75442,
      "latitude": 33.218463,
      "longitude": -96.379009,
      "city": "Farmersville",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75443,
      "latitude": 33.469719,
      "longitude": -96.111103,
      "city": "Gober",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75444,
      "latitude": 32.777228,
      "longitude": -95.407675,
      "city": "Golden",
      "state": "TX",
      "county": "Wood"
  },
  {
      "zipCode": 75446,
      "latitude": 33.488056,
      "longitude": -96.085924,
      "city": "Honey Grove",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75447,
      "latitude": 33.58203,
      "longitude": -95.921449,
      "city": "Ivanhoe",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75448,
      "latitude": 33.356865,
      "longitude": -95.584162,
      "city": "Klondike",
      "state": "TX",
      "county": "Delta"
  },
  {
      "zipCode": 75449,
      "latitude": 33.494184,
      "longitude": -96.150371,
      "city": "Ladonia",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75450,
      "latitude": 33.356865,
      "longitude": -95.584162,
      "city": "Lake Creek",
      "state": "TX",
      "county": "Delta"
  },
  {
      "zipCode": 75451,
      "latitude": 32.989797,
      "longitude": -94.936022,
      "city": "Leesburg",
      "state": "TX",
      "county": "Camp"
  },
  {
      "zipCode": 75452,
      "latitude": 33.41874,
      "longitude": -96.238027,
      "city": "Leonard",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75453,
      "latitude": 33.012331,
      "longitude": -95.933534,
      "city": "Lone Oak",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75454,
      "latitude": 33.283706,
      "longitude": -96.53316,
      "city": "Melissa",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75455,
      "latitude": 33.22101,
      "longitude": -94.984645,
      "city": "Mount Pleasant",
      "state": "TX",
      "county": "Titus"
  },
  {
      "zipCode": 75456,
      "latitude": 33.19045,
      "longitude": -94.967456,
      "city": "Mount Pleasant",
      "state": "TX",
      "county": "Titus"
  },
  {
      "zipCode": 75457,
      "latitude": 33.15396,
      "longitude": -95.217812,
      "city": "Mount Vernon",
      "state": "TX",
      "county": "Franklin"
  },
  {
      "zipCode": 75458,
      "latitude": 33.242656,
      "longitude": -96.291572,
      "city": "Merit",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75459,
      "latitude": 33.535538,
      "longitude": -96.655494,
      "city": "Howe",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75460,
      "latitude": 33.612433,
      "longitude": -95.464896,
      "city": "Paris",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75461,
      "latitude": 33.663213,
      "longitude": -95.460797,
      "city": "Paris",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75462,
      "latitude": 33.680451,
      "longitude": -95.490539,
      "city": "Paris",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75468,
      "latitude": 33.621656,
      "longitude": -95.466406,
      "city": "Pattonville",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75469,
      "latitude": 33.442173,
      "longitude": -95.851141,
      "city": "Pecan Gap",
      "state": "TX",
      "county": "Delta"
  },
  {
      "zipCode": 75470,
      "latitude": 33.65973,
      "longitude": -95.582906,
      "city": "Petty",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75471,
      "latitude": 33.168606,
      "longitude": -95.585464,
      "city": "Pickton",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75472,
      "latitude": 32.917861,
      "longitude": -95.898171,
      "city": "Point",
      "state": "TX",
      "county": "Rains"
  },
  {
      "zipCode": 75473,
      "latitude": 33.792741,
      "longitude": -95.522757,
      "city": "Powderly",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75474,
      "latitude": 32.934537,
      "longitude": -96.121946,
      "city": "Quinlan",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75475,
      "latitude": 33.484594,
      "longitude": -96.254127,
      "city": "Randolph",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75476,
      "latitude": 33.670261,
      "longitude": -96.276105,
      "city": "Ravenna",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75477,
      "latitude": 33.544969,
      "longitude": -95.721734,
      "city": "Roxton",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75478,
      "latitude": 33.168606,
      "longitude": -95.585464,
      "city": "Saltillo",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75479,
      "latitude": 33.555782,
      "longitude": -96.27085,
      "city": "Savoy",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75480,
      "latitude": 33.047785,
      "longitude": -95.196153,
      "city": "Scroggins",
      "state": "TX",
      "county": "Franklin"
  },
  {
      "zipCode": 75481,
      "latitude": 33.168606,
      "longitude": -95.585464,
      "city": "Sulphur Bluff",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75482,
      "latitude": 33.126435,
      "longitude": -95.600838,
      "city": "Sulphur Springs",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75483,
      "latitude": 33.168606,
      "longitude": -95.585464,
      "city": "Sulphur Springs",
      "state": "TX",
      "county": "Hopkins"
  },
  {
      "zipCode": 75485,
      "latitude": 33.361524,
      "longitude": -96.467617,
      "city": "Westminster",
      "state": "TX",
      "county": "Collin"
  },
  {
      "zipCode": 75486,
      "latitude": 33.65973,
      "longitude": -95.582906,
      "city": "Sumner",
      "state": "TX",
      "county": "Lamar"
  },
  {
      "zipCode": 75487,
      "latitude": 33.175662,
      "longitude": -95.216724,
      "city": "Talco",
      "state": "TX",
      "county": "Franklin"
  },
  {
      "zipCode": 75488,
      "latitude": 33.613994,
      "longitude": -96.114265,
      "city": "Telephone",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75489,
      "latitude": 33.529674,
      "longitude": -96.483563,
      "city": "Tom Bean",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75490,
      "latitude": 33.413778,
      "longitude": -96.30401,
      "city": "Trenton",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75491,
      "latitude": 33.502316,
      "longitude": -96.446845,
      "city": "Whitewright",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75492,
      "latitude": 33.563367,
      "longitude": -96.000216,
      "city": "Windom",
      "state": "TX",
      "county": "Fannin"
  },
  {
      "zipCode": 75493,
      "latitude": 33.166579,
      "longitude": -95.109,
      "city": "Winfield",
      "state": "TX",
      "county": "Titus"
  },
  {
      "zipCode": 75494,
      "latitude": 32.944701,
      "longitude": -95.306215,
      "city": "Winnsboro",
      "state": "TX",
      "county": "Wood"
  },
  {
      "zipCode": 75495,
      "latitude": 33.541018,
      "longitude": -96.6356,
      "city": "Van Alstyne",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 75496,
      "latitude": 33.32576,
      "longitude": -96.056153,
      "city": "Wolfe City",
      "state": "TX",
      "county": "Hunt"
  },
  {
      "zipCode": 75497,
      "latitude": 32.891514,
      "longitude": -95.579297,
      "city": "Yantis",
      "state": "TX",
      "county": "Wood"
  },
  {
      "zipCode": 75501,
      "latitude": 33.411321,
      "longitude": -94.177354,
      "city": "Texarkana",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75503,
      "latitude": 33.464376,
      "longitude": -94.116906,
      "city": "Texarkana",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75504,
      "latitude": 33.476552,
      "longitude": -94.39495,
      "city": "Texarkana",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75505,
      "latitude": 33.46238,
      "longitude": -94.0715,
      "city": "Texarkana",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75507,
      "latitude": 33.393447,
      "longitude": -94.340437,
      "city": "Texarkana",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75550,
      "latitude": 33.582918,
      "longitude": -94.915595,
      "city": "Annona",
      "state": "TX",
      "county": "Red River"
  },
  {
      "zipCode": 75551,
      "latitude": 33.08447,
      "longitude": -94.242023,
      "city": "Atlanta",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75554,
      "latitude": 33.551468,
      "longitude": -94.772766,
      "city": "Avery",
      "state": "TX",
      "county": "Red River"
  },
  {
      "zipCode": 75555,
      "latitude": 33.007551,
      "longitude": -94.074752,
      "city": "Bivins",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75556,
      "latitude": 33.095778,
      "longitude": -94.348359,
      "city": "Bloomburg",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75558,
      "latitude": 33.19045,
      "longitude": -94.967456,
      "city": "Cookville",
      "state": "TX",
      "county": "Titus"
  },
  {
      "zipCode": 75559,
      "latitude": 33.506028,
      "longitude": -94.589575,
      "city": "De Kalb",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75560,
      "latitude": 33.095778,
      "longitude": -94.348359,
      "city": "Douglassville",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75561,
      "latitude": 33.407128,
      "longitude": -94.337772,
      "city": "Hooks",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75562,
      "latitude": 33.008643,
      "longitude": -94.361886,
      "city": "Kildare",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75563,
      "latitude": 33.069718,
      "longitude": -94.264576,
      "city": "Linden",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75564,
      "latitude": 32.795866,
      "longitude": -94.28917,
      "city": "Lodi",
      "state": "TX",
      "county": "Marion"
  },
  {
      "zipCode": 75565,
      "latitude": 33.095778,
      "longitude": -94.348359,
      "city": "Mc Leod",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75566,
      "latitude": 33.095778,
      "longitude": -94.348359,
      "city": "Marietta",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75567,
      "latitude": 33.340864,
      "longitude": -94.317527,
      "city": "Maud",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75568,
      "latitude": 33.164839,
      "longitude": -94.686156,
      "city": "Naples",
      "state": "TX",
      "county": "Morris"
  },
  {
      "zipCode": 75569,
      "latitude": 33.406372,
      "longitude": -94.223069,
      "city": "Nash",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75570,
      "latitude": 33.421142,
      "longitude": -94.372021,
      "city": "New Boston",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75571,
      "latitude": 33.179537,
      "longitude": -94.739141,
      "city": "Omaha",
      "state": "TX",
      "county": "Morris"
  },
  {
      "zipCode": 75572,
      "latitude": 33.155715,
      "longitude": -94.153684,
      "city": "Queen City",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75573,
      "latitude": 33.357968,
      "longitude": -94.25707,
      "city": "Redwater",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75574,
      "latitude": 33.476552,
      "longitude": -94.39495,
      "city": "Simms",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75599,
      "latitude": 33.476552,
      "longitude": -94.39495,
      "city": "Texarkana",
      "state": "TX",
      "county": "Bowie"
  },
  {
      "zipCode": 75601,
      "latitude": 32.517846,
      "longitude": -94.730285,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75602,
      "latitude": 32.434239,
      "longitude": -94.747488,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75603,
      "latitude": 32.419782,
      "longitude": -94.690041,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75604,
      "latitude": 32.493577,
      "longitude": -94.827415,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75605,
      "latitude": 32.505708,
      "longitude": -94.718839,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75606,
      "latitude": 32.369393,
      "longitude": -94.616062,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75607,
      "latitude": 32.51121,
      "longitude": -94.783493,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75608,
      "latitude": 32.570051,
      "longitude": -94.848063,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75615,
      "latitude": 32.51121,
      "longitude": -94.783493,
      "city": "Longview",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75630,
      "latitude": 32.895799,
      "longitude": -94.574658,
      "city": "Avinger",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75631,
      "latitude": 32.233362,
      "longitude": -94.456952,
      "city": "Beckville",
      "state": "TX",
      "county": "Panola"
  },
  {
      "zipCode": 75633,
      "latitude": 32.183133,
      "longitude": -94.309189,
      "city": "Carthage",
      "state": "TX",
      "county": "Panola"
  },
  {
      "zipCode": 75636,
      "latitude": 33.122567,
      "longitude": -94.735972,
      "city": "Cason",
      "state": "TX",
      "county": "Morris"
  },
  {
      "zipCode": 75637,
      "latitude": 32.102729,
      "longitude": -94.493543,
      "city": "Clayton",
      "state": "TX",
      "county": "Panola"
  },
  {
      "zipCode": 75638,
      "latitude": 33.044611,
      "longitude": -94.739524,
      "city": "Daingerfield",
      "state": "TX",
      "county": "Morris"
  },
  {
      "zipCode": 75639,
      "latitude": 32.259146,
      "longitude": -94.21128,
      "city": "De Berry",
      "state": "TX",
      "county": "Panola"
  },
  {
      "zipCode": 75640,
      "latitude": 32.769819,
      "longitude": -94.703213,
      "city": "Diana",
      "state": "TX",
      "county": "Upshur"
  },
  {
      "zipCode": 75641,
      "latitude": 32.51121,
      "longitude": -94.783493,
      "city": "Easton",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75642,
      "latitude": 32.386275,
      "longitude": -94.210541,
      "city": "Elysian Fields",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75643,
      "latitude": 32.033446,
      "longitude": -94.380023,
      "city": "Gary",
      "state": "TX",
      "county": "Panola"
  },
  {
      "zipCode": 75644,
      "latitude": 32.712654,
      "longitude": -94.909365,
      "city": "Gilmer",
      "state": "TX",
      "county": "Upshur"
  },
  {
      "zipCode": 75645,
      "latitude": 32.67875,
      "longitude": -94.879548,
      "city": "Gilmer",
      "state": "TX",
      "county": "Upshur"
  },
  {
      "zipCode": 75647,
      "latitude": 32.477189,
      "longitude": -94.9327,
      "city": "Gladewater",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75650,
      "latitude": 32.526928,
      "longitude": -94.573183,
      "city": "Hallsville",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75651,
      "latitude": 32.676919,
      "longitude": -94.531537,
      "city": "Harleton",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75652,
      "latitude": 32.213061,
      "longitude": -94.783436,
      "city": "Henderson",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75653,
      "latitude": 32.204724,
      "longitude": -94.8845,
      "city": "Henderson",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75654,
      "latitude": 32.125958,
      "longitude": -94.748825,
      "city": "Henderson",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75656,
      "latitude": 32.953226,
      "longitude": -94.599193,
      "city": "Hughes Springs",
      "state": "TX",
      "county": "Cass"
  },
  {
      "zipCode": 75657,
      "latitude": 32.805853,
      "longitude": -94.365546,
      "city": "Jefferson",
      "state": "TX",
      "county": "Marion"
  },
  {
      "zipCode": 75658,
      "latitude": 32.195927,
      "longitude": -94.906515,
      "city": "Joinerville",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75659,
      "latitude": 32.507491,
      "longitude": -94.110636,
      "city": "Jonesville",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75660,
      "latitude": 32.51121,
      "longitude": -94.783493,
      "city": "Judson",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75661,
      "latitude": 32.65575,
      "longitude": -94.154735,
      "city": "Karnack",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75662,
      "latitude": 32.410306,
      "longitude": -94.793392,
      "city": "Kilgore",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75663,
      "latitude": 32.386976,
      "longitude": -94.895098,
      "city": "Kilgore",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75666,
      "latitude": 32.301898,
      "longitude": -94.928795,
      "city": "Laird Hill",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75667,
      "latitude": 31.986546,
      "longitude": -94.859558,
      "city": "Laneville",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75668,
      "latitude": 32.925231,
      "longitude": -94.704906,
      "city": "Lone Star",
      "state": "TX",
      "county": "Morris"
  },
  {
      "zipCode": 75669,
      "latitude": 32.044916,
      "longitude": -94.538115,
      "city": "Long Branch",
      "state": "TX",
      "county": "Panola"
  },
  {
      "zipCode": 75670,
      "latitude": 32.538112,
      "longitude": -94.406228,
      "city": "Marshall",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75671,
      "latitude": 32.522659,
      "longitude": -94.389476,
      "city": "Marshall",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75672,
      "latitude": 32.516507,
      "longitude": -94.32511,
      "city": "Marshall",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75680,
      "latitude": 32.125654,
      "longitude": -94.719095,
      "city": "Minden",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75681,
      "latitude": 31.966905,
      "longitude": -94.687331,
      "city": "Mount Enterprise",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75682,
      "latitude": 32.25416,
      "longitude": -94.932215,
      "city": "New London",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75683,
      "latitude": 32.832441,
      "longitude": -94.771016,
      "city": "Ore City",
      "state": "TX",
      "county": "Upshur"
  },
  {
      "zipCode": 75684,
      "latitude": 32.133478,
      "longitude": -94.83149,
      "city": "Overton",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75685,
      "latitude": 32.183774,
      "longitude": -94.308713,
      "city": "Panola",
      "state": "TX",
      "county": "Panola"
  },
  {
      "zipCode": 75686,
      "latitude": 32.998041,
      "longitude": -94.952278,
      "city": "Pittsburg",
      "state": "TX",
      "county": "Camp"
  },
  {
      "zipCode": 75687,
      "latitude": 32.151755,
      "longitude": -94.955432,
      "city": "Price",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75688,
      "latitude": 32.55397,
      "longitude": -94.239351,
      "city": "Scottsville",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75689,
      "latitude": 32.1826,
      "longitude": -94.935456,
      "city": "Selman City",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75691,
      "latitude": 32.301179,
      "longitude": -94.742539,
      "city": "Tatum",
      "state": "TX",
      "county": "Rusk"
  },
  {
      "zipCode": 75692,
      "latitude": 32.518392,
      "longitude": -94.135507,
      "city": "Waskom",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75693,
      "latitude": 32.538299,
      "longitude": -94.862219,
      "city": "White Oak",
      "state": "TX",
      "county": "Gregg"
  },
  {
      "zipCode": 75694,
      "latitude": 32.653731,
      "longitude": -94.342725,
      "city": "Woodlawn",
      "state": "TX",
      "county": "Harrison"
  },
  {
      "zipCode": 75701,
      "latitude": 32.288029,
      "longitude": -95.200403,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75702,
      "latitude": 32.309852,
      "longitude": -95.203647,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75703,
      "latitude": 32.230847,
      "longitude": -95.340504,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75704,
      "latitude": 32.385204,
      "longitude": -95.407365,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75705,
      "latitude": 32.350251,
      "longitude": -95.134664,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75706,
      "latitude": 32.464496,
      "longitude": -95.31477,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75707,
      "latitude": 32.286869,
      "longitude": -95.1511,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75708,
      "latitude": 32.41896,
      "longitude": -95.210564,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75709,
      "latitude": 32.315983,
      "longitude": -95.380577,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75710,
      "latitude": 32.347549,
      "longitude": -95.306528,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75711,
      "latitude": 32.539879,
      "longitude": -95.419983,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75712,
      "latitude": 32.411237,
      "longitude": -95.289903,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75713,
      "latitude": 32.411237,
      "longitude": -95.289903,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75750,
      "latitude": 32.27795,
      "longitude": -95.067302,
      "city": "Arp",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75751,
      "latitude": 32.188097,
      "longitude": -95.882391,
      "city": "Athens",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75752,
      "latitude": 32.251937,
      "longitude": -95.799861,
      "city": "Athens",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75754,
      "latitude": 32.423029,
      "longitude": -95.712867,
      "city": "Ben Wheeler",
      "state": "TX",
      "county": "Van Zandt"
  },
  {
      "zipCode": 75755,
      "latitude": 32.571552,
      "longitude": -95.057746,
      "city": "Big Sandy",
      "state": "TX",
      "county": "Upshur"
  },
  {
      "zipCode": 75756,
      "latitude": 32.232783,
      "longitude": -95.575732,
      "city": "Brownsboro",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75757,
      "latitude": 32.177304,
      "longitude": -95.254306,
      "city": "Bullard",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75758,
      "latitude": 32.257185,
      "longitude": -95.539252,
      "city": "Chandler",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75759,
      "latitude": 31.873255,
      "longitude": -95.228829,
      "city": "Cuney",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75760,
      "latitude": 31.714047,
      "longitude": -94.841773,
      "city": "Cushing",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75762,
      "latitude": 32.226635,
      "longitude": -95.390166,
      "city": "Flint",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75763,
      "latitude": 31.992987,
      "longitude": -95.499133,
      "city": "Frankston",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75764,
      "latitude": 31.88493,
      "longitude": -95.157159,
      "city": "Gallatin",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75765,
      "latitude": 32.648003,
      "longitude": -95.23372,
      "city": "Hawkins",
      "state": "TX",
      "county": "Wood"
  },
  {
      "zipCode": 75766,
      "latitude": 31.865305,
      "longitude": -95.155683,
      "city": "Jacksonville",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75770,
      "latitude": 32.127602,
      "longitude": -95.641089,
      "city": "Larue",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75771,
      "latitude": 32.506185,
      "longitude": -95.400598,
      "city": "Lindale",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75772,
      "latitude": 31.800846,
      "longitude": -95.300142,
      "city": "Maydelle",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75773,
      "latitude": 32.713422,
      "longitude": -95.485939,
      "city": "Mineola",
      "state": "TX",
      "county": "Wood"
  },
  {
      "zipCode": 75778,
      "latitude": 32.293384,
      "longitude": -95.742334,
      "city": "Murchison",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75779,
      "latitude": 31.794191,
      "longitude": -95.661964,
      "city": "Neches",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75780,
      "latitude": 31.782048,
      "longitude": -95.164021,
      "city": "New Summerfield",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75782,
      "latitude": 32.194199,
      "longitude": -95.548056,
      "city": "Poynor",
      "state": "TX",
      "county": "Henderson"
  },
  {
      "zipCode": 75783,
      "latitude": 32.782264,
      "longitude": -95.44337,
      "city": "Quitman",
      "state": "TX",
      "county": "Wood"
  },
  {
      "zipCode": 75784,
      "latitude": 31.86904,
      "longitude": -94.995364,
      "city": "Reklaw",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75785,
      "latitude": 31.813578,
      "longitude": -95.0965,
      "city": "Rusk",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75788,
      "latitude": 31.825389,
      "longitude": -94.918855,
      "city": "Sacul",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75789,
      "latitude": 32.2477,
      "longitude": -95.17896,
      "city": "Troup",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75790,
      "latitude": 32.543649,
      "longitude": -95.679211,
      "city": "Van",
      "state": "TX",
      "county": "Van Zandt"
  },
  {
      "zipCode": 75791,
      "latitude": 32.219518,
      "longitude": -95.217652,
      "city": "Whitehouse",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75792,
      "latitude": 32.51931,
      "longitude": -95.283799,
      "city": "Winona",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75798,
      "latitude": 32.411237,
      "longitude": -95.289903,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75799,
      "latitude": 32.411237,
      "longitude": -95.289903,
      "city": "Tyler",
      "state": "TX",
      "county": "Smith"
  },
  {
      "zipCode": 75801,
      "latitude": 31.848542,
      "longitude": -95.685179,
      "city": "Palestine",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75802,
      "latitude": 31.926836,
      "longitude": -95.579561,
      "city": "Palestine",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75803,
      "latitude": 31.881777,
      "longitude": -95.679494,
      "city": "Palestine",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75831,
      "latitude": 31.412142,
      "longitude": -95.990369,
      "city": "Buffalo",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 75832,
      "latitude": 31.794191,
      "longitude": -95.661964,
      "city": "Cayuga",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75833,
      "latitude": 31.363103,
      "longitude": -95.899965,
      "city": "Centerville",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 75834,
      "latitude": 31.105786,
      "longitude": -95.138794,
      "city": "Centralia",
      "state": "TX",
      "county": "Trinity"
  },
  {
      "zipCode": 75835,
      "latitude": 31.320814,
      "longitude": -95.392816,
      "city": "Crockett",
      "state": "TX",
      "county": "Houston"
  },
  {
      "zipCode": 75838,
      "latitude": 31.595644,
      "longitude": -96.075096,
      "city": "Donie",
      "state": "TX",
      "county": "Freestone"
  },
  {
      "zipCode": 75839,
      "latitude": 31.64803,
      "longitude": -95.555052,
      "city": "Elkhart",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75840,
      "latitude": 31.767543,
      "longitude": -96.138094,
      "city": "Fairfield",
      "state": "TX",
      "county": "Freestone"
  },
  {
      "zipCode": 75844,
      "latitude": 31.495931,
      "longitude": -95.468798,
      "city": "Grapeland",
      "state": "TX",
      "county": "Houston"
  },
  {
      "zipCode": 75845,
      "latitude": 31.062102,
      "longitude": -95.142669,
      "city": "Groveton",
      "state": "TX",
      "county": "Trinity"
  },
  {
      "zipCode": 75846,
      "latitude": 31.304025,
      "longitude": -96.16864,
      "city": "Jewett",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 75847,
      "latitude": 31.361995,
      "longitude": -95.182216,
      "city": "Kennard",
      "state": "TX",
      "county": "Houston"
  },
  {
      "zipCode": 75848,
      "latitude": 31.847652,
      "longitude": -96.280967,
      "city": "Kirvin",
      "state": "TX",
      "county": "Freestone"
  },
  {
      "zipCode": 75849,
      "latitude": 31.25937,
      "longitude": -95.367485,
      "city": "Latexo",
      "state": "TX",
      "county": "Houston"
  },
  {
      "zipCode": 75850,
      "latitude": 31.189891,
      "longitude": -95.901284,
      "city": "Leona",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 75851,
      "latitude": 31.136667,
      "longitude": -95.476903,
      "city": "Lovelady",
      "state": "TX",
      "county": "Houston"
  },
  {
      "zipCode": 75852,
      "latitude": 31.009503,
      "longitude": -95.772458,
      "city": "Midway",
      "state": "TX",
      "county": "Madison"
  },
  {
      "zipCode": 75853,
      "latitude": 31.794191,
      "longitude": -95.661964,
      "city": "Montalba",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75855,
      "latitude": 31.478777,
      "longitude": -95.866174,
      "city": "Oakwood",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 75856,
      "latitude": 31.105786,
      "longitude": -95.138794,
      "city": "Pennington",
      "state": "TX",
      "county": "Trinity"
  },
  {
      "zipCode": 75858,
      "latitude": 31.25937,
      "longitude": -95.367485,
      "city": "Ratcliff",
      "state": "TX",
      "county": "Houston"
  },
  {
      "zipCode": 75859,
      "latitude": 31.837751,
      "longitude": -96.23408,
      "city": "Streetman",
      "state": "TX",
      "county": "Freestone"
  },
  {
      "zipCode": 75860,
      "latitude": 31.627713,
      "longitude": -96.21236,
      "city": "Teague",
      "state": "TX",
      "county": "Freestone"
  },
  {
      "zipCode": 75861,
      "latitude": 31.802787,
      "longitude": -95.84481,
      "city": "Tennessee Colony",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75862,
      "latitude": 30.910161,
      "longitude": -95.312168,
      "city": "Trinity",
      "state": "TX",
      "county": "Trinity"
  },
  {
      "zipCode": 75865,
      "latitude": 31.105786,
      "longitude": -95.138794,
      "city": "Woodlake",
      "state": "TX",
      "county": "Trinity"
  },
  {
      "zipCode": 75880,
      "latitude": 31.794191,
      "longitude": -95.661964,
      "city": "Tennessee Colony",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75882,
      "latitude": 31.794191,
      "longitude": -95.661964,
      "city": "Palestine",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75884,
      "latitude": 31.794191,
      "longitude": -95.661964,
      "city": "Tennessee Colony",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75886,
      "latitude": 31.794191,
      "longitude": -95.661964,
      "city": "Tennessee Colony",
      "state": "TX",
      "county": "Anderson"
  },
  {
      "zipCode": 75901,
      "latitude": 31.276467,
      "longitude": -94.508447,
      "city": "Lufkin",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75902,
      "latitude": 31.362315,
      "longitude": -94.761103,
      "city": "Lufkin",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75903,
      "latitude": 31.276647,
      "longitude": -94.56759,
      "city": "Lufkin",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75904,
      "latitude": 31.335094,
      "longitude": -94.703921,
      "city": "Lufkin",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75915,
      "latitude": 31.287257,
      "longitude": -94.577084,
      "city": "Lufkin",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75925,
      "latitude": 31.806668,
      "longitude": -95.142971,
      "city": "Alto",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75926,
      "latitude": 31.105786,
      "longitude": -95.138794,
      "city": "Apple Springs",
      "state": "TX",
      "county": "Trinity"
  },
  {
      "zipCode": 75928,
      "latitude": 30.714636,
      "longitude": -93.709628,
      "city": "Bon Wier",
      "state": "TX",
      "county": "Newton"
  },
  {
      "zipCode": 75929,
      "latitude": 31.418078,
      "longitude": -94.182373,
      "city": "Broaddus",
      "state": "TX",
      "county": "San Augustine"
  },
  {
      "zipCode": 75930,
      "latitude": 31.373062,
      "longitude": -93.821948,
      "city": "Bronson",
      "state": "TX",
      "county": "Sabine"
  },
  {
      "zipCode": 75931,
      "latitude": 31.248984,
      "longitude": -93.757415,
      "city": "Brookeland",
      "state": "TX",
      "county": "Sabine"
  },
  {
      "zipCode": 75932,
      "latitude": 31.147913,
      "longitude": -93.612413,
      "city": "Burkeville",
      "state": "TX",
      "county": "Newton"
  },
  {
      "zipCode": 75933,
      "latitude": 30.714636,
      "longitude": -93.709628,
      "city": "Call",
      "state": "TX",
      "county": "Newton"
  },
  {
      "zipCode": 75934,
      "latitude": 30.817866,
      "longitude": -94.869052,
      "city": "Camden",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 75935,
      "latitude": 31.780781,
      "longitude": -94.235334,
      "city": "Center",
      "state": "TX",
      "county": "Shelby"
  },
  {
      "zipCode": 75936,
      "latitude": 30.766328,
      "longitude": -94.463387,
      "city": "Chester",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 75937,
      "latitude": 31.486578,
      "longitude": -94.348665,
      "city": "Chireno",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75938,
      "latitude": 30.900651,
      "longitude": -94.391575,
      "city": "Colmesneil",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 75939,
      "latitude": 31.040793,
      "longitude": -94.812363,
      "city": "Corrigan",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 75941,
      "latitude": 31.221634,
      "longitude": -94.593924,
      "city": "Diboll",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75942,
      "latitude": 30.808063,
      "longitude": -94.427902,
      "city": "Doucette",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 75943,
      "latitude": 31.622983,
      "longitude": -94.851213,
      "city": "Douglass",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75944,
      "latitude": 31.534162,
      "longitude": -94.639022,
      "city": "Etoile",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75946,
      "latitude": 31.771781,
      "longitude": -94.526793,
      "city": "Garrison",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75947,
      "latitude": 31.373062,
      "longitude": -93.821948,
      "city": "Geneva",
      "state": "TX",
      "county": "Sabine"
  },
  {
      "zipCode": 75948,
      "latitude": 31.329084,
      "longitude": -93.773671,
      "city": "Hemphill",
      "state": "TX",
      "county": "Sabine"
  },
  {
      "zipCode": 75949,
      "latitude": 31.285984,
      "longitude": -94.526589,
      "city": "Huntington",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75951,
      "latitude": 30.867257,
      "longitude": -93.997666,
      "city": "Jasper",
      "state": "TX",
      "county": "Jasper"
  },
  {
      "zipCode": 75954,
      "latitude": 31.966633,
      "longitude": -94.058836,
      "city": "Joaquin",
      "state": "TX",
      "county": "Shelby"
  },
  {
      "zipCode": 75956,
      "latitude": 30.658273,
      "longitude": -93.8984,
      "city": "Kirbyville",
      "state": "TX",
      "county": "Jasper"
  },
  {
      "zipCode": 75958,
      "latitude": 31.534162,
      "longitude": -94.639022,
      "city": "Martinsville",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75959,
      "latitude": 31.373062,
      "longitude": -93.821948,
      "city": "Milam",
      "state": "TX",
      "county": "Sabine"
  },
  {
      "zipCode": 75960,
      "latitude": 30.817866,
      "longitude": -94.869052,
      "city": "Moscow",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 75961,
      "latitude": 31.664506,
      "longitude": -94.578421,
      "city": "Nacogdoches",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75962,
      "latitude": 31.699494,
      "longitude": -94.607432,
      "city": "Nacogdoches",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75963,
      "latitude": 31.604573,
      "longitude": -94.664127,
      "city": "Nacogdoches",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75964,
      "latitude": 31.673736,
      "longitude": -94.693206,
      "city": "Nacogdoches",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75965,
      "latitude": 31.728994,
      "longitude": -94.626523,
      "city": "Nacogdoches",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75966,
      "latitude": 30.838608,
      "longitude": -93.767911,
      "city": "Newton",
      "state": "TX",
      "county": "Newton"
  },
  {
      "zipCode": 75968,
      "latitude": 31.24817,
      "longitude": -93.973106,
      "city": "Pineland",
      "state": "TX",
      "county": "Sabine"
  },
  {
      "zipCode": 75969,
      "latitude": 31.440245,
      "longitude": -94.869754,
      "city": "Pollok",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75972,
      "latitude": 31.548122,
      "longitude": -94.111751,
      "city": "San Augustine",
      "state": "TX",
      "county": "San Augustine"
  },
  {
      "zipCode": 75973,
      "latitude": 31.774637,
      "longitude": -94.152946,
      "city": "Shelbyville",
      "state": "TX",
      "county": "Shelby"
  },
  {
      "zipCode": 75974,
      "latitude": 31.948053,
      "longitude": -94.247695,
      "city": "Tenaha",
      "state": "TX",
      "county": "Shelby"
  },
  {
      "zipCode": 75975,
      "latitude": 31.905616,
      "longitude": -94.42602,
      "city": "Timpson",
      "state": "TX",
      "county": "Shelby"
  },
  {
      "zipCode": 75976,
      "latitude": 31.782048,
      "longitude": -95.164021,
      "city": "Wells",
      "state": "TX",
      "county": "Cherokee"
  },
  {
      "zipCode": 75977,
      "latitude": 30.714636,
      "longitude": -93.709628,
      "city": "Wiergate",
      "state": "TX",
      "county": "Newton"
  },
  {
      "zipCode": 75978,
      "latitude": 31.534162,
      "longitude": -94.639022,
      "city": "Woden",
      "state": "TX",
      "county": "Nacogdoches"
  },
  {
      "zipCode": 75979,
      "latitude": 30.758494,
      "longitude": -94.366929,
      "city": "Woodville",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 75980,
      "latitude": 31.174421,
      "longitude": -94.401293,
      "city": "Zavalla",
      "state": "TX",
      "county": "Angelina"
  },
  {
      "zipCode": 75990,
      "latitude": 30.792674,
      "longitude": -94.354476,
      "city": "Woodville",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 76001,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76002,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76003,
      "latitude": 32.741685,
      "longitude": -97.225324,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76004,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76005,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76006,
      "latitude": 32.776551,
      "longitude": -97.085559,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76007,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76008,
      "latitude": 32.681918,
      "longitude": -97.779522,
      "city": "Aledo",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76009,
      "latitude": 32.421034,
      "longitude": -97.196279,
      "city": "Alvarado",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76010,
      "latitude": 32.731821,
      "longitude": -97.079787,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76011,
      "latitude": 32.771455,
      "longitude": -97.079633,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76012,
      "latitude": 32.77408,
      "longitude": -97.131689,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76013,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76014,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76015,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76016,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76017,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76018,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76019,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76020,
      "latitude": 32.909715,
      "longitude": -97.526302,
      "city": "Azle",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76021,
      "latitude": 32.852546,
      "longitude": -97.136289,
      "city": "Bedford",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76022,
      "latitude": 32.831019,
      "longitude": -97.144339,
      "city": "Bedford",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76023,
      "latitude": 33.111268,
      "longitude": -97.638617,
      "city": "Boyd",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76028,
      "latitude": 32.492683,
      "longitude": -97.264359,
      "city": "Burleson",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76031,
      "latitude": 32.317774,
      "longitude": -97.440849,
      "city": "Cleburne",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76033,
      "latitude": 32.350907,
      "longitude": -97.410254,
      "city": "Cleburne",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76034,
      "latitude": 32.88955,
      "longitude": -97.143409,
      "city": "Colleyville",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76035,
      "latitude": 32.473973,
      "longitude": -97.67747,
      "city": "Cresson",
      "state": "TX",
      "county": "Hood"
  },
  {
      "zipCode": 76036,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Crowley",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76039,
      "latitude": 32.859305,
      "longitude": -97.075688,
      "city": "Euless",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76040,
      "latitude": 32.821379,
      "longitude": -97.108179,
      "city": "Euless",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76041,
      "latitude": 32.248588,
      "longitude": -96.823225,
      "city": "Forreston",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 76043,
      "latitude": 32.210812,
      "longitude": -97.792155,
      "city": "Glen Rose",
      "state": "TX",
      "county": "Somervell"
  },
  {
      "zipCode": 76044,
      "latitude": 32.418496,
      "longitude": -97.530566,
      "city": "Godley",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76048,
      "latitude": 32.435405,
      "longitude": -97.844867,
      "city": "Granbury",
      "state": "TX",
      "county": "Hood"
  },
  {
      "zipCode": 76049,
      "latitude": 32.449368,
      "longitude": -97.76658,
      "city": "Granbury",
      "state": "TX",
      "county": "Hood"
  },
  {
      "zipCode": 76050,
      "latitude": 32.336003,
      "longitude": -97.311068,
      "city": "Grandview",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76051,
      "latitude": 32.932843,
      "longitude": -97.080802,
      "city": "Grapevine",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76052,
      "latitude": 32.941139,
      "longitude": -97.353767,
      "city": "Haslet",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76053,
      "latitude": 32.882663,
      "longitude": -97.227779,
      "city": "Hurst",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76054,
      "latitude": 32.863193,
      "longitude": -97.175935,
      "city": "Hurst",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76055,
      "latitude": 32.176705,
      "longitude": -97.20549,
      "city": "Itasca",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76058,
      "latitude": 32.408644,
      "longitude": -97.303647,
      "city": "Joshua",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76059,
      "latitude": 32.334964,
      "longitude": -97.30553,
      "city": "Keene",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76060,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Kennedale",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76061,
      "latitude": 32.476067,
      "longitude": -97.189257,
      "city": "Lillian",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76063,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Mansfield",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76064,
      "latitude": 32.299304,
      "longitude": -97.02705,
      "city": "Maypearl",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 76065,
      "latitude": 32.431364,
      "longitude": -96.978849,
      "city": "Midlothian",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 76066,
      "latitude": 32.745716,
      "longitude": -97.956126,
      "city": "Millsap",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76067,
      "latitude": 32.800128,
      "longitude": -98.24943,
      "city": "Mineral Wells",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76068,
      "latitude": 32.759769,
      "longitude": -98.316175,
      "city": "Mineral Wells",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76070,
      "latitude": 32.234831,
      "longitude": -97.665434,
      "city": "Nemo",
      "state": "TX",
      "county": "Somervell"
  },
  {
      "zipCode": 76071,
      "latitude": 33.006977,
      "longitude": -97.492336,
      "city": "Newark",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76073,
      "latitude": 33.097605,
      "longitude": -97.727516,
      "city": "Paradise",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76077,
      "latitude": 32.284006,
      "longitude": -97.704041,
      "city": "Rainbow",
      "state": "TX",
      "county": "Somervell"
  },
  {
      "zipCode": 76078,
      "latitude": 33.092103,
      "longitude": -97.480329,
      "city": "Rhome",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76082,
      "latitude": 32.80864,
      "longitude": -97.693195,
      "city": "Springtown",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76084,
      "latitude": 32.455147,
      "longitude": -97.141191,
      "city": "Venus",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76085,
      "latitude": 32.861116,
      "longitude": -97.692165,
      "city": "Weatherford",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76086,
      "latitude": 32.780766,
      "longitude": -97.806778,
      "city": "Weatherford",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76087,
      "latitude": 32.753927,
      "longitude": -97.786026,
      "city": "Weatherford",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76088,
      "latitude": 32.847803,
      "longitude": -97.860618,
      "city": "Weatherford",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76092,
      "latitude": 32.948474,
      "longitude": -97.152368,
      "city": "Southlake",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76093,
      "latitude": 32.295054,
      "longitude": -97.326177,
      "city": "Rio Vista",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76094,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76095,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Bedford",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76096,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Arlington",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76097,
      "latitude": 32.524393,
      "longitude": -97.2609,
      "city": "Burleson",
      "state": "TX",
      "county": "Johnson"
  },
  {
      "zipCode": 76098,
      "latitude": 32.895716,
      "longitude": -97.563622,
      "city": "Azle",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76099,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Grapevine",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76101,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76102,
      "latitude": 32.759437,
      "longitude": -97.245612,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76103,
      "latitude": 32.756715,
      "longitude": -97.274093,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76104,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76105,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76106,
      "latitude": 32.788655,
      "longitude": -97.355195,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76107,
      "latitude": 32.75428,
      "longitude": -97.380446,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76108,
      "latitude": 32.782178,
      "longitude": -97.496948,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76109,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76110,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76111,
      "latitude": 32.794671,
      "longitude": -97.297494,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76112,
      "latitude": 32.77213,
      "longitude": -97.217742,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76113,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76114,
      "latitude": 32.779629,
      "longitude": -97.392797,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76115,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76116,
      "latitude": 32.743094,
      "longitude": -97.426847,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76117,
      "latitude": 32.801874,
      "longitude": -97.263393,
      "city": "Haltom City",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76118,
      "latitude": 32.801252,
      "longitude": -97.195241,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76119,
      "latitude": 32.798883,
      "longitude": -97.193275,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76120,
      "latitude": 32.769931,
      "longitude": -97.184721,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76121,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76122,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76123,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76124,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76126,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76127,
      "latitude": 32.766737,
      "longitude": -97.427153,
      "city": "Naval Air Station/ Jrb",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76129,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76130,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76131,
      "latitude": 32.891654,
      "longitude": -97.348081,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76132,
      "latitude": 32.868287,
      "longitude": -97.369898,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76133,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76134,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76135,
      "latitude": 32.834785,
      "longitude": -97.470199,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76136,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76137,
      "latitude": 32.853199,
      "longitude": -97.288144,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76140,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76147,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76148,
      "latitude": 32.862105,
      "longitude": -97.250808,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76150,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76155,
      "latitude": 32.831354,
      "longitude": -97.051969,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76161,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76162,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76163,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76164,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76177,
      "latitude": 32.93455,
      "longitude": -97.32362,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76178,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76179,
      "latitude": 32.907394,
      "longitude": -97.425681,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76180,
      "latitude": 32.868023,
      "longitude": -97.217416,
      "city": "North Richland Hills",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76181,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76182,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "North Richland Hills",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76185,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76191,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76192,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76193,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76195,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76196,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76197,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76198,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76199,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Fort Worth",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76201,
      "latitude": 33.244683,
      "longitude": -97.200555,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76202,
      "latitude": 33.225523,
      "longitude": -97.108546,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76203,
      "latitude": 33.246457,
      "longitude": -97.127027,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76204,
      "latitude": 33.20743,
      "longitude": -97.116282,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76205,
      "latitude": 33.152861,
      "longitude": -97.091876,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76206,
      "latitude": 33.169379,
      "longitude": -97.150558,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76207,
      "latitude": 33.238378,
      "longitude": -97.203975,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76208,
      "latitude": 33.160393,
      "longitude": -97.095421,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76209,
      "latitude": 33.2315,
      "longitude": -97.109986,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76210,
      "latitude": 33.149467,
      "longitude": -97.096016,
      "city": "Denton",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76225,
      "latitude": 33.355828,
      "longitude": -97.708353,
      "city": "Alvord",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76226,
      "latitude": 33.215066,
      "longitude": -97.164644,
      "city": "Argyle",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76227,
      "latitude": 33.278066,
      "longitude": -97.01748,
      "city": "Aubrey",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76228,
      "latitude": 33.634364,
      "longitude": -98.015081,
      "city": "Bellevue",
      "state": "TX",
      "county": "Clay"
  },
  {
      "zipCode": 76230,
      "latitude": 33.530011,
      "longitude": -97.856849,
      "city": "Bowie",
      "state": "TX",
      "county": "Montague"
  },
  {
      "zipCode": 76233,
      "latitude": 33.561589,
      "longitude": -96.856165,
      "city": "Collinsville",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 76234,
      "latitude": 33.260148,
      "longitude": -97.610974,
      "city": "Decatur",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76238,
      "latitude": 33.490924,
      "longitude": -97.313913,
      "city": "Era",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76239,
      "latitude": 33.436183,
      "longitude": -97.565592,
      "city": "Forestburg",
      "state": "TX",
      "county": "Montague"
  },
  {
      "zipCode": 76240,
      "latitude": 33.654698,
      "longitude": -97.158295,
      "city": "Gainesville",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76241,
      "latitude": 33.610384,
      "longitude": -97.03692,
      "city": "Gainesville",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76244,
      "latitude": 32.771419,
      "longitude": -97.291484,
      "city": "Keller",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76245,
      "latitude": 33.826599,
      "longitude": -96.851241,
      "city": "Gordonville",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 76246,
      "latitude": 33.317914,
      "longitude": -97.609392,
      "city": "Greenwood",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76247,
      "latitude": 33.100744,
      "longitude": -97.245551,
      "city": "Justin",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76248,
      "latitude": 32.934629,
      "longitude": -97.24871,
      "city": "Keller",
      "state": "TX",
      "county": "Tarrant"
  },
  {
      "zipCode": 76249,
      "latitude": 33.224192,
      "longitude": -97.286741,
      "city": "Krum",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76250,
      "latitude": 33.533869,
      "longitude": -97.171723,
      "city": "Lindsay",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76251,
      "latitude": 33.647198,
      "longitude": -97.720081,
      "city": "Montague",
      "state": "TX",
      "county": "Montague"
  },
  {
      "zipCode": 76252,
      "latitude": 33.690667,
      "longitude": -97.34502,
      "city": "Muenster",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76253,
      "latitude": 33.617841,
      "longitude": -97.309018,
      "city": "Myra",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76255,
      "latitude": 33.815096,
      "longitude": -97.691515,
      "city": "Nocona",
      "state": "TX",
      "county": "Montague"
  },
  {
      "zipCode": 76258,
      "latitude": 33.249576,
      "longitude": -97.067567,
      "city": "Pilot Point",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76259,
      "latitude": 33.192662,
      "longitude": -97.309629,
      "city": "Ponder",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76261,
      "latitude": 33.820163,
      "longitude": -97.941476,
      "city": "Ringgold",
      "state": "TX",
      "county": "Montague"
  },
  {
      "zipCode": 76262,
      "latitude": 33.199082,
      "longitude": -97.117815,
      "city": "Roanoke",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76263,
      "latitude": 33.487842,
      "longitude": -97.421155,
      "city": "Rosston",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76264,
      "latitude": 33.728996,
      "longitude": -96.846208,
      "city": "Sadler",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 76265,
      "latitude": 33.692738,
      "longitude": -97.524599,
      "city": "Saint Jo",
      "state": "TX",
      "county": "Montague"
  },
  {
      "zipCode": 76266,
      "latitude": 33.341653,
      "longitude": -97.214454,
      "city": "Sanger",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76267,
      "latitude": 33.378039,
      "longitude": -97.39219,
      "city": "Slidell",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76268,
      "latitude": 33.602427,
      "longitude": -96.757322,
      "city": "Southmayd",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 76270,
      "latitude": 33.499274,
      "longitude": -97.760266,
      "city": "Sunset",
      "state": "TX",
      "county": "Montague"
  },
  {
      "zipCode": 76271,
      "latitude": 33.532559,
      "longitude": -96.859159,
      "city": "Tioga",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 76272,
      "latitude": 33.495794,
      "longitude": -97.143184,
      "city": "Valley View",
      "state": "TX",
      "county": "Cooke"
  },
  {
      "zipCode": 76273,
      "latitude": 33.701372,
      "longitude": -96.836522,
      "city": "Whitesboro",
      "state": "TX",
      "county": "Grayson"
  },
  {
      "zipCode": 76299,
      "latitude": 33.20743,
      "longitude": -97.116282,
      "city": "Roanoke",
      "state": "TX",
      "county": "Denton"
  },
  {
      "zipCode": 76301,
      "latitude": 33.959758,
      "longitude": -98.48283,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76302,
      "latitude": 33.863948,
      "longitude": -98.47828,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76305,
      "latitude": 33.973736,
      "longitude": -98.512878,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76306,
      "latitude": 33.979635,
      "longitude": -98.696094,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76307,
      "latitude": 33.877746,
      "longitude": -98.494595,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76308,
      "latitude": 33.863226,
      "longitude": -98.582279,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76309,
      "latitude": 33.892709,
      "longitude": -98.499781,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76310,
      "latitude": 33.87121,
      "longitude": -98.576573,
      "city": "Wichita Falls",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76311,
      "latitude": 33.968945,
      "longitude": -98.505933,
      "city": "Sheppard Afb",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76351,
      "latitude": 33.556215,
      "longitude": -98.624934,
      "city": "Archer City",
      "state": "TX",
      "county": "Archer"
  },
  {
      "zipCode": 76352,
      "latitude": 33.811665,
      "longitude": -98.184762,
      "city": "Bluegrove",
      "state": "TX",
      "county": "Clay"
  },
  {
      "zipCode": 76354,
      "latitude": 34.048087,
      "longitude": -98.600475,
      "city": "Burkburnett",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76357,
      "latitude": 34.072254,
      "longitude": -98.191301,
      "city": "Byers",
      "state": "TX",
      "county": "Clay"
  },
  {
      "zipCode": 76360,
      "latitude": 33.999472,
      "longitude": -98.743552,
      "city": "Electra",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76363,
      "latitude": 33.514168,
      "longitude": -99.572744,
      "city": "Goree",
      "state": "TX",
      "county": "Knox"
  },
  {
      "zipCode": 76364,
      "latitude": 34.118913,
      "longitude": -99.033787,
      "city": "Harrold",
      "state": "TX",
      "county": "Wilbarger"
  },
  {
      "zipCode": 76365,
      "latitude": 33.906101,
      "longitude": -98.201732,
      "city": "Henrietta",
      "state": "TX",
      "county": "Clay"
  },
  {
      "zipCode": 76366,
      "latitude": 33.711373,
      "longitude": -98.711928,
      "city": "Holliday",
      "state": "TX",
      "county": "Archer"
  },
  {
      "zipCode": 76367,
      "latitude": 33.969067,
      "longitude": -98.717032,
      "city": "Iowa Park",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76369,
      "latitude": 34.023386,
      "longitude": -98.687748,
      "city": "Kamay",
      "state": "TX",
      "county": "Wichita"
  },
  {
      "zipCode": 76370,
      "latitude": 33.453275,
      "longitude": -98.929727,
      "city": "Megargel",
      "state": "TX",
      "county": "Archer"
  },
  {
      "zipCode": 76371,
      "latitude": 33.495136,
      "longitude": -99.642309,
      "city": "Munday",
      "state": "TX",
      "county": "Knox"
  },
  {
      "zipCode": 76372,
      "latitude": 33.1934,
      "longitude": -98.742126,
      "city": "Newcastle",
      "state": "TX",
      "county": "Young"
  },
  {
      "zipCode": 76373,
      "latitude": 34.145598,
      "longitude": -99.107033,
      "city": "Oklaunion",
      "state": "TX",
      "county": "Wilbarger"
  },
  {
      "zipCode": 76374,
      "latitude": 33.361214,
      "longitude": -98.779466,
      "city": "Olney",
      "state": "TX",
      "county": "Young"
  },
  {
      "zipCode": 76377,
      "latitude": 34.032867,
      "longitude": -98.228187,
      "city": "Petrolia",
      "state": "TX",
      "county": "Clay"
  },
  {
      "zipCode": 76379,
      "latitude": 33.615708,
      "longitude": -98.687302,
      "city": "Scotland",
      "state": "TX",
      "county": "Archer"
  },
  {
      "zipCode": 76380,
      "latitude": 33.620131,
      "longitude": -99.263681,
      "city": "Seymour",
      "state": "TX",
      "county": "Baylor"
  },
  {
      "zipCode": 76384,
      "latitude": 34.155524,
      "longitude": -99.284002,
      "city": "Vernon",
      "state": "TX",
      "county": "Wilbarger"
  },
  {
      "zipCode": 76385,
      "latitude": 34.146356,
      "longitude": -99.214088,
      "city": "Vernon",
      "state": "TX",
      "county": "Wilbarger"
  },
  {
      "zipCode": 76388,
      "latitude": 33.322098,
      "longitude": -99.674393,
      "city": "Weinert",
      "state": "TX",
      "county": "Haskell"
  },
  {
      "zipCode": 76389,
      "latitude": 33.615708,
      "longitude": -98.687302,
      "city": "Windthorst",
      "state": "TX",
      "county": "Archer"
  },
  {
      "zipCode": 76401,
      "latitude": 32.321348,
      "longitude": -98.108828,
      "city": "Stephenville",
      "state": "TX",
      "county": "Erath"
  },
  {
      "zipCode": 76402,
      "latitude": 32.215275,
      "longitude": -98.207997,
      "city": "Stephenville",
      "state": "TX",
      "county": "Erath"
  },
  {
      "zipCode": 76424,
      "latitude": 32.769037,
      "longitude": -98.84154,
      "city": "Breckenridge",
      "state": "TX",
      "county": "Stephens"
  },
  {
      "zipCode": 76426,
      "latitude": 33.174802,
      "longitude": -97.697401,
      "city": "Bridgeport",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76427,
      "latitude": 33.161547,
      "longitude": -98.387427,
      "city": "Bryson",
      "state": "TX",
      "county": "Jack"
  },
  {
      "zipCode": 76429,
      "latitude": 32.715706,
      "longitude": -98.749054,
      "city": "Caddo",
      "state": "TX",
      "county": "Stephens"
  },
  {
      "zipCode": 76430,
      "latitude": 32.768222,
      "longitude": -99.210207,
      "city": "Albany",
      "state": "TX",
      "county": "Shackelford"
  },
  {
      "zipCode": 76431,
      "latitude": 33.301868,
      "longitude": -97.814354,
      "city": "Chico",
      "state": "TX",
      "county": "Wise"
  },
  {
      "zipCode": 76432,
      "latitude": 31.801502,
      "longitude": -98.827637,
      "city": "Blanket",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76433,
      "latitude": 32.28842,
      "longitude": -98.053885,
      "city": "Bluff Dale",
      "state": "TX",
      "county": "Erath"
  },
  {
      "zipCode": 76435,
      "latitude": 32.228414,
      "longitude": -98.819663,
      "city": "Carbon",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76436,
      "latitude": 31.717077,
      "longitude": -98.115083,
      "city": "Carlton",
      "state": "TX",
      "county": "Hamilton"
  },
  {
      "zipCode": 76437,
      "latitude": 32.286476,
      "longitude": -98.973319,
      "city": "Cisco",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76439,
      "latitude": 32.632495,
      "longitude": -97.957483,
      "city": "Dennis",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76442,
      "latitude": 31.979672,
      "longitude": -98.513844,
      "city": "Comanche",
      "state": "TX",
      "county": "Comanche"
  },
  {
      "zipCode": 76443,
      "latitude": 32.16742,
      "longitude": -99.221055,
      "city": "Cross Plains",
      "state": "TX",
      "county": "Callahan"
  },
  {
      "zipCode": 76444,
      "latitude": 32.070468,
      "longitude": -98.650751,
      "city": "De Leon",
      "state": "TX",
      "county": "Comanche"
  },
  {
      "zipCode": 76445,
      "latitude": 32.296818,
      "longitude": -98.796686,
      "city": "Desdemona",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76446,
      "latitude": 32.123145,
      "longitude": -98.289028,
      "city": "Dublin",
      "state": "TX",
      "county": "Erath"
  },
  {
      "zipCode": 76448,
      "latitude": 32.403972,
      "longitude": -98.777973,
      "city": "Eastland",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76449,
      "latitude": 32.774543,
      "longitude": -98.337981,
      "city": "Graford",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76450,
      "latitude": 33.092674,
      "longitude": -98.64403,
      "city": "Graham",
      "state": "TX",
      "county": "Young"
  },
  {
      "zipCode": 76452,
      "latitude": 31.972712,
      "longitude": -98.540485,
      "city": "Energy",
      "state": "TX",
      "county": "Comanche"
  },
  {
      "zipCode": 76453,
      "latitude": 32.616663,
      "longitude": -98.368583,
      "city": "Gordon",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76454,
      "latitude": 32.221688,
      "longitude": -98.719352,
      "city": "Gorman",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76455,
      "latitude": 31.846846,
      "longitude": -98.401686,
      "city": "Gustine",
      "state": "TX",
      "county": "Comanche"
  },
  {
      "zipCode": 76457,
      "latitude": 31.939212,
      "longitude": -98.032562,
      "city": "Hico",
      "state": "TX",
      "county": "Hamilton"
  },
  {
      "zipCode": 76458,
      "latitude": 33.246805,
      "longitude": -98.264241,
      "city": "Jacksboro",
      "state": "TX",
      "county": "Jack"
  },
  {
      "zipCode": 76459,
      "latitude": 33.234207,
      "longitude": -98.172371,
      "city": "Jermyn",
      "state": "TX",
      "county": "Jack"
  },
  {
      "zipCode": 76460,
      "latitude": 33.290955,
      "longitude": -98.496902,
      "city": "Loving",
      "state": "TX",
      "county": "Young"
  },
  {
      "zipCode": 76461,
      "latitude": 32.221433,
      "longitude": -98.342798,
      "city": "Lingleville",
      "state": "TX",
      "county": "Erath"
  },
  {
      "zipCode": 76462,
      "latitude": 32.494133,
      "longitude": -97.989191,
      "city": "Lipan",
      "state": "TX",
      "county": "Hood"
  },
  {
      "zipCode": 76463,
      "latitude": 32.538075,
      "longitude": -98.421901,
      "city": "Mingus",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76464,
      "latitude": 32.624249,
      "longitude": -99.199479,
      "city": "Moran",
      "state": "TX",
      "county": "Shackelford"
  },
  {
      "zipCode": 76465,
      "latitude": 32.215275,
      "longitude": -98.207997,
      "city": "Morgan Mill",
      "state": "TX",
      "county": "Erath"
  },
  {
      "zipCode": 76466,
      "latitude": 32.442088,
      "longitude": -98.734228,
      "city": "Olden",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76467,
      "latitude": 32.341365,
      "longitude": -97.932083,
      "city": "Paluxy",
      "state": "TX",
      "county": "Hood"
  },
  {
      "zipCode": 76468,
      "latitude": 31.972712,
      "longitude": -98.540485,
      "city": "Proctor",
      "state": "TX",
      "county": "Comanche"
  },
  {
      "zipCode": 76469,
      "latitude": 32.374033,
      "longitude": -99.195668,
      "city": "Putnam",
      "state": "TX",
      "county": "Callahan"
  },
  {
      "zipCode": 76470,
      "latitude": 32.303639,
      "longitude": -98.778484,
      "city": "Ranger",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76471,
      "latitude": 32.268924,
      "longitude": -98.860949,
      "city": "Rising Star",
      "state": "TX",
      "county": "Eastland"
  },
  {
      "zipCode": 76472,
      "latitude": 32.635934,
      "longitude": -98.179314,
      "city": "Santo",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76474,
      "latitude": 31.972712,
      "longitude": -98.540485,
      "city": "Sidney",
      "state": "TX",
      "county": "Comanche"
  },
  {
      "zipCode": 76475,
      "latitude": 32.693772,
      "longitude": -98.509939,
      "city": "Strawn",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76476,
      "latitude": 32.400038,
      "longitude": -97.933708,
      "city": "Tolar",
      "state": "TX",
      "county": "Hood"
  },
  {
      "zipCode": 76481,
      "latitude": 33.005164,
      "longitude": -98.689196,
      "city": "South Bend",
      "state": "TX",
      "county": "Young"
  },
  {
      "zipCode": 76483,
      "latitude": 33.158707,
      "longitude": -99.145361,
      "city": "Throckmorton",
      "state": "TX",
      "county": "Throckmorton"
  },
  {
      "zipCode": 76484,
      "latitude": 32.704835,
      "longitude": -98.346877,
      "city": "Palo Pinto",
      "state": "TX",
      "county": "Palo Pinto"
  },
  {
      "zipCode": 76485,
      "latitude": 32.779315,
      "longitude": -97.805502,
      "city": "Peaster",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76486,
      "latitude": 33.030672,
      "longitude": -98.066151,
      "city": "Perrin",
      "state": "TX",
      "county": "Jack"
  },
  {
      "zipCode": 76487,
      "latitude": 32.94962,
      "longitude": -97.903499,
      "city": "Poolville",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76490,
      "latitude": 32.951494,
      "longitude": -97.942209,
      "city": "Whitt",
      "state": "TX",
      "county": "Parker"
  },
  {
      "zipCode": 76491,
      "latitude": 33.074064,
      "longitude": -99.054584,
      "city": "Woodson",
      "state": "TX",
      "county": "Throckmorton"
  },
  {
      "zipCode": 76501,
      "latitude": 31.068306,
      "longitude": -97.3047,
      "city": "Temple",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76502,
      "latitude": 31.104581,
      "longitude": -97.402704,
      "city": "Temple",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76503,
      "latitude": 31.053986,
      "longitude": -97.320306,
      "city": "Temple",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76504,
      "latitude": 31.121795,
      "longitude": -97.440951,
      "city": "Temple",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76505,
      "latitude": 31.036287,
      "longitude": -97.492017,
      "city": "Temple",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76508,
      "latitude": 31.036287,
      "longitude": -97.492017,
      "city": "Temple",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76511,
      "latitude": 30.906295,
      "longitude": -97.417295,
      "city": "Bartlett",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76513,
      "latitude": 30.983551,
      "longitude": -97.521848,
      "city": "Belton",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76518,
      "latitude": 30.868537,
      "longitude": -97.055021,
      "city": "Buckholts",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76519,
      "latitude": 30.973648,
      "longitude": -96.964199,
      "city": "Burlington",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76520,
      "latitude": 30.812561,
      "longitude": -96.902747,
      "city": "Cameron",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76522,
      "latitude": 31.202919,
      "longitude": -97.930051,
      "city": "Copperas Cove",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76523,
      "latitude": 30.784169,
      "longitude": -96.96376,
      "city": "Davilla",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76524,
      "latitude": 31.308813,
      "longitude": -97.277397,
      "city": "Eddy",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76525,
      "latitude": 31.438841,
      "longitude": -98.053477,
      "city": "Evant",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76526,
      "latitude": 31.295568,
      "longitude": -97.582152,
      "city": "Flat",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76527,
      "latitude": 30.807586,
      "longitude": -97.78119,
      "city": "Florence",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 76528,
      "latitude": 31.417739,
      "longitude": -97.833033,
      "city": "Gatesville",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76530,
      "latitude": 30.71136,
      "longitude": -97.418471,
      "city": "Granger",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 76531,
      "latitude": 31.671399,
      "longitude": -98.107585,
      "city": "Hamilton",
      "state": "TX",
      "county": "Hamilton"
  },
  {
      "zipCode": 76533,
      "latitude": 31.049621,
      "longitude": -97.493616,
      "city": "Heidenheimer",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76534,
      "latitude": 30.887588,
      "longitude": -97.418108,
      "city": "Holland",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76537,
      "latitude": 30.811931,
      "longitude": -97.594186,
      "city": "Jarrell",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 76538,
      "latitude": 31.559947,
      "longitude": -97.845634,
      "city": "Jonesboro",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76539,
      "latitude": 31.107716,
      "longitude": -97.957926,
      "city": "Kempner",
      "state": "TX",
      "county": "Lampasas"
  },
  {
      "zipCode": 76540,
      "latitude": 31.085833,
      "longitude": -97.357099,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76541,
      "latitude": 31.057772,
      "longitude": -97.621213,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76542,
      "latitude": 31.03757,
      "longitude": -97.680866,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76543,
      "latitude": 31.010681,
      "longitude": -97.585946,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76544,
      "latitude": 31.128195,
      "longitude": -97.746948,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76545,
      "latitude": 31.036287,
      "longitude": -97.492017,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76546,
      "latitude": 31.036287,
      "longitude": -97.492017,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76547,
      "latitude": 31.036287,
      "longitude": -97.492017,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76548,
      "latitude": 31.028627,
      "longitude": -97.611484,
      "city": "Harker Heights",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76549,
      "latitude": 31.043013,
      "longitude": -97.590787,
      "city": "Killeen",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76550,
      "latitude": 31.183225,
      "longitude": -98.236306,
      "city": "Lampasas",
      "state": "TX",
      "county": "Lampasas"
  },
  {
      "zipCode": 76552,
      "latitude": 31.351234,
      "longitude": -97.548468,
      "city": "Leon Junction",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76554,
      "latitude": 30.963007,
      "longitude": -97.361597,
      "city": "Little River",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76555,
      "latitude": 30.784169,
      "longitude": -96.96376,
      "city": "Maysfield",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76556,
      "latitude": 30.730427,
      "longitude": -96.880743,
      "city": "Milano",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76557,
      "latitude": 31.335232,
      "longitude": -97.336265,
      "city": "Moody",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76558,
      "latitude": 31.351386,
      "longitude": -97.644352,
      "city": "Mound",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76559,
      "latitude": 31.037986,
      "longitude": -97.5083,
      "city": "Nolanville",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76561,
      "latitude": 31.415049,
      "longitude": -97.560418,
      "city": "Oglesby",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76564,
      "latitude": 31.036287,
      "longitude": -97.492017,
      "city": "Pendleton",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76565,
      "latitude": 31.636838,
      "longitude": -98.348689,
      "city": "Pottsville",
      "state": "TX",
      "county": "Hamilton"
  },
  {
      "zipCode": 76566,
      "latitude": 31.484144,
      "longitude": -97.990338,
      "city": "Purmela",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76567,
      "latitude": 30.655359,
      "longitude": -97.035158,
      "city": "Rockdale",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76569,
      "latitude": 30.948804,
      "longitude": -97.225426,
      "city": "Rogers",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76570,
      "latitude": 31.103376,
      "longitude": -96.998652,
      "city": "Rosebud",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76571,
      "latitude": 30.925692,
      "longitude": -97.570057,
      "city": "Salado",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76573,
      "latitude": 30.805512,
      "longitude": -97.470456,
      "city": "Schwertner",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 76574,
      "latitude": 30.581504,
      "longitude": -97.393787,
      "city": "Taylor",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 76577,
      "latitude": 30.613774,
      "longitude": -97.205788,
      "city": "Thorndale",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 76578,
      "latitude": 30.546326,
      "longitude": -97.236259,
      "city": "Thrall",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 76579,
      "latitude": 31.174847,
      "longitude": -97.269835,
      "city": "Troy",
      "state": "TX",
      "county": "Bell"
  },
  {
      "zipCode": 76596,
      "latitude": 31.390196,
      "longitude": -97.799309,
      "city": "Gatesville",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76597,
      "latitude": 31.390196,
      "longitude": -97.799309,
      "city": "Gatesville",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76598,
      "latitude": 31.390196,
      "longitude": -97.799309,
      "city": "Gatesville",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76599,
      "latitude": 31.470598,
      "longitude": -97.734728,
      "city": "Gatesville",
      "state": "TX",
      "county": "Coryell"
  },
  {
      "zipCode": 76621,
      "latitude": 31.88454,
      "longitude": -97.077218,
      "city": "Abbott",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76622,
      "latitude": 31.987167,
      "longitude": -97.108113,
      "city": "Aquilla",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76623,
      "latitude": 32.221421,
      "longitude": -96.7832,
      "city": "Avalon",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 76624,
      "latitude": 31.687303,
      "longitude": -96.986233,
      "city": "Axtell",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76626,
      "latitude": 32.10146,
      "longitude": -96.733115,
      "city": "Blooming Grove",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 76627,
      "latitude": 32.045004,
      "longitude": -97.373528,
      "city": "Blum",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76628,
      "latitude": 31.987167,
      "longitude": -97.108113,
      "city": "Brandon",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76629,
      "latitude": 31.165947,
      "longitude": -96.675985,
      "city": "Bremond",
      "state": "TX",
      "county": "Robertson"
  },
  {
      "zipCode": 76630,
      "latitude": 31.335902,
      "longitude": -97.230006,
      "city": "Bruceville",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76631,
      "latitude": 31.968293,
      "longitude": -97.003015,
      "city": "Bynum",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76632,
      "latitude": 31.303213,
      "longitude": -97.039384,
      "city": "Chilton",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76633,
      "latitude": 31.666665,
      "longitude": -97.310809,
      "city": "China Spring",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76634,
      "latitude": 31.791801,
      "longitude": -97.520315,
      "city": "Clifton",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76635,
      "latitude": 31.695145,
      "longitude": -96.586919,
      "city": "Coolidge",
      "state": "TX",
      "county": "Limestone"
  },
  {
      "zipCode": 76636,
      "latitude": 32.235057,
      "longitude": -97.229962,
      "city": "Covington",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76637,
      "latitude": 31.897381,
      "longitude": -97.64097,
      "city": "Cranfills Gap",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76638,
      "latitude": 31.565099,
      "longitude": -97.43051,
      "city": "Crawford",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76639,
      "latitude": 31.909682,
      "longitude": -96.646518,
      "city": "Dawson",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 76640,
      "latitude": 31.699922,
      "longitude": -97.11457,
      "city": "Elm Mott",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76641,
      "latitude": 32.01253,
      "longitude": -96.792321,
      "city": "Frost",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 76642,
      "latitude": 31.473823,
      "longitude": -96.543147,
      "city": "Groesbeck",
      "state": "TX",
      "county": "Limestone"
  },
  {
      "zipCode": 76643,
      "latitude": 31.522017,
      "longitude": -97.164392,
      "city": "Hewitt",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76644,
      "latitude": 31.897381,
      "longitude": -97.64097,
      "city": "Laguna Park",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76645,
      "latitude": 31.937903,
      "longitude": -97.057661,
      "city": "Hillsboro",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76648,
      "latitude": 31.905748,
      "longitude": -97.079707,
      "city": "Hubbard",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76649,
      "latitude": 31.984603,
      "longitude": -97.870387,
      "city": "Iredell",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76650,
      "latitude": 31.987167,
      "longitude": -97.108113,
      "city": "Irene",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76651,
      "latitude": 32.189616,
      "longitude": -96.873872,
      "city": "Italy",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 76652,
      "latitude": 31.897381,
      "longitude": -97.64097,
      "city": "Kopperl",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76653,
      "latitude": 31.312973,
      "longitude": -96.629121,
      "city": "Kosse",
      "state": "TX",
      "county": "Limestone"
  },
  {
      "zipCode": 76654,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Leroy",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76655,
      "latitude": 31.527918,
      "longitude": -97.16353,
      "city": "Lorena",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76656,
      "latitude": 31.146313,
      "longitude": -97.043397,
      "city": "Lott",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76657,
      "latitude": 31.40352,
      "longitude": -97.393924,
      "city": "Mc Gregor",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76660,
      "latitude": 31.891824,
      "longitude": -96.911219,
      "city": "Malone",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76661,
      "latitude": 31.249621,
      "longitude": -96.885602,
      "city": "Marlin",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76664,
      "latitude": 31.554423,
      "longitude": -96.871366,
      "city": "Mart",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76665,
      "latitude": 31.926424,
      "longitude": -97.652901,
      "city": "Meridian",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76666,
      "latitude": 32.058486,
      "longitude": -96.893871,
      "city": "Mertens",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76667,
      "latitude": 31.674789,
      "longitude": -96.52153,
      "city": "Mexia",
      "state": "TX",
      "county": "Limestone"
  },
  {
      "zipCode": 76670,
      "latitude": 32.150961,
      "longitude": -96.981584,
      "city": "Milford",
      "state": "TX",
      "county": "Ellis"
  },
  {
      "zipCode": 76671,
      "latitude": 32.0068,
      "longitude": -97.634243,
      "city": "Morgan",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76673,
      "latitude": 31.755973,
      "longitude": -96.882676,
      "city": "Mount Calm",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76675,
      "latitude": 31.254242,
      "longitude": -96.937574,
      "city": "Otto",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76676,
      "latitude": 31.859638,
      "longitude": -96.926944,
      "city": "Penelope",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76677,
      "latitude": 31.254242,
      "longitude": -96.937574,
      "city": "Perry",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76678,
      "latitude": 31.517893,
      "longitude": -96.584425,
      "city": "Prairie Hill",
      "state": "TX",
      "county": "Limestone"
  },
  {
      "zipCode": 76679,
      "latitude": 31.931282,
      "longitude": -96.604622,
      "city": "Purdon",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 76680,
      "latitude": 31.254242,
      "longitude": -96.937574,
      "city": "Reagan",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76681,
      "latitude": 31.905199,
      "longitude": -96.388082,
      "city": "Richland",
      "state": "TX",
      "county": "Navarro"
  },
  {
      "zipCode": 76682,
      "latitude": 31.48862,
      "longitude": -96.944644,
      "city": "Riesel",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76684,
      "latitude": 31.717293,
      "longitude": -97.118754,
      "city": "Ross",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76685,
      "latitude": 31.371525,
      "longitude": -97.076732,
      "city": "Satin",
      "state": "TX",
      "county": "Falls"
  },
  {
      "zipCode": 76686,
      "latitude": 31.750384,
      "longitude": -96.541589,
      "city": "Tehuacana",
      "state": "TX",
      "county": "Limestone"
  },
  {
      "zipCode": 76687,
      "latitude": 31.409873,
      "longitude": -96.57281,
      "city": "Thornton",
      "state": "TX",
      "county": "Limestone"
  },
  {
      "zipCode": 76689,
      "latitude": 31.657441,
      "longitude": -97.471686,
      "city": "Valley Mills",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76690,
      "latitude": 32.043524,
      "longitude": -97.750239,
      "city": "Walnut Springs",
      "state": "TX",
      "county": "Bosque"
  },
  {
      "zipCode": 76691,
      "latitude": 31.759903,
      "longitude": -97.102936,
      "city": "West",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76692,
      "latitude": 31.971321,
      "longitude": -97.346289,
      "city": "Whitney",
      "state": "TX",
      "county": "Hill"
  },
  {
      "zipCode": 76693,
      "latitude": 31.76936,
      "longitude": -96.391904,
      "city": "Wortham",
      "state": "TX",
      "county": "Freestone"
  },
  {
      "zipCode": 76701,
      "latitude": 31.551566,
      "longitude": -97.15508,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76702,
      "latitude": 31.547516,
      "longitude": -97.14433,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76703,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76704,
      "latitude": 31.554918,
      "longitude": -97.133007,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76705,
      "latitude": 31.640315,
      "longitude": -97.096306,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76706,
      "latitude": 31.47441,
      "longitude": -97.112326,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76707,
      "latitude": 31.516366,
      "longitude": -97.160281,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76708,
      "latitude": 31.639896,
      "longitude": -97.266208,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76710,
      "latitude": 31.51156,
      "longitude": -97.186682,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76711,
      "latitude": 31.517516,
      "longitude": -97.15473,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76712,
      "latitude": 31.539194,
      "longitude": -97.265089,
      "city": "Woodway",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76714,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76715,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76716,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76795,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76797,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76798,
      "latitude": 31.553646,
      "longitude": -97.203166,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76799,
      "latitude": 31.541116,
      "longitude": -97.16148,
      "city": "Waco",
      "state": "TX",
      "county": "Mclennan"
  },
  {
      "zipCode": 76801,
      "latitude": 31.775393,
      "longitude": -98.99153,
      "city": "Brownwood",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76802,
      "latitude": 31.787392,
      "longitude": -98.922858,
      "city": "Early",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76803,
      "latitude": 31.763882,
      "longitude": -98.936021,
      "city": "Brownwood",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76804,
      "latitude": 31.77419,
      "longitude": -99.09213,
      "city": "Brownwood",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76820,
      "latitude": 30.720372,
      "longitude": -99.100089,
      "city": "Art",
      "state": "TX",
      "county": "Mason"
  },
  {
      "zipCode": 76821,
      "latitude": 31.770615,
      "longitude": -99.911547,
      "city": "Ballinger",
      "state": "TX",
      "county": "Runnels"
  },
  {
      "zipCode": 76823,
      "latitude": 31.758771,
      "longitude": -99.038558,
      "city": "Bangs",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76824,
      "latitude": 31.246571,
      "longitude": -98.238344,
      "city": "Bend",
      "state": "TX",
      "county": "Lampasas"
  },
  {
      "zipCode": 76825,
      "latitude": 31.150939,
      "longitude": -99.337237,
      "city": "Brady",
      "state": "TX",
      "county": "Mcculloch"
  },
  {
      "zipCode": 76827,
      "latitude": 31.547322,
      "longitude": -99.091825,
      "city": "Brookesmith",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76828,
      "latitude": 31.745879,
      "longitude": -99.458967,
      "city": "Burkett",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76831,
      "latitude": 30.70395,
      "longitude": -98.657239,
      "city": "Castell",
      "state": "TX",
      "county": "Llano"
  },
  {
      "zipCode": 76832,
      "latitude": 30.965073,
      "longitude": -98.710661,
      "city": "Cherokee",
      "state": "TX",
      "county": "San Saba"
  },
  {
      "zipCode": 76834,
      "latitude": 31.896653,
      "longitude": -99.400197,
      "city": "Coleman",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76836,
      "latitude": 31.217152,
      "longitude": -99.347156,
      "city": "Doole",
      "state": "TX",
      "county": "Mcculloch"
  },
  {
      "zipCode": 76837,
      "latitude": 31.174061,
      "longitude": -99.876368,
      "city": "Eden",
      "state": "TX",
      "county": "Concho"
  },
  {
      "zipCode": 76841,
      "latitude": 30.931395,
      "longitude": -99.827483,
      "city": "Fort Mc Kavett",
      "state": "TX",
      "county": "Menard"
  },
  {
      "zipCode": 76842,
      "latitude": 30.719628,
      "longitude": -99.224184,
      "city": "Fredonia",
      "state": "TX",
      "county": "Mason"
  },
  {
      "zipCode": 76844,
      "latitude": 31.447214,
      "longitude": -98.458794,
      "city": "Goldthwaite",
      "state": "TX",
      "county": "Mills"
  },
  {
      "zipCode": 76845,
      "latitude": 31.528604,
      "longitude": -99.546975,
      "city": "Gouldbusk",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76848,
      "latitude": 30.89918,
      "longitude": -99.80024,
      "city": "Hext",
      "state": "TX",
      "county": "Menard"
  },
  {
      "zipCode": 76849,
      "latitude": 30.49687,
      "longitude": -99.776055,
      "city": "Junction",
      "state": "TX",
      "county": "Kimble"
  },
  {
      "zipCode": 76852,
      "latitude": 31.217152,
      "longitude": -99.347156,
      "city": "Lohn",
      "state": "TX",
      "county": "Mcculloch"
  },
  {
      "zipCode": 76853,
      "latitude": 31.218283,
      "longitude": -98.394921,
      "city": "Lometa",
      "state": "TX",
      "county": "Lampasas"
  },
  {
      "zipCode": 76854,
      "latitude": 30.498845,
      "longitude": -99.709428,
      "city": "London",
      "state": "TX",
      "county": "Kimble"
  },
  {
      "zipCode": 76855,
      "latitude": 31.333614,
      "longitude": -99.85837,
      "city": "Lowake",
      "state": "TX",
      "county": "Concho"
  },
  {
      "zipCode": 76856,
      "latitude": 30.752875,
      "longitude": -99.142096,
      "city": "Mason",
      "state": "TX",
      "county": "Mason"
  },
  {
      "zipCode": 76857,
      "latitude": 31.951238,
      "longitude": -98.927189,
      "city": "May",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76858,
      "latitude": 31.217152,
      "longitude": -99.347156,
      "city": "Melvin",
      "state": "TX",
      "county": "Mcculloch"
  },
  {
      "zipCode": 76859,
      "latitude": 30.918307,
      "longitude": -99.78049,
      "city": "Menard",
      "state": "TX",
      "county": "Menard"
  },
  {
      "zipCode": 76861,
      "latitude": 31.680297,
      "longitude": -100.147719,
      "city": "Miles",
      "state": "TX",
      "county": "Runnels"
  },
  {
      "zipCode": 76862,
      "latitude": 31.535046,
      "longitude": -99.710871,
      "city": "Millersview",
      "state": "TX",
      "county": "Concho"
  },
  {
      "zipCode": 76864,
      "latitude": 31.551904,
      "longitude": -98.639178,
      "city": "Mullin",
      "state": "TX",
      "county": "Mills"
  },
  {
      "zipCode": 76865,
      "latitude": 31.869872,
      "longitude": -100.163871,
      "city": "Norton",
      "state": "TX",
      "county": "Runnels"
  },
  {
      "zipCode": 76866,
      "latitude": 31.462315,
      "longitude": -100.014384,
      "city": "Paint Rock",
      "state": "TX",
      "county": "Concho"
  },
  {
      "zipCode": 76867,
      "latitude": 31.217152,
      "longitude": -99.347156,
      "city": "Pear Valley",
      "state": "TX",
      "county": "Mcculloch"
  },
  {
      "zipCode": 76869,
      "latitude": 30.719628,
      "longitude": -99.224184,
      "city": "Pontotoc",
      "state": "TX",
      "county": "Mason"
  },
  {
      "zipCode": 76870,
      "latitude": 31.477095,
      "longitude": -98.630919,
      "city": "Priddy",
      "state": "TX",
      "county": "Mills"
  },
  {
      "zipCode": 76871,
      "latitude": 31.300297,
      "longitude": -98.912791,
      "city": "Richland Springs",
      "state": "TX",
      "county": "San Saba"
  },
  {
      "zipCode": 76872,
      "latitude": 31.217152,
      "longitude": -99.347156,
      "city": "Rochelle",
      "state": "TX",
      "county": "Mcculloch"
  },
  {
      "zipCode": 76873,
      "latitude": 31.745879,
      "longitude": -99.458967,
      "city": "Rockwood",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76874,
      "latitude": 30.498845,
      "longitude": -99.709428,
      "city": "Roosevelt",
      "state": "TX",
      "county": "Kimble"
  },
  {
      "zipCode": 76875,
      "latitude": 31.668401,
      "longitude": -100.047062,
      "city": "Rowena",
      "state": "TX",
      "county": "Runnels"
  },
  {
      "zipCode": 76877,
      "latitude": 31.212412,
      "longitude": -98.723024,
      "city": "San Saba",
      "state": "TX",
      "county": "San Saba"
  },
  {
      "zipCode": 76878,
      "latitude": 31.737608,
      "longitude": -99.326291,
      "city": "Santa Anna",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76880,
      "latitude": 31.477095,
      "longitude": -98.630919,
      "city": "Star",
      "state": "TX",
      "county": "Mills"
  },
  {
      "zipCode": 76882,
      "latitude": 31.78326,
      "longitude": -99.631855,
      "city": "Talpa",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76883,
      "latitude": 29.956952,
      "longitude": -100.227509,
      "city": "Telegraph",
      "state": "TX",
      "county": "Edwards"
  },
  {
      "zipCode": 76884,
      "latitude": 31.745879,
      "longitude": -99.458967,
      "city": "Valera",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76885,
      "latitude": 30.70395,
      "longitude": -98.657239,
      "city": "Valley Spring",
      "state": "TX",
      "county": "Llano"
  },
  {
      "zipCode": 76886,
      "latitude": 31.349348,
      "longitude": -100.494912,
      "city": "Veribest",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76887,
      "latitude": 31.217152,
      "longitude": -99.347156,
      "city": "Voca",
      "state": "TX",
      "county": "Mcculloch"
  },
  {
      "zipCode": 76888,
      "latitude": 31.745879,
      "longitude": -99.458967,
      "city": "Voss",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 76890,
      "latitude": 31.692764,
      "longitude": -98.80701,
      "city": "Zephyr",
      "state": "TX",
      "county": "Brown"
  },
  {
      "zipCode": 76901,
      "latitude": 31.44451,
      "longitude": -100.533397,
      "city": "San Angelo",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76902,
      "latitude": 31.39577,
      "longitude": -100.68959,
      "city": "San Angelo",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76903,
      "latitude": 31.505333,
      "longitude": -100.301959,
      "city": "San Angelo",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76904,
      "latitude": 31.342589,
      "longitude": -100.443556,
      "city": "San Angelo",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76905,
      "latitude": 31.441909,
      "longitude": -100.301587,
      "city": "San Angelo",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76906,
      "latitude": 31.372754,
      "longitude": -100.495114,
      "city": "San Angelo",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76908,
      "latitude": 31.39577,
      "longitude": -100.68959,
      "city": "Goodfellow Afb",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76909,
      "latitude": 31.39577,
      "longitude": -100.68959,
      "city": "San Angelo",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76930,
      "latitude": 31.143346,
      "longitude": -101.126017,
      "city": "Barnhart",
      "state": "TX",
      "county": "Irion"
  },
  {
      "zipCode": 76932,
      "latitude": 31.417011,
      "longitude": -101.5423,
      "city": "Big Lake",
      "state": "TX",
      "county": "Reagan"
  },
  {
      "zipCode": 76933,
      "latitude": 31.854544,
      "longitude": -100.343161,
      "city": "Bronte",
      "state": "TX",
      "county": "Coke"
  },
  {
      "zipCode": 76934,
      "latitude": 31.626621,
      "longitude": -100.669391,
      "city": "Carlsbad",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76935,
      "latitude": 31.188841,
      "longitude": -100.544674,
      "city": "Christoval",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76936,
      "latitude": 30.912773,
      "longitude": -100.676722,
      "city": "Eldorado",
      "state": "TX",
      "county": "Schleicher"
  },
  {
      "zipCode": 76937,
      "latitude": 31.361745,
      "longitude": -100.092229,
      "city": "Eola",
      "state": "TX",
      "county": "Concho"
  },
  {
      "zipCode": 76939,
      "latitude": 31.250101,
      "longitude": -100.592015,
      "city": "Knickerbocker",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76940,
      "latitude": 31.480347,
      "longitude": -100.143724,
      "city": "Mereta",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76941,
      "latitude": 31.347682,
      "longitude": -100.874701,
      "city": "Mertzon",
      "state": "TX",
      "county": "Irion"
  },
  {
      "zipCode": 76943,
      "latitude": 30.798496,
      "longitude": -101.498604,
      "city": "Ozona",
      "state": "TX",
      "county": "Crockett"
  },
  {
      "zipCode": 76945,
      "latitude": 31.911149,
      "longitude": -100.487299,
      "city": "Robert Lee",
      "state": "TX",
      "county": "Coke"
  },
  {
      "zipCode": 76949,
      "latitude": 31.889601,
      "longitude": -100.530253,
      "city": "Silver",
      "state": "TX",
      "county": "Coke"
  },
  {
      "zipCode": 76950,
      "latitude": 30.579386,
      "longitude": -100.671422,
      "city": "Sonora",
      "state": "TX",
      "county": "Sutton"
  },
  {
      "zipCode": 76951,
      "latitude": 31.882997,
      "longitude": -101.030277,
      "city": "Sterling City",
      "state": "TX",
      "county": "Sterling"
  },
  {
      "zipCode": 76953,
      "latitude": 31.739714,
      "longitude": -100.288365,
      "city": "Tennyson",
      "state": "TX",
      "county": "Coke"
  },
  {
      "zipCode": 76955,
      "latitude": 31.32795,
      "longitude": -100.154738,
      "city": "Vancourt",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76957,
      "latitude": 31.359844,
      "longitude": -100.206705,
      "city": "Wall",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 76958,
      "latitude": 31.635184,
      "longitude": -100.658982,
      "city": "Water Valley",
      "state": "TX",
      "county": "Tom Green"
  },
  {
      "zipCode": 77001,
      "latitude": 29.813142,
      "longitude": -95.309789,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77002,
      "latitude": 29.807651,
      "longitude": -95.391447,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77003,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77004,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77005,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77006,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77007,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77008,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77009,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77010,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77011,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77012,
      "latitude": 29.711528,
      "longitude": -95.251662,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77013,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77014,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77015,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77016,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77017,
      "latitude": 29.679879,
      "longitude": -95.257113,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77018,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77019,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77020,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77021,
      "latitude": 29.67843,
      "longitude": -95.373966,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77022,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77023,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77024,
      "latitude": 29.824899,
      "longitude": -95.421967,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77025,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77026,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77027,
      "latitude": 29.688374,
      "longitude": -95.303129,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77028,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77029,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77030,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77031,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77032,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77033,
      "latitude": 29.66868,
      "longitude": -95.337865,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77034,
      "latitude": 29.61903,
      "longitude": -95.197354,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77035,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77036,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77037,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77038,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77039,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77040,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77041,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77042,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77043,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77044,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77045,
      "latitude": 29.642031,
      "longitude": -95.403617,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77046,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77047,
      "latitude": 29.614782,
      "longitude": -95.387721,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77048,
      "latitude": 29.625781,
      "longitude": -95.340665,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77049,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77050,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77051,
      "latitude": 29.65833,
      "longitude": -95.381407,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77052,
      "latitude": 29.676829,
      "longitude": -95.177587,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77053,
      "latitude": 29.600732,
      "longitude": -95.424818,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77054,
      "latitude": 29.67543,
      "longitude": -95.391466,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77055,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77056,
      "latitude": 29.698579,
      "longitude": -95.207161,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77057,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77058,
      "latitude": 29.571604,
      "longitude": -95.099828,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77059,
      "latitude": 29.576505,
      "longitude": -95.120937,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77060,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77061,
      "latitude": 29.65688,
      "longitude": -95.28316,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77062,
      "latitude": 29.571478,
      "longitude": -95.131719,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77063,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77064,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77065,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77066,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77067,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77068,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77069,
      "latitude": 29.781938,
      "longitude": -95.335551,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77070,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77071,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77072,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77073,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77074,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77075,
      "latitude": 29.623734,
      "longitude": -95.262863,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77076,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77077,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77078,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77079,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77080,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77081,
      "latitude": 29.68193,
      "longitude": -95.306914,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77082,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77083,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77084,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77085,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77086,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77087,
      "latitude": 29.676829,
      "longitude": -95.297913,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77088,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77089,
      "latitude": 29.58949,
      "longitude": -95.235712,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77090,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77091,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77092,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77093,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77094,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77095,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77096,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77097,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77098,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77099,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77201,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77202,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77203,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77204,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77205,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77206,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77207,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77208,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77209,
      "latitude": 29.612816,
      "longitude": -95.158517,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77210,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77212,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77213,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77215,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77216,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77217,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77218,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77219,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77220,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77221,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77222,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77223,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77224,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77225,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77226,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77227,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77228,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77229,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77230,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77231,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77233,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77234,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77235,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77236,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77237,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77238,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77240,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77241,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77242,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77243,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77244,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77245,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77248,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77249,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77251,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77252,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77253,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77254,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77255,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77256,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77257,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77258,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77259,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77261,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77262,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77263,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77265,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77266,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77267,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77268,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77269,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77270,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77271,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77272,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77273,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77274,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77275,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77277,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77279,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77280,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77281,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77282,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77284,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77287,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77288,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77289,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77290,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77291,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77292,
      "latitude": 29.744341,
      "longitude": -95.332598,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77293,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77297,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77298,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77299,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77301,
      "latitude": 30.310041,
      "longitude": -95.51232,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77302,
      "latitude": 30.223802,
      "longitude": -95.357716,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77303,
      "latitude": 30.334581,
      "longitude": -95.480387,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77304,
      "latitude": 30.276638,
      "longitude": -95.444823,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77305,
      "latitude": 30.290638,
      "longitude": -95.383202,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77306,
      "latitude": 30.227669,
      "longitude": -95.285136,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77315,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "North Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77316,
      "latitude": 30.358652,
      "longitude": -95.685746,
      "city": "Montgomery",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77318,
      "latitude": 30.43794,
      "longitude": -95.490719,
      "city": "Willis",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77320,
      "latitude": 30.846986,
      "longitude": -95.597029,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77325,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Humble",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77326,
      "latitude": 30.520924,
      "longitude": -94.822101,
      "city": "Ace",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 77327,
      "latitude": 30.343114,
      "longitude": -94.978471,
      "city": "Cleveland",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77328,
      "latitude": 30.188885,
      "longitude": -94.804065,
      "city": "Cleveland",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77331,
      "latitude": 30.588554,
      "longitude": -95.111278,
      "city": "Coldspring",
      "state": "TX",
      "county": "San Jacinto"
  },
  {
      "zipCode": 77332,
      "latitude": 30.817866,
      "longitude": -94.869052,
      "city": "Dallardsville",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 77333,
      "latitude": 30.374064,
      "longitude": -95.79387,
      "city": "Dobbin",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77334,
      "latitude": 30.77197,
      "longitude": -95.383817,
      "city": "Dodge",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77335,
      "latitude": 30.594279,
      "longitude": -94.904791,
      "city": "Goodrich",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 77336,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Huffman",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77337,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Hufsmith",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77338,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Humble",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77339,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Humble",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77340,
      "latitude": 30.644755,
      "longitude": -95.579836,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77341,
      "latitude": 30.78128,
      "longitude": -95.59527,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77342,
      "latitude": 30.78128,
      "longitude": -95.59527,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77343,
      "latitude": 30.78128,
      "longitude": -95.59527,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77344,
      "latitude": 30.78128,
      "longitude": -95.59527,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77345,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Humble",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77346,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Humble",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77347,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Humble",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77348,
      "latitude": 30.78128,
      "longitude": -95.59527,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77349,
      "latitude": 30.78128,
      "longitude": -95.59527,
      "city": "Huntsville",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77350,
      "latitude": 30.856814,
      "longitude": -94.856052,
      "city": "Leggett",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 77351,
      "latitude": 30.682947,
      "longitude": -94.897605,
      "city": "Livingston",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 77353,
      "latitude": 30.180626,
      "longitude": -95.70925,
      "city": "Magnolia",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77354,
      "latitude": 30.233328,
      "longitude": -95.550188,
      "city": "Magnolia",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77355,
      "latitude": 30.182467,
      "longitude": -95.679174,
      "city": "Magnolia",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77356,
      "latitude": 30.354902,
      "longitude": -95.49833,
      "city": "Montgomery",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77357,
      "latitude": 30.27931,
      "longitude": -95.436337,
      "city": "New Caney",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77358,
      "latitude": 30.550542,
      "longitude": -95.466582,
      "city": "New Waverly",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77359,
      "latitude": 30.674933,
      "longitude": -95.290469,
      "city": "Oakhurst",
      "state": "TX",
      "county": "San Jacinto"
  },
  {
      "zipCode": 77360,
      "latitude": 30.822525,
      "longitude": -95.105581,
      "city": "Onalaska",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 77362,
      "latitude": 30.179187,
      "longitude": -95.692288,
      "city": "Pinehurst",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77363,
      "latitude": 30.288721,
      "longitude": -95.856892,
      "city": "Plantersville",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77364,
      "latitude": 30.755812,
      "longitude": -95.218676,
      "city": "Pointblank",
      "state": "TX",
      "county": "San Jacinto"
  },
  {
      "zipCode": 77365,
      "latitude": 30.21308,
      "longitude": -95.400036,
      "city": "Porter",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77367,
      "latitude": 30.847563,
      "longitude": -95.390439,
      "city": "Riverside",
      "state": "TX",
      "county": "Walker"
  },
  {
      "zipCode": 77368,
      "latitude": 30.188885,
      "longitude": -94.804065,
      "city": "Romayor",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77369,
      "latitude": 30.188885,
      "longitude": -94.804065,
      "city": "Rye",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77371,
      "latitude": 30.632802,
      "longitude": -95.085922,
      "city": "Shepherd",
      "state": "TX",
      "county": "San Jacinto"
  },
  {
      "zipCode": 77372,
      "latitude": 30.255457,
      "longitude": -95.183281,
      "city": "Splendora",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77373,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Spring",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77374,
      "latitude": 30.312185,
      "longitude": -94.401843,
      "city": "Thicket",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77375,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Tomball",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77376,
      "latitude": 30.426417,
      "longitude": -94.652109,
      "city": "Votaw",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77377,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Tomball",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77378,
      "latitude": 30.444115,
      "longitude": -95.450554,
      "city": "Willis",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77379,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Spring",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77380,
      "latitude": 30.144112,
      "longitude": -95.470336,
      "city": "Spring",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77381,
      "latitude": 30.171573,
      "longitude": -95.498497,
      "city": "Spring",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77382,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Spring",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77383,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Spring",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77384,
      "latitude": 30.225997,
      "longitude": -95.499935,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77385,
      "latitude": 30.191153,
      "longitude": -95.419023,
      "city": "Conroe",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77386,
      "latitude": 30.124558,
      "longitude": -95.401815,
      "city": "Spring",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77387,
      "latitude": 30.378446,
      "longitude": -95.557004,
      "city": "Spring",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77388,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Spring",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77389,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Spring",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77391,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Spring",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77393,
      "latitude": 30.329016,
      "longitude": -95.463474,
      "city": "Spring",
      "state": "TX",
      "county": "Montgomery"
  },
  {
      "zipCode": 77396,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Humble",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77399,
      "latitude": 30.817866,
      "longitude": -94.869052,
      "city": "Livingston",
      "state": "TX",
      "county": "Polk"
  },
  {
      "zipCode": 77401,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Bellaire",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77402,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Bellaire",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77404,
      "latitude": 28.798156,
      "longitude": -95.651058,
      "city": "Bay City",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77406,
      "latitude": 29.50401,
      "longitude": -95.919107,
      "city": "Richmond",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77410,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Cypress",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77411,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Alief",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77412,
      "latitude": 29.60466,
      "longitude": -96.524899,
      "city": "Altair",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 77413,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Barker",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77414,
      "latitude": 28.86362,
      "longitude": -95.917347,
      "city": "Bay City",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77415,
      "latitude": 28.815437,
      "longitude": -95.846061,
      "city": "Cedar Lane",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77417,
      "latitude": 29.482797,
      "longitude": -95.974399,
      "city": "Beasley",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77418,
      "latitude": 29.864772,
      "longitude": -96.321766,
      "city": "Bellville",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 77419,
      "latitude": 28.754719,
      "longitude": -95.945896,
      "city": "Blessing",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77420,
      "latitude": 29.240304,
      "longitude": -95.954627,
      "city": "Boling",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77422,
      "latitude": 28.995948,
      "longitude": -95.520413,
      "city": "Brazoria",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77423,
      "latitude": 29.85915,
      "longitude": -95.98022,
      "city": "Brookshire",
      "state": "TX",
      "county": "Waller"
  },
  {
      "zipCode": 77426,
      "latitude": 30.156759,
      "longitude": -96.278602,
      "city": "Chappell Hill",
      "state": "TX",
      "county": "Washington"
  },
  {
      "zipCode": 77428,
      "latitude": 28.754719,
      "longitude": -95.945896,
      "city": "Collegeport",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77429,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Cypress",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77430,
      "latitude": 29.235317,
      "longitude": -95.677475,
      "city": "Damon",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77431,
      "latitude": 29.173701,
      "longitude": -95.820719,
      "city": "Danciger",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77432,
      "latitude": 29.298532,
      "longitude": -96.241147,
      "city": "Danevang",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77433,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Cypress",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77434,
      "latitude": 29.590148,
      "longitude": -96.35207,
      "city": "Eagle Lake",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 77435,
      "latitude": 29.484089,
      "longitude": -96.093732,
      "city": "East Bernard",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77436,
      "latitude": 29.375762,
      "longitude": -96.227967,
      "city": "Egypt",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77437,
      "latitude": 29.322527,
      "longitude": -96.234361,
      "city": "El Campo",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77440,
      "latitude": 28.754719,
      "longitude": -95.945896,
      "city": "Elmaton",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77441,
      "latitude": 29.688985,
      "longitude": -95.935279,
      "city": "Fulshear",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77442,
      "latitude": 29.447899,
      "longitude": -96.397296,
      "city": "Garwood",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 77443,
      "latitude": 29.335331,
      "longitude": -96.175363,
      "city": "Glen Flora",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77444,
      "latitude": 29.349957,
      "longitude": -95.77215,
      "city": "Guy",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77445,
      "latitude": 30.02851,
      "longitude": -96.02782,
      "city": "Hempstead",
      "state": "TX",
      "county": "Waller"
  },
  {
      "zipCode": 77446,
      "latitude": 30.082135,
      "longitude": -96.013596,
      "city": "Prairie View",
      "state": "TX",
      "county": "Waller"
  },
  {
      "zipCode": 77447,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Hockley",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77448,
      "latitude": 29.413676,
      "longitude": -96.092868,
      "city": "Hungerford",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77449,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Katy",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77450,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Katy",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77451,
      "latitude": 29.466642,
      "longitude": -95.994475,
      "city": "Kendleton",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77452,
      "latitude": 29.849283,
      "longitude": -96.313271,
      "city": "Kenney",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 77453,
      "latitude": 29.170387,
      "longitude": -96.014159,
      "city": "Lane City",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77454,
      "latitude": 29.298532,
      "longitude": -96.241147,
      "city": "Lissie",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77455,
      "latitude": 29.113458,
      "longitude": -96.408664,
      "city": "Louise",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77456,
      "latitude": 28.962723,
      "longitude": -96.068212,
      "city": "Markham",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77457,
      "latitude": 28.649645,
      "longitude": -95.962269,
      "city": "Matagorda",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77458,
      "latitude": 28.941283,
      "longitude": -96.217884,
      "city": "Midfield",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77459,
      "latitude": 29.323965,
      "longitude": -95.649939,
      "city": "Missouri City",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77460,
      "latitude": 29.60466,
      "longitude": -96.524899,
      "city": "Nada",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 77461,
      "latitude": 29.423517,
      "longitude": -95.771651,
      "city": "Needville",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77462,
      "latitude": 29.298532,
      "longitude": -96.241147,
      "city": "Newgulf",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77463,
      "latitude": 29.130819,
      "longitude": -95.791554,
      "city": "Old Ocean",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77464,
      "latitude": 29.503446,
      "longitude": -95.904066,
      "city": "Orchard",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77465,
      "latitude": 28.934196,
      "longitude": -96.075063,
      "city": "Palacios",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77466,
      "latitude": 29.817257,
      "longitude": -96.007261,
      "city": "Pattison",
      "state": "TX",
      "county": "Waller"
  },
  {
      "zipCode": 77467,
      "latitude": 29.298532,
      "longitude": -96.241147,
      "city": "Pierce",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77468,
      "latitude": 28.949429,
      "longitude": -96.052347,
      "city": "Pledger",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77469,
      "latitude": 29.555831,
      "longitude": -95.788479,
      "city": "Richmond",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77470,
      "latitude": 29.60466,
      "longitude": -96.524899,
      "city": "Rock Island",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 77471,
      "latitude": 29.555889,
      "longitude": -95.858475,
      "city": "Rosenberg",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77473,
      "latitude": 29.792014,
      "longitude": -96.097261,
      "city": "San Felipe",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 77474,
      "latitude": 29.845826,
      "longitude": -96.317806,
      "city": "Sealy",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 77475,
      "latitude": 29.60466,
      "longitude": -96.524899,
      "city": "Sheridan",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 77476,
      "latitude": 29.667486,
      "longitude": -95.965953,
      "city": "Simonton",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77477,
      "latitude": 29.450518,
      "longitude": -95.996865,
      "city": "Stafford",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77478,
      "latitude": 29.525461,
      "longitude": -95.756462,
      "city": "Sugar Land",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77479,
      "latitude": 29.552921,
      "longitude": -95.666778,
      "city": "Sugar Land",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77480,
      "latitude": 29.100012,
      "longitude": -95.744623,
      "city": "Sweeny",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77481,
      "latitude": 29.489921,
      "longitude": -95.594174,
      "city": "Thompsons",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77482,
      "latitude": 29.015988,
      "longitude": -95.890495,
      "city": "Van Vleck",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77483,
      "latitude": 28.754719,
      "longitude": -95.945896,
      "city": "Wadsworth",
      "state": "TX",
      "county": "Matagorda"
  },
  {
      "zipCode": 77484,
      "latitude": 30.087937,
      "longitude": -95.948512,
      "city": "Waller",
      "state": "TX",
      "county": "Waller"
  },
  {
      "zipCode": 77485,
      "latitude": 29.789915,
      "longitude": -96.279263,
      "city": "Wallis",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 77486,
      "latitude": 29.122892,
      "longitude": -95.684009,
      "city": "West Columbia",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77487,
      "latitude": 29.525461,
      "longitude": -95.756462,
      "city": "Sugar Land",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77488,
      "latitude": 29.323344,
      "longitude": -96.091123,
      "city": "Wharton",
      "state": "TX",
      "county": "Wharton"
  },
  {
      "zipCode": 77489,
      "latitude": 29.525461,
      "longitude": -95.756462,
      "city": "Missouri City",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77491,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Katy",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77492,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Katy",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77493,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Katy",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77494,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Katy",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77496,
      "latitude": 29.525461,
      "longitude": -95.756462,
      "city": "Sugar Land",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77497,
      "latitude": 29.525461,
      "longitude": -95.756462,
      "city": "Stafford",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77501,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77502,
      "latitude": 29.678179,
      "longitude": -95.202911,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77503,
      "latitude": 29.65955,
      "longitude": -95.169129,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77504,
      "latitude": 29.645312,
      "longitude": -95.176291,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77505,
      "latitude": 29.648731,
      "longitude": -95.143911,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77506,
      "latitude": 29.714385,
      "longitude": -95.200011,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77507,
      "latitude": 29.622436,
      "longitude": -95.054456,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77508,
      "latitude": 29.569927,
      "longitude": -95.106637,
      "city": "Pasadena",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77510,
      "latitude": 29.403211,
      "longitude": -95.073408,
      "city": "Santa Fe",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77511,
      "latitude": 29.337714,
      "longitude": -95.406048,
      "city": "Alvin",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77512,
      "latitude": 29.362879,
      "longitude": -95.27605,
      "city": "Alvin",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77514,
      "latitude": 29.662044,
      "longitude": -94.592995,
      "city": "Anahuac",
      "state": "TX",
      "county": "Chambers"
  },
  {
      "zipCode": 77515,
      "latitude": 29.154269,
      "longitude": -95.406577,
      "city": "Angleton",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77516,
      "latitude": 29.183991,
      "longitude": -95.465083,
      "city": "Angleton",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77517,
      "latitude": 29.406632,
      "longitude": -95.084661,
      "city": "Santa Fe",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77518,
      "latitude": 29.497095,
      "longitude": -94.961157,
      "city": "Bacliff",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77519,
      "latitude": 30.220893,
      "longitude": -94.60237,
      "city": "Batson",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77520,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Baytown",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77521,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Baytown",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77522,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Baytown",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77530,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Channelview",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77531,
      "latitude": 29.031236,
      "longitude": -95.3908,
      "city": "Clute",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77532,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Crosby",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77533,
      "latitude": 30.105083,
      "longitude": -94.856128,
      "city": "Daisetta",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77534,
      "latitude": 29.319781,
      "longitude": -95.309176,
      "city": "Danbury",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77535,
      "latitude": 30.064742,
      "longitude": -94.80927,
      "city": "Dayton",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77536,
      "latitude": 29.687972,
      "longitude": -95.116749,
      "city": "Deer Park",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77538,
      "latitude": 29.990724,
      "longitude": -94.723055,
      "city": "Devers",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77539,
      "latitude": 29.458472,
      "longitude": -95.034525,
      "city": "Dickinson",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77541,
      "latitude": 29.161924,
      "longitude": -95.342392,
      "city": "Freeport",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77542,
      "latitude": 29.183991,
      "longitude": -95.465083,
      "city": "Freeport",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77545,
      "latitude": 29.525461,
      "longitude": -95.756462,
      "city": "Fresno",
      "state": "TX",
      "county": "Fort Bend"
  },
  {
      "zipCode": 77546,
      "latitude": 29.482185,
      "longitude": -95.156545,
      "city": "Friendswood",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77547,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Galena Park",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77549,
      "latitude": 29.330501,
      "longitude": -94.800238,
      "city": "Friendswood",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77550,
      "latitude": 29.323619,
      "longitude": -94.913451,
      "city": "Galveston",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77551,
      "latitude": 29.212008,
      "longitude": -94.933849,
      "city": "Galveston",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77552,
      "latitude": 29.22051,
      "longitude": -94.944391,
      "city": "Galveston",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77553,
      "latitude": 29.195607,
      "longitude": -94.981554,
      "city": "Galveston",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77554,
      "latitude": 29.277856,
      "longitude": -94.956217,
      "city": "Galveston",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77555,
      "latitude": 29.330501,
      "longitude": -94.800238,
      "city": "Galveston",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77560,
      "latitude": 29.693181,
      "longitude": -94.685815,
      "city": "Hankamer",
      "state": "TX",
      "county": "Chambers"
  },
  {
      "zipCode": 77561,
      "latitude": 30.150955,
      "longitude": -94.733789,
      "city": "Hardin",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77562,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "Highlands",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77563,
      "latitude": 29.344488,
      "longitude": -94.990471,
      "city": "Hitchcock",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77564,
      "latitude": 30.122197,
      "longitude": -94.667832,
      "city": "Hull",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77565,
      "latitude": 29.465064,
      "longitude": -95.053246,
      "city": "Kemah",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77566,
      "latitude": 29.09609,
      "longitude": -95.425232,
      "city": "Lake Jackson",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77568,
      "latitude": 29.374626,
      "longitude": -94.998361,
      "city": "La Marque",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77571,
      "latitude": 29.688447,
      "longitude": -95.051319,
      "city": "La Porte",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77572,
      "latitude": 29.83399,
      "longitude": -95.434241,
      "city": "La Porte",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77573,
      "latitude": 29.496451,
      "longitude": -95.052262,
      "city": "League City",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77574,
      "latitude": 29.511582,
      "longitude": -95.058153,
      "city": "League City",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77575,
      "latitude": 30.094593,
      "longitude": -94.737806,
      "city": "Liberty",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77577,
      "latitude": 29.266699,
      "longitude": -95.288863,
      "city": "Liverpool",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77578,
      "latitude": 29.478468,
      "longitude": -95.374419,
      "city": "Manvel",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77580,
      "latitude": 29.856137,
      "longitude": -94.842939,
      "city": "Mont Belvieu",
      "state": "TX",
      "county": "Chambers"
  },
  {
      "zipCode": 77581,
      "latitude": 29.328311,
      "longitude": -95.316425,
      "city": "Pearland",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77582,
      "latitude": 30.054158,
      "longitude": -94.676436,
      "city": "Raywood",
      "state": "TX",
      "county": "Liberty"
  },
  {
      "zipCode": 77583,
      "latitude": 29.42501,
      "longitude": -95.448799,
      "city": "Rosharon",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77584,
      "latitude": 29.54017,
      "longitude": -95.33232,
      "city": "Pearland",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77585,
      "latitude": 30.309518,
      "longitude": -94.477059,
      "city": "Saratoga",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77586,
      "latitude": 29.602041,
      "longitude": -95.101012,
      "city": "Seabrook",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77587,
      "latitude": 29.662579,
      "longitude": -95.229511,
      "city": "South Houston",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77588,
      "latitude": 29.512687,
      "longitude": -95.254188,
      "city": "Pearland",
      "state": "TX",
      "county": "Brazoria"
  },
  {
      "zipCode": 77590,
      "latitude": 29.376057,
      "longitude": -94.921474,
      "city": "Texas City",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77591,
      "latitude": 29.427468,
      "longitude": -94.969173,
      "city": "Texas City",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77592,
      "latitude": 29.330501,
      "longitude": -94.800238,
      "city": "Texas City",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77597,
      "latitude": 29.811188,
      "longitude": -94.682325,
      "city": "Wallisville",
      "state": "TX",
      "county": "Chambers"
  },
  {
      "zipCode": 77598,
      "latitude": 29.550411,
      "longitude": -95.128758,
      "city": "Webster",
      "state": "TX",
      "county": "Harris"
  },
  {
      "zipCode": 77611,
      "latitude": 30.019163,
      "longitude": -93.832632,
      "city": "Bridge City",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77612,
      "latitude": 30.493361,
      "longitude": -93.951837,
      "city": "Buna",
      "state": "TX",
      "county": "Jasper"
  },
  {
      "zipCode": 77613,
      "latitude": 30.025795,
      "longitude": -94.197989,
      "city": "China",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77614,
      "latitude": 30.584648,
      "longitude": -93.749467,
      "city": "Deweyville",
      "state": "TX",
      "county": "Newton"
  },
  {
      "zipCode": 77615,
      "latitude": 30.69986,
      "longitude": -94.163,
      "city": "Evadale",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77616,
      "latitude": 30.792674,
      "longitude": -94.354476,
      "city": "Fred",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 77617,
      "latitude": 29.523018,
      "longitude": -94.475459,
      "city": "Gilchrist",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77619,
      "latitude": 29.941563,
      "longitude": -93.916957,
      "city": "Groves",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77622,
      "latitude": 29.851906,
      "longitude": -94.289265,
      "city": "Hamshire",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77623,
      "latitude": 29.547224,
      "longitude": -94.426731,
      "city": "High Island",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77624,
      "latitude": 30.675022,
      "longitude": -94.353409,
      "city": "Hillister",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 77625,
      "latitude": 30.361001,
      "longitude": -94.37408,
      "city": "Kountze",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77626,
      "latitude": 30.203996,
      "longitude": -93.886646,
      "city": "Mauriceville",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77627,
      "latitude": 29.992248,
      "longitude": -94.195448,
      "city": "Nederland",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77629,
      "latitude": 29.993788,
      "longitude": -94.367034,
      "city": "Nome",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77630,
      "latitude": 30.135425,
      "longitude": -93.860282,
      "city": "Orange",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77631,
      "latitude": 30.054793,
      "longitude": -93.903108,
      "city": "Orange",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77632,
      "latitude": 30.17752,
      "longitude": -93.840937,
      "city": "Orange",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77639,
      "latitude": 30.063101,
      "longitude": -93.859903,
      "city": "Orangefield",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77640,
      "latitude": 29.870904,
      "longitude": -93.964278,
      "city": "Port Arthur",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77641,
      "latitude": 29.847569,
      "longitude": -94.129733,
      "city": "Port Arthur",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77642,
      "latitude": 29.864207,
      "longitude": -93.942055,
      "city": "Port Arthur",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77643,
      "latitude": 29.962144,
      "longitude": -93.867932,
      "city": "Port Arthur",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77650,
      "latitude": 29.437631,
      "longitude": -94.632252,
      "city": "Port Bolivar",
      "state": "TX",
      "county": "Galveston"
  },
  {
      "zipCode": 77651,
      "latitude": 30.005205,
      "longitude": -94.132182,
      "city": "Port Neches",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77655,
      "latitude": 29.782283,
      "longitude": -94.091127,
      "city": "Sabine Pass",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77656,
      "latitude": 30.327169,
      "longitude": -94.274154,
      "city": "Silsbee",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77657,
      "latitude": 30.281836,
      "longitude": -94.219122,
      "city": "Lumberton",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77659,
      "latitude": 30.238876,
      "longitude": -94.403654,
      "city": "Sour Lake",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77660,
      "latitude": 30.792674,
      "longitude": -94.354476,
      "city": "Spurger",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 77661,
      "latitude": 29.780948,
      "longitude": -94.390045,
      "city": "Stowell",
      "state": "TX",
      "county": "Chambers"
  },
  {
      "zipCode": 77662,
      "latitude": 30.15549,
      "longitude": -93.935765,
      "city": "Vidor",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77663,
      "latitude": 30.518504,
      "longitude": -94.4458,
      "city": "Village Mills",
      "state": "TX",
      "county": "Hardin"
  },
  {
      "zipCode": 77664,
      "latitude": 30.634714,
      "longitude": -94.380858,
      "city": "Warren",
      "state": "TX",
      "county": "Tyler"
  },
  {
      "zipCode": 77665,
      "latitude": 29.823383,
      "longitude": -94.475364,
      "city": "Winnie",
      "state": "TX",
      "county": "Chambers"
  },
  {
      "zipCode": 77670,
      "latitude": 30.054793,
      "longitude": -93.903108,
      "city": "Vidor",
      "state": "TX",
      "county": "Orange"
  },
  {
      "zipCode": 77701,
      "latitude": 30.073712,
      "longitude": -94.109705,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77702,
      "latitude": 30.084112,
      "longitude": -94.126444,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77703,
      "latitude": 30.113761,
      "longitude": -94.120426,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77704,
      "latitude": 30.12355,
      "longitude": -94.153941,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77705,
      "latitude": 29.928158,
      "longitude": -94.189854,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77706,
      "latitude": 30.115295,
      "longitude": -94.157916,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77707,
      "latitude": 30.009716,
      "longitude": -94.162252,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77708,
      "latitude": 30.148461,
      "longitude": -94.172481,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77709,
      "latitude": 30.176361,
      "longitude": -94.187683,
      "city": "Voth",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77710,
      "latitude": 29.847569,
      "longitude": -94.129733,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77713,
      "latitude": 30.069437,
      "longitude": -94.260793,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77720,
      "latitude": 29.847569,
      "longitude": -94.129733,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77725,
      "latitude": 29.847569,
      "longitude": -94.129733,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77726,
      "latitude": 30.111843,
      "longitude": -94.190147,
      "city": "Beaumont",
      "state": "TX",
      "county": "Jefferson"
  },
  {
      "zipCode": 77801,
      "latitude": 30.667044,
      "longitude": -96.361631,
      "city": "Bryan",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77802,
      "latitude": 30.620983,
      "longitude": -96.319377,
      "city": "Bryan",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77803,
      "latitude": 30.677338,
      "longitude": -96.395088,
      "city": "Bryan",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77805,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "Bryan",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77806,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "Bryan",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77807,
      "latitude": 30.671076,
      "longitude": -96.479892,
      "city": "Bryan",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77808,
      "latitude": 30.820187,
      "longitude": -96.305894,
      "city": "Bryan",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77830,
      "latitude": 30.562982,
      "longitude": -95.981285,
      "city": "Anderson",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77831,
      "latitude": 30.706523,
      "longitude": -95.954649,
      "city": "Bedias",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77833,
      "latitude": 30.216516,
      "longitude": -96.385104,
      "city": "Brenham",
      "state": "TX",
      "county": "Washington"
  },
  {
      "zipCode": 77834,
      "latitude": 30.231333,
      "longitude": -96.290358,
      "city": "Brenham",
      "state": "TX",
      "county": "Washington"
  },
  {
      "zipCode": 77835,
      "latitude": 30.179152,
      "longitude": -96.628566,
      "city": "Burton",
      "state": "TX",
      "county": "Washington"
  },
  {
      "zipCode": 77836,
      "latitude": 30.526184,
      "longitude": -96.658407,
      "city": "Caldwell",
      "state": "TX",
      "county": "Burleson"
  },
  {
      "zipCode": 77837,
      "latitude": 30.980617,
      "longitude": -96.674911,
      "city": "Calvert",
      "state": "TX",
      "county": "Robertson"
  },
  {
      "zipCode": 77838,
      "latitude": 30.513118,
      "longitude": -96.618047,
      "city": "Chriesman",
      "state": "TX",
      "county": "Burleson"
  },
  {
      "zipCode": 77839,
      "latitude": 30.341403,
      "longitude": -96.526669,
      "city": "Clay",
      "state": "TX",
      "county": "Burleson"
  },
  {
      "zipCode": 77840,
      "latitude": 30.582241,
      "longitude": -96.289328,
      "city": "College Station",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77841,
      "latitude": 30.57258,
      "longitude": -96.327044,
      "city": "College Station",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77842,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "College Station",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77843,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "College Station",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77844,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "College Station",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77845,
      "latitude": 30.579234,
      "longitude": -96.293826,
      "city": "College Station",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77850,
      "latitude": 31.313816,
      "longitude": -95.993482,
      "city": "Concord",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 77852,
      "latitude": 30.513118,
      "longitude": -96.618047,
      "city": "Deanville",
      "state": "TX",
      "county": "Burleson"
  },
  {
      "zipCode": 77853,
      "latitude": 30.277104,
      "longitude": -96.892115,
      "city": "Dime Box",
      "state": "TX",
      "county": "Lee"
  },
  {
      "zipCode": 77855,
      "latitude": 31.15928,
      "longitude": -96.12342,
      "city": "Flynn",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 77856,
      "latitude": 31.000137,
      "longitude": -96.517098,
      "city": "Franklin",
      "state": "TX",
      "county": "Robertson"
  },
  {
      "zipCode": 77857,
      "latitude": 30.783324,
      "longitude": -96.723691,
      "city": "Gause",
      "state": "TX",
      "county": "Milam"
  },
  {
      "zipCode": 77859,
      "latitude": 30.925686,
      "longitude": -96.581229,
      "city": "Hearne",
      "state": "TX",
      "county": "Robertson"
  },
  {
      "zipCode": 77861,
      "latitude": 30.714476,
      "longitude": -96.09887,
      "city": "Iola",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77862,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "Kurten",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77863,
      "latitude": 30.359245,
      "longitude": -96.591535,
      "city": "Lyons",
      "state": "TX",
      "county": "Burleson"
  },
  {
      "zipCode": 77864,
      "latitude": 30.970828,
      "longitude": -95.890751,
      "city": "Madisonville",
      "state": "TX",
      "county": "Madison"
  },
  {
      "zipCode": 77865,
      "latitude": 31.253791,
      "longitude": -96.22305,
      "city": "Marquez",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 77866,
      "latitude": 30.47857,
      "longitude": -96.244718,
      "city": "Millican",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77867,
      "latitude": 31.026276,
      "longitude": -96.533031,
      "city": "Mumford",
      "state": "TX",
      "county": "Robertson"
  },
  {
      "zipCode": 77868,
      "latitude": 30.383369,
      "longitude": -96.008619,
      "city": "Navasota",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77869,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "Navasota",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77870,
      "latitude": 31.026276,
      "longitude": -96.533031,
      "city": "New Baden",
      "state": "TX",
      "county": "Robertson"
  },
  {
      "zipCode": 77871,
      "latitude": 31.12486,
      "longitude": -96.109604,
      "city": "Normangee",
      "state": "TX",
      "county": "Leon"
  },
  {
      "zipCode": 77872,
      "latitude": 30.913412,
      "longitude": -96.085341,
      "city": "North Zulch",
      "state": "TX",
      "county": "Madison"
  },
  {
      "zipCode": 77873,
      "latitude": 30.579623,
      "longitude": -95.895889,
      "city": "Richards",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77875,
      "latitude": 30.607534,
      "longitude": -95.957945,
      "city": "Roans Prairie",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77876,
      "latitude": 30.494286,
      "longitude": -96.018489,
      "city": "Shiro",
      "state": "TX",
      "county": "Grimes"
  },
  {
      "zipCode": 77878,
      "latitude": 30.513118,
      "longitude": -96.618047,
      "city": "Snook",
      "state": "TX",
      "county": "Burleson"
  },
  {
      "zipCode": 77879,
      "latitude": 30.364879,
      "longitude": -96.526729,
      "city": "Somerville",
      "state": "TX",
      "county": "Burleson"
  },
  {
      "zipCode": 77880,
      "latitude": 30.298367,
      "longitude": -96.211265,
      "city": "Washington",
      "state": "TX",
      "county": "Washington"
  },
  {
      "zipCode": 77881,
      "latitude": 30.65212,
      "longitude": -96.341012,
      "city": "Wellborn",
      "state": "TX",
      "county": "Brazos"
  },
  {
      "zipCode": 77882,
      "latitude": 31.026276,
      "longitude": -96.533031,
      "city": "Wheelock",
      "state": "TX",
      "county": "Robertson"
  },
  {
      "zipCode": 77901,
      "latitude": 28.777373,
      "longitude": -97.026681,
      "city": "Victoria",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77902,
      "latitude": 28.925513,
      "longitude": -97.100624,
      "city": "Victoria",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77903,
      "latitude": 28.794935,
      "longitude": -96.974119,
      "city": "Victoria",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77904,
      "latitude": 28.90025,
      "longitude": -97.001424,
      "city": "Victoria",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77905,
      "latitude": 28.752488,
      "longitude": -97.033759,
      "city": "Victoria",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77950,
      "latitude": 28.308115,
      "longitude": -97.152097,
      "city": "Austwell",
      "state": "TX",
      "county": "Refugio"
  },
  {
      "zipCode": 77951,
      "latitude": 28.649465,
      "longitude": -96.894486,
      "city": "Bloomington",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77954,
      "latitude": 29.093677,
      "longitude": -97.260776,
      "city": "Cuero",
      "state": "TX",
      "county": "De Witt"
  },
  {
      "zipCode": 77957,
      "latitude": 29.003797,
      "longitude": -96.68519,
      "city": "Edna",
      "state": "TX",
      "county": "Jackson"
  },
  {
      "zipCode": 77960,
      "latitude": 28.677805,
      "longitude": -97.260556,
      "city": "Fannin",
      "state": "TX",
      "county": "Goliad"
  },
  {
      "zipCode": 77961,
      "latitude": 28.961388,
      "longitude": -96.501501,
      "city": "Francitas",
      "state": "TX",
      "county": "Jackson"
  },
  {
      "zipCode": 77962,
      "latitude": 28.988713,
      "longitude": -96.456421,
      "city": "Ganado",
      "state": "TX",
      "county": "Jackson"
  },
  {
      "zipCode": 77963,
      "latitude": 28.664092,
      "longitude": -97.460854,
      "city": "Goliad",
      "state": "TX",
      "county": "Goliad"
  },
  {
      "zipCode": 77964,
      "latitude": 29.418431,
      "longitude": -96.93942,
      "city": "Hallettsville",
      "state": "TX",
      "county": "Lavaca"
  },
  {
      "zipCode": 77967,
      "latitude": 29.098894,
      "longitude": -97.365742,
      "city": "Hochheim",
      "state": "TX",
      "county": "De Witt"
  },
  {
      "zipCode": 77968,
      "latitude": 28.851753,
      "longitude": -96.857725,
      "city": "Inez",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77969,
      "latitude": 28.851185,
      "longitude": -96.681608,
      "city": "La Salle",
      "state": "TX",
      "county": "Jackson"
  },
  {
      "zipCode": 77970,
      "latitude": 28.896485,
      "longitude": -96.462184,
      "city": "La Ward",
      "state": "TX",
      "county": "Jackson"
  },
  {
      "zipCode": 77971,
      "latitude": 28.863912,
      "longitude": -96.504071,
      "city": "Lolita",
      "state": "TX",
      "county": "Jackson"
  },
  {
      "zipCode": 77972,
      "latitude": 28.525453,
      "longitude": -96.694818,
      "city": "Long Mott",
      "state": "TX",
      "county": "Calhoun"
  },
  {
      "zipCode": 77973,
      "latitude": 28.551137,
      "longitude": -97.009923,
      "city": "Mcfaddin",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77974,
      "latitude": 28.917556,
      "longitude": -97.290727,
      "city": "Meyersville",
      "state": "TX",
      "county": "De Witt"
  },
  {
      "zipCode": 77975,
      "latitude": 29.576432,
      "longitude": -97.145989,
      "city": "Moulton",
      "state": "TX",
      "county": "Lavaca"
  },
  {
      "zipCode": 77976,
      "latitude": 28.954273,
      "longitude": -97.090604,
      "city": "Nursery",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77977,
      "latitude": 28.692323,
      "longitude": -96.825416,
      "city": "Placedo",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77978,
      "latitude": 28.681485,
      "longitude": -96.50072,
      "city": "Point Comfort",
      "state": "TX",
      "county": "Calhoun"
  },
  {
      "zipCode": 77979,
      "latitude": 28.556096,
      "longitude": -96.600353,
      "city": "Port Lavaca",
      "state": "TX",
      "county": "Calhoun"
  },
  {
      "zipCode": 77982,
      "latitude": 28.443245,
      "longitude": -96.422834,
      "city": "Port O Connor",
      "state": "TX",
      "county": "Calhoun"
  },
  {
      "zipCode": 77983,
      "latitude": 28.427276,
      "longitude": -96.667313,
      "city": "Seadrift",
      "state": "TX",
      "county": "Calhoun"
  },
  {
      "zipCode": 77984,
      "latitude": 29.42254,
      "longitude": -97.153946,
      "city": "Shiner",
      "state": "TX",
      "county": "Lavaca"
  },
  {
      "zipCode": 77985,
      "latitude": 29.347975,
      "longitude": -96.900331,
      "city": "Speaks",
      "state": "TX",
      "county": "Lavaca"
  },
  {
      "zipCode": 77986,
      "latitude": 29.487564,
      "longitude": -96.794605,
      "city": "Sublime",
      "state": "TX",
      "county": "Lavaca"
  },
  {
      "zipCode": 77987,
      "latitude": 29.347975,
      "longitude": -96.900331,
      "city": "Sweet Home",
      "state": "TX",
      "county": "Lavaca"
  },
  {
      "zipCode": 77988,
      "latitude": 28.838353,
      "longitude": -96.887217,
      "city": "Telferner",
      "state": "TX",
      "county": "Victoria"
  },
  {
      "zipCode": 77989,
      "latitude": 28.997449,
      "longitude": -97.153868,
      "city": "Thomaston",
      "state": "TX",
      "county": "De Witt"
  },
  {
      "zipCode": 77990,
      "latitude": 28.458661,
      "longitude": -96.892788,
      "city": "Tivoli",
      "state": "TX",
      "county": "Refugio"
  },
  {
      "zipCode": 77991,
      "latitude": 28.845526,
      "longitude": -96.581962,
      "city": "Vanderbilt",
      "state": "TX",
      "county": "Jackson"
  },
  {
      "zipCode": 77993,
      "latitude": 28.835825,
      "longitude": -97.444155,
      "city": "Weesatche",
      "state": "TX",
      "county": "Goliad"
  },
  {
      "zipCode": 77994,
      "latitude": 29.102107,
      "longitude": -97.328361,
      "city": "Westhoff",
      "state": "TX",
      "county": "De Witt"
  },
  {
      "zipCode": 77995,
      "latitude": 29.297302,
      "longitude": -97.047148,
      "city": "Yoakum",
      "state": "TX",
      "county": "Lavaca"
  },
  {
      "zipCode": 78001,
      "latitude": 28.338951,
      "longitude": -99.098369,
      "city": "Artesia Wells",
      "state": "TX",
      "county": "La Salle"
  },
  {
      "zipCode": 78002,
      "latitude": 29.279376,
      "longitude": -98.735851,
      "city": "Atascosa",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78003,
      "latitude": 29.724328,
      "longitude": -99.104014,
      "city": "Bandera",
      "state": "TX",
      "county": "Bandera"
  },
  {
      "zipCode": 78004,
      "latitude": 29.897947,
      "longitude": -98.564153,
      "city": "Bergheim",
      "state": "TX",
      "county": "Kendall"
  },
  {
      "zipCode": 78005,
      "latitude": 28.965841,
      "longitude": -98.857837,
      "city": "Bigfoot",
      "state": "TX",
      "county": "Frio"
  },
  {
      "zipCode": 78006,
      "latitude": 29.893137,
      "longitude": -98.685719,
      "city": "Boerne",
      "state": "TX",
      "county": "Kendall"
  },
  {
      "zipCode": 78007,
      "latitude": 28.350458,
      "longitude": -98.56876,
      "city": "Calliham",
      "state": "TX",
      "county": "Mcmullen"
  },
  {
      "zipCode": 78008,
      "latitude": 28.788589,
      "longitude": -98.139626,
      "city": "Campbellton",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78009,
      "latitude": 29.364948,
      "longitude": -98.909219,
      "city": "Castroville",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78010,
      "latitude": 29.931026,
      "longitude": -99.067431,
      "city": "Center Point",
      "state": "TX",
      "county": "Kerr"
  },
  {
      "zipCode": 78011,
      "latitude": 28.814293,
      "longitude": -98.655756,
      "city": "Charlotte",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78012,
      "latitude": 28.785811,
      "longitude": -98.488586,
      "city": "Christine",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78013,
      "latitude": 29.945489,
      "longitude": -98.712767,
      "city": "Comfort",
      "state": "TX",
      "county": "Kendall"
  },
  {
      "zipCode": 78014,
      "latitude": 28.243436,
      "longitude": -99.278563,
      "city": "Cotulla",
      "state": "TX",
      "county": "La Salle"
  },
  {
      "zipCode": 78015,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "Boerne",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78016,
      "latitude": 29.21041,
      "longitude": -98.978383,
      "city": "Devine",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78017,
      "latitude": 28.763313,
      "longitude": -99.217995,
      "city": "Dilley",
      "state": "TX",
      "county": "Frio"
  },
  {
      "zipCode": 78019,
      "latitude": 28.040526,
      "longitude": -99.356329,
      "city": "Encinal",
      "state": "TX",
      "county": "La Salle"
  },
  {
      "zipCode": 78021,
      "latitude": 28.338951,
      "longitude": -99.098369,
      "city": "Fowlerton",
      "state": "TX",
      "county": "La Salle"
  },
  {
      "zipCode": 78022,
      "latitude": 28.302094,
      "longitude": -98.104154,
      "city": "George West",
      "state": "TX",
      "county": "Live Oak"
  },
  {
      "zipCode": 78023,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "Helotes",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78024,
      "latitude": 30.079396,
      "longitude": -99.303613,
      "city": "Hunt",
      "state": "TX",
      "county": "Kerr"
  },
  {
      "zipCode": 78025,
      "latitude": 30.07271,
      "longitude": -99.207682,
      "city": "Ingram",
      "state": "TX",
      "county": "Kerr"
  },
  {
      "zipCode": 78026,
      "latitude": 28.932742,
      "longitude": -98.612998,
      "city": "Jourdanton",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78027,
      "latitude": 29.968844,
      "longitude": -98.540536,
      "city": "Kendalia",
      "state": "TX",
      "county": "Kendall"
  },
  {
      "zipCode": 78028,
      "latitude": 29.988525,
      "longitude": -99.155469,
      "city": "Kerrville",
      "state": "TX",
      "county": "Kerr"
  },
  {
      "zipCode": 78029,
      "latitude": 30.033226,
      "longitude": -99.140974,
      "city": "Kerrville",
      "state": "TX",
      "county": "Kerr"
  },
  {
      "zipCode": 78039,
      "latitude": 29.311728,
      "longitude": -98.839256,
      "city": "La Coste",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78040,
      "latitude": 27.515879,
      "longitude": -99.494078,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78041,
      "latitude": 27.556714,
      "longitude": -99.475079,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78042,
      "latitude": 27.565464,
      "longitude": -99.476792,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78043,
      "latitude": 27.462213,
      "longitude": -99.475032,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78044,
      "latitude": 27.363738,
      "longitude": -99.481919,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78045,
      "latitude": 27.63573,
      "longitude": -99.592284,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78046,
      "latitude": 27.404679,
      "longitude": -99.474294,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78049,
      "latitude": 27.732094,
      "longitude": -99.505138,
      "city": "Laredo",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78050,
      "latitude": 29.101362,
      "longitude": -98.488128,
      "city": "Leming",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78052,
      "latitude": 29.213865,
      "longitude": -98.755526,
      "city": "Lytle",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78053,
      "latitude": 28.851823,
      "longitude": -98.268515,
      "city": "Mc Coy",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78054,
      "latitude": 29.325602,
      "longitude": -98.732187,
      "city": "Macdona",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78055,
      "latitude": 29.729603,
      "longitude": -99.191141,
      "city": "Medina",
      "state": "TX",
      "county": "Bandera"
  },
  {
      "zipCode": 78056,
      "latitude": 29.511723,
      "longitude": -98.938654,
      "city": "Mico",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78057,
      "latitude": 29.059489,
      "longitude": -99.020436,
      "city": "Moore",
      "state": "TX",
      "county": "Frio"
  },
  {
      "zipCode": 78058,
      "latitude": 30.172545,
      "longitude": -99.484971,
      "city": "Mountain Home",
      "state": "TX",
      "county": "Kerr"
  },
  {
      "zipCode": 78059,
      "latitude": 29.199959,
      "longitude": -98.847138,
      "city": "Natalia",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78060,
      "latitude": 28.421814,
      "longitude": -98.071902,
      "city": "Oakville",
      "state": "TX",
      "county": "Live Oak"
  },
  {
      "zipCode": 78061,
      "latitude": 28.867782,
      "longitude": -99.108227,
      "city": "Pearsall",
      "state": "TX",
      "county": "Frio"
  },
  {
      "zipCode": 78062,
      "latitude": 28.919999,
      "longitude": -98.552942,
      "city": "Peggy",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78063,
      "latitude": 29.67956,
      "longitude": -98.948428,
      "city": "Pipe Creek",
      "state": "TX",
      "county": "Bandera"
  },
  {
      "zipCode": 78064,
      "latitude": 28.975023,
      "longitude": -98.495551,
      "city": "Pleasanton",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78065,
      "latitude": 29.059014,
      "longitude": -98.63756,
      "city": "Poteet",
      "state": "TX",
      "county": "Atascosa"
  },
  {
      "zipCode": 78066,
      "latitude": 29.461171,
      "longitude": -98.869444,
      "city": "Rio Medina",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78067,
      "latitude": 27.09468,
      "longitude": -99.354468,
      "city": "San Ygnacio",
      "state": "TX",
      "county": "Zapata"
  },
  {
      "zipCode": 78069,
      "latitude": 29.22053,
      "longitude": -98.667756,
      "city": "Somerset",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78070,
      "latitude": 29.881355,
      "longitude": -98.408614,
      "city": "Spring Branch",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78071,
      "latitude": 28.452822,
      "longitude": -98.166012,
      "city": "Three Rivers",
      "state": "TX",
      "county": "Live Oak"
  },
  {
      "zipCode": 78072,
      "latitude": 28.350458,
      "longitude": -98.56876,
      "city": "Tilden",
      "state": "TX",
      "county": "Mcmullen"
  },
  {
      "zipCode": 78073,
      "latitude": 29.247612,
      "longitude": -98.546361,
      "city": "Von Ormy",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78074,
      "latitude": 29.952304,
      "longitude": -98.794374,
      "city": "Waring",
      "state": "TX",
      "county": "Kendall"
  },
  {
      "zipCode": 78075,
      "latitude": 28.421814,
      "longitude": -98.071902,
      "city": "Whitsett",
      "state": "TX",
      "county": "Live Oak"
  },
  {
      "zipCode": 78076,
      "latitude": 26.923302,
      "longitude": -99.215337,
      "city": "Zapata",
      "state": "TX",
      "county": "Zapata"
  },
  {
      "zipCode": 78101,
      "latitude": 29.368735,
      "longitude": -98.253788,
      "city": "Adkins",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78102,
      "latitude": 28.488192,
      "longitude": -97.730956,
      "city": "Beeville",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78104,
      "latitude": 28.393116,
      "longitude": -97.776017,
      "city": "Beeville",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78107,
      "latitude": 28.529477,
      "longitude": -97.592509,
      "city": "Berclair",
      "state": "TX",
      "county": "Goliad"
  },
  {
      "zipCode": 78108,
      "latitude": 29.555429,
      "longitude": -98.090085,
      "city": "Cibolo",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78109,
      "latitude": 29.378081,
      "longitude": -98.250136,
      "city": "Converse",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78111,
      "latitude": 28.944864,
      "longitude": -97.882815,
      "city": "Ecleto",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78112,
      "latitude": 29.207578,
      "longitude": -98.383291,
      "city": "Elmendorf",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78113,
      "latitude": 28.967642,
      "longitude": -98.015225,
      "city": "Falls City",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78114,
      "latitude": 29.162771,
      "longitude": -98.175887,
      "city": "Floresville",
      "state": "TX",
      "county": "Wilson"
  },
  {
      "zipCode": 78115,
      "latitude": 29.54508,
      "longitude": -98.040833,
      "city": "Geronimo",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78116,
      "latitude": 29.092731,
      "longitude": -97.843908,
      "city": "Gillett",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78117,
      "latitude": 28.993819,
      "longitude": -97.928162,
      "city": "Hobson",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78118,
      "latitude": 28.922377,
      "longitude": -97.937085,
      "city": "Karnes City",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78119,
      "latitude": 28.883947,
      "longitude": -97.858465,
      "city": "Kenedy",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78121,
      "latitude": 29.34551,
      "longitude": -98.087356,
      "city": "La Vernia",
      "state": "TX",
      "county": "Wilson"
  },
  {
      "zipCode": 78122,
      "latitude": 29.407188,
      "longitude": -97.741358,
      "city": "Leesville",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78123,
      "latitude": 29.600748,
      "longitude": -98.041576,
      "city": "Mc Queeney",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78124,
      "latitude": 29.567879,
      "longitude": -98.130236,
      "city": "Marion",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78125,
      "latitude": 28.424535,
      "longitude": -97.732736,
      "city": "Mineral",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78130,
      "latitude": 29.776488,
      "longitude": -98.221041,
      "city": "New Braunfels",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78131,
      "latitude": 29.79918,
      "longitude": -98.338419,
      "city": "New Braunfels",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78132,
      "latitude": 29.756508,
      "longitude": -98.198321,
      "city": "New Braunfels",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78133,
      "latitude": 29.801223,
      "longitude": -98.220684,
      "city": "Canyon Lake",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78135,
      "latitude": 29.738502,
      "longitude": -98.087157,
      "city": "New Braunfels",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78140,
      "latitude": 29.331033,
      "longitude": -97.789879,
      "city": "Nixon",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78141,
      "latitude": 28.924265,
      "longitude": -97.446179,
      "city": "Nordheim",
      "state": "TX",
      "county": "De Witt"
  },
  {
      "zipCode": 78142,
      "latitude": 28.527798,
      "longitude": -97.783069,
      "city": "Normanna",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78143,
      "latitude": 29.24841,
      "longitude": -97.828699,
      "city": "Pandora",
      "state": "TX",
      "county": "Wilson"
  },
  {
      "zipCode": 78144,
      "latitude": 28.956228,
      "longitude": -97.898179,
      "city": "Panna Maria",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78145,
      "latitude": 28.424535,
      "longitude": -97.732736,
      "city": "Pawnee",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78146,
      "latitude": 28.616396,
      "longitude": -97.808154,
      "city": "Pettus",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78147,
      "latitude": 29.080236,
      "longitude": -98.106249,
      "city": "Poth",
      "state": "TX",
      "county": "Wilson"
  },
  {
      "zipCode": 78148,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "Universal City",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78150,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "Universal City",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78151,
      "latitude": 28.821975,
      "longitude": -97.768307,
      "city": "Runge",
      "state": "TX",
      "county": "Karnes"
  },
  {
      "zipCode": 78152,
      "latitude": 29.433781,
      "longitude": -98.222438,
      "city": "Saint Hedwig",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78154,
      "latitude": 29.578978,
      "longitude": -98.277839,
      "city": "Schertz",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78155,
      "latitude": 29.578734,
      "longitude": -97.951829,
      "city": "Seguin",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78156,
      "latitude": 29.611797,
      "longitude": -97.971208,
      "city": "Seguin",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78159,
      "latitude": 29.277318,
      "longitude": -97.645878,
      "city": "Smiley",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78160,
      "latitude": 29.235395,
      "longitude": -97.934068,
      "city": "Stockdale",
      "state": "TX",
      "county": "Wilson"
  },
  {
      "zipCode": 78161,
      "latitude": 29.288795,
      "longitude": -98.050916,
      "city": "Sutherland Springs",
      "state": "TX",
      "county": "Wilson"
  },
  {
      "zipCode": 78162,
      "latitude": 28.424535,
      "longitude": -97.732736,
      "city": "Tuleta",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78163,
      "latitude": 29.776691,
      "longitude": -98.46263,
      "city": "Bulverde",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78164,
      "latitude": 29.080478,
      "longitude": -97.504179,
      "city": "Yorktown",
      "state": "TX",
      "county": "De Witt"
  },
  {
      "zipCode": 78201,
      "latitude": 29.399933,
      "longitude": -98.537495,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78202,
      "latitude": 29.428432,
      "longitude": -98.462096,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78203,
      "latitude": 29.414633,
      "longitude": -98.461443,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78204,
      "latitude": 29.405931,
      "longitude": -98.507844,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78205,
      "latitude": 29.424132,
      "longitude": -98.484144,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78206,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78207,
      "latitude": 29.348816,
      "longitude": -98.439943,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78208,
      "latitude": 29.439982,
      "longitude": -98.460075,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78209,
      "latitude": 29.431232,
      "longitude": -98.427793,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78210,
      "latitude": 29.353652,
      "longitude": -98.480544,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78211,
      "latitude": 29.311651,
      "longitude": -98.642815,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78212,
      "latitude": 29.438782,
      "longitude": -98.493494,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78213,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78214,
      "latitude": 29.350735,
      "longitude": -98.483894,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78215,
      "latitude": 29.437982,
      "longitude": -98.483994,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78216,
      "latitude": 29.173285,
      "longitude": -98.478975,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78217,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78218,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78219,
      "latitude": 29.449182,
      "longitude": -98.34464,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78220,
      "latitude": 29.414183,
      "longitude": -98.391741,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78221,
      "latitude": 29.259178,
      "longitude": -98.453688,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78222,
      "latitude": 29.332886,
      "longitude": -98.382441,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78223,
      "latitude": 29.323178,
      "longitude": -98.372743,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78224,
      "latitude": 29.326065,
      "longitude": -98.456031,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78225,
      "latitude": 29.337885,
      "longitude": -98.440692,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78226,
      "latitude": 29.390123,
      "longitude": -98.549932,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78227,
      "latitude": 29.401583,
      "longitude": -98.625548,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78228,
      "latitude": 29.353335,
      "longitude": -98.467443,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78229,
      "latitude": 29.269288,
      "longitude": -98.33469,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78230,
      "latitude": 29.268287,
      "longitude": -98.7195,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78231,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78232,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78233,
      "latitude": 29.292564,
      "longitude": -98.408567,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78234,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78235,
      "latitude": 29.349352,
      "longitude": -98.442186,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78236,
      "latitude": 29.405983,
      "longitude": -98.608447,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78237,
      "latitude": 29.345385,
      "longitude": -98.483449,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78238,
      "latitude": 29.45102,
      "longitude": -98.616908,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78239,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78240,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78241,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78242,
      "latitude": 29.340511,
      "longitude": -98.670818,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78243,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78244,
      "latitude": 29.388484,
      "longitude": -98.448593,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78245,
      "latitude": 29.406883,
      "longitude": -98.722675,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78246,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78247,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78248,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78249,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78250,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78251,
      "latitude": 29.464885,
      "longitude": -98.671774,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78252,
      "latitude": 29.337325,
      "longitude": -98.705868,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78253,
      "latitude": 29.471977,
      "longitude": -98.757439,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78254,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78255,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78256,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78257,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78258,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78259,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78260,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78261,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78262,
      "latitude": 29.449332,
      "longitude": -98.290394,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78263,
      "latitude": 29.354369,
      "longitude": -98.305439,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78264,
      "latitude": 29.194922,
      "longitude": -98.499936,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78265,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78266,
      "latitude": 29.740162,
      "longitude": -98.22843,
      "city": "San Antonio",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78268,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78269,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78270,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78275,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78278,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78279,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78280,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78283,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78284,
      "latitude": 29.442632,
      "longitude": -98.491344,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78285,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78286,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78287,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78288,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78289,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78291,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78292,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78293,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78294,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78295,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78296,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78297,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78298,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78299,
      "latitude": 29.437532,
      "longitude": -98.461582,
      "city": "San Antonio",
      "state": "TX",
      "county": "Bexar"
  },
  {
      "zipCode": 78330,
      "latitude": 27.836111,
      "longitude": -97.530388,
      "city": "Agua Dulce",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78332,
      "latitude": 27.703829,
      "longitude": -98.055296,
      "city": "Alice",
      "state": "TX",
      "county": "Jim Wells"
  },
  {
      "zipCode": 78333,
      "latitude": 27.659473,
      "longitude": -98.012331,
      "city": "Alice",
      "state": "TX",
      "county": "Jim Wells"
  },
  {
      "zipCode": 78335,
      "latitude": 27.912454,
      "longitude": -97.188437,
      "city": "Aransas Pass",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78336,
      "latitude": 27.94979,
      "longitude": -97.513458,
      "city": "Aransas Pass",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78338,
      "latitude": 27.116964,
      "longitude": -97.612449,
      "city": "Armstrong",
      "state": "TX",
      "county": "Kenedy"
  },
  {
      "zipCode": 78339,
      "latitude": 27.804485,
      "longitude": -97.80874,
      "city": "Banquete",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78340,
      "latitude": 28.097783,
      "longitude": -97.206225,
      "city": "Bayside",
      "state": "TX",
      "county": "Refugio"
  },
  {
      "zipCode": 78341,
      "latitude": 27.592468,
      "longitude": -98.414188,
      "city": "Benavides",
      "state": "TX",
      "county": "Duval"
  },
  {
      "zipCode": 78342,
      "latitude": 27.659473,
      "longitude": -98.012331,
      "city": "Ben Bolt",
      "state": "TX",
      "county": "Jim Wells"
  },
  {
      "zipCode": 78343,
      "latitude": 27.623349,
      "longitude": -97.749935,
      "city": "Bishop",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78344,
      "latitude": 27.429451,
      "longitude": -98.838546,
      "city": "Bruni",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78347,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Chapman Ranch",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78349,
      "latitude": 27.660242,
      "longitude": -98.517894,
      "city": "Concepcion",
      "state": "TX",
      "county": "Duval"
  },
  {
      "zipCode": 78350,
      "latitude": 28.421814,
      "longitude": -98.071902,
      "city": "Dinero",
      "state": "TX",
      "county": "Live Oak"
  },
  {
      "zipCode": 78351,
      "latitude": 27.633281,
      "longitude": -97.763356,
      "city": "Driscoll",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78352,
      "latitude": 27.964959,
      "longitude": -97.668225,
      "city": "Edroy",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78353,
      "latitude": 27.023059,
      "longitude": -98.261049,
      "city": "Encino",
      "state": "TX",
      "county": "Brooks"
  },
  {
      "zipCode": 78355,
      "latitude": 27.223209,
      "longitude": -98.145056,
      "city": "Falfurrias",
      "state": "TX",
      "county": "Brooks"
  },
  {
      "zipCode": 78357,
      "latitude": 27.899181,
      "longitude": -98.614928,
      "city": "Freer",
      "state": "TX",
      "county": "Duval"
  },
  {
      "zipCode": 78358,
      "latitude": 28.058922,
      "longitude": -97.052415,
      "city": "Fulton",
      "state": "TX",
      "county": "Aransas"
  },
  {
      "zipCode": 78359,
      "latitude": 27.945389,
      "longitude": -97.245888,
      "city": "Gregory",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78360,
      "latitude": 27.071318,
      "longitude": -98.686327,
      "city": "Guerra",
      "state": "TX",
      "county": "Jim Hogg"
  },
  {
      "zipCode": 78361,
      "latitude": 27.07069,
      "longitude": -98.692717,
      "city": "Hebbronville",
      "state": "TX",
      "county": "Jim Hogg"
  },
  {
      "zipCode": 78362,
      "latitude": 27.873566,
      "longitude": -97.224236,
      "city": "Ingleside",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78363,
      "latitude": 27.422876,
      "longitude": -97.84068,
      "city": "Kingsville",
      "state": "TX",
      "county": "Kleberg"
  },
  {
      "zipCode": 78364,
      "latitude": 27.421023,
      "longitude": -97.882432,
      "city": "Kingsville",
      "state": "TX",
      "county": "Kleberg"
  },
  {
      "zipCode": 78368,
      "latitude": 28.000693,
      "longitude": -97.539384,
      "city": "Mathis",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78369,
      "latitude": 27.43807,
      "longitude": -99.001969,
      "city": "Mirando City",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78370,
      "latitude": 27.941424,
      "longitude": -97.581752,
      "city": "Odem",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78371,
      "latitude": 27.732094,
      "longitude": -99.505138,
      "city": "Oilton",
      "state": "TX",
      "county": "Webb"
  },
  {
      "zipCode": 78372,
      "latitude": 27.942358,
      "longitude": -98.06417,
      "city": "Orange Grove",
      "state": "TX",
      "county": "Jim Wells"
  },
  {
      "zipCode": 78373,
      "latitude": 27.770678,
      "longitude": -97.105137,
      "city": "Port Aransas",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78374,
      "latitude": 27.979489,
      "longitude": -97.538993,
      "city": "Portland",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78375,
      "latitude": 27.315242,
      "longitude": -98.164332,
      "city": "Premont",
      "state": "TX",
      "county": "Jim Wells"
  },
  {
      "zipCode": 78376,
      "latitude": 27.660242,
      "longitude": -98.517894,
      "city": "Realitos",
      "state": "TX",
      "county": "Duval"
  },
  {
      "zipCode": 78377,
      "latitude": 28.281826,
      "longitude": -97.297783,
      "city": "Refugio",
      "state": "TX",
      "county": "Refugio"
  },
  {
      "zipCode": 78379,
      "latitude": 27.297687,
      "longitude": -97.787713,
      "city": "Riviera",
      "state": "TX",
      "county": "Kleberg"
  },
  {
      "zipCode": 78380,
      "latitude": 27.795517,
      "longitude": -97.752083,
      "city": "Robstown",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78381,
      "latitude": 28.013108,
      "longitude": -97.09364,
      "city": "Rockport",
      "state": "TX",
      "county": "Aransas"
  },
  {
      "zipCode": 78382,
      "latitude": 28.070458,
      "longitude": -97.101567,
      "city": "Rockport",
      "state": "TX",
      "county": "Aransas"
  },
  {
      "zipCode": 78383,
      "latitude": 28.024961,
      "longitude": -97.870486,
      "city": "Sandia",
      "state": "TX",
      "county": "Jim Wells"
  },
  {
      "zipCode": 78384,
      "latitude": 27.660127,
      "longitude": -98.517875,
      "city": "San Diego",
      "state": "TX",
      "county": "Duval"
  },
  {
      "zipCode": 78385,
      "latitude": 27.222302,
      "longitude": -97.793153,
      "city": "Sarita",
      "state": "TX",
      "county": "Kenedy"
  },
  {
      "zipCode": 78387,
      "latitude": 28.012823,
      "longitude": -97.556615,
      "city": "Sinton",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78389,
      "latitude": 28.21206,
      "longitude": -97.712708,
      "city": "Skidmore",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78390,
      "latitude": 27.973917,
      "longitude": -97.421131,
      "city": "Taft",
      "state": "TX",
      "county": "San Patricio"
  },
  {
      "zipCode": 78391,
      "latitude": 28.178966,
      "longitude": -97.74564,
      "city": "Tynan",
      "state": "TX",
      "county": "Bee"
  },
  {
      "zipCode": 78393,
      "latitude": 28.206879,
      "longitude": -97.334538,
      "city": "Woodsboro",
      "state": "TX",
      "county": "Refugio"
  },
  {
      "zipCode": 78401,
      "latitude": 27.759399,
      "longitude": -97.648158,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78402,
      "latitude": 27.824911,
      "longitude": -97.387555,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78403,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78404,
      "latitude": 27.773456,
      "longitude": -97.644134,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78405,
      "latitude": 27.772443,
      "longitude": -97.438727,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78406,
      "latitude": 27.766605,
      "longitude": -97.528579,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78407,
      "latitude": 27.805687,
      "longitude": -97.446032,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78408,
      "latitude": 27.782654,
      "longitude": -97.445674,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78409,
      "latitude": 27.807955,
      "longitude": -97.69339,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78410,
      "latitude": 27.82437,
      "longitude": -97.584067,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78411,
      "latitude": 27.732089,
      "longitude": -97.385169,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78412,
      "latitude": 27.68456,
      "longitude": -97.351868,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78413,
      "latitude": 27.675937,
      "longitude": -97.40242,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78414,
      "latitude": 27.667387,
      "longitude": -97.373419,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78415,
      "latitude": 27.679532,
      "longitude": -97.450239,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78416,
      "latitude": 27.811334,
      "longitude": -97.613918,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78417,
      "latitude": 27.717017,
      "longitude": -97.445407,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78418,
      "latitude": 27.70944,
      "longitude": -97.26289,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78419,
      "latitude": 27.723611,
      "longitude": -97.380884,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78426,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78427,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78460,
      "latitude": 27.889868,
      "longitude": -97.879743,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78461,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78463,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78465,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78466,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78467,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78468,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78469,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78470,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78471,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78472,
      "latitude": 27.740225,
      "longitude": -97.579207,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78473,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78474,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78475,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78476,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78477,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78478,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78480,
      "latitude": 27.777,
      "longitude": -97.463213,
      "city": "Corpus Christi",
      "state": "TX",
      "county": "Nueces"
  },
  {
      "zipCode": 78501,
      "latitude": 26.269305,
      "longitude": -98.224364,
      "city": "Mcallen",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78502,
      "latitude": 26.25671,
      "longitude": -98.198929,
      "city": "Mcallen",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78503,
      "latitude": 26.182171,
      "longitude": -98.33773,
      "city": "Mcallen",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78504,
      "latitude": 26.326284,
      "longitude": -98.233488,
      "city": "Mcallen",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78505,
      "latitude": 26.409709,
      "longitude": -98.224206,
      "city": "Mcallen",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78516,
      "latitude": 26.221712,
      "longitude": -98.218896,
      "city": "Alamo",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78520,
      "latitude": 26.052155,
      "longitude": -97.514466,
      "city": "Brownsville",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78521,
      "latitude": 26.098629,
      "longitude": -97.5069,
      "city": "Brownsville",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78522,
      "latitude": 26.188911,
      "longitude": -97.764271,
      "city": "Brownsville",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78523,
      "latitude": 25.981006,
      "longitude": -97.520941,
      "city": "Brownsville",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78526,
      "latitude": 25.971728,
      "longitude": -97.469871,
      "city": "Brownsville",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78535,
      "latitude": 26.245051,
      "longitude": -97.74157,
      "city": "Combes",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78536,
      "latitude": 26.421394,
      "longitude": -98.848757,
      "city": "Delmita",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78537,
      "latitude": 26.211241,
      "longitude": -98.134864,
      "city": "Donna",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78538,
      "latitude": 26.332751,
      "longitude": -97.96224,
      "city": "Edcouch",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78539,
      "latitude": 26.328674,
      "longitude": -98.139672,
      "city": "Edinburg",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78540,
      "latitude": 26.319405,
      "longitude": -98.190922,
      "city": "Edinburg",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78543,
      "latitude": 26.297428,
      "longitude": -97.98837,
      "city": "Elsa",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78545,
      "latitude": 26.562044,
      "longitude": -99.133528,
      "city": "Falcon Heights",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78547,
      "latitude": 26.339353,
      "longitude": -98.737359,
      "city": "Garciasville",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78548,
      "latitude": 26.289718,
      "longitude": -98.647936,
      "city": "Grulla",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78549,
      "latitude": 26.409709,
      "longitude": -98.224206,
      "city": "Hargill",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78550,
      "latitude": 26.185202,
      "longitude": -97.536807,
      "city": "Harlingen",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78551,
      "latitude": 26.244651,
      "longitude": -97.720569,
      "city": "Harlingen",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78552,
      "latitude": 26.174402,
      "longitude": -97.683666,
      "city": "Harlingen",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78553,
      "latitude": 26.125242,
      "longitude": -97.475663,
      "city": "Harlingen",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78557,
      "latitude": 26.185789,
      "longitude": -98.272287,
      "city": "Hidalgo",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78558,
      "latitude": 26.294532,
      "longitude": -98.045699,
      "city": "La Blanca",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78559,
      "latitude": 26.131353,
      "longitude": -97.754971,
      "city": "La Feria",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78560,
      "latitude": 26.242565,
      "longitude": -98.474651,
      "city": "La Joya",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78561,
      "latitude": 26.561287,
      "longitude": -97.429952,
      "city": "Lasara",
      "state": "TX",
      "county": "Willacy"
  },
  {
      "zipCode": 78562,
      "latitude": 26.299892,
      "longitude": -97.924718,
      "city": "La Villa",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78563,
      "latitude": 26.409709,
      "longitude": -98.224206,
      "city": "Linn",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78564,
      "latitude": 26.945418,
      "longitude": -99.203985,
      "city": "Lopeno",
      "state": "TX",
      "county": "Zapata"
  },
  {
      "zipCode": 78565,
      "latitude": 26.409709,
      "longitude": -98.224206,
      "city": "Los Ebanos",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78566,
      "latitude": 26.131503,
      "longitude": -97.552425,
      "city": "Los Fresnos",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78567,
      "latitude": 26.041669,
      "longitude": -97.693736,
      "city": "Los Indios",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78568,
      "latitude": 26.190402,
      "longitude": -97.542263,
      "city": "Lozano",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78569,
      "latitude": 26.406243,
      "longitude": -97.791701,
      "city": "Lyford",
      "state": "TX",
      "county": "Willacy"
  },
  {
      "zipCode": 78570,
      "latitude": 26.209065,
      "longitude": -98.075583,
      "city": "Mercedes",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78572,
      "latitude": 26.229639,
      "longitude": -98.192732,
      "city": "Mission",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78573,
      "latitude": 26.409709,
      "longitude": -98.224206,
      "city": "Mission",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78575,
      "latitude": 26.006779,
      "longitude": -97.547392,
      "city": "Olmito",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78576,
      "latitude": 26.193734,
      "longitude": -98.101526,
      "city": "Penitas",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78577,
      "latitude": 26.186698,
      "longitude": -98.127765,
      "city": "Pharr",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78578,
      "latitude": 26.145304,
      "longitude": -97.505429,
      "city": "Port Isabel",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78579,
      "latitude": 26.092209,
      "longitude": -97.953259,
      "city": "Progreso",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78580,
      "latitude": 26.417824,
      "longitude": -97.79468,
      "city": "Raymondville",
      "state": "TX",
      "county": "Willacy"
  },
  {
      "zipCode": 78582,
      "latitude": 26.499887,
      "longitude": -98.752428,
      "city": "Rio Grande City",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78583,
      "latitude": 26.252266,
      "longitude": -97.565494,
      "city": "Rio Hondo",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78584,
      "latitude": 26.584581,
      "longitude": -99.004493,
      "city": "Roma",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78585,
      "latitude": 26.510436,
      "longitude": -98.746365,
      "city": "Salineno",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78586,
      "latitude": 26.152353,
      "longitude": -97.53175,
      "city": "San Benito",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78588,
      "latitude": 26.510436,
      "longitude": -98.746365,
      "city": "San Isidro",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78589,
      "latitude": 26.214873,
      "longitude": -98.161978,
      "city": "San Juan",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78590,
      "latitude": 26.455519,
      "longitude": -97.585805,
      "city": "San Perlita",
      "state": "TX",
      "county": "Willacy"
  },
  {
      "zipCode": 78591,
      "latitude": 26.749896,
      "longitude": -98.583016,
      "city": "Santa Elena",
      "state": "TX",
      "county": "Starr"
  },
  {
      "zipCode": 78592,
      "latitude": 26.125242,
      "longitude": -97.475663,
      "city": "Santa Maria",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78593,
      "latitude": 26.229551,
      "longitude": -97.803023,
      "city": "Santa Rosa",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78594,
      "latitude": 26.345298,
      "longitude": -97.791472,
      "city": "Sebastian",
      "state": "TX",
      "county": "Willacy"
  },
  {
      "zipCode": 78595,
      "latitude": 26.275293,
      "longitude": -98.56996,
      "city": "Sullivan City",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78596,
      "latitude": 26.178744,
      "longitude": -98.134649,
      "city": "Weslaco",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78597,
      "latitude": 26.115896,
      "longitude": -97.494039,
      "city": "South Padre Island",
      "state": "TX",
      "county": "Cameron"
  },
  {
      "zipCode": 78598,
      "latitude": 26.554299,
      "longitude": -97.426247,
      "city": "Port Mansfield",
      "state": "TX",
      "county": "Willacy"
  },
  {
      "zipCode": 78599,
      "latitude": 26.409709,
      "longitude": -98.224206,
      "city": "Weslaco",
      "state": "TX",
      "county": "Hidalgo"
  },
  {
      "zipCode": 78602,
      "latitude": 30.140905,
      "longitude": -97.313592,
      "city": "Bastrop",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78603,
      "latitude": 29.447211,
      "longitude": -97.494649,
      "city": "Bebe",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78604,
      "latitude": 29.447211,
      "longitude": -97.494649,
      "city": "Belmont",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78605,
      "latitude": 30.74431,
      "longitude": -98.08625,
      "city": "Bertram",
      "state": "TX",
      "county": "Burnet"
  },
  {
      "zipCode": 78606,
      "latitude": 30.137027,
      "longitude": -98.419985,
      "city": "Blanco",
      "state": "TX",
      "county": "Blanco"
  },
  {
      "zipCode": 78607,
      "latitude": 30.828132,
      "longitude": -98.431188,
      "city": "Bluffton",
      "state": "TX",
      "county": "Llano"
  },
  {
      "zipCode": 78608,
      "latitude": 30.878476,
      "longitude": -97.910703,
      "city": "Briggs",
      "state": "TX",
      "county": "Burnet"
  },
  {
      "zipCode": 78609,
      "latitude": 30.766448,
      "longitude": -98.427288,
      "city": "Buchanan Dam",
      "state": "TX",
      "county": "Llano"
  },
  {
      "zipCode": 78610,
      "latitude": 30.108361,
      "longitude": -97.989275,
      "city": "Buda",
      "state": "TX",
      "county": "Hays"
  },
  {
      "zipCode": 78611,
      "latitude": 30.749465,
      "longitude": -98.238816,
      "city": "Burnet",
      "state": "TX",
      "county": "Burnet"
  },
  {
      "zipCode": 78612,
      "latitude": 30.083931,
      "longitude": -97.486885,
      "city": "Cedar Creek",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78613,
      "latitude": 30.494032,
      "longitude": -97.628724,
      "city": "Cedar Park",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78614,
      "latitude": 29.447211,
      "longitude": -97.494649,
      "city": "Cost",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78615,
      "latitude": 30.487161,
      "longitude": -97.367571,
      "city": "Coupland",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78616,
      "latitude": 29.889187,
      "longitude": -97.573078,
      "city": "Dale",
      "state": "TX",
      "county": "Caldwell"
  },
  {
      "zipCode": 78617,
      "latitude": 30.151068,
      "longitude": -97.60206,
      "city": "Del Valle",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78618,
      "latitude": 30.373945,
      "longitude": -99.096213,
      "city": "Doss",
      "state": "TX",
      "county": "Gillespie"
  },
  {
      "zipCode": 78619,
      "latitude": 30.09393,
      "longitude": -98.000454,
      "city": "Driftwood",
      "state": "TX",
      "county": "Hays"
  },
  {
      "zipCode": 78620,
      "latitude": 30.165463,
      "longitude": -98.044468,
      "city": "Dripping Springs",
      "state": "TX",
      "county": "Hays"
  },
  {
      "zipCode": 78621,
      "latitude": 30.308019,
      "longitude": -97.36044,
      "city": "Elgin",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78622,
      "latitude": 29.76084,
      "longitude": -97.751156,
      "city": "Fentress",
      "state": "TX",
      "county": "Caldwell"
  },
  {
      "zipCode": 78623,
      "latitude": 29.95346,
      "longitude": -98.228503,
      "city": "Fischer",
      "state": "TX",
      "county": "Comal"
  },
  {
      "zipCode": 78624,
      "latitude": 30.313385,
      "longitude": -98.8936,
      "city": "Fredericksburg",
      "state": "TX",
      "county": "Gillespie"
  },
  {
      "zipCode": 78626,
      "latitude": 30.668034,
      "longitude": -97.574706,
      "city": "Georgetown",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78627,
      "latitude": 30.673597,
      "longitude": -97.646143,
      "city": "Georgetown",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78628,
      "latitude": 30.692191,
      "longitude": -97.756049,
      "city": "Georgetown",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78629,
      "latitude": 29.543029,
      "longitude": -97.543969,
      "city": "Gonzales",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78630,
      "latitude": 30.656817,
      "longitude": -97.602552,
      "city": "Cedar Park",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78631,
      "latitude": 30.247879,
      "longitude": -99.268227,
      "city": "Harper",
      "state": "TX",
      "county": "Gillespie"
  },
  {
      "zipCode": 78632,
      "latitude": 29.447211,
      "longitude": -97.494649,
      "city": "Harwood",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78634,
      "latitude": 30.551885,
      "longitude": -97.554189,
      "city": "Hutto",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78635,
      "latitude": 30.225386,
      "longitude": -98.542031,
      "city": "Hye",
      "state": "TX",
      "county": "Blanco"
  },
  {
      "zipCode": 78636,
      "latitude": 30.21577,
      "longitude": -98.405866,
      "city": "Johnson City",
      "state": "TX",
      "county": "Blanco"
  },
  {
      "zipCode": 78638,
      "latitude": 29.658865,
      "longitude": -97.835535,
      "city": "Kingsbury",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78639,
      "latitude": 30.697117,
      "longitude": -98.548345,
      "city": "Kingsland",
      "state": "TX",
      "county": "Llano"
  },
  {
      "zipCode": 78640,
      "latitude": 29.996599,
      "longitude": -97.83354,
      "city": "Kyle",
      "state": "TX",
      "county": "Hays"
  },
  {
      "zipCode": 78641,
      "latitude": 30.583474,
      "longitude": -97.857453,
      "city": "Leander",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78642,
      "latitude": 30.715353,
      "longitude": -97.91191,
      "city": "Liberty Hill",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78643,
      "latitude": 30.71514,
      "longitude": -98.610863,
      "city": "Llano",
      "state": "TX",
      "county": "Llano"
  },
  {
      "zipCode": 78644,
      "latitude": 29.875405,
      "longitude": -97.630472,
      "city": "Lockhart",
      "state": "TX",
      "county": "Caldwell"
  },
  {
      "zipCode": 78645,
      "latitude": 30.44901,
      "longitude": -97.966945,
      "city": "Leander",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78646,
      "latitude": 30.656817,
      "longitude": -97.602552,
      "city": "Leander",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78648,
      "latitude": 29.709928,
      "longitude": -97.638005,
      "city": "Luling",
      "state": "TX",
      "county": "Caldwell"
  },
  {
      "zipCode": 78650,
      "latitude": 30.272109,
      "longitude": -97.236741,
      "city": "Mc Dade",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78651,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Mc Neil",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78652,
      "latitude": 30.123827,
      "longitude": -97.839325,
      "city": "Manchaca",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78653,
      "latitude": 30.343501,
      "longitude": -97.512902,
      "city": "Manor",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78654,
      "latitude": 30.663954,
      "longitude": -98.161114,
      "city": "Marble Falls",
      "state": "TX",
      "county": "Burnet"
  },
  {
      "zipCode": 78655,
      "latitude": 29.839448,
      "longitude": -97.850842,
      "city": "Martindale",
      "state": "TX",
      "county": "Caldwell"
  },
  {
      "zipCode": 78656,
      "latitude": 29.85922,
      "longitude": -97.799121,
      "city": "Maxwell",
      "state": "TX",
      "county": "Caldwell"
  },
  {
      "zipCode": 78657,
      "latitude": 30.537773,
      "longitude": -98.333113,
      "city": "Marble Falls",
      "state": "TX",
      "county": "Burnet"
  },
  {
      "zipCode": 78658,
      "latitude": 29.447211,
      "longitude": -97.494649,
      "city": "Ottine",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78659,
      "latitude": 30.210039,
      "longitude": -97.157246,
      "city": "Paige",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78660,
      "latitude": 30.442737,
      "longitude": -97.597055,
      "city": "Pflugerville",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78661,
      "latitude": 29.751348,
      "longitude": -97.748349,
      "city": "Prairie Lea",
      "state": "TX",
      "county": "Caldwell"
  },
  {
      "zipCode": 78662,
      "latitude": 29.955422,
      "longitude": -97.425923,
      "city": "Red Rock",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78663,
      "latitude": 30.414943,
      "longitude": -98.356711,
      "city": "Round Mountain",
      "state": "TX",
      "county": "Blanco"
  },
  {
      "zipCode": 78664,
      "latitude": 30.530497,
      "longitude": -97.635103,
      "city": "Round Rock",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78665,
      "latitude": 30.219829,
      "longitude": -98.358613,
      "city": "Sandy",
      "state": "TX",
      "county": "Blanco"
  },
  {
      "zipCode": 78666,
      "latitude": 29.972907,
      "longitude": -97.997166,
      "city": "San Marcos",
      "state": "TX",
      "county": "Hays"
  },
  {
      "zipCode": 78667,
      "latitude": 30.054378,
      "longitude": -98.003574,
      "city": "San Marcos",
      "state": "TX",
      "county": "Hays"
  },
  {
      "zipCode": 78669,
      "latitude": 30.397227,
      "longitude": -97.82012,
      "city": "Spicewood",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78670,
      "latitude": 29.611797,
      "longitude": -97.971208,
      "city": "Staples",
      "state": "TX",
      "county": "Guadalupe"
  },
  {
      "zipCode": 78671,
      "latitude": 30.289559,
      "longitude": -98.706737,
      "city": "Stonewall",
      "state": "TX",
      "county": "Gillespie"
  },
  {
      "zipCode": 78672,
      "latitude": 30.772002,
      "longitude": -98.446332,
      "city": "Tow",
      "state": "TX",
      "county": "Llano"
  },
  {
      "zipCode": 78673,
      "latitude": 30.741495,
      "longitude": -97.589147,
      "city": "Walburg",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78674,
      "latitude": 30.674667,
      "longitude": -97.59286,
      "city": "Weir",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78675,
      "latitude": 30.387956,
      "longitude": -98.701902,
      "city": "Willow City",
      "state": "TX",
      "county": "Gillespie"
  },
  {
      "zipCode": 78676,
      "latitude": 30.012441,
      "longitude": -98.031713,
      "city": "Wimberley",
      "state": "TX",
      "county": "Hays"
  },
  {
      "zipCode": 78677,
      "latitude": 29.447211,
      "longitude": -97.494649,
      "city": "Wrightsboro",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78680,
      "latitude": 30.656817,
      "longitude": -97.602552,
      "city": "Round Rock",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78681,
      "latitude": 30.52398,
      "longitude": -97.718672,
      "city": "Round Rock",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78682,
      "latitude": 30.656817,
      "longitude": -97.602552,
      "city": "Round Rock",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78683,
      "latitude": 30.656817,
      "longitude": -97.602552,
      "city": "Round Rock",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78691,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Pflugerville",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78701,
      "latitude": 30.268335,
      "longitude": -97.741382,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78702,
      "latitude": 30.264115,
      "longitude": -97.713581,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78703,
      "latitude": 30.290107,
      "longitude": -97.766351,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78704,
      "latitude": 30.240685,
      "longitude": -97.768832,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78705,
      "latitude": 30.293057,
      "longitude": -97.736932,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78708,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78709,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78710,
      "latitude": 30.351953,
      "longitude": -97.715123,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78711,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78712,
      "latitude": 30.285207,
      "longitude": -97.735394,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78713,
      "latitude": 30.468583,
      "longitude": -97.843336,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78714,
      "latitude": 30.335787,
      "longitude": -97.443751,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78715,
      "latitude": 30.450088,
      "longitude": -97.486509,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78716,
      "latitude": 30.316223,
      "longitude": -97.85877,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78717,
      "latitude": 30.493156,
      "longitude": -97.756517,
      "city": "Austin",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78718,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78719,
      "latitude": 30.141293,
      "longitude": -97.678843,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78720,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78721,
      "latitude": 30.268684,
      "longitude": -97.684781,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78722,
      "latitude": 30.286857,
      "longitude": -97.718832,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78723,
      "latitude": 30.305107,
      "longitude": -97.686631,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78724,
      "latitude": 30.292188,
      "longitude": -97.617871,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78725,
      "latitude": 30.243552,
      "longitude": -97.625293,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78726,
      "latitude": 30.427124,
      "longitude": -97.843801,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78727,
      "latitude": 30.427402,
      "longitude": -97.719678,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78728,
      "latitude": 30.452151,
      "longitude": -97.694784,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78729,
      "latitude": 30.456713,
      "longitude": -97.770237,
      "city": "Austin",
      "state": "TX",
      "county": "Williamson"
  },
  {
      "zipCode": 78730,
      "latitude": 30.364587,
      "longitude": -97.836441,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78731,
      "latitude": 30.345554,
      "longitude": -97.769769,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78732,
      "latitude": 30.376821,
      "longitude": -97.889237,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78733,
      "latitude": 30.321504,
      "longitude": -97.889882,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78734,
      "latitude": 30.370457,
      "longitude": -97.942721,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78735,
      "latitude": 30.267607,
      "longitude": -97.860767,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78736,
      "latitude": 30.272424,
      "longitude": -97.973888,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78737,
      "latitude": 30.203952,
      "longitude": -97.931042,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78738,
      "latitude": 30.326866,
      "longitude": -98.013542,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78739,
      "latitude": 30.172937,
      "longitude": -97.886794,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78741,
      "latitude": 30.231309,
      "longitude": -97.715057,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78742,
      "latitude": 30.236455,
      "longitude": -97.669953,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78744,
      "latitude": 30.166102,
      "longitude": -97.737181,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78745,
      "latitude": 30.198702,
      "longitude": -97.797183,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78746,
      "latitude": 30.2971,
      "longitude": -97.818113,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78747,
      "latitude": 30.120427,
      "longitude": -97.743317,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78748,
      "latitude": 30.16696,
      "longitude": -97.823854,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78749,
      "latitude": 30.207969,
      "longitude": -97.857187,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78750,
      "latitude": 30.406729,
      "longitude": -97.8155,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78751,
      "latitude": 30.310606,
      "longitude": -97.722382,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78752,
      "latitude": 30.332901,
      "longitude": -97.699932,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78753,
      "latitude": 30.378554,
      "longitude": -97.676445,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78754,
      "latitude": 30.365596,
      "longitude": -97.646734,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78755,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78756,
      "latitude": 30.320606,
      "longitude": -97.737583,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78757,
      "latitude": 30.348567,
      "longitude": -97.601444,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78758,
      "latitude": 30.383637,
      "longitude": -97.708233,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78759,
      "latitude": 30.395586,
      "longitude": -97.750611,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78760,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78761,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78762,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78763,
      "latitude": 30.335398,
      "longitude": -97.559807,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78764,
      "latitude": 30.445502,
      "longitude": -97.659533,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78765,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78766,
      "latitude": 30.442202,
      "longitude": -97.62333,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78767,
      "latitude": 30.222007,
      "longitude": -97.896285,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78768,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78769,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78772,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78773,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78774,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78778,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78779,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78780,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78781,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78783,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78785,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78786,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78788,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78789,
      "latitude": 30.326374,
      "longitude": -97.771258,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78799,
      "latitude": 30.250927,
      "longitude": -97.684714,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": 78801,
      "latitude": 29.240446,
      "longitude": -99.727018,
      "city": "Uvalde",
      "state": "TX",
      "county": "Uvalde"
  },
  {
      "zipCode": 78802,
      "latitude": 29.223697,
      "longitude": -99.779351,
      "city": "Uvalde",
      "state": "TX",
      "county": "Uvalde"
  },
  {
      "zipCode": 78827,
      "latitude": 28.448923,
      "longitude": -99.759184,
      "city": "Asherton",
      "state": "TX",
      "county": "Dimmit"
  },
  {
      "zipCode": 78828,
      "latitude": 29.828166,
      "longitude": -100.105033,
      "city": "Barksdale",
      "state": "TX",
      "county": "Edwards"
  },
  {
      "zipCode": 78829,
      "latitude": 28.959826,
      "longitude": -99.621047,
      "city": "Batesville",
      "state": "TX",
      "county": "Zavala"
  },
  {
      "zipCode": 78830,
      "latitude": 28.569297,
      "longitude": -99.570327,
      "city": "Big Wells",
      "state": "TX",
      "county": "Dimmit"
  },
  {
      "zipCode": 78832,
      "latitude": 29.299888,
      "longitude": -100.421055,
      "city": "Brackettville",
      "state": "TX",
      "county": "Kinney"
  },
  {
      "zipCode": 78833,
      "latitude": 29.669203,
      "longitude": -100.012219,
      "city": "Camp Wood",
      "state": "TX",
      "county": "Real"
  },
  {
      "zipCode": 78834,
      "latitude": 28.507128,
      "longitude": -99.726018,
      "city": "Carrizo Springs",
      "state": "TX",
      "county": "Dimmit"
  },
  {
      "zipCode": 78836,
      "latitude": 28.42297,
      "longitude": -99.75426,
      "city": "Catarina",
      "state": "TX",
      "county": "Dimmit"
  },
  {
      "zipCode": 78837,
      "latitude": 29.763171,
      "longitude": -101.230032,
      "city": "Comstock",
      "state": "TX",
      "county": "Val Verde"
  },
  {
      "zipCode": 78838,
      "latitude": 29.357005,
      "longitude": -99.762039,
      "city": "Concan",
      "state": "TX",
      "county": "Uvalde"
  },
  {
      "zipCode": 78839,
      "latitude": 28.69381,
      "longitude": -99.824936,
      "city": "Crystal City",
      "state": "TX",
      "county": "Zavala"
  },
  {
      "zipCode": 78840,
      "latitude": 29.410195,
      "longitude": -100.893202,
      "city": "Del Rio",
      "state": "TX",
      "county": "Val Verde"
  },
  {
      "zipCode": 78841,
      "latitude": 29.346518,
      "longitude": -100.928864,
      "city": "Del Rio",
      "state": "TX",
      "county": "Val Verde"
  },
  {
      "zipCode": 78842,
      "latitude": 29.411955,
      "longitude": -100.934216,
      "city": "Del Rio",
      "state": "TX",
      "county": "Val Verde"
  },
  {
      "zipCode": 78843,
      "latitude": 29.356379,
      "longitude": -100.79269,
      "city": "Laughlin A F B",
      "state": "TX",
      "county": "Val Verde"
  },
  {
      "zipCode": 78847,
      "latitude": 29.763171,
      "longitude": -101.230032,
      "city": "Del Rio",
      "state": "TX",
      "county": "Val Verde"
  },
  {
      "zipCode": 78850,
      "latitude": 29.339502,
      "longitude": -99.321884,
      "city": "D Hanis",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78851,
      "latitude": 30.218602,
      "longitude": -102.106642,
      "city": "Dryden",
      "state": "TX",
      "county": "Terrell"
  },
  {
      "zipCode": 78852,
      "latitude": 28.781057,
      "longitude": -100.312766,
      "city": "Eagle Pass",
      "state": "TX",
      "county": "Maverick"
  },
  {
      "zipCode": 78853,
      "latitude": 28.679006,
      "longitude": -100.478373,
      "city": "Eagle Pass",
      "state": "TX",
      "county": "Maverick"
  },
  {
      "zipCode": 78860,
      "latitude": 28.529532,
      "longitude": -100.334651,
      "city": "El Indio",
      "state": "TX",
      "county": "Maverick"
  },
  {
      "zipCode": 78861,
      "latitude": 29.398586,
      "longitude": -99.176245,
      "city": "Hondo",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78870,
      "latitude": 29.298401,
      "longitude": -99.636636,
      "city": "Knippa",
      "state": "TX",
      "county": "Uvalde"
  },
  {
      "zipCode": 78871,
      "latitude": 29.763171,
      "longitude": -101.230032,
      "city": "Langtry",
      "state": "TX",
      "county": "Val Verde"
  },
  {
      "zipCode": 78872,
      "latitude": 28.943649,
      "longitude": -99.843874,
      "city": "La Pryor",
      "state": "TX",
      "county": "Zavala"
  },
  {
      "zipCode": 78873,
      "latitude": 29.865649,
      "longitude": -99.686783,
      "city": "Leakey",
      "state": "TX",
      "county": "Real"
  },
  {
      "zipCode": 78877,
      "latitude": 28.641577,
      "longitude": -100.389477,
      "city": "Quemado",
      "state": "TX",
      "county": "Maverick"
  },
  {
      "zipCode": 78879,
      "latitude": 29.84975,
      "longitude": -99.768284,
      "city": "Rio Frio",
      "state": "TX",
      "county": "Real"
  },
  {
      "zipCode": 78880,
      "latitude": 30.017074,
      "longitude": -100.212863,
      "city": "Rocksprings",
      "state": "TX",
      "county": "Edwards"
  },
  {
      "zipCode": 78881,
      "latitude": 29.321387,
      "longitude": -99.468886,
      "city": "Sabinal",
      "state": "TX",
      "county": "Uvalde"
  },
  {
      "zipCode": 78883,
      "latitude": 29.729603,
      "longitude": -99.191141,
      "city": "Tarpley",
      "state": "TX",
      "county": "Bandera"
  },
  {
      "zipCode": 78884,
      "latitude": 29.357005,
      "longitude": -99.762039,
      "city": "Utopia",
      "state": "TX",
      "county": "Uvalde"
  },
  {
      "zipCode": 78885,
      "latitude": 29.729603,
      "longitude": -99.191141,
      "city": "Vanderpool",
      "state": "TX",
      "county": "Bandera"
  },
  {
      "zipCode": 78886,
      "latitude": 29.236464,
      "longitude": -99.145273,
      "city": "Yancey",
      "state": "TX",
      "county": "Medina"
  },
  {
      "zipCode": 78931,
      "latitude": 29.849283,
      "longitude": -96.313271,
      "city": "Bleiblerville",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 78932,
      "latitude": 30.112343,
      "longitude": -96.719014,
      "city": "Carmine",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78933,
      "latitude": 29.886715,
      "longitude": -96.290838,
      "city": "Cat Spring",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 78934,
      "latitude": 29.69632,
      "longitude": -96.592531,
      "city": "Columbus",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 78935,
      "latitude": 29.709171,
      "longitude": -96.486458,
      "city": "Alleyton",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 78938,
      "latitude": 29.854599,
      "longitude": -96.685111,
      "city": "Ellinger",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78940,
      "latitude": 29.859063,
      "longitude": -96.845436,
      "city": "Fayetteville",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78941,
      "latitude": 29.782749,
      "longitude": -97.111371,
      "city": "Flatonia",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78942,
      "latitude": 30.249357,
      "longitude": -96.931852,
      "city": "Giddings",
      "state": "TX",
      "county": "Lee"
  },
  {
      "zipCode": 78943,
      "latitude": 29.699366,
      "longitude": -96.5942,
      "city": "Glidden",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 78944,
      "latitude": 29.977686,
      "longitude": -96.451652,
      "city": "Industry",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 78945,
      "latitude": 29.896494,
      "longitude": -96.872133,
      "city": "La Grange",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78946,
      "latitude": 30.09469,
      "longitude": -96.795224,
      "city": "Ledbetter",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78947,
      "latitude": 30.405444,
      "longitude": -97.020931,
      "city": "Lexington",
      "state": "TX",
      "county": "Lee"
  },
  {
      "zipCode": 78948,
      "latitude": 30.294922,
      "longitude": -96.96599,
      "city": "Lincoln",
      "state": "TX",
      "county": "Lee"
  },
  {
      "zipCode": 78949,
      "latitude": 29.828348,
      "longitude": -97.073122,
      "city": "Muldoon",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78950,
      "latitude": 29.913413,
      "longitude": -96.496067,
      "city": "New Ulm",
      "state": "TX",
      "county": "Austin"
  },
  {
      "zipCode": 78951,
      "latitude": 29.60466,
      "longitude": -96.524899,
      "city": "Oakland",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 78952,
      "latitude": 29.896219,
      "longitude": -96.943868,
      "city": "Plum",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78953,
      "latitude": 29.90847,
      "longitude": -97.317608,
      "city": "Rosanky",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78954,
      "latitude": 30.041719,
      "longitude": -96.711265,
      "city": "Round Top",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78956,
      "latitude": 29.714899,
      "longitude": -96.93382,
      "city": "Schulenburg",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78957,
      "latitude": 30.003401,
      "longitude": -97.222121,
      "city": "Smithville",
      "state": "TX",
      "county": "Bastrop"
  },
  {
      "zipCode": 78959,
      "latitude": 29.693474,
      "longitude": -97.299947,
      "city": "Waelder",
      "state": "TX",
      "county": "Gonzales"
  },
  {
      "zipCode": 78960,
      "latitude": 30.070244,
      "longitude": -96.902035,
      "city": "Warda",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78961,
      "latitude": 29.665518,
      "longitude": -97.039741,
      "city": "Round Top",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 78962,
      "latitude": 29.675937,
      "longitude": -96.743007,
      "city": "Weimar",
      "state": "TX",
      "county": "Colorado"
  },
  {
      "zipCode": 78963,
      "latitude": 29.914207,
      "longitude": -97.053015,
      "city": "West Point",
      "state": "TX",
      "county": "Fayette"
  },
  {
      "zipCode": 79001,
      "latitude": 35.40527,
      "longitude": -102.602749,
      "city": "Adrian",
      "state": "TX",
      "county": "Oldham"
  },
  {
      "zipCode": 79002,
      "latitude": 35.432839,
      "longitude": -100.746559,
      "city": "Alanreed",
      "state": "TX",
      "county": "Gray"
  },
  {
      "zipCode": 79003,
      "latitude": 35.401181,
      "longitude": -100.270306,
      "city": "Allison",
      "state": "TX",
      "county": "Wheeler"
  },
  {
      "zipCode": 79005,
      "latitude": 36.448291,
      "longitude": -100.330411,
      "city": "Booker",
      "state": "TX",
      "county": "Lipscomb"
  },
  {
      "zipCode": 79007,
      "latitude": 35.728104,
      "longitude": -101.487464,
      "city": "Borger",
      "state": "TX",
      "county": "Hutchinson"
  },
  {
      "zipCode": 79008,
      "latitude": 35.631621,
      "longitude": -101.599447,
      "city": "Borger",
      "state": "TX",
      "county": "Hutchinson"
  },
  {
      "zipCode": 79009,
      "latitude": 34.519042,
      "longitude": -102.784639,
      "city": "Bovina",
      "state": "TX",
      "county": "Parmer"
  },
  {
      "zipCode": 79010,
      "latitude": 35.40527,
      "longitude": -102.602749,
      "city": "Boys Ranch",
      "state": "TX",
      "county": "Oldham"
  },
  {
      "zipCode": 79011,
      "latitude": 35.532082,
      "longitude": -100.209628,
      "city": "Briscoe",
      "state": "TX",
      "county": "Wheeler"
  },
  {
      "zipCode": 79012,
      "latitude": 35.225625,
      "longitude": -102.029919,
      "city": "Bushland",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79013,
      "latitude": 36.041191,
      "longitude": -102.00075,
      "city": "Cactus",
      "state": "TX",
      "county": "Moore"
  },
  {
      "zipCode": 79014,
      "latitude": 35.904503,
      "longitude": -100.384093,
      "city": "Canadian",
      "state": "TX",
      "county": "Hemphill"
  },
  {
      "zipCode": 79015,
      "latitude": 35.004998,
      "longitude": -101.91008,
      "city": "Canyon",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79016,
      "latitude": 34.96539,
      "longitude": -101.895894,
      "city": "Canyon",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79018,
      "latitude": 35.685842,
      "longitude": -102.330294,
      "city": "Channing",
      "state": "TX",
      "county": "Hartley"
  },
  {
      "zipCode": 79019,
      "latitude": 35.133039,
      "longitude": -101.460835,
      "city": "Claude",
      "state": "TX",
      "county": "Armstrong"
  },
  {
      "zipCode": 79021,
      "latitude": 34.068903,
      "longitude": -101.826997,
      "city": "Cotton Center",
      "state": "TX",
      "county": "Hale"
  },
  {
      "zipCode": 79022,
      "latitude": 36.277826,
      "longitude": -102.602022,
      "city": "Dalhart",
      "state": "TX",
      "county": "Dallam"
  },
  {
      "zipCode": 79024,
      "latitude": 36.445302,
      "longitude": -100.325415,
      "city": "Darrouzett",
      "state": "TX",
      "county": "Lipscomb"
  },
  {
      "zipCode": 79025,
      "latitude": 34.910608,
      "longitude": -102.200173,
      "city": "Dawn",
      "state": "TX",
      "county": "Deaf Smith"
  },
  {
      "zipCode": 79027,
      "latitude": 34.530453,
      "longitude": -102.262133,
      "city": "Dimmitt",
      "state": "TX",
      "county": "Castro"
  },
  {
      "zipCode": 79029,
      "latitude": 35.9216,
      "longitude": -101.92026,
      "city": "Dumas",
      "state": "TX",
      "county": "Moore"
  },
  {
      "zipCode": 79031,
      "latitude": 34.237177,
      "longitude": -102.36276,
      "city": "Earth",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79032,
      "latitude": 34.278925,
      "longitude": -101.894088,
      "city": "Edmonson",
      "state": "TX",
      "county": "Hale"
  },
  {
      "zipCode": 79033,
      "latitude": 36.391123,
      "longitude": -100.761225,
      "city": "Farnsworth",
      "state": "TX",
      "county": "Ochiltree"
  },
  {
      "zipCode": 79034,
      "latitude": 36.433821,
      "longitude": -100.139117,
      "city": "Follett",
      "state": "TX",
      "county": "Lipscomb"
  },
  {
      "zipCode": 79035,
      "latitude": 34.627408,
      "longitude": -102.784361,
      "city": "Friona",
      "state": "TX",
      "county": "Parmer"
  },
  {
      "zipCode": 79036,
      "latitude": 35.663213,
      "longitude": -101.575988,
      "city": "Fritch",
      "state": "TX",
      "county": "Hutchinson"
  },
  {
      "zipCode": 79039,
      "latitude": 35.276741,
      "longitude": -101.232275,
      "city": "Groom",
      "state": "TX",
      "county": "Carson"
  },
  {
      "zipCode": 79040,
      "latitude": 36.230799,
      "longitude": -101.310055,
      "city": "Gruver",
      "state": "TX",
      "county": "Hansford"
  },
  {
      "zipCode": 79041,
      "latitude": 34.064607,
      "longitude": -101.845753,
      "city": "Hale Center",
      "state": "TX",
      "county": "Hale"
  },
  {
      "zipCode": 79042,
      "latitude": 34.741789,
      "longitude": -101.857175,
      "city": "Happy",
      "state": "TX",
      "county": "Swisher"
  },
  {
      "zipCode": 79043,
      "latitude": 34.385133,
      "longitude": -102.117552,
      "city": "Hart",
      "state": "TX",
      "county": "Castro"
  },
  {
      "zipCode": 79044,
      "latitude": 35.891634,
      "longitude": -102.392664,
      "city": "Hartley",
      "state": "TX",
      "county": "Hartley"
  },
  {
      "zipCode": 79045,
      "latitude": 34.966095,
      "longitude": -102.31309,
      "city": "Hereford",
      "state": "TX",
      "county": "Deaf Smith"
  },
  {
      "zipCode": 79046,
      "latitude": 36.118997,
      "longitude": -100.027623,
      "city": "Higgins",
      "state": "TX",
      "county": "Lipscomb"
  },
  {
      "zipCode": 79051,
      "latitude": 36.277839,
      "longitude": -102.602174,
      "city": "Kerrick",
      "state": "TX",
      "county": "Dallam"
  },
  {
      "zipCode": 79052,
      "latitude": 34.361722,
      "longitude": -101.749905,
      "city": "Kress",
      "state": "TX",
      "county": "Swisher"
  },
  {
      "zipCode": 79053,
      "latitude": 34.52997,
      "longitude": -102.784583,
      "city": "Lazbuddie",
      "state": "TX",
      "county": "Parmer"
  },
  {
      "zipCode": 79054,
      "latitude": 35.439056,
      "longitude": -100.805869,
      "city": "Lefors",
      "state": "TX",
      "county": "Gray"
  },
  {
      "zipCode": 79056,
      "latitude": 36.277602,
      "longitude": -100.273561,
      "city": "Lipscomb",
      "state": "TX",
      "county": "Lipscomb"
  },
  {
      "zipCode": 79057,
      "latitude": 35.231235,
      "longitude": -100.60084,
      "city": "Mclean",
      "state": "TX",
      "county": "Gray"
  },
  {
      "zipCode": 79058,
      "latitude": 35.837775,
      "longitude": -101.892846,
      "city": "Masterson",
      "state": "TX",
      "county": "Moore"
  },
  {
      "zipCode": 79059,
      "latitude": 35.71814,
      "longitude": -100.676817,
      "city": "Miami",
      "state": "TX",
      "county": "Roberts"
  },
  {
      "zipCode": 79061,
      "latitude": 35.515434,
      "longitude": -100.414781,
      "city": "Mobeetie",
      "state": "TX",
      "county": "Wheeler"
  },
  {
      "zipCode": 79062,
      "latitude": 36.277322,
      "longitude": -101.354354,
      "city": "Morse",
      "state": "TX",
      "county": "Hansford"
  },
  {
      "zipCode": 79063,
      "latitude": 34.542943,
      "longitude": -102.09605,
      "city": "Nazareth",
      "state": "TX",
      "county": "Castro"
  },
  {
      "zipCode": 79064,
      "latitude": 34.181129,
      "longitude": -102.145562,
      "city": "Olton",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79065,
      "latitude": 35.515114,
      "longitude": -100.836363,
      "city": "Pampa",
      "state": "TX",
      "county": "Gray"
  },
  {
      "zipCode": 79066,
      "latitude": 35.533384,
      "longitude": -100.956013,
      "city": "Pampa",
      "state": "TX",
      "county": "Gray"
  },
  {
      "zipCode": 79068,
      "latitude": 35.296115,
      "longitude": -101.345689,
      "city": "Panhandle",
      "state": "TX",
      "county": "Carson"
  },
  {
      "zipCode": 79070,
      "latitude": 36.408493,
      "longitude": -100.687227,
      "city": "Perryton",
      "state": "TX",
      "county": "Ochiltree"
  },
  {
      "zipCode": 79072,
      "latitude": 34.068999,
      "longitude": -101.82702,
      "city": "Plainview",
      "state": "TX",
      "county": "Hale"
  },
  {
      "zipCode": 79073,
      "latitude": 34.068903,
      "longitude": -101.826997,
      "city": "Plainview",
      "state": "TX",
      "county": "Hale"
  },
  {
      "zipCode": 79077,
      "latitude": 34.840485,
      "longitude": -100.204928,
      "city": "Samnorwood",
      "state": "TX",
      "county": "Collingsworth"
  },
  {
      "zipCode": 79078,
      "latitude": 35.672009,
      "longitude": -101.574959,
      "city": "Sanford",
      "state": "TX",
      "county": "Hutchinson"
  },
  {
      "zipCode": 79079,
      "latitude": 35.315345,
      "longitude": -100.272153,
      "city": "Shamrock",
      "state": "TX",
      "county": "Wheeler"
  },
  {
      "zipCode": 79080,
      "latitude": 35.569232,
      "longitude": -101.172931,
      "city": "Skellytown",
      "state": "TX",
      "county": "Carson"
  },
  {
      "zipCode": 79081,
      "latitude": 36.226446,
      "longitude": -101.294059,
      "city": "Spearman",
      "state": "TX",
      "county": "Hansford"
  },
  {
      "zipCode": 79082,
      "latitude": 34.23322,
      "longitude": -102.307243,
      "city": "Springlake",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79083,
      "latitude": 35.823913,
      "longitude": -101.445209,
      "city": "Stinnett",
      "state": "TX",
      "county": "Hutchinson"
  },
  {
      "zipCode": 79084,
      "latitude": 36.340796,
      "longitude": -102.073587,
      "city": "Stratford",
      "state": "TX",
      "county": "Sherman"
  },
  {
      "zipCode": 79085,
      "latitude": 34.743735,
      "longitude": -102.506442,
      "city": "Summerfield",
      "state": "TX",
      "county": "Castro"
  },
  {
      "zipCode": 79086,
      "latitude": 36.019612,
      "longitude": -101.820002,
      "city": "Sunray",
      "state": "TX",
      "county": "Moore"
  },
  {
      "zipCode": 79087,
      "latitude": 36.337083,
      "longitude": -102.916844,
      "city": "Texline",
      "state": "TX",
      "county": "Dallam"
  },
  {
      "zipCode": 79088,
      "latitude": 34.558293,
      "longitude": -101.803862,
      "city": "Tulia",
      "state": "TX",
      "county": "Swisher"
  },
  {
      "zipCode": 79091,
      "latitude": 34.96539,
      "longitude": -101.895894,
      "city": "Umbarger",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79092,
      "latitude": 35.224143,
      "longitude": -102.421407,
      "city": "Vega",
      "state": "TX",
      "county": "Oldham"
  },
  {
      "zipCode": 79093,
      "latitude": 36.278199,
      "longitude": -100.815862,
      "city": "Waka",
      "state": "TX",
      "county": "Ochiltree"
  },
  {
      "zipCode": 79094,
      "latitude": 34.965329,
      "longitude": -101.357838,
      "city": "Wayside",
      "state": "TX",
      "county": "Armstrong"
  },
  {
      "zipCode": 79095,
      "latitude": 34.852623,
      "longitude": -100.21447,
      "city": "Wellington",
      "state": "TX",
      "county": "Collingsworth"
  },
  {
      "zipCode": 79096,
      "latitude": 35.394891,
      "longitude": -100.224203,
      "city": "Wheeler",
      "state": "TX",
      "county": "Wheeler"
  },
  {
      "zipCode": 79097,
      "latitude": 35.414902,
      "longitude": -101.176661,
      "city": "White Deer",
      "state": "TX",
      "county": "Carson"
  },
  {
      "zipCode": 79098,
      "latitude": 35.211942,
      "longitude": -102.200425,
      "city": "Wildorado",
      "state": "TX",
      "county": "Oldham"
  },
  {
      "zipCode": 79101,
      "latitude": 35.205452,
      "longitude": -101.795512,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79102,
      "latitude": 35.200852,
      "longitude": -101.851514,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79103,
      "latitude": 35.195302,
      "longitude": -101.744272,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79104,
      "latitude": 35.205702,
      "longitude": -101.777911,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79105,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79106,
      "latitude": 35.248669,
      "longitude": -101.942719,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79107,
      "latitude": 35.273627,
      "longitude": -101.744686,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79108,
      "latitude": 35.293191,
      "longitude": -101.772258,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79109,
      "latitude": 35.11389,
      "longitude": -101.847324,
      "city": "Amarillo",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79110,
      "latitude": 35.131403,
      "longitude": -101.856612,
      "city": "Amarillo",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79111,
      "latitude": 35.258656,
      "longitude": -101.698058,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79114,
      "latitude": 35.050003,
      "longitude": -101.817485,
      "city": "Amarillo",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79116,
      "latitude": 35.245398,
      "longitude": -101.999047,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79117,
      "latitude": 35.308889,
      "longitude": -101.843033,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79118,
      "latitude": 35.098729,
      "longitude": -101.806557,
      "city": "Amarillo",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79119,
      "latitude": 35.060389,
      "longitude": -102.017795,
      "city": "Amarillo",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79120,
      "latitude": 35.196352,
      "longitude": -101.803412,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79121,
      "latitude": 35.142756,
      "longitude": -101.992003,
      "city": "Amarillo",
      "state": "TX",
      "county": "Randall"
  },
  {
      "zipCode": 79123,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79124,
      "latitude": 35.252532,
      "longitude": -101.939874,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79159,
      "latitude": 35.216029,
      "longitude": -102.071415,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79163,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79164,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79165,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79166,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79167,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79168,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79170,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79171,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79172,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79174,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79175,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79178,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79180,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79181,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79182,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79184,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79185,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79186,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79187,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79189,
      "latitude": 35.401475,
      "longitude": -101.895089,
      "city": "Amarillo",
      "state": "TX",
      "county": "Potter"
  },
  {
      "zipCode": 79201,
      "latitude": 34.4104,
      "longitude": -100.236376,
      "city": "Childress",
      "state": "TX",
      "county": "Childress"
  },
  {
      "zipCode": 79220,
      "latitude": 33.616428,
      "longitude": -100.779303,
      "city": "Afton",
      "state": "TX",
      "county": "Dickens"
  },
  {
      "zipCode": 79221,
      "latitude": 34.071514,
      "longitude": -101.30313,
      "city": "Aiken",
      "state": "TX",
      "county": "Floyd"
  },
  {
      "zipCode": 79222,
      "latitude": 34.529678,
      "longitude": -100.207642,
      "city": "Carey",
      "state": "TX",
      "county": "Childress"
  },
  {
      "zipCode": 79223,
      "latitude": 34.074854,
      "longitude": -100.258156,
      "city": "Cee Vee",
      "state": "TX",
      "county": "Cottle"
  },
  {
      "zipCode": 79224,
      "latitude": 34.074854,
      "longitude": -100.258156,
      "city": "Chalk",
      "state": "TX",
      "county": "Cottle"
  },
  {
      "zipCode": 79225,
      "latitude": 34.195699,
      "longitude": -99.547098,
      "city": "Chillicothe",
      "state": "TX",
      "county": "Hardeman"
  },
  {
      "zipCode": 79226,
      "latitude": 34.968107,
      "longitude": -100.891655,
      "city": "Clarendon",
      "state": "TX",
      "county": "Donley"
  },
  {
      "zipCode": 79227,
      "latitude": 33.968163,
      "longitude": -99.641288,
      "city": "Crowell",
      "state": "TX",
      "county": "Foard"
  },
  {
      "zipCode": 79229,
      "latitude": 33.62196,
      "longitude": -100.841745,
      "city": "Dickens",
      "state": "TX",
      "county": "Dickens"
  },
  {
      "zipCode": 79230,
      "latitude": 34.765663,
      "longitude": -100.021258,
      "city": "Dodson",
      "state": "TX",
      "county": "Collingsworth"
  },
  {
      "zipCode": 79231,
      "latitude": 34.071514,
      "longitude": -101.30313,
      "city": "Dougherty",
      "state": "TX",
      "county": "Floyd"
  },
  {
      "zipCode": 79232,
      "latitude": 33.616572,
      "longitude": -100.254835,
      "city": "Dumont",
      "state": "TX",
      "county": "King"
  },
  {
      "zipCode": 79233,
      "latitude": 34.530559,
      "longitude": -100.681047,
      "city": "Estelline",
      "state": "TX",
      "county": "Hall"
  },
  {
      "zipCode": 79234,
      "latitude": 34.073908,
      "longitude": -100.779551,
      "city": "Flomot",
      "state": "TX",
      "county": "Motley"
  },
  {
      "zipCode": 79235,
      "latitude": 34.015814,
      "longitude": -101.381126,
      "city": "Floydada",
      "state": "TX",
      "county": "Floyd"
  },
  {
      "zipCode": 79236,
      "latitude": 33.616723,
      "longitude": -100.254782,
      "city": "Guthrie",
      "state": "TX",
      "county": "King"
  },
  {
      "zipCode": 79237,
      "latitude": 34.869376,
      "longitude": -100.658936,
      "city": "Hedley",
      "state": "TX",
      "county": "Donley"
  },
  {
      "zipCode": 79238,
      "latitude": 34.529678,
      "longitude": -100.207642,
      "city": "Kirkland",
      "state": "TX",
      "county": "Childress"
  },
  {
      "zipCode": 79239,
      "latitude": 34.672342,
      "longitude": -100.696501,
      "city": "Lakeview",
      "state": "TX",
      "county": "Hall"
  },
  {
      "zipCode": 79240,
      "latitude": 34.965525,
      "longitude": -100.814865,
      "city": "Lelia Lake",
      "state": "TX",
      "county": "Donley"
  },
  {
      "zipCode": 79241,
      "latitude": 34.123072,
      "longitude": -101.443848,
      "city": "Lockney",
      "state": "TX",
      "county": "Floyd"
  },
  {
      "zipCode": 79243,
      "latitude": 33.616428,
      "longitude": -100.779303,
      "city": "Mcadoo",
      "state": "TX",
      "county": "Dickens"
  },
  {
      "zipCode": 79244,
      "latitude": 34.01165,
      "longitude": -100.820926,
      "city": "Matador",
      "state": "TX",
      "county": "Motley"
  },
  {
      "zipCode": 79245,
      "latitude": 34.730964,
      "longitude": -100.539782,
      "city": "Memphis",
      "state": "TX",
      "county": "Hall"
  },
  {
      "zipCode": 79247,
      "latitude": 34.341985,
      "longitude": -99.365767,
      "city": "Odell",
      "state": "TX",
      "county": "Wilbarger"
  },
  {
      "zipCode": 79248,
      "latitude": 34.02174,
      "longitude": -100.298633,
      "city": "Paducah",
      "state": "TX",
      "county": "Cottle"
  },
  {
      "zipCode": 79250,
      "latitude": 33.86948,
      "longitude": -101.596234,
      "city": "Petersburg",
      "state": "TX",
      "county": "Hale"
  },
  {
      "zipCode": 79251,
      "latitude": 34.964812,
      "longitude": -100.270689,
      "city": "Quail",
      "state": "TX",
      "county": "Collingsworth"
  },
  {
      "zipCode": 79252,
      "latitude": 34.39086,
      "longitude": -99.857216,
      "city": "Quanah",
      "state": "TX",
      "county": "Hardeman"
  },
  {
      "zipCode": 79255,
      "latitude": 34.380648,
      "longitude": -101.087775,
      "city": "Quitaque",
      "state": "TX",
      "county": "Briscoe"
  },
  {
      "zipCode": 79256,
      "latitude": 33.901277,
      "longitude": -100.857208,
      "city": "Roaring Springs",
      "state": "TX",
      "county": "Motley"
  },
  {
      "zipCode": 79257,
      "latitude": 34.471337,
      "longitude": -101.30419,
      "city": "Silverton",
      "state": "TX",
      "county": "Briscoe"
  },
  {
      "zipCode": 79258,
      "latitude": 34.071514,
      "longitude": -101.30313,
      "city": "South Plains",
      "state": "TX",
      "county": "Floyd"
  },
  {
      "zipCode": 79259,
      "latitude": 34.529678,
      "longitude": -100.207642,
      "city": "Tell",
      "state": "TX",
      "county": "Childress"
  },
  {
      "zipCode": 79261,
      "latitude": 34.394837,
      "longitude": -100.892561,
      "city": "Turkey",
      "state": "TX",
      "county": "Hall"
  },
  {
      "zipCode": 79311,
      "latitude": 33.949361,
      "longitude": -101.81782,
      "city": "Abernathy",
      "state": "TX",
      "county": "Hale"
  },
  {
      "zipCode": 79312,
      "latitude": 34.02831,
      "longitude": -102.425378,
      "city": "Amherst",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79313,
      "latitude": 33.770506,
      "longitude": -102.192713,
      "city": "Anton",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79314,
      "latitude": 33.606603,
      "longitude": -102.825745,
      "city": "Bledsoe",
      "state": "TX",
      "county": "Cochran"
  },
  {
      "zipCode": 79316,
      "latitude": 33.172013,
      "longitude": -102.335299,
      "city": "Brownfield",
      "state": "TX",
      "county": "Terry"
  },
  {
      "zipCode": 79320,
      "latitude": 33.929317,
      "longitude": -102.688297,
      "city": "Bula",
      "state": "TX",
      "county": "Bailey"
  },
  {
      "zipCode": 79322,
      "latitude": 33.616133,
      "longitude": -101.306058,
      "city": "Crosbyton",
      "state": "TX",
      "county": "Crosby"
  },
  {
      "zipCode": 79323,
      "latitude": 33.005196,
      "longitude": -102.851592,
      "city": "Denver City",
      "state": "TX",
      "county": "Yoakum"
  },
  {
      "zipCode": 79324,
      "latitude": 33.868293,
      "longitude": -102.764582,
      "city": "Enochs",
      "state": "TX",
      "county": "Bailey"
  },
  {
      "zipCode": 79325,
      "latitude": 34.524376,
      "longitude": -102.784569,
      "city": "Farwell",
      "state": "TX",
      "county": "Parmer"
  },
  {
      "zipCode": 79326,
      "latitude": 34.068938,
      "longitude": -102.350649,
      "city": "Fieldton",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79329,
      "latitude": 33.593715,
      "longitude": -101.814803,
      "city": "Idalou",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79330,
      "latitude": 33.179123,
      "longitude": -101.298004,
      "city": "Justiceburg",
      "state": "TX",
      "county": "Garza"
  },
  {
      "zipCode": 79331,
      "latitude": 32.715593,
      "longitude": -101.937544,
      "city": "Lamesa",
      "state": "TX",
      "county": "Dawson"
  },
  {
      "zipCode": 79336,
      "latitude": 33.606901,
      "longitude": -102.345939,
      "city": "Levelland",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79338,
      "latitude": 33.593213,
      "longitude": -102.362709,
      "city": "Levelland",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79339,
      "latitude": 34.063781,
      "longitude": -102.413251,
      "city": "Littlefield",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79342,
      "latitude": 32.671468,
      "longitude": -102.644589,
      "city": "Loop",
      "state": "TX",
      "county": "Gaines"
  },
  {
      "zipCode": 79343,
      "latitude": 33.621158,
      "longitude": -101.300936,
      "city": "Lorenzo",
      "state": "TX",
      "county": "Crosby"
  },
  {
      "zipCode": 79344,
      "latitude": 33.863254,
      "longitude": -102.955355,
      "city": "Maple",
      "state": "TX",
      "county": "Bailey"
  },
  {
      "zipCode": 79345,
      "latitude": 33.340812,
      "longitude": -102.208876,
      "city": "Meadow",
      "state": "TX",
      "county": "Terry"
  },
  {
      "zipCode": 79346,
      "latitude": 33.667497,
      "longitude": -102.690997,
      "city": "Morton",
      "state": "TX",
      "county": "Cochran"
  },
  {
      "zipCode": 79347,
      "latitude": 34.092391,
      "longitude": -102.829924,
      "city": "Muleshoe",
      "state": "TX",
      "county": "Bailey"
  },
  {
      "zipCode": 79350,
      "latitude": 33.729589,
      "longitude": -101.83551,
      "city": "New Deal",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79351,
      "latitude": 32.977027,
      "longitude": -101.937148,
      "city": "Odonnell",
      "state": "TX",
      "county": "Lynn"
  },
  {
      "zipCode": 79353,
      "latitude": 33.778988,
      "longitude": -102.466624,
      "city": "Pep",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79355,
      "latitude": 33.149263,
      "longitude": -102.834676,
      "city": "Plains",
      "state": "TX",
      "county": "Yoakum"
  },
  {
      "zipCode": 79356,
      "latitude": 33.180889,
      "longitude": -101.298072,
      "city": "Post",
      "state": "TX",
      "county": "Garza"
  },
  {
      "zipCode": 79357,
      "latitude": 33.68513,
      "longitude": -101.383596,
      "city": "Ralls",
      "state": "TX",
      "county": "Crosby"
  },
  {
      "zipCode": 79358,
      "latitude": 33.451993,
      "longitude": -102.285032,
      "city": "Ropesville",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79359,
      "latitude": 32.94248,
      "longitude": -102.588484,
      "city": "Seagraves",
      "state": "TX",
      "county": "Gaines"
  },
  {
      "zipCode": 79360,
      "latitude": 32.671912,
      "longitude": -102.805355,
      "city": "Seminole",
      "state": "TX",
      "county": "Gaines"
  },
  {
      "zipCode": 79363,
      "latitude": 33.714072,
      "longitude": -102.00946,
      "city": "Shallowater",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79364,
      "latitude": 33.553496,
      "longitude": -101.809124,
      "city": "Slaton",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79366,
      "latitude": 33.531329,
      "longitude": -101.700776,
      "city": "Ransom Canyon",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79367,
      "latitude": 33.566955,
      "longitude": -102.210086,
      "city": "Smyer",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79369,
      "latitude": 34.068938,
      "longitude": -102.350649,
      "city": "Spade",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79370,
      "latitude": 33.471532,
      "longitude": -100.802023,
      "city": "Spur",
      "state": "TX",
      "county": "Dickens"
  },
  {
      "zipCode": 79371,
      "latitude": 34.047934,
      "longitude": -102.526312,
      "city": "Sudan",
      "state": "TX",
      "county": "Lamb"
  },
  {
      "zipCode": 79372,
      "latitude": 33.494472,
      "longitude": -102.438638,
      "city": "Sundown",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79373,
      "latitude": 33.178077,
      "longitude": -101.816443,
      "city": "Tahoka",
      "state": "TX",
      "county": "Lynn"
  },
  {
      "zipCode": 79376,
      "latitude": 33.173518,
      "longitude": -102.829456,
      "city": "Tokio",
      "state": "TX",
      "county": "Yoakum"
  },
  {
      "zipCode": 79377,
      "latitude": 32.929753,
      "longitude": -102.127798,
      "city": "Welch",
      "state": "TX",
      "county": "Dawson"
  },
  {
      "zipCode": 79378,
      "latitude": 33.047275,
      "longitude": -102.428171,
      "city": "Wellman",
      "state": "TX",
      "county": "Terry"
  },
  {
      "zipCode": 79379,
      "latitude": 33.598837,
      "longitude": -102.619882,
      "city": "Whiteface",
      "state": "TX",
      "county": "Cochran"
  },
  {
      "zipCode": 79380,
      "latitude": 33.74566,
      "longitude": -102.320586,
      "city": "Whitharral",
      "state": "TX",
      "county": "Hockley"
  },
  {
      "zipCode": 79381,
      "latitude": 33.318712,
      "longitude": -101.725673,
      "city": "Wilson",
      "state": "TX",
      "county": "Lynn"
  },
  {
      "zipCode": 79382,
      "latitude": 33.563121,
      "longitude": -102.026405,
      "city": "Wolfforth",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79383,
      "latitude": 33.177231,
      "longitude": -101.816432,
      "city": "New Home",
      "state": "TX",
      "county": "Lynn"
  },
  {
      "zipCode": 79401,
      "latitude": 33.607701,
      "longitude": -101.842056,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79402,
      "latitude": 33.592235,
      "longitude": -101.851144,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79403,
      "latitude": 33.582684,
      "longitude": -101.835604,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79404,
      "latitude": 33.562452,
      "longitude": -101.821415,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79405,
      "latitude": 33.578735,
      "longitude": -101.850044,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79406,
      "latitude": 33.604197,
      "longitude": -101.894137,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79407,
      "latitude": 33.555775,
      "longitude": -101.989408,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79408,
      "latitude": 33.565926,
      "longitude": -101.92669,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79409,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79410,
      "latitude": 33.572085,
      "longitude": -101.896394,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79411,
      "latitude": 33.570485,
      "longitude": -101.862944,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79412,
      "latitude": 33.528692,
      "longitude": -101.931,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79413,
      "latitude": 33.546285,
      "longitude": -101.888794,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79414,
      "latitude": 33.576284,
      "longitude": -101.923415,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79415,
      "latitude": 33.675714,
      "longitude": -101.906553,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79416,
      "latitude": 33.587073,
      "longitude": -101.957394,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79423,
      "latitude": 33.472975,
      "longitude": -101.896714,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79424,
      "latitude": 33.492483,
      "longitude": -101.983125,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79430,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79452,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79453,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79457,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79464,
      "latitude": 33.489623,
      "longitude": -102.010895,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79490,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79491,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79493,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79499,
      "latitude": 33.610018,
      "longitude": -101.821292,
      "city": "Lubbock",
      "state": "TX",
      "county": "Lubbock"
  },
  {
      "zipCode": 79501,
      "latitude": 32.765623,
      "longitude": -99.854766,
      "city": "Anson",
      "state": "TX",
      "county": "Jones"
  },
  {
      "zipCode": 79502,
      "latitude": 33.139647,
      "longitude": -100.224966,
      "city": "Aspermont",
      "state": "TX",
      "county": "Stonewall"
  },
  {
      "zipCode": 79503,
      "latitude": 32.749919,
      "longitude": -99.783771,
      "city": "Avoca",
      "state": "TX",
      "county": "Jones"
  },
  {
      "zipCode": 79504,
      "latitude": 32.322053,
      "longitude": -99.335495,
      "city": "Baird",
      "state": "TX",
      "county": "Callahan"
  },
  {
      "zipCode": 79505,
      "latitude": 33.599303,
      "longitude": -99.884689,
      "city": "Benjamin",
      "state": "TX",
      "county": "Knox"
  },
  {
      "zipCode": 79506,
      "latitude": 32.175814,
      "longitude": -100.289569,
      "city": "Blackwell",
      "state": "TX",
      "county": "Nolan"
  },
  {
      "zipCode": 79508,
      "latitude": 32.289413,
      "longitude": -99.810319,
      "city": "Buffalo Gap",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79510,
      "latitude": 32.297559,
      "longitude": -99.412891,
      "city": "Clyde",
      "state": "TX",
      "county": "Callahan"
  },
  {
      "zipCode": 79511,
      "latitude": 32.299597,
      "longitude": -101.343248,
      "city": "Coahoma",
      "state": "TX",
      "county": "Howard"
  },
  {
      "zipCode": 79512,
      "latitude": 32.340912,
      "longitude": -100.900564,
      "city": "Colorado City",
      "state": "TX",
      "county": "Mitchell"
  },
  {
      "zipCode": 79516,
      "latitude": 32.747707,
      "longitude": -100.9153,
      "city": "Dunn",
      "state": "TX",
      "county": "Scurry"
  },
  {
      "zipCode": 79517,
      "latitude": 32.853009,
      "longitude": -101.103033,
      "city": "Fluvanna",
      "state": "TX",
      "county": "Scurry"
  },
  {
      "zipCode": 79518,
      "latitude": 33.336305,
      "longitude": -100.774317,
      "city": "Girard",
      "state": "TX",
      "county": "Kent"
  },
  {
      "zipCode": 79519,
      "latitude": 32.058222,
      "longitude": -99.686094,
      "city": "Goldsboro",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 79520,
      "latitude": 32.817535,
      "longitude": -99.967329,
      "city": "Hamlin",
      "state": "TX",
      "county": "Jones"
  },
  {
      "zipCode": 79521,
      "latitude": 33.207016,
      "longitude": -99.717417,
      "city": "Haskell",
      "state": "TX",
      "county": "Haskell"
  },
  {
      "zipCode": 79525,
      "latitude": 32.642251,
      "longitude": -99.923862,
      "city": "Hawley",
      "state": "TX",
      "county": "Jones"
  },
  {
      "zipCode": 79526,
      "latitude": 32.666914,
      "longitude": -100.789448,
      "city": "Hermleigh",
      "state": "TX",
      "county": "Scurry"
  },
  {
      "zipCode": 79527,
      "latitude": 32.646004,
      "longitude": -101.030102,
      "city": "Ira",
      "state": "TX",
      "county": "Scurry"
  },
  {
      "zipCode": 79528,
      "latitude": 33.185031,
      "longitude": -100.646103,
      "city": "Jayton",
      "state": "TX",
      "county": "Kent"
  },
  {
      "zipCode": 79529,
      "latitude": 33.493579,
      "longitude": -99.812671,
      "city": "Knox City",
      "state": "TX",
      "county": "Knox"
  },
  {
      "zipCode": 79530,
      "latitude": 32.128322,
      "longitude": -99.823884,
      "city": "Lawn",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79532,
      "latitude": 32.378102,
      "longitude": -100.759165,
      "city": "Loraine",
      "state": "TX",
      "county": "Mitchell"
  },
  {
      "zipCode": 79533,
      "latitude": 32.815151,
      "longitude": -99.745175,
      "city": "Lueders",
      "state": "TX",
      "county": "Jones"
  },
  {
      "zipCode": 79534,
      "latitude": 32.753257,
      "longitude": -100.232805,
      "city": "Mc Caulley",
      "state": "TX",
      "county": "Fisher"
  },
  {
      "zipCode": 79535,
      "latitude": 32.180499,
      "longitude": -100.424987,
      "city": "Maryneal",
      "state": "TX",
      "county": "Nolan"
  },
  {
      "zipCode": 79536,
      "latitude": 32.329643,
      "longitude": -99.939593,
      "city": "Merkel",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79537,
      "latitude": 32.272581,
      "longitude": -100.218794,
      "city": "Nolan",
      "state": "TX",
      "county": "Nolan"
  },
  {
      "zipCode": 79538,
      "latitude": 31.953707,
      "longitude": -99.605724,
      "city": "Novice",
      "state": "TX",
      "county": "Coleman"
  },
  {
      "zipCode": 79539,
      "latitude": 33.286038,
      "longitude": -99.847301,
      "city": "O Brien",
      "state": "TX",
      "county": "Haskell"
  },
  {
      "zipCode": 79540,
      "latitude": 33.178927,
      "longitude": -100.254017,
      "city": "Old Glory",
      "state": "TX",
      "county": "Stonewall"
  },
  {
      "zipCode": 79541,
      "latitude": 32.155364,
      "longitude": -99.873296,
      "city": "Ovalo",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79543,
      "latitude": 32.749277,
      "longitude": -100.338798,
      "city": "Roby",
      "state": "TX",
      "county": "Fisher"
  },
  {
      "zipCode": 79544,
      "latitude": 33.331926,
      "longitude": -99.781154,
      "city": "Rochester",
      "state": "TX",
      "county": "Haskell"
  },
  {
      "zipCode": 79545,
      "latitude": 32.384442,
      "longitude": -100.572217,
      "city": "Roscoe",
      "state": "TX",
      "county": "Nolan"
  },
  {
      "zipCode": 79546,
      "latitude": 32.837949,
      "longitude": -100.489193,
      "city": "Rotan",
      "state": "TX",
      "county": "Fisher"
  },
  {
      "zipCode": 79547,
      "latitude": 33.181,
      "longitude": -99.854024,
      "city": "Rule",
      "state": "TX",
      "county": "Haskell"
  },
  {
      "zipCode": 79548,
      "latitude": 33.077086,
      "longitude": -99.958978,
      "city": "Rule",
      "state": "TX",
      "county": "Haskell"
  },
  {
      "zipCode": 79549,
      "latitude": 32.745073,
      "longitude": -100.917479,
      "city": "Snyder",
      "state": "TX",
      "county": "Scurry"
  },
  {
      "zipCode": 79550,
      "latitude": 32.747707,
      "longitude": -100.9153,
      "city": "Snyder",
      "state": "TX",
      "county": "Scurry"
  },
  {
      "zipCode": 79553,
      "latitude": 32.869561,
      "longitude": -99.808377,
      "city": "Stamford",
      "state": "TX",
      "county": "Jones"
  },
  {
      "zipCode": 79556,
      "latitude": 32.308289,
      "longitude": -100.404514,
      "city": "Sweetwater",
      "state": "TX",
      "county": "Nolan"
  },
  {
      "zipCode": 79560,
      "latitude": 32.708935,
      "longitude": -100.234051,
      "city": "Sylvester",
      "state": "TX",
      "county": "Fisher"
  },
  {
      "zipCode": 79561,
      "latitude": 32.472234,
      "longitude": -100.065421,
      "city": "Trent",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79562,
      "latitude": 32.300287,
      "longitude": -99.909752,
      "city": "Tuscola",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79563,
      "latitude": 32.432954,
      "longitude": -99.924042,
      "city": "Tye",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79565,
      "latitude": 32.362705,
      "longitude": -101.043022,
      "city": "Westbrook",
      "state": "TX",
      "county": "Mitchell"
  },
  {
      "zipCode": 79566,
      "latitude": 32.031119,
      "longitude": -100.131927,
      "city": "Wingate",
      "state": "TX",
      "county": "Runnels"
  },
  {
      "zipCode": 79567,
      "latitude": 31.951207,
      "longitude": -99.955406,
      "city": "Winters",
      "state": "TX",
      "county": "Runnels"
  },
  {
      "zipCode": 79601,
      "latitude": 32.344457,
      "longitude": -99.80213,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79602,
      "latitude": 32.342605,
      "longitude": -99.694283,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79603,
      "latitude": 32.418824,
      "longitude": -99.880368,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79604,
      "latitude": 32.428796,
      "longitude": -99.795167,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79605,
      "latitude": 32.370484,
      "longitude": -99.814693,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79606,
      "latitude": 32.303126,
      "longitude": -99.908645,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79607,
      "latitude": 32.427052,
      "longitude": -99.745385,
      "city": "Dyess Afb",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79608,
      "latitude": 32.302132,
      "longitude": -99.890737,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79697,
      "latitude": 32.302132,
      "longitude": -99.890737,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79698,
      "latitude": 32.475074,
      "longitude": -99.73484,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79699,
      "latitude": 32.466474,
      "longitude": -99.711665,
      "city": "Abilene",
      "state": "TX",
      "county": "Taylor"
  },
  {
      "zipCode": 79701,
      "latitude": 31.861876,
      "longitude": -102.091276,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79702,
      "latitude": 31.963698,
      "longitude": -102.080064,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79703,
      "latitude": 31.973312,
      "longitude": -102.134352,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79704,
      "latitude": 31.869259,
      "longitude": -102.031726,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79705,
      "latitude": 32.046146,
      "longitude": -102.073493,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79706,
      "latitude": 31.881592,
      "longitude": -102.01343,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79707,
      "latitude": 32.008395,
      "longitude": -102.185691,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79708,
      "latitude": 31.869259,
      "longitude": -102.031726,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79710,
      "latitude": 31.869259,
      "longitude": -102.031726,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79711,
      "latitude": 31.869259,
      "longitude": -102.031726,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79712,
      "latitude": 31.869259,
      "longitude": -102.031726,
      "city": "Midland",
      "state": "TX",
      "county": "Midland"
  },
  {
      "zipCode": 79713,
      "latitude": 32.527337,
      "longitude": -101.715971,
      "city": "Ackerly",
      "state": "TX",
      "county": "Dawson"
  },
  {
      "zipCode": 79714,
      "latitude": 32.370377,
      "longitude": -102.523255,
      "city": "Andrews",
      "state": "TX",
      "county": "Andrews"
  },
  {
      "zipCode": 79718,
      "latitude": 31.383297,
      "longitude": -103.556598,
      "city": "Balmorhea",
      "state": "TX",
      "county": "Reeves"
  },
  {
      "zipCode": 79719,
      "latitude": 31.453665,
      "longitude": -103.420615,
      "city": "Barstow",
      "state": "TX",
      "county": "Ward"
  },
  {
      "zipCode": 79720,
      "latitude": 32.278703,
      "longitude": -101.457761,
      "city": "Big Spring",
      "state": "TX",
      "county": "Howard"
  },
  {
      "zipCode": 79721,
      "latitude": 32.27328,
      "longitude": -101.373968,
      "city": "Big Spring",
      "state": "TX",
      "county": "Howard"
  },
  {
      "zipCode": 79730,
      "latitude": 30.711984,
      "longitude": -102.676783,
      "city": "Coyanosa",
      "state": "TX",
      "county": "Pecos"
  },
  {
      "zipCode": 79731,
      "latitude": 31.500553,
      "longitude": -102.347243,
      "city": "Crane",
      "state": "TX",
      "county": "Crane"
  },
  {
      "zipCode": 79733,
      "latitude": 32.110537,
      "longitude": -101.366949,
      "city": "Forsan",
      "state": "TX",
      "county": "Howard"
  },
  {
      "zipCode": 79734,
      "latitude": 30.758682,
      "longitude": -104.209994,
      "city": "Fort Davis",
      "state": "TX",
      "county": "Jeff Davis"
  },
  {
      "zipCode": 79735,
      "latitude": 30.886367,
      "longitude": -102.890491,
      "city": "Fort Stockton",
      "state": "TX",
      "county": "Pecos"
  },
  {
      "zipCode": 79738,
      "latitude": 32.743795,
      "longitude": -101.432354,
      "city": "Gail",
      "state": "TX",
      "county": "Borden"
  },
  {
      "zipCode": 79739,
      "latitude": 31.864331,
      "longitude": -101.49375,
      "city": "Garden City",
      "state": "TX",
      "county": "Glasscock"
  },
  {
      "zipCode": 79740,
      "latitude": 30.711984,
      "longitude": -102.676783,
      "city": "Girvin",
      "state": "TX",
      "county": "Pecos"
  },
  {
      "zipCode": 79741,
      "latitude": 31.981472,
      "longitude": -102.615727,
      "city": "Goldsmith",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79742,
      "latitude": 31.340332,
      "longitude": -102.854027,
      "city": "Grandfalls",
      "state": "TX",
      "county": "Ward"
  },
  {
      "zipCode": 79743,
      "latitude": 30.711984,
      "longitude": -102.676783,
      "city": "Imperial",
      "state": "TX",
      "county": "Pecos"
  },
  {
      "zipCode": 79744,
      "latitude": 30.914993,
      "longitude": -101.899569,
      "city": "Iraan",
      "state": "TX",
      "county": "Pecos"
  },
  {
      "zipCode": 79745,
      "latitude": 31.808357,
      "longitude": -103.1135,
      "city": "Kermit",
      "state": "TX",
      "county": "Winkler"
  },
  {
      "zipCode": 79748,
      "latitude": 32.378209,
      "longitude": -101.61263,
      "city": "Knott",
      "state": "TX",
      "county": "Howard"
  },
  {
      "zipCode": 79749,
      "latitude": 32.258885,
      "longitude": -101.844195,
      "city": "Lenorah",
      "state": "TX",
      "county": "Martin"
  },
  {
      "zipCode": 79752,
      "latitude": 31.16157,
      "longitude": -102.107279,
      "city": "Mc Camey",
      "state": "TX",
      "county": "Upton"
  },
  {
      "zipCode": 79754,
      "latitude": 31.825466,
      "longitude": -103.65528,
      "city": "Mentone",
      "state": "TX",
      "county": "Loving"
  },
  {
      "zipCode": 79755,
      "latitude": 31.365814,
      "longitude": -102.04652,
      "city": "Midkiff",
      "state": "TX",
      "county": "Upton"
  },
  {
      "zipCode": 79756,
      "latitude": 31.548092,
      "longitude": -102.943277,
      "city": "Monahans",
      "state": "TX",
      "county": "Ward"
  },
  {
      "zipCode": 79758,
      "latitude": 32.028014,
      "longitude": -102.340552,
      "city": "Gardendale",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79759,
      "latitude": 31.869142,
      "longitude": -102.542944,
      "city": "Notrees",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79760,
      "latitude": 31.765163,
      "longitude": -102.354346,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79761,
      "latitude": 31.854178,
      "longitude": -102.35022,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79762,
      "latitude": 31.893906,
      "longitude": -102.353839,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79763,
      "latitude": 31.796965,
      "longitude": -102.440113,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79764,
      "latitude": 31.88967,
      "longitude": -102.482783,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79765,
      "latitude": 31.880682,
      "longitude": -102.347889,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79766,
      "latitude": 31.769701,
      "longitude": -102.345518,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79768,
      "latitude": 31.869142,
      "longitude": -102.542944,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79769,
      "latitude": 31.746572,
      "longitude": -102.566993,
      "city": "Odessa",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79770,
      "latitude": 31.383297,
      "longitude": -103.556598,
      "city": "Orla",
      "state": "TX",
      "county": "Reeves"
  },
  {
      "zipCode": 79772,
      "latitude": 31.446678,
      "longitude": -103.57906,
      "city": "Pecos",
      "state": "TX",
      "county": "Reeves"
  },
  {
      "zipCode": 79776,
      "latitude": 31.733396,
      "longitude": -102.587906,
      "city": "Penwell",
      "state": "TX",
      "county": "Ector"
  },
  {
      "zipCode": 79777,
      "latitude": 31.538694,
      "longitude": -103.126666,
      "city": "Pyote",
      "state": "TX",
      "county": "Ward"
  },
  {
      "zipCode": 79778,
      "latitude": 31.226573,
      "longitude": -101.944129,
      "city": "Rankin",
      "state": "TX",
      "county": "Upton"
  },
  {
      "zipCode": 79779,
      "latitude": 31.459448,
      "longitude": -103.188993,
      "city": "Royalty",
      "state": "TX",
      "county": "Ward"
  },
  {
      "zipCode": 79780,
      "latitude": 31.383297,
      "longitude": -103.556598,
      "city": "Saragosa",
      "state": "TX",
      "county": "Reeves"
  },
  {
      "zipCode": 79781,
      "latitude": 30.711984,
      "longitude": -102.676783,
      "city": "Sheffield",
      "state": "TX",
      "county": "Pecos"
  },
  {
      "zipCode": 79782,
      "latitude": 32.200014,
      "longitude": -101.823066,
      "city": "Stanton",
      "state": "TX",
      "county": "Martin"
  },
  {
      "zipCode": 79783,
      "latitude": 32.306026,
      "longitude": -101.949963,
      "city": "Tarzan",
      "state": "TX",
      "county": "Martin"
  },
  {
      "zipCode": 79785,
      "latitude": 31.383297,
      "longitude": -103.556598,
      "city": "Toyah",
      "state": "TX",
      "county": "Reeves"
  },
  {
      "zipCode": 79786,
      "latitude": 31.383297,
      "longitude": -103.556598,
      "city": "Toyahvale",
      "state": "TX",
      "county": "Reeves"
  },
  {
      "zipCode": 79788,
      "latitude": 31.569243,
      "longitude": -103.006747,
      "city": "Wickett",
      "state": "TX",
      "county": "Ward"
  },
  {
      "zipCode": 79789,
      "latitude": 31.755315,
      "longitude": -103.155748,
      "city": "Wink",
      "state": "TX",
      "county": "Winkler"
  },
  {
      "zipCode": 79821,
      "latitude": 31.725097,
      "longitude": -106.359795,
      "city": "Anthony",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79830,
      "latitude": 30.357926,
      "longitude": -103.66029,
      "city": "Alpine",
      "state": "TX",
      "county": "Brewster"
  },
  {
      "zipCode": 79831,
      "latitude": 30.436562,
      "longitude": -103.70439,
      "city": "Alpine",
      "state": "TX",
      "county": "Brewster"
  },
  {
      "zipCode": 79832,
      "latitude": 30.363139,
      "longitude": -103.653904,
      "city": "Alpine",
      "state": "TX",
      "county": "Brewster"
  },
  {
      "zipCode": 79834,
      "latitude": 29.818742,
      "longitude": -103.060715,
      "city": "Big Bend National Park",
      "state": "TX",
      "county": "Brewster"
  },
  {
      "zipCode": 79835,
      "latitude": 31.907296,
      "longitude": -106.596493,
      "city": "Canutillo",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79836,
      "latitude": 31.510446,
      "longitude": -106.145329,
      "city": "Clint",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79837,
      "latitude": 31.958194,
      "longitude": -105.201061,
      "city": "Dell City",
      "state": "TX",
      "county": "Hudspeth"
  },
  {
      "zipCode": 79838,
      "latitude": 31.519675,
      "longitude": -106.20338,
      "city": "Fabens",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79839,
      "latitude": 31.315723,
      "longitude": -105.452562,
      "city": "Fort Hancock",
      "state": "TX",
      "county": "Hudspeth"
  },
  {
      "zipCode": 79842,
      "latitude": 29.818742,
      "longitude": -103.060715,
      "city": "Marathon",
      "state": "TX",
      "county": "Brewster"
  },
  {
      "zipCode": 79843,
      "latitude": 30.351902,
      "longitude": -104.018475,
      "city": "Marfa",
      "state": "TX",
      "county": "Presidio"
  },
  {
      "zipCode": 79845,
      "latitude": 29.572128,
      "longitude": -104.372626,
      "city": "Presidio",
      "state": "TX",
      "county": "Presidio"
  },
  {
      "zipCode": 79846,
      "latitude": 29.943719,
      "longitude": -104.386683,
      "city": "Redford",
      "state": "TX",
      "county": "Presidio"
  },
  {
      "zipCode": 79847,
      "latitude": 31.315723,
      "longitude": -105.452562,
      "city": "Salt Flat",
      "state": "TX",
      "county": "Hudspeth"
  },
  {
      "zipCode": 79848,
      "latitude": 30.252946,
      "longitude": -102.162882,
      "city": "Sanderson",
      "state": "TX",
      "county": "Terrell"
  },
  {
      "zipCode": 79849,
      "latitude": 31.550844,
      "longitude": -106.250732,
      "city": "San Elizario",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79850,
      "latitude": 29.943719,
      "longitude": -104.386683,
      "city": "Shafter",
      "state": "TX",
      "county": "Presidio"
  },
  {
      "zipCode": 79851,
      "latitude": 31.315723,
      "longitude": -105.452562,
      "city": "Sierra Blanca",
      "state": "TX",
      "county": "Hudspeth"
  },
  {
      "zipCode": 79852,
      "latitude": 29.818742,
      "longitude": -103.060715,
      "city": "Terlingua",
      "state": "TX",
      "county": "Brewster"
  },
  {
      "zipCode": 79853,
      "latitude": 31.440685,
      "longitude": -106.076524,
      "city": "Tornillo",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79854,
      "latitude": 30.758682,
      "longitude": -104.209994,
      "city": "Valentine",
      "state": "TX",
      "county": "Jeff Davis"
  },
  {
      "zipCode": 79855,
      "latitude": 31.03912,
      "longitude": -104.824473,
      "city": "Van Horn",
      "state": "TX",
      "county": "Culberson"
  },
  {
      "zipCode": 79901,
      "latitude": 31.763608,
      "longitude": -106.484592,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79902,
      "latitude": 31.879779,
      "longitude": -106.544218,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79903,
      "latitude": 31.786857,
      "longitude": -106.445291,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79904,
      "latitude": 31.868055,
      "longitude": -106.44904,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79905,
      "latitude": 31.767758,
      "longitude": -106.438791,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79906,
      "latitude": 31.809164,
      "longitude": -106.424711,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79907,
      "latitude": 31.71251,
      "longitude": -106.328464,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79908,
      "latitude": 31.826457,
      "longitude": -106.385684,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79910,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79911,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79912,
      "latitude": 31.897242,
      "longitude": -106.564782,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79913,
      "latitude": 31.93728,
      "longitude": -106.572393,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79914,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79915,
      "latitude": 31.746559,
      "longitude": -106.372089,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79916,
      "latitude": 31.744353,
      "longitude": -106.287923,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79917,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79918,
      "latitude": 31.831782,
      "longitude": -106.390656,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79920,
      "latitude": 31.821439,
      "longitude": -106.461405,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79922,
      "latitude": 31.802756,
      "longitude": -106.545393,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79923,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79924,
      "latitude": 31.87224,
      "longitude": -106.502841,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79925,
      "latitude": 31.773452,
      "longitude": -106.37709,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79926,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79927,
      "latitude": 31.660671,
      "longitude": -106.176474,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79928,
      "latitude": 31.678635,
      "longitude": -106.115148,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79929,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79930,
      "latitude": 31.809457,
      "longitude": -106.464242,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79931,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79932,
      "latitude": 31.877386,
      "longitude": -106.607588,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79934,
      "latitude": 31.963221,
      "longitude": -106.39227,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79935,
      "latitude": 31.770111,
      "longitude": -106.331988,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79936,
      "latitude": 31.711209,
      "longitude": -106.243004,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79937,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79938,
      "latitude": 31.849828,
      "longitude": -106.3044,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79940,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79941,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79942,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79943,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79944,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79945,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79946,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79947,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79948,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79949,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79950,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79951,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79952,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79953,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79954,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79955,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79958,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79960,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79961,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79966,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79968,
      "latitude": 31.770458,
      "longitude": -106.504843,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79973,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79974,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79975,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79976,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79977,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79978,
      "latitude": 31.799275,
      "longitude": -106.382757,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79980,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79982,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79983,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79984,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79985,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79986,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79987,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79988,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79989,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79990,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79991,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79992,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79993,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79994,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79995,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79996,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79997,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79998,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 79999,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 80001,
      "latitude": 39.522014,
      "longitude": -105.223945,
      "city": "Arvada",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80002,
      "latitude": 39.827818,
      "longitude": -105.109236,
      "city": "Arvada",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80003,
      "latitude": 39.835086,
      "longitude": -105.105775,
      "city": "Arvada",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80004,
      "latitude": 39.814481,
      "longitude": -105.128173,
      "city": "Arvada",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80005,
      "latitude": 39.857259,
      "longitude": -105.12344,
      "city": "Arvada",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80006,
      "latitude": 39.522014,
      "longitude": -105.223945,
      "city": "Arvada",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80007,
      "latitude": 39.863369,
      "longitude": -105.172429,
      "city": "Arvada",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80010,
      "latitude": 39.715287,
      "longitude": -104.84748,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80011,
      "latitude": 39.788286,
      "longitude": -104.822765,
      "city": "Aurora",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80012,
      "latitude": 39.681201,
      "longitude": -104.80973,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80013,
      "latitude": 39.660654,
      "longitude": -104.76275,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80014,
      "latitude": 39.660437,
      "longitude": -104.82933,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80015,
      "latitude": 39.667429,
      "longitude": -104.775265,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80016,
      "latitude": 39.601172,
      "longitude": -104.739428,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80017,
      "latitude": 39.702142,
      "longitude": -104.80667,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80018,
      "latitude": 39.689487,
      "longitude": -104.67982,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80019,
      "latitude": 39.781442,
      "longitude": -104.685374,
      "city": "Aurora",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80020,
      "latitude": 40.046064,
      "longitude": -105.097151,
      "city": "Broomfield",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80021,
      "latitude": 39.885388,
      "longitude": -105.11389,
      "city": "Broomfield",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80022,
      "latitude": 39.869835,
      "longitude": -104.771527,
      "city": "Commerce City",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80024,
      "latitude": 39.844501,
      "longitude": -104.918783,
      "city": "Dupont",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80025,
      "latitude": 39.93242,
      "longitude": -105.287967,
      "city": "Eldorado Springs",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80026,
      "latitude": 40.026334,
      "longitude": -105.104899,
      "city": "Lafayette",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80027,
      "latitude": 39.980835,
      "longitude": -105.269915,
      "city": "Louisville",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80028,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Louisville",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80030,
      "latitude": 39.872535,
      "longitude": -105.034487,
      "city": "Westminster",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80031,
      "latitude": 39.87529,
      "longitude": -105.034487,
      "city": "Westminster",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80033,
      "latitude": 39.776986,
      "longitude": -105.108182,
      "city": "Wheat Ridge",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80034,
      "latitude": 39.522014,
      "longitude": -105.223945,
      "city": "Wheat Ridge",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80035,
      "latitude": 39.80797,
      "longitude": -104.407918,
      "city": "Westminster",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80036,
      "latitude": 39.80797,
      "longitude": -104.407918,
      "city": "Westminster",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80037,
      "latitude": 39.80797,
      "longitude": -104.407918,
      "city": "Commerce City",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80038,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Broomfield",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80040,
      "latitude": 39.80797,
      "longitude": -104.407918,
      "city": "Aurora",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80041,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80042,
      "latitude": 39.80797,
      "longitude": -104.407918,
      "city": "Aurora",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80044,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80045,
      "latitude": 39.746736,
      "longitude": -104.838361,
      "city": "Aurora",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80046,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80047,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Aurora",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80101,
      "latitude": 39.382335,
      "longitude": -104.084781,
      "city": "Agate",
      "state": "CO",
      "county": "Elbert"
  },
  {
      "zipCode": 80102,
      "latitude": 39.869384,
      "longitude": -104.458336,
      "city": "Bennett",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80103,
      "latitude": 39.652632,
      "longitude": -104.162633,
      "city": "Byers",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80104,
      "latitude": 39.374779,
      "longitude": -104.849988,
      "city": "Castle Rock",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80105,
      "latitude": 39.659461,
      "longitude": -103.948538,
      "city": "Deer Trail",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80106,
      "latitude": 39.208967,
      "longitude": -104.505121,
      "city": "Elbert",
      "state": "CO",
      "county": "Elbert"
  },
  {
      "zipCode": 80107,
      "latitude": 39.382844,
      "longitude": -104.495911,
      "city": "Elizabeth",
      "state": "CO",
      "county": "Elbert"
  },
  {
      "zipCode": 80110,
      "latitude": 39.648958,
      "longitude": -104.973852,
      "city": "Englewood",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80111,
      "latitude": 39.666811,
      "longitude": -104.864703,
      "city": "Englewood",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80112,
      "latitude": 39.581238,
      "longitude": -104.862194,
      "city": "Englewood",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80116,
      "latitude": 39.30403,
      "longitude": -104.756722,
      "city": "Franktown",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80117,
      "latitude": 39.370254,
      "longitude": -104.421214,
      "city": "Kiowa",
      "state": "CO",
      "county": "Elbert"
  },
  {
      "zipCode": 80118,
      "latitude": 39.213354,
      "longitude": -104.950189,
      "city": "Larkspur",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80120,
      "latitude": 39.597937,
      "longitude": -105.010182,
      "city": "Littleton",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80121,
      "latitude": 39.612789,
      "longitude": -104.88773,
      "city": "Littleton",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80122,
      "latitude": 39.582222,
      "longitude": -104.955576,
      "city": "Littleton",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80123,
      "latitude": 39.620596,
      "longitude": -105.090085,
      "city": "Littleton",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80124,
      "latitude": 39.529639,
      "longitude": -104.906562,
      "city": "Littleton",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80125,
      "latitude": 39.495327,
      "longitude": -105.05897,
      "city": "Littleton",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80126,
      "latitude": 39.54372,
      "longitude": -104.969143,
      "city": "Littleton",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80127,
      "latitude": 39.544307,
      "longitude": -105.153074,
      "city": "Littleton",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80128,
      "latitude": 39.591827,
      "longitude": -105.083196,
      "city": "Littleton",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80129,
      "latitude": 39.546879,
      "longitude": -105.011599,
      "city": "Littleton",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80130,
      "latitude": 39.528342,
      "longitude": -104.923869,
      "city": "Littleton",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80131,
      "latitude": 39.347863,
      "longitude": -104.994708,
      "city": "Louviers",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80132,
      "latitude": 39.086393,
      "longitude": -104.807666,
      "city": "Monument",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80133,
      "latitude": 39.112138,
      "longitude": -104.900328,
      "city": "Palmer Lake",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80134,
      "latitude": 39.489472,
      "longitude": -104.844731,
      "city": "Parker",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80135,
      "latitude": 39.311334,
      "longitude": -105.067554,
      "city": "Sedalia",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80136,
      "latitude": 39.865547,
      "longitude": -104.291682,
      "city": "Strasburg",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80137,
      "latitude": 39.796784,
      "longitude": -104.630327,
      "city": "Watkins",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80138,
      "latitude": 39.510214,
      "longitude": -104.721607,
      "city": "Parker",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80150,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Englewood",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80151,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Englewood",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80154,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Englewood",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80155,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Englewood",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80160,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Littleton",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80161,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Littleton",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80162,
      "latitude": 39.522014,
      "longitude": -105.223945,
      "city": "Littleton",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80163,
      "latitude": 39.347863,
      "longitude": -104.994708,
      "city": "Littleton",
      "state": "CO",
      "county": "Douglas"
  },
  {
      "zipCode": 80165,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Littleton",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80166,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Littleton",
      "state": "CO",
      "county": "Arapahoe"
  },
  {
      "zipCode": 80201,
      "latitude": 39.726303,
      "longitude": -104.856808,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80202,
      "latitude": 39.760189,
      "longitude": -104.996553,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80203,
      "latitude": 39.729205,
      "longitude": -104.970331,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80204,
      "latitude": 39.720136,
      "longitude": -105.021502,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80205,
      "latitude": 39.760137,
      "longitude": -104.967585,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80206,
      "latitude": 39.730787,
      "longitude": -104.953335,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80207,
      "latitude": 39.713186,
      "longitude": -104.903532,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80208,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80209,
      "latitude": 39.707332,
      "longitude": -104.964236,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80210,
      "latitude": 39.701677,
      "longitude": -104.964186,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80211,
      "latitude": 39.765291,
      "longitude": -105.018387,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80212,
      "latitude": 39.768286,
      "longitude": -105.049338,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80214,
      "latitude": 39.743636,
      "longitude": -105.064336,
      "city": "Denver",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80215,
      "latitude": 39.743486,
      "longitude": -105.100854,
      "city": "Denver",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80216,
      "latitude": 39.777036,
      "longitude": -104.942034,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80217,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80218,
      "latitude": 39.720236,
      "longitude": -104.968759,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80219,
      "latitude": 39.695014,
      "longitude": -105.034339,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80220,
      "latitude": 39.732436,
      "longitude": -104.910843,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80221,
      "latitude": 39.838028,
      "longitude": -104.998788,
      "city": "Denver",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80222,
      "latitude": 39.709343,
      "longitude": -104.924134,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80223,
      "latitude": 39.707186,
      "longitude": -105.008762,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80224,
      "latitude": 39.702037,
      "longitude": -104.911434,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80225,
      "latitude": 39.69709,
      "longitude": -105.12044,
      "city": "Denver",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80226,
      "latitude": 39.712337,
      "longitude": -105.091784,
      "city": "Denver",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80227,
      "latitude": 39.667479,
      "longitude": -105.067136,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80228,
      "latitude": 39.688776,
      "longitude": -105.156,
      "city": "Denver",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80229,
      "latitude": 39.867098,
      "longitude": -104.922688,
      "city": "Denver",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80230,
      "latitude": 39.721848,
      "longitude": -104.895093,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80231,
      "latitude": 39.68493,
      "longitude": -104.886785,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80232,
      "latitude": 39.689487,
      "longitude": -105.090779,
      "city": "Denver",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80233,
      "latitude": 39.901534,
      "longitude": -104.940683,
      "city": "Denver",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80234,
      "latitude": 39.910774,
      "longitude": -105.010886,
      "city": "Denver",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80235,
      "latitude": 39.645837,
      "longitude": -105.065003,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80236,
      "latitude": 39.649837,
      "longitude": -105.034988,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80237,
      "latitude": 39.638537,
      "longitude": -104.903984,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80238,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80239,
      "latitude": 39.804398,
      "longitude": -104.813919,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80241,
      "latitude": 39.927421,
      "longitude": -104.954833,
      "city": "Denver",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80243,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80244,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80246,
      "latitude": 39.708637,
      "longitude": -104.931234,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80248,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80249,
      "latitude": 39.83776,
      "longitude": -104.697674,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80250,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80251,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80252,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80254,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80255,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80256,
      "latitude": 39.74739,
      "longitude": -104.992842,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80257,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80259,
      "latitude": 39.746239,
      "longitude": -104.991334,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80260,
      "latitude": 39.867235,
      "longitude": -105.004125,
      "city": "Denver",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80261,
      "latitude": 39.737929,
      "longitude": -104.985036,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80262,
      "latitude": 39.731038,
      "longitude": -104.938391,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80263,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80264,
      "latitude": 39.74259,
      "longitude": -104.986292,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80265,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80266,
      "latitude": 39.747179,
      "longitude": -104.991511,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80270,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80271,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80273,
      "latitude": 39.727293,
      "longitude": -104.987535,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80274,
      "latitude": 39.743934,
      "longitude": -104.987577,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80275,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80279,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80280,
      "latitude": 39.716675,
      "longitude": -104.906942,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80281,
      "latitude": 39.74394,
      "longitude": -104.987577,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80285,
      "latitude": "",
      "longitude": "",
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80290,
      "latitude": 39.743608,
      "longitude": -104.987556,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80291,
      "latitude": 39.74394,
      "longitude": -104.987577,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80292,
      "latitude": 39.74739,
      "longitude": -104.992842,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80293,
      "latitude": 39.745786,
      "longitude": -104.990686,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80294,
      "latitude": 39.749086,
      "longitude": -104.989036,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80295,
      "latitude": 39.745486,
      "longitude": -104.986336,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80299,
      "latitude": 39.738752,
      "longitude": -104.408349,
      "city": "Denver",
      "state": "CO",
      "county": "Denver"
  },
  {
      "zipCode": 80301,
      "latitude": 40.094787,
      "longitude": -105.278083,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80302,
      "latitude": 40.063935,
      "longitude": -105.390027,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80303,
      "latitude": 40.067772,
      "longitude": -105.27484,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80304,
      "latitude": 40.114114,
      "longitude": -105.369797,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80305,
      "latitude": 39.976873,
      "longitude": -105.248683,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80306,
      "latitude": 40.102219,
      "longitude": -105.384694,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80307,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80308,
      "latitude": 40.027672,
      "longitude": -105.3868,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80309,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80310,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80314,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80321,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80322,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80323,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80328,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80329,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Boulder",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80401,
      "latitude": 39.718539,
      "longitude": -105.245659,
      "city": "Golden",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80402,
      "latitude": 39.522014,
      "longitude": -105.223945,
      "city": "Golden",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80403,
      "latitude": 39.829821,
      "longitude": -105.270395,
      "city": "Golden",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80419,
      "latitude": 39.522014,
      "longitude": -105.223945,
      "city": "Golden",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80420,
      "latitude": 39.297972,
      "longitude": -106.063548,
      "city": "Alma",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80421,
      "latitude": 39.203721,
      "longitude": -105.747242,
      "city": "Bailey",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80422,
      "latitude": 39.81599,
      "longitude": -105.475322,
      "city": "Black Hawk",
      "state": "CO",
      "county": "Gilpin"
  },
  {
      "zipCode": 80423,
      "latitude": 39.860356,
      "longitude": -106.631246,
      "city": "Bond",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 80424,
      "latitude": 39.475323,
      "longitude": -106.022472,
      "city": "Breckenridge",
      "state": "CO",
      "county": "Summit"
  },
  {
      "zipCode": 80425,
      "latitude": 39.332179,
      "longitude": -105.254998,
      "city": "Buffalo Creek",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80426,
      "latitude": 39.78361,
      "longitude": -106.896452,
      "city": "Burns",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 80427,
      "latitude": 39.821626,
      "longitude": -105.545631,
      "city": "Central City",
      "state": "CO",
      "county": "Gilpin"
  },
  {
      "zipCode": 80428,
      "latitude": 40.730717,
      "longitude": -106.913996,
      "city": "Clark",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80429,
      "latitude": 39.225758,
      "longitude": -106.311697,
      "city": "Climax",
      "state": "CO",
      "county": "Lake"
  },
  {
      "zipCode": 80430,
      "latitude": 40.480364,
      "longitude": -106.448221,
      "city": "Coalmont",
      "state": "CO",
      "county": "Jackson"
  },
  {
      "zipCode": 80432,
      "latitude": 39.158746,
      "longitude": -105.816986,
      "city": "Como",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80433,
      "latitude": 39.473542,
      "longitude": -105.284448,
      "city": "Conifer",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80434,
      "latitude": 40.663052,
      "longitude": -106.362488,
      "city": "Cowdrey",
      "state": "CO",
      "county": "Jackson"
  },
  {
      "zipCode": 80435,
      "latitude": 39.595212,
      "longitude": -105.974117,
      "city": "Dillon",
      "state": "CO",
      "county": "Summit"
  },
  {
      "zipCode": 80436,
      "latitude": 39.75315,
      "longitude": -105.635592,
      "city": "Dumont",
      "state": "CO",
      "county": "Clear Creek"
  },
  {
      "zipCode": 80437,
      "latitude": 39.522014,
      "longitude": -105.223945,
      "city": "Evergreen",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80438,
      "latitude": 39.76193,
      "longitude": -105.665451,
      "city": "Empire",
      "state": "CO",
      "county": "Clear Creek"
  },
  {
      "zipCode": 80439,
      "latitude": 39.629598,
      "longitude": -105.326248,
      "city": "Evergreen",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80440,
      "latitude": 39.149,
      "longitude": -105.885893,
      "city": "Fairplay",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80442,
      "latitude": 39.958523,
      "longitude": -105.792637,
      "city": "Fraser",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80443,
      "latitude": 39.558929,
      "longitude": -106.133183,
      "city": "Frisco",
      "state": "CO",
      "county": "Summit"
  },
  {
      "zipCode": 80444,
      "latitude": 39.725703,
      "longitude": -105.760626,
      "city": "Georgetown",
      "state": "CO",
      "county": "Clear Creek"
  },
  {
      "zipCode": 80446,
      "latitude": 40.073869,
      "longitude": -105.928533,
      "city": "Granby",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80447,
      "latitude": 40.153982,
      "longitude": -105.852209,
      "city": "Grand Lake",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80448,
      "latitude": 39.461223,
      "longitude": -105.658305,
      "city": "Grant",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80449,
      "latitude": 38.967262,
      "longitude": -105.878811,
      "city": "Hartsel",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80451,
      "latitude": 40.071207,
      "longitude": -106.069256,
      "city": "Hot Sulphur Springs",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80452,
      "latitude": 39.706574,
      "longitude": -105.541583,
      "city": "Idaho Springs",
      "state": "CO",
      "county": "Clear Creek"
  },
  {
      "zipCode": 80453,
      "latitude": 39.668426,
      "longitude": -105.244245,
      "city": "Idledale",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80454,
      "latitude": 39.654637,
      "longitude": -105.248119,
      "city": "Indian Hills",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80455,
      "latitude": 40.101985,
      "longitude": -105.423992,
      "city": "Jamestown",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80456,
      "latitude": 39.27593,
      "longitude": -105.686535,
      "city": "Jefferson",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80457,
      "latitude": 39.648137,
      "longitude": -105.278194,
      "city": "Kittredge",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80459,
      "latitude": 40.147681,
      "longitude": -106.378296,
      "city": "Kremmling",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80461,
      "latitude": 39.21753,
      "longitude": -106.35833,
      "city": "Leadville",
      "state": "CO",
      "county": "Lake"
  },
  {
      "zipCode": 80463,
      "latitude": 39.82712,
      "longitude": -106.772151,
      "city": "Mc Coy",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 80465,
      "latitude": 39.610289,
      "longitude": -105.201967,
      "city": "Morrison",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80466,
      "latitude": 40.000686,
      "longitude": -105.495998,
      "city": "Nederland",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80467,
      "latitude": 40.323272,
      "longitude": -106.944274,
      "city": "Oak Creek",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80468,
      "latitude": 39.953932,
      "longitude": -106.093005,
      "city": "Parshall",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80469,
      "latitude": 40.190711,
      "longitude": -106.973513,
      "city": "Phippsburg",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80470,
      "latitude": 39.356599,
      "longitude": -105.3136,
      "city": "Pine",
      "state": "CO",
      "county": "Jefferson"
  },
  {
      "zipCode": 80471,
      "latitude": 39.947386,
      "longitude": -105.459269,
      "city": "Pinecliffe",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80473,
      "latitude": 40.466701,
      "longitude": -106.185703,
      "city": "Rand",
      "state": "CO",
      "county": "Jackson"
  },
  {
      "zipCode": 80474,
      "latitude": 39.883804,
      "longitude": -105.5482,
      "city": "Rollinsville",
      "state": "CO",
      "county": "Gilpin"
  },
  {
      "zipCode": 80475,
      "latitude": 39.439493,
      "longitude": -105.602959,
      "city": "Shawnee",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80476,
      "latitude": 39.699631,
      "longitude": -105.75074,
      "city": "Silver Plume",
      "state": "CO",
      "county": "Clear Creek"
  },
  {
      "zipCode": 80477,
      "latitude": 40.348242,
      "longitude": -106.92691,
      "city": "Steamboat Springs",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80478,
      "latitude": 39.979743,
      "longitude": -105.86148,
      "city": "Tabernash",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80479,
      "latitude": 40.067609,
      "longitude": -106.851718,
      "city": "Toponas",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80480,
      "latitude": 40.678825,
      "longitude": -106.249045,
      "city": "Walden",
      "state": "CO",
      "county": "Jackson"
  },
  {
      "zipCode": 80481,
      "latitude": 40.055785,
      "longitude": -105.472634,
      "city": "Ward",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80482,
      "latitude": 39.949118,
      "longitude": -105.816439,
      "city": "Winter Park",
      "state": "CO",
      "county": "Grand"
  },
  {
      "zipCode": 80483,
      "latitude": 40.130445,
      "longitude": -106.911567,
      "city": "Yampa",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80487,
      "latitude": 40.632683,
      "longitude": -106.931827,
      "city": "Steamboat Springs",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80488,
      "latitude": 40.619661,
      "longitude": -106.860746,
      "city": "Steamboat Springs",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 80497,
      "latitude": 39.641146,
      "longitude": -106.108002,
      "city": "Silverthorne",
      "state": "CO",
      "county": "Summit"
  },
  {
      "zipCode": 80498,
      "latitude": 39.764692,
      "longitude": -106.221139,
      "city": "Silverthorne",
      "state": "CO",
      "county": "Summit"
  },
  {
      "zipCode": 80501,
      "latitude": 40.165634,
      "longitude": -105.1633,
      "city": "Longmont",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80502,
      "latitude": 40.087835,
      "longitude": -105.373507,
      "city": "Longmont",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80503,
      "latitude": 40.159043,
      "longitude": -105.212558,
      "city": "Longmont",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80504,
      "latitude": 40.129416,
      "longitude": -104.970816,
      "city": "Longmont",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80510,
      "latitude": 40.210184,
      "longitude": -105.50795,
      "city": "Allenspark",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80511,
      "latitude": 40.628112,
      "longitude": -105.569245,
      "city": "Estes Park",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80512,
      "latitude": 40.610729,
      "longitude": -105.525784,
      "city": "Bellvue",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80513,
      "latitude": 40.530475,
      "longitude": -105.321046,
      "city": "Berthoud",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80514,
      "latitude": 40.204344,
      "longitude": -104.911938,
      "city": "Dacono",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80515,
      "latitude": 40.427541,
      "longitude": -105.383062,
      "city": "Drake",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80516,
      "latitude": 40.062204,
      "longitude": -104.923481,
      "city": "Erie",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80517,
      "latitude": 40.370748,
      "longitude": -105.323971,
      "city": "Estes Park",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80520,
      "latitude": 40.122477,
      "longitude": -104.935838,
      "city": "Firestone",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80521,
      "latitude": 40.59227,
      "longitude": -105.298344,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80522,
      "latitude": 40.642907,
      "longitude": -105.057005,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80523,
      "latitude": 40.45553,
      "longitude": -105.464832,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80524,
      "latitude": 40.59889,
      "longitude": -105.252751,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80525,
      "latitude": 40.532277,
      "longitude": -105.207073,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80526,
      "latitude": 40.587553,
      "longitude": -105.324268,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80527,
      "latitude": 40.540565,
      "longitude": -105.280009,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80528,
      "latitude": 40.533726,
      "longitude": -105.034077,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80530,
      "latitude": 40.123557,
      "longitude": -104.938057,
      "city": "Frederick",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80532,
      "latitude": 40.457758,
      "longitude": -105.447005,
      "city": "Glen Haven",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80533,
      "latitude": 40.181515,
      "longitude": -105.232695,
      "city": "Hygiene",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80534,
      "latitude": 40.320091,
      "longitude": -104.867196,
      "city": "Johnstown",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80535,
      "latitude": 40.589014,
      "longitude": -105.262822,
      "city": "Laporte",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80536,
      "latitude": 40.693564,
      "longitude": -105.441688,
      "city": "Livermore",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80537,
      "latitude": 40.553704,
      "longitude": -105.266415,
      "city": "Loveland",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80538,
      "latitude": 40.551116,
      "longitude": -105.183385,
      "city": "Loveland",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80539,
      "latitude": 40.38636,
      "longitude": -105.163377,
      "city": "Loveland",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80540,
      "latitude": 40.189788,
      "longitude": -105.35528,
      "city": "Lyons",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80541,
      "latitude": 40.529278,
      "longitude": -105.372014,
      "city": "Masonville",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80542,
      "latitude": 40.327051,
      "longitude": -104.893796,
      "city": "Mead",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80543,
      "latitude": 40.387381,
      "longitude": -104.841349,
      "city": "Milliken",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80544,
      "latitude": 40.177043,
      "longitude": -105.275045,
      "city": "Niwot",
      "state": "CO",
      "county": "Boulder"
  },
  {
      "zipCode": 80545,
      "latitude": 40.610176,
      "longitude": -105.638852,
      "city": "Red Feather Lakes",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80546,
      "latitude": 40.524987,
      "longitude": -104.850544,
      "city": "Severance",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80547,
      "latitude": 40.708692,
      "longitude": -105.017539,
      "city": "Timnath",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80549,
      "latitude": 40.795455,
      "longitude": -105.085627,
      "city": "Wellington",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80550,
      "latitude": 40.307972,
      "longitude": -104.942467,
      "city": "Windsor",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80551,
      "latitude": 40.464092,
      "longitude": -104.885116,
      "city": "Windsor",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80553,
      "latitude": 40.628112,
      "longitude": -105.569245,
      "city": "Fort Collins",
      "state": "CO",
      "county": "Larimer"
  },
  {
      "zipCode": 80601,
      "latitude": 39.942984,
      "longitude": -104.786597,
      "city": "Brighton",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80602,
      "latitude": 39.966162,
      "longitude": -104.908888,
      "city": "Brighton",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80603,
      "latitude": 39.951146,
      "longitude": -104.660237,
      "city": "Brighton",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80610,
      "latitude": 40.675794,
      "longitude": -104.607073,
      "city": "Ault",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80611,
      "latitude": 40.443596,
      "longitude": -104.240542,
      "city": "Briggsdale",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80612,
      "latitude": 40.597204,
      "longitude": -104.869798,
      "city": "Carr",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80614,
      "latitude": 39.80797,
      "longitude": -104.407918,
      "city": "Eastlake",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80615,
      "latitude": 40.350055,
      "longitude": -104.660412,
      "city": "Eaton",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80620,
      "latitude": 40.263453,
      "longitude": -104.791285,
      "city": "Evans",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80621,
      "latitude": 40.363977,
      "longitude": -104.650827,
      "city": "Fort Lupton",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80622,
      "latitude": 40.536158,
      "longitude": -104.500735,
      "city": "Galeton",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80623,
      "latitude": 40.24938,
      "longitude": -104.796889,
      "city": "Gilcrest",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80624,
      "latitude": 40.475572,
      "longitude": -104.590607,
      "city": "Gill",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80631,
      "latitude": 40.384991,
      "longitude": -104.680631,
      "city": "Greeley",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80632,
      "latitude": 40.376626,
      "longitude": -104.762899,
      "city": "Greeley",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80633,
      "latitude": 40.500919,
      "longitude": -104.31497,
      "city": "Greeley",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80634,
      "latitude": 40.281195,
      "longitude": -104.796453,
      "city": "Greeley",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80638,
      "latitude": 40.500919,
      "longitude": -104.31497,
      "city": "Greeley",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80639,
      "latitude": 40.399281,
      "longitude": -104.70172,
      "city": "Greeley",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80640,
      "latitude": 39.879235,
      "longitude": -104.883581,
      "city": "Henderson",
      "state": "CO",
      "county": "Adams"
  },
  {
      "zipCode": 80642,
      "latitude": 40.109353,
      "longitude": -104.672306,
      "city": "Hudson",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80643,
      "latitude": 40.085322,
      "longitude": -104.506123,
      "city": "Keenesburg",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80644,
      "latitude": 40.47404,
      "longitude": -104.472779,
      "city": "Kersey",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80645,
      "latitude": 40.328185,
      "longitude": -104.665639,
      "city": "La Salle",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80646,
      "latitude": 40.48243,
      "longitude": -104.70542,
      "city": "Lucerne",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80648,
      "latitude": 40.492804,
      "longitude": -104.627586,
      "city": "Nunn",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80649,
      "latitude": 40.414728,
      "longitude": -104.081667,
      "city": "Orchard",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80650,
      "latitude": 40.646551,
      "longitude": -104.744575,
      "city": "Pierce",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80651,
      "latitude": 40.217382,
      "longitude": -104.712141,
      "city": "Platteville",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80652,
      "latitude": 40.087777,
      "longitude": -104.281976,
      "city": "Roggen",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80653,
      "latitude": 40.382218,
      "longitude": -103.974159,
      "city": "Weldona",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80654,
      "latitude": 40.159839,
      "longitude": -104.046791,
      "city": "Wiggins",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80701,
      "latitude": 40.262591,
      "longitude": -103.847648,
      "city": "Fort Morgan",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80705,
      "latitude": 40.270654,
      "longitude": -103.833813,
      "city": "Log Lane Village",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80720,
      "latitude": 40.073328,
      "longitude": -103.202379,
      "city": "Akron",
      "state": "CO",
      "county": "Washington"
  },
  {
      "zipCode": 80721,
      "latitude": 40.608363,
      "longitude": -102.3582,
      "city": "Amherst",
      "state": "CO",
      "county": "Phillips"
  },
  {
      "zipCode": 80722,
      "latitude": 40.525066,
      "longitude": -103.197305,
      "city": "Atwood",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80723,
      "latitude": 40.218547,
      "longitude": -103.613248,
      "city": "Brush",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80726,
      "latitude": 40.8523,
      "longitude": -103.074727,
      "city": "Crook",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80727,
      "latitude": 40.061126,
      "longitude": -102.4803,
      "city": "Eckley",
      "state": "CO",
      "county": "Yuma"
  },
  {
      "zipCode": 80728,
      "latitude": 40.598608,
      "longitude": -102.874735,
      "city": "Fleming",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80729,
      "latitude": 40.820129,
      "longitude": -104.168529,
      "city": "Grover",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80731,
      "latitude": 40.601383,
      "longitude": -102.523842,
      "city": "Haxtun",
      "state": "CO",
      "county": "Phillips"
  },
  {
      "zipCode": 80732,
      "latitude": 40.975104,
      "longitude": -104.305265,
      "city": "Hereford",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80733,
      "latitude": 40.345863,
      "longitude": -103.505721,
      "city": "Hillrose",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80734,
      "latitude": 40.587166,
      "longitude": -102.272326,
      "city": "Holyoke",
      "state": "CO",
      "county": "Phillips"
  },
  {
      "zipCode": 80735,
      "latitude": 39.816703,
      "longitude": -102.426235,
      "city": "Idalia",
      "state": "CO",
      "county": "Yuma"
  },
  {
      "zipCode": 80736,
      "latitude": 40.784483,
      "longitude": -102.960098,
      "city": "Iliff",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80737,
      "latitude": 40.875606,
      "longitude": -102.351994,
      "city": "Julesburg",
      "state": "CO",
      "county": "Sedgwick"
  },
  {
      "zipCode": 80740,
      "latitude": 39.790894,
      "longitude": -103.414172,
      "city": "Lindon",
      "state": "CO",
      "county": "Washington"
  },
  {
      "zipCode": 80741,
      "latitude": 40.632075,
      "longitude": -103.413883,
      "city": "Merino",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80742,
      "latitude": 40.736271,
      "longitude": -103.878656,
      "city": "New Raymer",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80743,
      "latitude": 40.163025,
      "longitude": -103.042516,
      "city": "Otis",
      "state": "CO",
      "county": "Washington"
  },
  {
      "zipCode": 80744,
      "latitude": 40.875571,
      "longitude": -102.358241,
      "city": "Ovid",
      "state": "CO",
      "county": "Sedgwick"
  },
  {
      "zipCode": 80745,
      "latitude": 40.884156,
      "longitude": -103.372814,
      "city": "Padroni",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80746,
      "latitude": 40.610554,
      "longitude": -102.472203,
      "city": "Paoli",
      "state": "CO",
      "county": "Phillips"
  },
  {
      "zipCode": 80747,
      "latitude": 40.825442,
      "longitude": -103.16182,
      "city": "Peetz",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80749,
      "latitude": 40.875703,
      "longitude": -102.44023,
      "city": "Sedgwick",
      "state": "CO",
      "county": "Sedgwick"
  },
  {
      "zipCode": 80750,
      "latitude": 40.415193,
      "longitude": -103.610575,
      "city": "Snyder",
      "state": "CO",
      "county": "Morgan"
  },
  {
      "zipCode": 80751,
      "latitude": 40.715081,
      "longitude": -103.211191,
      "city": "Sterling",
      "state": "CO",
      "county": "Logan"
  },
  {
      "zipCode": 80754,
      "latitude": 40.735758,
      "longitude": -103.997391,
      "city": "Stoneham",
      "state": "CO",
      "county": "Weld"
  },
  {
      "zipCode": 80755,
      "latitude": 39.812149,
      "longitude": -102.434252,
      "city": "Vernon",
      "state": "CO",
      "county": "Yuma"
  },
  {
      "zipCode": 80757,
      "latitude": 39.804985,
      "longitude": -103.57521,
      "city": "Woodrow",
      "state": "CO",
      "county": "Washington"
  },
  {
      "zipCode": 80758,
      "latitude": 40.068525,
      "longitude": -102.392973,
      "city": "Wray",
      "state": "CO",
      "county": "Yuma"
  },
  {
      "zipCode": 80759,
      "latitude": 40.061817,
      "longitude": -102.618522,
      "city": "Yuma",
      "state": "CO",
      "county": "Yuma"
  },
  {
      "zipCode": 80801,
      "latitude": 39.690908,
      "longitude": -103.137281,
      "city": "Anton",
      "state": "CO",
      "county": "Washington"
  },
  {
      "zipCode": 80802,
      "latitude": 38.847485,
      "longitude": -102.524932,
      "city": "Arapahoe",
      "state": "CO",
      "county": "Cheyenne"
  },
  {
      "zipCode": 80804,
      "latitude": 39.316461,
      "longitude": -103.323143,
      "city": "Arriba",
      "state": "CO",
      "county": "Lincoln"
  },
  {
      "zipCode": 80805,
      "latitude": 39.293345,
      "longitude": -102.433217,
      "city": "Bethune",
      "state": "CO",
      "county": "Kit Carson"
  },
  {
      "zipCode": 80807,
      "latitude": 39.308966,
      "longitude": -102.461287,
      "city": "Burlington",
      "state": "CO",
      "county": "Kit Carson"
  },
  {
      "zipCode": 80808,
      "latitude": 38.94043,
      "longitude": -104.282512,
      "city": "Calhan",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80809,
      "latitude": 38.945534,
      "longitude": -104.994972,
      "city": "Cascade",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80810,
      "latitude": 38.827259,
      "longitude": -102.411537,
      "city": "Cheyenne Wells",
      "state": "CO",
      "county": "Cheyenne"
  },
  {
      "zipCode": 80812,
      "latitude": 39.684772,
      "longitude": -102.99038,
      "city": "Cope",
      "state": "CO",
      "county": "Washington"
  },
  {
      "zipCode": 80813,
      "latitude": 38.826084,
      "longitude": -105.149874,
      "city": "Cripple Creek",
      "state": "CO",
      "county": "Teller"
  },
  {
      "zipCode": 80814,
      "latitude": 38.923901,
      "longitude": -105.185653,
      "city": "Divide",
      "state": "CO",
      "county": "Teller"
  },
  {
      "zipCode": 80815,
      "latitude": 39.32513,
      "longitude": -102.783284,
      "city": "Flagler",
      "state": "CO",
      "county": "Kit Carson"
  },
  {
      "zipCode": 80816,
      "latitude": 38.855231,
      "longitude": -105.190895,
      "city": "Florissant",
      "state": "CO",
      "county": "Teller"
  },
  {
      "zipCode": 80817,
      "latitude": 38.663856,
      "longitude": -104.652221,
      "city": "Fountain",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80818,
      "latitude": 39.348432,
      "longitude": -103.49065,
      "city": "Genoa",
      "state": "CO",
      "county": "Lincoln"
  },
  {
      "zipCode": 80819,
      "latitude": 38.93893,
      "longitude": -105.009444,
      "city": "Green Mountain Falls",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80820,
      "latitude": 38.835374,
      "longitude": -105.562124,
      "city": "Guffey",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80821,
      "latitude": 39.022942,
      "longitude": -103.457864,
      "city": "Hugo",
      "state": "CO",
      "county": "Lincoln"
  },
  {
      "zipCode": 80822,
      "latitude": 39.959546,
      "longitude": -102.500066,
      "city": "Joes",
      "state": "CO",
      "county": "Yuma"
  },
  {
      "zipCode": 80823,
      "latitude": 38.806509,
      "longitude": -103.474701,
      "city": "Karval",
      "state": "CO",
      "county": "Lincoln"
  },
  {
      "zipCode": 80824,
      "latitude": 39.664421,
      "longitude": -102.546469,
      "city": "Kirk",
      "state": "CO",
      "county": "Yuma"
  },
  {
      "zipCode": 80825,
      "latitude": 38.827174,
      "longitude": -102.598136,
      "city": "Kit Carson",
      "state": "CO",
      "county": "Cheyenne"
  },
  {
      "zipCode": 80826,
      "latitude": 39.041435,
      "longitude": -103.604851,
      "city": "Limon",
      "state": "CO",
      "county": "Lincoln"
  },
  {
      "zipCode": 80827,
      "latitude": 38.980056,
      "longitude": -105.514472,
      "city": "Lake George",
      "state": "CO",
      "county": "Park"
  },
  {
      "zipCode": 80828,
      "latitude": 39.31654,
      "longitude": -103.588908,
      "city": "Limon",
      "state": "CO",
      "county": "Lincoln"
  },
  {
      "zipCode": 80829,
      "latitude": 38.853366,
      "longitude": -104.927452,
      "city": "Manitou Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80830,
      "latitude": 39.114381,
      "longitude": -103.900144,
      "city": "Matheson",
      "state": "CO",
      "county": "Elbert"
  },
  {
      "zipCode": 80831,
      "latitude": 38.984206,
      "longitude": -104.526328,
      "city": "Peyton",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80832,
      "latitude": 39.035064,
      "longitude": -104.163326,
      "city": "Ramah",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80833,
      "latitude": 38.818756,
      "longitude": -104.117904,
      "city": "Rush",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80834,
      "latitude": 39.322355,
      "longitude": -102.881192,
      "city": "Seibert",
      "state": "CO",
      "county": "Kit Carson"
  },
  {
      "zipCode": 80835,
      "latitude": 39.208696,
      "longitude": -104.070186,
      "city": "Simla",
      "state": "CO",
      "county": "Elbert"
  },
  {
      "zipCode": 80836,
      "latitude": 39.304913,
      "longitude": -102.67301,
      "city": "Stratton",
      "state": "CO",
      "county": "Kit Carson"
  },
  {
      "zipCode": 80840,
      "latitude": 38.979235,
      "longitude": -104.860561,
      "city": "U S A F Academy",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80841,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "U S A F Academy",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80860,
      "latitude": 38.731005,
      "longitude": -105.132073,
      "city": "Victor",
      "state": "CO",
      "county": "Teller"
  },
  {
      "zipCode": 80861,
      "latitude": 39.311151,
      "longitude": -102.74229,
      "city": "Vona",
      "state": "CO",
      "county": "Kit Carson"
  },
  {
      "zipCode": 80862,
      "latitude": 38.804111,
      "longitude": -103.079761,
      "city": "Wild Horse",
      "state": "CO",
      "county": "Cheyenne"
  },
  {
      "zipCode": 80863,
      "latitude": 38.918437,
      "longitude": -105.166398,
      "city": "Woodland Park",
      "state": "CO",
      "county": "Teller"
  },
  {
      "zipCode": 80864,
      "latitude": 38.738943,
      "longitude": -104.193088,
      "city": "Yoder",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80866,
      "latitude": 39.03635,
      "longitude": -105.15597,
      "city": "Woodland Park",
      "state": "CO",
      "county": "Teller"
  },
  {
      "zipCode": 80901,
      "latitude": 38.861469,
      "longitude": -104.857828,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80903,
      "latitude": 38.828893,
      "longitude": -104.809929,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80904,
      "latitude": 38.861981,
      "longitude": -104.874531,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80905,
      "latitude": 38.844319,
      "longitude": -104.801472,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80906,
      "latitude": 38.796074,
      "longitude": -104.841763,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80907,
      "latitude": 38.943076,
      "longitude": -104.81444,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80908,
      "latitude": 39.020647,
      "longitude": -104.705498,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80909,
      "latitude": 38.838268,
      "longitude": -104.85023,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80910,
      "latitude": 38.804094,
      "longitude": -104.772059,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80911,
      "latitude": 38.922849,
      "longitude": -104.789157,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80912,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80913,
      "latitude": 38.729969,
      "longitude": -104.753607,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80914,
      "latitude": 38.819195,
      "longitude": -104.701177,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80915,
      "latitude": 38.874436,
      "longitude": -104.751515,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80916,
      "latitude": 38.81033,
      "longitude": -104.718555,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80917,
      "latitude": 38.82513,
      "longitude": -104.746689,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80918,
      "latitude": 38.90319,
      "longitude": -104.807998,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80919,
      "latitude": 38.938149,
      "longitude": -104.917436,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80920,
      "latitude": 38.965298,
      "longitude": -104.755956,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80921,
      "latitude": 39.055054,
      "longitude": -104.89122,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80922,
      "latitude": 38.904817,
      "longitude": -104.70124,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80925,
      "latitude": 38.737774,
      "longitude": -104.645854,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80926,
      "latitude": 38.644087,
      "longitude": -104.880825,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80928,
      "latitude": 38.645078,
      "longitude": -104.395746,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80929,
      "latitude": 38.846355,
      "longitude": -104.624727,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80930,
      "latitude": 38.824202,
      "longitude": -104.493259,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80931,
      "latitude": 38.953003,
      "longitude": -104.60923,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80932,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80933,
      "latitude": 38.939371,
      "longitude": -105.011786,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80934,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80935,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80936,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80937,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80940,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80941,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80942,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80943,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80944,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80945,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80946,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80947,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80949,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80950,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80960,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80962,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80970,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80977,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80995,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 80997,
      "latitude": 38.82469,
      "longitude": -104.562027,
      "city": "Colorado Springs",
      "state": "CO",
      "county": "El Paso"
  },
  {
      "zipCode": 81001,
      "latitude": 38.344117,
      "longitude": -104.427776,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81002,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81003,
      "latitude": 38.121031,
      "longitude": -104.763851,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81004,
      "latitude": 38.087325,
      "longitude": -104.822451,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81005,
      "latitude": 38.152191,
      "longitude": -104.841328,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81006,
      "latitude": 38.113259,
      "longitude": -104.52127,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81007,
      "latitude": 38.387004,
      "longitude": -104.779156,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81008,
      "latitude": 38.402934,
      "longitude": -104.644423,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81009,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81010,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81011,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81012,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81013,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81014,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81015,
      "latitude": 38.128626,
      "longitude": -104.552299,
      "city": "Pueblo",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81019,
      "latitude": 37.887346,
      "longitude": -104.613617,
      "city": "Colorado City",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81020,
      "latitude": 37.381577,
      "longitude": -104.653809,
      "city": "Aguilar",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81021,
      "latitude": 38.430393,
      "longitude": -103.366655,
      "city": "Arlington",
      "state": "CO",
      "county": "Kiowa"
  },
  {
      "zipCode": 81022,
      "latitude": 38.102502,
      "longitude": -104.529794,
      "city": "Avondale",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81023,
      "latitude": 38.127363,
      "longitude": -104.63577,
      "city": "Beulah",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81024,
      "latitude": 37.208109,
      "longitude": -104.719787,
      "city": "Boncarbo",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81025,
      "latitude": 38.319575,
      "longitude": -104.209119,
      "city": "Boone",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81027,
      "latitude": 37.232444,
      "longitude": -103.722865,
      "city": "Branson",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81029,
      "latitude": 37.184434,
      "longitude": -102.473938,
      "city": "Campo",
      "state": "CO",
      "county": "Baca"
  },
  {
      "zipCode": 81030,
      "latitude": 38.102941,
      "longitude": -103.5367,
      "city": "Cheraw",
      "state": "CO",
      "county": "Otero"
  },
  {
      "zipCode": 81033,
      "latitude": 38.229447,
      "longitude": -103.804241,
      "city": "Crowley",
      "state": "CO",
      "county": "Crowley"
  },
  {
      "zipCode": 81034,
      "latitude": 38.317851,
      "longitude": -103.779959,
      "city": "Crowley",
      "state": "CO",
      "county": "Crowley"
  },
  {
      "zipCode": 81036,
      "latitude": 38.440752,
      "longitude": -102.554901,
      "city": "Eads",
      "state": "CO",
      "county": "Kiowa"
  },
  {
      "zipCode": 81038,
      "latitude": 37.955547,
      "longitude": -103.072469,
      "city": "Fort Lyon",
      "state": "CO",
      "county": "Bent"
  },
  {
      "zipCode": 81039,
      "latitude": 37.98146,
      "longitude": -103.967799,
      "city": "Fowler",
      "state": "CO",
      "county": "Otero"
  },
  {
      "zipCode": 81040,
      "latitude": 37.787772,
      "longitude": -105.18487,
      "city": "Gardner",
      "state": "CO",
      "county": "Huerfano"
  },
  {
      "zipCode": 81041,
      "latitude": 37.870309,
      "longitude": -102.367626,
      "city": "Granada",
      "state": "CO",
      "county": "Prowers"
  },
  {
      "zipCode": 81042,
      "latitude": 37.315263,
      "longitude": -104.73461,
      "city": "Gulnare",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81043,
      "latitude": 38.119522,
      "longitude": -102.216833,
      "city": "Hartman",
      "state": "CO",
      "county": "Prowers"
  },
  {
      "zipCode": 81044,
      "latitude": 37.959036,
      "longitude": -103.015041,
      "city": "Hasty",
      "state": "CO",
      "county": "Bent"
  },
  {
      "zipCode": 81045,
      "latitude": 38.440077,
      "longitude": -103.130677,
      "city": "Haswell",
      "state": "CO",
      "county": "Kiowa"
  },
  {
      "zipCode": 81046,
      "latitude": 37.279584,
      "longitude": -104.358375,
      "city": "Hoehne",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81047,
      "latitude": 37.956511,
      "longitude": -102.335202,
      "city": "Holly",
      "state": "CO",
      "county": "Prowers"
  },
  {
      "zipCode": 81049,
      "latitude": 37.33276,
      "longitude": -103.373586,
      "city": "Kim",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81050,
      "latitude": 37.954619,
      "longitude": -103.664437,
      "city": "La Junta",
      "state": "CO",
      "county": "Otero"
  },
  {
      "zipCode": 81052,
      "latitude": 37.955488,
      "longitude": -102.436416,
      "city": "Lamar",
      "state": "CO",
      "county": "Prowers"
  },
  {
      "zipCode": 81054,
      "latitude": 37.923031,
      "longitude": -103.088444,
      "city": "Las Animas",
      "state": "CO",
      "county": "Bent"
  },
  {
      "zipCode": 81055,
      "latitude": 37.511672,
      "longitude": -105.057545,
      "city": "La Veta",
      "state": "CO",
      "county": "Huerfano"
  },
  {
      "zipCode": 81057,
      "latitude": 38.085032,
      "longitude": -102.88691,
      "city": "Mc Clave",
      "state": "CO",
      "county": "Bent"
  },
  {
      "zipCode": 81058,
      "latitude": 38.082793,
      "longitude": -103.885189,
      "city": "Manzanola",
      "state": "CO",
      "county": "Otero"
  },
  {
      "zipCode": 81059,
      "latitude": 37.519198,
      "longitude": -104.223007,
      "city": "Model",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81062,
      "latitude": 38.327723,
      "longitude": -103.953208,
      "city": "Olney Springs",
      "state": "CO",
      "county": "Crowley"
  },
  {
      "zipCode": 81063,
      "latitude": 38.317486,
      "longitude": -103.780036,
      "city": "Ordway",
      "state": "CO",
      "county": "Crowley"
  },
  {
      "zipCode": 81064,
      "latitude": 37.272262,
      "longitude": -102.914489,
      "city": "Pritchett",
      "state": "CO",
      "county": "Baca"
  },
  {
      "zipCode": 81066,
      "latitude": 37.712093,
      "longitude": -105.344931,
      "city": "Walsenburg",
      "state": "CO",
      "county": "Huerfano"
  },
  {
      "zipCode": 81067,
      "latitude": 37.995867,
      "longitude": -103.708332,
      "city": "Rocky Ford",
      "state": "CO",
      "county": "Otero"
  },
  {
      "zipCode": 81069,
      "latitude": 37.936061,
      "longitude": -104.874843,
      "city": "Rye",
      "state": "CO",
      "county": "Pueblo"
  },
  {
      "zipCode": 81071,
      "latitude": 38.441431,
      "longitude": -102.604797,
      "city": "Sheridan Lake",
      "state": "CO",
      "county": "Kiowa"
  },
  {
      "zipCode": 81073,
      "latitude": 37.371522,
      "longitude": -102.677359,
      "city": "Springfield",
      "state": "CO",
      "county": "Baca"
  },
  {
      "zipCode": 81074,
      "latitude": 37.121029,
      "longitude": -104.523155,
      "city": "Starkville",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81076,
      "latitude": 38.315371,
      "longitude": -103.599555,
      "city": "Sugar City",
      "state": "CO",
      "county": "Crowley"
  },
  {
      "zipCode": 81077,
      "latitude": 38.054962,
      "longitude": -103.619493,
      "city": "Swink",
      "state": "CO",
      "county": "Otero"
  },
  {
      "zipCode": 81081,
      "latitude": 37.0999,
      "longitude": -104.132767,
      "city": "Trinchera",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81082,
      "latitude": 37.269059,
      "longitude": -104.5038,
      "city": "Trinidad",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81084,
      "latitude": 37.521432,
      "longitude": -102.433241,
      "city": "Two Buttes",
      "state": "CO",
      "county": "Baca"
  },
  {
      "zipCode": 81087,
      "latitude": 37.33995,
      "longitude": -102.491522,
      "city": "Vilas",
      "state": "CO",
      "county": "Baca"
  },
  {
      "zipCode": 81089,
      "latitude": 37.646467,
      "longitude": -104.779815,
      "city": "Walsenburg",
      "state": "CO",
      "county": "Huerfano"
  },
  {
      "zipCode": 81090,
      "latitude": 37.368042,
      "longitude": -102.265146,
      "city": "Walsh",
      "state": "CO",
      "county": "Baca"
  },
  {
      "zipCode": 81091,
      "latitude": 37.190046,
      "longitude": -104.795074,
      "city": "Weston",
      "state": "CO",
      "county": "Las Animas"
  },
  {
      "zipCode": 81092,
      "latitude": 38.189902,
      "longitude": -102.705397,
      "city": "Wiley",
      "state": "CO",
      "county": "Prowers"
  },
  {
      "zipCode": 81101,
      "latitude": 37.479933,
      "longitude": -105.790967,
      "city": "Alamosa",
      "state": "CO",
      "county": "Alamosa"
  },
  {
      "zipCode": 81102,
      "latitude": 37.476026,
      "longitude": -105.881779,
      "city": "Alamosa",
      "state": "CO",
      "county": "Alamosa"
  },
  {
      "zipCode": 81120,
      "latitude": 37.135059,
      "longitude": -106.190367,
      "city": "Antonito",
      "state": "CO",
      "county": "Conejos"
  },
  {
      "zipCode": 81121,
      "latitude": 37.109225,
      "longitude": -107.31183,
      "city": "Arboles",
      "state": "CO",
      "county": "Archuleta"
  },
  {
      "zipCode": 81122,
      "latitude": 37.292065,
      "longitude": -107.736318,
      "city": "Bayfield",
      "state": "CO",
      "county": "La Plata"
  },
  {
      "zipCode": 81123,
      "latitude": 37.435812,
      "longitude": -105.57348,
      "city": "Blanca",
      "state": "CO",
      "county": "Costilla"
  },
  {
      "zipCode": 81124,
      "latitude": 37.304205,
      "longitude": -106.127935,
      "city": "Capulin",
      "state": "CO",
      "county": "Conejos"
  },
  {
      "zipCode": 81125,
      "latitude": 37.869875,
      "longitude": -106.046711,
      "city": "Center",
      "state": "CO",
      "county": "Saguache"
  },
  {
      "zipCode": 81126,
      "latitude": 37.326735,
      "longitude": -105.450502,
      "city": "Chama",
      "state": "CO",
      "county": "Costilla"
  },
  {
      "zipCode": 81127,
      "latitude": 37.250704,
      "longitude": -107.321138,
      "city": "Chimney Rock",
      "state": "CO",
      "county": "Archuleta"
  },
  {
      "zipCode": 81128,
      "latitude": 37.130148,
      "longitude": -106.821686,
      "city": "Chromo",
      "state": "CO",
      "county": "Archuleta"
  },
  {
      "zipCode": 81129,
      "latitude": 37.101134,
      "longitude": -106.02648,
      "city": "Conejos",
      "state": "CO",
      "county": "Conejos"
  },
  {
      "zipCode": 81130,
      "latitude": 37.761293,
      "longitude": -106.981852,
      "city": "Creede",
      "state": "CO",
      "county": "Mineral"
  },
  {
      "zipCode": 81131,
      "latitude": 37.953038,
      "longitude": -105.68788,
      "city": "Crestone",
      "state": "CO",
      "county": "Saguache"
  },
  {
      "zipCode": 81132,
      "latitude": 37.644684,
      "longitude": -106.407311,
      "city": "Del Norte",
      "state": "CO",
      "county": "Rio Grande"
  },
  {
      "zipCode": 81133,
      "latitude": 37.403455,
      "longitude": -105.415437,
      "city": "Fort Garland",
      "state": "CO",
      "county": "Costilla"
  },
  {
      "zipCode": 81134,
      "latitude": 37.092195,
      "longitude": -105.530023,
      "city": "Garcia",
      "state": "CO",
      "county": "Costilla"
  },
  {
      "zipCode": 81135,
      "latitude": 37.615741,
      "longitude": -106.375292,
      "city": "Homelake",
      "state": "CO",
      "county": "Rio Grande"
  },
  {
      "zipCode": 81136,
      "latitude": 37.699184,
      "longitude": -105.873616,
      "city": "Hooper",
      "state": "CO",
      "county": "Alamosa"
  },
  {
      "zipCode": 81137,
      "latitude": 37.114844,
      "longitude": -107.659745,
      "city": "Ignacio",
      "state": "CO",
      "county": "La Plata"
  },
  {
      "zipCode": 81138,
      "latitude": 37.197731,
      "longitude": -105.429395,
      "city": "Jaroso",
      "state": "CO",
      "county": "Costilla"
  },
  {
      "zipCode": 81140,
      "latitude": 37.276566,
      "longitude": -106.007989,
      "city": "La Jara",
      "state": "CO",
      "county": "Conejos"
  },
  {
      "zipCode": 81141,
      "latitude": 37.175463,
      "longitude": -105.927841,
      "city": "Manassa",
      "state": "CO",
      "county": "Conejos"
  },
  {
      "zipCode": 81143,
      "latitude": 38.065284,
      "longitude": -105.817955,
      "city": "Moffat",
      "state": "CO",
      "county": "Saguache"
  },
  {
      "zipCode": 81144,
      "latitude": 37.570515,
      "longitude": -106.345624,
      "city": "Monte Vista",
      "state": "CO",
      "county": "Rio Grande"
  },
  {
      "zipCode": 81146,
      "latitude": 37.631025,
      "longitude": -105.773133,
      "city": "Mosca",
      "state": "CO",
      "county": "Alamosa"
  },
  {
      "zipCode": 81147,
      "latitude": 37.202251,
      "longitude": -107.12619,
      "city": "Pagosa Springs",
      "state": "CO",
      "county": "Archuleta"
  },
  {
      "zipCode": 81148,
      "latitude": 37.172101,
      "longitude": -105.985811,
      "city": "Romeo",
      "state": "CO",
      "county": "Conejos"
  },
  {
      "zipCode": 81149,
      "latitude": 38.181206,
      "longitude": -106.351826,
      "city": "Saguache",
      "state": "CO",
      "county": "Saguache"
  },
  {
      "zipCode": 81151,
      "latitude": 37.255221,
      "longitude": -105.848097,
      "city": "Sanford",
      "state": "CO",
      "county": "Conejos"
  },
  {
      "zipCode": 81152,
      "latitude": 37.106599,
      "longitude": -105.47813,
      "city": "San Luis",
      "state": "CO",
      "county": "Costilla"
  },
  {
      "zipCode": 81153,
      "latitude": 37.202995,
      "longitude": -105.418718,
      "city": "San Pablo",
      "state": "CO",
      "county": "Costilla"
  },
  {
      "zipCode": 81154,
      "latitude": 37.638307,
      "longitude": -106.597775,
      "city": "South Fork",
      "state": "CO",
      "county": "Rio Grande"
  },
  {
      "zipCode": 81155,
      "latitude": 38.306022,
      "longitude": -106.046577,
      "city": "Villa Grove",
      "state": "CO",
      "county": "Saguache"
  },
  {
      "zipCode": 81157,
      "latitude": 37.270438,
      "longitude": -107.093153,
      "city": "Pagosa Springs",
      "state": "CO",
      "county": "Archuleta"
  },
  {
      "zipCode": 81201,
      "latitude": 38.629119,
      "longitude": -106.120582,
      "city": "Salida",
      "state": "CO",
      "county": "Chaffee"
  },
  {
      "zipCode": 81210,
      "latitude": 38.820006,
      "longitude": -106.660278,
      "city": "Almont",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81211,
      "latitude": 38.885032,
      "longitude": -106.179938,
      "city": "Buena Vista",
      "state": "CO",
      "county": "Chaffee"
  },
  {
      "zipCode": 81212,
      "latitude": 38.477727,
      "longitude": -105.507603,
      "city": "Canon City",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81215,
      "latitude": 38.477563,
      "longitude": -105.476577,
      "city": "Canon City",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81220,
      "latitude": 38.416326,
      "longitude": -107.529303,
      "city": "Cimarron",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81221,
      "latitude": 38.338919,
      "longitude": -105.159722,
      "city": "Coal Creek",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81222,
      "latitude": 38.382377,
      "longitude": -105.784315,
      "city": "Coaldale",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81223,
      "latitude": 38.439983,
      "longitude": -105.49649,
      "city": "Cotopaxi",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81224,
      "latitude": 38.880713,
      "longitude": -106.94868,
      "city": "Crested Butte",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81225,
      "latitude": 38.90056,
      "longitude": -106.963148,
      "city": "Crested Butte",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81226,
      "latitude": 38.355202,
      "longitude": -105.254782,
      "city": "Florence",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81227,
      "latitude": 38.545757,
      "longitude": -106.243422,
      "city": "Monarch",
      "state": "CO",
      "county": "Chaffee"
  },
  {
      "zipCode": 81228,
      "latitude": 39.030747,
      "longitude": -106.256615,
      "city": "Granite",
      "state": "CO",
      "county": "Chaffee"
  },
  {
      "zipCode": 81230,
      "latitude": 38.528323,
      "longitude": -107.037294,
      "city": "Gunnison",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81231,
      "latitude": 38.702309,
      "longitude": -106.940201,
      "city": "Gunnison",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81232,
      "latitude": 38.285533,
      "longitude": -105.642451,
      "city": "Hillside",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81233,
      "latitude": 38.410012,
      "longitude": -105.769848,
      "city": "Howard",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81235,
      "latitude": 38.026256,
      "longitude": -107.276581,
      "city": "Lake City",
      "state": "CO",
      "county": "Hinsdale"
  },
  {
      "zipCode": 81236,
      "latitude": 38.747896,
      "longitude": -106.251196,
      "city": "Nathrop",
      "state": "CO",
      "county": "Chaffee"
  },
  {
      "zipCode": 81237,
      "latitude": 38.590788,
      "longitude": -106.602838,
      "city": "Ohio City",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81239,
      "latitude": 38.537083,
      "longitude": -106.635214,
      "city": "Parlin",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81240,
      "latitude": 38.414125,
      "longitude": -105.047277,
      "city": "Penrose",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81241,
      "latitude": 38.589072,
      "longitude": -106.570985,
      "city": "Pitkin",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81242,
      "latitude": 38.471892,
      "longitude": -106.097284,
      "city": "Poncha Springs",
      "state": "CO",
      "county": "Chaffee"
  },
  {
      "zipCode": 81243,
      "latitude": 38.270602,
      "longitude": -107.302888,
      "city": "Powderhorn",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81244,
      "latitude": 38.336184,
      "longitude": -105.22114,
      "city": "Rockvale",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81246,
      "latitude": 38.477563,
      "longitude": -105.476577,
      "city": "Canon City",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81247,
      "latitude": 38.457513,
      "longitude": -107.292081,
      "city": "Gunnison",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81248,
      "latitude": 38.403295,
      "longitude": -106.416083,
      "city": "Sargents",
      "state": "CO",
      "county": "Saguache"
  },
  {
      "zipCode": 81251,
      "latitude": 39.151466,
      "longitude": -106.422616,
      "city": "Twin Lakes",
      "state": "CO",
      "county": "Lake"
  },
  {
      "zipCode": 81252,
      "latitude": 38.095173,
      "longitude": -105.421953,
      "city": "Westcliffe",
      "state": "CO",
      "county": "Custer"
  },
  {
      "zipCode": 81253,
      "latitude": 38.121657,
      "longitude": -105.14766,
      "city": "Wetmore",
      "state": "CO",
      "county": "Custer"
  },
  {
      "zipCode": 81290,
      "latitude": 38.358421,
      "longitude": -105.106881,
      "city": "Florence",
      "state": "CO",
      "county": "Fremont"
  },
  {
      "zipCode": 81301,
      "latitude": 37.318364,
      "longitude": -107.880367,
      "city": "Durango",
      "state": "CO",
      "county": "La Plata"
  },
  {
      "zipCode": 81302,
      "latitude": 37.357249,
      "longitude": -107.935945,
      "city": "Durango",
      "state": "CO",
      "county": "La Plata"
  },
  {
      "zipCode": 81303,
      "latitude": 37.133408,
      "longitude": -107.843803,
      "city": "Durango",
      "state": "CO",
      "county": "La Plata"
  },
  {
      "zipCode": 81320,
      "latitude": 37.718233,
      "longitude": -108.791671,
      "city": "Cahone",
      "state": "CO",
      "county": "Dolores"
  },
  {
      "zipCode": 81321,
      "latitude": 37.373594,
      "longitude": -108.662826,
      "city": "Cortez",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81323,
      "latitude": 37.469333,
      "longitude": -108.35245,
      "city": "Dolores",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81324,
      "latitude": 37.711532,
      "longitude": -108.924334,
      "city": "Dove Creek",
      "state": "CO",
      "county": "Dolores"
  },
  {
      "zipCode": 81325,
      "latitude": 37.929371,
      "longitude": -108.846067,
      "city": "Egnar",
      "state": "CO",
      "county": "San Miguel"
  },
  {
      "zipCode": 81326,
      "latitude": 37.205423,
      "longitude": -108.138519,
      "city": "Hesperus",
      "state": "CO",
      "county": "La Plata"
  },
  {
      "zipCode": 81327,
      "latitude": 37.458047,
      "longitude": -108.655638,
      "city": "Lewis",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81328,
      "latitude": 37.336041,
      "longitude": -108.468364,
      "city": "Mancos",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81329,
      "latitude": 37.092605,
      "longitude": -108.125194,
      "city": "Marvel",
      "state": "CO",
      "county": "La Plata"
  },
  {
      "zipCode": 81330,
      "latitude": 37.319207,
      "longitude": -108.509012,
      "city": "Mesa Verde National Park",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81331,
      "latitude": 37.551564,
      "longitude": -108.829926,
      "city": "Pleasant View",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81332,
      "latitude": 37.690498,
      "longitude": -108.03241,
      "city": "Rico",
      "state": "CO",
      "county": "Dolores"
  },
  {
      "zipCode": 81334,
      "latitude": 37.319207,
      "longitude": -108.509012,
      "city": "Towaoc",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81335,
      "latitude": 37.508114,
      "longitude": -108.744007,
      "city": "Yellow Jacket",
      "state": "CO",
      "county": "Montezuma"
  },
  {
      "zipCode": 81401,
      "latitude": 38.443069,
      "longitude": -108.050689,
      "city": "Montrose",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81402,
      "latitude": 38.485093,
      "longitude": -107.885975,
      "city": "Montrose",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81410,
      "latitude": 38.805013,
      "longitude": -107.983125,
      "city": "Austin",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81411,
      "latitude": 38.34867,
      "longitude": -108.937369,
      "city": "Bedrock",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81413,
      "latitude": 38.881646,
      "longitude": -107.795704,
      "city": "Cedaredge",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81414,
      "latitude": 38.788697,
      "longitude": -107.986174,
      "city": "Cory",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81415,
      "latitude": 38.767652,
      "longitude": -107.63089,
      "city": "Crawford",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81416,
      "latitude": 38.785768,
      "longitude": -107.97078,
      "city": "Delta",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81418,
      "latitude": 38.87534,
      "longitude": -107.961178,
      "city": "Eckert",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81419,
      "latitude": 38.859973,
      "longitude": -107.752349,
      "city": "Hotchkiss",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81420,
      "latitude": 38.781745,
      "longitude": -107.780778,
      "city": "Lazear",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81421,
      "latitude": 38.942927,
      "longitude": -107.939831,
      "city": "Maher",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81422,
      "latitude": 38.269387,
      "longitude": -108.574539,
      "city": "Naturita",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81423,
      "latitude": 38.028899,
      "longitude": -108.316049,
      "city": "Norwood",
      "state": "CO",
      "county": "San Miguel"
  },
  {
      "zipCode": 81424,
      "latitude": 38.310689,
      "longitude": -108.57669,
      "city": "Nucla",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81425,
      "latitude": 38.497566,
      "longitude": -108.078623,
      "city": "Olathe",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81426,
      "latitude": 37.843026,
      "longitude": -107.86454,
      "city": "Ophir",
      "state": "CO",
      "county": "San Miguel"
  },
  {
      "zipCode": 81427,
      "latitude": 38.087654,
      "longitude": -107.728712,
      "city": "Ouray",
      "state": "CO",
      "county": "Ouray"
  },
  {
      "zipCode": 81428,
      "latitude": 38.877198,
      "longitude": -107.609167,
      "city": "Paonia",
      "state": "CO",
      "county": "Delta"
  },
  {
      "zipCode": 81429,
      "latitude": 38.371101,
      "longitude": -108.969823,
      "city": "Paradox",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81430,
      "latitude": 38.059321,
      "longitude": -107.990438,
      "city": "Placerville",
      "state": "CO",
      "county": "San Miguel"
  },
  {
      "zipCode": 81431,
      "latitude": 38.192224,
      "longitude": -108.415514,
      "city": "Redvale",
      "state": "CO",
      "county": "Montrose"
  },
  {
      "zipCode": 81432,
      "latitude": 38.177464,
      "longitude": -107.72736,
      "city": "Ridgway",
      "state": "CO",
      "county": "Ouray"
  },
  {
      "zipCode": 81433,
      "latitude": 37.848391,
      "longitude": -107.680381,
      "city": "Silverton",
      "state": "CO",
      "county": "San Juan"
  },
  {
      "zipCode": 81434,
      "latitude": 38.988409,
      "longitude": -107.231438,
      "city": "Somerset",
      "state": "CO",
      "county": "Gunnison"
  },
  {
      "zipCode": 81435,
      "latitude": 37.983589,
      "longitude": -107.956606,
      "city": "Telluride",
      "state": "CO",
      "county": "San Miguel"
  },
  {
      "zipCode": 81501,
      "latitude": 39.069019,
      "longitude": -108.583126,
      "city": "Grand Junction",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81502,
      "latitude": 39.017876,
      "longitude": -108.481354,
      "city": "Grand Junction",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81503,
      "latitude": 39.013584,
      "longitude": -108.591229,
      "city": "Grand Junction",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81504,
      "latitude": 39.123554,
      "longitude": -108.589414,
      "city": "Grand Junction",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81505,
      "latitude": 39.14657,
      "longitude": -108.626481,
      "city": "Grand Junction",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81506,
      "latitude": 39.089167,
      "longitude": -108.566523,
      "city": "Grand Junction",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81520,
      "latitude": 39.08246,
      "longitude": -108.404055,
      "city": "Clifton",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81521,
      "latitude": 39.149614,
      "longitude": -108.685164,
      "city": "Fruita",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81522,
      "latitude": 38.678448,
      "longitude": -108.971863,
      "city": "Gateway",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81523,
      "latitude": 38.989416,
      "longitude": -108.78097,
      "city": "Glade Park",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81524,
      "latitude": 39.256425,
      "longitude": -108.78446,
      "city": "Loma",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81525,
      "latitude": 39.246379,
      "longitude": -108.933957,
      "city": "Mack",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81526,
      "latitude": 39.095369,
      "longitude": -108.299254,
      "city": "Palisade",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81527,
      "latitude": 38.814573,
      "longitude": -108.604695,
      "city": "Whitewater",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81601,
      "latitude": 39.500281,
      "longitude": -107.585235,
      "city": "Glenwood Springs",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81602,
      "latitude": 39.511742,
      "longitude": -107.325336,
      "city": "Glenwood Springs",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81610,
      "latitude": 40.36715,
      "longitude": -108.277407,
      "city": "Dinosaur",
      "state": "CO",
      "county": "Moffat"
  },
  {
      "zipCode": 81611,
      "latitude": 39.169324,
      "longitude": -106.776744,
      "city": "Aspen",
      "state": "CO",
      "county": "Pitkin"
  },
  {
      "zipCode": 81612,
      "latitude": 39.223416,
      "longitude": -106.882774,
      "city": "Aspen",
      "state": "CO",
      "county": "Pitkin"
  },
  {
      "zipCode": 81615,
      "latitude": 39.206518,
      "longitude": -106.868877,
      "city": "Snowmass Village",
      "state": "CO",
      "county": "Pitkin"
  },
  {
      "zipCode": 81620,
      "latitude": 39.631349,
      "longitude": -106.510843,
      "city": "Avon",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81621,
      "latitude": 39.495223,
      "longitude": -106.804842,
      "city": "Basalt",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81623,
      "latitude": 39.476857,
      "longitude": -107.38813,
      "city": "Carbondale",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81624,
      "latitude": 39.205633,
      "longitude": -107.929135,
      "city": "Collbran",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81625,
      "latitude": 40.612421,
      "longitude": -108.009784,
      "city": "Craig",
      "state": "CO",
      "county": "Moffat"
  },
  {
      "zipCode": 81626,
      "latitude": 40.667669,
      "longitude": -107.783276,
      "city": "Craig",
      "state": "CO",
      "county": "Moffat"
  },
  {
      "zipCode": 81630,
      "latitude": 39.292409,
      "longitude": -108.210688,
      "city": "De Beque",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81631,
      "latitude": 39.607401,
      "longitude": -106.805111,
      "city": "Eagle",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81632,
      "latitude": 39.638166,
      "longitude": -106.620644,
      "city": "Edwards",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81633,
      "latitude": 40.377031,
      "longitude": -108.398991,
      "city": "Dinosaur",
      "state": "CO",
      "county": "Moffat"
  },
  {
      "zipCode": 81635,
      "latitude": 39.458498,
      "longitude": -107.878874,
      "city": "Parachute",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81636,
      "latitude": 39.430535,
      "longitude": -108.023924,
      "city": "Battlement Mesa",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81637,
      "latitude": 39.615505,
      "longitude": -106.895722,
      "city": "Gypsum",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81638,
      "latitude": 40.297591,
      "longitude": -107.665731,
      "city": "Hamilton",
      "state": "CO",
      "county": "Moffat"
  },
  {
      "zipCode": 81639,
      "latitude": 40.438195,
      "longitude": -107.277181,
      "city": "Hayden",
      "state": "CO",
      "county": "Routt"
  },
  {
      "zipCode": 81640,
      "latitude": 40.673756,
      "longitude": -108.369943,
      "city": "Maybell",
      "state": "CO",
      "county": "Moffat"
  },
  {
      "zipCode": 81641,
      "latitude": 39.967864,
      "longitude": -108.043592,
      "city": "Meeker",
      "state": "CO",
      "county": "Rio Blanco"
  },
  {
      "zipCode": 81642,
      "latitude": 39.294737,
      "longitude": -106.715287,
      "city": "Meredith",
      "state": "CO",
      "county": "Pitkin"
  },
  {
      "zipCode": 81643,
      "latitude": 39.14932,
      "longitude": -108.169723,
      "city": "Mesa",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81645,
      "latitude": 39.580461,
      "longitude": -106.417574,
      "city": "Minturn",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81646,
      "latitude": 39.165152,
      "longitude": -108.076508,
      "city": "Molina",
      "state": "CO",
      "county": "Mesa"
  },
  {
      "zipCode": 81647,
      "latitude": 39.597433,
      "longitude": -107.566159,
      "city": "New Castle",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81648,
      "latitude": 39.936331,
      "longitude": -108.650793,
      "city": "Rangely",
      "state": "CO",
      "county": "Rio Blanco"
  },
  {
      "zipCode": 81649,
      "latitude": 39.458351,
      "longitude": -106.385056,
      "city": "Red Cliff",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81650,
      "latitude": 39.582273,
      "longitude": -107.794723,
      "city": "Rifle",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81652,
      "latitude": 39.502817,
      "longitude": -107.665664,
      "city": "Silt",
      "state": "CO",
      "county": "Garfield"
  },
  {
      "zipCode": 81653,
      "latitude": 40.997896,
      "longitude": -107.33877,
      "city": "Slater",
      "state": "CO",
      "county": "Moffat"
  },
  {
      "zipCode": 81654,
      "latitude": 39.251721,
      "longitude": -106.922425,
      "city": "Snowmass",
      "state": "CO",
      "county": "Pitkin"
  },
  {
      "zipCode": 81655,
      "latitude": 39.79073,
      "longitude": -106.696372,
      "city": "Wolcott",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81656,
      "latitude": 39.283116,
      "longitude": -106.898514,
      "city": "Woody Creek",
      "state": "CO",
      "county": "Pitkin"
  },
  {
      "zipCode": 81657,
      "latitude": 39.5824,
      "longitude": -106.44873,
      "city": "Vail",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 81658,
      "latitude": 39.624171,
      "longitude": -106.497314,
      "city": "Vail",
      "state": "CO",
      "county": "Eagle"
  },
  {
      "zipCode": 82001,
      "latitude": 41.25173,
      "longitude": -104.56264,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82002,
      "latitude": 41.327351,
      "longitude": -104.666365,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82003,
      "latitude": 41.219107,
      "longitude": -104.661244,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82005,
      "latitude": 41.139057,
      "longitude": -104.862947,
      "city": "Fe Warren Afb",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82006,
      "latitude": 41.327351,
      "longitude": -104.666365,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82007,
      "latitude": 41.209351,
      "longitude": -104.666476,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82008,
      "latitude": 41.327351,
      "longitude": -104.666365,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82009,
      "latitude": 41.377698,
      "longitude": -104.660353,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82010,
      "latitude": 41.327351,
      "longitude": -104.666365,
      "city": "Cheyenne",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82050,
      "latitude": 41.335468,
      "longitude": -104.249495,
      "city": "Albin",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82051,
      "latitude": 41.464065,
      "longitude": -105.676771,
      "city": "Bosler",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82052,
      "latitude": 41.110146,
      "longitude": -105.325712,
      "city": "Buford",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82053,
      "latitude": 41.235922,
      "longitude": -104.378477,
      "city": "Burns",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82054,
      "latitude": 41.097376,
      "longitude": -104.356347,
      "city": "Carpenter",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82055,
      "latitude": 41.311739,
      "longitude": -105.847938,
      "city": "Centennial",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82058,
      "latitude": 42.203504,
      "longitude": -105.677952,
      "city": "Garrett",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82059,
      "latitude": 41.047337,
      "longitude": -105.151706,
      "city": "Granite Canon",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82060,
      "latitude": 41.213557,
      "longitude": -104.493307,
      "city": "Hillsdale",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82061,
      "latitude": 41.435318,
      "longitude": -105.141679,
      "city": "Horse Creek",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82063,
      "latitude": 41.053927,
      "longitude": -106.076251,
      "city": "Jelm",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82070,
      "latitude": 41.43902,
      "longitude": -105.801022,
      "city": "Laramie",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82071,
      "latitude": 41.715061,
      "longitude": -105.797388,
      "city": "Laramie",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82072,
      "latitude": 41.582018,
      "longitude": -105.708717,
      "city": "Laramie",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82073,
      "latitude": 41.307065,
      "longitude": -105.624667,
      "city": "Laramie",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82081,
      "latitude": 41.513944,
      "longitude": -104.441122,
      "city": "Meriden",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82082,
      "latitude": 41.277286,
      "longitude": -104.205462,
      "city": "Pine Bluffs",
      "state": "WY",
      "county": "Laramie"
  },
  {
      "zipCode": 82083,
      "latitude": 42.023074,
      "longitude": -105.51461,
      "city": "Rock River",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82084,
      "latitude": 41.271207,
      "longitude": -105.486538,
      "city": "Tie Siding",
      "state": "WY",
      "county": "Albany"
  },
  {
      "zipCode": 82190,
      "latitude": 44.795733,
      "longitude": -110.613731,
      "city": "Yellowstone National Park",
      "state": "WY",
      "county": "Park"
  },
  {
      "zipCode": 82201,
      "latitude": 42.013984,
      "longitude": -104.967405,
      "city": "Wheatland",
      "state": "WY",
      "county": "Platte"
  },
  {
      "zipCode": 82210,
      "latitude": 41.74064,
      "longitude": -104.871308,
      "city": "Chugwater",
      "state": "WY",
      "county": "Platte"
  },
  {
      "zipCode": 82212,
      "latitude": 42.213334,
      "longitude": -104.518529,
      "city": "Fort Laramie",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82213,
      "latitude": 42.481824,
      "longitude": -104.980954,
      "city": "Glendo",
      "state": "WY",
      "county": "Platte"
  },
  {
      "zipCode": 82214,
      "latitude": 42.256819,
      "longitude": -104.786912,
      "city": "Guernsey",
      "state": "WY",
      "county": "Platte"
  },
  {
      "zipCode": 82215,
      "latitude": 42.321218,
      "longitude": -104.722426,
      "city": "Hartville",
      "state": "WY",
      "county": "Platte"
  },
  {
      "zipCode": 82217,
      "latitude": 42.088115,
      "longitude": -104.354049,
      "city": "Hawk Springs",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82218,
      "latitude": 42.088115,
      "longitude": -104.354049,
      "city": "Huntley",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82219,
      "latitude": 42.088115,
      "longitude": -104.354049,
      "city": "Jay Em",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82221,
      "latitude": 42.088115,
      "longitude": -104.354049,
      "city": "Lagrange",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82222,
      "latitude": 43.152195,
      "longitude": -104.59198,
      "city": "Lance Creek",
      "state": "WY",
      "county": "Niobrara"
  },
  {
      "zipCode": 82223,
      "latitude": 42.137939,
      "longitude": -104.351273,
      "city": "Lingle",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82224,
      "latitude": 42.987443,
      "longitude": -104.919086,
      "city": "Lost Springs",
      "state": "WY",
      "county": "Converse"
  },
  {
      "zipCode": 82225,
      "latitude": 42.964197,
      "longitude": -104.385106,
      "city": "Lusk",
      "state": "WY",
      "county": "Niobrara"
  },
  {
      "zipCode": 82227,
      "latitude": 42.73001,
      "longitude": -104.702397,
      "city": "Manville",
      "state": "WY",
      "county": "Niobrara"
  },
  {
      "zipCode": 82229,
      "latitude": 42.787675,
      "longitude": -105.063337,
      "city": "Shawnee",
      "state": "WY",
      "county": "Converse"
  },
  {
      "zipCode": 82240,
      "latitude": 42.072314,
      "longitude": -104.187474,
      "city": "Torrington",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82242,
      "latitude": 42.703263,
      "longitude": -104.140785,
      "city": "Van Tassell",
      "state": "WY",
      "county": "Niobrara"
  },
  {
      "zipCode": 82243,
      "latitude": 42.088115,
      "longitude": -104.354049,
      "city": "Veteran",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82244,
      "latitude": 42.088115,
      "longitude": -104.354049,
      "city": "Yoder",
      "state": "WY",
      "county": "Goshen"
  },
  {
      "zipCode": 82301,
      "latitude": 41.538075,
      "longitude": -106.921959,
      "city": "Rawlins",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82310,
      "latitude": 42.472474,
      "longitude": -107.760978,
      "city": "Jeffrey City",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82321,
      "latitude": 41.025579,
      "longitude": -107.569976,
      "city": "Baggs",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82322,
      "latitude": 42.239404,
      "longitude": -107.558882,
      "city": "Bairoil",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82323,
      "latitude": 41.031513,
      "longitude": -107.642891,
      "city": "Dixon",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82324,
      "latitude": 41.687359,
      "longitude": -106.414629,
      "city": "Elk Mountain",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82325,
      "latitude": 41.224462,
      "longitude": -106.790506,
      "city": "Encampment",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82327,
      "latitude": 41.869647,
      "longitude": -106.552216,
      "city": "Hanna",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82329,
      "latitude": 41.834011,
      "longitude": -106.180829,
      "city": "Medicine Bow",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82331,
      "latitude": 41.497994,
      "longitude": -106.753979,
      "city": "Saratoga",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82332,
      "latitude": 41.027293,
      "longitude": -107.634563,
      "city": "Savery",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82334,
      "latitude": 41.780239,
      "longitude": -107.117226,
      "city": "Sinclair",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82335,
      "latitude": 41.71694,
      "longitude": -106.999195,
      "city": "Walcott",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82336,
      "latitude": 41.853191,
      "longitude": -107.851852,
      "city": "Wamsutter",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82401,
      "latitude": 44.008105,
      "longitude": -108.141457,
      "city": "Worland",
      "state": "WY",
      "county": "Washakie"
  },
  {
      "zipCode": 82410,
      "latitude": 44.346935,
      "longitude": -108.165824,
      "city": "Basin",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82411,
      "latitude": 44.436282,
      "longitude": -108.470326,
      "city": "Burlington",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82412,
      "latitude": 44.784396,
      "longitude": -108.543965,
      "city": "Byron",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82414,
      "latitude": 44.541637,
      "longitude": -109.436585,
      "city": "Cody",
      "state": "WY",
      "county": "Park"
  },
  {
      "zipCode": 82420,
      "latitude": 44.884137,
      "longitude": -108.463779,
      "city": "Cowley",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82421,
      "latitude": 44.702955,
      "longitude": -108.335432,
      "city": "Deaver",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82422,
      "latitude": 44.583633,
      "longitude": -107.883519,
      "city": "Emblem",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82423,
      "latitude": 44.971811,
      "longitude": -108.622097,
      "city": "Frannie",
      "state": "WY",
      "county": "Park"
  },
  {
      "zipCode": 82426,
      "latitude": 44.477394,
      "longitude": -108.130553,
      "city": "Greybull",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82427,
      "latitude": 43.76976,
      "longitude": -108.454106,
      "city": "Hamilton Dome",
      "state": "WY",
      "county": "Hot Springs"
  },
  {
      "zipCode": 82428,
      "latitude": 44.217942,
      "longitude": -107.681792,
      "city": "Hyattville",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82430,
      "latitude": 43.804189,
      "longitude": -108.180469,
      "city": "Kirby",
      "state": "WY",
      "county": "Hot Springs"
  },
  {
      "zipCode": 82431,
      "latitude": 44.584242,
      "longitude": -108.264522,
      "city": "Lovell",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82432,
      "latitude": 44.303336,
      "longitude": -107.85976,
      "city": "Manderson",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82433,
      "latitude": 44.165967,
      "longitude": -108.916862,
      "city": "Meeteetse",
      "state": "WY",
      "county": "Park"
  },
  {
      "zipCode": 82434,
      "latitude": 44.532074,
      "longitude": -108.423412,
      "city": "Otto",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82435,
      "latitude": 44.615962,
      "longitude": -108.948914,
      "city": "Powell",
      "state": "WY",
      "county": "Park"
  },
  {
      "zipCode": 82440,
      "latitude": 44.69496,
      "longitude": -108.883283,
      "city": "Ralston",
      "state": "WY",
      "county": "Park"
  },
  {
      "zipCode": 82441,
      "latitude": 44.601118,
      "longitude": -107.78886,
      "city": "Shell",
      "state": "WY",
      "county": "Big Horn"
  },
  {
      "zipCode": 82442,
      "latitude": 43.908687,
      "longitude": -107.530662,
      "city": "Ten Sleep",
      "state": "WY",
      "county": "Washakie"
  },
  {
      "zipCode": 82443,
      "latitude": 43.775844,
      "longitude": -108.383949,
      "city": "Thermopolis",
      "state": "WY",
      "county": "Hot Springs"
  },
  {
      "zipCode": 82450,
      "latitude": 44.463071,
      "longitude": -109.415501,
      "city": "Wapiti",
      "state": "WY",
      "county": "Park"
  },
  {
      "zipCode": 82501,
      "latitude": 43.180647,
      "longitude": -108.925734,
      "city": "Riverton",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82510,
      "latitude": 43.035809,
      "longitude": -108.584486,
      "city": "Arapahoe",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82512,
      "latitude": 43.362997,
      "longitude": -109.221657,
      "city": "Crowheart",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82513,
      "latitude": 43.549861,
      "longitude": -109.704749,
      "city": "Dubois",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82514,
      "latitude": 43.059931,
      "longitude": -108.879125,
      "city": "Fort Washakie",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82515,
      "latitude": 42.900791,
      "longitude": -108.582745,
      "city": "Hudson",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82516,
      "latitude": 43.267755,
      "longitude": -108.933381,
      "city": "Kinnear",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82520,
      "latitude": 42.920832,
      "longitude": -108.591264,
      "city": "Lander",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82523,
      "latitude": 43.362321,
      "longitude": -108.699805,
      "city": "Pavillion",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82524,
      "latitude": 42.998588,
      "longitude": -108.57191,
      "city": "Saint Stephens",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82601,
      "latitude": 42.859875,
      "longitude": -106.312561,
      "city": "Casper",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82602,
      "latitude": 42.889576,
      "longitude": -106.35696,
      "city": "Casper",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82604,
      "latitude": 42.973745,
      "longitude": -106.893746,
      "city": "Casper",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82605,
      "latitude": 42.966192,
      "longitude": -106.807047,
      "city": "Casper",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82609,
      "latitude": 42.811163,
      "longitude": -106.267391,
      "city": "Casper",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82615,
      "latitude": 41.71694,
      "longitude": -106.999195,
      "city": "Shirley Basin",
      "state": "WY",
      "county": "Carbon"
  },
  {
      "zipCode": 82620,
      "latitude": 42.530223,
      "longitude": -106.759971,
      "city": "Alcova",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82630,
      "latitude": 42.966192,
      "longitude": -106.807047,
      "city": "Arminto",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82631,
      "latitude": 42.894249,
      "longitude": -105.485243,
      "city": "Bill",
      "state": "WY",
      "county": "Converse"
  },
  {
      "zipCode": 82633,
      "latitude": 42.894084,
      "longitude": -105.389691,
      "city": "Douglas",
      "state": "WY",
      "county": "Converse"
  },
  {
      "zipCode": 82635,
      "latitude": 43.407351,
      "longitude": -106.263808,
      "city": "Edgerton",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82636,
      "latitude": 42.899153,
      "longitude": -106.175375,
      "city": "Evansville",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82637,
      "latitude": 42.780251,
      "longitude": -105.871922,
      "city": "Glenrock",
      "state": "WY",
      "county": "Converse"
  },
  {
      "zipCode": 82638,
      "latitude": 42.966192,
      "longitude": -106.807047,
      "city": "Hiland",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82639,
      "latitude": 43.720933,
      "longitude": -106.541709,
      "city": "Kaycee",
      "state": "WY",
      "county": "Johnson"
  },
  {
      "zipCode": 82640,
      "latitude": 43.565149,
      "longitude": -106.172827,
      "city": "Linch",
      "state": "WY",
      "county": "Johnson"
  },
  {
      "zipCode": 82642,
      "latitude": 43.282255,
      "longitude": -107.815622,
      "city": "Lysite",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82643,
      "latitude": 43.405584,
      "longitude": -106.278264,
      "city": "Midwest",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82644,
      "latitude": 42.947954,
      "longitude": -106.444584,
      "city": "Mills",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82646,
      "latitude": 42.966192,
      "longitude": -106.807047,
      "city": "Natrona",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82648,
      "latitude": 42.966192,
      "longitude": -106.807047,
      "city": "Powder River",
      "state": "WY",
      "county": "Natrona"
  },
  {
      "zipCode": 82649,
      "latitude": 43.191539,
      "longitude": -108.009659,
      "city": "Shoshoni",
      "state": "WY",
      "county": "Fremont"
  },
  {
      "zipCode": 82701,
      "latitude": 43.8397,
      "longitude": -104.567814,
      "city": "Newcastle",
      "state": "WY",
      "county": "Weston"
  },
  {
      "zipCode": 82710,
      "latitude": 44.738184,
      "longitude": -104.288519,
      "city": "Aladdin",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82711,
      "latitude": 44.68734,
      "longitude": -104.441353,
      "city": "Alva",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82712,
      "latitude": 44.543625,
      "longitude": -104.074532,
      "city": "Beulah",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82713,
      "latitude": 44.525891,
      "longitude": -104.767846,
      "city": "Carlile",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82714,
      "latitude": 44.524758,
      "longitude": -104.686692,
      "city": "Devils Tower",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82715,
      "latitude": 43.840033,
      "longitude": -104.568128,
      "city": "Four Corners",
      "state": "WY",
      "county": "Weston"
  },
  {
      "zipCode": 82716,
      "latitude": 44.377283,
      "longitude": -105.550237,
      "city": "Gillette",
      "state": "WY",
      "county": "Campbell"
  },
  {
      "zipCode": 82717,
      "latitude": 44.304713,
      "longitude": -105.495943,
      "city": "Gillette",
      "state": "WY",
      "county": "Campbell"
  },
  {
      "zipCode": 82718,
      "latitude": 43.928249,
      "longitude": -105.549244,
      "city": "Gillette",
      "state": "WY",
      "county": "Campbell"
  },
  {
      "zipCode": 82720,
      "latitude": 44.675348,
      "longitude": -104.661438,
      "city": "Hulett",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82721,
      "latitude": 44.415431,
      "longitude": -104.838916,
      "city": "Moorcroft",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82723,
      "latitude": 44.04631,
      "longitude": -104.360224,
      "city": "Osage",
      "state": "WY",
      "county": "Weston"
  },
  {
      "zipCode": 82725,
      "latitude": 44.851059,
      "longitude": -105.669641,
      "city": "Recluse",
      "state": "WY",
      "county": "Campbell"
  },
  {
      "zipCode": 82727,
      "latitude": 44.412924,
      "longitude": -105.529275,
      "city": "Rozet",
      "state": "WY",
      "county": "Campbell"
  },
  {
      "zipCode": 82729,
      "latitude": 44.352274,
      "longitude": -104.420625,
      "city": "Sundance",
      "state": "WY",
      "county": "Crook"
  },
  {
      "zipCode": 82730,
      "latitude": 43.937319,
      "longitude": -104.620856,
      "city": "Upton",
      "state": "WY",
      "county": "Weston"
  },
  {
      "zipCode": 82731,
      "latitude": 44.770503,
      "longitude": -105.358147,
      "city": "Weston",
      "state": "WY",
      "county": "Campbell"
  },
  {
      "zipCode": 82732,
      "latitude": 43.761422,
      "longitude": -105.520135,
      "city": "Wright",
      "state": "WY",
      "county": "Campbell"
  },
  {
      "zipCode": 82801,
      "latitude": 44.780378,
      "longitude": -106.837563,
      "city": "Sheridan",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82831,
      "latitude": 44.731059,
      "longitude": -106.392413,
      "city": "Arvada",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82832,
      "latitude": 44.768801,
      "longitude": -106.555587,
      "city": "Banner",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82833,
      "latitude": 44.653104,
      "longitude": -107.024713,
      "city": "Big Horn",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82834,
      "latitude": 44.122611,
      "longitude": -106.561068,
      "city": "Buffalo",
      "state": "WY",
      "county": "Johnson"
  },
  {
      "zipCode": 82835,
      "latitude": 44.770346,
      "longitude": -106.425278,
      "city": "Clearmont",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82836,
      "latitude": 44.870973,
      "longitude": -107.330606,
      "city": "Dayton",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82837,
      "latitude": 44.783153,
      "longitude": -106.280598,
      "city": "Leiter",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82838,
      "latitude": 44.808925,
      "longitude": -107.135181,
      "city": "Parkman",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82839,
      "latitude": 44.917078,
      "longitude": -107.173995,
      "city": "Ranchester",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82840,
      "latitude": 44.497609,
      "longitude": -106.870963,
      "city": "Saddlestring",
      "state": "WY",
      "county": "Johnson"
  },
  {
      "zipCode": 82842,
      "latitude": 44.580015,
      "longitude": -106.897795,
      "city": "Story",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82844,
      "latitude": 44.850012,
      "longitude": -107.185309,
      "city": "Wolf",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82845,
      "latitude": 44.753413,
      "longitude": -106.694907,
      "city": "Wyarno",
      "state": "WY",
      "county": "Sheridan"
  },
  {
      "zipCode": 82901,
      "latitude": 41.634515,
      "longitude": -109.149045,
      "city": "Rock Springs",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82902,
      "latitude": 41.631408,
      "longitude": -108.963931,
      "city": "Rock Springs",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82922,
      "latitude": 43.221022,
      "longitude": -110.152755,
      "city": "Bondurant",
      "state": "WY",
      "county": "Sublette"
  },
  {
      "zipCode": 82923,
      "latitude": 42.723092,
      "longitude": -109.582132,
      "city": "Boulder",
      "state": "WY",
      "county": "Sublette"
  },
  {
      "zipCode": 82925,
      "latitude": 43.125465,
      "longitude": -109.981117,
      "city": "Cora",
      "state": "WY",
      "county": "Sublette"
  },
  {
      "zipCode": 82929,
      "latitude": 41.758101,
      "longitude": -109.745873,
      "city": "Little America",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82930,
      "latitude": 41.233758,
      "longitude": -110.664401,
      "city": "Evanston",
      "state": "WY",
      "county": "Uinta"
  },
  {
      "zipCode": 82931,
      "latitude": 41.261943,
      "longitude": -110.919995,
      "city": "Evanston",
      "state": "WY",
      "county": "Uinta"
  },
  {
      "zipCode": 82932,
      "latitude": 42.083362,
      "longitude": -109.418358,
      "city": "Farson",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82933,
      "latitude": 41.295023,
      "longitude": -110.399094,
      "city": "Fort Bridger",
      "state": "WY",
      "county": "Uinta"
  },
  {
      "zipCode": 82934,
      "latitude": 41.537225,
      "longitude": -109.763045,
      "city": "Granger",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82935,
      "latitude": 41.51437,
      "longitude": -109.427616,
      "city": "Green River",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82936,
      "latitude": 41.024224,
      "longitude": -110.173336,
      "city": "Lonetree",
      "state": "WY",
      "county": "Uinta"
  },
  {
      "zipCode": 82937,
      "latitude": 41.331817,
      "longitude": -110.303367,
      "city": "Lyman",
      "state": "WY",
      "county": "Uinta"
  },
  {
      "zipCode": 82938,
      "latitude": 41.251079,
      "longitude": -109.275019,
      "city": "Mc Kinnon",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82939,
      "latitude": 41.233491,
      "longitude": -110.337157,
      "city": "Mountain View",
      "state": "WY",
      "county": "Uinta"
  },
  {
      "zipCode": 82941,
      "latitude": 42.849757,
      "longitude": -109.938968,
      "city": "Pinedale",
      "state": "WY",
      "county": "Sublette"
  },
  {
      "zipCode": 82942,
      "latitude": 41.653038,
      "longitude": -108.525247,
      "city": "Point Of Rocks",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82943,
      "latitude": 41.669845,
      "longitude": -109.191857,
      "city": "Reliance",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 82944,
      "latitude": 41.099266,
      "longitude": -110.500655,
      "city": "Robertson",
      "state": "WY",
      "county": "Uinta"
  },
  {
      "zipCode": 82945,
      "latitude": 41.7643,
      "longitude": -108.968062,
      "city": "Superior",
      "state": "WY",
      "county": "Sweetwater"
  },
  {
      "zipCode": 83001,
      "latitude": 43.452793,
      "longitude": -110.739266,
      "city": "Jackson",
      "state": "WY",
      "county": "Teton"
  },
  {
      "zipCode": 83002,
      "latitude": 43.50537,
      "longitude": -110.786527,
      "city": "Jackson",
      "state": "WY",
      "county": "Teton"
  },
  {
      "zipCode": 83011,
      "latitude": 43.950896,
      "longitude": -110.553181,
      "city": "Kelly",
      "state": "WY",
      "county": "Teton"
  },
  {
      "zipCode": 83012,
      "latitude": 43.550144,
      "longitude": -110.747494,
      "city": "Moose",
      "state": "WY",
      "county": "Teton"
  },
  {
      "zipCode": 83013,
      "latitude": 43.950896,
      "longitude": -110.553181,
      "city": "Moran",
      "state": "WY",
      "county": "Teton"
  },
  {
      "zipCode": 83014,
      "latitude": 43.482361,
      "longitude": -110.839782,
      "city": "Wilson",
      "state": "WY",
      "county": "Teton"
  },
  {
      "zipCode": 83025,
      "latitude": 43.443851,
      "longitude": -110.694002,
      "city": "Teton Village",
      "state": "WY",
      "county": "Teton"
  },
  {
      "zipCode": 83101,
      "latitude": 42.368433,
      "longitude": -110.514132,
      "city": "Kemmerer",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83110,
      "latitude": 42.711296,
      "longitude": -110.619734,
      "city": "Afton",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83111,
      "latitude": 42.478175,
      "longitude": -110.981561,
      "city": "Auburn",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83112,
      "latitude": 42.8701,
      "longitude": -110.940062,
      "city": "Bedford",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83113,
      "latitude": 42.648328,
      "longitude": -110.124616,
      "city": "Big Piney",
      "state": "WY",
      "county": "Sublette"
  },
  {
      "zipCode": 83114,
      "latitude": 42.247455,
      "longitude": -110.941119,
      "city": "Cokeville",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83115,
      "latitude": 42.785695,
      "longitude": -110.186599,
      "city": "Daniel",
      "state": "WY",
      "county": "Sublette"
  },
  {
      "zipCode": 83116,
      "latitude": 41.783935,
      "longitude": -110.54,
      "city": "Diamondville",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83118,
      "latitude": 43.044268,
      "longitude": -111.008517,
      "city": "Etna",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83119,
      "latitude": 42.674643,
      "longitude": -111.014786,
      "city": "Fairview",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83120,
      "latitude": 43.026767,
      "longitude": -111.003953,
      "city": "Freedom",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83121,
      "latitude": 41.796019,
      "longitude": -110.537302,
      "city": "Frontier",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83122,
      "latitude": 42.789343,
      "longitude": -110.942061,
      "city": "Grover",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83123,
      "latitude": 42.206598,
      "longitude": -110.286804,
      "city": "La Barge",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83124,
      "latitude": 41.779459,
      "longitude": -110.275995,
      "city": "Opal",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83126,
      "latitude": 42.587769,
      "longitude": -110.916034,
      "city": "Smoot",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83127,
      "latitude": 42.956988,
      "longitude": -110.974174,
      "city": "Thayne",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83128,
      "latitude": 43.030327,
      "longitude": -110.884515,
      "city": "Alpine",
      "state": "WY",
      "county": "Lincoln"
  },
  {
      "zipCode": 83201,
      "latitude": 42.770177,
      "longitude": -112.259807,
      "city": "Pocatello",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83202,
      "latitude": 42.935728,
      "longitude": -112.46793,
      "city": "Pocatello",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83203,
      "latitude": 43.033131,
      "longitude": -112.428535,
      "city": "Fort Hall",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83204,
      "latitude": 42.72575,
      "longitude": -112.335661,
      "city": "Pocatello",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83205,
      "latitude": 42.80616,
      "longitude": -112.410283,
      "city": "Pocatello",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83206,
      "latitude": 42.639474,
      "longitude": -112.313836,
      "city": "Pocatello",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83209,
      "latitude": 42.639474,
      "longitude": -112.313836,
      "city": "Pocatello",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83210,
      "latitude": 43.004883,
      "longitude": -112.839971,
      "city": "Aberdeen",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83211,
      "latitude": 42.635233,
      "longitude": -112.945777,
      "city": "American Falls",
      "state": "ID",
      "county": "Power"
  },
  {
      "zipCode": 83212,
      "latitude": 42.512788,
      "longitude": -112.542643,
      "city": "Arbon",
      "state": "ID",
      "county": "Power"
  },
  {
      "zipCode": 83213,
      "latitude": 43.634236,
      "longitude": -113.213308,
      "city": "Arco",
      "state": "ID",
      "county": "Butte"
  },
  {
      "zipCode": 83214,
      "latitude": 42.566743,
      "longitude": -112.259209,
      "city": "Arimo",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83215,
      "latitude": 43.244795,
      "longitude": -112.297912,
      "city": "Atomic City",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83217,
      "latitude": 42.813756,
      "longitude": -111.52793,
      "city": "Bancroft",
      "state": "ID",
      "county": "Caribou"
  },
  {
      "zipCode": 83218,
      "latitude": 43.304278,
      "longitude": -112.181741,
      "city": "Basalt",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83220,
      "latitude": 42.295466,
      "longitude": -111.373414,
      "city": "Bern",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83221,
      "latitude": 43.251328,
      "longitude": -112.464518,
      "city": "Blackfoot",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83223,
      "latitude": 42.190025,
      "longitude": -111.470801,
      "city": "Bloomington",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83226,
      "latitude": 44.50599,
      "longitude": -114.222666,
      "city": "Challis",
      "state": "ID",
      "county": "Custer"
  },
  {
      "zipCode": 83227,
      "latitude": 44.22397,
      "longitude": -114.312713,
      "city": "Clayton",
      "state": "ID",
      "county": "Custer"
  },
  {
      "zipCode": 83228,
      "latitude": 42.207519,
      "longitude": -111.997341,
      "city": "Clifton",
      "state": "ID",
      "county": "Franklin"
  },
  {
      "zipCode": 83229,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "Cobalt",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83230,
      "latitude": 42.719102,
      "longitude": -111.596651,
      "city": "Conda",
      "state": "ID",
      "county": "Caribou"
  },
  {
      "zipCode": 83232,
      "latitude": 42.145827,
      "longitude": -111.97748,
      "city": "Dayton",
      "state": "ID",
      "county": "Franklin"
  },
  {
      "zipCode": 83233,
      "latitude": 42.177359,
      "longitude": -111.217381,
      "city": "Dingle",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83234,
      "latitude": 42.473005,
      "longitude": -112.120195,
      "city": "Downey",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83235,
      "latitude": 44.22397,
      "longitude": -114.312713,
      "city": "Ellis",
      "state": "ID",
      "county": "Custer"
  },
  {
      "zipCode": 83236,
      "latitude": 43.303218,
      "longitude": -112.10957,
      "city": "Firth",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83237,
      "latitude": 42.028792,
      "longitude": -111.787055,
      "city": "Franklin",
      "state": "ID",
      "county": "Franklin"
  },
  {
      "zipCode": 83238,
      "latitude": 42.331048,
      "longitude": -111.088609,
      "city": "Geneva",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83239,
      "latitude": 42.472875,
      "longitude": -111.435777,
      "city": "Georgetown",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83241,
      "latitude": 42.525874,
      "longitude": -111.76691,
      "city": "Grace",
      "state": "ID",
      "county": "Caribou"
  },
  {
      "zipCode": 83243,
      "latitude": 42.234859,
      "longitude": -112.599201,
      "city": "Holbrook",
      "state": "ID",
      "county": "Oneida"
  },
  {
      "zipCode": 83244,
      "latitude": 43.908662,
      "longitude": -113.087828,
      "city": "Howe",
      "state": "ID",
      "county": "Butte"
  },
  {
      "zipCode": 83245,
      "latitude": 42.670637,
      "longitude": -112.248038,
      "city": "Inkom",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83246,
      "latitude": 42.60197,
      "longitude": -112.058039,
      "city": "Lava Hot Springs",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83250,
      "latitude": 42.611881,
      "longitude": -112.207443,
      "city": "Mccammon",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83251,
      "latitude": 44.017199,
      "longitude": -113.740599,
      "city": "Mackay",
      "state": "ID",
      "county": "Custer"
  },
  {
      "zipCode": 83252,
      "latitude": 42.236441,
      "longitude": -112.556004,
      "city": "Malad City",
      "state": "ID",
      "county": "Oneida"
  },
  {
      "zipCode": 83253,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "May",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83254,
      "latitude": 42.28644,
      "longitude": -111.29681,
      "city": "Montpelier",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83255,
      "latitude": 43.729598,
      "longitude": -113.4544,
      "city": "Moore",
      "state": "ID",
      "county": "Butte"
  },
  {
      "zipCode": 83256,
      "latitude": 43.222648,
      "longitude": -112.442253,
      "city": "Moreland",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83261,
      "latitude": 42.224249,
      "longitude": -111.420825,
      "city": "Paris",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83262,
      "latitude": 43.133647,
      "longitude": -112.629467,
      "city": "Pingree",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83263,
      "latitude": 42.207376,
      "longitude": -111.812126,
      "city": "Preston",
      "state": "ID",
      "county": "Franklin"
  },
  {
      "zipCode": 83271,
      "latitude": 42.490846,
      "longitude": -112.836032,
      "city": "Rockland",
      "state": "ID",
      "county": "Power"
  },
  {
      "zipCode": 83272,
      "latitude": 42.095512,
      "longitude": -111.329873,
      "city": "Saint Charles",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83274,
      "latitude": 43.349659,
      "longitude": -112.118813,
      "city": "Shelley",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83276,
      "latitude": 42.772866,
      "longitude": -111.634757,
      "city": "Soda Springs",
      "state": "ID",
      "county": "Caribou"
  },
  {
      "zipCode": 83277,
      "latitude": 43.080334,
      "longitude": -112.672955,
      "city": "Springfield",
      "state": "ID",
      "county": "Bingham"
  },
  {
      "zipCode": 83278,
      "latitude": 44.371442,
      "longitude": -114.856089,
      "city": "Stanley",
      "state": "ID",
      "county": "Custer"
  },
  {
      "zipCode": 83281,
      "latitude": 42.319496,
      "longitude": -111.978055,
      "city": "Swanlake",
      "state": "ID",
      "county": "Bannock"
  },
  {
      "zipCode": 83283,
      "latitude": 42.379408,
      "longitude": -111.684093,
      "city": "Thatcher",
      "state": "ID",
      "county": "Franklin"
  },
  {
      "zipCode": 83285,
      "latitude": 42.892211,
      "longitude": -111.267458,
      "city": "Wayan",
      "state": "ID",
      "county": "Caribou"
  },
  {
      "zipCode": 83286,
      "latitude": 42.060353,
      "longitude": -112.013197,
      "city": "Weston",
      "state": "ID",
      "county": "Franklin"
  },
  {
      "zipCode": 83287,
      "latitude": 42.039684,
      "longitude": -111.441977,
      "city": "Fish Haven",
      "state": "ID",
      "county": "Bear Lake"
  },
  {
      "zipCode": 83301,
      "latitude": 42.443224,
      "longitude": -114.629497,
      "city": "Twin Falls",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83302,
      "latitude": 42.166936,
      "longitude": -114.815971,
      "city": "Rogerson",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83303,
      "latitude": 42.456273,
      "longitude": -114.519536,
      "city": "Twin Falls",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83311,
      "latitude": 42.339326,
      "longitude": -113.461324,
      "city": "Albion",
      "state": "ID",
      "county": "Cassia"
  },
  {
      "zipCode": 83312,
      "latitude": 42.084606,
      "longitude": -113.628255,
      "city": "Almo",
      "state": "ID",
      "county": "Cassia"
  },
  {
      "zipCode": 83313,
      "latitude": 43.39476,
      "longitude": -114.217718,
      "city": "Bellevue",
      "state": "ID",
      "county": "Blaine"
  },
  {
      "zipCode": 83314,
      "latitude": 42.918993,
      "longitude": -114.887601,
      "city": "Bliss",
      "state": "ID",
      "county": "Gooding"
  },
  {
      "zipCode": 83316,
      "latitude": 42.586201,
      "longitude": -114.829856,
      "city": "Buhl",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83318,
      "latitude": 42.399472,
      "longitude": -113.848558,
      "city": "Burley",
      "state": "ID",
      "county": "Cassia"
  },
  {
      "zipCode": 83320,
      "latitude": 43.391742,
      "longitude": -114.076206,
      "city": "Carey",
      "state": "ID",
      "county": "Blaine"
  },
  {
      "zipCode": 83321,
      "latitude": 42.402884,
      "longitude": -114.784168,
      "city": "Castleford",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83322,
      "latitude": 43.528155,
      "longitude": -114.730694,
      "city": "Corral",
      "state": "ID",
      "county": "Camas"
  },
  {
      "zipCode": 83323,
      "latitude": 42.524527,
      "longitude": -113.433757,
      "city": "Declo",
      "state": "ID",
      "county": "Cassia"
  },
  {
      "zipCode": 83324,
      "latitude": 42.888995,
      "longitude": -114.289216,
      "city": "Dietrich",
      "state": "ID",
      "county": "Lincoln"
  },
  {
      "zipCode": 83325,
      "latitude": 42.586804,
      "longitude": -114.246706,
      "city": "Eden",
      "state": "ID",
      "county": "Jerome"
  },
  {
      "zipCode": 83327,
      "latitude": 43.368419,
      "longitude": -114.686706,
      "city": "Fairfield",
      "state": "ID",
      "county": "Camas"
  },
  {
      "zipCode": 83328,
      "latitude": 42.54076,
      "longitude": -114.623799,
      "city": "Filer",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83330,
      "latitude": 42.907488,
      "longitude": -114.773963,
      "city": "Gooding",
      "state": "ID",
      "county": "Gooding"
  },
  {
      "zipCode": 83332,
      "latitude": 42.807535,
      "longitude": -114.86211,
      "city": "Hagerman",
      "state": "ID",
      "county": "Gooding"
  },
  {
      "zipCode": 83333,
      "latitude": 43.54195,
      "longitude": -114.31008,
      "city": "Hailey",
      "state": "ID",
      "county": "Blaine"
  },
  {
      "zipCode": 83334,
      "latitude": 42.451937,
      "longitude": -114.207144,
      "city": "Hansen",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83335,
      "latitude": 42.596069,
      "longitude": -114.071311,
      "city": "Hazelton",
      "state": "ID",
      "county": "Jerome"
  },
  {
      "zipCode": 83336,
      "latitude": 42.564732,
      "longitude": -113.809824,
      "city": "Heyburn",
      "state": "ID",
      "county": "Minidoka"
  },
  {
      "zipCode": 83337,
      "latitude": 43.3277,
      "longitude": -114.801713,
      "city": "Hill City",
      "state": "ID",
      "county": "Camas"
  },
  {
      "zipCode": 83338,
      "latitude": 42.736869,
      "longitude": -114.374568,
      "city": "Jerome",
      "state": "ID",
      "county": "Jerome"
  },
  {
      "zipCode": 83340,
      "latitude": 43.669159,
      "longitude": -114.485781,
      "city": "Ketchum",
      "state": "ID",
      "county": "Blaine"
  },
  {
      "zipCode": 83341,
      "latitude": 42.50541,
      "longitude": -114.367966,
      "city": "Kimberly",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83342,
      "latitude": 42.274076,
      "longitude": -113.392736,
      "city": "Malta",
      "state": "ID",
      "county": "Cassia"
  },
  {
      "zipCode": 83343,
      "latitude": 42.772955,
      "longitude": -113.509762,
      "city": "Minidoka",
      "state": "ID",
      "county": "Minidoka"
  },
  {
      "zipCode": 83344,
      "latitude": 42.476903,
      "longitude": -114.120513,
      "city": "Murtaugh",
      "state": "ID",
      "county": "Twin Falls"
  },
  {
      "zipCode": 83346,
      "latitude": 42.193954,
      "longitude": -113.872032,
      "city": "Oakley",
      "state": "ID",
      "county": "Cassia"
  },
  {
      "zipCode": 83347,
      "latitude": 42.684923,
      "longitude": -113.790133,
      "city": "Paul",
      "state": "ID",
      "county": "Minidoka"
  },
  {
      "zipCode": 83348,
      "latitude": 43.298245,
      "longitude": -114.023913,
      "city": "Picabo",
      "state": "ID",
      "county": "Blaine"
  },
  {
      "zipCode": 83349,
      "latitude": 43.060804,
      "longitude": -114.212408,
      "city": "Richfield",
      "state": "ID",
      "county": "Lincoln"
  },
  {
      "zipCode": 83350,
      "latitude": 42.688753,
      "longitude": -113.648077,
      "city": "Rupert",
      "state": "ID",
      "county": "Minidoka"
  },
  {
      "zipCode": 83352,
      "latitude": 43.059165,
      "longitude": -114.414825,
      "city": "Shoshone",
      "state": "ID",
      "county": "Lincoln"
  },
  {
      "zipCode": 83353,
      "latitude": 43.684047,
      "longitude": -114.527235,
      "city": "Sun Valley",
      "state": "ID",
      "county": "Blaine"
  },
  {
      "zipCode": 83354,
      "latitude": 43.594633,
      "longitude": -114.322568,
      "city": "Sun Valley",
      "state": "ID",
      "county": "Blaine"
  },
  {
      "zipCode": 83355,
      "latitude": 42.747536,
      "longitude": -114.730593,
      "city": "Wendell",
      "state": "ID",
      "county": "Gooding"
  },
  {
      "zipCode": 83401,
      "latitude": 43.516701,
      "longitude": -111.691535,
      "city": "Idaho Falls",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83402,
      "latitude": 43.51824,
      "longitude": -112.134734,
      "city": "Idaho Falls",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83403,
      "latitude": 43.421132,
      "longitude": -111.333376,
      "city": "Idaho Falls",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83404,
      "latitude": 43.433036,
      "longitude": -111.660433,
      "city": "Idaho Falls",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83405,
      "latitude": 43.323306,
      "longitude": -111.782152,
      "city": "Idaho Falls",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83406,
      "latitude": 43.444641,
      "longitude": -111.963375,
      "city": "Idaho Falls",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83415,
      "latitude": 43.323306,
      "longitude": -111.782152,
      "city": "Idaho Falls",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83420,
      "latitude": 44.015259,
      "longitude": -111.42527,
      "city": "Ashton",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83421,
      "latitude": 44.001141,
      "longitude": -111.535095,
      "city": "Chester",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83422,
      "latitude": 43.713752,
      "longitude": -111.158402,
      "city": "Driggs",
      "state": "ID",
      "county": "Teton"
  },
  {
      "zipCode": 83423,
      "latitude": 44.177432,
      "longitude": -112.219898,
      "city": "Dubois",
      "state": "ID",
      "county": "Clark"
  },
  {
      "zipCode": 83424,
      "latitude": 43.872755,
      "longitude": -111.153854,
      "city": "Felt",
      "state": "ID",
      "county": "Teton"
  },
  {
      "zipCode": 83425,
      "latitude": 43.878511,
      "longitude": -112.260858,
      "city": "Hamer",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83427,
      "latitude": 43.407293,
      "longitude": -111.854263,
      "city": "Iona",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83428,
      "latitude": 43.386149,
      "longitude": -111.25273,
      "city": "Irwin",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83429,
      "latitude": 44.408347,
      "longitude": -111.427555,
      "city": "Island Park",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83431,
      "latitude": 43.692913,
      "longitude": -112.023161,
      "city": "Lewisville",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83433,
      "latitude": 44.319199,
      "longitude": -111.60171,
      "city": "Macks Inn",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83434,
      "latitude": 43.714547,
      "longitude": -112.041547,
      "city": "Menan",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83435,
      "latitude": 43.871293,
      "longitude": -112.447757,
      "city": "Monteview",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83436,
      "latitude": 43.89767,
      "longitude": -111.59402,
      "city": "Newdale",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83438,
      "latitude": 44.319199,
      "longitude": -111.60171,
      "city": "Parker",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83440,
      "latitude": 43.79132,
      "longitude": -111.771375,
      "city": "Rexburg",
      "state": "ID",
      "county": "Madison"
  },
  {
      "zipCode": 83441,
      "latitude": 43.776095,
      "longitude": -111.691047,
      "city": "Rexburg",
      "state": "ID",
      "county": "Madison"
  },
  {
      "zipCode": 83442,
      "latitude": 43.692206,
      "longitude": -111.889325,
      "city": "Rigby",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83443,
      "latitude": 43.641033,
      "longitude": -111.71458,
      "city": "Ririe",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83444,
      "latitude": 43.698922,
      "longitude": -112.173195,
      "city": "Roberts",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83445,
      "latitude": 43.962969,
      "longitude": -111.703605,
      "city": "Saint Anthony",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83446,
      "latitude": 44.271785,
      "longitude": -112.306237,
      "city": "Spencer",
      "state": "ID",
      "county": "Clark"
  },
  {
      "zipCode": 83447,
      "latitude": 44.319199,
      "longitude": -111.60171,
      "city": "Squirrel",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83448,
      "latitude": 43.847662,
      "longitude": -111.693201,
      "city": "Sugar City",
      "state": "ID",
      "county": "Madison"
  },
  {
      "zipCode": 83449,
      "latitude": 43.509657,
      "longitude": -111.402336,
      "city": "Swan Valley",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83450,
      "latitude": 43.85886,
      "longitude": -112.439084,
      "city": "Terreton",
      "state": "ID",
      "county": "Jefferson"
  },
  {
      "zipCode": 83451,
      "latitude": 43.890665,
      "longitude": -111.650418,
      "city": "Teton",
      "state": "ID",
      "county": "Fremont"
  },
  {
      "zipCode": 83452,
      "latitude": 43.781382,
      "longitude": -111.211829,
      "city": "Tetonia",
      "state": "ID",
      "county": "Teton"
  },
  {
      "zipCode": 83454,
      "latitude": 43.593567,
      "longitude": -111.957274,
      "city": "Ucon",
      "state": "ID",
      "county": "Bonneville"
  },
  {
      "zipCode": 83455,
      "latitude": 43.63848,
      "longitude": -111.132302,
      "city": "Victor",
      "state": "ID",
      "county": "Teton"
  },
  {
      "zipCode": 83460,
      "latitude": 43.776095,
      "longitude": -111.691047,
      "city": "Rexburg",
      "state": "ID",
      "county": "Madison"
  },
  {
      "zipCode": 83462,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "Carmen",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83463,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "Gibbonsville",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83464,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "Leadore",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83465,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "Lemhi",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83466,
      "latitude": 45.612948,
      "longitude": -113.967272,
      "city": "North Fork",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83467,
      "latitude": 45.171856,
      "longitude": -113.921041,
      "city": "Salmon",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83468,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "Tendoy",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83469,
      "latitude": 44.968059,
      "longitude": -113.816735,
      "city": "Shoup",
      "state": "ID",
      "county": "Lemhi"
  },
  {
      "zipCode": 83501,
      "latitude": 46.3646,
      "longitude": -116.860852,
      "city": "Lewiston",
      "state": "ID",
      "county": "Nez Perce"
  },
  {
      "zipCode": 83520,
      "latitude": 46.507385,
      "longitude": -116.296141,
      "city": "Ahsahka",
      "state": "ID",
      "county": "Clearwater"
  },
  {
      "zipCode": 83522,
      "latitude": 45.688686,
      "longitude": -115.596198,
      "city": "Cottonwood",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83523,
      "latitude": 46.269379,
      "longitude": -116.539248,
      "city": "Craigmont",
      "state": "ID",
      "county": "Lewis"
  },
  {
      "zipCode": 83524,
      "latitude": 46.401878,
      "longitude": -116.651362,
      "city": "Culdesac",
      "state": "ID",
      "county": "Nez Perce"
  },
  {
      "zipCode": 83525,
      "latitude": 45.822931,
      "longitude": -115.791688,
      "city": "Elk City",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83526,
      "latitude": 46.152621,
      "longitude": -116.390762,
      "city": "Ferdinand",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83530,
      "latitude": 45.869801,
      "longitude": -116.19729,
      "city": "Grangeville",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83531,
      "latitude": 45.889314,
      "longitude": -115.557407,
      "city": "Fenn",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83533,
      "latitude": 45.889314,
      "longitude": -115.557407,
      "city": "Greencreek",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83535,
      "latitude": 46.582376,
      "longitude": -116.739999,
      "city": "Juliaetta",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83536,
      "latitude": 46.223289,
      "longitude": -116.034292,
      "city": "Kamiah",
      "state": "ID",
      "county": "Lewis"
  },
  {
      "zipCode": 83537,
      "latitude": 46.663944,
      "longitude": -116.573632,
      "city": "Kendrick",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83539,
      "latitude": 46.115992,
      "longitude": -115.945949,
      "city": "Kooskia",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83540,
      "latitude": 46.402671,
      "longitude": -116.805125,
      "city": "Lapwai",
      "state": "ID",
      "county": "Nez Perce"
  },
  {
      "zipCode": 83541,
      "latitude": 46.549573,
      "longitude": -116.377265,
      "city": "Lenore",
      "state": "ID",
      "county": "Clearwater"
  },
  {
      "zipCode": 83542,
      "latitude": 45.536959,
      "longitude": -116.323741,
      "city": "Lucile",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83543,
      "latitude": 46.296282,
      "longitude": -116.264274,
      "city": "Nezperce",
      "state": "ID",
      "county": "Lewis"
  },
  {
      "zipCode": 83544,
      "latitude": 46.4976,
      "longitude": -116.184848,
      "city": "Orofino",
      "state": "ID",
      "county": "Clearwater"
  },
  {
      "zipCode": 83545,
      "latitude": 46.451381,
      "longitude": -116.517114,
      "city": "Peck",
      "state": "ID",
      "county": "Nez Perce"
  },
  {
      "zipCode": 83546,
      "latitude": 46.443665,
      "longitude": -115.804849,
      "city": "Pierce",
      "state": "ID",
      "county": "Clearwater"
  },
  {
      "zipCode": 83547,
      "latitude": 45.325345,
      "longitude": -116.359122,
      "city": "Pollock",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83548,
      "latitude": 46.32309,
      "longitude": -116.538865,
      "city": "Reubens",
      "state": "ID",
      "county": "Lewis"
  },
  {
      "zipCode": 83549,
      "latitude": 45.400163,
      "longitude": -116.351079,
      "city": "Riggins",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83551,
      "latitude": 46.400349,
      "longitude": -116.804544,
      "city": "Spalding",
      "state": "ID",
      "county": "Nez Perce"
  },
  {
      "zipCode": 83552,
      "latitude": 46.117669,
      "longitude": -115.974107,
      "city": "Stites",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83553,
      "latitude": 46.381159,
      "longitude": -115.918826,
      "city": "Weippe",
      "state": "ID",
      "county": "Clearwater"
  },
  {
      "zipCode": 83554,
      "latitude": 45.889314,
      "longitude": -115.557407,
      "city": "White Bird",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83555,
      "latitude": 46.24243,
      "longitude": -116.544158,
      "city": "Winchester",
      "state": "ID",
      "county": "Lewis"
  },
  {
      "zipCode": 83601,
      "latitude": 43.847915,
      "longitude": -115.253751,
      "city": "Atlanta",
      "state": "ID",
      "county": "Elmore"
  },
  {
      "zipCode": 83602,
      "latitude": 44.088695,
      "longitude": -116.065807,
      "city": "Banks",
      "state": "ID",
      "county": "Boise"
  },
  {
      "zipCode": 83604,
      "latitude": 42.613957,
      "longitude": -115.841917,
      "city": "Bruneau",
      "state": "ID",
      "county": "Owyhee"
  },
  {
      "zipCode": 83605,
      "latitude": 43.630795,
      "longitude": -116.717598,
      "city": "Caldwell",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83606,
      "latitude": 43.724917,
      "longitude": -116.798923,
      "city": "Caldwell",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83607,
      "latitude": 43.618618,
      "longitude": -116.750109,
      "city": "Caldwell",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83610,
      "latitude": 44.642508,
      "longitude": -116.690316,
      "city": "Cambridge",
      "state": "ID",
      "county": "Washington"
  },
  {
      "zipCode": 83611,
      "latitude": 44.692713,
      "longitude": -115.641659,
      "city": "Cascade",
      "state": "ID",
      "county": "Valley"
  },
  {
      "zipCode": 83612,
      "latitude": 44.853452,
      "longitude": -116.548601,
      "city": "Council",
      "state": "ID",
      "county": "Adams"
  },
  {
      "zipCode": 83615,
      "latitude": 44.665905,
      "longitude": -116.036911,
      "city": "Donnelly",
      "state": "ID",
      "county": "Valley"
  },
  {
      "zipCode": 83616,
      "latitude": 43.735179,
      "longitude": -116.41033,
      "city": "Eagle",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83617,
      "latitude": 43.908876,
      "longitude": -116.492657,
      "city": "Emmett",
      "state": "ID",
      "county": "Gem"
  },
  {
      "zipCode": 83619,
      "latitude": 43.974615,
      "longitude": -116.900775,
      "city": "Fruitland",
      "state": "ID",
      "county": "Payette"
  },
  {
      "zipCode": 83620,
      "latitude": 44.817671,
      "longitude": -116.429857,
      "city": "Fruitvale",
      "state": "ID",
      "county": "Adams"
  },
  {
      "zipCode": 83622,
      "latitude": 44.091712,
      "longitude": -115.909781,
      "city": "Garden Valley",
      "state": "ID",
      "county": "Boise"
  },
  {
      "zipCode": 83623,
      "latitude": 42.950333,
      "longitude": -115.310227,
      "city": "Glenns Ferry",
      "state": "ID",
      "county": "Elmore"
  },
  {
      "zipCode": 83624,
      "latitude": 43.300562,
      "longitude": -116.510261,
      "city": "Grand View",
      "state": "ID",
      "county": "Owyhee"
  },
  {
      "zipCode": 83626,
      "latitude": 43.679989,
      "longitude": -116.76248,
      "city": "Greenleaf",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83627,
      "latitude": 42.948206,
      "longitude": -115.38369,
      "city": "Hammett",
      "state": "ID",
      "county": "Elmore"
  },
  {
      "zipCode": 83628,
      "latitude": 43.635593,
      "longitude": -116.96811,
      "city": "Homedale",
      "state": "ID",
      "county": "Owyhee"
  },
  {
      "zipCode": 83629,
      "latitude": 43.969086,
      "longitude": -116.11845,
      "city": "Horseshoe Bend",
      "state": "ID",
      "county": "Boise"
  },
  {
      "zipCode": 83630,
      "latitude": 43.602661,
      "longitude": -116.790284,
      "city": "Huston",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83631,
      "latitude": 43.883371,
      "longitude": -115.708048,
      "city": "Idaho City",
      "state": "ID",
      "county": "Boise"
  },
  {
      "zipCode": 83632,
      "latitude": 44.560567,
      "longitude": -116.462903,
      "city": "Indian Valley",
      "state": "ID",
      "county": "Adams"
  },
  {
      "zipCode": 83633,
      "latitude": 42.979861,
      "longitude": -115.189933,
      "city": "King Hill",
      "state": "ID",
      "county": "Elmore"
  },
  {
      "zipCode": 83634,
      "latitude": 43.459904,
      "longitude": -116.338457,
      "city": "Kuna",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83635,
      "latitude": 44.68367,
      "longitude": -115.453583,
      "city": "Lake Fork",
      "state": "ID",
      "county": "Valley"
  },
  {
      "zipCode": 83636,
      "latitude": 43.896275,
      "longitude": -116.646209,
      "city": "Letha",
      "state": "ID",
      "county": "Gem"
  },
  {
      "zipCode": 83637,
      "latitude": 44.108217,
      "longitude": -115.481823,
      "city": "Lowman",
      "state": "ID",
      "county": "Boise"
  },
  {
      "zipCode": 83638,
      "latitude": 44.75196,
      "longitude": -116.057987,
      "city": "Mccall",
      "state": "ID",
      "county": "Valley"
  },
  {
      "zipCode": 83639,
      "latitude": 43.388977,
      "longitude": -116.731362,
      "city": "Marsing",
      "state": "ID",
      "county": "Owyhee"
  },
  {
      "zipCode": 83641,
      "latitude": 43.389331,
      "longitude": -116.583102,
      "city": "Melba",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83642,
      "latitude": 43.579067,
      "longitude": -116.421656,
      "city": "Meridian",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83643,
      "latitude": 44.625475,
      "longitude": -116.449286,
      "city": "Mesa",
      "state": "ID",
      "county": "Adams"
  },
  {
      "zipCode": 83644,
      "latitude": 43.577401,
      "longitude": -116.590338,
      "city": "Middleton",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83645,
      "latitude": 44.38304,
      "longitude": -116.651974,
      "city": "Midvale",
      "state": "ID",
      "county": "Washington"
  },
  {
      "zipCode": 83647,
      "latitude": 43.156195,
      "longitude": -115.720455,
      "city": "Mountain Home",
      "state": "ID",
      "county": "Elmore"
  },
  {
      "zipCode": 83648,
      "latitude": 43.009282,
      "longitude": -115.588317,
      "city": "Mountain Home A F B",
      "state": "ID",
      "county": "Elmore"
  },
  {
      "zipCode": 83650,
      "latitude": 42.838437,
      "longitude": -116.032163,
      "city": "Murphy",
      "state": "ID",
      "county": "Owyhee"
  },
  {
      "zipCode": 83651,
      "latitude": 43.595567,
      "longitude": -116.61063,
      "city": "Nampa",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83652,
      "latitude": 43.70713,
      "longitude": -116.620774,
      "city": "Nampa",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83653,
      "latitude": 43.585149,
      "longitude": -116.752963,
      "city": "Nampa",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83654,
      "latitude": 45.086839,
      "longitude": -116.303451,
      "city": "New Meadows",
      "state": "ID",
      "county": "Adams"
  },
  {
      "zipCode": 83655,
      "latitude": 43.95779,
      "longitude": -116.813237,
      "city": "New Plymouth",
      "state": "ID",
      "county": "Payette"
  },
  {
      "zipCode": 83656,
      "latitude": 43.725933,
      "longitude": -116.799727,
      "city": "Notus",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83657,
      "latitude": 44.241764,
      "longitude": -116.273214,
      "city": "Ola",
      "state": "ID",
      "county": "Gem"
  },
  {
      "zipCode": 83660,
      "latitude": 43.785082,
      "longitude": -116.897455,
      "city": "Parma",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83661,
      "latitude": 44.063604,
      "longitude": -116.750704,
      "city": "Payette",
      "state": "ID",
      "county": "Payette"
  },
  {
      "zipCode": 83666,
      "latitude": 44.004244,
      "longitude": -115.966981,
      "city": "Placerville",
      "state": "ID",
      "county": "Boise"
  },
  {
      "zipCode": 83669,
      "latitude": 43.713037,
      "longitude": -116.485429,
      "city": "Star",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83670,
      "latitude": 43.989971,
      "longitude": -116.328567,
      "city": "Sweet",
      "state": "ID",
      "county": "Gem"
  },
  {
      "zipCode": 83671,
      "latitude": 45.889314,
      "longitude": -115.557407,
      "city": "Warren",
      "state": "ID",
      "county": "Idaho"
  },
  {
      "zipCode": 83672,
      "latitude": 44.304193,
      "longitude": -116.859937,
      "city": "Weiser",
      "state": "ID",
      "county": "Washington"
  },
  {
      "zipCode": 83676,
      "latitude": 43.657855,
      "longitude": -116.919629,
      "city": "Wilder",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83677,
      "latitude": 44.68367,
      "longitude": -115.453583,
      "city": "Yellow Pine",
      "state": "ID",
      "county": "Valley"
  },
  {
      "zipCode": 83680,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Meridian",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83686,
      "latitude": 43.549984,
      "longitude": -116.612678,
      "city": "Nampa",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83687,
      "latitude": 43.602302,
      "longitude": -116.552636,
      "city": "Nampa",
      "state": "ID",
      "county": "Canyon"
  },
  {
      "zipCode": 83701,
      "latitude": 43.603768,
      "longitude": -116.272921,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83702,
      "latitude": 43.661963,
      "longitude": -116.195004,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83703,
      "latitude": 43.680432,
      "longitude": -116.248664,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83704,
      "latitude": 43.641467,
      "longitude": -116.290009,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83705,
      "latitude": 43.565639,
      "longitude": -116.223203,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83706,
      "latitude": 43.478618,
      "longitude": -116.137752,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83707,
      "latitude": 43.38469,
      "longitude": -115.997118,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83708,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83709,
      "latitude": 43.558127,
      "longitude": -116.276002,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83711,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83712,
      "latitude": 43.58775,
      "longitude": -116.139425,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83713,
      "latitude": 43.634002,
      "longitude": -116.341948,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83714,
      "latitude": 43.6428,
      "longitude": -116.282554,
      "city": "Garden City",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83715,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83716,
      "latitude": 43.441445,
      "longitude": -116.079142,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83717,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83719,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83720,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83721,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83722,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83723,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83724,
      "latitude": 43.619477,
      "longitude": -116.195159,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83725,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83726,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83727,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83728,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83729,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83730,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83731,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83732,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83733,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83735,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83744,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83756,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83757,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83788,
      "latitude": 43.60682,
      "longitude": -116.282259,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83799,
      "latitude": 43.459855,
      "longitude": -116.243984,
      "city": "Boise",
      "state": "ID",
      "county": "Ada"
  },
  {
      "zipCode": 83801,
      "latitude": 47.922361,
      "longitude": -116.686007,
      "city": "Athol",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83802,
      "latitude": 47.501205,
      "longitude": -115.644501,
      "city": "Avery",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83803,
      "latitude": 47.982174,
      "longitude": -116.549408,
      "city": "Bayview",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83804,
      "latitude": 48.274708,
      "longitude": -116.604444,
      "city": "Blanchard",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83805,
      "latitude": 48.814078,
      "longitude": -116.398202,
      "city": "Bonners Ferry",
      "state": "ID",
      "county": "Boundary"
  },
  {
      "zipCode": 83806,
      "latitude": 46.871563,
      "longitude": -116.407235,
      "city": "Bovill",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83808,
      "latitude": 47.501205,
      "longitude": -115.644501,
      "city": "Calder",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83809,
      "latitude": 48.048216,
      "longitude": -116.59389,
      "city": "Careywood",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83810,
      "latitude": 47.527719,
      "longitude": -116.453134,
      "city": "Cataldo",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83811,
      "latitude": 48.115891,
      "longitude": -116.128983,
      "city": "Clark Fork",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83812,
      "latitude": 46.974333,
      "longitude": -116.28108,
      "city": "Clarkia",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83813,
      "latitude": 48.087984,
      "longitude": -116.614684,
      "city": "Cocolalla",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83814,
      "latitude": 47.728441,
      "longitude": -116.780869,
      "city": "Coeur D Alene",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83815,
      "latitude": 47.837453,
      "longitude": -116.718533,
      "city": "Coeur D Alene",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83816,
      "latitude": 47.678798,
      "longitude": -116.682718,
      "city": "Coeur D Alene",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83821,
      "latitude": 48.607807,
      "longitude": -116.84866,
      "city": "Coolin",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83822,
      "latitude": 48.155185,
      "longitude": -116.978066,
      "city": "Oldtown",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83823,
      "latitude": 46.777036,
      "longitude": -116.525556,
      "city": "Deary",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83824,
      "latitude": 47.146411,
      "longitude": -116.915483,
      "city": "Desmet",
      "state": "ID",
      "county": "Benewah"
  },
  {
      "zipCode": 83825,
      "latitude": 48.36875,
      "longitude": -116.545493,
      "city": "Dover",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83826,
      "latitude": 48.750618,
      "longitude": -116.54067,
      "city": "Eastport",
      "state": "ID",
      "county": "Boundary"
  },
  {
      "zipCode": 83827,
      "latitude": 46.779553,
      "longitude": -116.214523,
      "city": "Elk River",
      "state": "ID",
      "county": "Clearwater"
  },
  {
      "zipCode": 83830,
      "latitude": 47.112423,
      "longitude": -116.528526,
      "city": "Fernwood",
      "state": "ID",
      "county": "Benewah"
  },
  {
      "zipCode": 83832,
      "latitude": 46.604181,
      "longitude": -116.903754,
      "city": "Genesee",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83833,
      "latitude": 47.501761,
      "longitude": -116.751226,
      "city": "Harrison",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83834,
      "latitude": 46.924095,
      "longitude": -116.715837,
      "city": "Harvard",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83835,
      "latitude": 47.798886,
      "longitude": -116.742254,
      "city": "Hayden",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83836,
      "latitude": 48.248701,
      "longitude": -116.279202,
      "city": "Hope",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83837,
      "latitude": 47.515352,
      "longitude": -116.01766,
      "city": "Kellogg",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83839,
      "latitude": 47.574471,
      "longitude": -116.145637,
      "city": "Kingston",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83840,
      "latitude": 48.314378,
      "longitude": -116.515276,
      "city": "Kootenai",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83841,
      "latitude": 48.179085,
      "longitude": -116.824602,
      "city": "Laclede",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83842,
      "latitude": 47.464178,
      "longitude": -116.659053,
      "city": "Medimont",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83843,
      "latitude": 46.735334,
      "longitude": -116.870147,
      "city": "Moscow",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83844,
      "latitude": 46.836284,
      "longitude": -116.684553,
      "city": "Moscow",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83845,
      "latitude": 48.695988,
      "longitude": -116.305402,
      "city": "Moyie Springs",
      "state": "ID",
      "county": "Boundary"
  },
  {
      "zipCode": 83846,
      "latitude": 47.465825,
      "longitude": -115.765951,
      "city": "Mullan",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83847,
      "latitude": 48.694804,
      "longitude": -116.304264,
      "city": "Naples",
      "state": "ID",
      "county": "Boundary"
  },
  {
      "zipCode": 83848,
      "latitude": 48.614631,
      "longitude": -116.925844,
      "city": "Nordman",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83849,
      "latitude": 47.521132,
      "longitude": -116.021029,
      "city": "Osburn",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83850,
      "latitude": 47.494941,
      "longitude": -116.041059,
      "city": "Pinehurst",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83851,
      "latitude": 47.243487,
      "longitude": -116.862018,
      "city": "Plummer",
      "state": "ID",
      "county": "Benewah"
  },
  {
      "zipCode": 83852,
      "latitude": 48.411377,
      "longitude": -116.731899,
      "city": "Ponderay",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83853,
      "latitude": 48.750618,
      "longitude": -116.54067,
      "city": "Porthill",
      "state": "ID",
      "county": "Boundary"
  },
  {
      "zipCode": 83854,
      "latitude": 47.795737,
      "longitude": -116.946395,
      "city": "Post Falls",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83855,
      "latitude": 46.950211,
      "longitude": -116.935859,
      "city": "Potlatch",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83856,
      "latitude": 48.324253,
      "longitude": -116.669154,
      "city": "Priest River",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83857,
      "latitude": 46.884343,
      "longitude": -116.804736,
      "city": "Princeton",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83858,
      "latitude": 47.845814,
      "longitude": -116.839411,
      "city": "Rathdrum",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83860,
      "latitude": 48.193853,
      "longitude": -116.644705,
      "city": "Sagle",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83861,
      "latitude": 47.279233,
      "longitude": -116.646826,
      "city": "Saint Maries",
      "state": "ID",
      "county": "Benewah"
  },
  {
      "zipCode": 83862,
      "latitude": 48.36875,
      "longitude": -116.545493,
      "city": "Samuels",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83864,
      "latitude": 48.299486,
      "longitude": -116.553968,
      "city": "Sandpoint",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83865,
      "latitude": 48.363251,
      "longitude": -116.625594,
      "city": "Colburn",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 83866,
      "latitude": 47.154416,
      "longitude": -116.430997,
      "city": "Santa",
      "state": "ID",
      "county": "Benewah"
  },
  {
      "zipCode": 83867,
      "latitude": 47.498078,
      "longitude": -115.965732,
      "city": "Silverton",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83868,
      "latitude": 47.536163,
      "longitude": -116.206234,
      "city": "Smelterville",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83869,
      "latitude": 47.944549,
      "longitude": -116.811536,
      "city": "Spirit Lake",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83870,
      "latitude": 47.147494,
      "longitude": -116.918177,
      "city": "Tensed",
      "state": "ID",
      "county": "Benewah"
  },
  {
      "zipCode": 83871,
      "latitude": 46.75717,
      "longitude": -116.714214,
      "city": "Troy",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83872,
      "latitude": 46.871724,
      "longitude": -116.967516,
      "city": "Viola",
      "state": "ID",
      "county": "Latah"
  },
  {
      "zipCode": 83873,
      "latitude": 47.553491,
      "longitude": -116.01401,
      "city": "Wallace",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83874,
      "latitude": 47.501205,
      "longitude": -115.644501,
      "city": "Murray",
      "state": "ID",
      "county": "Shoshone"
  },
  {
      "zipCode": 83876,
      "latitude": 47.456856,
      "longitude": -116.898603,
      "city": "Worley",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83877,
      "latitude": 47.678798,
      "longitude": -116.682718,
      "city": "Post Falls",
      "state": "ID",
      "county": "Kootenai"
  },
  {
      "zipCode": 83888,
      "latitude": 48.36875,
      "longitude": -116.545493,
      "city": "Sandpoint",
      "state": "ID",
      "county": "Bonner"
  },
  {
      "zipCode": 84001,
      "latitude": 40.320728,
      "longitude": -110.435974,
      "city": "Altamont",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84002,
      "latitude": 40.320728,
      "longitude": -110.435974,
      "city": "Altonah",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84003,
      "latitude": 40.395796,
      "longitude": -111.803101,
      "city": "American Fork",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84004,
      "latitude": 40.459133,
      "longitude": -111.773164,
      "city": "Alpine",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84006,
      "latitude": 40.592045,
      "longitude": -112.0786,
      "city": "Bingham Canyon",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84007,
      "latitude": 40.312539,
      "longitude": -110.229816,
      "city": "Bluebell",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84008,
      "latitude": 40.443106,
      "longitude": -109.505593,
      "city": "Bonanza",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84010,
      "latitude": 40.874038,
      "longitude": -111.870994,
      "city": "Bountiful",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84011,
      "latitude": 40.963547,
      "longitude": -112.115984,
      "city": "Bountiful",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84013,
      "latitude": 40.183311,
      "longitude": -111.919871,
      "city": "Cedar Valley",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84014,
      "latitude": 40.929547,
      "longitude": -111.889651,
      "city": "Centerville",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84015,
      "latitude": 41.017594,
      "longitude": -111.995023,
      "city": "Clearfield",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84016,
      "latitude": 40.892995,
      "longitude": -111.888121,
      "city": "Clearfield",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84017,
      "latitude": 40.840518,
      "longitude": -111.322754,
      "city": "Coalville",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84018,
      "latitude": 41.090456,
      "longitude": -111.486959,
      "city": "Croydon",
      "state": "UT",
      "county": "Morgan"
  },
  {
      "zipCode": 84020,
      "latitude": 40.506148,
      "longitude": -111.869671,
      "city": "Draper",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84021,
      "latitude": 40.193815,
      "longitude": -110.31321,
      "city": "Duchesne",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84022,
      "latitude": 40.270806,
      "longitude": -112.689833,
      "city": "Dugway",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84023,
      "latitude": 40.936315,
      "longitude": -109.432883,
      "city": "Dutch John",
      "state": "UT",
      "county": "Daggett"
  },
  {
      "zipCode": 84024,
      "latitude": 40.989171,
      "longitude": -111.451001,
      "city": "Echo",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84025,
      "latitude": 40.969069,
      "longitude": -111.907344,
      "city": "Farmington",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84026,
      "latitude": 40.290515,
      "longitude": -109.846675,
      "city": "Fort Duchesne",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84027,
      "latitude": 40.320728,
      "longitude": -110.435974,
      "city": "Fruitland",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84028,
      "latitude": 41.907816,
      "longitude": -111.379691,
      "city": "Garden City",
      "state": "UT",
      "county": "Rich"
  },
  {
      "zipCode": 84029,
      "latitude": 40.560384,
      "longitude": -112.54061,
      "city": "Grantsville",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84030,
      "latitude": 40.160179,
      "longitude": -109.547839,
      "city": "Gusher",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84031,
      "latitude": 40.428901,
      "longitude": -110.807816,
      "city": "Hanna",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84032,
      "latitude": 40.517678,
      "longitude": -111.348882,
      "city": "Heber City",
      "state": "UT",
      "county": "Wasatch"
  },
  {
      "zipCode": 84033,
      "latitude": 41.018639,
      "longitude": -111.501094,
      "city": "Henefer",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84034,
      "latitude": 40.489989,
      "longitude": -113.095905,
      "city": "Ibapah",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84035,
      "latitude": 40.344753,
      "longitude": -109.387441,
      "city": "Jensen",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84036,
      "latitude": 40.667933,
      "longitude": -111.312427,
      "city": "Kamas",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84037,
      "latitude": 40.960691,
      "longitude": -111.92797,
      "city": "Kaysville",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84038,
      "latitude": 41.858357,
      "longitude": -111.349487,
      "city": "Laketown",
      "state": "UT",
      "county": "Rich"
  },
  {
      "zipCode": 84039,
      "latitude": 40.485962,
      "longitude": -109.774553,
      "city": "Lapoint",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84040,
      "latitude": 41.088889,
      "longitude": -111.92614,
      "city": "Layton",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84041,
      "latitude": 41.059863,
      "longitude": -111.994525,
      "city": "Layton",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84042,
      "latitude": 40.340251,
      "longitude": -111.719923,
      "city": "Lindon",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84043,
      "latitude": 40.318139,
      "longitude": -111.921078,
      "city": "Lehi",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84044,
      "latitude": 40.699401,
      "longitude": -112.088875,
      "city": "Magna",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84046,
      "latitude": 40.990039,
      "longitude": -109.704889,
      "city": "Manila",
      "state": "UT",
      "county": "Daggett"
  },
  {
      "zipCode": 84047,
      "latitude": 40.608972,
      "longitude": -111.885868,
      "city": "Midvale",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84049,
      "latitude": 40.489378,
      "longitude": -111.485127,
      "city": "Midway",
      "state": "UT",
      "county": "Wasatch"
  },
  {
      "zipCode": 84050,
      "latitude": 41.068883,
      "longitude": -111.702746,
      "city": "Morgan",
      "state": "UT",
      "county": "Morgan"
  },
  {
      "zipCode": 84051,
      "latitude": 40.320728,
      "longitude": -110.435974,
      "city": "Mountain Home",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84052,
      "latitude": 40.200074,
      "longitude": -110.049034,
      "city": "Myton",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84053,
      "latitude": 40.320728,
      "longitude": -110.435974,
      "city": "Neola",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84054,
      "latitude": 40.844595,
      "longitude": -111.919115,
      "city": "North Salt Lake",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84055,
      "latitude": 40.724311,
      "longitude": -111.24561,
      "city": "Oakley",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84056,
      "latitude": 41.120219,
      "longitude": -111.989813,
      "city": "Hill Afb",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84057,
      "latitude": 40.311353,
      "longitude": -111.72496,
      "city": "Orem",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84058,
      "latitude": 40.2818,
      "longitude": -111.72091,
      "city": "Orem",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84059,
      "latitude": 40.176975,
      "longitude": -111.536036,
      "city": "Orem",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84060,
      "latitude": 40.71188,
      "longitude": -111.448892,
      "city": "Park City",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84061,
      "latitude": 40.743379,
      "longitude": -111.351178,
      "city": "Peoa",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84062,
      "latitude": 40.386385,
      "longitude": -111.732911,
      "city": "Pleasant Grove",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84063,
      "latitude": 40.30168,
      "longitude": -109.806162,
      "city": "Randlett",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84064,
      "latitude": 41.690895,
      "longitude": -111.184512,
      "city": "Randolph",
      "state": "UT",
      "county": "Rich"
  },
  {
      "zipCode": 84065,
      "latitude": 40.495129,
      "longitude": -112.00627,
      "city": "Riverton",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84066,
      "latitude": 40.266539,
      "longitude": -110.106937,
      "city": "Roosevelt",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84067,
      "latitude": 41.178743,
      "longitude": -112.052525,
      "city": "Roy",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84068,
      "latitude": 40.66797,
      "longitude": -111.505365,
      "city": "Park City",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84069,
      "latitude": 40.356629,
      "longitude": -112.465914,
      "city": "Rush Valley",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84070,
      "latitude": 40.578597,
      "longitude": -111.859504,
      "city": "Sandy",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84071,
      "latitude": 40.441463,
      "longitude": -112.355926,
      "city": "Stockton",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84072,
      "latitude": 40.359782,
      "longitude": -110.721218,
      "city": "Tabiona",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84073,
      "latitude": 40.320728,
      "longitude": -110.435974,
      "city": "Talmage",
      "state": "UT",
      "county": "Duchesne"
  },
  {
      "zipCode": 84074,
      "latitude": 40.617392,
      "longitude": -113.144159,
      "city": "Tooele",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84075,
      "latitude": 41.096293,
      "longitude": -112.079415,
      "city": "Syracuse",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84076,
      "latitude": 40.425577,
      "longitude": -109.840776,
      "city": "Tridell",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84078,
      "latitude": 40.368246,
      "longitude": -109.556103,
      "city": "Vernal",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84079,
      "latitude": 40.160179,
      "longitude": -109.547839,
      "city": "Vernal",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84080,
      "latitude": 40.086909,
      "longitude": -112.455046,
      "city": "Vernon",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84082,
      "latitude": 40.410548,
      "longitude": -111.394222,
      "city": "Wallsburg",
      "state": "UT",
      "county": "Wasatch"
  },
  {
      "zipCode": 84083,
      "latitude": 40.7329,
      "longitude": -113.991849,
      "city": "Wendover",
      "state": "UT",
      "county": "Tooele"
  },
  {
      "zipCode": 84084,
      "latitude": 40.606125,
      "longitude": -111.978898,
      "city": "West Jordan",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84085,
      "latitude": 40.374808,
      "longitude": -109.911237,
      "city": "Whiterocks",
      "state": "UT",
      "county": "Uintah"
  },
  {
      "zipCode": 84086,
      "latitude": 41.467578,
      "longitude": -111.18365,
      "city": "Woodruff",
      "state": "UT",
      "county": "Rich"
  },
  {
      "zipCode": 84087,
      "latitude": 40.889027,
      "longitude": -111.926646,
      "city": "Woods Cross",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84088,
      "latitude": 40.587629,
      "longitude": -111.98342,
      "city": "West Jordan",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84089,
      "latitude": 40.963547,
      "longitude": -112.115984,
      "city": "Clearfield",
      "state": "UT",
      "county": "Davis"
  },
  {
      "zipCode": 84090,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Sandy",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84091,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Sandy",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84092,
      "latitude": 40.583401,
      "longitude": -111.746713,
      "city": "Sandy",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84093,
      "latitude": 40.595025,
      "longitude": -111.824566,
      "city": "Sandy",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84094,
      "latitude": 40.569893,
      "longitude": -111.858617,
      "city": "Sandy",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84095,
      "latitude": 40.554098,
      "longitude": -111.953891,
      "city": "South Jordan",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84097,
      "latitude": 40.297153,
      "longitude": -111.670519,
      "city": "Orem",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84098,
      "latitude": 40.702896,
      "longitude": -111.548098,
      "city": "Park City",
      "state": "UT",
      "county": "Summit"
  },
  {
      "zipCode": 84101,
      "latitude": 40.756095,
      "longitude": -111.900719,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84102,
      "latitude": 40.759246,
      "longitude": -111.86259,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84103,
      "latitude": 40.785847,
      "longitude": -111.869485,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84104,
      "latitude": 40.747845,
      "longitude": -111.9841,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84105,
      "latitude": 40.738153,
      "longitude": -111.856517,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84106,
      "latitude": 40.707846,
      "longitude": -111.836766,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84107,
      "latitude": 40.656796,
      "longitude": -111.890414,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84108,
      "latitude": 40.777078,
      "longitude": -111.753274,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84109,
      "latitude": 40.707005,
      "longitude": -111.744257,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84110,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84111,
      "latitude": 40.755596,
      "longitude": -111.883718,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84112,
      "latitude": 40.765868,
      "longitude": -111.840284,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84113,
      "latitude": 40.765795,
      "longitude": -111.836423,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84114,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84115,
      "latitude": 40.714496,
      "longitude": -111.893118,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84116,
      "latitude": 40.798495,
      "longitude": -111.999902,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84117,
      "latitude": 40.655097,
      "longitude": -111.833966,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84118,
      "latitude": 40.650407,
      "longitude": -112.005421,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84119,
      "latitude": 40.633297,
      "longitude": -111.913368,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84120,
      "latitude": 40.695045,
      "longitude": -112.000072,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84121,
      "latitude": 40.622557,
      "longitude": -111.777749,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84122,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84123,
      "latitude": 40.65963,
      "longitude": -111.919269,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84124,
      "latitude": 40.653246,
      "longitude": -111.820566,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84125,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84126,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84127,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84128,
      "latitude": 40.697645,
      "longitude": -112.037673,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84130,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84131,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84132,
      "latitude": 40.772743,
      "longitude": -111.838541,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84133,
      "latitude": 40.769046,
      "longitude": -111.889319,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84134,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84135,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84136,
      "latitude": 40.768546,
      "longitude": -111.887869,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84138,
      "latitude": 40.671614,
      "longitude": -111.74828,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84139,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84140,
      "latitude": 40.771345,
      "longitude": -111.93087,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84141,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84142,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84143,
      "latitude": 40.786886,
      "longitude": -111.900832,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84144,
      "latitude": 40.767946,
      "longitude": -111.890819,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84145,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84147,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84148,
      "latitude": 40.756796,
      "longitude": -111.837567,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84150,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84151,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84152,
      "latitude": 40.728579,
      "longitude": -111.66273,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84153,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84157,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84158,
      "latitude": 40.768996,
      "longitude": -111.762115,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84165,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84170,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84171,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84180,
      "latitude": 40.769045,
      "longitude": -111.900769,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84184,
      "latitude": 40.676839,
      "longitude": -111.956811,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84189,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84190,
      "latitude": 40.668068,
      "longitude": -111.908297,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84193,
      "latitude": "",
      "longitude": "",
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84194,
      "latitude": "",
      "longitude": "",
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84195,
      "latitude": "",
      "longitude": "",
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84199,
      "latitude": 40.725946,
      "longitude": -111.93942,
      "city": "Salt Lake City",
      "state": "UT",
      "county": "Salt Lake"
  },
  {
      "zipCode": 84201,
      "latitude": 41.244261,
      "longitude": -112.007924,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84244,
      "latitude": 41.255285,
      "longitude": -111.956721,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84301,
      "latitude": 41.609266,
      "longitude": -112.124107,
      "city": "Bear River City",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84302,
      "latitude": 41.537922,
      "longitude": -112.061761,
      "city": "Brigham City",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84304,
      "latitude": 41.816936,
      "longitude": -111.998197,
      "city": "Cache Junction",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84305,
      "latitude": 41.919726,
      "longitude": -112.030254,
      "city": "Clarkston",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84306,
      "latitude": 41.781268,
      "longitude": -112.066608,
      "city": "Collinston",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84307,
      "latitude": 41.454713,
      "longitude": -112.390322,
      "city": "Corinne",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84308,
      "latitude": 41.944253,
      "longitude": -111.973275,
      "city": "Cornish",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84309,
      "latitude": 41.713643,
      "longitude": -112.097195,
      "city": "Deweyville",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84310,
      "latitude": 41.339343,
      "longitude": -111.738298,
      "city": "Eden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84311,
      "latitude": 41.628942,
      "longitude": -112.094039,
      "city": "Fielding",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84312,
      "latitude": 41.798402,
      "longitude": -112.140361,
      "city": "Garland",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84313,
      "latitude": 41.500586,
      "longitude": -112.957875,
      "city": "Grouse Creek",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84314,
      "latitude": 41.651978,
      "longitude": -112.104979,
      "city": "Honeyville",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84315,
      "latitude": 41.182343,
      "longitude": -112.103475,
      "city": "Hooper",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84316,
      "latitude": 41.77328,
      "longitude": -112.396755,
      "city": "Howell",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84317,
      "latitude": 41.266035,
      "longitude": -111.715855,
      "city": "Huntsville",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84318,
      "latitude": 41.800028,
      "longitude": -111.812321,
      "city": "Hyde Park",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84319,
      "latitude": 41.717589,
      "longitude": -111.879653,
      "city": "Hyrum",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84320,
      "latitude": 41.816874,
      "longitude": -111.874421,
      "city": "Lewiston",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84321,
      "latitude": 41.76695,
      "longitude": -111.885084,
      "city": "Logan",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84322,
      "latitude": 41.641189,
      "longitude": -111.896571,
      "city": "Logan",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84323,
      "latitude": 41.684253,
      "longitude": -111.783783,
      "city": "Logan",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84324,
      "latitude": 41.492844,
      "longitude": -111.9424,
      "city": "Mantua",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84325,
      "latitude": 41.724699,
      "longitude": -111.989806,
      "city": "Mendon",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84326,
      "latitude": 41.675906,
      "longitude": -111.818459,
      "city": "Millville",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84327,
      "latitude": 41.921329,
      "longitude": -112.002633,
      "city": "Newton",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84328,
      "latitude": 41.548529,
      "longitude": -111.81074,
      "city": "Paradise",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84329,
      "latitude": 41.808234,
      "longitude": -113.523193,
      "city": "Park Valley",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84330,
      "latitude": 41.883058,
      "longitude": -112.138837,
      "city": "Plymouth",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84331,
      "latitude": 41.906183,
      "longitude": -112.180605,
      "city": "Portage",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84332,
      "latitude": 41.797849,
      "longitude": -111.915564,
      "city": "Providence",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84333,
      "latitude": 41.883064,
      "longitude": -111.798034,
      "city": "Richmond",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84334,
      "latitude": 41.786849,
      "longitude": -112.146687,
      "city": "Riverside",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84335,
      "latitude": 41.801798,
      "longitude": -111.910101,
      "city": "Smithfield",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84336,
      "latitude": 41.965964,
      "longitude": -112.726895,
      "city": "Snowville",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84337,
      "latitude": 41.68849,
      "longitude": -112.255453,
      "city": "Tremonton",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84338,
      "latitude": 41.92405,
      "longitude": -111.925583,
      "city": "Trenton",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84339,
      "latitude": 41.637826,
      "longitude": -111.917588,
      "city": "Wellsville",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84340,
      "latitude": 41.387828,
      "longitude": -112.04765,
      "city": "Willard",
      "state": "UT",
      "county": "Box Elder"
  },
  {
      "zipCode": 84341,
      "latitude": 41.838005,
      "longitude": -111.917217,
      "city": "Logan",
      "state": "UT",
      "county": "Cache"
  },
  {
      "zipCode": 84401,
      "latitude": 41.221328,
      "longitude": -111.957685,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84402,
      "latitude": 41.255285,
      "longitude": -111.956721,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84403,
      "latitude": 41.178343,
      "longitude": -111.938216,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84404,
      "latitude": 41.279292,
      "longitude": -112.079805,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84405,
      "latitude": 41.171493,
      "longitude": -111.968874,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84407,
      "latitude": 41.238547,
      "longitude": -111.965867,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84408,
      "latitude": 41.195593,
      "longitude": -111.948474,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84409,
      "latitude": 41.255285,
      "longitude": -111.956721,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84412,
      "latitude": 41.255285,
      "longitude": -111.956721,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84414,
      "latitude": 41.309184,
      "longitude": -111.92559,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84415,
      "latitude": 41.255285,
      "longitude": -111.956721,
      "city": "Ogden",
      "state": "UT",
      "county": "Weber"
  },
  {
      "zipCode": 84501,
      "latitude": 39.567883,
      "longitude": -110.650901,
      "city": "Price",
      "state": "UT",
      "county": "Carbon"
  },
  {
      "zipCode": 84510,
      "latitude": 37.748946,
      "longitude": -110.22691,
      "city": "Aneth",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84511,
      "latitude": 37.566938,
      "longitude": -109.44141,
      "city": "Blanding",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84512,
      "latitude": 37.624663,
      "longitude": -109.479932,
      "city": "Bluff",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84513,
      "latitude": 39.196393,
      "longitude": -111.052868,
      "city": "Castle Dale",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84515,
      "latitude": 38.999877,
      "longitude": -109.615008,
      "city": "Cisco",
      "state": "UT",
      "county": "Grand"
  },
  {
      "zipCode": 84516,
      "latitude": 39.119759,
      "longitude": -111.100814,
      "city": "Clawson",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84518,
      "latitude": 39.373583,
      "longitude": -110.893573,
      "city": "Cleveland",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84520,
      "latitude": 39.531795,
      "longitude": -110.403536,
      "city": "East Carbon",
      "state": "UT",
      "county": "Carbon"
  },
  {
      "zipCode": 84521,
      "latitude": 39.403255,
      "longitude": -110.810027,
      "city": "Elmo",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84522,
      "latitude": 38.9226,
      "longitude": -111.251238,
      "city": "Emery",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84523,
      "latitude": 39.14373,
      "longitude": -111.118572,
      "city": "Ferron",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84525,
      "latitude": 39.020075,
      "longitude": -110.213864,
      "city": "Green River",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84526,
      "latitude": 39.637459,
      "longitude": -110.77667,
      "city": "Helper",
      "state": "UT",
      "county": "Carbon"
  },
  {
      "zipCode": 84527,
      "latitude": 39.640779,
      "longitude": -110.560697,
      "city": "Hiawatha",
      "state": "UT",
      "county": "Carbon"
  },
  {
      "zipCode": 84528,
      "latitude": 39.283156,
      "longitude": -110.961933,
      "city": "Huntington",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84529,
      "latitude": 39.688418,
      "longitude": -110.80726,
      "city": "Kenilworth",
      "state": "UT",
      "county": "Carbon"
  },
  {
      "zipCode": 84530,
      "latitude": 37.748946,
      "longitude": -110.22691,
      "city": "La Sal",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84531,
      "latitude": 37.748946,
      "longitude": -110.22691,
      "city": "Mexican Hat",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84532,
      "latitude": 38.5804,
      "longitude": -109.54019,
      "city": "Moab",
      "state": "UT",
      "county": "Grand"
  },
  {
      "zipCode": 84533,
      "latitude": 37.748946,
      "longitude": -110.22691,
      "city": "Lake Powell",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84534,
      "latitude": 37.25391,
      "longitude": -109.106079,
      "city": "Montezuma Creek",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84535,
      "latitude": 38.099745,
      "longitude": -109.259108,
      "city": "Monticello",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84536,
      "latitude": 37.280022,
      "longitude": -109.56809,
      "city": "Monument Valley",
      "state": "UT",
      "county": "San Juan"
  },
  {
      "zipCode": 84537,
      "latitude": 39.230155,
      "longitude": -111.051827,
      "city": "Orangeville",
      "state": "UT",
      "county": "Emery"
  },
  {
      "zipCode": 84539,
      "latitude": 39.534651,
      "longitude": -110.398832,
      "city": "Sunnyside",
      "state": "UT",
      "county": "Carbon"
  },
  {
      "zipCode": 84540,
      "latitude": 38.999877,
      "longitude": -109.615008,
      "city": "Thompson",
      "state": "UT",
      "county": "Grand"
  },
  {
      "zipCode": 84542,
      "latitude": 39.614663,
      "longitude": -110.641314,
      "city": "Wellington",
      "state": "UT",
      "county": "Carbon"
  },
  {
      "zipCode": 84601,
      "latitude": 40.235053,
      "longitude": -111.695558,
      "city": "Provo",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84602,
      "latitude": 40.356343,
      "longitude": -111.732476,
      "city": "Provo",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84603,
      "latitude": 40.203908,
      "longitude": -111.626081,
      "city": "Provo",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84604,
      "latitude": 40.317059,
      "longitude": -111.608241,
      "city": "Provo",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84605,
      "latitude": 40.176975,
      "longitude": -111.536036,
      "city": "Provo",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84606,
      "latitude": 40.218905,
      "longitude": -111.635353,
      "city": "Provo",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84620,
      "latitude": 38.959002,
      "longitude": -111.904385,
      "city": "Aurora",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84621,
      "latitude": 39.192478,
      "longitude": -111.719605,
      "city": "Axtell",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84622,
      "latitude": 39.109892,
      "longitude": -111.823067,
      "city": "Centerfield",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84623,
      "latitude": 39.494319,
      "longitude": -111.493217,
      "city": "Chester",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84624,
      "latitude": 39.247056,
      "longitude": -112.526542,
      "city": "Delta",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84626,
      "latitude": 40.176975,
      "longitude": -111.536036,
      "city": "Elberta",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84627,
      "latitude": 39.373122,
      "longitude": -111.62523,
      "city": "Ephraim",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84628,
      "latitude": 39.941737,
      "longitude": -112.174424,
      "city": "Eureka",
      "state": "UT",
      "county": "Juab"
  },
  {
      "zipCode": 84629,
      "latitude": 39.697844,
      "longitude": -111.430513,
      "city": "Fairview",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84630,
      "latitude": 39.21521,
      "longitude": -111.879009,
      "city": "Fayette",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84631,
      "latitude": 39.055572,
      "longitude": -112.467412,
      "city": "Fillmore",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84632,
      "latitude": 39.392622,
      "longitude": -111.729316,
      "city": "Fountain Green",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84633,
      "latitude": 40.029693,
      "longitude": -111.764116,
      "city": "Goshen",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84634,
      "latitude": 39.136318,
      "longitude": -111.845821,
      "city": "Gunnison",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84635,
      "latitude": 39.114202,
      "longitude": -113.130306,
      "city": "Hinckley",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84636,
      "latitude": 39.096372,
      "longitude": -112.283443,
      "city": "Holden",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84637,
      "latitude": 38.831824,
      "longitude": -112.456072,
      "city": "Kanosh",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84638,
      "latitude": 39.530246,
      "longitude": -112.295191,
      "city": "Leamington",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84639,
      "latitude": 39.521515,
      "longitude": -111.943753,
      "city": "Levan",
      "state": "UT",
      "county": "Juab"
  },
  {
      "zipCode": 84640,
      "latitude": 39.494252,
      "longitude": -112.397903,
      "city": "Lynndyl",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84642,
      "latitude": 39.358358,
      "longitude": -111.65769,
      "city": "Manti",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84643,
      "latitude": 39.11544,
      "longitude": -111.690522,
      "city": "Mayfield",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84644,
      "latitude": 39.06433,
      "longitude": -113.032141,
      "city": "Meadow",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84645,
      "latitude": 39.848111,
      "longitude": -111.850284,
      "city": "Mona",
      "state": "UT",
      "county": "Juab"
  },
  {
      "zipCode": 84646,
      "latitude": 39.510792,
      "longitude": -111.560318,
      "city": "Moroni",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84647,
      "latitude": 39.348588,
      "longitude": -111.605642,
      "city": "Mount Pleasant",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84648,
      "latitude": 39.757861,
      "longitude": -111.841787,
      "city": "Nephi",
      "state": "UT",
      "county": "Juab"
  },
  {
      "zipCode": 84649,
      "latitude": 39.372902,
      "longitude": -112.328797,
      "city": "Oak City",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84650,
      "latitude": 39.29022,
      "longitude": -112.628084,
      "city": "Oasis",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84651,
      "latitude": 40.032911,
      "longitude": -111.723974,
      "city": "Payson",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84652,
      "latitude": 38.906498,
      "longitude": -111.975313,
      "city": "Redmond",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84653,
      "latitude": 40.011274,
      "longitude": -111.599759,
      "city": "Salem",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84654,
      "latitude": 38.921403,
      "longitude": -111.908156,
      "city": "Salina",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84655,
      "latitude": 40.003254,
      "longitude": -111.812973,
      "city": "Santaquin",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84656,
      "latitude": 39.249513,
      "longitude": -112.106366,
      "city": "Scipio",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84657,
      "latitude": 38.848233,
      "longitude": -111.973737,
      "city": "Sigurd",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84660,
      "latitude": 40.069946,
      "longitude": -111.649086,
      "city": "Spanish Fork",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84662,
      "latitude": 39.467083,
      "longitude": -111.46609,
      "city": "Spring City",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84663,
      "latitude": 40.180718,
      "longitude": -111.562586,
      "city": "Springville",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84664,
      "latitude": 40.125168,
      "longitude": -111.580197,
      "city": "Mapleton",
      "state": "UT",
      "county": "Utah"
  },
  {
      "zipCode": 84665,
      "latitude": 39.133489,
      "longitude": -111.740853,
      "city": "Sterling",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84667,
      "latitude": 39.488381,
      "longitude": -111.634051,
      "city": "Wales",
      "state": "UT",
      "county": "Sanpete"
  },
  {
      "zipCode": 84701,
      "latitude": 38.738771,
      "longitude": -112.074362,
      "city": "Richfield",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84710,
      "latitude": 37.438637,
      "longitude": -112.482285,
      "city": "Alton",
      "state": "UT",
      "county": "Kane"
  },
  {
      "zipCode": 84711,
      "latitude": 38.716845,
      "longitude": -112.065687,
      "city": "Annabella",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84712,
      "latitude": 37.729535,
      "longitude": -110.543374,
      "city": "Antimony",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84713,
      "latitude": 38.315334,
      "longitude": -112.798228,
      "city": "Beaver",
      "state": "UT",
      "county": "Beaver"
  },
  {
      "zipCode": 84714,
      "latitude": 37.721369,
      "longitude": -113.663891,
      "city": "Beryl",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84715,
      "latitude": 38.327826,
      "longitude": -111.526391,
      "city": "Bicknell",
      "state": "UT",
      "county": "Wayne"
  },
  {
      "zipCode": 84716,
      "latitude": 37.844777,
      "longitude": -111.308206,
      "city": "Boulder",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84717,
      "latitude": 37.844777,
      "longitude": -111.308206,
      "city": "Bryce Canyon",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84718,
      "latitude": 37.844777,
      "longitude": -111.308206,
      "city": "Cannonville",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84719,
      "latitude": 37.735522,
      "longitude": -112.839145,
      "city": "Brian Head",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84720,
      "latitude": 37.694059,
      "longitude": -113.192619,
      "city": "Cedar City",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84721,
      "latitude": 37.656843,
      "longitude": -113.3695,
      "city": "Cedar City",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84722,
      "latitude": 37.46693,
      "longitude": -113.624783,
      "city": "Central",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84723,
      "latitude": 38.182708,
      "longitude": -112.232857,
      "city": "Circleville",
      "state": "UT",
      "county": "Piute"
  },
  {
      "zipCode": 84724,
      "latitude": 38.686192,
      "longitude": -112.108495,
      "city": "Elsinore",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84725,
      "latitude": 37.579739,
      "longitude": -113.689359,
      "city": "Enterprise",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84726,
      "latitude": 37.767764,
      "longitude": -111.595666,
      "city": "Escalante",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84728,
      "latitude": 39.06433,
      "longitude": -113.032141,
      "city": "Garrison",
      "state": "UT",
      "county": "Millard"
  },
  {
      "zipCode": 84729,
      "latitude": 37.311416,
      "longitude": -112.601749,
      "city": "Glendale",
      "state": "UT",
      "county": "Kane"
  },
  {
      "zipCode": 84730,
      "latitude": 38.766687,
      "longitude": -111.96278,
      "city": "Glenwood",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84731,
      "latitude": 38.235645,
      "longitude": -112.783262,
      "city": "Greenville",
      "state": "UT",
      "county": "Beaver"
  },
  {
      "zipCode": 84732,
      "latitude": 38.328871,
      "longitude": -112.142622,
      "city": "Greenwich",
      "state": "UT",
      "county": "Piute"
  },
  {
      "zipCode": 84733,
      "latitude": 37.287695,
      "longitude": -113.760596,
      "city": "Gunlock",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84734,
      "latitude": 38.286909,
      "longitude": -110.68796,
      "city": "Hanksville",
      "state": "UT",
      "county": "Wayne"
  },
  {
      "zipCode": 84735,
      "latitude": 37.717477,
      "longitude": -112.477716,
      "city": "Hatch",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84736,
      "latitude": 37.844777,
      "longitude": -111.308206,
      "city": "Henrieville",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84737,
      "latitude": 37.145366,
      "longitude": -113.367458,
      "city": "Hurricane",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84738,
      "latitude": 37.218261,
      "longitude": -113.545812,
      "city": "Ivins",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84739,
      "latitude": 38.683242,
      "longitude": -112.115118,
      "city": "Joseph",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84740,
      "latitude": 38.243856,
      "longitude": -112.223742,
      "city": "Junction",
      "state": "UT",
      "county": "Piute"
  },
  {
      "zipCode": 84741,
      "latitude": 37.265251,
      "longitude": -111.687187,
      "city": "Kanab",
      "state": "UT",
      "county": "Kane"
  },
  {
      "zipCode": 84742,
      "latitude": 37.522753,
      "longitude": -113.203633,
      "city": "Kanarraville",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84743,
      "latitude": 38.227829,
      "longitude": -112.20536,
      "city": "Kingston",
      "state": "UT",
      "county": "Piute"
  },
  {
      "zipCode": 84744,
      "latitude": 38.722109,
      "longitude": -111.871305,
      "city": "Koosharem",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84745,
      "latitude": 37.222917,
      "longitude": -113.316429,
      "city": "La Verkin",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84746,
      "latitude": 37.237225,
      "longitude": -113.33562,
      "city": "Leeds",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84747,
      "latitude": 38.441473,
      "longitude": -111.595751,
      "city": "Loa",
      "state": "UT",
      "county": "Wayne"
  },
  {
      "zipCode": 84749,
      "latitude": 38.398684,
      "longitude": -111.592048,
      "city": "Lyman",
      "state": "UT",
      "county": "Wayne"
  },
  {
      "zipCode": 84750,
      "latitude": 38.434909,
      "longitude": -112.206757,
      "city": "Marysvale",
      "state": "UT",
      "county": "Piute"
  },
  {
      "zipCode": 84751,
      "latitude": 38.305873,
      "longitude": -113.250981,
      "city": "Milford",
      "state": "UT",
      "county": "Beaver"
  },
  {
      "zipCode": 84752,
      "latitude": 38.214874,
      "longitude": -112.923106,
      "city": "Minersville",
      "state": "UT",
      "county": "Beaver"
  },
  {
      "zipCode": 84753,
      "latitude": 37.775781,
      "longitude": -113.813634,
      "city": "Modena",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84754,
      "latitude": 38.657847,
      "longitude": -112.126117,
      "city": "Monroe",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84755,
      "latitude": 37.241269,
      "longitude": -112.696938,
      "city": "Mount Carmel",
      "state": "UT",
      "county": "Kane"
  },
  {
      "zipCode": 84756,
      "latitude": 37.692401,
      "longitude": -113.627239,
      "city": "Newcastle",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84757,
      "latitude": 37.455047,
      "longitude": -113.268012,
      "city": "New Harmony",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84758,
      "latitude": 37.272395,
      "longitude": -112.716919,
      "city": "Orderville",
      "state": "UT",
      "county": "Kane"
  },
  {
      "zipCode": 84759,
      "latitude": 37.827846,
      "longitude": -112.522595,
      "city": "Panguitch",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84760,
      "latitude": 37.886012,
      "longitude": -112.791568,
      "city": "Paragonah",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84761,
      "latitude": 37.823412,
      "longitude": -112.849246,
      "city": "Parowan",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84762,
      "latitude": 37.516889,
      "longitude": -112.663612,
      "city": "Duck Creek Village",
      "state": "UT",
      "county": "Kane"
  },
  {
      "zipCode": 84763,
      "latitude": 37.160144,
      "longitude": -113.042525,
      "city": "Rockville",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84764,
      "latitude": 37.844777,
      "longitude": -111.308206,
      "city": "Bryce",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84765,
      "latitude": 37.345219,
      "longitude": -113.676057,
      "city": "Santa Clara",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84766,
      "latitude": 38.578516,
      "longitude": -112.310757,
      "city": "Sevier",
      "state": "UT",
      "county": "Sevier"
  },
  {
      "zipCode": 84767,
      "latitude": 37.186249,
      "longitude": -113.013922,
      "city": "Springdale",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84770,
      "latitude": 37.306845,
      "longitude": -113.355421,
      "city": "Saint George",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84771,
      "latitude": 37.309231,
      "longitude": -113.476182,
      "city": "Saint George",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84772,
      "latitude": 37.815946,
      "longitude": -112.900041,
      "city": "Summit",
      "state": "UT",
      "county": "Iron"
  },
  {
      "zipCode": 84773,
      "latitude": 38.272761,
      "longitude": -111.464123,
      "city": "Teasdale",
      "state": "UT",
      "county": "Wayne"
  },
  {
      "zipCode": 84774,
      "latitude": 37.237701,
      "longitude": -113.332321,
      "city": "Toquerville",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84775,
      "latitude": 38.296853,
      "longitude": -111.413774,
      "city": "Torrey",
      "state": "UT",
      "county": "Wayne"
  },
  {
      "zipCode": 84776,
      "latitude": 37.626146,
      "longitude": -112.083565,
      "city": "Tropic",
      "state": "UT",
      "county": "Garfield"
  },
  {
      "zipCode": 84779,
      "latitude": 37.202201,
      "longitude": -113.185422,
      "city": "Virgin",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84780,
      "latitude": 37.322604,
      "longitude": -113.583816,
      "city": "Washington",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84781,
      "latitude": 37.37104,
      "longitude": -113.384806,
      "city": "Pine Valley",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84782,
      "latitude": 37.359958,
      "longitude": -113.621344,
      "city": "Veyo",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84783,
      "latitude": 37.307734,
      "longitude": -113.665249,
      "city": "Dammeron Valley",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84784,
      "latitude": 37.004364,
      "longitude": -112.978418,
      "city": "Hildale",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84790,
      "latitude": 37.083098,
      "longitude": -113.558068,
      "city": "Saint George",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 84791,
      "latitude": 37.309231,
      "longitude": -113.476182,
      "city": "Saint George",
      "state": "UT",
      "county": "Washington"
  },
  {
      "zipCode": 85001,
      "latitude": 33.703967,
      "longitude": -112.351835,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85002,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85003,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85004,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85005,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85006,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85007,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85008,
      "latitude": 33.446797,
      "longitude": -111.953512,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85009,
      "latitude": 33.447489,
      "longitude": -111.96942,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85010,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85011,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85012,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85013,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85014,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85015,
      "latitude": 33.478293,
      "longitude": -112.115805,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85016,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85017,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85018,
      "latitude": 33.482033,
      "longitude": -111.735322,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85019,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85020,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85021,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85022,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85023,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85024,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85025,
      "latitude": 33.422621,
      "longitude": -111.723635,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85026,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85027,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85028,
      "latitude": 33.382744,
      "longitude": -111.66139,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85029,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85030,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85031,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85032,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85033,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85034,
      "latitude": 33.431304,
      "longitude": -111.987383,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85035,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85036,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85037,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85038,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85039,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85040,
      "latitude": 33.367267,
      "longitude": -112.066878,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85041,
      "latitude": 33.379728,
      "longitude": -112.112254,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85042,
      "latitude": 33.355545,
      "longitude": -112.052242,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85043,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85044,
      "latitude": 33.338743,
      "longitude": -111.97277,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85045,
      "latitude": 33.302168,
      "longitude": -112.122581,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85046,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85048,
      "latitude": 33.316039,
      "longitude": -112.066901,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85050,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85051,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85053,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85054,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85055,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85060,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85061,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85062,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85063,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85064,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85065,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85066,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85067,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85068,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85069,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85070,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85071,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85072,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85073,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85074,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85075,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85076,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85077,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85078,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85079,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85080,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85082,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85085,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85086,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85087,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "New River",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85098,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85099,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85201,
      "latitude": 33.440695,
      "longitude": -111.856967,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85202,
      "latitude": 33.393484,
      "longitude": -111.804513,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85203,
      "latitude": 33.448876,
      "longitude": -111.824363,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85204,
      "latitude": 33.397131,
      "longitude": -111.787678,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85205,
      "latitude": 33.443345,
      "longitude": -111.732134,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85206,
      "latitude": 33.390148,
      "longitude": -111.717968,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85207,
      "latitude": 33.443017,
      "longitude": -111.743444,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85208,
      "latitude": 33.395932,
      "longitude": -111.663655,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85210,
      "latitude": 33.387296,
      "longitude": -111.840095,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85211,
      "latitude": 33.466313,
      "longitude": -111.837345,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85212,
      "latitude": 33.342476,
      "longitude": -111.635307,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85213,
      "latitude": 33.448407,
      "longitude": -111.830905,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85214,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85215,
      "latitude": 33.470724,
      "longitude": -111.718829,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85216,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85217,
      "latitude": 33.393398,
      "longitude": -111.478975,
      "city": "Apache Junction",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85218,
      "latitude": "",
      "longitude": "",
      "city": "Apache Junction",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85219,
      "latitude": 33.361583,
      "longitude": -111.279538,
      "city": "Apache Junction",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85220,
      "latitude": 33.408443,
      "longitude": -111.535089,
      "city": "Apache Junction",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85221,
      "latitude": 33.225652,
      "longitude": -111.476001,
      "city": "Bapchule",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85222,
      "latitude": 32.89055,
      "longitude": -111.753991,
      "city": "Casa Grande",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85223,
      "latitude": 32.745169,
      "longitude": -111.603327,
      "city": "Arizona City",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85224,
      "latitude": 33.298461,
      "longitude": -111.85062,
      "city": "Chandler",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85225,
      "latitude": 33.325991,
      "longitude": -111.824367,
      "city": "Chandler",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85226,
      "latitude": 33.303777,
      "longitude": -111.932892,
      "city": "Chandler",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85227,
      "latitude": 33.212186,
      "longitude": -111.686171,
      "city": "Chandler Heights",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85228,
      "latitude": 32.904485,
      "longitude": -111.247908,
      "city": "Coolidge",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85230,
      "latitude": 32.815123,
      "longitude": -111.705441,
      "city": "Casa Grande",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85231,
      "latitude": 32.914889,
      "longitude": -111.261161,
      "city": "Eloy",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85232,
      "latitude": 32.982354,
      "longitude": -111.424943,
      "city": "Florence",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85233,
      "latitude": 33.335401,
      "longitude": -111.815281,
      "city": "Gilbert",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85234,
      "latitude": 33.352925,
      "longitude": -111.780712,
      "city": "Gilbert",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85235,
      "latitude": 33.576401,
      "longitude": -110.855618,
      "city": "Hayden",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85236,
      "latitude": 33.299317,
      "longitude": -111.69546,
      "city": "Higley",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85237,
      "latitude": 33.145984,
      "longitude": -111.064109,
      "city": "Kearny",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85239,
      "latitude": 32.957645,
      "longitude": -112.053351,
      "city": "Maricopa",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85241,
      "latitude": 32.689594,
      "longitude": -111.531036,
      "city": "Picacho",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85242,
      "latitude": 33.284844,
      "longitude": -111.656128,
      "city": "Queen Creek",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85244,
      "latitude": 33.321316,
      "longitude": -111.888824,
      "city": "Chandler",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85245,
      "latitude": 32.579972,
      "longitude": -111.340953,
      "city": "Red Rock",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85246,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Chandler",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85247,
      "latitude": 33.141592,
      "longitude": -111.714232,
      "city": "Sacaton",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85248,
      "latitude": 33.250947,
      "longitude": -111.859308,
      "city": "Chandler",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85249,
      "latitude": 33.247861,
      "longitude": -111.770161,
      "city": "Chandler",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85250,
      "latitude": 33.524143,
      "longitude": -111.874248,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85251,
      "latitude": 33.420061,
      "longitude": -111.792658,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85252,
      "latitude": 33.499529,
      "longitude": -111.868432,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85253,
      "latitude": 33.360896,
      "longitude": -111.963149,
      "city": "Paradise Valley",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85254,
      "latitude": 33.483533,
      "longitude": -111.83018,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85255,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85256,
      "latitude": 33.507744,
      "longitude": -111.80033,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85257,
      "latitude": 33.465646,
      "longitude": -111.894039,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85258,
      "latitude": 33.553346,
      "longitude": -111.879806,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85259,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85260,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85261,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85262,
      "latitude": 33.407445,
      "longitude": -111.915298,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85263,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Rio Verde",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85264,
      "latitude": 33.625163,
      "longitude": -111.677135,
      "city": "Fort Mcdowell",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85266,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85267,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85268,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Fountain Hills",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85269,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Fountain Hills",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85271,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Scottsdale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85272,
      "latitude": 32.895764,
      "longitude": -111.957245,
      "city": "Stanfield",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85273,
      "latitude": 33.180583,
      "longitude": -111.130022,
      "city": "Superior",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85274,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85275,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85277,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Mesa",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85278,
      "latitude": 32.983653,
      "longitude": -111.326045,
      "city": "Apache Junction",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85279,
      "latitude": 32.983653,
      "longitude": -111.326045,
      "city": "Florence",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85280,
      "latitude": 33.401395,
      "longitude": -111.931298,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85281,
      "latitude": 33.432844,
      "longitude": -111.927219,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85282,
      "latitude": 33.396736,
      "longitude": -111.929253,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85283,
      "latitude": 33.373723,
      "longitude": -111.876915,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85284,
      "latitude": 33.343546,
      "longitude": -111.914127,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85285,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85287,
      "latitude": 33.428511,
      "longitude": -111.934865,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85289,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Tempe",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85290,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Tortilla Flat",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85291,
      "latitude": 33.013502,
      "longitude": -111.42979,
      "city": "Valley Farms",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85292,
      "latitude": 33.070467,
      "longitude": -110.789035,
      "city": "Winkelman",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85296,
      "latitude": 33.314508,
      "longitude": -111.748791,
      "city": "Gilbert",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85297,
      "latitude": 33.281347,
      "longitude": -111.733566,
      "city": "Gilbert",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85299,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Gilbert",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85301,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85302,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85303,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85304,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85305,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85306,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85307,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85308,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85309,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Luke Afb",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85310,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85311,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85312,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85313,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85318,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Glendale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85320,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Aguila",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85321,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Ajo",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85322,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Arlington",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85323,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Avondale",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85324,
      "latitude": 34.075451,
      "longitude": -112.134005,
      "city": "Black Canyon City",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 85325,
      "latitude": 33.95782,
      "longitude": -114.003634,
      "city": "Bouse",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85326,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Buckeye",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85327,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Cave Creek",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85328,
      "latitude": 33.672255,
      "longitude": -114.03215,
      "city": "Cibola",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85329,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Cashion",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85331,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Cave Creek",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85332,
      "latitude": 34.170305,
      "longitude": -112.787686,
      "city": "Congress",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 85333,
      "latitude": 32.825705,
      "longitude": -113.525199,
      "city": "Dateland",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85334,
      "latitude": 33.61767,
      "longitude": -114.507697,
      "city": "Ehrenberg",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85335,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "El Mirage",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85336,
      "latitude": 32.530155,
      "longitude": -114.774342,
      "city": "Gadsden",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85337,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Gila Bend",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85338,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Goodyear",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85339,
      "latitude": 33.335057,
      "longitude": -112.182248,
      "city": "Laveen",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85340,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Litchfield Park",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85341,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Lukeville",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85342,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Morristown",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85343,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Palo Verde",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85344,
      "latitude": 33.967712,
      "longitude": -114.268073,
      "city": "Parker",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85345,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Peoria",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85346,
      "latitude": 33.729219,
      "longitude": -114.192454,
      "city": "Quartzsite",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85347,
      "latitude": 32.752609,
      "longitude": -113.798728,
      "city": "Roll",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85348,
      "latitude": 33.64797,
      "longitude": -113.662489,
      "city": "Salome",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85349,
      "latitude": 32.540068,
      "longitude": -114.754999,
      "city": "San Luis",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85350,
      "latitude": 32.612408,
      "longitude": -114.606214,
      "city": "Somerton",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85351,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Sun City",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85352,
      "latitude": 32.693448,
      "longitude": -113.976313,
      "city": "Tacna",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85353,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Tolleson",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85354,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Tonopah",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85355,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Waddell",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85356,
      "latitude": 32.693461,
      "longitude": -114.156058,
      "city": "Wellton",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85357,
      "latitude": 34.042203,
      "longitude": -113.458002,
      "city": "Wenden",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85358,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Wickenburg",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85359,
      "latitude": 33.66688,
      "longitude": -114.239557,
      "city": "Quartzsite",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85360,
      "latitude": 35.74782,
      "longitude": -113.8106,
      "city": "Wikieup",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 85361,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Wittmann",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85362,
      "latitude": 34.250781,
      "longitude": -112.756652,
      "city": "Yarnell",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 85363,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Youngtown",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85364,
      "latitude": 32.615305,
      "longitude": -114.648722,
      "city": "Yuma",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85365,
      "latitude": 32.709332,
      "longitude": -114.490471,
      "city": "Yuma",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85366,
      "latitude": 32.609959,
      "longitude": -114.631172,
      "city": "Yuma",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85367,
      "latitude": 32.656575,
      "longitude": -114.404216,
      "city": "Yuma",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85369,
      "latitude": 32.751632,
      "longitude": -114.074901,
      "city": "Yuma",
      "state": "AZ",
      "county": "Yuma"
  },
  {
      "zipCode": 85371,
      "latitude": 34.031791,
      "longitude": -114.390171,
      "city": "Poston",
      "state": "AZ",
      "county": "La Paz"
  },
  {
      "zipCode": 85372,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Sun City",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85373,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Sun City",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85374,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Surprise",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85375,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Sun City West",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85376,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Sun City West",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85377,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Carefree",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85378,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Surprise",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85379,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Surprise",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85380,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Peoria",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85381,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Peoria",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85382,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Peoria",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85383,
      "latitude": 33.798406,
      "longitude": -112.305179,
      "city": "Peoria",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85385,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Peoria",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85387,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Surprise",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85390,
      "latitude": 33.276539,
      "longitude": -112.18717,
      "city": "Wickenburg",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": 85501,
      "latitude": 33.476884,
      "longitude": -110.868076,
      "city": "Globe",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85502,
      "latitude": 33.421919,
      "longitude": -110.81268,
      "city": "Globe",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85530,
      "latitude": 33.038678,
      "longitude": -109.785973,
      "city": "Bylas",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85531,
      "latitude": 32.869243,
      "longitude": -109.787778,
      "city": "Central",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85532,
      "latitude": 33.415409,
      "longitude": -110.814893,
      "city": "Claypool",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85533,
      "latitude": 32.991251,
      "longitude": -109.255872,
      "city": "Clifton",
      "state": "AZ",
      "county": "Greenlee"
  },
  {
      "zipCode": 85534,
      "latitude": 32.881003,
      "longitude": -109.215723,
      "city": "Duncan",
      "state": "AZ",
      "county": "Greenlee"
  },
  {
      "zipCode": 85535,
      "latitude": 32.975112,
      "longitude": -109.897401,
      "city": "Eden",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85536,
      "latitude": 33.033314,
      "longitude": -109.971733,
      "city": "Fort Thomas",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85539,
      "latitude": 33.528204,
      "longitude": -110.966562,
      "city": "Miami",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85540,
      "latitude": 33.054062,
      "longitude": -109.326923,
      "city": "Morenci",
      "state": "AZ",
      "county": "Greenlee"
  },
  {
      "zipCode": 85541,
      "latitude": 33.864471,
      "longitude": -111.075088,
      "city": "Payson",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85542,
      "latitude": 33.741518,
      "longitude": -110.860653,
      "city": "Peridot",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85543,
      "latitude": 32.878042,
      "longitude": -109.775698,
      "city": "Pima",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85544,
      "latitude": 34.320445,
      "longitude": -111.396855,
      "city": "Pine",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85545,
      "latitude": 33.614316,
      "longitude": -111.005928,
      "city": "Roosevelt",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85546,
      "latitude": 32.813612,
      "longitude": -109.701631,
      "city": "Safford",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85547,
      "latitude": 34.257457,
      "longitude": -111.28775,
      "city": "Payson",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85548,
      "latitude": 32.797009,
      "longitude": -109.752196,
      "city": "Safford",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85550,
      "latitude": 33.289447,
      "longitude": -110.491898,
      "city": "San Carlos",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85551,
      "latitude": 32.842769,
      "longitude": -109.696449,
      "city": "Solomon",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85552,
      "latitude": 32.850436,
      "longitude": -109.746133,
      "city": "Thatcher",
      "state": "AZ",
      "county": "Graham"
  },
  {
      "zipCode": 85553,
      "latitude": 33.741518,
      "longitude": -110.860653,
      "city": "Tonto Basin",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85554,
      "latitude": 33.741518,
      "longitude": -110.860653,
      "city": "Young",
      "state": "AZ",
      "county": "Gila"
  },
  {
      "zipCode": 85601,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Arivaca",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85602,
      "latitude": 32.035885,
      "longitude": -110.262611,
      "city": "Benson",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85603,
      "latitude": 31.445577,
      "longitude": -109.819216,
      "city": "Bisbee",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85605,
      "latitude": 31.891434,
      "longitude": -109.706426,
      "city": "Bowie",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85606,
      "latitude": 32.043893,
      "longitude": -109.835893,
      "city": "Cochise",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85607,
      "latitude": 31.604081,
      "longitude": -109.866187,
      "city": "Douglas",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85608,
      "latitude": 31.415114,
      "longitude": -109.596038,
      "city": "Douglas",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85609,
      "latitude": 32.040753,
      "longitude": -109.818047,
      "city": "Dragoon",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85610,
      "latitude": 31.737711,
      "longitude": -109.622175,
      "city": "Elfrida",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85611,
      "latitude": 31.609653,
      "longitude": -110.563551,
      "city": "Elgin",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85613,
      "latitude": 31.559131,
      "longitude": -110.318682,
      "city": "Fort Huachuca",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85614,
      "latitude": 31.838223,
      "longitude": -110.955483,
      "city": "Green Valley",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85615,
      "latitude": 31.411921,
      "longitude": -110.158573,
      "city": "Hereford",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85616,
      "latitude": 31.606087,
      "longitude": -110.248009,
      "city": "Huachuca City",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85617,
      "latitude": 31.512083,
      "longitude": -109.84377,
      "city": "Mc Neal",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85618,
      "latitude": 32.703898,
      "longitude": -110.69592,
      "city": "Mammoth",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85619,
      "latitude": 32.376306,
      "longitude": -110.760458,
      "city": "Mount Lemmon",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85620,
      "latitude": 31.385182,
      "longitude": -109.919794,
      "city": "Naco",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85621,
      "latitude": 31.46327,
      "longitude": -110.8819,
      "city": "Nogales",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85622,
      "latitude": 31.853334,
      "longitude": -110.932803,
      "city": "Green Valley",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85623,
      "latitude": 32.64213,
      "longitude": -110.850082,
      "city": "Oracle",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85624,
      "latitude": 31.523845,
      "longitude": -110.675964,
      "city": "Patagonia",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85625,
      "latitude": 31.766243,
      "longitude": -109.896053,
      "city": "Pearce",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85626,
      "latitude": 31.357528,
      "longitude": -109.611545,
      "city": "Pirtleville",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85627,
      "latitude": 32.049456,
      "longitude": -110.290892,
      "city": "Pomerene",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85628,
      "latitude": 31.531998,
      "longitude": -110.909305,
      "city": "Nogales",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85629,
      "latitude": 31.932883,
      "longitude": -110.895188,
      "city": "Sahuarita",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85630,
      "latitude": 31.891828,
      "longitude": -110.170442,
      "city": "Saint David",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85631,
      "latitude": 32.620892,
      "longitude": -110.599242,
      "city": "San Manuel",
      "state": "AZ",
      "county": "Pinal"
  },
  {
      "zipCode": 85632,
      "latitude": 31.880077,
      "longitude": -109.754263,
      "city": "San Simon",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85633,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Sasabe",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85634,
      "latitude": 31.974033,
      "longitude": -110.702027,
      "city": "Sells",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85635,
      "latitude": 31.810649,
      "longitude": -109.997623,
      "city": "Sierra Vista",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85636,
      "latitude": 31.668685,
      "longitude": -110.280111,
      "city": "Sierra Vista",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85637,
      "latitude": 31.673105,
      "longitude": -110.618814,
      "city": "Sonoita",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85638,
      "latitude": 31.67106,
      "longitude": -110.076564,
      "city": "Tombstone",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85639,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Topawa",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85640,
      "latitude": 31.616167,
      "longitude": -111.051279,
      "city": "Tumacacori",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85641,
      "latitude": 32.002669,
      "longitude": -110.705269,
      "city": "Vail",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85643,
      "latitude": 32.07005,
      "longitude": -109.886546,
      "city": "Willcox",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85644,
      "latitude": 32.30069,
      "longitude": -109.878211,
      "city": "Willcox",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85645,
      "latitude": 31.672252,
      "longitude": -111.09862,
      "city": "Amado",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85646,
      "latitude": 31.593758,
      "longitude": -111.066245,
      "city": "Tubac",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85648,
      "latitude": 31.508436,
      "longitude": -111.075695,
      "city": "Rio Rico",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85650,
      "latitude": 31.489157,
      "longitude": -110.215304,
      "city": "Sierra Vista",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85652,
      "latitude": 32.420055,
      "longitude": -111.113178,
      "city": "Cortaro",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85653,
      "latitude": 32.442979,
      "longitude": -111.159344,
      "city": "Marana",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85654,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Rillito",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85655,
      "latitude": 31.880077,
      "longitude": -109.754263,
      "city": "Douglas",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85662,
      "latitude": 31.531998,
      "longitude": -110.909305,
      "city": "Nogales",
      "state": "AZ",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 85670,
      "latitude": 31.880077,
      "longitude": -109.754263,
      "city": "Fort Huachuca",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85671,
      "latitude": 31.880077,
      "longitude": -109.754263,
      "city": "Sierra Vista",
      "state": "AZ",
      "county": "Cochise"
  },
  {
      "zipCode": 85701,
      "latitude": 32.217975,
      "longitude": -110.970869,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85702,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85703,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85704,
      "latitude": 32.395222,
      "longitude": -111.100062,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85705,
      "latitude": 32.294945,
      "longitude": -110.900395,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85706,
      "latitude": 32.093314,
      "longitude": -110.879684,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85707,
      "latitude": 32.169577,
      "longitude": -110.875093,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85708,
      "latitude": 32.187559,
      "longitude": -110.874973,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85709,
      "latitude": 32.200813,
      "longitude": -110.897966,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85710,
      "latitude": 32.213926,
      "longitude": -110.78934,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85711,
      "latitude": 32.214075,
      "longitude": -110.883744,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85712,
      "latitude": 32.253714,
      "longitude": -110.883744,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85713,
      "latitude": 32.192676,
      "longitude": -110.945048,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85714,
      "latitude": 32.123064,
      "longitude": -110.934945,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85715,
      "latitude": 32.251875,
      "longitude": -110.819977,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85716,
      "latitude": 32.24425,
      "longitude": -110.92318,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85717,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85718,
      "latitude": 32.311724,
      "longitude": -110.91898,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85719,
      "latitude": 32.247175,
      "longitude": -110.947541,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85720,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85721,
      "latitude": 32.233761,
      "longitude": -110.949996,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85722,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85723,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85724,
      "latitude": 32.240571,
      "longitude": -110.944343,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85725,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85726,
      "latitude": 32.202726,
      "longitude": -110.945346,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85728,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85730,
      "latitude": 32.159829,
      "longitude": -110.772728,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85731,
      "latitude": 32.088034,
      "longitude": -110.708174,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85732,
      "latitude": 32.084775,
      "longitude": -110.71225,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85733,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85734,
      "latitude": 32.065082,
      "longitude": -110.935337,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85735,
      "latitude": 32.440968,
      "longitude": -110.976006,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85736,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85737,
      "latitude": 32.435158,
      "longitude": -110.984499,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85738,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Catalina",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85739,
      "latitude": 32.465013,
      "longitude": -110.892213,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85740,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85741,
      "latitude": 32.340073,
      "longitude": -111.058754,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85742,
      "latitude": 32.407562,
      "longitude": -111.065029,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85743,
      "latitude": 32.333438,
      "longitude": -111.065869,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85744,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85745,
      "latitude": 32.215967,
      "longitude": -110.869052,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85746,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85747,
      "latitude": 32.098362,
      "longitude": -110.727183,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85748,
      "latitude": 32.213246,
      "longitude": -110.738517,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85749,
      "latitude": 32.263975,
      "longitude": -110.74667,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85750,
      "latitude": 32.297374,
      "longitude": -110.840422,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85751,
      "latitude": 32.161972,
      "longitude": -110.714678,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85752,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85754,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85775,
      "latitude": 31.970131,
      "longitude": -111.890713,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85777,
      "latitude": 32.071764,
      "longitude": -110.859106,
      "city": "Tucson",
      "state": "AZ",
      "county": "Pima"
  },
  {
      "zipCode": 85901,
      "latitude": 34.570811,
      "longitude": -110.032025,
      "city": "Show Low",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85902,
      "latitude": 34.298092,
      "longitude": -110.035185,
      "city": "Show Low",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85911,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Cibecue",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85912,
      "latitude": 34.266588,
      "longitude": -110.203073,
      "city": "White Mountain Lake",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85920,
      "latitude": 34.177052,
      "longitude": -109.240647,
      "city": "Alpine",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85922,
      "latitude": 33.102464,
      "longitude": -109.271169,
      "city": "Blue",
      "state": "AZ",
      "county": "Greenlee"
  },
  {
      "zipCode": 85923,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Clay Springs",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85924,
      "latitude": 34.468787,
      "longitude": -109.742654,
      "city": "Concho",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85925,
      "latitude": 34.107708,
      "longitude": -109.293952,
      "city": "Eagar",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85926,
      "latitude": 34.201164,
      "longitude": -110.010612,
      "city": "Fort Apache",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85927,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Greer",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85928,
      "latitude": 34.40998,
      "longitude": -110.588287,
      "city": "Heber",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85929,
      "latitude": 34.175198,
      "longitude": -109.972598,
      "city": "Lakeside",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85930,
      "latitude": 34.075359,
      "longitude": -109.853192,
      "city": "Mcnary",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85931,
      "latitude": 35.537441,
      "longitude": -111.27586,
      "city": "Forest Lakes",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 85932,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Nutrioso",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85933,
      "latitude": 34.408619,
      "longitude": -110.560023,
      "city": "Overgaard",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85934,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Pinedale",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85935,
      "latitude": 34.141314,
      "longitude": -109.935556,
      "city": "Pinetop",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85936,
      "latitude": 34.497709,
      "longitude": -109.481543,
      "city": "Saint Johns",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85937,
      "latitude": 34.521702,
      "longitude": -110.110981,
      "city": "Snowflake",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85938,
      "latitude": 34.118938,
      "longitude": -109.285553,
      "city": "Springerville",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85939,
      "latitude": 34.314937,
      "longitude": -110.002763,
      "city": "Taylor",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85940,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Vernon",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 85941,
      "latitude": 33.802071,
      "longitude": -109.993738,
      "city": "Whiteriver",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 85942,
      "latitude": 34.647454,
      "longitude": -110.080632,
      "city": "Woodruff",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86001,
      "latitude": 35.932116,
      "longitude": -111.597853,
      "city": "Flagstaff",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86002,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Flagstaff",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86003,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Flagstaff",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86004,
      "latitude": 35.610905,
      "longitude": -111.324353,
      "city": "Flagstaff",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86011,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Flagstaff",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86015,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Bellemont",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86016,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Gray Mountain",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86017,
      "latitude": 34.94122,
      "longitude": -111.640991,
      "city": "Munds Park",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86018,
      "latitude": 35.256347,
      "longitude": -111.95003,
      "city": "Parks",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86020,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Cameron",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86021,
      "latitude": 36.974658,
      "longitude": -112.978914,
      "city": "Colorado City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86022,
      "latitude": 36.96649,
      "longitude": -112.524696,
      "city": "Fredonia",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86023,
      "latitude": 35.942057,
      "longitude": -112.130937,
      "city": "Grand Canyon",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86024,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Happy Jack",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86025,
      "latitude": 34.776828,
      "longitude": -110.089544,
      "city": "Holbrook",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86028,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Petrified Forest Natl Pk",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86029,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Sun Valley",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86030,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Hotevilla",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86031,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Indian Wells",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86032,
      "latitude": 34.981359,
      "longitude": -110.337348,
      "city": "Joseph City",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86033,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Kayenta",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86034,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Keams Canyon",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86035,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Leupp",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86036,
      "latitude": 36.922976,
      "longitude": -111.449889,
      "city": "Marble Canyon",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86038,
      "latitude": 34.908287,
      "longitude": -111.463026,
      "city": "Mormon Lake",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86039,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Kykotsmovi Village",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86040,
      "latitude": 36.910804,
      "longitude": -111.502009,
      "city": "Page",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86042,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Polacca",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86043,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Second Mesa",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86044,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Tonalea",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86045,
      "latitude": 36.132723,
      "longitude": -111.241773,
      "city": "Tuba City",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86046,
      "latitude": 35.915563,
      "longitude": -112.366031,
      "city": "Williams",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86047,
      "latitude": 34.633764,
      "longitude": -110.35061,
      "city": "Winslow",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86052,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "North Rim",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86053,
      "latitude": 36.910554,
      "longitude": -111.451826,
      "city": "Kaibito",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86054,
      "latitude": 36.593079,
      "longitude": -110.655526,
      "city": "Shonto",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86301,
      "latitude": 34.620826,
      "longitude": -112.567165,
      "city": "Prescott",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86302,
      "latitude": 34.574921,
      "longitude": -112.491528,
      "city": "Prescott",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86303,
      "latitude": 34.49579,
      "longitude": -112.378309,
      "city": "Prescott",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86304,
      "latitude": 34.59668,
      "longitude": -112.49066,
      "city": "Prescott",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86305,
      "latitude": 34.683235,
      "longitude": -112.208914,
      "city": "Prescott",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86312,
      "latitude": 34.668291,
      "longitude": -112.307777,
      "city": "Prescott Valley",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86313,
      "latitude": 34.706724,
      "longitude": -112.39773,
      "city": "Prescott",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86314,
      "latitude": 34.627778,
      "longitude": -112.262805,
      "city": "Prescott Valley",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86320,
      "latitude": 34.970209,
      "longitude": -112.263974,
      "city": "Ash Fork",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86321,
      "latitude": 34.583624,
      "longitude": -113.164742,
      "city": "Bagdad",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86322,
      "latitude": 34.569687,
      "longitude": -111.809798,
      "city": "Camp Verde",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86323,
      "latitude": 34.755208,
      "longitude": -112.171623,
      "city": "Chino Valley",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86324,
      "latitude": 34.659484,
      "longitude": -112.13222,
      "city": "Clarkdale",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86325,
      "latitude": 34.735388,
      "longitude": -111.927746,
      "city": "Cornville",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86326,
      "latitude": 34.643596,
      "longitude": -112.043547,
      "city": "Cottonwood",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86327,
      "latitude": 34.55652,
      "longitude": -112.284396,
      "city": "Dewey",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86329,
      "latitude": 34.518883,
      "longitude": -112.25234,
      "city": "Humboldt",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86330,
      "latitude": 34.706724,
      "longitude": -112.39773,
      "city": "Iron Springs",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86331,
      "latitude": 34.748589,
      "longitude": -112.108626,
      "city": "Jerome",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86332,
      "latitude": 34.44395,
      "longitude": -112.657865,
      "city": "Kirkland",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86333,
      "latitude": 34.414534,
      "longitude": -112.163493,
      "city": "Mayer",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86334,
      "latitude": 34.80512,
      "longitude": -112.537368,
      "city": "Paulden",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86335,
      "latitude": 34.64161,
      "longitude": -111.796517,
      "city": "Rimrock",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86336,
      "latitude": 34.756885,
      "longitude": -111.279927,
      "city": "Sedona",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86337,
      "latitude": 35.331088,
      "longitude": -112.875402,
      "city": "Seligman",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86338,
      "latitude": 34.706724,
      "longitude": -112.39773,
      "city": "Skull Valley",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86339,
      "latitude": 34.907188,
      "longitude": -111.728572,
      "city": "Sedona",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86340,
      "latitude": 34.706724,
      "longitude": -112.39773,
      "city": "Sedona",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86341,
      "latitude": 34.776648,
      "longitude": -111.76788,
      "city": "Sedona",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86342,
      "latitude": 34.641736,
      "longitude": -111.787154,
      "city": "Lake Montezuma",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86343,
      "latitude": 34.706724,
      "longitude": -112.39773,
      "city": "Crown King",
      "state": "AZ",
      "county": "Yavapai"
  },
  {
      "zipCode": 86351,
      "latitude": 35.032371,
      "longitude": -111.684752,
      "city": "Sedona",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86401,
      "latitude": 35.341456,
      "longitude": -114.017106,
      "city": "Kingman",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86402,
      "latitude": 35.263249,
      "longitude": -114.063651,
      "city": "Kingman",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86403,
      "latitude": 34.500556,
      "longitude": -114.310294,
      "city": "Lake Havasu City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86404,
      "latitude": 34.557533,
      "longitude": -114.330704,
      "city": "Lake Havasu City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86405,
      "latitude": 35.605301,
      "longitude": -113.642712,
      "city": "Lake Havasu City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86406,
      "latitude": 34.756714,
      "longitude": -114.11897,
      "city": "Lake Havasu City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86411,
      "latitude": 35.605301,
      "longitude": -113.642712,
      "city": "Hackberry",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86412,
      "latitude": 35.397172,
      "longitude": -113.843241,
      "city": "Hualapai",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86413,
      "latitude": 35.30024,
      "longitude": -114.221531,
      "city": "Golden Valley",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86426,
      "latitude": 35.066454,
      "longitude": -114.567333,
      "city": "Fort Mohave",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86427,
      "latitude": 35.00429,
      "longitude": -114.581228,
      "city": "Fort Mohave",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86429,
      "latitude": 35.171497,
      "longitude": -114.538599,
      "city": "Bullhead City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86430,
      "latitude": 35.147264,
      "longitude": -114.543299,
      "city": "Bullhead City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86431,
      "latitude": 35.389575,
      "longitude": -114.222136,
      "city": "Chloride",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86432,
      "latitude": 34.901161,
      "longitude": -114.543288,
      "city": "Littlefield",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86433,
      "latitude": 35.028492,
      "longitude": -114.383666,
      "city": "Oatman",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86434,
      "latitude": 35.540361,
      "longitude": -113.415346,
      "city": "Peach Springs",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86435,
      "latitude": 35.630842,
      "longitude": -112.052427,
      "city": "Supai",
      "state": "AZ",
      "county": "Coconino"
  },
  {
      "zipCode": 86436,
      "latitude": 34.779071,
      "longitude": -114.482571,
      "city": "Topock",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86437,
      "latitude": 35.605301,
      "longitude": -113.642712,
      "city": "Valentine",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86438,
      "latitude": 35.605301,
      "longitude": -113.642712,
      "city": "Yucca",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86439,
      "latitude": 35.095848,
      "longitude": -114.619445,
      "city": "Bullhead City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86440,
      "latitude": 35.0524,
      "longitude": -114.245399,
      "city": "Mohave Valley",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86441,
      "latitude": 35.269759,
      "longitude": -114.243188,
      "city": "Dolan Springs",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86442,
      "latitude": 35.005498,
      "longitude": -114.323801,
      "city": "Bullhead City",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86443,
      "latitude": 35.210634,
      "longitude": -114.179228,
      "city": "Temple Bar Marina",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86444,
      "latitude": 35.982167,
      "longitude": -114.077519,
      "city": "Meadview",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86445,
      "latitude": 35.605301,
      "longitude": -113.642712,
      "city": "Willow Beach",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86446,
      "latitude": 34.849946,
      "longitude": -114.591728,
      "city": "Mohave Valley",
      "state": "AZ",
      "county": "Mohave"
  },
  {
      "zipCode": 86502,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Chambers",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86503,
      "latitude": 36.527297,
      "longitude": -109.691925,
      "city": "Chinle",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86504,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Fort Defiance",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86505,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Ganado",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86506,
      "latitude": 35.337124,
      "longitude": -109.247759,
      "city": "Houck",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86507,
      "latitude": 36.398903,
      "longitude": -109.259299,
      "city": "Lukachukai",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86508,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Lupton",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86510,
      "latitude": 35.962139,
      "longitude": -110.308125,
      "city": "Pinon",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86511,
      "latitude": 35.903962,
      "longitude": -109.308746,
      "city": "Saint Michaels",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86512,
      "latitude": 35.406729,
      "longitude": -109.285537,
      "city": "Sanders",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86514,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Teec Nos Pos",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86515,
      "latitude": 35.667596,
      "longitude": -109.07844,
      "city": "Window Rock",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86520,
      "latitude": 35.285746,
      "longitude": -110.288704,
      "city": "Blue Gap",
      "state": "AZ",
      "county": "Navajo"
  },
  {
      "zipCode": 86535,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Dennehotso",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86538,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Many Farms",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86540,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Nazlini",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86544,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Red Valley",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86545,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Rock Point",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86547,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Round Rock",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86549,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Sawmill",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 86556,
      "latitude": 35.237487,
      "longitude": -109.52295,
      "city": "Tsaile",
      "state": "AZ",
      "county": "Apache"
  },
  {
      "zipCode": 87001,
      "latitude": 35.337752,
      "longitude": -106.392905,
      "city": "Algodones",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87002,
      "latitude": 34.651051,
      "longitude": -106.695247,
      "city": "Belen",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87004,
      "latitude": 35.629445,
      "longitude": -106.742896,
      "city": "Bernalillo",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87005,
      "latitude": 35.273985,
      "longitude": -107.991394,
      "city": "Bluewater",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87006,
      "latitude": 34.613133,
      "longitude": -106.786042,
      "city": "Bosque",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87007,
      "latitude": 34.962954,
      "longitude": -108.056457,
      "city": "Casa Blanca",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87008,
      "latitude": 35.124363,
      "longitude": -106.375103,
      "city": "Cedar Crest",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87009,
      "latitude": 34.650925,
      "longitude": -105.880597,
      "city": "Cedarvale",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87010,
      "latitude": 35.264653,
      "longitude": -106.083792,
      "city": "Cerrillos",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87011,
      "latitude": 34.230014,
      "longitude": -107.374545,
      "city": "Claunch",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87012,
      "latitude": 36.230711,
      "longitude": -107.070343,
      "city": "Coyote",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87013,
      "latitude": 36.021095,
      "longitude": -106.888683,
      "city": "Cuba",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87014,
      "latitude": 34.962954,
      "longitude": -108.056457,
      "city": "Cubero",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87015,
      "latitude": 35.134731,
      "longitude": -106.125117,
      "city": "Edgewood",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87016,
      "latitude": 34.752146,
      "longitude": -106.16066,
      "city": "Estancia",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87017,
      "latitude": 36.707545,
      "longitude": -106.594795,
      "city": "Gallina",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87018,
      "latitude": 35.71742,
      "longitude": -106.935844,
      "city": "Counselor",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87020,
      "latitude": 35.09985,
      "longitude": -107.850191,
      "city": "Grants",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87021,
      "latitude": 35.192539,
      "longitude": -107.893691,
      "city": "Milan",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87022,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Isleta",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87023,
      "latitude": 34.592043,
      "longitude": -106.761096,
      "city": "Jarales",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87024,
      "latitude": 35.655141,
      "longitude": -106.739455,
      "city": "Jemez Pueblo",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87025,
      "latitude": 35.786457,
      "longitude": -106.648083,
      "city": "Jemez Springs",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87026,
      "latitude": 34.962954,
      "longitude": -108.056457,
      "city": "Laguna",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87027,
      "latitude": 36.13338,
      "longitude": -106.957116,
      "city": "La Jara",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87028,
      "latitude": 34.381271,
      "longitude": -106.814971,
      "city": "La Joya",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87029,
      "latitude": 36.324857,
      "longitude": -107.049527,
      "city": "Lindrith",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87031,
      "latitude": 34.76577,
      "longitude": -106.712287,
      "city": "Los Lunas",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87032,
      "latitude": 34.851897,
      "longitude": -106.052145,
      "city": "Mc Intosh",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87034,
      "latitude": 34.69753,
      "longitude": -106.807824,
      "city": "Pueblo Of Acoma",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87035,
      "latitude": 34.94103,
      "longitude": -106.055796,
      "city": "Moriarty",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87036,
      "latitude": 34.617468,
      "longitude": -106.277208,
      "city": "Mountainair",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87037,
      "latitude": 36.711152,
      "longitude": -107.974666,
      "city": "Nageezi",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87038,
      "latitude": 34.962954,
      "longitude": -108.056457,
      "city": "New Laguna",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87040,
      "latitude": 34.962954,
      "longitude": -108.056457,
      "city": "Paguate",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87041,
      "latitude": 35.59796,
      "longitude": -106.349162,
      "city": "Pena Blanca",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87042,
      "latitude": 34.840061,
      "longitude": -106.689807,
      "city": "Peralta",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87043,
      "latitude": 35.620401,
      "longitude": -106.637422,
      "city": "Placitas",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87044,
      "latitude": 35.711701,
      "longitude": -106.679604,
      "city": "Ponderosa",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87045,
      "latitude": 35.344949,
      "longitude": -108.101102,
      "city": "Prewitt",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87046,
      "latitude": 35.71742,
      "longitude": -106.935844,
      "city": "Regina",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87047,
      "latitude": 35.152995,
      "longitude": -106.311441,
      "city": "Sandia Park",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87048,
      "latitude": 35.659583,
      "longitude": -106.707978,
      "city": "Corrales",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87049,
      "latitude": 35.145372,
      "longitude": -107.837307,
      "city": "San Fidel",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87051,
      "latitude": 35.15105,
      "longitude": -107.87701,
      "city": "San Rafael",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87052,
      "latitude": 35.526918,
      "longitude": -106.344467,
      "city": "Santo Domingo Pueblo",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87053,
      "latitude": 35.668248,
      "longitude": -106.988531,
      "city": "San Ysidro",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87056,
      "latitude": 35.22779,
      "longitude": -105.977313,
      "city": "Stanley",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87057,
      "latitude": 34.650925,
      "longitude": -105.880597,
      "city": "Tajique",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87059,
      "latitude": 35.035534,
      "longitude": -106.275342,
      "city": "Tijeras",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87060,
      "latitude": 34.742622,
      "longitude": -106.732774,
      "city": "Tome",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87061,
      "latitude": 34.694759,
      "longitude": -106.324694,
      "city": "Torreon",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87062,
      "latitude": 34.473522,
      "longitude": -106.759591,
      "city": "Veguita",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87063,
      "latitude": 34.650925,
      "longitude": -105.880597,
      "city": "Willard",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87064,
      "latitude": 36.181917,
      "longitude": -106.589017,
      "city": "Youngsville",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87068,
      "latitude": 34.85765,
      "longitude": -106.699567,
      "city": "Bosque Farms",
      "state": "NM",
      "county": "Valencia"
  },
  {
      "zipCode": 87070,
      "latitude": 35.022943,
      "longitude": -105.979486,
      "city": "Clines Corners",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 87072,
      "latitude": 35.608882,
      "longitude": -106.351844,
      "city": "Cochiti Pueblo",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87083,
      "latitude": 35.630361,
      "longitude": -106.339756,
      "city": "Cochiti Lake",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87101,
      "latitude": 35.199592,
      "longitude": -106.644831,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87102,
      "latitude": 35.069296,
      "longitude": -106.64853,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87103,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87104,
      "latitude": 35.104045,
      "longitude": -106.671831,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87105,
      "latitude": 35.028423,
      "longitude": -106.664581,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87106,
      "latitude": 35.04319,
      "longitude": -106.489592,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87107,
      "latitude": 35.140444,
      "longitude": -106.638281,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87108,
      "latitude": 35.069946,
      "longitude": -106.577329,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87109,
      "latitude": 35.127194,
      "longitude": -106.58678,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87110,
      "latitude": 35.129594,
      "longitude": -106.62198,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87111,
      "latitude": 35.071965,
      "longitude": -106.454173,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87112,
      "latitude": 35.085042,
      "longitude": -106.444113,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87113,
      "latitude": 35.145397,
      "longitude": -106.50573,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87114,
      "latitude": 35.186754,
      "longitude": -106.665199,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87115,
      "latitude": 35.054947,
      "longitude": -106.546128,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87116,
      "latitude": 35.054355,
      "longitude": -106.561576,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87117,
      "latitude": 35.056747,
      "longitude": -106.571978,
      "city": "Kirtland Afb",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87118,
      "latitude": 35.056567,
      "longitude": -106.569446,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87119,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87120,
      "latitude": 35.119742,
      "longitude": -106.704983,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87121,
      "latitude": 35.059663,
      "longitude": -106.805535,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87122,
      "latitude": 35.18531,
      "longitude": -106.5293,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87123,
      "latitude": 35.069999,
      "longitude": -106.457841,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87124,
      "latitude": 35.282859,
      "longitude": -106.712495,
      "city": "Rio Rancho",
      "state": "NM",
      "county": "Sandoval"
  },
  {
      "zipCode": 87125,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87131,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87153,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87154,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87158,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87174,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Rio Rancho",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87176,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87181,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87184,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87185,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87187,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87190,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87191,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87192,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87193,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87194,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87195,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87196,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87197,
      "latitude": 35.108573,
      "longitude": -106.243911,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87198,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87199,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87201,
      "latitude": 35.044339,
      "longitude": -106.672872,
      "city": "Albuquerque",
      "state": "NM",
      "county": "Bernalillo"
  },
  {
      "zipCode": 87301,
      "latitude": 35.651526,
      "longitude": -108.319497,
      "city": "Gallup",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87302,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Gallup",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87305,
      "latitude": 35.449852,
      "longitude": -108.770522,
      "city": "Gallup",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87310,
      "latitude": 35.79583,
      "longitude": -108.595495,
      "city": "Brimhall",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87311,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Church Rock",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87312,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Continental Divide",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87313,
      "latitude": 35.598506,
      "longitude": -108.000168,
      "city": "Crownpoint",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87315,
      "latitude": 34.962954,
      "longitude": -108.056457,
      "city": "Fence Lake",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87316,
      "latitude": 35.519129,
      "longitude": -108.486407,
      "city": "Fort Wingate",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87317,
      "latitude": 35.576253,
      "longitude": -108.762584,
      "city": "Gamerco",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87319,
      "latitude": 35.50425,
      "longitude": -108.831686,
      "city": "Mentmore",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87320,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Mexican Springs",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87321,
      "latitude": 35.128655,
      "longitude": -108.502673,
      "city": "Ramah",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87322,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Rehoboth",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87323,
      "latitude": 35.397643,
      "longitude": -108.160405,
      "city": "Thoreau",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87325,
      "latitude": 35.884668,
      "longitude": -108.880374,
      "city": "Tohatchi",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87326,
      "latitude": 35.350896,
      "longitude": -108.698459,
      "city": "Vanderwagen",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87327,
      "latitude": 35.069064,
      "longitude": -108.844495,
      "city": "Zuni",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87328,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Navajo",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87347,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Jamestown",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87357,
      "latitude": 34.962954,
      "longitude": -108.056457,
      "city": "Pinehill",
      "state": "NM",
      "county": "Cibola"
  },
  {
      "zipCode": 87364,
      "latitude": 36.500162,
      "longitude": -108.233548,
      "city": "Sheep Springs",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87365,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Smith Lake",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87375,
      "latitude": 35.480924,
      "longitude": -108.176693,
      "city": "Yatahey",
      "state": "NM",
      "county": "Mckinley"
  },
  {
      "zipCode": 87401,
      "latitude": 36.826248,
      "longitude": -108.100535,
      "city": "Farmington",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87402,
      "latitude": 36.775852,
      "longitude": -108.101176,
      "city": "Farmington",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87410,
      "latitude": 36.842198,
      "longitude": -107.818288,
      "city": "Aztec",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87412,
      "latitude": 36.742883,
      "longitude": -108.059771,
      "city": "Blanco",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87413,
      "latitude": 36.613621,
      "longitude": -108.016239,
      "city": "Bloomfield",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87415,
      "latitude": 36.813789,
      "longitude": -108.047499,
      "city": "Flora Vista",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87416,
      "latitude": 36.761342,
      "longitude": -108.159329,
      "city": "Fruitland",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87417,
      "latitude": 36.742972,
      "longitude": -108.318698,
      "city": "Kirtland",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87418,
      "latitude": 36.927197,
      "longitude": -108.188227,
      "city": "La Plata",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87419,
      "latitude": 36.868927,
      "longitude": -107.652841,
      "city": "Navajo Dam",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87420,
      "latitude": 36.417185,
      "longitude": -108.860539,
      "city": "Shiprock",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87421,
      "latitude": 36.750745,
      "longitude": -108.203152,
      "city": "Waterflow",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87455,
      "latitude": 36.500162,
      "longitude": -108.233548,
      "city": "Newcomb",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87461,
      "latitude": 36.500162,
      "longitude": -108.233548,
      "city": "Sanostee",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87499,
      "latitude": 36.774472,
      "longitude": -108.06925,
      "city": "Farmington",
      "state": "NM",
      "county": "San Juan"
  },
  {
      "zipCode": 87500,
      "latitude": "",
      "longitude": "",
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87501,
      "latitude": 35.697473,
      "longitude": -105.982146,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87502,
      "latitude": 35.439937,
      "longitude": -106.123698,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87503,
      "latitude": 35.521181,
      "longitude": -105.981847,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87504,
      "latitude": 35.893597,
      "longitude": -106.007499,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87505,
      "latitude": 35.656638,
      "longitude": -105.946155,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87506,
      "latitude": 35.606283,
      "longitude": -106.075722,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87507,
      "latitude": 35.621812,
      "longitude": -106.108636,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87508,
      "latitude": 35.52063,
      "longitude": -105.945842,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87509,
      "latitude": 35.521181,
      "longitude": -105.981847,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87510,
      "latitude": 36.219921,
      "longitude": -106.262778,
      "city": "Abiquiu",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87511,
      "latitude": 36.064687,
      "longitude": -106.077688,
      "city": "Alcalde",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87512,
      "latitude": 36.803628,
      "longitude": -105.585441,
      "city": "Amalia",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87513,
      "latitude": 36.53294,
      "longitude": -105.656752,
      "city": "Arroyo Hondo",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87514,
      "latitude": 36.520002,
      "longitude": -105.534027,
      "city": "Arroyo Seco",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87515,
      "latitude": 36.465416,
      "longitude": -106.578513,
      "city": "Canjilon",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87516,
      "latitude": 36.181443,
      "longitude": -106.437649,
      "city": "Canones",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87517,
      "latitude": 36.504515,
      "longitude": -105.62924,
      "city": "Carson",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87518,
      "latitude": 36.465416,
      "longitude": -106.578513,
      "city": "Cebolla",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87519,
      "latitude": 36.504515,
      "longitude": -105.62924,
      "city": "Cerro",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87520,
      "latitude": 36.756297,
      "longitude": -106.631567,
      "city": "Chama",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87521,
      "latitude": 36.131096,
      "longitude": -105.738515,
      "city": "Chamisal",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87522,
      "latitude": 36.465416,
      "longitude": -106.578513,
      "city": "Chimayo",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87523,
      "latitude": 36.005591,
      "longitude": -105.903255,
      "city": "Cordova",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87524,
      "latitude": 36.504515,
      "longitude": -105.62924,
      "city": "Costilla",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87525,
      "latitude": 36.536648,
      "longitude": -105.580985,
      "city": "Taos Ski Valley",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87527,
      "latitude": 36.200125,
      "longitude": -105.862272,
      "city": "Dixon",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87528,
      "latitude": 36.934793,
      "longitude": -106.995107,
      "city": "Dulce",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87529,
      "latitude": 36.349198,
      "longitude": -105.624393,
      "city": "El Prado",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87530,
      "latitude": 36.283096,
      "longitude": -106.198853,
      "city": "El Rito",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87531,
      "latitude": 36.215288,
      "longitude": -105.890075,
      "city": "Embudo",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87532,
      "latitude": 36.424214,
      "longitude": -106.251469,
      "city": "Espanola",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87533,
      "latitude": 35.986585,
      "longitude": -106.065392,
      "city": "Espanola",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87535,
      "latitude": 35.77837,
      "longitude": -105.856714,
      "city": "Glorieta",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87537,
      "latitude": 36.074934,
      "longitude": -106.126648,
      "city": "Hernandez",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87538,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Ilfeld",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87539,
      "latitude": 36.465416,
      "longitude": -106.578513,
      "city": "La Madera",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87540,
      "latitude": 35.45554,
      "longitude": -105.874452,
      "city": "Lamy",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87543,
      "latitude": 36.148617,
      "longitude": -105.664751,
      "city": "Llano",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87544,
      "latitude": 35.849514,
      "longitude": -106.288482,
      "city": "Los Alamos",
      "state": "NM",
      "county": "Los Alamos"
  },
  {
      "zipCode": 87545,
      "latitude": 35.863858,
      "longitude": -106.295255,
      "city": "Los Alamos",
      "state": "NM",
      "county": "Los Alamos"
  },
  {
      "zipCode": 87548,
      "latitude": 36.189247,
      "longitude": -106.217575,
      "city": "Medanales",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87549,
      "latitude": 36.331253,
      "longitude": -106.002778,
      "city": "Ojo Caliente",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87551,
      "latitude": 36.728938,
      "longitude": -106.515714,
      "city": "Los Ojos",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87552,
      "latitude": 35.571588,
      "longitude": -105.687047,
      "city": "Pecos",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87553,
      "latitude": 36.176327,
      "longitude": -105.710451,
      "city": "Penasco",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87554,
      "latitude": 36.465416,
      "longitude": -106.578513,
      "city": "Petaca",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87556,
      "latitude": 36.758733,
      "longitude": -105.59761,
      "city": "Questa",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87557,
      "latitude": 36.345971,
      "longitude": -105.68471,
      "city": "Ranchos De Taos",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87558,
      "latitude": 36.665925,
      "longitude": -105.469436,
      "city": "Red River",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87560,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Ribera",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87562,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Rowe",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87564,
      "latitude": 36.619304,
      "longitude": -105.63093,
      "city": "San Cristobal",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87565,
      "latitude": 35.422716,
      "longitude": -105.544995,
      "city": "San Jose",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87566,
      "latitude": 36.065977,
      "longitude": -106.086827,
      "city": "San Juan Pueblo",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87567,
      "latitude": 35.993095,
      "longitude": -106.035116,
      "city": "Santa Cruz",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87569,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Serafina",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87571,
      "latitude": 36.429239,
      "longitude": -105.554532,
      "city": "Taos",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87573,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Tererro",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87574,
      "latitude": 35.787442,
      "longitude": -105.915713,
      "city": "Tesuque",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87575,
      "latitude": 36.696968,
      "longitude": -106.576636,
      "city": "Tierra Amarilla",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87576,
      "latitude": 36.504515,
      "longitude": -105.62924,
      "city": "Trampas",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87577,
      "latitude": 36.504515,
      "longitude": -105.62924,
      "city": "Tres Piedras",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87578,
      "latitude": 36.044377,
      "longitude": -105.812856,
      "city": "Truchas",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87579,
      "latitude": 36.133845,
      "longitude": -105.571165,
      "city": "Vadito",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87580,
      "latitude": 36.613044,
      "longitude": -105.590018,
      "city": "Valdez",
      "state": "NM",
      "county": "Taos"
  },
  {
      "zipCode": 87581,
      "latitude": 36.465416,
      "longitude": -106.578513,
      "city": "Vallecitos",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87582,
      "latitude": 36.132101,
      "longitude": -106.002201,
      "city": "Velarde",
      "state": "NM",
      "county": "Rio Arriba"
  },
  {
      "zipCode": 87583,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Villanueva",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87592,
      "latitude": 35.521181,
      "longitude": -105.981847,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87594,
      "latitude": 35.521181,
      "longitude": -105.981847,
      "city": "Santa Fe",
      "state": "NM",
      "county": "Santa Fe"
  },
  {
      "zipCode": 87701,
      "latitude": 35.551539,
      "longitude": -104.985643,
      "city": "Las Vegas",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87710,
      "latitude": 36.512495,
      "longitude": -104.919697,
      "city": "Angel Fire",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87711,
      "latitude": 34.781908,
      "longitude": -104.718891,
      "city": "Anton Chico",
      "state": "NM",
      "county": "Guadalupe"
  },
  {
      "zipCode": 87712,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Buena Vista",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87713,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Chacon",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87714,
      "latitude": 36.504216,
      "longitude": -104.905419,
      "city": "Cimarron",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87715,
      "latitude": 36.007478,
      "longitude": -105.382548,
      "city": "Cleveland",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87718,
      "latitude": 36.547612,
      "longitude": -105.283276,
      "city": "Eagle Nest",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87722,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Guadalupita",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87723,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Holman",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87724,
      "latitude": 34.781908,
      "longitude": -104.718891,
      "city": "La Loma",
      "state": "NM",
      "county": "Guadalupe"
  },
  {
      "zipCode": 87728,
      "latitude": 36.539682,
      "longitude": -104.559057,
      "city": "Maxwell",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87729,
      "latitude": 36.347987,
      "longitude": -104.792032,
      "city": "Miami",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87730,
      "latitude": 35.804116,
      "longitude": -103.902091,
      "city": "Mills",
      "state": "NM",
      "county": "Harding"
  },
  {
      "zipCode": 87731,
      "latitude": 35.649907,
      "longitude": -105.253167,
      "city": "Montezuma",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87732,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Mora",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87733,
      "latitude": 35.804116,
      "longitude": -103.902091,
      "city": "Mosquero",
      "state": "NM",
      "county": "Harding"
  },
  {
      "zipCode": 87734,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Ocate",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87735,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Ojo Feliz",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87736,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Rainsville",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87740,
      "latitude": 36.662666,
      "longitude": -104.815043,
      "city": "Raton",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87742,
      "latitude": 35.836395,
      "longitude": -105.390777,
      "city": "Rociada",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87743,
      "latitude": 35.93873,
      "longitude": -104.195805,
      "city": "Roy",
      "state": "NM",
      "county": "Harding"
  },
  {
      "zipCode": 87745,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Sapello",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 87746,
      "latitude": 35.804116,
      "longitude": -103.902091,
      "city": "Solano",
      "state": "NM",
      "county": "Harding"
  },
  {
      "zipCode": 87747,
      "latitude": 36.603697,
      "longitude": -104.859182,
      "city": "Springer",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87749,
      "latitude": 36.606924,
      "longitude": -104.692463,
      "city": "Ute Park",
      "state": "NM",
      "county": "Colfax"
  },
  {
      "zipCode": 87750,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Valmora",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87752,
      "latitude": 36.005998,
      "longitude": -104.707404,
      "city": "Wagon Mound",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87753,
      "latitude": 36.008302,
      "longitude": -105.023886,
      "city": "Watrous",
      "state": "NM",
      "county": "Mora"
  },
  {
      "zipCode": 87801,
      "latitude": 34.035975,
      "longitude": -107.03802,
      "city": "Socorro",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87820,
      "latitude": 33.913868,
      "longitude": -108.649811,
      "city": "Aragon",
      "state": "NM",
      "county": "Catron"
  },
  {
      "zipCode": 87821,
      "latitude": 33.890975,
      "longitude": -108.379371,
      "city": "Datil",
      "state": "NM",
      "county": "Catron"
  },
  {
      "zipCode": 87823,
      "latitude": 34.153754,
      "longitude": -106.912543,
      "city": "Lemitar",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87824,
      "latitude": 33.890975,
      "longitude": -108.379371,
      "city": "Luna",
      "state": "NM",
      "county": "Catron"
  },
  {
      "zipCode": 87825,
      "latitude": 34.111424,
      "longitude": -107.240895,
      "city": "Magdalena",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87827,
      "latitude": 33.890975,
      "longitude": -108.379371,
      "city": "Pie Town",
      "state": "NM",
      "county": "Catron"
  },
  {
      "zipCode": 87828,
      "latitude": 34.18757,
      "longitude": -106.919274,
      "city": "Polvadera",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87829,
      "latitude": 33.764949,
      "longitude": -108.558785,
      "city": "Quemado",
      "state": "NM",
      "county": "Catron"
  },
  {
      "zipCode": 87830,
      "latitude": 33.681174,
      "longitude": -108.859382,
      "city": "Reserve",
      "state": "NM",
      "county": "Catron"
  },
  {
      "zipCode": 87831,
      "latitude": 34.300124,
      "longitude": -106.878808,
      "city": "San Acacia",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87832,
      "latitude": 33.963287,
      "longitude": -106.87644,
      "city": "San Antonio",
      "state": "NM",
      "county": "Socorro"
  },
  {
      "zipCode": 87901,
      "latitude": 33.160602,
      "longitude": -107.266914,
      "city": "Truth Or Consequences",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 87930,
      "latitude": 33.042613,
      "longitude": -107.170508,
      "city": "Arrey",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 87931,
      "latitude": 33.042613,
      "longitude": -107.170508,
      "city": "Caballo",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 87933,
      "latitude": 33.042613,
      "longitude": -107.170508,
      "city": "Derry",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 87935,
      "latitude": 33.208962,
      "longitude": -107.221209,
      "city": "Elephant Butte",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 87936,
      "latitude": 32.52512,
      "longitude": -107.100101,
      "city": "Garfield",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 87937,
      "latitude": 32.661331,
      "longitude": -107.123524,
      "city": "Hatch",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 87939,
      "latitude": 33.042613,
      "longitude": -107.170508,
      "city": "Monticello",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 87940,
      "latitude": 32.41815,
      "longitude": -106.820075,
      "city": "Rincon",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 87941,
      "latitude": 32.6957,
      "longitude": -107.153465,
      "city": "Salem",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 87942,
      "latitude": 33.117058,
      "longitude": -107.291475,
      "city": "Williamsburg",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 87943,
      "latitude": 33.042613,
      "longitude": -107.170508,
      "city": "Winston",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 88001,
      "latitude": 32.41467,
      "longitude": -106.854065,
      "city": "Las Cruces",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88002,
      "latitude": 32.383871,
      "longitude": -106.493675,
      "city": "White Sands Missile Range",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88003,
      "latitude": 32.273778,
      "longitude": -106.747176,
      "city": "Las Cruces",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88004,
      "latitude": 32.41815,
      "longitude": -106.820075,
      "city": "Las Cruces",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88005,
      "latitude": 32.167296,
      "longitude": -106.897176,
      "city": "Las Cruces",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88006,
      "latitude": 32.305193,
      "longitude": -106.786259,
      "city": "Las Cruces",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88008,
      "latitude": 31.87054,
      "longitude": -106.626892,
      "city": "Santa Teresa",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88009,
      "latitude": 32.055007,
      "longitude": -108.62913,
      "city": "Playas",
      "state": "NM",
      "county": "Hidalgo"
  },
  {
      "zipCode": 88011,
      "latitude": 32.324407,
      "longitude": -106.668287,
      "city": "Las Cruces",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88012,
      "latitude": 32.329638,
      "longitude": -106.604677,
      "city": "Las Cruces",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88020,
      "latitude": 32.053042,
      "longitude": -108.906944,
      "city": "Animas",
      "state": "NM",
      "county": "Hidalgo"
  },
  {
      "zipCode": 88021,
      "latitude": 32.068186,
      "longitude": -106.570475,
      "city": "Anthony",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88022,
      "latitude": 32.783939,
      "longitude": -108.185406,
      "city": "Arenas Valley",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88023,
      "latitude": 32.819788,
      "longitude": -107.969586,
      "city": "Bayard",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88024,
      "latitude": 32.068164,
      "longitude": -106.630951,
      "city": "Berino",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88025,
      "latitude": 33.06335,
      "longitude": -108.695588,
      "city": "Buckhorn",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88026,
      "latitude": 32.793596,
      "longitude": -108.075231,
      "city": "Santa Clara",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88027,
      "latitude": 32.017438,
      "longitude": -106.66731,
      "city": "Chamberino",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88028,
      "latitude": 32.53667,
      "longitude": -108.327898,
      "city": "Cliff",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88029,
      "latitude": 32.221057,
      "longitude": -107.588344,
      "city": "Columbus",
      "state": "NM",
      "county": "Luna"
  },
  {
      "zipCode": 88030,
      "latitude": 32.079682,
      "longitude": -107.683073,
      "city": "Deming",
      "state": "NM",
      "county": "Luna"
  },
  {
      "zipCode": 88031,
      "latitude": 32.239815,
      "longitude": -107.74042,
      "city": "Deming",
      "state": "NM",
      "county": "Luna"
  },
  {
      "zipCode": 88032,
      "latitude": 32.393919,
      "longitude": -106.800976,
      "city": "Dona Ana",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88033,
      "latitude": 32.29838,
      "longitude": -106.881176,
      "city": "Fairacres",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88034,
      "latitude": 32.693536,
      "longitude": -107.906536,
      "city": "Faywood",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88036,
      "latitude": 32.757427,
      "longitude": -108.007527,
      "city": "Fort Bayard",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88038,
      "latitude": 32.53667,
      "longitude": -108.327898,
      "city": "Gila",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88039,
      "latitude": 33.325458,
      "longitude": -108.871953,
      "city": "Glenwood",
      "state": "NM",
      "county": "Catron"
  },
  {
      "zipCode": 88040,
      "latitude": 31.890411,
      "longitude": -108.331605,
      "city": "Hachita",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88041,
      "latitude": 32.804493,
      "longitude": -107.954524,
      "city": "Hanover",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88042,
      "latitude": 32.92029,
      "longitude": -107.569856,
      "city": "Hillsboro",
      "state": "NM",
      "county": "Sierra"
  },
  {
      "zipCode": 88043,
      "latitude": 32.702887,
      "longitude": -108.100988,
      "city": "Hurley",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88044,
      "latitude": 32.143754,
      "longitude": -106.691214,
      "city": "La Mesa",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88045,
      "latitude": 32.315951,
      "longitude": -108.723047,
      "city": "Lordsburg",
      "state": "NM",
      "county": "Hidalgo"
  },
  {
      "zipCode": 88046,
      "latitude": 32.267993,
      "longitude": -106.805867,
      "city": "Mesilla",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88047,
      "latitude": 32.232085,
      "longitude": -106.748341,
      "city": "Mesilla Park",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88048,
      "latitude": 31.990339,
      "longitude": -106.652047,
      "city": "Mesquite",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88049,
      "latitude": 32.908986,
      "longitude": -108.033173,
      "city": "Mimbres",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88051,
      "latitude": 33.107131,
      "longitude": -108.903197,
      "city": "Mule Creek",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88052,
      "latitude": 32.425921,
      "longitude": -106.613563,
      "city": "Organ",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88053,
      "latitude": 32.861433,
      "longitude": -108.21919,
      "city": "Pinos Altos",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88054,
      "latitude": 32.488841,
      "longitude": -106.914575,
      "city": "Radium Springs",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88055,
      "latitude": 32.53667,
      "longitude": -108.327898,
      "city": "Redrock",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88056,
      "latitude": 32.055007,
      "longitude": -108.62913,
      "city": "Rodeo",
      "state": "NM",
      "county": "Hidalgo"
  },
  {
      "zipCode": 88058,
      "latitude": 32.41815,
      "longitude": -106.820075,
      "city": "San Miguel",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88061,
      "latitude": 32.853738,
      "longitude": -108.330764,
      "city": "Silver City",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88062,
      "latitude": 32.728228,
      "longitude": -108.137931,
      "city": "Silver City",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88063,
      "latitude": 31.880055,
      "longitude": -106.599975,
      "city": "Sunland Park",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88065,
      "latitude": 32.619444,
      "longitude": -108.37041,
      "city": "Tyrone",
      "state": "NM",
      "county": "Grant"
  },
  {
      "zipCode": 88072,
      "latitude": 32.11871,
      "longitude": -106.648973,
      "city": "Vado",
      "state": "NM",
      "county": "Dona Ana"
  },
  {
      "zipCode": 88101,
      "latitude": 34.497241,
      "longitude": -103.294978,
      "city": "Clovis",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88102,
      "latitude": 34.628472,
      "longitude": -103.391269,
      "city": "Clovis",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88103,
      "latitude": 34.401452,
      "longitude": -103.326445,
      "city": "Cannon Afb",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88112,
      "latitude": 34.80789,
      "longitude": -103.128229,
      "city": "Broadview",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88113,
      "latitude": 33.928379,
      "longitude": -103.274249,
      "city": "Causey",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88114,
      "latitude": 32.690034,
      "longitude": -103.209405,
      "city": "Crossroads",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88115,
      "latitude": 33.916566,
      "longitude": -103.380387,
      "city": "Dora",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88116,
      "latitude": 33.985296,
      "longitude": -103.494855,
      "city": "Elida",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88118,
      "latitude": 34.181943,
      "longitude": -103.589732,
      "city": "Floyd",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88119,
      "latitude": 34.531467,
      "longitude": -104.174298,
      "city": "Fort Sumner",
      "state": "NM",
      "county": "De Baca"
  },
  {
      "zipCode": 88120,
      "latitude": 34.817795,
      "longitude": -103.354685,
      "city": "Grady",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88121,
      "latitude": 34.753722,
      "longitude": -103.970607,
      "city": "House",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88122,
      "latitude": 33.883159,
      "longitude": -103.709525,
      "city": "Kenna",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88123,
      "latitude": 33.717607,
      "longitude": -103.145119,
      "city": "Lingo",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88124,
      "latitude": 34.50512,
      "longitude": -103.564506,
      "city": "Melrose",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88125,
      "latitude": 33.720554,
      "longitude": -103.412259,
      "city": "Milnesand",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88126,
      "latitude": 33.805354,
      "longitude": -103.288102,
      "city": "Pep",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88130,
      "latitude": 34.076964,
      "longitude": -103.352595,
      "city": "Portales",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88132,
      "latitude": 33.921623,
      "longitude": -103.189798,
      "city": "Rogers",
      "state": "NM",
      "county": "Roosevelt"
  },
  {
      "zipCode": 88133,
      "latitude": 34.475607,
      "longitude": -103.46686,
      "city": "Saint Vrain",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88134,
      "latitude": 34.387466,
      "longitude": -104.419381,
      "city": "Taiban",
      "state": "NM",
      "county": "De Baca"
  },
  {
      "zipCode": 88135,
      "latitude": 34.518407,
      "longitude": -103.128609,
      "city": "Texico",
      "state": "NM",
      "county": "Curry"
  },
  {
      "zipCode": 88136,
      "latitude": 34.387466,
      "longitude": -104.419381,
      "city": "Yeso",
      "state": "NM",
      "county": "De Baca"
  },
  {
      "zipCode": 88201,
      "latitude": 33.346666,
      "longitude": -104.593687,
      "city": "Roswell",
      "state": "NM",
      "county": "Chaves"
  },
  {
      "zipCode": 88202,
      "latitude": 33.303825,
      "longitude": -104.431788,
      "city": "Roswell",
      "state": "NM",
      "county": "Chaves"
  },
  {
      "zipCode": 88203,
      "latitude": 33.29067,
      "longitude": -104.067489,
      "city": "Roswell",
      "state": "NM",
      "county": "Chaves"
  },
  {
      "zipCode": 88210,
      "latitude": 32.622225,
      "longitude": -104.433331,
      "city": "Artesia",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88211,
      "latitude": 32.753571,
      "longitude": -104.328113,
      "city": "Artesia",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88213,
      "latitude": 32.785417,
      "longitude": -103.433804,
      "city": "Caprock",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88220,
      "latitude": 32.416897,
      "longitude": -104.46539,
      "city": "Carlsbad",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88221,
      "latitude": 32.342033,
      "longitude": -104.293691,
      "city": "Carlsbad",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88230,
      "latitude": 33.238749,
      "longitude": -104.397276,
      "city": "Dexter",
      "state": "NM",
      "county": "Chaves"
  },
  {
      "zipCode": 88231,
      "latitude": 32.440629,
      "longitude": -103.18336,
      "city": "Eunice",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88232,
      "latitude": 33.101546,
      "longitude": -104.356588,
      "city": "Hagerman",
      "state": "NM",
      "county": "Chaves"
  },
  {
      "zipCode": 88240,
      "latitude": 32.646864,
      "longitude": -103.263575,
      "city": "Hobbs",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88241,
      "latitude": 32.7475,
      "longitude": -103.216516,
      "city": "Hobbs",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88242,
      "latitude": 32.496641,
      "longitude": -103.169754,
      "city": "Hobbs",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88244,
      "latitude": 32.785417,
      "longitude": -103.433804,
      "city": "Hobbs",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88250,
      "latitude": 32.802309,
      "longitude": -104.718722,
      "city": "Hope",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88252,
      "latitude": 32.094646,
      "longitude": -103.193924,
      "city": "Jal",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88253,
      "latitude": 33.026322,
      "longitude": -104.491621,
      "city": "Lake Arthur",
      "state": "NM",
      "county": "Chaves"
  },
  {
      "zipCode": 88254,
      "latitude": 32.636247,
      "longitude": -104.365038,
      "city": "Lakewood",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88255,
      "latitude": 32.482828,
      "longitude": -104.287257,
      "city": "Loco Hills",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88256,
      "latitude": 32.272039,
      "longitude": -104.147248,
      "city": "Loving",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88260,
      "latitude": 32.679494,
      "longitude": -103.24708,
      "city": "Lovington",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88262,
      "latitude": 32.785417,
      "longitude": -103.433804,
      "city": "Mc Donald",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88263,
      "latitude": 32.18132,
      "longitude": -104.066791,
      "city": "Malaga",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88264,
      "latitude": 32.785417,
      "longitude": -103.433804,
      "city": "Maljamar",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88265,
      "latitude": 32.654163,
      "longitude": -103.268907,
      "city": "Monument",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88267,
      "latitude": 33.252178,
      "longitude": -103.317675,
      "city": "Tatum",
      "state": "NM",
      "county": "Lea"
  },
  {
      "zipCode": 88268,
      "latitude": 32.482828,
      "longitude": -104.287257,
      "city": "Whites City",
      "state": "NM",
      "county": "Eddy"
  },
  {
      "zipCode": 88301,
      "latitude": 33.641799,
      "longitude": -105.875816,
      "city": "Carrizozo",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88310,
      "latitude": 32.870046,
      "longitude": -105.948967,
      "city": "Alamogordo",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88311,
      "latitude": 32.695546,
      "longitude": -105.612595,
      "city": "Alamogordo",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88312,
      "latitude": 33.388573,
      "longitude": -105.652113,
      "city": "Alto",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88314,
      "latitude": 33.201167,
      "longitude": -105.814309,
      "city": "Bent",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88316,
      "latitude": 33.501389,
      "longitude": -105.612793,
      "city": "Capitan",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88317,
      "latitude": 32.889717,
      "longitude": -105.674395,
      "city": "Cloudcroft",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88318,
      "latitude": 34.249031,
      "longitude": -105.596655,
      "city": "Corona",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88321,
      "latitude": 34.650925,
      "longitude": -105.880597,
      "city": "Encino",
      "state": "NM",
      "county": "Torrance"
  },
  {
      "zipCode": 88323,
      "latitude": 33.644358,
      "longitude": -105.873956,
      "city": "Fort Stanton",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88324,
      "latitude": 33.739651,
      "longitude": -105.629382,
      "city": "Glencoe",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88325,
      "latitude": 32.939582,
      "longitude": -105.818942,
      "city": "High Rolls Mountain Park",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88330,
      "latitude": 32.863459,
      "longitude": -106.000393,
      "city": "Holloman Air Force Base",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88336,
      "latitude": 33.739651,
      "longitude": -105.629382,
      "city": "Hondo",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88337,
      "latitude": 32.985382,
      "longitude": -105.923293,
      "city": "La Luz",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88338,
      "latitude": 33.739651,
      "longitude": -105.629382,
      "city": "Lincoln",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88339,
      "latitude": 32.989936,
      "longitude": -105.598225,
      "city": "Mayhill",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88340,
      "latitude": 33.154212,
      "longitude": -105.803067,
      "city": "Mescalero",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88341,
      "latitude": 33.739651,
      "longitude": -105.629382,
      "city": "Nogal",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88342,
      "latitude": 32.405061,
      "longitude": -106.079686,
      "city": "Orogrande",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88343,
      "latitude": 33.336307,
      "longitude": -105.640034,
      "city": "Picacho",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88344,
      "latitude": 32.609109,
      "longitude": -105.437481,
      "city": "Pinon",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88345,
      "latitude": 33.474521,
      "longitude": -105.688274,
      "city": "Ruidoso",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88346,
      "latitude": 33.353183,
      "longitude": -105.620581,
      "city": "Ruidoso Downs",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88347,
      "latitude": 32.78753,
      "longitude": -105.544431,
      "city": "Sacramento",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88348,
      "latitude": 33.739651,
      "longitude": -105.629382,
      "city": "San Patricio",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88349,
      "latitude": 32.8672,
      "longitude": -105.781129,
      "city": "Sunspot",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88350,
      "latitude": 32.629461,
      "longitude": -105.694486,
      "city": "Timberon",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88351,
      "latitude": 33.739651,
      "longitude": -105.629382,
      "city": "Tinnie",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88352,
      "latitude": 33.172467,
      "longitude": -105.960356,
      "city": "Tularosa",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88353,
      "latitude": 34.600841,
      "longitude": -105.205826,
      "city": "Vaughn",
      "state": "NM",
      "county": "Guadalupe"
  },
  {
      "zipCode": 88354,
      "latitude": 32.777584,
      "longitude": -105.466283,
      "city": "Weed",
      "state": "NM",
      "county": "Otero"
  },
  {
      "zipCode": 88355,
      "latitude": 33.330502,
      "longitude": -105.693322,
      "city": "Ruidoso",
      "state": "NM",
      "county": "Lincoln"
  },
  {
      "zipCode": 88401,
      "latitude": 35.114202,
      "longitude": -103.615509,
      "city": "Tucumcari",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88410,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Amistad",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88411,
      "latitude": 35.130266,
      "longitude": -103.160497,
      "city": "Bard",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88414,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Capulin",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88415,
      "latitude": 36.451155,
      "longitude": -103.162935,
      "city": "Clayton",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88416,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Conchas Dam",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 88417,
      "latitude": 34.781908,
      "longitude": -104.718891,
      "city": "Cuervo",
      "state": "NM",
      "county": "Guadalupe"
  },
  {
      "zipCode": 88418,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Des Moines",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88419,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Folsom",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88421,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Garita",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 88422,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Gladstone",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88424,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Grenville",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88426,
      "latitude": 35.52453,
      "longitude": -103.264281,
      "city": "Logan",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88427,
      "latitude": 34.7321,
      "longitude": -103.653955,
      "city": "Mc Alister",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88429,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Mount Dora",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88430,
      "latitude": 35.635105,
      "longitude": -103.223523,
      "city": "Nara Visa",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88431,
      "latitude": 34.781908,
      "longitude": -104.718891,
      "city": "Newkirk",
      "state": "NM",
      "county": "Guadalupe"
  },
  {
      "zipCode": 88433,
      "latitude": 34.916697,
      "longitude": -103.778184,
      "city": "Quay",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88434,
      "latitude": 35.123423,
      "longitude": -103.271136,
      "city": "San Jon",
      "state": "NM",
      "county": "Quay"
  },
  {
      "zipCode": 88435,
      "latitude": 34.852639,
      "longitude": -104.589897,
      "city": "Santa Rosa",
      "state": "NM",
      "county": "Guadalupe"
  },
  {
      "zipCode": 88436,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Sedan",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88437,
      "latitude": 36.369783,
      "longitude": -103.505541,
      "city": "Seneca",
      "state": "NM",
      "county": "Union"
  },
  {
      "zipCode": 88439,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Trementina",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 88441,
      "latitude": 35.456288,
      "longitude": -104.679611,
      "city": "Bell Ranch",
      "state": "NM",
      "county": "San Miguel"
  },
  {
      "zipCode": 88510,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88511,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88512,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88513,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88514,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88515,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88516,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88517,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88518,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88519,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88520,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88521,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88523,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88524,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88525,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88526,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88527,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88528,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88529,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88530,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88531,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88532,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88533,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88534,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88535,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88536,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88538,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88539,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88540,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88541,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88542,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88543,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88544,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88545,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88546,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88547,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88548,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88549,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88550,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88553,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88554,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88555,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88556,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88557,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88558,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88559,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88560,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88561,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88562,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88563,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88565,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88566,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88567,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88568,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88569,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88570,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88571,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88572,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88573,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88574,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88575,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88576,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88577,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88578,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88579,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88580,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88581,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88582,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88583,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88584,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88585,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88586,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88587,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88588,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88589,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88590,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88595,
      "latitude": 31.694842,
      "longitude": -106.299987,
      "city": "El Paso",
      "state": "TX",
      "county": "El Paso"
  },
  {
      "zipCode": 88901,
      "latitude": 36.322484,
      "longitude": -114.819717,
      "city": "The Lakes",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 88905,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "The Lakes",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89001,
      "latitude": 37.472491,
      "longitude": -115.143709,
      "city": "Alamo",
      "state": "NV",
      "county": "Lincoln"
  },
  {
      "zipCode": 89003,
      "latitude": 36.819857,
      "longitude": -116.609372,
      "city": "Beatty",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89004,
      "latitude": 36.088098,
      "longitude": -115.608969,
      "city": "Blue Diamond",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89005,
      "latitude": 36.020563,
      "longitude": -114.82952,
      "city": "Boulder City",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89006,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Boulder City",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89007,
      "latitude": 36.768399,
      "longitude": -114.128106,
      "city": "Bunkerville",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89008,
      "latitude": 38.017162,
      "longitude": -114.431896,
      "city": "Caliente",
      "state": "NV",
      "county": "Lincoln"
  },
  {
      "zipCode": 89009,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89010,
      "latitude": 37.585111,
      "longitude": -117.604871,
      "city": "Dyer",
      "state": "NV",
      "county": "Esmeralda"
  },
  {
      "zipCode": 89011,
      "latitude": 36.106464,
      "longitude": -114.919174,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89012,
      "latitude": 36.075327,
      "longitude": -115.141185,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89013,
      "latitude": 37.722454,
      "longitude": -117.796454,
      "city": "Goldfield",
      "state": "NV",
      "county": "Esmeralda"
  },
  {
      "zipCode": 89014,
      "latitude": 36.283135,
      "longitude": -114.804393,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89015,
      "latitude": 35.98395,
      "longitude": -115.193902,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89016,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89017,
      "latitude": 37.592635,
      "longitude": -115.226154,
      "city": "Hiko",
      "state": "NV",
      "county": "Lincoln"
  },
  {
      "zipCode": 89018,
      "latitude": 36.431289,
      "longitude": -115.535079,
      "city": "Indian Springs",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89019,
      "latitude": 35.736806,
      "longitude": -115.540529,
      "city": "Jean",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89020,
      "latitude": 36.539018,
      "longitude": -116.549634,
      "city": "Amargosa Valley",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89021,
      "latitude": 36.593525,
      "longitude": -114.468251,
      "city": "Logandale",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89022,
      "latitude": 38.702281,
      "longitude": -116.310045,
      "city": "Manhattan",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89023,
      "latitude": 37.583805,
      "longitude": -116.598559,
      "city": "Mercury",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89024,
      "latitude": 36.810062,
      "longitude": -114.072236,
      "city": "Mesquite",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89025,
      "latitude": 36.691647,
      "longitude": -114.651381,
      "city": "Moapa",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89026,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Jean",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89027,
      "latitude": 36.437362,
      "longitude": -114.728855,
      "city": "Mesquite",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89028,
      "latitude": 35.160398,
      "longitude": -114.746447,
      "city": "Laughlin",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89029,
      "latitude": 35.819678,
      "longitude": -114.675298,
      "city": "Laughlin",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89030,
      "latitude": 36.225271,
      "longitude": -115.15431,
      "city": "North Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89031,
      "latitude": 36.277966,
      "longitude": -115.143685,
      "city": "North Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89032,
      "latitude": 36.217968,
      "longitude": -115.170919,
      "city": "North Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89033,
      "latitude": 36.284511,
      "longitude": -115.134488,
      "city": "North Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89036,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "North Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89039,
      "latitude": 35.252249,
      "longitude": -114.871384,
      "city": "Cal Nev Ari",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89040,
      "latitude": 36.570259,
      "longitude": -114.473191,
      "city": "Overton",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89041,
      "latitude": 36.655797,
      "longitude": -116.004795,
      "city": "Pahrump",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89042,
      "latitude": 37.759736,
      "longitude": -114.972405,
      "city": "Panaca",
      "state": "NV",
      "county": "Lincoln"
  },
  {
      "zipCode": 89043,
      "latitude": 37.759736,
      "longitude": -114.972405,
      "city": "Pioche",
      "state": "NV",
      "county": "Lincoln"
  },
  {
      "zipCode": 89045,
      "latitude": 37.583805,
      "longitude": -116.598559,
      "city": "Round Mountain",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89046,
      "latitude": 35.332725,
      "longitude": -114.892999,
      "city": "Searchlight",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89047,
      "latitude": 37.722454,
      "longitude": -117.796454,
      "city": "Silverpeak",
      "state": "NV",
      "county": "Esmeralda"
  },
  {
      "zipCode": 89048,
      "latitude": 36.242371,
      "longitude": -116.160572,
      "city": "Pahrump",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89049,
      "latitude": 38.262575,
      "longitude": -116.624808,
      "city": "Tonopah",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89052,
      "latitude": 35.987798,
      "longitude": -115.116652,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89053,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89060,
      "latitude": 36.391556,
      "longitude": -116.057568,
      "city": "Pahrump",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89061,
      "latitude": 36.07011,
      "longitude": -115.906249,
      "city": "Pahrump",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89070,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Indian Springs",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89074,
      "latitude": 36.03637,
      "longitude": -115.081186,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89077,
      "latitude": 36.001143,
      "longitude": -115.122484,
      "city": "Henderson",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89084,
      "latitude": 36.297486,
      "longitude": -115.156932,
      "city": "North Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89086,
      "latitude": 36.2836,
      "longitude": -115.109962,
      "city": "North Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89101,
      "latitude": 36.17372,
      "longitude": -115.10647,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89102,
      "latitude": 36.281327,
      "longitude": -115.390646,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89103,
      "latitude": 36.33689,
      "longitude": -114.891987,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89104,
      "latitude": 36.113372,
      "longitude": -115.106002,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89106,
      "latitude": 36.181169,
      "longitude": -115.167635,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89107,
      "latitude": 36.169069,
      "longitude": -115.208529,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89108,
      "latitude": 36.218667,
      "longitude": -115.214037,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89109,
      "latitude": 35.989223,
      "longitude": -115.40521,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89110,
      "latitude": 36.183652,
      "longitude": -115.125031,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89111,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89112,
      "latitude": 36.157764,
      "longitude": -115.025559,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89113,
      "latitude": 36.057605,
      "longitude": -115.289522,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89114,
      "latitude": 36.011339,
      "longitude": -115.101508,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89115,
      "latitude": 36.220075,
      "longitude": -115.064105,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89116,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89117,
      "latitude": 36.130992,
      "longitude": -115.217932,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89118,
      "latitude": 36.030073,
      "longitude": -115.233131,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89119,
      "latitude": 36.089022,
      "longitude": -115.148313,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89120,
      "latitude": 36.310827,
      "longitude": -115.367158,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89121,
      "latitude": 36.122271,
      "longitude": -115.09153,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89122,
      "latitude": 36.111224,
      "longitude": -114.989134,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89123,
      "latitude": 35.988844,
      "longitude": -115.157513,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89124,
      "latitude": 36.167987,
      "longitude": -115.369128,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89125,
      "latitude": 36.223528,
      "longitude": -115.265529,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89126,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89127,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89128,
      "latitude": 36.119038,
      "longitude": -115.24018,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89129,
      "latitude": 35.794298,
      "longitude": -115.178996,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89130,
      "latitude": 36.246153,
      "longitude": -115.222064,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89131,
      "latitude": 36.343521,
      "longitude": -115.253899,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89132,
      "latitude": 36.018981,
      "longitude": -115.151937,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89133,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89134,
      "latitude": 36.126524,
      "longitude": -115.342404,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89135,
      "latitude": 36.137829,
      "longitude": -115.326081,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89137,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89138,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89139,
      "latitude": 36.012873,
      "longitude": -115.211805,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89141,
      "latitude": 36.010397,
      "longitude": -115.20729,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89142,
      "latitude": 36.147978,
      "longitude": -115.040429,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89143,
      "latitude": 36.317804,
      "longitude": -115.261939,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89144,
      "latitude": 36.178088,
      "longitude": -115.31835,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89145,
      "latitude": 36.169273,
      "longitude": -115.282751,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89146,
      "latitude": 36.142369,
      "longitude": -115.224185,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89147,
      "latitude": 36.159421,
      "longitude": -115.243814,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89148,
      "latitude": 36.062903,
      "longitude": -115.272463,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89149,
      "latitude": 36.276465,
      "longitude": -115.288521,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89150,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89151,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89152,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89153,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89154,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89155,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89156,
      "latitude": 36.20343,
      "longitude": -115.036376,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89159,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89160,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89163,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "The Lakes",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89164,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89170,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89173,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89177,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89180,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89185,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89191,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Nellis Afb",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89193,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89195,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89199,
      "latitude": 35.927901,
      "longitude": -114.972061,
      "city": "Las Vegas",
      "state": "NV",
      "county": "Clark"
  },
  {
      "zipCode": 89301,
      "latitude": 39.314155,
      "longitude": -114.840433,
      "city": "Ely",
      "state": "NV",
      "county": "White Pine"
  },
  {
      "zipCode": 89310,
      "latitude": 40.046976,
      "longitude": -117.19669,
      "city": "Austin",
      "state": "NV",
      "county": "Lander"
  },
  {
      "zipCode": 89311,
      "latitude": 38.955899,
      "longitude": -114.243831,
      "city": "Baker",
      "state": "NV",
      "county": "White Pine"
  },
  {
      "zipCode": 89314,
      "latitude": 39.402924,
      "longitude": -114.977022,
      "city": "Duckwater",
      "state": "NV",
      "county": "White Pine"
  },
  {
      "zipCode": 89315,
      "latitude": 39.332636,
      "longitude": -114.824507,
      "city": "Ely",
      "state": "NV",
      "county": "White Pine"
  },
  {
      "zipCode": 89316,
      "latitude": 39.547797,
      "longitude": -115.993806,
      "city": "Eureka",
      "state": "NV",
      "county": "Eureka"
  },
  {
      "zipCode": 89317,
      "latitude": 38.864122,
      "longitude": -115.006865,
      "city": "Lund",
      "state": "NV",
      "county": "White Pine"
  },
  {
      "zipCode": 89318,
      "latitude": 39.403429,
      "longitude": -114.7791,
      "city": "Mc Gill",
      "state": "NV",
      "county": "White Pine"
  },
  {
      "zipCode": 89319,
      "latitude": 39.342631,
      "longitude": -114.885945,
      "city": "Ruth",
      "state": "NV",
      "county": "White Pine"
  },
  {
      "zipCode": 89402,
      "latitude": 39.239457,
      "longitude": -119.971133,
      "city": "Crystal Bay",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89403,
      "latitude": 39.103007,
      "longitude": -119.482264,
      "city": "Dayton",
      "state": "NV",
      "county": "Lyon"
  },
  {
      "zipCode": 89404,
      "latitude": 41.263287,
      "longitude": -118.174506,
      "city": "Denio",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89405,
      "latitude": 40.613152,
      "longitude": -119.348515,
      "city": "Empire",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89406,
      "latitude": 39.479393,
      "longitude": -118.296879,
      "city": "Fallon",
      "state": "NV",
      "county": "Churchill"
  },
  {
      "zipCode": 89407,
      "latitude": 39.525628,
      "longitude": -118.842431,
      "city": "Fallon",
      "state": "NV",
      "county": "Churchill"
  },
  {
      "zipCode": 89408,
      "latitude": 39.489212,
      "longitude": -119.196644,
      "city": "Fernley",
      "state": "NV",
      "county": "Lyon"
  },
  {
      "zipCode": 89409,
      "latitude": 38.863664,
      "longitude": -117.928173,
      "city": "Gabbs",
      "state": "NV",
      "county": "Nye"
  },
  {
      "zipCode": 89410,
      "latitude": 38.818277,
      "longitude": -119.649498,
      "city": "Gardnerville",
      "state": "NV",
      "county": "Douglas"
  },
  {
      "zipCode": 89411,
      "latitude": 39.034129,
      "longitude": -119.822837,
      "city": "Genoa",
      "state": "NV",
      "county": "Douglas"
  },
  {
      "zipCode": 89412,
      "latitude": 40.652505,
      "longitude": -119.356505,
      "city": "Gerlach",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89413,
      "latitude": 39.050936,
      "longitude": -119.942771,
      "city": "Glenbrook",
      "state": "NV",
      "county": "Douglas"
  },
  {
      "zipCode": 89414,
      "latitude": 40.950867,
      "longitude": -117.494182,
      "city": "Golconda",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89415,
      "latitude": 38.576848,
      "longitude": -118.690644,
      "city": "Hawthorne",
      "state": "NV",
      "county": "Mineral"
  },
  {
      "zipCode": 89418,
      "latitude": 40.649475,
      "longitude": -118.288693,
      "city": "Imlay",
      "state": "NV",
      "county": "Pershing"
  },
  {
      "zipCode": 89419,
      "latitude": 40.251285,
      "longitude": -118.400276,
      "city": "Lovelock",
      "state": "NV",
      "county": "Pershing"
  },
  {
      "zipCode": 89420,
      "latitude": 38.484178,
      "longitude": -118.423999,
      "city": "Luning",
      "state": "NV",
      "county": "Mineral"
  },
  {
      "zipCode": 89421,
      "latitude": 41.970838,
      "longitude": -117.673683,
      "city": "Mc Dermitt",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89422,
      "latitude": 38.387008,
      "longitude": -118.109585,
      "city": "Mina",
      "state": "NV",
      "county": "Mineral"
  },
  {
      "zipCode": 89423,
      "latitude": 39.008718,
      "longitude": -119.729731,
      "city": "Minden",
      "state": "NV",
      "county": "Douglas"
  },
  {
      "zipCode": 89424,
      "latitude": 39.827156,
      "longitude": -119.360545,
      "city": "Nixon",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89425,
      "latitude": 41.263287,
      "longitude": -118.174506,
      "city": "Orovada",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89426,
      "latitude": 41.263287,
      "longitude": -118.174506,
      "city": "Paradise Valley",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89427,
      "latitude": 38.970781,
      "longitude": -118.833128,
      "city": "Schurz",
      "state": "NV",
      "county": "Mineral"
  },
  {
      "zipCode": 89428,
      "latitude": 39.26524,
      "longitude": -119.638802,
      "city": "Silver City",
      "state": "NV",
      "county": "Lyon"
  },
  {
      "zipCode": 89429,
      "latitude": 39.439405,
      "longitude": -119.391468,
      "city": "Silver Springs",
      "state": "NV",
      "county": "Lyon"
  },
  {
      "zipCode": 89430,
      "latitude": 38.773292,
      "longitude": -119.302929,
      "city": "Smith",
      "state": "NV",
      "county": "Lyon"
  },
  {
      "zipCode": 89431,
      "latitude": 40.039169,
      "longitude": -119.640601,
      "city": "Sparks",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89432,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Sparks",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89433,
      "latitude": 39.748304,
      "longitude": -119.804924,
      "city": "Sun Valley",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89434,
      "latitude": 39.591728,
      "longitude": -119.716747,
      "city": "Sparks",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89435,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Sparks",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89436,
      "latitude": 39.651879,
      "longitude": -119.659053,
      "city": "Sparks",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89438,
      "latitude": 41.263287,
      "longitude": -118.174506,
      "city": "Valmy",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89439,
      "latitude": 39.516486,
      "longitude": -119.983252,
      "city": "Verdi",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89440,
      "latitude": 39.332488,
      "longitude": -119.613509,
      "city": "Virginia City",
      "state": "NV",
      "county": "Storey"
  },
  {
      "zipCode": 89442,
      "latitude": 39.639846,
      "longitude": -119.302787,
      "city": "Wadsworth",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89444,
      "latitude": 38.844747,
      "longitude": -119.35228,
      "city": "Wellington",
      "state": "NV",
      "county": "Lyon"
  },
  {
      "zipCode": 89445,
      "latitude": 41.213481,
      "longitude": -117.706799,
      "city": "Winnemucca",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89446,
      "latitude": 41.076358,
      "longitude": -117.760165,
      "city": "Winnemucca",
      "state": "NV",
      "county": "Humboldt"
  },
  {
      "zipCode": 89447,
      "latitude": 39.080415,
      "longitude": -119.228631,
      "city": "Yerington",
      "state": "NV",
      "county": "Lyon"
  },
  {
      "zipCode": 89448,
      "latitude": 39.020403,
      "longitude": -119.911397,
      "city": "Zephyr Cove",
      "state": "NV",
      "county": "Douglas"
  },
  {
      "zipCode": 89449,
      "latitude": 38.860647,
      "longitude": -119.732977,
      "city": "Stateline",
      "state": "NV",
      "county": "Douglas"
  },
  {
      "zipCode": 89450,
      "latitude": 39.256357,
      "longitude": -119.946371,
      "city": "Incline Village",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89451,
      "latitude": 39.253908,
      "longitude": -119.935619,
      "city": "Incline Village",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89452,
      "latitude": 39.259103,
      "longitude": -119.956585,
      "city": "Incline Village",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89494,
      "latitude": "",
      "longitude": "",
      "city": "Sparks",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89496,
      "latitude": 39.537979,
      "longitude": -118.343592,
      "city": "Fallon",
      "state": "NV",
      "county": "Churchill"
  },
  {
      "zipCode": 89501,
      "latitude": 39.65558,
      "longitude": -119.704614,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89502,
      "latitude": 39.579526,
      "longitude": -119.753826,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89503,
      "latitude": 39.547117,
      "longitude": -119.901957,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89504,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89505,
      "latitude": 39.52241,
      "longitude": -119.835275,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89506,
      "latitude": 39.695488,
      "longitude": -119.811146,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89507,
      "latitude": 39.54231,
      "longitude": -119.816374,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89509,
      "latitude": 39.413458,
      "longitude": -119.857022,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89510,
      "latitude": 39.906211,
      "longitude": -119.604367,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89511,
      "latitude": 39.360265,
      "longitude": -119.8057,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89512,
      "latitude": 39.545363,
      "longitude": -119.879069,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89513,
      "latitude": 39.631922,
      "longitude": -119.293722,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89515,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89520,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89523,
      "latitude": 39.549297,
      "longitude": -119.639031,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89533,
      "latitude": 39.543941,
      "longitude": -119.906109,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89557,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89570,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89595,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89599,
      "latitude": 40.541218,
      "longitude": -119.586934,
      "city": "Reno",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89701,
      "latitude": 39.154485,
      "longitude": -119.73074,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89702,
      "latitude": 39.135503,
      "longitude": -119.75875,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89703,
      "latitude": 39.164225,
      "longitude": -119.800294,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89704,
      "latitude": 39.453739,
      "longitude": -119.722803,
      "city": "Washoe Valley",
      "state": "NV",
      "county": "Washoe"
  },
  {
      "zipCode": 89705,
      "latitude": 39.055424,
      "longitude": -119.805916,
      "city": "Carson City",
      "state": "NV",
      "county": "Douglas"
  },
  {
      "zipCode": 89706,
      "latitude": 39.202517,
      "longitude": -119.752626,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89711,
      "latitude": 39.167833,
      "longitude": -119.776409,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89712,
      "latitude": 39.167833,
      "longitude": -119.776409,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89713,
      "latitude": 39.167833,
      "longitude": -119.776409,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89714,
      "latitude": 39.167833,
      "longitude": -119.776409,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89721,
      "latitude": 39.167833,
      "longitude": -119.776409,
      "city": "Carson City",
      "state": "NV",
      "county": "Carson City"
  },
  {
      "zipCode": 89801,
      "latitude": 40.905583,
      "longitude": -115.534429,
      "city": "Elko",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89802,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Elko",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89803,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Elko",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89815,
      "latitude": 40.751905,
      "longitude": -115.595581,
      "city": "Spring Creek",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89820,
      "latitude": 40.042115,
      "longitude": -116.974803,
      "city": "Battle Mountain",
      "state": "NV",
      "county": "Lander"
  },
  {
      "zipCode": 89821,
      "latitude": 40.413793,
      "longitude": -116.581275,
      "city": "Crescent Valley",
      "state": "NV",
      "county": "Eureka"
  },
  {
      "zipCode": 89822,
      "latitude": 40.715931,
      "longitude": -116.103154,
      "city": "Carlin",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89823,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Deeth",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89824,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Halleck",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89825,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Jackpot",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89826,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Jarbidge",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89828,
      "latitude": 40.744704,
      "longitude": -115.554233,
      "city": "Lamoille",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89830,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Montello",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89831,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Mountain City",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89832,
      "latitude": 41.74828,
      "longitude": -116.007157,
      "city": "Owyhee",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89833,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Ruby Valley",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89834,
      "latitude": 41.059414,
      "longitude": -115.529596,
      "city": "Tuscarora",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89835,
      "latitude": 41.543923,
      "longitude": -114.821761,
      "city": "Wells",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 89883,
      "latitude": 40.504545,
      "longitude": -114.412635,
      "city": "West Wendover",
      "state": "NV",
      "county": "Elko"
  },
  {
      "zipCode": 90001,
      "latitude": 33.973951,
      "longitude": -118.248405,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90002,
      "latitude": 33.950514,
      "longitude": -118.245855,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90003,
      "latitude": 33.949164,
      "longitude": -118.273156,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90004,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90005,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90006,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90007,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90008,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90009,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90010,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90011,
      "latitude": 33.989264,
      "longitude": -118.250056,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90012,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90013,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90014,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90015,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90016,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90017,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90018,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90019,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90020,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90021,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90022,
      "latitude": 33.896564,
      "longitude": -118.176502,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90023,
      "latitude": 34.008262,
      "longitude": -118.196055,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90024,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90025,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90026,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90027,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90028,
      "latitude": 34.014613,
      "longitude": -118.225606,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90029,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90030,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90031,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90032,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90033,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90034,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90035,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90036,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90037,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90038,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90039,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90040,
      "latitude": 33.990863,
      "longitude": -118.153153,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90041,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90042,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90043,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90044,
      "latitude": 33.938108,
      "longitude": -118.285706,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90045,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90046,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90047,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90048,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90049,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90050,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90051,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90052,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90053,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90054,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90055,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90056,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90057,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90058,
      "latitude": 33.999433,
      "longitude": -118.213255,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90059,
      "latitude": 33.921065,
      "longitude": -118.244955,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90060,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90061,
      "latitude": 33.920965,
      "longitude": -118.275356,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90062,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90063,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90064,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90065,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90066,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90067,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90068,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90069,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "West Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90070,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90071,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90072,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90073,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90074,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90075,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90076,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90077,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90078,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90079,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90080,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90081,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90082,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90083,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90084,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90086,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90087,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90088,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90089,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90091,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90093,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90094,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90095,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90096,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90097,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90099,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90101,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90102,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90103,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90174,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90185,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Los Angeles",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90201,
      "latitude": 33.976663,
      "longitude": -118.168903,
      "city": "Bell",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90202,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Bell Gardens",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90209,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Beverly Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90210,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Beverly Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90211,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Beverly Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90212,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Beverly Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90213,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Beverly Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90220,
      "latitude": 33.874815,
      "longitude": -118.240208,
      "city": "Compton",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90221,
      "latitude": 33.879565,
      "longitude": -118.21681,
      "city": "Compton",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90222,
      "latitude": 33.909915,
      "longitude": -118.235654,
      "city": "Compton",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90223,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Compton",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90224,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Compton",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90230,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Culver City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90231,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Culver City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90232,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Culver City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90233,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Culver City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90239,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Downey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90240,
      "latitude": 33.946363,
      "longitude": -118.119461,
      "city": "Downey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90241,
      "latitude": 33.939164,
      "longitude": -118.125851,
      "city": "Downey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90242,
      "latitude": 33.922714,
      "longitude": -118.139969,
      "city": "Downey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90245,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "El Segundo",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90247,
      "latitude": 33.888315,
      "longitude": -118.295256,
      "city": "Gardena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90248,
      "latitude": 33.877165,
      "longitude": -118.269605,
      "city": "Gardena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90249,
      "latitude": 33.858866,
      "longitude": -118.299106,
      "city": "Gardena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90250,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Hawthorne",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90251,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Hawthorne",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90254,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Hermosa Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90255,
      "latitude": 33.977987,
      "longitude": -118.213137,
      "city": "Huntington Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90260,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lawndale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90261,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lawndale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90262,
      "latitude": 33.924538,
      "longitude": -118.202954,
      "city": "Lynwood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90263,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Malibu",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90264,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Malibu",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90265,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Malibu",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90266,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Manhattan Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90267,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Manhattan Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90270,
      "latitude": 33.988463,
      "longitude": -118.187376,
      "city": "Maywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90272,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pacific Palisades",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90274,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Palos Verdes Peninsula",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90275,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Rancho Palos Verdes",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90277,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Redondo Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90278,
      "latitude": 33.987613,
      "longitude": -118.179754,
      "city": "Redondo Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90280,
      "latitude": 33.937714,
      "longitude": -118.193403,
      "city": "South Gate",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90290,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Topanga",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90291,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Venice",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90292,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Marina Del Rey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90293,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Playa Del Rey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90294,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Venice",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90295,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Marina Del Rey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90296,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Playa Del Rey",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90301,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90302,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90303,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90304,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90305,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90306,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90307,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90308,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90309,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90310,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90311,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90312,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90313,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90397,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90398,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Inglewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90401,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90402,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90403,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90404,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90405,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90406,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90407,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90408,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90409,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90410,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90411,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Monica",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90501,
      "latitude": 33.835665,
      "longitude": -118.303805,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90502,
      "latitude": 33.833816,
      "longitude": -118.293405,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90503,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90504,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90505,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90506,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90507,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90508,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90509,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90510,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Torrance",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90601,
      "latitude": 34.004311,
      "longitude": -118.0441,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90602,
      "latitude": 33.971839,
      "longitude": -118.036578,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90603,
      "latitude": 33.947372,
      "longitude": -117.995297,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90604,
      "latitude": 33.932663,
      "longitude": -118.009085,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90605,
      "latitude": 33.944312,
      "longitude": -118.017424,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90606,
      "latitude": 33.967162,
      "longitude": -118.036999,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90607,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90608,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90609,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90610,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90612,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Whittier",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90620,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Buena Park",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90621,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Buena Park",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90622,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Buena Park",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90623,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "La Palma",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90624,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Buena Park",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90630,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Cypress",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90631,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "La Habra",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90632,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "La Habra",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90633,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "La Habra",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90637,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Mirada",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90638,
      "latitude": 33.900863,
      "longitude": -118.007297,
      "city": "La Mirada",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90639,
      "latitude": 33.905813,
      "longitude": -118.018247,
      "city": "La Mirada",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90640,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Montebello",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90650,
      "latitude": 33.906763,
      "longitude": -118.076549,
      "city": "Norwalk",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90651,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Norwalk",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90652,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Norwalk",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90659,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Norwalk",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90660,
      "latitude": 33.985812,
      "longitude": -118.088787,
      "city": "Pico Rivera",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90661,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pico Rivera",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90662,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pico Rivera",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90665,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pico Rivera",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90670,
      "latitude": 33.927063,
      "longitude": -118.063698,
      "city": "Santa Fe Springs",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90671,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Fe Springs",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90680,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Stanton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90701,
      "latitude": 33.865395,
      "longitude": -118.073148,
      "city": "Artesia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90702,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Artesia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90703,
      "latitude": 33.866914,
      "longitude": -118.068648,
      "city": "Cerritos",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90704,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Avalon",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90706,
      "latitude": 33.888014,
      "longitude": -118.12965,
      "city": "Bellflower",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90707,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Bellflower",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90710,
      "latitude": 33.791267,
      "longitude": -118.251554,
      "city": "Harbor City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90711,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lakewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90712,
      "latitude": 33.845624,
      "longitude": -118.14645,
      "city": "Lakewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90713,
      "latitude": 33.850465,
      "longitude": -118.109399,
      "city": "Lakewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90714,
      "latitude": 33.851215,
      "longitude": -118.1339,
      "city": "Lakewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90715,
      "latitude": 33.840565,
      "longitude": -118.078748,
      "city": "Lakewood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90716,
      "latitude": 33.830967,
      "longitude": -118.072796,
      "city": "Hawaiian Gardens",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90717,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lomita",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90720,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Los Alamitos",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90721,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Los Alamitos",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90723,
      "latitude": 33.899015,
      "longitude": -118.165152,
      "city": "Paramount",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90731,
      "latitude": 33.753088,
      "longitude": -118.253053,
      "city": "San Pedro",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90732,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Pedro",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90733,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Pedro",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90734,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Pedro",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90740,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Seal Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90742,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Sunset Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90743,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Surfside",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 90744,
      "latitude": 33.779417,
      "longitude": -118.253853,
      "city": "Wilmington",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90745,
      "latitude": 33.813317,
      "longitude": -118.261154,
      "city": "Carson",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90746,
      "latitude": 33.859716,
      "longitude": -118.255204,
      "city": "Carson",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90747,
      "latitude": 33.867138,
      "longitude": -118.253825,
      "city": "Carson",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90748,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Wilmington",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90749,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Carson",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90801,
      "latitude": 33.804309,
      "longitude": -118.200957,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90802,
      "latitude": 33.756024,
      "longitude": -118.201101,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90803,
      "latitude": 33.759885,
      "longitude": -118.13016,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90804,
      "latitude": 33.785666,
      "longitude": -118.135699,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90805,
      "latitude": 33.838815,
      "longitude": -118.1576,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90806,
      "latitude": 33.795016,
      "longitude": -118.172801,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90807,
      "latitude": 33.831466,
      "longitude": -118.181102,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90808,
      "latitude": 33.800266,
      "longitude": -118.106132,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90809,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90810,
      "latitude": 33.819266,
      "longitude": -118.232453,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90813,
      "latitude": 33.780017,
      "longitude": -118.183701,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90814,
      "latitude": 33.771301,
      "longitude": -118.142654,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90815,
      "latitude": 33.793516,
      "longitude": -118.114612,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90822,
      "latitude": 33.792666,
      "longitude": -118.1638,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90831,
      "latitude": 33.767817,
      "longitude": -118.199401,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90832,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90833,
      "latitude": 33.767817,
      "longitude": -118.199401,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90834,
      "latitude": 33.767817,
      "longitude": -118.199401,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90835,
      "latitude": 33.767817,
      "longitude": -118.199401,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90840,
      "latitude": 33.784266,
      "longitude": -118.115698,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90842,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90844,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90845,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90846,
      "latitude": 33.824918,
      "longitude": -118.150352,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90847,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90848,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90853,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90888,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 90899,
      "latitude": 33.767413,
      "longitude": -118.199414,
      "city": "Long Beach",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91001,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Altadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91003,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Altadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91006,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Arcadia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91007,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Arcadia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91009,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Duarte",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91010,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Duarte",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91011,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Canada Flintridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91012,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Canada Flintridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91016,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Monrovia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91017,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Monrovia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91020,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Montrose",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91021,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Montrose",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91023,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Mount Wilson",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91024,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sierra Madre",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91025,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sierra Madre",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91030,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "South Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91031,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "South Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91040,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sunland",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91041,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sunland",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91042,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Tujunga",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91043,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Tujunga",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91046,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Verdugo City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91050,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91051,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91066,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Arcadia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91077,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Arcadia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91101,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91102,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91103,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91104,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91105,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91106,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91107,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91108,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Marino",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91109,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91110,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91114,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91115,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91116,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91117,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91118,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Marino",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91121,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91123,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91124,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91125,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91126,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91129,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91131,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91175,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91182,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91184,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91185,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91186,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91187,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91188,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91189,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91191,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pasadena",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91201,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91202,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91203,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91204,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91205,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91206,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91207,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91208,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91209,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91210,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91214,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Crescenta",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91221,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91222,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91224,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Crescenta",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91225,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91226,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91301,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Agoura Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91302,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Calabasas",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91303,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Canoga Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91304,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Canoga Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91305,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Canoga Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91306,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Winnetka",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91307,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "West Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91308,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "West Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91309,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Canoga Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91310,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Castaic",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91311,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Chatsworth",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91312,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Chatsworth",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91313,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Chatsworth",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91316,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Encino",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91319,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Newbury Park",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91320,
      "latitude": 34.175921,
      "longitude": -118.905874,
      "city": "Newbury Park",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91321,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Newhall",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91322,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Newhall",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91324,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Northridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91325,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Northridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91326,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Northridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91327,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Northridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91328,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Northridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91329,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Northridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91330,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Northridge",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91331,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pacoima",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91333,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pacoima",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91334,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pacoima",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91335,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Reseda",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91337,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Reseda",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91340,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Fernando",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91341,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Fernando",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91342,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sylmar",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91343,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91344,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Granada Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91345,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Mission Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91346,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Mission Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91350,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Clarita",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91351,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Canyon Country",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91352,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sun Valley",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91353,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sun Valley",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91354,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Valencia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91355,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Valencia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91356,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Tarzana",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91357,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Tarzana",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91358,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Thousand Oaks",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91359,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Westlake Village",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91360,
      "latitude": 34.213403,
      "longitude": -118.800204,
      "city": "Thousand Oaks",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91361,
      "latitude": 34.141783,
      "longitude": -118.847732,
      "city": "Westlake Village",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91362,
      "latitude": 34.194756,
      "longitude": -118.823177,
      "city": "Thousand Oaks",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91363,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Westlake Village",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91364,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Woodland Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91365,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Woodland Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91367,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Woodland Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91371,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Woodland Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91372,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Calabasas",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91376,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Agoura Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91377,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Oak Park",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 91380,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Clarita",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91381,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Stevenson Ranch",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91382,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Clarita",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91383,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Santa Clarita",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91384,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Castaic",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91385,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Valencia",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91386,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Canyon Country",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91387,
      "latitude": 34.402519,
      "longitude": -118.363463,
      "city": "Canyon Country",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91388,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91390,
      "latitude": 34.576515,
      "longitude": -118.492764,
      "city": "Santa Clarita",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91392,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sylmar",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91393,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91394,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Granada Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91395,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Mission Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91396,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Winnetka",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91399,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Woodland Hills",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91401,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91402,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Panorama City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91403,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sherman Oaks",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91404,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91405,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91406,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91407,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91408,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91409,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91410,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91411,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91412,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Panorama City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91413,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sherman Oaks",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91416,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Encino",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91423,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sherman Oaks",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91426,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Encino",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91436,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Encino",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91470,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91482,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91495,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Sherman Oaks",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91496,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91497,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91499,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Van Nuys",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91501,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91502,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91503,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91504,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91505,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91506,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91507,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91508,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91510,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91521,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91522,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91523,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91526,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Burbank",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91601,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91602,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91603,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91604,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Studio City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91605,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91606,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91607,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Valley Village",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91608,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Universal City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91609,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91610,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Toluca Lake",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91611,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91612,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91614,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Studio City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91615,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91616,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91617,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Valley Village",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91618,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "North Hollywood",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91701,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Rancho Cucamonga",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91702,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Azusa",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91706,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Baldwin Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91708,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Chino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91709,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Chino Hills",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91710,
      "latitude": 34.159844,
      "longitude": -114.30117,
      "city": "Chino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91711,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Claremont",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91714,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "City Of Industry",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91715,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "City Of Industry",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91716,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "City Of Industry",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91722,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Covina",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91723,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Covina",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91724,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Covina",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91729,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Rancho Cucamonga",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91730,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Rancho Cucamonga",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91731,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "El Monte",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91732,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "El Monte",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91733,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "South El Monte",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91734,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "El Monte",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91735,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "El Monte",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91737,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Rancho Cucamonga",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91739,
      "latitude": 34.122307,
      "longitude": -116.247005,
      "city": "Rancho Cucamonga",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91740,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendora",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91741,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Glendora",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91743,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Guasti",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91744,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Puente",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91745,
      "latitude": 33.976114,
      "longitude": -117.981631,
      "city": "Hacienda Heights",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91746,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Puente",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91747,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Puente",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91748,
      "latitude": 33.966218,
      "longitude": -117.91736,
      "city": "Rowland Heights",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91749,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Puente",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91750,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "La Verne",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91752,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Mira Loma",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 91754,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Monterey Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91755,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Monterey Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91756,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Monterey Park",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91758,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Ontario",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91759,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Mt Baldy",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91761,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Ontario",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91762,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Ontario",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91763,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Montclair",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91764,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Ontario",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91765,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Diamond Bar",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91766,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pomona",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91767,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pomona",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91768,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pomona",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91769,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pomona",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91770,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Rosemead",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91771,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Rosemead",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91772,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Rosemead",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91773,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Dimas",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91775,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Gabriel",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91776,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Gabriel",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91778,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "San Gabriel",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91780,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Temple City",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91784,
      "latitude": 34.128118,
      "longitude": -116.246997,
      "city": "Upland",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91785,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Upland",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91786,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Upland",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91788,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Walnut",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91789,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Walnut",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91790,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "West Covina",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91791,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "West Covina",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91792,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "West Covina",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91793,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "West Covina",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91795,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Walnut",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91797,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pomona",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91798,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Ontario",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 91799,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pomona",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91801,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Alhambra",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91802,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Alhambra",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91803,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Alhambra",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91804,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Alhambra",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91841,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Alhambra",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91896,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Alhambra",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91899,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Alhambra",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 91901,
      "latitude": 32.74081,
      "longitude": -116.976341,
      "city": "Alpine",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91902,
      "latitude": 32.67387,
      "longitude": -117.020668,
      "city": "Bonita",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91903,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Alpine",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91905,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Boulevard",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91906,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Campo",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91908,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Bonita",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91909,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91910,
      "latitude": 32.636922,
      "longitude": -117.050072,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91911,
      "latitude": 32.615983,
      "longitude": -117.034012,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91912,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91913,
      "latitude": 32.639967,
      "longitude": -116.984669,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91914,
      "latitude": 32.656159,
      "longitude": -116.966139,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91915,
      "latitude": 32.629234,
      "longitude": -116.948228,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91916,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Descanso",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91917,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Dulzura",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91921,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Chula Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91931,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Guatay",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91932,
      "latitude": 32.578991,
      "longitude": -117.117351,
      "city": "Imperial Beach",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91933,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Imperial Beach",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91934,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Jacumba",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91935,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Jamul",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91941,
      "latitude": 32.76105,
      "longitude": -116.998102,
      "city": "La Mesa",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91942,
      "latitude": 32.781084,
      "longitude": -117.018638,
      "city": "La Mesa",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91943,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "La Mesa",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91944,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "La Mesa",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91945,
      "latitude": 32.732288,
      "longitude": -117.037344,
      "city": "Lemon Grove",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91946,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Lemon Grove",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91947,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Lincoln Acres",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91948,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Mount Laguna",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91950,
      "latitude": 32.671194,
      "longitude": -117.084353,
      "city": "National City",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91951,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "National City",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91962,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Pine Valley",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91963,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Potrero",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91976,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Spring Valley",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91977,
      "latitude": 32.718352,
      "longitude": -117.000109,
      "city": "Spring Valley",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91978,
      "latitude": 32.733452,
      "longitude": -116.957898,
      "city": "Spring Valley",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91979,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Spring Valley",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91980,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Tecate",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91987,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Tecate",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 91990,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Potrero",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92003,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Bonsall",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92004,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Borrego Springs",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92007,
      "latitude": 33.023042,
      "longitude": -117.274469,
      "city": "Cardiff By The Sea",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92008,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Carlsbad",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92009,
      "latitude": 33.082192,
      "longitude": -117.267169,
      "city": "Carlsbad",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92013,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Carlsbad",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92014,
      "latitude": 32.974074,
      "longitude": -117.224167,
      "city": "Del Mar",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92018,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Carlsbad",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92019,
      "latitude": 32.865113,
      "longitude": -117.041287,
      "city": "El Cajon",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92020,
      "latitude": 32.787514,
      "longitude": -116.960836,
      "city": "El Cajon",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92021,
      "latitude": 32.822138,
      "longitude": -116.885508,
      "city": "El Cajon",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92022,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "El Cajon",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92023,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Encinitas",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92024,
      "latitude": 33.054154,
      "longitude": -117.250253,
      "city": "Encinitas",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92025,
      "latitude": 33.057128,
      "longitude": -117.083403,
      "city": "Escondido",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92026,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Escondido",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92027,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Escondido",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92028,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Fallbrook",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92029,
      "latitude": 33.071941,
      "longitude": -117.158497,
      "city": "Escondido",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92030,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Escondido",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92033,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Escondido",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92036,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Julian",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92037,
      "latitude": 32.898511,
      "longitude": -117.225744,
      "city": "La Jolla",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92038,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "La Jolla",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92039,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "La Jolla",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92040,
      "latitude": 32.893498,
      "longitude": -116.898945,
      "city": "Lakeside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92046,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Escondido",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92049,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Oceanside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92051,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Oceanside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92052,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Oceanside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92054,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Oceanside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92055,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Camp Pendleton",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92056,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Oceanside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92057,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Oceanside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92058,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Oceanside",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92059,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Pala",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92060,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Palomar Mountain",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92061,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Pauma Valley",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92064,
      "latitude": 32.994097,
      "longitude": -117.030299,
      "city": "Poway",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92065,
      "latitude": 33.031572,
      "longitude": -116.976549,
      "city": "Ramona",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92066,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Ranchita",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92067,
      "latitude": 33.005026,
      "longitude": -117.21569,
      "city": "Rancho Santa Fe",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92068,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Luis Rey",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92069,
      "latitude": 33.099573,
      "longitude": -117.215112,
      "city": "San Marcos",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92070,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Santa Ysabel",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92071,
      "latitude": 32.843956,
      "longitude": -116.994511,
      "city": "Santee",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92072,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Santee",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92074,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Poway",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92075,
      "latitude": 33.007075,
      "longitude": -117.256769,
      "city": "Solana Beach",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92078,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Marcos",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92079,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Marcos",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92082,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Valley Center",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92083,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92084,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92085,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Vista",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92086,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Warner Springs",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92088,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Fallbrook",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92090,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "El Cajon",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92091,
      "latitude": 32.962307,
      "longitude": -117.046183,
      "city": "Rancho Santa Fe",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92092,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "La Jolla",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92093,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "La Jolla",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92096,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Marcos",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92101,
      "latitude": 32.7211,
      "longitude": -117.174366,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92102,
      "latitude": 32.71712,
      "longitude": -117.118604,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92103,
      "latitude": 32.746085,
      "longitude": -117.170517,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92104,
      "latitude": 32.73875,
      "longitude": -117.128908,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92105,
      "latitude": 32.7374,
      "longitude": -117.087313,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92106,
      "latitude": 32.709355,
      "longitude": -117.232764,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92107,
      "latitude": 32.735,
      "longitude": -117.202669,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92108,
      "latitude": 32.774699,
      "longitude": -117.148616,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92109,
      "latitude": 32.794748,
      "longitude": -117.239392,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92110,
      "latitude": 32.775849,
      "longitude": -117.207965,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92111,
      "latitude": 32.803717,
      "longitude": -117.16643,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92112,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92113,
      "latitude": 32.694601,
      "longitude": -117.121678,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92114,
      "latitude": 32.70614,
      "longitude": -117.051651,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92115,
      "latitude": 32.7576,
      "longitude": -117.070688,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92116,
      "latitude": 32.7654,
      "longitude": -117.127894,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92117,
      "latitude": 32.818398,
      "longitude": -117.197168,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92118,
      "latitude": 32.656176,
      "longitude": -117.160815,
      "city": "Coronado",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92119,
      "latitude": 32.815849,
      "longitude": -117.033162,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92120,
      "latitude": 32.794947,
      "longitude": -117.079863,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92121,
      "latitude": 32.90391,
      "longitude": -117.207828,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92122,
      "latitude": 32.856847,
      "longitude": -117.208027,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92123,
      "latitude": 32.810983,
      "longitude": -117.136812,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92124,
      "latitude": 32.818733,
      "longitude": -117.082387,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92126,
      "latitude": 32.899613,
      "longitude": -117.144916,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92127,
      "latitude": 33.021243,
      "longitude": -117.120915,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92128,
      "latitude": 32.995484,
      "longitude": -117.07483,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92129,
      "latitude": 32.965012,
      "longitude": -117.126619,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92130,
      "latitude": 32.952467,
      "longitude": -117.161871,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92131,
      "latitude": 32.874916,
      "longitude": -117.088322,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92132,
      "latitude": 32.643703,
      "longitude": -117.138414,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92133,
      "latitude": 32.733507,
      "longitude": -117.216451,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92134,
      "latitude": 32.562106,
      "longitude": -117.07166,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92135,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92136,
      "latitude": 32.68342,
      "longitude": -117.121913,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92137,
      "latitude": 32.85377,
      "longitude": -117.119744,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92138,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92139,
      "latitude": 32.68018,
      "longitude": -117.047627,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92140,
      "latitude": 32.74344,
      "longitude": -117.200412,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92142,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92143,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Ysidro",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92145,
      "latitude": 32.889139,
      "longitude": -117.100486,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92147,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92149,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92150,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92152,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92153,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92154,
      "latitude": 32.628038,
      "longitude": -117.035225,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92155,
      "latitude": 32.671602,
      "longitude": -117.165665,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92158,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92159,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92160,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92161,
      "latitude": 32.871846,
      "longitude": -117.229119,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92162,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92163,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92164,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92165,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92166,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92167,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92168,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92169,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92170,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92171,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92172,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92173,
      "latitude": 32.566356,
      "longitude": -116.997475,
      "city": "San Ysidro",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92174,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92175,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92176,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92177,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92178,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "Coronado",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92179,
      "latitude": 32.572602,
      "longitude": -116.918724,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92182,
      "latitude": 32.775088,
      "longitude": -117.076176,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92184,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92186,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92187,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92190,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92191,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92192,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92193,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92194,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92195,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92196,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92197,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92198,
      "latitude": 33.016928,
      "longitude": -116.846046,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92199,
      "latitude": 32.751575,
      "longitude": -117.191848,
      "city": "San Diego",
      "state": "CA",
      "county": "San Diego"
  },
  {
      "zipCode": 92201,
      "latitude": 33.728721,
      "longitude": -116.035705,
      "city": "Indio",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92202,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Indio",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92203,
      "latitude": 33.75317,
      "longitude": -116.26764,
      "city": "Indio",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92210,
      "latitude": 33.70271,
      "longitude": -116.303759,
      "city": "Indian Wells",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92211,
      "latitude": 33.76437,
      "longitude": -116.339766,
      "city": "Palm Desert",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92220,
      "latitude": 33.919215,
      "longitude": -116.864197,
      "city": "Banning",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92222,
      "latitude": 32.782242,
      "longitude": -114.561905,
      "city": "Bard",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92223,
      "latitude": 33.92703,
      "longitude": -116.954753,
      "city": "Beaumont",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92225,
      "latitude": 33.756749,
      "longitude": -115.723001,
      "city": "Blythe",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92226,
      "latitude": 33.59869,
      "longitude": -114.652517,
      "city": "Blythe",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92227,
      "latitude": 33.096382,
      "longitude": -115.280168,
      "city": "Brawley",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92230,
      "latitude": 33.842969,
      "longitude": -116.607126,
      "city": "Cabazon",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92231,
      "latitude": 32.946832,
      "longitude": -115.518355,
      "city": "Calexico",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92232,
      "latitude": 33.026203,
      "longitude": -115.284581,
      "city": "Calexico",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92233,
      "latitude": 33.157664,
      "longitude": -115.510284,
      "city": "Calipatria",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92234,
      "latitude": 33.647301,
      "longitude": -116.277152,
      "city": "Cathedral City",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92235,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Cathedral City",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92236,
      "latitude": 33.646405,
      "longitude": -116.143588,
      "city": "Coachella",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92239,
      "latitude": 33.809041,
      "longitude": -115.366577,
      "city": "Desert Center",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92240,
      "latitude": 33.900103,
      "longitude": -116.400701,
      "city": "Desert Hot Springs",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92241,
      "latitude": 33.876265,
      "longitude": -116.354024,
      "city": "Desert Hot Springs",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92242,
      "latitude": 34.201506,
      "longitude": -115.647775,
      "city": "Earp",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92243,
      "latitude": 32.900509,
      "longitude": -115.503842,
      "city": "El Centro",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92244,
      "latitude": 32.79475,
      "longitude": -115.692714,
      "city": "El Centro",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92249,
      "latitude": 32.72181,
      "longitude": -115.438271,
      "city": "Heber",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92250,
      "latitude": 32.867317,
      "longitude": -115.374769,
      "city": "Holtville",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92251,
      "latitude": 33.008903,
      "longitude": -115.640538,
      "city": "Imperial",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92252,
      "latitude": 34.175713,
      "longitude": -116.268415,
      "city": "Joshua Tree",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92253,
      "latitude": 33.670978,
      "longitude": -116.259176,
      "city": "La Quinta",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92254,
      "latitude": 33.545034,
      "longitude": -116.018731,
      "city": "Mecca",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92255,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Palm Desert",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92256,
      "latitude": 34.146607,
      "longitude": -116.371498,
      "city": "Morongo Valley",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92257,
      "latitude": 33.124361,
      "longitude": -115.641926,
      "city": "Niland",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92258,
      "latitude": 33.924967,
      "longitude": -116.549645,
      "city": "North Palm Springs",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92259,
      "latitude": 32.98097,
      "longitude": -115.823448,
      "city": "Ocotillo",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92260,
      "latitude": 33.680623,
      "longitude": -116.402695,
      "city": "Palm Desert",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92261,
      "latitude": 33.660374,
      "longitude": -116.408249,
      "city": "Palm Desert",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92262,
      "latitude": 33.842567,
      "longitude": -116.527996,
      "city": "Palm Springs",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92263,
      "latitude": 33.761076,
      "longitude": -116.535887,
      "city": "Palm Springs",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92264,
      "latitude": 33.797145,
      "longitude": -116.511883,
      "city": "Palm Springs",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92266,
      "latitude": 33.369594,
      "longitude": -114.735544,
      "city": "Palo Verde",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92267,
      "latitude": 34.249419,
      "longitude": -114.210609,
      "city": "Parker Dam",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92268,
      "latitude": 34.188703,
      "longitude": -116.504806,
      "city": "Pioneertown",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92270,
      "latitude": 33.703598,
      "longitude": -116.431961,
      "city": "Rancho Mirage",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92273,
      "latitude": 32.79413,
      "longitude": -115.694768,
      "city": "Seeley",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92274,
      "latitude": 33.5578,
      "longitude": -116.157152,
      "city": "Thermal",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92275,
      "latitude": 33.309217,
      "longitude": -115.957782,
      "city": "Salton City",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92276,
      "latitude": 33.843837,
      "longitude": -116.393413,
      "city": "Thousand Palms",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92277,
      "latitude": 34.234411,
      "longitude": -116.235073,
      "city": "Twentynine Palms",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92278,
      "latitude": 34.311015,
      "longitude": -115.277758,
      "city": "Twentynine Palms",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92280,
      "latitude": 34.200026,
      "longitude": -115.286691,
      "city": "Vidal",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92281,
      "latitude": 33.037976,
      "longitude": -115.591387,
      "city": "Westmorland",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92282,
      "latitude": 33.945145,
      "longitude": -116.649937,
      "city": "White Water",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92283,
      "latitude": 32.981774,
      "longitude": -114.685417,
      "city": "Winterhaven",
      "state": "CA",
      "county": "Imperial"
  },
  {
      "zipCode": 92284,
      "latitude": 34.451263,
      "longitude": -115.896753,
      "city": "Yucca Valley",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92285,
      "latitude": 34.310323,
      "longitude": -116.524124,
      "city": "Landers",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92286,
      "latitude": 34.180251,
      "longitude": -116.350003,
      "city": "Yucca Valley",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92292,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Palm Springs",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92301,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Adelanto",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92304,
      "latitude": 34.6122,
      "longitude": -115.800458,
      "city": "Amboy",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92305,
      "latitude": 34.153818,
      "longitude": -116.512262,
      "city": "Angelus Oaks",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92307,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Apple Valley",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92308,
      "latitude": 34.450143,
      "longitude": -116.726751,
      "city": "Apple Valley",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92309,
      "latitude": 35.445323,
      "longitude": -116.142146,
      "city": "Baker",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92310,
      "latitude": 35.262453,
      "longitude": -116.696561,
      "city": "Fort Irwin",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92311,
      "latitude": 34.693608,
      "longitude": -115.851819,
      "city": "Barstow",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92312,
      "latitude": 34.201638,
      "longitude": -116.906215,
      "city": "Barstow",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92313,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Grand Terrace",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92314,
      "latitude": 34.50514,
      "longitude": -116.110086,
      "city": "Big Bear City",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92315,
      "latitude": 34.223224,
      "longitude": -116.847414,
      "city": "Big Bear Lake",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92316,
      "latitude": 34.248404,
      "longitude": -116.849826,
      "city": "Bloomington",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92317,
      "latitude": 34.211175,
      "longitude": -117.079624,
      "city": "Blue Jay",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92318,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Bryn Mawr",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92320,
      "latitude": 33.976814,
      "longitude": -117.038262,
      "city": "Calimesa",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92321,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Cedar Glen",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92322,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Cedarpines Park",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92323,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Cima",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92324,
      "latitude": 34.151161,
      "longitude": -116.900557,
      "city": "Colton",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92325,
      "latitude": 34.850671,
      "longitude": -116.701168,
      "city": "Crestline",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92326,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Crest Park",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92327,
      "latitude": 34.899805,
      "longitude": -116.77035,
      "city": "Daggett",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92328,
      "latitude": 36.235817,
      "longitude": -117.144513,
      "city": "Death Valley",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 92329,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Phelan",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92332,
      "latitude": 34.588133,
      "longitude": -115.577138,
      "city": "Essex",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92333,
      "latitude": 34.258346,
      "longitude": -116.951508,
      "city": "Fawnskin",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92334,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Fontana",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92335,
      "latitude": 34.159844,
      "longitude": -114.301225,
      "city": "Fontana",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92336,
      "latitude": 34.122307,
      "longitude": -116.247005,
      "city": "Fontana",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92337,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Fontana",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92338,
      "latitude": 34.932852,
      "longitude": -115.802525,
      "city": "Ludlow",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92339,
      "latitude": 34.133057,
      "longitude": -116.911711,
      "city": "Forest Falls",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92340,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Hesperia",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92341,
      "latitude": 34.241137,
      "longitude": -116.938548,
      "city": "Green Valley Lake",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92342,
      "latitude": 34.496921,
      "longitude": -116.378323,
      "city": "Helendale",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92345,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Hesperia",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92346,
      "latitude": 34.156543,
      "longitude": -117.14027,
      "city": "Highland",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92347,
      "latitude": 35.012622,
      "longitude": -116.191575,
      "city": "Hinkley",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92350,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Loma Linda",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92352,
      "latitude": 34.209221,
      "longitude": -117.129213,
      "city": "Lake Arrowhead",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92354,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Loma Linda",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92356,
      "latitude": 34.770403,
      "longitude": -116.206281,
      "city": "Lucerne Valley",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92357,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Loma Linda",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92358,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Lytle Creek",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92359,
      "latitude": 34.470317,
      "longitude": -115.969627,
      "city": "Mentone",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92363,
      "latitude": 34.642102,
      "longitude": -115.558733,
      "city": "Needles",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92364,
      "latitude": 35.337173,
      "longitude": -115.628153,
      "city": "Nipton",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92365,
      "latitude": 34.90534,
      "longitude": -115.932613,
      "city": "Newberry Springs",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92366,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Mountain Pass",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92368,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Oro Grande",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92369,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Patton",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92371,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Phelan",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92372,
      "latitude": 34.237294,
      "longitude": -116.855056,
      "city": "Pinon Hills",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92373,
      "latitude": 34.2409,
      "longitude": -116.889474,
      "city": "Redlands",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92374,
      "latitude": 34.153575,
      "longitude": -116.898232,
      "city": "Redlands",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92375,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Redlands",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92376,
      "latitude": 34.202339,
      "longitude": -115.567483,
      "city": "Rialto",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92377,
      "latitude": 34.141501,
      "longitude": -116.982871,
      "city": "Rialto",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92378,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Rimforest",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92382,
      "latitude": 34.210229,
      "longitude": -117.110941,
      "city": "Running Springs",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92384,
      "latitude": 35.977703,
      "longitude": -116.270561,
      "city": "Shoshone",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 92385,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Skyforest",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92386,
      "latitude": 34.247205,
      "longitude": -116.818764,
      "city": "Sugarloaf",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92389,
      "latitude": 36.626033,
      "longitude": -117.218616,
      "city": "Tecopa",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 92391,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Twin Peaks",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92392,
      "latitude": 34.491985,
      "longitude": -114.754916,
      "city": "Victorville",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92393,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Victorville",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92394,
      "latitude": 34.527103,
      "longitude": -115.172471,
      "city": "Victorville",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92397,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Wrightwood",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92398,
      "latitude": 34.926948,
      "longitude": -116.709279,
      "city": "Yermo",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92399,
      "latitude": 34.06226,
      "longitude": -116.971138,
      "city": "Yucaipa",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92401,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92402,
      "latitude": 34.21392,
      "longitude": -117.127166,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92403,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92404,
      "latitude": 34.159076,
      "longitude": -116.709044,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92405,
      "latitude": 34.134794,
      "longitude": -116.228393,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92406,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92407,
      "latitude": 34.271173,
      "longitude": -116.93655,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92408,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92410,
      "latitude": 34.095956,
      "longitude": -116.479963,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92411,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92412,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92413,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92414,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92415,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92418,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92420,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92423,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92424,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92427,
      "latitude": 34.262243,
      "longitude": -116.861506,
      "city": "San Bernardino",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 92501,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92502,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92503,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92504,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92505,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92506,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92507,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92508,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92509,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92513,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92514,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92515,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92516,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92517,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92518,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "March Air Force Base",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92519,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92521,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92522,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Riverside",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92530,
      "latitude": 33.658068,
      "longitude": -116.649216,
      "city": "Lake Elsinore",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92531,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Lake Elsinore",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92532,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Lake Elsinore",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92536,
      "latitude": 33.519176,
      "longitude": -116.802625,
      "city": "Aguanga",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92539,
      "latitude": 33.524988,
      "longitude": -116.679174,
      "city": "Anza",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92543,
      "latitude": 33.651652,
      "longitude": -116.777014,
      "city": "Hemet",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92544,
      "latitude": 33.617074,
      "longitude": -116.786275,
      "city": "Hemet",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92545,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Hemet",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92546,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Hemet",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92548,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Homeland",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92549,
      "latitude": 33.741769,
      "longitude": -116.721444,
      "city": "Idyllwild",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92551,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Moreno Valley",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92552,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Moreno Valley",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92553,
      "latitude": 33.857218,
      "longitude": -116.720427,
      "city": "Moreno Valley",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92554,
      "latitude": 33.521993,
      "longitude": -115.915905,
      "city": "Moreno Valley",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92555,
      "latitude": 33.855098,
      "longitude": -116.920413,
      "city": "Moreno Valley",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92556,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Moreno Valley",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92557,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Moreno Valley",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92561,
      "latitude": 33.640142,
      "longitude": -116.556695,
      "city": "Mountain Center",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92562,
      "latitude": 33.44204,
      "longitude": -116.861027,
      "city": "Murrieta",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92563,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Murrieta",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92564,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Murrieta",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92567,
      "latitude": 33.628192,
      "longitude": -116.406069,
      "city": "Nuevo",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92570,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Perris",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92571,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Perris",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92572,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Perris",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92581,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "San Jacinto",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92582,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "San Jacinto",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92583,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "San Jacinto",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92584,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Menifee",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92585,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Sun City",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92586,
      "latitude": 33.48777,
      "longitude": -116.784521,
      "city": "Sun City",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92587,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Sun City",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92589,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Temecula",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92590,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Temecula",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92591,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Temecula",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92592,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Temecula",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92593,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Temecula",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92595,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Wildomar",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92596,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Winchester",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92599,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Perris",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92602,
      "latitude": 33.718018,
      "longitude": -117.72222,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92603,
      "latitude": 33.648871,
      "longitude": -117.764637,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92604,
      "latitude": 33.690475,
      "longitude": -117.790059,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92605,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Huntington Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92606,
      "latitude": 33.701121,
      "longitude": -117.817338,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92607,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Laguna Niguel",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92609,
      "latitude": 33.622146,
      "longitude": -117.701917,
      "city": "El Toro",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92610,
      "latitude": 33.663011,
      "longitude": -117.730745,
      "city": "Foothill Ranch",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92612,
      "latitude": 33.615525,
      "longitude": -117.782805,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92614,
      "latitude": 33.685319,
      "longitude": -117.827913,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92615,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Huntington Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92616,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92618,
      "latitude": 33.641579,
      "longitude": -117.73269,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92619,
      "latitude": 33.66985,
      "longitude": -117.765939,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92620,
      "latitude": 33.691619,
      "longitude": -117.765186,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92623,
      "latitude": 33.686519,
      "longitude": -117.830788,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92624,
      "latitude": 33.455277,
      "longitude": -117.662824,
      "city": "Capistrano Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92625,
      "latitude": 33.599956,
      "longitude": -117.865325,
      "city": "Corona Del Mar",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92626,
      "latitude": 33.6829,
      "longitude": -117.778398,
      "city": "Costa Mesa",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92627,
      "latitude": 33.685069,
      "longitude": -117.763886,
      "city": "Costa Mesa",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92628,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Costa Mesa",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92629,
      "latitude": 33.485903,
      "longitude": -117.696366,
      "city": "Dana Point",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92630,
      "latitude": 33.64079,
      "longitude": -117.693074,
      "city": "Lake Forest",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92637,
      "latitude": 33.608229,
      "longitude": -117.726099,
      "city": "Laguna Hills",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92646,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Huntington Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92647,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Huntington Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92648,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Huntington Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92649,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Huntington Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92650,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "East Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92651,
      "latitude": 33.520941,
      "longitude": -117.741315,
      "city": "Laguna Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92652,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Laguna Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92653,
      "latitude": 33.595714,
      "longitude": -117.715535,
      "city": "Laguna Hills",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92654,
      "latitude": 33.601673,
      "longitude": -117.716885,
      "city": "Laguna Hills",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92655,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Midway City",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92656,
      "latitude": 33.603459,
      "longitude": -117.751341,
      "city": "Aliso Viejo",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92657,
      "latitude": 33.597128,
      "longitude": -117.835987,
      "city": "Newport Coast",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92658,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Newport Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92659,
      "latitude": 33.611198,
      "longitude": -117.886731,
      "city": "Newport Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92660,
      "latitude": 33.637771,
      "longitude": -117.875189,
      "city": "Newport Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92661,
      "latitude": 33.555291,
      "longitude": -117.833298,
      "city": "Newport Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92662,
      "latitude": 33.606521,
      "longitude": -117.89274,
      "city": "Newport Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92663,
      "latitude": 33.61837,
      "longitude": -117.91709,
      "city": "Newport Beach",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92672,
      "latitude": 33.568923,
      "longitude": -117.616082,
      "city": "San Clemente",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92673,
      "latitude": 33.459745,
      "longitude": -117.625076,
      "city": "San Clemente",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92674,
      "latitude": 33.438428,
      "longitude": -117.623131,
      "city": "San Clemente",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92675,
      "latitude": 33.530631,
      "longitude": -117.618932,
      "city": "San Juan Capistrano",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92676,
      "latitude": 33.741994,
      "longitude": -117.727416,
      "city": "Silverado",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92677,
      "latitude": 33.532817,
      "longitude": -117.710262,
      "city": "Laguna Niguel",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92678,
      "latitude": 33.673979,
      "longitude": -117.59457,
      "city": "Trabuco Canyon",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92679,
      "latitude": 33.612322,
      "longitude": -117.595826,
      "city": "Trabuco Canyon",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92683,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Westminster",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92684,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Westminster",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92685,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Westminster",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92688,
      "latitude": 33.601944,
      "longitude": -117.603684,
      "city": "Rancho Santa Margarita",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92690,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Mission Viejo",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92691,
      "latitude": 33.605527,
      "longitude": -117.667923,
      "city": "Mission Viejo",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92692,
      "latitude": 33.575026,
      "longitude": -117.659899,
      "city": "Mission Viejo",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92693,
      "latitude": 33.555323,
      "longitude": -117.564,
      "city": "San Juan Capistrano",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92694,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Ladera Ranch",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92697,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92698,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Aliso Viejo",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92701,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92702,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92703,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92704,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92705,
      "latitude": 33.707568,
      "longitude": -117.845746,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92706,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92707,
      "latitude": 33.679069,
      "longitude": -117.876789,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92708,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fountain Valley",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92709,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92710,
      "latitude": 33.711552,
      "longitude": -117.809881,
      "city": "Irvine",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92711,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92712,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92728,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fountain Valley",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92735,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92780,
      "latitude": 33.579122,
      "longitude": -117.731534,
      "city": "Tustin",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92781,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Tustin",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92782,
      "latitude": 33.705787,
      "longitude": -117.816189,
      "city": "Tustin",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92799,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Santa Ana",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92801,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92802,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92803,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92804,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92805,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92806,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92807,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92808,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92809,
      "latitude": 33.865843,
      "longitude": -117.744003,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92811,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Atwood",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92812,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92814,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92815,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92816,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92817,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92821,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Brea",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92822,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Brea",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92823,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Brea",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92825,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92831,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92832,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92833,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92834,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92835,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92836,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92837,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92838,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Fullerton",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92840,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Garden Grove",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92841,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Garden Grove",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92842,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Garden Grove",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92843,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Garden Grove",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92844,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Garden Grove",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92845,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Garden Grove",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92846,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Garden Grove",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92850,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92856,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92857,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92859,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92860,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Norco",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92861,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Villa Park",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92862,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92863,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92864,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92865,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92866,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92867,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92868,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92869,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Orange",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92870,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Placentia",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92871,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Placentia",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92877,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Corona",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92878,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Corona",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92879,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Corona",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92880,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Corona",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92881,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Corona",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92882,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Corona",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92883,
      "latitude": 33.752886,
      "longitude": -116.055617,
      "city": "Corona",
      "state": "CA",
      "county": "Riverside"
  },
  {
      "zipCode": 92885,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Yorba Linda",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92886,
      "latitude": 33.674044,
      "longitude": -117.588233,
      "city": "Yorba Linda",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92887,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Yorba Linda",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 92899,
      "latitude": 33.640302,
      "longitude": -117.769442,
      "city": "Anaheim",
      "state": "CA",
      "county": "Orange"
  },
  {
      "zipCode": 93001,
      "latitude": 34.330829,
      "longitude": -119.358352,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93002,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93003,
      "latitude": 34.230353,
      "longitude": -119.221299,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93004,
      "latitude": 34.278752,
      "longitude": -119.16509,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93005,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93006,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93007,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93009,
      "latitude": 34.356248,
      "longitude": -119.146228,
      "city": "Ventura",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93010,
      "latitude": 34.307783,
      "longitude": -119.084253,
      "city": "Camarillo",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93011,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Camarillo",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93012,
      "latitude": 34.204429,
      "longitude": -118.974355,
      "city": "Camarillo",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93013,
      "latitude": 34.405045,
      "longitude": -119.520525,
      "city": "Carpinteria",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93014,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Carpinteria",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93015,
      "latitude": 34.393387,
      "longitude": -118.864285,
      "city": "Fillmore",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93016,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Fillmore",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93020,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Moorpark",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93021,
      "latitude": 34.301347,
      "longitude": -118.901098,
      "city": "Moorpark",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93022,
      "latitude": 34.412169,
      "longitude": -119.292335,
      "city": "Oak View",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93023,
      "latitude": 34.478806,
      "longitude": -119.25876,
      "city": "Ojai",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93024,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Ojai",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93030,
      "latitude": 34.224892,
      "longitude": -119.17749,
      "city": "Oxnard",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93031,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Oxnard",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93032,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Oxnard",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93033,
      "latitude": 34.154141,
      "longitude": -119.131326,
      "city": "Oxnard",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93034,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Oxnard",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93035,
      "latitude": 34.221157,
      "longitude": -119.202365,
      "city": "Oxnard",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93040,
      "latitude": 34.435224,
      "longitude": -118.785517,
      "city": "Piru",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93041,
      "latitude": 34.148893,
      "longitude": -119.15458,
      "city": "Port Hueneme",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93042,
      "latitude": 34.113389,
      "longitude": -119.112432,
      "city": "Point Mugu Nawc",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93043,
      "latitude": 34.162124,
      "longitude": -119.207364,
      "city": "Port Hueneme Cbc Base",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93044,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Port Hueneme",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93060,
      "latitude": 34.364943,
      "longitude": -119.085704,
      "city": "Santa Paula",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93061,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Santa Paula",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93062,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Simi Valley",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93063,
      "latitude": 34.292301,
      "longitude": -118.82033,
      "city": "Simi Valley",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93064,
      "latitude": 34.258203,
      "longitude": -118.710724,
      "city": "Brandeis",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93065,
      "latitude": 34.272162,
      "longitude": -118.733903,
      "city": "Simi Valley",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93066,
      "latitude": 34.304034,
      "longitude": -119.039305,
      "city": "Somis",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93067,
      "latitude": 34.422045,
      "longitude": -119.592561,
      "city": "Summerland",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93093,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Simi Valley",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93094,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Simi Valley",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93099,
      "latitude": 34.032383,
      "longitude": -119.1343,
      "city": "Simi Valley",
      "state": "CA",
      "county": "Ventura"
  },
  {
      "zipCode": 93101,
      "latitude": 34.421897,
      "longitude": -119.707135,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93102,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93103,
      "latitude": 34.430908,
      "longitude": -119.626863,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93105,
      "latitude": 34.508099,
      "longitude": -119.868622,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93106,
      "latitude": 34.432896,
      "longitude": -119.837072,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93107,
      "latitude": 34.421795,
      "longitude": -119.863722,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93108,
      "latitude": 34.437795,
      "longitude": -119.615862,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93109,
      "latitude": 34.406997,
      "longitude": -119.723668,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93110,
      "latitude": 34.539027,
      "longitude": -119.906198,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93111,
      "latitude": 34.453046,
      "longitude": -119.798472,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93116,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Goleta",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93117,
      "latitude": 34.503802,
      "longitude": -120.038806,
      "city": "Goleta",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93118,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Goleta",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93120,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93121,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93130,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93140,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93150,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93160,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93190,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Santa Barbara",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93199,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Goleta",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93201,
      "latitude": 35.85829,
      "longitude": -119.303735,
      "city": "Alpaugh",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93202,
      "latitude": 36.316529,
      "longitude": -119.704594,
      "city": "Armona",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93203,
      "latitude": 35.311064,
      "longitude": -118.659604,
      "city": "Arvin",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93204,
      "latitude": 36.003134,
      "longitude": -120.128716,
      "city": "Avenal",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93205,
      "latitude": 35.569608,
      "longitude": -118.487681,
      "city": "Bodfish",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93206,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Buttonwillow",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93207,
      "latitude": 35.881776,
      "longitude": -118.656086,
      "city": "California Hot Springs",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93208,
      "latitude": 36.139789,
      "longitude": -118.694576,
      "city": "Camp Nelson",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93210,
      "latitude": 36.247025,
      "longitude": -120.381432,
      "city": "Coalinga",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93212,
      "latitude": 36.067399,
      "longitude": -119.622634,
      "city": "Corcoran",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93215,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Delano",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93216,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Delano",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93218,
      "latitude": 36.082002,
      "longitude": -119.036316,
      "city": "Ducor",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93219,
      "latitude": 35.875918,
      "longitude": -119.262323,
      "city": "Earlimart",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93220,
      "latitude": 35.357018,
      "longitude": -118.810069,
      "city": "Edison",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93221,
      "latitude": 36.344716,
      "longitude": -119.088371,
      "city": "Exeter",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93222,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Frazier Park",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93223,
      "latitude": 36.304981,
      "longitude": -119.202664,
      "city": "Farmersville",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93224,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Fellows",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93225,
      "latitude": 35.674205,
      "longitude": -118.475381,
      "city": "Frazier Park",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93226,
      "latitude": 35.73296,
      "longitude": -118.709978,
      "city": "Glennville",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93227,
      "latitude": 36.357151,
      "longitude": -119.425371,
      "city": "Goshen",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93230,
      "latitude": 36.220468,
      "longitude": -119.71776,
      "city": "Hanford",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93232,
      "latitude": 36.138861,
      "longitude": -119.894727,
      "city": "Hanford",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93234,
      "latitude": 36.207165,
      "longitude": -120.103363,
      "city": "Huron",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93235,
      "latitude": 36.375844,
      "longitude": -119.134101,
      "city": "Ivanhoe",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93237,
      "latitude": 36.472713,
      "longitude": -118.902899,
      "city": "Kaweah",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93238,
      "latitude": 35.708701,
      "longitude": -118.405385,
      "city": "Kernville",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93239,
      "latitude": 36.005488,
      "longitude": -120.027031,
      "city": "Kettleman City",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93240,
      "latitude": 35.668987,
      "longitude": -118.457002,
      "city": "Lake Isabella",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93241,
      "latitude": 35.405369,
      "longitude": -118.699098,
      "city": "Lamont",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93242,
      "latitude": 36.445649,
      "longitude": -119.696386,
      "city": "Laton",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93243,
      "latitude": 34.881773,
      "longitude": -118.856562,
      "city": "Lebec",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93244,
      "latitude": 36.496901,
      "longitude": -118.99412,
      "city": "Lemon Cove",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93245,
      "latitude": 36.268194,
      "longitude": -119.817274,
      "city": "Lemoore",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93246,
      "latitude": 36.138861,
      "longitude": -119.894727,
      "city": "Lemoore",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93247,
      "latitude": 36.073033,
      "longitude": -119.129166,
      "city": "Lindsay",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93249,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Lost Hills",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93250,
      "latitude": 35.674813,
      "longitude": -118.489938,
      "city": "Mc Farland",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93251,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Mc Kittrick",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93252,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Maricopa",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93254,
      "latitude": 34.922283,
      "longitude": -119.590275,
      "city": "New Cuyama",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93255,
      "latitude": 35.63952,
      "longitude": -117.988136,
      "city": "Onyx",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93256,
      "latitude": 35.959881,
      "longitude": -119.13645,
      "city": "Pixley",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93257,
      "latitude": 35.973777,
      "longitude": -118.703592,
      "city": "Porterville",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93258,
      "latitude": 36.033126,
      "longitude": -119.007265,
      "city": "Porterville",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93260,
      "latitude": 35.819334,
      "longitude": -118.730136,
      "city": "Posey",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93261,
      "latitude": 35.805046,
      "longitude": -119.131527,
      "city": "Richgrove",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93262,
      "latitude": 36.36444,
      "longitude": -118.722755,
      "city": "Sequoia National Park",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93263,
      "latitude": 35.763646,
      "longitude": -118.435693,
      "city": "Shafter",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93265,
      "latitude": 36.126506,
      "longitude": -118.928478,
      "city": "Springville",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93266,
      "latitude": 36.189441,
      "longitude": -119.851865,
      "city": "Stratford",
      "state": "CA",
      "county": "Kings"
  },
  {
      "zipCode": 93267,
      "latitude": 36.137375,
      "longitude": -119.039111,
      "city": "Strathmore",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93268,
      "latitude": 35.703306,
      "longitude": -118.413077,
      "city": "Taft",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93270,
      "latitude": 35.904767,
      "longitude": -119.044239,
      "city": "Terra Bella",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93271,
      "latitude": 36.435485,
      "longitude": -118.82759,
      "city": "Three Rivers",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93272,
      "latitude": 36.005985,
      "longitude": -119.333512,
      "city": "Tipton",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93274,
      "latitude": 36.133527,
      "longitude": -119.299856,
      "city": "Tulare",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93275,
      "latitude": 36.26699,
      "longitude": -118.776902,
      "city": "Tulare",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93276,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Tupman",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93277,
      "latitude": 36.13188,
      "longitude": -119.355559,
      "city": "Visalia",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93278,
      "latitude": 36.126621,
      "longitude": -118.819365,
      "city": "Visalia",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93279,
      "latitude": 36.393615,
      "longitude": -119.118982,
      "city": "Visalia",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93280,
      "latitude": 35.467934,
      "longitude": -118.704037,
      "city": "Wasco",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93282,
      "latitude": 36.129557,
      "longitude": -119.516076,
      "city": "Waukena",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93283,
      "latitude": 35.54194,
      "longitude": -118.578135,
      "city": "Weldon",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93285,
      "latitude": 35.472296,
      "longitude": -118.552613,
      "city": "Wofford Heights",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93286,
      "latitude": 36.465943,
      "longitude": -119.122283,
      "city": "Woodlake",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93287,
      "latitude": 35.608372,
      "longitude": -118.79575,
      "city": "Woody",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93291,
      "latitude": 36.184014,
      "longitude": -119.363349,
      "city": "Visalia",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93292,
      "latitude": 36.19457,
      "longitude": -119.228324,
      "city": "Visalia",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93301,
      "latitude": 35.483501,
      "longitude": -119.007662,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93302,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93303,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93304,
      "latitude": 35.332109,
      "longitude": -119.022134,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93305,
      "latitude": 35.385489,
      "longitude": -118.985984,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93306,
      "latitude": 35.413048,
      "longitude": -118.867478,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93307,
      "latitude": 35.105383,
      "longitude": -118.716613,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93308,
      "latitude": 35.567657,
      "longitude": -118.892932,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93309,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93311,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93312,
      "latitude": 35.240577,
      "longitude": -118.917413,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93313,
      "latitude": 35.27581,
      "longitude": -119.013543,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93380,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93381,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93382,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93383,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93384,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93385,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93386,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93387,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93388,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93389,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93390,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Bakersfield",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93401,
      "latitude": 35.265573,
      "longitude": -120.62122,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93402,
      "latitude": 35.301041,
      "longitude": -120.794897,
      "city": "Los Osos",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93403,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93405,
      "latitude": 35.363067,
      "longitude": -120.703455,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93406,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93407,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93408,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93409,
      "latitude": 35.221085,
      "longitude": -120.636399,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93410,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "San Luis Obispo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93412,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "Los Osos",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93420,
      "latitude": 35.292432,
      "longitude": -120.655743,
      "city": "Arroyo Grande",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93421,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "Arroyo Grande",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93422,
      "latitude": 35.464793,
      "longitude": -120.712459,
      "city": "Atascadero",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93423,
      "latitude": 35.428241,
      "longitude": -120.769493,
      "city": "Atascadero",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93424,
      "latitude": 35.190349,
      "longitude": -120.717769,
      "city": "Avila Beach",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93426,
      "latitude": 35.907585,
      "longitude": -120.937371,
      "city": "Bradley",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93427,
      "latitude": 34.635927,
      "longitude": -120.201469,
      "city": "Buellton",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93428,
      "latitude": 35.587748,
      "longitude": -120.954454,
      "city": "Cambria",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93429,
      "latitude": 34.845765,
      "longitude": -120.534979,
      "city": "Casmalia",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93430,
      "latitude": 35.473343,
      "longitude": -120.908608,
      "city": "Cayucos",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93432,
      "latitude": 35.477945,
      "longitude": -120.436119,
      "city": "Creston",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93433,
      "latitude": 35.38173,
      "longitude": -120.50264,
      "city": "Grover Beach",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93434,
      "latitude": 34.938796,
      "longitude": -120.57318,
      "city": "Guadalupe",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93435,
      "latitude": 35.491888,
      "longitude": -120.976259,
      "city": "Harmony",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93436,
      "latitude": 34.629464,
      "longitude": -120.336594,
      "city": "Lompoc",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93437,
      "latitude": 34.737289,
      "longitude": -120.525706,
      "city": "Lompoc",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93438,
      "latitude": 34.262834,
      "longitude": -119.848555,
      "city": "Lompoc",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93440,
      "latitude": 34.745715,
      "longitude": -120.204944,
      "city": "Los Alamos",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93441,
      "latitude": 34.693012,
      "longitude": -120.068487,
      "city": "Los Olivos",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93442,
      "latitude": 35.402295,
      "longitude": -120.793742,
      "city": "Morro Bay",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93443,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "Morro Bay",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93444,
      "latitude": 35.048397,
      "longitude": -120.494812,
      "city": "Nipomo",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93445,
      "latitude": 35.106168,
      "longitude": -120.611142,
      "city": "Oceano",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93446,
      "latitude": 35.640598,
      "longitude": -120.700313,
      "city": "Paso Robles",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93447,
      "latitude": 35.75624,
      "longitude": -120.693505,
      "city": "Paso Robles",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93448,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "Pismo Beach",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93449,
      "latitude": 35.391719,
      "longitude": -120.549178,
      "city": "Pismo Beach",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93450,
      "latitude": 35.982551,
      "longitude": -120.828342,
      "city": "San Ardo",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93451,
      "latitude": 35.705546,
      "longitude": -120.646803,
      "city": "San Miguel",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93452,
      "latitude": 35.575711,
      "longitude": -120.888038,
      "city": "San Simeon",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93453,
      "latitude": 35.358427,
      "longitude": -120.259602,
      "city": "Santa Margarita",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93454,
      "latitude": 34.875832,
      "longitude": -120.340795,
      "city": "Santa Maria",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93455,
      "latitude": 34.883589,
      "longitude": -120.377957,
      "city": "Santa Maria",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93456,
      "latitude": 35.027682,
      "longitude": -120.196818,
      "city": "Santa Maria",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93457,
      "latitude": 34.836252,
      "longitude": -120.53421,
      "city": "Santa Maria",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93458,
      "latitude": 34.953532,
      "longitude": -120.495723,
      "city": "Santa Maria",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93460,
      "latitude": 34.646136,
      "longitude": -120.091629,
      "city": "Santa Ynez",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93461,
      "latitude": 35.610073,
      "longitude": -120.291994,
      "city": "Shandon",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93463,
      "latitude": 34.648841,
      "longitude": -120.170111,
      "city": "Solvang",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93464,
      "latitude": 34.674436,
      "longitude": -120.111465,
      "city": "Solvang",
      "state": "CA",
      "county": "Santa Barbara"
  },
  {
      "zipCode": 93465,
      "latitude": 35.517781,
      "longitude": -120.65385,
      "city": "Templeton",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93483,
      "latitude": 35.347065,
      "longitude": -120.455345,
      "city": "Grover Beach",
      "state": "CA",
      "county": "San Luis Obispo"
  },
  {
      "zipCode": 93501,
      "latitude": 35.038066,
      "longitude": -118.31596,
      "city": "Mojave",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93502,
      "latitude": 35.068161,
      "longitude": -118.224785,
      "city": "Mojave",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93504,
      "latitude": 35.187133,
      "longitude": -117.885359,
      "city": "California City",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93505,
      "latitude": 35.144767,
      "longitude": -117.897202,
      "city": "California City",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93510,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Acton",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93512,
      "latitude": 37.496842,
      "longitude": -118.624903,
      "city": "Benton",
      "state": "CA",
      "county": "Mono"
  },
  {
      "zipCode": 93513,
      "latitude": 37.169602,
      "longitude": -118.294915,
      "city": "Big Pine",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93514,
      "latitude": 37.026022,
      "longitude": -118.334393,
      "city": "Bishop",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93515,
      "latitude": 36.626033,
      "longitude": -117.218616,
      "city": "Bishop",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93516,
      "latitude": 35.215513,
      "longitude": -118.056018,
      "city": "Boron",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93517,
      "latitude": 38.248505,
      "longitude": -119.19876,
      "city": "Bridgeport",
      "state": "CA",
      "county": "Mono"
  },
  {
      "zipCode": 93518,
      "latitude": 35.488054,
      "longitude": -118.609553,
      "city": "Caliente",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93519,
      "latitude": 35.177369,
      "longitude": -118.035049,
      "city": "Cantil",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93522,
      "latitude": 36.626033,
      "longitude": -117.218616,
      "city": "Darwin",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93523,
      "latitude": 35.258171,
      "longitude": -118.130233,
      "city": "Edwards",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93524,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Edwards",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93526,
      "latitude": 36.831167,
      "longitude": -118.235097,
      "city": "Independence",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93527,
      "latitude": 35.639715,
      "longitude": -117.857026,
      "city": "Inyokern",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93528,
      "latitude": 35.370839,
      "longitude": -117.642748,
      "city": "Johannesburg",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93529,
      "latitude": 37.711282,
      "longitude": -119.054711,
      "city": "June Lake",
      "state": "CA",
      "county": "Mono"
  },
  {
      "zipCode": 93530,
      "latitude": 36.488612,
      "longitude": -117.874104,
      "city": "Keeler",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93531,
      "latitude": 35.226499,
      "longitude": -118.581032,
      "city": "Keene",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93532,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lake Hughes",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93534,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lancaster",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93535,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lancaster",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93536,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lancaster",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93539,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lancaster",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93541,
      "latitude": 37.97823,
      "longitude": -119.130361,
      "city": "Lee Vining",
      "state": "CA",
      "county": "Mono"
  },
  {
      "zipCode": 93542,
      "latitude": 36.626033,
      "longitude": -117.218616,
      "city": "Little Lake",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93543,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Littlerock",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93544,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Llano",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93545,
      "latitude": 36.553842,
      "longitude": -117.984465,
      "city": "Lone Pine",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93546,
      "latitude": 37.609434,
      "longitude": -118.865636,
      "city": "Mammoth Lakes",
      "state": "CA",
      "county": "Mono"
  },
  {
      "zipCode": 93549,
      "latitude": 36.229962,
      "longitude": -117.955189,
      "city": "Olancha",
      "state": "CA",
      "county": "Inyo"
  },
  {
      "zipCode": 93550,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Palmdale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93551,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Palmdale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93552,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Palmdale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93553,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Pearblossom",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93554,
      "latitude": 35.38661,
      "longitude": -117.715928,
      "city": "Randsburg",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93555,
      "latitude": 35.540458,
      "longitude": -117.794007,
      "city": "Ridgecrest",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93556,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Ridgecrest",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93558,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Red Mountain",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 93560,
      "latitude": 34.942862,
      "longitude": -118.40041,
      "city": "Rosamond",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93561,
      "latitude": 35.043028,
      "longitude": -118.502209,
      "city": "Tehachapi",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93562,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Trona",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 93563,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Valyermo",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93581,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Tehachapi",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93584,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lancaster",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93586,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Lancaster",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93590,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Palmdale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93591,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Palmdale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93592,
      "latitude": 34.839964,
      "longitude": -115.967051,
      "city": "Trona",
      "state": "CA",
      "county": "San Bernardino"
  },
  {
      "zipCode": 93596,
      "latitude": 35.294405,
      "longitude": -118.905173,
      "city": "Boron",
      "state": "CA",
      "county": "Kern"
  },
  {
      "zipCode": 93599,
      "latitude": 33.786594,
      "longitude": -118.298662,
      "city": "Palmdale",
      "state": "CA",
      "county": "Los Angeles"
  },
  {
      "zipCode": 93601,
      "latitude": 37.379045,
      "longitude": -119.736326,
      "city": "Ahwahnee",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93602,
      "latitude": 37.075671,
      "longitude": -119.253991,
      "city": "Auberry",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93603,
      "latitude": 36.568312,
      "longitude": -119.016197,
      "city": "Badger",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93604,
      "latitude": 37.299169,
      "longitude": -119.547639,
      "city": "Bass Lake",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93605,
      "latitude": 37.213607,
      "longitude": -119.247413,
      "city": "Big Creek",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93606,
      "latitude": 36.799615,
      "longitude": -120.018659,
      "city": "Biola",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93607,
      "latitude": 36.589797,
      "longitude": -119.899405,
      "city": "Burrel",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93608,
      "latitude": 36.607941,
      "longitude": -120.248963,
      "city": "Cantua Creek",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93609,
      "latitude": 36.528598,
      "longitude": -120.049939,
      "city": "Caruthers",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93610,
      "latitude": 37.013396,
      "longitude": -120.266097,
      "city": "Chowchilla",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93611,
      "latitude": 36.832583,
      "longitude": -119.592146,
      "city": "Clovis",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93612,
      "latitude": 36.878242,
      "longitude": -119.585303,
      "city": "Clovis",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93613,
      "latitude": 37.036529,
      "longitude": -119.511704,
      "city": "Clovis",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93614,
      "latitude": 37.158791,
      "longitude": -119.716753,
      "city": "Coarsegold",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93615,
      "latitude": 36.515805,
      "longitude": -119.236871,
      "city": "Cutler",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93616,
      "latitude": 36.6524,
      "longitude": -119.592015,
      "city": "Del Rey",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93618,
      "latitude": 36.509532,
      "longitude": -119.395365,
      "city": "Dinuba",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93620,
      "latitude": 37.073301,
      "longitude": -120.623363,
      "city": "Dos Palos",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 93621,
      "latitude": 36.723654,
      "longitude": -119.051698,
      "city": "Dunlap",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93622,
      "latitude": 36.772741,
      "longitude": -120.213442,
      "city": "Firebaugh",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93623,
      "latitude": 37.51165,
      "longitude": -119.652872,
      "city": "Fish Camp",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 93624,
      "latitude": 36.33859,
      "longitude": -120.111796,
      "city": "Five Points",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93625,
      "latitude": 36.623402,
      "longitude": -119.691869,
      "city": "Fowler",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93626,
      "latitude": 36.839928,
      "longitude": -119.668152,
      "city": "Friant",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93627,
      "latitude": 36.574499,
      "longitude": -120.066008,
      "city": "Helm",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93628,
      "latitude": 36.751546,
      "longitude": -118.957494,
      "city": "Hume",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93630,
      "latitude": 36.676147,
      "longitude": -120.219522,
      "city": "Kerman",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93631,
      "latitude": 36.5249,
      "longitude": -119.602863,
      "city": "Kingsburg",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93633,
      "latitude": 36.734051,
      "longitude": -118.958777,
      "city": "Kings Canyon National Pk",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93634,
      "latitude": 37.167262,
      "longitude": -119.243637,
      "city": "Lakeshore",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93635,
      "latitude": 37.103768,
      "longitude": -120.847479,
      "city": "Los Banos",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 93637,
      "latitude": 36.928286,
      "longitude": -120.182423,
      "city": "Madera",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93638,
      "latitude": 37.023383,
      "longitude": -119.977376,
      "city": "Madera",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93639,
      "latitude": 37.160622,
      "longitude": -119.945035,
      "city": "Madera",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93640,
      "latitude": 36.676898,
      "longitude": -120.393602,
      "city": "Mendota",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93641,
      "latitude": 36.698964,
      "longitude": -119.049219,
      "city": "Miramonte",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93642,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Mono Hot Springs",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93643,
      "latitude": 37.2141,
      "longitude": -119.496955,
      "city": "North Fork",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93644,
      "latitude": 37.37586,
      "longitude": -119.65238,
      "city": "Oakhurst",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93645,
      "latitude": 37.163948,
      "longitude": -119.665194,
      "city": "O Neals",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93646,
      "latitude": 36.677384,
      "longitude": -119.783475,
      "city": "Orange Cove",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93647,
      "latitude": 36.555602,
      "longitude": -119.166499,
      "city": "Orosi",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93648,
      "latitude": 36.661358,
      "longitude": -119.81608,
      "city": "Parlier",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93649,
      "latitude": 36.841665,
      "longitude": -119.349639,
      "city": "Piedra",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93650,
      "latitude": 36.8411,
      "longitude": -119.801015,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93651,
      "latitude": 37.008796,
      "longitude": -119.512686,
      "city": "Prather",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93652,
      "latitude": 36.591897,
      "longitude": -119.902728,
      "city": "Raisin",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93653,
      "latitude": 37.242121,
      "longitude": -119.859865,
      "city": "Raymond",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93654,
      "latitude": 36.669807,
      "longitude": -119.398857,
      "city": "Reedley",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93656,
      "latitude": 36.466514,
      "longitude": -119.97768,
      "city": "Riverdale",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93657,
      "latitude": 36.897408,
      "longitude": -119.829053,
      "city": "Sanger",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93660,
      "latitude": 36.458421,
      "longitude": -120.202084,
      "city": "San Joaquin",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93661,
      "latitude": 37.186872,
      "longitude": -120.650351,
      "city": "Santa Rita Park",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 93662,
      "latitude": 36.637507,
      "longitude": -119.992005,
      "city": "Selma",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93664,
      "latitude": 37.098774,
      "longitude": -119.166093,
      "city": "Shaver Lake",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93665,
      "latitude": 37.01349,
      "longitude": -120.744668,
      "city": "South Dos Palos",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 93666,
      "latitude": 36.520998,
      "longitude": -119.352004,
      "city": "Sultana",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93667,
      "latitude": 36.964156,
      "longitude": -119.370436,
      "city": "Tollhouse",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93668,
      "latitude": 36.632595,
      "longitude": -120.282776,
      "city": "Tranquillity",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93669,
      "latitude": 37.274585,
      "longitude": -119.540624,
      "city": "Wishon",
      "state": "CA",
      "county": "Madera"
  },
  {
      "zipCode": 93670,
      "latitude": 36.471833,
      "longitude": -119.259352,
      "city": "Yettem",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93673,
      "latitude": 36.444195,
      "longitude": -119.471745,
      "city": "Traver",
      "state": "CA",
      "county": "Tulare"
  },
  {
      "zipCode": 93675,
      "latitude": 36.750265,
      "longitude": -119.353631,
      "city": "Squaw Valley",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93701,
      "latitude": 36.782012,
      "longitude": -119.822019,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93702,
      "latitude": 36.770134,
      "longitude": -119.808651,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93703,
      "latitude": 36.768449,
      "longitude": -119.761076,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93704,
      "latitude": 36.92709,
      "longitude": -119.670552,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93705,
      "latitude": 36.795503,
      "longitude": -119.847234,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93706,
      "latitude": 36.648635,
      "longitude": -119.998749,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93707,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93708,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93709,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93710,
      "latitude": 36.896749,
      "longitude": -119.750294,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93711,
      "latitude": 36.830336,
      "longitude": -119.82824,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93712,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93714,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93715,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93716,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93717,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93718,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93720,
      "latitude": 36.850729,
      "longitude": -119.802227,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93721,
      "latitude": 36.729049,
      "longitude": -119.771526,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93722,
      "latitude": 36.793291,
      "longitude": -119.931132,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93724,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93725,
      "latitude": 36.620698,
      "longitude": -119.730824,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93726,
      "latitude": 36.726349,
      "longitude": -119.759875,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93727,
      "latitude": 36.763149,
      "longitude": -119.673272,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93728,
      "latitude": 36.756348,
      "longitude": -119.817429,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93729,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93740,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93741,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93744,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93745,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93747,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93750,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93755,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93760,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93761,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93762,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93764,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93765,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93771,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93772,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93773,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93774,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93775,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93776,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93777,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93778,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93779,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93780,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93784,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93786,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93790,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93791,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93792,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93793,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93794,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93844,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93888,
      "latitude": 36.746375,
      "longitude": -119.639658,
      "city": "Fresno",
      "state": "CA",
      "county": "Fresno"
  },
  {
      "zipCode": 93901,
      "latitude": 36.441768,
      "longitude": -121.416603,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93902,
      "latitude": 36.35433,
      "longitude": -121.132928,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93905,
      "latitude": 36.682888,
      "longitude": -121.612105,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93906,
      "latitude": 36.620989,
      "longitude": -121.487916,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93907,
      "latitude": 36.756306,
      "longitude": -121.67031,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93908,
      "latitude": 36.641676,
      "longitude": -121.63741,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93912,
      "latitude": 36.35433,
      "longitude": -121.132928,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93915,
      "latitude": 36.35433,
      "longitude": -121.132928,
      "city": "Salinas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93920,
      "latitude": 36.054105,
      "longitude": -121.607774,
      "city": "Big Sur",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93921,
      "latitude": 36.549729,
      "longitude": -121.922515,
      "city": "Carmel",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93922,
      "latitude": 36.543261,
      "longitude": -121.926315,
      "city": "Carmel",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93923,
      "latitude": 36.615248,
      "longitude": -121.79501,
      "city": "Carmel",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93924,
      "latitude": 36.446043,
      "longitude": -121.666999,
      "city": "Carmel Valley",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93925,
      "latitude": 36.583988,
      "longitude": -121.4516,
      "city": "Chualar",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93926,
      "latitude": 36.502102,
      "longitude": -121.408607,
      "city": "Gonzales",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93927,
      "latitude": 36.323971,
      "longitude": -121.324308,
      "city": "Greenfield",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93928,
      "latitude": 36.023549,
      "longitude": -121.21385,
      "city": "Jolon",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93930,
      "latitude": 36.201898,
      "longitude": -121.05232,
      "city": "King City",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93932,
      "latitude": 35.949963,
      "longitude": -121.062554,
      "city": "Lockwood",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93933,
      "latitude": 36.643259,
      "longitude": -121.812711,
      "city": "Marina",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93940,
      "latitude": 36.521811,
      "longitude": -121.688623,
      "city": "Monterey",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93942,
      "latitude": 36.35433,
      "longitude": -121.132928,
      "city": "Monterey",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93943,
      "latitude": 36.35433,
      "longitude": -121.132928,
      "city": "Monterey",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93944,
      "latitude": 36.606225,
      "longitude": -121.908915,
      "city": "Monterey",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93950,
      "latitude": 36.408303,
      "longitude": -121.544387,
      "city": "Pacific Grove",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93953,
      "latitude": 36.5831,
      "longitude": -121.936365,
      "city": "Pebble Beach",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93954,
      "latitude": 36.457423,
      "longitude": -121.290346,
      "city": "San Lucas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93955,
      "latitude": 36.429013,
      "longitude": -121.497712,
      "city": "Seaside",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93960,
      "latitude": 36.449485,
      "longitude": -121.368127,
      "city": "Soledad",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 93962,
      "latitude": 36.626059,
      "longitude": -121.655474,
      "city": "Spreckels",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 94002,
      "latitude": 37.516644,
      "longitude": -122.29339,
      "city": "Belmont",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94003,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Belmont",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94005,
      "latitude": 37.689182,
      "longitude": -122.404332,
      "city": "Brisbane",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94010,
      "latitude": 37.567086,
      "longitude": -122.367573,
      "city": "Burlingame",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94011,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Burlingame",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94012,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Burlingame",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94013,
      "latitude": "",
      "longitude": "",
      "city": "Daly City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94014,
      "latitude": 37.687509,
      "longitude": -122.438783,
      "city": "Daly City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94015,
      "latitude": 37.676864,
      "longitude": -122.475234,
      "city": "Daly City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94016,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Daly City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94017,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Daly City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94018,
      "latitude": 37.510085,
      "longitude": -122.473404,
      "city": "El Granada",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94019,
      "latitude": 37.389057,
      "longitude": -122.415566,
      "city": "Half Moon Bay",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94020,
      "latitude": 37.272556,
      "longitude": -122.249528,
      "city": "La Honda",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94021,
      "latitude": 37.279692,
      "longitude": -122.314831,
      "city": "Loma Mar",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94022,
      "latitude": 37.238842,
      "longitude": -121.885066,
      "city": "Los Altos",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94023,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Los Altos",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94024,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Los Altos",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94025,
      "latitude": 37.439616,
      "longitude": -122.186373,
      "city": "Menlo Park",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94026,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Menlo Park",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94027,
      "latitude": 37.450627,
      "longitude": -122.199439,
      "city": "Atherton",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94028,
      "latitude": 37.370194,
      "longitude": -122.218176,
      "city": "Portola Valley",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94029,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Menlo Park",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94030,
      "latitude": 37.598385,
      "longitude": -122.400083,
      "city": "Millbrae",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94031,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Millbrae",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94035,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Mountain View",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94037,
      "latitude": 37.542789,
      "longitude": -122.505152,
      "city": "Montara",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94038,
      "latitude": 37.52019,
      "longitude": -122.499618,
      "city": "Moss Beach",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94039,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Mountain View",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94040,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Mountain View",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94041,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Mountain View",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94042,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Mountain View",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94043,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Mountain View",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94044,
      "latitude": 37.616774,
      "longitude": -122.480015,
      "city": "Pacifica",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94045,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Pacifica",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94059,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Redwood City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94060,
      "latitude": 37.211194,
      "longitude": -122.343782,
      "city": "Pescadero",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94061,
      "latitude": 37.462158,
      "longitude": -122.235639,
      "city": "Redwood City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94062,
      "latitude": 37.424539,
      "longitude": -122.295997,
      "city": "Redwood City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94063,
      "latitude": 37.490387,
      "longitude": -122.212846,
      "city": "Redwood City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94064,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Redwood City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94065,
      "latitude": 37.53256,
      "longitude": -122.247806,
      "city": "Redwood City",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94066,
      "latitude": 37.623159,
      "longitude": -122.436034,
      "city": "San Bruno",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94067,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Bruno",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94070,
      "latitude": 37.498586,
      "longitude": -122.29815,
      "city": "San Carlos",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94071,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Carlos",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94074,
      "latitude": 37.328292,
      "longitude": -122.360233,
      "city": "San Gregorio",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94080,
      "latitude": 37.657443,
      "longitude": -122.423483,
      "city": "South San Francisco",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94083,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "South San Francisco",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94085,
      "latitude": 37.388617,
      "longitude": -122.017933,
      "city": "Sunnyvale",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94086,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Sunnyvale",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94087,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Sunnyvale",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94088,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Sunnyvale",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94089,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Sunnyvale",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94090,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Sunnyvale",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94096,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Bruno",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94098,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Bruno",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94099,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "South San Francisco",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94101,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94102,
      "latitude": 37.779278,
      "longitude": -122.416582,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94103,
      "latitude": 37.775678,
      "longitude": -122.412131,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94104,
      "latitude": 37.790728,
      "longitude": -122.401732,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94105,
      "latitude": 37.789595,
      "longitude": -122.395631,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94106,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94107,
      "latitude": 37.769029,
      "longitude": -122.393681,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94108,
      "latitude": 37.791028,
      "longitude": -122.408782,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94109,
      "latitude": 37.795219,
      "longitude": -122.420782,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94110,
      "latitude": 37.748932,
      "longitude": -122.416281,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94111,
      "latitude": 37.773579,
      "longitude": -122.405331,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94112,
      "latitude": 37.721981,
      "longitude": -122.439382,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94114,
      "latitude": 37.758279,
      "longitude": -122.439332,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94115,
      "latitude": 37.786028,
      "longitude": -122.437032,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94116,
      "latitude": 37.743857,
      "longitude": -122.483232,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94117,
      "latitude": 37.769079,
      "longitude": -122.443882,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94118,
      "latitude": 37.778779,
      "longitude": -122.459982,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94119,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94120,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94121,
      "latitude": 37.777129,
      "longitude": -122.491682,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94122,
      "latitude": 37.761179,
      "longitude": -122.475732,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94123,
      "latitude": 37.79967,
      "longitude": -122.435732,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94124,
      "latitude": 37.73123,
      "longitude": -122.382857,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94125,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94126,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94127,
      "latitude": 37.73403,
      "longitude": -122.458632,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94128,
      "latitude": 37.621582,
      "longitude": -122.392882,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94129,
      "latitude": 37.798878,
      "longitude": -122.465682,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94130,
      "latitude": 37.819763,
      "longitude": -122.368433,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94131,
      "latitude": 37.74363,
      "longitude": -122.443182,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94132,
      "latitude": 37.722631,
      "longitude": -122.482483,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94133,
      "latitude": 37.801928,
      "longitude": -122.410182,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94134,
      "latitude": 37.72168,
      "longitude": -122.410832,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94135,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94136,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94137,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94138,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94139,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94140,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94141,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94142,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94143,
      "latitude": 37.76313,
      "longitude": -122.458582,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94144,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94145,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94146,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94147,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94150,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94151,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94152,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94153,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94154,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94155,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94156,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94157,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94159,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94160,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94161,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94162,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94163,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94164,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94165,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94166,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94167,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94168,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94169,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94170,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94171,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94172,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94175,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94177,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94188,
      "latitude": 37.784827,
      "longitude": -122.727802,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": 94203,
      "latitude": 38.380456,
      "longitude": -121.555406,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94204,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94205,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94206,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94207,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94208,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94209,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94211,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94229,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94230,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94232,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94234,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94235,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94236,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94237,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94239,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94240,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94243,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94244,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94245,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94246,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94247,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94248,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94249,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94250,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94252,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94253,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94254,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94256,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94257,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94258,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94259,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94261,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94262,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94263,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94267,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94268,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94269,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94271,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94273,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94274,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94277,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94278,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94279,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94280,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94282,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94283,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94284,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94285,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94286,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94287,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94288,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94289,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94290,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94291,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94293,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94294,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94295,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94296,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94297,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94298,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94299,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 94301,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Palo Alto",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94302,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Palo Alto",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94303,
      "latitude": 37.467338,
      "longitude": -122.138765,
      "city": "Palo Alto",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94304,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Palo Alto",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94305,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Stanford",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94306,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Palo Alto",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94307,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Palo Alto",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94308,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "Palo Alto",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94309,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Palo Alto",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94310,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Palo Alto",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 94401,
      "latitude": 37.573485,
      "longitude": -122.32253,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94402,
      "latitude": 37.541886,
      "longitude": -122.33063,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94403,
      "latitude": 37.541486,
      "longitude": -122.304079,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94404,
      "latitude": 37.553774,
      "longitude": -122.269978,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94405,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94406,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94407,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94408,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94409,
      "latitude": 37.381144,
      "longitude": -122.334825,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94497,
      "latitude": 37.534736,
      "longitude": -122.32588,
      "city": "San Mateo",
      "state": "CA",
      "county": "San Mateo"
  },
  {
      "zipCode": 94501,
      "latitude": 37.770563,
      "longitude": -122.264779,
      "city": "Alameda",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94502,
      "latitude": 37.735088,
      "longitude": -122.243067,
      "city": "Alameda",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94503,
      "latitude": 38.187798,
      "longitude": -122.223826,
      "city": "American Canyon",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94506,
      "latitude": 37.832075,
      "longitude": -121.916718,
      "city": "Danville",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94507,
      "latitude": 37.936723,
      "longitude": -122.067431,
      "city": "Alamo",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94508,
      "latitude": 38.57145,
      "longitude": -122.450215,
      "city": "Angwin",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94509,
      "latitude": 37.931997,
      "longitude": -121.906748,
      "city": "Antioch",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94510,
      "latitude": 38.1012,
      "longitude": -122.032061,
      "city": "Benicia",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94511,
      "latitude": 38.02662,
      "longitude": -121.642463,
      "city": "Bethel Island",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94512,
      "latitude": 38.13293,
      "longitude": -121.827733,
      "city": "Birds Landing",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94513,
      "latitude": 37.912274,
      "longitude": -121.843071,
      "city": "Brentwood",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94514,
      "latitude": 37.858925,
      "longitude": -121.617002,
      "city": "Byron",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94515,
      "latitude": 38.615055,
      "longitude": -122.507742,
      "city": "Calistoga",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94516,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Canyon",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94517,
      "latitude": 37.897832,
      "longitude": -121.784818,
      "city": "Clayton",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94518,
      "latitude": 37.953672,
      "longitude": -122.022872,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94519,
      "latitude": 37.984821,
      "longitude": -122.011272,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94520,
      "latitude": 37.988871,
      "longitude": -122.013355,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94521,
      "latitude": 37.951661,
      "longitude": -121.960045,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94522,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94523,
      "latitude": 37.957572,
      "longitude": -122.077774,
      "city": "Pleasant Hill",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94524,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94525,
      "latitude": 37.958011,
      "longitude": -122.152602,
      "city": "Crockett",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94526,
      "latitude": 37.914851,
      "longitude": -122.053573,
      "city": "Danville",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94527,
      "latitude": 37.953542,
      "longitude": -121.957778,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94528,
      "latitude": 37.838744,
      "longitude": -121.96672,
      "city": "Diablo",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94529,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Concord",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94530,
      "latitude": 37.918024,
      "longitude": -122.30248,
      "city": "El Cerrito",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94531,
      "latitude": 37.920852,
      "longitude": -121.723919,
      "city": "Antioch",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94533,
      "latitude": 38.278428,
      "longitude": -122.020276,
      "city": "Fairfield",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94535,
      "latitude": 38.271218,
      "longitude": -121.94123,
      "city": "Travis Afb",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94536,
      "latitude": 37.565285,
      "longitude": -121.982721,
      "city": "Fremont",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94537,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Fremont",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94538,
      "latitude": 37.509453,
      "longitude": -121.95832,
      "city": "Fremont",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94539,
      "latitude": 37.520339,
      "longitude": -121.912568,
      "city": "Fremont",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94540,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Hayward",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94541,
      "latitude": 37.67513,
      "longitude": -121.97412,
      "city": "Hayward",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94542,
      "latitude": 37.662552,
      "longitude": -122.051179,
      "city": "Hayward",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94543,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Hayward",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94544,
      "latitude": 37.613883,
      "longitude": -122.061673,
      "city": "Hayward",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94545,
      "latitude": 37.635482,
      "longitude": -122.092324,
      "city": "Hayward",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94546,
      "latitude": 37.731229,
      "longitude": -122.066373,
      "city": "Castro Valley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94547,
      "latitude": 38.008343,
      "longitude": -122.262101,
      "city": "Hercules",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94548,
      "latitude": 37.972622,
      "longitude": -121.665205,
      "city": "Knightsen",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94549,
      "latitude": 37.901223,
      "longitude": -122.120089,
      "city": "Lafayette",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94550,
      "latitude": 37.676781,
      "longitude": -121.91605,
      "city": "Livermore",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94551,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Livermore",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94552,
      "latitude": 37.68943,
      "longitude": -121.9251,
      "city": "Castro Valley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94553,
      "latitude": 37.986421,
      "longitude": -122.134976,
      "city": "Martinez",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94555,
      "latitude": 37.570681,
      "longitude": -122.063323,
      "city": "Fremont",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94556,
      "latitude": 37.838926,
      "longitude": -122.099224,
      "city": "Moraga",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94557,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Hayward",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94558,
      "latitude": 38.516158,
      "longitude": -122.311921,
      "city": "Napa",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94559,
      "latitude": 38.256973,
      "longitude": -122.311685,
      "city": "Napa",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94560,
      "latitude": 37.534102,
      "longitude": -122.025352,
      "city": "Newark",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94561,
      "latitude": 37.996516,
      "longitude": -121.68972,
      "city": "Oakley",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94562,
      "latitude": 38.437887,
      "longitude": -122.399101,
      "city": "Oakville",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94563,
      "latitude": 37.893124,
      "longitude": -122.144826,
      "city": "Orinda",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94564,
      "latitude": 37.990772,
      "longitude": -122.243294,
      "city": "Pinole",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94565,
      "latitude": 38.00307,
      "longitude": -121.917219,
      "city": "Pittsburg",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94566,
      "latitude": 37.646081,
      "longitude": -121.862128,
      "city": "Pleasanton",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94567,
      "latitude": 38.649304,
      "longitude": -122.44529,
      "city": "Pope Valley",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94568,
      "latitude": 37.714402,
      "longitude": -121.906329,
      "city": "Dublin",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94569,
      "latitude": 38.04697,
      "longitude": -122.193028,
      "city": "Port Costa",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94570,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Moraga",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94571,
      "latitude": 38.209069,
      "longitude": -121.777874,
      "city": "Rio Vista",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94572,
      "latitude": 38.030821,
      "longitude": -122.245129,
      "city": "Rodeo",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94573,
      "latitude": 38.466508,
      "longitude": -122.414189,
      "city": "Rutherford",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94574,
      "latitude": 38.537757,
      "longitude": -122.401289,
      "city": "Saint Helena",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94575,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Moraga",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94576,
      "latitude": 38.450909,
      "longitude": -122.444889,
      "city": "Deer Park",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94577,
      "latitude": 37.715629,
      "longitude": -122.158621,
      "city": "San Leandro",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94578,
      "latitude": 37.704429,
      "longitude": -122.124225,
      "city": "San Leandro",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94579,
      "latitude": 37.688518,
      "longitude": -122.147425,
      "city": "San Leandro",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94580,
      "latitude": 37.68123,
      "longitude": -122.133825,
      "city": "San Lorenzo",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94581,
      "latitude": 38.509569,
      "longitude": -122.3539,
      "city": "Napa",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94583,
      "latitude": 37.768556,
      "longitude": -121.975531,
      "city": "San Ramon",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94585,
      "latitude": 38.195385,
      "longitude": -121.994935,
      "city": "Suisun City",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94586,
      "latitude": 37.585883,
      "longitude": -121.883018,
      "city": "Sunol",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94587,
      "latitude": 37.589084,
      "longitude": -121.97362,
      "city": "Union City",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94588,
      "latitude": 37.688244,
      "longitude": -121.898741,
      "city": "Pleasanton",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94589,
      "latitude": 38.158221,
      "longitude": -122.280383,
      "city": "Vallejo",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94590,
      "latitude": 38.096719,
      "longitude": -122.25328,
      "city": "Vallejo",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94591,
      "latitude": 38.114249,
      "longitude": -122.206292,
      "city": "Vallejo",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94592,
      "latitude": 38.096788,
      "longitude": -122.26986,
      "city": "Vallejo",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 94595,
      "latitude": 37.873343,
      "longitude": -122.070625,
      "city": "Walnut Creek",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94596,
      "latitude": 37.952389,
      "longitude": -121.864451,
      "city": "Walnut Creek",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94597,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Walnut Creek",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94598,
      "latitude": 37.906873,
      "longitude": -122.037045,
      "city": "Walnut Creek",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94599,
      "latitude": 38.41611,
      "longitude": -122.361587,
      "city": "Yountville",
      "state": "CA",
      "county": "Napa"
  },
  {
      "zipCode": 94601,
      "latitude": 37.786027,
      "longitude": -122.223779,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94602,
      "latitude": 37.802777,
      "longitude": -122.204578,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94603,
      "latitude": 37.761778,
      "longitude": -122.198428,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94604,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94605,
      "latitude": 37.759322,
      "longitude": -122.156626,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94606,
      "latitude": 37.789927,
      "longitude": -122.243979,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94607,
      "latitude": 37.807967,
      "longitude": -122.29878,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94608,
      "latitude": 37.833876,
      "longitude": -122.29548,
      "city": "Emeryville",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94609,
      "latitude": 37.834776,
      "longitude": -122.26328,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94610,
      "latitude": 37.811277,
      "longitude": -122.238779,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94611,
      "latitude": 37.847076,
      "longitude": -122.222278,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94612,
      "latitude": 37.809435,
      "longitude": -122.27018,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94613,
      "latitude": 37.781098,
      "longitude": -122.186573,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94614,
      "latitude": 37.727739,
      "longitude": -122.204573,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94615,
      "latitude": 37.806677,
      "longitude": -122.30038,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94617,
      "latitude": 37.807827,
      "longitude": -122.271652,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94618,
      "latitude": 37.843126,
      "longitude": -122.240179,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94619,
      "latitude": 37.783877,
      "longitude": -122.167126,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94620,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Piedmont",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94621,
      "latitude": 37.742529,
      "longitude": -122.196689,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94622,
      "latitude": "",
      "longitude": "",
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94623,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94624,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94625,
      "latitude": 37.803927,
      "longitude": -122.319655,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94626,
      "latitude": 37.819314,
      "longitude": -122.303136,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94627,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94643,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94649,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94659,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94660,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94661,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94662,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Emeryville",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94666,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Oakland",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94701,
      "latitude": 37.860576,
      "longitude": -122.29673,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94702,
      "latitude": 37.864425,
      "longitude": -122.28713,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94703,
      "latitude": 37.865725,
      "longitude": -122.27573,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94704,
      "latitude": 37.866975,
      "longitude": -122.256729,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94705,
      "latitude": 37.85983,
      "longitude": -122.242029,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94706,
      "latitude": 37.889525,
      "longitude": -122.29828,
      "city": "Albany",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94707,
      "latitude": 37.892675,
      "longitude": -122.27608,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94708,
      "latitude": 37.891825,
      "longitude": -122.260429,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94709,
      "latitude": 37.879825,
      "longitude": -122.26553,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94710,
      "latitude": 37.873366,
      "longitude": -122.30273,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94712,
      "latitude": 37.680181,
      "longitude": -121.921498,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94720,
      "latitude": 37.866825,
      "longitude": -122.253582,
      "city": "Berkeley",
      "state": "CA",
      "county": "Alameda"
  },
  {
      "zipCode": 94801,
      "latitude": 37.947523,
      "longitude": -122.384032,
      "city": "Richmond",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94802,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Richmond",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94803,
      "latitude": 37.966422,
      "longitude": -122.206177,
      "city": "El Sobrante",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94804,
      "latitude": 37.925174,
      "longitude": -122.344381,
      "city": "Richmond",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94805,
      "latitude": 37.929123,
      "longitude": -122.30093,
      "city": "Richmond",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94806,
      "latitude": 37.959573,
      "longitude": -122.334131,
      "city": "San Pablo",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94807,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Richmond",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94808,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Richmond",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94820,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "El Sobrante",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94850,
      "latitude": 37.777208,
      "longitude": -121.955399,
      "city": "Richmond",
      "state": "CA",
      "county": "Contra Costa"
  },
  {
      "zipCode": 94901,
      "latitude": 37.970948,
      "longitude": -122.504286,
      "city": "San Rafael",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94903,
      "latitude": 38.033922,
      "longitude": -122.58549,
      "city": "San Rafael",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94904,
      "latitude": 37.947874,
      "longitude": -122.536337,
      "city": "Greenbrae",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94912,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "San Rafael",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94913,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "San Rafael",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94914,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "Kentfield",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94915,
      "latitude": 38.07392,
      "longitude": -122.55944,
      "city": "San Rafael",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94920,
      "latitude": 37.935324,
      "longitude": -122.564439,
      "city": "Belvedere Tiburon",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94922,
      "latitude": 38.351416,
      "longitude": -122.974134,
      "city": "Bodega",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94923,
      "latitude": 38.367894,
      "longitude": -123.048629,
      "city": "Bodega Bay",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94924,
      "latitude": 38.001976,
      "longitude": -122.77915,
      "city": "Bolinas",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94925,
      "latitude": 37.925039,
      "longitude": -122.512035,
      "city": "Corte Madera",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94926,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Cotati",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94927,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Rohnert Park",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94928,
      "latitude": 38.345663,
      "longitude": -122.698398,
      "city": "Rohnert Park",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94929,
      "latitude": 38.246928,
      "longitude": -122.956202,
      "city": "Dillon Beach",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94930,
      "latitude": 37.981723,
      "longitude": -122.607791,
      "city": "Fairfax",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94931,
      "latitude": 38.323614,
      "longitude": -122.710565,
      "city": "Cotati",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94933,
      "latitude": 38.015803,
      "longitude": -122.690302,
      "city": "Forest Knolls",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94937,
      "latitude": 38.112571,
      "longitude": -122.887717,
      "city": "Inverness",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94938,
      "latitude": 38.022974,
      "longitude": -122.699183,
      "city": "Lagunitas",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94939,
      "latitude": 37.936624,
      "longitude": -122.534237,
      "city": "Larkspur",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94940,
      "latitude": 38.17087,
      "longitude": -122.823185,
      "city": "Marshall",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94941,
      "latitude": 37.886325,
      "longitude": -122.562937,
      "city": "Mill Valley",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94942,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "Mill Valley",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94945,
      "latitude": 38.027654,
      "longitude": -122.540951,
      "city": "Novato",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94946,
      "latitude": 38.008973,
      "longitude": -122.685568,
      "city": "Nicasio",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94947,
      "latitude": 38.09502,
      "longitude": -122.620893,
      "city": "Novato",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94948,
      "latitude": 38.148858,
      "longitude": -122.573745,
      "city": "Novato",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94949,
      "latitude": 38.06372,
      "longitude": -122.541739,
      "city": "Novato",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94950,
      "latitude": 38.046703,
      "longitude": -122.769854,
      "city": "Olema",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94951,
      "latitude": 38.315342,
      "longitude": -122.648346,
      "city": "Penngrove",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94952,
      "latitude": 38.265366,
      "longitude": -122.822588,
      "city": "Petaluma",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94953,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Petaluma",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94954,
      "latitude": 38.225816,
      "longitude": -122.574526,
      "city": "Petaluma",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94955,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Petaluma",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94956,
      "latitude": 38.057934,
      "longitude": -122.853421,
      "city": "Point Reyes Station",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94957,
      "latitude": 38.022322,
      "longitude": -122.561689,
      "city": "Ross",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94960,
      "latitude": 37.986473,
      "longitude": -122.59184,
      "city": "San Anselmo",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94963,
      "latitude": 38.016622,
      "longitude": -122.659644,
      "city": "San Geronimo",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94964,
      "latitude": 37.941571,
      "longitude": -122.48436,
      "city": "San Quentin",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94965,
      "latitude": 37.850417,
      "longitude": -122.531423,
      "city": "Sausalito",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94966,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "Sausalito",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94970,
      "latitude": 37.911625,
      "longitude": -122.652043,
      "city": "Stinson Beach",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94971,
      "latitude": 38.242733,
      "longitude": -122.91446,
      "city": "Tomales",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94972,
      "latitude": 38.307224,
      "longitude": -122.855757,
      "city": "Valley Ford",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94973,
      "latitude": 38.007172,
      "longitude": -122.661691,
      "city": "Woodacre",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94974,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "San Quentin",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94975,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Petaluma",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 94976,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "Corte Madera",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94977,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "Larkspur",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94978,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "Fairfax",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94979,
      "latitude": 38.068036,
      "longitude": -122.740988,
      "city": "San Anselmo",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94998,
      "latitude": 38.117269,
      "longitude": -122.568442,
      "city": "Novato",
      "state": "CA",
      "county": "Marin"
  },
  {
      "zipCode": 94999,
      "latitude": 38.267466,
      "longitude": -122.658097,
      "city": "Petaluma",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95001,
      "latitude": 37.05297,
      "longitude": -121.949418,
      "city": "Aptos",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95002,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Alviso",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95003,
      "latitude": 37.024933,
      "longitude": -121.879432,
      "city": "Aptos",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95004,
      "latitude": 36.864154,
      "longitude": -121.647608,
      "city": "Aromas",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 95005,
      "latitude": 37.096297,
      "longitude": -122.095525,
      "city": "Ben Lomond",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95006,
      "latitude": 37.176595,
      "longitude": -122.130887,
      "city": "Boulder Creek",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95007,
      "latitude": 37.10604,
      "longitude": -122.111546,
      "city": "Brookdale",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95008,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Campbell",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95009,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Campbell",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95010,
      "latitude": 37.012368,
      "longitude": -122.000537,
      "city": "Capitola",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95011,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Campbell",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95012,
      "latitude": 36.782556,
      "longitude": -121.735759,
      "city": "Castroville",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 95013,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Coyote",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95014,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Cupertino",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95015,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Cupertino",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95017,
      "latitude": 37.097536,
      "longitude": -122.24338,
      "city": "Davenport",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95018,
      "latitude": 37.102414,
      "longitude": -122.068323,
      "city": "Felton",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95019,
      "latitude": 36.937042,
      "longitude": -121.780262,
      "city": "Freedom",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95020,
      "latitude": 37.023347,
      "longitude": -121.493849,
      "city": "Gilroy",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95021,
      "latitude": 36.986148,
      "longitude": -121.497349,
      "city": "Gilroy",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95023,
      "latitude": 36.828078,
      "longitude": -121.3924,
      "city": "Hollister",
      "state": "CA",
      "county": "San Benito"
  },
  {
      "zipCode": 95024,
      "latitude": 36.85443,
      "longitude": -121.411909,
      "city": "Hollister",
      "state": "CA",
      "county": "San Benito"
  },
  {
      "zipCode": 95026,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Holy City",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95030,
      "latitude": 37.107647,
      "longitude": -121.842064,
      "city": "Los Gatos",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95031,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Los Gatos",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95032,
      "latitude": 37.070923,
      "longitude": -121.66186,
      "city": "Los Gatos",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95033,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Los Gatos",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95035,
      "latitude": 37.436454,
      "longitude": -121.861989,
      "city": "Milpitas",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95036,
      "latitude": 37.456807,
      "longitude": -121.913632,
      "city": "Milpitas",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95037,
      "latitude": 37.130238,
      "longitude": -121.681557,
      "city": "Morgan Hill",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95038,
      "latitude": 37.225787,
      "longitude": -121.617894,
      "city": "Morgan Hill",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95039,
      "latitude": 36.797555,
      "longitude": -121.76066,
      "city": "Moss Landing",
      "state": "CA",
      "county": "Monterey"
  },
  {
      "zipCode": 95041,
      "latitude": 37.052137,
      "longitude": -122.058024,
      "city": "Mount Hermon",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95042,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "New Almaden",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95043,
      "latitude": 36.587379,
      "longitude": -121.148586,
      "city": "Paicines",
      "state": "CA",
      "county": "San Benito"
  },
  {
      "zipCode": 95044,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Redwood Estates",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95045,
      "latitude": 36.831008,
      "longitude": -121.442746,
      "city": "San Juan Bautista",
      "state": "CA",
      "county": "San Benito"
  },
  {
      "zipCode": 95046,
      "latitude": 37.090125,
      "longitude": -121.606099,
      "city": "San Martin",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95050,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Santa Clara",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95051,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Santa Clara",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95052,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Santa Clara",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95053,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Santa Clara",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95054,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Santa Clara",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95055,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Santa Clara",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95056,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Santa Clara",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95060,
      "latitude": 37.052748,
      "longitude": -122.111126,
      "city": "Santa Cruz",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95061,
      "latitude": 37.007399,
      "longitude": -122.075125,
      "city": "Santa Cruz",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95062,
      "latitude": 36.971549,
      "longitude": -121.990571,
      "city": "Santa Cruz",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95063,
      "latitude": 36.9634,
      "longitude": -121.973771,
      "city": "Santa Cruz",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95064,
      "latitude": 36.988837,
      "longitude": -122.059073,
      "city": "Santa Cruz",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95065,
      "latitude": 37.033568,
      "longitude": -121.982632,
      "city": "Santa Cruz",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95066,
      "latitude": 37.071387,
      "longitude": -122.006276,
      "city": "Scotts Valley",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95067,
      "latitude": 37.05297,
      "longitude": -121.949418,
      "city": "Scotts Valley",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95070,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Saratoga",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95071,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "Saratoga",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95073,
      "latitude": 37.031948,
      "longitude": -121.94972,
      "city": "Soquel",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95075,
      "latitude": 36.761702,
      "longitude": -121.274795,
      "city": "Tres Pinos",
      "state": "CA",
      "county": "San Benito"
  },
  {
      "zipCode": 95076,
      "latitude": 36.98025,
      "longitude": -121.74696,
      "city": "Watsonville",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95077,
      "latitude": 37.05297,
      "longitude": -121.949418,
      "city": "Watsonville",
      "state": "CA",
      "county": "Santa Cruz"
  },
  {
      "zipCode": 95101,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95102,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95103,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95106,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95108,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95109,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95110,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95111,
      "latitude": 37.267947,
      "longitude": -121.800001,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95112,
      "latitude": 37.37004,
      "longitude": -121.892093,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95113,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95114,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95115,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95116,
      "latitude": 37.35504,
      "longitude": -121.835344,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95117,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95118,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95119,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95120,
      "latitude": 37.38478,
      "longitude": -121.895798,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95121,
      "latitude": 37.298592,
      "longitude": -121.797977,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95122,
      "latitude": 37.33074,
      "longitude": -121.828333,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95123,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95124,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95125,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95126,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95127,
      "latitude": 37.373903,
      "longitude": -121.795913,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95128,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95129,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95130,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95131,
      "latitude": 37.388389,
      "longitude": -121.886316,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95132,
      "latitude": 37.433587,
      "longitude": -121.829615,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95133,
      "latitude": 37.372239,
      "longitude": -121.864265,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95134,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95135,
      "latitude": 37.272289,
      "longitude": -121.723862,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95136,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95137,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95138,
      "latitude": 37.258733,
      "longitude": -121.745159,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95139,
      "latitude": 37.216394,
      "longitude": -121.740059,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95140,
      "latitude": 37.304321,
      "longitude": -121.633457,
      "city": "Mount Hamilton",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95141,
      "latitude": 37.178745,
      "longitude": -121.741809,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95142,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95148,
      "latitude": 37.335928,
      "longitude": -121.776861,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95150,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95151,
      "latitude": 37.187349,
      "longitude": -121.75722,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95152,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95153,
      "latitude": 37.216184,
      "longitude": -121.743632,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95154,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95155,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95156,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95157,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95158,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95159,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95160,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95161,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95164,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95170,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95171,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95172,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95173,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95190,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95191,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95192,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95193,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95194,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95196,
      "latitude": 37.189396,
      "longitude": -121.705327,
      "city": "San Jose",
      "state": "CA",
      "county": "Santa Clara"
  },
  {
      "zipCode": 95201,
      "latitude": 37.672196,
      "longitude": -121.298794,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95202,
      "latitude": 37.958573,
      "longitude": -121.289206,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95203,
      "latitude": 37.954573,
      "longitude": -121.321707,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95204,
      "latitude": 37.975473,
      "longitude": -121.323758,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95205,
      "latitude": 37.954773,
      "longitude": -121.255405,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95206,
      "latitude": 37.912324,
      "longitude": -121.387007,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95207,
      "latitude": 38.018146,
      "longitude": -121.325487,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95208,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95209,
      "latitude": 37.988691,
      "longitude": -121.337187,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95210,
      "latitude": 38.025472,
      "longitude": -121.291628,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95211,
      "latitude": 37.981073,
      "longitude": -121.308008,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95212,
      "latitude": 38.037321,
      "longitude": -121.216156,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95213,
      "latitude": 37.904777,
      "longitude": -121.226782,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95215,
      "latitude": 37.964123,
      "longitude": -121.128903,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95219,
      "latitude": 38.001613,
      "longitude": -121.455259,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95220,
      "latitude": 38.198666,
      "longitude": -121.230207,
      "city": "Acampo",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95221,
      "latitude": 38.092294,
      "longitude": -120.535316,
      "city": "Altaville",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95222,
      "latitude": 38.052816,
      "longitude": -120.618608,
      "city": "Angels Camp",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95223,
      "latitude": 38.328969,
      "longitude": -120.223837,
      "city": "Arnold",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95224,
      "latitude": 38.217249,
      "longitude": -120.377348,
      "city": "Avery",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95225,
      "latitude": 38.191258,
      "longitude": -120.910251,
      "city": "Burson",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95226,
      "latitude": 38.211495,
      "longitude": -120.849106,
      "city": "Campo Seco",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95227,
      "latitude": 38.211127,
      "longitude": -121.044252,
      "city": "Clements",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95228,
      "latitude": 37.971877,
      "longitude": -120.649912,
      "city": "Copperopolis",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95229,
      "latitude": 38.110355,
      "longitude": -120.45436,
      "city": "Douglas Flat",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95230,
      "latitude": 37.915075,
      "longitude": -120.967398,
      "city": "Farmington",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95231,
      "latitude": 37.883775,
      "longitude": -121.290405,
      "city": "French Camp",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95232,
      "latitude": 38.352793,
      "longitude": -120.573947,
      "city": "Glencoe",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95233,
      "latitude": 38.183537,
      "longitude": -120.388215,
      "city": "Hathaway Pines",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95234,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Holt",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95236,
      "latitude": 38.017322,
      "longitude": -121.039852,
      "city": "Linden",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95237,
      "latitude": 38.141969,
      "longitude": -121.152088,
      "city": "Lockeford",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95240,
      "latitude": 38.111356,
      "longitude": -121.172415,
      "city": "Lodi",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95241,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Lodi",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95242,
      "latitude": 38.138318,
      "longitude": -121.422011,
      "city": "Lodi",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95245,
      "latitude": 38.29138,
      "longitude": -120.571875,
      "city": "Mokelumne Hill",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95246,
      "latitude": 38.279047,
      "longitude": -120.536352,
      "city": "Mountain Ranch",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95247,
      "latitude": 38.131035,
      "longitude": -120.454381,
      "city": "Murphys",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95248,
      "latitude": 38.325626,
      "longitude": -120.485154,
      "city": "Rail Road Flat",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95249,
      "latitude": 38.190106,
      "longitude": -120.616052,
      "city": "San Andreas",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95250,
      "latitude": 38.243158,
      "longitude": -120.436953,
      "city": "Sheep Ranch",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95251,
      "latitude": 38.085205,
      "longitude": -120.471841,
      "city": "Vallecito",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95252,
      "latitude": 38.190383,
      "longitude": -120.733128,
      "city": "Valley Springs",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95253,
      "latitude": 38.114669,
      "longitude": -121.203656,
      "city": "Victor",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95254,
      "latitude": 38.161589,
      "longitude": -120.944264,
      "city": "Wallace",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95255,
      "latitude": 38.403007,
      "longitude": -120.477467,
      "city": "West Point",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95257,
      "latitude": 38.390344,
      "longitude": -120.490831,
      "city": "Wilseyville",
      "state": "CA",
      "county": "Calaveras"
  },
  {
      "zipCode": 95258,
      "latitude": 38.157962,
      "longitude": -121.314909,
      "city": "Woodbridge",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95267,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95269,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95290,
      "latitude": 37.953473,
      "longitude": -121.285257,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95296,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Lyoth",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95297,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95298,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Stockton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95301,
      "latitude": 37.317255,
      "longitude": -120.647347,
      "city": "Atwater",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95303,
      "latitude": 37.470114,
      "longitude": -120.624564,
      "city": "Ballico",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95304,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Tracy",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95305,
      "latitude": 37.822025,
      "longitude": -120.241685,
      "city": "Big Oak Flat",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95306,
      "latitude": 37.408643,
      "longitude": -120.122715,
      "city": "Catheys Valley",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 95307,
      "latitude": 37.561889,
      "longitude": -120.967976,
      "city": "Ceres",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95309,
      "latitude": 37.807938,
      "longitude": -120.440467,
      "city": "Chinese Camp",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95310,
      "latitude": 38.061003,
      "longitude": -120.363545,
      "city": "Columbia",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95311,
      "latitude": 37.700116,
      "longitude": -120.155251,
      "city": "Coulterville",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 95312,
      "latitude": 37.422175,
      "longitude": -120.627348,
      "city": "Cressey",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95313,
      "latitude": 37.429147,
      "longitude": -121.054997,
      "city": "Crows Landing",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95314,
      "latitude": 38.033541,
      "longitude": -119.92502,
      "city": "Dardanelle",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95315,
      "latitude": 37.429369,
      "longitude": -120.762047,
      "city": "Delhi",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95316,
      "latitude": 37.55026,
      "longitude": -120.718131,
      "city": "Denair",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95317,
      "latitude": 37.125735,
      "longitude": -120.504934,
      "city": "El Nido",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95318,
      "latitude": 37.622232,
      "longitude": -119.861258,
      "city": "El Portal",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 95319,
      "latitude": 37.637531,
      "longitude": -120.906593,
      "city": "Empire",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95320,
      "latitude": 37.817826,
      "longitude": -121.033598,
      "city": "Escalon",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95321,
      "latitude": 37.867634,
      "longitude": -120.088197,
      "city": "Groveland",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95322,
      "latitude": 37.150438,
      "longitude": -120.987196,
      "city": "Gustine",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95323,
      "latitude": 37.599514,
      "longitude": -120.659136,
      "city": "Hickman",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95324,
      "latitude": 37.399608,
      "longitude": -120.885973,
      "city": "Hilmar",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95325,
      "latitude": 37.555242,
      "longitude": -120.226091,
      "city": "Hornitos",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 95326,
      "latitude": 37.545683,
      "longitude": -120.966091,
      "city": "Hughson",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95327,
      "latitude": 37.883131,
      "longitude": -120.419802,
      "city": "Jamestown",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95328,
      "latitude": 37.55928,
      "longitude": -120.913846,
      "city": "Keyes",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95329,
      "latitude": 37.653226,
      "longitude": -120.50941,
      "city": "La Grange",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95330,
      "latitude": 37.811976,
      "longitude": -121.287362,
      "city": "Lathrop",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95333,
      "latitude": 37.264851,
      "longitude": -120.231843,
      "city": "Le Grand",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95334,
      "latitude": 37.358769,
      "longitude": -120.750082,
      "city": "Livingston",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95335,
      "latitude": 38.112838,
      "longitude": -120.126643,
      "city": "Long Barn",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95336,
      "latitude": 37.807297,
      "longitude": -121.177601,
      "city": "Manteca",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95337,
      "latitude": 37.743327,
      "longitude": -121.227351,
      "city": "Manteca",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95338,
      "latitude": 37.572907,
      "longitude": -120.018973,
      "city": "Mariposa",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 95340,
      "latitude": 37.338191,
      "longitude": -120.475427,
      "city": "Merced",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95341,
      "latitude": 37.186872,
      "longitude": -120.650351,
      "city": "Merced",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95342,
      "latitude": 37.367315,
      "longitude": -120.570634,
      "city": "Atwater",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95343,
      "latitude": 37.186872,
      "longitude": -120.650351,
      "city": "Merced",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95344,
      "latitude": 37.378234,
      "longitude": -120.617808,
      "city": "Merced",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95345,
      "latitude": 37.564369,
      "longitude": -119.962569,
      "city": "Midpines",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 95346,
      "latitude": 38.08455,
      "longitude": -120.135262,
      "city": "Mi Wuk Village",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95347,
      "latitude": 37.805371,
      "longitude": -120.375945,
      "city": "Moccasin",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95348,
      "latitude": 37.412623,
      "longitude": -120.50975,
      "city": "Merced",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95350,
      "latitude": 37.669463,
      "longitude": -121.016796,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95351,
      "latitude": 37.636384,
      "longitude": -121.082742,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95352,
      "latitude": 37.60396,
      "longitude": -120.937052,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95353,
      "latitude": 37.60396,
      "longitude": -120.937052,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95354,
      "latitude": 37.533682,
      "longitude": -121.090835,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95355,
      "latitude": 37.685879,
      "longitude": -120.948033,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95356,
      "latitude": 37.722878,
      "longitude": -121.035846,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95357,
      "latitude": 37.679529,
      "longitude": -120.875893,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95358,
      "latitude": 37.611,
      "longitude": -121.103547,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95360,
      "latitude": 37.340337,
      "longitude": -121.213529,
      "city": "Newman",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95361,
      "latitude": 37.839643,
      "longitude": -120.698578,
      "city": "Oakdale",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95363,
      "latitude": 37.516383,
      "longitude": -121.23848,
      "city": "Patterson",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95364,
      "latitude": 38.004532,
      "longitude": -119.993941,
      "city": "Pinecrest",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95365,
      "latitude": 37.289085,
      "longitude": -120.317156,
      "city": "Planada",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95366,
      "latitude": 37.765177,
      "longitude": -121.115411,
      "city": "Ripon",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95367,
      "latitude": 37.69222,
      "longitude": -120.865061,
      "city": "Riverbank",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95368,
      "latitude": 37.695359,
      "longitude": -121.078608,
      "city": "Salida",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95369,
      "latitude": 37.483708,
      "longitude": -120.463408,
      "city": "Snelling",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95370,
      "latitude": 37.96091,
      "longitude": -120.343863,
      "city": "Sonora",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95372,
      "latitude": 38.010365,
      "longitude": -120.230556,
      "city": "Soulsbyville",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95373,
      "latitude": 37.986377,
      "longitude": -120.241412,
      "city": "Standard",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95374,
      "latitude": 37.324536,
      "longitude": -120.864936,
      "city": "Stevinson",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95375,
      "latitude": 38.184096,
      "longitude": -120.021342,
      "city": "Strawberry",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95376,
      "latitude": 37.688679,
      "longitude": -121.407804,
      "city": "Tracy",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95377,
      "latitude": 37.674004,
      "longitude": -121.516857,
      "city": "Tracy",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95378,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Tracy",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95379,
      "latitude": 37.921694,
      "longitude": -120.267887,
      "city": "Tuolumne",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95380,
      "latitude": 37.540206,
      "longitude": -120.844063,
      "city": "Turlock",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95381,
      "latitude": 37.60396,
      "longitude": -120.937052,
      "city": "Turlock",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95382,
      "latitude": 37.530174,
      "longitude": -120.851857,
      "city": "Turlock",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95383,
      "latitude": 38.085837,
      "longitude": -120.176042,
      "city": "Twain Harte",
      "state": "CA",
      "county": "Tuolumne"
  },
  {
      "zipCode": 95385,
      "latitude": 37.889849,
      "longitude": -121.253872,
      "city": "Vernalis",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95386,
      "latitude": 37.659644,
      "longitude": -120.775617,
      "city": "Waterford",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95387,
      "latitude": 37.561682,
      "longitude": -121.249449,
      "city": "Westley",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95388,
      "latitude": 37.411145,
      "longitude": -120.55271,
      "city": "Winton",
      "state": "CA",
      "county": "Merced"
  },
  {
      "zipCode": 95389,
      "latitude": 37.63791,
      "longitude": -119.694652,
      "city": "Yosemite National Park",
      "state": "CA",
      "county": "Mariposa"
  },
  {
      "zipCode": 95390,
      "latitude": 37.60396,
      "longitude": -120.937052,
      "city": "Riverbank",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95391,
      "latitude": 37.78079,
      "longitude": -121.598048,
      "city": "Tracy",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95397,
      "latitude": 37.60396,
      "longitude": -120.937052,
      "city": "Modesto",
      "state": "CA",
      "county": "Stanislaus"
  },
  {
      "zipCode": 95401,
      "latitude": 38.450412,
      "longitude": -122.783159,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95402,
      "latitude": 38.440061,
      "longitude": -122.710598,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95403,
      "latitude": 38.477043,
      "longitude": -122.810328,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95404,
      "latitude": 38.452642,
      "longitude": -122.656296,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95405,
      "latitude": 38.444714,
      "longitude": -122.786392,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95406,
      "latitude": 38.459575,
      "longitude": -122.715654,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95407,
      "latitude": 38.389113,
      "longitude": -122.741849,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95408,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95409,
      "latitude": 38.462575,
      "longitude": -122.626545,
      "city": "Santa Rosa",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95410,
      "latitude": 39.168027,
      "longitude": -123.393401,
      "city": "Albion",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95412,
      "latitude": 38.710387,
      "longitude": -123.386924,
      "city": "Annapolis",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95415,
      "latitude": 39.046943,
      "longitude": -123.418728,
      "city": "Boonville",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95416,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Boyes Hot Springs",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95417,
      "latitude": 39.677625,
      "longitude": -123.64746,
      "city": "Branscomb",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95418,
      "latitude": 39.213324,
      "longitude": -123.277143,
      "city": "Calpella",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95419,
      "latitude": 38.417883,
      "longitude": -122.959401,
      "city": "Camp Meeker",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95420,
      "latitude": 39.367286,
      "longitude": -123.8042,
      "city": "Caspar",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95421,
      "latitude": 38.562107,
      "longitude": -123.189714,
      "city": "Cazadero",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95422,
      "latitude": 38.946476,
      "longitude": -122.657017,
      "city": "Clearlake",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95423,
      "latitude": 38.953387,
      "longitude": -122.620832,
      "city": "Clearlake Oaks",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95424,
      "latitude": 38.985997,
      "longitude": -122.661027,
      "city": "Clearlake Park",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95425,
      "latitude": 38.638928,
      "longitude": -122.91333,
      "city": "Cloverdale",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95426,
      "latitude": 38.82677,
      "longitude": -122.732911,
      "city": "Cobb",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95427,
      "latitude": 39.238683,
      "longitude": -123.58761,
      "city": "Comptche",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95428,
      "latitude": 39.835096,
      "longitude": -123.148437,
      "city": "Covelo",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95429,
      "latitude": 39.671989,
      "longitude": -123.394207,
      "city": "Dos Rios",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95430,
      "latitude": 38.442735,
      "longitude": -123.071403,
      "city": "Duncans Mills",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95431,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Eldridge",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95432,
      "latitude": 39.076371,
      "longitude": -123.618031,
      "city": "Elk",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95433,
      "latitude": 38.313864,
      "longitude": -122.513093,
      "city": "El Verano",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95435,
      "latitude": 39.00686,
      "longitude": -122.859625,
      "city": "Finley",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95436,
      "latitude": 38.509736,
      "longitude": -122.918385,
      "city": "Forestville",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95437,
      "latitude": 39.500634,
      "longitude": -123.637284,
      "city": "Fort Bragg",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95439,
      "latitude": 38.49031,
      "longitude": -122.776963,
      "city": "Fulton",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95441,
      "latitude": 38.707323,
      "longitude": -123.006531,
      "city": "Geyserville",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95442,
      "latitude": 38.383854,
      "longitude": -122.557253,
      "city": "Glen Ellen",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95443,
      "latitude": 39.034627,
      "longitude": -122.748109,
      "city": "Glenhaven",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95444,
      "latitude": 38.434926,
      "longitude": -122.868154,
      "city": "Graton",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95445,
      "latitude": 38.836933,
      "longitude": -123.527323,
      "city": "Gualala",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95446,
      "latitude": 38.495554,
      "longitude": -122.979355,
      "city": "Guerneville",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95448,
      "latitude": 38.512761,
      "longitude": -122.787101,
      "city": "Healdsburg",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95449,
      "latitude": 38.965637,
      "longitude": -123.138986,
      "city": "Hopland",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95450,
      "latitude": 38.546934,
      "longitude": -123.227415,
      "city": "Jenner",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95451,
      "latitude": 38.930811,
      "longitude": -122.793699,
      "city": "Kelseyville",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95452,
      "latitude": 38.413111,
      "longitude": -122.548243,
      "city": "Kenwood",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95453,
      "latitude": 39.05345,
      "longitude": -122.919291,
      "city": "Lakeport",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95454,
      "latitude": 39.772903,
      "longitude": -123.573407,
      "city": "Laytonville",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95456,
      "latitude": 39.267235,
      "longitude": -123.696392,
      "city": "Littleriver",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95457,
      "latitude": 38.89699,
      "longitude": -122.625621,
      "city": "Lower Lake",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95458,
      "latitude": 39.157828,
      "longitude": -122.809585,
      "city": "Lucerne",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95459,
      "latitude": 38.950236,
      "longitude": -123.661507,
      "city": "Manchester",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95460,
      "latitude": 39.319949,
      "longitude": -123.734377,
      "city": "Mendocino",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95461,
      "latitude": 39.040188,
      "longitude": -122.719276,
      "city": "Middletown",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95462,
      "latitude": 38.459755,
      "longitude": -123.000625,
      "city": "Monte Rio",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95463,
      "latitude": 39.128136,
      "longitude": -123.486408,
      "city": "Navarro",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95464,
      "latitude": 39.276878,
      "longitude": -122.868266,
      "city": "Nice",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95465,
      "latitude": 38.406676,
      "longitude": -123.020406,
      "city": "Occidental",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95466,
      "latitude": 39.056598,
      "longitude": -123.525375,
      "city": "Philo",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95468,
      "latitude": 38.919145,
      "longitude": -123.540572,
      "city": "Point Arena",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95469,
      "latitude": 39.360935,
      "longitude": -123.106751,
      "city": "Potter Valley",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95470,
      "latitude": 39.302446,
      "longitude": -123.462532,
      "city": "Redwood Valley",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95471,
      "latitude": 38.523472,
      "longitude": -122.982142,
      "city": "Rio Nido",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95472,
      "latitude": 38.407222,
      "longitude": -122.869654,
      "city": "Sebastopol",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95473,
      "latitude": 38.325851,
      "longitude": -122.505846,
      "city": "Sebastopol",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95476,
      "latitude": 38.255943,
      "longitude": -122.476819,
      "city": "Sonoma",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95480,
      "latitude": 38.676694,
      "longitude": -123.372059,
      "city": "Stewarts Point",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95481,
      "latitude": 39.127247,
      "longitude": -123.164533,
      "city": "Talmage",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95482,
      "latitude": 39.403699,
      "longitude": -123.321202,
      "city": "Ukiah",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95485,
      "latitude": 39.252489,
      "longitude": -122.85643,
      "city": "Upper Lake",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95486,
      "latitude": 38.464487,
      "longitude": -123.037996,
      "city": "Villa Grande",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95487,
      "latitude": 38.463088,
      "longitude": -122.989975,
      "city": "Vineburg",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95488,
      "latitude": 39.660425,
      "longitude": -123.786385,
      "city": "Westport",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95490,
      "latitude": 39.525958,
      "longitude": -123.36573,
      "city": "Willits",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95492,
      "latitude": 38.532827,
      "longitude": -122.8041,
      "city": "Windsor",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95493,
      "latitude": 39.185033,
      "longitude": -122.965163,
      "city": "Witter Springs",
      "state": "CA",
      "county": "Lake"
  },
  {
      "zipCode": 95494,
      "latitude": 38.934552,
      "longitude": -123.268378,
      "city": "Yorkville",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95497,
      "latitude": 38.717318,
      "longitude": -123.463976,
      "city": "The Sea Ranch",
      "state": "CA",
      "county": "Sonoma"
  },
  {
      "zipCode": 95501,
      "latitude": 40.646324,
      "longitude": -124.025773,
      "city": "Eureka",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95502,
      "latitude": 40.733552,
      "longitude": -123.944021,
      "city": "Eureka",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95503,
      "latitude": 40.787799,
      "longitude": -123.938685,
      "city": "Eureka",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95511,
      "latitude": 40.13049,
      "longitude": -123.602161,
      "city": "Alderpoint",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95514,
      "latitude": 40.318601,
      "longitude": -123.672124,
      "city": "Blocksburg",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95518,
      "latitude": 40.881383,
      "longitude": -123.984232,
      "city": "Arcata",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95519,
      "latitude": 40.708094,
      "longitude": -123.976234,
      "city": "Mckinleyville",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95521,
      "latitude": 40.564495,
      "longitude": -123.935891,
      "city": "Arcata",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95524,
      "latitude": 40.823396,
      "longitude": -124.041746,
      "city": "Bayside",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95525,
      "latitude": 40.961876,
      "longitude": -123.88501,
      "city": "Blue Lake",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95526,
      "latitude": 40.484837,
      "longitude": -123.753321,
      "city": "Bridgeville",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95527,
      "latitude": 40.477722,
      "longitude": -123.107281,
      "city": "Burnt Ranch",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 95528,
      "latitude": 40.501603,
      "longitude": -123.952838,
      "city": "Carlotta",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95531,
      "latitude": 41.744452,
      "longitude": -124.056897,
      "city": "Crescent City",
      "state": "CA",
      "county": "Del Norte"
  },
  {
      "zipCode": 95532,
      "latitude": 41.852985,
      "longitude": -124.15721,
      "city": "Crescent City",
      "state": "CA",
      "county": "Del Norte"
  },
  {
      "zipCode": 95534,
      "latitude": 40.733552,
      "longitude": -123.944021,
      "city": "Cutten",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95536,
      "latitude": 40.465229,
      "longitude": -124.26879,
      "city": "Ferndale",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95537,
      "latitude": 40.72533,
      "longitude": -124.21666,
      "city": "Fields Landing",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95538,
      "latitude": 41.892473,
      "longitude": -124.145734,
      "city": "Fort Dick",
      "state": "CA",
      "county": "Del Norte"
  },
  {
      "zipCode": 95540,
      "latitude": 40.815265,
      "longitude": -123.903317,
      "city": "Fortuna",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95542,
      "latitude": 40.65136,
      "longitude": -123.862815,
      "city": "Garberville",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95543,
      "latitude": 41.912328,
      "longitude": -123.810269,
      "city": "Gasquet",
      "state": "CA",
      "county": "Del Norte"
  },
  {
      "zipCode": 95545,
      "latitude": 40.25679,
      "longitude": -124.151347,
      "city": "Honeydew",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95546,
      "latitude": 40.786924,
      "longitude": -123.754058,
      "city": "Hoopa",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95547,
      "latitude": 40.548691,
      "longitude": -124.09898,
      "city": "Hydesville",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95548,
      "latitude": 41.609872,
      "longitude": -124.054355,
      "city": "Klamath",
      "state": "CA",
      "county": "Del Norte"
  },
  {
      "zipCode": 95549,
      "latitude": 40.647049,
      "longitude": -123.913353,
      "city": "Kneeland",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95550,
      "latitude": 40.768799,
      "longitude": -123.859218,
      "city": "Korbel",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95551,
      "latitude": 40.665179,
      "longitude": -124.239192,
      "city": "Loleta",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95552,
      "latitude": 40.450929,
      "longitude": -123.505623,
      "city": "Mad River",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 95553,
      "latitude": 40.23548,
      "longitude": -123.845935,
      "city": "Miranda",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95554,
      "latitude": 40.262278,
      "longitude": -123.81444,
      "city": "Myers Flat",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95555,
      "latitude": 41.291871,
      "longitude": -124.060455,
      "city": "Orick",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95556,
      "latitude": 41.304908,
      "longitude": -123.557541,
      "city": "Orleans",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95558,
      "latitude": 40.303424,
      "longitude": -124.21441,
      "city": "Petrolia",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95559,
      "latitude": 40.195018,
      "longitude": -123.786863,
      "city": "Phillipsville",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95560,
      "latitude": 40.505478,
      "longitude": -123.812063,
      "city": "Redway",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95562,
      "latitude": 40.314708,
      "longitude": -123.984516,
      "city": "Rio Dell",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95563,
      "latitude": 40.672576,
      "longitude": -123.034604,
      "city": "Salyer",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 95564,
      "latitude": 40.795138,
      "longitude": -124.199568,
      "city": "Samoa",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95565,
      "latitude": 40.440193,
      "longitude": -124.022847,
      "city": "Scotia",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95567,
      "latitude": 41.925977,
      "longitude": -124.159812,
      "city": "Smith River",
      "state": "CA",
      "county": "Del Norte"
  },
  {
      "zipCode": 95568,
      "latitude": 41.451129,
      "longitude": -123.467024,
      "city": "Somes Bar",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 95569,
      "latitude": 40.36547,
      "longitude": -123.895246,
      "city": "Redcrest",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95570,
      "latitude": 40.982322,
      "longitude": -124.125506,
      "city": "Trinidad",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95571,
      "latitude": 40.323964,
      "longitude": -123.882487,
      "city": "Weott",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95573,
      "latitude": 40.872068,
      "longitude": -123.90987,
      "city": "Willow Creek",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95585,
      "latitude": 39.873214,
      "longitude": -123.721407,
      "city": "Leggett",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95587,
      "latitude": 39.947575,
      "longitude": -123.686938,
      "city": "Piercy",
      "state": "CA",
      "county": "Mendocino"
  },
  {
      "zipCode": 95589,
      "latitude": 40.47949,
      "longitude": -123.847878,
      "city": "Whitethorn",
      "state": "CA",
      "county": "Humboldt"
  },
  {
      "zipCode": 95595,
      "latitude": 40.672576,
      "longitude": -123.034604,
      "city": "Zenia",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 95601,
      "latitude": 38.418568,
      "longitude": -120.831845,
      "city": "Amador City",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95602,
      "latitude": 39.014933,
      "longitude": -121.07047,
      "city": "Auburn",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95603,
      "latitude": 38.973505,
      "longitude": -121.067718,
      "city": "Auburn",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95604,
      "latitude": 39.106287,
      "longitude": -120.536032,
      "city": "Auburn",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95605,
      "latitude": 38.591854,
      "longitude": -121.537801,
      "city": "West Sacramento",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95606,
      "latitude": 38.799836,
      "longitude": -122.181084,
      "city": "Brooks",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95607,
      "latitude": 38.785596,
      "longitude": -122.140491,
      "city": "Capay",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95608,
      "latitude": 38.62136,
      "longitude": -121.332191,
      "city": "Carmichael",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95609,
      "latitude": 38.650335,
      "longitude": -121.327214,
      "city": "Carmichael",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95610,
      "latitude": 38.689802,
      "longitude": -121.26736,
      "city": "Citrus Heights",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95611,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Citrus Heights",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95612,
      "latitude": 38.43141,
      "longitude": -121.594875,
      "city": "Clarksburg",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95613,
      "latitude": 38.81419,
      "longitude": -120.895188,
      "city": "Coloma",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95614,
      "latitude": 38.877956,
      "longitude": -120.986029,
      "city": "Cool",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95615,
      "latitude": 38.338322,
      "longitude": -121.5378,
      "city": "Courtland",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95616,
      "latitude": 38.521776,
      "longitude": -121.749016,
      "city": "Davis",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95617,
      "latitude": 38.619545,
      "longitude": -121.961532,
      "city": "Davis",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95618,
      "latitude": 38.54431,
      "longitude": -121.685904,
      "city": "El Macero",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95619,
      "latitude": 38.629821,
      "longitude": -120.820021,
      "city": "Diamond Springs",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95620,
      "latitude": 38.397372,
      "longitude": -121.761419,
      "city": "Dixon",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 95621,
      "latitude": 38.693052,
      "longitude": -121.309461,
      "city": "Citrus Heights",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95623,
      "latitude": 38.676272,
      "longitude": -120.84598,
      "city": "El Dorado",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95624,
      "latitude": 38.44148,
      "longitude": -121.307142,
      "city": "Elk Grove",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95625,
      "latitude": 38.348022,
      "longitude": -121.90029,
      "city": "Elmira",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 95626,
      "latitude": 38.695702,
      "longitude": -121.452097,
      "city": "Elverta",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95627,
      "latitude": 38.779509,
      "longitude": -122.100884,
      "city": "Esparto",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95628,
      "latitude": 38.655263,
      "longitude": -121.260709,
      "city": "Fair Oaks",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95629,
      "latitude": 38.510343,
      "longitude": -120.708029,
      "city": "Fiddletown",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95630,
      "latitude": 38.594181,
      "longitude": -121.310796,
      "city": "Folsom",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95631,
      "latitude": 39.024453,
      "longitude": -120.862663,
      "city": "Foresthill",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95632,
      "latitude": 38.289817,
      "longitude": -121.313883,
      "city": "Galt",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95633,
      "latitude": 38.854672,
      "longitude": -120.830282,
      "city": "Garden Valley",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95634,
      "latitude": 38.924378,
      "longitude": -120.703303,
      "city": "Georgetown",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95635,
      "latitude": 38.909448,
      "longitude": -120.889138,
      "city": "Greenwood",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95636,
      "latitude": 38.63765,
      "longitude": -120.553399,
      "city": "Grizzly Flats",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95637,
      "latitude": 38.850382,
      "longitude": -122.214722,
      "city": "Guinda",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95638,
      "latitude": 38.320561,
      "longitude": -121.154808,
      "city": "Herald",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95639,
      "latitude": 38.357839,
      "longitude": -121.541649,
      "city": "Hood",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95640,
      "latitude": 38.340647,
      "longitude": -120.91473,
      "city": "Ione",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95641,
      "latitude": 38.18394,
      "longitude": -121.60164,
      "city": "Isleton",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95642,
      "latitude": 38.366959,
      "longitude": -120.763365,
      "city": "Jackson",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95644,
      "latitude": 38.463282,
      "longitude": -120.550021,
      "city": "Kit Carson",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95645,
      "latitude": 38.858483,
      "longitude": -121.772472,
      "city": "Knights Landing",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95646,
      "latitude": 38.575433,
      "longitude": -120.285021,
      "city": "Kirkwood",
      "state": "CA",
      "county": "Alpine"
  },
  {
      "zipCode": 95648,
      "latitude": 38.929305,
      "longitude": -121.327143,
      "city": "Lincoln",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95650,
      "latitude": 38.814428,
      "longitude": -121.176622,
      "city": "Loomis",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95651,
      "latitude": 38.888405,
      "longitude": -120.842623,
      "city": "Lotus",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95652,
      "latitude": 38.666653,
      "longitude": -121.389613,
      "city": "Mcclellan",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95653,
      "latitude": 38.671903,
      "longitude": -121.972096,
      "city": "Madison",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95654,
      "latitude": 38.463282,
      "longitude": -120.550021,
      "city": "Martell",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95655,
      "latitude": 38.554406,
      "longitude": -121.28181,
      "city": "Mather",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95656,
      "latitude": 38.54723,
      "longitude": -120.707891,
      "city": "Mount Aukum",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95658,
      "latitude": 39.038217,
      "longitude": -120.971166,
      "city": "Newcastle",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95659,
      "latitude": 38.837134,
      "longitude": -121.551933,
      "city": "Nicolaus",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95660,
      "latitude": 38.667003,
      "longitude": -121.387541,
      "city": "North Highlands",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95661,
      "latitude": 38.74073,
      "longitude": -121.25603,
      "city": "Roseville",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95662,
      "latitude": 38.687103,
      "longitude": -121.225369,
      "city": "Orangevale",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95663,
      "latitude": 38.852288,
      "longitude": -121.180531,
      "city": "Penryn",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95664,
      "latitude": 38.75162,
      "longitude": -120.979123,
      "city": "Pilot Hill",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95665,
      "latitude": 38.4038,
      "longitude": -120.668334,
      "city": "Pine Grove",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95666,
      "latitude": 38.556287,
      "longitude": -120.352661,
      "city": "Pioneer",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95667,
      "latitude": 38.730224,
      "longitude": -120.797149,
      "city": "Placerville",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95668,
      "latitude": 38.830643,
      "longitude": -121.534228,
      "city": "Pleasant Grove",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95669,
      "latitude": 38.486559,
      "longitude": -120.86184,
      "city": "Plymouth",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95670,
      "latitude": 38.597705,
      "longitude": -121.270426,
      "city": "Rancho Cordova",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95671,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Represa",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95672,
      "latitude": 38.718327,
      "longitude": -120.994305,
      "city": "Rescue",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95673,
      "latitude": 38.478399,
      "longitude": -121.459135,
      "city": "Rio Linda",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95674,
      "latitude": 38.931318,
      "longitude": -121.489882,
      "city": "Rio Oso",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95675,
      "latitude": 38.544557,
      "longitude": -120.74279,
      "city": "River Pines",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95676,
      "latitude": 38.868875,
      "longitude": -121.70949,
      "city": "Robbins",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95677,
      "latitude": 38.801233,
      "longitude": -121.230374,
      "city": "Rocklin",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95678,
      "latitude": 38.910488,
      "longitude": -121.131309,
      "city": "Roseville",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95679,
      "latitude": 38.867147,
      "longitude": -122.224813,
      "city": "Rumsey",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95680,
      "latitude": 38.232898,
      "longitude": -121.568427,
      "city": "Ryde",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95681,
      "latitude": 38.989408,
      "longitude": -121.346893,
      "city": "Sheridan",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95682,
      "latitude": 38.62183,
      "longitude": -120.78801,
      "city": "Shingle Springs",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95683,
      "latitude": 38.51858,
      "longitude": -121.125431,
      "city": "Sloughhouse",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95684,
      "latitude": 38.639336,
      "longitude": -120.599848,
      "city": "Somerset",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95685,
      "latitude": 38.436584,
      "longitude": -120.742839,
      "city": "Sutter Creek",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95686,
      "latitude": 38.189865,
      "longitude": -121.450953,
      "city": "Thornton",
      "state": "CA",
      "county": "San Joaquin"
  },
  {
      "zipCode": 95687,
      "latitude": 38.35056,
      "longitude": -121.912773,
      "city": "Vacaville",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 95688,
      "latitude": 38.407405,
      "longitude": -122.012778,
      "city": "Vacaville",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 95689,
      "latitude": 38.44727,
      "longitude": -120.706876,
      "city": "Volcano",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95690,
      "latitude": 38.181635,
      "longitude": -121.578895,
      "city": "Walnut Grove",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95691,
      "latitude": 38.608615,
      "longitude": -121.610919,
      "city": "West Sacramento",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95692,
      "latitude": 39.08337,
      "longitude": -121.397892,
      "city": "Wheatland",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95693,
      "latitude": 38.405222,
      "longitude": -121.239026,
      "city": "Wilton",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95694,
      "latitude": 38.622768,
      "longitude": -121.923196,
      "city": "Winters",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95695,
      "latitude": 38.74388,
      "longitude": -121.915653,
      "city": "Woodland",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95696,
      "latitude": 38.34896,
      "longitude": -121.908324,
      "city": "Vacaville",
      "state": "CA",
      "county": "Solano"
  },
  {
      "zipCode": 95697,
      "latitude": 38.751038,
      "longitude": -121.832156,
      "city": "Yolo",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95698,
      "latitude": 38.782731,
      "longitude": -121.89811,
      "city": "Zamora",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95699,
      "latitude": 38.437001,
      "longitude": -120.845706,
      "city": "Drytown",
      "state": "CA",
      "county": "Amador"
  },
  {
      "zipCode": 95701,
      "latitude": 39.214162,
      "longitude": -120.795987,
      "city": "Alta",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95703,
      "latitude": 38.998186,
      "longitude": -120.994598,
      "city": "Applegate",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95709,
      "latitude": 38.745549,
      "longitude": -120.693072,
      "city": "Camino",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95712,
      "latitude": 39.167276,
      "longitude": -120.964275,
      "city": "Chicago Park",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95713,
      "latitude": 39.126473,
      "longitude": -120.863763,
      "city": "Colfax",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95714,
      "latitude": 39.196515,
      "longitude": -120.829762,
      "city": "Dutch Flat",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95715,
      "latitude": 39.254601,
      "longitude": -120.721541,
      "city": "Emigrant Gap",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95717,
      "latitude": 39.168473,
      "longitude": -120.802035,
      "city": "Gold Run",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95720,
      "latitude": 38.892518,
      "longitude": -120.25249,
      "city": "Kyburz",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95721,
      "latitude": 38.83074,
      "longitude": -120.055163,
      "city": "Echo Lake",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95722,
      "latitude": 39.001448,
      "longitude": -121.034843,
      "city": "Meadow Vista",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95724,
      "latitude": 39.26599,
      "longitude": -120.64145,
      "city": "Norden",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95726,
      "latitude": 38.766276,
      "longitude": -120.481278,
      "city": "Pollock Pines",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95728,
      "latitude": 39.31959,
      "longitude": -120.435313,
      "city": "Soda Springs",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95735,
      "latitude": 38.802139,
      "longitude": -120.140714,
      "city": "Twin Bridges",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95736,
      "latitude": 39.01892,
      "longitude": -120.962012,
      "city": "Weimar",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95741,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Rancho Cordova",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95742,
      "latitude": 38.579621,
      "longitude": -121.20253,
      "city": "Rancho Cordova",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95743,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Rancho Cordova",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95746,
      "latitude": 38.756614,
      "longitude": -121.183436,
      "city": "Granite Bay",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95747,
      "latitude": 38.779875,
      "longitude": -121.335559,
      "city": "Roseville",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95758,
      "latitude": 38.347205,
      "longitude": -121.428681,
      "city": "Elk Grove",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95759,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Elk Grove",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95762,
      "latitude": 38.686847,
      "longitude": -121.044846,
      "city": "El Dorado Hills",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 95763,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Folsom",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95765,
      "latitude": 38.819795,
      "longitude": -121.277439,
      "city": "Rocklin",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 95776,
      "latitude": 38.695895,
      "longitude": -121.709713,
      "city": "Woodland",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95798,
      "latitude": 38.619545,
      "longitude": -121.961532,
      "city": "West Sacramento",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95799,
      "latitude": 38.619545,
      "longitude": -121.961532,
      "city": "West Sacramento",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95812,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95813,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95814,
      "latitude": 38.579055,
      "longitude": -121.480905,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95815,
      "latitude": 38.589505,
      "longitude": -121.448665,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95816,
      "latitude": 38.571505,
      "longitude": -121.467549,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95817,
      "latitude": 38.549232,
      "longitude": -121.452264,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95818,
      "latitude": 38.557255,
      "longitude": -121.495915,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95819,
      "latitude": 38.568305,
      "longitude": -121.440764,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95820,
      "latitude": 38.536606,
      "longitude": -121.446414,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95821,
      "latitude": 38.627204,
      "longitude": -121.437964,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95822,
      "latitude": 38.511356,
      "longitude": -121.497716,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95823,
      "latitude": 38.481354,
      "longitude": -121.442071,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95824,
      "latitude": 38.517256,
      "longitude": -121.440764,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95825,
      "latitude": 38.585804,
      "longitude": -121.402213,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95826,
      "latitude": 38.547639,
      "longitude": -121.385459,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95827,
      "latitude": 38.552752,
      "longitude": -121.322653,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95828,
      "latitude": 38.488446,
      "longitude": -121.423245,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95829,
      "latitude": 38.495328,
      "longitude": -121.321524,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95830,
      "latitude": 38.490022,
      "longitude": -121.25614,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95831,
      "latitude": 38.497863,
      "longitude": -121.531332,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95832,
      "latitude": 38.435014,
      "longitude": -121.497276,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95833,
      "latitude": 38.621802,
      "longitude": -121.514016,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95834,
      "latitude": 38.584193,
      "longitude": -121.523566,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95835,
      "latitude": 38.667783,
      "longitude": -121.526051,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95836,
      "latitude": 38.710901,
      "longitude": -121.522717,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95837,
      "latitude": 38.692855,
      "longitude": -121.603038,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95838,
      "latitude": 38.645103,
      "longitude": -121.440015,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95840,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95841,
      "latitude": 38.665385,
      "longitude": -121.353862,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95842,
      "latitude": 38.692752,
      "longitude": -121.359009,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95843,
      "latitude": 38.716091,
      "longitude": -121.366613,
      "city": "Antelope",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95851,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95852,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95853,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95857,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95860,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95864,
      "latitude": 38.586554,
      "longitude": -121.379467,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95865,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95866,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95867,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95873,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95887,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95894,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95899,
      "latitude": 38.377411,
      "longitude": -121.444429,
      "city": "Sacramento",
      "state": "CA",
      "county": "Sacramento"
  },
  {
      "zipCode": 95901,
      "latitude": 39.260878,
      "longitude": -121.370959,
      "city": "Marysville",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95903,
      "latitude": 39.111453,
      "longitude": -121.360957,
      "city": "Beale Afb",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95910,
      "latitude": 39.466324,
      "longitude": -120.829633,
      "city": "Alleghany",
      "state": "CA",
      "county": "Sierra"
  },
  {
      "zipCode": 95912,
      "latitude": 39.0134,
      "longitude": -121.996852,
      "city": "Arbuckle",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95913,
      "latitude": 39.698298,
      "longitude": -122.168928,
      "city": "Artois",
      "state": "CA",
      "county": "Glenn"
  },
  {
      "zipCode": 95914,
      "latitude": 39.409669,
      "longitude": -121.406274,
      "city": "Bangor",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95915,
      "latitude": 39.912222,
      "longitude": -120.834958,
      "city": "Belden",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95916,
      "latitude": 39.653813,
      "longitude": -121.383744,
      "city": "Berry Creek",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95917,
      "latitude": 39.40672,
      "longitude": -121.753522,
      "city": "Biggs",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95918,
      "latitude": 39.321808,
      "longitude": -121.367758,
      "city": "Browns Valley",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95919,
      "latitude": 39.425005,
      "longitude": -121.273889,
      "city": "Brownsville",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95920,
      "latitude": 39.458507,
      "longitude": -121.934145,
      "city": "Butte City",
      "state": "CA",
      "county": "Glenn"
  },
  {
      "zipCode": 95922,
      "latitude": 39.414386,
      "longitude": -121.111454,
      "city": "Camptonville",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95923,
      "latitude": 40.211176,
      "longitude": -121.120635,
      "city": "Canyondam",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95924,
      "latitude": 39.26599,
      "longitude": -120.64145,
      "city": "Cedar Ridge",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95925,
      "latitude": 39.467095,
      "longitude": -121.204452,
      "city": "Challenge",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95926,
      "latitude": 39.746159,
      "longitude": -121.840083,
      "city": "Chico",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95927,
      "latitude": 39.805255,
      "longitude": -121.684124,
      "city": "Chico",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95928,
      "latitude": 39.687626,
      "longitude": -121.799166,
      "city": "Chico",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95929,
      "latitude": 39.731406,
      "longitude": -121.801215,
      "city": "Chico",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95930,
      "latitude": 39.543835,
      "longitude": -121.220469,
      "city": "Clipper Mills",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95931,
      "latitude": 39.169245,
      "longitude": -122.290204,
      "city": "College City",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95932,
      "latitude": 39.203958,
      "longitude": -122.221269,
      "city": "Colusa",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95934,
      "latitude": 40.086309,
      "longitude": -120.924541,
      "city": "Crescent Mills",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95935,
      "latitude": 39.377751,
      "longitude": -121.188917,
      "city": "Dobbins",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95936,
      "latitude": 39.558607,
      "longitude": -120.853714,
      "city": "Downieville",
      "state": "CA",
      "county": "Sierra"
  },
  {
      "zipCode": 95937,
      "latitude": 38.835384,
      "longitude": -122.057921,
      "city": "Dunnigan",
      "state": "CA",
      "county": "Yolo"
  },
  {
      "zipCode": 95938,
      "latitude": 39.626362,
      "longitude": -121.777546,
      "city": "Durham",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95939,
      "latitude": 39.54842,
      "longitude": -122.567428,
      "city": "Elk Creek",
      "state": "CA",
      "county": "Glenn"
  },
  {
      "zipCode": 95940,
      "latitude": 39.585648,
      "longitude": -121.259009,
      "city": "Feather Falls",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95941,
      "latitude": 39.528374,
      "longitude": -121.265237,
      "city": "Forbestown",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95942,
      "latitude": 39.947462,
      "longitude": -121.582814,
      "city": "Forest Ranch",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95943,
      "latitude": 39.596546,
      "longitude": -122.032181,
      "city": "Glenn",
      "state": "CA",
      "county": "Glenn"
  },
  {
      "zipCode": 95944,
      "latitude": 39.49253,
      "longitude": -120.898248,
      "city": "Goodyears Bar",
      "state": "CA",
      "county": "Sierra"
  },
  {
      "zipCode": 95945,
      "latitude": 39.196288,
      "longitude": -120.95855,
      "city": "Grass Valley",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95946,
      "latitude": 39.21656,
      "longitude": -121.117883,
      "city": "Penn Valley",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95947,
      "latitude": 40.124832,
      "longitude": -120.87884,
      "city": "Greenville",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95948,
      "latitude": 39.355492,
      "longitude": -121.724685,
      "city": "Gridley",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95949,
      "latitude": 39.110986,
      "longitude": -121.128336,
      "city": "Grass Valley",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95950,
      "latitude": 39.065895,
      "longitude": -121.908208,
      "city": "Grimes",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95951,
      "latitude": 39.728209,
      "longitude": -122.053823,
      "city": "Hamilton City",
      "state": "CA",
      "county": "Glenn"
  },
  {
      "zipCode": 95953,
      "latitude": 39.236141,
      "longitude": -121.767177,
      "city": "Live Oak",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95954,
      "latitude": 39.867959,
      "longitude": -121.599628,
      "city": "Magalia",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95955,
      "latitude": 39.2847,
      "longitude": -122.210089,
      "city": "Maxwell",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95956,
      "latitude": 39.896259,
      "longitude": -121.096023,
      "city": "Meadow Valley",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95957,
      "latitude": 39.066931,
      "longitude": -121.848565,
      "city": "Meridian",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95958,
      "latitude": 39.556251,
      "longitude": -121.803777,
      "city": "Nelson",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95959,
      "latitude": 39.299606,
      "longitude": -120.887965,
      "city": "Nevada City",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95960,
      "latitude": 39.330529,
      "longitude": -121.084241,
      "city": "North San Juan",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95961,
      "latitude": 39.214767,
      "longitude": -121.391149,
      "city": "Olivehurst",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95962,
      "latitude": 39.210524,
      "longitude": -121.347605,
      "city": "Oregon House",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95963,
      "latitude": 39.631197,
      "longitude": -122.327137,
      "city": "Orland",
      "state": "CA",
      "county": "Glenn"
  },
  {
      "zipCode": 95965,
      "latitude": 39.578352,
      "longitude": -121.58212,
      "city": "Oroville",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95966,
      "latitude": 39.463113,
      "longitude": -121.417372,
      "city": "Oroville",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95967,
      "latitude": 39.723898,
      "longitude": -121.572986,
      "city": "Paradise",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95968,
      "latitude": 39.443039,
      "longitude": -121.55118,
      "city": "Palermo",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95969,
      "latitude": 39.654279,
      "longitude": -121.707265,
      "city": "Paradise",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95970,
      "latitude": 39.379166,
      "longitude": -122.095709,
      "city": "Princeton",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95971,
      "latitude": 40.005131,
      "longitude": -121.017725,
      "city": "Quincy",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95972,
      "latitude": 39.44442,
      "longitude": -121.318329,
      "city": "Rackerby",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95973,
      "latitude": 39.837577,
      "longitude": -121.869949,
      "city": "Chico",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95974,
      "latitude": 39.50107,
      "longitude": -121.754346,
      "city": "Richvale",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95975,
      "latitude": 39.225477,
      "longitude": -121.148643,
      "city": "Rough And Ready",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95976,
      "latitude": 39.723898,
      "longitude": -121.572986,
      "city": "Chico",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95977,
      "latitude": 39.19443,
      "longitude": -121.314435,
      "city": "Smartville",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95978,
      "latitude": 39.995078,
      "longitude": -121.47936,
      "city": "Stirling City",
      "state": "CA",
      "county": "Butte"
  },
  {
      "zipCode": 95979,
      "latitude": 39.28207,
      "longitude": -122.431045,
      "city": "Stonyford",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95980,
      "latitude": 39.918503,
      "longitude": -121.337404,
      "city": "Storrie",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95981,
      "latitude": 39.577329,
      "longitude": -121.101562,
      "city": "Strawberry Valley",
      "state": "CA",
      "county": "Yuba"
  },
  {
      "zipCode": 95982,
      "latitude": 39.172636,
      "longitude": -121.80251,
      "city": "Sutter",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95983,
      "latitude": 39.963458,
      "longitude": -120.77591,
      "city": "Taylorsville",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95984,
      "latitude": 39.950913,
      "longitude": -121.094912,
      "city": "Twain",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 95986,
      "latitude": 39.352343,
      "longitude": -120.79118,
      "city": "Washington",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 95987,
      "latitude": 39.142737,
      "longitude": -122.243739,
      "city": "Williams",
      "state": "CA",
      "county": "Colusa"
  },
  {
      "zipCode": 95988,
      "latitude": 39.519386,
      "longitude": -122.292996,
      "city": "Willows",
      "state": "CA",
      "county": "Glenn"
  },
  {
      "zipCode": 95991,
      "latitude": 39.048854,
      "longitude": -121.612481,
      "city": "Yuba City",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95992,
      "latitude": 39.020133,
      "longitude": -121.681345,
      "city": "Yuba City",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 95993,
      "latitude": 39.130338,
      "longitude": -121.737911,
      "city": "Yuba City",
      "state": "CA",
      "county": "Sutter"
  },
  {
      "zipCode": 96001,
      "latitude": 40.675738,
      "longitude": -122.456982,
      "city": "Redding",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96002,
      "latitude": 40.624448,
      "longitude": -122.3076,
      "city": "Redding",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96003,
      "latitude": 40.71711,
      "longitude": -122.279493,
      "city": "Redding",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96006,
      "latitude": 41.236677,
      "longitude": -120.932949,
      "city": "Adin",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96007,
      "latitude": 40.469324,
      "longitude": -122.278404,
      "city": "Anderson",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96008,
      "latitude": 40.715565,
      "longitude": -122.11939,
      "city": "Bella Vista",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96009,
      "latitude": 41.131506,
      "longitude": -121.128616,
      "city": "Bieber",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96010,
      "latitude": 40.672576,
      "longitude": -123.034604,
      "city": "Big Bar",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96011,
      "latitude": 40.974917,
      "longitude": -121.82502,
      "city": "Big Bend",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96013,
      "latitude": 40.942396,
      "longitude": -121.687076,
      "city": "Burney",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96014,
      "latitude": 41.281549,
      "longitude": -122.875526,
      "city": "Callahan",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96015,
      "latitude": 41.458738,
      "longitude": -120.80293,
      "city": "Canby",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96016,
      "latitude": 40.933652,
      "longitude": -121.56766,
      "city": "Cassel",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96017,
      "latitude": 41.110338,
      "longitude": -122.316052,
      "city": "Castella",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96019,
      "latitude": 40.69686,
      "longitude": -122.368263,
      "city": "Shasta Lake",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96020,
      "latitude": 40.277282,
      "longitude": -121.200726,
      "city": "Chester",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 96021,
      "latitude": 40.125399,
      "longitude": -122.135411,
      "city": "Corning",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96022,
      "latitude": 40.439989,
      "longitude": -122.429495,
      "city": "Cottonwood",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96023,
      "latitude": 41.919428,
      "longitude": -121.973887,
      "city": "Dorris",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96024,
      "latitude": 40.668853,
      "longitude": -122.942468,
      "city": "Douglas City",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96025,
      "latitude": 41.45574,
      "longitude": -122.390196,
      "city": "Dunsmuir",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96027,
      "latitude": 41.44631,
      "longitude": -123.010024,
      "city": "Etna",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96028,
      "latitude": 41.034309,
      "longitude": -121.471061,
      "city": "Fall River Mills",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96029,
      "latitude": 39.902424,
      "longitude": -122.491767,
      "city": "Flournoy",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96031,
      "latitude": 41.219117,
      "longitude": -123.23633,
      "city": "Forks Of Salmon",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96032,
      "latitude": 41.581383,
      "longitude": -122.824847,
      "city": "Fort Jones",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96033,
      "latitude": 40.689232,
      "longitude": -122.58753,
      "city": "French Gulch",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96034,
      "latitude": 41.441483,
      "longitude": -122.699937,
      "city": "Gazelle",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96035,
      "latitude": 40.049209,
      "longitude": -122.209437,
      "city": "Gerber",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96037,
      "latitude": 41.540332,
      "longitude": -122.936575,
      "city": "Greenview",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96038,
      "latitude": 41.63468,
      "longitude": -122.46953,
      "city": "Grenada",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96039,
      "latitude": 41.666514,
      "longitude": -123.421603,
      "city": "Happy Camp",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96040,
      "latitude": 40.808623,
      "longitude": -121.499992,
      "city": "Hat Creek",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96041,
      "latitude": 40.500622,
      "longitude": -123.167247,
      "city": "Hayfork",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96044,
      "latitude": 41.939792,
      "longitude": -122.525589,
      "city": "Hornbrook",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96046,
      "latitude": 40.613736,
      "longitude": -123.448823,
      "city": "Hyampom",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96047,
      "latitude": 40.478246,
      "longitude": -122.722296,
      "city": "Igo",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96048,
      "latitude": 40.741117,
      "longitude": -123.071788,
      "city": "Junction City",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96049,
      "latitude": 40.709783,
      "longitude": -122.311628,
      "city": "Redding",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96050,
      "latitude": 41.871172,
      "longitude": -122.977919,
      "city": "Klamath River",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96051,
      "latitude": 40.910481,
      "longitude": -122.410576,
      "city": "Lakehead",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96052,
      "latitude": 40.736068,
      "longitude": -122.837262,
      "city": "Lewiston",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96054,
      "latitude": 41.234693,
      "longitude": -121.215582,
      "city": "Lookout",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96055,
      "latitude": 40.039203,
      "longitude": -122.13374,
      "city": "Los Molinos",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96056,
      "latitude": 41.096751,
      "longitude": -121.436822,
      "city": "Mcarthur",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96057,
      "latitude": 41.247548,
      "longitude": -122.112813,
      "city": "Mccloud",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96058,
      "latitude": 41.787881,
      "longitude": -121.911394,
      "city": "Macdoel",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96059,
      "latitude": 40.420879,
      "longitude": -121.864689,
      "city": "Manton",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96061,
      "latitude": 40.343925,
      "longitude": -121.503495,
      "city": "Mill Creek",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96062,
      "latitude": 40.570081,
      "longitude": -122.067214,
      "city": "Millville",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96063,
      "latitude": 40.356403,
      "longitude": -121.57102,
      "city": "Mineral",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96064,
      "latitude": 41.757491,
      "longitude": -122.196783,
      "city": "Montague",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96065,
      "latitude": 40.900945,
      "longitude": -121.916728,
      "city": "Montgomery Creek",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96067,
      "latitude": 41.427158,
      "longitude": -122.473307,
      "city": "Mount Shasta",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96068,
      "latitude": 41.129955,
      "longitude": -121.134222,
      "city": "Nubieber",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96069,
      "latitude": 40.691529,
      "longitude": -122.042324,
      "city": "Oak Run",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96070,
      "latitude": 40.735153,
      "longitude": -122.1944,
      "city": "Obrien",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96071,
      "latitude": 40.658943,
      "longitude": -121.449467,
      "city": "Old Station",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96073,
      "latitude": 40.56844,
      "longitude": -122.201552,
      "city": "Palo Cedro",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96074,
      "latitude": 39.877198,
      "longitude": -122.581386,
      "city": "Paskenta",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96075,
      "latitude": 40.364148,
      "longitude": -121.841348,
      "city": "Paynes Creek",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96076,
      "latitude": 40.375954,
      "longitude": -122.936967,
      "city": "Platina",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96078,
      "latitude": 40.12517,
      "longitude": -122.204141,
      "city": "Proberta",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96079,
      "latitude": 40.686639,
      "longitude": -122.334778,
      "city": "Shasta Lake",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96080,
      "latitude": 40.142907,
      "longitude": -122.340888,
      "city": "Red Bluff",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96084,
      "latitude": 40.762944,
      "longitude": -122.171093,
      "city": "Round Mountain",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96085,
      "latitude": 41.728874,
      "longitude": -123.03007,
      "city": "Scott Bar",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96086,
      "latitude": 41.833921,
      "longitude": -123.235079,
      "city": "Seiad Valley",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96087,
      "latitude": 40.601263,
      "longitude": -122.494455,
      "city": "Shasta",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96088,
      "latitude": 40.488664,
      "longitude": -121.960944,
      "city": "Shingletown",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96089,
      "latitude": 40.657893,
      "longitude": -122.427333,
      "city": "Shasta Lake",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96090,
      "latitude": 40.021383,
      "longitude": -122.118643,
      "city": "Tehama",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96091,
      "latitude": 41.050081,
      "longitude": -122.686387,
      "city": "Trinity Center",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96092,
      "latitude": 39.927203,
      "longitude": -122.025047,
      "city": "Vina",
      "state": "CA",
      "county": "Tehama"
  },
  {
      "zipCode": 96093,
      "latitude": 40.704907,
      "longitude": -122.956559,
      "city": "Weaverville",
      "state": "CA",
      "county": "Trinity"
  },
  {
      "zipCode": 96094,
      "latitude": 41.6661,
      "longitude": -122.408583,
      "city": "Weed",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96095,
      "latitude": 40.735153,
      "longitude": -122.1944,
      "city": "Whiskeytown",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96096,
      "latitude": 40.575131,
      "longitude": -121.86265,
      "city": "Whitmore",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96097,
      "latitude": 41.623828,
      "longitude": -122.440389,
      "city": "Yreka",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96099,
      "latitude": 40.704337,
      "longitude": -122.387808,
      "city": "Redding",
      "state": "CA",
      "county": "Shasta"
  },
  {
      "zipCode": 96101,
      "latitude": 41.433984,
      "longitude": -120.640865,
      "city": "Alturas",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96103,
      "latitude": 39.812686,
      "longitude": -120.640497,
      "city": "Blairsden-Graeagle",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 96104,
      "latitude": 41.42426,
      "longitude": -120.111396,
      "city": "Cedarville",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96105,
      "latitude": 39.794136,
      "longitude": -120.132207,
      "city": "Chilcoot",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 96106,
      "latitude": 39.754627,
      "longitude": -120.595092,
      "city": "Clio",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 96107,
      "latitude": 38.533336,
      "longitude": -119.485034,
      "city": "Coleville",
      "state": "CA",
      "county": "Mono"
  },
  {
      "zipCode": 96108,
      "latitude": 41.590548,
      "longitude": -120.727706,
      "city": "Davis Creek",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96109,
      "latitude": 39.962841,
      "longitude": -120.20247,
      "city": "Doyle",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96110,
      "latitude": 41.590548,
      "longitude": -120.727706,
      "city": "Eagleville",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96111,
      "latitude": 39.392752,
      "longitude": -120.021212,
      "city": "Floriston",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 96112,
      "latitude": 41.590548,
      "longitude": -120.727706,
      "city": "Fort Bidwell",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96113,
      "latitude": 40.101514,
      "longitude": -120.244727,
      "city": "Herlong",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96114,
      "latitude": 40.298255,
      "longitude": -120.506184,
      "city": "Janesville",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96115,
      "latitude": 41.763674,
      "longitude": -120.179132,
      "city": "Lake City",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96116,
      "latitude": 41.232908,
      "longitude": -120.507888,
      "city": "Likely",
      "state": "CA",
      "county": "Modoc"
  },
  {
      "zipCode": 96117,
      "latitude": 40.407309,
      "longitude": -120.40921,
      "city": "Litchfield",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96118,
      "latitude": 39.613008,
      "longitude": -120.285676,
      "city": "Loyalton",
      "state": "CA",
      "county": "Sierra"
  },
  {
      "zipCode": 96119,
      "latitude": 40.97664,
      "longitude": -120.554754,
      "city": "Madeline",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96120,
      "latitude": 38.771319,
      "longitude": -119.832669,
      "city": "Markleeville",
      "state": "CA",
      "county": "Alpine"
  },
  {
      "zipCode": 96121,
      "latitude": 40.196745,
      "longitude": -120.367586,
      "city": "Milford",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96122,
      "latitude": 39.800456,
      "longitude": -120.542224,
      "city": "Portola",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 96123,
      "latitude": 40.776154,
      "longitude": -120.326259,
      "city": "Ravendale",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96124,
      "latitude": 39.613937,
      "longitude": -120.404625,
      "city": "Calpine",
      "state": "CA",
      "county": "Sierra"
  },
  {
      "zipCode": 96125,
      "latitude": 39.593596,
      "longitude": -120.626908,
      "city": "Sierra City",
      "state": "CA",
      "county": "Sierra"
  },
  {
      "zipCode": 96126,
      "latitude": 39.582462,
      "longitude": -120.371073,
      "city": "Sierraville",
      "state": "CA",
      "county": "Sierra"
  },
  {
      "zipCode": 96127,
      "latitude": 40.446063,
      "longitude": -120.664132,
      "city": "Susanville",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96128,
      "latitude": 40.341664,
      "longitude": -120.423118,
      "city": "Standish",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96129,
      "latitude": 39.772064,
      "longitude": -120.405095,
      "city": "Beckwourth",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 96130,
      "latitude": 40.548881,
      "longitude": -120.719159,
      "city": "Susanville",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96132,
      "latitude": 40.950868,
      "longitude": -120.612954,
      "city": "Termo",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96133,
      "latitude": 38.617086,
      "longitude": -119.506094,
      "city": "Topaz",
      "state": "CA",
      "county": "Mono"
  },
  {
      "zipCode": 96134,
      "latitude": 41.93519,
      "longitude": -121.58865,
      "city": "Tulelake",
      "state": "CA",
      "county": "Siskiyou"
  },
  {
      "zipCode": 96135,
      "latitude": 40.02349,
      "longitude": -120.79861,
      "city": "Vinton",
      "state": "CA",
      "county": "Plumas"
  },
  {
      "zipCode": 96136,
      "latitude": 40.34056,
      "longitude": -120.282398,
      "city": "Wendel",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96137,
      "latitude": 40.303792,
      "longitude": -121.022584,
      "city": "Westwood",
      "state": "CA",
      "county": "Lassen"
  },
  {
      "zipCode": 96140,
      "latitude": 39.229978,
      "longitude": -120.066235,
      "city": "Carnelian Bay",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 96141,
      "latitude": 39.122962,
      "longitude": -120.178327,
      "city": "Homewood",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 96142,
      "latitude": 38.998714,
      "longitude": -120.107955,
      "city": "Tahoma",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96143,
      "latitude": 39.201123,
      "longitude": -120.083236,
      "city": "Kings Beach",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 96145,
      "latitude": 39.163631,
      "longitude": -120.131614,
      "city": "Tahoe City",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 96146,
      "latitude": 39.175186,
      "longitude": -120.19544,
      "city": "Olympic Valley",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 96148,
      "latitude": 39.2226,
      "longitude": -120.068988,
      "city": "Tahoe Vista",
      "state": "CA",
      "county": "Placer"
  },
  {
      "zipCode": 96150,
      "latitude": 38.927507,
      "longitude": -120.039632,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96151,
      "latitude": 38.90392,
      "longitude": -119.995024,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96152,
      "latitude": 38.927149,
      "longitude": -119.999032,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96154,
      "latitude": 38.87529,
      "longitude": -120.0188,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96155,
      "latitude": 38.844909,
      "longitude": -120.042996,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96156,
      "latitude": 38.935245,
      "longitude": -119.967631,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96157,
      "latitude": 38.934409,
      "longitude": -119.976707,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96158,
      "latitude": 38.898142,
      "longitude": -119.998419,
      "city": "South Lake Tahoe",
      "state": "CA",
      "county": "El Dorado"
  },
  {
      "zipCode": 96160,
      "latitude": 39.3781,
      "longitude": -120.186383,
      "city": "Truckee",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 96161,
      "latitude": 39.377677,
      "longitude": -120.407502,
      "city": "Truckee",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 96162,
      "latitude": 39.26599,
      "longitude": -120.64145,
      "city": "Truckee",
      "state": "CA",
      "county": "Nevada"
  },
  {
      "zipCode": 96201,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96202,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96203,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96204,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96205,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96206,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96207,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96208,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96212,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96213,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96214,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96215,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96217,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96218,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96219,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96220,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96221,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96224,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96251,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96257,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96258,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96259,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96260,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96262,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96264,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96266,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96267,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96269,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96271,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96275,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96276,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96278,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96283,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96284,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96297,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96306,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96309,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96310,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96311,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96313,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96319,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96321,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96322,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96323,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96325,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96326,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96328,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96330,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96336,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96337,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96338,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96343,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96347,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96348,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96349,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96350,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96351,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96362,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96364,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96365,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96367,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96368,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96370,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96372,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96373,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96374,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96375,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96376,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96377,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96378,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96379,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96384,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96386,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96387,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96388,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96403,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96490,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96505,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96506,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96507,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96508,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96511,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96512,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96515,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96517,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96518,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96520,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96521,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96522,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96529,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96530,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96531,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96534,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96535,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96536,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96537,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96538,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96539,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96540,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96541,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96542,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96543,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96544,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96545,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96546,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96547,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96548,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96549,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96551,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96552,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96553,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96554,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96555,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96557,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96558,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96575,
      "latitude": "",
      "longitude": "",
      "city": "APO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96580,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96594,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96595,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96596,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96597,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96598,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96599,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96601,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96602,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96603,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96604,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96605,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96606,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96607,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96608,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96609,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96610,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96611,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96612,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96615,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96617,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96621,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96622,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96623,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96624,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96626,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96627,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96628,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96629,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96634,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96635,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96642,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96643,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96644,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96647,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96648,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96649,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96657,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96660,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96661,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96662,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96663,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96664,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96665,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96666,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96667,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96668,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96669,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96670,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96671,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96672,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96673,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96674,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96675,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96676,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96677,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96678,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96679,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96681,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96682,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96683,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96684,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96686,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96687,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96698,
      "latitude": "",
      "longitude": "",
      "city": "FPO",
      "state": "AP",
      "county": ""
  },
  {
      "zipCode": 96701,
      "latitude": 21.39065,
      "longitude": -157.927829,
      "city": "Aiea",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96703,
      "latitude": 22.055787,
      "longitude": -159.454519,
      "city": "Anahola",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96704,
      "latitude": 19.269226,
      "longitude": -155.757929,
      "city": "Captain Cook",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96705,
      "latitude": 21.937654,
      "longitude": -159.645162,
      "city": "Eleele",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96706,
      "latitude": 21.34455,
      "longitude": -158.01395,
      "city": "Ewa Beach",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96707,
      "latitude": 21.341784,
      "longitude": -158.091383,
      "city": "Kapolei",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96708,
      "latitude": 20.849675,
      "longitude": -156.165622,
      "city": "Haiku",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96709,
      "latitude": 23.573339,
      "longitude": -164.701172,
      "city": "Kapolei",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96710,
      "latitude": 19.911689,
      "longitude": -155.148483,
      "city": "Hakalau",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96712,
      "latitude": 21.63185,
      "longitude": -158.03645,
      "city": "Haleiwa",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96713,
      "latitude": 20.725172,
      "longitude": -156.02293,
      "city": "Hana",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96714,
      "latitude": 22.213592,
      "longitude": -159.482796,
      "city": "Hanalei",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96715,
      "latitude": 21.979991,
      "longitude": -159.512392,
      "city": "Hanamaulu",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96716,
      "latitude": 22.020489,
      "longitude": -159.617765,
      "city": "Hanapepe",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96717,
      "latitude": 21.583507,
      "longitude": -157.882511,
      "city": "Hauula",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96718,
      "latitude": 19.593456,
      "longitude": -155.438015,
      "city": "Hawaii National Park",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96719,
      "latitude": 20.222737,
      "longitude": -155.818291,
      "city": "Hawi",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96720,
      "latitude": 19.564519,
      "longitude": -155.26203,
      "city": "Hilo",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96721,
      "latitude": 19.593456,
      "longitude": -155.438015,
      "city": "Hilo",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96722,
      "latitude": 22.219231,
      "longitude": -159.486562,
      "city": "Princeville",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96725,
      "latitude": 19.618904,
      "longitude": -155.948308,
      "city": "Holualoa",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96726,
      "latitude": 19.278298,
      "longitude": -155.845329,
      "city": "Honaunau",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96727,
      "latitude": 20.079156,
      "longitude": -155.507938,
      "city": "Honokaa",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96728,
      "latitude": 19.593456,
      "longitude": -155.438015,
      "city": "Honomu",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96729,
      "latitude": 21.163056,
      "longitude": -157.0766,
      "city": "Hoolehua",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96730,
      "latitude": 21.585127,
      "longitude": -157.883316,
      "city": "Kaaawa",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96731,
      "latitude": 21.6277,
      "longitude": -157.935304,
      "city": "Kahuku",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96732,
      "latitude": 20.859244,
      "longitude": -156.259166,
      "city": "Kahului",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96733,
      "latitude": 20.866638,
      "longitude": -156.646667,
      "city": "Kahului",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96734,
      "latitude": 21.595644,
      "longitude": -158.039121,
      "city": "Kailua",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96737,
      "latitude": 19.100167,
      "longitude": -155.725803,
      "city": "Ocean View",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96738,
      "latitude": 19.972371,
      "longitude": -155.817987,
      "city": "Waikoloa",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96739,
      "latitude": 19.593456,
      "longitude": -155.438015,
      "city": "Keauhou",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96740,
      "latitude": 19.674123,
      "longitude": -155.936565,
      "city": "Kailua Kona",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96741,
      "latitude": 21.925909,
      "longitude": -159.530254,
      "city": "Kalaheo",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96742,
      "latitude": 20.866638,
      "longitude": -156.646667,
      "city": "Kalaupapa",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96743,
      "latitude": 19.887872,
      "longitude": -155.751907,
      "city": "Kamuela",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96744,
      "latitude": 21.66875,
      "longitude": -157.93955,
      "city": "Kaneohe",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96745,
      "latitude": 19.593456,
      "longitude": -155.438015,
      "city": "Kailua Kona",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96746,
      "latitude": 22.074197,
      "longitude": -159.406197,
      "city": "Kapaa",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96747,
      "latitude": 21.945428,
      "longitude": -159.921351,
      "city": "Kaumakani",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96748,
      "latitude": 21.092266,
      "longitude": -157.012533,
      "city": "Kaunakakai",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96749,
      "latitude": 19.581873,
      "longitude": -155.032104,
      "city": "Keaau",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96750,
      "latitude": 19.343913,
      "longitude": -155.874923,
      "city": "Kealakekua",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96751,
      "latitude": 22.105955,
      "longitude": -159.308471,
      "city": "Kealia",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96752,
      "latitude": 21.971285,
      "longitude": -159.713792,
      "city": "Kekaha",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96753,
      "latitude": 20.723542,
      "longitude": -156.469915,
      "city": "Kihei",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96754,
      "latitude": 22.207314,
      "longitude": -159.395386,
      "city": "Kilauea",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96755,
      "latitude": 20.226101,
      "longitude": -155.776957,
      "city": "Kapaau",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96756,
      "latitude": 22.028379,
      "longitude": -159.432992,
      "city": "Koloa",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96757,
      "latitude": 21.16101,
      "longitude": -157.02544,
      "city": "Kualapuu",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96759,
      "latitude": 21.463,
      "longitude": -158.06385,
      "city": "Kunia",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96760,
      "latitude": 19.569277,
      "longitude": -155.034859,
      "city": "Kurtistown",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96761,
      "latitude": 20.919823,
      "longitude": -156.717614,
      "city": "Lahaina",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96762,
      "latitude": 21.61095,
      "longitude": -157.90205,
      "city": "Laie",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96763,
      "latitude": 20.812854,
      "longitude": -156.95283,
      "city": "Lanai City",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96764,
      "latitude": 19.985166,
      "longitude": -155.248429,
      "city": "Laupahoehoe",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96765,
      "latitude": 21.930922,
      "longitude": -159.499342,
      "city": "Lawai",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96766,
      "latitude": 22.070553,
      "longitude": -159.430328,
      "city": "Lihue",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96767,
      "latitude": 20.866638,
      "longitude": -156.646667,
      "city": "Lahaina",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96768,
      "latitude": 20.832664,
      "longitude": -156.320242,
      "city": "Makawao",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96769,
      "latitude": 21.945428,
      "longitude": -159.921351,
      "city": "Makaweli",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96770,
      "latitude": 21.176357,
      "longitude": -157.257157,
      "city": "Maunaloa",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96771,
      "latitude": 19.534945,
      "longitude": -155.125657,
      "city": "Mountain View",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96772,
      "latitude": 19.052137,
      "longitude": -155.622528,
      "city": "Naalehu",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96773,
      "latitude": 19.935047,
      "longitude": -155.166821,
      "city": "Ninole",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96774,
      "latitude": 20.01506,
      "longitude": -155.280974,
      "city": "Ookala",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96775,
      "latitude": 19.593456,
      "longitude": -155.438015,
      "city": "Paauhau",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96776,
      "latitude": 20.035356,
      "longitude": -155.377961,
      "city": "Paauilo",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96777,
      "latitude": 19.593456,
      "longitude": -155.438015,
      "city": "Pahala",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96778,
      "latitude": 19.390023,
      "longitude": -155.152268,
      "city": "Pahoa",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96779,
      "latitude": 20.904275,
      "longitude": -156.373506,
      "city": "Paia",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96780,
      "latitude": 19.964138,
      "longitude": -155.201267,
      "city": "Papaaloa",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96781,
      "latitude": 19.798878,
      "longitude": -155.116093,
      "city": "Papaikou",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96782,
      "latitude": 21.51195,
      "longitude": -157.994662,
      "city": "Pearl City",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96783,
      "latitude": 19.819475,
      "longitude": -155.106308,
      "city": "Pepeekeo",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96784,
      "latitude": 20.866638,
      "longitude": -156.646667,
      "city": "Puunene",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96785,
      "latitude": 19.440523,
      "longitude": -155.250569,
      "city": "Volcano",
      "state": "HI",
      "county": "Hawaii"
  },
  {
      "zipCode": 96786,
      "latitude": 21.5324,
      "longitude": -158.047735,
      "city": "Wahiawa",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96788,
      "latitude": 20.855864,
      "longitude": -156.325923,
      "city": "Pukalani",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96789,
      "latitude": 21.458855,
      "longitude": -158.012322,
      "city": "Mililani",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96790,
      "latitude": 20.753763,
      "longitude": -156.329499,
      "city": "Kula",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96791,
      "latitude": 21.55325,
      "longitude": -158.163415,
      "city": "Waialua",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96792,
      "latitude": 21.437389,
      "longitude": -158.181356,
      "city": "Waianae",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96793,
      "latitude": 20.901536,
      "longitude": -156.566493,
      "city": "Wailuku",
      "state": "HI",
      "county": "Maui"
  },
  {
      "zipCode": 96795,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Waimanalo",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96796,
      "latitude": 21.966709,
      "longitude": -159.686193,
      "city": "Waimea",
      "state": "HI",
      "county": "Kauai"
  },
  {
      "zipCode": 96797,
      "latitude": 21.399548,
      "longitude": -158.015151,
      "city": "Waipahu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96799,
      "latitude": -7.209975,
      "longitude": -170.7716,
      "city": "Pago Pago",
      "state": "AS",
      "county": "American Samoa"
  },
  {
      "zipCode": 96801,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96802,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96803,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96804,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96805,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96806,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96807,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96808,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96809,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96810,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96811,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96812,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96813,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96814,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96815,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96816,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96817,
      "latitude": 21.65098,
      "longitude": -157.922303,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96818,
      "latitude": 21.347951,
      "longitude": -157.93965,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96819,
      "latitude": 21.373179,
      "longitude": -157.901979,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96820,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96821,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96822,
      "latitude": 21.67115,
      "longitude": -157.94085,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96823,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96824,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96825,
      "latitude": 21.670926,
      "longitude": -157.940731,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96826,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96827,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96828,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96830,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96835,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96836,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96837,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96838,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96839,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96840,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96841,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96842,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96843,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96844,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96845,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96846,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96847,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96848,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96849,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96850,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Honolulu",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96853,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Hickam Afb",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96854,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Wheeler Army Airfield",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96857,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Schofield Barracks",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96858,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Fort Shafter",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96859,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Tripler Army Medical Ctr",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96860,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Pearl Harbor",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96861,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Camp H M Smith",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96862,
      "latitude": 21.319,
      "longitude": -158.0749,
      "city": "Barbers Point",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96863,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "M C B H Kaneohe Bay",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96898,
      "latitude": 24.859832,
      "longitude": -168.021815,
      "city": "Wake Island",
      "state": "HI",
      "county": "Honolulu"
  },
  {
      "zipCode": 96910,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Hagatna",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96911,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Tamuning",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96912,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Dededo",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96913,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Barrigada",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96914,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Yona",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96915,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Santa Rita",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96916,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Merizo",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96917,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Inarajan",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96918,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Umatac",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96919,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Agana Heights",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96921,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Barrigada",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96922,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Asan",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96923,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Mangilao",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96925,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Piti",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96926,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Sinajana",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96927,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Mongmong",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96928,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Agat",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96929,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Yigo",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96930,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Talofofo",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96931,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Tamuning",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96932,
      "latitude": 13.444257,
      "longitude": 144.786297,
      "city": "Hagatna",
      "state": "GU",
      "county": "Guam"
  },
  {
      "zipCode": 96940,
      "latitude": 7.225664,
      "longitude": 134.362169,
      "city": "Palau",
      "state": "PW",
      "county": "Palau"
  },
  {
      "zipCode": 96941,
      "latitude": 7.138297,
      "longitude": 151.503116,
      "city": "Pohnpei",
      "state": "FM",
      "county": "Federated States Of Micro"
  },
  {
      "zipCode": 96942,
      "latitude": 7.138297,
      "longitude": 151.503116,
      "city": "Chuuk",
      "state": "FM",
      "county": "Federated States Of Micro"
  },
  {
      "zipCode": 96943,
      "latitude": 7.138297,
      "longitude": 151.503116,
      "city": "Yap",
      "state": "FM",
      "county": "Federated States Of Micro"
  },
  {
      "zipCode": 96944,
      "latitude": 7.138297,
      "longitude": 151.503116,
      "city": "Kosrae",
      "state": "FM",
      "county": "Federated States Of Micro"
  },
  {
      "zipCode": 96950,
      "latitude": 15.190135,
      "longitude": 145.740804,
      "city": "Saipan",
      "state": "MP",
      "county": "Northern Mariana Islands"
  },
  {
      "zipCode": 96951,
      "latitude": 15.190135,
      "longitude": 145.740804,
      "city": "Rota",
      "state": "MP",
      "county": "Northern Mariana Islands"
  },
  {
      "zipCode": 96952,
      "latitude": 15.190135,
      "longitude": 145.740804,
      "city": "Tinian",
      "state": "MP",
      "county": "Northern Mariana Islands"
  },
  {
      "zipCode": 96960,
      "latitude": 11.140496,
      "longitude": 166.410291,
      "city": "Majuro",
      "state": "MH",
      "county": "Marshall Islands"
  },
  {
      "zipCode": 96970,
      "latitude": 11.140496,
      "longitude": 166.410291,
      "city": "Ebeye",
      "state": "MH",
      "county": "Marshall Islands"
  },
  {
      "zipCode": 97001,
      "latitude": 45.263301,
      "longitude": -121.088513,
      "city": "Antelope",
      "state": "OR",
      "county": "Wasco"
  },
  {
      "zipCode": 97002,
      "latitude": 45.224241,
      "longitude": -122.8198,
      "city": "Aurora",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97004,
      "latitude": 45.247636,
      "longitude": -122.468991,
      "city": "Beavercreek",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97005,
      "latitude": 45.496289,
      "longitude": -122.800146,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97006,
      "latitude": 45.52013,
      "longitude": -122.860376,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97007,
      "latitude": 45.450489,
      "longitude": -122.865171,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97008,
      "latitude": 45.45602,
      "longitude": -122.799559,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97009,
      "latitude": 45.411842,
      "longitude": -122.37642,
      "city": "Boring",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97010,
      "latitude": 45.582981,
      "longitude": -122.101103,
      "city": "Bridal Veil",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97011,
      "latitude": 45.359095,
      "longitude": -121.998736,
      "city": "Brightwood",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97013,
      "latitude": 45.214288,
      "longitude": -122.661593,
      "city": "Canby",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97014,
      "latitude": 45.671017,
      "longitude": -121.868565,
      "city": "Cascade Locks",
      "state": "OR",
      "county": "Hood River"
  },
  {
      "zipCode": 97015,
      "latitude": 45.415038,
      "longitude": -122.499461,
      "city": "Clackamas",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97016,
      "latitude": 46.066499,
      "longitude": -123.205886,
      "city": "Clatskanie",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97017,
      "latitude": 45.1887,
      "longitude": -122.397774,
      "city": "Colton",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97018,
      "latitude": 45.892304,
      "longitude": -122.814191,
      "city": "Columbia City",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97019,
      "latitude": 45.51827,
      "longitude": -122.229299,
      "city": "Corbett",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97020,
      "latitude": 45.219924,
      "longitude": -122.840536,
      "city": "Donald",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97021,
      "latitude": 45.391278,
      "longitude": -121.143152,
      "city": "Dufur",
      "state": "OR",
      "county": "Wasco"
  },
  {
      "zipCode": 97022,
      "latitude": 45.346076,
      "longitude": -122.312496,
      "city": "Eagle Creek",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97023,
      "latitude": 45.226738,
      "longitude": -122.247547,
      "city": "Estacada",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97024,
      "latitude": 45.544407,
      "longitude": -122.53636,
      "city": "Fairview",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97026,
      "latitude": 45.120045,
      "longitude": -122.935106,
      "city": "Gervais",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97027,
      "latitude": 45.390442,
      "longitude": -122.59322,
      "city": "Gladstone",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97028,
      "latitude": 45.297173,
      "longitude": -121.739004,
      "city": "Government Camp",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97029,
      "latitude": 45.306543,
      "longitude": -120.797313,
      "city": "Grass Valley",
      "state": "OR",
      "county": "Sherman"
  },
  {
      "zipCode": 97030,
      "latitude": 45.508117,
      "longitude": -122.430233,
      "city": "Gresham",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97031,
      "latitude": 45.638312,
      "longitude": -121.677126,
      "city": "Hood River",
      "state": "OR",
      "county": "Hood River"
  },
  {
      "zipCode": 97032,
      "latitude": 45.1969,
      "longitude": -122.812012,
      "city": "Hubbard",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97033,
      "latitude": 45.237277,
      "longitude": -120.626298,
      "city": "Kent",
      "state": "OR",
      "county": "Sherman"
  },
  {
      "zipCode": 97034,
      "latitude": 45.408374,
      "longitude": -122.691388,
      "city": "Lake Oswego",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97035,
      "latitude": 45.408077,
      "longitude": -122.723058,
      "city": "Lake Oswego",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97036,
      "latitude": 45.173284,
      "longitude": -122.259861,
      "city": "Marylhurst",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97037,
      "latitude": 45.155868,
      "longitude": -121.338132,
      "city": "Maupin",
      "state": "OR",
      "county": "Wasco"
  },
  {
      "zipCode": 97038,
      "latitude": 45.078408,
      "longitude": -122.565028,
      "city": "Molalla",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97039,
      "latitude": 45.502564,
      "longitude": -120.684499,
      "city": "Moro",
      "state": "OR",
      "county": "Sherman"
  },
  {
      "zipCode": 97040,
      "latitude": 45.65079,
      "longitude": -121.37902,
      "city": "Mosier",
      "state": "OR",
      "county": "Wasco"
  },
  {
      "zipCode": 97041,
      "latitude": 45.492879,
      "longitude": -121.598308,
      "city": "Mount Hood Parkdale",
      "state": "OR",
      "county": "Hood River"
  },
  {
      "zipCode": 97042,
      "latitude": 45.201439,
      "longitude": -122.537939,
      "city": "Mulino",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97044,
      "latitude": 45.643738,
      "longitude": -121.536658,
      "city": "Odell",
      "state": "OR",
      "county": "Hood River"
  },
  {
      "zipCode": 97045,
      "latitude": 45.317893,
      "longitude": -122.523602,
      "city": "Oregon City",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97048,
      "latitude": 46.038342,
      "longitude": -122.972651,
      "city": "Rainier",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97049,
      "latitude": 45.346537,
      "longitude": -121.915134,
      "city": "Rhododendron",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97050,
      "latitude": 45.647067,
      "longitude": -120.76413,
      "city": "Rufus",
      "state": "OR",
      "county": "Sherman"
  },
  {
      "zipCode": 97051,
      "latitude": 45.887794,
      "longitude": -122.919935,
      "city": "Saint Helens",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97053,
      "latitude": 45.824213,
      "longitude": -122.874601,
      "city": "Warren",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97054,
      "latitude": 45.942286,
      "longitude": -122.919248,
      "city": "Deer Island",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97055,
      "latitude": 45.388551,
      "longitude": -122.172544,
      "city": "Sandy",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97056,
      "latitude": 45.811578,
      "longitude": -122.974184,
      "city": "Scappoose",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97057,
      "latitude": 45.263301,
      "longitude": -121.088513,
      "city": "Shaniko",
      "state": "OR",
      "county": "Wasco"
  },
  {
      "zipCode": 97058,
      "latitude": 45.554978,
      "longitude": -121.18703,
      "city": "The Dalles",
      "state": "OR",
      "county": "Wasco"
  },
  {
      "zipCode": 97060,
      "latitude": 45.525007,
      "longitude": -122.390049,
      "city": "Troutdale",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97062,
      "latitude": 45.366608,
      "longitude": -122.783887,
      "city": "Tualatin",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97063,
      "latitude": 45.297105,
      "longitude": -121.233727,
      "city": "Tygh Valley",
      "state": "OR",
      "county": "Wasco"
  },
  {
      "zipCode": 97064,
      "latitude": 45.888246,
      "longitude": -123.209056,
      "city": "Vernonia",
      "state": "OR",
      "county": "Columbia"
  },
  {
      "zipCode": 97065,
      "latitude": 45.403298,
      "longitude": -120.710276,
      "city": "Wasco",
      "state": "OR",
      "county": "Sherman"
  },
  {
      "zipCode": 97067,
      "latitude": 45.327305,
      "longitude": -121.973561,
      "city": "Welches",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97068,
      "latitude": 45.343615,
      "longitude": -122.664926,
      "city": "West Linn",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97070,
      "latitude": 45.308493,
      "longitude": -122.785518,
      "city": "Wilsonville",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97071,
      "latitude": 45.148007,
      "longitude": -122.876205,
      "city": "Woodburn",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97075,
      "latitude": 45.548616,
      "longitude": -123.114725,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97076,
      "latitude": 45.548616,
      "longitude": -123.114725,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97077,
      "latitude": 45.548616,
      "longitude": -123.114725,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97078,
      "latitude": 45.451976,
      "longitude": -122.789257,
      "city": "Beaverton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97080,
      "latitude": 45.481252,
      "longitude": -122.38675,
      "city": "Gresham",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97101,
      "latitude": 45.121454,
      "longitude": -123.191368,
      "city": "Amity",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97102,
      "latitude": 45.810934,
      "longitude": -123.962989,
      "city": "Arch Cape",
      "state": "OR",
      "county": "Clatsop"
  },
  {
      "zipCode": 97103,
      "latitude": 46.142206,
      "longitude": -123.795996,
      "city": "Astoria",
      "state": "OR",
      "county": "Clatsop"
  },
  {
      "zipCode": 97106,
      "latitude": 45.594526,
      "longitude": -123.138952,
      "city": "Banks",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97107,
      "latitude": 45.609399,
      "longitude": -123.894297,
      "city": "Bay City",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97108,
      "latitude": 45.278621,
      "longitude": -123.746717,
      "city": "Beaver",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97109,
      "latitude": 45.728555,
      "longitude": -123.177864,
      "city": "Buxton",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97110,
      "latitude": 45.946617,
      "longitude": -123.931458,
      "city": "Cannon Beach",
      "state": "OR",
      "county": "Clatsop"
  },
  {
      "zipCode": 97111,
      "latitude": 45.282393,
      "longitude": -123.218624,
      "city": "Carlton",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97112,
      "latitude": 45.21636,
      "longitude": -123.876339,
      "city": "Cloverdale",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97113,
      "latitude": 45.600635,
      "longitude": -122.920629,
      "city": "Cornelius",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97114,
      "latitude": 45.187141,
      "longitude": -123.077797,
      "city": "Dayton",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97115,
      "latitude": 45.27237,
      "longitude": -123.02067,
      "city": "Dundee",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97116,
      "latitude": 45.598052,
      "longitude": -123.1818,
      "city": "Forest Grove",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97117,
      "latitude": 45.604204,
      "longitude": -123.26958,
      "city": "Gales Creek",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97118,
      "latitude": 45.562572,
      "longitude": -123.900072,
      "city": "Garibaldi",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97119,
      "latitude": 45.466171,
      "longitude": -123.149974,
      "city": "Gaston",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97121,
      "latitude": 46.182894,
      "longitude": -123.95135,
      "city": "Hammond",
      "state": "OR",
      "county": "Clatsop"
  },
  {
      "zipCode": 97122,
      "latitude": 45.176795,
      "longitude": -123.846,
      "city": "Hebo",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97123,
      "latitude": 45.458397,
      "longitude": -122.977963,
      "city": "Hillsboro",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97124,
      "latitude": 45.62337,
      "longitude": -122.953316,
      "city": "Hillsboro",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97125,
      "latitude": 45.659193,
      "longitude": -123.191739,
      "city": "Manning",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97127,
      "latitude": 45.232882,
      "longitude": -123.110948,
      "city": "Lafayette",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97128,
      "latitude": 45.187444,
      "longitude": -123.237145,
      "city": "Mcminnville",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97130,
      "latitude": 45.736552,
      "longitude": -123.923108,
      "city": "Manzanita",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97131,
      "latitude": 45.651933,
      "longitude": -123.853529,
      "city": "Nehalem",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97132,
      "latitude": 45.329475,
      "longitude": -122.981045,
      "city": "Newberg",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97133,
      "latitude": 45.668573,
      "longitude": -123.028124,
      "city": "North Plains",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97134,
      "latitude": 45.454855,
      "longitude": -123.965029,
      "city": "Oceanside",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97135,
      "latitude": 45.204976,
      "longitude": -123.95897,
      "city": "Pacific City",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97136,
      "latitude": 45.635205,
      "longitude": -123.913048,
      "city": "Rockaway Beach",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97137,
      "latitude": 45.208263,
      "longitude": -122.948636,
      "city": "Saint Paul",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97138,
      "latitude": 45.97677,
      "longitude": -123.857394,
      "city": "Seaside",
      "state": "OR",
      "county": "Clatsop"
  },
  {
      "zipCode": 97140,
      "latitude": 45.371478,
      "longitude": -122.874613,
      "city": "Sherwood",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97141,
      "latitude": 45.461952,
      "longitude": -123.740874,
      "city": "Tillamook",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97143,
      "latitude": 45.434049,
      "longitude": -123.945785,
      "city": "Netarts",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97144,
      "latitude": 45.740988,
      "longitude": -123.299311,
      "city": "Timber",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97145,
      "latitude": 45.866346,
      "longitude": -123.959518,
      "city": "Tolovana Park",
      "state": "OR",
      "county": "Clatsop"
  },
  {
      "zipCode": 97146,
      "latitude": 46.125267,
      "longitude": -123.905174,
      "city": "Warrenton",
      "state": "OR",
      "county": "Clatsop"
  },
  {
      "zipCode": 97147,
      "latitude": 45.397283,
      "longitude": -123.916076,
      "city": "Wheeler",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97148,
      "latitude": 45.351892,
      "longitude": -123.23484,
      "city": "Yamhill",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97149,
      "latitude": 45.097011,
      "longitude": -123.943418,
      "city": "Neskowin",
      "state": "OR",
      "county": "Tillamook"
  },
  {
      "zipCode": 97201,
      "latitude": 45.498931,
      "longitude": -122.693157,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97202,
      "latitude": 45.48169,
      "longitude": -122.637331,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97203,
      "latitude": 45.604838,
      "longitude": -122.743737,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97204,
      "latitude": 45.51809,
      "longitude": -122.675106,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97205,
      "latitude": 45.51949,
      "longitude": -122.690157,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97206,
      "latitude": 45.483341,
      "longitude": -122.596154,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97207,
      "latitude": 45.480324,
      "longitude": -122.711117,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97208,
      "latitude": 45.532165,
      "longitude": -122.564824,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97209,
      "latitude": 45.53199,
      "longitude": -122.683826,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97210,
      "latitude": 45.547339,
      "longitude": -122.732358,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97211,
      "latitude": 45.577289,
      "longitude": -122.615906,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97212,
      "latitude": 45.54374,
      "longitude": -122.643106,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97213,
      "latitude": 45.53909,
      "longitude": -122.599751,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97214,
      "latitude": 45.51444,
      "longitude": -122.642305,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97215,
      "latitude": 45.51894,
      "longitude": -122.599104,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97216,
      "latitude": 45.515341,
      "longitude": -122.558003,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97217,
      "latitude": 45.594589,
      "longitude": -122.704308,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97218,
      "latitude": 45.575689,
      "longitude": -122.605205,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97219,
      "latitude": 45.45509,
      "longitude": -122.696724,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97220,
      "latitude": 45.55634,
      "longitude": -122.556104,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97221,
      "latitude": 45.50655,
      "longitude": -122.733888,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97222,
      "latitude": 45.437341,
      "longitude": -122.614703,
      "city": "Portland",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97223,
      "latitude": 45.44739,
      "longitude": -122.795294,
      "city": "Portland",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97224,
      "latitude": 45.409448,
      "longitude": -122.8014,
      "city": "Portland",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97225,
      "latitude": 45.498473,
      "longitude": -122.778659,
      "city": "Portland",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97227,
      "latitude": 45.545039,
      "longitude": -122.680007,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97228,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97229,
      "latitude": 45.548317,
      "longitude": -122.827561,
      "city": "Portland",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97230,
      "latitude": 45.547173,
      "longitude": -122.500053,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97231,
      "latitude": 45.652987,
      "longitude": -122.839778,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97232,
      "latitude": 45.52369,
      "longitude": -122.642655,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97233,
      "latitude": 45.515121,
      "longitude": -122.496052,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97236,
      "latitude": 45.484594,
      "longitude": -122.504175,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97238,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97240,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97242,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97251,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97253,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97254,
      "latitude": 45.596678,
      "longitude": -122.59418,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97255,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97256,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97258,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97259,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97266,
      "latitude": 45.482949,
      "longitude": -122.557502,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97267,
      "latitude": 45.402142,
      "longitude": -122.614353,
      "city": "Portland",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97268,
      "latitude": 45.173284,
      "longitude": -122.259861,
      "city": "Portland",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97269,
      "latitude": 45.173284,
      "longitude": -122.259861,
      "city": "Portland",
      "state": "OR",
      "county": "Clackamas"
  },
  {
      "zipCode": 97271,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97272,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97280,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97281,
      "latitude": 45.548616,
      "longitude": -123.114725,
      "city": "Portland",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97282,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97283,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97286,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97290,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97291,
      "latitude": 45.548616,
      "longitude": -123.114725,
      "city": "Portland",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97292,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97293,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97294,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97296,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97298,
      "latitude": 45.548616,
      "longitude": -123.114725,
      "city": "Portland",
      "state": "OR",
      "county": "Washington"
  },
  {
      "zipCode": 97299,
      "latitude": 45.580557,
      "longitude": -122.374776,
      "city": "Portland",
      "state": "OR",
      "county": "Multnomah"
  },
  {
      "zipCode": 97301,
      "latitude": 44.90472,
      "longitude": -122.921721,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97302,
      "latitude": 44.890486,
      "longitude": -123.082192,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97303,
      "latitude": 44.992731,
      "longitude": -123.01672,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97304,
      "latitude": 44.99351,
      "longitude": -123.107442,
      "city": "Salem",
      "state": "OR",
      "county": "Polk"
  },
  {
      "zipCode": 97305,
      "latitude": 44.996133,
      "longitude": -122.91239,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97306,
      "latitude": 44.856786,
      "longitude": -123.088297,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97307,
      "latitude": 44.984465,
      "longitude": -122.457024,
      "city": "Keizer",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97308,
      "latitude": 44.984465,
      "longitude": -122.457024,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97309,
      "latitude": 44.984465,
      "longitude": -122.457024,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97310,
      "latitude": 44.927141,
      "longitude": -122.986105,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97311,
      "latitude": 44.943841,
      "longitude": -123.028607,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97312,
      "latitude": 44.936357,
      "longitude": -123.038116,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97313,
      "latitude": 44.984941,
      "longitude": -122.998756,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97314,
      "latitude": 44.965541,
      "longitude": -123.006606,
      "city": "Salem",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97321,
      "latitude": 44.594489,
      "longitude": -122.581676,
      "city": "Albany",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97324,
      "latitude": 44.377113,
      "longitude": -123.588271,
      "city": "Alsea",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97325,
      "latitude": 44.799503,
      "longitude": -122.900739,
      "city": "Aumsville",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97326,
      "latitude": 44.638187,
      "longitude": -123.538238,
      "city": "Blodgett",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97327,
      "latitude": 44.378703,
      "longitude": -122.942438,
      "city": "Brownsville",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97329,
      "latitude": 44.399487,
      "longitude": -122.363606,
      "city": "Cascadia",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97330,
      "latitude": 44.639931,
      "longitude": -123.275969,
      "city": "Corvallis",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97331,
      "latitude": 44.565613,
      "longitude": -123.278957,
      "city": "Corvallis",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97333,
      "latitude": 44.467218,
      "longitude": -123.337762,
      "city": "Corvallis",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97335,
      "latitude": 44.634439,
      "longitude": -122.893292,
      "city": "Crabtree",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97336,
      "latitude": 44.359301,
      "longitude": -122.850752,
      "city": "Crawfordsville",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97338,
      "latitude": 44.928092,
      "longitude": -123.337291,
      "city": "Dallas",
      "state": "OR",
      "county": "Polk"
  },
  {
      "zipCode": 97339,
      "latitude": 44.49893,
      "longitude": -123.445033,
      "city": "Corvallis",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97341,
      "latitude": 44.832205,
      "longitude": -124.050272,
      "city": "Depoe Bay",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97342,
      "latitude": 44.735834,
      "longitude": -122.066865,
      "city": "Detroit",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97343,
      "latitude": 44.604237,
      "longitude": -123.736099,
      "city": "Eddyville",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97344,
      "latitude": 44.870977,
      "longitude": -123.473276,
      "city": "Falls City",
      "state": "OR",
      "county": "Polk"
  },
  {
      "zipCode": 97345,
      "latitude": 44.430866,
      "longitude": -122.400143,
      "city": "Foster",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97346,
      "latitude": 44.762578,
      "longitude": -122.372622,
      "city": "Gates",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97347,
      "latitude": 45.053757,
      "longitude": -123.648356,
      "city": "Grand Ronde",
      "state": "OR",
      "county": "Polk"
  },
  {
      "zipCode": 97348,
      "latitude": 44.385837,
      "longitude": -123.115961,
      "city": "Halsey",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97350,
      "latitude": 44.700127,
      "longitude": -122.074043,
      "city": "Idanha",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97351,
      "latitude": 44.836951,
      "longitude": -123.165523,
      "city": "Independence",
      "state": "OR",
      "county": "Polk"
  },
  {
      "zipCode": 97352,
      "latitude": 44.768025,
      "longitude": -123.003516,
      "city": "Jefferson",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97355,
      "latitude": 44.526381,
      "longitude": -122.827457,
      "city": "Lebanon",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97357,
      "latitude": 44.70824,
      "longitude": -123.805124,
      "city": "Logsden",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97358,
      "latitude": 44.749921,
      "longitude": -122.594993,
      "city": "Lyons",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97359,
      "latitude": 44.984465,
      "longitude": -122.457024,
      "city": "Marion",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97360,
      "latitude": 44.621679,
      "longitude": -122.5785,
      "city": "Mill City",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97361,
      "latitude": 44.808768,
      "longitude": -123.326075,
      "city": "Monmouth",
      "state": "OR",
      "county": "Polk"
  },
  {
      "zipCode": 97362,
      "latitude": 44.934698,
      "longitude": -122.655118,
      "city": "Mount Angel",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97364,
      "latitude": 44.99821,
      "longitude": -123.974717,
      "city": "Neotsu",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97365,
      "latitude": 44.552413,
      "longitude": -124.024426,
      "city": "Newport",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97366,
      "latitude": 44.574645,
      "longitude": -124.043287,
      "city": "South Beach",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97367,
      "latitude": 44.908891,
      "longitude": -123.98885,
      "city": "Lincoln City",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97368,
      "latitude": 45.002157,
      "longitude": -123.905622,
      "city": "Otis",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97369,
      "latitude": 44.758819,
      "longitude": -124.06328,
      "city": "Otter Rock",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97370,
      "latitude": 44.555144,
      "longitude": -123.439249,
      "city": "Philomath",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97371,
      "latitude": 44.991024,
      "longitude": -123.209356,
      "city": "Rickreall",
      "state": "OR",
      "county": "Polk"
  },
  {
      "zipCode": 97372,
      "latitude": 44.659979,
      "longitude": -123.892016,
      "city": "Rose Lodge",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97373,
      "latitude": 44.984465,
      "longitude": -122.457024,
      "city": "Saint Benedict",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97374,
      "latitude": 44.689609,
      "longitude": -122.748639,
      "city": "Scio",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97375,
      "latitude": 44.989702,
      "longitude": -122.618718,
      "city": "Scotts Mills",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97376,
      "latitude": 44.487404,
      "longitude": -123.956719,
      "city": "Seal Rock",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97377,
      "latitude": 44.435988,
      "longitude": -123.106034,
      "city": "Shedd",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97378,
      "latitude": 45.145945,
      "longitude": -123.385904,
      "city": "Sheridan",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97380,
      "latitude": 44.746459,
      "longitude": -123.84463,
      "city": "Siletz",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97381,
      "latitude": 44.954501,
      "longitude": -122.748442,
      "city": "Silverton",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97383,
      "latitude": 44.807707,
      "longitude": -122.726619,
      "city": "Stayton",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97384,
      "latitude": 44.790267,
      "longitude": -122.618719,
      "city": "Mehama",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97385,
      "latitude": 44.886618,
      "longitude": -122.740264,
      "city": "Sublimity",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97386,
      "latitude": 44.380262,
      "longitude": -122.698954,
      "city": "Sweet Home",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97388,
      "latitude": 44.885,
      "longitude": -123.994219,
      "city": "Gleneden Beach",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97389,
      "latitude": 44.533934,
      "longitude": -123.081664,
      "city": "Tangent",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97390,
      "latitude": 44.36178,
      "longitude": -123.891668,
      "city": "Tidewater",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97391,
      "latitude": 44.65719,
      "longitude": -123.932758,
      "city": "Toledo",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97392,
      "latitude": 44.7952,
      "longitude": -122.94221,
      "city": "Turner",
      "state": "OR",
      "county": "Marion"
  },
  {
      "zipCode": 97394,
      "latitude": 44.395907,
      "longitude": -123.980384,
      "city": "Waldport",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97396,
      "latitude": 45.145011,
      "longitude": -123.523136,
      "city": "Willamina",
      "state": "OR",
      "county": "Yamhill"
  },
  {
      "zipCode": 97401,
      "latitude": 44.117868,
      "longitude": -123.074193,
      "city": "Eugene",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97402,
      "latitude": 44.033642,
      "longitude": -123.073186,
      "city": "Eugene",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97403,
      "latitude": 44.032396,
      "longitude": -123.095799,
      "city": "Eugene",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97404,
      "latitude": 44.100192,
      "longitude": -123.168807,
      "city": "Eugene",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97405,
      "latitude": 43.935009,
      "longitude": -123.166429,
      "city": "Eugene",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97406,
      "latitude": 42.656925,
      "longitude": -124.133206,
      "city": "Agness",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97407,
      "latitude": 43.246918,
      "longitude": -124.231068,
      "city": "Allegany",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97408,
      "latitude": 44.141548,
      "longitude": -123.125468,
      "city": "Eugene",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97409,
      "latitude": 44.127631,
      "longitude": -123.266648,
      "city": "Alvadore",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97410,
      "latitude": 42.834139,
      "longitude": -123.13216,
      "city": "Azalea",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97411,
      "latitude": 43.088824,
      "longitude": -124.389936,
      "city": "Bandon",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97412,
      "latitude": 44.174264,
      "longitude": -123.571057,
      "city": "Blachly",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97413,
      "latitude": 44.175516,
      "longitude": -122.12169,
      "city": "Blue River",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97414,
      "latitude": 43.138755,
      "longitude": -124.127089,
      "city": "Broadbent",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97415,
      "latitude": 42.137789,
      "longitude": -124.249196,
      "city": "Brookings",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97416,
      "latitude": 43.040527,
      "longitude": -123.653799,
      "city": "Camas Valley",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97417,
      "latitude": 42.95096,
      "longitude": -123.258975,
      "city": "Canyonville",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97419,
      "latitude": 44.210177,
      "longitude": -123.368242,
      "city": "Cheshire",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97420,
      "latitude": 43.215143,
      "longitude": -124.198358,
      "city": "Coos Bay",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97423,
      "latitude": 43.232318,
      "longitude": -124.22419,
      "city": "Coquille",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97424,
      "latitude": 43.72893,
      "longitude": -123.049498,
      "city": "Cottage Grove",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97425,
      "latitude": 43.51799,
      "longitude": -121.949514,
      "city": "Crescent Lake",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97426,
      "latitude": 43.895976,
      "longitude": -123.041968,
      "city": "Creswell",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97427,
      "latitude": 43.657939,
      "longitude": -122.787122,
      "city": "Culp Creek",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97428,
      "latitude": 43.747559,
      "longitude": -123.196161,
      "city": "Curtin",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97429,
      "latitude": 42.972697,
      "longitude": -123.088917,
      "city": "Days Creek",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97430,
      "latitude": 44.146196,
      "longitude": -123.681581,
      "city": "Deadwood",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97431,
      "latitude": 43.968007,
      "longitude": -123.424918,
      "city": "Dexter",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97432,
      "latitude": 43.113502,
      "longitude": -123.418068,
      "city": "Dillard",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97434,
      "latitude": 43.736115,
      "longitude": -122.894601,
      "city": "Dorena",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97435,
      "latitude": 43.716624,
      "longitude": -123.334749,
      "city": "Drain",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97436,
      "latitude": 43.573594,
      "longitude": -123.556893,
      "city": "Elkton",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97437,
      "latitude": 44.097363,
      "longitude": -123.388216,
      "city": "Elmira",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97438,
      "latitude": 43.9823,
      "longitude": -122.76915,
      "city": "Fall Creek",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97439,
      "latitude": 44.064723,
      "longitude": -123.996747,
      "city": "Florence",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97440,
      "latitude": 43.863845,
      "longitude": -122.99879,
      "city": "Eugene",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97441,
      "latitude": 43.75114,
      "longitude": -124.123806,
      "city": "Gardiner",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97442,
      "latitude": 42.761476,
      "longitude": -123.39135,
      "city": "Glendale",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97443,
      "latitude": 43.26595,
      "longitude": -123.018231,
      "city": "Glide",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97444,
      "latitude": 42.410099,
      "longitude": -124.334383,
      "city": "Gold Beach",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97446,
      "latitude": 44.273684,
      "longitude": -123.108438,
      "city": "Harrisburg",
      "state": "OR",
      "county": "Linn"
  },
  {
      "zipCode": 97447,
      "latitude": 43.065884,
      "longitude": -122.869705,
      "city": "Idleyld Park",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97448,
      "latitude": 44.192567,
      "longitude": -123.273576,
      "city": "Junction City",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97449,
      "latitude": 43.583767,
      "longitude": -124.122749,
      "city": "Lakeside",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97450,
      "latitude": 42.893126,
      "longitude": -124.406262,
      "city": "Langlois",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97451,
      "latitude": 43.817721,
      "longitude": -123.273366,
      "city": "Lorane",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97452,
      "latitude": 43.885422,
      "longitude": -122.694091,
      "city": "Lowell",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97453,
      "latitude": 44.010088,
      "longitude": -123.901261,
      "city": "Mapleton",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97454,
      "latitude": 44.212732,
      "longitude": -122.812544,
      "city": "Marcola",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97455,
      "latitude": 43.967356,
      "longitude": -122.915417,
      "city": "Pleasant Hill",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97456,
      "latitude": 44.346783,
      "longitude": -123.325952,
      "city": "Monroe",
      "state": "OR",
      "county": "Benton"
  },
  {
      "zipCode": 97457,
      "latitude": 43.300361,
      "longitude": -123.225556,
      "city": "Myrtle Creek",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97458,
      "latitude": 43.020023,
      "longitude": -124.136075,
      "city": "Myrtle Point",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97459,
      "latitude": 43.241489,
      "longitude": -124.163469,
      "city": "North Bend",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97460,
      "latitude": 43.138755,
      "longitude": -124.127089,
      "city": "Norway",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97461,
      "latitude": 44.099169,
      "longitude": -123.468389,
      "city": "Noti",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97462,
      "latitude": 43.503232,
      "longitude": -123.414079,
      "city": "Oakland",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97463,
      "latitude": 43.737605,
      "longitude": -122.437723,
      "city": "Oakridge",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97464,
      "latitude": 42.739889,
      "longitude": -124.51056,
      "city": "Ophir",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97465,
      "latitude": 42.703155,
      "longitude": -124.45094,
      "city": "Port Orford",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97466,
      "latitude": 42.879975,
      "longitude": -124.070945,
      "city": "Powers",
      "state": "OR",
      "county": "Coos"
  },
  {
      "zipCode": 97467,
      "latitude": 43.449523,
      "longitude": -123.745579,
      "city": "Reedsport",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97469,
      "latitude": 43.267918,
      "longitude": -123.42545,
      "city": "Riddle",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97470,
      "latitude": 43.053573,
      "longitude": -123.360805,
      "city": "Roseburg",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97472,
      "latitude": 43.863845,
      "longitude": -122.99879,
      "city": "Saginaw",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97473,
      "latitude": 43.671853,
      "longitude": -123.825751,
      "city": "Scottsburg",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97476,
      "latitude": 42.800135,
      "longitude": -124.433374,
      "city": "Sixes",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97477,
      "latitude": 43.937276,
      "longitude": -123.444371,
      "city": "Springfield",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97478,
      "latitude": 44.095761,
      "longitude": -122.872806,
      "city": "Springfield",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97479,
      "latitude": 43.345559,
      "longitude": -123.208273,
      "city": "Sutherlin",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97480,
      "latitude": 44.101084,
      "longitude": -123.732051,
      "city": "Swisshome",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97481,
      "latitude": 43.11405,
      "longitude": -123.529417,
      "city": "Tenmile",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97482,
      "latitude": 43.863845,
      "longitude": -122.99879,
      "city": "Thurston",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97484,
      "latitude": 42.91767,
      "longitude": -122.907864,
      "city": "Tiller",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97486,
      "latitude": 43.393775,
      "longitude": -123.516847,
      "city": "Umpqua",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97487,
      "latitude": 44.003051,
      "longitude": -123.367371,
      "city": "Veneta",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97488,
      "latitude": 44.162401,
      "longitude": -122.870723,
      "city": "Vida",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97489,
      "latitude": 44.128776,
      "longitude": -122.637619,
      "city": "Walterville",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97490,
      "latitude": 44.000245,
      "longitude": -123.615073,
      "city": "Walton",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97491,
      "latitude": 42.609252,
      "longitude": -124.388576,
      "city": "Wedderburn",
      "state": "OR",
      "county": "Curry"
  },
  {
      "zipCode": 97492,
      "latitude": 43.757499,
      "longitude": -122.481043,
      "city": "Westfir",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97493,
      "latitude": 43.91587,
      "longitude": -124.031752,
      "city": "Westlake",
      "state": "OR",
      "county": "Lane"
  },
  {
      "zipCode": 97494,
      "latitude": 43.32843,
      "longitude": -123.333177,
      "city": "Wilbur",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97495,
      "latitude": 43.333141,
      "longitude": -123.325617,
      "city": "Winchester",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97496,
      "latitude": 43.306579,
      "longitude": -123.45342,
      "city": "Winston",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97497,
      "latitude": 42.652236,
      "longitude": -123.425008,
      "city": "Wolf Creek",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97498,
      "latitude": 44.333892,
      "longitude": -124.024246,
      "city": "Yachats",
      "state": "OR",
      "county": "Lincoln"
  },
  {
      "zipCode": 97499,
      "latitude": 43.308689,
      "longitude": -123.165395,
      "city": "Yoncalla",
      "state": "OR",
      "county": "Douglas"
  },
  {
      "zipCode": 97501,
      "latitude": 42.482623,
      "longitude": -122.72255,
      "city": "Medford",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97502,
      "latitude": 42.416384,
      "longitude": -122.917383,
      "city": "Central Point",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97503,
      "latitude": 42.537028,
      "longitude": -122.883923,
      "city": "White City",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97504,
      "latitude": 42.322392,
      "longitude": -122.793659,
      "city": "Medford",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97520,
      "latitude": 42.148098,
      "longitude": -122.537356,
      "city": "Ashland",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97522,
      "latitude": 42.530813,
      "longitude": -122.554633,
      "city": "Butte Falls",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97523,
      "latitude": 42.273683,
      "longitude": -123.554046,
      "city": "Cave Junction",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97524,
      "latitude": 42.478023,
      "longitude": -122.635695,
      "city": "Eagle Point",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97525,
      "latitude": 42.440993,
      "longitude": -123.029161,
      "city": "Gold Hill",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97526,
      "latitude": 42.402144,
      "longitude": -123.416119,
      "city": "Grants Pass",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97527,
      "latitude": 42.345125,
      "longitude": -123.440456,
      "city": "Grants Pass",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97528,
      "latitude": 42.396421,
      "longitude": -123.535027,
      "city": "Grants Pass",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97530,
      "latitude": 42.181333,
      "longitude": -123.0241,
      "city": "Jacksonville",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97531,
      "latitude": 42.212141,
      "longitude": -123.624688,
      "city": "Kerby",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97532,
      "latitude": 42.400936,
      "longitude": -123.525826,
      "city": "Merlin",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97533,
      "latitude": 42.308007,
      "longitude": -123.327323,
      "city": "Murphy",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97534,
      "latitude": 42.087941,
      "longitude": -123.688998,
      "city": "O Brien",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97535,
      "latitude": 42.262556,
      "longitude": -122.81464,
      "city": "Phoenix",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97536,
      "latitude": 42.787066,
      "longitude": -122.506046,
      "city": "Prospect",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97537,
      "latitude": 42.533003,
      "longitude": -123.097269,
      "city": "Rogue River",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97538,
      "latitude": 42.265435,
      "longitude": -123.551451,
      "city": "Selma",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97539,
      "latitude": 42.621218,
      "longitude": -122.756141,
      "city": "Shady Cove",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97540,
      "latitude": 42.221879,
      "longitude": -122.770093,
      "city": "Talent",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97541,
      "latitude": 42.697957,
      "longitude": -122.769269,
      "city": "Trail",
      "state": "OR",
      "county": "Jackson"
  },
  {
      "zipCode": 97543,
      "latitude": 42.292694,
      "longitude": -123.548618,
      "city": "Wilderville",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97544,
      "latitude": 42.357375,
      "longitude": -123.321239,
      "city": "Williams",
      "state": "OR",
      "county": "Josephine"
  },
  {
      "zipCode": 97601,
      "latitude": 42.293293,
      "longitude": -121.816873,
      "city": "Klamath Falls",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97602,
      "latitude": 42.804981,
      "longitude": -121.584833,
      "city": "Klamath Falls",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97603,
      "latitude": 42.61276,
      "longitude": -121.685853,
      "city": "Klamath Falls",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97604,
      "latitude": 42.733365,
      "longitude": -121.987076,
      "city": "Crater Lake",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97620,
      "latitude": 42.805272,
      "longitude": -120.355294,
      "city": "Adel",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97621,
      "latitude": 42.464842,
      "longitude": -121.259056,
      "city": "Beatty",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97622,
      "latitude": 42.399541,
      "longitude": -121.080557,
      "city": "Bly",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97623,
      "latitude": 42.193652,
      "longitude": -121.33006,
      "city": "Bonanza",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97624,
      "latitude": 42.72222,
      "longitude": -121.878916,
      "city": "Chiloquin",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97625,
      "latitude": 42.291015,
      "longitude": -121.507834,
      "city": "Dairy",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97626,
      "latitude": 42.641537,
      "longitude": -122.037995,
      "city": "Fort Klamath",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97627,
      "latitude": 42.119398,
      "longitude": -122.008124,
      "city": "Keno",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97630,
      "latitude": 42.180763,
      "longitude": -120.369607,
      "city": "Lakeview",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97632,
      "latitude": 42.095447,
      "longitude": -121.387408,
      "city": "Malin",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97633,
      "latitude": 42.055503,
      "longitude": -121.522384,
      "city": "Merrill",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97634,
      "latitude": 42.128418,
      "longitude": -121.870983,
      "city": "Midland",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97635,
      "latitude": 42.805272,
      "longitude": -120.355294,
      "city": "New Pine Creek",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97636,
      "latitude": 42.775198,
      "longitude": -120.330349,
      "city": "Paisley",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97637,
      "latitude": 42.805272,
      "longitude": -120.355294,
      "city": "Plush",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97638,
      "latitude": 42.805272,
      "longitude": -120.355294,
      "city": "Silver Lake",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97639,
      "latitude": 42.486975,
      "longitude": -121.531087,
      "city": "Sprague River",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97640,
      "latitude": 42.944694,
      "longitude": -120.794615,
      "city": "Summer Lake",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97641,
      "latitude": 43.157855,
      "longitude": -120.724397,
      "city": "Christmas Valley",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97701,
      "latitude": 44.082037,
      "longitude": -121.227125,
      "city": "Bend",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97702,
      "latitude": 43.998448,
      "longitude": -121.260298,
      "city": "Bend",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97707,
      "latitude": 43.843071,
      "longitude": -121.576423,
      "city": "Bend",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97708,
      "latitude": 44.001834,
      "longitude": -120.94952,
      "city": "Bend",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97709,
      "latitude": 44.001834,
      "longitude": -120.94952,
      "city": "Bend",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97710,
      "latitude": 43.019785,
      "longitude": -119.069624,
      "city": "Fields",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97711,
      "latitude": 44.538351,
      "longitude": -121.321903,
      "city": "Ashwood",
      "state": "OR",
      "county": "Jefferson"
  },
  {
      "zipCode": 97712,
      "latitude": 43.665917,
      "longitude": -120.225618,
      "city": "Brothers",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97720,
      "latitude": 43.554287,
      "longitude": -118.874273,
      "city": "Burns",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97721,
      "latitude": 43.019785,
      "longitude": -119.069624,
      "city": "Princeton",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97722,
      "latitude": 43.019785,
      "longitude": -119.069624,
      "city": "Diamond",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97730,
      "latitude": 44.443474,
      "longitude": -121.659909,
      "city": "Camp Sherman",
      "state": "OR",
      "county": "Jefferson"
  },
  {
      "zipCode": 97731,
      "latitude": 42.804981,
      "longitude": -121.584833,
      "city": "Chemult",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97732,
      "latitude": 43.019785,
      "longitude": -119.069624,
      "city": "Crane",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97733,
      "latitude": 43.444217,
      "longitude": -121.710925,
      "city": "Crescent",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97734,
      "latitude": 44.499106,
      "longitude": -121.267976,
      "city": "Culver",
      "state": "OR",
      "county": "Jefferson"
  },
  {
      "zipCode": 97735,
      "latitude": 42.805272,
      "longitude": -120.355294,
      "city": "Fort Rock",
      "state": "OR",
      "county": "Lake"
  },
  {
      "zipCode": 97736,
      "latitude": 43.56203,
      "longitude": -119.078609,
      "city": "Frenchglen",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97737,
      "latitude": 43.372529,
      "longitude": -121.658336,
      "city": "Gilchrist",
      "state": "OR",
      "county": "Klamath"
  },
  {
      "zipCode": 97738,
      "latitude": 43.555768,
      "longitude": -119.081558,
      "city": "Hines",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97739,
      "latitude": 43.692193,
      "longitude": -121.565187,
      "city": "La Pine",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97740,
      "latitude": 43.019785,
      "longitude": -119.069624,
      "city": "Lawen",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97741,
      "latitude": 44.62616,
      "longitude": -121.128106,
      "city": "Madras",
      "state": "OR",
      "county": "Jefferson"
  },
  {
      "zipCode": 97750,
      "latitude": 44.644668,
      "longitude": -120.014665,
      "city": "Mitchell",
      "state": "OR",
      "county": "Wheeler"
  },
  {
      "zipCode": 97751,
      "latitude": 44.127641,
      "longitude": -119.864655,
      "city": "Paulina",
      "state": "OR",
      "county": "Crook"
  },
  {
      "zipCode": 97752,
      "latitude": 44.085051,
      "longitude": -120.276069,
      "city": "Post",
      "state": "OR",
      "county": "Crook"
  },
  {
      "zipCode": 97753,
      "latitude": 44.232525,
      "longitude": -121.008489,
      "city": "Powell Butte",
      "state": "OR",
      "county": "Crook"
  },
  {
      "zipCode": 97754,
      "latitude": 44.16805,
      "longitude": -120.660334,
      "city": "Prineville",
      "state": "OR",
      "county": "Crook"
  },
  {
      "zipCode": 97756,
      "latitude": 44.268862,
      "longitude": -121.266414,
      "city": "Redmond",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97758,
      "latitude": 43.019785,
      "longitude": -119.069624,
      "city": "Riley",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97759,
      "latitude": 44.31648,
      "longitude": -121.509124,
      "city": "Sisters",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97760,
      "latitude": 44.349792,
      "longitude": -121.245224,
      "city": "Terrebonne",
      "state": "OR",
      "county": "Deschutes"
  },
  {
      "zipCode": 97761,
      "latitude": 44.771108,
      "longitude": -121.334786,
      "city": "Warm Springs",
      "state": "OR",
      "county": "Jefferson"
  },
  {
      "zipCode": 97801,
      "latitude": 45.711814,
      "longitude": -118.637974,
      "city": "Pendleton",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97810,
      "latitude": 45.724767,
      "longitude": -118.490788,
      "city": "Adams",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97812,
      "latitude": 45.567282,
      "longitude": -120.275127,
      "city": "Arlington",
      "state": "OR",
      "county": "Gilliam"
  },
  {
      "zipCode": 97813,
      "latitude": 45.82394,
      "longitude": -118.524881,
      "city": "Athena",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97814,
      "latitude": 44.754237,
      "longitude": -117.742659,
      "city": "Baker City",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97817,
      "latitude": 44.477794,
      "longitude": -118.947769,
      "city": "Bates",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97818,
      "latitude": 45.419841,
      "longitude": -119.6575,
      "city": "Boardman",
      "state": "OR",
      "county": "Morrow"
  },
  {
      "zipCode": 97819,
      "latitude": 44.493135,
      "longitude": -117.765498,
      "city": "Bridgeport",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97820,
      "latitude": 44.391149,
      "longitude": -118.946498,
      "city": "Canyon City",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97821,
      "latitude": 45.683276,
      "longitude": -118.45808,
      "city": "Cayuse",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97823,
      "latitude": 45.255099,
      "longitude": -120.140937,
      "city": "Condon",
      "state": "OR",
      "county": "Gilliam"
  },
  {
      "zipCode": 97824,
      "latitude": 45.341881,
      "longitude": -117.872662,
      "city": "Cove",
      "state": "OR",
      "county": "Union"
  },
  {
      "zipCode": 97825,
      "latitude": 44.465268,
      "longitude": -119.533402,
      "city": "Dayville",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97826,
      "latitude": 45.692754,
      "longitude": -119.198798,
      "city": "Echo",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97827,
      "latitude": 45.612664,
      "longitude": -117.907539,
      "city": "Elgin",
      "state": "OR",
      "county": "Union"
  },
  {
      "zipCode": 97828,
      "latitude": 45.648199,
      "longitude": -117.169858,
      "city": "Enterprise",
      "state": "OR",
      "county": "Wallowa"
  },
  {
      "zipCode": 97830,
      "latitude": 44.928579,
      "longitude": -120.130869,
      "city": "Fossil",
      "state": "OR",
      "county": "Wheeler"
  },
  {
      "zipCode": 97831,
      "latitude": 44.477794,
      "longitude": -118.947769,
      "city": "Fox",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97833,
      "latitude": 44.941557,
      "longitude": -117.987928,
      "city": "Haines",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97834,
      "latitude": 44.952248,
      "longitude": -117.123753,
      "city": "Halfway",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97835,
      "latitude": 45.909854,
      "longitude": -118.755877,
      "city": "Helix",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97836,
      "latitude": 45.301332,
      "longitude": -119.577731,
      "city": "Heppner",
      "state": "OR",
      "county": "Morrow"
  },
  {
      "zipCode": 97837,
      "latitude": 44.530415,
      "longitude": -118.029277,
      "city": "Hereford",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97838,
      "latitude": 45.699986,
      "longitude": -119.100692,
      "city": "Hermiston",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97839,
      "latitude": 45.493821,
      "longitude": -119.638037,
      "city": "Lexington",
      "state": "OR",
      "county": "Morrow"
  },
  {
      "zipCode": 97840,
      "latitude": 44.963341,
      "longitude": -116.84359,
      "city": "Oxbow",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97841,
      "latitude": 45.474915,
      "longitude": -117.957463,
      "city": "Imbler",
      "state": "OR",
      "county": "Union"
  },
  {
      "zipCode": 97842,
      "latitude": 45.503578,
      "longitude": -116.807469,
      "city": "Imnaha",
      "state": "OR",
      "county": "Wallowa"
  },
  {
      "zipCode": 97843,
      "latitude": 45.456534,
      "longitude": -119.842256,
      "city": "Ione",
      "state": "OR",
      "county": "Morrow"
  },
  {
      "zipCode": 97844,
      "latitude": 45.707049,
      "longitude": -119.631097,
      "city": "Irrigon",
      "state": "OR",
      "county": "Morrow"
  },
  {
      "zipCode": 97845,
      "latitude": 44.550033,
      "longitude": -118.914774,
      "city": "John Day",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97846,
      "latitude": 45.406071,
      "longitude": -117.073337,
      "city": "Joseph",
      "state": "OR",
      "county": "Wallowa"
  },
  {
      "zipCode": 97848,
      "latitude": 44.477794,
      "longitude": -118.947769,
      "city": "Kimberly",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97850,
      "latitude": 45.29535,
      "longitude": -118.164822,
      "city": "La Grande",
      "state": "OR",
      "county": "Union"
  },
  {
      "zipCode": 97856,
      "latitude": 44.713688,
      "longitude": -119.090832,
      "city": "Long Creek",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97857,
      "latitude": 45.465083,
      "longitude": -117.457163,
      "city": "Lostine",
      "state": "OR",
      "county": "Wallowa"
  },
  {
      "zipCode": 97859,
      "latitude": 45.521453,
      "longitude": -118.433494,
      "city": "Meacham",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97861,
      "latitude": 45.438822,
      "longitude": -120.206698,
      "city": "Mikkalo",
      "state": "OR",
      "county": "Gilliam"
  },
  {
      "zipCode": 97862,
      "latitude": 45.740766,
      "longitude": -118.50432,
      "city": "Milton Freewater",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97864,
      "latitude": 44.818887,
      "longitude": -119.419221,
      "city": "Monument",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97865,
      "latitude": 44.446986,
      "longitude": -119.09743,
      "city": "Mount Vernon",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97867,
      "latitude": 45.043975,
      "longitude": -117.981498,
      "city": "North Powder",
      "state": "OR",
      "county": "Union"
  },
  {
      "zipCode": 97868,
      "latitude": 45.42115,
      "longitude": -118.8603,
      "city": "Pilot Rock",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97869,
      "latitude": 44.459922,
      "longitude": -118.704035,
      "city": "Prairie City",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97870,
      "latitude": 44.794629,
      "longitude": -117.229802,
      "city": "Richland",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97872,
      "latitude": 44.477794,
      "longitude": -118.947769,
      "city": "Ritter",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97873,
      "latitude": 44.119638,
      "longitude": -119.122317,
      "city": "Seneca",
      "state": "OR",
      "county": "Grant"
  },
  {
      "zipCode": 97874,
      "latitude": 44.780103,
      "longitude": -119.914072,
      "city": "Spray",
      "state": "OR",
      "county": "Wheeler"
  },
  {
      "zipCode": 97875,
      "latitude": 45.806358,
      "longitude": -119.176169,
      "city": "Stanfield",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97876,
      "latitude": 45.295599,
      "longitude": -118.000258,
      "city": "Summerville",
      "state": "OR",
      "county": "Union"
  },
  {
      "zipCode": 97877,
      "latitude": 44.72563,
      "longitude": -118.181033,
      "city": "Sumpter",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97880,
      "latitude": 45.498472,
      "longitude": -118.704081,
      "city": "Ukiah",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97882,
      "latitude": 45.869529,
      "longitude": -118.95693,
      "city": "Umatilla",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97883,
      "latitude": 45.162055,
      "longitude": -117.848982,
      "city": "Union",
      "state": "OR",
      "county": "Union"
  },
  {
      "zipCode": 97884,
      "latitude": 44.456084,
      "longitude": -118.195489,
      "city": "Unity",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97885,
      "latitude": 45.655005,
      "longitude": -117.511052,
      "city": "Wallowa",
      "state": "OR",
      "county": "Wallowa"
  },
  {
      "zipCode": 97886,
      "latitude": 45.788782,
      "longitude": -118.300068,
      "city": "Weston",
      "state": "OR",
      "county": "Umatilla"
  },
  {
      "zipCode": 97901,
      "latitude": 43.785214,
      "longitude": -117.368446,
      "city": "Adrian",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97902,
      "latitude": 42.915641,
      "longitude": -117.497988,
      "city": "Arock",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97903,
      "latitude": 44.248595,
      "longitude": -117.563891,
      "city": "Brogan",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97904,
      "latitude": 43.019785,
      "longitude": -119.069624,
      "city": "Drewsey",
      "state": "OR",
      "county": "Harney"
  },
  {
      "zipCode": 97905,
      "latitude": 44.498826,
      "longitude": -117.412907,
      "city": "Durkee",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97906,
      "latitude": 43.830263,
      "longitude": -117.626175,
      "city": "Harper",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97907,
      "latitude": 44.432748,
      "longitude": -117.389163,
      "city": "Huntington",
      "state": "OR",
      "county": "Baker"
  },
  {
      "zipCode": 97908,
      "latitude": 44.341058,
      "longitude": -117.818949,
      "city": "Ironside",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97909,
      "latitude": 44.216093,
      "longitude": -117.481258,
      "city": "Jamieson",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97910,
      "latitude": 42.951169,
      "longitude": -117.469061,
      "city": "Jordan Valley",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97911,
      "latitude": 43.820762,
      "longitude": -118.036522,
      "city": "Juntura",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97913,
      "latitude": 43.627554,
      "longitude": -117.207823,
      "city": "Nyssa",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97914,
      "latitude": 44.093127,
      "longitude": -117.079896,
      "city": "Ontario",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97917,
      "latitude": 43.509919,
      "longitude": -118.098586,
      "city": "Riverside",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97918,
      "latitude": 44.052329,
      "longitude": -117.283258,
      "city": "Vale",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 97920,
      "latitude": 43.966853,
      "longitude": -117.709928,
      "city": "Westfall",
      "state": "OR",
      "county": "Malheur"
  },
  {
      "zipCode": 98001,
      "latitude": 47.465495,
      "longitude": -121.821908,
      "city": "Auburn",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98002,
      "latitude": 47.315422,
      "longitude": -122.20423,
      "city": "Auburn",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98003,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Federal Way",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98004,
      "latitude": 47.615471,
      "longitude": -122.207221,
      "city": "Bellevue",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98005,
      "latitude": 47.620471,
      "longitude": -122.178883,
      "city": "Bellevue",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98006,
      "latitude": 47.551771,
      "longitude": -122.144727,
      "city": "Bellevue",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98007,
      "latitude": 47.620152,
      "longitude": -122.142282,
      "city": "Bellevue",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98008,
      "latitude": 47.605867,
      "longitude": -122.111082,
      "city": "Bellevue",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98009,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Bellevue",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98010,
      "latitude": 47.310568,
      "longitude": -121.998721,
      "city": "Black Diamond",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98011,
      "latitude": 47.750689,
      "longitude": -122.214376,
      "city": "Bothell",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98012,
      "latitude": 47.840068,
      "longitude": -122.197238,
      "city": "Bothell",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98013,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Burton",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98014,
      "latitude": 47.661862,
      "longitude": -121.927236,
      "city": "Carnation",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98015,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Bellevue",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98019,
      "latitude": 47.73459,
      "longitude": -121.931974,
      "city": "Duvall",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98020,
      "latitude": 47.806068,
      "longitude": -122.372401,
      "city": "Edmonds",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98021,
      "latitude": 47.794519,
      "longitude": -122.194276,
      "city": "Bothell",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98022,
      "latitude": 47.234107,
      "longitude": -121.903936,
      "city": "Enumclaw",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98023,
      "latitude": 47.775627,
      "longitude": -122.240337,
      "city": "Federal Way",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98024,
      "latitude": 47.483051,
      "longitude": -121.987813,
      "city": "Fall City",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98025,
      "latitude": 47.591564,
      "longitude": -122.056013,
      "city": "Hobart",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98026,
      "latitude": 47.835318,
      "longitude": -122.326981,
      "city": "Edmonds",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98027,
      "latitude": 47.497419,
      "longitude": -122.010699,
      "city": "Issaquah",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98028,
      "latitude": 47.754219,
      "longitude": -122.124643,
      "city": "Kenmore",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98029,
      "latitude": 47.565587,
      "longitude": -122.025754,
      "city": "Issaquah",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98031,
      "latitude": 47.379972,
      "longitude": -122.16538,
      "city": "Kent",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98032,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Kent",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98033,
      "latitude": 47.673263,
      "longitude": -122.187029,
      "city": "Kirkland",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98034,
      "latitude": 47.725139,
      "longitude": -122.205646,
      "city": "Kirkland",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98035,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Kent",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98036,
      "latitude": 47.804869,
      "longitude": -122.285481,
      "city": "Lynnwood",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98037,
      "latitude": 47.857022,
      "longitude": -122.28301,
      "city": "Lynnwood",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98038,
      "latitude": 47.409304,
      "longitude": -121.969445,
      "city": "Maple Valley",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98039,
      "latitude": 47.62692,
      "longitude": -122.231389,
      "city": "Medina",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98040,
      "latitude": 47.56025,
      "longitude": -122.228083,
      "city": "Mercer Island",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98041,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Bothell",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98042,
      "latitude": 47.353456,
      "longitude": -122.091718,
      "city": "Kent",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98043,
      "latitude": 47.793269,
      "longitude": -122.30764,
      "city": "Mountlake Terrace",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98045,
      "latitude": 47.467224,
      "longitude": -121.687506,
      "city": "North Bend",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98046,
      "latitude": 48.037258,
      "longitude": -121.682271,
      "city": "Lynnwood",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98047,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Pacific",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98050,
      "latitude": 47.536015,
      "longitude": -121.931195,
      "city": "Preston",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98051,
      "latitude": 47.274764,
      "longitude": -121.932232,
      "city": "Ravensdale",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98052,
      "latitude": 47.678756,
      "longitude": -122.121034,
      "city": "Redmond",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98053,
      "latitude": 47.648626,
      "longitude": -122.02106,
      "city": "Redmond",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98054,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Redondo",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98055,
      "latitude": 47.485348,
      "longitude": -122.02967,
      "city": "Renton",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98056,
      "latitude": 47.514671,
      "longitude": -122.186447,
      "city": "Renton",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98057,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Renton",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98058,
      "latitude": 47.432927,
      "longitude": -122.117999,
      "city": "Renton",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98059,
      "latitude": 47.505822,
      "longitude": -122.115699,
      "city": "Renton",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98061,
      "latitude": 47.680763,
      "longitude": -122.576199,
      "city": "Rollingbay",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98062,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seahurst",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98063,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Federal Way",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98064,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Kent",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98065,
      "latitude": 47.534473,
      "longitude": -121.816158,
      "city": "Snoqualmie",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98068,
      "latitude": 47.445171,
      "longitude": -121.431033,
      "city": "Snoqualmie Pass",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98070,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Vashon",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98071,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Auburn",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98072,
      "latitude": 47.740741,
      "longitude": -122.090874,
      "city": "Woodinville",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98073,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Redmond",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98074,
      "latitude": 47.622544,
      "longitude": -122.04421,
      "city": "Sammamish",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98075,
      "latitude": 47.58609,
      "longitude": -122.037436,
      "city": "Sammamish",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98082,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Bothell",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98083,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Kirkland",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98092,
      "latitude": 47.288362,
      "longitude": -122.097976,
      "city": "Auburn",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98093,
      "latitude": 47.311041,
      "longitude": -122.113791,
      "city": "Federal Way",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98101,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98102,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98103,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98104,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98105,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98106,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98107,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98108,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98109,
      "latitude": 47.583868,
      "longitude": -122.052333,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98110,
      "latitude": 47.647774,
      "longitude": -122.538016,
      "city": "Bainbridge Island",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98111,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98112,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98114,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98115,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98116,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98117,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98118,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98119,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98121,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98122,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98124,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98125,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98126,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98129,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98131,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98132,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98133,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98134,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98136,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98138,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98144,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98145,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98146,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98148,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98151,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98154,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98155,
      "latitude": 47.767169,
      "longitude": -122.259737,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98158,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98160,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98161,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98164,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98166,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98168,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98170,
      "latitude": 47.609487,
      "longitude": -122.339073,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98171,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98174,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98177,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98178,
      "latitude": 47.492385,
      "longitude": -122.235856,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98181,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98184,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98185,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98188,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98190,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98191,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98195,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98198,
      "latitude": 47.432251,
      "longitude": -121.803388,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98199,
      "latitude": 47.776875,
      "longitude": -122.264256,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98201,
      "latitude": 47.988661,
      "longitude": -122.199795,
      "city": "Everett",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98203,
      "latitude": 47.946017,
      "longitude": -122.231795,
      "city": "Everett",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98204,
      "latitude": 47.899696,
      "longitude": -122.255522,
      "city": "Everett",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98205,
      "latitude": 47.953523,
      "longitude": -122.123325,
      "city": "Everett",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98206,
      "latitude": 47.859918,
      "longitude": -122.284785,
      "city": "Everett",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98207,
      "latitude": 48.037258,
      "longitude": -121.682271,
      "city": "Everett",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98208,
      "latitude": 47.897284,
      "longitude": -122.190392,
      "city": "Everett",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98220,
      "latitude": 48.688554,
      "longitude": -122.186966,
      "city": "Acme",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98221,
      "latitude": 48.499172,
      "longitude": -122.607111,
      "city": "Anacortes",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98222,
      "latitude": 48.610644,
      "longitude": -122.921551,
      "city": "Blakely Island",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98223,
      "latitude": 48.181498,
      "longitude": -121.959469,
      "city": "Arlington",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98224,
      "latitude": 47.767103,
      "longitude": -121.481395,
      "city": "Baring",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98225,
      "latitude": 48.747687,
      "longitude": -122.454297,
      "city": "Bellingham",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98226,
      "latitude": 48.768396,
      "longitude": -122.486483,
      "city": "Bellingham",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98227,
      "latitude": 48.814753,
      "longitude": -121.988548,
      "city": "Bellingham",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98228,
      "latitude": 48.814753,
      "longitude": -121.988548,
      "city": "Bellingham",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98230,
      "latitude": 48.935767,
      "longitude": -122.366448,
      "city": "Blaine",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98231,
      "latitude": 48.814753,
      "longitude": -121.988548,
      "city": "Blaine",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98232,
      "latitude": 48.565955,
      "longitude": -122.448358,
      "city": "Bow",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98233,
      "latitude": 48.533616,
      "longitude": -122.362337,
      "city": "Burlington",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98235,
      "latitude": 48.470099,
      "longitude": -122.238754,
      "city": "Clearlake",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98236,
      "latitude": 47.956934,
      "longitude": -122.417721,
      "city": "Clinton",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98237,
      "latitude": 48.508868,
      "longitude": -121.697178,
      "city": "Concrete",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98238,
      "latitude": 48.340002,
      "longitude": -122.345571,
      "city": "Conway",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98239,
      "latitude": 48.1894,
      "longitude": -122.66765,
      "city": "Coupeville",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98240,
      "latitude": 48.941068,
      "longitude": -122.625235,
      "city": "Custer",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98241,
      "latitude": 48.239542,
      "longitude": -121.589209,
      "city": "Darrington",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98243,
      "latitude": 48.557824,
      "longitude": -122.983742,
      "city": "Deer Harbor",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98244,
      "latitude": 48.80177,
      "longitude": -122.091834,
      "city": "Deming",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98245,
      "latitude": 48.659128,
      "longitude": -122.867603,
      "city": "Eastsound",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98247,
      "latitude": 48.904384,
      "longitude": -122.321834,
      "city": "Everson",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98248,
      "latitude": 48.861113,
      "longitude": -122.608561,
      "city": "Ferndale",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98249,
      "latitude": 48.019581,
      "longitude": -122.540489,
      "city": "Freeland",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98250,
      "latitude": 48.538181,
      "longitude": -123.068323,
      "city": "Friday Harbor",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98251,
      "latitude": 47.833114,
      "longitude": -121.636528,
      "city": "Gold Bar",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98252,
      "latitude": 48.074894,
      "longitude": -121.798489,
      "city": "Granite Falls",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98253,
      "latitude": 48.100424,
      "longitude": -122.57612,
      "city": "Greenbank",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98255,
      "latitude": 48.525461,
      "longitude": -121.9887,
      "city": "Hamilton",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98256,
      "latitude": 47.81179,
      "longitude": -121.549712,
      "city": "Index",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98257,
      "latitude": 48.414123,
      "longitude": -122.508364,
      "city": "La Conner",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98258,
      "latitude": 48.046751,
      "longitude": -122.056379,
      "city": "Lake Stevens",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98259,
      "latitude": 48.037258,
      "longitude": -121.682271,
      "city": "North Lakewood",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98260,
      "latitude": 48.031815,
      "longitude": -122.458831,
      "city": "Langley",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98261,
      "latitude": 48.498773,
      "longitude": -122.874334,
      "city": "Lopez Island",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98262,
      "latitude": 48.713022,
      "longitude": -122.67647,
      "city": "Lummi Island",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98263,
      "latitude": 48.534462,
      "longitude": -122.058644,
      "city": "Lyman",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98264,
      "latitude": 48.932328,
      "longitude": -122.436249,
      "city": "Lynden",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98266,
      "latitude": 48.853213,
      "longitude": -122.247881,
      "city": "Maple Falls",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98267,
      "latitude": 48.537813,
      "longitude": -121.47384,
      "city": "Marblemount",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98270,
      "latitude": 48.049576,
      "longitude": -122.161951,
      "city": "Marysville",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98271,
      "latitude": 48.079149,
      "longitude": -122.234168,
      "city": "Marysville",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98272,
      "latitude": 47.863149,
      "longitude": -121.88433,
      "city": "Monroe",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98273,
      "latitude": 48.435248,
      "longitude": -122.208184,
      "city": "Mount Vernon",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98274,
      "latitude": 48.364253,
      "longitude": -122.140311,
      "city": "Mount Vernon",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98275,
      "latitude": 47.912402,
      "longitude": -122.302114,
      "city": "Mukilteo",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98276,
      "latitude": 48.834208,
      "longitude": -122.335734,
      "city": "Nooksack",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98277,
      "latitude": 48.319858,
      "longitude": -122.629353,
      "city": "Oak Harbor",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98278,
      "latitude": 48.310127,
      "longitude": -122.655862,
      "city": "Oak Harbor",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98279,
      "latitude": 48.557824,
      "longitude": -122.983742,
      "city": "Olga",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98280,
      "latitude": 48.590114,
      "longitude": -122.940972,
      "city": "Orcas",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98281,
      "latitude": 48.987386,
      "longitude": -123.058243,
      "city": "Point Roberts",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98282,
      "latitude": 48.212509,
      "longitude": -122.499121,
      "city": "Camano Island",
      "state": "WA",
      "county": "Island"
  },
  {
      "zipCode": 98283,
      "latitude": 48.428261,
      "longitude": -121.609872,
      "city": "Rockport",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98284,
      "latitude": 48.486147,
      "longitude": -122.137243,
      "city": "Sedro Woolley",
      "state": "WA",
      "county": "Skagit"
  },
  {
      "zipCode": 98286,
      "latitude": 48.557824,
      "longitude": -122.983742,
      "city": "Shaw Island",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98287,
      "latitude": 48.037258,
      "longitude": -121.682271,
      "city": "Silvana",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98288,
      "latitude": 47.722245,
      "longitude": -121.369352,
      "city": "Skykomish",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": 98290,
      "latitude": 47.954558,
      "longitude": -122.014722,
      "city": "Snohomish",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98291,
      "latitude": 47.909511,
      "longitude": -122.050108,
      "city": "Snohomish",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98292,
      "latitude": 48.080148,
      "longitude": -122.1064,
      "city": "Stanwood",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98293,
      "latitude": 47.870275,
      "longitude": -121.76849,
      "city": "Startup",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98294,
      "latitude": 47.869494,
      "longitude": -121.731275,
      "city": "Sultan",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98295,
      "latitude": 48.953377,
      "longitude": -122.19886,
      "city": "Sumas",
      "state": "WA",
      "county": "Whatcom"
  },
  {
      "zipCode": 98296,
      "latitude": 47.857915,
      "longitude": -122.092025,
      "city": "Snohomish",
      "state": "WA",
      "county": "Snohomish"
  },
  {
      "zipCode": 98297,
      "latitude": 48.557824,
      "longitude": -122.983742,
      "city": "Waldron",
      "state": "WA",
      "county": "San Juan"
  },
  {
      "zipCode": 98303,
      "latitude": 47.156263,
      "longitude": -122.706706,
      "city": "Anderson Island",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98304,
      "latitude": 46.752611,
      "longitude": -122.05517,
      "city": "Ashford",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98305,
      "latitude": 48.006094,
      "longitude": -124.360572,
      "city": "Beaver",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98310,
      "latitude": 47.606478,
      "longitude": -122.634712,
      "city": "Bremerton",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98311,
      "latitude": 47.627129,
      "longitude": -122.637276,
      "city": "Bremerton",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98312,
      "latitude": 47.578921,
      "longitude": -122.79859,
      "city": "Bremerton",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98314,
      "latitude": 47.559317,
      "longitude": -122.64915,
      "city": "Bremerton",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98315,
      "latitude": 47.690048,
      "longitude": -122.712678,
      "city": "Silverdale",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98320,
      "latitude": 47.698291,
      "longitude": -122.943809,
      "city": "Brinnon",
      "state": "WA",
      "county": "Jefferson"
  },
  {
      "zipCode": 98321,
      "latitude": 47.152347,
      "longitude": -122.079177,
      "city": "Buckley",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98322,
      "latitude": 47.687971,
      "longitude": -122.729304,
      "city": "Burley",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98323,
      "latitude": 47.05221,
      "longitude": -122.025502,
      "city": "Carbonado",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98324,
      "latitude": 48.18315,
      "longitude": -123.872972,
      "city": "Carlsborg",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98325,
      "latitude": 47.915272,
      "longitude": -122.815701,
      "city": "Chimacum",
      "state": "WA",
      "county": "Jefferson"
  },
  {
      "zipCode": 98326,
      "latitude": 48.199745,
      "longitude": -124.390233,
      "city": "Clallam Bay",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98327,
      "latitude": 47.090904,
      "longitude": -122.656691,
      "city": "Dupont",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98328,
      "latitude": 46.904844,
      "longitude": -122.30141,
      "city": "Eatonville",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98329,
      "latitude": 47.369501,
      "longitude": -122.715346,
      "city": "Gig Harbor",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98330,
      "latitude": 46.777707,
      "longitude": -122.202297,
      "city": "Elbe",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98331,
      "latitude": 47.976301,
      "longitude": -124.369332,
      "city": "Forks",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98332,
      "latitude": 47.363745,
      "longitude": -122.603703,
      "city": "Gig Harbor",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98333,
      "latitude": 47.248013,
      "longitude": -122.628989,
      "city": "Fox Island",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98335,
      "latitude": 47.293619,
      "longitude": -122.623504,
      "city": "Gig Harbor",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98336,
      "latitude": 46.53912,
      "longitude": -122.100372,
      "city": "Glenoma",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98337,
      "latitude": 47.568617,
      "longitude": -122.63735,
      "city": "Bremerton",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98338,
      "latitude": 46.96608,
      "longitude": -122.384322,
      "city": "Graham",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98339,
      "latitude": 47.955331,
      "longitude": -122.791259,
      "city": "Port Hadlock",
      "state": "WA",
      "county": "Jefferson"
  },
  {
      "zipCode": 98340,
      "latitude": 47.868952,
      "longitude": -122.557486,
      "city": "Hansville",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98342,
      "latitude": 47.753544,
      "longitude": -122.566561,
      "city": "Indianola",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98343,
      "latitude": 48.18315,
      "longitude": -123.872972,
      "city": "Joyce",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98344,
      "latitude": 46.989916,
      "longitude": -122.222642,
      "city": "Kapowsin",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98345,
      "latitude": 47.698025,
      "longitude": -122.625529,
      "city": "Keyport",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98346,
      "latitude": 47.834483,
      "longitude": -122.547078,
      "city": "Kingston",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98348,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "La Grande",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98349,
      "latitude": 47.288589,
      "longitude": -122.777592,
      "city": "Lakebay",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98350,
      "latitude": 47.905203,
      "longitude": -124.62608,
      "city": "La Push",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98351,
      "latitude": 47.207364,
      "longitude": -122.772012,
      "city": "Longbranch",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98352,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Sumner",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98353,
      "latitude": 47.595155,
      "longitude": -122.622513,
      "city": "Manchester",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98354,
      "latitude": 47.245769,
      "longitude": -122.311073,
      "city": "Milton",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98355,
      "latitude": 46.713843,
      "longitude": -122.093813,
      "city": "Mineral",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98356,
      "latitude": 46.591038,
      "longitude": -122.068418,
      "city": "Morton",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98357,
      "latitude": 48.327961,
      "longitude": -124.615051,
      "city": "Neah Bay",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98358,
      "latitude": 48.057776,
      "longitude": -122.691213,
      "city": "Nordland",
      "state": "WA",
      "county": "Jefferson"
  },
  {
      "zipCode": 98359,
      "latitude": 47.435986,
      "longitude": -122.580554,
      "city": "Olalla",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98360,
      "latitude": 47.006578,
      "longitude": -122.118676,
      "city": "Orting",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98361,
      "latitude": 46.621697,
      "longitude": -121.652807,
      "city": "Packwood",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98362,
      "latitude": 48.051108,
      "longitude": -123.360411,
      "city": "Port Angeles",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98363,
      "latitude": 48.055733,
      "longitude": -123.91784,
      "city": "Port Angeles",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98364,
      "latitude": 47.834431,
      "longitude": -122.592763,
      "city": "Port Gamble",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98365,
      "latitude": 47.911892,
      "longitude": -122.709606,
      "city": "Port Ludlow",
      "state": "WA",
      "county": "Jefferson"
  },
  {
      "zipCode": 98366,
      "latitude": 47.542664,
      "longitude": -122.587102,
      "city": "Port Orchard",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98367,
      "latitude": 47.470651,
      "longitude": -122.650972,
      "city": "Port Orchard",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98368,
      "latitude": 48.018572,
      "longitude": -122.849683,
      "city": "Port Townsend",
      "state": "WA",
      "county": "Jefferson"
  },
  {
      "zipCode": 98370,
      "latitude": 47.751046,
      "longitude": -122.614094,
      "city": "Poulsbo",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98371,
      "latitude": 47.212822,
      "longitude": -122.317148,
      "city": "Puyallup",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98372,
      "latitude": 47.197581,
      "longitude": -122.162076,
      "city": "Puyallup",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98373,
      "latitude": 47.145817,
      "longitude": -122.309704,
      "city": "Puyallup",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98374,
      "latitude": 47.139095,
      "longitude": -122.258646,
      "city": "Puyallup",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98375,
      "latitude": 47.098845,
      "longitude": -122.36393,
      "city": "Puyallup",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98376,
      "latitude": 47.828495,
      "longitude": -122.838769,
      "city": "Quilcene",
      "state": "WA",
      "county": "Jefferson"
  },
  {
      "zipCode": 98377,
      "latitude": 46.516754,
      "longitude": -121.894411,
      "city": "Randle",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98378,
      "latitude": 47.687971,
      "longitude": -122.729304,
      "city": "Retsil",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98380,
      "latitude": 47.591261,
      "longitude": -122.868581,
      "city": "Seabeck",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98381,
      "latitude": 48.288633,
      "longitude": -124.397803,
      "city": "Sekiu",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98382,
      "latitude": 48.06773,
      "longitude": -123.080362,
      "city": "Sequim",
      "state": "WA",
      "county": "Clallam"
  },
  {
      "zipCode": 98383,
      "latitude": 47.672498,
      "longitude": -122.707982,
      "city": "Silverdale",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98384,
      "latitude": 47.521803,
      "longitude": -122.539637,
      "city": "South Colby",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98385,
      "latitude": 47.138271,
      "longitude": -122.096799,
      "city": "South Prairie",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98386,
      "latitude": 47.51037,
      "longitude": -122.499139,
      "city": "Southworth",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98387,
      "latitude": 47.058452,
      "longitude": -122.41648,
      "city": "Spanaway",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98388,
      "latitude": 47.132671,
      "longitude": -122.331781,
      "city": "Steilacoom",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98390,
      "latitude": 47.176966,
      "longitude": -122.15982,
      "city": "Sumner",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98392,
      "latitude": 47.732624,
      "longitude": -122.564557,
      "city": "Suquamish",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98393,
      "latitude": 47.626717,
      "longitude": -122.649953,
      "city": "Tracyton",
      "state": "WA",
      "county": "Kitsap"
  },
  {
      "zipCode": 98394,
      "latitude": 47.311117,
      "longitude": -122.772503,
      "city": "Vaughn",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98395,
      "latitude": 47.266017,
      "longitude": -122.832799,
      "city": "Wauna",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98396,
      "latitude": 47.109524,
      "longitude": -122.037015,
      "city": "Wilkeson",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98397,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Longmire",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98398,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Paradise Inn",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98401,
      "latitude": 47.253671,
      "longitude": -122.444335,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98402,
      "latitude": 47.261502,
      "longitude": -122.463308,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98403,
      "latitude": 47.265371,
      "longitude": -122.457087,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98404,
      "latitude": 47.209131,
      "longitude": -122.397929,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98405,
      "latitude": 47.247871,
      "longitude": -122.472793,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98406,
      "latitude": 47.259216,
      "longitude": -122.509235,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98407,
      "latitude": 47.287606,
      "longitude": -122.500486,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98408,
      "latitude": 47.206871,
      "longitude": -122.448534,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98409,
      "latitude": 47.205777,
      "longitude": -122.321095,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98411,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98412,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98413,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98415,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98416,
      "latitude": 47.264577,
      "longitude": -122.482076,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98418,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98421,
      "latitude": 47.254021,
      "longitude": -122.400133,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98422,
      "latitude": 47.283379,
      "longitude": -122.388781,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98424,
      "latitude": 47.232513,
      "longitude": -122.359432,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98430,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Camp Murray",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98431,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98433,
      "latitude": 47.126735,
      "longitude": -122.491749,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98438,
      "latitude": 47.151297,
      "longitude": -122.567631,
      "city": "Mcchord Afb",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98439,
      "latitude": 47.128671,
      "longitude": -122.510316,
      "city": "Lakewood",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98442,
      "latitude": 47.308061,
      "longitude": -122.418495,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98443,
      "latitude": 47.207371,
      "longitude": -122.373466,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98444,
      "latitude": 47.146371,
      "longitude": -122.457172,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98445,
      "latitude": 47.129145,
      "longitude": -122.409355,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98446,
      "latitude": 47.128485,
      "longitude": -122.373631,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98447,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98450,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98455,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98460,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98464,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98465,
      "latitude": 47.254749,
      "longitude": -122.530336,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98466,
      "latitude": 47.243075,
      "longitude": -122.53936,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98467,
      "latitude": 47.18717,
      "longitude": -122.532335,
      "city": "University Place",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98471,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98477,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98481,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98492,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Lakewood",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98493,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Tacoma",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98497,
      "latitude": 47.066193,
      "longitude": -122.113223,
      "city": "Lakewood",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98498,
      "latitude": 47.159149,
      "longitude": -122.548486,
      "city": "Lakewood",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98499,
      "latitude": 47.16772,
      "longitude": -122.50236,
      "city": "Lakewood",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98501,
      "latitude": 46.984767,
      "longitude": -122.869079,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98502,
      "latitude": 47.104269,
      "longitude": -123.055153,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98503,
      "latitude": 47.026418,
      "longitude": -122.796695,
      "city": "Lacey",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98504,
      "latitude": 46.978118,
      "longitude": -122.702393,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98505,
      "latitude": 47.065404,
      "longitude": -122.976187,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98506,
      "latitude": 47.104227,
      "longitude": -122.870043,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98507,
      "latitude": 46.978118,
      "longitude": -122.702393,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98508,
      "latitude": 46.978118,
      "longitude": -122.702393,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98509,
      "latitude": 46.978118,
      "longitude": -122.702393,
      "city": "Lacey",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98511,
      "latitude": 46.986422,
      "longitude": -122.904723,
      "city": "Tumwater",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98512,
      "latitude": 46.949776,
      "longitude": -123.021207,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98513,
      "latitude": 46.993897,
      "longitude": -122.743016,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98516,
      "latitude": 47.112575,
      "longitude": -122.779419,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98520,
      "latitude": 47.002735,
      "longitude": -123.67129,
      "city": "Aberdeen",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98522,
      "latitude": 46.571323,
      "longitude": -123.298384,
      "city": "Adna",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98524,
      "latitude": 47.322429,
      "longitude": -122.984003,
      "city": "Allyn",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98526,
      "latitude": 47.485913,
      "longitude": -123.920555,
      "city": "Amanda Park",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98527,
      "latitude": 46.637057,
      "longitude": -123.884996,
      "city": "Bay Center",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98528,
      "latitude": 47.432465,
      "longitude": -122.928877,
      "city": "Belfair",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98530,
      "latitude": 46.798216,
      "longitude": -122.871798,
      "city": "Bucoda",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98531,
      "latitude": 46.713822,
      "longitude": -123.01046,
      "city": "Centralia",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98532,
      "latitude": 46.615653,
      "longitude": -123.024845,
      "city": "Chehalis",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98533,
      "latitude": 46.579292,
      "longitude": -122.568709,
      "city": "Cinebar",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98535,
      "latitude": 47.149086,
      "longitude": -124.139665,
      "city": "Copalis Beach",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98536,
      "latitude": 47.159136,
      "longitude": -124.135203,
      "city": "Copalis Crossing",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98537,
      "latitude": 46.878164,
      "longitude": -123.64539,
      "city": "Cosmopolis",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98538,
      "latitude": 46.495639,
      "longitude": -123.146764,
      "city": "Curtis",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98539,
      "latitude": 46.637834,
      "longitude": -123.280421,
      "city": "Doty",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98540,
      "latitude": 46.978118,
      "longitude": -122.702393,
      "city": "East Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98541,
      "latitude": 47.16,
      "longitude": -123.736287,
      "city": "Elma",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98542,
      "latitude": 46.530022,
      "longitude": -122.724004,
      "city": "Ethel",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98544,
      "latitude": 46.734909,
      "longitude": -123.02536,
      "city": "Galvin",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98546,
      "latitude": 47.327254,
      "longitude": -122.916256,
      "city": "Grapeview",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98547,
      "latitude": 46.826407,
      "longitude": -124.094009,
      "city": "Grayland",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98548,
      "latitude": 47.446972,
      "longitude": -123.198634,
      "city": "Hoodsport",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98550,
      "latitude": 47.101202,
      "longitude": -123.731611,
      "city": "Hoquiam",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98552,
      "latitude": 47.342898,
      "longitude": -123.929112,
      "city": "Humptulips",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98554,
      "latitude": 46.561375,
      "longitude": -123.54788,
      "city": "Lebam",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98555,
      "latitude": 47.532281,
      "longitude": -123.049632,
      "city": "Lilliwaup",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98556,
      "latitude": 46.902376,
      "longitude": -123.017043,
      "city": "Littlerock",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98557,
      "latitude": 47.029716,
      "longitude": -123.272999,
      "city": "Mccleary",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98558,
      "latitude": 46.955863,
      "longitude": -122.55585,
      "city": "Mckenna",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98559,
      "latitude": 46.958918,
      "longitude": -123.326762,
      "city": "Malone",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98560,
      "latitude": 47.263601,
      "longitude": -123.441178,
      "city": "Matlock",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98561,
      "latitude": 46.514625,
      "longitude": -123.76903,
      "city": "Menlo",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98562,
      "latitude": 47.162482,
      "longitude": -123.794069,
      "city": "Moclips",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98563,
      "latitude": 47.015536,
      "longitude": -123.719565,
      "city": "Montesano",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98564,
      "latitude": 46.50281,
      "longitude": -122.468293,
      "city": "Mossyrock",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98565,
      "latitude": 46.579281,
      "longitude": -122.909666,
      "city": "Napavine",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98566,
      "latitude": 47.387674,
      "longitude": -123.890317,
      "city": "Neilton",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98568,
      "latitude": 46.866084,
      "longitude": -123.283859,
      "city": "Oakville",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98569,
      "latitude": 47.071971,
      "longitude": -124.152461,
      "city": "Ocean Shores",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98570,
      "latitude": 46.563491,
      "longitude": -122.690491,
      "city": "Onalaska",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98571,
      "latitude": 47.219468,
      "longitude": -124.191502,
      "city": "Pacific Beach",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98572,
      "latitude": 46.582897,
      "longitude": -123.314715,
      "city": "Pe Ell",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98575,
      "latitude": 47.46028,
      "longitude": -123.830676,
      "city": "Quinault",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98576,
      "latitude": 46.873509,
      "longitude": -122.661394,
      "city": "Rainier",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98577,
      "latitude": 46.653496,
      "longitude": -123.657572,
      "city": "Raymond",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98579,
      "latitude": 46.832816,
      "longitude": -123.05927,
      "city": "Rochester",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98580,
      "latitude": 46.93823,
      "longitude": -122.468087,
      "city": "Roy",
      "state": "WA",
      "county": "Pierce"
  },
  {
      "zipCode": 98581,
      "latitude": 46.379714,
      "longitude": -123.056039,
      "city": "Ryderwood",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98582,
      "latitude": 46.514633,
      "longitude": -122.648072,
      "city": "Salkum",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98583,
      "latitude": 47.002178,
      "longitude": -123.483596,
      "city": "Satsop",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98584,
      "latitude": 47.263255,
      "longitude": -123.159683,
      "city": "Shelton",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98585,
      "latitude": 46.546091,
      "longitude": -122.487578,
      "city": "Silver Creek",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98586,
      "latitude": 46.588006,
      "longitude": -123.834262,
      "city": "South Bend",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98587,
      "latitude": 47.230148,
      "longitude": -124.232056,
      "city": "Taholah",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98588,
      "latitude": 47.434953,
      "longitude": -123.096769,
      "city": "Tahuya",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98589,
      "latitude": 46.84275,
      "longitude": -122.849451,
      "city": "Tenino",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98590,
      "latitude": 46.730891,
      "longitude": -124.027885,
      "city": "Tokeland",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98591,
      "latitude": 46.456038,
      "longitude": -122.79498,
      "city": "Toledo",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98592,
      "latitude": 47.336838,
      "longitude": -123.074952,
      "city": "Union",
      "state": "WA",
      "county": "Mason"
  },
  {
      "zipCode": 98593,
      "latitude": 46.423038,
      "longitude": -122.973972,
      "city": "Vader",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98595,
      "latitude": 46.894327,
      "longitude": -123.965158,
      "city": "Westport",
      "state": "WA",
      "county": "Grays Harbor"
  },
  {
      "zipCode": 98596,
      "latitude": 46.490874,
      "longitude": -122.879917,
      "city": "Winlock",
      "state": "WA",
      "county": "Lewis"
  },
  {
      "zipCode": 98597,
      "latitude": 46.882364,
      "longitude": -122.562689,
      "city": "Yelm",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98599,
      "latitude": 46.978118,
      "longitude": -122.702393,
      "city": "Olympia",
      "state": "WA",
      "county": "Thurston"
  },
  {
      "zipCode": 98601,
      "latitude": 45.923556,
      "longitude": -122.450194,
      "city": "Amboy",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98602,
      "latitude": 45.850437,
      "longitude": -121.312852,
      "city": "Appleton",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98603,
      "latitude": 45.999338,
      "longitude": -122.442472,
      "city": "Ariel",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98604,
      "latitude": 45.803592,
      "longitude": -122.531645,
      "city": "Battle Ground",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98605,
      "latitude": 45.721519,
      "longitude": -121.447323,
      "city": "Bingen",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98606,
      "latitude": 45.731631,
      "longitude": -122.477093,
      "city": "Brush Prairie",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98607,
      "latitude": 45.656489,
      "longitude": -122.399341,
      "city": "Camas",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98609,
      "latitude": 46.071482,
      "longitude": -122.864838,
      "city": "Carrolls",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98610,
      "latitude": 45.784205,
      "longitude": -121.841736,
      "city": "Carson",
      "state": "WA",
      "county": "Skamania"
  },
  {
      "zipCode": 98611,
      "latitude": 46.220778,
      "longitude": -122.673021,
      "city": "Castle Rock",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98612,
      "latitude": 46.214628,
      "longitude": -123.334899,
      "city": "Cathlamet",
      "state": "WA",
      "county": "Wahkiakum"
  },
  {
      "zipCode": 98613,
      "latitude": 45.738205,
      "longitude": -120.993902,
      "city": "Centerville",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98614,
      "latitude": 46.263023,
      "longitude": -123.933161,
      "city": "Chinook",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98616,
      "latitude": 46.025434,
      "longitude": -122.359589,
      "city": "Cougar",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98617,
      "latitude": 45.632681,
      "longitude": -121.17169,
      "city": "Dallesport",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98619,
      "latitude": 45.989951,
      "longitude": -121.282054,
      "city": "Glenwood",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98620,
      "latitude": 45.840666,
      "longitude": -120.768762,
      "city": "Goldendale",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98621,
      "latitude": 46.355461,
      "longitude": -123.597874,
      "city": "Grays River",
      "state": "WA",
      "county": "Wahkiakum"
  },
  {
      "zipCode": 98622,
      "latitude": 45.801586,
      "longitude": -122.520347,
      "city": "Heisson",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98623,
      "latitude": 45.807631,
      "longitude": -121.487682,
      "city": "Husum",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98624,
      "latitude": 46.317185,
      "longitude": -123.999449,
      "city": "Ilwaco",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98625,
      "latitude": 46.002114,
      "longitude": -122.72175,
      "city": "Kalama",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98626,
      "latitude": 46.147274,
      "longitude": -122.825047,
      "city": "Kelso",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98628,
      "latitude": 45.856217,
      "longitude": -121.044345,
      "city": "Klickitat",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98629,
      "latitude": 45.881459,
      "longitude": -122.615416,
      "city": "La Center",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98631,
      "latitude": 46.376798,
      "longitude": -123.995591,
      "city": "Long Beach",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98632,
      "latitude": 46.181872,
      "longitude": -123.057597,
      "city": "Longview",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98635,
      "latitude": 45.822789,
      "longitude": -121.213705,
      "city": "Lyle",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98637,
      "latitude": 46.514625,
      "longitude": -123.76903,
      "city": "Nahcotta",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98638,
      "latitude": 46.342219,
      "longitude": -123.802552,
      "city": "Naselle",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98639,
      "latitude": 45.641704,
      "longitude": -122.005783,
      "city": "North Bonneville",
      "state": "WA",
      "county": "Skamania"
  },
  {
      "zipCode": 98640,
      "latitude": 46.495565,
      "longitude": -124.040389,
      "city": "Ocean Park",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98641,
      "latitude": 46.544789,
      "longitude": -124.042671,
      "city": "Oysterville",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98642,
      "latitude": 45.756797,
      "longitude": -122.620162,
      "city": "Ridgefield",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98643,
      "latitude": 46.310011,
      "longitude": -123.614319,
      "city": "Rosburg",
      "state": "WA",
      "county": "Wahkiakum"
  },
  {
      "zipCode": 98644,
      "latitude": 46.330755,
      "longitude": -124.04553,
      "city": "Seaview",
      "state": "WA",
      "county": "Pacific"
  },
  {
      "zipCode": 98645,
      "latitude": 46.314662,
      "longitude": -122.728835,
      "city": "Silverlake",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98647,
      "latitude": 46.300349,
      "longitude": -123.456145,
      "city": "Skamokawa",
      "state": "WA",
      "county": "Wahkiakum"
  },
  {
      "zipCode": 98648,
      "latitude": 45.673707,
      "longitude": -121.912581,
      "city": "Stevenson",
      "state": "WA",
      "county": "Skamania"
  },
  {
      "zipCode": 98649,
      "latitude": 46.334539,
      "longitude": -122.647324,
      "city": "Toutle",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98650,
      "latitude": 45.958949,
      "longitude": -121.53564,
      "city": "Trout Lake",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98651,
      "latitude": 45.75826,
      "longitude": -121.576567,
      "city": "Underwood",
      "state": "WA",
      "county": "Skamania"
  },
  {
      "zipCode": 98660,
      "latitude": 45.74327,
      "longitude": -122.713366,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98661,
      "latitude": 45.769989,
      "longitude": -122.643658,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98662,
      "latitude": 45.691419,
      "longitude": -122.580457,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98663,
      "latitude": 45.650488,
      "longitude": -122.663608,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98664,
      "latitude": 45.620669,
      "longitude": -122.576413,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98665,
      "latitude": 45.689187,
      "longitude": -122.661608,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98666,
      "latitude": 45.801586,
      "longitude": -122.520347,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98667,
      "latitude": 45.801586,
      "longitude": -122.520347,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98668,
      "latitude": 45.801586,
      "longitude": -122.520347,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98670,
      "latitude": 45.810317,
      "longitude": -121.302305,
      "city": "Wahkiacus",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98671,
      "latitude": 45.611697,
      "longitude": -122.315559,
      "city": "Washougal",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98672,
      "latitude": 45.828588,
      "longitude": -121.405863,
      "city": "White Salmon",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98673,
      "latitude": 45.641548,
      "longitude": -121.07317,
      "city": "Wishram",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 98674,
      "latitude": 45.937521,
      "longitude": -122.686548,
      "city": "Woodland",
      "state": "WA",
      "county": "Cowlitz"
  },
  {
      "zipCode": 98675,
      "latitude": 45.824611,
      "longitude": -122.498993,
      "city": "Yacolt",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98682,
      "latitude": 45.678782,
      "longitude": -122.478845,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98683,
      "latitude": 45.603216,
      "longitude": -122.513318,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98684,
      "latitude": 45.6359,
      "longitude": -122.515538,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98685,
      "latitude": 45.754637,
      "longitude": -122.696001,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98686,
      "latitude": 45.705566,
      "longitude": -122.572193,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98687,
      "latitude": 45.801586,
      "longitude": -122.520347,
      "city": "Vancouver",
      "state": "WA",
      "county": "Clark"
  },
  {
      "zipCode": 98801,
      "latitude": 47.573816,
      "longitude": -120.351884,
      "city": "Wenatchee",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98802,
      "latitude": 47.497831,
      "longitude": -120.214402,
      "city": "East Wenatchee",
      "state": "WA",
      "county": "Douglas"
  },
  {
      "zipCode": 98807,
      "latitude": 47.905787,
      "longitude": -120.520211,
      "city": "Wenatchee",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98811,
      "latitude": 47.708473,
      "longitude": -120.329107,
      "city": "Ardenvoir",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98812,
      "latitude": 48.365236,
      "longitude": -119.829391,
      "city": "Brewster",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98813,
      "latitude": 47.735893,
      "longitude": -119.891998,
      "city": "Bridgeport",
      "state": "WA",
      "county": "Douglas"
  },
  {
      "zipCode": 98814,
      "latitude": 48.551984,
      "longitude": -119.812938,
      "city": "Carlton",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98815,
      "latitude": 47.6329,
      "longitude": -120.348064,
      "city": "Cashmere",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98816,
      "latitude": 47.876985,
      "longitude": -120.07775,
      "city": "Chelan",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98817,
      "latitude": 47.841829,
      "longitude": -119.957918,
      "city": "Chelan Falls",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98819,
      "latitude": 48.546905,
      "longitude": -119.75262,
      "city": "Conconully",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98821,
      "latitude": 47.582186,
      "longitude": -120.595955,
      "city": "Dryden",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98822,
      "latitude": 47.774872,
      "longitude": -120.356279,
      "city": "Entiat",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98823,
      "latitude": 47.177565,
      "longitude": -119.401958,
      "city": "Ephrata",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98824,
      "latitude": 47.079243,
      "longitude": -119.860117,
      "city": "George",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98826,
      "latitude": 47.695101,
      "longitude": -120.799413,
      "city": "Leavenworth",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98827,
      "latitude": 48.892514,
      "longitude": -119.721016,
      "city": "Loomis",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98828,
      "latitude": 47.319535,
      "longitude": -120.173347,
      "city": "Malaga",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98829,
      "latitude": 48.248002,
      "longitude": -119.75246,
      "city": "Malott",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98830,
      "latitude": 47.847735,
      "longitude": -119.525987,
      "city": "Mansfield",
      "state": "WA",
      "county": "Douglas"
  },
  {
      "zipCode": 98831,
      "latitude": 47.817133,
      "longitude": -120.080148,
      "city": "Manson",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98832,
      "latitude": 47.301392,
      "longitude": -119.063956,
      "city": "Marlin",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98833,
      "latitude": 48.619527,
      "longitude": -120.457954,
      "city": "Mazama",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98834,
      "latitude": 48.139518,
      "longitude": -120.046348,
      "city": "Methow",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98836,
      "latitude": 47.485197,
      "longitude": -120.415773,
      "city": "Monitor",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98837,
      "latitude": 47.180684,
      "longitude": -119.298979,
      "city": "Moses Lake",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98840,
      "latitude": 48.577378,
      "longitude": -119.956787,
      "city": "Okanogan",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98841,
      "latitude": 48.426942,
      "longitude": -119.560672,
      "city": "Omak",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98843,
      "latitude": 47.73051,
      "longitude": -120.045781,
      "city": "Orondo",
      "state": "WA",
      "county": "Douglas"
  },
  {
      "zipCode": 98844,
      "latitude": 48.681342,
      "longitude": -119.579107,
      "city": "Oroville",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98845,
      "latitude": 47.393566,
      "longitude": -119.90361,
      "city": "Palisades",
      "state": "WA",
      "county": "Douglas"
  },
  {
      "zipCode": 98846,
      "latitude": 48.411234,
      "longitude": -119.742154,
      "city": "Pateros",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98847,
      "latitude": 47.545758,
      "longitude": -120.596146,
      "city": "Peshastin",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98848,
      "latitude": 47.182173,
      "longitude": -119.754143,
      "city": "Quincy",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98849,
      "latitude": 48.518361,
      "longitude": -119.434674,
      "city": "Riverside",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98850,
      "latitude": 47.312058,
      "longitude": -120.103205,
      "city": "Rock Island",
      "state": "WA",
      "county": "Douglas"
  },
  {
      "zipCode": 98851,
      "latitude": 47.404594,
      "longitude": -119.442377,
      "city": "Soap Lake",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98852,
      "latitude": 47.905787,
      "longitude": -120.520211,
      "city": "Stehekin",
      "state": "WA",
      "county": "Chelan"
  },
  {
      "zipCode": 98853,
      "latitude": 47.404308,
      "longitude": -119.312977,
      "city": "Stratford",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98855,
      "latitude": 48.502603,
      "longitude": -119.542935,
      "city": "Tonasket",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98856,
      "latitude": 48.446836,
      "longitude": -119.811252,
      "city": "Twisp",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98857,
      "latitude": 47.013759,
      "longitude": -119.087915,
      "city": "Warden",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98858,
      "latitude": 47.701979,
      "longitude": -119.86123,
      "city": "Waterville",
      "state": "WA",
      "county": "Douglas"
  },
  {
      "zipCode": 98859,
      "latitude": 48.798456,
      "longitude": -119.025578,
      "city": "Wauconda",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98860,
      "latitude": 47.473874,
      "longitude": -119.18473,
      "city": "Wilson Creek",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 98862,
      "latitude": 48.500635,
      "longitude": -120.372447,
      "city": "Winthrop",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 98901,
      "latitude": 46.644476,
      "longitude": -120.725557,
      "city": "Yakima",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98902,
      "latitude": 46.420138,
      "longitude": -120.29712,
      "city": "Yakima",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98903,
      "latitude": 46.544506,
      "longitude": -120.744393,
      "city": "Yakima",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98904,
      "latitude": 46.56451,
      "longitude": -120.694658,
      "city": "Yakima",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98907,
      "latitude": 46.628757,
      "longitude": -120.573967,
      "city": "Yakima",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98908,
      "latitude": 46.527811,
      "longitude": -120.687412,
      "city": "Yakima",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98909,
      "latitude": 46.637538,
      "longitude": -120.794978,
      "city": "Yakima",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98920,
      "latitude": 46.56451,
      "longitude": -120.694658,
      "city": "Brownstown",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98921,
      "latitude": 46.421218,
      "longitude": -120.315141,
      "city": "Buena",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98922,
      "latitude": 47.156558,
      "longitude": -120.806926,
      "city": "Cle Elum",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98923,
      "latitude": 46.678192,
      "longitude": -120.753007,
      "city": "Cowiche",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98925,
      "latitude": 47.232953,
      "longitude": -121.177187,
      "city": "Easton",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98926,
      "latitude": 47.028084,
      "longitude": -120.48998,
      "city": "Ellensburg",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98929,
      "latitude": 46.915735,
      "longitude": -121.241591,
      "city": "Goose Prairie",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98930,
      "latitude": 46.45594,
      "longitude": -120.312088,
      "city": "Grandview",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98932,
      "latitude": 46.340157,
      "longitude": -120.15493,
      "city": "Granger",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98933,
      "latitude": 46.323528,
      "longitude": -120.375459,
      "city": "Harrah",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98934,
      "latitude": 46.980645,
      "longitude": -120.416259,
      "city": "Kittitas",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98935,
      "latitude": 46.165237,
      "longitude": -120.050377,
      "city": "Mabton",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98936,
      "latitude": 46.410236,
      "longitude": -120.233518,
      "city": "Moxee",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98937,
      "latitude": 46.805796,
      "longitude": -120.992096,
      "city": "Naches",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98938,
      "latitude": 46.366004,
      "longitude": -120.119917,
      "city": "Outlook",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98939,
      "latitude": 46.56451,
      "longitude": -120.694658,
      "city": "Parker",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98940,
      "latitude": 47.250307,
      "longitude": -121.045575,
      "city": "Ronald",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98941,
      "latitude": 47.223287,
      "longitude": -121.002513,
      "city": "Roslyn",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98942,
      "latitude": 46.544316,
      "longitude": -120.396038,
      "city": "Selah",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98943,
      "latitude": 47.188645,
      "longitude": -120.95368,
      "city": "South Cle Elum",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98944,
      "latitude": 46.373611,
      "longitude": -120.01059,
      "city": "Sunnyside",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98946,
      "latitude": 47.073905,
      "longitude": -120.758301,
      "city": "Thorp",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98947,
      "latitude": 46.740371,
      "longitude": -120.693103,
      "city": "Tieton",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98948,
      "latitude": 46.328522,
      "longitude": -120.370257,
      "city": "Toppenish",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98950,
      "latitude": 46.871354,
      "longitude": -119.985869,
      "city": "Vantage",
      "state": "WA",
      "county": "Kittitas"
  },
  {
      "zipCode": 98951,
      "latitude": 46.522398,
      "longitude": -120.508444,
      "city": "Wapato",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98952,
      "latitude": 46.449869,
      "longitude": -120.667145,
      "city": "White Swan",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 98953,
      "latitude": 46.419838,
      "longitude": -120.231713,
      "city": "Zillah",
      "state": "WA",
      "county": "Yakima"
  },
  {
      "zipCode": 99001,
      "latitude": 47.643803,
      "longitude": -117.59205,
      "city": "Airway Heights",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99003,
      "latitude": 47.92167,
      "longitude": -117.262989,
      "city": "Chattaroy",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99004,
      "latitude": 47.6067,
      "longitude": -117.500285,
      "city": "Cheney",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99005,
      "latitude": 47.852847,
      "longitude": -117.337529,
      "city": "Colbert",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99006,
      "latitude": 47.73874,
      "longitude": -117.442395,
      "city": "Deer Park",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99008,
      "latitude": 47.60894,
      "longitude": -118.399551,
      "city": "Edwall",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99009,
      "latitude": 47.992362,
      "longitude": -117.26615,
      "city": "Elk",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99011,
      "latitude": 47.633161,
      "longitude": -117.654603,
      "city": "Fairchild Air Force Base",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99012,
      "latitude": 47.405253,
      "longitude": -117.17253,
      "city": "Fairfield",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99013,
      "latitude": 47.913372,
      "longitude": -117.797447,
      "city": "Ford",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99014,
      "latitude": 47.55988,
      "longitude": -117.595751,
      "city": "Four Lakes",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99015,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Freeman",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99016,
      "latitude": 47.622784,
      "longitude": -117.138178,
      "city": "Greenacres",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99017,
      "latitude": 46.96178,
      "longitude": -117.687163,
      "city": "Lamont",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99018,
      "latitude": 47.299889,
      "longitude": -117.139491,
      "city": "Latah",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99019,
      "latitude": 47.651635,
      "longitude": -117.085845,
      "city": "Liberty Lake",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99020,
      "latitude": 47.617665,
      "longitude": -117.592591,
      "city": "Marshall",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99021,
      "latitude": 47.834074,
      "longitude": -117.240392,
      "city": "Mead",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99022,
      "latitude": 47.615007,
      "longitude": -117.703977,
      "city": "Medical Lake",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99023,
      "latitude": 47.557733,
      "longitude": -117.183637,
      "city": "Mica",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99025,
      "latitude": 47.76727,
      "longitude": -117.090112,
      "city": "Newman Lake",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99026,
      "latitude": 47.799507,
      "longitude": -117.619375,
      "city": "Nine Mile Falls",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99027,
      "latitude": 47.734728,
      "longitude": -117.107735,
      "city": "Otis Orchards",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99029,
      "latitude": 47.701645,
      "longitude": -117.755268,
      "city": "Reardan",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99030,
      "latitude": 47.672177,
      "longitude": -117.255465,
      "city": "Rockford",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99031,
      "latitude": 47.438748,
      "longitude": -117.370017,
      "city": "Spangle",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99032,
      "latitude": 47.299844,
      "longitude": -117.977732,
      "city": "Sprague",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99033,
      "latitude": 47.226641,
      "longitude": -117.096157,
      "city": "Tekoa",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99034,
      "latitude": 47.894108,
      "longitude": -117.753629,
      "city": "Tumtum",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99036,
      "latitude": 47.520107,
      "longitude": -117.270216,
      "city": "Valleyford",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99037,
      "latitude": 47.636834,
      "longitude": -117.199587,
      "city": "Veradale",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99039,
      "latitude": 47.329704,
      "longitude": -117.244981,
      "city": "Waverly",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99040,
      "latitude": 47.881367,
      "longitude": -117.933742,
      "city": "Wellpinit",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99101,
      "latitude": 48.336378,
      "longitude": -117.957926,
      "city": "Addy",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99102,
      "latitude": 46.934379,
      "longitude": -117.412136,
      "city": "Albion",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99103,
      "latitude": 47.529705,
      "longitude": -118.599193,
      "city": "Almira",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99104,
      "latitude": 47.079345,
      "longitude": -117.176954,
      "city": "Belmont",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99105,
      "latitude": 46.859127,
      "longitude": -118.161058,
      "city": "Benge",
      "state": "WA",
      "county": "Adams"
  },
  {
      "zipCode": 99107,
      "latitude": 48.84269,
      "longitude": -118.182764,
      "city": "Boyds",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99109,
      "latitude": 48.293181,
      "longitude": -117.739245,
      "city": "Chewelah",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99110,
      "latitude": 48.007138,
      "longitude": -117.557907,
      "city": "Clayton",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99111,
      "latitude": 46.959127,
      "longitude": -117.505163,
      "city": "Colfax",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99113,
      "latitude": 46.575075,
      "longitude": -117.206588,
      "city": "Colton",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99114,
      "latitude": 48.676916,
      "longitude": -117.809315,
      "city": "Colville",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99115,
      "latitude": 47.737206,
      "longitude": -119.227032,
      "city": "Coulee City",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 99116,
      "latitude": 48.037889,
      "longitude": -118.941876,
      "city": "Coulee Dam",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 99117,
      "latitude": 47.644181,
      "longitude": -118.450679,
      "city": "Creston",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99118,
      "latitude": 48.894734,
      "longitude": -118.669864,
      "city": "Curlew",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99119,
      "latitude": 48.510409,
      "longitude": -117.348383,
      "city": "Cusick",
      "state": "WA",
      "county": "Pend Oreille"
  },
  {
      "zipCode": 99121,
      "latitude": 48.954016,
      "longitude": -118.505768,
      "city": "Danville",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99122,
      "latitude": 47.712295,
      "longitude": -118.122173,
      "city": "Davenport",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99123,
      "latitude": 47.913065,
      "longitude": -119.042562,
      "city": "Electric City",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 99124,
      "latitude": 48.015361,
      "longitude": -118.954893,
      "city": "Elmer City",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 99125,
      "latitude": 46.911972,
      "longitude": -117.702655,
      "city": "Endicott",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99126,
      "latitude": 48.740671,
      "longitude": -117.972888,
      "city": "Evans",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99127,
      "latitude": 46.838747,
      "longitude": -117.644337,
      "city": "Saint John",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99128,
      "latitude": 47.066613,
      "longitude": -117.361435,
      "city": "Farmington",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99129,
      "latitude": 48.005075,
      "longitude": -118.228564,
      "city": "Fruitland",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99130,
      "latitude": 46.929426,
      "longitude": -117.170603,
      "city": "Garfield",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99131,
      "latitude": 48.268511,
      "longitude": -118.113552,
      "city": "Gifford",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99133,
      "latitude": 47.822463,
      "longitude": -119.042714,
      "city": "Grand Coulee",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 99134,
      "latitude": 47.478233,
      "longitude": -118.254439,
      "city": "Harrington",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99135,
      "latitude": 47.611505,
      "longitude": -119.084131,
      "city": "Hartline",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 99136,
      "latitude": 46.697296,
      "longitude": -117.941665,
      "city": "Hay",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99137,
      "latitude": 48.150398,
      "longitude": -118.077422,
      "city": "Hunters",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99138,
      "latitude": 48.268566,
      "longitude": -118.305461,
      "city": "Inchelium",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99139,
      "latitude": 48.737714,
      "longitude": -117.36469,
      "city": "Ione",
      "state": "WA",
      "county": "Pend Oreille"
  },
  {
      "zipCode": 99140,
      "latitude": 48.889691,
      "longitude": -118.217849,
      "city": "Keller",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99141,
      "latitude": 48.695338,
      "longitude": -117.966943,
      "city": "Kettle Falls",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99143,
      "latitude": 46.759878,
      "longitude": -117.728679,
      "city": "Lacrosse",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99144,
      "latitude": 47.60894,
      "longitude": -118.399551,
      "city": "Lamona",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99146,
      "latitude": 48.975272,
      "longitude": -118.220816,
      "city": "Laurier",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99147,
      "latitude": 47.60894,
      "longitude": -118.399551,
      "city": "Lincoln",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99148,
      "latitude": 48.107165,
      "longitude": -117.627815,
      "city": "Loon Lake",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99149,
      "latitude": 47.0101,
      "longitude": -117.476605,
      "city": "Malden",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99150,
      "latitude": 48.810999,
      "longitude": -118.604355,
      "city": "Malo",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99151,
      "latitude": 48.786802,
      "longitude": -117.924021,
      "city": "Marcus",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99152,
      "latitude": 48.484729,
      "longitude": -117.22144,
      "city": "Metaline",
      "state": "WA",
      "county": "Pend Oreille"
  },
  {
      "zipCode": 99153,
      "latitude": 48.731121,
      "longitude": -117.339995,
      "city": "Metaline Falls",
      "state": "WA",
      "county": "Pend Oreille"
  },
  {
      "zipCode": 99154,
      "latitude": 47.60894,
      "longitude": -118.399551,
      "city": "Mohler",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99155,
      "latitude": 48.143441,
      "longitude": -119.00938,
      "city": "Nespelem",
      "state": "WA",
      "county": "Okanogan"
  },
  {
      "zipCode": 99156,
      "latitude": 48.448981,
      "longitude": -117.228067,
      "city": "Newport",
      "state": "WA",
      "county": "Pend Oreille"
  },
  {
      "zipCode": 99157,
      "latitude": 48.905142,
      "longitude": -117.851127,
      "city": "Northport",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99158,
      "latitude": 47.079658,
      "longitude": -117.41146,
      "city": "Oakesdale",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99159,
      "latitude": 47.344402,
      "longitude": -118.658816,
      "city": "Odessa",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99160,
      "latitude": 48.887781,
      "longitude": -118.210467,
      "city": "Orient",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99161,
      "latitude": 46.770141,
      "longitude": -117.157476,
      "city": "Palouse",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99163,
      "latitude": 46.82748,
      "longitude": -117.317741,
      "city": "Pullman",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99164,
      "latitude": 46.838747,
      "longitude": -117.644337,
      "city": "Pullman",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99165,
      "latitude": 46.838747,
      "longitude": -117.644337,
      "city": "Pullman",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99166,
      "latitude": 48.667659,
      "longitude": -118.680631,
      "city": "Republic",
      "state": "WA",
      "county": "Ferry"
  },
  {
      "zipCode": 99167,
      "latitude": 48.42424,
      "longitude": -118.100693,
      "city": "Rice",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99169,
      "latitude": 47.087055,
      "longitude": -118.4813,
      "city": "Ritzville",
      "state": "WA",
      "county": "Adams"
  },
  {
      "zipCode": 99170,
      "latitude": 47.034713,
      "longitude": -117.4646,
      "city": "Rosalia",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99171,
      "latitude": 47.123718,
      "longitude": -117.676432,
      "city": "Saint John",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99173,
      "latitude": 48.002188,
      "longitude": -117.828964,
      "city": "Springdale",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99174,
      "latitude": 46.838747,
      "longitude": -117.644337,
      "city": "Steptoe",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99176,
      "latitude": 47.12196,
      "longitude": -117.433708,
      "city": "Thornton",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99179,
      "latitude": 46.545655,
      "longitude": -117.10797,
      "city": "Uniontown",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99180,
      "latitude": 48.25013,
      "longitude": -117.353111,
      "city": "Usk",
      "state": "WA",
      "county": "Pend Oreille"
  },
  {
      "zipCode": 99181,
      "latitude": 48.124594,
      "longitude": -117.762554,
      "city": "Valley",
      "state": "WA",
      "county": "Stevens"
  },
  {
      "zipCode": 99185,
      "latitude": 47.761025,
      "longitude": -118.729143,
      "city": "Wilbur",
      "state": "WA",
      "county": "Lincoln"
  },
  {
      "zipCode": 99201,
      "latitude": 47.66264,
      "longitude": -117.435997,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99202,
      "latitude": 47.613475,
      "longitude": -117.515897,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99203,
      "latitude": 47.629341,
      "longitude": -117.406269,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99204,
      "latitude": 47.650091,
      "longitude": -117.429846,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99205,
      "latitude": 47.693501,
      "longitude": -117.44995,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99206,
      "latitude": 47.665198,
      "longitude": -117.373399,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99207,
      "latitude": 47.693766,
      "longitude": -117.379096,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99208,
      "latitude": 47.780541,
      "longitude": -117.455375,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99209,
      "latitude": 47.646148,
      "longitude": -117.793734,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99210,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99211,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99212,
      "latitude": 47.670693,
      "longitude": -117.310991,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99213,
      "latitude": 47.656726,
      "longitude": -117.282483,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99214,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99215,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99216,
      "latitude": 47.671164,
      "longitude": -117.19926,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99217,
      "latitude": 47.737957,
      "longitude": -117.25569,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99218,
      "latitude": 47.75754,
      "longitude": -117.407552,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99219,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99220,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99223,
      "latitude": 47.592452,
      "longitude": -117.34212,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99224,
      "latitude": 47.628858,
      "longitude": -117.551291,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99228,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99251,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99252,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99256,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99258,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99260,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99299,
      "latitude": 47.653568,
      "longitude": -117.431742,
      "city": "Spokane",
      "state": "WA",
      "county": "Spokane"
  },
  {
      "zipCode": 99301,
      "latitude": 46.42066,
      "longitude": -118.899447,
      "city": "Pasco",
      "state": "WA",
      "county": "Franklin"
  },
  {
      "zipCode": 99302,
      "latitude": 46.234957,
      "longitude": -119.094271,
      "city": "Pasco",
      "state": "WA",
      "county": "Franklin"
  },
  {
      "zipCode": 99320,
      "latitude": 46.24204,
      "longitude": -119.483517,
      "city": "Benton City",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99321,
      "latitude": 46.844992,
      "longitude": -119.935689,
      "city": "Beverly",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 99322,
      "latitude": 45.887647,
      "longitude": -120.281675,
      "city": "Bickleton",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 99323,
      "latitude": 46.196597,
      "longitude": -118.901678,
      "city": "Burbank",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99324,
      "latitude": 46.047069,
      "longitude": -118.409275,
      "city": "College Place",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99326,
      "latitude": 46.590118,
      "longitude": -118.897786,
      "city": "Connell",
      "state": "WA",
      "county": "Franklin"
  },
  {
      "zipCode": 99327,
      "latitude": 46.828931,
      "longitude": -118.829153,
      "city": "Cunningham",
      "state": "WA",
      "county": "Adams"
  },
  {
      "zipCode": 99328,
      "latitude": 46.392683,
      "longitude": -117.94953,
      "city": "Dayton",
      "state": "WA",
      "county": "Columbia"
  },
  {
      "zipCode": 99329,
      "latitude": 46.140821,
      "longitude": -118.153064,
      "city": "Dixie",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99330,
      "latitude": 46.508917,
      "longitude": -118.992619,
      "city": "Eltopia",
      "state": "WA",
      "county": "Franklin"
  },
  {
      "zipCode": 99332,
      "latitude": 46.77062,
      "longitude": -118.800049,
      "city": "Hatton",
      "state": "WA",
      "county": "Adams"
  },
  {
      "zipCode": 99333,
      "latitude": 46.838747,
      "longitude": -117.644337,
      "city": "Hooper",
      "state": "WA",
      "county": "Whitman"
  },
  {
      "zipCode": 99335,
      "latitude": 46.677852,
      "longitude": -118.53374,
      "city": "Kahlotus",
      "state": "WA",
      "county": "Franklin"
  },
  {
      "zipCode": 99336,
      "latitude": 46.212306,
      "longitude": -119.155671,
      "city": "Kennewick",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99337,
      "latitude": 46.117122,
      "longitude": -119.090676,
      "city": "Kennewick",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99338,
      "latitude": 46.155994,
      "longitude": -119.263991,
      "city": "Kennewick",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99341,
      "latitude": 46.904229,
      "longitude": -118.690546,
      "city": "Lind",
      "state": "WA",
      "county": "Adams"
  },
  {
      "zipCode": 99343,
      "latitude": 46.577941,
      "longitude": -119.162115,
      "city": "Mesa",
      "state": "WA",
      "county": "Franklin"
  },
  {
      "zipCode": 99344,
      "latitude": 46.852708,
      "longitude": -118.993182,
      "city": "Othello",
      "state": "WA",
      "county": "Adams"
  },
  {
      "zipCode": 99345,
      "latitude": 45.859058,
      "longitude": -119.857639,
      "city": "Paterson",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99346,
      "latitude": 45.930067,
      "longitude": -119.402276,
      "city": "Plymouth",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99347,
      "latitude": 46.475341,
      "longitude": -117.537763,
      "city": "Pomeroy",
      "state": "WA",
      "county": "Garfield"
  },
  {
      "zipCode": 99348,
      "latitude": 46.354972,
      "longitude": -118.455541,
      "city": "Prescott",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99349,
      "latitude": 46.730957,
      "longitude": -119.778257,
      "city": "Mattawa",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 99350,
      "latitude": 46.148328,
      "longitude": -119.559674,
      "city": "Prosser",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99352,
      "latitude": 46.282031,
      "longitude": -119.491659,
      "city": "Richland",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99353,
      "latitude": 46.315267,
      "longitude": -119.371393,
      "city": "West Richland",
      "state": "WA",
      "county": "Benton"
  },
  {
      "zipCode": 99356,
      "latitude": 45.851428,
      "longitude": -120.354257,
      "city": "Roosevelt",
      "state": "WA",
      "county": "Klickitat"
  },
  {
      "zipCode": 99357,
      "latitude": 46.897589,
      "longitude": -119.686316,
      "city": "Royal City",
      "state": "WA",
      "county": "Grant"
  },
  {
      "zipCode": 99359,
      "latitude": 46.512828,
      "longitude": -118.10237,
      "city": "Starbuck",
      "state": "WA",
      "county": "Columbia"
  },
  {
      "zipCode": 99360,
      "latitude": 46.134445,
      "longitude": -118.461436,
      "city": "Touchet",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99361,
      "latitude": 46.159844,
      "longitude": -118.139822,
      "city": "Waitsburg",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99362,
      "latitude": 46.1341,
      "longitude": -118.291407,
      "city": "Walla Walla",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99363,
      "latitude": 46.084584,
      "longitude": -118.906079,
      "city": "Wallula",
      "state": "WA",
      "county": "Walla Walla"
  },
  {
      "zipCode": 99371,
      "latitude": 46.815306,
      "longitude": -118.338081,
      "city": "Washtucna",
      "state": "WA",
      "county": "Adams"
  },
  {
      "zipCode": 99401,
      "latitude": 46.121593,
      "longitude": -117.17858,
      "city": "Anatone",
      "state": "WA",
      "county": "Asotin"
  },
  {
      "zipCode": 99402,
      "latitude": 46.174099,
      "longitude": -117.118489,
      "city": "Asotin",
      "state": "WA",
      "county": "Asotin"
  },
  {
      "zipCode": 99403,
      "latitude": 46.369711,
      "longitude": -117.225934,
      "city": "Clarkston",
      "state": "WA",
      "county": "Asotin"
  },
  {
      "zipCode": 99501,
      "latitude": 61.287624,
      "longitude": -149.486981,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99502,
      "latitude": 61.166069,
      "longitude": -149.960008,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99503,
      "latitude": 61.188411,
      "longitude": -149.893867,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99504,
      "latitude": 61.205284,
      "longitude": -149.771928,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99505,
      "latitude": 61.259092,
      "longitude": -149.690318,
      "city": "Fort Richardson",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99506,
      "latitude": 61.257409,
      "longitude": -149.804165,
      "city": "Elmendorf Afb",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99507,
      "latitude": 61.151903,
      "longitude": -149.776157,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99508,
      "latitude": 61.205076,
      "longitude": -149.823203,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99509,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99510,
      "latitude": 61.144568,
      "longitude": -149.878418,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99511,
      "latitude": 61.068324,
      "longitude": -149.800476,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99512,
      "latitude": 61.203954,
      "longitude": -149.808426,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99513,
      "latitude": 61.214877,
      "longitude": -149.886174,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99514,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99515,
      "latitude": 61.115754,
      "longitude": -149.912019,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99516,
      "latitude": 61.091201,
      "longitude": -149.762371,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99517,
      "latitude": 61.189494,
      "longitude": -149.93809,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99518,
      "latitude": 61.157813,
      "longitude": -149.894338,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99519,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99520,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99521,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99522,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99523,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99524,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99540,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Indian",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99546,
      "latitude": 54.24018,
      "longitude": -176.787412,
      "city": "Adak",
      "state": "AK",
      "county": "Aleutians West"
  },
  {
      "zipCode": 99547,
      "latitude": 54.24018,
      "longitude": -176.787412,
      "city": "Atka",
      "state": "AK",
      "county": "Aleutians West"
  },
  {
      "zipCode": 99548,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Chignik Lake",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99549,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Port Heiden",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99550,
      "latitude": 57.701171,
      "longitude": -153.505567,
      "city": "Port Lions",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99551,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Akiachak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99552,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Akiak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99553,
      "latitude": 55.430594,
      "longitude": -162.55813,
      "city": "Akutan",
      "state": "AK",
      "county": "Aleutians East"
  },
  {
      "zipCode": 99554,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Alakanuk",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99555,
      "latitude": 58.962101,
      "longitude": -163.118947,
      "city": "Aleknagik",
      "state": "AK",
      "county": "Dillingham"
  },
  {
      "zipCode": 99556,
      "latitude": 59.837116,
      "longitude": -151.70781,
      "city": "Anchor Point",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99557,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Aniak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99558,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Anvik",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99559,
      "latitude": 60.129979,
      "longitude": -162.173906,
      "city": "Bethel",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99561,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Chefornak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99563,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Chevak",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99564,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Chignik",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99565,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Chignik Lagoon",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99566,
      "latitude": 61.470966,
      "longitude": -144.991027,
      "city": "Chitina",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99567,
      "latitude": 61.393834,
      "longitude": -149.424631,
      "city": "Chugiak",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99568,
      "latitude": 60.187496,
      "longitude": -151.442983,
      "city": "Clam Gulch",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99569,
      "latitude": 58.962101,
      "longitude": -163.118947,
      "city": "Clarks Point",
      "state": "AK",
      "county": "Dillingham"
  },
  {
      "zipCode": 99571,
      "latitude": 55.650875,
      "longitude": -160.654522,
      "city": "Cold Bay",
      "state": "AK",
      "county": "Aleutians East"
  },
  {
      "zipCode": 99572,
      "latitude": 60.491478,
      "longitude": -149.770159,
      "city": "Cooper Landing",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99573,
      "latitude": 61.470966,
      "longitude": -144.991027,
      "city": "Copper Center",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99574,
      "latitude": 60.536166,
      "longitude": -145.75335,
      "city": "Cordova",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99575,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Crooked Creek",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99576,
      "latitude": 59.040181,
      "longitude": -158.523138,
      "city": "Dillingham",
      "state": "AK",
      "county": "Dillingham"
  },
  {
      "zipCode": 99577,
      "latitude": 61.303131,
      "longitude": -149.424518,
      "city": "Eagle River",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99578,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Eek",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99579,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Egegik",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99580,
      "latitude": 58.962101,
      "longitude": -163.118947,
      "city": "Ekwok",
      "state": "AK",
      "county": "Dillingham"
  },
  {
      "zipCode": 99581,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Emmonak",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99583,
      "latitude": 55.430594,
      "longitude": -162.55813,
      "city": "False Pass",
      "state": "AK",
      "county": "Aleutians East"
  },
  {
      "zipCode": 99584,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Flat",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99585,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Marshall",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99586,
      "latitude": 61.470966,
      "longitude": -144.991027,
      "city": "Gakona",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99587,
      "latitude": 60.957674,
      "longitude": -149.140623,
      "city": "Girdwood",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99588,
      "latitude": 61.470966,
      "longitude": -144.991027,
      "city": "Glennallen",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99589,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Goodnews Bay",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99590,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Grayling",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99591,
      "latitude": 54.24018,
      "longitude": -176.787412,
      "city": "Saint George Island",
      "state": "AK",
      "county": "Aleutians West"
  },
  {
      "zipCode": 99599,
      "latitude": 61.187188,
      "longitude": -149.880393,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99602,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Holy Cross",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99603,
      "latitude": 60.056453,
      "longitude": -151.362422,
      "city": "Homer",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99604,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Hooper Bay",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99605,
      "latitude": 60.036583,
      "longitude": -151.655567,
      "city": "Hope",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99606,
      "latitude": 59.752463,
      "longitude": -154.823629,
      "city": "Iliamna",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99607,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Kalskag",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99608,
      "latitude": 58.268704,
      "longitude": -155.797078,
      "city": "Karluk",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99609,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Kasigluk",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99610,
      "latitude": 60.352575,
      "longitude": -151.116301,
      "city": "Kasilof",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99611,
      "latitude": 60.422041,
      "longitude": -150.407958,
      "city": "Kenai",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99612,
      "latitude": 55.430594,
      "longitude": -162.55813,
      "city": "King Cove",
      "state": "AK",
      "county": "Aleutians East"
  },
  {
      "zipCode": 99613,
      "latitude": 58.745599,
      "longitude": -157.159459,
      "city": "King Salmon",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99614,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Kipnuk",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99615,
      "latitude": 57.762816,
      "longitude": -152.472876,
      "city": "Kodiak",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99619,
      "latitude": 58.268704,
      "longitude": -155.797078,
      "city": "Kodiak",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99620,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Kotlik",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99621,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Kwethluk",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99622,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Kwigillingok",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99624,
      "latitude": 58.268704,
      "longitude": -155.797078,
      "city": "Larsen Bay",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99625,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Levelock",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99626,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Lower Kalskag",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99627,
      "latitude": 62.945716,
      "longitude": -155.5712,
      "city": "Mc Grath",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99628,
      "latitude": 58.962101,
      "longitude": -163.118947,
      "city": "Manokotak",
      "state": "AK",
      "county": "Dillingham"
  },
  {
      "zipCode": 99630,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Mekoryuk",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99631,
      "latitude": 60.036583,
      "longitude": -151.655567,
      "city": "Moose Pass",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99632,
      "latitude": 62.332461,
      "longitude": -164.287228,
      "city": "Mountain Village",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99633,
      "latitude": 58.655425,
      "longitude": -156.788199,
      "city": "Naknek",
      "state": "AK",
      "county": "Bristol Bay"
  },
  {
      "zipCode": 99634,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Napakiak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99635,
      "latitude": 60.700696,
      "longitude": -151.276633,
      "city": "Nikiski",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99636,
      "latitude": 58.962101,
      "longitude": -163.118947,
      "city": "New Stuyahok",
      "state": "AK",
      "county": "Dillingham"
  },
  {
      "zipCode": 99637,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Toksook Bay",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99638,
      "latitude": 54.24018,
      "longitude": -176.787412,
      "city": "Nikolski",
      "state": "AK",
      "county": "Aleutians West"
  },
  {
      "zipCode": 99639,
      "latitude": 60.04914,
      "longitude": -151.517286,
      "city": "Ninilchik",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99640,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Nondalton",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99641,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Nunapitchuk",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99643,
      "latitude": 58.268704,
      "longitude": -155.797078,
      "city": "Old Harbor",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99644,
      "latitude": 58.268704,
      "longitude": -155.797078,
      "city": "Ouzinkie",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99645,
      "latitude": 62.257284,
      "longitude": -149.633916,
      "city": "Palmer",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99647,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Pedro Bay",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99648,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Perryville",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99649,
      "latitude": 57.528787,
      "longitude": -157.485781,
      "city": "Pilot Point",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99650,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Pilot Station",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99651,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Platinum",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99652,
      "latitude": 61.523043,
      "longitude": -149.728806,
      "city": "Big Lake",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99653,
      "latitude": 58.268704,
      "longitude": -156.648418,
      "city": "Port Alsworth",
      "state": "AK",
      "county": "Lake And Peninsula"
  },
  {
      "zipCode": 99654,
      "latitude": 61.581305,
      "longitude": -149.374335,
      "city": "Wasilla",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99655,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Quinhagak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99656,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Red Devil",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99657,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Russian Mission",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99658,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Saint Marys",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99659,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Saint Michael",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99660,
      "latitude": 54.24018,
      "longitude": -176.787412,
      "city": "Saint Paul Island",
      "state": "AK",
      "county": "Aleutians West"
  },
  {
      "zipCode": 99661,
      "latitude": 55.350356,
      "longitude": -160.485506,
      "city": "Sand Point",
      "state": "AK",
      "county": "Aleutians East"
  },
  {
      "zipCode": 99662,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Scammon Bay",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99663,
      "latitude": 59.449486,
      "longitude": -151.700915,
      "city": "Seldovia",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99664,
      "latitude": 60.25281,
      "longitude": -149.402123,
      "city": "Seward",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99665,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Shageluk",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99666,
      "latitude": 62.117231,
      "longitude": -163.237636,
      "city": "Nunam Iqua",
      "state": "AK",
      "county": "Wade Hampton"
  },
  {
      "zipCode": 99667,
      "latitude": 62.280436,
      "longitude": -149.715167,
      "city": "Skwentna",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99668,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Sleetmute",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99669,
      "latitude": 60.110034,
      "longitude": -151.247935,
      "city": "Soldotna",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99670,
      "latitude": 58.745599,
      "longitude": -157.159459,
      "city": "South Naknek",
      "state": "AK",
      "county": "Bristol Bay"
  },
  {
      "zipCode": 99671,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Stebbins",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99672,
      "latitude": 60.513614,
      "longitude": -150.853238,
      "city": "Sterling",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99674,
      "latitude": 61.732741,
      "longitude": -148.783687,
      "city": "Sutton",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99675,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Takotna",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99676,
      "latitude": 62.280436,
      "longitude": -149.715167,
      "city": "Talkeetna",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99677,
      "latitude": 61.470966,
      "longitude": -144.991027,
      "city": "Tatitlek",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99678,
      "latitude": 58.962101,
      "longitude": -163.118947,
      "city": "Togiak",
      "state": "AK",
      "county": "Dillingham"
  },
  {
      "zipCode": 99679,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Tuluksak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99680,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Tuntutuliak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99681,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Tununak",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99682,
      "latitude": 60.036583,
      "longitude": -151.655567,
      "city": "Tyonek",
      "state": "AK",
      "county": "Kenai Peninsula"
  },
  {
      "zipCode": 99683,
      "latitude": 61.720499,
      "longitude": -150.009239,
      "city": "Trapper Creek",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99684,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Unalakleet",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99685,
      "latitude": 53.85513,
      "longitude": -166.518069,
      "city": "Unalaska",
      "state": "AK",
      "county": "Aleutians West"
  },
  {
      "zipCode": 99686,
      "latitude": 61.68557,
      "longitude": -145.775152,
      "city": "Valdez",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99687,
      "latitude": 61.531298,
      "longitude": -149.478398,
      "city": "Wasilla",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99688,
      "latitude": 61.896608,
      "longitude": -150.003758,
      "city": "Willow",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99689,
      "latitude": 58.331798,
      "longitude": -135.181256,
      "city": "Yakutat",
      "state": "AK",
      "county": "Yakutat"
  },
  {
      "zipCode": 99690,
      "latitude": 60.314735,
      "longitude": -163.118947,
      "city": "Nightmute",
      "state": "AK",
      "county": "Bethel"
  },
  {
      "zipCode": 99691,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Nikolai",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99692,
      "latitude": 53.865295,
      "longitude": -166.525333,
      "city": "Dutch Harbor",
      "state": "AK",
      "county": "Aleutians West"
  },
  {
      "zipCode": 99693,
      "latitude": 61.470966,
      "longitude": -144.991027,
      "city": "Whittier",
      "state": "AK",
      "county": "Valdez Cordova"
  },
  {
      "zipCode": 99694,
      "latitude": 62.280436,
      "longitude": -149.715167,
      "city": "Houston",
      "state": "AK",
      "county": "Matanuska Susitna"
  },
  {
      "zipCode": 99695,
      "latitude": 61.108864,
      "longitude": -149.440311,
      "city": "Anchorage",
      "state": "AK",
      "county": "Anchorage"
  },
  {
      "zipCode": 99697,
      "latitude": 58.268704,
      "longitude": -155.797078,
      "city": "Kodiak",
      "state": "AK",
      "county": "Kodiak Island"
  },
  {
      "zipCode": 99701,
      "latitude": 64.644047,
      "longitude": -147.522052,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99702,
      "latitude": 64.790803,
      "longitude": -147.463336,
      "city": "Eielson Afb",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99703,
      "latitude": 64.832821,
      "longitude": -147.64418,
      "city": "Fort Wainwright",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99704,
      "latitude": 63.543571,
      "longitude": -149.986676,
      "city": "Clear",
      "state": "AK",
      "county": "Denali"
  },
  {
      "zipCode": 99705,
      "latitude": 64.780765,
      "longitude": -147.207748,
      "city": "North Pole",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99706,
      "latitude": 64.852055,
      "longitude": -147.937747,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99707,
      "latitude": 64.699839,
      "longitude": -147.405134,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99708,
      "latitude": 64.947462,
      "longitude": -147.856443,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99709,
      "latitude": 64.860126,
      "longitude": -147.999791,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99710,
      "latitude": 64.948648,
      "longitude": -147.535549,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99711,
      "latitude": 64.826445,
      "longitude": -147.38884,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99712,
      "latitude": 65.036453,
      "longitude": -147.156588,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99714,
      "latitude": 64.489102,
      "longitude": -146.924788,
      "city": "Salcha",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99716,
      "latitude": 64.874591,
      "longitude": -146.898195,
      "city": "Two Rivers",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99720,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Allakaket",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99721,
      "latitude": 66.693255,
      "longitude": -153.993988,
      "city": "Anaktuvuk Pass",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99722,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Arctic Village",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99723,
      "latitude": 70.220472,
      "longitude": -150.238942,
      "city": "Barrow",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99724,
      "latitude": 66.929746,
      "longitude": -149.19301,
      "city": "Beaver",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99725,
      "latitude": 64.870974,
      "longitude": -148.09493,
      "city": "Ester",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99726,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Bettles Field",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99727,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Buckland",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99729,
      "latitude": 63.543571,
      "longitude": -149.986676,
      "city": "Cantwell",
      "state": "AK",
      "county": "Denali"
  },
  {
      "zipCode": 99730,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Central",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99732,
      "latitude": 63.870552,
      "longitude": -144.002648,
      "city": "Chicken",
      "state": "AK",
      "county": "Southeast Fairbanks"
  },
  {
      "zipCode": 99733,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Circle",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99734,
      "latitude": 66.693255,
      "longitude": -153.993988,
      "city": "Prudhoe Bay",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99736,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Deering",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99737,
      "latitude": 63.107461,
      "longitude": -143.422478,
      "city": "Delta Junction",
      "state": "AK",
      "county": "Southeast Fairbanks"
  },
  {
      "zipCode": 99738,
      "latitude": 63.870552,
      "longitude": -144.002648,
      "city": "Eagle",
      "state": "AK",
      "county": "Southeast Fairbanks"
  },
  {
      "zipCode": 99739,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Elim",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99740,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Fort Yukon",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99741,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Galena",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99742,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Gambell",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99743,
      "latitude": 63.543571,
      "longitude": -149.986676,
      "city": "Healy",
      "state": "AK",
      "county": "Denali"
  },
  {
      "zipCode": 99744,
      "latitude": 63.543571,
      "longitude": -149.986676,
      "city": "Anderson",
      "state": "AK",
      "county": "Denali"
  },
  {
      "zipCode": 99745,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Hughes",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99746,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Huslia",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99747,
      "latitude": 66.693255,
      "longitude": -153.993988,
      "city": "Kaktovik",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99748,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Kaltag",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99749,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Kiana",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99750,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Kivalina",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99751,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Kobuk",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99752,
      "latitude": 66.865256,
      "longitude": -161.780844,
      "city": "Kotzebue",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99753,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Koyuk",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99754,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Koyukuk",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99755,
      "latitude": 63.543571,
      "longitude": -149.986676,
      "city": "Denali National Park",
      "state": "AK",
      "county": "Denali"
  },
  {
      "zipCode": 99756,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Manley Hot Springs",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99757,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Lake Minchumina",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99758,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Minto",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99759,
      "latitude": 68.887048,
      "longitude": -164.249713,
      "city": "Point Lay",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99760,
      "latitude": 64.117464,
      "longitude": -153.451125,
      "city": "Nenana",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99761,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Noatak",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99762,
      "latitude": 63.888671,
      "longitude": -163.80076,
      "city": "Nome",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99763,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Noorvik",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99764,
      "latitude": 63.870552,
      "longitude": -144.002648,
      "city": "Northway",
      "state": "AK",
      "county": "Southeast Fairbanks"
  },
  {
      "zipCode": 99765,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Nulato",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99766,
      "latitude": 66.693255,
      "longitude": -153.993988,
      "city": "Point Hope",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99767,
      "latitude": 65.509038,
      "longitude": -150.161868,
      "city": "Rampart",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99768,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Ruby",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99769,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Savoonga",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99770,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Selawik",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99771,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Shaktoolik",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99772,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Shishmaref",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99773,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Shungnak",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99774,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Stevens Village",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99775,
      "latitude": 64.859078,
      "longitude": -147.826709,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99776,
      "latitude": 63.870552,
      "longitude": -144.002648,
      "city": "Tanacross",
      "state": "AK",
      "county": "Southeast Fairbanks"
  },
  {
      "zipCode": 99777,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Tanana",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99778,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Teller",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99779,
      "latitude": 63.870552,
      "longitude": -144.002648,
      "city": "Tetlin",
      "state": "AK",
      "county": "Southeast Fairbanks"
  },
  {
      "zipCode": 99780,
      "latitude": 63.870552,
      "longitude": -144.002648,
      "city": "Tok",
      "state": "AK",
      "county": "Southeast Fairbanks"
  },
  {
      "zipCode": 99781,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Venetie",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99782,
      "latitude": 66.693255,
      "longitude": -153.993988,
      "city": "Wainwright",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99783,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Wales",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99784,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "White Mountain",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99785,
      "latitude": 64.755604,
      "longitude": -165.672344,
      "city": "Brevig Mission",
      "state": "AK",
      "county": "Nome"
  },
  {
      "zipCode": 99786,
      "latitude": 66.868442,
      "longitude": -159.843924,
      "city": "Ambler",
      "state": "AK",
      "county": "Northwest Arctic"
  },
  {
      "zipCode": 99788,
      "latitude": 65.226375,
      "longitude": -151.025085,
      "city": "Chalkyitsik",
      "state": "AK",
      "county": "Yukon Koyukuk"
  },
  {
      "zipCode": 99789,
      "latitude": 66.693255,
      "longitude": -153.993988,
      "city": "Nuiqsut",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99790,
      "latitude": 64.856055,
      "longitude": -146.27598,
      "city": "Fairbanks",
      "state": "AK",
      "county": "Fairbanks North Star"
  },
  {
      "zipCode": 99791,
      "latitude": 70.494693,
      "longitude": -157.441073,
      "city": "Atqasuk",
      "state": "AK",
      "county": "North Slope"
  },
  {
      "zipCode": 99801,
      "latitude": 58.373561,
      "longitude": -134.523946,
      "city": "Juneau",
      "state": "AK",
      "county": "Juneau"
  },
  {
      "zipCode": 99802,
      "latitude": 58.405657,
      "longitude": -134.58969,
      "city": "Juneau",
      "state": "AK",
      "county": "Juneau"
  },
  {
      "zipCode": 99803,
      "latitude": 58.377074,
      "longitude": -134.617383,
      "city": "Juneau",
      "state": "AK",
      "county": "Juneau"
  },
  {
      "zipCode": 99811,
      "latitude": 58.38348,
      "longitude": -134.197786,
      "city": "Juneau",
      "state": "AK",
      "county": "Juneau"
  },
  {
      "zipCode": 99820,
      "latitude": 57.500525,
      "longitude": -134.585954,
      "city": "Angoon",
      "state": "AK",
      "county": "Skagway Hoonah Angoon"
  },
  {
      "zipCode": 99821,
      "latitude": 58.449413,
      "longitude": -134.700348,
      "city": "Auke Bay",
      "state": "AK",
      "county": "Juneau"
  },
  {
      "zipCode": 99824,
      "latitude": 58.276308,
      "longitude": -134.397642,
      "city": "Douglas",
      "state": "AK",
      "county": "Juneau"
  },
  {
      "zipCode": 99825,
      "latitude": 58.331798,
      "longitude": -135.181256,
      "city": "Elfin Cove",
      "state": "AK",
      "county": "Skagway Hoonah Angoon"
  },
  {
      "zipCode": 99826,
      "latitude": 58.331798,
      "longitude": -135.181256,
      "city": "Gustavus",
      "state": "AK",
      "county": "Skagway Hoonah Angoon"
  },
  {
      "zipCode": 99827,
      "latitude": 59.312688,
      "longitude": -135.681153,
      "city": "Haines",
      "state": "AK",
      "county": "Haines"
  },
  {
      "zipCode": 99829,
      "latitude": 58.097655,
      "longitude": -135.431561,
      "city": "Hoonah",
      "state": "AK",
      "county": "Skagway Hoonah Angoon"
  },
  {
      "zipCode": 99830,
      "latitude": 56.973554,
      "longitude": -133.935996,
      "city": "Kake",
      "state": "AK",
      "county": "Wrangell Petersburg"
  },
  {
      "zipCode": 99832,
      "latitude": 58.331798,
      "longitude": -135.181256,
      "city": "Pelican",
      "state": "AK",
      "county": "Skagway Hoonah Angoon"
  },
  {
      "zipCode": 99833,
      "latitude": 56.767056,
      "longitude": -132.93195,
      "city": "Petersburg",
      "state": "AK",
      "county": "Wrangell Petersburg"
  },
  {
      "zipCode": 99835,
      "latitude": 57.080818,
      "longitude": -135.318348,
      "city": "Sitka",
      "state": "AK",
      "county": "Sitka"
  },
  {
      "zipCode": 99836,
      "latitude": 56.53595,
      "longitude": -132.827948,
      "city": "Port Alexander",
      "state": "AK",
      "county": "Wrangell Petersburg"
  },
  {
      "zipCode": 99840,
      "latitude": 59.456753,
      "longitude": -135.314852,
      "city": "Skagway",
      "state": "AK",
      "county": "Skagway Hoonah Angoon"
  },
  {
      "zipCode": 99841,
      "latitude": 57.798249,
      "longitude": -135.285061,
      "city": "Tenakee Springs",
      "state": "AK",
      "county": "Skagway Hoonah Angoon"
  },
  {
      "zipCode": 99850,
      "latitude": 58.38348,
      "longitude": -134.197786,
      "city": "Juneau",
      "state": "AK",
      "county": "Juneau"
  },
  {
      "zipCode": 99901,
      "latitude": 55.400674,
      "longitude": -131.67409,
      "city": "Ketchikan",
      "state": "AK",
      "county": "Ketchikan Gateway"
  },
  {
      "zipCode": 99903,
      "latitude": 55.517921,
      "longitude": -132.003244,
      "city": "Meyers Chuck",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99918,
      "latitude": 55.517921,
      "longitude": -132.003244,
      "city": "Coffman Cove",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99919,
      "latitude": 55.126774,
      "longitude": -131.580407,
      "city": "Thorne Bay",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99921,
      "latitude": 55.513301,
      "longitude": -133.117022,
      "city": "Craig",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99922,
      "latitude": 55.208706,
      "longitude": -132.825903,
      "city": "Hydaburg",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99923,
      "latitude": 55.517921,
      "longitude": -132.003244,
      "city": "Hyder",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99925,
      "latitude": 55.554393,
      "longitude": -133.09068,
      "city": "Klawock",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99926,
      "latitude": 55.094325,
      "longitude": -131.566827,
      "city": "Metlakatla",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99927,
      "latitude": 55.517921,
      "longitude": -132.003244,
      "city": "Point Baker",
      "state": "AK",
      "county": "Prince Wales Ketchikan"
  },
  {
      "zipCode": 99928,
      "latitude": 55.395359,
      "longitude": -131.67537,
      "city": "Ward Cove",
      "state": "AK",
      "county": "Ketchikan Gateway"
  },
  {
      "zipCode": 99929,
      "latitude": 56.449893,
      "longitude": -132.364407,
      "city": "Wrangell",
      "state": "AK",
      "county": "Wrangell Petersburg"
  },
  {
      "zipCode": 99950,
      "latitude": 55.542007,
      "longitude": -131.432682,
      "city": "Ketchikan",
      "state": "AK",
      "county": "Ketchikan Gateway"
  },
  {
      "zipCode": "10001",
      "latitude": 40.712776,
      "longitude": -74.005974,
      "city": "New York",
      "state": "NY",
      "county": "New York"
  },
  {
      "zipCode": "30301",
      "latitude": 33.749001,
      "longitude": -84.387978,
      "city": "Atlanta",
      "state": "GA",
      "county": "Fulton"
  },
  {
      "zipCode": "60601",
      "latitude": 41.878113,
      "longitude": -87.629799,
      "city": "Chicago",
      "state": "IL",
      "county": "Cook"
  },
  {
      "zipCode": "94101",
      "latitude": 37.774929,
      "longitude": -122.419418,
      "city": "San Francisco",
      "state": "CA",
      "county": "San Francisco"
  },
  {
      "zipCode": "73301",
      "latitude": 30.267153,
      "longitude": -97.743057,
      "city": "Austin",
      "state": "TX",
      "county": "Travis"
  },
  {
      "zipCode": "85001",
      "latitude": 33.448376,
      "longitude": -112.074036,
      "city": "Phoenix",
      "state": "AZ",
      "county": "Maricopa"
  },
  {
      "zipCode": "98101",
      "latitude": 47.606209,
      "longitude": -122.332069,
      "city": "Seattle",
      "state": "WA",
      "county": "King"
  },
  {
      "zipCode": "75201",
      "latitude": 32.776664,
      "longitude": -96.796988,
      "city": "Dallas",
      "state": "TX",
      "county": "Dallas"
  },
  {
      "zipCode": "33101",
      "latitude": 25.76168,
      "longitude": -80.19182,
      "city": "Miami",
      "state": "FL",
      "county": "Miami-Dade"
  },
  {
      "zipCode": "20001",
      "latitude": 38.89511,
      "longitude": -77.03637,
      "city": "Washington",
      "state": "DC",
      "county": "District of Columbia"
  },
  {
      "zipCode": "20000",
      "latitude": 41.627549,
      "longitude": -86.275464,
      "city": "Prizren",
      "state": "Kosovo",
      "county": "Prizren"
  },
  {
      "zipCode": "20001",
      "latitude": 42.665671,
      "longitude": 21.165506,
      "city": "Pristina",
      "state": "Kosovo",
      "county": "Pristina"
  },
  {
      "zipCode": "20002",
      "latitude": 42.356078,
      "longitude": 20.881544,
      "city": "Ferizaj",
      "state": "Kosovo",
      "county": "Ferizaj"
  },
  {
      "zipCode": "20003",
      "latitude": 42.701689,
      "longitude": 20.295201,
      "city": "Pej\u00eb",
      "state": "Kosovo",
      "county": "Pej\u00eb"
  },
  {
      "zipCode": "20004",
      "latitude": 42.380655,
      "longitude": 20.405706,
      "city": "Gjakov\u00eb",
      "state": "Kosovo",
      "county": "Gjakov\u00eb"
  },
  {
      "zipCode": "20005",
      "latitude": 42.46879,
      "longitude": 21.226727,
      "city": "Gjilan",
      "state": "Kosovo",
      "county": "Gjilan"
  },
  {
      "zipCode": "20006",
      "latitude": 42.928431,
      "longitude": 21.165581,
      "city": "Podujev\u00eb",
      "state": "Kosovo",
      "county": "Podujev\u00eb"
  },
  {
      "zipCode": "20007",
      "latitude": 42.797423,
      "longitude": 20.872092,
      "city": "Mitrovic\u00eb",
      "state": "Kosovo",
      "county": "Mitrovic\u00eb"
  },
  {
      "zipCode": "20008",
      "latitude": 42.717814,
      "longitude": 21.079795,
      "city": "Lipjan",
      "state": "Kosovo",
      "county": "Lipjan"
  },
  {
      "zipCode": "20009",
      "latitude": 42.741112,
      "longitude": 20.866817,
      "city": "Vushtrri",
      "state": "Kosovo",
      "county": "Vushtrri"
  },
  {
      "zipCode": "20010",
      "latitude": 42.660006,
      "longitude": 20.637509,
      "city": "Suharek\u00eb",
      "state": "Kosovo",
      "county": "Suharek\u00eb"
  },
  {
      "zipCode": "20011",
      "latitude": 42.617087,
      "longitude": 20.951148,
      "city": "Gllogovc",
      "state": "Kosovo",
      "county": "Gllogovc"
  },
  {
      "zipCode": "20012",
      "latitude": 42.773923,
      "longitude": 20.772758,
      "city": "Rahovec",
      "state": "Kosovo",
      "county": "Rahovec"
  },
  {
      "zipCode": "20013",
      "latitude": 42.701639,
      "longitude": 20.755222,
      "city": "Skenderaj",
      "state": "Kosovo",
      "county": "Skenderaj"
  },
  {
      "zipCode": "20014",
      "latitude": 42.764596,
      "longitude": 20.911423,
      "city": "Viti",
      "state": "Kosovo",
      "county": "Viti"
  },
  {
      "zipCode": "20015",
      "latitude": 42.663401,
      "longitude": 21.226373,
      "city": "Fush\u00eb Kosov\u00eb",
      "state": "Kosovo",
      "county": "Fush\u00eb Kosov\u00eb"
  },
  {
      "zipCode": "20016",
      "latitude": 42.748741,
      "longitude": 20.770732,
      "city": "Istog",
      "state": "Kosovo",
      "county": "Istog"
  },
  {
      "zipCode": "20017",
      "latitude": 42.766315,
      "longitude": 20.75192,
      "city": "Klin\u00eb",
      "state": "Kosovo",
      "county": "Klin\u00eb"
  },
  {
      "zipCode": "20018",
      "latitude": 42.722469,
      "longitude": 21.219414,
      "city": "Kamenic\u00eb",
      "state": "Kosovo",
      "county": "Kamenic\u00eb"
  },
  {
      "zipCode": "20019",
      "latitude": 42.628233,
      "longitude": 21.352817,
      "city": "Dragash",
      "state": "Kosovo",
      "county": "Dragash"
  },
  {
      "zipCode": "20020",
      "latitude": 42.582185,
      "longitude": 21.38461,
      "city": "Ka\u00e7anik",
      "state": "Kosovo",
      "county": "Ka\u00e7anik"
  },
  {
      "zipCode": "20021",
      "latitude": 42.637344,
      "longitude": 20.830049,
      "city": "Shtime",
      "state": "Kosovo",
      "county": "Shtime"
  },
  {
      "zipCode": "20022",
      "latitude": 42.718522,
      "longitude": 20.889431,
      "city": "Leposaviq",
      "state": "Kosovo",
      "county": "Leposaviq"
  },
  {
      "zipCode": "20023",
      "latitude": 42.764473,
      "longitude": 20.929274,
      "city": "Zve\u00e7an",
      "state": "Kosovo",
      "county": "Zve\u00e7an"
  },
  {
      "zipCode": "20024",
      "latitude": 42.711453,
      "longitude": 20.868325,
      "city": "Zubin Potok",
      "state": "Kosovo",
      "county": "Zubin Potok"
  },
  {
      "zipCode": "20025",
      "latitude": 42.776935,
      "longitude": 20.868692,
      "city": "Vitomiric\u00eb",
      "state": "Kosovo",
      "county": "Vitomiric\u00eb"
  },
  {
      "zipCode": "20026",
      "latitude": 42.757096,
      "longitude": 20.907214,
      "city": "Mati\u00e7an",
      "state": "Kosovo",
      "county": "Mati\u00e7an"
  },
  {
      "zipCode": "20027",
      "latitude": 42.640795,
      "longitude": 20.929377,
      "city": "Sht\u00ebrpc\u00eb",
      "state": "Kosovo",
      "county": "Sht\u00ebrpc\u00eb"
  },
  {
      "zipCode": "20028",
      "latitude": 42.799459,
      "longitude": 20.794832,
      "city": "Gra\u00e7anic\u00eb",
      "state": "Kosovo",
      "county": "Gra\u00e7anic\u00eb"
  },
  {
      "zipCode": "20029",
      "latitude": 42.688468,
      "longitude": 20.813114,
      "city": "Hani i Elezit",
      "state": "Kosovo",
      "county": "Hani i Elezit"
  },
  {
      "zipCode": "20030",
      "latitude": 42.635135,
      "longitude": 21.151925,
      "city": "Dushanov\u00eb",
      "state": "Kosovo",
      "county": "Dushanov\u00eb"
  },
  {
      "zipCode": "20031",
      "latitude": 42.741968,
      "longitude": 21.037779,
      "city": "Zhur",
      "state": "Kosovo",
      "county": "Zhur"
  },
  {
      "zipCode": "20032",
      "latitude": 42.774175,
      "longitude": 20.707245,
      "city": "Donje \u017dabare",
      "state": "Kosovo",
      "county": "Donje \u017dabare"
  },
  {
      "zipCode": "20033",
      "latitude": 42.652589,
      "longitude": 20.816472,
      "city": "Hajvali",
      "state": "Kosovo",
      "county": "Hajvali"
  },
  {
      "zipCode": "20034",
      "latitude": 42.751081,
      "longitude": 20.670376,
      "city": "Novob\u00ebrd\u00eb",
      "state": "Kosovo",
      "county": "Novob\u00ebrd\u00eb"
  },
  {
      "zipCode": "20035",
      "latitude": 42.644084,
      "longitude": 20.881781,
      "city": "Obiliq",
      "state": "Kosovo",
      "county": "Obiliq"
  },
  {
      "zipCode": "20036",
      "latitude": 42.762895,
      "longitude": 20.649698,
      "city": "Junik",
      "state": "Kosovo",
      "county": "Junik"
  },
  {
      "zipCode": "20037",
      "latitude": 42.635628,
      "longitude": 20.777749,
      "city": "Lubizhd\u00eb",
      "state": "Kosovo",
      "county": "Lubizhd\u00eb"
  },
  {
      "zipCode": "20038",
      "latitude": 42.642046,
      "longitude": 20.918437,
      "city": "Ranillug",
      "state": "Kosovo",
      "county": "Ranillug"
  },
  {
      "zipCode": "20039",
      "latitude": 42.672674,
      "longitude": 20.802604,
      "city": "Bresje",
      "state": "Kosovo",
      "county": "Bresje"
  },
  {
      "zipCode": "20040",
      "latitude": 42.719078,
      "longitude": 20.76294,
      "city": "Mamush\u00eb",
      "state": "Kosovo",
      "county": "Mamush\u00eb"
  },
  {
      "zipCode": "20041",
      "latitude": 42.695847,
      "longitude": 20.695407,
      "city": "Korish\u00eb",
      "state": "Kosovo",
      "county": "Korish\u00eb"
  },
  {
      "zipCode": "20042",
      "latitude": 42.721468,
      "longitude": 20.677191,
      "city": "Damjan",
      "state": "Kosovo",
      "county": "Damjan"
  },
  {
      "zipCode": "20043",
      "latitude": 42.654439,
      "longitude": 20.840266,
      "city": "Mej\u00eb",
      "state": "Kosovo",
      "county": "Mej\u00eb"
  },
  {
      "zipCode": "20044",
      "latitude": 42.67568,
      "longitude": 20.792706,
      "city": "Shipol",
      "state": "Kosovo",
      "county": "Shipol"
  },
  {
      "zipCode": "20045",
      "latitude": 42.65135,
      "longitude": 20.725948,
      "city": "Gjonaj",
      "state": "Kosovo",
      "county": "Gjonaj"
  },
  {
      "zipCode": "20046",
      "latitude": 42.644174,
      "longitude": 20.766402,
      "city": "Restelic\u00eb",
      "state": "Kosovo",
      "county": "Restelic\u00eb"
  },
  {
      "zipCode": "20047",
      "latitude": 42.70474,
      "longitude": 20.756745,
      "city": "Pleshin\u00eb",
      "state": "Kosovo",
      "county": "Pleshin\u00eb"
  },
  {
      "zipCode": "20048",
      "latitude": 42.66867,
      "longitude": 20.830912,
      "city": "Krush\u00eb e Madhe",
      "state": "Kosovo",
      "county": "Krush\u00eb e Madhe"
  },
  {
      "zipCode": "20049",
      "latitude": 42.64268,
      "longitude": 20.707028,
      "city": "Greme",
      "state": "Kosovo",
      "county": "Greme"
  },
  {
      "zipCode": "20050",
      "latitude": 42.657685,
      "longitude": 20.897755,
      "city": "Komogllav\u00eb",
      "state": "Kosovo",
      "county": "Komogllav\u00eb"
  },
  {
      "zipCode": "20051",
      "latitude": 42.699657,
      "longitude": 20.812265,
      "city": "Komoran",
      "state": "Kosovo",
      "county": "Komoran"
  },
  {
      "zipCode": "20052",
      "latitude": 42.629462,
      "longitude": 20.756818,
      "city": "Pozharan",
      "state": "Kosovo",
      "county": "Pozharan"
  },
  {
      "zipCode": "20053",
      "latitude": 42.739309,
      "longitude": 20.830692,
      "city": "Stanoc i Posht\u00ebm",
      "state": "Kosovo",
      "county": "Stanoc i Posht\u00ebm"
  },
  {
      "zipCode": "20054",
      "latitude": 42.747768,
      "longitude": 20.745306,
      "city": "Rogov\u00eb",
      "state": "Kosovo",
      "county": "Rogov\u00eb"
  },
  {
      "zipCode": "20055",
      "latitude": 42.733265,
      "longitude": 20.853272,
      "city": "T\u00ebrstenik",
      "state": "Kosovo",
      "county": "T\u00ebrstenik"
  },
  {
      "zipCode": "20056",
      "latitude": 42.627108,
      "longitude": 20.754749,
      "city": "Smir\u00eb",
      "state": "Kosovo",
      "county": "Smir\u00eb"
  },
  {
      "zipCode": "20057",
      "latitude": 42.681574,
      "longitude": 20.823436,
      "city": "Istini\u0107",
      "state": "Kosovo",
      "county": "Istini\u0107"
  },
  {
      "zipCode": "20058",
      "latitude": 42.65251,
      "longitude": 20.817614,
      "city": "De\u00e7an",
      "state": "Kosovo",
      "county": "De\u00e7an"
  },
  {
      "zipCode": "20059",
      "latitude": 42.647882,
      "longitude": 20.809605,
      "city": "Gllamnik",
      "state": "Kosovo",
      "county": "Gllamnik"
  },
  {
      "zipCode": "20060",
      "latitude": 42.728814,
      "longitude": 20.851032,
      "city": "Ratkoc",
      "state": "Kosovo",
      "county": "Ratkoc"
  },
  {
      "zipCode": "20061",
      "latitude": 42.651063,
      "longitude": 20.786885,
      "city": "Nekodim",
      "state": "Kosovo",
      "county": "Nekodim"
  },
  {
      "zipCode": "20062",
      "latitude": 42.710223,
      "longitude": 20.729973,
      "city": "Banj\u00eb",
      "state": "Kosovo",
      "county": "Banj\u00eb"
  },
  {
      "zipCode": "20063",
      "latitude": 42.726764,
      "longitude": 20.832781,
      "city": "Llashkadrenoc",
      "state": "Kosovo",
      "county": "Llashkadrenoc"
  },
  {
      "zipCode": "20064",
      "latitude": 42.749322,
      "longitude": 20.838448,
      "city": "Donji Crnobreg",
      "state": "Kosovo",
      "county": "Donji Crnobreg"
  },
  {
      "zipCode": "20065",
      "latitude": 42.652871,
      "longitude": 20.743066,
      "city": "Donji Livo\u010d",
      "state": "Kosovo",
      "county": "Donji Livo\u010d"
  },
  {
      "zipCode": "20066",
      "latitude": 42.635188,
      "longitude": 20.721837,
      "city": "Koretin",
      "state": "Kosovo",
      "county": "Koretin"
  },
  {
      "zipCode": "20067",
      "latitude": 42.669488,
      "longitude": 20.783378,
      "city": "Llapushnik",
      "state": "Kosovo",
      "county": "Llapushnik"
  },
  {
      "zipCode": "20068",
      "latitude": 42.669205,
      "longitude": 20.79404,
      "city": "Sllovi",
      "state": "Kosovo",
      "county": "Sllovi"
  },
  {
      "zipCode": "20069",
      "latitude": 42.650727,
      "longitude": 20.859092,
      "city": "Jerli Talinovac",
      "state": "Kosovo",
      "county": "Jerli Talinovac"
  },
  {
      "zipCode": "20070",
      "latitude": 42.699566,
      "longitude": 20.716345,
      "city": "Veliki Ki\u010di\u0107",
      "state": "Kosovo",
      "county": "Veliki Ki\u010di\u0107"
  },
  {
      "zipCode": "20071",
      "latitude": 42.661679,
      "longitude": 20.817833,
      "city": "Ho\u00e7\u00eb e Qytetit",
      "state": "Kosovo",
      "county": "Ho\u00e7\u00eb e Qytetit"
  },
  {
      "zipCode": "20072",
      "latitude": 42.645799,
      "longitude": 20.931559,
      "city": "Malishev\u00eb",
      "state": "Kosovo",
      "county": "Malishev\u00eb"
  },
  {
      "zipCode": "20073",
      "latitude": 42.690476,
      "longitude": 20.81474,
      "city": "Mushtisht\u00eb",
      "state": "Kosovo",
      "county": "Mushtisht\u00eb"
  },
  {
      "zipCode": "20074",
      "latitude": 42.694228,
      "longitude": 20.768547,
      "city": "Bllac\u00eb",
      "state": "Kosovo",
      "county": "Bllac\u00eb"
  },
  {
      "zipCode": "20075",
      "latitude": 42.692146,
      "longitude": 20.728767,
      "city": "Strellc i Ep\u00ebrm",
      "state": "Kosovo",
      "county": "Strellc i Ep\u00ebrm"
  },
  {
      "zipCode": "20076",
      "latitude": 42.630827,
      "longitude": 20.699639,
      "city": "Donje Gadimlje",
      "state": "Kosovo",
      "county": "Donje Gadimlje"
  },
  {
      "zipCode": "20077",
      "latitude": 42.674954,
      "longitude": 20.710283,
      "city": "Zheg\u00ebr",
      "state": "Kosovo",
      "county": "Zheg\u00ebr"
  },
  {
      "zipCode": "20078",
      "latitude": 42.637731,
      "longitude": 20.761343,
      "city": "Studen\u00e7an",
      "state": "Kosovo",
      "county": "Studen\u00e7an"
  },
  {
      "zipCode": "20079",
      "latitude": 42.634271,
      "longitude": 20.835267,
      "city": "Samodra\u017ea",
      "state": "Kosovo",
      "county": "Samodra\u017ea"
  },
  {
      "zipCode": "20080",
      "latitude": 42.647629,
      "longitude": 20.846676,
      "city": "X\u00ebrx\u00eb",
      "state": "Kosovo",
      "county": "X\u00ebrx\u00eb"
  },
  {
      "zipCode": "20081",
      "latitude": 42.683269,
      "longitude": 20.834847,
      "city": "Arllat",
      "state": "Kosovo",
      "county": "Arllat"
  },
  {
      "zipCode": "20082",
      "latitude": 42.643513,
      "longitude": 20.839922,
      "city": "Nekoc",
      "state": "Kosovo",
      "county": "Nekoc"
  },
  {
      "zipCode": "20083",
      "latitude": 42.674042,
      "longitude": 20.854115,
      "city": "Obranq\u00eb",
      "state": "Kosovo",
      "county": "Obranq\u00eb"
  },
  {
      "zipCode": "20084",
      "latitude": 42.658032,
      "longitude": 20.716535,
      "city": "Sopij\u00eb",
      "state": "Kosovo",
      "county": "Sopij\u00eb"
  },
  {
      "zipCode": "20085",
      "latitude": 42.682723,
      "longitude": 20.881107,
      "city": "Dumnic\u00eb e Poshtme",
      "state": "Kosovo",
      "county": "Dumnic\u00eb e Poshtme"
  },
  {
      "zipCode": "20086",
      "latitude": 42.73347,
      "longitude": 20.811829,
      "city": "Brekoc",
      "state": "Kosovo",
      "county": "Brekoc"
  },
  {
      "zipCode": "20087",
      "latitude": 42.755673,
      "longitude": 20.734972,
      "city": "Mazgit",
      "state": "Kosovo",
      "county": "Mazgit"
  },
  {
      "zipCode": "20088",
      "latitude": 42.668056,
      "longitude": 20.692526,
      "city": "Brodosan\u00eb",
      "state": "Kosovo",
      "county": "Brodosan\u00eb"
  },
  {
      "zipCode": "20089",
      "latitude": 42.748613,
      "longitude": 20.758103,
      "city": "Bresalc",
      "state": "Kosovo",
      "county": "Bresalc"
  },
  {
      "zipCode": "20090",
      "latitude": 42.686826,
      "longitude": 20.669362,
      "city": "Bardhi i Madh",
      "state": "Kosovo",
      "county": "Bardhi i Madh"
  },
  {
      "zipCode": "20091",
      "latitude": 42.653053,
      "longitude": 20.761658,
      "city": "Romaj\u00eb",
      "state": "Kosovo",
      "county": "Romaj\u00eb"
  },
  {
      "zipCode": "20092",
      "latitude": 42.739176,
      "longitude": 20.707217,
      "city": "Mrenoc",
      "state": "Kosovo",
      "county": "Mrenoc"
  },
  {
      "zipCode": "20093",
      "latitude": 42.726912,
      "longitude": 20.743504,
      "city": "Kushtov\u00eb",
      "state": "Kosovo",
      "county": "Kushtov\u00eb"
  },
  {
      "zipCode": "20094",
      "latitude": 42.751512,
      "longitude": 20.793436,
      "city": "Rr\u00ebza i Dajti",
      "state": "Kosovo",
      "county": "Rr\u00ebza i Dajti"
  },
  {
      "zipCode": "20095",
      "latitude": 42.721317,
      "longitude": 20.798458,
      "city": "Krajk",
      "state": "Kosovo",
      "county": "Krajk"
  },
  {
      "zipCode": "20096",
      "latitude": 42.735682,
      "longitude": 20.756186,
      "city": "Rogov\u00eb e Poshtme",
      "state": "Kosovo",
      "county": "Rogov\u00eb e Poshtme"
  },
  {
      "zipCode": "20097",
      "latitude": 42.737357,
      "longitude": 20.807243,
      "city": "Llapashtic\u00eb",
      "state": "Kosovo",
      "county": "Llapashtic\u00eb"
  },
  {
      "zipCode": "20098",
      "latitude": 42.751207,
      "longitude": 20.796903,
      "city": "Donja Klina",
      "state": "Kosovo",
      "county": "Donja Klina"
  },
  {
      "zipCode": "20099",
      "latitude": 42.642176,
      "longitude": 20.831214,
      "city": "Lajthiza",
      "state": "Kosovo",
      "county": "Lajthiza"
  },
  {
      "zipCode": "20100",
      "latitude": 42.648485,
      "longitude": 20.711612,
      "city": "Shak\u00ebr",
      "state": "Kosovo",
      "county": "Shak\u00ebr"
  },
  {
      "zipCode": "20101",
      "latitude": 42.675235,
      "longitude": 20.731219,
      "city": "Cakran",
      "state": "Kosovo",
      "county": "Cakran"
  },
  {
      "zipCode": "20102",
      "latitude": 42.642383,
      "longitude": 20.710869,
      "city": "Donje i Gajde",
      "state": "Kosovo",
      "county": "Donje i Gajde"
  },
  {
      "zipCode": "20103",
      "latitude": 42.662692,
      "longitude": 20.740336,
      "city": "Dajkoc",
      "state": "Kosovo",
      "county": "Dajkoc"
  },
  {
      "zipCode": "20104",
      "latitude": 42.735522,
      "longitude": 20.771063,
      "city": "Mali\u0161evo",
      "state": "Kosovo",
      "county": "Mali\u0161evo"
  },
  {
      "zipCode": "20105",
      "latitude": 42.686364,
      "longitude": 20.730872,
      "city": "Gajkov\u00eb",
      "state": "Kosovo",
      "county": "Gajkov\u00eb"
  },
  {
      "zipCode": "20106",
      "latitude": 42.656426,
      "longitude": 20.767722,
      "city": "Shtime",
      "state": "Kosovo",
      "county": "Shtime"
  },
  {
      "zipCode": "20107",
      "latitude": 42.73778,
      "longitude": 20.730866,
      "city": "Krah\u00ebn",
      "state": "Kosovo",
      "county": "Krah\u00ebn"
  },
  {
      "zipCode": "20108",
      "latitude": 42.640915,
      "longitude": 20.759445,
      "city": "Zaskok",
      "state": "Kosovo",
      "county": "Zaskok"
  },
  {
      "zipCode": "20109",
      "latitude": 42.725564,
      "longitude": 20.743957,
      "city": "Gajkoc",
      "state": "Kosovo",
      "county": "Gajkoc"
  },
  {
      "zipCode": "20110",
      "latitude": 42.659284,
      "longitude": 20.759725,
      "city": "Arban\u00eb",
      "state": "Kosovo",
      "county": "Arban\u00eb"
  },
  {
      "zipCode": "20111",
      "latitude": 42.664768,
      "longitude": 20.731591,
      "city": "Rajce",
      "state": "Kosovo",
      "county": "Rajce"
  },
  {
      "zipCode": "20112",
      "latitude": 42.720072,
      "longitude": 20.750778,
      "city": "Bujan",
      "state": "Kosovo",
      "county": "Bujan"
  },
  {
      "zipCode": "20113",
      "latitude": 42.638601,
      "longitude": 20.744038,
      "city": "Jasi",
      "state": "Kosovo",
      "county": "Jasi"
  },
  {
      "zipCode": "20114",
      "latitude": 42.683916,
      "longitude": 20.793823,
      "city": "Sht\u00ebrpce",
      "state": "Kosovo",
      "county": "Sht\u00ebrpce"
  },
  {
      "zipCode": "20115",
      "latitude": 42.657369,
      "longitude": 20.804812,
      "city": "Zahaq",
      "state": "Kosovo",
      "county": "Zahaq"
  },
  {
      "zipCode": "20116",
      "latitude": 42.669593,
      "longitude": 20.792297,
      "city": "Trpej",
      "state": "Kosovo",
      "county": "Trpej"
  },
  {
      "zipCode": "20117",
      "latitude": 42.732752,
      "longitude": 20.75764,
      "city": "Baj\u00e7\u00eb",
      "state": "Kosovo",
      "county": "Baj\u00e7\u00eb"
  },
  {
      "zipCode": "20118",
      "latitude": 42.672225,
      "longitude": 20.795014,
      "city": "Shtrepc\u00eb",
      "state": "Kosovo",
      "county": "Shtrepc\u00eb"
  },
  {
      "zipCode": "20119",
      "latitude": 42.660237,
      "longitude": 20.768415,
      "city": "Rugov\u00eb",
      "state": "Kosovo",
      "county": "Rugov\u00eb"
  },
  {
      "zipCode": "20120",
      "latitude": 42.623393,
      "longitude": 20.73255,
      "city": "Fush\u00eb-Kruj\u00eb",
      "state": "Kosovo",
      "county": "Fush\u00eb-Kruj\u00eb"
  },
  {
      "zipCode": "20121",
      "latitude": 42.647016,
      "longitude": 20.793391,
      "city": "Rrashk\u00eb",
      "state": "Kosovo",
      "county": "Rrashk\u00eb"
  },
  {
      "zipCode": "20122",
      "latitude": 42.624891,
      "longitude": 20.76404,
      "city": "Topli\u00e7\u00eb",
      "state": "Kosovo",
      "county": "Topli\u00e7\u00eb"
  },
  {
      "zipCode": "20123",
      "latitude": 42.675276,
      "longitude": 20.754945,
      "city": "Viti",
      "state": "Kosovo",
      "county": "Viti"
  },
  {
      "zipCode": "20124",
      "latitude": 42.675603,
      "longitude": 20.797249,
      "city": "Bujan",
      "state": "Kosovo",
      "county": "Bujan"
  },
  {
      "zipCode": "20125",
      "latitude": 42.697523,
      "longitude": 20.746436,
      "city": "Mu\u00e7iverc\u00eb",
      "state": "Kosovo",
      "county": "Mu\u00e7iverc\u00eb"
  },
  {
      "zipCode": "20126",
      "latitude": 42.736662,
      "longitude": 20.756856,
      "city": "Sht\u00ebpc\u00eb",
      "state": "Kosovo",
      "county": "Sht\u00ebpc\u00eb"
  },
  {
      "zipCode": "20127",
      "latitude": 42.719071,
      "longitude": 20.774026,
      "city": "Malisheva",
      "state": "Kosovo",
      "county": "Malisheva"
  },
  {
      "zipCode": "20128",
      "latitude": 42.647446,
      "longitude": 20.790013,
      "city": "Rrahovec",
      "state": "Kosovo",
      "county": "Rrahovec"
  },
  {
      "zipCode": "20129",
      "latitude": 42.722091,
      "longitude": 20.796305,
      "city": "V\u00ebrrin\u00eb",
      "state": "Kosovo",
      "county": "V\u00ebrrin\u00eb"
  },
  {
      "zipCode": "20130",
      "latitude": 42.65127,
      "longitude": 20.780741,
      "city": "Isniq",
      "state": "Kosovo",
      "county": "Isniq"
  },
  {
      "zipCode": "20131",
      "latitude": 42.658903,
      "longitude": 20.727851,
      "city": "Drenoc",
      "state": "Kosovo",
      "county": "Drenoc"
  },
  {
      "zipCode": "20132",
      "latitude": 42.629021,
      "longitude": 20.752828,
      "city": "Cakran",
      "state": "Kosovo",
      "county": "Cakran"
  },
  {
      "zipCode": "20133",
      "latitude": 42.652533,
      "longitude": 20.726408,
      "city": "Gajkov\u00eb",
      "state": "Kosovo",
      "county": "Gajkov\u00eb"
  },
  {
      "zipCode": "20134",
      "latitude": 42.642659,
      "longitude": 20.764239,
      "city": "Jasi",
      "state": "Kosovo",
      "county": "Jasi"
  },
  {
      "zipCode": "20135",
      "latitude": 42.667051,
      "longitude": 20.777387,
      "city": "Kollov\u00eb",
      "state": "Kosovo",
      "county": "Kollov\u00eb"
  },
  {
      "zipCode": "20136",
      "latitude": 42.655361,
      "longitude": 20.762609,
      "city": "Shtime",
      "state": "Kosovo",
      "county": "Shtime"
  },
  {
      "zipCode": "20137",
      "latitude": 42.638593,
      "longitude": 20.733572,
      "city": "Nikol\u00e7",
      "state": "Kosovo",
      "county": "Nikol\u00e7"
  },
  {
      "zipCode": "20138",
      "latitude": 42.664212,
      "longitude": 20.764451,
      "city": "Grlic\u00eb",
      "state": "Kosovo",
      "county": "Grlic\u00eb"
  },
  {
      "zipCode": "20139",
      "latitude": 42.645123,
      "longitude": 20.754434,
      "city": "Llapashtic\u00eb",
      "state": "Kosovo",
      "county": "Llapashtic\u00eb"
  },
  {
      "zipCode": "20140",
      "latitude": 42.693621,
      "longitude": 20.809606,
      "city": "Breznic\u00eb",
      "state": "Kosovo",
      "county": "Breznic\u00eb"
  },
  {
      "zipCode": "20141",
      "latitude": 42.683987,
      "longitude": 20.778122,
      "city": "Rajce",
      "state": "Kosovo",
      "county": "Rajce"
  },
  {
      "zipCode": "20142",
      "latitude": 42.645892,
      "longitude": 20.751549,
      "city": "Shak\u00eb",
      "state": "Kosovo",
      "county": "Shak\u00eb"
  },
  {
      "zipCode": "20143",
      "latitude": 42.674831,
      "longitude": 20.748564,
      "city": "X\u00ebrx\u00eb",
      "state": "Kosovo",
      "county": "X\u00ebrx\u00eb"
  },
  {
      "zipCode": "20144",
      "latitude": 42.661749,
      "longitude": 20.755426,
      "city": "Llapushnik",
      "state": "Kosovo",
      "county": "Llapushnik"
  },
  {
      "zipCode": "20145",
      "latitude": 42.659425,
      "longitude": 20.738084,
      "city": "Sht\u00ebrpc\u00eb",
      "state": "Kosovo",
      "county": "Sht\u00ebrpc\u00eb"
  },
  {
      "zipCode": "20146",
      "latitude": 42.645895,
      "longitude": 20.711254,
      "city": "Hajvali",
      "state": "Kosovo",
      "county": "Hajvali"
  },
  {
      "zipCode": "20147",
      "latitude": 42.658451,
      "longitude": 20.716185,
      "city": "Prapashtic\u00eb",
      "state": "Kosovo",
      "county": "Prapashtic\u00eb"
  },
  {
      "zipCode": "20148",
      "latitude": 42.69929,
      "longitude": 20.75964,
      "city": "Vrell\u00eb",
      "state": "Kosovo",
      "county": "Vrell\u00eb"
  },
  {
      "zipCode": "20149",
      "latitude": 42.720471,
      "longitude": 20.74073,
      "city": "P\u00ebrlepnic\u00eb",
      "state": "Kosovo",
      "county": "P\u00ebrlepnic\u00eb"
  },
  {
      "zipCode": "20150",
      "latitude": 42.652775,
      "longitude": 20.721861,
      "city": "Tushk\u00eb",
      "state": "Kosovo",
      "county": "Tushk\u00eb"
  },
  {
      "zipCode": "20151",
      "latitude": 42.65956,
      "longitude": 20.736914,
      "city": "Peran",
      "state": "Kosovo",
      "county": "Peran"
  },
  {
      "zipCode": "20152",
      "latitude": 42.630578,
      "longitude": 20.720799,
      "city": "Gajkov\u00eb",
      "state": "Kosovo",
      "county": "Gajkov\u00eb"
  },
  {
      "zipCode": "20153",
      "latitude": 42.652529,
      "longitude": 20.744087,
      "city": "Krajk",
      "state": "Kosovo",
      "county": "Krajk"
  },
  {
      "zipCode": "20154",
      "latitude": 42.675169,
      "longitude": 20.774124,
      "city": "Cakran",
      "state": "Kosovo",
      "county": "Cakran"
  },
  {
      "zipCode": "20155",
      "latitude": 42.621723,
      "longitude": 20.738363,
      "city": "Bajc\u00eb",
      "state": "Kosovo",
      "county": "Bajc\u00eb"
  },
  {
      "zipCode": "20156",
      "latitude": 42.68508,
      "longitude": 20.806564,
      "city": "Dajkoc",
      "state": "Kosovo",
      "county": "Dajkoc"
  },
  {
      "zipCode": "20157",
      "latitude": 42.681634,
      "longitude": 20.814398,
      "city": "Koshar",
      "state": "Kosovo",
      "county": "Koshar"
  },
  {
      "zipCode": "20158",
      "latitude": 42.724166,
      "longitude": 20.764594,
      "city": "Prapashtic\u00eb",
      "state": "Kosovo",
      "county": "Prapashtic\u00eb"
  },
  {
      "zipCode": "20159",
      "latitude": 42.678597,
      "longitude": 20.756459,
      "city": "Rajce",
      "state": "Kosovo",
      "county": "Rajce"
  },
  {
      "zipCode": "20160",
      "latitude": 42.730471,
      "longitude": 20.797073,
      "city": "Kolonje",
      "state": "Kosovo",
      "county": "Kolonje"
  }
]